import FreteTipoCalculo from 'core/models/FRO/freteTipoCalculo';
import api from '../api';

const baseURL = `/FRO/FreteTipoCalculo`;

export const getFreteTipoCalculoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    tipoCalculos: value?.map((json) => new FreteTipoCalculo(json)) ?? [],
  };
};
