import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete, Button, Modal, GridView } from 'ui/components';
import { ResponseStatus, Theme, BootstrapSizes } from 'ui/Helpers/utils';
import { getEmpresasUsuario } from 'core/services/SEG/empresa';
import { changeChipCompany } from 'core/services/TEL';

export default function ModalTrocarEmpresa({
  linhasSelecionadas,
  show,
  onClose,
}) {
  const gridViewLinhas = useRef();
  const [chip, setChip] = useState({});
  const [messageState, setMessage] = useState(null);
  const columns = [
    { key: 'nrSeqChip', visible: false },
    { key: 'cdChip', title: 'Código' },
    { key: 'linhaFormatada', title: 'Nr. Linha' },
    { key: 'status', title: 'Status' },
    { key: 'nriccid', title: 'ICCID' },
    { key: 'operadora', title: 'Operadora' },
    { key: 'empresa', title: 'Empresa' },
    { key: 'situacao', title: 'Situação' },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  const onSave = async () => {
    const nrSeqChips = linhasSelecionadas.map((e) => e.nrSeqChip);

    nrSeqChips.push(chip.nrSeqEmpresa);
    const { status, message: msg } = await changeChipCompany(nrSeqChips);
    if (status === ResponseStatus.Success) {
      onClose();
    }
    onSetMessage(status, msg);
  };

  const onSearchEmpresa = async (e) => {
    const { empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });
    return empresas;
  };

  const searchEmpresas = async () => {
    const { status, message, empresas } = await getEmpresasUsuario({});
    onSetMessage(status, message);

    return empresas;
  };

  useEffect(() => {
    searchEmpresas();
    if (gridViewLinhas && gridViewLinhas.current) {
      if (linhasSelecionadas.length > 0)
        gridViewLinhas.current.setDataSource(linhasSelecionadas);
    }
  }, [linhasSelecionadas]);

  return (
    <Modal
      show={show}
      size={BootstrapSizes.Large}
      title='Trocar de Empresa'
      onClose={onClose}
      message={messageState}
    >
      <Modal.Body className='bg-light'>
        <div className='col-6'>
          <Autocomplete
            label='Empresa'
            searchDataSource={onSearchEmpresa}
            selectedItem={chip.empresa}
            onSelectItem={(empresa) =>
              setChip({
                ...chip,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='row mt-3'>
          <div className='col'>
            <GridView
              ref={gridViewLinhas}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          theme={Theme.Primary}
          icon='times'
          text='Save'
          onClick={onSave}
        />
        <Button
          theme={Theme.Primary}
          icon='times'
          text='Fechar'
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
