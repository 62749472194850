import FormaPagamento from '../FIN/formaPagamento';

export default class NfsFatura {
  constructor(jsonObject = {}) {
    this.nfsFatura = jsonObject.nfsFatura;
    this.nrSeqNfs = jsonObject.nrSeqNfs;
    this.vlrParcela = jsonObject.vlrParcela;
    this.dtVencimento = jsonObject.dtVencimento;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqFatura = jsonObject.nrSeqFatura;
    this.noStatus = jsonObject.noStatus;

    this.formaPagamento =
      jsonObject.formaPagamento !== undefined &&
      jsonObject.formaPagamento !== null
        ? new FormaPagamento(jsonObject.formaPagamento)
        : new FormaPagamento();
  }

  toJson = () => JSON.stringify(this);
}
