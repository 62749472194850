import React, { useState } from 'react';

import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  Loader,
  Modal,
  Notification,
} from 'ui/components';

import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { changePaymentMethod } from 'core/services/TEL/clienteLinha';

export default function ModalAlterarFormaPagamento({ recurso, show, onClose }) {
  const [data, setData] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
    });
    onSetMessage(status, msg);
    return formasPagamento;
  };

  const onSend = async () => {
    setLoading(true);
    const nrSeqClientesLinhas = JSON.parse(JSON.stringify(recurso));
    nrSeqClientesLinhas.push(data.nrSeqFormaPagamento);

    const { status, message: msg } = await changePaymentMethod(
      nrSeqClientesLinhas
    );
    setLoading(false);
    onSetMessage(status, msg);
  };

  return (
    <Modal
      show={show}
      title='Alterar Forma de Pagamento'
      onClose={onClose}
      size={BootstrapSizes.Large}
      icon={['far', 'comment-alt']}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col'>
            <Autocomplete
              label='Forma de Pagamento'
              searchDataSource={onSearchFormaPagamento}
              selectedItem={data.formaPagamento}
              onSelectItem={(formaPagamento) =>
                setData({
                  ...data,
                  formaPagamento,
                  nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                })
              }
              dataSourceTextProperty='noFormaPagamento'
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Salvar'
          theme={Theme.Success}
          icon='sms'
          onClick={onSend}
        />
        <Button
          text='Sair'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            setData({});
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
