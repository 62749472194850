export default class PagamentoMassivoItem {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqPagamentoMassivoItem = jsonObject.nrSeqPagamentoMassivoItem;
    this.vlrSaldoPago = jsonObject.vlrSaldoPago;
    this.vlrMulta = jsonObject.vlrMulta;
    this.vlrJuro = jsonObject.vlrJuro;
    this.vlrTotal = jsonObject.vlrTotal;
    this.dtPagamento = jsonObject.dtPagamento;
    this.nrSeqPagamentoMassivo = jsonObject.nrSeqPagamentoMassivo;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqContaCorrente = jsonObject.nrSeqContaCorrente;
  }

  toJson = () => JSON.stringify(this);
}
