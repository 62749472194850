import React, { useRef, useState, useEffect } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  printRecebimento,
  excelRecebimento,
  getRecebimentoPagined,
} from 'core/services/FIN/recebimento';
import {
  Autocomplete,
  CSDSelPage,
  DatePicker,
  DropdownMulti,
  GridView,
  Panel,
  RadioButton,
  Textbox,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getClienteAutoComplete,
  getFormaPagamentoAutoComplete,
} from 'core/services/FIN';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import { SearchColetaEmbarqueRecebimento } from 'core/services/FRO/coletaEmbarque';
import ModalVisualizarCTE from './modalVisualizarCTE';

export default function Recebimento({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({ tipoTituloReceber: 'A' });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});
  const dropDownFormaPagamento = useRef();
  const [modalShow, setModalShow] = useState({});

  const search = async (params = null) => {
    setLoading(true);

    const { data: recebimentos, pagination } = await getRecebimentoPagined(
      params ?? filtros
    );

    let sum = 0;
    let saldo = 0;
    let pago = 0;

    for (let i = 0; i < recebimentos.length; ) {
      sum += recebimentos[i].vlrTitulo;
      pago += recebimentos[i].vlrPago;
      saldo += recebimentos[i].vlrSaldoTit;
      i += 1;
    }

    filtros.vlrTotal = sum;
    filtros.saldoTotal = saldo;
    filtros.totalPago = pago;

    if (gridView && gridView.current)
      gridView.current.setDataSource(recebimentos, pagination);

    setLoading(false);
  };

  const onOpenVisualizarCTe = async (sourceRow) => {
    const searchTituloReceber = sourceRow.tituloReceber.nrSeqTituloReceber;

    const titulosreceber = await SearchColetaEmbarqueRecebimento(
      searchTituloReceber
    );

    if (titulosreceber.data.length === 0) {
      setMessage({
        message: 'Recebimento Selecionado, sem CTEs vinculados',
        theme:
          titulosreceber.status === ResponseStatus.Success
            ? Theme.Success
            : Theme.Danger,
      });
      return;
    }

    setModalShow({
      ...modalShow,
      visualizerCTEs: true,
      coletaEmbarqueS: titulosreceber.data,
      nrSeqTituloReceber: searchTituloReceber,
    });
  };

  const columns = [
    {
      key: 'tituloReceber.nrSeqTituloReceber',
      type: GridView.ColumnTypes.Checkbox,
    },
    { key: 'tituloReceber.nrTit', title: 'Tit', sortKey: 'nrTit' },
    { key: 'tituloReceber.nrOrdem', title: 'Ordem', sortKey: 'nrOrdem' },

    {
      key: 'tituloReceber.cliente.noPessoa',
      title: 'Cliente',
      sortKey: 'noPessoa',
    },
    {
      key: 'tituloReceber.tituloEspecie.noEspecie',
      title: 'Receita',
      sortKey: 'noEspecie',
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma Pagamento',
      sortKey: 'noFormaPagamento',
    },
    {
      key: 'tituloReceber.vlrTitulo',
      title: 'Valor',
      format: GridView.DataTypes.Money,
      sortKey: 'vlrTitulo',
    },
    {
      key: 'vlrTotal',
      title: 'Recebido',
      format: GridView.DataTypes.Money,
      sortKey: 'vlrTotal',
    },
    {
      key: 'tituloReceber.vlrSaldoTit',
      title: 'Saldo',
      format: GridView.DataTypes.Money,
      sortKey: 'vlrSaldoTit',
    },

    {
      key: 'tituloReceber.dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
      sortKey: 'dtVencimento',
    },
    {
      key: 'dtRecebimento',
      title: 'Data Recebimento',
      format: GridView.DataTypes.Date,
      sortKey: 'dtRecebimento',
    },
    {
      key: 'tituloReceber.nrSeqTituloReceber',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onOpenVisualizarCTe(e),
      theme: Theme.Primary,
      icon: 'cash-register',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Visualizar CTEs',
      tooltipDirection: 'bottom',
      title: 'Visualizar CTEs',
    },
  ];

  const tipoTitulo = [
    {
      text: 'Aberto',
      value: 'A',
    },
    {
      text: 'Todos',
      value: 'T',
    },
    {
      text: 'Fechado',
      value: 'F',
    },
  ];

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Recebimento/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqRecebimento = sourceRow.find(
      (e) => e.key === 'tituloReceber.nrSeqTituloReceber'
    );
    onOpenMaintenance(nrSeqRecebimento.value);
  };

  const onPrint = async (param) => {
    setLoading(true);
    const obj = { ...filtros, noRelatorio: param };
    const value = await printRecebimento(obj);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onPrintExcel = async (param) => {
    setLoading(true);
    const obj = { ...filtros, noRelatorio: param };
    const value = await excelRecebimento(obj);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `Recebimento.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  /* const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllRecebimento(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  }; */

  const onSearchFormaPagamento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
    });

    return formasPagamento;
  };

  useEffect(() => {
    dropDownFormaPagamento.current.loadDataSource(onSearchFormaPagamento);
  }, []);

  const onSearchTipo = async (e) => {
    const { data: tituloReceberEspecie } = await getTituloEspecieAutoComplete({
      noEspecie: e,
    });

    return tituloReceberEspecie;
  };
  const onSearchEmpresa = async (e) => {
    const { empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    return empresas;
  };
  const onSearchCliente = async (e) => {
    const { clientes } = await getClienteAutoComplete({
      noPessoa: e,
      flgFiltrarEmpresa: true,
    });
    return clientes;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Recebimento'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      // onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Imprimir'
          icon='print'
          onClick={() => onPrint('Geral')}
        />
        <ToolbarButtons.ButtonList
          onPrintFornecedorAgrupado
          icon='caret-down'
          items={[
            {
              text: 'Cliente',
              onClick: () => onPrint('Cliente'),
            },
            {
              text: 'Total do Cliente ',
              onClick: () => onPrint('TotalCliente'),
            },
            {
              text: 'Vencimento',
              onClick: () => onPrint('Vencimento'),
            },
            {
              text: 'Recebimento',
              onClick: () => onPrint('Recebimento'),
            },
            {
              text: 'Receb. c/ Juros e Multa',
              onClick: () => onPrint('JuroMulta'),
            },
            {
              text: 'Receb. Agrup. Form. Recebimento',
              onClick: () => onPrint('FormaRecebimento'),
            },
          ]}
        />
      </ToolbarButtons>
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Excel'
          icon={['far', 'file-excel']}
          onClick={() => onPrintExcel('Padrao')}
        />
        <ToolbarButtons.ButtonList
          onPrintFornecedorAgrupado
          icon='caret-down'
          items={[
            {
              text: 'Cliente',
              onClick: () => onPrintExcel('Cliente'),
            },
            {
              text: 'Total do Cliente ',
              onClick: () => onPrintExcel('TotalCliente'),
            },
            {
              text: 'Vencimento',
              onClick: () => onPrintExcel('Vencimento'),
            },
            {
              text: 'Recebimento',
              onClick: () => onPrintExcel('Recebimento'),
            },
            {
              text: 'Receb. c/ Juros e Multa',
              onClick: () => onPrintExcel('JuroMulta'),
            },
            {
              text: 'Receb. Agrup. Form. Recebimento',
              onClick: () => onPrintExcel('FormaRecebimento'),
            },
          ]}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Filtros' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-8'>
                <Autocomplete
                  label='Cliente'
                  searchDataSource={onSearchCliente}
                  selectedItem={filtros.cliente}
                  nrseqTransactionToOpen='nrSeqPessoaCli'
                  onSelectItem={(cliente) => {
                    setFiltros({
                      ...filtros,
                      cliente,
                      nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col'>
                <RadioButton
                  label='Tipos de Titulo Receber'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.tipoTituloReceber}
                  buttons={tipoTitulo}
                  onChange={(tipoTituloReceber) => {
                    setFiltros({
                      ...filtros,
                      tipoTituloReceber,
                    });
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-4'>
                <Autocomplete
                  label='Empresa'
                  searchDataSource={onSearchEmpresa}
                  selectedItem={filtros.empresa}
                  onSelectItem={(empresa) => {
                    setFiltros({
                      ...filtros,
                      empresa,
                      nrSeqEmpresa: empresa.nrSeqEmpresa,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>

              <div className='col-4'>
                <Autocomplete
                  label='Tipo'
                  searchDataSource={onSearchTipo}
                  selectedItem={filtros.tituloEspecie}
                  onSelectItem={(tituloEspecie) => {
                    setFiltros({
                      ...filtros,
                      tituloEspecie,
                      nrSeqTituloEspecie: tituloEspecie.nrSeqTituloEspecie,
                    });
                  }}
                  dataSourceTextProperty='noEspecie'
                />
              </div>
              <div className='col-4'>
                <Textbox
                  maxLength={20}
                  label='Nr. Nota'
                  text={filtros.nrnfv}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(nrnfv) =>
                    setFiltros({
                      ...filtros,
                      nrnfv,
                    })
                  }
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-4'>
                <DropdownMulti
                  label='Forma De Recebimento'
                  ref={dropDownFormaPagamento}
                  dataSourcePropertyText='noFormaPagamento'
                  dataSourcePropertyValue='nrSeqFormaPagamento'
                  selectedItems={selectedItems.formaPagamento ?? []}
                  onSelectItem={(formaPagamento) => {
                    setSelectedItems({ ...selectedItems, formaPagamento });
                    setFiltros({
                      ...filtros,
                      nrSeqFormaPagamentos: formaPagamento?.map(
                        (p) => p.nrSeqFormaPagamento
                      ),
                    });
                  }}
                />
              </div>
              <div className='col-4'>
                <Textbox
                  maxLength={20}
                  label='Nr.Titulo/Fatura'
                  text={filtros.nrTitulo}
                  onChangedValue={(nrTitulo) =>
                    setFiltros({
                      ...filtros,
                      nrTitulo,
                    })
                  }
                />
              </div>
              <div className='col-4'>
                <Textbox
                  maxLength={20}
                  label='Valor'
                  mask={MaskTypes.DecimalCustom}
                  text={filtros.vlrTitulo}
                  onChangedValue={(vlrTitulo) =>
                    setFiltros({
                      ...filtros,
                      vlrTitulo,
                    })
                  }
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-2'>
                <DatePicker
                  label='Vencimento De'
                  text={filtros.dtVencimentoDe}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtVencimentoDe) =>
                    setFiltros({ ...filtros, dtVencimentoDe })
                  }
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Vencimento Até'
                  text={filtros.dtVencimentoAt}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtVencimentoAt) =>
                    setFiltros({ ...filtros, dtVencimentoAt })
                  }
                />
              </div>
              <div className='col-2 mb-3'>
                <DatePicker
                  label='Recebimento De'
                  text={filtros.dtRecebimentoDe}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtRecebimentoDe) =>
                    setFiltros({ ...filtros, dtRecebimentoDe })
                  }
                />
              </div>
              <div className='col-2 mb-3'>
                <DatePicker
                  label='Recebimento Até'
                  text={filtros.dtRecebimentoAte}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtRecebimentoAte) =>
                    setFiltros({ ...filtros, dtRecebimentoAte })
                  }
                />
              </div>
              <div className='col-4'>
                <Textbox
                  maxLength={20}
                  label='Nº do Recibo'
                  text={filtros.nrIdentificado}
                  onChangedValue={(nrIdentificado) =>
                    setFiltros({
                      ...filtros,
                      nrIdentificado,
                    })
                  }
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            sumFields
            enableExcelExport
          />
        </div>
      </div>
      <ModalVisualizarCTE
        show={modalShow.visualizerCTEs}
        coletaEmbarqueS={modalShow.coletaEmbarqueS}
        nrSeqTituloReceber={modalShow.nrSeqTituloReceber}
        onClose={({ message: msg, theme }) => {
          setModalShow({
            ...modalShow,
            visualizerCTEs: false,
          });
          setMessage({
            message: msg,
            theme,
          });
        }}
      />
    </CSDSelPage>
  );
}
