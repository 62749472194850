import TipoDesconto from 'core/models/FIN/tipoDesconto';
import Motorista from 'core/models/FRO/motorista';
import Veiculo from 'core/models/FRO/veiculo';
import Cidade from 'core/models/SEG/cidade';

export default class ComissaoMotorista {
  constructor(jsonObject = {}) {
    this.nrSeqColetaEmbarque = jsonObject.nrSeqColetaEmbarque;
    this.nrSeqComissaoMotorista = jsonObject.nrSeqComissaoMotorista;
    this.vlrFrete = jsonObject.vlrFrete;
    this.peso = jsonObject.peso;
    this.notaTransportada = jsonObject.notaTransportada;
    this.nrSeqCidadeOri = jsonObject.nrSeqCidadeOri;
    this.noCidadeOri = jsonObject.noCidadeOri;
    this.nrSeqCidade = jsonObject.nrSeqCidade;
    this.vlrComissao = jsonObject.vlrComissao;
    this.nrSeqViagem = jsonObject.nrSeqViagem;
    this.dtViagem = jsonObject.dtViagem;
    this.vlrVale = jsonObject.vlrVale;
    this.nrSeqTituloPagar = jsonObject.nrSeqTituloPagar;
    this.nrTit = jsonObject.nrTit;
    this.listIdsgridView = [];
    this.flgPericulosidade = jsonObject.flgPericulosidade;
    this.noObservacao = jsonObject.noObservacao;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.noPlaca = jsonObject.noPlaca;
    this.nrSeqPessoaMot = jsonObject.nrSeqPessoaMot;
    this.noMotorista = jsonObject.noMotorista;
    this.nrNotaFiscal = jsonObject.nrNotaFiscal;
    this.vlrTotal = jsonObject.vlrTotal;
    this.vlrTarifa = jsonObject.vlrTarifa;
    this.vlrRepo = jsonObject.vlrRepo;
    this.nrSeqCidadeDes = jsonObject.nrSeqCidadeDes;
    this.noCidadeDes = jsonObject.noCidadeDes;
    this.tipoDesconto = new TipoDesconto(jsonObject.tipoDesconto ?? {});
    this.motorista = new Motorista(jsonObject.motorista ?? {});
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    this.cidadeDes = new Cidade(jsonObject.cidadeDes ?? {});
    this.cidadeOri = new Cidade(jsonObject.cidadeOri ?? {});
    this.tiposDesconto = jsonObject.tiposDesconto
      ? jsonObject.tiposDesconto.map((item) => new TipoDesconto(item ?? {}))
      : [];
  }

  toJson = () => JSON.stringify(this);
}
