import React, { useState, useRef } from 'react';
import { CSDSelPage, GridView, Textbox } from 'ui/components';
import { PageTypes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { ColumnTypes, Theme, ResponseStatus } from 'ui/Helpers/enums.ts';
import {
  getGrupoLinhaPaginated,
  deleteAllGrupoLinha,
} from 'core/services/TEL/grupoLinha';

export default function GrupoLinha({
  transaction,
  onOpenPage,
  isActive,
}: Page) {
  const gridView = useRef<any>();

  const [filtros, setFiltros] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const columns: Array<GridviewColumns> = [
    {
      key: 'nrSeqGrupoLinha',
      type: ColumnTypes.Checkbox,
    },
    { key: 'noDescricao', title: 'Descrição' },
    { key: 'noObservacoes', title: 'Observação' },
  ];

  const search = async (): Promise<void> => {
    if (!loading) {
      setLoading(true);

      const { data: grupoLinhas, pagination } = await getGrupoLinhaPaginated(
        filtros
      );

      if (gridView && gridView.current)
        gridView.current.setDataSource(grupoLinhas, pagination);

      setLoading(false);
    }
  };

  const onColumnSort = async (SortBy: number): Promise<void> => {
    setFiltros({ ...filtros, SortBy });
    await search();
  };

  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    setFiltros({ ...filtros, totalByPage });
    await search();
  };

  const onPageChange = async (page: any): Promise<void> => {
    setFiltros({ ...filtros, page });
    await search();
  };

  const onOpenMaintenance = (registryKey = null): void => {
    onOpenPage(
      transaction.noTransacao,
      'GrupoLinha/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow: any): void => {
    const nrSeqGrupoLinha = sourceRow.find(
      (e: any) => e.key === 'nrSeqGrupoLinha'
    );

    onOpenMaintenance(nrSeqGrupoLinha.value);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllGrupoLinha(
        selecteds.map((row: any) => row[0].value)
      );
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      await search();
    } else {
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Grupo de Linhas'
      loading={loading}
      onSearch={search}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <Textbox
            label='Descrição'
            text={filtros.noDescricao}
            maxLength={4000}
            onChangedValue={(noDescricao: string) =>
              setFiltros({ ...filtros, noDescricao })
            }
          />
        </div>
        <div className='col'>
          {/* @ts-expect-error */}
          <Textbox
            label='Observações'
            text={filtros.noObservacoes}
            maxLength={4000}
            onChangedValue={(noObservacoes: string) =>
              setFiltros({ ...filtros, noObservacoes })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            onColumnSort={onColumnSort}
            enableExcelExport
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
