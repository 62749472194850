import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { confirmPrint } from 'core/services/FIN/cheque';
import ModeloCheque from './modeloCheque';

const Cheque = React.forwardRef((props, ref) => {
  const { model } = props;
  const componentRef = useRef();
  return (
    <div ref={ref}>
      <div className='row mb-4'>
        <div className='col-12'>
          <ModeloCheque ref={componentRef} model={model} />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <ReactToPrint
            onBeforePrint={() => {
              confirmPrint(model);
            }}
            trigger={() => (
              <button type='button' className='botaoImprimir'>
                Imprimir Cheque
              </button>
            )}
            content={() => componentRef.current}
          />
        </div>
      </div>
    </div>
  );
});

export default Cheque;
