import React, { useState } from 'react';
import {
  Autocomplete,
  Button,
  Loader,
  Modal,
  Notification,
  Switch,
  WhatsApp,
} from 'ui/components';
import { Theme, ResponseStatus, BootstrapSizes } from 'ui/Helpers/utils';
import { getWhatsAppContaAutoComplete, sendFaturas } from 'core/services/TEL';
import { salvarOcorrencias } from 'core/services/FIN';

export default function ModalEnviarFaturasPorWhats({
  recursos,
  show,
  onClose,
  onSave,
}) {
  const [data, setData] = useState({});
  const [messageState, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onAutoCompleteMensagem = async (noTituloEmail) => {
    const { status, message, emails } = await getWhatsAppContaAutoComplete({
      noTituloEmail,
    });

    if (status !== ResponseStatus.Success)
      setMessage({
        message,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return emails;
  };

  const onSend = async () => {
    setLoading(true);
    const param = {
      ...data,
      FlgEnvioPadraoWhatsApp: true,
      nrSeqContaRecursos: recursos?.map((p) => p.nrSeqContaRecurso),
    };

    const ocorrencia = [];
    ocorrencia.push({
      dtCadastro: new Date(),
      noOcorrencia: `(WhatsApp) - ${data?.emailTitulo?.msgEmailTitulo}`,
      nrSeqSTituloReceber: recursos?.map((p) => p.nrSeqTituloReceber),
    });

    if (data.flgAnexarFaturaEBoleto)
      param.noFormatoEnvioWhatsapp = 'whatsapp_mensagem_conta_boleto';

    const { status, message } = await sendFaturas(param);

    setLoading(false);

    if (status === ResponseStatus.Success) {
      await salvarOcorrencias(ocorrencia);
      onSave(status, message);
      onClose();
    } else {
      const warningOrDanger =
        status === ResponseStatus.Warning ? Theme.Warning : Theme.Danger;
      setMessage({
        message,
        theme:
          status === ResponseStatus.Success ? Theme.Success : warningOrDanger,
      });
    }
  };

  return (
    <Modal
      show={show}
      title='Enviar Whatsapp para as Linhas Selecionadas'
      onClose={onClose}
      size={BootstrapSizes.Large}
      icon={['fab', 'whatsapp']}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col'>
            <WhatsApp />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <Autocomplete
              label='Mensagem a ser enviada'
              required
              searchDataSource={onAutoCompleteMensagem}
              selectedItem={data.emailTitulo}
              onSelectItem={(emailTitulo) => {
                setData({
                  ...data,
                  emailTitulo,
                  nrSeqMensagemWhatsApp: emailTitulo.nrSeqEmailTitulo,
                });
              }}
              dataSourceTextProperty='noTituloEmail'
            />
          </div>
          <div className='col-auto'>
            <Switch
              formControl
              label='Anexar Fatura e Boleto'
              onChange={(flgAnexarFaturaEBoleto) =>
                setData({
                  ...data,
                  flgAnexarFaturaEBoleto,
                })
              }
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <div className='alert alert-dark'>
              <h4 className='alert-heading'>Mensagem</h4>
              <p>{data?.emailTitulo?.msgEmailTitulo}</p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            {recursos?.map((recurso) => (
              <div
                key={recurso.nrSeqContaRecurso}
                className='badge bg-secondary me-2 p-2 fs-6 mb-2'
              >
                {recurso.numeroCompleto}
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Enviar Whatsapp'
          theme={Theme.Success}
          icon={['fab', 'whatsapp']}
          onClick={onSend}
        />
        <Button
          text='Cancelar'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            setData({});
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
