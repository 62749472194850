import React, { useEffect, useState } from 'react';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  Autocomplete,
  Button,
  Modal,
  Notification,
  Textbox,
  Textarea,
  DatePicker,
} from 'ui/components';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';

function newDateFormat() {
  const newDate = new Date();
  const day = newDate.getDate().toString().padStart(2, '0');
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const year = newDate.getFullYear();
  return `${year}-${month}-${day}T00:00:00.0`;
}

export default function ModalGerarLancamentoValeMotorista({
  show,
  onClose,
  data,
}) {
  const [message, setMessage] = useState(null);
  const [acertoMotorista, setAcertoMotorista] = useState({});

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  useEffect(async () => {
    if (show) {
      const { formasPagamento } = await getFormaPagamentoAutoComplete({
        flgBuscaSimples: true,
        flgVinculaFornecedor: true,
      });

      let formaSelecionado = null;
      if (formasPagamento.length > 0) {
        const itemFound = formasPagamento.find(
          (item) => item.noFormaPagamento === 'Nutricash'
        );

        if (itemFound) formaSelecionado = itemFound;
      }

      setAcertoMotorista({
        ...data,
        vlrAdiantamento: data.vlrAbastecimento,
        dtVale: newDateFormat(),
        formaPagamento: formaSelecionado,
        nrSeqFormaPagamento:
          formaSelecionado !== null
            ? formaSelecionado.nrSeqFormaPagamento
            : null,
      });
    }
  }, [show]);

  const onClickGerarLancamentoValeMotorista = async () => {
    const vlrVale = acertoMotorista.vlrAdiantamento
      ? acertoMotorista.vlrAdiantamento
      : data.vlrAbastecimento * 0.25;

    if (vlrVale > data.vlrAbastecimento) {
      return onSetMessage(
        ResponseStatus.Warning,
        'O valor do vale não pode ser maior que o valor do abastecimento'
      );
    }

    const objAcerto = {
      pessoaMot: data.motorista,
      nrSeqPessoaMot: data.motorista.nrSeqPessoaMot,
      dtAdiantamento: acertoMotorista.dtVale,
      vlrAdiantamento: vlrVale,
      obsAdiantamento: acertoMotorista.obsVale,
      formaPagamento: acertoMotorista.formaPagamento,
      nrSeqFormaPagamento: acertoMotorista.nrSeqFormaPagamento,
      status: 'Inserir',
    };

    return onClose(objAcerto);
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgBuscaSimples: true,
      flgVinculaFornecedor: true,
    });

    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  return (
    <Modal
      show={show}
      title='Lançar Vale Motorista'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-2'>
          {/* Motorista */}
          <div className='col'>
            <Textbox
              label='Motorista'
              readOnly
              text={acertoMotorista?.motorista?.noPessoa}
            />
          </div>
          {/* Data */}
          <div className='col-3'>
            <DatePicker
              label='Data'
              maxLength={10}
              text={acertoMotorista?.dtVale}
              mask={MaskTypes.Date}
              onChange={(dtVale) => {
                setAcertoMotorista({ ...acertoMotorista, dtVale });
              }}
            />
          </div>
        </div>
        <div className='row mb-2'>
          {/* Valor */}
          <div className='col'>
            <Textbox
              label='Valor'
              mask={MaskTypes.Decimal}
              text={acertoMotorista?.vlrAbastecimento}
              onChangedValue={(vlrAdiantamento) => {
                setAcertoMotorista({ ...acertoMotorista, vlrAdiantamento });
              }}
            />
          </div>
          {/* Forma de Pagamento */}
          <div className='col-4'>
            <Autocomplete
              label='Forma de Pagamento'
              searchDataSource={onSearchFormaPagamento}
              selectedItem={acertoMotorista?.formaPagamento}
              onSelectItem={(formaPagamento) => {
                setAcertoMotorista({
                  ...acertoMotorista,
                  formaPagamento,
                  nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                });
              }}
              dataSourceTextProperty='noFormaPagamento'
            />
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col mb-3'>
            <Textarea
              label='Observações'
              maxLength={1000}
              text={acertoMotorista?.obsVale}
              onChangedValue={(obsVale) =>
                setAcertoMotorista({ ...acertoMotorista, obsVale })
              }
            />
          </div>
        </div>
        <div className='row justify-content-end mb-2'>
          <div className='col-2'>
            <Button
              outline
              theme={Theme.Success}
              text='Gerar Lançamento'
              icon='fa-solid fa-cloud-check'
              onClick={onClickGerarLancamentoValeMotorista}
            />
          </div>
          <div className='col-2'>
            <Button
              outline
              text='Cancelar'
              theme={Theme.Danger}
              icon='fas fa-times'
              onClick={() => {
                onClose();
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
