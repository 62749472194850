import React, { useEffect, useRef, useState } from 'react';
import { ResponseStatus, Theme, BootstrapSizes } from 'ui/Helpers/utils';
import {
  getGeracaoEtiquetasPagined,
  gerarEtiquetas,
} from 'core/services/ASS/associado';
import { getCidadeAutoComplete } from 'core/services/SEG/cidade';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
import { getPessoaAutoCompleteRemDesCli } from 'core/services/SEG/pessoa';

import {
  CSDSelPage,
  GridView,
  Panel,
  Textbox,
  Autocomplete,
  RadioButton,
  Button,
  DropdownMulti,
} from 'ui/components';

const statusAtividade = [
  { text: 'Nome', value: 'N' },
  { text: 'Por Cidade', value: 'P' },
  { text: 'CEP', value: 'C' },
];

const statusEndereco = [
  { text: 'Somente Com Endereço', value: 'C' },
  { text: 'Somente Sem Endereço', value: 'S' },
];

const columns = [
  { key: 'nrSeqPessoa', type: GridView.ColumnTypes.Checkbox },
  { key: 'noPessoa', title: 'Nome' },
];

export default function GeracaoEtiquetas({
  transaction,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({ filtroAtividade: 'N' });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});
  const dropDownNome = useRef();
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    const {
      status,
      message: msg,
      data,
      pagination,
    } = await getGeracaoEtiquetasPagined(params ?? filtros);

    if (data && gridView && gridView.current) {
      gridView.current.setDataSource(data, pagination);
    }

    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    setLoading(false);
  };

  const onSearchPessoaAss = async (e) => {
    const {
      status,
      message: msg,
      data: pessoasCli,
    } = await getPessoaAutoCompleteRemDesCli({
      noPessoa: e,
      noPessoaTipo: 'ASS',
    });
    if (msg) onSetMessage(status, msg);
    return pessoasCli;
  };

  useEffect(() => {
    async function load() {
      setLoading(true);

      dropDownNome.current.loadDataSource(onSearchPessoaAss);
      if (gridView && gridView.current) {
        gridView.current.setDataSource();
      }

      setLoading(false);
    }
    load();
  }, []);

  const onSearchCidade = async (e) => {
    const { status, message: msg, cidades } = await getCidadeAutoComplete({
      noCidade: e,
      nrSeqEstado: filtros?.nrSeqEstado ?? null,
    });
    onSetMessage(status, msg);
    return cidades;
  };

  const onSearchEstado = async (e) => {
    const { status, message: msg, estados } = await getEstadoAutoComplete({
      noEstado: e,
    });
    onSetMessage(status, msg);
    return estados;
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onGerarEtiquetas = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { value } = await gerarEtiquetas(
        selecteds.map((row) => row[0].value)
      );

      onOpenReport(transaction.noTransacao, value);
      setLoading(false);
    } else {
      onSetMessage(
        ResponseStatus.Error,
        'Informar no mínimo um associado para geração da etiqueta.'
      );
    }

    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Geração de Etiquetas'
      loading={loading}
      onSearch={() => search()}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Filtros' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3 align-items-end'>
              <div className='col-3'>
                <Autocomplete
                  label='Estado'
                  searchDataSource={onSearchEstado}
                  selectedItem={filtros.estado}
                  onSelectItem={(estado) =>
                    setFiltros({
                      ...filtros,
                      estado,
                      nrSeqEstado: estado.nrSeqEstado,
                    })
                  }
                  dataSourceTextProperty='noEstado'
                />
              </div>
              <div className='col-3'>
                <Autocomplete
                  label='Cidade'
                  searchDataSource={onSearchCidade}
                  selectedItem={filtros.cidade}
                  onSelectItem={(cidade) =>
                    setFiltros({
                      ...filtros,
                      cidade,
                      nrSeqCidade: cidade.nrSeqCidade,
                    })
                  }
                  dataSourceTextProperty='noCidade'
                />
              </div>
              <div className='col-auto'>
                <RadioButton
                  label='Ordenar'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.filtroAtividade}
                  buttons={statusAtividade}
                  onChange={(filtroAtividade) =>
                    setFiltros({
                      ...filtros,
                      filtroAtividade,
                    })
                  }
                />
              </div>
            </div>
            <div className='row mb-3 align-items-end'>
              <div className='col-4'>
                <DropdownMulti
                  label='Nome'
                  ref={dropDownNome}
                  dataSourcePropertyText='noPessoa'
                  dataSourcePropertyValue='nrSeqPessoa'
                  selectedItems={selectedItems.pessoa ?? []}
                  onSelectItem={(pessoa) => {
                    setSelectedItems({ ...selectedItems, pessoa });
                    setFiltros({
                      ...filtros,
                      nrSeqPessoaS: pessoa?.map((p) => p.nrSeqPessoa),
                    });
                  }}
                />
              </div>
              <div className='col-auto'>
                <RadioButton
                  label='Somente'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.filtroEndereco || 'P'}
                  buttons={statusEndereco}
                  onChange={(filtroEndereco) =>
                    setFiltros({
                      ...filtros,
                      filtroEndereco,
                    })
                  }
                />
              </div>
            </div>
            <div className='row mb-3 align-items-end'>
              <div className='col-4'>
                <Textbox
                  maxLength={2}
                  label='Mês do aniversário'
                  text={filtros.noMesAniversario}
                  onChangedValue={(noMesAniversario) => {
                    setFiltros({
                      ...filtros,
                      noMesAniversario,
                    });
                  }}
                />
              </div>
              <div className='col-2 mt-3'>
                <Button
                  text='Gerar'
                  theme={Theme.Success}
                  icon='print'
                  onClick={() => onGerarEtiquetas()}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
