import React, { useState } from 'react';

import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  Modal,
  Notification,
  Switch,
  Textbox,
} from 'ui/components';

import { getPocOrcamentoTipoDocumentoAutoComplete } from 'core/services/POC/pocOrcamentoTipoDocumento';

export default function ModalAlterarObservacoes({
  documento,
  show,
  onClose,
  onChange,
  isOrcEne,
}) {
  const [message, setMessage] = useState(null);

  const onSearchTipoDocumento = async (e) => {
    const { data: tipos } = await getPocOrcamentoTipoDocumentoAutoComplete({
      noPocOrcamentoTipoDocumento: e,
    });

    return tipos;
  };

  const onSend = async () => {
    onChange(documento);
  };

  return (
    <Modal
      show={show}
      title='Alterar Informações Documento'
      onClose={onClose}
      size={BootstrapSizes.Large}
      icon={['far', 'comment-alt']}
    >
      <Modal.Body className='p-4 pt-3'>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col-6'>
            <Autocomplete
              label='Tipo Documento'
              searchDataSource={onSearchTipoDocumento}
              selectedItem={documento.tipoDocumento}
              onSelectItem={(tipoDocumento) => {
                documento.tipoDocumento = tipoDocumento;
                documento.nrSeqPocOrcamentoTipoDocumento =
                  tipoDocumento.nrSeqPocOrcamentoTipoDocumento;
              }}
              dataSourceTextProperty='noPocOrcamentoTipoDocumento'
            />
          </div>
          {isOrcEne && (
            <div className='col-6'>
              <Switch
                label='Documento Assinado'
                formControl
                checked={documento.flgAssinadoClickSign}
                onChange={(checked) => {
                  documento.flgAssinadoClickSign = checked;
                }}
              />
            </div>
          )}
        </div>
        <div className='col'>
          <Textbox
            maxLength={400}
            label='Observações'
            text={documento.noObservacoes}
            onChangedValue={(noObservacoes) => {
              documento.noObservacoes = noObservacoes;
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Salvar'
          theme={Theme.Success}
          icon='sms'
          onClick={() => {
            onSend();
            onClose();
          }}
        />
        <Button
          text='Sair'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
