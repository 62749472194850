export default class TipoMovimento {
  constructor(jsonObject = {}) {
    this.nrSeqTipoMovimento = jsonObject.nrSeqTipoMovimento;
    this.cdTipoMovimento = jsonObject.cdTipoMovimento;
    this.noTipoMovimento = jsonObject.noTipoMovimento;
    this.noFormatoEnvio = jsonObject.noFormatoEnvio;
    this.flgAtivo = jsonObject.flgAtivo;
    this.nrSeqBanco = jsonObject.nrSeqBanco;
  }

  toJson = () => JSON.stringify(this);
}
