import React, { useEffect } from 'react';
import EnergiaConta from 'core/models/ENE/energiaConta';
import { GridView, Modal } from 'ui/components';
import { GridviewColumns } from 'ui/Helpers/interfaces';
import { ColumnDataTypes } from 'ui/Helpers/enums';

interface IProps {
  conta: EnergiaConta | undefined;
  show: boolean;
  gridView: any;
  onClose: () => void;
}

export const ModalEncargosFatura = ({
  conta,
  gridView,
  onClose,
  show,
}: IProps) => {
  useEffect(() => {
    if (gridView && gridView.current && conta !== undefined) {
      gridView.current.setDataSource(conta.encargos);
    }
  }, [show]);

  const columnsEncargos: GridviewColumns[] = [
    {
      key: 'noEnergiaContaEncargo',
      title: 'Descrição',
      sortable: false,
    },
    {
      key: 'unidadeMedidaEncargo',
      title: 'Uni. Med.',
      sortable: false,
    },
    {
      key: 'qtdeFaturada',
      title: 'Qtd. Faturada',
      sortable: false,
    },
    {
      key: 'vlrTarifa',
      title: 'Tarifa ANEEL',
      sortable: false,
    },
    {
      key: 'vlrTarifaTributos',
      title: 'Tarifa com Tributos',
      sortable: false,
    },
    {
      key: 'vlrTotal',
      title: 'Valor',
      sortable: false,
      format: ColumnDataTypes.Money,
    },
    {
      key: 'vlrBaseCalIcms',
      title: 'Base Calc. ICMS',
      sortable: false,
    },
    {
      key: 'vlrAliqIcms',
      title: 'Aliq. ICMS',
      sortable: false,
    },
    {
      key: 'vlrIcms',
      title: 'ICMS',
      sortable: false,
    },
    {
      key: 'vlrPis',
      title: 'PIS',
      sortable: false,
    },
    {
      key: 'vlrCofins',
      title: 'COFINS',
      sortable: false,
    },
  ];

  return (
    // @ts-expect-error
    <Modal show={show} title='Encargos da fatura' onClose={onClose} size='lg'>
      {/* @ts-expect-error */}
      <Modal.Body>
        <GridView
          ref={gridView}
          // @ts-expect-error
          dataColumns={columnsEncargos}
          showSelectSizes={false}
          showPagination={false}
        />
      </Modal.Body>
    </Modal>
  );
};
