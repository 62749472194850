import qs from 'qs';
import Pagination from 'core/models/pagination';
import EnergiaUnidadeConsumidora from 'core/models/ENE/energiaUnidadeConsumidora';
import api from '../api';

const baseURL = `/ENE/EnergiaUnidadeConsumidora`;

export const getEnergiaUnidadeConsumidoraList = async (filters: any) => {
  const response = await api.get(`/$EnergiaUnidadeConsumidora/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new EnergiaUnidadeConsumidora(json)) ?? [],
  };
};

export const getEnergiaUnidadeConsumidoraPagined = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new EnergiaUnidadeConsumidora(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getEnergiaUnidadeConsumidora = async (id: number) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new EnergiaUnidadeConsumidora(value) : {};
};

export const getEnergiaUnidadeConsumidoraAutoComplete = async (
  filters: any
) => {
  const response = await api.get(`${baseURL}/SearchAutoComplete`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    ucs: value?.map((json: any) => new EnergiaUnidadeConsumidora(json)) ?? [],
  };
};

export const saveEnergiaUnidadeConsumidora = async (
  data: EnergiaUnidadeConsumidora | {}
) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteEnergiaUnidadeConsumidora = async (id: number) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printEnergiaUnidadeConsumidora = async (filters: any) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelEnergiaUnidadeConsumidora = async (filters: any) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllEnergiaUnidadeConsumidora = async (ids: number[]) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
