import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  Switch,
  Textarea,
  Panel,
  GridView,
  Button,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getTipoDemanda,
  saveTipoDemanda,
  deleteTipoDemanda,
  getTipoDemandaAutoComplete,
} from 'core/services/HELP/tipoDemanda';
import { getEncargoManualAutoComplete } from 'core/services/TEL/encargoManual';
import { getGrupoTipoChamadaAutoComplete } from 'core/services/TEL/grupoTipoChamada';
import { getUsuarioAutoComplete } from 'core/services/SEG/usuario';
import { getDocumentoConfiguracaoAutoComplete } from 'core/services/SEG/documentoConfiguracao';
import TipoDemanda from 'core/models/HELP/tipoDemanda';
import { getPerfilAutoComplete } from 'core/services/SEG/perfil';
import { getFormaPagamentoPdvAutoComplete } from 'core/services/FIN';

export default function TipoDemandaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const gridViewPerfis = useRef();
  const [data, setData] = useState(new TipoDemanda({}));
  const [dataPerfil, setDataPerfil] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [visible, setVisible] = useState(true);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onNew = () => {
    setData({
      flgAtivo: true,
      noCorpoMensagem: '',
    });

    gridViewPerfis?.current?.setDataSource([]);
  };

  const onSetMureg = (mureg) => {
    let obj = {};

    if (mureg) {
      setVisible(false);
      obj = {
        ...data,
        flgPortabilidade: false,
        flgNrLinhaNova: false,
        flgExibePacote: false,
        flgExibeChip: false,
        flgCancelaClienteLinha: false,
        flgEfetivaAutomaticamente: false,
        flgExibePacoteSMS: false,
        flgDevolveAparelhoEstoque: false,
        flgTrocaTitularidade: false,
      };
    } else {
      setVisible(true);
      obj = { ...data };
    }

    setData({
      ...obj,
      flgMureg: mureg,
    });
  };

  const load = useCallback(async () => {
    setLoading(true);

    if (Number.isInteger(registryKey)) {
      const { status, message: msg, value: tipoDemanda } = await getTipoDemanda(
        registryKey
      );

      if (status === ResponseStatus.Success) {
        const perfisAutorizados = tipoDemanda.perfisAutorizados ?? [];

        gridViewPerfis?.current?.setDataSource(perfisAutorizados);

        setData(tipoDemanda);
      } else {
        onSetMessage(status, msg);
      }
    } else {
      onNew();
    }

    setLoading(false);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);

    const perfisAutorizados = gridViewPerfis?.current?.getDataSource() ?? [];

    data.perfisAutorizados = perfisAutorizados;

    const { status, message: msg } = await saveTipoDemanda(data);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      onNew();
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteTipoDemanda(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchDocumentoConfiguracao = async (e) => {
    const {
      status,
      message: msg,
      data: documentos,
    } = await getDocumentoConfiguracaoAutoComplete({
      cdDocumentoConfiguracao: e,
    });
    if (msg) onSetMessage(status, msg);
    return documentos;
  };

  const onSearchTipoDemandaPai = async (e) => {
    const {
      status,
      message: msg,
      tiposDemanda: tipoDemandaPais,
    } = await getTipoDemandaAutoComplete({ noTipoDemanda: e });
    if (msg) onSetMessage(status, msg);
    return tipoDemandaPais;
  };

  const onSearchUsuarioAtendimento = async (e) => {
    const {
      status,
      message: msg,
      data: usuarios,
    } = await getUsuarioAutoComplete({ noLogin: e });
    if (msg) onSetMessage(status, msg);
    return usuarios;
  };

  const onSearchEncargoManual = async (e) => {
    const {
      status,
      message: msg,
      encargos: encargoManuals,
    } = await getEncargoManualAutoComplete({ noEncargoManual: e });
    if (msg) onSetMessage(status, msg);
    return encargoManuals;
  };

  const onSearchGrupoTipoChamada = async (e) => {
    const {
      status,
      message: msg,
      grupos: grupoTipoChamadas,
    } = await getGrupoTipoChamadaAutoComplete({ noGrupoTipoChamada: e });
    if (msg) onSetMessage(status, msg);
    return grupoTipoChamadas;
  };

  const onSearchPerfil = async (e) => {
    const { perfis } = await getPerfilAutoComplete({ noPerfil: e });

    return perfis;
  };

  const onAutoCompleteFormaPagamento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoPdvAutoComplete({
      noFormaPagamento: e,
    });

    return formasPagamento;
  };

  const onRemoverPerfil = (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const list = datasource.filter((el) => el !== e);

    if (gridItem.status !== GridView.EnumStatus.Inserir) {
      gridItem.status = GridView.EnumStatus.Remover;

      list.push(gridItem);
    }

    gridViewPerfis?.current?.setDataSource(list);
  };

  const columnPerfis = [
    { key: 'noPerfil', title: 'Perfil' },
    {
      key: 'nrSeqPerfil',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoverPerfil(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onAddPerfil = () => {
    if (!dataPerfil.nrSeqPerfil) {
      onSetMessage(ResponseStatus.Error, 'Selecione o Perfil');

      return;
    }

    const dataSource = gridViewPerfis?.current?.getDataSource() ?? [];

    const index = dataSource.findIndex(
      (e) => e.nrSeqPerfil === dataPerfil.nrSeqPerfil
    );

    if (index >= 0) {
      if (dataSource[index].status === GridView.EnumStatus.Remover) {
        dataSource[index].status = '';

        gridViewPerfis.current.setDataSource(dataSource);
      } else {
        onSetMessage(ResponseStatus.Error, 'Perfil já foi adicionado');
      }
    } else {
      const obj = { ...dataPerfil, status: GridView.EnumStatus.Inserir };

      gridViewPerfis.current.setDataSource([...dataSource, obj]);
    }

    setDataPerfil({});
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Tipo de Demanda'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      onDelete={data?.nrSeqTipoDemanda > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-4'>
          <Textbox
            maxLength={50}
            label='Tipo Demanda'
            text={data.noTipoDemanda}
            onChangedValue={(noTipoDemanda) =>
              setData({ ...data, noTipoDemanda })
            }
          />
        </div>
        <div className='col-4'>
          <Textbox
            maxLength={50}
            label='Código'
            text={data.cdTipoDemanda}
            onChangedValue={(cdTipoDemanda) =>
              setData({ ...data, cdTipoDemanda })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Ativo'
            checked={data.flgAtivo}
            onChange={(flgAtivo) => setData({ ...data, flgAtivo })}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Versão Old'
            checked={data.flgVersaoOld}
            onChange={(flgVersaoOld) => setData({ ...data, flgVersaoOld })}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Bloq. Inadimplente'
            checked={data.flgBloqueioInadimplente}
            onChange={(flgBloqueioInadimplente) =>
              setData({ ...data, flgBloqueioInadimplente })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Upload Arq.'
            checked={data.flgUploadArquivo}
            onChange={(flgUploadArquivo) =>
              setData({ ...data, flgUploadArquivo })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Obriga Upload'
            checked={data.flgObrigaUploadEfetivacao}
            onChange={(flgObrigaUploadEfetivacao) =>
              setData({ ...data, flgObrigaUploadEfetivacao })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Gera TT'
            checked={data.flgGeraTT}
            onChange={(flgGeraTT) => setData({ ...data, flgGeraTT })}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Sincroniza Celebre'
            checked={data.flgSincronizaCelebre}
            onChange={(flgSincronizaCelebre) =>
              setData({ ...data, flgSincronizaCelebre })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            visible={visible}
            label='Pacote Dados'
            checked={data.flgExibePacote}
            onChange={(flgExibePacote) => setData({ ...data, flgExibePacote })}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Exibe Chip'
            visible={visible}
            checked={data.flgExibeChip}
            onChange={(flgExibeChip) => setData({ ...data, flgExibeChip })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Portabilidade'
            checked={data.flgPortabilidade}
            visible={visible}
            onChange={(flgPortabilidade) =>
              setData({
                ...data,
                flgPortabilidade,
              })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            visible={visible}
            label='Linha Nova'
            checked={data.flgNrLinhaNova}
            onChange={(flgNrLinhaNova) => setData({ ...data, flgNrLinhaNova })}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            visible={visible}
            label='Can. ClienteLinha'
            checked={data.flgCancelaClienteLinha}
            onChange={(flgCancelaClienteLinha) =>
              setData({
                ...data,
                flgCancelaClienteLinha,
              })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Efetiva Automat.'
            visible={visible}
            checked={data.flgEfetivaAutomaticamente}
            onChange={(flgEfetivaAutomaticamente) =>
              setData({
                ...data,
                flgEfetivaAutomaticamente,
              })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Dev. Aparelho Est.'
            checked={data.flgDevolveAparelhoEstoque}
            visible={visible}
            onChange={(flgDevolveAparelhoEstoque) =>
              setData({
                ...data,
                flgDevolveAparelhoEstoque,
              })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Gera Massivo'
            checked={data.flgGeraMassivo}
            onChange={(flgGeraMassivo) => setData({ ...data, flgGeraMassivo })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Mureg'
            checked={data.flgMureg}
            onChange={(flgMureg) => {
              // onMureg(flgMureg);
              onSetMureg(flgMureg);
            }}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Troca Aparelho'
            checked={data.flgTrocaAparelho}
            onChange={(flgTrocaAparelho) =>
              setData({ ...data, flgTrocaAparelho })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Troca Número'
            checked={data.flgTrocaNumero}
            onChange={(flgTrocaNumero) => setData({ ...data, flgTrocaNumero })}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Encargo Manual'
            checked={data.flgEncargoManual}
            onChange={(flgEncargoManual) =>
              setData({ ...data, flgEncargoManual })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Convênio'
            checked={data.flgConvenio}
            onChange={(flgConvenio) => setData({ ...data, flgConvenio })}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Efetiva no Destino'
            checked={data.flgExecutaEfetivacaoDestino}
            onChange={(flgExecutaEfetivacaoDestino) =>
              setData({ ...data, flgExecutaEfetivacaoDestino })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Gerar Enc. Manual do Pacote'
            checked={data.flgPacoteEncargoManual}
            onChange={(flgPacoteEncargoManual) =>
              setData({ ...data, flgPacoteEncargoManual })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Permite Protocolo'
            checked={data.flgProtocolo}
            onChange={(flgProtocolo) => setData({ ...data, flgProtocolo })}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Pacote SMS'
            checked={data.flgExibePacoteSMS}
            visible={visible}
            onChange={(flgExibePacoteSMS) =>
              setData({
                ...data,
                flgExibePacoteSMS,
              })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Troca Titularidade'
            checked={data.flgTrocaTitularidade}
            visible={visible}
            onChange={(flgTrocaTitularidade) =>
              setData({
                ...data,
                flgTrocaTitularidade,
              })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Exibe Centro Custo'
            checked={data.flgExibeCentroCusto}
            onChange={(flgExibeCentroCusto) =>
              setData({ ...data, flgExibeCentroCusto })
            }
          />
        </div>

        <div className='col mb-3'>
          <Switch
            formControl
            label='Reverte Portabilidade'
            checked={data.flgRevertePortabilidade}
            onChange={(flgRevertePortabilidade) =>
              setData({ ...data, flgRevertePortabilidade })
            }
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Encargo Manual'
            readOnly={!visible}
            searchDataSource={onSearchEncargoManual}
            selectedItem={data.encargoManual}
            onSelectItem={(encargoManual) => {
              setData({
                ...data,
                encargoManual,
                nrSeqEncargoManual: encargoManual.nrSeqEncargoManual,
              });
            }}
            dataSourceTextProperty='noEncargoManual'
          />
        </div>
        <div className='col-3'>
          <Textbox
            label='Qtd Meses Renovação'
            text={data.qtdMesesRenovacao}
            mask={MaskTypes.Integer}
            onChangedValue={(qtdMesesRenovacao) =>
              setData({ ...data, qtdMesesRenovacao })
            }
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Perfil Autorizado'
            tooltip='O perfil selecionado sera o único perfil com acesso a essa demanda'
            placeholder='Ao selecionar um perfil o acesso a esse demanda sera restrito a ele'
            searchDataSource={onSearchPerfil}
            selectedItem={data.perfil}
            onSelectItem={(perfil) => {
              setData({
                ...data,
                perfil,
                nrSeqPerfil: perfil.nrSeqPerfil,
              });
            }}
            dataSourceTextProperty='noPerfil'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Panel>
            <Panel.Header
              theme={Theme.Primary}
              title='Perfis Autorizados à Efetivar'
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col'>
                  <Autocomplete
                    label='Perfil'
                    tooltip='O perfil selecionado terá autorizacao para Efetivar demandas desse tipo demanda'
                    searchDataSource={onSearchPerfil}
                    selectedItem={dataPerfil}
                    onSelectItem={(perfil) => {
                      setDataPerfil(perfil);
                    }}
                    dataSourceTextProperty='noPerfil'
                  />
                </div>
                <div className='col d-flex align-items-end'>
                  <Button
                    text='Adicionar Perfil'
                    theme={Theme.Success}
                    icon='plus'
                    onClick={onAddPerfil}
                    template={Button.Templates.Default}
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <GridView
                    ref={gridViewPerfis}
                    dataColumns={columnPerfis}
                    showSelectSizes={false}
                    showPagination={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Textbox
            maxLength={80}
            label='NoPágina'
            text={data.noPagina}
            onChangedValue={(noPagina) => setData({ ...data, noPagina })}
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Documento Configuração'
            searchDataSource={onSearchDocumentoConfiguracao}
            selectedItem={data.documento}
            onSelectItem={(documento) => {
              setData({
                ...data,
                documento,
                cdDocumentoConfiguracao: documento.cdDocumentoConfiguracao,
              });
            }}
            dataSourceTextProperty='cdDocumentoConfiguracao'
          />
        </div>

        <div className='col'>
          <Autocomplete
            label='Usuario Atendimento'
            searchDataSource={onSearchUsuarioAtendimento}
            selectedItem={data.usuario}
            onSelectItem={(usuario) => {
              setData({
                ...data,
                usuario,
                nrSeqUsuarioAtendimento: usuario.nrSeqUsuario,
              });
            }}
            dataSourceTextProperty='noLogin'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Grupo Tipo Chamada'
            searchDataSource={onSearchGrupoTipoChamada}
            selectedItem={data.grupoTipoChamada}
            readOnly={!visible}
            onSelectItem={(grupoTipoChamada) => {
              setData({
                ...data,
                grupoTipoChamada,
                nrSeqGrupoTipoChamada: grupoTipoChamada.nrSeqGrupoTipoChamada,
              });
            }}
            dataSourceTextProperty='noGrupoTipoChamada'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Demanda Pai'
            searchDataSource={onSearchTipoDemandaPai}
            selectedItem={data.tipoDemandaPai}
            readOnly={!visible}
            onSelectItem={(tipoDemandaPai) => {
              setData({
                ...data,
                tipoDemandaPai,
                nrSeqTipoDemandaPai: tipoDemandaPai.nrSeqTipoDemanda,
              });
            }}
            dataSourceTextProperty='noTipoDemanda'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Forma de Pagamento'
            searchDataSource={onAutoCompleteFormaPagamento}
            selectedItem={data.formaPagamento}
            onSelectItem={(formaPagamento) => {
              setData({
                ...data,
                formaPagamento,
                nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
              });
            }}
            dataSourceTextProperty='noFormaPagamento'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Textbox
            maxLength={100}
            label='Título Email'
            text={data.noTituloMensagem}
            onChangedValue={(noTituloMensagem) =>
              setData({ ...data, noTituloMensagem })
            }
          />
        </div>
        <div className='col-8'>
          <Textbox
            maxLength={300}
            label='Mensagem Ajuda'
            text={data.noAjuda}
            onChangedValue={(noAjuda) => setData({ ...data, noAjuda })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={200}
            label='Emails para Atualização de Status'
            text={data.noEmailAlertaStatus}
            onChangedValue={(noEmailAlertaStatus) =>
              setData({ ...data, noEmailAlertaStatus })
            }
          />
        </div>
      </div>
      <div className='col mb-3'>
        <Textarea
          maxLength={-1}
          rows={3}
          label='Corpo do Email Operadora'
          text={data.noCorpoMensagem}
          onChangedValue={(noCorpoMensagem) =>
            setData({ ...data, noCorpoMensagem })
          }
        />
      </div>
    </CSDManutPage>
  );
}
