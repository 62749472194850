export default class TipoAssociado {
  constructor(jsonObject = {}) {
    this.nrSeqTipoAssociado = jsonObject.nrSeqTipoAssociado;
    this.noTipoAssociado = jsonObject.noTipoAssociado;
    this.vlrMensalidade = jsonObject.vlrMensalidade;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
