import React, { useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getPagamentoMassivoPagined,
  deleteAllPagamentoMassivo,
} from 'core/services/FIN/pagamentoMassivo';
import {
  Autocomplete,
  CSDSelPage,
  DatePicker,
  GridView,
  Panel,
  Textbox,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getEmpresaAutoComplete } from 'core/services/SEG';

const columns = [
  { key: 'nrSeqPagamentoMassivo', type: GridView.ColumnTypes.Checkbox },
  { key: 'nrIdentificado', title: 'Nr. Identificado' },
  { key: 'empresa.noPessoa', title: 'Empresa' },
  {
    key: 'dtCad',
    title: 'Cadastro',
    format: GridView.DataTypes.Date,
  },
  {
    key: 'dtPagamento',
    title: 'Dt Pagamento',
    format: GridView.DataTypes.Date,
  },
  { key: 'vlrTotal', title: 'Total', format: GridView.DataTypes.Decimal },
];

export default function PagamentoMassivo({
  transaction,
  onOpenPage,
  isActive,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getPagamentoMassivoPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'PagamentoMassivo/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqPagamentoMassivo = sourceRow.find(
      (e) => e.key === 'nrSeqPagamentoMassivo'
    );
    onOpenMaintenance(nrSeqPagamentoMassivo.value);
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });
    if (msg) setMessage(status, msg);
    return empresas;
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllPagamentoMassivo(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de PagamentoMassivo'
      loading={loading}
      transaction={transaction}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <Panel>
        <div className='row mb-3'>
          <div className='col-12'>
            <Panel.Header
              class='fa-solid fa-eraser'
              title='Filtros'
              theme={Theme.Primary}
            />
          </div>
        </div>
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-4'>
              <Autocomplete
                label='Empresa'
                searchDataSource={onSearchEmpresa}
                selectedItem={filtros.empresa}
                onSelectItem={(empresa) => {
                  setFiltros({
                    ...filtros,
                    empresa,
                    nrSeqEmpresa: empresa.nrSeqEmpresa,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col'>
              <Textbox
                maxLength={20}
                label='Nr Identificado'
                text={filtros.nrIdentificado}
                onChangedValue={(nrIdentificado) =>
                  setFiltros({
                    ...filtros,
                    nrIdentificado,
                  })
                }
              />
            </div>
            <div className='col'>
              <Textbox
                maxLength={20}
                label='Vlr Pago'
                text={filtros.vlrTotal}
                onChangedValue={(vlrTotal) =>
                  setFiltros({
                    ...filtros,
                    vlrTotal,
                  })
                }
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-3'>
              <DatePicker
                label='Pagamento De'
                text={filtros.dtPagamentoDe}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtPagamentoDe) =>
                  setFiltros({ ...filtros, dtPagamentoDe })
                }
              />
            </div>
            <div className='col-3'>
              <DatePicker
                label='Pagamento Até'
                text={filtros.dtPagamentoAt}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtPagamentoAt) =>
                  setFiltros({ ...filtros, dtPagamentoAt })
                }
              />
            </div>
            <div className='col-3'>
              <DatePicker
                label='Cadastro De'
                text={filtros.dtCadDe}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtCadDe) => setFiltros({ ...filtros, dtCadDe })}
              />
            </div>
            <div className='col-3'>
              <DatePicker
                label='Cadastro Até'
                text={filtros.dtCadAt}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtCadAt) => setFiltros({ ...filtros, dtCadAt })}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>

      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
