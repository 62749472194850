import React, { useState, useEffect, useRef } from 'react';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { Button, Loader, Modal, Notification, GridView } from 'ui/components';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';

import { getTituloPagarList } from 'core/services/FIN/tituloPagar';
import { getTituloReceberListForFluxoCaixa } from 'core/services/FIN/tituloReceber';

export default function ModalDetalhamento({
  show,
  onClose,
  loading,
  setLoading,
  data,
  findTransaction,
  onOpenTransaction,
}) {
  const gridView = useRef();
  const gridViewItem = useRef();

  const [message, setMessage] = useState(null);
  const [titleModal, setTitleModal] = useState(null);

  const [subTable, setSubTable] = useState({});
  const gridType = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  useEffect(() => {
    setLoading(true);
    setSubTable({});
    gridView?.current?.setDataSource(data ?? []);
    gridViewItem?.current?.setDataSource([]);
    setLoading(false);
    setTitleModal(data ? data[0] : {});
  }, [data]);

  const columns = [
    {
      key: 'nrSeqExtratoCategoria',
      title: 'nrSeqExtratoCategoria',
      visible: false,
    },
    { key: 'nrSeqCentroDeCusto', title: 'NrSeqCentroDeCusto', visible: false },
    { key: 'nrSeqPlanoDeConta', title: 'nrSeqPlanoDeConta', visible: false },
    {
      key: 'noPlanoDeConta',
      title: 'Plano De Conta',
    },
    { key: 'vlrTotalLista', title: 'Valor', format: GridView.DataTypes.Money },
    { key: 'dataInicial', title: 'dataInicial', visible: false },
    { key: 'dataFinal', title: 'dataFinal', visible: false },
    { key: 'grupoCategoria', title: 'grupoCategoria', visible: false },
  ];

  const onClickVerDespesa = async (datasource) => {
    setLoading(true);
    const transactionToOpen = findTransaction(TransacationCodes.TituloPagar);

    if (transactionToOpen) {
      transactionToOpen.registryKey = datasource.nrSeqTituloPagar;
      setLoading(false);
      onOpenTransaction(transactionToOpen, true);
    } else {
      onSetMessage(
        ResponseStatus.Error,
        `Não foi possível abrir o Titulo a Pagar cód.: ${datasource.cdTituloPagar}.`
      );
    }
  };

  const onClickVerReceita = async (datasource) => {
    setLoading(true);
    const transactionToOpen = findTransaction(TransacationCodes.TituloReceber);

    if (transactionToOpen) {
      transactionToOpen.registryKey = datasource.nrSeqTituloReceber;
      setLoading(false);
      onOpenTransaction(transactionToOpen, true);
    } else {
      onSetMessage(
        ResponseStatus.Error,
        `Não foi possível abrir o Titulo a Receber cód.: ${datasource.cdTituloReceber}.`
      );
    }
  };

  const subTableColumnsDespesa = [
    { key: 'cdTituloPagar', title: 'Cód.' },
    {
      key: 'nrTit',
      title: 'Nr. Tit.',
    },
    { key: 'fornecedor.noFornecedor', title: 'Fornecedor' },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma de Pagamento',
    },
    {
      key: 'vlrPago',
      title: 'Valor Total do Título',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'nrSeqTituloPagar',
      title: 'Ver',
      type: GridView.ColumnTypes.Button,
      onClick: (datasource) => onClickVerDespesa(datasource),
      theme: Theme.Warning,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Ver Despesa',
      tooltipDirection: 'bottom',
    },
  ];

  const subTableColumnsReceita = [
    { key: 'nrSeqTituloReceber', title: 'Cód.' },
    {
      key: 'noFormaPagamento',
      title: 'Forma de Pagamento',
    },
    {
      key: 'vlrRecebido',
      title: 'Valor Total do Título',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'nrSeqTituloReceber',
      title: 'Ver',
      type: GridView.ColumnTypes.Button,
      onClick: (datasource) => onClickVerReceita(datasource),
      theme: Theme.Warning,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Ver Receita',
      tooltipDirection: 'bottom',
    },
  ];

  const onRowDoubleClick = async (sourceRow) => {
    setLoading(true);

    const dataInicial = sourceRow[5].value.slice(0, 10);
    const dataFinal = sourceRow[6].value.slice(0, 10);

    if (sourceRow[7].value === 'DESPESA') {
      const obj = {
        nrSeqTipoDespesa: sourceRow[0].value,
        nrSeqCentroDeCusto: sourceRow[1].value,
        nrSeqPlanoDeConta: sourceRow[2].value,
        pagamentoDe: dataInicial,
        pagamentoAte: dataFinal,
      };

      const response = await getTituloPagarList(obj);

      if (response.status === ResponseStatus.Error) {
        onSetMessage(ResponseStatus.Error, 'Erro ao buscar Títulos a Pagar.');

        return setLoading(false);
      }

      gridType.current = 'despesa';

      if (gridViewItem?.current) {
        gridViewItem.current.setDataSource(response.data);
      }

      setSubTable(response.data);

      return setLoading(false);
    }

    const planoContaObj = {
      nrSeqCentroDeCusto: sourceRow[1].value,
      nrSeqPlanoDeConta: sourceRow[2].value,
    };

    const obj = {
      nrSeqTituloEspecie: sourceRow[0].value,
      planoConta: planoContaObj,
      dtRecebimentoDe: dataInicial,
      dtRecebimentoAte: dataFinal,
    };

    const response = await getTituloReceberListForFluxoCaixa(obj);

    if (response.status === ResponseStatus.Error) {
      onSetMessage(ResponseStatus.Error, 'Erro ao buscar Títulos a Receber.');

      return setLoading(false);
    }

    gridType.current = 'receita';

    if (gridViewItem?.current) {
      gridViewItem.current.setDataSource(response.data);
    }

    setSubTable(response.data);

    return setLoading(false);
  };

  return (
    <Modal
      show={show}
      title={`${titleModal?.grupoCategoria}`}
      onClose={() => {
        onClose();
      }}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body className='p-3 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mt-2'>
          <h6>Centro de Custo: {titleModal?.centroDeCusto}</h6>
        </div>

        {Object.keys(subTable).length > 0 && (
          <div className='row mt-2'>
            <div className='col'>
              <GridView
                ref={gridViewItem}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={
                  gridType.current === 'despesa'
                    ? subTableColumnsDespesa
                    : subTableColumnsReceita
                }
                offlineData
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </div>
        )}

        {data && (
          <div className='row mt-2'>
            <div className='col'>
              <GridView
                ref={gridView}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columns}
                offlineData
                showPagination={false}
                showSelectSizes={false}
                sumFields
                onRowDoubleClick={onRowDoubleClick}
              />
            </div>
          </div>
        )}
        <div className='row'>
          <div className='col-2 mt-4'>
            <Button
              outline
              text='Sair'
              theme={Theme.Danger}
              icon='fas fa-times'
              onClick={() => {
                onClose();
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
