import qs from 'qs';
import Pagination from 'core/models/pagination';
import Pagamento from 'core/models/FIN/pagamento';
import api from '../api';

const baseURL = `/FIN/Pagamento`;

export const getPagamentoList = async (filters) => {
  const response = await api.get(`/$Pagamento/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Pagamento(json)) ?? [],
  };
};

export const getPagamentoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Pagamento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPagamento = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Pagamento(value) : {};
};
export const findPagamento = async (id) => {
  const response = await api.get(`${baseURL}/FindPagamento`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Pagamento(value) : {};
};

export const printPagamentoFornecedor = async (filters) => {
  const response = await api.get(`${baseURL}/PrintFornecedor`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const printPagamentoVencimento = async (filters) => {
  const response = await api.get(`${baseURL}/Vencimento`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const printPagamentos = async (filters) => {
  const response = await api.get(`${baseURL}/PrintPagamento`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const excelPagamentoFornecedor = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelFornecedor`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const excelPagamentoVencimento = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelVencimento`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const excelPagamentos = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelPagamento`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const savePagamento = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePagamento = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPagamento = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPagamento = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPagamento = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const PrintRecibo = async (data) => {
  const response = await api.post(`${baseURL}/PrintRecibo`, data);
  return response.data ?? {};
};
