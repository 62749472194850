import ExtratoGrupoCategoria from './extratoGrupoCategoria';

export default class ExtratoCategoria {
  constructor(jsonObject = {}) {
    this.nrSeqExtratoCategoria = jsonObject.nrSeqExtratoCategoria;
    this.noExtratoCategoria = jsonObject.noExtratoCategoria;
    this.flgSaldo = jsonObject.flgSaldo;
    this.cdExtratoCategoria = jsonObject.cdExtratoCategoria;
    this.nrSeqExtratoGrupoCategoria = jsonObject.nrSeqExtratoGrupoCategoria;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.extratoGrupoCategoria = new ExtratoGrupoCategoria(
      jsonObject.extratoGrupoCategoria ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
