import React, { useRef, useEffect, useState } from 'react';
import { CSDSelPage, GridView, Panel } from 'ui/components';
import { getMonitoramentoVeiculoList } from 'core/services/FRO/MonitoramentoVeiculo';
import { Theme } from 'ui/Helpers/utils';

export default function MonitoramentoVeiculo({ transaction, isActive }) {
  const gridView = useRef();
  const gridView2 = useRef();

  const [loading, setLoading] = useState(false);

  const columns = [
    { key: 'veiculo.noVeiculo', title: 'Veiculo' },
    { key: 'veiID', title: 'Id' },
    { key: 'vel', title: 'Velocidade' },
    { key: 'rua', title: 'Rua' },
    { key: 'mun', title: 'Cidade' },
    { key: 'uf', title: 'Estado' },
    { key: 'odometer', title: 'Odômetro' },
    { key: 'data', title: 'Data Gps', format: GridView.DataTypes.DateHour },
    { key: 'latitude', title: 'Latitude' },
    { key: 'longitude', title: 'Longitude' },
  ];

  const manutencaoPreventiva = [
    {
      key: 'placa',
      title: 'Placa',
      format: GridView.DataTypes.Text,
      colorText: 'noColorVeiculo',
    },
    {
      key: 'manutencaoPreventiva.noManutencaoPreventiva',
      title: 'Manutenção Preventiva',
      format: GridView.DataTypes.Text,
      colorText: 'noColorVeiculo',
    },
    {
      key: 'nrOdometroMaximo',
      title: 'Odomêtro para manutenção',
      colorText: 'noColorVeiculo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'nrOdometroInicial',
      title: 'Odomêtro Atual',
      colorText: 'noColorVeiculo',
      format: GridView.DataTypes.Decimal,
    },
  ];

  useEffect(() => {
    async function load() {
      setLoading(true);
      const monitoramentoveiculo = await getMonitoramentoVeiculoList();

      if (gridView && gridView.current)
        gridView.current.setDataSource(monitoramentoveiculo.ultimasPosicoes);
      if (gridView2 && gridView2.current)
        gridView2.current.setDataSource(
          monitoramentoveiculo.veiculoComManutencaoProxima
        );

      setLoading(false);
    }
    load();
  }, []);

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção Monitoramento Veículos'
      loading={loading}
      transaction={transaction}
    >
      <Panel className='mb-3'>
        <Panel.Header
          icon={['fas', 'user']}
          title='Manutenções Pendências'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col'>
              <GridView
                ref={gridView2}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={manutencaoPreventiva}
                onPageSizeChange={false}
                showSelectSizes={false}
                showPagination={false}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <Panel className='mb-3'>
        <Panel.Header
          icon={['fas', 'user']}
          title='Monitoramento Veiculo'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div>
            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridView}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns}
                  onPageSizeChange={false}
                  showSelectSizes={false}
                  showPagination={false}
                />
              </div>
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </CSDSelPage>
  );
}
