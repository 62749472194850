import TabelaPrecoProduto from './tabelaPrecoProduto';
import Empresa from '../SEG/empresa';

export default class TabelaPreco {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoempresa = jsonObject.nrSeqGrupoempresa;
    this.nrSeqTabelaPreco = jsonObject.nrSeqTabelaPreco;
    this.cdTabelaPreco = jsonObject.cdTabelaPreco;
    this.noTabelaPreco = jsonObject.noTabelaPreco;
    this.dtInivalidade = jsonObject.dtInivalidade;
    this.dtFimvalidade = jsonObject.dtFimvalidade;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.empresa = new Empresa(jsonObject.empresa ?? {});

    this.tabelaPrecoProduto = (jsonObject.tabelaPrecoProduto ?? []).map(
      (PrecoProduto) => new TabelaPrecoProduto(PrecoProduto)
    );
  }

  get getdtIniValidadeFormatada() {
    if (this.dtInivalidade) {
      return `${new Date(this.dtInivalidade).toLocaleDateString('pt-BR')}`;
    }
    return '';
  }

  get dtFimValidadeFormatada() {
    if (this.dtFimvalidade) {
      return `${new Date(this.dtFimvalidade).toLocaleDateString('pt-BR')}`;
    }
    return '';
  }

  toJson = () => JSON.stringify(this);
}
