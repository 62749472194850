import React, { useState, useRef } from 'react';
import { CSDSelPage, GridView, ToolbarButtons } from 'ui/components';
// @ts-expect-error
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { ResponseStatus, Theme } from 'ui/Helpers/enums.ts';
import { criarDemandasDesbloqueio } from 'core/services/HELP/demanda';
import { getClienteLinhasParaDesbloqueio } from 'core/services/TEL/clienteLinha';

export default function DesbloqueioMassivo({ isActive, transaction }: Page) {
  const gridView = useRef<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const onSetMessage = (status: number, messageShow: string): void => {
    if (messageShow)
      setMessage({
        message: messageShow,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (): Promise<void> => {
    if (!loading) {
      setLoading(true);

      const {
        status,
        message: msg,
        data: clienteLinhas,
      } = await getClienteLinhasParaDesbloqueio();

      if (status === ResponseStatus.Error) {
        onSetMessage(status, msg);
      }

      if (gridView && gridView.current) {
        gridView.current.setDataSource(clienteLinhas ?? []);
      }

      if (clienteLinhas.length === 0) {
        onSetMessage(
          ResponseStatus.Error,
          'Não foi localizado nenhuma linha para desbloqueio'
        );
      }

      setLoading(false);
    }
  };

  const columns: Array<GridviewColumns> = [
    { key: 'cliente.noPessoa', title: 'Cliente' },
    {
      key: 'chip.nrPrefixo',
      title: 'Prefixo',
    },
    { key: 'chip.nrLinha', title: 'Linha' },
  ];

  const onGerarDemandasDesbloqueio = async (): Promise<void> => {
    if (!loading) {
      setLoading(true);

      const dataSoure = gridView.current.getDataSource() ?? [];

      if (dataSoure.length > 0) {
        const nrSeqsClienteLinha: Array<number> = dataSoure.map(
          (e: any) => e.nrSeqClienteLinha
        );

        const { status, message: msg } = await criarDemandasDesbloqueio(
          nrSeqsClienteLinha
        );

        onSetMessage(status, msg);
      } else {
        onSetMessage(
          ResponseStatus.Error,
          'Nenhuma cliente linha para ser desbloqueado'
        );
      }

      setLoading(false);
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Desbloqueio Massivo'
      loading={loading}
      onSearch={search}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons fixedOnTop>
        <ToolbarButtons.Button
          text='Gerar Desbloqueios'
          tooltip='Gera uma demanda de Desbloqueio para as linhas'
          onClick={onGerarDemandasDesbloqueio}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            showSelectSizes={false}
            showPagination={false}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
