export default class TabelaPrecoProduto {
  constructor(jsonObject = {}) {
    this.dtAlteracao = jsonObject.dtAlteracao;
    this.flgPromocao = jsonObject.flgPromocao;
    this.nrSeqProduto = jsonObject.nrSeqProduto;
    this.noProdutoAgrupador = jsonObject.noProdutoAgrupador;
    this.nrSeqTabelaPreco = jsonObject.nrSeqTabelaPreco;
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.vlrOriginal = jsonObject.vlrOriginal;
    this.vlrMinimo = jsonObject.vlrMinimo;
    this.vlrPromocao = jsonObject.vlrPromocao;
    this.vlrVenda = jsonObject.vlrVenda;
  }

  get getFlgPromocao() {
    return this.flgPromocao === true ? 'Sim' : 'Não';
  }

  toJson = () => JSON.stringify(this);
}
