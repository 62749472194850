import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Textbox } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getProdutoSubGrupo,
  saveProdutoSubGrupo,
  deleteProdutoSubGrupo,
} from 'core/services/EST/produtoSubGrupo';
import { getProdutoGrupoList } from 'core/services/EST/produtoGrupo';

export default function ProdutoSubGrupoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      const produtoSG = await getProdutoSubGrupo(registryKey);
      const produtoG = {
        nrSeqProdutoGrupo: produtoSG.nrSeqProdutoGrupo,
        noProdutoGrupo: produtoSG.noProdutoGrupo,
      };

      setData({ ...produtoSG, produtoGrupo: produtoG });
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveProdutoSubGrupo(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onSearchProdutoGrupo = async (e) => {
    const { status, msg, produtogrupos } = await getProdutoGrupoList({
      noProdutoGrupo: e,
    });
    if (msg) onSetMessage(status, msg);
    return produtogrupos;
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteProdutoSubGrupo(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Produto Sub Grupo'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqProdutoSubGrupo > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-4'>
          <Textbox
            label='Código'
            text={data.cdProdutoSubGrupo}
            mask={MaskTypes.Integer}
            readOnly
          />
        </div>
        <div className='col-5 mb-3'>
          <Autocomplete
            required
            label='Produto Grupo'
            searchDataSource={onSearchProdutoGrupo}
            selectedItem={data.produtoGrupo}
            onSelectItem={(produtoGrupo) => {
              setData({
                ...data,
                produtoGrupo,
                nrSeqProdutoGrupo: produtoGrupo.nrSeqProdutoGrupo,
              });
            }}
            dataSourceTextProperty='noProdutoGrupo'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-5'>
          <Textbox
            required
            maxLength={60}
            label='Produto Sub Grupo'
            text={data.noProdutoSubGrupo}
            onChangedValue={(noProdutoSubGrupo) =>
              setData({ ...data, noProdutoSubGrupo })
            }
          />
        </div>
        <div className='col-4'>
          <Textbox
            required
            label='Valor Custo'
            text={data.vlrCusto}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(vlrCusto) => setData({ ...data, vlrCusto })}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
