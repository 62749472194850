import React from 'react';
import { Modal, Button } from 'ui/components';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';

export default function ModalAvisoManifesto(props) {
  const { show } = props;

  const onSend = (encerra) => {
    props.onClose(encerra);
  };

  return (
    <Modal
      show={show}
      title='Aviso de Manifesto'
      onClose={() => onSend(false)}
      size={BootstrapSizes.Large}
    >
      <Modal.Body>
        <div className='alert alert-info' role='alert'>
          <div className='row'>
            <div className='col fs-6'>
              <div className='row mb-2'>
                <span className='fw-bold'>
                  Você realmente deseja encerrar o manifesto?
                </span>
              </div>
              <div className='row mb-2'>
                <span>
                  Depois de encerrado o MDFE não poderá mais ser cancelado, se
                  desejar CANCELAR um CTE é preciso CANCELAR o manifesto.
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Continuar'
          theme={Theme.Success}
          template={Button.Templates.Default}
          size={BootstrapSizes.Medium}
          onClick={() => onSend(true)}
        />
        <Button
          text='Sair'
          theme={Theme.Danger}
          template={Button.Templates.Default}
          size={BootstrapSizes.Medium}
          onClick={() => onSend(false)}
        />
      </Modal.Footer>
    </Modal>
  );
}
