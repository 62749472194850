import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getTipoChamadoPagined,
  deleteAllTipoChamado,
} from 'core/services/HELP/tipoChamado';
import { CSDSelPage, GridView } from 'ui/components';
import {
  GridviewColumns,
  Message,
  Page,
  RowDoubleClick,
} from 'ui/Helpers/interfaces';
import { ColumnTypes } from 'ui/Helpers/enums';

const columns: Array<GridviewColumns> = [
  { key: 'nrSeqTipoChamado', type: ColumnTypes.Checkbox },
  { key: 'cdTipoChamado', title: 'Código' },
  { key: 'noTipoChamado', title: 'Tipo de Chamado' },
  { key: 'area.noAreaChamado', title: 'Area de Chamado', sortable: false },
];

export default function TipoChamado({
  transaction,
  onOpenPage,
  isActive,
}: Page) {
  const gridView: any = useRef();

  const [filtros, setFiltros] = useState<Object>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const search = async (params: Object | null = null): Promise<void> => {
    setLoading(true);

    const { data, pagination } = await getTipoChamadoPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  useEffect(() => {
    search();
  }, []);

  const onColumnSort = (sortBy: string): void => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    search(filters);
  };

  const onPageSizeChange = (totalByPage: number): void => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    search(filters);
  };

  const onPageChange = (page: number): void => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    search(filters);
  };

  const onOpenMaintenance = (registryKey: number | null = null): void => {
    onOpenPage(
      transaction.noTransacao,
      'TipoChamado/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow: RowDoubleClick[]): void => {
    const nrSeqTipoChamado = sourceRow.find(
      (e) => e.key === 'nrSeqTipoChamado'
    );
    onOpenMaintenance(nrSeqTipoChamado?.value);
  };

  const onDelete = async (): Promise<void> => {
    setLoading(true);

    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllTipoChamado(
        selecteds.map((row: any) => row[0].value)
      );

      setLoading(false);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      search();
    } else {
      setLoading(false);

      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Tipo de Chamado'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
