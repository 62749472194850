import GrupoCofins from 'core/models/FIS/grupoCofins';
import GrupoIcms from 'core/models/FIS/grupoIcms';
import GrupoIPI from 'core/models/FIS/grupoIPI';
import GrupoPIS from 'core/models/FIS/grupoPIS';
import GrupoIssqn from 'core/models/FIS/grupoIssqn';

import ProdutoGrupo from 'core/models/EST/produtoGrupo';
import ProdutoSubGrupo from 'core/models/EST/produtoSubGrupo';
import ProdutoUnidadeMedida from 'core/models/EST/produtoUnidadeMedida';
import ProdutoCodigo from 'core/models/EST/produtoCodigo';

import ProdutoMarca from 'core/models/EST/produtoMarca';
import ProdutoLinha from 'core/models/EST/produtoLinha';
import ProdutoOrigem from 'core/models/EST/produtoOrigem';

import Almoxarifado from 'core/models/EST/almoxarifado';
import AlmoxarifadoLocal from 'core/models/EST/almoxarifadoLocal';

import NfItem from 'core/models/COP/nfItem';
import ManutencaoPreventiva from './manutencaoPreventiva';

export default class ProdutoAgrupador {
  constructor(jsonObject = {}) {
    this.nrSeqProdutoAgrupador = jsonObject.nrSeqProdutoAgrupador;
    this.noProduto = jsonObject.noProduto;
    this.nrSeqProdutoSubGrupo = jsonObject.nrSeqProdutoSubGrupo;
    this.nrSeqProdutoTipoServico = jsonObject.nrSeqProdutoTipoServico;
    this.nrSeqProdutoOrigem = jsonObject.nrSeqProdutoOrigem;
    this.nrSeqGrupoCofins = jsonObject.nrSeqGrupoCofins;
    this.pesoLiq = jsonObject.pesoLiq;
    this.pesoBruto = jsonObject.pesoBruto;
    this.nrSeqProdutoCor = jsonObject.nrSeqProdutoCor;
    this.nrSeqProdutoTamanho = jsonObject.nrSeqProdutoTamanho;
    this.nrSeqGrupoIcms = jsonObject.nrSeqGrupoIcms;
    this.nrSeqProdutoUnidadeMedida = jsonObject.nrSeqProdutoUnidadeMedida;
    this.nrSeqGrupoIssqn = jsonObject.nrSeqGrupoIssqn;
    this.nrSeqGrupoIPI = jsonObject.nrSeqGrupoIPI;
    this.nrSeqGrupoPIS = jsonObject.nrSeqGrupoPIS;
    this.nrSeqProdutoMaterial = jsonObject.nrSeqProdutoMaterial;
    this.nrSeqProdutoGrupo = jsonObject.nrSeqProdutoGrupo;
    this.nrSeqProdutoUnidadeMedidaTrib =
      jsonObject.nrSeqProdutoUnidadeMedidaTrib;
    this.noEantrib = jsonObject.noEantrib;
    this.noEan = jsonObject.noEan;
    this.noEan2 = jsonObject.noEan2;
    this.cdPeca = jsonObject.cdPeca;
    this.cdProdutoCodigo = jsonObject.cdProdutoCodigo;
    this.cdCest = jsonObject.cdCest;
    this.cdCa = jsonObject.cdCa;
    this.noCodigoANP = jsonObject.noCodigoANP;
    this.noDescricaoANP = jsonObject.noDescricaoANP;
    this.noCodigoSpedFiscalEntrada = jsonObject.noCodigoSpedFiscalEntrada;
    this.grupoCofins = new GrupoCofins(
      jsonObject.grupoCofins ?? new GrupoCofins()
    );
    this.grupoIcms = new GrupoIcms(jsonObject.grupoIcms ?? new GrupoIcms());
    this.grupoIPI = new GrupoIPI(jsonObject.grupoIPI ?? new GrupoIPI());
    this.grupoPIS = new GrupoPIS(jsonObject.grupoPIS ?? new GrupoPIS());
    this.grupoIssqn = new GrupoIssqn(jsonObject.grupoIssqn ?? new GrupoIssqn());
    this.historicoCompras = (jsonObject.historicoCompras ?? []).map(
      (item) => new NfItem(item)
    );
    this.produtoUnidadeMedida = new ProdutoUnidadeMedida(
      jsonObject.produtoUnidadeMedida ?? new ProdutoUnidadeMedida()
    );

    this.produtoGrupo = new ProdutoGrupo(
      jsonObject.produtoGrupo ?? new ProdutoGrupo()
    );

    this.produtoSubGrupo = new ProdutoSubGrupo(
      jsonObject.produtoSubGrupo ?? new ProdutoSubGrupo()
    );

    this.produtoLinha = new ProdutoLinha(
      jsonObject.produtoLinha ?? new ProdutoLinha()
    );

    this.produtoOrigem = new ProdutoOrigem(
      jsonObject.produtoOrigem ?? new ProdutoOrigem()
    );

    this.produtoMarca = new ProdutoMarca(
      jsonObject.produtoMarca ?? new ProdutoMarca()
    );

    this.localArmazenagem = new AlmoxarifadoLocal(
      jsonObject.localArmazenagem ?? {}
    );

    this.almoxarifado = new Almoxarifado(jsonObject.almoxarifado ?? {});

    this.manutencoesPreventiva =
      jsonObject.manutencoesPreventiva?.map(
        (json) => new ManutencaoPreventiva(json)
      ) ?? [];

    this.almoxarifados = jsonObject.almoxarifados;
    this.nrSeqAlmoxarifadoLocal = jsonObject.nrSeqAlmoxarifadoLocal;
    this.nrSeqProdutoMarca = jsonObject.nrSeqProdutoMarca;
    this.codigos =
      jsonObject.codigos?.map((json) => new ProdutoCodigo(json)) ?? [];
    this.cdNcm = jsonObject.cdNcm;
    this.nrSeqProduto = jsonObject.nrSeqProduto;
    this.nrSeqProdutoLinha = jsonObject.nrSeqProdutoLinha;
    this.noProdutoAgrupador = jsonObject.noProdutoAgrupador;
    this.flgAtivo = jsonObject.flgAtivo;
    this.noSaldo = jsonObject.noSaldo;
    this.noValorFinal = jsonObject.noValorFinal;
    this.dtUltimaCompra = jsonObject.dtUltimaCompra;
  }

  toJson = () => JSON.stringify(this);
}
