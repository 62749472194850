import React, { useState, useEffect } from 'react';
import { BootstrapSizes, Theme, generateID } from 'ui/Helpers/utils';

export default function Switch({
  checked = false,
  onChange,
  right,
  size = BootstrapSizes.Medium,
  theme = Theme.Primary,
  label,
  formControl,
  tooltip,
  id = generateID(),
  visible,
  disabled,
}) {
  const [checkedState, setCheckedState] = useState(checked);

  useEffect(() => {
    setCheckedState(checked);
  }, [checked]);

  const onCheckChange = () => {
    if (disabled) {
      return;
    }
    const newChecked = !checkedState;
    setCheckedState(newChecked);
    if (onChange) onChange(newChecked);
  };

  const className = ['form-switch-square'];

  if (right) className.push('form-switch-right');

  className.push(`form-switch-${size}`);
  className.push(`form-switch-${theme}`);

  const isVisible = visible ?? true;

  return isVisible ? (
    <>
      {label && formControl && (
        <label className='form-label' title={tooltip} htmlFor={id}>
          {label}
        </label>
      )}
      <div id={id} className={className.join(' ')}>
        <div
          role='presentation'
          className='form-switch-square-label'
          onClick={onCheckChange}
        >
          <span
            className='form-switch-square-span'
            data-checked={checkedState}
            data-unchecked-label='Não'
            data-checked-label='Sim'
          />
        </div>
      </div>
    </>
  ) : null;
}
