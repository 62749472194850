export default class DashboardTransporte {
  constructor(jsonObject = {}) {
    this.pesoCarregado = jsonObject.pesoCarregado;
    this.totalPeso = jsonObject.totalPeso;
    this.numeroEmbarques = jsonObject.numeroEmbarques;
    this.faturamentoPorCliente = jsonObject.faturamentoPorCliente;
    this.carregamentoCteMes = jsonObject.carregamentoCteMes;
    this.carregamentoCteAno = jsonObject.carregamentoCteAno;
    this.pesoMes = jsonObject.pesoMes;
    this.estadoColeta = jsonObject.estadoColeta;
    this.estadoEntrega = jsonObject.estadoEntrega;
    this.valorEstadoColeta = jsonObject.valorEstadoColeta;
    this.valorEstadoEntrega = jsonObject.valorEstadoEntrega;
    this.pesoEstadoEntrega = jsonObject.pesoEstadoEntrega;
    this.totalLitros = jsonObject.totalLitros;
    this.valorMedioPosto = jsonObject.valorMedioPosto;
  }
}
