import React, { useState, useEffect } from 'react';
import { ResponseStatus, BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { Button, Loader, Modal, Notification, DatePicker } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';

import { generateTituloFrete } from 'core/services/COP/nf';

const ModalDtVencimento = ({ dataNf, show, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    setData(dataNf);
    setMessage({ message: '' });
  }, [dataNf]);

  const onSave = async () => {
    setLoading(true);
    const { status, message: msg, data: nf } = await generateTituloFrete(data);

    if (status === ResponseStatus.Success) {
      setData({ ...data, dtValid: nf.dtValid });
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  return (
    <Modal
      show={show}
      size={BootstrapSizes.ExtraLarge}
      title='Informar a Data de Vencimento do Titulo Pagar'
      onClose={onClose}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-5'>
          <div className='col-md-4'>
            {' '}
            {/* Aqui você pode ajustar a largura da coluna */}
            <div className='card mt-3' style={{ height: '330px' }}>
              <div className='card-body'>
                <div className='col-12'>
                  <DatePicker
                    label='Data Vencimento'
                    required
                    text={data.dtVencimento}
                    mask={MaskTypes.Date}
                    onChange={(dtVencimento) =>
                      setData({ ...data, dtVencimento })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row mt-5' />
      </Modal.Body>
      <Modal.Footer>
        <Button
          icon={['far', 'save']}
          text='Gerar Titulo Frete'
          theme={Theme.Success}
          onClick={onSave}
        />
        <Button
          icon='ban'
          text='Cancelar'
          theme={Theme.Danger}
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default ModalDtVencimento;
