import { Empresa } from '../SEG';
import FormaPagamento from './formaPagamento';

export default class CondicaoPagamentoFormaPagamen {
  constructor(jsonObject = {}) {
    this.nrSeqCondicaoPagamento = jsonObject.nrSeqCondicaoPagamento;
    this.nrParcela = jsonObject.nrParcela;
    this.nrDias = jsonObject.nrDias;
    this.percentual = jsonObject.percentual;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.taxaAdm = jsonObject.taxaAdm;
    this.outrasDespesas = jsonObject.outrasDespesas;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.flgDataFixa = jsonObject.flgDataFixa;
    this.dataFixa = jsonObject.dataFixa;
    this.flgLiquida = jsonObject.flgLiquida;
    this.nrSeqCondicaoPagamento_FormaPagamento =
      jsonObject.nrSeqCondicaoPagamento_FormaPagamento;
  }

  toJson = () => JSON.stringify(this);
}
