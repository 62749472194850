import qs from 'qs';
import Pagination from 'core/models/pagination';
import VendedorComissao from 'core/models/VEN/vendedorComissao';
import VendedorComissaoList from 'core/models/VEN/vendedorComissaoList';
import api from '../api';

const baseURL = `/VEN/VendedorComissao`;

export const getVendedorComissaoList = async (filters) => {
  const response = await api.get(`/$VendedorComissao/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new VendedorComissao(json)) ?? [],
  };
};

export const getVendedorComissaoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};
  return {
    status,
    message,
    data: value?.map((json) => new VendedorComissaoList(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getVendedorComissao = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};

  return { tabela: value?.map((json) => new VendedorComissao(json)) ?? [] };
};

export const saveVendedorComissao = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const saveVendedorComissaoCopia = async (data) => {
  const response = await api.post(`${baseURL}/SaveVendedorComissaoCopia`, data);
  return response.data ?? {};
};

export const deleteVendedorComissao = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printVendedorComissao = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelVendedorComissao = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllVendedorComissao = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
