export default class TipoVenda {
  constructor(jsonObject = {}) {
    this.nrSeqTipoVenda = jsonObject.nrSeqTipoVenda;
    this.cdTipoVenda = jsonObject.cdTipoVenda;
    this.flgAtivo = jsonObject.flgAtivo;
    this.noTipoVenda = jsonObject.noTipoVenda;
    this.flgEmiteNf = jsonObject.flgEmiteNf;
    this.flgGeraEstq = jsonObject.flgGeraEstq;
    this.nrSeqCfoEst = jsonObject.nrSeqCfoEst;
    this.nrSeqCfoPais = jsonObject.nrSeqCfoPais;
    this.flgComprometeEstoque = jsonObject.flgComprometeEstoque;
    this.flgGeraTitulo = jsonObject.flgGeraTitulo;
    this.flgProvisionaTitulo = jsonObject.flgProvisionaTitulo;
    this.nrSeqTituloEspeciePro = jsonObject.nrSeqTituloEspeciePro;
    this.serieNfv = jsonObject.serieNfv;
    this.nrSeqEstoquetTipo = jsonObject.nrSeqEstoquetTipo;
    this.noNaturezaOperacao = jsonObject.noNaturezaOperacao;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqCfoExt = jsonObject.nrSeqCfoExt;
    this.flgExcecaoIcmsEstado = jsonObject.flgExcecaoIcmsEstado;
    this.flgExcecaoIpiEstado = jsonObject.flgExcecaoIpiEstado;
    this.flgExcecaoIcmsPais = jsonObject.flgExcecaoIcmsPais;
    this.flgExcecaoIpiPais = jsonObject.flgExcecaoIpiPais;
    this.flgExcecaoIcmsExt = jsonObject.flgExcecaoIcmsExt;
    this.flgExcecaoIpiExt = jsonObject.flgExcecaoIpiExt;
    this.nrSeqCfoEstInverso = jsonObject.nrSeqCfoEstInverso;
    this.nrSeqCfoPaisInverso = jsonObject.nrSeqCfoPaisInverso;
    this.nrSeqCfoExtInverso = jsonObject.nrSeqCfoExtInverso;
    this.indPres = jsonObject.indPres;
    this.flgCupomFiscal = jsonObject.flgCupomFiscal;
  }

  toJson = () => JSON.stringify(this);
}
