export default class TituloPagarFatura {
  constructor(jsonObject = {}) {
    this.nrSeqTitulopagarfatura = jsonObject.nrSeqTitulopagarfatura;
    this.vlrTituloPagar = jsonObject.vlrTituloPagar;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.dtCad = jsonObject.dtCad;
    this.nrFatura = jsonObject.nrFatura;
    this.nrSeqPessoaFor = jsonObject.nrSeqPessoaFor;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqArquivoBancario = jsonObject.nrSeqArquivoBancario;
    this.cdAgencia = jsonObject.cdAgencia;
    this.cdContaCorrente = jsonObject.cdContaCorrente;
    this.dtVencimento = jsonObject.dtVencimento;
    this.flgRemessaPendente = jsonObject.flgRemessaPendente;
  }

  toJson = () => JSON.stringify(this);
}
