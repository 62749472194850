import React, { useState } from 'react';
import {
  Autocomplete,
  Button,
  Loader,
  Modal,
  Notification,
} from 'ui/components';
import { Theme, ResponseStatus, BootstrapSizes } from 'ui/Helpers/utils';

import { getEmailTituloAutoComplete, sendFaturas } from 'core/services/TEL';

export default function ModalEnviarFaturasPorEmail(props) {
  const [data, setData] = useState({});
  const [messageState, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onAutoCompleteMensagem = async (noTituloEmail) => {
    const { status, message, emails } = await getEmailTituloAutoComplete({
      noTituloEmail,
    });

    if (status !== ResponseStatus.Success)
      setMessage({
        message,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return emails;
  };

  const onSend = async () => {
    setLoading(true);
    const param = {
      ...data,
      FlgEnvioPadraoEmail: true,
      nrSeqContaRecursos: props.recursos?.map((p) => p.nrSeqContaRecurso),
    };
    const { status, message } = await sendFaturas(param);

    setLoading(false);

    if (status === ResponseStatus.Success) {
      props.onSave(status, message);
      props.onClose();
    } else {
      const warning =
        status === ResponseStatus.Warning ? Theme.Warning : Theme.Danger;
      setMessage({
        message,
        theme: status === ResponseStatus.Success ? Theme.Success : warning,
      });
    }
  };

  const { show, onClose, recursos } = props;
  return (
    <Modal
      show={show}
      title='Enviar Email para as Linhas Selecionadas'
      onClose={onClose}
      size={BootstrapSizes.Large}
      icon='envelope'
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col'>
            <Autocomplete
              label='Mensagem a ser enviada'
              required
              searchDataSource={onAutoCompleteMensagem}
              selectedItem={data.emailTitulo}
              onSelectItem={(emailTitulo) => {
                setData({
                  ...data,
                  emailTitulo,
                  nrSeqMensagemEmail: emailTitulo.nrSeqEmailTitulo,
                });
              }}
              dataSourceTextProperty='noTituloEmail'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <div className='alert alert-dark'>
              <h4 className='alert-heading'>Mensagem</h4>
              <p>{data?.emailTitulo?.msgEmailTitulo}</p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            {recursos?.map((recurso) => (
              <div
                key={recurso.nrSeqContaRecurso}
                className='badge bg-secondary me-2 p-2 fs-6 mb-2'
              >
                {recurso.numeroCompleto}
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Enviar Email'
          theme={Theme.Success}
          icon='envelope'
          onClick={onSend}
        />
        <Button
          text='Cancelar'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            setData({});
            props.onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
