export default class NFEImportadaTipo {
  nrSeqNfeImportadaTipo: Number;

  noNfeImportadaTipo: string;

  constructor(jsonObject: NFEImportadaTipo) {
    this.nrSeqNfeImportadaTipo = jsonObject.nrSeqNfeImportadaTipo;
    this.noNfeImportadaTipo = jsonObject.noNfeImportadaTipo;
  }

  toJson = () => JSON.stringify(this);
}
