import React, { useEffect, useRef, useState } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import { getPlanoConvenioPagined } from 'core/services/ASS/planoConvenio';
import {
  CSDSelPage,
  GridView,
  Autocomplete,
  Textbox,
  RadioButton,
} from 'ui/components';
import { getConvenioAutoComplete } from 'core/services/ASS/convenio';

const columns = [
  { key: 'nrSeqPlanoConvenio', type: GridView.ColumnTypes.Checkbox },
  { key: 'flgMensal', title: 'FLGMENSAL', visible: false },
  { key: 'flgFaixaEtaria', title: 'FLGFAIXAETARIA', visible: false },
  { key: 'nrSeqConvenio', title: 'NrSeqConvenio', visible: false },
  { key: 'convenio.noConvenio', title: 'Convenio' },
  { key: 'noPlanoConvenio', title: 'Nome Plano' },
  { key: 'flgAtivo', title: 'FLGATIVO', visible: false },
  {
    key: 'flgGeraLancamentoManual',
    title: 'FLGGERALANCAMENTOMANUAL',
    visible: false,
  },
];

export default function PlanoConvenio({ transaction, onOpenPage, isActive }) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({ status: 'T' });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    let flgAtivo = false;

    if (filtros.status === 'S') {
      flgAtivo = true;
    }

    const { data, pagination } = await getPlanoConvenioPagined(
      params ?? { ...filtros, flgAtivo }
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      // const { data, pagination } = await getPlanoConvenioPagined({});

      // if (gridView && gridView.current)
      //   gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'PlanoConvenio/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqPlanoConvenio = sourceRow.find(
      (e) => e.key === 'nrSeqPlanoConvenio'
    );
    onOpenMaintenance(nrSeqPlanoConvenio.value);
  };

  const onSearchConvenio = async (e) => {
    const { status, message: msg, convenios } = await getConvenioAutoComplete({
      noConvenio: e,
    });
    if (msg) onSetMessage(status, msg);
    return convenios;
  };

  const statusButtons = [
    { text: 'Todos', value: 'T' },
    { text: 'Ativos', value: 'S' },
  ];

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Plano Convenio'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-4'>
          <Textbox
            maxLength={200}
            label='Plano Convenio'
            text={filtros.noPlanoConvenio}
            onChangedValue={(noPlanoConvenio) =>
              setFiltros({ ...filtros, noPlanoConvenio })
            }
          />
        </div>

        <div className='col-4'>
          <Autocomplete
            label='Convenio'
            searchDataSource={onSearchConvenio}
            selectedItem={filtros.convenio}
            onSelectItem={(convenio) => {
              setFiltros({
                ...filtros,
                convenio,
                nrSeqConvenio: convenio.nrSeqConvenio,
              });
            }}
            dataSourceTextProperty='noConvenio'
          />
        </div>

        <div className='col-2'>
          <RadioButton
            outline
            label='Status'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={filtros.status}
            onChange={(status) => {
              setFiltros({ ...filtros, status });
            }}
            buttons={statusButtons}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
