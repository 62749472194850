import React, { useState, useEffect, useRef } from 'react';
import { setTransaction } from 'core/services/api';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import {
  Button,
  Modal,
  Notification,
  DatePicker,
  Autocomplete,
  Textbox,
  GridView,
  RadioButton,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN';
import {
  postParcelaTituloReceberRepactuacao,
  postCalculatePenaltyParcelManual,
  postCalculaEGeraValoresFaturas,
} from 'core/services/FIN/tituloReceber';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import { getEmpresaAutoComplete } from 'core/services/SEG';
// import TituloReceber from 'core/models/FIN/tituloReceber';
import { getInformacaoBancariaAutoComplete } from 'core/services/FIN/informacaoBancaria';

export default function ModalRepactuacaoParcelarTitulo({
  show,
  onClose,
  titulos,
  transaction,
  retornoSave,
}) {
  // #region Inicializadores
  const [message, setMessage] = useState(null);
  const [data, setData] = useState({
    vlrMultaCalculado: 0,
    vlrJuroCalculado: 0,
    vlrMulta: 0,
    vlrJuro: 0,
    vlrEntrada: 0,
    vlrParcela: 0,
    vlrTotalReceber: 0,
    vlrTotalTituloAntigo: 0,
    fglClickCalcular: false,
  });
  const [tipoMulta, setTipoMulta] = useState('Porcentagem');

  const gridViewAgrupadosNovosParaGerar = useRef();
  const gridViewAgrupadosSelecao = useRef();

  const dataRef = useRef();

  useEffect(() => {
    dataRef.current = data;
  }, [data]);
  // #endregion

  // #region Funções
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onClickRemover = () => {
    const dataRefVar = dataRef.current;
    setData({ ...dataRefVar, vlrTotalParcelas: null });

    if (gridViewAgrupadosNovosParaGerar?.current)
      gridViewAgrupadosNovosParaGerar.current.setDataSource([]);
  };

  const onCalcularJuroMulta = async () => {
    const tituloReceberAgrupadosSelecao = gridViewAgrupadosSelecao.current.getDataSource();

    const obj = {
      tituloReceberS: tituloReceberAgrupadosSelecao,
      vlrJuro: data.vlrJuro,
      vlrMulta: data.vlrMulta,
      nrSeqTituloEspecie: data.nrSeqTituloEspecie,
      noTipoMulta: tipoMulta,
    };

    const {
      status,
      message: msg,
      value,
    } = await postCalculatePenaltyParcelManual(obj);
    const { vlrJuroCalculado, vlrMultaCalculado, vlrTotalReceber } = value;
    if (status === ResponseStatus.Error) {
      onSetMessage(Theme.Danger, msg);
    } else {
      const listaParcelas = gridViewAgrupadosNovosParaGerar?.current?.getDataSource();
      if (listaParcelas && listaParcelas.length > 0)
        gridViewAgrupadosNovosParaGerar.current.setDataSource([]);

      setData({
        ...data,
        vlrJuroCalculado,
        vlrMultaCalculado,
        vlrTotalReceber,
        fglClickCalcular: true,
        vlrTotalParcelas: null,
      });
    }
  };

  const validaDadosRepactuacao = (dataValidacao) => {
    let msgRetorno = '';

    if (dataValidacao.vlrTotalReceber <= 0) {
      msgRetorno = 'Calcule o Juros e a Multa antes de adicionar as parcelas.';
      return { dataValidacao, msgRetorno };
    }

    if (!dataValidacao.nrSeqEmpresa) {
      msgRetorno = 'Necessário informar a empresa';
      return { dataValidacao, msgRetorno };
    }

    if (!dataValidacao.nrSeqTituloEspecie) {
      msgRetorno = 'Necessário informar o título espécie';
      return { dataValidacao, msgRetorno };
    }

    if (!dataValidacao.nrSeqFormaPagamento) {
      msgRetorno = 'Necessário informar a forma de pagamento';
      return { dataValidacao, msgRetorno };
    }

    if (!dataValidacao.dtVencimento) {
      msgRetorno = 'Necessário informar o vencimento';
      return { dataValidacao, msgRetorno };
    }

    if (!dataValidacao.vlrParcela || dataValidacao.vlrParcela < 0) {
      msgRetorno = 'Necessário informar o valor da primeira parcela';
      return { dataValidacao, msgRetorno };
    }

    if (
      dataValidacao.formaPagamento?.flgGrupo === 'D' &&
      !dataValidacao.nrSeqPessoaInformacaoBancaria
    ) {
      msgRetorno = 'Necessário informar informação bancária';
      return { dataValidacao, msgRetorno };
    }

    if (dataValidacao.vlrEntrada >= dataValidacao.vlrTotalReceber) {
      msgRetorno =
        'Valor da entrada não pode ser maior ou igual ao valor total a receber.';
      return { dataValidacao, msgRetorno };
    }

    if (!dataValidacao.vlrEntrada || dataValidacao.vlrEntrada < 0) {
      dataValidacao.vlrEntrada = 0;
    }

    return { dataValidacao, msgRetorno };
  };

  const onAdicionarParcela = async () => {
    const { dataValidacao, msgRetorno } = validaDadosRepactuacao(data);
    if (msgRetorno.length > 0) {
      onSetMessage(Theme.Danger, msgRetorno);
      return;
    }

    const { value: titulosGerados } = await postCalculaEGeraValoresFaturas(
      dataValidacao
    );

    const vlrTotalParcelasGeradas = titulosGerados.reduce(
      (acc, currentValue) => acc + currentValue.vlrTitulo,
      0
    );

    let saldoDevedor = data.vlrTotalReceber;
    titulosGerados.forEach((item) => {
      item.vlrFaturaColumn =
        item.vlrTitulo - item.vlrJuroAdicionado - item.vlrMultaAdicionado;

      if (saldoDevedor <= data.vlrParcela) {
        item.vlrSaldoDevedorColumn = 0;
      } else {
        item.vlrSaldoDevedorColumn = saldoDevedor - item.vlrFaturaColumn;
      }

      saldoDevedor = item.vlrSaldoDevedorColumn;
    });

    if (
      gridViewAgrupadosNovosParaGerar &&
      gridViewAgrupadosNovosParaGerar.current
    ) {
      gridViewAgrupadosNovosParaGerar.current.setDataSource(titulosGerados);
    }

    setData({ ...data, vlrTotalParcelas: vlrTotalParcelasGeradas });
  };

  const onSearchInformacaoBancaria = async () => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoComplete({
      nrSeqPessoa: titulos[0].nrSeqPessoaCli,
    });
    onSetMessage(status, msg);
    return informacoesBancarias;
  };

  const columnsAgrupadosNovosParaGerar = [
    {
      key: 'nrSeqTituloReceber',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'noObs', title: 'NoObs' },
    {
      key: 'vlrTitulo',
      title: 'Valor',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrJuroAdicionado',
      title: 'Juros',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMultaAdicionado',
      title: 'Multa',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrFaturaColumn',
      title: 'Valor Fatura',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrSaldoDevedorColumn',
      title: 'Saldo Devedor',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma',
    },
    // {
    //   key: 'nrSeqTituloReceber',
    //   type: GridView.ColumnTypes.Button,
    //   onClick: (e) => onClickRemover(e, data),
    //   theme: Theme.Danger,
    //   icon: 'trash-alt',
    //   size: BootstrapSizes.Small,
    //   sortable: false,
    //   tooltipDirection: 'bottom',
    //   title: 'Remover',
    // },
  ];

  const columnsAgrupadosSelecao = [
    {
      key: 'nrSeqTituloReceber',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'nrTit', title: 'NrTit' },
    { key: 'nrOrdem', title: 'NrOrdem' },
    {
      key: 'cliente.noPessoa',
      title: 'Cliente',
    },
    {
      key: 'tituloEspecie.noEspecie',
      title: 'Receita',
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma',
      visible: false,
    },
    {
      key: 'nrDiasEmAtraso',
      title: 'Atraso',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrRecebido',
      title: 'Recebido',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrSaldoTit',
      title: 'Saldo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'dtVencimentoStr',
      visible: false,
    },
    { key: 'noObs', title: 'NoObs', visible: false },
    {
      key: 'dtContab',
      title: 'Contabil',
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'dtRecebimento',
      title: 'Recebimento',
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'nrSeqTituloReceberFatura',
      title: 'NrSeqTituloReceberFatura',
      visible: false,
    },
  ];

  const onSearchFormaPagamento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitRec: true,
    });

    return formasPagamento;
  };

  const onSalvar = async () => {
    const tituloReceberAgrupadosSelecao = gridViewAgrupadosSelecao.current.getDataSource();
    const tituloReceberAgrupadosNovosParaGerar = gridViewAgrupadosNovosParaGerar.current.getDataSource();
    const obj = {
      tituloReceberAgrupadosSelecao,
      tituloReceberAgrupadosNovosParaGerar,
    };
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);

    const retornoSaveParcela = await postParcelaTituloReceberRepactuacao(obj);

    if (retornoSaveParcela.status === ResponseStatus.Error) {
      return onSetMessage(Theme.Danger, retornoSaveParcela.message);
    }

    const retornoDados = {
      vlrEntrada: data.vlrEntrada,
      vlrBaseCalculo: data.vlrTotalReceber,
      vlrJurosDoc: data.vlrJuroCalculado,
      vlrMultaDoc: data.vlrMultaCalculado,
      vlrParcela: data.vlrParcela,
      dtPrimeiroPagamento: data.dtVencimento,
      nrSeqFormaPagamento: data.nrSeqFormaPagamento,
    };
    const retorno = {
      retornoDados,
      retornoSaveParcela,
      tituloReceberAgrupadosSelecao,
    };

    setData({
      vlrMultaCalculado: 0,
      vlrJuroCalculado: 0,
      vlrEntrada: 0,
      vlrPrimeiraParcela: 0,
      nrParcelas: 0,
    });

    return retornoSave(retorno);
  };

  const onSearchTipo = async (e) => {
    const { data: tituloReceberEspecie } = await getTituloEspecieAutoComplete({
      noEspecie: e,
      flgExibeNoParcelamento: true,
    });

    return tituloReceberEspecie;
  };

  const onSearchEmpresa = async (e) => {
    const { empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    return empresas;
  };

  const calculaTotalTitulosParcelamento = () => {
    const total = titulos.reduce(
      (acum, item) => acum + item.vlrTitulo - item.vlrRecebido,
      0
    );
    return total;
  };

  useEffect(async () => {
    if (show) {
      setData({
        vlrMultaCalculado: 0,
        vlrJuroCalculado: 0,
        nrSeqEmpresa: titulos[0].nrSeqEmpresa,
        empresa: titulos[0].empresa,
        nrSeqPessoaCli: titulos[0].nrSeqPessoaCli,
        cliente: titulos[0].cliente,
        vlrTotalTituloAntigo: calculaTotalTitulosParcelamento(),
        vlrMulta: 0,
        vlrJuro: 0,
        vlrEntrada: 0,
        vlrParcela: 0,
        vlrTotalReceber: 0,
        fglClickCalcular: false,
      });
      if (gridViewAgrupadosSelecao && gridViewAgrupadosSelecao.current)
        gridViewAgrupadosSelecao.current.setDataSource(titulos);
    }
  }, [show]);
  // #endregion

  const dataTipoMulta = [
    {
      text: 'R$',
      value: 'Valor',
    },
    {
      text: '%',
      value: 'Porcentagem',
    },
  ];

  const handleTipoMulta = () => {
    if (tipoMulta === 'Porcentagem') {
      setTipoMulta('Valor');
    } else {
      setTipoMulta('Porcentagem');
    }
  };

  useEffect(() => {
    if (data?.tituloEspecie?.noEspecie === 'Acordo Judicial') {
      setTipoMulta('Valor');
    }
  }, [data.nrSeqTituloEspecie]);

  return (
    <Modal
      show={show}
      title='Repactuação - Titulo Receber'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body className='p-4 pt-3'>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-6'>
          <div className='card'>
            <div className='card-body'>
              <div className='row mb-3'>
                {/* Linha 1 */}
                <div className='row mb-3'>
                  <div className='col'>
                    <Autocomplete
                      label='Empresa'
                      searchDataSource={onSearchEmpresa}
                      selectedItem={data.empresa}
                      onSelectItem={(empresa) => {
                        setData({
                          ...data,
                          empresa,
                          nrSeqEmpresa: empresa.nrSeqEmpresa,
                        });
                      }}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                  <div className='col'>
                    <Autocomplete
                      label='Tipo Receita'
                      searchDataSource={onSearchTipo}
                      selectedItem={data.tituloEspecie}
                      onSelectItem={(tituloEspecie) => {
                        setData({
                          ...data,
                          tituloEspecie,
                          nrSeqTituloEspecie: tituloEspecie.nrSeqTituloEspecie,
                        });
                      }}
                      dataSourceTextProperty='noEspecie'
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='Valor Pendente'
                      text={data?.vlrTotalTituloAntigo}
                      mask={MaskTypes.DecimalCustom}
                      readOnly
                    />
                  </div>
                </div>

                <div className='row mb-3'>
                  {data?.tituloEspecie?.noEspecie !== 'Acordo Judicial' && (
                    <div className='col-2'>
                      <Textbox
                        label='Juros (%)'
                        text={data.vlrJuro}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={(vlrJuro) =>
                          setData({
                            ...data,
                            vlrJuro,
                          })
                        }
                      />
                    </div>
                  )}

                  <div className='col-2'>
                    {tipoMulta === 'Porcentagem' ? (
                      <Textbox
                        label='Multa (%)'
                        text={data.vlrMulta}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={(vlrMulta) =>
                          setData({
                            ...data,
                            vlrMulta,
                          })
                        }
                      />
                    ) : (
                      <Textbox
                        label='Multa (R$)'
                        text={data.vlrMulta}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={(vlrMulta) =>
                          setData({
                            ...data,
                            vlrMulta,
                          })
                        }
                      />
                    )}
                  </div>
                  {data?.tituloEspecie?.noEspecie !== 'Acordo Judicial' && (
                    <div className='col-2 mb-3'>
                      <RadioButton
                        label='Tipo Multa:'
                        outline
                        size={BootstrapSizes.Small}
                        theme={Theme.Primary}
                        selectedButton={tipoMulta}
                        buttons={dataTipoMulta}
                        onChange={handleTipoMulta}
                      />
                    </div>
                  )}

                  <div className='col mt-3'>
                    <Button
                      theme={Theme.Primary}
                      template={Button.Templates.Button}
                      text='Calcular'
                      onClick={() => onCalcularJuroMulta()}
                    />
                  </div>
                </div>

                {data?.fglClickCalcular && (
                  <>
                    {/* Linha 2 */}
                    <div className='row mb-3'>
                      <div className='col'>
                        <Textbox
                          label='Saldo Devedor Final'
                          text={data?.vlrTotalReceber}
                          mask={MaskTypes.DecimalCustom}
                          readOnly
                        />
                      </div>
                      <div className='col'>
                        <Textbox
                          label='Juros Calculado'
                          readOnly
                          text={data.vlrJuroCalculado}
                          mask={MaskTypes.DecimalCustom}
                          onChangedValue={(vlrJuroCalculado) =>
                            setData({
                              ...data,
                              vlrJuroCalculado,
                            })
                          }
                        />
                      </div>
                      <div className='col'>
                        <Textbox
                          label='Multa Calculada'
                          readOnly
                          text={data.vlrMultaCalculado}
                          mask={MaskTypes.DecimalCustom}
                          onChangedValue={(vlrMultaCalculado) =>
                            setData({
                              ...data,
                              vlrMultaCalculado,
                            })
                          }
                        />
                      </div>
                      <div className='col'>
                        <Textbox
                          label='Valor da Entrada'
                          text={data?.vlrEntrada}
                          mask={MaskTypes.DecimalCustom}
                          onChangedValue={(vlrEntrada) =>
                            setData({
                              ...data,
                              vlrEntrada,
                            })
                          }
                        />
                      </div>
                      <div className='col'>
                        <Textbox
                          label='Valor da 1ª Parcela'
                          text={data?.vlrParcela}
                          mask={MaskTypes.DecimalCustom}
                          onChangedValue={(vlrParcela) =>
                            setData({
                              ...data,
                              vlrParcela,
                            })
                          }
                        />
                      </div>
                      <div className='col-3'>
                        <DatePicker
                          label='Vencimento'
                          text={data.dtVencimento}
                          mask={MaskTypes.Date}
                          onChange={(dtVencimento) =>
                            setData({ ...data, dtVencimento })
                          }
                        />
                      </div>
                    </div>

                    {/* Linha 3 */}
                    <div className='row mb-3'>
                      <div className='col-3'>
                        <Autocomplete
                          label='Forma de Recebimento'
                          searchDataSource={onSearchFormaPagamento}
                          selectedItem={data.formaPagamento}
                          onSelectItem={(formaPagamento) => {
                            setData({
                              ...data,
                              formaPagamento,
                              nrSeqFormaPagamento:
                                formaPagamento.nrSeqFormaPagamento,
                            });
                          }}
                          dataSourceTextProperty='noFormaPagamento'
                        />
                      </div>
                      {data.formaPagamento?.flgGrupo === 'D' && (
                        <div className='col-4'>
                          <Autocomplete
                            label='Informação Bancária'
                            searchDataSource={onSearchInformacaoBancaria}
                            selectedItem={data.informacaoBancaria}
                            onSelectItem={(informacaoBancaria) =>
                              setData({
                                ...data,
                                informacaoBancaria,
                                nrSeqPessoaInformacaoBancaria:
                                  informacaoBancaria.nrSeqPessoaInformacaoBancaria,
                              })
                            }
                            dataSourceTextProperty='noDescricao'
                          />
                        </div>
                      )}
                      <div className='col-4 mt-3'>
                        <Button
                          theme={Theme.Primary}
                          template={Button.Templates.Button}
                          text='Adicionar Parcelas'
                          onClick={() => onAdicionarParcela()}
                        />
                      </div>
                    </div>

                    {/* Grids */}
                    <div className='row'>
                      <div className='col'>
                        <GridView
                          ref={gridViewAgrupadosNovosParaGerar}
                          className='table-striped table-hover table-bordered table-sm'
                          dataColumns={columnsAgrupadosNovosParaGerar}
                          showPagination={false}
                          transaction={transaction}
                          showSelectSizes={false}
                        />
                      </div>
                    </div>

                    {data?.vlrTotalParcelas && (
                      <div className='row'>
                        <div className='col-2'>
                          <Textbox
                            label='Valor Total das Parcelas'
                            readOnly
                            text={data?.vlrTotalParcelas}
                            mask={MaskTypes.DecimalCustom}
                            onChangedValue={(vlrTotalParcelas) =>
                              setData({
                                ...data,
                                vlrTotalParcelas,
                              })
                            }
                          />
                        </div>

                        <div className='col mt-3 d-flex justify-content-end'>
                          <Button
                            theme={Theme.Danger}
                            template={Button.Templates.Button}
                            text='Remover Parcelas'
                            onClick={() => onClickRemover()}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              <GridView
                ref={gridViewAgrupadosSelecao}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columnsAgrupadosSelecao}
                showPagination={false}
                transaction={transaction}
                showSelectSizes={false}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Salvar'
          theme={Theme.Success}
          icon={['far', 'save']}
          onClick={() => onSalvar()}
        />
        <Button
          text='Fechar'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            setData({
              vlrMultaCalculado: 0,
              vlrJuroCalculado: 0,
              vlrMulta: 0,
              vlrJuro: 0,
              vlrEntrada: 0,
              vlrParcela: 0,
              vlrTotalReceber: 0,
              vlrTotalTituloAntigo: 0,
              fglClickCalcular: false,
            });
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
