import TipoDespesaPlanoDeConta from 'core/models/FIN/tipoDespesa_PlanoDeConta';
import { CentroDeCusto } from '../TEL';
import ExtratoCategoria from './extratoCategoria';
import FormaPagamento from './formaPagamento';

export default class TipoDespesa {
  constructor(jsonObject = {}) {
    this.cdTipoDespesa = jsonObject.cdTipoDespesa;
    this.nrSeqTipoDespesa = jsonObject.nrSeqTipoDespesa;
    this.nrSeqExtratoCategoria = jsonObject.nrSeqExtratoCategoria;
    this.noTipoDespesa = jsonObject.noTipoDespesa;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgVinculaVeiculo = jsonObject.flgVinculaVeiculo;
    this.flgContaCredito = jsonObject.flgContaCredito;
    this.flgContaDebito = jsonObject.flgContaDebito;
    this.flgGeraContaB = jsonObject.flgGeraContaB;
    this.flgInativo = jsonObject.flgInativo;
    this.nrSeqCentroDeCusto = jsonObject.nrSeqCentroDeCusto;
    this.grupoempresas = jsonObject.grupoEmpresas;
    this.centrodecusto = new CentroDeCusto(jsonObject.centroDeCusto ?? {});
    this.extratocategoria = new ExtratoCategoria(
      jsonObject.extratoCategoria ?? {}
    );
    this.noExtratoCategoria = jsonObject.noExtratoCategoria;
    this.planoDeContas = (jsonObject.planoDeContas ?? []).map(
      (planoDeContas) => new TipoDespesaPlanoDeConta(planoDeContas)
    );
    this.noCentroDeCusto = jsonObject.noCentroDeCusto;
    this.flgGeraCustoRecorrente = jsonObject.flgGeraCustoRecorrente;
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.diaVencimento = jsonObject.diaVencimento;
    this.vlrCusto = jsonObject.vlrCusto;
  }

  toJson = () => JSON.stringify(this);
}
