export default class TipoDesconto {
  constructor(jsonObject = {}) {
    this.nrSeqTipoDesconto = jsonObject.nrSeqTipoDesconto;
    this.cdTipoDesconto = jsonObject.cdTipoDesconto;
    this.noTipoDesconto = jsonObject.noTipoDesconto;
    this.flgCalculoAutomatico = jsonObject.flgCalculoAutomatico;
    this.vlrPercDesconto = jsonObject.vlrPercDesconto;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.noRegraBusca = jsonObject.noRegraBusca;
  }

  toJson = () => JSON.stringify(this);
}
