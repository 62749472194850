import {
  TipoChamada,
  ContaRecurso,
  AreaChamada,
  CategoriaLigacao,
} from 'core/models/TEL';

export default class ContaChamada {
  constructor(jsonObject = {}) {
    this.nrSeqContaChamada = jsonObject.nrSeqContaChamada;
    this.nrSeqContaRecurso = jsonObject.nrSeqContaRecurso;
    this.nrPrefixoDestino = jsonObject.nrPrefixoDestino;
    this.nrLinhaDestino = jsonObject.nrLinhaDestino;
    this.horaChamada = jsonObject.horaChamada;
    this.duracaoXLS = jsonObject.duracaoXLS;
    this.vlrMinuto = jsonObject.vlrMinuto;
    this.vlrChamadaXLS = jsonObject.vlrChamadaXLS;
    this.vlrCobradoXLS = jsonObject.vlrCobradoXLS;
    this.noLocalidadeChamadaXLS = jsonObject.noLocalidadeChamadaXLS;
    this.noCategoriaXLS = jsonObject.noCategoriaXLS;
    this.vlrTarifaOperXLS = jsonObject.vlrTarifaOperXLS;
    this.nrSeqAreaChamada = jsonObject.nrSeqAreaChamada;
    this.nrSeqTipoChamada = jsonObject.nrSeqTipoChamada;
    this.dtChamada = jsonObject.dtChamada;
    this.vlrCobradoRecalculo = jsonObject.vlrCobradoRecalculo;
    this.vlrChamadaRecalculo = jsonObject.vlrChamadaRecalculo;
    this.noCategoriaFEB = jsonObject.noCategoriaFEB;
    this.noLocalidadeChamadaFEB = jsonObject.noLocalidadeChamadaFEB;
    this.noTipoXLS = jsonObject.noTipoXLS;
    this.valorXLS = jsonObject.valorXLS;
    this.vlrCobradoFEB = jsonObject.vlrCobradoFEB;
    this.nrSeqTipoChamadaAudit = jsonObject.nrSeqTipoChamadaAudit;
    this.duracaoXLSConv = jsonObject.duracaoXLSConv;
    this.nrSeqPortabilidadeOperadora = jsonObject.nrSeqPortabilidadeOperadora;
    this.cdContaChamada = jsonObject.cdContaChamada;
    this.flgExclusaoLogica = jsonObject.flgExclusaoLogica;
    this.duracaoChamada = jsonObject.duracaoChamada;
    this.cdFormaCobranca = jsonObject.cdFormaCobranca;
    this.noIDIntegracao = jsonObject.noIDIntegracao;
    this.nrSeqChipTronco = jsonObject.nrSeqChipTronco;
    this.noIDGrupoIntegracao = jsonObject.noIDGrupoIntegracao;
    this.nrCallType = jsonObject.nrCallType;
    this.nrSeqContaChamadaVinculo = jsonObject.nrSeqContaChamadaVinculo;
    this.vlrRateioEmpresa = jsonObject.vlrRateioEmpresa;
    this.nrSeqCategoriaLigacao = jsonObject.nrSeqCategoriaLigacao;
    this.noTipoChamada = jsonObject.noTipoChamada;

    this.setNumeroDestinoCompleto = jsonObject.setNumeroDestinoCompleto;

    this.contaRecurso = new ContaRecurso(jsonObject.contaRecurso ?? {});
    this.tipoChamada = new TipoChamada(jsonObject.tipoChamada ?? {});
    this.areaChamada = new AreaChamada(jsonObject.areaChamada ?? {});
    this.categoriaLigacao = new CategoriaLigacao(
      jsonObject.categoriaLigacao ?? {}
    );

    this.vlrTotalChamada = jsonObject.vlrTotalChamada;
    this.vlrTotalCobrado = jsonObject.vlrTotalCobrado;
    this.vlrTotalRecalculado = jsonObject.vlrTotalRecalculado;
    this.nrQuantidade = jsonObject.nrQuantidade ?? 0;
    this.vlrTotalLigacoesVc1 = jsonObject.vlrTotalLigacoesVc1 ?? '0';
    this.vlrTotalLigacoesVc2 = jsonObject.vlrTotalLigacoesVc2 ?? '0';
    this.vlrTotalLigacoesVc3 = jsonObject.vlrTotalLigacoesVc3 ?? '0';

    this.cdContratoDtFatura = jsonObject.cdContratoDtFatura;
    this.nrOrigemComp = jsonObject.nrOrigemComp;

    this.setNumeroDestinoCompleto = jsonObject.setNumeroDestinoCompleto;
  }

  get numeroDestinoCompleto() {
    if (this.nrLinhaDestino)
      return `(${this.nrPrefixoDestino}) ${this.nrLinhaDestino}`;
    return '';
  }

  get dtHoraChamada() {
    if (this.dtChamada) {
      const dtHora = new Date(this.dtChamada);

      if (this.horaChamada) {
        if (this.horaChamada.length > 0) {
          const [horas, minutos, segundos] = this.horaChamada
            .split(':')
            .map(Number);

          dtHora.setHours(horas, minutos, segundos);
        }
      }

      return dtHora;
    }
    return '';
  }

  toJson = () => JSON.stringify(this);
}
