class CentroDeCusto {
  constructor(jsonObject = {}) {
    this.nrSeqCentroDeCusto = jsonObject.nrSeqCentroDeCusto;
    this.noCentroDeCusto = jsonObject.noCentroDeCusto;
    this.cdCentroDeCusto = jsonObject.cdCentroDeCusto;
    this.flgAtivo = jsonObject.flgAtivo;
    this.flgSintetico = jsonObject.flgSintetico;
    this.nrSeqCentroDeCustoPai = jsonObject.nrSeqCentroDeCustoPai;
    this.noCentroDeCustoPai = jsonObject.noCentroDeCustoPai;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.grupoempresa = jsonObject.grupoEmpresas;
    this.noCaminhoCompleto = jsonObject.noCaminhoCompleto;
    this.cdCompleto = jsonObject.cdCompleto;
  }

  toJson = () => JSON.stringify(this);
}

export default CentroDeCusto;
