import qs from 'qs';
import Pagination from 'core/models/pagination';
import Fatura from 'core/models/FIN/fatura';
import api from '../api';

const baseURL = `/FIN/Fatura`;

export const printDocumentsOrder = async (filters) => {
  const response = await api.get(`${baseURL}/PrintDocumentsOrder`, {
    params: filters,
  });
  return response.data ?? {};
};

export const getFaturaList = async (filters) => {
  const response = await api.get(`/$Fatura/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Fatura(json)) ?? [],
  };
};

export const getFaturaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Fatura(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getFatura = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Fatura(value) : {};
};

export const saveFatura = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const postValidateOcorrenciaBoleto = async (data) => {
  const response = await api.post(`${baseURL}/ValidateOccurrence`, data);
  return response.data ?? {};
};

export const postValidateAlteraVencimentoDebito = async (data) => {
  const response = await api.post(`${baseURL}/ValidateUpdateDueDate`, data);
  return response.data ?? {};
};

export const postUpdateVencimentoDebito = async (data) => {
  const response = await api.post(`${baseURL}/UpdateDueDate`, data);
  return response.data ?? {};
};

export const postSaveOcorrencia = async (data) => {
  const response = await api.post(`${baseURL}/RegisterOccurrence`, data);
  return response.data ?? {};
};

export const deleteFatura = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printFatura = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelFatura = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllFatura = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
