import React, { useState, useEffect } from 'react';
import { Autocomplete, DatePicker, Textbox, Button } from 'ui/components';
import { getPneuAutoComplete } from 'core/services/FRO/pneu';
import { Theme } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import styles from './index.module.css';

function newDateFormat() {
  const newDate = new Date();
  const day = newDate.getDate().toString().padStart(2, '0');
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const year = newDate.getFullYear();
  return `${year}-${month}-${day}T00:00:00.0`;
}

export default function Properties({
  data,
  includeTire,
  removeTire,
  waste,
  retread,
  selectedTires,
  tireRotation,
  vehicleInformation = {},
  nrSeqProduto,
  // onMessage,
}) {
  const [current, setCurrent] = useState({ flgMostrarSulcagem: false });
  // const [sulcagem, setSulcagem] = useState({});

  useEffect(() => {
    const { nrOdometro } = vehicleInformation;
    data.dtFim = newDateFormat();
    setCurrent(data);
    if (selectedTires.length === 2) data.kmRodizio = nrOdometro;
    else if (!data.nrSeqPneuVeiculo) data.kmInicio = nrOdometro;
    else if (data.nrSeqPneuVeiculo) {
      data.kmFim = nrOdometro;
      data.kmAcumulado = data.kmFim - data.kmInicio;
    }
  }, [data.nrOrdem]);

  useEffect(() => {}, [data.nrOdometro]);

  const onAutoCompletePneu = async (e) => {
    const { data: pneus } = await getPneuAutoComplete({
      cdPneu: e,
      flgDisponivel: true,
      nrSeqProduto,
    });

    return pneus;
  };

  return (
    <div className={styles.properties}>
      {!current.flgMostrarSulcagem && selectedTires.length === 1 && (
        <div className={styles.tire_properties}>
          <div className='row'>
            <div className='col-12'>
              <Autocomplete
                label='Pneu'
                searchDataSource={onAutoCompletePneu}
                selectedItem={current.pneu}
                readOnly={data.nrSeqPneuVeiculo}
                onSelectItem={(pneu) =>
                  setCurrent({
                    ...current,
                    pneu,
                    nrSeqPneu: pneu.nrSeqPneu,
                  })
                }
                dataSourceTextProperty='cdPneu'
              />
            </div>
          </div>

          {!data.nrSeqPneuVeiculo > 0 && (
            <div className='row mt-3'>
              <div className='col-12'>
                <DatePicker
                  label='Data Início'
                  text={current.dtInicio}
                  mask={MaskTypes.Date}
                  onChange={(dtInicio) => setCurrent({ ...current, dtInicio })}
                />
              </div>
            </div>
          )}

          {data.nrSeqPneuVeiculo > 0 && (
            <div className='row mt-3'>
              <div className='col-12'>
                <Textbox
                  label='Vida do Pneu'
                  readOnly
                  text={current?.pneu?.pneuVida?.noPneuVida}
                />
              </div>
            </div>
          )}
          {data.nrSeqPneuVeiculo > 0 && (
            <div className='row mt-3'>
              <div className='col-12'>
                <DatePicker
                  label='Data Fim'
                  text={current.dtFim}
                  mask={MaskTypes.Date}
                  onChange={(dtFim) => setCurrent({ ...current, dtFim })}
                />
              </div>
            </div>
          )}

          <div className='row mt-3'>
            <div className='col-12'>
              <Textbox
                label='Km Início'
                mask={MaskTypes.Integer}
                readOnly={data.nrSeqPneuVeiculo}
                text={current.kmInicio}
                onChangedValue={(kmInicio) => {
                  setCurrent({
                    ...current,
                    kmInicio,
                  });
                }}
              />
            </div>
          </div>

          {data.nrSeqPneuVeiculo > 0 && (
            <div className='row mt-3'>
              <div className='col-12'>
                <Textbox
                  label='Km Final'
                  mask={MaskTypes.Integer}
                  text={current.kmFim}
                  readOnly={!data.nrSeqPneuVeiculo}
                  onChangedValue={(kmFim) => {
                    setCurrent({
                      ...current,
                      kmFim,
                      kmAcumulado: kmFim - data.kmInicio,
                    });
                  }}
                />
              </div>
            </div>
          )}

          {data.nrSeqPneuVeiculo > 0 && (
            <div className='row mt-3'>
              <div className='col-12'>
                <Textbox
                  label='Km Acumulado'
                  mask={MaskTypes.Integer}
                  text={current.kmAcumulado}
                  readOnly
                  onChangedValue={(kmAcumulado) => {
                    setCurrent({
                      ...current,
                      kmAcumulado,
                    });
                  }}
                />
              </div>
            </div>
          )}

          {!data.nrSeqPneuVeiculo > 0 && (
            <div className='row mt-3'>
              <div className='col'>
                <Button
                  text='Vincular Pneu'
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  icon='save'
                  className={styles.actionButton}
                  onClick={() => {
                    includeTire(current);
                  }}
                />
              </div>
            </div>
          )}

          {data.nrSeqPneuVeiculo > 0 && (
            <>
              <div className='row mt-3'>
                <div className='col'>
                  <Button
                    text='Desvincular Pneu'
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    icon='save'
                    className={styles.actionButton}
                    onClick={() => {
                      removeTire({ ...current });
                    }}
                  />
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col'>
                  <Button
                    text='Recapagem'
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    icon='save'
                    className={styles.actionButton}
                    onClick={() => {
                      retread({ ...current });
                    }}
                  />
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col'>
                  <Button
                    text='Sucata'
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    icon='save'
                    className={styles.actionButton}
                    onClick={() => {
                      waste({ ...current });
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {!current.flgMostrarSulcagem && selectedTires.length === 2 && (
        <div className={styles.rotation_properties}>
          <>
            <div className='row mt-3'>
              <div className='col-12'>
                <DatePicker
                  label='Data Rodizio'
                  text={current.dtRodizio}
                  mask={MaskTypes.Date}
                  onChange={(dtRodizio) =>
                    setCurrent({ ...current, dtRodizio })
                  }
                />
              </div>
            </div>

            <div className='row mt-3'>
              <div className='col-12'>
                <Textbox
                  label='Km Rodízio'
                  mask={MaskTypes.Integer}
                  text={current.kmRodizio}
                  onChangedValue={(kmRodizio) => {
                    setCurrent({
                      ...current,
                      kmRodizio,
                    });
                  }}
                />
              </div>
            </div>

            <div className='row mt-3 '>
              <div className='col'>
                <Button
                  text='Realizar Rodízio'
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  icon='save'
                  className={styles.actionButton}
                  onClick={() => {
                    tireRotation({
                      pneuA: selectedTires[0],
                      pneuB: selectedTires[1],
                      nrSeqPneuMotivoTroca: current.nrSeqPneuMotivoTroca,
                      dtRodizio: current.dtRodizio,
                      kmRodizio: current.kmRodizio,
                    });
                  }}
                />
              </div>
            </div>
          </>
        </div>
      )}
    </div>
  );
}
