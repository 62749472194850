import React from 'react';
import ChequeBancoDoBrasil from './modelos/chequeBancoDoBrasil';
import ChequeBanrisul from './modelos/chequeBanrisul';
import ChequeCaixa from './modelos/chequeCaixa';
import ChequeSicredi from './modelos/chequeSicredi';
import ChequeSicoob from './modelos/chequeSicoob';
import './cheque.css';

const ModeloCheque = React.forwardRef((props, ref) => {
  const { model } = props;

  if (model.contaCorrente) {
    if (model.contaCorrente.noModeloCheque === 'chequeBancoDoBrasil') {
      return (
        <div ref={ref}>
          <ChequeBancoDoBrasil model={model} />
        </div>
      );
    }
    if (model.contaCorrente.noModeloCheque === 'chequeCaixa') {
      return (
        <div ref={ref}>
          <ChequeCaixa model={model} />
        </div>
      );
    }

    if (model.contaCorrente.noModeloCheque === 'chequeSicredi') {
      return (
        <div ref={ref}>
          <ChequeSicredi model={model} />
        </div>
      );
    }

    if (model.contaCorrente.noModeloCheque === 'chequeSicoob') {
      return (
        <div ref={ref}>
          <ChequeSicoob model={model} />
        </div>
      );
    }
    return (
      <div ref={ref}>
        <ChequeBanrisul model={model} />
      </div>
    );
  }
  return <div />;
});

export default ModeloCheque;
