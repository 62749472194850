import Banco from './banco';

export default class Agencia {
  constructor(jsonObject = {}) {
    this.nrSeqBanco = jsonObject.nrSeqBanco;
    this.nrSeqAgencia = jsonObject.nrSeqAgencia;
    this.cdAgencia = jsonObject.cdAgencia;
    this.noAgencia = jsonObject.noAgencia;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.cdAgenciaDig = jsonObject.cdAgenciaDig;

    this.banco = new Banco(jsonObject.banco ?? {});
  }

  get cdAgenciaFormatted() {
    if (this.cdAgenciaDig || this.cdAgenciaDig === 0)
      return `${this.cdAgencia}-${this.cdAgenciaDig}`;

    return `${this.cdAgencia}`;
  }

  toJson = () => JSON.stringify(this);
}
