import React, { forwardRef, useState, useRef, useEffect } from 'react';
import {
  Autocomplete,
  Button,
  GridView,
  Textbox,
  Panel,
  RadioButton,
  Switch,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';

import { getConvenioAutoComplete } from 'core/services/ASS/convenio';
import { getVendedorAutoComplete } from 'core/services/VEN/vendedor';

const OutrosStep = forwardRef(({ data, setData, onSetMessage }) => {
  const gridViewVendedor = useRef();

  const [dadosTributos, setDadosTributos] = useState({});
  const [vendedorAssociado, setVendedorAssociado] = useState({});

  const btnTipoVendedor = [
    { text: 'Vendedor', value: 'VEN' },
    { text: 'Agenciador', value: 'AGE' },
  ];

  useEffect(() => {
    gridViewVendedor?.current?.setDataSource(data.vendedoresAssociado ?? []);
  }, [data.nrSeqPessoaAss]);

  const onSearchConvenio = async (e) => {
    const { convenios } = await getConvenioAutoComplete({
      noConvenio: e,
    });
    return convenios;
  };

  const onSearchVendedor = async (e) => {
    const { status, message: msg, vendedores } = await getVendedorAutoComplete({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return vendedores;
  };

  const onClickAddVendedorAss = async () => {
    if (vendedorAssociado.nrSeqPessoaVen) {
      const vendedorAss = {
        ...vendedorAssociado,
        nrSeqConvenio: vendedorAssociado.nrSeqConvenio || null,
        status:
          vendedorAssociado.status !== GridView.EnumStatus.Alterar
            ? GridView.EnumStatus.Inserir
            : GridView.EnumStatus.Alterar,
      };

      const listaVendedores = gridViewVendedor?.current?.getDataSource() ?? [];

      if (
        vendedorAssociado.tipoVendedor &&
        vendedorAssociado.tipoVendedor === 'AGE'
      ) {
        if (
          listaVendedores.some(
            (p) =>
              p.status !== GridView.EnumStatus.Remover &&
              p.tipoVendedor &&
              p.tipoVendedor === 'AGE'
          )
        ) {
          return onSetMessage(
            false,
            'Já existe um agenciador vinculado para o Associado.'
          );
        }
      }

      if (
        listaVendedores.some(
          (p) =>
            p.status !== GridView.EnumStatus.Remover &&
            p.tipoVendedor &&
            p.tipoVendedor === 'VEN' &&
            p.nrSeqConvenio === vendedorAss.nrSeqConvenio
        )
      ) {
        return onSetMessage(
          false,
          'Já existe um vendedor vinculado para o Associado.'
        );
      }

      vendedorAss.tipoVendedor = vendedorAss.tipoVendedor || 'VEN';

      listaVendedores.push(vendedorAss);

      if (gridViewVendedor && gridViewVendedor.current)
        gridViewVendedor.current.setDataSource(listaVendedores);

      setData({ ...data, vendedoresAssociado: listaVendedores });

      setVendedorAssociado({});
    }
    return 0;
  };

  const onClickEditVendedorAss = async (selectedValue, datasource) => {
    const listaVendedoresAss = datasource.filter(
      (item) => item !== selectedValue
    );

    setVendedorAssociado({
      ...selectedValue,
      status:
        selectedValue.status !== GridView.EnumStatus.Inserir
          ? GridView.EnumStatus.Alterar
          : GridView.EnumStatus.Inserir,
    });

    if (gridViewVendedor && gridViewVendedor.current)
      gridViewVendedor.current.setDataSource(listaVendedoresAss);
  };

  const onClickRemoveVendedorAss = async (e, datasource) => {
    const listaVendedoresAss = datasource.filter((el) => el !== e);

    if (e.status !== GridView.EnumStatus.Inserir) {
      e.status = GridView.EnumStatus.Remover;

      listaVendedoresAss.push(e);
    }

    if (gridViewVendedor && gridViewVendedor.current)
      gridViewVendedor.current.setDataSource(listaVendedoresAss);

    setData({ ...data, vendedoresAssociado: listaVendedoresAss });
  };

  const columnsVendedor = [
    { key: 'nrSeqPessoaVen', type: GridView.ColumnTypes.Checkbox },
    { key: 'noVendedor', title: 'Nome', sortable: false },
    {
      key: 'tipoVendedor',
      title: 'Tipo Vendedor',
      sortable: false,
    },
    {
      key: 'vlrPercentual',
      title: 'Percentual',
      sortable: false,
    },
    { key: 'convenio.noConvenio', title: 'Convênio/Ativo', sortable: false },

    {
      key: 'nrSeqPessoaVen',
      type: GridView.ColumnTypes.Button,
      title: 'Editar',
      onClick: (e, dataSource) => onClickEditVendedorAss(e, dataSource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqPessoaVen',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemoveVendedorAss(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <div>
      <div className='row mt-3'>
        <Panel>
          <Panel.Header title='Vendedor' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3 algin-items-end'>
              <div className='col-2'>
                <RadioButton
                  outline
                  label='*Tipo Vendedor'
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  buttons={btnTipoVendedor}
                  selectedButton={vendedorAssociado?.tipoVendedor}
                  onChange={(tipoVendedor) =>
                    setVendedorAssociado({
                      ...vendedorAssociado,
                      tipoVendedor,
                    })
                  }
                />
              </div>

              <div className='col-3'>
                <Autocomplete
                  label='Vendedor'
                  searchDataSource={onSearchVendedor}
                  selectedItem={vendedorAssociado?.vendedor}
                  onSelectItem={(vendedor) =>
                    setVendedorAssociado({
                      ...vendedorAssociado,
                      vendedor,
                      nrSeqPessoaVen: vendedor.nrSeqPessoa,
                      noVendedor: vendedor.noPessoa,
                    })
                  }
                  dataSourceTextProperty='noPessoa'
                />
              </div>

              <div className='col-2'>
                <Textbox
                  label='Percentual'
                  maxLength={10}
                  mask={MaskTypes.Decimal}
                  text={vendedorAssociado?.vlrPercentual}
                  onChangedValue={(vlrPercentual) =>
                    setVendedorAssociado({
                      ...vendedorAssociado,
                      vlrPercentual,
                    })
                  }
                />
              </div>

              <div className='col-3'>
                <Autocomplete
                  label='Convênio'
                  searchDataSource={onSearchConvenio}
                  selectedItem={vendedorAssociado?.convenio}
                  onSelectItem={(convenio) =>
                    setVendedorAssociado({
                      ...vendedorAssociado,
                      convenio,
                      nrSeqConvenio: convenio.nrSeqConvenio,
                    })
                  }
                  dataSourceTextProperty='noConvenio'
                />
              </div>
              <div className='col-2 mt-3'>
                <Button
                  onClick={onClickAddVendedorAss}
                  outline
                  theme={Theme.Success}
                  template={Button.Templates.Button}
                  text='Adicionar'
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>

      <div className='row mt-3'>
        <div className='col'>
          <GridView
            ref={gridViewVendedor}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columnsVendedor}
            offlineData
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>

      <div className='row mt-3'>
        <Panel>
          <Panel.Header title='Tributos' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3 algin-items-end'>
              <div className='col-2'>
                <Switch
                  formControl
                  checked={dadosTributos.flgContrICMS}
                  label='Contribuinte ICMS'
                  onChange={(flgContrICMS) =>
                    setDadosTributos({
                      ...dadosTributos,
                      flgContrICMS,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Switch
                  formControl
                  checked={dadosTributos.flgAgro}
                  label='Ramo Agropecuário'
                  onChange={(flgAgro) =>
                    setDadosTributos({
                      ...dadosTributos,
                      flgAgro,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Switch
                  formControl
                  checked={dadosTributos.flgEnviaContabilidade}
                  label='Envia para Contabilidade'
                  onChange={(flgEnviaContabilidade) =>
                    setDadosTributos({
                      ...dadosTributos,
                      flgEnviaContabilidade,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Switch
                  formControl
                  checked={dadosTributos.flgSubstitutoTributario}
                  label='Substituto Tributário'
                  onChange={(flgSubstitutoTributario) =>
                    setDadosTributos({
                      ...dadosTributos,
                      flgSubstitutoTributario,
                    })
                  }
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
});

export default OutrosStep;
