import React from 'react';

interface Props {
  items: Array<React.ReactNode>;
  light?: boolean;
  indicators?: boolean;
  interval?: Number;
}

const Carousel: React.FC<Props> = ({
  items = [],
  light = false,
  indicators = false,
  interval,
}) => {
  const configItems = (item: React.ReactNode, index: Number) => {
    let classNameItems: string = 'carousel-item';

    if (index === 0) classNameItems += ' active';

    if (React.isValidElement(item)) {
      const originalClassNameItem = item.props.className;
      const newClassNameItem = `${originalClassNameItem} mb-4`;

      item = React.cloneElement(item, {
        ...item.props,
        className: newClassNameItem,
      });
    }

    return (
      <div className={classNameItems}>
        <div className='row justify-content-center mb-4'>{item}</div>
      </div>
    );
  };

  const configIndicators = (item: React.ReactNode, index: Number) => {
    let indicator: React.ReactNode;

    if (index === 0) {
      indicator = (
        <button
          type='button'
          data-bs-target='#carousel'
          data-bs-slide-to={index}
          className='active'
          aria-current='true'
          aria-label={`Slide ${index.valueOf() + 1}`}
        />
      );
    } else {
      indicator = (
        <button
          type='button'
          data-bs-target='#carousel'
          data-bs-slide-to={index}
          aria-label={`Slide ${index.valueOf() + 1}`}
        />
      );
    }

    return indicator;
  };

  return (
    <div className='row'>
      {items.length > 0 && (
        <div className='col'>
          <div
            id='carousel'
            className={
              light ? 'carousel slide' : 'carousel carousel-dark slide'
            }
            data-bs-interval={interval ?? 'false'}
          >
            {indicators && (
              <div className='carousel-indicators'>
                {items.map((item: React.ReactNode, index: Number) =>
                  configIndicators(item, index)
                )}
              </div>
            )}
            <div className='carousel-inner'>
              {items.map((item: React.ReactNode, index: Number) =>
                configItems(item, index)
              )}
            </div>
            <button
              className='carousel-control-prev'
              type='button'
              data-bs-target='#carousel'
              data-bs-slide='prev'
            >
              <span className='carousel-control-prev-icon' aria-hidden='true' />
              <span className='visually-hidden'>Previous</span>
            </button>
            <button
              className='carousel-control-next'
              type='button'
              data-bs-target='#carousel'
              data-bs-slide='next'
            >
              <span className='carousel-control-next-icon' aria-hidden='true' />
              <span className='visually-hidden'>Next</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Carousel;
