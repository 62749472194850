import Almoxarifado from 'core/models/EST/almoxarifado';

export default class OficinaAlmoxarifado {
  constructor(jsonObject = {}) {
    this.nrSeqPessoaOfi = jsonObject.nrSeqPessoaOfi;
    this.nrSeqAlmoxarifado = jsonObject.nrSeqAlmoxarifado;
    this.almoxarifado = new Almoxarifado(jsonObject.almoxarifado ?? {});
  }

  toJson = () => JSON.stringify(this);
}
