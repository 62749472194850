import React, { useEffect, useRef, useState } from 'react';
import { CSDSelPage, GridView, Textbox } from 'ui/components';
import { PageTypes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { ColumnTypes, Theme, ResponseStatus } from 'ui/Helpers/enums.ts';
// @ts-expect-error
import { FiltersProfissao } from 'core/interfaces/SEG/profissao.ts';
import {
  getProfissaoList,
  printProfissoes,
  deleteProfissoes,
  // @ts-expect-error
} from 'core/services/SEG/profissaoTs.ts';

const columns: Array<GridviewColumns> = [
  { key: 'nrSeqProfissao', type: ColumnTypes.Checkbox },
  { key: 'cdProfissao', title: 'Código/CBO' },
  { key: 'noProfissao', title: 'Descrição' },
];

export default function Profissao({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}: Page) {
  const gridView = useRef<any>();

  const [filtros, setFiltros] = useState<FiltersProfissao | any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const search = async (): Promise<void> => {
    setLoading(true);

    const { profissoes, pagination } = await getProfissaoList(filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(profissoes, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load(): Promise<void> {
      setLoading(true);

      const { profissoes, pagination } = await getProfissaoList({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(profissoes, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (SortBy: number): Promise<void> => {
    setFiltros({ ...filtros, SortBy });
    await search();
  };
  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    setFiltros({ ...filtros, totalByPage });
    await search();
  };
  const onPageChange = async (page: any): Promise<void> => {
    setFiltros({ ...filtros, page });
    await search();
  };
  const onOpenMaintenance = (registryKey = null): void => {
    onOpenPage(
      transaction.noTransacao,
      'Profissao/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow: any): void => {
    const nrSeqProfissao = sourceRow.find(
      (e: any) => e.key === 'nrSeqProfissao'
    );
    onOpenMaintenance(nrSeqProfissao.value);
  };

  const onPrint = async (): Promise<void> => {
    setLoading(true);
    const { value } = await printProfissoes(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteProfissoes(
        selecteds.map((row: any) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Profissões'
      loading={loading}
      onSearch={search}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-6'>
          {/* @ts-expect-error */}
          <Textbox
            label='Descricao'
            text={filtros.noProfissao}
            onChangedValue={(noProfissao: string) => {
              setFiltros({ ...filtros, noProfissao });
            }}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            onColumnSort={onColumnSort}
            enableExcelExport
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
