import React, { useEffect, useRef, useState } from 'react';
// import { MaskTypes } from 'ui/Helpers/masks';
//  @ts-expect-error;
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces.ts';
//  @ts-expect-error;
import { ColumnDataTypes, Theme, ResponseStatus } from 'ui/Helpers/enums.ts';

import {
  CSDSelPage,
  DatePicker,
  GridView,
  Panel,
  Switch,
  DropdownMulti,
} from 'ui/components';
import { getAgrupamentoFinanceiroList } from 'core/services/FIN/agrupamentoFinanceiro';
import {
  getCurrentBalance,
  getFinancialForecast,
  print,
} from 'core/services/FIN/previsaoFinanceira';
import AgrupamentoFinanceiro from 'core/models/FIN/agrupamentoFinanceiro';
import { PageTypes } from 'ui/Helpers/utils';
import PanelResumo from './panelResumo';

export default function PrevisaoFinanceira({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}: Page) {
  interface IFilters {
    agrupamentoFinanceiroIds: Array<number>;
    dataInicial: string;
    dataFinal: string;
    flgSaldoVisivel: boolean;
    flgAcumulaSaldo: boolean;
    sortBy?: string;
  }

  // #region USESTATE, USEEFFECT E USEREF
  const [filters, setFilters] = useState<IFilters>({
    agrupamentoFinanceiroIds: [],
    dataInicial: ' ',
    dataFinal: ' ',
    flgSaldoVisivel: false,
    flgAcumulaSaldo: true,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [selectedItems, setSelectedItems] = useState<any>({});
  const [resumoValoresEmAberto, setResumoValoresEmAberto] = useState<any>({});

  const gridView = useRef<any>();

  const gridViewBalancePerDay = useRef<any>();

  const dropDownAgrupamentoFinanceiro = useRef<any>();

  // #endregion

  // #region MESSAGE
  const onSetMessage = (status: any, msg: string) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  // #endregion

  // #region COLUMNS
  const columnsGridBalance: Array<GridviewColumns> = [
    {
      key: 'data',
      title: 'Data',
      format: ColumnDataTypes.Date,
      sortable: false,
    },
    {
      key: 'contaCorrente.noContaCorrente',
      title: 'Conta Corrente',
      sortable: false,
    },

    {
      key: 'valor',
      title: 'Saldo',
      format: ColumnDataTypes.Money,
      colorText: 'corSaldo',
    },
  ];

  const columnsGridFinancialForecast: Array<GridviewColumns> = [
    {
      key: 'data',
      visible: false,
    },
    {
      key: 'data',
      title: 'Data',
      format: ColumnDataTypes.Date,
    },
    {
      key: 'vlrTituloPagar',
      title: 'Pagar',
      format: ColumnDataTypes.Money,
      colorText: 'corDespesa',
    },
    {
      key: 'vlrTituloReceber',
      title: 'Receber',
      format: ColumnDataTypes.Money,
      colorText: 'corReceita',
    },
    {
      key: 'valor',
      title: 'Saldo',
      format: ColumnDataTypes.Money,
      colorText: 'corSaldo',
    },
  ];

  // #endregion

  // #region SEARCH FUNCTIONS

  const getSaldoAtual = (listaValoresconta: any): number => {
    let valorSaldoAtual = 0;
    if (listaValoresconta && listaValoresconta.length > 0) {
      valorSaldoAtual = listaValoresconta.reduce(
        (acc: number, cur: any) => acc + cur.valor,
        0
      );
    }
    return valorSaldoAtual;
  };

  const searchBalance = async (): Promise<any> => {
    const { data: listaSaldo, status, message: msg } = await getCurrentBalance(
      filters
    );

    if (msg) {
      onSetMessage(status, msg);
      return [];
    }
    listaSaldo.forEach((element: any) => {
      element.corSaldo = element.valor > 0 ? 'text-primary' : 'text-danger';
    });
    return listaSaldo;
  };

  const updateListFinancialForecast = async (
    valoresPrevistos: any,
    resumo: any,
    flgAcumulaSaldo: boolean,
    saldo?: number,
    flgSaldoVisivel?: boolean
  ) => {
    if (valoresPrevistos && resumo) {
      let acumulado = 0;
      const vlrSaldovisivel = flgSaldoVisivel ? saldo : 0;

      const vlrDiaAnteriorPrevisao = flgAcumulaSaldo
        ? resumo.valor + vlrSaldovisivel
        : vlrSaldovisivel; // 0

      valoresPrevistos.unshift({
        data: resumo.data,
        valor: vlrDiaAnteriorPrevisao || 0,
        corSaldo:
          vlrDiaAnteriorPrevisao > 0 ? 'text-primary bold' : 'text-danger bold',
        vlrTituloReceber: flgAcumulaSaldo ? resumo.vlrTituloReceber : 0,
        vlrTituloPagar: flgAcumulaSaldo ? resumo.vlrTituloPagar : 0,
        corDespesa: 'text-danger',
        corReceita: 'text-primary',
      });

      valoresPrevistos.forEach((element: any, index: number) => {
        if (index === 0) {
          acumulado += vlrDiaAnteriorPrevisao;
        } else {
          acumulado += element.valor;
          element.valor = acumulado;
          element.corSaldo = acumulado > 0 ? 'text-primary' : 'text-danger';
          element.corDespesa = 'text-danger';
          element.corReceita = 'text-primary';
        }
      });
    }
    return valoresPrevistos;
  };

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'PrevisaoFinanceira/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow: any) => {
    const dataPrevisao = sourceRow.find((e: any) => e.key === 'data');
    onOpenMaintenance(dataPrevisao.value);
  };

  useEffect(() => {
    setResumoValoresEmAberto({
      ...resumoValoresEmAberto,
      saldo: filters?.flgSaldoVisivel ? resumoValoresEmAberto.saldo : 0,
    });
  }, [filters?.flgSaldoVisivel]);

  const search = async (): Promise<void> => {
    if (!loading) {
      setLoading(true);

      let listaSaldo = [];
      if (
        filters.agrupamentoFinanceiroIds &&
        filters.agrupamentoFinanceiroIds.length > 0
      )
        listaSaldo = await searchBalance();

      const { data: valoresPrevistos, resumo } = await getFinancialForecast(
        filters
      );

      gridView?.current?.setDataSource(listaSaldo ?? []);
      const resultadoSaldo = getSaldoAtual(listaSaldo);

      setResumoValoresEmAberto({
        ...resumo,
        saldo: filters.flgSaldoVisivel ? resultadoSaldo : 0,
        flgSaldoVisivel: filters.flgSaldoVisivel,
      });

      const valoresPrevistosSaldoAcumulado = await updateListFinancialForecast(
        valoresPrevistos,
        resumo,
        filters.flgAcumulaSaldo,
        resultadoSaldo,
        filters.flgSaldoVisivel
      );

      gridViewBalancePerDay?.current?.setDataSource(
        valoresPrevistosSaldoAcumulado ?? []
      );
    }
    setLoading(false);
  };

  const onSearchAgrupamentoFinanceiro = async (
    e?: string
  ): Promise<Array<AgrupamentoFinanceiro>> => {
    const { data: agrupamentoFinanceiro } = await getAgrupamentoFinanceiroList({
      noAgrupamentoFinanceiro: e,
    });
    return agrupamentoFinanceiro;
  };

  // #endregion

  // #region USEEFFECTS
  useEffect(() => {
    dropDownAgrupamentoFinanceiro?.current.loadDataSource(
      onSearchAgrupamentoFinanceiro
    );
  }, []);

  // #endregion

  // #region PRINT FUNCTIONS

  const onPrint = async () => {
    setLoading(true);
    const { value, message: msg } = await print(filters);

    if (value) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(false, msg || 'Erro ao Imprimir Relatório');
    }

    setLoading(false);
  };

  // #endregion

  return (
    <CSDSelPage
      isActive={isActive}
      title='Previsão Financeira'
      loading={loading}
      onSearch={() => search()}
      message={message}
      onMessagerClose={() => setMessage(null)}
      onPrint={onPrint}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-ignore */}
          <Panel>
            {/* @ts-ignore */}
            <Panel.Header
              icon={['fas', 'user']}
              title='Filtros'
              theme={Theme.Primary}
            />
            <Panel.Body>
              <div className='row justify-content-start'>
                <div className='row mb-3'>
                  <div className='col-2'>
                    {/* @ts-ignore */}
                    <DatePicker
                      label='Data Inicial: '
                      text={filters.dataInicial}
                      onChange={(dataInicial: any) =>
                        setFilters({ ...filters, dataInicial })
                      }
                    />
                  </div>
                  <div className='col-6'>
                    {/* @ts-ignore */}
                    <DropdownMulti
                      label='Grupos'
                      ref={dropDownAgrupamentoFinanceiro}
                      dataSourcePropertyText='noAgrupamentoFinanceiro'
                      dataSourcePropertyValue='nrSeqAgrupamentoFinanceiro'
                      selectedItems={selectedItems.agrupamentoFinanceiro ?? []}
                      onSelectItem={(
                        agrupamentoFinanceiro: Array<AgrupamentoFinanceiro>
                      ) => {
                        setSelectedItems({
                          ...selectedItems,
                          agrupamentoFinanceiro,
                        });
                        const ids = agrupamentoFinanceiro.map(
                          (el: AgrupamentoFinanceiro) =>
                            Number(el.nrSeqAgrupamentoFinanceiro)
                        );
                        setFilters({
                          ...filters,
                          agrupamentoFinanceiroIds: ids,
                          flgSaldoVisivel: ids.length > 0,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-2'>
                    {/* @ts-ignore */}
                    <DatePicker
                      label='Data Final: '
                      text={filters.dataFinal}
                      onChange={(dataFinal: any) =>
                        setFilters({ ...filters, dataFinal })
                      }
                    />
                  </div>
                  <div className='col-1'>
                    {/* @ts-ignore */}
                    <Switch
                      formControl
                      label='Saldo'
                      checked={filters.flgSaldoVisivel}
                      onChange={(flgSaldoVisivel: boolean) =>
                        setFilters({
                          ...filters,
                          flgSaldoVisivel,
                          flgAcumulaSaldo: flgSaldoVisivel
                            ? filters.flgAcumulaSaldo
                            : false,
                        })
                      }
                    />
                  </div>
                  <div className='col'>
                    {/* @ts-ignore */}
                    <Switch
                      formControl
                      label='Acumula Saldo de Contas em Previsão'
                      checked={filters.flgAcumulaSaldo}
                      onChange={(flgAcumulaSaldo: boolean) =>
                        setFilters({
                          ...filters,
                          flgAcumulaSaldo,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      {filters?.flgSaldoVisivel && (
        <div className='row mt-3'>
          {/* @ts-ignore */}
          <Panel>
            {/* @ts-ignore */}
            <Panel.Header
              title='Saldo em conta na data inicial'
              theme={Theme.Primary}
            />

            <Panel.Body>
              <div className='row mt-2 mb-3'>
                <div className='col'>
                  <GridView
                    ref={gridView}
                    // @ts-expect-error
                    dataColumns={columnsGridBalance}
                    showPagination={false}
                    showSelectSizes={false}
                    offlineData
                    sumFields
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      )}
      {filters.flgAcumulaSaldo && (
        <div className='row mt-4'>
          <div className='col mb-4'>
            <PanelResumo resumo={resumoValoresEmAberto} />
          </div>
        </div>
      )}

      <div className='row mt-3'>
        {/* @ts-ignore */}
        <Panel>
          {/* @ts-ignore */}
          <Panel.Header
            title='Previsão financeira por data'
            theme={Theme.Primary}
          />
          <Panel.Body>
            <div className='row mt-2 mb-3'>
              <div className='col '>
                <GridView
                  ref={gridViewBalancePerDay}
                  // @ts-expect-error
                  dataColumns={columnsGridFinancialForecast}
                  showPagination={false}
                  showSelectSizes={false}
                  onRowDoubleClick={onRowDoubleClick}
                  offlineData
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    </CSDSelPage>
  );
}
