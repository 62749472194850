import React, { useState, useEffect } from 'react';
import { Button } from 'ui/components';
import './tree.css';

const Tree = ({
  label,
  data = [],
  onChangedValue,
  selectedKey,
  justRoot = true,
  borderStyle,
}) => {
  let dataFiltered = data;
  if (justRoot) dataFiltered = data.filter((item) => item.root);
  const border = borderStyle === undefined ? 'tree' : borderStyle;

  return (
    <div>
      <p className='treeLabel'>{label}</p>
      <ul className={border}>
        {dataFiltered.map((item) => (
          <TreeNode
            node={item}
            onChangedValue={(e) => {
              onChangedValue(e);
            }}
            selectedKey={selectedKey}
          />
        ))}
      </ul>
    </div>
  );
};

function TreeNode({ node, onChangedValue, selectedKey }) {
  const [childVisible, setChildVisible] = useState(true);
  const [icon, setIcon] = useState('angle-down');
  const [color, setColor] = useState('icon-color-black');
  const [markColor, setMarkColor] = useState('markColorBlue');
  const hasChild = node.children;

  useEffect(() => {
    if (!hasChild.length > 0) {
      setIcon('angle-up');
      setColor('icon-color-gray');
    }
  }, [hasChild]);

  const handleToggle = () => {
    if (hasChild.length > 0) {
      setChildVisible((v) => !v);
      setIcon((v) => (v === 'angle-right' ? 'angle-down' : 'angle-right'));
    }
  };

  return (
    <li key={node.label} className='itemList'>
      <div className='itemList__div'>
        <div className='itemButtonLabel'>
          {hasChild.length > 0 ? (
            <Button
              outline
              template={Button.Templates.Button}
              icon={icon}
              color={color}
              role='button'
              onClick={handleToggle}
            />
          ) : (
            <Button
              outline
              template={Button.Templates.Button}
              icon={icon}
              color={color}
              role='button'
              onClick={handleToggle}
            />
          )}

          {node.key === selectedKey ? (
            <mark className={markColor}>
              <b
                role='button'
                tabIndex={0}
                onClick={() => {
                  if (node.key === null) {
                    onChangedValue(node.key);
                  }

                  if (node.key !== null) {
                    onChangedValue(null);
                  }
                }}
                onKeyPress={node.handleKeyPress}
              >
                {node.label.toLowerCase()}
              </b>
            </mark>
          ) : (
            <div
              role='button'
              tabIndex={0}
              onClick={() => {
                onChangedValue(node.key);
                setMarkColor('markColorBlue');
              }}
              onKeyPress={node.handleKeyPress}
            >
              {node.label.toLowerCase()}
            </div>
          )}
        </div>

        <div className='itemChildrens'>
          {hasChild.length >= 1 && childVisible ? (
            <ul>
              <Tree
                borderStyle='treeChildrens'
                data={node.children}
                selectedKey={selectedKey}
                onChangedValue={(e) => {
                  onChangedValue(e);
                }}
                justRoot={false}
                onClick={handleToggle}
              />
            </ul>
          ) : (
            <></>
          )}
        </div>
      </div>
    </li>
  );
}

export default Tree;
