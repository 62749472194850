export default class TituloPagarTipoDocumento {
  constructor(jsonObject = {}) {
    this.nrSeqTituloPagarTipoDocumento =
      jsonObject.nrSeqTituloPagarTipoDocumento;
    this.cdTituloPagarTipoDocumento = jsonObject.cdTituloPagarTipoDocumento;
    this.noTituloPagarTipoDocumento = jsonObject.noTituloPagarTipoDocumento;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
