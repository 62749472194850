import React, { useEffect, useRef, useState } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import { deleteAllConvenioExtrato } from 'core/services/ASS/convenioExtrato';
import {
  getLancamentosPaged,
  printRelFinanceiro,
  associar,
  deleteAllBySearch,
  atualizar,
  printBoletoDetalhadoAssociado,
  printRelatorioMensalAssociadoDetalhado,
  printResultadoPorCompetencia,
  printRelFinanceiroAgrupado,
  printRelAgrupadoTitular,
  printRelMensalCompetencia,
  printRelMensalSemMatricula,
  printRelComissoes,
  printRelBonificacao,
  printRelLancamentoAssociado,
  printRelLancamentoMensalPorAssociado,
} from 'core/services/ASS/lancamentoAssociado';
import {
  CSDSelPage,
  GridView,
  Switch,
  RadioButton,
  Autocomplete,
  DropdownMulti,
  DatePicker,
  ToolbarButtons,
  Textbox,
  Panel,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getConvenioAutoComplete } from 'core/services/ASS/convenio';
import { getAssociadoList } from 'core/services/ASS/associado';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getVendedorAutoComplete } from 'core/services/VEN/vendedor';
import { getPlanoConvenioList } from 'core/services/ASS/planoConvenio';
import { getConvenioEncargoList } from 'core/services/ASS/convenioEncargo';
import { getAssociadoPlanoConvenioByAssociadoId } from 'core/services/ASS/associadoPlanoConvenio';
import { getInformacaoBancariaByNrSeqPessoa } from 'core/services/FIN/informacaoBancaria';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import PanelResumo from './panelResumo';
import { FilterTextOperator } from './components';
import ModalImportacaoConvenio from './modalImportacaoConvenio';
import ModalGerarLancamento from './modalGerarLancamento';
import ModalExportarLancamentos from './modalExportacao';
import ModalVinculaPlanoAssociado from './modalVinculaPlanoAssociado';

export default function LancamentoAssociado({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
  onOpenTransaction,
  findTransaction,
}) {
  const gridView = useRef();
  const gridViewRelPorConvenio = useRef();
  const dropDownEncargos = useRef();

  const [filtros, setFiltros] = useState({
    noStatus: 'T',
    noStatusFinanceiro: 'T',
    flgIncluirEvento: false,
    flgAssPendente: false,
    flgIncluirStatus: true,
    flgRelPorConvenio: false,
    flgIncluirVendedor: false,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});
  const [convenioExtrato, setConvenioExtrato] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [modalShow, setModalShow] = useState({});
  const [dataGrid, setDataGrid] = useState(null);
  const [dataGridRelPorConvenio, setDataGridRelPorConvenio] = useState(null);
  const [isVisibleRelPorConvenio, setIsVisibleRelPorConvenio] = useState(false);
  const [dadosPlanoAssociado, setDadosPlanoAssociado] = useState(null);

  const btnStatus = [
    { text: 'Todos', value: 'T' },
    { text: 'Liberados', value: 'L' },
    { text: 'Não Liberados', value: 'N' },
  ];

  const btsFiltroFinanceiro = [
    { text: 'Todos', value: 'T' },
    { text: 'Recebidos', value: 'R' },
    { text: 'Abertos', value: 'A' },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);
    setIsVisible(false);

    const {
      status,
      message: msg,
      data,
      resumo,
      pagination,
    } = await getLancamentosPaged(params ?? filtros);

    if (data) {
      if (!filtros.flgRelPorConvenio) {
        setDataGrid({ lancamentos: data, pagination });
        setConvenioExtrato(resumo);
        setIsVisible(true);
        setIsVisibleRelPorConvenio(false);
      } else {
        setDataGridRelPorConvenio({ lancamentos: data, pagination });
        setConvenioExtrato(resumo);
        setIsVisibleRelPorConvenio(true);
        setIsVisible(false);
      }
    } else {
      setConvenioExtrato(null);
      gridView?.current?.setDataSource([]);
    }

    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    setLoading(false);
  };

  useEffect(() => {
    if (gridView && gridView.current && dataGrid.lancamentos) {
      gridView.current.setDataSource(dataGrid.lancamentos, dataGrid.pagination);
    }
  }, [isVisible]);

  useEffect(() => {
    if (
      gridViewRelPorConvenio &&
      gridViewRelPorConvenio?.current &&
      dataGridRelPorConvenio?.lancamentos
    ) {
      gridViewRelPorConvenio.current.setDataSource(
        dataGridRelPorConvenio.lancamentos,
        dataGridRelPorConvenio.pagination
      );
    }
  }, [isVisibleRelPorConvenio]);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage) => {
    setFiltros({ ...filtros, totalByPage });
    await search({ ...filtros, totalByPage });
  };

  const onPageChange = async (page) => {
    setFiltros({ ...filtros, page });
    await search({ ...filtros, page });
  };

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'LancamentoAssociado/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqConvenioExtrato = sourceRow.find(
      (e) => e.key === 'nrSeqConvenioExtrato'
    );
    onOpenMaintenance(nrSeqConvenioExtrato.value);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView?.current?.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllConvenioExtrato(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchConvenio = async (e) => {
    const { status, message: msg, convenios } = await getConvenioAutoComplete({
      noConvenio: e,
    });
    if (msg) onSetMessage(status, msg);
    return convenios;
  };

  const onSearchPlanoConvenio = async (e) => {
    const { status, message: msg, data: planos } = await getPlanoConvenioList({
      noPlanoConvenio: e,
      nrSeqConvenio: filtros?.nrSeqConvenio ?? null,
    });
    onSetMessage(status, msg);
    return planos;
  };

  const onSearchAssociado = async (e) => {
    const { status, message: msg, associados } = await getAssociadoList({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return associados;
  };

  const onSearchVendedor = async (e) => {
    const { status, message: msg, vendedores } = await getVendedorAutoComplete({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return vendedores;
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noformaPagamento: e,
    });
    onSetMessage(status, msg);
    return formasPagamento;
  };

  const onSearchConvenioEncargos = async (e) => {
    const { data: encargos } = await getConvenioEncargoList({
      noConvenioEncargo: e,
    });
    return encargos;
  };

  useEffect(() => {
    if (dropDownEncargos && dropDownEncargos.current) {
      dropDownEncargos.current.loadDataSource(onSearchConvenioEncargos);
    }
  }, []);

  const onClickAssociar = async (e) => {
    setLoading(true);
    const { status, message: msg } = await associar(e);

    if (msg) onSetMessage(status, msg);
    setLoading(false);
  };

  const onClickVincularPlano = async (e) => {
    const objModal = { convenioExtrato: e, planosConvAssociado: [] };

    const dtInicio = `${e.anoMes.slice(2)}-${e.anoMes.slice(0, 2)}-01`;
    let formaPag;
    let infoBancaria;

    let planoConvenio;

    const {
      data: planosAssociado,
    } = await getAssociadoPlanoConvenioByAssociadoId(e.nrSeqPessoaAss);

    if (planosAssociado && planosAssociado.length > 0) {
      objModal.planosConvAssociado = planosAssociado.filter(
        (el) => el.nrSeqPlanoConvenio !== e.nrSeqPlanoConvenio
      );

      const plano = planosAssociado.filter(
        (el) => el.nrSeqPlanoConvenio === e.nrSeqPlanoConvenio
      );

      if (plano && plano.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        objModal.planoConvenioEdit = plano[0];
      } else {
        const valoresformaPag = planosAssociado.map(
          (obj) => obj.formaPagamento
        );

        const valoresinfoBancaria = planosAssociado
          .filter((obj) => obj.pessoaInformacaoBancaria !== null)
          .map((obj) => obj.pessoaInformacaoBancaria);

        formaPag =
          valoresformaPag && valoresformaPag.length > 0
            ? valoresformaPag[0]
            : null;

        infoBancaria =
          valoresinfoBancaria && valoresinfoBancaria.length > 0
            ? valoresinfoBancaria[0]
            : null;

        objModal.planoConvenioEdit = {
          formaPagamento: { ...formaPag },
          pessoaInformacaoBancaria:
            Object.keys(infoBancaria).length === 0 ? null : { ...infoBancaria },
        };
      }
    }

    const { data: planosConv } = await getPlanoConvenioList({
      nrSeqPlanoConvenio: e.nrSeqPlanoConvenio ?? null,
      nrSeqConvenio: e.nrSeqConvenio,
    });

    if (planosConv && planosConv.length > 0) {
      planoConvenio = planosConv.length > 1 ? planosConv : planosConv[0];
    }

    if (!infoBancaria || Object.keys(infoBancaria).length === 0) {
      const informacoesBancarias = await getInformacaoBancariaByNrSeqPessoa(
        e.nrSeqPessoaAss
      );

      infoBancaria = { ...informacoesBancarias };
    }

    if (objModal.planoConvenioEdit) {
      objModal.planoConvenioEdit.convenio = e.convenio;

      objModal.planoConvenioEdit.planoConvenio =
        objModal.planoConvenioEdit?.planoConvenio ?? planoConvenio;

      objModal.planoConvenioEdit.pessoaInformacaoBancaria =
        objModal.planoConvenioEdit?.pessoaInformacaoBancaria ?? infoBancaria;

      objModal.planoConvenioEdit.formaPagamento =
        objModal.planoConvenioEdit?.formaPagamento ?? formaPag;

      objModal.planoConvenioEdit.dtInicioVigencia =
        objModal.planoConvenioEdit?.dtInicioVigencia ?? dtInicio;
    } else {
      const planoConvenioInserir = {
        convenio: e.convenio,
        planoConvenio:
          objModal.planoConvenioEdit?.planoConvenio ?? planoConvenio,

        dtInicioVigencia:
          objModal.planoConvenioEdit?.dtInicioVigencia ?? dtInicio,
        pessoaInformacaoBancaria:
          objModal.planoConvenioEdit?.pessoaInformacaoBancaria ?? infoBancaria,
        formaPagamento: objModal.planoConvenioEdit?.formaPagamento ?? formaPag,
        nrSeqAssocPlanoConv:
          objModal.planoConvenioEdit?.nrSeqAssocPlanoConv ?? 0,
      };

      objModal.planoConvenioEdit = planoConvenioInserir;
    }

    objModal.planoConvenioEdit.nrSeqConvenio = e.nrSeqConvenio;

    objModal.planoConvenioEdit.nrSeqPlanoConvenio =
      objModal.planoConvenioEdit.planoConvenio?.nrSeqPlanoConvenio;

    objModal.planoConvenioEdit.planoConvenio.convenio = e.convenio;

    objModal.planoConvenioEdit.nrSeqPessoaAss = e.nrSeqPessoaAss;
    objModal.planoConvenioEdit.dtFimVigencia = null;

    setDadosPlanoAssociado(objModal);
    setModalShow({ ...modalShow, vincularPlanoAssociado: true });
  };

  const onOpenImportarConvenio = async () => {
    setModalShow({ ...modalShow, importarConvenio: true });
  };

  const onOpenGerarLancamento = async () => {
    setModalShow({ ...modalShow, gerarLancamento: true });
  };

  const onOpenExportar = async () => {
    setModalShow({ ...modalShow, exportarLancamentos: true });
  };

  const onClickAtualizar = async () => {
    setLoading(true);

    const { status, message: msg } = await atualizar(filtros);
    setLoading(false);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
  };

  const onClickOpenDetalhamentoContaTel = (selectedValue) => {
    const transactionContaChamada = findTransaction(
      TransacationCodes.ContaChamada
    );

    if (transactionContaChamada) {
      transactionContaChamada.registryKey = selectedValue.nrSeqContaRecurso;
      onOpenTransaction(transactionContaChamada);
    }
  };

  //  #region PRINT
  const onPrintRelFinanceiro = async () => {
    const obj = { ...filtros };
    if (filtros.convenio) {
      obj.convenio = {
        noConvenio: filtros.convenio?.noConvenio,
        nrSeqConvenio: obj.nrSeqConvenio,
      };
    }

    if (filtros.formaRecebimento) {
      obj.formaRecebimento = {
        noformaPagamento: filtros.formaRecebimento.noformaPagamento,
        nrSeqFormaPagamento: obj.nrSeqFormaRecebimento,
      };
    }

    if (filtros.formaPagamento) {
      obj.formaPagamento = !filtros.formaPagamento ?? {
        noformaPagamento: filtros.formaPagamento.noformaPagamento,
      };
    }

    setLoading(true);
    const { value, message: msg } = await printRelFinanceiro(filtros);

    if (value) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(false, msg || 'Erro ao Imprimir Relatório');
    }

    setLoading(false);
  };

  const onPrintRelFinanceiroAgrupado = async () => {
    setLoading(true);
    const { value, message: msg } = await printRelFinanceiroAgrupado(filtros);

    if (value) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(false, msg || 'Erro ao Imprimir Relatório');
    }

    setLoading(false);
  };

  const onClickPrintBoleto = async (selectedValue) => {
    const obj = {
      nrSeqConvenioExtrato: selectedValue.nrSeqConvenioExtrato,
      nrSeqPessoaAss: selectedValue.nrSeqPessoaAss,
      anoMes: selectedValue.anoMes,
      nrSeqTituloReceber: selectedValue.nrSeqTituloReceber,
      nrSeqConvenio: selectedValue.nrSeqConvenio,
      nrSeqFormaPagamento: selectedValue.nrSeqFormaPagamento,
    };

    setLoading(true);

    const { value, message: msg } = await printBoletoDetalhadoAssociado(obj);

    if (value) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(false, msg || 'Erro ao Imprimir Boleto');
    }

    setLoading(false);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value, message: msg } = await printRelLancamentoAssociado(filtros);

    if (value) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(false, msg || 'Erro ao Imprimir Relatório');
    }

    setLoading(false);
  };

  const onPrintPorAssociadoDetalhado = async () => {
    setLoading(true);
    const {
      value,
      message: msg,
    } = await printRelatorioMensalAssociadoDetalhado(filtros);

    if (value) {
      const linkSource = `data:image/pdf;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `AssociadoDetalhado.pdf`;
      downloadLink.click();
    } else {
      onSetMessage(false, msg || 'Erro ao Imprimir Relatório');
    }

    setLoading(false);
  };

  const onPrintResultadoPorCompetencia = async () => {
    if (filtros.anoMes) {
      const obj = { ...filtros };

      setLoading(true);
      const { value, message: msg } = await printResultadoPorCompetencia(obj);

      if (value) {
        onOpenReport(transaction.noTransacao, value);
      } else {
        onSetMessage(false, msg || 'Erro ao Imprimir Relatório');
      }

      setLoading(false);
    } else {
      onSetMessage(false, 'É necessário o preenchimento da Competência.');
    }
  };

  const onPrintRelAgrupadoTitular = async () => {
    setLoading(true);
    const { value, message: msg } = await printRelAgrupadoTitular(filtros);

    if (value) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(false, msg || 'Erro ao Imprimir Relatório');
    }

    setLoading(false);
  };

  const onPrintRelMensalCompetencia = async () => {
    setLoading(true);
    const { value, message: msg } = await printRelMensalCompetencia(filtros);

    if (value) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(false, msg || 'Erro ao Imprimir Relatório');
    }

    setLoading(false);
  };

  const onPrintRelComissoesPorConvenio = async () => {
    const obj = { ...filtros };
    obj.FlgComissoesPorConvenio = true;
    setLoading(true);

    const { value, message: msg } = await printRelComissoes(obj);

    if (value) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(false, msg || 'Erro ao Imprimir Relatório');
    }
    setFiltros({
      noStatus: 'T',
      noStatusFinanceiro: 'T',
      flgIncluirEvento: false,
      flgAssPendente: false,
      flgIncluirStatus: true,
      flgIncluirVendedor: false,
    });

    setLoading(false);
  };
  const onPrintRelBonificacaoPorConvenio = async () => {
    const obj = { ...filtros };
    setLoading(true);
    if (filtros.flgRelPorConvenio) {
      const { value, message: msg } = await printRelBonificacao(obj);

      if (value) {
        onOpenReport(transaction.noTransacao, value);
      } else {
        onSetMessage(false, msg || 'Erro ao Imprimir Relatório');
      }
      setFiltros({
        noStatus: 'T',
        noStatusFinanceiro: 'T',
        flgIncluirEvento: false,
        flgAssPendente: false,
        flgIncluirStatus: true,
        flgIncluirVendedor: false,
      });
    } else {
      onSetMessage(false, 'Selecione a opção Relatorio Total por Convenio');
    }

    setLoading(false);
  };

  const onPrintRelComissoesPorVendedor = async () => {
    const obj = { ...filtros };
    obj.FlgComissoesPorVendedor = true;
    obj.FlgComissoesPorConvenio = false;
    setLoading(true);
    const { value, message: msg } = await printRelComissoes(obj);

    if (value) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(false, msg || 'Erro ao Imprimir Relatório');
    }

    setLoading(false);
  };

  const onPrintRelMensalSemMatricula = async () => {
    setLoading(true);
    const { value, message: msg } = await printRelMensalSemMatricula(filtros);

    if (value) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(false, msg || 'Erro ao Imprimir Relatório');
    }

    setLoading(false);
  };

  const onPrintRelLancamentoMensalPorAssociado = async () => {
    setLoading(true);

    const { value, message: msg } = await printRelLancamentoMensalPorAssociado(
      filtros
    );

    if (value) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(false, msg || 'Erro ao Imprimir Relatório');
    }
    setLoading(false);
  };

  // #endregion

  //  #region EXCEL
  const onDownloadExcel = async () => {
    const obj = { ...filtros };
    obj.FlgExcel = true;
    setLoading(true);
    const { value, message: msg } = await printRelLancamentoAssociado(obj);

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'LancamentoAssociado.xls';
      downloadLink.click();
    } else onSetMessage(false, msg || 'Erro ao gerar excel.');

    setLoading(false);
  };

  const onDownloadExcelResultadoPorCompetencia = async () => {
    if (filtros.anoMes) {
      const obj = { ...filtros, FlgExcel: true };

      setLoading(true);
      const { value, message: msg } = await printResultadoPorCompetencia(obj);

      if (value) {
        const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download =
          'LancamentoAssociadoResultadoPorCompetencia.xls';
        downloadLink.click();
      } else {
        onSetMessage(false, msg || 'Erro ao gerar excel.');
      }
    } else
      onSetMessage(
        false,
        'É necessário o preenchimento da Competência e das datas de Vencimento inicial e final.'
      );

    setLoading(false);
  };

  const onDownloadExcelMensalCompetencia = async () => {
    const obj = { ...filtros };
    obj.FlgExcel = true;
    setLoading(true);
    const { value, message: msg } = await printRelMensalCompetencia(obj);

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'LancamentoAssociadoPorCompetencia.xls';
      downloadLink.click();
    } else {
      onSetMessage(false, msg || 'Erro ao gerar excel.');
    }

    setLoading(false);
  };

  const onDownloadExcelComissaoPorVendedor = async () => {
    const obj = { ...filtros };
    obj.FlgExcel = true;
    obj.FlgComissoesPorVendedor = true;
    obj.FlgComissoesPorConvenio = false;
    setLoading(true);
    const { value, message: msg } = await printRelComissoes(obj);

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'LancamentoAssociadoComissoesPorVendedor.xls';
      downloadLink.click();
    } else onSetMessage(false, msg || 'Erro ao gerar excel.');

    setLoading(false);
  };

  const onDownloadExcelComissaoPorConvenio = async () => {
    const obj = { ...filtros };
    obj.FlgExcel = true;
    obj.FlgComissoesPorVendedor = false;
    obj.FlgComissoesPorConvenio = true;
    setLoading(true);
    const { value, message: msg } = await printRelComissoes(obj);

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'LancamentoAssociadoComissoesPorConvenio.xls';
      downloadLink.click();
    } else onSetMessage(false, msg || 'Erro ao gerar excel.');

    setLoading(false);
  };

  const onDownloadExcelSemMatricula = async () => {
    const obj = { ...filtros };
    obj.FlgExcel = true;

    setLoading(true);
    const { value, message: msg } = await printRelMensalSemMatricula(obj);

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'LancamentoAssociadoSemMatricula.xls';
      downloadLink.click();
    } else onSetMessage(false, msg || 'Erro ao gerar excel.');

    setLoading(false);
  };

  const onDownloadExcelPorAssociadoDetalhado = async () => {
    const obj = { ...filtros };
    obj.FlgExcel = true;

    setLoading(true);
    const {
      value,
      message: msg,
    } = await printRelatorioMensalAssociadoDetalhado(obj);

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'LancamentoAssociadoPorAssociadoDetalhado.xls';
      downloadLink.click();
    } else onSetMessage(false, msg || 'Erro ao gerar excel.');

    setLoading(false);
  };

  const onDownloadExcelFinanceiroAgrupado = async () => {
    const obj = { ...filtros };
    obj.FlgExcel = true;

    setLoading(true);
    const { value, message: msg } = await printRelFinanceiroAgrupado(obj);

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'LancamentoAssociadoFinanceiroAgrupado.xls';
      downloadLink.click();
    } else onSetMessage(false, msg || 'Erro ao gerar excel.');

    setLoading(false);
  };

  const onDownloadExcelFinanceiro = async () => {
    const obj = { ...filtros };
    obj.FlgExcel = true;

    setLoading(true);
    const { value, message: msg } = await printRelFinanceiro(obj);

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'LancamentoAssociadoFinanceiro.xls';
      downloadLink.click();
    } else onSetMessage(false, msg || 'Erro ao gerar excel.');

    setLoading(false);
  };

  const onDownloadExcelAgrupadoTitular = async () => {
    const obj = { ...filtros };
    obj.FlgExcel = true;

    setLoading(true);
    const { value, message: msg } = await printRelAgrupadoTitular(obj);

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'LancamentoAssociadoAgrupadoTitular.xls';
      downloadLink.click();
    } else onSetMessage(false, msg || 'Erro ao gerar excel.');

    setLoading(false);
  };

  const onDownloadExcelPorAssociado = async () => {
    const obj = { ...filtros };
    obj.FlgExcel = true;

    setLoading(true);
    const { value, message: msg } = await printRelLancamentoMensalPorAssociado(
      obj
    );

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'LancamentoAssociadoPorPessoa.xls';
      downloadLink.click();
    } else onSetMessage(false, msg || 'Erro ao gerar excel.');

    setLoading(false);
  };
  //  #endregion

  const onDeleteAllResults = async () => {
    setLoading(true);

    if (
      filtros.anoMes ||
      filtros.dtInicialCadastro ||
      filtros.dtInicialRecebimento ||
      filtros.dtInicialVencimento
    ) {
      const { status, message: msg } = await deleteAllBySearch(filtros);

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message:
          'Não há filtros selecionados para buscar os lançamentos que deseja excluir.',
        theme: Theme.Danger,
      });
    }
  };

  const columns = [
    { key: 'nrSeqConvenioExtrato', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'convenio.noConvenio',
      title: 'Convênio',
      sortKey: 'noConvenio',
    },
    {
      key: 'evento',
      title: 'Evento',
      visible: filtros.flgIncluirEvento ?? false,
      sortKey: 'ConvenioExtrato.evento',
    },
    { key: 'anoMes', title: 'Mês/Ano' },
    { key: 'nome', title: 'Nome Importação' },
    { key: 'nrMatricula', title: 'Matrícula/ Nr. Contrato' },
    {
      key: 'noVendedor',
      title: 'Vendedor',
      visible: filtros.flgIncluirVendedor,
    },
    {
      key: 'associado.noPessoa',
      title: 'Associado',
      sortKey: 'noPessoa',
    },
    { key: 'associado.cdAssociado', title: 'Cód Associado' },
    {
      key: 'vlrOriginal',
      title: 'Original',
      format: GridView.DataTypes.Money,
      sortKey: 'ConvenioExtrato.vlrOriginal',
    },
    {
      key: 'vlrRecalculado',
      title: 'Recalculado',
      format: GridView.DataTypes.Money,
      sortKey: 'ConvenioExtrato.vlrRecalculado',
    },
    {
      key: 'vlrComissao',
      title: 'Comissão',
      format: GridView.DataTypes.Money,
      sortKey: 'ConvenioExtrato.vlrComissao',
    },
    {
      key: 'vlrResultado',
      title: 'Resultado',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'noMotivoAlteracao',
      title: 'Observação',
      sortKey: 'ConvenioExtrato.noMotivoAlteracao',
    },
    {
      key: 'liberado',
      title: 'Liberado',
      colorText: 'noColorStatusLiberado',
      format: GridView.DataTypes.Boolean,
      sortable: false,
      tooltipKey: 'noMsgLiberado',
    },
    {
      key: 'btnAssociar',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickVincularPlano(e, datasource),
      onVisible: (e) => !e.liberado && e.nrSeqPessoaAss !== null,
      theme: Theme.Info,
      icon: 'check',
      size: BootstrapSizes.Small,
      sortable: false,
      title: 'Vincular plano',
      tooltip: 'Vincular plano não liberado ao associado',
      tooltipDirection: 'bottom',
    },
    {
      key: 'btnAssociar',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickAssociar(e, datasource),
      onVisible: (e) => !e.liberado && !e.nrSeqPessoaAss,
      theme: Theme.Success,
      icon: 'check',
      size: BootstrapSizes.Small,
      sortable: false,
      title: 'Associar',
      tooltip:
        'Cadastrar este associado e realizar o vínculo de todos os seus lançamentos nesta competência.',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqTituloReceber',
      onVisible: (e) =>
        e.nrSeqTituloReceber !== null && e.formaPagamento?.flgGrupo === 'G',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickPrintBoleto(e),
      theme: Theme.Success,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      title: 'Boleto',
      tooltip: 'Imprimir Boleto',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqContaRecurso',
      onVisible: (e) =>
        e.nrSeqContaRecurso && e.nrSeqPessoaAss && e.convenio?.flgTelefonia,
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOpenDetalhamentoContaTel(e),
      theme: Theme.Warning,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      title: 'Conta Tel',
      tooltip: 'Detalhar Telefone',
      tooltipDirection: 'bottom',
    },
  ];
  const columnsRelatorio = [
    {
      key: 'nrSeqConvenioExtrato',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'convenio.noConvenio',
      title: 'Convênio',
      sortKey: 'noConvenio',
    },
    { key: 'anoMes', title: 'Mês/Ano' },
    { key: 'nrMatricula', title: 'Matrícula/ Nr. Contrato' },
    {
      key: 'associado.noPessoa',
      title: 'Associado',
      sortKey: 'noPessoa',
    },
    { key: 'nrCpf', title: 'CPF' },
    {
      key: 'vlrOriginal',
      title: 'Original',
      format: GridView.DataTypes.Money,
      sortKey: 'ConvenioExtrato.vlrOriginal',
    },
    {
      key: 'vlrRecalculado',
      title: 'Recalculado',
      format: GridView.DataTypes.Money,
      sortKey: 'ConvenioExtrato.vlrRecalculado',
    },
    {
      key: 'vlrPercentualBonificacao',
      title: 'Vlr Bonificação',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'convenio.percentualBonificacao',
      title: 'Pencentual Bonificação',
    },
  ];

  return (
    <CSDSelPage
      isActive={isActive}
      title='Lançamento Associado'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.ButtonList
          text='Excluir'
          icon='caret-down'
          items={[
            {
              text: 'Itens Selecionados',
              onClick: () => onDelete(),
            },
            {
              text: 'Todos itens pesquisados',
              onClick: () => onDeleteAllResults(),
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Imprimir'
          icon='print'
          onClick={() => onPrint()}
        />
        <ToolbarButtons.ButtonList
          text=''
          icon='caret-down'
          items={[
            {
              text: 'Relatório Mensal por Competência',
              onClick: () => onPrintRelMensalCompetencia(),
            },

            {
              text: 'Mensal Sem Matrícula',
              onClick: () => onPrintRelMensalSemMatricula(),
              visible: false,
            },

            {
              text: 'Relatório Comissões por Vendedor',
              onClick: () => onPrintRelComissoesPorVendedor(),
            },
            {
              text: 'Relatório Comissões por Convênio',
              onClick: () => onPrintRelComissoesPorConvenio(),
            },

            {
              text: 'Relatório Financeiro',
              onClick: () => onPrintRelFinanceiro(),
            },
            {
              text: 'Relatório Financeiro Agrupado',
              onClick: () => onPrintRelFinanceiroAgrupado(),
            },
            {
              text: 'Por Associado Detalhado',
              onClick: () => onPrintPorAssociadoDetalhado(),
            },

            {
              text: 'Resultado por Competência',
              onClick: () => onPrintResultadoPorCompetencia(),
            },
            {
              text: 'Relatório Agrupado Titular',
              onClick: () => onPrintRelAgrupadoTitular(),
            },
            {
              text: 'Relatório Por Associado',
              onClick: () => onPrintRelLancamentoMensalPorAssociado(),
            },
            {
              text: 'Relatório Total Bonificação por Convênio',
              onClick: () => onPrintRelBonificacaoPorConvenio(),
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Excel'
          icon={['far', 'file-excel']}
          onClick={() => onDownloadExcel()}
        />
        <ToolbarButtons.ButtonList
          icon='caret-down'
          items={[
            {
              text: 'Mensal por Competência',
              onClick: () => onDownloadExcelMensalCompetencia(),
            },
            {
              text: 'Comissões por Vendedor',
              onClick: () => onDownloadExcelComissaoPorVendedor(),
            },
            {
              text: 'Comissões por Convênio',
              onClick: () => onDownloadExcelComissaoPorConvenio(),
            },
            {
              text: 'Sem Matrícula',
              onClick: () => onDownloadExcelSemMatricula(),
            },
            {
              text: 'Por Associado',
              onClick: () => onDownloadExcelPorAssociado(),
            },
            {
              text: 'Por Associado Detalhado',
              onClick: () => onDownloadExcelPorAssociadoDetalhado(),
            },
            {
              text: 'Financeiro Agrupado',
              onClick: () => onDownloadExcelFinanceiroAgrupado(),
            },

            {
              text: 'Financeiro',
              onClick: () => onDownloadExcelFinanceiro(),
            },

            {
              text: 'Agrupado Titular',
              onClick: () => onDownloadExcelAgrupadoTitular(),
            },
            {
              text: 'Resultado por Competência',
              onClick: () => onDownloadExcelResultadoPorCompetencia(),
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Importar'
          onClick={() => onOpenImportarConvenio()}
        />
        <ToolbarButtons.Button
          text='Gerar Lançamento'
          onClick={() => onOpenGerarLancamento()}
        />
        <ToolbarButtons.Button
          text='Atualizar'
          onClick={() => onClickAtualizar()}
        />
        <ToolbarButtons.Button
          text='Exportar'
          onClick={() => onOpenExportar()}
        />
      </ToolbarButtons>

      <div className='row mb-3 mt-2'>
        <div className='col'>
          <Panel>
            <Panel.Header
              icon={['fas', 'user']}
              title='Filtros'
              theme={Theme.Primary}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col-2'>
                  <Switch
                    formControl
                    label='Relatorio Total por Convenio'
                    checked={filtros.flgRelPorConvenio}
                    onChange={(flgRelPorConvenio) =>
                      setFiltros({
                        ...filtros,
                        flgRelPorConvenio,
                      })
                    }
                  />
                </div>
                {filtros.flgRelPorConvenio && (
                  <div className='col-2 mb-3'>
                    <Textbox
                      maxLength={7}
                      label='Competência'
                      text={filtros.anoMes}
                      mask={MaskTypes.Competence}
                      onChangedValue={(anoMes) =>
                        setFiltros({ ...filtros, anoMes })
                      }
                    />
                  </div>
                )}
                {filtros.flgRelPorConvenio && (
                  <div className='col-4 mb-3'>
                    <Autocomplete
                      label='Convênio'
                      searchDataSource={onSearchConvenio}
                      selectedItem={filtros.convenio}
                      onSelectItem={(convenio) => {
                        setFiltros({
                          ...filtros,
                          convenio,
                          nrSeqConvenio: convenio.nrSeqConvenio,
                        });
                      }}
                      dataSourceTextProperty='noConvenio'
                    />
                  </div>
                )}
              </div>

              {!filtros.flgRelPorConvenio && (
                <div className='row mt-3'>
                  <div className='col-2 mb-3'>
                    <DatePicker
                      label='Cadastro De'
                      text={filtros.dtInicialCadastro}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtInicialCadastro) =>
                        setFiltros({ ...filtros, dtInicialCadastro })
                      }
                    />
                  </div>

                  <div className='col-2 mb-3'>
                    <DatePicker
                      label='Cadastro Até'
                      text={filtros.dtFinalCadastro}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtFinalCadastro) =>
                        setFiltros({ ...filtros, dtFinalCadastro })
                      }
                    />
                  </div>

                  <div className='col-2 mb-3'>
                    <Textbox
                      maxLength={7}
                      label='Competência'
                      text={filtros.anoMes}
                      mask={MaskTypes.Competence}
                      onChangedValue={(anoMes) =>
                        setFiltros({ ...filtros, anoMes })
                      }
                    />
                  </div>

                  <div className='col-3 text-center mb-3'>
                    <RadioButton
                      outline
                      label='Status'
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      buttons={btnStatus}
                      selectedButton={filtros.noStatus}
                      onChange={(status) =>
                        setFiltros({
                          ...filtros,
                          noStatus: status,
                        })
                      }
                    />
                  </div>
                  <div className='col-3 text-center mb-3'>
                    <RadioButton
                      outline
                      label='Filtro Financeiro'
                      required
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      buttons={btsFiltroFinanceiro}
                      selectedButton={filtros.noStatusFinanceiro}
                      onChange={(status) =>
                        setFiltros({
                          ...filtros,
                          noStatusFinanceiro: status,
                        })
                      }
                    />
                  </div>
                </div>
              )}
              {!filtros.flgRelPorConvenio && (
                <div className='row'>
                  <div className='col-4 mb-3'>
                    <Autocomplete
                      label='Convênio'
                      searchDataSource={onSearchConvenio}
                      selectedItem={filtros.convenio}
                      onSelectItem={(convenio) => {
                        setFiltros({
                          ...filtros,
                          convenio,
                          nrSeqConvenio: convenio.nrSeqConvenio,
                        });
                      }}
                      dataSourceTextProperty='noConvenio'
                    />
                  </div>
                  <div className='col-2 mb-3'>
                    <Switch
                      formControl
                      label='Incluir Status'
                      checked={filtros.flgIncluirStatus}
                      onChange={(flgIncluirStatus) =>
                        setFiltros({
                          ...filtros,
                          flgIncluirStatus,
                        })
                      }
                    />
                  </div>

                  <div className='col-2 mb-3'>
                    <Switch
                      formControl
                      checked={filtros.flgIncluirEvento}
                      label='Incluir Evento'
                      onChange={(flgIncluirEvento) =>
                        setFiltros({
                          ...filtros,
                          flgIncluirEvento,
                        })
                      }
                    />
                  </div>

                  <div className='col-2 mb-3'>
                    <Switch
                      formControl
                      checked={filtros.flgIncluirVendedor}
                      label='Incluir Vendedor'
                      onChange={(flgIncluirVendedor) =>
                        setFiltros({
                          ...filtros,
                          flgIncluirVendedor,
                        })
                      }
                    />
                  </div>

                  <div className='col-2 mb-3'>
                    <Switch
                      formControl
                      checked={filtros.flgAssPendente}
                      label='Associação Pendente'
                      onChange={(flgAssPendente) =>
                        setFiltros({
                          ...filtros,
                          flgAssPendente,
                        })
                      }
                    />
                  </div>
                </div>
              )}
              {!filtros.flgRelPorConvenio && (
                <div className='row'>
                  <div className='col-8'>
                    <div className='row mb-3'>
                      <div className='col-4 mb-3'>
                        <Autocomplete
                          label='Plano'
                          searchDataSource={onSearchPlanoConvenio}
                          selectedItem={filtros.planoConvenio}
                          onSelectItem={(planoConvenio) =>
                            setFiltros({
                              ...filtros,
                              planoConvenio,
                              nrSeqPlanoConvenio:
                                planoConvenio.nrSeqPlanoConvenio,
                            })
                          }
                          dataSourceTextProperty='noPlanoConvenio'
                        />
                      </div>

                      <div className='col-4 mb-3'>
                        <Autocomplete
                          label='Associado'
                          searchDataSource={onSearchAssociado}
                          selectedItem={filtros.associado}
                          onSelectItem={(associado) =>
                            setFiltros({
                              ...filtros,
                              associado,
                              nrSeqPessoaAss: associado.nrSeqPessoaAss,
                            })
                          }
                          dataSourceTextProperty='noPessoa'
                        />
                      </div>

                      <div className='col-4 mb-3'>
                        <Autocomplete
                          label='Vendedor'
                          searchDataSource={onSearchVendedor}
                          selectedItem={filtros.vendedor}
                          onSelectItem={(vendedor) =>
                            setFiltros({
                              ...filtros,
                              vendedor,
                              nrSeqPessoaVen: vendedor.nrSeqPessoa,
                              flgIncluirVendedor: true,
                            })
                          }
                          dataSourceTextProperty='noPessoa'
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-4 mb-3'>
                        <Autocomplete
                          label='Forma Pagamento'
                          tooltip='Forma que o Títilo foi gerado'
                          searchDataSource={onSearchFormaPagamento}
                          selectedItem={filtros.formaPagamento}
                          onSelectItem={(formaPagamento) =>
                            setFiltros({
                              ...filtros,
                              formaPagamento,
                              nrSeqFormaPagamento:
                                formaPagamento.nrSeqFormaPagamento,
                            })
                          }
                          dataSourceTextProperty='noFormaPagamento'
                        />
                      </div>

                      <div className='col-4 mb-3'>
                        <Autocomplete
                          label='Forma Recebimento'
                          searchDataSource={onSearchFormaPagamento}
                          selectedItem={filtros.formaRecebimento}
                          onSelectItem={(formaRecebimento) =>
                            setFiltros({
                              ...filtros,
                              formaRecebimento,
                              nrSeqFormaRecebimento:
                                formaRecebimento.nrSeqFormaPagamento,
                            })
                          }
                          dataSourceTextProperty='noFormaPagamento'
                        />
                      </div>
                      <div className='col-4 mb-3'>
                        <Textbox
                          maxLength={200}
                          label='Nome'
                          text={filtros.nome}
                          onChangedValue={(nome) =>
                            setFiltros({ ...filtros, nome })
                          }
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-4'>
                        <DropdownMulti
                          label='Encargos'
                          ref={dropDownEncargos}
                          dataSourcePropertyText='noConvenioEncargo'
                          dataSourcePropertyValue='nrSeqConvenioEncargo'
                          selectedItems={selectedItems.encargos ?? []}
                          onSelectItem={(encargos) => {
                            setSelectedItems({ ...selectedItems, encargos });
                            setFiltros({
                              ...filtros,
                              nrSeqsConvenioEncargos: encargos?.map(
                                (p) => p.nrSeqConvenioEncargo
                              ),
                            });
                          }}
                        />
                      </div>
                      <div className='col-4 mb-3'>
                        <Textbox
                          maxLength={200}
                          label='Evento'
                          text={filtros.evento}
                          onChangedValue={(evento) =>
                            setFiltros({ ...filtros, evento })
                          }
                        />
                      </div>
                      <div className='col-4 mb-3'>
                        <Textbox
                          maxLength={200}
                          label='Matrícula / Nr. Contrato'
                          text={filtros.nrMatricula}
                          onChangedValue={(nrMatricula) => {
                            nrMatricula =
                              nrMatricula && typeof nrMatricula === 'number'
                                ? nrMatricula.toString()
                                : nrMatricula;
                            setFiltros({ ...filtros, nrMatricula });
                          }}
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-4 mb-3'>
                        <DatePicker
                          label='Recebido De'
                          text={filtros.dtInicialRecebimento}
                          maxLength={10}
                          mask={MaskTypes.Date}
                          onChange={(dtInicialRecebimento) =>
                            setFiltros({ ...filtros, dtInicialRecebimento })
                          }
                        />
                      </div>

                      <div className='col-4 mb-3'>
                        <DatePicker
                          label='Recebido Até'
                          text={filtros.dtFinalRecebimento}
                          maxLength={10}
                          mask={MaskTypes.Date}
                          onChange={(dtFinalRecebimento) =>
                            setFiltros({ ...filtros, dtFinalRecebimento })
                          }
                        />
                      </div>

                      <div className='col-3 mb-3'>
                        <Switch
                          formControl
                          checked={filtros.flgDtCredito}
                          label='Data de Crédito'
                          onChange={(flgDtCredito) =>
                            setFiltros({
                              ...filtros,
                              flgDtCredito,
                            })
                          }
                        />
                      </div>
                      <div className='row'>
                        <div className='col-4 mb-2'>
                          <DatePicker
                            label='Vencimento De'
                            text={filtros.dtInicialVencimento}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtInicialVencimento) =>
                              setFiltros({ ...filtros, dtInicialVencimento })
                            }
                          />
                        </div>

                        <div className='col-4 mb-2'>
                          <DatePicker
                            label='Vencimento Até'
                            text={filtros.dtFinalVencimento}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtFinalVencimento) =>
                              setFiltros({ ...filtros, dtFinalVencimento })
                            }
                          />
                        </div>
                        <div className='col-4 mb-3'>
                          <Textbox
                            maxLength={100}
                            label='Observação'
                            text={filtros.noMotivoAlteracao}
                            onChangedValue={(noMotivoAlteracao) =>
                              setFiltros({ ...filtros, noMotivoAlteracao })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-4 mt-2'>
                    <div className='card mb-2'>
                      <div className='card-body'>
                        <div className='display-8 mb-2'>Filtros de valores</div>
                        <div className='row mt-3'>
                          <div className='col mb-2'>
                            <FilterTextOperator
                              label='Valor Original'
                              text={filtros.vlrOriginal}
                              onChangedValue={(e) =>
                                setFiltros({
                                  ...filtros,
                                  vlrOriginal: e !== '' ? e : null,
                                  opVlrOriginal: filtros.opVlrOriginal ?? '>=',
                                })
                              }
                              filterProp={filtros.opVlrOriginal}
                              onSelectItem={(item) =>
                                setFiltros({
                                  ...filtros,
                                  opVlrOriginal: item.value,
                                  vlrOriginal: filtros.vlrOriginal ?? 0,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col mb-2'>
                            <FilterTextOperator
                              label='Valor Recalculado'
                              text={filtros.vlrRecalculado}
                              onChangedValue={(e) =>
                                setFiltros({
                                  ...filtros,
                                  vlrRecalculado: e !== '' ? e : null,
                                  opVlrRecalculado:
                                    filtros.opVlrRecalculado ?? '>=',
                                })
                              }
                              filterProp={filtros.opVlrRecalculado}
                              onSelectItem={(item) =>
                                setFiltros({
                                  ...filtros,
                                  opVlrRecalculado: item.value,
                                  vlrRecalculado: filtros.vlrRecalculado ?? 0,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className='row mb-3'>
                          <div className='col mb-2'>
                            <FilterTextOperator
                              label='Valor Resultado'
                              text={filtros.vlrResultado}
                              onChangedValue={(e) => {
                                setFiltros({
                                  ...filtros,
                                  vlrResultado: e !== '' ? e : null,
                                  opVlrResultado:
                                    filtros.opVlrResultado ?? '>=',
                                });
                              }}
                              filterProp={filtros.opVlrResultado}
                              onSelectItem={(item) =>
                                setFiltros({
                                  ...filtros,
                                  opVlrResultado: item.value,
                                  vlrResultado: filtros.vlrResultado ?? 0,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Panel.Body>
          </Panel>
        </div>
      </div>

      <div className='row mt-2'>
        <div className='col mb-4'>
          <PanelResumo convenioExtrato={convenioExtrato} />
        </div>
      </div>
      {isVisible && !filtros.flgRelPorConvenio && (
        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              canControlVisibility
              onColumnSort={onColumnSort}
              onPageSizeChange={onPageSizeChange}
              onPageChange={onPageChange}
              showPagination
              onRowDoubleClick={onRowDoubleClick}
              transaction={transaction}
              enableExcelExport
            />
          </div>
        </div>
      )}
      {isVisibleRelPorConvenio && filtros.flgRelPorConvenio && (
        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridViewRelPorConvenio}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnsRelatorio}
              canControlVisibility
              onColumnSort={onColumnSort}
              onPageSizeChange={onPageSizeChange}
              onPageChange={onPageChange}
              showPagination
              transaction={transaction}
              enableExcelExport
            />
          </div>
        </div>
      )}

      <ModalImportacaoConvenio
        convenio={convenioExtrato}
        show={modalShow.importarConvenio}
        onSave={(msg, status) => {
          onSetMessage(status, msg);
        }}
        onClose={() => {
          setModalShow({ ...modalShow, importarConvenio: false });
        }}
      />

      <ModalGerarLancamento
        convenio={convenioExtrato}
        show={modalShow.gerarLancamento}
        onSave={(msg, status) => {
          onSetMessage(status, msg);
        }}
        onClose={() => {
          setModalShow({ ...modalShow, gerarLancamento: false });
        }}
      />

      <ModalExportarLancamentos
        show={modalShow.exportarLancamentos}
        onSave={(msg, status) => {
          onSetMessage(status, msg);
        }}
        onClose={() => {
          setModalShow({ ...modalShow, exportarLancamentos: false });
        }}
      />

      <ModalVinculaPlanoAssociado
        dadosAssociadoPlano={dadosPlanoAssociado}
        show={modalShow.vincularPlanoAssociado}
        onSave={(msg, status) => {
          onSetMessage(status, msg);
          search();
        }}
        onClose={() => {
          setModalShow({ ...modalShow, vincularPlanoAssociado: false });
        }}
      />
    </CSDSelPage>
  );
}
