import Empresa from 'core/models/SEG/empresa';
import Estado from 'core/models/SEG/estado';

export default class EmpresaTipoDocumentoEletronico {
  constructor(jsonObject = {}) {
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.tipoDocumentoEletronico = jsonObject.tipoDocumentoEletronico;
    this.flgAmbienteProducao = jsonObject.flgAmbienteProducao;
    this.nrSeqEstado = jsonObject.nrSeqEstado;
    this.diretorioXml = jsonObject.diretorioXml;
    this.noCertificado = jsonObject.noCertificado;
    this.tpEmissao = jsonObject.tpEmissao;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.certificadodigitalthumbprint = jsonObject.certificadodigitalthumbprint;
    this.crt = jsonObject.crt;
    this.flgEnviaDocumentoAutomatico = jsonObject.flgEnviaDocumentoAutomatico;
    this.versao = jsonObject.versao;
    this.noProtocoloEnvio = jsonObject.noProtocoloEnvio;
    this.flgSincrono = jsonObject.flgSincrono;
    this.vlrAliquotaIssqn = jsonObject.vlrAliquotaIssqn;
    this.vlrAliquotaSimples = jsonObject.vlrAliquotaSimples;
    this.vlrAliquotaPis = jsonObject.vlrAliquotaPis;
    this.vlrAliquotaCofins = jsonObject.vlrAliquotaCofins;
    this.vlrAliquotaIRRetido = jsonObject.vlrAliquotaIRRetido;
    this.vlrAliquotaIRContador = jsonObject.vlrAliquotaIRContador;
    this.vlrAliquotaIRDesoneradoContador =
      jsonObject.vlrAliquotaIRDesoneradoContador;
    this.flgMdfeIntegrado = jsonObject.flgMdfeIntegrado;
    this.flgPadraoNacional = jsonObject.flgPadraoNacional;
    this.idCscProducao = jsonObject.idCscProducao;
    this.idCscHomologacao = jsonObject.idCscHomologacao;
    this.noCscProducao = jsonObject.noCscProducao;
    this.noCscHomologacao = jsonObject.noCscHomologacao;
    this.nrNsuNfe = jsonObject.nrNsuNfe;
    this.noUltimoXmlConsultaSefaz = jsonObject.noUltimoXmlConsultaSefaz;
    this.dtUltimaConsultaSefaz = jsonObject.dtUltimaConsultaSefaz;
    this.nrConsultasSefaz = jsonObject.nrConsultasSefaz;
    this.noClientId = jsonObject.noClientId;
    this.noClientSecret = jsonObject.noClientSecret;
    this.noSenhaAutenticacao = jsonObject.noSenhaAutenticacao;
    this.noCodigoAEDF = jsonObject.noCodigoAEDF;
    this.serie = jsonObject.serie;
    this.noCertificadoConteudo = jsonObject.noCertificadoConteudo;
    this.noCertificadoSenha = jsonObject.noCertificadoSenha;
    this.noCertificadoSenhaDecript = jsonObject.noCertificadoSenhaDecript;

    // #region Propriedades Auxiliares
    this.protocoloEnvio = jsonObject.protocoloEnvio;
    this.noTipoDocumentoEletronico = jsonObject.noTipoDocumentoEletronico;
    this.noTpEmissao = jsonObject.noTpEmissao;
    this.noCrt = jsonObject.noCrt;
    this.noVersao = jsonObject.noVersao;
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.estado = new Estado(jsonObject.estado ?? {});
    this.noBase64Certificado = jsonObject.noBase64Certificado;
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
