import React from 'react';

const Loader = ({ loading }) => {
  if (loading)
    return (
      <>
        <div className='loader-background' />
        <div className='loader-spinner spinner'>
          <div />
          <div />
        </div>
      </>
    );

  return null;
};

export default Loader;
