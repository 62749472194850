import React, { useState } from 'react';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { Button, Modal, Notification, Textarea } from 'ui/components';

export default function ModalCancelamento({
  show,
  onClose,
  dataPedidoCompra,
  onCancel,
  setData,
}) {
  const [message, setMessage] = useState(null);

  return (
    <Modal
      show={show}
      onClose={() => {
        onClose();
      }}
      size={BootstrapSizes.Small}
    >
      <Modal.Body className='p-3 pt-3'>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='col'>
            <h6>
              Deseja cancelar o Pedido de Compra cód:
              {dataPedidoCompra?.nrSeqPedidoCompra} ?
            </h6>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <Textarea
              label='Motivo do cancelamento'
              rows={2}
              maxLength={300}
              text={dataPedidoCompra?.noMotivoCancelamento}
              onChangedValue={(noMotivoCancelamento) => {
                setData({ ...dataPedidoCompra, noMotivoCancelamento });
              }}
            />
          </div>
        </div>
        <div className='row justify-content-evenly'>
          <div className='col-2 mt-4'>
            <Button
              outline
              theme={Theme.Success}
              template={Button.Templates.Button}
              text='Confirmar'
              icon='fa-solid fa-cloud-check'
              onClick={onCancel}
            />
          </div>
          <div className='col-2 mt-4'>
            <Button
              outline
              text='Voltar'
              theme={Theme.Danger}
              icon='fas fa-times'
              onClick={() => {
                onClose();
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
