import React, { useState } from 'react';

import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { Button, Loader, Modal, Notification, DatePicker } from 'ui/components';

import { MaskTypes } from 'ui/Helpers/masks';

import { confirmCheque } from 'core/services/FIN/cheque';

export default function ModalCompensarCheque({ cheque, show, onClose }) {
  const [data, setData] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const chequeDescricao = {
    fontWeight: 500,
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSend = async () => {
    setLoading(true);

    const obj = cheque;
    obj.dtCompensacao = data.dtCompensacao;

    const { status, message: msg } = await confirmCheque(obj);
    setLoading(false);
    onSetMessage(status, msg);
  };

  return (
    <Modal
      show={show}
      title='Compensar Cheque'
      onClose={onClose}
      size={BootstrapSizes.Large}
      icon={['far', 'comment-alt']}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='row mb-4'>
          <div className='col-sm-3'>
            <DatePicker
              label='Data de Compensação'
              text={data.dtCompensacao}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtCompensacao) => setData({ ...data, dtCompensacao })}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12'>
            <div className='alert alert-info fs-8' role='alert'>
              <p>
                {cheque && (
                  <span className={chequeDescricao}>
                    Realizar a compensação do cheque {cheque.noCheque} n°{' '}
                    {cheque.nrCheque} de valor: {cheque.vlrCheque.toFixed(2)}
                  </span>
                )}
              </p>
            </div>
            .
          </div>
        </div>

        <div />
      </Modal.Body>
      <Modal.Footer>
        <Button text='Compensar' theme={Theme.Success} onClick={onSend} />
        <Button
          text='Cancelar'
          theme={Theme.Danger}
          onClick={() => {
            setData({});
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
