export default class TipoSeguro {
  constructor(jsonObject = {}) {
    this.nrSeqTipoSeguro = jsonObject.nrSeqTipoSeguro;
    this.cdTipoSeguro = jsonObject.cdTipoSeguro;
    this.noTipoSeguro = jsonObject.noTipoSeguro;
    this.flgRouboAntt = jsonObject.flgRouboAntt;
    this.flgTerceiro = jsonObject.flgTerceiro;
    this.flgTombamentoAntt = jsonObject.flgTombamentoAntt;
    this.flgOutro = jsonObject.flgOutro;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
