import React, { useEffect, useRef, useState } from 'react';
import { MaskTypes } from 'ui/Helpers/masks';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getManutencaoVeiculoPagined,
  printManutencaoVeiculoReport,
  printManutencaoVeiculoReportPorOficina,
  printManutencaoVeiculoReportPorOficinaCompra,
  printManutencaoVeiculoReportPorPlaca,
  deleteAllManutencaoVeiculo,
  excelManutencaoVeiculo,
  excelManutencaoVeiculoPorOficina,
  excelManutencaoVeiculoPorOficinaCompra,
  excelManutencaoVeiculoPorPlaca,
} from 'core/services/FRO/manutencaoVeiculo';
import { getOficinaAutocomplete } from 'core/services/FRO/oficina';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getProdutoAgrupadorAutoCompleteDetalhado } from 'core/services/EST/produtoAgrupador';
import { getProdutoGrupoAutoComplete } from 'core/services/EST/produtoGrupo';
import { getProdutoSubGrupoAutoComplete } from 'core/services/EST/produtoSubGrupo';
import {
  CSDSelPage,
  GridView,
  ToolbarButtons,
  Panel,
  Textbox,
  Autocomplete,
  Switch,
  DatePicker,
  RadioButton,
  // Button,
} from 'ui/components';

export const BootstrapSizes = {
  ExtraSmall: 'xs',
  Small: 'sm',
  Medium: 'md',
  Large: 'lg',
  ExtraLarge: 'xl',
};

const columns = [
  { key: 'nrSeqManutencaoVeiculo', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdManutencaoVeiculo', title: 'Código', sortable: false },
  {
    key: 'dtDocumento',
    title: 'Data',
    format: GridView.DataTypes.Date,
    sortable: false,
  },
  { key: 'nrDocumento', title: 'Nº Documento', sortable: false },
  { key: 'oficina.noOficina', title: 'Oficina', sortable: false },
  {
    key: 'vlrTotal',
    title: 'Total',
    format: GridView.DataTypes.Money,
    sortable: false,
  },
  { key: 'noPlacasConcat', title: 'Placas', sortable: false },
];

export default function ManutencaoVeiculo({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getManutencaoVeiculoPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      setFiltros({ ...filtros, flgTipoOficina: 'Todas' });

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ManutencaoVeiculo/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqManutencaoVeiculo = sourceRow.find(
      (e) => e.key === 'nrSeqManutencaoVeiculo'
    );
    onOpenMaintenance(nrSeqManutencaoVeiculo.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printManutencaoVeiculoReport(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onPrintPorOficina = async () => {
    setLoading(true);
    const { value } = await printManutencaoVeiculoReportPorOficina(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onPrintPorOficinaCompra = async () => {
    setLoading(true);
    const { value } = await printManutencaoVeiculoReportPorOficinaCompra(
      filtros
    );

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onPrintPorPlaca = async () => {
    setLoading(true);
    const { value } = await printManutencaoVeiculoReportPorPlaca(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDownloadExcel = async () => {
    setLoading(true);

    const value = await excelManutencaoVeiculo(filtros);

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'ManutencaoVeiculo.xls';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onDownloadExcelPorOficina = async () => {
    setLoading(true);

    const value = await excelManutencaoVeiculoPorOficina(filtros);

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'ManutencaoVeiculoPorOficina.xls';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onDownloadExcelPorOficinaCompra = async () => {
    setLoading(true);

    const value = await excelManutencaoVeiculoPorOficinaCompra(filtros);

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'ManutencaoVeiculoPorOficinaCompra.xls';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onDownloadExcelPorPlaca = async () => {
    setLoading(true);

    const value = await excelManutencaoVeiculoPorPlaca(filtros);

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'ManutencaoVeiculoPorPlaca.xls';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllManutencaoVeiculo(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchOficina = async (e) => {
    const { status, message: msg, oficinas } = await getOficinaAutocomplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);
    return oficinas;
  };

  const onSearchVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
    });

    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onSearchProdutoAgrupador = async (e) => {
    const {
      status,
      message: msg,
      data: produtoAgrupador,
    } = await getProdutoAgrupadorAutoCompleteDetalhado({
      noProduto: e,
    });

    if (msg) onSetMessage(status, msg);
    return produtoAgrupador;
  };

  const onSearchProdutoGrupo = async (e) => {
    const {
      status,
      message: msg,
      data: produtoGrupo,
    } = await getProdutoGrupoAutoComplete({
      noProdutoGrupo: e,
    });

    if (msg) onSetMessage(status, msg);
    return produtoGrupo;
  };

  const onSearchProdutoSubGrupo = async (e) => {
    const {
      status,
      message: msg,
      data: produtoSubGrupo,
    } = await getProdutoSubGrupoAutoComplete({
      noProdutoSubGrupo: e,
      nrSeqProdutoGrupo: filtros.nrSeqProdutoGrupo,
    });

    if (msg) onSetMessage(status, msg);
    return produtoSubGrupo;
  };

  const tipeOficina = [
    { text: 'Internas', value: 'Internas' },
    { text: 'Externas', value: 'Externas' },
    { text: 'Todas', value: 'Todas' },
  ];

  return (
    <CSDSelPage
      isActive={isActive}
      title='Manutenção Veículo'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.ButtonList
          onPrint
          icon='caret-down'
          items={[
            {
              text: 'Imprimir por Oficina',
              onClick: () => onPrintPorOficina('Agrupado'),
            },
            {
              text: 'Imprimir por Oficina - Compras',
              onClick: () => onPrintPorOficinaCompra('Agrupado'),
            },
            {
              text: 'Imprimir por Placa',
              onClick: () => onPrintPorPlaca('Agrupado'),
            },
          ]}
        />
      </ToolbarButtons>
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Excel'
          icon={['far', 'file-excel']}
          onClick={() => onDownloadExcel('Padrao')}
        />
        <ToolbarButtons.ButtonList
          onPrint
          icon='caret-down'
          items={[
            {
              text: 'Excel por Oficina',
              onClick: () => onDownloadExcelPorOficina('Agrupado'),
            },
            {
              text: 'Excel por Oficina - Compras',
              onClick: () => onDownloadExcelPorOficinaCompra('Agrupado'),
            },
            {
              text: 'Excel por Placa',
              onClick: () => onDownloadExcelPorPlaca('Agrupado'),
            },
          ]}
        />
      </ToolbarButtons>
      <Panel>
        <Panel.Header
          icon={['fas', 'search']}
          title='Filtros'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col-2 mb-2'>
              <Textbox
                maxLength={20}
                label='Código'
                text={filtros.cdManutencaoVeiculo}
                onChangedValue={(cdManutencaoVeiculo) =>
                  setFiltros({
                    ...filtros,
                    cdManutencaoVeiculo,
                  })
                }
              />
            </div>

            <div className='col mb-2'>
              <Autocomplete
                label='Oficina'
                searchDataSource={onSearchOficina}
                selectedItem={filtros.oficina}
                onSelectItem={(oficina) => {
                  setFiltros({
                    ...filtros,
                    oficina,
                    nrSeqPessoaOfi: oficina.nrSeqPessoaOfi,
                  });
                }}
                dataSourceTextProperty='noOficina'
              />
            </div>

            <div className='col-2 mb-2'>
              <Textbox
                maxLength={20}
                label='Nº Documento'
                text={filtros.nrDocumento}
                onChangedValue={(nrDocumento) =>
                  setFiltros({
                    ...filtros,
                    nrDocumento,
                  })
                }
              />
            </div>
          </div>

          <div className='row'>
            <div className='col mb-2'>
              <Autocomplete
                label='Veículo'
                searchDataSource={onSearchVeiculo}
                selectedItem={filtros.veiculo}
                onSelectItem={(veiculo) => {
                  setFiltros({
                    ...filtros,
                    veiculo,
                    nrSeqVeiculo: veiculo.nrSeqVeiculo,
                  });
                }}
                dataSourceTextProperty='placa'
              />
            </div>

            <div className='col mb-2'>
              <Textbox
                maxLength={20}
                label='Cód. Ordem de Serviço'
                text={filtros.nrSeqManutencaoVeiculoOS}
                onChangedValue={(nrSeqManutencaoVeiculoOS) =>
                  setFiltros({
                    ...filtros,
                    nrSeqManutencaoVeiculoOS,
                  })
                }
              />
            </div>

            <div className='col mb-2'>
              <Textbox
                maxLength={20}
                label='Cód. Interno Peça'
                text={filtros.cdProdutoCodigo}
                onChangedValue={(cdProdutoCodigo) =>
                  setFiltros({
                    ...filtros,
                    cdProdutoCodigo,
                  })
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-2 mb-2'>
              <Switch
                formControl
                label='Exceto'
                checked={filtros.flgExceto}
                onChange={(flgExceto) => setFiltros({ ...filtros, flgExceto })}
              />
            </div>
            {/* <div className='col-1 mt-3'>
              <Button
                outline
                theme={Theme.Primary}
                template={Button.Templates.Button}
                icon='search'
                tooltip='Pesquisar o endereço pelo CEP digitado.'
                // onClick={onSearchCep}
              />
            </div> */}

            <div className='col mb-2'>
              <Autocomplete
                label='Produto agrupador'
                searchDataSource={onSearchProdutoAgrupador}
                selectedItem={filtros.produtoAgrupador}
                onSelectItem={(produtoAgrupador) => {
                  setFiltros({
                    ...filtros,
                    produtoAgrupador,
                    nrSeqProdutoAgrupador:
                      produtoAgrupador.nrSeqProdutoAgrupador,
                  });
                }}
                dataSourceTextProperty='noProdutoAgrupador'
              />
            </div>
            {filtros.nrSeqProdutoAgrupador ? (
              <div className='col mb-2'>
                <Autocomplete
                  label='Grupo'
                  searchDataSource={onSearchProdutoGrupo}
                  selectedItem={filtros.produtoGrupo}
                  onSelectItem={(produtoGrupo) => {
                    setFiltros({
                      ...filtros,
                      produtoGrupo,
                      nrSeqProdutoGrupo: produtoGrupo.nrSeqProdutoGrupo,
                      noProdutoGrupo: produtoGrupo.noProdutoGrupo,
                    });
                  }}
                  dataSourceTextProperty='noProdutoGrupo'
                />
              </div>
            ) : (
              <div className='col mb-2'>
                <Autocomplete label='Grupo' />
              </div>
            )}
            {filtros.nrSeqProdutoGrupo ? (
              <div className='col mb-2'>
                <Autocomplete
                  label='Sub grupo'
                  searchDataSource={onSearchProdutoSubGrupo}
                  selectedItem={filtros.subGrupo}
                  onSelectItem={(subGrupo) => {
                    setFiltros({
                      ...filtros,
                      subGrupo,
                      nrSeqProdutoSubGrupo: subGrupo.nrSeqProdutoSubGrupo,
                      noProdutoSubGrupo: subGrupo.noProdutoSubGrupo,
                    });
                  }}
                  dataSourceTextProperty='noProdutoSubGrupo'
                />
              </div>
            ) : (
              <div className='col mb-2'>
                <Autocomplete label='Sub grupo' />
              </div>
            )}
          </div>
          <div className='row'>
            <div className='col-2 mb-2'>
              <DatePicker
                label='Data Inicial'
                text={filtros.dtDocInicio}
                mask={MaskTypes.Date}
                onChange={(dtDocInicio) =>
                  setFiltros({
                    ...filtros,
                    dtDocInicio,
                  })
                }
              />
            </div>

            <div className='col-2 mb-2'>
              <DatePicker
                label='Data Final'
                text={filtros.dtDocFim}
                mask={MaskTypes.Date}
                onChange={(dtDocFim) =>
                  setFiltros({
                    ...filtros,
                    dtDocFim,
                  })
                }
              />
            </div>

            <div className='col-4 mb-2'>
              <RadioButton
                label='Oficinas'
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={filtros.flgTipoOficina}
                buttons={tipeOficina}
                onChange={(flgTipoOficina) =>
                  setFiltros({
                    ...filtros,
                    flgTipoOficina,
                  })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
