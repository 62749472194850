import React, { useEffect, useRef, useState } from 'react';
import {
  getTipoEmbarquePagined,
  printTipoEmbarque,
  deleteAllTipoEmbarque,
} from 'core/services/FRO/tipoEmbarque';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDSelPage, GridView, Panel, Textbox } from 'ui/components';
import { GridviewColumns, Message, Page } from 'ui/Helpers/interfaces';
import { ColumnTypes } from 'ui/Helpers/enums';

const columns: Array<GridviewColumns> = [
  { key: 'nrSeqTipoEmbarque', type: ColumnTypes.Checkbox },
  { key: 'cdTipoEmbarque', title: 'Código' },
  { key: 'noTipoEmbarque', title: 'Nome' },
];

export default function TipoEmbarque({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}: Page) {
  const gridView: any = useRef();

  const [filtros, setFiltros] = useState<Object>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const search = async (params: Object | null = null): Promise<void> => {
    setLoading(true);

    const { data, pagination } = await getTipoEmbarquePagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  useEffect((): void => {
    search();
  }, [isActive]);

  const onColumnSort = async (sortBy: string): Promise<void> => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page: number): Promise<void> => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onOpenMaintenance = (registryKey: number | null = null): void => {
    onOpenPage(
      transaction.noTransacao,
      'TipoEmbarque/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow: Array<any>): void => {
    const nrSeqTipoEmbarque = sourceRow.find(
      (e) => e.key === 'nrSeqTipoEmbarque'
    );

    onOpenMaintenance(nrSeqTipoEmbarque.value);
  };

  const onPrint = async (): Promise<void> => {
    setLoading(true);

    const { value } = await printTipoEmbarque(filtros);

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onDelete = async (): Promise<void> => {
    setLoading(true);

    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllTipoEmbarque(
        selecteds.map((row: any) => row[0].value)
      );

      setLoading(false);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);

      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Tipo Embarque'
      loading={loading}
      transaction={transaction}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <div className='row mb-3'>
        {/* @ts-expect-error */}
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header title='Filtros' theme={Theme.Primary} />
          <div className='col'>
            <Panel.Body>
              <div className='row'>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Código'
                    maxLength={20}
                    onChangedValue={(cdTipoEmbarque: string) =>
                      setFiltros({ ...filtros, cdTipoEmbarque })
                    }
                  />
                </div>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Descrição'
                    maxLength={60}
                    onChangedValue={(noTipoEmbarque: string) =>
                      setFiltros({ ...filtros, noTipoEmbarque })
                    }
                  />
                </div>
              </div>
            </Panel.Body>
          </div>
        </Panel>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            transaction={transaction}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            enableExcelExport
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
