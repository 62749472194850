import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  Switch,
  Panel,
  GridView,
  Button,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getPlanoConvenio,
  savePlanoConvenio,
  deletePlanoConvenio,
  getPlanoConvenioPagined,
} from 'core/services/ASS/planoConvenio';

import { getConvenioAutoComplete } from 'core/services/ASS/convenio';
import { getConvenioEncargoAutoComplete } from 'core/services/ASS/convenioEncargo';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import { getPlanoConvenioConvenioEncargoList } from 'core/services/ASS/planoConvenio_ConvenioEncargo';

export default function PlanoConvenioItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const prevDataRef = useRef();
  const [dataPlanoConvenioEncargos, setDataPlanoConvenioEncargos] = useState({
    flgPercentual: false,
    flgRepassa: false,
    flgExibeExtrato: false,
    flgAplicaValorAjuste: false,
    flgGeraComissao: false,
    flgRecalculo_IncluiVlrOriginal: false,
    flgRepassaVlrNegativo: false,
  });
  const [data, setData] = useState({
    listaEncargos: [],
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const gridViewEncargos = useRef();

  useEffect(() => {
    prevDataRef.current = data;
  }, [data]);

  const onClickRemovePlanoConvEncargoConv = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const list = datasource.filter((el) => el !== e);

    if (
      gridItem.status !== GridView.EnumStatus.Inserir &&
      gridItem.status !== GridView.EnumStatus.Alterar
    ) {
      gridItem.status = GridView.EnumStatus.Remover;

      list.push(gridItem);
    }

    if (gridViewEncargos && gridViewEncargos.current)
      gridViewEncargos.current.setDataSource(list);

    setData({
      ...prevDataRef.current,
      listaEncargos: list,
    });
  };
  const onClickEditPlanoConvEncargoConv = (e, datasource) => {
    const objPlanoConvEncargoConv = datasource.find((item) => item === e);

    if (objPlanoConvEncargoConv.status !== GridView.EnumStatus.Inserir) {
      objPlanoConvEncargoConv.status = GridView.EnumStatus.Alterar;
    }

    setDataPlanoConvenioEncargos({ ...objPlanoConvEncargoConv });

    const listaPlanoConvEncargoConv = datasource.filter((item) => item !== e);

    if (gridViewEncargos && gridViewEncargos.current)
      gridViewEncargos.current.setDataSource(listaPlanoConvEncargoConv);

    setData({
      ...prevDataRef.current,
      listaEncargos: listaPlanoConvEncargoConv,
    });
  };
  const columnsEncargos = [
    { key: 'convenioEncargo.noConvenioEncargo', title: 'Encargo' },
    { key: 'flgPercentual', title: 'Percentual' },
    { key: 'flgRepassa', title: 'Repassa' },
    { key: 'flgExibeExtrato', title: 'Exibe no Extrato' },
    { key: 'flgAplicaValorAjuste', title: 'Aplica Ajuste' },
    { key: 'flgGeraComissao', title: 'Gera Comissao' },
    {
      key: 'flgRecalculo_IncluiVlrOriginal',
      title: 'Recal. Incluir Valor Original',
    },
    { key: 'flgRepassaVlrNegativo', title: 'Repassa Valor Negativo' },
    { key: 'vlrOriginal', title: 'Valor Original' },
    { key: 'vlrRecalculado', title: 'Valor Recalculo' },
    {
      key: 'tituloEspecie.noEspecie',
      title: 'Titulo Espécie',
    },
    {
      key: 'nrSeqPlanoConvEncargoConv',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) =>
        onClickEditPlanoConvEncargoConv(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
      title: 'Editar',
    },
    {
      key: 'nrSeqPlanoConvEncargoConv',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) =>
        onClickRemovePlanoConvEncargoConv(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  const search = async (params = null) => {
    setLoading(true);

    let flgAtivo = false;

    if (data.status === 'S') {
      flgAtivo = true;
    }

    const { data: planoConvenio, pagination } = await getPlanoConvenioPagined(
      params ?? { ...data, flgAtivo }
    );

    if (gridViewEncargos && gridViewEncargos.current)
      gridViewEncargos.current.setDataSource(planoConvenio, pagination);

    setLoading(false);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...data, totalByPage };
    setData(filters);
    await search(filters);
  };
  const onSearchTituloEspecie = async (e) => {
    const {
      status,
      message: msg,
      data: tituloEspecies,
    } = await getTituloEspecieAutoComplete({ noTituloEspecie: e });
    if (msg) onSetMessage(status, msg);
    return tituloEspecies;
  };

  const onNew = () => {
    setData({});
    if (gridViewEncargos && gridViewEncargos.current) {
      gridViewEncargos.current.setDataSource([]);
    }
    setDataPlanoConvenioEncargos({
      flgPercentual: false,
      flgRepassa: false,
      flgExibeExtrato: false,
      flgAplicaValorAjuste: false,
      flgGeraComissao: false,
      flgRecalculo_IncluiVlrOriginal: false,
      flgRepassaVlrNegativo: false,
    });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const response = await getPlanoConvenio(registryKey);

      const { data: listaEncargos } = await getPlanoConvenioConvenioEncargoList(
        {
          nrSeqPlanoConvenio: response.nrSeqPlanoConvenio,
          nrSeqConvenio: response.nrSeqConvenio,
        }
      );

      if (gridViewEncargos && gridViewEncargos.current) {
        gridViewEncargos.current.setDataSource(listaEncargos);
      }

      setData({ ...response, listaEncargos });
      setLoading(false);
    } else {
      onNew();
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const obj = { ...data };
    const { status, message: msg } = await savePlanoConvenio(obj);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      onNew();
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deletePlanoConvenio(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchConvenio = async (e) => {
    const { status, message: msg, convenios } = await getConvenioAutoComplete({
      noConvenio: e,
    });
    if (msg) onSetMessage(status, msg);
    return convenios;
  };
  const onSearchConvenioEncargo = async (e) => {
    const {
      status,
      message: msg,
      convenioEncargos,
    } = await getConvenioEncargoAutoComplete({
      noConvenioEncargo: e,
      nrSeqConvenio: data.nrSeqConvenio,
    });
    if (msg) onSetMessage(status, msg);
    return convenioEncargos;
  };
  const duplicarPlano = async () => {
    const novoPlano = data;
    novoPlano.nrSeqPlanoConvenio = 0;

    novoPlano.listaEncargos.forEach((encargo) => {
      encargo.nrSeqPlanoConvEncargoConv = 0;
      encargo.status = GridView.EnumStatus.Inserir;
    });

    setLoading(true);
    const { status, message: msg } = await savePlanoConvenio(novoPlano);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      onNew();
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const adicionaEncargo = () => {
    if (!data.convenio?.nrSeqConvenio) return;

    const encargos = data.listaEncargos ?? [];

    const encargoStatus =
      dataPlanoConvenioEncargos.status === undefined ? 'Inserir' : 'Alterar';

    const novoEncargo = {
      ...dataPlanoConvenioEncargos,
      tituloEspecie: dataPlanoConvenioEncargos.tituloEspecie,
      status: encargoStatus,
    };

    encargos.push(novoEncargo);

    if (gridViewEncargos && gridViewEncargos.current) {
      gridViewEncargos.current.setDataSource(encargos);
    }
    setData({ ...data, listaEncargos: encargos });
    setDataPlanoConvenioEncargos({});
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Plano Convenio'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqPlanoConvenio > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Duplicar Plano'
          icon={['far', 'credit-card']}
          onClick={() => duplicarPlano()}
        />
      </ToolbarButtons>
      <Panel className='mb-3'>
        <Panel.Header title='informações Plano' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row'>
            <div className='col-5'>
              <Textbox
                maxLength={200}
                label='Plano Convenio'
                text={data.noPlanoConvenio}
                onChangedValue={(noPlanoConvenio) =>
                  setData({
                    ...data,
                    noPlanoConvenio,
                  })
                }
              />
            </div>

            <div className='col-4'>
              <Autocomplete
                label='Convenio'
                searchDataSource={onSearchConvenio}
                selectedItem={data.convenio}
                onSelectItem={(convenio) => {
                  setData({
                    ...data,
                    convenio,
                    nrSeqConvenio: convenio.nrSeqConvenio,
                  });
                }}
                dataSourceTextProperty='noConvenio'
              />
            </div>

            <div className='col mb-3'>
              <Switch
                formControl
                label='Ativo'
                checked={data.flgAtivo}
                onChange={(flgAtivo) => setData({ ...data, flgAtivo })}
              />
            </div>
            <div className='col mb-3'>
              <Switch
                formControl
                label='Gera Lanc Manual'
                checked={data.flgGeraLancamentoManual}
                onChange={(flgGeraLancamentoManual) =>
                  setData({
                    ...data,
                    flgGeraLancamentoManual,
                  })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>

      <Panel className='mb-3'>
        <Panel.Header title='Encargos à Associar ' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-4'>
              <Autocomplete
                label='Encargo'
                readOnly={!data.convenio?.nrSeqConvenio}
                enabled={!!data.convenio?.nrSeqConvenio}
                searchDataSource={onSearchConvenioEncargo}
                selectedItem={dataPlanoConvenioEncargos.convenioEncargo}
                onSelectItem={(convenioEncargo) => {
                  setDataPlanoConvenioEncargos({
                    ...dataPlanoConvenioEncargos,
                    convenioEncargo,
                    nrSeqConvenioEncargo: convenioEncargo.nrSeqConvenioEncargo,
                  });
                }}
                dataSourceTextProperty='noConvenioEncargo'
              />
            </div>
            <div className='col mb-3'>
              <Switch
                formControl
                label='Percentual'
                checked={dataPlanoConvenioEncargos.flgPercentual}
                onChange={(flgPercentual) =>
                  setDataPlanoConvenioEncargos({
                    ...dataPlanoConvenioEncargos,
                    flgPercentual,
                  })
                }
                disabled={!data.convenio?.nrSeqConvenio}
              />
            </div>
            <div className='col mb-3'>
              <Switch
                formControl
                label='Repassa Valor'
                checked={dataPlanoConvenioEncargos.flgRepassa}
                onChange={(flgRepassa) =>
                  setDataPlanoConvenioEncargos({
                    ...dataPlanoConvenioEncargos,
                    flgRepassa,
                  })
                }
                disabled={!data.convenio?.nrSeqConvenio}
              />
            </div>
            <div className='col mb-3'>
              <Switch
                formControl
                label='Exibe no Extrato'
                checked={dataPlanoConvenioEncargos.flgExibeExtrato}
                onChange={(flgExibeExtrato) =>
                  setDataPlanoConvenioEncargos({
                    ...dataPlanoConvenioEncargos,
                    flgExibeExtrato,
                  })
                }
                disabled={!data.convenio?.nrSeqConvenio}
              />
            </div>
            <div className='col mb-3'>
              <Switch
                formControl
                label='Aplica Ajuste'
                checked={dataPlanoConvenioEncargos.flgAplicaValorAjuste}
                onChange={(flgAplicaValorAjuste) =>
                  setDataPlanoConvenioEncargos({
                    ...dataPlanoConvenioEncargos,
                    flgAplicaValorAjuste,
                  })
                }
                disabled={!data.convenio?.nrSeqConvenio}
              />
            </div>
            <div className='col mb-3'>
              <Switch
                formControl
                label='Gera Comissão'
                checked={dataPlanoConvenioEncargos.flgGeraComissao}
                onChange={(flgGeraComissao) =>
                  setDataPlanoConvenioEncargos({
                    ...dataPlanoConvenioEncargos,
                    flgGeraComissao,
                  })
                }
                disabled={!data.convenio?.nrSeqConvenio}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-4'>
              <Autocomplete
                label='Espécie Título'
                readOnly={!data.convenio?.nrSeqConvenio}
                enabled={!!data.convenio?.nrSeqConvenio}
                searchDataSource={onSearchTituloEspecie}
                selectedItem={dataPlanoConvenioEncargos.tituloEspecie}
                onSelectItem={(tituloEspecie) => {
                  setDataPlanoConvenioEncargos({
                    ...dataPlanoConvenioEncargos,
                    tituloEspecie,
                    nrSeqTituloEspecie: tituloEspecie.nrSeqTituloEspecie,
                  });
                }}
                dataSourceTextProperty='noEspecie'
              />
            </div>
            <div className='col-2'>
              <Textbox
                readOnly={!data.convenio?.nrSeqConvenio}
                enabled={!!data.convenio?.nrSeqConvenio}
                mask={MaskTypes.Decimal}
                label='Valor Original'
                text={dataPlanoConvenioEncargos.vlrOriginal}
                onChangedValue={(vlrOriginal) =>
                  setDataPlanoConvenioEncargos({
                    ...dataPlanoConvenioEncargos,
                    vlrOriginal,
                  })
                }
              />
            </div>
            <div className='col-2'>
              <Textbox
                readOnly={!data.convenio?.nrSeqConvenio}
                enabled={!!data.convenio?.nrSeqConvenio}
                mask={MaskTypes.Decimal}
                label='Valor Recalculo'
                text={dataPlanoConvenioEncargos.vlrRecalculado}
                onChangedValue={(vlrRecalculado) =>
                  setDataPlanoConvenioEncargos({
                    ...dataPlanoConvenioEncargos,
                    vlrRecalculado,
                  })
                }
              />
            </div>
            <div className='col mb-3'>
              <Switch
                formControl
                label='Recal. Incluir Valor Original'
                checked={
                  dataPlanoConvenioEncargos.flgRecalculo_IncluiVlrOriginal
                }
                onChange={(flgRecalculoIncluiVlrOriginal) =>
                  setDataPlanoConvenioEncargos({
                    ...dataPlanoConvenioEncargos,
                    flgRecalculo_IncluiVlrOriginal: flgRecalculoIncluiVlrOriginal,
                  })
                }
                disabled={!data.convenio?.nrSeqConvenio}
              />
            </div>
            <div className='col mb-3'>
              <Switch
                formControl
                label='Repassa Valor Negativo'
                checked={dataPlanoConvenioEncargos.flgRepassaVlrNegativo}
                onChange={(flgRepassaVlrNegativo) =>
                  setDataPlanoConvenioEncargos({
                    ...dataPlanoConvenioEncargos,
                    flgRepassaVlrNegativo,
                  })
                }
                disabled={!data.convenio?.nrSeqConvenio}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col mt-2 d-flex align-itens-end'>
              <Button
                disabled={!data.convenio?.nrSeqConvenio}
                outline
                icon='plus'
                size={BootstrapSizes.Medium}
                theme={Theme.Primary}
                template={Button.Templates.Default}
                onClick={adicionaEncargo}
                text='Adicionar'
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>

      <Panel className='mb-3'>
        <Panel.Header title='Encargos Associados' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col'>
              <GridView
                ref={gridViewEncargos}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columnsEncargos}
                onPageSizeChange={onPageSizeChange}
                transaction={transaction}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </CSDManutPage>
  );
}
