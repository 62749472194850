import qs from 'qs';
import { ResumoSinteticoFinanceiro } from 'core/models/TEL';
import api from '../api';

const base = 'TEL/GeraContaPdf';

export const getGeraContaPdf = async (filters) => {
  const response = await api.get(`${base}/`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });

  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    pagination,
    resumoSintetico: value ? new ResumoSinteticoFinanceiro(value) : {},
  };
};

export const postGeraContaPdf = async (parameters) => {
  const response = await api.post(`${base}/`, parameters);
  return response.data ?? {};
};
