import Transacao from 'core/models/SEG/transacao';

export default class UsuarioTransacaoInicial {
  constructor(jsonObject = {}) {
    this.nrSeqTransacaoInicial = jsonObject.nrSeqTransacaoInicial;
    this.nrSeqTransacao = jsonObject.nrSeqTransacao;
    this.nrOrdem = jsonObject.nrOrdem;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.transacao = new Transacao(jsonObject.transacao ?? {});
  }

  toJson = () => JSON.stringify(this);
}
