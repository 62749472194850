import TipoCartao from './tipoCartao';

export default class TipoCartaoVinculo {
  constructor(jsonObject = {}) {
    this.nrSeqTipoCartao_Vinculo = jsonObject.nrSeqTipoCartao_Vinculo;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.numeroCartao = jsonObject.numeroCartao;
    this.dtInicio = jsonObject.dtInicio;
    this.dtFim = jsonObject.dtFim;
    this.noSenha = jsonObject.noSenha;
    this.nrSeqPessoaMot = jsonObject.nrSeqPessoaMot;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.nrSeqTipoCartao = jsonObject.nrSeqTipoCartao;
    this.vlrSaldo = jsonObject.vlrSaldo;
    this.tipoCartao = new TipoCartao(jsonObject.tipoCartao ?? {});
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
