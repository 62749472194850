import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { Modal, Loader, Notification, FileUpload, Button } from 'ui/components';

import { BootstrapSizes, MimeTypes, Theme } from 'ui/Helpers/utils';

import { importNf } from 'core/services/FRO/manifestoNfv';

const ModalImportaNota = forwardRef(({ props, show, onClose }, param) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [importedFiles, setImportedFiles] = useState([]);

  useImperativeHandle(param, () => ({
    param: importedFiles,
  }));

  const importFile = async () => {
    setLoading(true);

    if (importedFiles.length > 0) {
      const promises = [];
      const datasource = [];

      importedFiles.forEach(async (file, index) => {
        const promise = new Promise((resolve, reject) => {
          setTimeout(async () => {
            importNf(file)
              .then((result) => resolve(result))
              .catch((error) => reject(error));
          }, index * 1000);
        });

        promises.push(promise);
      });

      await Promise.all(promises).then((results) =>
        results.forEach((result) => datasource.push(result))
      );

      onClose(datasource);
      setImportedFiles([]);
      setMessage(null);
    } else {
      setMessage({
        message: 'Não há arquivos selecionados para importação.',
        theme: Theme.Danger,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (show) setImportedFiles([]);
  }, [show, props]);

  const onSend = () => {
    setImportedFiles([]);
    onClose();
  };

  return (
    <Modal
      show={show}
      title='Importar XML NFE'
      icon='cloud-upload-alt'
      onClose={() => onSend()}
      size={BootstrapSizes.Large}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-4'>
          <FileUpload
            files={importedFiles}
            onChange={(files) => {
              setImportedFiles(files);
              setMessage(null);
            }}
            allowedMimeTypes={[MimeTypes.Types.XML]}
            maxFiles={5}
            dropzone
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Small}
          icon='cloud-upload-alt'
          theme={Theme.Success}
          template={Button.Templates.Default}
          onClick={importFile}
          text='Importar'
        />
        <Button
          size={BootstrapSizes.Small}
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          onClick={() => onSend()}
          text='Sair'
        />
      </Modal.Footer>
    </Modal>
  );
});

export default ModalImportaNota;
