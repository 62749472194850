export default class Comprador {
  constructor(jsonObject = {}) {
    this.nrSeqPessoaCop = jsonObject.nrSeqPessoaCop;
    this.cdComprador = jsonObject.cdComprador;
    this.flgAtivo = jsonObject.flgAtivo;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqCompradorNivel = jsonObject.nrSeqCompradorNivel;
    this.noPessoa = jsonObject.noPessoa;
  }

  toJson = () => JSON.stringify(this);
}
