import React, { useState } from 'react';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { Button, Loader, Modal, Notification, Textbox } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { exportar } from 'core/services/ASS/lancamentoAssociado';

export default function ModalExportarLancamentos({ show, onClose }) {
  const [dataModal, setDataModal] = useState({
    flgExportarArquivo: false,
  });
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onClickExportar = async () => {
    if (!dataModal.anoMes) {
      setMessage({
        message: 'Preencha a competência para gerar o documento.',
        theme: Theme.Danger,
      });
    } else {
      setLoading(true);
      const { message: msg, status, value: base64 } = await exportar(dataModal);
      if (base64) {
        const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = 'LancamentosExportados.csv';
        downloadLink.click();

        setDataModal({});
        onClose();
      }

      if (msg)
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
    }
    setLoading(false);
  };

  return (
    <Modal
      show={show}
      title=' Exportar Lançamentos'
      onClose={() => {
        setDataModal({});
        onClose();
      }}
      size={BootstrapSizes.Medium}
    >
      <Modal.Body className='p-3 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='row justify-content-evenly'>
          <div className='col-4 mb-3'>
            <Textbox
              maxLength={7}
              label='Competência'
              mask={MaskTypes.Competence}
              text={dataModal.anoMes}
              onChangedValue={(anoMes) => {
                setDataModal({ ...dataModal, anoMes });
              }}
            />
          </div>

          <div className='col-2 mt-4'>
            <Button
              outline
              theme={Theme.Success}
              template={Button.Templates.Button}
              text='Exportar'
              icon='fa-solid fa-cloud-check'
              onClick={onClickExportar}
            />
          </div>
          <div className='col-2 mt-4'>
            <Button
              outline
              text='Cancelar'
              theme={Theme.Danger}
              icon='fas fa-times'
              onClick={() => {
                setDataModal({});
                onClose();
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
