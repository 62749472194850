import React, { useEffect, useRef, useState } from 'react';
import {
  deleteAllInfracao,
  getInfracaoPagined,
  printInfracao,
} from 'core/services/FRO/infracao';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDSelPage, GridView } from 'ui/components';
import { ColumnTypes } from 'ui/Helpers/enums';
import { GridviewColumns, Message, Page } from 'ui/Helpers/interfaces';

const columns: Array<GridviewColumns> = [
  { key: 'nrSeqInfracao', type: ColumnTypes.Checkbox },
  { key: 'cdInfracao', title: 'Código' },
  { key: 'noInfracao', title: 'Nome' },
];

export default function Infracao({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}: Page) {
  const gridView: any = useRef();

  const [filtros, setFiltros] = useState<Object>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const search = async (params: Object | null = null): Promise<void> => {
    setLoading(true);

    const { data, pagination } = await getInfracaoPagined(params ?? filtros);

    if (gridView && gridView.current) {
      gridView.current.setDataSource(data, pagination);
    }

    setLoading(false);
  };

  useEffect((): void => {
    setLoading(true);

    search();

    setLoading(false);
  }, [isActive]);

  const onColumnSort = async (sortBy: string): Promise<void> => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page: number): Promise<void> => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onOpenMaintenance = (registryKey: number | null = null): void => {
    onOpenPage(
      transaction.noTransacao,
      'Infracao/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow: Array<any>): void => {
    const nrSeqInfracao = sourceRow.find(
      (infracoes) => infracoes.key === 'nrSeqInfracao'
    );
    onOpenMaintenance(nrSeqInfracao.value);
  };

  const onDelete = async (): Promise<void> => {
    setLoading(true);

    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllInfracao(
        selecteds.map((row: any) => row[0].value)
      );

      setLoading(false);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);

      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onPrint = async (): Promise<void> => {
    setLoading(true);

    const { value } = await printInfracao(filtros);

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Cadastro descrição da infração'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
