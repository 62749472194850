import React, { useState } from 'react';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { Button, Loader, Modal, Notification, DatePicker } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';

export default function ModalPrintCartao({
  show,
  onClose,
  dataAss,
  setData,
  onClickConfirm,
}) {
  const [message, setMessage] = useState(null);

  const onConfirm = async () => {
    if (!dataAss.dataValidade) {
      setMessage({
        message: 'Necessário preencher a data de validade.',
        theme: Theme.Danger,
      });
    } else {
      onClickConfirm();

      onClose();
    }
  };

  return (
    <Modal
      show={show}
      title='Data de Validade do Cartão'
      onClose={() => {
        onClose();
      }}
      size={BootstrapSizes.Large}
    >
      <Modal.Body className='p-3 pt-3'>
        <Loader />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='row justify-content-evenly mb-4'>
          <div className='col mt-4'>
            <DatePicker
              label='* Data Validade'
              text={dataAss?.dataValidade}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dataValidade) => setData({ ...dataAss, dataValidade })}
            />
          </div>
        </div>
        <div className='row justify-content-evenly mt-4'>
          <div className='col-2 mt-4'>
            <Button
              outline
              theme={Theme.Success}
              template={Button.Templates.Button}
              text='Salvar'
              onClick={onConfirm}
            />
          </div>
          <div className='col-2 mt-4'>
            <Button
              outline
              text='Cancelar'
              theme={Theme.Danger}
              onClick={() => {
                onClose();
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
