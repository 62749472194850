import Motorista from './motorista';
import TipoOcorrencia from './tipoOcorrencia';
import Veiculo from './veiculo';
import VeiculoOcorrenciaDocumento from './veiculoOcorrenciaDocumento';

export default class LancamentoOcorrencia {
  constructor(jsonObject = {}) {
    this.nrSeqVeiculoOcorrencia = jsonObject.nrSeqVeiculoOcorrencia;
    this.nrSeqPessoaMot = jsonObject.nrSeqPessoaMot;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.dtOcorrencia = jsonObject.dtOcorrencia;
    this.descricao = jsonObject.descricao;
    this.hrOcorrencia = jsonObject.hrOcorrencia;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqTipoOcorrencia = jsonObject.nrSeqTipoOcorrencia;
    this.noLogin = jsonObject.noLogin;
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    this.motorista = new Motorista(jsonObject.motorista ?? {});
    this.tipoOcorrencia = new TipoOcorrencia(jsonObject.tipoOcorrencia ?? {});
    this.veiculoOcorrenciaDocumento = (
      jsonObject.veiculoOcorrenciaDocumento ?? []
    ).map((PrecoProduto) => new VeiculoOcorrenciaDocumento(PrecoProduto));
  }

  toJson = () => JSON.stringify(this);
}
