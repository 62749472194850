import { EnvioProcessamentoContas } from 'core/models/TEL';
import api from '../api';

const base = 'TEL/EnvioProcessamentoContas';

export const getEnvioProcessamentoContas = async (id) => {
  const response = await api.get(`${base}`, {
    params: { id },
  });
  const { value } = response.data ?? {};

  return value ? new EnvioProcessamentoContas(value) : {};
};

export const postEnvioProcessamentoContas = async (payload) => {
  const response = await api.post(`${base}`, payload);
  return response.data ?? {};
};
