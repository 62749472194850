import React, { useState, useEffect } from 'react';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import {
  Modal,
  Autocomplete,
  DropdownList,
  Textbox,
  Notification,
  Loader,
  Button,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getGruposContrato,
  getContratoList,
  postResetarCotaGrupo,
} from 'core/services/TEL';

const modalResetarCotaGrupo = ({ show, onClose }) => {
  const [state, setState] = useState({});
  const [messages, setMessages] = useState([]);
  const [gruposDeLinhas, setGruposDeLinhas] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setState({});
  }, [show]);

  const handleSearchContrato = async (cdNoCliente) => {
    const { status, message: msg, contratos } = await getContratoList({
      cdNoCliente,
    });

    if (msg)
      setMessages([
        {
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        },
      ]);

    return contratos;
  };

  const handleSearchGrupoLinhas = async (cdCliente) => {
    setLoading(true);
    const {
      listaAgrupado,
      message: msg,
      status,
      errors,
    } = await getGruposContrato({
      cdCliente,
    });

    if (errors?.lenght > 0) {
      setMessages(
        errors.map((error) => ({
          message: error,
          theme: Theme.Danger,
        }))
      );
    } else if (msg)
      setMessages([
        {
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        },
      ]);

    setGruposDeLinhas(listaAgrupado ?? []);

    setLoading(false);
  };

  const handleReset = async () => {
    if ((state.contrato?.nrSeqClienteContaCab ?? 0) <= 0) {
      setMessages([
        {
          message: 'Obrigatório selecionar um contrato',
          theme: Theme.Danger,
        },
      ]);
      return;
    }

    if (!state.grupo?.nome) {
      setMessages([
        {
          message: 'Obrigatório selecionar um grupo',
          theme: Theme.Danger,
        },
      ]);
      return;
    }

    setLoading(true);

    const { status, message: msg, errors } = await postResetarCotaGrupo({
      nrSeqClienteContaCabecalho: state.contrato.nrSeqClienteContaCab,
      nomeGrupo: state.grupo.nome,
      cotaEmGB: state.cota ?? 0,
    });

    if (errors?.length > 0) {
      setMessages(
        errors.map((error) => ({
          message: error,
          theme: Theme.Danger,
        }))
      );
    } else if (msg)
      setMessages([
        {
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        },
      ]);

    setLoading(false);
  };

  return (
    <Modal
      show={show}
      size={BootstrapSizes.Large}
      title='Resetar cota do Grupo'
      onClose={onClose}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {messages && (
          <div className='mb-3'>
            <Notification messages={messages} onClose={() => setMessages([])} />
          </div>
        )}
        <div className='row'>
          <div className='col'>
            <div className='alert alert-info'>
              <b>Todas</b> as linhas do grupo selecionado serão alteradas para a
              cota informada.
            </div>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <Autocomplete
              label='Contrato'
              searchDataSource={handleSearchContrato}
              selectedItem={state.contrato}
              onSelectItem={(contrato) => {
                setState({ ...state, contrato });
                handleSearchGrupoLinhas(contrato.cdCliente);
              }}
              dataSourceTextProperty='cdNoCliente'
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <DropdownList
              label='Grupo de Linhas'
              dataSource={gruposDeLinhas}
              dataSourcePropertyText='nomeCota'
              selectedItems={state.grupo ?? []}
              onSelectItem={(item) =>
                setState({
                  ...state,
                  grupo: item,
                })
              }
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <Textbox
              label='Cota em GB'
              mask={MaskTypes.Decimal}
              text={state.cota}
              onChangedValue={(cota) => setState({ ...state, cota })}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Resetar'
          theme={Theme.Success}
          icon='sync-alt'
          onClick={handleReset}
        />
        <Button
          text='Cancelar'
          theme={Theme.Danger}
          icon='ban'
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default modalResetarCotaGrupo;
