export const TipoBomba = {
  Submersa: 'Submersa',
  Catavento: 'Catavento',
  Compressor: 'Compressor',
  BombaInjetora: 'BombaInjetora',
};
export const Etapa = {
  Revestimento: 'Revestimento',
  Perfuracao: 'Perfuracao',
  Anular: 'Anular',
  PrefilLitologico: 'PrefilLitologico',
  AnaliseAgua: 'AnaliseAgua',
  DadoHidraulico: 'DadoHidraulico',
  Desenvolvimento: 'Desenvolvimento',
  PerfilLitologico: 'PerfilLitologico',
};
