import React, { useEffect, useRef, useState } from 'react';
import { PageTypes } from 'ui/Helpers/utils';
import { getChecklistGrupoPagined } from 'core/services/FRO/checklistGrupo';
import { CSDSelPage, GridView, Textbox } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';

const columns = [
  {
    key: 'nrSeqChecklistGrupo',
    type: GridView.ColumnTypes.Checkbox,
    visible: false,
  },
  { key: 'cdGrupo', title: 'Código' },
  { key: 'noGrupo', title: 'Descrição' },
];

export default function ChecklistGrupo({ transaction, onOpenPage, isActive }) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getChecklistGrupoPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getChecklistGrupoPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'CheckListGrupo/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqChecklistGrupo = sourceRow.find(
      (e) => e.key === 'nrSeqChecklistGrupo'
    );
    onOpenMaintenance(nrSeqChecklistGrupo.value);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de ChecklistGrupo'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-5 mb-3'>
          <Textbox
            maxLength={200}
            label='Descrição'
            text={filtros.noGrupo}
            onChangedValue={(noGrupo) => setFiltros({ ...filtros, noGrupo })}
          />
        </div>

        <div className='col-2 mb-3'>
          <Textbox
            required
            label='Codigo'
            text={filtros.cdGrupo}
            mask={MaskTypes.Integer}
            onChangedValue={(cdGrupo) => setFiltros({ ...filtros, cdGrupo })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
