import PlanoDeConta from 'core/models/FIN/planoDeConta';

export default class NfPlanoDeConta {
  constructor(jsonObject = {}) {
    this.nrSeqNfPlanoDeConta = jsonObject.nrSeqNfPlanoDeConta;
    this.nrSeqPlanoDeConta = jsonObject.nrSeqPlanoDeConta;
    this.vlrTitulo = jsonObject.vlrTitulo;
    this.nrSeqNf = jsonObject.nrSeqNf;
    this.nrSeqPlanoDeContaCredito = jsonObject.nrSeqPlanoDeContaCredito;
    this.nrSeqPlanoDeContaDebito = jsonObject.nrSeqPlanoDeContaDebito;
    this.planoDeConta = new PlanoDeConta(jsonObject.planoDeConta ?? {});
  }

  toJson = () => JSON.stringify(this);
}
