import React, { useState, useEffect } from 'react';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  Loader,
  Modal,
  Notification,
  Switch,
  Textbox,
} from 'ui/components';
import {
  getVeiculoTipoAutoComplete,
  saveVeiculoTipo,
  getTipoVeiculoMdfeAutoComplete,
} from 'core/services/FRO/veiculoTipo';
import { getVeiculoFuncaoAutoComplete } from 'core/services/FRO/veiculoFuncao';
import { MaskTypes } from 'ui/Helpers/masks';

export default function ModalCadastroVeiculoTipo({
  show,
  onClose,
  data,
  setData,
}) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataModal, setDataModal] = useState({});

  const onSend = async (msg) => {
    onClose(msg);
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  useEffect(() => {
    setDataModal({
      flgPrincipal: false,
      flgAceitaVinculo: false,
      flgAceitaFerramenta: false,
    });
  }, [show]);

  const onSearchVeiculoTipo = async (e) => {
    const response = await getVeiculoTipoAutoComplete({ noVeiculoTipo: e });

    return response.data;
  };

  const onSearchTipoVeiculoMdfe = async (e) => {
    const {
      status,
      message: msg,
      data: tipoveiculomdfe,
    } = await getTipoVeiculoMdfeAutoComplete(e);
    if (msg) onSetMessage(status, msg);
    return tipoveiculomdfe;
  };

  const save = async () => {
    setLoading(true);

    if (
      dataModal.noVeiculoTipo &&
      dataModal.tipoRodadoMdfe &&
      dataModal.nrEixos &&
      dataModal.nrSeqVeiculoFuncao
    ) {
      const { status, message: msg } = await saveVeiculoTipo(dataModal);

      if (status === ResponseStatus.Success) {
        const veiculoTipos = await onSearchVeiculoTipo(dataModal.noVeiculoTipo);

        setData({
          ...data,
          veiculoTipo: veiculoTipos[0],
          nrSeqVeiculoTipo: veiculoTipos[0].nrSeqVeiculoTipo,
        });

        onSend();
      } else {
        setMessage({
          message: msg,
          theme: Theme.Danger,
        });
      }
    } else {
      setMessage({
        message: 'Preencha os campos obrigatórios',
        theme: Theme.Danger,
      });
    }

    setLoading(false);
  };

  const onSearchVeiculoFuncao = async (e) => {
    const {
      status,
      message: msg,
      data: veiculoFuncaos,
    } = await getVeiculoFuncaoAutoComplete({ noVeiculoFuncao: e });
    if (msg) onSetMessage(status, msg);
    return veiculoFuncaos;
  };

  return (
    <Modal
      show={show}
      title='Cadastrar Veículo Tipo'
      onClose={() => onSend()}
      size={BootstrapSizes.Large}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col-4'>
            <Textbox
              required
              maxLength={60}
              label='Nome '
              text={dataModal.noVeiculoTipo}
              onChangedValue={(noVeiculoTipo) =>
                setDataModal({ ...dataModal, noVeiculoTipo })
              }
            />
          </div>
          <div className='col'>
            <div className='row'>
              <div className='col'>
                <Switch
                  formControl
                  label='Principal'
                  checked={dataModal.flgPrincipal}
                  onChange={(flgPrincipal) =>
                    setDataModal({ ...dataModal, flgPrincipal })
                  }
                />
              </div>
              <div className='col'>
                <Switch
                  formControl
                  label='Aceita Vínculo'
                  checked={dataModal.flgAceitaVinculo}
                  onChange={(flgAceitaVinculo) =>
                    setDataModal({ ...dataModal, flgAceitaVinculo })
                  }
                />
              </div>
              <div className='col-5'>
                <Switch
                  formControl
                  label='Aceita Ferramenta'
                  checked={dataModal.flgAceitaFerramenta}
                  onChange={(flgAceitaFerramenta) =>
                    setDataModal({ ...dataModal, flgAceitaFerramenta })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row pb-5 mb-5'>
          <div className='col-4'>
            <Autocomplete
              required
              label='Tipo Rodado MDFE'
              searchDataSource={onSearchTipoVeiculoMdfe}
              selectedItem={dataModal.tipoVeiculoMdfe}
              onSelectItem={(tipoVeiculoMdfe) => {
                setDataModal({
                  ...dataModal,
                  tipoRodadoMdfe: tipoVeiculoMdfe.cdNoTipoVeiculoMDFE,
                });
              }}
              dataSourceTextProperty='noTipoVeiculoMDFE'
            />
          </div>
          <div className='col-2'>
            <Textbox
              required
              label='Número Eixos'
              text={dataModal.nrEixos}
              mask={MaskTypes.Integer}
              onChangedValue={(nrEixos) =>
                setDataModal({ ...dataModal, nrEixos })
              }
            />
          </div>
          <div className='col-4'>
            <Autocomplete
              required
              label='Veiculo Função'
              searchDataSource={onSearchVeiculoFuncao}
              selectedItem={dataModal.veiculoFuncao}
              onSelectItem={(veiculoFuncao) => {
                setDataModal({
                  ...dataModal,
                  veiculoFuncao,
                  nrSeqVeiculoFuncao: veiculoFuncao.nrSeqVeiculoFuncao,
                });
              }}
              dataSourceTextProperty='noVeiculoFuncao'
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Small}
          icon={['far', 'save']}
          theme={Theme.Success}
          template={Button.Templates.Default}
          onClick={save}
          text='Cadastrar'
        />
      </Modal.Footer>
    </Modal>
  );
}
