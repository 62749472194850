import qs from 'qs';
import Pagination from 'core/models/pagination';
import PagamentoMassivo from 'core/models/FIN/pagamentoMassivo';
import api from '../api';

const baseURL = `/FIN/PagamentoMassivo`;

export const getPagamentoMassivoList = async (filters) => {
  const response = await api.get(`/$PagamentoMassivo/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PagamentoMassivo(json)) ?? [],
  };
};

export const getPagamentoMassivoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PagamentoMassivo(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPagamentoMassivo = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PagamentoMassivo(value) : {};
};

export const savePagamentoMassivo = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePagamentoMassivo = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPagamentoMassivo = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPagamentoMassivo = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPagamentoMassivo = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
