export default class PocTipoOrcamentoDocumento {
  nrSeqPocTipoOrcamentoDocumento: number;

  nrSeqPocTipoOrcamento: number;

  noDocumento: string;

  documento: string;

  flgFisica: boolean;

  status: string;

  constructor(jsonObject: PocTipoOrcamentoDocumento) {
    this.nrSeqPocTipoOrcamentoDocumento =
      jsonObject.nrSeqPocTipoOrcamentoDocumento;
    this.nrSeqPocTipoOrcamento = jsonObject.nrSeqPocTipoOrcamento;
    this.noDocumento = jsonObject.noDocumento;
    this.documento = jsonObject.documento;
    this.flgFisica = jsonObject.flgFisica;
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
