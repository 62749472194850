export default class TipoPendenciaFinanceira {
  constructor(jsonObject = {}) {
    this.nrSeqTipoPendenciaFinanceira = jsonObject.nrSeqTipoPendenciaFinanceira;
    this.noTipoPendenciaFinanceira = jsonObject.noTipoPendenciaFinanceira;
    this.noPaginaWeb = jsonObject.noPaginaWeb;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.cdTransacao = jsonObject.cdTransacao;
  }

  toJson = () => JSON.stringify(this);
}
