import Eixo from './eixo';

export default class VeiculoModeloEixo {
  constructor(jsonObject = {}) {
    this.nrSeqVeiculoModelo = jsonObject.nrSeqVeiculoModelo;
    this.nrSeqEixo = jsonObject.nrSeqEixo;
    this.nrOrdem = jsonObject.nrOrdem;
    this.eixo = new Eixo(jsonObject.eixo ?? {});
  }

  toJson = () => JSON.stringify(this);
}
