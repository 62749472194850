import React, { useState, useEffect } from 'react';

import {
  Modal,
  Notification,
  Autocomplete,
  Button,
  Loader,
} from 'ui/components';

import { ResponseStatus, Theme, BootstrapSizes } from 'ui/Helpers/utils';

import { getApoliceSeguroAutoComplete } from 'core/services/FRO/apoliceSeguro';
import {
  saveColetaEmbarque,
  averbarCte,
} from 'core/services/FRO/coletaEmbarque';

export default function ModalAverbaCte({ show, onClose, cte }) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const onSetMessage = (status, msg) => {
    let theme;

    if (status === ResponseStatus.Success) theme = Theme.Success;
    else if (status === ResponseStatus.Warning) theme = Theme.Warning;
    else theme = Theme.Danger;

    if (msg)
      setMessage({
        message: msg,
        theme,
      });
  };

  useEffect(() => {
    if (show) setData(cte);
  }, [show]);

  const onSend = (status, msg) => {
    onClose(status, msg);
  };

  const averbaCte = async () => {
    setLoading(true);

    const { status: statusSave, message: msgSave } = await saveColetaEmbarque(
      data
    );

    if (statusSave === ResponseStatus.Success) {
      const {
        status: statusAverbacao,
        message: msgAverbacao,
      } = await averbarCte({
        nrSeqColetaEmbarque: data.nrSeqColetaEmbarque,
      });

      if (statusAverbacao === ResponseStatus.Success)
        onSend(statusAverbacao, msgAverbacao);
      else onSetMessage(statusAverbacao, msgAverbacao);
    } else {
      onSetMessage(statusSave, msgSave);
    }

    setLoading(false);
  };

  const onAutoCompleteApoliceSeguro = async () => {
    const {
      status,
      message: msg,
      data: apolicesSeguro,
    } = await getApoliceSeguroAutoComplete({
      nrSeqPessoaTomador: data.nrSeqPessoaCli,
      nrSeqPessoaRem: data.nrSeqPessoaRem,
      nrSeqPessoaDes: data.nrSeqPessoaDes,
      nrSeqColetaEmbarque: data.nrSeqColetaEmbarque,
    });

    if (msg) onSetMessage(status, msg);

    return apolicesSeguro;
  };

  return (
    <Modal
      show={show}
      title='Averbar CTe'
      onClose={() => onSend()}
      size={BootstrapSizes.Medium}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='alert alert-info' role='alert'>
            <div className='row'>
              <div className='col fs-6'>
                <span className='fw-bold'>
                  CTe: {cte?.cdColetaEmbarque} sem apólice de seguro vinculada.
                  Selecione a apólice para poder averbar.
                </span>
              </div>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              <Autocomplete
                label='Apólice de Seguro'
                searchDataSource={onAutoCompleteApoliceSeguro}
                selectedItem={data.apoliceSeguro}
                onSelectItem={(apoliceSeguro) =>
                  setData({
                    ...data,
                    apoliceSeguro,
                    nrSeqApoliceSeguro: apoliceSeguro.nrSeqApoliceSeguro,
                  })
                }
                dataSourceTextProperty='noAutoComplete'
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Averbar'
          icon='check'
          theme={Theme.Success}
          template={Button.Templates.Default}
          size={BootstrapSizes.Medium}
          onClick={() => averbaCte()}
        />
        <Button
          text='Sair'
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          size={BootstrapSizes.Medium}
          onClick={() => onSend()}
        />
      </Modal.Footer>
    </Modal>
  );
}
