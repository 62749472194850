import api from '../api';

const baseURL = '/TEL/ImportaConta';

export const postImportaConta = async (
  files: File[],
  nrseqoperadora: any,
  dtvencimento: any
) => {
  const formData = new FormData();
  formData.append('nrseqoperadora', nrseqoperadora);
  formData.append('dtvencimento', dtvencimento);
  files.forEach((file) => {
    formData.append('files', file);
  });

  const response = await api.post(`${baseURL}`, formData);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    value,
  };
};
