import React from 'react';

import logo from './logoCaixa.jpg';

const ChequeCaixa = (props) => {
  const { model } = props;
  const {
    vlrCheque,
    nrCheque,
    noValorPorExtenso,
    dayFormatted,
    monthFormatted,
    yearFormatted,
    contaCorrente,
  } = model;
  const { agencia } = contaCorrente;

  return (
    <div className='styleBordaExternaBanrisul'>
      <div className='row'>
        <div className='col-1 stylePadrao'>
          <span> Comp</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>Banco</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span className='textosimples'>Agência</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>DV</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>C1</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>Conta</span>
        </div>

        <div className='col-1 text-left stylePadrao'>
          <span>Série</span>
        </div>

        <div className='col-1 text-left stylePadrao'>
          <span>N</span>
        </div>
      </div>
      <div className='row mb'>
        <div className='col-1 text-left stylePadrao'>
          <span>010</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>{agencia ? agencia.banco.cdBanco : ''}</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>{agencia ? agencia.cdAgenciaFormatted : ''}</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>1</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>{contaCorrente ? contaCorrente.cdContaCorrente : ''}</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>9</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>000</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>{nrCheque}</span>
        </div>

        <div className='col-1 text-left'>
          <span className='styleValorCheque'>(**{vlrCheque.toFixed(2)}**)</span>
        </div>
      </div>

      <div className='row'>
        <div className='col styleDescricoesAdicionais'>
          Pague por este cheque a quantia de
        </div>
      </div>

      <div className='row mb-2'>
        <div className='col styleValo styleValorPorExtenso'>
          <span>
            ***{noValorPorExtenso}**********************************************
          </span>
        </div>
      </div>

      <div className='row mb-2'>
        <div className='col-4 logo'>
          <img src={logo} alt='logo' />
        </div>

        <div className='col-7'>
          <div className='row'>
            <div className='col-2'>
              <span className='styleDataEmissaoDia'>Canoas</span>
            </div>
            <div className='col-1'>
              <span className='styleDataEmissaoDia'>RS</span>
            </div>
            <div className='col-1'>
              <span className='styleDataEmissaoDia'>{dayFormatted}</span>
            </div>
            <div className='col-1'>
              <span className='styleDeEmissao'>de</span>
            </div>

            <div className='col-3'>
              <span className='styleDataEmissaoMes'>{monthFormatted}</span>
            </div>

            <div className='col'>
              <span className='styleDataEmissaoAno'>
                {String(yearFormatted).substring(2)}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-5 styleIdentifacaoEmpresa'>
          CHACARA BARRETO-0201.41
        </div>
        <div className='col styleLinhaAssinatura' />
      </div>

      <div className='row'>
        <div className='col-5 styleIdentifacaoEmpresa'>
          <span>AV SANTOS FERREIRA, 1591</span>
        </div>
        <div className='col styleIdentifacaoEmpresa'>
          <span>TRANSPORTADORA CADOMAR LTDA</span>
        </div>
      </div>

      <div className='row'>
        <div className='col-5 styleIdentifacaoEmpresa'>
          <span>CANOAS RS</span>
        </div>
        <div className='col styleIdentifacaoEmpresa'>
          <span>CNPJ 88.325.816/0001-36</span>
        </div>
      </div>

      <div className='row'>
        <div className='col-5 styleIdentifacaoEmpresa'>
          <span> </span>
        </div>
        <div className='col styleIdentifacaoEmpresa'>
          <span> CLIENTE BANCÁRIO DESDE 01/1994</span>
        </div>
      </div>
    </div>
  );
};

export default ChequeCaixa;
