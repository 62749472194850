import qs from 'qs';
import Pagination from 'core/models/pagination';
import LogErroSistema from 'core/models/SEG/logErroSistema';
import api from '../api';

const baseURL = `/SEG/LogErroSistema`;

export const getLogErroSistemaList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new LogErroSistema(json)) ?? [],
  };
};

export const getLogErroSistemaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new LogErroSistema(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getLogErroSistema = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new LogErroSistema(value) : {};
};

export const saveLogErroSistema = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const saveObjectError = async (data) => {
  const response = await api.post(`${baseURL}/SaveObjectError`, data);
  return response.data ?? {};
};

export const deleteLogErroSistema = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printLogErroSistema = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelLogErroSistema = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllLogErroSistema = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
