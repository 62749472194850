import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';

import {
  CSDSelPage,
  GridView,
  Autocomplete,
  DatePicker,
  Textbox,
  ToolbarButtons,
} from 'ui/components';

import { MaskTypes } from 'ui/Helpers/masks';

import {
  getPedidoCompraPagined,
  deleteAllPedidoCompra,
  printPedidoCompraSelecao,
  groupOrder,
} from 'core/services/VEN/pedidoCompra';

import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';

import { getProdutoAutoComplete } from 'core/services/EST/produto';

import { getEmpresasUsuario } from 'core/services/SEG/empresa';

import { getCompradorAutoComplete } from 'core/services/COP/comprador';

export default function PedidoCompra({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    filtros.situacao = filtros.situacaoPedido?.noSituacao;
    filtros.status = filtros.statusPedido?.noStatus;

    const { data: pedidos, pagination } = await getPedidoCompraPagined(
      params ?? filtros
    );

    let valorTotal = 0;

    pedidos.forEach((pedido) => {
      if (pedido.noStatusPedido !== 'Cancelado') {
        valorTotal += pedido.vlrPedido;
      }
    });

    const gridItemTotal = {
      vlrPedido: valorTotal,
      empresa: { noPessoa: '' },
    };

    pedidos.push(gridItemTotal);

    if (gridView && gridView.current)
      gridView.current.setDataSource(pedidos, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data: pedidos, pagination } = await getPedidoCompraPagined({});

      let valorTotal = 0;

      pedidos.forEach((pedido) => {
        if (pedido.noStatusPedido !== 'Cancelado') {
          valorTotal += pedido.vlrPedido;
        }
      });

      const gridItemTotal = {
        vlrPedido: valorTotal,
        empresa: { noPessoa: '' },
      };

      pedidos.push(gridItemTotal);

      if (gridView && gridView.current)
        gridView.current.setDataSource(pedidos, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchComprador = async (e) => {
    const {
      status,
      message: msg,
      data: comprador,
    } = await getCompradorAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return comprador;
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'PedidoCompra/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqPedidoCompra = sourceRow.find(
      (e) => e.key === 'nrSeqPedidoCompra'
    );
    if (nrSeqPedidoCompra.value) {
      onOpenMaintenance(nrSeqPedidoCompra.value);
    }
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    const nrSeqs = selecteds.filter((item) => item[0].value !== undefined);

    if (nrSeqs.length > 0) {
      const { status, message: msg } = await deleteAllPedidoCompra(
        nrSeqs.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };
  const onGroupOrder = async () => {
    setLoading(true);

    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    const nrSeqs = selecteds.filter((item) => item[0].value !== undefined);
    const pedidoCompras = nrSeqs.map((row) => ({
      nrSeqPedidoCompra: row[0].value,
    }));

    if (pedidoCompras.length > 0) {
      const { status, message: msg } = await groupOrder(pedidoCompras);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      await search();
    } else {
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }

    setLoading(false);
  };

  const onSearchPessoaFor = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaFors,
    } = await getFornecedorAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return pessoaFors;
  };

  const onSearchProduto = async (e) => {
    const {
      status,
      message: msg,
      data: produtos,
    } = await getProdutoAutoComplete({
      noSearchField: e,
    });
    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onSearchStatus = () => {
    const lista = [
      { nrSeqStatus: 1, noStatus: 'Aberto' },
      { nrSeqStatus: 2, noStatus: 'Cancelado' },
      { nrSeqStatus: 3, noStatus: 'Encerrado' },
    ];

    return lista;
  };

  const onSearchSituacao = () => {
    const lista = [
      { noSituacao: 'Aguardando nota fiscal' },
      { noSituacao: 'Recebido' },
      { noSituacao: 'Cancelado' },
    ];

    return lista;
  };

  const onPrint = async () => {
    setLoading(true);
    const { value, message: msg } = await printPedidoCompraSelecao(filtros);

    if (value) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(false, msg || 'Erro ao Imprimir Relatório');
    }

    setLoading(false);
  };

  const columns = [
    { key: 'nrSeqPedidoCompra', type: GridView.ColumnTypes.Checkbox },
    { key: 'dtEmissao', title: 'Emissão', format: GridView.DataTypes.Date },
    { key: 'cdPedidoCompra', title: 'Código' },
    {
      key: 'vlrPedido',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
      decimalPlaces: 3,
    },
    { key: 'noPessoaFor', title: 'Fornecedor' },
    {
      key: 'noStatusPedido',
      title: 'Status',
      colorText: 'noCorStatusPedido',
      sortable: false,
    },
    { key: 'noPessoaCop', title: 'Comprador' },
    {
      key: 'empresa.noEmpresa',
      title: 'Empresa',
      sortKey: 'noEmpresa',
      sortable: false,
    },
    {
      key: 'noSituacaoPedido',
      title: 'Situação do Pedido',
      colorText: 'noCorSituacaoPedido',
      sortable: false,
    },
  ];

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Pedido de Compra'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Agrupar Pedidos'
          icon='file-alt'
          onClick={onGroupOrder}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Fornecedor'
            searchDataSource={onSearchPessoaFor}
            selectedItem={filtros.pessoaFor}
            onSelectItem={(pessoaFor) => {
              setFiltros({
                ...filtros,
                pessoaFor,
                nrSeqPessoaFor: pessoaFor.nrSeqPessoaFor,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Produto'
            searchDataSource={onSearchProduto}
            selectedItem={filtros.produto}
            onSelectItem={(produto) => {
              setFiltros({
                ...filtros,
                produto,
                nrSeqProduto: produto.nrSeqProduto,
              });
            }}
            dataSourceTextProperty='noProduto'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Comprador'
            searchDataSource={onSearchComprador}
            selectedItem={filtros.comprador}
            onSelectItem={(comprador) => {
              setFiltros({
                ...filtros,
                comprador,
                nrSeqPessoaCop: comprador.nrSeqPessoaCop,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Status do Pedido'
            searchDataSource={onSearchStatus}
            selectedItem={filtros.statusPedido}
            onSelectItem={(status) =>
              setFiltros({
                ...filtros,
                statusPedido: status,
                noStatusPedido: status.noStatus,
              })
            }
            dataSourceTextProperty='noStatus'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Situação do Pedido'
            searchDataSource={onSearchSituacao}
            selectedItem={filtros.situacaoPedido}
            onSelectItem={(situacaoPedido) =>
              setFiltros({
                ...filtros,
                situacaoPedido,
                noSituacaoPedido: situacaoPedido.noSituacao,
              })
            }
            dataSourceTextProperty='noSituacao'
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data Início Emissão'
            text={filtros.dtEmissaoIni}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtEmissaoIni) =>
              setFiltros({ ...filtros, dtEmissaoIni })
            }
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data Fim Emissão'
            text={filtros.dtEmissaoFim}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtEmissaoFim) =>
              setFiltros({ ...filtros, dtEmissaoFim })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-6'>
          <Autocomplete
            label='Empresa'
            searchDataSource={onSearchEmpresa}
            selectedItem={filtros.empresa}
            onSelectItem={(empresa) => {
              setFiltros({
                ...filtros,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-2'>
          <Textbox
            maxLength={80}
            label='Código do Pedido'
            text={filtros.cdPedidoCompra}
            onChangedValue={(cdPedidoCompra) =>
              setFiltros({ ...filtros, cdPedidoCompra })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
            enableExcelExport
            // sumFields
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
