import React from 'react';
import { Button } from 'ui/components';
// @ts-expect-error
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';
import { getCNPJInformation } from 'core/services/SEG/pessoa';

interface ISearchCNPJButtonProps {
  cnpj: string;
  formData: any;
  setFormData: (object: any) => void;
  onSetMessage: (status: ResponseStatus, msg: string) => void;
  setLoading: (e: boolean) => void;
}

export default function SearchCNPJButton({
  cnpj,
  formData,
  setFormData,
  onSetMessage,
  setLoading,
}: ISearchCNPJButtonProps) {
  const onSearchCNPJInformation = async () => {
    if (!cnpj || cnpj === '') {
      onSetMessage(
        ResponseStatus.Error,
        'Necessário preencher o campo CNPJ para efetuar a busca.'
      );
    } else {
      setLoading(true);
      const { status, message, data: resposeData } = await getCNPJInformation(
        cnpj
      );

      if (
        (resposeData || Object.keys(resposeData).length > 0) &&
        status === ResponseStatus.Success
      ) {
        setFormData({
          ...formData,
          ...resposeData,
          nrSeqPessoa: undefined,
          disableDoc: true,
          disableTipoPessoa: true,
        });
      } else onSetMessage(ResponseStatus.Error, message);
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        outline
        theme={Theme.Success}
        text='Buscar CNPJ'
        onClick={onSearchCNPJInformation}
      />
    </div>
  );
}
