import qs from 'qs';
import Pagination from 'core/models/pagination';
import CarteiraTipo from 'core/models/FRO/carteiraTipo';
import api from '../api';

const baseURL = `/FRO/CarteiraTipo`;

export const getCarteiraTipoList = async (filters) => {
  const response = await api.get(`/$CarteiraTipo/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new CarteiraTipo(json)) ?? [],
  };
};

export const getCarteiraTipoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new CarteiraTipo(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getCarteiraTipo = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new CarteiraTipo(value) : {};
};

export const saveCarteiraTipo = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteCarteiraTipo = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printCarteiraTipo = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelCarteiraTipo = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllCarteiraTipo = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getCarteiraTipoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    carteiraTipos: value?.map((json) => new CarteiraTipo(json)) ?? [],
  };
};
