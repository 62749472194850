import React, { useState, useRef, useEffect } from 'react';
import {
  CSDSelPage,
  GridView,
  Panel,
  Autocomplete,
  DatePicker,
  Switch,
} from 'ui/components';
import {
  Page,
  Message,
  GridViewCurrent,
  GridviewColumns,
} from 'ui/Helpers/interfaces';
import {
  ColumnDataTypes,
  ResponseStatus,
  Theme,
  ColumnTypes,
  BootstrapSizes,
} from 'ui/Helpers/enums';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';

import { getVeiculoDisponibilidadePagined } from 'core/services/FRO/veiculoDisponibilidade';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';

import {
  VeiculoDisponibilidade,
  Veiculo,
  UploadImagemFrota,
  Motorista,
} from 'core/models/FRO';
import { Estado } from 'core/models/SEG';

import { FiltrosVeiculoDisponibilidade } from 'core/interfaces/FRO/veiculoDisponibilidade';

import ModalDocumentos from 'ui/pages/FRO/VeiculoDisponibilidade/modalDocumentos';

interface IModalDocumentos {
  show: boolean;
  nrSeqVeiculo: number;
  data: Array<UploadImagemFrota>;
  onClose?: () => void;
}

export default function VeiculoDisponibilidadeSel({
  transaction,
  findTransaction,
  onOpenTransaction,
  isActive,
}: Page) {
  const [filtros, setFiltros] = useState<FiltrosVeiculoDisponibilidade>({
    flgDisponivel: true,
    dtInicial: new Date(),
    dtFinal: new Date(),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [modalDocumentos, setModalDocumentos] = useState<IModalDocumentos>({
    show: false,
    data: [],
    nrSeqVeiculo: 0,
  });
  const gridView = useRef<GridViewCurrent<VeiculoDisponibilidade>>();

  const onSetMessage = (
    status: ResponseStatus | undefined,
    msg: string
  ): void => {
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
  };

  const search = async (
    params?: FiltrosVeiculoDisponibilidade
  ): Promise<void> => {
    setLoading(true);

    const {
      status,
      message: msg,
      data: veiculosDisponibilidade,
      pagination,
    } = await getVeiculoDisponibilidadePagined(params ?? filtros);

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      if (gridView && gridView.current) {
        gridView.current.setDataSource(veiculosDisponibilidade, pagination);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (filtros.flgDisponivel) {
      const intervalId = setInterval(search, 180000);

      search();

      return () => clearInterval(intervalId);
    }

    search();
    return () => {};
  }, [filtros.flgDisponivel]);

  const onClickAbrirDocumentos = async (
    e: VeiculoDisponibilidade
  ): Promise<void> => {
    setLoading(true);

    setModalDocumentos({
      show: true,
      data: e.uploadImagens,
      nrSeqVeiculo: e.nrSeqVeiculo,
    });

    setLoading(false);
  };

  const onColumnSort = async (sortBy: string) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const columns: Array<GridviewColumns> = [
    { key: 'nrSeqVeiculoDisponibilidade', visible: false },
    {
      key: 'flgDisponivel',
      title: 'Disponível',
      color: 'flgDisponivelColor',
      format: ColumnDataTypes.Boolean,
    },
    {
      key: 'veiculo.placa',
      title: 'Veículo',
      sortKey: 'placa',
    },
    {
      key: 'motorista.noPessoa',
      title: 'Motorista',
      sortKey: 'noPessoa',
    },
    {
      key: 'veiculo.veiculoModelo.noVeiculoModelo',
      title: 'Modelo Veículo',
      sortKey: 'noVeiculoModelo',
    },
    {
      key: 'estadoDestino.cdEstado',
      title: 'Estado',
      sortKey: 'nrSeqEstado',
    },
    {
      key: 'noCidade',
      title: 'Cidade',
    },
    {
      key: 'flgTemDevolucao',
      title: 'Tem Devolução',
      format: ColumnDataTypes.Boolean,
    },
    { key: 'itensDevolucao', title: 'Devoluções' },
    { key: 'qtdPallets', title: 'Qtd. Pallets' },
    { key: 'localPallets', title: 'Local Pallets' },
    {
      key: 'dtDisponibilidade',
      title: 'Dt. Disponibilidade',
      format: ColumnDataTypes.DateHour,
    },
    {
      key: 'nrSeqVeiculoDisponibilidade',
      type: ColumnTypes.Button,
      onClick: (e: VeiculoDisponibilidade) => onClickAbrirDocumentos(e),
      theme: Theme.Success,
      icon: 'copy',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      tooltipDirection: 'bottom',
    },
  ];

  const onAutoCompleteVeiculo = async (e: string): Promise<Array<Veiculo>> => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
    });

    if (msg) onSetMessage(status, msg);

    return veiculos;
  };

  const onAutoCompleteMotorista = async (e: string): Promise<void> => {
    const { status, message: msg, motoristas } = await getMotoristaAutoComplete(
      {
        noPessoa: e,
      }
    );

    if (msg) onSetMessage(status, msg);

    return motoristas;
  };

  const onSearchAutoCompleteEstado = async (e: string): Promise<void> => {
    const { status, message: msg, estados } = await getEstadoAutoComplete({
      noEstado: e,
    });

    if (msg) onSetMessage(status, msg);

    return estados;
  };

  return (
    <CSDSelPage
      transaction={transaction}
      isActive={isActive}
      title='Seleção de Veículo Disponibilidade'
      onSearch={() => search()}
      loading={loading}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      {/* @ts-expect-error */}
      <Panel className='mb-3'>
        {/* @ts-expect-error */}
        <Panel.Header title='Filtros' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-2'>
              <Autocomplete
                label='Veículo'
                searchDataSource={onAutoCompleteVeiculo}
                selectedItem={filtros.veiculo}
                onSelectItem={(item: Veiculo) => {
                  setFiltros({
                    ...filtros,
                    veiculo: item,
                    nrSeqVeiculo: item.nrSeqVeiculo,
                  });
                }}
                dataSourceTextProperty='placa'
              />
            </div>
            <div className='col-3'>
              <Autocomplete
                label='Motorista'
                searchDataSource={onAutoCompleteMotorista}
                selectedItem={filtros.motorista}
                onSelectItem={(item: Motorista) => {
                  setFiltros({
                    ...filtros,
                    motorista: item,
                    nrSeqPessoaMot: item.nrSeqPessoaMot,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-2'>
              <DatePicker
                label='Data Inicial'
                text={filtros.dtInicial}
                onChange={(item: Date) => {
                  setFiltros({
                    ...filtros,
                    dtInicial: item,
                  });
                }}
              />
            </div>
            <div className='col-2'>
              <DatePicker
                label='Data Final'
                text={filtros.dtFinal}
                onChange={(item: Date) => {
                  setFiltros({
                    ...filtros,
                    dtFinal: item,
                  });
                }}
              />
            </div>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <Switch
                checked={filtros.flgDisponivel}
                label='Somente disponíveis'
                formControl
                onChange={(flgDisponivel: boolean) =>
                  setFiltros({ ...filtros, flgDisponivel })
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-2'>
              <Autocomplete
                label='Estado'
                searchDataSource={onSearchAutoCompleteEstado}
                selectedItem={filtros.estado}
                onSelectItem={(estado: Estado) => {
                  setFiltros({
                    ...filtros,
                    estado,
                    nrSeqEstado: estado.nrSeqEstado,
                  });
                }}
                dataSourceTextProperty='noEstado'
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalDocumentos
            show={modalDocumentos.show}
            data={modalDocumentos.data}
            nrSeqVeiculo={modalDocumentos.nrSeqVeiculo}
            openViagem={async (nrSeqViagem: number) => {
              setModalDocumentos({
                show: false,
                data: [],
                nrSeqVeiculo: 0,
              });

              setLoading(true);

              const transactionOpen = findTransaction(TransacationCodes.Viagem);

              if (transactionOpen) {
                transactionOpen.registryKey = nrSeqViagem;
                onOpenTransaction(transactionOpen, true);
              } else {
                setMessage({
                  message: 'Não foi possível abrir a Viagem.',
                  theme: Theme.Danger,
                });
              }

              setLoading(false);
            }}
            onClose={() => {
              setModalDocumentos({
                show: false,
                data: [],
                nrSeqVeiculo: 0,
              });
            }}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
