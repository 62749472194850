import qs from 'qs';
import Chamado from 'core/models/HELP/chamado';
import Pagination from 'core/models/pagination';
import { IPaginedChamado, IPrintChamado } from 'core/interfaces/HELP/chamado';
import api from '../api';

const baseURL = '/HELP/Chamado';

export const getChamadoPagined = async (
  filters: Object
): Promise<IPaginedChamado> => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });

  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new Chamado(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getChamado = async (id: number): Promise<Chamado | {}> => {
  const response = await api.get(`${baseURL}/`, {
    params: { pk: id },
  });

  const { value } = response.data ?? {};

  return value ? new Chamado(value) : {};
};

export const saveChamado = async (data: any) => {
  const response = await api.post(`${baseURL}/`, data);

  return response.data ?? {};
};

export const deleteChamado = async (id: number) => {
  const response = await api.delete(`${baseURL}/`, {
    params: { pk: id },
  });

  return response.data ?? {};
};

export const deleteAllChamado = async (ids: number[]) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { pks: ids },
    paramsSerializer: (params) => qs.stringify(params),
  });

  return response.data ?? {};
};

export const printChamado = async (filters: Object): Promise<IPrintChamado> => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });

  return response.data ?? {};
};

export const downloadBase = async (
  noBase: string,
  nrseqpessoacli: number = 0
) => {
  const response = await api.get(`${baseURL}/DownloadBase`, {
    params: { noBase, nrseqpessoacli },
  });

  const { message, value } = response.data;

  return {
    msg: message,
    data: value,
  };
};

export const saveChamadoDemandas = async (data: any) => {
  const response = await api.post(`${baseURL}/SaveChamadoDemandas`, data);

  return response.data ?? {};
};

export const importFromEmail = async () => {
  const response = await api.post(`${baseURL}/CreateFromEmail`);

  const { status, message, value } = response.data;

  return {
    status,
    message,
    data: value,
  };
};

export const responderEmail = async (data: any) => {
  const response = await api.post(`${baseURL}/ResponderEmail`, data);

  const { status, message, value } = response.data;

  return {
    status,
    message,
    data: value,
  };
};

export const searchEmailsNaoImportados = async () => {
  const response = await api.get(`${baseURL}/SearchEmailsNaoImportados`);

  const { status, message, value } = response.data;

  return {
    status,
    message,
    data: value,
  };
};

export const removerEmailNaoImportado = async (id: number) => {
  const response = await api.delete(`${baseURL}/RemoverEmailNaoImportado`, {
    params: { pk: id },
  });

  const { status, message } = response.data;

  return {
    status,
    message,
  };
};
