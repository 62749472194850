import { Usuario } from 'core/models/SEG';

export default class PessoaHistorico {
  constructor(jsonObject = {}) {
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.nrSeqPessoaHistorico = jsonObject.nrSeqPessoaHistorico;
    this.dtCad = jsonObject.dtCad;
    this.descricao = jsonObject.descricao;
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.usuario = new Usuario(jsonObject.usuario ?? {});
  }

  toJson = () => JSON.stringify(this);
}
