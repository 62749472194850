import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  JustifyContent,
  BootstrapSizes,
  dateNow,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  DatePicker,
  Panel,
  GridView,
  Switch,
  Button,
  ToolbarButtons,
  RadioButton,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';

import { TransacationCodes } from 'ui/Helpers/transactionCodes';

import {
  getNfAvulsa,
  saveNfAvulsa,
  deleteNfAvulsa,
  printNfAvulsa,
  confirmNfvAvulsa,
  getNfAvulsaReturn,
  getNfAvulsaCancelEfetivacao,
} from 'core/services/VEN/nfvAvulsa.tsx';

import { PainelEndereco } from 'ui/pages/SEG';
import { getNfAutoComplete } from 'core/services/COP/nf';
import { getTransportadoraAutoComplete } from 'core/services/FRO/transportadora';

import {
  getPocOrcamentoAutoComplete,
  getPocOrcamento,
} from 'core/services/POC/pocOrcamento';

import { getProdutoAgrupador } from 'core/services/EST/produtoAgrupador';
import { getTipoNfvAvulsaAutoComplete } from 'core/services/VEN/tipoNfvAvulsa';

import { getClienteAutoComplete, getCliente } from 'core/services/FIN/cliente';

import { getEmpresaAutoComplete, getEmpresa } from 'core/services/SEG/empresa';

import { getProdutoAutoComplete } from 'core/services/EST/produto';

import { getCfoAutoComplete } from 'core/services/FIS/cfo';

import { getTipoFreteAutoComplete } from 'core/services/FRO/tipoFrete';

import {
  getAlmoxarifadoLocalAutoComplete,
  getBalance,
} from 'core/services/EST/almoxarifadoLocal';

import { getEstadoAutoComplete } from 'core/services/SEG/estado';

import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';

import NfvAvulsa from 'core/models/VEN/nfvAvulsa.tsx';

import Nfv from 'core/models/VEN/nfv';

import NfvAvulsaItem from 'core/models/VEN/nfvAvulsaItem.tsx';
import NfNfv from 'core/models/VEN/nfNfv';
import { ProdutoAgrupador } from 'core/models/EST';

import { getLastPurchaseValue } from 'core/services/COP/nfItem';

import { getOrdemServicoProdList } from 'core/services/FIN/ordemServicoProd';

import { getOrdemServicoAutoComplete } from 'core/services/FIN/ordemServico';

import { postTransmit, printNfvForNfvAvulsa } from 'core/services/VEN/Nfv';

import ModalImpostosNotaFiscal from './modalImpostosNotaFiscal';

export default function NotaFiscalAvulsaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  onOpenReport,
  transaction,
  onOpenTransaction,
  findTransaction,
}) {
  const formSubmit = useRef();
  const gridViewProdutos = useRef();
  const gridViewItensOrdemServico = useRef();
  const [data, setData] = useState(new NfvAvulsa({ noTipoDevolucao: 'N' }));
  const [dataProduto, setDataProduto] = useState(new NfvAvulsaItem({}));
  const [dataNfNfv, setDataNfNfv] = useState(new NfNfv({}));
  const gridViewNota = useRef();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [atualizaTotalNota, setAtualizaTotalNota] = useState(false);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const clearForm = async () => {
    const dataGrid = await getNfAvulsa(0);
    if (gridViewProdutos && gridViewProdutos.current)
      gridViewProdutos.current.setDataSource([]);

    const newData = { ...dataGrid, DtNfvAvulsa: dateNow() };
    setData(newData);
    setDataNfNfv(new Nfv({}));
    setDataProduto(new NfvAvulsaItem({}));
    setAtualizaTotalNota(false);

    if (gridViewProdutos && gridViewProdutos.current)
      gridViewProdutos.current.setDataSource([]);

    if (gridViewNota && gridViewNota.current)
      gridViewNota.current.setDataSource([]);

    if (gridViewItensOrdemServico && gridViewItensOrdemServico.current)
      gridViewItensOrdemServico.current.setDataSource([]);
  };

  const onGetNfsReturn = async () => {
    const nota = await getNfAvulsaReturn(data.nrSeqNfvAvulsa);
    setData(nota);
  };

  const onGetNfsCancelEfetivacao = async () => {
    setLoading(true);

    const { status, message: msg, value } = await getNfAvulsaCancelEfetivacao(
      data.nrSeqNfvAvulsa
    );

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      setData(new NfvAvulsa(value));
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onClickTransmitir = async () => {
    setLoading(true);

    const nfvEmitir = new Nfv();
    nfvEmitir.nrSeqNfvAvulsa = data.nrSeqNfvAvulsa;

    const { status, message: msg } = await postTransmit(nfvEmitir);

    setLoading(false);

    onSetMessage(status, msg);
  };

  const statusNotaDevolucao = [
    { text: 'Por Número da Nota', value: 'N' },
    { text: 'Por Chave na Nota', value: 'C' },
  ];

  const onClickEditProduto = async (selectedValue, datasource) => {
    selectedValue.status = GridView.EnumStatus.Alterar;

    const dataProdutos = datasource.filter(
      (produto) => produto !== selectedValue
    );

    setDataProduto(selectedValue);

    if (gridViewProdutos && gridViewProdutos.current)
      gridViewProdutos.current.setDataSource(dataProdutos);

    setAtualizaTotalNota(true);
  };

  const onClickRemoveProduto = async (selectedValue, datasource) => {
    if (selectedValue.nrSeqNfvAvulsaItem > 0)
      datasource.find((item) => item === selectedValue).status =
        GridView.EnumStatus.Remover;
    else {
      const index = datasource.indexOf(selectedValue);
      datasource.splice(index, 1);
    }

    if (gridViewProdutos && gridViewProdutos.current)
      gridViewProdutos.current.setDataSource(datasource);

    setAtualizaTotalNota(true);
  };

  const calculateTotalProducts = (produtos) => {
    let total = 0;
    produtos.forEach((item) => {
      if (item.status !== 'Remover') {
        total += item.vlrTotNfv;
      } else {
        total -= 0;
      }
    });
    return total;
  };

  const calculateQuantityProducts = (produtos) => {
    let total = 0;
    produtos.forEach((item) => {
      if (item.status !== 'Remover') {
        total += item.qtdeItNfv;
      } else {
        total -= 0;
      }
    });
    return total;
  };

  const calculateTotDespeses = (produtos) => {
    let total = 0;
    produtos.forEach((item) => {
      if (item.status !== 'Remover') {
        total +=
          item.vlrOutrasDespesasAcessorias !== undefined
            ? item.vlrOutrasDespesasAcessorias
            : 0;
      } else {
        total -= 0;
      }
    });
    return total;
  };

  const calculateTotDescont = (produtos) => {
    let total = 0;
    produtos.forEach((item) => {
      if (item.status !== 'Remover') {
        total += item.vlrDesc;
      } else {
        total -= 0;
      }
    });
    return total;
  };

  const calcularValorTotalProduto = ({ qtdeItNfv, vlrItNfv, vlrDesc }) => {
    const a = qtdeItNfv ?? (dataProduto.qtdeItNfv || 0);
    const b = vlrItNfv ?? (dataProduto.vlrItNfv || 0);
    const c = vlrDesc ?? (dataProduto.vlrDesc || 0);

    return a * b - c;
  };

  useEffect(() => {
    if (atualizaTotalNota) {
      const datasource = gridViewProdutos.current.getDataSource();

      setData((currentState) => {
        currentState.vlrNfvAvulsa = calculateTotalProducts(datasource);
        currentState.qtdeItNfvTotal = calculateQuantityProducts(datasource);
        currentState.vlrDespesas = calculateTotDespeses(datasource);
        currentState.vlrDesc = calculateTotDescont(datasource);
        return currentState;
      });

      setAtualizaTotalNota(false);
    }
  }, [atualizaTotalNota]);

  const onClickRemoveNotas = async (selectedValue, datasource) => {
    const itemRemover = datasource.find((item) => item === selectedValue);
    if (itemRemover.nrSeqNfNfv > 0)
      datasource.find((item) => item === selectedValue).status =
        GridView.EnumStatus.Remover;
    else {
      const index = datasource.indexOf(itemRemover);
      datasource.splice(index, 1);
    }
    if (gridViewNota && gridViewNota.current)
      gridViewNota.current.setDataSource(datasource);
  };

  const columnsNf = [
    { key: 'nf.nrForNf', title: 'Nota' },
    { key: 'nf.fornecedor.noPessoa', title: 'Fornecedor' },
    { key: 'nf.chaveNfe', title: 'Chave NFE' },
    {
      key: 'NrSeqNfNfv',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveNotas(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsProdutos = [
    { key: 'nrSeqProduto', type: GridView.ColumnTypes.Checkbox },
    { key: 'produto.cdProdutoCodigoPrincipal', title: 'Código' },
    {
      key: 'produto.produtoAgrupador.noProduto',
      title: 'Produto',
    },
    {
      key: 'vlrItNfv',
      title: 'Unitário',
      format: GridView.DataTypes.Decimal,
      decimalPlaces: 4,
    },
    {
      key: 'qtdeItNfv',
      title: 'Quantidade',
      format: GridView.DataTypes.Decimal,
      decimalPlaces: 4,
    },

    { key: 'vlrDesc', title: 'Desconto', format: GridView.DataTypes.Decimal },

    { key: 'vlrTotNfv', title: 'Total', format: GridView.DataTypes.Decimal },

    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditProduto(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveProduto(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const defineProdutoCodigo = (itens) => {
    itens.forEach((element) => {
      if (element.produto.codigos?.length > 0) {
        element.produto.cdProdutoCodigoPrincipal =
          element.produto.codigos[0].cdProdutoCodigo;
      }
    });
  };

  const onSearchTipoFrete = async (e) => {
    const {
      status,
      message: msg,
      tiposFrete,
    } = await getTipoFreteAutoComplete({ noTipoFrete: e });
    if (msg) onSetMessage(status, msg);
    return tiposFrete;
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      setDataProduto(new NfvAvulsaItem({}));
      const nota = await getNfAvulsa(registryKey);

      defineProdutoCodigo(nota.itens);

      const { data: itens } = await getOrdemServicoProdList({
        nrSeqOrdemServico: nota.nrSeqOrdemServico,
      });

      if (
        itens.length > 0 &&
        gridViewItensOrdemServico &&
        gridViewItensOrdemServico.current
      ) {
        gridViewItensOrdemServico.current.setDataSource(itens);
      }

      if (gridViewProdutos && gridViewProdutos.current)
        gridViewProdutos.current.setDataSource(nota.itens);

      if (nota.tipoNfvAvulsa.flgDevolucao) {
        if (nota.notas.length > 0) {
          nota.noTipoDevolucao = 'N';
          if (gridViewNota && gridViewNota.current)
            gridViewNota.current.setDataSource(nota.notas);
        } else {
          nota.noTipoDevolucao = 'C';
        }
      }

      setData(nota);

      setLoading(false);
    } else {
      clearForm();
      setLoading(false);
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);

    const obj = {
      ...data,
      itens: gridViewProdutos.current.getDataSource(),
      notas: gridViewNota.current?.getDataSource() ?? [],
    };

    const { status, message: msg, data: value } = await saveNfAvulsa(obj);
    if (status === ResponseStatus.Success) {
      setData(value);

      if (gridViewProdutos && gridViewProdutos.current)
        gridViewProdutos.current.setDataSource(value.itens);

      if (gridViewNota && gridViewNota.current)
        gridViewNota.current.setDataSource(value.notas);
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onConfirm = async () => {
    setLoading(true);

    const obj = {
      ...data,
      itens: gridViewProdutos.current.getDataSource(),
    };

    const { status, message: msg } = await confirmNfvAvulsa(obj);
    const value = await getNfAvulsa(data.nrSeqNfvAvulsa);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      setData(new NfvAvulsa(value));
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onPrintNfv = async () => {
    setLoading(true);
    const { value } = await printNfvForNfvAvulsa({
      nrSeqNfvAvulsa: data.nrSeqNfvAvulsa,
    });

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onPrint = async () => {
    setLoading(true);

    const { value } = await printNfAvulsa({
      nrSeqNfvAvulsa: data.nrSeqNfvAvulsa,
    });

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteNfAvulsa(registryKey);

      if (status === ResponseStatus.Success) {
        formSubmit.current.reset();
        setData(new NfvAvulsa({}));
      }

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const validateAddProduto = () => {
    let msg = '';
    const status = Theme.Danger;

    if (!dataProduto.nrSeqProduto) msg = 'Produto não informado.';
    else if (
      !dataProduto.nrSeqAlmoxarifadoLocal &&
      dataProduto.produto?.produtoAgrupador?.produtoGrupo?.produtoGrupoTipo
        ?.flgServico !== true
    )
      msg = 'Local de Armazenagem não definido.';
    else if (!dataProduto.qtdeItNfv || dataProduto.qtdeItNfv <= 0)
      msg = 'Quantidade não informada.';
    else if (dataProduto.vlrItNfv < 0)
      msg = 'Valor unitário não pode ser inferior a zero.';

    if (msg) {
      onSetMessage(status, msg);
      return false;
    }
    return true;
  };

  const onClickAddProduto = async () => {
    if (validateAddProduto()) {
      if (gridViewProdutos && gridViewProdutos.current) {
        const produtos = gridViewProdutos.current.getDataSource();
        if (dataProduto.nrSeqNfvAvulsaItem > 0)
          dataProduto.status = GridView.EnumStatus.Alterar;
        else dataProduto.status = GridView.EnumStatus.Inserir;

        if (!dataProduto.vlrDesc) dataProduto.vlrDesc = 0;
        if (!dataProduto.vlrOutrasDespesasAcessorias)
          dataProduto.vlrOutrasDespesasAcessorias = 0;

        dataProduto.produto.produtoAgrupador = new ProdutoAgrupador({});
        dataProduto.produto.produtoAgrupador.noProduto =
          dataProduto.produto.noProduto;

        produtos.push(dataProduto);
        gridViewProdutos.current.setDataSource(produtos);
        setDataProduto(new NfvAvulsaItem({}));

        if (data.vlrNfvAvulsa === undefined) {
          data.vlrNfvAvulsa = 0;
        }
        if (data.qtdeItNfvTotal === undefined) {
          data.qtdeItNfvTotal = 0;
        }

        setAtualizaTotalNota(true);

        setDataProduto({
          produto: [],

          qtdeItNfv: 0,
          vlrItNfv: 0,
          vlrDesc: 0,
          vlrOutrasDespesasAcessorias: 0,
          vlrTotNfv: 0,
        });

        setData({ ...data, itens: produtos });
      }
    }
  };

  const setProdutosDataSource = (prodsList) => {
    const produtos = prodsList.map((item) => {
      const produto = {
        produto: {
          noProduto: item.produto.noProduto,
          nrSeqProduto: item.nrSeqProduto,
          cdProdutoCodigoPrincipal: item.produtoCodigo.cdProdutoCodigo,
          produtoAgrupador: {
            noProduto: item.produto.noProduto,
            nrSeqProduto: item.nrSeqProduto,
            cdProdutoCodigoPrincipal: item.produtoCodigo.cdProdutoCodigo,
          },
        },
        nrSeqProduto: item.nrSeqProduto,
        qtdeItNfv: item.qtdeItem,
        vlrItNfv: item.vlrUnitario,
        vlrTotNfv: item.vlrTotal,
        vlrDesc: item.vlrDesconto,
        status: GridView.EnumStatus.Inserir,
      };

      return produto;
    });
    return produtos;
  };

  const onClickAddNota = () => {
    if (dataNfNfv.nf) {
      dataNfNfv.nrSeqNf = dataNfNfv.nf.nrSeqNf;
      dataNfNfv.status = GridView.EnumStatus.Inserir;
      const lista = gridViewNota.current.getDataSource() ?? [];
      lista.push(dataNfNfv);

      if (gridViewNota && gridViewNota.current)
        gridViewNota.current.setDataSource(lista);
    }

    setDataNfNfv({
      nf: {},
    });
  };

  const onClickAddImpostos = async () => {
    setShowModal(true);
  };

  const onSearchEstadoVeiculo = async (e) => {
    const { status, message: msg, estados } = await getEstadoAutoComplete({
      cdEstado: e,
    });
    if (msg) onSetMessage(status, msg);
    return estados;
  };

  const onSearchVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onSearchNota = async (e) => {
    const { status, message: msg, data: nota } = await getNfAutoComplete({
      nrForNf: e,
    });
    if (msg) onSetMessage(status, msg);
    return nota;
  };

  const onSearchProduto = async (e) => {
    const {
      status,
      message: msg,
      data: produtos,
    } = await getProdutoAutoComplete({
      noSearchField: e,
    });
    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const onSearchTransportadora = async (e) => {
    const {
      status,
      message: msg,
      transportadoras,
    } = await getTransportadoraAutoComplete({
      noTransportadora: e,
    });
    if (msg) onSetMessage(status, msg);
    return transportadoras;
  };

  const onSearchAlmoxarifadoLocal = async (e) => {
    const {
      status,
      message: msg,
      almoxarifados,
    } = await getAlmoxarifadoLocalAutoComplete({
      noAlmoxarifadoLocal: e,
      nrSeqProduto: dataProduto.nrSeqProduto,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifados;
  };

  const onSearchCfo = async (e) => {
    const { status, message: msg, cfos } = await getCfoAutoComplete({
      noCfo: e,
    });
    if (msg) onSetMessage(status, msg);
    return cfos;
  };

  const onSearchPessoaCli = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return clientes;
  };

  const onSearchTipoNotaFiscalAvulsa = async (e) => {
    const {
      status,
      message: msg,
      data: tiposnotafiscal,
    } = await getTipoNfvAvulsaAutoComplete({
      noTipoNfvAvulsa: e,
    });
    if (msg) onSetMessage(status, msg);
    return tiposnotafiscal;
  };

  const onSearchOrdemServico = async (cdOrdemServico) => {
    const {
      status,
      message: msg,
      data: ordemServico,
    } = await getOrdemServicoAutoComplete({
      nrSeqPocOrcamento: data.nrSeqPocOrcamento,
      nrSeqPessoaCli: data.nrSeqPessoaCli,
      flgEncerrado: true,
      flgRomaneio: true,
      cdOrdemServico,
    });

    if (msg) onSetMessage(status, msg);

    return ordemServico;
  };

  const onSearchOrcamento = async (e) => {
    const {
      status,
      message: msg,
      data: orcamentos,
    } = await getPocOrcamentoAutoComplete({
      cdProposta: e,
      nrSeqPessoaCli: data.nrSeqPessoaCli,
    });
    if (msg) onSetMessage(status, msg);
    return orcamentos;
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const { id: idSelecao } = PageTypes.Selection;

  // const showServiceOrderItens = data.ordemServico?.nrSeqOrdemServico ?? false;

  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Notas Fiscais'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => clearForm()}
      onSave={save}
      onDelete={data?.nrSeqNfvAvulsa > 0 ? onExcluir : null}
      message={message}
      onPrint={onPrint}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
      removeValidate
    >
      <ToolbarButtons>
        {data.nrSeqNfvAvulsa > 0 && !data.dtVenda && (
          <ToolbarButtons.Button
            text='Efetivar'
            icon={['fa', 'check']}
            onClick={() => onConfirm()}
          />
        )}

        {data.nrSeqNfvAvulsa > 0 && data.dtVenda && (
          <ToolbarButtons.Button
            text='Cancelar Efetivação'
            icon={['fa', 'check']}
            onClick={() => onGetNfsCancelEfetivacao()}
          />
        )}
        {data.nrSeqNfvAvulsa > 0 && data.dtVenda && (
          <ToolbarButtons.Button
            text='Visualizar Nota Efetivada'
            icon={['fa', 'check']}
            onClick={() => onPrintNfv()}
          />
        )}

        {data.nrSeqNfvAvulsa > 0 && data.dtVenda && (
          <ToolbarButtons.Button
            text='Transmitir'
            icon={['fa', 'check']}
            onClick={(e, datasource) => onClickTransmitir(e, datasource)}
          />
        )}

        {data.nrSeqNfvAvulsa > 0 &&
          data.dtVenda &&
          data.nrSeqTipoNfvAvulsa === 14 && (
            <ToolbarButtons.Button
              text='Devolver'
              icon={['fa', 'check']}
              onClick={() => onGetNfsReturn()}
            />
          )}
      </ToolbarButtons>

      <div className='row mb-2'>
        <div className='col-2'>
          <DatePicker
            label='* Data Emissão'
            text={data.dtEmis}
            mask={MaskTypes.Date}
            onChange={(dtEmis) =>
              setData({
                ...data,
                dtEmis,
              })
            }
          />
        </div>

        <div className='col-4'>
          <Autocomplete
            label='* Empresa'
            searchDataSource={onSearchEmpresa}
            selectedItem={data.empresa}
            onSelectItem={(empresa) =>
              setData({
                ...data,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>

        <div className='col-6'>
          <Autocomplete
            label='* Cliente'
            searchDataSource={onSearchPessoaCli}
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transactionToOpen={TransacationCodes.Cliente}
            nrseqTransactionToOpen='nrSeqPessoaCli'
            selectedItem={data.cliente}
            onSelectItem={(cliente) =>
              setData({
                ...data,
                cliente,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>

      <div className='row mb-2'>
        <div className='col-2'>
          <DatePicker
            label='* Data Saída'
            text={data.dtSaida}
            mask={MaskTypes.Date}
            onChange={(dtSaida) =>
              setData({
                ...data,
                dtSaida,
              })
            }
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='* Tipo Nota Fiscal Avulsa'
            searchDataSource={onSearchTipoNotaFiscalAvulsa}
            selectedItem={data.tipoNfvAvulsa}
            onSelectItem={(tipoNfvAvulsa) =>
              setData({
                ...data,
                tipoNfvAvulsa,
                nrSeqTipoNfvAvulsa: tipoNfvAvulsa.nrSeqTipoNfvAvulsa,
                cfo: tipoNfvAvulsa.cfoPais,
              })
            }
            dataSourceTextProperty='noTipoNfvAvulsa'
          />
        </div>
        {data.tipoNfvAvulsa?.flgPossuiOrcamento && (
          <>
            <div className='col-3'>
              <Autocomplete
                label='Orçamento'
                searchDataSource={onSearchOrcamento}
                selectedItem={data.orcamento}
                onSelectItem={async (orcamento) => {
                  setData({
                    ...data,
                    orcamento,
                    nrSeqPocOrcamento: orcamento.nrSeqPocOrcamento,
                  });
                }}
                dataSourceTextProperty='cdProposta'
              />
            </div>
            <div className='col-3'>
              <Autocomplete
                label='* Ordem de Serviço'
                searchDataSource={onSearchOrdemServico}
                selectedItem={data.ordemServico}
                onSelectItem={async (ordemServico) => {
                  const { data: empresa } = await getEmpresa(
                    ordemServico.nrSeqEmpresa
                  );

                  const cliente = await getCliente(ordemServico.nrSeqPessoaCli);

                  const orcamento = await getPocOrcamento(
                    ordemServico.nrSeqPocOrcamento
                  );

                  const { data: prodsList } = await getOrdemServicoProdList({
                    nrSeqOrdemServico: ordemServico.nrSeqOrdemServico,
                  });

                  const itens = setProdutosDataSource(prodsList);

                  setData({
                    ...data,
                    ordemServico,
                    nrSeqOrdemServico: ordemServico.nrSeqOrdemServico,
                    nrSeqPocOrcamento: ordemServico.nrSeqPocOrcamento,
                    nrSeqEmpresa: empresa.nrSeqEmpresa,
                    nrSeqPessoaCli: ordemServico.nrSeqPessoaCli,
                    orcamento,
                    cliente,
                    empresa,
                    itens,
                    qtdeItNfvTotal: calculateQuantityProducts(itens),
                    vlrDesc: calculateTotDescont(itens),
                    vlrNfvAvulsa: calculateTotalProducts(itens),
                  });

                  if (prodsList.length > 0) {
                    gridViewProdutos.current.setDataSource(itens);
                    if (
                      gridViewItensOrdemServico &&
                      gridViewItensOrdemServico.current
                    ) {
                      gridViewItensOrdemServico.current.setDataSource(
                        prodsList
                      );
                    }
                  }
                }}
                dataSourceTextProperty='cdCompletoOrdemServico'
              />
            </div>
          </>
        )}
      </div>

      <div className='row mb-2'>
        <div className='col-3'>
          <Textbox
            label='CFO'
            text={data?.cfo?.noCfo}
            readOnly
            maxLength={20}
            onChangedValue={(nrNfv) =>
              setData({
                ...data,
                nrNfv,
              })
            }
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Cód. CFO'
            text={data?.cfo?.cdCfo}
            readOnly
            maxLength={20}
            onChangedValue={(nrNfv) =>
              setData({
                ...data,
                nrNfv,
              })
            }
          />
        </div>

        <div className='col-2'>
          <Textbox
            label='N° Nota Avulsa'
            text={data.nrNfvAvulsa}
            readOnly
            maxLength={20}
            mask={MaskTypes.Integer}
            onChangedValue={(nrNfvAvulsa) =>
              setData({
                ...data,
                nrNfvAvulsa,
              })
            }
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='N° Nota Fiscal Efetivada'
            text={data?.nfv?.nrNfv}
            maxLength={20}
            readOnly
            onChangedValue={(nrNfv) => {
              const { nfv } = data;
              setData({
                ...data,
                nfv: { ...nfv, nrNfv },
              });
            }}
          />
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col'>
          <Textbox
            label='Observações da nota'
            text={data.obsNfv}
            maxLength={300}
            onChangedValue={(obsNfv) =>
              setData({
                ...data,
                obsNfv,
              })
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col mb-3'>
          <PainelEndereco NrSeqPessoa={data.nrSeqPessoaCli} />
        </div>
      </div>
      {data.tipoNfvAvulsa?.flgDevolucao && (
        <div className='row mb-3'>
          <div className='col-12'>
            <Panel>
              <Panel.Header
                icon={['fas', 'briefcase']}
                title='Nota de Devolução'
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col'>
                    <RadioButton
                      outline
                      label='Tipo de Devolução'
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      buttons={statusNotaDevolucao}
                      selectedButton={data.noTipoDevolucao}
                      onChange={async (noTipoDevolucao) => {
                        if (noTipoDevolucao === 'N' && gridViewNota.current) {
                          gridViewNota.current.setDataSource(data.notas);
                        }
                        setData({ ...data, noTipoDevolucao });
                      }}
                    />
                  </div>
                </div>
                {data.noTipoDevolucao === 'C' && (
                  <div className='col-5'>
                    <Textbox
                      label='Chave Nf Entrada'
                      text={data.nfvChaveNfe.chaveNfe}
                      onChangedValue={(chaveNfe) =>
                        setData({ ...data, nfvChaveNfe: { chaveNfe } })
                      }
                    />
                  </div>
                )}
                {data.noTipoDevolucao === 'N' && (
                  <div className='row mb-3'>
                    <div className='col-2'>
                      <Autocomplete
                        label='Nro Nf'
                        searchDataSource={onSearchNota}
                        selectedItem={dataNfNfv.nf}
                        onSelectItem={(nf) => {
                          setDataNfNfv({
                            ...dataNfNfv,
                            nf,
                            nrSeqNf: nf.nrSeqNf,
                          });
                        }}
                        dataSourceTextProperty='nrForNf'
                      />
                    </div>
                    <div className='col-1 mt-3'>
                      <Button
                        outline
                        theme={Theme.Success}
                        template={Button.Templates.Button}
                        text='Adicionar'
                        onClick={onClickAddNota}
                      />
                    </div>
                  </div>
                )}
                {data.noTipoDevolucao === 'N' && (
                  <div className='row mb-3'>
                    <div className='col-12'>
                      <GridView
                        ref={gridViewNota}
                        className='table-striped table-hover table-bordered table-sm'
                        dataColumns={columnsNf}
                        offlineData
                        showPagination={false}
                        showSelectSizes={false}
                      />
                    </div>
                  </div>
                )}
              </Panel.Body>
            </Panel>
          </div>
        </div>
      )}

      <div className='row mb-2'>
        <div className='col-12'>
          <Panel>
            <Panel.Header
              icon={['fas', 'briefcase']}
              title='Produtos'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />

            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-4'>
                  <Autocomplete
                    label='* Produto'
                    searchDataSource={onSearchProduto}
                    selectedItem={dataProduto?.produto}
                    onSelectItem={async (produto) => {
                      const { nrSeqProduto } = produto;

                      if (nrSeqProduto) {
                        const vlrItNfv = await getLastPurchaseValue({
                          nrSeqProduto,
                        });

                        const produtoAgrupadores = await getProdutoAgrupador(
                          produto.nrSeqProduto
                        );
                        produto.produtoAgrupador = produtoAgrupadores;

                        const newDataProduto = {
                          ...dataProduto,
                          produto,
                          nrSeqProduto,
                          vlrItNfv,
                        };
                        setDataProduto(newDataProduto);
                      } else {
                        setDataProduto({
                          qtdeItNfv: 0,
                          vlrItNfv: 0,
                          vlrTotNfv: 0,
                          vlrDesc: 0,
                          vlrOutrasDespesasAcessorias: 0,
                          qtdeItem: 0,
                        });
                      }
                    }}
                    dataSourceTextProperty='noProduto'
                  />
                </div>

                {dataProduto.produto?.produtoAgrupador?.produtoGrupo
                  ?.produtoGrupoTipo?.flgServico !== true && (
                  <div className='col-4'>
                    <Autocomplete
                      label='* Local de Armazenagem'
                      searchDataSource={onSearchAlmoxarifadoLocal}
                      selectedItem={dataProduto?.almoxarifadoLocal}
                      onSelectItem={async (almoxarifadoLocal) => {
                        const { nrSeqAlmoxarifadoLocal } = almoxarifadoLocal;

                        if (nrSeqAlmoxarifadoLocal) {
                          const qtdeItem = await getBalance(
                            nrSeqAlmoxarifadoLocal
                          );

                          const newDataProduto = {
                            ...dataProduto,
                            almoxarifadoLocal,
                            nrSeqAlmoxarifadoLocal,
                            qtdeItem,
                          };
                          setDataProduto(newDataProduto);
                        } else {
                          setDataProduto({
                            qtdeItNfv: 0,
                            vlrItNfv: 0,
                            vlrTotNfv: 0,
                            vlrDesc: 0,
                            qtdeItem: 0,
                            vlrOutrasDespesasAcessorias: 0,
                          });
                        }
                      }}
                      dataSourceTextProperty='noAlmoxarifadoLocal'
                    />
                  </div>
                )}

                <div className='col-4'>
                  <Autocomplete
                    label='CFO'
                    searchDataSource={onSearchCfo}
                    selectedItem={dataProduto?.cfo}
                    onSelectItem={(cfo) => {
                      setDataProduto({
                        ...dataProduto,
                        cfo,
                        nrSeqCfo: cfo.nrSeqCfo,
                      });
                    }}
                    dataSourceTextProperty='noCfo'
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-1'>
                  <Textbox
                    // observar essa parte
                    label='Qtd.Estoque'
                    text={dataProduto.qtdeItem}
                    readOnly
                    maxLength={20}
                    onChangedValue={(qtdeItem) =>
                      setDataProduto({
                        ...dataProduto,
                        qtdeItem,
                      })
                    }
                  />
                </div>
                <div className='col-1'>
                  <Textbox
                    label='Unid.'
                    text={
                      dataProduto?.produto?.produtoUnidadeMedida
                        ?.cdProdutoUnidadeMedida
                    }
                    readOnly
                  />
                </div>

                <div className='col-1'>
                  <Textbox
                    label='* Qtd.'
                    text={dataProduto.qtdeItNfv}
                    mask={MaskTypes.DecimalCustom}
                    decimalPlaces={4}
                    onChangedValue={(qtdeItNfv) =>
                      setDataProduto({
                        ...dataProduto,
                        qtdeItNfv,
                        vlrTotNfv: calcularValorTotalProduto({ qtdeItNfv }),
                      })
                    }
                  />
                </div>
                <div className='col-1'>
                  <Textbox
                    label='* Vlr. Unit'
                    text={dataProduto.vlrItNfv}
                    mask={MaskTypes.DecimalCustom}
                    decimalPlaces={4}
                    onChangedValue={(vlrItNfv) =>
                      setDataProduto({
                        ...dataProduto,
                        vlrItNfv,
                        vlrTotNfv: calcularValorTotalProduto({ vlrItNfv }),
                      })
                    }
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Vlr. Desconto'
                    text={dataProduto.vlrDesc}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrDesc) =>
                      setDataProduto({
                        ...dataProduto,
                        vlrDesc,
                        vlrTotNfv: calcularValorTotalProduto({ vlrDesc }),
                      })
                    }
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Vlr. Outras Despesas'
                    text={dataProduto.vlrOutrasDespesasAcessorias}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrOutrasDespesasAcessorias) =>
                      setDataProduto({
                        ...dataProduto,
                        vlrOutrasDespesasAcessorias,
                      })
                    }
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Valor Total'
                    text={dataProduto.vlrTotNfv}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                  />
                </div>

                <div className='col-1 mt-3'>
                  <Button
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Adicionar'
                    onClick={onClickAddProduto}
                  />
                </div>

                <div className='col-1 mt-3'>
                  <Button
                    outline
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    text='Impostos'
                    onClick={onClickAddImpostos}
                    visible={data.tipoNfvAvulsa?.flgImpostoManual}
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-12'>
                  <GridView
                    ref={gridViewProdutos}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsProdutos}
                    showPagination={false}
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-2'>
                  <Textbox
                    label='Desconto'
                    mask={MaskTypes.DecimalCustom}
                    text={data.vlrDesc}
                    readOnly
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Valor Produtos'
                    text={data.vlrNfvAvulsa}
                    readOnly
                    mask={MaskTypes.DecimalCustom}
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Frete'
                    text={data.vlrFrete}
                    readOnly
                    mask={MaskTypes.DecimalCustom}
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Total Outras Despesas'
                    text={data.vlrDespesas}
                    readOnly
                    mask={MaskTypes.DecimalCustom}
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Total Nota'
                    text={data.vlrTotOperacao}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>

          <div className='row mb-2'>
            <div className='col-12'>
              <Panel>
                <Panel.Header
                  icon={['fa', 'truck']}
                  title='Frete'
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                />

                <Panel.Body>
                  <div className='row mb-3'>
                    <div className='col-3'>
                      <Autocomplete
                        label='Tipo Frete'
                        searchDataSource={onSearchTipoFrete}
                        selectedItem={data.tipoFrete}
                        onSelectItem={(tipoFrete) => {
                          setData({
                            ...data,
                            tipoFrete,
                            nrSeqTipoFrete: tipoFrete.nrSeqTipoFrete,
                          });
                        }}
                        dataSourceTextProperty='noTipoFrete'
                      />
                    </div>
                    <div className='col-6'>
                      <Autocomplete
                        label='Transportadora'
                        searchDataSource={onSearchTransportadora}
                        selectedItem={data.transportadora}
                        onSelectItem={(transportadora) => {
                          setData({
                            ...data,
                            transportadora,
                            nrSeqPessoaTran: transportadora.nrSeqPessoaTran,
                          });
                        }}
                        dataSourceTextProperty='noPessoa'
                      />
                    </div>
                    <div className='col-3'>
                      <Textbox
                        label='Valor do Frete'
                        text={data.vlrFrete}
                        maxLength={20}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={(vlrFrete) =>
                          setData({
                            ...data,
                            vlrFrete,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <div className='col-2'>
                      <Textbox
                        label='Quantidade'
                        text={data.qtdeFrete}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={(qtdeFrete) =>
                          setData({
                            ...data,
                            qtdeFrete,
                          })
                        }
                      />
                    </div>
                    <div className='col-3'>
                      <Textbox
                        label='Espécie'
                        text={data.noEspecieFrete}
                        maxLength={20}
                        onChangedValue={(noEspecieFrete) =>
                          setData({
                            ...data,
                            noEspecieFrete,
                          })
                        }
                      />
                    </div>
                    <div className='col-2'>
                      <Textbox
                        label='Peso Bruto'
                        text={data.pesoBrutoFrete}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={(pesoBrutoFrete) =>
                          setData({
                            ...data,
                            pesoBrutoFrete,
                          })
                        }
                      />
                    </div>
                    <div className='col-2'>
                      <Textbox
                        label='Peso Líquido'
                        text={data.pesoLiquidoFrete}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={(pesoLiquidoFrete) =>
                          setData({
                            ...data,
                            pesoLiquidoFrete,
                          })
                        }
                      />
                    </div>
                    <div className='col-3'>
                      <Autocomplete
                        label='Transportadora Redespacho'
                        searchDataSource={onSearchTransportadora}
                        selectedItem={data.transportadoraRedespacho}
                        onSelectItem={(transportadoraRedespacho) => {
                          setData({
                            ...data,
                            transportadoraRedespacho,
                            nrSeqPessoaTrar:
                              transportadoraRedespacho.nrSeqPessoaTran,
                          });
                        }}
                        dataSourceTextProperty='noPessoa'
                      />
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <div className='col-3'>
                      <Switch
                        formControl
                        label='Veículo Cadastrado'
                        checked={data.flgVeiculoCadastrado}
                        onChange={(flgVeiculoCadastrado) => {
                          setData({ ...data, flgVeiculoCadastrado });
                        }}
                      />
                    </div>
                  </div>

                  {data.flgVeiculoCadastrado && (
                    <div className='row mb-3'>
                      <div className='col-2'>
                        <Autocomplete
                          label='Placa'
                          searchDataSource={onSearchVeiculo}
                          selectedItem={data.veiculo}
                          onSelectItem={(veiculo) => {
                            setData({
                              ...data,
                              veiculo,
                              nrSeqVeiculo: veiculo.nrSeqVeiculo,
                            });
                          }}
                          dataSourceTextProperty='placa'
                        />
                      </div>
                    </div>
                  )}

                  {!data.flgVeiculoCadastrado && (
                    <div className='row mb-3'>
                      <div className='col-2'>
                        <Textbox
                          label='Placa'
                          text={data.placa}
                          maxLength={7}
                          onChangedValue={(placa) =>
                            setData({
                              ...data,
                              placa,
                            })
                          }
                        />
                      </div>

                      <div className='col-2'>
                        <Textbox
                          label='Rntrc'
                          text={data.rntrc}
                          maxLength={8}
                          onChangedValue={(rntrc) =>
                            setData({
                              ...data,
                              rntrc,
                            })
                          }
                        />
                      </div>
                      <div className='col-2'>
                        <Autocomplete
                          label='UF Veículo'
                          searchDataSource={onSearchEstadoVeiculo}
                          selectedItem={data.estadoVeiculo}
                          onSelectItem={(estadoVeiculo) =>
                            setData({
                              ...data,
                              estadoVeiculo,
                              nrSeqEstadoVeiculo: estadoVeiculo.nrSeqEstado,
                            })
                          }
                          dataSourceTextProperty='cdEstado'
                        />
                      </div>
                    </div>
                  )}
                </Panel.Body>
              </Panel>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <ModalImpostosNotaFiscal
            show={showModal}
            itemNota={dataProduto}
            onClose={() => setShowModal(false)}
            onSave={(item) => setDataProduto(item)}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
