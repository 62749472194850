import React, { useRef, useState, useEffect } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  getPagamentoPagined,
  deleteAllPagamento,
  printPagamentoFornecedor,
  printPagamentoVencimento,
  printPagamentos,
  excelPagamentoFornecedor,
  excelPagamentoVencimento,
  excelPagamentos,
} from 'core/services/FIN/pagamento';

import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import {
  Autocomplete,
  CSDSelPage,
  DatePicker,
  GridView,
  Panel,
  RadioButton,
  Textbox,
  ToolbarButtons,
  DropdownMulti,
} from 'ui/components';
import { getTipoDespesaAutoComplete } from 'core/services/FIN';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import { getFornecedorList } from 'core/services/FIN/fornecedor';
import { MaskTypes } from 'ui/Helpers/masks';

const columns = [
  { key: 'tituloPagar.nrSeqTituloPagar', type: GridView.ColumnTypes.Checkbox },
  { key: 'tituloPagar.cdTituloPagar', title: 'Compromisso' },
  { key: 'tituloPagar.nrTit', title: 'nr. Tit' },
  { key: 'pessoa.noPessoa', title: 'Fornecedor', sortKey: 'noPessoa' },
  { key: 'vlrTotal', title: 'VlrTotal', format: GridView.DataTypes.Money },
  { key: 'vlrSaldoPago', title: 'Pago', format: GridView.DataTypes.Money },
  { key: 'vlrSaldo', title: ' Saldo', format: GridView.DataTypes.Money },
  {
    key: 'tituloPagar.dtVencimento',
    title: 'Vencimento',
    format: GridView.DataTypes.Date,
  },
  { key: 'dtPagamento', title: 'Pagamento', format: GridView.DataTypes.Date },
];

export default function Pagamento({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({
    tipoTituloPagar: 'A',
    dtVencimentoDe: null,
    dtVencimentoAt: null,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});

  const dropDownFormaPagamento = useRef();

  const onSearchFormaPagamento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgTitPag: true,
    });

    return formasPagamento;
  };

  useEffect(() => {
    dropDownFormaPagamento.current.loadDataSource(onSearchFormaPagamento);
  }, []);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getPagamentoPagined(params ?? filtros);
    for (let i = 0; i < data.length; ) {
      data[i].vlrSaldo = data[i].vlrTotal - data[i].vlrSaldoPago;
      i += 1;
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const tipoTitulo = [
    {
      text: 'Aberto',
      value: 'A',
    },
    {
      text: 'Todos',
      value: 'T',
    },
    {
      text: 'Fechado',
      value: 'F',
    },
  ];

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Pagamento/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqTituloPagar = sourceRow.find(
      (e) => e.key === 'tituloPagar.nrSeqTituloPagar'
    );
    onOpenMaintenance(nrSeqTituloPagar.value);
  };

  const onPrintFornecedor = async () => {
    setLoading(true);
    const value = await printPagamentoFornecedor(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onPrintVencimento = async () => {
    setLoading(true);
    const value = await printPagamentoVencimento(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onPrintPagamento = async () => {
    setLoading(true);
    const value = await printPagamentos(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onExcelFornecedor = async () => {
    setLoading(true);
    const value = await excelPagamentoFornecedor(filtros);

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `Pagamento.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const onExcelVencimento = async () => {
    setLoading(true);
    const value = await excelPagamentoVencimento(filtros);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `Pagamento.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const onExcelPagamento = async () => {
    setLoading(true);
    const value = await excelPagamentos(filtros);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `Pagamento.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllPagamento(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };
  const onSearchTipoDespesa = async (e) => {
    const { tipoDespesas: tipoDespesa } = await getTipoDespesaAutoComplete({
      noTipoDespesa: e,
    });

    return tipoDespesa;
  };

  const onSearchEmpresa = async (e) => {
    const { empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });

    return empresas;
  };

  const onSearchFornecedor = async (e) => {
    const { pessoaFors } = await getFornecedorList({
      noFornecedor: e,
    });

    return pessoaFors;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Pagamento'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.ButtonList
          onPrintFornecedorAgrupado
          text='Tipo De Impressão'
          icon='caret-down'
          items={[
            {
              text: 'Fornecedor ',
              onClick: () => onPrintFornecedor('Agrupado'),
            },

            {
              text: 'Vencimento',
              onClick: () => onPrintVencimento('Agrupado'),
            },
            {
              text: 'Pagamento',
              onClick: () => onPrintPagamento('Agrupado'),
            },
          ]}
        />
      </ToolbarButtons>
      <ToolbarButtons>
        <ToolbarButtons.ButtonList
          onPrintFornecedorAgrupado
          text='Tipo De Excel'
          icon='caret-down'
          items={[
            {
              text: 'Fornecedor ',
              onClick: () => onExcelFornecedor('Agrupado'),
            },

            {
              text: 'Vencimento',
              onClick: () => onExcelVencimento('Agrupado'),
            },
            {
              text: 'Pagamento',
              onClick: () => onExcelPagamento('Agrupado'),
            },
          ]}
        />
      </ToolbarButtons>
      <Panel>
        <Panel.Header
          icon={['fas', 'user']}
          title='Filtros'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col-6 mb-4'>
              <Autocomplete
                label='Fornecedor'
                searchDataSource={onSearchFornecedor}
                selectedItem={filtros.fornecedor}
                onSelectItem={(fornecedor) => {
                  setFiltros({
                    ...filtros,
                    fornecedor,
                    nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-4 mb-4'>
              <RadioButton
                label='Tipo'
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={filtros.tipoTituloPagar}
                buttons={tipoTitulo}
                onChange={(tipoTituloPagar) => {
                  setFiltros({
                    ...filtros,
                    tipoTituloPagar,
                  });
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-6 mb-4'>
              <Autocomplete
                label='Empresa'
                searchDataSource={onSearchEmpresa}
                selectedItem={filtros.empresa}
                onSelectItem={(empresa) => {
                  setFiltros({
                    ...filtros,
                    empresa,
                    nrSeqEmpresa: empresa.nrSeqEmpresa,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-6 mb-4'>
              <Autocomplete
                label='Tipo Despesa'
                searchDataSource={onSearchTipoDespesa}
                selectedItem={filtros.tipoDespesa}
                onSelectItem={(tipoDespesa) => {
                  setFiltros({
                    ...filtros,
                    tipoDespesa,
                    nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
                  });
                }}
                dataSourceTextProperty='noTipoDespesa'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-3 mb-3'>
              <Textbox
                maxLength={20}
                label='Nr. Documento'
                text={filtros.nrTit}
                onChangedValue={(nrTit) =>
                  setFiltros({
                    ...filtros,
                    nrTit,
                  })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                maxLength={20}
                label='Nr. Compromisso'
                text={filtros.nrIdentificado}
                onChangedValue={(nrIdentificado) =>
                  setFiltros({
                    ...filtros,
                    nrIdentificado,
                  })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <Textbox
                maxLength={20}
                label='Vlr Título'
                text={filtros.VlrTotal}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(VlrTotal) =>
                  setFiltros({
                    ...filtros,
                    VlrTotal,
                  })
                }
              />
            </div>

            <div className='col-4'>
              <DropdownMulti
                label='Forma De Pagamento'
                ref={dropDownFormaPagamento}
                dataSourcePropertyText='noFormaPagamento'
                dataSourcePropertyValue='nrSeqFormaPagamento'
                selectedItems={selectedItems.formaPagamento ?? []}
                onSelectItem={(formaPagamento) => {
                  setSelectedItems({ ...selectedItems, formaPagamento });
                  setFiltros({
                    ...filtros,
                    nrSeqFormaPagamentos: formaPagamento?.map(
                      (p) => p.nrSeqFormaPagamento
                    ),
                  });
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Vencimento De'
                text={filtros.dtVencimentoDe}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtVencimentoDe) =>
                  setFiltros({ ...filtros, dtVencimentoDe })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Vencimento Até'
                text={filtros.dtVencimentoAt}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtVencimentoAt) =>
                  setFiltros({ ...filtros, dtVencimentoAt })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Contabil De'
                text={filtros.contabilDe}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(contabilDe) =>
                  setFiltros({ ...filtros, contabilDe })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Contabil Até'
                text={filtros.contabilAte}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(contabilAte) =>
                  setFiltros({ ...filtros, contabilAte })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Pagamento De'
                text={filtros.pagamentoDe}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(pagamentoDe) =>
                  setFiltros({ ...filtros, pagamentoDe })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Pagamento Até'
                text={filtros.pagamentoAte}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(pagamentoAte) =>
                  setFiltros({ ...filtros, pagamentoAte })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
            sumFields
            enableExcelExport
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
