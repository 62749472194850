import Produto from './produto';
import ProdutoAgrupador from './produtoAgrupador';
import EstoqueTipo from './estoqueTipo';
import Empresa from '../SEG/empresa';

export default class RequisicaoMovEstoque {
  constructor(jsonObject = {}) {
    this.nrSeqRequisacaoMovEstoque = jsonObject.nrSeqRequisacaoMovEstoque;
    this.dtCad = jsonObject.dtCad;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.dtMovimento = jsonObject.dtMovimento;
    this.noDocumento = jsonObject.noDocumento;
    this.nrSeqEstoqueTipo = jsonObject.nrSeqEstoqueTipo;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.cdRequisacaoMovEstoque = jsonObject.cdRequisacaoMovEstoque;
    this.observacao = jsonObject.observacao;
    this.nrSeqAlmoxarifado = jsonObject.nrSeqAlmoxarifado;
    this.nrSeqProdutoGrupo = jsonObject.nrSeqProdutoGrupo;
    this.status = jsonObject.status;

    this.flgTransferenciaAlmoxarifados =
      jsonObject.flgTransferenciaAlmoxarifados;
    this.nrSeqPessoaFun = jsonObject.nrSeqPessoaFun;
    this.flgSaidaEPIFuncionario = jsonObject.flgSaidaEPIFuncionario;
    this.flgGerarDocEntregaEPI = jsonObject.flgGerarDocEntregaEPI;

    this.produtoGrupo = jsonObject.produtoGrupo ?? {};
    this.produtoSubGrupo = jsonObject.produtoSubGrupo ?? {};

    this.produto = new Produto(jsonObject.produto ?? {});
    this.produtoAgrupador = new ProdutoAgrupador(
      jsonObject.produtoAgrupador ?? {}
    );
    this.estoqueTipo = new EstoqueTipo(jsonObject.estoqueTipo ?? {});
    this.requisicoesMovEstoqueItem = jsonObject.requisicoesMovEstoqueItem ?? {};
    this.almoxarifado = jsonObject.almoxarifado ?? {};
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.funcionario = jsonObject.funcionario ?? {};
  }

  toJson = () => JSON.stringify(this);
}
