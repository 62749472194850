import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Textbox,
  Switch,
  Autocomplete,
  GridView,
  DatePicker,
  Button,
  DropdownList,
  Textarea,
  ToolbarButtons,
} from 'ui/components';
import {
  getManutencaoChecklistFrota,
  printManutencaoChecklistFrota,
  postManutencaoChecklistFrotaSearchVeiculosVinculados,
  postManutencaoChecklistFrotaSearchTriggerPlacaDetails,
  saveManutencaoChecklistFrota,
  deleteManutencaoChecklistFrota,
  gerarOsManutencaoChecklistFrota,
} from 'core/services/FRO/manutencaoChecklistFrota';
import { MaskTypes } from 'ui/Helpers/masks';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';
import { getChecklistGrupoList } from 'core/services/FRO/checklistGrupo';
import { getChecklistFrotaAutoComplete } from 'core/services/FRO/checklistFrota';
import { saveChecklistValor } from 'core/services/FRO/checklistValor';
import { getChecklistFrotaManutencaoRetorno } from 'core/services/FRO/checklistFrotaRetorno';
import { getManutencaoVeiculoOSFindOs } from 'core/services/FRO/manutencaoVeiculoOS';
import ModalImportDocumento from 'ui/pages/FRO/ManutencaoChecklistFrota/modallmportDocumento';
import ModalExcluirOcorrencia from 'ui/pages/FRO/ManutencaoChecklistFrota/modalExcluirOcorrencia';

export default function ManutencaoChecklistFrotaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  onOpenReport,
  transaction,
}) {
  const gridViewManutencaoChecklistFrotaVeiculo = useRef();
  const gridViewManutencaoPreventiva = useRef();
  const gridViewChecklistFrotaRetornos = useRef();
  const gridViewchecklistOcorrencias = useRef();
  const gridViewChecklistDocumentos = useRef();

  const formSubmit = useRef();
  const modalImportDocumentoRef = useRef({});
  const [data, setData] = useState({
    flgBuscaVeiculoVinculado: true,
  });
  const [modalimportaDocumento, setModalimportaDocumento] = useState({});
  const [
    manutencaochecklistFrotaDocumento,
    setManutencaochecklistFrotaDocumento,
  ] = useState({});
  const [
    manutencaochecklistFrotaDocumentoS,
    setManutencaochecklistFrotaDocumentoS,
  ] = useState([]);
  const [veiculoOcorrencia, setVeiculoOcorrencia] = useState({});
  const [ordemServico, setOrdemServico] = useState({ flgConcluido: false });
  const [listVeiculoOcorrencia, setListVeiculoOcorrencia] = useState([]);
  const [listGrupoOcorrencia, setListGrupoOcorrencia] = useState([]);
  const [
    manutencaoChecklistFrotaVeiculo,
    setManutencaoChecklistFrotaVeiculo,
  ] = useState({});
  const [listManutencaoPreventiva, setListManutencaoPreventiva] = useState([]);
  const [
    listManutencaoChecklistFrotaVeiculo,
    setListManutencaoChecklistFrotaVeiculo,
  ] = useState([]);
  const [listChecklistRetornos, setListChecklistRetornos] = useState([]);

  const [listchecklistOcorrencias, setlistchecklistOcorrencias] = useState([]);
  const [modalExcluirOcorrencia, setModalExcluirOcorrencia] = useState({
    show: false,
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const saveRetornoMecanico = async (keyValue, value) => {
    const datasource = gridViewchecklistOcorrencias.current
      ? gridViewchecklistOcorrencias.current.getDataSource()
      : [];

    const itemOcorrencia = datasource.find(
      (item) => item.nrSeqChecklistValor === keyValue
    );
    itemOcorrencia.valor = value;

    for (let i = 0; i < datasource.length; ) {
      if (
        datasource[i].nrSeqChecklistValor === itemOcorrencia.nrSeqChecklistValor
      ) {
        datasource[i].valor = itemOcorrencia.valor;
      }
      i += 1;
    }

    if (gridViewchecklistOcorrencias && gridViewchecklistOcorrencias.current)
      gridViewchecklistOcorrencias.current.setDataSource(datasource);
  };
  const saveRetornoOdometro = async (keyValue, value) => {
    const datasource = gridViewManutencaoChecklistFrotaVeiculo.current
      ? gridViewManutencaoChecklistFrotaVeiculo.current.getDataSource()
      : [];

    const itemOdometro = datasource.find(
      (item) => item.nrSeqManutencaoChecklistFrotaVeiculo === keyValue
    );
    itemOdometro.nrOdometro = value;
    itemOdometro.status = GridView.EnumStatus.Alterar;

    for (let i = 0; i < datasource.length; ) {
      if (
        datasource[i].nrSeqManutencaoChecklistFrotaVeiculo ===
        itemOdometro.nrSeqManutencaoChecklistFrotaVeiculo
      ) {
        datasource[i].nrOdometro = itemOdometro.nrOdometro;
      }
      i += 1;
    }

    if (
      gridViewManutencaoChecklistFrotaVeiculo &&
      gridViewManutencaoChecklistFrotaVeiculo.current
    )
      gridViewManutencaoChecklistFrotaVeiculo.current.setDataSource(datasource);
  };

  const fRetornoMecanico = function (keyValue, value) {
    saveRetornoMecanico(keyValue, value);
  };
  const fRetornoOdometro = function (keyValue, value) {
    saveRetornoOdometro(keyValue, value);
  };
  const saveRetornoComplemento = async (keyValue, value) => {
    const datasource = gridViewChecklistFrotaRetornos.current
      ? gridViewChecklistFrotaRetornos.current.getDataSource()
      : [];

    const itemCampos = datasource.find(
      (item) => item.nrSeqChecklistCampos === keyValue
    );
    itemCampos.noComplemento = value;

    for (let i = 0; i < datasource.length; ) {
      if (
        datasource[i].nrSeqChecklistCampos === itemCampos.nrSeqChecklistCampos
      ) {
        datasource[i].noComplemento = itemCampos.noComplemento;
      }
      i += 1;
    }

    if (
      gridViewChecklistFrotaRetornos &&
      gridViewChecklistFrotaRetornos.current
    )
      gridViewChecklistFrotaRetornos.current.setDataSource(datasource);
  };

  const fRetornoComplemento = function (keyValue, value) {
    saveRetornoComplemento(keyValue, value);
  };

  const fOnSelectItem = (keyValue, value) => {
    const datasource = gridViewChecklistFrotaRetornos.current
      ? gridViewChecklistFrotaRetornos.current.getDataSource()
      : [];

    const gridItem = datasource.find(
      (item) => item.nrSeqChecklistCampos === keyValue
    );
    gridItem.checklistValor = value;
    gridItem.nrSeqChecklistValor = value.nrSeqChecklistValor;
  };

  const onClickRemoveChecklistFrotaVeiculo = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);
    const updatedList = datasource.filter((el) => el !== e);

    if (
      gridItem.status !== GridView.EnumStatus.Inserir &&
      gridItem.status !== GridView.EnumStatus.Alterar
    ) {
      setListManutencaoChecklistFrotaVeiculo((prevList) =>
        prevList.map((item) =>
          item === e ? { ...item, status: GridView.EnumStatus.Remover } : item
        )
      );
    }

    if (
      gridViewManutencaoChecklistFrotaVeiculo &&
      gridViewManutencaoChecklistFrotaVeiculo.current
    ) {
      gridViewManutencaoChecklistFrotaVeiculo.current.setDataSource(
        updatedList
      );
    }
  };
  const onClickRemoveChecklistFrotaValor = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    setModalExcluirOcorrencia({
      show: true,
      itemClicado: gridItem,
    });
  };
  const onClickAtualizarOcorrenciaVeiculo = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);
    console.log(ordemServico);
    if (
      gridItem.nrSeqChecklistValor !== undefined &&
      gridItem.nrSeqChecklistCampos !== undefined
    ) {
      const checkvalor = {
        nrSeqChecklistValor: gridItem.nrSeqChecklistValor,
        nrSeqChecklistCampos: gridItem.nrSeqChecklistCampos,
        nrSeqManutencaoChecklistFrotaVeiculo:
          gridItem.nrSeqManutencaoChecklistFrotaVeiculo,
        cdChecklist: gridItem.cdChecklist,
        valor: gridItem.valor,
        flgIncluiObs: gridItem.flgIncluiObs,
        status: 'Alterar',
      };
      if (checkvalor !== null) {
        const { status, message: msg } = await saveChecklistValor(checkvalor);
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
      }
    }
  };

  const onClickRemoveChecklistDocumentos = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    gridItem.status = GridView.EnumStatus.Remover;

    if (gridViewChecklistDocumentos && gridViewChecklistDocumentos.current) {
      gridViewChecklistDocumentos.current.setDataSource(datasource);
    }
    setManutencaochecklistFrotaDocumentoS(datasource);
  };

  const onClickDownload = async (e) => {
    const linkSource = `data:image/png;base64, ${
      e.noImagemBase64 ?? e.noImagem
    }`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = e.noDocumento;
    downloadLink.click();
  };

  const columnsManutencaoChecklistFrotaVeiculo = [
    {
      key: 'nrSeqManutencaoChecklistFrotaVeiculo',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'veiculo.placa', title: 'Placa' },
    { key: 'nrSeqVeiculo', title: 'nrSeqVeiculo', visible: false },
    {
      key: 'nrOdometro',
      title: 'Odometro',
      type: GridView.ColumnTypes.Textbox,
      onBlur: fRetornoOdometro,
    },

    {
      key: 'nrSeqManutencaoChecklist',
      title: 'nrSeqManutencaoChecklist',
      visible: false,
    },
    {
      key: 'nrSeqManutencaoChecklistFrotaVeiculo',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) =>
        onClickRemoveChecklistFrotaVeiculo(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];
  const columnsManutencaoPreventiva = [
    {
      key: 'nrSeqVeiculo_ManutencaoPrevent',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'manutencaoPreventiva.noManutencaoPreventiva',
      title: 'Manutenção',
      bgClassProperty: 'noColorPendencia',
    },
    {
      key: 'nrOdometro',
      title: 'Odometro',
      bgClassProperty: 'noColorPendencia',
    },
    {
      key: 'dtMovimento',
      title: 'Data',
      format: GridView.DataTypes.Date,
      bgClassProperty: 'noColorPendencia',
    },
    {
      key: 'manutencaoPreventiva.valor',
      title: 'Manutenção Estimada',
      bgClassProperty: 'noColorPendencia',
    },
    {
      key: 'manutencaoPreventiva.tipoIntervalo.noTipoIntervalo',
      title: 'Unidade',
      bgClassProperty: 'noColorPendencia',
    },
    {
      key: 'ultimaInfoManutencao',
      title: 'Ultima Troca',
      bgClassProperty: 'noColorPendencia',
    },
    {
      key: 'proximaManutencao',
      title: 'Proxima Troca',
      bgClassProperty: 'noColorPendencia',
    },
    {
      key: 'faltaKmManutencao',
      title: 'Falta Km/Troca',
      bgClassProperty: 'noColorPendencia',
    },
    {
      key: 'statusManutencaoPreventiva',
      title: 'Status Manut Prev',
      bgClassProperty: 'noColorPendencia',
    },
  ];
  const columnsChecklistValores = [
    {
      key: 'nrSeqChecklistValor',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'placa', title: 'Placa' },
    { key: 'checklistCampos.noCampo', title: 'Campo' },
    {
      key: 'valor',
      title: 'Retorno/Mecânico',
      type: GridView.ColumnTypes.Textbox,
      onBlur: fRetornoMecanico,
    },
    {
      key: 'nrSeqChecklistCampos',
      title: 'NrSeqChecklistCampos',
      visible: false,
    },
    {
      key: 'nrSeqManutencaoChecklistFrotaVeiculo',
      title: 'NrSeqManutencaoChecklistFrotaVeiculo',
      visible: false,
    },
    {
      key: 'nrSeqChecklistValor',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
      onClick: (e, dataSource) =>
        onClickRemoveChecklistFrotaValor(e, dataSource),
    },
    {
      key: 'nrSeqChecklistValor',
      type: GridView.ColumnTypes.Button,
      title: 'Atualizar',
      theme: Theme.Secondary,
      icon: 'pencil-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Atualizar',
      tooltipDirection: 'bottom',
      onClick: (e, dataSource) =>
        onClickAtualizarOcorrenciaVeiculo(e, dataSource),
      visibleDynamic: 'flgConcluido',
    },
  ];
  const columnsChecklistDocumentos = [
    {
      key: 'nrSeqManutencaoChecklistFrotaDocumento',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'noImagem', title: 'Imagem', visible: false },
    { key: 'nrDocumento', title: 'Nr Documento' },
    { key: 'noDocumento', title: 'Nome Documento' },
    { key: 'tipo', title: 'Tipo', visible: false },
    { key: 'tamanho', title: 'Tamanho', visible: false },
    {
      key: 'nrSeqManutencaoChecklist',
      title: 'NrSeqManutencaoChecklist',
      visible: false,
    },
    {
      key: 'nrSeqManutencaoChecklistFrotaDocumento',
      type: GridView.ColumnTypes.Button,
      title: 'Download',
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
    },
    {
      key: 'nrSeqManutencaoChecklistFrotaDocumento',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) =>
        onClickRemoveChecklistDocumentos(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];
  const columnsChecklistFrotaRetornos = [
    {
      key: 'nrSeqChecklistCampos',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'cdTipoCampo', title: 'CdTipoCampo', visible: false },

    { key: 'noGrupo', title: 'Grupo' },
    { key: 'noCampo', title: 'Campo' },
    {
      key: 'nrSeqChecklistFrotaRetorno',
      visible: false,
    },
    {
      key: 'checklistValor',
      title: 'Valor',
      type: GridView.ColumnTypes.Autocomplete,
      dataSourceListProperty: 'checklistValores',
      dataSourceTextProperty: 'valor',
      onSelectItem: fOnSelectItem,
    },
    {
      key: 'noComplemento',
      title: 'Observações',
      type: GridView.ColumnTypes.Textbox,
      onBlur: fRetornoComplemento,
      readOnlyDynamic: 'flgAlteraTextBox',
    },
    {
      key: 'flgAlteraTextBox',
      visible: false,
    },
  ];

  const searchUltimoOdometro = async () => {
    const novaPlaca = {
      ...manutencaoChecklistFrotaVeiculo,
      status: 'Inserir',
    };

    const {
      value: objManutencaoChecklistFrota,
    } = await postManutencaoChecklistFrotaSearchTriggerPlacaDetails({
      ...data,
      manutencaoChecklistFrotaVeiculo: novaPlaca,
    });

    if (objManutencaoChecklistFrota !== undefined) {
      setData({
        ...data,
        ultimoOdometro: objManutencaoChecklistFrota.ultimoOdometro,
        motorista: objManutencaoChecklistFrota.motorista,
      });
    }
  };
  const searchChecklistFrotaRetorno = async () => {
    const {
      data: objChecklistFrotaRetorno,
    } = await getChecklistFrotaManutencaoRetorno({
      nrSeqChecklistFrota: data.nrSeqChecklistFrota,
    });

    if (objChecklistFrotaRetorno !== undefined) {
      objChecklistFrotaRetorno.forEach((item) => {
        item.checklistValor = undefined;
      });
      setListChecklistRetornos(objChecklistFrotaRetorno);
    }
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  const onNew = async () => {
    const currentDate = new Date(); // Obtém a data e hora atuais
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;

    setData({
      flgBuscaVeiculoVinculado: true,
      dtExecucao: formattedDate,
      dtFechamento: formattedDate,
      dtChecklist: formattedDate,
      dtSaidaChecklist: formattedDate,
    });

    setVeiculoOcorrencia({});
    setManutencaoChecklistFrotaVeiculo({});
    setListManutencaoPreventiva([]);
    setListManutencaoChecklistFrotaVeiculo([]);
    setManutencaochecklistFrotaDocumentoS([]);
    setManutencaochecklistFrotaDocumento({});
    setListChecklistRetornos([]);
    setlistchecklistOcorrencias([]);
    setListVeiculoOcorrencia([]);
    gridViewManutencaoChecklistFrotaVeiculo.current.setDataSource([]);
    gridViewChecklistFrotaRetornos.current.setDataSource([]);
    gridViewChecklistDocumentos.current.setDataSource([]);
    gridViewManutencaoPreventiva.current.setDataSource([]);
    gridViewchecklistOcorrencias.current.setDataSource([]);
    const { data: objchecklistgrupo } = await getChecklistGrupoList('');
    if (objchecklistgrupo !== undefined) {
      setListGrupoOcorrencia(objchecklistgrupo);
    }
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const obj = await getManutencaoChecklistFrota(registryKey);

      if (obj !== undefined) {
        setData({
          ...obj,
          flgBuscaVeiculoVinculado: true,
          motorista: obj.motorista,
        });
        if (obj.manutencaoChecklistFrotaVeiculo !== undefined) {
          setManutencaoChecklistFrotaVeiculo(
            obj.manutencaoChecklistFrotaVeiculo
          );
        }
        if (obj.manutencaoChecklistFrotaVeiculoS !== undefined) {
          setListManutencaoChecklistFrotaVeiculo(
            obj.manutencaoChecklistFrotaVeiculoS
          );
          const listaVeiculoOcorrencia = obj.manutencaoChecklistFrotaVeiculoS.map(
            (item) => item.veiculo
          );

          setListVeiculoOcorrencia(listaVeiculoOcorrencia);
        }
        if (obj.veiculo_ManutencaoPreventivaS !== null) {
          setListManutencaoPreventiva(obj.veiculo_ManutencaoPreventivaS);
        }
        if (obj.checklistOcorrencias !== undefined) {
          obj.checklistOcorrencias.forEach((p) => {
            if (p.manutencaoChecklistFrotaVeiculo !== null) {
              if (p.manutencaoChecklistFrotaVeiculo.veiculo !== null) {
                p.placa = p.manutencaoChecklistFrotaVeiculo.veiculo.placa;
              }
            }
          });
          setlistchecklistOcorrencias(obj.checklistOcorrencias);
          if (
            gridViewchecklistOcorrencias &&
            gridViewchecklistOcorrencias.current
          ) {
            gridViewchecklistOcorrencias.current.setDataSource(
              obj.checklistOcorrencias
            );
          }
        }

        if (obj.checklistRetornos !== undefined) {
          obj.checklistRetornos.forEach((item) => {
            if (item.checklistValor.nrSeqChecklistValor === undefined) {
              item.checklistValor = undefined;
            }
          });

          if (
            gridViewChecklistFrotaRetornos &&
            gridViewChecklistFrotaRetornos.current
          ) {
            gridViewChecklistFrotaRetornos.current.setDataSource(
              obj.checklistRetornos
            );
          }
          setListChecklistRetornos(obj.checklistRetornos);
        }
        if (obj.manutencaoChecklistFrotaDocumentoS !== undefined) {
          if (
            gridViewChecklistDocumentos &&
            gridViewChecklistDocumentos.current
          ) {
            gridViewChecklistDocumentos.current.setDataSource(
              obj.manutencaoChecklistFrotaDocumentoS
            );
          }
          setManutencaochecklistFrotaDocumentoS(
            obj.manutencaoChecklistFrotaDocumentoS
          );
        }

        const { data: objchecklistgrupo } = await getChecklistGrupoList();
        if (objchecklistgrupo !== undefined) {
          setListGrupoOcorrencia(objchecklistgrupo);
        }
        if (obj.nrSeqManutencaoVeiculoOS > 0) {
          const res = await getManutencaoVeiculoOSFindOs(
            obj.nrSeqManutencaoVeiculoOS
          );
          if (Object.keys(res).length > 0) {
            if (res.flgConcluido) {
              setOrdemServico(res);
            } else {
              setOrdemServico({ flgConcluido: false });
            }
          }
        }
      }
      setLoading(false);
    } else {
      onNew();
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  useEffect(() => {
    if (manutencaoChecklistFrotaVeiculo.nrSeqVeiculo > 0) {
      searchUltimoOdometro();
    }
  }, [manutencaoChecklistFrotaVeiculo?.nrSeqVeiculo]);
  useEffect(() => {
    if (!data.nrSeqManutencaoChecklist && data.nrSeqChecklistFrota > 0) {
      searchChecklistFrotaRetorno();
    }
  }, [data.nrSeqChecklistFrota]);
  useEffect(() => {
    if (
      gridViewManutencaoChecklistFrotaVeiculo &&
      gridViewManutencaoChecklistFrotaVeiculo.current
    ) {
      gridViewManutencaoChecklistFrotaVeiculo.current.setDataSource(
        listManutencaoChecklistFrotaVeiculo
      );
    }
  }, [listManutencaoChecklistFrotaVeiculo]);
  useEffect(() => {
    listChecklistRetornos.forEach((p) => {
      p.flgAlteraTextBox = true;
      if (p.cdTipoCampo === 2) {
        p.flgAlteraTextBox = false;
      }
      if (p.checklistValor !== null && p.checklistValor?.flgIncluiObs) {
        p.flgAlteraTextBox = false;
      }
    });
    if (
      gridViewChecklistFrotaRetornos &&
      gridViewChecklistFrotaRetornos.current
    ) {
      gridViewChecklistFrotaRetornos.current.setDataSource(
        listChecklistRetornos
      );
    }
  }, [listChecklistRetornos]);
  useEffect(() => {
    listchecklistOcorrencias.forEach((p) => {
      p.flgConcluido = false;
      if (ordemServico.flgConcluido) {
        p.flgConcluido = true;
      }
    });
    if (gridViewchecklistOcorrencias && gridViewchecklistOcorrencias.current) {
      gridViewchecklistOcorrencias.current.setDataSource(
        listchecklistOcorrencias
      );
    }
  }, [ordemServico.flgConcluido]);
  useEffect(() => {
    if (gridViewManutencaoPreventiva && gridViewManutencaoPreventiva.current) {
      gridViewManutencaoPreventiva.current.setDataSource(
        listManutencaoPreventiva
      );
    }
  }, [listManutencaoPreventiva]);
  const loader = async (params = null) => {
    if (Number.isInteger(params)) {
      setLoading(true);
      const obj = await getManutencaoChecklistFrota(params);

      if (obj !== undefined) {
        setData({
          ...obj,
          flgBuscaVeiculoVinculado: true,
          motorista: obj.motorista,
        });

        if (obj.manutencaoChecklistFrotaVeiculo !== null) {
          setManutencaoChecklistFrotaVeiculo(
            obj.manutencaoChecklistFrotaVeiculo
          );
        }
        if (obj.manutencaoChecklistFrotaVeiculoS !== null) {
          setListManutencaoChecklistFrotaVeiculo(
            obj.manutencaoChecklistFrotaVeiculoS
          );
          const listaVeiculoOcorrencia = obj.manutencaoChecklistFrotaVeiculoS.map(
            (item) => item.veiculo
          );

          setListVeiculoOcorrencia(listaVeiculoOcorrencia);
        }
        if (obj.veiculo_ManutencaoPreventivaS !== null) {
          setListManutencaoPreventiva(obj.veiculo_ManutencaoPreventivaS);
        }
        if (obj.checklistOcorrencias !== null) {
          obj.checklistOcorrencias.forEach((p) => {
            if (p.manutencaoChecklistFrotaVeiculo !== null) {
              if (p.manutencaoChecklistFrotaVeiculo.veiculo !== null) {
                p.placa = p.manutencaoChecklistFrotaVeiculo.veiculo.placa;
              }
            }
          });
          setlistchecklistOcorrencias(obj.checklistOcorrencias);
          if (
            gridViewchecklistOcorrencias &&
            gridViewchecklistOcorrencias.current
          ) {
            gridViewchecklistOcorrencias.current.setDataSource(
              obj.checklistOcorrencias
            );
          }
        }

        if (obj.checklistRetornos !== null) {
          obj.checklistRetornos.forEach((item) => {
            if (item.checklistValor.nrSeqChecklistValor === undefined) {
              item.checklistValor = undefined;
            }
          });
          if (
            gridViewChecklistFrotaRetornos &&
            gridViewChecklistFrotaRetornos.current
          ) {
            gridViewChecklistFrotaRetornos.current.setDataSource(
              obj.checklistRetornos
            );
          }
          setListChecklistRetornos(obj.checklistRetornos);
        }
        if (obj.manutencaoChecklistFrotaDocumentoS != null) {
          if (
            gridViewChecklistDocumentos &&
            gridViewChecklistDocumentos.current
          ) {
            gridViewChecklistDocumentos.current.setDataSource(
              obj.manutencaoChecklistFrotaDocumentoS
            );
          }
          setManutencaochecklistFrotaDocumentoS(
            obj.manutencaoChecklistFrotaDocumentoS
          );
        }

        const { data: objchecklistgrupo } = await getChecklistGrupoList();
        if (objchecklistgrupo !== undefined) {
          setListGrupoOcorrencia(objchecklistgrupo);
        }
        if (obj.nrSeqManutencaoVeiculoOS > 0) {
          const res = await getManutencaoVeiculoOSFindOs(
            data.nrSeqManutencaoVeiculoOS
          );
          if (Object.keys(res).length > 0) {
            if (res.flgConcluido) {
              setOrdemServico(res);
            } else {
              setOrdemServico({ flgConcluido: false });
            }
          }
        }
      }
      setLoading(false);
    } else {
      onNew();
    }
    setMessage(null);
  };

  const salvar = async () => {
    const listaChecklistRetornos = gridViewChecklistFrotaRetornos.current.getDataSource();
    const retornos = [];
    const listachecklistOcorrencias = gridViewchecklistOcorrencias.current.getDataSource();
    const listaManutencaoPreventivaS = gridViewManutencaoPreventiva.current.getDataSource();
    const listaManutencaoChecklistFrotaVeiculoS = gridViewManutencaoChecklistFrotaVeiculo.current.getDataSource();
    const listaManutencaoChecklistFrotaDocumentoS = gridViewChecklistDocumentos.current.getDataSource();
    const objManutencaoChecklistFrotaVeiculo = manutencaoChecklistFrotaVeiculo;

    if (listaManutencaoChecklistFrotaVeiculoS !== null)
      data.manutencaoChecklistFrotaVeiculoS = listaManutencaoChecklistFrotaVeiculoS;

    if (objManutencaoChecklistFrotaVeiculo !== null)
      data.manutencaoChecklistFrotaVeiculo = objManutencaoChecklistFrotaVeiculo;

    if (listaManutencaoPreventivaS !== null)
      data.veiculo_ManutencaoPreventivaS = listaManutencaoPreventivaS;

    if (listachecklistOcorrencias !== null)
      data.checklistOcorrencias = listachecklistOcorrencias;

    if (listaChecklistRetornos !== null) {
      listaChecklistRetornos.forEach((p) => {
        const retorno = {
          noComplemento: p.noComplemento,
          nrSeqChecklistCampos: p.nrSeqChecklistCampos,
          checklistValor: p.checklistValor,
          nrSeqChecklistValor: p.nrSeqChecklistValor,
          nrSeqUsuarioConf: p.nrSeqUsuarioConf,
          nrSeqManutencaoChecklist: p.nrSeqManutencaoChecklist,
          nrSeqChecklistFrotaRetorno: p.nrSeqChecklistFrotaRetorno,
        };
        retornos.push(retorno);
      });
      data.checklistFrotaRetornoS = retornos;
    }

    if (listaManutencaoChecklistFrotaDocumentoS !== null) {
      data.manutencaoChecklistFrotaDocumentoS = listaManutencaoChecklistFrotaDocumentoS;
    }

    const {
      status,
      message: msg,
      value: nrSeqManutencaoChecklistFrota,
    } = await saveManutencaoChecklistFrota(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    if (nrSeqManutencaoChecklistFrota > 0) {
      loader(nrSeqManutencaoChecklistFrota);
    }
  };

  const save = async () => {
    setLoading(true);

    if (data.nrSeqManutencaoVeiculoOS > 0) {
      const res = await getManutencaoVeiculoOSFindOs(
        data.nrSeqManutencaoVeiculoOS
      );

      if (Object.keys(res).length > 0) {
        if (res.flgConcluido) {
          onSetMessage(
            ResponseStatus.Warning,
            'Não é possivel editar apos ter gerado a OS'
          );
        } else {
          await salvar();
        }
      }
    } else {
      await salvar();
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (data.nrSeqManutencaoChecklist > 0) {
      setLoading(true);
      const { status, message: msg } = await deleteManutencaoChecklistFrota(
        data.nrSeqManutencaoChecklist
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };
  const onGerarOs = async () => {
    if (data.nrSeqManutencaoChecklist > 0) {
      setLoading(true);
      const { status, message: msg } = await gerarOsManutencaoChecklistFrota(
        data.nrSeqManutencaoChecklist
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };
  const onPrint = async () => {
    setLoading(true);
    const { value: obj } = await printManutencaoChecklistFrota({ ...data });

    onOpenReport(transaction.noTransacao, obj);
    setLoading(false);
  };
  const onSearchVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };
  const onSearchPessoaMot = async (e) => {
    const {
      status,
      message: msg,
      motoristas,
    } = await getMotoristaAutoComplete({ noPessoa: e });
    if (msg) onSetMessage(status, msg);
    return motoristas;
  };
  const onSearchChecklistFrota = async (e) => {
    const {
      status,
      message: msg,
      checklistFrotas,
    } = await getChecklistFrotaAutoComplete({ noChecklistFrota: e });
    if (msg) onSetMessage(status, msg);
    return checklistFrotas;
  };
  const onClickAddManutencaoChecklistFrotaVeiculo = async () => {
    if (!manutencaoChecklistFrotaVeiculo.nrOdometro) {
      return onSetMessage(
        ResponseStatus.Warning,
        'Necessario Inserir o Odometro'
      );
    }
    if (!manutencaoChecklistFrotaVeiculo.nrSeqVeiculo) {
      return onSetMessage(
        ResponseStatus.Warning,
        'Necessario Inserir o Veiculo'
      );
    }
    if (manutencaoChecklistFrotaVeiculo.nrOdometro < data.ultimoOdometro) {
      return onSetMessage(
        ResponseStatus.Warning,
        'O valor do odômetro não pode ser menor que o último registrado.'
      );
    }
    const placas = listManutencaoChecklistFrotaVeiculo ?? [];
    const preventivas = listManutencaoPreventiva ?? [];
    const ocorrencias = listchecklistOcorrencias ?? [];
    const novaPlaca = { ...manutencaoChecklistFrotaVeiculo, status: 'Inserir' };

    const {
      status,
      message: msg,
      value: objManutencaoChecklistFrota,
    } = await postManutencaoChecklistFrotaSearchVeiculosVinculados({
      ...data,
      manutencaoChecklistFrotaVeiculoS: placas,
      manutencaoChecklistFrotaVeiculo: novaPlaca,
      veiculo_ManutencaoPreventivaS: preventivas,
      checklistOcorrencias: ocorrencias,
    });

    if (msg) onSetMessage(status, msg);

    if (objManutencaoChecklistFrota !== undefined) {
      if (
        objManutencaoChecklistFrota.manutencaoChecklistFrotaVeiculoS.length > 0
      ) {
        setListManutencaoChecklistFrotaVeiculo(
          objManutencaoChecklistFrota.manutencaoChecklistFrotaVeiculoS
        );
      }

      setListManutencaoPreventiva(
        objManutencaoChecklistFrota.veiculo_ManutencaoPreventivaS
      );
      if (objManutencaoChecklistFrota.checklistOcorrencias.length > 0) {
        objManutencaoChecklistFrota.checklistOcorrencias.forEach((p) => {
          if (p.manutencaoChecklistFrotaVeiculo !== null) {
            if (p.manutencaoChecklistFrotaVeiculo.veiculo !== null) {
              p.placa = p.manutencaoChecklistFrotaVeiculo.veiculo.placa;
            }
          }
        });
        setlistchecklistOcorrencias(
          objManutencaoChecklistFrota.checklistOcorrencias
        );

        if (
          gridViewchecklistOcorrencias &&
          gridViewchecklistOcorrencias.current
        ) {
          gridViewchecklistOcorrencias.current.setDataSource(
            objManutencaoChecklistFrota.checklistOcorrencias
          );
        }
      }

      const listaVeiculoOcorrencia = objManutencaoChecklistFrota.manutencaoChecklistFrotaVeiculoS.map(
        (item) => item.veiculo
      );

      setListVeiculoOcorrencia(listaVeiculoOcorrencia);
    }
    return true;
  };
  const onClickAddOcorrenciaVeiculo = async () => {
    if (!veiculoOcorrencia.nrSeqVeiculo) {
      return onSetMessage(
        ResponseStatus.Warning,
        'Selecione o Veiculo da Ocorrência'
      );
    }
    if (!veiculoOcorrencia.nrSeqChecklistGrupo) {
      return onSetMessage(
        ResponseStatus.Warning,
        'Selecione Um Grupo da Ocorrência'
      );
    }

    const ocorrenciaS = gridViewchecklistOcorrencias.current
      ? gridViewchecklistOcorrencias.current.getDataSource()
      : [];
    let novaplaca = '';
    let placas = [];
    const ocorrencia = {
      ...veiculoOcorrencia,
    };
    placas = listManutencaoChecklistFrotaVeiculo;
    if (ocorrenciaS.length === 0) {
      placas.forEach((p) => {
        if (p.nrSeqVeiculo === ocorrencia.nrSeqVeiculo) {
          novaplaca = p.veiculo.placa;
        }
      });
    } else {
      novaplaca = ocorrencia.veiculoS.placa;
    }

    const checkcampo = {
      cdTipoCampo: 0,
      noCampo: ocorrencia.nomeNovaOcorrencia,
      nrSeqChecklistGrupo: ocorrencia.nrSeqChecklistGrupo,
      nrOrdem: 0,
      flgObrigaConfManutencao: false,
      flgVeiculo: true,
      status: 'Inserir',
    };
    const checkvalor = {
      cdChecklist: '',
      valor: '',
      checklistCampos: checkcampo,
      placa: novaplaca,
      manutencaoChecklistFrotaVeiculo: placas.find(
        (f) => f.nrSeqVeiculo === ocorrencia.nrSeqVeiculo
      ),
      status: 'Inserir',
    };

    ocorrenciaS.push(checkvalor);

    if (gridViewchecklistOcorrencias && gridViewchecklistOcorrencias.current) {
      gridViewchecklistOcorrencias.current.setDataSource(ocorrenciaS);
    }
    return setlistchecklistOcorrencias(ocorrenciaS);
  };
  const onClickGerarOcorrenciaVeiculo = async () => {
    if (!veiculoOcorrencia.nrSeqVeiculo) {
      return onSetMessage(
        ResponseStatus.Warning,
        'Selecione o Veiculo da Ocorrência'
      );
    }
    const ocorrencia = {
      ...veiculoOcorrencia,
    };

    const campos = gridViewChecklistFrotaRetornos.current
      ? gridViewChecklistFrotaRetornos.current.getDataSource()
      : [];
    const ocorrenciaS = gridViewchecklistOcorrencias.current
      ? gridViewchecklistOcorrencias.current.getDataSource()
      : [];

    let novaplaca = '';
    let placas = [];

    placas = listManutencaoChecklistFrotaVeiculo;

    if (ocorrenciaS.length === 0) {
      placas.forEach((p) => {
        if (p.nrSeqVeiculo === ocorrencia.nrSeqVeiculo) {
          novaplaca = p.veiculo.placa;
        }
      });
    } else {
      novaplaca = ocorrencia.veiculoS.placa;
    }

    const itensNaoOk = [];

    campos.forEach((item) => {
      if (item.checklistValor && item.checklistValor.valor) {
        const valorUpper = item.checklistValor.valor.toUpperCase();
        if (valorUpper === 'NAO OK' || valorUpper === 'NÃO OK') {
          itensNaoOk.push(item);
        }
      }
    });

    itensNaoOk.forEach((p) => {
      const checkcampo = {
        cdTipoCampo: 0,
        noCampo: p.noCampo,
        nrSeqChecklistGrupo: p.nrSeqChecklistGrupo,
        nrOrdem: 0,
        flgObrigaConfManutencao: false,
        flgVeiculo: true,
        status: 'Inserir',
      };

      const checkvalor = {
        cdChecklist: '',
        valor: '',
        checklistCampos: checkcampo,
        placa: novaplaca,
        manutencaoChecklistFrotaVeiculo: placas.find(
          (f) => f.nrSeqVeiculo === ocorrencia.nrSeqVeiculo
        ),
        status: 'Inserir',
      };

      ocorrenciaS.push(checkvalor);
    });

    if (gridViewchecklistOcorrencias && gridViewchecklistOcorrencias.current) {
      gridViewchecklistOcorrencias.current.setDataSource([...ocorrenciaS]);
    }

    return setlistchecklistOcorrencias(ocorrenciaS);
  };

  const onClickChecklistFrota = () => {
    const datasource = gridViewChecklistFrotaRetornos.current
      ? gridViewChecklistFrotaRetornos.current.getDataSource()
      : [];

    datasource.forEach((item) => {
      item.checklistValores.forEach((checklistvalor) => {
        if (checklistvalor.valor.toUpperCase() === 'SIM') {
          item.checklistValor = checklistvalor;
          item.nrSeqChecklistValor = checklistvalor.nrSeqChecklistValor;
        }
        if (checklistvalor.valor.toUpperCase() === 'OK') {
          item.checklistValor = checklistvalor;
          item.nrSeqChecklistValor = checklistvalor.nrSeqChecklistValor;
        }
        if (checklistvalor.valor.toUpperCase() === 'CONFORME') {
          item.checklistValor = checklistvalor;
          item.nrSeqChecklistValor = checklistvalor.nrSeqChecklistValor;
        }
      });
    });

    setListChecklistRetornos(datasource);
    if (
      gridViewChecklistFrotaRetornos &&
      gridViewChecklistFrotaRetornos.current
    ) {
      gridViewChecklistFrotaRetornos.current.setDataSource(datasource);
    }
  };

  const onImportaDocumento = async () => {
    setModalimportaDocumento({ importaDocumento: true });
  };
  const onCloseModalImportaDocumento = async (dataSource) => {
    setModalimportaDocumento({
      show: false,
    });
    const documentoS = gridViewChecklistDocumentos.current
      ? gridViewChecklistDocumentos.current.getDataSource()
      : [];

    documentoS.push(...dataSource);

    setManutencaochecklistFrotaDocumentoS(documentoS);
    if (gridViewChecklistDocumentos && gridViewChecklistDocumentos.current) {
      gridViewChecklistDocumentos.current.setDataSource([...documentoS]);
    }
  };
  const closeModalExcluirOcorrencia = () => {
    setModalExcluirOcorrencia({ show: false });
  };
  const onCancelar = async () => {
    setModalExcluirOcorrencia({ show: false });
    setLoading(true);

    setLoading(false);
  };
  const onConfirme = async (dataSource) => {
    setModalExcluirOcorrencia({ show: false });
    const gridItem = modalExcluirOcorrencia.itemClicado;

    const OcorrenciaS = gridViewchecklistOcorrencias.current
      ? gridViewchecklistOcorrencias.current.getDataSource()
      : [];

    const updatedList = OcorrenciaS.map((item) => {
      if (item === gridItem) {
        return { ...item, valor: dataSource.valor };
      }
      return item;
    });

    setlistchecklistOcorrencias(updatedList);
    if (gridViewchecklistOcorrencias && gridViewchecklistOcorrencias.current) {
      gridViewchecklistOcorrencias.current.setDataSource([...updatedList]);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de ChecklistFrota'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      onDelete={data?.nrSeqManutencaoChecklist > 0 ? onExcluir : null}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {data.nrSeqManutencaoChecklist > 0 &&
        data.nrSeqManutencaoVeiculoOS == null && (
          <ToolbarButtons>
            <ToolbarButtons.Button text='Gerar OS' onClick={onGerarOs} />
          </ToolbarButtons>
        )}

      <div className='row'>
        <div className='col-1 mb-3'>
          <Textbox
            readOnly
            label='Codigo:'
            text={data.cdManutencaoChecklist}
            mask={MaskTypes.Integer}
            onChangedValue={(cdManutencaoChecklist) =>
              setData({ ...data, cdManutencaoChecklist })
            }
          />
        </div>

        <div className='col-1 mb-3'>
          <Textbox
            readOnly
            label='Codigo OS:'
            text={data.cdMManutencaoVeiculoOS}
            mask={MaskTypes.Integer}
            onChangedValue={(cdMManutencaoVeiculoOS) =>
              setData({ ...data, cdMManutencaoVeiculoOS })
            }
          />
        </div>
        <div className='col-3 mb-3'>
          <DatePicker
            readOnly={!!data.nrSeqManutencaoChecklist}
            label='Dt Lançamento:'
            text={data.dtExecucao}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtExecucao) => {
              setData({ ...data, dtExecucao });
            }}
          />
        </div>
        <div className='col-3 mb-3'>
          <DatePicker
            readOnly={!!data.nrSeqManutencaoChecklist}
            label='Dt Última Alteração:'
            text={data.dtFechamento}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtFechamento) => {
              setData({ ...data, dtFechamento });
            }}
          />
        </div>
        <div className='col-3 mb-3'>
          <DatePicker
            label='Dt CheckList:'
            text={data.dtChecklist}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtChecklist) => {
              setData({ ...data, dtChecklist });
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-3 mb-3'>
          <DatePicker
            label='Dt Previsão Saida:'
            text={data.dtSaidaChecklist}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtSaidaChecklist) => {
              setData({ ...data, dtSaidaChecklist });
            }}
          />
        </div>

        <div className='col-1 mb-3'>
          <Textbox
            maxLength={8}
            label='Hora Saida'
            text={data.horaSaidaChecklist}
            mask={MaskTypes.TimeHM}
            onChangedValue={(horaSaidaChecklist) =>
              setData({ ...data, horaSaidaChecklist })
            }
          />
        </div>
        <div className='col-3 mb-3'>
          <DatePicker
            label='Dt Conclusão:'
            text={data.dtConclusaoPreenchimento}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtConclusaoPreenchimento) => {
              setData({ ...data, dtConclusaoPreenchimento });
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-3 mb-3'>
          <Autocomplete
            label='Placa'
            searchDataSource={onSearchVeiculo}
            selectedItem={manutencaoChecklistFrotaVeiculo.veiculo}
            onSelectItem={(veiculo) => {
              setManutencaoChecklistFrotaVeiculo({
                ...manutencaoChecklistFrotaVeiculo,
                veiculo,
                nrSeqVeiculo: veiculo.nrSeqVeiculo,
              });
            }}
            dataSourceTextProperty='placa'
          />
        </div>
        <div className='col-2 mb-3'>
          <Textbox
            readOnly
            mask={MaskTypes.Integer}
            label='Ultimo Odometro'
            text={data.ultimoOdometro}
            onChangedValue={(ultimoOdometro) =>
              setData({ ...data, ultimoOdometro })
            }
          />
        </div>
        <div className='col-2 mb-3'>
          <Textbox
            required
            mask={MaskTypes.Integer}
            label='Odometro'
            text={manutencaoChecklistFrotaVeiculo.nrOdometro}
            onChangedValue={(nrOdometro) =>
              setManutencaoChecklistFrotaVeiculo({
                ...manutencaoChecklistFrotaVeiculo,
                nrOdometro,
              })
            }
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Busca Veiculo Vinculado'
            checked={data.flgBuscaVeiculoVinculado}
            onChange={(flgBuscaVeiculoVinculado) =>
              setData({ ...data, flgBuscaVeiculoVinculado })
            }
          />
        </div>
        <div className='col mt-2 d-flex align-items-center'>
          <Button
            onClick={onClickAddManutencaoChecklistFrotaVeiculo}
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Adicionar'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <h6 style={{ marginLeft: '5px' }} className='row'>
            Veículos Adicionados
          </h6>
          <GridView
            ref={gridViewManutencaoChecklistFrotaVeiculo}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columnsManutencaoChecklistFrotaVeiculo}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-4 mb-3'>
          <Autocomplete
            label='Motorista'
            required
            searchDataSource={onSearchPessoaMot}
            selectedItem={data.motorista}
            onSelectItem={(motorista) => {
              setData({
                ...data,
                motorista,
                nrSeqPessoaMot: motorista.nrSeqPessoaMot,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-4 mb-3'>
          <Autocomplete
            label='Checklist'
            required
            enabled={!data.nrSeqChecklistFrota > 0}
            searchDataSource={onSearchChecklistFrota}
            selectedItem={data.checklistFrota}
            onSelectItem={(checklistFrota) => {
              setData({
                ...data,
                checklistFrota,
                nrSeqChecklistFrota: checklistFrota.nrSeqChecklistFrota,
              });
            }}
            dataSourceTextProperty='noChecklistFrota'
          />
        </div>
        <div className='col mt-2 d-flex align-items-center'>
          <Button
            enabled={!data.data?.nrSeqManutencaoChecklist > 0}
            readOnly={!data.data?.nrSeqManutencaoChecklist > 0}
            disabledTabIndex={!data.data?.nrSeqManutencaoChecklist > 0}
            onClick={onClickChecklistFrota}
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Marcar Todos'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <h6 style={{ marginLeft: '5px' }} className='row'>
            Veículos Adicionados
          </h6>
          <GridView
            ref={gridViewManutencaoPreventiva}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columnsManutencaoPreventiva}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <h6 style={{ marginLeft: '5px' }} className='row'>
            Checklist
          </h6>
          <GridView
            ref={gridViewChecklistFrotaRetornos}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columnsChecklistFrotaRetornos}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-2'>
          <DropdownList
            label='Veiculo da Ocorrência'
            dataSource={listVeiculoOcorrencia}
            dataSourcePropertyText='placa'
            dataSourcePropertyValue='nrSeqVeiculo'
            selectedItems={veiculoOcorrencia.veiculoS ?? []}
            onSelectItem={(item) => {
              setVeiculoOcorrencia({
                ...veiculoOcorrencia,
                veiculoS: item,
                nrSeqVeiculo: item.nrSeqVeiculo,
              });
            }}
          />
        </div>
        <div className='col-2'>
          <DropdownList
            label='Grupo de Ocorrências'
            dataSource={listGrupoOcorrencia}
            dataSourcePropertyText='noGrupo'
            dataSourcePropertyValue='nrSeqChecklistGrupo'
            selectedItems={veiculoOcorrencia.checklistGrupoS ?? []}
            onSelectItem={(item) => {
              setVeiculoOcorrencia({
                ...veiculoOcorrencia,
                checklistGrupoS: item,
                nrSeqChecklistGrupo: item.nrSeqChecklistGrupo,
              });
            }}
          />
        </div>
        <div className='col-3 mb-3'>
          <Textbox
            label='Descrição:'
            text={veiculoOcorrencia.nomeNovaOcorrencia}
            maxLength={100}
            onChangedValue={(nomeNovaOcorrencia) =>
              setVeiculoOcorrencia({ ...veiculoOcorrencia, nomeNovaOcorrencia })
            }
          />
        </div>
        <div className='col-2 mt-2 d-flex align-items-center'>
          <Button
            onClick={onClickAddOcorrenciaVeiculo}
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Adicionar'
          />
        </div>
        <div className='col-2 mt-2 d-flex align-items-center'>
          <Button
            onClick={onClickGerarOcorrenciaVeiculo}
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Gerar Ocorrencias'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <h6 style={{ marginLeft: '5px' }} className='row'>
            ocorrencias
          </h6>
          <GridView
            ref={gridViewchecklistOcorrencias}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columnsChecklistValores}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Textarea
            maxLength={300}
            label='Observações'
            text={data.obs}
            onChangedValue={(obs) => setData({ ...data, obs })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-4'>
          <Textbox
            label='Número do Documento'
            mask={MaskTypes.Integer}
            text={manutencaochecklistFrotaDocumento.nrDocumento}
            onChangedValue={(nrDocumento) =>
              setManutencaochecklistFrotaDocumento({
                ...manutencaochecklistFrotaDocumento,
                nrDocumento,
              })
            }
          />
        </div>
        <div className='col-4'>
          <Textbox
            label='Nome Documento'
            text={manutencaochecklistFrotaDocumento.noDocumento}
            onChangedValue={(noDocumento) =>
              setManutencaochecklistFrotaDocumento({
                ...manutencaochecklistFrotaDocumento,
                noDocumento,
              })
            }
          />
        </div>
        <div className='col-2 mt-3'>
          <Button
            className='m-1'
            icon='cloud-upload-alt'
            tooltip='Importar'
            tooltipDirection='bottom'
            onClick={onImportaDocumento}
            theme={Theme.Warning}
            template={Button.Templates.Default}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalImportDocumento
            show={modalimportaDocumento.importaDocumento}
            ref={modalImportDocumentoRef}
            onClose={(dataSource) => {
              onCloseModalImportaDocumento(dataSource);
            }}
            manutencaochecklistFrotaDocumentoS={
              manutencaochecklistFrotaDocumentoS
            }
            setManutencaochecklistFrotaDocumentoS={
              setManutencaochecklistFrotaDocumentoS
            }
            manutencaochecklistFrotaDocumento={
              manutencaochecklistFrotaDocumento
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <h6 style={{ marginLeft: '5px' }} className='row'>
            Documentos
          </h6>
          <GridView
            ref={gridViewChecklistDocumentos}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columnsChecklistDocumentos}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
      <ModalExcluirOcorrencia
        show={modalExcluirOcorrencia.show}
        onCancelar={(item) => onCancelar(item)}
        onClose={closeModalExcluirOcorrencia}
        onConfirme={(dataSource) => {
          onConfirme(dataSource);
        }}
      />
    </CSDManutPage>
  );
}
