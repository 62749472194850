import { TipoChamada } from 'core/models/TEL';

class ContaRecursoTipoChamada {
  constructor(jsonObject = {}) {
    this.nrSeqContaRectTipoChamada = jsonObject.nrSeqContaRectTipoChamada;
    this.nrSeqContaRecurso = jsonObject.nrSeqContaRecurso;
    this.nrLinha = jsonObject.nrLinha;
    this.nrSeqTipoChamada = jsonObject.nrSeqTipoChamada;
    this.quantidadeTotal = jsonObject.quantidadeTotal;
    this.valorTotal = jsonObject.valorTotal;
    this.quantidadeZero = jsonObject.quantidadeZero;
    this.quantidadeNegativo = jsonObject.quantidadeNegativo;
    this.valorNegativo = jsonObject.valorNegativo;
    this.vlrRecalculado = jsonObject.vlrRecalculado;
    this.vlrRateio = jsonObject.vlrRateio;
    this.tipoChamada = new TipoChamada(jsonObject.tipoChamada ?? {});
  }

  toJson = () => JSON.stringify(this);
}
export default ContaRecursoTipoChamada;
