import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  JustifyContent,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Switch,
  Textbox,
  Panel,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getCustoFixo,
  saveCustoFixo,
  deleteCustoFixo,
} from 'core/services/FIN/custoFixo';

import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getFornecedorList } from 'core/services/FIN/fornecedor';
import { getTituloPagarTipoDocumentoAutoComplete } from 'core/services/FIN/tituloPagarTipoDocumento';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getTipoDespesaAutoComplete } from 'core/services/FIN/tipoDespesa';
import { getFormaLancamentoEletronicoAutoComplete } from 'core/services/FIN/formaLancamentoEletronico';
import { getTipoMovimentoAutoComplete } from 'core/services/FIN/tipoMovimento';
import { getInstrucaoMovimentoAutoComplete } from 'core/services/FIN/instrucaoMovimento';
import { getTipoServicoBancarioAutoComplete } from 'core/services/FIN/tipoServicoBancario';
import { getTipoMovimentoRemessaAutoComplete } from 'core/services/FIN/tipoMovimentoRemessa';
import { getPessoaAutoComplete } from 'core/services/SEG';
import { getTipoDespesaPlanoDeContaTituloPagar } from 'core/services/FIN/tipoDespesa_PlanoDeConta';
import { getMultipag, getUsuario } from 'core/services/FIN/tituloPagar';

export default function CustoFixoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const obj = await getCustoFixo(registryKey);

      if (obj.planoDeConta !== null || obj.planoDeConta !== undefined) {
        obj.planoDeConta.noPlanoConta =
          obj.planoDeConta.planoDeContaCre.cdCaminhoCompleto;
      }

      setData(obj);
      setLoading(false);
    } else {
      const usuario = await getUsuario(1);
      setData({
        empresa: usuario.empresa,
        nrSeqEmpresa: usuario.empresa.nrSeqEmpresa,
        tituloPagarTipoDocumento: usuario.tituloPagarTipoDocumento,
        nrSeqTituloPagarTipoDocumento:
          usuario.tituloPagarTipoDocumento.nrSeqTituloPagarTipoDocumento,
        geraSequencia: false,
        vlrCusto: 0,
        flgAtivo: true,
      });
    }
    setMessage(null);
  }, [registryKey]);

  const multipag = async (e) => {
    if (data.formaPagamento?.flgGrupo === 'E') {
      e = data;
      const obj = await getMultipag({
        ...data,
      });
      setData({
        ...data,
        tipoServico: obj.tipoServico,
        nrSeqTipoServicoBancario: obj.nrSeqTipoServicoBancario,
        tipoMovimento: obj.tipoMovimento,
        nrSeqTipoMovimento: obj.nrSeqTipoMovimento,
        formaLancamentoEletronico: obj.formaLancamentoEletronico,
        nrSeqFormaLancamentoEletronico: obj.nrSeqFormaLancamentoEletronico,
        instrucaoMovimento: obj.instrucaoMovimento,
        nrSeqInstrucaoMovimento: obj.nrSeqInstrucaoMovimento,
        formaPagamento: data.formaPagamento,
        nrSeqFormaPagamento: data.formaPagamento.nrSeqFormaPagamento,
        movimentoRemessa: obj.movimentoRemessa,
        nrSeqTipoMovimentoRemessa: obj.nrSeqTipoMovimentoRemessa,
      });

      return obj;
    }
    setData({
      ...e,
      formaPagamento: data.formaPagamento,
      nrSeqFormaPagamento: data.formaPagamento.nrSeqFormaPagamento,
    });
    return true;
  };

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveCustoFixo(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteCustoFixo(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };
  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onSearchPessoaFor = async (e) => {
    const { status, message: msg, pessoaFors } = await getFornecedorList({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return pessoaFors;
  };

  const onSearchTituloPagarTipoDocumento = async (e) => {
    const {
      status,
      message: msg,
      data: tituloPagarTipoDocumentos,
    } = await getTituloPagarTipoDocumentoAutoComplete({
      noTituloPagarTipoDocumento: e,
    });
    if (msg) onSetMessage(status, msg);
    return tituloPagarTipoDocumentos;
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({ noFormaPagamento: e });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const onSearchTipoDespesa = async (e) => {
    const {
      status,
      message: msg,
      tipoDespesas,
    } = await getTipoDespesaAutoComplete({ noTipoDespesa: e });
    if (msg) onSetMessage(status, msg);
    return tipoDespesas;
  };

  const onSearchPlanoDeConta = async (e) => {
    if (data.nrSeqTipoDespesa === null || data.nrSeqTipoDespesa === undefined) {
      onSetMessage(
        ResponseStatus.Error,
        'Selecione um tipo de despesa antes de buscar o plano de conta'
      );
      return false;
    }

    const {
      status,
      message: msg,
      data: planoconta,
    } = await getTipoDespesaPlanoDeContaTituloPagar({
      noPlanoDeConta: e,
      nrSeqTipoDespesa: data.nrSeqTipoDespesa,
    });
    if (msg) onSetMessage(status, msg);

    if (planoconta !== null || planoconta !== undefined) {
      planoconta.forEach((item) => {
        item.noPlanoConta = item.planoDeContaCre.cdCaminhoCompleto;
        item.nrSeqPlanoDeConta = item.nrSeqPlanoDeContaCred;
      });
    }
    return planoconta;
  };
  const onSearchTipoDeServico = async (e) => {
    const {
      status,
      message: msg,
      data: tipodeservico,
    } = await getTipoServicoBancarioAutoComplete({
      noTipoServicoBancario: e,
    });
    if (msg) onSetMessage(status, msg);
    return tipodeservico;
  };
  const onSearchFormaDeLancamento = async (e) => {
    const {
      status,
      message: msg,
      data: formaDeLancamento,
    } = await getFormaLancamentoEletronicoAutoComplete({
      noFormaLancamentoEletronico: e,
    });
    if (msg) onSetMessage(status, msg);
    return formaDeLancamento;
  };
  const onSearchTipoDeMovimento = async (e) => {
    const {
      status,
      message: msg,
      data: tipoDeMovimento,
    } = await getTipoMovimentoAutoComplete({
      noTipoMovimento: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return tipoDeMovimento;
  };
  const onSearchInstrucaoDeMovimento = async (e) => {
    const {
      status,
      message: msg,
      data: instrucaoDeMovimento,
    } = await getInstrucaoMovimentoAutoComplete({ noInstrucaoMovimento: e });
    if (msg) onSetMessage(status, msg);
    return instrucaoDeMovimento;
  };
  const onSearchMovimentoRemessa = async (e) => {
    const {
      status,
      message: msg,
      data: TipoMovimentoRemessa,
    } = await getTipoMovimentoRemessaAutoComplete({
      noTipoMovimentoRemessa: e,
    });
    if (msg) onSetMessage(status, msg);
    return TipoMovimentoRemessa;
  };
  const onSearchSacadorAvalista = async (e) => {
    const { status, message: msg, data: Pessoa } = await getPessoaAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return Pessoa;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Custo Fixo'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({ vlrCusto: 0 })}
      onSave={save}
      onDelete={data?.nrSeqCustoFixo > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-5 mb-3'>
          <Autocomplete
            required
            label='Empresa'
            searchDataSource={onSearchEmpresa}
            selectedItem={data.empresa}
            onSelectItem={(empresa) => {
              setData({
                ...data,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-3 mb-3'>
          <Autocomplete
            required
            label='Tipo'
            searchDataSource={onSearchTituloPagarTipoDocumento}
            selectedItem={data.tituloPagarTipoDocumento}
            onSelectItem={(tituloPagarTipoDocumento) => {
              setData({
                ...data,
                tituloPagarTipoDocumento,
                nrSeqTituloPagarTipoDocumento:
                  tituloPagarTipoDocumento.nrSeqTituloPagarTipoDocumento,
              });
            }}
            dataSourceTextProperty='noTituloPagarTipoDocumento'
          />
        </div>
        <div className='col-4 mb-3'>
          <Textbox
            label='Nr. Custo'
            readOnly
            text={data.cdCustoFixo}
            onChangedValue={(cdCustoFixo) => setData({ ...data, cdCustoFixo })}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-8 mb-3'>
          <Autocomplete
            required
            label='Fornecedor'
            searchDataSource={onSearchPessoaFor}
            selectedItem={data.fornecedor}
            onSelectItem={(fornecedor) => {
              setData({
                ...data,
                fornecedor,
                nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Ativo'
            checked={data.flgAtivo}
            onChange={(flgAtivo) => setData({ ...data, flgAtivo })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-4 mb-3'>
          <Textbox
            required
            maxLength={20}
            label='Nr. Documento'
            text={data.nrTit}
            onChangedValue={(nrTit) => setData({ ...data, nrTit })}
          />
        </div>
        <div className='col-4 mb-3'>
          <Textbox
            required
            label='Dia Vencimento'
            text={data.diaVencimento}
            mask={MaskTypes.Integer}
            onChangedValue={(diaVencimento) =>
              setData({ ...data, diaVencimento })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-6 mb-3'>
          <Autocomplete
            required
            label='Tipo Despesa'
            searchDataSource={onSearchTipoDespesa}
            selectedItem={data.tipoDespesa}
            onSelectItem={(tipoDespesa) => {
              setData({
                ...data,
                tipoDespesa,
                nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
              });
            }}
            dataSourceTextProperty='noTipoDespesa'
          />
        </div>
        <div className='col-6 mb-3'>
          <Autocomplete
            required
            label='Forma Pagamento'
            searchDataSource={onSearchFormaPagamento}
            selectedItem={data.formaPagamento}
            onSelectItem={(formaPagamento) => {
              data.formaPagamento = formaPagamento;
              data.nrSeqFormaPagamento = formaPagamento.nrSeqFormaPagamento;
              multipag(data);
            }}
            dataSourceTextProperty='noFormaPagamento'
          />
        </div>
      </div>
      {data.formaPagamento?.flgGrupo === 'E' && (
        <Panel className='row mb-3'>
          <Panel.Header
            icon={['fas', 'file']}
            title='Dados Complementares para pagamento eletrônico'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />

          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-3 mb-3'>
                <Autocomplete
                  label='Tipo de serviço'
                  searchDataSource={onSearchTipoDeServico}
                  selectedItem={data.tipoServico}
                  onSelectItem={(tipoServico) => {
                    setData({
                      ...data,
                      tipoServico,
                      nrSeqTipoServicoBancario:
                        tipoServico.nrSeqTipoServicoBancario,
                    });
                  }}
                  dataSourceTextProperty='noTipoServicoBancario'
                />
              </div>
              <div className='col-3 mb-3'>
                <Autocomplete
                  label='Forma de lançamento'
                  searchDataSource={onSearchFormaDeLancamento}
                  selectedItem={data.formaLancamentoEletronico}
                  onSelectItem={(formaLancamentoEletronico) => {
                    setData({
                      ...data,
                      formaLancamentoEletronico,
                      nrSeqFormaLancamentoEletronico:
                        formaLancamentoEletronico.nrSeqFormaLancamentoEletronico,
                    });
                  }}
                  dataSourceTextProperty='noFormaLancamentoEletronico'
                />
              </div>
              <div className='col-3 mb-3'>
                <Autocomplete
                  label='Tipo de movimento'
                  searchDataSource={onSearchTipoDeMovimento}
                  selectedItem={data.tipoMovimento}
                  onSelectItem={(tipoMovimento) => {
                    setData({
                      ...data,
                      tipoMovimento,
                      nrSeqTipoMovimento: tipoMovimento.nrSeqTipoMovimento,
                    });
                  }}
                  dataSourceTextProperty='noTipoMovimento'
                />
              </div>
              <div className='col-3 mb-3'>
                <Autocomplete
                  label='Instrução de movimento'
                  searchDataSource={onSearchInstrucaoDeMovimento}
                  selectedItem={data.instrucaoMovimento}
                  onSelectItem={(instrucaoMovimento) => {
                    setData({
                      ...data,
                      instrucaoMovimento,
                      nrSeqInstrucaoMovimento:
                        instrucaoMovimento.nrSeqInstrucaoMovimento,
                    });
                  }}
                  dataSourceTextProperty='noInstrucaoMovimento'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-3 mb-3'>
                <Autocomplete
                  label='Movimento Remessa'
                  searchDataSource={onSearchMovimentoRemessa}
                  selectedItem={data.movimentoRemessa}
                  onSelectItem={(movimentoRemessa) => {
                    setData({
                      ...data,
                      movimentoRemessa,
                      nrSeqTipoMovimentoRemessa:
                        movimentoRemessa.nrSeqTipoMovimentoRemessa,
                    });
                  }}
                  dataSourceTextProperty='noTipoMovimentoRemessa'
                />
              </div>
              <div className='col-3 mb-3'>
                <Autocomplete
                  label='Sacador Avalista'
                  searchDataSource={onSearchSacadorAvalista}
                  selectedItem={data.pessoaSacador}
                  onSelectItem={(pessoaSacador) => {
                    setData({
                      ...data,
                      pessoaSacador,
                      nrSeqPessoaSacador: pessoaSacador.nrSeqPessoa,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      )}

      <div className='row'>
        <div className='col-4 mb-3'>
          <Textbox
            label='Valor Titulo'
            text={data.vlrCusto}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(vlrCusto) => setData({ ...data, vlrCusto })}
          />
        </div>
        <div className='col-6 mb-3'>
          <Autocomplete
            label='Plano De Conta'
            searchDataSource={onSearchPlanoDeConta}
            selectedItem={data.planoDeConta}
            onSelectItem={(planoDeConta) => {
              setData({
                ...data,
                planoDeConta,
                nrSeqPlanoDeConta: planoDeConta.nrSeqPlanoDeConta,
              });
            }}
            dataSourceTextProperty='noPlanoConta'
          />
        </div>
      </div>

      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={200}
            label='Obs'
            text={data.noDetalhe}
            onChangedValue={(noDetalhe) => setData({ ...data, noDetalhe })}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
