import React, { useEffect } from 'react';
import { Button, FontAwesomeIcon } from 'ui/components';
import { MimeTypes } from 'ui/Helpers/utils';

function preventDefaults(e) {
  e.preventDefault();
  e.stopPropagation();
}

const Single = React.forwardRef((props, ref) => {
  const { files, onChange, getIconByFileType, allowedMimeTypes = [] } = props;

  const handleDrop = (e) => {
    preventDefaults(e);

    const validFiles =
      allowedMimeTypes.length === 0
        ? e.dataTransfer.files
        : MimeTypes.getValidFiles([...e.dataTransfer.files], allowedMimeTypes);

    onChange(validFiles.length > 1 ? validFiles.slice(0, 1) : validFiles);
  };

  const btnRemoveClass = ['single-remove'];
  if (files.length > 0) btnRemoveClass.push('active');
  else btnRemoveClass.push('inactive');

  useEffect(() => {
    if (ref.current) {
      if (!ref.current.onchange) {
        ref.current.onchange = (e) => {
          e.preventDefault();
          e.stopPropagation();

          onChange(e.target.files);
        };
      }
    }
  }, [ref.current]);

  const text = files[0]?.name
    ? files[0]?.name
    : 'Clique para importar ou arraste um arquivo para cá...';

  return (
    <div className='file-upload file-upload-single'>
      <div className='input-group'>
        <div
          role='presentation'
          className='form-control'
          onClick={() => ref.current.click()}
          onDragEnter={preventDefaults}
          onDragOver={preventDefaults}
          onDragLeave={preventDefaults}
          onDrop={handleDrop}
        >
          {files[0] &&
            React.createElement(FontAwesomeIcon, {
              ...getIconByFileType(files[0].type),
            })}
          <span>
            {text}
            {files[0] && (
              <span className='file-size'>({files[0].size} KB)</span>
            )}
          </span>
        </div>
        <Button
          className={btnRemoveClass.join(' ')}
          icon='trash-alt'
          onClick={() => {
            onChange([]);
            ref.current.value = [];
          }}
        />
        <Button
          icon='cloud-upload-alt'
          text='Selecionar'
          theme='primary'
          onClick={() => {
            ref.current.click();
          }}
        />
      </div>
    </div>
  );
});

export default Single;
