import Oficina from 'core/models/FRO/oficina';
import Nf from 'core/models/COP/nf';
import VeiculoManutencaoPreventiva from 'core/models/FRO/veiculo_ManutencaoPreventiva';
import ManutencaoVeiculoPlaca from './manutencaoVeiculoPlaca';
import ManutencaoVeiculoProduto from './manutencaoVeiculo_Produto';
import Pessoa from '../SEG/pessoa';
import ManutencaoVeiculoOS from './manutencaoVeiculoOS';
import ManutencaoVeiculoFatura from './manutencaoVeiculo_Fatura';
import { Usuario } from '../SEG';

export default class ManutencaoVeiculo {
  constructor(jsonObject = {}) {
    this.nrSeqManutencaoVeiculo = jsonObject.nrSeqManutencaoVeiculo;
    this.nrDocumento = jsonObject.nrDocumento;
    this.nrSeqPessoaOfi = jsonObject.nrSeqPessoaOfi;
    this.dtDocumento = jsonObject.dtDocumento;
    this.dtCad = jsonObject.dtCad;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.nrQuilometragem = jsonObject.nrQuilometragem;
    this.nrOdometro = jsonObject.nrOdometro;
    this.vlrTotal = jsonObject.vlrTotal;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.cdManutencaoVeiculo = jsonObject.cdManutencaoVeiculo;
    this.nrSeqViagem = jsonObject.nrSeqViagem;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.nrSeqUsuarioAlt = jsonObject.nrSeqUsuarioAlt;
    this.dtInicio = jsonObject.dtInicio;
    this.dtFim = jsonObject.dtFim;
    this.dtContab = jsonObject.dtContab;
    this.nrSeqAcertoMotorista = jsonObject.nrSeqAcertoMotorista;
    this.nrSeqNf = jsonObject.nrSeqNf;
    this.noObs = jsonObject.noObs;

    // Propriedades auxiliares
    this.noPlacasConcat = jsonObject.noPlacasConcat;
    this.usuario = new Usuario(jsonObject.usuario ?? {});
    this.oficina = new Oficina(jsonObject.oficina ?? {});
    this.placas =
      jsonObject.placas?.map((json) => new ManutencaoVeiculoPlaca(json)) ?? [];
    this.nf = new Nf(jsonObject.nf ?? {});
    this.manutencaoVeiculoFaturas =
      jsonObject.manutencaoVeiculoFaturas?.map(
        (json) => new ManutencaoVeiculoFatura(json)
      ) ?? [];
    this.produtos =
      jsonObject.produtos?.map((json) => new ManutencaoVeiculoProduto(json)) ??
      [];
    this.empresa = new Pessoa(jsonObject.empresa ?? {});
    this.manutencaoVeiculoOS = new ManutencaoVeiculoOS(
      jsonObject.manutencaoVeiculoOS ?? []
    );
    this.veiculoManutencaoPreventivas =
      jsonObject.veiculoManutencaoPreventivas?.map(
        (json) => new VeiculoManutencaoPreventiva(json)
      ) ?? [];
  }

  toJson = () => JSON.stringify(this);
}
