import React, { useState, useEffect } from 'react';

import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import {
  Button,
  Modal,
  Notification,
  RadioButton,
  Textarea,
} from 'ui/components';

const motivoButtonsInfisc = [
  { text: 'Erro na Emissão', value: 1 },
  { text: 'Serviço não Prestado', value: 2 },
];

const motivoButtonsOutros = [
  { text: 'Erro na Emissão', value: 1 },
  { text: 'Serviço não Prestado', value: 2 },
  { text: 'Outros', value: 9 },
];

const PatternsNFSe = {
  ABRASAF: 1,
  SOFTPLAN: 2,
  SEFINNACIONAL: 3,
  ATENDENET: 4,
  INFISC: 5,
};

export default function ModalMotivoCancelNotaFiscal({
  onClose,
  show,
  onCancelar,
  nfse,
}) {
  const [message, setMessage] = useState(null);
  const [data, setData] = useState({
    cdMotivoCancelamento: 'Serviço não Prestado',
  });

  useEffect(() => {
    setData(nfse);
  }, [nfse]);

  return (
    <Modal
      show={show}
      title='Motivo do Cancelamento da Nota Serviço'
      onClose={onClose}
      size={BootstrapSizes.Medium}
    >
      <Modal.Body className='p-4 pt-3'>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='col'>
          <RadioButton
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={data?.cdMotivoCancelamento}
            onChange={(cdMotivoCancelamento) => {
              setData({ ...data, cdMotivoCancelamento });
            }}
            buttons={
              data?.tipoIntegracao === PatternsNFSe.INFISC
                ? motivoButtonsInfisc
                : motivoButtonsOutros
            }
          />
        </div>
        {data?.tipoIntegracao !== PatternsNFSe.INFISC && (
          <div className='row mb-3'>
            <div className='col-12'>
              <Textarea
                label='Motivo'
                onChangedValue={(noMotivoCancelamento) =>
                  setData({ ...data, noMotivoCancelamento })
                }
              />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Ok'
          theme={Theme.Success}
          onClick={() => {
            if (data.cdMotivoCancelamento === 0) {
              setMessage('Selecione o motivo do cancelamento!');
            } else {
              const obj = {
                ...nfse,
                cdMotivoCancelamento: data.cdMotivoCancelamento,
                noMotivoCancelamento: data.noMotivoCancelamento,
              };
              onCancelar(obj);
            }
          }}
        />
        <Button
          text='Sair'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
