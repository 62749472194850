import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { getDownloadContas, postGerarPDFContaBase64 } from 'core/services/TEL';
import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, GridView } from 'ui/components';
import { getGerarPdfBoletoByContaRecurso } from 'core/services/FIN';

export default function DownloadConta() {
  const gridView = useRef();
  const { idConta } = useParams();
  const [msgperiodo, setMsgPeriodo] = useState();
  const [cliente, setCliente] = useState();
  const [boleto, setBoleto] = useState();
  const [qrcode, setQrCode] = useState();
  const [emv, setEmv] = useState();
  const [icone, setIcone] = useState('copy');
  const [texto, setTexto] = useState('PIX Copia e Cola');
  const [corTexto, setCorTexto] = useState('black');

  const trocarCorTexto = () => {
    setCorTexto('green');
  };

  const trocarIcone = () => {
    setIcone('check');
  };

  const trocarTexto = () => {
    setTexto('Copiado!');
  };

  const onClickPrintConta = async (selectedValue) => {
    const nrSeqs = [];
    nrSeqs.push(selectedValue.nrSeqContaRecurso);
    const obj = {
      nrSeqContasRecurso: nrSeqs,
      total: selectedValue.nrLinhaCompleto,
    };
    const { value } = await postGerarPDFContaBase64(obj);

    if (value) {
      const linkSource = `data:application/pdf;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'Conta.pdf';
      downloadLink.click();
    }
  };

  const columns = [
    {
      key: 'nrSeqContaRecurso',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'nrLinhaCompleto', title: 'Linhas' },
    { key: 'periodo', title: 'Período' },
    {
      key: 'vlrRecalculado',
      title: 'Valor Linha',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'qtdeTotalVoz',
      title: 'Voz',
    },
    {
      key: 'nrLinhaDigitavel',
      title: 'Linha Digitável',
    },
    {
      key: 'vlrFatura',
      title: 'Valor da Fatura',
      format: GridView.DataTypes.Money,
    },
    { key: 'qtdeTotalDados', title: 'Dados' },
    { key: 'qtdeTotalSms', title: 'SMS' },
    { key: 'noStatusFinanceiro', title: 'Info Pgto', color: 'noColor' },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrSeqContaRecurso',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickPrintConta(e),
      theme: Theme.Primary,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir Conta Detalhada',
      title: 'Conta Detalhada',
      tooltipDirection: 'bottom',
      visibleDynamic: 'exibirConta',
    },
  ];

  useEffect(() => {
    async function load() {
      const { contaDetalhada } = await getDownloadContas({
        noDownloadConta: idConta,
      });
      if (contaDetalhada.length > 0) {
        setMsgPeriodo(`Período da conta ${contaDetalhada[0].periodo}`);
        setCliente(contaDetalhada[0].nome);
        setQrCode(contaDetalhada[contaDetalhada.length - 1].qrCode);
        setEmv(contaDetalhada[0].emv);
        if (gridView && gridView.current)
          gridView.current.setDataSource(contaDetalhada);

        const { value } = await getGerarPdfBoletoByContaRecurso(
          idConta,
          contaDetalhada[0].nrSeqContaRecurso
        );

        setBoleto(value);
      } else {
        setMsgPeriodo('Registro não localizado.');
      }
    }
    load();
  }, []);

  const downloadBoleto = () => {
    if (boleto) {
      const linkSource = `data:application/pdf;base64, ${boleto}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'Boleto.pdf';
      downloadLink.click();
    }
  };

  return (
    <div>
      <div className='row mb-3'>
        <div className='col-8 mt-4'>
          <h1 align='center'>{cliente}</h1>
          <h2 align='center'>{msgperiodo}</h2>
        </div>
        {qrcode && (
          <div className='col-2'>
            <div className='col-8'>
              <div className='row mb-2'>
                <div
                  className='col'
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <img
                    src={qrcode}
                    className='img-thumbnail'
                    alt='QR Code Boleto'
                  />
                </div>
              </div>
              <div
                className='col'
                style={{
                  textAlign: 'center',
                }}
              >
                {/* *trocar a cor do texto ao clicar no botão */}

                <Button
                  style={{
                    fontSize: '10px',
                    textAlign: 'center',
                    backgroundColor: 'white',
                    color: corTexto,
                  }}
                  theme={Theme.Primary}
                  text={texto}
                  icon={icone}
                  className='width50'
                  onClick={() => {
                    navigator.clipboard.writeText(emv);
                    trocarCorTexto();
                    trocarIcone();
                    trocarTexto();
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div className='col-2'>
          <div className='col-8 mt-3'>
            {boleto ? (
              <div
                className='col-2 mt-2 widget-square text-white p-4'
                style={{ textAlign: 'center' }}
              >
                <Button
                  theme={Theme.Success}
                  text='Boleto'
                  icon='download'
                  onClick={() => downloadBoleto()}
                />
              </div>
            ) : (
              <Button
                theme={Theme.Primary}
                text='Não há boletos'
                onClick={() => downloadBoleto()}
              />
            )}
          </div>
        </div>
      </div>
      <div>
        <div className='card mt-4'>
          <div className='card-body'>
            <div className='row'>
              <div className='col'>
                <GridView
                  ref={gridView}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns}
                  onPageSizeChange={false}
                  showSelectSizes={false}
                  showPagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
