import Pessoa from 'core/models/SEG/pessoa';
import Empresa from 'core/models/SEG/empresa';
import EstoqueTipo from 'core/models/EST/estoqueTipo';
import ManutencaoVeiculoProduto from 'core/models/FRO/manutencaoVeiculo_Produto';

export default class EstoqueMovimento {
  constructor(jsonObject = {}) {
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.nrSeqEstoqueMovimento = jsonObject.nrSeqEstoqueMovimento;
    this.nrSeqEstoqueTipo = jsonObject.nrSeqEstoqueTipo;
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.quantidade = jsonObject.quantidade;
    this.dtMovimento = jsonObject.dtMovimento;
    this.nrDoc = jsonObject.nrDoc;
    this.nrSerie = jsonObject.nrSerie;
    this.vlrUnit = jsonObject.vlrUnit;
    this.vlrTotal = jsonObject.vlrTotal;
    this.dtCad = jsonObject.dtCad;
    this.obsMov = jsonObject.obsMov;
    this.dtCancel = jsonObject.dtCancel;
    this.nrSeqAlmoxarifadoLocal = jsonObject.nrSeqAlmoxarifadoLocal;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqDocumento = jsonObject.nrSeqDocumento;
    this.pessoaFor = new Pessoa(jsonObject.pessoaFor ?? {});
    this.pessoaEmp = new Empresa(jsonObject.pessoaEmp ?? {});
    this.estoqueTipo = new EstoqueTipo(jsonObject.estoqueTipo ?? {});
    this.manutencaoVeiculoProduto = new ManutencaoVeiculoProduto(
      jsonObject.manutencaoVeiculoProduto ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
