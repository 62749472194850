import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  deleteChamado,
  downloadBase,
  getChamado,
  saveChamado,
} from 'core/services/HELP/chamado';
import {
  getEmpresaAutoComplete,
  getUsuarioAutoComplete,
} from 'core/services/SEG';
import { getClienteAutoComplete } from 'core/services/FIN';
import { getChamadoClassificacaoAutoComplete } from 'core/services/HELP/chamadoClassificacao';
import { getPrioridadeChamadoAutoComplete } from 'core/services/HELP/prioridadeChamado';
import { getSituacaoChamadoAutoComplete } from 'core/services/HELP/situacaoChamado';
import { Cliente } from 'core/models/FIN';
import Chamado from 'core/models/HELP/chamado';
import ChamadoClassificacao from 'core/models/HELP/chamadoClassificacao';
import ChamadoDocumento from 'core/models/HELP/chamadoDocumento';
import PrioridadeChamado from 'core/models/HELP/prioridadeChamado';
import SituacaoChamado from 'core/models/HELP/situacaoChamado';
import { Empresa, Usuario } from 'core/models/SEG';
import { ColumnDataTypes, ResponseStatus, Theme } from 'ui/Helpers/enums';
import { GridviewColumns, Message, Page } from 'ui/Helpers/interfaces';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  BootstrapSizes,
  MimeTypes,
  PageTypes,
  dateNow,
  toBase64,
} from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  CSDManutPage,
  DatePicker,
  DropdownList,
  FileUpload,
  GridView,
  Panel,
  Textarea,
  Textbox,
} from 'ui/components';
import AreaChamado from 'core/models/HELP/areaChamado';
import { getAreaChamadoAutoComplete } from 'core/services/HELP/areaChamado';
import TipoChamado from 'core/models/HELP/tipoChamado';
import { getTipoChamadoAutoComplete } from 'core/services/HELP/tipoChamado';

export default function ChamadoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit: any = useRef();
  const movimentacaoGridView: any = useRef();
  const documentoGridView: any = useRef();

  const [chamado, setChamado] = useState<Chamado | {}>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [prioridades, setPrioridades] = useState<Array<PrioridadeChamado>>([]);
  const [situacao, setSituacao] = useState<Array<SituacaoChamado>>([]);
  const [importedFiles, setImportedFiles] = useState<Array<File>>([]);
  const [movimentacao, setMovimentacao] = useState<string>('');

  const onSearchEmpresa = async (e: string): Promise<Array<Empresa>> => {
    const {
      empresas,
    }: { empresas: Array<Empresa> } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    return empresas;
  };

  const onSearchUsuario = async (e: string): Promise<Array<Usuario>> => {
    const { data }: { data: Array<Usuario> } = await getUsuarioAutoComplete({
      noLogin: e,
    });

    return data;
  };

  const onNew = async (): Promise<void> => {
    setImportedFiles([]);
    movimentacaoGridView.current?.setDataSource([]);
    documentoGridView.current?.setDataSource([]);

    const usuarios = await onSearchUsuario('');
    const empresas = await onSearchEmpresa('');

    const celebre = empresas.find((emp) =>
      String(emp.noPessoa).includes('CELEBRE')
    );

    const resp: Usuario = JSON.parse(
      String(sessionStorage.getItem('@dataAuth'))
    ).user;

    const user = usuarios.find((usu) => usu.nrSeqUsuario === resp.nrSeqUsuario);

    setChamado({
      empresa: celebre,
      nrSeqEmpresa: celebre?.nrSeqEmpresa,
      usuarioOp: user,
      nrSeqUsuarioOp: user?.nrSeqUsuario,
      dtAbertura: dateNow(),
    });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const obj: Chamado | {} = await getChamado(registryKey);

      setChamado(obj);

      if ('chamadoDocumento' in obj) {
        documentoGridView.current.setDataSource(obj.chamadoDocumento);
      }

      if ('chamadoMovimentacao' in obj) {
        movimentacaoGridView.current.setDataSource(obj.chamadoMovimentacao);
      }

      setLoading(false);
    } else {
      onNew();
    }
    setMessage(null);
  }, [registryKey]);

  const onAddDocumeto = async (): Promise<void> => {
    let noDocBase64 = await toBase64(importedFiles[0]);
    noDocBase64 = String(noDocBase64).substring(
      String(noDocBase64).indexOf(',') + 1
    );

    const obj = {
      noDocumento: importedFiles[0].name,
      noTipoDocumento: importedFiles[0].type,
      nrTamanhoEmBytes: importedFiles[0].size,
      blDocumento: noDocBase64,
    };

    const list = documentoGridView.current.getDataSource();

    if (list.length > 4) {
      setMessage({
        message: 'Limite de 5 documentos alcançado.',
        theme: Theme.Warning,
      });

      return;
    }

    // @ts-expect-error
    obj.status = GridView.EnumStatus.Inserir;

    list.push(obj);

    if (documentoGridView && documentoGridView.current) {
      documentoGridView.current.setDataSource(list);
    }
  };

  const onClickRemoveFromGridView = async (e: any, gridView: any) => {
    const list = gridView.current.getDataSource();

    list.filter((elemento: any) => elemento === e)[0].status =
      // @ts-expect-error
      GridView.EnumStatus.Remover;

    if (gridView && gridView.current) {
      gridView.current.setDataSource(list);
    }
  };

  const downloadDocument = (chamadoDocument: ChamadoDocumento): void => {
    const byteCharacters = atob(chamadoDocument.blDocumento);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = chamadoDocument.noDocumento;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const documentoColumns: Array<GridviewColumns> = [
    { key: 'noDocumento', title: 'Documentos' },
    {
      key: 'nrSeqChamadoDocumento',
      title: 'Download',
      // @ts-expect-error
      type: GridView.ColumnTypes.Button,
      onClick: (e: any) => downloadDocument(e),
      theme: Theme.Success,
      icon: 'sync-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqChamadoDocumento',
      title: 'Remover',
      // @ts-expect-error
      type: GridView.ColumnTypes.Button,
      onClick: (e: any) => onClickRemoveFromGridView(e, documentoGridView),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onAddMovimentacao = (): void => {
    const obj = {
      descricao: movimentacao,
      dtMovimentacao: dateNow(),
    };

    const list = movimentacaoGridView.current.getDataSource();

    // @ts-expect-error
    obj.status = GridView.EnumStatus.Inserir;

    list.unshift(obj);

    setMovimentacao('');

    if (movimentacaoGridView && movimentacaoGridView.current) {
      movimentacaoGridView.current.setDataSource(list);
    }
  };

  const movimentacaoColumns: Array<GridviewColumns> = [
    {
      key: 'dtMovimentacao',
      title: 'Data Movimentação',
      format: ColumnDataTypes.Date,
    },
    { key: 'descricao', title: 'Descrição' },
    {
      key: 'nrSeqChamadoMovimentacao',
      title: 'Remover',
      // @ts-expect-error
      type: GridView.ColumnTypes.Button,
      onClick: (e: any) => onClickRemoveFromGridView(e, movimentacaoGridView),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onSave = async (): Promise<void> => {
    if (!('situacaoChamado' in chamado)) {
      setMessage({
        message: 'É necessário selecionar o status do chamado.',
        theme: Theme.Warning,
      });

      return;
    }

    if (!('prioridadeChamado' in chamado)) {
      setMessage({
        message: 'É necessário selecionar a prioridade do chamado.',
        theme: Theme.Warning,
      });

      return;
    }

    if (chamado.situacaoChamado.noSituacaoChamado === 'Cancelado') {
      const list: [] = movimentacaoGridView.current.getDataSource();
      if (
        !list.find((x: any) => x.status === 'Inserir') &&
        !chamado.chamadoMovimentacao.find(
          (mov) => mov.nrSeqSituacaoChamado === 5
        )
      ) {
        setMessage({
          message:
            'É necessário adicionar uma movimentação justificando o cancelamento.',
          theme: Theme.Warning,
        });

        return;
      }
    }

    setLoading(true);

    chamado.chamadoDocumento = documentoGridView.current.getDataSource();

    const { status, message: msg } = await saveChamado(chamado);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      onNew();
    }

    setChamado(status === ResponseStatus.Success ? {} : chamado);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onDelete = async (): Promise<void> => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const { status, message: msg } = await deleteChamado(registryKey);

      setChamado(status === ResponseStatus.Success ? {} : chamado);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      setLoading(false);
    }
  };

  const onSearchAreaChamado = async (e: string): Promise<AreaChamado[]> => {
    const {
      areasChamado,
    }: { areasChamado: AreaChamado[] } = await getAreaChamadoAutoComplete({
      noAreaChamado: e,
    });

    return areasChamado;
  };

  const onSearchTipoChamado = async (e: string): Promise<TipoChamado[]> => {
    if ('areaChamado' in chamado) {
      const {
        tiposChamado,
      }: { tiposChamado: TipoChamado[] } = await getTipoChamadoAutoComplete({
        noTipoChamado: e,
        nrSeqAreaChamado: chamado.areaChamado.nrSeqAreaChamado,
      });

      return tiposChamado;
    }

    setMessage({
      message: 'É necessário selecionar uma area.',
      theme: Theme.Warning,
    });

    return [];
  };

  const onSearchCliente = async (e: string): Promise<Array<Cliente>> => {
    const {
      clientes,
    }: { clientes: Array<Cliente> } = await getClienteAutoComplete({
      noPessoa: e,
    });

    return clientes;
  };

  const onSearchPrioridadeChamado = async (): Promise<void> => {
    const {
      prioridadesChamados,
    }: {
      prioridadesChamados: Array<PrioridadeChamado>;
    } = await getPrioridadeChamadoAutoComplete();

    setPrioridades(prioridadesChamados);
  };

  const onSearchSituacaoChamado = async (e: string = ''): Promise<void> => {
    const {
      situacoesChamados,
    }: {
      situacoesChamados: Array<SituacaoChamado>;
    } = await getSituacaoChamadoAutoComplete({ noSituacaoChamado: e });

    setSituacao(situacoesChamados);
  };

  const onSearchChamadoClassificacao = async (): Promise<
    Array<ChamadoClassificacao>
  > => {
    const {
      situacoesChamados,
    }: {
      situacoesChamados: Array<ChamadoClassificacao>;
    } = await getChamadoClassificacaoAutoComplete();

    return situacoesChamados;
  };

  const onClickDownloadBase = async (): Promise<void> => {
    if ('nrSeqPessoaCli' in chamado) {
      const { data, msg } = await downloadBase('', chamado.nrSeqPessoaCli);

      if (msg) {
        setMessage({
          message: msg,
          theme: Theme.Info,
        });

        return;
      }

      const a = document.createElement('a');
      a.href = data;
      a.download = chamado.cliente.noPessoa;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  };

  useEffect((): void => {
    load();
    onSearchPrioridadeChamado();
    onSearchSituacaoChamado();
  }, [load, registryKey, reload]);

  useEffect(() => {
    if (importedFiles.length > 0) {
      onAddDocumeto();

      setImportedFiles([]);
    }
  }, [importedFiles]);

  const { id: idSelecao } = PageTypes.Selection;

  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Chamado'
      loading={loading}
      message={message}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={onSave}
      onDelete={onDelete}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox label='Código' readOnly text={chamado?.cdChamado} />
        </div>
        <div className='col-5'>
          <Autocomplete
            label='Area'
            searchDataSource={onSearchAreaChamado}
            required
            selectedItem={'areaChamado' in chamado ? chamado.areaChamado : null}
            onSelectItem={(areaChamado: AreaChamado) => {
              setChamado({ ...chamado, areaChamado });
            }}
            dataSourceTextProperty='noAreaChamado'
          />
        </div>
        <div className='col-5'>
          <Autocomplete
            label='Tipo de chamado'
            required
            searchDataSource={onSearchTipoChamado}
            selectedItem={'tipoChamado' in chamado ? chamado.tipoChamado : null}
            onSelectItem={(tipoChamado: TipoChamado) => {
              setChamado({
                ...chamado,
                tipoChamado,
                nrSeqTipoChamado: tipoChamado.nrSeqTipoChamado,
              });
            }}
            dataSourceTextProperty='noTipoChamado'
          />
        </div>
      </div>

      <div className='mb-3'>
        {/* @ts-expect-error */}
        <Textarea
          label='Descrição'
          readOnly={'nrSeqChamado' in chamado}
          maxLength={4000}
          rows={6}
          text={'descricao' in chamado ? chamado.descricao : ''}
          onChangedValue={(descricao: string) =>
            setChamado({ ...chamado, descricao })
          }
        />
      </div>

      <div className='row mb-3'>
        <div
          className={
            'nrSeqChamado' in chamado && chamado.nrSeqChamado > 0
              ? 'col-5'
              : 'col-6'
          }
        >
          <Autocomplete
            label='Unidade'
            searchDataSource={onSearchEmpresa}
            required
            selectedItem={'empresa' in chamado ? chamado.empresa : null}
            onSelectItem={(empresa: Empresa) =>
              setChamado({
                ...chamado,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div
          className={
            'nrSeqChamado' in chamado && chamado.nrSeqChamado > 0
              ? 'col-5'
              : 'col-6'
          }
        >
          <Autocomplete
            label='Cliente'
            searchDataSource={onSearchCliente}
            required
            selectedItem={'cliente' in chamado ? chamado.cliente : null}
            onSelectItem={(cliente: Cliente) => {
              setChamado({
                ...chamado,
                cliente,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                noEmailContato: cliente.email,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        {'nrSeqChamado' in chamado && (
          <div className='col-2 d-flex align-items-end pb-1'>
            <Button
              text='Download Base'
              onClick={onClickDownloadBase}
              theme={Theme.Success}
              size='sm'
            />
          </div>
        )}
      </div>

      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Data de abertura'
            readOnly
            text={'dtAbertura' in chamado ? chamado.dtAbertura : null}
            // @ts-expect-error
            mask={MaskTypes.Date}
            onChange={(dtAbertura: string) =>
              setChamado({ ...chamado, dtAbertura })
            }
          />
        </div>
        <div className='col-2'>
          <DropdownList
            label='Prioridade'
            dataSource={prioridades}
            dataSourcePropertyText='noPrioridade'
            dataSourcePropertyValue='nrSeqPrioridadeChamado'
            // @ts-expect-error
            selectedItems={chamado.prioridadeChamado ?? []}
            onSelectItem={(prioridade: PrioridadeChamado) =>
              setChamado({
                ...chamado,
                prioridadeChamado: prioridade,
                nrSeqPrioridadeChamado: prioridade.nrSeqPrioridadeChamado,
              })
            }
          />
        </div>
        <div className='col-2'>
          <DropdownList
            label='Status'
            dataSource={situacao}
            dataSourcePropertyText='noSituacaoChamado'
            dataSourcePropertyValue='nrSeqSituacaoChamado'
            // @ts-expect-error
            selectedItems={chamado.situacaoChamado ?? []}
            onSelectItem={(status: SituacaoChamado) =>
              setChamado({
                ...chamado,
                situacaoChamado: status,
                nrSeqSituacaoChamado: status.nrSeqSituacaoChamado,
              })
            }
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Prazo de conclusão'
            required
            text={
              'dtPrazoConclusao' in chamado ? chamado.dtPrazoConclusao : null
            }
            // @ts-expect-error
            mask={MaskTypes.Date}
            onChange={(dtPrazoConclusao: string) =>
              setChamado({
                ...chamado,
                dtPrazoConclusao: new Date(dtPrazoConclusao)
                  .toISOString()
                  .split('T')[0],
              })
            }
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Responsável'
            searchDataSource={onSearchUsuario}
            selectedItem={'usuarioOp' in chamado ? chamado.usuarioOp : null}
            onSelectItem={(usuarioOp: Usuario) =>
              setChamado({
                ...chamado,
                usuarioOp,
                nrSeqUsuarioOp: usuarioOp.nrSeqUsuario,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-4'>
          {/* @ts-expect-error */}
          <Textbox
            label='Contato'
            maxLength={80}
            text={'noContato' in chamado ? chamado.noContato : null}
            onChangedValue={(noContato: string) =>
              setChamado({ ...chamado, noContato })
            }
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Classificação do chamado'
            searchDataSource={onSearchChamadoClassificacao}
            required
            selectedItem={
              'chamadoClassificacao' in chamado
                ? chamado.chamadoClassificacao
                : null
            }
            onSelectItem={(chamadoClassificacao: ChamadoClassificacao) =>
              setChamado({
                ...chamado,
                chamadoClassificacao,
                nrSeqChamadoClassificacao:
                  chamadoClassificacao.nrSeqChamadoClassificacao,
              })
            }
            dataSourceTextProperty='noChamadoClassificacao'
          />
        </div>
        <div className='col-4'>
          {/* @ts-expect-error */}
          <Textbox
            label='E-mail contato'
            maxLength={200}
            text={'noEmailContato' in chamado ? chamado.noEmailContato : null}
            onChangedValue={(noEmailContato: string) =>
              setChamado({ ...chamado, noEmailContato })
            }
          />
        </div>
      </div>

      {/* @ts-expect-error */}
      <Panel className='mb-3'>
        {/* @ts-expect-error */}
        <Panel.Header title='Documentos' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-6'>
              <GridView
                ref={documentoGridView}
                // @ts-expect-error
                dataColumns={documentoColumns}
                showSelectSizes={false}
                showPagination={false}
                transaction={transaction}
              />
            </div>
            <div className='col-5'>
              <FileUpload
                files={importedFiles}
                onChange={(files: Array<File>) => {
                  setImportedFiles(files);
                }}
                allowedMimeTypes={[
                  MimeTypes.Types.PDF,
                  MimeTypes.Types.Image,
                  MimeTypes.Types.Word,
                  MimeTypes.Types.Txt,
                  MimeTypes.Types.Excel,
                ]}
                maxFiles={5}
                dropzone
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>

      {registryKey > 0 && (
        // @ts-expect-error
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header title='Movimentações' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row align-items-end'>
              <div className='col-8'>
                {/* @ts-expect-error */}
                <Textarea
                  label='Interação no chamado'
                  maxLength={4000}
                  rows={2}
                  text={movimentacao}
                  onChangedValue={(text: string) => setMovimentacao(text)}
                />
              </div>
              <div className='col-2'>
                <Button
                  text='Adicionar Movimentação'
                  theme={Theme.Success}
                  size='sm'
                  onClick={onAddMovimentacao}
                />
              </div>
            </div>
            <GridView
              ref={movimentacaoGridView}
              // @ts-expect-error
              dataColumns={movimentacaoColumns}
              showSelectSizes={false}
              showPagination={false}
              transaction={transaction}
            />
          </Panel.Body>
        </Panel>
      )}
    </CSDManutPage>
  );
}
