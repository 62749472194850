import React, { useState, useRef, useEffect } from 'react';
import { ResponseStatus, BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';

import {
  getContaRecursoAutoComplete,
  getEnviaEmailIndividual,
  sendFaturas,
} from 'core/services/TEL/contaRecurso';

import { getPlanoAutoComplete } from 'core/services/TEL/plano';
import { getCentroDeCustoAutoComplete } from 'core/services/TEL/centroDeCusto';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getEmpresasUsuario } from 'core/services/SEG/empresa';

import {
  getEmailTituloAutoComplete,
  getSMSContaAutoComplete,
  getWhatsAppContaAutoComplete,
} from 'core/services/TEL/emailTitulo';

import {
  Autocomplete,
  Button,
  CSDSelPage,
  DropdownMulti,
  GridView,
  Switch,
  Textbox,
  RadioButton,
  WhatsApp,
} from 'ui/components';

import PanelSelecaoContratos from 'ui/pages/TEL/panelSelecaoContratos';

const columns = [
  { key: 'nrSeqContaRecurso', type: GridView.ColumnTypes.Checkbox },
  { key: 'noPessoa', title: 'Cliente' },
  { key: 'numeroCompleto', title: 'Nro. Telefone' },
  { key: 'noEnvioPadrao', title: 'Envio' },
  { key: 'noPlano', title: 'Plano' },
  { key: 'noCentroDeCusto', title: 'Centro de Custo' },
  { key: 'noFormaPagamento', title: 'Forma Pagamento' },
  { key: 'email', title: 'E-mail' },
  {
    key: 'vlrTotalRecalculo',
    title: 'Vlr Fatura',
    format: GridView.DataTypes.Decimal,
  },
  {
    key: 'nrSeqTituloReceberFatura',
    title: 'nrSeqTituloReceberFatura',
    visible: false,
  },
];

const columnsAgrupado = [
  { key: 'nrSeqContaRecurso', type: GridView.ColumnTypes.Checkbox },
  { key: 'noPessoa', title: 'Cliente' },
  { key: 'numeroCompleto', title: 'Nro. Telefone' },
  { key: 'noEnvioPadrao', title: 'Envio' },
  { key: 'noPlano', title: 'Plano' },
  { key: 'noCentroDeCusto', title: 'Centro de Custo' },
  { key: 'noFormaPagamento', title: 'Forma Pagamento' },
  { key: 'email', title: 'E-mail' },
  {
    key: 'dtFatura',
    title: 'Data Fatura',
    format: GridView.DataTypes.Date,
  },
  {
    key: 'dtVencimento',
    title: 'Data Vencimento',
    format: GridView.DataTypes.Date,
  },
  {
    key: 'nrSeqTituloReceberFatura',
    title: 'nrSeqTituloReceberFatura',
    visible: false,
  },
];

const pagamento = [
  {
    text: 'Em Aberto',
    value: 'Aberto',
  },
  {
    text: 'Pago',
    value: 'Pago',
  },

  {
    text: 'Todos',
    value: 'Todos',
  },
];

const formatoEnvioMensagem = [
  {
    text: 'Apenas Mensagem',
    value: 'apenas_mensagem',
  },
  {
    text: 'Link contas + boleto',
    value: 'link_conta_e_boleto',
  },
  {
    text: 'Link boleto',
    value: 'link_boleto',
  },
  {
    text: 'Email anexo contas + boleto',
    value: 'anexo_conta_e_boleto',
  },
];

const formatoNomeArquivoPdf = [
  {
    text: 'Numero',
    value: 'numero',
  },
  {
    text: 'Contrato',
    value: 'contrato',
  },
  { text: 'Nome', value: 'nome' },

  { text: 'Data', value: 'data' },
];

const intialState = {
  flgEnvioPorEmail: true,
  flgEnvioPorSms: true,
  flgEnvioPorWhatsApp: true,
  flgEnvioPorMobile: true,
  noFormatoNomeArquivoPdf: 'numero',
  noFormatoEnvioMensagem: 'link_boleto',
  flgSomenteNaoEnviados: false,
  flgSomenteContaMaiorQueZero: false,
  noTituloRecebido: 'Aberto',
};

const EnviaEmailIndividual = ({ isActive, transaction }) => {
  const [filters, setFilters] = useState(intialState);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [verificaStatus, setVerificaStatus] = useState(false);
  const gridView = useRef();
  const gridViewAgrupado = useRef();
  const dropDownEmpresas = useRef();
  const dropDownFormasRecebimento = useRef();
  const dropDownCentrosCusto = useRef();
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });

    onSetMessage(status, msg);
    return clientes;
  };

  const searchCentroDeCusto = async () => {
    const {
      status,
      message: msg,
      data: centros,
    } = await getCentroDeCustoAutoComplete({});

    if (msg) onSetMessage(status, msg);

    return centros;
  };

  const searchFormaPagamento = async () => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({});

    if (msg) onSetMessage(status, msg);

    return formasPagamento;
  };

  const searchSMSConta = async (e) => {
    if (!filters.FlgEnvioPadraoSms) {
      onSetMessage(1, 'Selecione o filtro de Envio por SMS.');
      return [];
    }
    const { status, message: msg, emails } = await getSMSContaAutoComplete({
      cdEmailTitulo: e,
    });

    onSetMessage(status, msg);

    return emails;
  };

  const searchMobileConta = async (e) => {
    if (!filters.FlgEnvioPadraoMobile) {
      onSetMessage(1, 'Selecione o filtro de Envio por Mobile.');
      return [];
    }

    const { status, message: msg, emails } = await getSMSContaAutoComplete({
      cdEmailTitulo: e,
    });

    onSetMessage(status, msg);

    return emails;
  };

  const searchEmailConta = async (e) => {
    if (!filters.FlgEnvioPadraoEmail) {
      onSetMessage(1, 'Selecione o filtro de Envio por Email.');
      return [];
    }
    const { status, message: msg, emails } = await getEmailTituloAutoComplete({
      cdEmailTitulo: e,
    });

    onSetMessage(status, msg);

    return emails;
  };

  const searchWhatsAppConta = async (e) => {
    if (!filters.FlgEnvioPadraoWhatsApp) {
      onSetMessage(1, 'Selecione o filtro de Envio por WhatsApp.');
      return [];
    }
    const {
      status,
      message: msg,
      emails,
    } = await getWhatsAppContaAutoComplete({ cdEmailTitulo: e });
    onSetMessage(status, msg);

    return emails;
  };

  const searchEmpresa = async () => {
    const { status, message: msg, empresas } = await getEmpresasUsuario({});

    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  useEffect(() => {
    dropDownEmpresas.current.loadDataSource(searchEmpresa);
    dropDownFormasRecebimento.current.loadDataSource(searchFormaPagamento);
    dropDownCentrosCusto.current.loadDataSource(searchCentroDeCusto);
  }, []);

  const onSearchLinha = async (e) => {
    if (!filters.nrSeqContratoAgrupado && !filters.nrSeqContaCabecalho) {
      onSetMessage(
        ResponseStatus.Error,
        'Obrigatório informar o Contrato Agrupado ou o Contrato / Fatura.'
      );
      return [];
    }

    const {
      status,
      message: msg,
      contaRecursos,
    } = await getContaRecursoAutoComplete({
      nrPrefixoLinha: e,
      nrSeqContratoAgrupado: filters.nrSeqContratoAgrupado,
      nrSeqContaCabecalho: filters.nrSeqContaCabecalho,
    });

    onSetMessage(status, msg);

    return contaRecursos;
  };

  const onSearchPlano = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoComplete({
      noPlano: e,
    });
    onSetMessage(status, msg);
    return planos;
  };

  const enviarFaturasEmail = async () => {
    const param = {
      ...filters,
      contaRecursoFatura: gridView.current
        .getCheckBoxValuesAt(0)
        ?.map((column) => ({
          nrSeqContaRecurso: column.find(
            (prop) => prop.key === 'nrSeqContaRecurso'
          ).value,
          nrSeqTituloReceberFatura: column.find(
            (prop) => prop.key === 'nrSeqTituloReceberFatura'
          ).value,
        })),
      nrseqcontarecursos: gridView.current
        .getCheckBoxValuesAt(0)
        ?.map((row) => row[0].value),
    };

    onSetMessage();
    setLoading(true);
    const { status, message: msg, erros } = await sendFaturas(param);

    if (erros) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${erros}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'Erros.txt';
      downloadLink.click();
    }

    setLoading(false);

    onSetMessage(status, msg);
  };

  const enviarFaturasEmailAgrupado = async () => {
    const param = {
      ...filters,
      contaRecursoFatura: gridViewAgrupado.current
        .getCheckBoxValuesAt(0)
        ?.map((column) => ({
          nrSeqContaRecurso: column.find(
            (prop) => prop.key === 'nrSeqContaRecurso'
          ).value,
          nrSeqTituloReceberFatura: column.find(
            (prop) => prop.key === 'nrSeqTituloReceberFatura'
          ).value,
        })),
      nrseqcontarecursos: gridViewAgrupado.current
        .getCheckBoxValuesAt(0)
        ?.map((row) => row[0].value),
    };

    onSetMessage();
    setLoading(true);
    const { status, message: msg, erros } = await sendFaturas(param);

    if (erros) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${erros}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'Erros.txt';
      downloadLink.click();
    }

    setLoading(false);

    onSetMessage(status, msg);
  };

  const onSearch = async (filterPage) => {
    onSetMessage();
    setLoading(true);

    if (filterPage) {
      filters.page = filterPage.page;
      filters.totalByPage = 5000;
      filters.sortBy = filterPage.sortBy;
    }

    const {
      status,
      message: msg,
      contaRecursos,
      pagination,
    } = await getEnviaEmailIndividual(filters);

    onSetMessage(status, msg);

    if (filters.noTituloRecebido === 'Aberto') {
      if (gridViewAgrupado && gridViewAgrupado.current)
        gridViewAgrupado.current.setDataSource(contaRecursos, pagination);
    }
    if (filters.noTituloRecebido !== 'Aberto') {
      if (gridView && gridView.current)
        gridView.current.setDataSource(contaRecursos, pagination);
    }

    setLoading(false);
  };

  const onPageChange = async (page) => {
    setFilters({ ...filters, page });

    await onSearch({ ...filters, page });
  };
  const onColumnSort = async (sortBy) => {
    setFilters({ ...filters, sortBy });
    await onSearch({ ...filters, sortBy });
  };
  const onPageSizeChange = async (totalByPage) => {
    setFilters({ ...filters, totalByPage });
    await onSearch({ ...filters, totalByPage });
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Envia E-mail Individual'
      transaction={transaction}
      loading={loading}
      onSearch={async () => {
        onSearch({ totalByPage: 5000 });
      }}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <div className='row mb-3'>
        {verificaStatus && (
          <div className='col'>
            <WhatsApp />
          </div>
        )}
      </div>

      <div className='row mb-3'>
        <div className='col'>
          <DropdownMulti
            label='Empresa'
            ref={dropDownEmpresas}
            dataSourcePropertyText='noPessoa'
            dataSourcePropertyValue='nrSeqEmpresa'
            selectedItems={selectedItems.empresas}
            onSelectItem={(empresas) => {
              setSelectedItems({ ...selectedItems, empresas });
              setFilters({
                ...filters,
                nrSeqEmpresas: empresas?.map((p) => p.nrSeqEmpresa),
              });
            }}
          />
        </div>
        <div className='col'>
          <PanelSelecaoContratos
            justActives
            onContratoAgrupadoChange={(contratoAgrupado) =>
              setFilters({
                ...filters,
                nrSeqContratoAgrupado: contratoAgrupado.nrSeqContratoAgrupado,
              })
            }
            onFaturaChange={(fatura) =>
              setFilters({
                ...filters,
                nrSeqContaCabecalho: fatura.nrSeqContaCabecalho,
              })
            }
            onMessage={(msg, theme) => setMessage({ message: msg, theme })}
          />
        </div>
      </div>

      <div className='row '>
        <div className='col mb-3'>
          <div className='row mb-3'>
            <div className='col-4'>
              <Autocomplete
                label='Prefixo/Número'
                searchDataSource={onSearchLinha}
                selectedItem={filters.contaRecurso}
                onSelectItem={(contaRecurso) =>
                  setFilters({
                    ...filters,
                    contaRecurso,
                    nrSeqContaRecurso: contaRecurso.nrSeqContaRecurso,
                  })
                }
                dataSourceTextProperty='linhaFormatada'
              />
            </div>
            <div className='col'>
              <Autocomplete
                label='Cliente'
                searchDataSource={onSearchCliente}
                selectedItem={filters.cliente}
                onSelectItem={(cliente) =>
                  setFilters({
                    ...filters,
                    cliente,
                    nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                  })
                }
                dataSourceTextProperty='noPessoa'
              />
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col'>
              <div className='card'>
                <div className='card-body'>
                  <div className='row mb-3'>
                    <div className='col'>
                      <Switch
                        formControl
                        label='Envio por e-mail'
                        checked={filters.FlgEnvioPadraoEmail}
                        onChange={(flgEnvioPorEmail) => {
                          setFilters({
                            ...filters,
                            FlgEnvioPadraoEmail: flgEnvioPorEmail,
                          });
                        }}
                      />
                    </div>

                    <div className='col'>
                      <Switch
                        formControl
                        label='Envio por SMS'
                        checked={filters.FlgEnvioPadraoSms}
                        onChange={(FlgEnvioSms) =>
                          setFilters({
                            ...filters,
                            FlgEnvioPadraoSms: FlgEnvioSms,
                          })
                        }
                      />
                    </div>

                    <div className='col'>
                      <Switch
                        formControl
                        label='Envio por Whatsapp'
                        checked={filters.FlgEnvioPadraoWhatsApp}
                        onChange={(flgEnvioPorWhatsApp) =>
                          setFilters({
                            ...filters,
                            FlgEnvioPadraoWhatsApp: flgEnvioPorWhatsApp,
                          })
                        }
                      />
                    </div>

                    <div className='col'>
                      <Switch
                        formControl
                        label='Envio por Mobile'
                        checked={filters.FlgEnvioPadraoMobile}
                        onChange={(flgEnvioPorMobile) =>
                          setFilters({
                            ...filters,
                            FlgEnvioPadraoMobile: flgEnvioPorMobile,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col'>
              <Textbox
                label='E-mail Cópia'
                text={filters.noEmailCopia}
                onChangedValue={(noEmailCopia) => {
                  setFilters({ ...filters, noEmailCopia });
                }}
              />
            </div>
          </div>
        </div>
        <div className='col mb-3'>
          <div className='col mb-3'>
            <DropdownMulti
              label='Forma Recebimento'
              ref={dropDownFormasRecebimento}
              dataSourcePropertyText='noFormaPagamento'
              dataSourcePropertyValue='nrSeqFormaPagamento'
              selectedItems={selectedItems.formaspagamento}
              onSelectItem={(formaspagamento) => {
                setSelectedItems({ ...selectedItems, formaspagamento });
                setFilters({
                  ...filters,
                  nrSeqFormasPagamentos: formaspagamento?.map(
                    (p) => p.nrSeqFormaPagamento
                  ),
                });
              }}
            />
          </div>
          <div className='col mb-3'>
            <DropdownMulti
              label='Centro de Custos'
              ref={dropDownCentrosCusto}
              dataSourcePropertyText='noCentroDeCusto'
              dataSourcePropertyValue='nrSeqCentroDeCusto'
              selectedItems={selectedItems.centrosdecustos ?? []}
              onSelectItem={(centrosdecustos) => {
                setSelectedItems({ ...selectedItems, centrosdecustos });
                setFilters({
                  ...filters,
                  nrSeqCentrosDeCustos: centrosdecustos?.map(
                    (p) => p.nrSeqCentroDeCusto
                  ),
                });
              }}
            />
          </div>
          <div className='col mb-3'>
            <Autocomplete
              label='Plano'
              searchDataSource={onSearchPlano}
              selectedItem={filters.plano}
              onSelectItem={(plano) =>
                setFilters({
                  ...filters,
                  plano,
                  nrSeqPlano: plano.nrSeqPlano,
                })
              }
              dataSourceTextProperty='noPlano'
            />
          </div>
          <div className='row mb-3'>
            <div className='col-auto'>
              <RadioButton
                label='Pagamento'
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={filters.noTituloRecebido}
                buttons={pagamento}
                onChange={(noTituloRecebido) =>
                  setFilters({
                    ...filters,
                    noTituloRecebido,
                  })
                }
              />
            </div>
            <div className='col-auto'>
              <Switch
                formControl
                label='Não enviados'
                onChange={(flgSomenteNaoEnviados) => {
                  setFilters({
                    ...filters,
                    flgSomenteNaoEnviados,
                  });
                  if (
                    flgSomenteNaoEnviados &&
                    filters.FlgEnvioPadraoSms &&
                    filters.nrSeqMensagemSms === undefined &&
                    filters.FlgEnvioPadraoWhatsApp &&
                    filters.nrSeqMensagemWhatsApp === undefined &&
                    filters.FlgEnvioPadraoEmail &&
                    filters.nrSeqMensagemEmail === undefined
                  )
                    onSetMessage(1, 'Selecione a mensagem de envio.');
                }}
              />
            </div>

            <div className='col-auto'>
              <Switch
                formControl
                label='Somente conta maior que zero'
                onChange={(flgSomenteContaMaiorQueZero) =>
                  setFilters({
                    ...filters,
                    flgSomenteContaMaiorQueZero,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-6'>
          <RadioButton
            label='Forma Envio Mensagem'
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={filters.noFormatoEnvioMensagem}
            buttons={formatoEnvioMensagem}
            onChange={(noFormatoEnvioMensagem) =>
              setFilters({
                ...filters,
                noFormatoEnvioMensagem,
              })
            }
          />
        </div>

        <div className='col'>
          <div className='col'>
            <RadioButton
              label='Formato Nome Arquivo PDF'
              outline
              size={BootstrapSizes.Small}
              theme={Theme.Primary}
              selectedButton={filters.noFormatoNomeArquivoPdf}
              buttons={formatoNomeArquivoPdf}
              onChange={(noFormatoNomeArquivoPdf) =>
                setFilters({
                  ...filters,
                  noFormatoNomeArquivoPdf,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <div className='row'>
            <div className='col'>
              <div className='bg-white'>
                <div className='row'>
                  <div className='col align-self-end'>
                    <div className='row mb-3'>
                      <div className='col'>
                        <Autocomplete
                          label='Mensagem SMS'
                          searchDataSource={searchSMSConta}
                          selectedItem={filters.emailcontasms}
                          onSelectItem={(emailcontasms) => {
                            setFilters({
                              ...filters,
                              emailcontasms,
                              nrSeqMensagemSms: emailcontasms.nrSeqEmailTitulo,
                            });
                          }}
                          dataSourceTextProperty='codigoTitulo'
                        />
                      </div>
                      <div className='col'>
                        <Autocomplete
                          label='Mensagem Whatsapp'
                          searchDataSource={searchWhatsAppConta}
                          selectedItem={filters.emailcontawhatsapp}
                          onSelectItem={(emailcontawhatsapp) => {
                            setFilters({
                              ...filters,
                              emailcontawhatsapp,
                              nrSeqMensagemWhatsApp:
                                emailcontawhatsapp.nrSeqEmailTitulo,
                              noFormatoEnvioMensagem: 'link_conta_e_boleto',
                            });

                            setVerificaStatus(
                              emailcontawhatsapp.flgVerificaConexaoWhatsApp
                            );
                          }}
                          dataSourceTextProperty='codigoTitulo'
                        />
                      </div>
                      <div className='col'>
                        <Autocomplete
                          label='Mensagem e-mail'
                          searchDataSource={searchEmailConta}
                          selectedItem={filters.emailconta}
                          onSelectItem={(emailconta) => {
                            setFilters({
                              ...filters,
                              emailconta,
                              nrSeqMensagemEmail: emailconta.nrSeqEmailTitulo,
                              noFormatoEnvioMensagem: 'link_conta_e_boleto',
                            });
                          }}
                          dataSourceTextProperty='codigoTitulo'
                        />
                      </div>
                      <div className='col'>
                        <Autocomplete
                          label='Mensagem Mobile'
                          searchDataSource={searchMobileConta}
                          selectedItem={filters.emailcontamobile}
                          onSelectItem={(emailcontamobile) => {
                            setFilters({
                              ...filters,
                              emailcontamobile,
                              nrSeqMensagemMobile:
                                emailcontamobile.nrSeqEmailTitulo,
                            });
                          }}
                          dataSourceTextProperty='codigoTitulo'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col'>
          <Button
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Enviar Mensagem'
            className='width100'
            onClick={
              filters.noTituloRecebido === 'Aberto'
                ? enviarFaturasEmailAgrupado
                : enviarFaturasEmail
            }
          />
        </div>
      </div>
      {filters.noTituloRecebido !== 'Aberto' && (
        <div className='row mt-3'>
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              onPageChange={onPageChange}
              onColumnSort={onColumnSort}
              onPageSizeChange={onPageSizeChange}
              gridSizeList={[5000]}
            />
          </div>
        </div>
      )}
      {filters.noTituloRecebido === 'Aberto' && (
        <div className='row mt-3'>
          <div className='col'>
            <GridView
              ref={gridViewAgrupado}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnsAgrupado}
              onPageChange={onPageChange}
              onColumnSort={onColumnSort}
              onPageSizeChange={onPageSizeChange}
              gridSizeList={[5000]}
            />
          </div>
        </div>
      )}
    </CSDSelPage>
  );
};

export default EnviaEmailIndividual;
