import React, { useRef, useState, useEffect } from 'react';
import {
  deleteAllChamadoClassificacao,
  getChamadoClassificacaoPagined,
} from 'core/services/HELP/chamadoClassificacao';
import { ColumnTypes } from 'ui/Helpers/enums';
import { GridviewColumns, Message, Page } from 'ui/Helpers/interfaces';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDSelPage, GridView } from 'ui/components';

const columns: Array<GridviewColumns> = [
  { key: 'nrSeqChamadoClassificacao', type: ColumnTypes.Checkbox },
  { key: 'noChamadoClassificacao', title: 'Classificação de chamado' },
];

export default function ChamadoClassificacao({
  transaction,
  onOpenPage,
  isActive,
}: Page) {
  const gridView: any = useRef();

  const [filters, setFilters] = useState<Object>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const search = async (params: Object | null = null): Promise<void> => {
    setLoading(true);

    const { data, pagination } = await getChamadoClassificacaoPagined(
      params ?? filters
    );

    if (gridView && gridView.current) {
      gridView.current.setDataSource(data, pagination);
    }

    setLoading(false);
  };

  useEffect(() => {
    search();
  }, []);

  const onColumnSort = (sortBy: string): void => {
    const filtros = { ...filters, sortBy };

    setFilters(filtros);

    search(filtros);
  };

  const onPageSizeChange = (totalByPage: number): void => {
    const filtros = { ...filters, totalByPage };

    setFilters(filtros);

    search(filtros);
  };

  const onPageChange = (page: number): void => {
    const filtros = { ...filters, page };

    setFilters(filtros);

    search(filtros);
  };

  const onOpenMaintenance = (registryKey: number | null): void => {
    onOpenPage(
      transaction.noTransacao,
      'ChamadoClassificacao/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow: Array<any>): void => {
    const nrSeqChamadoClassificacao = sourceRow.find(
      (e) => e.key === 'nrSeqChamadoClassificacao'
    );

    onOpenMaintenance(nrSeqChamadoClassificacao.value);
  };

  const onDelete = async (): Promise<void> => {
    setLoading(true);

    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllChamadoClassificacao(
        selecteds.map((row: any) => row[0].value)
      );

      setLoading(false);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);

      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Classificação de chamado'
      loading={loading}
      message={message}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <GridView
        ref={gridView}
        // @ts-expect-error
        dataColumns={columns}
        onColumnSort={onColumnSort}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        onRowDoubleClick={onRowDoubleClick}
        transaction={transaction}
      />
    </CSDSelPage>
  );
}
