import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  Switch,
  DatePicker,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getContaCorrente,
  saveContaCorrente,
  deleteContaCorrente,
} from 'core/services/FIN/contaCorrente';

import { getAgenciaAutoComplete } from 'core/services/FIN/agencia';

export default function ContaCorrenteItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ flgVinculaBanco: true });
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      setData(await getContaCorrente(registryKey));
      setLoading(false);
    } else setData({ flgVinculaBanco: true });

    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveContaCorrente(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteContaCorrente(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchAgencia = async (e) => {
    const {
      status,
      message: msg,
      data: agencias,
    } = await getAgenciaAutoComplete({
      noAgencia: e,
    });
    if (msg) onSetMessage(status, msg);
    return agencias;
  };

  const { id: idSelecao } = PageTypes.Selection;

  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Conta Corrente'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({ flgVinculaBanco: true })}
      onSave={save}
      onDelete={data?.nrSeqContaCorrente > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-3'>
          <Textbox
            maxLength={20}
            label='Numero da Conta'
            text={data.cdContaCorrente}
            onChangedValue={(cdContaCorrente) =>
              setData({ ...data, cdContaCorrente })
            }
          />
        </div>
        <div className='col-4'>
          <Textbox
            maxLength={2}
            label='Dígito'
            text={data.cdContaCorrenteDig}
            onChangedValue={(cdContaCorrenteDig) =>
              setData({ ...data, cdContaCorrenteDig })
            }
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-5'>
          <Textbox
            maxLength={40}
            label='Nome Da Conta'
            text={data.noContaCorrente}
            onChangedValue={(noContaCorrente) =>
              setData({ ...data, noContaCorrente })
            }
          />
        </div>
        <div className='col-3'>
          <Textbox
            label='Float Para Previsão Financeira'
            maxLength={3}
            text={data.floatPrevisao}
            mask={MaskTypes.Integer}
            onChangedValue={(floatPrevisao) =>
              setData({ ...data, floatPrevisao })
            }
          />
        </div>
        <div className='col-3 '>
          <DatePicker
            label='Data Inicio'
            text={data.dtInicio}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtInicio) => setData({ ...data, dtInicio })}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-5 '>
          <Textbox
            label='Saldo Inicial'
            maxLength={12}
            text={data.saldoInicial}
            mask={MaskTypes.Decimal}
            onChangedValue={(saldoInicial) =>
              setData({ ...data, saldoInicial })
            }
          />
        </div>

        <div className='col-6 '>
          <Switch
            required
            formControl
            label='Vincula Banco'
            checked={data.flgVinculaBanco}
            onChange={(flgVinculaBanco) =>
              setData({ ...data, flgVinculaBanco })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-5'>
          <Autocomplete
            visible={data.flgVinculaBanco}
            label='Agencia'
            searchDataSource={onSearchAgencia}
            selectedItem={data.agencia}
            onSelectItem={(agencia) => {
              setData({
                ...data,
                agencia,
                nrSeqAgencia: agencia.nrSeqAgencia,
              });
            }}
            dataSourceTextProperty='noAgencia'
          />
        </div>
        <div className='col-5 '>
          <Textbox
            visible={data.flgVinculaBanco}
            label='Banco'
            readOnly
            text={data.agencia?.banco?.noBanco}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
