import React, {
  forwardRef,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { GridView, FileUpload } from 'ui/components';
import { Theme, BootstrapSizes, MimeTypes } from 'ui/Helpers/utils';

import {
  deletePocPocoDocumentoAnaliseAgua,
  getPocPocoDocumentoAnaliseAguaPagined,
  uploadDocumento,
} from 'core/services/POC/pocPocoDocumentoAnaliseAgua';
import PocPocoDocumentoAnaliseAgua from 'core/models/POC/pocPocoDocumentoAnaliseAgua';

const CadastroDocumentoAnaliseAgua = forwardRef(({ data, setData }) => {
  const gridView = useRef();

  const [importedFile, setImportedFile] = useState([]);
  const [documentos, setDocumentos] = useState([]);

  const load = useCallback(async () => {
    const obj = { nrSeqPocPoco: data.nrSeqPocPoco };
    const listaDocumentos = await getPocPocoDocumentoAnaliseAguaPagined(obj);
    setDocumentos(listaDocumentos.data);
    if (gridView && gridView.current)
      gridView.current.setDataSource(listaDocumentos.data);
  }, []);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, []);

  useEffect(async () => {
    if (importedFile.length > 0) {
      await uploadDocumento(importedFile[0], data.nrSeqPocPoco);

      const documento = new PocPocoDocumentoAnaliseAgua({
        noArquivo: importedFile[0].name,
        noTipoArquivo: importedFile[0].type,
        noTamanho: importedFile[0].size,
        flgExibeDownload: false,
      });

      const lista = [];
      lista.push(documento);
      const obj = { nrSeqPocPoco: data.nrSeqPocPoco };
      const listaDocumentos = await getPocPocoDocumentoAnaliseAguaPagined(obj);
      setDocumentos(documentos);
      if (gridView && gridView.current)
        gridView.current.setDataSource(listaDocumentos.data);
    }
  }, [importedFile]);

  const onClickDownload = async (e) => {
    const linkSource = `data:image/png;base64, ${e.noImagem}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = e.noArquivo;
    downloadLink.click();
  };

  const onRemoveDocumento = async (e, datasource) => {
    const docS = datasource.filter((doc) => doc !== e);

    setDocumentos(docS);
    if (gridView && gridView.current) gridView.current.setDataSource(docS);

    setData({
      ...data,
    });
    deletePocPocoDocumentoAnaliseAgua(e.nrSeqPocPocoDocumentoAnaliseAgua);
  };

  const columns = [
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'nrSeqPocPocoDocumentoAnaliseAgua',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },
    {
      key: 'nrSeqPocPocoDocumentoAnaliseAgua',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <div>
      <div className='row mb-3'>
        <div className='display-8'>8 - Documentos de Analise de Água</div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <div className='card'>
            <div className='card-body'>
              <div className='row mb-4'>
                <FileUpload
                  files={importedFile}
                  onChange={(files) => setImportedFile(files)}
                  allowedMimeTypes={[
                    MimeTypes.Types.PDF,
                    MimeTypes.Types.Image,
                    MimeTypes.Types.Word,
                  ]}
                />
              </div>
              <div className='row mb-3' style={{ paddingBottom: '450px' }}>
                <div className='col'>
                  <GridView
                    ref={gridView}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default CadastroDocumentoAnaliseAgua;
