import { TipoPendenciaFinanceira, Cliente, Fornecedor } from 'core/models/FIN';
import PedidoCompra from 'core/models/VEN/pedidoCompra';
import { Vendedor } from 'core/models/VEN';

export default class AprovaFinanceiro {
  constructor(jsonObject = {}) {
    this.nrSeqPendenciaFinanceira = jsonObject.nrSeqPendenciaFinanceira;
    this.nrSeqPendente = jsonObject.nrSeqPendente;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqTipoPendenciaFinanceira = jsonObject.nrSeqTipoPendenciaFinanceira;
    this.flgAprovada = jsonObject.flgAprovada;
    this.dtCadastro = jsonObject.dtCadastro;
    this.nrDocumento = jsonObject.nrDocumento;
    this.noObs = jsonObject.noObs;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.nrSeqUsuarioAprov = jsonObject.nrSeqUsuarioAprov;
    this.dtAprovacao = jsonObject.dtAprovacao;
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.noPessoaCli = jsonObject.noPessoaCli;
    this.nrSeqPessoaVen = jsonObject.nrSeqPessoaVen;
    this.noPessoaVen = jsonObject.noPessoaVen;
    this.noTipoPendenciaFinanceira = jsonObject.noTipoPendenciaFinanceira;
    this.noSituacao = jsonObject.noSituacao;
    this.noColor = jsonObject.noColor;
    this.vlrTotal = jsonObject.vlrTotal;
    this.flgPendenteAprovacao = jsonObject.flgPendenteAprovacao;
    this.tipoPendenciaFinanceira = new TipoPendenciaFinanceira(
      jsonObject.tipoPendenciaFinanceira ?? {}
    );
    this.cliente = new Cliente(jsonObject.cliente ?? {});
    this.vendedor = new Vendedor(jsonObject.vendedor ?? {});
    this.fornecedor = new Fornecedor(jsonObject.fornecedor ?? {});
    this.pedidoCompra = new PedidoCompra(jsonObject.pedidoCompra ?? {});
  }

  get getdtCadastroFormatada() {
    if (this.dtCadastro) {
      return `${new Date(this.dtCadastro).toLocaleDateString('pt-BR')}`;
    }
    return '';
  }

  get getdtAprovacao() {
    if (this.dtCadastro) {
      return `${new Date().toLocaleDateString('pt-BR')}`;
    }
    return '';
  }

  get getFlgAprovada() {
    if (this.dtAprovacao === undefined) return '';

    return this.flgAprovada;
  }

  toJson = () => JSON.stringify(this);
}
