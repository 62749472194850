import Veiculo from './veiculo';

export default class AcertoMotoristaModeloMedia {
  constructor(jsonObject = {}) {
    this.nrSeqAcertoMotoristaModeloMedia =
      jsonObject.nrSeqAcertoMotoristaModeloMedia;
    this.nrSeqVeiculoPrinc = jsonObject.nrSeqVeiculoPrinc;
    this.nrSeqVeiculoComp = jsonObject.nrSeqVeiculoComp;
    this.nrSeqVeiculoCompB = jsonObject.nrSeqVeiculoCompB;
    this.nrSeqVeiculocompC = jsonObject.nrSeqVeiculocompC;
    this.veiculoPrinc = new Veiculo(jsonObject.veiculoPrinc ?? {});
    this.veiculoComp = new Veiculo(jsonObject.veiculoComp ?? {});
    this.veiculoCompB = new Veiculo(jsonObject.veiculoCompB ?? {});
    this.veiculoCompC = new Veiculo(jsonObject.veiculoCompC ?? {});
    this.acertoMotoristaMediaS = jsonObject.acertoMotoristaMediaS;
  }

  toJson = () => JSON.stringify(this);
}
