export default class ProdutoLinha {
  constructor(jsonObject = {}) {
    this.nrSeqProdutoLinha = jsonObject.nrSeqProdutoLinha;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.noProdutoLinha = jsonObject.noProdutoLinha;
    this.cdProdutoLinha = jsonObject.cdProdutoLinha;
    this.grupoempresa = jsonObject.grupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
