import { Cidade, Estado } from '../SEG';

export default class OrcamentoEndereco {
  constructor(jsonObject = {}) {
    this.nrSeqPocOrcamentoEndereco = jsonObject.nrSeqPocOrcamentoEndereco;
    this.noEndereco = jsonObject.noEndereco;
    this.noBairro = jsonObject.noBairro;
    this.nrNumero = jsonObject.nrNumero;
    this.dtData = jsonObject.dtData;
    this.noHora = jsonObject.noHora;
    this.noObs = jsonObject.noObs;
    this.nrSeqPocOrcamento = jsonObject.nrSeqPocOrcamento;
    this.nrSeqCidade = jsonObject.nrSeqCidade;
    this.flgTipoEndereco = jsonObject.flgTipoEndereco;
    this.flgTipoEnderecoStr = jsonObject.flgTipoEnderecoStr;
    this.status = jsonObject.status;
    this.cidade = new Cidade(jsonObject.cidade ?? {});
    this.estado = new Estado(jsonObject.estado ?? {});
  }

  toJson = () => JSON.stringify(this);
}
