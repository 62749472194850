/* eslint-disable no-unused-vars */
import React, { forwardRef, useEffect, useState, useRef } from 'react';
import {
  getClienteAutoComplete,
  getTituloReceberFaturaPendetesPaginated,
  postValidaEnviaFaturaEmail,
} from 'core/services/FIN';
import { getLinkDownloadFatura, postGerarPDFConta } from 'core/services/TEL';
import {
  Autocomplete,
  Button,
  CSDBarGraphic,
  DatePicker,
  GridView,
  Panel,
} from 'ui/components';
import {
  BootstrapSizes,
  ResponseStatus,
  Theme,
  Themes,
} from 'ui/Helpers/utils';
import ModalEnviaFatura from 'ui/pages/FIN/EditarFatura/modalEnviaFatura';
import ModalEnvioSMS from 'ui/pages/FIN/TituloReceber/modalEnvioSMS';
import {
  getEmpresaDefaultUsuario,
  getEmpresasUsuario,
} from 'core/services/SEG';

const TitulosStep = forwardRef(
  ({ data, setData, setLoading, transaction, onOpenReport, onSetMessage }) => {
    const vencimentoDe = new Date();
    vencimentoDe.setMonth(vencimentoDe.getMonth() - 6);

    const vencimentoAte = new Date();
    vencimentoAte.setDate(vencimentoAte.getDate() - 5);

    const graficoRef = useRef();
    const gridView = useRef();
    const [filters, setFilters] = useState({
      dtVencimentoAte: vencimentoAte.toJSON().slice(0, 10),
      dtVencimentoDe: vencimentoDe.toJSON().slice(0, 10),
      empresa: data.empresa,
      nrSeqEmpresa: data.nrSeqEmpresa,
      totalByPage: 1000,
      sortBy: 'TITULORECEBERFATURA.DTVENCIMENTO DESC',
    });
    const [modalShow, setModalShow] = useState({});
    const [envioPadrao, setEnvioPadrao] = useState();
    const [titulosReceberFatura, setTitulosReceberFatura] = useState([]);
    const [showModalEnviaFatura, setShowModalEnviaFatura] = useState(false);
    const [graficoData, setGraficoData] = useState([]);

    const onClickPrintConta = async (selectedValue) => {
      setLoading(true);

      const { nrSeqsContaRecurso } = selectedValue;

      if (nrSeqsContaRecurso.length === 0) {
        onSetMessage(
          ResponseStatus.Error,
          'Nenhum Conta Recurso vinculado aos Titulos da Fatura'
        );
      } else {
        const { status, message: msg, pathConta } = await postGerarPDFConta(
          nrSeqsContaRecurso
        );

        onSetMessage(status, msg);

        if (status === ResponseStatus.Success) {
          onOpenReport(transaction.noTransacao, pathConta);
        }
      }

      setLoading(false);
    };

    const onClickEnviarEmail = async (e, datasource) => {
      setLoading(true);

      const gridItem = datasource.find((el) => el === e);

      setEnvioPadrao(0);

      const nrSeqTituloReceberFatura = [gridItem.nrSeqTituloReceberFatura];

      const retorno = await postValidaEnviaFaturaEmail(
        nrSeqTituloReceberFatura
      );

      if (retorno.status === ResponseStatus.Success) {
        setTitulosReceberFatura(retorno.data);

        setShowModalEnviaFatura(true);
      } else {
        onSetMessage(retorno.status, retorno.message);
      }
      setLoading(false);
    };

    const onClickAbrirLinkFatura = async (selectedValue) => {
      setLoading(true);

      const { nrSeqsContaRecurso } = selectedValue;

      if (nrSeqsContaRecurso.length === 0) {
        onSetMessage(
          ResponseStatus.Error,
          'Nenhum Conta Recurso vinculado aos Titulos da Fatura'
        );
      } else {
        const {
          status,
          message: msg,
          value: link,
        } = await getLinkDownloadFatura(nrSeqsContaRecurso[0]);

        if (status === ResponseStatus.Success) {
          window.open(link, '_blank');
          // const openLink = document.createElement('a');
          // openLink.href = link;
          // a.rel = 'noopener noreferrer';
          // openLink.setAttribute('target', '_blank');
          // openLink.click();
        } else {
          onSetMessage(status, msg);
        }
      }

      setLoading(false);
    };

    const columns = [
      {
        key: 'cliente.noPessoa',
        title: 'Cliente',
        sortKey: 'noPessoa',
      },
      {
        key: 'formaPagamento.noFormaPagamento',
        title: 'Forma Pagamento',
        sortKey: 'FormaPagamento.noFormaPagamento',
      },
      {
        key: 'vlrFatura',
        title: 'Valor',
        format: GridView.DataTypes.Decimal,
      },
      {
        key: 'dtVencimento',
        title: 'Vencimento',
        format: GridView.DataTypes.Date,
        sortKey: 'TITULORECEBERFATURA.DTVENCIMENTO',
      },
      {
        key: 'nrSeqContaRecurso',
        type: GridView.ColumnTypes.Button,
        onClick: (e) => onClickPrintConta(e),
        theme: Theme.Primary,
        icon: 'print',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Imprimir conta detalhada',
        tooltipDirection: 'bottom',
        title: 'Imprimir Detalhado',
      },
      {
        key: 'nrSeqTituloReceberFatura',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onClickEnviarEmail(e, datasource),
        theme: Theme.Primary,
        icon: 'envelope',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Enviar Email',
        tooltipDirection: 'bottom',
        title: 'Enviar Email',
      },
      {
        key: 'nrSeqTituloReceberFatura',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onClickAbrirLinkFatura(e),
        theme: Theme.Primary,
        icon: 'eye',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Abrir link da Fatura',
        tooltipDirection: 'bottom',
        title: 'Abrir Fatura',
      },
    ];

    const getTitulos = async (params = null) => {
      setLoading(true);

      if (!filters.nrSeqEmpresa) {
        onSetMessage(ResponseStatus.Error, 'Selecione a Empresa');
      } else {
        let obj = {
          ...filters,
          retornarGrafico: true,
        };

        if (params !== null) {
          obj = { ...obj, ...params };
        }

        const {
          data: titulos,
          pagination,
        } = await getTituloReceberFaturaPendetesPaginated(obj);

        if (titulos.length > 0) {
          gridView?.current?.setDataSource(titulos, pagination);

          if (titulos[0].graficoData) {
            setGraficoData(titulos[0].graficoData);
          }
        } else {
          gridView?.current?.setDataSource(titulos, pagination);

          setGraficoData([]);
        }
      }

      setLoading(false);
    };

    const onColumnSort = (sortBy) => {
      const filtros = { ...filters, sortBy };

      setFilters(filtros);

      getTitulos(filtros);
    };

    const onPageSizeChange = (totalByPage) => {
      const filtros = { ...filters, totalByPage };

      setFilters(filtros);

      getTitulos(filters);
    };

    const onPageChange = (page) => {
      const filtros = { ...filters, page };

      setFilters(filtros);

      getTitulos(filtros);
    };

    const onSearchCliente = async (e) => {
      const { clientes } = await getClienteAutoComplete({
        noPessoa: e,
        flgFiltrarEmpresa: true,
      });

      return clientes;
    };

    const onSearchEmpresa = async (e) => {
      const { status, message: msg, empresas } = await getEmpresasUsuario({
        noPessoa: e,
      });

      onSetMessage(status, msg);

      return empresas;
    };

    const optionsBarGraphic = {
      responsive: true,
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              const { formattedValue } = tooltipItem;

              return `Valor Pendente total: R$ ${formattedValue}`;
            },
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    };

    useEffect(async () => {
      if (!data.nrSeqEmpresa) {
        setLoading(true);

        const { empresas } = await getEmpresaDefaultUsuario();

        setFilters({
          ...filters,
          empresa: empresas[0],
          nrSeqEmpresa: empresas[0].nrSeqEmpresa,
        });

        setData({
          ...data,
          empresa: empresas[0],
          nrSeqEmpresa: empresas[0].nrSeqEmpresa,
        });

        setLoading(false);
      }
    }, []);

    useEffect(() => {
      if (filters.nrSeqEmpresa) {
        getTitulos();
      }
    }, []);

    useEffect(() => {
      if (graficoData.length > 0) {
        const labels = graficoData?.map((p) => p.noMes);

        const faturasPendenteData = graficoData?.map(
          (item) => item.vlrPendente
        );

        graficoRef?.current?.setDataSource({
          labels,
          datasets: [
            {
              label: 'Faturas Pendentes',
              data: faturasPendenteData,
              backgroundColor: 'rgba(220, 226, 35, 0.8)',
            },
          ],
        });
      }
    }, [graficoData]);

    return (
      <>
        <div className='row mb-3'>
          <div className='col'>
            <Autocomplete
              label='Empresa'
              searchDataSource={onSearchEmpresa}
              selectedItem={filters.empresa}
              onSelectItem={(empresa) => {
                setFilters({
                  ...filters,
                  empresa,
                  nrSeqEmpresa: empresa.nrSeqEmpresa,
                });

                setData({
                  ...data,
                  empresa,
                  nrSeqEmpresa: empresa.nrSeqEmpresa,
                });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <Panel>
              <Panel.Header title='Títulos Pendentes' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col'>
                    <Autocomplete
                      label='Cliente'
                      searchDataSource={onSearchCliente}
                      selectedItem={filters.cliente}
                      onSelectItem={(cliente) => {
                        setFilters({ ...filters, cliente });
                      }}
                      minLengthTextSearch={3}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Data de Vencimento De'
                      text={filters.dtVencimentoDe}
                      De
                      onChange={(dtVencimentoDe) => {
                        setFilters({ ...filters, dtVencimentoDe });
                      }}
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Data de Vencimento Até'
                      text={filters.dtVencimentoAte}
                      onChange={(dtVencimentoAte) => {
                        setFilters({ ...filters, dtVencimentoAte });
                      }}
                    />
                  </div>
                  <div className='col d-flex align-items-end'>
                    <Button
                      text='Pesquisar'
                      icon='search'
                      theme={Theme.Primary}
                      template={Button.Templates.Default}
                      onClick={() => getTitulos()}
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col'>
                    <GridView
                      ref={gridView}
                      dataColumns={columns}
                      onColumnSort={onColumnSort}
                      onPageSizeChange={onPageSizeChange}
                      onPageChange={onPageChange}
                      gridSizeList={[1000, 800, 600, 400, 300, 200, 100]}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <CSDBarGraphic ref={graficoRef} options={optionsBarGraphic} />
          </div>
        </div>

        <ModalEnvioSMS
          show={modalShow.enviarSms}
          transaction={transaction}
          envioSms={modalShow.envioSms}
          onClose={({ message: msg, theme }) => {
            setModalShow({
              ...modalShow,
              enviarSms: false,
            });
            onSetMessage(
              theme === Themes.Success
                ? ResponseStatus.Success
                : ResponseStatus.Error,
              msg
            );
          }}
        />
        <ModalEnviaFatura
          show={showModalEnviaFatura}
          titulosreceberfatura={titulosReceberFatura}
          nrFormaEnvio={envioPadrao}
          flgEditFatura={false}
          onClose={({ message: msg, theme }) => {
            setShowModalEnviaFatura(false);
            onSetMessage(
              theme === Themes.Success
                ? ResponseStatus.Success
                : ResponseStatus.Error,
              msg
            );
          }}
          retornoEnvioEmail={(retorno) => {
            onSetMessage(retorno.status, retorno.message);
            setShowModalEnviaFatura(false);
          }}
        />
      </>
    );
  }
);

export default TitulosStep;
