import React from 'react';

import Routes from './ui/routes';
import './ui/theme/scss/style.scss';

function App() {
  return <Routes />;
}

export default App;
