import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getVeiculoInfracaoPagined,
  deleteAllVeiculoInfracao,
  printLancamentoInfracao,
} from 'core/services/FRO/veiculo_Infracao';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';
import { CSDSelPage, GridView, DatePicker, Autocomplete } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';

const columns = [
  { key: 'nrSeqVeiculo_Infracao', type: GridView.ColumnTypes.Checkbox },
  { key: 'motorista.noPessoa', title: 'Motorista' },
  {
    key: 'dtOcorrencia',
    title: 'Data',
    format: GridView.DataTypes.Date,
  },
  { key: 'veiculo.noVeiculo', title: 'Veiculo' },
  {
    key: 'vlrInfracao',
    title: 'Valor',
    format: GridView.DataTypes.Decimal,
  },
];

export default function VeiculoInfracao({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getVeiculoInfracaoPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getVeiculoInfracaoPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'LancamentoInfracao/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printLancamentoInfracao(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqVeiculoInfracao = sourceRow.find(
      (e) => e.key === 'nrSeqVeiculo_Infracao'
    );
    onOpenMaintenance(nrSeqVeiculoInfracao.value);
  };
  const onSearchPessoaMot = async (e) => {
    const { status, message: msg, motoristas } = await getMotoristaAutoComplete(
      {
        noPessoaMot: e,
      }
    );
    if (msg) onSetMessage(status, msg);
    return motoristas;
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllVeiculoInfracao(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Lançamento de Infração'
      loading={loading}
      onSearch={() => search()}
      onPrint={onPrint}
      transaction={transaction}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <div className='row'>
        <div className='col-2 mb-3'>
          <DatePicker
            label='Data Inicial'
            required
            text={filtros.dtOcorrenciaIni}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtOcorrenciaIni) =>
              setFiltros({ ...filtros, dtOcorrenciaIni })
            }
          />
        </div>
        <div className='col-2 mb-3'>
          <DatePicker
            label='Data Final'
            required
            text={filtros.dtOcorrenciaFim}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtOcorrenciaFim) =>
              setFiltros({ ...filtros, dtOcorrenciaFim })
            }
          />
        </div>
        <div className='col-3 mb-3'>
          <Autocomplete
            label='Motorista'
            searchDataSource={onSearchPessoaMot}
            selectedItem={filtros.motorista}
            onSelectItem={(motorista) => {
              setFiltros({
                ...filtros,
                motorista,
                nrSeqPessoaMot: motorista.nrSeqPessoaMot,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            transaction={transaction}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            enableExcelExport
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
