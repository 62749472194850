export default class ExtratoGrupoCategoria {
  constructor(jsonObject = {}) {
    this.nrSeqExtratoGrupoCategoria = jsonObject.nrSeqExtratoGrupoCategoria;
    this.cdExtratoGrupoCategoria = jsonObject.cdExtratoGrupoCategoria;
    this.noExtratoGrupoCategoria = jsonObject.noExtratoGrupoCategoria;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgDebitar = jsonObject.flgDebitar;
  }

  toJson = () => JSON.stringify(this);
}
