export default class FornecedorIntegracao {
  constructor(jsonObject = {}) {
    this.nrSeqFornecedorIntegracao = jsonObject.nrSeqFornecedorIntegracao;
    this.nrSeqPessoaFor = jsonObject.nrSeqPessoaFor;
    this.noTipointegracao = jsonObject.noTipointegracao;
    this.noEndereco = jsonObject.noEndereco;
    this.noUsuario = jsonObject.noUsuario;
    this.noSenha = jsonObject.noSenha;
    this.nrPorta = jsonObject.nrPorta;
    this.noLayout = jsonObject.noLayout;
    this.noOperacao = jsonObject.noOperacao;
    this.noArquivo = jsonObject.noArquivo;
    this.noLocalOrigem = jsonObject.noLocalOrigem;
    this.noLocalDestino = jsonObject.noLocalDestino;
    this.flgAmbProducao = jsonObject.flgAmbProducao;
    this.fornecedor = jsonObject.fornecedor;
    this.modNfe = jsonObject.modNfe;
  }

  toJson = () => JSON.stringify(this);
}
