import { Empresa } from 'core/models/SEG';
import FormaPagamento from '../FIN/formaPagamento';
import TituloPagarTipoDocumento from '../FIN/tituloPagarTipoDocumento';

export default class PedidoCompraFatura {
  constructor(jsonObject = {}) {
    this.nrSeqPedidoCompraFatura = jsonObject.nrSeqPedidoCompraFatura;
    this.nrSeqPedidoCompra = jsonObject.nrSeqPedidoCompra;
    this.nrSeqFatura = jsonObject.nrSeqFatura;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.vlrParcela = jsonObject.vlrParcela;
    this.nrSeqTituloPagar = jsonObject.nrSeqTituloPagar;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqOrdem = jsonObject.nrSeqOrdem;
    this.nrSeqNfv = jsonObject.nrSeqNfv;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.dtVencimento = jsonObject.dtVencimento;
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.empresa = jsonObject.empresa ?? new Empresa();
    this.flgPermiteAlterar = jsonObject.flgPermiteAlterar;
    this.tituloPagarTipoDocumento = new TituloPagarTipoDocumento(
      jsonObject.tituloPagarTipoDocumento ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
