import React, { useRef, useEffect } from 'react';
import { GridView } from 'ui/components';
import {
  ColumnDataTypes,
  ColumnTypes,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/enums';

import { NfvAvulsaItem } from 'core/models/VEN';

interface Props {
  data: NfvAvulsaItem[];
  modalShow: (e: any) => void;
}

const MovimentoSaidaStep = ({ data, modalShow }: Props) => {
  const gridView = useRef<any>();

  useEffect(() => {
    if (gridView.current && gridView && data) {
      gridView.current.setDataSource(data);
    }
  }, []);

  const onShowItensSaida = (e: NfvAvulsaItem) => {
    modalShow(e);
  };

  const columns = [
    { key: 'nrSeqProduto', title: 'nrSeqProduto', visible: false },
    {
      key: 'produto.noProduto',
      title: 'Produto',
    },
    {
      key: 'produto.produtoUnidadeMedida.cdProdutoUnidadeMedida',
      title: 'Unidade Medida',
    },
    {
      key: 'qtdeEnvio',
      title: 'Quantidade Saída',
      format: ColumnDataTypes.Decimal,
    },
    {
      key: 'qtdeRetorno',
      title: 'Quantidade Retorno',
      format: ColumnDataTypes.Decimal,
    },
    {
      key: 'qtdeSaldoItem',
      title: 'Quantidade Saldo',
      format: ColumnDataTypes.Decimal,
    },
    {
      key: 'vlrItNfv',
      title: 'Unitário',
      format: ColumnDataTypes.Decimal,
    },
    { key: 'vlrTotNfv', title: 'Total', format: ColumnDataTypes.Decimal },
    {
      key: 'nrSeqProduto',
      type: ColumnTypes.Button,
      theme: Theme.Success,
      icon: 'eye',
      size: BootstrapSizes.Small,
      onClick: (e: NfvAvulsaItem) => onShowItensSaida(e),
      sortable: false,
      tooltip: 'Visualizar',
      tooltipDirection: 'bottom',
      title: 'Visualizar',
    },
  ];

  return (
    <div className='row mb-3 align-items-end'>
      <div className='col'>
        <GridView
          ref={gridView}
          // @ts-expect-error
          className='table-striped table-hover table-bordered table-sm'
          dataColumns={columns}
          offlineData
          showPagination={false}
          showSelectSizes={false}
        />
      </div>
    </div>
  );
};

export default MovimentoSaidaStep;
