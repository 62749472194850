export default class AssociadoPlanoConvenio {
  constructor(jsonObject = {}) {
    this.nrSeqAssocPlanoConv = jsonObject.nrSeqAssocPlanoConv;
    this.nrSeqPessoaAss = jsonObject.nrSeqPessoaAss;
    this.nrSeqPlanoConvenio = jsonObject.nrSeqPlanoConvenio;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqPessoaInformacaoBancaria =
      jsonObject.nrSeqPessoaInformacaoBancaria;
    this.dtInicioVigencia = jsonObject.dtInicioVigencia;
    this.dtFimVigencia = jsonObject.dtFimVigencia;
    this.flgFaturaConsolidador = jsonObject.flgFaturaConsolidador;
    this.vlrAjuste = jsonObject.vlrAjuste;

    this.associado = jsonObject.associado ?? {};
    this.formaPagamento = jsonObject.formaPagamento ?? {};
    this.planoConvenio = jsonObject.planoConvenio ?? {};
    this.pessoaInformacaoBancaria = jsonObject.pessoaInformacaoBancaria ?? {};
  }

  toJson = () => JSON.stringify(this);
}
