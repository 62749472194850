import { TributacaoIcms } from 'core/models/FIS';
import { Estado, Pais } from 'core/models/SEG';

export default class GrupoIcmsEstado {
  constructor(jsonObject = {}) {
    this.nrSeqEstadoOrigem = jsonObject.nrSeqEstadoOrigem;
    this.nrSeqEstadoDestino = jsonObject.nrSeqEstadoDestino;
    this.nrSeqTributacaoIcms = jsonObject.nrSeqTributacaoIcms;
    this.aliquotaIcms = jsonObject.aliquotaIcms;
    this.baseCalculoIcms = jsonObject.baseCalculoIcms;
    this.nrSeqGrupoIcms = jsonObject.nrSeqGrupoIcms;
    this.flgParcial = jsonObject.flgParcial;
    this.noObservacoes = jsonObject.noObservacoes;
    this.flgNaoDestacarIcms = jsonObject.flgNaoDestacarIcms;
    this.flgIcmsDesonerado = jsonObject.flgIcmsDesonerado;
    this.baseCalculoIcmsDesonerado = jsonObject.baseCalculoIcmsDesonerado;
    this.aliquotaIcmsDesonerado = jsonObject.aliquotaIcmsDesonerado;
    this.aliquotaFCP = jsonObject.aliquotaFCP;
    this.baseCalculoFCP = jsonObject.baseCalculoFCP;
    this.fglAliquotaIcms = jsonObject.fglAliquotaIcms;
    this.aliquotaIcmsImportados = jsonObject.aliquotaIcmsImportados;
    this.estadoOrigem = new Estado(jsonObject.estadoOrigem ?? {});
    this.estadoDestino = new Estado(jsonObject.estadoDestino ?? {});
    this.paisOrigem = new Pais(jsonObject.paisOrigem ?? {});
    this.paisDestino = new Pais(jsonObject.paisDestino ?? {});
    this.tributacaoIcms = new TributacaoIcms(jsonObject.tributacaoIcms ?? {});
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
