export default class TipoTransportadora {
  constructor(jsonObject = {}) {
    this.nrSeqTipoTransportadora = jsonObject.nrSeqTipoTransportadora;
    this.noSigla = jsonObject.noSigla;
    this.noNome = jsonObject.noNome;
    this.flgPessoaFisica = jsonObject.flgPessoaFisica;
  }

  toJson = () => JSON.stringify(this);
}
