import qs from 'qs';
import Pagination from 'core/models/pagination';
import NfItem from 'core/models/COP/nfItem';
import api from '../api';

const baseURL = `/COP/NfItem`;

export const getNfItemList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    data: value?.map((json) => new NfItem(json)) ?? [],
  };
};

export const getNfItemPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new NfItem(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getHistoricoCompras = async (id) => {
  const response = await api.get(`${baseURL}/BuscaHistorico`, {
    params: { id },
  });
  const { value } = response.data ?? [];
  return value ?? [];
};

export const getNfItem = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new NfItem(value) : {};
};

export const saveNfItem = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteNfItem = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printNfItem = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelNfItem = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllNfItem = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const saveProdutoNf = async (data) => {
  const response = await api.post(`${baseURL}/SaveProdutoNf`, data);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ? new NfItem(value) : {},
  };
};

export const getLastPurchaseValue = async (filters) => {
  const response = await api.get(`${baseURL}/GetLastPurchaseValue`, {
    params: filters,
  });
  const { value } = response.data ?? {};

  return value;
};

export const getNfItemListForManutencaoVeiculo = async (filters) => {
  const response = await api.get(`${baseURL}/SearchForManutencaoVeiculo`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new NfItem(json)) ?? [],
  };
};

export const getNfItemByproduto = async (id) => {
  const response = await api.get(`${baseURL}/SearchUltimaCompra`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new NfItem(value) : {};
};
