import React, { useEffect, useRef, useState } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  deleteAllPedidoAparelho,
  getPedidoAparelhoPagined,
} from 'core/services/TEL/pedidoAparelho';
import {
  Autocomplete,
  CSDSelPage,
  DatePicker,
  GridView,
  RadioButton,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getModeloaparelhocelularList } from 'core/services/TEL/modeloAparelhoCelular';

const StatusPedido = [
  { text: 'Entregue', value: 'E' },
  { text: 'Pendente', value: 'P' },
  { text: 'Todos', value: 'T' },
];

const columns = [
  { key: 'nrSeqPedidoAparelho', type: GridView.ColumnTypes.Checkbox },
  { key: 'noTituloPedido', title: 'Titulo' },
  { key: 'noDescricaoPedido', title: 'Descrição' },
  { key: 'nrAditivo', title: 'Aditivo' },
  { key: 'nrCtPrincipal', title: 'Conta Principal' },
  { key: 'flgStatusStr', title: 'Status' },
  { key: 'dtPedido', title: 'Data Pedido', format: GridView.DataTypes.Date },
  { key: 'dtEntrega', title: 'Data Entrega', format: GridView.DataTypes.Date },
  { key: 'vlrTotalPedido', title: 'Vlr Total Pedido' },
];

export default function PedidoAparelho({ transaction, onOpenPage, isActive }) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({ flgStatus: 'T' });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getPedidoAparelhoPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onSearchModeloAparelho = async (e) => {
    const {
      status,
      message: msg,
      data: modeloAparelhos,
    } = await getModeloaparelhocelularList({
      noModeloAparelho: e,
      nrSeqMarcaAparelho: filtros.marcaAparelhoCelular
        ? filtros.marcaAparelhoCelular.nrSeqMarcaAparelho
        : 0,
    });
    if (msg) onSetMessage(status, msg);
    return modeloAparelhos;
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'PedidoAparelho/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqPedidoAparelho = sourceRow.find(
      (e) => e.key === 'nrSeqPedidoAparelho'
    );
    onOpenMaintenance(nrSeqPedidoAparelho.value);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllPedidoAparelho(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Pedido Aparelho'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Modelo Aparelho'
            searchDataSource={onSearchModeloAparelho}
            selectedItem={filtros.modeloAparelhoCelular}
            onSelectItem={(modeloAparelhoCelular) => {
              setFiltros({
                ...filtros,
                modeloAparelhoCelular,
                nrSeqModeloAparelho: modeloAparelhoCelular.nrSeqModeloAparelho,
              });
            }}
            dataSourceTextProperty='noModeloAparelho'
          />
        </div>

        <div className='col-auto'>
          <DatePicker
            label='De Data Pedido'
            text={filtros.dtPedidoInicio}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtPedidoInicio) =>
              setFiltros({ ...filtros, dtPedidoInicio })
            }
          />
        </div>
        <div className='col-auto'>
          <DatePicker
            label='Ate Data Pedido'
            text={filtros.dtPedidoFinal}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtPedidoFinal) =>
              setFiltros({ ...filtros, dtPedidoFinal })
            }
          />
        </div>
        <div className='col-auto'>
          <RadioButton
            outline
            label='Status'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={StatusPedido}
            selectedButton={filtros.flgStatus}
            onChange={(flgStatus) =>
              setFiltros({
                ...filtros,
                flgStatus,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-auto'>
          <DatePicker
            label='De Data Entrega'
            text={filtros.dtEntregaInicio}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtEntregaInicio) =>
              setFiltros({ ...filtros, dtEntregaInicio })
            }
          />
        </div>
        <div className='col-auto'>
          <DatePicker
            label='Ate Data Entrega'
            text={filtros.dtEntregaFinal}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtEntregaFinal) =>
              setFiltros({ ...filtros, dtEntregaFinal })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            enableExcelExport
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
