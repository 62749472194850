import qs from 'qs';
import Pagination from 'core/models/pagination';
import ProdutoOrigem from 'core/models/EST/produtoOrigem';
import api from '../api';

const baseURL = `/EST/ProdutoOrigem`;

export const getProdutoOrigemList = async (filters: any) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new ProdutoOrigem(json)) ?? [],
  };
};

export const getProdutoOrigemPagined = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new ProdutoOrigem(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getProdutoOrigem = async (id: number) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ProdutoOrigem(value) : {};
};

export const saveProdutoOrigem = async (data: any) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteProdutoOrigem = async (id: number) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printProdutoOrigem = async (filters: any) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelProdutoOrigem = async (filters: any) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllProdutoOrigem = async (ids: Array<number>) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getProdutoOrigemAutoComplete = async (filters: any) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new ProdutoOrigem(json)) ?? [],
  };
};
