import { TipoDocumentoPessoa } from 'core/models/SEG';

export default class VeiculoDocumento {
  constructor(jsonObject = {}) {
    this.nrSeqVeiculoDocumento = jsonObject.nrSeqVeiculoDocumento;
    this.nrSeqTipoDocumentoPessoa = jsonObject.nrSeqTipoDocumentoPessoa;
    this.noImagem = jsonObject.noImagem;
    this.tipo = jsonObject.tipo;
    this.tamanho = jsonObject.tamanho;
    this.obs = jsonObject.obs;
    this.nrDocumento = jsonObject.nrDocumento;
    this.dtFimValidade = jsonObject.dtFimValidade;
    this.dtIniValidade = jsonObject.dtIniValidade;
    this.dtCad = jsonObject.dtCad;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.noPessoa = jsonObject.noPessoa;
    this.celular = jsonObject.celular;
    this.placa = jsonObject.placa;

    this.tipoDocumentoPessoa = new TipoDocumentoPessoa(
      jsonObject.tipoDocumentoPessoa ?? {}
    );
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
