export enum ColumnTypes {
  Label,
  Textbox,
  Checkbox,
  Button,
  Group,
  GroupColumn,
}

export enum ColumnDataTypes {
  Text,
  Decimal,
  Integer,
  Boolean,
  Money,
  Date,
  DateTime,
  Time,
  Inch,
  DateHour,
}

export enum Theme {
  Primary = 'primary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Light = 'light',
  Secondary = 'secondary',
  Dark = 'dark',
  Inactive = 'inactive',
}

export enum ResponseStatus {
  Success,
  Error,
  Warning,
}

export enum MaskTypes {
  Competence,
  Currency,
  Decimal,
  Integer,
  Date,
  CPF,
  CNPJ,
  CellPhone,
  Time,
  DecimalCustom,
  TimeHM,
  Inch,
}

export enum BootstrapSizes {
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'xl',
}

export enum FormDataType {
  Json = 'json',
  File = 'file',
  Files = 'files',
  Extension = 'extension',
}

export enum JustifyContent {
  Start = 'justify-content-start',
  End = 'justify-content-end',
}

export enum StatusGrid {
  Inserir = 'Inserir',
  Remover = 'Remover',
  Alterar = 'Alterar',
}
