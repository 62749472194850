export default class Profissao {
  noProfissao: string;

  cdProfissao: string;

  nrSeqProfissao: number;

  constructor(jsonObject: Profissao) {
    this.noProfissao = jsonObject.noProfissao;
    this.cdProfissao = jsonObject.cdProfissao;
    this.nrSeqProfissao = jsonObject.nrSeqProfissao;
  }

  toJson = () => JSON.stringify(this);
}
