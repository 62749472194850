import Estado from 'core/models/SEG/estado';
import RotaTransporte from 'core/models/FRO/rotaTransporte';
import TipoCargaMdfe from 'core/models/FRO/tipoCargaMdfe';
import Veiculo from 'core/models/FRO/veiculo';
import Usuario from 'core/models/SEG/usuario';
import Empresa from 'core/models/SEG/empresa';
import Cidade from 'core/models/SEG/cidade';
import Nfv from 'core/models/VEN/nfv';
import Motorista from 'core/models/FRO/motorista';

export default class ManifestoCTE {
  constructor(jsonObject = {}) {
    this.nrSeqManifestoCte = jsonObject.nrSeqManifestoCte;
    this.nrSeqViagem = jsonObject.nrSeqViagem;
    this.cdManifestoCte = jsonObject.cdManifestoCte;
    this.cdSerieManifesto = jsonObject.cdSerieManifesto;
    this.dtEmissao = jsonObject.dtEmissao;
    this.dtCad = jsonObject.dtCad;
    this.noInfCpl = jsonObject.noInfCpl;
    this.noInfAdFisco = jsonObject.noInfAdFisco;
    this.vlrFrete = jsonObject.vlrFrete;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.nrSeqUsuarioEncerramento = jsonObject.nrSeqUsuarioEncerramento;
    this.qntCarga = jsonObject.qntCarga;
    this.dtEncerramento = jsonObject.dtEncerramento;
    this.nrSeqEstadoOri = jsonObject.nrSeqEstadoOri;
    this.nrSeqEstadoDest = jsonObject.nrSeqEstadoDest;
    this.nrSeqRotaTransporte = jsonObject.nrSeqRotaTransporte;
    this.nrSeqManifestoMdfe = jsonObject.nrSeqManifestoMdfe;
    this.nrSeqCidadeEncerramento = jsonObject.nrSeqCidadeEncerramento;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.nrCiot = jsonObject.nrCiot;
    this.nrSeqTipoCargaMdfe = jsonObject.nrSeqTipoCargaMdfe;
    this.noValePedagioCnpjForn = jsonObject.noValePedagioCnpjForn;
    this.noValePedagioCnpjPag = jsonObject.noValePedagioCnpjPag;
    this.noValePedagioNumCompra = jsonObject.noValePedagioNumCompra;
    this.vlrValePedagio = jsonObject.vlrValePedagio;
    this.diasEmAberto = jsonObject.diasEmAberto;
    this.flgTicketNaoRecebido = jsonObject.flgTicketNaoRecebido;
    this.flgContingencia = jsonObject.flgContingencia;

    // #region Propriedades Axuliares
    this.motivoCancelamento = jsonObject.motivoCancelamento;
    this.flgMostraEfetivar = jsonObject.flgMostraEfetivar;
    this.flgMostraImprimir = jsonObject.flgMostraImprimir;
    this.flgMostrarTicket = jsonObject.flgMostrarTicket;
    this.flgMostraExcluir = jsonObject.flgMostraExcluir;
    this.flgMostraEncerrar = jsonObject.flgMostraEncerrar;
    this.flgMostraCancelar = jsonObject.flgMostraCancelar;
    this.flgMostraContingencia = jsonObject.flgMostraContingencia;
    this.nrNotas = jsonObject.nrNotas;
    this.statusMdfe = jsonObject.statusMdfe;
    this.statusMdfeString = jsonObject.statusMdfeString;
    this.colorBackgroundStatus = jsonObject.colorBackgroundStatus;
    this.nrConhecimentos = jsonObject.nrConhecimentos;
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.rotaTransporte = new RotaTransporte(jsonObject.rotaTransporte ?? {});
    this.tipoCarga = new TipoCargaMdfe(jsonObject.tipoCarga ?? {});
    this.estadoOrigem = new Estado(jsonObject.estadoOrigem ?? {});
    this.estadoDestino = new Estado(jsonObject.estadoDestino ?? {});
    this.cidadeCarregamento = new Cidade(jsonObject.cidadeCarregamento ?? {});
    this.cidadeDescarregamento = new Cidade(
      jsonObject.cidadeDescarregamento ?? {}
    );
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    this.usuarioCad = new Usuario(jsonObject.usuarioCad ?? {});
    this.usuarioEncerramento = new Usuario(
      jsonObject.usuarioEncerramento ?? {}
    );
    this.notas = jsonObject.notas
      ? jsonObject.notas.map((item) => new Nfv(item ?? {}))
      : [];
    this.motorista = new Motorista(jsonObject.motorista ?? {});
    // #endregion

    this.xmlEnvio = jsonObject.xmlEnvio;
  }

  toJson = () => JSON.stringify(this);
}
