import { Empresa } from '../SEG';
import Modeloaparelhocelular from './modeloAparelhoCelular';
import Operadora from './operadora';
import MarcaaparelhoCelular from './marcaAparelhoCelular';
import { Fornecedor } from '../FIN';
import AparelhoStatus from './aparelhoStatus';

class Aparelho {
  constructor(jsonObject = {}) {
    this.nrSeqAparelho = jsonObject.nrSeqAparelho;
    this.nrSeqPedidoAparelho = jsonObject.nrSeqPedidoAparelho;
    this.noTituloPedido = jsonObject.noTituloPedido;
    this.cdAparelho = jsonObject.cdAparelho;
    this.nrImei = jsonObject.nrImei;
    this.nrSeqPessoaFor = jsonObject.nrSeqPessoaFor;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.noAparelho = jsonObject.noAparelho;
    this.nrSeqPedidoAparelhoModelo = jsonObject.nrSeqPedidoAparelhoModelo;
    this.flgStatus = jsonObject.flgStatus;
    this.flgStatusStr = jsonObject.flgStatusStr;
    this.nrMesesGarantia = jsonObject.nrMesesGarantia;
    this.observacao = jsonObject.observacao;
    this.nrSeqModeloAparelho = jsonObject.nrSeqModeloAparelho;
    this.nrSeqMarcaAparelho = jsonObject.nrSeqMarcaAparelho;
    this.flgTipoAparelhoStr = jsonObject.flgTipoAparelhoStr;
    this.flgTipoAparelho = jsonObject.flgTipoAparelho;
    this.flgFormaAquisicao = jsonObject.flgFormaAquisicao;
    this.flgFormaAquisicaoStr = jsonObject.flgFormaAquisicaoStr;
    this.vlrPrecoCusto = jsonObject.vlrPrecoCusto;
    this.nrSerial = jsonObject.nrSerial;
    this.noClienteConta = jsonObject.noClienteConta;
    this.nrNotaFiscal = jsonObject.nrNotaFiscal;
    this.dtNotaFiscal = jsonObject.dtNotaFiscal;
    this.noItensInclusos = jsonObject.noItensInclusos;
    this.modeloAparelhoCelular = new Modeloaparelhocelular(
      jsonObject.modeloAparelhoCelular ?? {}
    );
    this.nrSeqAparelhoStatus = jsonObject.nrSeqAparelhoStatus;
    this.aparelhoStatus = new AparelhoStatus(jsonObject.aparelhoStatus ?? {});
    this.marcaAparelhoCelular = new MarcaaparelhoCelular(
      jsonObject.marcaAparelhoCelular ?? {}
    );
    this.fornecedor = new Fornecedor(jsonObject.fornecedor ?? {});
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.nrSeqOperadora = jsonObject.nrSeqOperadora;
    this.operadora = new Operadora(jsonObject.operadora ?? {});
  }

  toJson = () => JSON.stringify(this);
}

export default Aparelho;
