import React from 'react';
import { Modal } from 'ui/components';

export default function ModalStatus({ data = { phone: {} }, show, onClose }) {
  return (
    <Modal show={show} title='Detalhes do telefone conectado' onClose={onClose}>
      <Modal.Body>
        <div className='row mb-3'>
          <div className='col-auto'>
            <b>Nome: </b>
            <span>{data.pushname}</span>
          </div>
          <div className='col-auto'>
            <b>Sistema Op.: </b>
            <span>{data.platform}</span>
          </div>
          <div className='col-auto'>
            <b>Versão Sistema: </b>
            <span>{data.phone.osVersion}</span>
          </div>
          <div className='row'>
            <div className='col-auto'>
              <b>ID: </b>
              <span>{data.wid}</span>
            </div>
            <div className='col-auto'>
              <b>Marca: </b>
              <span>{data.phone.deviceManufacturer}</span>
            </div>
            <div className='col-auto'>
              <b>Modelo: </b>
              <span>{data.phone.deviceModel}</span>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
