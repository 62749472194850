import React, { forwardRef, useState, useCallback, useEffect } from 'react';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import {
  RadioButton,
  Textbox,
  Autocomplete,
  DatePicker,
  Button,
  Switch,
  Textarea,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { getPaisAutoComplete } from 'core/services/SEG/pais';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
import { getCidadeAutoComplete, getCep } from 'core/services/SEG/cidade';
import { Pais, Estado, Cidade, Endereco } from 'core/models/SEG';

import { getDominioGeologicoAutoComplete } from 'core/services/POC/dominioGeologico';
import { getPerfuratrizAutoComplete } from 'core/services/POC/perfuratriz';
import { getStatusPocoAutoComplete } from 'core/services/POC/pocPoco';

import ModalLocalizacao from './modalLocalizacao';

const TipoPoco = [
  {
    text: 'Poço',
    value: 'P',
  },
  {
    text: 'Piezômetro ',
    value: 'I',
  },
];

const ClassificacaoPoco = [
  {
    text: 'Parcialmente Revestido',
    value: 'PR',
  },
  {
    text: 'Parcialmente Revestido com Filtro',
    value: 'PF',
  },
  {
    text: 'Totalmente Revestido',
    value: 'TR',
  },
];

const CadastroPoco = forwardRef(
  ({ onOpenTransaction, findTransaction, setData, data, onSetLoading }) => {
    const [pais, setPais] = useState(new Pais());
    const [estado, setEstado] = useState(new Estado());
    const [cidade, setCidade] = useState(new Cidade());
    const [endereco, setEndereco] = useState(new Endereco());
    const [showLocalizacao, setShowLocalizacao] = useState(false);

    const [dataCadastroPoco, setDataCadastroPoco] = useState(data);

    const onSearchDominioGeologico = async (e) => {
      const { dominioGeologico } = await getDominioGeologicoAutoComplete({
        noPocDominioGeologico: e,
      });
      return dominioGeologico;
    };

    const onSearchPerfuratriz = async (e) => {
      const { perfuratriz } = await getPerfuratrizAutoComplete({
        noPocPerfuratriz: e,
      });
      return perfuratriz;
    };

    const onSearchStatusPoco = async (e) => {
      const { statuspoco } = await getStatusPocoAutoComplete({
        noStatusPoco: e,
      });

      return statuspoco;
    };

    const onSearchPais = async (e) => {
      const { paises } = await getPaisAutoComplete({
        noPais: e,
      });
      return paises;
    };

    const onSearchEstado = async (e) => {
      const { estados } = await getEstadoAutoComplete({
        noEstado: e,
        nrSeqPais: pais.nrSeqPais ?? new Pais(),
      });
      return estados;
    };

    const onSearchCidade = async (e) => {
      const { cidades } = await getCidadeAutoComplete({
        noCidade: e,
        nrSeqEstado: estado.nrSeqEstado ?? new Estado(),
      });
      return cidades;
    };

    const onSearchCep = async () => {
      onSetLoading(true);
      if (endereco.Cep && endereco.Cep.length > 4) {
        const cid = await getCep({
          cep: endereco.Cep,
        });

        setCidade(cid.cidade);
        setEstado(cid.cidade.estado);
        setPais(cid.cidade.estado.pais);
        setEndereco(cid);

        const end = new Endereco({
          cep: cid.cep,
          bairro: cid.bairro,
          noRua: cid.noRua,
          cidade: cid.cidade,
          nrSeqCidade: cid.cidade.nrSeqCidade,
          nrSeqEndereco: dataCadastroPoco.nrSeqEndereco,
        });

        setDataCadastroPoco({ ...dataCadastroPoco, endereco: end });
        dataCadastroPoco.endereco = end;

        setData({ ...dataCadastroPoco, endereco: end });
      }
      onSetLoading(false);
      return new Endereco();
    };

    const load = useCallback(async () => {
      dataCadastroPoco.endereco = data.endereco ?? new Endereco({});
      dataCadastroPoco.statusPoco = {
        noStatusPoco: data.noStatusPoco,
        nrSeqStatusPoco: data.nrSeqStatusPoco,
      };

      setDataCadastroPoco({ ...dataCadastroPoco });
      setEndereco(data.endereco);
      setCidade(data.endereco.cidade);
      setEstado(data.endereco.cidade.estado);
      setPais(data.endereco.cidade.estado.pais);
    });

    useEffect(() => {
      (async function func() {
        await load();
      })();
    }, []);

    function setCopiaEnderecoCliente() {
      dataCadastroPoco.endereco = data.cliente.endereco;
      dataCadastroPoco.flgEnderecoCliente = true;

      setDataCadastroPoco({
        ...dataCadastroPoco,
      });

      setData({
        ...dataCadastroPoco,
      });
      setEndereco(data.cliente.endereco);
      setCidade(data.cliente.endereco.cidade);
      setEstado(data.cliente.endereco.cidade.estado);
      setPais(data.cliente.endereco.cidade.estado.pais);
    }

    return (
      <div>
        <div className='row mb-3'>
          <div className='display-8'>2 - Obra/Poço</div>
        </div>
        <div className='card'>
          <div className='card-body'>
            <div className='row mb-3'>
              <div className='row mb-3'>
                <div className='col'>
                  <div className='card'>
                    <div className='card-body'>
                      <div className='row mb-3'>
                        <div className='col-1'>
                          <Textbox
                            label='Código'
                            text={data.cdPocPoco ?? ' '}
                            readOnly
                          />
                        </div>
                        <div className='col-auto'>
                          <RadioButton
                            label='Tipo'
                            outline
                            size={BootstrapSizes.Small}
                            theme={Theme.Primary}
                            buttons={TipoPoco}
                            selectedButton={dataCadastroPoco.flgTipoPoco}
                            onChange={(flgTipoPoco) => {
                              dataCadastroPoco.flgTipoPoco = flgTipoPoco;
                              setDataCadastroPoco({
                                ...dataCadastroPoco,
                              });
                              setData({
                                ...dataCadastroPoco,
                              });
                            }}
                          />
                        </div>
                        <div className='col-auto'>
                          <RadioButton
                            label='Classificação'
                            outline
                            size={BootstrapSizes.Small}
                            theme={Theme.Primary}
                            buttons={ClassificacaoPoco}
                            selectedButton={
                              dataCadastroPoco.flgClassificacaoPoco
                            }
                            onChange={(flgClassificacaoPoco) => {
                              dataCadastroPoco.flgClassificacaoPoco = flgClassificacaoPoco;
                              setDataCadastroPoco({
                                ...dataCadastroPoco,
                              });
                              setData({
                                ...dataCadastroPoco,
                              });
                            }}
                          />
                        </div>

                        <div className='col'>
                          <Autocomplete
                            label='Status'
                            searchDataSource={onSearchStatusPoco}
                            selectedItem={dataCadastroPoco.statusPoco}
                            onSelectItem={(statuspoco) => {
                              dataCadastroPoco.statusPoco = statuspoco;
                              dataCadastroPoco.noStatusPoco =
                                statuspoco.noStatusPoco;
                              dataCadastroPoco.flgStatusPoco =
                                statuspoco.flgStatusPoco;

                              setData({
                                ...dataCadastroPoco,
                              });
                            }}
                            dataSourceTextProperty='noStatusPoco'
                          />
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-auto'>
                          <Switch
                            formControl
                            label='Perfurado pelo Hidrogeo'
                            checked={dataCadastroPoco.flgPocoProprio}
                            onChange={(flgPocoProprio) => {
                              dataCadastroPoco.flgPocoProprio = flgPocoProprio;
                              dataCadastroPoco.flgTercAnaliseAgua = false;
                              dataCadastroPoco.flgTercAprofundamento = false;
                              dataCadastroPoco.flgTercDesenvolvimento = false;
                              dataCadastroPoco.flgTercEnsaioBomb = false;
                              dataCadastroPoco.flgTercManutencao = false;
                              dataCadastroPoco.flgTercRevestimento = false;
                              dataCadastroPoco.flgTercOutro = false;
                              dataCadastroPoco.noTercOutros = '';
                              dataCadastroPoco.flgHidroColetadoHidro = false;
                              dataCadastroPoco.flgHidroInfoProp = false;
                              dataCadastroPoco.flgHidroRelTecPerf = false;
                              dataCadastroPoco.flgHidroVideoEndoscopia = false;
                              dataCadastroPoco.flgHidroOutros = false;
                              dataCadastroPoco.noHidroOutros = '';
                              dataCadastroPoco.noEmpresaTerceira = '';
                              setDataCadastroPoco({
                                ...dataCadastroPoco,
                              });
                              setData({
                                ...dataCadastroPoco,
                              });
                            }}
                          />
                        </div>
                        <div className='col-3'>
                          <Textbox
                            label='Empresa'
                            readOnly={dataCadastroPoco.flgPocoProprio}
                            text={dataCadastroPoco.noEmpresaTerceira ?? ' '}
                            maxLength={50}
                            onChangedValue={(noEmpresaTerceira) => {
                              dataCadastroPoco.noEmpresaTerceira = noEmpresaTerceira;
                              setData({
                                ...dataCadastroPoco,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <div className='card'>
                    <div className='card-body'>
                      <div className='row mb-3'>
                        <div className='col'>
                          <div className='card'>
                            <div className='card-body'>
                              <div className='row mb-3'>
                                <div className='col'>
                                  <DatePicker
                                    label='Data de Lançamento'
                                    text={dataCadastroPoco.dtCadastro}
                                    maxLength={10}
                                    mask={MaskTypes.Date}
                                    onChange={(dtCadastro) => {
                                      dataCadastroPoco.dtCadastro = dtCadastro;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                  />
                                </div>
                                <div className='col'>
                                  <DatePicker
                                    label='Data de Inicio'
                                    text={dataCadastroPoco.dtInicioPoco}
                                    maxLength={10}
                                    mask={MaskTypes.Date}
                                    onChange={(dtInicioPoco) => {
                                      dataCadastroPoco.dtInicioPoco = dtInicioPoco;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                  />
                                </div>
                                <div className='col'>
                                  <DatePicker
                                    label='Data de Termino'
                                    text={dataCadastroPoco.dtFimPoco}
                                    maxLength={10}
                                    mask={MaskTypes.Date}
                                    onChange={(dtFimPoco) => {
                                      dataCadastroPoco.dtFimPoco = dtFimPoco;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className='row mb-3'>
                                <div className='col'>
                                  <Autocomplete
                                    label='Domínio Geológico'
                                    searchDataSource={onSearchDominioGeologico}
                                    selectedItem={
                                      dataCadastroPoco.dominioGeologico
                                    }
                                    findTransaction={findTransaction}
                                    onOpenTransaction={onOpenTransaction}
                                    transactionToOpen={
                                      TransacationCodes.DominioGeologico
                                    }
                                    nrseqTransactionToOpen='nrSeqPocDominioGeologico'
                                    onSelectItem={(dominioGeologico) => {
                                      dataCadastroPoco.dominioGeologico = dominioGeologico;
                                      dataCadastroPoco.nrSeqPocDominioGeologico =
                                        dominioGeologico.nrSeqPocDominioGeologico;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                    dataSourceTextProperty='noPocDominioGeologico'
                                  />
                                </div>
                                <div className='col-auto'>
                                  <Textbox
                                    label='Profundidade (mts)'
                                    text={dataCadastroPoco.nrProfundidade ?? 0}
                                    maxLength={8}
                                    mask={MaskTypes.DecimalCustom}
                                    decimalPlaces={2}
                                    onChangedValue={(nrProfundidade) => {
                                      dataCadastroPoco.nrProfundidade = nrProfundidade;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className='row mb-3'>
                                <div className='col'>
                                  <Autocomplete
                                    label='Perfuratriz'
                                    searchDataSource={onSearchPerfuratriz}
                                    selectedItem={dataCadastroPoco.perfuratriz}
                                    findTransaction={findTransaction}
                                    onOpenTransaction={onOpenTransaction}
                                    transactionToOpen={
                                      TransacationCodes.Perfuratriz
                                    }
                                    nrseqTransactionToOpen='nrSeqPocPerfuratriz'
                                    onSelectItem={(perfuratriz) => {
                                      dataCadastroPoco.perfuratriz = perfuratriz;
                                      dataCadastroPoco.nrSeqPocPerfuratriz =
                                        perfuratriz.nrSeqPocPerfuratriz;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                    dataSourceTextProperty='noPocPerfuratriz'
                                  />
                                </div>
                                <div className='col-auto'>
                                  <Textbox
                                    label='* Diametro Útil (pol)'
                                    text={
                                      dataCadastroPoco.nrOdiametroutil ?? ''
                                    }
                                    onChangedValue={(nrOdiametroutil) => {
                                      dataCadastroPoco.nrOdiametroutil = nrOdiametroutil;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className='row mb-3'>
                                <div className='col'>
                                  <Textbox
                                    label='Método de Perfuração'
                                    text={data.noMetodoPerfuracao}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-4'>
                          <div className='card'>
                            <div className='card-body'>
                              <div className='row mb-4'>
                                <div className='col-6'>
                                  <Textbox
                                    label='Zona UTM'
                                    text={dataCadastroPoco.noZonaUtm}
                                    maxLength={6}
                                    onChangedValue={(noZonaUtm) => {
                                      dataCadastroPoco.noZonaUtm = noZonaUtm;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <div className='col-6'>
                                  <Textbox
                                    label='UTM N'
                                    text={dataCadastroPoco.utmLongitude ?? ' '}
                                    maxLength={10}
                                    onChangedValue={(utmLongitude) => {
                                      dataCadastroPoco.utmLongitude = utmLongitude;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <div className='col-6'>
                                  <Textbox
                                    label='UTM E'
                                    text={dataCadastroPoco.utmLatitude ?? ' '}
                                    maxLength={10}
                                    onChangedValue={(utmLatitude) => {
                                      dataCadastroPoco.utmLatitude = utmLatitude;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className='row mb-3'>
                                <div className='col'>
                                  <Textbox
                                    label='Cota (m)'
                                    text={dataCadastroPoco.nrCotaMetros ?? 0}
                                    maxLength={8}
                                    mask={MaskTypes.DecimalCustom}
                                    decimalPlaces={2}
                                    onChangedValue={(nrCotaMetros) => {
                                      dataCadastroPoco.nrCotaMetros = nrCotaMetros;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col'>
                          <Textarea
                            label='Identificação do Poço'
                            text={dataCadastroPoco.noPocPocoObsIdentificacao}
                            onChangedValue={(noPocPocoObsIdentificacao) => {
                              dataCadastroPoco.noPocPocoObsIdentificacao = noPocPocoObsIdentificacao;
                              setData({
                                ...dataCadastroPoco,
                              });
                            }}
                            maxLength={500}
                          />
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col'>
                          <Textarea
                            label='Observação'
                            text={dataCadastroPoco.noPocPocoObs}
                            onChangedValue={(noPocPocoObs) => {
                              dataCadastroPoco.noPocPocoObs = noPocPocoObs;
                              setData({
                                ...dataCadastroPoco,
                              });
                            }}
                            maxLength={500}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!dataCadastroPoco.flgPocoProprio && (
              <div className='row mb-3'>
                <div className='bg text-black bg-gradient rounded-2 p-3'>
                  <div className='row mb-3'>
                    <div className='display-8'>Dados Obtidos Hidrogeo</div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col'>
                      <div className='card'>
                        <div className='card-body'>
                          <div className='row'>
                            <div className='col-8'>
                              <div className='row mb-3'>
                                <div className='col'>
                                  <Switch
                                    formControl
                                    label='Coletado pela Hidrogeo'
                                    checked={
                                      dataCadastroPoco.flgHidroColetadoHidro
                                    }
                                    onChange={(flgHidroColetadoHidro) => {
                                      dataCadastroPoco.flgHidroColetadoHidro = flgHidroColetadoHidro;
                                      setDataCadastroPoco({
                                        ...dataCadastroPoco,
                                      });
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                  />
                                </div>
                                <div className='col'>
                                  <Switch
                                    formControl
                                    label='Video endoscopia'
                                    checked={
                                      dataCadastroPoco.flgHidroVideoEndoscopia
                                    }
                                    onChange={(flgHidroVideoEndoscopia) => {
                                      dataCadastroPoco.flgHidroVideoEndoscopia = flgHidroVideoEndoscopia;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className='row mb-3'>
                                <div className='col'>
                                  <Switch
                                    formControl
                                    label='Informações do proprietário'
                                    checked={dataCadastroPoco.flgHidroInfoProp}
                                    onChange={(flgHidroInfoProp) => {
                                      dataCadastroPoco.flgHidroInfoProp = flgHidroInfoProp;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                  />
                                </div>
                                <div className='col'>
                                  <Switch
                                    formControl
                                    label='Relatório tecnico do perfurador'
                                    checked={
                                      dataCadastroPoco.flgHidroRelTecPerf
                                    }
                                    onChange={(flgHidroRelTecPerf) => {
                                      dataCadastroPoco.flgHidroRelTecPerf = flgHidroRelTecPerf;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='col'>
                              <div className='card'>
                                <div className='card-body'>
                                  <div className='row mb-4'>
                                    <Switch
                                      formControl
                                      label='Outros'
                                      checked={dataCadastroPoco.flgHidroOutros}
                                      onChange={(flgHidroOutros) => {
                                        dataCadastroPoco.flgHidroOutros = flgHidroOutros;
                                        setDataCadastroPoco({
                                          ...dataCadastroPoco,
                                        });
                                        setData({
                                          ...dataCadastroPoco,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className='row mb-3'>
                                    <div className='col'>
                                      <Textbox
                                        text={
                                          dataCadastroPoco.noHidroOutros ?? ''
                                        }
                                        readOnly={
                                          !dataCadastroPoco.flgHidroOutros
                                        }
                                        onChangedValue={(noHidroOutros) => {
                                          dataCadastroPoco.noHidroOutros = noHidroOutros;
                                          setData({
                                            ...dataCadastroPoco,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!dataCadastroPoco.flgPocoProprio && (
              <div className='row mb-3'>
                <div className='bg text-black bg-gradient rounded-2 p-3'>
                  <div className='row mb-3'>
                    <div className='display-8'>
                      Serviços executados pela empresa
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col'>
                      <div className='card'>
                        <div className='card-body'>
                          <div className='row'>
                            <div className='col-8'>
                              <div className='row mb-3'>
                                <div className='col'>
                                  <Switch
                                    formControl
                                    label='Manutenção'
                                    checked={dataCadastroPoco.flgTercManutencao}
                                    onChange={(flgTercManutencao) => {
                                      dataCadastroPoco.flgTercManutencao = flgTercManutencao;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                  />
                                </div>
                                <div className='col'>
                                  <Switch
                                    formControl
                                    label='Video endoscopia'
                                    checked={
                                      dataCadastroPoco.flgTercVideoEndoscopia
                                    }
                                    onChange={(flgTercVideoEndoscopia) => {
                                      dataCadastroPoco.flgTercVideoEndoscopia = flgTercVideoEndoscopia;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                  />
                                </div>
                                <div className='col'>
                                  <Switch
                                    formControl
                                    label='Analise de água'
                                    checked={
                                      dataCadastroPoco.flgTercAnaliseAgua
                                    }
                                    onChange={(flgTercAnaliseAgua) => {
                                      dataCadastroPoco.flgTercAnaliseAgua = flgTercAnaliseAgua;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className='row mb-3'>
                                <div className='col'>
                                  <Switch
                                    formControl
                                    label='Aprofundamento'
                                    checked={
                                      dataCadastroPoco.flgTercAprofundamento
                                    }
                                    onChange={(flgTercAprofundamento) => {
                                      dataCadastroPoco.flgTercAprofundamento = flgTercAprofundamento;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                  />
                                </div>
                                <div className='col'>
                                  <Switch
                                    formControl
                                    label='Ensaio bombeamento'
                                    checked={dataCadastroPoco.flgTercEnsaioBomb}
                                    onChange={(flgTercEnsaioBomb) => {
                                      dataCadastroPoco.flgTercEnsaioBomb = flgTercEnsaioBomb;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                  />
                                </div>
                                <div className='col'>
                                  <Switch
                                    formControl
                                    label='Desenvolvimento'
                                    checked={
                                      dataCadastroPoco.flgTercDesenvolvimento
                                    }
                                    onChange={(flgTercDesenvolvimento) => {
                                      dataCadastroPoco.flgTercDesenvolvimento = flgTercDesenvolvimento;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                  />
                                </div>
                                <div className='col'>
                                  <Switch
                                    formControl
                                    label='Revestimento'
                                    checked={
                                      dataCadastroPoco.flgTercRevestimento
                                    }
                                    onChange={(flgTercRevestimento) => {
                                      dataCadastroPoco.flgTercRevestimento = flgTercRevestimento;
                                      setData({
                                        ...dataCadastroPoco,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='col'>
                              <div className='card'>
                                <div className='card-body'>
                                  <div className='row mb-4'>
                                    <Switch
                                      formControl
                                      label='Outros'
                                      checked={dataCadastroPoco.flgTercOutro}
                                      onChange={(flgTercOutro) => {
                                        dataCadastroPoco.flgTercOutro = flgTercOutro;
                                        setDataCadastroPoco({
                                          ...dataCadastroPoco,
                                        });
                                        setData({
                                          ...dataCadastroPoco,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className='row mb-3'>
                                    <div className='col'>
                                      <Textbox
                                        text={
                                          dataCadastroPoco.noTercOutros ?? ''
                                        }
                                        readOnly={
                                          !dataCadastroPoco.flgTercOutro
                                        }
                                        onChangedValue={(noTercOutros) => {
                                          dataCadastroPoco.noTercOutros = noTercOutros;
                                          setData({
                                            ...dataCadastroPoco,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className='row mb-3'>
              <div className='row mb-3'>
                <div className='col'>
                  <div className='card'>
                    <div className='card-body'>
                      <div className='row mb-3'>
                        <div className='col-auto'>
                          <Button
                            outline
                            theme={Theme.Primary}
                            template={Button.Templates.Button}
                            text='Usar endereço do cliente'
                            onClick={setCopiaEnderecoCliente}
                          />
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-2'>
                          <Textbox
                            label='Cep'
                            text={endereco.cep ?? ''}
                            maxLength={80}
                            onChangedValue={(cep) => {
                              endereco.cep = cep;
                              setData({
                                ...data,
                                cep,
                                endereco,
                              });
                              setEndereco({ ...endereco, Cep: cep });
                            }}
                          />
                        </div>
                        <div className='col-1 mt-3'>
                          <Button
                            outline
                            theme={Theme.Primary}
                            template={Button.Templates.Button}
                            icon='search'
                            tooltip='Pesquisar o endereço pelo CEP digitado.'
                            onClick={onSearchCep}
                          />
                        </div>
                        <div className='col-4'>
                          <Textbox
                            label='Endereço'
                            text={endereco.noRua}
                            maxLength={80}
                            onChangedValue={(noRua) => {
                              endereco.noRua = noRua;
                              dataCadastroPoco.endereco = endereco;
                              setData({
                                ...dataCadastroPoco,
                              });
                            }}
                          />
                        </div>

                        <div className='col-2'>
                          <Textbox
                            label='Número'
                            text={endereco.numero}
                            maxLength={20}
                            onChangedValue={(numero) => {
                              endereco.numero = numero;
                              setEndereco({
                                ...endereco,
                                numero,
                              });
                              dataCadastroPoco.endereco = endereco;
                              setData({
                                ...dataCadastroPoco,
                              });
                            }}
                          />
                        </div>

                        <div className='col-3'>
                          <Textbox
                            label='Complemento'
                            text={endereco.complemento}
                            maxLength={40}
                            onChangedValue={(complemento) => {
                              endereco.complemento = complemento;
                              setEndereco({
                                ...endereco,
                                complemento,
                              });
                              dataCadastroPoco.endereco = endereco;
                              setData({
                                ...dataCadastroPoco,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className='row mb-3'>
                        <div className='col-3'>
                          <Textbox
                            label='Bairro'
                            text={endereco.bairro}
                            maxLength={60}
                            onChangedValue={(bairro) => {
                              endereco.bairro = bairro;
                              setEndereco({
                                ...endereco,
                                bairro,
                              });

                              dataCadastroPoco.endereco = endereco;
                              setData({
                                ...dataCadastroPoco,
                              });
                            }}
                          />
                        </div>

                        <div className='col-3'>
                          <Autocomplete
                            label='País'
                            searchDataSource={onSearchPais}
                            selectedItem={pais}
                            onSelectItem={(obj) => {
                              setPais(obj);
                              setEstado({});
                              setCidade({});
                              endereco.cidade = {};
                              dataCadastroPoco.endereco = endereco;
                              setData({
                                ...dataCadastroPoco,
                              });
                            }}
                            dataSourceTextProperty='noPais'
                          />
                        </div>

                        <div className='col-3'>
                          <Autocomplete
                            label='Estado'
                            searchDataSource={onSearchEstado}
                            selectedItem={estado}
                            onSelectItem={(obj) => {
                              setEstado(obj);
                              setCidade({});
                              endereco.cidade = {};
                              dataCadastroPoco.endereco = endereco;
                              setData({
                                ...dataCadastroPoco,
                              });
                            }}
                            dataSourceTextProperty='noEstado'
                          />
                        </div>

                        <div className='col-3'>
                          <Autocomplete
                            label='Cidade'
                            searchDataSource={onSearchCidade}
                            selectedItem={cidade}
                            onSelectItem={(obj) => {
                              setCidade(obj);
                              endereco.cidade = obj;
                              endereco.nrSeqCidade = obj.nrSeqCidade;

                              dataCadastroPoco.endereco = endereco;
                              setData({
                                ...dataCadastroPoco,
                              });
                            }}
                            dataSourceTextProperty='noCidade'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalLocalizacao
          show={showLocalizacao}
          onClose={() => {
            setShowLocalizacao(false);
          }}
        />
      </div>
    );
  }
);
export default CadastroPoco;
