export default class Contato {
  constructor(jsonObject = {}) {
    this.fone = jsonObject.fone ?? '';
    this.email = jsonObject.email;
    this.site = jsonObject.site;
    this.voip = jsonObject.voip;
    this.contato = jsonObject.contato;
    this.foneEmp = jsonObject.foneEmp;
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.celular = jsonObject.celular;
    this.fax = jsonObject.fax;
    this.emailNfe = jsonObject.emailnfe;
    this.flgWhatsapp = jsonObject.flgWhatsapp;
    this.flgEnvioPadrao = jsonObject.flgEnvioPadrao;
  }
}
