import ColetaRemetente from 'core/models/FRO/coletaRemetente';
import ColetaDestinatario from 'core/models/FRO/coletaDestinatario';
import TipoEmbarque from 'core/models/FRO/tipoEmbarque';
import TipoFrete from 'core/models/FRO/tipoFrete';
import ColetaConsignatario from 'core/models/FRO/coletaConsignatario';
import EspecieFrete from 'core/models/FRO/especieFrete';
import FreteMercadoria from 'core/models/FRO/freteMercadoria';
import CondicaoPagamento from 'core/models/FIN/condicaoPagamento';
import ProdutoUnidadeMedida from 'core/models/EST/produtoUnidadeMedida';

export default class RemetenteDestinatario {
  constructor(jsonObject = {}) {
    this.nrSeqPessoaRem = jsonObject.nrSeqPessoaRem;
    this.nrSeqPessoaDes = jsonObject.nrSeqPessoaDes;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqTipoFrete = jsonObject.nrSeqTipoFrete;
    this.flgPedagioCliente = jsonObject.flgPedagioCliente;
    this.vlrPedagio = jsonObject.vlrPedagio;
    this.nrSeqProdutoUnidadeMedida = jsonObject.nrSeqProdutoUnidadeMedida;
    this.vlrFrete = jsonObject.vlrFrete;
    this.nrSeqTipoEmbarque = jsonObject.nrSeqTipoEmbarque;
    this.nrSeqEspecieFrete = jsonObject.nrSeqEspecieFrete;
    this.nrSeqCondicaoPagamento = jsonObject.nrSeqCondicaoPagamento;
    this.nrDiaFixo = jsonObject.nrDiaFixo;
    this.nrSeqPessoaConsigOri = jsonObject.nrSeqPessoaConsigOri;
    this.nrSeqPessoaConsigDest = jsonObject.nrSeqPessoaConsigDest;
    this.nrSeqFreteMercadoria = jsonObject.nrSeqFreteMercadoria;
    this.nrSeqCfo = jsonObject.nrSeqCfo;
    this.noObservacaoCte = jsonObject.noObservacaoCte;
    this.flgEnviaEmailCliente = jsonObject.flgEnviaEmailCliente;
    this.status = jsonObject.status;
    this.remetente = new ColetaRemetente(jsonObject.remetente ?? {});
    this.destinatario = new ColetaDestinatario(jsonObject.destinatario ?? {});
    this.tipoEmbarque = new TipoEmbarque(jsonObject.tipoEmbarque ?? {});
    this.tipoFrete = new TipoFrete(jsonObject.tipoFrete ?? {});
    this.origemConsig = new ColetaConsignatario(jsonObject.origemConsig ?? {});
    this.destinoConsig = new ColetaConsignatario(
      jsonObject.destinoConsig ?? {}
    );
    this.especieFrete = new EspecieFrete(jsonObject.especieFrete ?? {});
    this.freteMercadoria = new FreteMercadoria(
      jsonObject.freteMercadoria ?? {}
    );
    this.condicaoPagamento = new CondicaoPagamento(
      jsonObject.condicaoPagamento ?? {}
    );
    this.produtoUnidadeMedida = new ProdutoUnidadeMedida(
      jsonObject.produtoUnidadeMedida ?? {}
    );
    // #region Propriedades Auxiliares
    this.nomeRem = jsonObject.nomeRem;
    this.nomeDes = jsonObject.nomeDes;
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
