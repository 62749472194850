/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import {
  GridView,
  Textbox,
  Panel,
  Autocomplete,
  Button,
  FileUpload,
  Switch,
  DatePicker,
} from 'ui/components';
import {
  PageTypes,
  JustifyContent,
  BootstrapSizes,
  MimeTypes,
} from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { ColumnTypes, Theme, ResponseStatus } from 'ui/Helpers/enums.ts';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { MaskTypes } from 'ui/Helpers/masks';
import { IDemanda, IManutencaoDemanda } from 'core/interfaces/HELP/demanda';
import {
  SearchClienteFromChip,
  getClienteAutoCompleteDemanda,
} from 'core/services/FIN/cliente';
import {
  getChipAutoCompleteMVNO,
  getChipAutoCompleteChipDemanda,
  getOperadoraAutoComplete,
  getClienteLinhasByCliente,
  getUltimoCicloLinha,
  getCentroDeCustoAutoCompleteSimples,
  getChipAutoComplete,
  getAparelhoAutoCompleteForDemanda,
} from 'core/services/TEL';
import { getPlanoVinculoCentrodeCustoEmpAutoComplete } from 'core/services/TEL/planoVinculoCentrodeCustoEmp';
import { getPlanoModeloAparelhoAutoCompleteForDemanda } from 'core/services/TEL/planoModeloAparelho';
import { BuscarTitulosVencidosDoCliente } from 'core/services/FIN/tituloReceber';
import { getPlanoAutoCompleteDemanda } from 'core/services/TEL/plano';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import { getFormaPagamentoPdvAutoComplete } from 'core/services/FIN';
import { getInformacaoBancariaAutoCompleteFiltraFormaPagamento } from 'core/services/FIN/informacaoBancaria';
import { getVencimentoAutoComplete } from 'core/services/ASS/vencimento';

// interface IData extends IDemanda {
// flgPermiteAlterar: boolean;
// cliente: any;
// nrSeqPessoaCli: number;
// formaPagamento: any;
// nrSeqFormaPagamento: number;
// vencimento: any;
// nrSeqVencimento: number;
// tipoDemanda: any;
// nrSeqTipoDemanda: number;
// operadora: any;
// nrSeqOperadora: number;
// operadoraDestino: any;
// nrSeqOperadoraDestino: number;
// operadoraOrigem: any;
// nrSeqOperadoraOrigem: number;
// plano: any;
// nrSeqPlano: number;
// tipoChamada: any;
// nrSeqTipoChamada: number;
// nrSeqTipoChamadaDados: number;
// chip: any;
// nrSeqChip: number;
// empresa: any;
// nrSeqEmpresa: number;
// centroDeCusto: any;
// nrSeqCentroDeCusto: number;
// vlrPacoteEncargoManual: number;
// planoRateioEmpresa: any;
// nrSeqPlanoRateioEmpresa: number;
// planoModeloAparelho: any;
// nrSeqModeloAparelho: number;
// parcelamentoAparelho: any;
// aparelho: any;
// nrSeqAparelho: number;
// dtSolicitacao: Date | string;
// informacaoBancaria: any;
// nrSeqPessoaInformacaoBancaria: number;
// }

interface IClienteMessage {
  showMessage: boolean;
  message: string;
}

interface IEndereco {}

interface IResumo {
  nome: string;
  protocolo: string;
  operadora: string;
  linha: string;
  plano: string;
}

interface IManutencaoDemandaNovoContrato extends IManutencaoDemanda {
  onOpenTransaction: any;
  findTransaction: any;
  onGerarContrato: () => void;
  resumo: IResumo;
}

interface IContratoMessage {
  cycle: string;
  message: string;
  color: string;
}

export default function ManutencaoNovoContrato({
  data,
  setData,
  onSetMessage,
  dataListDocRemove,
  setDataListDocRemove,
  documentos = [],
  setDocumentos,
  onOpenTransaction,
  findTransaction,
  setModalAlterarFormaPagamento,
  infoPagamentoDefault,
  setInfoPagamentoDefault,
  dataChip,
  setDataChip,
  onGerarContrato,
  onAutoCompleteFormaPagamento,
}: IManutencaoDemandaNovoContrato) {
  const gridView = useRef<any>();
  const [clienteMessage, setClienteMessage] = useState<IClienteMessage>({
    showMessage: false,
    message: '',
  });
  const [endereco, setEndereco] = useState<any>({});
  const [darEntrada, setDarEntrada] = useState<boolean>(false);
  const [hasPlanoRateioDefault, setHasPlanoRateioDefault] = useState<boolean>(
    false
  );
  const [contratoMessage, setContratoMessage] = useState<
    // eslint-disable-next-line no-undef
    Partial<IContratoMessage> | IContratoMessage
  >({});
  const [tituloReceber, setTituloReceber] = useState<any>({});
  const [importedFiles, setImportedFiles] = useState<any>({});
  // eslint-disable-next-line no-undef
  const [resumo, setResumo] = useState<Partial<IResumo> | IResumo>({});

  const onSearchCliente = async (e: string): Promise<Array<any>> => {
    const { clientes } = await getClienteAutoCompleteDemanda({
      noPessoa: e,
      flgIncluirEndereco: true,
      flgFiltrarEmpresa: true,
    });
    return clientes;
  };

  const onSearchOperadora = async (e: string): Promise<Array<any>> => {
    const {
      status,
      message: msg,
      operadoras,
    } = await getOperadoraAutoComplete({ noOperadora: e });

    if (status === ResponseStatus.Error) {
      onSetMessage(msg, status);
      return [];
    }

    const result = operadoras.filter(
      (operadora: any) => !operadora.noOperadora.includes('Importação Manual')
    );

    // Não fazer Novo Contrato Massivo para MVNO
    if (data.tipoDemanda?.cdTipoDemanda === 'Novo Contrato Massivo') {
      return result.filter(
        (operadora: any) => !operadora.noOperadora.includes('MVNO')
      );
    }

    return result;
  };

  const onSearchCentroDeCusto = async (e: string): Promise<Array<any>> => {
    const {
      status,
      message: msg,
      data: centros,
    } = await getCentroDeCustoAutoCompleteSimples({
      codNoCentroDeCusto: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });
    onSetMessage(status, msg);
    return centros;
  };

  const onSearchPlano = async (e: string): Promise<Array<any>> => {
    const { status, message: msg, planos } = await getPlanoAutoCompleteDemanda({
      noPlano: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
      nrSeqOperadora: data.nrSeqOperadora,
      flgAgrupado: true,
    });
    if (planos.length === 0)
      onSetMessage(ResponseStatus.Error, 'Nenhum plano vinculado a empresa.');

    onSetMessage(status, msg);

    return planos;
  };

  const onSearchPlanoRateio = async (e: string): Promise<Array<any>> => {
    const { status, message: msg, planos } = await getPlanoAutoCompleteDemanda({
      noPlano: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
      flgAtivo: true,
      flgPlanoRateioEmpresa: true,
    });

    if (planos.length === 0)
      onSetMessage(ResponseStatus.Error, 'Nenhum plano vinculado a empresa.');

    onSetMessage(status, msg);

    return planos;
  };

  const getPlanoRateio = async (nrSeqTipoChamada: number): Promise<any> => {
    const { planos } = await getPlanoAutoCompleteDemanda({
      nrSeqTipoChamada,
      nrSeqEmpresa: data.nrSeqEmpresa,
      flgAtivo: true,
      flgPlanoRateioEmpresa: true,
    });

    return planos.length > 0 ? planos[0] : undefined;
  };

  const onSearchPacoteDados = async (e: string): Promise<Array<any>> => {
    if (
      data.nrSeqCentroDeCusto === undefined ||
      data.nrSeqCentroDeCusto === 0
    ) {
      onSetMessage(ResponseStatus.Error, 'Selecione o centro de custo.');
      return [];
    }
    if (data.nrSeqPlano === undefined || data.nrSeqPlano === 0) {
      onSetMessage(ResponseStatus.Error, 'Selecione o plano.');
      return [];
    }

    const { planovinculo } = await getPlanoVinculoCentrodeCustoEmpAutoComplete({
      nrSeqPlano: data.plano?.nrSeqPlano,
      nrSeqOperadora: data.nrSeqOperadora,
      nrSeqEmpresa: data.nrSeqEmpresa,
      nrSeqCentroDeCusto: data.nrSeqCentroDeCusto,
      noTipoChamada: e,
    });
    if (planovinculo.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Centro de Custo sem pacote vinculado.'
      );
    }
    return planovinculo;
  };

  const onSearchChip = async (e: string): Promise<Array<any>> => {
    if (data.tipoDemanda?.flgTrocaNumero) {
      const { status, message: msg, chips } = await getChipAutoComplete({
        NrPrefixoLinha: e,
        nrSeqEmpresa: data.nrSeqEmpresa,
      });

      if (status === ResponseStatus.Success) {
        onSetMessage(status, msg);
      }

      return chips;
    }

    let retorno: any = {};

    if (data.nrSeqOperadora === 20) {
      if (dataChip?.nrPrefixo === undefined || dataChip?.nrPrefixo === '') {
        onSetMessage(1, 'Informe o Prefixo.');
        return [];
      }

      retorno = await getChipAutoCompleteMVNO({
        nrSeqOperadora: data.nrSeqOperadora,
        nrLinha: e,
        nrSeqEmpresa: data.nrSeqEmpresa,
        nrPrefixo: dataChip.nrPrefixo,
        flgNumeroEspecial: dataChip.flgNumeroEspecial,
      });
    } else {
      retorno = await getChipAutoCompleteChipDemanda({
        nrSeqOperadora: data.nrSeqOperadora,
        nrLinha: e,
        nrSeqEmpresa: data.nrSeqEmpresa,
        nrPrefixo: dataChip.nrPrefixo,
        nrSeqCentroDeCusto: data.nrSeqCentroDeCusto,
      });
    }

    onSetMessage(retorno.status, retorno.message);

    return retorno.chips;
  };

  const getSuggestedDate = (cycleStart: Date) => {
    const today = new Date();

    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();

    const cycleStartDay = cycleStart.getDate();

    let currentCycleStart: Date;
    let nextCycleStart: Date;

    if (today.getDate() < cycleStartDay) {
      currentCycleStart = new Date(
        currentYear,
        currentMonth - 1,
        cycleStartDay
      );

      nextCycleStart = new Date(currentYear, currentMonth, cycleStartDay);
    } else {
      currentCycleStart = new Date(currentYear, currentMonth, cycleStartDay);

      nextCycleStart = new Date(currentYear, currentMonth + 1, cycleStartDay);
    }

    const diffToCurrentCycle: number = Math.abs(
      today.getTime() - currentCycleStart.getTime()
    );
    const diffToNextCycle: number = Math.abs(
      today.getTime() - nextCycleStart.getTime()
    );

    if (diffToCurrentCycle <= diffToNextCycle) {
      return currentCycleStart;
    }

    return nextCycleStart;
  };

  const onSelectChip = async (chip: any): Promise<void> => {
    if ('nrSeqChip' in chip) {
      const lastCycle = await getUltimoCicloLinha(chip.nrSeqChip);

      const today = new Date();

      let cliente: any;

      if (data.tipoDemanda?.flgTrocaNumero) {
        cliente = await SearchClienteFromChip(chip.nrSeqChip);
      }

      if (lastCycle) {
        const dtPerInicial = new Date(lastCycle.dtPerInicial);
        const dtPerFinal = new Date(lastCycle.dtPerFinal);

        const twoMonthsAgo = new Date();
        twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);

        const suggestedDate = getSuggestedDate(dtPerInicial);

        if (dtPerFinal <= twoMonthsAgo) {
          setContratoMessage({
            cycle: `Último ciclo importado: ${
              dtPerInicial.toLocaleString('pt-BR').split(',')[0]
            } - ${dtPerFinal.toLocaleString('pt-BR').split(',')[0]}`,
            message: `Essa linha não possui um ciclo importado recente, verifique se a linha não esta cancelada antes de prosseguir `,
            color: 'danger',
          });
        } else {
          setContratoMessage({
            cycle: `Último ciclo importado: ${
              dtPerInicial.toLocaleString('pt-BR').split(',')[0]
            } - ${dtPerFinal.toLocaleString('pt-BR').split(',')[0]}`,
            message: ``,
            color: 'primary',
          });
        }

        if (data.tipoDemanda?.flgTrocaNumero) {
          setData({
            ...data,
            cliente,
            nrSeqPessoaCli: cliente?.nrSeqPessoaCli,
            chip,
            dtSolicitacao: suggestedDate.toJSON().slice(0, 10),
          });

          setEndereco(cliente?.endereco);
        } else {
          setData({
            ...data,
            chip,
            dtSolicitacao: suggestedDate.toJSON().slice(0, 10),
          });
        }
      } else {
        setContratoMessage({
          cycle: 'Linha não possui um ciclo anterior',
          message: '',
          color: 'primary',
        });

        setData({
          ...data,
          chip,
          dtSolicitacao: today.toJSON().slice(0, 10),
        });
      }

      setDataChip({
        ...dataChip,
        chip,
        nrSeqChip: chip.nrSeqChip,
        nrLinha: chip.nrLinha,
      });
    } else {
      setContratoMessage({});

      if (data.tipoDemanda?.flgTrocaNumero) {
        setData({
          ...data,
          chip: undefined,
          cliente: undefined,
          nrSeqPessoaCli: undefined,
        });

        setEndereco({});
      } else {
        setData({ ...data, chip: undefined, nrSeqChip: undefined });
      }

      setDataChip({
        ...dataChip,
        chip: undefined,
        nrSeqChip: undefined,
        nrLinha: undefined,
      });
    }
  };

  const onSearchPlanoModeloAparelho = async (
    e: string
  ): Promise<Array<any>> => {
    const {
      status,
      message: msg,
      value,
    } = await getPlanoModeloAparelhoAutoCompleteForDemanda({
      nrSeqPlano: data.nrSeqPlano,
      noModeloAparelho: e,
      nrSeqTipoChamandaMin: data.nrSeqTipoChamada,
    });

    onSetMessage(status, msg);

    return value;
  };

  const onSearchAparelho = async (e: string): Promise<Array<any>> => {
    const {
      status,
      message: msg,
      aparelhos,
    } = await getAparelhoAutoCompleteForDemanda({
      nrSeqPlano: data.nrSeqPlano,
      nrSeqModeloAparelho: data.nrSeqModeloAparelho,
      nrImei: e,
    });

    onSetMessage(status, msg);
    return aparelhos;
  };

  const onSearchTituloEspecie = async (e: string): Promise<Array<any>> => {
    const {
      status,
      message: msg,
      data: titulos,
    } = await getTituloEspecieAutoComplete({
      noEspecie: e,
    });

    onSetMessage(status, msg);

    return titulos;
  };

  // const onAutoCompleteFormaPagamento = async (
  //   e: string
  // ): Promise<Array<any>> => {
  //   const { formasPagamento } = await getFormaPagamentoPdvAutoComplete({
  //     noFormaPagamento: e,
  //   });

  //   return formasPagamento;
  // };

  const onSearchInformacaoBancariaEntrada = async (): Promise<Array<any>> => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoCompleteFiltraFormaPagamento({
      nrSeqPessoa: data.cliente?.nrSeqPessoaCli,
      nrSeqFormaPagamento: tituloReceber.nrSeqFormaPagamento,
    });

    onSetMessage(status, msg);

    return informacoesBancarias;
  };

  const onSearchVencimento = async (e: string): Promise<Array<any>> => {
    const {
      status,
      message: msg,
      vencimentos,
    } = await getVencimentoAutoComplete({
      noVencimento: e,
    });
    onSetMessage(status, msg);
    return vencimentos;
  };

  const onSearchInformacaoBancaria = async (): Promise<Array<any>> => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoCompleteFiltraFormaPagamento({
      nrSeqPessoa: data.cliente?.nrSeqPessoaCli,
      nrSeqFormaPagamento: data.nrSeqFormaPagamento,
    });

    onSetMessage(status, msg);

    return informacoesBancarias;
  };

  const onClickAddDocumento = async (): Promise<void> => {
    if (importedFiles.length > 0) {
      const documento = {
        ...importedFiles[0],
        noArquivo: importedFiles[0].name,
        noTipoArquivo: importedFiles[0].type,
        noTamanho: importedFiles[0].size,
        flgExibeDownload: false,
        status: 'Inserir',
      };

      const dataSource = gridView?.current?.getDataSource() ?? [];

      dataSource.push(documento);

      if (gridView && gridView.current) {
        gridView.current.setDataSource(dataSource);
      }

      setDocumentos([...documentos, importedFiles[0]]);

      setImportedFiles([]);
    }
  };

  const onClickDownload = async (e: any): Promise<void> => {
    const downloadLink = document.createElement('a');

    if (e.noImagem !== null && e.noImagem !== '') {
      const linkSource = `data:image/png;base64, ${e.noImagem}`;
      downloadLink.href = linkSource;
      downloadLink.download = e.noArquivo;
    } else if (e.linkDownload !== null && e.linkDownload !== '') {
      downloadLink.href = e.linkDownload;
      downloadLink.target = '_blank';
    }

    downloadLink.click();
  };

  const onRemoveDocumento = async (e: any, datasource: any): Promise<void> => {
    const dataDocs = datasource.filter((doc: any) => doc !== e);

    const lista = dataListDocRemove;
    if (e.nrSeqDemandaDocumento) {
      lista.push(e.nrSeqDemandaDocumento);
    }

    setDataListDocRemove(lista);

    if (gridView && gridView.current) gridView.current.setDataSource(dataDocs);
  };

  const columns = [
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'nrSeqDemandaDocumento',
      type: ColumnTypes.Button,
      onClick: (e: any) => onClickDownload(e),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },
    {
      key: 'nrSeqDemandaDocumento',
      type: ColumnTypes.Button,
      onClick: (e: any, datasource: any) => onRemoveDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header
              theme={Theme.Primary}
              title='Cliente'
              align={JustifyContent.Start}
            />
            <Panel.Body>
              {clienteMessage.showMessage && (
                <div className='row mb-2'>
                  <div className='col'>
                    <div className='card border-danger'>
                      <div className='card-body text-danger'>
                        <p className='card-text'>{clienteMessage.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className='row mb-3'>
                <div className='col'>
                  <Autocomplete
                    label='Cliente'
                    enabled={data.flgPermiteAlterar}
                    required
                    searchDataSource={onSearchCliente}
                    minLengthTextSearch={3}
                    placeholder='Digite ao menos 3 caracteres para buscar Cliente'
                    selectedItem={data.cliente}
                    findTransaction={findTransaction}
                    onOpenTransaction={onOpenTransaction}
                    transactionToOpen={TransacationCodes.Cliente}
                    nrseqTransactionToOpen='nrSeqPessoaCli'
                    onSelectItem={async (cliente: any) => {
                      const {
                        data: clienteLinhas,
                      } = await getClienteLinhasByCliente({
                        nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                      });

                      const titulosVencidos = await BuscarTitulosVencidosDoCliente(
                        cliente.nrSeqPessoaCli
                      );

                      if (titulosVencidos.length > 0) {
                        setClienteMessage({
                          showMessage: true,
                          message: `Cliente possui título${
                            titulosVencidos.length === 1 ? '' : 's'
                          } vencido${
                            titulosVencidos.length === 1 ? '' : 's'
                          } há mais de ${
                            titulosVencidos[0].nrLimiteDiasVencidos
                          } dias, favor contatar o suporte antes de prosseguir.`,
                        });
                      } else {
                        setClienteMessage({
                          showMessage: false,
                          message: '',
                        });
                      }

                      if (clienteLinhas.length > 0) {
                        const { formaPagamento, vencimento } = clienteLinhas[0];

                        setInfoPagamentoDefault({
                          formaPagamento,
                          nrSeqFormaPagamento:
                            formaPagamento.nrSeqFormaPagamento,
                          vencimento,
                          nrSeqVencimento: vencimento.nrSeqVencimento,
                        });
                        setData({
                          ...data,
                          cliente,
                          nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                          formaPagamento,
                          nrSeqFormaPagamento:
                            formaPagamento.nrSeqFormaPagamento,
                          vencimento,
                          nrSeqVencimento: vencimento.nrSeqVencimento,
                        });
                      } else {
                        setInfoPagamentoDefault({});

                        setData({
                          ...data,
                          cliente,
                          nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                          formaPagamento: undefined,
                          nrSeqFormaPagamento: undefined,
                          vencimento: undefined,
                          nrSeqVencimento: undefined,
                        });
                      }

                      setEndereco(cliente.endereco);
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='RG'
                    text={data.cliente?.nrRG}
                    maxLength={15}
                    visible={data.cliente?.flgFisica}
                    readOnly
                  />
                </div>
                {data.cliente?.flgFisica ? (
                  <div className='col'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='CPF'
                      text={data.cliente?.nrDocumento}
                      mask={MaskTypes.CPF}
                      readOnly
                    />
                  </div>
                ) : (
                  <div className='col'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='CNPJ'
                      text={data.cliente?.nrDocumento}
                      mask={MaskTypes.CNPJ}
                      readOnly
                    />
                  </div>
                )}
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox label='Endereço' text={endereco?.noRua} readOnly />
                </div>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox label='Número' text={endereco?.numero} readOnly />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox label='Bairro' text={endereco?.bairro} readOnly />
                </div>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Cidade'
                    text={endereco?.cidade?.noCidade}
                    readOnly
                  />
                </div>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox label='CEP' text={endereco?.cep} readOnly />
                </div>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='UF'
                    text={endereco?.cidade?.estado?.cdEstado}
                    readOnly
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox label='Email' text={data.cliente?.email} readOnly />
                </div>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Telefone'
                    text={data.cliente?.fone}
                    mask={MaskTypes.CellPhone}
                    readOnly
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header
              title='Plano / Linha / Aparelho'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col'>
                  <Autocomplete
                    label='Operadora'
                    enabled={data.flgPermiteAlterar}
                    required
                    searchDataSource={onSearchOperadora}
                    selectedItem={data.operadora}
                    onSelectItem={(operadora: any) => {
                      setData({
                        ...data,
                        operadora,
                        nrSeqOperadoraDestino: operadora.nrSeqOperadora,
                        nrSeqOperadora: operadora.nrSeqOperadora,
                        plano: undefined,
                        nrSeqPlano: undefined,
                        tipoChamada: {},
                        nrSeqTipoChamada: undefined,
                        chip: undefined,
                        nrSeqChip: undefined,
                      });
                      setDataChip({});
                    }}
                    dataSourceTextProperty='noOperadora'
                  />
                </div>
                <div className='col'>
                  <Autocomplete
                    label='Centro de Custo'
                    enabled={
                      data.flgPermiteAlterar && !!data.empresa?.nrSeqEmpresa
                    }
                    required
                    searchDataSource={onSearchCentroDeCusto}
                    selectedItem={data.centroDeCusto}
                    onSelectItem={(centroDeCusto: any) =>
                      setData({
                        ...data,
                        centroDeCusto,
                        nrSeqCentroDeCusto: centroDeCusto.nrSeqCentroDeCusto,
                      })
                    }
                    dataSourceTextProperty='noCentroDeCusto'
                  />
                </div>
                <div className='col'>
                  <Autocomplete
                    label='Plano'
                    enabled={
                      data.flgPermiteAlterar &&
                      !!data.empresa?.nrSeqEmpresa &&
                      !!data.operadora?.nrSeqOperadora
                    }
                    required
                    searchDataSource={onSearchPlano}
                    selectedItem={data.plano}
                    onSelectItem={(plano: any) =>
                      setData({
                        ...data,
                        plano,
                        nrSeqPlano: plano.nrSeqPlano,
                        tipoChamada: {},
                        nrSeqTipoChamada: undefined,
                      })
                    }
                    dataSourceTextProperty='noPlano'
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-4'>
                  <Autocomplete
                    label='Pacote'
                    enabled={
                      data.flgPermiteAlterar &&
                      !!data.plano?.nrSeqPlano &&
                      !!data.centroDeCusto?.nrSeqCentroDeCusto
                    }
                    required
                    searchDataSource={onSearchPacoteDados}
                    selectedItem={data.tipoChamada}
                    onSelectItem={async (tipoChamada: any) => {
                      if ('nrSeqTipoChamada' in tipoChamada) {
                        const planoRateio = await getPlanoRateio(
                          tipoChamada.nrSeqTipoChamada
                        );

                        if (planoRateio) {
                          setHasPlanoRateioDefault(true);

                          setData({
                            ...data,
                            tipoChamada,
                            nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                            nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                            vlrPacoteEncargoManual: tipoChamada.vlrVenda,
                            planoRateioEmpresa: planoRateio,
                            nrSeqPlanoRateioEmpresa: planoRateio.nrSeqPlano,
                            chip: undefined,
                            planoModeloAparelho: undefined,
                            nrSeqModeloAparelho: undefined,
                            parcelamentoAparelho: undefined,
                            aparelho: undefined,
                            nrSeqAparelho: undefined,
                          });
                        } else {
                          setHasPlanoRateioDefault(false);

                          setData({
                            ...data,
                            tipoChamada,
                            nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                            nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                            vlrPacoteEncargoManual: tipoChamada.vlrVenda,
                            planoRateioEmpresa: undefined,
                            nrSeqPlanoRateioEmpresa: undefined,
                            chip: undefined,
                            planoModeloAparelho: undefined,
                            nrSeqModeloAparelho: undefined,
                            parcelamentoAparelho: undefined,
                            aparelho: undefined,
                            nrSeqAparelho: undefined,
                          });
                        }
                      } else {
                        setHasPlanoRateioDefault(false);

                        setData({
                          ...data,
                          tipoChamada,
                          nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                          nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                          // noPacoteDados: tipoChamada.noTipoChamada,
                          vlrPacoteEncargoManual: tipoChamada.vlrVenda,
                          planoRateioEmpresa: undefined,
                          nrSeqPlanoRateioEmpresa: undefined,
                          chip: undefined,
                        });
                      }

                      setDarEntrada(false);
                      setDataChip({});
                    }}
                    dataSourceTextProperty='noTipoChamada'
                  />
                </div>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Valor Pacote'
                    readOnly={
                      !data.flgPermiteAlterar ||
                      !data.tipoChamada?.flgPermiteEditar
                    }
                    text={data.vlrPacoteEncargoManual}
                    mask={MaskTypes.Decimal}
                    onChangedValue={(vlrPacoteEncargoManual: any) => {
                      setData({
                        ...data,
                        vlrPacoteEncargoManual,
                      });
                    }}
                  />
                </div>
                <div className='col'>
                  <Autocomplete
                    label='Plano de Rateio Empresa'
                    searchDataSource={onSearchPlanoRateio}
                    enabled={
                      data.flgPermiteAlterar &&
                      !!data.empresa?.nrSeqEmpresa &&
                      !hasPlanoRateioDefault
                    }
                    placeholder={
                      !data.empresa?.nrSeqEmpresa ? 'Seleciona a Empresa' : ''
                    }
                    selectedItem={data.planoRateioEmpresa}
                    onSelectItem={(planoRateioEmpresa: any) => {
                      setData({
                        ...data,
                        planoRateioEmpresa,
                        nrSeqPlanoRateioEmpresa: planoRateioEmpresa.nrSeqPlano,
                      });
                    }}
                    dataSourceTextProperty='noPlano'
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-1'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='DDD'
                    readOnly={data.flgPermiteAlterar}
                    visible={
                      data.nrSeqTipoChamada &&
                      data.plano?.nrSeqPlano &&
                      data.nrSeqOperadora === 20
                    }
                    text={dataChip.nrPrefixo}
                    onChangedValue={(nrPrefixo: number) => {
                      setDataChip({
                        ...dataChip,
                        nrPrefixo,
                      });
                    }}
                  />
                </div>
                <div className='col-3'>
                  <Autocomplete
                    label='Pesquisar Linha'
                    enabled={
                      data.flgPermiteAlterar && !!data.empresa?.nrSeqEmpresa
                    }
                    required
                    searchDataSource={onSearchChip}
                    selectedItem={dataChip.chip}
                    onSelectItem={async (chip: any) => {
                      await onSelectChip(chip);
                    }}
                    dataSourceTextProperty='linhaFormatada'
                  />
                </div>
                {data.nrSeqTipoChamada &&
                  data.plano?.nrSeqPlano &&
                  data.nrSeqOperadora === 20 && (
                    <div className='col-auto'>
                      {/* @ts-expect-error */}
                      <Switch
                        formControl
                        label='Números Especiais'
                        checked={data.chip?.flgNumeroEspecial}
                        onChange={(flgNumeroEspecial: boolean) =>
                          setDataChip({
                            ...dataChip,
                            flgNumeroEspecial,
                          })
                        }
                      />
                    </div>
                  )}
                <div className='col-3'>
                  {/* @ts-expect-error */}
                  <Textbox
                    required={
                      data.operadora?.nrSeqOperadora !== 1 &&
                      data.operadora?.nrSeqOperadora !== 2 &&
                      data.operadora?.nrSeqOperadora !== 6
                    }
                    label='ICCID'
                    readOnly={data.flgPermiteAlterar}
                    maxLength={20}
                    text={dataChip.nrIccid}
                    onChangedValue={(nrIccid: number): void => {
                      setDataChip({
                        ...dataChip,
                        nrIccid,
                      });

                      setData({
                        ...data,
                        chip: data.chip,
                      });
                    }}
                  />
                </div>
                <div className='col'>
                  <Autocomplete
                    label='Modelos de Aparelhos em Estoque'
                    enabled={data.flgPermiteAlterar}
                    visible={data.plano?.nrSeqPlano}
                    searchDataSource={onSearchPlanoModeloAparelho}
                    selectedItem={data.planoModeloAparelho}
                    onSelectItem={(planoModeloAparelho: any) => {
                      setData({
                        ...data,
                        planoModeloAparelho,
                        nrSeqModeloAparelho:
                          planoModeloAparelho.nrSeqModeloAparelho,
                        parcelamentoAparelho: {
                          nrParcelas: planoModeloAparelho.nrParcelas,
                          vlrOriginal: planoModeloAparelho.vlrTotal,
                        },
                        aparelho: undefined,
                        nrSeqAparelho: undefined,
                      });

                      setDarEntrada(false);
                      setTituloReceber({});
                    }}
                    dataSourceTextProperty='noModeloAparelho'
                  />
                </div>
              </div>
              {data.planoModeloAparelho?.nrSeqModeloAparelho && (
                <>
                  <div className='row mb-3'>
                    <div className='col'>
                      <Autocomplete
                        label='IMEI do Aparelho'
                        enabled={data.flgPermiteAlterar}
                        required={!!data.nrSeqModeloAparelho}
                        visible={
                          data.planoModeloAparelho?.nrSeqModeloAparelho != null
                        }
                        searchDataSource={onSearchAparelho}
                        selectedItem={data.aparelho}
                        onSelectItem={(aparelho: any) =>
                          setData({
                            ...data,
                            aparelho,
                            nrSeqAparelho: aparelho.nrSeqAparelho,
                          })
                        }
                        dataSourceTextProperty='nrImei'
                      />
                    </div>
                    <div className='col-1'>
                      {/* @ts-expect-error */}
                      <Textbox
                        label='Parcelas'
                        required={!!data.nrSeqModeloAparelho}
                        readOnly={
                          !data.flgPermiteAlterar &&
                          !data.parcelamentoAparelho?.flgAutorizaEditar
                        }
                        text={data.parcelamentoAparelho?.nrParcelas}
                        mask={MaskTypes.Integer}
                        onChangedValue={(nrParcelas: any) =>
                          setData({
                            ...data,
                            parcelamentoAparelho: {
                              ...data.parcelamentoAparelho,
                              nrParcelas,
                            },
                          })
                        }
                      />
                    </div>
                    <div className='col'>
                      {/* @ts-expect-error */}
                      <Textbox
                        label='Valor Aparelho'
                        readOnly
                        text={data.parcelamentoAparelho?.vlrOriginal}
                        mask={MaskTypes.Currency}
                      />
                    </div>
                    <div className='col'>
                      {/* @ts-expect-error */}
                      <Textbox
                        label='Valor Parcela'
                        readOnly
                        text={
                          data.parcelamentoAparelho?.vlrOriginal /
                          data.parcelamentoAparelho?.nrParcelas
                        }
                        mask={MaskTypes.Currency}
                      />
                    </div>
                    <div className='col-2'>
                      {/* @ts-expect-error */}
                      <Switch
                        formControl
                        label='Dar Entrada'
                        checked={darEntrada}
                        onChange={(value: boolean) => {
                          setDarEntrada(value);
                          setTituloReceber({});
                        }}
                      />
                    </div>
                  </div>
                  {darEntrada && (
                    <div className='row mb-3'>
                      <div className='col'>
                        {/* @ts-expect-error */}
                        <Textbox
                          label='Valor da Entrada'
                          required={darEntrada}
                          text={tituloReceber.vlrTitulo ?? 0}
                          onChangedValue={(vlrTitulo: number) =>
                            setTituloReceber({
                              ...tituloReceber,
                              vlrTitulo,
                            })
                          }
                          mask={MaskTypes.Decimal}
                        />
                      </div>
                      <div className='col-2'>
                        <DatePicker
                          label='Vencimento da Entrada'
                          required={darEntrada}
                          text={tituloReceber.dtVencimento}
                          // mask={MaskTypes.Date}
                          onChange={(dtVencimento: Date | string) =>
                            setTituloReceber({
                              ...tituloReceber,
                              dtVencimento,
                            })
                          }
                        />
                      </div>
                      <div className='col'>
                        <Autocomplete
                          label='Tipo Receita'
                          required={darEntrada}
                          searchDataSource={onSearchTituloEspecie}
                          selectedItem={tituloReceber.tituloEspecie}
                          onSelectItem={(tituloEspecie: any) =>
                            setTituloReceber({
                              ...tituloReceber,
                              tituloEspecie,
                              nrSeqTituloEspecie:
                                tituloEspecie.nrSeqTituloEspecie,
                            })
                          }
                          dataSourceTextProperty='noEspecie'
                        />
                      </div>
                      <div className='col'>
                        <Autocomplete
                          label='Forma Pagamento da Entrada'
                          required={darEntrada}
                          searchDataSource={onAutoCompleteFormaPagamento}
                          selectedItem={tituloReceber.formaPagamento}
                          onSelectItem={(formaPagamento: any) =>
                            setTituloReceber({
                              ...tituloReceber,
                              formaPagamento,
                              nrSeqFormaPagamento:
                                formaPagamento.nrSeqFormaPagamento,
                            })
                          }
                          dataSourceTextProperty='noFormaPagamento'
                        />
                      </div>
                      <div className='col'>
                        <Autocomplete
                          label='Informação Bancária'
                          required={
                            tituloReceber.formaPagamento?.flgGrupo === 'D'
                          }
                          visible={
                            tituloReceber.formaPagamento &&
                            tituloReceber.formaPagamento?.flgGrupo === 'D'
                          }
                          searchDataSource={onSearchInformacaoBancariaEntrada}
                          selectedItem={tituloReceber.informacaoBancaria}
                          onSelectItem={(informacaoBancaria: any) =>
                            setTituloReceber({
                              ...tituloReceber,
                              informacaoBancaria,
                              nrSeqPessoaInformacaoBancaria:
                                informacaoBancaria.nrSeqPessoaInformacaoBancaria,
                            })
                          }
                          dataSourceTextProperty='noDescricao'
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header
              title='Informação Pagamento'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col'>
                  <Autocomplete
                    label='Forma de Pagamento'
                    enabled={data.flgPermiteAlterar}
                    required
                    searchDataSource={onAutoCompleteFormaPagamento}
                    selectedItem={data.formaPagamento}
                    onSelectItem={(formaPagamento: any): void => {
                      if (
                        formaPagamento.nrSeqFormaPagamento &&
                        infoPagamentoDefault.nrSeqFormaPagamento
                      ) {
                        if (
                          formaPagamento.nrSeqFormaPagamento !==
                          infoPagamentoDefault.nrSeqFormaPagamento
                        ) {
                          setModalAlterarFormaPagamento({
                            show: true,
                            formaPagamento,
                          });
                        } else {
                          setData({
                            ...data,
                            formaPagamento,
                            nrSeqFormaPagamento:
                              formaPagamento.nrSeqFormaPagamento,
                          });
                        }
                      } else {
                        setData({
                          ...data,
                          formaPagamento,
                          nrSeqFormaPagamento:
                            formaPagamento.nrSeqFormaPagamento,
                        });
                      }
                    }}
                    dataSourceTextProperty='noFormaPagamento'
                  />
                </div>
                <div className='col-2'>
                  <Autocomplete
                    label='Vencimento'
                    enabled={data.flgPermiteAlterar}
                    required
                    searchDataSource={onSearchVencimento}
                    selectedItem={data.vencimento}
                    onSelectItem={(vencimento: any) =>
                      setData({
                        ...data,
                        vencimento,
                        nrSeqVencimento: vencimento.nrSeqVencimento,
                      })
                    }
                    dataSourceTextProperty='noVencimento'
                  />
                </div>
                <div className='col'>
                  <Autocomplete
                    label='Informação Bancária'
                    enabled={data.flgPermiteAlterar}
                    visible={
                      data.formaPagamento &&
                      data.formaPagamento.flgGrupo === 'D'
                    }
                    searchDataSource={onSearchInformacaoBancaria}
                    selectedItem={data.informacaoBancaria}
                    onSelectItem={(informacaoBancaria: any) =>
                      setData({
                        ...data,
                        informacaoBancaria,
                        nrSeqPessoaInformacaoBancaria:
                          informacaoBancaria.nrSeqPessoaInformacaoBancaria,
                      })
                    }
                    dataSourceTextProperty='noDescricao'
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header
              title='Contrato'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              {clienteMessage.showMessage && (
                <div className='row mb-2'>
                  <div className='col'>
                    <div className='card border-danger'>
                      <div className='card-body text-danger'>
                        <p className='card-text'>{clienteMessage.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className='row mb-3'>
                <div className='col-2'>
                  <DatePicker
                    label='Data de Início de Contrato'
                    required
                    readOnly={!data.flgPermiteAlterar}
                    text={data.dtSolicitacao}
                    onChange={(dtSolicitacao: Date | string) => {
                      setData({
                        ...data,
                        dtSolicitacao,
                      });
                    }}
                  />
                </div>
                {'cycle' in contratoMessage &&
                  dataChip.nrSeqChip !== undefined && (
                    <div className='col'>
                      <div className={`card border-${contratoMessage.color}`}>
                        <div
                          className={`card-body text-${contratoMessage.color}`}
                        >
                          <p className='card-text mb-0'>
                            {contratoMessage.cycle}
                          </p>
                          {contratoMessage.message &&
                            contratoMessage.message.length > 0 && (
                              <p className='card-text'>
                                {contratoMessage.message}
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              <div className='row mb-3'>
                <div className='col-2 align-self-center'>
                  <Button
                    theme={Theme.Primary}
                    template={Button.Templates.Default}
                    text='Gerar Contrato'
                    onClick={onGerarContrato}
                  />
                </div>

                <div className='col-6'>
                  <FileUpload
                    files={importedFiles}
                    onChange={(files: any) => {
                      if (files.length > 0) {
                        if (files[0].size <= 5000000) {
                          setImportedFiles(files);
                        } else {
                          onSetMessage(
                            ResponseStatus.Error,
                            'Arquivo não pode ser maior que 5MB'
                          );
                        }
                      } else {
                        setImportedFiles(files);
                      }
                    }}
                    allowedMimeTypes={[
                      MimeTypes.Types.PDF,
                      MimeTypes.Types.Image,
                      MimeTypes.Types.Word,
                    ]}
                  />
                </div>
                <div className='col align-self-center'>
                  <Button
                    text='Adicionar Documento'
                    className='px-5'
                    theme={Theme.Success}
                    template={Button.Templates.Default}
                    onClick={() => onClickAddDocumento()}
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <GridView
                    ref={gridView}
                    // @ts-expect-error
                    dataColumns={columns}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header
              title='Resumo'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col'>
                  <h5>Dados do Contrato</h5>
                  <div className='row mb-3'>
                    <div className='col'>
                      {/* @ts-expect-error */}
                      <Textbox label='Nome' text={resumo.nome} readOnly />
                    </div>
                    <div
                      className='col-2 mt-2 widget-square bg-gradient-primary text-white p-3'
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      Protocolo:
                      {resumo.protocolo ?? ''}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <h5>Dados do Plano</h5>
                  <div className='row mb-3'>
                    <div className='col'>
                      {/* @ts-expect-error */}
                      <Textbox
                        label='Operadora'
                        text={resumo.operadora}
                        readOnly
                      />
                    </div>
                    <div className='col'>
                      {/* @ts-expect-error */}
                      <Textbox label='Plano' text={resumo.plano} readOnly />
                    </div>
                    <div className='col-2'>
                      {/* @ts-expect-error */}
                      <Textbox label='Linha' text={resumo.linha} readOnly />
                    </div>
                  </div>
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
    </>
  );
}
