import PocTipoBomba from 'core/models/POC/tipoBomba';
import PocTipoRede from 'core/models/POC/tipoRede';
import PocTipoVoltagem from 'core/models/POC/tipoVoltagem';
import api from '../api';

const baseURL = `/POC/PocPocoBombaInstalada`;

export const getTipoBombaAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteTipoBomba`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    tiposbomba: value?.map((json) => new PocTipoBomba(json)) ?? [],
  };
};

export const getTipoRedeAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteTipoRede`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    tiposrede: value?.map((json) => new PocTipoRede(json)) ?? [],
  };
};

export const getTipoVoltagemAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteTipoVoltagem`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    tiposvoltagem: value?.map((json) => new PocTipoVoltagem(json)) ?? [],
  };
};
