export default class RecebimentoMassivoItem {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.vlrTotal = jsonObject.vlrTotal;
    this.nrSeqRecebimentoMassivo = jsonObject.nrSeqRecebimentoMassivo;
    this.dtRecebimento = jsonObject.dtRecebimento;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqContaCorrente = jsonObject.nrSeqContaCorrente;
    this.nrSeqRecebimentoMassivoItem = jsonObject.nrSeqRecebimentoMassivoItem;
    this.vlrMulta = jsonObject.vlrMulta;
    this.vlrJuro = jsonObject.vlrJuro;
    this.vlrSaldoRecebido = jsonObject.vlrSaldoRecebido;
    this.dtCredito = jsonObject.dtCredito;
  }

  toJson = () => JSON.stringify(this);
}
