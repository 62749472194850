import { Usuario } from '../SEG';
import FormaPagamento from './formaPagamento';
import TituloReceber from './tituloReceber';

export default class Recebimento {
  constructor(jsonObject = {}) {
    this.nrSeqRecebimento = jsonObject.nrSeqRecebimento;
    this.dtRecebimento = jsonObject.dtRecebimento;
    this.nrIdentificado = jsonObject.nrIdentificado;
    this.vlrTotal = jsonObject.vlrTotal;
    this.nrDiasAtraso = jsonObject.nrDiasAtraso;
    this.flgDescontoPendente = jsonObject.flgDescontoPendente;
    this.dtEstorno = jsonObject.dtEstorno;
    this.vlrJuro = jsonObject.vlrJuro;
    this.vlrMulta = jsonObject.vlrMulta;
    this.vlrSaldoRecebido = jsonObject.vlrSaldoRecebido;
    this.nrSeqTituloReceber = jsonObject.nrSeqTituloReceber;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqContaCorrente = jsonObject.nrSeqContaCorrente;
    this.nrSeqExtratoCategoria = jsonObject.nrSeqExtratoCategoria;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqExtrato = jsonObject.nrSeqExtrato;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqCheque = jsonObject.nrSeqCheque;
    this.nrSeqRecebimentoMassivo = jsonObject.nrSeqRecebimentoMassivo;
    this.vlrDesconto = jsonObject.vlrDesconto;
    this.obs = jsonObject.obs;
    this.dtCredito = jsonObject.dtCredito;
    this.nrSeqTipoRecebimento = jsonObject.nrSeqTipoRecebimento;
    this.tituloReceber = new TituloReceber(jsonObject.tituloReceber ?? {});
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.recebimentos = jsonObject.recebimentos;
    this.usuario = new Usuario(jsonObject.usuario ?? {});
  }

  toJson = () => JSON.stringify(this);
}
