import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  BootstrapSizes,
  ResponseStatus,
  Theme,
  JustifyContent,
  toBase64,
} from 'ui/Helpers/utils';
import {
  deleteEmpresaTipoDocumentoEletronico,
  postEmpresaTipoDocumentoEletronico,
  saveEmpresaTipoDocumentoEletronico,
  installCertificate,
  downloadCertificate,
} from 'core/services/SEG/empresaTipoDocumentoEletronico';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  RadioButton,
  Switch,
  Panel,
  FileUpload,
  Button,
} from 'ui/components';

const tiposDocumentoEletronico = [
  { Key: 0, noTipoDocumentoEletronico: 'NFe', tipoDocumentoEletronico: 0 },
  { Key: 1, noTipoDocumentoEletronico: 'CTe', tipoDocumentoEletronico: 1 },
  { Key: 2, noTipoDocumentoEletronico: 'NFSe', tipoDocumentoEletronico: 2 },
  { Key: 3, noTipoDocumentoEletronico: 'MDFe', tipoDocumentoEletronico: 3 },
];
const tiposCrt = [
  { Key: 0, noCrt: 'Simples Nacional', crt: '1' },
  { Key: 1, noCrt: 'Simples Excesso Receita', crt: '2' },
  { Key: 2, noCrt: 'Regime Normal', crt: '3' },
];
const tiposVersao = [
  { Key: 0, noVersao: 'Versão 2.0', versao: '2.00' },
  { Key: 1, noVersao: 'Versão 3.0', versao: '3.00' },
  { Key: 2, noVersao: 'UniNfe Versão 3.1', versao: 'UniNfe Versão 3.1' },
  { Key: 3, noVersao: 'UniNfe Versão 4.0', versao: 'UniNfe Versão 4.0' },
  { Key: 4, noVersao: '3.1', versao: '3.1' },
  { Key: 5, noVersao: '4.00', versao: '4.00' },
];
const tiposProtocolo = [
  { Key: 0, noProtocoloEnvio: 'SSL', protocoloEnvio: 'SSL' },
  { Key: 1, noProtocoloEnvio: 'TLS1.0', protocoloEnvio: 'TLS1.0' },
  { Key: 2, noProtocoloEnvio: 'TLS1.1', protocoloEnvio: 'TLS1.1' },
  { Key: 3, noProtocoloEnvio: 'TLS1.2', protocoloEnvio: 'TLS1.2' },
];
const tiposEmissao = [
  { Key: 0, noTpEmissao: 'Normal', tpEmissao: 1 },
  {
    Key: 1,
    noTpEmissao: 'Contingência com formulário de segurança (FS)',
    tpEmissao: 2,
  },
  {
    Key: 2,
    noTpEmissao: 'Contingência com SCAN do Ambiente Nacional',
    tpEmissao: 3,
  },
  {
    Key: 3,
    noTpEmissao: 'Contingência com DPEC',
    tpEmissao: 4,
  },
  {
    Key: 4,
    noTpEmissao: 'Contingência com formulário de segurança (FS-DA)',
    tpEmissao: 5,
  },
  {
    Key: 5,
    noTpEmissao: 'Contingência com SVC-AN',
    tpEmissao: 6,
  },
  {
    Key: 6,
    noTpEmissao: 'Contingência com SVC-RS',
    tpEmissao: 7,
  },
  {
    Key: 7,
    noTpEmissao: 'Contingência com SVC-SP',
    tpEmissao: 8,
  },
];

export default function EmpresaTipoDocumentoEletronicoItem({
  registryKey,
  reload,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({
    ambienteProducao: 'S',
    status: 'Inserir',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [importedFile, setImportedFile] = useState([]);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onNew = () => {
    setData({
      ambienteProducao: 'S',
      status: 'Inserir',
      vlrAliquotaIssqn: 0,
      vlrAliquotaPis: 0,
      vlrAliquotaCofins: 0,
      vlrAliquotaIRRetido: 0,
      vlrAliquotaIRContador: 0,
      vlrAliquotaIRDesoneradoContador: 0,
      vlrAliquotaSimples: 0,
      serie: undefined,
    });
    onSetMessage();
  };

  const handleDownloadCertificate = async () => {
    setLoading(true);

    const { status, message: msg, data: value } = await downloadCertificate({
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    onSetMessage(status, msg);
    if (data) {
      const xmlURI = encodeURIComponent(value);
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:text/plain;charset=utf-8,${xmlURI}`;
      downloadLink.download = `certificado.pfx`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const handleInstallCertificate = async () => {
    if (!data.nrSeqEmpresa) {
      onSetMessage(1, 'Necessário informar a empresa');
      return;
    }

    if (!data.noCertificadoSenha) {
      onSetMessage(1, 'Necessário informar a senha do certificado');
      return;
    }

    if (importedFile.length === 0) {
      onSetMessage(1, 'Necessário informar o certificado');
      return;
    }

    setLoading(true);
    const dataNewCertificate = {
      noCertificadoSenha: data.noCertificadoSenha,
      noBase64Certificado: await toBase64(importedFile[0]),
      nrSeqEmpresa: data.nrSeqEmpresa,
    };
    const { status, message: msg } = await installCertificate(
      dataNewCertificate
    );
    setLoading(false);
    setImportedFile([]);
    onSetMessage(status, msg);
  };

  const onSearchTipoProtocoloDefaultLoad = async (noProtocolo) => {
    const listDefault = tiposProtocolo.find(
      (item) => item.noProtocoloEnvio === noProtocolo
    );
    return listDefault;
  };
  const onSearchTipoVersaoDefaultLoad = async (versao) => {
    const listDefault = tiposVersao.find((item) => item.versao === versao);
    return listDefault;
  };
  const onSearchTipoCrtDefaultLoad = async (crt) => {
    const listDefault = tiposCrt.find((item) => item.crt === crt);
    return listDefault;
  };
  const onSearchTipoDocumentoEletronicoDefaultLoad = async (
    tipoDocumentoEletronico
  ) => {
    const listDefault = tiposDocumentoEletronico.find(
      (item) => item.tipoDocumentoEletronico === tipoDocumentoEletronico
    );
    return listDefault;
  };
  const onSearchTipoEmissaoDefaultLoad = async (tpEmissao) => {
    const listDefault = tiposEmissao.find(
      (item) => item.tpEmissao === tpEmissao
    );
    return listDefault;
  };
  /* const validaCertificado = async () => {
    const { status, message: msgCertificado } = await certificateValidation();
    return { status, message: msgCertificado };
  }; */

  const load = useCallback(async () => {
    setLoading(true);
    if (
      Number.isInteger(registryKey.nrSeqEmpresa) &&
      Number.isInteger(registryKey.tipoDocumentoEletronico)
    ) {
      const {
        data: objDocumentoEletronico,
      } = await postEmpresaTipoDocumentoEletronico({
        nrSeqEmpresa: registryKey.nrSeqEmpresa,
        tipoDocumentoEletronico: registryKey.tipoDocumentoEletronico,
      });
      /* const {
        status: statusCertificado,
        message: msgCertificado,
      } = await validaCertificado();
*/
      if (objDocumentoEletronico != null) {
        const loadDefaults = async () => {
          const tipoProtocolo = await onSearchTipoProtocoloDefaultLoad(
            objDocumentoEletronico.noProtocoloEnvio
          );
          const tipoVersao = await onSearchTipoVersaoDefaultLoad(
            objDocumentoEletronico.versao
          );
          const tipoCrt = await onSearchTipoCrtDefaultLoad(
            objDocumentoEletronico.crt
          );
          const documentoEletronico = await onSearchTipoDocumentoEletronicoDefaultLoad(
            objDocumentoEletronico.tipoDocumentoEletronico
          );
          const tipoEmissao = await onSearchTipoEmissaoDefaultLoad(
            objDocumentoEletronico.tpEmissao
          );

          setData({
            ...objDocumentoEletronico,
            status: 'Alterar',
            ambienteProducao: objDocumentoEletronico.flgAmbienteProducao
              ? 'S'
              : 'N',
            objTipoProtocolo: tipoProtocolo,
            objTipoVersao: tipoVersao,
            objTipoCrt: tipoCrt,
            objTipoDocumentoEletronico: documentoEletronico,
            objTipoEmissao: tipoEmissao,
            protocoloEnvio: tipoProtocolo.protocoloEnvio,
            noProtocoloEnvio: tipoProtocolo.noProtocoloEnvio,
            versao: tipoVersao.versao,
            noVersao: tipoVersao.noVersao,
            crt: tipoCrt.crt,
            noCrt: tipoCrt.noCrt,
            tipoDocumentoEletronico:
              documentoEletronico.tipoDocumentoEletronico,
            noTipoDocumentoEletronico:
              documentoEletronico.noTipoDocumentoEletronico,
            tpEmissao: tipoEmissao.tpEmissao,
            noTpEmissao: tipoEmissao.noTpEmissao,
          });
        };

        loadDefaults();
      }

      // onSetMessage(statusCertificado, msgCertificado);
    } else {
      onNew();
    }
    setLoading(false);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const save = async () => {
    setLoading(true);
    data.flgAmbienteProducao = data.ambienteProducao === 'S';
    const { status, message: msg } = await saveEmpresaTipoDocumentoEletronico(
      data
    );

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(
      status === ResponseStatus.Success
        ? {
            vlrAliquotaIssqn: 0,
            vlrAliquotaPis: 0,
            vlrAliquotaCofins: 0,
            vlrAliquotaIRRetido: 0,
            vlrAliquotaIRContador: 0,
            vlrAliquotaIRDesoneradoContador: 0,
            vlrAliquotaSimples: 0,
            serie: undefined,
          }
        : data
    );

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };
  const onExcluir = async () => {
    if (data.nrSeqEmpresa > 0 && data.tipoDocumentoEletronico > 0) {
      setLoading(true);
      const {
        status,
        message: msg,
      } = await deleteEmpresaTipoDocumentoEletronico({
        nrSeqEmpresa: data.nrSeqEmpresa,
        tipoDocumentoEletronico: data.tipoDocumentoEletronico,
      });

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchTipoProtocoloDefault = async () => {
    const listDefault = tiposProtocolo.find(
      (item) => item.protocoloEnvio === 'SSL'
    );
    return listDefault;
  };

  const onSearchTipoProtocolo = async (searchText) => {
    const searchTerm = searchText.toLowerCase();
    const filteredTiposProtocolo = tiposProtocolo
      .filter((objTipoProtocolo) =>
        objTipoProtocolo.noProtocoloEnvio.toLowerCase().includes(searchTerm)
      )
      .sort((a, b) => a.noProtocoloEnvio.localeCompare(b.noProtocoloEnvio));
    return filteredTiposProtocolo;
  };

  const onSearchTipoVersaoDefault = async () => {
    const listDefault = tiposVersao.find((item) => item.versao === '3.00');
    return listDefault;
  };

  const onSearchTipoVersao = async (searchText) => {
    const searchTerm = searchText.toLowerCase();
    const filteredTiposVersao = tiposVersao
      .filter((objTipoVersao) =>
        objTipoVersao.noVersao.toLowerCase().includes(searchTerm)
      )
      .sort((a, b) => a.noVersao.localeCompare(b.noVersao));
    return filteredTiposVersao;
  };

  const onSearchTipoCrtDefault = async () => {
    const listDefault = tiposCrt.find((item) => item.crt === '3');
    return listDefault;
  };

  const onSearchTipoCrt = async (searchText) => {
    const searchTerm = searchText.toLowerCase();
    const filteredTiposCrt = tiposCrt
      .filter((objTipoCrt) =>
        objTipoCrt.noCrt.toLowerCase().includes(searchTerm)
      )
      .sort((a, b) => a.noCrt.localeCompare(b.noCrt));
    return filteredTiposCrt;
  };

  const onSearchTipoDocumentoEletronicoDefault = async () => {
    const listDefault = tiposDocumentoEletronico.find(
      (item) => item.tipoDocumentoEletronico === 1
    );
    return listDefault;
  };

  const onSearchTipoDocumentoEletronico = async (searchText) => {
    const searchTerm = searchText.toLowerCase();
    const filteredTiposDocumentoEletronico = tiposDocumentoEletronico
      .filter((objTipoDocumentoEletronico) =>
        objTipoDocumentoEletronico.noTipoDocumentoEletronico
          .toLowerCase()
          .includes(searchTerm)
      )
      .sort((a, b) =>
        a.noTipoDocumentoEletronico.localeCompare(b.noTipoDocumentoEletronico)
      );
    return filteredTiposDocumentoEletronico;
  };

  const onSearchTipoEmissaoDefault = async () => {
    const listDefault = tiposEmissao.find((item) => item.tpEmissao === 1);
    return listDefault;
  };

  const onSearchTipoEmissao = async (searchText) => {
    const searchTerm = searchText.toLowerCase();
    const filteredTiposEmissao = tiposEmissao
      .filter((objTipoEmissao) =>
        objTipoEmissao.noTpEmissao.toLowerCase().includes(searchTerm)
      )
      .sort((a, b) => a.noTpEmissao.localeCompare(b.noTpEmissao));
    return filteredTiposEmissao;
  };

  useEffect(() => {
    const loadDefaults = async () => {
      const tipoProtocolo = await onSearchTipoProtocoloDefault();
      const tipoVersao = await onSearchTipoVersaoDefault();
      const tipoCrt = await onSearchTipoCrtDefault();
      const documentoEletronico = await onSearchTipoDocumentoEletronicoDefault();
      const tipoEmissao = await onSearchTipoEmissaoDefault();

      setData({
        ...data,
        objTipoProtocolo: tipoProtocolo,
        objTipoVersao: tipoVersao,
        objTipoCrt: tipoCrt,
        objTipoDocumentoEletronico: documentoEletronico,
        objTipoEmissao: tipoEmissao,
        protocoloEnvio: tipoProtocolo.protocoloEnvio,
        noProtocoloEnvio: tipoProtocolo.noProtocoloEnvio,
        versao: tipoVersao.versao,
        noVersao: tipoVersao.noVersao,
        crt: tipoCrt.crt,
        noCrt: tipoCrt.noCrt,
        tipoDocumentoEletronico: documentoEletronico.tipoDocumentoEletronico,
        noTipoDocumentoEletronico:
          documentoEletronico.noTipoDocumentoEletronico,
        tpEmissao: tipoEmissao.tpEmissao,
        noTpEmissao: tipoEmissao.noTpEmissao,
      });
    };

    loadDefaults();
  }, []);
  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return empresas;
  };
  const onSearchEstado = async (e) => {
    const { status, message: msg, estados } = await getEstadoAutoComplete({
      noEstado: e,
    });
    if (msg) onSetMessage(status, msg);
    return estados;
  };

  const ambiente = [
    { text: 'Produção ', value: 'S' },
    { text: 'Homologação', value: 'N' },
  ];

  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de DocumentoEletronico'
      loading={loading}
      onNew={() => onNew()}
      onSave={save}
      onDelete={
        data?.nrSeqEmpresa > 0 && data?.tipoDocumentoEletronico
          ? onExcluir
          : null
      }
      message={message}
      ref={formSubmit}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <Panel>
        <Panel.Header
          icon={['far', 'file']}
          title='Documento Eletronico'
          theme={Theme.Primary}
          align={JustifyContent.Start}
        />
        <Panel.Body>
          <div className='row '>
            <div className='col-5 mb-3'>
              <Autocomplete
                required
                label='Empresa'
                searchDataSource={onSearchEmpresa}
                selectedItem={data.empresa}
                onSelectItem={(empresa) =>
                  setData({
                    ...data,
                    empresa,
                    nrSeqEmpresa: empresa.nrSeqEmpresa,
                  })
                }
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-3 mb-3'>
              <Autocomplete
                required
                label='Tipo Documento'
                searchDataSource={onSearchTipoDocumentoEletronico}
                selectedItem={data.objTipoDocumentoEletronico}
                onSelectItem={(objTipoDocumentoEletronico) => {
                  setData({
                    ...data,
                    objTipoDocumentoEletronico,
                    tipoDocumentoEletronico:
                      objTipoDocumentoEletronico.tipoDocumentoEletronico,
                    noTipoDocumentoEletronico:
                      objTipoDocumentoEletronico.noTipoDocumentoEletronico,
                  });
                }}
                dataSourceTextProperty='noTipoDocumentoEletronico'
                dataSource={tiposDocumentoEletronico}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-5'>
              <Textbox
                required
                label='Diretorio'
                maxLength={200}
                text={data.diretorioXml}
                onChangedValue={(diretorioXml) => {
                  setData({ ...data, diretorioXml });
                }}
              />
            </div>
            <div className='col-3 mb-3'>
              <Autocomplete
                required
                label='Tipo Emissão'
                searchDataSource={onSearchTipoEmissao}
                selectedItem={data.objTipoEmissao}
                onSelectItem={(objTipoEmissao) => {
                  setData({
                    ...data,
                    objTipoEmissao,
                    tpEmissao: objTipoEmissao.tpEmissao,
                    noTpEmissao: objTipoEmissao.noTpEmissao,
                  });
                }}
                dataSourceTextProperty='noTpEmissao'
                dataSource={tiposEmissao}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-3 mb-3'>
              <Autocomplete
                required
                label='Tipo Empresa'
                searchDataSource={onSearchTipoCrt}
                selectedItem={data.objTipoCrt}
                onSelectItem={(objTipoCrt) => {
                  setData({
                    ...data,
                    objTipoCrt,
                    crt: objTipoCrt.crt,
                    noCrt: objTipoCrt.noCrt,
                  });
                }}
                dataSourceTextProperty='noCrt'
                dataSource={tiposCrt}
              />
            </div>
            <div className='col-3 mb-3'>
              <Autocomplete
                required
                label='Estado'
                searchDataSource={onSearchEstado}
                selectedItem={data.estado}
                onSelectItem={(estado) => {
                  setData({
                    ...data,
                    estado,
                    nrSeqEstado: estado.nrSeqEstado,
                  });
                }}
                dataSourceTextProperty='noEstado'
              />
            </div>
            <div className='col-3 mb-3'>
              <Autocomplete
                required
                label='Protocolo Envio'
                searchDataSource={onSearchTipoProtocolo}
                selectedItem={data.objTipoProtocolo}
                onSelectItem={(objTipoProtocolo) => {
                  setData({
                    ...data,
                    objTipoProtocolo,
                    noProtocoloEnvio: objTipoProtocolo.protocoloEnvio,
                    noVersao: objTipoProtocolo.noProtocoloEnvio,
                  });
                }}
                dataSourceTextProperty='noProtocoloEnvio'
                dataSource={tiposProtocolo}
              />
            </div>

            <div className='col-3 mb-3'>
              <Autocomplete
                required
                label='Versão'
                searchDataSource={onSearchTipoVersao}
                selectedItem={data.objTipoVersao}
                onSelectItem={(objTipoVersao) => {
                  setData({
                    ...data,
                    objTipoVersao,
                    versao: objTipoVersao.versao,
                    noVersao: objTipoVersao.noVersao,
                  });
                }}
                dataSourceTextProperty='noVersao'
                dataSource={tiposVersao}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-3'>
              <RadioButton
                outline
                label='Ambiente :'
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={data.ambienteProducao}
                onChange={(ambienteProducao) => {
                  setData({ ...data, ambienteProducao });
                }}
                buttons={ambiente}
              />
            </div>
            <div className='col mb-3'>
              <Switch
                formControl
                label='Envia Documento Automático'
                checked={data.flgEnviaDocumentoAutomatico}
                onChange={(flgEnviaDocumentoAutomatico) =>
                  setData({ ...data, flgEnviaDocumentoAutomatico })
                }
              />
            </div>
            <div className='col mb-3'>
              <Switch
                formControl
                label='Síncrono'
                checked={data.flgSincrono}
                onChange={(flgSincrono) => setData({ ...data, flgSincrono })}
              />
            </div>
            <div className='col mb-3'>
              <Switch
                formControl
                label='MDFE Integrado'
                checked={data.flgMdfeIntegrado}
                onChange={(flgMdfeIntegrado) =>
                  setData({ ...data, flgMdfeIntegrado })
                }
              />
            </div>
            <div className='col mb-3'>
              <Switch
                formControl
                label='Padrão Nacional'
                checked={data.flgPadraoNacional}
                onChange={(flgPadraoNacional) =>
                  setData({ ...data, flgPadraoNacional })
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-6 mb-3'>
              <Textbox
                label='Certificado Digital ThumbPrint'
                maxLength={200}
                readOnly
                text={data.certificadodigitalthumbprint}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col mb-3'>
              <Textbox
                label='Certificado '
                maxLength={200}
                readOnly
                text={data.noCertificado}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <Panel>
        <Panel.Header
          icon={['far', 'file']}
          title='Impostos'
          theme={Theme.Primary}
          align={JustifyContent.Start}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col-3 mb-3'>
              <Textbox
                label='Aliquota ISSQN'
                maxLength={20}
                mask={MaskTypes.DecimalCustom}
                text={data.vlrAliquotaIssqn}
                onChangedValue={(vlrAliquotaIssqn) => {
                  setData({ ...data, vlrAliquotaIssqn });
                }}
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Aliquota PIS'
                maxLength={20}
                mask={MaskTypes.DecimalCustom}
                text={data.vlrAliquotaPis}
                onChangedValue={(vlrAliquotaPis) => {
                  setData({ ...data, vlrAliquotaPis });
                }}
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Aliquota COFINS'
                maxLength={20}
                mask={MaskTypes.DecimalCustom}
                text={data.vlrAliquotaCofins}
                onChangedValue={(vlrAliquotaCofins) => {
                  setData({ ...data, vlrAliquotaCofins });
                }}
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Aliquota IR Retido'
                maxLength={20}
                mask={MaskTypes.DecimalCustom}
                text={data.vlrAliquotaIRRetido}
                onChangedValue={(vlrAliquotaIRRetido) => {
                  setData({ ...data, vlrAliquotaIRRetido });
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-3 mb-3'>
              <Textbox
                label='Aliquota IR Contador'
                maxLength={20}
                mask={MaskTypes.DecimalCustom}
                text={data.vlrAliquotaIRContador}
                onChangedValue={(vlrAliquotaIRContador) => {
                  setData({ ...data, vlrAliquotaIRContador });
                }}
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Aliquota IR Desonerado Contador'
                maxLength={20}
                mask={MaskTypes.DecimalCustom}
                text={data.vlrAliquotaIRDesoneradoContador}
                onChangedValue={(vlrAliquotaIRDesoneradoContador) => {
                  setData({ ...data, vlrAliquotaIRDesoneradoContador });
                }}
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Aliquota Simples'
                maxLength={20}
                mask={MaskTypes.DecimalCustom}
                text={data.vlrAliquotaSimples}
                onChangedValue={(vlrAliquotaSimples) => {
                  setData({ ...data, vlrAliquotaSimples });
                }}
              />
            </div>
            <div className='col-2 mb-3'>
              <Textbox
                label='Série'
                maxLength={6}
                text={data.serie}
                onChangedValue={(serie) => {
                  setData({ ...data, serie });
                }}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <Panel>
        <Panel.Header
          icon={['far', 'file']}
          title='Consulta Sefaz'
          theme={Theme.Primary}
          align={JustifyContent.Start}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col-3 mb-3'>
              <Textbox
                label='Nr Consultas Sefaz'
                maxLength={6}
                readOnly
                text={data.nrConsultasSefaz}
                onChangedValue={(nrConsultasSefaz) => {
                  setData({ ...data, nrConsultasSefaz });
                }}
              />
            </div>

            <div className='col-3 mb-3'>
              <Textbox
                label='Nr NsuNfe'
                maxLength={6}
                text={data.nrNsuNfe}
                onChangedValue={(nrNsuNfe) => {
                  setData({ ...data, nrNsuNfe });
                }}
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Data Ultima Consulta Sefaz'
                text={data.dtUltimaConsultaSefaz}
                maxLength={10}
                readOnly
                onChange={(dtUltimaConsultaSefaz) => {
                  setData({ ...data, dtUltimaConsultaSefaz });
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-3 mb-3'>
              <Textbox
                label='Id Cliente'
                maxLength={50}
                text={data.noClientId}
                onChangedValue={(noClientId) => {
                  setData({ ...data, noClientId });
                }}
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Cliente Secret'
                maxLength={50}
                text={data.noClientSecret}
                onChangedValue={(noClientSecret) => {
                  setData({ ...data, noClientSecret });
                }}
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Senha Autenticacao'
                maxLength={50}
                text={data.noSenhaAutenticacao}
                onChangedValue={(noSenhaAutenticacao) => {
                  setData({ ...data, noSenhaAutenticacao });
                }}
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Codigo AEDF'
                maxLength={50}
                text={data.noCodigoAEDF}
                onChangedValue={(noCodigoAEDF) => {
                  setData({ ...data, noCodigoAEDF });
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-3 mb-3'>
              <Textbox
                label='Id Csc Produção'
                maxLength={80}
                text={data.idCscProducao}
                onChangedValue={(idCscProducao) => {
                  setData({ ...data, idCscProducao });
                }}
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Id Csc Homologacao'
                maxLength={80}
                text={data.idCscHomologacao}
                onChangedValue={(idCscHomologacao) => {
                  setData({ ...data, idCscHomologacao });
                }}
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Csc Producao'
                maxLength={80}
                text={data.noCscProducao}
                onChangedValue={(noCscProducao) => {
                  setData({ ...data, noCscProducao });
                }}
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Csc Homologacao'
                maxLength={80}
                text={data.noCscHomologacao}
                onChangedValue={(noCscHomologacao) => {
                  setData({ ...data, noCscHomologacao });
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col mb-3'>
              <Textbox
                label='Ultimo Xml Consulta Sefaz'
                maxLength={200}
                readOnly
                text={data.noUltimoXmlConsultaSefaz}
                onChangedValue={(noUltimoXmlConsultaSefaz) => {
                  setData({ ...data, noUltimoXmlConsultaSefaz });
                }}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Header
          icon={['far', 'file']}
          title='Instalar Novo Certificado'
          theme={Theme.Primary}
          align={JustifyContent.Start}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col-6'>
              <FileUpload
                files={importedFile}
                onChange={(files) => setImportedFile(files)}
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='Senha Certificado'
                maxLength={50}
                text={data.noCertificadoSenha}
                onChangedValue={(noCertificadoSenha) => {
                  setData({ ...data, noCertificadoSenha });
                }}
              />
            </div>
            <div className='col-3 mt-3'>
              <Button
                onClick={handleInstallCertificate}
                text='Instalar Certificado'
                visible
                outline
                theme={Theme.Success}
                template={Button.Templates.Button}
                icon='plus'
                size={BootstrapSizes.Medium}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col mt-3'>
              <Button
                onClick={handleDownloadCertificate}
                text='Download Certificado'
                visible
                outline
                theme={Theme.Success}
                template={Button.Templates.Button}
                icon='save'
                size={BootstrapSizes.Medium}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </CSDManutPage>
  );
}
