import TituloPagar from './tituloPagar';

export default class TituloPagarCustoFixo {
  constructor(jsonObject = {}) {
    this.nrSeqCustoFixo = jsonObject.nrSeqCustoFixo;
    this.anoMes = jsonObject.anoMes;
    this.nrSeqTituloPagar = jsonObject.nrSeqTituloPagar;
    this.tituloPagar = new TituloPagar(jsonObject.tituloPagar ?? {});

    // props auxiliares
    this.noObservacoes = jsonObject.noObservacoes;
  }

  toJson = () => JSON.stringify(this);
}
