export default class PocTipoFiltro {
  constructor(jsonObject = {}) {
    this.nrSeqPocTipoFiltro = jsonObject.nrSeqPocTipoFiltro;
    this.cdPocTipoFiltro = jsonObject.cdPocTipoFiltro;
    this.noPocTipoFiltro = jsonObject.noPocTipoFiltro;
    this.nrSlot = jsonObject.nrSlot;
    this.flgAtivo = jsonObject.flgAtivo;
  }

  toJson = () => JSON.stringify(this);
}
