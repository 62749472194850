export default class EnergiaSearchMediaConsumo {
  nrSeqEnergiaConta: number;

  vlrMedia: number;

  constructor(jsonObject: EnergiaSearchMediaConsumo) {
    this.nrSeqEnergiaConta = jsonObject.nrSeqEnergiaConta;
    this.vlrMedia = jsonObject.vlrMedia;
  }

  toJson = () => JSON.stringify(this);
}
