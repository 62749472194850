export default class TipoCargaMdfe {
  constructor(jsonObject = {}) {
    this.nrSeqTipoCargaMdfe = jsonObject.nrSeqTipoCargaMdfe;
    this.noTipoCargaMdfe = jsonObject.noTipoCargaMdfe;
    this.flgInativo = jsonObject.flgInativo;
    this.flgPadrao = jsonObject.flgPadrao;
  }

  toJson = () => JSON.stringify(this);
}
