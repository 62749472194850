import { getTipoCartaoAutoComplete } from 'core/services/FRO/tipoCartao';
import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Autocomplete,
  Button,
  DatePicker,
  GridView,
  Textbox,
  Panel,
} from 'ui/components';
import {
  Theme,
  ResponseStatus,
  BootstrapSizes,
  ColumnTypes,
  ColumnDataTypes,
  StatusGrid,
} from 'ui/Helpers/enums';
import { JustifyContent } from 'ui/Helpers/utils';
// @ts-expect-error
import { GridviewColumns } from 'ui/Helpers/interfaces.ts';
import { IMotorista, ITipoCartaoVinculo } from 'core/interfaces/FRO';

interface IDocumentsPanelProps {
  motoristaData: IMotorista;
  setMotoristaData: (e: Object) => void;
  onSetMessage: (status: ResponseStatus, msg: string) => void;
}

interface ITipoCartaoVinculoData extends ITipoCartaoVinculo {
  status?: StatusGrid;
  flgExibeDownload?: boolean;
  importedFile?: any;
}

const TipoCartaoVinculosPanel = forwardRef(
  (
    { motoristaData, setMotoristaData, onSetMessage }: IDocumentsPanelProps,
    ref
  ) => {
    const gridView = useRef<any>();

    const [
      tipoCartaoVinculoData,
      setTipoCartaoVinculoData,
    ] = useState<ITipoCartaoVinculoData>({});

    const fillGridView = () => {
      if (
        gridView &&
        gridView.current &&
        motoristaData.motoristaTipoCartaoVinculos &&
        motoristaData.motoristaTipoCartaoVinculos.length > 0
      ) {
        const gridList = motoristaData.motoristaTipoCartaoVinculos.map(
          (item) => {
            if (item.noImagem) {
              item.flgExibeDownload = true;
            } else {
              item.flgExibeDownload = false;
            }
            return item;
          }
        );
        gridView.current.setDataSource(gridList);
      }
    };

    useImperativeHandle(ref, () => ({
      clear() {
        setTipoCartaoVinculoData({});
        gridView?.current?.setDataSource([]);
      },
      fillGrid() {
        fillGridView();
      },
      getDataGrid() {
        const listaDocsGrid = gridView?.current?.getDataSource() ?? [];
        return listaDocsGrid;
      },
    }));

    const onSearchTipoCartao = async (e) => {
      const {
        status,
        message: msg,
        data: tiposCartoes,
      } = await getTipoCartaoAutoComplete({
        noIntegracao: e,
      });

      if (msg) onSetMessage(status, msg);
      return tiposCartoes;
    };

    const onClickAddDocumento = async () => {
      if (
        tipoCartaoVinculoData.nrSeqTipoCartao > 0 &&
        tipoCartaoVinculoData.numeroCartao
      ) {
        const obj = {
          ...tipoCartaoVinculoData,
          status: tipoCartaoVinculoData.status ?? StatusGrid.Inserir,
        };
        const list = [...gridView.current.getDataSource(), obj];

        if (gridView && gridView.current) gridView.current.setDataSource(list);

        setMotoristaData({
          ...motoristaData,
          motoristaTipoCartaoVinculos: list,
        });
        setTipoCartaoVinculoData({});
      } else if (
        tipoCartaoVinculoData.nrSeqTipoCartao === 0 ||
        tipoCartaoVinculoData.nrSeqTipoCartao === undefined
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o Tipo de Cartão');
      } else if (!tipoCartaoVinculoData.numeroCartao) {
        onSetMessage(ResponseStatus.Error, 'Informe o Número do Cartão');
      }
    };

    const onClickEditCartaoVinculado = (e: any, datasource: any[]) => {
      const obj = datasource.find((item) => item === e);

      if (obj.status !== StatusGrid.Inserir) {
        obj.status = StatusGrid.Alterar;
      }

      setTipoCartaoVinculoData({ ...obj });

      const listaEquipamentos = datasource.filter((item) => item !== e);

      if (gridView && gridView.current)
        gridView.current.setDataSource(listaEquipamentos);
    };

    const onClickRemoveCartaoVinculado = async (e: any) => {
      const list = gridView.current.getDataSource();

      list.filter((el: any) => el === e)[0].status = StatusGrid.Remover;

      if (gridView && gridView.current) gridView.current.setDataSource(list);
    };

    const columns: Array<GridviewColumns> = [
      {
        key: 'tipoCartao.noTipoCartao',
        title: 'Tipo',
        sortable: false,
      },
      { key: 'numeroCartao', title: 'Número', sortable: false },
      { key: 'vlrSaldo', title: 'Saldo' },
      {
        key: 'noSenha',
        title: 'Senha',
        sortable: false,
      },
      {
        key: 'dtInicio',
        title: 'Data Inicio',
        sortable: false,
        format: ColumnDataTypes.Date,
      },
      {
        key: 'dtFim',
        title: 'Data Fim',
        sortable: false,
        format: ColumnDataTypes.Date,
      },
      {
        key: 'nrSeqTipoCartaoVinculo_btnEdit',
        type: ColumnTypes.Button,
        onClick: (e: any, datasource: any) =>
          onClickEditCartaoVinculado(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
        title: 'Editar',
      },
      {
        key: 'nrSeqTipoCartaoVinculo_btnExcluir',
        type: ColumnTypes.Button,
        title: 'Excluir',
        onClick: (e: any) => onClickRemoveCartaoVinculado(e),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    return (
      <div className='col mt-3'>
        {/* @ts-expect-error */}
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header
            title='Cartão'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-5'>
                <Autocomplete
                  label='Tipo Cartão'
                  searchDataSource={onSearchTipoCartao}
                  selectedItem={tipoCartaoVinculoData.tipoCartao}
                  onSelectItem={(tipoCartao: any) =>
                    setTipoCartaoVinculoData({
                      ...tipoCartaoVinculoData,
                      tipoCartao,
                      nrSeqTipoCartao: tipoCartao.nrSeqTipoCartao,
                    })
                  }
                  dataSourceTextProperty='noTipoCartao'
                />
              </div>
              <div className='col-3'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Número do Cartão'
                  text={tipoCartaoVinculoData.numeroCartao}
                  maxLength={20}
                  onChangedValue={(numeroCartao: string) =>
                    setTipoCartaoVinculoData({
                      ...tipoCartaoVinculoData,
                      numeroCartao,
                    })
                  }
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <DatePicker
                  label='Inicio da Vigência do Cartão'
                  text={tipoCartaoVinculoData.dtInicio}
                  onChange={(dtInicio: string) =>
                    setTipoCartaoVinculoData({
                      ...tipoCartaoVinculoData,
                      dtInicio,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <DatePicker
                  label='Fim da Vigência do Cartão'
                  text={tipoCartaoVinculoData.dtFim}
                  onChange={(dtFim: string) =>
                    setTipoCartaoVinculoData({
                      ...tipoCartaoVinculoData,
                      dtFim,
                    })
                  }
                />
              </div>
              <div className='col d-flex align-items-end'>
                <Button
                  onClick={onClickAddDocumento}
                  outline
                  theme={Theme.Success}
                  text='Adicionar'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridView}
                  // @ts-expect-error
                  dataColumns={columns}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
);

export default TipoCartaoVinculosPanel;
