import qs from 'qs';
import Pagination from 'core/models/pagination';
import TipoOcorrenciaBoleto from 'core/models/FIN/tipoOcorrenciaBoleto';
import api from '../api';

const baseURL = `/FIN/TipoOcorrenciaBoleto`;

export const getTipoOcorrenciaBoletoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    tiposocorrencia: value?.map((json) => new TipoOcorrenciaBoleto(json)) ?? [],
  };
};

export const getTipoOcorrenciaBoletoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TipoOcorrenciaBoleto(json)) ?? [],
  };
};

export const getTipoOcorrenciaBoletoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TipoOcorrenciaBoleto(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getTipoOcorrenciaBoleto = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new TipoOcorrenciaBoleto(value) : {};
};

export const saveTipoOcorrenciaBoleto = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteTipoOcorrenciaBoleto = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printTipoOcorrenciaBoleto = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelTipoOcorrenciaBoleto = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllTipoOcorrenciaBoleto = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
