import { Estado } from 'core/models/SEG';
import api from '../api';

export const getEstadoList = async (filters) => {
  const response = await api.get(`/Estado/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    estados: value?.map((json) => new Estado(json)) ?? [],
  };
};

export const getEstadoAutoComplete = async (filters) => {
  const response = await api.get(`/Estado/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    estados: value?.map((json) => new Estado(json)) ?? [],
  };
};

export const getEstadoAutoCompleteViagem = async (filters) => {
  const response = await api.get('/Estado/AutoCompleteViagem', {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    estados: value?.map((json) => new Estado(json)) ?? [],
  };
};
