import { getTipoDocumentoPessoaAutoComplete } from 'core/services/SEG';
import React, { forwardRef, useState, useRef, useEffect } from 'react';
import {
  Autocomplete,
  Button,
  DatePicker,
  FileUpload,
  GridView,
  Textarea,
  Textbox,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  BootstrapSizes,
  MimeTypes,
  ResponseStatus,
  Theme,
  toBase64,
} from 'ui/Helpers/utils';

const DocumentoStep = forwardRef(({ data, setData, onSetMessage }) => {
  const gridView = useRef();
  const [docData, setDocData] = useState({});
  const [importedFile, setImportedFile] = useState([]);

  useEffect(() => {
    if (gridView && gridView.current && data.veiculoDocumentos) {
      const gridList = data.veiculoDocumentos.map((item) => {
        if (item.noImagem) {
          item.flgExibeDownload = true;
          item.flgPermiteEditar = false;
        } else {
          item.flgExibeDownload = false;
          item.flgPermiteEditar = true;
        }
        return item;
      });
      gridView.current.setDataSource(gridList);
    }

    setDocData({ obs: '' });
  }, []);

  const onSearchTipoDocumentoPessoa = async (e) => {
    const res = await getTipoDocumentoPessoaAutoComplete({
      noTipoDocumentoPessoa: e,
      flgVeiculo: true,
    });

    return res.data;
  };

  const onClickAddDocumento = async () => {
    const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;

    if (
      docData.nrSeqTipoDocumentoPessoa &&
      docData.nrDocumento &&
      ((docData.tipoDocumentoPessoa?.flgControlaValidade &&
        docData.dtIniValidade &&
        docData.dtFimValidade) ||
        !docData.tipoDocumentoPessoa?.flgControlaValidade)
    ) {
      const obj = {
        ...docData,
        status: docData.status ?? GridView.EnumStatus.Inserir,
        nrSeqUsuarioCad: sessionUser.nrSeqUsuario,
        flgExibeDownload: false,
        flgPermiteEditar: true,
      };

      if (importedFile?.length > 0) {
        const noImagemBase64 = await toBase64(importedFile[0]);

        const noImagem = String(noImagemBase64).substring(
          String(noImagemBase64).indexOf(',') + 1
        );

        obj.noImagem = noImagem;
        obj.tipo = importedFile[0].type;
        obj.tamanho = String(importedFile[0].size);
        obj.flgExibeDownload = true;
        obj.file = importedFile;
      } else {
        onSetMessage(ResponseStatus.Error, 'Nenhum Documento Anexado');
      }

      const list = gridView?.current?.getDataSource() ?? [];
      list.push(obj);

      if (gridView && gridView?.current) gridView?.current?.setDataSource(list);

      setData({ ...data, veiculoDocumentos: list });

      setImportedFile([]);

      setDocData({ obs: '' });
    } else if (!docData.tipoDocumentoPessoa) {
      onSetMessage(ResponseStatus.Error, 'Informe o Tipo Documento');
    } else if (!docData.nrDocumento) {
      onSetMessage(ResponseStatus.Error, 'Informe o Número do Documento');
    } else if (
      docData.tipoDocumentoPessoa?.flgControlaValidade &&
      (!docData.dtIniValidade || !docData.dtFimValidade)
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Informe as Datas de Inicio e Fim da Validade'
      );
    }
  };

  const onClickEditDocumento = (e, datasource) => {
    const obj = datasource.find((item) => item === e);

    if (obj.status !== GridView.EnumStatus.Inserir) {
      obj.status = GridView.EnumStatus.Alterar;
    }

    setDocData({ ...obj });
    setImportedFile(obj.file);

    const listaEquipamentos = datasource.filter((item) => item !== e);

    if (gridView && gridView.current)
      gridView.current.setDataSource(listaEquipamentos);
  };

  const onClickRemoveDocumento = async (e) => {
    const list = gridView.current.getDataSource();

    list.filter((el) => el === e)[0].status = GridView.EnumStatus.Remover;

    if (gridView && gridView.current) gridView.current.setDataSource(list);

    setData({ ...data, veiculoDocumentos: list });
  };

  const onClickDownload = async (e) => {
    const linkSource = `data:${e.tipo};base64, ${e.noImagem}`;

    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = e.noImagem;
    downloadLink.click();
  };

  const onClickBaixarTodos = async () => {
    const list = gridView.current.getDataSource();

    list.forEach((e) => {
      if (e.noImagem && e.status !== GridView.EnumStatus.Remover) {
        const linkSource = `data:image/png;base64, ${e.noImagem}`;

        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = e.noImagem;
        downloadLink.click();
      }
    });
  };

  const columns = [
    {
      key: 'tipoDocumentoPessoa.noTipoDocumentoPessoa',
      title: 'Tipo',
      sortable: false,
    },
    { key: 'nrDocumento', title: 'Número', sortable: false },
    {
      key: 'dtIniValidade',
      title: 'Inicio da Vigência',
      sortable: false,
      format: GridView.DataTypes.Date,
    },
    {
      key: 'dtFimValidade',
      title: 'Fim da Vigência',
      sortable: false,
      format: GridView.DataTypes.Date,
    },
    {
      key: 'obs',
      title: 'Observação',
      sortable: false,
    },
    {
      key: 'nrSeqVeiculoDocumento',
      type: GridView.ColumnTypes.Button,
      title: 'Baixar',
      onClick: (e, dataSource) => onClickDownload(e, dataSource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgExibeDownload',
    },
    {
      key: 'nrSeqVeiculoDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditDocumento(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
      title: 'Editar',
      visibleDynamic: 'flgPermiteEditar',
    },
    {
      key: 'nrSeqVeiculoDocumento',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemoveDocumento(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <div>
      <div className='row mb-3'>
        <div className='col-3'>
          <Textbox
            label='Número do Documento'
            text={docData.nrDocumento}
            maxLength={20}
            onChangedValue={(nrDocumento) =>
              setDocData({ ...docData, nrDocumento })
            }
          />
        </div>
        <div className='col-5'>
          <Autocomplete
            label='Tipo Documento'
            searchDataSource={onSearchTipoDocumentoPessoa}
            selectedItem={docData.tipoDocumentoPessoa}
            onSelectItem={(tipoDocumentoPessoa) =>
              setDocData({
                ...docData,
                tipoDocumentoPessoa,
                nrSeqTipoDocumentoPessoa:
                  tipoDocumentoPessoa.nrSeqTipoDocumentoPessoa,
              })
            }
            dataSourceTextProperty='noTipoDocumentoPessoa'
          />
        </div>
        {/* Perido de Validade */}
        {docData.tipoDocumentoPessoa?.flgControlaValidade && (
          <>
            <div className='col-2'>
              <DatePicker
                label='Inicio da Vigência'
                text={docData.dtIniValidade}
                mask={MaskTypes.Date}
                maxLength={10}
                onChange={(dtIniValidade) =>
                  setDocData({ ...docData, dtIniValidade })
                }
              />
            </div>
            <div className='col-2'>
              <DatePicker
                label='Fim da Vigência'
                text={docData.dtFimValidade}
                mask={MaskTypes.Date}
                maxLength={10}
                onChange={(dtFimValidade) =>
                  setDocData({ ...docData, dtFimValidade })
                }
              />
            </div>
          </>
        )}
      </div>
      {/* Upload de Imagem */}
      {docData.tipoDocumentoPessoa?.flgAceitaImg && (
        <div className='row mb-3'>
          <div className='col'>
            <FileUpload
              files={importedFile}
              onChange={(files) => {
                setImportedFile(files);
                setDocData({ ...docData, importedFile: files });
              }}
              allowedMimeTypes={[MimeTypes.Types.Image, MimeTypes.Types.PDF]}
            />
          </div>
        </div>
      )}
      <div className='row mb-3'>
        <div className='col-10'>
          <Textarea
            label='Observações'
            text={docData.obs}
            onChangedValue={(obs) => setDocData({ ...docData, obs })}
          />
        </div>
        <div className='col d-flex align-items-end'>
          <Button
            onClick={onClickAddDocumento}
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Adicionar'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            offlineData
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
      {(data.veiculoDocumentos?.length > 0 ||
        (gridView &&
          gridView.current &&
          gridView.current
            .getDataSource()
            .findIndex(
              (el) =>
                el.flgExibeDownload === true &&
                el.status !== GridView.EnumStatus.Remover
            ) !== -1)) && (
        <div className='row'>
          <div className='col d-flex justify-content-end'>
            <Button
              onClick={onClickBaixarTodos}
              outline
              theme={Theme.Success}
              template={Button.Templates.Button}
              text='Baixar Todos'
            />
          </div>
        </div>
      )}
    </div>
  );
});

export default DocumentoStep;
