import SituacaoChamado from 'core/models/HELP/situacaoChamado';
import api from '../api';

const baseURL = '/HELP/SituacaoChamado';

export const getSituacaoChamadoAutoComplete = async (filters: any) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    situacoesChamados:
      value?.map((json: any) => new SituacaoChamado(json)) ?? [],
  };
};
