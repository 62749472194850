import Pessoa from 'core/models/SEG/pessoa';
import Almoxarifado from 'core/models/EST/almoxarifado';
import FormaPagamento from 'core/models/FIN/formaPagamento';
import TipoDespesa from 'core/models/FIN/tipoDespesa';
import OficinaAlmoxarifado from 'core/models/FRO/oficina_Almoxarifado';
import { Endereco } from '../SEG';

export default class Oficina extends Pessoa {
  constructor(jsonObject = {}) {
    super(jsonObject);
    this.nrSeqPessoaOfi = jsonObject.nrSeqPessoaOfi;
    this.cdOficina = jsonObject.cdOficina;
    this.noOficina = jsonObject.noPessoa;
    this.noFantasia = jsonObject.noFantasia;
    this.flgContasPagar = jsonObject.flgContasPagar;
    this.diaPagamento = jsonObject.diaPagamento;
    this.flgAtivo = jsonObject.flgAtivo;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqTipoDespesa = jsonObject.nrSeqTipoDespesa;
    this.nrSeqAlmoxarifado = jsonObject.nrSeqAlmoxarifado;
    this.flgControlaEstoque = jsonObject.flgControlaEstoque;
    this.flgProprio = jsonObject.flgProprio;
    this.flgControlaFuncionario = jsonObject.flgControlaFuncionario;
    this.flgControlaChecklist = jsonObject.flgControlaChecklist;
    this.almoxarifado = new Almoxarifado(jsonObject.almoxarifado ?? {});
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.tipoDespesa = new TipoDespesa(jsonObject.tipoDespesa ?? {});
    this.oficinaAlmoxarifados = jsonObject.oficinaAlmoxarifados
      ? jsonObject.oficinaAlmoxarifados.map(
          (item) => new OficinaAlmoxarifado(item ?? {})
        )
      : [];

    // Propriedades auxiliares
    this.endereco = new Endereco(jsonObject.endereco ?? {});
  }

  toJson = () => JSON.stringify(this);
}
