import React, {
  useState,
  useEffect,
  forwardRef,
  ForwardedRef,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  Modal,
  FileUpload,
  Textbox,
  Button,
  Loader,
  Notification,
  GridView,
} from 'ui/components';
import {
  BootstrapSizes,
  Theme,
  ResponseStatus,
  ColumnDataTypes,
  ColumnTypes,
} from 'ui/Helpers/enums';
import {
  Message,
  GridViewCurrent,
  GridviewColumns,
} from 'ui/Helpers/interfaces';
import { MimeTypes, toBase64 } from 'ui/Helpers/utils';

import {
  saveUploadImagemFrota,
  searchByColetaEmbarque,
  deleteUploadImagemFrota,
} from 'core/services/FRO/uploadImagemFrota';

import UploadImagemFrota from 'core/models/FRO/uploadImagemFrota';

interface Props {
  show: boolean;
  nrSeqColetaEmbarque: number;
  onClose: (status?: ResponseStatus, message?: string) => void;
}

const ModalComprovanteEntrega = forwardRef(
  ({ show, nrSeqColetaEmbarque, onClose }: Props, param: ForwardedRef<any>) => {
    const [message, setMessage] = useState<Message | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [importedFiles, setImportedFiles] = useState<any>([]);
    const [
      uploadImagemFrota,
      setUploadImagemFrota,
    ] = useState<UploadImagemFrota | null>(null);
    const gridView = useRef<GridViewCurrent<UploadImagemFrota>>();

    useImperativeHandle(param, () => ({
      param: importedFiles,
    }));

    const onSend = () => {
      setImportedFiles([]);
      setUploadImagemFrota(null);
      onClose();
    };

    const importaArquivo = async (): Promise<void> => {
      if (importedFiles.length > 0) {
        let noImageBase64 = await toBase64(importedFiles[0]);
        noImageBase64 = String(noImageBase64).substring(
          String(noImageBase64).indexOf(',') + 1
        );

        setUploadImagemFrota({
          nrSeqUploadImagem: 0,
          nrSeqManifestoCte: null,
          nrSeqDocumento: null,
          naoBuscaDocumento: false,
          nrSeqVeiculoDisponibilidade: null,
          noImagem: undefined,
          dtCadastro: null,
          nrSeqColetaEmbarque,
          noArquivo: importedFiles[0]?.name,
          noBaseImagem64: noImageBase64,
          noTamanho: String(importedFiles[0]?.size),
          noTipoArquivo: importedFiles[0]?.type,
        });
      }
    };

    const buscaImagens = async (load: boolean = true): Promise<void> => {
      if (load) setLoading(true);

      const {
        status,
        message: msg,
        data: images,
      } = await searchByColetaEmbarque({
        nrSeqColetaEmbarque,
      });

      if (msg)
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });

      if (status === ResponseStatus.Success && images?.length > 0) {
        if (gridView && gridView.current) {
          gridView.current.setDataSource(images);
        }
      }

      setLoading(false);
    };

    useEffect(() => {
      if (show) importaArquivo();
    }, [importedFiles]);

    useEffect(() => {
      if (show) buscaImagens();
    }, [show]);

    const handleSaveImage = async () => {
      setLoading(true);

      if (uploadImagemFrota === null) {
        setMessage({
          message: 'Nenhuma imagem selecionada.',
          theme: Theme.Danger,
        });

        setLoading(false);
        return;
      }

      const { status, message: msg } = await saveUploadImagemFrota(
        uploadImagemFrota
      );

      if (msg)
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });

      if (status === ResponseStatus.Success) {
        setImportedFiles([]);
        setUploadImagemFrota(null);

        await buscaImagens(false);
      }

      setLoading(false);
    };

    const removeImage = async (e: UploadImagemFrota): Promise<void> => {
      setLoading(true);

      const { status, message: msg } = await deleteUploadImagemFrota(
        Number(e?.nrSeqUploadImagem)
      );

      if (msg)
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });

      if (status === ResponseStatus.Success) await buscaImagens(false);

      setLoading(false);
    };

    const downloadImage = (e: UploadImagemFrota) => {
      const linkSource = `data:image/png;base64, ${e?.noBaseImagem64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `${e?.noArquivo}`;
      downloadLink.click();
    };

    const columns: GridviewColumns[] = [
      { key: 'nrSeqUploadImagem', visible: false },
      { key: 'noArquivo', title: 'Nome' },
      {
        key: 'dtCadastro',
        title: 'Data Cadastro',
        format: ColumnDataTypes.Date,
      },
      {
        key: 'nrSeqUploadImagem',
        type: ColumnTypes.Button,
        onClick: (e: UploadImagemFrota) => downloadImage(e),
        theme: Theme.Primary,
        icon: 'download',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Conhecimentos',
        tooltipDirection: 'bottom',
      },
      {
        key: 'nrSeqUploadImagem',
        type: ColumnTypes.Button,
        onClick: (e: UploadImagemFrota) => removeImage(e),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Conhecimentos',
        tooltipDirection: 'bottom',
      },
    ];

    return (
      // @ts-expect-error
      <Modal
        show={show}
        icon={['far', 'image']}
        title='Comprovante de Entrega'
        onClose={() => onSend()}
        size={BootstrapSizes.Large}
      >
        {/* @ts-expect-error */}
        <Modal.Body>
          <Loader loading={loading} />
          {message && (
            <div className='m-1'>
              {/* @ts-expect-error */}
              <Notification
                message={message.message}
                theme={message.theme}
                onClose={() => setMessage(null)}
              />
            </div>
          )}
          <div className='row mb-3'>
            <div className='col'>
              <FileUpload
                files={importedFiles}
                onChange={(files: any) => setImportedFiles(files)}
                allowedMimeTypes={[MimeTypes.Types.Image]}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Textbox
                label='Nome do Arquivo'
                text={uploadImagemFrota?.noArquivo}
                readOnly
              />
            </div>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <Textbox
                label='Tipo Arquivo'
                text={uploadImagemFrota?.noTipoArquivo}
                readOnly
              />
            </div>
            {uploadImagemFrota !== null && (
              <>
                <div className='col-1 mt-3'>
                  <Button
                    icon='trash-alt'
                    theme={Theme.Danger}
                    onClick={() => {
                      setImportedFiles([]);
                      setUploadImagemFrota(null);
                    }}
                  />
                </div>
                <div className='col-3 mt-3'>
                  <Button
                    icon='plus'
                    text='Adicionar'
                    theme={Theme.Success}
                    onClick={handleSaveImage}
                  />
                </div>
              </>
            )}
          </div>
          <div className='row'>
            <div className='col'>
              <GridView
                ref={gridView}
                // @ts-expect-error
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columns}
                offlineData
                showSelectSizes={false}
                showPagination={false}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            text='Sair'
            icon={['fas', 'times']}
            theme={Theme.Danger}
            template={Button.Templates.Default}
            size={BootstrapSizes.Medium}
            onClick={() => onSend()}
          />
        </Modal.Footer>
      </Modal>
    );
  }
);

export default ModalComprovanteEntrega;
