import qs from 'qs';
import Pagination from 'core/models/pagination';
import PocPocoPerfuracao from 'core/models/POC/pocPocoPerfuracao';
import api from '../api';

const baseURL = `/POC/PocPocoPerfuracao`;

export const getPocPocoPerfuracaoList = async (filters) => {
  const response = await api.get(`${baseURL}/PocPocoPerfuracao/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPocoPerfuracao(json)) ?? [],
  };
};

export const getPocPocoPerfuracaoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPocoPerfuracao(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocPocoPerfuracao = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocPocoPerfuracao(value) : {};
};

export const getMetricConversion = async (diametro) => {
  const response = await api.get(`${baseURL}/MetricConversion`, {
    params: { diametro },
  });
  return response.data ?? {};
};

export const savePocPocoPerfuracao = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePocPocoPerfuracao = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPocPocoPerfuracao = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocPocoPerfuracao = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocPocoPerfuracao = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const sortPocPocoPerfuracao = async (data) => {
  const response = await api.post(`${baseURL}/Sort`, data);
  const { value } = response.data ?? {};
  return {
    listaPerfuracao: value?.map((json) => new PocPocoPerfuracao(json)) ?? [],
  };
};
