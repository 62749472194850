import Pessoa from './pessoa';
import ContatoAdicional from './contatoAdicional';

export default class Empresa extends Pessoa {
  constructor(jsonObject = {}) {
    super(jsonObject);
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.cdEmpresa = jsonObject.cdEmpresa;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.noPessoa = jsonObject.noPessoa;
    this.noEmpresa = jsonObject.noEmpresa;
    this.nrSeqPessoaEmp = jsonObject.nrSeqPessoaEmp;
    this.vlrPercentualDescontoPagamentos =
      jsonObject.vlrPercentualDescontoPagamentos;
    this.contatosAdicionais = jsonObject.contatosAdicionais
      ? jsonObject.contatosAdicionais.map(
          (item) => new ContatoAdicional(item ?? {})
        )
      : [];
  }

  toJson = () => JSON.stringify(this);
}
