import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getCondicaoPagamentoPagined,
  printCondicaoPagamento,
  deleteAllCondicaoPagamento,
} from 'core/services/FIN/condicaoPagamento';
import { CSDSelPage, GridView, Textbox } from 'ui/components';

const columns = [
  { key: 'nrSeqCondicaoPagamento', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdCondicaoPagamento', title: 'Código' },
  { key: 'noCondicaoPagamento', title: 'Condição Pagamento' },
  { key: 'tabelaPreco.noTabelaPreco', title: 'Tabela Preço', visible: false },
  { key: 'tipoVenda.noTipoVenda', title: 'Tipo Venda', visible: false },
  {
    key: 'dtValidade',
    title: 'Data Validade',
    format: GridView.DataTypes.Date,
  },
  {
    key: 'valorMaximo',
    title: 'Valor Máximo',
    format: GridView.DataTypes.Money,
  },
  {
    key: 'valorMinimo',
    title: 'Valor Mínimo',
    format: GridView.DataTypes.Money,
  },
  { key: 'diasTolerancia', title: 'Dias Tolerância' },
  { key: 'indiceCorrecao', title: 'Índice Correção' },
  {
    key: 'outrasDespesas',
    title: 'Outras Despesas',
    format: GridView.DataTypes.Money,
  },
];

export default function CondicaoPagamento({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getCondicaoPagamentoPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getCondicaoPagamentoPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'CondicaoPagamento/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqCondicaoPagamento = sourceRow.find(
      (e) => e.key === 'nrSeqCondicaoPagamento'
    );
    onOpenMaintenance(nrSeqCondicaoPagamento.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printCondicaoPagamento(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllCondicaoPagamento(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Condição Pagamento'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='col-4 mb-4'>
        <Textbox
          label='Condição de Pagamento'
          text={filtros.noCondicaoPagamento}
          onChangedValue={(noCondicaoPagamento) =>
            setFiltros({
              ...filtros,
              noCondicaoPagamento,
            })
          }
        />
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            enableExcelExport
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            canControlVisibility
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
