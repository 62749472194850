import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Switch, Textbox } from 'ui/components';
// import { MaskTypes } from 'ui/Helpers/masks';
import {
  getTipoTituloExtrato,
  saveTipoTituloExtrato,
  deleteTipoTituloExtrato,
} from 'core/services/FIN/tipoTituloExtrato';

import { getTipoDespesaAutoComplete } from 'core/services/FIN/tipoDespesa';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getExtratoCategoriaAutoComplete } from 'core/services/FIN/extratoCategoria';

export default function TipoTituloExtratoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      setData(await getTipoTituloExtrato(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveTipoTituloExtrato(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteTipoTituloExtrato(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchTipoDespesa = async (e) => {
    const {
      status,
      message: msg,
      tipoDespesas,
    } = await getTipoDespesaAutoComplete({ noTipoDespesa: e });
    if (msg) onSetMessage(status, msg);
    return tipoDespesas;
  };

  const onSearchTituloEspecie = async (e) => {
    const {
      status,
      message: msg,
      data: tituloEspecies,
    } = await getTituloEspecieAutoComplete({ noEspecie: e });
    if (msg) onSetMessage(status, msg);
    return tituloEspecies;
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const onSearchExtratoCategoria = async (e) => {
    const {
      status,
      message: msg,
      extratocategorias,
    } = await getExtratoCategoriaAutoComplete({ noExtratoCategoria: e });
    if (msg) onSetMessage(status, msg);
    return extratocategorias;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de TipoTituloExtrato'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqTipoTituloExtrato > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-12 mb-3'>
          <Textbox
            maxLength={60}
            label='Nome'
            text={data.noTituloExtrato}
            onChangedValue={(noTituloExtrato) =>
              setData({ ...data, noTituloExtrato })
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Gera Titulo Pagar'
            checked={data.flgTituloPagar}
            onChange={(flgTituloPagar) => setData({ ...data, flgTituloPagar })}
          />
        </div>

        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Liquida Titulo Pagar'
            checked={data.flgLiquidaTituloPagar}
            onChange={(flgLiquidaTituloPagar) =>
              setData({ ...data, flgLiquidaTituloPagar })
            }
          />
        </div>

        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Gera Titulo Receber'
            checked={data.flgTituloReceber}
            onChange={(flgTituloReceber) =>
              setData({ ...data, flgTituloReceber })
            }
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Liquida Titulo Receber'
            checked={data.flgLiquidaTituloReceber}
            onChange={(flgLiquidaTituloReceber) =>
              setData({ ...data, flgLiquidaTituloReceber })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Vincula ao Acerto'
            checked={data.flgAcerto}
            onChange={(flgAcerto) => setData({ ...data, flgAcerto })}
          />
        </div>

        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Vincula ao Adiantamento'
            checked={data.flgAdiantamento}
            onChange={(flgAdiantamento) =>
              setData({ ...data, flgAdiantamento })
            }
          />
        </div>

        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Aceita valor liquidado no acerto'
            checked={data.flgAceitaFechado}
            onChange={(flgAceitaFechado) =>
              setData({ ...data, flgAceitaFechado })
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='Tipo Despesa'
            searchDataSource={onSearchTipoDespesa}
            selectedItem={data.tipoDespesa}
            onSelectItem={(tipoDespesa) => {
              setData({
                ...data,
                tipoDespesa,
                nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
              });
            }}
            dataSourceTextProperty='noTipoDespesa'
          />
        </div>
        <div className='col mb-3'>
          <Autocomplete
            label='Tipo Receita'
            searchDataSource={onSearchTituloEspecie}
            selectedItem={data.tituloEspecie}
            onSelectItem={(tituloEspecie) => {
              setData({
                ...data,
                tituloEspecie,
                nrSeqTituloEspecie: tituloEspecie.nrSeqTituloEspecie,
              });
            }}
            dataSourceTextProperty='noEspecie'
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-6 mb-3'>
          <Autocomplete
            label='Categoria do Recebimento'
            searchDataSource={onSearchExtratoCategoria}
            selectedItem={data.extratoCategoriaRec}
            onSelectItem={(extratoCategoriaRec) => {
              setData({
                ...data,
                extratoCategoriaRec,
                nrSeqExtratoCategoriaRec:
                  extratoCategoriaRec.nrSeqExtratoCategoria,
              });
            }}
            dataSourceTextProperty='noExtratoCategoria'
          />
        </div>
        <div className='col-6 mb-3'>
          <Autocomplete
            label='Categoria do Pagamento'
            searchDataSource={onSearchExtratoCategoria}
            selectedItem={data.extratoCategoriaPag}
            onSelectItem={(extratoCategoriaPag) => {
              setData({
                ...data,
                extratoCategoriaPag,
                nrSeqExtratoCategoriaPag:
                  extratoCategoriaPag.nrSeqExtratoCategoria,
              });
            }}
            dataSourceTextProperty='noExtratoCategoria'
          />
        </div>
      </div>

      <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='Forma Pagamento'
            searchDataSource={onSearchFormaPagamento}
            selectedItem={data.formaPagamento}
            onSelectItem={(formaPagamento) => {
              setData({
                ...data,
                formaPagamento,
                nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
              });
            }}
            dataSourceTextProperty='noFormaPagamento'
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
