import Fornecedor from 'core/models/FIN/fornecedor';
import TipoDespesa from 'core/models/FIN/tipoDespesa';
import FormaPagamento from 'core/models/FIN/formaPagamento';
import TituloPagarTipoDocumento from 'core/models/FIN/tituloPagarTipoDocumento';

export default class NfFatura {
  constructor(jsonObject = {}) {
    this.dtVencimento = jsonObject.dtVencimento;
    this.vlrParcela = jsonObject.vlrParcela;
    this.vlrJuro = jsonObject.vlrJuro;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqTituloPagar = jsonObject.nrSeqTituloPagar;
    this.vlrMulta = jsonObject.vlrMulta;
    this.nrSeqNf = jsonObject.nrSeqNf;
    this.nrFat = jsonObject.nrFat;
    this.vDesc = jsonObject.vDesc;
    this.vlrOrigem = jsonObject.vlrOrigem;
    this.nrSeqTipoDespesa = jsonObject.nrSeqTipoDespesa;
    this.nrSeqPessoaFor = jsonObject.nrSeqPessoaFor;
    this.flgImposto = jsonObject.flgImposto;
    this.noCodigoBarra = jsonObject.noCodigoBarra;
    this.nrSeqTipoServicoBancario = jsonObject.nrSeqTipoServicoBancario;
    this.nrSeqFormaLancamentoEletronico =
      jsonObject.nrSeqFormaLancamentoEletronico;
    this.nrSeqTipoMovimento = jsonObject.nrSeqTipoMovimento;
    this.nrSeqTipoMovimentoRemessa = jsonObject.nrSeqTipoMovimentoRemessa;
    this.nrSeqInstrucaoMovimento = jsonObject.nrSeqInstrucaoMovimento;
    this.nrSeqPessoaSacador = jsonObject.nrSeqPessoaSacador;
    this.nrSeqNfFatura = jsonObject.nrSeqNfFatura;
    this.nrSeqTituloPagarTipoDocumento =
      jsonObject.nrSeqTituloPagarTipoDocumento;
    this.flgTipoCodigoBarras = jsonObject.flgTipoCodigoBarras;
    this.fornecedor = new Fornecedor(jsonObject.fornecedor ?? {});
    this.tipoDespesa = new TipoDespesa(jsonObject.tipoDespesa ?? {});
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.tituloPagarTipoDocumento = new TituloPagarTipoDocumento(
      jsonObject.tituloPagarTipoDocumento ?? {}
    );

    this.dtPagamentoTitulo = jsonObject.dtPagamentoTitulo;
  }

  toJson = () => JSON.stringify(this);
}
