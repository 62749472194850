import CentroDeCusto from './centroDeCusto';

export default class ClienteLinhaLog {
  constructor(jsonObject = {}) {
    this.id = jsonObject.id;
    this.usuario = jsonObject.usuario;
    this.transacao = jsonObject.transacao;
    this.data = jsonObject.data;
    this.computador = jsonObject.computador;
    this.tipoOperacao = jsonObject.tipoOperacao;
    this.nrSeqClienteLinha = jsonObject.nrSeqClienteLinha;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqChip = jsonObject.nrSeqChip;
    this.nrSeqAparelho = jsonObject.nrSeqAparelho;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqVencimento = jsonObject.nrSeqVencimento;
    this.vlrVendaAparelho = jsonObject.vlrVendaAparelho;
    this.flgStatus = jsonObject.flgStatus;
    this.flgSituacao = jsonObject.flgSituacao;
    this.dtAquisicao = jsonObject.dtAquisicao;
    this.dtCancelamento = jsonObject.dtCancelamento;
    this.observacao = jsonObject.observacao;
    this.nrProtBloqueio = jsonObject.nrProtBloqueio;
    this.dtBloqueio = jsonObject.dtBloqueio;
    this.dtDesbloqueio = jsonObject.dtDesbloqueio;
    this.flgModem = jsonObject.flgModem;
    this.flgRecFatCorreio = jsonObject.flgRecFatCorreio;
    this.nrSeqPlano = jsonObject.nrSeqPlano;
    this.nrSeqPacoteSMS = jsonObject.nrSeqPacoteSMS;
    this.dtIniVigenciaPacSMS = jsonObject.dtIniVigenciaPacSMS;
    this.dtFimVigenciaPacSMS = jsonObject.dtFimVigenciaPacSMS;
    this.vlrPacoteSMS = jsonObject.vlrPacoteSMS;
    this.nrSeqClienteLinhaPai = jsonObject.nrSeqClienteLinhaPai;
    this.nrSeqCentrodeCusto = jsonObject.nrSeqCentrodeCusto;
    this.noClienteConta = jsonObject.noClienteConta;
    this.nrSeqBonus = jsonObject.nrSeqBonus;
    this.nrSeqGrupocustoEmpresa = jsonObject.nrSeqGrupocustoEmpresa;
    this.nrSeqGrupoTcFatProPorc = jsonObject.nrSeqGrupoTcFatProPorc;
    this.dtRenovacao = jsonObject.dtRenovacao;
    this.nrTermo = jsonObject.nrTermo;
    this.txteMailFatura = jsonObject.txteMailFatura;
    this.flgEnviaSMS = jsonObject.flgEnviaSMS;
    this.flgRecebeMail = jsonObject.flgRecebeMail;
    this.nrSeqPlanoRateioEmpresa = jsonObject.nrSeqPlanoRateioEmpresa;
    this.nrSeqPessoaInformacaoBancaria =
      jsonObject.nrSeqPessoaInformacaoBancaria;

    this.centroDeCusto = new CentroDeCusto(jsonObject.centroDeCusto ?? {});
  }

  toJson = () => JSON.stringify(this);
}
