import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { Switch } from 'ui/components';

const PanelInformacoesGerais = forwardRef(({ props }, param) => {
  const [parametros, setParametros] = useState({});

  useImperativeHandle(param, () => ({
    param: parametros,
  }));

  useEffect(() => {
    setParametros({
      ...parametros,
      flge3recibo: props.flge3recibo,
      flg6sintetcentcusto: props.flg6sintetcentcusto,
      flgcontrolecentrocusto: props.flgcontrolecentrocusto,
    });
  }, [props]);

  return (
    <div>
      <div className='row mb-3'>
        <div className='col-3'>
          <Switch
            formControl
            label='Gerar Recibo'
            checked={parametros.flge3recibo}
            onChange={(e) => setParametros({ ...parametros, flge3recibo: e })}
          />
        </div>
        <div className='col-3'>
          <Switch
            formControl
            label='Gerar Sintético por Centro de Custo'
            checked={parametros.flg6sintetcentcusto}
            onChange={(e) =>
              setParametros({ ...parametros, flg6sintetcentcusto: e })
            }
          />
        </div>
        <div className='col-3'>
          <Switch
            formControl
            label='Possui controle por Centro de Custo'
            checked={parametros.flgcontrolecentrocusto}
            onChange={(e) =>
              setParametros({ ...parametros, flgcontrolecentrocusto: e })
            }
          />
        </div>
      </div>
    </div>
  );
});
export default PanelInformacoesGerais;
