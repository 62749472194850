export default class FreteMercadoria {
  constructor(jsonObject = {}) {
    this.nrSeqFreteMercadoria = jsonObject.nrSeqFreteMercadoria;
    this.noFreteMercadoria = jsonObject.noFreteMercadoria;
    this.cdFreteMercadoria = jsonObject.cdFreteMercadoria;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqGrupoIcmsCif = jsonObject.nrSeqGrupoIcmsCif;
    this.nrSeqGrupoIcmsFob = jsonObject.nrSeqGrupoIcmsFob;
    this.peso = jsonObject.peso;
    this.nrSeqProdutoUnidadeMedida = jsonObject.nrSeqProdutoUnidadeMedida;
    this.noMarca = jsonObject.noMarca;
    this.cdCnm = jsonObject.cdCnm;
    this.flgAlimenticio = jsonObject.flgAlimenticio;
  }

  toJson = () => JSON.stringify(this);
}
