import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  dateNow,
  JustifyContent,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  DatePicker,
  Panel,
  GridView,
  ToolbarButtons,
  Button,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import OrdemServicoProd from 'core/models/FIN/ordemServicoProd';
import OrdemServicoTecnico from 'core/models/FIN/ordemServicoTecnico';
import {
  getOrdemServico,
  saveOrdemServico,
  deleteOrdemServico,
  EncerraOrdemServico,
  CancelarOrdemServico,
  printOrdemServico,
} from 'core/services/FIN/ordemServico';
import { getFuncionarioList } from 'core/services/SEG/funcionario';
import { getPessoaAutoComplete } from 'core/services/SEG/pessoa';
import { getEmpresasUsuario } from 'core/services/SEG/empresa';
import {
  getAutoCompleteProdGroup,
  getPocProdOrcamento,
} from 'core/services/POC/pocProdOrcamento';

export default function OrdemServicoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  onOpenReport,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [dataOrdemProduto, setDataOrdemProduto] = useState(
    new OrdemServicoProd({})
  );
  const [dataTecnico, setDataTecnico] = useState(new OrdemServicoTecnico({}));
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const gridViewProd = useRef();
  const gridViewTecnicos = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      const retorno = await getOrdemServico(registryKey);
      setData(retorno);

      if (gridViewProd && gridViewProd.current)
        gridViewProd.current.setDataSource(retorno.ordemServicoProds ?? []);
      if (gridViewTecnicos && gridViewTecnicos.current)
        gridViewTecnicos.current.setDataSource(retorno.tecnicos ?? []);

      setLoading(false);
    } else {
      // ATRIBUINDO DATA
      setData({});
      data.dtCad = dateNow();
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const save = async () => {
    setLoading(true);

    const tecnicos = gridViewTecnicos.current?.getDataSource() ?? [];
    const itens = gridViewProd.current.getDataSource();

    const itensProduto = itens.map(
      (produtoitem) => new OrdemServicoProd(produtoitem)
    );

    const tecnicosOs = tecnicos.map(
      (tecnico) => new OrdemServicoTecnico(tecnico)
    );

    const obj = {
      ...data,
      tecnicos: tecnicosOs,
      ordemServicoProds: itensProduto,
    };

    const { status, message: msg, data: value } = await saveOrdemServico(obj);
    if (status === ResponseStatus.Success) {
      setData(value);

      if (gridViewProd && gridViewProd.current)
        gridViewProd.current.setDataSource(value.ordemServicoProds);

      if (gridViewTecnicos && gridViewTecnicos.current)
        gridViewTecnicos.current.setDataSource(value.tecnicos);
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onPrint = async () => {
    setLoading(true);

    const { value } = await printOrdemServico({
      nrSeqOrdemServico: data.nrSeqOrdemServico,
    });
    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onEncerraOrdemServico = async () => {
    setLoading(true);

    const {
      status,
      message: msg,
      data: ordemServico,
    } = await EncerraOrdemServico(data);

    if (status === ResponseStatus.Success) {
      setData(ordemServico);
    }
    onSetMessage(status, msg);

    setLoading(false);
  };

  const onCancelarOrdemServico = async () => {
    setLoading(true);

    const {
      status,
      message: msg,
      data: ordemServico,
    } = await CancelarOrdemServico(data);

    if (status === ResponseStatus.Success) {
      setData(ordemServico);
    }
    onSetMessage(status, msg);

    setLoading(false);
  };

  const onAdicionaTecnico = () => {
    if (dataTecnico.funcionario.noPessoa) {
      dataTecnico.nrSeqOrdemServico = data.nrSeqOrdemServico;
      dataTecnico.noTecnico = dataTecnico.funcionario.noPessoa;
      dataTecnico.status = GridView.EnumStatus.Inserir;
      const lista = gridViewTecnicos.current.getDataSource() ?? [];
      lista.push(dataTecnico);

      if (gridViewTecnicos && gridViewTecnicos.current)
        gridViewTecnicos.current.setDataSource(lista);
    }

    setDataTecnico({
      funcionario: {},
    });
  };

  const calcularValorTotalProduto = ({
    qtdeItem,
    vlrUnitario,
    vlrDesconto,
  }) => {
    const a = qtdeItem ?? (dataOrdemProduto.qtdeItem || 0);
    const b = vlrUnitario ?? (dataOrdemProduto.vlrUnitario || 0);
    const c = vlrDesconto !== undefined ? vlrDesconto : 0;

    return a * b - c;
  };

  const onRemoveTecnico = async (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (gridViewTecnicos && gridViewTecnicos.current)
      gridViewTecnicos.current.setDataSource(datasource);
  };

  const onRemoveOrdemServico = async (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (gridViewProd && gridViewProd.current)
      gridViewProd.current.setDataSource(datasource);
  };

  const onAdicionarProduto = () => {
    if (!dataOrdemProduto.nrSeqPocProdOrcamento)
      onSetMessage(1, 'Necessário selecionar um Item do Orçamento');
    if (dataOrdemProduto.qtdeItem === 0 || !dataOrdemProduto.qtdeItem)
      onSetMessage(1, 'Necessário informar a quantidade');
    else {
      const lista = gridViewProd.current.getDataSource() ?? [];
      if (dataOrdemProduto.nrSeqOrdemServicoProd > 0) {
        dataOrdemProduto.nrSeqOrdemServico = data.nrSeqOrdemServico;

        lista.push(dataOrdemProduto);

        if (gridViewProd && gridViewProd.current)
          gridViewProd.current.setDataSource(lista);

        setDataOrdemProduto({
          produto: {},

          qtdeItem: 0,
          vlrTotal: 0,
          vlrDesconto: 0,
          vlrUnitario: 0,
        });
      } else {
        dataOrdemProduto.status = GridView.EnumStatus.Inserir;
        dataOrdemProduto.nrSeqOrdemServico = data.nrSeqOrdemServico;
        lista.push(dataOrdemProduto);

        if (gridViewProd && gridViewProd.current)
          gridViewProd.current.setDataSource(lista);

        setDataOrdemProduto({
          produto: {},

          qtdeItem: 0,
          vlrTotal: 0,
          vlrDesconto: 0,
          vlrUnitario: 0,
        });
      }
    }
  };

  const onClickEditProduto = async (selectedValue, datasource) => {
    const dataOrdemProd = datasource.find((item) => item === selectedValue);
    dataOrdemProd.status = GridView.EnumStatus.Alterar;

    const dataProdutos = datasource.filter(
      (produto) => produto !== selectedValue
    );

    setDataOrdemProduto(dataOrdemProd);

    if (gridViewProd && gridViewProd.current)
      gridViewProd.current.setDataSource(dataProdutos);
  };
  const columns = [
    {
      key: 'nrSeqOrdemServicoProd',
      title: 'nrSeqOrdemServicoProd',
      visible: false,
    },
    {
      key: 'pocProdOrcamento.noPocProdOrcamento',
      title: 'Item do Orçamento',
    },
    { key: 'noDescricao', title: 'Complemento' },
    { key: 'pocProdOrcamento.cdUnidade', title: 'Unidade' },
    { key: 'qtdeItem', title: 'Quantidade' },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditProduto(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    // ADICIONANDO BOTÃO DE DELEÇÃO
    {
      key: 'nrSeqOrdemServicoProd',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveOrdemServico(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsTecnicos = [
    {
      key: 'nrSeqOrdemServicoTecnico',
      title: 'nrSeqOrdemServicoTecnico',
      visible: false,
    },
    { key: 'funcionario.noPessoa', title: 'Técnico' },
    {
      key: 'nrSeqOrdemServicoTecnico',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveTecnico(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteOrdemServico(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchTecnico = async (e) => {
    const { status, message: msg, data: tecnicos } = await getFuncionarioList({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return tecnicos;
  };

  const onSearchPocProdOrcamento = async (e) => {
    const {
      status,
      message: msg,
      data: produtos,
    } = await getAutoCompleteProdGroup({
      noPocProdOrcamento: e,
    });
    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const onSearchPessoaCli = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaClis,
    } = await getPessoaAutoComplete({
      noPessoaCli: e,
    });
    if (msg) onSetMessage(status, msg);
    return pessoaClis;
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresasUsuario({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      transaction={transaction}
      isActive={isActive}
      title='Manutenção de Ordem de Serviço'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      // onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqOrdemServico > 0 ? onExcluir : null}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <ToolbarButtons>
        {data.nrSeqOrdemServico &&
          !data.dtEncerrado &&
          data.flgRomaneio === false && (
            <ToolbarButtons.Button
              text='Encerrar Ordem Servico'
              icon={['fa', 'ban']}
              onClick={() => onEncerraOrdemServico()}
            />
          )}
        {data.nrSeqOrdemServico &&
          !data.dtEncerrado &&
          data.flgRomaneio === true && (
            <ToolbarButtons.Button
              text='Encerrar Romaneio'
              icon={['fa', 'ban']}
              onClick={() => onEncerraOrdemServico()}
            />
          )}
        {data.nrSeqOrdemServico && data.dtEncerrado && (
          <ToolbarButtons.Button
            text='Cancelar o Encerramento'
            icon={['fa', 'ban']}
            onClick={() => onCancelarOrdemServico()}
          />
        )}
      </ToolbarButtons>
      <div className='row'>
        <div className='col-2 mb-3'>
          <Textbox
            label='Código Ordem de Serviço'
            readOnly
            text={data.cdOrdemServico}
            mask={MaskTypes.Integer}
          />
        </div>
        <div className='col-2 mb-3'>
          <Textbox label='Código Orçamento' readOnly text={data.cdOrcamento} />
        </div>
        <div className='col-5 mb-3'>
          <Autocomplete
            label='Cliente'
            readOnly
            searchDataSource={onSearchPessoaCli}
            selectedItem={data.pessoa}
            onSelectItem={(pessoa) => {
              setData({
                ...data,
                pessoa,
                nrSeqPessoaCli: pessoa.nrSeqPessoaCli,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col mb-3'>
          <DatePicker
            label='Proposta'
            readOnly
            text={data.dtCad}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtCad) => setData({ ...data, dtCad })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-2 mb-3'>
          <Textbox label='Usuário' readOnly text={data.noUsuario} />
        </div>
        <div className='col-7 mb-3'>
          <Autocomplete
            label='Empresa'
            readOnly
            searchDataSource={onSearchEmpresa}
            selectedItem={data.pessoaEmp}
            onSelectItem={(pessoaEmp) => {
              setData({
                ...data,
                pessoaEmp,
                nrSeqPessoa: pessoaEmp.nrSeqPessoa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col mb-3'>
          <DatePicker
            label='Data Atendimento'
            readOnly
            text={data.dtAtendimento}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtAtendimento) => setData({ ...data, dtAtendimento })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <Textbox
            label='Observação Ordem de Serviço'
            text={data.noObservacao}
            onChangedValue={(noObservacao) =>
              setData({ ...data, noObservacao })
            }
          />
        </div>
        <div className='col'>
          <Textbox
            label='Observação Endereço'
            text={data.noObservacaoEndereco}
            onChangedValue={(noObservacaoEndereco) =>
              setData({ ...data, noObservacaoEndereco })
            }
          />
        </div>
      </div>
      {data.flgDIP === true && (
        <div className='row'>
          <div className='col mt-2'>
            <Textbox
              label='Poço'
              text={data.noPoco}
              onChangedValue={(noPoco) => setData({ ...data, noPoco })}
            />
          </div>
          <div className='col mt-2'>
            <Textbox
              label='Profundidade'
              text={data.noProfundidade}
              onChangedValue={(noProfundidade) =>
                setData({ ...data, noProfundidade })
              }
            />
          </div>
          <div className='col mt-2'>
            <Textbox
              label='Diâmetro Útil'
              text={data.noDiametro}
              onChangedValue={(noDiametro) => setData({ ...data, noDiametro })}
            />
          </div>
        </div>
      )}
      <div className='row mt-4'>
        <Panel>
          <Panel.Header
            icon={['fa', 'briefcase']}
            title='Serviços'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />

          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-6'>
                <Autocomplete
                  label='Item do Orçamento'
                  searchDataSource={onSearchPocProdOrcamento}
                  selectedItem={dataOrdemProduto?.pocProdOrcamento}
                  onSelectItem={async (pocProdOrcamento) => {
                    const { nrSeqPocProdOrcamento } = pocProdOrcamento;
                    if (nrSeqPocProdOrcamento) {
                      const unidade = await getPocProdOrcamento(
                        nrSeqPocProdOrcamento
                      );

                      setDataOrdemProduto({
                        ...dataOrdemProduto,
                        unidade,
                        pocProdOrcamento,
                        nrSeqPocProdOrcamento:
                          pocProdOrcamento.nrSeqPocProdOrcamento,
                      });
                    }
                  }}
                  dataSourceTextProperty='noPocProdOrcamento'
                />
              </div>
              <div className='col-1'>
                <Textbox
                  label='Unid'
                  readOnly
                  text={dataOrdemProduto.pocProdOrcamento?.cdUnidade}
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Quantidade'
                  text={dataOrdemProduto?.qtdeItem}
                  mask={MaskTypes.DecimalCustom}
                  decimalPlaces={6}
                  onChangedValue={(qtdeItem) =>
                    setDataOrdemProduto({
                      ...dataOrdemProduto,
                      qtdeItem,
                      vlrTotal: calcularValorTotalProduto({ qtdeItem }),
                    })
                  }
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-6'>
                <Textbox
                  maxLength={200}
                  label='Complemento'
                  text={dataOrdemProduto?.noDescricao}
                  onChangedValue={(noDescricao) =>
                    setDataOrdemProduto({
                      ...dataOrdemProduto,
                      noDescricao,
                    })
                  }
                />
              </div>
              <div className='col-2 mb-3'>
                <Button
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Success}
                  template={Button.Templates.Quick}
                  onClick={onAdicionarProduto}
                  text='Adicionar'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <GridView
                  ref={gridViewProd}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns}
                  showPagination={false}
                  showSelectSizes={false}
                  offlineData
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mt-4'>
        <Panel>
          <Panel.Header
            icon={['fas', 'user']}
            title='Técnicos'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row'>
              <div className='col-3 mb-3'>
                <Autocomplete
                  label='Técnicos/Equipe'
                  searchDataSource={onSearchTecnico}
                  selectedItem={dataTecnico.funcionario}
                  onSelectItem={(funcionario) => {
                    setDataTecnico({
                      ...dataTecnico,
                      funcionario,
                      nrSeqPessoaFun: funcionario.nrSeqPessoaFun,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-auto'>
                <div className='widget-square text-white p-3 br-tl-1 br-bl-1'>
                  <Button
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Success}
                    template={Button.Templates.Quick}
                    onClick={onAdicionaTecnico}
                    text='Adicionar'
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-4'>
                <GridView
                  ref={gridViewTecnicos}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsTecnicos}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    </CSDManutPage>
  );
}
