import PocPoco from 'core/models/POC/pocPoco';
import React, {
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { ResponseStatus } from 'ui/Helpers/utils';
import { savePocPoco } from 'core/services/POC/pocPoco';
import { uploadDocumento } from 'core/services/POC/pocPocoDocumentoAnaliseAgua';

const Finalizacao = forwardRef(
  ({ paramPoco, onErro, onLoading, onSuccess }, param) => {
    const [dataPoco, setDataPoco] = useState(new PocPoco());
    useImperativeHandle(param, () => ({
      param: dataPoco,
    }));

    const load = useCallback(async () => {
      setDataPoco({
        ...paramPoco,
        salvarEtapa: true,
      });
    }, []);

    const save = async () => {
      onLoading(true);
      if (paramPoco.salvarEtapa) {
        const obj = {
          ...paramPoco,
          perfuracoes: [],
          camadas: [],
          formacoes: [],
          revestimento: [],
          cimentacoes: [],
          prefiltros: [],
          entradasagua: [],
          bombeamentos: [],
          recuperacoes: [],
          bombas: [],
          tubos: [],
          cabos: [],
        };
        const { status, message: msg, value: nrseq } = await savePocPoco(obj);
        const files = paramPoco.documentosUpload ?? [];
        setDataPoco({
          ...paramPoco,
          nrSeqPocPoco: nrseq,
          salvarEtapa: false,
        });

        if (files.length > 0) {
          files.forEach(async (file) => {
            await uploadDocumento(file, paramPoco.nrSeqPocPoco);
          });
        }

        if (status === ResponseStatus.Success) {
          setDataPoco(new PocPoco());
          onSuccess(true);
        }

        if (status === ResponseStatus.Error) {
          onErro(msg);
        }
      }
      onLoading(false);
    };

    useEffect(() => {
      (async function func() {
        save();
        await load();
      })();
    }, []);

    return (
      <div className='row'>
        <div className='card'>
          <div className='card-body'>
            <div className='col mt-3'>
              <h5>Cadastro finalizado!</h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default Finalizacao;
