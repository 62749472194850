import React, { useState, useRef, useEffect } from 'react';
import { ResponseStatus, BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import { CondicaoPagamento } from 'core/models/FIN';
import {
  Autocomplete,
  Button,
  GridView,
  Loader,
  Modal,
  Notification,
  Textbox,
  Confirm,
} from 'ui/components';

import {
  gerarParcelas,
  getContratoServico,
  faturarContrato,
} from 'core/services/GProj/faturarContratos';
import { getCondicaoPagamentoAutoComplete } from 'core/services/FIN/condicaoPagamento';

const ModalFaturar = ({
  contratoServico,
  onSuccessFaturarContrato,
  onCloseClick,
  onTransmitir,
}) => {
  const gridView = useRef(null);
  const [state, setParams] = useState({ loading: true });
  const [retornofaturado, setRetornoFaturado] = useState({});
  const [showConfirmaGerarNFS, setShowConfirmaGerarNFS] = useState(false);

  useEffect(() => {
    getContratoServico({
      nrSeqContratoServico: contratoServico.nrSeqContratoServico,
      nrSeqContratoAgrupado: contratoServico.nrSeqContratoAgrupado,
      nrSeqContaCabecalho: contratoServico.nrSeqContaCabecalho,
    }).then(({ contratoServico: ctoServico }) => {
      setParams({
        ...ctoServico,
        condicaoPagamento: new CondicaoPagamento(ctoServico),
        nrSeqContratoServico: contratoServico.nrSeqContratoServico,
        nrSeqContratoAgrupado: contratoServico.nrSeqContratoAgrupado,
        noContratoAgrupado: contratoServico.noContratoAgrupado,
        vlrFaturar: contratoServico.vlrPendente,
        nrSeqPessoaCli: ctoServico.nrSeqPessoaCli,
        nrSeqEmpresa: ctoServico.nrSeqEmpresa,
        loading: false,
      });
    });
  }, [contratoServico]);

  const onGerarParcelas = async () => {
    const {
      nrSeqCondicaoPagamento,
      vlrFaturar,
      nrSeqContratoServico,
      nrSeqContratoAgrupado,
      noCompetencia,
    } = state;

    const { parcelas, message, status } = await gerarParcelas({
      nrSeqCondicaoPagamento,
      vlrFaturar,
      nrSeqContratoServico,
      nrSeqContratoAgrupado,
      noCompetencia,
    });

    if (message)
      setParams({
        ...state,
        parcelas,
        message,
        messageTheme:
          status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    else setParams({ ...state, parcelas });

    gridView.current.setDataSource(parcelas);
  };
  const onSearchCondicaoPagamento = async (e) => {
    const {
      condicoesPagamento,
      message,
      status,
    } = await getCondicaoPagamentoAutoComplete({
      noCondicaoPagamento: e,
    });

    if (message)
      setParams({
        ...state,
        message,
        messageTheme:
          status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return condicoesPagamento;
  };

  const onFaturar = async () => {
    const { parcelas } = state;
    if (!parcelas || parcelas.length <= 0)
      setParams({
        ...state,
        message: 'É preciso gerar parcelas para faturar o contrato.',
        messageTheme: Theme.Danger,
      });
    else {
      const { message, status, fatura } = await faturarContrato(parcelas);

      if (status === ResponseStatus.Success) {
        setRetornoFaturado({
          nrSeqPessoaCli: state.nrSeqPessoaCli,
          nrSeqEmpresa: state.nrSeqEmpresa,
          nrSeqFatura: fatura,
          parcelas,
          message,
          Success: Theme.Success,
        });
        setShowConfirmaGerarNFS(true);
      } else setParams({ ...state, message, messageTheme: Theme.Danger });
    }
  };

  const handleConfirm = async () => {
    onSuccessFaturarContrato(
      retornofaturado.nrSeqPessoaCli,
      retornofaturado.nrSeqEmpresa,
      retornofaturado.nrSeqFatura,
      retornofaturado.parcelas,
      retornofaturado.message,
      retornofaturado.Success,
      true
    );
  };
  const columns = [
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
      sortable: false,
    },
    {
      key: 'vlrParcelaFaturamento',
      title: 'Valor da Parcela',
      format: GridView.DataTypes.Money,
      sortable: false,
    },
  ];

  return (
    <Modal
      show
      size={BootstrapSizes.ExtraLarge}
      title={`Faturar: ${state.noContratoAgrupado} - ${state.noCentroDeCusto}`}
      onClose={onCloseClick}
      onTransmitir={onTransmitir}
    >
      <Modal.Body>
        <Loader loading={state.loading} />
        {state.message && (
          <div className='mb-3'>
            <Notification
              message={state.message}
              theme={state.messageTheme}
              onClose={() => setParams({ ...state, message: undefined })}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col'>
            <Textbox
              label='Competência'
              text={state.noCompetencia}
              mask={MaskTypes.Competence}
              onChangedValue={(noCompetencia) =>
                setParams({ ...state, noCompetencia })
              }
            />
          </div>
          <div className='col'>
            <Autocomplete
              label='Condição de Recebimento'
              searchDataSource={onSearchCondicaoPagamento}
              selectedItem={state.condicaoPagamento}
              dataSourceTextProperty='noCondicaoPagamento'
              onSelectItem={(condicaoPagamento) =>
                setParams({
                  ...state,
                  condicaoPagamento,
                  nrSeqCondicaoPagamento:
                    condicaoPagamento.nrSeqCondicaoPagamento,
                })
              }
            />
          </div>
          <div className='col'>
            <Textbox
              label='Valor Faturado'
              text={state.vlrFaturar}
              mask={MaskTypes.Currency}
              onChangedValue={(vlrFaturar) =>
                setParams({ ...state, vlrFaturar })
              }
            />
          </div>
          <div className='col-auto d-flex align-items-end'>
            <Button
              icon='cogs'
              text='Gerar Parcelas'
              theme={Theme.Primary}
              onClick={onGerarParcelas}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              showSelectSizes={false}
              showPagination={false}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          icon={['far', 'save']}
          text='Faturar'
          theme={Theme.Success}
          onClick={onFaturar}
        />
        <Button
          icon='ban'
          text='Cancelar'
          theme={Theme.Danger}
          onClick={onCloseClick}
        />
      </Modal.Footer>

      <Confirm
        show={showConfirmaGerarNFS}
        title='Confirmar Gerar NFS'
        titleIcon='exclamation-triangle'
        text='Deseja Gerar Nota fiscal de Serviço?'
        handleConfirm={handleConfirm}
        handleCancel={() =>
          onSuccessFaturarContrato(
            retornofaturado.nrSeqPessoaCli,
            retornofaturado.nrSeqEmpresa,
            retornofaturado.nrSeqFatura,
            retornofaturado.parcelas,
            retornofaturado.message,
            retornofaturado.Success,
            false
          )
        }
      />
    </Modal>
  );
};
export default ModalFaturar;
