import React, { useRef, useEffect, useState } from 'react';
import { Modal, GridView, Button, Loader, Notification } from 'ui/components';
// @ts-expect-error
import { Message, GridviewColumns } from 'ui/Helpers/interfaces.ts';
import {
  ColumnTypes,
  BootstrapSizes,
  Theme,
  ResponseStatus,
  // @ts-expect-error
} from 'ui/Helpers/enums.ts';

import { alteraProtocoloSeguro } from 'core/services/FRO/coletaEmbarque';

import ColetaEmbarque from 'core/models/FRO/coletaEmbarque';

interface Props {
  show: boolean;
  onClose: (msg: string, status: ResponseStatus) => void;
  ctes: Array<ColetaEmbarque>;
}

export default function ModalInformaProtocolo({ show, onClose, ctes }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const gridView = useRef<any>();

  const onSend = () => {
    onClose('', ResponseStatus.Error);
  };

  const load = () => {
    if (show) {
      if (gridView && gridView.current) gridView.current.setDataSource(ctes);
    }
  };

  useEffect(() => {
    load();
  }, [show]);

  const onBlurColumnTextBox = (keyValue: any, value: any) => {
    setLoading(true);

    const datasource = gridView.current.getDataSource();

    datasource.forEach((item: ColetaEmbarque) => {
      if (item.nrSeqColetaEmbarque === keyValue)
        item.noProtocoloAverbacaoSeguro = value;
    });

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);

    setLoading(false);
  };

  const columns: Array<GridviewColumns> = [
    { key: 'nrSeqColetaEmbarque', visible: false },
    { key: 'cdColetaEmbarque', title: 'Nr. CTE' },
    { key: 'coletaEmbarqueAutorizacao.nrChaveCTE', title: 'Chave' },
    {
      key: 'noProtocoloAverbacaoSeguro',
      title: 'Protocolo',
      type: ColumnTypes.Textbox,
      onBlur: onBlurColumnTextBox,
    },
  ];

  const onSave = async () => {
    setLoading(true);

    const datasource = gridView.current.getDataSource();

    const { status, message: msg } = await alteraProtocoloSeguro(datasource);

    if (status === ResponseStatus.Success) onClose(msg, status);
    else
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    setLoading(false);
  };

  return (
    // @ts-expect-error
    <Modal
      show={show}
      title='Informar Protocolo Manual'
      icon='check'
      size={BootstrapSizes.Large}
      onClose={() => onSend()}
    >
      {/* @ts-expect-error */}
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          // @ts-expect-error
          <Notification
            message={message?.message}
            theme={message?.theme}
            onClose={() => setMessage(null)}
          />
        )}
        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridView}
              // @ts-expect-error
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              offlineData
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Salvar'
          icon={['far', 'save']}
          theme={Theme.Success}
          onClick={onSave}
        />
        <Button
          text='Sair'
          icon={['fas', 'times']}
          theme={Theme.Danger}
          onClick={onSend}
        />
      </Modal.Footer>
    </Modal>
  );
}
