export default class DashboardAbastecimento {
  constructor(jsonObject = {}) {
    this.totalLitros = jsonObject.totalLitros;
    this.valorMedioPosto = jsonObject.valorMedioPosto;
    this.totalPorVeiculo = jsonObject.totalPorVeiculo;
    this.valorMedioPorModelo = jsonObject.valorMedioPorModelo;
    this.valorMediaPorVeiculo = jsonObject.valorMediaPorVeiculo;
    this.valorMediaPorEstado = jsonObject.valorMediaPorEstado;
  }
}
