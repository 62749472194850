export default class PessoaSetor {
  constructor(jsonObject = {}) {
    this.nrSeqPessoaSetor = jsonObject.nrSeqPessoaSetor;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.cdPessoaSetor = jsonObject.cdPessoaSetor;
    this.noPessoaSetor = jsonObject.noPessoaSetor;
  }

  toJson = () => JSON.stringify(this);
}
