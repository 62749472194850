import React, { useState, useRef } from 'react';
import { CSDSelPage, GridView, Textbox, RadioButton } from 'ui/components';
import { PageTypes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import {
  ColumnTypes,
  ColumnDataTypes,
  Theme,
  ResponseStatus,
  BootstrapSizes,
} from 'ui/Helpers/enums.ts';

import {
  getVendedorPagined,
  printVendedor,
  deleteAllVendedor,
} from 'core/services/VEN/vendedor';

export interface IVendedorFilters {
  noPessoa?: string;
  flgExcel?: boolean;
  SortBy?: number;
  totalByPage?: number;
  page?: any;
}

export default function Vendedor({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}: Page) {
  const gridView = useRef<any>();

  const [filtros, setFiltros] = useState<IVendedorFilters>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const columns: Array<GridviewColumns> = [
    { key: 'nrSeqPessoaVen', type: ColumnTypes.Checkbox },
    { key: 'noPessoa', title: 'Nome', sortKey: 'noPessoa' },
    { key: 'cdVendedor', title: 'Código', sortKey: 'cdVendedor' },
    {
      key: 'flgAtivo',
      title: 'Ativo',
      format: ColumnDataTypes.Boolean,
    },
    { key: 'comissao', title: 'Comissão', sortKey: 'comissao' },
    {
      key: 'nivel.noVendedorNivel',
      title: 'Nível',
      sortKey: 'noVendedorNivel',
    },
  ];

  const Status = [
    { text: 'Todos', value: 'T' },
    { text: 'Ativos', value: 'A' },
    { text: 'Inativos', value: 'I' },
  ];

  const StatusFisico = [
    { text: 'Todos', value: 'T' },
    { text: 'Fisica', value: 'F' },
    { text: 'Juridica', value: 'J' },
  ];

  const search = async (params = null): Promise<void> => {
    setLoading(true);

    const { data, pagination } = await getVendedorPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onOpenMaintenance = (registryKey = null): void => {
    onOpenPage(
      transaction.noTransacao,
      'Vendedor/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onColumnSort = async (SortBy: number): Promise<void> => {
    setFiltros({ ...filtros, SortBy });
    await search();
  };

  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    setFiltros({ ...filtros, totalByPage });
    await search();
  };

  const onPageChange = async (page: any): Promise<void> => {
    setFiltros({ ...filtros, page });
    await search();
  };

  const onRowDoubleClick = (sourceRow: any): void => {
    const nrSeqPessoaVen = sourceRow.find(
      (e: any) => e.key === 'nrSeqPessoaVen'
    );

    onOpenMaintenance(nrSeqPessoaVen.value);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllVendedor(
        selecteds.map((row: any) => row[0].value)
      );
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      await search();
    } else {
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printVendedor(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Transportadora'
      loading={loading}
      onSearch={search}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      {/* @ts-expect-error */}
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <Textbox
            label='Nome/ Razão Social'
            text={filtros.noPessoa}
            maxLength={200}
            onChangedValue={(noPessoa: string) =>
              setFiltros({ ...filtros, noPessoa })
            }
          />
        </div>
        <div className='col-auto'>
          <RadioButton
            outline
            label='Status'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={Status}
            selectedButton={filtros.noStatus}
            onChange={(status) =>
              setFiltros({
                ...filtros,
                noStatus: status,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-auto'>
          <RadioButton
            outline
            label='Status'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={StatusFisico}
            selectedButton={filtros.noStatusFisico}
            onChange={(statusFisico) =>
              setFiltros({
                ...filtros,
                noStatusFisico: statusFisico,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            onColumnSort={onColumnSort}
            enableExcelExport
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
