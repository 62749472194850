import React, { useEffect, useRef, useState } from 'react';

import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  CSDSelPage,
  GridView,
  Autocomplete,
  RadioButton,
  Switch,
  ToolbarButtons,
  DropdownMulti,
} from 'ui/components';
import { GridviewColumns, Message, Page } from 'ui/Helpers/interfaces';
import { ColumnTypes } from 'ui/Helpers/enums';
import {
  getGeraContaPdf,
  postGeraContaPdf,
} from 'core/services/TEL/geraContaPdf';
import {
  getCentroDeCustoAutoComplete,
  getContaRecursoAutoComplete,
  getGrupoLinhaAutoComplete,
  getPlanoAutoComplete,
} from 'core/services/TEL';
import {
  getClienteAutoComplete,
  getFormaPagamentoAutoComplete,
} from 'core/services/FIN';
import PanelSelecaoContratos from '../panelSelecaoContratos';

function RenderTextMask(props) {
  const { label } = props;
  return (
    <div className='col-auto mt-3'>
      <div className='fw-bold'>{label}</div>
    </div>
  );
}

const columns: Array<GridviewColumns> = [
  { key: 'nrSeqContaRecurso', type: ColumnTypes.Checkbox },
  { key: 'cdContratoDTFatura', title: 'Contrato / Fatura' },
  { key: 'noPessoaCli', title: 'Cliente' },
  { key: 'noClienteConta', title: 'Cliente Conta', visible: false },
  { key: 'noPlano', title: 'Plano' },

  { key: 'numeroCompleto', title: 'Número' },
  {
    key: 'vlrOriginal',
    title: 'Conta Original',
    format: GridView.DataTypes.Decimal,
  },
  {
    key: 'vlrTotalRecalculo',
    title: 'Conta Recalculada',
    format: GridView.DataTypes.Decimal,
  },
];

const FormatoEnvio = [
  {
    text: 'Arquivo anexado',
    value: 'arquivo_anexado',
  },
  {
    text: 'Link para download',
    value: 'link_download',
  },
];

const FormatoNomeArquivo = [
  {
    text: 'Numero',
    value: 'numero',
  },
  {
    text: 'Contrato',
    value: 'contrato',
  },
  {
    text: 'Nome',
    value: 'nome',
  },
  {
    text: 'Data',
    value: 'data',
  },
];

const Ordenacao = [
  {
    text: 'Número de celular',
    value: 'numero',
  },
  {
    text: 'Cliente',
    value: 'cliente',
  },
  {
    text: 'Plano',
    value: 'plano',
  },
];

const intialState = {
  noFormaEnvioEmail: 'arquivo_anexado',
  flgFormatoNomeArquivo: 'numero',
  flgGeraArquivoUnicoZip: true,
  flgGeraArquivoUnico: true,
  noOrdenacao: 'numero',
};

export default function GeraContaPDF({ transaction, isActive }: Page) {
  const gridView: any = useRef();
  const dropDownFormasRecebimento: any = useRef();
  const dropDownCentrosCusto: any = useRef();

  const msgtotaloriginal = 'Total Original: R$';
  const msgtotalrecalulada = 'Total Recalculado: R$';
  const [totalContaOriginal, setTotalContaOriginal] = useState<Object>(
    msgtotaloriginal
  );
  const [totalContaRecalculada, setTotalContaRecalculada] = useState<Object>(
    msgtotalrecalulada
  );

  const [filtros, setFiltros] = useState<Object>({ ...intialState });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [selectedItems, setSelectedItems] = useState<Object>({});
  const [link, setLink] = useState<string>('');

  const search = async (params: Object | null = null): Promise<void> => {
    setLoading(true);

    const planos = [];
    planos.push(filtros.nrSeqPlano);
    filtros.planos = planos;

    const { resumoSintetico, pagination } = await getGeraContaPdf(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(resumoSintetico.recursos, pagination);

    setLoading(false);
  };

  const onColumnSort = async (sortBy: string): Promise<void> => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page: number): Promise<void> => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });

    setMessage({ status, msg });
    return clientes;
  };

  const searchCentroDeCusto = async () => {
    const {
      status,
      message: msg,
      data: centros,
    } = await getCentroDeCustoAutoComplete({});

    if (msg) setMessage({ status, msg });

    return centros;
  };

  const searchFormaPagamento = async () => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({});

    if (msg) setMessage({ status, msg });

    return formasPagamento;
  };

  const onSearchLinha = async (e) => {
    if (!filtros.nrSeqContratoAgrupado && !filtros.nrSeqContaCabecalho) {
      setMessage(
        ResponseStatus.Error,
        'Obrigatório informar o Contrato Agrupado ou o Contrato / Fatura.'
      );
      return [];
    }

    const {
      status,
      message: msg,
      contaRecursos,
    } = await getContaRecursoAutoComplete({
      nrPrefixoLinha: e,
      nrSeqContratoAgrupado: filtros.nrSeqContratoAgrupado,
      nrSeqContaCabecalho: filtros.nrSeqContaCabecalho,
    });

    setMessage(status, msg);

    return contaRecursos;
  };

  const onSearchPlano = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoComplete({
      noPlano: e,
    });
    setMessage(status, msg);
    return planos;
  };

  const onSearch = async () => {
    setMessage();
    setLoading(true);

    const planos = [];
    planos.push(filtros.nrSeqPlano);
    filtros.planos = planos;
    const {
      status,
      message: msg,
      resumoSintetico,
      pagination,
    } = await getGeraContaPdf(filtros);

    setMessage({ status, msg });

    if (gridView && gridView.current)
      gridView.current.setDataSource(resumoSintetico.recursos, pagination);

    setLoading(false);
  };

  const onAutoCompleteGrupoLinha = async (e) => {
    const { gruposLinhas } = await getGrupoLinhaAutoComplete({
      noDescricao: e,
    });

    return gruposLinhas;
  };

  const onGerarConta = async () => {
    setLoading(true);
    setLink('');
    const param = {
      ...filtros,
      nrseqscontarecurso: gridView.current
        .getCheckBoxValuesAt(0)
        ?.map((row) => row[0].value),
    };
    const { status, message: msg, value: links } = await postGeraContaPdf(
      param
    );
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    if (links) {
      if (links.includes('contas')) {
        setLink(links);
      } else {
        const linkSource = `data:application/vnd.ms-excel;base64, ${links}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = 'Links.txt';
        downloadLink.click();
      }
    }
    setLoading(false);
  };

  useEffect((): void => {
    dropDownFormasRecebimento.current.loadDataSource(searchFormaPagamento);
    dropDownCentrosCusto.current.loadDataSource(searchCentroDeCusto);
  }, []);

  const onCalculaTotais = (sourceRow) => {
    let totalO = 0;
    let totalR = 0;
    if (sourceRow[0].state !== undefined) {
      const selecteds = gridView.current.getCheckBoxValuesAt(0);
      if (!selecteds || selecteds.length <= 0) {
        setTotalContaOriginal(msgtotaloriginal + 0);
        setTotalContaRecalculada(msgtotalrecalulada + 0);
      } else {
        const linhas = selecteds.map((column) => ({
          nrSeqContaCabecalho: column.find(
            (prop) => prop.key === 'nrSeqContaRecurso'
          ).value,
          totalOriginal: column.find((prop) => prop.key === 'vlrOriginal')
            .value,
          totalRecalculado: column.find(
            (prop) => prop.key === 'vlrTotalRecalculo'
          ).value,
        }));
        for (let i = 0; i < linhas.length; ) {
          totalO += parseFloat(linhas[i].totalOriginal?.replace(',', '.'));
          totalR += parseFloat(linhas[i].totalRecalculado?.replace(',', '.'));

          i += 1;
        }

        setTotalContaOriginal(msgtotaloriginal + totalO.toFixed(2));
        setTotalContaRecalculada(msgtotalrecalulada + totalR.toFixed(2));
      }
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Geração de Contas em Formato PDF'
      transaction={transaction}
      loading={loading}
      message={message}
      onSearch={async () => {
        onSearch();
      }}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Gerar Contas'
          icon='sync-alt'
          onClick={onGerarConta}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col'>
          <PanelSelecaoContratos
            justActives
            onContratoAgrupadoChange={(contratoAgrupado) =>
              setFiltros({
                ...filtros,
                nrSeqContratoAgrupado: contratoAgrupado.nrSeqContratoAgrupado,
              })
            }
            onFaturaChange={(fatura) =>
              setFiltros({
                ...filtros,
                nrSeqContaCabecalho: fatura.nrSeqContaCabecalho,
              })
            }
            onMessage={(msg, theme) => setMessage({ message: msg, theme })}
          />
        </div>
      </div>

      <div className='col mb-3'>
        <div className='row mb-3'>
          <div className='col-3'>
            <Autocomplete
              label='Prefixo/Número'
              searchDataSource={onSearchLinha}
              selectedItem={filtros.contaRecurso}
              onSelectItem={(contaRecurso) =>
                setFiltros({
                  ...filtros,
                  contaRecurso,
                  nrSeqContaRecurso: contaRecurso.nrSeqContaRecurso,
                })
              }
              dataSourceTextProperty='linhaFormatada'
            />
          </div>
          <div className='col'>
            <Autocomplete
              label='Cliente'
              searchDataSource={onSearchCliente}
              selectedItem={filtros.cliente}
              onSelectItem={(cliente) =>
                setFiltros({
                  ...filtros,
                  cliente,
                  nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                })
              }
              dataSourceTextProperty='noPessoa'
            />
          </div>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col'>
          <DropdownMulti
            label='Forma de Pagamento'
            ref={dropDownFormasRecebimento}
            dataSourcePropertyText='noFormaPagamento'
            dataSourcePropertyValue='nrSeqFormaPagamento'
            selectedItems={selectedItems.formaspagamento}
            onSelectItem={(formaspagamento) => {
              setSelectedItems({ ...selectedItems, formaspagamento });
              setFiltros({
                ...filtros,
                nrSeqFormasPagamentos: formaspagamento?.map(
                  (p) => p.nrSeqFormaPagamento
                ),
              });
            }}
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Grupo de Linha'
            searchDataSource={onAutoCompleteGrupoLinha}
            selectedItem={selectedItems.grupoLinha}
            onSelectItem={(grupoLinha) => {
              setSelectedItems({ ...selectedItems, grupoLinha });
              setFiltros({
                ...filtros,
                nrSeqGrupoLinha: grupoLinha.nrSeqGrupoLinha,
              });
            }}
            dataSourceTextProperty='noDescricao'
          />
        </div>
        <div className='col'>
          <DropdownMulti
            label='Centro de Custos'
            ref={dropDownCentrosCusto}
            dataSourcePropertyText='noCentroDeCusto'
            dataSourcePropertyValue='nrSeqCentroDeCusto'
            selectedItems={selectedItems.centrosdecustos ?? []}
            onSelectItem={(centrosdecustos) => {
              setSelectedItems({ ...selectedItems, centrosdecustos });
              setFiltros({
                ...filtros,
                centrosCusto: centrosdecustos?.map((p) => p.nrSeqCentroDeCusto),
              });
            }}
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Plano'
            searchDataSource={onSearchPlano}
            selectedItem={filtros.plano}
            onSelectItem={(plano) =>
              setFiltros({
                ...filtros,
                plano,
                nrSeqPlano: plano.nrSeqPlano,
              })
            }
            dataSourceTextProperty='noPlano'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <div className='card'>
            <div className='card-body'>
              <div className='row mb-3'>
                <div className='col-3'>
                  <RadioButton
                    label='Forma de Envio de Email'
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    selectedButton={filtros.noFormaEnvioEmail}
                    buttons={FormatoEnvio}
                    onChange={(noFormaEnvioEmail) =>
                      setFiltros({
                        ...filtros,
                        noFormaEnvioEmail,
                      })
                    }
                  />
                </div>
                <div className='col-3'>
                  <Switch
                    formControl
                    label='Download Direto Agrupado'
                    checked={filtros.flgDownloadDiretoAgrupado}
                    onChange={(flgDownloadDiretoAgrupado) => {
                      setFiltros({
                        ...filtros,
                        flgDownloadDiretoAgrupado,
                      });
                    }}
                    visible={filtros.noFormaEnvioEmail === 'link_download'}
                  />
                </div>
                <div className='col-3'>
                  <RadioButton
                    label='Formato Nome Arquivo.PDF'
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    selectedButton={filtros.flgFormatoNomeArquivo}
                    buttons={FormatoNomeArquivo}
                    onChange={(flgFormatoNomeArquivo) =>
                      setFiltros({
                        ...filtros,
                        flgFormatoNomeArquivo,
                      })
                    }
                  />
                </div>

                <div className='col-3'>
                  <RadioButton
                    label='Ordem'
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    selectedButton={filtros.noOrdenacao}
                    buttons={Ordenacao}
                    onChange={(noOrdenacao) =>
                      setFiltros({
                        ...filtros,
                        noOrdenacao,
                      })
                    }
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-3'>
                  <Switch
                    formControl
                    label='Gerar Contas em Único Arquivo PDF'
                    checked={filtros.flgGeraArquivoUnico}
                    onChange={(flgGeraArquivoUnico) => {
                      setFiltros({
                        ...filtros,
                        flgGeraArquivoUnico,
                        flgGeraArquivoUnicoZip: flgGeraArquivoUnico,
                      });
                    }}
                  />
                </div>
                <div className='col-3'>
                  <Switch
                    formControl
                    label='Gerar Contas em Único Arquivo PDF por Cliente'
                    checked={filtros.flgGeraArquivoUnicoCliente}
                    onChange={(flgGeraArquivoUnicoCliente) => {
                      setFiltros({
                        ...filtros,
                        flgGeraArquivoUnicoCliente,
                      });
                    }}
                  />
                </div>
                <div className='col'>
                  <Switch
                    formControl
                    label='Gerar Contas em Único Arquivo ZIP'
                    checked={filtros.flgGeraArquivoUnicoZip}
                    onChange={(flgGeraArquivoUnicoZip) => {
                      setFiltros({
                        ...filtros,
                        flgGeraArquivoUnicoZip,
                      });
                    }}
                  />
                </div>
                <div className='col'>
                  <Switch
                    formControl
                    label='Gerar Página em Branco'
                    checked={filtros.flgGeraPaginaBranco}
                    onChange={(flgGeraPaginaBranco) => {
                      setFiltros({
                        ...filtros,
                        flgGeraPaginaBranco,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
            Link para download:
          </span>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{link}</span>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='bg-primary text-white bg-gradient rounded-2 p-3'>
          <div className='row'>
            <div className='col'>
              <div className='display-8'>Totais</div>
              <hr className='mt-1 mb-0' />
              <div className='row'>
                <RenderTextMask label={totalContaOriginal} />
                <RenderTextMask label={totalContaRecalculada} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onPageChange={onPageChange}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onRowRender={onCalculaTotais}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
