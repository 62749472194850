import qs from 'qs';
import Pagination from 'core/models/pagination';
import TipoChamado from 'core/models/HELP/tipoChamado';
import api from '../api';

const baseURL = `/HELP/TipoChamado`;

export const getTipoChamadoList = async (filters: any) => {
  const response = await api.get(`/$TipoChamado/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new TipoChamado(json)) ?? [],
  };
};

export const getTipoChamadoPagined = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new TipoChamado(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getTipoChamado = async (id: number) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new TipoChamado(value) : new TipoChamado();
};

export const getTipoChamadoAutoComplete = async (filters: any) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    tiposChamado: value?.map((json: any) => new TipoChamado(json)) ?? [],
  };
};

export const saveTipoChamado = async (data: TipoChamado) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteTipoChamado = async (id: number) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const deleteAllTipoChamado = async (ids: number[]) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
