import React, { useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getExtratoTransferenciaPagined,
  printExtratoTransferencia,
  deleteAllExtratoTransferencia,
} from 'core/services/FIN/extratoTransferencia';
import {
  Autocomplete,
  CSDSelPage,
  DatePicker,
  GridView,
  Panel,
  Textbox,
} from 'ui/components';
import { getContaCorrenteTransferenciaList } from 'core/services/FIN/contaCorrenteTransferencia';
import { MaskTypes } from 'ui/Helpers/masks';

const columns = [
  { key: 'nrSeqExtratoTransferencia', type: GridView.ColumnTypes.Checkbox },
  {
    key: 'transferencia.noContaCorrenteTransferencia',
    title: 'Conta Corrente Transferencia',
  },
  { key: 'dtCad', title: 'Data Cadastro ', format: GridView.DataTypes.Date },
  {
    key: 'usuario.noLogin',
    title: 'Usuario',
  },
  {
    key: 'dtDocumento',
    title: 'Data Documento',
    format: GridView.DataTypes.Date,
  },
  {
    key: 'vlrMovimento',
    title: 'Valor Movimento',
    format: GridView.DataTypes.Decimal,
  },
  {
    key: 'debitado',
    title: 'Debitado Origem',
    color: 'noColorDest',
  },
  {
    key: 'creditado',
    title: 'Creditado Destino',
    color: 'noColorCred',
  },
];

export default function ExtratoTransferencia({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getExtratoTransferenciaPagined(
      params ?? filtros
    );

    for (let i = 0; i < data.length; ) {
      if (
        (data[i].nrSeqExtratoDest === null &&
          data[i].nrSeqExtratoTransferencia > 0) ||
        (data[i].nrSeqExtratoDest === undefined &&
          data[i].nrSeqExtratoTransferencia > 0)
      ) {
        data[i].creditado = 'Crédito Pendente';
      } else {
        data[i].creditado = 'Creditado';
      }

      if (
        (data[i].nrSeqExtratoOri === null &&
          data[i].nrSeqExtratoTransferencia > 0) ||
        (data[i].nrSeqExtratoOri === undefined &&
          data[i].nrSeqExtratoTransferencia > 0)
      ) {
        data[i].debitado = 'Debito Pendente';
      } else {
        data[i].debitado = 'Debitado';
      }

      i += 1;
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ExtratoTransferencia/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqExtratoTransferencia = sourceRow.find(
      (e) => e.key === 'nrSeqExtratoTransferencia'
    );
    onOpenMaintenance(nrSeqExtratoTransferencia.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const value = await printExtratoTransferencia(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllExtratoTransferencia(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchContaCorrenteTransferencia = async (e) => {
    const {
      status,
      message: msg,
      data: contaCorrenteTransferencia,
    } = await getContaCorrenteTransferenciaList({
      noTipoExtratoTransferencia: e,
    });
    if (msg) setMessage(status, msg);
    return contaCorrenteTransferencia;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de ExtratoTransferencia'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <Panel>
        <Panel.Header
          icon={['fas', 'user']}
          title='Filtros'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row mt-3'>
            <div className='col-5 mb-3'>
              <Autocomplete
                label='Descrição Transferencia'
                searchDataSource={onSearchContaCorrenteTransferencia}
                selectedItem={filtros.transferencia}
                onSelectItem={(transferencia) => {
                  setFiltros({
                    ...filtros,
                    transferencia,
                    nrSeqContaCorrenteTransferenci:
                      transferencia.nrSeqContaCorrenteTransferenci,
                    nrSeqContaCorrenteOri: transferencia.nrSeqContaCorrenteOri,
                    contaCorrenteOri: transferencia.contaCorrenteOri,
                    nrSeqContaCorrenteDest:
                      transferencia.nrSeqContaCorrenteDest,
                    contaCorrenteDest: transferencia.contaCorrenteDest,
                  });
                }}
                dataSourceTextProperty='noContaCorrenteTransferencia'
              />
            </div>
            <div className='col-4 mb-3'>
              <Textbox
                maxLength={20}
                label='Valor Movimento'
                mask={MaskTypes.DecimalCustom}
                text={filtros.vlrMovimento}
                onChangedValue={(vlrMovimento) =>
                  setFiltros({
                    ...filtros,
                    vlrMovimento,
                  })
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Movimento De'
                text={filtros.dtMovimentoDe}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtMovimentoDe) =>
                  setFiltros({ ...filtros, dtMovimentoDe })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Movimento Até'
                text={filtros.dtMovimentoAt}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtMovimentoAt) =>
                  setFiltros({ ...filtros, dtMovimentoAt })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Cadastro De'
                text={filtros.dtCadDe}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtCadDe) => setFiltros({ ...filtros, dtCadDe })}
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Cadastro Até'
                text={filtros.dtCadAt}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtCadAt) => setFiltros({ ...filtros, dtCadAt })}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div className='row mt-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            canControlVisibility
            enableExcelExport
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
