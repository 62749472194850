export default class GrupoPIS {
  constructor(jsonObject = {}) {
    this.cdGrupoPIS = jsonObject.cdGrupoPIS;
    this.noGrupoPIS = jsonObject.noGrupoPIS;
    this.aliquotaPIS = jsonObject.aliquotaPIS;
    this.nrSeqGrupoPIS = jsonObject.nrSeqGrupoPIS;
    this.baseCalculoPIS = jsonObject.baseCalculoPIS;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqCstPIS = jsonObject.nrSeqCstPIS;
  }

  toJson = () => JSON.stringify(this);
}
