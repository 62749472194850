import React, { useEffect, useState, useRef } from 'react';
import { Modal, Textbox, Button, GridView, Notification } from 'ui/components';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';

export default function ModalDestalhesCte({
  show,
  cte,
  findTransaction,
  onOpenTransaction,
  onClose,
}) {
  const [data, setData] = useState({});
  const [message, setMessage] = useState(null);
  const gridView = useRef();

  useEffect(() => {
    setData(cte);

    if (gridView && gridView.current)
      gridView.current.setDataSource(cte.coletasEletronicas);
  }, [show]);

  const onDownloadXml = (e) => {
    if (e.noXml) {
      const xmlURI = encodeURIComponent(e.noXml);
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:text/plain;charset=utf-8,${xmlURI}`;
      downloadLink.download = `${e.cdTipoSolicitacao}-${e.nrChaveCTE}.xml`;
      downloadLink.click();
    } else {
      setMessage({
        message: 'XML não encontrado.',
        theme: Theme.Danger,
      });
    }
  };

  const columns = [
    {
      key: 'dtSolicitacao',
      title: 'Data',
      format: GridView.DataTypes.DateTime,
    },
    { key: 'cdTipoSolicitacao', title: 'Solicitação' },
    { key: 'retenviCTEXMotivo', title: 'Retorno Envio' },
    { key: 'retconsreciCTEXMotivo', title: 'Retorno CTE' },
    { key: 'retconsreciCTENProt', title: 'Protocolo' },
    {
      key: 'nrSeqColetaEmbarqueCTE',
      title: 'Download XML',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onDownloadXml(e),
      theme: Theme.Info,
      icon: ['fa', 'download'],
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download XML',
      tooltipDirection: 'bottom',
    },
  ];

  const onOpenViagem = () => {
    const transactionOpen = findTransaction(TransacationCodes.Viagem);

    if (transactionOpen) {
      transactionOpen.registryKey = cte.nrSeqViagem;
      onOpenTransaction(transactionOpen, true);
      onClose();
    } else {
      setMessage({
        message: 'Não foi possível abrir a Viagem.',
        theme: Theme.Danger,
      });
    }
  };

  const onSend = () => {
    onClose();
  };

  return (
    <Modal
      show={show}
      title='Detalhes do Conhecimento'
      onClose={() => onSend()}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='row mb-3'>
            <div className='col-6'>
              <Textbox
                label='Remetente'
                readOnly
                text={data?.remetente?.noPessoa}
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='Cidade Remetente'
                readOnly
                text={data?.remetente?.endereco?.cidade?.noCidade}
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='Documento Remetente'
                readOnly
                text={data?.remetente?.documento}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-6'>
              <Textbox
                label='Destinatário'
                readOnly
                text={data?.destinatario?.noPessoa}
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='Cidade Destinatário'
                readOnly
                text={data?.destinatario?.endereco?.cidade?.noCidade}
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='Documento Destinatário'
                readOnly
                text={data?.destinatario?.documento}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-6'>
              <Textbox
                label='Cliente'
                readOnly
                text={data?.cliente?.noPessoa}
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='Cidade Cliente'
                readOnly
                text={data?.cliente?.endereco?.cidade?.noCidade}
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='Documento Cliente'
                readOnly
                text={data?.cliente?.documento}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-2'>
              <Textbox
                label='Total Doc.'
                readOnly
                mask={MaskTypes.DecimalCustom}
                text={data?.vlrDoc}
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Total Frete'
                readOnly
                mask={MaskTypes.DecimalCustom}
                text={data?.vlrFrete}
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Total CTE'
                readOnly
                mask={MaskTypes.DecimalCustom}
                text={data?.vlrTotal}
              />
            </div>
            <div className='col-6'>
              <Textbox
                label='Nr Documentos'
                readOnly
                text={data?.stringDocumentos}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-6'>
              <Textbox label='Chave' readOnly text={data?.nrChaveCte} />
            </div>
            <div className='col-2 mt-3'>
              <Button
                outline
                text='Abrir Viagem'
                icon='check'
                theme={Theme.Success}
                template={Button.Templates.Default}
                size={BootstrapSizes.Medium}
                onClick={() => onOpenViagem()}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              <GridView
                ref={gridView}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columns}
                offlineData
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
