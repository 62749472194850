import React, { useEffect } from 'react';
import { Panel, Textbox } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
// @ts-expect-error
import { Theme } from 'ui/Helpers/enums.ts';

interface Props {
  empresaData: any;
  setEmpresaData: (object: any) => void;
}

const EmpresaPanel = ({ empresaData, setEmpresaData }: Props) => {
  useEffect(() => {
    const objEmpresa = { ...empresaData };

    setEmpresaData(objEmpresa);
  }, [empresaData.nrSeqPessoaEmp]);

  return (
    <div className='col mt-3'>
      {/* @ts-expect-error */}
      <Panel>
        {/* @ts-expect-error */}
        <Panel.Header title='Empresa' theme={Theme.Primary} />

        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <Textbox
                label='Código'
                mask={MaskTypes.Integer}
                maxLength={6}
                text={empresaData.cdEmpresa}
                onChangedValue={(cdEmpresa: string) => {
                  setEmpresaData({
                    ...empresaData,
                    cdEmpresa,
                  });
                }}
              />
            </div>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <Textbox
                label='Percentual Desconto Pagamentos'
                text={empresaData.vlrPercentualDescontoPagamentos}
                mask={MaskTypes.DecimalCustom}
                maxLength={4}
                decimalPlaces={2}
                onChangedValue={(vlrPercentualDescontoPagamentos: string) => {
                  setEmpresaData({
                    ...empresaData,
                    vlrPercentualDescontoPagamentos,
                  });
                }}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </div>
  );
};

export default EmpresaPanel;
