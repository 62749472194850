import React, { useState, useRef, useEffect } from 'react';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import {
  Button,
  Loader,
  Modal,
  Notification,
  DatePicker,
  DropdownMulti,
  Autocomplete,
  Textbox,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getDataFaturamentoCancelar,
  cancelaFaturamento,
} from 'core/services/ASS/faturarConvenios';

export default function ModalCancelar({
  show,
  onClose,
  tiposReceita,
  onSearchFormaPagamento,
}) {
  const dropDownTiposReceita = useRef();

  const [message, setMessage] = useState(null);
  const [dataCancel, setDataCancel] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dropDownTiposReceita && dropDownTiposReceita.current)
      dropDownTiposReceita.current.loadDataSource(tiposReceita);
  }, [tiposReceita]);

  const onConfirm = async () => {
    if (dataCancel && dataCancel.flgConfirmaCancel) {
      setLoading(true);

      const { status, message: msg } = await cancelaFaturamento(dataCancel);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      setLoading(false);

      if (status === ResponseStatus.Success) {
        setDataCancel({});
        onClose();
      }
    }
  };

  const onClickCancelarFaturamento = async () => {
    if (!dataCancel.anoMes) {
      setMessage({
        message: 'Necessário preencher a competência.',
        theme: Theme.Danger,
      });
    } else if (!dataCancel.dtCadastro) {
      setMessage({
        message: 'Necessário preencher a data de cadastro.',
        theme: Theme.Danger,
      });
    } else if (!dataCancel.formaPagamento) {
      setMessage({
        message: 'Necessário preencher a forma de pagamento.',
        theme: Theme.Danger,
      });
    } else {
      setLoading(true);
      const { status, message: msg, value } = await getDataFaturamentoCancelar(
        dataCancel
      );

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      if (status === ResponseStatus.Success && value) {
        setDataCancel({
          ...dataCancel,
          flgConfirmaCancel: true,
          dadosCancelaFaturamento: value,
        });
      }
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      title='Cancelar Faturamento'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body className='pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        {dataCancel?.flgConfirmaCancel && (
          <div className='card text-center mb-3'>
            <div className='card-body'>
              <p className='card-text'>{dataCancel?.dadosCancelaFaturamento}</p>
            </div>
            <div className='row justify-content-center mt-3'>
              <div className='col mb-3'>
                <Button
                  outline
                  visible={dataCancel?.flgConfirmaCancel}
                  theme={Theme.Success}
                  template={Button.Templates.Button}
                  text='Confirmar Cancelamento'
                  onClick={onConfirm}
                />
              </div>
            </div>
          </div>
        )}

        <div className='row mt-3 p-3'>
          <div className='col mb-4'>
            <Textbox
              maxLength={10}
              label='Competência'
              mask={MaskTypes.Competence}
              text={dataCancel?.anoMes}
              onChangedValue={(anoMes) =>
                setDataCancel({ ...dataCancel, anoMes })
              }
            />
          </div>
          <div className='col mb-4'>
            <DropdownMulti
              label='* Tipo Receita'
              ref={dropDownTiposReceita}
              dataSourcePropertyText='noEspecie'
              dataSourcePropertyValue='nrSeqTituloEspecie'
              selectedItems={selectedItems.nrSeqsEspeciesTitulos ?? []}
              onSelectItem={(nrSeqsEspeciesTitulos) => {
                setSelectedItems({
                  ...selectedItems,
                  nrSeqsEspeciesTitulos,
                });
                setDataCancel({
                  ...dataCancel,
                  nrSeqsEspeciesTitulos: nrSeqsEspeciesTitulos?.map(
                    (p) => p.nrSeqTituloEspecie
                  ),
                });
              }}
            />
          </div>

          <div className='col mb-4 '>
            <DatePicker
              label='* Data Cadastro'
              text={dataCancel?.dtCadastro}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtCadastro) =>
                setDataCancel({ ...dataCancel, dtCadastro })
              }
            />
          </div>
        </div>
        <div className='row mb-4 p-3'>
          <div className='col mb-4'>
            <Autocomplete
              label='* Forma Recebimento'
              searchDataSource={onSearchFormaPagamento}
              selectedItem={dataCancel?.formaPagamento}
              onSelectItem={(formaPagamento) =>
                setDataCancel({
                  ...dataCancel,
                  formaPagamento,
                  nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                })
              }
              dataSourceTextProperty='noFormaPagamento'
            />
          </div>
          <div className='col mb-4'>
            <DatePicker
              label='* Data Vencimento'
              text={dataCancel?.dtInicialVencimento}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtInicialVencimento) =>
                setDataCancel({ ...dataCancel, dtInicialVencimento })
              }
            />
          </div>
        </div>
        <div className='row justify-content-evenly mt-4 '>
          <div className='col-3 mb-3'>
            <Button
              outline
              theme={Theme.Success}
              template={Button.Templates.Button}
              text='Confirmar'
              onClick={onClickCancelarFaturamento}
            />
          </div>

          <div className='col-3 mb-3'>
            <Button
              outline
              text='Voltar'
              theme={Theme.Danger}
              onClick={() => {
                setDataCancel({});
                onClose();
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
