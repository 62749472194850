import { NFEImportadaTipo } from 'core/models/COP';
import { Empresa, Usuario } from 'core/models/SEG';

export default class NFEImportada {
  nrSeqNfeImportada: number;

  nrSeqEmpresa: number;

  vlrNfe: number;

  nonsu: string;

  noFornecedor: string;

  xmlNFeZip: string;

  dtCad: Date;

  chNfe: string;

  dtNfe: Date;

  noDescricaoEvento: string;

  nrSeqNfeImportadaTipo: number;

  nrDocumento: string;

  nrTpEvento: number;

  flgPendenteManifestacao: Boolean;

  nfeImportadaTipo: NFEImportadaTipo;

  empresa: Empresa;

  usuario: Usuario;

  noCliente: string;

  constructor(jsonObject: NFEImportada) {
    this.nrSeqNfeImportada = jsonObject.nrSeqNfeImportada;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.vlrNfe = jsonObject.vlrNfe;
    this.nonsu = jsonObject.nonsu;
    this.noFornecedor = jsonObject.noFornecedor;
    this.xmlNFeZip = jsonObject.xmlNFeZip;
    this.dtCad = jsonObject.dtCad;
    this.nrDocumento = jsonObject.nrDocumento;
    this.chNfe = jsonObject.chNfe;
    this.dtNfe = jsonObject.dtNfe;
    this.flgPendenteManifestacao = jsonObject.flgPendenteManifestacao;
    this.noDescricaoEvento = jsonObject.noDescricaoEvento;
    this.nrSeqNfeImportadaTipo = jsonObject.nrSeqNfeImportadaTipo;
    this.nrTpEvento = jsonObject.nrTpEvento;
    this.noCliente = jsonObject.noCliente;
    this.nfeImportadaTipo = new NFEImportadaTipo(
      jsonObject.nfeImportadaTipo ?? {}
    );
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.usuario = new Usuario(jsonObject.usuario ?? {});
  }

  toJson = () => JSON.stringify(this);
}
