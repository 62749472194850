import PlanoDeConta from './planoDeConta';

export default class TipoDespesaPlanoDeConta {
  constructor(jsonObject = {}) {
    this.nrSeqTipoDespesa_PlanoDeConta =
      jsonObject.nrSeqTipoDespesa_PlanoDeConta;
    this.flgPercentual = jsonObject.flgPercentual;
    this.vlrPercentual = jsonObject.vlrPercentual;
    this.nrSeqTipoDespesa = jsonObject.nrSeqTipoDespesa;
    this.nrSeqPlanoDeContaCred = jsonObject.nrSeqPlanoDeContaCred;
    this.nrSeqPlanoDeContaDeb = jsonObject.nrSeqPlanoDeContaDeb;
    this.planoDeContaDeb = new PlanoDeConta(jsonObject.planoDeContaDeb ?? {});
    this.planoDeContaCre = new PlanoDeConta(jsonObject.planoDeContaCre ?? {});
    this.vlrPlanoConta = jsonObject.vlrPlanoConta;
    this.vlrTitulo = jsonObject.vlrTitulo;
  }

  toJson = () => JSON.stringify(this);
}
