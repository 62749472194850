import Equipamento from 'core/models/FRO/equipamento';
import api from '../api';

const baseURL = `/FRO/Equipamento`;

export const getEquipamentoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    equipamentos: value?.map((json) => new Equipamento(json)) ?? [],
  };
};
