import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getProdutoOrigemPagined,
  printProdutoOrigem,
  deleteAllProdutoOrigem,
  excelProdutoOrigem,
  getProdutoOrigemList,
} from 'core/services/EST/produtoOrigem.tsx';
import { Autocomplete, CSDSelPage, GridView } from 'ui/components';

const columns = [
  { key: 'nrSeqProdutoOrigem', type: GridView.ColumnTypes.Checkbox },
  { key: 'noProdutoOrigem', title: 'Nome Produto Origem' },
  { key: 'cdProdutoOrigem', title: 'Codigo do Produto Origem' },
];

export default function ProdutoOrigem({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getProdutoOrigemPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getProdutoOrigemPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ProdutoOrigem/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqProdutoOrigem = sourceRow.find(
      (e) => e.key === 'nrSeqProdutoOrigem'
    );
    onOpenMaintenance(nrSeqProdutoOrigem.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printProdutoOrigem(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onDownloadExcel = async () => {
    setLoading(true);

    const { value: base64 } = await excelProdutoOrigem(filtros);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'ProdutoOrigem.csv';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onSearchProdutoOrigem = async (e) => {
    const { data } = await getProdutoOrigemList({
      noProdutoOrigem: e,
    });

    return data;
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllProdutoOrigem(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Produto Origem'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      onDownloadExcel={onDownloadExcel}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-5 mb-3'>
          <Autocomplete
            label='Produto Origem'
            searchDataSource={onSearchProdutoOrigem}
            selectedItem={filtros.data}
            onSelectItem={(data) => {
              setFiltros({
                ...filtros,
                data,
                nrSeqProdutoOrigem: data.nrSeqProdutoOrigem,
              });
            }}
            dataSourceTextProperty='noProdutoOrigem'
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
