import React from 'react';
import { mask, MaskTypes } from 'ui/Helpers/masks';

function RenderTextMask(props) {
  const { label, value, masktype } = props;
  return (
    <div className='col mt-2 d-flex flex-column'>
      <div className='fw-bold h-100 align-items-stretch'>{label}</div>
      <div className='mt-2'>
        <div className='d-flex justify-content-end me-2'>
          {mask(value ?? 0, masktype ?? MaskTypes.Currency)}
        </div>
      </div>
    </div>
  );
}

export default function PanelResumo(props) {
  const { resumo } = props;
  const dataInicial = resumo?.data ? new Date(resumo.data) : new Date();
  const dataFormatada = dataInicial.toLocaleDateString('pt-BR');
  const saldoTotal = resumo.flgSaldoVisivel
    ? resumo.valor + resumo.saldo
    : resumo.valor;

  return (
    <div className='bg-primary text-white bg-gradient rounded-2 p-3'>
      <div className='row'>
        <div className='col'>
          <div className='row'>
            <h6>Resumo dos títulos em aberto até {dataFormatada}</h6>
          </div>
          <div className='row'>
            <RenderTextMask
              label='Quantidade Total de Títulos em Aberto'
              value={resumo?.qtdTotalLancamentos}
              masktype={MaskTypes.Integer}
            />
            <RenderTextMask
              label='Saldo de Contas Corrente em Data Inicial'
              value={resumo?.saldo}
            />
            <RenderTextMask
              label='Valor Total de Receitas em Aberto'
              value={resumo?.vlrTituloReceber}
            />
            <RenderTextMask
              label='Valor Total de Despesas em Aberto'
              value={resumo?.vlrTituloPagar}
            />
            <RenderTextMask
              label='Somatório do Saldo Total em Contas com Despesas e Receitas em Aberto'
              value={saldoTotal || 0}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
