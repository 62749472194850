import qs from 'qs';
import Pagination from 'core/models/pagination';
import PocPocoCimentacao from 'core/models/POC/pocPocoCimentacao';
import api from '../api';

const baseURL = `/POC/PocPocoCimentacao`;

export const getPocPocoCimentacaoList = async (filters) => {
  const response = await api.get(`/$PocPocoCimentacao/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPocoCimentacao(json)) ?? [],
  };
};

export const getPocPocoCimentacaoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPocoCimentacao(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocPocoCimentacao = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocPocoCimentacao(value) : {};
};

export const savePocPocoCimentacao = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePocPocoCimentacao = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPocPocoCimentacao = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocPocoCimentacao = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocPocoCimentacao = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const sortPocPocoCimentacao = async (data) => {
  const response = await api.post(`${baseURL}/Sort`, data);
  const { value } = response.data ?? {};
  return {
    listaCimentacao: value?.map((json) => new PocPocoCimentacao(json)) ?? [],
  };
};
