import Importacao from './importacao';

export default class ImportacaoSequencia {
  constructor(jsonObject = {}) {
    this.nrSeqImportacaoSequencia = jsonObject.nrSeqImportacaoSequencia;
    this.noImportacaoSequencia = jsonObject.noImportacaoSequencia;
    this.nrSeqImportacao = jsonObject.nrSeqImportacao;
    this.nrSeqImportacaoVinculada = jsonObject.nrSeqImportacaoVinculada;
    this.nrOrdem = jsonObject.nrOrdem;
    this.status = jsonObject.status;
    this.importacao = new Importacao(jsonObject.importacao ?? {});
  }

  toJson = () => JSON.stringify(this);
}
