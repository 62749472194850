import {
  deleteExtrato,
  getContaCorrenteByUser,
  getExtrato,
  saveExtrato,
} from 'core/services/FIN';
import { getExtratoCategoriaAutoComplete } from 'core/services/FIN/extratoCategoria';
import { getExtratoGrupoCategoriaAutoComplete } from 'core/services/FIN/extratoGrupoCategoria';
import {
  getEmpresaDefaultUsuario,
  getEmpresasUsuario,
  getPessoaAutoCompleteFornecedorECliente,
} from 'core/services/SEG';
import React, { useRef, useEffect, useState, useCallback } from 'react';
import { MaskTypes } from 'ui/Helpers/masks';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  Autocomplete,
  CSDManutPage,
  DatePicker,
  Textarea,
  Textbox,
} from 'ui/components';

export default function ExtratoContaCorrenteItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onNew = async () => {
    const { empresas } = await getEmpresaDefaultUsuario();

    setData({
      obs: '',
      vlrDocumento: '',
      empresa: empresas[0],
      nrSeqEmpresa: empresas[0].nrSeqEmpresa,
      flgPermiteAlterar: true,
    });
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    setLoading(true);

    if (Number.isInteger(registryKey)) {
      const extrato = await getExtrato(registryKey);

      setData({
        ...extrato,
        extratoGrupoCategoria: extrato.extratoCategoria?.extratoGrupoCategoria,
        nrSeqExtratoGrupoCategoria:
          extrato.extratoCategoria?.nrSeqExtratoGrupoCategoria,
      });
    } else {
      onNew();
    }

    setLoading(false);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const save = async () => {
    setLoading(true);

    if (!isNaN(parseFloat(data.vlrDocumento))) {
      if (data.vlrDocumento > 0) {
        const { status, message: msg } = await saveExtrato(data);

        if (status === ResponseStatus.Success) {
          const obj = {
            obs: '',
            vlrDocumento: '',
            empresa: data.empresa,
            nrSeqEmpresa: data.nrSeqEmpresa,
            contaCorrente: data.contaCorrente,
            nrSeqContaCorrente: data.nrSeqContaCorrente,
            extratoGrupoCategoria: data.extratoGrupoCategoria,
            nrSeqExtratoGrupoCategoria: data.nrSeqExtratoGrupoCategoria,
            dtDocumento: data.dtDocumento,
            flgPermiteAlterar: true,
          };

          setData(obj);

          setMessage({
            message: msg,
            theme: Theme.Success,
          });
        } else {
          setMessage({
            message: msg,
            theme: Theme.Danger,
          });
        }
      } else {
        onSetMessage(false, 'Valor não pode ser 0,00');
      }
    } else {
      setMessage({
        message: 'Valor informado não é valido',
        theme: Theme.Danger,
      });
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const { status, message: msg } = await deleteExtrato(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchContaCorrente = async (e) => {
    const {
      status,
      message: msg,
      data: contasCorrentes,
    } = await getContaCorrenteByUser({
      noContaCorrente: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    if (msg) {
      onSetMessage(status, msg);
    }

    return contasCorrentes;
  };

  const onSearchExtratoGrupoCategoria = async (e) => {
    const {
      status,
      message: msg,
      data: grupos,
    } = await getExtratoGrupoCategoriaAutoComplete({
      noExtratoGrupoCategoria: e,
    });

    if (msg) {
      onSetMessage(status, msg);
    }

    return grupos;
  };

  const onSearchExtratoCategoria = async (e) => {
    if (data.extratoGrupoCategoria?.nrSeqExtratoGrupoCategoria) {
      const {
        status,
        message: msg,
        extratocategorias,
      } = await getExtratoCategoriaAutoComplete({
        noExtratoCategoria: e,
        nrSeqExtratoGrupoCategoria: data.nrSeqExtratoGrupoCategoria,
      });

      if (msg) {
        onSetMessage(status, msg);
      }

      return extratocategorias;
    }

    onSetMessage(
      ResponseStatus.Danger,
      'Selecione o Grupo antes de escolher o Detalhe'
    );

    return [];
  };

  const onSearchPessoa = async (e) => {
    const {
      status,
      message: msg,
      data: pessoas,
    } = await getPessoaAutoCompleteFornecedorECliente({ noPessoa: e });

    if (msg) onSetMessage(status, msg);

    return pessoas;
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Extrato Conta Corrente'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={data.flgPermiteAlterar ? save : null}
      onDelete={data?.nrSeqVeiculoMarca > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Empresa'
            required
            enabled={data.flgPermiteAlterar}
            searchDataSource={onSearchEmpresa}
            selectedItem={data.empresa}
            onSelectItem={(empresa) => {
              setData({
                ...data,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            required
            label='Conta Corrente'
            enabled={data.flgPermiteAlterar}
            searchDataSource={onSearchContaCorrente}
            selectedItem={data.contaCorrente}
            onSelectItem={(contaCorrente) =>
              setData({
                ...data,
                contaCorrente,
                nrSeqContaCorrente: contaCorrente.nrSeqContaCorrente,
              })
            }
            dataSourceTextProperty='noContaCorrente'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            required
            label='Grupo'
            enabled={data.flgPermiteAlterar}
            searchDataSource={onSearchExtratoGrupoCategoria}
            selectedItem={data.extratoGrupoCategoria}
            onSelectItem={(extratoGrupoCategoria) => {
              setData({
                ...data,
                extratoGrupoCategoria,
                nrSeqExtratoGrupoCategoria:
                  extratoGrupoCategoria.nrSeqExtratoGrupoCategoria,
                extratoCategoria: undefined,
                nrSeqExtratoCategoria: undefined,
              });
            }}
            dataSourceTextProperty='noExtratoGrupoCategoria'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            required
            label='Detalhe'
            enabled={data.flgPermiteAlterar}
            searchDataSource={onSearchExtratoCategoria}
            selectedItem={data.extratoCategoria}
            onSelectItem={(extratoCategoria) => {
              setData({
                ...data,
                extratoCategoria,
                nrSeqExtratoCategoria: extratoCategoria.nrSeqExtratoCategoria,
              });
            }}
            dataSourceTextProperty='noExtratoCategoria'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Textbox
            required
            label='Documento'
            maxLength={11}
            readOnly={!data.flgPermiteAlterar}
            text={data.nrDocumento}
            onChangedValue={(nrDocumento) => {
              setData({ ...data, nrDocumento });
            }}
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Fornecedor/Cliente'
            enabled={data.flgPermiteAlterar}
            searchDataSource={onSearchPessoa}
            selectedItem={data.pessoa}
            onSelectItem={(pessoa) => {
              setData({
                ...data,
                pessoa,
                nrSeqPessoa: pessoa.nrSeqPessoa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col'>
          <Textbox
            required
            label='Valor'
            readOnly={!data.flgPermiteAlterar}
            text={data.vlrDocumento}
            mask={MaskTypes.DecimalCustom}
            decimalPlaces={2}
            maxLength={11}
            onChangedValue={(vlrDocumento) => {
              setData({ ...data, vlrDocumento });
            }}
          />
        </div>
        <div className='col'>
          <DatePicker
            required
            label='Data'
            readOnly={!data.flgPermiteAlterar}
            text={data.dtDocumento}
            mask={MaskTypes.Date}
            onChange={(dtDocumento) => {
              setData({ ...data, dtDocumento });
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Textarea
            label='Observação'
            text={data.obs}
            readOnly={!data.flgPermiteAlterar}
            rows={2}
            onChangedValue={(obs) => setData({ ...data, obs })}
          />
        </div>
      </div>

      {data.nrSeqExtrato > 0 && (
        <div className='row mb-3'>
          <span>
            Inclusão: {data.usuarioCad.noPessoa} -{' '}
            {new Date(data.dtCad).toLocaleDateString()}
          </span>
        </div>
      )}
      {data.nrSeqExtrato > 0 && data.nrSeqUsuarioAlt > 0 && (
        <div className='row mb-3'>
          <span>
            Alteração: {data.usuarioAlt.noPessoa} -{' '}
            {new Date(data.dtAlteracao).toLocaleDateString()}
          </span>
        </div>
      )}
    </CSDManutPage>
  );
}
