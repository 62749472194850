import React, { useState } from 'react';
import {
  Modal,
  Button,
  Panel,
  Textbox,
  Notification,
  Loader,
} from 'ui/components';
import { BootstrapSizes, Theme, JustifyContent } from 'ui/Helpers/utils';

function GenerateRandomText() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';

  let word = '';

  for (let i = 0; i < 5; i += 1) {
    const index = Math.floor(Math.random() * characters.length);
    word += characters[index];
  }

  return word;
}

export default function ModalNotaDuplicada({ show, data, onClose }) {
  const [loading, setLoading] = useState(false);
  const [randomText, setRandomtext] = useState(GenerateRandomText());
  const [message, setMessage] = useState(null);
  const [input, setInput] = useState('');

  const onConfirm = async () => {
    setLoading(true);

    if (randomText.localeCompare(input) === 0) {
      if (Array.isArray(data)) {
        await onClose(true, data);
      } else {
        const dataimport = [];
        dataimport.push(data);
        onClose(true, dataimport);
      }
    } else {
      setMessage({
        message: 'Tente novamente.',
        theme: Theme.Warning,
      });
    }

    setRandomtext(GenerateRandomText());

    setInput('');

    setLoading(false);
  };

  return (
    <Modal
      show={show}
      title='Upload Documento'
      onClose={() => onClose()}
      size={BootstrapSizes.Large}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='row mb-3'>
            <span>
              Notas <strong>{data?.chaveNfe}</strong> já lançadas para esse
              remetente com este número/série. Para lança-la novamente é
              necessário a confirmação dos caracteres abaixo.
            </span>
          </div>
          <Panel>
            <Panel.Header
              icon={['fas', 'keyboard']}
              title='Confirme os caracteres'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='row mb-3 justify-content-center'>
                  <div className='col-2'>
                    <h2 className='fw-bold' style={{ color: '#008000' }}>
                      {randomText}
                    </h2>
                  </div>
                  <div className='col-1 mt-1'>
                    <Button
                      outline
                      icon={['fas', 'random']}
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      template={Button.Templates.Default}
                      tooltip='Gerar Nova Sequencia'
                      tooltipDirection='bottom'
                      onClick={() => setRandomtext(GenerateRandomText())}
                    />
                  </div>
                </div>
                <div className='row mb-3 justify-content-center'>
                  <div className='col-5'>
                    <Textbox
                      text={input}
                      onChangedValue={(item) => setInput(item)}
                    />
                  </div>
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Small}
          icon='check'
          theme={Theme.Success}
          onClick={() => onConfirm()}
          text='Confirmar'
        />
        <Button
          size={BootstrapSizes.Small}
          icon={['fas', 'times']}
          theme={Theme.Danger}
          onClick={() => onClose()}
          text='Cancelar'
        />
      </Modal.Footer>
    </Modal>
  );
}
