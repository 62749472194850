import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { Autocomplete, CSDSelPage, GridView, Textbox } from 'ui/components';
import {
  getInfracaoGravidadePagined,
  printInfracaoGravidade,
  deleteAllInfracaoGravidade,
  getInfracaoGravidadeAutoComplete,
} from 'core/services/FRO/infracaoGravidade';

const columns = [
  { key: 'nrSeqInfracaoGravidade', type: GridView.ColumnTypes.Checkbox },
  { key: 'noInfracaoGravidade', title: 'Infração Gravidade' },
  { key: 'cdInfracaoGravidade', title: 'Código' },
];

export default function InfracaoGravidade({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);
    const obj = { ...filtros };
    if (obj.infracaoGravidade) {
      obj.infracaoGravidade = undefined;
    }
    if (params) {
      params = undefined;
    }
    const { data, pagination } = await getInfracaoGravidadePagined(obj);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getInfracaoGravidadePagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'InfracaoGravidade/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqInfracaoGravidade = sourceRow.find(
      (e) => e.key === 'nrSeqInfracaoGravidade'
    );
    onOpenMaintenance(nrSeqInfracaoGravidade.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printInfracaoGravidade(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllInfracaoGravidade(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  const onSearchInfracaoGravidade = async (e) => {
    const {
      status,
      message: msg,
      infracaogravidades,
    } = await getInfracaoGravidadeAutoComplete({
      noInfracaoGravidade: e,
    });
    if (msg) onSetMessage(status, msg);
    return infracaogravidades;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Infração Gravidade'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-2 mb-3'>
          <Autocomplete
            label='Infração Gravidade'
            searchDataSource={onSearchInfracaoGravidade}
            selectedItem={filtros.infracaoGravidade}
            onSelectItem={(infracaoGravidade) => {
              setFiltros({
                ...filtros,
                infracaoGravidade,
                nrSeqInfracaoGravidade:
                  infracaoGravidade.nrSeqInfracaoGravidade,
              });
            }}
            dataSourceTextProperty='noInfracaoGravidade'
          />
        </div>
        <div className='col-2 mb-3'>
          <Textbox
            maxLength={60}
            label='Código'
            text={filtros.cdInfracaoGravidade}
            onChangedValue={(cdInfracaoGravidade) =>
              setFiltros({ ...filtros, cdInfracaoGravidade })
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            enableExcelExport
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
