import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
  JustifyContent,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  Switch,
  Panel,
  Button,
  GridView,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getTransacao,
  saveTransacao,
  deleteTransacao,
} from 'core/services/SEG/transacao';

import { getMenuAutoComplete } from 'core/services/SEG/menu';
import TransacaoPagina from 'core/models/SEG/transacaoPagina';
import TransacaoPaginaConfiguracao from 'core/models/SEG/transacaoPaginaConfiguracao';
import { getUsuarioList } from 'core/services/SEG/usuario';

export default function TransacaoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [dataPagina, setDataPagina] = useState(new TransacaoPagina());
  const [dataPaginaConfig, setDataPaginaConfig] = useState({
    flgHabilitado: true,
    flgObrigatorio: false,
    flgVisivel: true,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const gridView = useRef();
  const gridViewConfig = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const obj = await getTransacao(registryKey);

      setData(obj);
      if (gridView && gridView.current) {
        gridView.current.setDataSource(obj.pages);
      }

      if (
        Number.isInteger(registryKey) &&
        gridViewConfig &&
        gridViewConfig.current
      ) {
        gridViewConfig.current.setDataSource(obj.pagesConfig);
      }

      setLoading(false);
    } else {
      setData({});
      gridView.current.setDataSource([]);
    }

    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const paginas = gridView.current.getDataSource() ?? [];
    const paginasConfiguracoes =
      data?.nrSeqTransacao > 0
        ? gridViewConfig.current.getDataSource() ?? []
        : null;
    const obj =
      data?.nrSeqTransacao > 0
        ? { ...data, paginas, paginasConfiguracoes }
        : { ...data, paginas };

    const { status, message: msg } = await saveTransacao(obj);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      gridView.current.setDataSource([]);
      if (data?.nrSeqTransacao > 0) {
        gridViewConfig.current.setDataSource([]);
      }
    }

    setData(status === ResponseStatus.Success ? {} : data);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteTransacao(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchMenu = async (e) => {
    const response = await getMenuAutoComplete({
      noMenu: e,
    });
    if (response.message) onSetMessage(response.status, response.message);
    return response.data;
  };

  const onSearchUsuario = async (e) => {
    const response = await getUsuarioList({
      noLogin: e,
    });
    if (response.message) onSetMessage(response.status, response.message);
    return response.data;
  };

  const onSearchTransacaoPagina = async (e) => {
    const list = gridView.current.getDataSource();

    return list.filter((item) => item.noPaginaWeb.includes(e));
  };

  const onClickAddPagina = async () => {
    const obj = new TransacaoPagina({
      nrSeqTransacao: data.nrSeqTransacao,
      noPaginaWeb: dataPagina.noPaginaWeb,
      flgPrincipal: dataPagina.flgPrincipal ?? false,
      noPaginaWebRet: dataPagina.noPaginaWebRet,
      status: 'Inserir',
    });

    if (obj.noPaginaWeb) {
      const list = gridView.current.getDataSource();
      list.push(obj);

      if (gridView && gridView.current) gridView.current.setDataSource(list);

      setDataPagina(new TransacaoPagina());
    } else {
      onSetMessage(Theme.Danger, 'Necessário informa todos os campos');
    }
  };

  const onClickRemovePagina = async (e) => {
    const list = gridView.current.getDataSource();

    list.filter((el) => el === e)[0].status = GridView.EnumStatus.Remover;

    if (gridView && gridView.current) gridView.current.setDataSource(list);
  };

  const columns = [
    { key: 'nrSeqTransacaoPagina', type: GridView.ColumnTypes.Checkbox },
    { key: 'noPaginaWeb', title: 'Página' },
    { key: 'flgPrincipal', title: 'Página Principal?' },
    { key: 'noPaginaWebRet', title: 'Página de Retorno' },
    {
      key: 'nrSeqTransacaoPagina',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemovePagina(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onNewDataPaginaConfig = () => {
    setDataPaginaConfig({
      flgHabilitado: true,
      flgObrigatorio: false,
      flgVisivel: true,
    });
  };

  const onClickAddConfiguracao = async () => {
    const obj = new TransacaoPaginaConfiguracao({
      noPaginaWeb: dataPaginaConfig.noPaginaWeb,
      noCampo: dataPaginaConfig.noCampo,
      noLabel: dataPaginaConfig.noLabel,
      flgHabilitado: dataPaginaConfig.flgHabilitado ?? false,
      flgObrigatorio: dataPaginaConfig.flgObrigatorio ?? false,
      flgVisivel: dataPaginaConfig.flgVisivel ?? false,
      noDefaultValue: dataPaginaConfig.noDefaultValue ?? null,
      nrSeqUsuario: dataPaginaConfig.nrSeqUsuario ?? null,
      usuario: dataPaginaConfig.usuario ?? null,
      nrSeqTransacaoPagina: dataPaginaConfig.nrSeqTransacaoPagina ?? null,
      status: 'Inserir',
    });

    if (obj.noPaginaWeb && obj.noCampo) {
      const list = gridViewConfig.current.getDataSource();
      list.push(obj);

      gridViewConfig.current.setDataSource(list);

      onNewDataPaginaConfig();
    } else {
      onSetMessage(Theme.Danger, 'Necessário informa campos obrigatórios');
    }
  };

  const onClickRemoveConfiguracao = async (e) => {
    const list = gridViewConfig.current.getDataSource();

    list.filter((el) => el === e)[0].status = GridView.EnumStatus.Remover;

    if (gridViewConfig && gridViewConfig.current)
      gridViewConfig.current.setDataSource(list);
  };

  const columnsGridConfig = [
    {
      key: 'nrSeqTransacaoPaginaConfiguracao',
      type: GridView.ColumnTypes.Checkbox,
    },
    { key: 'noLabel', title: 'Label' },
    { key: 'noPaginaWeb', title: 'Página' },
    { key: 'noCampo', title: 'Campo' },
    { key: 'noDefaultValue', title: 'Valor Padrão do Campo' },
    { key: 'usuario.noLogin', title: 'Usuario' },
    { key: 'flgVisivel', title: 'Visível?' },
    { key: 'flgObrigatorio', title: 'Obrigatório?' },
    { key: 'flgHabilitado', title: 'Habilitado?' },
    {
      key: 'nrSeqTransacaoPaginaConfiguracao',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemoveConfiguracao(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const { id: idSelecao } = PageTypes.Selection;

  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Transação'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => {
        setData({});
        gridView.current.setDataSource([]);
      }}
      onSave={save}
      onDelete={data?.nrSeqTransacao > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row mb-3 d-flex justify-content-between'>
        <div className='col-3 mb-3'>
          <Textbox
            required
            maxLength={40}
            label='Nome da Transação'
            text={data.noTransacao}
            onChangedValue={(noTransacao) => setData({ ...data, noTransacao })}
          />
        </div>
        <div className='col-2 mb-3'>
          <Textbox
            required
            maxLength={60}
            label='Diretório Fonte'
            text={data.noDirFonte}
            onChangedValue={(noDirFonte) => setData({ ...data, noDirFonte })}
          />
        </div>
        <div className='col-2 mb-3'>
          <Autocomplete
            required
            label='Menu'
            searchDataSource={onSearchMenu}
            selectedItem={data.menu}
            onSelectItem={(menu) => {
              setData({
                ...data,
                menu,
                nrSeqMenu: menu.nrSeqMenu,
              });
            }}
            dataSourceTextProperty='noMenu'
          />
        </div>
        <div className='col-1 mb-3'>
          <Switch
            formControl
            checked={data.flgMobile}
            label='Mobile'
            onChange={(flgMobile) =>
              setData({
                ...data,
                flgMobile,
              })
            }
          />
        </div>
        <div className='col-2 mb-3'>
          <Textbox
            label='Ordem'
            text={data.nrOrdem || 0}
            mask={MaskTypes.Integer}
            onChangedValue={(nrOrdem) => setData({ ...data, nrOrdem })}
          />
        </div>

        <div className='col-1 mb-3'>
          <Textbox
            maxLength={40}
            label='Código'
            text={data.cdTransacao}
            onChangedValue={(cdTransacao) => setData({ ...data, cdTransacao })}
          />
        </div>
      </div>
      {/* TransacaoPagina */}
      <div className='row mb-3'>
        <Panel>
          <Panel.Header
            title='Transação Página'
            theme={Theme.Primary}
            align={JustifyContent.Star}
            collapsed
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-4'>
                <Textbox
                  maxLength={100}
                  label='Nome da Página'
                  text={dataPagina.noPaginaWeb}
                  onChangedValue={(noPaginaWeb) =>
                    setDataPagina({ ...dataPagina, noPaginaWeb })
                  }
                />
              </div>

              <div className='col-4'>
                <Textbox
                  maxLength={100}
                  label='Nome da Página de Retorno'
                  text={dataPagina.noPaginaWebRet}
                  onChangedValue={(noPaginaWebRet) =>
                    setDataPagina({ ...dataPagina, noPaginaWebRet })
                  }
                />
              </div>
              <div className='col'>
                <Switch
                  formControl
                  label='Página Principal'
                  checked={dataPagina.flgPrincipal}
                  onChange={(flgPrincipal) =>
                    setDataPagina({ ...dataPagina, flgPrincipal })
                  }
                />
              </div>
              <div className='col d-flex align-items-end justify-content-start'>
                <Button
                  onClick={onClickAddPagina}
                  outline
                  icon='plus'
                  theme={Theme.Success}
                  template={Button.Templates.Button}
                  text='Adicionar'
                />
              </div>
            </div>
            <div className='row'>
              <GridView
                canControlVisibility
                ref={gridView}
                dataColumns={columns}
                offlineData
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </Panel.Body>
        </Panel>
      </div>
      {/* TransacaoPaginaConfiguracao */}
      <div className='row mb-3'>
        <Panel visible={data?.nrSeqTransacao > 0}>
          <Panel.Header
            title='Configurações Transação Página'
            theme={Theme.Primary}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-3'>
                <Autocomplete
                  label='Transação Página'
                  searchDataSource={onSearchTransacaoPagina}
                  selectedItem={dataPaginaConfig.transacaoPagina}
                  onSelectItem={(transacaoPagina) => {
                    setDataPaginaConfig({
                      ...dataPaginaConfig,
                      transacaoPagina,
                      nrSeqTransacaoPagina:
                        transacaoPagina.nrSeqTransacaoPagina,
                      noPaginaWeb: transacaoPagina.noPaginaWeb,
                    });
                  }}
                  dataSourceTextProperty='noPaginaWeb'
                />
              </div>
              <div className='col-3'>
                <Textbox
                  maxLength={100}
                  label='Campo'
                  tooltip='id do campo que será alterado'
                  text={dataPaginaConfig.noCampo}
                  onChangedValue={(noCampo) =>
                    setDataPaginaConfig({
                      ...dataPaginaConfig,
                      noCampo,
                    })
                  }
                />
              </div>
              <div className='col-3'>
                <Textbox
                  maxLength={50}
                  label='Valor Padrão do Campo'
                  text={dataPaginaConfig.noDefaultValue}
                  onChangedValue={(noDefaultValue) =>
                    setDataPaginaConfig({
                      ...dataPaginaConfig,
                      noDefaultValue,
                    })
                  }
                />
              </div>
              <div className='col-3'>
                <Textbox
                  maxLength={100}
                  label='Label'
                  text={dataPaginaConfig.noLabel}
                  onChangedValue={(noLabel) =>
                    setDataPaginaConfig({
                      ...dataPaginaConfig,
                      noLabel,
                    })
                  }
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-6'>
                <Autocomplete
                  label='Usuário'
                  searchDataSource={onSearchUsuario}
                  selectedItem={dataPaginaConfig.usuario}
                  onSelectItem={(usuario) => {
                    setDataPaginaConfig({
                      ...dataPaginaConfig,
                      usuario,
                      nrSeqUsuario: usuario.nrSeqUsuario,
                      noLogin: usuario.noLogin,
                    });
                  }}
                  dataSourceTextProperty='noLogin'
                />
              </div>
              <div className='col-1'>
                <Switch
                  formControl
                  label='Obrigatório'
                  checked={dataPaginaConfig.flgObrigatorio}
                  onChange={(flgObrigatorio) =>
                    setDataPaginaConfig({
                      ...dataPaginaConfig,
                      flgObrigatorio,
                    })
                  }
                />
              </div>
              <div className='col-1'>
                <Switch
                  formControl
                  label='Visível'
                  checked={dataPaginaConfig.flgVisivel}
                  onChange={(flgVisivel) =>
                    setDataPaginaConfig({
                      ...dataPaginaConfig,
                      flgVisivel,
                    })
                  }
                />
              </div>
              <div className='col-1'>
                <Switch
                  formControl
                  label='Habilitado'
                  checked={dataPaginaConfig.flgHabilitado}
                  onChange={(flgHabilitado) =>
                    setDataPaginaConfig({
                      ...dataPaginaConfig,
                      flgHabilitado,
                    })
                  }
                />
              </div>
              <div className='col d-flex align-items-end'>
                <Button
                  onClick={onClickAddConfiguracao}
                  outline
                  theme={Theme.Success}
                  className='px-5'
                  icon='plus'
                  template={Button.Templates.Default}
                  text='Adicionar'
                />
              </div>
            </div>
            <div className='row'>
              <GridView
                canControlVisibility
                ref={gridViewConfig}
                dataColumns={columnsGridConfig}
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </Panel.Body>
        </Panel>
      </div>
    </CSDManutPage>
  );
}
