export default class Vencimento {
  constructor(jsonObject = {}) {
    this.nrSeqVencimento = jsonObject.nrSeqVencimento;
    this.cdVencimento = jsonObject.cdVencimento;
    this.diaVencimento = jsonObject.diaVencimento;
    this.noVencimento = jsonObject.noVencimento;
    this.flgTipoVencimento = jsonObject.flgTipoVencimento;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgTipoDia = jsonObject.flgTipoDia;
  }

  toJson = () => JSON.stringify(this);
}
