export default class TipoIntervalo {
  constructor(jsonObject = {}) {
    this.nrSeqTipoIntervalo = jsonObject.nrSeqTipoIntervalo;
    this.noTipoIntervalo = jsonObject.noTipoIntervalo;
    this.cdTipoIntervalo = jsonObject.cdTipoIntervalo;
    this.flgTipo = jsonObject.flgTipo;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.statusIntervaloManutencao = jsonObject.statusIntervaloManutencao;
  }

  toJson = () => JSON.stringify(this);
}
