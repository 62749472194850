import { CategoriaLigacao } from 'core/models/TEL';
import api from '../api';

const getCategoriaLigacaoAutoComplete = async (filters) => {
  const response = await api.get(`/CategoriaLigacao/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    categorias: value?.map((json) => new CategoriaLigacao(json)) ?? [],
  };
};

export { getCategoriaLigacaoAutoComplete };
