export default class TipoFrete {
  constructor(jsonObject = {}) {
    this.cdTipoFrete = jsonObject.cdTipoFrete;
    this.nrSeqTipoFrete = jsonObject.nrSeqTipoFrete;
    this.noTipoFrete = jsonObject.noTipoFrete;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
