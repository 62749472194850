import qs from 'qs';
import Pagination from 'core/models/pagination';
import Agencia from 'core/models/FIN/agencia';
import api from '../api';

export const getAgenciaList = async (filters) => {
  const response = await api.get(`/Agencia/Search`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Agencia(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getAgencia = async (id) => {
  const response = await api.get(`/Agencia/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Agencia(value) : {};
};

export const saveAgencia = async (data) => {
  const response = await api.post(`/Agencia/`, data);
  return response.data ?? {};
};

export const deleteAgencia = async (id) => {
  const response = await api.delete(`/Agencia/`, { params: { id } });
  return response.data ?? {};
};

export const printAgencia = async (filters) => {
  const response = await api.get(`/Agencia/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelAgencia = async (filters) => {
  const response = await api.get(`/Agencia/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllAgencia = async (ids) => {
  const response = await api.delete(`/Agencia/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getAgenciaAutoComplete = async (filters) => {
  const response = await api.get(`/Agencia/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Agencia(json)) ?? [],
  };
};
