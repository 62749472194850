import React, { useState, useEffect, useRef } from 'react';

import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  GridView,
  Loader,
  Modal,
  Notification,
} from 'ui/components';
import { getEmailContaList } from 'core/services/ASS/emailConta';
import { salvarOcorrencias } from 'core/services/FIN';
import { sendFaturas } from 'core/services/TEL';

export default function ModalEnvioSMS(props, onSave) {
  const [messageState, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const gridView = useRef();
  const gridView2 = useRef();

  const { show, envioSms, onClose } = props;

  const onSearchEmailConta = async (e) => {
    const { emails } = await getEmailContaList({
      noTituloemail: e,
    });
    return emails;
  };

  const columns = [
    {
      key: 'cdFatura',
      title: 'Cd Fatura',
    },
    {
      key: 'nrTit',
      title: 'Nr. Tit',
    },
    {
      key: 'cliente.noPessoa',
      title: 'Cliente',
    },
    {
      key: 'telefone',
      title: 'Telefone',
    },
    {
      key: 'tituloEspecie.noEspecie',
      title: 'Tipo',
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma',
    },
    {
      key: 'vlrSaldoTit',
      title: 'Atraso',
    },
    {
      key: 'dtVencimento',
      title: 'Data Vencimento',
      format: GridView.DataTypes.Date,
    },
  ];
  const columns2 = [
    {
      key: 'cliente.noPessoa',
      title: 'Cliente',
    },
    {
      key: 'mensagem.msgEmailConta',
      title: 'Mensagem',
    },
  ];

  const onSend = async () => {
    setLoading(true);
    const param = {
      ...data,
      FlgEnvioPadraoSms: true,
      nrSeqTituloReceber: envioSms?.map((p) => p.nrSeqTituloReceber),
    };

    const ocorrencia = [];
    ocorrencia.push({
      dtCadastro: new Date(),
      noOcorrencia: data?.emailTitulo?.msgEmailTitulo,
      nrSeqSTituloReceber: envioSms?.map((p) => p.nrSeqTituloReceber),
    });

    const { status, message } = await sendFaturas(param);

    setLoading(false);

    if (status === ResponseStatus.Success) {
      await salvarOcorrencias(ocorrencia);
      onSave(status, message);
      onClose();
    } else {
      const warningOrDanger =
        status === ResponseStatus.Warning ? Theme.Warning : Theme.Danger;
      // setDataListErros(erros);
      setMessage({
        message,
        theme:
          status === ResponseStatus.Success ? Theme.Success : warningOrDanger,
      });
    }
  };

  useEffect(() => {
    if (envioSms !== undefined) {
      gridView?.current?.setDataSource(envioSms);
    }
  }, [show]);

  const adicionarSms = async () => {
    let lista = [];

    lista = gridView.current.getDataSource();
    lista[0].mensagem = data.mensagem;
    lista[0].nrSeqEmailConta = data.nrSeqEmailConta;

    if (gridView2 && gridView2.current) gridView2.current.setDataSource(lista);
    setData({});
  };

  const sair = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
    });
  };

  return (
    <Modal show={show} title='Envio SMS' size={BootstrapSizes.ExtraLarge}>
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-8 mb-3'>
            <Autocomplete
              label='Mensagem'
              searchDataSource={onSearchEmailConta}
              required
              selectedItem={data?.mensagem}
              onSelectItem={(mensagem) => {
                setData({
                  ...data,
                  mensagem,
                  nrSeqEmailConta: mensagem.nrSeqEmailConta,
                });
              }}
              dataSourceTextProperty='noTituloemail'
            />
          </div>
          <div className='col-4 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Adicionar Selecionados'
              onClick={adicionarSms}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridView2}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns2}
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-4 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Enviar Sms'
              onClick={onSend}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-4 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Sair'
              onClick={sair}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button text='Enviar' theme={Theme.Success} icon={['far', 'save']} />
        <Button
          text='Fechar'
          theme={Theme.Danger}
          icon='ban'
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
