import { Usuario } from '../SEG';

export default class TituloReceberOcorrencia {
  constructor(jsonObject = {}) {
    this.nrSeqTituloReceberOcorrencia = jsonObject.nrSeqTituloReceberOcorrencia;
    this.noOcorrencia = jsonObject.noOcorrencia;
    this.dtCadastro = jsonObject.dtCadastro;
    this.nrSeqTituloReceber = jsonObject.nrSeqTituloReceber;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.flgOcorrenciaSMS = jsonObject.flgOcorrenciaSMS;
    this.noPessoaUsuarioCad = jsonObject.noPessoaUsuarioCad;
    this.usuario = new Usuario(jsonObject.usuario ?? {});
    this.dtDataAtual = jsonObject.dtDataAtual;
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
