import qs from 'qs';
import Pagination from 'core/models/pagination';
import VendedorRegiao from 'core/models/VEN/vendedorRegiao';
import api from '../api';

const baseURL = `/VEN/VendedorRegiao`;

export const getVendedorRegiaoList = async (filters) => {
  const response = await api.get(`/$VendedorRegiao/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new VendedorRegiao(json)) ?? [],
  };
};

export const getVendedorRegiaoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new VendedorRegiao(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getVendedorRegiao = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new VendedorRegiao(value) : {};
};

export const saveVendedorRegiao = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteVendedorRegiao = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printVendedorRegiao = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelVendedorRegiao = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllVendedorRegiao = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getVendedorRegiaoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/SearchAutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    regiao: value?.map((json) => new VendedorRegiao(json)) ?? [],
  };
};
