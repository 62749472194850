export default class VendedorTelefonia {
  constructor(jsonObject = {}) {
    this.nrSeqVendedorTelefonia = jsonObject.nrSeqVendedorTelefonia;
    this.nrSeqPessoaVen = jsonObject.nrSeqPessoaVen;
    this.nrSeqCentroDeCusto = jsonObject.nrSeqCentroDeCusto;
    this.flgPercentual = jsonObject.flgPercentual;
    this.vlrComissao = jsonObject.vlrComissao;
    this.flgDescontaCustoNf = jsonObject.flgDescontaCustoNf;
    this.flgDescontaCustoTaxaBancaria = jsonObject.flgDescontaCustoTaxaBancaria;
    this.flgDescontaAviso = jsonObject.flgDescontaAviso;
    this.flgParcelaUnica = jsonObject.flgParcelaUnica;
    this.flgDescontaCustoRateio = jsonObject.flgDescontaCustoRateio;
    this.flgDescontaParcelaAberta = jsonObject.flgDescontaParcelaAberta;
  }

  toJson = () => JSON.stringify(this);
}
