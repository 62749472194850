import React, { useEffect, useRef, useState } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  getNfvPagined,
  printNfv,
  deleteAllNfv,
  excelNfv,
  postTransmit,
  postDownload,
  printNFe,
} from 'core/services/VEN/Nfv';

import { MaskTypes } from 'ui/Helpers/masks';

import { getClienteAutoComplete } from 'core/services/FIN/cliente';
import { getEmpresasUsuario } from 'core/services/SEG/empresa';

import {
  CSDSelPage,
  GridView,
  Textbox,
  DatePicker,
  Autocomplete,
  RadioButton,
} from 'ui/components';

import ModalInutilizar from './modalInutilizar';
import ModalCancelar from './modalCancelar';

export default function Nfv({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({
    flgCondicaoNota: 'T',
    flgDataInicial: false,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [modalShow, setModalShow] = useState({});

  const download = (filename, text) => {
    const element = document.createElement('a');

    const link = `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`;

    element.setAttribute('href', link);

    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const onClickDownload = async (e) => {
    setLoading(true);

    const { message: msg } = await postDownload(e);

    download(msg.split(';')[0], msg.split(';')[1]);

    setLoading(false);
  };

  const condicaoNota = [
    { text: 'Notas Pendentes', value: 'P' },
    { text: 'Notas Autorizadas', value: 'A' },
    { text: 'Notas Canceladas', value: 'C' },
    { text: 'Todas as Notas', value: 'T' },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getNfvPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoaCli: e,
    });
    if (msg) onSetMessage(status, msg);
    return clientes;
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return empresas;
  };

  const onClickTransmitir = async (e) => {
    setLoading(true);

    const { status, message: msg } = await postTransmit(e);

    setLoading(false);

    onSetMessage(status, msg);

    search();
  };

  const onOpenInutilizar = async (e) => {
    setModalShow({ ...modalShow, inutilizar: true, nfv: e });
  };

  const onOpenCancelar = async (e) => {
    setModalShow({ ...modalShow, cancelar: true, nfv: e });
  };
  /*
  const onPrintNF = async (e) => {
    setLoading(true);
    const { value } = await printNF({ nrSeqNfv: e.nrSeqNfv });

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  */

  const onPrintNFE = async (e) => {
    setLoading(true);
    const { value } = await printNFe({ nrSeqNfv: e.nrSeqNfv });

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const columns = [
    { key: 'nrSeqNfv', type: GridView.ColumnTypes.Checkbox },
    { key: 'nrNfv', title: 'Número' },
    { key: 'cliente.noPessoa', title: 'Cliente' },
    { key: 'dtCad', title: 'Cadastro', format: GridView.DataTypes.Date },
    {
      key: 'dtEmissao',
      title: 'Emissão',
      format: GridView.DataTypes.Date,
    },
    { key: 'vlrNfv', title: 'Total', format: GridView.DataTypes.Decimal },

    {
      key: 'noStatus',
      title: 'Status',
    },
    {
      key: 'nrSeqNfv',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickTransmitir(e, datasource),
      theme: Theme.Success,
      icon: 'check',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Transmitir',
      tooltipDirection: 'bottom',
      title: 'Transmitir',
      visibleDynamic: 'flgPendenteTransmitir',
    },

    {
      key: 'nrSeqNfv',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onOpenCancelar(e, datasource),
      theme: Theme.Danger,
      icon: ['fa', 'ban'],
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Cancelar',
      tooltipDirection: 'bottom',
      title: 'Cancelar',
      visibleDynamic: 'flgPendenteCancelar',
    },

    {
      key: 'nrSeqNfv',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onOpenInutilizar(e),
      theme: Theme.Danger,
      icon: ['fa', 'exclamation'],
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Inutilizar',
      tooltipDirection: 'bottom',
      title: 'Inutilizar',
      visibleDynamic: 'flgPendenteInutilizar',
    },

    {
      key: 'nrSeqNfv',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Info,
      icon: ['fa', 'download'],
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      tooltipDirection: 'bottom',
      title: 'Download',
      visibleDynamic: 'flgPendenteDownload',
    },

    {
      key: 'nrSeqNfv',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onPrintNFE(e, datasource),
      theme: Theme.Info,
      icon: ['fa', 'print'],
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
      title: 'Imprimir',
    },
  ];

  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getNfvPagined({
        flgDataInicial: true,
      });

      if (data.length > 0)
        setFiltros({
          ...filtros,
          dtEmissaoInicial: data[0].dtEmissaoInicial,
          dtEmissaoFinal: data[0].dtEmissaoFinal,
        });

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Nfv/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqNfv = sourceRow.find((e) => e.key === 'nrSeqNfv');
    onOpenMaintenance(nrSeqNfv.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printNfv(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onDownloadExcel = async () => {
    setLoading(true);

    const { value: base64 } = await excelNfv(filtros);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'Nfv.csv';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllNfv(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Nfv'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      onDownloadExcel={onDownloadExcel}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-1'>
          <Textbox
            label='Nr. Nota'
            text={filtros.nrNfv}
            onChangedValue={(nrNfv) => setFiltros({ ...filtros, nrNfv })}
          />
        </div>

        <div className='col-2'>
          <DatePicker
            label='Emissão Inicial'
            text={filtros.dtEmissaoInicial}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtEmissaoInicial) => {
              setFiltros({ ...filtros, dtEmissaoInicial });
            }}
          />
        </div>

        <div className='col-2'>
          <DatePicker
            label='Emissão Final'
            text={filtros.dtEmissaoFinal}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtEmissaoFinal) => {
              setFiltros({ ...filtros, dtEmissaoFinal });
            }}
          />
        </div>

        <div className='col-5'>
          <Autocomplete
            label='Cliente'
            searchDataSource={onSearchCliente}
            selectedItem={filtros.cliente}
            onSelectItem={(cliente) => {
              setFiltros({
                ...filtros,
                cliente,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-5'>
          <Autocomplete
            label='Empresa'
            searchDataSource={onSearchEmpresa}
            selectedItem={filtros.empresa}
            onSelectItem={(empresa) => {
              setFiltros({
                ...filtros,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>

        <div className='col-5'>
          <RadioButton
            label='Condição Nota'
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={filtros.flgCondicaoNota}
            buttons={condicaoNota}
            onChange={(flgCondicaoNota) =>
              setFiltros({
                ...filtros,
                flgCondicaoNota,
              })
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>

      <ModalInutilizar
        show={modalShow.inutilizar}
        nfv={modalShow.nfv}
        onClose={() => {
          setModalShow({ ...modalShow, inutilizar: false, nfv: undefined });
          search();
        }}
      />

      <ModalCancelar
        show={modalShow.cancelar}
        nfv={modalShow.nfv}
        onClose={() => {
          setModalShow({ ...modalShow, cancelar: false, nfv: undefined });
          search();
        }}
      />
    </CSDSelPage>
  );
}
