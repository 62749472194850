import React, { useState, useRef } from 'react';

import {
  getVendedorTelefoniaAutoComplete,
  getCentroDeCustoAutoComplete,
  getContratoAgrupadoList,
} from 'core/services/TEL';

import {
  BootstrapSizes,
  ResponseStatus,
  SortDirections,
  Theme,
} from 'ui/Helpers/utils';
import { collapseSection, expandSection } from 'ui/Helpers/effects';
import { MaskTypes, mask } from 'ui/Helpers/masks';
import {
  postBuscarComissaoCalculada,
  postCalcularComissao,
  postGerarTituloPagarComissao,
  postVerificaSeComissaoPendenteParaFaturar,
  postExcluirComissaoCalculada,
} from 'core/services/GProj/faturarContratos';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getTipoDespesaAutoComplete } from 'core/services/FIN/tipoDespesa';
import {
  Autocomplete,
  Button,
  DropdownMulti,
  FontAwesomeIcon,
  GridView,
  Loader,
  Notification,
  Panel,
  Textbox,
} from 'ui/components';

import './calcularComissao.scss';

export default function CalcularComissao({ className, onMessage }) {
  const gridRecursosComissao = useRef();
  const gridRecursosComissaoGerada = useRef();
  const dropdownCentroCusto = useRef();
  const [comissao, setComissao] = useState({});
  const [recursos, setRecursos] = useState([]);
  const [state, setState] = useState([]);
  const [selectedItems, setSelectedItems] = useState({});
  const [loading, setLoading] = useState(false);
  const validarCalculoComissao = () => {
    if (
      !comissao.nrSeqContratoAgrupado ||
      comissao.nrSeqContratoAgrupado <= 0
    ) {
      setState({
        ...state,
        message:
          'Obrigatório a seleção do contrato agrupado para o calculo da comissão.',
        messageTheme: Theme.Danger,
      });
      return false;
    }

    if (
      !comissao.nrSeqVendedorTelefonia ||
      comissao.nrSeqVendedorTelefonia <= 0
    ) {
      setState({
        ...state,
        message:
          'Obrigatório a seleção do vendedor para o calculo da comissão.',
        messageTheme: Theme.Danger,
      });
      return false;
    }

    if (!comissao.nrSeqCentrosCusto || comissao.nrSeqCentrosCusto.length <= 0) {
      setState({
        ...state,
        message:
          'Obrigatório a seleção de um ou mais centros de custo para o calculo da comissão.',
        messageTheme: Theme.Danger,
      });
      return false;
    }

    return true;
  };
  const calcularComissao = async () => {
    if (!validarCalculoComissao()) return;

    setLoading(true);
    const { status, message, data } = await postCalcularComissao(comissao);

    gridRecursosComissao.current.setDataSource(data?.itens ?? []);

    setState({
      ...state,
      loading: false,
      message,
      messageTheme:
        status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setComissao({
      ...comissao,
      ...data,
    });
    setRecursos(data.itens);
    setLoading(false);
  };
  const onTogglePanel = () => {
    const { showDetail } = state;
    const element = document.getElementById('panel');

    if (showDetail) collapseSection(element);
    else expandSection(element);
    setState({ ...state, showDetail: !showDetail });
  };
  const onTogglePanelComissaoGerada = () => {
    const { showDetail } = state;
    const element = document.getElementById('panelComissaoGerada');

    if (showDetail) collapseSection(element);
    else expandSection(element);
    setState({ ...state, showDetail: !showDetail });
  };
  const onRowRender = (rowSource) => {
    const status = rowSource.find((row) => row.key === 'status');
    const pago = status.value === 'Pago' ? '' : 'table-warning';
    return {
      className: status.value === 'Aberto' ? 'table-danger' : pago,
    };
  };
  const onColumnSort = async (columnFilter) => {
    setLoading(true);
    const columnName = columnFilter.replace(' asc', '').replace(' desc', '');

    const sorted =
      recursos?.sort((a, b) => {
        const isNumeric = !Number.isNaN(parseFloat(a[columnName]));
        const compareNumericBA = b[columnName] > a[columnName] ? -1 : 1;
        const compareASC = isNumeric
          ? compareNumericBA
          : a[columnName].localeCompare(b[columnName]);
        const compareNumericAB = a[columnName] > b[columnName] ? -1 : 1;
        const compareDESC = isNumeric
          ? compareNumericAB
          : b[columnName].localeCompare(a[columnName]);
        return columnFilter.endsWith(` ${SortDirections.Asc}`)
          ? compareASC
          : compareDESC;
      }) ?? [];

    if (gridRecursosComissao?.current)
      gridRecursosComissao.current.setDataSource(sorted, {
        sortBy: columnFilter,
      });
    setLoading(false);
  };

  const onColumnSortComissaoGerada = async (columnFilter) => {
    setState({ ...state, loading: true });

    const columnName = columnFilter.replace(' asc', '').replace(' desc', '');

    const sorted =
      recursos?.sort((a, b) => {
        const isNumeric = !Number.isNaN(parseFloat(a[columnName]));
        const compareNumericBA = b[columnName] > a[columnName] ? -1 : 1;
        const compareASC = isNumeric
          ? compareNumericBA
          : a[columnName].localeCompare(b[columnName]);
        const compareNumericAB = a[columnName] > b[columnName] ? -1 : 1;
        const compareDESC = isNumeric
          ? compareNumericAB
          : b[columnName].localeCompare(a[columnName]);
        return columnFilter.endsWith(` ${SortDirections.Asc}`)
          ? compareASC
          : compareDESC;
      }) ?? [];

    if (gridRecursosComissaoGerada?.current)
      gridRecursosComissaoGerada.current.setDataSource(sorted, {
        sortBy: columnFilter,
      });

    setState({ ...state, loading: false });
  };
  const validateAlreadyCalculated = async (validate) => {
    const { data } = await postVerificaSeComissaoPendenteParaFaturar(validate);
    return data;
  };
  const onAutoCompleteFormaPagamento = async (e) => {
    const {
      status,
      message,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
    });

    if (message)
      setState({
        ...state,
        message,
        messageTheme:
          status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return formasPagamento;
  };
  const onAutoCompleteTipoDespesa = async (e) => {
    const { status, message, tipoDespesas } = await getTipoDespesaAutoComplete({
      noTipoDespesa: e,
    });

    if (message)
      setState({
        ...state,
        message,
        messageTheme:
          status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return tipoDespesas;
  };

  const onGerarTitulo = async () => {
    setLoading(true);
    const { status, message } = await postGerarTituloPagarComissao({
      ...comissao,
      ResumoSinteticoS: comissao.itens,
    });
    if (message)
      setState({
        ...state,
        message,
        messageTheme:
          status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    setLoading(false);
  };

  const onSearchVendedor = async (e) => {
    const {
      status,
      message,
      vendedores,
    } = await getVendedorTelefoniaAutoComplete({
      noPessoa: e,
    });

    if (message)
      setState({
        message,
        messageTheme:
          status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return vendedores;
  };
  const handleValidateAlreadyCalculated = async (validate) =>
    validateAlreadyCalculated(validate);
  const handleLoadDataSource = async (vendedor) => {
    const {
      status,
      message,
      data: centros,
    } = await getCentroDeCustoAutoComplete({
      nrSeqVendedorTelefonia: vendedor.nrSeqVendedorTelefonia,
      FiltroCentroCustoPaiNulo: true,
    });

    if (message) {
      setComissao({
        ...comissao,
        nrSeqVendedorTelefonia: vendedor.nrSeqVendedorTelefonia,
      });

      setState({
        ...state,
        message,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
    return centros;
  };

  const excluirComissao = async () => {
    const { status, message } = await postExcluirComissaoCalculada(comissao);
    setState({
      ...state,
      loading: false,
      message,
      messageTheme:
        status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    const validate = {
      nrSeqVendedorTelefonia: comissao.nrSeqVendedorTelefonia,
      nrSeqContratoAgrupado: comissao.nrSeqContratoAgrupado,
    };
    const calculoComissaoPendente = await handleValidateAlreadyCalculated(
      validate
    );
    const { comissoes } = await postBuscarComissaoCalculada(validate);
    gridRecursosComissaoGerada.current.setDataSource(comissoes);
    setComissao({
      ...comissao,
      nrSeqContratoAgrupado: comissao.nrSeqContratoAgrupado,
      calculoComissaoPendente,
    });
  };
  const onChangeContratoAgrupado = async (contratoAgrupado) => {
    setSelectedItems({ ...selectedItems, contratoAgrupado });
    const validate = {
      nrSeqVendedorTelefonia: comissao.nrSeqVendedorTelefonia,
      nrSeqContratoAgrupado: contratoAgrupado.nrSeqContratoAgrupado,
    };
    const calculoComissaoPendente = await handleValidateAlreadyCalculated(
      validate
    );
    const { comissoes } = await postBuscarComissaoCalculada(validate);
    gridRecursosComissaoGerada.current.setDataSource(comissoes);
    setComissao({
      ...comissao,
      nrSeqContratoAgrupado: contratoAgrupado.nrSeqContratoAgrupado,
      calculoComissaoPendente,
    });
  };
  const onChangeVendedor = async (vendedor) => {
    if (vendedor.nrSeqVendedorTelefonia > 0) {
      setSelectedItems({ ...selectedItems, vendedor });
      dropdownCentroCusto.current.loadDataSource(() =>
        handleLoadDataSource(vendedor)
      );

      const validate = {
        nrSeqVendedorTelefonia: vendedor.nrSeqVendedorTelefonia,
        nrSeqContratoAgrupado: comissao.nrSeqContratoAgrupado,
      };

      const calculoComissaoPendente = await handleValidateAlreadyCalculated(
        validate
      );
      setComissao({
        ...comissao,
        nrSeqVendedorTelefonia: vendedor.nrSeqVendedorTelefonia,
        nrSeqPessoaVen: vendedor.nrSeqPessoa,
        calculoComissaoPendente,
      });
      const { comissoes } = await postBuscarComissaoCalculada(validate);
      gridRecursosComissaoGerada.current.setDataSource(comissoes);
    } else {
      setSelectedItems({ ...selectedItems, vendedor });
      setComissao({
        ...comissao,
        nrSeqVendedorTelefonia: undefined,
        nrSeqCentrosCusto: undefined,
        calculoComissaoPendente: false,
      });

      dropdownCentroCusto.current.reset();
      gridRecursosComissaoGerada.current.setDataSource([]);
    }
  };

  const onSearchContratoAgrupado = async (e) => {
    const {
      status,
      message,
      contratosAgrupados,
    } = await getContratoAgrupadoList({ noContratoAgrupado: e });

    if (message && onMessage)
      onMessage(
        message,
        status === ResponseStatus.Success ? Theme.Success : Theme.Danger
      );

    return contratosAgrupados;
  };
  const { message, messageTheme, showDetail } = state;
  const columnsComissaoJaGerada = [
    {
      key: 'nrSeqVendedorTelefoniaComissao',
      type: GridView.ColumnTypes.Checkbox,
    },
    { key: 'chip.empresa.noPessoa', title: 'Empresa', filterable: true },
    { key: 'chip.linhaFormatada', title: 'Linha', filterable: true },
    {
      key: 'centroDeCusto.noCentroDeCusto',
      title: 'Centro de Custo',
      filterable: true,
    },
    {
      key: 'vlrComissao',
      title: 'Comissão',
      format: GridView.DataTypes.Money,
      filterable: true,
      decimalPlaces: 4,
    },
  ];
  const columns = [
    { key: 'nrSeqContaRecurso', type: GridView.ColumnTypes.Checkbox },
    { key: 'noEmpresa', title: 'Empresa', filterable: true },
    { key: 'noCliente', title: 'Cliente', filterable: true },
    { key: 'numeroCompleto', title: 'Linha', filterable: true },
    {
      key: 'noCentroDeCustoCompleto',
      title: 'Centro de Custo',
      filterable: true,
    },
    {
      key: 'vlrContaRecalculada',
      title: 'Recalculo',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrRateioEmpresa',
      title: 'Rateio',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrTaxasBancarias',
      title: 'Taxa',
      format: GridView.DataTypes.Money,
    },
    { key: 'vlrTaxaSMS', title: 'SMS', format: GridView.DataTypes.Money },
    { key: 'aliqIssQn', title: 'ISSQN', format: GridView.DataTypes.Money },
    {
      key: 'vlrResultadoRateioComissao',
      title: 'Resultado',
      format: GridView.DataTypes.Money,
      decimalPlaces: 4,
    },
    {
      key: 'vlrComissao',
      title: 'Comissão',
      format: GridView.DataTypes.Money,
      decimalPlaces: 4,
    },
    {
      key: 'percentualValor',
      title: 'Percentual',
    },
    {
      key: 'parcelaUnica',
      title: 'Parcela Única',
      format: GridView.DataTypes.Money,
    },
    { key: 'status', title: 'Status' },
  ];

  return (
    <div className={className}>
      <Loader loading={loading} />
      <Panel>
        <Panel.Body>
          {message && (
            <div className='mb-3'>
              <Notification
                message={message}
                theme={messageTheme}
                onClose={() => setState({ ...state, message: undefined })}
              />
            </div>
          )}
          <div className='row mb-2'>
            <div className='col'>
              <div className='display-7'>
                Dados utilizados para filtrar as linhas e calcular a comissão
              </div>
              <hr />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-auto'>
              <Autocomplete
                label='Contrato Agrupado'
                searchDataSource={onSearchContratoAgrupado}
                selectedItem={selectedItems.contratoAgrupado}
                onSelectItem={onChangeContratoAgrupado}
                dataSourceTextProperty='noContratoAgrupado'
              />
            </div>
            <div className='col'>
              <Autocomplete
                label='Vendedor'
                searchDataSource={onSearchVendedor}
                selectedItem={selectedItems.vendedor}
                onSelectItem={onChangeVendedor}
                dataSourceTextProperty='noPessoa'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              <DropdownMulti
                label='Centro de Custo'
                ref={dropdownCentroCusto}
                dataSourcePropertyText='noCentroDeCusto'
                dataSourcePropertyValue='nrSeqCentroDeCusto'
                selectedItems={selectedItems.centrosCusto ?? []}
                enabled={comissao.nrSeqVendedorTelefonia > 0}
                onSelectItem={(centrosCusto) => {
                  setSelectedItems({ ...selectedItems, centrosCusto });
                  setComissao({
                    ...comissao,
                    nrSeqCentrosCusto: centrosCusto?.map(
                      (p) => p.nrSeqCentroDeCusto
                    ),
                  });
                }}
              />
            </div>
            <div className='col-auto'>
              <Button
                text='Calcular'
                size={BootstrapSizes.Small}
                theme={Theme.Secondary}
                template={Button.Templates.Quick}
                icon='search'
                onClick={calcularComissao}
              />
            </div>
            <div className='col-auto'>
              <Button
                text='Excluir Comissäo'
                size={BootstrapSizes.Small}
                theme={Theme.Secondary}
                template={Button.Templates.Quick}
                icon='trash-alt'
                onClick={excluirComissao}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>

      <div className='row bg-inactive pt-3 pb-3'>
        <div className='col'>
          <div className='bg-white'>
            <div className='row'>
              <div className='col-auto'>
                <div className='widget-square bg-gradient-primary text-white p-4 br-tl-1 br-bl-1'>
                  <FontAwesomeIcon className='fs-1' icon='cash-register' />
                </div>
              </div>
              <div className='col align-self-end'>
                <div className='row'>
                  <div className='col-auto d-flex align-items-center ms-3 me-2'>
                    <div>
                      <div>Resultado:</div>
                      <div
                        className={`fw-bolder ${
                          comissao?.vlrBaseComissaoTotal >= 0
                            ? 'text-success'
                            : 'text-danger'
                        } display-6`}
                      >
                        {mask(
                          comissao?.vlrBaseComissaoTotal ?? 0,
                          MaskTypes.Currency
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-auto d-flex align-items-end ms-3 p-3'>
                    <div>
                      <Textbox
                        label='Valor da comissão'
                        text={comissao.vlrComissaoTotalAlterado}
                        mask={MaskTypes.Decimal}
                        onChangedValue={(vlrComissaoTotalAlterado) =>
                          setComissao({
                            ...comissao,
                            vlrComissaoTotalAlterado,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <hr className='mb-0 text-primary' />
                  </div>
                  <div className='col-auto d-flex align-items-end'>
                    <div className='pe-3'>
                      <button
                        type='button'
                        className={`btn-link bg-white border-0 link-primary${
                          showDetail ? ' active' : ''
                        }`}
                        onClick={onTogglePanel}
                      >
                        Detalhar
                        <FontAwesomeIcon className='ms-3' icon='chevron-down' />
                      </button>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col'>
                    <hr className='mb-0 text-primary' />
                  </div>
                  <div className='col-auto d-flex align-items-end'>
                    <div className='pe-3'>
                      <button
                        type='button'
                        className={`btn-link bg-white border-0 link-primary${
                          showDetail ? ' active' : ''
                        }`}
                        onClick={onTogglePanelComissaoGerada}
                      >
                        Relatório Comissão Gerada
                        <FontAwesomeIcon className='ms-3' icon='chevron-down' />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id='panel'
              className='row panel-expand'
              style={{ height: '0px' }}
            >
              <div className='col mt-3'>
                <GridView
                  ref={gridRecursosComissao}
                  className='table-striped table-hover table-bordered table-sm'
                  enableExcelExport
                  dataColumns={columns}
                  showPagination={false}
                  showSelectSizes={false}
                  gridSizeList={[9999]}
                  onRowRender={onRowRender}
                  onColumnSort={onColumnSort}
                />
              </div>
            </div>
            <div
              id='panelComissaoGerada'
              className='row panel-expand'
              style={{ height: '0px' }}
            >
              <div className='col mt-3'>
                <GridView
                  ref={gridRecursosComissaoGerada}
                  className='table-striped table-hover table-bordered table-sm'
                  enableExcelExport
                  dataColumns={columnsComissaoJaGerada}
                  showPagination={false}
                  showSelectSizes={false}
                  onColumnSort={onColumnSortComissaoGerada}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-3 bg-inactive'>
        <div className='col'>
          <div className='bg-white'>
            <div className='p-4 pt-3'>
              <div className='row'>
                <div className='col'>
                  <span className='display-7'>Titulo a Pagar</span>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <hr />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <div className='row mb-3'>
                    <div className='col'>
                      <Autocomplete
                        label='Forma de Pagamento'
                        searchDataSource={onAutoCompleteFormaPagamento}
                        selectedItem={comissao.formaPagamento}
                        visible={comissao.calculoComissaoPendente}
                        onSelectItem={(formaPagamento) =>
                          setComissao({
                            ...comissao,
                            formaPagamento,
                            nrSeqFormaPagamento:
                              formaPagamento.nrSeqFormaPagamento,
                          })
                        }
                        dataSourceTextProperty='noFormaPagamento'
                      />
                    </div>
                    <div className='col'>
                      <Autocomplete
                        label='Tipo de Despesa'
                        searchDataSource={onAutoCompleteTipoDespesa}
                        visible={comissao.calculoComissaoPendente}
                        selectedItem={comissao.tipoDespesa}
                        onSelectItem={(tipoDespesa) =>
                          setComissao({
                            ...comissao,
                            tipoDespesa,
                            nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
                          })
                        }
                        dataSourceTextProperty='noTipoDespesa'
                      />
                    </div>
                    <div className='col-auto'>
                      <Textbox
                        label='Data de Vencimento'
                        text={comissao.dataVencimento}
                        visible={comissao.calculoComissaoPendente}
                        maxLength={10}
                        mask={MaskTypes.Date}
                        onChangedValue={(dataVencimento) =>
                          setComissao({ ...comissao, dataVencimento })
                        }
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <Textbox
                        label='Observações'
                        text={comissao.observacao}
                        visible={comissao.calculoComissaoPendente}
                        required
                        onChangedValue={(e) =>
                          setComissao({
                            ...comissao,
                            observacao: e,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className='col-auto d-flex align-items-center'>
                  <Button
                    text='Gerar Titulo'
                    size={BootstrapSizes.Medium}
                    visible={comissao.calculoComissaoPendente}
                    theme={Theme.Success}
                    template={Button.Templates.Quick}
                    icon='file-invoice-dollar'
                    onClick={onGerarTitulo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
