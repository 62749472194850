import qs from 'qs';
import Pagination from 'core/models/pagination';
import ModeloManutencaoPreventiva from 'core/models/FRO/modeloManutencaoPreventiva';
import api from '../api';

const baseURL = `/FRO/ModeloManutencaoPreventiva`;

export const getModeloManutencaoPreventivaList = async (filters) => {
  const response = await api.get(`/$ModeloManutencaoPreventiva/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ModeloManutencaoPreventiva(json)) ?? [],
  };
};

export const getModeloManutencaoPreventivaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ModeloManutencaoPreventiva(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getModeloManutencaoPreventiva = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ModeloManutencaoPreventiva(value) : {};
};

export const saveModeloManutencaoPreventiva = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteModeloManutencaoPreventiva = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printModeloManutencaoPreventiva = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelModeloManutencaoPreventiva = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllModeloManutencaoPreventiva = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
