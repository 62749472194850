export default class GrauParentesco {
  constructor(jsonObject = {}) {
    this.nrSeqGrauParentesco = jsonObject.nrSeqGrauParentesco;
    this.noGrauParentesco = jsonObject.noGrauParentesco;
    this.cdGrauParentesco = jsonObject.cdGrauParentesco;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
