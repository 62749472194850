import ChecklistCampos from 'core/models/FRO/checklistCampos';
import ManutencaoChecklistFrotaVeiculo from 'core/models/FRO/manutencaoChecklistFrotaVeiculo';

export default class ChecklistValor {
  constructor(jsonObject = {}) {
    this.nrSeqChecklistValor = jsonObject.nrSeqChecklistValor;
    this.cdChecklist = jsonObject.cdChecklist;
    this.valor = jsonObject.valor;
    this.nrSeqManutencaoChecklistFrotaVeiculo =
      jsonObject.nrSeqManutencaoChecklistFrotaVeiculo;
    this.nrSeqChecklistCampos = jsonObject.nrSeqChecklistCampos;
    this.flgIncluiObs = jsonObject.flgIncluiObs;

    // #region Propriedades Auxiliares
    this.placa = jsonObject.placa;
    this.manutencaoChecklistFrotaVeiculo = new ManutencaoChecklistFrotaVeiculo(
      jsonObject.manutencaoChecklistFrotaVeiculo ?? {}
    );
    this.checklistCamposSearch = new ChecklistCampos(
      jsonObject.checklistCamposSearch ?? {}
    );
    this.checklistCampos = new ChecklistCampos(
      jsonObject.checklistCampos ?? {}
    );
    this.listChecklistCampos = jsonObject.listChecklistCampos
      ? jsonObject.listChecklistCampos.map(
          (item) => new ChecklistCampos(item ?? {})
        )
      : [];
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
