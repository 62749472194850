import React, { useState, useEffect } from 'react';
import { generateID } from 'ui/Helpers/utils';

export default function Textarea({
  onChangedValue,
  placeholder,
  className,
  id = generateID(),
  required,
  readOnly,
  maxLength,
  label,
  invalidFeedback,
  rows,
  text,
}) {
  const [textState, setTextState] = useState(undefined);
  useEffect(() => {
    setTextState(text);
  }, [text]);

  const handleValueChange = (e) => {
    const { value } = e.target;
    setTextState(value);

    if (onChangedValue) onChangedValue(value);
  };

  const inputProps = {
    placeholder: placeholder ?? '...',
    className: 'form-control',
    value: textState,
    onChange: handleValueChange,
    id,
  };

  if (className) inputProps.className += ` ${className}`;
  if (required) inputProps.required = required;
  if (readOnly) inputProps.readOnly = readOnly;
  if (maxLength) inputProps.maxLength = maxLength;
  if (rows) inputProps.rows = rows;

  return (
    <>
      <label className='form-label' htmlFor={id}>
        {label}
      </label>
      <div className='CSDTextbox input-group'>
        {React.createElement('textarea', inputProps)}
        {invalidFeedback && (
          <div className='invalid-feedback'>{invalidFeedback}</div>
        )}
      </div>
    </>
  );
}
