import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  DatePicker,
  Textbox,
  Switch,
  Textarea,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getPneuConserto,
  savePneuConserto,
  deletePneuConserto,
} from 'core/services/FRO/pneuConserto';
import { getPneuAutoComplete } from 'core/services/FRO/pneu';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';

export default function PneuConsertoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getPneuConserto(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await savePneuConserto(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deletePneuConserto(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchPneu = async (e) => {
    const { status, message: msg, data: pneus } = await getPneuAutoComplete({
      cdPneu: e,
    });
    if (msg) onSetMessage(status, msg);
    return pneus;
  };

  const onSearchPessoaFor = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaFors,
    } = await getFornecedorAutoComplete({
      noPessoaFor: e,
    });
    if (msg) onSetMessage(status, msg);
    return pessoaFors;
  };

  const onSearchVeiculo = async (e) => {
    const {
      status,
      message: msg,
      veiculos: veiculo,
    } = await getVeiculoAutoComplete({
      noVeiculo: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculo;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de PneuConserto'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqPneuConserto > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Pneu'
            required
            searchDataSource={onSearchPneu}
            selectedItem={data.pneu}
            onSelectItem={(pneu) => {
              setData({
                ...data,
                pneu,
                nrSeqPneu: pneu.nrSeqPneu,
              });
            }}
            dataSourceTextProperty='cdPneu'
          />
        </div>
        <div className='col mb-3'>
          <Autocomplete
            label='Veiculo'
            required
            searchDataSource={onSearchVeiculo}
            selectedItem={data.veiculo}
            onSelectItem={(veiculo) => {
              setData({
                ...data,
                veiculo,
                nrSeqVeiculo: veiculo.nrSeqVeiculo,
              });
            }}
            dataSourceTextProperty='noVeiculo'
          />
        </div>
        <div className='col'>
          <DatePicker
            label='Data Conserto'
            required
            text={data.dtConserto}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtConserto) => setData({ ...data, dtConserto })}
          />
        </div>
        <div className='col mb-3'>
          <Textbox
            label='Nr. Sulco Entrada'
            required
            text={data.nrSulcoEntrada}
            mask={MaskTypes.Decimal}
            onChangedValue={(nrSulcoEntrada) =>
              setData({ ...data, nrSulcoEntrada })
            }
          />
        </div>
        <div className='col'>
          <DatePicker
            label='Data Vencimento'
            required
            text={data.dtVencimento}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtVencimento) => setData({ ...data, dtVencimento })}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Textbox
            label='Valor Conserto'
            required
            text={data.vlrConserto}
            mask={MaskTypes.Decimal}
            onChangedValue={(vlrConserto) => setData({ ...data, vlrConserto })}
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Fornecedor'
            searchDataSource={onSearchPessoaFor}
            required
            selectedItem={data.pessoaFor}
            onSelectItem={(pessoaFor) => {
              setData({
                ...data,
                pessoaFor,
                noPessoaFor: pessoaFor.noPessoa,
                nrSeqPessoaFor: pessoaFor.nrSeqPessoaFor,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='col mb-3'>
        <Textarea
          maxLength={200}
          label='Obs'
          text={data.obs}
          onChangedValue={(obs) => setData({ ...data, obs })}
        />
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Recapagem'
            checked={data.flgRecapagem}
            onChange={(flgRecapagem) => setData({ ...data, flgRecapagem })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <DatePicker
            required
            label='Data Promessa'
            text={data.dtPromessa}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtPromessa) => setData({ ...data, dtPromessa })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={40}
            required
            label='Nr. Documento'
            text={data.nrDocumento}
            onChangedValue={(nrDocumento) => setData({ ...data, nrDocumento })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <DatePicker
            required
            label='Data Retorno'
            text={data.dtRetorno}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtRetorno) => setData({ ...data, dtRetorno })}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
