import qs from 'qs';
import Pagination from 'core/models/pagination';
import EnergiaTipoTarifa from 'core/models/ENE/energiaTipoTarifa';
import api from '../api';

const baseURL = `/ENE/EnergiaTipoTarifa`;

export const getEnergiaTipoTarifaList = async (filters: any) => {
  const response = await api.get(`/$EnergiaTipoTarifa/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new EnergiaTipoTarifa(json)) ?? [],
  };
};

export const getEnergiaTipoTarifaPagined = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new EnergiaTipoTarifa(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getEnergiaTipoTarifa = async (id: number) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new EnergiaTipoTarifa(value) : {};
};

export const getEnergiaTipoTarifaAutoComplete = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchAutoComplete`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    tipoTarifas: value?.map((json: any) => new EnergiaTipoTarifa(json)) ?? [],
  };
};

export const saveEnergiaTipoTarifa = async (data: EnergiaTipoTarifa) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteEnergiaTipoTarifa = async (id: number) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printEnergiaTipoTarifa = async (filters: any) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelEnergiaTipoTarifa = async (filters: any) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllEnergiaTipoTarifa = async (ids: number[]) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
