import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { BootstrapSizes, MimeTypes, Theme } from 'ui/Helpers/utils';
import { Button, FileUpload, Loader, Modal, Notification } from 'ui/components';

import { getVeiculoOcorrenciaDocumentoPagined } from 'core/services/FRO/veiculoOcorrenciaDocumento';

const ModalDocumentoOcorrencia = forwardRef(
  ({ props, show, onClose }, param) => {
    const [importedFile, setImportedFile] = useState([]);
    const [message, setMessages] = useState();
    const [loading, setLoading] = useState();
    const multipleFiles = true;

    useImperativeHandle(param, () => ({
      param: importedFile,
    }));

    useEffect(() => {
      if (show) {
        setImportedFile([]);
      }
      async function load() {
        setLoading(true);

        await getVeiculoOcorrenciaDocumentoPagined({});

        setLoading(false);
      }
      load();
    }, [show, props]);

    return (
      <Modal
        show={show}
        title='Upload Documentos'
        icon='cloud-upload-alt'
        size={BootstrapSizes.Large}
        onClose={onClose}
      >
        <Modal.Body>
          <Loader loading={loading} />
          {message && (
            <div className='mb-3'>
              <Notification
                message={message.message}
                theme={message.theme}
                onClose={() => setMessages()}
              />
            </div>
          )}
          <div className='row mb-4'>
            <FileUpload
              files={importedFile}
              dropzone={multipleFiles}
              onChange={(files) => setImportedFile(files)}
              allowedMimeTypes={[
                MimeTypes.Types.PDF,
                MimeTypes.Types.Image,
                MimeTypes.Types.Word,
              ]}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button text='OK' theme={Theme.Success} onClick={onClose} />
        </Modal.Footer>
      </Modal>
    );
  }
);
export default ModalDocumentoOcorrencia;
