import React, { useEffect, useRef, useState } from 'react';
import { ResponseStatus, Theme, BootstrapSizes } from 'ui/Helpers/utils';
import { MaskTypes, mask } from 'ui/Helpers/masks';

import {
  CSDSelPage,
  GridView,
  Textbox,
  Autocomplete,
  DropdownMulti,
  DatePicker,
  Panel,
  ToolbarButtons,
  Button,
} from 'ui/components';
import {
  gerarFaturasConvenios,
  faturarComissoes,
  searchUltimasComissoes,
  recalcularFaturaComissoes,
  deleteComissao,
  printComissao,
} from 'core/services/ASS/faturarConvenios';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import { getConvenioAutoComplete } from 'core/services/ASS/convenio';
import { getTipoDespesaList } from 'core/services/FIN/tipoDespesa';
import ModalCancelar from './modalCancelar';
import ModalDadosImpressao from './modalDadosImpressao';

function RenderTextMask(props) {
  const { label, value, masktype } = props;
  return (
    <div className='col-4 mt-3'>
      <div className='fw-bold'>{label}</div>
      {mask(value ?? 0, masktype ?? MaskTypes.Currency)}
    </div>
  );
}

export default function FaturarConvenios({
  transaction,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();
  const dropDownTiposReceita = useRef();

  const [filtros, setFiltros] = useState({});
  const [resumo, setResumo] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filtrosComissao, setFiltrosComissao] = useState(null);
  const [modalShow, setModalShow] = useState({
    cancelar: false,
    dadosImpressao: false,
  });

  const searchComissoes = async (params = null) => {
    setLoading(true);

    const {
      status,
      message: msg,
      titulosPagarComissoes,
      resumoComissoes,
    } = await searchUltimasComissoes(params);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    if (resumoComissoes) setResumo(resumoComissoes);

    if (gridView && gridView.current)
      gridView.current.setDataSource(titulosPagarComissoes);

    setLoading(false);
  };

  useEffect(() => {
    async function load() {
      await searchComissoes();
    }
    load();
  }, []);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noformaPagamento: e,
    });
    onSetMessage(status, msg);
    return formasPagamento;
  };

  const onSearchTituloEspecie = async (e) => {
    const {
      status,
      message: msg,
      data: tituloEspecie,
    } = await getTituloEspecieAutoComplete({ noEspecie: e?.noEspecie });

    onSetMessage(status, msg);

    return tituloEspecie;
  };
  const onSearchConvenio = async (e) => {
    const { status, message: msg, convenios } = await getConvenioAutoComplete({
      noConvenio: e?.noConvenio,
    });
    onSetMessage(status, msg);
    return convenios;
  };

  const onSearchTipoDespesa = async (e) => {
    const {
      status,
      message: msg,
      data: tipoDespesas,
    } = await getTipoDespesaList({
      noTipoDespesa: e.noTipoDespesa,
    });

    onSetMessage(status, msg);

    return tipoDespesas;
  };

  const onClickRemoveComissao = async (e) => {
    const idTituloPagar = e.nrSeqTituloPagar;

    setLoading(true);
    const { status, message: msg } = await deleteComissao(idTituloPagar);

    onSetMessage(status, msg);

    setLoading(false);

    if (status === ResponseStatus.Success) {
      await searchComissoes();
    }
  };

  const onClickPrintComissao = async (e) => {
    const { nrSeqTituloPagar } = e;

    setLoading(true);

    const { status, message: msg, value } = await printComissao(
      nrSeqTituloPagar
    );

    if (value) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(status, msg);
    }

    setLoading(false);
  };

  const columnsFaturaComissao = [
    {
      key: 'nrSeqTituloPagar',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    { key: 'fornecedor.noFornecedor', title: 'Vendedor' },
    { key: 'formaPagamento.noFormaPagamento', title: 'Forma Pagamento' },
    { key: 'tipoDespesa.noTipoDespesa', title: 'Tipo Despesa' },
    { key: 'vlrTitulo', title: 'Valor', format: GridView.DataTypes.Money },

    {
      key: 'nrSeqTituloPagar',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e) => onClickRemoveComissao(e),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },

    {
      key: 'nrSeqTituloPagar',
      type: GridView.ColumnTypes.Button,
      title: 'Imprimir',
      onClick: (e) => onClickPrintComissao(e),
      theme: Theme.Warning,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];

  useEffect(() => {
    if (dropDownTiposReceita && dropDownTiposReceita.current)
      dropDownTiposReceita.current.loadDataSource(onSearchTituloEspecie);
  }, []);

  const onClickGerarFaturas = async () => {
    setLoading(true);

    const { status, message: msg } = await gerarFaturasConvenios(filtros);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onClickFaturarComissao = async () => {
    setLoading(true);

    const { status, message: msg } = await faturarComissoes(filtrosComissao);

    if (status === ResponseStatus.Success) await searchComissoes();

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onClickRecalcularFaturaComissoes = async () => {
    setLoading(true);
    const { status, message: msg } = await recalcularFaturaComissoes(
      filtrosComissao
    );

    if (status === ResponseStatus.Success) {
      await searchComissoes();
    } else {
      onSetMessage(status, msg);
    }

    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Dados Faturamento'
      loading={loading}
      onSearch={() => searchComissoes()}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Cancelar Cobranças'
          onClick={() => setModalShow({ ...modalShow, cancelar: true })}
        />
        <ToolbarButtons.Button
          text='Impressões'
          onClick={() => setModalShow({ ...modalShow, dadosImpressao: true })}
        />
      </ToolbarButtons>
      <div className='row mb-3 mt-2'>
        <div className='col'>
          <Panel>
            <Panel.Header title='Dados Faturamento' theme={Theme.Primary} />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col'>
                  <Textbox
                    maxLength={10}
                    label='Competência'
                    mask={MaskTypes.Competence}
                    text={filtros.competencia}
                    onChangedValue={(competencia) =>
                      setFiltros({ ...filtros, competencia })
                    }
                  />
                </div>
                <div className='col'>
                  <Autocomplete
                    label='Forma Recebimento'
                    searchDataSource={onSearchFormaPagamento}
                    selectedItem={filtros?.formaPagamento}
                    onSelectItem={(formaPagamento) => {
                      setFiltros({
                        ...filtros,
                        formaPagamento,
                        nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                      });
                    }}
                    dataSourceTextProperty='noFormaPagamento'
                  />
                </div>

                <div className='col-2 mb-3'>
                  <DatePicker
                    label='Data Vencimento'
                    text={filtros?.dtVencimento}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dtVencimento) =>
                      setFiltros({ ...filtros, dtVencimento })
                    }
                  />
                </div>

                <div className='col-4'>
                  <DropdownMulti
                    label='Tipo Receita'
                    ref={dropDownTiposReceita}
                    dataSourcePropertyText='noEspecie'
                    dataSourcePropertyValue='nrSeqTituloEspecie'
                    selectedItems={selectedItems.nrSeqsTiposReceita ?? []}
                    onSelectItem={(nrSeqsTiposReceita) => {
                      setSelectedItems({
                        ...selectedItems,
                        nrSeqsTiposReceita,
                      });
                      setFiltros({
                        ...filtros,
                        nrSeqsTiposReceita: nrSeqsTiposReceita?.map(
                          (p) => p.nrSeqTituloEspecie
                        ),
                      });
                    }}
                  />
                </div>
                <div className='col d-flex align-items-center justify-content-center'>
                  <Button
                    onClick={onClickGerarFaturas}
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Gerar Faturas Convênio'
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>

      <div className='row mb-3 mt-3'>
        <div className='col'>
          <Panel>
            <Panel.Header title='Faturar Comissão' theme={Theme.Primary} />
            <Panel.Body>
              <div className='bg-inactive bg-gradient rounded-2 p-3'>
                <div className='row'>
                  <RenderTextMask
                    label='Quantidade Comissões:'
                    value={resumo?.qtdTotalComissoes}
                    masktype={MaskTypes.Integer}
                  />
                  <RenderTextMask
                    label='Valor Total Comissões'
                    value={resumo?.vlrTotalComissoes}
                  />

                  <div className='col-3 mt-2'>
                    <Button
                      onClick={onClickRecalcularFaturaComissoes}
                      outline
                      theme={Theme.Success}
                      template={Button.Templates.Button}
                      text='Recalcular Comissões'
                    />
                  </div>
                </div>
              </div>
              <div className='row mt-4'>
                <div className='col-3 '>
                  <DatePicker
                    label='Data Limite'
                    text={filtrosComissao?.dtLimite}
                    maxLength={MaskTypes.Date}
                    onChange={(dtLimite) =>
                      setFiltrosComissao({ ...filtrosComissao, dtLimite })
                    }
                  />
                </div>
                <div className='col-2'>
                  <DatePicker
                    label='Data Vencimento'
                    text={filtrosComissao?.dtVencimento}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dtVencimento) =>
                      setFiltrosComissao({ ...filtrosComissao, dtVencimento })
                    }
                  />
                </div>

                <div className='col-3'>
                  <Autocomplete
                    label='Forma Pagamento'
                    searchDataSource={onSearchFormaPagamento}
                    selectedItem={filtrosComissao?.formaPagamento}
                    onSelectItem={(formaPagamento) =>
                      setFiltrosComissao({
                        ...filtrosComissao,
                        formaPagamento,
                        nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                      })
                    }
                    dataSourceTextProperty='noFormaPagamento'
                  />
                </div>
                <div className='col-3'>
                  <Autocomplete
                    label='Tipo de Despesa'
                    searchDataSource={onSearchTipoDespesa}
                    selectedItem={filtrosComissao?.tipoDespesa}
                    onSelectItem={(tipoDespesa) =>
                      setFiltrosComissao({
                        ...filtrosComissao,
                        tipoDespesa,
                        nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
                      })
                    }
                    dataSourceTextProperty='noTipoDespesa'
                  />
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col d-flex align-items-center'>
                  <Button
                    onClick={onClickFaturarComissao}
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Faturar Comissões'
                  />
                </div>
              </div>
              <div className='row mt-4'>
                <div className='col mb-3'>
                  <GridView
                    ref={gridView}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsFaturaComissao}
                    transaction={transaction}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>

      <ModalCancelar
        show={modalShow.cancelar}
        tiposReceita={onSearchTituloEspecie}
        onSearchFormaPagamento={onSearchFormaPagamento}
        onClose={() => {
          setModalShow({ ...modalShow, cancelar: false });
        }}
      />

      <ModalDadosImpressao
        show={modalShow.dadosImpressao}
        transaction={transaction}
        onSearchTituloEspecie={onSearchTituloEspecie}
        onSearchConvenio={onSearchConvenio}
        onSearchFormaPagamento={onSearchFormaPagamento}
        onClose={() => {
          setModalShow({ ...modalShow, dadosImpressao: false });
        }}
      />
    </CSDSelPage>
  );
}
