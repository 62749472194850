import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getCentroDeCustoPagined,
  deleteAllCentroDeCusto,
  getCentroDeCustoPaiAutoComplete,
  excelCentroDeCusto,
  printCentroDeCusto,
} from 'core/services/TEL/centroDeCusto';
import {
  CSDSelPage,
  GridView,
  Panel,
  Autocomplete,
  Textbox,
  ToolbarButtons,
} from 'ui/components';

const columns = [
  { key: 'nrSeqCentroDeCusto', type: GridView.ColumnTypes.Checkbox },
  { key: 'noCentroDeCusto', title: 'Descrição' },
  { key: 'noCaminhoCompleto', title: 'Caminho' },
  { key: 'cdCompleto', title: 'Código' },
  { key: 'flgAtivo', title: 'Ativo' },
  { key: 'flgSintetico', title: 'Sintético' },
];

export default function CentroDeCusto({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getCentroDeCustoPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getCentroDeCustoPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'CentroDeCusto/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqCentroDeCusto = sourceRow.find(
      (e) => e.key === 'nrSeqCentroDeCusto'
    );
    onOpenMaintenance(nrSeqCentroDeCusto.value);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllCentroDeCusto(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printCentroDeCusto(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDownloadExcel = async () => {
    setLoading(true);

    const obj = { ...filtros };

    const value = await excelCentroDeCusto(obj);

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `CentroDeCusto.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const onSearchCentroDeCustoPai = async (e) => {
    const { data } = await getCentroDeCustoPaiAutoComplete({
      noCentroDeCustoPai: e,
    });

    return data;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Centro de Custo'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Excel'
          icon={['far', 'file-excel']}
          onClick={() => onDownloadExcel('Padrao')}
        />
      </ToolbarButtons>
      <Panel>
        <Panel.Header
          icon={['fas', 'user']}
          title='Filtros'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col mb-3'>
              <Textbox
                maxLength={20}
                label='Descrição'
                text={filtros.noCentroDeCusto}
                onChangedValue={(data) =>
                  setFiltros({
                    ...filtros,
                    noCentroDeCusto: data,
                  })
                }
              />
            </div>
            <div className='col-2'>
              <Textbox
                maxLength={20}
                label='Código'
                text={filtros.cdCompleto}
                onChangedValue={(data) =>
                  setFiltros({
                    ...filtros,
                    cdCompleto: data,
                  })
                }
              />
            </div>
            <div className='col-4 mb-4'>
              <Autocomplete
                label='Centro de Custo Pai'
                searchDataSource={onSearchCentroDeCustoPai}
                selectedItem={filtros.data}
                onSelectItem={(data) => {
                  setFiltros({
                    ...filtros,
                    data,
                    nrSeqCentroDeCustoPai: data.nrSeqCentroDeCustoPai,
                  });
                }}
                dataSourceTextProperty='noCentroDeCustoPai'
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
