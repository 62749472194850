import qs from 'qs';
import Pagination from 'core/models/pagination';
import { Cidade, Endereco } from 'core/models/SEG';
import api from '../api';

const baseURL = `/SEG/Cidade`;

export const getCep = async (filters) => {
  const response = await api.get(`${baseURL}/GetCep`, {
    params: filters,
  });

  const { value } = response.data ?? {};
  return value ? new Endereco(value) : {};
};

export const getCidadeAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    cidades: value?.map((json) => new Cidade(json)) ?? [],
  };
};

export const getCidadeEstadoConcatAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteCidadeEstadoConcat`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    cidades: value?.map((json) => new Cidade(json)) ?? [],
  };
};

export const getCidadeEstadoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteCidadeEstado`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    cidades: value?.map((json) => new Cidade(json)) ?? [],
  };
};

export const getCidadeList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Cidade(json)) ?? [],
  };
};

export const getCidadePagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Cidade(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getCidade = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Cidade(value) : {};
};

export const saveCidade = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteCidade = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printCidade = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelCidade = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllCidade = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
