import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Textbox, Switch } from 'ui/components';
import Tree from 'ui/components/Treeview/index';
import {
  getPlanoDeConta,
  savePlanoDeConta,
  deletePlanoDeConta,
  getPlanoDeCustoPaiTreeview,
} from 'core/services/FIN/planoDeConta';
import { getParametro } from 'core/services/SEG/parametro';
import { getCentroDeCustoList } from 'core/services/TEL/centroDeCusto';

export default function PlanoDeContaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [treeData, setTree] = useState([]);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const criaHierarquiaTreeView = (input) => {
    input.forEach((item) => {
      item.label = item.noPlanoDeConta;
    });

    const linhas = input.map((column) => ({
      label: column.noPlanoDeConta ?? '',
      key: column.nrSeqPlanoDeConta,
      root: !column.nrSeqPlanodecontapai > 0,
      children: input
        .filter(
          (item) => item.nrSeqPlanodecontapai === column.nrSeqPlanoDeConta
        )
        .map((column2) => ({
          label: column2.noPlanoDeConta,
          key: column2.nrSeqPlanoDeConta,
          root: !column2.nrSeqPlanodecontapai > 0,
          children: input
            .filter(
              (item) => item.nrSeqPlanodecontapai === column2.nrSeqPlanoDeConta
            )
            .map((column3) => ({
              label: column3.noPlanoDeConta,
              key: column3.nrSeqPlanoDeConta,
              root: !column3.nrSeqPlanodecontapai > 0,
              children: input
                .filter(
                  (item) =>
                    item.nrSeqPlanodecontapai === column3.nrSeqPlanoDeConta
                )
                .map((column4) => ({
                  label: column4.noPlanoDeConta,
                  key: column4.nrSeqPlanoDeConta,
                  root: !column4.nrSeqPlanodecontapai > 0,
                  children: input
                    .filter(
                      (item) =>
                        item.nrSeqPlanodecontapai === column4.nrSeqPlanoDeConta
                    )
                    .map((column5) => ({
                      label: column5.noPlanoDeConta,
                      key: column5.nrSeqPlanoDeConta,
                      root: !column5.nrSeqPlanodecontapai > 0,
                      children: input.filter(
                        (item) =>
                          item.nrSeqPlanodecontapai ===
                          column5.nrSeqPlanoDeConta
                      ),
                    })),
                })),
            })),
        })),
    }));

    return linhas;
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      const obj = await getPlanoDeConta(registryKey);

      let flgObrigaCentroDeCusto = true;
      const obrigaCentroDeCusto = await getParametro('OBRIGA_CENTRODECUSTO', 1);
      if (obrigaCentroDeCusto?.noConteudo === 'N')
        flgObrigaCentroDeCusto = false;

      setData({ ...obj, flgObrigaCentroDeCusto });

      const { planos } = await getPlanoDeCustoPaiTreeview();

      const treeViewData = criaHierarquiaTreeView(planos);
      setTree(treeViewData);

      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const validaSave = (obj) => {
    if (obj.noPlanoDeConta === undefined || obj.noPlanoDeConta === null) {
      setLoading(false);
      return {
        valida: false,
        msgValida: 'Preencher descrição do Plano de Conta.',
      };
    }

    if (obj.flgObrigaCentroDeCusto) {
      if (
        obj.nrSeqCentroDeCusto === undefined ||
        obj.nrSeqCentroDeCusto === null
      ) {
        setLoading(false);
        return { valida: false, msgValida: 'Selecionar um Centro de Custo.' };
      }
    }

    if (obj.cdPlanoDeConta === undefined || obj.cdPlanoDeConta === null) {
      setLoading(false);
      return {
        valida: false,
        msgValida: 'Preencher o Código do Plano de Conta.',
      };
    }

    return { valida: true, msgValida: 'Validado com sucesso.' };
  };

  const save = async () => {
    setLoading(true);

    const { valida, msgValida } = validaSave(data);

    let response = {};

    if (valida) response = await savePlanoDeConta(data);
    else return onSetMessage(ResponseStatus.Error, msgValida);

    const { status, message: msg } = response;

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      setData({});
    }

    setLoading(false);

    return setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deletePlanoDeConta(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchCentroDeCusto = async (e) => {
    const { data: centroDeCusto } = await getCentroDeCustoList({
      noCentroDeCusto: e,
    });

    return centroDeCusto;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Plano de Conta'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqPlanoDeConta > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            label='Descrição'
            required
            maxLength={200}
            text={data.noPlanoDeConta}
            onChangedValue={(noPlanoDeConta) =>
              setData({
                ...data,
                noPlanoDeConta,
              })
            }
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Código'
            required
            maxLength={20}
            text={data.cdPlanoDeConta}
            onChangedValue={(cdPlanoDeConta) =>
              setData({ ...data, cdPlanoDeConta })
            }
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Código Resumo'
            readOnly
            maxLength={40}
            text={data.cdResumo}
            onChangedValue={(cdResumo) => setData({ ...data, cdResumo })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-6 mb-4'>
          <Autocomplete
            label='Centro de Custo'
            searchDataSource={onSearchCentroDeCusto}
            selectedItem={data.centroDeCusto}
            onSelectItem={(centroDeCusto) => {
              setData({
                ...data,
                centroDeCusto,
                nrSeqCentroDeCusto: centroDeCusto.nrSeqCentroDeCusto,
              });
            }}
            dataSourceTextProperty='noCentroDeCusto'
          />
        </div>
        <div className='col-1'>
          <Switch
            label='Ativo'
            formControl
            checked={data.flgAtivo}
            onChange={(flgAtivo) => setData({ ...data, flgAtivo })}
          />
        </div>
        <div className='col-1'>
          <Switch
            label='Sintético'
            formControl
            checked={data.flgSintetico}
            onChange={(flgSintetico) => setData({ ...data, flgSintetico })}
          />
        </div>
      </div>

      <div className='row' label='Plano de Conta Pai'>
        <div className='col-12 mb-3'>
          <Tree
            label='Plano de Conta Pai'
            data={treeData}
            selectedKey={data.nrSeqPlanodecontapai}
            onChangedValue={(nrSeqPlanodecontapai) =>
              setData({ ...data, nrSeqPlanodecontapai })
            }
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
