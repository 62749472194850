import qs from 'qs';
import Pagination from 'core/models/pagination';
import ProdutoCodigo from 'core/models/EST/produtoCodigo';
import api from '../api';

const baseURL = `/EST/ProdutoCodigo`;

export const getProdutoCodigoList = async (filters) => {
  const response = await api.get(`/$ProdutoCodigo/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ProdutoCodigo(json)) ?? [],
  };
};

export const getProdutoCodigoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ProdutoCodigo(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getProdutoCodigo = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ProdutoCodigo(value) : {};
};

export const saveProdutoCodigo = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteProdutoCodigo = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printProdutoCodigo = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelProdutoCodigo = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllProdutoCodigo = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getEtiquetas = async (data) => {
  const response = await api.post(`${baseURL}/GenerateEtiquetas`, data);

  return response.data ?? {};
};

export const generateCodigoInterno = async (data) => {
  const response = await api.post(`${baseURL}/GenerateCodigoInterno`, data);

  const { value } = response.data ?? {};
  return value;
};
