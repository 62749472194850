import qs from 'qs';
import Pagination from 'core/models/pagination';
import Veiculo from 'core/models/FRO/veiculo';
import api from '../api';

const baseURL = `/FRO/Veiculo`;

export const getVeiculoVehicleInformation = async (id) => {
  const response = await api.get(`${baseURL}/GetVehicleInformation`, {
    params: { id },
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const getVeiculoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    veiculos: value?.map((json) => new Veiculo(json)) ?? [],
  };
};

export const getVeiculoPorVinculoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompletePorVinculo`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    veiculos: value?.map((json) => new Veiculo(json)) ?? [],
  };
};

export const getVeiculoAutoCompleteResumido = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteResumido`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    veiculos: value?.map((json) => new Veiculo(json)) ?? [],
  };
};

export const getVeiculoList = async (filters) => {
  const response = await api.get(`/${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Veiculo(json)) ?? [],
  };
};

export const getVeiculoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Veiculo(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getVeiculo = async (id, vinculadosSomenteDePrincipal = false) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id, vinculadosSomenteDePrincipal },
  });

  const { value } = response.data ?? {};
  return value ? new Veiculo(value) : {};
};

export const saveVeiculo = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteVeiculo = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printVeiculo = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printVeiculoDetalhado = async (filters) => {
  const response = await api.get(`${baseURL}/PrintDetalhado`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printVeiculoData = async (filters) => {
  const response = await api.get(`${baseURL}/PrintVeiculoData`, {
    params: filters,
  });

  return response.data ?? {};
};

export const printVeiculoHistorico = async (filters) => {
  const response = await api.get(`${baseURL}/PrintVeiculoHistorico`, {
    params: filters,
  });

  return response.data ?? {};
};

export const printVeiculoTransporteDetalhado = async (filters) => {
  const response = await api.get(`${baseURL}/PrintVeiculoTransporteDetalhado`, {
    params: filters,
  });

  return response.data ?? {};
};

export const excelVeiculo = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllVeiculo = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
