import React, { useState } from 'react';

import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { Button, Modal, Notification, Textarea } from 'ui/components';

export default function ModalDetalhesNotaFiscal({
  noXml,
  noRetornoLote,
  noRetornoNfs,
  onClose,
  show,
}) {
  const [message, setMessage] = useState(null);

  return (
    <Modal
      show={show}
      title='Detalhes da Nota Fiscal'
      onClose={onClose}
      size={BootstrapSizes.Large}
      icon={['far', 'comment-alt']}
    >
      <Modal.Body className='p-4 pt-3'>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col-12'>
            <Textarea
              label='Lote enviado para a prefeitura (XML)'
              text={noXml}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-12'>
            <Textarea
              label='Retorno da prefeitura para o lote enviado (XML)'
              text={noRetornoLote}
            />
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-12'>
            <Textarea
              label='Retorno da consulta do lote (XML)'
              text={noRetornoNfs}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Sair'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
