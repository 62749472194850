import React, { useState, useCallback, useEffect } from 'react';

import {
  Autocomplete,
  Button,
  Loader,
  Modal,
  Notification,
  Textbox,
} from 'ui/components';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';
import {
  getFormaPagamentoAutoComplete,
  getTipoDespesaAutoComplete,
} from 'core/services/FIN';
import { MaskTypes } from 'ui/Helpers/masks';
import { getMultipag } from 'core/services/FIN/tituloPagar';
import { UpdateReferenceDriversAndEmployees } from 'core/services/FIN/custoFixo';

export default function ModalGerarCustoFixo(props) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [messageState, setMessage] = useState(null);

  const { show, registryKey } = props;

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchTipoDespesa = async (e) => {
    const {
      status,
      message: msg,
      tipoDespesas,
    } = await getTipoDespesaAutoComplete({ noTipoDespesa: e });
    if (msg) onSetMessage(status, msg);
    return tipoDespesas;
  };
  const load = useCallback(async () => {
    setMessage({
      message:
        'Este modal gerará um custo fixo para todos os motoristas e funcionários ativos no sistema.',
      theme: Theme.Danger,
    });
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  const download = (text) => {
    const element = document.createElement('a');

    const link = `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`;

    element.setAttribute('href', link);

    element.setAttribute('download', 'resumo.txt');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const salvar = async () => {
    setLoading(true);

    const obj = { ...data };

    const {
      message: msg,
      status,
      value,
    } = await UpdateReferenceDriversAndEmployees(obj);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    download(value);
    setLoading(false);
    if (status === ResponseStatus.Success) {
      props.onClose({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
  };

  const cancelar = async () => {
    props.onClose({
      message: null,
      theme: null,
    });
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({ noFormaPagamento: e });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const multipag = async (e) => {
    if (data.formaPagamento?.flgGrupo === 'E') {
      e = data;
      const obj = await getMultipag({
        ...data,
      });
      setData({
        ...data,
        tipoServico: obj.tipoServico,
        nrSeqTipoServicoBancario: obj.nrSeqTipoServicoBancario,
        tipoMovimento: obj.tipoMovimento,
        nrSeqTipoMovimento: obj.nrSeqTipoMovimento,
        formaLancamentoEletronico: obj.formaLancamentoEletronico,
        nrSeqFormaLancamentoEletronico: obj.nrSeqFormaLancamentoEletronico,
        instrucaoMovimento: obj.instrucaoMovimento,
        nrSeqInstrucaoMovimento: obj.nrSeqInstrucaoMovimento,
        formaPagamento: data.formaPagamento,
        nrSeqFormaPagamento: data.formaPagamento.nrSeqFormaPagamento,
        movimentoRemessa: obj.movimentoRemessa,
        nrSeqTipoMovimentoRemessa: obj.nrSeqTipoMovimentoRemessa,
      });

      return obj;
    }
    setData({
      ...e,
      formaPagamento: data.formaPagamento,
      nrSeqFormaPagamento: data.formaPagamento.nrSeqFormaPagamento,
    });
    return true;
  };

  return (
    <Modal
      loading={loading}
      show={show}
      title='Geração de Titulos Custo Fixo'
      size={BootstrapSizes.Large}
      icon='money'
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='col-4 mb-3'>
            <Textbox
              required
              label='Dia Vencimento'
              text={data?.diaVencimento}
              mask={MaskTypes.Integer}
              onChangedValue={(diaVencimento) =>
                setData({ ...data, diaVencimento })
              }
            />
          </div>
          <div className='col-4 mb-3'>
            <Autocomplete
              label='Tipo Despesa'
              searchDataSource={onSearchTipoDespesa}
              required
              selectedItem={data?.tipoDespesa}
              onSelectItem={(tipoDespesa) => {
                setData({
                  ...data,
                  tipoDespesa,
                  nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
                });
              }}
              dataSourceTextProperty='noTipoDespesa'
            />
          </div>
          <div className='col-4 mb-3'>
            <Autocomplete
              required
              label='Forma Pagamento'
              searchDataSource={onSearchFormaPagamento}
              selectedItem={data?.formaPagamento}
              onSelectItem={(formaPagamento) => {
                data.formaPagamento = formaPagamento;
                data.nrSeqFormaPagamento = formaPagamento.nrSeqFormaPagamento;
                multipag(data);
              }}
              dataSourceTextProperty='noFormaPagamento'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-4 mb-3'>
            <Textbox
              label='Valor Titulo'
              text={data?.vlrCusto}
              mask={MaskTypes.DecimalCustom}
              onChangedValue={(vlrCusto) => setData({ ...data, vlrCusto })}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-12 mt-3 text-end'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Gerar'
              onClick={salvar}
            />
            <Button
              style={{ marginLeft: '5px' }}
              theme={Theme.Danger}
              template={Button.Templates.Button}
              text='Cancelar'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
