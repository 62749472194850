import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  JustifyContent,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  DatePicker,
  Textbox,
  Panel,
  ToolbarButtons,
  GridView,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getAcertoMotoristaAdiantamento,
  saveAcertoMotoristaAdiantamento,
  deleteAcertoMotoristaAdiantamento,
  getTipoTituloPagarAdiantamento,
} from 'core/services/FIN/acertoMotoristaAdiantamento';
import { setTransaction } from 'core/services/api';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';
import TituloEspecie from 'core/models/FIN/tituloEspecie';
import {
  GetTituloEspeciePlanoDeContaTituloReceber,
  getRetornoGridPlanoContaReceber,
} from 'core/services/FIN/tituloEspeciePlanoDeConta';
import {
  getRetornoGridPlanoConta,
  getTipoDespesaPlanoDeContaTituloPagar,
} from 'core/services/FIN/tipoDespesa_PlanoDeConta';
import { TipoDespesa } from 'core/models/FIN';

export default function AcertoMotoristaAdiantamentoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const gridView = useRef();
  const gridView2 = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const savePricePagar = async (keyValue, value) => {
    const datasource = gridView.current ? gridView.current.getDataSource() : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTipoDespesa_PlanoDeConta === keyValue
    );
    itemParcela.vlrPlanoConta = value;

    for (let i = 0; i < datasource.length; ) {
      if (
        datasource[i].nrSeqTipoDespesa_PlanoDeConta ===
        itemParcela.nrSeqTipoDespesa_PlanoDeConta
      ) {
        datasource[i].vlrPlanoConta = itemParcela.vlrPlanoConta;
      }
      i += 1;
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);
  };

  const savePriceReceber = async (keyValue, value) => {
    const datasource = gridView2.current
      ? gridView2.current.getDataSource()
      : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTituloEspeciePlanoDeConta === keyValue
    );
    itemParcela.vlrPlanoConta = value;

    for (let i = 0; i < datasource.length; ) {
      if (
        datasource[i].nrSeqTituloEspeciePlanoDeConta ===
        itemParcela.nrSeqTituloEspeciePlanoDeConta
      ) {
        datasource[i].vlrPlanoConta = itemParcela.vlrPlanoConta;
      }
      i += 1;
    }

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(datasource);
  };

  const fPricePagar = function (keyValue, value) {
    savePricePagar(keyValue, value);
  };
  const fPriceReceber = function (keyValue, value) {
    savePriceReceber(keyValue, value);
  };

  const columns = [
    {
      key: 'nrSeqTipoDespesa_PlanoDeConta',
      visible: false,
    },
    {
      key: 'planoDeContaCre.cdCaminhoCompleto',
      title: 'Plano De Contas Credito',
    },
    {
      key: 'vlrPlanoConta',
      title: 'Valor',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      readOnlyDynamic: 'flgPercentual',
      onBlur: fPricePagar,
      maxLength: 10,
    },
    { key: 'flgPercentual', visible: false },
  ];

  const columns2 = [
    {
      key: 'nrSeqTituloEspeciePlanoDeConta',
      visible: false,
    },
    {
      key: 'planoDeContaCre.cdCaminhoCompleto',
      title: 'Plano De Contas Credito',
    },
    {
      key: 'vlrPlanoConta',
      title: 'Valor',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      readOnlyDynamic: 'flgPercentual',
      onBlur: fPriceReceber,
      maxLength: 10,
    },
    { key: 'flgPercentual', visible: false },
  ];
  const RecalculaTituloReceber = async (nrSeqTituloEspecie) => {
    if (data.tituloEspecie === undefined) {
      data.tituloEspecie = new TituloEspecie();
    }

    if (nrSeqTituloEspecie > 0) {
      const {
        data: planoconta,
      } = await GetTituloEspeciePlanoDeContaTituloReceber({
        nrSeqTituloEspecie,
      });
      for (let i = 0; i < planoconta.length; ) {
        planoconta[i].vlrTitulo = data.vlrAdiantamento;
        i += 1;
      }
      if (planoconta.length > 0) {
        if (data.tituloEspecie.planoDeContas.length === 0) {
          data.tituloEspecie.planoDeContas.push(...planoconta);
        } else {
          for (let i = 0; i < data.tituloEspecie.planoDeContas.length; ) {
            data.tituloEspecie.planoDeContas[i] = planoconta.vlrPlanoConta;
            i += 1;
          }
        }
        const obj = await getRetornoGridPlanoContaReceber(planoconta);
        if (gridView2 && gridView2.current)
          gridView2.current.setDataSource(obj);
      } else if (gridView2 && gridView2.current)
        gridView2.current.setDataSource(null);
    } else if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(null);
  };

  const RecalculaTituloPagar = async (nrSeqTipoDespesa) => {
    if (data.tipoDespesa === undefined) {
      data.tipoDespesa = new TipoDespesa();
    }

    if (nrSeqTipoDespesa > 0) {
      const { data: planoconta } = await getTipoDespesaPlanoDeContaTituloPagar({
        nrSeqTipoDespesa,
      });
      for (let i = 0; i < planoconta.length; ) {
        planoconta[i].vlrTitulo = data.vlrAdiantamento;
        i += 1;
      }
      if (planoconta.length > 0) {
        if (data.tipoDespesa.planoDeContas.length === 0) {
          data.tipoDespesa.planoDeContas.push(...planoconta);
        } else {
          for (let i = 0; i < data.tipoDespesa.planoDeContas.length; ) {
            data.tipoDespesa.planoDeContas[i] = planoconta.vlrPlanoConta;
            i += 1;
          }
        }
        const obj = await getRetornoGridPlanoConta(planoconta);
        if (gridView && gridView.current) gridView.current.setDataSource(obj);
      } else if (gridView && gridView.current)
        gridView.current.setDataSource(null);
    } else if (gridView && gridView.current)
      gridView.current.setDataSource(null);
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      setData(await getAcertoMotoristaAdiantamento(registryKey));
      setLoading(false);
    } else {
      const obj = await getTipoTituloPagarAdiantamento(1);
      if (obj.nrSeqTipoDespesa > 0) {
        RecalculaTituloPagar(obj.nrSeqTipoDespesa);
      }
      if (obj.nrSeqTituloEspecie > 0) {
        RecalculaTituloReceber(obj.nrSeqTituloEspecie);
      }
      if (obj.nrSeqTituloEspecie > 0 && obj.nrSeqTipoDespesa > 0) {
        setData({
          nrSeqTituloEspecie: obj.nrSeqTituloEspecie,
          nrSeqTipoDespesa: obj.nrSeqTipoDespesa,
          vlrAdiantamento: 0,
        });
      } else if (obj.nrSeqTituloEspecie > 0) {
        setData({
          nrSeqTituloEspecie: obj.nrSeqTituloEspecie,
          vlrAdiantamento: 0,
        });
      } else if (obj.nrSeqTipoDespesa > 0) {
        setData({
          nrSeqTipoDespesa: obj.nrSeqTipoDespesa,
          vlrAdiantamento: 0,
        });
      } else {
        setData({ vlrAdiantamento: 0 });
      }
      /* if (gridView && gridView.current)
        gridView.current.setDataSource(obj.tituloPagarPlanoConta);
      if (gridView2 && gridView2.current)
        gridView2.current.setDataSource(obj.tituloReceberPlanoConta); */
    }
    if (transaction.setValues !== null && transaction.setValues !== undefined) {
      setData({
        ...data,
        nrSeqPessoaMot: transaction.setValues.nrSeqPessoaMot,
        pessoaMot: transaction.setValues.pessoaMot,
      });
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const Recalcula = async () => {
    if (data.nrSeqTipoDespesa > 0) {
      RecalculaTituloPagar(data.nrSeqTipoDespesa);
    }
    if (data.nrSeqTituloEspecie > 0) {
      RecalculaTituloReceber(data.nrSeqTituloEspecie);
    }
  };

  const save = async () => {
    setLoading(true);
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);

    const despesa = gridView.current ? gridView.current.getDataSource() : [];

    const receber = gridView2.current ? gridView2.current.getDataSource() : [];

    const obj = {
      ...data,
      nrSeqTransacao: transaction.nrSeqTransacao,
      tituloPagarPlanoConta: despesa,
      tituloReceberPlanoConta: receber,
    };

    if (obj.tituloPagarPlanoConta !== null) {
      for (let i = 0; i < obj.tituloPagarPlanoConta.length; ) {
        obj.tituloPagarPlanoConta[i].vlrTitulo =
          obj.tituloPagarPlanoConta[i].vlrPlanoConta;
        i += 1;
      }
    }
    if (obj.tituloReceberPlanoConta !== null) {
      for (let i = 0; i < obj.tituloReceberPlanoConta.length; ) {
        obj.tituloReceberPlanoConta[i].vlrTitulo =
          obj.tituloReceberPlanoConta[i].vlrPlanoConta;
        i += 1;
      }
    }

    const { status, message: msg } = await saveAcertoMotoristaAdiantamento(obj);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    if (status === ResponseStatus.Success) {
      if (gridView && gridView.current) gridView.current.setDataSource(null);
      if (gridView2 && gridView2.current) gridView2.current.setDataSource(null);
    }

    setData(status === ResponseStatus.Success ? { vlrAdiantamento: 0 } : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteAcertoMotoristaAdiantamento(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitRec: true,
      flgTitPag: true,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const onSearchPessoaMot = async (e) => {
    const {
      status,
      message: msg,
      motoristas,
    } = await getMotoristaAutoComplete({ noPessoa: e });
    if (msg) onSetMessage(status, msg);
    return motoristas;
  };

  /* const onSearchFreteTerceiro = async (e) => {
    const {
      status,
      message: msg,
      freteTerceiros,
    } = await getFreteTerceiroAutoComplete({ noFreteTerceiro: e });
    if (msg) onSetMessage(status, msg);
    return freteTerceiros;
  }; */

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Adiantamento Motorista '
      transaction={transaction}
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({ vlrAdiantamento: 0 })}
      onDelete={data?.nrSeqAcertoMotoristaAdiantamento > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <ToolbarButtons>
        {data.nrSeqAcertoMotoristaAdiantamento == null && (
          <ToolbarButtons.Button
            text='Salvar'
            icon={['far', 'save']}
            onClick={save}
          />
        )}
      </ToolbarButtons>
      <div className='row'>
        <div className='col-8 mb-3'>
          <Autocomplete
            label='Motorista'
            required
            searchDataSource={onSearchPessoaMot}
            selectedItem={data.pessoaMot}
            onSelectItem={(pessoaMot) => {
              setData({
                ...data,
                pessoaMot,
                nrSeqPessoaMot: pessoaMot.nrSeqPessoaMot,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-2 mb-3'>
          <DatePicker
            label='Data'
            required
            text={data.dtAdiantamento}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtAdiantamento) => setData({ ...data, dtAdiantamento })}
          />
        </div>
        <div className='col-3 mb-3'>
          <Textbox
            label='Valor'
            required
            text={data.vlrAdiantamento}
            textRight
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(vlrAdiantamento) =>
              setData({ ...data, vlrAdiantamento })
            }
            onBlur={Recalcula}
          />
        </div>
        <div className='col-3 mb-3'>
          <Autocomplete
            label='Forma Pagamento'
            required
            searchDataSource={onSearchFormaPagamento}
            selectedItem={data.formaPagamento}
            onSelectItem={(formaPagamento) => {
              setData({
                ...data,
                formaPagamento,
                nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
              });
            }}
            dataSourceTextProperty='noFormaPagamento'
          />
        </div>
      </div>
      {data.formaPagamento?.flgGrupo === 'C' && (
        <div className='col-8'>
          <Panel>
            <Panel.Header
              title='Dados Cheque'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                {data.formaPagamento?.flgGrupo === 'C' && (
                  <div className='col-6 mb-3'>
                    <DatePicker
                      label='Data Cheque'
                      required
                      text={data.dtCheque}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtCheque) => setData({ ...data, dtCheque })}
                    />
                  </div>
                )}

                {data.formaPagamento?.flgGrupo === 'C' &&
                  !data.formaPagamento?.flgControlaCheque && (
                    <div className='col-6 mb-3'>
                      <Textbox
                        label='Nr. Cheque'
                        required
                        text={data.nrCheque}
                        onChangedValue={(nrCheque) =>
                          setData({ ...data, nrCheque })
                        }
                      />
                    </div>
                  )}
              </div>
            </Panel.Body>
          </Panel>
        </div>
      )}

      <div className='row'>
        <div className='col-8 mb-3'>
          <Textbox
            maxLength={600}
            label='Observação'
            text={data.obsAdiantamento}
            onChangedValue={(obsAdiantamento) =>
              setData({ ...data, obsAdiantamento })
            }
          />
        </div>
      </div>
      {(data.nrSeqAcertoMotoristaAdiantamento === null ||
        data.nrSeqAcertoMotoristaAdiantamento === undefined) && (
        <div className='row'>
          <div className='col-12'>
            <Panel>
              <Panel.Header
                title='Rateio do plano de Contas Titulo Pagar'
                theme={Theme.Light}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-9'>
                    <GridView
                      ref={gridView}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columns}
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      )}
      {(data.nrSeqAcertoMotoristaAdiantamento === null ||
        data.nrSeqAcertoMotoristaAdiantamento === undefined) && (
        <div className='row'>
          <div className='col-12'>
            <Panel>
              <Panel.Header
                title='Rateio do plano de Contas Titulo Receber'
                theme={Theme.Light}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-9'>
                    <GridView
                      ref={gridView2}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columns2}
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      )}
    </CSDManutPage>
  );
}

/* <div className='row'>
<div className='col mb-3'>
  <Autocomplete
    label='FreteTerceiro'
    searchDataSource={onSearchFreteTerceiro}
    selectedItem={data.freteTerceiro}
    onSelectItem={(freteTerceiro) => {
      setData({
        ...data,
        freteTerceiro,
        nrSeqFreteTerceiro: freteTerceiro.nrSeqFreteTerceiro,
      });
    }}
    dataSourceTextProperty='noFreteTerceiro'
  />
</div>
</div> */
