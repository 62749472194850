import React, { useState, useEffect } from 'react';

import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';

import {
  Button,
  Loader,
  Modal,
  FontAwesomeIcon,
  RadioButton,
  Notification,
} from 'ui/components';

import {
  ativaBloquearDados,
  ativaBloquearVoz,
  ativaBloquearSMS,
} from 'core/services/TEL/';

const statusAtivarBloquearDados = [
  { text: 'Ativar', value: 'Ativado' },
  { text: 'Bloquear', value: 'Bloqueado' },
];
const statusAtivarBloquearVoz = [
  { text: 'Ativar', value: 'Ativado' },
  { text: 'Bloquear', value: 'Bloqueado' },
];
const statusAtivarBloquearSMS = [
  { text: 'Ativar', value: 'Ativado' },
  { text: 'Bloquear', value: 'Bloqueado' },
];

const ModalAtivarBloquearLinha = (props) => {
  const [loading, setLoading] = useState(true);
  const {
    ativarBloquearLinha: { linha, linhaFormatada },
  } = props;
  const [message, setMessage] = useState(null);

  const {
    ativarBloquearLinha: { bloqueadoDados, bloqueadoVoz, bloqueadoSMS },
  } = props;

  const [flgAtivaBloqueia, setFlgAtivaBloqueia] = useState({
    flgAtivaBloqueiaDados: bloqueadoDados,
    flgAtivaBloqueiaVoz: bloqueadoVoz,
    flgAtivaBloqueiaSMS: bloqueadoSMS,
    flgEnviado: false,
    linha: 0,
  });
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    else setMessage(null);
  };

  const AtivarBloquearDados = async () => {
    const dados = {
      Linha: linha,
      Acao: flgAtivaBloqueia.flgAtivaBloqueiaDados,
    };
    return ativaBloquearDados(dados);
  };

  const AtivarBloquearSMS = async () => {
    const sms = {
      Linha: linha,
      Acao: flgAtivaBloqueia.flgAtivaBloqueiaSMS,
    };
    return ativaBloquearSMS(sms);
  };

  const AtivarBloquearVoz = async () => {
    const voz = {
      Linha: linha,
      Acao: flgAtivaBloqueia.flgAtivaBloqueiaVoz,
    };
    return ativaBloquearVoz(voz);
  };

  const onCancelar = async () => {
    setFlgAtivaBloqueia({ ...flgAtivaBloqueia, flgEnviado: false });
    props.onClose();
  };

  const onAtivarBloquear = async () => {
    setLoading(true);
    let retornoDados = 0;
    let retornoVoz = 0;
    let retornoSMS = 0;
    if (
      bloqueadoDados !== flgAtivaBloqueia.flgAtivaBloqueiaDados &&
      flgAtivaBloqueia.flgAtivaBloqueiaDados !== ''
    )
      retornoDados = await AtivarBloquearDados();
    if (
      bloqueadoVoz !== flgAtivaBloqueia.flgAtivaBloqueiaVoz &&
      flgAtivaBloqueia.flgAtivaBloqueiaVoz !== ''
    )
      retornoVoz = await AtivarBloquearVoz();
    if (
      bloqueadoSMS !== flgAtivaBloqueia.flgAtivaBloqueiaSMS &&
      flgAtivaBloqueia.flgAtivaBloqueiaSMS !== ''
    )
      retornoSMS = await AtivarBloquearSMS();

    if (
      retornoDados.status > 0 ||
      retornoVoz.status > 0 ||
      retornoSMS.status > 0
    ) {
      onSetMessage(ResponseStatus.Error, 'Falha ao Ativar / Bloquear Linha');
    }
    onCancelar();
    setLoading(false);
  };

  const { show, onClose } = props;
  useEffect(() => {
    if (linha) {
      setLoading(false);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      size={BootstrapSizes.Medium}
      title='Ativar / Bloquear Serviços'
      onClose={onClose}
    >
      <Modal.Body className='bg-light'>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.messageTheme}
              onClose={() => setMessage(undefined)}
            />
          </div>
        )}
        <div className='row mb-1'>
          <div className='row mb-3'>
            <div className='col-auto d-flex align-items-center'>
              <div className='widget-square bg-gradient-primary text-white p-3 rounded-2'>
                <FontAwesomeIcon className='fs-1' icon='phone' />
              </div>
            </div>
            <div className='col d-flex align-items-center'>
              <div className='fw-bold text-primary'>
                <div>Linha:</div>
                <span className='display-7 fw-bold'>{linhaFormatada}</span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-auto'>
              <RadioButton
                outline
                label='Dados'
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                buttons={statusAtivarBloquearDados}
                selectedButton={
                  flgAtivaBloqueia.flgAtivaBloqueiaDados ?? bloqueadoDados
                }
                onChange={(flgAtivaBloqueiaDados) =>
                  setFlgAtivaBloqueia({
                    ...flgAtivaBloqueia,
                    flgAtivaBloqueiaDados,
                  })
                }
              />
            </div>

            <div className='col-auto'>
              <RadioButton
                outline
                label='Voz'
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                buttons={statusAtivarBloquearVoz}
                selectedButton={
                  flgAtivaBloqueia.flgAtivaBloqueiaVoz ?? bloqueadoVoz
                }
                onChange={(flgAtivaBloqueiaVoz) =>
                  setFlgAtivaBloqueia({
                    ...flgAtivaBloqueia,
                    flgAtivaBloqueiaVoz,
                  })
                }
              />
            </div>
            <div className='col-auto'>
              <RadioButton
                outline
                label='SMS'
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                buttons={statusAtivarBloquearSMS}
                selectedButton={
                  flgAtivaBloqueia.flgAtivaBloqueiaSMS ?? bloqueadoSMS
                }
                onChange={(flgAtivaBloqueiaSMS) =>
                  setFlgAtivaBloqueia({
                    ...flgAtivaBloqueia,
                    flgAtivaBloqueiaSMS,
                  })
                }
              />
            </div>
          </div>
        </div>
        <Loader loading={loading} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          icon='share'
          text='Enviar'
          theme={Theme.Success}
          onClick={onAtivarBloquear}
        />
        <Button
          icon='ban'
          text='Cancelar'
          theme={Theme.Danger}
          onClick={onCancelar}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAtivarBloquearLinha;
