export default class GrupoIPI {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoIPI = jsonObject.nrSeqGrupoIPI;
    this.aliquotaIPI = jsonObject.aliquotaIPI;
    this.cdGrupoIPI = jsonObject.cdGrupoIPI;
    this.baseCalculoIPI = jsonObject.baseCalculoIPI;
    this.noGrupoIPI = jsonObject.noGrupoIPI;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqCSTIPI = jsonObject.nrSeqCSTIPI;
  }

  toJson = () => JSON.stringify(this);
}
