import TituloEspecie from '../FIN/tituloEspecie';
import Cfo from '../FIS/cfo';
import ProdutoServico from '../VEN/produtoServico';
import TipoServicoCidade from '../VEN/tipoServicoCidade';

export default class TipoServico {
  constructor(jsonObject = {}) {
    this.nrSeqTipoServico = jsonObject.nrSeqTipoServico;
    this.noTipoServico = jsonObject.noTipoServico;
    this.cdTipoServico = jsonObject.cdTipoServico;
    this.flgAtivo = jsonObject.flgAtivo;
    this.flgGeraNfs = jsonObject.flgGeraNfs;
    this.flgGeraTitulo = jsonObject.flgGeraTitulo;
    this.flgTransporte = jsonObject.flgTransporte;
    this.serieNFS = jsonObject.serieNFS;
    this.nrSeqTituloEspecie = jsonObject.nrSeqTituloEspecie;
    this.nrSeqCFOEst = jsonObject.nrSeqCFOEst;
    this.nrSeqCFOPais = jsonObject.nrSeqCFOPais;
    this.nrSeqCFOExt = jsonObject.nrSeqCFOExt;
    this.nrSeqProdutoServico = jsonObject.nrSeqProdutoServico;
    this.flgBuscaQtdeRemoto = jsonObject.flgBuscaQtdeRemoto;
    this.aliqIssqn = jsonObject.aliqIssqn;
    this.aliqPis = jsonObject.aliqPis;
    this.aliqCofins = jsonObject.aliqCofins;
    this.aliqCsll = jsonObject.aliqCsll;
    this.aliqIR = jsonObject.aliqIR;
    this.aliqInss = jsonObject.aliqInss;
    this.flgIssqnRetido = jsonObject.flgIssqnRetido;
    this.flgPisRetido = jsonObject.flgPisRetido;
    this.flgCofinsRetido = jsonObject.flgCofinsRetido;
    this.flgCsllRetido = jsonObject.flgCsllRetido;
    this.flgIssqnCidadeOrigem = jsonObject.flgIssqnCidadeOrigem;
    this.flgIssqnRetCidadeTomador = jsonObject.flgIssqnRetCidadeTomador;
    this.tituloEspecie = new TituloEspecie(jsonObject.tituloEspecie ?? {});
    this.cfoEst = new Cfo(jsonObject.cfoEst ?? {});
    this.cfoExt = new Cfo(jsonObject.cfoExt ?? {});
    this.cfoPais = new Cfo(jsonObject.cfoPais ?? {});
    this.produtoServico = new ProdutoServico(jsonObject.produtoServico ?? {});
    this.tipoServicoCidades = (jsonObject.tipoServicoCidades ?? []).map(
      (vmex) => new TipoServicoCidade(vmex)
    );
    this.nrTipoISSQN = jsonObject.nrTipoISSQN;
  }

  toJson = () => JSON.stringify(this);
}
