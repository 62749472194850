export default class ContratoPendenteFaturamento {
  constructor(jsonObject = {}) {
    this.flgFaturaConsumidorfinal = jsonObject.flgFaturaConsumidorfinal;
    this.nrSeqContratoServico = jsonObject.nrSeqContratoServico;
    this.nrSeqContratoAgrupado = jsonObject.nrSeqContratoAgrupado;
    this.noContratoAgrupado = jsonObject.noContratoAgrupado;
    this.nrSeqCentroDeCusto = jsonObject.nrSeqCentroDeCusto;
    this.centroCustoCompleto = jsonObject.centroCustoCompleto;
    this.noPessoa = jsonObject.noPessoa;
    this.nrSeqPessoaVen = jsonObject.nrSeqPessoaVen;
    this.codNoCentroCusto = jsonObject.codNoCentroCusto;
    this.nrSeqCentroCustoPai = jsonObject.nrSeqCentroCustoPai;
    this.codNoCentroCustoPai = jsonObject.codNoCentroCustoPai;
    this.vlrTotal = jsonObject.vlrTotal;
    this.vlrTotalRecalculo = jsonObject.vlrTotalRecalculo;
    this.vlrFaturado = jsonObject.vlrFaturado;
    this.vlrPendente = jsonObject.vlrPendente;
    this.vlrTotalRecebido = jsonObject.vlrTotalRecebido;
    this.vlrTotalEmAberto = jsonObject.vlrTotalEmAberto;
    this.totalDias = jsonObject.totalDias;
    this.totalRows = jsonObject.totalRows;
    this.vlrComissao = jsonObject.vlrComissao;
    this.qtdeLinhas = jsonObject.qtdeLinhas;
  }

  get bgVlrTotalEmAberto() {
    return this.vlrTotalEmAberto > 0 ? 'table-danger' : '';
  }

  toJson = () => JSON.stringify(this);
}
