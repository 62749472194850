import React from 'react';
import {
  CSDSelPage,
  DatePicker,
  Panel,
  RadioButton,
  GridView,
  CSDBarGraphic,
} from 'ui/components';
import {
  Theme,
  JustifyContent,
  BootstrapSizes,
  ResponseStatus,
  dateNow,
} from 'ui/Helpers/utils';
import { getDemonstrativoFinanceiro } from 'core/services/FIN/demonstrativoFinanceiro';

export default function Dre({ transaction, isActive }) {
  const graphicDRE = React.useRef();
  const demonstrativoGrid = React.useRef();

  const optionsBarGraphic = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const { formattedValue } = tooltipItem;

            return `Valor: ${formattedValue}`;
          },
        },
        legend: {
          position: 'bottom',
        },
      },
    },
  };

  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [dataColumns, setDataColumns] = React.useState([]);
  const [filters, setFilters] = React.useState({
    dtInicio: dateNow(),
    dtFim: dateNow(),
    flgIncluirQuitacao: 'N',
    flgIncluirAcerto: 'N',
    flgIncluirTodasContas: 'S',
  });
  const [resetGrid, setResetGrid] = React.useState(true);

  const columnsPadrao = [
    {
      key: 'cdCaminhoCompleto',
      title: 'Código Plano de Conta',
      sortable: false,
      bgClassProperty: 'noColorText',
    },
    {
      key: 'noPlanoDeConta',
      title: 'Plano de Conta',
      sortable: false,
      bgClassProperty: 'noColorText',
    },
    {
      key: 'noCaminhoCompleto',
      title: 'Caminho Completo',
      sortable: false,
      bgClassProperty: 'noColorText',
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const validateDates = () => {
    let isValid = true;
    let validationMsg = '';

    if (!filters.dtInicio) {
      isValid = false;
      validationMsg += 'Data de início não informada.';
    }

    if (!filters.dtFim) {
      isValid = false;
      validationMsg += 'Data fim não informada.';
    }

    return { isValid, validationMsg };
  };

  const formatFilters = (input) => {
    const obj = {
      ...input,
      flgIncluirQuitacao: filters.flgIncluirQuitacao === 'S',
      flgIncluirAcerto: filters.flgIncluirAcerto === 'S',
      flgIncluirTodasContas: filters.flgIncluirTodasContas === 'S',
    };

    return obj;
  };

  const formatGraphicData = (input) => {
    const labels = [];
    input.valoresPeriodo.forEach((item) => labels.push(item.periodo));

    const graphicData = {
      labels,
      datasets: [
        {
          label: 'Despesas',
          data: input.valoresPeriodo.map((item) => item.vlrTotalDespesas),
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        },
        {
          label: 'Receita',
          data: input.valoresPeriodo.map((item) => item.vlrTotalReceitas),
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          borderColor: 'rgba(53, 162, 235, 1)',
          borderWidth: 1,
        },
      ],
    };

    return graphicData;
  };

  const createDreColumns = (mesAnoRefs) => {
    const dynamicColumns = [...columnsPadrao];

    if (mesAnoRefs?.valoresPeriodo.length > 0) {
      const periodos = mesAnoRefs.valoresPeriodo;
      periodos.forEach((item, index) => {
        const count = index + 1;

        dynamicColumns.push({
          key: count.toString(),
          title: item.periodo,
          format: GridView.DataTypes.Money,
          sortable: false,
          bgClassProperty: 'noColorText',
        });
      });
    }

    dynamicColumns.push({
      key: 'vlrSaldo',
      title: 'Total',
      format: GridView.DataTypes.Money,
      sortable: false,
      bgClassProperty: 'noColorText',
    });

    return dynamicColumns;
  };

  const formatGridData = (dreData) => {
    const dataFormat = [];

    if (dreData?.length > 0) {
      for (let i = 0; i < dreData.length; i += 1) {
        const valores = {};

        for (let j = 0; j < dreData[i].receitas.length; j += 1) {
          valores[j + 1] =
            dreData[i].receitas[j].vlrTotal - dreData[i].despesas[j].vlrTotal;
        }

        dataFormat.push({
          ...dreData[i],
          ...valores,
        });
      }
    }

    return dataFormat;
  };

  const search = async (params = null) => {
    setLoading(true);

    const validate = validateDates();

    if (validate.isValid) {
      const res = await getDemonstrativoFinanceiro(formatFilters(params));

      if (res.status === ResponseStatus.Error) {
        setLoading(false);

        return onSetMessage(res.status, res.message);
      }

      const columns = createDreColumns(res.value);
      setDataColumns([...columns]);

      const dataFormatada = formatGridData(res.value.demonstrativoFinanceiro);
      setData(dataFormatada);

      const dataGraphicFormatada = formatGraphicData(res.value);
      graphicDRE?.current?.setDataSource(dataGraphicFormatada);
    } else {
      onSetMessage(ResponseStatus.Error, validate.validationMsg);
    }

    setResetGrid(false);
    return setLoading(false);
  };

  React.useEffect(() => {
    if (!resetGrid) {
      setResetGrid(true);
    } else {
      const dataOrdered = data.sort((a, b) => {
        if (a.cdCaminhoCompleto < b.cdCaminhoCompleto) {
          return -1;
        }
        if (a.cdCaminhoCompleto > b.cdCaminhoCompleto) {
          return 1;
        }
        return 0;
      });

      demonstrativoGrid?.current?.setDataSource(dataOrdered);
    }
  }, [resetGrid]);

  const btnQuitacao = [
    {
      text: 'Incluir',
      value: 'S',
    },
    {
      text: 'Não Incluir',
      value: 'N',
    },
  ];

  const btnAcerto = [
    {
      text: 'Incluir',
      value: 'S',
    },
    {
      text: 'Não Incluir',
      value: 'N',
    },
  ];

  const btnTodasContas = [
    {
      text: 'Incluir',
      value: 'S',
    },
    {
      text: 'Não Incluir',
      value: 'N',
    },
  ];

  return (
    <CSDSelPage
      isActive={isActive}
      title='Demonstrativo Financeiro'
      loading={loading}
      onSearch={() => search(filters)}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <Panel>
          <Panel.Header
            title='Filtros'
            icon='search'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row'>
              <div className='col-2'>
                <DatePicker
                  label='Data Início Competência'
                  text={filters?.dtInicio}
                  onChange={(dtInicio) => setFilters({ ...filters, dtInicio })}
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Data Fim Competência'
                  text={filters?.dtFim}
                  onChange={(dtFim) => setFilters({ ...filters, dtFim })}
                />
              </div>
              <div className='col-2 d-flex flex-column align-items-left'>
                <RadioButton
                  label='Quitação'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filters?.flgIncluirQuitacao}
                  buttons={btnQuitacao}
                  onChange={(flgIncluirQuitacao) =>
                    setFilters({ ...filters, flgIncluirQuitacao })
                  }
                />
              </div>
              <div className='col-2 d-flex flex-column align-items-left'>
                <RadioButton
                  label='Acerto'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filters?.flgIncluirAcerto}
                  buttons={btnAcerto}
                  onChange={(flgIncluirAcerto) =>
                    setFilters({ ...filters, flgIncluirAcerto })
                  }
                />
              </div>
              <div className='col-2 d-flex flex-column align-items-left'>
                <RadioButton
                  label='Tods as contas'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filters?.flgIncluirTodasContas}
                  buttons={btnTodasContas}
                  onChange={(flgIncluirTodasContas) =>
                    setFilters({ ...filters, flgIncluirTodasContas })
                  }
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>

      <div className='row mb-5'>
        <CSDBarGraphic ref={graphicDRE} options={optionsBarGraphic} />
      </div>

      {resetGrid && (
        <div className='row mb-3'>
          <Panel>
            <Panel.Header
              title='Demonstrativo Financeiro'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <GridView
                  className='table-striped table-hover table-bordered table-sm'
                  ref={demonstrativoGrid}
                  dataColumns={dataColumns}
                  offlineData
                  // showSelectSizes={false}
                  // showPagination={false}
                />
              </div>
            </Panel.Body>
          </Panel>
        </div>
      )}
    </CSDSelPage>
  );
}
