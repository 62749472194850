import { saveChipVirgem } from 'core/services/TEL';
import React, { useState } from 'react';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { Button, Loader, Modal, Notification, Textbox } from 'ui/components';

export default function ModalRegistrarChipNovo({
  show,
  onClose,
  data,
  setData,
}) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chipData, setChipData] = useState({});

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSaveChipNovo = async () => {
    setLoading(true);

    const { status, message: msg, value: chipNovo } = await saveChipVirgem({
      ...chipData,
      nrSeqOperadora: data.chip.nrSeqOperadora,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    if (status === ResponseStatus.Success) {
      setData({ ...data, chipNovo, nrSeqChipNovo: chipNovo.nrSeqChip });
      setChipData({});
      onClose();
    } else {
      onSetMessage(status, msg);
    }

    setLoading(false);
  };

  return (
    <Modal
      show={show}
      title='Cadastro Rápido de Chip Virgem'
      onClose={() => {
        setChipData({});
        onClose();
      }}
      size={BootstrapSizes.ExtraLarge}
      icon={['far', 'comment-alt']}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='col'>
            <Textbox
              label='Número ICCID'
              maxLength={20}
              text={chipData.nrIccid}
              onChangedValue={(nrIccid) => {
                setChipData({ ...chipData, nrIccid });
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Salvar Chip Novo'
          theme={Theme.Success}
          onClick={onSaveChipNovo}
        />

        <Button
          text='Fechar'
          theme={Theme.Danger}
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
