import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  CSDManutPage,
  DatePicker,
  Textbox,
  Switch,
  Autocomplete,
  Button,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getExtratoTransferencia,
  saveExtratoTransferencia,
  CreditarDestino,
  DebitarOrigem,
} from 'core/services/FIN/extratoTransferencia';
import { getContaCorrenteAutoComplete } from 'core/services/FIN';
import { getTipoExtratoTransferenciaList } from 'core/services/FIN/tipoExtratoTransferencia';
import { getContaCorrenteTransferenciaList } from 'core/services/FIN/contaCorrenteTransferencia';

export default function ExtratoTransferenciaItem({
  transaction,
  registryKey,
  reload,
  onSelectPage,
  isActive,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({ vlrMovimento: 0 });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const obj = await getExtratoTransferencia(registryKey);

      setData({
        ...obj,
        transferencia: obj.transferencia,
        nrSeqContaCorrenteTransferenci: obj.nrSeqContaCorrenteTransferenci,
        nrSeqContaCorrenteOri: obj.transferencia.nrSeqContaCorrenteOri,
        contaCorrenteOri: obj.transferencia.contaCorrenteOri,
        nrSeqContaCorrenteDest: obj.transferencia.nrSeqContaCorrenteDest,
        contaCorrenteDest: obj.transferencia.contaCorrenteDest,
        nrSeqExtratoDest: obj.nrSeqExtratoDest,
        nrSeqExtratoOri: obj.nrSeqExtratoOri,
      });

      setLoading(false);
    } else setData({ vlrMovimento: 0 });
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveExtratoTransferencia(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? { vlrMovimento: 0 } : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onSearchContaCorrente = async (e) => {
    const {
      status,
      message: msg,
      data: contas,
    } = await getContaCorrenteAutoComplete({
      noContaCorrente: e,
    });
    if (msg) onSetMessage(status, msg);
    return contas;
  };

  const onSearchTipoTransferencia = async (e) => {
    const {
      status,
      message: msg,
      data: tipoExtratoTransferencia,
    } = await getTipoExtratoTransferenciaList({
      noTipoExtratoTransferencia: e,
    });
    if (msg) onSetMessage(status, msg);
    return tipoExtratoTransferencia;
  };
  const onSearchContaCorrenteTransferencia = async (e) => {
    const {
      status,
      message: msg,
      data: contaCorrenteTransferencia,
    } = await getContaCorrenteTransferenciaList({
      noTipoExtratoTransferencia: e,
    });
    if (msg) onSetMessage(status, msg);
    return contaCorrenteTransferencia;
  };

  const debitarOrigem = async () => {
    setLoading(true);

    const { status, message: msg } = await DebitarOrigem(data);

    if (status === ResponseStatus.Success) {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    setLoading(false);
  };

  const creditarDestino = async () => {
    setLoading(true);

    const { status, message: msg } = await CreditarDestino(data);

    if (status === ResponseStatus.Success) {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    setLoading(false);
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de ExtratoTransferencia'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({ vlrMovimento: 0 })}
      onSave={save}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-5 mb-3'>
          <Autocomplete
            required
            label='Descrição Transferencia'
            searchDataSource={onSearchContaCorrenteTransferencia}
            selectedItem={data.transferencia}
            onSelectItem={(transferencia) => {
              setData({
                ...data,
                transferencia,
                nrSeqContaCorrenteTransferenci:
                  transferencia.nrSeqContaCorrenteTransferenci,
                nrSeqContaCorrenteOri: transferencia.nrSeqContaCorrenteOri,
                contaCorrenteOri: transferencia.contaCorrenteOri,
                nrSeqContaCorrenteDest: transferencia.nrSeqContaCorrenteDest,
                contaCorrenteDest: transferencia.contaCorrenteDest,
              });
            }}
            dataSourceTextProperty='noContaCorrenteTransferencia'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-5 mb-3'>
          <Autocomplete
            required
            label='Conta Corrente Origem'
            readOnly={!data.flgBloquear}
            searchDataSource={onSearchContaCorrente}
            selectedItem={data.contaCorrenteOri}
            onSelectItem={(contaCorrenteOri) => {
              setData({
                ...data,
                contaCorrenteOri,
                NrSeqContaCorrenteOri: contaCorrenteOri.NrSeqContaCorrente,
              });
            }}
            dataSourceTextProperty='noContaCorrente'
          />
        </div>
        {data.nrSeqExtratoOri === null && data.nrSeqExtratoTransferencia > 0 && (
          <div className='col-4 mt-3 text-end'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Debitar Origem'
              onClick={debitarOrigem}
            />
          </div>
        )}
        {data.nrSeqExtratoOri === undefined &&
          data.nrSeqExtratoTransferencia > 0 && (
            <div className='col-4 mt-3 text-end'>
              <Button
                theme={Theme.Primary}
                template={Button.Templates.Button}
                text='Debitar Origem'
                onClick={debitarOrigem}
              />
            </div>
          )}
      </div>
      <div className='row'>
        <div className='col-5 mb-3'>
          <Autocomplete
            required
            readOnly={!data.flgBloquear}
            label='Conta Corrente Destino'
            searchDataSource={onSearchContaCorrente}
            selectedItem={data.contaCorrenteDest}
            onSelectItem={(contaCorrenteDest) => {
              setData({
                ...data,
                contaCorrenteDest,
                NrSeqContaCorrenteDest: contaCorrenteDest.NrSeqContaCorrente,
              });
            }}
            dataSourceTextProperty='noContaCorrente'
          />
        </div>
        {data.nrSeqExtratoDest === null && data.nrSeqExtratoTransferencia > 0 && (
          <div className='col-4 mt-3 text-end'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Creditar Destino'
              onClick={creditarDestino}
            />
          </div>
        )}
        {data.nrSeqExtratoDest === undefined &&
          data.nrSeqExtratoTransferencia > 0 && (
            <div className='col-4 mt-3 text-end'>
              <Button
                theme={Theme.Primary}
                template={Button.Templates.Button}
                text='Creditar Destino'
                onClick={creditarDestino}
              />
            </div>
          )}
      </div>
      <div className='row'>
        <div className='col-5 mb-3'>
          <Autocomplete
            required
            label='Tipo Transferencia'
            searchDataSource={onSearchTipoTransferencia}
            selectedItem={data.tipoExtratoTransferencia}
            onSelectItem={(tipoExtratoTransferencia) => {
              setData({
                ...data,
                tipoExtratoTransferencia,
                nrSeqTipoExtratoTransferencia:
                  tipoExtratoTransferencia.nrSeqTipoExtratoTransferencia,
              });
            }}
            dataSourceTextProperty='noTipoExtratoTransferencia'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-5 mb-3'>
          <DatePicker
            label='Data Documento'
            text={data.dtDocumento}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtDocumento) => setData({ ...data, dtDocumento })}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-5 mb-3'>
          <Textbox
            label='Valor Movimento'
            required
            text={data.vlrMovimento}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(vlrMovimento) =>
              setData({ ...data, vlrMovimento })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-5 mb-3'>
          <Textbox
            maxLength={20}
            label='Numero Identificador [Malote]'
            text={data.nrIdentificador}
            onChangedValue={(nrIdentificador) =>
              setData({ ...data, nrIdentificador })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-5 mb-3'>
          <Textbox
            maxLength={20}
            label='Observação adicional'
            text={data.noObs}
            onChangedValue={(noObs) => setData({ ...data, noObs })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-5 mb-3'>
          <Switch
            formControl
            label='Operação sera vinculada ao pagamento massivo?'
            checked={data.flgPagamentoMassivo}
            onChange={(flgPagamentoMassivo) =>
              setData({ ...data, flgPagamentoMassivo })
            }
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
