import AlmoxarifadoLocal from 'core/models/EST/almoxarifadoLocal';
import Almoxarifado from 'core/models/EST/almoxarifado';
import Produto from 'core/models/EST/produto';
import ProdutoAgrupador from 'core/models/EST/produtoAgrupador';
import Nf from 'core/models/COP/nf';
import Funcionario from '../SEG/funcionario';
import Veiculo from './veiculo';
import Usuario from '../SEG/usuario';

export default class ManutencaoVeiculoProduto {
  constructor(jsonObject = {}) {
    this.quantidade = jsonObject.quantidade;
    this.vlrUnitario = jsonObject.vlrUnitario;
    this.nrSeqEstoqueMovimento = jsonObject.nrSeqEstoqueMovimento;
    this.vlrTotal = jsonObject.vlrTotal;
    this.nrSeqProduto = jsonObject.nrSeqProduto;
    this.nrSeqManutencaoVeiculo = jsonObject.nrSeqManutencaoVeiculo;
    this.nrSeqAlmoxarifadoLocal = jsonObject.nrSeqAlmoxarifadoLocal;
    this.nrSeqManutencaoVeiculoItem = jsonObject.nrSeqManutencaoVeiculoItem;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.nrSeqPessoaMec = jsonObject.nrSeqPessoaMec;
    this.dtCad = jsonObject.dtCad;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.vlrDesconto = jsonObject.vlrDesconto;
    this.nrSeqPessoaFun = jsonObject.nrSeqPessoaFun;
    this.obs = jsonObject.obs;
    this.nrSeqNfCompra = jsonObject.nrSeqNfCompra;

    // Propriedades auxiliares
    this.status = jsonObject.status;
    this.usuario = new Usuario(jsonObject.usuario ?? {});
    this.produto = new Produto(jsonObject.produto ?? {});
    this.produtoAgrupador = new ProdutoAgrupador(
      jsonObject.produtoAgrupador ?? {}
    );
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    this.funcionario = new Funcionario(jsonObject.funcionario ?? {});
    this.almoxarifadoLocal = new AlmoxarifadoLocal(
      jsonObject.almoxarifadoLocal ?? {}
    );
    this.almoxarifado = new Almoxarifado(jsonObject.almoxarifado ?? {});
    this.nf = new Nf(jsonObject.nf ?? {});
    this.dtMovimento = jsonObject.dtMovimento;
  }

  toJson = () => JSON.stringify(this);
}
