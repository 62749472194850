import React, { useState } from 'react';

import { Button, DatePicker, Modal, Textbox } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { Theme, BootstrapSizes } from 'ui/Helpers/utils';

export default function ModalNovoTituloComSaldo(props) {
  const [data, setData] = useState({});

  const { show } = props;
  const fechar = async () => {
    props.onClose();
  };

  data.teste =
    'O título financeiro atual terá seu saldo em aberto zerado e um novo valor financeiro a pagar será gerado com o saldo em aberto e vencimento informado.';

  return (
    <Modal
      show={show}
      title='Gerar novo título com o saldo em aberto'
      size={BootstrapSizes.Large}
      icon='layer-group'
    >
      <Modal.Body className='p-4 pt-3'>
        <div className='row mb-3'>
          <div className='col-12 mb-2'>
            <Textbox
              readOnly
              text={data.teste}
              onChangedValue={(teste) => setData({ ...data, teste })}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col mb-3'>
            <DatePicker
              label='Novo Vencimento'
              required
              maxLength={10}
              mask={MaskTypes.Date}
            />
          </div>
          <div className='col-6 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Gerar Novo Título'
              onClick={fechar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
