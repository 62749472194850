import qs from 'qs';
import Pagination from 'core/models/pagination';
import PocEnsaioBombeamento from 'core/models/POC/pocEnsaioBombeamento';
import api from '../api';

const baseURL = `/POC/PocEnsaioBombeamento`;

export const getPocEnsaioBombeamentoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocEnsaioBombeamento(json)) ?? [],
  };
};

export const getPocEnsaioBombeamentoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocEnsaioBombeamento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocEnsaioBombeamento = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocEnsaioBombeamento(value) : {};
};

export const savePocEnsaioBombeamento = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const updateNivelBombeamento = async (data) => {
  const response = await api.post(`${baseURL}/updateNivel`, data);
  const { message, status, value: bombeamento } = response.data;
  return { message, status, value: new PocEnsaioBombeamento(bombeamento) };
};

export const updateRebaixamentoBombeamento = async (data) => {
  const response = await api.post(`${baseURL}/updateRebaixamento`, data);
  return response.data ?? {};
};

export const updateVazaoBombeamento = async (data) => {
  const response = await api.post(`${baseURL}/updateVazao`, data);
  return response.data ?? {};
};

export const updateTempoBombeamento = async (data) => {
  const response = await api.post(`${baseURL}/updateTempo`, data);
  return response.data ?? {};
};

export const deletePocEnsaioBombeamento = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const deletePocEnsaioBombeamentoTimesEmpty = async (id) => {
  const response = await api.delete(`${baseURL}/deleteTimesEmpty`, {
    params: { id },
  });
  return response.data ?? {};
};

export const printPocEnsaioBombeamento = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocEnsaioBombeamento = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocEnsaioBombeamento = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
