import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Switch, Textbox } from 'ui/components';
import {
  deleteTributacaoIcms,
  getTributacaoIcms,
  saveTributacaoIcms,
} from 'core/services/FIS';
import { Message, Page } from 'ui/Helpers/interfaces';
import { TributacaoIcms } from 'core/models/FIS';
import { ITributacaoICMS } from 'core/interfaces/FIS/tributacaoICMS';

export default function TributacaoICMSItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit: any = useRef();

  const [data, setData] = useState<ITributacaoICMS | TributacaoIcms | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const onNew = (): void => {
    setData({ flgAtivo: true });
    formSubmit.current?.reset();
  };

  const load = useCallback(async (): Promise<void> => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const tibutacaoIcms: TributacaoIcms | null = await getTributacaoIcms(
        registryKey
      );

      setData(tibutacaoIcms);

      setLoading(false);
    } else {
      onNew();
    }

    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    load();
  }, [load, registryKey, reload]);

  const save = async (): Promise<void> => {
    setLoading(true);

    const { status, message: msg } = await saveTributacaoIcms(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? { flgAtivo: true } : data);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onExcluir = async (): Promise<void> => {
    if (registryKey) {
      setLoading(true);

      const { status, message: msg } = await deleteTributacaoIcms(registryKey);

      setData(status === ResponseStatus.Success ? { flgAtivo: true } : data);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;

  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Tributo'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      // @ts-expect-error
      onDelete={data?.nrSeqTributacaoIcms > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='d-flex flex-column gap-3'>
        <div>
          {/* @ts-ignore */}
          <Textbox
            id='tributacaoicms1'
            maxLength={3}
            required
            label='Código'
            text={data?.cdTributacaoIcms}
            onChangedValue={(cdTributacaoIcms: string) =>
              // @ts-expect-error
              setData({
                ...data,
                cdTributacaoIcms,
              })
            }
          />
        </div>
        <div>
          {/* @ts-ignore */}
          <Textbox
            id='tributacaoicms2'
            maxLength={80}
            required
            label='Descrição'
            text={data?.noTributacaoIcms}
            onChangedValue={(noTributacaoIcms: string) =>
              // @ts-expect-error
              setData({ ...data, noTributacaoIcms })
            }
          />
        </div>
        <div>
          {/* @ts-ignore */}
          <Switch
            id='tributacaoicms3'
            formControl
            checked={data?.flgAtivo}
            label='Ativo'
            onChange={(flgAtivo: boolean) => setData({ ...data, flgAtivo })}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
