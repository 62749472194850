import React from 'react';
import { Themes, generateID } from 'ui/Helpers/utils';

export default function RadioButton({
  selectedButton,
  outline,
  theme,
  size,
  onChange,
  visible = true,
  id = generateID(),
  label,
  buttons,
  disabled,
}) {
  const outlineClass = (outline && `-outline`) ?? '';

  const buttonClass = ['btn'];
  if (Themes.includes(theme)) buttonClass.push(`btn${outlineClass}-${theme}`);

  if (size) buttonClass.push(`btn-${size}`);

  const handleOnChange = (item) => {
    if (onChange) onChange(item.value);
  };

  const groupName = `options-${((id || label) ?? '').replaceAll(' ', '')}`;

  return visible ? (
    <>
      {label && (
        <label className='form-label' htmlFor={id}>
          {label}
        </label>
      )}
      <div id={id} className='btn-group d-block'>
        {buttons.map((button, index) => {
          const optID = `${groupName}-${index}`;

          const inputProps = {
            type: 'radio',
            className: 'btn-check',
            name: groupName,
            id: optID,
            autoComplete: 'off',
            value: button.value,
            onChange: () => handleOnChange(button),
          };

          if (
            selectedButton &&
            String(button.value).trim().toUpperCase() ===
              String(selectedButton).trim().toUpperCase()
          ) {
            inputProps.checked = true;
          }

          if (disabled) inputProps.disabled = true;

          return (
            <React.Fragment key={generateID('rdbi')}>
              {React.createElement('input', inputProps)}
              <label className={buttonClass.join(' ')} htmlFor={optID}>
                {button.text}
              </label>
            </React.Fragment>
          );
        })}
      </div>
    </>
  ) : null;
}
