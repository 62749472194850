import ChecklistCampos from 'core/models/FRO/checklistCampos';

export default class ChecklistFrota {
  constructor(jsonObject = {}) {
    this.nrSeqChecklistFrota = jsonObject.nrSeqChecklistFrota;
    this.noChecklistFrota = jsonObject.noChecklistFrota;
    this.cdChecklistFrota = jsonObject.cdChecklistFrota;
    this.noLayout = jsonObject.noLayout;
    this.noTitulo = jsonObject.noTitulo;
    this.flgImagemEmpresa = jsonObject.flgImagemEmpresa;
    this.flgManutencaoPreventiva = jsonObject.flgManutencaoPreventiva;
    this.flgImprimeTodosItens = jsonObject.flgImprimeTodosItens;
    this.flgMotorista = jsonObject.flgMotorista;

    // #region Propriedades Auxiliares
    this.listChecklistCampos = jsonObject.listChecklistCampos
      ? jsonObject.listChecklistCampos.map(
          (item) => new ChecklistCampos(item ?? {})
        )
      : [];
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
