import { Produto } from 'core/models/EST';

export default class PedidoItem {
  constructor(jsonObject = {}) {
    this.nrSeqPedidoItem = jsonObject.nrSeqPedidoItem;
    this.qtdeItPed = jsonObject.qtdeItPed ?? 0;
    this.qtdeCancel = jsonObject.qtdeCancel ?? 0;
    this.qtdeSaldoPed = jsonObject.qtdeSaldoPed ?? 0;
    this.flgPrzEntrega = jsonObject.flgPrzEntrega;
    this.vlrItPedTab = jsonObject.vlrItPedTab ?? 0;
    this.vlrItPed = jsonObject.vlrItPed ?? 0;
    this.nrSeqProduto = jsonObject.nrSeqProduto;
    this.nrSeqAlmoxarifadoLocal = jsonObject.nrSeqAlmoxarifadoLocal;
    this.vlrDesc = jsonObject.vlrDesc ?? 0;
    this.obsItPed = jsonObject.obsItPed;
    this.nrSeqPedido = jsonObject.nrSeqPedido;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.vlrTotPed = jsonObject.vlrTotPed ?? 0;
    this.flgTipo = jsonObject.flgTipo;
    this.msGenda = jsonObject.msGenda;
    this.romaneioQtde = jsonObject.romaneioQtde;
    this.msgVenda = jsonObject.msgVenda;
    this.vlrItFrete = jsonObject.vlrItFrete ?? 0;
    this.vlrImperfeicao = jsonObject.vlrImperfeicao ?? 0;

    this.produto = jsonObject.produto ?? new Produto();
  }

  toJson = () => JSON.stringify(this);
}
