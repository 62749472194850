import Estado from './estado';

export default class Cidade {
  constructor(jsonObject = {}) {
    this.nrSeqEstado = jsonObject.nrSeqEstado;
    this.nrSeqCidade = jsonObject.nrSeqCidade;
    this.noCidade = jsonObject.noCidade;
    this.cdCidade = jsonObject.cdCidade;
    this.cdIbge = jsonObject.cdIbge;
    this.noCidadeEstado = {};

    this.estado = new Estado(jsonObject.estado ?? {});

    if (this.nrSeqCidade > 0) {
      this.noCidadeEstado = `${this.noCidade} - ${this.estado.cdEstado} `;
    } else {
      this.noCidadeEstado = '';
    }
  }

  toJson = () => JSON.stringify(this);
}
