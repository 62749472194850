import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getLancamentoOcorrenciaPagined,
  printLancamentoOcorrencia,
  excelLancamentoOcorrencia,
  deleteAllLancamentoOcorrencia,
} from 'core/services/FRO/lancamentoOcorrencia';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { MaskTypes } from 'ui/Helpers/masks';
import { CSDSelPage, GridView, Autocomplete, DatePicker } from 'ui/components';

const columns = [
  { key: 'nrSeqVeiculoOcorrencia', type: GridView.ColumnTypes.Checkbox },
  { key: 'nrSeqPessoaMot', title: 'NrSeqPessoaMot', visible: false },
  { key: 'nrSeqVeiculo', title: 'NrSeqVeiculo', visible: false },
  {
    key: 'dtOcorrencia',
    title: 'Data da Ocorrência',
    format: GridView.DataTypes.Date,
  },
  {
    key: 'hrOcorrencia',
    title: 'Hora da Ocorrência',
    format: GridView.DataTypes.Time,
  },
  {
    key: 'motorista.noPessoa',
    title: 'Motorista',
  },
  {
    key: 'veiculo.noVeiculo',
    title: 'Veiculo',
  },
  { key: 'descricao', title: 'Descrição' },
  { key: 'nrSeqGrupoEmpresa', title: 'NrSeqGrupoEmpresa', visible: false },
  { key: 'nrSeqTipoOcorrencia', title: 'NrSeqTipoOcorrencia', visible: false },
  { key: 'noLogin', title: 'Usuário Cadastro' },
];

export default function LancamentoOcorrencia({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getLancamentoOcorrenciaPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getLancamentoOcorrenciaPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'LancamentoOcorrencia/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqVeiculoOcorrencia = sourceRow.find(
      (e) => e.key === 'nrSeqVeiculoOcorrencia'
    );
    onOpenMaintenance(nrSeqVeiculoOcorrencia.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printLancamentoOcorrencia(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllLancamentoOcorrencia(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchPessoaMot = async (e) => {
    const { status, message: msg, motoristas } = await getMotoristaAutoComplete(
      {
        noPessoa: e,
      }
    );

    if (msg) onSetMessage(status, msg);
    return motoristas;
  };

  const onSearchVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onDownloadExcel = async () => {
    setLoading(true);

    const { value: base64 } = await excelLancamentoOcorrencia(filtros);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'Ocorrencias.csv';
      downloadLink.click();
    }

    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção Lançamento Ocorrência'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      onDownloadExcel={onDownloadExcel}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Data da Ocorrência'
            text={filtros.dtOcorrencia}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtOcorrencia) =>
              setFiltros({ ...filtros, dtOcorrencia })
            }
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Veiculo'
            searchDataSource={onSearchVeiculo}
            selectedItem={filtros.veiculo}
            onSelectItem={(veiculo) => {
              setFiltros({
                ...filtros,
                veiculo,
                nrSeqVeiculo: veiculo.nrSeqVeiculo,
              });
            }}
            dataSourceTextProperty='noVeiculo'
          />
        </div>
        <div className='col-6'>
          <Autocomplete
            label='Motorista'
            searchDataSource={onSearchPessoaMot}
            selectedItem={filtros.motorista}
            onSelectItem={(motorista) => {
              setFiltros({
                ...filtros,
                motorista,
                nrSeqPessoaMot: motorista.nrSeqPessoaMot,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
