import { Nf } from '../COP';
import { Produto } from '../EST';
import PneuSituacao from './pneuSituacao';
import PneuVida from './pneuVida';
import PneuModelo from './pneuModelo';

export default class Pneu {
  constructor(jsonObject = {}) {
    this.nrSeqPneu = jsonObject.nrSeqPneu;
    this.kmAcumulada = jsonObject.kmAcumulada;
    this.numeroSerie = jsonObject.numeroSerie;
    this.dtCompra = jsonObject.dtCompra;
    this.nrSeqPneuSituacao = jsonObject.nrSeqPneuSituacao;
    this.nrSeqPneuVida = jsonObject.nrSeqPneuVida;
    this.nrSulcoInicial = jsonObject.nrSulcoInicial;
    this.kmInicio = jsonObject.kmInicio;
    this.dtTermino = jsonObject.dtTermino;
    this.cdPneu = jsonObject.cdPneu;
    this.nrSulcoFinal = jsonObject.nrSulcoFinal;
    this.nrSeqProduto = jsonObject.nrSeqProduto;
    this.nrDot = jsonObject.nrDot;
    this.flgParcial = jsonObject.flgParcial;
    this.nrOrdem = jsonObject.nrOrdem;
    this.kmInicial = jsonObject.kmInicial;
    this.vlrCompra = jsonObject.vlrCompra;

    this.pneuVida = new PneuVida(jsonObject.pneuVida ?? {});
    this.pneuSituacao = new PneuSituacao(jsonObject.pneuSituacao ?? {});
    this.pneuModelo = new PneuModelo(jsonObject.pneuModelo ?? {});
    this.nf = new Nf(jsonObject.nf ?? {});
    this.produto = new Produto(jsonObject.produto ?? {});
    this.pneuVeiculoS = jsonObject.pneuVeiculoS;

    // propriedades auxiliares
    this.dtTerminoInicial = jsonObject.dtTerminoInicial;
    this.dtTerminoFinal = jsonObject.dtTerminoFinal;
    this.dtCompraInicial = jsonObject.dtCompraInicial;
    this.dtCompraFinal = jsonObject.dtCompraFinal;
  }

  toJson = () => JSON.stringify(this);
}
