import React, { useEffect, useRef, useState } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  printVeiculoModelo,
  deleteAllVeiculoModelo,
  getVeiculoModeloPaginated,
} from 'core/services/FRO/veiculoModelo';
import {
  CSDSelPage,
  GridView,
  Autocomplete,
  Textbox,
  Panel,
  RadioButton,
} from 'ui/components';
import { getVeiculoMarcaAutoComplete } from 'core/services/FRO/veiculoMarca';

const columns = [
  { key: 'nrSeqVeiculoModelo', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdVeiculoModelo', title: 'Código' },
  { key: 'noVeiculoModelo', title: 'Modelo' },
  { key: 'veiculoMarca.noVeiculoMarca', title: 'Marca' },
  { key: 'vlrTara', title: 'Valor Tara' },
  { key: 'flgAtivo', title: 'Ativo' },
];

export default function VeiculoModelo({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({ status: 'A' });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    let flgAtivo = null;

    if (filtros.status === 'I') {
      flgAtivo = false;
    } else if (filtros.status === 'A') {
      flgAtivo = true;
    }

    const { data, pagination } = await getVeiculoModeloPaginated(
      params ?? { ...filtros, flgAtivo }
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  useEffect(() => {
    async function load() {
      setLoading(true);

      search();

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'VeiculoModelo/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqVeiculoModelo = sourceRow.find(
      (e) => e.key === 'nrSeqVeiculoModelo'
    );
    onOpenMaintenance(nrSeqVeiculoModelo.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printVeiculoModelo(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onSearchVeiculoMarca = async (e) => {
    const {
      status,
      message: msg,
      data: veiculoMarcas,
    } = await getVeiculoMarcaAutoComplete({ noVeiculoMarca: e });
    if (msg) onSetMessage(status, msg);
    return veiculoMarcas;
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllVeiculoModelo(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const statusButtons = [
    { text: 'Todos', value: 'T' },
    { text: 'Ativos', value: 'A' },
    { text: 'Inativos', value: 'I' },
  ];

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Veiculo Modelo'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Filtros' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col'>
                <Autocomplete
                  label='Marca'
                  searchDataSource={onSearchVeiculoMarca}
                  selectedItem={filtros.veiculoMarca}
                  onSelectItem={(veiculoMarca) => {
                    setFiltros({
                      ...filtros,
                      veiculoMarca,
                      nrSeqVeiculoMarca: veiculoMarca.nrSeqVeiculoMarca,
                    });
                  }}
                  dataSourceTextProperty='noVeiculoMarca'
                />
              </div>
              <div className='col'>
                <Textbox
                  maxLength={60}
                  label='Modelo'
                  text={filtros.noVeiculoModelo}
                  onChangedValue={(noVeiculoModelo) =>
                    setFiltros({ ...filtros, noVeiculoModelo })
                  }
                />
              </div>
              <div className='col'>
                <RadioButton
                  outline
                  label='Status'
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.status}
                  onChange={(status) => {
                    setFiltros({ ...filtros, status });
                  }}
                  buttons={statusButtons}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            canControlVisibility
            enableExcelExport
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
