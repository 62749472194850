import qs from 'qs';
import Pagination from 'core/models/pagination';
import EnergiaDistribuidora from 'core/models/ENE/energiaDistribuidora';
import api from '../api';

const baseURL = `/ENE/EnergiaDistribuidora`;

export const getEnergiaDistribuidoraList = async (filters: any) => {
  const response = await api.get(`/$EnergiaDistribuidora/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new EnergiaDistribuidora(json)) ?? [],
  };
};

export const getEnergiaDistribuidoraPagined = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new EnergiaDistribuidora(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getEnergiaDistribuidora = async (id: number) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new EnergiaDistribuidora(value) : {};
};

export const getEnergiaDistribuidoraAutoComplete = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchAutoComplete`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    distribuidoras:
      value?.map((json: any) => new EnergiaDistribuidora(json)) ?? [],
  };
};

export const saveEnergiaDistribuidora = async (data: EnergiaDistribuidora) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteEnergiaDistribuidora = async (id: number) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printEnergiaDistribuidora = async (filters: any) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelEnergiaDistribuidora = async (filters: any) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllEnergiaDistribuidora = async (ids: number[]) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
