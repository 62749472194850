import React from 'react';

const getArrayOfPages = (totalPages, selectedPage) => {
  if (totalPages <= 7) return Array(totalPages).keys();
  if (selectedPage <= 3) return [...Array(6).keys(), totalPages - 1];
  if (selectedPage >= totalPages - 3)
    return [0, ...Array.from(Array(totalPages).keys()).slice(selectedPage - 6)];

  return [
    0,
    ...Array.from(Array(totalPages).keys()).slice(
      selectedPage - 3,
      selectedPage + 2
    ),
    totalPages - 1,
  ];
};

const TPagination = (props) => {
  const { pagination, onPageChange, notAllowChangePage } = props;

  const firstRegister =
    pagination?.page * pagination?.totalByPage - pagination?.totalByPage + 1;
  let lastRegister = pagination?.page * pagination?.totalByPage;
  if (lastRegister > pagination?.totalRows)
    lastRegister = pagination?.totalRows;

  const totalPages = Math.ceil(
    (pagination?.totalRows ?? 1) / (pagination?.totalByPage ?? 1)
  );

  let lastPage = 1;
  const pages = Array.from(getArrayOfPages(totalPages, pagination?.page)).map(
    (index) => {
      if (pagination?.totalRows > 0) {
        const pageIndex = index + 1;
        const activePage = pagination?.page === pageIndex;

        const spread = lastPage !== pageIndex;
        const auxLastPage = lastPage;
        lastPage = pageIndex + 1;
        return (
          <React.Fragment key={index}>
            {spread && (
              <li className='page-item'>
                <button
                  type='button'
                  className='page-link'
                  onClick={() =>
                    onPageChange &&
                    onPageChange(
                      Math.round((pageIndex - auxLastPage) / 2) + auxLastPage
                    )
                  }
                >
                  ...
                </button>
              </li>
            )}
            <li
              className={`page-item${activePage ? ' active' : ''}`}
              aria-current={activePage ? 'page' : ''}
            >
              <button
                type='button'
                className='page-link'
                onClick={() => onPageChange && onPageChange(pageIndex)}
              >
                {pageIndex}
              </button>
            </li>
          </React.Fragment>
        );
      }
      return (
        <li key={index} className='page-item disabled'>
          <button type='button' className='page-link' aria-disabled='true'>
            0
          </button>
        </li>
      );
    }
  );

  const previousActive = pagination?.page > 1;
  const nextActive = totalPages > 1 && pagination?.page !== totalPages;

  return (
    <div className='row'>
      <div className='col'>
        Exibindo de{' '}
        <span className='text-primary fw-bold'>
          {Number.isNaN(firstRegister) ? 0 : firstRegister}
        </span>{' '}
        até{' '}
        <span className='text-primary fw-bold'>
          {Number.isNaN(lastRegister) ? 0 : lastRegister}
        </span>{' '}
        de um total de{' '}
        <span className='text-primary fw-bold'>
          {pagination?.totalRows ?? 0}
        </span>
      </div>
      <div className='col'>
        {!notAllowChangePage && (
          <nav>
            <ul className='pagination justify-content-end'>
              <li className={`page-item${previousActive ? '' : ' disabled'}`}>
                <button
                  type='button'
                  className='page-link'
                  aria-disabled={!previousActive}
                  onClick={() => onPageChange(pagination.page - 1)}
                >
                  Anterior
                </button>
              </li>
              {pages}
              <li className={`page-item${nextActive ? '' : ' disabled'}`}>
                <button
                  type='button'
                  className='page-link'
                  aria-disabled={!nextActive}
                  onClick={() => onPageChange(pagination.page + 1)}
                >
                  Próxima
                </button>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
};
export default TPagination;
