import qs from 'qs';
import Pagination from 'core/models/pagination';
import PostoCombustivel from 'core/models/FRO/postoCombustivel';
import api from '../api';

const baseURL = `/FRO/PostoCombustivel`;

export const getPostoCombustivelAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PostoCombustivel(json)) ?? [],
  };
};

export const getPostoCombustivelAutoCompleteWithIntegration = async (
  filters
) => {
  const response = await api.get(`${baseURL}/AutoCompleteWithIntegration`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PostoCombustivel(json)) ?? [],
  };
};

export const getPostoCombustivelList = async (filters) => {
  const response = await api.get(`${baseURL}/PostoCombustivel/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PostoCombustivel(json)) ?? [],
  };
};

export const getPostoCombustivelPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PostoCombustivel(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPostoCombustivel = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PostoCombustivel(value) : {};
};

export const savePostoCombustivel = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePostoCombustivel = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPostoCombustivel = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPostoCombustivel = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPostoCombustivel = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
