import qs from 'qs';
import Pagination from 'core/models/pagination';
import TransacaoPaginaPermissao from 'core/models/SEG/transacaoPaginaPermissao';
import api from '../api';

const baseURL = `/SEG/TransacaoPaginaPermissao`;

export const getTransacaoPaginaPermissaoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TransacaoPaginaPermissao(json)) ?? [],
  };
};

export const getTransacaoPaginaPermissaoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TransacaoPaginaPermissao(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getTransacaoPaginaPermissao = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new TransacaoPaginaPermissao(value) : {};
};

export const saveTransacaoPaginaPermissao = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteTransacaoPaginaPermissao = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printTransacaoPaginaPermissao = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelTransacaoPaginaPermissao = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllTransacaoPaginaPermissao = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
