import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Button,
  Modal,
  Notification,
  Panel,
  Textbox,
} from 'ui/components';
import {
  Theme,
  ResponseStatus,
  BootstrapSizes,
  JustifyContent,
} from 'ui/Helpers/utils';

import { MaskTypes } from 'ui/Helpers/masks';

import { NfvAvulsaItem } from 'core/models/VEN';

import {
  getTributacaoIcmsAutoComplete,
  getCstIpiAutoComplete,
  getCstPisAutoComplete,
  getCstCofinsAutoComplete,
} from 'core/services/FIS';
import { getProdutoOrigemAutoComplete } from 'core/services/EST/produtoOrigem.tsx';

export default function ModalImpostosNotaFiscal(props) {
  const { itemNota } = props;
  const [dataItemNota, setDataItemNota] = useState(new NfvAvulsaItem({}));

  useEffect(() => {
    setDataItemNota(itemNota);
  }, [itemNota]);

  const [messageState, setMessage] = useState(null);

  const onSearchTributacaoIcms = async (noTributacaoIcms) => {
    const {
      status,
      message,
      data: tributacaoIcms,
    } = await getTributacaoIcmsAutoComplete({
      noTributacaoIcms,
    });

    if (status !== ResponseStatus.Success)
      setMessage({
        message,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return tributacaoIcms;
  };

  const onSearchTributacaoIpi = async (cdCstIpi) => {
    const {
      status,
      message,
      data: tributacaoIpi,
    } = await getCstIpiAutoComplete({
      cdCstIpi,
    });

    if (status !== ResponseStatus.Success)
      setMessage({
        message,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return tributacaoIpi;
  };

  const onSearchTributacaoPis = async (cdCstPIs) => {
    const {
      status,
      message,
      data: tributacaoPis,
    } = await getCstPisAutoComplete({
      cdCstPIs,
    });

    if (status !== ResponseStatus.Success)
      setMessage({
        message,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return tributacaoPis;
  };

  const onSearchTributacaoCofins = async (cdCstCofins) => {
    const {
      status,
      message,
      data: tributacaoCofins,
    } = await getCstCofinsAutoComplete({
      cdCstCofins,
    });

    if (status !== ResponseStatus.Success)
      setMessage({
        message,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return tributacaoCofins;
  };

  const onSearchProdutoOrigem = async (noProdutoOrigem) => {
    const {
      status,
      message,
      data: produtoOrigem,
    } = await getProdutoOrigemAutoComplete({
      noProdutoOrigem,
    });

    if (status !== ResponseStatus.Success)
      setMessage({
        message,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return produtoOrigem;
  };

  const handlePercBaseCalcIcmsChange = (percBaseCalcIcms) => {
    const vlrBaseCalcIcms =
      (parseFloat(percBaseCalcIcms) / 100) * dataItemNota.vlrTotNfv;
    setDataItemNota({
      ...dataItemNota,
      percBaseCalcIcms,
      vlrBaseCalcIcms,
    });
  };

  const handleAliqIcmsChange = (aliqIcms) => {
    const vlrIcms =
      (parseFloat(dataItemNota.percBaseCalcIcms) / 100) *
      (parseFloat(aliqIcms) / 100) *
      dataItemNota.vlrTotNfv;
    setDataItemNota({
      ...dataItemNota,
      aliqIcms,
      vlrIcms,
    });
  };

  const handleVlrBaseCalcIcmsChange = (vlrBaseCalcIcms) => {
    setDataItemNota({
      ...dataItemNota,
      vlrBaseCalcIcms,
    });
  };

  const handlePercBaseCalcIpiChange = (percBaseCalcIpi) => {
    const vlrBaseCalcIpi =
      (parseFloat(percBaseCalcIpi) / 100) * dataItemNota.vlrTotNfv;
    setDataItemNota({
      ...dataItemNota,
      percBaseCalcIpi,
      vlrBaseCalcIpi,
    });
  };

  const handleAliqIpiChange = (aliqIpi) => {
    const vlrIpi =
      (parseFloat(dataItemNota.percBaseCalcIpi) / 100) *
      (parseFloat(aliqIpi) / 100) *
      dataItemNota.vlrTotNfv;
    setDataItemNota({
      ...dataItemNota,
      aliqIpi,
      vlrIpi,
    });
  };

  const handleVlrBaseCalcIpiChange = (vlrBaseCalcIpi) => {
    setDataItemNota({
      ...dataItemNota,
      vlrBaseCalcIpi,
    });
  };

  const onSend = async () => {
    props.onSave(dataItemNota);
    props.onClose();
  };

  const { show, onClose, recursos } = props;
  return (
    <Modal
      show={show}
      title='Detalhes dos Impostos'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body className='p-3 pt-3'>
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='row mb-3'>
          <div className='col-6'>
            <Panel>
              <Panel.Header
                title='ICMS'
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />

              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-12'>
                    <Autocomplete
                      label='Tributação'
                      required
                      searchDataSource={onSearchTributacaoIcms}
                      selectedItem={dataItemNota.tributacaoIcms}
                      onSelectItem={(tributacaoIcms) => {
                        setDataItemNota({
                          ...dataItemNota,
                          tributacaoIcms,
                          nrSeqTributacaoIcms:
                            tributacaoIcms.nrSeqTributacaoIcms,
                        });
                      }}
                      dataSourceTextProperty='noTributacaoIcms'
                    />
                  </div>
                </div>
                {dataItemNota.tributacaoIcms?.cdTributacaoIcms !== '40' && (
                  <div className='row'>
                    <div className='col-6'>
                      <Textbox
                        label='% Base'
                        text={dataItemNota.percBaseCalcIcms}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={handlePercBaseCalcIcmsChange}
                      />
                    </div>
                    <div className='col-6'>
                      <Textbox
                        label='Alíquota'
                        text={dataItemNota.aliqIcms}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={handleAliqIcmsChange}
                      />
                    </div>
                  </div>
                )}

                {dataItemNota.tributacaoIcms?.cdTributacaoIcms !== '40' && (
                  <div className='row mt-3'>
                    <div className='col-6'>
                      <Textbox
                        label='Valor Base'
                        text={dataItemNota.vlrBaseCalcIcms}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={handleVlrBaseCalcIcmsChange}
                      />
                    </div>

                    <div className='col-6'>
                      <Textbox
                        label='Valor ICMS'
                        text={dataItemNota.vlrIcms}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={(vlrIcms) =>
                          setDataItemNota({
                            ...dataItemNota,
                            vlrIcms,
                          })
                        }
                      />
                    </div>
                  </div>
                )}

                {dataItemNota.tributacaoIcms?.cdTributacaoIcms !== '40' && (
                  <div className='row mt-3'>
                    <div className='col-6'>
                      <Textbox
                        label='B.C Substituição'
                        text={dataItemNota.vlrBSubstituicao}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={(vlrBSubstituicao) =>
                          setDataItemNota({
                            ...dataItemNota,
                            vlrBSubstituicao,
                          })
                        }
                      />
                    </div>

                    <div className='col-6'>
                      <Textbox
                        label='Vlr. Substituição'
                        text={dataItemNota.vlrSubstituicao}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={(vlrSubstituicao) =>
                          setDataItemNota({
                            ...dataItemNota,
                            vlrSubstituicao,
                          })
                        }
                      />
                    </div>
                  </div>
                )}

                <div className='row mt-3'>
                  <div className='col-12'>
                    <Autocomplete
                      label='Produto Origem'
                      required
                      searchDataSource={onSearchProdutoOrigem}
                      selectedItem={dataItemNota.produtoOrigem}
                      onSelectItem={(produtoOrigem) => {
                        setDataItemNota({
                          ...dataItemNota,
                          produtoOrigem,
                          nrSeqProdutoOrigem: produtoOrigem.nrSeqProdutoOrigem,
                        });
                      }}
                      dataSourceTextProperty='noProdutoOrigem'
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>

          <div className='col-6'>
            <Panel>
              <Panel.Header
                title='IPI'
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />

              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-12'>
                    <Autocomplete
                      label='Tributação'
                      required
                      searchDataSource={onSearchTributacaoIpi}
                      selectedItem={dataItemNota.cstIpi}
                      onSelectItem={(cstIpi) => {
                        setDataItemNota({
                          ...dataItemNota,
                          cstIpi,
                          nrSeqCstIpi: cstIpi.nrSeqCstIpi,
                        });
                      }}
                      dataSourceTextProperty='noCstIpi'
                    />
                  </div>
                </div>
                {dataItemNota.cstIpi?.cdCstIpi !== '52' && (
                  <div className='row'>
                    <div className='col-6'>
                      <Textbox
                        label='% Base'
                        text={dataItemNota.percBaseCalcIpi}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={handlePercBaseCalcIpiChange}
                      />
                    </div>

                    <div className='col-6'>
                      <Textbox
                        label='Alíquota'
                        text={dataItemNota.aliqIpi}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={handleAliqIpiChange}
                      />
                    </div>
                  </div>
                )}

                {dataItemNota.cstIpi?.cdCstIpi !== '52' && (
                  <div className='row mt-3'>
                    <div className='col-6'>
                      <Textbox
                        label='Valor Base'
                        text={dataItemNota.vlrBaseCalcIpi}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={handleVlrBaseCalcIpiChange}
                      />
                    </div>

                    <div className='col-6'>
                      <Textbox
                        label='Valor IPI'
                        text={dataItemNota.vlrIpi}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={(vlrIpi) =>
                          setDataItemNota({
                            ...dataItemNota,
                            vlrIpi,
                          })
                        }
                      />
                    </div>
                  </div>
                )}
              </Panel.Body>
            </Panel>
          </div>

          <div className='col-6'>
            <Panel>
              <Panel.Header
                title='PIS'
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />

              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-12'>
                    <Autocomplete
                      label='Tributação'
                      required
                      searchDataSource={onSearchTributacaoPis}
                      selectedItem={dataItemNota.cstPis}
                      onSelectItem={(cstPis) => {
                        setDataItemNota({
                          ...dataItemNota,
                          cstPis,
                          nrSeqCstPis: cstPis.nrSeqCstPis,
                        });
                      }}
                      dataSourceTextProperty='noCstPis'
                    />
                  </div>
                </div>
                {dataItemNota.cstPis?.cdCstPis !== '07' && (
                  <div className='row'>
                    <div className='col-6'>
                      <Textbox
                        label='Alíquota'
                        text={dataItemNota.aliqPis}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={(aliqPis) =>
                          setDataItemNota({
                            ...dataItemNota,
                            aliqPis,
                          })
                        }
                      />
                    </div>

                    <div className='col-6'>
                      <Textbox
                        label='Valor'
                        text={dataItemNota.vlrPis}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={(vlrPis) =>
                          setDataItemNota({
                            ...dataItemNota,
                            vlrPis,
                          })
                        }
                      />
                    </div>
                  </div>
                )}
              </Panel.Body>
            </Panel>
          </div>

          <div className='col-6'>
            <Panel>
              <Panel.Header
                title='COFINS'
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />

              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-12'>
                    <Autocomplete
                      label='Tributação'
                      required
                      searchDataSource={onSearchTributacaoCofins}
                      selectedItem={dataItemNota.cstCofins}
                      onSelectItem={(cstCofins) => {
                        setDataItemNota({
                          ...dataItemNota,
                          cstCofins,
                          nrSeqCstCofins: cstCofins.nrSeqCstCofins,
                        });
                      }}
                      dataSourceTextProperty='noCstCofins'
                    />
                  </div>
                </div>
                {dataItemNota.cstCofins?.cdCstCofins !== '07' && (
                  <div className='row'>
                    <div className='col-6'>
                      <Textbox
                        label='Alíquota'
                        text={dataItemNota.aliqCofins}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={(aliqCofins) =>
                          setDataItemNota({
                            ...dataItemNota,
                            aliqCofins,
                          })
                        }
                      />
                    </div>

                    <div className='col-6'>
                      <Textbox
                        label='Valor'
                        text={dataItemNota.vlrCofins}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={(vlrCofins) =>
                          setDataItemNota({
                            ...dataItemNota,
                            vlrCofins,
                          })
                        }
                      />
                    </div>
                  </div>
                )}
              </Panel.Body>
            </Panel>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            {recursos?.map((recurso) => (
              <div
                key={recurso.nrSeqContaRecurso}
                className='badge bg-secondary me-2 p-2 fs-6 mb-2'
              >
                {recurso.numeroCompleto}
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button text='Confirmar' theme={Theme.Success} onClick={onSend} />
      </Modal.Footer>
    </Modal>
  );
}
