/* eslint-disable import/extensions */
import TipoServico from 'core/models/GProj/tipoServico';
import TituloEspecie from '../FIN/tituloEspecie';
import FormaPagamento from '../FIN/formaPagamento';
import PocTipoOrcamentoDocumento from './pocTipoOrcamentoDocumento';

export default class PocTipoOrcamento {
  constructor(jsonObject = {}) {
    this.nrSeqPocTipoOrcamento = jsonObject.nrSeqPocTipoOrcamento;
    this.noPocTipoOrcamento = jsonObject.noPocTipoOrcamento;
    this.flgVinculaCliente = jsonObject.flgVinculaCliente;
    this.flgManutencao = jsonObject.flgManutencao;
    this.flgGeraPoco = jsonObject.flgGeraPoco;
    this.noObservacao = jsonObject.noObservacao;
    this.flgIncluirDescricaoItensNota = jsonObject.flgIncluirDescricaoItensNota;
    this.nrSeqTipoServico = jsonObject.nrSeqTipoServico;
    this.flgOrcamentoEndereco = jsonObject.flgOrcamentoEndereco;
    this.flgObra = jsonObject.flgObra;
    this.nrSeqTituloEspecie = jsonObject.nrSeqTituloEspecie;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.noModeloDocumento = jsonObject.noModeloDocumento;
    this.tipoServico = new TipoServico(jsonObject.tipoServico ?? {});
    this.tituloEspecie = new TituloEspecie(jsonObject.tituloEspecie ?? {});
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    if (jsonObject.documentos) {
      this.documentos = jsonObject.documentos?.map(
        (documento) => new PocTipoOrcamentoDocumento(documento ?? {})
      );
    } else {
      this.documentos = [];
    }
  }

  toJson = () => JSON.stringify(this);
}
