import ApiEnvio from './apiEnvio';

export default class EmailTitulo {
  constructor(jsonObject = {}) {
    this.nrSeqEmailTitulo = jsonObject.nrSeqEmailTitulo;
    this.cdEmailTitulo = jsonObject.cdEmailTitulo;
    this.msgEmailTitulo = jsonObject.msgEmailTitulo;
    this.noTituloEmail = jsonObject.noTituloEmail;
    this.cdApiEnvio = jsonObject.cdApiEnvio;
    this.flgSMS = jsonObject.flgSMS;
    this.flgAtivo = jsonObject.flgAtivo;
    this.flgWhatsApp = jsonObject.flgWhatsApp;
    this.flgEmail = jsonObject.flgEmail;
    this.flgFinanceiro = jsonObject.flgFinanceiro;
    this.flgTemplateWhatsApp = jsonObject.flgTemplateWhatsApp;
    this.flgVerificaConexaoWhatsApp = jsonObject.flgVerificaConexaoWhatsApp;
    this.flgSalvaOcorrencia = jsonObject.flgSalvaOcorrencia;
    this.flgMensagemEnvioNFS = jsonObject.flgMensagemEnvioNFS;
    this.nrTelefoneOrigem = jsonObject.nrTelefoneOrigem;
    this.nrTeamId = jsonObject.nrTeamId;
    this.nrBotFlowId = jsonObject.nrBotFlowId;
    this.nrBotConfigId = jsonObject.nrBotConfigId;
    this.noImagem = jsonObject.noImagem;
    this.apiEnvio = new ApiEnvio(jsonObject.apiEnvio ?? {});
  }

  get codigoTitulo() {
    if (this.cdEmailTitulo && this.noTituloEmail)
      return `(${this.cdEmailTitulo}) ${this.noTituloEmail}`;
    return '';
  }

  toJson = () => JSON.stringify(this);
}
