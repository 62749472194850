import React, { useState, useRef, useEffect } from 'react';
import { ResponseStatus, Theme } from 'ui/Helpers/utils';

import {
  getPessoaDocumentoPagined,
  sendSms,
} from 'core/services/SEG/pessoaDocumento';

import { getVeiculoDocumentoPendingPagined } from 'core/services/FRO/veiculoDocumento';

import { getSMSContaAutoComplete } from 'core/services/TEL/emailTitulo';

import { getTipoDocumentoPessoaAutoComplete } from 'core/services/SEG/tipoDocumentoPessoa';

import { MaskTypes } from 'ui/Helpers/masks';

import {
  Button,
  CSDSelPage,
  DropdownList,
  GridView,
  DatePicker,
} from 'ui/components';

const columns = [
  { key: 'nrSeqPessoa', type: GridView.ColumnTypes.Checkbox },
  {
    key: 'nrSeqVeiculoDocumento',
    title: 'NrSeqVeiculoDocumento',
    visible: false,
  },
  { key: 'noPessoa', title: 'Nome' },
  { key: 'placa', title: 'Placa' },
  { key: 'tipoDocumentoPessoa.noTipoDocumentoPessoa', title: 'Documento' },
  { key: 'celular', title: 'Celular' },
  { key: 'dtFimValidade', title: 'Validade', format: GridView.DataTypes.Date },
];

const intialState = {
  flgEnvioPorSms: true,
};

const EnviaMensagemDocumentos = ({ isActive, transaction }) => {
  const [filters, setFilters] = useState(intialState);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const [dropDownSMSContaSource, setDropDownSMSContaSource] = useState([]);
  const [dropDownTipoDocumento, setDropDownTipoDocumento] = useState([]);

  const gridView = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const searchSMSConta = async () => {
    const { status, message: msg, emails } = await getSMSContaAutoComplete({});
    setDropDownSMSContaSource(emails);

    onSetMessage(status, msg);
  };

  const searchTipoDocumento = async () => {
    const {
      status,
      message: msg,
      data,
    } = await getTipoDocumentoPessoaAutoComplete();
    setDropDownTipoDocumento(data);

    onSetMessage(status, msg);
  };

  const enviarSms = async () => {
    const param = {
      ...filters,
      nrseqpessoas: gridView.current
        .getCheckBoxValuesAt(0)
        ?.map((row) => row[0].value),
      nrseqveiculosdocumentos: gridView.current
        .getCheckBoxValuesAt(0)
        ?.map((row) => row[1].value),
    };

    if (!filters.tipoDocumentoPessoa.flgVeiculo) {
      param.nrseqveiculosdocumentos = [];
    }

    onSetMessage();
    setLoading(true);

    const { status, message: msg } = await sendSms(param);

    setLoading(false);
    onSetMessage(status, msg);
  };

  useEffect(() => {
    searchSMSConta();
    searchTipoDocumento();
  }, []);

  return (
    <CSDSelPage
      isActive={isActive}
      title='Envia Mensagem Documentos'
      transaction={transaction}
      loading={loading}
      onSearch={async () => {
        onSetMessage();
        setLoading(true);

        if (!filters.tipoDocumentoPessoa) {
          onSetMessage(1, 'Informe o tipo de documento para pesquisa');
        } else if (!filters.dtFimValidade)
          onSetMessage(1, 'Informe a data de validade para a pesquisa');
        else if (filters.tipoDocumentoPessoa.flgVeiculo) {
          const {
            status,
            message: msg,
            data,
            pagination,
          } = await getVeiculoDocumentoPendingPagined(filters);
          if (gridView && gridView.current)
            gridView.current.setDataSource(data, pagination);
          onSetMessage(status, msg);
        } else {
          const {
            status,
            message: msg,
            data,
            pagination,
          } = await getPessoaDocumentoPagined(filters);
          if (gridView && gridView.current)
            gridView.current.setDataSource(data, pagination);
          onSetMessage(status, msg);
        }

        setLoading(false);
      }}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Validade até'
            text={filters.dtFimValidade}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtFimValidade) =>
              setFilters({ ...filters, dtFimValidade })
            }
          />
        </div>

        <div className='col-3'>
          <DropdownList
            label='Tipo Documento'
            dataSource={dropDownTipoDocumento}
            dataSourcePropertyText='noTipoDocumentoPessoa'
            dataSourcePropertyValue='nrSeqTipoDocumentoPessoa'
            selectedItems={filters.tipoDocumentoPessoa ?? []}
            onSelectItem={(item) =>
              setFilters({
                ...filters,
                tipoDocumentoPessoa: item,
                nrSeqTipoDocumentoPessoa: item.nrSeqTipoDocumentoPessoa,
              })
            }
          />
        </div>

        <div className='col-3'>
          <DropdownList
            label='Mensagem SMS'
            dataSource={dropDownSMSContaSource}
            dataSourcePropertyText='codigoTitulo'
            dataSourcePropertyValue='nrSeqEmailTitulo'
            selectedItems={filters.emailcontasms ?? []}
            onSelectItem={(item) =>
              setFilters({
                ...filters,
                emailcontasms: item,
                nrSeqEmailTitulo: item.nrSeqEmailTitulo,
              })
            }
          />
        </div>

        <div className='col-3 mt-3'>
          <Button
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Enviar Mensagem'
            onClick={enviarSms}
          />
        </div>
      </div>

      <div className='row mt-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
};

export default EnviaMensagemDocumentos;
