import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { Autocomplete, Button, GridView, Textbox, Panel } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { Theme } from 'ui/Helpers/utils';

import { getConvenioAutoComplete } from 'core/services/ASS/convenio';
import { getLancamentosPaged } from 'core/services/ASS/lancamentoAssociado';

const RelMensalLancamentosStep = forwardRef(
  ({
    data,
    setData,
    onSetMessage,
    onPrintRelMensalDetalhado,
    onPrintRelMensal,
  }) => {
    const gridViewLancamentos = useRef();

    const [filtrosLancamentoAss, setFiltrosLancamentoAss] = useState({});

    useEffect(() => {
      if (data.filtroBuscaLancamento) {
        setFiltrosLancamentoAss({
          ...data.filtroBuscaLancamento,
          nrSeqPessoaAss: data.nrSeqPessoaAss,
        });
      } else if (data.nrSeqPessoaAss > 0) {
        setFiltrosLancamentoAss({
          ...filtrosLancamentoAss,
          nrSeqPessoaAss: data.nrSeqPessoaAss,
        });
      }
      gridViewLancamentos?.current?.setDataSource(
        data.lancamentosAssociado ?? []
      );
    }, [data.nrSeqPessoaAss]);

    useEffect(() => {
      if (data.filtroBuscaLancamento !== filtrosLancamentoAss)
        setData({ ...data, filtroBuscaLancamento: filtrosLancamentoAss });
    }, [filtrosLancamentoAss]);

    const onSearchLancamentos = async () => {
      const {
        status,
        message: msg,
        data: lancamentos,
        pagination,
      } = await getLancamentosPaged(filtrosLancamentoAss);

      if (lancamentos) {
        if (gridViewLancamentos && gridViewLancamentos.current)
          gridViewLancamentos.current.setDataSource(lancamentos, pagination);
        setData({ ...data, lancamentosAssociado: lancamentos });
      } else {
        gridViewLancamentos?.current?.setDataSource([]);
      }

      if (msg) onSetMessage(status, msg);
    };

    const onSearchConvenio = async (e) => {
      const { convenios } = await getConvenioAutoComplete({
        noConvenio: e,
      });
      return convenios;
    };

    const onPrintDetalhado = async () => {
      if (filtrosLancamentoAss.anoMes)
        await onPrintRelMensalDetalhado(filtrosLancamentoAss);
      else onSetMessage(false, 'Necessário preencher o campo Competência.');
    };

    const onPrintLancamentoMensal = async () => {
      if (filtrosLancamentoAss.anoMes)
        await onPrintRelMensal(filtrosLancamentoAss);
      else onSetMessage(false, 'Necessário preencher o campo Competência.');
    };

    const columnsLancamentos = [
      { key: 'nrSeqConvenioExtrato', type: GridView.ColumnTypes.Checkbox },
      { key: 'convenio.noConvenio', title: 'Convênio/Ativo', sortable: false },
      {
        key: 'anoMes',
        title: 'Mês/Ano',
        sortable: false,
      },
      {
        key: 'dtCadastro',
        title: 'Data Cadastro',
        format: GridView.DataTypes.Date,
        sortable: false,
      },
      { key: 'nome', title: 'Nome', sortable: false },

      {
        key: 'plano',
        title: 'Plano',
        sortable: false,
      },
      {
        key: 'vlrRecalculado',
        title: 'Valor Total',
        format: GridView.DataTypes.Money,
        sortable: false,
      },
      { key: 'noObsImportacao', title: 'Observação', sortable: false },
    ];

    return (
      <div>
        <div className='row mt-3'>
          <Panel>
            <Panel.Header title='Filtros' theme={Theme.Primary} />
            <Panel.Body>
              <div className='row mb-3 algin-items-end'>
                <div className='col-2'>
                  <Textbox
                    label='Competência'
                    maxLength={10}
                    mask={MaskTypes.Competence}
                    text={filtrosLancamentoAss.anoMes}
                    onChangedValue={(anoMes) =>
                      setFiltrosLancamentoAss({
                        ...filtrosLancamentoAss,
                        anoMes,
                      })
                    }
                  />
                </div>

                <div className='col-4'>
                  <Autocomplete
                    label='Convênio'
                    searchDataSource={onSearchConvenio}
                    selectedItem={filtrosLancamentoAss.convenio}
                    onSelectItem={(convenio) =>
                      setFiltrosLancamentoAss({
                        ...filtrosLancamentoAss,
                        convenio,
                        nrSeqConvenio: convenio.nrSeqConvenio,
                      })
                    }
                    dataSourceTextProperty='noConvenio'
                  />
                </div>

                <div className='col mt-3'>
                  <Button
                    onClick={onSearchLancamentos}
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Pesquisar'
                  />
                </div>

                <div className='col mt-3'>
                  <Button
                    onClick={onPrintDetalhado}
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Imprimir Extrato Detalhado'
                  />
                </div>
                <div className='col mt-3'>
                  <Button
                    onClick={onPrintLancamentoMensal}
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Imprimir Rel. Mensal'
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>

        <div className='row mt-3'>
          <div className='col'>
            <GridView
              ref={gridViewLancamentos}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnsLancamentos}
              offlineData
              showPagination={false}
              showSelectSizes={false}
              sumFields
            />
          </div>
        </div>
      </div>
    );
  }
);

export default RelMensalLancamentosStep;
