import { ParcelamentoAparelho } from 'core/models/TEL';
import api from '../api';

const getParcelamentoAparelhoList = async (filters) => {
  const response = await api.get(`/ParcelamentoAparelho/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,

    parcelamentos: value?.map((json) => new ParcelamentoAparelho(json)) ?? [],
  };
};

export { getParcelamentoAparelhoList };
