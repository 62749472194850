import React, { useRef, useState } from 'react';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  CSDSelPage,
  DatePicker,
  GridView,
  Panel,
  Switch,
} from 'ui/components';
import { mask, MaskTypes } from 'ui/Helpers/masks';
import {
  deleteAllExtratoFinanceiro,
  conciliarExtratoFinanceiro,
  getExtratoFinanceiroPagined,
  Print,
} from 'core/services/FIN/extratoFinanceiro';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';
import { setTransaction } from 'core/services/api';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { SearchTituloPagamentoMassivo } from 'core/services/FIN/tituloPagar';
import ModalPagamentoMassivo from '../TituloPagar/ModalPagamentoMassivo';
import ModalQuitarSaldo from '../TituloReceber/modalQuitarSaldo';
import ModalExtrato from '../AcertoMotorista/ModalExtrato';

export default function ExtratoFinanceiro({
  transaction,
  isActive,
  findTransaction,
  onOpenTransaction,
  onOpenReport,
}) {
  const gridView = useRef();
  const [filtros, setFiltros] = useState({ flgExibeAdiantamento: false });
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [modalShow, setModalShow] = useState({});
  const [modalQuitarSaldoShow, setModalQuitarSaldoShow] = useState(false);
  const [modalExtrato, setModalExtrato] = useState(false);

  const search = async (params = null) => {
    setLoading(true);
    const {
      status,
      message: msg,
      data: obj,
    } = await getExtratoFinanceiroPagined(params ?? filtros);

    if (status !== ResponseStatus.Success)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    if (obj !== undefined && obj !== null) {
      obj.forEach((item) => {
        item.flgTituloPagar = item.nrSeqTituloPagar > 0;
        item.flgTituloReceber = item.nrSeqTituloReceber > 0;
      });

      const i = obj.length;

      const date = new Date(filtros.dtFinal);
      const formattedDate = date.toLocaleDateString('pt-BR', {
        timeZone: 'UTC',
      });

      if (filtros.flgExibeAdiantamento) {
        setFiltros({
          ...filtros,
          vlrContaCorrente: obj[i - 1].vlrContaCorrente,
          vlrAdiantamento: obj[i - 1].vlrAdiantamento,
          vlrTotal: obj[i - 1].vlrTotal + obj[i - 1].vlrAdiantamento,
          flgMostraValor: true,
          dtBuscaFinal: formattedDate,
        });
      } else {
        setFiltros({
          ...filtros,
          vlrContaCorrente: obj[i - 1].vlrContaCorrente,
          vlrAdiantamento: obj[i - 1].vlrAdiantamento,
          vlrTotal: obj[i - 1].vlrContaCorrente,
          flgMostraValor: true,
          dtBuscaFinal: formattedDate,
        });
      }

      if (gridView && gridView.current) gridView.current.setDataSource(obj);
    }

    setLoading(false);
  };

  const onClickModalExtrato = async () => {
    if (
      filtros.nrSeqPessoaForCli === null ||
      filtros.nrSeqPessoaForCli === undefined
    ) {
      setMessage({
        theme: Theme.Danger,
        message:
          'Não é possivel gerar um extrato financeiro sem selecionar um fornecedor',
      });
      return;
    }

    setFiltros({ ...filtros, nrSeqPessoaMot: filtros.nrSeqPessoaForCli });

    setModalExtrato(true);
  };

  const onConciliar = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    const listInts = selecteds.map((row) => row[0].value);

    if (selecteds.length > 0) {
      const listaDeObjetos = gridView.current
        ? gridView.current.getDataSource()
        : [];

      const novaLista = listaDeObjetos.filter((obj) =>
        listInts.includes(obj.nrSeqTituloPagarReceber)
      );

      setTransaction(transaction ? transaction.nrSeqTransacao : 0);

      novaLista.forEach((item) => {
        item.nrSeqTransacao = transaction.nrSeqTransacao;
      });

      const { status, message: msg } = await conciliarExtratoFinanceiro(
        novaLista
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a conciliação.',
        theme: Theme.Danger,
      });
    }
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const listaDeObjetos = gridView.current
        ? gridView.current.getDataSource()
        : [];

      const novaLista = listaDeObjetos.filter((obj) =>
        selecteds
          .map((row) => row[0].value)
          .includes(obj.nrSeqTituloPagarReceber)
      );

      const { status, message: msg } = await deleteAllExtratoFinanceiro(
        novaLista
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onPrint = async () => {
    setLoading(true);
    const printItens = await Print(filtros);

    onOpenReport(transaction.noTransacao, printItens);
    setLoading(false);
  };

  const onClickOpenTitulos = async (sourceRow) => {
    if (sourceRow.vlrTitulo > 0) {
      const transactionContaChamada = findTransaction(
        TransacationCodes.TituloPagar
      );

      if (transactionContaChamada) {
        transactionContaChamada.registryKey = sourceRow.nrSeqTituloPagarReceber;

        onOpenTransaction(transactionContaChamada, true);
      }
    } else {
      const transactionContaChamada = findTransaction(
        TransacationCodes.TituloReceber
      );

      if (transactionContaChamada) {
        transactionContaChamada.registryKey = sourceRow.nrSeqTituloPagarReceber;

        onOpenTransaction(transactionContaChamada, true);
      }
    }
  };

  const onOpenPagamento = async (sourceRow) => {
    const { nrSeqTituloPagar } = sourceRow;
    const searchTituloPagar = { nrSeqTituloPagar };
    const titulospagar = await SearchTituloPagamentoMassivo(searchTituloPagar);

    if (titulospagar.data.length === 0) {
      setMessage({
        message: titulospagar.message,
        theme:
          titulospagar.status === ResponseStatus.Success
            ? Theme.Success
            : Theme.Danger,
      });
      return;
    }

    setModalShow({
      ...modalShow,
      modalPagamentos: true,
      pagamentoMassivo: titulospagar.data,
    });
  };

  const onClickQuitarSaldo = (e) => {
    setData(e);
    setModalQuitarSaldoShow(true);
  };

  const columns = [
    { key: 'nrSeqTituloPagarReceber', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    { key: 'tipoDocumento', title: 'TipoDocumento' },
    {
      key: 'historico',
      title: 'Historico',
    },
    {
      key: 'vlrTitulo',
      title: 'Total',
      format: GridView.DataTypes.Decimal,
      colorText: 'noColorVlrTitulo',
    },
    { key: 'vlrSaldoTit', title: 'Saldo', format: GridView.DataTypes.Decimal },
    {
      key: 'vlrContaCorrente',
      title: 'C/C',
      colorText: 'noColorVlrCC',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'nrSeqTituloPagarReceber',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOpenTitulos(e),
      theme: Theme.Info,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Visualizar Titulo',
      tooltipDirection: 'bottom',
      title: '',
    },
    {
      key: 'nrSeqTituloPagar',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onOpenPagamento(e),
      theme: Theme.Primary,
      icon: 'cash-register',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Quitar Titulo Pagar',
      tooltipDirection: 'bottom',
      title: 'Lançar Pag.',
      visibleDynamic: 'flgTituloPagar',
    },
    {
      key: 'nrSeqTituloReceber',
      type: GridView.ColumnTypes.Button,
      title: 'Lançar Rec.',
      onClick: (e) => onClickQuitarSaldo(e),
      theme: Theme.Warning,
      icon: 'cash-register',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Quitar Titulo Receber',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgTituloReceber',
    },
  ];

  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  function RenderTextMask(props) {
    const { label, value, date, masktype } = props;
    return (
      <div className='col-auto mt-3'>
        <div className='fw-bold'>
          {label} {date}: {mask(value ?? 0, masktype ?? MaskTypes.Currency)}
        </div>
      </div>
    );
  }

  const OnSearchFornecedor = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaFors,
    } = await getFornecedorAutoComplete({
      noPessoa: e,
    });
    if (msg) setMessage(status, msg);
    return pessoaFors;
  };
  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Extrato Financeiro'
      loading={loading}
      onSearch={() => search()}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <Panel>
        <Panel.Header
          icon={['fas', 'user']}
          title='Filtros'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col-5 mb-4'>
              <Autocomplete
                label='Fornecedor / Cliente'
                searchDataSource={OnSearchFornecedor}
                selectedItem={filtros.fornecedor}
                onSelectItem={(fornecedor) => {
                  setFiltros({
                    ...filtros,
                    fornecedor,
                    nrSeqPessoaForCli: fornecedor.nrSeqPessoaFor,
                  });
                }}
                dataSourceTextProperty='nomeDocumentoCidadeEstado'
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Data Inicial'
                text={filtros.dtInicial}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtInicial) => setFiltros({ ...filtros, dtInicial })}
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Data Final'
                text={filtros.dtFinal}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtFinal) => setFiltros({ ...filtros, dtFinal })}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>

      <div className='row'>
        <div className='col-sm-12'>
          <div className='alert alert-info fs-8' role='alert'>
            {filtros.flgMostraValor && (
              <div className='row'>
                <div className='col-4 float-right'>
                  <RenderTextMask
                    label='Saldo Até'
                    date={filtros.dtBuscaFinal}
                    value={filtros.vlrContaCorrente}
                  />
                </div>
              </div>
            )}
            <div className='row'>
              <div className='col-6 mb-3 float-right'>
                <RenderTextMask
                  label='Total Adiantamento '
                  value={filtros.vlrAdiantamento}
                />
              </div>

              <div className='col-2 mt-3'>
                <Button
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  icon='plus'
                  text='Executar ação'
                  onClick={onClickModalExtrato}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-6 mb-3 float-right'>
                <RenderTextMask
                  label='Saldo - Adiantamento '
                  value={filtros.vlrTotal}
                />
              </div>

              <div className='col-1 mt-3'>
                <Button
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  icon='plus'
                  text='Conciliar'
                  onClick={onConciliar}
                />
              </div>
              <div className='col-1 mt-3'>
                <Button
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  icon='exclamation-triangle'
                  text='Excluir'
                  onClick={onDelete}
                />
              </div>
              <div className='col-1 mt-3'>
                <Button
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  icon='print'
                  text='Imprimir'
                  onClick={onPrint}
                />
              </div>
              <div className='col-2 mb-3'>
                <Switch
                  formControl
                  label='Exibe Adiantamento'
                  checked={filtros.flgExibeAdiantamento}
                  onChange={(flgExibeAdiantamento) =>
                    setFiltros({ ...filtros, flgExibeAdiantamento })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            enableExcelExport
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            transaction={transaction}
          />
        </div>
      </div>
      <ModalPagamentoMassivo
        show={modalShow.modalPagamentos}
        transaction={transaction}
        pagamentoMassivo={modalShow.pagamentoMassivo}
        onClose={({ message: msg, theme }) => {
          setModalShow({
            ...modalShow,
            modalPagamentos: false,
          });
          setMessage({
            message: msg,
            theme,
          });
        }}
      />
      <ModalQuitarSaldo
        show={modalQuitarSaldoShow}
        transaction={transaction}
        onClose={({ message: msg, theme }) => {
          setModalQuitarSaldoShow(false);
          setMessage({
            message: msg,
            theme,
          });
        }}
        tituloreceber={data ?? {}}
        imprimirRecibo={(msg) => {
          setModalQuitarSaldoShow(false);
          onOpenReport(transaction.noTransacao, msg);
        }}
      />
      <ModalExtrato
        show={modalExtrato}
        dataExtrato={filtros}
        onClose={({ message: msg, theme }) => {
          setModalExtrato(false);
          setMessage({
            message: msg,
            theme,
          });
        }}
      />
    </CSDSelPage>
  );
}
