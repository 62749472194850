import ImportacaoCampo from './importacaoCampo';

export default class Importacao {
  constructor(jsonObject = {}) {
    this.nrSeqImportacao = jsonObject.nrSeqImportacao;
    this.noImportacao = jsonObject.noImportacao;
    this.noTabela = jsonObject.noTabela;
    this.flgAuxiliar = jsonObject.flgAuxiliar;
    this.importacaoCampos = jsonObject.importacaoCampos
      ? jsonObject.importacaoCampos.map(
          (item) => new ImportacaoCampo(item ?? {})
        )
      : [];
  }

  toJson = () => JSON.stringify(this);
}
