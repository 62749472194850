export default class ChipVinculosOperadora {
  constructor(jsonObject = {}) {
    this.nrSeqChipVinculoOperadora = jsonObject.nrSeqChipVinculoOperadora;
    this.nrSeqChip = jsonObject.nrSeqChip;
    this.nrSeqTipoChamadaPlano = jsonObject.nrSeqTipoChamadaPlano;
    this.noPlano = jsonObject.noPlano;
    this.nrSeqTipoChamadaDados = jsonObject.nrSeqTipoChamadaDados;
    this.noPacoteDados = jsonObject.noPacoteDados;
    this.dtCadastro = jsonObject.dtCadastro;
    this.dtInicioVigencia = jsonObject.dtInicioVigencia;
  }

  toJson = () => JSON.stringify(this);
}
