import React, { useState, useEffect, useRef, useCallback } from 'react';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { CSDManutPage, GridView } from 'ui/components';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';

import { getTituloPagarPagined } from 'core/services/FIN/tituloPagar';
import { getTituloReceberPagined } from 'core/services/FIN/tituloReceber';

const columns = [
  {
    key: 'nrSeqExtratoCategoria',
    title: 'nrSeqExtratoCategoria',
    visible: false,
  },
  { key: 'nrSeqCentroDeCusto', title: 'NrSeqCentroDeCusto', visible: false },
  { key: 'nrSeqPlanoDeConta', title: 'nrSeqPlanoDeConta', visible: false },
  {
    key: 'noPlanoDeConta',
    title: 'Plano De Conta',
  },

  { key: 'dataInicial', title: 'dataInicial', visible: false },
  { key: 'dataFinal', title: 'dataFinal', visible: false },
  { key: 'grupoCategoria', title: 'grupoCategoria', visible: false },
  {
    key: 'categoria',
    title: 'Categoria',
  },
  { key: 'vlrTotalLista', title: 'Valor', format: GridView.DataTypes.Money },
];

export default function FornecedorItem({
  registryKey,
  reload,
  isActive,
  transaction,
  findTransaction,
  onOpenTransaction,
}) {
  const gridView = useRef();
  const gridViewItem = useRef();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [titleModal, setTitleModal] = useState(null);

  const [data, setData] = useState();
  const [filtersSelecao, setFiltersSelecao] = useState();
  const [subTable, setSubTable] = useState({ gridType: '' });

  const formSubmit = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const formatarDatas = (dtInicio, dtFim) => {
    const dtInicioArr = dtInicio.split('-');
    const dataInicial = `${dtInicioArr[2]}/${dtInicioArr[1]}/${dtInicioArr[0]}`;

    const dtFimArr = dtFim.split('-');
    const dataFinal = `${dtFimArr[2]}/${dtFimArr[1]}/${dtFimArr[0]}`;

    return `${dataInicial} a ${dataFinal}`;
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);

      setData(registryKey.retorno);
      setFiltersSelecao(registryKey.filtersSelecao);

      const periodo = formatarDatas(
        registryKey.filtersSelecao.dataInicial,
        registryKey.filtersSelecao.dataFinal
      );
      setTitleModal(
        registryKey?.filtersSelecao
          ? `${registryKey?.filtersSelecao.grupoCategoria} - ${registryKey?.filtersSelecao.categoria} - Período de ${periodo}`
          : ''
      );

      if (registryKey?.filtersSelecao.grupoCategoria === 'RECEITA') {
        setSubTable({ gridType: 'receita' });
      }

      console.log(filtersSelecao);

      gridView?.current?.setDataSource(registryKey.retorno ?? []);
      gridViewItem?.current?.setDataSource([]);

      setLoading(false);
    } else {
      setData();
      setFiltersSelecao();
      setTitleModal('');
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const onClickVerDespesa = async (datasource) => {
    setLoading(true);
    const transactionToOpen = findTransaction(TransacationCodes.TituloPagar);

    if (transactionToOpen) {
      transactionToOpen.registryKey = datasource.nrSeqTituloPagar;
      setLoading(false);
      onOpenTransaction(transactionToOpen, true);
    } else {
      setLoading(false);
      onSetMessage(
        ResponseStatus.Error,
        `Não foi possível abrir o Titulo a Pagar cód.: ${datasource.cdTituloPagar}.`
      );
    }
  };

  const onClickVerReceita = async (datasource) => {
    setLoading(true);
    const transactionToOpen = findTransaction(TransacationCodes.TituloReceber);

    if (transactionToOpen) {
      transactionToOpen.registryKey = datasource.nrSeqTituloReceber;
      setLoading(false);
      onOpenTransaction(transactionToOpen, true);
    } else {
      setLoading(false);
      onSetMessage(
        ResponseStatus.Error,
        `Não foi possível abrir o Titulo a Receber cód.: ${datasource.cdTituloReceber}.`
      );
    }
  };

  const subTableColumnsDespesa = [
    { key: 'cdTituloPagar', title: 'Cód.' },
    {
      key: 'nrTit',
      title: 'Nr. Tit.',
    },
    { key: 'fornecedor.noFornecedor', title: 'Fornecedor' },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma de Pagamento',
    },
    {
      key: 'vlrPago',
      title: 'Valor Total do Título',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'nrSeqTituloPagar',
      title: 'Ver',
      type: GridView.ColumnTypes.Button,
      onClick: (datasource) => onClickVerDespesa(datasource),
      theme: Theme.Warning,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Ver Despesa',
      tooltipDirection: 'bottom',
    },
  ];

  const subTableColumnsReceita = [
    { key: 'nrSeqTituloReceber', title: 'Cód.' },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma de Pagamento',
    },
    {
      key: 'vlrRecebido',
      title: 'Valor Total do Título',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'nrSeqTituloReceber',
      title: 'Ver',
      type: GridView.ColumnTypes.Button,
      onClick: (datasource) => onClickVerReceita(datasource),
      theme: Theme.Warning,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Ver Receita',
      tooltipDirection: 'bottom',
    },
  ];

  const onRowDoubleClick = async (sourceRow) => {
    setLoading(true);

    console.log(data);

    if (sourceRow[0].value !== null && sourceRow[0].value !== undefined) {
      const dataInicial = sourceRow[4].value.slice(0, 10);
      const dataFinal = sourceRow[5].value.slice(0, 10);

      if (sourceRow[6].value === 'DESPESA') {
        const obj = {
          nrSeqTipoDespesa: sourceRow[0].value,
          // nrSeqCentroDeCusto: sourceRow[1].value,
          nrSeqPlanoDeConta: sourceRow[2].value,
          pagamentoDe: dataInicial,
          pagamentoAte: dataFinal,
        };

        const response = await getTituloPagarPagined(obj);

        if (response.status === ResponseStatus.Error) {
          onSetMessage(ResponseStatus.Error, 'Erro ao buscar Títulos a Pagar.');

          return setLoading(false);
        }

        setSubTable({ gridType: 'despesa' });

        if (gridViewItem?.current) {
          gridViewItem.current.setDataSource(response.data);
        }

        return setLoading(false);
      }

      const obj = {
        nrSeqTituloEspecie: sourceRow[0].value,
        nrSeqPlanoConta: sourceRow[2].value,
        dtRecebimentoDe: dataInicial,
        dtRecebimentoAte: dataFinal,
      };

      const response = await getTituloReceberPagined(obj);

      if (response.status === ResponseStatus.Error) {
        onSetMessage(ResponseStatus.Error, 'Erro ao buscar Títulos a Receber.');

        return setLoading(false);
      }

      setSubTable({ gridType: 'receita' });

      if (gridViewItem?.current) {
        gridViewItem.current.setDataSource(response.data);
      }
    }
    return setLoading(false);
  };

  return (
    <CSDManutPage
      isActive={isActive}
      title={titleModal}
      loading={loading}
      message={message}
      ref={formSubmit}
      transaction={transaction}
    >
      {subTable?.gridType === 'despesa' && (
        <div className='row mt-2'>
          <div className='col'>
            <GridView
              ref={gridViewItem}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={subTableColumnsDespesa}
              offlineData
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>
      )}
      {subTable?.gridType === 'receita' && (
        <div className='row mt-2'>
          <div className='col'>
            <GridView
              ref={gridViewItem}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={subTableColumnsReceita}
              offlineData
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>
      )}

      <div className='row mt-2'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            offlineData
            showPagination={false}
            showSelectSizes={false}
            sumFields
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
