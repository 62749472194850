import React, { useEffect, useState } from 'react';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { Button, Modal, Textbox, Autocomplete, Loader } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getTipoRedeAutoComplete,
  getTipoVoltagemAutoComplete,
} from 'core/services/POC/tipoBomba';
import { getPocCaboAutoComplete } from 'core/services/POC/pocCabo';
import { getMetricConversion } from 'core/services/POC/pocPocoPerfuracao';

import {
  getPocPocoPocEnsaio,
  savePocPocoPocEnsaio,
} from 'core/services/POC/pocPocoPocEnsaio';

export default function ModalEquipamento({ show, onClose, nrSeqPocEnsaio }) {
  const [dataEquipamento, setDataEquipamento] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    const equipamento = await getPocPocoPocEnsaio(nrSeqPocEnsaio);

    const { tiposvoltagem } = await getTipoVoltagemAutoComplete({});
    const { tiposrede } = await getTipoRedeAutoComplete({});
    const { cabos } = await getPocCaboAutoComplete();

    const rede = tiposrede.filter(
      (p) => p.noTipoRede === equipamento.noRedeBomba
    );
    const voltagem = tiposvoltagem.filter(
      (p) => p.nrTipoVoltagem === equipamento.nrVoltagemBomba
    );
    const caboselecionado = cabos.filter(
      (p) => p.noDescricao?.trim() === equipamento.noCaboEletrico?.trim()
    );

    const tipoRedeItem = { ...rede[0] };
    const tipoVoltagemItem = { ...voltagem[0] };
    const caboEletricoItem = { ...caboselecionado[0] };

    setDataEquipamento({
      ...equipamento,
      tiposrede: tipoRedeItem,
      tiposvoltagem: tipoVoltagemItem,
      cabo: caboEletricoItem,
    });
    setLoading(false);
  }, [nrSeqPocEnsaio]);

  const onSearchTipoVoltagem = async () => {
    const { tiposvoltagem } = await getTipoVoltagemAutoComplete({});
    const tipoVoltagemItem = {
      noTipoVoltagem: tiposvoltagem[0].noTipoVoltagem,
      cdTipoVoltagem: tiposvoltagem[0].cdTipoVoltagem,
    };
    setDataEquipamento({ ...dataEquipamento, tiposvoltagem: tipoVoltagemItem });
    return tiposvoltagem;
  };

  const onSearchCabos = async (e) => {
    const { cabos } = await getPocCaboAutoComplete({
      noDescricao: e,
    });
    return cabos;
  };

  const onSearchTipoRede = async () => {
    const { tiposrede } = await getTipoRedeAutoComplete({});
    const tipoRedeItem = {
      noTipoRede: tiposrede[0].noTipoRede,
      nrSeqTipoRede: tiposrede[0].nrSeqTipoRede,
    };
    setDataEquipamento({ ...dataEquipamento, tiposrede: tipoRedeItem });
    return tiposrede;
  };

  const onSave = async () => {
    const resp = await savePocPocoPocEnsaio(dataEquipamento);
    if (resp) {
      onClose();
    }
  };

  return (
    <Modal
      show={show}
      title='Adicionar Equipamento'
      size={BootstrapSizes.ExtraLarge}
      onClose={onClose}
    >
      <Modal.Body>
        <Loader loading={loading} />
        <div className='row'>
          <div className='row mb-3'>
            <div className='col'>
              <Textbox
                maxLength={100}
                label='Tipo Bomba'
                text={dataEquipamento.noTipoBomba}
                onChangedValue={(noTipoBomba) =>
                  setDataEquipamento({
                    ...dataEquipamento,
                    noTipoBomba,
                  })
                }
              />
            </div>
            <div className='col'>
              <Textbox
                maxLength={100}
                label='Marca'
                text={dataEquipamento.noMarcaBomba}
                onChangedValue={(noMarcaBomba) =>
                  setDataEquipamento({
                    ...dataEquipamento,
                    noMarcaBomba,
                  })
                }
              />
            </div>
            <div className='col'>
              <Textbox
                maxLength={100}
                label='Modelo'
                text={dataEquipamento.noModeloBomba}
                onChangedValue={(noModeloBomba) =>
                  setDataEquipamento({
                    ...dataEquipamento,
                    noModeloBomba,
                  })
                }
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              <Textbox
                maxLength={10}
                label='Diâmetro (pol)'
                text={dataEquipamento.noDiametroBomba}
                onChangedValue={(noDiametroBomba) =>
                  setDataEquipamento({
                    ...dataEquipamento,
                    noDiametroBomba,
                  })
                }
              />
            </div>
            <div className='col'>
              <Textbox
                label='Potência (hp)'
                text={dataEquipamento.nrPotenciaBomba}
                mask={MaskTypes.DecimalCustom}
                decimalPlaces={2}
                onChangedValue={(nrPotenciaBomba) =>
                  setDataEquipamento({
                    ...dataEquipamento,
                    nrConsumoBomba: nrPotenciaBomba * 0.746,
                    nrPotenciaBomba,
                  })
                }
              />
            </div>
            <div className='col'>
              <Textbox
                label='Estágios'
                text={dataEquipamento.nrEstagiosBomba}
                mask={MaskTypes.Integer}
                maxLength={3}
                onChangedValue={(nrEstagiosBomba) =>
                  setDataEquipamento({
                    ...dataEquipamento,
                    nrEstagiosBomba,
                  })
                }
              />
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col'>
              <Autocomplete
                label='Voltagem (V)'
                searchDataSource={onSearchTipoVoltagem}
                selectedItem={dataEquipamento.tiposvoltagem}
                onSelectItem={(tiposvoltagem) =>
                  setDataEquipamento({
                    ...dataEquipamento,
                    tiposvoltagem,
                    nrVoltagemBomba: Number(tiposvoltagem.noTipoVoltagem),
                  })
                }
                dataSourceTextProperty='noTipoVoltagem'
              />
            </div>
            <div className='col'>
              <Textbox
                label='Consumo (KW)'
                text={dataEquipamento.nrConsumoBomba}
                mask={MaskTypes.DecimalCustom}
                decimalPlaces={3}
                onChangedValue={(nrConsumoBomba) =>
                  setDataEquipamento({
                    ...dataEquipamento,
                    nrConsumoBomba,
                  })
                }
              />
            </div>
            <div className='col'>
              <Autocomplete
                label='Rede'
                searchDataSource={onSearchTipoRede}
                selectedItem={dataEquipamento.tiposrede}
                onSelectItem={(tiposrede) =>
                  setDataEquipamento({
                    ...dataEquipamento,
                    tiposrede,
                    noRedeBomba: tiposrede.noTipoRede,
                  })
                }
                dataSourceTextProperty='noTipoRede'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              <Textbox
                label='Profundidade (mts)'
                text={dataEquipamento.nrProfundidadeInst}
                mask={MaskTypes.Integer}
                onChangedValue={(nrProfundidadeInst) =>
                  setDataEquipamento({
                    ...dataEquipamento,
                    nrProfundidadeInst,
                  })
                }
              />
            </div>
            <div className='col'>
              <Autocomplete
                label='Cabo Elétrico (mm)'
                searchDataSource={onSearchCabos}
                selectedItem={dataEquipamento.cabo}
                onSelectItem={(cabo) => {
                  setDataEquipamento({
                    ...dataEquipamento,
                    cabo,
                    noCaboEletrico: cabo.noDescricao,
                  });
                }}
                dataSourceTextProperty='noDescricao'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-3'>
              <Textbox
                label='Adução Diâmetro Pol'
                text={dataEquipamento.noDiametroAducaopol}
                onChangedValue={(noDiametroAducaopol) =>
                  setDataEquipamento({
                    ...dataEquipamento,
                    noDiametroAducaopol,
                  })
                }
                onBlur={async (param1, param2, param3) => {
                  const obj = {
                    diametropol: param2,
                    param1,
                    param3,
                  };
                  const { value } = await getMetricConversion(obj.diametropol);
                  setDataEquipamento({
                    ...dataEquipamento,
                    nrDiametromm: value,
                  });
                }}
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='Diâmetro mm'
                text={dataEquipamento.nrDiametromm}
                readOnly
              />
            </div>
            <div className='col'>
              <Textbox
                label='Tubulação Adução'
                text={dataEquipamento.noTubulacaoAducao}
                onChangedValue={(noTubulacaoAducao) =>
                  setDataEquipamento({
                    ...dataEquipamento,
                    noTubulacaoAducao,
                  })
                }
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Salvar'
          icon='check'
          onClick={onSave}
          theme={Theme.Success}
        />
        <Button
          text='Fechar'
          icon='ban'
          theme={Theme.Danger}
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
