import qs from 'qs';
import Pagination from 'core/models/pagination';
import { PocOrcamentoStatus } from 'core/models/POC';
import api from '../api';

const baseURL = `/POC/PocOrcamentoStatus`;

export const getPocOrcamentoStatusAutoComplete = async (filters: any) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new PocOrcamentoStatus(json)) ?? [],
  };
};

export const getPocOrcamentoStatusList = async (filters: any) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new PocOrcamentoStatus(json)) ?? [],
  };
};

export const getPocOrcamentoStatusPagined = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new PocOrcamentoStatus(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocOrcamentoStatus = async (id: number) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocOrcamentoStatus(value) : {};
};

export const savePocOrcamentoStatus = async (data: PocOrcamentoStatus) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePocOrcamentoStatus = async (id: number) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPocOrcamentoStatus = async (filters: any) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocOrcamentoStatus = async (filters: any) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocOrcamentoStatus = async (ids: Array<number>) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
