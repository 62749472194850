import React, { useRef, useState, useEffect } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  CSDSelPage,
  GridView,
  Autocomplete,
  DatePicker,
  Panel,
  Textbox,
  RadioButton,
  ToolbarButtons,
} from 'ui/components';
import {
  getOrdemCompraPagined,
  deleteAllOrdemCompra,
  printOrdemCompraAgrupado,
  printOrdemCompraDetalhado,
} from 'core/services/COP/ordemCompra';
import { getProdutoAgrupadorAutoComplete } from 'core/services/EST/produtoAgrupador';
import {
  getEmpresaAutoComplete,
  getEmpresaDefaultUsuario,
} from 'core/services/SEG/empresa';
import { getPedidoCompraTipoDespesaDefault } from 'core/services/VEN/pedidoCompra';
import ModalPedidoCompra from './modalPedidoCompra';

const btnStatusOrdemCompra = [
  {
    text: 'Todos',
    value: 'Todos',
  },
  {
    text: 'Pendentes',
    value: 'Pendente',
  },
  {
    text: 'Canceladas',
    value: 'Cancelado',
  },
];

export default function OrdemCompra({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({ statusOrdemCompra: 'Todos' });
  const [autoSearch, setAutoSearch] = useState(false);
  const [dataPedidoCompra, setDataPedidoCompra] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const columns = [
    { key: 'nrSeqOrdemCompra', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'dtPedido',
      title: 'Data Pedido',
      format: GridView.DataTypes.Date,
    },
    { key: 'nrSeqOrdemCompra', title: 'Cód. Ordem Compra' },
    { key: 'pessoaSol.noPessoa', title: 'Solicitante', sortable: false },
    {
      key: 'ordemItensDescricao',
      title: 'Produtos',
      sortable: false,
    },
    {
      key: 'ordemItensPlacas',
      title: 'Placas',
      sortable: false,
      visible: false,
      type: GridView.ColumnTypes.GroupColumn,
    },
    {
      key: 'ordemItensObservacoes',
      title: 'Observações Produtos',
      sortable: false,
      visible: false,
      type: GridView.ColumnTypes.GroupColumn,
    },
    {
      key: 'statusOrdemCompra',
      title: 'Status',
      colorText: 'noCorStatus',
      sortable: false,
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  function breackLineValuecell(text, col, truncate = false, maxLength = 40) {
    return text.split(';').map((curs, idx) => (
      <div key={`${col}-${idx.toString}`}>
        <p>
          {truncate && curs.length > maxLength
            ? `${curs.slice(0, maxLength)} …`
            : curs}
        </p>
      </div>
    ));
  }

  const search = async (params = null) => {
    setLoading(true);

    const obj = { ...filtros };
    if (obj.produto) {
      obj.produto = {};
    }

    const {
      status,
      message: msg,
      data,
      pagination,
    } = await getOrdemCompraPagined(params ?? obj);

    if (status === ResponseStatus.Success && data.length > 0) {
      data.forEach((item) => {
        let descricao = '';
        let placas = '';
        let obss = '';

        item.ordensCompra.forEach((e, index) => {
          descricao += `${index + 1} - ${e.produto.noProduto} - ${
            e.produto?.produtoMarca?.noProdutoMarca ?? '-'
          };`;

          placas += e.nrSeqVeiculo ? `${index + 1} - ${e.veiculo.placa};` : `;`;

          obss += e.obsProduto ? `${index + 1} - ${e.obsProduto};` : `-;`;
        });

        item.ordemItensDescricao = breackLineValuecell(
          descricao,
          'ordemItensDescricao',
          true,
          120
        );
        item.ordemItensPlacas = breackLineValuecell(placas, 'ordemItensPlacas');
        item.ordemItensObservacoes = breackLineValuecell(
          obss,
          'ordemItensObservacoes',
          true,
          20
        );
      });
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    onSetMessage(status, msg);
    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'OrdemCompra/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqOrdemCompra = sourceRow.find(
      (e) => e.key === 'nrSeqOrdemCompra'
    );
    onOpenMaintenance(nrSeqOrdemCompra.value);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllOrdemCompra(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchProdutoAgrupador = async (e) => {
    const {
      status,
      message: msg,
      data: produtoAgrupador,
    } = await getProdutoAgrupadorAutoComplete({ noProduto: e, flgAtivo: true });
    if (msg) onSetMessage(status, msg);
    return produtoAgrupador;
  };

  const onSearchUnidade = async (e) => {
    const {
      status,
      message: msg,
      empresas: empresa,
    } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresa;
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      if (autoSearch) {
        await search({ ...filtros, statusOrdemCompra: 'Pendente' });
        setFiltros({ ...filtros, statusOrdemCompra: 'Pendente' });
      }
    }, 300000);
    return () => clearInterval(interval);
  }, [autoSearch]);

  const onChangeAutoSearch = () => {
    setAutoSearch(!autoSearch);
  };

  const onNewPedidoCompra = async () => {
    const selecteds = gridView?.current?.getCheckBoxValuesAt(0) ?? [];

    if (selecteds.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        `Necessário selecionar ao menos um item para gerar o pedido de compra.`
      );
      return;
    }

    const objS = {};

    const ordensSelecionadas = [];
    const ordensAnexos = [];
    const ordensTodosItens = [];

    //  captura as ordens de compra selecionadas já filtrando as que estão pendentes e parcialmente pendentes
    selecteds.forEach((item) => {
      const dataItem = gridView.current
        .getDataSource()
        .find(
          (x) =>
            x.nrSeqOrdemCompra === item[0].value &&
            x.statusOrdemCompra !== ('Efetivado' || 'Cancelado')
        );
      if (dataItem) {
        ordensSelecionadas.push(dataItem);
        const arrProdutosPendentes = dataItem.ordensCompra.filter(
          (e) => e.nrSeqPedidoCompra === null
        );
        //  e agrupa todos os itens das ordens em um único array
        ordensTodosItens.push(...arrProdutosPendentes);

        //  captura todos os anexos das ordens de compra selecionadas
        if (dataItem.documentos !== null) {
          ordensAnexos.push(...dataItem.documentos);
        }
      }
    });

    if (ordensSelecionadas.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        `As solicitações de compra selecionadas já estão efetivadas.`
      );
      return;
    }

    const produtosPendentesAgrupados = [];
    let valorTotalPedido = 0;

    //  agrupa os produtos pendentes em um único array
    ordensTodosItens.forEach((item, i) => {
      const index = produtosPendentesAgrupados.findIndex(
        (p) =>
          p.nrSeqProduto === item.nrSeqProduto &&
          p.nrSeqVeiculo === item.nrSeqVeiculo
      );

      // se já existe o produto no array, soma a quantidade, o valor total confere placa e observação
      if (index > -1) {
        produtosPendentesAgrupados[index].quantidade += item.quantidade;
        produtosPendentesAgrupados[index].vlrUnitario =
          produtosPendentesAgrupados[index].vlrUnitario >= item.vlrUnitario
            ? produtosPendentesAgrupados[index].vlrUnitario
            : item.vlrUnitario;
        produtosPendentesAgrupados[index].vlrTotal =
          produtosPendentesAgrupados[index].vlrUnitario *
          produtosPendentesAgrupados[index].quantidade;

        produtosPendentesAgrupados[index].vlrTotalItem +=
          item.vlrUnitario * item.quantidade;
        produtosPendentesAgrupados[index].vlrSaldoQuantidade +=
          item.saldoProduto;

        valorTotalPedido += produtosPendentesAgrupados[index].vlrTotal;

        //  confere se já tem veiculo vinculado a um produto, se tem mantem, se não tem, vincula o veiculo do item;
        if (
          produtosPendentesAgrupados[index].nrSeqVeiculo === null &&
          item.nrSeqVeiculo !== null
        ) {
          produtosPendentesAgrupados[index].nrSeqVeiculo = item.nrSeqVeiculo;
          produtosPendentesAgrupados[index].veiculo = item.veiculo;
        }
        // adiciona ou concatena informações do mesmo produto;
        if (
          produtosPendentesAgrupados[index].noObservacao === null &&
          item.obsProduto !== null
        ) {
          produtosPendentesAgrupados[index].noObservacao = item.obsProduto;
        } else if (
          produtosPendentesAgrupados[index].noObservacao !== null &&
          item.obsProduto !== null
        ) {
          produtosPendentesAgrupados[
            index
          ].noObservacao += ` - ${item.obsProduto}`;
        }
      } else {
        valorTotalPedido += item.vlrUnitario * item.quantidade;
        produtosPendentesAgrupados.push({
          nrSeqPedidoCompra: 0,
          produto: item.produto,
          produtoMarca: item.produto?.produtoMarca || {},
          nrSeqProduto: item.nrSeqProduto,
          nrSeqProdutoMarca:
            item.produto.produtoMarca?.nrSeqProdutoMarca || null,
          quantidade: item.quantidade,
          vlrUnitario: item.vlrUnitario,
          vlrTotalItem: item.vlrUnitario * item.quantidade,
          noObservacao: item.obsProduto,
          vlrSaldoQuantidade: item.saldoProduto || 0,
          nrSeqVeiculo: item.nrSeqVeiculo || null,
          veiculo: item.veiculo?.nrSeqVeiculo ? item.veiculo : {},
          vlrDesconto: 0,
          vlrFrete: 0,
          vlrIPI: 0,
          vlrIPIPorcentagem: 0,
          nrOrdem: i + 1,
        });
      }
    });

    const anexos = [];

    //  confere se tem anexos para os produtos selecionados
    if (ordensAnexos.length > 0) {
      ordensAnexos.forEach((doc) => {
        const index = produtosPendentesAgrupados.findIndex(
          (p) => p.nrSeqProduto === doc.nrSeqProduto
        );

        if (index > -1) {
          anexos.push({
            noArquivo: doc.noArquivo,
            noTipoArquivo: doc.noTipoArquivo,
            noTamanho: doc.noTamanho,
            noObservacao: doc.produto.noProduto,
            noImageBase64: doc.noImagem,
            nrSeqPedidoCompra: 0,
            nrSeqPedidoCompraDocumento: 0,
            status: GridView.EnumStatus.Inserir,
          });
        }
      });
    }

    const { empresas } = await getEmpresaDefaultUsuario({});
    objS.ordensSelecionadas = ordensSelecionadas;

    objS.informacoesPedido = {
      nrSeqPedidoCompra: 0,
      empresa: empresas[0] || {},
      nrSeqGrupoEmpresa: ordensSelecionadas[0]?.nrSeqGrupoEmpresa,
      nrSeqEmpresa:
        empresas[0]?.nrSeqEmpresa || ordensSelecionadas[0].nrSeqEmpresa,
      pessoaSol: ordensSelecionadas[0].pessoaSol,
      nrSeqPessoaSol: ordensSelecionadas[0].nrSeqPessoaSol,
      comprador: ordensSelecionadas[0].pessoaCop,
      nrSeqPessoaCop: ordensSelecionadas[0].nrSeqPessoaCop,
      pedidoCompraItens: produtosPendentesAgrupados,
      documentos: anexos,
      vlrFretePedido: 0,
      vlrPedido: valorTotalPedido,
    };

    //  #region Tipo Despesa Default
    const tipoDespesaDefault = await getPedidoCompraTipoDespesaDefault();

    if (tipoDespesaDefault.tipoDespesa?.nrSeqTipoDespesa > 0) {
      objS.informacoesPedido.tipoDespesa = tipoDespesaDefault.tipoDespesa;
      objS.informacoesPedido.nrSeqTipoDespesa =
        tipoDespesaDefault.tipoDespesa?.nrSeqTipoDespesa;
    }
    //  #endregion

    setDataPedidoCompra(objS);
    setModalShow(true);
  };

  const onPrint = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      setLoading(true);
      const { value } = await printOrdemCompraAgrupado(
        selecteds.map((row) => row[0].value)
      );

      if (value) {
        onOpenReport(transaction.noTransacao, value);
      } else {
        onSetMessage(false, 'Erro ao Imprimir Relatório');
      }
      setLoading(false);
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a impressão.',
        theme: Theme.Warning,
      });
    }
  };
  const onPrintDetalhado = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      setLoading(true);
      const { value } = await printOrdemCompraDetalhado(
        selecteds.map((row) => row[0].value)
      );

      if (value) {
        onOpenReport(transaction.noTransacao, value);
      } else {
        onSetMessage(false, 'Erro ao Imprimir Relatório');
      }
      setLoading(false);
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a impressão.',
        theme: Theme.Warning,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção Ordem de Compra'
      loading={loading}
      onSearch={() => {
        search();
        setAutoSearch(false);
      }}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.ButtonList
          onPrintFornecedorAgrupado
          icon='caret-down'
          items={[
            {
              text: 'Ordem de Compra Detalhado',
              onClick: () => onPrintDetalhado('Agrupado'),
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Gerar Pedido de compra'
          icon='plus'
          onClick={onNewPedidoCompra}
        />
      </ToolbarButtons>
      <ToolbarButtons>
        <ToolbarButtons.Button
          text={`${autoSearch ? 'Desabilitar' : 'Habilitar'} Busca Automática`}
          icon='search-plus'
          theme={autoSearch ? Theme.Primary : Theme.Inactive}
          onClick={onChangeAutoSearch}
        />
      </ToolbarButtons>
      <Panel>
        <div className='row'>
          <div className='col-12 mb-4'>
            <Panel.Header
              icon={['fas', 'user']}
              title='Filtros'
              theme={Theme.Primary}
            />
          </div>
        </div>
        <Panel.Body>
          <div className='row'>
            <div className='col-4 mb-4'>
              <Autocomplete
                label='Unidade'
                searchDataSource={onSearchUnidade}
                selectedItem={filtros.empresa}
                onSelectItem={(empresa) => {
                  setFiltros({
                    ...filtros,
                    empresa,
                    nrSeqEmpresa: empresa.nrSeqEmpresa,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>

            <div className='col-4'>
              <div className='row'>
                <div className='col-6 mb-4'>
                  <DatePicker
                    label='Data Inicial'
                    text={filtros.dataInicial}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dataInicial) =>
                      setFiltros({ ...filtros, dataInicial })
                    }
                  />
                </div>
                <div className='col-6 mb-4'>
                  <DatePicker
                    label='Data Final'
                    text={filtros.dataFinal}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dataFinal) =>
                      setFiltros({ ...filtros, dataFinal })
                    }
                  />
                </div>
              </div>
            </div>

            <div className='col-2 mb-4'>
              <Textbox
                label='Nr. Ordem'
                maxLength={10}
                text={filtros.nrSeqOrdemCompra}
                mask={MaskTypes.Integer}
                onChangedValue={(nrSeqOrdemCompra) =>
                  setFiltros({ ...filtros, nrSeqOrdemCompra })
                }
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-4 mb-4'>
              <Autocomplete
                label='Produto'
                searchDataSource={onSearchProdutoAgrupador}
                selectedItem={filtros.produto}
                onSelectItem={(produto) => {
                  setFiltros({
                    ...filtros,
                    produto,
                    nrSeqProdutoAgrupador: produto.nrSeqProdutoAgrupador,
                  });
                }}
                dataSourceTextProperty='noProduto'
              />
            </div>

            <div className='col-2 mb-4'>
              <Textbox
                label='Pedido Compra'
                text={filtros.nrSeqPedidoCompra}
                mask={MaskTypes.Integer}
                onChangedValue={(nrSeqPedidoCompra) =>
                  setFiltros({ ...filtros, nrSeqPedidoCompra })
                }
              />
            </div>

            <div className='col-4 mb-4'>
              <RadioButton
                label='Tipo Status Ordem Compra'
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={filtros.statusOrdemCompra}
                buttons={btnStatusOrdemCompra}
                onChange={(statusOrdemCompra) => {
                  setFiltros({
                    ...filtros,
                    statusOrdemCompra,
                  });
                }}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>

      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            enableExcelExport
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            canControlVisibility
            transaction={transaction}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <ModalPedidoCompra
            data={dataPedidoCompra}
            show={modalShow}
            onSave={(msg, status) => {
              setDataPedidoCompra(null);
              onSetMessage(status, msg);
            }}
            onClose={() => {
              setModalShow(false);
            }}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
