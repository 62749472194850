export default class LimiteCredito {
  constructor(jsonObject = {}) {
    this.cdLimiteCredito = jsonObject.cdLimiteCredito;
    this.vlrIni = jsonObject.vlrIni;
    this.vlrFim = jsonObject.vlrFim;
    this.noLimiteCredito = jsonObject.noLimiteCredito;
    this.nrSeqLimiteCredito = jsonObject.nrSeqLimiteCredito;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
