import api from '../api';

const baseURL = `/FIN/GerenciarIntegracaoBancaria`;

export const BuscaCartaoTarget = async (data) => {
  const response = await api.post(`${baseURL}/BuscaTitulosCartaoTarget`, data);
  return response.data ?? {};
};

export const GerarDespesaTarget = async (data) => {
  const response = await api.post(`${baseURL}/GerarDespesaCartaoTarget`, data);
  return response.data ?? {};
};

export const getGerenciarIntegracaoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const printDetalhamento = async (id) => {
  const response = await api.get(`${baseURL}/PrintDetalhamento`, {
    params: { nrSeqArquivoBancario: id },
  });
  return response.data ?? {};
};

export const excelDetalhamento = async (id) => {
  const response = await api.get(`${baseURL}/ExcelDetalhamento`, {
    params: { nrSeqArquivoBancario: id },
  });
  return response.data ?? {};
};
