export default class PocPocoDadosHidraulicos {
  constructor(jsonObject = {}) {
    this.nrSeqPocPocoDadosHidraulicos = jsonObject.nrSeqPocPocoDadosHidraulicos;
    this.dtEnsaioComp = jsonObject.dtEnsaioComp;
    this.nrVazaoEnsaioComp = jsonObject.nrVazaoEnsaioComp;
    this.nrNivelEstaticoComp = jsonObject.nrNivelEstaticoComp;
    this.dtEnsaioBomba = jsonObject.dtEnsaioBomba;
    this.nrVazaoEnsaioBomba = jsonObject.nrVazaoEnsaioBomba;
    this.nrNivelEstaticoBomba = jsonObject.nrNivelEstaticoBomba;
    this.nrNivelDinamicoBomba = jsonObject.nrNivelDinamicoBomba;
    this.nrCapacidadeCampo = jsonObject.nrCapacidadeCampo;
    this.nrTransmissividade = jsonObject.nrTransmissividade;
    this.noMetodoRecuperacao = jsonObject.noMetodoRecuperacao;
    this.nrCapacidadeCalculada = jsonObject.nrCapacidadeCalculada;
    this.nrVazaoRecomendada = jsonObject.nrVazaoRecomendada;
    this.nrNivelRecomendado = jsonObject.nrNivelRecomendado;
    this.nrRegimeBombeamento = jsonObject.nrRegimeBombeamento;
    this.nrBombeio = jsonObject.nrBombeio;
    this.nrDescanso = jsonObject.nrDescanso;
    this.flgBombeamentoRecomendado = jsonObject.flgBombeamentoRecomendado;
    this.nrRegimeBombeamentoStr = jsonObject.nrRegimeBombeamentoStr;
    this.nrBombeioStr = jsonObject.nrBombeioStr;
    this.nrDescansoStr = jsonObject.nrDescansoStr;
    this.nrVazaoBombeamento = jsonObject.nrVazaoBombeamento;
    this.nrNivelDinamicoBombeamento = jsonObject.nrNivelDinamicoBombeamento;
    this.nrProducaoBombeamento = jsonObject.nrProducaoBombeamento;
    this.nrSeqPocPoco = jsonObject.nrSeqPocPoco;
    this.nrSeqPocEnsaio = jsonObject.nrSeqPocEnsaio;

    this.nrCapacidadeCampoStr = jsonObject.nrCapacidadeCampoStr;
    this.nrTransmissividadeStr = jsonObject.nrTransmissividadeStr;
    this.nrCapacidadeCalculadaStr = jsonObject.nrCapacidadeCalculadaStr;
    this.nrVazaoRecomendadaStr = jsonObject.nrVazaoRecomendadaStr;
    this.nrNivelRecomendadoStr = jsonObject.nrNivelRecomendadoStr;
    this.nrNivelEstaticoBombaStr = jsonObject.nrNivelEstaticoBombaStr;
    this.nrNivelDinamicoBombaStr = jsonObject.nrNivelDinamicoBombaStr;

    this.nrVazaoBombeamentoStr = jsonObject.nrVazaoBombeamentoStr;
    this.nrNivelDinamicoBombeamentoStr =
      jsonObject.nrNivelDinamicoBombeamentoStr;
    this.nrProducaoBombeamentoStr = jsonObject.nrProducaoBombeamentoStr;
    this.nrNivelEstaticoCompStr = jsonObject.nrNivelEstaticoCompStr;
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
