export default class ColetaEtapa {
  constructor(jsonObject = {}) {
    this.nrSeqColetaEtapa = jsonObject.nrSeqColetaEtapa;
    this.cdColetaEtapa = jsonObject.cdColetaEtapa;
    this.noColetaEtapa = jsonObject.noColetaEtapa;
    this.flgSolicitacao = jsonObject.flgSolicitacao;
    this.flgEmbarque = jsonObject.flgEmbarque;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
