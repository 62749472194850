import React, { useState } from 'react';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { Button, Loader, Modal, Notification, FileUpload } from 'ui/components';
import { postImportaConsumo } from '../../../../core/services/TEL/recalculaConta';

export default function ModalImportaConsumo({
  show,
  onClose,
  nrseqcontacabecalho,
}) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState();

  const onImportaConsumo = async () => {
    setLoading(true);
    const { status, message: msg } = await postImportaConsumo(
      files,
      nrseqcontacabecalho
    );
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  return (
    <Modal
      show={show}
      title='Relatório Recálculo Conta'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='row mb-3'>
          <FileUpload
            files={files}
            onChange={(items: any) => {
              setFiles(items);
              setMessage(null);
            }}
            maxFiles={500}
            dropzone
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Small}
          icon='cloud-upload-alt'
          theme={Theme.Success}
          template={Button.Templates.Default}
          onClick={onImportaConsumo}
          text='Importar'
        />
        <Button
          text='Sair'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
