import React, {
  forwardRef,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {
  GridView,
  Textbox,
  Autocomplete,
  Button,
  Textarea,
} from 'ui/components';
import { Theme, BootstrapSizes } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import PocPocoPerfuracao from 'core/models/POC/pocPocoPerfuracao';
import {
  getMetricConversion,
  sortPocPocoPerfuracao,
} from 'core/services/POC/pocPocoPerfuracao';
import { getPerfuracaoAutoComplete } from 'core/services/POC/perfuracao';

const CadastroPerfuracao = forwardRef(
  ({
    registryKey,
    data,
    setData,
    onInicioFimInvalido,
    onValidaCampoObrigatorio,
  }) => {
    const gridView = useRef();
    const [dataPerfuracao, setDataPerfuracao] = useState(
      new PocPocoPerfuracao({})
    );

    const [totalPerfuracoes, setTotalPerfuracoes] = useState();

    function somaPerfuracoes(lista) {
      const newlist = lista.filter((e) => e.status !== 'Remover');
      const perfuracoes = newlist.map(
        (perfuracao) => new PocPocoPerfuracao(perfuracao).total
      );
      const resultado = perfuracoes.reduce((a, b) => a + b, 0).toFixed(2);
      setTotalPerfuracoes(resultado.replace('.', ','));
    }

    const onSearchMetodoPerfuracao = async (e) => {
      const { perfuracao } = await getPerfuracaoAutoComplete({
        noPocMetodoPerfuracao: e,
      });
      return perfuracao;
    };

    const load = useCallback(async () => {
      const listaPerfuracao = data.perfuracoes ?? [];
      listaPerfuracao.sort((a, b) => (a.nrInicio > b.nrInicio ? 1 : -1));

      if (gridView && gridView.current)
        gridView.current.setDataSource(listaPerfuracao);

      somaPerfuracoes(listaPerfuracao);
    }, [registryKey]);

    useEffect(() => {
      (async function func() {
        await load();
      })();
    }, []);

    const onAdicionarPerfuracao = async () => {
      if (onInicioFimInvalido(dataPerfuracao.nrInicio, dataPerfuracao.nrFim))
        return;
      if (!onValidaCampoObrigatorio(dataPerfuracao)) return;
      if (!dataPerfuracao.nrInicio) dataPerfuracao.nrInicio = 0;

      const listaPerfuracao = gridView.current.getDataSource();
      const obj = new PocPocoPerfuracao({
        ...dataPerfuracao,
        nrSeqPocPoco: data.nrSeqPocPoco,
        status: dataPerfuracao.status ?? GridView.EnumStatus.Inserir,
      });
      listaPerfuracao.push(obj);
      setDataPerfuracao(
        new PocPocoPerfuracao({ nrInicio: dataPerfuracao.nrFim, nrFim: '' })
      );

      if (listaPerfuracao.length > 1) {
        const listaOrdenada = {
          perfuracoes: listaPerfuracao,
        };
        const datasorted = await sortPocPocoPerfuracao(listaOrdenada);

        if (gridView && gridView.current)
          gridView.current.setDataSource(datasorted.listaPerfuracao);

        somaPerfuracoes(datasorted.listaPerfuracao);

        data.perfuracoes = datasorted.listaPerfuracao;

        setData({
          ...data,
          perfuracoes: datasorted.listaPerfuracao,
          salvarEtapa: true,
        });
      } else {
        if (gridView && gridView.current)
          gridView.current.setDataSource(listaPerfuracao);

        somaPerfuracoes(listaPerfuracao);
        data.perfuracoes = listaPerfuracao;

        setData({
          ...data,
          perfuracoes: listaPerfuracao,
          salvarEtapa: true,
        });
      }
      document.getElementById('inicio').focus();
    };

    const onEditarPerfuracao = async (e, datasource) => {
      const dataPerfS = datasource.filter((perfuracao) => perfuracao !== e);
      const dataPerf = datasource.filter((perfuracao) => perfuracao === e);

      const perfuracaoItem = {
        nrSeqPocMetodoPerfuracao: dataPerf[0].nrSeqPocMetodoPerfuracao,
        noPocMetodoPerfuracao: dataPerf[0].noPocMetodoPerfuracao,
      };
      const poco = data;
      setDataPerfuracao({
        ...dataPerf[0],
        status: GridView.EnumStatus.Alterar,
        metodoPerfuracao: perfuracaoItem,
      });

      if (dataPerfS.length > 1) {
        const obj = { ...poco, perfuracoes: dataPerfS };
        const datasorted = await sortPocPocoPerfuracao(obj);

        if (gridView && gridView.current)
          gridView.current.setDataSource(datasorted.listaPerfuracao);

        somaPerfuracoes(datasorted.listaPerfuracao);
      } else {
        if (gridView && gridView.current)
          gridView.current.setDataSource(dataPerfS);

        somaPerfuracoes(dataPerfS);
      }
    };

    const onRemovePerfuracao = async (e, datasource) => {
      const dataPerfS = datasource.filter((perfuracao) => perfuracao !== e);
      const obj = { ...e, status: GridView.EnumStatus.Remover };
      dataPerfS.push(obj);
      const poco = data;
      if (gridView && gridView.current)
        gridView.current.setDataSource(dataPerfS);

      somaPerfuracoes(dataPerfS);
      data.perfuracoes = dataPerfS;

      setData({ ...poco, perfuracoes: dataPerfS, salvarEtapa: true });
    };

    const columns = [
      { key: 'nrSeqPocPocoPerfuracao', visible: false },
      {
        key: 'nrInicio',
        title: 'Inicio (m)',
        format: GridView.DataTypes.Decimal,
      },
      { key: 'nrFim', title: 'Fim (m)', format: GridView.DataTypes.Decimal },
      {
        key: 'nrDiametropol',
        title: 'Diâmetro (pol)',
        format: GridView.DataTypes.Inch,
      },
      {
        key: 'nrDiametromm',
        title: 'Diâmetro (mm)',
        format: GridView.DataTypes.Decimal,
      },
      {
        key: 'noPocMetodoPerfuracao',
        title: 'Perfuração',
      },
      { key: 'total', title: 'Total', format: GridView.DataTypes.Decimal },
      {
        key: 'nrSeqPocMetodoPerfuracao',
        title: 'NrSeqPocMetodoPerfuracao',
        visible: false,
      },
      {
        key: 'nrSeqPocPocoPerfuracao',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onEditarPerfuracao(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },
      {
        key: 'nrSeqPocPocoPerfuracao',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemovePerfuracao(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    return (
      <div>
        <div className='row mb-3'>
          <div className='display-8'>3 - Perfuração</div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <div className='card'>
              <div className='card-body'>
                <div className='row mb-3'>
                  <div className='col'>
                    <Textbox
                      id='inicio'
                      label='Inicio (m)'
                      text={dataPerfuracao.nrInicio}
                      maxLength={8}
                      mask={MaskTypes.DecimalCustom}
                      decimalPlaces={2}
                      onChangedValue={(nrInicio) =>
                        setDataPerfuracao({
                          ...dataPerfuracao,
                          nrInicio: nrInicio ?? 0,
                        })
                      }
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='Fim (m)'
                      text={dataPerfuracao.nrFim}
                      maxLength={8}
                      mask={MaskTypes.DecimalCustom}
                      decimalPlaces={2}
                      onChangedValue={(obj) =>
                        setDataPerfuracao({
                          ...dataPerfuracao,
                          nrFim: obj,
                          total:
                            obj > dataPerfuracao.nrInicio
                              ? parseFloat(
                                  (obj - dataPerfuracao.nrInicio).toFixed(2)
                                )
                              : 0,
                        })
                      }
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='Diâm. (pol)'
                      text={dataPerfuracao.nrDiametropol ?? ''}
                      maxLength={8}
                      onChangedValue={(nrDiametropol) =>
                        setDataPerfuracao({
                          ...dataPerfuracao,
                          nrDiametropol,
                        })
                      }
                      onBlur={async (param1, param2, param3) => {
                        const obj = {
                          diametropol: param2,
                          param1,
                          param3,
                        };
                        const { value } = await getMetricConversion(
                          obj.diametropol
                        );
                        setDataPerfuracao({
                          ...dataPerfuracao,
                          nrDiametromm: value,
                        });
                      }}
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='Diâm. (mm)'
                      text={dataPerfuracao.nrDiametromm ?? ' '}
                      readOnly
                    />
                  </div>
                  <div className='col-4'>
                    <Autocomplete
                      label='Perfuração'
                      searchDataSource={onSearchMetodoPerfuracao}
                      selectedItem={dataPerfuracao.metodoPerfuracao}
                      onSelectItem={(metodoPerfuracao) =>
                        setDataPerfuracao({
                          ...dataPerfuracao,
                          metodoPerfuracao,
                          noPocMetodoPerfuracao:
                            metodoPerfuracao.noPocMetodoPerfuracao,
                          nrSeqPocMetodoPerfuracao:
                            metodoPerfuracao.nrSeqPocMetodoPerfuracao,
                        })
                      }
                      dataSourceTextProperty='noPocMetodoPerfuracao'
                    />
                  </div>
                  <div className='col-auto mt-3'>
                    <Button
                      theme={Theme.Primary}
                      template={Button.Templates.Button}
                      text='Adicionar'
                      onClick={onAdicionarPerfuracao}
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col'>
                    <GridView
                      ref={gridView}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columns}
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div
                    className='col'
                    style={{
                      textAlign: 'right',
                      borderTop: '1px solid #0000002b',
                      borderBottom: '1px solid #0000002b',
                      paddingTop: '5px',
                    }}
                  >
                    <h6
                      style={{
                        padding: '2px',
                        color: '#605959',
                      }}
                    >
                      Total: {totalPerfuracoes}
                    </h6>
                  </div>
                </div>
                <div className='row' style={{ paddingBottom: '250px' }}>
                  <div className='col mb-3'>
                    <Textarea
                      maxLength={200}
                      label='Observação'
                      text={data.noPocPocoPerfuracaoObs}
                      onChangedValue={(noPocPocoPerfuracaoObs) => {
                        data.noPocPocoPerfuracaoObs = noPocPocoPerfuracaoObs;
                        setData({
                          ...data,
                          noPocPocoPerfuracaoObs,
                          salvarEtapa: true,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default CadastroPerfuracao;
