import React, { useEffect, useRef, useState } from 'react';
import { getLogErroSistemaPagined } from 'core/services/SEG/logErroSistema';
import { CSDSelPage, DatePicker, GridView } from 'ui/components';
import { ResponseStatus, Theme } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';

export default function LogErroSistema({ transaction, isActive }) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const columns = [
    {
      key: 'nrSeqLogErroSistema',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'dtCad', title: 'Dt. Cad', format: GridView.DataTypes.Date },
    {
      key: 'dtLogErroSistemaStr',
      title: 'Dt. Erro',
    },
    { key: 'noCaminho', title: 'Caminho' },
    { key: 'noMensagem', title: 'Mensagem' },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    const {
      data,
      status,
      pagination,
      message: msg,
    } = await getLogErroSistemaPagined(params ?? filtros);

    onSetMessage(status, msg);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getLogErroSistemaPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Log Erro Sistema'
      loading={loading}
      onSearch={() => search()}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-auto'>
          <DatePicker
            label='Data Erro Inicio'
            text={filtros.dtLogErroSistemaIni}
            mask={MaskTypes.Date}
            onChange={(dtLogErroSistemaIni) =>
              setFiltros({ ...filtros, dtLogErroSistemaIni })
            }
          />
        </div>
        <div className='col-auto'>
          <DatePicker
            label='Data Erro Fim'
            text={filtros.dtLogErroSistemaFim}
            mask={MaskTypes.Date}
            onChange={(dtLogErroSistemaFim) =>
              setFiltros({ ...filtros, dtLogErroSistemaFim })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={false}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            enableExcelExport
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
