import qs from 'qs';
import Pagination from 'core/models/pagination';
import AreaChamado from 'core/models/HELP/areaChamado';
import api from '../api';

const baseURL = `/HELP/AreaChamado`;

export const getAreaChamadoList = async (filters: any) => {
  const response = await api.get(`/$AreaChamado/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new AreaChamado(json)) ?? [],
  };
};

export const getAreaChamadoPagined = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new AreaChamado(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getAreaChamado = async (id: number) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new AreaChamado(value) : {};
};

export const getAreaChamadoAutoComplete = async (filters: any) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    areasChamado: value?.map((json: any) => new AreaChamado(json)) ?? [],
  };
};

export const saveAreaChamado = async (data: AreaChamado) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteAreaChamado = async (id: number) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const deleteAllAreaChamado = async (ids: number[]) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params: any) => qs.stringify(params),
  });
  return response.data ?? {};
};
