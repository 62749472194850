export default class InstrucaoMovimento {
  constructor(jsonObject = {}) {
    this.nrSeqInstrucaoMovimento = jsonObject.nrSeqInstrucaoMovimento;
    this.cdInstrucaoMovimento = jsonObject.cdInstrucaoMovimento;
    this.noInstrucaoMovimento = jsonObject.noInstrucaoMovimento;
    this.noFormatoEnvio = jsonObject.noFormatoEnvio;
    this.flgAtivo = jsonObject.flgAtivo;
    this.nrSeqBanco = jsonObject.nrSeqBanco;
  }

  toJson = () => JSON.stringify(this);
}
