import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Textbox, Switch } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getPocGranulometrica,
  savePocGranulometrica,
  deletePocGranulometrica,
} from 'core/services/POC/pocGranulometria';

export default function PocGranulometricaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      setData(await getPocGranulometrica(registryKey));
      setLoading(false);
    } else setData({ flgAtivo: true });
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await savePocGranulometrica(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deletePocGranulometrica(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Granulometria'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqPocGranulometria > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-2'>
          <Textbox
            required
            label='Código Granulometria'
            text={data.cdPocGranulometria}
            mask={MaskTypes.Integer}
            onChangedValue={(cdPocGranulometria) =>
              setData({ ...data, cdPocGranulometria })
            }
          />
        </div>
        <div className='col'>
          <Textbox
            maxLength={120}
            label='Descrição'
            text={data.noPocGranulometria}
            onChangedValue={(noPocGranulometria) =>
              setData({ ...data, noPocGranulometria })
            }
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Ativo'
            checked={data.flgAtivo}
            onChange={(flgAtivo) => setData({ ...data, flgAtivo })}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
