import qs from 'qs';
import Pagination from 'core/models/pagination';
import PocPerflitCamada from 'core/models/POC/pocPerflitCamada';
import api from '../api';

const baseURL = `/POC/PocPerflitCamada`;

export const getPocPerflitCamadaAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    camada: value?.map((json) => new PocPerflitCamada(json)) ?? [],
  };
};

export const getPocPerflitCamadaList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPerflitCamada(json)) ?? [],
  };
};

export const getPocPerflitCamadaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPerflitCamada(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocPerflitCamada = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocPerflitCamada(value) : {};
};

export const savePocPerflitCamada = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePocPerflitCamada = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPocPerflitCamada = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocPerflitCamada = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocPerflitCamada = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
