import React, { useEffect, useRef, useState } from 'react';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import {
  Button,
  Loader,
  Modal,
  Notification,
  DatePicker,
  Textbox,
  Autocomplete,
  DropdownMulti,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  printBoleto,
  printTrocaFaixa,
} from 'core/services/ASS/faturarConvenios';

export default function ModalDadosImpressao({
  show,
  onClose,
  onSearchTituloEspecie,
  onSearchConvenio,
  onSearchFormaPagamento,
}) {
  const dropDownTiposReceita = useRef();
  const dropDownConvenios = useRef();
  const [dataPrint, setDataPrint] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsConvenio, setSelectedItemsConvenio] = useState([]);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dropDownTiposReceita && dropDownTiposReceita.current)
      dropDownTiposReceita.current.loadDataSource(onSearchTituloEspecie);
    if (dropDownConvenios && dropDownConvenios.current)
      dropDownConvenios.current.loadDataSource(onSearchConvenio);
  }, [show]);

  const onPrintBoleto = async () => {
    if (!dataPrint.dtCadastro) {
      setMessage({
        message: 'Necessário preencher a data de cadastro.',
        theme: Theme.Danger,
      });
    } else {
      setLoading(true);
      const { message: msg, value } = await printBoleto(dataPrint);
      setMessage({
        message: msg,
        theme: Theme.Danger,
      });

      if (value) {
        const linkSource = `data:image/pdf;base64, ${value}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = `BoletoResuno.pdf`;
        downloadLink.click();
      }
      setLoading(false);
    }
  };

  const onPrintTrocaFaixa = async () => {
    setLoading(true);
    const { message: msg, value } = await printTrocaFaixa(dataPrint);

    if (value) {
      const linkSource = `data:image/pdf;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `TrocaFaixa.pdf`;
      downloadLink.click();
    } else {
      setMessage({
        message: msg,
        theme: Theme.Danger,
      });
    }

    setLoading(false);
  };

  return (
    <Modal
      show={show}
      title='Dados Impressão'
      onClose={() => {
        onClose();
      }}
      size={BootstrapSizes.Large}
    >
      <Modal.Body className='p-3 pt-4 '>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='row justify-content-evenly mt-4 mb-4 '>
          <div className='col-2 mb-4'>
            <Textbox
              label='Competência'
              text={dataPrint?.anoMes}
              masLength={10}
              mask={MaskTypes.Competence}
              onChangedValue={(anoMes) =>
                setDataPrint({ ...dataPrint, anoMes })
              }
            />
          </div>
          <div className='col-3 mb-4 '>
            <DatePicker
              label='Data Cadastro'
              text={dataPrint?.dtCadastro}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtCadastro) =>
                setDataPrint({ ...dataPrint, dtCadastro })
              }
            />
          </div>

          <div className='col-5 mb-4'>
            <Autocomplete
              label='* Forma Recebimento'
              searchDataSource={onSearchFormaPagamento}
              selectedItem={dataPrint?.formaPagamento}
              onSelectItem={(formaPagamento) =>
                setDataPrint({
                  ...dataPrint,
                  formaPagamento,
                  nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                })
              }
              dataSourceTextProperty='noFormaPagamento'
            />
          </div>
        </div>
        <div className='row justify-content-evenly mt-4 mb-4 '>
          <div className='col-5'>
            <DropdownMulti
              label='Tipo Receita'
              ref={dropDownTiposReceita}
              dataSourcePropertyText='noEspecie'
              dataSourcePropertyValue='nrSeqTituloEspecie'
              selectedItems={selectedItems.nrSeqsTiposReceita ?? []}
              onSelectItem={(nrSeqsTiposReceita) => {
                setSelectedItems({
                  ...selectedItems,
                  nrSeqsTiposReceita,
                });
                setDataPrint({
                  ...dataPrint,
                  nrSeqsEspeciesTitulos: nrSeqsTiposReceita?.map(
                    (p) => p.nrSeqTituloEspecie
                  ),
                });
              }}
            />
          </div>
          <div className='col-5'>
            <DropdownMulti
              label='Convenio'
              ref={dropDownConvenios}
              dataSourcePropertyText='noConvenio'
              dataSourcePropertyValue='nrSeqConvenio'
              selectedItems={selectedItemsConvenio.nrSeqsConvenios ?? []}
              onSelectItem={(nrSeqsConvenios) => {
                setSelectedItemsConvenio({
                  ...selectedItemsConvenio,
                  nrSeqsConvenios,
                });
                setDataPrint({
                  ...dataPrint,
                  nrSeqsConvenios: nrSeqsConvenios?.map((p) => p.nrSeqConvenio),
                });
              }}
            />
          </div>
        </div>
        <div className='row justify-content-evenly mt-3 mb-4 '>
          <div className='col-3 mt-3'>
            <Button
              outline
              theme={Theme.Success}
              template={Button.Templates.Button}
              text='Boleto Resumo'
              onClick={onPrintBoleto}
            />
          </div>
          <div className='col-3 mt-3'>
            <Button
              outline
              text='Troca de Faixa'
              theme={Theme.Danger}
              onClick={onPrintTrocaFaixa}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}
