export default class FormaPagamento {
  constructor(jsonObject = {}) {
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.noFormaPagamento = jsonObject.noFormaPagamento;
    this.flgGrupo = jsonObject.flgGrupo;
    this.flgControlaCheque = jsonObject.flgControlaCheque;
    this.flgGeraSequenciaRecibo = jsonObject.flgGeraSequenciaRecibo;
    this.flgJuroMultaManual = jsonObject.flgJuroMultaManual;
    this.noFormatoEnvio = jsonObject.noFormatoEnvio;
    this.flgVinculaFornecedor = jsonObject.flgVinculaFornecedor;
  }

  toJson = () => JSON.stringify(this);
}
