export default class VendedorComissaoList {
  constructor(jsonObject = {}) {
    this.noPessoaVen = jsonObject.noPessoaVen ?? '-';
    this.noPessoaEmp = jsonObject.noPessoaEmp ?? '-';
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.quantidade = jsonObject.quantidade;
    this.maxNrSeqVendedorComissao = jsonObject.maxNrSeqVendedorComissao;
  }

  toJson = () => JSON.stringify(this);
}
