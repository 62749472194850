import React, { useState, useEffect } from 'react';
import { BootstrapSizes } from 'ui/Helpers/utils';
import { Modal, Button, Textarea, Loader } from 'ui/components';
// @ts-expect-error
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';
import { responderEmail } from 'core/services/HELP/chamado';

interface IModal {
  show: boolean;
  onClose: any;
  data: any;
  onSetMessage: (status: number, message: string) => void;
  setModalData: (e: Array<any>) => any;
}

export default function ModalResponderEmail({
  show,
  onClose,
  data,
  onSetMessage,
  setModalData,
}: IModal) {
  const [loading, setLoading] = useState<boolean>(false);
  const [emailCorpo, setEmailCorpo] = useState<string>('');

  useEffect(() => {
    if (show) {
      setEmailCorpo('');
    }
  }, [show]);

  const onSendEmail = async (): Promise<void> => {
    setLoading(true);

    const { data: value, status, message: msg } = await responderEmail({
      nrSeqChamado: data.nrSeqChamadoPai ?? data.nrSeqChamado,
      emailCorpo,
    });

    onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      setModalData(value);
    }

    setLoading(false);

    onClose();
  };

  return (
    //  @ts-expect-error
    <Modal
      show={show}
      title='Responder Email'
      onClose={onClose}
      size={BootstrapSizes.Large}
      icon={['far', 'comment-alt']}
    >
      {/* @ts-expect-error */}
      <Modal.Body>
        <Loader loading={loading} />
        <div className='row mb-3'>
          <div className='col'>
            {/* @ts-expect-error */}
            <Textarea
              label=''
              text={emailCorpo}
              rows={6}
              onChangedValue={(text: string) => setEmailCorpo(text)}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Responder Email'
          theme={Theme.Success}
          onClick={onSendEmail}
        />
        <Button text='Cancelar' theme={Theme.Danger} onClick={onClose} />
      </Modal.Footer>
    </Modal>
  );
}
