import React, { useEffect, useState } from 'react';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { Button, Modal, Switch } from 'ui/components';

export default function ModalImprimir({ show, onClose, onPrint }) {
  const [filters, setFilters] = useState({
    flgRelNotasFiscais: true,
    flgRelAbastecimento: true,
    flgRelMovimentoSaida: true,
  });
  useEffect(() => {
    setFilters({
      flgRelNotasFiscais: true,
      flgRelAbastecimento: true,
      flgRelMovimentoSaida: true,
    });
  }, [show]);

  return (
    <Modal
      show={show}
      title='Impressão'
      size={BootstrapSizes.Small}
      onClose={onClose}
    >
      <Modal.Body>
        <div className='row'>
          <div className='col'>
            <Switch
              formControl
              label='Notas Fiscais Enviadas Diretamente à Obra'
              checked={filters.flgRelNotasFiscais}
              onChange={(flgRelNotasFiscais) =>
                setFilters({
                  ...filters,
                  flgRelNotasFiscais,
                })
              }
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <Switch
              formControl
              label='Abastecimento'
              checked={filters.flgRelAbastecimento}
              onChange={(flgRelAbastecimento) =>
                setFilters({
                  ...filters,
                  flgRelAbastecimento,
                })
              }
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <Switch
              formControl
              label='Movimentos de Saída'
              checked={filters.flgRelMovimentoSaida}
              onChange={(flgRelMovimentoSaida) =>
                setFilters({
                  ...filters,
                  flgRelMovimentoSaida,
                })
              }
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Imprimir'
          icon='check'
          theme={Theme.Success}
          onClick={() => onPrint(filters)}
        />
        <Button
          text='Fechar'
          icon='ban'
          theme={Theme.Danger}
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
