import qs from 'qs';
import DashboardAbastecimento from 'core/models/FRO/dashboardAbastecimento';
import api from '../api';

const baseURL = '/FRO/DashboardAbastecimento';

export const getDefaultFiltersValues = async () => {
  const response = await api.get(`${baseURL}/SearchDefault`);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};

export const getDashboardTransporte = async () => {
  const response = await api.get(`${baseURL}/`);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: new DashboardAbastecimento(value ?? {}),
  };
};

export const saveDashboardTransporte = async (data) => {
  const response = await api.post(`${baseURL}/`, data);

  const { status, message } = response.data ?? {};

  return {
    status,
    message,
  };
};

export const getGraficos = async (filters) => {
  const response = await api.get(`${baseURL}/BuscarGraficos`, {
    params: filters,
  });

  const { value, status, message } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? [],
  };
};

export const printDashboardAbastecimento = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
