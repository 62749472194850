import api from '../api';

const baseURL = '/FIN/DashboardFinanceiro';

export const getGraphics = async (filters: any) => {
  const response = await api.get(`${baseURL}/`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};

export const searchGrids = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchGrids`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};

export const detalhamentoReceitasDespesas = async (filters: any) => {
  const response = await api.get(`${baseURL}/DetalhamentoReceitasDespesas`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};
