import qs from 'qs';
import Pagination from 'core/models/pagination';
import FretePreco from 'core/models/FRO/fretePreco';
import api from '../api';

const baseURL = `/FRO/FretePreco`;

export const getFretePrecoList = async (filters) => {
  const response = await api.get(`/$FretePreco/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new FretePreco(json)) ?? [],
  };
};

export const getFretePrecoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new FretePreco(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getFretePreco = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new FretePreco(value) : {};
};

export const saveFretePreco = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteFretePreco = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printFretePreco = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelFretePreco = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllFretePreco = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const copyFretePreco = async (ids, nrSeqPessoaCopy) => {
  const obj = {
    ids,
    nrSeqPessoaCopy,
  };

  const response = await api.post(`${baseURL}/SaveCopy`, obj);
  const value = response.data ?? {};
  return value;
};
