import qs from 'qs';
import TituloPagar from 'core/models/FIN/tituloPagar';
import api from '../api';

const baseURL = `/ASS/FaturarConvenio`;

export const gerarFaturasConvenios = async (data) => {
  const response = await api.post(`${baseURL}/FaturarConvenios`, data);
  return response.data ?? {};
};

export const faturarComissoes = async (data) => {
  const response = await api.post(`${baseURL}/FaturarComissoes`, data);
  return response.data ?? {};
};

export const recalcularFaturaComissoesold = async (data) => {
  const response = await api.post(`${baseURL}/RecalcularComissoes`, data);
  const { status, message, value } = response.data ?? {};

  const titulosPagarComissoes = value.TitulosPagarComissoes ?? [];
  const resumoComissoes = value.ResumoComissoes ?? {};

  return {
    status,
    message,
    titulosPagarComissoes: titulosPagarComissoes?.map(
      (json) => new TituloPagar(json)
    ),
    resumoComissoes,
  };
};

export const recalcularFaturaComissoes = async (data) => {
  const response = await api.post(`${baseURL}/RecalcularComissoes`, data);
  return response.data ?? {};
};

export const searchUltimasComissoes = async (data) => {
  const response = await api.get(`${baseURL}/SearchUltimasComissoes`, data);

  const { status, message, value } = response.data ?? {};

  const titulosPagarComissoes = value?.titulosPagarComissoes ?? [];
  const resumoComissoes = value?.resumoComissoes ?? {};

  return {
    status,
    message,
    titulosPagarComissoes: titulosPagarComissoes?.map(
      (json) => new TituloPagar(json)
    ),
    resumoComissoes,
  };
};

export const deleteComissao = async (id) => {
  const response = await api.delete(`${baseURL}/DeleteComissao`, {
    params: { id },
  });
  return response.data ?? {};
};

export const cancelaFaturamento = async (filters) => {
  const response = await api.delete(`${baseURL}/CancelarFaturamento`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getDataFaturamentoCancelar = async (filter) => {
  const response = await api.get(`${baseURL}/SearchDataFaturamentoCancelar`, {
    params: filter,
    paramsSerializer: (params) => qs.stringify(params),
  });

  return response.data ?? {};
};

export const printTrocaFaixa = async (filters) => {
  const response = await api.post(`${baseURL}/PrintTrocaFaixa`, filters);
  return response.data ?? {};
};

export const printBoleto = async (filters) => {
  const response = await api.post(`${baseURL}/PrintBoleto`, filters);
  return response.data ?? {};
};

export const printComissao = async (nrSeqTituloPagar) => {
  const response = await api.get(`${baseURL}/PrintComissao`, {
    params: { nrSeqTituloPagar },
  });
  return response.data ?? {};
};
