import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Textbox, Switch } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getAcertoMotoristaPercentualReducao,
  saveAcertoMotoristaPercentualReducao,
  deleteAcertoMotoristaPercentualReducao,
} from 'core/services/FIN/acertoMotoristaPercentualReducao';
import { getEstadoAutoComplete } from 'core/services/SEG';
import { getClienteAutoComplete } from 'core/services/FIN';

export default function AcertoMotoristaPercentualReducaoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      setData(await getAcertoMotoristaPercentualReducao(registryKey));
      setLoading(false);
    } else setData({ vlrPercentualComissao: 0, vlrPercentualReducao: 0 });
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveAcertoMotoristaPercentualReducao(
      data
    );

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(
      status === ResponseStatus.Success
        ? { vlrPercentualComissao: 0, vlrPercentualReducao: 0 }
        : data
    );
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const {
        status,
        message: msg,
      } = await deleteAcertoMotoristaPercentualReducao(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchEstado = async (e) => {
    const { status, message: msg, estados } = await getEstadoAutoComplete({
      noEstado: e,
    });
    if (msg) onSetMessage(status, msg);
    return estados;
  };

  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return clientes;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Acerto Motorista Percentual Reducao'
      loading={loading}
      transaction={transaction}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() =>
        setData({ vlrPercentualComissao: 0, vlrPercentualReducao: 0 })
      }
      onSave={save}
      onDelete={
        data?.nrSeqAcertoMotoristaPercentualReducao > 0 ? onExcluir : null
      }
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <div className='row'>
        <div className='col-5 mb-3'>
          <Autocomplete
            required
            label='Estado Origem'
            searchDataSource={onSearchEstado}
            selectedItem={data.estadoOri}
            onSelectItem={(estadoOri) => {
              setData({
                ...data,
                estadoOri,
                nrSeqEstadoOri: estadoOri.nrSeqEstado,
              });
            }}
            dataSourceTextProperty='noEstado'
          />
        </div>
        <div className='col-5 mb-3'>
          <Autocomplete
            required
            label='Estado Destino'
            searchDataSource={onSearchEstado}
            selectedItem={data.estadoDest}
            onSelectItem={(estadoDest) => {
              setData({
                ...data,
                estadoDest,
                nrSeqEstadoDest: estadoDest.nrSeqEstado,
              });
            }}
            dataSourceTextProperty='noEstado'
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-5 mb-3'>
          <Textbox
            label='Valor da comissão'
            required
            text={data.vlrPercentualComissao}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(vlrPercentualComissao) =>
              setData({ ...data, vlrPercentualComissao })
            }
          />
        </div>
        <div className='col-5 mb-3'>
          <Textbox
            label='Valor da Redução'
            required
            text={data.vlrPercentualReducao}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(vlrPercentualReducao) =>
              setData({ ...data, vlrPercentualReducao })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Vincula Remetente'
            checked={data.flgVinculaRemetente}
            onChange={(flgVinculaRemetente) =>
              setData({ ...data, flgVinculaRemetente })
            }
          />
        </div>
        {data.flgVinculaRemetente && (
          <div className='col-5 mb-3'>
            <Autocomplete
              required
              label='Remetente'
              searchDataSource={onSearchCliente}
              selectedItem={data.cliente}
              onSelectItem={(cliente) => {
                setData({
                  ...data,
                  cliente,
                  nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
        )}
      </div>
    </CSDManutPage>
  );
}
