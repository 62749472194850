import React, { useEffect, useRef } from 'react';
import { Theme } from 'ui/Helpers/utils';
import { GridView, Panel } from 'ui/components';

export default function LinhasValoresNulos({ param }) {
  const gridView = useRef();

  const columns = [
    {
      key: 'nrLinha',
      title: 'Linha',
    },
    {
      key: 'vlrTotal',
      title: 'VlrTotal',
    },
    {
      key: 'vlrTotalRecalculo',
      title: 'VlrTotalRecalculo',
    },
  ];

  useEffect(() => {
    if (gridView && gridView.current) gridView.current.setDataSource(param);
  }, []);

  return (
    <div className='row my-3'>
      <Panel>
        <Panel.Header
          title='Linhas com Valores Negativos'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col'>
              <GridView
                ref={gridView}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columns}
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </div>
  );
}
