import {
  Plano,
  Bonus,
  GrupoTipoChamada,
  CentroDeCusto,
  Chip,
  Aparelho,
  PacoteSms,
  ClienteLinhaCentroDeCusto,
  ClienteLinhaEncargoManual,
  ClienteLinhaOcorrencia,
  ClienteLinhaPai,
  TipoChamada,
} from 'core/models/TEL';

import {
  FormaPagamento,
  Cliente,
  PessoaInformacaoBancaria,
} from 'core/models/FIN';
import { Vencimento } from 'core/models/ASS';

export default class ClienteLinha {
  constructor(jsonObject = {}) {
    this.nrSeqClienteLinha = jsonObject.nrSeqClienteLinha;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqChip = jsonObject.nrSeqChip;
    this.nrSeqAparelho = jsonObject.nrSeqAparelho;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqVencimento = jsonObject.nrSeqVencimento;
    this.vlrVendaAparelho = jsonObject.vlrVendaAparelho;
    this.flgStatus = jsonObject.flgStatus;
    this.dtAquisicao = jsonObject.dtAquisicao;
    this.dtAquisicaoFormat = jsonObject.dtAquisicaoFormat;
    this.dtCancelamento = jsonObject.dtCancelamento;
    this.dtRenovacao = jsonObject.dtRenovacao;
    this.observacao = jsonObject.observacao;
    this.nrProtbloqueio = jsonObject.nrProtbloqueio;
    this.dtBloqueio = jsonObject.dtBloqueio;
    this.dtBloqueioFormat = jsonObject.dtBloqueioFormat;
    this.dtDesbloqueio = jsonObject.dtDesbloqueio;
    this.dtDesbloqueioFormat = jsonObject.dtDesbloqueioFormat;
    this.flgModem = jsonObject.flgModem;
    this.flgRecFatCorreio = jsonObject.flgRecFatCorreio;
    this.nrSeqPlano = jsonObject.nrSeqPlano;
    this.nrSeqPacoteSms = jsonObject.nrSeqPacoteSms;
    this.dtIniVigenciaPacSms = jsonObject.dtIniVigenciaPacSms;
    this.dtFimVigenciaPacSms = jsonObject.DtFimVigenciaPacSms;
    this.dtIniVigenciaPacSmsFormat = jsonObject.dtIniVigenciaPacSmsFormat;
    this.dtFimVigenciaPacSmsFormat = jsonObject.dtFimVigenciaPacSmsFormat;

    this.vlrPacoteSms = jsonObject.vlrPacoteSms;
    this.nrSeqClienteLinhaPai = jsonObject.nrSeqClienteLinhaPai;
    this.nrSeqCentroDeCusto = jsonObject.nrSeqCentroDeCusto;
    this.noClienteConta = jsonObject.noClienteConta;
    this.nrSeqBonus = jsonObject.nrSeqBonus;
    this.nrSeqGrupoCustoEmpresa = jsonObject.nrSeqGrupoCustoEmpresa;
    this.nrSeqGrupoTCFatProporc = jsonObject.nrSeqGrupoTCFatProporc;
    this.dtRenovacao = jsonObject.dtRenovacao;
    this.dtRenovacaoFormat = jsonObject.dtRenovacaoFormat;
    this.nrTermo = jsonObject.nrTermo;
    this.txtEmailFatura = jsonObject.txtEmailFatura;
    this.flgEnviaSms = jsonObject.flgEnviaSms;
    this.flgRecebeEmail = jsonObject.flgRecebeEmail;
    this.nrLinhaCompleto = jsonObject.nrLinhaCompleto;
    this.nrSeqPlanoRateioEmpresa = jsonObject.nrSeqPlanoRateioEmpresa;

    this.nrSeqPessoaInformacaoBancaria =
      jsonObject.nrSeqPessoaInformacaoBancaria;

    this.Ocorrencias = jsonObject.ocorrencias ?? [];

    this.informacaoBancaria = new PessoaInformacaoBancaria(
      jsonObject.informacaoBancaria ?? {}
    );
    this.plano = new Plano(jsonObject.plano ?? {});
    this.bonus = new Bonus(jsonObject.bonus ?? {});
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.planoRateioEmpresa = new Plano(jsonObject.planoRateioEmpresa ?? {});
    this.grupoCustoEmpresa = new GrupoTipoChamada(
      jsonObject.grupoCustoEmpresa ?? {}
    );

    this.centroDeCusto = new CentroDeCusto(jsonObject.centroDeCusto ?? {});

    this.vencimento = new Vencimento(jsonObject.vencimento ?? {});

    this.cliente = new Cliente(jsonObject.cliente ?? {});

    this.chip = new Chip(jsonObject.chip ?? {});

    this.aparelho = new Aparelho(jsonObject.aparelho ?? {});

    this.grupoTCFatProporc = new GrupoTipoChamada(
      jsonObject.grupoTCFatProporc ?? {}
    );

    this.pacoteSms = new PacoteSms(jsonObject.pacoteSms ?? {});

    this.encargosManuais = (jsonObject.encargosManuais ?? []).map(
      (encargo) => new ClienteLinhaEncargoManual(encargo)
    );

    this.ocorrencias = (jsonObject.ocorrencias ?? []).map(
      (ocorrencia) => new ClienteLinhaOcorrencia(ocorrencia)
    );

    this.centroDeCustos = (jsonObject.centroDeCustos ?? []).map(
      (centro) => new ClienteLinhaCentroDeCusto(centro)
    );

    if (this.nrSeqClienteLinhaPai > 0) {
      this.clienteLinhaPai = new ClienteLinhaPai(
        jsonObject.clienteLinhaPai ?? {}
      );
    }

    this.flgSituacaoStr = jsonObject.flgSituacaoStr;
    this.status = jsonObject.status;
    this.vlrEncargo = jsonObject.vlrEncargo;
    this.nrSeqTipoChamada = jsonObject.nrSeqTipoChamada;
    this.tipoChamada = new TipoChamada(jsonObject.tipoChamada ?? {});
    this.consumosRecente = jsonObject.consumosRecente;
    this.consumoSMS = jsonObject.consumoSMS;
    this.consumoDados = jsonObject.consumoDados;
    this.consumoVoz = jsonObject.consumoVoz;
  }

  get linhaFormatada() {
    return `(${this.chip.nrPrefixo}) ${this.chip.nrLinha}`;
  }

  toJson = () => JSON.stringify(this);
}
