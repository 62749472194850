import React, {
  forwardRef,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import {
  GridView,
  Textbox,
  Autocomplete,
  Button,
  Textarea,
} from 'ui/components';
import { Theme, BootstrapSizes } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';

import { getPocPerflitCamadaAutoComplete } from 'core/services/POC/pocPerflitCamada';
import { getPocPerflitFormacaoAutoComplete } from 'core/services/POC/pocPerflitFormacao';

import PocPocoPerflitCamada from 'core/models/POC/pocPocoPerflitCamada';
import PocPocoPerflitFormacao from 'core/models/POC/pocPocoPerflitFormacao';
import { sortPocPocoPerflitCamada } from 'core/services/POC/pocPocoPerflitCamada';
import { sortPocPocoPerflitFormacao } from 'core/services/POC/pocPocoPerflitFormacao';

const CadastroPerfilLitologico = forwardRef(
  ({
    data,
    setData,
    onInicioFimInvalido,
    onValidaCampoObrigatorioCamada,
    onValidaCampoObrigatorioFormacao,
  }) => {
    const gridViewCamada = useRef();
    const gridViewFormacao = useRef();

    const [dataCamada, setDataCamada] = useState(new PocPocoPerflitCamada({}));
    const [dataFormacao, setDataFormacao] = useState(
      new PocPocoPerflitFormacao({})
    );

    const [totalCamadas, setTotalCamadas] = useState();
    const [totalFormacoes, setTotalFormacoes] = useState();

    const calculaTotalItens = (lista, gridlista) => {
      if (!lista) return null;
      let itens = [];

      const newlist = lista.filter((e) => e.status !== 'Remover');
      if (gridlista === 'camada') {
        itens = newlist.map(
          (item) => new PocPocoPerflitCamada(item).totalCamada
        );
        return itens
          .reduce((a, b) => a + b, 0)
          .toFixed(2)
          .replace('.', ',');
      }

      itens = newlist.map(
        (item) => new PocPocoPerflitFormacao(item).totalFormacao
      );
      return itens
        .reduce((a, b) => a + b, 0)
        .toFixed(2)
        .replace('.', ',');
    };

    const onSearchCamada = async (e) => {
      const { camada } = await getPocPerflitCamadaAutoComplete({
        noPocPerflitCamada: e,
      });
      return camada;
    };

    const onSearchFormacao = async (e) => {
      const { formacao } = await getPocPerflitFormacaoAutoComplete({
        noPocPerflitFormacao: e,
      });
      return formacao;
    };

    const onEditarCamada = async (e, datasource) => {
      const perfilCamadas = datasource.filter((camada) => camada !== e);
      const perfilCamada = datasource.filter((camada) => camada === e);

      const camadaItem = {
        nrSeqPocPerflitCamada: perfilCamada[0].nrSeqPocPerflitCamada,
        noPocPerflitCamada: perfilCamada[0].noPocPerflitCamada,
      };

      setDataCamada({
        ...perfilCamada[0],
        camada: camadaItem,
        status: GridView.EnumStatus.Alterar,
      });
      if (perfilCamadas.length > 1) {
        const obj = { camadas: perfilCamadas };
        const dataordened = await sortPocPocoPerflitCamada(obj);

        if (gridViewCamada && gridViewCamada.current)
          gridViewCamada.current.setDataSource(dataordened.listaCamada);

        setTotalCamadas(calculaTotalItens(dataordened.listaCamada, 'camada'));
      } else {
        if (gridViewCamada && gridViewCamada.current)
          gridViewCamada.current.setDataSource(perfilCamadas);

        setTotalCamadas(calculaTotalItens(perfilCamadas, 'camada'));
      }
    };

    const onAdicionarCamada = async () => {
      if (onInicioFimInvalido(dataCamada.nrInicio, dataCamada.nrFim)) return;
      if (!onValidaCampoObrigatorioCamada(dataCamada)) return;
      const listaCamada = gridViewCamada.current.getDataSource() ?? [];

      const newdata = new PocPocoPerflitCamada({
        ...dataCamada,
        nrSeqPocPoco: data.nrSeqPocPoco,
        status: dataCamada.status ?? GridView.EnumStatus.Inserir,
      });

      listaCamada.push(newdata);

      setDataCamada(
        new PocPocoPerflitCamada({ nrInicio: dataCamada.nrFim, nrFim: '' })
      );

      const listaFormacao = gridViewFormacao.current.getDataSource() ?? [];

      if (listaCamada.length > 1) {
        const obj = { camadas: listaCamada };
        const dataordened = await sortPocPocoPerflitCamada(obj);

        if (gridViewCamada && gridViewCamada.current)
          gridViewCamada.current.setDataSource(dataordened.listaCamada);

        setTotalCamadas(calculaTotalItens(dataordened.listaCamada, 'camada'));

        data.camadas = dataordened.listaCamada;

        setData({
          ...data,
          camadas: dataordened.listaCamada,
          formacoes: listaFormacao,
        });
      } else {
        if (gridViewCamada && gridViewCamada.current)
          gridViewCamada.current.setDataSource(listaCamada);

        setTotalCamadas(calculaTotalItens(listaCamada, 'camada'));

        data.camadas = listaCamada;
        setData({ ...data, camadas: listaCamada, formacoes: listaFormacao });
      }

      document.getElementById('inicioCamada').focus();
    };

    const onAdicionarFormacao = async () => {
      if (onInicioFimInvalido(dataFormacao.nrInicio, dataFormacao.nrFim))
        return;
      if (!onValidaCampoObrigatorioFormacao(dataFormacao)) return;
      const listaFormacao = gridViewFormacao.current.getDataSource() ?? [];

      const newdata = new PocPocoPerflitFormacao({
        ...dataFormacao,
        nrSeqPocPoco: data.nrSeqPocPoco,
        status: dataFormacao.status ?? GridView.EnumStatus.Inserir,
      });
      listaFormacao.push(newdata);

      setDataFormacao(
        new PocPocoPerflitFormacao({ nrInicio: dataFormacao.nrFim, nrFim: '' })
      );

      const listaCamada = gridViewCamada.current.getDataSource() ?? [];

      if (listaFormacao.length > 1) {
        const obj = { formacoes: listaFormacao };
        const dataordened = await sortPocPocoPerflitFormacao(obj);

        if (gridViewFormacao && gridViewFormacao.current)
          gridViewFormacao.current.setDataSource(dataordened.listaFormacao);

        setTotalFormacoes(
          calculaTotalItens(dataordened.listaFormacao, 'formacao')
        );

        data.formacoes = dataordened.listaFormacao;
        setData({
          ...data,
          formacoes: dataordened.listaFormacao,
          camadas: listaCamada,
        });
      } else {
        if (gridViewFormacao && gridViewFormacao.current)
          gridViewFormacao.current.setDataSource(listaFormacao);

        setTotalFormacoes(calculaTotalItens(listaFormacao, 'formacao'));

        data.formacoes = listaFormacao;
        setData({
          ...data,
          formacoes: listaFormacao,
          camadas: listaCamada,
        });
      }

      document.getElementById('inicioFormacao').focus();
    };

    const onEditarFormacao = async (e, datasource) => {
      const perfilFormacoes = datasource.filter((formacao) => formacao !== e);
      const perfilFormacao = datasource.filter((formacao) => formacao === e);

      const formacaoItem = {
        nrSeqPocPerflitFormacao: perfilFormacao[0].nrSeqPocPerflitFormacao,
        noPocPerflitFormacao: perfilFormacao[0].noPocPerflitFormacao,
      };

      setDataFormacao({
        ...perfilFormacao[0],
        formacao: formacaoItem,
        status: GridView.EnumStatus.Alterar,
      });

      if (perfilFormacoes.length > 1) {
        const obj = { formacoes: perfilFormacoes };
        const dataordened = await sortPocPocoPerflitFormacao(obj);

        if (gridViewFormacao && gridViewFormacao.current)
          gridViewFormacao.current.setDataSource(dataordened.listaFormacao);

        setTotalFormacoes(
          calculaTotalItens(dataordened.listaFormacao, 'formacao')
        );
      } else {
        if (gridViewFormacao && gridViewFormacao.current)
          gridViewFormacao.current.setDataSource(perfilFormacoes);

        setTotalFormacoes(calculaTotalItens(perfilFormacoes, 'formacao'));
      }
    };

    const onRemoveCamada = async (e, datasource) => {
      const camadaS = datasource.filter((camada) => camada !== e);
      const obj = { ...e, status: GridView.EnumStatus.Remover };
      camadaS.push(obj);
      if (gridViewCamada && gridViewCamada.current)
        gridViewCamada.current.setDataSource(camadaS);

      setTotalCamadas(calculaTotalItens(camadaS, 'camada'));

      data.camadas = camadaS;

      setData({
        ...data,
        camadas: camadaS,
        formacoes: gridViewFormacao.current.getDataSource(),
      });
    };

    const onRemoveFormacao = async (e, datasource) => {
      const formacoeS = datasource.filter((formacao) => formacao !== e);
      const obj = { ...e, status: GridView.EnumStatus.Remover };
      formacoeS.push(obj);
      if (gridViewFormacao && gridViewFormacao.current)
        gridViewFormacao.current.setDataSource(formacoeS);

      setTotalFormacoes(calculaTotalItens(formacoeS, 'formacao'));

      setData({
        ...data,
        formacoes: formacoeS,
        camadas: gridViewCamada.current.getDataSource(),
      });

      data.formacoes = formacoeS;
    };

    const columnsCamada = [
      { key: 'nrSeqPocPocoPerflitCamada', visible: false },
      {
        key: 'nrInicio',
        title: 'Inicio (m)',
        format: GridView.DataTypes.Decimal,
      },
      { key: 'nrFim', title: 'Fim (m)', format: GridView.DataTypes.Decimal },
      { key: 'cdPocPerflitCamada', title: 'Camada' },
      { key: 'noPocPerflitCamada', title: 'Descrição da Camada' },
      { key: 'noObservacao', title: 'Observação' },
      {
        key: 'totalCamada',
        title: 'Total',
        format: GridView.DataTypes.Decimal,
      },

      {
        key: 'nrSeqPocPocoPerflitCamada',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onEditarCamada(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },
      {
        key: 'nrSeqPocPocoPerflitCamada',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemoveCamada(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    const columnsFormacao = [
      { key: 'nrSeqPocPocoPerflitFormacao', visible: false },
      {
        key: 'nrInicio',
        title: 'Inicio (m)',
        format: GridView.DataTypes.Decimal,
      },
      { key: 'nrFim', title: 'Fim (m)', format: GridView.DataTypes.Decimal },
      { key: 'cdPocPerflitFormacao', title: 'Formação' },
      { key: 'noPocPerflitFormacao', title: 'Descrição da Formação' },
      {
        key: 'totalFormacao',
        title: 'Total',
        format: GridView.DataTypes.Decimal,
      },

      {
        key: 'nrSeqPocPocoPerflitFormacao',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onEditarFormacao(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },
      {
        key: 'nrSeqPocPocoPerflitFormacao',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemoveFormacao(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    const load = useCallback(async () => {
      if (gridViewCamada && gridViewCamada.current)
        gridViewCamada.current.setDataSource(data.camadas);

      setTotalCamadas(calculaTotalItens(data.camadas, 'camada'));

      if (gridViewFormacao && gridViewFormacao.current)
        gridViewFormacao.current.setDataSource(data.formacoes);

      setTotalFormacoes(calculaTotalItens(data.formacoes, 'formacao'));
    }, []);

    useEffect(() => {
      (async function func() {
        await load();
      })();
    }, []);

    return (
      <div>
        <div className='row mb-3'>
          <div className='display-8'>4 - Perfil Litológico</div>
        </div>
        <div className='row'>
          <div className='card'>
            <div className='card-body'>
              <div className='row'>
                <div className='bg text-black bg-gradient rounded-2 p-3'>
                  <div className='row mb-3'>
                    <div className='display-8'>Camadas</div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <div className='card'>
                        <div className='card-body'>
                          <div className='row mb-3'>
                            <div className='col-2'>
                              <Textbox
                                id='inicioCamada'
                                label='Inicio (m)'
                                text={dataCamada.nrInicio}
                                maxLength={8}
                                mask={MaskTypes.DecimalCustom}
                                decimalPlaces={2}
                                onChangedValue={(nrInicio) =>
                                  setDataCamada({ ...dataCamada, nrInicio })
                                }
                              />
                            </div>
                            <div className='col-2'>
                              <Textbox
                                label='Fim (m)'
                                text={dataCamada.nrFim}
                                maxLength={8}
                                mask={MaskTypes.DecimalCustom}
                                decimalPlaces={2}
                                onChangedValue={(obj) =>
                                  setDataCamada({
                                    ...dataCamada,
                                    nrFim: obj,
                                    totalCamada:
                                      obj > dataCamada.nrInicio
                                        ? parseFloat(
                                            (obj - dataCamada.nrInicio).toFixed(
                                              2
                                            )
                                          )
                                        : 0,
                                  })
                                }
                              />
                            </div>
                            <div className='col-4'>
                              <Autocomplete
                                label='Camada'
                                searchDataSource={onSearchCamada}
                                selectedItem={dataCamada.camada}
                                onSelectItem={(camada) =>
                                  setDataCamada({
                                    ...dataCamada,
                                    camada,
                                    cdPocPerflitCamada:
                                      camada.cdPocPerflitCamada,
                                    nrSeqPocPerflitCamada:
                                      camada.nrSeqPocPerflitCamada,
                                    noPocPerflitCamada:
                                      camada.noPocPerflitCamada,
                                  })
                                }
                                dataSourceTextProperty='noPocPerflitCamada'
                              />
                            </div>
                            <div className='col'>
                              <Textbox
                                maxLength={60}
                                label='Observação'
                                text={dataCamada.noObservacao}
                                onChangedValue={(noObservacao) => {
                                  data.noObservacao = noObservacao;
                                  setDataCamada({
                                    ...dataCamada,
                                    noObservacao,
                                    salvarEtapa: true,
                                  });
                                }}
                              />
                            </div>

                            <div className='col-auto mt-3'>
                              <Button
                                theme={Theme.Primary}
                                template={Button.Templates.Button}
                                text='Adicionar'
                                onClick={onAdicionarCamada}
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col'>
                              <GridView
                                ref={gridViewCamada}
                                className='table-striped table-hover table-bordered table-sm'
                                dataColumns={columnsCamada}
                                showPagination={false}
                                showSelectSizes={false}
                              />
                            </div>
                            <div className='row'>
                              <div
                                className='col'
                                style={{
                                  textAlign: 'right',
                                  borderTop: '1px solid #0000002b',
                                  borderBottom: '1px solid #0000002b',
                                  paddingTop: '5px',
                                }}
                              >
                                <h6
                                  style={{
                                    padding: '2px',
                                    color: '#605959',
                                  }}
                                >
                                  Total: {totalCamadas}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='bg text-black bg-gradient rounded-2 p-3'>
                  <div className='row mb-3'>
                    <div className='display-8'>Formação</div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <div className='card'>
                        <div className='card-body'>
                          <div className='row mb-3'>
                            <div className='col'>
                              <Textbox
                                id='inicioFormacao'
                                label='Inicio (m)'
                                text={dataFormacao.nrInicio}
                                maxLength={8}
                                mask={MaskTypes.DecimalCustom}
                                decimalPlaces={2}
                                onChangedValue={(nrInicio) =>
                                  setDataFormacao({
                                    ...dataFormacao,
                                    nrInicio,
                                  })
                                }
                              />
                            </div>
                            <div className='col'>
                              <Textbox
                                label='Fim (m)'
                                text={dataFormacao.nrFim}
                                maxLength={8}
                                mask={MaskTypes.DecimalCustom}
                                decimalPlaces={2}
                                onChangedValue={(obj) =>
                                  setDataFormacao({
                                    ...dataFormacao,
                                    nrFim: obj,
                                    totalFormacao:
                                      obj > dataFormacao.nrInicio
                                        ? parseFloat(
                                            (
                                              obj - dataFormacao.nrInicio
                                            ).toFixed(2)
                                          )
                                        : 0,
                                  })
                                }
                              />
                            </div>
                            <div className='col-6'>
                              <Autocomplete
                                label='Formação'
                                searchDataSource={onSearchFormacao}
                                selectedItem={dataFormacao.formacao}
                                onSelectItem={(formacao) =>
                                  setDataFormacao({
                                    ...dataFormacao,
                                    formacao,
                                    nrSeqPocPerflitFormacao:
                                      formacao.nrSeqPocPerflitFormacao,
                                    cdPocPerflitFormacao:
                                      formacao.cdPocPerflitFormacao,
                                    noPocPerflitFormacao:
                                      formacao.noPocPerflitFormacao,
                                  })
                                }
                                dataSourceTextProperty='noPocPerflitFormacao'
                              />
                            </div>
                            <div className='col-auto mt-3'>
                              <Button
                                theme={Theme.Primary}
                                template={Button.Templates.Button}
                                text='Adicionar'
                                onClick={onAdicionarFormacao}
                              />
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col'>
                              <GridView
                                ref={gridViewFormacao}
                                className='table-striped table-hover table-bordered table-sm'
                                dataColumns={columnsFormacao}
                                showPagination={false}
                                showSelectSizes={false}
                              />
                            </div>
                            <div className='row mb-3'>
                              <div
                                className='col'
                                style={{
                                  textAlign: 'right',
                                  borderTop: '1px solid #0000002b',
                                  borderBottom: '1px solid #0000002b',
                                  paddingTop: '5px',
                                }}
                              >
                                <h6
                                  style={{
                                    padding: '2px',
                                    color: '#605959',
                                  }}
                                >
                                  Total: {totalFormacoes}
                                </h6>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col'>
                                <Textarea
                                  maxLength={200}
                                  label='Observação'
                                  text={data.noPocPocoPerflitFormacaoObs}
                                  onChangedValue={(
                                    noPocPocoPerflitFormacaoObs
                                  ) => {
                                    data.noPocPocoPerflitFormacaoObs = noPocPocoPerflitFormacaoObs;
                                    setData({
                                      ...data,
                                      noPocPocoPerflitFormacaoObs,
                                      salvarEtapa: true,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default CadastroPerfilLitologico;
