import qs from 'qs';
import Pagination from 'core/models/pagination';
import OrdemServicoManutencao from 'core/models/FRO/ordemServico_Manutencao';
import OrdemServicoManutencaoSearchPaginedModel from 'core/models/FRO/models/OrdemServicoManutencaoSearchPaginedModel';
import api from '../api';

const baseURL = `/FRO/OrdemServico_Manutencao`;

export const getManutencaoVeiculoOSList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new OrdemServicoManutencao(json)) ?? [],
  };
};

export const getManutencaoVeiculoOSPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new OrdemServicoManutencao(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getFilteredManutencaoVeiculoOSPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPaginedFilters`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data:
      value?.map(
        (json) => new OrdemServicoManutencaoSearchPaginedModel(json)
      ) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getManutencaoVeiculoOS = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ? new OrdemServicoManutencao(value) : {},
  };
};

export const saveManutencaoVeiculoOS = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const createManutencaoVeiculoOS = async (data) => {
  const response = await api.post(`${baseURL}/Create`, data);

  return response.data ?? {};
};

export const createFromAcertoMotorista = async (data) => {
  const response = await api.post(`${baseURL}/CreateFromAcertoMotorista`, data);

  return response.data ?? {};
};

export const deleteManutencaoVeiculoOS = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printOSManutencaoVeiculoOS = async (filters) => {
  const response = await api.post(`${baseURL}/PrintOS`, filters);
  return response.data ?? {};
};

export const printReportManutencaoVeiculoOS = async (filters) => {
  const response = await api.post(`${baseURL}/PrintReport`, filters);
  return response.data ?? {};
};

export const excelManutencaoVeiculoOS = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllManutencaoVeiculoOS = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getManutencaoVeiculoOSAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new OrdemServicoManutencao(json)) ?? [],
  };
};

export const printManutencaoVeiculoOSReportPorOficina = async (filters) => {
  const response = await api.get(`${baseURL}/PrintReportPorOficina`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printManutencaoVeiculoOSReportPorOficinaCompra = async (
  filters
) => {
  const response = await api.get(`${baseURL}/PrintReportPorOficinaCompra`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printManutencaoVeiculoOSReportPorPlaca = async (filters) => {
  const response = await api.get(`${baseURL}/PrintReportPorPlaca`, {
    params: filters,
  });
  return response.data ?? {};
};

export const validaEBuscaOSsPendentesPorPlaca = async (filters) => {
  const response = await api.get(
    `${baseURL}/ValidaEBuscaOSsPendentesPorPlaca`,
    {
      params: filters,
    }
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new OrdemServicoManutencao(json)) ?? [],
  };
};

export const closeOS = async (data) => {
  const response = await api.post(`${baseURL}/CloseOS`, data);
  return response.data ?? {};
};
