import { Plano } from 'core/models/TEL';
import api from '../api';

export const getPlanoAutoComplete = async (filters) => {
  const response = await api.get(`Plano/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    planos: value?.map((json) => new Plano(json)) ?? [],
  };
};

export const getPlanoAutoCompleteDemanda = async (filters) => {
  const response = await api.get(`Plano/AutoCompleteDemanda`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    planos: value?.map((json) => new Plano(json)) ?? [],
  };
};
