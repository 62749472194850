import React, { useState } from 'react';
import { Loader, Modal, Notification, Textbox, Button } from 'ui/components';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/enums';
import { Message } from 'ui/Helpers/interfaces';
import { IPneuModeloRecapagem } from 'core/interfaces/FRO/pneuModeloRecapagem';
import { savePneuModeloRecapagem } from 'core/services/FRO/pneuModeloRecapagem';

interface IProps {
  show: boolean;
  onClose: () => void;
}

export default function ModalPneuModeloRecapagem({ show, onClose }: IProps) {
  const [message, setMessage] = useState<Message | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [
    modeloRecapagem,
    setModeloRecapagem,
  ] = useState<IPneuModeloRecapagem | null>(null);

  const onClickCadastrar = async () => {
    setLoading(true);

    const { status, message: msg } = await savePneuModeloRecapagem(
      modeloRecapagem
    );

    if (status === ResponseStatus.Success) {
      setMessage({ theme: Theme.Success, message: msg });
      setModeloRecapagem(null);
    } else {
      setMessage({ theme: Theme.Danger, message: msg });
    }

    setLoading(false);
  };

  return (
    // @ts-expect-error
    <Modal
      show={show}
      title='Cadastro Rápido de Modelo de Recapagem'
      size={BootstrapSizes.Small}
      onClose={onClose}
    >
      <Loader loading={loading} />
      {message && (
        <div className='m-3'>
          {/* @ts-expect-error */}
          <Notification
            message={message.message}
            theme={message.theme}
            onClose={() => setMessage(null)}
          />
        </div>
      )}

      {/* @ts-expect-error */}
      <Modal.Body>
        <div className='row'>
          <div className='col-7'>
            {/* @ts-expect-error */}
            <Textbox
              label='Modelo Recapagem'
              text={modeloRecapagem?.noPneuModeloRecapagem}
              onChangedValue={(noPneuModeloRecapagem: string) =>
                setModeloRecapagem({ noPneuModeloRecapagem })
              }
            />
          </div>
          <div className='col d-flex align-items-end'>
            <Button
              text='Cadastrar'
              theme={Theme.Success}
              onClick={onClickCadastrar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
