import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Panel,
  Textbox,
  Switch,
  Autocomplete,
  Textarea,
  GridView,
  Button,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { BootstrapSizes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Theme, ResponseStatus, StatusGrid } from 'ui/Helpers/enums.ts';
import { getTipoEmbarqueAutoComplete } from 'core/services/FRO/tipoEmbarque';
import { getConsignatarioAutoComplete } from 'core/services/FRO/coletaConsignatario';
import { getPessoaAutoCompleteRemDesCli } from 'core/services/SEG';
import { getTipoFreteAutoComplete } from 'core/services/FRO/tipoFrete';
import { getFreteMercadoriaAutoComplete } from 'core/services/FRO/freteMercadoria';
import { getEspecieFreteAutoComplete } from 'core/services/FRO/especieFrete';
import { getCondicaoPagamentoAutoComplete } from 'core/services/FIN/condicaoPagamento';
import { getProdutoUnidadeMedidaAutoComplete } from 'core/services/EST/produtoUnidadeMedida';
import RemetenteDestinatario from 'core/models/FRO/remetente_Destinatario';

interface Props {
  coletaDestinatarioData: any;
  setColetaDestinatarioData: (object: any) => void;
  onSetMessage: (status: ResponseStatus, msg: string) => void;
  status?: StatusGrid;
}

const DestinatarioPanel = forwardRef(
  (
    { coletaDestinatarioData, setColetaDestinatarioData, onSetMessage }: Props,
    ref
  ) => {
    useEffect(() => {
      const objDestinatario = { ...coletaDestinatarioData };
      setColetaDestinatarioData(objDestinatario);
    }, [coletaDestinatarioData.nrSeqPessoaDes]);

    const [remetenteDestinatarioData, setRemetenteDestinatarioData] = useState(
      new RemetenteDestinatario({})
    );
    const gridViewRemetenteDestinatario = useRef();

    const fillGridView = () => {
      if (
        gridViewRemetenteDestinatario &&
        gridViewRemetenteDestinatario.current &&
        coletaDestinatarioData.remetenteDestinatario &&
        coletaDestinatarioData.remetenteDestinatario.length > 0
      ) {
        const gridList = coletaDestinatarioData.remetenteDestinatario.map(
          (item) => item
        );
        gridViewRemetenteDestinatario.current.setDataSource(gridList);
      }
    };

    useImperativeHandle(ref, () => ({
      clear() {
        setRemetenteDestinatarioData({ noObservacaoCte: '' });
        gridViewRemetenteDestinatario?.current?.setDataSource([]);
      },
      fillGrid() {
        fillGridView();
      },
      getDataGrid() {
        const listaRemDesGrid =
          gridViewRemetenteDestinatario?.current?.getDataSource() ?? [];
        return listaRemDesGrid;
      },
    }));

    const onSearchPessoaConsig = async (e: any) => {
      const { data: pessoaConsigs } = await getConsignatarioAutoComplete({
        noPessoaConsig: e,
      });

      return pessoaConsigs;
    };

    const onSearchRemetente = async (e: any) => {
      const { data: remetentes } = await getPessoaAutoCompleteRemDesCli({
        noPessoa: e,
        noPessoaTipo: 'REM',
      });

      return remetentes;
    };

    const onSearchTipoFrete = async (e: any) => {
      const { tiposFrete } = await getTipoFreteAutoComplete({ noTipoFrete: e });

      return tiposFrete;
    };

    const onSearchPessoaConsigOri = async (e: any) => {
      const { data: pessoaConsigOris } = await getConsignatarioAutoComplete({
        noPessoa: e,
      });
      return pessoaConsigOris;
    };

    const onSearchPessoaConsigDest = async (e: any) => {
      const { data: pessoaConsigDests } = await getConsignatarioAutoComplete({
        noPessoa: e,
      });

      return pessoaConsigDests;
    };

    const onSearchTipoEmbarque = async (e: string) => {
      const { data: tiposEmbarque } = await getTipoEmbarqueAutoComplete({
        noTipoEmbarque: e,
      });

      return tiposEmbarque;
    };

    const onSearchFreteMercadoria = async (e) => {
      const { data: freteMercadorias } = await getFreteMercadoriaAutoComplete({
        noFreteMercadoria: e,
      });

      return freteMercadorias;
    };

    const onSearchEspecieFrete = async (e: string) => {
      const { data: especiesFrete } = await getEspecieFreteAutoComplete({
        noEspecieFrete: e,
      });
      return especiesFrete;
    };

    const onSearchCondicaoPagamento = async (e) => {
      const { condicoesPagamento } = await getCondicaoPagamentoAutoComplete({
        noCondicaoPagamento: e,
      });

      return condicoesPagamento;
    };

    const onSearchUniMedProduto = async (e) => {
      const {
        data: unidadesMedida,
      } = await getProdutoUnidadeMedidaAutoComplete({
        noProdutoUnidadeMedida: e,
      });

      return unidadesMedida;
    };

    const onClickEditProduto = async (selectedValue, datasource) => {
      const dataOrdemRemDes = datasource.find((item) => item === selectedValue);
      dataOrdemRemDes.status = GridView.EnumStatus.Alterar;

      const dataProdutos = datasource.filter(
        (produto) => produto !== selectedValue
      );

      setRemetenteDestinatarioData(dataOrdemRemDes);

      if (
        gridViewRemetenteDestinatario &&
        gridViewRemetenteDestinatario.current
      )
        gridViewRemetenteDestinatario.current.setDataSource(dataProdutos);
    };

    const onRemoveOrdemServico = async (e, datasource) => {
      const index = datasource.findIndex((item) => item === e);
      datasource[index].status = GridView.EnumStatus.Remover;

      if (
        gridViewRemetenteDestinatario &&
        gridViewRemetenteDestinatario.current
      )
        gridViewRemetenteDestinatario.current.setDataSource(datasource);
    };

    const onAdicionarRemDest = async () => {
      if (
        remetenteDestinatarioData.nrSeqTipoFrete > 0 &&
        remetenteDestinatarioData.nrSeqTipoEmbarque > 0 &&
        remetenteDestinatarioData.nrSeqFreteMercadoria > 0 &&
        remetenteDestinatarioData.nrSeqEspecieFrete > 0 &&
        remetenteDestinatarioData.nrSeqProdutoUnidadeMedida > 0 &&
        remetenteDestinatarioData.nrSeqCondicaoPagamento > 0 &&
        remetenteDestinatarioData.vlrFrete > 0
      ) {
        const obj = {
          ...remetenteDestinatarioData,
          status: remetenteDestinatarioData.status ?? StatusGrid.Inserir,
        };

        const list = gridViewRemetenteDestinatario.current.getDataSource();
        list.push(obj);

        if (
          gridViewRemetenteDestinatario &&
          gridViewRemetenteDestinatario.current
        )
          gridViewRemetenteDestinatario.current.setDataSource(list);

        setColetaDestinatarioData({
          ...coletaDestinatarioData,
          remetenteDestinatario: list,
        });
        setRemetenteDestinatarioData({
          noObservacaoCte: '',
          vlrFrete: 0,
          vlrPedagio: 0,
        });
      } else if (
        remetenteDestinatarioData.nrSeqPessoaRem === undefined ||
        remetenteDestinatarioData.nrSeqPessoaRem === null
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o Remetente');
      } else if (
        remetenteDestinatarioData.nrSeqTipoFrete === undefined ||
        remetenteDestinatarioData.nrSeqTipoFrete === null
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o Tipo frete');
      } else if (
        remetenteDestinatarioData.nrSeqTipoEmbarque === undefined ||
        remetenteDestinatarioData.nrSeqTipoEmbarque === null
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o Tipo Embarque');
      } else if (
        remetenteDestinatarioData.nrSeqFreteMercadoria === undefined ||
        remetenteDestinatarioData.nrSeqFreteMercadoria === null
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o frete mercadoria');
      } else if (
        remetenteDestinatarioData.nrSeqProdutoUnidadeMedida === undefined ||
        remetenteDestinatarioData.nrSeqProdutoUnidadeMedida === null
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe a Unidade de Medida');
      } else if (
        remetenteDestinatarioData.nrSeqEspecieFrete === undefined ||
        remetenteDestinatarioData.nrSeqEspecieFrete === null
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o especie frete');
      } else if (
        remetenteDestinatarioData.nrSeqCondicaoPagamento === undefined ||
        remetenteDestinatarioData.nrSeqCondicaoPagamento === null
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe a condição de pagamento');
      } else if (
        remetenteDestinatarioData.vlrPedagio < 0 ||
        remetenteDestinatarioData.vlrPedagio === undefined
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o valor do Pedágio');
      } else if (
        remetenteDestinatarioData.vlrFrete <= 0 ||
        remetenteDestinatarioData.vlrFrete === undefined
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o valor do frete');
      }
    };

    const columns = [
      {
        key: 'nrSeqPessoaRem',
        title: 'nrSeqPessoaRem',
        visible: false,
      },
      {
        key: 'remetente.noPessoa',
        title: 'Remetente',
      },
      { key: 'tipoFrete.noTipoFrete', title: 'Tipo Frete' },
      { key: 'tipoEmbarque.noTipoEmbarque', title: 'Tipo Embarque' },
      {
        key: 'flgEnviaEmailCliente',
        title: 'Envia Email',
        format: GridView.DataTypes.Boolean,
      },
      {
        key: 'remetente.endereco.cidade.noCidade',
        title: 'Cidade',
      },
      {
        key: 'nrSeqPessoaRem',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onClickEditProduto(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },
      // ADICIONANDO BOTÃO DE DELEÇÃO
      {
        key: 'nrSeqPessoaRem',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemoveOrdemServico(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    return (
      <div className='col mt-3'>
        {/* @ts-expect-error */}
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header title='Destinatário' theme={Theme.Primary} />

          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-1 mb-3'>
                <Switch
                  formControl
                  label='Ativo'
                  checked={coletaDestinatarioData.flgAtivo}
                  onChange={(flgAtivo: boolean) =>
                    setColetaDestinatarioData({
                      ...coletaDestinatarioData,
                      flgAtivo,
                    })
                  }
                />
              </div>
              <div className='col-5'>
                {/* @ts-expect-error */}
                <Autocomplete
                  label='Consignatário'
                  searchDataSource={onSearchPessoaConsig}
                  selectedItem={coletaDestinatarioData.coletaConsignatario}
                  onSelectItem={(coletaConsignatario: any) =>
                    setColetaDestinatarioData({
                      ...coletaDestinatarioData,
                      coletaConsignatario,
                      nrSeqPessoaConsig: coletaConsignatario.nrSeqPessoaConsig,
                    })
                  }
                  dataSourceTextProperty='noPessoa'
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
        <div className='row mb-3'>
          <div className='col-12 mb-3'>
            <Panel>
              {/* @ts-expect-error */}
              <Panel.Header title='Remetentes' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-6'>
                    <Autocomplete
                      label='Remetente'
                      searchDataSource={onSearchRemetente}
                      selectedItem={remetenteDestinatarioData.remetente}
                      onSelectItem={(remetente) => {
                        setRemetenteDestinatarioData({
                          ...remetenteDestinatarioData,
                          remetente,
                          nrSeqPessoaRem: remetente.nrSeqPessoa,
                        });
                      }}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                  <div className='col-3 mb-3'>
                    <Autocomplete
                      label='Tipo de Frete'
                      searchDataSource={onSearchTipoFrete}
                      selectedItem={remetenteDestinatarioData.tipoFrete}
                      onSelectItem={(tipoFrete) => {
                        setRemetenteDestinatarioData({
                          ...remetenteDestinatarioData,
                          tipoFrete,
                          nrSeqTipoFrete: tipoFrete.nrSeqTipoFrete,
                        });
                      }}
                      dataSourceTextProperty='noTipoFrete'
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col mb-3'>
                    <Autocomplete
                      label='Consignatário Origem'
                      searchDataSource={onSearchPessoaConsigOri}
                      selectedItem={remetenteDestinatarioData.origemConsig}
                      onSelectItem={(origemConsig) => {
                        setRemetenteDestinatarioData({
                          ...remetenteDestinatarioData,
                          origemConsig,
                          nrSeqPessoaConsigOri: origemConsig.nrSeqPessoaConsig,
                        });
                      }}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                  <div className='col mb-3'>
                    <Autocomplete
                      label='Consignatário Destino (Local de Entrega)'
                      searchDataSource={onSearchPessoaConsigDest}
                      selectedItem={remetenteDestinatarioData.destinoConsig}
                      onSelectItem={(destinoConsig) => {
                        setRemetenteDestinatarioData({
                          ...remetenteDestinatarioData,
                          destinoConsig,
                          nrSeqPessoaConsigDest:
                            destinoConsig.nrSeqPessoaConsig,
                        });
                      }}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-4'>
                    <Autocomplete
                      label='Tipo Embarque'
                      searchDataSource={onSearchTipoEmbarque}
                      selectedItem={remetenteDestinatarioData.tipoEmbarque}
                      onSelectItem={(tipoEmbarque: any) => {
                        setRemetenteDestinatarioData({
                          ...remetenteDestinatarioData,
                          tipoEmbarque,
                          nrSeqTipoEmbarque: tipoEmbarque.nrSeqTipoEmbarque,
                        });
                      }}
                      dataSourceTextProperty='noTipoEmbarque'
                    />
                  </div>
                  <div className='col-3 mb-3'>
                    <Autocomplete
                      label='Frete Mercadoria'
                      searchDataSource={onSearchFreteMercadoria}
                      selectedItem={remetenteDestinatarioData.freteMercadoria}
                      onSelectItem={(freteMercadoria) => {
                        setRemetenteDestinatarioData({
                          ...remetenteDestinatarioData,
                          freteMercadoria,
                          nrSeqFreteMercadoria:
                            freteMercadoria.nrSeqFreteMercadoria,
                        });
                      }}
                      dataSourceTextProperty='noFreteMercadoria'
                    />
                  </div>
                  <div className='col-5'>
                    <Autocomplete
                      label='Produto Unidade Medida'
                      searchDataSource={onSearchUniMedProduto}
                      selectedItem={
                        remetenteDestinatarioData.produtoUnidadeMedida
                      }
                      onSelectItem={(produtoUnidadeMedida) =>
                        setRemetenteDestinatarioData({
                          ...remetenteDestinatarioData,
                          produtoUnidadeMedida,
                          nrSeqProdutoUnidadeMedida:
                            produtoUnidadeMedida.nrSeqProdutoUnidadeMedida,
                        })
                      }
                      dataSourceTextProperty='noProdutoUnidadeMedida'
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-4'>
                    <Autocomplete
                      label='Espécie Frete'
                      searchDataSource={onSearchEspecieFrete}
                      selectedItem={remetenteDestinatarioData.especieFrete}
                      onSelectItem={(especieFrete: any) => {
                        setRemetenteDestinatarioData({
                          ...remetenteDestinatarioData,
                          especieFrete,
                          nrSeqEspecieFrete: especieFrete.nrSeqEspecieFrete,
                        });
                      }}
                      dataSourceTextProperty='noEspecieFrete'
                    />
                  </div>
                  <div className='col mb-3'>
                    <Autocomplete
                      label='Condição de Pagamento'
                      searchDataSource={onSearchCondicaoPagamento}
                      selectedItem={remetenteDestinatarioData.condicaoPagamento}
                      onSelectItem={(condicaoPagamento) => {
                        setRemetenteDestinatarioData({
                          ...remetenteDestinatarioData,
                          condicaoPagamento,
                          nrSeqCondicaoPagamento:
                            condicaoPagamento.nrSeqCondicaoPagamento,
                        });
                      }}
                      dataSourceTextProperty='noCondicaoPagamento'
                    />
                  </div>
                  <div className='col-2'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='Valor Frete'
                      text={remetenteDestinatarioData.vlrFrete}
                      mask={MaskTypes.DecimalCustom}
                      maxLength={10}
                      decimalPlaces={2}
                      onChangedValue={(vlrFrete: string) => {
                        setRemetenteDestinatarioData({
                          ...remetenteDestinatarioData,
                          vlrFrete,
                        });
                      }}
                    />
                  </div>
                  <div className='col-2'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='Valor Pedágio'
                      text={remetenteDestinatarioData.vlrPedagio}
                      mask={MaskTypes.DecimalCustom}
                      maxLength={10}
                      decimalPlaces={2}
                      onChangedValue={(vlrPedagio: string) => {
                        setRemetenteDestinatarioData({
                          ...remetenteDestinatarioData,
                          vlrPedagio,
                        });
                      }}
                    />
                  </div>
                  <div className='col-1'>
                    {/* @ts-expect-error */}
                    <Switch
                      formControl
                      checked={remetenteDestinatarioData?.flgPedagioCliente}
                      label='Pedágio Cliente'
                      onChange={(flgPedagioCliente: boolean) =>
                        setRemetenteDestinatarioData({
                          ...remetenteDestinatarioData,
                          flgPedagioCliente,
                        })
                      }
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-6'>
                    <Textarea
                      label='Observação'
                      text={remetenteDestinatarioData.noObservacaoCte}
                      maxLength={150}
                      rows={2}
                      onChangedValue={(noObservacaoCte: string) =>
                        setRemetenteDestinatarioData({
                          ...remetenteDestinatarioData,
                          noObservacaoCte,
                        })
                      }
                    />
                  </div>
                  <div className='col-1'>
                    {/* @ts-expect-error */}
                    <Switch
                      formControl
                      checked={remetenteDestinatarioData?.flgEnviaEmailCliente}
                      label='Envia Email Cliente'
                      onChange={(flgEnviaEmailCliente: boolean) =>
                        setRemetenteDestinatarioData({
                          ...remetenteDestinatarioData,
                          flgEnviaEmailCliente,
                        })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='Dia Fixo'
                      maxLength={2}
                      text={remetenteDestinatarioData.nrDiaFixo}
                      onChangedValue={(nrDiaFixo: string) => {
                        setRemetenteDestinatarioData({
                          ...remetenteDestinatarioData,
                          nrDiaFixo,
                        });
                      }}
                    />
                  </div>
                  <div className='col-2 mb-3'>
                    <Button
                      outline
                      size={BootstrapSizes.Small}
                      theme={Theme.Success}
                      template={Button.Templates.Quick}
                      onClick={onAdicionarRemDest}
                      text='Adicionar'
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <GridView
                      ref={gridViewRemetenteDestinatario}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columns}
                      showPagination={false}
                      showSelectSizes={false}
                      offlineData
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
);

export default DestinatarioPanel;
