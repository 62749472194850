export default class ProdutoUnidadeMedida {
  constructor(jsonObject = {}) {
    this.nrSeqProdutoUnidadeMedida = jsonObject.nrSeqProdutoUnidadeMedida;
    this.cdProdutoUnidadeMedida = jsonObject.cdProdutoUnidadeMedida;
    this.noProdutoUnidadeMedida = jsonObject.noProdutoUnidadeMedida;
    this.flgDecimal = jsonObject.flgDecimal;
    this.qtdeDecimal = jsonObject.qtdeDecimal;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.grupoempresa = jsonObject.grupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
