import React, { useState } from 'react';
import './tooltip.css';

export default function Tooltip(props) {
  const { children, content } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      className='custom-tooltip'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && <div className='tooltip-content'>{content}</div>}
    </div>
  );
}
