import qs from 'qs';
import Pagination from 'core/models/pagination';
import PocTipoOrcamento from 'core/models/POC/pocTipoOrcamento';
import api from '../api';

const baseURL = `/POC/PocTipoOrcamento`;

export const getPocTipoOrcamentoList = async (filters) => {
  const response = await api.get(`/${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocTipoOrcamento(json)) ?? [],
  };
};

export const getPocTipoOrcamentoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocTipoOrcamento(json)) ?? [],
  };
};

export const getPocTipoOrcamentoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocTipoOrcamento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocTipoOrcamento = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocTipoOrcamento(value) : {};
};

export const savePocTipoOrcamento = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePocTipoOrcamento = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPocTipoOrcamento = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocTipoOrcamento = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocTipoOrcamento = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
