export default class PrioridadeChamado {
  nrSeqPrioridadeChamado: number;

  nrSeqGrupoEmpresa: number;

  cdPrioridade: string;

  noPrioridade: string;

  nivel: number;

  cor: string;

  constructor(jsonObject: PrioridadeChamado) {
    this.nrSeqPrioridadeChamado = jsonObject.nrSeqPrioridadeChamado;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.cdPrioridade = jsonObject.cdPrioridade;
    this.noPrioridade = jsonObject.noPrioridade;
    this.nivel = jsonObject.nivel;
    this.cor = jsonObject.cor;
  }

  toJson = () => JSON.stringify(this);
}
