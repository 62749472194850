import qs from 'qs';
import Pagination from 'core/models/pagination';
import SolicitanteCompra from 'core/models/COP/solicitanteCompra';
import api from '../api';

const baseURL = `/COP/SolicitanteCompra`;

export const getSolicitanteCompraList = async (filters) => {
  const response = await api.get(`/$SolicitanteCompra/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new SolicitanteCompra(json)) ?? [],
  };
};

export const getSolicitanteCompraPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new SolicitanteCompra(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getSolicitanteCompra = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new SolicitanteCompra(value) : {};
};

export const saveSolicitanteCompra = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteSolicitanteCompra = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printSolicitanteCompra = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelSolicitanteCompra = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllSolicitanteCompra = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getSolicitanteCompraAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new SolicitanteCompra(json)) ?? [],
  };
};
