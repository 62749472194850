import qs from 'qs';
import Pagination from 'core/models/pagination';
import OrdemServico from 'core/models/FIN/ordemServico';
import api from '../api';

const baseURL = `/FIN/OrdemServico`;

export const getOrdemServicoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new OrdemServico(json)) ?? [],
  };
};

export const getOrdemServicoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new OrdemServico(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const EncerraOrdemServico = async (data) => {
  const response = await api.post(`${baseURL}/EncerraOrdemServico`, data);
  const { status, message, value } = response.data;
  return {
    status,
    message,
    data: new OrdemServico(value ?? {}),
  };
};

export const CancelarOrdemServico = async (data) => {
  const response = await api.post(`${baseURL}/CancelarOrdemServico`, data);
  const { status, message, value } = response.data;
  return {
    status,
    message,
    data: new OrdemServico(value ?? {}),
  };
};

export const getOrdemServico = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new OrdemServico(value) : {};
};

export const saveOrdemServico = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const geraOrdemServico = async (data) => {
  const response = await api.post(`${baseURL}/GeraOrdemServico`, data);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const geraRomaneio = async (data) => {
  const response = await api.post(`${baseURL}/GeraRomaneio`, data);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const gerarOSDip = async (data) => {
  const response = await api.post(`${baseURL}/GerarOSDIP`, data);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const gerarRomaneioDip = async (data) => {
  const response = await api.post(`${baseURL}/GerarRomaneioDIP`, data);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const deleteOrdemServico = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printOrdemServico = async (filters) => {
  const response = await api.get(`${baseURL}/PrintOrdemServico`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printOrdemServicoRelatorio = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelOrdemServico = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data.value ?? {};
};

export const deleteAllOrdemServico = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getOrdemServicoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new OrdemServico(json)) ?? [],
  };
};
