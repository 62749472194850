import React, { useState, useRef } from 'react';
import { ResponseStatus, BootstrapSizes, Theme } from 'ui/Helpers/utils';

import { CSDSelPage, GridView, Switch, ToolbarButtons } from 'ui/components';

import { getGruposContrato, getDetalheLinha } from 'core/services/TEL';

import PanelSelecaoContratosSemFatura from './panelSelecaoContratosSemFatura';
import PanelGestaoContaFiltroLinha from './panelGestaoContaFiltroLinha';
import PanelGestaoContaFiltroGrupoLinha from './panelGestaoContaFiltroGrupoLinha';
import ModalDetalheGrupoLinha from './modalDetalheGrupoLinha';
import ModalAtivarBloquearLinha from './modalAtivarBloquearLinha';
import LinhasNaoRecuperadas from './linhasNaoRecuperadas';
import ModalMoverLinhaGrupo from './modalMoverLinhaGrupo';
import ModalAtivarBloquearServicos from './modalAtivarBloquearServicos';
import ModalResetarCotaGrupo from './modalResetarCotaGrupo';

const SelecaoGestaoOperadora = ({ isActive, transaction }) => {
  const [contratosNaoRecuperados, setContratosNaoRecuperados] = useState([]);
  const [linhasNaoRecuperadas, setLinhasNaoRecuperadas] = useState([]);
  const [linhasRecuperadas, setLinhasRecuperadas] = useState([]);
  const linhasSelecionadasRef = useRef();
  const gridViewGrupo = useRef();
  const gridViewLinha = useRef();
  const [flgFiltros, setFlgFilters] = useState({
    flgFiltroLinhaContrato: false,
    flgFiltroGrupoLinhas: false,
  });

  const [filters, setFilters] = useState({
    page: 1,
  });
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [detalheGrupoLinhas, setDetalheGrupoLinhas] = useState({});
  const [ativarBloquearLinha, setAtivarBloquearLinha] = useState({});
  const [modalShow, setModalShow] = useState({});
  const [linhasServicos, setLinhasServicos] = useState([]);
  const [ativarBloquearServicos, setAtivarBloquearServicos] = useState({});
  const [listaLinhasSelecionadas, setListaLinhasSelecionadas] = useState([]);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    else setMessage(null);
  };

  const limparPesquisa = () => {
    linhasRecuperadas.forEach((e) => {
      linhasRecuperadas.pop(e);
    });
    linhasNaoRecuperadas.forEach((e) => {
      linhasNaoRecuperadas.pop(e);
    });
  };

  const onSearch = async () => {
    setContratosNaoRecuperados([]);

    limparPesquisa();

    onSetMessage();
    setLoading(true);

    if (flgFiltros.flgFiltroLinhaContrato) {
      const {
        status,
        message: msg,
        listaAgrupado,
        listaContratosNaoRecuperados,
      } = await getGruposContrato(filters);

      if (listaContratosNaoRecuperados?.length > 0) {
        setContratosNaoRecuperados(listaContratosNaoRecuperados);
      }

      onSetMessage(status, msg);

      if (gridViewGrupo && gridViewGrupo.current) {
        gridViewGrupo.current.setDataSource(listaAgrupado);
      }

      setLoading(false);
    } else {
      for (
        let i = 0;
        i < linhasSelecionadasRef.current.lin.linha.length;
        i = +20
      ) {
        const listaMap = linhasSelecionadasRef.current.lin.linha.map(
          (nrseq) => nrseq.nrSeqChip
        );
        const obj = {
          nrSeqChips: listaMap.splice(i, 20),
        };
        const {
          status,
          message: msg,
          listaAgrupado,
          listaLinhasNaoRecuperadas,
          pagination,
        } = await getDetalheLinha(obj); // eslint-disable-line no-await-in-loop

        if (status === ResponseStatus.Error) {
          onSetMessage(ResponseStatus.Error, msg);
          setLoading(false);
          return;
        }

        if (listaLinhasNaoRecuperadas?.length > 0) {
          linhasNaoRecuperadas.push(listaLinhasNaoRecuperadas);
          const listaNaoR = [];
          linhasNaoRecuperadas.forEach((item) => {
            item.forEach((item1) => {
              listaNaoR.push(item1);
            });
          });
          setLinhasNaoRecuperadas(listaNaoR);
        }

        if (listaAgrupado?.length > 0) {
          linhasRecuperadas.push(listaAgrupado);
          setLinhasRecuperadas(linhasRecuperadas);
          const listaGrupos = [];
          listaAgrupado.forEach((e) => {
            listaGrupos.push({ id: e.id, nome: e.nome });
          });
        }

        const listaAgrupadoNova = [];
        if (gridViewLinha && gridViewLinha.current)
          linhasRecuperadas.forEach((item) => {
            item.forEach((item1) => {
              listaAgrupadoNova.push(item1);
            });
          });
        pagination.totalRows = listaAgrupadoNova.length;
        pagination.totalByPage = listaAgrupadoNova.length;
        gridViewLinha.current.setDataSource(listaAgrupadoNova, pagination);
      }

      setLoading(false);
    }
  };

  const onChangeFiltrarContrato = (e) => {
    limparPesquisa();
    setFlgFilters({
      ...flgFiltros,
      flgFiltroLinhaContrato: e,
    });
  };

  const onAtivarBloquearDados = async (e) => {
    setModalShow({ ...modalShow, enviarAtivaBloqueiaLinhaDados: true });
    setAtivarBloquearLinha(e);
  };

  const onMoverLinhaGrupo = async (e = null) => {
    const selecteds = gridViewLinha.current.getCheckBoxValuesAt(0);

    if ((!selecteds || selecteds.length <= 0) && !e)
      onSetMessage(
        ResponseStatus.Error,
        'Nenhuma linha selecionada para mover de grupo.'
      );
    else if (e) {
      setListaLinhasSelecionadas([{ linha: e.linha, nrSeqChip: e.nrSeqChip }]);
    } else {
      const linhas = selecteds.map((column) => ({
        nrSeqChip: column.find((prop) => prop.key === 'nrSeqChip').value,
        linha: column.find((prop) => prop.key === 'linhaFormatada').value,
      }));
      setListaLinhasSelecionadas(linhas);
    }

    setModalShow({ ...modalShow, moverLinhaGrupo: true });
  };

  const columnsGrupo = [
    { key: 'contrato', title: 'Contrato' },
    { key: 'nome', title: 'Grupo' },
    { key: 'cotaDadosFormatado', title: 'Cota de Dados' },
    { key: 'cotaDadosNaoAtribuidasFormatado', title: 'Cota Não Atribuída' },
    { key: 'consumoDadosFormatado', title: 'Consumo Dados' },
    { key: 'percentualConsumoCota', title: 'Percentual Consumo Cota' },
    { key: 'totalLinhas', title: 'Total Linhas' },
    {
      key: 'id',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => setDetalheGrupoLinhas(e),
      theme: Theme.Secondary,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Exibir Detalhes do Grupo',
      tooltipDirection: 'bottom',
    },
  ];
  const columnsLinha = [
    { key: 'nrSeqChip', type: GridView.ColumnTypes.Checkbox },
    { key: 'linhaFormatada', title: 'Linha' },
    { key: 'grupo', title: 'Grupo' },
    { key: 'consumoDadosFormatado', title: 'Consumo de Dados' },
    { key: 'consumoVozFormatado', title: 'Consumo de Voz' },
    { key: 'cotaDadosFormatado', title: 'Cota de Dados' },
    { key: 'percentualConsumoCota', title: 'Percentual de Consumo da Cota' },
    { key: 'bloqueadoDados', title: 'Status Dados' },
    { key: 'bloqueadoVoz', title: 'Status Voz' },
    { key: 'bloqueadoSMS', title: 'Status SMS' },
    {
      key: 'nrSeqChip',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onAtivarBloquearDados(e),
      theme: Theme.Info,
      icon: 'tasks',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Ativar / Bloquear Dados',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqChip',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onMoverLinhaGrupo(e),
      theme: Theme.Success,
      icon: 'random',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Mover Linha de Grupo',
      tooltipDirection: 'bottom',
    },
  ];

  const onAtivarBloquearServicos = async () => {
    const selecteds = gridViewLinha.current.getCheckBoxValuesAt(0);

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Nenhuma linha selecionada para mover de grupo.'
      );
    else {
      const linhas = selecteds.map((column) => ({
        linha: column.find((prop) => prop.key === 'linhaFormatada').value,
      }));
      setLinhasServicos(linhas);
      setModalShow({ ...modalShow, enviarAtivaBloqueiaServicos: true });
      setAtivarBloquearServicos(linhas);
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Gestão Conta'
      loading={loading}
      onSearch={onSearch}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Ativar Serviços'
          icon='tasks'
          onClick={onAtivarBloquearServicos}
        />
        <ToolbarButtons.Button
          text='Alterar Grupo'
          icon='random'
          onClick={() => onMoverLinhaGrupo()}
        />
        <ToolbarButtons.Button
          text='Resetar Cota de Grupo'
          icon='sync-alt'
          onClick={() =>
            setModalShow({ ...modalShow, modalResetarCotaGrupo: true })
          }
        />
      </ToolbarButtons>
      <div className='row'>
        <div className='mb-3'>
          <div className='col-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='row mb-2'>
                  <div className='row'>
                    <div className='col-2'>
                      <Switch
                        formControl
                        label='Filtrar Contratos'
                        onChange={(e) => onChangeFiltrarContrato(e)}
                      />
                    </div>
                    <div className='col-2'>
                      {!flgFiltros.flgFiltroLinhaContrato && (
                        <Switch
                          formControl
                          label='Grupo de Linhas'
                          onChange={(flgFiltroGrupoLinhas) =>
                            setFlgFilters({
                              ...flgFiltros,
                              flgFiltroGrupoLinhas,
                            })
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='mb-3'>
          <div className='col-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='row mb-2'>
                  <div className='row'>
                    {flgFiltros.flgFiltroLinhaContrato ? (
                      <div>
                        <div className='col'>
                          <PanelSelecaoContratosSemFatura
                            contratoAgrupado={selectedItems.contratoAgrupado}
                            onContratoAgrupadoChange={(contratoAgrupado) => {
                              setSelectedItems({
                                ...selectedItems,
                                contratoAgrupado,
                              });
                              setFilters({
                                ...filters,
                                nrSeqContratoAgrupado:
                                  contratoAgrupado.nrSeqContratoAgrupado,
                              });
                            }}
                            contrato={selectedItems.contratoAgrupado}
                            onContratoChange={(contrato) => {
                              setSelectedItems({ ...selectedItems, contrato });
                              setFilters({
                                ...filters,
                                cdCliente: contrato.cdCliente,
                              });
                            }}
                            onMessage={(msg, theme) =>
                              setMessage({ message: msg, theme })
                            }
                          />
                        </div>
                        {contratosNaoRecuperados.length > 0 && (
                          <div className='row'>
                            <div className='col-12'>
                              <div
                                className='accordion'
                                id='accordionContratosNaoRecuperados'
                              >
                                <div className='accordion-item'>
                                  <h2
                                    className='accordion-header'
                                    id='headingOne'
                                  >
                                    <button
                                      className='accordion-button col-12'
                                      type='button'
                                      data-bs-toggle='collapse'
                                      data-bs-target='#collapseOne'
                                      aria-expanded='true'
                                      aria-controls='collapseOne'
                                    >
                                      Contratos Não Recuperados
                                    </button>
                                  </h2>
                                  <div
                                    id='collapseOne'
                                    className='accordion-collapse collapse show'
                                  >
                                    <div className='accordion-body'>
                                      <LinhasNaoRecuperadas
                                        linhas={contratosNaoRecuperados}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className='row mt-3'>
                          <div className='col'>
                            <GridView
                              ref={gridViewGrupo}
                              className='table-striped table-hover table-bordered table-sm'
                              dataColumns={columnsGrupo}
                              canControlVisibility
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className='col-8' />
                        {linhasNaoRecuperadas.length > 0 && (
                          <div className='row'>
                            <div className='col-12'>
                              <div
                                className='accordion'
                                id='accordionLinhasNaoRecuperadas'
                              >
                                <div className='accordion-item'>
                                  <h2
                                    className='accordion-header'
                                    id='headingOne'
                                  >
                                    <button
                                      className='accordion-button col-12'
                                      type='button'
                                      data-bs-toggle='collapse'
                                      data-bs-target='#collapseOne'
                                      aria-expanded='true'
                                      aria-controls='collapseOne'
                                    >
                                      Contratos Não Recuperados
                                    </button>
                                  </h2>
                                  <div
                                    id='collapseOne'
                                    className='accordion-collapse collapse show'
                                  >
                                    <div className='accordion-body'>
                                      <LinhasNaoRecuperadas
                                        linhas={linhasNaoRecuperadas}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {flgFiltros.flgFiltroGrupoLinhas ? (
                          <PanelGestaoContaFiltroGrupoLinha
                            ref={linhasSelecionadasRef}
                          />
                        ) : (
                          <PanelGestaoContaFiltroLinha
                            ref={linhasSelecionadasRef}
                          />
                        )}
                        <div className='row mt-3'>
                          <div className='col'>
                            <GridView
                              ref={gridViewLinha}
                              className='table-striped table-hover table-bordered table-sm'
                              dataColumns={columnsLinha}
                              canControlVisibility
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalDetalheGrupoLinha
        detalheGrupoLinhas={detalheGrupoLinhas}
        onClose={() => setDetalheGrupoLinhas({})}
      />
      <ModalAtivarBloquearLinha
        ativarBloquearLinha={ativarBloquearLinha}
        show={modalShow.enviarAtivaBloqueiaLinhaDados}
        onSearch={onSearch}
        onClose={() =>
          setModalShow({ ...modalShow, enviarAtivaBloqueiaLinhaDados: false })
        }
      />
      <ModalAtivarBloquearServicos
        linhasServicos={linhasServicos}
        ativarBloquearServicos={ativarBloquearServicos}
        show={modalShow.enviarAtivaBloqueiaServicos}
        onSearch={onSearch}
        onClose={() =>
          setModalShow({ ...modalShow, enviarAtivaBloqueiaServicos: false })
        }
      />
      <ModalMoverLinhaGrupo
        linhas={listaLinhasSelecionadas}
        show={modalShow.moverLinhaGrupo}
        onClose={(msg) => {
          setModalShow({ ...modalShow, moverLinhaGrupo: false });
          if (typeof msg === 'string')
            onSetMessage(ResponseStatus.Success, msg);
        }}
      />
      <ModalResetarCotaGrupo
        show={modalShow.modalResetarCotaGrupo}
        onClose={() =>
          setModalShow({ ...modalShow, modalResetarCotaGrupo: false })
        }
      />
    </CSDSelPage>
  );
};

export default SelecaoGestaoOperadora;
