export default class MarcaaparelhoCelular {
  constructor(jsonObject = {}) {
    this.nrSeqMarcaAparelho = jsonObject.nrSeqMarcaAparelho;
    this.cdMarcaAparelho = jsonObject.cdMarcaAparelho;
    this.noMarcaAparelho = jsonObject.noMarcaAparelho;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
