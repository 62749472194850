export default class PneuDimensao {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqPneuDimensao = jsonObject.nrSeqPneuDimensao;
    this.noPneuDimensao = jsonObject.noPneuDimensao;
    this.cdPneuDimensao = jsonObject.cdPneuDimensao;
  }

  toJson = () => JSON.stringify(this);
}
