import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, BootstrapSizes } from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Textbox,
  Textarea,
  Autocomplete,
  Button,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
// @ts-expect-error
import { Message, Page } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { ResponseStatus, Theme } from 'ui/Helpers/enums.ts';

import {
  getParametro,
  saveParametro,
  deleteParametro,
  getEncryptedContent,
} from 'core/services/SEG/parametro';
import {
  getGrupoEmpresaAutoComplete,
  getGrupoEmpresa,
} from 'core/services/SEG/grupoEmpresa';
// @ts-expect-error
import IParametro from 'core/interfaces/SEG/parametro.ts';

export default function ParametroItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit = useRef<any>();

  const [data, setData] = useState<any>({ noConteudo: '', descricao: '' });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const onNew = (): void => {
    setData({ noConteudo: '', descricao: '' });
  };

  const onSetMessage = (status: number, msg: string): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async (): Promise<void> => {
    if (typeof registryKey === 'string') {
      setLoading(true);

      const parametro: IParametro = await getParametro(registryKey);
      parametro.grupoEmpresa = await getGrupoEmpresa(
        parametro?.nrSeqGrupoEmpresa
      );

      setData(parametro);

      setLoading(false);
    } else onNew();

    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func(): Promise<void> {
      await load();
    })();
  }, [load, registryKey, reload]);

  const save = async (): Promise<void> => {
    setLoading(true);
    const { status, message: msg } = await saveParametro(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);

    onSetMessage(status, msg);

    setLoading(false);
  };

  const onExcluir = async (): Promise<void> => {
    if (typeof registryKey === 'string') {
      setLoading(true);
      const { status, message: msg } = await deleteParametro(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);

      onSetMessage(status, msg);

      setLoading(false);
    }
  };

  const onSearchGrupoEmpresa = async (e: string) => {
    const {
      status,
      message: msg,
      grupoempresas,
    } = await getGrupoEmpresaAutoComplete({ noGrupoEmpresa: e });
    if (msg) onSetMessage(status, msg);
    return grupoempresas;
  };

  const onEncrypted = async (): Promise<void> => {
    if (!data.noConteudo && data.noConteudo.length === 0) {
      onSetMessage(ResponseStatus.Error, 'Campo contúdo vazio.');
    } else {
      const { data: conteudoCriptografado } = await getEncryptedContent(
        data.noConteudo
      );

      setData({
        ...data,
        noConteudoCriptografado: conteudoCriptografado ?? '',
      });
    }
  };

  const { id: idSelecao } = PageTypes.Selection;

  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Parâmetro'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      onDelete={data?.noParametro !== null ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col mb-3'>
          {/* @ts-ignore */}
          <Textbox
            maxLength={60}
            label='Parâmetro'
            required
            text={data?.noParametro}
            onChangedValue={(noParametro: string) =>
              setData({ ...data, noParametro })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-10 mb-3'>
          {/* @ts-ignore */}
          <Textarea
            label='Conteúdo'
            required
            maxLength={2000}
            text={data.noConteudo}
            onChangedValue={(noConteudo: string) =>
              setData({ ...data, noConteudo })
            }
          />
        </div>
        <div className='col-2 mt-4 '>
          {/* @ts-ignore */}
          <Button
            text='Criptografar'
            theme={Theme.Success}
            size={BootstrapSizes.Medium}
            onClick={() => onEncrypted()}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          {/* @ts-ignore */}
          <Textarea
            maxLength={200}
            label='Descricao'
            text={data?.descricao}
            onChangedValue={(descricao: string) =>
              setData({ ...data, descricao })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-3 mb-3'>
          {/* @ts-ignore */}
          <Textbox
            maxLength={1}
            label='Flg Tipo'
            text={data?.flgTipo}
            onChangedValue={(flgTipo: boolean) => setData({ ...data, flgTipo })}
          />
        </div>

        <div className='col-3 mb-3'>
          {/* @ts-ignore */}
          <Textbox
            label='Tamanho'
            maxLength={2}
            text={data?.tamanho}
            mask={MaskTypes.Integer}
            onChangedValue={(tamanho: number) => setData({ ...data, tamanho })}
          />
        </div>
        <div className='col-3 mb-3'>
          {/* @ts-ignore */}
          <Autocomplete
            label='Grupo Empresa'
            searchDataSource={onSearchGrupoEmpresa}
            selectedItem={data?.grupoEmpresa}
            onSelectItem={(grupoEmpresa: any) => {
              setData({
                ...data,
                grupoEmpresa,
                nrSeqGrupoEmpresa: grupoEmpresa.nrSeqGrupoEmpresa,
              });
            }}
            dataSourceTextProperty='noGrupoEmpresa'
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
