import React, { useState, useEffect, useRef } from 'react';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import {
  Button,
  Modal,
  Notification,
  GridView,
  RadioButton,
  Autocomplete,
  Textbox,
  WhatsApp,
} from 'ui/components';
import {
  getEmailTituloAutoComplete,
  getWhatsAppContaAutoComplete,
} from 'core/services/TEL';
import { postEnviarFaturas } from 'core/services/FIN';

const formaEnvioEmail = [
  {
    text: 'Por Cliente - com anexo do boleto',
    value: 'email_por_cliente_com_boleto',
  },
  {
    text: 'Por Cliente - sem anexo do boleto',
    value: 'email_por_cliente_sem_boleto',
  },
];

const formaEnvioWhatsapp = [
  {
    text: 'Por Cliente - com anexo do boleto',
    value: 'whatsapp_por_cliente_com_boleto',
  },
  {
    text: 'Por Cliente - sem anexo do boleto',
    value: 'whatsapp_por_cliente_sem_boleto',
  },
];

const envioPadrao = [
  {
    text: 'E-Mail',
    value: 'email',
  },
  {
    text: 'Whatsapp',
    value: 'whatsapp',
  },
  {
    text: 'SMS',
    value: 'sms',
  },
];

export default function ModalEnviaFatura({
  show,
  onClose,
  titulosreceberfatura,
  nrFormaEnvio,
  flgEditFatura,
  retornoEnvioEmail,
}) {
  const [message, setMessage] = useState(null);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const gridView = useRef();

  const columns = [
    { key: 'nrSeqFatura', type: GridView.ColumnTypes.Checkbox, visible: false },
    { key: 'nrSeqGrupoEmpresa', title: 'NrSeqGrupoEmpresa', visible: false },
    { key: 'nrSeqEmpresa', title: 'NrSeqEmpresa', visible: false },
    { key: 'cdTituloReceberFatura', title: 'Cd.Tit.' },
    {
      key: 'cliente.noPessoa',
      title: 'Cliente',
    },
    {
      key: 'empresa.noPessoa',
      title: 'Empresa',
    },

    {
      title: 'Vencimento',
      visible: false,
      key: 'dtVencimento',
      format: GridView.DataTypes.Date,
    },

    {
      key: 'noFormaPagamento',
      title: 'Forma de Pagamento',
      visible: false,
    },
    {
      key: 'vlrFatura',
      title: 'Valor',
      format: GridView.DataTypes.Money,
    },
    { key: 'nrSeqPessoaCli', title: 'NrSeqPessoaCli', visible: false },
    {
      key: 'dtFatura',
      title: 'DtFatura',
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'vlrDesconto',
      title: 'VlrDesconto',
      format: GridView.DataTypes.Money,
      visible: false,
    },
    {
      key: 'vlrAcrescimo',
      title: 'VlrAcrescimo',
      format: GridView.DataTypes.Money,
      visible: false,
    },
  ];

  const onEnviaFaturas = async () => {
    setLoading(true);

    const lista = gridView.current.getDataSource() ?? [];
    const nrSeqs = lista.map((row) => row.nrSeqTituloReceberFatura);

    const obj = {
      nrSeqsTituloReceberFatura: nrSeqs,
      nrEnvioPadrao: filters.nrEnvioPadrao,
      noFormaEnvioEmail: filters.noFormaEnvioEmail,
      noFormaEnvioWhatsapp: filters.noFormaEnvioWhatsapp,
      noMensagem: filters.noMensagem,
      noEmail: filters.noEmail,
      noEmailCopia: filters.noEmailCopia,
      emailTitulo: filters.emailTitulo,
      nrSeqEmailTitulo: filters.nrSeqEmailTitulo,
    };

    const retorno = await postEnviarFaturas(obj);

    setLoading(false);

    retornoEnvioEmail(retorno);
  };

  const onSearchEmailConta = async () => {
    const { emails } = await getEmailTituloAutoComplete({
      flgSMS: false,
      flgEmail: true,
      flgWhatsapp: false,
    });

    return emails;
  };

  const onSearchSms = async () => {
    const { emails } = await getEmailTituloAutoComplete({
      flgSMS: true,
      flgEmail: false,
      flgWhatsapp: false,
    });

    return emails;
  };

  const onSearchWhatsAppConta = async () => {
    const { emails } = await getWhatsAppContaAutoComplete({
      flgSMS: false,
      flgEmail: false,
      flgWhatsapp: true,
    });

    return emails;
  };

  useEffect(async () => {
    if (show) {
      if (envioPadrao) {
        setFilters({
          ...filters,
          nrEnvioPadrao: nrFormaEnvio,
          noEnvioPadrao: nrFormaEnvio === 0 ? 'email' : 'whatsapp',
          noFormaEnvioEmail: 'email_por_cliente_com_boleto',
          noFormaEnvioWhatsapp: 'whatsapp_por_cliente_com_boleto',
          noEmailCopia: '',
          noEmail: '',
          emailTitulo: {},
        });
      } else {
        setFilters({
          ...filters,
          noEmailCopia: '',
          noEmail: '',
          emailTitulo: {},
        });
      }
      if (gridView && gridView.current)
        gridView.current.setDataSource(titulosreceberfatura);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      title='Envia Faturas'
      loading={loading}
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body className='p-4 pt-3'>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        {filters.noEnvioPadrao === 'whatsapp' && (
          <div className='col'>
            <WhatsApp />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-auto'>
            <RadioButton
              label='Envio padrão'
              outline
              size={BootstrapSizes.Small}
              theme={Theme.Primary}
              selectedButton={filters.noEnvioPadrao}
              buttons={envioPadrao}
              onChange={(noEnvioPadrao) => {
                let envio = 0;
                if (noEnvioPadrao === 'email') envio = 0;
                else if (noEnvioPadrao === 'whatsapp') envio = 1;
                else envio = 2;
                setFilters({
                  ...filters,
                  nrEnvioPadrao: envio,
                  noEnvioPadrao,
                });
              }}
            />
          </div>
        </div>

        {filters.noEnvioPadrao === 'email' && (
          <div>
            <div className='row mb-3'>
              <div className='col-auto'>
                <RadioButton
                  label='Forma Envio E-mail'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filters.noFormaEnvioEmail}
                  buttons={formaEnvioEmail}
                  onChange={(noformaEnvioEmail) =>
                    setFilters({
                      ...filters,
                      noformaEnvioEmail,
                    })
                  }
                />
              </div>
            </div>
            {!flgEditFatura && (
              <div className='row mb-3'>
                <div className='col'>
                  <Autocomplete
                    label='Mensagem Email'
                    searchDataSource={onSearchEmailConta}
                    selectedItem={filters.emailTitulo}
                    onSelectItem={(emailTitulo) => {
                      setFilters({
                        ...filters,
                        emailTitulo,
                        nrSeqEmailTitulo: emailTitulo.nrSeqEmailTitulo,
                      });
                    }}
                    dataSourceTextProperty='noTituloEmail'
                  />
                </div>
              </div>
            )}

            {flgEditFatura && (
              <div className='row mb-3'>
                <div className='col-6' />
                <Textbox
                  label='Mensagem'
                  text={filters.noMensagem}
                  onChangedValue={(noMensagem) => {
                    setFilters({ ...filters, noMensagem });
                  }}
                />
              </div>
            )}
            <div className='row mb-3'>
              <div className='col-6' />
              <Textbox
                label='E-mail'
                text={filters.noEmail}
                onChangedValue={(noEmail) => {
                  setFilters({ ...filters, noEmail });
                }}
                visible={flgEditFatura}
              />
            </div>
            <div className='row mb-3'>
              <div className='col-6' />
              <Textbox
                label='E-mail Cópia'
                text={filters.noEmailCopia}
                onChangedValue={(noEmailCopia) => {
                  setFilters({ ...filters, noEmailCopia });
                }}
              />
            </div>
          </div>
        )}
        {filters.noEnvioPadrao === 'sms' && (
          <div>
            {!flgEditFatura && (
              <div className='row mb-3'>
                <div className='col'>
                  <Autocomplete
                    label='Mensagem SMS'
                    searchDataSource={onSearchSms}
                    selectedItem={filters.emailTitulo}
                    onSelectItem={(emailTitulo) => {
                      setFilters({
                        ...filters,
                        emailTitulo,
                        nrSeqEmailTitulo: emailTitulo.nrSeqEmailTitulo,
                      });
                    }}
                    dataSourceTextProperty='noTituloEmail'
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {filters.noEnvioPadrao === 'whatsapp' && (
          <div>
            <div className='row mb-3'>
              <div className='col-auto'>
                <RadioButton
                  label='Forma Envio Whatsapp'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filters.noformaEnvioWhatsapp}
                  buttons={formaEnvioWhatsapp}
                  onChange={(noformaEnvioWhatsapp) =>
                    setFilters({
                      ...filters,
                      noformaEnvioWhatsapp,
                    })
                  }
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col' style={{ height: '200px' }}>
                <Autocomplete
                  label='Mensagem Whatsapp'
                  searchDataSource={onSearchWhatsAppConta}
                  selectedItem={filters.emailTitulo}
                  onSelectItem={(emailTitulo) => {
                    setFilters({
                      ...filters,
                      emailTitulo,
                      nrSeqEmailTitulo: emailTitulo.nrSeqEmailTitulo,
                    });
                  }}
                  dataSourceTextProperty='noTituloEmail'
                />
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Enviar'
          theme={Theme.Success}
          icon='share'
          onClick={() => onEnviaFaturas()}
        />
        <Button
          text='Fechar'
          theme={Theme.Danger}
          icon='ban'
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
