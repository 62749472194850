import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  deleteAllTributacaoIcms,
  getTributacaoIcmsPagined,
  printTributacaoIcms,
} from 'core/services/FIS';
import { CSDSelPage, GridView } from 'ui/components';
import { ColumnTypes } from 'ui/Helpers/enums';
import {
  GridViewCurrent,
  GridviewColumns,
  Message,
  Page,
  RowDoubleClick,
  SearchParams,
} from 'ui/Helpers/interfaces';
import { TributacaoIcms } from 'core/models/FIS';

const columns: Array<GridviewColumns> = [
  { key: 'nrSeqTributacaoIcms', type: ColumnTypes.Checkbox },
  { key: 'noTributacaoIcms', title: 'Descrição' },
];

export default function TributacaoICMS({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}: Page) {
  const gridView: MutableRefObject<
    GridViewCurrent<TributacaoIcms> | undefined
  > = useRef();

  const [filtros, setFiltros] = useState<SearchParams>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const search = async (params: SearchParams | null = null): Promise<void> => {
    setLoading(true);

    const { data, pagination } = await getTributacaoIcmsPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const load = (): void => {
    setLoading(true);
    search();
    setLoading(false);
  };

  useEffect(() => {
    load();
  }, [isActive]);

  const onColumnSort = async (sortBy: string): Promise<void> => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page: number): Promise<void> => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onOpenMaintenance = (registryKey: number | null = null): void => {
    onOpenPage(
      transaction.noTransacao,
      'TributacaoICMS/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow: Array<RowDoubleClick>): void => {
    const nrSeqTributacaoIcms = sourceRow.find(
      (e) => e.key === 'nrSeqTributacaoIcms'
    );
    onOpenMaintenance(nrSeqTributacaoIcms?.value);
  };

  const onDelete = async (): Promise<void> => {
    setLoading(true);
    const selecteds = gridView.current?.getCheckBoxValuesAt(0);

    if (selecteds && selecteds.length > 0) {
      const { status, message: msg } = await deleteAllTributacaoIcms(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onPrint = async (): Promise<void> => {
    setLoading(true);
    const { value } = await printTributacaoIcms(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Tributo'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
