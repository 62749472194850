import ContaCorrente from './contaCorrente';

export default class AgrupamentoFinanceiroContacor {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqAgrupamentoFinanceiro = jsonObject.nrSeqAgrupamentoFinanceiro;
    this.nrSeqContaCorrente = jsonObject.nrSeqContaCorrente;
    this.contaCorrente = new ContaCorrente(jsonObject.contaCorrente ?? {});
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
