import React from 'react';

import { Theme } from 'ui/Helpers/utils';

import { Button, Modal } from 'ui/components';

export default function modalResumoMessages(props) {
  const { show, onClose, messages } = props;
  return (
    <Modal show={show} title='Recálculo Realizado' onClose={onClose}>
      <Modal.Body className='bg-light'>
        <div className='row'>
          <div className='col'>
            <table className='table table-sm table-striped'>
              <thead className='table-primary'>
                <tr>
                  <th className='text-center'>Linha</th>
                  <th className='text-center'>Mensagem</th>
                </tr>
              </thead>
              <tbody>
                {(messages ?? []).map((p) => (
                  <tr key={p.nrLinha}>
                    <td>{p.nrLinha}</td>
                    <td>{p.message}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          theme={Theme.Primary}
          icon='times'
          text='Fechar'
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
