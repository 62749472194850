import React, { useRef, useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  Modal,
  RadioButton,
  Loader,
  Notification,
  Button,
  Panel,
  ControlTires,
} from 'ui/components';
import {
  BootstrapSizes,
  Theme,
  ResponseStatus,
  JustifyContent,
} from 'ui/Helpers/enums';
import { Message } from 'ui/Helpers/interfaces';
import { getVeiculo } from 'core/services/FRO/veiculo';
import Veiculo from 'core/models/FRO/veiculo';

interface Props {
  show: boolean;
  nrSeqVeiculo: number;
  setPneusAlocados: any;
  onClose: () => void;
}

export default function ModalImpressaoPneuVeiculo({
  show,
  nrSeqVeiculo,
  setPneusAlocados,
  onClose,
}: Props) {
  const [message, setMessage] = useState<Message | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [placas, setPlacas] = useState<Array<Veiculo> | null>(null);
  const [imprimeVinculados, setImprimeVinculados] = useState<any>({
    flgImprimeVinculados: 'N',
  });
  const vinculoPneuVeiculo = useRef<any>();

  const onSetMessage = (status: ResponseStatus, msg: string): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const getVeiculosVinculadosLista = (veiculo: any): void => {
    const veiculosLista: Array<Veiculo> = [];

    if (veiculo.veiculosVinculados?.length > 0) {
      veiculo.veiculosVinculados.forEach((item: Veiculo) =>
        veiculosLista.push(item)
      );
    }

    setPlacas(veiculosLista);
  };

  const load = async () => {
    setLoading(true);

    if (nrSeqVeiculo) {
      const veiculo: any = await getVeiculo(nrSeqVeiculo);

      if (!veiculo) {
        onSetMessage(ResponseStatus.Error, 'Erro ao buscar veículo.');
      } else {
        getVeiculosVinculadosLista(veiculo);
        setData({
          veiculoPrincipal: veiculo,
          veiculoEixos: veiculo,
          nrSeqVeiculo: veiculo.nrSeqVeiculo,
        });
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (show) {
      load();
    }
  }, [show]);

  const imprimeVinculadosButton = [
    {
      text: 'Sim',
      value: 'S',
    },
    {
      text: 'Não',
      value: 'N',
    },
  ];

  const onHandleImprimirVinculadosButton = () => {
    if (imprimeVinculados?.flgImprimeVinculados === 'S') {
      setImprimeVinculados({ flgImprimeVinculados: 'N' });
    } else {
      setImprimeVinculados({ flgImprimeVinculados: 'S' });
    }
  };

  const onHandleClose = () => {
    setData(null);
    setPlacas(null);
    setImprimeVinculados({ flgImprimeVinculados: 'N' });
    onClose();
  };

  return (
    // @ts-expect-error
    <Modal
      show={show}
      title='Impressão Pneus Vinculados'
      icon='print'
      size={BootstrapSizes.Large}
      onClose={() => onHandleClose()}
    >
      <Loader loading={loading} />
      {message && (
        <div className='m-3'>
          {/* @ts-expect-error */}
          <Notification
            message={message.message}
            theme={message.theme}
            onClose={() => setMessage(null)}
          />
        </div>
      )}

      {/* @ts-expect-error */}
      <Modal.Body>
        <div className='row'>
          <div className='col mb-3 d-flex flex-column align-items-start'>
            {/* @ts-expect-error */}
            <RadioButton
              label='Imprime Viculados?'
              outline
              size={BootstrapSizes.Small}
              theme={Theme.Primary}
              selectedButton={imprimeVinculados.flgImprimeVinculados}
              buttons={imprimeVinculadosButton}
              onChange={() => onHandleImprimirVinculadosButton()}
            />
          </div>
        </div>

        <div className='row m-3' ref={vinculoPneuVeiculo}>
          <div className='row mb-3'>
            <div className='col'>
              {/* @ts-expect-error */}
              <Panel>
                {/* @ts-expect-error */}
                <Panel.Header
                  title={`Placa: ${data?.veiculoPrincipal.placa}`}
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                />
                <Panel.Body>
                  <div className='row justify-content-center'>
                    <div className='col'>
                      {/* @ts-expect-error */}
                      <ControlTires
                        nrSeqVeiculo={data?.nrSeqVeiculo}
                        onMessage={onSetMessage}
                        onSetLoading={setLoading}
                        setPneusAlocados={setPneusAlocados}
                        print
                      />
                    </div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
          </div>

          {/* @ts-expect-error */}
          {placas?.length > 0 &&
            imprimeVinculados.flgImprimeVinculados === 'S' &&
            placas?.map((veiculoVinculado) => (
              <div key={veiculoVinculado.placa} className='row mb-3'>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Panel>
                    {/* @ts-expect-error */}
                    <Panel.Header
                      title={`Placa: ${veiculoVinculado.placa}`}
                      theme={Theme.Primary}
                      align={JustifyContent.Start}
                    />
                    <Panel.Body>
                      <div className='row justify-content-center'>
                        <div className='col'>
                          {/* @ts-expect-error */}
                          <ControlTires
                            nrSeqVeiculo={veiculoVinculado.nrSeqVeiculo}
                            onMessage={onSetMessage}
                            onSetLoading={setLoading}
                            setPneusAlocados={setPneusAlocados}
                            print
                          />
                        </div>
                      </div>
                    </Panel.Body>
                  </Panel>
                </div>
              </div>
            ))}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          text='Imprimir'
          icon='print'
          theme={Theme.Success}
          onClick={useReactToPrint({
            content: () => vinculoPneuVeiculo.current,
          })}
        />
      </Modal.Footer>
    </Modal>
  );
}
