import React, {
  useState,
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { convertThemeToRgb } from 'ui/Helpers/utils';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IDataSource {
  labels: Array<string>;
  datasets: Array<any>;
}

interface Props {
  options: any;
  onClick?: (object: any) => void;
  borderWidth?: number;
  border?: boolean;
}

const BarGraphic = forwardRef(
  (
    { options, border = false, borderWidth = 2, onClick }: Props,
    ref: ForwardedRef<any>
  ) => {
    const [dataSource, setDataSource] = useState<IDataSource>({
      labels: [],
      datasets: [],
    });
    const [optionsSource, setOptionsSource] = useState(options);
    let dataSourceAtRunTime: IDataSource;

    const handleOnclick = (event: any, elements: any) => {
      if (!onClick) return;

      if (elements.length > 0) {
        const { datasetIndex: dataSetIndex, index } = elements[0];

        const object = {
          dataset: dataSourceAtRunTime.datasets[dataSetIndex].label,
          label: dataSourceAtRunTime.labels[index],
          value: dataSourceAtRunTime.datasets[dataSetIndex].data[index],
        };

        onClick(object);
      }
    };

    const updateDataSource = (newDataSource: IDataSource) => {
      newDataSource.datasets.forEach((dataSet: any) => {
        if (!dataSet.backgroundColor) {
          const { arrayRgb, borderRgb } = convertThemeToRgb(dataSet.theme);
          delete dataSet.theme;
          dataSet.backgroundColor = `rgba(${arrayRgb[0]}, ${arrayRgb[1]}, ${arrayRgb[2]})`;

          if (border) {
            dataSet.borderColor = `rgba(${borderRgb[0]}, ${borderRgb[1]}, ${borderRgb[2]})`;
            dataSet.borderWidth = borderWidth;
          }
        }
      });

      setDataSource(newDataSource);
      dataSourceAtRunTime = newDataSource;
    };

    useImperativeHandle(ref, () => ({
      setDataSource(newDataSource: IDataSource) {
        updateDataSource(newDataSource);

        setOptionsSource({
          ...optionsSource,
          onClick: (event: any, elements: any) =>
            handleOnclick(event, elements),
        });
      },

      getDataSource() {
        return dataSource;
      },
    }));

    return (
      <>
        {dataSource.datasets.length > 0 && (
          <Bar data={dataSource} options={optionsSource} />
        )}
      </>
    );
  }
);

export default BarGraphic;
