export default class CstCofins {
  nrSeqCstCofins: number;

  cdCstCofins: string;

  noCstCofins: string;

  nrSeqGrupoEmpresa: number;

  constructor(jsonObject: CstCofins) {
    this.nrSeqCstCofins = jsonObject.nrSeqCstCofins;
    this.cdCstCofins = jsonObject.cdCstCofins;
    this.noCstCofins = jsonObject.noCstCofins;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
