import Associado from './associado';
import Convenio from './convenio';

export default class ConvenioMassivo {
  constructor(jsonObject = {}) {
    this.nrSeqConvenioMassivo = jsonObject.nrSeqConvenioMassivo;
    this.nrSeqConvenio = jsonObject.nrSeqConvenio;
    this.anoMesIni = jsonObject.anoMesIni;
    this.anoMesFim = jsonObject.anoMesFim;
    this.nrSeqPessoaAss = jsonObject.nrSeqPessoaAss;
    this.vlrParcela = jsonObject.vlrParcela;
    this.vlrTotal = jsonObject.vlrTotal;
    this.noObs = jsonObject.noObs;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.dtCad = jsonObject.dtCad;
    this.qtdParcela = jsonObject.qtdParcela;

    this.convenio = new Convenio(jsonObject.convenio ?? {});
    this.associado = new Associado(jsonObject.associado ?? {});
  }

  toJson = () => JSON.stringify(this);
}
