import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  CSDManutPage,
  //  Autocomplete,
  Textbox,
  Textarea,
} from 'ui/components';
// import { MaskTypes } from 'ui/Helpers/masks';
import {
  getCarteiraTipo,
  saveCarteiraTipo,
  deleteCarteiraTipo,
} from 'core/services/FRO/carteiraTipo';

// import { getGrupoempresaAutoComplete } from 'core/services/FIN/grupoempresa';

export default function CarteiraTipoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  // const onSetMessage = (status, msg) => {
  //   if (msg)
  //     setMessage({
  //       message: msg,
  //       theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
  //     });
  // };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getCarteiraTipo(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveCarteiraTipo(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteCarteiraTipo(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  // const onSearchGrupoempresa = async (e) => {
  //   const {
  //     status,
  //     message: msg,
  //     grupoempresas,
  //   } = await getGrupoempresaAutoComplete({ noGrupoempresa: e });
  //   if (msg) onSetMessage(status, msg);
  //   return grupoempresas;
  // };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Carteira Tipo'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqCarteiraTipo > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-1 mb-3'>
          <Textbox
            required
            maxLength={20}
            label='Código'
            text={data.cdCarteiraTipo}
            onChangedValue={(cdCarteiraTipo) =>
              setData({ ...data, cdCarteiraTipo })
            }
          />
        </div>

        <div className='col mb-3'>
          <Textbox
            required
            maxLength={100}
            label='Carteira Tipo'
            text={data.noCarteiraTipo}
            onChangedValue={(noCarteiraTipo) =>
              setData({ ...data, noCarteiraTipo })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Textarea
            label='Observações'
            text={data.obs}
            onChangedValue={(obs) => setData({ ...data, obs })}
          />
        </div>
      </div>

      {/* <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='Grupoempresa'
            searchDataSource={onSearchGrupoempresa}
            selectedItem={data.grupoempresa}
            onSelectItem={(grupoempresa) => {
              setData({
                ...data,
                grupoempresa,
                nrSeqGrupoempresa: grupoempresa.nrSeqGrupoempresa,
              });
            }}
            dataSourceTextProperty='noGrupoempresa'
          />
        </div>
      </div> */}
    </CSDManutPage>
  );
}
