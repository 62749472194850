import { Empresa } from '../SEG';
import Cliente from './cliente';
import FormaPagamento from './formaPagamento';
import TituloEspecie from './tituloEspecie';
import TituloReceberFaturaOcorrencia from './tituloReceberFaturaOcorrencia';
import TituloReceberOcorrencia from './tituloReceberOcorrencia';
import TipoRecebimento from './tipoRecebimento';

export default class TituloReceber {
  constructor(jsonObject = {}) {
    this.nrSeqTituloReceber = jsonObject.nrSeqTituloReceber;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.vlrTitulo = jsonObject.vlrTitulo;
    this.dtCad = jsonObject.dtCad;
    this.dtVencimento = jsonObject.dtVencimento;
    this.nrTit = jsonObject.nrTit;
    this.nrSeqNfv = jsonObject.nrSeqNfv;
    this.nrOrdem = jsonObject.nrOrdem;
    this.noObs = jsonObject.noObs;
    this.noVeiculo = jsonObject.noVeiculo;
    this.vlrRecebido = jsonObject.vlrRecebido;
    this.vlrDesconto = jsonObject.vlrDesconto;
    this.vlrSaldoTit = jsonObject.vlrSaldoTit;
    this.vlrJuroDia = jsonObject.vlrJuroDia;
    this.vlrJuroRecebido = jsonObject.vlrJuroRecebido;
    this.vlrMultaRecebida = jsonObject.vlrMultaRecebida;
    this.vlrMulta = jsonObject.vlrMulta;
    this.vlrJuro = jsonObject.vlrJuro;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqTituloEspecie = jsonObject.nrSeqTituloEspecie;
    this.nrSeqTipoRecebimento = jsonObject.nrSeqTipoRecebimento;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqTituloReceberFatura = jsonObject.nrSeqTituloReceberFatura;
    this.vlrJuroAdicionado = jsonObject.vlrJuroAdicionado;
    this.vlrMultaAdicionado = jsonObject.vlrMultaAdicionado;
    this.dtContab = jsonObject.dtContab;
    this.nrSeqTituloPagar = jsonObject.nrSeqTituloPagar;
    this.nrNossoNumero = jsonObject.nrNossoNumero;
    this.tipoTituloReceber = jsonObject.tipoTituloReceber;
    this.nrDocumento = jsonObject.nrDocumento;
    this.nrNfv = jsonObject.nrNfv;

    this.dtRecebimento = jsonObject.dtRecebimento;

    this.dtRecebimentoDe = jsonObject.dtRecebimentoDe;
    this.dtRecebimentoAte = jsonObject.dtRecebimentoAte;
    this.dtVencimentoDe = jsonObject.dtVencimentoDe;
    this.dtVencimentoAt = jsonObject.dtVencimentoAt;
    this.dtContabilDe = jsonObject.dtContabilDe;
    this.dtContabilAte = jsonObject.dtContabilAte;

    this.vlrSaldoTit = jsonObject.vlrSaldoTit;

    this.nrParcelas = jsonObject.nrParcelas;
    this.nrDiasParcela = jsonObject.nrDiasParcela;
    this.flgGeraSequencia = jsonObject.flgGeraSequencia;
    this.noTipoParcela = jsonObject.noTipoParcela;
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.cliente = new Cliente(jsonObject.cliente ?? {});
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.tituloEspecie = new TituloEspecie(jsonObject.tituloEspecie ?? {});
    this.tituloOcorrencia = new TituloReceberOcorrencia(
      jsonObject.tituloOcorrencia ?? {}
    );
    this.tipoRecebimento = new TipoRecebimento(
      jsonObject.tipoRecebimento ?? {}
    );
    this.vlrPago = jsonObject.vlrPago;
    this.flgPermiteAlterar = jsonObject.flgPermiteAlterar ?? false;
    this.tituloReceberFaturaOcorrencias =
      jsonObject.tituloReceberFaturaOcorrencias?.map(
        (json) => new TituloReceberFaturaOcorrencia(json)
      ) ?? [];
    this.dtVencimentoStr = jsonObject.dtVencimentoStr;
    this.vlrJuroCalculado = jsonObject.vlrJuroCalculado;
    this.vlrMultaCalculado = jsonObject.vlrMultaCalculado;
    this.vlrDescontoCalculado = jsonObject.vlrDescontoCalculado;
    this.vlrTotalEncargo = jsonObject.vlrTotalEncargo;
    this.vlrTotalReceber = jsonObject.vlrTotalReceber;

    this.dtCheque = jsonObject.dtCheque;
    this.nrSeqBanco = jsonObject.nrSeqBanco;
    this.noAgencia = jsonObject.noAgencia;
    this.nrCheque = jsonObject.nrCheque;
    this.noConta = jsonObject.noConta;
    this.flgExibeCheque = jsonObject.flgExibeCheque;
    this.nrDiasEmAtraso = jsonObject.nrDiasEmAtraso;
    this.documentos = jsonObject.documentos;
    this.tituloReceberS = jsonObject.tituloReceberS;
    this.tituloReceberPlanoDeConta = jsonObject.tituloReceberPlanoDeConta;
    this.nrSeqContaRecurso = jsonObject.nrSeqContaRecurso;
    this.nrSeqConvenioExtrato = jsonObject.nrSeqConvenioExtrato;
    this.impressaoBoleto = jsonObject.impressaoBoleto;
    this.noColorVlrSaldoTit = jsonObject.noColorVlrSaldoTit;
    this.noPlanoConta = jsonObject.noPlanoConta;
    this.totalByPage = jsonObject.totalByPage;
    this.nrLimiteDiasVencidos = jsonObject.nrLimiteDiasVencidos;
    this.nrSeqPessoaInformacaoBancaria =
      jsonObject.nrSeqPessoaInformacaoBancaria;
    this.informacaoBancaria = jsonObject.informacaoBancaria;
    this.vlrSomadoTotal = jsonObject.vlrSomadoTotal;
    this.vlrSaldoTotal = jsonObject.vlrSaldoTotal;
    this.vlrPagoTotal = jsonObject.vlrPagoTotal;

    // propriedades auxiliares
    this.noDescricao = jsonObject.noDescricao;
    this.vlrFaturaColumn = jsonObject.vlrFaturaColumn;
    this.vlrSaldoDevedorColumn = jsonObject.vlrSaldoDevedorColumn;
    this.flgPermiteEditarParcela = jsonObject.flgBotaoEditarParcela;
    this.flgPermiteRemoverParcela = jsonObject.flgBotaoRemoverParcela;
    this.flgPermiteAlterarVencimento = jsonObject.flgPermiteAlterarVencimento;
    this.noFormaPagamento = jsonObject.noFormaPagamento;
  }

  toJson = () => JSON.stringify(this);
}
