import React from 'react';

export default function ListaMensagens({ param }) {
  return (
    <div>
      <ul>
        {param.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </div>
  );
}
