import React, { useState, useRef, useEffect } from 'react';
import {
  CSDSelPage,
  GridView,
  Textbox,
  Autocomplete,
  DropdownMulti,
  DatePicker,
  Panel,
  ToolbarButtons,
} from 'ui/components';
import { PageTypes, BootstrapSizes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces.ts';
import {
  ColumnTypes,
  ResponseStatus,
  Theme,
  ColumnDataTypes,
  // @ts-expect-error
} from 'ui/Helpers/enums.ts';
import { getAreaChamadoAutoComplete } from 'core/services/HELP/areaChamado';
import { getTipoChamadoAutoComplete } from 'core/services/HELP/tipoChamado';
import { getClienteAutoComplete } from 'core/services/FIN';
import { getUsuarioAutoComplete } from 'core/services/SEG';
import { getSituacaoChamadoAutoComplete } from 'core/services/HELP/situacaoChamado';
import {
  getChamadoPagined,
  removerEmailNaoImportado,
  searchEmailsNaoImportados,
  importFromEmail,
  // @ts-expect-error
} from 'core/services/HELP/chamado.ts';

interface IShowGrid {
  show: boolean;
  data?: Array<any>;
}
export default function ChamadoAutomatico({
  transaction,
  onOpenPage,
  isActive,
}: Page) {
  const gridView = useRef<any>();
  const gridViewEmailsNaoImportados = useRef<any>();
  const dropDownStatus = useRef<any>();

  const [filters, setFilters] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [
    showGridEmailNaoImportado,
    setShowGridEmailNaoImportado,
  ] = useState<IShowGrid>({ show: false });

  const columns: Array<GridviewColumns> = [
    {
      key: 'nrSeqChamado',
      type: ColumnTypes.Checkbox,
    },
    { key: 'cdChamado', title: 'Código' },
    { key: 'noTituloChamado', title: 'Título' },
    { key: 'areaChamado.noAreaChamado', title: 'Área' },
    { key: 'tipoChamado.noTipoChamado', title: 'Tipo Chamado' },
    { key: 'situacaoChamado.noSituacaoChamado', title: 'Status' },
    { key: 'cliente.noPessoa', title: 'Cliente' },
    { key: 'usuarioOp.noPessoa', title: 'Responsável' },
    { key: 'dtAbertura', title: 'Abertura', format: ColumnDataTypes.Date },
    { key: 'dtPrazoConclusao', title: 'Prazo', format: ColumnDataTypes.Date },
  ];

  const onSetMessage = (status: number, messageShow: string) => {
    if (messageShow)
      setMessage({
        message: messageShow,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onRemoveEmailNaoImportado = async (e: any, datasource: Array<any>) => {
    setLoading(true);

    const index = datasource.findIndex((el: any) => el === e);

    const gridItem = datasource[index];

    const { status, message: msg } = await removerEmailNaoImportado(
      gridItem.nrSeqEmailNaoImportado
    );

    if (status === ResponseStatus.Success) {
      datasource[index].status = 'Remover';

      gridViewEmailsNaoImportados.current.setDataSource(datasource);
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const columnsEmailsNaoImportados: Array<GridviewColumns> = [
    { key: 'nrSeqEmailNaoimportado', visible: false },
    { key: 'noRemetente', title: 'Remetente' },
    { key: 'noAssunto', title: 'Assunto' },
    { key: 'dtEnviado', title: 'Data de Envio', format: ColumnDataTypes.Date },
    { key: 'noObservacao', title: 'Observação' },
    {
      key: 'nrSeqEmailNaoEnviado',
      title: 'Excluir',
      // @ts-expect-error
      type: GridView.ColumnTypes.Button,
      onClick: (e: any, datasource: Array<any>) =>
        onRemoveEmailNaoImportado(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
    },
  ];

  const search = async (): Promise<void> => {
    if (!loading) {
      setLoading(true);

      const { data: chamados, pagination } = await getChamadoPagined(filters);

      if (gridView && gridView.current) {
        gridView.current.setDataSource(chamados, pagination);
      }

      gridViewEmailsNaoImportados?.current?.setDataSource([]);
      setShowGridEmailNaoImportado({ show: false });

      setLoading(false);
    }
  };

  const onColumnSort = async (SortBy: number): Promise<void> => {
    setFilters({ ...filters, SortBy });
    await search();
  };

  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    setFilters({ ...filters, totalByPage });
    await search();
  };

  const onPageChange = async (page: any): Promise<void> => {
    setFilters({ ...filters, page });
    await search();
  };

  const onOpenMaintenance = (registryKey = null): void => {
    onOpenPage(
      transaction.noTransacao,
      'ChamadoAutomatico/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow: any): void => {
    const nrSeqChamado = sourceRow.find((e: any) => e.key === 'nrSeqChamado');

    onOpenMaintenance(nrSeqChamado.value);
  };

  const onSearchAreaChamado = async (e: string): Promise<Array<any>> => {
    const {
      areasChamado,
    }: { areasChamado: Array<any> } = await getAreaChamadoAutoComplete({
      noAreaChamado: e,
    });

    return areasChamado;
  };

  const onSearchTipoChamado = async (e: string): Promise<Array<any>> => {
    if (filters.nrSeqAreaChamado === 0) {
      setMessage({
        message: 'É necessário selecionar uma area.',
        theme: Theme.Warning,
      });

      return [];
    }

    const {
      tiposChamado,
    }: { tiposChamado: Array<any> } = await getTipoChamadoAutoComplete({
      noTipoChamado: e,
      nrSeqAreaChamado: filters.nrSeqAreaChamado,
    });

    return tiposChamado;
  };

  const onSearchSituacaoChamado = async (
    e: string = ''
  ): Promise<Array<any>> => {
    const {
      situacoesChamados,
    }: {
      situacoesChamados: Array<any>;
    } = await getSituacaoChamadoAutoComplete({ noSituacaoChamado: e });

    return situacoesChamados;
  };

  const onSearchCliente = async (e: string): Promise<Array<any>> => {
    const { clientes }: { clientes: Array<any> } = await getClienteAutoComplete(
      {
        noPessoa: e,
      }
    );

    return clientes;
  };

  const onSearchUsuario = async (e: string): Promise<Array<any>> => {
    const { data }: { data: Array<any> } = await getUsuarioAutoComplete({
      noLogin: e,
    });

    return data;
  };

  const onSearchEmailsNaoImportados = async () => {
    setLoading(true);

    const {
      status,
      message: msg,
      data: value,
    } = await searchEmailsNaoImportados();

    if (status === ResponseStatus.Success) {
      if (value.length > 0) {
        setShowGridEmailNaoImportado({ show: true, data: value });
      }
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onImportarEmails = async (): Promise<void> => {
    setLoading(true);

    const { data: value, status, message: msg } = await importFromEmail();

    if (status) {
      onSetMessage(status, msg);
    }

    if (status === ResponseStatus.Success) {
      setShowGridEmailNaoImportado({
        show: true,
        data: value.emailsNaoImportados ?? [],
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    const usuarioOp = JSON.parse(String(sessionStorage.getItem('@dataAuth')))
      .user;

    setFilters({
      ...filters,
      usuarioOp,
      nrSeqUsuarioOp: usuarioOp.nrSeqUsuario,
    });

    dropDownStatus.current.loadDataSource(onSearchSituacaoChamado);
  }, []);

  useEffect(() => {
    if (showGridEmailNaoImportado.show) {
      gridViewEmailsNaoImportados?.current?.setDataSource(
        showGridEmailNaoImportado.data ?? []
      );
    } else {
      gridViewEmailsNaoImportados?.current?.setDataSource([]);
    }
  }, [showGridEmailNaoImportado.show]);

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Chamado Demandas'
      loading={loading}
      onSearch={search}
      onNew={onOpenMaintenance}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons fixedOnTop={false}>
        <ToolbarButtons.Button
          text='Importar Chamados do E-mail'
          onClick={onImportarEmails}
          tooltip='Importa os chamados apartir do email .'
        />
        <ToolbarButtons.Button
          text='Buscar Emails Não Importado'
          onClick={onSearchEmailsNaoImportados}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        {/* @ts-expect-error */}
        <Panel className='mb-3'>
          {/* @ts-expect-error */}
          <Panel.Header title='Filtros' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-4'>
                <Autocomplete
                  label='Area'
                  searchDataSource={onSearchAreaChamado}
                  selectedItem={filters.areaChamado}
                  onSelectItem={(areaChamado: any) =>
                    setFilters({
                      ...filters,
                      areaChamado,
                      nrSeqAreaChamado: areaChamado.nrSeqAreaChamado,
                    })
                  }
                  dataSourceTextProperty='noAreaChamado'
                />
              </div>
              <div className='col-5'>
                <Autocomplete
                  label='Tipo de Chamado'
                  searchDataSource={onSearchTipoChamado}
                  selectedItem={filters.tipoChamado}
                  onSelectItem={(tipoChamado: any) => {
                    setFilters({
                      ...filters,
                      tipoChamado,
                      nrSeqTipoChamado: tipoChamado.nrSeqTipoChamado,
                    });
                  }}
                  dataSourceTextProperty='noTipoChamado'
                />
              </div>
              <div className='col-3'>
                <DropdownMulti
                  // @ts-expect-error
                  label='Status'
                  ref={dropDownStatus}
                  dataSourcePropertyText='noSituacaoChamado'
                  dataSourcePropertyValue='nrSeqSituacaoChamado'
                  selectedItems={selectedItems.item ?? []}
                  onSelectItem={(item: any) => {
                    setSelectedItems({ ...selectedItems, item });
                    setFilters({
                      ...filters,
                      nrSeqsSituacaoChamado: item
                        ?.map((x: any) => x.nrSeqSituacaoChamado)
                        .join(', '),
                    });
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col'>
                <Autocomplete
                  label='Cliente'
                  minLengthTextSearch={3}
                  searchDataSource={onSearchCliente}
                  selectedItem={filters.cliente}
                  onSelectItem={(cliente: any) => {
                    setFilters({
                      ...filters,
                      cliente,
                      nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Titulo Chamado'
                  maxLength={1000}
                  onChangedValue={(noTituloChamado: string) =>
                    setFilters({ ...filters, noTituloChamado })
                  }
                />
              </div>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Código'
                  maxLength={60}
                  onChangedValue={(cdChamado: string) =>
                    setFilters({ ...filters, cdChamado })
                  }
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col'>
                {/* @ts-expect-error */}
                <DatePicker
                  label='Aberto de'
                  onChange={(dtAbertura: Date) =>
                    setFilters({ ...filters, dtAbertura })
                  }
                />
              </div>
              <div className='col'>
                {/* @ts-expect-error */}
                <DatePicker
                  label='Aberto até'
                  onChange={(dtAberturaAte: Date) =>
                    setFilters({
                      ...filters,
                      dtAberturaAte,
                    })
                  }
                />
              </div>
              <div className='col'>
                {/* @ts-expect-error */}
                <DatePicker
                  label='Concluido de'
                  onChange={(dtFechamento: Date) =>
                    setFilters({ ...filters, dtFechamento })
                  }
                />
              </div>
              <div className='col '>
                {/* @ts-expect-error */}
                <DatePicker
                  label='Concluido até'
                  onChange={(dtFechamentoAte: Date) =>
                    setFilters({
                      ...filters,
                      dtFechamentoAte,
                    })
                  }
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Descrição Chamado'
                  maxLength={60}
                  onChangedValue={(descricao: string) =>
                    setFilters({ ...filters, descricao })
                  }
                />
              </div>
              <div className='col'>
                <Autocomplete
                  label='Responsável'
                  searchDataSource={onSearchUsuario}
                  selectedItem={filters.usuarioOp}
                  onSelectItem={(usuarioOp: any) => {
                    setFilters({
                      ...filters,
                      usuarioOp,
                      nrSeqUsuarioOp: usuarioOp.nrSeqUsuario,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      {showGridEmailNaoImportado.show && (
        <div className='row mb-3'>
          <div className='col'>
            {/* @ts-expect-error */}
            <Panel>
              {/* @ts-expect-error */}
              <Panel.Header
                title='Emails Não Importados'
                theme={Theme.Danger}
                onClickCollapseButton={() =>
                  setShowGridEmailNaoImportado({
                    show: false,
                  })
                }
              />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col'>
                    <p>
                      Não foi possivel importar os seguintes emails, faça a
                      abertura do chamado manualmente se necessário
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <GridView
                      ref={gridViewEmailsNaoImportados}
                      // @ts-expect-error
                      dataColumns={columnsEmailsNaoImportados}
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <p>
                      Foram encontrados{' '}
                      {
                        gridViewEmailsNaoImportados?.current?.getDataSource()
                          .length
                      }{' '}
                      registros
                    </p>
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      )}
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
            enableExcelExport
          />
        </div>
      </div>

      {/* <ModalImportarEmail
        show={showModal.show}
        onClose={() => setShowModal({ ...showModal, show: false })}
        setModalData={(e: Array<any>) =>
          setShowModal({ ...showModal, data: e })
        }
      /> */}
    </CSDSelPage>
  );
}
