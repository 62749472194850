import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Textbox } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getPaisAutoComplete } from 'core/services/SEG/pais';
import { getCidade, saveCidade, deleteCidade } from 'core/services/SEG/cidade';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
import { Pais } from 'core/models/SEG';

export default function CidadeItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      const obj = await getCidade(registryKey);
      setData(obj);
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveCidade(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteCidade(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchEstado = async (e) => {
    const { status, message: msg, estados } = await getEstadoAutoComplete({
      noEstado: e,
      nrSeqPais: data.pais.nrSeqPais ?? new Pais(),
    });
    onSetMessage(status, msg);
    return estados;
  };

  const onSearchPais = async (e) => {
    const { status, message: msg, paises } = await getPaisAutoComplete({
      noPais: e,
    });
    onSetMessage(status, msg);
    return paises;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Cidade'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqCidade > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-2'>
          <Textbox
            label='CdCidade'
            readOnly
            text={data.cdCidade}
            mask={MaskTypes.Integer}
            onChangedValue={(cdCidade) => setData({ ...data, cdCidade })}
          />
        </div>
        <div className='col-2'>
          <Textbox
            maxLength={20}
            label='Código IBGE'
            text={data.cdIbge}
            onChangedValue={(cdIbge) => setData({ ...data, cdIbge })}
          />
        </div>
        <div className='col-6'>
          <Textbox
            maxLength={80}
            label='Descrição'
            text={data.noCidade}
            onChangedValue={(noCidade) => setData({ ...data, noCidade })}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='País'
            searchDataSource={onSearchPais}
            selectedItem={data.estado?.pais}
            onSelectItem={(pais) => {
              setData({
                ...data,
                pais,
                nrSeqPais: pais.nrSeqPais,
              });
            }}
            dataSourceTextProperty='noPais'
          />
        </div>
        <div className='col-6'>
          <Autocomplete
            label='Estado'
            searchDataSource={onSearchEstado}
            selectedItem={data.estado}
            onSelectItem={(estado) => {
              setData({
                ...data,
                estado,
                nrSeqEstado: estado.nrSeqEstado,
              });
            }}
            dataSourceTextProperty='noEstado'
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
