import { FormaPagamento } from 'core/models/FIN';

export default class PedidoFatura {
  constructor(jsonObject = {}) {
    this.nrSeqPedidoFatura = jsonObject.nrSeqPedidoFatura;
    this.nrSeqPedido = jsonObject.nrSeqPedido;
    this.nrSeqFatura = jsonObject.nrSeqFatura;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.vlrParcela = jsonObject.vlrParcela;
    this.dtVencimento = jsonObject.dtVencimento;
    this.nrSeqTituloReceber = jsonObject.nrSeqTituloReceber;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqOrdem = jsonObject.nrSeqOrdem;
    this.nrSeqNfv = jsonObject.nrSeqNfv;
    this.flgLiquida = jsonObject.flgLiquida;

    this.formaPagamento = jsonObject.formaPagamento ?? new FormaPagamento();
  }

  toJson = () => JSON.stringify(this);
}
