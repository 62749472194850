import React, { useState, useEffect } from 'react';

import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import {
  Button,
  Modal,
  Notification,
  DatePicker,
  DropdownList,
  Loader,
} from 'ui/components';

import { MaskTypes } from 'ui/Helpers/masks';
import { getScheduledDate } from 'core/services/HELP';

export default function ModalAgendaPortabilidade({
  show,
  onClose,
  onAgendaPortabilidade,
  dataPortab,
  nrseq,
}) {
  const [message, setMessage] = useState(null);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [dropDownHorariosSource, setDropDownHorariosSource] = useState([]);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const searchHorariosPortabilidade = async (e) => {
    if (e || data?.dtPortabilidade) {
      setLoading(true);
      const { status, message: msg, agenda } = await getScheduledDate({
        dtPortabilidade: e,
      });
      if (status === ResponseStatus.Error) {
        onSetMessage(status, msg);
        await getScheduledDate({});
        setDropDownHorariosSource([]);
      } else {
        setDropDownHorariosSource(agenda.horarios);
      }
    }
    setLoading(false);
  };

  const onEnviar = async () => {
    const obj = {
      nrSeqDemanda: nrseq,
      dtPortabilidade: data.dtPortabilidade,
      noHoraInicioFim: data.noHoraInicioFim,
    };
    onAgendaPortabilidade(obj);
  };

  useEffect(() => {
    if (show) {
      searchHorariosPortabilidade(dataPortab);
      setData({ ...data, dtPortabilidade: dataPortab });
    }
  }, [show]);

  return (
    <Modal
      show={show}
      title='Agendar Portabilidade'
      onClose={onClose}
      size={BootstrapSizes.Medium}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3 altura200'>
          <div className='col'>
            <h4>Deseja enviar a demanda para a operadora MVNO?</h4>
            <div className='card mt-3' style={{ height: '330px' }}>
              <div className='card-body'>
                <div className='row mb-4'>
                  <div className='row mb-4'>
                    <div className='col'>
                      <DatePicker
                        label='Data Portabilidade'
                        required
                        text={data?.dtPortabilidade}
                        maxLength={10}
                        mask={MaskTypes.Date}
                        onChange={(dtPortabilidade) => {
                          if (dtPortabilidade && dtPortabilidade !== '')
                            searchHorariosPortabilidade(dtPortabilidade);
                          else {
                            setDropDownHorariosSource([]);
                          }
                          setData({
                            ...data,
                            dtPortabilidade,
                          });
                          setMessage(null);
                        }}
                      />
                    </div>
                    <div className='col'>
                      <DropdownList
                        label='Horários disponíveis'
                        enabled={data?.flgPermiteAlterar}
                        dataSource={dropDownHorariosSource}
                        dataSourcePropertyText='noHoraInicioFim'
                        dataSourcePropertyValue='nrSeqHoraInicioFim'
                        selectedItems={data?.horarios ?? []}
                        onSelectItem={(item) => {
                          setData({
                            ...data,
                            horarios: item,
                            noHoraInicioFim: item.noHoraInicioFim,
                            nrSeqHoraInicioFim: item.nrSeqHoraInicioFim,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Enviar'
          theme={Theme.Success}
          icon={['far', 'save']}
          onClick={() => onEnviar()}
        />
        <Button
          text='Fechar'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
