import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Textbox, Switch } from 'ui/components';
import { getNfTipo, saveNfTipo, deleteNfTipo } from 'core/services/COP/nfTipo';
import { getOficinaAutocomplete } from 'core/services/FRO/oficina';
import { getTipoDespesaAutoComplete } from 'core/services/FIN/tipoDespesa';
import { getEstoqueTipoAutoComplete } from 'core/services/EST/estoqueTipo';

export default function NfTipoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getNfTipo(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveNfTipo(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteNfTipo(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchTipoDespesa = async (e) => {
    const {
      status,
      message: msg,
      tipoDespesas,
    } = await getTipoDespesaAutoComplete({ noTipoDespesa: e });
    if (msg) onSetMessage(status, msg);
    return tipoDespesas;
  };

  const onSearchEstoqueTipo = async (e) => {
    const {
      status,
      message: msg,
      data: estoqueTipos,
    } = await getEstoqueTipoAutoComplete({
      noEstoqueTipo: e,
    });
    if (msg) onSetMessage(status, msg);
    return estoqueTipos;
  };

  const onSearchOficina = async (e) => {
    const { status, message: msg, oficinas } = await getOficinaAutocomplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);
    return oficinas;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      transaction={transaction}
      isActive={isActive}
      title='Manutenção de Tipo de Nota Fiscal'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqNfTipo > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            readOnly
            maxLength={2}
            label='Código'
            text={data.cdNfTipo}
            onChangedValue={(cdNfTipo) => setData({ ...data, cdNfTipo })}
          />
        </div>
        <div className='col mb-3'>
          <Autocomplete
            label='Tipo Despesa'
            searchDataSource={onSearchTipoDespesa}
            selectedItem={data.tipoDespesa}
            onSelectItem={(tipoDespesa) => {
              setData({
                ...data,
                tipoDespesa,
                nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
              });
            }}
            dataSourceTextProperty='noTipoDespesa'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={60}
            label='Descrição'
            text={data.noNfTipo}
            onChangedValue={(noNfTipo) => setData({ ...data, noNfTipo })}
          />
        </div>
        <div className='col mb-3'>
          <Autocomplete
            label='Tipo Estoque'
            searchDataSource={onSearchEstoqueTipo}
            selectedItem={data.estoqueTipo}
            onSelectItem={(estoqueTipo) => {
              setData({
                ...data,
                estoqueTipo,
                nrSeqEstoqueTipo: estoqueTipo.nrSeqEstoqueTipo,
              });
            }}
            dataSourceTextProperty='noEstoqueTipo'
          />
        </div>
        <div className='col mb-3'>
          <Autocomplete
            label='Tipo Estoque Saída'
            searchDataSource={onSearchEstoqueTipo}
            selectedItem={data.estoqueTipoSaida}
            onSelectItem={(estoqueTipoSaida) => {
              setData({
                ...data,
                estoqueTipoSaida,
                nrSeqEstoqueTipoSaida: estoqueTipoSaida.nrSeqEstoqueTipo,
              });
            }}
            dataSourceTextProperty='noEstoqueTipo'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-6 mb-3'>
          <Autocomplete
            label='Oficina'
            searchDataSource={onSearchOficina}
            selectedItem={data.oficina}
            onSelectItem={(oficina) => {
              setData({
                ...data,
                oficina,
                nrSeqPessoaOfi: oficina.nrSeqPessoaOfi,
              });
            }}
            dataSourceTextProperty='noOficina'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Vincula Nota de Entrada e Saída'
            checked={data.flgGeraLoteEstoqueMovimento}
            onChange={(flgGeraLoteEstoqueMovimento) =>
              setData({ ...data, flgGeraLoteEstoqueMovimento })
            }
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Obriga Fatura'
            checked={data.flgObrigaFatura}
            onChange={(flgObrigaFatura) =>
              setData({ ...data, flgObrigaFatura })
            }
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Retem Imposto'
            checked={data.flgRetemImpostos}
            onChange={(flgRetemImpostos) =>
              setData({ ...data, flgRetemImpostos })
            }
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Gera Pendência Financeira'
            checked={data.flgGeraPendencia}
            onChange={(flgGeraPendencia) =>
              setData({ ...data, flgGeraPendencia })
            }
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Gera Manutenção Veículo'
            checked={data.flgManutecaoVeiculo}
            onChange={(flgManutecaoVeiculo) =>
              setData({ ...data, flgManutecaoVeiculo })
            }
          />
        </div>
        <div className='row'>
          <div className='col-2 mb-3'>
            <Switch
              formControl
              label='Exporta Sped'
              checked={data.flgExportaSped}
              onChange={(flgExportaSped) =>
                setData({ ...data, flgExportaSped })
              }
            />
          </div>
          <div className='col-2 mb-3'>
            <Switch
              formControl
              label='Consid. Despesa Pedido'
              checked={data.flgConsTipoDespesaPedido}
              onChange={(flgConsTipoDespesaPedido) =>
                setData({ ...data, flgConsTipoDespesaPedido })
              }
            />
          </div>
          <div className='col-2 mb-3'>
            <Switch
              formControl
              label='Tipo Nota Fiscal Ativo'
              checked={data.flgAtivo}
              onChange={(flgAtivo) => setData({ ...data, flgAtivo })}
            />
          </div>
        </div>
      </div>
    </CSDManutPage>
  );
}
