import React, { useEffect } from 'react';
import { Panel, Switch, Autocomplete, Textbox } from 'ui/components';
// @ts-expect-error
import { Theme } from 'ui/Helpers/enums.ts';
import { getConsignatarioAutoComplete } from 'core/services/FRO/coletaConsignatario';
import { getColetaTipoDocumentoAutoComplete } from 'core/services/FRO/coletaTipoDocumento';

interface Props {
  remetenteData: any;
  setRemetenteData: (object: any) => void;
}

const RemetentePanel = ({ remetenteData, setRemetenteData }: Props) => {
  useEffect(() => {
    const objTransportadora = { ...remetenteData };

    setRemetenteData(objTransportadora);
  }, [remetenteData.nrSeqPessoaRem]);

  const onSearchTipoDocumento = async (e: any) => {
    const {
      data: coletaTiposDocumento,
    } = await getColetaTipoDocumentoAutoComplete({ noTipoDocumento: e });

    return coletaTiposDocumento;
  };

  const onSearchPessoaConsig = async (e: any) => {
    const { data: pessoaConsigs } = await getConsignatarioAutoComplete({
      noPessoaConsig: e,
    });

    return pessoaConsigs;
  };

  return (
    <div className='col mt-3'>
      {/* @ts-expect-error */}
      <Panel>
        {/* @ts-expect-error */}
        <Panel.Header title='Remetente' theme={Theme.Primary} />

        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-1 mb-3'>
              <Switch
                formControl
                label='Ativo'
                checked={remetenteData.flgAtivo}
                onChange={(flgAtivo) =>
                  setRemetenteData({ ...remetenteData, flgAtivo })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              <Autocomplete
                maxLength={3}
                label='Tipo de Documento Padrão'
                searchDataSource={onSearchTipoDocumento}
                selectedItem={remetenteData.coletaTipoDocumento}
                onSelectItem={(coletaTipoDocumento) => {
                  setRemetenteData({
                    ...remetenteData,
                    coletaTipoDocumento,
                    cdTipoDocumento: coletaTipoDocumento.cdTipoDocumento,
                  });
                }}
                dataSourceTextProperty='noTipoDocumento'
              />
            </div>
            <div className='col-6 mb-3'>
              <Autocomplete
                label='Consignatário'
                searchDataSource={onSearchPessoaConsig}
                selectedItem={remetenteData.coletaConsignatario}
                onSelectItem={(coletaConsignatario) => {
                  setRemetenteData({
                    ...remetenteData,
                    coletaConsignatario,
                    nrSeqPessoaConsig: coletaConsignatario.nrSeqPessoaConsig,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={remetenteData?.flgRemessa}
                label='Obriga Remessa?'
                onChange={(flgRemessa: boolean) =>
                  setRemetenteData({
                    ...remetenteData,
                    flgRemessa,
                  })
                }
              />
            </div>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={remetenteData?.flgAdiantamentoPedagio}
                label='Adiantamento Pedágio'
                onChange={(flgAdiantamentoPedagio: boolean) =>
                  setRemetenteData({
                    ...remetenteData,
                    flgAdiantamentoPedagio,
                  })
                }
              />
            </div>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={remetenteData?.flgOrdemTransporte}
                label='Obriga Ordem Transporte?'
                onChange={(flgOrdemTransporte: boolean) =>
                  setRemetenteData({
                    ...remetenteData,
                    flgOrdemTransporte,
                  })
                }
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={remetenteData?.flgPesoLiquidoMercad}
                label='Apropriar pelo peso líquido das mercadorias'
                onChange={(flgPesoLiquidoMercad: boolean) =>
                  setRemetenteData({
                    ...remetenteData,
                    flgPesoLiquidoMercad,
                  })
                }
              />
            </div>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={remetenteData?.flgIncluirCodProdutoObserv}
                label='Incluir código dos produtos nas observações do CTE'
                onChange={(flgIncluirCodProdutoObserv: boolean) =>
                  setRemetenteData({
                    ...remetenteData,
                    flgIncluirCodProdutoObserv,
                  })
                }
              />
            </div>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={remetenteData?.flgEdi}
                label='Remetente EDI'
                onChange={(flgEdi: boolean) =>
                  setRemetenteData({
                    ...remetenteData,
                    flgEdi,
                  })
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={remetenteData?.flgFreteMercadoriaDaNota}
                label='Produto Mercadoria da Nota'
                onChange={(flgFreteMercadoriaDaNota: boolean) =>
                  setRemetenteData({
                    ...remetenteData,
                    flgFreteMercadoriaDaNota,
                  })
                }
              />
            </div>

            <div className='col-5'>
              {/* @ts-expect-error */}
              <Textbox
                label='Padrão OT Na Nota'
                id='txtPadraoOTNota'
                text={remetenteData?.noPadraoOrdemTransporteNota}
                onChangedValue={(noPadraoOrdemTransporteNota: string) =>
                  setRemetenteData({
                    ...remetenteData,
                    noPadraoOrdemTransporteNota,
                  })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </div>
  );
};

export default RemetentePanel;
