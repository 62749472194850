import Associado from 'core/models/ASS/associado';

export default class GeracaoEtiquetas extends Associado {
  constructor(jsonObject = {}) {
    super(jsonObject);
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.nrSeqCidade = jsonObject.nrSeqCidade;
    this.nome = jsonObject.nome;
    this.nrSeqEstado = jsonObject.nrSeqEstado;
    this.noMesAniversario = jsonObject.noMesAniversario;
    this.flgOrdenarCidade = jsonObject.flgOrdenarCidade;
  }

  toJson = () => JSON.stringify(this);
}
