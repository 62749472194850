import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Textbox,
  DatePicker,
  Switch,
  Autocomplete,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getPedagio,
  savePedagio,
  deletePedagio,
} from 'core/services/FRO/pedagio';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';

export default function PedagioItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [tituloPagarPedagio, setTituloPagarPedagio] = useState({});
  const [acertoMotoristaPedagio, setAcertoMotoristaPedagio] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const resultado = await getPedagio(registryKey);

      let formataDataHora = resultado.dtPedagio;
      formataDataHora = new Date(formataDataHora).toLocaleTimeString('pt-BR');
      resultado.horaPedagio = formataDataHora;

      setData(resultado);
      setTituloPagarPedagio(resultado.tituloPagar);
      setAcertoMotoristaPedagio(resultado.acertoMotorista);

      setLoading(false);
    } else {
      setData({});
      setTituloPagarPedagio({});
      setAcertoMotoristaPedagio({});
    }

    if (transaction.setValues !== null && transaction.setValues !== undefined) {
      setData({
        ...data,
        nrSeqVeiculo: transaction.setValues.nrSeqVeiculoPrinc,
        veiculo: transaction.setValues.veiculo,
      });
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    if (!data.nrSeqVeiculo || data.nrSeqVeiculo <= 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Necessário adicionar uma Placa válida.'
      );
      return;
    }

    if (!data.noTag || data.noTag.length < 10) {
      onSetMessage(
        ResponseStatus.Error,
        'Necessário adicionar uma Tag válida.'
      );
      return;
    }

    if (!data.dtPedagio) {
      onSetMessage(
        ResponseStatus.Error,
        'Necessário adicionar a data da passagem pelo pedágio.'
      );
      return;
    }

    if (!data.horaPedagio || data.horaPedagio === '') {
      onSetMessage(
        ResponseStatus.Error,
        'Necessário adicionar a hora da passagem pelo pedágio.'
      );
      return;
    }

    setLoading(true);

    const { status, message: msg } = await savePedagio(data);

    if (status === ResponseStatus.Success) {
      setData({});
      setTituloPagarPedagio({});
      setAcertoMotoristaPedagio({});
      formSubmit.current.reset();
    }

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deletePedagio(registryKey);

      if (status === ResponseStatus.Success) {
        setData({});
        setAcertoMotoristaPedagio({});
        setTituloPagarPedagio({});
      }

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchVeiculo = async (e) => {
    const veiculos = await getVeiculoAutoComplete({
      placa: e,
    });

    if (veiculos.message) onSetMessage(veiculos.status, veiculos.message);

    return veiculos.veiculos;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Pedágio'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqPedagio > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-3 mb-4'>
          <Autocomplete
            required
            maxLength={7}
            label='Placa'
            searchDataSource={onSearchVeiculo}
            selectedItem={data.veiculo}
            onSelectItem={(veiculo) => {
              setData({
                ...data,
                veiculo,
                nrSeqVeiculo: veiculo.nrSeqVeiculo,
              });
            }}
            dataSourceTextProperty='placa'
          />
        </div>

        <div className='col-3 mb-4'>
          <Textbox
            maxLength={20}
            minLength={10}
            label='Tag'
            required
            text={data.noTag}
            onChangedValue={(noTag) => setData({ ...data, noTag })}
          />
        </div>

        <div className='col-3 mb-4'>
          <DatePicker
            label='Data Pedágio'
            text={data.dtPedagio}
            required
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtPedagio) => setData({ ...data, dtPedagio })}
          />
        </div>

        <div className='col-2 mb-4'>
          <Textbox
            maxLength={8}
            label='Hora'
            required
            mask={MaskTypes.Time}
            text={data.horaPedagio}
            onChangedValue={(horaPedagio) => setData({ ...data, horaPedagio })}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-3 mb-4'>
          <Textbox
            label='Valor Pedágio'
            required
            text={data.vlrPedagio}
            mask={MaskTypes.Currency}
            onChangedValue={(vlrPedagio) => setData({ ...data, vlrPedagio })}
          />
        </div>

        <div className='col-4 mb-4'>
          <Textbox
            maxLength={200}
            required
            label='Rodovia'
            text={data.noRodovia}
            onChangedValue={(noRodovia) => setData({ ...data, noRodovia })}
          />
        </div>

        <div className='col-4 mb-4'>
          <Textbox
            maxLength={200}
            required
            label='Praça'
            text={data.noPraca}
            onChangedValue={(noPraca) => setData({ ...data, noPraca })}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-2 mb-4'>
          <Textbox
            label='Nr. Categoria'
            text={data.nrCategoria}
            mask={MaskTypes.Integer}
            onChangedValue={(nrCategoria) => setData({ ...data, nrCategoria })}
          />
        </div>
        <div className='col-3 mb-4'>
          <Textbox
            maxLength={20}
            label='Nr. Cartão'
            text={data.numeroCartao}
            onChangedValue={(numeroCartao) =>
              setData({ ...data, numeroCartao })
            }
          />
        </div>
        <div className='col-2 mb-4'>
          <Switch
            formControl
            label='Desconta Motorista'
            checked={data.flgDescontaMotorista}
            onChange={(flgDescontaMotorista) =>
              setData({ ...data, flgDescontaMotorista })
            }
          />
        </div>
        {data.tituloPagar && (
          <div className='col-2 mb-4'>
            <Textbox
              maxLength={20}
              readOnly
              label='Cód. Título Pagar'
              text={tituloPagarPedagio.cdTituloPagar}
              onChangedValue={(cdTituloPagar) =>
                setTituloPagarPedagio({ ...tituloPagarPedagio, cdTituloPagar })
              }
            />
          </div>
        )}
        {data.flgDescontaMotorista && (
          <div className='col-2 mb-4'>
            <Textbox
              maxLength={20}
              readOnly
              visible={data.flgDescontaMotorista}
              label='Cód. Acerto Motorista'
              text={acertoMotoristaPedagio.cdAcertoMotorista}
              onChangedValue={(cdAcertoMotorista) =>
                setAcertoMotoristaPedagio({
                  ...acertoMotoristaPedagio,
                  cdAcertoMotorista,
                })
              }
            />
          </div>
        )}
      </div>
    </CSDManutPage>
  );
}
