import Aparelho from './aparelho';

export default class PedidoAparelhoAparelho {
  constructor(jsonObject = {}) {
    this.nrSeqPedidoaparelhoAparelho = jsonObject.nrSeqPedidoaparelhoAparelho;
    this.nrSeqPedidoAparelho = jsonObject.nrSeqPedidoAparelho;
    this.nrSeqAparelho = jsonObject.nrSeqAparelho;
    this.aparelho = new Aparelho(jsonObject.Aparelho ?? {});
  }

  toJson = () => JSON.stringify(this);
}
