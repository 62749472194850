export default class MotoristaTipoVinculo {
  constructor(jsonObject = {}) {
    this.nrSeqMotoristaTipoVinculo = jsonObject.nrSeqMotoristaTipoVinculo;
    this.noMotoristaTipoVinculo = jsonObject.noMotoristaTipoVinculo;
    this.cdMotoristaTipoVinculo = jsonObject.cdMotoristaTipoVinculo;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
