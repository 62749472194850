export default class ResumoPedagios {
  constructor(jsonObject = {}) {
    this.placa = jsonObject.placa;
    this.tag = jsonObject.tag;
    this.nrCategoria = jsonObject.nrCategoria;
    this.nrPassagens = jsonObject.nrPassagens;
    this.vlrTotalImportadoPlaca = jsonObject.vlrTotalImportadoPlaca;
    this.tipoUso = jsonObject.tipoUso;
    this.noColorInconsistencia = jsonObject.noColorInconsistencia;
    this.tituloPagarVeiculo = jsonObject.tituloPagarVeiculo ?? {};
  }

  toJson = () => JSON.stringify(this);
}
