import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Button, GridView, Textbox, Panel } from 'ui/components';
import {
  Theme,
  ResponseStatus,
  BootstrapSizes,
  ColumnTypes,
  StatusGrid,
} from 'ui/Helpers/enums';
import { JustifyContent, dateNow } from 'ui/Helpers/utils';
// @ts-expect-error
import { GridviewColumns } from 'ui/Helpers/interfaces.ts';
import { IMotorista } from 'core/interfaces/FRO';
import PessoaHistorico from 'core/models/SEG/pessoaHistorico';

interface IHistoricPanelProps {
  motoristaData: IMotorista;
  setMotoristaData: (e: Object) => void;
  onSetMessage: (status: ResponseStatus, msg: string) => void;
}

const PessoaReferencialPanel = forwardRef(
  (
    { motoristaData, setMotoristaData, onSetMessage }: IHistoricPanelProps,
    ref
  ) => {
    const gridView = useRef<any>();

    const [
      pessoaHistoricoData,
      setPessoaHistoricoData,
    ] = useState<PessoaHistorico>({});

    const fillGridView = () => {
      if (
        gridView &&
        gridView.current &&
        motoristaData.motoristaHistoricos &&
        motoristaData.motoristaHistoricos.length > 0
      ) {
        const gridList = motoristaData.motoristaHistoricos.map((item) => {
          if (item.noImagem) {
            item.flgExibeDownload = true;
          } else {
            item.flgExibeDownload = false;
          }
          return item;
        });
        gridView.current.setDataSource(gridList);
      }
    };

    useImperativeHandle(ref, () => ({
      clear() {
        setPessoaHistoricoData({});
        gridView?.current?.setDataSource([]);
      },
      fillGrid() {
        fillGridView();
      },
      getDataGrid() {
        const listaDocsGrid = gridView?.current?.getDataSource() ?? [];
        return listaDocsGrid;
      },
    }));

    const onClickAddHistorico = async () => {
      if (pessoaHistoricoData.descricao) {
        const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth'))
          .user;

        const obj = {
          ...pessoaHistoricoData,
          status: pessoaHistoricoData.status ?? StatusGrid.Inserir,
          dtCad: dateNow(),
          nrSeqUsuario: sessionUser.nrSeqUsuario,
          usuario: sessionUser,
        };
        const list = gridView.current.getDataSource();
        list.push(obj);

        if (gridView && gridView.current) gridView.current.setDataSource(list);

        setMotoristaData({
          ...motoristaData,
          motoristaHistoricos: list,
        });
        setPessoaHistoricoData({});
      } else if (
        pessoaHistoricoData.descricao === null ||
        pessoaHistoricoData.descricao === undefined
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe uma Descrição');
      }
    };

    const onClickEditPessoaHistorico = (e: any, datasource: any[]) => {
      const obj = datasource.find((item) => item === e);

      if (obj.status !== StatusGrid.Inserir) {
        obj.status = StatusGrid.Alterar;
      }

      setPessoaHistoricoData({ ...obj });

      const listaEquipamentos = datasource.filter((item) => item !== e);

      if (gridView && gridView.current)
        gridView.current.setDataSource(listaEquipamentos);
    };

    const onClickRemovePessoaHistorico = async (e: any) => {
      const list = gridView.current.getDataSource();

      list.filter((el: any) => el === e)[0].status = StatusGrid.Remover;

      if (gridView && gridView.current) gridView.current.setDataSource(list);
    };

    const columns: Array<GridviewColumns> = [
      { key: 'dtCad', title: 'Data', format: GridView.DataTypes.Date },
      { key: 'usuario.noPessoa', title: 'Usuário' },
      {
        key: 'descricao',
        title: 'Descrição',
        sortable: false,
      },
      {
        key: 'nrSeqPessoaHistorico_btnEdit',
        type: ColumnTypes.Button,
        onClick: (e: any, datasource: any) =>
          onClickEditPessoaHistorico(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
        title: 'Editar',
      },
      {
        key: 'nrSeqPessoaHistorico_btnExcluir',
        type: ColumnTypes.Button,
        title: 'Excluir',
        onClick: (e: any) => onClickRemovePessoaHistorico(e),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    return (
      <div className='col mt-3'>
        {/* @ts-expect-error */}
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header
            title='Histórico'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-4'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Descrição'
                  text={pessoaHistoricoData.descricao}
                  maxLength={500}
                  onChangedValue={(descricao: string) =>
                    setPessoaHistoricoData({
                      ...pessoaHistoricoData,
                      descricao,
                    })
                  }
                />
              </div>
              <div className='col d-flex align-items-end'>
                <Button
                  onClick={onClickAddHistorico}
                  outline
                  theme={Theme.Success}
                  text='Adicionar'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridView}
                  // @ts-expect-error
                  dataColumns={columns}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
);

export default PessoaReferencialPanel;
