import { getFormaPagamentoAutoComplete } from 'core/services/FIN';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import { getCentroDeCustoList } from 'core/services/TEL';
import {
  getCancelaFaturaContaTelefone,
  postCancelaFaturaContaTelefone,
} from 'core/services/TEL/faturarContaTelefone';
import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Modal,
  Autocomplete,
  DatePicker,
  Loader,
  Notification,
  GridView,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  BootstrapSizes,
  formatStringNumberToFloat,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';

const columns = [
  {
    key: 'nrSeqTituloReceberFatura',
    type: GridView.ColumnTypes.Checkbox,
  },
  { key: 'linhaFormatada', title: 'Nº Linha' },
  { key: 'pessoa.noPessoa', title: 'Cliente' },
  {
    key: 'vlrFatura',
    title: 'Valor',
    format: GridView.DataTypes.Decimal,
    visible: false,
  },
  {
    key: 'vlrFatura',
    title: 'Valor',
    format: GridView.DataTypes.Money,
    visible: false,
  },
  {
    key: 'vlrTitulo',
    title: 'Valor',
    format: GridView.DataTypes.Decimal,
    visible: false,
  },
  {
    key: 'vlrTitulo',
    title: 'Valor',
    format: GridView.DataTypes.Money,
  },
  { key: 'dtVencimento', title: 'Vencimento', format: GridView.DataTypes.Date },
];

function RenderTextMask(props) {
  const { label } = props;
  return (
    <div className='col-auto mt-3'>
      <div className='fw-bold'>{label}</div>
    </div>
  );
}

export default function ModalCancelaFaturamento({ show, onClose }) {
  const [data, setData] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const gridView = useRef();
  const msglinhas = 'Total Linhas: ';
  const msgvalor = 'Total Reais: R$';
  const [totalLinhas, setTotalLinhas] = useState(msglinhas);
  const [totalReais, setTotalReais] = useState(msgvalor);

  useEffect(() => {
    if (show) {
      setData({});
      setSelectedItems({});
      setTotalLinhas(msglinhas + 0);
      setTotalReais(msgvalor + 0);
    }
  }, [show]);

  const onCalculaTotais = (sourceRow) => {
    let totalV = 0;
    if (sourceRow[0].state !== undefined) {
      const selecteds = gridView.current.getCheckBoxValuesAt(0);
      if (!selecteds || selecteds.length <= 0) {
        setTotalLinhas(msglinhas + 0);
        setTotalReais(msgvalor + 0);
      } else {
        const linhas = selecteds.map((column) => ({
          nrSeqContaCabecalho: column.find(
            (prop) => prop.key === 'nrSeqTituloReceberFatura'
          ).value,
          totalFatura: column.find((prop) => prop.key === 'vlrTitulo').value,
        }));
        for (let i = 0; i < linhas.length; ) {
          totalV += Number(
            formatStringNumberToFloat(linhas[i].totalFatura.trim())
          );
          i += 1;
        }

        setTotalLinhas(msglinhas + selecteds.length);
        setTotalReais(msgvalor + totalV.toFixed(2));
      }
    }
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchCancelaFaturaContaTelefone = async () => {
    onSetMessage();
    setLoading(true);

    const {
      status,
      message: msg,
      contas,
      pagination,
    } = await getCancelaFaturaContaTelefone(data);

    onSetMessage(status, msg);

    if (gridView && gridView.current)
      gridView.current.setDataSource(contas, pagination);

    setLoading(false);
  };

  const onCancelarFaturaContaTelefone = async () => {
    onSetMessage();
    setLoading(true);

    const param = {
      ...data,
      nrSeqsTituloReceberFatura: gridView.current
        .getCheckBoxValuesAt(0)
        ?.map((row) => row[0].value),
    };

    const { status, message: msg } = await postCancelaFaturaContaTelefone(
      param
    );

    onSetMessage(status, msg);

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...data, sortBy };
    setData(filters);
    await onSearchCancelaFaturaContaTelefone(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...data, totalByPage };
    setData(filters);
    await onSearchCancelaFaturaContaTelefone(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...data, page };
    setData(filters);
    await onSearchCancelaFaturaContaTelefone(filters);
  };

  const onSearchCentroDeCusto = async (e) => {
    const { data: centroDeCusto } = await getCentroDeCustoList({
      noCentroDeCusto: e,
    });

    return centroDeCusto;
  };

  const onSearchTipo = async (e) => {
    const { data: tituloReceberEspecie } = await getTituloEspecieAutoComplete({
      noEspecie: e,
    });

    return tituloReceberEspecie;
  };

  const onAutoCompleteFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
    });

    onSetMessage(status, msg);

    return formasPagamento;
  };

  return (
    <Modal
      show={show}
      title='Cancelar Faturamento'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body className='p-4 pt-3'>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <Loader loading={loading} />
        <div className='row mb-3'>
          <div className='col'>
            <DatePicker
              label='Cadastro'
              text={data.dtCadastro}
              mask={MaskTypes.Date}
              onChange={(dtCadastro) =>
                setData({
                  ...data,
                  dtCadastro,
                })
              }
            />
          </div>
          <div className='col'>
            <DatePicker
              label='Vencimento'
              text={data.dtVencimento}
              mask={MaskTypes.Date}
              onChange={(dtVencimento) =>
                setData({
                  ...data,
                  dtVencimento,
                })
              }
            />
          </div>
          <div className='col'>
            <Autocomplete
              label='Forma de Recebimento'
              searchDataSource={onAutoCompleteFormaPagamento}
              selectedItem={selectedItems.formaPagamento}
              onSelectItem={(formaPagamento) => {
                setSelectedItems({
                  ...selectedItems,
                  formaPagamento,
                });
                setData({
                  ...data,
                  nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                });
              }}
              dataSourceTextProperty='noFormaPagamento'
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <Autocomplete
              label='Centro de Custo'
              searchDataSource={onSearchCentroDeCusto}
              selectedItem={data.centroDeCusto}
              onSelectItem={(centroDeCusto) => {
                setData({
                  ...data,
                  centroDeCusto,
                  nrSeqCentroDeCusto: centroDeCusto.nrSeqCentroDeCusto,
                });
              }}
              dataSourceTextProperty='noCentroDeCusto'
            />
          </div>
          <div className='col'>
            <Autocomplete
              label='Tipo Receita'
              searchDataSource={onSearchTipo}
              selectedItem={data.tituloEspecie}
              onSelectItem={async (tituloEspecie) => {
                setData({
                  ...data,
                  tituloEspecie,
                  nrSeqTituloEspecie: tituloEspecie.nrSeqTituloEspecie,
                });
              }}
              dataSourceTextProperty='noEspecie'
              nrseqTransactionToOpen='nrSeqTituloEspecie'
            />
          </div>
          <div className='col mt-4'>
            <Button
              theme={Theme.Info}
              template={Button.Templates.Button}
              text='Pesquisar'
              className='width100'
              onClick={onSearchCancelaFaturaContaTelefone}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='bg-primary text-white bg-gradient rounded-2 p-3'>
            <div className='row'>
              <div className='col'>
                <div className='display-8'>
                  Conforme os filtros aplicados, serão canceladas as faturas
                </div>
                <hr className='mt-1 mb-0' />
                <div className='row'>
                  <RenderTextMask label={totalLinhas} />
                  <RenderTextMask label={totalReais} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              onColumnSort={onColumnSort}
              onPageSizeChange={onPageSizeChange}
              onPageChange={onPageChange}
              onRowRender={onCalculaTotais}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Cancelar Faturamento'
          theme={Theme.Success}
          onClick={() => {
            onCancelarFaturaContaTelefone();
          }}
        />
        <Button
          text='Sair'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
