import qs from 'qs';
import Pagination from 'core/models/pagination';
import PessoaDocumento from 'core/models/SEG/pessoaDocumento';
import api from '../api';

const baseURL = `/SEG/PessoaDocumento`;

export const getPessoaDocumentoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PessoaDocumento(json)) ?? [],
  };
};

export const getPessoaDocumentoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PessoaDocumento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPessoaDocumentoPaginedEmployeeDocument = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPaginedEmployeeDocument`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PessoaDocumento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPessoaDocumento = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PessoaDocumento(value) : {};
};

export const savePessoaDocumento = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePessoaDocumento = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPessoaDocumento = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printDocumentoFuncionario = async (filters) => {
  const response = await api.get(`${baseURL}/PrintDocumentoFuncionario`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPessoaDocumento = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPessoaDocumento = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const sendSms = async (filters) =>
  (await api.post(`${baseURL}/EnviarSms`, filters)).data ?? {};
