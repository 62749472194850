import React, { useState, useEffect } from 'react';

import {
  Modal,
  Loader,
  Notification,
  Button,
  DropdownList,
  Textarea,
} from 'ui/components';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';

import { dropDownListRotaTransporte } from 'core/services/FRO/rotaTransporte';

export default function ModalEffectMdfe({ show, onClose, onEffect, data }) {
  const [dataSource, setDataSource] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [dropDownRotas, setDropDownRotas] = useState([]);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSend = () => {
    onClose();
  };

  const searchRotas = async () => {
    const {
      status,
      message: msg,
      data: rotas,
    } = await dropDownListRotaTransporte({
      nrSeqEstadoOri: data.nrSeqEstadoOri,
      nrSeqEstadoDest: data.nrSeqEstadoDest,
    });

    if (msg) onSetMessage(status, msg);

    setDropDownRotas(rotas);
  };

  const load = async () => {
    setLoading(true);

    await searchRotas();

    setDataSource(data);

    setLoading(false);
  };

  useEffect(() => {
    if (show) load();
  }, [show]);

  return (
    <Modal
      show={show}
      title='Efetivar Manifesto Eletrônico (MDFe)'
      onClose={() => onSend()}
      size={BootstrapSizes.Large}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='row mb-3'>
            <div className='col'>
              <DropdownList
                label='Rotas'
                dataSource={dropDownRotas}
                dataSourcePropertyText='noRotaCompleta'
                dataSourcePropertyValue='nrSeqRotaTransporte'
                selectedItems={dataSource?.rotaTransporte ?? []}
                onSelectItem={(item) => {
                  setDataSource({ ...dataSource, rotaTransporte: item });
                }}
              />
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col'>
              <Textarea
                label='Observação'
                rows={4}
                text={dataSource?.observacao}
                onChangedValue={(observacao) => {
                  setDataSource({ ...dataSource, observacao });
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Medium}
          icon={['far', 'save']}
          theme={Theme.Success}
          template={Button.Templates.Default}
          onClick={() => {
            setDataSource({});

            onEffect(dataSource);
          }}
          text='Efetivar'
        />
        <Button
          size={BootstrapSizes.Medium}
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          onClick={() => onSend()}
          text='Sair'
        />
      </Modal.Footer>
    </Modal>
  );
}
