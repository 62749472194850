import React, { useState } from 'react';

import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { Button, Loader, Modal, Notification, Textbox } from 'ui/components';

import { postCancel } from 'core/services/VEN/Nfv';

export default function ModalCancelar({ nfv, show, onClose }) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onClickCancelar = async () => {
    setLoading(true);

    const { nrSeqNfv } = nfv;

    data.nrSeqNfv = nrSeqNfv;

    const { status, message: msg } = await postCancel(data);

    setLoading(false);

    onSetMessage(status, msg);
  };

  const onSend = async () => {
    onClickCancelar();
  };

  return (
    <Modal
      show={show}
      title='Cancelar Nota Fiscal'
      onClose={onClose}
      size={BootstrapSizes.Large}
      icon={['far', 'comment-alt']}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col'>
            <Textbox
              label='Justificativa'
              text={data.noMotivoCancelamento}
              required
              onChangedValue={(noMotivoCancelamento) => {
                setData({ ...data, noMotivoCancelamento });
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Cancelar'
          theme={Theme.Success}
          icon='sms'
          onClick={onSend}
        />
        <Button
          text='Sair'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
