import React, { useState, useRef, useEffect, useCallback } from 'react';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import {
  Button,
  GridView,
  Loader,
  Modal,
  Notification,
  Textbox,
} from 'ui/components';

import { MaskTypes } from 'ui/Helpers/masks';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { getNfvAvulsaItemList } from 'core/services/VEN/nfvAvulsaItem.tsx';

const ModalItensSaida = ({
  show,
  nrSeqProduto,
  nrSeqPocOrcamento,
  findTransaction,
  onOpenTransaction,
  onClose,
}) => {
  const gridViewProdutosSaida = useRef();
  const [loading, setLoading] = useState(false);
  const [vlrTotal, setVlrTotal] = useState(0);
  const [message, setMessage] = useState(false);

  const load = useCallback(async () => {
    if (Number.isInteger(nrSeqPocOrcamento)) {
      setLoading(true);
      const { data: value } = await getNfvAvulsaItemList({
        nrSeqProduto,
        nrSeqPocOrcamento,
      });

      const filteredValue = value.filter(
        (item) =>
          item.nfvAvulsa?.dtVenda !== null &&
          item.nfvAvulsa?.dtVenda !== undefined
      );

      filteredValue.forEach((item) => {
        if (
          item.nfvAvulsa?.tipoNfvAvulsa?.flgGeraEstq &&
          item.nfvAvulsa?.tipoNfvAvulsa?.flgRetornoMercadoria
        ) {
          item.qtdeItNfv = -item.qtdeItNfv;
          item.vlrItNfv = -item.vlrItNfv;
          item.vlrTotNfv = -item.vlrTotNfv;
          item.noStatusMovimento = 'Retorno';
        } else {
          item.noStatusMovimento = 'Saída';
        }
      });

      const totalSaida = value.reduce((acc, item) => acc + item.vlrTotNfv, 0);

      setVlrTotal(totalSaida);

      if (gridViewProdutosSaida && gridViewProdutosSaida.current)
        gridViewProdutosSaida.current.setDataSource(filteredValue);
      setLoading(false);
    }
  }, [nrSeqPocOrcamento]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, nrSeqProduto]);

  const onOpenNfvAvulsa = (datasource) => {
    setLoading(true);

    const transactionOpen = findTransaction(TransacationCodes.NotaFiscalAvulsa);

    if (transactionOpen) {
      transactionOpen.registryKey = datasource.nrSeqNfvAvulsa;
      onOpenTransaction(transactionOpen, true);

      setLoading(false);
    } else {
      setMessage({
        message: 'Não foi possível abrir a Nota Fiscal Avulsa.',
        theme: Theme.Danger,
      });
    }

    setLoading(false);
  };

  const columnsProdutosSaida = [
    { key: 'nrSeqProduto', title: 'nrSeqProduto', visible: false },
    { key: 'noStatusMovimento', title: 'Status' },
    { key: 'nfvAvulsa.nrNfvAvulsa', title: 'Nota' },
    {
      key: 'produto.produtoAgrupador.noProduto',
      title: 'Produto',
    },
    {
      key: 'produto.produtoUnidadeMedida.noProdutoUnidadeMedida',
      title: 'Unidade Medida',
    },
    {
      key: 'qtdeItNfv',
      title: 'Quantidade',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrItNfv',
      title: 'Unitário',
      format: GridView.DataTypes.Decimal,
    },
    { key: 'vlrTotNfv', title: 'Total', format: GridView.DataTypes.Decimal },
    {
      key: 'dtMovimento',
      title: 'Movimentação',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrSeqNfvAvulsa',
      type: GridView.ColumnTypes.Button,
      onClick: (datasource) => onOpenNfvAvulsa(datasource),
      theme: Theme.Warning,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Importar',
      tooltipDirection: 'bottom',
      title: 'Abrir',
    },
  ];

  return (
    <Modal
      show={show}
      size={BootstrapSizes.ExtraLarge}
      title='Itens não agrupado'
      onClose={onClose}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mt-5'>
          <div className='col'>
            <GridView
              ref={gridViewProdutosSaida}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnsProdutosSaida}
              showSelectSizes={false}
              showPagination={false}
            />
          </div>
        </div>
        <div className='row mb-2 justify-content-end'>
          <div className='col-3 mb-1'>
            <Textbox
              label={
                <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                  Valor Total Saída
                </span>
              }
              text={vlrTotal}
              style={{ width: '100%' }}
              mask={MaskTypes.Currency}
              readOnly
            />
          </div>
        </div>
        <div className='row mt-5' />
      </Modal.Body>
      <Modal.Footer>
        <Button
          icon='ban'
          text='Fechar'
          theme={Theme.Danger}
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default ModalItensSaida;
