export default class OrdemCompra {
  constructor(jsonObject = {}) {
    this.nrSeqOrdemCompra = jsonObject.nrSeqOrdemCompra;
    this.dtPedido = jsonObject.dtPedido;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.dtCad = jsonObject.dtCad;
    this.obs = jsonObject.obs;
    this.dtCancel = jsonObject.dtCancel;
    this.nrSeqPessoaAut = jsonObject.nrSeqPessoaAut;
    this.nrSeqPessoaSol = jsonObject.nrSeqPessoaSol;
    this.dtAutorizacao = jsonObject.dtAutorizacao;
    this.nrSeqPessoaCop = jsonObject.nrSeqPessoaCop;
    this.dtOrcamento = jsonObject.dtOrcamento;
    this.dtPedidoCompra = jsonObject.dtPedidoCompra;
    this.flgPedidoDireto = jsonObject.flgPedidoDireto;
    this.statusOrdemCompra = jsonObject.statusOrdemCompra;
    this.flgOrdemCancelada = jsonObject.flgOrdemCancelada;
    this.flgPedidoCompra = jsonObject.flgPedidoCompra;
    this.noCorStatus = jsonObject.noCorStatus;
    this.produto = jsonObject.produto ?? {};
    this.pessoaSol = jsonObject.pessoaSol ?? {};
    this.pessoaCop = jsonObject.pessoaCop ?? {};
    this.ordensCompra = jsonObject.ordensCompra ?? [];
    this.empresa = jsonObject.empresa ?? {};
    this.documentos = jsonObject.documentos ?? [];
    this.ordemItensDescricao = jsonObject.ordemItensDescricao;
  }

  toJson = () => JSON.stringify(this);
}
