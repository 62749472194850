import React, { useEffect, useRef, useState } from 'react';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
// import { printNfs, excelNfs } from 'core/services/VEN/nfs';
import { getPocOrcamentoAutoComplete } from 'core/services/POC/pocOrcamento';
import { getProdutoAutoComplete } from 'core/services/EST/produto';
import {
  getNfAvulsaPagined,
  deleteAllNfAvulsa,
  printNfAvulsaReport,
  printNfAvulsaReportBudge,
  excelNfAvulsa,
} from 'core/services/VEN/nfvAvulsa.tsx';
import { getTipoNfvAvulsaAutoComplete } from 'core/services/VEN/tipoNfvAvulsa';

import {
  CSDSelPage,
  GridView,
  Autocomplete,
  DatePicker,
  Textbox,
  ToolbarButtons,
} from 'ui/components';

const columns = [
  { key: 'nrSeqNfvAvulsa', type: GridView.ColumnTypes.Checkbox },
  { key: 'cliente.noPessoa', title: 'Cliente', sortKey: 'noPessoa' },
  {
    key: 'orcamento.noLocalObra',
    title: 'Local da Obra',
    sortKey: 'noLocalObra',
  },
  {
    key: 'tipoNfvAvulsa.noTipoNfvAvulsa',
    title: 'Tipo de Nota',
  },
  { key: 'dtCad', title: 'Data Cadastro', format: GridView.DataTypes.Date },
  { key: 'dtSaida', title: 'Saída', format: GridView.DataTypes.Date },
  {
    key: 'vlrNfvAvulsa',
    title: 'Valor Produtos',
    format: GridView.DataTypes.Decimal,
  },
  { key: 'vlrDesc', title: 'Desconto', format: GridView.DataTypes.Decimal },
  { key: 'vlrFrete', title: 'Frete', format: GridView.DataTypes.Decimal },
  {
    key: 'nrNfvAvulsa',
    title: 'N° Nota Avulsa',
    format: GridView.DataTypes.Integer,
  },

  {
    key: 'nfv.nrNfv',
    title: 'N° Nota Efetivada',
    sortKey: 'nrNfv',
    format: GridView.DataTypes.Integer,
  },
];

export default function NotaFiscalAvulsa({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchPessoaCli = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return clientes;
  };

  const onSearchProduto = async (e) => {
    const {
      status,
      message: msg,
      data: produtos,
    } = await getProdutoAutoComplete({
      noSearchField: e,
    });
    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const search = async () => {
    setLoading(true);
    const {
      nrSeqEmpresa,
      dtNfAvulsaInicial,
      dtNfAvulsaFinal,
      nrSeqPessoaCli,
      nrNfv,
      nrNfvAvulsa,
      nrSeqPocOrcamento,
      dtSaidaInicial,
      dtSaidaFinal,
      nrSeqProduto,
      nrSeqTipoNfvAvulsa,
    } = filtros;
    const { data, pagination } = await getNfAvulsaPagined({
      nrSeqEmpresa,
      dtNfAvulsaInicial,
      dtNfAvulsaFinal,
      nrSeqPessoaCli,
      nrNfv,
      nrNfvAvulsa,
      nrSeqPocOrcamento,
      dtSaidaInicial,
      dtSaidaFinal,
      nrSeqProduto,
      nrSeqTipoNfvAvulsa,
    });

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getNfAvulsaPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'NotaFiscalAvulsa/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqNfvAvulsa = sourceRow.find((e) => e.key === 'nrSeqNfvAvulsa');
    onOpenMaintenance(nrSeqNfvAvulsa.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printNfAvulsaReport(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onPrintBudge = async () => {
    setLoading(true);
    const { value } = await printNfAvulsaReportBudge(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDownloadExcel = async () => {
    setLoading(true);

    const obj = { ...filtros };

    const value = await excelNfAvulsa(obj);

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `NotaFiscalAvulsa.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllNfAvulsa(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchPocOrcamento = async (e) => {
    const {
      status,
      message: msg,
      data: orcamentos,
    } = await getPocOrcamentoAutoComplete({
      cdProposta: e,
    });

    if (msg) {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    return orcamentos;
  };

  const onSearchTipoNotaFiscalAvulsa = async (e) => {
    const {
      status,
      message: msg,
      data: tiposnotafiscal,
    } = await getTipoNfvAvulsaAutoComplete({
      noTipoNfvAvulsa: e,
    });
    if (msg) onSetMessage(status, msg);
    return tiposnotafiscal;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Notas Fiscais'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Excel'
          icon={['far', 'file-excel']}
          onClick={() => onDownloadExcel('Padrao')}
        />

        <ToolbarButtons.Button
          text='Imprimir p/ Orçamento'
          icon={['far', 'file-excel']}
          onClick={() => onPrintBudge()}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col-7'>
          <Autocomplete
            label='Empresa'
            searchDataSource={onSearchEmpresa}
            selectedItem={filtros.empresa}
            onSelectItem={(empresa) =>
              setFiltros({
                ...filtros,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>

        <div className='col-2'>
          <DatePicker
            label='Data Inicial'
            text={filtros.dtNfAvulsaInicial}
            mask={MaskTypes.Date}
            onChange={(dtNfAvulsaInicial) =>
              setFiltros({
                ...filtros,
                dtNfAvulsaInicial,
              })
            }
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data Final'
            text={filtros.dtNfAvulsaFinal}
            mask={MaskTypes.Date}
            onChange={(dtNfAvulsaFinal) =>
              setFiltros({
                ...filtros,
                dtNfAvulsaFinal,
              })
            }
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-7'>
          <Autocomplete
            label='Cliente'
            searchDataSource={onSearchPessoaCli}
            selectedItem={filtros.cliente}
            onSelectItem={(cliente) =>
              setFiltros({
                ...filtros,
                cliente,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>

        <div className='col-2'>
          <Textbox
            label='N° Nota Efetivada'
            text={filtros.nrNfv}
            maxLength={20}
            onChangedValue={(nrNfv) =>
              setFiltros({
                ...filtros,
                nrNfv,
              })
            }
          />
        </div>

        <div className='col-2'>
          <Textbox
            label='N° Nota Avulsa'
            text={filtros.nrNfvAvulsa}
            maxLength={20}
            onChangedValue={(nrNfvAvulsa) =>
              setFiltros({
                ...filtros,
                nrNfvAvulsa,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-7'>
          <Autocomplete
            label='Orçamento'
            searchDataSource={onSearchPocOrcamento}
            selectedItem={filtros.orcamento}
            onSelectItem={(orcamento) =>
              setFiltros({
                ...filtros,
                orcamento,
                nrSeqPocOrcamento: orcamento.nrSeqPocOrcamento,
              })
            }
            dataSourceTextProperty='cdProposta'
          />
        </div>

        <div className='col-2'>
          <DatePicker
            label='Data Saída Inicial'
            text={filtros.dtSaidaInicial}
            mask={MaskTypes.Date}
            onChange={(dtSaidaInicial) =>
              setFiltros({
                ...filtros,
                dtSaidaInicial,
              })
            }
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data Saída Final'
            text={filtros.dtSaidaFinal}
            mask={MaskTypes.Date}
            onChange={(dtSaidaFinal) =>
              setFiltros({
                ...filtros,
                dtSaidaFinal,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-7'>
          <Autocomplete
            label='Produto'
            searchDataSource={onSearchProduto}
            selectedItem={filtros.produto}
            onSelectItem={(produto) => {
              setFiltros({
                ...filtros,
                produto,
                nrSeqProduto: produto.nrSeqProduto,
              });
            }}
            dataSourceTextProperty='noProduto'
          />
        </div>
        <div className='col-5'>
          <Autocomplete
            label='Tipo Nota Fiscal Avulsa'
            searchDataSource={onSearchTipoNotaFiscalAvulsa}
            selectedItem={filtros.tipoNfvAvulsa}
            onSelectItem={(tipoNfvAvulsa) =>
              setFiltros({
                ...filtros,
                tipoNfvAvulsa,
                nrSeqTipoNfvAvulsa: tipoNfvAvulsa.nrSeqTipoNfvAvulsa,
              })
            }
            dataSourceTextProperty='noTipoNfvAvulsa'
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            canControlVisibility
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
