import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  saveVendedorTelefonia,
  getVendedorTelefonia,
  deleteVendedorTelefonia,
  getCentroDeCustoAutoComplete,
} from 'core/services/TEL';
import { getVendedorAutoComplete } from 'core/services/VEN/vendedor';
import {
  Autocomplete,
  CSDManutPage,
  DropdownMulti,
  Switch,
  Textbox,
} from 'ui/components';
import { VendedorTelefonia } from 'core/models/TEL';
import { MaskTypes } from 'ui/Helpers/masks';

const VendedorTelefoniaItem = (props) => {
  const dropDownCentrosCusto = useRef();

  const { registryKey } = props;

  const [filters, setFilters] = useState({});
  const [messageState, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [vendedorTelefonia, setVendedorTelefoniaDataSource] = useState([]);

  const onSetMessage = (status, message) => {
    if (message)
      setMessage({
        message,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const searchVendedorTelefonia = async (e) => {
    if (e === 0) {
      setFilters({});
      setVendedorTelefoniaDataSource(new VendedorTelefonia());
      setSelectedItems({ ...selectedItems, centrosCusto: [] });
    } else {
      const result = await getVendedorTelefonia(registryKey);
      setVendedorTelefoniaDataSource(result);
      setFilters(result);

      onSetMessage(result.status, result.message);
    }
  };

  const onSearchVendedor = async (e) => {
    const { status, message, vendedores } = await getVendedorAutoComplete({
      noPessoa: e,
    });
    onSetMessage(status, message);
    return vendedores;
  };
  const searchCentroDeCusto = async (e) => {
    const { data: centrocustos } = await getCentroDeCustoAutoComplete();
    const vendTelefonia = await getVendedorTelefonia(e);
    const centrosCusto = [];
    if (centrocustos.length > 0 && e > 0) {
      centrocustos.forEach((item) => {
        const ret = vendTelefonia.nrSeqsCentroDeCusto.findIndex(
          (nrseq) => nrseq === item.nrSeqCentroDeCusto
        );

        if (ret >= 0) {
          centrosCusto.push(item);
        }
      });
      setSelectedItems({ ...selectedItems, centrosCusto });
    }
    return centrocustos;
  };

  const onSave = async () => {
    onSetMessage();
    setLoading(true);

    const { status, message } = await saveVendedorTelefonia(filters);

    setMessage({
      message,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onDelete = async () => {
    onSetMessage();
    setLoading(true);

    const { status, message } = await deleteVendedorTelefonia(
      filters.nrSeqVendedor_Telefonia
    );

    setMessage({
      message,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const load = useCallback(async () => {
    if (registryKey && !Number.isNaN(registryKey)) {
      searchVendedorTelefonia(registryKey).then(() =>
        dropDownCentrosCusto.current.loadDataSource(() =>
          searchCentroDeCusto(registryKey)
        )
      );
    } else {
      searchVendedorTelefonia(0);
      dropDownCentrosCusto.current.loadDataSource(searchCentroDeCusto);
      setVendedorTelefoniaDataSource({ flgDescontaParcelaAberta: true });
    }
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [registryKey]);

  const { isActive, transaction } = props;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Vendedor Telefonia'
      loading={loading}
      message={messageState}
      onSave={onSave}
      onNew={() => setVendedorTelefoniaDataSource(new VendedorTelefonia())}
      onDelete={onDelete}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      {vendedorTelefonia.noPessoa ? (
        <div className='row mb-3'>
          <Textbox
            label='Vendedor'
            text={vendedorTelefonia.noPessoa ?? ' '}
            readOnly
          />
        </div>
      ) : (
        <Autocomplete
          label='Vendedor'
          searchDataSource={onSearchVendedor}
          required
          selectedItem={filters.NrSeqPessoaVen}
          onSelectItem={(item) =>
            setFilters({
              ...filters,
              noPessoa: item.noPessoa,
              nrSeqPessoaVen: item.nrSeqPessoa,
            })
          }
          dataSourceTextProperty='noPessoa'
        />
      )}
      <div className='col'>
        <div className='row mb-2'>
          <div className='col'>
            <Switch
              formControl
              label='Desconta Custo Nota Fiscal'
              checked={vendedorTelefonia.flgDescontaCustoNf}
              onChange={(flgDescontaCustoNf) =>
                setFilters({
                  ...filters,
                  flgDescontaCustoNf,
                })
              }
            />
          </div>

          <div className='col'>
            <Switch
              formControl
              label='Desconta Aviso'
              checked={vendedorTelefonia.flgDescontaAviso}
              onChange={(flgDescontaAviso) =>
                setFilters({
                  ...filters,
                  flgDescontaAviso,
                })
              }
            />
          </div>
          <div className='col'>
            <Switch
              formControl
              label='Desconta Custo Taxa Bancaria'
              checked={vendedorTelefonia.flgDescontaCustoTaxaBancaria}
              onChange={(flgDescontaCustoTaxaBancaria) =>
                setFilters({
                  ...filters,
                  flgDescontaCustoTaxaBancaria,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col'>
          <Switch
            formControl
            label='Desconta Custo Rateio'
            checked={vendedorTelefonia.flgDescontaCustoRateio}
            onChange={(flgDescontaCustoRateio) =>
              setFilters({
                ...filters,
                flgDescontaCustoRateio,
              })
            }
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Percentual'
            checked={vendedorTelefonia.flgPercentual}
            onChange={(flgPercentual) =>
              setFilters({
                ...filters,
                flgPercentual,
              })
            }
          />
        </div>
        <div className='col'>
          <Switch
            label='Parcela Única'
            checked={vendedorTelefonia.flgParcelaUnica}
            formControl
            onChange={(flgParcelaUnica) =>
              setFilters({
                ...filters,
                flgParcelaUnica,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Switch
            label='Desconta Parcela Aberta'
            checked={vendedorTelefonia.flgDescontaParcelaAberta}
            formControl
            onChange={(flgDescontaParcelaAberta) =>
              setFilters({
                ...filters,
                flgDescontaParcelaAberta,
              })
            }
          />
        </div>

        <div className='col-3'>
          <Textbox
            label='Comissão'
            text={filters.vlrComissao}
            required
            mask={MaskTypes.Decimal}
            onChangedValue={(vlrComissao) =>
              setFilters({ ...filters, vlrComissao })
            }
          />
        </div>
      </div>
      <div className='row mb-1'>
        <div className='col-8'>
          <DropdownMulti
            label='Centro de Custo'
            ref={dropDownCentrosCusto}
            dataSourcePropertyText='noCentroDeCusto'
            dataSourcePropertyValue='nrSeqCentroDeCusto'
            selectedItems={selectedItems.centrosCusto ?? []}
            onSelectItem={(centrosCusto) => {
              setSelectedItems({ ...selectedItems, centrosCusto });
              setFilters({
                ...filters,
                nrSeqsCentroDeCusto: centrosCusto?.map(
                  (p) => p.nrSeqCentroDeCusto
                ),
              });
            }}
          />
        </div>
      </div>
    </CSDManutPage>
  );
};

export default VendedorTelefoniaItem;
