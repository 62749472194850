import React, { useEffect } from 'react';
import {
  Panel,
  Textbox,
  Autocomplete,
  DatePicker,
  Switch,
} from 'ui/components';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
// @ts-expect-error
import { Theme } from 'ui/Helpers/enums.ts';
import { MaskTypes } from 'ui/Helpers/masks';

interface Props {
  funcionarioData: any;
  setFuncionarioData: (object: any) => void;
}

const FuncionarioPanel = ({ funcionarioData, setFuncionarioData }: Props) => {
  const onSearchEmpresa = async (e: any) => {
    const { empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });

    return empresas;
  };

  const onSearchEstado = async (e: any) => {
    const { estados } = await getEstadoAutoComplete({
      noEstado: e,
    });

    return estados;
  };

  useEffect(() => {
    const objfuncionario = { ...funcionarioData };

    setFuncionarioData(objfuncionario);
  }, [funcionarioData.nrSeqPessoaFun]);

  return (
    <div className='col mt-3'>
      {/* @ts-expect-error */}
      <Panel>
        {/* @ts-expect-error */}
        <Panel.Header title='Funcionário' theme={Theme.Primary} />

        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Textbox
                id='txtCdFuncionario'
                label='Código'
                text={funcionarioData?.cdFuncionario}
                maxLength={20}
                onChangedValue={(cdFuncionario: string) => {
                  setFuncionarioData({
                    ...funcionarioData,
                    cdFuncionario,
                  });
                }}
              />
            </div>
            <div className='col-5'>
              <Autocomplete
                id='txtEmpresa'
                label='Empresa'
                searchDataSource={onSearchEmpresa}
                selectedItem={funcionarioData.empresa}
                onSelectItem={(empresa: any) =>
                  setFuncionarioData({
                    ...funcionarioData,
                    empresa,
                    nrSeqEmpresa: empresa.nrSeqEmpresa,
                  })
                }
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-1'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={funcionarioData?.flgMotorista}
                label='Motorista'
                onChange={(flgMotorista: boolean) =>
                  setFuncionarioData({
                    ...funcionarioData,
                    flgMotorista,
                  })
                }
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <DatePicker
                id='txtDtAdmissao'
                label='Data Admissão'
                text={funcionarioData?.dtAdmissao}
                onChange={(dtAdmissao: Date | string) =>
                  setFuncionarioData({
                    ...funcionarioData,
                    dtAdmissao,
                  })
                }
              />
            </div>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <DatePicker
                label='Data de Demissão'
                text={funcionarioData?.dtDemissao}
                onChange={(dtDemissao: Date | string) => {
                  setFuncionarioData({
                    ...funcionarioData,
                    dtDemissao,
                  });
                }}
              />
            </div>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Textbox
                label='Salário'
                mask={MaskTypes.DecimalCustom}
                maxLength={14}
                decimalPlaces={2}
                text={funcionarioData?.vlrSalario}
                onChangedValue={(vlrSalario: number) => {
                  setFuncionarioData({
                    ...funcionarioData,
                    vlrSalario,
                  });
                }}
              />
            </div>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Textbox
                label='Comissão'
                mask={MaskTypes.DecimalCustom}
                maxLength={6}
                decimalPlaces={2}
                text={funcionarioData?.vlrComissao}
                onChangedValue={(vlrComissao: number) => {
                  setFuncionarioData({
                    ...funcionarioData,
                    vlrComissao,
                  });
                }}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-3'>
              {/* @ts-expect-error */}
              <Textbox
                label='NIS'
                text={funcionarioData?.noNis}
                maxLength={50}
                onChangedValue={(noNis: string) => {
                  setFuncionarioData({
                    ...funcionarioData,
                    noNis,
                  });
                }}
              />
            </div>
            <div className='col-3'>
              {/* @ts-expect-error */}
              <Textbox
                label='N° Carteira de Trabalho'
                maxLength={11}
                text={funcionarioData?.noCtps}
                onChangedValue={(noCtps: string) => {
                  setFuncionarioData({
                    ...funcionarioData,
                    noCtps,
                  });
                }}
              />
            </div>
            <div className='col-3'>
              {/* @ts-expect-error */}
              <Textbox
                label='Série Carteira de Trabalho'
                maxLength={5}
                text={funcionarioData?.noSerieCtps}
                onChangedValue={(noSerieCtps: string) => {
                  setFuncionarioData({
                    ...funcionarioData,
                    noSerieCtps,
                  });
                }}
              />
            </div>
            <div className='col-3'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={funcionarioData?.flgGeraCustoRecorrente}
                label='Gerar Custo Recorrente'
                onChange={(flgGeraCustoRecorrente: boolean) =>
                  setFuncionarioData({
                    ...funcionarioData,
                    flgGeraCustoRecorrente,
                  })
                }
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-6'>
              {/* @ts-expect-error */}
              <Textbox
                label='Observações'
                maxLength={127}
                text={funcionarioData?.obs}
                onChangedValue={(obs: string) => {
                  setFuncionarioData({
                    ...funcionarioData,
                    obs,
                  });
                }}
              />
            </div>
            <div className='col-4 mb-3'>
              <Autocomplete
                label='Estado CTPS'
                searchDataSource={onSearchEstado}
                selectedItem={funcionarioData.estadoCtps}
                onSelectItem={(estadoCtps: any) => {
                  setFuncionarioData({
                    ...funcionarioData,
                    estadoCtps,
                    nrSeqEstadoCtps: estadoCtps.nrSeqEstado,
                  });
                }}
                dataSourceTextProperty='noEstado'
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </div>
  );
};

export default FuncionarioPanel;
