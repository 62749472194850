import qs from 'qs';
import Pagination from 'core/models/pagination';
import PocProdOrcamento from 'core/models/POC/pocProdOrcamento';
import api from '../api';

const baseURL = `/POC/PocProdOrcamento`;

export const getPocProdOrcamentoList = async (filters) => {
  const response = await api.get(`${baseURL}/PocProdOrcamento/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocProdOrcamento(json)) ?? [],
  };
};

export const getAutoCompleteDiameter = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteDiameter`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocProdOrcamento(json)) ?? [],
  };
};

export const getAutoCompleteProdGroup = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteGroup`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocProdOrcamento(json)) ?? [],
  };
};

export const getPocProdOrcamentoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocProdOrcamento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocProdOrcamento = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocProdOrcamento(value) : {};
};

export const savePocProdOrcamento = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePocProdOrcamento = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPocProdOrcamento = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocProdOrcamento = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocProdOrcamento = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
