export default class GruposLinha {
  constructor(jsonObject = {}) {
    this.id = jsonObject.id;
    this.nome = jsonObject.nome;
    this.cotaDadosNaoAtribuidas = jsonObject.cotaDadosNaoAtribuidas;
  }

  get nomeCotaDisponivel() {
    return `${this.nome} (${this.cotaDadosNaoAtribuidas} GB(s) disponível)`;
  }

  toJson = () => JSON.stringify(this);
}
