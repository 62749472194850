export default class RotaTransporte {
  constructor(jsonObject = {}) {
    this.nrSeqRotaTransporte = jsonObject.nrSeqRotaTransporte;
    this.nrSeqEstadoOri = jsonObject.nrSeqEstadoOri;
    this.nrSeqEstadoDest = jsonObject.nrSeqEstadoDest;

    // #region Propriedades Auxiliares
    this.noRotaCompleta = jsonObject.noRotaCompleta;
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
