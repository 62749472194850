export default class TransacaoPaginaPermissao {
  constructor(jsonObject = {}) {
    this.nrSeqTransacaoPaginaPermissao =
      jsonObject.nrSeqTransacaoPaginaPermissao;
    this.nrSeqTransacaoPagina = jsonObject.nrSeqTransacaoPagina;
    this.noIdFuncionalidade = jsonObject.noIdFuncionalidade;
    this.flgBloqueado = jsonObject.flgBloqueado;
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.nrSeqPerfil = jsonObject.nrSeqPerfil;
  }

  toJson = () => JSON.stringify(this);
}
