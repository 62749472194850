import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  ResponseStatus,
  Theme,
  JustifyContent,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  DropdownList,
  Autocomplete,
  Panel,
  GridView,
  Button,
  Textbox,
  DatePicker,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';

import ManifestoCTE from 'core/models/FRO/manifestoCTE';
import ModalImportaNota from 'ui/pages/FRO/ManifestoNfv/modalImportaNota';

import {
  getManifestoCTE,
  saveManifestoNfv,
} from 'core/services/FRO/manifestoCTE';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';
import { getRotaTransporteAutoCompleteManifestoNfv } from 'core/services/FRO/rotaTransporte';
import { getNfvAutoComplete } from 'core/services/VEN/Nfv';
import { getCidadeEstadoConcatAutoComplete } from 'core/services/SEG/cidade';

export default function ManifestoNfvItem({
  isActive,
  transaction,
  reload,
  registryKey,
}) {
  const formSubmit = useRef();
  const gridView = useRef();
  const refImportaNota = useRef();
  const [data, setData] = useState(new ManifestoCTE({}));
  const [nota, setNota] = useState({});
  const [filtroNota, setFiltroNota] = useState({});
  const [totais, setTotais] = useState({
    vlrTotal: 0,
    pesoNotas: 0,
    qtdeNotas: 0,
  });
  const [modalImportaNota, setModalImportaNota] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [dropDownEmpresa, setDropDownEmpresa] = useState([]);
  const [dropDownRotas, setDropDownRotas] = useState([]);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const getCompanies = async () => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({});

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      setDropDownEmpresa(empresas);
    }
  };

  const newData = async () => {
    const manifestoCTE = await getManifestoCTE(0);

    setData(manifestoCTE);

    setNota({});

    setTotais({
      vlrTotal: 0,
      pesoNotas: 0,
      qtdeNotas: 0,
    });

    await getCompanies();

    if (gridView && gridView.current) gridView.current.setDataSource([]);
  };

  const save = async () => {
    setLoading(true);

    const notas = gridView.current.getDataSource();

    const obj = new ManifestoCTE({
      ...data,
      notas,
    });

    const { status, message: msg } = await saveManifestoNfv(obj);

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      await newData();
    }

    setLoading(false);
  };

  const load = useCallback(async () => {
    if (!Number.isInteger(registryKey)) {
      setLoading(true);

      newData();

      setLoading(false);
    }
  }, [registryKey]);

  const onAutoCompleteRotasTransporte = async () => {
    setLoading(true);

    if (
      data.cidadeCarregamento?.nrSeqEstado &&
      data.cidadeDescarregamento?.nrSeqEstado
    ) {
      const {
        status,
        message: msg,
        data: rotasTransporte,
      } = await getRotaTransporteAutoCompleteManifestoNfv({
        nrSeqEstadoOri: data.cidadeCarregamento?.nrSeqEstado,
        nrSeqEstadoDest: data.cidadeDescarregamento?.nrSeqEstado,
      });

      if (msg) onSetMessage(status, msg);

      if (status === ResponseStatus.Success) {
        setDropDownRotas(rotasTransporte);
      }
    }

    setLoading(false);
  };

  const onAutoCompleteNfv = async (e) => {
    const { status, message: msg, data: nfvs } = await getNfvAutoComplete({
      nrNfv: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
      dtEmissaoInicial: filtroNota.dtEmissaoInicial,
      dtEmissaoFinal: filtroNota.dtEmissaoFinal,
    });

    if (msg) onSetMessage(status, msg);

    return nfvs;
  };

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [registryKey, reload]);

  useEffect(async () => {
    await onAutoCompleteRotasTransporte();
  }, [data.cidadeCarregamento, data.cidadeDescarregamento]);

  const onAutoCompleteVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
    });

    if (msg) onSetMessage(status, msg);

    return veiculos;
  };

  const onAutoCompleteMotorista = async (e) => {
    const { status, message: msg, motoristas } = await getMotoristaAutoComplete(
      {
        noPessoa: e,
      }
    );

    if (msg) onSetMessage(status, msg);

    return motoristas;
  };

  const onAutoCompleteCidade = async (e) => {
    const {
      status,
      message: msg,
      cidades,
    } = await getCidadeEstadoConcatAutoComplete({
      noCidade: e,
    });

    if (msg) onSetMessage(status, msg);

    return cidades;
  };

  const recalculaTotais = (datasource) => {
    let vlrTotal = 0;
    let pesoNotas = 0;
    let qtdeNotas = 0;

    datasource.forEach((item) => {
      vlrTotal += item.vlrNfv;
      pesoNotas += item.pesoBrutoFrete;

      qtdeNotas += 1;
    });

    setTotais({
      vlrTotal,
      pesoNotas,
      qtdeNotas,
    });
  };

  const onClickRemoveNota = (e, datasource) => {
    const dataSource = datasource.filter((item) => item !== e);

    if (gridView && gridView.current)
      gridView.current.setDataSource(dataSource);

    recalculaTotais(dataSource);
  };

  const columns = [
    { key: 'nrSeqNfv', title: 'nrSeqNfv', visible: false },
    { key: 'cliente.noPessoa', title: 'Cliente' },
    { key: 'dtEmissao', title: 'Dt. Emissão', format: GridView.DataTypes.Date },
    { key: 'nrNfv', title: 'Nr. Nota' },
    { key: 'serieNfv', title: 'Serie Nota' },
    { key: 'qtdeFrete', title: 'Quantidade' },
    { key: 'vlrNfv', title: 'Valor', format: GridView.DataTypes.Decimal },
    {
      key: 'nrSeqNfv',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveNota(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const validaNota = () => {
    if (!nota.nrSeqNfv) {
      onSetMessage(ResponseStatus.Error, 'Selecione uma nota.');

      return false;
    }

    return true;
  };

  const onClickAdicionarNota = () => {
    setLoading(true);

    const datasource = gridView.current.getDataSource();
    nota.status = nota.status ?? GridView.EnumStatus.Inserir;

    datasource.push(nota);

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);

    setData({
      ...data,
      cidadeDescarregamento: nota.cliente?.endereco?.cidade,
    });

    setNota({});

    setFiltroNota({});

    recalculaTotais(datasource);

    setLoading(false);
  };

  const importaNota = () => {
    setLoading(true);

    setModalImportaNota({
      ...modalImportaNota,
      show: true,
    });

    setLoading(false);
  };

  const onCloseModalImportaNf = () => {
    setModalImportaNota({
      ...modalImportaNota,
      show: false,
    });
  };

  return (
    <CSDManutPage
      transaction={transaction}
      isActive={isActive}
      title='Manutenção de Manifesto NFV'
      loading={loading}
      onNew={() => load()}
      onSave={save}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Importar Nota'
          icon='cloud-upload-alt'
          onClick={() => importaNota()}
        />
      </ToolbarButtons>
      <div className='row'>
        <div className='row mb-3'>
          <div className='col-5'>
            <DropdownList
              label='Empresa'
              required
              dataSource={dropDownEmpresa}
              dataSourcePropertyText='noPessoa'
              dataSourcePropertyValue='nrSeqEmpresa'
              selectedItems={data?.empresa ?? []}
              onSelectItem={(empresa) => {
                setData({
                  ...data,
                  empresa,
                  nrSeqEmpresa: empresa.nrSeqEmpresa,
                });
              }}
            />
          </div>
          <div className='col-2'>
            <Autocomplete
              label='Veículo'
              required
              searchDataSource={onAutoCompleteVeiculo}
              selectedItem={data.veiculo}
              onSelectItem={(veiculo) => {
                setData({
                  ...data,
                  veiculo,
                  nrSeqVeiculo: veiculo.nrSeqVeiculo,
                });
              }}
              dataSourceTextProperty='placa'
            />
          </div>
          <div className='col-5'>
            <Autocomplete
              label='Motorista'
              required
              searchDataSource={onAutoCompleteMotorista}
              selectedItem={data.motorista}
              onSelectItem={(motorista) => {
                setData({ ...data, motorista });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
        </div>
        <div className='row mb-4'>
          <div className='col-2'>
            <Autocomplete
              label='Cidade Carregamento'
              required
              searchDataSource={onAutoCompleteCidade}
              selectedItem={data.cidadeCarregamento}
              onSelectItem={(cidadeCarregamento) => {
                setData({
                  ...data,
                  cidadeCarregamento,
                });
              }}
              dataSourceTextProperty='noCidadeEstado'
            />
          </div>
          {data.cidadeDescarregamento?.nrSeqEstado && (
            <div className='col-3'>
              <DropdownList
                label='Rotas'
                required
                dataSource={dropDownRotas}
                dataSourcePropertyText='noRotaCompleta'
                dataSourcePropertyValue='nrSeqRotaTransporte'
                selectedItems={data?.rotaTransporte ?? []}
                onSelectItem={(rotaTransporte) => {
                  setData({ ...data, rotaTransporte });
                }}
              />
            </div>
          )}
        </div>
        <Panel>
          <Panel.Header
            title='Notas'
            icon={['fas', 'file']}
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row'>
              <div className='row mb-3'>
                <div className='col-3'>
                  <Autocomplete
                    label='Nr. Nota'
                    searchDataSource={onAutoCompleteNfv}
                    selectedItem={nota}
                    onSelectItem={(e) => {
                      setNota(e);
                    }}
                    dataSourceTextPropertyLabel='Número: '
                    dataSourceTextProperty='nrNfv'
                    dataSourceTextPropertyLabel2='Série: '
                    dataSourceTextProperty2='serieNfv'
                  />
                </div>
                <div className='col-2'>
                  <DatePicker
                    label='Data de Emissão Inicial'
                    text={filtroNota.dtEmissaoInicial}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dtEmissaoInicial) =>
                      setFiltroNota({ ...filtroNota, dtEmissaoInicial })
                    }
                  />
                </div>
                <div className='col-2'>
                  <DatePicker
                    label='Data de Emissão Final'
                    text={filtroNota.dtEmissaoFinal}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dtEmissaoFinal) =>
                      setFiltroNota({ ...filtroNota, dtEmissaoFinal })
                    }
                  />
                </div>
                <div className='col-2 mt-3'>
                  <Button
                    outline
                    text='Adicionar Nota'
                    icon='plus'
                    theme={Theme.Success}
                    size={BootstrapSizes.Medium}
                    onClick={() => {
                      if (validaNota()) {
                        onClickAdicionarNota();
                      }
                    }}
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <GridView
                    ref={gridView}
                    dataColumns={columns}
                    offlineData
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
              <div className='row mb-3 flex-row-reverse'>
                <div className='col-2'>
                  <Textbox
                    readOnly
                    label='Valor Total'
                    mask={MaskTypes.Decimal}
                    text={totais.vlrTotal}
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    readOnly
                    label='Peso Notas'
                    mask={MaskTypes.Decimal}
                    text={totais.pesoNotas}
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    readOnly
                    label='Quantidade Notas'
                    mask={MaskTypes.Integer}
                    text={totais.qtdeNotas}
                  />
                </div>
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalImportaNota
            show={modalImportaNota?.show}
            ref={refImportaNota}
            onClose={(datasource) => {
              if (datasource) onCloseModalImportaNf(datasource);
              else setModalImportaNota({ ...modalImportaNota, show: false });
            }}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
