import { Cliente, Cheque } from 'core/models/FIN';
import { Empresa } from 'core/models/SEG';
import { Cfo } from 'core/models/FIS';

export default class Pedido {
  constructor(jsonObject = {}) {
    this.nrSeqPedido = jsonObject.nrSeqPedido;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.nrPed = jsonObject.nrPed;
    this.dtPed = jsonObject.dtPed;
    this.dtCad = jsonObject.dtCad;
    this.dtVenda = jsonObject.dtVenda;
    this.dtCancel = jsonObject.dtCancel;
    this.dtEmis = jsonObject.dtEmis;
    this.percDesc = jsonObject.percDesc;
    this.vlrPed = jsonObject.vlrPed;
    this.vlrAdiant = jsonObject.vlrAdiant;
    this.obsPed = jsonObject.obsPed;
    this.vlrDesc = jsonObject.vlrDesc;
    this.vlrFrete = jsonObject.vlrFrete;
    this.flgOrcamento = jsonObject.flgOrcamento;
    this.nrSeqTipoFrete = jsonObject.nrSeqTipoFrete;
    this.nrSeqPedidoEtapa = jsonObject.nrSeqPedidoEtapa;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.nrSeqPessoaVen = jsonObject.nrSeqPessoaVen;
    this.nrSeqAlmoxarifado = jsonObject.nrSeqAlmoxarifado;
    this.nrSeqEndEntrega = jsonObject.nrSeqEndEntrega;
    this.vlrComissao = jsonObject.vlrComissao;
    this.nrSeqTipoVenda = jsonObject.nrSeqTipoVenda;
    this.nrSeqCondicaoPagamento = jsonObject.nrSeqCondicaoPagamento;
    this.dtPrevisto = jsonObject.dtPrevisto;
    this.perComissao = jsonObject.perComissao;
    this.dtEtiqueta = jsonObject.dtEtiqueta;
    this.nrSeqCfo = jsonObject.nrSeqCfo;
    this.obsNFV = jsonObject.obsNFV;
    this.dtSaida = jsonObject.dtSaida;
    this.nrSeqPessoaTra = jsonObject.nrSeqPessoaTra;
    this.flgConfirmaAssinatura = jsonObject.flgConfirmaAssinatura;
    this.nrSeqUsuarioConfirmacao = jsonObject.nrSeqUsuarioConfirmacao;
    this.flgCotacaoFrete = jsonObject.flgCotacaoFrete;
    this.nrPedRepresentante = jsonObject.nrPedRepresentante;
    this.flgPedidoCompleto = jsonObject.flgPedidoCompleto;
    this.flgPendenteDesconto = jsonObject.flgPendenteDesconto;
    this.nrSeqVendedorEquipe = jsonObject.nrSeqVendedorEquipe;
    this.motivoCancel = jsonObject.motivoCancel;
    this.dtConfirmacaoAssinatura = jsonObject.dtConfirmacaoAssinatura;
    this.noCliente = jsonObject.noCliente;
    this.enderecoCliente = jsonObject.enderecoCliente;
    this.obsCancelItems = jsonObject.obsCancelItems;
    this.flgEntregaFutura = jsonObject.flgEntregaFutura;
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.nrSeqPessoaTran = jsonObject.nrSeqPessoaTran;
    this.flgPedidoSimplificado = jsonObject.flgPedidoSimplificado;
    this.nrseqFormaPagamento = jsonObject.nrseqFormaPagamento;
    this.flgLicitacao = jsonObject.flgLicitacao;

    this.noEspecieFrete = jsonObject.noEspecieFrete;
    this.qtdeFrete = jsonObject.qtdeFrete;

    this.nrNfv = jsonObject.nrNfv;

    this.cliente =
      jsonObject.cliente !== undefined && jsonObject.cliente !== null
        ? new Cliente(jsonObject.cliente)
        : new Cliente();
    this.empresa =
      jsonObject.empresa !== undefined && jsonObject.empresa !== null
        ? new Empresa(jsonObject.empresa)
        : new Empresa();
    this.vendedor = jsonObject.vendedor;

    this.tipoFrete = jsonObject.tipoFrete;

    this.formaPagamento = jsonObject.formaPagamento;

    this.condicaoPagamento = jsonObject.condicaoPagamento;

    this.itens = jsonObject.itens;

    this.faturas = jsonObject.faturas;

    this.pedidoEtapa = jsonObject.pedidoEtapa;

    this.transportadora = jsonObject.transportadora;

    this.cfo =
      jsonObject.cfo !== undefined && jsonObject.cfo !== null
        ? new Cfo(jsonObject.cfo)
        : new Cfo();

    this.cheque =
      jsonObject.cheque !== undefined && jsonObject.cheque !== null
        ? new Cheque(jsonObject.cheque)
        : new Cheque();

    this.tipoVenda = jsonObject.tipoVenda;
  }

  toJson = () => JSON.stringify(this);
}
