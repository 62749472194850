export default class ContratoServicoFatura {
  constructor(jsonObject = {}) {
    this.dtVencimento = jsonObject.dtVencimento;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.vlrParcelaFaturamento = jsonObject.vlrParcelaFaturamento;
    this.nrSeqContratoServico = jsonObject.nrSeqContratoServico;
    this.nrSeqContratoAgrupado = jsonObject.nrSeqContratoAgrupado;
    this.vlrRecalculo = jsonObject.vlrRecalculo;
    this.noCompetencia = jsonObject.noCompetencia;
  }
}
