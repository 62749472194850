export default class PneuRodagem {
  constructor(jsonObject = {}) {
    this.nrSeqPneuRodagem = jsonObject.nrSeqPneuRodagem;
    this.nrOdometro = jsonObject.nrOdometro;
    this.nrSeqPneuVeiculo = jsonObject.nrSeqPneuVeiculo;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.nrSeqAcertoMotorista = jsonObject.nrSeqAcertoMotorista;
    this.nrSeqPneu = jsonObject.nrSeqPneu;
    this.nrSeqAbastecimento = jsonObject.nrSeqAbastecimento;
    this.nrOdometroAcumulado = jsonObject.nrOdometroAcumulado;
    this.dtRodagem = jsonObject.dtRodagem;
    this.nrSulco = jsonObject.nrSulco;
    this.flgAtivo = jsonObject.flgAtivo;
    this.kmRodado = jsonObject.kmRodado;
  }

  toJson = () => JSON.stringify(this);
}
