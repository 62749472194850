import React, { useEffect, useRef, useState } from 'react';
import { Theme, ResponseStatus, PageTypes } from 'ui/Helpers/utils';
import {
  getAdjudicacaoIcmsPagined,
  adjudicate,
  printAdjudicacaoIcms,
  excelAdjudicacaoIcms,
} from 'core/services/VEN/adjudicacaoIcms';

import { getEmpresasUsuario } from 'core/services/SEG/empresa';

import {
  CSDSelPage,
  GridView,
  ToolbarButtons,
  Autocomplete,
} from 'ui/components';

const columns = [
  { key: 'nrSeqProduto', type: GridView.ColumnTypes.Checkbox },
  { key: 'noProduto', title: 'Produto' },
  {
    key: 'vlrIcms',
    title: 'Valor ICMS em Vendas',
    format: GridView.DataTypes.Decimal,
  },
  {
    key: 'vlrIcmsCst',
    title: 'Valor ICMS em Compras',
    format: GridView.DataTypes.Decimal,
  },
];

export default function AdjudicacaoIcms({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    const {
      data,
      pagination,
      status,
      message: msg,
    } = await getAdjudicacaoIcmsPagined(params ?? filtros);

    onSetMessage(status, msg);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getAdjudicacaoIcmsPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onadjucate = async () => {
    setLoading(true);

    const selecteds = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);
    filtros.nrSeqProdutos = selecteds;

    const { status, message: msg } = await adjudicate(filtros);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'AdjudicacaoIcms/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return empresas;
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqAdjudicacaoIcms = sourceRow.find(
      (e) => e.key === 'nrSeqAdjudicacaoIcms'
    );
    onOpenMaintenance(nrSeqAdjudicacaoIcms.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printAdjudicacaoIcms(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDownloadExcel = async () => {
    setLoading(true);

    const { value: base64 } = await excelAdjudicacaoIcms(filtros);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'AdjudicacaoIcms.csv';
      downloadLink.click();
    }

    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de AdjudicacaoIcms'
      loading={loading}
      onSearch={() => search()}
      onPrint={onPrint}
      onDownloadExcel={onDownloadExcel}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Adjudicar'
          icon='sync-alt'
          tooltip='Efetua a adjudicação do icms dos produtos listados na pesquisa'
          onClick={onadjucate}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col-6'>
          <Autocomplete
            label='Empresa'
            searchDataSource={onSearchEmpresa}
            selectedItem={filtros.empresa}
            onSelectItem={(empresa) =>
              setFiltros({
                ...filtros,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
