import qs from 'qs';
import Pagination from 'core/models/pagination';
import CamposCorrecaoCte from 'core/models/FRO/camposCorrecaoCte';
import api from '../api';

const baseURL = '/FRO/CamposCorrecaoCte';

export const getCamposCorrecaoCteAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new CamposCorrecaoCte(json) ?? []),
  };
};

export const getCamposCorrecaoCtePagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });

  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new CamposCorrecaoCte(json) ?? []),
    pagination: new Pagination(pagination),
  };
};

export const getCamposCorrecaoCte = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new CamposCorrecaoCte(value) : {};
};

export const saveCamposCorrecaoCte = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteCamposCorrecaoCte = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printCamposCorrecaoCte = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelCamposCorrecaoCte = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllCamposCorrecaoCte = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
