import {
  getTipoDescontoAcerto,
  getValorBonus,
} from 'core/services/FIN/acertoMotorista';
import React, { useRef, forwardRef, useState, useEffect } from 'react';
import { MaskTypes, mask } from 'ui/Helpers/masks';
import { GridView, Notification, Textbox } from 'ui/components';

const Bonus = forwardRef(({ bonus, setBonus, data }) => {
  const gridView = useRef();
  const [message, onSetMessage] = useState();
  const [modalBonus, setModalBonus] = useState({});

  const calculate = (bonuS) => {
    let vlrpercentual = 0;

    if (modalBonus.vlrBonus === null || modalBonus.vlrBonus === undefined) {
      modalBonus.vlrBonus = 0;
    }
    if (modalBonus.vlrBonus < 0) {
      modalBonus.vlrBonus = 0;
    }

    if (modalBonus.vlrBonus > 0) {
      if (bonuS !== undefined) {
        for (let i = 0; i < bonuS.length; ) {
          if (bonuS[i].flgAdicionado === true) {
            vlrpercentual += bonuS[i].tipoDesconto.vlrPercDesconto;
          }
          i += 1;
        }
        if (vlrpercentual > 100) {
          vlrpercentual = 100;
        }
      }

      const vlrdescontado = (modalBonus.vlrBonus / 100) * vlrpercentual;

      modalBonus.totalDescontado = vlrdescontado;

      const receber = modalBonus.vlrBonus - vlrdescontado;

      setModalBonus({
        ...modalBonus,
        totalDescontado: vlrdescontado,
        vlrBonusRecebido: receber,
      });

      setBonus({
        ...bonus,
        totalDescontado: vlrdescontado,
        vlrBonusRecebido: receber,
        vlrBonus: modalBonus.vlrBonus,
      });
    } else {
      setModalBonus({
        ...modalBonus,
        totalDescontado: 0,
        vlrBonusRecebido: 0,
      });

      setBonus({
        ...bonus,
        totalDescontado: 0,
        vlrBonusRecebido: 0,
        vlrBonus: 0,
      });
    }
  };

  const load = async () => {
    if (data.nrSeqAcertoMotorista > 0) {
      modalBonus.vlrBonus = bonus.vlrBonus;

      setModalBonus({ vlrBonus: bonus.vlrBonus });
    } else {
      const vlrBonusBusca = await getValorBonus({});

      modalBonus.vlrBonus = vlrBonusBusca;

      setModalBonus({ ...modalBonus, vlrBonus: vlrBonusBusca });
      setBonus({ ...bonus, vlrBonus: vlrBonusBusca });
    }

    if (bonus.bonus === undefined || bonus.bonus === null) {
      const acertoMotorista = await getTipoDescontoAcerto({});
      if (gridView && gridView.current)
        gridView.current.setDataSource(acertoMotorista.bonus);
    } else {
      if (gridView && gridView.current)
        gridView.current.setDataSource(bonus.bonus);

      calculate(bonus.bonus);
    }
  };

  const savePrice = async (keyValue, value) => {
    const datasource = gridView.current ? gridView.current.getDataSource() : [];

    const itemParcela = datasource.find(
      (item) => item.tipoDesconto.nrSeqTipoDesconto === keyValue
    );
    itemParcela.noObs = value;

    for (let i = 0; i < datasource.length; ) {
      if (
        datasource[i].tipoDesconto.nrSeqTipoDesconto ===
        itemParcela.tipoDesconto.nrSeqTipoDesconto
      ) {
        datasource[i].noObs = itemParcela.noObs;
      }
      i += 1;
    }

    gridView?.current?.setDataSource(datasource);
  };

  const fPrice = function (keyValue, value) {
    savePrice(keyValue, value);
  };

  const saveItem = async ({ flgAdicionado, row }) => {
    let vlrpercentual = 0;

    const datasource = gridView?.current?.getDataSource();
    datasource[row].flgAdicionado = flgAdicionado;

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].flgAdicionado === true) {
        vlrpercentual += datasource[i].tipoDesconto.vlrPercDesconto;
      }
      i += 1;
    }
    if (vlrpercentual > 100) {
      vlrpercentual = 100;
    }

    const vlrdescontado = (modalBonus.vlrBonus / 100) * vlrpercentual;

    modalBonus.totalDescontado = vlrdescontado;

    const receber = modalBonus.vlrBonus - vlrdescontado;

    setModalBonus({
      ...modalBonus,
      totalDescontado: vlrdescontado,
      vlrBonusRecebido: receber,
    });

    gridView?.current?.setDataSource(datasource);

    setBonus({
      ...bonus,
      bonus: datasource,
      totalDescontado: vlrdescontado,
      vlrBonusRecebido: receber,
      vlrBonus: modalBonus.vlrBonus,
    });
  };

  const onRemoveItens = function onRemoveItens(row, column, checked, key) {
    saveItem({
      flgAdicionado: checked,
      nrSeqTipoDesconto: key,
      column,
      row,
    });
  };

  const columns = [
    { key: 'tipoDesconto.nrSeqTipoDesconto', visible: false },
    {
      key: 'flgAdicionado',
      disabledTabIndex: true,
      type: GridView.ColumnTypes.Checkbox,
      onColumnClick: onRemoveItens,
      checkField: 'flgAdicionado',
    },

    {
      key: 'tipoDesconto.noTipoDesconto',
      title: 'Item',
    },
    { key: 'tipoDesconto.vlrPercDesconto', title: 'Valor' },
    {
      key: 'noObs',
      title: 'Observação',
      type: GridView.ColumnTypes.Textbox,
      onBlur: fPrice,
    },
  ];

  useEffect(() => {
    (async function func() {
      calculate(bonus.bonus);

      // ValidaValorManual();
    })();
  }, [modalBonus.vlrBonus]);

  useEffect(() => {
    (async function func() {
      load();
    })();
  }, [data.nrSeqViagem]);

  function RenderTextMask(props) {
    const { label, value, masktype } = props;
    return (
      <div className='col-auto mt-3'>
        <div className='fw-bold'>
          {label}: {mask(value ?? 0, masktype ?? MaskTypes.Currency)}
        </div>
      </div>
    );
  }

  return (
    <div>
      {message && (
        <div className='mb-3'>
          <Notification
            message={message.message}
            theme={message.theme}
            onClose={() => onSetMessage(null)}
          />
        </div>
      )}
      <div className='row mb-3'>
        <div className='display-8'>10 - Bônus</div>
      </div>
      <div className='card'>
        <div className='card-body'>
          <div className='row mb-3'>
            <div className='row'>
              <div className='row mb-3'>
                <div className='col-12'>
                  <GridView
                    ref={gridView}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='alert alert-info fs-8' role='alert'>
                      <div className='row'>
                        <div className='col-4 mb-3 fw-bold'>
                          <Textbox
                            label='Valor Bonus'
                            required
                            textRight
                            text={modalBonus.vlrBonus}
                            mask={MaskTypes.DecimalCustom}
                            onChangedValue={(vlrBonus) => {
                              setModalBonus({ ...modalBonus, vlrBonus });
                            }}
                          />
                        </div>
                        <div className='col-4 mb-3 float-right'>
                          <RenderTextMask
                            label='Total Descontado'
                            value={modalBonus?.totalDescontado}
                          />
                        </div>
                        <div className='col-4 mb-3 float-right'>
                          <RenderTextMask
                            label='Valor a receber'
                            value={modalBonus?.vlrBonusRecebido}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default Bonus;
