import TipoOcorrenciaColetaEmbarque from 'core/models/FRO/tipoOcorrenciaColetaEmbarque';
import Usuario from 'core/models/SEG/usuario';

export default class ColetaEmbarqueOcorrencia {
  constructor(jsonObject = {}) {
    this.nrSeqColetaEmbarqueOcorrencia =
      jsonObject.nrSeqColetaEmbarqueOcorrencia;
    this.nrSeqColetaEmbarque = jsonObject.nrSeqColetaEmbarque;
    this.nrSeqTipoOcorrenciaColetaEmbarque =
      jsonObject.nrSeqTipoOcorrenciaColetaEmbarque;
    this.dtOcorrencia = jsonObject.dtOcorrencia;
    this.tipoOcorrencia = new TipoOcorrenciaColetaEmbarque(
      jsonObject.tipoOcorrencia ?? {}
    );
    this.usuario = new Usuario(jsonObject.usuario ?? {});
  }

  toJson = () => JSON.stringify(this);
}
