export default class DocumentoConfiguracao {
  constructor(jsonObject = {}) {
    this.nrSeqDocumentoConfiguracao = jsonObject.nrSeqDocumentoConfiguracao;
    this.cdDocumentoConfiguracao = jsonObject.cdDocumentoConfiguracao;
    this.nrOrdem = jsonObject.nrOrdem;
    this.nrColuna = jsonObject.nrColuna;
    this.noBackgroundColor = jsonObject.noBackgroundColor;
    this.noHorizontalAlignment = jsonObject.noHorizontalAlignment;
    this.nrTamanhoBorda = jsonObject.nrTamanhoBorda;
    this.nrMarginLeft = jsonObject.nrMarginLeft;
    this.noPropriedadeLista = jsonObject.noPropriedadeLista;
    this.nrParagraphSpacing = jsonObject.nrParagraphSpacing;
    this.nrWidth = jsonObject.nrWidth;
  }

  toJson = () => JSON.stringify(this);
}
