import React, {
  forwardRef,
  ForwardedRef,
  useState,
  useImperativeHandle,
} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import { convertThemeToRgb } from 'ui/Helpers/utils';

ChartJS.register(ArcElement, Tooltip, Legend);

interface IDataSource {
  labels: Array<string>;
  datasets: Array<any>;
}

interface Props {
  options: any;
  onClick?: (object: any) => void;
  borderWidth?: number;
  border?: boolean;
}

const PieGraphic = forwardRef(
  (
    { options, borderWidth = 2, border = false, onClick }: Props,
    ref: ForwardedRef<any>
  ) => {
    const [dataSource, setDataSource] = useState<IDataSource>({
      labels: [],
      datasets: [],
    });
    const [optionsSource, setOptionsSource] = useState(options);
    let dataSourceAtRunTime: IDataSource;

    const handleOnClick = (event: any, elements: any) => {
      if (!onClick) return;

      if (elements.length > 0) {
        const { datasetIndex: dataSetIndex, index } = elements[0];

        const object = {
          dataset: dataSourceAtRunTime.datasets[dataSetIndex].label,
          label: dataSourceAtRunTime.labels[index],
          value: dataSourceAtRunTime.datasets[dataSetIndex].data[index],
        };

        onClick(object);
      }
    };

    const updateDataSource = (newDataSource: IDataSource) => {
      newDataSource.datasets.forEach((dataSet: any) => {
        dataSet.themes.forEach((theme: any) => {
          if (!dataSet.backgroundColor) dataSet.backgroundColor = [];

          if (theme.indexOf('rgb') === -1) {
            const { arrayRgb, borderRgb } = convertThemeToRgb(theme);

            dataSet.backgroundColor.push(
              `rgba(${arrayRgb[0]}, ${arrayRgb[1]}, ${arrayRgb[2]})`
            );

            if (border) {
              if (!dataSet.borderColor) dataSet.borderColor = [];

              dataSet.borderColor.push(
                `rgba(${borderRgb[0]}, ${borderRgb[1]}, ${borderRgb[2]})`
              );
              dataSet.borderWidth = borderWidth;
            }
          } else {
            dataSet.backgroundColor.push(theme);
          }
        });

        delete dataSet.themes;
      });

      setDataSource(newDataSource);
      dataSourceAtRunTime = newDataSource;
    };

    useImperativeHandle(ref, () => ({
      setDataSource(newDataSource: IDataSource) {
        updateDataSource(newDataSource);

        if (onClick) {
          setOptionsSource({
            ...optionsSource,
            onClick: (event: any, elements: any) =>
              handleOnClick(event, elements),
          });
        }
      },

      getDataSource() {
        return dataSource;
      },
    }));

    return (
      <>
        {dataSource.datasets.length > 0 && (
          <Pie data={dataSource} options={optionsSource} />
        )}
      </>
    );
  }
);

export default PieGraphic;
