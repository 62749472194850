import React, { useRef, useState } from 'react';
import { setTransaction } from 'core/services/api';

import {
  Autocomplete,
  Button,
  GridView,
  Loader,
  Modal,
  Notification,
  Textbox,
} from 'ui/components';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';
import { getTipoDespesaAutoComplete } from 'core/services/FIN';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  gerarLancamento,
  SalvarModalGeraTitulos,
} from 'core/services/FIN/tituloPagarCustoFixo';

export default function ModalGerarLancamentos(props) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [messageState, setMessage] = useState(null);
  const gridView = useRef();

  const { show, transaction } = props;

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchTipoDespesa = async (e) => {
    const {
      status,
      message: msg,
      tipoDespesas,
    } = await getTipoDespesaAutoComplete({ noTipoDespesa: e });
    if (msg) onSetMessage(status, msg);
    return tipoDespesas;
  };

  const salvar = async () => {
    setLoading(true);
    let obj = [];

    const lista = gridView.current ? gridView.current.getDataSource() : [];
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);
    if (lista.length > 0) {
      obj = lista;
    }
    const { status, message: msg } = await SalvarModalGeraTitulos(obj);

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
    if (status === ResponseStatus.Success) {
      props.onClose({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
  };

  const cancelar = async () => {
    props.onClose({
      message: null,
      theme: null,
    });
  };

  const savePrice = async (keyValue, value) => {
    const datasource = gridView.current ? gridView.current.getDataSource() : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqCustoFixo === keyValue
    );
    itemParcela.tituloPagar.vlrTitulo = value;

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].nrSeqCustoFixo === itemParcela.nrSeqCustoFixo) {
        datasource[i].tituloPagar.vlrTitulo = itemParcela.tituloPagar.vlrTitulo;
      }
      i += 1;
    }

    // gridView.current.setDataSource(datasource);
  };

  const fPrice = function (keyValue, value) {
    savePrice(keyValue, value);
  };

  const columns = [
    {
      key: 'nrSeqCustoFixo',
      visible: false,
    },
    {
      key: 'tituloPagar.fornecedor.noFornecedor',
      title: 'Fornecedor',
    },
    {
      key: 'tituloPagar.nrTit',
      title: 'Nr Tit',
    },
    {
      key: 'tituloPagar.nrOrdem',
      title: 'Nr Ordem',
    },
    {
      key: 'tituloPagar.vlrTitulo',
      title: 'Valor Do Titulo',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      onBlur: fPrice,
      maxLength: 10,
    },
    {
      key: 'tituloPagar.dtVencimento',
      title: 'Data Vencimento',
      format: GridView.DataTypes.Date,
    },

    {
      key: 'noObservacoes',
      title: 'Observações',
    },
  ];

  const onAdicionarLançamentos = async () => {
    setLoading(true);
    const obj = await gerarLancamento(data);

    for (let i = 0; i < obj.data.length; ) {
      obj.data[i].nrOrdem = 1;
      i += 1;
    }

    if (gridView && gridView.current) gridView.current.setDataSource(obj.data);
    setLoading(false);
  };
  const Recalcula = async (tipoDespesa) => {
    if (tipoDespesa.nrSeqTipoDespesa === undefined) {
      if (gridView && gridView.current) gridView.current.setDataSource(null);
    }
  };

  return (
    <Modal
      loading={loading}
      show={show}
      title='Geração de Titulos Custo Fixo'
      size={BootstrapSizes.Large}
      icon='money'
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='col-3 mb-3'>
            <Textbox
              label='Mês/Ano'
              text={data?.anoMes}
              mask={MaskTypes.Competence}
              onChangedValue={(anoMes) => setData({ ...data, anoMes })}
            />
          </div>
          <div className='col-4 mb-3'>
            <Autocomplete
              label='Tipo Despesa'
              searchDataSource={onSearchTipoDespesa}
              required
              selectedItem={data?.tipoDespesa}
              onSelectItem={(tipoDespesa) => {
                setData({
                  ...data,
                  tipoDespesa,
                  nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
                });
                Recalcula(tipoDespesa);
              }}
              dataSourceTextProperty='noTipoDespesa'
            />
          </div>
          <div className='col-4 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Mostrar'
              onClick={onAdicionarLançamentos}
            />
          </div>
        </div>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>

        <div className='row'>
          <div className='col-12 mt-3 text-end'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Gerar'
              onClick={salvar}
            />
            <Button
              style={{ marginLeft: '5px' }}
              theme={Theme.Danger}
              template={Button.Templates.Button}
              text='Cancelar'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
