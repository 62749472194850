import React, { useRef, useState } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  getInventarioPaginated,
  deleteAllInventario,
} from 'core/services/EST/inventario';
import {
  Autocomplete,
  CSDSelPage,
  DatePicker,
  GridView,
  Panel,
  RadioButton,
  Textbox,
} from 'ui/components';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import { MaskTypes } from 'ui/Helpers/masks';

const columns = [
  { key: 'nrSeqInventario', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdInventario', title: 'Código' },
  { key: 'noInventario', title: 'Inventário' },
  { key: 'dtInicio', title: 'Data Início', format: GridView.DataTypes.Date },
  { key: 'dtFim', title: 'Data Fim', format: GridView.DataTypes.Date },
];

export default function Inventario({ transaction, onOpenPage, isActive }) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({ flgPendente: false });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const validateDataInicioFim = () => {
    const dataInicialPieces = filtros.dtInicial.split('/');

    const dataInicial = new Date(
      parseInt(dataInicialPieces[2], 10),
      parseInt(dataInicialPieces[1], 10) - 1,
      parseInt(dataInicialPieces[0], 10)
    );

    const dataFinalPieces = filtros.dtFinal.split('/');

    const dataFinal = new Date(
      parseInt(dataFinalPieces[2], 10),
      parseInt(dataFinalPieces[1], 10) - 1,
      parseInt(dataFinalPieces[0], 10)
    );

    return dataFinal >= dataInicial;
  };

  const search = async (params = null) => {
    setLoading(true);

    let flgPendente = null;

    if (filtros.status === 'E') {
      flgPendente = false;
    } else if (filtros.status === 'P') {
      flgPendente = true;
    }

    if (filtros.dtInicial || filtros.dtFinal) {
      if (filtros.dtInicial && filtros.dtFinal) {
        if (validateDataInicioFim) {
          const { data, pagination } = await getInventarioPaginated(
            params ?? { ...filtros, flgPendente }
          );

          if (gridView && gridView.current)
            await gridView.current.setDataSource(data, pagination);
        } else {
          setMessage({
            message: 'A data inicial não pode ser maior que a final',
            theme: Theme.Danger,
          });
        }
      } else {
        setMessage({
          message: 'O inicio e fim do periodo devem ser preenchidos',
          theme: Theme.Danger,
        });
      }
    } else {
      const { data, pagination } = await getInventarioPaginated(
        params ?? { ...filtros, flgPendente }
      );

      if (gridView && gridView.current)
        await gridView.current.setDataSource(data, pagination);
    }

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Inventario/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqInventario = sourceRow.find((e) => e.key === 'nrSeqInventario');
    onOpenMaintenance(nrSeqInventario.value);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllInventario(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchEmpresa = async (e) => {
    const { empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });

    return empresas;
  };

  const statusButtons = [
    { text: 'Todos', value: 'T' },
    { text: 'Efetivados', value: 'E' },
    { text: 'Pendentes', value: 'P' },
  ];

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Inventário'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Filtros' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3 align-items-end'>
              <div className='col'>
                <Textbox
                  label='Nome'
                  text={filtros.noInventario}
                  maxLength={60}
                  onChangedValue={(noInventario) =>
                    setFiltros({ ...filtros, noInventario })
                  }
                />
              </div>
              <div className='col'>
                <Autocomplete
                  label='Empresa'
                  searchDataSource={onSearchEmpresa}
                  selectedItem={filtros.empresa}
                  onSelectItem={(empresa) =>
                    setFiltros({
                      ...filtros,
                      empresa,
                      nrSeqEmpresa: empresa.nrSeqEmpresa,
                    })
                  }
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Data Inicial'
                  text={filtros.dtInicial}
                  mask={MaskTypes.Date}
                  onChange={(dtInicial) =>
                    setFiltros({ ...filtros, dtInicial })
                  }
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Data Final'
                  text={filtros.dtFinal}
                  mask={MaskTypes.Date}
                  onChange={(dtFinal) => setFiltros({ ...filtros, dtFinal })}
                />
              </div>
              <div className='col'>
                <RadioButton
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.status ?? 'T'}
                  onChange={(status) => {
                    setFiltros({ ...filtros, status });
                  }}
                  buttons={statusButtons}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
