import React, { useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getFornecedorAtividadePagined,
  deleteAllFornecedorAtividade,
  getFornecedorAtividadeAutoComplete,
} from 'core/services/FIN/fornecedorAtividade';
import { CSDSelPage, GridView, Autocomplete, Textbox } from 'ui/components';

const columns = [
  { key: 'nrSeqFornecedorAtividade', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdFornecedorAtividade', title: 'Codigo' },
  { key: 'noFornecedorAtividade', title: 'Atividade' },
];

export default function FornecedorAtividade({
  transaction,
  onOpenPage,
  isActive,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getFornecedorAtividadePagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setFiltros({});
    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'FornecedorAtividade/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqFornecedorAtividade = sourceRow.find(
      (e) => e.key === 'nrSeqFornecedorAtividade'
    );
    onOpenMaintenance(nrSeqFornecedorAtividade.value);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllFornecedorAtividade(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  const onSearchAtividadeFornecedor = async (e) => {
    const {
      status,
      message: msg,
      fornecedorAtividades,
    } = await getFornecedorAtividadeAutoComplete({
      noFornecedorAtividade: e,
    });
    if (msg) onSetMessage(status, msg);
    return fornecedorAtividades;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de FornecedorAtividade'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-3 mb-2'>
          <Autocomplete
            label='Atividade Fornecedor'
            searchDataSource={onSearchAtividadeFornecedor}
            selectedItem={filtros.fornecedorAtividade}
            onSelectItem={(fornecedorAtividade) => {
              setFiltros({
                ...filtros,
                nrSeqFornecedorAtividade:
                  fornecedorAtividade.nrSeqFornecedorAtividade,
                noFornecedorAtividade:
                  fornecedorAtividade.noFornecedorAtividade,
              });
            }}
            dataSourceTextProperty='noFornecedorAtividade'
          />
        </div>
        <div className='col-2 mb-3'>
          <Textbox
            label='Codigo'
            text={filtros.cdFornecedorAtividade}
            onChangedValue={(cdFornecedorAtividade) =>
              setFiltros({
                ...filtros,
                cdFornecedorAtividade,
              })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
