class ParcelamentoAparelho {
  constructor(jsonObject = {}) {
    this.nrSeqParcelamentoAparelho = jsonObject.nrSeqParcelamentoAparelho;
    this.nrSeqClienteLinha = jsonObject.nrSeqClienteLinha;
    this.noParcelamentoAparelho = jsonObject.noParcelamentoAparelho;
    this.nrParcelas = jsonObject.nrParcelas;
    this.vlrOriginal = jsonObject.vlrOriginal;
    this.vlrParcelado = jsonObject.vlrParcelado;
    this.dtIniVigencia = jsonObject.dtIniVigencia;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqTipoChamada = jsonObject.nrSeqTipoChamada;

    this.flgTipoParcelamento = jsonObject.flgTipoParcelamento;
    this.vlrRateio = jsonObject.vlrRateio;
    this.nrSeqAparelho = jsonObject.nrSeqAparelho;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;

    this.vlrPago = jsonObject.vlrPago;
    this.vlrParcela = jsonObject.vlrParcela;
    this.vlrRestante = jsonObject.vlrRestante;
    this.nrParcelasClienteLinha = jsonObject.nrParcelasClienteLinha;
  }

  toJson = () => JSON.stringify(this);
}
export default ParcelamentoAparelho;
