import qs from 'qs';
import Pagination from 'core/models/pagination';
import EmpresaOrigemCentro from 'core/models/SEG/empresaOrigemCentro';
import api from '../api';

const baseURL = `/SEG/EmpresaOrigemCentro`;

export const getEmpresaOrigemCentroList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new EmpresaOrigemCentro(json)) ?? [],
  };
};
export const getEmpresaOrigemCentroAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    empresas: value?.map((json) => new EmpresaOrigemCentro(json)) ?? [],
  };
};

export const getEmpresaOrigemCentroPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new EmpresaOrigemCentro(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getEmpresaOrigemCentro = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new EmpresaOrigemCentro(value) : {};
};

export const saveEmpresaOrigemCentro = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteEmpresaOrigemCentro = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printEmpresaOrigemCentro = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelEmpresaOrigemCentro = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllEmpresaOrigemCentro = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
