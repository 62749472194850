import React from 'react';

import { FontAwesomeIcon } from 'ui/components';

import style from './index.module.css';

export default function VehicleProperties({ data = {} }) {
  const { nrOdometro, dtUltimoAbastecimento, dtUltimaManutencao } = data;

  const nrOdometroFormatted = () => {
    if (nrOdometro) {
      return ` ${new Intl.NumberFormat('id').format(nrOdometro)} km`;
    }
    return '';
  };

  const ultimoAbastecimentoFormatted = () => {
    if (dtUltimoAbastecimento) {
      return new Intl.DateTimeFormat('pt-BR').format(
        new Date(dtUltimoAbastecimento)
      );
    }
    return '';
  };

  const ultimaManutencaoFormatted = () => {
    if (dtUltimaManutencao) {
      return new Intl.DateTimeFormat('pt-BR').format(
        new Date(dtUltimaManutencao)
      );
    }
    return '';
  };

  return (
    <div className={style.wrapper}>
      <div className={style.information}>
        <div>
          <FontAwesomeIcon icon='truck' size='3x' />
        </div>
        <div className={style.label}>
          <span>Última Quilometragem</span>
        </div>
        <div>
          <span className={style.value}>{nrOdometroFormatted()}</span>
        </div>
      </div>
      <div className={style.information}>
        <div>
          <FontAwesomeIcon icon='tasks' size='3x' />
        </div>
        <div className={style.label}>
          <span>Último Abastecimento</span>
        </div>
        <div>
          <span className={style.value}>{ultimoAbastecimentoFormatted()}</span>
        </div>
      </div>

      <div className={style.information}>
        <div>
          <FontAwesomeIcon icon='briefcase' size='3x' />
        </div>
        <div className={style.label}>
          <span>Última Manutenção</span>
        </div>
        <div>
          <span className={style.value}>{ultimaManutencaoFormatted()}</span>
        </div>
      </div>
    </div>
  );
}
