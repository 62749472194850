import React from 'react';
import { Button, Modal } from 'ui/components';
import { Theme } from 'ui/Helpers/utils';

const Confirm = ({
  titleIcon,
  title,
  text,
  show,
  confirmText = 'Confirmar',
  cancelText = 'Cancelar',
  handleConfirm,
  handleCancel,
}) => (
  <Modal show={show} icon={titleIcon} title={title}>
    <Modal.Body>{text && <span>{text}</span>}</Modal.Body>

    <Modal.Footer>
      <Button
        text={confirmText}
        onClick={handleConfirm}
        theme={Theme.Success}
      />
      <Button text={cancelText} onClick={handleCancel} theme={Theme.Light} />
    </Modal.Footer>
  </Modal>
);

export default Confirm;
