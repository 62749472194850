export default class PocPocoRevestimento {
  constructor(jsonObject = {}) {
    this.nrSeqPocPocoRevestimento = jsonObject.nrSeqPocPocoRevestimento;
    this.nrSeqPocPoco = jsonObject.nrSeqPocPoco;
    this.nrInicio = jsonObject.nrInicio;
    this.nrFim = jsonObject.nrFim;
    this.flgTipoObjeto = jsonObject.flgTipoObjeto;
    this.nrDiametropol = jsonObject.nrDiametropol;
    this.nrDiametromm = jsonObject.nrDiametromm;
    this.nrSeqPocTipoFiltro = jsonObject.nrSeqPocTipoFiltro;
    this.noPocTipoFiltro = jsonObject.noPocTipoFiltro;
    this.cdPocTipoFiltro = jsonObject.cdPocTipoFiltro;
    this.nrSlot = jsonObject.nrSlot;
    this.nrSeqPocMaterialRevestimento = jsonObject.nrSeqPocMaterialRevestimento;
    this.noPocMaterialRevestimento = jsonObject.noPocMaterialRevestimento;
    this.cdPocMaterialRevestimento = jsonObject.cdPocMaterialRevestimento;
    this.total = jsonObject.total;

    this.status = jsonObject.status;
  }

  get getTipoMaterial() {
    return this.flgTipoObjeto === 'R' ? 'Revestimento' : 'Filtro';
  }

  get getMaterial() {
    if (this.flgTipoObjeto === 'R')
      return `${this.cdPocMaterialRevestimento} - ${this.noPocMaterialRevestimento}`;
    return `${this.cdPocTipoFiltro} - ${this.noPocTipoFiltro}`;
  }

  toJson = () => JSON.stringify(this);
}
