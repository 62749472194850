import Operadora from './operadora';
import Empresa from '../SEG/empresa';
import ChipVinculosOperadora from './chipVinculosOperadora';

class Chip {
  constructor(jsonObject = {}) {
    this.nrSeqChip = jsonObject.nrSeqChip;
    this.cdChip = jsonObject.cdChip;
    this.nrPrefixo = jsonObject.nrPrefixo;
    this.nrLinha = jsonObject.nrLinha;
    this.nrIccid = jsonObject.nrIccid;
    this.nrSeqOperadora = jsonObject.nrSeqOperadora;
    this.nrNotaFiscal = jsonObject.nrNotaFiscal;
    this.dtNotaFiscal = jsonObject.dtNotaFiscal;
    this.dtNotaFiscalStr = jsonObject.dtNotaFiscalStr;
    this.vlrPrecoCusto = jsonObject.vlrPrecoCusto;
    this.flgStatus = jsonObject.flgStatus;
    this.flgStatusStr = jsonObject.flgStatusStr;
    this.flgSituacao = jsonObject.flgSituacao;
    this.flgSituacaoStr = jsonObject.flgSituacaoStr;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.noTipo = jsonObject.noTipo;
    this.noIdIntegracao = jsonObject.noIdIntegracao;
    this.nrSeqCentralTelefonica = jsonObject.nrSeqCentralTelefonica;
    this.noObs = jsonObject.noObs;
    this.nrSeqChipPai = jsonObject.nrSeqChipPai;
    this.nrPrefixoLinha = `${this.nrPrefixo ?? ''}${this.nrLinha ?? ''}`;
    this.flgNumeroEspecial = jsonObject.flgNumeroEspecial;

    this.operadora = new Operadora(jsonObject.operadora ?? {});
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.vinculosOperadora = (jsonObject.chipVinculosOperadora ?? []).map(
      (vinculoOperadora) => new ChipVinculosOperadora(vinculoOperadora)
    );
  }

  get linhaFormatada() {
    if (this.nrLinha) return `(${this.nrPrefixo}) ${this.nrLinha}`;
    return '';
  }

  get statusChip() {
    if (this.flgStatus === 'T') return 'Todos';
    if (this.flgStatus === 'I') return 'Inativo';
    if (this.flgStatus === 'A') return 'Ativo';
    return 'Virtual';
  }

  get situacaoChip() {
    if (this.flgSituacao === 'T') return 'Todos';
    if (this.flgSituacao === 'A') return 'Em Uso';
    if (this.flgSituacao === 'E') return 'Em Estoque';
    if (this.flgSituacao === 'R') return 'Reserva';
    if (this.flgSituacao === 'C') return 'Cancelado';

    return this.flgSituacaoStr;
  }

  get tipoChip() {
    if (this.flgTipo === 'M') return 'Movel';
    if (this.flgTipo === 'F') return 'Fixo';
    if (this.flgTipo === 'R') return 'Ramal';
    if (this.flgTipo === 'L') return 'Link Net';
    return 'Too-Free';
  }

  toJson = () => JSON.stringify(this);
}
export default Chip;
