import React, { useRef, useState } from 'react';
import { setTransaction } from 'core/services/api';

import {
  Autocomplete,
  Button,
  DatePicker,
  GridView,
  Loader,
  Modal,
  Notification,
  Switch,
  Textbox,
} from 'ui/components';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';
import {
  BuscaCartaoTarget,
  GerarDespesaTarget,
} from 'core/services/FIN/gerenciarIntegracaoBancaria';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';
import { MaskTypes } from 'ui/Helpers/masks';

export default function ModalIntegracaoTarget(props) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [messageState, setMessage] = useState(null);
  const gridView = useRef();

  const { show, transaction } = props;

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onAdicionarLançamentos = async () => {
    setLoading(true);

    const { status, message: msg, value } = await BuscaCartaoTarget(data);

    if (status === ResponseStatus.Success) {
      if (gridView && gridView.current)
        gridView.current.setDataSource(value.tituloPagarS);
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    setLoading(false);
  };

  const salvar = async () => {
    setLoading(true);

    const lista = gridView.current ? gridView.current.getDataSource() : [];
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);

    const obj = {
      ...data,
      tituloPagarS: lista,
    };

    const { status, message: msg, value } = await GerarDespesaTarget(obj);

    if (value !== '' && value !== undefined && value !== null) {
      const blob = new Blob([value], { type: 'text/plain' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'valores não enviados.txt';
      link.click();
    }

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
    if (status === ResponseStatus.Success) {
      props.onClose({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    } else {
      onAdicionarLançamentos();
    }
  };

  const cancelar = async () => {
    props.onClose({
      message: null,
      theme: null,
    });
  };

  const columns = [
    {
      key: 'nrSeqTituloPagar',
      visible: false,
    },
    {
      key: 'fornecedor.noFornecedor',
      title: 'Fornecedor',
    },
    {
      key: 'nrTit',
      title: 'Nr Tit',
    },
    {
      key: 'nrOrdem',
      title: 'Nr Ordem',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor Do Titulo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'dtVencimento',
      title: 'Data Vencimento',
      format: GridView.DataTypes.Date,
    },

    {
      key: 'nrCartao',
      title: 'Nr. Cartão',
    },
    {
      key: 'noObs',
      title: 'Observação',
    },
  ];

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitPag: true,
    });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const onSearchFornecedor = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaFors,
    } = await getFornecedorAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return pessoaFors;
  };

  const ValorString = async () => {
    if (data.hora !== undefined || data.hora !== null) {
      if (data.hora.length === 1) {
        const valor = `${data.hora}0:00:00`;
        setData({ ...data, hora: valor });
      }
      if (data.hora.length === 2) {
        const valor = `${data.hora}:00:00`;
        setData({ ...data, hora: valor });
      }
      if (data.hora.length === 3) {
        const resultado = data.hora.split('');

        const valor = `${resultado[0]}${resultado[1]}:${resultado[2]}0:00`;
        setData({ ...data, hora: valor });
      }
      if (data.hora.length === 4) {
        const resultado = data.hora.split('');

        const valor = `${resultado[0]}${resultado[1]}:${resultado[2]}${resultado[3]}:00`;

        setData({ ...data, hora: valor });
      }
      if (data.hora.length === 5) {
        const resultado = data.hora.split('');

        const valor = `${resultado[0]}${resultado[1]}:${resultado[2]}${resultado[3]}:${resultado[4]}0`;

        setData({ ...data, hora: valor });
      }
    }
  };

  return (
    <Modal
      loading={loading}
      show={show}
      title='Geração de valores'
      size={BootstrapSizes.Large}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => onSetMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='col-6 mb-3'>
            <Autocomplete
              label='Forma Pagamento'
              searchDataSource={onSearchFormaPagamento}
              selectedItem={data?.formaPagamento}
              onSelectItem={(formaPagamento) => {
                setData({
                  ...data,
                  formaPagamento,
                  nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                });
              }}
              dataSourceTextProperty='noFormaPagamento'
            />
          </div>

          <div className='col-6 mb-4'>
            <Autocomplete
              label='Fornecedor'
              searchDataSource={onSearchFornecedor}
              selectedItem={data?.fornecedor}
              onSelectItem={(fornecedor) => {
                setData({
                  ...data,
                  fornecedor,
                  nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                });
              }}
              dataSourceTextProperty='nomeDocumentoCidadeEstado'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-4 mb-3'>
            <DatePicker
              label='Vencimento De'
              text={data?.dataPagamentoDe}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dataPagamentoDe) =>
                setData({ ...data, dataPagamentoDe })
              }
            />
          </div>
          <div className='col-4 mb-3'>
            <DatePicker
              label='Vencimento Até'
              text={data?.dataPagamentoAte}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dataPagamentoAte) =>
                setData({ ...data, dataPagamentoAte })
              }
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-4 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Mostrar'
              onClick={onAdicionarLançamentos}
            />
          </div>
        </div>

        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>

        <div className='row'>
          <div className='col-4 mb-3'>
            <Switch
              formControl
              label='Data/Hora Recarga Manual '
              checked={data?.flgDataHoraManual}
              onChange={(flgDataHoraManual) =>
                setData({ ...data, flgDataHoraManual })
              }
            />
          </div>
          {data?.flgDataHoraManual && (
            <div className='col-4 mb-3'>
              <DatePicker
                label='Data'
                required
                text={data?.dataPagamento}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dataPagamento) =>
                  setData({ ...data, dataPagamento })
                }
              />
            </div>
          )}

          {data?.flgDataHoraManual && (
            <div className='col-4 mb-3'>
              <Textbox
                label='Hora'
                text={data?.hora}
                mask={MaskTypes.Time}
                onChangedValue={(hora) => setData({ ...data, hora })}
                onBlur={ValorString}
              />
            </div>
          )}
        </div>

        <div className='row'>
          <div className='col-12 mt-3 text-end'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Gerar'
              onClick={salvar}
            />
            <Button
              style={{ marginLeft: '5px' }}
              theme={Theme.Danger}
              template={Button.Templates.Button}
              text='Cancelar'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
