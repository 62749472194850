import React, { useEffect } from 'react';
import { Panel, Switch } from 'ui/components';
// @ts-expect-error
import { Theme } from 'ui/Helpers/enums.ts';

interface Props {
  consignatarioData: any;
  setConsignatarioData: (object: any) => void;
}

const ConsignatarioPanel = ({
  consignatarioData,
  setConsignatarioData,
}: Props) => {
  useEffect(() => {
    const objConsignatario = { ...consignatarioData };

    setConsignatarioData(objConsignatario);
  }, [consignatarioData.nrSeqPessoaConsig]);

  return (
    <div className='col mt-3'>
      {/* @ts-expect-error */}
      <Panel>
        {/* @ts-expect-error */}
        <Panel.Header title='Consignatário' theme={Theme.Primary} />

        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-1 mb-3'>
              <Switch
                formControl
                label='Ativo'
                checked={consignatarioData.flgAtivo}
                onChange={(flgAtivo) =>
                  setConsignatarioData({ ...consignatarioData, flgAtivo })
                }
              />
            </div>
            <div className='col-1 mb-3'>
              <Switch
                formControl
                label='Origem'
                checked={consignatarioData.flgOrigem}
                onChange={(flgOrigem) =>
                  setConsignatarioData({ ...consignatarioData, flgOrigem })
                }
              />
            </div>
            <div className='col-1 mb-3'>
              <Switch
                formControl
                label='Destino'
                checked={consignatarioData.flgDestino}
                onChange={(flgDestino) =>
                  setConsignatarioData({ ...consignatarioData, flgDestino })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </div>
  );
};

export default ConsignatarioPanel;
