import React, { useState } from 'react';
import { MaskTypes } from 'ui/Helpers/masks';
import { DropdownButton, Textbox } from 'ui/components';

const mathOperators = [
  {
    icon: 'greater-than-equal',
    text: 'Maior igual que',
    value: '>=',
    showTextOnSelect: false,
  },
  {
    icon: 'less-than-equal',
    text: 'Menor igual que',
    value: '<=',
    showTextOnSelect: false,
  },
  { icon: 'equals', text: 'Igual', value: '=', showTextOnSelect: false },
  {
    icon: 'not-equal',
    text: 'Diferente',
    value: '<>',
    showTextOnSelect: false,
  },
];

export const FilterTextOperator = ({
  label,
  text,
  onChangedValue,
  onSelectItem,
  filterProp,
}) => {
  const selected =
    mathOperators.find((item) => item.value === filterProp) ?? mathOperators[0];
  return (
    <Textbox
      label={label}
      text={text}
      mask={MaskTypes.Decimal}
      onChangedValue={(e) => onChangedValue(e, selected.value)}
      prependRender={
        <DropdownButton
          dropdownItems={mathOperators}
          selectedItem={selected}
          onSelectItem={onSelectItem}
        />
      }
    />
  );
};

export const HoverText = (props) => {
  const { children, tooltipMsg } = props;
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div>
      <div>
        <div onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
          {children}
        </div>

        {isHovering && (
          <div>
            <p>{tooltipMsg}</p>
          </div>
        )}
      </div>
    </div>
  );
};
