import React, { useEffect } from 'react';

const PDFViewer = ({ src, className }) => {
  const moveToTop = () => {
    if (document.getElementById('focusTop'))
      document.getElementById('focusTop').scrollIntoView();
  };

  useEffect(() => {
    moveToTop();
  }, [src]);

  if (!src)
    return (
      <div className={className}>
        <div className='panel'>
          <div className='panel-body'>
            O Servidor não retornou dados para impressão.
          </div>
        </div>
      </div>
    );

  return (
    <>
      <div id='focusTop' />
      <iframe
        src={
          !src.endsWith('.pdf') ? `data:application/pdf;base64, ${src}` : src
        }
        className={className}
        type='application/pdf'
        title='Impressão PDF'
        id='report'
      />
    </>
  );
};

export default PDFViewer;
