import Nf from 'core/models/COP/nf';

export default class NfNfv {
  constructor(jsonObject = {}) {
    this.nrSeqNfNfv = jsonObject.nrSeqNfNfv;
    this.nrSeqNfv = jsonObject.nrSeqNfv;
    this.nrSeqNf = jsonObject.nrSeqNf;
    this.nrSeqNfvAvulsa = jsonObject.nrSeqNfvAvulsa;
    this.status = jsonObject.status;
    this.nf = new Nf(jsonObject.nf ?? {});
  }

  toJson = () => JSON.stringify(this);
}
