export default class DetalheLinha {
  constructor(jsonObject = {}) {
    this.nrSeqChip = jsonObject.nrSeqChip;
    this.linha = jsonObject.linha;
    this.grupo = jsonObject.grupo;
    this.bloqueadoDados =
      jsonObject.bloqueadoDados === true ? 'Bloqueado' : 'Ativado';
    this.bloqueadoVoz =
      jsonObject.bloqueadoVoz === true ? 'Bloqueado' : 'Ativado';
    this.bloqueadoSMS =
      jsonObject.bloqueadoSMS === true ? 'Bloqueado' : 'Ativado';
    this.consumoVoz = jsonObject.consumoVoz;
    this.unidadeConsumoVoz = jsonObject.unidadeConsumoVoz;
    this.cotaDados = jsonObject.cotaDados;
    this.unidadeCotaDados = jsonObject.unidadeCotaDados ?? '';
    this.consumoDados = jsonObject.consumoDados;
    this.unidadeConsumoDados = jsonObject.unidadeConsumoDados;
    this.percentualConsumoCota = jsonObject.percentualConsumoCota;
  }

  get consumoVozFormatado() {
    return `${this.consumoVoz} ${this.unidadeConsumoVoz}`;
  }

  get cotaDadosFormatado() {
    return `${this.cotaDados} ${this.unidadeCotaDados}`;
  }

  get consumoDadosFormatado() {
    return `${this.consumoDados} ${this.unidadeConsumoDados}`;
  }

  get linhaFormatada() {
    return `(${this.linha.substring(0, 2)})${this.linha.substring(2, 11)} `;
  }

  toJson = () => JSON.stringify(this);
}
