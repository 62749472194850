import React, { useEffect, useRef, useState } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import { getPocGrupoProdOrcamentoAutoComplete } from 'core/services/POC/pocGrupoProdOrcamento';
import { getPocTipoOrcamentoAutoComplete } from 'core/services/POC/pocTipoOrcamento';
import {
  getPocProdOrcamentoPagined,
  deleteAllPocProdOrcamento,
} from 'core/services/POC/pocProdOrcamento';
import {
  CSDSelPage,
  GridView,
  Autocomplete,
  Textbox,
  RadioButton,
} from 'ui/components';

const columns = [
  {
    key: 'nrSeqPocProdOrcamento',
    type: GridView.ColumnTypes.Checkbox,
  },
  {
    key: 'nrSeqPocGrupoProdOrcamento',
    title: '',
    visible: 'false',
  },

  {
    key: 'grupoOrcamento.cdPocGrupoProdOrcamento',
    title: 'Grupo',
    sortKey: 'cdPocGrupoProdOrcamento',
  },
  {
    key: 'noPocProdOrcamento',
    title: 'Item do Orçamento',
    sortKey: 'noPocProdOrcamento',
  },
  { key: 'produto.noProduto', title: 'Produto', sortKey: 'noProduto' },
  {
    key: 'flgInativo',
    title: 'Inativo',
    sortKey: 'flgInativo',
  },
  { key: 'cdUnidade', title: 'Unidade' },
  { key: 'vlrBase', title: 'Valor', format: GridView.DataTypes.Decimal },
  {
    key: 'flgPermiteEditar',
    title: 'Editável',
    sortKey: 'flgPermiteEditar',
  },
  {
    key: 'nrDiametropol',
    title: 'Diâmetro',
    sortKey: 'nrDiametropol',
  },
  {
    key: 'tipo.noPocProdOrcamentoTipo',
    title: 'Tipo',
    sortKey: 'noPocProdOrcamentoTipo',
  },
  {
    key: 'nrOrdem',
    title: 'Ordem',
    sortKey: 'nrOrdem',
  },
];

const produtosAtivosInativos = [
  {
    text: 'Todos',
    value: 'T',
  },
  {
    text: 'Ativo ',
    value: 'A',
  },
  {
    text: 'Inativo ',
    value: 'I',
  },
];

export default function PocProdOrcamento({
  transaction,
  onOpenPage,
  isActive,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({ flgProdutoAtivoInativo: 'T' });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, messageshow) => {
    if (messageshow)
      setMessage({
        message: messageshow,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchTipoOrcamento = async (e) => {
    const {
      status,
      message: msg,
      data: grupos,
    } = await getPocTipoOrcamentoAutoComplete({
      noTipoOrcamento: e,
    });
    onSetMessage(status, msg);
    return grupos;
  };

  const onSearchGrupoOrcamento = async (e) => {
    const {
      status,
      message: msg,
      data: grupos,
    } = await getPocGrupoProdOrcamentoAutoComplete({
      cdPocGrupoProdOrcamento: e,
      nrSeqPocTipoOrcamento: filtros.nrSeqPocTipoOrcamento,
    });
    onSetMessage(status, msg);
    return grupos;
  };

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getPocProdOrcamentoPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getPocProdOrcamentoPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ProdutoOrcamento/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqPocProdOrcamento = sourceRow.find(
      (e) => e.key === 'nrSeqPocProdOrcamento'
    );
    onOpenMaintenance(nrSeqPocProdOrcamento.value);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllPocProdOrcamento(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      transaction={transaction}
      isActive={isActive}
      title='Seleção de Produtos Orçamento'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <div className='row'>
        <div className='col-4 mb-3'>
          <Autocomplete
            label='Tipo Orçamento'
            searchDataSource={onSearchTipoOrcamento}
            selectedItem={filtros.tipoOrcamento}
            onSelectItem={(tipoOrcamento) =>
              setFiltros({
                ...filtros,
                tipoOrcamento,
                nrSeqPocTipoOrcamento: tipoOrcamento.nrSeqPocTipoOrcamento,
              })
            }
            dataSourceTextProperty='noPocTipoOrcamento'
          />
        </div>
        <div className='col-4 mb-3'>
          <Autocomplete
            label='Grupo Orçamento'
            searchDataSource={onSearchGrupoOrcamento}
            selectedItem={filtros.grupoOrcamento}
            onSelectItem={(grupoOrcamento) =>
              setFiltros({
                ...filtros,
                grupoOrcamento,
                nrSeqPocGrupoProdOrcamento:
                  grupoOrcamento.nrSeqPocGrupoProdOrcamento,
              })
            }
            dataSourceTextProperty='cdPocGrupoProdOrcamento'
          />
        </div>
        <div className='col-4 mb-3'>
          <RadioButton
            label='Produto Ativo/Inativo'
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={produtosAtivosInativos}
            selectedButton={filtros.flgProdutoAtivoInativo}
            onChange={(flgProdutoAtivoInativo) =>
              setFiltros({
                ...filtros,
                flgProdutoAtivoInativo,
              })
            }
          />
        </div>
      </div>
      <div className='col-8 mb-1'>
        <Textbox
          label='Produto'
          text={filtros.noProduto}
          onChangedValue={(noPocProdOrcamento) => {
            filtros.noPocProdOrcamento = noPocProdOrcamento;
          }}
        />
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            gridSizeList={[5000]}
            showPagination={false}
            showSelectSizes={false}
            enableExcelExport
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
