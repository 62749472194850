import React, { useState, useEffect, useRef } from 'react';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  Button,
  Loader,
  Modal,
  Notification,
  Textbox,
  DatePicker,
  Textarea,
  RadioButton,
  GridView,
  Panel,
  Autocomplete,
} from 'ui/components';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
import { getCidadeEstadoAutoComplete } from 'core/services/SEG/cidade';
import {
  onGetValoresPedagios,
  onEmitirValePedagio,
  onConsultarProviderValePedagio,
} from 'core/services/FRO/freteTerceiro';

function newDateFormat() {
  const newDate = new Date();
  const day = newDate.getDate().toString().padStart(2, '0');
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const year = newDate.getFullYear();
  return `${year}-${month}-${day}T00:00:00.0`;
}

export default function ModalValePedagio({ show, onClose, data, setData }) {
  const gridView = useRef();
  const gridViewPedagios = useRef();

  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [valePedagio, setValePedagio] = useState({
    flgPedagioDescontaFrete: 'S',
    vlrPedagio: 0,
    noObservacao: '',
  });
  const [emitirVPprovider, setEmitirVPprovider] = useState(true);
  const [parada, setParada] = useState({ dtParada: newDateFormat() });

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onGetProvider = async (params) => {
    const {
      status,
      message: msg,
      value: provider,
    } = await onConsultarProviderValePedagio({
      veiculo: params.veiculo,
      noTipoIntegracao: 'TruckPad',
    });

    if (
      status === ResponseStatus.Error ||
      msg.includes('One or more errors occurred.')
    ) {
      return onSetMessage(ResponseStatus.Warning, msg);
    }

    if (provider.provider === 'semparar') {
      provider.noProvider = 'Sem Parar';
    } else if (provider.provider === 'conectcar') {
      provider.noProvider = 'Conectcar';
    }

    return provider;
  };

  useEffect(async () => {
    if (show) {
      setLoading(true);

      const newDate = newDateFormat();
      const providerData = await onGetProvider({ veiculo: data.veiculo });

      if (providerData === undefined) {
        setEmitirVPprovider(false);
      } else {
        setValePedagio({
          flgPedagioDescontaFrete: 'S',
          motorista: data.motorista,
          veiculo: data.veiculo,
          vlrPedagio: 0,
          provider: providerData,
          veiculoCategoria: providerData.categoria.codigo,
          cidadeOrigem: data.cidadeOrigem,
          cidadeDestino: data.cidadeDestino,
          dtInicioViagem: newDate,
          dtFinalViagem: data.dtPrevistaEntrega,
          noObservacao: '',
          nrSeqFreteTerceiro: data.nrSeqFreteTerceiro,
        });

        setParada({ dtParada: newDateFormat() });

        if (gridView?.current) {
          data.cidadeOrigem.noParada = 'Origem';
          data.cidadeOrigem.nrOrdem = 1;
          data.cidadeOrigem.dtParada = newDate;
          data.cidadeDestino.noParada = 'Destino';
          data.cidadeDestino.nrOrdem = 2;
          data.cidadeDestino.dtParada = data.dtPrevistaEntrega;

          gridView.current.setDataSource([
            data.cidadeOrigem,
            data.cidadeDestino,
          ]);
        }
      }

      setLoading(false);
    }
  }, [show]);

  const calcPedagioDescontaFrete = [
    {
      text: 'Sim',
      value: 'S',
    },
    {
      text: 'Não',
      value: 'N',
    },
  ];

  const onSearchEstado = async (e) => {
    const { status, message: msg, estados } = await getEstadoAutoComplete({
      noEstado: e,
    });

    if (msg) onSetMessage(status, msg);
    return estados;
  };

  const onSearchCidade = async (e) => {
    const { status, message: msg, cidades } = await getCidadeEstadoAutoComplete(
      {
        noCidade: e,
        nrSeqEstado: parada.nrSeqEstado,
      }
    );

    if (msg) onSetMessage(status, msg);
    return cidades;
  };

  const sortCidades = (cidadesList, newParada = null) => {
    const origem = cidadesList.find((item) => item.noParada === 'Origem');
    const paradasList =
      cidadesList.filter(
        (item) => item.noParada !== 'Origem' && item.noParada !== 'Destino'
      ) || [];
    const destino = cidadesList.find((item) => item.noParada === 'Destino');

    if (newParada) paradasList.push(newParada);

    paradasList.forEach((el, index) => {
      el.nrOrdem = index + 2;
      el.noParada = `Parada nº${index + 1}`;
    });

    destino.nrOrdem = paradasList.length + 2;

    return [origem, ...paradasList, destino];
  };

  const onClickAddParada = () => {
    const cidadesList = gridView.current.getDataSource();

    const newParada = parada.cidade;
    newParada.estado = parada.estado;
    newParada.dtParada = parada.dtParada;

    const cidadesSorted = sortCidades(cidadesList, newParada);

    if (gridView?.current) gridView.current.setDataSource(cidadesSorted);

    setParada({ dtParada: newDateFormat() });
  };

  const onClickRemoveParada = async (selectedValue, datasource) => {
    const cidadesList = datasource.filter((item) => item !== selectedValue);

    const cidadesSorted = sortCidades(cidadesList);

    if (gridView?.current) gridView.current.setDataSource(cidadesSorted);
  };

  const onFormataParadas = (paradasList) => {
    const paradas = [];
    paradasList.forEach((item) => {
      const newParada = {
        estado: item.estado.cdEstado,
        pais: item.estado.pais.noPais,
        cidade: item.noCidade,
        dtParada: item.dtParada,
      };

      paradas.push(newParada);
    });

    return paradas;
  };

  const onClickSimularValoresPedagio = async () => {
    setLoading(true);

    if (valePedagio.provider === undefined) {
      onSetMessage(ResponseStatus.Error, 'Escolha a solução de Vale Pedágio.');
    } else {
      const paradasList = [...gridView.current.getDataSource()];

      const paradas = onFormataParadas(paradasList);

      const provider = {
        noProvider: valePedagio.provider.provider,
        tag: valePedagio.provider.tag,
        veiculoCategoria: valePedagio.veiculoCategoria,
        noVeiculoCategoriaDescricao: valePedagio.provider.categoria.nome,
      };

      const simularVP = {
        nrSeqFreteTerceiro: data.nrSeqFreteTerceiro,
        dtInicioViagem: valePedagio.dtInicioViagem,
        dtFinalViagem: valePedagio.dtFinalViagem,
        provider,
        veiculo: valePedagio.veiculo,
        paradas,
        noObservacao: valePedagio.noObservacao,
        noTipoIntegracao: 'TruckPad',
      };

      const {
        status,
        message: msg,
        value: pedagios,
      } = await onGetValoresPedagios(simularVP);

      if (status === ResponseStatus.Error) {
        onSetMessage(status, msg);
      } else if (gridViewPedagios?.current) {
        let rotaId = 1;
        pedagios.forEach((item) => {
          item.nrSeqRota = rotaId;
          rotaId += 1;
        });
        gridViewPedagios.current.setDataSource(pedagios);
      }
    }

    setLoading(false);
  };

  const onClickGerarValePedagio = async () => {
    setLoading(true);

    const rotas = gridViewPedagios.current.getDataSource();
    const selected = gridViewPedagios.current.getCheckBoxValuesAt(0);

    const dadosRota = rotas.find((el) => el.nrSeqRota === selected[0][0].value);
    console.log(dadosRota);

    if (selected.length === 0) {
      onSetMessage(ResponseStatus.Warning, 'Selecione uma rota.');
    } else {
      const providerEmissao = {
        noProvider: valePedagio.provider.provider,
        tag: valePedagio.provider.tag,
        veiculoCategoria: valePedagio.veiculoCategoria,
        noVeiculoCategoriaDescricao: valePedagio.provider.categoria.nome,
      };

      const paradasList = [...gridView.current.getDataSource()];
      const paradasFormatadas = onFormataParadas(paradasList);

      const { status, message: msg } = await onEmitirValePedagio({
        paradas: paradasFormatadas,
        dtInicioViagem: valePedagio.dtInicioViagem,
        dtFinalViagem: valePedagio.dtFinalViagem,
        provider: providerEmissao,
        veiculo: valePedagio.veiculo,
        noObservacao: valePedagio.noObservacao,
        nrSeqPessoaMot: valePedagio.motorista.nrSeqPessoaMot,
        dataPedagio: dadosRota.dataRota,
        noTipoIntegracao: 'TruckPad',
        nrSeqFreteTerceiro: valePedagio.nrSeqFreteTerceiro,
        nrSeqCidadeOri: valePedagio.cidadeOrigem.nrSeqCidade,
        nrSeqCidadeDes: valePedagio.cidadeOrigem.nrSeqCidade,
        nrSeqPessoaProp: valePedagio.veiculo.nrSeqPessoaTran,
        vlrValePedagio: dadosRota.valorBrutoPedagio,
        rota: dadosRota.noRotaDescricao,
      });

      setLoading(false);

      if (msg) onSetMessage(status, msg);

      if (status === ResponseStatus.Success) {
        if (valePedagio.flgPedagioDescontaFrete === 'S') {
          const vlrAbatimento = data.vlrAbatimento + dadosRota.vlrValePedagio;
          const noAbatimento = `${data.noAbatimento} + Vale Pedágio`;
          setData({ ...data, vlrAbatimento, noAbatimento });
        }

        onClose();
      }
    }

    setLoading(false);
  };

  const columnsTrajeto = [
    {
      key: 'noParada',
      title: 'Local',
    },
    {
      key: 'noCidade',
      title: 'Cidade',
    },
    {
      key: 'estado.noEstado',
      title: 'Estado',
    },
    {
      key: 'dtParada',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrOrdem',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveParada(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgExibeBtn',
    },
  ];

  const columnsPedagios = [
    {
      key: 'nrSeqRota',
      type: GridView.ColumnTypes.Checkbox,
    },
    {
      key: 'noRota',
      title: 'Opção',
    },
    {
      key: 'noDistancia',
      title: 'Distância',
    },
    {
      key: 'valorBrutoPedagio',
      title: 'Valor Bruto',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'valorPedagio',
      title: 'Valor',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'noRotaDescricao',
      title: 'Detalhamento da Rota',
    },
  ];

  return (
    <Modal
      show={show}
      title='Gerar Vale Pedágio'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <Panel className='mb-3'>
          <Panel.Header title='Informações Iniciais' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-2'>
              <div className='col'>
                <Textbox
                  label='Motorista'
                  readOnly
                  text={valePedagio.motorista?.noPessoa}
                />
              </div>

              <div className='col-2'>
                <Textbox
                  readOnly
                  label='Veículo'
                  text={valePedagio.veiculo?.placa}
                />
              </div>

              <div className='col-3'>
                <Textbox
                  label='Solução Vale-Pedágio'
                  text={valePedagio.provider?.noProvider}
                  readOnly
                />
              </div>

              <div className='col-2 d-flex flex-column align-items-start flex-wrap-nowrap'>
                <RadioButton
                  label='Desconta do Frete?'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={valePedagio.flgPedagioDescontaFrete}
                  buttons={calcPedagioDescontaFrete}
                  onChange={(flgPedagioDescontaFrete) => {
                    setValePedagio({ ...valePedagio, flgPedagioDescontaFrete });
                  }}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-2'>
                <Textbox
                  readOnly
                  label='Cidade Origem'
                  text={valePedagio.cidadeOrigem?.noCidade}
                />
              </div>

              <div className='col-1'>
                <Textbox
                  readOnly
                  label='UF'
                  text={valePedagio.cidadeOrigem?.estado.cdEstado}
                />
              </div>

              <div className='col-3'>
                <DatePicker
                  label='Início da Viagem'
                  text={valePedagio?.dtInicioViagem}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtInicioViagem) => {
                    setValePedagio({ ...valePedagio, dtInicioViagem });
                  }}
                />
              </div>

              <div className='col-2'>
                <Textbox
                  readOnly
                  label='Cidade Destino'
                  text={valePedagio.cidadeDestino?.noCidade}
                />
              </div>

              <div className='col-1'>
                <Textbox
                  readOnly
                  label='UF'
                  text={valePedagio.cidadeDestino?.estado.cdEstado}
                />
              </div>

              <div className='col-3'>
                <DatePicker
                  label='Fim da Viagem'
                  text={valePedagio?.dtFinalViagem}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtFinalViagem) => {
                    setValePedagio({ ...valePedagio, dtFinalViagem });

                    const cidadesList = gridView.current.getDataSource();
                    cidadesList[
                      cidadesList.length - 1
                    ].dtParada = dtFinalViagem;

                    if (gridView?.current)
                      gridView.current.setDataSource(cidadesList);
                  }}
                />
              </div>
            </div>

            <div className='row mb-2'>
              {/* Observações */}
              <div className='col mb-3'>
                <Textarea
                  label='Observações'
                  maxLength={1000}
                  text={valePedagio.noObservacao}
                  onChangedValue={(noObservacao) =>
                    setValePedagio({ ...valePedagio, noObservacao })
                  }
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>

        {emitirVPprovider && (
          <>
            <Panel className='mb-3'>
              <Panel.Header title='Adicionar Paradas' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row'>
                  <div className='col'>
                    <Autocomplete
                      label='Estado'
                      searchDataSource={onSearchEstado}
                      selectedItem={parada?.estado}
                      onSelectItem={(estado) => {
                        setParada({
                          ...parada,
                          estado,
                          nrSeqEstado: estado.nrSeqEstado,
                        });
                      }}
                      dataSourceTextProperty='noEstado'
                    />
                  </div>

                  <div className='col'>
                    <Autocomplete
                      label='Cidade'
                      readOnly={!parada?.nrSeqEstado > 0}
                      searchDataSource={onSearchCidade}
                      selectedItem={parada?.cidade}
                      onSelectItem={(cidade) => {
                        setParada({
                          ...parada,
                          cidade,
                          nrSeqCidade: cidade.nrSeqCidade,
                        });
                      }}
                      dataSourceTextProperty='noCidade'
                    />
                  </div>

                  <div className='col-3'>
                    <DatePicker
                      label='Data'
                      text={parada?.dtParada}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtParada) => {
                        setParada({ ...parada, dtParada });
                      }}
                    />
                  </div>

                  <div className='col-2 d-flex align-items-end'>
                    <Button
                      text='Adicionar'
                      theme={Theme.Primary}
                      icon='fas fa-times'
                      onClick={onClickAddParada}
                    />
                  </div>

                  <div className='col-2 d-flex align-items-end'>
                    <Button
                      outline
                      text='Simular Valores'
                      theme={Theme.Primary}
                      icon='fas fa-times'
                      onClick={onClickSimularValoresPedagio}
                    />
                  </div>
                </div>

                <div className='row'>
                  <GridView
                    className='table-striped table-hover table-bordered table-sm'
                    ref={gridView}
                    dataColumns={columnsTrajeto}
                    showSelectSizes={false}
                    showPagination={false}
                  />
                </div>
              </Panel.Body>
            </Panel>

            <Panel className='mb-3'>
              <Panel.Header
                title='Escolha um Vale Pedágio:'
                theme={Theme.Primary}
              />
              <Panel.Body>
                <div className='row'>
                  <GridView
                    className='table-striped table-hover table-bordered table-sm'
                    ref={gridViewPedagios}
                    dataColumns={columnsPedagios}
                    showSelectSizes={false}
                    showPagination={false}
                  />
                </div>
              </Panel.Body>
            </Panel>

            <div className='row justify-content-end mb-2'>
              <div className='col-2'>
                <Button
                  outline
                  theme={Theme.Success}
                  text='Emitir Vale Pedágio'
                  icon='fa-solid fa-cloud-check'
                  onClick={onClickGerarValePedagio}
                />
              </div>
              <div className='col-2'>
                <Button
                  outline
                  text='Cancelar'
                  theme={Theme.Danger}
                  icon='fas fa-times'
                  onClick={() => {
                    onClose();
                  }}
                />
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
