import api from '../api';

const baseURL = `/FRO/ConhecimentosFaturar`;

export const postGerarFaturamento = async (data) => {
  const response = await api.post(`${baseURL}/GerarFaturamento`, data);
  return response.data ?? {};
};

export const geraParcelasAutomatico = async (data) => {
  const response = await api.get(`${baseURL}/GerarParcelasAutomatico`, {
    params: data,
  });
  return response.data ?? {};
};

export const printBoleto = async (id) => {
  const response = await api.post(`${baseURL}/PrintBoleto`, { params: { id } });

  return response.data ?? {};
};

export const postGerarFaturamentoMassivo = async (data) => {
  const response = await api.post(`${baseURL}/GerarFaturamentoMassivo`, data);
  return response.data ?? {};
};
