import qs from 'qs';
import Pagination from 'core/models/pagination';
import PocPocoTipoDocumento from 'core/models/POC/pocPocoTipoDocumento';
import api from '../api';

const baseURL = `POC/PocPocoTipoDocumento`;

export const getPocPocoTipoDocumentoAutoComplete = async (filters) => {
  const response = await api.get(`/${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPocoTipoDocumento(json)) ?? [],
  };
};

export const getPocPocoTipoDocumentoList = async (filters) => {
  const response = await api.get(`/${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPocoTipoDocumento(json)) ?? [],
  };
};

export const getPocPocoTipoDocumentoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPocoTipoDocumento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocPocoTipoDocumento = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocPocoTipoDocumento(value) : {};
};

export const savePocPocoTipoDocumento = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePocPocoTipoDocumento = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPocPocoTipoDocumento = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocPocoTipoDocumento = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocPocoTipoDocumento = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
