import { getTipoCombustivelAutoComplete } from 'core/services/FRO/tipoCombustivel';
import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { Autocomplete, Button, GridView, Textbox } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';

const TipoCombustivelStep = forwardRef(({ data, setData, onSetMessage }) => {
  const gridView = useRef();
  const [stepData, setStepData] = useState({
    vlrMediaMinimaAbastecimento: data.vlrMediaMinimaAbastecimento ?? 0,
    tiposCombustivel: data.tiposCombustivel ?? [],
  });
  const [dataTipoCombustivel, setDataTipoCombustivel] = useState({});

  useEffect(() => {
    if (gridView && gridView.current && data.tiposCombustivel) {
      gridView.current.setDataSource(data.tiposCombustivel);
    }
  }, []);

  useEffect(async () => {
    await setData({
      ...data,
      ...stepData,
    });
  }, [stepData]);

  const onSearchTipoCombustivel = async (e) => {
    const response = await getTipoCombustivelAutoComplete({
      noTipoCombustivel: e,
    });

    return response.tipoCombustiveis;
  };

  const onClickAddTipoCombustivel = async () => {
    if (dataTipoCombustivel.tipoCombustivel) {
      const obj = {
        ...dataTipoCombustivel.tipoCombustivel,
        status: GridView.EnumStatus.Inserir,
      };

      const list = gridView.current.getDataSource();
      list.push(obj);

      if (gridView && gridView.current) gridView.current.setDataSource(list);

      setStepData({ ...stepData, tiposCombustivel: list });
      setDataTipoCombustivel({});
    } else {
      onSetMessage(false, 'Informe o Tipo Combustível');
    }
  };

  const onClickRemoveTipoCombustivel = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const list = datasource.filter((el) => el !== e);

    if (gridItem.status !== GridView.EnumStatus.Inserir) {
      gridItem.status = GridView.EnumStatus.Remover;

      list.push(gridItem);
    }

    if (gridView && gridView.current) gridView.current.setDataSource(list);

    setData({ ...data, tiposCombustivel: list });
  };

  const columns = [
    { key: 'noTipoCombustivel', title: 'Tipo Combustível', sortable: false },
    {
      key: 'nrSeqTipoCombustivel',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemoveTipoCombustivel(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <div>
      <div className='row mb-3'>
        <div className='col-2'>
          <Textbox
            label='Média Mínima de Consumo'
            text={stepData.vlrMediaMinimaAbastecimento}
            maxLength={20}
            mask={MaskTypes.Decimal}
            onChangedValue={(vlrMediaMinimaAbastecimento) =>
              setStepData({ ...stepData, vlrMediaMinimaAbastecimento })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Tipo Combustível'
            searchDataSource={onSearchTipoCombustivel}
            selectedItem={dataTipoCombustivel.tipoCombustivel}
            onSelectItem={(tipoCombustivel) =>
              setDataTipoCombustivel({
                ...dataTipoCombustivel,
                tipoCombustivel,
                nrSeqTipoCombustivel: tipoCombustivel.nrSeqTipoCombustivel,
              })
            }
            dataSourceTextProperty='noTipoCombustivel'
          />
        </div>

        <div className='col d-flex align-items-end'>
          <Button
            onClick={onClickAddTipoCombustivel}
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Adicionar'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            offlineData
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
    </div>
  );
});

export default TipoCombustivelStep;
