import qs from 'qs';
import Pagination from 'core/models/pagination';
import InfracaoGravidade from 'core/models/FRO/infracaoGravidade';
import api from '../api';

const baseURL = `/FRO/InfracaoGravidade`;

export const getInfracaoGravidadeList = async (filters) => {
  const response = await api.get(`/$InfracaoGravidade/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new InfracaoGravidade(json)) ?? [],
  };
};
export const getInfracaoGravidadeAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    infracaogravidades: value?.map((json) => new InfracaoGravidade(json)) ?? [],
  };
};

export const getInfracaoGravidadePagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new InfracaoGravidade(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getInfracaoGravidade = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new InfracaoGravidade(value) : {};
};

export const saveInfracaoGravidade = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteInfracaoGravidade = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printInfracaoGravidade = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelInfracaoGravidade = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllInfracaoGravidade = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
