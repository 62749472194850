import React, { useEffect, useRef, useReducer } from 'react';
import {
  Modal,
  GridView,
  Button,
  Notification,
  DropdownList,
  Loader,
} from 'ui/components';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/enums';
import { GridviewColumns, Message } from 'ui/Helpers/interfaces';

import { getApoliceSeguroAutoComplete } from 'core/services/FRO/apoliceSeguro';
import { averbarLoteCtes } from 'core/services/FRO/coletaEmbarque';

import { ColetaEmbarque, ApoliceSeguro } from 'core/models/FRO';

interface Props {
  show: boolean;
  ctes: ColetaEmbarque[];
  onAverbados: (ctes: ColetaEmbarque[], messages: Message[] | null) => void;
  onClose: (search: boolean, messages: Message[] | null) => void;
}

interface ReducerState {
  loading: boolean;
  messages: Message[] | null;
  dropDownApoliceSeguro: ApoliceSeguro[];
  apoliceSeguro: ApoliceSeguro;
}

interface ReducerAction {
  type: string;
  payload?: any;
}

function reducer(state: ReducerState, action: ReducerAction): ReducerState {
  switch (action.type) {
    case 'openLoader':
      return { ...state, loading: true };
    case 'closeLoader':
      return { ...state, loading: false };
    case 'updateMessage':
      return {
        ...state,
        messages: action.payload,
      };
    case 'updateApoliceSeguro':
      return { ...state, apoliceSeguro: action.payload };
    case 'updateDropDownApoliceSeguro':
      return { ...state, dropDownApoliceSeguro: action.payload };
    default:
      return state;
  }
}

export default function ModalAverbaLoteCtes({
  show,
  ctes,
  onAverbados,
  onClose,
}: Props) {
  const gridView = useRef<any>();
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    messages: null,
    apoliceSeguro: new ApoliceSeguro({}),
    dropDownApoliceSeguro: [],
  });

  const columns: GridviewColumns[] = [
    { key: 'nrSeqColetaEmbarque', visible: false },
    { key: 'cdColetaEmbarque', title: 'Nr. CTE' },
    { key: 'coletaEmbarqueAutorizacao.nrChaveCTE', title: 'Chave' },
  ];

  const onSend = () => {
    onClose(false, []);
  };

  const onAverbaCtes = async (): Promise<void> => {
    dispatch({ type: 'openLoader' });

    const { messages, data: ctesAverbados } = await averbarLoteCtes({
      nrSeqsColetaEmbarque: ctes.map(
        (item: ColetaEmbarque) => item.nrSeqColetaEmbarque
      ),
      apoliceSeguro: state.apoliceSeguro,
    });

    if (messages) {
      dispatch({
        type: 'updateMessage',
        payload: messages,
      });
    }

    if (ctesAverbados?.length > 0) onAverbados(ctesAverbados, messages);
    else onClose(true, messages);

    if (gridView && gridView.current) gridView.current.setDataSource([]);

    dispatch({ type: 'updateDropDownApoliceSeguro', payload: [] });
    dispatch({ type: 'updateApoliceSeguro', payload: {} });

    dispatch({ type: 'closeLoader' });
  };

  const onSearchApolicesSeguro = async (): Promise<void> => {
    const {
      status,
      message: msg,
      data: apolicesSeguro,
    } = await getApoliceSeguroAutoComplete({
      nrSeqPessoaTomador: ctes[0].nrSeqPessoaCli,
      nrSeqPessoaRem: ctes[0].nrSeqPessoaRem,
      nrSeqPessoaDes: ctes[0].nrSeqPessoaDes,
      nrSeqColetaEmbarque: ctes[0].nrSeqColetaEmbarque,
    });

    if (msg)
      dispatch({
        type: 'updateMessage',
        payload: [
          {
            message: msg,
            theme:
              status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
          },
        ],
      });

    dispatch({ type: 'updateDropDownApoliceSeguro', payload: apolicesSeguro });
  };

  useEffect((): void => {
    dispatch({ type: 'openLoader' });

    if (show) {
      if (gridView && gridView.current) gridView.current.setDataSource(ctes);

      onSearchApolicesSeguro();
    } else {
      if (gridView && gridView.current) gridView.current.setDataSource([]);

      dispatch({
        type: 'updateDropDownApoliceSeguro',
        payload: [],
      });
    }

    dispatch({ type: 'closeLoader' });
  }, [show]);

  return (
    // @ts-expect-error
    <Modal
      show={show}
      icon='check'
      title='Averbar Lote CTEs'
      size={BootstrapSizes.Large}
      onClose={() => onSend()}
    >
      {/* @ts-expect-error */}
      <Modal.Body>
        <Loader loading={state.loading} />
        {state.messages && (
          // @ts-expect-error
          <Notification
            messages={state.messages}
            onClose={() => {
              dispatch({
                type: 'updateMessage',
                payload: null,
              });
            }}
          />
        )}
        {!state.messages && (
          <div className='alert alert-warning' role='alert'>
            <b>
              Selecione a apólice que será atribuída aos CTEs sem apólice
              vinculada.
            </b>
            <p />
            Os seguintes CTEs serão averbados:
          </div>
        )}
        <div className='row'>
          <div className='col'>
            <DropdownList
              label='Apólice Seguro'
              dataSource={state.dropDownApoliceSeguro}
              dataSourcePropertyText='nrApolice'
              dataSourcePropertyValue='nrSeqApoliceSeguro'
              selectedItems={state.apoliceSeguro ?? []}
              onSelectItem={(item: ApoliceSeguro) => {
                dispatch({ type: 'updateApoliceSeguro', payload: item });
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridView}
              // @ts-expect-error
              dataColumns={columns}
              gridSizeList={[500]}
              offlineData
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Averbar'
          icon='check'
          theme={Theme.Success}
          onClick={onAverbaCtes}
        />
        <Button
          text='Sair'
          icon={['fas', 'times']}
          theme={Theme.Danger}
          onClick={onSend}
        />
      </Modal.Footer>
    </Modal>
  );
}
