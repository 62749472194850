export default class FormaLancamentoEletronico {
  constructor(jsonObject = {}) {
    this.nrSeqFormaLancamentoEletronico =
      jsonObject.nrSeqFormaLancamentoEletronico;
    this.cdFormaLancamentoEletronico = jsonObject.cdFormaLancamentoEletronico;
    this.noFormaLancamentoEletronico = jsonObject.noFormaLancamentoEletronico;
    this.noFormatoEnvio = jsonObject.noFormatoEnvio;
    this.flgAtivo = jsonObject.flgAtivo;
    this.nrSeqBanco = jsonObject.nrSeqBanco;
  }

  toJson = () => JSON.stringify(this);
}
