import CfoMensagem from 'core/models/FIS/cfoMensagem';
import { Estado, Pais } from 'core/models/SEG';

export default class GrupoIcmsMensagemEstado {
  constructor(jsonObject = {}) {
    this.nrSeqCfoMensagem = jsonObject.nrSeqCfoMensagem;
    this.nrSeqGrupoIcms = jsonObject.nrSeqGrupoIcms;
    this.nrSeqEstadoOrigem = jsonObject.nrSeqEstadoOrigem;
    this.nrSeqEstadoDestino = jsonObject.nrSeqEstadoDestino;
    this.cfoMensagem = new CfoMensagem(jsonObject.cfoMensagem ?? {});
    this.estadoOrigem = new Estado(jsonObject.estadoOrigem ?? {});
    this.estadoDestino = new Estado(jsonObject.estadoDestino ?? {});
    this.paisOrigem = new Pais(jsonObject.paisOrigem ?? {});
    this.paisDestino = new Pais(jsonObject.paisDestino ?? {});
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
