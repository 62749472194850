export default class GrupoContrato {
  constructor(jsonObject = {}) {
    this.contrato = jsonObject.contrato;
    this.id = jsonObject.id;
    this.nome = jsonObject.nome;
    this.bonusDados = jsonObject.bonusDados;
    this.unidadeBonusDados = jsonObject.unidadeBonusDados;
    this.bonusConsumidosDados = jsonObject.bonusConsumidosDados;
    this.unidadeBonusConsumidosDados = jsonObject.unidadeBonusConsumidosDados;
    this.cotaDados = jsonObject.cotaDados;
    this.unidadeCotaDados = jsonObject.unidadeCotaDados;
    this.consumoDados = jsonObject.consumoDados;
    this.unidadeConsumoDados = jsonObject.unidadeConsumoDados;
    this.percentualConsumoCota = jsonObject.percentualConsumoCota;
    this.cotaDadosNaoAtribuidas = jsonObject.cotaDadosNaoAtribuidas;
    this.unidadeCotaDadosNaoAtribuidas =
      jsonObject.unidadeCotaDadosNaoAtribuidas;
    this.totalLinhas = jsonObject.totalLinhas;
  }

  get bonusDadosFormatado() {
    return `${this.bonusDados} ${this.unidadeBonusDados}`;
  }

  get bonusConsumidosDadosFormatado() {
    return `${this.bonusConsumidosDados} ${this.unidadebonusConsumidosDados}`;
  }

  get cotaDadosFormatado() {
    return `${this.cotaDados} ${this.unidadeCotaDados}`;
  }

  get consumoDadosFormatado() {
    return `${this.consumoDados} ${this.unidadeConsumoDados}`;
  }

  get cotaDadosNaoAtribuidasFormatado() {
    return `${this.cotaDadosNaoAtribuidas} ${this.unidadeCotaDadosNaoAtribuidas}`;
  }

  get nomeCota() {
    return `${this.nome} (${this.cotaDados} GB(s) ao total)`;
  }

  toJson = () => JSON.stringify(this);
}
