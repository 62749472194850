import qs from 'qs';
import Pagination from 'core/models/pagination';
import Aparelho from 'core/models/TEL/aparelho';
import api from '../api';

const baseURL = `/TEL/Aparelho`;

export const getAparelhoList = async (filters) => {
  const response = await api.get(`/$Aparelho/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Aparelho(json)) ?? [],
  };
};

export const getAparelhoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    aparelho: value?.map((json) => new Aparelho(json)) ?? [],
  };
};

export const getAparelhoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Aparelho(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getAparelho = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Aparelho(value) : {};
};

export const saveAparelho = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const changeAparelho = async (data) => {
  const response = await api.put(`${baseURL}/ChangeCompany`, data);

  return response.data ?? {};
};
export const deleteAparelho = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printAparelho = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelAparelho = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllAparelho = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const getAparelhoAutoCompleteForDemanda = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteForDemanda`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    aparelhos: value?.map((json) => new Aparelho(json)) ?? [],
  };
};
