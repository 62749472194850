export default class PneuTipo {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqPneuTipo = jsonObject.nrSeqPneuTipo;
    this.cdPneuTipo = jsonObject.cdPneuTipo;
    this.noPneuTipo = jsonObject.noPneuTipo;
  }

  toJson = () => JSON.stringify(this);
}
