import React, { useState, useEffect, useRef } from 'react';
import { getDetalheGrupoLinhas } from 'core/services/TEL';
import { Theme, BootstrapSizes } from 'ui/Helpers/utils';
import { Button, Loader, Modal, GridView, Textbox } from 'ui/components';
import ModalAtivarBloquearLinha from './modalAtivarBloquearLinha';

export default function ModalDetalheGrupoLinha(props) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const gridView = useRef();
  const [ativarBloquearLinha, setAtivarBloquearLinha] = useState({});
  const [modalShow, setModalShow] = useState({});
  const {
    detalheGrupoLinhas: {
      id,
      nome,
      contrato,
      cotaDadosNaoAtribuidasFormatado,
      cotaDadosFormatado,
      percentualConsumoCota,
      consumoDadosFormatado,
      totalLinhas,
    },
  } = props;

  useEffect(() => {
    async function buscarDetalheGrupoLinhas() {
      if (id) {
        setShow(true);
        setLoading(true);
        const { detalheLinhas } = await getDetalheGrupoLinhas({
          NomeGrupo: nome,
          Conta: contrato,
        });
        if (gridView && gridView.current)
          gridView.current.setDataSource(detalheLinhas);

        setLoading(false);
      } else setShow(false);
    }
    buscarDetalheGrupoLinhas();
  }, [contrato, nome, id]);

  const onAtivarBloquear = async (e) => {
    setModalShow({ ...modalShow, enviarAtivaBloqueiaLinha: true });
    setAtivarBloquearLinha(e);
  };

  const columns = [
    { key: 'linhaFormatada', title: 'Linha' },
    { key: 'consumoDadosFormatado', title: 'Consumo de Dados' },
    { key: 'consumoVozFormatado', title: 'Consumo de Voz' },
    { key: 'cotaDadosFormatado', title: 'Cota de Dados' },
    { key: 'percentualConsumoCota', title: 'Percentual de Consumo da Cota' },
    { key: 'bloqueadoDados', title: 'Status Dados' },
    { key: 'bloqueadoVoz', title: 'Status Voz' },
    { key: 'bloqueadoSMS', title: 'Status SMS' },
    {
      key: 'id',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onAtivarBloquear(e),
      theme: Theme.Info,
      icon: 'tasks',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Ativar / Bloquear Dados',
      tooltipDirection: 'bottom',
    },
  ];
  const { onClose } = props;
  return (
    <Modal
      show={show}
      size={BootstrapSizes.ExtraLarge}
      title='Detalhe Grupo Linha'
      onClose={onClose}
    >
      <Modal.Body className='bg-light'>
        <div className='row mb-1'>
          <div className='row mb-3'>
            <div className='col-4'>
              <Textbox text={contrato} label='Contrato' />
            </div>
            <div className='col-4'>
              <Textbox text={nome} label='Grupo' />
            </div>
            <div className='col-4'>
              <Textbox text={totalLinhas} label='Total de Linhas' />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-3'>
              <Textbox text={cotaDadosFormatado} label='Cota de Dados' />
            </div>
            <div className='col-3'>
              <Textbox text={consumoDadosFormatado} label='Consumo de Dados' />
            </div>
            <div className='col-3'>
              <Textbox
                text={percentualConsumoCota}
                label='Percentual de Consumo da Cota'
              />
            </div>
            <div className='col-3'>
              <Textbox
                text={cotaDadosNaoAtribuidasFormatado}
                label='Cota de Dados Não Atribuida'
              />
            </div>
          </div>
        </div>
        <Loader loading={loading} />
        <div className='row mt-3'>
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              canControlVisibility
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          theme={Theme.Primary}
          icon='times'
          text='Fechar'
          onClick={onClose}
        />
      </Modal.Footer>
      <ModalAtivarBloquearLinha
        ativarBloquearLinha={ativarBloquearLinha}
        show={modalShow.enviarAtivaBloqueiaLinha}
        onClose={() =>
          setModalShow({ ...modalShow, enviarAtivaBloqueiaLinha: false })
        }
      />
    </Modal>
  );
}
