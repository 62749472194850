import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getEstoqueTipoPagined,
  deleteAllEstoqueTipo,
  printEstoqueTipo,
  getEstoqueTipoAutoComplete,
} from 'core/services/EST/estoqueTipo';
import {
  CSDSelPage,
  GridView,
  Panel,
  Textbox,
  Autocomplete,
} from 'ui/components';

const columns = [
  {
    key: 'nrSeqEstoqueTipo',
    type: GridView.ColumnTypes.Checkbox,
  },
  { key: 'noEstoqueTipo', title: 'Descrição' },
  { key: 'cdEstoqueTipo', title: 'Cód. Estoque Tipo' },

  { key: 'flgContaMovimento', title: 'Conta Movimento', sortable: false },
  { key: 'flgLancamentoManual', title: 'Lançamento Manual', sortable: false },
  { key: 'flgServico', title: 'Serviço', sortable: false },
  { key: 'noSinal', title: 'Sinal', sortable: false },
  { key: 'noPaginaWeb', title: 'Parametros Página', visible: false },
  { key: 'cdTransacao', title: 'Cód. Transação' },
];

export default function EstoqueTipo({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    if (filtros.nrSeqEstoqueTipo) {
      params = {
        ...params,
        nrSeqEstoqueTipo: filtros.nrSeqEstoqueTipo,
        noEstoqueTipo: filtros.noEstoqueTipo,
        noSinal: filtros.noSinal || null,
        cdEstoqueTipo: filtros.cdEstoqueTipo || null,
      };
    }
    const {
      message: msg,
      status,
      data,
      pagination,
    } = await getEstoqueTipoPagined(params ?? filtros);

    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getEstoqueTipoPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'EstoqueTipo/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqRequisacaoMovEstoque = sourceRow.find(
      (e) => e.key === 'nrSeqEstoqueTipo'
    );
    onOpenMaintenance(nrSeqRequisacaoMovEstoque.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printEstoqueTipo(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllEstoqueTipo(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchEstoqueTipo = async (e) => {
    const {
      status,
      message: msg,
      data: estoqueTipo,
    } = await getEstoqueTipoAutoComplete({ noEstoqueTipo: e });
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    return estoqueTipo;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção Tipo de Movimento de Estoque'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col'>
          <Panel>
            <Panel.Header
              icon={['fas', 'user']}
              title='Filtros'
              theme={Theme.Primary}
            />
            <Panel.Body>
              <div className='row mb-3 mt-3'>
                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Estoque Tipo'
                    searchDataSource={onSearchEstoqueTipo}
                    selectedItem={filtros.estoqueTipo}
                    onSelectItem={(estoqueTipo) => {
                      setFiltros({
                        ...filtros,
                        estoqueTipo,
                        nrSeqEstoqueTipo: estoqueTipo.nrSeqEstoqueTipo,
                      });
                    }}
                    dataSourceTextProperty='noEstoqueTipo'
                  />
                </div>

                <div className='col-4 mb-3'>
                  <Textbox
                    maxLength={80}
                    label='Cód. Estoque Tipo Movimento'
                    mask={MaskTypes.Integer}
                    text={filtros.cdEstoqueTipo}
                    onChangedValue={(cdEstoqueTipo) =>
                      setFiltros({
                        ...filtros,
                        cdEstoqueTipo,
                      })
                    }
                  />
                </div>
                <div className='col-4 mb-3'>
                  <Textbox
                    maxLength={80}
                    label='Sinal'
                    text={filtros.noSinal}
                    onChangedValue={(noSinal) =>
                      setFiltros({
                        ...filtros,
                        noSinal,
                      })
                    }
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            canControlVisibility
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
