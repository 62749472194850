import React, { useState, useRef } from 'react';
import {
  CSDSelPage,
  GridView,
  Textbox,
  RadioButton,
  Autocomplete,
} from 'ui/components';
import { PageTypes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import {
  ColumnTypes,
  Theme,
  ResponseStatus,
  ColumnDataTypes,
  BootstrapSizes,
} from 'ui/Helpers/enums.ts';
import { getUsuarioPagined, deleteAllUsuario } from 'core/services/SEG/usuario';
import { getPerfilAutoComplete } from 'core/services/SEG/perfil';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';

export interface IUsuarioFilters {
  noPessoa?: string;
  flgExcel?: boolean;
  SortBy?: number;
  totalByPage?: number;
  page?: any;
}

export default function Usuario({ transaction, onOpenPage, isActive }: Page) {
  const gridView = useRef<any>();

  const [filtros, setFiltros] = useState<IUsuarioFilters>({
    noStatus: 'A',
    noStatusPerfil: 'S',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const columns: Array<GridviewColumns> = [
    { key: 'nrSeqPessoaUsr', type: ColumnTypes.Checkbox },
    { key: 'cdUsuario', title: 'Código' },
    { key: 'noPessoa', title: 'Nome', sortKey: 'noPessoa' },
    { key: 'noLogin', title: 'Login', sortKey: 'noLogin' },
    {
      key: 'nrTentativaAccesso',
      title: 'Tentativa Acesso',
      sortKey: 'nrTentativaAccesso',
    },
    {
      key: 'dtUltimaTentativaAcesso',
      title: 'Ultimo Acesso',
      format: ColumnDataTypes.Date,
      sortKey: 'dtUltimaTentativaAcesso',
    },
  ];

  const Status = [
    { text: 'Todos', value: 'T' },
    { text: 'Ativos', value: 'A' },
    { text: 'Inativos', value: 'I' },
  ];

  const Perfil = [
    { text: 'Sim', value: 'S' },
    { text: 'Não', value: 'N' },
  ];

  const search = async (params = null): Promise<void> => {
    setLoading(true);

    const { data, pagination } = await getUsuarioPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onOpenMaintenance = (registryKey = null): void => {
    onOpenPage(
      transaction.noTransacao,
      'Usuario/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onColumnSort = async (SortBy: number): Promise<void> => {
    setFiltros({ ...filtros, SortBy });
    await search();
  };

  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    setFiltros({ ...filtros, totalByPage });
    await search();
  };

  const onPageChange = async (page: any): Promise<void> => {
    setFiltros({ ...filtros, page });
    await search();
  };

  const onRowDoubleClick = (sourceRow: any): void => {
    const nrSeqPessoaUsr = sourceRow.find(
      (e: any) => e.key === 'nrSeqPessoaUsr'
    );

    onOpenMaintenance(nrSeqPessoaUsr.value);
  };

  const onSearchEmpresaUsuario = async (e: any) => {
    const { empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    return empresas;
  };

  const onSearchPerfil = async (e: any) => {
    const { perfis } = await getPerfilAutoComplete({ noPerfil: e });

    return perfis;
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllUsuario(
        selecteds.map((row: any) => row[0].value)
      );
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      await search();
    } else {
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Usuário'
      loading={loading}
      onSearch={search}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      {/* @ts-expect-error */}
      <div className='row mb-3'>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox
            label='Códigos'
            text={filtros.cdUsuario}
            maxLength={200}
            onChangedValue={(cdUsuario: string) =>
              setFiltros({ ...filtros, cdUsuario })
            }
          />
        </div>
        <div className='col-5'>
          {/* @ts-expect-error */}
          <Textbox
            label='Nome/ Razão Social'
            text={filtros.noPessoa}
            maxLength={200}
            onChangedValue={(noPessoa: string) =>
              setFiltros({ ...filtros, noPessoa })
            }
          />
        </div>
        <div className='col-3'>
          {/* @ts-expect-error */}
          <Textbox
            label='Login'
            text={filtros.noLogin}
            maxLength={200}
            onChangedValue={(noLogin: string) =>
              setFiltros({ ...filtros, noLogin })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-auto'>
          <RadioButton
            outline
            label='Usuário com Perfil'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={Perfil}
            selectedButton={filtros.noStatusPerfil}
            onChange={(perfil) =>
              setFiltros({
                ...filtros,
                noStatusPerfil: perfil,
              })
            }
          />
        </div>
        {filtros.noStatusPerfil === 'S' && (
          <div className='col-4'>
            <Autocomplete
              label='Empresa'
              searchDataSource={onSearchEmpresaUsuario}
              selectedItem={filtros.empresa}
              onSelectItem={(empresa) => {
                setFiltros({
                  ...filtros,
                  empresa,
                  nrSeqEmpresaDefault: empresa.nrSeqEmpresa,
                });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
        )}
        {filtros.noStatusPerfil === 'S' && (
          <div className='col-4'>
            <Autocomplete
              label='Perfil'
              searchDataSource={onSearchPerfil}
              selectedItem={filtros.perfil}
              onSelectItem={(perfil) => {
                setFiltros({
                  ...filtros,
                  perfil,
                  nrSeqPerfilDeAcesso: perfil.nrSeqPerfil,
                });
              }}
              dataSourceTextProperty='noPerfil'
            />
          </div>
        )}
        <div className='col-auto'>
          <RadioButton
            outline
            label='Status'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={Status}
            selectedButton={filtros.noStatus}
            onChange={(status) =>
              setFiltros({
                ...filtros,
                noStatus: status,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            onColumnSort={onColumnSort}
            enableExcelExport
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
