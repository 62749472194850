import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getAreaChamadaPagined,
  printAreaChamada,
  deleteAllAreaChamada,
} from 'core/services/TEL/areaChamada';
import { CSDSelPage, GridView, Panel, Textbox, Switch } from 'ui/components';

const columns = [
  { key: 'nrSeqAreaChamada', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdAreaChamada', title: 'Código' },
  { key: 'noAreaChamada', title: 'Área Chamada' },
  { key: 'flgVc1', title: 'VC1' },
  { key: 'flgVc2', title: 'VC2' },
  { key: 'flgVc3', title: 'VC3' },
];

export default function AreaChamada({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getAreaChamadaPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getAreaChamadaPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'AreaChamada/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqAreaChamada = sourceRow.find(
      (e) => e.key === 'nrSeqAreaChamada'
    );
    onOpenMaintenance(nrSeqAreaChamada.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printAreaChamada(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllAreaChamada(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Área Chamada'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <Panel>
        <Panel.Header
          icon={['fas', 'user']}
          title='Filtros'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col-6'>
              <Textbox
                maxLength={20}
                label='Área Chamada'
                text={filtros.noAreaChamada}
                onChangedValue={(noAreaChamada) =>
                  setFiltros({
                    ...filtros,
                    noAreaChamada,
                  })
                }
              />
            </div>
            <div className='col-1 mb-3'>
              <Switch
                formControl
                label='VC1'
                checked={filtros.flgVc1}
                onChange={(flgVc1) => setFiltros({ ...filtros, flgVc1 })}
              />
            </div>
            <div className='col-1 mb-3'>
              <Switch
                formControl
                label='VC2'
                checked={filtros.flgVc2}
                onChange={(flgVc2) => setFiltros({ ...filtros, flgVc2 })}
              />
            </div>
            <div className='col-1 mb-3'>
              <Switch
                formControl
                label='VC3'
                checked={filtros.flgVc3}
                onChange={(flgVc3) => setFiltros({ ...filtros, flgVc3 })}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div className='row'>
        <div className='col'>
          <GridView
            enableExcelExport
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
