import qs from 'qs';
import Pagination from 'core/models/pagination';
import AdjudicacaoIcms from 'core/models/VEN/adjudicacaoIcms';
import api from '../api';

const baseURL = `/VEN/AdjudicacaoIcms`;

export const getAdjudicacaoIcmsList = async (filters) => {
  const response = await api.get(`/$AdjudicacaoIcms/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AdjudicacaoIcms(json)) ?? [],
  };
};

export const getAdjudicacaoIcmsPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AdjudicacaoIcms(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getAdjudicacaoIcms = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new AdjudicacaoIcms(value) : {};
};

export const saveAdjudicacaoIcms = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const adjudicate = async (data) => {
  const response = await api.post(`${baseURL}/Adjudicate`, data);
  return response.data ?? {};
};

export const deleteAdjudicacaoIcms = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printAdjudicacaoIcms = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelAdjudicacaoIcms = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllAdjudicacaoIcms = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
