import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { getManutencaoChecklistFrotaPagined } from 'core/services/FRO/manutencaoChecklistFrota';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getChecklistFrotaAutoComplete } from 'core/services/FRO/checklistFrota';
import {
  CSDSelPage,
  GridView,
  Panel,
  Textbox,
  Autocomplete,
  DatePicker,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';

const columns = [
  {
    key: 'nrSeqManutencaoChecklist',
    type: GridView.ColumnTypes.Checkbox,
    visible: false,
  },
  { key: 'checklistFrota.noChecklistFrota', title: 'Checklist' },
  {
    key: 'cdManutencaoChecklist',
    title: 'Codigo',
    sortBy: 'cdManutencaoChecklist',
  },
  { key: 'nrSeqChecklistFrota', title: 'NrSeqChecklistFrota', visible: false },
  {
    key: 'dtChecklist',
    title: 'Data',
    format: GridView.DataTypes.Date,
    sortBy: 'dtChecklist',
  },
  { key: 'placas', title: 'Placas', sortBy: 'Placas' },
  { key: 'usuario.noPessoa', title: 'Usuario', sortKey: 'noPessoa' },
  { key: 'noStatus', title: 'Status' },
  { key: 'nrSeqPessoaMot', title: 'NrSeqPessoaMot', visible: false },
  { key: 'dtCad', title: 'DtCad', visible: false },
  {
    key: 'nrSeqManutencaoVeiculo',
    title: 'NrSeqManutencaoVeiculo',
    visible: false,
  },
  { key: 'dtExecucao', title: 'DtExecucao', visible: false },
  { key: 'obs', title: 'Obs', visible: false },
  { key: 'dtFechamento', title: 'DtFechamento', visible: false },
  { key: 'nrSeqUsuario', title: 'NrSeqUsuario', visible: false },
  { key: 'dtSaidaChecklist', title: 'DtSaidaChecklist', visible: false },
  {
    key: 'nrSeqManutencaoVeiculoOs',
    title: 'NrSeqManutencaoVeiculoOs',
    visible: false,
  },
];

export default function ManutencaoChecklistFrota({
  transaction,
  onOpenPage,
  isActive,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);
    const { data, pagination } = await getManutencaoChecklistFrotaPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ManutencaoChecklistFrota/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqManutencaoChecklist = sourceRow.find(
      (e) => e.key === 'nrSeqManutencaoChecklist'
    );
    onOpenMaintenance(nrSeqManutencaoChecklist.value);
  };

  const onSearchVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };
  const onSearchChecklistFrota = async (e) => {
    const {
      status,
      message: msg,
      checklistFrotas,
    } = await getChecklistFrotaAutoComplete({ noChecklistFrota: e });
    if (msg) onSetMessage(status, msg);
    return checklistFrotas;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de ManutencaoChecklistFrota'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <Panel className='mb-3'>
        <Panel.Header title='Filtros' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row'>
            <div className='col-3 mb-3'>
              <Autocomplete
                label='Checklist'
                searchDataSource={onSearchChecklistFrota}
                selectedItem={filtros.checklistFrota}
                onSelectItem={(checklistFrota) => {
                  setFiltros({
                    ...filtros,
                    checklistFrota,
                    nrSeqChecklistFrota: checklistFrota.nrSeqChecklistFrota,
                  });
                }}
                dataSourceTextProperty='noChecklistFrota'
              />
            </div>
            <div className='col-2 mb-3'>
              <Textbox
                required
                label='Codigo'
                text={filtros.cdManutencaoChecklist}
                mask={MaskTypes.Integer}
                onChangedValue={(cdManutencaoChecklist) =>
                  setFiltros({ ...filtros, cdManutencaoChecklist })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <Autocomplete
                label='Placa'
                searchDataSource={onSearchVeiculo}
                selectedItem={filtros.veiculo}
                onSelectItem={(veiculo) => {
                  setFiltros({
                    ...filtros,
                    veiculo,
                    nrSeqVeiculo: veiculo.nrSeqVeiculo,
                  });
                }}
                dataSourceTextProperty='placa'
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Data Inicial'
                text={filtros.dtCheckListIni}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtCheckListIni) => {
                  setFiltros({ ...filtros, dtCheckListIni });
                }}
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Data Final'
                text={filtros.dtCheckListFim}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtCheckListFim) => {
                  setFiltros({ ...filtros, dtCheckListFim });
                }}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            enableExcelExport
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
