import Pagination from 'core/models/pagination';
import { TituloReceberOcorrencia } from 'core/models/FIN';
import api from '../api';

const base = 'FIN/TituloReceberOcorrencia';

export const getTituloReceberOcorrenciaPagined = async (filters) => {
  const response = await api.get(`${base}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloReceberOcorrencia(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getTituloReceberOcorrenciaList = async (filters) => {
  const response = await api.get(`${base}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloReceberOcorrencia(json)) ?? [],
  };
};

export const getAdicionaOcorrencia = async (filters) => {
  const response = await api.get(`${base}/AddOccurrence`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloReceberOcorrencia(json)) ?? [],
  };
};
export const obterOcorrenciasPorTitulo = async (filters) => {
  const response = await api.get(`${base}/ObterOcorrenciasPorTitulo`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    ocorrencias: value?.map((json) => new TituloReceberOcorrencia(json)) ?? [],
  };
};

export const salvarOcorrencias = async (params) => {
  const response = await api.post(`${base}/SalvarOcorrencias`, params);
  return response.data ?? {};
};

export const saveTituloReceberOcorrencias = async (data) => {
  const response = await api.post(`${base}/`, data);
  return response.data ?? {};
};
