export default class Pais {
  constructor(jsonObject = {}) {
    this.cdPais = jsonObject.cdPais;
    this.nrSeqPais = jsonObject.nrSeqPais;
    this.noPais = jsonObject.noPais;
    this.cdIbge = jsonObject.cdIbge;
  }

  toJson = () => JSON.stringify(this);
}
