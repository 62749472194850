import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  Switch,
  GridView,
  Button,
  Panel,
  RadioButton,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getVeiculoModelo,
  saveVeiculoModelo,
  deleteVeiculoModelo,
} from 'core/services/FRO/veiculoModelo';
import { getVeiculoMarcaAutoComplete } from 'core/services/FRO/veiculoMarca';
import { getEixoAutoComplete } from 'core/services/FRO/eixo';
import { getModeloManutencaoPreventivaPagined } from 'core/services/FRO/modeloManutencaoPreventiva';
import VeiculoModeloEixo from 'core/models/FRO/veiculoModeloEixo';

const columnsManutPreventiva = [
  { key: 'nrSeqManutencaoPreventiva', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdManutencaoPreventiva', title: 'Código', sortable: false },
  { key: 'noManutencaoPreventiva', title: 'Nome', sortable: false },
  {
    key: 'nrSeqTipoIntervalo',
    title: 'NrSeqTipoIntervalo',
    visible: false,
    sortable: false,
  },
  {
    key: 'valor',
    title: 'Valor',
    format: GridView.DataTypes.Decimal,
    sortable: false,
  },
];

export default function VeiculoModelotipoOdometroItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({ flgAtivo: true, noTipoOdometro: 'O' });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const gridView = useRef();
  const gridViewManutPreventiva = useRef();

  const onClickRemoveEixo = (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);
  };

  const onNew = () => {
    setData({ flgAtivo: true, noTipoOdometro: 'O' });
  };

  const columns = [
    { key: 'nrSeqVeiculoModelo', type: GridView.ColumnTypes.Checkbox },
    { key: 'eixo.noEixo', title: 'Eixo', sortable: false },
    { key: 'nrOrdem', title: 'Ordem', sortable: false },
    {
      key: 'nrSeqVeiculoModelo',
      title: 'Remover',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveEixo(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const odometro = [
    {
      text: 'Odômetro ',
      value: 'O',
    },
    {
      text: 'Horímetro',
      value: 'H',
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const veiculoModelo = await getVeiculoModelo(registryKey);

      setData(veiculoModelo);

      if (gridView && gridView.current)
        gridView.current.setDataSource(veiculoModelo.veiculoModeloEixoS);

      const {
        data: modeloManutPreventiva,
        pagination,
      } = await getModeloManutencaoPreventivaPagined({
        nrSeqVeiculoModelo: veiculoModelo.nrSeqVeiculoModelo,
      });

      if (gridViewManutPreventiva && gridViewManutPreventiva.current)
        gridViewManutPreventiva.current.setDataSource(
          modeloManutPreventiva,
          pagination
        );

      setLoading(false);
    } else {
      onNew();
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const save = async () => {
    setLoading(true);

    const eixoS = gridView.current ? gridView.current.getDataSource() : [];

    const obj = {
      nrSeqVeiculoModelo: data.nrSeqVeiculoModelo,
      noVeiculoModelo: data.noVeiculoModelo,
      cdVeiculoModelo: data.cdVeiculoModelo,
      nrSeqVeiculoMarca: data.nrSeqVeiculoMarca,
      vlrTara: data.vlrTara,
      flgAtivo: data.flgAtivo,
      veiculoMarca: data.veiculoMarca,
      veiculoModeloEixos: eixoS,
      noTipoOdometro: data.noTipoOdometro,
    };

    if (
      data.cdVeiculoModelo &&
      data.noVeiculoModelo &&
      data.nrSeqVeiculoMarca
    ) {
      const { status, message: msg } = await saveVeiculoModelo(obj);

      if (status === ResponseStatus.Success) formSubmit.current.reset();

      setData(status === ResponseStatus.Success ? {} : data);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    } else {
      onSetMessage(false, 'Preencha os campos obrigatórios');
    }

    setLoading(false);
  };

  const onAdicionaEixo = async () => {
    const obj = new VeiculoModeloEixo({
      nrOrdem: data.nrOrdem,
      nrSeqEixo: data.eixo.nrSeqEixo,
      eixo: data.eixo,
    });
    const list = gridView.current.getDataSource() ?? [];
    list.push(obj);
    if (gridView && gridView.current) gridView.current.setDataSource(list);
  };

  const onExcluir = async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const { status, message: msg } = await deleteVeiculoModelo(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchVeiculoMarca = async (e) => {
    const {
      status,
      message: msg,
      data: veiculoMarcas,
    } = await getVeiculoMarcaAutoComplete({ noVeiculoMarca: e });
    if (msg) onSetMessage(status, msg);
    return veiculoMarcas;
  };

  const onSearchEixo = async (e) => {
    const { status, message: msg, data: eixoSS } = await getEixoAutoComplete({
      noEixo: e,
    });
    if (msg) onSetMessage(status, msg);
    return eixoSS;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Veículo Modelo'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      onDelete={data?.nrSeqVeiculoModelo > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-3'>
          <Textbox
            maxLength={10}
            label='Código'
            text={data.cdVeiculoModelo}
            onChangedValue={(cdVeiculoModelo) =>
              setData({ ...data, cdVeiculoModelo })
            }
          />
        </div>
        <div className='col-3'>
          <Textbox
            required
            maxLength={60}
            label='Nome'
            text={data.noVeiculoModelo}
            onChangedValue={(noVeiculoModelo) =>
              setData({ ...data, noVeiculoModelo })
            }
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            required
            label='Marca'
            searchDataSource={onSearchVeiculoMarca}
            selectedItem={data.veiculoMarca}
            onSelectItem={(veiculoMarca) => {
              setData({
                ...data,
                veiculoMarca,
                nrSeqVeiculoMarca: veiculoMarca.nrSeqVeiculoMarca,
              });
            }}
            dataSourceTextProperty='noVeiculoMarca'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-3'>
          <Textbox
            label='Valor Tara'
            text={data.vlrTara}
            mask={MaskTypes.Decimal}
            onChangedValue={(vlrTara) => setData({ ...data, vlrTara })}
          />
        </div>
        <div className='col-3 mb-4'>
          <RadioButton
            label='Tipo de odômetro'
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={data.noTipoOdometro}
            buttons={odometro}
            onChange={(noTipoOdometro) => {
              setData({
                ...data,
                noTipoOdometro,
              });
            }}
          />
        </div>
        <div className='col-3'>
          <Switch
            formControl
            label='Ativo'
            checked={data.flgAtivo}
            onChange={(flgAtivo) => setData({ ...data, flgAtivo })}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Eixos' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-5'>
                <Autocomplete
                  label='Eixo'
                  searchDataSource={onSearchEixo}
                  selectedItem={data.eixo}
                  onSelectItem={(eixo) => {
                    setData({
                      ...data,
                      eixo,
                      nrSeqEixo: eixo.nrSeqEixo,
                    });
                  }}
                  dataSourceTextProperty='noEixo'
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Ordem'
                  text={data.nrOrdem}
                  mask={MaskTypes.Integer}
                  onChangedValue={(nrOrdem) => setData({ ...data, nrOrdem })}
                />
              </div>
              <div className='col d-flex align-items-end'>
                <Button
                  icon='plus'
                  text='Adicionar'
                  size={BootstrapSizes.Medium}
                  theme={Theme.Success}
                  template={Button.Templates.Default}
                  onClick={onAdicionaEixo}
                  outline
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridView}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      {data.nrSeqVeiculoModelo && (
        <>
          <div className='row mb-3'>
            <Panel>
              <Panel.Header
                title='Manutenções Preventivas'
                theme={Theme.Primary}
              />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col'>
                    <GridView
                      ref={gridViewManutPreventiva}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columnsManutPreventiva}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </>
      )}
    </CSDManutPage>
  );
}
