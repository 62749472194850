import React, { useState, useEffect } from 'react';

import { getEnderecoByPessoa, getEndereco } from 'core/services/SEG/endereco';
import { getCidadeAutoComplete, getCep } from 'core/services/SEG/cidade';

import { Textbox, Autocomplete, Button, RadioButton } from 'ui/components';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { getPessoa } from 'core/services/SEG';

export default function Address({
  nrSeqPessoa,
  nrSeqEndereco,
  onSetLoading,
  onChangeAddress,
  title,
  readOnly,
}) {
  const [data, setData] = useState({ tipo: 2 });
  const [enderecoFixo, setEnderecoFixo] = useState({});

  const load = async () => {
    if (nrSeqEndereco) {
      if (nrSeqEndereco > 0) {
        const endereco = await getEndereco(nrSeqEndereco);
        const pessoa = await getPessoa(nrSeqPessoa);
        if (endereco.nrSeqEndereco === pessoa?.pessoaEndereco?.nrSeqEndereco) {
          setData({ ...endereco, tipo: 1 });
        } else {
          enderecoFixo.nrSeqEndereco = nrSeqEndereco;
          setData({ ...endereco, tipo: 2 });
          setEnderecoFixo({ ...enderecoFixo });
        }
      } else {
        setData({ tipo: 2 });
      }
    } else {
      const pessoa = await getPessoa(nrSeqPessoa);
      if (nrSeqPessoa > 0) {
        const endereco = await getEndereco(pessoa.pessoaEndereco.nrSeqEndereco);
        setData({ ...endereco, tipo: 1 });
      } else {
        setData({ tipo: 1 });
      }
    }
  };

  const onSearchCep = async () => {
    onSetLoading(true);
    if (data.cep) {
      if (data.cep.length > 4) {
        const endereco = await getCep({
          cep: data.cep,
        });

        if (enderecoFixo.nrSeqEndereco > 0) {
          endereco.nrSeqEndereco = enderecoFixo.nrSeqEndereco;
        }

        setData({ ...endereco, tipo: 2 });
        onChangeAddress({ ...endereco, tipo: 2 });
      }
    }
    onSetLoading(false);
  };

  const onSearchCidade = async (e) => {
    const { cidades } = await getCidadeAutoComplete({
      noCidade: e,
    });
    return cidades;
  };

  const setCopiaEnderecoCliente = async (tipo) => {
    if (tipo === 1) {
      const endereco = await getEnderecoByPessoa(nrSeqPessoa);
      setData({ ...data, ...endereco, tipo: 1 });

      onChangeAddress({ ...data, ...endereco, tipo: 1 });
    }

    if (tipo === 2) {
      if (enderecoFixo.nrSeqEndereco > 0) {
        data.nrSeqEndereco = enderecoFixo.nrSeqEndereco;

        setData({
          ...data,
          tipo: 2,
          cep: null,
          noRua: null,
          numero: null,
          complemento: null,
          bairro: null,
          cidade: null,
          estado: null,
          pais: null,
        });
        onChangeAddress({
          ...data,
          tipo: 2,
          cep: null,
          noRua: null,
          numero: null,
          complemento: null,
          bairro: null,
          cidade: null,
          estado: null,
          pais: null,
        });
      } else {
        setData({
          ...data,
          tipo: 2,
          cep: null,
          noRua: null,
          numero: null,
          complemento: null,
          bairro: null,
          cidade: null,
          estado: null,
          pais: null,
          nrSeqEndereco: null,
        });
        onChangeAddress({
          ...data,
          tipo: 2,
          cep: null,
          noRua: null,
          numero: null,
          complemento: null,
          bairro: null,
          cidade: null,
          estado: null,
          pais: null,
          nrSeqEndereco: null,
        });
      }
    }
  };
  useEffect(() => {
    load();
  }, [nrSeqPessoa, nrSeqEndereco]);

  const tipoEnderecos = [
    {
      text: 'Usar endereço do cliente',
      value: 1,
    },
    {
      text: 'Endereço Manual',
      value: 2,
    },
  ];

  const hasTitle = title ?? false;
  return (
    <div className='row mb-3'>
      <div className='row mb-3'>
        <div className='col'>
          <div className='card'>
            {hasTitle && <h6 className='card-title'>{title}</h6>}

            <div className='card-body'>
              {!readOnly && (
                <div className='row mb-3'>
                  <div className='col-4'>
                    <RadioButton
                      outline
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      selectedButton={data.tipo}
                      onChange={(tipo) => {
                        onSetLoading(true);
                        setCopiaEnderecoCliente(tipo);
                        onSetLoading(false);
                      }}
                      buttons={tipoEnderecos}
                    />
                  </div>
                </div>
              )}
              <div className='row mb-3'>
                {data.tipo === 1 && (
                  <div className='col-2'>
                    <Textbox
                      label='Cep'
                      readOnly
                      text={data.cep}
                      maxLength={80}
                      onChangedValue={(cep) => {
                        setData(
                          {
                            ...data,
                            cep,
                          },
                          onChangeAddress({ ...data, cep })
                        );
                      }}
                    />
                  </div>
                )}
                {data.tipo === 2 && (
                  <div className='col-2'>
                    <Textbox
                      label='Cep'
                      text={data.cep}
                      maxLength={80}
                      onChangedValue={(cep) => {
                        setData(
                          {
                            ...data,
                            cep,
                          },
                          onChangeAddress({ ...data, cep })
                        );
                      }}
                    />
                  </div>
                )}
                {data.tipo === 2 && (
                  <div className='col-1 mt-3'>
                    <Button
                      outline
                      theme={Theme.Primary}
                      template={Button.Templates.Button}
                      icon='search'
                      tooltip='Pesquisar o endereço pelo CEP digitado.'
                      onClick={onSearchCep}
                    />
                  </div>
                )}
                {data.tipo === 1 && (
                  <div className='col-4'>
                    <Textbox
                      label='Endereço'
                      readOnly
                      text={data.noRua}
                      maxLength={80}
                      onChangedValue={(noRua) => {
                        setData(
                          {
                            ...data,
                            noRua,
                          },
                          onChangeAddress({ ...data, noRua })
                        );
                      }}
                    />
                  </div>
                )}
                {data.tipo === 2 && (
                  <div className='col-4'>
                    <Textbox
                      label='Endereço'
                      text={data.noRua}
                      maxLength={80}
                      onChangedValue={(noRua) => {
                        setData(
                          {
                            ...data,
                            noRua,
                          },
                          onChangeAddress({ ...data, noRua })
                        );
                      }}
                    />
                  </div>
                )}
                {data.tipo === 1 && (
                  <div className='col-2'>
                    <Textbox
                      label='Número'
                      readOnly
                      text={data.numero}
                      maxLength={20}
                      onChangedValue={(numero) => {
                        setData(
                          {
                            ...data,
                            numero,
                          },
                          onChangeAddress({ ...data, numero })
                        );
                      }}
                    />
                  </div>
                )}
                {data.tipo === 2 && (
                  <div className='col-2'>
                    <Textbox
                      label='Número'
                      text={data.numero}
                      maxLength={20}
                      onChangedValue={(numero) => {
                        setData(
                          {
                            ...data,
                            numero,
                          },
                          onChangeAddress({ ...data, numero })
                        );
                      }}
                    />
                  </div>
                )}
                {data.tipo === 1 && (
                  <div className='col-3'>
                    <Textbox
                      label='Complemento'
                      text={data.complemento}
                      readOnly
                      maxLength={40}
                      onChangedValue={(complemento) => {
                        setData(
                          {
                            ...data,
                            complemento,
                          },
                          onChangeAddress({ ...data, complemento })
                        );
                      }}
                    />
                  </div>
                )}
                {data.tipo === 2 && (
                  <div className='col-3'>
                    <Textbox
                      label='Complemento'
                      text={data.complemento}
                      maxLength={40}
                      onChangedValue={(complemento) => {
                        setData(
                          {
                            ...data,
                            complemento,
                          },
                          onChangeAddress({ ...data, complemento })
                        );
                      }}
                    />
                  </div>
                )}
              </div>

              <div className='row mb-3'>
                {data.tipo === 1 && (
                  <div className='col-3'>
                    <Textbox
                      label='Bairro'
                      text={data.bairro}
                      readOnly
                      maxLength={60}
                      onChangedValue={(bairro) => {
                        setData(
                          {
                            ...data,
                            bairro,
                          },
                          onChangeAddress({ ...data, bairro })
                        );
                      }}
                    />
                  </div>
                )}
                {data.tipo === 2 && (
                  <div className='col-3'>
                    <Textbox
                      label='Bairro'
                      text={data.bairro}
                      maxLength={60}
                      onChangedValue={(bairro) => {
                        setData(
                          {
                            ...data,
                            bairro,
                          },
                          onChangeAddress({ ...data, bairro })
                        );
                      }}
                    />
                  </div>
                )}
                {data.tipo === 1 && (
                  <div className='col-3'>
                    <Autocomplete
                      label='Cidade'
                      readOnly
                      searchDataSource={onSearchCidade}
                      selectedItem={data?.cidade}
                      onSelectItem={(cidade) => {
                        setData(
                          {
                            ...data,
                            cidade,
                            nrSeqCidade: cidade.nrSeqCidade,
                          },
                          onChangeAddress({
                            ...data,
                            cidade,
                            nrSeqCidade: cidade.nrSeqCidade,
                          })
                        );
                      }}
                      dataSourceTextProperty='noCidade'
                    />
                  </div>
                )}
                {data.tipo === 2 && (
                  <div className='col-3'>
                    <Autocomplete
                      label='Cidade'
                      searchDataSource={onSearchCidade}
                      selectedItem={data?.cidade}
                      onSelectItem={(cidade) => {
                        setData(
                          {
                            ...data,
                            cidade,
                            nrSeqCidade: cidade.nrSeqCidade,
                          },
                          onChangeAddress({
                            ...data,
                            cidade,
                            nrSeqCidade: cidade.nrSeqCidade,
                          })
                        );
                      }}
                      dataSourceTextProperty='noCidade'
                    />
                  </div>
                )}

                <div className='col-3'>
                  <Textbox
                    label='Estado'
                    text={data.cidade?.estado?.noEstado}
                    maxLength={80}
                    readOnly
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='País'
                    text={data?.cidade?.estado?.pais?.noPais}
                    maxLength={80}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
