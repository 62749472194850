import React, { useState, useEffect } from 'react';
import {
  CSDSelPage,
  Switch,
  ToolbarButtons,
  FontAwesomeIcon,
} from 'ui/components';
import { PageTypes, Theme, ResponseStatus } from 'ui/Helpers/utils';

import {
  getDashboardTransporte,
  saveDashboardTransporte,
} from 'core/services/FRO/dashboardTransporte';

import DashboardTransporteModel from 'core/models/FRO/dashboardTransporte';

export default function DashboardTransporte({
  transaction,
  isActive,
  onOpenPage,
}) {
  const [data, setData] = useState(new DashboardTransporteModel({}));
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async () => {
    setLoading(true);

    const {
      status,
      message: msg,
      data: dashboardTransporte,
    } = await getDashboardTransporte();

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) setData(dashboardTransporte);

    setLoading(false);
  };

  const save = async () => {
    setLoading(true);

    const { status, message: msg } = await saveDashboardTransporte(data);

    if (msg) onSetMessage(status, msg);

    setLoading(false);
  };

  useEffect(() => {
    search();
  }, []);

  return (
    <CSDSelPage
      title='Seleção Dashboard Frotas'
      transaction={transaction}
      isActive={isActive}
      loading={loading}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Salvar'
          icon={['far', 'save']}
          onClick={() => {
            save();
          }}
        />
        <ToolbarButtons.Button
          text='Abrir Gráficos'
          icon={['fas', 'chart-bar']}
          onClick={() => {
            onOpenPage(
              transaction.noTransacao,
              'DashboardTransporte/manutencao',
              PageTypes.Maintenence
            );
          }}
        />
      </ToolbarButtons>
      <div className='row'>
        <div
          className='row mb-3 justify-content-center m-auto'
          style={{ maxWidth: 515 }}
        >
          <div className='alert alert-primary' role='alert'>
            <FontAwesomeIcon icon='exclamation' className='mx-2' />
            <span>
              <b>
                Selecione os gráficos que deseja visualizar e salve a
                configuração.
              </b>
            </span>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-3'>
            <Switch
              label='Top 10 Clientes por Peso Carregado (TON)'
              checked={data.pesoCarregado}
              formControl
              onChange={(pesoCarregado) => {
                setData({ ...data, pesoCarregado });
              }}
            />
          </div>
          <div className='col-3'>
            <Switch
              label='Total de Peso'
              checked={data.totalPeso}
              formControl
              onChange={(totalPeso) => {
                setData({ ...data, totalPeso });
              }}
            />
          </div>
          <div className='col-3'>
            <Switch
              label='Número de Embarques'
              checked={data.numeroEmbarques}
              formControl
              onChange={(numeroEmbarques) => {
                setData({ ...data, numeroEmbarques });
              }}
            />
          </div>
          <div className='col-3'>
            <Switch
              label='Top 10 Faturamento Por Cliente'
              checked={data.faturamentoPorCliente}
              formControl
              onChange={(faturamentoPorCliente) => {
                setData({ ...data, faturamentoPorCliente });
              }}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-3'>
            <Switch
              label='Quantidade de Carregamentos no Mês'
              checked={data.carregamentoCteMes}
              formControl
              onChange={(carregamentoCteMes) => {
                setData({ ...data, carregamentoCteMes });
              }}
            />
          </div>
          <div className='col-3'>
            <Switch
              label='Total de Litros Abastecidos Por Posto'
              checked={data.totalLitros}
              formControl
              onChange={(totalLitros) => {
                setData({ ...data, totalLitros });
              }}
            />
          </div>
          <div className='col-3'>
            <Switch
              label='Peso Carregado P/ Unidade (TON)'
              checked={data.pesoMes}
              formControl
              onChange={(pesoMes) => {
                setData({ ...data, pesoMes });
              }}
            />
          </div>
          <div className='col-3'>
            <Switch
              label='Média do Valor Por Posto'
              checked={data.valorMedioPosto}
              formControl
              onChange={(valorMedioPosto) => {
                setData({ ...data, valorMedioPosto });
              }}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-3'>
            <Switch
              label='Quantidade de Conhecimentos por Estado de Entrega'
              checked={data.estadoEntrega}
              formControl
              onChange={(estadoEntrega) => {
                setData({ ...data, estadoEntrega });
              }}
            />
          </div>
          <div className='col-3'>
            <Switch
              label='Valor Total de Conhecimentos por Estado de Coleta'
              checked={data.valorEstadoColeta}
              formControl
              onChange={(valorEstadoColeta) => {
                setData({ ...data, valorEstadoColeta });
              }}
            />
          </div>
          <div className='col-3'>
            <Switch
              label='Valor Total de Conhecimetnos por Estado de Entrega'
              checked={data.valorEstadoEntrega}
              formControl
              onChange={(valorEstadoEntrega) => {
                setData({ ...data, valorEstadoEntrega });
              }}
            />
          </div>
          <div className='col-3'>
            <Switch
              label='Quantidade de Conhecimentos por Mês (No Ano)'
              checked={data.carregamentoCteAno}
              formControl
              onChange={(carregamentoCteAno) => {
                setData({ ...data, carregamentoCteAno });
              }}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-3'>
            <Switch
              label='Quantidade de Conhecimentos Por Estado de Coleta'
              checked={data.estadoColeta}
              formControl
              onChange={(estadoColeta) => {
                setData({ ...data, estadoColeta });
              }}
            />
          </div>
          <div className='col-3'>
            <Switch
              label='Peso Total de Conhecimentos por Estado de Entrega'
              checked={data.pesoEstadoEntrega}
              formControl
              onChange={(pesoEstadoEntrega) => {
                setData({ ...data, pesoEstadoEntrega });
              }}
            />
          </div>
        </div>
      </div>
    </CSDSelPage>
  );
}
