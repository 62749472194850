import React, { useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { getPedidoPagined, deleteAllPedido } from 'core/services/VEN/pedido';
import {
  CSDSelPage,
  GridView,
  DatePicker,
  Textbox,
  Autocomplete,
} from 'ui/components';

import { MaskTypes } from 'ui/Helpers/masks';
import { getVendedorAutoComplete } from 'core/services/VEN/vendedor';
import { getEmpresasUsuario } from 'core/services/SEG/empresa';
import { getClienteAutoCompleteSeller } from 'core/services/FIN/cliente';

const columns = [
  { key: 'nrSeqPedido', type: GridView.ColumnTypes.Checkbox },
  { key: 'dtPed', title: 'Data Pedido', format: GridView.DataTypes.Date },
  { key: 'noCliente', title: 'Cliente' },
  { key: 'nrPed', title: 'Número' },
  { key: 'nrPedRepresentante', title: 'Número Representante' },
  { key: 'vendedor.noPessoa', title: 'Vendedor' },
  { key: 'pedidoEtapa.noPedidoEtapa', title: 'Etapa' },
  { key: 'vlrPed', title: 'Valor', format: GridView.DataTypes.Money },
];

export default function PedidoVenda({ transaction, onOpenPage, isActive }) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getPedidoPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Pedido/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqPedido = sourceRow.find((e) => e.key === 'nrSeqPedido');
    onOpenMaintenance(nrSeqPedido.value);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllPedido(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchVendedor = async (e) => {
    const { status, message: msg, vendedores } = await getVendedorAutoComplete({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return vendedores;
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return empresas;
  };

  const onSearchCliente = async (e) => {
    const {
      status,
      message: msg,
      clientes,
    } = await getClienteAutoCompleteSeller({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return clientes;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Pedido'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Data Inicial'
            text={filtros.dtInicial}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtInicial) => setFiltros({ ...filtros, dtInicial })}
          />
        </div>

        <div className='col-2'>
          <DatePicker
            label='Data Final'
            text={filtros.dtFinal}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtFinal) => setFiltros({ ...filtros, dtFinal })}
          />
        </div>

        <div className='col-2'>
          <Textbox
            label='NF'
            text={filtros.nrNfv}
            maxLength={20}
            onChangedValue={(nrNfv) =>
              setFiltros({
                ...filtros,
                nrNfv,
              })
            }
          />
        </div>

        <div className='col-3'>
          <Textbox
            label='Ped. Sistema'
            text={filtros.nrPed}
            maxLength={20}
            onChangedValue={(nrPed) =>
              setFiltros({
                ...filtros,
                nrPed,
              })
            }
          />
        </div>

        <div className='col-3'>
          <Textbox
            label='Ped. Representante'
            text={filtros.nrPedRepresentante}
            maxLength={20}
            onChangedValue={(nrPedRepresentante) =>
              setFiltros({
                ...filtros,
                nrPedRepresentante,
              })
            }
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Cliente'
            searchDataSource={onSearchCliente}
            selectedItem={filtros.cliente}
            onSelectItem={(cliente) =>
              setFiltros({
                ...filtros,
                cliente,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-6'>
          <Autocomplete
            label='Empresa'
            searchDataSource={onSearchEmpresa}
            selectedItem={filtros.empresa}
            onSelectItem={(empresa) =>
              setFiltros({
                ...filtros,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Vendedor'
            searchDataSource={onSearchVendedor}
            selectedItem={filtros.vendedor}
            onSelectItem={(vendedor) =>
              setFiltros({
                ...filtros,
                vendedor,
                nrSeqPessoaVen: vendedor.nrSeqPessoa,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-12'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            sumFields
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
