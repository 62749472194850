export default class PedidoEtapa {
  constructor(jsonObject = {}) {
    this.nrSeqPedidoEtapa = jsonObject.nrSeqPedidoEtapa;
    this.noPedidoEtapa = jsonObject.noPedidoEtapa;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;

    this.flgItens = jsonObject.flgItens;
    this.flgParcelas = jsonObject.flgParcelas;
    this.flgTransporte = jsonObject.flgTransporte;
    this.flgDesconto = jsonObject.flgDesconto;
    this.flgAutorizaDesconto = jsonObject.flgAutorizaDesconto;
    this.flgGeraNota = jsonObject.flgGeraNota;
    this.flgCancelado = jsonObject.flgCancelado;
    this.flgPedidoEtapa = jsonObject.flgPedidoEtapa;
    this.flgConferenciaEstoque = jsonObject.flgConferenciaEstoque;
    this.nrSeqTipoPendenciaFinanceira = jsonObject.nrSeqTipoPendenciaFinanceira;

    this.nrOrdem = jsonObject.nrOrdem;
  }

  toJson = () => JSON.stringify(this);
}
