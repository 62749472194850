import React from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { getVendedorAutoComplete } from 'core/services/VEN/vendedor';

import { getPedidoPagined } from 'core/services/VEN/pedido';

import {
  Autocomplete,
  GridView,
  Loader,
  Notification,
  Panel,
  ToolbarButtons,
} from 'ui/components';

import ModalFaturar from './modalFaturar';

export default class Comissao extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filtros: {
        contratoAgrupado: true,
        filtroContratoServico: true,
        filtroPendenteFaturamento: true,
      },
    };
  }

  componentDidMount() {
    this.gridView = React.createRef();
  }

  onSearchVendedor = async (e) => {
    const { status, message, vendedores } = await getVendedorAutoComplete({
      noPessoa: e,
    });

    if (message)
      this.setState({
        message,
        messageTheme:
          status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return vendedores;
  };

  onSearch = async () => {
    const { filtros } = this.state;

    const { data, pagination } = await getPedidoPagined(filtros);

    if (this.gridView && this.gridView.current)
      this.gridView.current.setDataSource(data, pagination);

    this.setState({ loading: false });
  };

  onColumnSort = async (columnName) => {
    const { filtros } = this.state;
    filtros.SortBy = columnName;
    this.setState({ filtros });
    await this.onSearch();
  };

  onFaturarClick = (e) => {
    this.setState({ faturar: e });
  };

  onFaturarCloseClick = () => {
    this.setState({ faturar: null });
  };

  onSuccessFaturarContrato = async (message, messageTheme) => {
    await this.onSearch();

    this.setState({
      message,
      messageTheme,
      faturar: null,
    });
  };

  onOpenMaintenance = () => {
    const { transaction, onOpenPage } = this.props;

    onOpenPage(
      transaction.noTransacao,
      'Comissao/calcularComissao',
      PageTypes.Maintenence
    );
  };

  render() {
    const { filtros, loading, message, messageTheme, faturar } = this.state;

    const columns = [
      { key: 'nrSeqPedido', type: GridView.ColumnTypes.Checkbox },
      { key: 'dtPed', title: 'Data Pedido', format: GridView.DataTypes.Date },
      { key: 'noCliente', title: 'Cliente' },
      { key: 'nrPed', title: 'Número' },
      { key: 'nrPedRepresentante', title: 'Número Representante' },
      {
        key: 'vlrPed',
        title: 'Valor Pedido',
        format: GridView.DataTypes.Decimal,
      },
    ];

    const { className } = this.props;
    return (
      <>
        <Panel className={className}>
          <Loader loading={loading} />
          <Panel.Header title='Calcular Comissão'>
            <ToolbarButtons>
              <ToolbarButtons.Button
                text='Pesquisar'
                icon='search'
                onClick={this.onSearch}
              />
              <ToolbarButtons.Button
                text='Calcular Comissao'
                icon='calculator'
                onClick={this.onOpenMaintenance}
              />
            </ToolbarButtons>
          </Panel.Header>
          <Panel.Body>
            {message && (
              <div className='mb-3'>
                <Notification
                  message={message}
                  theme={messageTheme}
                  onClose={() => this.setState({ message: undefined })}
                />
              </div>
            )}

            <div className='row mb-3'>
              <div className='col'>
                <Autocomplete
                  label='Vendedor'
                  searchDataSource={this.onSearchVendedor}
                  selectedItem={filtros.vendedor}
                  onSelectItem={(vendedor) =>
                    this.setState({
                      filtros: {
                        ...filtros,
                        vendedor,
                        nrSeqPessoaVen: vendedor.nrSeqPessoa,
                      },
                    })
                  }
                  dataSourceTextProperty='noPessoa'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col'>
                <hr />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={this.gridView}
                  className='table-striped table-hover table-bordered table-sm'
                  enableExcelExport
                  dataColumns={columns}
                  onRowDoubleClick={this.onRowDoubleClick}
                  canControlVisibility
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
        {faturar && (
          <ModalFaturar
            contratoServico={faturar}
            onCloseClick={this.onFaturarCloseClick}
            onSuccessFaturarContrato={this.onSuccessFaturarContrato}
          />
        )}
      </>
    );
  }
}
