import { ImportNfsViaZip } from 'core/services/COP/nf';
import { getNfTipoAutoComplete } from 'core/services/COP/nfTipo';
import { getProdutoAutoComplete } from 'core/services/EST/produto';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import React, { useEffect, useRef, useState } from 'react';

import {
  Autocomplete,
  Button,
  FileUpload,
  Modal,
  Notification,
} from 'ui/components';
import {
  Theme,
  BootstrapSizes,
  ResponseStatus,
  MimeTypes,
} from 'ui/Helpers/utils';

export default function ModalImportarNFS(props) {
  const [messageState, setMessage] = useState(null);
  const gridView = useRef();
  const [importedFile, setImportedFile] = useState([]);
  const [data, setData] = useState({});

  const { show } = props;

  useEffect(() => {
    setImportedFile([]);
  }, [show]);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const salvar = async () => {
    if (data.nrSeqEmpresa === null || data.nrSeqEmpresa === undefined) {
      setMessage({
        message: 'Necessário informar a empresa para importar.',
        theme: Theme.Danger,
      });
      return;
    }

    if (data.nrSeqNftipo === null || data.nrSeqNftipo === undefined) {
      setMessage({
        message: 'Necessário informar o Tipo Nf para importar.',
        theme: Theme.Danger,
      });
      return;
    }

    if (importedFile[0] === null || importedFile[0] === undefined) {
      setMessage({
        message: 'Necessário informar o documento para importar.',
        theme: Theme.Danger,
      });
      return;
    }

    const { status, message: msg } = await ImportNfsViaZip({
      file: importedFile[0],
      nrSeqEmpresa: data.nrSeqEmpresa,
      nrSeqNftipo: data.nrSeqNftipo,
      nrSeqProduto: data.nrSeqProduto,
    });
    if (status === ResponseStatus.Success) {
      if (gridView && gridView.current) gridView.current.setDataSource(null);
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    if (status === ResponseStatus.Success) {
      props.onClose({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
  };

  const cancelar = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
    });
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onSearchTipoNf = async (e) => {
    const { status, message: msg, nftipos } = await getNfTipoAutoComplete({
      noNfTipo: e,
    });

    if (msg) onSetMessage(status, msg);

    return nftipos;
  };

  const onSearchProduto = async (e) => {
    const {
      status,
      message: msg,
      data: produtos,
    } = await getProdutoAutoComplete({
      noSearchField: e,
    });
    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  return (
    <Modal
      show={show}
      title='Importar Nota Fiscal De Serviço'
      size={BootstrapSizes.Large}
      onMessagerClose={() => setMessage(null)}
    >
      <Modal.Body className='p-4 pt-3'>
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mt-3'>
          <div className='col-4 mt-3'>
            <Autocomplete
              label='Empresa'
              required
              searchDataSource={onSearchEmpresa}
              selectedItem={data.empresa}
              onSelectItem={(empresa) => {
                setData({
                  ...data,
                  empresa,
                  nrSeqEmpresa: empresa.nrSeqEmpresa,
                });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
          <div className='col-4 mt-3'>
            <Autocomplete
              label='Tipo NF'
              required
              searchDataSource={onSearchTipoNf}
              selectedItem={data.tipoNotaFiscal}
              onSelectItem={(tipoNotaFiscal) => {
                setData({
                  ...data,
                  tipoNotaFiscal,
                  nrSeqNftipo: tipoNotaFiscal.nrSeqNfTipo,
                });
              }}
              dataSourceTextProperty='noNfTipo'
            />
          </div>
          <div className='col-4 mt-3'>
            <Autocomplete
              label='Produto'
              searchDataSource={onSearchProduto}
              selectedItem={data.produto}
              onSelectItem={(produto) => {
                setData({
                  ...data,
                  produto,
                  nrSeqProduto: produto.nrSeqProduto,
                });
              }}
              dataSourceTextProperty='noProduto'
            />
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-12 mt-3'>
            <FileUpload
              files={importedFile}
              onChange={(files) => setImportedFile(files)}
              allowedMimeTypes={[MimeTypes.Types.Excel]}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-4 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Importar'
              onClick={salvar}
            />
          </div>
          <div className='col-2 mt-3'>
            <Button
              theme={Theme.Danger}
              template={Button.Templates.Button}
              text='Sair'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
