import qs from 'qs';
import Pagination from 'core/models/pagination';
import ClienteLinhaLog from 'core/models/TEL/clienteLinhaLog';
import api from '../api';

const baseURL = `/TEL/ClienteLinhaLog`;

export const getClienteLinhaLogList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    logs: value?.map((json) => new ClienteLinhaLog(json)) ?? [],
  };
};

export const getClienteLinhaLogPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ClienteLinhaLog(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getClienteLinhaLog = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ClienteLinhaLog(value) : {};
};

export const saveClienteLinhaLog = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteClienteLinhaLog = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printClienteLinhaLog = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelClienteLinhaLog = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllClienteLinhaLog = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
