import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) =>
  React.createElement(Route, {
    ...rest,
    render: (props) => {
      const { location } = props;
      return isAuthenticated ? (
        React.createElement(Component, props)
      ) : (
        <Redirect to={{ pathname: '/Login', state: { from: location } }} />
      );
    },
  });

export default PrivateRoute;
