import Pagination from 'core/models/pagination';
import Pais from 'core/models/SEG/pais';
import api from '../api';

const baseURL = `/SEG/Pais`;

export const getPaisAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    paises: value?.map((json) => new Pais(json)) ?? [],
  };
};

export const getPaisList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Pais(json)) ?? [],
  };
};

export const getPaisPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Pais(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPais = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Pais(value) : {};
};

export const savePais = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const printPais = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPais = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};
