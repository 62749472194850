import FormaPagamento from 'core/models/FIN/formaPagamento';

export default class ColetaEmbarqueFatura {
  constructor(jsonObject = {}) {
    this.nrSeqColetaEmbarque = jsonObject.nrSeqColetaEmbarque;
    this.nrSeqFatura = jsonObject.nrSeqFatura;
    this.vlrFatura = jsonObject.vlrFatura;
    this.dtVencimento = jsonObject.dtVencimento;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqTituloReceber = jsonObject.nrSeqTituloReceber;
    this.flgLiquidado = jsonObject.flgLiquidado;

    // #region Propriedades Auxiliares
    this.nrDias = jsonObject.nrDias;
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
