import React, { useState, useRef, useEffect } from 'react';
import {
  BootstrapSizes,
  Theme,
  dateNow,
  ResponseStatus,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  Modal,
  Switch,
  Autocomplete,
  Textbox,
  RadioButton,
  DatePicker,
  Button,
  GridView,
  Notification,
} from 'ui/components';
import NfFatura from 'core/models/COP/nfFatura';

import {
  convertBarCode,
  getDtVencimentoValorParcela,
} from 'core/services/COP/nfFatura';
import { getFornecedorList } from 'core/services/FIN/fornecedor';
import { getTituloPagarTipoDocumentoAutoComplete } from 'core/services/FIN/tituloPagarTipoDocumento';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';

export default function ModalFaturasNotaFiscalEntrada(props) {
  const gridViewFaturas = useRef();
  const { show, data } = props;
  const [vlrTotalFatura, setVlrTotalFatura] = useState(0);
  const [message, setMessage] = useState(null);
  const [fatura, setFatura] = useState(
    new NfFatura({
      flgTipoCodigoBarras: 'L',
      flgImposto: false,
      vlrMulta: 0,
      vlrJuro: 0,
    })
  );

  const clearFatura = () => {
    setFatura(
      new NfFatura({
        fornecedor: data.fornecedor,
        flgTipoCodigoBarras: 'L',
        vlrParcela: 0,
        vlrMulta: 0,
        vlrJuro: 0,
      })
    );
  };

  const somaVlrTotalFatura = (parcela) => {
    const totalFatura = vlrTotalFatura + parcela;

    return parseFloat(totalFatura.toFixed(2));
  };

  const load = () => {
    if (show) {
      if (gridViewFaturas && gridViewFaturas.current)
        gridViewFaturas.current.setDataSource(data.faturas);

      setVlrTotalFatura(data.vlrTotalFatura ?? 0);
      setFatura({ ...fatura, fornecedor: data.fornecedor ?? {} });
    }
  };

  useEffect(() => {
    load();
  }, [show]);

  const tipoCodigoBarras = [
    {
      text: 'Linha Digitavel',
      value: 'L',
    },
    {
      text: 'Código de Barras',
      value: 'C',
    },
  ];

  const recalculaTotalFatura = (datasource) => {
    let soma = 0;

    for (let i = 0; i < datasource.length; i += 1) {
      if (datasource[i].status !== 'Remover') {
        soma += datasource[i].vlrParcela;
      }
    }

    return soma;
  };

  const onClickEditFatura = (e, datasource) => {
    const dataFatura = datasource.find((item) => item === e);

    if (dataFatura.status !== GridView.EnumStatus.Inserir)
      dataFatura.status = GridView.EnumStatus.Alterar;

    const dataFaturas = datasource.filter((item) => item !== e);

    setVlrTotalFatura(recalculaTotalFatura(dataFaturas));

    setFatura({
      ...dataFatura,
      flgTipoCodigoBarras: 'C',
    });

    if (gridViewFaturas && gridViewFaturas.current)
      gridViewFaturas.current.setDataSource(dataFaturas);
  };

  const onClickRemoveFatura = (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (gridViewFaturas && gridViewFaturas.current)
      gridViewFaturas.current.setDataSource(datasource);

    setVlrTotalFatura(recalculaTotalFatura(datasource));
  };

  const columns = [
    { key: 'nrSeqNfFatura', title: 'nrSeqNfFatura', visible: false },
    { key: 'fornecedor.noPessoa', title: 'Fornecedor' },
    { key: 'tipoDespesa.noTipoDespesa', title: 'Tipo Despesa' },
    {
      key: 'tituloPagarTipoDocumento.noTituloPagarTipoDocumento',
      title: 'Tipo Documento',
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    { key: 'nrFat', title: 'Nr Fatura' },
    { key: 'formaPagamento.noFormaPagamento', title: 'Forma Pagamento' },
    {
      key: 'vlrParcela',
      title: 'Parcela',
      format: GridView.DataTypes.Decimal,
    },
    { key: 'vlrMulta', title: 'Multa', format: GridView.DataTypes.Decimal },
    { key: 'vlrJuro', title: 'Juros', format: GridView.DataTypes.Decimal },
    {
      key: 'nrSeqNfFatura',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditFatura(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqNfFatura',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveFatura(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onSearchPessoaFor = async (e) => {
    const { pessoaFors } = await getFornecedorList({
      noPessoaFor: e,
    });
    return pessoaFors;
  };

  const onSearchTipoDocTituloPagar = async (e) => {
    const {
      data: tipoDocTituloPagar,
    } = await getTituloPagarTipoDocumentoAutoComplete({
      noTituloPagarTipoDocumento: e,
    });

    return tipoDocTituloPagar;
  };

  const onAutoCompleteFormaPagamento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
    });

    return formasPagamento;
  };

  const validateFaturaItems = (datasource) => {
    const dataMinima = '01/01/0001';
    const dataZero = '00/00/0000';
    const dataAtual = new Date(dateNow());
    const dtVencimento = new Date(`${datasource.dtVencimento}T00:00:00`);

    if (
      datasource.dtVencimento === dataMinima ||
      datasource.dtVencimento === dataZero ||
      datasource.dtVencimento === undefined
    ) {
      setMessage({
        message: 'Utilize uma data válida para o vencimento!',
        theme: Theme.Danger,
      });
      return false;
    }

    if (datasource.formaPagamento.nrSeqFormaPagamento === undefined) {
      setMessage({
        message: 'Campo Obrigatório: Forma de Pagamento.',
        theme: Theme.Danger,
      });
      return false;
    }

    if (datasource.dtVencimento === null) {
      setMessage({
        message: 'Campo Obrigatório: Data Vencimento Fatura.',
        theme: Theme.Danger,
      });
      return false;
    }

    if (datasource.vlrParcela === undefined || datasource.vlrParcela === 0) {
      setMessage({
        message: 'Valor da parcela não informado ou inválido.',
        theme: Theme.Danger,
      });
      return false;
    }

    if (dtVencimento < dataAtual) {
      setMessage({
        message: 'A data de vencimento informada é retroativa. Verifique!',
        theme: Theme.Primary,
      });
      return true;
    }

    return true;
  };

  const adicionaFatura = () => {
    const faturas = gridViewFaturas.current.getDataSource() ?? [];
    if (!fatura.status) fatura.status = GridView.EnumStatus.Inserir;
    fatura.nrSeqPessoaFor = fatura.fornecedor.nrSeqPessoaFor;

    if (data.tipoDespesa) fatura.tipoDespesa = data.tipoDespesa;

    if (validateFaturaItems(fatura)) {
      setVlrTotalFatura(somaVlrTotalFatura(fatura.vlrParcela));
      if (!fatura.vlrJuro === undefined) fatura.vlrJuro = 0;
      if (!fatura.vlrMulta === undefined) fatura.vlrMulta = 0;
      if (!fatura.vlrParcela === undefined) fatura.vlrParcela = 0;

      faturas.push(fatura);

      if (gridViewFaturas && gridViewFaturas.current) {
        gridViewFaturas.current.setDataSource(faturas);
      }
      clearFatura();
    }
  };

  const onPadronizaFornecedorEPagamento = () => {
    const datasource = gridViewFaturas.current.getDataSource();
    const newDataSource = datasource.map((item) => {
      if (fatura.fornecedor) {
        item.fornecedor = fatura.fornecedor;
        item.nrSeqPessoaFor = fatura.fornecedor.nrSeqPessoaFor;
      }
      if (fatura.formaPagamento?.nrSeqFormaPagamento) {
        item.formaPagamento = fatura.formaPagamento;
        item.nrSeqFormaPagamento = fatura.formaPagamento.nrSeqFormaPagamento;
      }

      return item;
    });

    if (gridViewFaturas && gridViewFaturas.current)
      gridViewFaturas.current.setDataSource(newDataSource);
  };

  useEffect(async () => {
    if (fatura.noCodigoBarra) {
      const { status, message: msg, data: retorno } = await convertBarCode({
        flgTipoCodigoBarras: fatura.flgTipoCodigoBarras,
        noCodigoBarra: fatura.noCodigoBarra,
      });

      if (status === ResponseStatus.Success) {
        setFatura({
          ...fatura,
          noCodigoBarra: retorno.noCodigoBarra,
        });
      } else {
        setMessage({
          message: msg,
          theme: Theme.Danger,
        });
      }
    }
  }, [fatura.flgTipoCodigoBarras]);

  const onSend = async () => {
    clearFatura();
    setVlrTotalFatura(0);
    if (gridViewFaturas && gridViewFaturas.current) {
      const itens = gridViewFaturas.current.getDataSource();
      props.onSave(itens, vlrTotalFatura);
    }
    props.onClose();
  };

  const carregaValorEData = async () => {
    if (fatura.noCodigoBarra.length === 44) {
      const { data: nfFatura } = await getDtVencimentoValorParcela({
        noCodigoBarra: fatura.noCodigoBarra,
      });

      setFatura({
        ...fatura,
        dtVencimento: nfFatura.dtVencimento,
        vlrParcela: nfFatura.vlrParcela,
        flgTipoCodigoBarras: 'C',
      });
    } else {
      setFatura({
        ...fatura,
        flgTipoCodigoBarras: 'L',
      });
    }
  };

  return (
    <Modal
      show={show}
      title='Detalhes das Faturas'
      onClose={() => onSend()}
      size={BootstrapSizes.ExtraLarge}
      icon={['far', 'credit-card']}
    >
      <Modal.Body>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row pb-5'>
          <div className='row mb-3'>
            <div className='col-1'>
              <Switch
                formControl
                label='Imposto'
                checked={fatura.flgImposto}
                onChange={(flgImposto) => setFatura({ ...fatura, flgImposto })}
              />
            </div>
            <div className='col-6'>
              <Autocomplete
                label='Fornecedor'
                readOnly
                searchDataSource={onSearchPessoaFor}
                selectedItem={fatura.fornecedor}
                onSelectItem={(fornecedor) =>
                  setFatura({
                    ...fatura,
                    fornecedor,
                    nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                  })
                }
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-5'>
              <Autocomplete
                label='Tipo'
                searchDataSource={onSearchTipoDocTituloPagar}
                selectedItem={fatura.tituloPagarTipoDocumento}
                onSelectItem={(tituloPagarTipoDocumento) =>
                  setFatura({
                    ...fatura,
                    tituloPagarTipoDocumento,
                    nrSeqTituloPagarTipoDocumento:
                      tituloPagarTipoDocumento.nrSeqTituloPagarTipoDocumento,
                  })
                }
                dataSourceTextProperty='noTituloPagarTipoDocumento'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-8'>
              <Textbox
                onBlur={() => carregaValorEData()}
                label='Código de Barras'
                text={fatura.noCodigoBarra}
                maxLength={48}
                onChangedValue={(noCodigoBarra) =>
                  setFatura({ ...fatura, noCodigoBarra })
                }
              />
            </div>
            <div className='col'>
              <RadioButton
                outline
                label='Tipo Código de Barras'
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                buttons={tipoCodigoBarras}
                selectedButton={fatura.flgTipoCodigoBarras}
                onChange={(flgTipoCodigoBarras) => {
                  setFatura({ ...fatura, flgTipoCodigoBarras });
                }}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-6'>
              <Autocomplete
                label='Forma Pagamento'
                searchDataSource={onAutoCompleteFormaPagamento}
                selectedItem={fatura.formaPagamento}
                onSelectItem={(formaPagamento) =>
                  setFatura({
                    ...fatura,
                    formaPagamento,
                    nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                  })
                }
                dataSourceTextProperty='noFormaPagamento'
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='Número Fatura'
                text={fatura.nrFat}
                onChangedValue={(nrFat) => setFatura({ ...fatura, nrFat })}
              />
            </div>
            <div className='col-3'>
              <DatePicker
                label='Vencimento'
                text={fatura.dtVencimento}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtVencimento) =>
                  setFatura({ ...fatura, dtVencimento })
                }
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-3'>
              <Textbox
                label='Valor Parcela'
                text={fatura.vlrParcela}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrParcela) =>
                  setFatura({ ...fatura, vlrParcela })
                }
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='Valor Multa'
                text={fatura.vlrMulta}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrMulta) =>
                  setFatura({ ...fatura, vlrMulta })
                }
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='Valor Juros'
                text={fatura.vlrJuro}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrJuro) => setFatura({ ...fatura, vlrJuro })}
              />
            </div>
            <div className='col mt-4'>
              <Button
                outline
                icon='plus'
                size={BootstrapSizes.Medium}
                theme={Theme.Success}
                template={Button.Templates.Default}
                onClick={() => adicionaFatura()}
                text='Adicionar'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-6 mt-4'>
              <Button
                outline
                icon={['fas', 'cogs']}
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                template={Button.Templates.Default}
                onClick={() => onPadronizaFornecedorEPagamento()}
                text='Padronizar Fornecedor e Forma Pagamento'
              />
            </div>
            <div className='col-3'>
              <Textbox
                readOnly
                label='Valor Total Itens'
                mask={MaskTypes.DecimalCustom}
                text={data.vlrNfBruto}
              />
            </div>
            <div className='col-3'>
              <Textbox
                readOnly
                label='Valor Total Fatura'
                mask={MaskTypes.DecimalCustom}
                text={vlrTotalFatura}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <GridView
                ref={gridViewFaturas}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columns}
                offlineData
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Medium}
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          onClick={() => onSend()}
          text='Sair'
        />
      </Modal.Footer>
    </Modal>
  );
}
