import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  JustifyContent,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Textbox,
  Switch,
  Button,
  GridView,
  Autocomplete,
  Panel,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getAlmoxarifado,
  saveAlmoxarifado,
  deleteAlmoxarifado,
} from 'core/services/EST/almoxarifado';

import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';

export default function AlmoxarifadoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const gridViewEmpresas = useRef();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onRemoveEmpresa = async (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (gridViewEmpresas && gridViewEmpresas.current)
      gridViewEmpresas.current.setDataSource(datasource);
  };

  const columnsEmpresas = [
    {
      key: 'nrSeqEmpresa',
      title: 'Código',
      visible: false,
    },
    { key: 'noPessoa', title: 'Empresa' },
    {
      key: 'nrSeqEmpresa',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveEmpresa(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getAlmoxarifado(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveAlmoxarifado(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteAlmoxarifado(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      transaction={transaction}
      isActive={isActive}
      title='Manutenção de Almoxarifado'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqAlmoxarifado > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <div className='row'>
        <div className='col-2'>
          <Textbox
            required
            label='Código'
            text={data.cdAlmoxarifado}
            mask={MaskTypes.Integer}
            onChangedValue={(cdAlmoxarifado) =>
              setData({ ...data, cdAlmoxarifado })
            }
          />
        </div>

        <div className='col-5'>
          <Textbox
            maxLength={40}
            label='Almoxarifado'
            text={data.noAlmoxarifado}
            onChangedValue={(noAlmoxarifado) =>
              setData({ ...data, noAlmoxarifado })
            }
          />
        </div>

        <div className='col-2'>
          <Switch
            formControl
            label='Estoque Negativo'
            checked={data.flgEstoqueNegativo}
            onChange={(flgEstoqueNegativo) =>
              setData({ ...data, flgEstoqueNegativo })
            }
          />
        </div>

        <div className='col-2'>
          <Switch
            formControl
            label='Gera Itens Automático'
            checked={data.flgCadAutomatico}
            onChange={(flgCadAutomatico) =>
              setData({ ...data, flgCadAutomatico })
            }
          />
        </div>
      </div>

      <div className='row mt-4'>
        <Panel>
          <Panel.Header
            icon={['fas', 'user']}
            title='Empresas'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row'>
              <div className='col-3 mb-3'>
                <Autocomplete
                  label='Empresa'
                  required
                  searchDataSource={onSearchEmpresa}
                  selectedItem={data.empresa}
                  onSelectItem={(empresa) => {
                    setData({
                      ...data,
                      empresa,
                      nrSeqEmpresa: empresa.nrSeqEmpresa,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-auto'>
                <div className='widget-square text-white p-3 br-tl-1 br-bl-1'>
                  <Button
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Success}
                    template={Button.Templates.Quick}
                    text='Adicionar'
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-4'>
                <GridView
                  ref={gridViewEmpresas}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsEmpresas}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    </CSDManutPage>
  );
}
