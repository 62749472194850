// import InventarioItem from './inventarioItem';
import ProdutoInventarioItem from './produtoInventarioItemModal';

export default class Inventario {
  constructor(jsonObject = {}) {
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.cdInventario = jsonObject.cdInventario;
    this.dtInicio = jsonObject.dtInicio;
    this.dtFim = jsonObject.dtFim;
    this.dtEfetiva = jsonObject.dtEfetiva;
    this.noInventario = jsonObject.noInventario;
    this.nrSeqAlmoxarifado = jsonObject.nrSeqAlmoxarifado;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqInventario = jsonObject.nrSeqInventario;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgBoqItemNaoPrevisto = jsonObject.flgBoqItemNaoPrevisto;
    this.flgExibirEstoque = jsonObject.flgExibirEstoque;
    this.flgAcumulaLancamento = jsonObject.flgAcumulaLancamento;
    this.flgInventarioCompleto = jsonObject.flgInventarioCompleto;
    this.qtdeContagem = jsonObject.qtdeContagem;
    this.flgExibeCusto = jsonObject.flgExibeCusto;

    this.inventarioItens = jsonObject.inventarioItens?.map(
      (inventarioItem) => new ProdutoInventarioItem(inventarioItem) ?? []
    );
    this.usuario = jsonObject.usuario;
    this.almoxarifado = jsonObject.almoxarifado;
    this.empresa = jsonObject.empresa;
  }

  toJson = () => JSON.stringify(this);
}
