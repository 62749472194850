export default class MaterialRevestimento {
  constructor(jsonObject = {}) {
    this.nrSeqPocMaterialRevestimento = jsonObject.nrSeqPocMaterialRevestimento;
    this.cdPocMaterialRevestimento = jsonObject.cdPocMaterialRevestimento;
    this.noPocMaterialRevestimento = jsonObject.noPocMaterialRevestimento;
    this.flgAtivo = jsonObject.flgAtivo;
  }

  toJson = () => JSON.stringify(this);
}
