export default class ChamadoClassificacao {
  nrSeqChamadoClassificacao: number;

  noChamadoClassificacao: string;

  constructor(jsonObject: ChamadoClassificacao) {
    this.nrSeqChamadoClassificacao = jsonObject.nrSeqChamadoClassificacao;
    this.noChamadoClassificacao = jsonObject.noChamadoClassificacao;
  }

  toJson = () => JSON.stringify(this);
}
