import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  JustifyContent,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  GridView,
  Switch,
  Panel,
  Button,
  RadioButton,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getTipoServico,
  saveTipoServico,
  deleteTipoServico,
} from 'core/services/GProj/tipoServico';

import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import { getProdutoServicoAutoComplete } from 'core/services/VEN/produtoServico';
import { getCidadeEstadoAutoComplete } from 'core/services/SEG/cidade';
import TipoServicoCidade from 'core/models/VEN/tipoServicoCidade';

const tributacaoISSQN = [
  { text: 'Operação Tributável', value: 1 },
  { text: 'Imunidade', value: 2 },
  { text: 'Exportação de Serviço', value: 3 },
  { text: 'Não Incidência', value: 4 },
];

export default function TipoServicoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({ nrTipoISSQN: 1 });
  const [dataTipoServicoCidade, setDataTipoServicoCidade] = useState(
    new TipoServicoCidade()
  );
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const gridView = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const retorno = await getTipoServico(registryKey);
      setData(retorno);
      if (gridView && gridView.current)
        gridView.current.setDataSource(retorno.tipoServicoCidades);
      setLoading(false);
    } else setData({ aliqCofins: undefined });
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const tiposervicocidades = gridView.current.getDataSource() ?? [];
    const obj = { ...data, tipoServicoCidades: tiposervicocidades };
    const { status, message: msg } = await saveTipoServico(obj);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(
      status === ResponseStatus.Success
        ? {
            aliqPis: '',
            aliqCofins: '',
            aliqCsll: '',
            aliqIR: '',
            aliqInss: '',
            aliqIssqn: '',
          }
        : data
    );
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onClickAddCidade = async () => {
    const obj = new TipoServicoCidade({
      nrSeqTipoServico: data.nrSeqTipoServico,
      nrSeqCidade: dataTipoServicoCidade.nrSeqCidade,
      cidade: dataTipoServicoCidade.cidade,
      aliqIssqn: dataTipoServicoCidade.aliqIssqn,
      status: 'Inserir',
    });
    if (obj.nrSeqCidade && obj.aliqIssqn != null) {
      const list = gridView.current.getDataSource();
      list.push(obj);

      if (gridView && gridView.current) gridView.current.setDataSource(list);

      setDataTipoServicoCidade(new TipoServicoCidade());
    } else {
      onSetMessage(Theme.Danger, 'Necessário informar todos os campos.');
    }
  };

  const onClickRemoveCidade = async (e) => {
    const list = gridView.current.getDataSource();
    list.filter((elemento) => elemento === e)[0].status =
      GridView.EnumStatus.Remover;

    if (gridView && gridView.current) gridView.current.setDataSource(list);
  };

  const columns = [
    { key: 'nrSeqTipoServicoCidade', type: GridView.ColumnTypes.Checkbox },
    { key: 'nrSeqTipoServico', title: 'NrSeqTipoServico', visible: false },
    { key: 'nrSeqCidade', title: 'NrSeqCidade', visible: false },
    { key: 'cidade.noCidadeEstado', title: 'Cidade' },
    { key: 'aliqIssqn', title: 'Issqn', format: GridView.DataTypes.Decimal },
    {
      key: 'nrSeqTipoServicoCidade',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveCidade(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteTipoServico(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchTituloEspecie = async (e) => {
    const {
      status,
      message: msg,
      data: tituloEspecies,
    } = await getTituloEspecieAutoComplete({ noTituloEspecie: e });
    if (msg) onSetMessage(status, msg);
    return tituloEspecies;
  };

  const onSearchProdutoServico = async (e) => {
    const {
      status,
      message: msg,
      data: produtoSericos,
    } = await getProdutoServicoAutoComplete({ noProdutoServico: e });
    if (msg) onSetMessage(status, msg);
    return produtoSericos;
  };

  const onSearchCidade = async (e) => {
    const { status, message: msg, cidades } = await getCidadeEstadoAutoComplete(
      {
        noCidade: e,
      }
    );
    if (msg) onSetMessage(status, msg);
    return cidades;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Tipo Serviço'
      transaction={transaction}
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqTipoServico > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <div className='row mb-3'>
        <div className='col-2'>
          <Textbox
            maxLength={60}
            required
            label='Código'
            text={data.cdTipoServico}
            onChangedValue={(cdTipoServico) =>
              setData({ ...data, cdTipoServico })
            }
          />
        </div>
        <div className='col-4'>
          <Textbox
            maxLength={80}
            required
            label='Nome'
            text={data.noTipoServico}
            onChangedValue={(noTipoServico) =>
              setData({ ...data, noTipoServico })
            }
          />
        </div>
        <div className='col-1'>
          <Switch
            formControl
            label='Ativo'
            checked={data.flgAtivo}
            onChange={(flgAtivo) => setData({ ...data, flgAtivo })}
          />
        </div>
        <div className='col-1'>
          <Switch
            formControl
            label='Transporte'
            checked={data.flgTransporte}
            onChange={(flgTransporte) => setData({ ...data, flgTransporte })}
          />
        </div>
        <div className='col-1'>
          <Switch
            formControl
            label='Nota Fis'
            flgGeraNfs
            checked={data.flgGeraNfs}
            onChange={(flgGeraNfs) => setData({ ...data, flgGeraNfs })}
          />
        </div>
        <div className='col-1'>
          <Switch
            formControl
            label='Título'
            checked={data.flgGeraTitulo}
            onChange={(flgGeraTitulo) => setData({ ...data, flgGeraTitulo })}
          />
        </div>
        <div className='col-2'>
          <Switch
            formControl
            label='Trib. Município Origem'
            checked={data.flgIssqnCidadeOrigem}
            onChange={(flgIssqnCidadeOrigem) =>
              setData({ ...data, flgIssqnCidadeOrigem })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-2'>
          <Textbox
            maxLength={3}
            required
            label=' Série Nota Fiscal'
            text={data.serieNFS}
            onChangedValue={(serieNFS) => setData({ ...data, serieNFS })}
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Titulo Especie'
            searchDataSource={onSearchTituloEspecie}
            selectedItem={data.tituloEspecie}
            onSelectItem={(tituloEspecie) => {
              setData({
                ...data,
                tituloEspecie,
                nrSeqTituloEspecie: tituloEspecie.nrSeqTituloEspecie,
              });
            }}
            dataSourceTextProperty='noEspecie'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Produto Servico'
            searchDataSource={onSearchProdutoServico}
            selectedItem={data.produtoServico}
            onSelectItem={(produtoServico) => {
              setData({
                ...data,
                produtoServico,
                nrSeqProdutoServico: produtoServico.nrSeqProdutoServico,
              });
            }}
            dataSourceTextProperty='noProdutoServico'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-2'>
          <Textbox
            label='Alíquota PIS'
            maxLength={6}
            text={data.aliqPis}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(aliqPis) => setData({ ...data, aliqPis })}
          />
        </div>
        <div className='col-1'>
          <Switch
            formControl
            label='PIS Retido'
            checked={data.flgPisRetido}
            onChange={(flgPisRetido) => setData({ ...data, flgPisRetido })}
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Alíquota Cofins'
            maxLength={6}
            text={data.aliqCofins}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(aliqCofins) => setData({ ...data, aliqCofins })}
          />
        </div>
        <div className='col-1'>
          <Switch
            formControl
            label='Cofins Ret'
            checked={data.flgCofinsRetido}
            onChange={(flgCofinsRetido) =>
              setData({ ...data, flgCofinsRetido })
            }
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Alíquota INSS'
            maxLength={6}
            text={data.aliqInss}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(aliqInss) => setData({ ...data, aliqInss })}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-2'>
          <Textbox
            label='Alíquota CSLL'
            text={data.aliqCsll}
            maxLength={6}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(aliqCsll) => setData({ ...data, aliqCsll })}
          />
        </div>
        <div className='col-1'>
          <Switch
            formControl
            label='CSLL Ret'
            checked={data.flgCsllRetido}
            onChange={(flgCsllRetido) => setData({ ...data, flgCsllRetido })}
          />
        </div>

        <div className='col-2'>
          <Textbox
            label='Alíquota Issqn'
            text={data.aliqIssqn}
            maxLength={6}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(aliqIssqn) => setData({ ...data, aliqIssqn })}
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Alíquota IR'
            maxLength={6}
            text={data.aliqIR}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(aliqIR) => setData({ ...data, aliqIR })}
          />
        </div>
        <div className='col-1'>
          <Switch
            formControl
            label='ISSQN Ret'
            checked={data.flgIssqnRetido}
            onChange={(flgIssqnRetido) =>
              setData({
                ...data,
                flgIssqnRetido,
                flgIssqnRetCidadeTomador: !flgIssqnRetido,
              })
            }
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='ISSQN Ret pelo Cliente se Outra Cidade'
            checked={data.flgIssqnRetCidadeTomador}
            onChange={(flgIssqnRetCidadeTomador) =>
              setData({
                ...data,
                flgIssqnRetCidadeTomador,
                flgIssqnRetido: !flgIssqnRetCidadeTomador,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <span>Tributação do ISSQN sobre o serviço prestado</span>
        <div>
          <RadioButton
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={data.nrTipoISSQN}
            buttons={tributacaoISSQN}
            onChange={(nrTipoISSQN) => setData({ ...data, nrTipoISSQN })}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col mb-12'>
          <Panel>
            <Panel.Header
              title='Alíquota Cidades'
              icon={['fa', 'briefcase']}
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-3'>
                  <Autocomplete
                    label='Cidade'
                    searchDataSource={onSearchCidade}
                    selectedItem={data.cidade}
                    onSelectItem={(cidade) => {
                      setDataTipoServicoCidade({
                        ...dataTipoServicoCidade,
                        cidade,
                        nrSeqCidade: cidade.nrSeqCidade,
                      });
                    }}
                    dataSourceTextProperty='noCidadeEstado'
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Alíquota Issqn'
                    text={dataTipoServicoCidade.aliqIssqn}
                    maxLength={6}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(aliqIssqn) =>
                      setDataTipoServicoCidade({
                        ...dataTipoServicoCidade,
                        aliqIssqn,
                      })
                    }
                  />
                </div>

                <div className='col-2 mt-3'>
                  <Button
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Adicionar'
                    onClick={onClickAddCidade}
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-12'>
                  <GridView
                    ref={gridView}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns}
                    offlineData
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
    </CSDManutPage>
  );
}
