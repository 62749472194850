import qs from 'qs';
import Pagination from 'core/models/pagination';
import PessoaSetor from 'core/models/SEG/pessoaSetor';
import api from '../api';

const baseURL = `/SEG/PessoaSetor`;

export const getPessoaSetorList = async (filters) => {
  const response = await api.get(`/$PessoaSetor/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PessoaSetor(json)) ?? [],
  };
};

export const getPessoaSetorAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    setores: value?.map((json) => new PessoaSetor(json)) ?? [],
  };
};

export const getPessoaSetorPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PessoaSetor(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPessoaSetor = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PessoaSetor(value) : {};
};

export const savePessoaSetor = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePessoaSetor = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPessoaSetor = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPessoaSetor = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPessoaSetor = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
