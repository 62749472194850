export default class TipoExtratoTransferencia {
  constructor(jsonObject = {}) {
    this.nrSeqTipoExtratoTransferencia =
      jsonObject.nrSeqTipoExtratoTransferencia;
    this.cdTipoExtratoTransferencia = jsonObject.cdTipoExtratoTransferencia;
    this.noTipoExtratoTransferencia = jsonObject.noTipoExtratoTransferencia;
    this.flgDebitaContaOrigem = jsonObject.flgDebitaContaOrigem;
    this.flgCreditaContaDestino = jsonObject.flgCreditaContaDestino;
  }

  toJson = () => JSON.stringify(this);
}
