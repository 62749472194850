import EncargoManual from './encargoManual';

class ClienteLinhaEncargoManual {
  constructor(jsonObject = {}) {
    this.nrSeqEncargoManual = jsonObject.nrSeqEncargoManual;
    this.nrSeqClienteLinha = jsonObject.nrSeqClienteLinha;
    this.vlrEncargo = jsonObject.vlrEncargo;
    this.dtIniEncargoManual = jsonObject.dtIniEncargoManual;
    this.dtFimEncargoManual = jsonObject.dtFimEncargoManual;

    this.dtIniEncargoManualFormat = jsonObject.dtIniEncargoManualFormat;
    this.dtFimEncargoManualFormat = jsonObject.dtFimEncargoManualFormat;

    this.encargoManual = new EncargoManual(jsonObject.encargoManual ?? {});
  }

  toJson = () => JSON.stringify(this);
}
export default ClienteLinhaEncargoManual;
