import React, { useState, useEffect } from 'react';

import {
  Button,
  DatePicker,
  Modal,
  Notification,
  Textbox,
} from 'ui/components';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';

export default function ModalGerarRecibo(props) {
  const [recibo, setRecibo] = useState();
  const [messageState, setMessage] = useState(null);

  const { show, gerarRecibo } = props;

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  useEffect(() => {
    if (gerarRecibo !== undefined) {
      setRecibo({ ...gerarRecibo });
    }
  }, [show]);

  const cancelar = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
      obj: null,
    });
  };

  const salvar = async () => {
    const obj = {
      ...recibo,
    };
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
      obj,
    });
  };

  return (
    <Modal show={show} title='Gerar Recibo' size={BootstrapSizes.Large}>
      <Modal.Body className='p-4 pt-3'>
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => onSetMessage(null)}
            />
          </div>
        )}

        <div className='row mb-3'>
          <div className='col-4 mb-3'>
            <Textbox
              required
              label='Valor'
              text={recibo?.vlrAcerto}
              mask={MaskTypes.DecimalCustom}
              onChangedValue={(vlrAcerto) =>
                setRecibo({ ...recibo, vlrAcerto })
              }
            />
          </div>
          <div className='col-4 mb-3'>
            <DatePicker
              label='Data'
              text={recibo?.dtAcerto}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtAcerto) => setRecibo({ ...recibo, dtAcerto })}
            />
          </div>
          <div className='col-4 mb-3'>
            <Textbox
              label='Referente-a'
              text={recibo?.noObs}
              onChangedValue={(noObs) => setRecibo({ ...recibo, noObs })}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-4 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Gerar'
              onClick={salvar}
            />
          </div>
          <div className='col-2 mt-3'>
            <Button
              theme={Theme.Danger}
              template={Button.Templates.Button}
              text='Sair'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
