import Cliente from 'core/models/FIN/cliente';
import PocEnsaioBombeamento from './pocEnsaioBombeamento';
import PocEnsaioRecuperacao from './pocEnsaioRecuperacao';
import PocPocoCimentacao from './pocPocoCimentacao';
import PocPocoDesenvolvimento from './pocPocoDesenvolvimento';
import PocPocoEntradaAgua from './pocPocoEntradaAgua';
import PocPocoPerflitCamada from './pocPocoPerflitCamada';
import PocPocoPerflitFormacao from './pocPocoPerflitFormacao';
import PocPocoPerfuracao from './pocPocoPerfuracao';
import PocPocoPocCabo from './pocPocoPocCabo';
import PocPocoPocTubulacaoAducao from './pocPocoPocTubulacaoAducao';
import PocPocoPrefiltro from './pocPocoPrefiltro';
import PocPocoRevestimento from './pocPocoRevestimento';
import PocPocoDadosHidraulicos from './pocPocoDadosHidraulicos';
import PocPocoPocEnsaio from './pocPocoPocEnsaio';
import PocPocoBombaInstalada from './pocPocoBombaInstalada';

export default class PocPoco {
  constructor(jsonObject = {}) {
    this.nrSeqPocPoco = jsonObject.nrSeqPocPoco;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.cdPocPoco = jsonObject.cdPocPoco;
    this.flgTipoPoco = jsonObject.flgTipoPoco;
    this.flgClassificacaoPoco = jsonObject.flgClassificacaoPoco;
    this.flgPocoProprio = jsonObject.flgPocoProprio;
    this.noEmpresaTerceira = jsonObject.noEmpresaTerceira;
    this.flgHidroColetadoHidro = jsonObject.flgHidroColetadoHidro;
    this.flgHidroVideoEndoscopia = jsonObject.flgHidroVideoEndoscopia;
    this.flgHidroInfoProp = jsonObject.flgHidroInfoProp;
    this.flgHidroRelTecPerf = jsonObject.flgHidroRelTecPerf;
    this.flgHidroOutros = jsonObject.flgHidroOutros;
    this.noHidroOutros = jsonObject.noHidroOutros;
    this.flgTercManutencao = jsonObject.flgTercManutencao;
    this.flgTercVideoEndoscopia = jsonObject.flgTercVideoEndoscopia;
    this.flgTercAprofundamento = jsonObject.flgTercAprofundamento;
    this.flgTercDesenvolvimento = jsonObject.flgTercDesenvolvimento;
    this.flgTercRevestimento = jsonObject.flgTercRevestimento;
    this.flgTercEnsaioBomb = jsonObject.flgTercEnsaioBomb;
    this.flgTercAnaliseAgua = jsonObject.flgTercAnaliseAgua;
    this.flgStatusPoco = jsonObject.flgStatusPoco;
    this.noStatusPoco = jsonObject.noStatusPoco;
    this.flgTercOutro = jsonObject.flgTercOutro;
    this.noTercOutros = jsonObject.noTercOutros;
    this.noPocPocoObs = jsonObject.noPocPocoObs;
    this.noPocPocoObsIdentificacao = jsonObject.noPocPocoObsIdentificacao;
    this.noCliente = jsonObject.noCliente;
    this.nrSeqPocDominioGeologico = jsonObject.nrSeqPocDominioGeologico;
    this.utmLatitude = jsonObject.utmLatitude;
    this.utmLongitude = jsonObject.utmLongitude;
    this.noZonaUtm = jsonObject.noZonaUtm;
    this.geoLatitude = jsonObject.geoLatitude;
    this.geoLongitude = jsonObject.geoLongitude;
    this.nrCotaMetros = jsonObject.nrCotaMetros;
    this.dtCadastro = jsonObject.dtCadastro;
    this.dtInicioPoco = jsonObject.dtInicioPoco;
    this.dtFimPoco = jsonObject.dtFimPoco;
    this.nrProfundidade = jsonObject.nrProfundidade;
    this.nrSeqEndereco = jsonObject.nrSeqEndereco;
    this.nrSeqPocPerfuratriz = jsonObject.nrSeqPocPerfuratriz;
    this.nrOdiametroutil = jsonObject.nrOdiametroutil;
    this.noPocPocoPerfuracaoObs = jsonObject.noPocPocoPerfuracaoObs;
    this.noPocPocoPerflitCamadaObs = jsonObject.noPocPocoPerflitCamadaObs;
    this.noPocPocoPerflitFormacaoObs = jsonObject.noPocPocoPerflitFormacaoObs;
    this.noPocPocoRevestimentoObs = jsonObject.noPocPocoRevestimentoObs;
    this.noPocPocoPrefiltroObs = jsonObject.noPocPocoPrefiltroObs;
    this.noPocPocoFiltroObs = jsonObject.noPocPocoFiltroObs;
    this.noPocPocoDesenvolvimentoObs = jsonObject.noPocPocoDesenvolvimentoObs;
    this.noPocPocoCimentacaoObs = jsonObject.noPocPocoCimentacaoObs;
    this.noPocPocoEntradaAguaObs = jsonObject.noPocPocoEntradaAguaObs;
    this.noPocPocoEquipamentoObs = jsonObject.noPocPocoEquipamentoObs;
    this.noImagemDesenho = jsonObject.noImagemDesenho;
    this.perfuratriz = jsonObject.perfuratriz ?? {};
    this.dominioGeologico = jsonObject.dominioGeologico ?? {};
    this.dtCadastroStr = jsonObject.dtCadastroStr;
    this.latitude = jsonObject.utmLatitude ?? this.geoLatitude;
    this.longitude = jsonObject.utmLongitude ?? this.geoLongitude;
    this.nrEscalaDesenho = jsonObject.nrEscalaDesenho;
    this.coordenadas = jsonObject.coordenadas;
    this.nrVazao = jsonObject.nrVazao;
    this.cliente = new Cliente(jsonObject.cliente ?? {});
    this.endereco = jsonObject.endereco;
    this.perfuracoes =
      jsonObject.perfuracoes?.map((json) => new PocPocoPerfuracao(json)) ?? [];
    this.camadas =
      jsonObject.camadas?.map((json) => new PocPocoPerflitCamada(json)) ?? [];
    this.formacoes =
      jsonObject.formacoes?.map((json) => new PocPocoPerflitFormacao(json)) ??
      [];
    this.revestimentos =
      jsonObject.revestimentos?.map((json) => new PocPocoRevestimento(json)) ??
      [];
    this.prefiltros =
      jsonObject.prefiltros?.map((json) => new PocPocoPrefiltro(json)) ?? [];
    this.cimentacoes =
      jsonObject.cimentacoes?.map((json) => new PocPocoCimentacao(json)) ?? [];
    this.desenvolvimentos =
      jsonObject.desenvolvimentos?.map(
        (json) => new PocPocoDesenvolvimento(json)
      ) ?? [];
    this.entradasAgua =
      jsonObject.entradasAgua?.map((json) => new PocPocoEntradaAgua(json)) ??
      [];

    this.bombeamentos =
      jsonObject.bombeamentos?.map((json) => new PocEnsaioBombeamento(json)) ??
      [];

    this.bombeamentosModel =
      jsonObject.bombeamentos?.map((json) => new PocEnsaioBombeamento(json)) ??
      [];

    this.recuperacoes =
      jsonObject.recuperacoes?.map((json) => new PocEnsaioRecuperacao(json)) ??
      [];

    this.recuperacoesModel =
      jsonObject.recuperacoes?.map((json) => new PocEnsaioRecuperacao(json)) ??
      [];

    this.bombas =
      jsonObject.bombas?.map((json) => new PocPocoBombaInstalada(json)) ?? [];

    this.tubos =
      jsonObject.tubos?.map((json) => new PocPocoPocTubulacaoAducao(json)) ??
      [];

    this.cabos =
      jsonObject.cabos?.map((json) => new PocPocoPocCabo(json)) ?? [];

    this.ensaios =
      jsonObject.ensaios?.map((json) => new PocPocoPocEnsaio(json)) ?? [];

    this.dadosHidraulicos = new PocPocoDadosHidraulicos(
      jsonObject.dadosHidraulicos ?? {}
    );

    this.quadroComando = jsonObject.quadroComando;

    this.totalPerfuracoes = jsonObject.totalPerfuracoes;
    this.totalDesenvolvimentos = jsonObject.totalDesenvolvimentos;
    this.totalEntradasAgua = jsonObject.totalEntradasAgua;

    this.salvarEtapa = jsonObject.salvarEtapa;
    this.noPocEtapa = jsonObject.noPocEtapa;
    this.noMetodoPerfuracao = jsonObject.noMetodoPerfuracao;
    this.nrSeqDocumentosRemover = [];
    this.noCidadePoco = jsonObject.noCidadePoco;
  }

  get getTipoPoco() {
    return this.flgTipoPoco === 'P' ? 'Poço' : 'Piezômetro';
  }

  get getDataInicio() {
    if (this.dtInicioPoco)
      return `${new Date(this.dtInicioPoco).toLocaleDateString('pt-BR')}`;
    return '';
  }

  get getDataFim() {
    if (this.dtFimPoco)
      return `${new Date(this.dtFimPoco).toLocaleDateString('pt-BR')}`;
    return '';
  }

  toJson = () => JSON.stringify(this);
}
