export default class PocEnsaioBombeamento {
  constructor(jsonObject = {}) {
    this.nrSeqPocEnsaioBombeamento = jsonObject.nrSeqPocEnsaioBombeamento;
    this.nrTempo = jsonObject.nrTempo;
    this.nrTempoStr = jsonObject.nrTempoStr;
    this.nrNivel = jsonObject.nrNivel;
    this.nrRebaixamento = jsonObject.nrRebaixamento;
    this.nrVazao = jsonObject.nrVazao;
    this.nrSeqPocEnsaio = jsonObject.nrSeqPocEnsaio;
    this.reload = jsonObject.reload;
  }

  toJson = () => JSON.stringify(this);
}
