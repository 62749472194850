import Pneu from './pneu';
import Fornecedor from '../FIN/fornecedor';
import Veiculo from './veiculo';

export default class PneuConserto {
  constructor(jsonObject = {}) {
    this.nrSeqPneuConserto = jsonObject.nrSeqPneuConserto;
    this.nrSeqPneu = jsonObject.nrSeqPneu;
    this.dtConserto = jsonObject.dtConserto;
    this.vlrConserto = jsonObject.vlrConserto;
    this.nrSeqPessoaFor = jsonObject.nrSeqPessoaFor;
    this.dtVencimento = jsonObject.dtVencimento;
    this.obs = jsonObject.obs;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.flgRecapagem = jsonObject.flgRecapagem;
    this.dtPromessa = jsonObject.dtPromessa;
    this.nrDocumento = jsonObject.nrDocumento;
    this.nrSulcoEntrada = jsonObject.nrSulcoEntrada;
    this.dtRetorno = jsonObject.dtRetorno;
    this.pneu = new Pneu(jsonObject.pneu ?? {});
    this.pessoaFor = new Fornecedor(jsonObject.pessoaFor ?? {});
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
  }

  toJson = () => JSON.stringify(this);
}
