import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Panel,
  Textbox,
  Switch,
  Autocomplete,
  GridView,
  Button,
} from 'ui/components';
import { BootstrapSizes } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
// @ts-expect-error
import { Theme, ResponseStatus, StatusGrid } from 'ui/Helpers/enums.ts';
import { getTipoDespesaList } from 'core/services/FIN/tipoDespesa';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getAlmoxarifadoAutoComplete } from 'core/services/EST/almoxarifado';
import OficinaAlmoxarifado from 'core/models/FRO/oficina_Almoxarifado';

interface Props {
  oficinaData: any;
  setOficinaData: (object: any) => void;
  onSetMessage: (status: ResponseStatus, msg: string) => void;
  status?: StatusGrid;
}

const OficinaPanel = forwardRef(
  ({ oficinaData, setOficinaData, onSetMessage }: Props, ref) => {
    useEffect(() => {
      const objOficina = { ...oficinaData };
      setOficinaData(objOficina);
    }, [oficinaData.nrSeqPessoaOfi]);

    const [oficinaAlmoxarifadoData, setOficinaAlmoxarifadoData] = useState(
      new OficinaAlmoxarifado({})
    );
    const gridViewOficinaAlmoxariifados = useRef();

    const fillGridView = () => {
      if (
        gridViewOficinaAlmoxariifados &&
        gridViewOficinaAlmoxariifados.current &&
        oficinaData.oficinaAlmoxarifados &&
        oficinaData.oficinaAlmoxarifados.length > 0
      ) {
        const gridListOficinaAlmxoarifados = oficinaData.oficinaAlmoxarifados.map(
          (item) => item
        );
        gridViewOficinaAlmoxariifados.current.setDataSource(
          gridListOficinaAlmxoarifados
        );
      }
    };

    useImperativeHandle(ref, () => ({
      clear() {
        setOficinaData({});
        setOficinaAlmoxarifadoData({});
        gridViewOficinaAlmoxariifados?.current?.setDataSource([]);
      },
      fillGrid() {
        fillGridView();
      },
      getDataGrid() {
        const listaOficinaAlmoxarifados =
          gridViewOficinaAlmoxariifados?.current?.getDataSource() ?? [];
        return {
          listaOficinaAlmoxarifados,
        };
      },
    }));

    const onSearchFormaPagamento = async (e: any) => {
      const { formasPagamento } = await getFormaPagamentoAutoComplete({
        noFormaPagamento: e,
      });
      return formasPagamento;
    };

    const onSearchTipoDespesa = async (e: any) => {
      const { data: tipoDespesas } = await getTipoDespesaList({
        noTipoDespesa: e.noTipoDespesa,
      });

      return tipoDespesas;
    };

    const onSearchAlmoxarifado = async (e: any) => {
      const { data: almoxarifados } = await getAlmoxarifadoAutoComplete({
        noAlmoxarifado: e,
      });

      return almoxarifados;
    };

    const onClickEditProduto = async (selectedValue, datasource) => {
      const dataPostoComb = datasource.find((item) => item === selectedValue);
      dataPostoComb.status = GridView.EnumStatus.Alterar;

      const dataProdutos = datasource.filter(
        (produto) => produto !== selectedValue
      );

      setOficinaAlmoxarifadoData(dataPostoComb);

      if (
        gridViewOficinaAlmoxariifados &&
        gridViewOficinaAlmoxariifados.current
      )
        gridViewOficinaAlmoxariifados.current.setDataSource(dataProdutos);
    };

    const onRemovePostoCombustivel = async (e, datasource) => {
      const index = datasource.findIndex((item) => item === e);
      datasource[index].status = GridView.EnumStatus.Remover;

      if (
        gridViewOficinaAlmoxariifados &&
        gridViewOficinaAlmoxariifados.current
      )
        gridViewOficinaAlmoxariifados.current.setDataSource(datasource);
    };

    const onAdicionarOficinaAlmoxarifados = async () => {
      if (oficinaAlmoxarifadoData.almoxarifado) {
        const obj = {
          ...oficinaAlmoxarifadoData,
          status: oficinaAlmoxarifadoData.status ?? StatusGrid.Inserir,
        };

        const list = gridViewOficinaAlmoxariifados.current.getDataSource();
        list.push(obj);

        if (
          gridViewOficinaAlmoxariifados &&
          gridViewOficinaAlmoxariifados.current
        ) {
          gridViewOficinaAlmoxariifados.current.setDataSource(list);
        }

        setOficinaData({
          ...oficinaData,
          oficinaAlmoxarifados: list,
        });
        setOficinaAlmoxarifadoData({});
      } else {
        onSetMessage(ResponseStatus.Error, 'Informe um almoxarifado');
      }
    };

    const columns = [
      {
        key: 'nrSeqPessoaOfi',
        title: 'nrSeqPessoaOfi',
        visible: false,
      },
      {
        key: 'almoxarifado.noAlmoxarifado',
        title: 'Almoxarifado',
      },
      {
        key: 'nrSeqPessoaOfi',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onClickEditProduto(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },
      // ADICIONANDO BOTÃO DE DELEÇÃO
      {
        key: 'nrSeqPessoaOfi',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemovePostoCombustivel(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    return (
      <div className='col mt-3'>
        <Panel>
          <Panel.Header title='Oficina' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-1 mb-3'>
                <Switch
                  formControl
                  label='Ativo'
                  checked={oficinaData.flgAtivo}
                  onChange={(flgAtivo) =>
                    setOficinaData({ ...oficinaData, flgAtivo })
                  }
                />
              </div>
              <div className='col-auto'>
                <Switch
                  formControl
                  checked={oficinaData?.flgContasPagar}
                  label='Informações de Pagamento'
                  onChange={(flgContasPagar) =>
                    setOficinaData({ ...oficinaData, flgContasPagar })
                  }
                />
              </div>
              <div className='col-auto'>
                <Switch
                  formControl
                  checked={oficinaData?.flgControlaFuncionario}
                  label='Valida Funcionário'
                  onChange={(flgControlaFuncionario) =>
                    setOficinaData({ ...oficinaData, flgControlaFuncionario })
                  }
                />
              </div>
              <div className='col-1 mb-3'>
                <Switch
                  formControl
                  label='Próprio'
                  checked={oficinaData.flgProprio}
                  onChange={(flgProprio) =>
                    setOficinaData({ ...oficinaData, flgProprio })
                  }
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
        {oficinaData?.flgContasPagar && (
          <div className='col mt-3'>
            <Panel>
              <Panel.Header
                title='Informações de Pagamentos'
                theme={Theme.Primary}
              />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-3 mb-3'>
                    <Autocomplete
                      label='Forma Pagamento'
                      searchDataSource={onSearchFormaPagamento}
                      selectedItem={oficinaData.formaPagamento}
                      onSelectItem={(formaPagamento) =>
                        setOficinaData({
                          ...oficinaData,
                          formaPagamento,
                          nrSeqFormaPagamento:
                            formaPagamento.nrSeqFormaPagamento,
                        })
                      }
                      dataSourceTextProperty='noFormaPagamento'
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Dia Padrão de Vencimento'
                      maxLength={2}
                      mask={MaskTypes.Integer}
                      text={oficinaData?.diaPagamento}
                      onChangedValue={(diaPagamento: string) =>
                        setOficinaData({ ...oficinaData, diaPagamento })
                      }
                    />
                  </div>
                  <div className='col-3 mb-3'>
                    <Autocomplete
                      label='Tipo Despesa'
                      searchDataSource={onSearchTipoDespesa}
                      selectedItem={oficinaData.tipoDespesa}
                      onSelectItem={(tipoDespesa) =>
                        setOficinaData({
                          ...oficinaData,
                          tipoDespesa,
                          nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
                        })
                      }
                      dataSourceTextProperty='noTipoDespesa'
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        )}
        {oficinaData?.flgProprio && (
          <div className='col mt-3'>
            <Panel>
              <Panel.Header title='Próprio' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-1 mb-3'>
                    <Switch
                      formControl
                      label='Controla Estoque'
                      checked={oficinaData.flgControlaEstoque}
                      onChange={(flgControlaEstoque) =>
                        setOficinaData({ ...oficinaData, flgControlaEstoque })
                      }
                    />
                  </div>
                </div>
                {oficinaData?.flgControlaEstoque && (
                  <>
                    <div className='row mb-3'>
                      <div className='col-6'>
                        <Autocomplete
                          label='Almoxarifado'
                          searchDataSource={onSearchAlmoxarifado}
                          selectedItem={oficinaAlmoxarifadoData.almoxarifado}
                          onSelectItem={(almoxarifado) =>
                            setOficinaAlmoxarifadoData({
                              ...oficinaAlmoxarifadoData,
                              almoxarifado,
                              nrSeqAlmoxarifado: almoxarifado.nrSeqAlmoxarifado,
                            })
                          }
                          dataSourceTextProperty='noAlmoxarifado'
                        />
                      </div>
                      <div className='col-2 mb-3'>
                        <Button
                          outline
                          size={BootstrapSizes.Small}
                          theme={Theme.Success}
                          template={Button.Templates.Quick}
                          onClick={onAdicionarOficinaAlmoxarifados}
                          text='Adicionar'
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12'>
                        <GridView
                          ref={gridViewOficinaAlmoxariifados}
                          className='table-striped table-hover table-bordered table-sm'
                          dataColumns={columns}
                          showPagination={false}
                          showSelectSizes={false}
                          offlineData
                        />
                      </div>
                    </div>
                  </>
                )}
              </Panel.Body>
            </Panel>
          </div>
        )}
      </div>
    );
  }
);

export default OficinaPanel;
