import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Textbox } from 'ui/components';
import {
  getTipoChamado,
  saveTipoChamado,
  deleteTipoChamado,
} from 'core/services/HELP/tipoChamado';
import { getAreaChamadoAutoComplete } from 'core/services/HELP/areaChamado';
import { Message, Page } from 'ui/Helpers/interfaces';
import TipoChamado from 'core/models/HELP/tipoChamado';
import { ResponseStatus, Theme } from 'ui/Helpers/enums';
import AreaChamado from 'core/models/HELP/areaChamado';

export default function TipoChamadoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit: any = useRef();

  const [data, setData] = useState<TipoChamado>(new TipoChamado());
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const onSetMessage = (status: number, msg: string): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      setData(await getTipoChamado(registryKey));

      setLoading(false);
    } else {
      setData(new TipoChamado());
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    load();
  }, [load, registryKey, reload]);

  const save = async (): Promise<void> => {
    setLoading(true);

    const { status, message: msg } = await saveTipoChamado(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? new TipoChamado() : data);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onExcluir = async (): Promise<void> => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const { status, message: msg } = await deleteTipoChamado(registryKey);

      setData(status === ResponseStatus.Success ? new TipoChamado() : data);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      setLoading(false);
    }
  };

  const onSearchAreaChamado = async (e: string): Promise<AreaChamado[]> => {
    const {
      status,
      message: msg,
      areasChamado,
    } = await getAreaChamadoAutoComplete({ noAreaChamado: e });

    if (msg) onSetMessage(status, msg);

    return areasChamado;
  };

  const { id: idSelecao } = PageTypes.Selection;

  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Tipo de Chamado'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData(new TipoChamado())}
      onSave={save}
      onDelete={data?.nrSeqTipoChamado > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox
            maxLength={20}
            readOnly
            label='Código'
            text={data.cdTipoChamado}
            onChangedValue={(cdTipoChamado: string) =>
              setData({ ...data, cdTipoChamado })
            }
          />
        </div>
        <div className='col'>
          {/* @ts-expect-error */}
          <Textbox
            maxLength={100}
            required
            label='Tipo de chamado'
            text={data.noTipoChamado}
            onChangedValue={(noTipoChamado: string) =>
              setData({ ...data, noTipoChamado })
            }
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Area do chamado'
            required
            searchDataSource={onSearchAreaChamado}
            selectedItem={data.area}
            onSelectItem={(area: AreaChamado) => {
              setData({
                ...data,
                area,
                nrSeqAreaChamado: area.nrSeqAreaChamado,
              });
            }}
            dataSourceTextProperty='noAreaChamado'
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
