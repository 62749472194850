export default class InfracaoGravidade {
  constructor(jsonObject = {}) {
    this.nrSeqInfracaoGravidade = jsonObject.nrSeqInfracaoGravidade;
    this.noInfracaoGravidade = jsonObject.noInfracaoGravidade;
    this.cdInfracaoGravidade = jsonObject.cdInfracaoGravidade;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrPonto = jsonObject.nrPonto;
  }

  toJson = () => JSON.stringify(this);
}
