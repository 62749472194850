import React, { useState, useRef, useEffect } from 'react';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  BootstrapSizes,
  ResponseStatus,
  JustifyContent,
  Theme,
} from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  Loader,
  Modal,
  GridView,
  Textbox,
  Switch,
  Panel,
  Notification,
} from 'ui/components';

import { getChipAutoComplete } from 'core/services/TEL/chip';
import { getPlanoAutoComplete } from 'core/services/TEL/plano';

import {
  changePlan,
  getClienteLinhaList,
} from 'core/services/TEL/clienteLinha';

export default function ModalAlterarPlano({ recurso, show, onClose }) {
  const [data, setData] = useState({ nrSeqPlano: 1 });
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const gridViewAlterarPlano = useRef();
  const columnsClientes = [
    { key: 'nrSeqEncargomanual', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'cliente.noPessoa',
      title: 'Cliente',
      visible: true,
    },
    {
      key: 'linhaFormatada',
      title: 'Nr. Linha',
      visible: true,
    },
    {
      key: 'plano.noPlano',
      title: 'Plano Atual',
      visible: true,
    },
    {
      key: 'flgSituacaoStr',
      title: 'Situação',
      visible: true,
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchChip = async (e) => {
    const { status, message: msg, chips } = await getChipAutoComplete({
      nrPrefixoLinha: e,
    });

    onSetMessage(status, msg);

    return chips;
  };

  const onSearchPlano = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoComplete({
      noPlano: e,
    });
    onSetMessage(status, msg);
    return planos;
  };

  const onClickAdicionar = async () => {
    const filters = { nrSeqChip: data.nrSeqChip, flgStatus: 'N' };
    const response = await getClienteLinhaList(filters);
    if (response.linhas[0] !== undefined) recurso.push(response.linhas[0]);
    gridViewAlterarPlano.current.setDataSource(recurso);
  };

  const onSend = async () => {
    setLoading(true);
    const nrSeqClientesLinhas = recurso.map(
      (column) => column.nrSeqClienteLinha
    );

    const datasend = {
      nrSeqClientesLinhas,
      nrSeqPlano: data.nrSeqPlano,
      flgCriarNovoClienteLinha: data.flgCriarNovoClienteLinha,
      flgMigrarParcelamento: data.flgMigrarParcelamento,
      dtAquisicao: data.dtAquisicao,
    };

    const { status, message: msg } = await changePlan(datasend);
    setLoading(false);
    onSetMessage(status, msg);
  };

  useEffect(() => {
    (async function load() {
      setMessage(null);
      if (recurso.length > 0)
        gridViewAlterarPlano.current.setDataSource(recurso);
    })();
  }, [recurso]);

  return (
    <Modal
      show={show}
      title='Linhas para troca de plano'
      onClose={onClose}
      size={BootstrapSizes.Large}
      icon={['far', 'comment-alt']}
      message={message}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col-6'>
            <Autocomplete
              label='Nº Linha (Chip)'
              required
              searchDataSource={onSearchChip}
              selectedItem={data.chip}
              onSelectItem={(chip) => {
                setData({
                  ...data,
                  chip,
                  nrSeqChip: chip.nrSeqChip,
                });
              }}
              dataSourceTextProperty='linhaFormatada'
            />
          </div>

          <div className='col-auto'>
            <Button
              outline
              theme={Theme.Success}
              template={Button.Templates.Button}
              text='Adicionar'
              onClick={onClickAdicionar}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridViewAlterarPlano}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnsClientes}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <Panel>
              <Panel.Header
                title='Antes de salvar, ajuste as configurações para efetivar a mudança de plano nas linhas selecionadas acima.'
                theme={Theme.Light}
                align={JustifyContent.Start}
                fontSize='0.7em'
              />
              <Panel.Body>
                <div className='row'>
                  <div className='col-3'>
                    <Textbox
                      label='Data Aquisição'
                      text={data.dtAquisicao}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChangedValue={(dtAquisicao) =>
                        setData({
                          ...data,
                          dtAquisicao,
                        })
                      }
                    />
                  </div>

                  <div className='col-5'>
                    <Autocomplete
                      label='Plano'
                      required
                      searchDataSource={onSearchPlano}
                      selectedItem={data.plano}
                      onSelectItem={(plano) => {
                        setData({
                          ...data,
                          plano,
                          nrSeqPlano: plano.nrSeqPlano,
                        });
                      }}
                      dataSourceTextProperty='noPlano'
                    />
                  </div>

                  <div className='col-sm-3'>
                    <Switch
                      formControl
                      label='Criar novo cliente linha?'
                      checked={data.flgCriarNovoClienteLinha}
                      onChange={(flgCriarNovoClienteLinha) => {
                        setData({
                          ...data,
                          flgCriarNovoClienteLinha,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-3'>
                    <Switch
                      formControl
                      label='Migrar Parcelamento'
                      checked={data.flgMigrarParcelamento}
                      onChange={(flgMigrarParcelamento) => {
                        setData({
                          ...data,
                          flgMigrarParcelamento,
                        });
                      }}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Salvar'
          theme={Theme.Success}
          icon='sms'
          onClick={onSend}
        />
        <Button
          text='Sair'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            setData({});
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
