import React, { forwardRef, useState } from 'react';
import { setTransaction } from 'core/services/api';
import {
  Confirm,
  Form,
  Loader,
  Notification,
  Panel,
  ToolbarButtons,
} from 'ui/components';

const CSDManutPage = forwardRef((props, formSubmit) => {
  const {
    children,
    loading,
    isActive,
    title,
    message,
    onMessagerClose,
    onBack,
    onNew,
    onSave,
    onDelete,
    onPrint,
    nestedForms = [],
    removeValidate,
    transaction,
  } = props;

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleDelete = async ({ confirmed, canceled }) => {
    if (!confirmed && !canceled) setShowConfirmDelete(true);
    else if (confirmed) {
      await onDelete();
      setShowConfirmDelete(false);
      setTransaction(transaction ? transaction.nrSeqTransacao : 0);
    } else setShowConfirmDelete(false);
  };

  return (
    <Panel className={!isActive ? 'd-none' : 'CSDPage'}>
      <Loader loading={loading} />

      {onDelete && transaction.permissao.flgExcluir && (
        <Confirm
          show={showConfirmDelete}
          title='Confirmar a exclusão de registro'
          titleIcon='exclamation-triangle'
          text='Você confirma a exclusão deste registro ?'
          handleConfirm={async () => {
            await handleDelete({ confirmed: true });
          }}
          handleCancel={() => handleDelete({ canceled: true })}
        />
      )}

      <Form
        ref={formSubmit}
        onSubmit={async () => {
          setIsSaving(true);
          setTransaction(transaction ? transaction.nrSeqTransacao : 0);
          if (onSave) await onSave();
          setIsSaving(false);
        }}
        removeValidate={removeValidate}
      >
        <Panel.Header title={title} classSize='display-7'>
          <ToolbarButtons fixedOnTop>
            {onBack && (
              <ToolbarButtons.Button
                text='Voltar'
                icon='arrow-circle-left'
                onClick={onBack}
              />
            )}
            {onNew && transaction.permissao.flgNovo && (
              <ToolbarButtons.Button text='Novo' icon='plus' onClick={onNew} />
            )}
            {onSave && transaction.permissao.flgSalvar && (
              <ToolbarButtons.Button
                text='Salvar'
                icon={['far', 'save']}
                type='submit'
                isSaving={isSaving}
              />
            )}
            {onDelete && transaction.permissao.flgExcluir && (
              <ToolbarButtons.Button
                text='Excluir'
                icon='trash-alt'
                onClick={handleDelete}
              />
            )}
            {onPrint && transaction.permissao.flgImprimir && (
              <ToolbarButtons.Button
                text='Imprimir'
                icon='print'
                onClick={onPrint}
              />
            )}
            {children &&
              React.Children.map(children, (child) =>
                child && child.type.name === ToolbarButtons.name
                  ? child.props.children
                  : null
              )}
          </ToolbarButtons>
        </Panel.Header>
        <Panel.Body>
          {message && (
            <div className='mb-3'>
              <Notification
                floatable
                message={message.message}
                theme={message.theme}
                onClose={onMessagerClose}
              />
            </div>
          )}
          {children &&
            React.Children.map(children, (child) =>
              child && child.type.name !== ToolbarButtons.name ? child : null
            )}
        </Panel.Body>
      </Form>

      {nestedForms}
    </Panel>
  );
});
export default CSDManutPage;
