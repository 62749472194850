import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  JustifyContent,
  BootstrapSizes,
  dateNow,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  Panel,
  Button,
  GridView,
  DatePicker,
  Switch,
  ToolbarButtons,
  Address,
  Textarea,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';

import { TransacationCodes } from 'ui/Helpers/transactionCodes';

import {
  getNfs,
  getConfig,
  saveNfs,
  deleteNfs,
  transmitNfs,
  estornarNfs,
  printNfs,
  calculateTaxes,
  recalculateTaxes,
  effect,
  sendEmail,
  sendWhatsApp,
} from 'core/services/VEN/nfs';

import { generateParcels } from 'core/services/VEN/nfsFatura';
import { getNfsItemTelephonyContracts } from 'core/services/VEN/nfsItem';

import { getProdutoServicoAutoComplete } from 'core/services/VEN/produtoServico';
import { getContratoServicoFullAutoComplete } from 'core/services/GProj/contratoServico';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';

import {
  getTipoServico,
  getTipoServicoAutoComplete,
} from 'core/services/GProj/tipoServico';
import { getCondicaoPagamentoAutoComplete } from 'core/services/FIN/condicaoPagamento';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';

import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';

import { NfsFatura, Nfs, NfsImpostos } from 'core/models/VEN';
import NfsItem from 'core/models/VEN/nfsItem';
import {
  buscaMotoristaEVeiculos,
  getNotaPelaChave,
  recalculateFreight,
} from 'core/services/FRO/viagem';
import { ColetaEmbarque, ColetaEmbarqueDocumento } from 'core/models/FRO';
import {
  buscaCamposChaveNfe,
  validaDuplicidadeDocumento,
} from 'core/services/FRO/coletaEmbarque_Documento';
import ModalNotaDuplicada from 'ui/pages/FRO/Viagem/modalNotaDuplicada';
import {
  downloadXML,
  getNFEImportadaList,
} from 'core/services/COP/nfeimportada';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getMotoristaViagemAutoComplete } from 'core/services/FRO/motorista';

import ModalImportNF from './modalImportNF';

export default function NotaFiscalItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  onOpenReport,
  findTransaction,
  onOpenTransaction,
}) {
  const formSubmit = useRef();
  const [modalNotaDuplicada, setModalNotaDuplicada] = useState({});
  const [tipoDocumentoPadrao] = useState();
  const [tipoServicoTransporte, setTipoServicoTransporte] = useState(false);
  const [parameters] = useState({
    enabledMotorista: true,
  });
  const [data, setData] = useState(
    new Nfs({ vlrLiquido: 0, impostos: new NfsImpostos() })
  );
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [config, setConfig] = useState({ flgShowLocationService: false });
  const [dataItemFatura, setDataItemFatura] = useState(new NfsFatura());
  const [dataNotaFiscal, setDataNotaFiscal] = useState({});
  const [dataItemServico, setDataItemServico] = useState(
    new NfsItem({ vlrDesconto: 0 })
  );
  const [coletaEmbarque, setColetaEmbarque] = useState(
    new ColetaEmbarque({ flgGeraSeguro: true })
  );
  const [modalimportaNf, setModalimportaNf] = useState({});

  const gridViewServicos = useRef();
  const gridViewFaturamento = useRef();
  const gridViewDocumentos = useRef();
  const modalImportNfRef = useRef({});

  const [documento, setDocumento] = useState(
    new ColetaEmbarqueDocumento({
      flgRemetente: true,
    })
  );
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onClickEditServico = async (selectedValue, datasource) => {
    const dataServicos = datasource.filter(
      (servico) => servico.nrSeqNfsItem !== selectedValue.nrSeqNfsItem
    );

    const dataServico = datasource.filter(
      (servico) => servico.nrSeqNfsItem === selectedValue.nrSeqNfsItem
    );
    dataServico[0].status = GridView.EnumStatus.Alterar;
    setDataItemServico(dataServico[0]);

    if (gridViewServicos && gridViewServicos.current)
      gridViewServicos.current.setDataSource(dataServicos);

    const tiposervico = await getTipoServico(
      selectedValue.tipoServico.nrSeqTipoServico
    );

    if (tiposervico.flgTransporte) {
      setTipoServicoTransporte(tiposervico.flgTransporte);
      setDataNotaFiscal({
        vlrTotalFrete: data.vlrTotalFrete,
        vlrTarifaFrete: data.vlrTarifaFrete,
        nrQuantidadeFrete: data.nrQuantidadeFrete,
        nrPesoFrete: data.nrPesoFrete,
        chNfe: data.chNfe,
        nrNotaFiscal: data.chNfe.substring(26, 34),
      });
    }
  };

  const onClickEditFatura = async (selectedValue, datasource) => {
    const dataFaturas = datasource.filter(
      (servico) => servico !== selectedValue
    );

    const dataFatura = datasource.filter(
      (servico) => servico === selectedValue
    );

    dataFatura[0].status = GridView.EnumStatus.Alterar;
    setDataItemFatura(dataFatura[0]);

    if (gridViewFaturamento && gridViewFaturamento.current)
      gridViewFaturamento.current.setDataSource(dataFaturas);
  };

  const onClickRemoveServico = async (selectedValue, datasource) => {
    const dataServicos = datasource.filter(
      (servico) => servico !== selectedValue
    );

    const dataServico = datasource.filter(
      (servico) => servico === selectedValue
    );

    dataServico[0].status = GridView.EnumStatus.Remover;
    dataServicos.push(dataServico[0]);

    if (gridViewServicos && gridViewServicos.current)
      gridViewServicos.current.setDataSource(dataServicos);
  };

  const onClickRemoveFatura = async (selectedValue, datasource) => {
    const dataFaturas = datasource.filter(
      (servico) => servico.nrSeqFatura !== selectedValue.nrSeqFatura
    );

    const dataFatura = datasource.filter(
      (servico) => servico.nrSeqFatura === selectedValue.nrSeqFatura
    );

    dataFatura[0].status = GridView.EnumStatus.Remover;
    dataFaturas.push(dataFatura[0]);

    if (gridViewFaturamento && gridViewFaturamento.current)
      gridViewFaturamento.current.setDataSource(dataFaturas);
  };
  const [resumoConhecimento, setResumoConhecimento] = useState({
    qtdDocs: 0,
    pesoDoc: 0,
    qtdTotalDoc: 0,
    valorDoc: 0,
    valorFaturas: 0,
  });
  const [manterInfo] = useState({
    peso: false,
    tarifa: false,
    remetente: false,
    destinatario: false,
    tipoEmbarque: false,
    pedagio: false,
    buscaSefaz: true,
    buscaNfe: true,
  });
  const columnsDocumentos = [
    {
      key: 'tipoDocumento.cdTipoDocumento',
      title: 'Tipo Documento',
      visible: false,
    },
    { key: 'chaveNfe', title: 'ChNfe', visible: false },
    { key: 'nrNotaFiscal', title: 'Número', visible: false },
    {
      key: 'dtEmissao',
      title: 'Data',
      format: GridView.DataTypes.Date,
      visible: false,
    },
    { key: 'quantidade', title: 'Quantidade', visible: false },
    {
      key: 'vlrNota',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
      visible: false,
    },
    {
      key: 'peso',
      title: 'Peso',
      format: GridView.DataTypes.Decimal,
      visible: false,
    },
  ];

  const columnsServicos = [
    {
      key: 'nrSeqNfsItem',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },

    { key: 'produtoServico.noProdutoServico', title: 'Serviço' },
    { key: 'tipoServico.noTipoServico', title: 'Tipo Serviço' },
    { key: 'noDescricao', title: 'Descrição' },
    {
      key: 'quantidade',
      title: 'Quantidade',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'valorUnitario',
      title: 'Valor Unitário',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotal',
      title: 'Valor Total',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrDesconto',
      title: 'Valor Desconto',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'nrSeqNfsItem',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditServico(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqNfsItem',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveServico(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsFaturamento = [
    { key: 'nrSeqFatura', type: GridView.ColumnTypes.Checkbox, visible: false },
    { key: 'formaPagamento.noFormaPagamento', title: 'Forma Pagamento' },
    { key: 'vlrParcela', title: 'Valor', format: GridView.DataTypes.Decimal },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },

    {
      key: 'nrSeqFatura',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditFatura(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqFatura',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveFatura(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onCalculateTaxes = async (nfs) => {
    const { status, message: msg, value: nf } = await calculateTaxes(
      nfs ?? data
    );
    if (status === ResponseStatus.Success) {
      setData(nf);
    } else {
      onSetMessage(status, msg);
    }
  };

  const onRecalculateTaxes = async (nfs) => {
    const { status, message: msg, value: nf } = await recalculateTaxes(
      nfs ?? data
    );
    if (status === ResponseStatus.Success) {
      setData(nf);
    } else {
      onSetMessage(status, msg);
    }
  };

  const onGetTelephonyContracts = async () => {
    const {
      status,
      message: msg,
      data: services,
    } = await getNfsItemTelephonyContracts(
      dataItemServico.nrSeqContratoServico
    );
    if (status === ResponseStatus.Success) {
      if (gridViewServicos && gridViewServicos.current) {
        gridViewServicos.current.setDataSource(services);
        setData({ ...data, itens: services });

        await onCalculateTaxes({ ...data, itens: services });
      }
    } else {
      onSetMessage(status, msg);
    }
  };

  const onClickAddServico = async () => {
    if (!data.nrSeqEmpresa) {
      onSetMessage(
        Theme.Danger,
        'Necessário informar a empresa para adicionar o serviço.'
      );
      return;
    }

    if (!data.nrSeqPessoaCli) {
      onSetMessage(
        Theme.Danger,
        'Necessário informar o cliente para adicionar o serviço.'
      );
      return;
    }

    if (
      dataItemServico.nrSeqTipoServico <= 0 ||
      dataItemServico.nrSeqTipoServico === undefined
    ) {
      onSetMessage(Theme.Danger, 'Necessário informar o tipo de serviço.');
    }
    if (
      dataItemServico.nrSeqProdutoServico <= 0 ||
      dataItemServico.nrSeqProdutoServico === undefined
    ) {
      onSetMessage(Theme.Danger, 'Necessário informar o produto serviço.');
    } else if (dataItemServico.quantidade <= 0 || !dataItemServico.quantidade) {
      onSetMessage(Theme.Danger, 'Necessário informar a quantidade.');
    } else if (!dataItemServico.valorUnitario) {
      onSetMessage(Theme.Danger, 'Necessário informar o valor unitário.');
    } else {
      let dataServicos = gridViewServicos.current.getDataSource();
      if (!dataServicos) dataServicos = [];

      if (!dataItemServico.vlrDesconto) dataItemServico.vlrDesconto = 0;
      if (!dataItemServico.status)
        dataItemServico.status = GridView.EnumStatus.Inserir;

      if (tipoServicoTransporte) {
        data.vlrTotalFrete = dataNotaFiscal.vlrTotalFrete;
        setData({ ...data, vlrTotalFrete: dataNotaFiscal.vlrTotalFrete });
      }

      dataServicos.push(dataItemServico);

      if (gridViewServicos && gridViewServicos.current) {
        gridViewServicos.current.setDataSource(dataServicos);

        setData({ ...data, itens: dataServicos });

        setDataItemServico(
          new NfsItem({
            quantidade: 0,
            valorUnitario: 0,
            vlrTotal: 0,
            vlrDesconto: 0,
          })
        );
      }

      await onCalculateTaxes({
        ...data,
        itens: dataServicos,
        nrPesoFrete: dataNotaFiscal.nrPesoFrete,
      });
    }
  };

  const onClickAddFatura = async () => {
    if (
      dataItemFatura.nrSeqFormaPagamento <= 0 ||
      dataItemFatura.nrSeqFormaPagamento === undefined
    ) {
      onSetMessage(Theme.Danger, 'Necessário informar a forma de pagamento.');
    } else if (dataItemFatura.vlrParcela <= 0) {
      onSetMessage(Theme.Danger, 'Necessário informar o valor da parcela.');
    } else if (
      dataItemFatura.dtVencimento === undefined ||
      dataItemFatura.dtVencimento === null
    ) {
      onSetMessage(Theme.Danger, 'Necessário informar a data de vencimento.');
    } else {
      const dataFaturamento = gridViewFaturamento.current.getDataSource();
      dataItemFatura.status = GridView.EnumStatus.Inserir;
      dataFaturamento.push(dataItemFatura);

      if (gridViewFaturamento && gridViewFaturamento.current) {
        gridViewFaturamento.current.setDataSource(dataFaturamento);
        setData({ ...data, faturas: dataFaturamento });
        setDataItemFatura(new NfsFatura());
      }
    }
  };

  const onTransmit = async () => {
    setLoading(true);
    const { status, message: msg, data: nfs } = await transmitNfs(data);
    if (status === ResponseStatus.Success) {
      setData(nfs);
    }
    onSetMessage(status, msg);
    setLoading(false);
    return true;
  };

  const onEstorn = async () => {
    setLoading(true);
    const { status, message: msg, data: nfs } = await estornarNfs(data);
    if (status === ResponseStatus.Success) {
      setData(nfs);
    }
    onSetMessage(status, msg);
    setLoading(false);
    return true;
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
    });
    onSetMessage(status, msg);
    return formasPagamento;
  };

  const clearForm = () => {
    setData(
      new Nfs({
        vlrLiquido: 0,
        impostos: new NfsImpostos({
          vlrBaseCalIssqn: 0,
          aliqIssqn: 0,
          vlrIssqn: 0,
          vlrOutrasRetencoes: 0,
          vlrBaseCalPis: 0,
          aliqPis: 0,
          vlrPis: 0,
          vlrBaseCalCofins: 0,
          aliqCofins: 0,
          vlrCofins: 0,
          vlrBaseCalCsll: 0,
          aliqCsllRetido: 0,
          vlrCsllRetido: 0,
          aliqCsllContador: 0,
          vlrCsllContador: 0,
          vlrBaseCalIRPJ: 0,
          aliqIrpjRetido: 0,
          vlrIrpjRetido: 0,
          aliqIrpjContador: 0,
          vlrIrpjContador: 0,
          vlrBaseCalINSS: 0,
          aliqInss: 0,
          vlrInss: 0,
          vlrBaseCalDeson: 0,
          aliqDesonContador: 0,
          vlrDesonContador: 0,
        }),
        obsNfs: '',
      })
    );
    setDataItemFatura(new NfsFatura({ vlrParcela: 0 }));
    setDataItemServico(
      new NfsItem({
        quantidade: 0,
        valorUnitario: 0,
        vlrTotal: 0,
        tiposervico: {},
      })
    );
    setTipoServicoTransporte(false);
    gridViewFaturamento.current.setDataSource([]);
    gridViewServicos.current.setDataSource([]);
  };

  const load = useCallback(async () => {
    setLoading(true);
    const configuracao = await getConfig();
    setConfig(configuracao.flgShowLocationService);

    if (Number.isInteger(registryKey)) {
      const nota = await getNfs(registryKey);

      setData(nota);
      setDataNotaFiscal({
        vlrTotalFrete: nota.vlrTotalFrete,
        vlrTarifaFrete: nota.vlrTarifaFrete,
        nrQuantidadeFrete: nota.nrQuantidadeFrete,
        nrPesoFrete: nota.nrPesoFrete,
        chNfe: nota.chNfe,
        nrNotaFiscal: nota.chNfe?.substring(26, 34),
      });

      if (gridViewServicos && gridViewServicos.current) {
        gridViewServicos.current.setDataSource(nota.itens);
      }

      if (gridViewFaturamento && gridViewFaturamento.current) {
        gridViewFaturamento.current.setDataSource(nota.faturas);
      }
    } else {
      clearForm();
      if (configuracao.formaPagamento) {
        setDataItemFatura({
          ...dataItemFatura,
          formaPagamento: configuracao.formaPagamento,
          nrSeqFormaPagamento: configuracao.formaPagamento.nrSeqFormaPagamento,
        });
      }
    }
    setLoading(false);
    setMessage(null);
  }, [registryKey]);

  const onEffect = async () => {
    setLoading(true);
    const { status, message: msg, value: nfsEfetivada } = await effect(data);
    if (status === ResponseStatus.Success) {
      setData(nfsEfetivada);
      onSetMessage(status, msg);
    } else {
      onSetMessage(status, msg);
    }
    setLoading(false);
  };

  const onSendEmail = async () => {
    setLoading(true);
    const { status, message: msg } = await sendEmail(data);

    onSetMessage(status, msg);

    setLoading(false);
  };

  const onSendWhatsApp = async () => {
    setLoading(true);
    const { status, message: msg } = await sendWhatsApp(data);

    onSetMessage(status, msg);

    setLoading(false);
  };

  const onGenerateParcels = async () => {
    setLoading(true);
    const { status, message: msg, value: faturas } = await generateParcels(
      data
    );
    if (status === ResponseStatus.Success) {
      const faturasgrid = gridViewFaturamento.current.getDataSource();
      const newfaturas = faturas.concat(faturasgrid);

      setData({ ...data, faturas: newfaturas });
      gridViewFaturamento.current.setDataSource(newfaturas);
    } else {
      onSetMessage(status, msg);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  useEffect(() => {}, [load, reload]);

  const save = async () => {
    setLoading(true);
    data.faturas = gridViewFaturamento.current.getDataSource();
    data.itens = gridViewServicos.current.getDataSource();

    if (tipoServicoTransporte) {
      data.nrQuantidadeFrete = dataNotaFiscal.nrQuantidadeFrete;
      data.nrPesoFrete = dataNotaFiscal.nrPesoFrete;
      data.vlrTotalFrete = dataNotaFiscal.vlrTotalFrete;
      data.vlrTarifaFrete = dataNotaFiscal.vlrTarifaFrete;
      data.chNfe = dataNotaFiscal.chnfe;
    }
    const { status, message: msg, data: nfs } = await saveNfs(data);

    if (status === ResponseStatus.Success) {
      setData(nfs);
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteNfs(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printNfs(data.nrSeqNfs);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onSearchPessoaCli = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
      flgIncluirEndereco: true,
    });
    if (msg) onSetMessage(status, msg);
    return clientes;
  };

  const onSearchProdutoServico = async (e) => {
    const {
      status,
      message: msg,
      data: servicos,
    } = await getProdutoServicoAutoComplete({
      noProdutoServico: e,
    });
    if (msg) onSetMessage(status, msg);
    return servicos;
  };

  const onSearchTipoServico = async (e) => {
    const {
      status,
      message: msg,
      data: servicos,
    } = await getTipoServicoAutoComplete({
      noTipoServico: e,
      FlgGeraNfs: true,
    });
    if (msg) onSetMessage(status, msg);
    return servicos;
  };

  const onSearchContratoServico = async (e) => {
    const {
      status,
      message: msg,
      data: contratos,
    } = await getContratoServicoFullAutoComplete({
      noContratoServico: e,
      flgAtivo: true,
      nrSeqPessoaCli: data.nrSeqPessoaCli,
    });
    if (msg) onSetMessage(status, msg);
    return contratos;
  };

  const onSearchCondicaoPagamento = async (e) => {
    const {
      status,
      message: msg,
      condicoesPagamento,
    } = await getCondicaoPagamentoAutoComplete({
      noCondicaoPagamento: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });
    if (msg) onSetMessage(status, msg);
    return condicoesPagamento;
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const setContratoServico = async (contratoServico) => {
    const itemServico = {
      noDescricao: contratoServico.noContratoServico,
      quantidade: contratoServico.vlrBase,
      valorUnitario: contratoServico.vlrUnitario,
      vlrTotal:
        contratoServico.vlrBase *
        (contratoServico.vlrUnitario ? contratoServico.vlrUnitario : 0),
    };

    setDataItemServico({
      ...itemServico,
      contratoServico,
      tipoServico: contratoServico.tipoServico,
      nrSeqTipoServico: contratoServico.nrSeqTipoServico,
      produtoServico: contratoServico.produtoServico,
      nrSeqProdutoServico: contratoServico.nrSeqProdutoServico,
      condicaoPagamento: contratoServico.condicaoPagamento,
      nrSeqCondicaoPagamento: contratoServico.nrSeqCondicaoPagamento,
      nrSeqContratoServico: contratoServico.nrSeqContratoServico,
    });

    setData({
      ...data,
      condicaoPagamento: contratoServico.condicaoPagamento,
      nrSeqCondicaoPagamento: contratoServico.nrSeqCondicaoPagamento,
    });
  };

  const validaBuscaPelaChave = () => {
    if (!documento) {
      setDocumento({});
      return false;
    }
    if (documento.chaveNfe?.length === 0) {
      setDocumento({});
      return false;
    }
    if (documento.chaveNfe?.length !== 44 && documento.chaveNfe?.length > 0) {
      onSetMessage(1, 'Chave Inválida!');
      return false;
    }

    return true;
  };

  const preencheCamposPelaChave = async () => {
    setLoading(true);

    if (!validaBuscaPelaChave()) {
      return;
    }

    const { data: document, status, message: msg } = await buscaCamposChaveNfe({
      chaveNfe: documento.chaveNfe,
    });

    let peso = 0;

    const { data: nfes } = await getNFEImportadaList({
      chNfe: documento.chaveNfe,
    });

    if (nfes.length > 0) {
      const { data: xml } = await downloadXML(nfes[0].nrSeqNfeImportada);
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xml, 'text/xml');
      peso = xmlDoc.getElementsByTagName('pesoB')[0].textContent;
    }

    if (status === ResponseStatus.Success) {
      setDataNotaFiscal({
        ...dataNotaFiscal,
        nrNotaFiscal: document.nrNotaFiscal,
        nrPesoFrete: dataNotaFiscal.nrPesoFrete ?? peso,
      });
      setDocumento(document);
    } else if (msg) {
      onSetMessage(status, msg);
      if (documento.chaveNfe) {
        documento.chaveNfe = documento.chaveNfe.replace(' ', '');
        documento.chaveNfe = documento.chaveNfe.replace('.', '');
        documento.chaveNfe = documento.chaveNfe.replace('-', '');
        documento.chaveNfe = documento.chaveNfe.replace('/', '');
        setDocumento(documento);
      }
    }

    setLoading(false);
  };
  const validaDuplicidade = async (document) => {
    const { data: modal } = await validaDuplicidadeDocumento({
      coletaEmbarque,
      coletaEmbarqueDocumento: document ?? documento,
    });

    return modal;
  };
  const countQtdNotasEmbarque = (
    coletaEmbarqueDocumento,
    calculaDocumentoAtual
  ) => {
    let quantidade = 0;
    const documentos = gridViewDocumentos.current.getDataSource();

    if (documentos) {
      documentos
        .filter((item) => item.status !== GridView.EnumStatus.Remover)
        .forEach((item) => {
          quantidade += item.quantidade;
        });

      if (calculaDocumentoAtual)
        quantidade += coletaEmbarqueDocumento.quantidade;
    }

    return quantidade;
  };
  const calculateFrameResumoConhecimento = () => {
    let qtdDocs = 0;
    let pesoDoc = 0;
    let qtdTotalDoc = 0;
    let valorDoc = 0;

    const { qtdFaturas, valorFaturas } = coletaEmbarque;

    const dataDocuments = gridViewDocumentos.current.getDataSource();
    dataDocuments
      .filter((elem) => elem.status !== 'Remover')
      .forEach((elem) => {
        qtdDocs += 1;
        pesoDoc += elem.peso;
        qtdTotalDoc += elem.quantidade;
        valorDoc += elem.vlrNota;
      });

    setResumoConhecimento({
      ...resumoConhecimento,
      qtdDocs,
      pesoDoc,
      qtdTotalDoc,
      valorDoc,
      qtdFaturas,
      valorFaturas,
    });
  };
  const carregarDocumentoImportado = async (
    nota,
    naoAdicionaDados,
    calculaDocumentoAtual
  ) => {
    const qtdeEmbarque = countQtdNotasEmbarque(
      nota.coletaEmbarqueDocumento,
      calculaDocumentoAtual
    );

    nota.coletaEmbarqueDocumento.status = GridView.EnumStatus.Inserir;

    const { coletaEmbarque: embarque } = nota;

    const objColetaEmbarque = {
      ...coletaEmbarque,
      remetente: nota.remetente,
      nrSeqPessoaRem: nota.remetente?.nrSeqPessoaRem,
      destinatario: nota.destinatario,
      nrSeqPessoaDes: nota.destinatario?.nrSeqPessoaDes,
      origemConsig: embarque.origemConsig,
      nrSeqPessoaConsigOri: embarque.origemConsig?.nrSeqPessoaConsig,
      nrSeqPessoaConsigDest: embarque.destinoConsig?.nrSeqPessoaConsig,
      destinoConsig: embarque.destinoConsig,
      motorista: nota.motorista ?? {},
      tipoFrete: embarque.tipoFrete ?? {},
      nrSeqTipoFrete: embarque.tipoFrete?.nrSeqTipoFrete,
      tipoEmbarque: embarque.tipoEmbarque ?? coletaEmbarque.tipoEmbarque,
      nrSeqTipoEmbarque:
        embarque.tipoEmbarque?.nrSeqTipoEmbarque ??
        coletaEmbarque.tipoEmbarque?.nrSeqTipoEmbarque,
      apoliceSeguro: embarque.apoliceSeguro ?? {},
      nrSeqApoliceSeguro: embarque.apoliceSeguro?.nrSeqApoliceSeguro,
      especieFrete: embarque.especieFrete ?? {},
      nrSeqEspecieFrete: embarque.especieFrete?.nrSeqEspecieFrete,
      mercadoriaPrincipal: embarque.mercadoriaPrincipal ?? {},
      nrSeqFreteMercadoria: embarque.mercadoriaPrincipal?.nrSeqFreteMercadoria,
      vlrTarifa: embarque.vlrTarifa ?? 0,
      vlrFrete: embarque.vlrFrete ?? 0,
      vlrTotal: embarque.vlrTotal ?? 0,
      vlrPedagio: embarque.vlrPedagio ?? 0,
      observacao: embarque.observacao,
      remetenteDestinatario: embarque.remetenteDestinatario,
      qtdeEmbarque,
    };

    if (!naoAdicionaDados) setColetaEmbarque(objColetaEmbarque);

    let objData = {};

    if (nota.nrSeqVeiculoPrinc) {
      objData = {
        ...data,
        nrSeqPessoaMot: nota.motorista?.nrSeqPessoaMot,
        motorista: nota.motorista,
        nrSeqVeiculoPrinc: nota.veiculoPrincipal?.nrSeqVeiculo,
        veiculoPrincipal: nota.veiculoPrincipal,
        nrSeqVeiculoComp: nota.veiculoCompA?.nrSeqVeiculo,
        veiculoCompA: nota.veiculoCompA,
        nrSeqVeiculoCompB: nota.veiculoCompB?.nrSeqVeiculo,
        veiculoCompB: nota.veiculoCompB,
      };

      if (!naoAdicionaDados) setData(objData);
    }

    calculateFrameResumoConhecimento();

    return {
      coletaEmbarque: objColetaEmbarque,
      nota: nota.coletaEmbarqueDocumento,
    };
  };

  const onImportaNf = async () => {
    setModalimportaNf({ importaNf: true });
  };

  const recalculaFrete = async (cteCurrent) => {
    const cte = cteCurrent ?? coletaEmbarque;
    if (cte?.nrSeqTipoEmbarque && cte?.nrSeqTipoEmbarque !== 0) {
      setLoading(true);

      const documentosCte = gridViewDocumentos.current.getDataSource();

      const obj = {
        ...data,
        coletaEmbarque: {
          ...cte,
          qtdeEmbarque: countQtdNotasEmbarque(undefined, false),
          documentos:
            documentosCte.length > 0
              ? documentosCte
              : coletaEmbarque.documentos,
          vlrTarifa: dataNotaFiscal.vlrTarifaFrete,
        },
        mantemTarifa: manterInfo.tarifa,
      };

      const { data: frete, status, message: msg } = await recalculateFreight(
        obj
      );

      if (status === ResponseStatus.Success) {
        dataNotaFiscal.vlrTotalFrete = frete.vlrTotal;
        dataNotaFiscal.vlrTarifaFrete = frete.vlrTarifa;
        dataNotaFiscal.nrPesoFrete = frete.documentos[0]?.peso;
        dataNotaFiscal.nrNotaFiscal = documentosCte[0]?.nrNotaFiscal;
        setDataNotaFiscal(dataNotaFiscal);
        setData({ ...data, vlrTarifaFrete: frete.vlrTotal });
        setColetaEmbarque(frete);
        if (tipoServicoTransporte) {
          setDataItemServico({
            ...dataItemServico,
            quantidade: 1,
            valorUnitario: frete.vlrTotal,
            vlrTotal: frete.vlrTotal,
          });
        }
      } else if (msg) {
        onSetMessage(status, msg);
      }

      setLoading(false);
    } else {
      dataNotaFiscal.vlrTotalFrete = (
        dataNotaFiscal.vlrTarifaFrete *
        (dataNotaFiscal.nrPesoFrete / 1000)
      ).toFixed(2);

      setDataNotaFiscal(dataNotaFiscal);
      setData({ ...data, vlrTarifaFrete: dataNotaFiscal.vlrTotalFrete });
      setDataItemServico({
        ...dataItemServico,
        quantidade: 1,
        valorUnitario: dataNotaFiscal.vlrTotalFrete,
        vlrTotal: dataNotaFiscal.vlrTotalFrete,
      });
    }
  };
  const calcularQuantidadeEmbarcadaNoCTE = () => {
    let qtdeEmbarque = 0;
    const dataSource = gridViewDocumentos.current.getDataSource();
    dataSource
      .filter((item) => item.status !== GridView.EnumStatus.Remover)
      .forEach((item) => {
        qtdeEmbarque += item.quantidade;
      });

    setColetaEmbarque((state) => ({ ...state, qtdeEmbarque }));
  };
  const limparDocumentoEmbarque = () => {
    setDocumento(
      new ColetaEmbarqueDocumento({
        tipoDocumento: tipoDocumentoPadrao,
        flgRemetente: true,
        dtEmissao: dateNow(),
        quantidade: 0,
        vlrNota: 0,
        peso: 0,
      })
    );
  };
  const validaArquivosComErro = (dataSource) => {
    let msg = '';

    const documentosInvalidos = dataSource
      .filter((item) => item.status === ResponseStatus.Warning)
      .map((item) => item.data.archiveName);

    if (documentosInvalidos.length > 0) {
      msg = `Documentos inválidos: ${documentosInvalidos
        .map((item) => item)
        .join(', ')}`;
    }

    const documentosComErro = dataSource.filter(
      (item) => item.status === ResponseStatus.Error
    );

    if (documentosComErro.length > 0) {
      if (documentosInvalidos.length) msg += '; ';

      msg += 'Erro desconhecido ao adicionar nota(as).';
    }

    if (msg) onSetMessage(1, msg);
  };
  const calculaTotaisEmbarque = (limpaDocumento) => {
    calcularQuantidadeEmbarcadaNoCTE();

    if (limpaDocumento) limparDocumentoEmbarque();

    calculateFrameResumoConhecimento();
  };

  const incluirDocumentoNaGrid = async (newDocument) => {
    const dataSource = gridViewDocumentos.current.getDataSource();
    newDocument.status = newDocument.status ?? GridView.EnumStatus.Inserir;

    dataSource.push(newDocument);

    setDataNotaFiscal({
      nrNotaFiscal: newDocument.nrNotaFiscal,
      nrPesoFrete: newDocument.peso,
      chNfe: newDocument.chaveNfe,
    });
  };
  const adicionaDocumento = async (recalcula, document, naoCalcula) => {
    const newDocument = document ?? documento;
    incluirDocumentoNaGrid(newDocument);

    if (recalcula) {
      await recalculaFrete();
    }

    if (!naoCalcula) {
      calculaTotaisEmbarque();
    }
  };
  const adicionaVariosDocumentos = async (dataSource) => {
    await Promise.all(
      dataSource.map(async (item) => {
        await adicionaDocumento(false, item, true);
      })
    );
  };
  const modalImportaNFCarregaDocumentosimportados = async (
    datasource,
    naoAdicionaDados
  ) => {
    const documentoImportado = await carregarDocumentoImportado(
      datasource[0].data.viagem,
      naoAdicionaDados
    );

    return documentoImportado.nota;
  };
  const onCloseModalImportaNF = async (datasource) => {
    let duplicated = false;
    let coletaEmbarqueCarregado = null;

    if (datasource.length === 1) {
      if (datasource[0].status === ResponseStatus.Success) {
        const nota = await modalImportaNFCarregaDocumentosimportados(
          datasource,
          true
        );
        adicionaDocumento(false, nota, true);

        duplicated = await validaDuplicidade(nota);
        duplicated = false;
        if (!duplicated) {
          if (parameters.addDocumentoAuto) {
            adicionaDocumento(false, nota, true);
          } else {
            nota.status = nota.status ?? GridView.EnumStatus.Inserir;
            setDocumento(nota);
          }

          const documentoImportado = await carregarDocumentoImportado(
            datasource[0].data.viagem,
            false,
            false
          );

          coletaEmbarqueCarregado = documentoImportado.coletaEmbarque;
        } else {
          setModalimportaNf({
            show: false,
          });

          setModalNotaDuplicada({
            show: true,
            data: nota,
            dataCte: datasource[0].data.viagem,
          });
        }
      } else {
        onSetMessage(1, datasource[0].data.message);
      }
    } else {
      await adicionaVariosDocumentos(
        datasource
          .filter((item) => item.status === ResponseStatus.Success)
          .map((item) => item.data.viagem.coletaEmbarqueDocumento)
      );

      validaArquivosComErro(datasource);

      const documentoImportado = await carregarDocumentoImportado(
        datasource[0].data.viagem,
        false
      );

      coletaEmbarqueCarregado = documentoImportado.coletaEmbarque;
    }

    if (!duplicated) {
      await recalculaFrete(coletaEmbarqueCarregado);

      calculaTotaisEmbarque(false);

      setModalimportaNf({
        show: false,
      });
    }
  };
  const removeEspacos = (str) => str.replace(/\s/g, '');

  const chaveNfeSelectedValue = async (chaveNfe) => {
    chaveNfe = removeEspacos(chaveNfe);
    setDataNotaFiscal({ ...dataNotaFiscal, chnfe: chaveNfe });
    setDocumento({ ...documento, chaveNfe });
  };
  const onCloseModalNotaDuplicada = async (liberadaImportacao, dataSource) => {
    if (liberadaImportacao) {
      let retornoDocumentoImportado = {};

      await adicionaVariosDocumentos(dataSource);

      if (modalNotaDuplicada.dataCte) {
        retornoDocumentoImportado = await carregarDocumentoImportado(
          modalNotaDuplicada.dataCte,
          false,
          false
        );
      }

      await recalculaFrete(retornoDocumentoImportado?.coletaEmbarque);

      calculaTotaisEmbarque();
    }

    setModalNotaDuplicada({
      show: false,
      dataCte: {},
    });
  };
  const onImportaDocumento = async (datasource) => {
    const { viagem: nota } = datasource;

    let notaRetorno = {};

    const document = nota.coletaEmbarqueDocumento;
    let duplicated = await validaDuplicidade(document);
    duplicated = false;
    if (!duplicated) {
      const documentoImportado = await carregarDocumentoImportado(nota);
      notaRetorno = documentoImportado.nota;
      setDocumento(nota.coletaEmbarqueDocumento);
      return notaRetorno;
    }

    setModalNotaDuplicada({
      show: true,
      data: document,
      dataCte: nota,
    });

    return notaRetorno;
  };

  const onBuscaPelaChave = async () => {
    if (documento.chaveNfe) {
      setLoading(true);
      const { status, message: msg, data: nota } = await getNotaPelaChave({
        chNfe: documento.chaveNfe,
        nrSeqEmpresa: 1,
      });

      if (
        status === ResponseStatus.Success ||
        status === ResponseStatus.Warning
      ) {
        await onImportaDocumento(nota);
      }

      onSetMessage(status, msg);
      setLoading(false);
    }
  };

  const onAutoCompleteVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onAutoCompleteMotorista = async (e) => {
    const {
      status,
      message: msg,
      motoristas,
    } = await getMotoristaViagemAutoComplete({
      noPessoa: e,
      nrSeqVeiculo: data.veiculoPrincipal?.nrSeqVeiculo,
    });
    if (msg) onSetMessage(status, msg);
    return motoristas;
  };

  const buscaVeiculoEMotorista = async (id) => {
    setLoading(true);

    const { status, message: msg, value } = await buscaMotoristaEVeiculos({
      nrSeqVeiculo: id,
      nrSeqFreteMercadoria: coletaEmbarque.nrSeqFreteMercadoria,
    });

    setLoading(false);

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Error) return {};

    return {
      veiculoCompA: value.veiculoCompA ?? {},
      veiculoCompB: value.veiculoCompB ?? {},
      veiculoCompC: value.veiculoCompC ?? {},
      motorista: value.motorista ?? {},
      nrSeqPessoaMot: value.motorista?.nrSeqPessoaMot,
      nrSeqVeiculoComp: value.veiculoCompA?.nrSeqVeiculo,
      nrSeqVeiculoCompb: value.veiculoCompB?.nrSeqVeiculo,
      nrSeqVeiculoCompc: value.veiculoCompC?.nrSeqVeiculo,
    };
  };

  const onSelectVeiculoPrincipal = async (veiculo) => {
    setLoading(true);

    let dataSource = {};

    if (veiculo?.nrSeqVeiculo !== undefined)
      dataSource = await buscaVeiculoEMotorista(veiculo.nrSeqVeiculo);

    setData({
      ...data,
      ...dataSource,
      veiculo,
      nrSeqVeiculo: veiculo?.nrSeqVeiculo,
    });

    setLoading(false);

    return dataSource;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Notas Fiscais'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => clearForm()}
      onSave={save}
      onDelete={data?.nrSeqNfs > 0 ? onExcluir : null}
      message={message}
      onPrint={onPrint}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Efetivar'
          icon={['fa', 'check']}
          onClick={() => onEffect()}
        />

        <ToolbarButtons.Button
          text='Enviar E-mail'
          icon={['fa', 'check']}
          onClick={() => onSendEmail()}
        />
        <ToolbarButtons.ButtonList
          icon='caret-down'
          items={[
            {
              text: 'Enviar WhatsApp',
              icon: 'fa-check',
              onClick: onSendWhatsApp,
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Transmitir'
          icon={['fa', 'chevron-right']}
          onClick={() => onTransmit()}
        />
        <ToolbarButtons.Button
          text='Estornar'
          icon={['fa', 'chevron-right']}
          onClick={() => onEstorn()}
        />
      </ToolbarButtons>
      <Panel>
        <Panel.Header
          icon={['fas', 'briefcase']}
          title='Geral'
          theme={Theme.Primary}
          align={JustifyContent.Start}
        />

        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-3'>
              <Textbox
                label='Número da Nota'
                text={data.nrNfs}
                mask={MaskTypes.Integer}
                readOnly
                onChangedValue={(nrNfs) => setData({ ...data, nrNfs })}
              />
            </div>

            <div className='col-3'>
              <Textbox
                maxLength={3}
                label='Série da Nota'
                text={data.serieNFs}
                readOnly
                onChangedValue={(serieNFs) => setData({ ...data, serieNFs })}
              />
            </div>

            <div className='col-6'>
              <Autocomplete
                label='Empresa'
                searchDataSource={onSearchEmpresa}
                selectedItem={data.empresa}
                onSelectItem={(empresa) => {
                  setData({
                    ...data,
                    empresa,
                    nrSeqEmpresa: empresa.nrSeqEmpresa,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-12 mb-3'>
              <Autocomplete
                label='Cliente'
                searchDataSource={onSearchPessoaCli}
                selectedItem={data.cliente}
                onOpenTransaction={onOpenTransaction}
                transactionToOpen={TransacationCodes.Cliente}
                findTransaction={findTransaction}
                nrseqTransactionToOpen='nrSeqPessoaCli'
                onSelectItem={(cliente) => {
                  setData({
                    ...data,
                    cliente,
                    nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                  });

                  getContratoServicoFullAutoComplete({
                    nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                    flgAtivo: true,
                  }).then((item) => {
                    if (item.data.length === 1) {
                      setContratoServico(item.data[0]);
                      setData({
                        ...data,
                        cliente,
                        nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                      });
                    }
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
          </div>

          {config?.flgShowLocationService && (
            <div className='row mb-3'>
              <div className='col'>
                <Address
                  nrSeqPessoa={data.nrSeqPessoaCli}
                  nrSeqEndereco={data.nrSeqEndereco}
                  readOnly
                  onSetLoading={setLoading}
                  title='Local de Prestação do Serviço'
                  onChangeAddress={(endereco) => {
                    setData({
                      ...data,
                      endereco,
                    });
                  }}
                />
              </div>
            </div>
          )}

          <div className='row'>
            <div className='col-auto'>
              <Switch
                formControl
                label='Tributado no Município de Origem'
                checked={data.flgIssqnCidadeOrigem}
                onChange={(flgIssqnCidadeOrigem) =>
                  setData({
                    ...data,
                    flgIssqnCidadeOrigem,
                  })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>

      <div className='row mb-3'>
        <div className='col'>
          <Panel>
            <Panel.Header
              icon={['fa', 'list']}
              title='Serviços'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-6'>
                  <Autocomplete
                    label='Contrato'
                    searchDataSource={onSearchContratoServico}
                    selectedItem={dataItemServico.contratoServico}
                    onSelectItem={(contratoServico) => {
                      setContratoServico(contratoServico);
                    }}
                    dataSourceTextProperty='noContratoServico'
                  />
                </div>
                <div className='col-4'>
                  <Autocomplete
                    label='Tipo de Serviço'
                    searchDataSource={onSearchTipoServico}
                    selectedItem={dataItemServico.tipoServico}
                    onSelectItem={(tipoServico) => {
                      setDataItemServico({
                        ...dataItemServico,
                        tipoServico,
                        nrSeqTipoServico: tipoServico.nrSeqTipoServico,
                        nrSeqProdutoServico: tipoServico.nrSeqProdutoServico,
                        produtoServico: tipoServico.produtoServico,
                      });

                      setData({
                        ...data,
                        flgIssqnCidadeOrigem: tipoServico.flgIssqnCidadeOrigem,
                      });

                      if (tipoServico.flgTransporte) {
                        setTipoServicoTransporte(true);
                      } else {
                        setTipoServicoTransporte(false);
                        setDataNotaFiscal({});
                      }
                    }}
                    dataSourceTextProperty='noTipoServico'
                  />
                </div>
                {dataItemServico.tipoServico?.flgBuscaQtdeRemoto && (
                  <div className='col-2 mt-3'>
                    <Button
                      outline
                      theme={Theme.Success}
                      template={Button.Templates.Button}
                      text='Buscar Quantidade'
                      onClick={onGetTelephonyContracts}
                    />
                  </div>
                )}
              </div>
              {tipoServicoTransporte && (
                <div className='card mt-4 mb-3'>
                  <div className='card-body'>
                    <div className='row mb-3'>
                      <div className='col-4'>
                        <Textbox
                          label='Chave NFE'
                          onBlur={preencheCamposPelaChave}
                          text={dataNotaFiscal.chNfe}
                          onChangedValue={(chNfe) => {
                            chaveNfeSelectedValue(chNfe);
                          }}
                        />
                      </div>
                      <div className='col-2 mt-3'>
                        <Button
                          className='m-1'
                          icon={['fas', 'search']}
                          tooltip='Buscar Pela Chave da Nota'
                          tooltipDirection='bottom'
                          onClick={onBuscaPelaChave}
                          theme={Theme.Primary}
                          template={Button.Templates.Default}
                        />
                        <Button
                          className='m-1'
                          icon='cloud-upload-alt'
                          tooltip='Importar'
                          tooltipDirection='bottom'
                          onClick={onImportaNf}
                          theme={Theme.Warning}
                          template={Button.Templates.Default}
                        />
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col'>
                        <Textbox
                          label='Número NF'
                          readOnly
                          text={dataNotaFiscal.nrNotaFiscal}
                        />
                      </div>
                      <div className='col-2'>
                        <Textbox
                          label='Quantidade'
                          visible={false}
                          mask={MaskTypes.DecimalCustom}
                          text={dataNotaFiscal.nrQuantidadeFrete}
                          onChangedValue={(nrQuantidadeFrete) =>
                            setDataNotaFiscal({
                              ...dataNotaFiscal,
                              nrQuantidadeFrete,
                            })
                          }
                          onBlur={recalculaFrete}
                        />
                      </div>
                      <div className='col-2'>
                        <Textbox
                          label='Peso (kg)'
                          mask={MaskTypes.DecimalCustom}
                          text={dataNotaFiscal.nrPesoFrete}
                          onChangedValue={(nrPesoFrete) =>
                            setDataNotaFiscal({
                              ...dataNotaFiscal,
                              nrPesoFrete,
                            })
                          }
                        />
                      </div>
                      <div className='col'>
                        <Textbox
                          label='Valor Tarifa'
                          onBlur={recalculaFrete}
                          text={dataNotaFiscal.vlrTarifaFrete}
                          mask={MaskTypes.DecimalCustom}
                          onChangedValue={(vlrTarifaFrete) =>
                            setDataNotaFiscal({
                              ...dataNotaFiscal,
                              vlrTarifaFrete,
                            })
                          }
                        />
                      </div>
                      <div className='col'>
                        <Textbox
                          label='Total Frete'
                          readOnly
                          text={dataNotaFiscal.vlrTotalFrete}
                        />
                      </div>
                    </div>

                    <div className='row mb-3'>
                      <div className='col'>
                        <Autocomplete
                          label='Veículo'
                          searchDataSource={onAutoCompleteVeiculo}
                          selectedItem={data.veiculo}
                          onSelectItem={onSelectVeiculoPrincipal}
                          dataSourceTextProperty='noVeiculo'
                        />
                      </div>
                      <div className='col'>
                        <Autocomplete
                          label='Motorista'
                          searchDataSource={onAutoCompleteMotorista}
                          selectedItem={data.motorista}
                          onSelectItem={(motorista) => {
                            setData({
                              ...data,
                              motorista,
                              nrSeqPessoaMot: motorista.nrSeqPessoaMot,
                            });
                          }}
                          dataSourceTextProperty='noPessoa'
                        />
                      </div>
                    </div>

                    <div className='row mb-3'>
                      <div className='col'>
                        <GridView
                          ref={gridViewDocumentos}
                          className='table-striped table-hover table-bordered table-sm'
                          dataColumns={columnsDocumentos}
                          offlineData
                          showPagination={false}
                          showSelectSizes={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className='row mb-3'>
                <div className='col-4'>
                  <Autocomplete
                    label='Serviço'
                    searchDataSource={onSearchProdutoServico}
                    selectedItem={dataItemServico.produtoServico}
                    onSelectItem={(produtoServico) => {
                      setDataItemServico({
                        ...dataItemServico,
                        produtoServico,

                        nrSeqProdutoServico: produtoServico.nrSeqProdutoServico,
                      });
                    }}
                    dataSourceTextProperty='noProdutoServico'
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Quantidade'
                    text={dataItemServico.quantidade}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(quantidade) =>
                      setDataItemServico({
                        ...dataItemServico,
                        quantidade,
                        vlrTotal:
                          quantidade *
                          (dataItemServico.valorUnitario
                            ? dataItemServico.valorUnitario
                            : 0),
                      })
                    }
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Valor Unitário'
                    text={dataItemServico.valorUnitario}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(valorUnitario) =>
                      setDataItemServico({
                        ...dataItemServico,
                        valorUnitario,
                        vlrTotal:
                          valorUnitario *
                          (dataItemServico.quantidade
                            ? dataItemServico.quantidade
                            : 0),
                      })
                    }
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Valor Total'
                    text={dataItemServico.vlrTotal}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                  />
                </div>

                <div className='col-2 mt-3'>
                  <Button
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Adicionar Serviço'
                    onClick={onClickAddServico}
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-8'>
                  <Textbox
                    label='Descrição do Serviço'
                    text={dataItemServico.noDescricao}
                    onChangedValue={(noDescricao) =>
                      setDataItemServico({ ...dataItemServico, noDescricao })
                    }
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Valor Desconto'
                    text={dataItemServico.vlrDesconto}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrDesconto) =>
                      setDataItemServico({
                        ...dataItemServico,
                        vlrDesconto,
                      })
                    }
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-12'>
                  <GridView
                    ref={gridViewServicos}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsServicos}
                    offlineData
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-2 '>
                  <Textbox
                    label='Valor Líquido'
                    text={data.vlrLiquido}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                    onChangedValue={(vlrLiquido) =>
                      setData({ ...data, vlrLiquido })
                    }
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <h6 className='fw-normal mt-3'>
          <span
            className='form-label text-right rounded p-2'
            style={{ marginRight: '60%', color: '#FF0000' }}
          >
            {data.impostos?.noMensagem}
          </span>
        </h6>
      </div>

      <div className='row mb-3'>
        <div className='col'>
          <Panel>
            <Panel.Header
              icon={['fa', 'list']}
              title='Tributos'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-1'>
                  <Switch
                    formControl
                    label='Issqn Retido'
                    checked={data.impostos?.flgIssqnRetido}
                    onChange={(flgIssqnRetido) => {
                      const nfs = data;
                      nfs.impostos.flgIssqnRetido = flgIssqnRetido;
                      onRecalculateTaxes(nfs);
                    }}
                  />
                </div>
                <div className='col-1'>
                  <Switch
                    formControl
                    label='Issqn Reti. Manu.'
                    checked={data.impostos?.flgIssqnRetidoManual}
                    onChange={(flgIssqnRetidoManual) => {
                      const nfs = data;
                      nfs.impostos.flgIssqnRetidoManual = flgIssqnRetidoManual;
                      onRecalculateTaxes(nfs);
                    }}
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='Base Cálculo do ISSQN'
                    text={data.impostos?.vlrBaseCalIssqn}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                    disabledTabIndex
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='Alíquota ISSQN'
                    text={data.impostos?.aliqIssqn}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(aliqIssqn) => {
                      const { impostos } = data;
                      setData({
                        ...data,
                        impostos: { ...impostos, aliqIssqn },
                      });
                    }}
                    onBlur={onRecalculateTaxes}
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Valor ISSQN'
                    disabledTabIndex
                    text={data.impostos?.vlrIssqn}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrIssqn) => {
                      const { impostos } = data;
                      setData({
                        ...data,
                        impostos: { ...impostos, vlrIssqn },
                      });
                    }}
                    readOnly
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Outras Retenções'
                    disabledTabIndex
                    text={data.impostos?.vlrOutrasRetencoes}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrOutrasRetencoes) => {
                      const { impostos } = data;
                      setData({
                        ...data,
                        impostos: { ...impostos, vlrOutrasRetencoes },
                      });
                    }}
                    readOnly
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-2'>
                  <Switch
                    formControl
                    label='Pis Retido:'
                    checked={data.impostos?.flgPisRetido}
                    onChange={(flgPisRetido) => {
                      const nfs = data;
                      nfs.impostos.flgPisRetido = flgPisRetido;
                      onRecalculateTaxes(nfs);
                    }}
                  />
                </div>
                <div className='col-3'>
                  <Textbox
                    label='Base Cálculo do Pis'
                    text={data.impostos?.vlrBaseCalPis}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                    disabledTabIndex
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='Alíquota Pis'
                    text={data.impostos?.aliqPis}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(aliqPis) => {
                      const { impostos } = data;
                      setData({
                        ...data,
                        impostos: { ...impostos, aliqPis },
                      });
                    }}
                    onBlur={onRecalculateTaxes}
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='Valor Pis'
                    text={data.impostos?.vlrPis}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                    disabledTabIndex
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-2'>
                  <Switch
                    formControl
                    label='Cofins Retido:'
                    checked={data.impostos?.flgCofinsRetido}
                    onChange={(flgCofinsRetido) => {
                      const nfs = data;
                      nfs.impostos.flgCofinsRetido = flgCofinsRetido;
                      onRecalculateTaxes(nfs);
                    }}
                  />
                </div>
                <div className='col-3'>
                  <Textbox
                    label='Base Cálculo do Cofins'
                    text={data.impostos?.vlrBaseCalCofins}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                    disabledTabIndex
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='Alíquota Cofins'
                    text={data.impostos?.aliqCofins}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(aliqCofins) => {
                      const { impostos } = data;
                      setData({
                        ...data,
                        impostos: { ...impostos, aliqCofins },
                      });
                    }}
                    onBlur={onRecalculateTaxes}
                  />
                </div>
                <div className='col-3'>
                  <Textbox
                    label='Valor Cofins'
                    text={data.impostos?.vlrCofins}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                    disabledTabIndex
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-2'>
                  <Switch
                    formControl
                    label='Csll Retido:'
                    checked={data.impostos?.flgCsllRetido}
                    onChange={(flgCsllRetido) => {
                      const nfs = data;
                      nfs.impostos.flgCsllRetido = flgCsllRetido;
                      onRecalculateTaxes(nfs);
                    }}
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Base Cálculo do Csll'
                    text={data.impostos?.vlrBaseCalCsll}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                    disabledTabIndex
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Aliq Csll Retido'
                    text={data.impostos?.aliqCsllRetido}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(aliqCsllRetido) => {
                      const { impostos } = data;
                      setData({
                        ...data,
                        impostos: { ...impostos, aliqCsllRetido },
                      });
                    }}
                    onBlur={onRecalculateTaxes}
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Vlr Csll Retido'
                    text={data.impostos?.vlrCsllRetido}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                    disabledTabIndex
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Aliq Csll Contador'
                    text={data.impostos?.aliqCsllContador}
                    mask={MaskTypes.DecimalCustom}
                    readOnly={!data.impostos?.flgCsllRetido}
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Vlr Csll Contador'
                    text={data.impostos?.vlrCsllContador}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                    disabledTabIndex
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-2 offset-2'>
                  <Textbox
                    label='Base Cálculo do IRPJ'
                    text={data.impostos?.vlrBaseCalIRPJ}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                    disabledTabIndex
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Aliq IRPJ Retido'
                    text={data.impostos?.aliqIrpjRetido}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(aliqIrpjRetido) => {
                      const { impostos } = data;
                      setData({
                        ...data,
                        impostos: { ...impostos, aliqIrpjRetido },
                      });
                    }}
                    onBlur={onRecalculateTaxes}
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Vlr IRPJ Retido'
                    text={data.impostos?.vlrIrpjRetido}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                    disabledTabIndex
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Aliq IRPJ Contador'
                    text={data.impostos?.aliqIrpjContador}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(aliqIrpjContador) => {
                      const { impostos } = data;
                      setData({
                        ...data,
                        impostos: { ...impostos, aliqIrpjContador },
                      });
                    }}
                    onBlur={onRecalculateTaxes}
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Vlr IRPJ Contador'
                    text={data.impostos?.vlrIrpjContador}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                    disabledTabIndex
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-3 offset-2'>
                  <Textbox
                    label='Base Cálculo do INSS'
                    text={data.impostos?.vlrBaseCalINSS}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                    disabledTabIndex
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='Alíquota Inss'
                    text={data.impostos?.aliqInss}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(aliqInss) => {
                      const { impostos } = data;
                      setData({
                        ...data,
                        impostos: { ...impostos, aliqInss },
                      });
                    }}
                    onBlur={onRecalculateTaxes}
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='Valor Inss'
                    text={data.impostos?.vlrInss}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                    disabledTabIndex
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-3 offset-2'>
                  <Textbox
                    label='Base Cálculo do Deson'
                    text={data.impostos?.vlrBaseCalDeson}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                    disabledTabIndex
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='Aliq IR Deson Contador'
                    text={data.impostos?.aliqDesonContador}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(aliqDesonContador) => {
                      const { impostos } = data;
                      setData({
                        ...data,
                        impostos: { ...impostos, aliqDesonContador },
                      });
                    }}
                    onBlur={onRecalculateTaxes}
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='Vlr Deson Contador'
                    text={data.impostos?.vlrDesonContador}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                    disabledTabIndex
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      {}
      <div className='row mb-3'>
        <div className='col'>
          <Panel>
            <Panel.Header
              icon={['fa', 'list']}
              title='Financeiro'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Condição Pagamento'
                    searchDataSource={onSearchCondicaoPagamento}
                    selectedItem={data.condicaoPagamento}
                    onSelectItem={(condicaoPagamento) => {
                      setData({
                        ...data,
                        condicaoPagamento,
                        nrSeqCondicaoPagamento:
                          condicaoPagamento.nrSeqCondicaoPagamento,
                      });
                    }}
                    dataSourceTextProperty='noCondicaoPagamento'
                  />
                </div>
                <div className='col-2 mt-3'>
                  <Button
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Gerar Títulos'
                    onClick={onGenerateParcels}
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-2'>
                  <Textbox
                    label='Valor'
                    text={dataItemFatura.vlrParcela}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrParcela) =>
                      setDataItemFatura({ ...dataItemFatura, vlrParcela })
                    }
                  />
                </div>

                <div className='col-2'>
                  <DatePicker
                    label='Vencimento'
                    text={dataItemFatura.dtVencimento}
                    mask={MaskTypes.Date}
                    onChange={(dtVencimento) =>
                      setDataItemFatura({
                        ...dataItemFatura,
                        dtVencimento,
                      })
                    }
                  />
                </div>

                <div className='col-5'>
                  <Autocomplete
                    label='Forma de Pagamento'
                    searchDataSource={onSearchFormaPagamento}
                    selectedItem={dataItemFatura.formaPagamento}
                    onSelectItem={(formaPagamento) =>
                      setDataItemFatura({
                        ...dataItemFatura,
                        formaPagamento,
                        nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                      })
                    }
                    dataSourceTextProperty='noFormaPagamento'
                  />
                </div>

                <div className='col-2 mt-3'>
                  <Button
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Adicionar Fatura'
                    onClick={onClickAddFatura}
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-12'>
                  <GridView
                    ref={gridViewFaturamento}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsFaturamento}
                    offlineData
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalNotaDuplicada
              show={modalNotaDuplicada.show}
              data={modalNotaDuplicada.data}
              onClose={(liberadaImportacao, dataSource) => {
                onCloseModalNotaDuplicada(liberadaImportacao, dataSource);
              }}
            />
          </div>
        </div>
        <div className='col'>
          <ModalImportNF
            show={modalimportaNf.importaNf}
            ref={modalImportNfRef}
            nrSeqEmpresa={data.unidOrigem?.nrSeqEmpresa}
            onClose={(datasource) => {
              if (datasource) {
                onCloseModalImportaNF(datasource);
              } else {
                setModalimportaNf({
                  show: false,
                });
              }
            }}
          />
        </div>
      </div>

      <Panel>
        <Panel.Header
          icon={['fas', 'briefcase']}
          title='Diversos'
          theme={Theme.Primary}
          align={JustifyContent.Start}
        />

        <Panel.Body>
          <div className='row mb-3'>
            <div className='col mb-3'>
              <Textarea
                maxLength={6000}
                label='Observações Nota'
                text={data.obsNfs}
                onChangedValue={(obsNfs) => setData({ ...data, obsNfs })}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col mb-3'>
              <Textarea
                maxLength={6000}
                label='Observações Gerais'
                text={data.obsGeraisNfs}
                onChangedValue={(obsGeraisNfs) =>
                  setData({ ...data, obsGeraisNfs })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <Panel>
        <Panel.Header
          icon={['fas', 'briefcase']}
          title='Nota Fiscal Eletrônica'
          theme={Theme.Primary}
          align={JustifyContent.Start}
        />

        <Panel.Body>
          <div className='row mb-3'>
            <div className='col mb-3'>
              <Button
                outline
                theme={Theme.Success}
                template={Button.Templates.Button}
                text='Transmitir Nota'
                onClick={onTransmit}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </CSDManutPage>
  );
}
