import React, { useRef, useState } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  getVeiculoPagined,
  printVeiculo,
  printVeiculoDetalhado,
  deleteAllVeiculo,
  getVeiculoAutoCompleteResumido,
  printVeiculoHistorico,
} from 'core/services/FRO/veiculo';
import {
  Autocomplete,
  CSDSelPage,
  GridView,
  Panel,
  RadioButton,
  Textbox,
  ToolbarButtons,
} from 'ui/components';
import {
  getCidadeAutoComplete,
  getEstadoAutoComplete,
} from 'core/services/SEG';
import { getVeiculoModeloAutoComplete } from 'core/services/FRO/veiculoModelo';
import { getVeiculoMarcaAutoComplete } from 'core/services/FRO/veiculoMarca';
import { getVeiculoTipoAutoComplete } from 'core/services/FRO/veiculoTipo';
import { getTransportadoraAutoComplete } from 'core/services/FRO/transportadora';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';

const columns = [
  { key: 'nrSeqVeiculo', type: GridView.ColumnTypes.Checkbox },
  { key: 'placa', title: 'Placa' },
  { key: 'noVeiculo', title: 'Nome' },
  {
    key: 'veiculoMarca.noVeiculoMarca',
    title: 'Marca',
    sortKey: 'noVeiculoMarca',
  },
  {
    key: 'veiculoModelo.noVeiculoModelo',
    title: 'Modelo',
    sortKey: 'noVeiculoModelo',
  },
  { key: 'anoFabricacao', title: 'Ano Fabricação', sortable: false },
  { key: 'anoModelo', title: 'Ano Modelo', sortable: false },
  { key: 'nrChassi', title: 'Chassi', sortable: false },
  { key: 'flgProprio', title: 'Próprio' },
  { key: 'noVeiculosVinculados', title: 'Placas Vinculadas', sortable: false },
  {
    key: 'cidade.noCidadeEstado',
    title: 'Cidade - Estado',
    sortable: false,
  },
  {
    key: 'motorista.noPessoa',
    title: 'Motorista',
    sortKey: 'noPessoa',
    sortable: false,
  },
  {
    key: 'motorista.contato.fone',
    title: 'Contato',
    sortKey: 'fone',
    sortable: false,
  },
  {
    key: 'transportadora.noPessoa',
    title: 'Transportadora',
    sortKey: 'transportadora',
    sortable: false,
    visible: false,
  },
];

export default function Veiculo({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({
    filtroVeiculoAtivo: 'All',
    filtroVeiculoPrincipal: 'All',
    filtroVeiculoProprio: 'All',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    const obj = params ? { ...params } : { ...filtros };

    if (obj.filtroVeiculoAtivo !== 'All') {
      obj.flgAtivo = obj.filtroVeiculoAtivo === 'Yes';
    } else {
      obj.flgAtivo = undefined;
    }

    if (obj.filtroVeiculoPrincipal !== 'All') {
      obj.flgPrincipal = obj.filtroVeiculoPrincipal === 'Yes';
    } else {
      obj.flgPrincipal = undefined;
    }

    if (obj.filtroVeiculoProprio !== 'All') {
      obj.flgProprio = obj.filtroVeiculoProprio === 'Own';
    } else {
      obj.flgProprio = undefined;
    }

    if (obj.veiculo) {
      obj.veiculo = undefined;
    }

    const { data, pagination } = await getVeiculoPagined(obj);

    if (gridView && gridView.current) {
      gridView.current.setDataSource(data, pagination);
    }

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Veiculo/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqVeiculo = sourceRow.find((e) => e.key === 'nrSeqVeiculo');
    onOpenMaintenance(nrSeqVeiculo.value);
  };
  const onPrint = async () => {
    setLoading(true);

    const obj = { ...filtros };

    if (filtros.filtroVeiculoAtivo !== 'All') {
      obj.flgAtivo = filtros.filtroVeiculoAtivo === 'Yes';
    } else {
      obj.flgAtivo = undefined;
    }

    if (filtros.filtroVeiculoPrincipal !== 'All') {
      obj.flgPrincipal = filtros.filtroVeiculoPrincipal === 'Yes';
    } else {
      obj.flgPrincipal = undefined;
    }

    if (filtros.filtroVeiculoProprio !== 'All') {
      obj.flgProprio = filtros.filtroVeiculoProprio === 'Own';
    } else {
      obj.flgProprio = undefined;
    }

    if (filtros.veiculo) {
      obj.veiculo = undefined;
    }

    const { value } = await printVeiculo(obj);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onPrintDetalhado = async () => {
    setLoading(true);

    const obj = { ...filtros };

    if (filtros.filtroVeiculoAtivo !== 'All') {
      obj.flgAtivo = filtros.filtroVeiculoAtivo === 'Yes';
    } else {
      obj.flgAtivo = undefined;
    }

    if (filtros.filtroVeiculoPrincipal !== 'All') {
      obj.flgPrincipal = filtros.filtroVeiculoPrincipal === 'Yes';
    } else {
      obj.flgPrincipal = undefined;
    }

    if (filtros.filtroVeiculoProprio !== 'All') {
      obj.flgProprio = filtros.filtroVeiculoProprio === 'Own';
    } else {
      obj.flgProprio = undefined;
    }

    if (filtros.veiculo) {
      obj.veiculo = undefined;
    }

    const { value } = await printVeiculoDetalhado(obj);

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onPrintHistorico = async () => {
    setLoading(true);

    const obj = { ...filtros };

    if (filtros.filtroVeiculoAtivo !== 'All') {
      obj.flgAtivo = filtros.filtroVeiculoAtivo === 'Yes';
    } else {
      obj.flgAtivo = undefined;
    }

    if (filtros.filtroVeiculoPrincipal !== 'All') {
      obj.flgPrincipal = filtros.filtroVeiculoPrincipal === 'Yes';
    } else {
      obj.flgPrincipal = undefined;
    }

    if (filtros.filtroVeiculoProprio !== 'All') {
      obj.flgProprio = filtros.filtroVeiculoProprio === 'Own';
    } else {
      obj.flgProprio = undefined;
    }

    if (filtros.veiculo) {
      obj.veiculo = undefined;
    }

    const { value } = await printVeiculoHistorico(obj);

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllVeiculo(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchVeiculo = async (e) => {
    const {
      status,
      message: msg,
      veiculos,
    } = await getVeiculoAutoCompleteResumido({ placa: e });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onSearchVeiculoTipo = async (e) => {
    const {
      status,
      message: msg,
      data: veiculoTipos,
    } = await getVeiculoTipoAutoComplete({ noVeiculoTipo: e });
    if (msg) onSetMessage(status, msg);
    return veiculoTipos;
  };

  const onSearchVeiculoModelo = async (e) => {
    const res = await getVeiculoModeloAutoComplete({
      noVeiculoModelo: e,
      nrSeqVeiculoMarca: filtros.nrSeqVeiculoMarca,
    });
    if (res.message) onSetMessage(res.status, res.message);
    return res.data;
  };

  const onSearchVeiculoMarca = async (e) => {
    const res = await getVeiculoMarcaAutoComplete({ noVeiculoMarca: e });
    if (res.message) onSetMessage(res.status, res.message);
    return res.data;
  };

  const onSearchMotorista = async (e) => {
    const { status, message: msg, motoristas } = await getMotoristaAutoComplete(
      {
        noPessoa: e,
      }
    );
    if (msg) onSetMessage(status, msg);
    return motoristas;
  };

  const onSearchEstado = async (e) => {
    const { status, message: msg, estados } = await getEstadoAutoComplete({
      noEstado: e,
    });
    if (msg) onSetMessage(status, msg);
    return estados;
  };

  const onSearchCidade = async (e) => {
    const { status, message: msg, cidades } = await getCidadeAutoComplete({
      noCidade: e,
      nrSeqEstado: filtros.nrSeqEstado,
    });
    if (msg) onSetMessage(status, msg);
    return cidades;
  };

  const onSearchTransportadora = async (e) => {
    const {
      status,
      message: msg,
      transportadoras: pessoaTrans,
    } = await getTransportadoraAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);

    return pessoaTrans;
  };

  const vehicleOnwnershipButtons = [
    { text: 'Todos', value: 'All' },
    { text: 'Veículo Próprio', value: 'Own' },
    { text: 'Veículo Terceiro', value: 'Others' },
  ];
  const mainVehicleButtons = [
    { text: 'Todos', value: 'All' },
    { text: 'Sim', value: 'Yes' },
    { text: 'Não', value: 'No' },
  ];
  const activeVehicleButtons = [
    { text: 'Todos', value: 'All' },
    { text: 'Sim', value: 'Yes' },
    { text: 'Não', value: 'No' },
  ];

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Veículo'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Imprimir Detalhado'
          icon='print'
          onClick={onPrintDetalhado}
        />
        <ToolbarButtons.Button
          text='Imprimir Historico'
          icon='print'
          onClick={onPrintHistorico}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Filtros' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3 '>
              <div className='col-2'>
                <Autocomplete
                  label='Placa'
                  searchDataSource={onSearchVeiculo}
                  selectedItem={filtros.veiculo}
                  onSelectItem={(veiculo) => {
                    setFiltros({
                      ...filtros,
                      veiculo,
                      nrSeqVeiculo: veiculo.nrSeqVeiculo,
                    });
                  }}
                  dataSourceTextProperty='placa'
                />
              </div>
              <div className='col-3'>
                <Textbox
                  maxLength={100}
                  label='Nome'
                  text={filtros.noVeiculo}
                  onChangedValue={(noVeiculo) =>
                    setFiltros({ ...filtros, noVeiculo })
                  }
                />
              </div>
              <div className='col-3'>
                <Autocomplete
                  label='Tipo Veículo'
                  searchDataSource={onSearchVeiculoTipo}
                  selectedItem={filtros.veiculoTipo}
                  onSelectItem={(veiculoTipo) => {
                    setFiltros({
                      ...filtros,
                      veiculoTipo,
                      nrSeqVeiculoTipo: veiculoTipo.nrSeqVeiculoTipo,
                    });
                  }}
                  dataSourceTextProperty='noVeiculoTipo'
                />
              </div>
              <div className='col'>
                <Autocomplete
                  label='Marca'
                  searchDataSource={onSearchVeiculoMarca}
                  selectedItem={filtros.veiculoMarca}
                  onSelectItem={(veiculoMarca) => {
                    setFiltros({
                      ...filtros,
                      veiculoMarca,
                      nrSeqVeiculoMarca: veiculoMarca.nrSeqVeiculoMarca,
                      veiculoModelo: null,
                      nrSeqVeiculoModelo: null,
                    });
                  }}
                  dataSourceTextProperty='noVeiculoMarca'
                />
              </div>
              <div className='col'>
                <Autocomplete
                  label='Modelo'
                  readOnly={!filtros.veiculoMarca?.nrSeqVeiculoMarca}
                  enabled={!!filtros.veiculoMarca?.nrSeqVeiculoMarca}
                  placeholder={
                    !filtros.veiculoMarca?.nrSeqVeiculoMarca &&
                    'Selecione a marca'
                  }
                  searchDataSource={onSearchVeiculoModelo}
                  selectedItem={filtros.veiculoModelo}
                  onSelectItem={(veiculoModelo) => {
                    setFiltros({
                      ...filtros,
                      veiculoModelo,
                      nrSeqVeiculoModelo: veiculoModelo.nrSeqVeiculoModelo,
                    });
                  }}
                  dataSourceTextProperty='noVeiculoModelo'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col'>
                <Autocomplete
                  label='Motorista'
                  searchDataSource={onSearchMotorista}
                  selectedItem={filtros.motorista}
                  onSelectItem={(motorista) => {
                    setFiltros({
                      ...filtros,
                      motorista,
                      nrSeqPessoaMot: motorista.nrSeqPessoaMot,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col'>
                <Autocomplete
                  label='Transportadora'
                  searchDataSource={onSearchTransportadora}
                  selectedItem={filtros.pessoaTran}
                  onSelectItem={(pessoaTran) => {
                    setFiltros({
                      ...filtros,
                      pessoaTran,
                      nrSeqPessoaTran: pessoaTran.nrSeqPessoaTran,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col'>
                <Autocomplete
                  label='Transportadora Estado'
                  searchDataSource={onSearchEstado}
                  selectedItem={filtros.estado}
                  onSelectItem={(estado) => {
                    setFiltros({
                      ...filtros,
                      estado,
                      nrSeqEstado: estado.nrSeqEstado,
                      cidade: null,
                    });
                  }}
                  dataSourceTextProperty='noEstado'
                />
              </div>
              <div className='col'>
                <Autocomplete
                  label='Transportadora Cidade'
                  readOnly={!filtros.estado?.nrSeqEstado}
                  enabled={!!filtros.estado?.nrSeqEstado}
                  placeholder={
                    !filtros.estado?.nrSeqEstado && 'Selecione o Estado'
                  }
                  searchDataSource={onSearchCidade}
                  selectedItem={filtros.cidade}
                  onSelectItem={(cidade) => {
                    setFiltros({
                      ...filtros,
                      cidade,
                      nrSeqCidade: cidade.nrSeqCidade,
                    });
                  }}
                  dataSourceTextProperty='noCidade'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-3'>
                <Textbox
                  label='Chassi'
                  tooltip='Insira o número completo ou parcial do chassi'
                  text={filtros.nrChassi}
                  maxLength={40}
                  onChangedValue={(nrChassi) => {
                    setFiltros({ ...filtros, nrChassi });
                  }}
                />
              </div>
              <div className='col'>
                <RadioButton
                  label='Filtro Veículos'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.filtroVeiculoProprio}
                  buttons={vehicleOnwnershipButtons}
                  onChange={(filtroVeiculoProprio) => {
                    setFiltros({
                      ...filtros,
                      filtroVeiculoProprio,
                    });
                  }}
                />
              </div>
              <div className='col'>
                <RadioButton
                  label='Veículo Principal'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.filtroVeiculoPrincipal}
                  buttons={mainVehicleButtons}
                  onChange={(filtroVeiculoPrincipal) =>
                    setFiltros({ ...filtros, filtroVeiculoPrincipal })
                  }
                />
              </div>
              <div className='col'>
                <RadioButton
                  label='Apenas Ativos'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.filtroVeiculoAtivo}
                  buttons={activeVehicleButtons}
                  onChange={(filtroVeiculoAtivo) =>
                    setFiltros({ ...filtros, filtroVeiculoAtivo })
                  }
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>

      <div className='row mb-3'>
        <div className='col'>
          <GridView
            enableExcelExport
            canControlVisibility
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
