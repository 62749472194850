export default class FreteTipoCalculo {
  constructor(jsonObject = {}) {
    this.nrSeqFreteTipoCalculo = jsonObject.nrSeqFreteTipoCalculo;
    this.noFreteTipoCalculo = jsonObject.noFreteTipoCalculo;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgPeso = jsonObject.flgPeso;
    this.flgQuantidade = jsonObject.flgQuantidade;
    this.flgFreteFixo = jsonObject.flgFreteFixo;
    this.flgValor = jsonObject.flgValor;
  }

  toJson = () => JSON.stringify(this);
}
