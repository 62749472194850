import React, { useEffect, useState, useRef } from 'react';
import { Button, CSDBasePageContext, FontAwesomeIcon } from 'ui/components';
import { BootstrapSizes, generateID, Theme } from 'ui/Helpers/utils';

export default function Autocomplete(props) {
  const {
    dataSourceTextPropertyLabel,
    dataSourceTextPropertyLabel2,
    dataSourceTextPropertyLabel3,
    dataSourceTextPropertyLabel4,
    dataSourceTextPropertyLabel5,
    dataSourceTextPropertyLabel6,
    dataSourceTextProperty,
    dataSourceTextProperty2,
    dataSourceTextProperty3,
    dataSourceTextProperty4,
    dataSourceTextProperty5,
    dataSourceTextProperty6,
    type,
    className,
    placeholder,
    label,
    visible,
    selectedItem,
    enabled,
    id,
    required,
    tooltip,
    onSelectItem,
    onNewItemPageRef,
    transactionToOpen,
    nrseqTransactionToOpen,
    onOpenTransaction,
    findTransaction,
    minLengthTextSearch,
    readOnly,
    openModal,
  } = props;

  const [source, setSource] = useState([]);
  const [text, setText] = useState(
    (selectedItem ?? {})[dataSourceTextProperty]
  );
  const [searching, setSearching] = useState(false);

  const inputText = useRef();
  const dropDownList = useRef();

  const context = React.useContext(CSDBasePageContext);
  const { settings } = context ?? [];

  useEffect(() => {
    if (source && source.length > 1) {
      dropDownList.current.focus();
    }
  }, [source]);

  const formatText = (item) => {
    let textformatted = item[dataSourceTextProperty];
    if (item[dataSourceTextProperty]) {
      if (dataSourceTextPropertyLabel)
        textformatted = `${dataSourceTextPropertyLabel} ${item[dataSourceTextProperty]}`;

      if (dataSourceTextPropertyLabel2)
        textformatted += ` ${dataSourceTextPropertyLabel2} ${item[dataSourceTextProperty2]}`;

      if (dataSourceTextPropertyLabel3)
        textformatted += ` ${dataSourceTextPropertyLabel3} ${item[dataSourceTextProperty3]}`;

      if (dataSourceTextPropertyLabel4)
        textformatted += ` ${dataSourceTextPropertyLabel4} ${item[dataSourceTextProperty4]}`;

      if (dataSourceTextPropertyLabel5)
        textformatted += ` ${dataSourceTextPropertyLabel5} ${item[dataSourceTextProperty5]}`;

      if (dataSourceTextPropertyLabel6)
        textformatted += ` ${dataSourceTextPropertyLabel6} ${item[dataSourceTextProperty6]}`;
    }

    return textformatted;
  };

  useEffect(() => {
    if (selectedItem && dataSourceTextProperty) {
      setText(formatText(selectedItem));
    } else setText(undefined);
  }, [selectedItem, dataSourceTextProperty]);

  const selectItem = (item) => {
    setSource([]);

    setText(formatText(item));

    if (onSelectItem) onSelectItem(item);
  };

  const handleSearchDataSource = async (value) => {
    if (minLengthTextSearch) {
      if (value.length < minLengthTextSearch) {
        return;
      }
    }

    value = value.replace("'", "''");
    const { searchDataSource } = props;

    if (searchDataSource) {
      setSearching(true);
      const filteredSource = await searchDataSource(value);

      setSource(filteredSource);
      setSearching(false);
      if ((filteredSource?.length ?? 0) === 1) {
        selectItem(filteredSource[0]);
      } else if ((filteredSource?.length ?? 0) === 0) {
        setText(null);
        if (inputText.current) inputText.current.focus();
      }
    }
  };

  const handleAutocompleteChange = async (e) => {
    setText(e.target.value);
  };

  const handleAutocompleteSearchClick = async () => {
    const inputValue = inputText.current?.value ?? '';
    setText(inputValue);
    await handleSearchDataSource(inputValue);
  };

  const handleAutocompleteMouseEnter = () => {
    if (source.length) document.body.onclick = null;
  };

  const handleAutocompleteMouseLeave = () => {
    if (source.length)
      document.body.onclick = () => {
        setSource([]);
        document.body.onclick = null;
      };
  };

  const handleAutocompleteClick = (item) => {
    selectItem(item);
  };

  const handleAutocompleteNavigate = (e, item) => {
    const ul = e.currentTarget.parentNode;
    const lis = ul.querySelectorAll('li');
    const current = ul.querySelectorAll('li.active')[0] ?? lis[0];
    const primeiroSelecionado = !current.classList.contains('active');

    if (e.key === 'ArrowDown') {
      const nextSibiling = current.nextElementSibling;

      if (
        !primeiroSelecionado &&
        nextSibiling !== null &&
        nextSibiling !== lis[0]
      ) {
        current.classList.toggle('active');

        nextSibiling.classList.toggle('active');
        nextSibiling.children[0].focus();
      }

      e.preventDefault();
    } else if (e.key === 'ArrowUp') {
      const prevSibiling = current.previousElementSibling;
      if (
        !primeiroSelecionado &&
        prevSibiling !== null &&
        prevSibiling !== lis[lis.length]
      ) {
        current.classList.toggle('active');

        prevSibiling.classList.toggle('active');
        prevSibiling.children[0].focus();
      }

      e.preventDefault();
    } else if (e.key === ' ' || e.key === 'Enter' || e.detail === 0) {
      const selectedElement = source.find(
        (el) => el[dataSourceTextProperty].trim() === current.textContent.trim()
      );

      if (
        item[dataSourceTextProperty] !== selectedElement[dataSourceTextProperty]
      ) {
        selectItem(selectedElement);
      }
    } else if (e.key === 'Escape') {
      setSource([]);
      inputText.current.focus();
    }
  };

  const handleLostFocus = async () => {
    if (props.selectedItem) {
      if (
        props.selectedItem[dataSourceTextProperty] === null ||
        props.selectedItem[dataSourceTextProperty] === undefined
      )
        props.selectedItem[dataSourceTextProperty] = '';
    }
    if (
      text &&
      text.trim() !== '' &&
      (!props.selectedItem ||
        !Object.keys(props.selectedItem).length ||
        !props.selectedItem[dataSourceTextProperty].includes(text.trim()))
    ) {
      await handleSearchDataSource(text);
    }
  };

  const handleKeyDown = async (e) => {
    if (
      !e.ctrlKey &&
      e.key &&
      (e.key === 'Enter' ||
        (e.key === 'Tab' &&
          text &&
          text.trim() !== '' &&
          (!props.selectedItem || !Object.keys(props.selectedItem).length)))
    ) {
      await handleSearchDataSource(text);
    } else if (
      !e.ctrlKey &&
      (e.key === 'Backspace' ||
        e.key === 'Delete' ||
        e.key === 'Del' ||
        e.key === 'Space') &&
      props.selectedItem &&
      Object.keys(props.selectedItem).length > 0 &&
      props.onSelectItem
    ) {
      props.onSelectItem({});
    }
  };

  const handleClear = () => {
    setText(undefined);
    if (props.onSelectItem) props.onSelectItem({});
  };

  const onTransactionToOpen = () => {
    const transaction = findTransaction(transactionToOpen);
    transaction.registryKey = (selectedItem ?? {})[nrseqTransactionToOpen];
    onOpenTransaction(transaction, true, undefined, undefined, true);
  };

  const onTransactionToNew = () => {
    const transaction = findTransaction(transactionToOpen);
    transaction.registryKey = '';
    onOpenTransaction(transaction, true, undefined, undefined, true);
  };

  const loaderShow = searching ? 'show' : '';

  const inputProps = {
    type: type ?? 'text',
    className: className ?? 'form-control',
    placeholder: placeholder ?? '...',
    value: text || '',
    onChange: handleAutocompleteChange,
    onKeyDown: handleKeyDown,
    onBlur: handleLostFocus,
    ref: inputText,
    id: id ?? generateID(),
  };

  if (readOnly) inputProps.readOnly = readOnly;

  if (required) inputProps.required = required;

  let isEnabled = enabled;

  if (isEnabled === false) inputProps.disabled = 'disabled';

  if (readOnly) {
    isEnabled = false;
  }

  // Carrega as configurações do banco se tiver e sobrescreve as configurações do componente
  let setting = id;
  if (settings) setting = settings.find((p) => p.noCampo === id);

  if (setting?.flgHabilitado) {
    isEnabled = true;
  }

  const isVisible = setting?.flgVisivel ?? visible ?? true;
  const renderedLabel = (setting?.noLabel || label) && (
    <label className='form-label' title={tooltip} htmlFor={inputProps.id}>
      {required && '* '}
      {setting?.noLabel || label}
    </label>
  );

  if ((setting?.flgObrigatorio ?? undefined) !== undefined) {
    inputProps.required = setting.flgObrigatorio;
  }
  if ((setting?.flgHabilitado ?? undefined) !== undefined)
    inputProps.disabled = !setting.flgHabilitado;

  return isVisible ? (
    <>
      {renderedLabel}
      <div
        className='autoComplete input-group'
        onMouseEnter={handleAutocompleteMouseEnter}
        onMouseLeave={handleAutocompleteMouseLeave}
      >
        <Button
          style={{ zIndex: 0 }}
          theme={Theme.Inactive}
          icon='search'
          onClick={() => (isEnabled ?? true) && handleAutocompleteSearchClick()}
          disabledTabIndex
        />
        <div className={`autocomplete ${loaderShow}`}>
          {React.createElement('input', inputProps)}

          {source.length > 1 && (
            <div className='autocomplete-select'>
              <ul className='autocomplete-menu show'>
                {source.map((item, index) => (
                  <li
                    role='presentation'
                    key={generateID('ACI')}
                    className={index === 0 ? 'active' : ''}
                    onClick={(e) => {
                      if (e.detail === 0) {
                        handleAutocompleteNavigate(e, item);
                      } else {
                        handleAutocompleteClick(item);
                      }
                    }}
                    onKeyUp={(e) => handleAutocompleteNavigate(e, item)}
                  >
                    <div className='row'>
                      <div className='col'>
                        <button
                          type='button'
                          ref={index === 0 ? dropDownList : null}
                          className=' w-100'
                        >
                          <div className='row mb-1'>
                            {!dataSourceTextPropertyLabel && (
                              <div className='col'>
                                <span> {item[dataSourceTextProperty]}</span>
                              </div>
                            )}

                            {dataSourceTextPropertyLabel && (
                              <div className='col'>
                                <b>
                                  <span style={{ fontWeight: 500 }}>
                                    {dataSourceTextPropertyLabel}
                                  </span>
                                </b>
                                <span> {item[dataSourceTextProperty]}</span>
                              </div>
                            )}

                            {dataSourceTextPropertyLabel2 && (
                              <div className='col'>
                                <b>
                                  <span style={{ fontWeight: 500 }}>
                                    {dataSourceTextPropertyLabel2}
                                  </span>
                                </b>
                                <span> {item[dataSourceTextProperty2]}</span>
                              </div>
                            )}

                            {dataSourceTextPropertyLabel3 && (
                              <div className='col'>
                                <b>
                                  <span style={{ fontWeight: 500 }}>
                                    {dataSourceTextPropertyLabel3}
                                  </span>
                                </b>
                                <span>{item[dataSourceTextProperty3]}</span>
                              </div>
                            )}
                          </div>

                          {dataSourceTextProperty4 && (
                            <div className='row'>
                              {dataSourceTextPropertyLabel4 && (
                                <div className='col'>
                                  <b>
                                    <span style={{ fontWeight: 500 }}>
                                      {dataSourceTextPropertyLabel4}
                                    </span>
                                  </b>
                                  <span>{item[dataSourceTextProperty4]}</span>
                                </div>
                              )}

                              {dataSourceTextPropertyLabel5 && (
                                <div className='col'>
                                  <b>
                                    <span style={{ fontWeight: 500 }}>
                                      {dataSourceTextPropertyLabel5}
                                    </span>
                                  </b>
                                  <span>{item[dataSourceTextProperty5]}</span>
                                </div>
                              )}

                              {dataSourceTextPropertyLabel6 && (
                                <div className='col'>
                                  <b>
                                    <span style={{ fontWeight: 500 }}>
                                      {dataSourceTextPropertyLabel6}
                                    </span>
                                  </b>
                                  <span>{item[dataSourceTextProperty6]}</span>
                                </div>
                              )}
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className='autocomplete-tools-box'>
            {selectedItem &&
              (isEnabled ?? true) &&
              Object.keys(selectedItem).length > 0 && (
                <span
                  role='presentation'
                  className='autocomplete-clear'
                  onClick={handleClear}
                >
                  <FontAwesomeIcon icon='times' />
                </span>
              )}
            <div className='autocomplete-loader-box'>
              <div
                className='spinner-grow spinner-grow-sm text-primary '
                role='status'
              />
            </div>
          </div>
        </div>
        {transactionToOpen && nrseqTransactionToOpen && (
          <>
            <div className='d-flex align-items-stretch'>
              <Button
                onClick={onTransactionToOpen}
                visible
                outline
                theme={Theme.Success}
                template={Button.Templates.Button}
                icon='folder-open'
                size={BootstrapSizes.ExtraSmall}
              />
            </div>
            <div className='d-flex align-items-stretch'>
              <Button
                onClick={onTransactionToNew}
                visible
                outline
                theme={Theme.Success}
                template={Button.Templates.Button}
                icon='plus'
                size={BootstrapSizes.ExtraSmall}
              />
            </div>
          </>
        )}
        {onNewItemPageRef && (
          <Button
            theme={Theme.Inactive}
            icon={['far', 'file-alt']}
            onClick={() => onNewItemPageRef.current?.Show()}
          />
        )}
        {openModal && (
          <div className='mx-1 d-flex align-items-stretch'>
            <Button
              outline
              visible
              theme={Theme.Success}
              template={Button.Templates.Button}
              icon='plus'
              onClick={() => openModal(true)}
              size={BootstrapSizes.ExtraSmall}
              tooltip='Cadastro Rápido'
            />
          </div>
        )}
      </div>
    </>
  ) : null;
}
