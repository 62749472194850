import React, { useRef, useEffect, useState } from 'react';
import { Modal, GridView, Loader, Notification, Button } from 'ui/components';
import {
  ColumnDataTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/enums';
import {
  GridviewColumns,
  GridViewCurrent,
  Message,
} from 'ui/Helpers/interfaces';

import { getColetaEmbarqueList } from 'core/services/FRO/coletaEmbarque';

import ColetaEmbarque from 'core/models/FRO/coletaEmbarque';

interface Props {
  show: boolean;
  nrSeqViagem: number;
  onClose: () => void;
  openViagem: (nrSeqViagem: number) => void;
}

export default function ModalComprovantesConhecimentos({
  show,
  nrSeqViagem,
  onClose,
  openViagem,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const gridView = useRef<GridViewCurrent<ColetaEmbarque>>();

  const onSend = (): void => {
    onClose();
  };

  const onClickOpenViagem = (): void => {
    setLoading(true);

    openViagem(nrSeqViagem);

    setLoading(false);
  };

  const columns: GridviewColumns[] = [
    { key: 'nrSeqColetaEmbarque', visible: false },
    {
      key: 'temComprovante',
      title: 'Comprovante de Entrega?',
      color: 'noColorTemComprovante',
      format: ColumnDataTypes.Boolean,
    },
    { key: 'cdColetaEmbarque', title: 'Código' },
    { key: 'dtEmissao', title: 'Data', format: ColumnDataTypes.Date },
    { key: 'vlrTotal', title: 'Total Frete', format: ColumnDataTypes.Decimal },
  ];

  const load = async (): Promise<void> => {
    setLoading(true);

    const { status, message: msg, data: ctes } = await getColetaEmbarqueList({
      nrSeqViagem,
    });

    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    if (status === ResponseStatus.Success) {
      if (gridView && gridView.current) gridView.current.setDataSource(ctes);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (show) load();
  }, [show]);

  return (
    // @ts-expect-error
    <Modal
      icon='list'
      show={show}
      title='Comprovantes dos Conhecimentos'
      size={BootstrapSizes.Large}
      onClose={() => onSend()}
    >
      {/* @ts-expect-error */}
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='m-1'>
            {/* @ts-expect-error */}
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridView}
              // @ts-expect-error
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              offlineData
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Abrir Viagem'
          icon='edit'
          theme={Theme.Primary}
          template={Button.Templates.Default}
          size={BootstrapSizes.Medium}
          onClick={onClickOpenViagem}
        />
        <Button
          text='Sair'
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          size={BootstrapSizes.Medium}
          onClick={() => onSend()}
        />
      </Modal.Footer>
    </Modal>
  );
}
