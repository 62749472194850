export default class PneuSituacao {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;

    this.nrSeqPneuSituacao = jsonObject.nrSeqPneuSituacao;

    this.cdPneuSituacao = jsonObject.cdPneuSituacao;

    this.noPneuSituacao = jsonObject.noPneuSituacao;

    this.imagem = jsonObject.imagem;

    this.flgSituacao = jsonObject.flgSituacao;
  }

  get situacao() {
    if (this.flgSituacao === true) return 'Ativo';

    return 'Inativo';
  }

  toJson = () => JSON.stringify(this);
}
