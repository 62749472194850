import React, { useState } from 'react';
import { generateID } from 'ui/Helpers/utils';

export default function DropdownList(props) {
  const [viewing, setViewing] = useState(false);

  const onDropDownListMouseEnter = () => {
    if (viewing) document.body.onclick = null;
  };

  const onDropDownListMouseLeave = () => {
    if (viewing)
      document.body.onclick = () => {
        setViewing(false);
        document.body.onclick = null;
      };
  };

  const {
    dataSource,
    dataSourcePropertyText,
    dataSourcePropertyValue,
    label,
    selectedItems,
    onSelectItem,
    id = generateID(),
  } = props;

  const onDropDownListItemClick = (item) => {
    if (onSelectItem) onSelectItem(item);
    setViewing(false);
  };

  return (
    <>
      {label && (
        <label className='form-label' htmlFor={id}>
          {label}
        </label>
      )}
      <div
        id={id}
        className='dropdown'
        onMouseLeave={onDropDownListMouseLeave}
        onMouseEnter={onDropDownListMouseEnter}
      >
        <div
          role='presentation'
          className='form-select'
          onClick={() => setViewing(!viewing)}
        >
          <div className='dropdown-display'>
            {selectedItems[dataSourcePropertyText]
              ? selectedItems[dataSourcePropertyText]
              : '...'}
          </div>
        </div>
        {viewing && (
          <div className='dropdown-list'>
            {dataSource &&
              dataSource.map((item) => (
                <div
                  role='presentation'
                  className='dropdown-list-item'
                  key={item[dataSourcePropertyValue]}
                  onClick={() => onDropDownListItemClick(item)}
                >
                  {item[dataSourcePropertyText]}
                </div>
              ))}
          </div>
        )}
      </div>
    </>
  );
}
