export default class DemandaDocumento {
  constructor(jsonObject = {}) {
    this.nrSeqDemandaDocumento = jsonObject.nrSeqDemandaDocumento;
    this.nrSeqDemanda = jsonObject.nrSeqDemanda;
    this.noImagem = jsonObject.noImagem;
    this.noTipoArquivo = jsonObject.noTipoArquivo;
    this.noTamanho = jsonObject.noTamanho;
    this.dtCadastro = jsonObject.dtCadastro;
    this.noArquivo = jsonObject.noArquivo;
  }

  toJson = () => JSON.stringify(this);
}
