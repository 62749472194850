import qs from 'qs';
import Pagination from 'core/models/pagination';
import PocPerfuratriz from 'core/models/POC/perfuratriz';
import api from '../api';

const baseURL = `/POC/Perfuratriz`;

export const getPerfuratrizAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    perfuratriz: value?.map((json) => new PocPerfuratriz(json)) ?? [],
  };
};

export const getPocPerfuratrizList = async (filters) => {
  const response = await api.get(`/$PocPerfuratriz/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPerfuratriz(json)) ?? [],
  };
};

export const getPocPerfuratrizPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPerfuratriz(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocPerfuratriz = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocPerfuratriz(value) : {};
};

export const savePocPerfuratriz = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePocPerfuratriz = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPocPerfuratriz = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocPerfuratriz = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocPerfuratriz = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
