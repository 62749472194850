export default class BoletoApi {
  constructor(jsonObject = {}) {
    this.numeroBoleto = jsonObject.numeroBoleto;
    this.estadoTituloCobranca = jsonObject.estadoTituloCobranca;
    this.dataRegistro = jsonObject.dataRegistro;
    this.dataVencimento = jsonObject.dataVencimento;
    this.dataMovimento = jsonObject.dataMovimento;
    this.dtRegistro = jsonObject.dtRegistro;
    this.dtVencimento = jsonObject.dtVencimento;
    this.dtMovimento = jsonObject.dtMovimento;
    this.valorOriginal = jsonObject.valorOriginal;
    this.valorAtual = jsonObject.valorAtual;
    this.valorPago = jsonObject.valorPago;
    this.contrato = jsonObject.contrato;
    this.carteiraConvenio = jsonObject.carteiraConvenio;
    this.variacaoCarteiraConvenio = jsonObject.variacaoCarteiraConvenio;
    this.codigoEstadoTituloCobranca = jsonObject.codigoEstadoTituloCobranca;
    this.dataCredito = jsonObject.dataCredito;
    this.dtCredito = jsonObject.dtCredito;
    this.noOcorrencia = jsonObject.noOcorrencia;
  }

  toJson = () => JSON.stringify(this);
}
