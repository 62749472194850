import React, {
  useState,
  forwardRef,
  useRef,
  useEffect,
  useImperativeHandle,
} from 'react';
import { Theme } from 'ui/Helpers/utils';

import {
  getChipAutoComplete,
  getOperadoraAutoComplete,
  getCentroDeCustoAutoComplete,
  getLinhasOperadoraCentroDeCusto,
} from 'core/services/TEL';
import { Autocomplete, Button, DropdownMulti, Loader } from 'ui/components';
import LinhasSelecionadas from './linhasSelecionadas';

const PanelGestaoContaFiltroLinha = forwardRef((props, lin) => {
  const dropDownCentrosCusto = useRef();
  const [selectedItems, setSelectedItems] = useState({});
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [linhasSelecionadas, setLinhas] = useState({
    linha: [],
  });

  const searchCentroDeCusto = async () => {
    const obj = { FiltroCentroCustoPaiNulo: true };
    const { data: centros } = await getCentroDeCustoAutoComplete(obj);

    return centros;
  };

  useImperativeHandle(lin, () => ({ lin: linhasSelecionadas }));

  useEffect(() => {
    dropDownCentrosCusto.current.loadDataSource(searchCentroDeCusto);
  });

  const onRemoveLinha = (e) => {
    const position = linhasSelecionadas.linha.indexOf(e);
    linhasSelecionadas.linha.splice(position, 1);
    setLinhas({ ...linhasSelecionadas, linhasSelecionadas });
  };

  const onSearchLinha = async (e) => {
    if (e.length < 3 && !filters.nrSeqOperadora) {
      return [];
    }

    const { chips } = await getChipAutoComplete({
      nrPrefixoLinha: e,
    });

    return chips;
  };
  const onSearchOperadora = async (e) => {
    const { operadoras } = await getOperadoraAutoComplete({
      noOperadora: e,
    });

    return operadoras;
  };

  const onAdicionarLinha = async () => {
    if (filters.nrSeqCentrosDeCustos || filters.nrSeqOperadora) {
      const obj = {
        NrSeqOperadora: filters.nrSeqOperadora,
        NrSeqsCentroDeCusto: filters.nrSeqCentrosDeCustos,
        NrSeqChip: filters.nrSeqChip,
      };
      setLoading(true);
      const chips = await getLinhasOperadoraCentroDeCusto(obj);
      setLoading(false);

      const linhas = chips.linhas.map((linha) => linha);

      linhasSelecionadas.linha = linhas;

      setLinhas({ ...linhasSelecionadas, linhasSelecionadas });
    } else if (!filters.linha?.nrSeqChip) return;

    const linhasAdicionadas = linhasSelecionadas.linha.map((e) => e.nrSeqChip);
    const posicaoLinha = linhasAdicionadas.indexOf(filters.linha.nrSeqChip);

    if (posicaoLinha === -1) {
      const { linha } = linhasSelecionadas;
      linha.push(filters.linha);
      setFilters({
        ...filters,
        linha,
        nrSeqChip: null,
      });
    } else {
      const linha = [];
      linha.push(filters.linha);
      setFilters({
        ...filters,
        linha,
        nrSeqChip: null,
      });
    }
  };

  return (
    <div className='row'>
      <div className='mb-3'>
        <div className='row'>
          <div className='col-4'>
            <Autocomplete
              label='Prefixo/Número'
              searchDataSource={onSearchLinha}
              selectedItem={filters.linha}
              onSelectItem={(linha) =>
                setFilters({
                  ...filters,
                  linha,
                  nrSeqChip: linha.nrSeqChip,
                })
              }
              dataSourceTextProperty='linhaFormatada'
            />
          </div>
          <div className='col-4'>
            <Autocomplete
              label='Operadora'
              searchDataSource={onSearchOperadora}
              selectedItem={filters.operadora}
              onSelectItem={(operadora) =>
                setFilters({
                  ...filters,
                  nrSeqOperadora: operadora.nrSeqOperadora,
                })
              }
              dataSourceTextProperty='noOperadora'
            />
          </div>
          <div className='col-4'>
            <DropdownMulti
              label='Centro de Custos'
              ref={dropDownCentrosCusto}
              dataSourcePropertyText='noCentroDeCusto'
              dataSourcePropertyValue='nrSeqCentroDeCusto'
              selectedItems={selectedItems.centrosdecustos ?? []}
              onSelectItem={(centrosdecustos) => {
                setSelectedItems({ ...selectedItems, centrosdecustos });
                setFilters({
                  ...filters,
                  nrSeqCentrosDeCustos: centrosdecustos?.map(
                    (p) => p.nrSeqCentroDeCusto
                  ),
                });
              }}
            />
          </div>
          <div className='row'>
            <Loader loading={loading} />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='mb-3'>
          <Button
            outline
            theme={Theme.Success}
            template={Button.Templates.Default}
            text='Adicionar'
            onClick={onAdicionarLinha}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='accordion' id='accordionLinhasAdicionadas'>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingOne'>
                <button
                  className='accordion-button col-12'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseOne'
                  aria-expanded='true'
                  aria-controls='collapseOne'
                >
                  Linhas Adicionadas
                </button>
              </h2>
              <div
                id='collapseOne'
                className='accordion-collapse collapse show'
              >
                <div className='accordion-body'>
                  <LinhasSelecionadas
                    linhas={linhasSelecionadas.linha}
                    onRemoveLinha={onRemoveLinha}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PanelGestaoContaFiltroLinha;
