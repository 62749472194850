import {
  ProdutoAgrupador,
  ProdutoUnidadeMedida,
  ProdutoCodigo,
} from 'core/models/EST';

export default class Produto {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqProduto = jsonObject.nrSeqProduto;
    this.nrSeqProdutoUnidadeMedida = jsonObject.nrSeqProdutoUnidadeMedida;
    this.noProduto = jsonObject.noProduto;
    this.pesoBruto = jsonObject.pesoBruto;
    this.pesoLiq = jsonObject.pesoLiq;
    this.obs = jsonObject.obs;
    this.nrSeqGrupoPIS = jsonObject.nrSeqGrupoPIS;
    this.nrSeqGrupoICMS = jsonObject.nrSeqGrupoICMS;
    this.nrSeqGrupoISSQN = jsonObject.nrSeqGrupoISSQN;
    this.nrSeqProdutoOrigem = jsonObject.nrSeqProdutoOrigem;
    this.nrSeqProdutoMarca = jsonObject.nrSeqProdutoMarca;
    this.nrSeqGrupoIPI = jsonObject.nrSeqGrupoIPI;
    this.flgAtivo = jsonObject.flgAtivo;
    this.flgPneu = jsonObject.flgPneu;
    this.nrSeqGrupoCofins = jsonObject.nrSeqGrupoCofins;
    this.nrSeqProdutoSubGrupo = jsonObject.nrSeqProdutoSubGrupo;
    this.flgProdutoComposto = jsonObject.flgProdutoComposto;
    this.nrSeqProdutoCor = jsonObject.nrSeqProdutoCor;
    this.nrSeqProdutotTamanho = jsonObject.nrSeqProdutotTamanho;
    this.nrSeqProdutoLinha = jsonObject.nrSeqProdutoLinha;
    this.nrSeqProdutoTipoServico = jsonObject.nrSeqProdutoTipoServico;
    this.nrSeqProdutoMaterial = jsonObject.nrSeqProdutoMaterial;
    this.nrSeqProdutoAgrupador = jsonObject.nrSeqProdutoAgrupador;
    this.cdNCM = jsonObject.cdNCM;
    this.pesoLiquido = jsonObject.pesoLiquido;
    this.qteMaximo = jsonObject.qteMaximo;
    this.qteMinimo = jsonObject.qteMinimo;
    this.noCodigoANP = jsonObject.noCodigoANP;
    this.noDescricaoANP = jsonObject.noDescricaoANP;
    this.noProdutoAgrupador = jsonObject.noProdutoAgrupador;
    this.produtoCodigo = new ProdutoCodigo(jsonObject.produtoCodigo ?? {});
    this.produtoUnidadeMedida = new ProdutoUnidadeMedida(
      jsonObject.produtoUnidadeMedida ?? new ProdutoUnidadeMedida()
    );

    this.produtoAgrupador = new ProdutoAgrupador(
      jsonObject.produtoAgrupador ?? {}
    );

    this.codigos =
      jsonObject.codigos?.map((item) => new ProdutoCodigo(item)) ?? [];

    // #region Propriedades auxiliares
    this.noProdutoCodigoMarca = jsonObject.noProdutoCodigoMarca;
    this.nrSeqProdutoOld = jsonObject.nrSeqProdutoPraOld;
    this.nrSeqProdutoNew = jsonObject.nrSeqProdutoPraNew;
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
