import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Textbox, Switch } from 'ui/components';
// import { MaskTypes } from 'ui/Helpers/masks';

import {
  getColetaEtapa,
  saveColetaEtapa,
  deleteColetaEtapa,
} from 'core/services/FRO/coletaEtapa';

//  import {getGrupoEmpresaAutoComplete} from 'core/services/FIN/grupoEmpresa'; Autocomplete,

export default function ColetaEtapaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  /* const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  }; */

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getColetaEtapa(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveColetaEtapa(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteColetaEtapa(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  /*  const onSearchGrupoEmpresa = async (e) => {
    const {
      status,
      message: msg,
      grupoEmpresas
    } = await getGrupoEmpresaAutoComplete({noGrupoEmpresa:e});
    if (msg) onSetMessage(status, msg);
    return grupoEmpresas
    }; */

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Coleta Etapa'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqColetaEtapa > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-5 mb-3'>
          <Textbox
            required
            maxLength={20}
            label='Cód. Etapa Coleta'
            text={data.cdColetaEtapa}
            onChangedValue={(cdColetaEtapa) =>
              setData({ ...data, cdColetaEtapa })
            }
          />
        </div>
        <div className='col-5 mb-3'>
          <Textbox
            required
            maxLength={40}
            label='Etapa Coleta'
            text={data.noColetaEtapa}
            onChangedValue={(noColetaEtapa) =>
              setData({ ...data, noColetaEtapa })
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Solicitação'
            checked={data.flgSolicitacao}
            onChange={(flgSolicitacao) => setData({ ...data, flgSolicitacao })}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Embarque'
            checked={data.flgEmbarque}
            onChange={(flgEmbarque) => setData({ ...data, flgEmbarque })}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}

/**
 *  <div className='row'>
        <div className='col mb-3'>
          {  <Autocomplete
            label='GrupoEmpresa'
            searchDataSource={onSearchGrupoEmpresa}
            selectedItem={data.grupoEmpresa}
            onSelectItem={(grupoEmpresa) => {
            setData({
            ...data,
             grupoEmpresa,
            nrSeqGrupoEmpresa:grupoEmpresa.nrSeqGrupoEmpresa
            })
            }
            }
            dataSourceTextProperty='noGrupoEmpresa'
          /> }
        </div>
      </div>
 */
