import React, { useEffect, useRef, useState } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  getFornecedorIntegracaoPagined,
  printFornecedorIntegracao,
  deleteAllFornecedorIntegracao,
  excelFornecedorIntegracao,
} from 'core/services/SEG/fornecedorIntegracao';
import {
  Autocomplete,
  CSDSelPage,
  GridView,
  Panel,
  RadioButton,
} from 'ui/components';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';

const columns = [
  { key: 'nrSeqFornecedorIntegracao', type: GridView.ColumnTypes.Checkbox },
  { key: 'fornecedor.noPessoa', title: 'Fornecedor' },
  { key: 'noLayout', title: 'NoLayout' },
  { key: 'noEndereco', title: 'Email Campo Texto' },
  { key: 'modNfe', title: 'ModNfe' },
];

export default function FornecedorIntegracao({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({ modNfe: 'T' });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getFornecedorIntegracaoPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getFornecedorIntegracaoPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'FornecedorIntegracao/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqFornecedorIntegracao = sourceRow.find(
      (e) => e.key === 'nrSeqFornecedorIntegracao'
    );
    onOpenMaintenance(nrSeqFornecedorIntegracao.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printFornecedorIntegracao(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onDownloadExcel = async () => {
    setLoading(true);

    const { value: base64 } = await excelFornecedorIntegracao(filtros);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'FornecedorIntegracao.csv';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllFornecedorIntegracao(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchFornecedor = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaFors,
    } = await getFornecedorAutoComplete({
      noPessoa: e,
    });
    if (msg) setMessage(status, msg);
    return pessoaFors;
  };

  const tipoLayout = [
    {
      text: 'Todos',
      value: 'T',
    },
    {
      text: 'Nf-E',
      value: '1',
    },
    {
      text: 'Nfc-E',
      value: '2',
    },
  ];

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Fornecedor Integracao'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      onDownloadExcel={onDownloadExcel}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <Panel>
        <Panel.Header
          icon={['fas', 'user']}
          title='Filtros'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col-4 mb-4'>
              <Autocomplete
                label='Fornecedor'
                searchDataSource={onSearchFornecedor}
                selectedItem={filtros.fornecedor}
                onSelectItem={(fornecedor) => {
                  setFiltros({
                    ...filtros,
                    fornecedor,
                    nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                  });
                }}
                dataSourceTextProperty='nomeDocumentoCidadeEstado'
              />
            </div>
            <div className='col-4 mb-4'>
              <RadioButton
                label='Tipos de Nota Fiscal'
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={filtros.modNfe}
                buttons={tipoLayout}
                onChange={(modNfe) => {
                  setFiltros({
                    ...filtros,
                    modNfe,
                  });
                }}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
