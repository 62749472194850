import React, { useState, useRef } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { getVendedorTelefoniaList } from 'core/services/TEL';
import { getVendedorAutoComplete } from 'core/services/VEN/vendedor';
import { Autocomplete, CSDSelPage, GridView } from 'ui/components';

const SelecaoVendedorTelefonia = (props) => {
  const gridView = useRef();
  const [filters, setFilters] = useState({});
  const [messageState, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSetMessage = (status, message) => {
    if (message)
      setMessage({
        message,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    else setMessage(null);
  };

  const onOpenMaintenance = (rowItem) => {
    const { transaction, onOpenPage } = props;
    onOpenPage(
      transaction.noTransacao,
      'VendedorTelefonia/manutencao',
      PageTypes.Maintenence,
      rowItem
    );
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqVendedorTelefonia = sourceRow.find(
      (item) => item.key === 'nrSeqVendedor_Telefonia'
    );
    onOpenMaintenance(nrSeqVendedorTelefonia.value);
  };

  const onSearchVendedor = async (e) => {
    const { status, message, vendedores } = await getVendedorAutoComplete({
      noPessoa: e,
    });
    onSetMessage(status, message);
    return vendedores;
  };
  const onSearch = async () => {
    onSetMessage();
    setLoading(true);

    const {
      status,
      message,
      vendedoresTelefonia,
      pagination,
    } = await getVendedorTelefoniaList(filters);

    onSetMessage(status, message);
    if (gridView && gridView.current)
      gridView.current.setDataSource(vendedoresTelefonia, pagination);
    setLoading(false);
  };

  const onNew = async () => {
    onSetMessage();
    setLoading(true);

    const { transaction, onOpenPage } = props;

    onOpenPage(
      transaction.noTransacao,
      'VendedorTelefonia/manutencao',
      PageTypes.Maintenence,
      null
    );
    setLoading(false);
  };

  const columns = [
    { key: 'nrSeqVendedor_Telefonia', type: GridView.ColumnTypes.Checkbox },
    { key: 'noPessoa', title: 'Vendedor' },
    { key: 'flgPercentualFormatado', title: 'Percentual' },
    { key: 'flgDescontaCustoNfFormatado', title: 'Desconta Custo NF' },
    {
      key: 'flgDescontaCustoTaxaBancariaFormatado',
      title: 'Desconta Custo Taxa Bancaria',
    },
    { key: 'flgDescontaAvisoFormatado', title: 'Desconta Aviso' },
    { key: 'flgParcelaUnicaFormatado', title: 'Desconta Parcela Unica' },
    { key: 'flgDescontaCustoRateioFormatado', title: 'Desconta Custo Rateio' },
    {
      key: 'flgDescontaParcelaAbertaFormatado',
      title: 'Desconta Parcela Aberta',
    },
    { key: 'vlrComissao', title: 'Valor Comissão' },
  ];

  const { isActive, transaction } = props;
  return (
    <CSDSelPage
      isActive={isActive}
      title='Vendedor Telefonia'
      loading={loading}
      onSearch={onSearch}
      onNew={onNew}
      message={messageState}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-7'>
          <Autocomplete
            label='Vendedor'
            searchDataSource={onSearchVendedor}
            selectedItem={filters.vendedor}
            onSelectItem={(vendedor) =>
              setFilters({
                ...filters,
                vendedor,
                noPessoa: vendedor.noPessoa,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            canControlVisibility
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
};

export default SelecaoVendedorTelefonia;
