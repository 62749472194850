import qs from 'qs';
import Pagination from 'core/models/pagination';
import NfFatura from 'core/models/COP/nfFatura';
import api from '../api';

const baseURL = `/COP/NfFatura`;

export const getNfFaturaList = async (filters) => {
  const response = await api.get(`/$NfFatura/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new NfFatura(json)) ?? [],
  };
};

export const getNfFaturaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new NfFatura(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getNfFatura = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new NfFatura(value) : {};
};

export const saveNfFatura = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteNfFatura = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printNfFatura = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelNfFatura = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllNfFatura = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const convertBarCode = async (filters) => {
  const response = await api.get(`${baseURL}/ConvertBarCode`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};

export const getDtVencimentoValorParcela = async (filters) => {
  const response = await api.get(`${baseURL}/GetDtVencimentoValorParcela`, {
    params: filters,
  });

  const { value } = response.data ?? {};

  return {
    data: value ? new NfFatura(value) : {},
  };
};

export const getNfFaturasByPedidoCompra = async (nrSeqPedidoCompra) => {
  const response = await api.get(`${baseURL}/SearchByPedidoCompra`, {
    params: { nrSeqPedidoCompra },
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new NfFatura(json)) ?? [],
  };
};
