import React from 'react';
import {
  PageTypes,
  ResponseStatus,
  BootstrapSizes,
  Theme,
} from 'ui/Helpers/utils';
import { getVendedorTelefoniaAutoComplete } from 'core/services/TEL/vendedorTelefonia';
import { getFaturamentoPendenteList } from 'core/services/GProj/faturarContratos';
import {
  Autocomplete,
  GridView,
  Loader,
  Notification,
  Panel,
  Switch,
  ToolbarButtons,
} from 'ui/components';

import PanelSelecaoContratos from 'ui/pages/TEL/panelSelecaoContratos';
import ModalFaturar from './modalFaturar';
import ModalGerarNFS from './modalGerarNFS';

export default class FaturarContratos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filtros: {
        contratoAgrupado: true,
        filtroContratoServico: true,
        filtroPendenteFaturamento: true,
      },
    };
  }

  componentDidMount() {
    this.gridView = React.createRef();
  }

  onSearchVendedor = async (e) => {
    const {
      status,
      message,
      vendedores,
    } = await getVendedorTelefoniaAutoComplete({
      noPessoa: e,
    });

    if (message)
      this.setState({
        message,
        messageTheme:
          status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return vendedores;
  };

  onSearch = async () => {
    const { filtros } = this.state;
    const { nrSeqContratoAgrupado, nrSeqContaCabecalho } = filtros;

    if (
      (!nrSeqContratoAgrupado || nrSeqContratoAgrupado <= 0) &&
      (!nrSeqContaCabecalho || nrSeqContaCabecalho <= 0)
    ) {
      this.setState({
        message:
          'Obrigatório a seleção do contrato agrupado ou a conta e fatura, para a pesquisa dos contratos de serviço',
        messageTheme: Theme.Danger,
      });
    } else {
      this.setState({ loading: true, message: null, messageTheme: null });

      const {
        status,
        message,
        contratosPendentesFaturamento,
        pagination,
      } = await getFaturamentoPendenteList(filtros);

      if (message)
        this.setState({
          message,
          messageTheme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });

      if (this.gridView && this.gridView.current)
        this.gridView.current.setDataSource(
          contratosPendentesFaturamento,
          pagination
        );

      this.setState({ loading: false });
    }
  };

  onColumnSort = async (columnName) => {
    const { filtros } = this.state;
    filtros.SortBy = columnName;
    this.setState({ filtros });
    await this.onSearch();
  };

  onFaturarClick = (e) => {
    this.setState({ faturar: e });
  };

  onFaturarCloseClick = () => {
    this.setState({
      faturar: null,
      param: null,
    });
  };

  onCloseClickNFS = async () => {
    this.setState({
      faturar: null,
      param: null,
      showGerarNFS: false,
    });
  };

  onTransmitir = async () => {
    console.log('teste');
  };

  onSuccessFaturarContrato = async (
    nrseqpessoacli,
    nrseqempresa,
    nrseqfatura,
    parcelas,
    message,
    messageTheme,
    cancel
  ) => {
    await this.onSearch();

    this.setState({
      message,
      messageTheme,
      faturar: null,
      param: {
        nrSeqFatura: nrseqfatura,
        nrSeqPessoaCli: nrseqpessoacli,
        nrSeqEmpresa: nrseqempresa,
        faturamento: parcelas,
      },
      loading: false,
      showGerarNFS: cancel,
    });
  };

  onOpenMaintenance = () => {
    const { transaction, onOpenPage } = this.props;

    onOpenPage(
      transaction.noTransacao,
      'FaturarContratos/calcularComissao',
      PageTypes.Maintenence
    );
  };

  onGerarNFS = () => {
    // nrseqpessoacli,
    // nrseqempresa,
    // nrseqfatura,
    // parcelas,
    const { transaction, onOpenPage } = this.props;

    onOpenPage(
      transaction.noTransacao,
      'FaturarContratos/calcularComissao',
      PageTypes.Maintenence
    );
  };

  render() {
    const {
      filtros,
      loading,
      message,
      messageTheme,
      faturar,
      showGerarNFS,
      param,
    } = this.state;

    const columns = [
      { key: 'nrSeqContratoServico', type: GridView.ColumnTypes.Checkbox },
      {
        key: 'noContratoAgrupado',
        title: 'Contrato Agrupado',
        filterable: true,
      },
      {
        key: 'flgFaturaConsumidorfinal',
        title: 'Fat Direto',
        filterable: true,
      },
      { key: 'centroCustoCompleto', title: 'Centro Custo', filterable: true },
      { key: 'noPessoa', title: 'Vendedor', filterable: true, visible: false },
      { key: 'vlrTotal', title: 'Original', format: GridView.DataTypes.Money },
      {
        key: 'vlrTotalRecalculo',
        title: 'Recalculado',
        format: GridView.DataTypes.Money,
      },
      {
        key: 'vlrFaturado',
        title: 'Faturado',
        format: GridView.DataTypes.Money,
      },
      {
        key: 'vlrTotalRecebido',
        title: 'Recebido',
        format: GridView.DataTypes.Money,
      },
      {
        key: 'vlrTotalEmAberto',
        title: 'Em Aberto',
        format: GridView.DataTypes.Money,
        bgClassProperty: 'bgVlrTotalEmAberto',
      },
      {
        key: 'vlrPendente',
        title: 'Pendente',
        format: GridView.DataTypes.Money,
      },
      {
        key: 'vlrComissao',
        title: 'Comissão',
        format: GridView.DataTypes.Money,
      },
      {
        key: 'qtdeLinhas',
        title: 'Linhas',
        format: GridView.DataTypes.Integer,
      },
      { key: 'totalDias', title: 'Dt Venc' },
      {
        key: 'nrSeqContratoServico',
        title: 'Faturar',
        type: GridView.ColumnTypes.Button,
        onClick: this.onFaturarClick,
        theme: Theme.Primary,
        icon: 'cash-register',
        size: BootstrapSizes.Small,
        sortable: false,
      },
    ];

    const { className } = this.props;
    return (
      <>
        <Panel className={className}>
          <Loader loading={loading} />
          <Panel.Header title='Faturar Contratos'>
            <ToolbarButtons>
              <ToolbarButtons.Button
                text='Pesquisar'
                icon='search'
                onClick={this.onSearch}
              />
              <ToolbarButtons.Button
                text='Calcular Comissao'
                icon='calculator'
                onClick={this.onOpenMaintenance}
              />
            </ToolbarButtons>
          </Panel.Header>
          <Panel.Body>
            {message && (
              <div className='mb-3'>
                <Notification
                  message={message}
                  theme={messageTheme}
                  onClose={() => this.setState({ message: undefined })}
                />
              </div>
            )}
            <div className='row mb-3'>
              <div className='col'>
                <PanelSelecaoContratos
                  onContratoAgrupadoChange={(contratoAgrupado) =>
                    this.setState({
                      filtros: {
                        ...filtros,
                        nrSeqContratoAgrupado:
                          contratoAgrupado.nrSeqContratoAgrupado,
                      },
                    })
                  }
                  onContratoChange={(contrato) =>
                    this.setState({ filtros: { ...filtros, contrato } })
                  }
                  onFaturaChange={(fatura) =>
                    this.setState({ filtros: { ...filtros, fatura } })
                  }
                  onMessage={(msg, theme) =>
                    this.setState({ message: msg, messageTheme: theme })
                  }
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-auto'>
                <Switch
                  label='Contrato Serviço'
                  size={BootstrapSizes.Large}
                  formControl
                  checked={filtros.filtroContratoServico}
                  onChange={(filtroContratoServico) =>
                    this.setState({
                      filtros: { ...filtros, filtroContratoServico },
                    })
                  }
                />
              </div>
              <div className='col-auto'>
                <Switch
                  label='Pendente Faturamento'
                  size={BootstrapSizes.Large}
                  formControl
                  checked={filtros.filtroPendenteFaturamento}
                  onChange={(filtroPendenteFaturamento) =>
                    this.setState({
                      filtros: { ...filtros, filtroPendenteFaturamento },
                    })
                  }
                />
              </div>
              <div className='col'>
                <Autocomplete
                  label='Vendedor'
                  searchDataSource={this.onSearchVendedor}
                  selectedItem={filtros.vendedor}
                  onSelectItem={(vendedor) =>
                    this.setState({
                      filtros: {
                        ...filtros,
                        vendedor,
                        nrSeqPessoaVen: vendedor.nrSeqPessoa,
                      },
                    })
                  }
                  dataSourceTextProperty='noPessoa'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col'>
                <hr />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={this.gridView}
                  className='table-striped table-hover table-bordered table-sm'
                  enableExcelExport
                  dataColumns={columns}
                  onColumnSort={this.onColumnSort}
                  onPageSizeChange={this.onPageSizeChange}
                  onPageChange={this.onPageChange}
                  onRowDoubleClick={this.onRowDoubleClick}
                  canControlVisibility
                  sumFields
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
        {faturar && (
          <ModalFaturar
            contratoServico={faturar}
            onCloseClick={this.onFaturarCloseClick}
            onSuccessFaturarContrato={this.onSuccessFaturarContrato}
            onTransmitir={this.onTransmitir}
          />
        )}
        {param && (
          <ModalGerarNFS
            show={showGerarNFS}
            param={param}
            onCloseClick={this.onFaturarCloseClick}
          />
        )}
      </>
    );
  }
}
