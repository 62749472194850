import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { Modal, Notification, FileUpload, Loader, Button } from 'ui/components';
import { BootstrapSizes, MimeTypes, Theme } from 'ui/Helpers/utils';
import { ImportaManutencaochecklistFrotaDocumentos } from 'core/services/FRO/manutencaoChecklistFrotaDocumento';

const ModalImportDocumento = forwardRef(
  ({ props, show, onClose, manutencaochecklistFrotaDocumento }, param) => {
    const [loading, setLoading] = useState(false);
    const [importedFiles, setImportedFiles] = useState([]);
    const [message, setMessage] = useState(null);

    useImperativeHandle(param, () => ({
      param: importedFiles,
    }));

    const importFile = async () => {
      setLoading(true);

      if (importedFiles.length > 0) {
        let dataSource = [];

        const {
          data: retorno,
        } = await ImportaManutencaochecklistFrotaDocumentos(
          manutencaochecklistFrotaDocumento,
          importedFiles
        );
        if (retorno !== undefined) {
          dataSource = retorno;
        }

        onClose(dataSource);
        setImportedFiles([]);
        setMessage(null);
      } else {
        setMessage({
          message: 'Não há arquivos selecionados para importação.',
          theme: Theme.Danger,
        });
      }
    };

    useEffect(() => {
      if (show) setImportedFiles([]);
    }, [show, props]);

    const onSend = () => {
      setImportedFiles([]);
      onClose();
    };

    return (
      <Modal
        show={show}
        title='Importar Documento'
        icon='cloud-upload-alt'
        onClose={() => onSend()}
        size={BootstrapSizes.Large}
      >
        <Modal.Body>
          <Loader loading={loading} />
          {message && (
            <div className='mb-3'>
              <Notification
                message={message.message}
                theme={message.theme}
                onClose={() => setMessage(null)}
              />
            </div>
          )}
          <div className='row mb-4'>
            <FileUpload
              files={importedFiles}
              onChange={(files) => {
                setImportedFiles(files);
                setMessage(null);
              }}
              allowedMimeTypes={[MimeTypes.Types.Image]}
              maxFiles={60}
              dropzone
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size={BootstrapSizes.Small}
            icon='cloud-upload-alt'
            theme={Theme.Success}
            template={Button.Templates.Default}
            onClick={importFile}
            text='Importar'
          />
          <Button
            size={BootstrapSizes.Small}
            icon={['fas', 'times']}
            theme={Theme.Danger}
            template={Button.Templates.Default}
            onClick={() => onSend()}
            text='Sair'
          />
        </Modal.Footer>
      </Modal>
    );
  }
);

export default ModalImportDocumento;
