import React, { useRef, useState } from 'react';
import { Theme } from 'ui/Helpers/utils';
import { Button, FontAwesomeIcon } from 'ui/components';

function videoOff(video) {
  const stream = video.srcObject;
  const tracks = stream.getTracks();

  tracks.forEach((track) => {
    track.stop();
  });
}

export default function Capture({
  picture,
  onPictureLoad,
  defaultIcon = 'user',
  cameraButtonVisible = true,
}) {
  const video = useRef();
  const fileUpload = useRef();
  const [showCam, setShowCam] = useState(false);

  function loadCamera() {
    setShowCam(true);
    video.current.setAttribute('autoplay', '');
    video.current.setAttribute('muted', '');
    video.current.setAttribute('playsinline', '');

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: false, video: { facingMode: 'user' } })
        .then((stream) => {
          video.current.srcObject = stream;
        });
    }
  }

  function takeSnapshot() {
    const canvas = document.createElement('canvas');
    canvas.width = video.current.videoWidth;
    canvas.height = video.current.videoHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(video.current, 0, 0, canvas.width, canvas.height);

    if (onPictureLoad) onPictureLoad(canvas.toDataURL('image/jpeg'));

    setShowCam(false);
    videoOff(video.current);
    video.current.srcObject = null;
  }

  function cleanSnapshot() {
    if (onPictureLoad) onPictureLoad(null);
  }

  function unloadCamera() {
    setShowCam(false);
    videoOff(video.current);
    video.current.srcObject = null;
  }

  function uploadImage() {
    fileUpload.current.click();
  }

  function imageUploaded(file) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => onPictureLoad && onPictureLoad(reader.result),
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  const showSnapshot = !showCam && picture;
  const showDefault = !showCam && !picture;
  return (
    <div className='capture'>
      <input
        type='file'
        className='d-none'
        ref={fileUpload}
        accept='image/*'
        onChange={(e) => imageUploaded(e.currentTarget.files[0])}
      />
      <div className='img-thumbnail img-fluid m-3 bg-light'>
        <div className='row'>
          <div className='col d-flex file-upload-image'>
            {showDefault && <FontAwesomeIcon icon={defaultIcon} />}
            {showSnapshot && <img src={picture} alt='Imagem carregada' />}
            <video autoPlay ref={video} className={showCam ? '' : 'd-none'}>
              <track kind='captions' />
            </video>
          </div>
        </div>
        <div className='row toolbar-buttons'>
          <div className='col d-grid gap-1'>
            <Button
              icon='cloud-upload-alt'
              theme={Theme.Secondary}
              tooltip='Importar Arquivo'
              visible={!showCam}
              onClick={uploadImage}
            />
            <Button
              icon='ban'
              theme={Theme.Secondary}
              tooltip='Cancelar'
              onClick={unloadCamera}
              visible={showCam}
            />
          </div>
          <div className='col d-grid gap-1'>
            <Button
              icon='camera'
              theme={Theme.Secondary}
              tooltip='Capturar Webcam'
              onClick={loadCamera}
              visible={cameraButtonVisible && !showCam}
            />
          </div>
          <div className='col d-grid gap-1'>
            <Button
              icon='broom'
              theme={Theme.Secondary}
              tooltip='Limpar Arquivo Importado'
              visible={!showCam}
              onClick={cleanSnapshot}
            />
            <Button
              icon='camera'
              theme={Theme.Secondary}
              tooltip='Capturar foto'
              onClick={takeSnapshot}
              visible={showCam}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
