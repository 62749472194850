import React, { useState } from 'react';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { Button, Loader, Modal, Notification, Textarea } from 'ui/components';
import { onCancelaFreteTerceiro } from '../../../../core/services/FRO/freteTerceiro';

export default function ModalCancelaFrete({ show, onClose, data, setData }) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onClickCancelar = async () => {
    setLoading(true);

    const response = await onCancelaFreteTerceiro(data);

    if (response.status === ResponseStatus.Success) {
      onClose(response);
    } else {
      onSetMessage(ResponseStatus.Error, response.message);
    }

    setLoading(false);
  };

  return (
    <Modal
      show={show}
      title='Cancelar Recibo de Frete'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-2'>
          <div className='col mb-3'>
            <Textarea
              label='Observações'
              maxLength={1000}
              text={data.motivoCancelamento}
              onChangedValue={(motivoCancelamento) =>
                setData({ ...data, motivoCancelamento })
              }
            />
          </div>
        </div>
        <div className='row justify-content-end mb-2'>
          <div className='col-3'>
            <Button
              outline
              theme={Theme.Success}
              text='Confirmar Cancelamento'
              icon='fa-solid fa-cloud-check'
              onClick={onClickCancelar}
            />
          </div>
          <div className='col-2'>
            <Button
              outline
              text='Cancelar'
              theme={Theme.Danger}
              icon='fas fa-times'
              onClick={() => {
                onClose();
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
