import React, { useState, useEffect, useRef } from 'react';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  Autocomplete,
  Button,
  Loader,
  Modal,
  Notification,
  DatePicker,
  GridView,
} from 'ui/components';
import { getConvenioAutoComplete } from 'core/services/ASS/convenio';
import { getPlanoConvenioList } from 'core/services/ASS/planoConvenio';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getInformacaoBancariaAutoComplete } from 'core/services/FIN/informacaoBancaria';
import { saveAllAssociadoPlanosConvenio } from 'core/services/ASS/associadoPlanoConvenio';

export default function ModalVinculaPlanoAssociado(props) {
  const { show, onClose, dadosAssociadoPlano } = props;
  const [dataAssociado, setDataAssociado] = useState({});

  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const gridViewPlanosAssociado = useRef();

  const columns = [
    { key: 'nrSeqAssocPlanoConv', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'planoConvenio.convenio.noConvenio',
      title: 'Convênio',
    },
    {
      key: 'planoConvenio.noPlanoConvenio',
      title: 'Plano',
    },
    { key: 'formaPagamento.noFormaPagamento', title: 'Forma Pagamento' },
    {
      key: 'dtInicioVigencia',
      title: 'Inicio Vigência',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'dtFimVigencia',
      title: 'Fim Vigência',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'flgFaturaConsolidador',
      title: 'Fatura Consolidador',
      format: GridView.DataTypes.Boolean,
    },
  ];

  const load = async () => {
    const dadosPlano = dadosAssociadoPlano?.planoConvenioEdit ?? {};

    if (gridViewPlanosAssociado && gridViewPlanosAssociado.current) {
      gridViewPlanosAssociado.current.setDataSource(
        dadosAssociadoPlano.planosConvAssociado ?? []
      );
    }

    setDataAssociado(dadosPlano);
  };

  useEffect(() => {
    load();
  }, [show]);

  const onClickVincularPlano = async () => {
    if (!dataAssociado.nrSeqConvenio) {
      setMessage({
        message: 'Necessário preencher o campo Convênio.',
        theme: Theme.Danger,
      });
      return;
    }
    if (!dataAssociado.planoConvenio) {
      setMessage({
        message: 'Necessário preencher o plano do convênio.',
        theme: Theme.Danger,
      });
      return;
    }

    if (!dataAssociado.formaPagamento) {
      setMessage({
        message: 'Necessário preencher a forma de pagamento.',
        theme: Theme.Danger,
      });
      return;
    }

    if (
      !dataAssociado.pessoaInformacaoBancaria &&
      dataAssociado.pessoaInformacaoBancaria.nrSeqPessoaInformacaoBancaria
    ) {
      setMessage({
        message: 'Necessário preencher o campo informações bancarias.',
        theme: Theme.Danger,
      });
      return;
    }

    if (!dataAssociado.dtInicioVigencia) {
      setMessage({
        message: 'Necessário preencher a data de início da vigência.',
        theme: Theme.Danger,
      });
      return;
    }

    if (
      dataAssociado.dtFimVigencia &&
      dataAssociado.dtFimVigencia <= Date.now()
    ) {
      setMessage({
        message:
          'Necessário preencher a data final da vigência precisa ser mamior que a data atual.',
        theme: Theme.Danger,
      });
      return;
    }

    const lista = gridViewPlanosAssociado?.current?.getDataSource() ?? [];

    if (!dataAssociado.nrSeqAssocPlanoConv > 0) {
      dataAssociado.status = GridView.EnumStatus.Inserir;
    } else {
      dataAssociado.status = GridView.EnumStatus.Alterar;
    }

    lista.push(dataAssociado);

    if (gridViewPlanosAssociado && gridViewPlanosAssociado.current)
      gridViewPlanosAssociado.current.setDataSource(lista);

    setDataAssociado({});
  };

  const onSave = async () => {
    const listaPlanos = gridViewPlanosAssociado?.current?.getDataSource() ?? [];

    if (listaPlanos.length > 0) {
      setLoading(true);

      const { status, message: msg } = await saveAllAssociadoPlanosConvenio(
        listaPlanos
      );

      if (status === ResponseStatus.Success) {
        setDataAssociado({});
        gridViewPlanosAssociado?.current?.setDataSource([]);
        onClose();
        props.onSave(status, msg);
      } else {
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
      }

      setLoading(false);
    }
  };

  const onSearchConvenio = async (e) => {
    const { status, message: msg, convenios } = await getConvenioAutoComplete({
      noConvenio: e,
    });
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    return convenios;
  };

  const onSearchPlanoConvenio = async (e) => {
    const {
      status,
      message: msg,
      data: planosConvenio,
    } = await getPlanoConvenioList({
      noPlanoConvenio: e,
      nrSeqConvenio: dataAssociado?.nrSeqConvenio ?? null,
    });
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    return planosConvenio;
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noformaPagamento: e,
    });
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    return formasPagamento;
  };

  const onSearchInfoBancaria = async () => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoComplete({
      nrSeqPessoa: dataAssociado.nrSeqPessoaAss,
    });
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    return informacoesBancarias;
  };

  return (
    <Modal
      show={show}
      title='Vincular Associado'
      onClose={onClose}
      size={BootstrapSizes.Large}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='card'>
          <div className='card-body'>
            <div className='row justify-content-between'>
              <div className='col-4 mb-3'>
                <Autocomplete
                  label='Convênio'
                  searchDataSource={onSearchConvenio}
                  selectedItem={dataAssociado.convenio}
                  onSelectItem={(convenio) => {
                    setDataAssociado({
                      ...dataAssociado,
                      convenio,
                      nrSeqConvenio: convenio.nrSeqConvenio,
                      flgTelefonia: convenio.flgTelefonia,
                      flgConvenioMassivo: convenio?.flgConvenioMassivo,
                    });
                  }}
                  dataSourceTextProperty='noConvenio'
                />
              </div>

              <div className='col-4 mb-3'>
                <Autocomplete
                  label='Plano'
                  searchDataSource={onSearchPlanoConvenio}
                  selectedItem={dataAssociado.planoConvenio}
                  onSelectItem={(planoConvenio) => {
                    setDataAssociado({
                      ...dataAssociado,
                      planoConvenio,
                      nrSeqPlanoConvenio: planoConvenio.nrSeqPlanoConvenio,
                    });
                  }}
                  dataSourceTextProperty='noPlanoConvenio'
                />
              </div>

              <div className='col-4 mb-3'>
                <Autocomplete
                  label='Forma Pagamento'
                  searchDataSource={onSearchFormaPagamento}
                  selectedItem={dataAssociado.formaPagamento}
                  onSelectItem={(formaPagamento) =>
                    setDataAssociado({
                      ...dataAssociado,
                      formaPagamento,
                      nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                    })
                  }
                  dataSourceTextProperty='noFormaPagamento'
                />
              </div>
            </div>
            <div className='row justify-content-between'>
              <div className='col-4 mb-3'>
                <Autocomplete
                  label='Informação Bancária'
                  searchDataSource={onSearchInfoBancaria}
                  selectedItem={dataAssociado.pessoaInformacaoBancaria}
                  onSelectItem={(pessoaInformacaoBancaria) =>
                    setDataAssociado({
                      ...dataAssociado,
                      pessoaInformacaoBancaria,
                      nrSeqPessoaInformacaoBancaria:
                        pessoaInformacaoBancaria.nrSeqPessoaInformacaoBancaria,
                    })
                  }
                  dataSourceTextProperty='noDescricao'
                />
              </div>
              <div className='col-3 mb-3'>
                <DatePicker
                  label='Início Vigência'
                  text={dataAssociado.dtInicioVigencia}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtInicioVigencia) =>
                    setDataAssociado({ ...dataAssociado, dtInicioVigencia })
                  }
                />
              </div>

              <div className='col-3 mb-3'>
                <DatePicker
                  label='Fim Vigência'
                  text={dataAssociado.dtFimVigencia}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtFimVigencia) =>
                    setDataAssociado({ ...dataAssociado, dtFimVigencia })
                  }
                />
              </div>

              <div className='col-2 mt-4'>
                <Button
                  outline
                  theme={Theme.Success}
                  template={Button.Templates.Button}
                  text='Adicionar'
                  size={BootstrapSizes.Small}
                  onClick={onClickVincularPlano}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='row mt-3'>
          <div className='col mb-2'>
            <GridView
              ref={gridViewPlanosAssociado}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>

        <div className='row justify-content-end mt-3 mb-4'>
          <div className='col-3 mb-3'>
            <Button
              outline
              theme={Theme.Success}
              text='Confirmar'
              icon='fa-solid fa-cloud-check'
              onClick={onSave}
            />
          </div>
          <div className='col-3 mb-3'>
            <Button
              outline
              text='Cancelar'
              theme={Theme.Danger}
              icon='fas fa-times'
              onClick={() => {
                setDataAssociado({});
                onClose();
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
