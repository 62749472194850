import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createBrowserHistory } from 'history'; // eslint-disable-line import/no-extraneous-dependencies
import { logout } from 'core/services/auth';
import { storeLogout } from 'store/actions';
import { Button, FontAwesomeIcon } from 'ui/components';
import {
  getUsuarioNotificacaoList,
  postSetVisualizada,
} from 'core/services/SEG/usuarioNotificacao.ts';
import Avatar from 'ui/theme/img/avatar.png';
import ChangePassword from './changePassword';
import ModalUsuarioNotificacao from './modalUsuarioNotificacao';

class Userbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changePassword: false,
      modalShow: false,
      userNotifications: [],
      msgTooltip: '',
      primeiravez: true,
      cor: 'btn-link text-warning',
    };
  }

  onLogout = async () => {
    await logout();
    const { storeLogout: handleStoreLogout } = this.props;
    handleStoreLogout();

    const history = createBrowserHistory();
    history.push('/Login');
  };

  onModalClose = () => {
    this.setState({ changePassword: false });
  };

  onGetNotifcacoes = async () => {
    this.setState({
      primeiravez: false,
    });
    const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;
    const { notificacao } = await getUsuarioNotificacaoList({
      nrSeqUsuario: sessionUser.nrSeqUsuario,
    });

    if (notificacao.length > 0) {
      this.setState({
        modalShow: true,
        userNotifications: notificacao,
        msgTooltip: `Há ${notificacao.length} notificações não lidas!`,
      });
    } else {
      this.setState({ msgTooltip: 'Não há notificações!', cor: 'btn-link' });
    }
  };

  onModalNotificacaoClose = () => {
    this.setState({ modalShow: false });
  };

  onModalNotificacaoSetVisualizadaClose = (param) => {
    this.setState({ modalShow: false, userNotifications: [] });
    postSetVisualizada(param).then(() => {
      this.onGetNotifcacoes().then(() => {
        this.setState({ modalShow: true });
      });
    });
  };

  onGetQtdNotifcacoes = async () => {
    if (this.primeiravez) {
      const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;
      const { notificacao } = await getUsuarioNotificacaoList({
        nrSeqUsuario: sessionUser.nrSeqUsuario,
      });
      if (notificacao.length > 0) {
        this.setState({
          msgTooltip: `Há ${notificacao.length} notificações não lidas!`,
          primeiravez: false,
        });
      } else {
        this.setState({
          msgTooltip: 'Não há notificações!',
          primeiravez: false,
        });
      }
    }
    this.setState({
      primeiravez: false,
    });
  };

  render() {
    const {
      changePassword,
      modalShow,
      userNotifications,
      msgTooltip,
      primeiravez,
      cor = 'btn-link',
    } = this.state;
    const {
      authentication: { user },
    } = this.props;
    return (
      <div className='row'>
        <div className='col-auto'>
          <div className='col-auto'>
            <div>
              <Button
                className={cor}
                icon='bell'
                tooltip={msgTooltip}
                onClick={() => {
                  this.onGetNotifcacoes();
                }}
              />
            </div>
          </div>
        </div>
        <div className='col-auto'>
          <>
            <div
              className='right-menu'
              id='dropdownMenuButton'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              {console.log('primeiravez', primeiravez)}
              <div className='row'>
                <div className='col-auto'>
                  <div>Bem Vindo(a) !</div>
                  <div>{user?.noPessoa}</div>
                </div>
                <div className='col d-flex align-items-center'>
                  <img
                    className='img-thumbnail rounded-circle'
                    alt='Imagem do perfil do usuário'
                    src={Avatar}
                  />
                </div>
              </div>
            </div>
            <ul
              className='dropdown-menu dropdown-menu-end'
              aria-labelledby='dropdownMenuButton'
            >
              <li>
                <Button
                  className='btn-link dropdown-item '
                  text='Alterar Senha'
                  onClick={() => {
                    this.setState({
                      changePassword: true,
                    });
                  }}
                />
              </li>
              <li className='border-top'>
                <Button
                  className='btn-link dropdown-item text-danger'
                  onClick={this.onLogout}
                >
                  <FontAwesomeIcon icon='sign-out-alt' />
                  <span>Sair</span>
                </Button>
              </li>
            </ul>
            <ChangePassword
              show={changePassword}
              onModalClose={this.onModalClose}
            />
            <ModalUsuarioNotificacao
              show={modalShow}
              userNotifications={userNotifications}
              setVisualizadas={this.setVisualizadas}
              onClose={() => this.onModalNotificacaoClose()}
              onCloseSetVisualizada={(param) => {
                this.onModalNotificacaoSetVisualizadaClose(param);
              }}
            />
          </>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ storeLogout }, dispatch);
const mapStateToProps = (store) => ({
  authentication: store.authState.authentication,
});
export default connect(mapStateToProps, mapDispatchToProps)(Userbar);
