import Pessoa from 'core/models/SEG/pessoa';
import TipoSeguro from 'core/models/FRO/tipoSeguro';
import AverbadoraSeguro from 'core/models/FRO/averbadoraSeguro';
import ApoliceSeguroVeiculo from 'core/models/FRO/apoliceSeguroVeiculo';
import Fornecedor from 'core/models/FIN/fornecedor';
import ApoliceSeguroPai from 'core/models/FRO/apoliceSeguro';

export default class ApoliceSeguro {
  constructor(jsonObject = {}) {
    this.nrSeqApoliceSeguro = jsonObject.nrSeqApoliceSeguro;
    this.nrApolice = jsonObject.nrApolice;
    this.dtInicioVigencia = jsonObject.dtInicioVigencia;
    this.dtFimVigencia = jsonObject.dtFimVigencia;
    this.vlrFranquia = jsonObject.vlrFranquia;
    this.vlrIndPessoal = jsonObject.vlrIndPessoal;
    this.vlrIndMaterial = jsonObject.vlrIndMaterial;
    this.vlrApolice = jsonObject.vlrApolice;
    this.nrSeqPessoaFor = jsonObject.nrSeqPessoaFor;
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqTipoSeguro = jsonObject.nrSeqTipoSeguro;
    this.nrSeqApoliceSeguroPai = jsonObject.nrSeqApoliceSeguroPai;
    this.nrSeqAverbadoraSeguro = jsonObject.nrSeqAverbadoraSeguro;

    // #region Propriedades Auxiliares
    this.apoliceSeguroPai = jsonObject.apoliceSeguroPai
      ? new ApoliceSeguroPai(jsonObject.apoliceSeguroPai)
      : null;
    this.tipoSeguro = new TipoSeguro(jsonObject.tipoSeguro ?? {});
    this.fornecedor = new Fornecedor(jsonObject.fornecedor ?? {});
    this.pessoa = new Pessoa(jsonObject.pessoa ?? {});
    this.averbadoraSeguro = new AverbadoraSeguro(
      jsonObject.averbadoraSeguro ?? {}
    );
    this.apoliceSeguroVeiculos =
      jsonObject.apoliceSeguroVeiculos?.map(
        (el) => new ApoliceSeguroVeiculo(el)
      ) ?? [];

    this.noAutoComplete = jsonObject.noAutoComplete;
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
