import Equipamento from './equipamento';
import EquipamentoMarca from './equipamentoMarca';

export default class VeiculoEquipamento {
  constructor(jsonObject = {}) {
    this.nrSeqVeiculoEquipamento = jsonObject.nrSeqVeiculoEquipamento;
    this.tipoEquipamento = jsonObject.tipoEquipamento;
    this.idEquipamento = jsonObject.idEquipamento;
    this.dtInstalacao = jsonObject.dtInstalacao;
    this.dtDesinstalacao = jsonObject.dtDesinstalacao;
    this.vlrMensalidade = jsonObject.vlrMensalidade;
    this.diaVencimento = jsonObject.diaVencimento;
    this.nrSeqEquipamentoMarca = jsonObject.nrSeqEquipamentoMarca;
    this.nrSeqVeiculoRet = jsonObject.nrSeqVeiculoRet;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.noObservacao = jsonObject.noObservacao;
    this.noLogin = jsonObject.noLogin;
    this.noSenha = jsonObject.noSenha;
    this.nrSeqEquipamento = jsonObject.nrSeqEquipamento;
    this.equipamento = new Equipamento(jsonObject.equipamento ?? {});
    this.equipamentoMarca = new EquipamentoMarca(
      jsonObject.equipamentoMarca ?? {}
    );
    this.status = jsonObject.status;
    this.manutencaoPreventiva = jsonObject.manutencaoPreventiva;
    this.nrSeqManutencaoPreventiva = jsonObject.nrSeqManutencaoPreventiva;
  }

  toJson = () => JSON.stringify(this);
}
