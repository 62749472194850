export default class VeiculoCentroDeCusto {
  constructor(jsonObject = {}) {
    this.nrSeqVeiculoCentroDeCusto = jsonObject.nrSeqVeiculoCentroDeCusto;
    this.nrSeqCentroDeCusto = jsonObject.nrSeqCentroDeCusto;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.dtInicial = jsonObject.dtInicial;
    this.dtFinal = jsonObject.dtFinal;
    this.centroDeCusto = jsonObject.centroDeCusto;
  }

  toJson = () => JSON.stringify(this);
}
