import qs from 'qs';
import Pagination from 'core/models/pagination';
import Endereco from 'core/models/SEG/endereco';
import api from '../api';

const baseURL = `/SEG/Endereco`;

export const getEnderecoList = async (filters) => {
  const response = await api.get(`/$Endereco/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Endereco(json)) ?? [],
  };
};

export const getEnderecoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Endereco(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getEndereco = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Endereco(value) : {};
};

export const getEnderecoByPessoa = async (id) => {
  const response = await api.get(`${baseURL}/SearchByPerson`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Endereco(value) : {};
};

export const getResidentialAddressByPerson = async (id) => {
  const response = await api.get(`${baseURL}/SearchByPerson`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Endereco(value) : {};
};

export const getAllAddressByPerson = async (id) => {
  const response = await api.get(`${baseURL}/SearchByPersonList`, {
    params: { id },
  });
  const { value } = response.data ?? {};

  const addresses = value?.map((json) => new Endereco(json)) ?? [];

  return addresses;
};

export const getAllAddressByPersonWithNotResidential = async (id) => {
  const response = await api.get(
    `${baseURL}/SearchNotResidentialAddressByPerson`,
    {
      params: { id },
    }
  );
  const { value } = response.data ?? {};

  const addresses = value?.map((json) => new Endereco(json)) ?? [];

  return addresses;
};

export const saveEndereco = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteEndereco = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printEndereco = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelEndereco = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllEndereco = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getEnderecoByPersonList = async (id) => {
  const response = await api.get(`${baseURL}/SearchByPersonList`, {
    params: { id },
  });

  const { value } = response.data ?? {};

  return value ? value.map((item) => new Endereco(item)) : [];
};
