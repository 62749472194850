export default class ArquivoBancario {
  constructor(jsonObject = {}) {
    this.nrSeqArquivoBancario = jsonObject.nrSeqArquivoBancario;
    this.nrSeqBanco = jsonObject.nrSeqBanco;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.dtLancamento = jsonObject.dtLancamento;
    this.flgRetorno = jsonObject.flgRetorno;
    this.noArquivo = jsonObject.noArquivo;
    this.nrSequencia = jsonObject.nrSequencia;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.flgPagamento = jsonObject.flgPagamento;
    this.dtCadastro = jsonObject.dtCadastro;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
  }

  toJson = () => JSON.stringify(this);
}
