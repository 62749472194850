import qs from 'qs';
import ResultadoVeiculo from 'core/models/FRO/resultadoVeiculo';
import Pagination from 'core/models/pagination';
import api from '../api';

const baseURL = `/FRO/ResultadoVeiculo`;

export const getResultadoVeiculoPaginated = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPaginated`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ResultadoVeiculo(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getResultadoVeiculo = async (filters) => {
  const response = await api.get(`${baseURL}/Find`, {
    params: filters,
  });
  const { value } = response.data ?? {};

  return value ? new ResultadoVeiculo(value) : {};
};

export const printResultadoVeiculoResumo = async (filters) => {
  const response = await api.get(`${baseURL}/PrintResumo`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printResultadoVeiculo = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printResultadoVeiculoDespesasPorCompetencia = async (filters) => {
  const response = await api.get(`${baseURL}/PrintDespesasPorCompetencia`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
