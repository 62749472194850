import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  JustifyContent,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  Panel,
  GridView,
  Button,
  Switch,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getProdutoAgrupador,
  saveProdutoAgrupador,
  deleteProdutoAgrupador,
  getProdutoConfig,
} from 'core/services/EST/produtoAgrupador';

import { getHistoricoCompras } from 'core/services/COP/nfItem';
import { getFindLatestOutGoingMovements } from 'core/services/EST/estoqueMovimento';
import { getProdutoMarcaAutoComplete } from 'core/services/EST/produtoMarca';
import { getProdutoLinhaAutoComplete } from 'core/services/EST/produtoLinha';
import { getProdutoOrigemAutoComplete } from 'core/services/EST';
import { getProdutoUnidadeMedidaAutoComplete } from 'core/services/EST/produtoUnidadeMedida';
import { getProdutoGrupoAutoComplete } from 'core/services/EST/produtoGrupo';
import { getProdutoSubGrupoAutoComplete } from 'core/services/EST/produtoSubGrupo';
import { getAlmoxarifadoAutoComplete } from 'core/services/EST/almoxarifado';
import { getAlmoxarifadoLocalAutoComplete } from 'core/services/EST/almoxarifadoLocal';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';
import { getGrupoCofinsAutoComplete } from 'core/services/FIS/grupoCofins';
import { getGrupoIPIAutoComplete } from 'core/services/FIS/grupoIPI';
import { getGrupoIssqnAutoComplete } from 'core/services/FIS/grupoIssqn';
import { getGrupoPISAutoComplete } from 'core/services/FIS/grupoPIS';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { ProdutoAgrupador } from 'core/models/EST';
import { getManutencaoPreventivaAutoComplete } from 'core/services/EST/manutencaoPreventiva';
import ManutencaoPreventiva from 'core/models/EST/manutencaoPreventiva';
import ProdutoCodigo from 'core/models/EST/produtoCodigo';
import { getGrupoIcmsAutoComplete } from 'core/services/FIS';
import PanelEtiquetasProdutos from './PanelEtiquetasProdutos';
import ModalMoverProduto from './modalMoverProduto';

export default function ProdutoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  findTransaction,
  onOpenTransaction,
}) {
  const formSubmit = useRef();
  const gridViewAlmoxarifados = useRef();
  const gridViewCodigos = useRef();
  const gridViewHistorico = useRef();
  const gridViewHistoricoMovimento = useRef();
  const gridViewManutencaoPreventiva = useRef();
  const nrseqproduto = useRef();
  const [data, setData] = useState(
    new ProdutoAgrupador({ pesoBruto: 0, pesoLiq: 0, flgAtivo: true })
  );
  const [dataManutencaoPreventiva, setDataManutencaoPreventiva] = useState(
    new ManutencaoPreventiva({})
  );
  const [dataAlmoxarifado, setDataAlmoxarifado] = useState({
    flgAtivo: true,
    qtdeMaximo: '',
    qtdeMinimo: '',
  });
  const [dataProdutoCodigo, setDataProdutoCodigo] = useState({});
  const [dataProdutoEtiquetas, setDataProdutoEtiquetas] = useState({
    gerarEtiquetas: false,
    produtoCodigoEtiqueta: [],
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [config, setConfig] = useState({});
  const [modalMoverProduto, setModalMoverProduto] = useState({
    show: false,
  });

  const onClickRemoveAlmoxarifado = async (selectedValue, datasource) => {
    datasource.find((almoxarifado) => almoxarifado === selectedValue).status =
      GridView.EnumStatus.Remover;

    if (gridViewAlmoxarifados && gridViewAlmoxarifados.current)
      gridViewAlmoxarifados.current.setDataSource(datasource);
  };

  const onClickEditAlmoxarifado = async (selectedValue, datasource) => {
    const dataAlmoxarifados = datasource.filter(
      (almoxarifadoLocal) => almoxarifadoLocal !== selectedValue
    );

    const dataAlmoxarifadoItem = datasource.filter(
      (almoxarifadoLocal) => almoxarifadoLocal === selectedValue
    );

    dataAlmoxarifadoItem[0].status = GridView.EnumStatus.Alterar;
    setDataAlmoxarifado(dataAlmoxarifadoItem[0]);

    if (gridViewAlmoxarifados && gridViewAlmoxarifados.current)
      gridViewAlmoxarifados.current.setDataSource(dataAlmoxarifados);
  };

  const onClickRemoveCodigo = async (selectedValue, datasource) => {
    const dataCodigos = datasource.filter(
      (codigo) =>
        codigo.cdProdutoCodigo !== selectedValue.cdProdutoCodigo ||
        codigo.cdPeca !== selectedValue.cdPeca ||
        (codigo.nrSeqPessoaFor ?? 0) !== (selectedValue.nrSeqPessoaFor ?? 0)
    );
    if (gridViewCodigos && gridViewCodigos.current)
      gridViewCodigos.current.setDataSource(dataCodigos);
  };

  const onClickRemoveManutPreventiva = async (selectedValue, datasource) => {
    const manutencoes = datasource.filter((manut) => manut !== selectedValue);
    const obj = { ...selectedValue, status: GridView.EnumStatus.Remover };
    manutencoes.push(obj);

    if (gridViewManutencaoPreventiva && gridViewManutencaoPreventiva.current)
      gridViewManutencaoPreventiva.current.setDataSource(manutencoes);
  };

  const onClickEditCodigo = async (selectedValue, datasource) => {
    const dataCodigos = datasource.filter(
      (codigo) =>
        codigo.cdProdutoCodigo !== selectedValue.cdProdutoCodigo &&
        codigo.cdPeca !== selectedValue.cdPeca &&
        codigo.nrSeqPessoaFor !== selectedValue.nrSeqPessoaFor
    );

    const dataProdutoCodigoItem = datasource.filter(
      (codigo) =>
        codigo.cdProdutoCodigo === selectedValue.cdProdutoCodigo &&
        codigo.cdPeca === selectedValue.cdPeca &&
        codigo.nrSeqPessoaFor === selectedValue.nrSeqPessoaFor
    );

    dataProdutoCodigoItem[0].status = GridView.EnumStatus.Alterar;
    setDataProdutoCodigo(dataProdutoCodigoItem[0]);

    if (gridViewCodigos && gridViewCodigos.current)
      gridViewCodigos.current.setDataSource(dataCodigos);
  };

  const onOpenNf = (datasource) => {
    const transactionOpen = findTransaction(
      TransacationCodes.NotaFiscalEntrada
    );

    if (transactionOpen) {
      transactionOpen.registryKey = datasource.nrSeqNf;
      onOpenTransaction(transactionOpen, true);
    } else {
      setMessage({
        message: 'Não foi possível abrir a Nota Fiscal de Entrada.',
        theme: Theme.Danger,
      });
    }
  };

  const closeModalMoverProduto = () => {
    setModalMoverProduto({ show: false });
  };

  const columns = [
    {
      key: 'almoxarifadoLocalPai.nrSeqAlmoxarifadoLocal',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'almoxarifado.noAlmoxarifado',
      title: 'Almoxarifado',
      bgClassProperty: 'noColorPendencia',
    },
    {
      key: 'almoxarifadoLocalPai.noAlmoxarifadoLocal',
      title: 'Almoxarifado Local',
      bgClassProperty: 'noColorPendencia',
    },
    {
      key: 'qtdeMinimo',
      title: 'Quantidade Mínima',
      bgClassProperty: 'noColorPendencia',
    },
    {
      key: 'qtdeMaximo',
      title: 'Quantidade Máxima',
      bgClassProperty: 'noColorPendencia',
    },
    { key: 'flgAtivo', title: 'Ativo', bgClassProperty: 'noColorPendencia' },
    { key: 'saldo', title: 'Saldo', bgClassProperty: 'noColorPendencia' },
    {
      key: 'noStatusQuantidade',
      title: 'Status Quant Prod',
      bgClassProperty: 'noColorPendencia',
    },
    {
      key: 'nrSeqAlmoxarifadoLocalPai',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveAlmoxarifado(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqAlmoxarifadoLocalPai',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditAlmoxarifado(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsCodigos = [
    {
      key: 'cdProdutoCodigol',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'cdPeca', title: 'Código da Peça' },
    { key: 'cdProdutoCodigo', title: 'Código de Produto' },
    { key: 'noProdutoCodigo', title: 'Nome do produto para este código' },
    { key: 'fornecedor.noPessoa', title: 'Fornecedor' },

    {
      key: 'cdProdutoCodigo',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveCodigo(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
    {
      key: 'cdProdutoCodigo',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditCodigo(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsHistorico = [
    { key: 'nrSeqNf', title: 'nrSeqNf', visible: false },
    { key: 'noEmpresa', title: 'Empresa' },
    { key: 'nrForNf', title: 'Número' },
    { key: 'noFornecedor', title: 'Fornecedor' },
    { key: 'dtEmis', title: 'Emissão', format: GridView.DataTypes.Date },
    { key: 'dtReceb', title: 'Recebimento', format: GridView.DataTypes.Date },
    { key: 'qtdeItNf', title: 'Qtde' },
    { key: 'vlrUnit', title: 'Vlr Unit.', format: GridView.DataTypes.Decimal },
    {
      key: 'nrSeqNf',
      type: GridView.ColumnTypes.Button,
      onClick: (datasource) => onOpenNf(datasource),
      theme: Theme.Warning,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Importar',
      tooltipDirection: 'bottom',
      title: 'Abrir',
    },
  ];

  const columnsHistoricoMovimento = [
    {
      key: 'nrSeqEstoqueMovimento',
      title: 'nrSeqEstoqueMovimento',
      visible: false,
    },
    { key: 'pessoaEmp.noEmpresa', title: 'Empresa' },
    { key: 'pessoaFor.noPessoa', title: 'Fornecedor' },
    { key: 'manutencaoVeiculoProduto.veiculo.placa', title: 'Veiculo' },
    { key: 'dtMovimento', title: 'Movimento', format: GridView.DataTypes.Date },
    { key: 'quantidade', title: 'Qtde' },
    { key: 'vlrUnit', title: 'Vlr Unit.', format: GridView.DataTypes.Decimal },
  ];

  const columnsManutencaoPreventiva = [
    {
      key: 'nrSeqManutencaoPreventiva',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'cdManutencaoPreventiva',
      title: 'CdManutencaoPreventiva',
      visible: false,
    },
    { key: 'noManutencaoPreventiva', title: 'Manutencao Preventiva' },
    { key: 'nrSeqTipoIntervalo', title: 'NrSeqTipoIntervalo', visible: false },
    { key: 'valor', title: 'Valor', visible: false },
    {
      key: 'nrSeqManutencaoPreventiva',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveManutPreventiva(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onGetProdutoConfig = async () => {
    const value = await getProdutoConfig();

    return value;
  };

  const load = useCallback(async () => {
    setConfig(await onGetProdutoConfig());

    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const produto = await getProdutoAgrupador(registryKey);
      produto.historicoCompras = await getHistoricoCompras(
        produto.nrSeqProduto
      );

      if (produto.almoxarifados !== null) {
        if (produto.almoxarifados.length > 0)
          produto.estoqueMovimento = await getFindLatestOutGoingMovements({
            nrSeqProduto: produto.almoxarifados[0].nrSeqProduto,
            'estoqueTipo.noSinal': '-',
          });
      }

      if (gridViewHistorico && gridViewHistorico.current) {
        gridViewHistorico.current.setDataSource(produto.historicoCompras);
      }

      if (gridViewHistoricoMovimento && gridViewHistoricoMovimento.current) {
        gridViewHistoricoMovimento.current.setDataSource(
          produto.estoqueMovimento
        );
      }

      if (gridViewAlmoxarifados && gridViewAlmoxarifados.current) {
        gridViewAlmoxarifados.current.setDataSource(produto.almoxarifados);
      }

      if (gridViewCodigos && gridViewCodigos.current) {
        gridViewCodigos.current.setDataSource(produto.codigos);
      }

      if (
        gridViewManutencaoPreventiva &&
        gridViewManutencaoPreventiva.current
      ) {
        gridViewManutencaoPreventiva.current.setDataSource(
          produto.manutencoesPreventiva
        );
      }

      setData(produto);
      setDataAlmoxarifado({ flgAtivo: true, qtdeMaximo: '', qtdeMinimo: '' });
      setDataProdutoCodigo({});

      setLoading(false);
    } else {
      setData({ pesoBruto: 0, pesoLiq: 0, flgAtivo: true });

      if (gridViewHistorico && gridViewHistorico.current) {
        gridViewHistorico.current.setDataSource([]);
      }

      if (gridViewHistoricoMovimento && gridViewHistoricoMovimento.current) {
        gridViewHistoricoMovimento.current.setDataSource([]);
      }

      if (gridViewAlmoxarifados && gridViewAlmoxarifados.current) {
        gridViewAlmoxarifados.current.setDataSource([]);
      }

      if (gridViewCodigos && gridViewCodigos.current) {
        gridViewCodigos.current.setDataSource([]);
      }
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    data.almoxarifados = gridViewAlmoxarifados.current.getDataSource();
    data.codigos = gridViewCodigos.current.getDataSource();
    data.manutencoesPreventiva = gridViewManutencaoPreventiva.current.getDataSource();

    if (!isNaN(data.pesoBruto)) data.pesoBruto = 0;
    if (!isNaN(data.pesoLiq)) data.pesoLiq = 0;

    const { status, message: msg } = await saveProdutoAgrupador(data);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      if (gridViewAlmoxarifados && gridViewAlmoxarifados.current) {
        gridViewAlmoxarifados.current.setDataSource([]);
      }
      if (gridViewCodigos && gridViewCodigos.current) {
        gridViewCodigos.current.setDataSource([]);
      }
      setDataAlmoxarifado({
        qtdeMinimo: 0,
        qtdeMaximo: 0,
        flgAtivo: true,
      });
      setData({ pesoLiq: 0, pesoBruto: 0, flgAtivo: true });
      setDataProdutoCodigo({});
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onClickAddManutencaoPreventiva = () => {
    let manutencoes = gridViewManutencaoPreventiva.current.getDataSource();

    manutencoes = manutencoes.map((e) => new ManutencaoPreventiva(e));
    dataManutencaoPreventiva.manutencoespreventiva.status =
      GridView.EnumStatus.Inserir;
    manutencoes.push(dataManutencaoPreventiva.manutencoespreventiva);

    if (gridViewManutencaoPreventiva && gridViewManutencaoPreventiva.current)
      gridViewManutencaoPreventiva.current.setDataSource(manutencoes);

    setDataManutencaoPreventiva(new ManutencaoPreventiva({}));
  };

  const onClickAddAlmoxarifado = async () => {
    let dataAlmoxarifados = gridViewAlmoxarifados.current.getDataSource();
    if (!dataAlmoxarifados) dataAlmoxarifados = [];
    if (!dataAlmoxarifado.nrSeqAlmoxarifadoLocal)
      dataAlmoxarifado.status = GridView.EnumStatus.Inserir;

    if (!dataAlmoxarifado.nrSeqAlmoxarifadoLocalPai) {
      onSetMessage(1, 'Necessário informar o almoxarifado.');
    } else if (!dataAlmoxarifado.qtdeMinimo) {
      onSetMessage(1, 'Necessário informar a quantidade mínima.');
    } else if (!dataAlmoxarifado.qtdeMaximo) {
      onSetMessage(1, 'Necessário informar a quantidade máxima.');
    } else if (dataAlmoxarifado.qtdeMaximo < dataAlmoxarifado.qtdeMinimo) {
      onSetMessage(
        1,
        'Quantidade máxima não pode ser inferior a quantidade mínima.'
      );
    } else {
      if (dataAlmoxarifados) {
        const vinculo = dataAlmoxarifados.filter(
          (almoxarifado) =>
            almoxarifado.nrSeqAlmoxarifadoLocalPai ===
            dataAlmoxarifado.nrSeqAlmoxarifadoLocalPai
        );
        for (let i = 0; i < vinculo.length; i += 1) {
          if (vinculo[i].status === 'Inserir') {
            onSetMessage(1, 'Almoxarifado já vinculado neste produto.');
            return;
          }
        }
      }

      dataAlmoxarifados.push(dataAlmoxarifado);

      if (gridViewAlmoxarifados && gridViewAlmoxarifados.current)
        gridViewAlmoxarifados.current.setDataSource(dataAlmoxarifados);

      setDataAlmoxarifado({
        flgAtivo: true,
        qtdeMaximo: '',
        qtdeMinimo: '',
      });
    }
  };

  const onClickAddCodigo = async () => {
    const dataCodigos = gridViewCodigos.current.getDataSource();
    if (!dataProdutoCodigo.cdPeca || !dataProdutoCodigo.cdProdutoCodigo) {
      onSetMessage(
        1,
        'Necessário informar o código do produto e código da peça.'
      );
    } else {
      const codigos = dataCodigos.filter(
        (codigo) =>
          codigo.cdPeca === dataProdutoCodigo.cdPeca &&
          codigo.cdProdutoCodigo === dataProdutoCodigo.cdProdutoCodigo
      );

      if (codigos.length > 0) {
        onSetMessage(1, 'Este código já foi inserido.');
      } else {
        dataCodigos.push(dataProdutoCodigo);

        if (gridViewCodigos && gridViewCodigos.current)
          gridViewCodigos.current.setDataSource(dataCodigos);

        setDataProdutoCodigo({});
      }
    }
  };

  const onSearcManutencaoPreventiva = async (e) => {
    const {
      status,
      message: msg,
      data: manutencoes,
    } = await getManutencaoPreventivaAutoComplete({
      noManutencaoPreventiva: e,
    });
    if (msg) onSetMessage(status, msg);
    return manutencoes;
  };

  const onSearchProdutoLinha = async (e) => {
    const {
      status,
      message: msg,
      data: linhas,
    } = await getProdutoLinhaAutoComplete({
      noProdutoLinha: e,
    });
    if (msg) onSetMessage(status, msg);
    return linhas;
  };

  const onSearchProdutoMarca = async (e) => {
    const {
      status,
      message: msg,
      data: marcas,
    } = await getProdutoMarcaAutoComplete({
      noProdutoMarca: e,
    });
    if (msg) onSetMessage(status, msg);
    return marcas;
  };

  const onSearchProdutoGrupo = async (e) => {
    const {
      status,
      message: msg,
      data: grupos,
    } = await getProdutoGrupoAutoComplete({
      noProdutoGrupo: e,
    });
    if (msg) onSetMessage(status, msg);
    return grupos;
  };

  const onSearchProdutoSubGrupo = async (e) => {
    const {
      status,
      message: msg,
      data: subGrupos,
    } = await getProdutoSubGrupoAutoComplete({
      noProdutoSubGrupo: e,
      nrSeqProdutoGrupo: data.nrSeqProdutoGrupo,
    });
    if (msg) onSetMessage(status, msg);
    return subGrupos;
  };

  const onSearchAlmoxarifadoAutoComplete = async (e) => {
    const {
      status,
      message: msg,
      data: almoxarifados,
    } = await getAlmoxarifadoAutoComplete({
      noAlmoxarifado: e,
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifados;
  };

  const onSearchAlmoxarifadoLocalAutoComplete = async (e) => {
    const {
      status,
      message: msg,
      almoxarifados,
    } = await getAlmoxarifadoLocalAutoComplete({
      noAlmoxarifado: e,
      flgAtivo: true,
      flgNotItem: true,
      nrSeqAlmoxarifado: dataAlmoxarifado.nrSeqAlmoxarifado,
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifados;
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteProdutoAgrupador(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchFornecedorAutoComplete = async (e) => {
    const {
      status,
      message: msg,
      data: fornecedores,
    } = await getFornecedorAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return fornecedores;
  };

  const onSearchProdutoOrigem = async (e) => {
    const {
      status,
      message: msg,
      data: origens,
    } = await getProdutoOrigemAutoComplete({
      noProdutoOrigem: e,
    });
    if (msg) onSetMessage(status, msg);
    return origens;
  };

  const onSearchProdutoUnidadeMedida = async (e) => {
    const {
      status,
      message: msg,
      data: medidas,
    } = await getProdutoUnidadeMedidaAutoComplete({
      noProdutoUnidadeMedida: e,
    });
    if (msg) onSetMessage(status, msg);
    return medidas;
  };

  const onSearchGrupoIcms = async (e) => {
    const { status, message: msg, grupos } = await getGrupoIcmsAutoComplete({
      noGrupoIcms: e,
    });
    if (msg) onSetMessage(status, msg);
    return grupos;
  };

  const onSearchGrupoIpi = async (e) => {
    const { status, message: msg, grupos } = await getGrupoIPIAutoComplete({
      noGrupoIpi: e,
    });
    if (msg) onSetMessage(status, msg);
    return grupos;
  };

  const onSearchGrupoPis = async (e) => {
    const { status, message: msg, grupos } = await getGrupoPISAutoComplete({
      noGrupoPis: e,
    });
    if (msg) onSetMessage(status, msg);
    return grupos;
  };

  const onSearchGrupoIssqn = async (e) => {
    const { status, message: msg, grupos } = await getGrupoIssqnAutoComplete({
      noGrupoIssqn: e,
    });
    if (msg) onSetMessage(status, msg);
    return grupos;
  };

  const onSearchGrupoCofins = async (e) => {
    const { status, message: msg, grupos } = await getGrupoCofinsAutoComplete({
      noGrupoCofins: e,
    });
    if (msg) onSetMessage(status, msg);
    return grupos;
  };

  const onOpenPrintEtiquetas = async () => {
    const arrEtiquetas = [
      new ProdutoCodigo({
        nrSeqProduto: data.nrSeqProduto,
        noProdutoCodigo: data.noProduto,
        cdProdutoCodigo: data.cdProdutoCodigo,
        flgPrincipal: true,
        cdPeca: data.cdPeca,
      }),
    ];

    setDataProdutoEtiquetas({
      gerarEtiquetas: true,
      produtoCodigoEtiqueta: arrEtiquetas,
    });
  };
  const onMoverProduto = async () => {
    setLoading(true);

    if (Array.isArray(data.almoxarifados) && data.almoxarifados.length > 0) {
      nrseqproduto.current = data.nrSeqProduto;
      setModalMoverProduto({ show: true });
    }
    setLoading(false);
  };
  const onCancelar = async () => {
    setModalMoverProduto({ show: false });
    setLoading(true);

    setLoading(false);
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Produto'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onDelete={data?.nrSeqProduto > 0 ? onExcluir : null}
      onNew={() => {
        setData({ pesoBruto: 0, pesoLiq: 0, flgAtivo: true });
        gridViewAlmoxarifados.current.setDataSource([]);
        gridViewCodigos.current.setDataSource([]);
        setDataProdutoCodigo({});
        setDataAlmoxarifado({ flgAtivo: true });
      }}
      onSave={save}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Gerar Etiquetas'
          icon='print'
          onClick={onOpenPrintEtiquetas}
        />
        {data.nrSeqProduto > 0 && (
          <ToolbarButtons.Button
            text='Mover Produto'
            onClick={onMoverProduto}
          />
        )}
      </ToolbarButtons>
      {dataProdutoEtiquetas.gerarEtiquetas && (
        <div className='row mb-3'>
          <div className='col mb-12'>
            <PanelEtiquetasProdutos
              visible={dataProdutoEtiquetas.gerarEtiquetas}
              data={dataProdutoEtiquetas.produtoCodigoEtiqueta}
              onClose={() => {
                setDataProdutoEtiquetas({
                  ...dataProdutoEtiquetas,
                  gerarEtiquetas: false,
                });
              }}
            />
          </div>
        </div>
      )}

      <div className='row mb-3'>
        <div className='col mb-12'>
          <Panel>
            <Panel.Header
              icon={['fa', 'check']}
              title='Informações do produto'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col mb-3'>
                  <Textbox
                    maxLength={90}
                    required
                    label='Produto'
                    text={data.noProduto}
                    onChangedValue={(noProduto) =>
                      setData({ ...data, noProduto })
                    }
                  />
                </div>

                <div className='col mb-3'>
                  <Autocomplete
                    label='Grupo'
                    searchDataSource={onSearchProdutoGrupo}
                    selectedItem={data.produtoGrupo}
                    onSelectItem={(produtoGrupo) => {
                      setData({
                        ...data,
                        produtoGrupo,
                        nrSeqProdutoGrupo: produtoGrupo.nrSeqProdutoGrupo,
                      });
                    }}
                    dataSourceTextProperty='noProdutoGrupo'
                    findTransaction={findTransaction}
                    onOpenTransaction={onOpenTransaction}
                    transactionToOpen={TransacationCodes.ProdutoGrupo}
                    nrseqTransactionToOpen='nrSeqProdutoGrupo'
                  />
                </div>
                <div className='col mb-3'>
                  <Autocomplete
                    label='Sub Grupo'
                    searchDataSource={onSearchProdutoSubGrupo}
                    selectedItem={data.produtoSubGrupo}
                    onSelectItem={(produtoSubGrupo) => {
                      setData({
                        ...data,
                        produtoSubGrupo,
                        nrSeqProdutoSubGrupo:
                          produtoSubGrupo.nrSeqProdutoSubGrupo,
                      });
                    }}
                    dataSourceTextProperty='noProdutoSubGrupo'
                    findTransaction={findTransaction}
                    onOpenTransaction={onOpenTransaction}
                    transactionToOpen={TransacationCodes.ProdutoSubGrupo}
                    nrseqTransactionToOpen='nrSeqProdutoSubGrupo'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col mb-3'>
                  <Autocomplete
                    label='Unidade Medida'
                    searchDataSource={onSearchProdutoUnidadeMedida}
                    selectedItem={data.produtoUnidadeMedida}
                    onSelectItem={(produtoUnidadeMedida) => {
                      setData({
                        ...data,
                        produtoUnidadeMedida,
                        nrSeqProdutoUnidadeMedida:
                          produtoUnidadeMedida.nrSeqProdutoUnidadeMedida,
                      });
                    }}
                    dataSourceTextProperty='noProdutoUnidadeMedida'
                    findTransaction={findTransaction}
                    onOpenTransaction={onOpenTransaction}
                    transactionToOpen={TransacationCodes.ProdutoUnidadeMedida}
                    nrseqTransactionToOpen='nrSeqProdutoUnidadeMedida'
                  />
                </div>
                <div className='col mb-3'>
                  <Textbox
                    label='Peso Bruto (Kg)'
                    text={data.pesoBruto}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(pesoBruto) =>
                      setData({ ...data, pesoBruto })
                    }
                  />
                </div>

                <div className='col mb-3'>
                  <Textbox
                    label='Peso Líquido (Kg)'
                    text={data.pesoLiq}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(pesoLiq) => setData({ ...data, pesoLiq })}
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-4'>
                  <Autocomplete
                    label='Produto Linha'
                    searchDataSource={onSearchProdutoLinha}
                    selectedItem={data.produtoLinha}
                    onSelectItem={(produtoLinha) => {
                      setData({
                        ...data,
                        produtoLinha,
                        nrSeqProdutoLinha: produtoLinha.nrSeqProdutoLinha,
                      });
                    }}
                    dataSourceTextProperty='noProdutoLinha'
                    findTransaction={findTransaction}
                    onOpenTransaction={onOpenTransaction}
                    transactionToOpen={TransacationCodes.ProdutoLinha}
                    nrseqTransactionToOpen='nrSeqProdutoLinha'
                  />
                </div>
                <div className='col-4'>
                  <Autocomplete
                    label='Produto Origem'
                    searchDataSource={onSearchProdutoOrigem}
                    selectedItem={data.produtoOrigem}
                    onSelectItem={(produtoOrigem) => {
                      setData({
                        ...data,
                        produtoOrigem,
                        nrSeqProdutoOrigem: produtoOrigem.nrSeqProdutoOrigem,
                      });
                    }}
                    dataSourceTextProperty='noProdutoOrigem'
                    findTransaction={findTransaction}
                    onOpenTransaction={onOpenTransaction}
                    transactionToOpen={TransacationCodes.ProdutoOrigem}
                    nrseqTransactionToOpen='nrSeqProdutoOrigem'
                  />
                </div>

                <div className='col-4'>
                  <Autocomplete
                    label='Produto Marca'
                    searchDataSource={onSearchProdutoMarca}
                    selectedItem={data.produtoMarca}
                    onSelectItem={(produtoMarca) => {
                      setData({
                        ...data,
                        produtoMarca,
                        nrSeqProdutoMarca: produtoMarca.nrSeqProdutoMarca,
                      });
                    }}
                    dataSourceTextProperty='noProdutoMarca'
                    findTransaction={findTransaction}
                    onOpenTransaction={onOpenTransaction}
                    transactionToOpen={TransacationCodes.ProdutoMarca}
                    nrseqTransactionToOpen='nrSeqProdutoMarca'
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-2'>
                  <Textbox
                    maxLength={8}
                    label='NCM'
                    text={data.cdNcm}
                    onChangedValue={(cdNcm) => setData({ ...data, cdNcm })}
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    maxLength={7}
                    label='CEST'
                    text={data.cdCest}
                    onChangedValue={(cdCest) => setData({ ...data, cdCest })}
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Código da Peça'
                    text={data.cdPeca}
                    onChangedValue={(cdPeca) => setData({ ...data, cdPeca })}
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Código do Produto'
                    readOnly={!config.flgCdProdutoCodigo}
                    text={data.cdProdutoCodigo}
                    onChangedValue={(cdProdutoCodigo) =>
                      setData({ ...data, cdProdutoCodigo })
                    }
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    maxLength={20}
                    label='EAN'
                    text={data.noEan}
                    onChangedValue={(noEan) => setData({ ...data, noEan })}
                  />
                </div>

                <div className='col-1 mb-3'>
                  <Switch
                    formControl
                    label='Ativo'
                    checked={data.flgAtivo}
                    onChange={(flgAtivo) => {
                      setData({
                        ...data,
                        flgAtivo,
                      });
                    }}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-3'>
                  <Textbox
                    maxLength={30}
                    label='Código ANP'
                    text={data.noCodigoANP}
                    onChangedValue={(noCodigoANP) =>
                      setData({ ...data, noCodigoANP })
                    }
                  />
                </div>
                <div className='col-3'>
                  <Textbox
                    maxLength={50}
                    label='Descrição ANP'
                    text={data.noDescricaoANP}
                    onChangedValue={(noDescricaoANP) =>
                      setData({ ...data, noDescricaoANP })
                    }
                  />
                </div>
                <div className='col-3'>
                  <Textbox
                    maxLength={10}
                    label='CA (Certificado de Aprovação)'
                    text={data.cdCa}
                    onChangedValue={(cdCa) => setData({ ...data, cdCa })}
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    maxLength={20}
                    label='EAN'
                    text={data.noEan2}
                    onChangedValue={(noEan2) => setData({ ...data, noEan2 })}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col mb-12'>
          <Panel>
            <Panel.Header
              title='Locais de armazenamento'
              icon={['fa', 'briefcase']}
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-3'>
                  <Autocomplete
                    label='Almoxarifado'
                    searchDataSource={onSearchAlmoxarifadoAutoComplete}
                    selectedItem={dataAlmoxarifado.almoxarifado}
                    onSelectItem={async (almoxarifado) => {
                      const { nrSeqAlmoxarifado } = almoxarifado;
                      if (nrSeqAlmoxarifado) {
                        setDataAlmoxarifado({
                          ...dataAlmoxarifado,
                          almoxarifado,
                          nrSeqAlmoxarifado: almoxarifado.nrSeqAlmoxarifado,
                        });
                      } else {
                        setDataAlmoxarifado({
                          ...dataAlmoxarifado,
                          nrSeqAlmoxarifadoLocalPai: null,
                          almoxarifadoLocalPai: undefined,
                        });
                      }
                    }}
                    dataSourceTextProperty='noAlmoxarifado'
                  />
                </div>
                <div className='col-3'>
                  <Autocomplete
                    label='Almoxarifado Local'
                    searchDataSource={onSearchAlmoxarifadoLocalAutoComplete}
                    selectedItem={dataAlmoxarifado.almoxarifadoLocalPai}
                    onSelectItem={(almoxarifadoLocalPai) => {
                      setDataAlmoxarifado({
                        ...dataAlmoxarifado,
                        almoxarifadoLocalPai,
                        nrSeqAlmoxarifadoLocalPai:
                          almoxarifadoLocalPai.nrSeqAlmoxarifadoLocal,
                      });
                    }}
                    dataSourceTextProperty='noAlmoxarifadoLocal'
                  />
                </div>

                <div className='col mb-3'>
                  <Textbox
                    label='Qtde. Mínima'
                    text={dataAlmoxarifado.qtdeMinimo}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(qtdeMinimo) =>
                      setDataAlmoxarifado({
                        ...dataAlmoxarifado,
                        qtdeMinimo,
                      })
                    }
                  />
                </div>

                <div className='col mb-3'>
                  <Textbox
                    label='Qtde. Máxima'
                    text={dataAlmoxarifado.qtdeMaximo}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(qtdeMaximo) =>
                      setDataAlmoxarifado({
                        ...dataAlmoxarifado,
                        qtdeMaximo,
                      })
                    }
                  />
                </div>

                <div className='col-1 mb-3'>
                  <Switch
                    formControl
                    label='Ativo'
                    checked={dataAlmoxarifado.flgAtivo}
                    onChange={(flgAtivo) => {
                      setDataAlmoxarifado({
                        ...dataAlmoxarifado,
                        flgAtivo,
                      });
                    }}
                  />
                </div>

                <div className='col-2 mt-3'>
                  <Button
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Adicionar'
                    onClick={onClickAddAlmoxarifado}
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-12'>
                  <GridView
                    ref={gridViewAlmoxarifados}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns}
                    offlineData
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col mb-12'>
          <Panel>
            <Panel.Header
              icon={['fa', 'list']}
              title='Códigos adicionais utilizados para este produto'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-2 mb-3'>
                  <Textbox
                    label='Código da Peça'
                    text={dataProdutoCodigo.cdPeca}
                    onChangedValue={(cdPeca) =>
                      setDataProdutoCodigo({
                        ...dataProdutoCodigo,
                        cdPeca,
                      })
                    }
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    label='Código do Produto'
                    text={dataProdutoCodigo.cdProdutoCodigo}
                    onChangedValue={(cdProdutoCodigo) =>
                      setDataProdutoCodigo({
                        ...dataProdutoCodigo,
                        cdProdutoCodigo,
                      })
                    }
                  />
                </div>

                <div className='col-3 mb-3'>
                  <Textbox
                    label='Nome do produto para este código'
                    text={dataProdutoCodigo.noProdutoCodigo}
                    onChangedValue={(noProdutoCodigo) =>
                      setDataProdutoCodigo({
                        ...dataProdutoCodigo,
                        noProdutoCodigo,
                      })
                    }
                  />
                </div>

                <div className='col-4'>
                  <Autocomplete
                    label='Fornecedor'
                    searchDataSource={onSearchFornecedorAutoComplete}
                    selectedItem={dataProdutoCodigo.fornecedor}
                    onSelectItem={(fornecedor) => {
                      setDataProdutoCodigo({
                        ...dataProdutoCodigo,
                        fornecedor,
                        nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                      });
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
              </div>
              <div className='row mb-3' style={{ textAlign: 'center' }}>
                <div className='col mt-3'>
                  <Button
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Adicionar'
                    className='width80'
                    onClick={onClickAddCodigo}
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-12'>
                  <GridView
                    ref={gridViewCodigos}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsCodigos}
                    offlineData
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col mb-12'>
          <Panel>
            <Panel.Header
              icon={['fa', 'edit']}
              title='Informações fiscais'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col mb-3'>
                  <Autocomplete
                    label='PIS'
                    searchDataSource={onSearchGrupoPis}
                    selectedItem={data.grupoPIS}
                    onSelectItem={(grupoPIS) => {
                      setData({
                        ...data,
                        grupoPIS,
                        nrSeqGrupoPIS: grupoPIS.nrSeqGrupoPIS,
                      });
                    }}
                    dataSourceTextProperty='noGrupoPIS'
                  />
                </div>
                <div className='col mb-3'>
                  <Autocomplete
                    label='ICMS'
                    searchDataSource={onSearchGrupoIcms}
                    selectedItem={data.grupoIcms}
                    onSelectItem={(grupoIcms) => {
                      setData({
                        ...data,
                        grupoIcms,
                        nrSeqGrupoIcms: grupoIcms.nrSeqGrupoIcms,
                      });
                    }}
                    dataSourceTextProperty='noGrupoIcms'
                  />
                </div>
                <div className='col mb-3'>
                  <Autocomplete
                    label='IPI'
                    searchDataSource={onSearchGrupoIpi}
                    selectedItem={data.grupoIPI}
                    onSelectItem={(grupoIPI) => {
                      setData({
                        ...data,
                        grupoIPI,
                        nrSeqGrupoIPI: grupoIPI.nrSeqGrupoIPI,
                      });
                    }}
                    dataSourceTextProperty='noGrupoIPI'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col mb-3'>
                  <Autocomplete
                    label='ISSQN'
                    searchDataSource={onSearchGrupoIssqn}
                    selectedItem={data.grupoIssqn}
                    onSelectItem={(grupoIssqn) => {
                      setData({
                        ...data,
                        grupoIssqn,
                        nrSeqGrupoIssqn: grupoIssqn.nrSeqGrupoIssqn,
                      });
                    }}
                    dataSourceTextProperty='noGrupoIssqn'
                  />
                </div>

                <div className='col mb-3'>
                  <Autocomplete
                    label='Cofins'
                    searchDataSource={onSearchGrupoCofins}
                    selectedItem={data.grupoCofins}
                    onSelectItem={(grupoCofins) => {
                      setData({
                        ...data,
                        grupoCofins,
                        nrSeqGrupoCofins: grupoCofins.nrSeqGrupoCofins,
                      });
                    }}
                    dataSourceTextProperty='noGrupoCofins'
                  />
                </div>
                <div className='col mb-3'>
                  <Textbox
                    maxLength={20}
                    label='Código SpedFiscal'
                    text={data.noCodigoSpedFiscalEntrada}
                    onChangedValue={(noCodigoSpedFiscalEntrada) =>
                      setData({ ...data, noCodigoSpedFiscalEntrada })
                    }
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header
            title='Histórico de Compras'
            icon={['fa', 'briefcase']}
            theme={Theme.Light}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row'>
              <div className='col-12'>
                <GridView
                  ref={gridViewHistorico}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsHistorico}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header
            title='Histórico de Saídas'
            icon={['fa', 'briefcase']}
            theme={Theme.Light}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row'>
              <div className='col-12'>
                <GridView
                  ref={gridViewHistoricoMovimento}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsHistoricoMovimento}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Manutenção Preventiva'
            searchDataSource={onSearcManutencaoPreventiva}
            selectedItem={dataManutencaoPreventiva.manutencoespreventiva}
            onSelectItem={(manutencoespreventiva) => {
              setDataManutencaoPreventiva({
                ...dataManutencaoPreventiva,
                manutencoespreventiva,
                nrSeqmanutencaoPreventiva:
                  manutencoespreventiva.nrSeqmanutencaoPreventiva,
              });
            }}
            dataSourceTextProperty='noManutencaoPreventiva'
          />
        </div>
        <div className='col-2 mt-3'>
          <Button
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Adicionar'
            onClick={onClickAddManutencaoPreventiva}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header
            title='Manutenção Preventiva'
            theme={Theme.Light}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row'>
              <div className='col-12'>
                <GridView
                  ref={gridViewManutencaoPreventiva}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsManutencaoPreventiva}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <ModalMoverProduto
        show={modalMoverProduto.show}
        nrseqproduto={nrseqproduto.current}
        onCancelar={(item) => onCancelar(item)}
        onClose={closeModalMoverProduto}
      />
    </CSDManutPage>
  );
}
