import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  BootstrapSizes,
  JustifyContent,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Textbox,
  DatePicker,
  Panel,
  Autocomplete,
  GridView,
  Button,
  RadioButton,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getPedidoAparelho,
  savePedidoAparelho,
  deletePedidoAparelho,
} from 'core/services/TEL/pedidoAparelho';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import { getMarcaAparelhoAutoComplete } from 'core/services/TEL/marcaAparelhoCelular';
import { getModeloaparelhocelularList } from 'core/services/TEL/modeloAparelhoCelular';

const StatusPedido = [
  { text: 'Entregue', value: 'E' },
  { text: 'Pendente', value: 'P' },
];
export default function PedidoAparelhoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const gridView = useRef();
  const grid = useRef();
  const [data, setData] = useState({});
  const [dataPedidoAparelho, setDataPedidoAparelho] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [statusPedido, setStatus] = useState({ flgStatus: 'P' });

  const somaVlrTotalPedido = (listaPedidoAparelho) => {
    const valortotal = listaPedidoAparelho
      .filter((item) => item.status !== 'Remover')
      .reduce(
        (acumulador, valorAtual) =>
          acumulador + valorAtual.qtdeAparelho * valorAtual.vlrCusto,
        0
      );
    return valortotal;
  };

  const onClickEditPedidoModelo = async (selectedValue, datasource) => {
    const dataPedidos = datasource.filter((pedido) => pedido !== selectedValue);
    const dataPedido = datasource.filter((pedido) => pedido === selectedValue);

    dataPedido[0].status = GridView.EnumStatus.Alterar;
    setDataPedidoAparelho(dataPedido[0]);
    if (gridView && gridView.current)
      gridView.current.setDataSource(dataPedidos);
  };

  const onClickRemovePedidoModelo = async (e, datasource) => {
    const index = datasource.find((item) => item === e);
    index.status = GridView.EnumStatus.Remover;

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);

    setData({
      ...data,
      vlrTotalPedido: somaVlrTotalPedido(datasource),
    });
  };
  const columnsAparelho = [
    {
      key: 'nrSeqAparelho',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'marcaAparelhoCelular.noMarcaAparelho', title: 'Marca Aparelho' },
    { key: 'modeloAparelhoCelular.noModeloAparelho', title: 'Modelo Aparelho' },
    { key: 'nrImei', title: 'Imei' },
    { key: 'nrNotaFiscal', title: 'Nota Fiscal' },
    { key: 'observacao', title: 'Observação' },
  ];

  const columns = [
    { key: 'nrSeqPedidoAparelhoModelo', type: GridView.ColumnTypes.Checkbox },
    { key: 'marcaAparelhoCelular.noMarcaAparelho', title: 'Marca Aparelho' },
    { key: 'modeloAparelhoCelular.noModeloAparelho', title: 'Modelo Aparelho' },
    {
      key: 'nrSeqMarcaAparelho',
      title: 'NrSeqMarcaAparelho',
      visible: false,
    },
    {
      key: 'nrSeqPedidoAparelho',
      title: 'NrSeqPedidoAparelho',
      visible: false,
    },

    { key: 'vlrCusto', title: 'Valor Custo' },
    {
      key: 'nrSeqModeloAparelho',
      title: 'NrSeqModeloAparelho',
      visible: false,
    },
    { key: 'qtdeAparelho', title: 'Quantidade' },
    {
      key: 'nrSeqPedidoAparelhoModelo',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditPedidoModelo(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqPedidoAparelhoModelo',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemovePedidoModelo(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Excluir',
      tooltipDirection: 'bottom',
    },
  ];
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onNew = () => {
    setData({});
    setDataPedidoAparelho({});
    setStatus({ flgStatus: 'P' });
    gridView.current.setDataSource([]);
    grid.current.setDataSource([]);
  };
  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const retorno = await getPedidoAparelho(registryKey);

      setData(retorno);
      setStatus(retorno);
      if (gridView && gridView.current) {
        gridView.current.setDataSource(retorno.pedidoAparelhoModelo);
      }

      if (grid && grid.current) {
        grid.current.setDataSource(retorno.aparelhos);
      }
      setLoading(false);
    } else onNew();
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    try {
      setLoading(true);

      const pedidos = gridView.current.getDataSource() ?? [];
      const updateData = grid.current.getDataSource() ?? [];

      const obj = {
        ...statusPedido,
        ...data,
        updateData,
        pedidoAparelhoModelo: pedidos,
      };

      const { status, message: msg } = await savePedidoAparelho(obj);

      if (status === ResponseStatus.Success) {
        formSubmit.current.reset();
        onNew();
      }

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deletePedidoAparelho(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };
  // const onSearchCliente = async (e) => {
  //   if (e.length < 3) {
  //     onSetMessage(ResponseStatus.Error, 'Digite ao menos 3 caracteres');
  //     return [];
  //   }
  //   const { status, message: msg, clientes } = await getClienteAutoComplete({
  //     noPessoa: e,
  //   });
  //   if (msg) setMessage(status, msg);
  //   return clientes;
  // };
  const onSearchMarcaAparelho = async (e) => {
    const {
      status,
      message: msg,
      data: marcaAparelhos,
    } = await getMarcaAparelhoAutoComplete({ noMarcaAparelho: e });
    if (msg) onSetMessage(status, msg);
    return marcaAparelhos;
  };

  const onSearchModeloAparelho = async (e) => {
    if (!dataPedidoAparelho.nrSeqMarcaAparelho) {
      onSetMessage(ResponseStatus.Error, 'Marca deve ser selecionada');
    }
    const {
      status,
      message: msg,
      data: modeloAparelhos,
    } = await getModeloaparelhocelularList({
      noModeloAparelho: e,
      nrSeqMarcaAparelho:
        dataPedidoAparelho.nrSeqMarcaAparelho > 0
          ? dataPedidoAparelho.nrSeqMarcaAparelho
          : 0,
    });
    if (msg) onSetMessage(status, msg);
    return modeloAparelhos;
  };
  const onClickAddAparelho = async () => {
    const pedidos = gridView.current.getDataSource() ?? [];

    const PedidoAparelhoModeloStatus =
      dataPedidoAparelho.status === undefined ? 'Inserir' : 'Alterar';

    const obj = {
      ...dataPedidoAparelho,
      status: PedidoAparelhoModeloStatus,
    };
    pedidos.push(obj);

    if (gridView && gridView.current) {
      gridView.current.setDataSource(pedidos);
    }
    setData({
      ...data,
      vlrTotalPedido: somaVlrTotalPedido(pedidos),
    });
    setDataPedidoAparelho({});
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Pedido Aparelho'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      onDelete={data?.nrSeqPedidoAparelho > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <Panel>
        <Panel.Body>
          <div className='row mt-3'>
            <div className='col-4'>
              <Autocomplete
                label='Empresa'
                searchDataSource={onSearchEmpresa}
                selectedItem={data.empresa}
                onSelectItem={(empresa) => {
                  setData({
                    ...data,
                    empresa,
                    nrSeqEmpresa: empresa.nrSeqEmpresa,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            {/* <div className='col-4 mb-3'>
              <Autocomplete
                label='Cliente Responsável'
                searchDataSource={onSearchCliente}
                selectedItem={data.cliente}
                onSelectItem={(cliente) => {
                  setData({
                    ...data,
                    cliente,
                    nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div> */}
            <div className='col-2'>
              <DatePicker
                label='Data Pedido'
                text={data.dtPedido}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtPedido) => setData({ ...data, dtPedido })}
              />
            </div>
            <div className='col-2'>
              <DatePicker
                label='Data Entrega'
                text={data.dtEntrega}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtEntrega) => setData({ ...data, dtEntrega })}
              />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-4'>
              <Textbox
                maxLength={200}
                label='Titulo Pedido'
                text={data.noTituloPedido}
                onChangedValue={(noTituloPedido) =>
                  setData({ ...data, noTituloPedido })
                }
              />
            </div>
            <div className='col-4'>
              <Textbox
                maxLength={200}
                label='Descrição Pedido'
                text={data.noDescricaoPedido}
                onChangedValue={(noDescricaoPedido) =>
                  setData({ ...data, noDescricaoPedido })
                }
              />
            </div>
            <div className='col-2'>
              <Textbox
                maxLength={9}
                label='Número do Aditivo'
                text={data.nrAditivo}
                onChangedValue={(nrAditivo) => setData({ ...data, nrAditivo })}
              />
            </div>
            <div className='col-2'>
              <Textbox
                maxLength={9}
                label='Número Contrato'
                text={data.nrCtPrincipal}
                onChangedValue={(nrCtPrincipal) =>
                  setData({ ...data, nrCtPrincipal })
                }
              />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-3'>
              <Textbox
                readOnly
                label='Valor Total Pedido'
                text={data.vlrTotalPedido}
                mask={MaskTypes.Decimal}
                onChangedValue={(vlrTotalPedido) =>
                  setData({ ...data, vlrTotalPedido })
                }
              />
            </div>
            <div className='col-3'>
              <RadioButton
                outline
                label='Status'
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                buttons={StatusPedido}
                selectedButton={statusPedido.flgStatus}
                onChange={(flgStatus) =>
                  setStatus({
                    ...statusPedido,
                    flgStatus,
                  })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header
            title='Aparelhos do Pedido'
            theme={Theme.Light}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-3'>
                <Autocomplete
                  label='Marca Aparelho'
                  searchDataSource={onSearchMarcaAparelho}
                  selectedItem={dataPedidoAparelho.marcaAparelhoCelular}
                  onSelectItem={(marcaAparelhoCelular) => {
                    setDataPedidoAparelho({
                      ...dataPedidoAparelho,
                      marcaAparelhoCelular,
                      nrSeqMarcaAparelho:
                        marcaAparelhoCelular.nrSeqMarcaAparelho,
                    });
                  }}
                  dataSourceTextProperty='noMarcaAparelho'
                />
              </div>
              <div className='col-3'>
                <Autocomplete
                  label='Modelo Aparelho'
                  searchDataSource={onSearchModeloAparelho}
                  selectedItem={dataPedidoAparelho.modeloAparelhoCelular}
                  onSelectItem={(modeloAparelhoCelular) => {
                    setDataPedidoAparelho({
                      ...dataPedidoAparelho,
                      modeloAparelhoCelular,
                      nrSeqModeloAparelho:
                        modeloAparelhoCelular.nrSeqModeloAparelho,
                    });
                  }}
                  dataSourceTextProperty='noModeloAparelho'
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Quantidade'
                  text={dataPedidoAparelho.qtdeAparelho}
                  mask={MaskTypes.Integer}
                  onChangedValue={(qtdeAparelho) =>
                    setDataPedidoAparelho({
                      ...dataPedidoAparelho,
                      qtdeAparelho,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Valor Custo'
                  text={dataPedidoAparelho.vlrCusto}
                  mask={MaskTypes.Decimal}
                  onChangedValue={(vlrCusto) =>
                    setDataPedidoAparelho({ ...dataPedidoAparelho, vlrCusto })
                  }
                />
              </div>
              <div className='col-2 mt-3 h-2'>
                <Button
                  outline
                  icon='plus'
                  size={BootstrapSizes.Small}
                  theme={Theme.Success}
                  template={Button.Templates.Default}
                  onClick={onClickAddAparelho}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <GridView
                  ref={gridView}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
        <Panel>
          <Panel.Header
            title='Aparelhos Vinculados'
            theme={Theme.Light}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row'>
              <div className='col'>
                <GridView
                  ref={grid}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsAparelho}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    </CSDManutPage>
  );
}
