import React, { useState, useEffect, useRef } from 'react';
import {
  BootstrapSizes,
  MimeTypes,
  Theme,
  ResponseStatus,
  JustifyContent,
} from 'ui/Helpers/utils';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { getPostoCombustivelAutoCompleteWithIntegration } from 'core/services/FRO/postoCombustivel';
import {
  postUploadAbastecimentos,
  postImportAbastecimentos,
  uploadTicketLog,
  UploadAtualizar,
  BuscaEmail,
  postUploadAbastecimentosXml,
} from 'core/services/FRO/abastecimento';
import { getEmpresasUsuario } from 'core/services/SEG/empresa';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';
import { MaskTypes } from 'ui/Helpers/masks';

import {
  Button,
  FileUpload,
  Autocomplete,
  DatePicker,
  GridView,
  Textbox,
  CSDSelPage,
  Panel,
  RadioButton,
} from 'ui/components';
import { getTipoCombustivelList } from 'core/services/FRO/tipoCombustivel';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';

export default function Abastecimento({
  transaction,
  isActive,
  show,
  findTransaction,
  onOpenTransaction,
}) {
  const [importedFile, setImportedFile] = useState([]);
  const [message, onSetMessage] = useState();
  const [loading, setLoading] = useState();
  const [data, setData] = useState({ tipoBusca: 2, tipoTituloPagar: 1 });
  const [valorTotal, setValorTotal] = useState(0);
  const gridView = useRef();
  const gridView2 = useRef();
  const gridView3 = useRef();

  useEffect(() => {
    setImportedFile([]);
    onSetMessage(null);
    setLoading(false);
  }, [show]);

  const onClickRemoveAbastecimento = async (selectedValue, datasource) => {
    const dataSourceFiltered = datasource.filter(
      (elem) => elem !== selectedValue
    );

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(dataSourceFiltered);
  };

  const alteracaoKm = async (keyValue, value) => {
    const datasource = gridView2.current
      ? gridView2.current.getDataSource()
      : [];
    const itemParcela = datasource.find(
      (item) => item.nrSeqVendaDiaria === keyValue
    );
    itemParcela.nrOdometro = value;
    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].nrSeqVendaDiaria === itemParcela.nrSeqVendaDiaria) {
        datasource[i].nrOdometro = itemParcela.nrOdometro;
      }
      i += 1;
    }
    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(datasource);
  };
  const fPriceReceita = function (keyValue, value) {
    alteracaoKm(keyValue, value);
  };

  const columns = [
    { key: 'nrSeqVendaDiaria', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'noPosto',
      title: 'Posto',
      colorText: 'noColorPosto',
    },
    {
      key: 'dtAbastecimento',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'veiculo.placa',
      title: 'Placa',
      format: GridView.DataTypes.Text,
      colorText: 'noColorVeiculo',
    },
    {
      key: 'nrOdometro',
      title: 'Kilometragem',
      format: GridView.DataTypes.Decimal,
      colorText: 'noColorVeiculo',
    },
    {
      key: 'noMotorista',
      title: 'Motorista',
      format: GridView.DataTypes.Text,
      colorText: 'noColorMotorista',
    },
    {
      key: 'tipoCombustivel.noTipoCombustivel',
      title: 'Tipo Combustível',
      format: GridView.DataTypes.Text,
      colorText: 'noColorTipoCombustivel',
    },
    { key: 'nrDocumento', title: 'Documento', colorText: 'noColorNotaFiscal' },
    { key: 'qtdeLitro', title: 'Litros', format: GridView.DataTypes.Decimal },
    {
      key: 'vlrLitro',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotal',
      title: 'Total',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'noObs',
      title: 'Obs',
      visible: false,
    },
  ];
  const columns2 = [
    {
      key: 'nrSeqVendaDiaria',
      visible: false,
    },
    { key: 'nrSeqVendaDiaria', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'noPosto',
      title: 'Posto',
      colorText: 'noColorPosto',
    },
    {
      key: 'dtAbastecimento',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'veiculo.placa',
      title: 'Placa',
      format: GridView.DataTypes.Text,
      colorText: 'noColorVeiculo',
    },
    {
      key: 'nrOdometro',
      title: 'Kilometragem',
      colorText: 'noColorVeiculo',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fPriceReceita,
    },
    {
      key: 'noMotorista',
      title: 'Motorista',
      format: GridView.DataTypes.Text,
    },
    {
      key: 'tipoCombustivel.noTipoCombustivel',
      title: 'Tipo Combustível',
      format: GridView.DataTypes.Text,
      colorText: 'noColorTipoCombustivel',
    },
    {
      key: 'nrDocumento',
      title: 'Documento',
      colorText: 'noColorNotaFiscal',
    },
    { key: 'qtdeLitro', title: 'Litros', format: GridView.DataTypes.Decimal },

    {
      key: 'vlrLitro',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotal',
      title: 'Total',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'nrSeqAbastecimento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveAbastecimento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
    {
      key: 'noObs',
      title: 'Obs',
      visible: false,
    },
  ];
  const columns3 = [
    {
      key: 'emailNaoLidos',
      title: 'Emails',
    },
  ];

  const onSearchVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onSearchPostoCombustivel = async (e) => {
    const {
      status,
      message: msg,
      data: postos,
    } = await getPostoCombustivelAutoCompleteWithIntegration({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return postos;
  };

  const importacaocerta = [];
  const itenscomerro = [];
  let placas = [];
  let tabelacomerroatualizada = [];

  const BuscarPeloEmail = async () => {
    setLoading(true);
    const obj = { ...data };

    const retornoXml = await BuscaEmail(obj);
    if (retornoXml.data !== undefined) {
      if (retornoXml.data.abastecimentoS.length > 0) {
        for (let i = 0; i < retornoXml.data.abastecimentoS.length; ) {
          if (retornoXml.data.abastecimentoS[i].status === 'Importar') {
            importacaocerta.push(retornoXml.data.abastecimentoS[i]);
          } else itenscomerro.push(retornoXml.data.abastecimentoS[i]);

          i += 1;
        }
        if (gridView && gridView.current)
          gridView.current.setDataSource(itenscomerro);
        if (gridView2 && gridView2.current) {
          gridView2.current.setDataSource(importacaocerta);
        }
        if (gridView3 && gridView3.current) {
          gridView3.current.setDataSource(retornoXml.data.naoLidos);
        }

        let total = 0;
        importacaocerta.forEach((item) => {
          if (!item.noColorVeiculo && !item.noColorTipoCombustivel)
            total += item.vlrTotal;
        });
        setValorTotal(total);
      }
    } else {
      onSetMessage({
        message: retornoXml.message,
        theme: Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onAtualizarItens = async () => {
    setLoading(true);
    const nrSeqVendaDiariaS = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    if (nrSeqVendaDiariaS.length === 0) {
      onSetMessage(ResponseStatus.Error, 'Selecione um item para atualizar ');
      return;
    }

    const datasource = gridView.current.getDataSource();

    const tabelacerta = gridView2.current.getDataSource();

    importacaocerta.push(...tabelacerta);

    tabelacomerroatualizada = gridView.current.getDataSource();

    for (let i = 0; i < nrSeqVendaDiariaS.length; ) {
      placas = tabelacomerroatualizada.filter(
        (Placa) => Placa.nrSeqVendaDiaria !== nrSeqVendaDiariaS[i]
      );

      tabelacomerroatualizada = placas;
      i += 1;
    }

    if (gridView && gridView.current) gridView.current.setDataSource(placas);

    itenscomerro.push(...tabelacomerroatualizada);

    const datasourcefiltrado = datasource.filter((item) =>
      nrSeqVendaDiariaS.includes(item.nrSeqVendaDiaria, 0)
    );

    const obj = { ...data, abastecimentoS: datasourcefiltrado };

    const importacaoabastecimento = await UploadAtualizar(obj);

    if (importacaoabastecimento.data.abastecimentoS.length > 0) {
      for (
        let i = 0;
        i < importacaoabastecimento.data.abastecimentoS.length;

      ) {
        if (
          importacaoabastecimento.data.abastecimentoS[i].status === 'Importar'
        ) {
          importacaocerta.push(importacaoabastecimento.data.abastecimentoS[i]);
        } else
          itenscomerro.push(importacaoabastecimento.data.abastecimentoS[i]);

        i += 1;
      }
      if (gridView && gridView.current)
        gridView.current.setDataSource(itenscomerro);

      if (gridView2 && gridView2.current)
        gridView2.current.setDataSource(importacaocerta);
    }
    setLoading(false);
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({ noFormaPagamento: e });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const onSearchTipoCombustivel = async (e) => {
    const {
      status,
      message: msg,
      data: tiposCombustivel,
    } = await getTipoCombustivelList({ noTipoCombustivel: e });
    if (msg) onSetMessage(status, msg);
    return tiposCombustivel;
  };

  const uploadFile = async () => {
    setLoading(true);

    if (!importedFile)
      onSetMessage({
        message: 'Não há arquivos selecionados para importação.',
        theme: Theme.Danger,
      });
    else {
      const {
        status,
        message: msg,
        data: abastecimentos,
        pagination,
      } = await postUploadAbastecimentos(
        importedFile[0],
        data.nrSeqPessoaPos,
        data.nrSeqFormaPagamento,
        data.dtVencimento
      );

      onSetMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      if (status === ResponseStatus.Success) {
        for (let i = 0; i < abastecimentos.length; ) {
          if (abastecimentos[i].status === 'Importar') {
            importacaocerta.push(abastecimentos[i]);
          } else itenscomerro.push(abastecimentos[i]);

          i += 1;
        }

        if (gridView && gridView.current)
          gridView.current.setDataSource(itenscomerro, pagination);

        if (gridView2 && gridView2.current)
          gridView2.current.setDataSource(importacaocerta, pagination);
      }

      let total = 0;
      abastecimentos.forEach((item) => {
        if (!item.noColorVeiculo && !item.noColorTipoCombustivel)
          total += item.vlrTotal;
      });
      setValorTotal(total);
    }
    setLoading(false);
  };
  const uploadFileXml = async () => {
    setLoading(true);

    if (!importedFile)
      onSetMessage({
        message: 'Não há arquivos selecionados para importação.',
        theme: Theme.Danger,
      });
    else {
      const {
        status,
        message: msg,
        data: abastecimentos,
        pagination,
      } = await postUploadAbastecimentosXml(
        importedFile[0],
        data.nrSeqPessoaPos,
        data.nrSeqFormaPagamento,
        data.dtVencimento
      );

      onSetMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      if (status === ResponseStatus.Success) {
        for (let i = 0; i < abastecimentos.length; ) {
          if (abastecimentos[i].status === 'Importar') {
            importacaocerta.push(abastecimentos[i]);
          } else itenscomerro.push(abastecimentos[i]);

          i += 1;
        }

        if (gridView && gridView.current)
          gridView.current.setDataSource(itenscomerro, pagination);

        if (gridView2 && gridView2.current)
          gridView2.current.setDataSource(importacaocerta, pagination);
      }

      let total = 0;
      abastecimentos.forEach((item) => {
        if (!item.noColorVeiculo && !item.noColorTipoCombustivel)
          total += item.vlrTotal;
      });
      setValorTotal(total);
    }
    setLoading(false);
  };

  const postImport = async () => {
    setLoading(true);

    const abastecimentosImport = gridView2.current.getDataSource();

    if (abastecimentosImport.length > 0) {
      abastecimentosImport[0].nrSeqEmpresa = data.nrSeqEmpresa;

      if (data.nrSeqPessoaPos > 0) {
        abastecimentosImport[0].nrSeqPessoaFor = data.nrSeqPessoaPos;
      }

      if (data.nrDocumento === undefined) {
        abastecimentosImport[0].nrDocumentoTituloPagar = null;
      } else {
        abastecimentosImport[0].nrDocumentoTituloPagar = data.nrDocumento;
      }
      if (data.dtVencimento === undefined) {
        abastecimentosImport[0].dtVencimento = null;
      } else {
        abastecimentosImport[0].dtVencimento = data.dtVencimento;
      }
      abastecimentosImport[0].tipoTituloPagar = data.tipoTituloPagar;
    }

    const { status, message: msg } = await postImportAbastecimentos(
      abastecimentosImport
    );

    onSetMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const tipoLayout = [
    {
      text: 'Não Gera Financeiro',
      value: 1,
    },
    {
      text: 'Financeiro Individual',
      value: 2,
    },
    {
      text: 'Financeiro Massivo',
      value: 3,
    },
  ];
  const tipoBuscar = [
    {
      text: 'Busca Email',
      value: 1,
    },
    {
      text: 'Importar Excel',
      value: 2,
    },
    {
      text: 'Importar Ticket Log',
      value: 3,
    },
    {
      text: 'Importador Zip',
      value: 4,
    },
  ];

  const buscaTicketLog = async () => {
    setLoading(true);

    if (!importedFile)
      onSetMessage({
        message: 'Não há arquivos selecionados para importação.',
        theme: Theme.Danger,
      });
    else {
      const {
        status,
        message: msg,
        data: abastecimentos,
        pagination,
      } = await uploadTicketLog({
        file: importedFile[0],
        nrSeqPessoaPos: data.nrSeqPessoaPos,
        nrSeqFormaPagamento: data.nrSeqFormaPagamento,
        dtVencimento: data.dtVencimento,
      });

      if (msg) {
        onSetMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
      }

      const importacaoCorreta = [];
      const importacaoIncorreta = [];

      if (status === ResponseStatus.Success) {
        for (let i = 0; i < abastecimentos.length; i += 1) {
          if (abastecimentos[i].status === 'Importar') {
            importacaoCorreta.push(abastecimentos[i]);
          } else {
            importacaoIncorreta.push(abastecimentos[i]);
          }
        }

        if (gridView && gridView.current)
          gridView.current.setDataSource(importacaoIncorreta, pagination);

        if (gridView2 && gridView2.current)
          gridView2.current.setDataSource(importacaoCorreta, pagination);
      }

      let total = 0;

      abastecimentos.forEach((item) => {
        if (!item.noColorVeiculo && !item.noColorTipoCombustivel)
          total += item.vlrTotal;
      });

      setValorTotal(total);
    }

    setLoading(false);
  };

  const onSearchMotorista = async (e) => {
    const { status, message: msg, motoristas } = await getMotoristaAutoComplete(
      {
        noPessoa: e,
      }
    );

    if (msg) onSetMessage(status, msg);
    return motoristas;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Abastecimento'
      loading={loading}
      message={message}
      onMessagerClose={() => onSetMessage(null)}
      transaction={transaction}
    >
      <Panel>
        <Panel.Header
          icon={['fas', 'user']}
          title='Item para Importação'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-12 text-center'>
              <RadioButton
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={data.tipoBusca}
                buttons={tipoBuscar}
                onChange={(tipoBusca) => {
                  setData({
                    ...data,
                    tipoBusca,
                  });
                }}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <Panel>
        <Panel.Header
          icon={['fas', 'user']}
          title='Valores'
          theme={Theme.Primary}
        />
        <Panel.Body>
          {(data.tipoBusca === 1 ||
            data.tipoBusca === 2 ||
            data.tipoBusca === 3 ||
            data.tipoBusca === 4) && (
            <div className='row mb-3'>
              <div className='col-8'>
                <Autocomplete
                  label='Posto Combustível'
                  searchDataSource={onSearchPostoCombustivel}
                  selectedItem={data.postoCombustivel}
                  onSelectItem={(postoCombustivel) => {
                    setData({
                      ...data,
                      postoCombustivel,
                      nrSeqPessoaPos: postoCombustivel.nrSeqPessoaPos,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
            </div>
          )}
          <div className='row mb-3'>
            <div className='col-8'>
              <Autocomplete
                label='Empresa'
                searchDataSource={onSearchEmpresa}
                selectedItem={data.empresa}
                onSelectItem={(empresa) => {
                  setData({
                    ...data,
                    empresa,
                    nrSeqEmpresa: empresa.nrSeqEmpresa,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            {data.tipoTituloPagar === 3 && (
              <div className='col-3'>
                <Textbox
                  maxLength={20}
                  label='Documento'
                  text={data.nrDocumento}
                  onChangedValue={(nrDocumento) =>
                    setData({ ...data, nrDocumento })
                  }
                />
              </div>
            )}
          </div>
          <div className='row'>
            <div className='col-8 mb-3'>
              <Autocomplete
                label='Forma Pagamento'
                searchDataSource={onSearchFormaPagamento}
                selectedItem={data.formaPagamento}
                onSelectItem={(formaPagamento) => {
                  setData({
                    ...data,
                    formaPagamento,
                    nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                  });
                }}
                dataSourceTextProperty='noFormaPagamento'
              />
            </div>

            {(data.tipoBusca === 2 || data.tipoBusca === 3) && (
              <div className='col-4'>
                <DatePicker
                  label='Vencimento'
                  text={data.dtVencimento}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtVencimento) =>
                    setData({ ...data, dtVencimento })
                  }
                />
              </div>
            )}
            {data.tipoBusca === 1 && data.tipoTituloPagar === 3 && (
              <div className='col-4'>
                <DatePicker
                  label='Vencimento'
                  text={data.dtVencimento}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtVencimento) =>
                    setData({ ...data, dtVencimento })
                  }
                />
              </div>
            )}
            {data.tipoBusca === 4 && data.tipoTituloPagar === 3 && (
              <div className='col-4'>
                <DatePicker
                  label='Vencimento'
                  text={data.dtVencimento}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtVencimento) =>
                    setData({ ...data, dtVencimento })
                  }
                />
              </div>
            )}
          </div>
          {(data.tipoBusca === 2 ||
            data.tipoBusca === 3 ||
            data.tipoBusca === 4) && (
            <div className='row'>
              <div className='col'>
                <FileUpload
                  files={importedFile}
                  onChange={(files) => setImportedFile(files)}
                  allowedMimeTypes={[MimeTypes.Types.Excel]}
                />
              </div>
            </div>
          )}
        </Panel.Body>
      </Panel>
      <div className='row mt-5  mb-4'>
        {data.tipoBusca === 2 && (
          <div className='col-3'>
            <Button
              text='Carregar Arquivo'
              icon='folder-open'
              theme={Theme.Success}
              onClick={uploadFile}
            />
          </div>
        )}
        {data.tipoBusca === 1 && (
          <div className='col-3'>
            <Button
              text='Carregar Email'
              icon='folder-open'
              theme={Theme.Success}
              onClick={BuscarPeloEmail}
            />
          </div>
        )}
        {data.tipoBusca === 3 && (
          <div className='col-3'>
            <Button
              text='Carregar Ticket Log'
              icon='folder-open'
              theme={Theme.Success}
              onClick={buscaTicketLog}
            />
          </div>
        )}
        {data.tipoBusca === 4 && (
          <div className='col-3'>
            <Button
              text='Carregar Arquivo Zip'
              icon='folder-open'
              theme={Theme.Success}
              onClick={uploadFileXml}
            />
          </div>
        )}
        <div className='col-6'>
          <RadioButton
            id='rdbAbastecimentoFinanceiro'
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={data.tipoTituloPagar}
            buttons={tipoLayout}
            onChange={(tipoTituloPagar) => {
              setData({
                ...data,
                tipoTituloPagar,
              });
            }}
          />
        </div>
        <div className='col-3'>
          <Button
            text='Importar Abastecimentos'
            icon='list'
            theme={Theme.Secondary}
            onClick={postImport}
            style={{ marginLeft: '5px' }}
          />
        </div>
      </div>

      <Panel>
        <Panel.Header
          title='Itens que não serão importados '
          theme={Theme.Light}
          align={JustifyContent.Start}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col'>
              <GridView
                ref={gridView}
                canControlVisibility
                dataColumns={columns}
                className='table-striped table-hover table-bordered table-sm'
                showSelectSizes={false}
                sortOffline
                transaction={transaction}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <Panel>
        <Panel.Header
          title='Atualização dos itens'
          theme={Theme.Light}
          align={JustifyContent.Start}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col-3 mb-3'>
              <Autocomplete
                label='Tipo Combustivel'
                searchDataSource={onSearchTipoCombustivel}
                findTransaction={findTransaction}
                onOpenTransaction={onOpenTransaction}
                transactionToOpen={TransacationCodes.TipoCombustivel}
                nrseqTransactionToOpen='nrSeqTipoCombustivel'
                selectedItem={data.tipoCombustivel}
                onSelectItem={(tipoCombustivel) => {
                  setData({
                    ...data,
                    tipoCombustivel,
                    nrSeqTipoCombustivel: tipoCombustivel.nrSeqTipoCombustivel,
                  });
                }}
                dataSourceTextProperty='noTipoCombustivel'
              />
            </div>
            <div className='col-3 mb-3'>
              <Autocomplete
                label='Placa'
                searchDataSource={onSearchVeiculo}
                findTransaction={findTransaction}
                onOpenTransaction={onOpenTransaction}
                transactionToOpen={TransacationCodes.Veiculo}
                nrseqTransactionToOpen='nrSeqVeiculo'
                selectedItem={data.veiculo}
                onSelectItem={(veiculo) => {
                  setData({
                    ...data,
                    veiculo,
                    nrSeqVeiculo: veiculo.nrSeqVeiculo,
                  });
                }}
                dataSourceTextProperty='placa'
              />
            </div>
            <div className='col-4 mb-3'>
              <Autocomplete
                label='Motorista'
                searchDataSource={onSearchMotorista}
                selectedItem={data.motorista}
                onSelectItem={(motorista) => {
                  setData({
                    ...data,
                    motorista,
                    nrSeqPessoaMot: motorista.nrSeqPessoaMot,
                    noMotorista: motorista.noPessoa,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-2 mt-3'>
              <Button
                theme={Theme.Primary}
                template={Button.Templates.Button}
                text='Corrigir Itens'
                onClick={onAtualizarItens}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Header
          title='Itens prontos para serem importados '
          theme={Theme.Light}
          align={JustifyContent.Start}
        />
        <Panel.Body>
          <div className='col'>
            <GridView
              ref={gridView2}
              canControlVisibility
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns2}
              transaction={transaction}
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </Panel.Body>
      </Panel>

      <span className='form-label text-right ' style={{ marginRight: '60%' }}>
        <b>Valor Total: {valorTotal.toLocaleString('pt-BR')}</b>
      </span>
      {data.tipoBusca === 1 && (
        <Panel>
          <Panel.Header
            title='Emails não lidos '
            theme={Theme.Light}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='col'>
              <GridView
                ref={gridView3}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columns3}
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </Panel.Body>
        </Panel>
      )}
    </CSDSelPage>
  );
}
