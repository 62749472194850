import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Theme } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import { Textbox, Switch, RadioButton } from 'ui/components';

const FiltrarPor = [
  { text: 'Contrato/Fatura', value: 'S' },
  { text: 'Contrato Agrupado', value: 'N' },
];

const OrdenarPor = [
  { text: 'Nome', value: 'S' },
  { text: 'Numero', value: 'N' },
];

const GerarPor = [
  { text: 'Sem Filtro', value: 'SemFiltro' },
  { text: 'Plano', value: 'Plano' },
  { text: 'Centro de Custo', value: 'CentroCusto' },
];

const PanelRelSinteticoFinanceiro = forwardRef(({ props }, param) => {
  const [parametros, setParametros] = useState({});

  useImperativeHandle(param, () => ({
    param: parametros,
  }));

  useEffect(() => {
    setParametros({
      ...parametros,
      flg1sintfinan: props.flg1sintfinan,
      flg1contfat: props.flg1contfat,
      flg1gerporplano: props.flg1gerporplano,
      flg1gerporcentrodecusto: props.flg1gerporcentrodecusto,
      flg1ordnomecliente: props.flg1ordnomecliente,
      flg1gercominfbanc: props.flg1gercominfbanc,
      flg1gercommatr: props.flg1gercommatr,
      flg1gercomemail: props.flg1gercomemail,
      flg1gercomcod: props.flg1gercomemail,
      flg1gercomcentrocusto: props.flg1gercomcentrocusto,
      flgvlr1recalculomaior: props.flgvlr1recalculomaior,
      vlr1recalculomaior: props.vlr1recalculomaior,
      flg1GerarPor: props.flg1GerarPor,
    });
  }, [props]);

  return (
    <div>
      <div className='row mb-3'>
        <div className='col-2'>
          <div className='row'>
            <div className='col-auto'>
              <Switch
                formControl
                label='Gerar'
                checked={parametros.flg1sintfinan}
                onChange={(e) =>
                  setParametros({ ...parametros, flg1sintfinan: e })
                }
              />
            </div>
          </div>
        </div>
        <div className='col-10'>
          <div className='row'>
            <div className='row'>
              <div className='col-auto'>
                <RadioButton
                  outline
                  label='Gerar por:'
                  theme={Theme.Primary}
                  buttons={GerarPor}
                  selectedButton={parametros.flg1GerarPor}
                  onChange={(flg1GerarPor) =>
                    setParametros({
                      ...parametros,
                      flg1GerarPor,
                    })
                  }
                />
              </div>
              <div className='col-auto'>
                <RadioButton
                  outline
                  label='Filtrado por:'
                  theme={Theme.Primary}
                  buttons={FiltrarPor}
                  selectedButton={parametros.flg1contfat}
                  onChange={(flg1contfat) =>
                    setParametros({
                      ...parametros,
                      flg1contfat,
                    })
                  }
                />
              </div>
              <div className='col-auto'>
                <RadioButton
                  outline
                  label='Ordenado por:'
                  theme={Theme.Primary}
                  buttons={OrdenarPor}
                  selectedButton={parametros.flg1ordnomecliente}
                  onChange={(flg1ordnomecliente) =>
                    setParametros({
                      ...parametros,
                      flg1ordnomecliente,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-6'>
          <div className='row'>
            <div className='col-auto'>
              <Switch
                formControl
                label='Gerar Inf. Bancárias'
                checked={parametros.flg1gercominfbanc}
                onChange={(e) =>
                  setParametros({ ...parametros, flg1gercominfbanc: e })
                }
              />
            </div>
            <div className='col-auto'>
              <Switch
                formControl
                label='Matrícula'
                checked={parametros.flg1gercommatr}
                onChange={(e) =>
                  setParametros({ ...parametros, flg1gercommatr: e })
                }
              />
            </div>
            <div className='col-auto'>
              <Switch
                formControl
                label='Código'
                checked={parametros.flg1gercomcod}
                onChange={(e) =>
                  setParametros({ ...parametros, flg1gercomcod: e })
                }
              />
            </div>
            <div className='col-auto'>
              <Switch
                formControl
                label='E-mail'
                checked={parametros.flg1gercomemail}
                onChange={(e) =>
                  setParametros({ ...parametros, flg1gercomemail: e })
                }
              />
            </div>
            <div className='col-auto'>
              <Switch
                formControl
                label='Centro Custo'
                checked={parametros.flg1gercomcentrocusto}
                onChange={(e) =>
                  setParametros({ ...parametros, flg1gercomcentrocusto: e })
                }
              />
            </div>
          </div>
        </div>

        <div className='col-6'>
          <div className='row mb-1'>
            <div className='col-auto'>
              <span>Valor Recalculado maior que:</span>
            </div>
          </div>
          <div className='row'>
            <div className='col-2'>
              <Switch
                formControl
                checked={parametros.flgvlr1recalculomaior}
                onChange={(e) =>
                  setParametros({
                    ...parametros,
                    flgvlr1recalculomaior: e,
                  })
                }
              />
            </div>
            <div className='col-4'>
              {parametros.flgvlr1recalculomaior ? (
                <Textbox
                  mask={MaskTypes.Decimal}
                  text={parametros.vlr1recalculomaior}
                  onChangedValue={(vlr1recalculomaior) =>
                    setParametros({
                      ...parametros,
                      vlr1recalculomaior,
                    })
                  }
                />
              ) : (
                <Textbox
                  readOnly
                  mask={MaskTypes.Decimal}
                  text={parametros.vlr1recalculomaior}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default PanelRelSinteticoFinanceiro;
