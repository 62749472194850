import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Switch, RadioButton } from 'ui/components';
import { Theme } from 'ui/Helpers/utils';

export const FiltrarPor = [
  { text: 'Contrato/Fatura', value: 'S' },
  { text: 'Contrato Agrupado', value: 'N' },
];

const PanelRelFinanceiroSubsidio = forwardRef(({ props }, param) => {
  const [parametros, setParametros] = useState({});

  useImperativeHandle(param, () => ({
    param: parametros,
  }));

  useEffect(() => {
    setParametros({
      ...parametros,
      flg5finansubsidio: props.flg5finansubsidio,
      flg5gerporcentrodecusto: props.flg5gerporcentrodecusto,
      flg5contfat: props.flg5contfat,
    });
  }, [props]);

  return (
    <div>
      <div className='row mb-3'>
        <div className='col-2'>
          <Switch
            formControl
            label='Gerar'
            checked={parametros.flg5finansubsidio}
            onChange={(e) =>
              setParametros({ ...parametros, flg5finansubsidio: e })
            }
          />
        </div>
        <div className='col-4'>
          <Switch
            formControl
            label='Por Centro de Custo'
            checked={parametros.flg5gerporcentrodecusto}
            onChange={(e) =>
              setParametros({ ...parametros, flg5gerporcentrodecusto: e })
            }
          />
        </div>
        <div className='col-6'>
          <RadioButton
            outline
            label='Filtrar por:'
            theme={Theme.Primary}
            buttons={FiltrarPor}
            selectedButton={parametros.flg5contfat}
            onChange={(flg5contfat) =>
              setParametros({
                ...parametros,
                flg5contfat,
              })
            }
          />
        </div>
      </div>
    </div>
  );
});
export default PanelRelFinanceiroSubsidio;
