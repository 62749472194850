export default class TributacaoIcms {
  nrSeqTributacaoIcms: number;

  cdTributacaoIcms: string;

  noTributacaoIcms: string;

  nrSeqGrupoEmpresa: number;

  flgAtivo: boolean;

  constructor(jsonObject: TributacaoIcms) {
    this.nrSeqTributacaoIcms = jsonObject.nrSeqTributacaoIcms;
    this.cdTributacaoIcms = jsonObject.cdTributacaoIcms;
    this.noTributacaoIcms = jsonObject.noTributacaoIcms;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgAtivo = jsonObject.flgAtivo;
  }

  toJson = () => JSON.stringify(this);
}
