import PocTipoOrcamento from 'core/models/POC/pocTipoOrcamento';

export default class PocGrupoProdOrcamento {
  constructor(jsonObject = {}) {
    this.nrSeqPocGrupoProdOrcamento = jsonObject.nrSeqPocGrupoProdOrcamento;
    this.cdPocGrupoProdOrcamento = jsonObject.cdPocGrupoProdOrcamento;
    this.nrOrdem = jsonObject.nrOrdem;
    this.flgManutencao = jsonObject.flgManutencao;
    this.nrSeqPocTipoOrcamento = jsonObject.nrSeqPocTipoOrcamento;
    this.tipoOrcamento = new PocTipoOrcamento(jsonObject.tipoOrcamento ?? {});
    this.nrSeqPocTipoOrcamentoS = jsonObject.nrSeqPocTipoOrcamentoS;
    this.pocTipoOrcamentoS = jsonObject.pocTipoOrcamentoS;
    this.flgDIP = jsonObject.flgDIP;
  }

  toJson = () => JSON.stringify(this);
}
