import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Panel,
  Textbox,
  Switch,
  Autocomplete,
  GridView,
  Button,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { BootstrapSizes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Theme, ResponseStatus, StatusGrid } from 'ui/Helpers/enums.ts';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getVendedorNivelAutoComplete } from 'core/services/VEN/vendedorNivel';
import { getVendedorRegiaoAutoComplete } from 'core/services/VEN/vendedorRegiao';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
import VendedorEquipe from 'core/models/FIN/vendedorEquipe';
import VendedorEstado from 'core/models/FIN/vendedor_Estado';

interface Props {
  vendedorData: any;
  setVendedorData: (object: any) => void;
  onSetMessage: (status: ResponseStatus, msg: string) => void;
  status?: StatusGrid;
}

const VendedorPanel = forwardRef(
  ({ vendedorData, setVendedorData, onSetMessage }: Props, ref) => {
    useEffect(() => {
      const objVendedor = { ...vendedorData };
      setVendedorData(objVendedor);
    }, [vendedorData.nrSeqPessoaVen]);

    const [vendendorEquipeData, setVendendorEquipeData] = useState(
      new VendedorEquipe({})
    );

    const [vendendorEstadoData, setVendendorEstadoData] = useState(
      new VendedorEstado({})
    );
    const gridViewEquipes = useRef();
    const gridViewEstado = useRef();

    const fillGridView = () => {
      if (
        gridViewEquipes &&
        gridViewEquipes.current &&
        vendedorData.vendedorEquipes &&
        vendedorData.vendedorEquipes.length > 0
      ) {
        const gridList = vendedorData.vendedorEquipes.map((item) => item);
        gridViewEquipes.current.setDataSource(gridList);
      }

      if (
        gridViewEstado &&
        gridViewEstado.current &&
        vendedorData.vendedorEstados &&
        vendedorData.vendedorEstados.length > 0
      ) {
        const gridListEstado = vendedorData.vendedorEstados.map((item) => item);
        gridViewEstado.current.setDataSource(gridListEstado);
      }
    };

    useImperativeHandle(ref, () => ({
      clear() {
        setVendendorEquipeData({});
        gridViewEquipes?.current?.setDataSource([]);

        setVendendorEstadoData({});
        gridViewEstado?.current?.setDataSource([]);
      },
      fillGrid() {
        fillGridView();
      },
      getDataGrid() {
        const listaEquipesGrid =
          gridViewEquipes?.current?.getDataSource() ?? [];

        const listaEstadoGrid = gridViewEstado?.current?.getDataSource() ?? [];

        return {
          listaEquipesGrid,
          listaEstadoGrid,
        };
      },
    }));

    const onSearchEmpresa = async (e: any) => {
      const { empresas } = await getEmpresaAutoComplete({
        noEmpresa: e,
      });

      return empresas;
    };

    const onSearchVendedorNivel = async (e: any) => {
      const { nivel } = await getVendedorNivelAutoComplete({
        noVendedorNivel: e,
      });

      return nivel;
    };

    const onSearchVendedorRegiao = async (e: any) => {
      const { regiao } = await getVendedorRegiaoAutoComplete({
        noVendedorRegiao: e,
      });

      return regiao;
    };

    const onSearchEstado = async (e: any) => {
      const { estados } = await getEstadoAutoComplete({
        noEstado: e,
      });

      return estados;
    };

    const onClickEditEstado = async (selectedValue, datasource) => {
      const dataOrdemEquipe = datasource.find((item) => item === selectedValue);
      dataOrdemEquipe.status = GridView.EnumStatus.Alterar;

      const dataProdutos = datasource.filter(
        (produto) => produto !== selectedValue
      );

      setVendendorEstadoData(dataOrdemEquipe);

      if (gridViewEstado && gridViewEstado.current)
        gridViewEstado.current.setDataSource(dataProdutos);
    };

    const onRemoveEstado = async (e, datasource) => {
      const index = datasource.findIndex((item) => item === e);
      datasource[index].status = GridView.EnumStatus.Remover;

      if (gridViewEstado && gridViewEstado.current)
        gridViewEstado.current.setDataSource(datasource);
    };

    const onAdicionarEstado = async () => {
      if (vendendorEstadoData.estado) {
        const obj = {
          ...vendendorEstadoData,
          status: vendendorEstadoData.status ?? StatusGrid.Inserir,
        };

        const list = gridViewEstado.current.getDataSource();
        list.push(obj);

        if (gridViewEstado && gridViewEstado.current)
          gridViewEstado.current.setDataSource(list);

        setVendedorData({
          ...vendedorData,
          vendedorEstados: list,
        });
        setVendendorEstadoData({});
      } else if (
        vendendorEstadoData.nrSeqEstado === undefined ||
        vendendorEstadoData.nrSeqEstado === null
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o estado');
      }
    };

    const onClickEditEquipe = async (selectedValue, datasource) => {
      const dataOrdemEquipe = datasource.find((item) => item === selectedValue);
      dataOrdemEquipe.status = GridView.EnumStatus.Alterar;

      const dataProdutos = datasource.filter(
        (produto) => produto !== selectedValue
      );

      setVendendorEquipeData(dataOrdemEquipe);

      if (gridViewEquipes && gridViewEquipes.current)
        gridViewEquipes.current.setDataSource(dataProdutos);
    };

    const onRemoveEquipe = async (e, datasource) => {
      const index = datasource.findIndex((item) => item === e);
      datasource[index].status = GridView.EnumStatus.Remover;

      if (gridViewEquipes && gridViewEquipes.current)
        gridViewEquipes.current.setDataSource(datasource);
    };

    const onAdicionarEquipes = async () => {
      if (
        (vendendorEquipeData.nome !== undefined ||
          vendendorEquipeData.nome !== null) &&
        (vendendorEquipeData.fone !== undefined ||
          vendendorEquipeData.fone !== null)
      ) {
        const obj = {
          ...vendendorEquipeData,
          status: vendendorEquipeData.status ?? StatusGrid.Inserir,
        };

        const list = gridViewEquipes.current.getDataSource();
        list.push(obj);

        if (gridViewEquipes && gridViewEquipes.current)
          gridViewEquipes.current.setDataSource(list);

        setVendedorData({
          ...vendedorData,
          vendedorEquipes: list,
        });
        setVendendorEquipeData({});
      } else if (
        vendendorEquipeData.nome === undefined ||
        vendendorEquipeData.nome === null
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o nome');
      } else if (
        vendendorEquipeData.fone === undefined ||
        vendendorEquipeData.fone === null
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o fone');
      }
    };

    const columns = [
      {
        key: 'nrSeqPessoaVen',
        title: 'nrSeqPessoaVen',
        visible: false,
      },
      {
        key: 'nome',
        title: 'Nome',
      },
      { key: 'fone', title: 'Fone' },
      { key: 'detalhe', title: 'Detalhe' },
      { key: 'email', title: 'Email' },
      {
        key: 'nrSeqPessoaVen',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onClickEditEquipe(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },
      // ADICIONANDO BOTÃO DE DELEÇÃO
      {
        key: 'nrSeqPessoaVen',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemoveEquipe(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    const columnsEstados = [
      {
        key: 'nrSeqPessoaVen',
        title: 'nrSeqPessoaVen',
        visible: false,
      },
      {
        key: 'estado.noEstado',
        title: 'Estado',
      },
      {
        key: 'nrSeqPessoaVen',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onClickEditEstado(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },
      // ADICIONANDO BOTÃO DE DELEÇÃO
      {
        key: 'nrSeqPessoaVen',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemoveEstado(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    return (
      <div className='col mt-3'>
        {/* @ts-expect-error */}
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header title='Vendedor' theme={Theme.Primary} />

          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-1'>
                <Switch
                  formControl
                  label='Ativo'
                  checked={vendedorData.flgAtivo}
                  onChange={(flgAtivo: boolean) =>
                    setVendedorData({
                      ...vendedorData,
                      flgAtivo,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Código'
                  text={vendedorData.cdVendedor}
                  maxLength={20}
                  onChangedValue={(cdVendedor: string) => {
                    setVendedorData({
                      ...vendedorData,
                      cdVendedor,
                    });
                  }}
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  id='txtEmpresa'
                  label='Empresa'
                  searchDataSource={onSearchEmpresa}
                  selectedItem={vendedorData.empresa}
                  onSelectItem={(empresa: any) =>
                    setVendedorData({
                      ...vendedorData,
                      empresa,
                      nrSeqEmpresa: empresa.nrSeqEmpresa,
                    })
                  }
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-3'>
                <Autocomplete
                  id='txtVendedorNivel'
                  label='Nível do Vendedor'
                  searchDataSource={onSearchVendedorNivel}
                  selectedItem={vendedorData.nivel}
                  onSelectItem={(nivel: any) =>
                    setVendedorData({
                      ...vendedorData,
                      nivel,
                      nrSeqVendedorNivel: nivel.nrSeqVendedorNivel,
                    })
                  }
                  dataSourceTextProperty='noVendedorNivel'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-3'>
                <Autocomplete
                  id='txtVendedorRegiao'
                  label='Região do Vendedor'
                  searchDataSource={onSearchVendedorRegiao}
                  selectedItem={vendedorData.vendedorRegiao}
                  onSelectItem={(vendedorRegiao: any) =>
                    setVendedorData({
                      ...vendedorData,
                      vendedorRegiao,
                      nrSeqVendedorRegiao: vendedorRegiao.nrSeqVendedorRegiao,
                    })
                  }
                  dataSourceTextProperty='noVendedorRegiao'
                />
              </div>
              <div className='col-1 mb-3'>
                <Switch
                  formControl
                  label='Comissão'
                  checked={vendedorData.flgComissao}
                  onChange={(flgComissao: boolean) =>
                    setVendedorData({
                      ...vendedorData,
                      flgComissao,
                    })
                  }
                />
              </div>
              {vendedorData.flgComissao && (
                <div className='col-2'>
                  <Textbox
                    label='Valor Comissão'
                    text={vendedorData.comissao}
                    mask={MaskTypes.DecimalCustom}
                    maxLength={10}
                    decimalPlaces={2}
                    onChangedValue={(comissao: string) => {
                      setVendedorData({
                        ...vendedorData,
                        comissao,
                      });
                    }}
                  />
                </div>
              )}
              <div className='col-2 mb-3'>
                <Switch
                  formControl
                  label='Comissão Tele Venda'
                  checked={vendedorData.flgComissaoTelevenda}
                  onChange={(flgComissaoTelevenda: boolean) =>
                    setVendedorData({
                      ...vendedorData,
                      flgComissaoTelevenda,
                    })
                  }
                />
              </div>
              {vendedorData.flgComissaoTelevenda && (
                <div className='col-2 mb-3'>
                  <Textbox
                    label='Valor Comissão Tele Venda'
                    text={vendedorData.comissaoTelevenda}
                    mask={MaskTypes.DecimalCustom}
                    maxLength={10}
                    decimalPlaces={2}
                    onChangedValue={(comissaoTelevenda: string) => {
                      setVendedorData({
                        ...vendedorData,
                        comissaoTelevenda,
                      });
                    }}
                  />
                </div>
              )}
            </div>
          </Panel.Body>
        </Panel>
        <div className='col mt-3'>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header title='Estado' theme={Theme.Primary} />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-5 mb-3'>
                  <Autocomplete
                    label='Estado'
                    searchDataSource={onSearchEstado}
                    selectedItem={vendendorEstadoData.estado}
                    onSelectItem={(estado: any) => {
                      setVendendorEstadoData({
                        ...vendendorEstadoData,
                        estado,
                        nrSeqEstado: estado.nrSeqEstado,
                      });
                    }}
                    dataSourceTextProperty='noEstado'
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Button
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Success}
                    template={Button.Templates.Quick}
                    onClick={onAdicionarEstado}
                    text='Adicionar'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <GridView
                    ref={gridViewEstado}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsEstados}
                    showPagination={false}
                    showSelectSizes={false}
                    offlineData
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        <div className='row mb-3'>
          <div className='col-12 mb-3'>
            <Panel>
              {/* @ts-expect-error */}
              <Panel.Header title='Equipes' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-1 mb-3'>
                    <Switch
                      formControl
                      label='Equipe'
                      checked={vendedorData.flgEquipe}
                      onChange={(flgEquipe: boolean) =>
                        setVendedorData({
                          ...vendedorData,
                          flgEquipe,
                        })
                      }
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  {vendedorData.flgEquipe && (
                    <div className='col-3 mb-3'>
                      <Textbox
                        label='Nome'
                        text={vendendorEquipeData.nome}
                        maxLength={60}
                        onChangedValue={(nome: string) => {
                          setVendendorEquipeData({
                            ...vendendorEquipeData,
                            nome,
                          });
                        }}
                      />
                    </div>
                  )}
                  {vendedorData.flgEquipe && (
                    <div className='col-2 mb-3'>
                      <Textbox
                        label='Fone'
                        text={vendendorEquipeData.fone}
                        maxLength={20}
                        mask={MaskTypes.CellPhone}
                        onChangedValue={(fone: string) => {
                          setVendendorEquipeData({
                            ...vendendorEquipeData,
                            fone,
                          });
                        }}
                      />
                    </div>
                  )}
                  {vendedorData.flgEquipe && (
                    <div className='col-2 mb-3'>
                      <Textbox
                        label='Email'
                        text={vendendorEquipeData.email}
                        maxLength={60}
                        onChangedValue={(email: string) => {
                          setVendendorEquipeData({
                            ...vendendorEquipeData,
                            email,
                          });
                        }}
                      />
                    </div>
                  )}
                  {vendedorData.flgEquipe && (
                    <div className='col-2 mb-3'>
                      <Textbox
                        label='Detalhe'
                        text={vendendorEquipeData.detalhe}
                        maxLength={60}
                        onChangedValue={(detalhe: string) => {
                          setVendendorEquipeData({
                            ...vendendorEquipeData,
                            detalhe,
                          });
                        }}
                      />
                    </div>
                  )}
                  {vendedorData.flgEquipe && (
                    <div className='col-2 mb-3'>
                      <Button
                        outline
                        size={BootstrapSizes.Small}
                        theme={Theme.Success}
                        template={Button.Templates.Quick}
                        onClick={onAdicionarEquipes}
                        text='Adicionar'
                      />
                    </div>
                  )}
                </div>
                <div className='row'>
                  {vendedorData.flgEquipe && (
                    <div className='col-12'>
                      <GridView
                        ref={gridViewEquipes}
                        className='table-striped table-hover table-bordered table-sm'
                        dataColumns={columns}
                        showPagination={false}
                        showSelectSizes={false}
                        offlineData
                      />
                    </div>
                  )}
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
);

export default VendedorPanel;
