import TipoIntervalo from '../FRO/tipoIntervalo';

export default class ManutencaoPreventiva {
  constructor(jsonObject = {}) {
    this.nrSeqManutencaoPreventiva = jsonObject.nrSeqManutencaoPreventiva;
    this.cdManutencaoPreventiva = jsonObject.cdManutencaoPreventiva;
    this.noManutencaoPreventiva = jsonObject.noManutencaoPreventiva;
    this.nrSeqTipoIntervalo = jsonObject.nrSeqTipoIntervalo;
    this.valor = jsonObject.valor;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.tipoIntervalo = new TipoIntervalo(jsonObject.tipoIntervalo ?? {});
    this.noTipoIntervalo = jsonObject.noTipoIntervalo;
  }

  toJson = () => JSON.stringify(this);
}
