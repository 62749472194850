import { RecalculaResumo } from 'core/services/FIN/acertoMotorista';
import { GetAcertoMotoristaTituloSemAcerto } from 'core/services/FIN/acertoMotoristaTitulo';
import React, { useRef, forwardRef, useState, useEffect } from 'react';
import { DatePicker, Button, GridView, Notification } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { ResponseStatus, Theme } from 'ui/Helpers/utils';
import ModalExtrato from './ModalExtrato';

const ExtratoFinanceiro = forwardRef(
  ({ data, registryKey, setData, acerto }) => {
    const [dataCadastroCliente, setDataCadastroCliente] = useState({});
    const [modalExtrato, setModalExtrato] = useState(false);
    const gridView = useRef();
    const gridView2 = useRef();
    const [message, onSetMessage] = useState(null);

    const setMessage = (status, msg) => {
      if (msg)
        onSetMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
    };

    const columns = [
      {
        key: 'nrSeqTituloPagarReceber',
        type: GridView.ColumnTypes.Checkbox,
      },
      {
        key: 'dtVencimento',
        title: 'Vencimento',
        format: GridView.DataTypes.Date,
      },
      {
        key: 'tipoDocumento',
        title: 'Tipo Documento',
      },
      {
        key: 'historico',
        title: 'Historico',
      },
      {
        key: 'vlrSaldoTit',
        title: 'Valor',
        format: GridView.DataTypes.Money,
        colorText: 'noColor',
      },
    ];

    const savePrice = async (keyValue, value) => {
      const datasource = gridView2.current
        ? gridView2.current.getDataSource()
        : [];

      const itemParcela = datasource.find(
        (item) => item.nrSeqTituloPagarReceber === keyValue
      );
      itemParcela.vlrPago = value;

      for (let i = 0; i < datasource.length; ) {
        if (
          datasource[i].nrSeqTituloPagarReceber ===
          itemParcela.nrSeqTituloPagarReceber
        ) {
          datasource[i].vlrPago = itemParcela.vlrPago;
          if (datasource[i].nrSeqAcertoMotoristaTitulo > 0) {
            datasource[i].status = GridView.EnumStatus.Alterar;
          } else {
            datasource[i].status = GridView.EnumStatus.Inserir;
          }
        }
        i += 1;
      }

      const nrSeqAcerto = data.nrSeqAcertoMotorista;

      const valorObj = { ...data, ...acerto, extratoFinanceiroS: datasource };

      const obj = await RecalculaResumo(valorObj);

      if (obj.nrSeqAcertoMotorista > 0) {
        setData({
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.viagens,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: obj.outrasReceitasComissao,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalReceitas: obj.totalReceitas,
          totalPeso: obj.totalPeso,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });
      } else {
        setData({
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          nrSeqAcertoMotorista: nrSeqAcerto,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.viagens,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: obj.outrasReceitasComissao,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalPeso: obj.totalPeso,
          totalReceitas: obj.totalReceitas,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });
      }

      gridView2?.current?.setDataSource(datasource);
    };

    const fPrice = function (keyValue, value) {
      savePrice(keyValue, value);
    };

    const columns2 = [
      {
        key: 'nrSeqTituloPagarReceber',
        type: GridView.ColumnTypes.Checkbox,
      },
      {
        key: 'dtVencimento',
        title: 'Vencimento',
        format: GridView.DataTypes.Date,
      },
      {
        key: 'tipoDocumento',
        title: 'Tipo Documento',
      },
      {
        key: 'historico',
        title: 'Historico',
      },
      {
        key: 'vlrSaldoTit',
        title: 'Valor',
        format: GridView.DataTypes.Money,
        colorText: 'noColor',
      },
      {
        key: 'vlrPago',
        title: 'Valor Acertar',
        type: GridView.ColumnTypes.Textbox,
        mask: MaskTypes.DecimalCustom,
        format: GridView.DataTypes.DecimalCustom,
        onBlur: fPrice,
        maxLength: 10,
      },
    ];

    const onSearchExtratoFinanceiro = async () => {
      if (!data.nrSeqPessoaMot > 0) {
        setMessage(
          ResponseStatus.Error,
          'Não é possivel buscar um extrato sem selecionar o motorista'
        );
        return;
      }

      if (data.dtAcerto === undefined || data.dtAcerto === null) {
        const obj = {
          ...dataCadastroCliente,
          nrSeqPessoaMot: data.nrSeqPessoaMot,
        };
        const extratoFinanceiro = await GetAcertoMotoristaTituloSemAcerto(obj);

        const tabela2 = gridView2.current.getDataSource();

        const listaFiltrada = extratoFinanceiro.filter(
          (item1) =>
            !tabela2.some(
              (item2) =>
                item2.nrSeqTituloPagarReceber === item1.nrSeqTituloPagarReceber
            )
        );

        if (gridView && gridView.current)
          gridView.current.setDataSource(listaFiltrada);
      }
    };

    const load = async () => {
      if (data !== undefined) {
        if (data.extratoFinanceiroS !== undefined) {
          if (gridView2 && gridView2.current)
            gridView2.current.setDataSource(data.extratoFinanceiroS);
        }
      }
      if (data.nrSeqPessoaMot > 0) {
        onSearchExtratoFinanceiro();
      }
    };

    useEffect(() => {
      (async function func() {
        load();
      })();
    }, [data.nrSeqPessoaCli, registryKey]);

    const onSearchRight = async () => {
      const importacaocerta = [];
      let placas = [];
      let tabelafinal = [];

      const nrSeqVendaDiariaS = gridView.current
        .getCheckBoxValuesAt(0)
        ?.map((row) => row[0].value);

      if (nrSeqVendaDiariaS.length === 0) {
        onSetMessage(ResponseStatus.Error, 'Selecione um item para atualizar ');
        return;
      }

      const datasource = gridView.current.getDataSource();

      const tabelacerta = gridView2.current.getDataSource();

      if (tabelacerta !== null) {
        if (tabelacerta.length > 0) {
          importacaocerta.push(...tabelacerta);
        }
      }

      tabelafinal = gridView.current.getDataSource();

      for (let i = 0; i < nrSeqVendaDiariaS.length; ) {
        placas = tabelafinal.filter(
          (Placa) => Placa.nrSeqTituloPagarReceber !== nrSeqVendaDiariaS[i]
        );

        tabelafinal = placas;
        i += 1;
      }

      const datasourcefiltrado = datasource.filter((item) =>
        nrSeqVendaDiariaS.includes(item.nrSeqTituloPagarReceber, 0)
      );

      if (datasourcefiltrado !== null) {
        for (let i = 0; i < datasourcefiltrado.length; ) {
          if (tabelacerta !== null) {
            if (tabelacerta.length > 0) {
              for (let j = 0; j < tabelacerta.length; ) {
                if (
                  datasourcefiltrado[i].nrSeqTituloPagarReceber ===
                    tabelacerta[j].nrSeqTituloPagarReceber &&
                  datasourcefiltrado[i].nrSeqAcertoMotorista > 0
                ) {
                  datasourcefiltrado.pop(datasourcefiltrado[i]);
                  tabelacerta[i].status = GridView.EnumStatus.Alterar;
                } else if (datasourcefiltrado[i].status === null) {
                  datasourcefiltrado[i].status = GridView.EnumStatus.Inserir;
                }

                j += 1;
              }
            } else {
              datasourcefiltrado[i].status = GridView.EnumStatus.Inserir;
            }
          } else {
            datasourcefiltrado[i].status = GridView.EnumStatus.Inserir;
          }
          i += 1;
        }
      }

      importacaocerta.push(...datasourcefiltrado);

      if (gridView && gridView.current)
        gridView.current.setDataSource(tabelafinal);

      if (gridView2 && gridView2.current)
        gridView2.current.setDataSource(importacaocerta);

      const nrSeqAcerto = data.nrSeqAcertoMotorista;

      const valorObj = {
        ...data,
        ...acerto,
        extratoFinanceiroS: importacaocerta,
      };

      const obj = await RecalculaResumo(valorObj);

      if (obj.nrSeqAcertoMotorista > 0) {
        setData({
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.viagens,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: obj.outrasReceitasComissao,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalReceitas: obj.totalReceitas,
          totalPeso: obj.totalPeso,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });
      } else {
        setData({
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          nrSeqAcertoMotorista: nrSeqAcerto,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.viagens,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: obj.outrasReceitasComissao,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalPeso: obj.totalPeso,
          totalReceitas: obj.totalReceitas,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });
      }
    };

    const onSearchLeft = async () => {
      const importacaocerta = [];
      let placas = [];
      let tabelafinal = [];

      const nrSeqVendaDiariaS = gridView2.current
        .getCheckBoxValuesAt(0)
        ?.map((row) => row[0].value);

      if (nrSeqVendaDiariaS.length === 0) {
        onSetMessage(ResponseStatus.Error, 'Selecione um item para atualizar ');
        return;
      }

      const datasource = gridView.current.getDataSource();

      const tabelacerta = gridView2.current.getDataSource();

      importacaocerta.push(...datasource);

      tabelafinal = gridView2.current.getDataSource();

      for (let i = 0; i < nrSeqVendaDiariaS.length; ) {
        placas = tabelafinal.filter(
          (Placa) => Placa.nrSeqTituloPagarReceber !== nrSeqVendaDiariaS[i]
        );

        tabelafinal = placas;
        i += 1;
      }

      const datasourcefiltrado = tabelacerta.filter((item) =>
        nrSeqVendaDiariaS.includes(item.nrSeqTituloPagarReceber, 0)
      );
      const datasourcefiltradoList2 = tabelacerta.filter((item) =>
        nrSeqVendaDiariaS.includes(item.nrSeqTituloPagarReceber, 0)
      );

      for (let i = 0; i < datasourcefiltrado.length; ) {
        if (datasourcefiltrado[i].nrSeqAcertoMotorista > 0) {
          datasourcefiltrado[i].status = GridView.EnumStatus.Alterar;
        }
        i += 1;
      }

      importacaocerta.push(...datasourcefiltrado);

      if (gridView && gridView.current)
        gridView.current.setDataSource(importacaocerta);

      for (let i = 0; i < datasourcefiltradoList2.length; ) {
        if (datasourcefiltradoList2[i].nrSeqAcertoMotorista > 0) {
          datasourcefiltradoList2[i].status = GridView.EnumStatus.Remover;
          tabelafinal.push(datasourcefiltradoList2[i]);
        }
        i += 1;
      }

      if (gridView2 && gridView2.current)
        gridView2.current.setDataSource(tabelafinal);

      const nrSeqAcerto = data.nrSeqAcertoMotorista;

      const valorObj = { ...data, ...acerto, extratoFinanceiroS: tabelafinal };

      const obj = await RecalculaResumo(valorObj);

      if (obj.nrSeqAcertoMotorista > 0) {
        setData({
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.viagens,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: obj.outrasReceitasComissao,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalReceitas: obj.totalReceitas,
          totalPeso: obj.totalPeso,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });
      } else {
        setData({
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          nrSeqAcertoMotorista: nrSeqAcerto,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.viagens,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: obj.outrasReceitasComissao,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalPeso: obj.totalPeso,
          totalReceitas: obj.totalReceitas,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });
      }
    };

    const onClickModalExtrato = async () => {
      if (data.nrSeqPessoaMot === null || data.nrSeqPessoaMot === undefined) {
        setMessage(
          ResponseStatus.Error,
          'Não é possivel gerar um extrato financeiro sem um motorista selecionado'
        );
        return;
      }

      if (
        data.nrSeqAcertoMotorista === null ||
        data.nrSeqAcertoMotorista === undefined
      ) {
        setMessage(
          ResponseStatus.Error,
          'Para salvar o extrato, por favor salve o acerto primeiro'
        );
        return;
      }

      setModalExtrato(true);
    };

    return (
      <div>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => onSetMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='display-8'>8 - Extrato Financeiro</div>
        </div>
        <div className='card'>
          <div className='card-body'>
            <div className='row mb-3'>
              <div className='row'>
                <div className='row mb-3'>
                  <div className='col-2 mb-3'>
                    <DatePicker
                      label='De'
                      text={dataCadastroCliente.dtInicial}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtInicial) =>
                        setDataCadastroCliente({
                          ...dataCadastroCliente,
                          dtInicial,
                        })
                      }
                    />
                  </div>
                  <div className='col-2 mb-3'>
                    <DatePicker
                      label='Ate'
                      text={dataCadastroCliente.dtFinal}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtFinal) =>
                        setDataCadastroCliente({
                          ...dataCadastroCliente,
                          dtFinal,
                        })
                      }
                    />
                  </div>
                  <div className='col-1 mt-3'>
                    <Button
                      outline
                      theme={Theme.Primary}
                      template={Button.Templates.Button}
                      icon='search'
                      onClick={onSearchExtratoFinanceiro}
                    />
                  </div>
                  <div className='col-2 mt-3'>
                    <Button
                      outline
                      theme={Theme.Primary}
                      template={Button.Templates.Button}
                      icon='plus'
                      text='Novo'
                      onClick={() => onClickModalExtrato()}
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-5'>
                  <GridView
                    ref={gridView}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
                <div className='col-1 mt-3 mb-3'>
                  <Button
                    outline
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    icon='plus'
                    text='Adicionar'
                    onClick={onSearchRight}
                  />
                  <Button
                    outline
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    icon='arrow-circle-left'
                    text='Remover'
                    onClick={onSearchLeft}
                  />
                </div>
                <div className='col-5'>
                  <GridView
                    ref={gridView2}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns2}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
              <ModalExtrato
                show={modalExtrato}
                dataExtrato={data}
                onClose={({ message: msg, theme }) => {
                  setModalExtrato(false);
                  setMessage({
                    message: msg,
                    theme,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default ExtratoFinanceiro;
