import DetalheLinha from 'core/models/TEL/GestaoConta/detalheLinha';
import GrupoContrato from 'core/models/TEL/GestaoConta/grupoContrato';
import GruposLinha from 'core/models/TEL/GestaoConta/gruposLinha';
import Chip from 'core/models/TEL/chip';
import api from '../../api';

export const getGruposContrato = async (filters) => {
  const response = await api.get(`/GestaoOperadoras/ListarGruposContrato`, {
    params: filters,
  });
  const { status, message, value, errors } = response.data ?? {};

  return {
    status,
    message,
    listaAgrupado:
      value?.listaAgrupado.map((json) => new GrupoContrato(json)) ?? [],
    listaContratosNaoRecuperados: value?.listaContratosNaoRecuperados,
    errors,
  };
};
export const getGruposLinha = async (filters) => {
  const response = await api.get(`/GestaoOperadoras/ListarGruposLinha`, {
    params: filters,
  });
  const { value } = response.data ?? {};

  return {
    listaGrupos: value?.map((json) => new GruposLinha(json)) ?? [],
  };
};
export const getDetalheLinha = async (filters) => {
  const response = await api.post(`/GestaoOperadoras/DetalharLinha`, filters);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    listaAgrupado:
      value?.listaAgrupado.map((json) => new DetalheLinha(json)) ?? [],
    listaLinhasNaoRecuperadas: value?.listaLinhasNaoRecuperadas,
    pagination: value?.pagination,
  };
};

export const getLinhasOperadora = async (filters) => {
  const response = await api.get(`/GestaoOperadoras/GetLinhasOperadora`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    linhas: value?.map((json) => new Chip(json)) ?? [],
  };
};

export const getLinhasGrupo = async (filters) => {
  const response = await api.get(`/TEL/Chip/SearchPorGrupoLinha`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    linhas: value?.map((json) => new Chip(json)) ?? [],
  };
};
export const getDetalheGrupoLinhas = async (filters) => {
  const response = await api.get(`/GestaoOperadoras/DetalharGrupoLinhas`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    detalheLinhas: value?.map((json) => new DetalheLinha(json)) ?? [],
  };
};
export const ativaBloquearDados = async (filters) => {
  const response = await api.post(
    `/GestaoOperadoras/AtivarBloquearDadosLinha`,
    filters
  );
  const { status, message } = response.data ?? {};

  return { status, message };
};
export const ativaBloquearVoz = async (filters) => {
  const response = await api.post(
    `/GestaoOperadoras/AtivarBloquearVozLinha`,
    filters
  );
  const { status, message } = response.data ?? {};

  return { status, message };
};
export const ativaBloquearSMS = async (filters) => {
  const response = await api.post(
    `/GestaoOperadoras/AtivarBloquearSMSLinha`,
    filters
  );
  const { status, message } = response.data ?? {};

  return { status, message };
};
export const moverLinhaGrupo = async (filters) => {
  const response = await api.post(
    `/GestaoOperadoras/MoverLinhasGrupo`,
    filters
  );
  const { status, message } = response.data ?? {};

  return { status, message };
};
export const getLinhasOperadoraCentroDeCusto = async (filters) => {
  const response = await api.post(
    `/GestaoOperadoras/SearchLinhasOperadoraCentroDeCusto`,
    filters
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    linhas: value?.map((json) => new Chip(json)) ?? [],
  };
};

export const postResetarCotaGrupo = async (data) => {
  const response = await api.post(`/GestaoOperadoras/ResetarCotaGrupo`, data);
  const { status, message, errors } = response.data ?? {};

  return {
    status,
    message,
    errors,
  };
};
