import React, { useState, useEffect } from 'react';

import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { Button, Loader, Modal, Notification } from 'ui/components';
import LinhasClienteLinhaDuplicado from './linhasClienteLinhaDuplicado';
import LinhasValoresNulos from './linhasValoresNulos';
import LinhasParadaComConsumo from './linhasParadaComConsumo';
import LinhasComAlteracaoPacoteDados from './linhasComAlteracaoPacoteDados';
import LinhasCobrandoDoisPacotesDeDados from './linhasCobrandoDoisPacotesDeDados';
import LinhasComDuplicidadeRegistroChamada from './linhasComDuplicidadeRegistroChamada';
import LinhasComPacoteDDDNacionalSemBonusClienteLinha from './linhasComPacoteDDDNacionalSemBonusClienteLinha';
import LinhasComDataAquisicaoSuperiorDataFinalPeriodo from './linhasComDataAquisicaoSuperiorDataFinalPeriodo';
import LinhasInconsistenciaDeValorPagoOperadora from './linhasInconsistenciaDeValorPagoOperadora';
import LinhasComDuplicidadeEmFatura from './linhasComDuplicidadeEmFatura';
import TipoChamadasNaoVinculadosNoPlano from './tipoChamadasNaoVinculadosNoPlano';
import LinhasVinculadaPlanoOutraOperadora from './linhasVinculadaPlanoOutraOperadora';
import ListaMensagens from './mensagens';

export default function ModalRelatorio({
  relRecalculoConta,
  mensagens,
  show,
  onClose,
}) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      setLoading(true);
      setLoading(false);
      console.log(mensagens[0]);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      title='Relatório Recálculo Conta'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <ListaMensagens param={relRecalculoConta?.mensagens} />
        {relRecalculoConta?.linhasVinculadaPlanoOutraOperadora?.length > 0 && (
          <LinhasVinculadaPlanoOutraOperadora
            param={relRecalculoConta?.linhasVinculadaPlanoOutraOperadora}
          />
        )}
        {relRecalculoConta?.linhasClienteLinhaDuplicado?.length > 0 && (
          <LinhasClienteLinhaDuplicado
            param={relRecalculoConta?.linhasClienteLinhaDuplicado}
          />
        )}
        {relRecalculoConta?.linhasValoresNulos?.length > 0 && (
          <LinhasValoresNulos param={relRecalculoConta?.linhasValoresNulos} />
        )}
        {relRecalculoConta?.linhasParadaComConsumo?.length > 0 && (
          <LinhasParadaComConsumo
            param={relRecalculoConta?.linhasParadaComConsumo}
          />
        )}
        {relRecalculoConta?.linhasComAlteracaoPacoteDados?.length > 0 && (
          <LinhasComAlteracaoPacoteDados
            param={relRecalculoConta?.linhasComAlteracaoPacoteDados}
          />
        )}
        {relRecalculoConta?.linhasCobrandoDoisPacotesDeDados?.length > 0 && (
          <LinhasCobrandoDoisPacotesDeDados
            param={relRecalculoConta?.linhasCobrandoDoisPacotesDeDados}
          />
        )}
        {relRecalculoConta?.linhasComDuplicidadeRegistroChamada?.length > 0 && (
          <LinhasComDuplicidadeRegistroChamada
            param={relRecalculoConta?.linhasComDuplicidadeRegistroChamada}
          />
        )}
        {relRecalculoConta?.linhasComPacoteDDDNacionalSemBonusClienteLinha
          ?.length > 0 && (
          <LinhasComPacoteDDDNacionalSemBonusClienteLinha
            param={
              relRecalculoConta?.linhasComPacoteDDDNacionalSemBonusClienteLinha
            }
          />
        )}
        {relRecalculoConta?.linhasComDataAquisicaoSuperiorDataFinalPeriodo
          ?.length > 0 && (
          <LinhasComDataAquisicaoSuperiorDataFinalPeriodo
            param={
              relRecalculoConta?.linhasComDataAquisicaoSuperiorDataFinalPeriodo
            }
          />
        )}
        {relRecalculoConta?.linhasInconsistenciaDeValorPagoOperadora?.length >
          0 && (
          <LinhasInconsistenciaDeValorPagoOperadora
            param={relRecalculoConta?.linhasInconsistenciaDeValorPagoOperadora}
          />
        )}
        {relRecalculoConta?.linhasComDuplicidadeEmFatura?.length > 0 && (
          <LinhasComDuplicidadeEmFatura
            param={relRecalculoConta?.linhasComDuplicidadeEmFatura}
          />
        )}
        {relRecalculoConta?.tipoChamadasNaoVinculadosNoPlano?.length > 0 && (
          <TipoChamadasNaoVinculadosNoPlano
            param={relRecalculoConta?.tipoChamadasNaoVinculadosNoPlano}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Sair'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
