import React, { useState, useRef } from 'react';
import { CSDSelPage, FileUpload, Button, GridView } from 'ui/components';
import {
  MimeTypes,
  BootstrapSizes,
  Theme,
  ResponseStatus,
} from 'ui/Helpers/utils';
import { onImportFiles } from 'core/services/COP/nf';

export default function MatrixDistance({ transaction, isActive }) {
  const [message, setMessage] = useState(null);
  const [importedFiles, setImportedFiles] = useState([]);
  const gridViewDistancias = useRef();

  const importFiles = async () => {
    const { data, status } = await onImportFiles(importedFiles);

    if (status === ResponseStatus.Success) {
      if (gridViewDistancias ?? gridViewDistancias.current)
        gridViewDistancias.current.setDataSource(data);
    }
  };

  const columnsDistancias = [
    { key: 'endereco', title: 'Origem -> Destino' },
    { key: 'distancia', title: 'Distância' },
  ];

  return (
    <CSDSelPage
      transaction={transaction}
      isActive={isActive}
      title='Matrix Distance API'
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <div className='row'>
        <div className='row mb-3'>
          <div className='col-6'>
            <FileUpload
              files={importedFiles}
              onChange={(files) => {
                setImportedFiles(files);
              }}
              allowedMimeTypes={[MimeTypes.Types.XML]}
              dropzone
            />
          </div>
          <div className='col mt-5'>
            <Button
              size={BootstrapSizes.Large}
              theme={Theme.Success}
              template={Button.Templates.Default}
              onClick={importFiles}
              text='Importar'
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              ref={gridViewDistancias}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnsDistancias}
              offlineData
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>
      </div>
    </CSDSelPage>
  );
}
