import React, {
  useState,
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import {
  Modal,
  Button,
  Loader,
  FileUpload,
  Notification,
  Panel,
} from 'ui/components';
import {
  Theme,
  BootstrapSizes,
  ResponseStatus,
  JustifyContent,
} from 'ui/Helpers/enums';
import { Message } from 'ui/Helpers/interfaces';
import { MimeTypes } from 'ui/Helpers/utils';

import { mudaCtesViagem } from 'core/services/FRO/viagem';

type Props = {
  props: any;
  show: boolean;
  nrSeqViagem: number;
  onClose: () => void;
  generateTravelSuccessful: (msg: string) => void;
};

const ModalMudaCtesViagem = forwardRef(
  (
    { props, show, nrSeqViagem, onClose, generateTravelSuccessful }: Props,
    param: ForwardedRef<any>
  ) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [importedFiles, setImportedFiles] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useImperativeHandle(param, () => ({
      param: importedFiles,
    }));

    useEffect(() => {
      if (show) {
        setImportedFiles([]);
      }
    }, [show, props]);

    const onSend = (): void => {
      onClose();
    };

    const handleGenerateNewViagem = async (): Promise<void> => {
      setLoading(true);

      const { status, messages: msgs } = await mudaCtesViagem(
        importedFiles,
        nrSeqViagem
      );

      if (msgs && msgs?.length > 0) {
        setMessages(msgs);
      }

      if (status === ResponseStatus.Success) {
        generateTravelSuccessful(msgs[0]?.message);
      }

      setLoading(false);
    };

    return (
      // @ts-expect-error
      <Modal
        title='Trocar Conhecimentos para Nova Viagem'
        show={show}
        size={BootstrapSizes.Large}
        onClose={() => onSend()}
      >
        <Loader loading={loading} />
        {messages.length > 0 && (
          <div className='m-3'>
            {/* @ts-expect-error */}
            <Notification messages={messages} onClose={() => setMessages([])} />
          </div>
        )}
        {/* @ts-expect-error */}
        <Modal.Body>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header
              title='Romaneios'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col'>
                  <FileUpload
                    files={importedFiles}
                    onChange={(items: any) => {
                      setImportedFiles(items);
                    }}
                    allowedMimeTypes={[MimeTypes.Types.PDF]}
                    maxFiles={50}
                    dropzone
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </Modal.Body>
        <Modal.Footer>
          <Button
            text='Gerar Viagem'
            theme={Theme.Success}
            size={BootstrapSizes.Medium}
            onClick={handleGenerateNewViagem}
          />
          <Button
            text='Cancelar'
            theme={Theme.Danger}
            size={BootstrapSizes.Medium}
            onClick={() => onSend()}
          />
        </Modal.Footer>
      </Modal>
    );
  }
);

export default ModalMudaCtesViagem;
