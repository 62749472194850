import api from '../api';

const baseURL = `/FRO/MonitoramentoVeiculo`;

/* export const getMonitoramentoVeiculoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  return response.data ?? {};
}; */

export const getMonitoramentoVeiculoList = async (data) => {
  const response = await api.post(`${baseURL}/Search`, data);
  return response.data ?? {};
};

export const printMonitoramentoVeiculo = async (data) => {
  const response = await api.post(`${baseURL}/Print`, data);
  return response.data.value ?? {};
};
export const printVeiculoDocumento = async (data) => {
  const response = await api.post(`${baseURL}/PrintVeiculoDocumento`, data);
  return response.data.value ?? {};
};
export const printMotoristaDocumento = async (data) => {
  const response = await api.post(`${baseURL}/PrintMotoristaDocumento`, data);
  return response.data.value ?? {};
};
export const printEquipamentoVeiculo = async (data) => {
  const response = await api.post(`${baseURL}/PrintEquipamentoVeiculo`, data);
  return response.data.value ?? {};
};
export const excelMonitoramentoVeiculo = async (data) => {
  const response = await api.post(`${baseURL}/ExcelMonitoramentoVeiculo`, data);
  return response.data.value ?? {};
};
export const excelVeiculoDocumento = async (data) => {
  const response = await api.post(`${baseURL}/ExcelVeiculoDocumento`, data);
  return response.data.value ?? {};
};
export const excelMotoristaDocumento = async (data) => {
  const response = await api.post(`${baseURL}/ExcelMotoristaDocumento`, data);
  return response.data.value ?? {};
};
export const excelEquipamentoVeiculo = async (data) => {
  const response = await api.post(`${baseURL}/ExcelEquipamentoVeiculo`, data);
  return response.data.value ?? {};
};
