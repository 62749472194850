export default class PocPocoPocEnsaio {
  constructor(jsonObject = {}) {
    this.nrSeqPocEnsaio = jsonObject.nrSeqPocEnsaio;
    this.dtInicioEnsaio = jsonObject.dtInicioEnsaio;
    this.dtFimEnsaio = jsonObject.dtFimEnsaio;
    this.noExecutor = jsonObject.noExecutor;
    this.noTecnicaMedida = jsonObject.noTecnicaMedida;
    this.flgTipoEnsaio = jsonObject.flgTipoEnsaio;
    this.noTipoEnsaio = jsonObject.noTipoEnsaio;
    this.noObservacaoEnsaio = jsonObject.noObservacaoEnsaio;
    this.noTipoBomba = jsonObject.noTipoBomba;
    this.noMarcaBomba = jsonObject.noMarcaBomba;
    this.noModeloBomba = jsonObject.noModeloBomba;
    this.noDiametroBomba = jsonObject.noDiametroBomba;
    this.nrPotenciaBomba = jsonObject.nrPotenciaBomba;
    this.nrEstagiosBomba = jsonObject.nrEstagiosBomba;
    this.nrVoltagemBomba = jsonObject.nrVoltagemBomba;
    this.nrConsumoBomba = jsonObject.nrConsumoBomba;
    this.noRedeBomba = jsonObject.noRedeBomba;
    this.nrProfundidadeInst = jsonObject.nrProfundidadeInst;
    this.noCaboEletrico = jsonObject.noCaboEletrico;
    this.noDiametroAducaopol = jsonObject.noDiametroAducaopol;
    this.nrDiametromm = jsonObject.nrDiametromm;
    this.noTubulacaoAducao = jsonObject.noTubulacaoAducao;
    this.nrSeqPocPoco = jsonObject.nrSeqPocPoco;
    this.status = jsonObject.status;
    this.flgVisibleBombRecup = jsonObject.flgVisibleBombRecup;
    this.dadosHidraulicos = jsonObject.dadosHidraulicos;
    this.bombeamentos = jsonObject.bombeamentos;
    this.recuperacoes = jsonObject.recuperacoes;
  }

  get getDataInicio() {
    if (this.dtInicioEnsaio)
      return `${new Date(this.dtInicioEnsaio).toLocaleDateString('pt-BR')}`;
    return '';
  }

  get getDataFim() {
    if (this.dtFimEnsaio)
      return `${new Date(this.dtFimEnsaio).toLocaleDateString('pt-BR')}`;
    return '';
  }

  toJson = () => JSON.stringify(this);
}
