export default class TipoDocumentoPessoa {
  constructor(jsonObject = {}) {
    this.nrSeqTipoDocumentoPessoa = jsonObject.nrSeqTipoDocumentoPessoa;
    this.cdTipoDocumentoPessoa = jsonObject.cdTipoDocumentoPessoa;
    this.flgControlaValidade = jsonObject.flgControlaValidade;
    this.flgAceitaImg = jsonObject.flgAceitaImg;
    this.emailNotificacao = jsonObject.emailNotificacao;
    this.noTipoDocumentoPessoa = jsonObject.noTipoDocumentoPessoa;
    this.flgVeiculo = jsonObject.flgVeiculo;
    this.flgCliente = jsonObject.flgCliente;
    this.flgFuncionario = jsonObject.flgFuncionario;
    this.flgMotorista = jsonObject.flgMotorista;
    this.flgAssociado = jsonObject.flgAssociado;
    this.flgPostoCombustivel = jsonObject.flgPostoCombustivel;
    this.flgConsignatario = jsonObject.flgConsignatario;
    this.flgRemetente = jsonObject.flgRemetente;
    this.flgDestinatario = jsonObject.flgDestinatario;
    this.flgUsuario = jsonObject.flgUsuario;
    this.flgExibeMobile = jsonObject.flgExibeMobile;
    this.flgTransportadora = jsonObject.flgTransportadora;
    this.nrDiasAlertaVencimento = jsonObject.nrDiasAlertaVencimento;
  }

  toJson = () => JSON.stringify(this);
}
