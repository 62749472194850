import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Textbox, Switch } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getTipoDesconto,
  saveTipoDesconto,
  deleteTipoDesconto,
} from 'core/services/FIN/tipoDesconto';

export default function TipoDescontoItem({
  registryKey,
  reload,
  onSelectPage,
  transaction,
  isActive,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({ flgCalculoAutomatico: false });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      setData(await getTipoDesconto(registryKey));
      setLoading(false);
    } else setData({ vlrPercDesconto: 0, flgCalculoAutomatico: false });
    onSetMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveTipoDesconto(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? { vlrPercDesconto: 0 } : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteTipoDesconto(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de TipoDesconto'
      loading={loading}
      transaction={transaction}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({ vlrPercDesconto: 0, flgCalculoAutomatico: false })}
      onSave={save}
      onDelete={data?.nrSeqTipoDesconto > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <div className='row'>
        <div className='col-4 mb-3'>
          <Textbox
            maxLength={20}
            label='Código'
            text={data.cdTipoDesconto}
            onChangedValue={(cdTipoDesconto) =>
              setData({ ...data, cdTipoDesconto })
            }
          />
        </div>
        <div className='col-4 mb-3'>
          <Textbox
            maxLength={100}
            label='Nome'
            required
            text={data.noTipoDesconto}
            onChangedValue={(noTipoDesconto) =>
              setData({ ...data, noTipoDesconto })
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-4 mb-3'>
          <Switch
            formControl
            label='Calculo Automatico'
            checked={data.flgCalculoAutomatico}
            onChange={(flgCalculoAutomatico) =>
              setData({ ...data, flgCalculoAutomatico })
            }
          />
        </div>
        {!data.flgCalculoAutomatico && (
          <div className='col-4 mb-3'>
            <Textbox
              label='Porcentagem Maximo'
              required
              text={data.vlrPercDesconto}
              mask={MaskTypes.DecimalCustom}
              onChangedValue={(vlrPercDesconto) =>
                setData({ ...data, vlrPercDesconto })
              }
            />
          </div>
        )}
        {data.flgCalculoAutomatico && (
          <div className='col-4 mb-3'>
            <Textbox
              label='Regra para busca automatica'
              required
              text={data.noRegraBusca}
              onChangedValue={(noRegraBusca) =>
                setData({ ...data, noRegraBusca })
              }
            />
          </div>
        )}
      </div>
    </CSDManutPage>
  );
}
