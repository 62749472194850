import { TituloPagar } from '../FIN';

export default class FreteTerceiroFatura {
  constructor(jsonObject = {}) {
    this.nrSeqFreteTerceiro = jsonObject.nrSeqFreteTerceiro;
    this.nrSeqTituloPagar = jsonObject.nrSeqTituloPagar;
    this.vlrTitulo = jsonObject.vlrTitulo;
    this.dtVencimento = jsonObject.dtVencimento;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqFreteTerceiroFatura = jsonObject.nrSeqFreteTerceiroFatura;

    this.tituloPagar = new TituloPagar(jsonObject.tituloPagar ?? {});
  }

  toJson = () => JSON.stringify(this);
}
