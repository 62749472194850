export default class Permissao {
  constructor(jsonObject = {}) {
    this.nrSeqPerfil = jsonObject.nrSeqPerfil;
    this.flgNovo = jsonObject.flgNovo;
    this.flgExcluir = jsonObject.flgExcluir;
    this.flgSalvar = jsonObject.flgSalvar;
    this.flgPesquisar = jsonObject.flgPesquisar;
    this.flgImprimir = jsonObject.flgImprimir;
    this.flgAbrir = jsonObject.flgAbrir;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqTransacao = jsonObject.nrSeqTransacao;
    this.status = jsonObject.status;
    this.noTransacaoAuxiliar = jsonObject.noTransacaoAuxiliar;
    this.transacao = jsonObject.transacao ?? {};
  }

  toJson = () => JSON.stringify(this);
}
