import qs from 'qs';
import Pagination from 'core/models/pagination';
import { NfvAvulsa } from 'core/models/VEN';

import api from '../api';

const baseURL = `/VEN/NfvAvulsa`;

export const getNfAvulsaList = async (filters: any) => {
  const response = await api.get(`${baseURL}/NfAvulsa/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new NfvAvulsa(json)) ?? [],
  };
};

export const getNfAvulsaPagined = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: NfvAvulsa) => new NfvAvulsa(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getNfvAvulsaAutoComplete = async (filters: any) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new NfvAvulsa(json)) ?? [],
  };
};

export const getNfAvulsaReturn = async (id: any) => {
  const response = await api.get(`${baseURL}/Return`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new NfvAvulsa(value) : {};
};

export const getNfAvulsaCancelEfetivacao = async (id: any) => {
  const response = await api.get(`${baseURL}/CancelEfetivacao`, {
    params: { id },
  });

  return response.data ? response.data : {};
};

export const getNfAvulsa = async (id: any) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });

  const { value } = response.data ?? {};
  return value ? new NfvAvulsa(value) : {};
};

export const confirmNfvAvulsa = async (data: any) => {
  const response = await api.post(`${baseURL}/Efetivar`, data);
  return response.data ?? {};
};

export const saveNfAvulsa = async (data: any) => {
  const response = await api.post(`${baseURL}/`, data);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const deleteNfAvulsa = async (id: any) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printNfAvulsaReport = async (filters: any) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printNfAvulsaReportBudge = async (filters: any) => {
  const response = await api.get(`${baseURL}/PrintBudge`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printNfAvulsa = async (filters: any) => {
  const response = await api.get(`${baseURL}/PrintNfvAvulsa`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelNfAvulsa = async (filters: any) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data.value ?? {};
};

export const deleteAllNfAvulsa = async (ids: any) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
