import React from 'react';
import { Button, FontAwesomeIcon } from 'ui/components';
import Userbar from './userbar';

const Header = ({
  onToggleMenu,
  openedTransactions = [],
  userNotifications = [],
  onOpenTransaction,
  onCloseTransaction,
}) => (
  <div className='row header'>
    <div className='col d-flex align-items-center'>
      <div className='row'>
        <div className='col-auto'>
          <Button
            style={{ zIndex: 0 }}
            className='btn-menu-collapse'
            icon='bars'
            onClick={onToggleMenu}
          />
        </div>
        {openedTransactions.map((transaction) => {
          if (transaction) {
            const statusClass = transaction.active ? 'primary' : 'inactive';

            return (
              <div className='col-auto' key={transaction.nrSeqTransacao}>
                <div
                  role='presentation'
                  className={`btn btn-${statusClass} btn-page`}
                  onClick={() => onOpenTransaction(transaction)}
                >
                  <div
                    role='presentation'
                    className='btn-close'
                    onClick={(e) => {
                      onCloseTransaction(transaction);
                      e.stopPropagation();
                    }}
                  >
                    <FontAwesomeIcon icon='times' />
                  </div>
                  {transaction.noTransacao}
                </div>
              </div>
            );
          }

          return null;
        })}
      </div>
    </div>
    <div className='col-auto'>
      <Userbar userNotifications={userNotifications} />
    </div>
  </div>
);
export default Header;
