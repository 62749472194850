import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Theme, ResponseStatus, BootstrapSizes } from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Textarea,
  Textbox,
  Button,
  ToolbarButtons,
  GridView,
} from 'ui/components';
import { EnvioProcessamentoContas } from 'core/models/TEL';

import {
  getEnvioProcessamentoContas,
  postEnvioProcessamentoContas,
} from 'core/services/TEL/envioprocessamentocontas';

import ModalQRCode from 'ui/components/WhatsApp/modalQRCode';

import PanelRelSinteticoFinanceiro from '../ParametroEnvioProcessamentoContas/panelRelSinteticoFinanceiro';
import PanelArquivoIntegracao from '../ParametroEnvioProcessamentoContas/panelArquivoIntegracao';
import PanelInformacoesGerais from '../ParametroEnvioProcessamentoContas/panelInformacoesGerais';
import PanelRelAnaliticoServicosLigacoes from '../ParametroEnvioProcessamentoContas/panelRelAnaliticoServicosLigacoes';
import PanelRelFinanceiroSubsidio from '../ParametroEnvioProcessamentoContas/panelRelFinanceiroSubsidio';
import PanelGerarContasPDF from '../ParametroEnvioProcessamentoContas/panelGerarContasPDF';

import PanelSelecaoContratos from '../panelSelecaoContratos';

export default function SelecaoParametroEnvioProcessamentoConta({
  isActive,
  transaction,
}) {
  const [parametros, setParametros] = useState({});
  const [state, setState] = useState({ viewQrCode: false, viewStatus: false });
  const [loading, setLoading] = useState(false);
  const [messageState, setMessage] = useState(null);
  const [contratosSelecionados, setContratosSelecionados] = useState([]);
  const [selectedItems, setSelectedItems] = useState({});
  const [filters, setFilters] = useState();
  const exibirDataFaturamento = true;
  const exibirPaginaBranca = true;
  const gridView = useRef();

  const panelArquivoIntegracaoRef = useRef({});
  const panelRelSinteticoFinanceiroRef = useRef({});
  const panelInformacoesGeraisRef = useRef({});
  const panelRelAnaliticoServicosLigacoesRef = useRef({});
  const panelGerarContasPDFRef = useRef({});
  const panelRelFinanceiroSubsidioRef = useRef({});

  const onRemove = (e, datasource) => {
    setContratosSelecionados([]);
    const data = datasource.filter((plano) => plano !== e);
    if (gridView && gridView.current) gridView.current.setDataSource(data);
    setContratosSelecionados(data);
  };

  const columns = [
    { key: 'nrSeqClienteContaCab', visible: false },
    { key: 'contrato', title: 'Contrato' },
    { key: 'periodoFatura', title: 'Período Fatura' },
    {
      key: 'nrSeqClienteContaCab',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemove(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remove',
      tooltipDirection: 'bottom',
    },
  ];

  const searchParametroEnvioProcessamentoConta = async () => {
    const param = await getEnvioProcessamentoContas();
    setParametros(param);
  };

  const loadStatus = useCallback(async () => {}, []);

  useEffect(() => {
    setParametros(new EnvioProcessamentoContas());
    searchParametroEnvioProcessamentoConta();
  }, []);

  const set = () => {
    setState({ ...state, viewQrCode: true });
  };

  const onSave = async () => {
    const obj = {
      email: parametros.email,
      notitulo: parametros.notitulo,
      nomensagem: parametros.nomensagem,
      nrseqparamenvioprocessamentoconta:
        parametros.nrseqparamenvioprocessamentoconta,
      ...panelRelAnaliticoServicosLigacoesRef.current.param,
      ...panelInformacoesGeraisRef.current.param,
      ...panelArquivoIntegracaoRef.current.param,
      ...panelRelSinteticoFinanceiroRef.current.param,
      ...panelGerarContasPDFRef.current.param,
      ...panelRelFinanceiroSubsidioRef.current.param,
    };

    const { status, message } = await postEnvioProcessamentoContas(obj);

    setMessage({
      message,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
    searchParametroEnvioProcessamentoConta();
  };
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    else setMessage(null);
  };
  const onAdicionarFatura = () => {
    const lista = contratosSelecionados;
    const obj = {
      nrSeqContaCabecalho: selectedItems.fatura.nrSeqContaCabecalho,
      contrato: selectedItems.contrato.cdNoCliente,
      periodoFatura: selectedItems.fatura.periodoFatura,
    };
    const faturaAdicionada = contratosSelecionados.findIndex(
      (e) => e.nrSeqContaCabecalho === obj.nrSeqContaCabecalho
    );

    if (faturaAdicionada === -1) {
      lista.push(obj);
      setContratosSelecionados(lista);
      if (gridView && gridView.current) gridView.current.setDataSource(lista);
    } else {
      onSetMessage(ResponseStatus.Error, 'Fatura já adicionada.');
    }
  };

  return (
    <CSDManutPage
      isActive={isActive}
      onSave={onSave}
      loading={loading}
      message={messageState}
      title='Envio Processamento Contas'
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button text='Enviar' icon={['far', 'file-excel']} />
        <ToolbarButtons.Button text='Upload' icon='cloud-upload-alt' />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col align-self-end'>
          <div className='row mb-3'>
            <div className='col-8'>
              <PanelSelecaoContratos
                contratoAgrupado={selectedItems.contratoAgrupado}
                onContratoAgrupadoChange={(contratoAgrupado) => {
                  setSelectedItems({ ...selectedItems, contratoAgrupado });
                  setFilters({
                    ...filters,
                    nrSeqContratoAgrupado:
                      contratoAgrupado.nrSeqContratoAgrupado,
                  });
                }}
                contrato={selectedItems.contratoAgrupado}
                onContratoChange={(contrato) => {
                  setSelectedItems({ ...selectedItems, contrato });
                }}
                fatura={selectedItems.fatura}
                onFaturaChange={(fatura) => {
                  setSelectedItems({ ...selectedItems, fatura });
                  setFilters({
                    ...filters,
                    nrSeqContaCabecalho: fatura.nrSeqContaCabecalho,
                  });
                }}
                onMessage={(msg, theme) => setMessage({ message: msg, theme })}
              />
            </div>
            <div className='col-2'>
              <div className='widget-square text-white p-4 br-tl-1 br-bl-1'>
                <Button
                  outline
                  text='Adicionar'
                  size={BootstrapSizes.Small}
                  theme={Theme.Success}
                  template={Button.Templates.Default}
                  onClick={onAdicionarFatura}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            canControlVisibility
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body'>
              <div className='row mb-3'>
                <div className='col-6'>
                  <Textbox
                    label='E-mails de Destino'
                    text={parametros.email}
                    onChangedValue={(email) =>
                      setParametros({
                        ...parametros,
                        email,
                      })
                    }
                  />
                </div>
                <div className='col-4'>
                  <Textbox
                    label='Título E-mail'
                    text={parametros.notitulo}
                    onChangedValue={(notitulo) =>
                      setParametros({
                        ...parametros,
                        notitulo,
                      })
                    }
                  />
                </div>
                <div className='col-2'>
                  <Textbox label='Celular Cliente' />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-12'>
                  <Textarea
                    label={
                      'Mensagem do E-mail (Dica: a-Para tags html utilizar "%" ao invés de "<" e " & " ao invés de ">"; b-Utilizar a tag %assinatura & e fechar com %/assinatura& para informar a assinatura do e-mail)'
                    }
                    text={parametros.nomensagem}
                    onChangedValue={(nomensagem) =>
                      setParametros({
                        ...parametros,
                        nomensagem,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-12'>
        <div className='card'>
          <div className='card-body'>
            <PanelInformacoesGerais
              props={parametros}
              ref={panelInformacoesGeraisRef}
            />
          </div>
        </div>
      </div>
      <div className='col-12'>
        <div className='card'>
          <div className='card-body'>
            <Button
              theme={Theme.Info}
              template={Button.Templates.Default}
              text='Gerar QRCode'
              onClick={set}
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='accordion' id='accordionRelSinteticoFinanceiro'>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='relSinteticoFinanceiro'>
                <button
                  className='accordion-button col-12'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseRelSinteticoFinanceiro'
                  aria-expanded='true'
                  aria-controls='collapseRelSinteticoFinanceiro'
                >
                  Relatório Sintético Financeiro
                </button>
              </h2>
              <div
                id='collapseRelSinteticoFinanceiro'
                className='accordion-collapse collapse show'
              >
                <div className='accordion-body'>
                  <PanelRelSinteticoFinanceiro
                    props={parametros}
                    ref={panelRelSinteticoFinanceiroRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='accordion' id='accordionArquivoIntegracao'>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='arquivoIntegracao'>
                <button
                  className='accordion-button col-12'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseArquivoIntegracao'
                  aria-expanded='true'
                  aria-controls='collapseArquivoIntegracao'
                >
                  Arquivo de Integração
                </button>
              </h2>
              <div
                id='collapseArquivoIntegracao'
                className='accordion-collapse collapse show'
              >
                <div className='accordion-body'>
                  <PanelArquivoIntegracao
                    props={parametros}
                    exibirDataFaturamento={exibirDataFaturamento}
                    ref={panelArquivoIntegracaoRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='accordion' id='accordionRelAnaliticoServicoLigacoes'>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='relAnaliticoServicoLigacoes'>
                <button
                  className='accordion-button col-12'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseRelAnaliticoServicoLigacoes'
                  aria-expanded='true'
                  aria-controls='collapseRelAnaliticoServicoLigacoes'
                >
                  Relatório Analitico Serviços e Ligações
                </button>
              </h2>
              <div
                id='collapseRelAnaliticoServicoLigacoes'
                className='accordion-collapse collapse show'
              >
                <div className='accordion-body'>
                  <PanelRelAnaliticoServicosLigacoes
                    props={parametros}
                    ref={panelRelAnaliticoServicosLigacoesRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='accordion' id='accordionRelFinanceiroSubsidio'>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='relFinanceiroSubsidio'>
                <button
                  className='accordion-button col-12'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseRelFinanceiroSubsidio'
                  aria-expanded='true'
                  aria-controls='collapseRelFinanceiroSubsidio'
                >
                  Relatório Financeiro com Subsidio
                </button>
              </h2>
              <div
                id='collapseRelFinanceiroSubsidio'
                className='accordion-collapse collapse show'
              >
                <div className='accordion-body'>
                  <PanelRelFinanceiroSubsidio
                    props={parametros}
                    ref={panelRelFinanceiroSubsidioRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='accordion' id='accordionGerarContasPDF'>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='gerarContasPDF'>
                <button
                  className='accordion-button col-12'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseGerarContasPDF'
                  aria-expanded='true'
                  aria-controls='collapseGerarContasPDF'
                >
                  Gera contas PDF
                </button>
              </h2>
              <div
                id='collapseGerarContasPDF'
                className='accordion-collapse collapse show'
              >
                <div className='accordion-body'>
                  <PanelGerarContasPDF
                    props={parametros}
                    exibirPaginaBranca={exibirPaginaBranca}
                    ref={panelGerarContasPDFRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalQRCode
        show={state.viewQrCode}
        onClose={() => {
          setState({ ...state, viewQrCode: false });
          loadStatus();
        }}
      />
    </CSDManutPage>
  );
}
