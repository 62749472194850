import React, { useEffect, useRef, useState } from 'react';
import { PageTypes } from 'ui/Helpers/utils';
import { getChecklistFrotaPagined } from 'core/services/FRO/checklistFrota';
import { CSDSelPage, GridView, Textbox } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';

const columns = [
  {
    key: 'nrSeqChecklistFrota',
    type: GridView.ColumnTypes.Checkbox,
    visible: false,
  },
  { key: 'cdChecklistFrota', title: 'Codigo' },
  { key: 'noChecklistFrota', title: 'Nome Checklist' },
  { key: 'noLayout', title: 'Layout' },
  { key: 'noTitulo', title: 'NoTitulo', visible: false },
  { key: 'flgImagemEmpresa', title: 'FlgImagemEmpresa', visible: false },
  {
    key: 'flgManutencaoPreventiva',
    title: 'FlgManutencaoPreventiva',
    visible: false,
  },
  {
    key: 'flgImprimeTodosItens',
    title: 'FlgImprimeTodosItens',
    visible: false,
  },
];

export default function ChecklistFrota({ transaction, onOpenPage, isActive }) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getChecklistFrotaPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getChecklistFrotaPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ChecklistFrota/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqChecklistFrota = sourceRow.find(
      (e) => e.key === 'nrSeqChecklistFrota'
    );
    onOpenMaintenance(nrSeqChecklistFrota.value);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de ChecklistFrota'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-2 mb-3'>
          <Textbox
            required
            label='Codigo CheckList'
            text={filtros.cdChecklistFrota}
            mask={MaskTypes.Integer}
            onChangedValue={(cdChecklistFrota) =>
              setFiltros({ ...filtros, cdChecklistFrota })
            }
          />
        </div>
        <div className='col-5 mb-3'>
          <Textbox
            maxLength={200}
            label='Nome Checklist'
            text={filtros.noChecklistFrota}
            onChangedValue={(noChecklistFrota) =>
              setFiltros({ ...filtros, noChecklistFrota })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            enableExcelExport
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
