import qs from 'qs';
import Pagination from 'core/models/pagination';
import Inventario from 'core/models/EST/inventario';
import api from '../api';

const baseURL = `/EST/Inventario`;

export const getInventarioList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Inventario(json)) ?? [],
  };
};

export const getInventarioPaginated = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPaginated`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Inventario(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getInventario = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Inventario(value) : {};
};

export const saveInventario = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteInventario = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printInventario = async (filters) => {
  const response = await api.post(`${baseURL}/Print`, {
    ...filters,
  });
  return response.data ?? {};
};

export const printInventarioDiferenca = async (filters) => {
  const response = await api.post(`${baseURL}/PrintDiferenca`, {
    ...filters,
  });
  return response.data ?? {};
};

export const excelInventario = async (filters) => {
  const response = await api.post(`${baseURL}/Excel`, {
    ...filters,
  });
  return response.data ?? {};
};

export const deleteAllInventario = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const efetivarInventario = async (data) => {
  const response = await api.post(`${baseURL}/Efetivar`, data);

  return response.data ?? {};
};
