import { PessoaInformacaoBancaria } from 'core/models/FIN';
import api from '../api';

const baseURL = `/FIN/PessoaInformacaoBancaria`;

export const getInformacaoBancariaList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PessoaInformacaoBancaria(json)) ?? [],
  };
};

const getInformacaoBancariaAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    informacoesBancarias:
      value?.map((json) => new PessoaInformacaoBancaria(json)) ?? [],
  };
};

export const getInformacaoBancariaAutoCompleteFiltraFormaPagamento = async (
  filters
) => {
  const response = await api.get(
    `/InformacaoBancaria/SearchAutoCompleteFiltraFormaPagamento`,
    {
      params: filters,
    }
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    informacoesBancarias:
      value?.map((json) => new PessoaInformacaoBancaria(json)) ?? [],
  };
};

export { getInformacaoBancariaAutoComplete };

export const getInformacaoBancariaByNrSeqPessoa = async (nrSeqPessoa) => {
  const response = await api.get(`${baseURL}/SearchByFornecedor`, {
    params: { nrSeqPessoa },
  });
  const { value } = response.data ?? {};
  return value ? new PessoaInformacaoBancaria(value) : {};
};
