import qs from 'qs';
import Pagination from 'core/models/pagination';
import { NFEImportada, NFEImportadaResult } from 'core/models/COP';

import api from '../api';

const baseURL = `/COP/NFEImportada`;

export const getSearchNewDocuments = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchNewDocuments`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new NFEImportada(json)) ?? [],
  };
};

export const getSearchNewDocumentsMissing = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchNewDocumentsMissing`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new NFEImportada(json)) ?? [],
  };
};

export const getSearchNewDocumentsService = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchNewDocumentsService`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new NFEImportada(json)) ?? [],
  };
};

export const manifestDocument = async (filters: any) => {
  const response = await api.get(`${baseURL}/ManifestDocument`, {
    params: filters,
  });
  const { status, message } = response.data ?? {};

  return {
    status,
    message,
  };
};

export const getNFEImportadaList = async (filters: any) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new NFEImportada(json)) ?? [],
  };
};

export const getNFEImportadaPagined = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data:
      value?.map((json: NFEImportadaResult) => new NFEImportadaResult(json)) ??
      [],
    pagination: new Pagination(pagination),
  };
};

export const getNFEImportada = async (id: any) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new NFEImportada(value) : {};
};

export const downloadXML = async (id: any) => {
  const response = await api.get(`${baseURL}/DownloadXML`, {
    params: { id },
  });
  const { value, status, message } = response.data ?? {};

  return { data: value, status, message };
};

export const downloadPdf = async (id: any) => {
  const response = await api.get(`${baseURL}/DownloadPdf`, {
    params: { id },
  });
  const { value, status, message } = response.data ?? {};

  return { data: value, status, message };
};

export const saveNFEImportada = async (data: any) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};
export const ImportAbastecimentos = async (data: any) => {
  const response = await api.post(`${baseURL}/ImportAbastecimentos`, data);
  return response.data ?? {};
};

export const deleteNFEImportada = async (id: any) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printNFEImportada = async (filters: any) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelNFEImportada = async (filters: any) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllNFEImportada = async (ids: any) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
