export default class PocPerflitCamada {
  constructor(jsonObject = {}) {
    this.nrSeqPocPerflitCamada = jsonObject.nrSeqPocPerflitCamada;
    this.cdPocPerflitCamada = jsonObject.cdPocPerflitCamada;
    this.noPocPerflitCamada = jsonObject.noPocPerflitCamada;
    this.noImagem = jsonObject.noImagem;
    this.noImagemBase64 = jsonObject.noImagemBase64;
    this.noTipoArquivo = jsonObject.noTipoArquivo;
    this.noArquivo = jsonObject.noArquivo;
    this.noTamanho = jsonObject.noTamanho;
    this.flgAtivo = jsonObject.flgAtivo;
  }

  toJson = () => JSON.stringify(this);
}
