import Nf from 'core/models/COP/nf';
import Produto from 'core/models/EST/produto';
import AlmoxarifadoLocal from 'core/models/EST/almoxarifadoLocal';
import Almoxarifado from 'core/models/EST/almoxarifado';
import Cfo from 'core/models/FIS/cfo';
import { PocOrcamento } from 'core/models/POC';
import Veiculo from 'core/models/FRO/veiculo';
import ManutencaoVeiculoOS from 'core/models/FRO/manutencaoVeiculoOS';
import ManutencaoVeiculoProduto from 'core/models/FRO/manutencaoVeiculo_Produto';

export default class NfItem {
  constructor(jsonObject = {}) {
    this.flgTribIpi = jsonObject.flgTribIpi;
    this.flgTribIcms = jsonObject.flgTribIcms;
    this.qtdeItNf = jsonObject.qtdeItNf;
    this.vlrUnit = jsonObject.vlrUnit;
    this.vlrTotal = jsonObject.vlrTotal;
    this.vlrDespesa = jsonObject.vlrDespesa;
    this.vlrServico = jsonObject.vlrServico;
    this.vlrDesc = jsonObject.vlrDesc;
    this.vlrFrete = jsonObject.vlrFrete;
    this.vlrBaseCalcIcms = jsonObject.vlrBaseCalcIcms;
    this.vlrBaseCalcIpi = jsonObject.vlrBaseCalcIpi;
    this.vlrIcms = jsonObject.vlrIcms;
    this.vlrIcmsFrete = jsonObject.vlrIcmsFrete;
    this.vlrIpi = jsonObject.vlrIpi;
    this.percBaseCalcIpi = jsonObject.percBaseCalcIpi;
    this.percBaseCalcIcms = jsonObject.percBaseCalcIcms;
    this.aliqIcms = jsonObject.aliqIcms;
    this.nrSeqAlmoxarifado = jsonObject.nrSeqAlmoxarifado;
    this.nrSeqAlmoxarifadoLocal = jsonObject.nrSeqAlmoxarifadoLocal;
    this.nrSeqProduto = jsonObject.nrSeqProduto;
    this.nrSeqCfo = jsonObject.nrSeqCfo;
    this.nrSeqEstoqueMovimento = jsonObject.nrSeqEstoqueMovimento;
    this.aliqIpi = jsonObject.aliqIpi;
    this.nrOrdem = jsonObject.nrOrdem;
    this.flgRecIpi = jsonObject.flgRecIpi;
    this.nrSeqNf = jsonObject.nrSeqNf;
    this.flgRecIcms = jsonObject.flgRecIcms;
    this.cdNcm = jsonObject.cdNcm;
    this.nrSeqPedidoCompra = jsonObject.nrSeqPedidoCompra;
    this.cdCstIcms = jsonObject.cdCstIcms;
    this.cdCstIpi = jsonObject.cdCstIpi;
    this.vlrBaseCalcPis = jsonObject.vlrBaseCalcPis;
    this.cdCstPis = jsonObject.cdCstPis;
    this.aliqPis = jsonObject.aliqPis;
    this.vlrPis = jsonObject.vlrPis;
    this.cdCstCofins = jsonObject.cdCstCofins;
    this.vlrBaseCalcCofins = jsonObject.vlrBaseCalcCofins;
    this.aliqCofins = jsonObject.aliqCofins;
    this.vlrCofins = jsonObject.vlrCofins;
    this.saldo = jsonObject.saldo;
    this.cstIcmsOrigem = jsonObject.cstIcmsOrigem;
    this.observacao = jsonObject.observacao;
    this.nrSeqCstIpi = jsonObject.nrSeqCstIpi;
    this.noCodigo = jsonObject.noCodigo;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.vlrBcST = jsonObject.vlrBcST;
    this.vlrPIcmsST = jsonObject.vlrPIcmsST;
    this.vlrIcmsST = jsonObject.vlrIcmsST;
    this.dtEmis = jsonObject.dtEmis;
    this.dtReceb = jsonObject.dtReceb;
    this.noFornecedor = jsonObject.noFornecedor;
    this.noEmpresa = jsonObject.noEmpresa;
    this.nrForNf = jsonObject.nrForNf;
    this.nrOdometro = jsonObject.nrOdometro;
    this.nrSeqPocOrcamento = jsonObject.nrSeqPocOrcamento;
    this.nrSeqManutencaoVeiculoOS = jsonObject.nrSeqManutencaoVeiculoOS;
    this.nrSeqManutencaoVeiculoItem = jsonObject.nrSeqManutencaoVeiculoItem;
    this.produto = new Produto(jsonObject.produto ?? {});
    this.nf = new Nf(jsonObject.nf ?? {});
    this.localArmazenagem = new AlmoxarifadoLocal(
      jsonObject.localArmazenagem ?? {}
    );
    this.almoxarifado = new Almoxarifado(jsonObject.almoxarifado ?? {});
    this.cfo = new Cfo(jsonObject.cfo ?? {});
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    this.orcamento =
      jsonObject.orcamento !== undefined && jsonObject.orcamento !== null
        ? new PocOrcamento(jsonObject.orcamento)
        : new PocOrcamento({});
    this.manutencaoVeiculoOS = new ManutencaoVeiculoOS(
      jsonObject.manutencaoVeiculoOS ?? {}
    );
    this.manutencaoVeiculoProduto = new ManutencaoVeiculoProduto(
      jsonObject.manutencaoVeiculoProduto ?? {}
    );
    this.produtoNaoCadastrado = jsonObject.produtoNaoCadastrado;
    this.corProduto = jsonObject.corProduto;
  }

  toJson = () => JSON.stringify(this);
}
