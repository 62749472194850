import qs from 'qs';
import Pagination from 'core/models/pagination';
import RotaTransporte from 'core/models/FRO/rotaTransporte';
import api from '../api';

const baseURL = `/FRO/RotaTransporte`;

export const getRotaTransporteList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new RotaTransporte(json)) ?? [],
  };
};

export const getRotaTransporteAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new RotaTransporte(json)) ?? [],
  };
};

export const dropDownListRotaTransporte = async (filters) => {
  const response = await api.get(`${baseURL}/DropDownList`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new RotaTransporte(json)) ?? [],
  };
};

export const getRotaTransporteAutoCompleteManifestoNfv = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteManifestoNfv`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new RotaTransporte(json)) ?? [],
  };
};

export const getRotaTransportePagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new RotaTransporte(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getRotaTransporte = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new RotaTransporte(value) : {};
};

export const saveRotaTransporte = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteRotaTransporte = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printRotaTransporte = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelRotaTransporte = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllRotaTransporte = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
