/* eslint-disable no-unused-vars */
import {
  getDemandasDevolvidasPaginated,
  getGraficoDemandasDevolvidaInfo,
} from 'core/services/HELP';
import {
  getEmpresaDefaultUsuario,
  getEmpresasUsuario,
} from 'core/services/SEG';
import React, { forwardRef, useEffect, useState, useRef } from 'react';
import {
  Autocomplete,
  Button,
  CSDBarGraphic,
  DatePicker,
  GridView,
  Panel,
} from 'ui/components';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';

const DemandaStep = forwardRef(
  ({
    data,
    setData,
    setLoading,
    onOpenTransaction,
    findTransaction,
    onSetMessage,
  }) => {
    const dtInicio = new Date();
    dtInicio.setMonth(dtInicio.getMonth() - 6);

    const dtFim = new Date();
    dtFim.setDate(dtFim.getDate() - 5);

    const gridView = useRef();
    const graficoRef = useRef();

    const [filters, setFilters] = useState({
      dtSolicitacaoInicial: dtInicio.toJSON().slice(0, 10),
      dtSolicitacaoFinal: dtFim.toJSON().slice(0, 10),
      empresa: data.empresa,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });
    const [graficoData, setGraficoData] = useState({});

    const onOpenDemanda = async (e, datasource) => {
      setLoading(true);

      const gridItem = datasource.find((el) => el === e);

      const demandaTransaction = await findTransaction(
        TransacationCodes.Demanda
      );

      demandaTransaction.registryKey = gridItem.nrSeqDemanda;

      onOpenTransaction(demandaTransaction, true, undefined, undefined, true);

      setLoading(false);
    };

    const columns = [
      { key: 'nrSeqDemanda', title: 'Contrato', sortable: false },
      { key: 'noEmpresa', title: 'Empresa', visible: false, sortable: false },
      {
        key: 'operadoraDestino.noOperadora',
        title: 'Operadora',
        sortable: false,
      },
      {
        key: 'tipoDemanda.noTipoDemanda',
        title: 'Tipo',
        sortable: false,
      },
      {
        key: 'noCentroDeCusto',
        title: 'Centro Custo',
        visible: false,
        sortable: false,
      },
      { key: 'chip.linhaFormatada', title: 'Linha' },
      { key: 'noCliente', title: 'Cliente', sortable: false },
      {
        key: 'noUsuarioSolicitacao',
        title: 'Usu. Sol.',
        visible: false,
        sortable: false,
      },
      {
        key: 'dtSolicitacao',
        title: 'Solicitacao',
        format: GridView.DataTypes.Date,
        sortable: false,
      },
      { key: 'noStatusDemanda', title: 'Status', sortable: false },
      { key: 'noIteracao', title: 'Iteração', sortable: false },
      {
        key: 'noObservacao',
        title: 'Observação',
        visible: false,
        sortable: false,
      },
      {
        key: 'nrSeqDemanda',
        title: 'Abrir',
        type: GridView.ColumnTypes.Button,
        onClick: (e, dataSource) => onOpenDemanda(e, dataSource),
        theme: Theme.Info,
        icon: 'folder-open',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Abrir Demanda',
        tooltipDirection: 'bottom',
      },
    ];

    const getDemandasDevolvidas = async (params = null) => {
      setLoading(true);

      const {
        data: demandas,
        pagination,
      } = await getDemandasDevolvidasPaginated(params || filters);

      gridView?.current?.setDataSource(demandas ?? [], pagination);

      setLoading(false);
    };

    const getGraficoData = async (params = null) => {
      if (!filters.nrSeqEmpresa) {
        onSetMessage(ResponseStatus.Error, 'Selecione uma empresa');
      } else {
        const { value: demandas } = await getGraficoDemandasDevolvidaInfo(
          params || filters
        );

        setGraficoData({ demandas });
      }
    };

    const onColumnSort = (sortBy) => {
      const filtros = { ...filters, sortBy };

      setFilters(filtros);

      getDemandasDevolvidas(filtros);
      getGraficoData(filtros);
    };

    const onPageSizeChange = (totalByPage) => {
      const filtros = { ...filters, totalByPage };

      setFilters(filtros);

      getDemandasDevolvidas(filters);
      getGraficoData(filtros);
    };

    const onPageChange = (page) => {
      const filtros = { ...filters, page };

      setFilters(filtros);

      getDemandasDevolvidas(filtros);
      getGraficoData(filtros);
    };

    const optionsBarGraphicDemandas = {
      responsive: true,
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              const { formattedValue } = tooltipItem;

              return `Demandas devolvidas: ${formattedValue}`;
            },
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    };

    useEffect(() => {
      getDemandasDevolvidas();
      getGraficoData();
    }, []);

    const onSearchEmpresa = async (e) => {
      const { status, message: msg, empresas } = await getEmpresasUsuario({
        noPessoa: e,
      });

      onSetMessage(status, msg);

      return empresas;
    };

    useEffect(async () => {
      if (!data.nrSeqEmpresa) {
        const { empresas } = await getEmpresaDefaultUsuario();

        setFilters({
          ...filters,
          empresa: empresas[0],
          nrSeqEmpresa: empresas[0].nrSeqEmpresa,
        });

        setData({
          ...data,
          empresa: empresas[0],
          nrSeqEmpresa: empresas[0].nrSeqEmpresa,
        });
      }
    }, []);

    useEffect(() => {
      if (Object.keys(graficoData).length > 0) {
        const labels = graficoData.demandas?.map((p) => p.noMes);

        const demandasDevolvidasData = graficoData.demandas?.map(
          (item) => item.qtdDemandas
        );

        graficoRef?.current?.setDataSource({
          labels,
          datasets: [
            {
              label: 'Demandas Devolvidas',
              data: demandasDevolvidasData,
              backgroundColor: 'rgba(220, 226, 35, 0.8)',
            },
          ],
        });
      }
    }, [graficoData]);

    return (
      <>
        <div className='row mb-3'>
          <div className='col'>
            <Autocomplete
              label='Empresa'
              searchDataSource={onSearchEmpresa}
              selectedItem={filters.empresa}
              onSelectItem={(empresa) => {
                setFilters({
                  ...filters,
                  empresa,
                  nrSeqEmpresa: empresa.nrSeqEmpresa,
                });

                setData({
                  ...data,
                  empresa,
                  nrSeqEmpresa: empresa.nrSeqEmpresa,
                });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <Panel>
              <Panel.Header title='Demandas Devolvidas' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-2'>
                    <DatePicker
                      label='Data Inicial'
                      text={filters.dtSolicitacaoInicial}
                      onChange={(dtSolicitacaoInicial) => {
                        setFilters({
                          ...filters,
                          dtSolicitacaoInicial,
                        });
                      }}
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Data Final'
                      text={filters.dtSolicitacaoFinal}
                      onChange={(dtSolicitacaoFinal) => {
                        setFilters({ ...filters, dtSolicitacaoFinal });
                      }}
                    />
                  </div>
                  <div className='col-3 d-flex align-items-end'>
                    <Button
                      text='Pesquisar'
                      icon='search'
                      theme={Theme.Primary}
                      template={Button.Templates.Default}
                      onClick={() => {
                        getDemandasDevolvidas();
                        getGraficoData();
                      }}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <GridView
                      ref={gridView}
                      dataColumns={columns}
                      onColumnSort={onColumnSort}
                      onPageSizeChange={onPageSizeChange}
                      onPageChange={onPageChange}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <CSDBarGraphic
              ref={graficoRef}
              options={optionsBarGraphicDemandas}
            />
          </div>
        </div>
      </>
    );
  }
);

export default DemandaStep;
