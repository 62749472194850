import qs from 'qs';
import Pagination from 'core/models/pagination';
import EstoqueMovimento from 'core/models/EST/estoqueMovimento';
import ConsultaMovimentoEstoque from 'core/models/EST/consultaMovimentoEstoque';
import api from '../api';

const baseURL = `/EST/EstoqueMovimento`;

export const getEstoqueMovimentoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new EstoqueMovimento(json)) ?? [],
  };
};

export const getEstoqueMovimentoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new EstoqueMovimento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getEstoqueMovimento = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new EstoqueMovimento(value) : {};
};

export const getFindLatestOutGoingMovements = async (filters) => {
  const response = await api.get(`${baseURL}/FindLatestOutGoingMovements`, {
    params: filters,
  });
  const { value } = response.data ?? [];
  return value?.map((json) => new EstoqueMovimento(json)) ?? [];
};

export const saveEstoqueMovimento = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteEstoqueMovimento = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printEstoqueMovimento = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelEstoqueMovimento = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllEstoqueMovimento = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getConsultaMovimentoEstoquePaged = async (filters) => {
  const response = await api.get(`${baseURL}/GetMovementPagedQuery`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ConsultaMovimentoEstoque(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};
export const searchDetailsMovementQuery = async (filters) => {
  const response = await api.post(
    `${baseURL}/SearchDetailsMovementQuery`,
    filters
  );
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ConsultaMovimentoEstoque(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const printConsultaMovEstoque = async (filters) => {
  const response = await api.get(`${baseURL}/PrintConsulta`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelConsultaMovimentos = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelConsultaMovimentos`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
