export default class NfvChaveNfe {
  constructor(jsonObject = {}) {
    this.nrSeqNfvChaveNfe = jsonObject.nrSeqNfvChaveNfe;
    this.chaveNfe = jsonObject.chaveNfe;
    this.nrSeqNfv = jsonObject.nrSeqNfv;
    this.nrSeqNfvAvulsa = jsonObject.nrSeqNfvAvulsa;
  }

  toJson = () => JSON.stringify(this);
}
