import React, { useState } from 'react';
import { Autocomplete, Textarea, Switch } from 'ui/components';
// @ts-expect-error
import { ResponseStatus } from 'ui/Helpers/enums.ts';
import { IManutencaoDemanda } from 'core/interfaces/HELP/demanda';
import {
  getOperadoraAutoComplete,
  getCentroDeCustoAutoCompleteSimples,
  searchLinhasParada,
} from 'core/services/TEL';

export default function ManutencaoSolicitacaoLinha({
  data,
  setData,
  onSetMessage,
}: IManutencaoDemanda) {
  const [centroDeCustoOrigem, setCentroDeCustoOrigem] = useState<any>({});
  const [switchState, setSwitchState] = useState<boolean>(true);

  const onSearchOperadora = async (e: string) => {
    const {
      status,
      message: msg,
      operadoras,
    } = await getOperadoraAutoComplete({ noOperadora: e });

    if (status === ResponseStatus.Error) {
      onSetMessage(status, msg);

      return [];
    }

    const result = operadoras.filter(
      (operadora: any) => !operadora.noOperadora.includes('Importação Manual')
    );

    return result;
  };

  const onSearchCentroDeCusto = async (e: string) => {
    if (!data.nrSeqEmpresa) {
      onSetMessage(ResponseStatus.Error, 'Selecione a Empresa');
    }

    const {
      status,
      message: msg,
      data: centros,
    } = await getCentroDeCustoAutoCompleteSimples({
      codNoCentroDeCusto: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    onSetMessage(status, msg);

    return centros;
  };

  const onSearchLinhasEstoque = async (e: string): Promise<Array<any>> => {
    if (!data.nrSeqOperadoraDestino) {
      onSetMessage(ResponseStatus.Error, 'Seleciona a Operadora');
    }

    const obj: any = {
      nrPrefixoLinha: e,
      nrSeqOperadora: data.nrSeqOperadoraDestino,
    };

    if (centroDeCustoOrigem) {
      if (centroDeCustoOrigem.nrSeqCentroDeCusto) {
        obj.nrSeqCentroDeCusto = centroDeCustoOrigem.nrSeqCentroDeCusto;
      }
    }

    if (data.nrSeqEmpresa) {
      obj.nrSeqEmpresa = data.nrSeqEmpresa;
    }

    const { status, message: msg, data: linhas } = await searchLinhasParada(
      obj
    );

    if (status === ResponseStatus.Success) {
      onSetMessage(status, msg);
    }

    return linhas ?? [];
  };

  return (
    <>
      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Operadora'
            required
            enabled={!data.nrSeqChip && data.flgPermiteAlterar}
            searchDataSource={onSearchOperadora}
            selectedItem={data.operadora}
            onSelectItem={(operadora: any) => {
              setData({
                ...data,
                operadora,
                nrSeqOperadoraDestino: operadora.nrSeqOperadora,
              });
            }}
            dataSourceTextProperty='noOperadora'
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Switch
            formControl
            disabled={!(!data.nrSeqChip && data.flgPermiteAlterar)}
            label='Buscar Linha no Estoque?'
            checked={switchState}
            onChange={(choise: boolean) => setSwitchState(choise)}
            tooltip='Desmarque esta opção apenas se não quiser usar uma linha do estoque padrão'
          />
        </div>
        {!switchState && (
          <div className='col'>
            <Autocomplete
              label='Centro de Custo Origem'
              enabled={!data.nrSeqChip && data.flgPermiteAlterar}
              placeholder=''
              searchDataSource={onSearchCentroDeCusto}
              selectedItem={centroDeCustoOrigem}
              onSelectItem={(centroDeCusto: any) => {
                setCentroDeCustoOrigem(centroDeCusto);
              }}
              dataSourceTextProperty='noCentroDeCusto'
            />
          </div>
        )}
      </div>
      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Nr Linha em Estoque'
            required
            enabled={data.flgPermiteAlterar}
            searchDataSource={onSearchLinhasEstoque}
            selectedItem={data.chip}
            onSelectItem={(chip: any) => {
              setData({
                ...data,
                chip,
                nrSeqChip: chip.nrSeqChip,
              });
            }}
            dataSourceTextProperty='linhaFormatada'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Centro de Custo Destino'
            required
            enabled={data.flgPermiteAlterar}
            searchDataSource={onSearchCentroDeCusto}
            selectedItem={data.centroDeCusto}
            onSelectItem={(centroDeCusto: any) => {
              setData({
                ...data,
                centroDeCusto,
                nrSeqCentroDeCusto: centroDeCusto.nrSeqCentroDeCusto,
              });
            }}
            dataSourceTextProperty='noCentroDeCusto'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <Textarea
            label='Observação'
            maxLength={2000}
            readOnly={!data.flgPermiteAlterar}
            rows={5}
            text={data.noObservacao}
            onChangedValue={(noObservacao: string) => {
              setData({ ...data, noObservacao });
            }}
          />
        </div>
      </div>
    </>
  );
}
