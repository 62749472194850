import React from 'react';

export default function MenuTransacao({
  transacao,
  filteredText,
  onOpenTransaction,
}) {
  let { noTransacao } = transacao;

  if (filteredText) {
    const regEx = new RegExp(filteredText.toLowerCase(), 'gi').exec(
      noTransacao
    );

    noTransacao = noTransacao.replaceAll(
      regEx,
      `<span class='highlight'>${regEx}</span>`
    );
  }

  return (
    <li role='presentation' onClick={() => onOpenTransaction(transacao)}>
      <div
        className='menu-item'
        dangerouslySetInnerHTML={{ __html: noTransacao }}
      />
    </li>
  );
}
