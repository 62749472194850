import qs from 'qs';
import Pagination from 'core/models/pagination';
import AverbadoraSeguro from 'core/models/FRO/averbadoraSeguro';
import api from '../api';

const baseURL = `/FRO/AverbadoraSeguro`;

export const getAverbadoraSeguroList = async (filters) => {
  const response = await api.get(`/$AverbadoraSeguro/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AverbadoraSeguro(json)) ?? [],
  };
};

export const getAverbadoraSeguroPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AverbadoraSeguro(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getAverbadoraSeguro = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new AverbadoraSeguro(value) : {};
};

export const saveAverbadoraSeguro = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteAverbadoraSeguro = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printAverbadoraSeguro = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelAverbadoraSeguro = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllAverbadoraSeguro = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getAverbadoraSeguroAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/SearchAutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AverbadoraSeguro(json)) ?? [],
  };
};
