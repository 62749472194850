export default class Almoxarifado {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.cdAlmoxarifado = jsonObject.cdAlmoxarifado;
    this.flgEstoqueNegativo = jsonObject.flgEstoqueNegativo;
    this.noAlmoxarifado = jsonObject.noAlmoxarifado;
    this.nrSeqAlmoxarifado = jsonObject.nrSeqAlmoxarifado;
    this.flgCadAutomatico = jsonObject.flgCadAutomatico;

    // #region Propriedades auxiliares
    this.nrSeqAlmoxarifadoNew = jsonObject.nrSeqAlmoxarifadoNew;
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
