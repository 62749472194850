import Banco from './banco';

export default class PessoaInformacaoBancaria {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.nrSeqBanco = jsonObject.nrSeqBanco;
    this.cdAgencia = jsonObject.cdAgencia;
    this.cdContaCorrente = jsonObject.cdContaCorrente;
    this.tpContaCorrente = jsonObject.tpContaCorrente ?? 'C';
    this.nrSeqPessoaInformacaoBancaria =
      jsonObject.nrSeqPessoaInformacaoBancaria;
    this.dtCad = jsonObject.dtCad;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.dtAlt = jsonObject.dtAlt;
    this.nrSeqUsuarioAlt = jsonObject.nrSeqUsuarioAlt;
    this.banco = new Banco(jsonObject.banco ?? {});
    this.tpPix = jsonObject.tpPix;
    this.cdPix = jsonObject.cdPix;
    this.noDescricao = jsonObject.noDescricao;
  }

  toJson = () => JSON.stringify(this);
}
