import HoraPortabilidade from './horaPortabilidade';

export default class HoraAgendamentoPortabilidade {
  constructor(jsonObject = {}) {
    this.horarios =
      jsonObject.horarios?.map((json) => new HoraPortabilidade(json)) ?? [];
    this.dtPortabilidade = jsonObject.dtPortabilidade;
  }

  toJson = () => JSON.stringify(this);
}
