import BoletoApi from 'core/models/FIN/boletoApi';
import { MimeTypes } from 'ui/Helpers/utils';
import api from '../api';

const baseURL = 'FIN/BoletoAPI';

export const getBoletoApiList = async (filters) => {
  const response = await api.get(`${baseURL}/SearchBoletoAPI`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new BoletoApi(json)) ?? [],
  };
};

export const postReenviarBoletoApiList = async (data) => {
  const response = await api.post(`${baseURL}/ReenviarBoletosAPI`, data);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new BoletoApi(json)) ?? [],
  };
};

export const getOcorrenciaBoletoApiList = async (filters) => {
  const response = await api.get(`${baseURL}/SerchOcorrenciaBoletosApi`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new BoletoApi(json)) ?? [],
  };
};

export const postUploadBaixaTitulo = async (file, data) => {
  const formData = new FormData();
  if (file) {
    formData.append('file', file);
    formData.append('extension', MimeTypes.getExtension(file.type));
  }

  formData.append('nrSeqFormaPagamento', data.nrSeqFormaPagamento);

  const response = await api.post(
    `${baseURL}/BaixaTitulosLiquidados`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  return response.data ?? {};
};
