import api from '../api';

const baseURL = `/FIN/DemonstrativoFinanceiro`;

export const getDemonstrativoFinanceiro = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });

  return response.data ?? {};
};
