import React, { useEffect, useState } from 'react';
import { Button, Modal, Autocomplete, Notification } from 'ui/components';
import {
  Theme,
  BootstrapSizes,
  formatNumber,
  formatDate,
  ResponseStatus,
} from 'ui/Helpers/utils';

import { manifestDocument } from 'core/services/COP/nfeimportada.tsx';
import { getManifestEventAutoComplete } from 'core/services/VEN/sefazNfe';

export default function modalManifestarNFE(props) {
  const { data } = props;
  const [evento, setEvento] = useState({});
  const [message, setMessage] = useState(null);

  useEffect(() => {}, [data]);
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  const onSend = async () => {
    const { status, message: msg } = await manifestDocument({
      nrSeqNfeImportada: data.nrSeqNfeImportada,
      tpEvento: evento.tpEvento,
    });
    if (msg) onSetMessage(status, msg);
  };

  const onSearchManifestEvent = async () => {
    const {
      status,
      message: msg,
      data: eventos,
    } = await getManifestEventAutoComplete({});
    if (msg) onSetMessage(status, msg);
    return eventos;
  };

  const { show, onClose } = props;
  return (
    <Modal
      show={show}
      title='Manifestar Nota Fiscal Eletrônica'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-4'>
          <div className='col-8'>
            <Autocomplete
              label='Tipo Manifestação'
              searchDataSource={onSearchManifestEvent}
              selectedItem={evento}
              onSelectItem={(dataEvento) => {
                setEvento(dataEvento);
              }}
              dataSourceTextProperty='noDescricao'
            />
          </div>
        </div>

        <div className='row  mb-1'>
          <div className='col-2'>
            <strong>Tipo Evento</strong>
          </div>
          <div className='col-5'>
            <span>{data.nfeImportadaTipo.noNfeImportadaTipo}</span>
          </div>

          <div className='col-1'>
            <strong>Empresa</strong>
          </div>
          <div className='col-4'>
            <span>{data.empresa.noPessoa}</span>
          </div>
        </div>

        <div className='row mb-1'>
          <div className='col-2'>
            <strong>Chave Eletrônica</strong>
          </div>
          <div className='col-5'>
            <span>{data.chNfe}</span>
          </div>

          <div className='col-1'>
            <strong>NSU</strong>
          </div>
          <div className='col-4'>
            <span>{data.nonsu}</span>
          </div>
        </div>

        <div className='row mb-1'>
          <div className='col-2'>
            <strong>Fornecedor</strong>
          </div>
          <div className='col-5'>
            <span>{data.noFornecedor}</span>
          </div>
          <div className='col-1'>
            <strong>Cadastro</strong>
          </div>
          <div className='col-3'>
            <span>{formatDate(data.dtCad)}</span>
          </div>
        </div>

        <div className='row mb-1'>
          <div className='col-2'>
            <strong>Valor</strong>
          </div>
          <div className='col-5'>
            <span>{formatNumber(data.vlrNfe)}</span>
          </div>

          <div className='col-1'>
            <strong>Documento</strong>
          </div>
          <div className='col-4'>
            <span>{data.nrDocumento}</span>
          </div>
        </div>

        <div className='row mb-1'>
          <div className='col-2'>
            <strong>Usuário</strong>
          </div>
          <div className='col-5'>
            <span>{data.usuario.noLogin}</span>
          </div>

          <div className='col-1'>
            <strong>Pend(Manif)</strong>
          </div>
          <div className='col-3'>
            <span>{data.flgPendenteManifestacao ? 'Sim' : 'Não'}</span>
          </div>
        </div>

        <div className='row mb-1'>
          <div className='col-2'>
            <strong>Dt Evento</strong>
          </div>
          <div className='col-9'>
            <span>{formatDate(data.dtNfe)}</span>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button text='Fechar' theme={Theme.Danger} onClick={onClose} />
        <Button text='Manifestar' theme={Theme.Success} onClick={onSend} />
      </Modal.Footer>
    </Modal>
  );
}
