import React from 'react';

export const CSDBasePageContext = React.createContext({});

const CSDBasePage = (props) => {
  const { page, children } = props;

  return (
    <CSDBasePageContext.Provider value={page}>
      {children}
    </CSDBasePageContext.Provider>
  );
};

export default CSDBasePage;
