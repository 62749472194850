// #region Imports
import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  DatePicker,
  Panel,
  GridView,
  Textarea,
  Button,
  ControlTires,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getManutencaoVeiculo,
  saveManutencaoVeiculo,
  deleteManutencaoVeiculo,
  printManutencaoVeiculo,
  getUsuario,
} from 'core/services/FRO/manutencaoVeiculo';
import {
  getVeiculoAutoComplete,
  getVeiculo,
  getVeiculoPorVinculoAutoComplete,
} from 'core/services/FRO/veiculo';
import { getProdutoAgrupadorAutoCompleteDetalhado } from 'core/services/EST/produtoAgrupador';
import { getOficinaAutocomplete } from 'core/services/FRO/oficina';
import { getManutencaoVeiculoOSAutoComplete } from 'core/services/FRO/manutencaoVeiculoOS';
// import { getNfAutoComplete } from 'core/services/COP/nf';
import { getVeiculoManutencaoPreventivaPendentesByVeiculo } from 'core/services/FRO/veiculo_ManutencaoPreventiva';
import ManutencaoVeiculoPlaca from 'core/models/FRO/manutencaoVeiculoPlaca';
import ManutencaoVeiculo from 'core/models/FRO/manutencaoVeiculo';
import { getAlmoxarifadoAutoComplete } from 'core/services/EST/almoxarifado';
import {
  getAlmoxarifadoLocalAutoComplete,
  getBalance,
  getCusto,
} from 'core/services/EST/almoxarifadoLocal';
import { getFuncionarioAutoComplete } from 'core/services/SEG/funcionario';
import { getProdutoAutoCompleteByCodigos } from 'core/services/EST/produto';
// import { getNfItemListForManutencaoVeiculo } from 'core/services/COP/nfItem';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN';
import ManutencaoVeiculoProduto from 'core/models/FRO/manutencaoVeiculo_Produto';
import ManutencaoVeiculoFatura from 'core/models/FRO/manutencaoVeiculo_Fatura';
import { getUltimoAbastecimento } from 'core/services/FRO/abastecimento';
import { getProdutoAgrupadorManutencao } from 'core/services/EST/produtoAgrupadorManutencao';
import { getManutencaoVeiculoFaturaList } from 'core/services/FRO/manutencaoVeiculo_Fatura';
import { getManutencaoVeiculoProdutoList } from 'core/services/FRO/manutencaoVeiculo_Produto';
// #endregion

export default function ManutencaoVeiculoItem({
  registryKey,
  reload,
  onSelectPage,
  onOpenReport,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [dataVeiculo, setDataVeiculo] = useState({});
  const [dataVeiculoAux, setDataVeiculoAux] = useState({});
  const [dataManutencaoVeiculoOS, setDataManutencaoVeiculoOS] = useState({});
  const [dataPneuVeiculo, setDataPneuVeiculo] = useState({});
  const [dataPecas, setDataPecas] = useState({});
  const [dataFatura, setDataFatura] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const gridViewVeiculos = useRef();
  const gridViewManutencaoPreventivaOnNew = useRef();
  const gridViewManutencaoPreventiva = useRef();
  const gridViewPecas = useRef();
  const gridViewManutencaoVeiculosOS = useRef();
  const gridViewFaturas = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const atualizaVlrTotal = (listaProdutos) => {
    let valorAtual = 0.0;

    listaProdutos.forEach((produto) => {
      valorAtual += produto.vlrTotal;
    });

    return valorAtual;
  };

  const limparForm = async () => {
    const usuario = await getUsuario(1);

    const manutencaoVeiculo = new ManutencaoVeiculo();

    if (gridViewVeiculos && gridViewVeiculos.current) {
      gridViewVeiculos.current.setDataSource(manutencaoVeiculo.placas);
    }

    if (gridViewManutencaoPreventiva && gridViewManutencaoPreventiva.current) {
      gridViewManutencaoPreventiva.current.setDataSource(
        manutencaoVeiculo.veiculoManutencaoPreventivas
      );
    }

    if (gridViewManutencaoVeiculosOS && gridViewManutencaoVeiculosOS.current)
      gridViewManutencaoVeiculosOS.current.setDataSource(
        manutencaoVeiculo.manutencaoVeiculosOS
      );

    if (gridViewFaturas && gridViewFaturas.current) {
      gridViewFaturas.current.setDataSource(
        manutencaoVeiculo.manutencaoVeiculoFaturas
      );
    }

    if (gridViewPecas && gridViewPecas.current) {
      gridViewPecas.current.setDataSource(manutencaoVeiculo.produtos);
    }

    return setData({
      ...manutencaoVeiculo,
      usuario: usuario.usuario,
      empresa: usuario.empresa,
      nrSeqUsuarioCad: usuario.nrSeqUsuarioCad,
      noObs: '',
    });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const manutencaoVeiculo = await getManutencaoVeiculo(registryKey);
      const usuario = await getUsuario(1);

      if (gridViewVeiculos && gridViewVeiculos.current) {
        gridViewVeiculos.current.setDataSource(manutencaoVeiculo.placas);
      }

      if (
        gridViewManutencaoPreventiva &&
        gridViewManutencaoPreventiva.current
      ) {
        gridViewManutencaoPreventiva.current.setDataSource(
          manutencaoVeiculo.veiculoManutencaoPreventivas
        );
      }

      if (manutencaoVeiculo.manutencaoVeiculoOS.nrSeqManutencaoVeiculoOS) {
        const arrayOS = [manutencaoVeiculo.manutencaoVeiculoOS];

        if (
          gridViewManutencaoVeiculosOS &&
          gridViewManutencaoVeiculosOS.current
        )
          gridViewManutencaoVeiculosOS.current.setDataSource(arrayOS);
      }

      const { data: faturas } = await getManutencaoVeiculoFaturaList({
        nrSeqManutencaoVeiculo: manutencaoVeiculo.nrSeqManutencaoVeiculo,
      });

      if (faturas) {
        manutencaoVeiculo.manutencaoVeiculoFaturas = faturas;

        if (gridViewFaturas && gridViewFaturas.current) {
          gridViewFaturas.current.setDataSource(faturas);
        }
      }

      if (gridViewFaturas && gridViewFaturas.current) {
        gridViewFaturas.current.setDataSource(
          manutencaoVeiculo.manutencaoVeiculoFaturas
        );
      }

      if (gridViewPecas && gridViewPecas.current) {
        gridViewPecas.current.setDataSource(manutencaoVeiculo.produtos);
      }

      if (manutencaoVeiculo) {
        if (data.nrSeqNf !== null) {
          data.serieForNf = manutencaoVeiculo.nf.serieForNf;
        }

        const produtosList = manutencaoVeiculo.produtos;
        const controlaPneu = produtosList.filter(
          (item) => item.produtoAgrupador.produtoGrupo.flgControlaPneu === true
        );

        if (controlaPneu.length > 0) {
          data.vinculaPneuBtnOn = true;
          setData({
            ...manutencaoVeiculo,
            ...data,
            controlaPneu,
            usuario: usuario.usuario,
            nrSeqEmpresa: usuario.empresa.nrSeqEmpresa,
          });
        } else {
          setData({
            ...manutencaoVeiculo,
            usuario: usuario.usuario,
            nrSeqEmpresa: usuario.nrSeqEmpresa,
          });
        }
      }

      setLoading(false);
    } else {
      const usuario = await getUsuario(1);

      const manutencao = new ManutencaoVeiculo();

      setData({
        ...manutencao,
        usuario: usuario.usuario,
        empresa: usuario.empresa,
        nrSeqUsuarioCad: usuario.nrSeqUsuarioCad,
        nrSeqEmpresa: usuario.nrSeqEmpresa,
      });

      setMessage(null);
    }
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);

    if (data.placas.length > 0) {
      const selecteds = gridViewManutencaoPreventivaOnNew.current.getCheckBoxValuesAt(
        0
      );

      selecteds.forEach((item) => {
        const nrSeqItem = item[0].value;

        const itemFounded = data.veiculoManutencaoPreventivas.find(
          (manutencaoPreventiva) =>
            manutencaoPreventiva.nrSeqVeiculo_ManutencaoPrevent === nrSeqItem
        );

        itemFounded.status = 'Afetado';
      });
    }

    const {
      status,
      message: msg,
      value: manutencaoVeiculo,
    } = await saveManutencaoVeiculo(data);

    if (manutencaoVeiculo) {
      const produtosList = manutencaoVeiculo.produtos;
      const controlaPneu = produtosList.filter(
        (item) => item.produtoAgrupador.produtoGrupo.flgControlaPneu === true
      );

      if (controlaPneu.length > 0) {
        data.vinculaPneuPanelOn = true;
        setData({ ...data, controlaPneu });
      }
    }

    // setData(new ManutencaoVeiculo({ ...data }));

    onSetMessage(status, msg);

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteManutencaoVeiculo(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const vinculaPneu = async () => {
    if (data.vinculaPneuPanelOn) {
      setData({ ...data, vinculaPneuPanelOn: false });
    } else {
      setData({ ...data, vinculaPneuPanelOn: true });
    }

    return data;
  };

  const onSearchOficina = async (e) => {
    const { status, message: msg, oficinas } = await getOficinaAutocomplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);
    return oficinas;
  };

  const onSearchVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
      nrSeqGrupoEmpresa: data.empresa.nrSeqGrupoEmpresa,
    });

    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onSearchVeiculoFromManutencaoVeiculo = async () => {
    const veiculos = data.placas.map((item) => item.veiculo);

    return veiculos;
  };

  const onSearchPneuVeiculo = async () => {
    // const veiculos = data.controlaPneu.map((item) => item.veiculo);
    const produtosVeiculos = data.controlaPneu;

    return produtosVeiculos;
  };

  const onSearchOrdemServico = async () => {
    const {
      status,
      message: msg,
      data: manutencaoVeiculoOS,
    } = await getManutencaoVeiculoOSAutoComplete({
      nrSeqPessoaOfi: data.oficina.nrSeqPessoaOfi,
      flgConcluido: false,
      nrSeqVeiculo: data.nrSeqVeiculo,
    });

    if (msg) onSetMessage(status, msg);
    return manutencaoVeiculoOS;
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
    });

    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const onSearchProdutoAgrupador = async (e) => {
    const {
      status,
      message: msg,
      data: produtoAgrupador,
    } = await getProdutoAgrupadorAutoCompleteDetalhado({
      noProduto: e,
    });

    if (msg) onSetMessage(status, msg);
    return produtoAgrupador;
  };

  const onSearchProdutos = async (nrSeqProdutoAgrupador) => {
    const {
      status,
      message: msg,
      produtos,
    } = await getProdutoAutoCompleteByCodigos({
      nrSeqProdutoAgrupador,
    });

    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const onSearchAlmoxarifado = async (nrSeqProduto) => {
    const { data: almoxarifados } = await getAlmoxarifadoAutoComplete({
      nrSeqProduto,
    });

    return almoxarifados;
  };

  const onSearchAlmoxarifadoLocal = async (nrSeqProduto, nrSeqAlmoxarifado) => {
    const {
      status,
      message: msg,
      almoxarifados,
    } = await getAlmoxarifadoLocalAutoComplete({
      nrSeqProduto,
      nrSeqAlmoxarifado,
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifados;
  };

  const onSearchFuncionario = async (e) => {
    const {
      status,
      message: msg,
      funcionarios,
    } = await getFuncionarioAutoComplete({
      nrSeqPessoaFun: e,
    });

    if (msg) onSetMessage(status, msg);
    return funcionarios;
  };

  const onPrint = async () => {
    setLoading(true);

    const { value } = await printManutencaoVeiculo({
      nrSeqManutencaoVeiculo: data.nrSeqManutencaoVeiculo,
    });

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onClickEditVeiculo = async (selectedValue, datasource) => {
    const obj = datasource.find((item) => item === selectedValue);
    if (obj.status !== 'Inserir') {
      obj.status = GridView.EnumStatus.Alterar;
    }

    setDataVeiculoAux({ ...obj });

    const veiculos = datasource.filter((veiculo) => veiculo !== selectedValue);

    if (gridViewVeiculos && gridViewVeiculos.current)
      gridViewVeiculos.current.setDataSource(veiculos);

    const veiculoFound = await getVeiculo(obj.nrSeqVeiculo);

    setDataVeiculo({
      veiculo: veiculoFound,
      nrOdometro: obj.nrOdometro,
      placa: veiculoFound.placa,
      nrSeqVeiculo: obj.nrSeqVeiculo,
      ultOdometro: obj.ultOdometro,
      ultAbastecimento: obj.ultAbastecimento,
      status: obj.status,
    });
  };

  const onClickRemoveVeiculo = async (selectedValue, datasource) => {
    const itemRemover = datasource.find((item) => item === selectedValue);
    const veiculos = datasource.filter((veiculo) => veiculo !== selectedValue);

    itemRemover.status = GridView.EnumStatus.Remover;
    veiculos.push(itemRemover);

    if (gridViewVeiculos && gridViewVeiculos.current) {
      gridViewVeiculos.current.setDataSource(veiculos);
    }
  };

  const onSearchPreventivas = async (dataGeneral, veiculo) => {
    const {
      data: manutencaoPreventiva,
    } = await getVeiculoManutencaoPreventivaPendentesByVeiculo(
      veiculo.nrSeqVeiculo,
      veiculo.nrOdometro,
      data.dtDocumento
    );

    manutencaoPreventiva.forEach((preventiva) => {
      if (preventiva.manutencaoPreventiva.tipoIntervalo.flgTipo === false) {
        preventiva.odometroRestanteManutencao = `${preventiva.odometroRestanteManutencao} KM`;
      } else {
        preventiva.odometroRestanteManutencao = `${preventiva.odometroRestanteManutencao} dias`;
      }

      const found = dataGeneral.veiculoManutencaoPreventivas.find(
        (item) =>
          item.nrSeqVeiculo_ManutencaoPrevent ===
          preventiva.nrSeqVeiculo_ManutencaoPrevent
      );

      if (!found) {
        data.veiculoManutencaoPreventivas.push(preventiva);
      }
    });

    setData({ ...dataGeneral, flgGridPreventiva: true });

    if (
      gridViewManutencaoPreventivaOnNew &&
      gridViewManutencaoPreventivaOnNew.current
    ) {
      gridViewManutencaoPreventivaOnNew.current.setDataSource(
        data.veiculoManutencaoPreventivas
      );
    }

    return dataGeneral;
  };

  const onAdicionaVeiculo = async () => {
    if (dataVeiculo.veiculo.nrSeqVeiculo != null) {
      setLoading(true);

      data.nrSeqVeiculo = dataVeiculo.veiculo.nrSeqVeiculo;

      if (dataVeiculo.status === null || dataVeiculo.status === undefined) {
        const novoManutencaoVeiculoPlaca = new ManutencaoVeiculoPlaca({
          veiculo: dataVeiculo.veiculo,
          nrSeqVeiculo: dataVeiculo.nrSeqVeiculo,
          nrOdometro: dataVeiculo.nrOdometro,
          nrSeqManutencaoVeiculo: data.nrSeqManutencaoVeiculo,
          ultOdometro: dataVeiculo.ultOdometro,
          ultAbastecimento: dataVeiculo.ultAbastecimento,
          status: GridView.EnumStatus.Inserir,
        });

        data.placas.push(novoManutencaoVeiculoPlaca);
      }

      if (
        dataVeiculo.status === 'Alterar' ||
        dataVeiculo.status === 'Inserir'
      ) {
        const obj = data.placas.find(
          (item) => item.nrSeqVeiculo === dataVeiculoAux.nrSeqVeiculo
        );
        obj.veiculo = dataVeiculo.veiculo;
        obj.nrSeqVeiculo = dataVeiculo.nrSeqVeiculo;
        obj.nrOdometro = dataVeiculo.nrOdometro;
        obj.ultOdometro = dataVeiculo.ultOdometro;
        obj.ultAbastecimento = dataVeiculo.ultAbastecimento;
      }

      setDataVeiculo({});

      if (gridViewVeiculos && gridViewVeiculos.current) {
        gridViewVeiculos.current.setDataSource(data.placas);
      }

      onSearchPreventivas(data, dataVeiculo);

      setData({ ...data });

      setLoading(false);
    }
  };

  const onAdicionaVinculados = async () => {
    setLoading(true);

    if (dataVeiculo.placa !== null) {
      if (dataVeiculo.status === null || dataVeiculo.status === undefined) {
        const novoManutencaoVeiculoPlaca = new ManutencaoVeiculoPlaca({
          veiculo: dataVeiculo.veiculo,
          nrSeqVeiculo: dataVeiculo.nrSeqVeiculo,
          nrOdometro: dataVeiculo.nrOdometro,
          nrSeqManutencaoVeiculo: data.nrSeqManutencaoVeiculo,
          ultOdometro: dataVeiculo.ultOdometro,
          ultAbastecimento: dataVeiculo.ultAbastecimento,
          status: GridView.EnumStatus.Inserir,
        });

        data.placas.push(novoManutencaoVeiculoPlaca);
      }

      if (
        dataVeiculo.status === 'Alterar' ||
        dataVeiculo.status === 'Inserir'
      ) {
        const obj = data.placas.find(
          (item) => item.nrSeqVeiculo === dataVeiculoAux.nrSeqVeiculo
        );
        obj.veiculo = dataVeiculo.veiculo;
        obj.nrSeqVeiculo = dataVeiculo.nrSeqVeiculo;
        obj.nrOdometro = dataVeiculo.nrOdometro;
        obj.ultOdometro = dataVeiculo.ultOdometro;
        obj.ultAbastecimento = dataVeiculo.ultAbastecimento;
      }

      setDataVeiculo({});

      onSearchPreventivas(data, dataVeiculo);
    }

    const vinculados = await getVeiculoPorVinculoAutoComplete({
      nrSeqVeiculo: dataVeiculo.nrSeqVeiculo,
    });

    if (vinculados.veiculos.length > 0) {
      const listaVinculados = vinculados.veiculos;

      for (let i = 0; i < listaVinculados.length; i += 1) {
        const novoManutencaoVeiculoPlaca = new ManutencaoVeiculoPlaca({
          veiculo: listaVinculados[i],
          placa: listaVinculados[i].placa,
          nrSeqVeiculo: listaVinculados[i].nrSeqVeiculo,
          nrOdometro: listaVinculados[i].nrOdometro,
          nrSeqManutencaoVeiculo: data.nrSeqManutencaoVeiculo,
          ultOdometro: listaVinculados[i].ultOdometro,
          ultAbastecimento: listaVinculados[i].ultAbastecimento,
          status: GridView.EnumStatus.Inserir,
        });

        onSearchPreventivas(data, listaVinculados[i]);

        data.placas.push(novoManutencaoVeiculoPlaca);
      }
    }

    if (gridViewVeiculos && gridViewVeiculos.current) {
      gridViewVeiculos.current.setDataSource(data.placas);
    }

    setLoading(false);
  };

  const onClickRemoveOrdemServico = async (selectedValue, datasource) => {
    setLoading(true);

    const arrayOS = datasource.filter((os) => os !== selectedValue);
    data.manutencaoVeiculoOS = [];

    if (gridViewManutencaoVeiculosOS && gridViewManutencaoVeiculosOS.current) {
      gridViewManutencaoVeiculosOS.current.setDataSource(arrayOS);
    }

    setData({ ...data });

    setLoading(false);
  };

  const onAdicionaOrdemServico = async () => {
    if (data.manutencaoVeiculoOS.nrSeqManutencaoVeiculoOS) {
      setMessage('Já existe uma Ordem de Serviço adicionada.', 2);
    } else if (dataManutencaoVeiculoOS.nrSeqManutencaoVeiculoOS) {
      setLoading(true);

      data.manutencaoVeiculoOS = {
        ...dataManutencaoVeiculoOS.manutencaoVeiculoOS,
      };

      const arrayOS = [dataManutencaoVeiculoOS.manutencaoVeiculoOS];
      if (
        gridViewManutencaoVeiculosOS &&
        gridViewManutencaoVeiculosOS.current
      ) {
        gridViewManutencaoVeiculosOS.current.setDataSource(arrayOS);
      }

      const { data: produtos } = await getManutencaoVeiculoProdutoList({
        nrSeqManutencaoVeiculo:
          dataManutencaoVeiculoOS.manutencaoVeiculoOS.nrSeqManutencaoVeiculo,
      });
      if (produtos) {
        produtos.forEach((item) => data.produtos.push(item));

        if (gridViewPecas && gridViewPecas.current) {
          gridViewPecas.current.setDataSource(data.produtos);
        }
      }

      setDataManutencaoVeiculoOS({});
      setData({ ...data });

      setLoading(false);
    }
  };

  const onClickEditFatura = async (selectedValue, datasource) => {
    const obj = datasource.find((item) => item === selectedValue);
    obj.status = GridView.EnumStatus.Alterar;

    setDataFatura({
      ...obj,
    });

    // setDataFatura({
    //   ...obj,
    //   fatura: new ManutencaoVeiculoFatura({ nrSeq: obj.nrSeq }),
    // });

    const faturas = datasource.filter((peca) => peca !== selectedValue);

    if (gridViewFaturas && gridViewFaturas.current) {
      gridViewFaturas.current.setDataSource(faturas);
    }
  };

  const onClickRemoveFatura = async (selectedValue, datasource) => {
    const itemRemover = datasource.find((item) => item === selectedValue);
    if (itemRemover.nrSeqManutencaoVeiculo > 0) {
      datasource.find((item) => item === selectedValue).status =
        GridView.EnumStatus.Remover;

      if (gridViewFaturas && gridViewFaturas.current) {
        gridViewFaturas.current.setDataSource(datasource);
      }
    } else {
      const faturas = datasource.filter((peca) => peca !== selectedValue);

      const index1 = datasource.indexOf(itemRemover);
      datasource.splice(index1, 1);

      if (gridViewFaturas && gridViewFaturas.current) {
        gridViewFaturas.current.setDataSource(faturas);
      }
    }
  };

  const onAdicionaFatura = async () => {
    if (
      dataFatura.dtVencimento != null ||
      dataFatura.vlrParcela != null ||
      dataFatura.nrSeqFormaPagamento != null
    ) {
      setLoading(true);

      let nrSeqAtual = data.manutencaoVeiculoFaturas.length;

      if (nrSeqAtual > 0) {
        nrSeqAtual += 1;
      } else {
        nrSeqAtual = 1;
      }

      if (dataFatura.status === null || dataFatura.status === undefined) {
        const newFatura = new ManutencaoVeiculoFatura({
          nrSeq: nrSeqAtual,
          dtVencimento: dataFatura.dtVencimento,
          formaPagamento: dataFatura.formaPagamento,
          nrSeqManutencaoVeiculo: data.nrSeqManutencaoVeiculo,
          vlrParcela: dataFatura.vlrParcela,
          nrSeqFormaPagamento: dataFatura.nrSeqFormaPagamento,
          status: 'Inserir',
        });

        data.manutencaoVeiculoFaturas.push(newFatura);
      }

      setDataFatura({});

      if (gridViewFaturas && gridViewFaturas.current)
        gridViewFaturas.current.setDataSource(data.manutencaoVeiculoFaturas);

      setLoading(false);
    }
  };

  const onClickEditPecas = async (selectedValue, datasource) => {
    const obj = datasource.find((item) => item === selectedValue);
    if (obj.status === 'Inserir') {
      obj.status = GridView.EnumStatus.Inserir;
    } else {
      obj.status = GridView.EnumStatus.Alterar;
    }

    const pecas = datasource.filter((peca) => peca !== selectedValue);

    if (gridViewPecas && gridViewPecas.current) {
      gridViewPecas.current.setDataSource(pecas);
    }

    const valorTotal = atualizaVlrTotal(pecas);
    setData({ ...data, vlrTotal: valorTotal });

    setDataPecas({ ...obj });
  };

  const onClickRemovePecas = async (selectedValue, datasource) => {
    const itemRemover = datasource.find((item) => item === selectedValue);

    if (itemRemover.nrSeqManutencaoVeiculoItem > 0)
      datasource.find((item) => item === selectedValue).status =
        GridView.EnumStatus.Remover;
    else {
      const index = datasource.indexOf(itemRemover);
      datasource.splice(index, 1);
      data.produtos = datasource;
    }
    if (gridViewPecas && gridViewPecas.current) {
      gridViewPecas.current.setDataSource(datasource);
    }

    const valorTotal = atualizaVlrTotal(datasource);
    setData({ ...data, vlrTotal: valorTotal });
  };

  const afetaManutencaoPreventiva = async (produtoAgrupador) => {
    const afetaManutencao = await getProdutoAgrupadorManutencao(
      produtoAgrupador.nrSeqProdutoAgrupador
    );

    if (afetaManutencao) {
      const found = data.veiculoManutencaoPreventivas.find(
        (item) =>
          item.manutencaoPreventiva.nrSeqManutencaoPreventiva ===
          afetaManutencao.nrSeqManutencaoPreventiva
      );

      if (found) {
        const grid = gridViewManutencaoPreventivaOnNew.current.getBuildedDataSource();
        grid.forEach((item) => {
          if (item[0].value === found.nrSeqVeiculo_ManutencaoPrevent) {
            item[0].state = true;
          }
        });
      }
    }
  };

  const onAdicionaPecas = async () => {
    if (dataPecas.nrSeqProduto != null) {
      setLoading(true);

      if (dataPecas.status == null) {
        const valorTotalProduto =
          dataPecas.quantidade * dataPecas.vlrUnitario - dataPecas.vlrDesconto;

        const novoManutencaoVeiculoProduto = new ManutencaoVeiculoProduto({
          ...dataPecas,
          vlrTotal: valorTotalProduto,
          nrSeqUsuarioCad: data.nrSeqUsuarioCad,
          usuario: data.usuario,
        });

        data.produtos.push(novoManutencaoVeiculoProduto);
        afetaManutencaoPreventiva(
          novoManutencaoVeiculoProduto.produtoAgrupador
        );
      }

      if (dataPecas.status === 'Alterar' || dataPecas.status === 'Inserir') {
        const obj = data.produtos.find(
          (item) => item.nrSeqProduto === dataPecas.nrSeqProduto
        );

        const valorTotalProduto =
          dataPecas.quantidade * dataPecas.vlrUnitario - dataPecas.vlrDesconto;

        if (data.nrSeqPessoaOfi > 0 && data.oficina.flgControlaEstoque) {
          obj.almoxarifado = dataPecas.almoxarifado;
          obj.almoxarifadoLocal = dataPecas.almoxarifadoLocal;
          obj.funcionario = dataPecas.funcionario;
          obj.noPessoa = dataPecas.noPessoa;
          obj.noPessoaFun = dataPecas.noPessoaFun;
          obj.noProduto = dataPecas.produtoAgrupador.noProduto;
          obj.noUsuario = data.noUsuario;
          obj.nrSeqAlmoxarifadoLocal = dataPecas.nrSeqAlmoxarifadoLocal;
          obj.nrSeqEstoqueMovimento = dataPecas.nrSeqEstoqueMovimento;
          obj.nrSeqManutencaoVeiculo = dataPecas.nrSeqManutencaoVeiculo;
          obj.nrSeqManutencaoVeiculoItem = dataPecas.nrSeqManutencaoVeiculoItem;
          obj.nrSeqNfCompra = dataPecas.nrSeqNfCompra;
          obj.nrSeqPessoaFun = dataPecas.funcionario.nrSeqPessoaFun;
          obj.nrSeqProduto = dataPecas.nrSeqProduto;
          obj.nrSeqUsuarioCad = data.nrSeqUsuarioCad;
          obj.nrSeqVeiculo = dataPecas.nrSeqVeiculo;
          obj.veiculo = dataPecas.veiculo;
          obj.obs = dataPecas.obs;
          obj.placa = dataPecas.placa;
          obj.produto = dataPecas.produto;
          obj.produtoAgrupador = dataPecas.produtoAgrupador;
          obj.quantidade = dataPecas.quantidade;
          obj.veiculo = dataPecas.veiculo;
          obj.vlrDesconto = dataPecas.vlrDesconto;
          obj.vlrTotal = valorTotalProduto;
          obj.vlrUnitario = dataPecas.vlrUnitario;
        } else {
          obj.noPessoa = dataPecas.noPessoa;
          obj.noProduto = dataPecas.produtoAgrupador.noProduto;
          obj.noUsuario = data.noUsuario;
          obj.nrSeqManutencaoVeiculo = dataPecas.nrSeqManutencaoVeiculo;
          obj.nrSeqManutencaoVeiculoItem = dataPecas.nrSeqManutencaoVeiculoItem;
          obj.nrSeqNfCompra = dataPecas.nrSeqNfCompra;
          obj.nrSeqProduto = dataPecas.nrSeqProduto;
          obj.nrSeqUsuarioCad = data.nrSeqUsuarioCad;
          obj.nrSeqVeiculo = dataPecas.veiculo.nrSeqVeiculo;
          obj.obs = dataPecas.obs;
          obj.placa = dataPecas.placa;
          obj.produto = dataPecas.produto;
          obj.produtoAgrupador = dataPecas.produtoAgrupador;
          obj.quantidade = dataPecas.quantidade;
          obj.veiculo = dataPecas.veiculo;
          obj.vlrDesconto = dataPecas.vlrDesconto;
          obj.vlrTotal = valorTotalProduto;
          obj.vlrUnitario = dataPecas.vlrUnitario;
        }
        afetaManutencaoPreventiva(obj.produtoAgrupador);
      }

      setDataPecas(new ManutencaoVeiculoProduto({ obs: '' }));

      if (gridViewPecas && gridViewPecas.current) {
        gridViewPecas.current.setDataSource(data.produtos);
      }

      const valorTotal = atualizaVlrTotal(data.produtos);
      setData({ ...data, vlrTotal: valorTotal });

      setLoading(false);
    }
  };

  const onNew = async () => {
    setLoading(true);

    setData({});

    const usuario = await getUsuario(1);

    const manutencaoVeiculo = new ManutencaoVeiculo();

    if (gridViewVeiculos && gridViewVeiculos.current) {
      gridViewVeiculos.current.setDataSource(manutencaoVeiculo.placas);
    }

    if (gridViewManutencaoPreventiva && gridViewManutencaoPreventiva.current) {
      gridViewManutencaoPreventiva.current.setDataSource(
        manutencaoVeiculo.veiculoManutencaoPreventivas
      );
    }

    if (gridViewManutencaoVeiculosOS && gridViewManutencaoVeiculosOS.current)
      gridViewManutencaoVeiculosOS.current.setDataSource(
        manutencaoVeiculo.manutencaoVeiculosOS
      );

    if (gridViewFaturas && gridViewFaturas.current) {
      gridViewFaturas.current.setDataSource(
        manutencaoVeiculo.manutencaoVeiculoFaturas
      );
    }

    if (gridViewPecas && gridViewPecas.current) {
      gridViewPecas.current.setDataSource(manutencaoVeiculo.produtos);
    }

    setData({
      ...manutencaoVeiculo,
      usuario,
      empresa: usuario.empresa,
      nrSeqUsuarioCad: usuario.nrSeqUsuarioCad,
    });

    setLoading(false);
  };

  const preencheCamposProduto = async (produtoAgrupador) => {
    setLoading(true);

    if (produtoAgrupador.nrSeqProdutoAgrupador) {
      const produtoCodigo = await onSearchProdutos(
        produtoAgrupador.nrSeqProdutoAgrupador
      );

      if (produtoCodigo.length > 1) {
        setDataPecas({
          ...dataPecas,
          produtoAgrupador,
          nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
        });
      } else {
        const almoxarifado = await onSearchAlmoxarifado(
          produtoCodigo[0].nrSeqProduto
        );

        const almoxarifadoLocal = await onSearchAlmoxarifadoLocal(
          produtoCodigo[0].nrSeqProduto,
          almoxarifado[0].nrSeqAlmoxarifado
        );

        if (almoxarifadoLocal.length > 1) {
          setDataPecas({
            ...dataPecas,
            produtoAgrupador,
            nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
            produto: produtoCodigo[0],
            nrSeqProduto: produtoCodigo[0].nrSeqProduto,
            almoxarifado: almoxarifado[0],
            nrSeqAlmoxarifado: almoxarifado[0].nrSeqAlmoxarifado,
            noAlmoxarifado: almoxarifado[0].noAlmoxarifado,
          });
        } else {
          const saldoAtual = await getBalance(
            almoxarifadoLocal[0].nrSeqAlmoxarifadoLocal
          );

          const custoUnitario = await getCusto(
            almoxarifadoLocal[0].nrSeqAlmoxarifadoLocal,
            data.dtDocumento
          );

          setDataPecas({
            ...dataPecas,
            produtoAgrupador,
            nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
            produto: produtoCodigo[0],
            nrSeqProduto: produtoCodigo[0].nrSeqProduto,
            almoxarifado: almoxarifado[0],
            nrSeqAlmoxarifado: almoxarifado[0].nrSeqAlmoxarifado,
            noAlmoxarifado: almoxarifado[0].noAlmoxarifado,
            estoque: saldoAtual,
            vlrUnitario: custoUnitario,
            almoxarifadoLocal: almoxarifadoLocal[0],
            nrSeqAlmoxarifadoLocal: almoxarifadoLocal[0].nrSeqAlmoxarifadoLocal,
            noAlmoxarifadoLocal: almoxarifadoLocal[0].noAlmoxarifadoLocal,
          });
        }
      }
    } else {
      setDataPecas({
        veiculo: dataPecas.veiculo,
        nrSeqVeiculo: dataPecas.nrSeqVeiculo,
        placa: dataPecas.placa,
      });
    }

    setLoading(false);
  };

  const { id: idSelecao } = PageTypes.Selection;

  const columnsVeiculos = [
    { key: 'veiculo.placa', title: 'Placa' },
    { key: 'nrOdometro', title: 'Odômetro' },
    {
      key: 'nrSeqVeiculo',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditVeiculo(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqVeiculo',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveVeiculo(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsManutencaoPreventivaOnNew = [
    {
      key: 'nrSeqVeiculo_ManutencaoPrevent',
      type: GridView.ColumnTypes.Checkbox,
    },
    {
      key: 'placa',
      title: 'Placa',
    },
    {
      key: 'manutencaoPreventiva.noManutencaoPreventiva',
      title: 'Manutenção Preventiva',
    },
    { key: 'ultimoOdometroNoSistema', title: 'Último Odômetro' },
    {
      key: 'ultimaDataNoSistema',
      title: 'Data Últ. Odômetro',
      format: GridView.DataTypes.Date,
    },
    { key: 'manutencaoPreventiva.valor', title: 'Manutenção Estimada' },
    { key: 'odometroRestanteManutencao', title: 'Próxima Troca em' },
    { key: 'status', title: 'Status' },
  ];

  const columnsManutencaoPreventiva = [
    {
      key: 'placa',
      title: 'Placa',
    },
    {
      key: 'manutencaoPreventiva.noManutencaoPreventiva',
      title: 'Manutenção Preventiva',
    },
    { key: 'nrOdometro', title: 'Último Odômetro' },
    {
      key: 'dtMovimento',
      title: 'Data Últ. Odômetro',
      format: GridView.DataTypes.Date,
    },
  ];

  const columnsManutencaoVeiculosOS = [
    { key: 'nrSeqManutencaoVeiculoOS', title: 'Número da Ordem de Serviço' },
    { key: 'obsOS', title: 'Observações' },
    { key: 'nrOdometro', title: 'Odômetro' },
    {
      key: 'nrSeqManutencaoVeiculoOS',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveOrdemServico(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsFaturas = [
    { key: 'nrSeq', title: 'Sequência' },
    { key: 'vlrParcela', title: 'Valor', format: GridView.DataTypes.Money },
    { key: 'formaPagamento.noFormaPagamento', title: 'Forma de Pagamento' },
    {
      key: 'dtVencimento',
      title: 'Data de vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrSeq',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditFatura(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeq',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveFatura(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsPecas = [
    { key: 'veiculo.placa', title: 'Placa' },
    { key: 'produto.produtoCodigo.cdPeca', title: 'Cód. Peça' },
    { key: 'produtoAgrupador.noProduto', title: 'Produto Agrupador' },
    { key: 'produto.noProduto', title: 'Produto' },
    {
      key: 'vlrUnitario',
      title: 'Vlr. Unit.',
      format: GridView.DataTypes.Money,
    },
    { key: 'quantidade', title: 'Qtde' },
    { key: 'vlrDesconto', title: 'Desc.', format: GridView.DataTypes.Money },
    { key: 'vlrTotal', title: 'Total', format: GridView.DataTypes.Money },
    { key: 'funcionario.noPessoa', title: 'Funcionário' },
    { key: 'usuario.noLogin', title: 'Usuário Cad.' },
    { key: 'obs', title: 'Obs.' },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditPecas(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemovePecas(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Veículo'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      onDelete={data?.nrSeqManutencaoVeiculo > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {data.nrSeqManutencaoVeiculo && (
        <ToolbarButtons>
          <ToolbarButtons.Button text='Imprimir' onClick={() => onPrint()} />
        </ToolbarButtons>
      )}
      {!data.nrSeqManutencaoVeiculo && (
        <ToolbarButtons>
          <ToolbarButtons.Button
            text='Limpar Formulário'
            onClick={() => limparForm()}
          />
        </ToolbarButtons>
      )}

      {data.vinculaPneuBtnOn && (
        <ToolbarButtons>
          <ToolbarButtons.Button
            text='Vincular Pneus'
            onClick={() => vinculaPneu()}
          />
        </ToolbarButtons>
      )}

      {data.vinculaPneuPanelOn && (
        <Panel className='mb-3'>
          <Panel.Header title='Controle de Pneus' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row'>
              <div className='col mb-3'>
                <Autocomplete
                  label='Placa'
                  searchDataSource={onSearchPneuVeiculo}
                  selectedItem={dataPneuVeiculo.veiculo}
                  onSelectItem={async (pneuVeiculo) => {
                    setDataPneuVeiculo({
                      veiculo: pneuVeiculo.veiculo,
                      nrSeqVeiculo: pneuVeiculo.veiculo.nrSeqVeiculo,
                      nrSeqProduto: pneuVeiculo.nrSeqProduto,
                    });
                  }}
                  dataSourceTextProperty='placa'
                />
              </div>

              {dataPneuVeiculo.nrSeqVeiculo ? (
                <div className='col-10 '>
                  <ControlTires
                    nrSeqVeiculo={dataPneuVeiculo.nrSeqVeiculo}
                    nrSeqProduto={dataPneuVeiculo.nrSeqProduto}
                    onMessage={onSetMessage}
                    onSetLoading={setLoading}
                  />
                </div>
              ) : (
                <div className='col-10 '> </div>
              )}
            </div>
          </Panel.Body>
        </Panel>
      )}

      <Panel>
        <Panel.Header title='Principal' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row'>
            <div className='col-3 mb-3'>
              <Textbox
                label='Código'
                maxLength={20}
                readOnly
                text={data.cdManutencaoVeiculo}
                onChangedValue={(item) =>
                  setData({
                    ...data,
                    cdManutencaoVeiculo: item.cdManutencaoVeiculo,
                  })
                }
              />
            </div>

            <div className='col mb-3'>
              <Autocomplete
                label='Empresa'
                readOnly
                selectedItem={data.empresa}
                dataSourceTextProperty='noPessoa'
              />
            </div>
          </div>
          <div className='row'>
            {data.nrSeqManutencaoVeiculo ? (
              <div className='col mb-3'>
                <Autocomplete
                  label='Oficina'
                  readOnly
                  selectedItem={data.oficina}
                  dataSourceTextProperty='noOficina'
                />
              </div>
            ) : (
              <div className='col mb-3'>
                <Autocomplete
                  label='Oficina'
                  searchDataSource={onSearchOficina}
                  selectedItem={data.oficina}
                  onSelectItem={(oficina) => {
                    setData({
                      ...data,
                      oficina,
                      nrSeqPessoaOfi: oficina.nrSeqPessoaOfi,
                    });
                  }}
                  dataSourceTextProperty='noOficina'
                />
              </div>
            )}

            <div className='col-6 mb-3'>
              <Textbox
                label='Nota Fiscal Serviço'
                readOnly
                maxLength={60}
                text={data.serieForNf}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col mb-3'>
              <Textbox
                label='Número do Documento'
                maxLength={20}
                text={data.nrDocumento}
                onChangedValue={(nrDocumento) =>
                  setData({
                    ...data,
                    nrDocumento,
                  })
                }
              />
            </div>

            <div className='col-2 mb-2'>
              <DatePicker
                label='Data Documento'
                text={data.dtDocumento}
                mask={MaskTypes.Date}
                onChange={(dtDocumento) =>
                  setData({
                    ...data,
                    dtDocumento,
                  })
                }
              />
            </div>

            <div className='col-2 mb-3'>
              <Textbox
                label='Valor total'
                maxLength={20}
                readOnly
                mask={MaskTypes.Currency}
                text={data.vlrTotal}
                onChangedValue={(vlrTotal) =>
                  setData({
                    ...data,
                    vlrTotal,
                  })
                }
              />
            </div>
          </div>
          <Panel className='mb-3'>
            <Panel.Header title='Veículo' theme={Theme.Primary} />
            <Panel.Body>
              <div className='row'>
                <div className='col mb-3'>
                  <Autocomplete
                    label='Placa'
                    searchDataSource={onSearchVeiculo}
                    selectedItem={dataVeiculo.veiculo}
                    onSelectItem={async (veiculo) => {
                      if (veiculo.nrSeqVeiculo != null) {
                        const {
                          data: abastecimento,
                        } = await getUltimoAbastecimento(veiculo.nrSeqVeiculo);

                        setDataVeiculo({
                          ...dataVeiculo,
                          veiculo,
                          placa: veiculo.placa,
                          nrSeqVeiculo: veiculo.nrSeqVeiculo,
                          ultOdometro: abastecimento.nrOdometro,
                          ultAbastecimento: abastecimento.dtAbastecimento,
                        });
                      }
                    }}
                    dataSourceTextProperty='placa'
                  />
                </div>

                <div className='col mb-3'>
                  <Textbox
                    label='Odômetro'
                    maxLength={20}
                    text={dataVeiculo.nrOdometro}
                    mask={MaskTypes.Integer}
                    onChangedValue={(item) =>
                      setDataVeiculo({
                        ...dataVeiculo,
                        nrOdometro: item,
                      })
                    }
                  />
                </div>

                <div className='col mb-3'>
                  <Textbox
                    label='Último Odômetro'
                    maxLength={20}
                    readOnly
                    text={dataVeiculo.ultOdometro}
                    mask={MaskTypes.Integer}
                    onChangedValue={(item) =>
                      setDataVeiculo({
                        ...dataVeiculo,
                        ultOdometro: item.ultOdometro,
                      })
                    }
                  />
                </div>

                <div className='col mb-3'>
                  <DatePicker
                    label='Último Abastecimento'
                    text={dataVeiculo.ultAbastecimento}
                    mask={MaskTypes.Date}
                    readOnly
                  />
                </div>

                <div className='col-2 btn-group mr-2'>
                  <Button
                    text='Adicionar'
                    className='mb-3 mt-4'
                    icon='plus'
                    size={BootstrapSizes.Small}
                    theme={Theme.Success}
                    template={Button.Templates.Default}
                    onClick={onAdicionaVeiculo}
                    outline
                  />
                </div>

                <div className='col-2 btn-group'>
                  <Button
                    text='Adicionar Vinculados'
                    className='mb-3 mt-4'
                    icon='plus'
                    size={BootstrapSizes.Small}
                    theme={Theme.Success}
                    template={Button.Templates.Default}
                    onClick={onAdicionaVinculados}
                    outline
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-12'>
                  <GridView
                    ref={gridViewVeiculos}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsVeiculos}
                    offlineData
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>

            {data.nrSeqManutencaoVeiculo && (
              <>
                <Panel>
                  <Panel.Header
                    title='Manutenção Preventiva'
                    theme={Theme.Primary}
                  />
                  <Panel.Body>
                    <div className='row'>
                      <div className='mb-3'>
                        <GridView
                          ref={gridViewManutencaoPreventiva}
                          className='table-striped table-hover table-bordered table-sm'
                          dataColumns={columnsManutencaoPreventiva}
                          offlineData
                          showPagination={false}
                          showSelectSizes={false}
                        />
                      </div>
                    </div>
                  </Panel.Body>
                </Panel>
              </>
            )}

            {data.nrSeqManutencaoVeiculo == null && data.flgGridPreventiva && (
              <>
                <Panel>
                  <Panel.Header
                    title='Manutenção Preventiva'
                    theme={Theme.Primary}
                  />
                  <Panel.Body>
                    <div className='row'>
                      <div className='mb-3'>
                        <GridView
                          ref={gridViewManutencaoPreventivaOnNew}
                          className='table-striped table-hover table-bordered table-sm'
                          dataColumns={columnsManutencaoPreventivaOnNew}
                          offlineData
                          showPagination={false}
                          showSelectSizes={false}
                        />
                      </div>
                    </div>
                  </Panel.Body>
                </Panel>
              </>
            )}
          </Panel>

          <Panel className='mb-3'>
            <Panel.Header title='Serviço' theme={Theme.Primary} />
            <Panel.Body>
              <div className='row'>
                <div className='col mb-3'>
                  <Autocomplete
                    label='Ordem de Serviço'
                    searchDataSource={onSearchOrdemServico}
                    selectedItem={dataManutencaoVeiculoOS.manutencaoVeiculoOS}
                    onSelectItem={(manutencaoVeiculoOS) => {
                      setDataManutencaoVeiculoOS({
                        ...dataManutencaoVeiculoOS,
                        manutencaoVeiculoOS,
                        nrSeqManutencaoVeiculoOS:
                          manutencaoVeiculoOS.nrSeqManutencaoVeiculoOS,
                      });
                    }}
                    dataSourceTextProperty='nrSeqManutencaoVeiculoOS'
                  />
                </div>

                <div className='col-2 btn-group mr-2'>
                  <Button
                    text='Adicionar'
                    className='mb-3 mt-4'
                    icon='plus'
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    template={Button.Templates.Default}
                    onClick={onAdicionaOrdemServico}
                    outline
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-12'>
                  <GridView
                    ref={gridViewManutencaoVeiculosOS}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsManutencaoVeiculosOS}
                    offlineData
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>

          {data.nrSeqPessoaOfi > 0 && !data.oficina.flgControlaEstoque && (
            <Panel className='mb-3'>
              <Panel.Header title='Forma de Pagamento' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row'>
                  <div className='col-3 mb-3'>
                    <Textbox
                      label='Valor da Parcela'
                      maxLength={20}
                      mask={MaskTypes.Currency}
                      text={dataFatura.vlrParcela}
                      onChangedValue={(vlrParcela) =>
                        setDataFatura({
                          ...dataFatura,
                          vlrParcela,
                        })
                      }
                    />
                  </div>

                  <div className='col mb-3'>
                    <Autocomplete
                      label='Forma de Pagamento'
                      searchDataSource={onSearchFormaPagamento}
                      selectedItem={dataFatura.formaPagamento}
                      onSelectItem={(formaPagamento) => {
                        setDataFatura({
                          ...dataFatura,
                          formaPagamento,
                          nrSeqFormaPagamento:
                            formaPagamento.nrSeqFormaPagamento,
                        });
                      }}
                      dataSourceTextProperty='noFormaPagamento'
                    />
                  </div>

                  <div className='col-2 mb-2'>
                    <DatePicker
                      label='Data de Vencimento'
                      text={dataFatura.dtVencimento}
                      mask={MaskTypes.Date}
                      onChange={(dtVencimento) =>
                        setDataFatura({
                          ...dataFatura,
                          dtVencimento,
                        })
                      }
                    />
                  </div>

                  <div className='col-2 btn-group mr-2'>
                    <Button
                      text='Adicionar'
                      className='mb-3 mt-4'
                      icon='plus'
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      template={Button.Templates.Default}
                      onClick={onAdicionaFatura}
                      outline
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <GridView
                      ref={gridViewFaturas}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columnsFaturas}
                      offlineData
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          )}

          {data.nrSeqPessoaOfi > 0 && (
            <Panel className='mb-3'>
              <Panel.Header title='Peças' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row'>
                  <div className='col-3 mb-3'>
                    <Autocomplete
                      label='Placa'
                      searchDataSource={onSearchVeiculoFromManutencaoVeiculo}
                      selectedItem={dataPecas.veiculo}
                      onSelectItem={(veiculo) => {
                        setDataPecas({
                          ...dataPecas,
                          veiculo,
                          nrSeqVeiculo: veiculo.nrSeqVeiculo,
                          placa: veiculo.placa,
                        });
                      }}
                      dataSourceTextProperty='placa'
                    />
                  </div>

                  <div className='col mb-3'>
                    <Autocomplete
                      label='Produto Agrupador'
                      searchDataSource={onSearchProdutoAgrupador}
                      selectedItem={dataPecas.produtoAgrupador}
                      onSelectItem={async (produtoAgrupador) => {
                        preencheCamposProduto(produtoAgrupador);
                      }}
                      dataSourceTextProperty='noProdutoAgrupador'
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col mb-3'>
                    <Autocomplete
                      label='Cód. Interno - Produto - Marca - Cód. Peça:'
                      searchDataSource={() =>
                        onSearchProdutos(dataPecas.nrSeqProdutoAgrupador)
                      }
                      selectedItem={dataPecas.produto}
                      onSelectItem={(produto) => {
                        setDataPecas({
                          ...dataPecas,
                          produto,
                          nrSeqProduto: produto.nrSeqProduto,
                        });
                      }}
                      dataSourceTextProperty='noProduto'
                    />
                  </div>

                  {data.oficina.flgControlaEstoque ? (
                    <div className='col-5 mb-3'>
                      <Autocomplete
                        label='Almoxarifado'
                        searchDataSource={() =>
                          onSearchAlmoxarifado(dataPecas.nrSeqProduto)
                        }
                        selectedItem={dataPecas.almoxarifado}
                        onSelectItem={(almoxarifado) => {
                          setDataPecas({
                            ...dataPecas,
                            almoxarifado,
                            nrSeqAlmoxarifado: almoxarifado.nrSeqAlmoxarifado,
                            noAlmoxarifado: almoxarifado.noAlmoxarifado,
                          });
                        }}
                        dataSourceTextProperty='noAlmoxarifado'
                      />
                    </div>
                  ) : (
                    <div className='col-5 mb-3'> </div>
                  )}
                </div>

                <div className='row'>
                  {data.oficina.flgControlaEstoque ? (
                    <div className='col mb-3'>
                      <Autocomplete
                        label='Local'
                        searchDataSource={() =>
                          onSearchAlmoxarifadoLocal(
                            dataPecas.nrSeqProduto,
                            dataPecas.nrSeqAlmoxarifado
                          )
                        }
                        selectedItem={dataPecas.almoxarifadoLocal}
                        onSelectItem={async (almoxarifadoLocal) => {
                          const saldoAtual = await getBalance(
                            almoxarifadoLocal.nrSeqAlmoxarifadoLocal
                          );

                          const custoUnitario = await getCusto(
                            almoxarifadoLocal.nrSeqAlmoxarifadoLocal,
                            data.dtDocumento
                          );

                          setDataPecas({
                            ...dataPecas,
                            estoque: saldoAtual,
                            vlrUnitario: custoUnitario,
                            almoxarifadoLocal,
                            nrSeqAlmoxarifadoLocal:
                              almoxarifadoLocal.nrSeqAlmoxarifadoLocal,
                            noAlmoxarifadoLocal:
                              almoxarifadoLocal.noAlmoxarifadoLocal,
                          });
                        }}
                        dataSourceTextProperty='noAlmoxarifadoLocal'
                      />
                    </div>
                  ) : (
                    <div className='col mb-3'> </div>
                  )}

                  <div className='col-2 mb-3'>
                    <Textbox
                      label='Quantidade'
                      maxLength={20}
                      text={dataPecas.quantidade}
                      mask={MaskTypes.Integer}
                      onChangedValue={(quantidade) =>
                        setDataPecas({
                          ...dataPecas,
                          quantidade,
                        })
                      }
                    />
                  </div>

                  {data.oficina.flgControlaEstoque ? (
                    <div className='col-2 mb-3'>
                      <Textbox
                        label='Estoque'
                        maxLength={20}
                        readOnly
                        text={dataPecas.estoque}
                        mask={MaskTypes.Integer}
                        onChangedValue={(estoque) =>
                          setDataPecas({
                            ...dataPecas,
                            estoque,
                          })
                        }
                      />
                    </div>
                  ) : (
                    <div className='col-2 mb-3'> </div>
                  )}

                  {data.oficina.flgControlaEstoque ? (
                    <div className='col-2 mb-3'>
                      <Textbox
                        label='Valor unitário'
                        maxLength={20}
                        readOnly
                        text={dataPecas.vlrUnitario}
                        mask={MaskTypes.Currency}
                        onChangedValue={(vlrUnitario) =>
                          setDataPecas({
                            ...dataPecas,
                            vlrUnitario,
                          })
                        }
                      />
                    </div>
                  ) : (
                    <div className='col-2 mb-3'>
                      <Textbox
                        label='Valor unitário'
                        maxLength={20}
                        text={dataPecas.vlrUnitario}
                        mask={MaskTypes.Currency}
                        onChangedValue={(vlrUnitario) =>
                          setDataPecas({
                            ...dataPecas,
                            vlrUnitario,
                          })
                        }
                      />
                    </div>
                  )}
                  <div className='col-2 mb-3'>
                    <Textbox
                      label='Desconto'
                      maxLength={20}
                      text={dataPecas.vlrDesconto}
                      mask={MaskTypes.Currency}
                      onChangedValue={(vlrDesconto) =>
                        setDataPecas({
                          ...dataPecas,
                          vlrDesconto,
                        })
                      }
                    />
                  </div>
                </div>

                <div className='row'>
                  {data.oficina.flgControlaEstoque ? (
                    <div className='col mb-3'>
                      <Autocomplete
                        label='Funcionário'
                        searchDataSource={onSearchFuncionario}
                        selectedItem={dataPecas.funcionario}
                        onSelectItem={(funcionario) => {
                          setDataPecas({
                            ...dataPecas,
                            funcionario,
                          });
                        }}
                        dataSourceTextProperty='noPessoa'
                      />
                    </div>
                  ) : (
                    <div className='col mb-3'> </div>
                  )}

                  <div className='col mb-3'>
                    <Textarea
                      label='Observações'
                      text={dataPecas.obs}
                      onChangedValue={(obs) =>
                        setDataPecas({ ...dataPecas, obs })
                      }
                    />
                  </div>

                  <div className='col-2 btn-group mr-2'>
                    <Button
                      text='Adicionar'
                      className='mb-3 mt-4'
                      icon='plus'
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      template={Button.Templates.Default}
                      onClick={onAdicionaPecas}
                      outline
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <GridView
                      ref={gridViewPecas}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columnsPecas}
                      offlineData
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          )}
          <div className='row'>
            <div className='col mb-3'>
              <Textarea
                label='Observações'
                text={data.noObs}
                onChangedValue={(noObs) => setData({ ...data, noObs })}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </CSDManutPage>
  );
}
