export default class MotivoDesligamento {
  constructor(jsonObject = {}) {
    this.nrSeqMotivoDesligamento = jsonObject.nrSeqMotivoDesligamento;
    this.noMotivoDesligamento = jsonObject.noMotivoDesligamento;
    this.cdMotivoDesligamento = jsonObject.cdMotivoDesligamento;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
