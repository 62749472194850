import {
  deleteAllExtrato,
  excelExtrato,
  excelExtratoConferenciaSaldo,
  excelExtratoContabil,
  excelExtratoContabil2,
  excelExtratoContas,
  getContaCorrenteByUser,
  getExtratoList,
  printExtrato,
  printExtratoAgrupado,
  printExtratoConferenciaSaldo,
  printExtratoContabil,
  printExtratoContabil2,
  printExtratoContas,
} from 'core/services/FIN';
import {
  getEmpresasUsuario,
  getPessoaAutoCompleteFornecedorECliente,
} from 'core/services/SEG';
import React, { useEffect, useRef, useState } from 'react';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  Autocomplete,
  CSDSelPage,
  DatePicker,
  DropdownMulti,
  GridView,
  Panel,
  RadioButton,
  Switch,
  Textarea,
  Textbox,
  ToolbarButtons,
} from 'ui/components';

export default function ExtratoContaConrrente({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();
  const dropDownContaCorrente = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const validatePeriodo = () => {
    if (filtros.dtInicial && filtros.dtFinal) {
      const dataInicialPieces = filtros.dtInicial.split('/');

      const dataInicial = new Date(
        parseInt(dataInicialPieces[2], 10),
        parseInt(dataInicialPieces[1], 10) - 1,
        parseInt(dataInicialPieces[0], 10)
      );

      const dataFinalPieces = filtros.dtFinal.split('/');

      const dataFinal = new Date(
        parseInt(dataFinalPieces[2], 10),
        parseInt(dataFinalPieces[1], 10) - 1,
        parseInt(dataFinalPieces[0], 10)
      );

      if (dataInicial > dataFinal) {
        return false;
      }
    }

    if (filtros.dtCadInicial && filtros.dtCadFinal) {
      const dataCadInicialPieces = filtros.dtCadInicial.split('/');

      const dataCadInicial = new Date(
        parseInt(dataCadInicialPieces[2], 10),
        parseInt(dataCadInicialPieces[1], 10) - 1,
        parseInt(dataCadInicialPieces[0], 10)
      );

      const dataCadFinalPieces = filtros.dtCadFinal.split('/');

      const dataCadFinal = new Date(
        parseInt(dataCadFinalPieces[2], 10),
        parseInt(dataCadFinalPieces[1], 10) - 1,
        parseInt(dataCadFinalPieces[0], 10)
      );

      if (dataCadInicial > dataCadFinal) {
        return false;
      }
    }

    return true;
  };

  const search = async () => {
    setLoading(true);

    if (filtros.nrSeqsContaCorrente?.length > 0) {
      if (
        (filtros.dtInicial && filtros.dtFinal) ||
        (filtros.dtCadInicial && filtros.dtCadFinal)
      ) {
        if (validatePeriodo) {
          const { data } = await getExtratoList(filtros);

          if (gridView && gridView.current) {
            await gridView.current.setDataSource(data);

            if (filtros.dtCadInicial && filtros.dtCadFinal) {
              const gridColumns = gridView.current.getColumns();

              const dataIndex = gridColumns.findIndex(
                (e) => e.key === 'dtDocumento'
              );
              const dataCadIndex = gridColumns.findIndex(
                (e) => e.key === 'dtCad'
              );
              const saldoIndex = gridColumns.findIndex(
                (e) => e.key === 'saldo'
              );

              gridColumns[dataIndex].visible = false;
              gridColumns[dataCadIndex].visible = true;
              gridColumns[saldoIndex].visible = false;
            } else {
              const gridColumns = gridView.current.getColumns();

              const dataIndex = gridColumns.findIndex(
                (e) => e.key === 'dtDocumento'
              );
              const dataCadIndex = gridColumns.findIndex(
                (e) => e.key === 'dtCad'
              );
              const saldoIndex = gridColumns.findIndex(
                (e) => e.key === 'saldo'
              );

              gridColumns[dataIndex].visible = true;
              gridColumns[dataCadIndex].visible = false;

              if (filtros.nrSeqPessoa || filtros.nrDocumento || filtros.obs) {
                gridColumns[saldoIndex].visible = false;
              } else {
                gridColumns[saldoIndex].visible = true;
              }
            }
          }
        } else {
          onSetMessage(
            false,
            'O inicio do periodo de busca não pode ser maior que o final'
          );
        }
      } else {
        onSetMessage(false, 'Defina o periodo de busca corretamente');
      }
    } else {
      onSetMessage(false, 'Selecione ao menos uma conta');
    }
    setLoading(false);
  };

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ExtratoContaCorrente/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqExtrato = sourceRow.find((e) => e.key === 'nrSeqExtrato');

    if (nrSeqExtrato.value && nrSeqExtrato.value > 0) {
      onOpenMaintenance(nrSeqExtrato.value);
    }
  };

  const onDelete = async () => {
    setLoading(true);

    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllExtrato(
        selecteds.map((row) => row[0].value)
      );

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }

    setLoading(false);
  };

  const onPrint = async () => {
    setLoading(true);

    if (
      filtros.nrSeqsContaCorrente?.length > 0 &&
      filtros.dtInicial &&
      filtros.dtFinal
    ) {
      const { value } = await printExtrato({ ...filtros });

      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(false, 'Preencha os campos obrigatórios');
    }

    setLoading(false);
  };

  const onExcel = async (pageFormat) => {
    setLoading(true);
    if (
      filtros.nrSeqsContaCorrente?.length > 0 &&
      filtros.dtInicial &&
      filtros.dtFinal
    ) {
      const { value: base64 } = await excelExtrato({ ...filtros, pageFormat });

      if (base64) {
        const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;

        const downloadLink = document.createElement('a');

        downloadLink.href = linkSource;

        downloadLink.download = `ExtratoContaCorrente.xls`;

        downloadLink.click();
      }
    } else {
      onSetMessage(false, 'Preencha os campos obrigatórios');
    }

    setLoading(false);
  };

  const onPrintContabil = async () => {
    setLoading(true);

    if (
      filtros.nrSeqsContaCorrente?.length > 0 &&
      filtros.dtInicial &&
      filtros.dtFinal
    ) {
      const { value } = await printExtratoContabil(filtros);

      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(false, 'Preencha os campos obrigatórios');
    }

    setLoading(false);
  };

  const onExcelContabil = async () => {
    setLoading(true);

    if (
      filtros.nrSeqsContaCorrente?.length > 0 &&
      filtros.dtInicial &&
      filtros.dtFinal
    ) {
      const { value: base64 } = await excelExtratoContabil(filtros);

      if (base64) {
        const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;

        const downloadLink = document.createElement('a');

        downloadLink.href = linkSource;

        downloadLink.download = `ExtratoContaCorrenteContabil.xls`;

        downloadLink.click();
      }
    } else {
      onSetMessage(false, 'Preencha os campos obrigatórios');
    }

    setLoading(false);
  };

  const onPrintContabil2 = async () => {
    setLoading(true);

    if (
      filtros.nrSeqsContaCorrente?.length > 0 &&
      filtros.dtInicial &&
      filtros.dtFinal
    ) {
      const { value } = await printExtratoContabil2(filtros);

      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(false, 'Preencha os campos obrigatórios');
    }

    setLoading(false);
  };

  const onExcelContabil2 = async () => {
    setLoading(true);

    if (
      filtros.nrSeqsContaCorrente?.length > 0 &&
      filtros.dtInicial &&
      filtros.dtFinal
    ) {
      const { value: base64 } = await excelExtratoContabil2(filtros);

      if (base64) {
        const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;

        const downloadLink = document.createElement('a');

        downloadLink.href = linkSource;

        downloadLink.download = `ExtratoContaCorrenteContabil2.xls`;

        downloadLink.click();
      }
    } else {
      onSetMessage(false, 'Preencha os campos obrigatórios');
    }

    setLoading(false);
  };

  const onPrintAgrupado = async () => {
    setLoading(true);

    if (
      filtros.nrSeqsContaCorrente?.length > 0 &&
      filtros.dtInicial &&
      filtros.dtFinal
    ) {
      const { value } = await printExtratoAgrupado(filtros);

      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(false, 'Preencha os campos obrigatórios');
    }

    setLoading(false);
  };

  const onPrintExtratoContas = async () => {
    setLoading(true);

    if (
      filtros.nrSeqsContaCorrente?.length > 0 &&
      filtros.dtInicial &&
      filtros.dtFinal
    ) {
      const { value } = await printExtratoContas(filtros);

      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(false, 'Preencha os campos obrigatórios');
    }

    setLoading(false);
  };

  const onExcelExtratoContas = async () => {
    setLoading(true);

    if (
      filtros.nrSeqsContaCorrente?.length > 0 &&
      filtros.dtInicial &&
      filtros.dtFinal
    ) {
      const { value: base64 } = await excelExtratoContas(filtros);

      if (base64) {
        const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;

        const downloadLink = document.createElement('a');

        downloadLink.href = linkSource;

        downloadLink.download = `ExtratoContas.xls`;

        downloadLink.click();
      }
    } else {
      onSetMessage(false, 'Preencha os campos obrigatórios');
    }

    setLoading(false);
  };

  const onPrintConferenciaSaldo = async () => {
    setLoading(true);

    if (
      filtros.nrSeqsContaCorrente?.length > 0 &&
      filtros.dtInicial &&
      filtros.dtFinal
    ) {
      const { value } = await printExtratoConferenciaSaldo(filtros);

      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(false, 'Preencha os campos obrigatórios');
    }

    setLoading(false);
  };

  const onExcelConferenciaSaldo = async () => {
    setLoading(true);

    if (
      filtros.nrSeqsContaCorrente?.length > 0 &&
      filtros.dtInicial &&
      filtros.dtFinal
    ) {
      const { value: base64 } = await excelExtratoConferenciaSaldo(filtros);

      if (base64) {
        const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;

        const downloadLink = document.createElement('a');

        downloadLink.href = linkSource;

        downloadLink.download = `ConferenciaSaldo.xls`;

        downloadLink.click();
      }
    } else {
      onSetMessage(false, 'Preencha os campos obrigatórios');
    }

    setLoading(false);
  };

  const onSearchContaCorrente = async () => {
    const { status, message: msg, data } = await getContaCorrenteByUser();

    if (msg) {
      onSetMessage(status, msg);
    }

    return data;
  };

  const onSearchContaCorrenteByEmpresa = async () => {
    const { status, message: msg, data } = await getContaCorrenteByUser({
      nrSeqEmpresa: filtros.nrSeqEmpresa,
    });

    if (msg) {
      onSetMessage(status, msg);
    }

    return data;
  };

  const onSearchPessoa = async (e) => {
    const {
      status,
      message: msg,
      data: pessoas,
    } = await getPessoaAutoCompleteFornecedorECliente({ noPessoa: e });

    if (msg) onSetMessage(status, msg);

    return pessoas;
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const columns = [
    { key: 'nrSeqExtrato', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'contaCorrente.noContaCorrente',
      title: 'Conta',
      sortable: false,
      colorText: 'noCor',
    },
    {
      key: 'nrDocumento',
      title: 'Documento',
      sortable: false,
      colorText: 'noCor',
    },
    {
      key: 'dtDocumento',
      title: 'Data',
      format: GridView.DataTypes.Date,
      sortable: false,
      colorText: 'noCor',
    },
    {
      key: 'dtCad',
      title: 'Data Cadastro',
      format: GridView.DataTypes.Date,
      sortable: false,
      colorText: 'noCor',
      visible: false,
    },
    {
      key: 'extratoCategoria.extratoGrupoCategoria.noExtratoGrupoCategoria',
      title: 'Grupo',
      visible: false,
      sortable: false,
      colorText: 'noCor',
    },
    {
      key: 'extratoCategoria.noExtratoCategoria',
      title: 'Detalhe',
      sortable: false,
      colorText: 'noCor',
      filterable: true,
    },
    {
      key: 'cheque',
      title: 'Cheque',
      visible: false,
      sortable: false,
      colorText: 'noCor',
    },
    {
      key: 'noPessoa',
      title: 'Nome',
      sortable: false,
      colorText: 'noCor',
      filterable: true,
    },
    {
      key: 'nrDocumentoPessoa',
      title: 'Documento',
      visible: false,
      sortable: false,
      colorText: 'noCor',
      filterable: true,
    },
    {
      key: 'noDespesaReceita',
      title: 'Despesa/Receita',
      visible: true,
      sortable: false,
      colorText: 'noCor',
      filterable: true,
    },
    {
      key: 'nrIdentificado',
      title: 'Nr. Identificado',
      visible: true,
      sortable: false,
      colorText: 'noCor',
      filterable: true,
    },
    {
      key: 'nrCte',
      title: 'CTEs',
      visible: false,
      colorText: 'noCor',
    },
    {
      key: 'obs',
      title: 'Observações',
      sortable: false,
      colorText: 'noCor',
      filterable: true,
    },
    {
      key: 'vlrCredito',
      title: 'Crédito',
      format: GridView.DataTypes.Decimal,
      sortable: false,
      colorText: 'noCor',
    },
    {
      key: 'vlrDebito',
      title: 'Débito',
      format: GridView.DataTypes.Decimal,
      sortable: false,
      colorText: 'noCor',
    },
    {
      key: 'saldo',
      title: 'Saldo',
      format: GridView.DataTypes.Decimal,
      sortable: false,
      colorText: 'saldoCor',
    },
  ];

  useEffect(async () => {
    if (filtros.nrSeqEmpresa) {
      setSelectedItems({});

      dropDownContaCorrente.current.loadDataSource(
        onSearchContaCorrenteByEmpresa
      );
    } else {
      setSelectedItems({});

      dropDownContaCorrente.current.loadDataSource(onSearchContaCorrente);
    }
  }, [filtros.nrSeqEmpresa]);

  const limparFiltros = () => {
    dropDownContaCorrente.current.loadDataSource(onSearchContaCorrente);

    const today = new Date();

    const strToday = `${today.getFullYear()}-${String(
      today.getMonth() + 1
    ).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const strYesterday = `${yesterday.getFullYear()}-${String(
      yesterday.getMonth() + 1
    ).padStart(2, '0')}-${String(yesterday.getDate()).padStart(2, '0')}`;

    setFiltros({
      dtInicial: strYesterday,
      dtFinal: strToday,
      pageFormat: 'P',
      flgAgrupado: false,
      flgExibeDespesaReceita: true,
      flgExibeObs: true,
      flgChequesEmitidos: false,
      flgExibeDetalhe: false,
      flgExibeNrIdentificado: true,
      flgExibeGrupo: false,
      flgExibePessoaDetalhe: true,
      obs: '',
    });

    setSelectedItems({});
  };

  useEffect(() => {
    limparFiltros();
  }, []);

  const pageFormatOptions = [
    {
      text: 'Retrato',
      value: 'R',
    },
    {
      text: 'Paisagem',
      value: 'P',
    },
  ];

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Extrato Conta Corrente'
      loading={loading}
      onSearch={search}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.ButtonList
          text='Imprimir'
          icon='caret-down'
          items={[
            {
              text: 'Imprimir Vencimento',
              icon: 'print',
              onClick: () => onPrint(),
            },
            {
              text: 'Imprimir Contabil',
              icon: 'print',
              onClick: () => onPrintContabil(),
            },
            {
              text: 'Imprimir Agrupado Despesa/Receita',
              icon: 'print',
              onClick: () => onPrintAgrupado(),
            },
            {
              text: 'Imprimir Contabil v2',
              icon: 'print',
              onClick: () => onPrintContabil2(),
            },
            {
              text: 'Imprimir Extrato de Contas',
              icon: 'print',
              onClick: () => onPrintExtratoContas(),
            },
            {
              text: 'Imprimir Extrato para Conferencia Saldo',
              icon: 'print',
              onClick: () => onPrintConferenciaSaldo(),
            },
          ]}
        />
        <ToolbarButtons.ButtonList
          text='Excel'
          icon='caret-down'
          items={[
            {
              text: 'Download Excel Contabil',
              icon: ['far', 'file-excel'],
              onClick: () => onExcelContabil(),
            },
            {
              text: 'Download Excel Vencimento Paisagem',
              icon: ['far', 'file-excel'],
              onClick: () => onExcel('P'),
            },
            {
              text: 'Download Excel Vencimento Retrato',
              icon: ['far', 'file-excel'],
              onClick: () => onExcel('R'),
            },
            {
              text: 'Download Excel Contabil v2',
              icon: ['far', 'file-excel'],
              onClick: () => onExcelContabil2(),
            },
            {
              text: 'Download Excel Extrato de Contas',
              icon: ['far', 'file-excel'],
              onClick: () => onExcelExtratoContas(),
            },
            {
              text: 'Download Excel Extrato para Conferencia Saldo',
              icon: ['far', 'file-excel'],
              onClick: () => onExcelConferenciaSaldo(),
            },
          ]}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col'>
          <Panel>
            <Panel.Header
              title='Filtros'
              theme={Theme.Primary}
              onClickCollapseButton={limparFiltros}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col'>
                  <Autocomplete
                    label='Empresa'
                    searchDataSource={onSearchEmpresa}
                    selectedItem={filtros.empresa}
                    onSelectItem={(empresa) => {
                      setFiltros({
                        ...filtros,
                        empresa,
                        nrSeqEmpresa: empresa.nrSeqEmpresa,
                      });
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <DropdownMulti
                    required
                    label='Conta Corrente'
                    ref={dropDownContaCorrente}
                    dataSourcePropertyText='noContaCorrente'
                    dataSourcePropertyValue='nrSeqContaCorrente'
                    selectedItems={selectedItems.contaCorrente ?? []}
                    onSelectItem={(contaCorrente) => {
                      setSelectedItems({ ...selectedItems, contaCorrente });
                      setFiltros({
                        ...filtros,
                        nrSeqsContaCorrente: contaCorrente?.map(
                          (cc) => cc.nrSeqContaCorrente
                        ),
                      });
                    }}
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <div className='row'>
                    <div className='col'>
                      <DatePicker
                        label='Data Inicial'
                        text={filtros.dtInicial}
                        mask={MaskTypes.Date}
                        onChange={(dtInicial) =>
                          setFiltros({ ...filtros, dtInicial })
                        }
                      />
                    </div>
                    <div className='col'>
                      <DatePicker
                        label='Data Final'
                        text={filtros.dtFinal}
                        mask={MaskTypes.Date}
                        onChange={(dtFinal) =>
                          setFiltros({ ...filtros, dtFinal })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='col'>
                  <div className='row'>
                    <div className='col'>
                      <DatePicker
                        label='Data Cadastro Inicial'
                        text={filtros.dtCadInicial}
                        mask={MaskTypes.Date}
                        onChange={(dtCadInicial) =>
                          setFiltros({ ...filtros, dtCadInicial })
                        }
                      />
                    </div>
                    <div className='col'>
                      <DatePicker
                        label='Data Cadastro Final'
                        text={filtros.dtCadFinal}
                        mask={MaskTypes.Date}
                        onChange={(dtCadFinal) =>
                          setFiltros({ ...filtros, dtCadFinal })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='col'>
                  <div className='row'>
                    <div className='col-4'>
                      <Switch
                        formControl
                        label='Agrupado'
                        checked={filtros.flgAgrupado}
                        onChange={(flgAgrupado) =>
                          setFiltros({ ...filtros, flgAgrupado })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <Autocomplete
                    label='Fornecedor/Cliente'
                    searchDataSource={onSearchPessoa}
                    selectedItem={filtros.pessoa}
                    onSelectItem={(pessoa) => {
                      setFiltros({
                        ...filtros,
                        pessoa,
                        nrSeqPessoa: pessoa.nrSeqPessoa,
                      });
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='N° Identificado'
                    maxLength={60}
                    text={filtros.nrDocumento}
                    onChangedValue={(nrDocumento) =>
                      setFiltros({ ...filtros, nrDocumento })
                    }
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <Textarea
                    label='Observações'
                    text={filtros.obs}
                    onChangedValue={(obs) => setFiltros({ ...filtros, obs })}
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <Panel>
                  <Panel.Header
                    title='Preferências de Impressão'
                    theme={Theme.Primary}
                  />
                  <Panel.Body>
                    <div className='row'>
                      <div className='col'>
                        <RadioButton
                          outline
                          label='Formato da Página'
                          size={BootstrapSizes.Small}
                          theme={Theme.Primary}
                          selectedButton={filtros.pageFormat}
                          onChange={(pageFormat) => {
                            setFiltros({ ...filtros, pageFormat });
                          }}
                          buttons={pageFormatOptions}
                        />
                      </div>
                      <div className='col'>
                        <Switch
                          formControl
                          label='Exibir Detalhe'
                          checked={filtros.flgExibeDetalhe}
                          onChange={(flgExibeDetalhe) => {
                            setFiltros({ ...filtros, flgExibeDetalhe });
                          }}
                        />
                      </div>
                      <div className='col'>
                        <Switch
                          formControl
                          label='Pessoa/Detalhe'
                          checked={filtros.flgExibePessoaDetalhe}
                          onChange={(flgExibePessoaDetalhe) =>
                            setFiltros({ ...filtros, flgExibePessoaDetalhe })
                          }
                        />
                      </div>
                      <div className='col'>
                        <Switch
                          formControl
                          label='Exibir Grupo'
                          checked={filtros.flgExibeGrupo}
                          onChange={(flgExibeGrupo) => {
                            setFiltros({ ...filtros, flgExibeGrupo });
                          }}
                        />
                      </div>
                      <div className='col'>
                        <Switch
                          formControl
                          label='Exibir Observação'
                          checked={filtros.flgExibeObs}
                          onChange={(flgExibeObs) => {
                            setFiltros({ ...filtros, flgExibeObs });
                          }}
                        />
                      </div>
                      <div className='col'>
                        <Switch
                          formControl
                          label='Exibir Despesa/Receita'
                          checked={filtros.flgExibeDespesaReceita}
                          onChange={(flgExibeDespesaReceita) => {
                            setFiltros({ ...filtros, flgExibeDespesaReceita });
                          }}
                        />
                      </div>
                      <div className='col'>
                        <Switch
                          formControl
                          label='Exibir Nr. Identificado'
                          checked={filtros.flgExibeNrIdentificado}
                          onChange={(flgExibeNrIdentificado) => {
                            setFiltros({ ...filtros, flgExibeNrIdentificado });
                          }}
                        />
                      </div>
                      <div className='col'>
                        <Switch
                          formControl
                          label='Cheques Emitidos'
                          checked={filtros.flgChequesEmitidos}
                          onChange={(flgChequesEmitidos) =>
                            setFiltros({ ...filtros, flgChequesEmitidos })
                          }
                        />
                      </div>
                    </div>
                  </Panel.Body>
                </Panel>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            canControlVisibility
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showSelectSizes={false}
            showPagination={false}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
