import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  Switch,
  GridView,
  Panel,
  Button,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getTituloEspecie,
  saveTituloEspecie,
  deleteTituloEspecie,
} from 'core/services/FIN/tituloEspecie';
import { getExtratoCategoriaList } from 'core/services/FIN/extratoCategoria';
import { getPlanoDeContaList } from 'core/services/FIN/planoDeConta';
import TituloEspeciePlanoDeConta from 'core/models/FIN/tituloEspeciePlanoDeConta';

export default function TituloEspecieItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({
    flgPlanoContaCred: false,
    flgPlanoContaDeb: false,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [
    tituloespecieplanodeconta,
    setTituloEspecie,
  ] = useState(new TituloEspeciePlanoDeConta(), { flgPercentual: true });
  const gridView = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const obj = await getTituloEspecie(registryKey);
      setData(obj);
      setTituloEspecie({
        ...tituloespecieplanodeconta,
        flgPercentual: false,
      });
      if (gridView && gridView.current)
        gridView.current.setDataSource(obj.planoDeContas);
      setLoading(false);
    } else {
      setData({
        ...data,
        flgPlanoContaCred: false,
        ...data,
        flgPlanoContaDeb: false,
      });
      setTituloEspecie({
        ...tituloespecieplanodeconta,
        flgPercentual: false,
      });
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const lista = gridView.current ? gridView.current.getDataSource() : [];
    const obj = { ...data, planoDeContas: lista };
    const { status, message: msg } = await saveTituloEspecie(obj);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      setData(
        status === ResponseStatus.Success
          ? { flgPlanoContaCred: false, flgPlanoContaDeb: false }
          : data
      );
      setTituloEspecie(status === ResponseStatus.Success ? {} : lista);
      if (gridView && gridView.current) gridView.current.setDataSource([]);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteTituloEspecie(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchExtratoCategoria = async (e) => {
    const {
      status,
      message: msg,
      extratocategorias,
    } = await getExtratoCategoriaList({ noExtratoCategoria: e });
    if (msg) onSetMessage(status, msg);
    return extratocategorias;
  };

  const onSearchPlanoDeContaDeb = async (e) => {
    const { status, message: msg, planodecontas } = await getPlanoDeContaList({
      noPlanoDeContaDeb: e,
    });
    if (msg) onSetMessage(status, msg);
    return planodecontas;
  };

  const onSearchPlanoDeContaCred = async (e) => {
    const { status, message: msg, planodecontas } = await getPlanoDeContaList({
      noPlanoDeContaCred: e,
    });
    if (msg) onSetMessage(status, msg);
    return planodecontas;
  };

  const onClickEditTituloEspecie = async (selectedValue, datasource) => {
    const tituloEspecies = datasource.filter(
      (especie) => especie !== selectedValue
    );
    const tituloEspecie = datasource.filter(
      (especie) => especie === selectedValue
    );

    tituloEspecie[0].status = GridView.EnumStatus.Alterar;
    setTituloEspecie(tituloEspecie[0]);
    if (gridView && gridView.current)
      gridView.current.setDataSource(tituloEspecies);
  };

  const onClickRemoveTituloEspecie = async (selectedValue, datasource) => {
    let tituloEspecies = [];
    if (selectedValue.nrSeqTituloEspeciePlanoDeConta === undefined) {
      tituloEspecies = datasource.filter(
        (tituloEspecie) => tituloEspecie !== selectedValue
      );
    } else {
      tituloEspecies = datasource.filter(
        (tituloEspecie) => tituloEspecie !== selectedValue
      );
      const tituloespecie = datasource.filter(
        (tituloEspecie) => tituloEspecie === selectedValue
      );
      tituloespecie[0].status = GridView.EnumStatus.Remover;
      tituloEspecies.push(tituloespecie[0]);
    }
    if (gridView && gridView.current)
      gridView.current.setDataSource(tituloEspecies);
  };

  const columns = [
    {
      key: 'planoDeContaDeb.noPlanoDeConta',
      title: 'Conta Debito',
      format: GridView.DataTypes.String,
    },
    {
      key: 'planoDeContaCre.noPlanoDeConta',
      title: 'Conta Credito',
      format: GridView.DataTypes.String,
    },
    {
      key: 'flgPercentual',
      title: 'Valor Percentual',
      format: GridView.DataTypes.String,
    },
    {
      key: 'vlrPercentual',
      title: 'Valor',
      format: GridView.DataTypes.Integer,
    },
    {
      key: 'nrSeqTipoDespesaPlanoDeConta',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditTituloEspecie(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqTipoDespesaPlanoDeConta',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveTituloEspecie(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onAdicionarPlanoDeConta = async () => {
    if (
      tituloespecieplanodeconta.planoDeContaCre == null &&
      tituloespecieplanodeconta.planoDeContaDeb == null
    ) {
      onSetMessage(ResponseStatus.Error, 'Informe qual o plano de conta');
      return;
    }
    if (
      tituloespecieplanodeconta.flgPercentual &&
      tituloespecieplanodeconta.vlrPercentual == null
    ) {
      onSetMessage(ResponseStatus.Error, 'Informe o Valor Percentual');
    } else {
      if (!tituloespecieplanodeconta.flgPercentual) {
        tituloespecieplanodeconta.vlrPercentual = undefined;
      }
      if (!data.flgPlanoContaCred) {
        tituloespecieplanodeconta.nrSeqPlanoDeContaCred = undefined;
        tituloespecieplanodeconta.planoDeContaCred = undefined;
      }
      if (!data.flgPlanoContaDeb) {
        tituloespecieplanodeconta.nrSeqPlanoDeContaDeb = undefined;
        tituloespecieplanodeconta.planoDeContaDeb = undefined;
      }
      const lista = gridView.current.getDataSource();
      const listaValidacao = [];
      lista.forEach((elem) => listaValidacao.push(elem));
      listaValidacao.push(tituloespecieplanodeconta);

      let ePercentual = false;
      let naoPercentual = false;

      ePercentual = listaValidacao.filter(
        (elem) => elem.flgPercentual === true && elem.status !== 'Remover'
      );
      naoPercentual = listaValidacao.filter(
        (elem) => elem.flgPercentual === false && elem.status !== 'Remover'
      );
      if (ePercentual.length > 0 && naoPercentual.length > 0) {
        onSetMessage(
          ResponseStatus.Error,
          'Você não pode colocar um valor não percentual com um percentual'
        );
        return;
      }

      if (tituloespecieplanodeconta.status !== GridView.EnumStatus.Alterar) {
        tituloespecieplanodeconta.status = GridView.EnumStatus.Inserir;
      }
      lista.push(tituloespecieplanodeconta);
      if (gridView && gridView.current) gridView.current.setDataSource(lista);
      if (tituloespecieplanodeconta.flgPercentual === true) {
        setTituloEspecie({ flgPercentual: true });
      } else {
        setTituloEspecie({ flgPercentual: false });
      }
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Titulo Especie'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqTituloEspecie > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-5 mb-3'>
          <Textbox
            readOnly
            label='Codigo'
            text={data.cdTituloEspecie}
            mask={MaskTypes.Integer}
            onChangedValue={(cdTituloEspecie) =>
              setData({ ...data, cdTituloEspecie })
            }
          />
        </div>
        <div className='col-5 mb-3'>
          <Textbox
            required
            maxLength={40}
            label='Titulo Especie'
            text={data.noEspecie}
            onChangedValue={(noEspecie) => setData({ ...data, noEspecie })}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Previsão'
            checked={data.flgPrevisao}
            onChange={(flgPrevisao) => setData({ ...data, flgPrevisao })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-5 mb-3'>
          <Textbox
            required
            maxLength={150}
            label='Pagina Detalhamento'
            text={data.noPaginaDetalhamento}
            onChangedValue={(noPaginaDetalhamento) =>
              setData({ ...data, noPaginaDetalhamento })
            }
          />
        </div>
        <div className='col-5 mb-3'>
          <Autocomplete
            required
            label='Extrato Categoria'
            searchDataSource={onSearchExtratoCategoria}
            selectedItem={data.extratocategoria}
            onSelectItem={(extratocategoria) => {
              setData({
                ...data,
                extratocategoria,
                nrSeqExtratoCategoria: extratocategoria.nrSeqExtratoCategoria,
              });
            }}
            dataSourceTextProperty='noExtratoCategoria'
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-4 mb-3'>
          <Switch
            formControl
            label='Paga'
            checked={data.flgPaga}
            onChange={(flgPaga) => setData({ ...data, flgPaga })}
          />
        </div>
        <div className='col-4 mb-3'>
          <Switch
            formControl
            label='Plano Conta Credito'
            checked={data.flgPlanoContaCred}
            onChange={(flgPlanoContaCred) =>
              setData({ ...data, flgPlanoContaCred })
            }
          />
        </div>
        <div className='col-4 mb-3'>
          <Switch
            formControl
            label='Plano Conta Debito'
            checked={data.flgPlanoContaDeb}
            onChange={(flgPlanoContaDeb) =>
              setData({ ...data, flgPlanoContaDeb })
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-4 mb-3'>
          <Switch
            formControl
            label='Exporta Titulo Receber'
            checked={data.flgExportaTitReceber}
            onChange={(flgExportaTitReceber) =>
              setData({ ...data, flgExportaTitReceber })
            }
          />
        </div>
        <div className='col-4 mb-3'>
          <Switch
            formControl
            label='Exporta Recebimento'
            checked={data.flgExportaRecebimento}
            onChange={(flgExportaRecebimento) =>
              setData({ ...data, flgExportaRecebimento })
            }
          />
        </div>
        <div className='col-4 mb-3'>
          <Switch
            formControl
            label='Conhecimento Eletronico'
            checked={data.flgConhecimentoEletronico}
            onChange={(flgConhecimentoEletronico) =>
              setData({ ...data, flgConhecimentoEletronico })
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Inativo'
            checked={data.flgInativo}
            onChange={(flgInativo) => setData({ ...data, flgInativo })}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Exporta Acumuladomes'
            checked={data.flgExportaAcumuladoMes}
            onChange={(flgExportaAcumuladoMes) =>
              setData({ ...data, flgExportaAcumuladoMes })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Exporta Contabil Agrupado'
            checked={data.flgExportaContaBilAgrupado}
            onChange={(flgExportaContaBilAgrupado) =>
              setData({ ...data, flgExportaContaBilAgrupado })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-4 mb-3'>
          <Switch
            formControl
            label='Exibe No Parcelamento'
            checked={data.flgExibeNoParcelamento}
            onChange={(flgExibeNoParcelamento) =>
              setData({ ...data, flgExibeNoParcelamento })
            }
          />
        </div>
        <div className='col-4 mb-3'>
          <Switch
            formControl
            label='Exporta Contabil Apenas Liquidado'
            checked={data.flgExportaContaBilApenasLiquidado}
            onChange={(flgExportaContaBilApenasLiquidado) =>
              setData({ ...data, flgExportaContaBilApenasLiquidado })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Gera Código'
            checked={data.flgGeraCod}
            onChange={(flgGeraCod) => setData({ ...data, flgGeraCod })}
          />
        </div>
      </div>
      <Panel visible={data.flgPlanoContaDeb || data.flgPlanoContaCred}>
        <Panel.Header
          icon={['fas', 'user']}
          title='Planos de Conta'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col-5 mb-3'>
              <Autocomplete
                visible={data.flgPlanoContaCred}
                label='Plano De Conta Credito'
                searchDataSource={onSearchPlanoDeContaCred}
                selectedItem={tituloespecieplanodeconta.planoDeContaCre}
                onSelectItem={(planoDeContaCre) => {
                  setTituloEspecie({
                    ...tituloespecieplanodeconta,
                    planoDeContaCre,
                    nrSeqPlanoDeContaCred: planoDeContaCre.nrSeqPlanoDeConta,
                  });
                }}
                dataSourceTextProperty='noPlanoDeConta'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-5 mb-3'>
              <Autocomplete
                visible={data.flgPlanoContaDeb}
                label='Plano De Conta Debito'
                searchDataSource={onSearchPlanoDeContaDeb}
                selectedItem={tituloespecieplanodeconta.planoDeContaDeb}
                onSelectItem={(planoDeContaDeb) => {
                  setTituloEspecie({
                    ...tituloespecieplanodeconta,
                    planoDeContaDeb,
                    nrSeqPlanoDeContaDeb: planoDeContaDeb.nrSeqPlanoDeConta,
                  });
                }}
                dataSourceTextProperty='noPlanoDeConta'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-2 mb-3'>
              <Switch
                formControl
                label='Percentual'
                checked={tituloespecieplanodeconta.flgPercentual}
                onChange={(flgPercentual) =>
                  setTituloEspecie({
                    ...tituloespecieplanodeconta,
                    flgPercentual,
                  })
                }
              />
            </div>
            <div className='col-5 mb-3'>
              <Textbox
                visible={tituloespecieplanodeconta.flgPercentual}
                label='Valor Percentual'
                text={tituloespecieplanodeconta.vlrPercentual}
                mask={MaskTypes.Decimal}
                onChangedValue={(vlrPercentual) =>
                  setTituloEspecie({
                    ...tituloespecieplanodeconta,
                    vlrPercentual,
                  })
                }
              />
            </div>
            <div className='col-auto mt-3'>
              <Button
                theme={Theme.Primary}
                template={Button.Templates.Button}
                text='Adicionar'
                onClick={onAdicionarPlanoDeConta}
              />
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col'>
              <GridView
                ref={gridView}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columns}
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </CSDManutPage>
  );
}
