import { Empresa } from '../SEG';
import Cliente from './cliente';
import FormaPagamento from './formaPagamento';
import PessoaInformacaoBancaria from './pessoaInformacaoBancaria';

export default class TituloReceberFatura {
  constructor(jsonObject = {}) {
    this.nrSeqTituloReceberFatura = jsonObject.nrSeqTituloReceberFatura;
    this.cdTituloReceberFatura = jsonObject.cdTituloReceberFatura;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.vlrFatura = jsonObject.vlrFatura;
    this.dtVencimento = jsonObject.dtVencimento;
    this.flgManual = jsonObject.flgManual;
    this.linhaDigitavel = jsonObject.linhaDigitavel;
    this.codBarrasBoleto = jsonObject.codBarrasBoleto;
    this.numeroBancario = jsonObject.numeroBancario;
    this.flgRemessaPendente = jsonObject.flgRemessaPendente;
    this.dtRemessa = jsonObject.dtRemessa;
    this.dtRetorno = jsonObject.dtRetorno;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nossoNumero = jsonObject.nossoNumero;
    this.nrSeqRemessaFormaPagamento = jsonObject.nrSeqRemessaFormaPagamento;
    this.nrSeqRemessaArquivoBancario = jsonObject.nrSeqRemessaArquivoBancario;
    this.dtCad = jsonObject.dtCad;
    this.nrSeqFatura = jsonObject.nrSeqFatura;
    this.cdAgencia = jsonObject.cdAgencia;
    this.cdContaCorrente = jsonObject.cdContaCorrente;
    this.noPessoa = jsonObject.noPessoa;
    this.urlPix = jsonObject.urlPix;
    this.txtId = jsonObject.txtId;
    this.emv = jsonObject.emv;

    this.vlrSaldoTit = jsonObject.vlrSaldoTit;
    this.vlrNovoValor = jsonObject.vlrNovoValor;
    this.vlrAcrescimo = jsonObject.vlrAcrescimo;
    this.vlrDesconto = jsonObject.vlrDesconto;

    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.cliente = new Cliente(jsonObject.cliente ?? {});

    this.vlrFaturaStr = jsonObject.vlrFaturaStr;

    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.informacaoBancaria = new PessoaInformacaoBancaria(
      jsonObject.informacaoBancaria ?? {}
    );
    this.numeroTituloBeneficiario = jsonObject.numeroTituloBeneficiario;

    this.flgPermiteAlterar = jsonObject.flgPermiteAlterar;

    this.flgFaturaAtiva = jsonObject.flgFaturaAtiva;

    this.flgConfirmaAlteracao = jsonObject.flgConfirmaAlteracao;
    this.tituloReceberFaturaS = jsonObject.tituloReceberFaturaS;
    this.flgFrota = jsonObject.flgFrota;
    this.nrSeqsContaRecurso = jsonObject.nrSeqsContaRecurso;
    this.graficoData = jsonObject.graficoData;
  }

  toJson = () => JSON.stringify(this);
}
