import React, { useState, useEffect, useRef } from 'react';
import { setTransaction } from 'core/services/api';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import {
  Button,
  Modal,
  Notification,
  DatePicker,
  Autocomplete,
  Textbox,
  GridView,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN';
import { postParcelaTituloReceberRepactuacao } from 'core/services/FIN/tituloReceber';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import TituloReceber from 'core/models/FIN/tituloReceber';
import { getInformacaoBancariaAutoComplete } from 'core/services/FIN/informacaoBancaria';

export default function ModalRepactuacaoParcelarTitulo({
  show,
  onClose,
  titulos,
  transaction,
  retornoSave,
}) {
  // #region Inicializadores
  const [message, setMessage] = useState(null);
  const [data, setData] = useState({
    vlrMultaCalculado: 0,
    vlrJuroCalculado: 0,
  });
  const gridViewAgrupadosNovosParaGerar = useRef();
  const gridViewAgrupadosSelecao = useRef();
  // #endregion

  // #region Funções
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onClickRemover = (e) => {
    const lista = gridViewAgrupadosNovosParaGerar.current.getDataSource();
    const listafiltrada = lista.filter((item) => item !== e);

    if (
      gridViewAgrupadosNovosParaGerar &&
      gridViewAgrupadosNovosParaGerar.current
    )
      gridViewAgrupadosNovosParaGerar.current.setDataSource(listafiltrada);
  };

  const addMonths = (date, months) => {
    date.setMonth(date.getMonth() + months);

    return date;
  };

  const onGerarListaDeFaturas = () => {
    const saldoDevedorInicial = data.vlrTotalTituloAntigo - data.vlrEntrada;
    let vlrFatura = 0;
    let nrDaParcela = 0;

    const titulosReceber = [];

    if (data.vlrEntrada > 0) {
      const titulo = new TituloReceber({
        ...data,
        noDescricao: 'Entrada',
        vlrTitulo: data.vlrEntrada,
        vlrJuro: 0,
        vlrMulta: 0,
        vlrFaturaColumn: data.vlrEntrada,
        vlrSaldoDevedorColumn: saldoDevedorInicial,
        dtVencimento: new Date(),
        noObs: `Renegociado em ${new Date()}`,
      });

      titulosReceber.push(titulo);
    }

    for (
      let saldoDevedor = saldoDevedorInicial;
      saldoDevedor > 0;
      saldoDevedor -= vlrFatura
    ) {
      const vlrJuros = saldoDevedor * (data.vlrJuroCalculado / 100);
      const valorMulta = nrDaParcela === 0 ? data.vlrMultaCalculado : 0;
      vlrFatura = data.vlrPrimeiraParcela - vlrJuros - valorMulta;

      if (saldoDevedor > data.vlrPrimeiraParcela) {
        const titulo = new TituloReceber({
          ...data,
          noDescricao: `${nrDaParcela + 1}ª Parcela`,
          vlrTitulo: data.vlrPrimeiraParcela,
          vlrJuroAdicionado: vlrJuros,
          vlrMultaAdicionado: valorMulta,
          vlrFaturaColumn: vlrFatura,
          vlrSaldoDevedorColumn: saldoDevedor - vlrFatura,
          dtVencimento: addMonths(new Date(data.dtVencimento), nrDaParcela),
        });

        nrDaParcela += 1;
        titulosReceber.push(titulo);
      } else if (saldoDevedor < data.vlrPrimeiraParcela && saldoDevedor > 0) {
        const titulo = new TituloReceber({
          ...data,
          noDescricao: `${nrDaParcela + 1}ª Parcela`,
          vlrTitulo: saldoDevedor + vlrJuros,
          vlrJuroAdicionado: vlrJuros,
          vlrMultaAdicionado: nrDaParcela === 0 ? data.vlrMultaCalculado : 0,
          vlrFaturaColumn: saldoDevedor,
          vlrSaldoDevedorColumn: 0,
          dtVencimento: addMonths(new Date(data.dtVencimento), nrDaParcela),
        });

        saldoDevedor = 0;
        titulosReceber.push(titulo);
      }
    }

    return titulosReceber;
  };

  const onAdicionarParcela = async () => {
    if (!data.nrSeqEmpresa) {
      onSetMessage(Theme.Danger, 'Necessário informar a empresa');
      return;
    }

    if (!data.nrSeqTituloEspecie) {
      onSetMessage(Theme.Danger, 'Necessário informar o título espécie');
      return;
    }

    if (!data.nrSeqFormaPagamento) {
      onSetMessage(Theme.Danger, 'Necessário informar a forma de pagamento');
      return;
    }

    if (!data.dtVencimento) {
      onSetMessage(Theme.Danger, 'Necessário informar o vencimento');
      return;
    }

    if (!data.vlrPrimeiraParcela || data.vlrPrimeiraParcela < 0) {
      onSetMessage(
        Theme.Danger,
        'Necessário informar o valor da primeira parcela'
      );
      return;
    }

    if (
      data.formaPagamento?.flgGrupo === 'D' &&
      !data.nrSeqPessoaInformacaoBancaria
    ) {
      onSetMessage(Theme.Danger, 'Necessário informar informação bancária');
      return;
    }

    if (!data.vlrEntrada || data.vlrEntrada < 0) {
      data.vlrEntrada = 0;
    }

    const titulosGerados = onGerarListaDeFaturas();

    if (
      gridViewAgrupadosNovosParaGerar &&
      gridViewAgrupadosNovosParaGerar.current
    ) {
      gridViewAgrupadosNovosParaGerar.current.setDataSource(titulosGerados);
    }
  };

  const onSearchInformacaoBancaria = async () => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoComplete({
      nrSeqPessoa: titulos[0].nrSeqPessoaCli,
    });
    onSetMessage(status, msg);
    return informacoesBancarias;
  };

  const columnsAgrupadosNovosParaGerar = [
    {
      key: 'nrSeqTituloReceber',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'nrTit', title: 'NrTit', visible: false },
    { key: 'nrOrdem', title: 'NrOrdem', visible: false },
    {
      key: 'cliente.noPessoa',
      title: 'Cliente',
      visible: false,
    },
    {
      key: 'tituloEspecie.noEspecie',
      title: 'Receita',
      visible: false,
    },
    {
      key: 'noDescricao',
      title: 'Descrição',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrJuroAdicionado',
      title: 'Juros',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMultaAdicionado',
      title: 'Multa',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrFaturaColumn',
      title: 'Valor Fatura',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrSaldoDevedorColumn',
      title: 'Saldo Devedor',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrRecebido',
      title: 'Pago',
      format: GridView.DataTypes.Money,
      visible: false,
    },
    {
      key: 'vlrSaldoTit',
      title: 'Saldo',
      format: GridView.DataTypes.Money,
      visible: false,
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma',
    },
    {
      key: 'dtVencimentoStr',
      visible: false,
    },
    { key: 'noObs', title: 'NoObs', visible: false },
    {
      key: 'dtContab',
      title: 'Contabil',
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'dtRecebimento',
      title: 'Recebimento',
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'nrSeqTituloReceberFatura',
      title: 'NrSeqTituloReceberFatura',
      visible: false,
    },
    {
      key: 'nrSeqTituloReceber',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickRemover(e),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltipDirection: 'bottom',
      title: 'Remover',
    },
  ];

  const columnsAgrupadosSelecao = [
    {
      key: 'nrSeqTituloReceber',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'nrTit', title: 'NrTit' },
    { key: 'nrOrdem', title: 'NrOrdem' },
    {
      key: 'cliente.noPessoa',
      title: 'Cliente',
    },
    {
      key: 'tituloEspecie.noEspecie',
      title: 'Receita',
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma',
      visible: false,
    },
    {
      key: 'nrDiasEmAtraso',
      title: 'Atraso',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrRecebido',
      title: 'Recebido',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrSaldoTit',
      title: 'Saldo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'dtVencimentoStr',
      visible: false,
    },
    { key: 'noObs', title: 'NoObs', visible: false },
    {
      key: 'dtContab',
      title: 'Contabil',
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'dtRecebimento',
      title: 'Recebimento',
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'nrSeqTituloReceberFatura',
      title: 'NrSeqTituloReceberFatura',
      visible: false,
    },
  ];

  const onSearchFormaPagamento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitRec: true,
    });

    return formasPagamento;
  };

  const onSalvar = async () => {
    const tituloReceberAgrupadosSelecao = gridViewAgrupadosSelecao.current.getDataSource();
    const tituloReceberAgrupadosNovosParaGerar = gridViewAgrupadosNovosParaGerar.current.getDataSource();
    const obj = {
      tituloReceberAgrupadosSelecao,
      tituloReceberAgrupadosNovosParaGerar,
    };
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);

    const retornoSaveParcela = await postParcelaTituloReceberRepactuacao(obj);

    if (retornoSaveParcela.status === ResponseStatus.Error) {
      onSetMessage(Theme.Danger, retornoSaveParcela.message);
    }

    const retornoDados = {
      vlrEntrada: data.vlrEntrada,
      vlrBaseCalculo: data.vlrTotalTituloAntigo,
      vlrJurosDoc: data.vlrJuroCalculado,
      vlrMultaDoc: data.vlrMultaCalculado,
      vlrParcela: data.vlrPrimeiraParcela,
      dtPrimeiroPagamento: data.dtVencimento,
      nrSeqFormaPagamento: data.nrSeqFormaPagamento,
    };
    const retorno = {
      retornoDados,
      retornoSaveParcela,
      tituloReceberAgrupadosSelecao,
    };

    setData({
      vlrMultaCalculado: 0,
      vlrJuroCalculado: 0,
      vlrEntrada: 0,
      vlrPrimeiraParcela: 0,
      nrParcelas: 0,
    });

    retornoSave(retorno);
  };

  const onSearchTipo = async (e) => {
    const { data: tituloReceberEspecie } = await getTituloEspecieAutoComplete({
      noEspecie: e,
      flgExibeNoParcelamento: true,
    });

    return tituloReceberEspecie;
  };

  const onSearchEmpresa = async (e) => {
    const { empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    return empresas;
  };

  const calculaTotalTitulosParcelamento = () => {
    const total = titulos.reduce(
      (acum, item) => acum + item.vlrTitulo - item.vlrRecebido,
      0
    );
    return total;
  };

  useEffect(async () => {
    if (show) {
      // validateChangePaymentMethod();
      setData({
        vlrMultaCalculado: 0,
        vlrJuroCalculado: 0,
        nrSeqEmpresa: titulos[0].nrSeqEmpresa,
        empresa: titulos[0].empresa,
        vlrTotalTituloAntigo: calculaTotalTitulosParcelamento(),
      });
      if (gridViewAgrupadosSelecao && gridViewAgrupadosSelecao.current)
        gridViewAgrupadosSelecao.current.setDataSource(titulos);
    }
  }, [show]);
  // #endregion

  return (
    <Modal
      show={show}
      title='Parcelar Titulo Receber'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body className='p-4 pt-3'>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-6'>
          <div className='card'>
            <div className='card-body'>
              <div className='row mb-3'>
                <div className='row mb-3'>
                  <div className='col'>
                    <Autocomplete
                      label='Empresa'
                      searchDataSource={onSearchEmpresa}
                      selectedItem={data.empresa}
                      onSelectItem={(empresa) => {
                        setData({
                          ...data,
                          empresa,
                          nrSeqEmpresa: empresa.nrSeqEmpresa,
                        });
                      }}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                  <div className='col-3'>
                    <Autocomplete
                      label='Tipo Receita'
                      searchDataSource={onSearchTipo}
                      selectedItem={data.tituloEspecie}
                      onSelectItem={(tituloEspecie) => {
                        setData({
                          ...data,
                          tituloEspecie,
                          nrSeqTituloEspecie: tituloEspecie.nrSeqTituloEspecie,
                        });
                      }}
                      dataSourceTextProperty='noEspecie'
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col'>
                    <Textbox
                      label='Valor Pendente'
                      text={data?.vlrTotalTituloAntigo}
                      mask={MaskTypes.DecimalCustom}
                      readOnly
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Juros (%)'
                      text={data.vlrJuroCalculado}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(vlrJuroCalculado) =>
                        setData({
                          ...data,
                          vlrJuroCalculado,
                        })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Multa (R$)'
                      text={data.vlrMultaCalculado}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(vlrMultaCalculado) =>
                        setData({
                          ...data,
                          vlrMultaCalculado,
                        })
                      }
                    />
                  </div>

                  <div className='col-4'>
                    <Autocomplete
                      label='Forma de Recebimento'
                      searchDataSource={onSearchFormaPagamento}
                      selectedItem={data.formaPagamento}
                      onSelectItem={(formaPagamento) => {
                        setData({
                          ...data,
                          formaPagamento,
                          nrSeqFormaPagamento:
                            formaPagamento.nrSeqFormaPagamento,
                        });
                      }}
                      dataSourceTextProperty='noFormaPagamento'
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-2'>
                    <Textbox
                      label='Valor da Entrada'
                      text={data?.vlrEntrada}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(vlrEntrada) =>
                        setData({
                          ...data,
                          vlrEntrada,
                        })
                      }
                    />
                  </div>

                  <div className='col-2'>
                    <Textbox
                      label='Valor da 1ª Parcela'
                      text={data?.vlrPrimeiraParcela}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(vlrPrimeiraParcela) =>
                        setData({
                          ...data,
                          vlrPrimeiraParcela,
                        })
                      }
                    />
                  </div>

                  <div className='col-3'>
                    <DatePicker
                      label='Vencimento'
                      text={data.dtVencimento}
                      mask={MaskTypes.Date}
                      onChange={(dtVencimento) =>
                        setData({ ...data, dtVencimento })
                      }
                    />
                  </div>

                  <div className='col-4 mt-3'>
                    <Button
                      theme={Theme.Primary}
                      template={Button.Templates.Button}
                      text='Adicionar Parcelas'
                      onClick={() => onAdicionarParcela()}
                    />
                  </div>

                  {data.formaPagamento?.flgGrupo === 'D' && (
                    <div className='col-4'>
                      <Autocomplete
                        label='Informação Bancária'
                        searchDataSource={onSearchInformacaoBancaria}
                        selectedItem={data.informacaoBancaria}
                        onSelectItem={(informacaoBancaria) =>
                          setData({
                            ...data,
                            informacaoBancaria,
                            nrSeqPessoaInformacaoBancaria:
                              informacaoBancaria.nrSeqPessoaInformacaoBancaria,
                          })
                        }
                        dataSourceTextProperty='noDescricao'
                      />
                    </div>
                  )}
                </div>

                <div className='row mb-3'>
                  <div className='col'>
                    <GridView
                      ref={gridViewAgrupadosNovosParaGerar}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columnsAgrupadosNovosParaGerar}
                      showPagination={false}
                      transaction={transaction}
                      showSelectSizes={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              <GridView
                ref={gridViewAgrupadosSelecao}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columnsAgrupadosSelecao}
                showPagination={false}
                transaction={transaction}
                showSelectSizes={false}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Salvar'
          theme={Theme.Success}
          icon={['far', 'save']}
          onClick={() => onSalvar()}
        />
        <Button
          text='Fechar'
          theme={Theme.Danger}
          icon='ban'
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
