import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  Theme,
  JustifyContent,
  dateNow,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Textbox,
  DatePicker,
  GridView,
  Panel,
  Steps,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getPocResultadoOrcamento,
  printPocResultadoOrcamento,
} from 'core/services/POC/pocOrcamento';

import PocOrcamento from 'core/models/POC/pocOrcamento.tsx';
import MovimentoSaidaStep from 'ui/pages/POC/ResultadoOrcamento/movimentoSaidaStep.tsx';
import ModalItensEntrada from './modalItensEntrada';
import ModalItensSaida from './modalItensSaida';
import ModalImprimir from './modalImprimir';

export default function PocOrcamentoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  findTransaction,
  onOpenTransaction,
  onOpenReport,
}) {
  const formSubmit = useRef();
  const stepMovimentacaoSaida = useRef();
  const [data, setData] = useState(new PocOrcamento({}));
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [modalShow, setModalShow] = useState({});
  const [showImprimir, setShowImprimir] = useState(false);
  const gridViewProdutosEntrada = useRef();
  const gridViewFaturamento = useRef();
  const gridViewAbastecimentos = useRef();

  const onShowItensEntrada = (e) => {
    setModalShow({
      itensEntrada: true,
      nrSeqProduto: e.nrSeqProduto,
      nrSeqPocOrcamento: e.nrSeqPocOrcamento,
    });
  };

  const onShowItensSaida = (e) => {
    setModalShow({
      itensSaida: true,
      nrSeqProduto: e.nrSeqProduto,
      nrSeqPocOrcamento: e.nfvAvulsa.nrSeqPocOrcamento,
    });
  };

  const columnsProdutosEntrada = [
    { key: 'nrSeqProduto', title: 'nrSeqProduto', visible: false },
    { key: 'produto.noProduto', title: 'Produto', colorText: 'corProduto' },
    {
      key: 'localArmazenagem.noAlmoxarifadoLocal',
      title: 'Almoxarifado',
      colorText: 'corProduto',
    },
    {
      key: 'vlrFrete',
      title: 'Frete',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
      decimalPlaces: 6,
    },
    {
      key: 'vlrDesc',
      title: 'Desconto',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
      decimalPlaces: 6,
    },
    {
      key: 'qtdeItNf',
      title: 'Quantidade',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
      decimalPlaces: 6,
    },
    {
      key: 'vlrUnit',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
      decimalPlaces: 6,
    },
    {
      key: 'vlrTotal',
      title: 'Total',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
    },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onShowItensEntrada(e),
      theme: Theme.Success,
      icon: 'eye',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Visualizar',
      tooltipDirection: 'bottom',
      title: 'Visualizar',
    },
  ];

  const columnsFaturamento = [
    { key: 'nrSeqFatura', type: GridView.ColumnTypes.Checkbox, visible: false },
    { key: 'formaPagamento.noFormaPagamento', title: 'Forma Pagamento' },
    { key: 'vlrParcela', title: 'Valor', format: GridView.DataTypes.Decimal },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'noStatus',
      title: 'Status',
    },
  ];

  const geraCusto = () => {
    const datasource = data.itensSaida.filter((item) => item.vlrTotNfv > 0);

    return (
      <MovimentoSaidaStep data={datasource} modalShow={onShowItensSaida} />
    );
  };

  const naoGeraCusto = () => {
    const datasource = data.itensSaida.filter((item) => item.vlrTotNfv <= 0);

    return (
      <MovimentoSaidaStep data={datasource} modalShow={onShowItensSaida} />
    );
  };

  const stepGeraCusto = React.createElement(geraCusto);
  const stepNaoGeraCusto = React.createElement(naoGeraCusto);

  const steps = [
    {
      index: 1,
      title: 'Gera Custo',
      visible: true,
      content: () => stepGeraCusto,
    },
    {
      index: 2,
      title: 'Não Gera Custo',
      visible: true,
      content: () => stepNaoGeraCusto,
    },
  ];

  const montaTodasGrids = (resultadoOrcamento) => {
    if (gridViewProdutosEntrada && gridViewProdutosEntrada.current)
      gridViewProdutosEntrada.current.setDataSource(
        resultadoOrcamento.itensEntrada
      );

    if (gridViewFaturamento && gridViewFaturamento.current)
      gridViewFaturamento.current.setDataSource(resultadoOrcamento.faturas);

    if (gridViewAbastecimentos && gridViewAbastecimentos.current)
      gridViewAbastecimentos.current.setDataSource(
        resultadoOrcamento.abastecimentos
      );
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const resultadoOrcamento = await getPocResultadoOrcamento(registryKey);
      setData(resultadoOrcamento);

      montaTodasGrids(resultadoOrcamento);

      setLoading(false);
    } else {
      setData({});
      data.dtCad = dateNow();
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const onPrint = async (param) => {
    setShowImprimir(false);
    setLoading(true);
    const { value } = await printPocResultadoOrcamento({
      nrSeqPocOrcamento: data.nrSeqPocOrcamento,
      ...param,
    });

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const columnsAbastecimentos = [
    { key: 'nrSeqAbastecimento', visible: false },
    { key: 'dtAbastecimento', title: 'Data', format: GridView.DataTypes.Date },
    { key: 'veiculo.placa', title: 'Placa' },
    { key: 'motorista.noPessoa', title: 'Motorista' },
    { key: 'nrOdometro', title: 'Odometro' },
    {
      key: 'vlrLitro',
      title: 'Valor Litro',
      format: GridView.DataTypes.Decimal,
    },
    { key: 'qtdeLitro', title: 'Litros' },
    { key: 'vlrTotal', title: 'Total', format: GridView.DataTypes.Decimal },
  ];

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      transaction={transaction}
      isActive={isActive}
      title='Resultado Orçamento'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      removeValidate
    >
      <ToolbarButtons>
        {onPrint && (
          <ToolbarButtons.Button
            text='Imprimir'
            icon='print'
            onClick={() => setShowImprimir(true)}
          />
        )}
      </ToolbarButtons>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header
            icon={['fa', 'briefcase']}
            title='Dados do Orçamento'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-2 mb-1'>
                <Textbox label='Código' text={data?.cdProposta} readOnly />
              </div>
              <div className='col-2 mb-1'>
                <Textbox label='Revisão' text={data?.nrOrdem} readOnly />
              </div>
              <div className='col-2 mb-3'>
                <DatePicker
                  label='Validade da Proposta'
                  text={data.dtValidade}
                  maxLength={10}
                  readOnly
                  mask={MaskTypes.Date}
                  onChange={(dtValidade) => {
                    data.dtValidade = dtValidade;
                  }}
                />
              </div>
              <div className='col-5 mb-3'>
                <Textbox
                  label='Tipo Orcamento'
                  readOnly
                  text={data.noPocTipoOrcamento}
                  onChangedValue={(noPocTipoOrcamento) =>
                    setData({ ...data, noPocTipoOrcamento })
                  }
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-5 mb-3'>
                <Textbox
                  label='Cliente'
                  readOnly
                  text={data.noCliente}
                  onChangedValue={(noCliente) =>
                    setData({ ...data, noCliente })
                  }
                />
              </div>
              <div className='col-3 mb-1'>
                <Textbox label='Documento' text={data.documento} readOnly />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-5'>
                <Textbox
                  label='Local da Obra'
                  readOnly
                  text={data.noLocalObra}
                  onChangedValue={(noLocalObra) =>
                    setData({ ...data, noLocalObra })
                  }
                />
              </div>
              <div className='col-5'>
                <Textbox
                  label='Poço'
                  readOnly
                  text={data.noPoco}
                  onChangedValue={(noPoco) => setData({ ...data, noPoco })}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header
            icon={['fa', 'briefcase']}
            title='Notas Fiscais Enviadas Diretamente à Obra'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-3 align-items-end'>
              <div className='col'>
                <GridView
                  ref={gridViewProdutosEntrada}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsProdutosEntrada}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
            <div className='row mb-2 justify-content-end'>
              <div className='col-3 mb-1'>
                <Textbox
                  label={
                    <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                      Valor Total Diretamente à Obra
                    </span>
                  }
                  text={data.vlrTotalNotaEntrada}
                  style={{ width: '100%' }}
                  mask={MaskTypes.Currency}
                  readOnly
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header
            icon={['fa', 'truck']}
            title='Abastecimentos'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridViewAbastecimentos}
                  dataColumns={columnsAbastecimentos}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
            <div className='row mb-2 justify-content-end'>
              <div className='col-3 mb-1'>
                <Textbox
                  label={
                    <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                      Valor Total dos Abastecimentos
                    </span>
                  }
                  text={data.vlrTotalAbastecimentos}
                  mask={MaskTypes.Currency}
                  readOnly
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header
            icon={['fa', 'briefcase']}
            title='Movimentos de Saída'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            {data.itensSaida?.length > 0 && (
              <Steps
                propriedade={1}
                steps={steps}
                theme={Theme.Primary}
                align='horizontal'
                ref={stepMovimentacaoSaida}
              />
            )}

            <div className='row mb-2 justify-content-end'>
              <div className='col-3 mb-1'>
                <Textbox
                  label={
                    <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                      Valor Total Saídas
                    </span>
                  }
                  text={data.vlrTotalNotaSaida}
                  style={{ width: '100%' }}
                  mask={MaskTypes.Currency}
                  readOnly
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mb-2'>
        <div className='col-3 mb-1'>
          <Textbox
            label='Valor Total Diretamente à Obra'
            text={data.vlrTotalNotaEntrada}
            style={{ width: '100%' }}
            mask={MaskTypes.Currency}
            readOnly
          />
        </div>
        <div className='col-3 mb-1'>
          <Textbox
            label='Valor Total Saídas'
            text={data.vlrTotalNotaSaida}
            style={{ width: '100%' }}
            mask={MaskTypes.Currency}
            readOnly
          />
        </div>
        <div className='col-3 mb-1'>
          <Textbox
            label='Valor Total Abastecimentos'
            text={data.vlrTotalAbastecimentos}
            style={{ width: '100%' }}
            mask={MaskTypes.Currency}
            readOnly
          />
        </div>
        <div className='col-3 mb-1'>
          <Textbox
            text={data.vlrTotalObra}
            mask={MaskTypes.Currency}
            readOnly
            label={
              <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                Valor Total da Obra
              </span>
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header
            icon={['fa', 'briefcase']}
            title='Financeiro'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-3 align-items-end'>
              <div className='col'>
                <GridView
                  ref={gridViewFaturamento}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsFaturamento}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                  sumFields
                />
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col-3 mb-1'>
                <Textbox
                  text={data.vlrTotalOrcamento}
                  style={{ width: '100%' }}
                  mask={MaskTypes.Currency}
                  readOnly
                  label={
                    <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                      Valor do Orçamento
                    </span>
                  }
                />
              </div>
              <div className='col-3 mb-1'>
                <Textbox
                  label='Faturado'
                  text={data.vlrFaturado}
                  style={{ width: '100%' }}
                  mask={MaskTypes.Currency}
                  readOnly
                />
              </div>
              <div className='col-3 mb-1'>
                <Textbox
                  label='Imposto Retido'
                  text={data.vlrTotalImposto}
                  style={{ width: '100%' }}
                  mask={MaskTypes.Currency}
                  readOnly
                />
              </div>
              <div className='col-3 mb-1'>
                <Textbox
                  label='Pendente de faturamento'
                  text={data.vlrSaldoOrcamento}
                  style={{ width: '100%' }}
                  mask={MaskTypes.Currency}
                  readOnly
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalItensEntrada
            show={modalShow.itensEntrada}
            nrSeqProduto={modalShow.nrSeqProduto}
            nrSeqPocOrcamento={modalShow.nrSeqPocOrcamento}
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transaction={transaction}
            onClose={() => {
              setModalShow({ itensEntrada: false });
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalItensSaida
            show={modalShow.itensSaida}
            nrSeqProduto={modalShow.nrSeqProduto}
            nrSeqPocOrcamento={modalShow.nrSeqPocOrcamento}
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transaction={transaction}
            onClose={() => {
              setModalShow({ itensSaida: false });
            }}
          />
        </div>
      </div>
      <ModalImprimir
        show={showImprimir}
        onClose={() => setShowImprimir(false)}
        onPrint={(param) => onPrint(param)}
      />
    </CSDManutPage>
  );
}
