import React, { useRef, useEffect, useState } from 'react';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';
import { Panel, Button, GridView } from 'ui/components';

import { getEtiquetas } from 'core/services/EST/produtoCodigo';

export default function PanelEtiquetasProdutos(props) {
  const { data, setMessage, setLoading } = props;

  const gridViewEtiquetas = useRef();

  const [dataProdutosEtiquetas, setDataProdutosEtiquetas] = useState(data);

  useEffect(() => {
    if (gridViewEtiquetas && gridViewEtiquetas.current)
      gridViewEtiquetas.current.setDataSource(dataProdutosEtiquetas);
  }, []);

  const onSend = async () => {
    props.onClose();
  };

  const onClickRemoveProdutoEtiqueta = async (selectedValue, datasource) => {
    const dataEtiquetas = datasource.filter(
      (produto) => produto !== selectedValue
    );

    if (gridViewEtiquetas && gridViewEtiquetas.current)
      gridViewEtiquetas.current.setDataSource(dataEtiquetas);

    if (dataEtiquetas.length < 1) {
      onSend();
    }
  };

  const columns = [
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'noProdutoCodigo', title: 'Produto' },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveProdutoEtiqueta(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onPrintEtiquetas = async () => {
    if (dataProdutosEtiquetas.length > 0) {
      setLoading(true);

      const itensParaImprimir = gridViewEtiquetas.current
        .getDataSource()
        .filter((produto) => produto.status !== GridView.EnumStatus.Remover);

      const { value: etiquetas, status, message: msg } = await getEtiquetas(
        itensParaImprimir
      );

      if (etiquetas) {
        const linkSource = `data:application/pdf;base64, ${etiquetas}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = `Etiquetas.pdf`;
        downloadLink.click();
      }

      if (status === ResponseStatus.Success) {
        if (gridViewEtiquetas && gridViewEtiquetas.current)
          gridViewEtiquetas.current.setDataSource([]);

        setDataProdutosEtiquetas([]);
        onSend();
      }

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  return (
    <Panel>
      <Panel.Header
        icon={['fa', 'check']}
        title='Etiquetas'
        theme={Theme.Light}
      />
      <Panel.Body>
        <div className='row'>
          <div className='col-3 mb-3'>
            <Button
              outline
              theme={Theme.Success}
              template={Button.Templates.Button}
              text='Confirmar'
              onClick={onPrintEtiquetas}
            />
          </div>

          <div className='col-3 mb-3'>
            <Button
              outline
              theme={Theme.Danger}
              template={Button.Templates.Button}
              text='Cancelar'
              onClick={onSend}
            />
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-12'>
            <GridView
              ref={gridViewEtiquetas}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              offlineData
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>
      </Panel.Body>
    </Panel>
  );
}
