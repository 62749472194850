export default class VendedorNivel {
  constructor(jsonObject = {}) {
    this.nrSeqVendedorNivel = jsonObject.nrSeqVendedorNivel;
    this.cdVendedorNivel = jsonObject.cdVendedorNivel;
    this.noVendedorNivel = jsonObject.noVendedorNivel;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqVendedorNivelPai = jsonObject.nrSeqVendedorNivelPai;
    this.vlrMaximoAprovacao = jsonObject.vlrMaximoAprovacao;
    this.percDesconto = jsonObject.percDesconto;
    this.vlrMaximoDesconto = jsonObject.vlrMaximoDesconto;
  }

  toJson = () => JSON.stringify(this);
}
