// eslint-disable-next-line import/extensions
import AreaChamado from './areaChamado';

export default class TipoChamado {
  nrSeqTipoChamado: number;

  cdTipoChamado: string;

  noTipoChamado: string;

  nrSeqGrupoEmpresa: number;

  nrSeqSlaChamado: number | null;

  nrSeqAreaChamado: number;

  nrSeqPrioridadeChamado: number | null;

  area: AreaChamado;

  constructor(jsonObject: TipoChamado | {} = {}) {
    this.nrSeqTipoChamado =
      'nrSeqTipoChamado' in jsonObject ? jsonObject.nrSeqTipoChamado : 0;
    this.cdTipoChamado =
      'cdTipoChamado' in jsonObject ? jsonObject.cdTipoChamado : '';
    this.noTipoChamado =
      'noTipoChamado' in jsonObject ? jsonObject.noTipoChamado : '';
    this.nrSeqGrupoEmpresa =
      'nrSeqGrupoEmpresa' in jsonObject ? jsonObject.nrSeqGrupoEmpresa : 0;
    this.nrSeqSlaChamado =
      'nrSeqSlaChamado' in jsonObject ? jsonObject.nrSeqSlaChamado : null;
    this.nrSeqAreaChamado =
      'nrSeqAreaChamado' in jsonObject ? jsonObject.nrSeqAreaChamado : 0;
    this.nrSeqPrioridadeChamado =
      'nrSeqPrioridadeChamado' in jsonObject
        ? jsonObject.nrSeqPrioridadeChamado
        : null;
    this.area = 'area' in jsonObject ? jsonObject.area : new AreaChamado();
  }

  toJson = () => JSON.stringify(this);
}
