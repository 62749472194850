import qs from 'qs';
import Pagination from 'core/models/pagination';
import PocPocoPocEnsaio from 'core/models/POC/pocPocoPocEnsaio';
import PocTipoEnsaio from 'core/models/POC/tipoEnsaio';
import PocTecnicaMedida from 'core/models/POC/tecnicaMedida';
import api from '../api';

const baseURL = `/POC/PocPocoPocEnsaio`;

export const getTipoEnsaioAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/TipoEnsaioAutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    tiposEnsaio: value?.map((json) => new PocTipoEnsaio(json)) ?? [],
  };
};

export const getTecnicaMedidaAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/TecnicaMedidaAutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    tecnicasMedida: value?.map((json) => new PocTecnicaMedida(json)) ?? [],
  };
};
export const getPocPocoPocEnsaioList = async (filters) => {
  const response = await api.get(`/$PocPocoPocEnsaio/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPocoPocEnsaio(json)) ?? [],
  };
};

export const getPocPocoPocEnsaioPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPocoPocEnsaio(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocPocoPocEnsaio = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocPocoPocEnsaio(value) : {};
};

export const savePocPocoPocEnsaio = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePocPocoPocEnsaio = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPocPocoPocEnsaio = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocPocoPocEnsaio = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocPocoPocEnsaio = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
