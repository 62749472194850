import React from 'react';
import { FontAwesomeIcon } from 'ui/components';
import MenuTransacao from './menuTransacao';

const MenuItem = (props) => {
  const { menu, minified } = props;

  // adaptação para não precisar alterar no banco (DEPOOIS QUE IMPLANTAR REACT TIRAR TODO ESSE CODIGO E AJUSTAR OS ICONES NO BANCO)
  let menuImagem =
    menu.cssImagem && menu.cssImagem.replace('fas ', '').split(' ')[0];

  if (menuImagem && menuImagem.startsWith('fa-'))
    menuImagem = menuImagem.slice(3);

  menuImagem = menuImagem?.toLowerCase();

  const toogleID = menu.noMenu.replaceAll(' ', '');

  return (
    <li>
      <div
        className='menu-item'
        data-bs-toggle='collapse'
        data-bs-target={`#${toogleID}`}
        role='button'
        aria-expanded='false'
        aria-controls={toogleID}
      >
        {menu.cssImagem && (
          <FontAwesomeIcon className='me-1' icon={menuImagem} />
        )}
        {!minified && menu.noMenu}
        {!minified && (menu.menus.length > 0 || menu.transacoes.length > 0) && (
          <FontAwesomeIcon className='menu-arrow' icon='chevron-right' />
        )}
      </div>
      <ul className='collapse' id={toogleID}>
        {(menu.menus ?? []).map((subMenu) => (
          <MenuItem
            key={`sub-${subMenu.uniqueId}`}
            menu={subMenu}
            onOpenTransaction={props.onOpenTransaction}
          />
        ))}
        {(menu.transacoes ?? []).map((transacao) => (
          <MenuTransacao
            key={transacao.nrSeqTransacao}
            transacao={transacao}
            onOpenTransaction={props.onOpenTransaction}
          />
        ))}
      </ul>
    </li>
  );
};

export default MenuItem;
