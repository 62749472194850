import VendedorTelefonia from 'core/models/TEL/vendedorTelefonia';
import Vendedor from 'core/models/VEN/vendedor';
import api from '../api';

export const getVendedorTelefoniaAutoComplete = async (filters) => {
  const response = await api.get(`/VendedorTelefonia/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    vendedores: value?.map((json) => new Vendedor(json)) ?? [],
  };
};

export const getVendedorTelefoniaList = async (filters) => {
  const response = await api.get(`/VendedorTelefonia/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    vendedoresTelefonia:
      value?.map((json) => new VendedorTelefonia(json)) ?? [],
  };
};
export const getVendedorTelefonia = async (id) => {
  const response = await api.get(`/VendedorTelefonia/`, { params: { id } });

  const { vendedorTelefonia } = response.data ?? {};

  return vendedorTelefonia ? new VendedorTelefonia(vendedorTelefonia) : {};
};

export const saveVendedorTelefonia = async (payload) => {
  const response = await api.post(`/VendedorTelefonia/Salvar`, payload);
  return response.data ?? {};
};

export const deleteVendedorTelefonia = async (id) => {
  const response = await api.delete(`/VendedorTelefonia/Excluir`, {
    params: { id },
  });
  return response.data ?? {};
};
