import qs from 'qs';
import Pagination from 'core/models/pagination';
import ChequeSituacao from 'core/models/FIN/chequeSituacao';
import api from '../api';

const baseURL = `/FIN/ChequeSituacao`;

export const getChequeSituacaoList = async (filters) => {
  const response = await api.get(`/$ChequeSituacao/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ChequeSituacao(json)) ?? [],
  };
};

export const getChequeSituacaoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ChequeSituacao(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getChequeSituacao = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ChequeSituacao(value) : {};
};

export const saveChequeSituacao = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteChequeSituacao = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printChequeSituacao = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelChequeSituacao = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllChequeSituacao = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getChequeSituacaoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    situacoes: value?.map((json) => new ChequeSituacao(json)) ?? [],
  };
};
