export default class ModeloManutencaoPreventiva {
  constructor(jsonObject = {}) {
    this.nrSeqVeiculomodelo = jsonObject.nrSeqVeiculomodelo;
    this.cdManutencaoPreventiva = jsonObject.cdManutencaoPreventiva;
    this.noManutencaoPreventiva = jsonObject.noManutencaoPreventiva;
    this.valor = jsonObject.valor;
  }

  toJson = () => JSON.stringify(this);
}
