import React, { useEffect, useRef, useState } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  getFilteredManutencaoVeiculoOSPagined,
  printReportManutencaoVeiculoOS,
  deleteAllManutencaoVeiculoOS,
} from 'core/services/FRO/manutencaoVeiculoOS';
import {
  CSDSelPage,
  GridView,
  Panel,
  Textbox,
  RadioButton,
  Autocomplete,
  DatePicker,
  Button,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getVeiculoAutoCompleteResumido } from 'core/services/FRO/veiculo';
import { getCondicaoPagamentoAutoComplete } from 'core/services/FIN/condicaoPagamento';
import { getOficinaAutocomplete } from 'core/services/FRO/oficina';
import { getUsuarioAutoComplete } from 'core/services/SEG/usuario';

const columns = [
  { key: 'nrSeqManutencaoVeiculoOS', type: GridView.ColumnTypes.Checkbox },
  { key: 'status', title: 'Status', sortKey: 'flgConcluido' },
  { key: 'cdManutencaoVeiculoOS', title: 'Código' },
  {
    key: 'veiculo.noVeiculo',
    title: 'Veículo',
    sortKey: 'noVeiculo',
  },
  {
    key: 'veiculo.noVeiculosVinculados',
    title: 'Veículos Vinculados',
    sortable: false,
  },
  { key: 'nrOdometro', title: 'Odômetro' },
  {
    key: 'pessoaOfi.noOficina',
    title: 'Oficina',
    sortKey: 'noPessoa',
  },
  {
    key: 'vlrOs',
    title: 'Valor Orçado',
    visible: false,
    format: GridView.DataTypes.Money,
  },
  {
    key: 'vlrFechamentoOS',
    title: 'Valor de Fechamento',
    format: GridView.DataTypes.Money,
  },
  {
    key: 'condicaoPagamento.noCondicaoPagamento',
    title: 'Condição Pagamento',
    sortKey: 'noCondicaoPagamento',
  },
  {
    key: 'dtCad',
    title: 'Data de Cadastro',
    visible: false,
    format: GridView.DataTypes.Date,
  },
  { key: 'dtSaida', title: 'Data de Saída', format: GridView.DataTypes.Date },
  {
    key: 'dtPrevistaRetorno',
    title: 'Previsão de Retorno',
    format: GridView.DataTypes.Date,
  },
  {
    key: 'dtAbertura',
    title: 'Data de Abertura',
    visible: false,
    format: GridView.DataTypes.Date,
  },
  {
    key: 'dtAlteracao',
    title: 'Data de Alteração',
    visible: false,
    format: GridView.DataTypes.Date,
  },
  {
    key: 'dtFechamento',
    title: 'Data de Fechamento',
    visible: false,
    format: GridView.DataTypes.Date,
  },
  {
    key: 'usuarioCad.noLogin',
    title: 'Cadastrado Por',
    sortKey: 'noUsuarioCad',
    visible: false,
    sortable: false,
  },
  {
    key: 'usuarioAlt.noLogin',
    title: 'Alterado Por',
    sortKey: 'noUsuarioAlt',
    visible: false,
    sortable: false,
  },
  {
    key: 'usuarioAut.noLogin',
    title: 'Autorizado Por',
    sortKey: 'noUsuarioAut',
    visible: false,
    sortable: false,
  },
  {
    key: 'usuarioSol.noLogin',
    title: 'Solicitado Por',
    sortKey: 'noUsuarioSol',
    sortable: false,
  },
];

export default function ManutencaoVeiculoOS({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({
    flgConcluido: 'All',
    flgExpandeFiltros: false,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const obj = { ...filtros };

    if (obj.flgConcluido === 'S') {
      obj.flgConcluido = true;
    } else if (obj.flgConcluido === 'N') {
      obj.flgConcluido = false;
    } else {
      obj.flgConcluido = undefined;
    }

    const { data, pagination } = await getFilteredManutencaoVeiculoOSPagined(
      params ?? obj
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setFiltros({ flgConcluido: 'All' });
    setLoading(false);
  };

  useEffect(() => {
    async function load() {
      setLoading(true);

      search();

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };

    if (filters.flgConcluido === 'S') {
      filters.flgConcluido = true;
    } else if (filters.flgConcluido === 'N') {
      filters.flgConcluido = false;
    } else {
      filters.flgConcluido = undefined;
    }
    // setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = async (registryKey = null) => {
    setLoading(true);

    await onOpenPage(
      transaction.noTransacao,
      'ManutencaoVeiculoOS/manutencao',
      PageTypes.Maintenence,
      registryKey
    );

    setLoading(false);
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqManutencaoVeiculoOS = sourceRow.find(
      (e) => e.key === 'nrSeqManutencaoVeiculoOS'
    );
    onOpenMaintenance(nrSeqManutencaoVeiculoOS.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printReportManutencaoVeiculoOS(filtros);

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllManutencaoVeiculoOS(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const handleFilters = async () => {
    setFiltros({
      ...filtros,
      flgExpandeFiltros: !filtros.flgExpandeFiltros,
    });
  };

  // #region FILTROS

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchVeiculo = async (e) => {
    const {
      status,
      message: msg,
      veiculos,
    } = await getVeiculoAutoCompleteResumido({
      placa: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onSearchCondicaoPagamento = async (e) => {
    const {
      status,
      message: msg,
      condicoesPagamento,
    } = await getCondicaoPagamentoAutoComplete({ noCondicaoPagamento: e });
    if (msg) onSetMessage(status, msg);
    return condicoesPagamento;
  };

  const onSearchOficina = async (e) => {
    const { status, message: msg, oficinas } = await getOficinaAutocomplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);
    return oficinas;
  };

  const onSearchUsuario = async (e) => {
    const response = await getUsuarioAutoComplete({
      noLogin: e,
    });
    if (response.message) onSetMessage(response.status, response.message);
    return response.data;
  };
  // #endregion

  const status = [
    {
      text: 'Todos',
      value: 'All',
    },
    {
      text: 'Pendente',
      value: 'N',
    },
    {
      text: 'Concluído',
      value: 'S',
    },
  ];

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Manutenção Veículo OS'
      loading={loading}
      onSearch={search}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <Panel className='mb-2'>
          <Panel.Header title='Filtros' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-2'>
                <Autocomplete
                  label='Veículo'
                  searchDataSource={onSearchVeiculo}
                  selectedItem={filtros.veiculo}
                  onSelectItem={(veiculo) => {
                    setFiltros({
                      ...filtros,
                      veiculo,
                      nrSeqVeiculo: veiculo.nrSeqVeiculo,
                    });
                  }}
                  dataSourceTextProperty='noVeiculo'
                />
              </div>
              <div className='col-1'>
                <Textbox
                  label='Odômetro'
                  text={filtros.nrOdometro}
                  mask={MaskTypes.Integer}
                  onChangedValue={(nrOdometro) =>
                    setFiltros({ ...filtros, nrOdometro })
                  }
                />
              </div>
              {/* Data Cadastro */}
              <div className='col'>
                <DatePicker
                  label='Cadastro De:'
                  text={filtros.dtCadastroInicio}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtCadastroInicio) =>
                    setFiltros({ ...filtros, dtCadastroInicio })
                  }
                />
              </div>
              <div className='col'>
                <DatePicker
                  label='Cadastro Até:'
                  text={filtros.dtCadastroFim}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtCadastroFim) =>
                    setFiltros({ ...filtros, dtCadastroFim })
                  }
                />
              </div>
              <div className='col-3 d-flex flex-column align-items-start flex-wrap-nowrap'>
                <RadioButton
                  label='Status da OS'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.flgConcluido ?? 'All'}
                  buttons={status}
                  onChange={(flgConcluido) => {
                    setFiltros({
                      ...filtros,
                      flgConcluido,
                    });
                  }}
                />
              </div>
              {/* Botão Mais/Menos filtros */}
              <div className='col-2 d-flex justify-content-end align-items-end'>
                {!filtros.flgExpandeFiltros ? (
                  <Button
                    outline
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    icon='plus'
                    text='Mais Filtros'
                    onClick={handleFilters}
                  />
                ) : (
                  <Button
                    outline
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    icon='minus'
                    text='Menos Filtros'
                    onClick={handleFilters}
                  />
                )}
              </div>
            </div>

            {filtros.flgExpandeFiltros && (
              <>
                <div className='row mb-3'>
                  <div className='col-2'>
                    <Textbox
                      maxLength={8}
                      label='Código'
                      text={filtros.cdManutencaoVeiculoOS}
                      mask={MaskTypes.Integer}
                      onChangedValue={(cdManutencaoVeiculoOS) =>
                        setFiltros({ ...filtros, cdManutencaoVeiculoOS })
                      }
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='Observação OS'
                      text={filtros.obsOS}
                      onChangedValue={(obsOS) =>
                        setFiltros({ ...filtros, obsOS })
                      }
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col mb-3'>
                    <Autocomplete
                      label='Oficina'
                      searchDataSource={onSearchOficina}
                      selectedItem={filtros.pessoaOfi}
                      onSelectItem={(pessoaOfi) => {
                        setFiltros({
                          ...filtros,
                          pessoaOfi,
                          nrSeqPessoaOfi: pessoaOfi.nrSeqPessoaOfi,
                        });
                      }}
                      dataSourceTextProperty='noOficina'
                    />
                  </div>
                  <div className='col mb-3'>
                    <Autocomplete
                      label='Condição de Pagamento'
                      searchDataSource={onSearchCondicaoPagamento}
                      selectedItem={filtros.condicaoPagamento}
                      onSelectItem={(condicaoPagamento) => {
                        setFiltros({
                          ...filtros,
                          condicaoPagamento,
                          nrSeqCondicaoPagamento:
                            condicaoPagamento.nrSeqCondicaoPagamento,
                        });
                      }}
                      dataSourceTextProperty='noCondicaoPagamento'
                    />
                  </div>
                  <div className='col-2 mb-3'>
                    <Textbox
                      label='Valor no Orçamento'
                      text={filtros.vlrOs}
                      mask={MaskTypes.Currency}
                      onChangedValue={(vlrOs) =>
                        setFiltros({ ...filtros, vlrOs })
                      }
                    />
                  </div>
                  <div className='col-2 mb-3'>
                    <Textbox
                      label='Valor no Fechamento'
                      text={filtros.vlrFechamentoOS}
                      mask={MaskTypes.Currency}
                      onChangedValue={(vlrFechamentoOS) =>
                        setFiltros({ ...filtros, vlrFechamentoOS })
                      }
                    />
                  </div>
                </div>
                {/* Filtros de Users */}
                <div className='row mb-4'>
                  <div className='col'>
                    <Autocomplete
                      label='Cadastrado Por'
                      searchDataSource={onSearchUsuario}
                      selectedItem={filtros.usuarioCad}
                      onSelectItem={(usuarioCad) => {
                        setFiltros({
                          ...filtros,
                          usuarioCad,
                          nrSeqUsuarioCad: usuarioCad.nrSeqUsuario,
                        });
                      }}
                      dataSourceTextProperty='noLogin'
                    />
                  </div>
                  <div className='col'>
                    <Autocomplete
                      label='Solicitado Por'
                      searchDataSource={onSearchUsuario}
                      selectedItem={filtros.usuarioSol}
                      onSelectItem={(usuarioSol) => {
                        setFiltros({
                          ...filtros,
                          usuarioSol,
                          nrSeqUsuarioSol: usuarioSol.nrSeqUsuario,
                        });
                      }}
                      dataSourceTextProperty='noLogin'
                    />
                  </div>
                  <div className='col'>
                    <Autocomplete
                      label='Autorizado Por'
                      searchDataSource={onSearchUsuario}
                      selectedItem={filtros.usuarioAut}
                      onSelectItem={(usuarioAut) => {
                        setFiltros({
                          ...filtros,
                          usuarioAut,
                          nrSeqUsuarioAut: usuarioAut.nrSeqUsuario,
                        });
                      }}
                      dataSourceTextProperty='noLogin'
                    />
                  </div>
                  <div className='col'>
                    <Autocomplete
                      label='Alterado Por'
                      searchDataSource={onSearchUsuario}
                      selectedItem={filtros.usuarioAlt}
                      onSelectItem={(usuarioAlt) => {
                        setFiltros({
                          ...filtros,
                          usuarioAlt,
                          nrSeqUsuarioAlt: usuarioAlt.nrSeqUsuario,
                        });
                      }}
                      dataSourceTextProperty='noLogin'
                    />
                  </div>
                </div>
                {/* Filtros por Periodo */}
                <div className='row mb-3'>
                  {/* Data Abertura */}
                  <div className='col'>
                    <div className='card'>
                      <div className='card-header bg-light px-3 pt-2 py-2 '>
                        <p className='my-0'>Data de Abertura</p>
                      </div>
                      <div className='card-body'>
                        <div className='row mb-2'>
                          <DatePicker
                            label='De:'
                            text={filtros.dtAberturaInicio}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtAberturaInicio) =>
                              setFiltros({ ...filtros, dtAberturaInicio })
                            }
                          />
                        </div>
                        <div className='row mb-2'>
                          <DatePicker
                            label='Até:'
                            text={filtros.dtAberturaFim}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtAberturaFim) =>
                              setFiltros({ ...filtros, dtAberturaFim })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Data de Saida */}
                  <div className='col'>
                    <div className='card' style={{ height: '100%' }}>
                      <div className='card-header bg-light px-3 pt-2 py-2 '>
                        <p className='my-0'>Data de Saída</p>
                      </div>
                      <div className='card-body'>
                        <div className='row mb-2'>
                          <DatePicker
                            label='De:'
                            text={filtros.dtSaidaInicio}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtSaidaInicio) =>
                              setFiltros({ ...filtros, dtSaidaInicio })
                            }
                          />
                        </div>
                        <div className='row mb-2'>
                          <DatePicker
                            label='Até:'
                            text={filtros.dtSaidaFim}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtSaidaFim) =>
                              setFiltros({ ...filtros, dtSaidaFim })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Data Prevista de Retorno */}
                  <div className='col'>
                    <div className='card'>
                      <div className='card-header bg-light px-3 pt-2 py-2 '>
                        <p className='my-0'>Previsão de Retorno</p>
                      </div>
                      <div className='card-body'>
                        <div className='row mb-2'>
                          <DatePicker
                            label='De:'
                            text={filtros.dtPrevistaRetornoInicio}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtPrevistaRetornoInicio) =>
                              setFiltros({
                                ...filtros,
                                dtPrevistaRetornoInicio,
                              })
                            }
                          />
                        </div>
                        <div className='row mb-2'>
                          <DatePicker
                            label='Até:'
                            text={filtros.dtPrevistaRetornoFim}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtPrevistaRetornoFim) =>
                              setFiltros({ ...filtros, dtPrevistaRetornoFim })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Data de Alteração */}
                  <div className='col'>
                    <div className='card'>
                      <div className='card-header bg-light px-3 pt-2 py-2 '>
                        <p className='my-0'>Data de Alteração</p>
                      </div>
                      <div className='card-body'>
                        <div className='row mb-2'>
                          <DatePicker
                            label='De:'
                            text={filtros.dtAlteracaoInicio}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtAlteracaoInicio) =>
                              setFiltros({ ...filtros, dtAlteracaoInicio })
                            }
                          />
                        </div>
                        <div className='row mb-2'>
                          <DatePicker
                            label='Até:'
                            text={filtros.dtAlteracaoFim}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtAlteracaoFim) =>
                              setFiltros({ ...filtros, dtAlteracaoFim })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Data de Fechamento */}
                  <div className='col'>
                    <div className='card'>
                      <div className='card-header bg-light px-3 pt-2 py-2 '>
                        <p className='my-0'>Data de Fechamento</p>
                      </div>
                      <div className='card-body'>
                        <div className='row mb-2'>
                          <DatePicker
                            label='De:'
                            text={filtros.dtFechamentoInicio}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtFechamentoInicio) =>
                              setFiltros({ ...filtros, dtFechamentoInicio })
                            }
                          />
                        </div>
                        <div className='row mb-2'>
                          <DatePicker
                            label='Até:'
                            text={filtros.dtFechamentoFim}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtFechamentoFim) =>
                              setFiltros({ ...filtros, dtFechamentoFim })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Panel.Body>
        </Panel>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            canControlVisibility
            enableExcelExport
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
