import CentroDeCusto from 'core/models/TEL/centroDeCusto';

export default class ClienteLinhaCentroDeCusto {
  constructor(jsonObject = {}) {
    this.nrSeqClienteLinhaCentroDeCusto =
      jsonObject.nrSeqClienteLinhaCentroDeCusto;

    this.vlrPercentual = jsonObject.vlrPercentual;

    this.nrSeqClienteLinha = jsonObject.nrSeqClienteLinha;

    this.nrSeqCentroDeCusto = jsonObject.nrSeqCentroDeCusto;

    this.centroDeCusto = new CentroDeCusto(jsonObject.centroDeCusto ?? {});
  }

  toJson = () => JSON.stringify(this);
}
