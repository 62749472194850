import React, { useState, useEffect } from 'react';
import {
  Loader,
  Modal,
  Notification,
  Textbox,
  Button,
  DatePicker,
  Autocomplete,
} from 'ui/components';
import { BootstrapSizes, Theme } from 'ui/Helpers/enums';
import { Message } from 'ui/Helpers/interfaces';
import { IPneuModeloRecapagem } from 'core/interfaces/FRO/pneuModeloRecapagem';
import { IPneu } from 'core/interfaces/FRO/pneu';
import { getPneuModeloRecapagemAutocomplete } from 'core/services/FRO/pneuModeloRecapagem';
// @ts-expect-error
import ModalPneuModeloRecapagem from './ModalPneuModeloRecapagem.tsx';

interface IProps {
  show: any;
  onClose: () => void;
}

interface IPneuRecapagem {
  pneu: IPneu;
  kmInicio: number;
  kmFim: number | null;
  kmAcumulado: number | null;
  dtFim: string | null;
  nomeBanda: string;
  pneuRecapagem: any;
  pneuModeloRecapagem: IPneuModeloRecapagem;
}

export default function ModalRecapagem({ show, onClose }: IProps) {
  const [message, setMessage] = useState<Message | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [recapagem, setRecapagem] = useState<IPneuRecapagem | null>(null);
  const [modalShow, setModalShow] = useState<boolean>(false);

  useEffect(() => {
    if (show.showPneuRecapagem === true) {
      setLoading(true);
      setRecapagem(show.recapagem);
      setLoading(false);
    }
  }, [show.showPneuRecapagem]);

  const onSearchModeloRecapagem = async (filter: string) => {
    const { data: modelosRecapagem } = await getPneuModeloRecapagemAutocomplete(
      {
        noPneuModeloRecapagem: filter,
      }
    );

    return modelosRecapagem;
  };

  return (
    // @ts-expect-error
    <Modal
      show={show.showPneuRecapagem}
      title='Cadastro Rápido de Modelo de Recapagem'
      size={BootstrapSizes.Large}
      onClose={onClose}
    >
      <Loader loading={loading} />
      {message && (
        <div className='m-3'>
          {/* @ts-expect-error */}
          <Notification
            message={message.message}
            theme={message.theme}
            onClose={() => setMessage(null)}
          />
        </div>
      )}

      {/* @ts-expect-error */}
      <Modal.Body>
        <div className='row mb-2'>
          <div className='col'>
            {/* @ts-expect-error */}
            <Textbox
              label='Pneu'
              text={recapagem?.pneu.cdPneu}
              readOnly
              onChangedValue={(pneu: IPneu) =>
                // @ts-expect-error
                setRecapagem({ ...recapagem, pneu })
              }
            />
          </div>
          <div className='col'>
            {/* @ts-expect-error */}
            <Textbox
              label='Km Início'
              text={recapagem?.kmInicio}
              readOnly
              onChangedValue={(kmInicio: number) =>
                // @ts-expect-error
                setRecapagem({ ...recapagem, kmInicio })
              }
            />
          </div>
          <div className='col'>
            {/* @ts-expect-error */}
            <Textbox
              label='Km Final'
              text={recapagem?.kmFim}
              readOnly
              onChangedValue={(kmFim: number) =>
                // @ts-expect-error
                setRecapagem({ ...recapagem, kmFim })
              }
            />
          </div>
          <div className='col'>
            {/* @ts-expect-error */}
            <Textbox
              label='Km Acumulado'
              text={recapagem?.kmAcumulado}
              readOnly
              onChangedValue={(kmAcumulado: number) =>
                // @ts-expect-error
                setRecapagem({ ...recapagem, kmAcumulado })
              }
            />
          </div>
          <div className='col-3'>
            <DatePicker
              label='Data Fim'
              text={recapagem?.dtFim}
              readOnly
              /* @ts-expect-error */
              onChange={(dtFim) => setRecapagem({ ...recapagem, dtFim })}
            />
          </div>
        </div>

        <div className='row mb-4'>
          <div className='col-3'>
            {/* @ts-expect-error */}
            <Textbox
              label='Nome da Banda'
              text={recapagem?.nomeBanda}
              onChangedValue={(nomeBanda: number) =>
                // @ts-expect-error
                setRecapagem({
                  ...recapagem,
                  pneuRecapagem: { ...recapagem?.pneuRecapagem, nomeBanda },
                })
              }
            />
          </div>
          <div className='col'>
            <Autocomplete
              label='Modelo da Recapagem'
              searchDataSource={(e: string) => onSearchModeloRecapagem(e)}
              selectedItem={recapagem?.pneuModeloRecapagem}
              onSelectItem={(pneuModeloRecapagem: IPneuModeloRecapagem) => {
                // @ts-expect-error
                setRecapagem({
                  ...recapagem,
                  pneuRecapagem: {
                    ...recapagem?.pneuRecapagem,
                    nrSeqPneuModeloRecapagem:
                      pneuModeloRecapagem.nrSeqPneuModeloRecapagem,
                  },
                  pneuModeloRecapagem,
                });
              }}
              dataSourceTextProperty='noPneuModeloRecapagem'
            />
          </div>
          <div className='col d-flex justify-content-end align-items-end'>
            <Button
              text='Cadastrar Modelo de Recapagem'
              outline
              // icon='plus'
              theme={Theme.Success}
              onClick={() => setModalShow(true)}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col d-flex justify-content-end align-items-end'>
            <Button
              text='Enviar para Recapagem'
              theme={Theme.Success}
              // @ts-expect-error
              onClick={() => onClose({ recapagem, enviaRecapagem: true })}
            />
          </div>
        </div>
      </Modal.Body>

      <ModalPneuModeloRecapagem
        show={modalShow}
        onClose={() => {
          setModalShow(false);
        }}
      />
    </Modal>
  );
}
