import Usuario from 'core/models/SEG/usuario';

export default class TransacaoPaginaConfiguracao {
  constructor(jsonObject = {}) {
    this.nrSeqTransacaoPaginaConfig = jsonObject.nrSeqTransacaoPaginaConfig;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.noPaginaWeb = jsonObject.noPaginaWeb;
    this.noCampo = jsonObject.noCampo;
    this.noLabel = jsonObject.noLabel;
    this.flgVisivel = jsonObject.flgVisivel;
    this.flgObrigatorio = jsonObject.flgObrigatorio;
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.usuario = new Usuario(jsonObject.usuario ?? {});
    this.flgHabilitado = jsonObject.flgHabilitado;
    this.noDefaultValue = jsonObject.noDefaultValue;
    this.nrSeqTransacaoPagina = jsonObject.nrSeqTransacaoPagina;
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
