import qs from 'qs';
import Pagination from 'core/models/pagination';
import Pedagio from 'core/models/FRO/pedagio';
import ResumoPedagio from 'core/models/FRO/resumoPedagios';
import TituloPagar from 'core/models/FIN/tituloPagar';
import { MimeTypes } from 'ui/Helpers/utils';
import api from '../api';

const baseURL = `/FRO/Pedagio`;

export const getPedagioList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Pedagio(json)) ?? [],
  };
};

export const GetPedagioSemAcerto = async (filters) => {
  const response = await api.post(`${baseURL}/GetPedagioSemAcerto`, filters);
  const { value } = response.data ?? {};
  return value;
};

export const getPedagioPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Pedagio(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPedagio = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Pedagio(value) : {};
};

export const savePedagio = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePedagio = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPedagio = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPedagio = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPedagio = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const postUploadPedagios = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('extension', MimeTypes.getExtension(file.type));

  const response = await api.post(`${baseURL}/Upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  const { status, message, value } = response.data ?? {};
  const {
    pedagios,
    resumoPedagios,
    tituloPagarGerado,
    pedagiosInconsistentes,
  } = value ?? {};

  return {
    status,
    message,
    pedagios: pedagios?.map((json) => new Pedagio(json)) ?? [],
    resumoPedagios:
      resumoPedagios?.map((json) => new ResumoPedagio(json)) ?? [],
    tituloPagarGerado: tituloPagarGerado
      ? new TituloPagar(tituloPagarGerado)
      : {},
    pedagiosInconsistentes:
      pedagiosInconsistentes?.map((json) => new ResumoPedagio(json)) ?? [],
  };
};

export const postImportPedagios = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('extension', MimeTypes.getExtension(file.type));

  const response = await api.post(`${baseURL}/Import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data ?? {};
};

export const postImportPedagiosComFinanceiro = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('extension', MimeTypes.getExtension(file.type));

  const response = await api.post(`${baseURL}/ImportWithFinancial`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data ?? {};
};
