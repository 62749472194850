import Fornecedor from 'core/models/FIN/fornecedor';
import ColetaDestinatario from 'core/models/FRO/coletaDestinatario';
import Cliente from 'core/models/FIN/cliente';
import Cidade from 'core/models/SEG/cidade';

export default class NfControleMercadoriaModel {
  nrSeqNfControleMercadoriaHist: number;

  nrOrdem: number;

  nrSeqNf: number;

  serieForNf: number;

  nrForNf: number;

  nrSeqColetaEmbarque: number;

  nrSeqPessoaFor: number;

  fornecedor: Fornecedor;

  nrSeqPessoaDes: number;

  destinatario: ColetaDestinatario;

  nrSeqPessoaCli: number;

  cliente: Cliente;

  cidadeEntrega: Cidade;

  nrSeqControleMercadoriaDesembarque: number;

  nrSeqControleMercadoriaEmbarque: number;

  cdColetaEmbarque: string;

  statusCte: string;

  averbado: string;

  noColorStatus: string;

  flgMostraTransmitir: boolean;

  nrSeqViagem: number;

  flgReentrega: boolean;

  constructor(jsonObject: NfControleMercadoriaModel) {
    this.nrSeqNfControleMercadoriaHist =
      jsonObject.nrSeqNfControleMercadoriaHist;

    this.nrOrdem = jsonObject.nrOrdem;

    this.nrSeqNf = jsonObject.nrSeqNf;
    this.serieForNf = jsonObject.serieForNf;
    this.nrForNf = jsonObject.nrForNf;

    this.nrSeqColetaEmbarque = jsonObject.nrSeqColetaEmbarque;

    this.nrSeqPessoaFor = jsonObject.nrSeqPessoaFor;
    this.fornecedor = new Fornecedor(jsonObject.fornecedor ?? {});

    this.nrSeqPessoaDes = jsonObject.nrSeqPessoaDes;
    this.destinatario = new ColetaDestinatario(jsonObject.destinatario ?? {});

    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.cliente = new Cliente(jsonObject.cliente ?? {});

    this.cidadeEntrega = new Cidade(jsonObject.cidadeEntrega ?? {});

    this.nrSeqControleMercadoriaDesembarque =
      jsonObject.nrSeqControleMercadoriaDesembarque;
    this.nrSeqControleMercadoriaEmbarque =
      jsonObject.nrSeqControleMercadoriaEmbarque;

    this.cdColetaEmbarque = jsonObject.cdColetaEmbarque;
    this.statusCte = jsonObject.statusCte;
    this.averbado = jsonObject.averbado;
    this.noColorStatus = jsonObject.noColorStatus;
    this.flgMostraTransmitir = jsonObject.flgMostraTransmitir;
    this.nrSeqViagem = jsonObject.nrSeqViagem;
    this.flgReentrega = jsonObject.flgReentrega;
  }

  toJson = () => JSON.stringify(this);
}
