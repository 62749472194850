export default class EmailConta {
  constructor(jsonObject = {}) {
    this.nrSeqEmailConta = jsonObject.nrSeqEmailConta;
    this.cdEmailConta = jsonObject.cdEmailConta;
    this.msgEmailConta = jsonObject.msgEmailConta;
    this.ccoEmail = jsonObject.ccoEmail;
    this.nrSeqOperadora = jsonObject.nrSeqOperadora;
    this.noTituloemail = jsonObject.noTituloEmail;
    this.flgSms = jsonObject.flgSms;
    this.noImagem = jsonObject.noImagem;
    this.tipo = jsonObject.tipo;
    this.tamanho = jsonObject.tamanho;
  }

  toJson = () => JSON.stringify(this);
}
