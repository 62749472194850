import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getProdutoMarcaPagined,
  printProdutoMarca,
  deleteAllProdutoMarca,
} from 'core/services/EST/produtoMarca';
import { CSDSelPage, GridView, Textbox } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';

const columns = [
  { key: 'nrSeqProdutoMarca', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdProdutoMarca', title: 'Código' },
  { key: 'noProdutoMarca', title: 'Descrição' },
];

export default function ProdutoMarca({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getProdutoMarcaPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getProdutoMarcaPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ProdutoMarca/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqProdutoMarca = sourceRow.find(
      (e) => e.key === 'nrSeqProdutoMarca'
    );
    onOpenMaintenance(nrSeqProdutoMarca.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printProdutoMarca(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllProdutoMarca(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Produto Marca'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-2 mb-3'>
          <Textbox
            label='Código'
            text={filtros.cdProdutoMarca}
            mask={MaskTypes.Integer}
            onChangedValue={(cdProdutoMarca) =>
              setFiltros({ ...filtros, cdProdutoMarca })
            }
          />
        </div>
        <div className='col-5 mb-3'>
          <Textbox
            maxLength={40}
            label='Descrição'
            text={filtros.noProdutoMarca}
            onChangedValue={(noProdutoMarca) =>
              setFiltros({ ...filtros, noProdutoMarca })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            enableExcelExport
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
