import Convenio from './convenio';

export default class PlanoConvenio {
  constructor(jsonObject = {}) {
    this.nrSeqPlanoConvenio = jsonObject.nrSeqPlanoConvenio;
    this.fLgMensal = jsonObject.fLgMensal;
    this.flgFaixaEtaria = jsonObject.flgFaixaEtaria;
    this.nrSeqConvenio = jsonObject.nrSeqConvenio;
    this.noPlanoConvenio = jsonObject.noPlanoConvenio;
    this.flgAtivo = jsonObject.flgAtivo;
    this.flgGeraLancamentoManual = jsonObject.flgGeraLancamentoManual;
    this.convenio = new Convenio(jsonObject.convenio ?? {});
  }

  toJson = () => JSON.stringify(this);
}
