import React, { useState } from 'react';
import { cancelDemand } from 'core/services/HELP';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { Button, Loader, Modal } from 'ui/components';

export default function ModalConfirmarCancelamento({
  show,
  onClose,
  data,
  setData,
  onSetMessage,
}) {
  const [loading, setLoading] = useState(false);

  const onCancelaDemanda = async () => {
    const { status, message: msg, value } = await cancelDemand(
      data.nrSeqDemanda
    );

    if (status === ResponseStatus.Success) {
      setData(value);
    }

    onSetMessage(status, msg);
  };

  const onYes = async () => {
    setLoading(true);

    await onCancelaDemanda();

    setLoading(false);

    onClose();
  };

  return (
    <Modal
      show={show}
      title='Cancelar Demanda'
      onClose={() => onClose()}
      size={BootstrapSizes.Large}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        <h3 className='text-center'>
          Tem certeza que deseja cancelar a demanda?
        </h3>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Sim'
          theme={Theme.Success}
          size={BootstrapSizes.Large}
          className='px-5'
          onClick={() => onYes()}
        />
        <Button
          text='Não'
          theme={Theme.Danger}
          size={BootstrapSizes.Large}
          className='px-5'
          onClick={() => onClose()}
        />
      </Modal.Footer>
    </Modal>
  );
}
