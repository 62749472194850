import qs from 'qs';
import Pagination from 'core/models/pagination';
import AreaChamada from 'core/models/TEL/areaChamada';
import api from '../api';

const baseURL = `/TEL/AreaChamada`;

// ================================================================
// Início - Parte do arquivo que já tinha no projeto
// OBS: Esta função está sendo importada em 'TEL/ContaChamada/manutencao.jsx'
export const getAreaChamadaAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    areaschamadas: value?.map((json) => new AreaChamada(json)) ?? [],
  };
};
// Fim - Parte do arquivo que já tinha no projeto
// ================================================================

export const getAreaChamadaList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AreaChamada(json)) ?? [],
  };
};

export const getAreaChamadaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AreaChamada(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getAreaChamada = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new AreaChamada(value) : {};
};

export const saveAreaChamada = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteAreaChamada = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printAreaChamada = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllAreaChamada = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
