import qs from 'qs';
import Pagination from 'core/models/pagination';
import VeiculoManutencaoPreventiva from 'core/models/FRO/veiculo_ManutencaoPreventiva';
import api from '../api';

const baseURL = `/FRO/Veiculo_ManutencaoPreventiva`;

export const getVeiculoManutencaoPreventivaAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    veiculos: value?.map((json) => new VeiculoManutencaoPreventiva(json)) ?? [],
  };
};

export const getVeiculoManutencaoPreventivaList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    data: value?.map((json) => new VeiculoManutencaoPreventiva(json)) ?? [],
  };
};

export const getVeiculoManutencaoPreventivaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};
  return {
    status,
    message,
    data: value?.map((json) => new VeiculoManutencaoPreventiva(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getVeiculoManutencaoPreventiva = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new VeiculoManutencaoPreventiva(value) : {};
};

export const saveVeiculoManutencaoPreventiva = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteVeiculoManutencaoPreventiva = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const deleteAllVeiculoManutencaoPreventiva = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const printVeiculoManutencaoPreventiva = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelVeiculoManutencaoPreventiva = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const getVeiculoManutencaoPreventivaByVeiculo = async (id) => {
  const response = await api.get(`${baseURL}/SearchByVeiculo`, {
    params: { id },
  });
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    data: value?.map((json) => new VeiculoManutencaoPreventiva(json)) ?? [],
  };
};

export const getVeiculoManutencaoPreventivaPendentesByVeiculo = async (
  veiculo
) => {
  const response = await api.get(`${baseURL}/SearchPendentesByVeiculo`, {
    params: veiculo,
  });
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    data: value?.map((json) => new VeiculoManutencaoPreventiva(json)) ?? [],
  };
};

export const getVeiculoManutencaoPreventivaPendentesByListaDeVeiculos = async (
  listaVeiculos
) => {
  const response = await api.post(
    `${baseURL}/SearchPendentesByListaDeVeiculos`,
    listaVeiculos
  );
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    data: value?.map((json) => new VeiculoManutencaoPreventiva(json)) ?? [],
  };
};
