import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  CSDManutPage,
  Panel,
  Textbox,
  Autocomplete,
  DatePicker,
  GridView,
  Button,
} from 'ui/components';
import { PageTypes, BootstrapSizes, dateNow } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { Theme, ResponseStatus, StatusGrid } from 'ui/Helpers/enums.ts';
import {
  getApoliceSeguro,
  saveApoliceSeguro,
  deleteApoliceSeguro,
  getApoliceSeguroSearchAutoComplete,
} from 'core/services/FRO/apoliceSeguro';
import { IApoliceSeguro, IApoliceSeguroVeiculo } from 'core/interfaces/FRO';
import { getTipoSeguroAutoComplete } from 'core/services/FRO/tipoSeguro';
import { getAverbadoraSeguroAutoComplete } from 'core/services/FRO/averbadoraSeguro';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';
import { getPessoaAutoCompleteFornecedorECliente } from 'core/services/SEG';
import { MaskTypes } from 'ui/Helpers/masks';

export default function ApoliceSeguro({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit = useRef<any>();
  const [data, setData] = useState<IApoliceSeguro | any>({});
  const [dataApoliceSeguroVeiculo, setDataApoliceSeguroVeiculo] = useState<
    IApoliceSeguroVeiculo | any
  >({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const gridViewApoliceSeguroVeiculo = useRef();

  const onSetMessage = (status: ResponseStatus, msg: string = ''): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onNew = (): void => {
    setData({
      vlrFranquia: 0,
      vlrIndPessoal: 0,
      vlrIndMaterial: 0,
      vlrApolice: 0,
    });
    setDataApoliceSeguroVeiculo({});
    if (gridViewApoliceSeguroVeiculo && gridViewApoliceSeguroVeiculo.current)
      gridViewApoliceSeguroVeiculo.current.setDataSource([]);
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const retorno = await getApoliceSeguro(registryKey);
      setData(retorno);
      if (gridViewApoliceSeguroVeiculo && gridViewApoliceSeguroVeiculo.current)
        gridViewApoliceSeguroVeiculo.current.setDataSource(
          retorno.apoliceSeguroVeiculos ?? []
        );

      setLoading(false);
    } else {
      onNew();
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);

    const { status, message: msg } = await saveApoliceSeguro(data);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();

      setData(status === ResponseStatus.Success ? {} : data);

      if (gridViewApoliceSeguroVeiculo && gridViewApoliceSeguroVeiculo.current)
        gridViewApoliceSeguroVeiculo.current.setDataSource([]);
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      onNew();
    }
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteApoliceSeguro(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchFornecedor = async (e: any) => {
    const { data: fornecedor } = await getFornecedorAutoComplete({
      noPessoa: e,
    });
    return fornecedor;
  };

  const onSearchPessoa = async (e: any) => {
    const { data: pessoas } = await getPessoaAutoCompleteFornecedorECliente({
      noPessoa: e,
    });

    return pessoas;
  };

  const onSearchApoliceSeguroPai = async (e: any) => {
    const { data: apoliceSeguroPai } = await getApoliceSeguroSearchAutoComplete(
      {
        nrApolice: e,
        dtInicioVigencia: dateNow(),
        dtFimVigencia: dateNow(),
      }
    );

    return apoliceSeguroPai;
  };

  const onSearchAverbadoraSeguro = async (e: any) => {
    const { data: averboSeguro } = await getAverbadoraSeguroAutoComplete({
      noAverboSeguro: e,
    });

    return averboSeguro;
  };

  const onSearchTipoSeguro = async (e: any) => {
    const { data: tipoSeguros } = await getTipoSeguroAutoComplete({
      noTipoSeguro: e,
    });

    return tipoSeguros;
  };

  const onSearchVeiculo = async (e: any) => {
    const { veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
    });
    return veiculos;
  };

  const validateItemsApoliceSeguroVeiculo = (datasource) => {
    if (!datasource.nrSeqVeiculo) {
      onSetMessage(
        ResponseStatus.Error,
        'Campo Obrigatório: Informar o Veículo.'
      );
      return false;
    }

    if (!datasource.vlrBonus) {
      onSetMessage(
        ResponseStatus.Error,
        'Campo Obrigatório: Informar o Bonus.'
      );
      return false;
    }

    return true;
  };

  const onAdicionarApoliceSeguroVeiculos = async () => {
    if (validateItemsApoliceSeguroVeiculo(dataApoliceSeguroVeiculo)) {
      const obj = {
        ...dataApoliceSeguroVeiculo,
        status: dataApoliceSeguroVeiculo.status ?? StatusGrid.Inserir,
      };

      const list = gridViewApoliceSeguroVeiculo.current.getDataSource();
      list.push(obj);

      if (
        gridViewApoliceSeguroVeiculo &&
        gridViewApoliceSeguroVeiculo.current
      ) {
        gridViewApoliceSeguroVeiculo.current.setDataSource(list);
      }

      setData({
        ...data,
        apoliceSeguroVeiculos: list,
      });
      setDataApoliceSeguroVeiculo({});
    }
  };

  const onClickEditVeiculo = async (selectedValue, datasource) => {
    const dataGrupoIcmsVeiculos = datasource.find(
      (item) => item === selectedValue
    );
    if (dataGrupoIcmsVeiculos.status !== 'Inserir')
      dataGrupoIcmsVeiculos.status = GridView.EnumStatus.Alterar;

    const dataProdutos = datasource.filter(
      (produto) => produto !== selectedValue
    );

    setDataApoliceSeguroVeiculo(dataGrupoIcmsVeiculos);

    if (gridViewApoliceSeguroVeiculo && gridViewApoliceSeguroVeiculo.current)
      gridViewApoliceSeguroVeiculo.current.setDataSource(dataProdutos);
  };

  const onRemoveVeiculos = async (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (gridViewApoliceSeguroVeiculo && gridViewApoliceSeguroVeiculo.current)
      gridViewApoliceSeguroVeiculo.current.setDataSource(datasource);
  };

  const columnsVeiculos = [
    {
      key: 'nrSeqApoliceSeguroVeiculo',
      title: 'nrSeqApoliceSeguroVeiculo',
      visible: false,
    },
    {
      key: 'veiculo.placa',
      title: 'Veiculo',
    },
    {
      key: 'vlrBonus',
      title: 'Bonus',
    },
    {
      key: 'nrSeqApoliceSeguroVeiculo',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditVeiculo(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqApoliceSeguroVeiculo',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveVeiculos(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Apólice de Seguro'
      loading={loading}
      transaction={transaction}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => {
        onNew();
      }}
      onSave={save}
      onDelete={data?.nrSeqApoliceSeguro > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      {/* @ts-expect-error */}
      <Panel>
        {/* @ts-expect-error */}
        <Panel.Header
          title='Dados do Apólice de Seguro'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-5 mb-3'>
              <Autocomplete
                label='Fornecedor (Seguradora)'
                searchDataSource={onSearchFornecedor}
                selectedItem={data.fornecedor}
                onSelectItem={(fornecedor: any) => {
                  setData({
                    ...data,
                    fornecedor,
                    nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-5'>
              <Autocomplete
                label='Beneficiário'
                searchDataSource={onSearchPessoa}
                selectedItem={data.pessoa}
                onSelectItem={(pessoa: any) => {
                  setData({
                    ...data,
                    pessoa,
                    nrSeqPessoa: pessoa.nrSeqPessoa,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-5'>
              <Autocomplete
                label='Apólice Associada (Pai)'
                searchDataSource={onSearchApoliceSeguroPai}
                selectedItem={data.apoliceSeguroPai}
                onSelectItem={(apoliceSeguroPai: any) => {
                  setData({
                    ...data,
                    apoliceSeguroPai,
                    nrSeqApoliceSeguroPai: apoliceSeguroPai.nrSeqApoliceSeguro,
                  });
                }}
                dataSourceTextProperty='noAutoComplete'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-3'>
              <Autocomplete
                label='Seguradora'
                searchDataSource={onSearchAverbadoraSeguro}
                selectedItem={data.averbadoraSeguro}
                onSelectItem={(averbadoraSeguro: any) => {
                  setData({
                    ...data,
                    averbadoraSeguro,
                    nrSeqAverbadoraSeguro:
                      averbadoraSeguro.nrSeqAverbadoraSeguro,
                  });
                }}
                dataSourceTextProperty='noAverbadoraSeguro'
              />
            </div>
            <div className='col-3'>
              <Autocomplete
                label='Tipo Seguro'
                searchDataSource={onSearchTipoSeguro}
                selectedItem={data.tipoSeguro}
                onSelectItem={(tipoSeguro: any) => {
                  setData({
                    ...data,
                    tipoSeguro,
                    nrSeqTipoSeguro: tipoSeguro.nrSeqTipoSeguro,
                  });
                }}
                dataSourceTextProperty='noTipoSeguro'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-3 mb-3'>
              {/* @ts-expect-error */}
              <Textbox
                maxLength={40}
                label='* Código'
                text={data.nrApolice}
                onChangedValue={(nrApolice: string) =>
                  setData({ ...data, nrApolice })
                }
              />
            </div>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <DatePicker
                label='Data Início Vigência'
                text={data.dtInicioVigencia}
                onChange={(dtInicioVigencia: string) =>
                  setData({ ...data, dtInicioVigencia })
                }
              />
            </div>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <DatePicker
                label='Data Término Vigência'
                text={data.dtFimVigencia}
                onChange={(dtFimVigencia: string) =>
                  setData({ ...data, dtFimVigencia })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              {/* @ts-expect-error */}
              <Textbox
                maxLength={9}
                label='* Valor Franquia'
                mask={MaskTypes.DecimalCustom}
                text={data.vlrFranquia}
                onChangedValue={(vlrFranquia: string) =>
                  setData({ ...data, vlrFranquia })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              {/* @ts-expect-error */}
              <Textbox
                maxLength={9}
                label='* Valor Indenização Pessoal'
                mask={MaskTypes.DecimalCustom}
                text={data.vlrIndPessoal}
                onChangedValue={(vlrIndPessoal: string) =>
                  setData({ ...data, vlrIndPessoal })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              {/* @ts-expect-error */}
              <Textbox
                maxLength={9}
                label='* Valor Indenização Material'
                mask={MaskTypes.DecimalCustom}
                text={data.vlrIndMaterial}
                onChangedValue={(vlrIndMaterial: string) =>
                  setData({ ...data, vlrIndMaterial })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              {/* @ts-expect-error */}
              <Textbox
                maxLength={9}
                label='* Valor Apólice'
                mask={MaskTypes.DecimalCustom}
                text={data.vlrApolice}
                onChangedValue={(vlrApolice: string) =>
                  setData({ ...data, vlrApolice })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      {/* @ts-expect-error */}
      <Panel>
        {/* @ts-expect-error */}
        <Panel.Header title='Veículo' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-4 mb-3'>
              <Autocomplete
                label='Placa'
                searchDataSource={onSearchVeiculo}
                selectedItem={dataApoliceSeguroVeiculo.veiculo}
                onSelectItem={(veiculo: any) => {
                  setDataApoliceSeguroVeiculo({
                    ...dataApoliceSeguroVeiculo,
                    veiculo,
                    nrSeqVeiculo: veiculo.nrSeqVeiculo,
                  });
                }}
                dataSourceTextProperty='placa'
              />
            </div>
            <div className='col-3 mb-3'>
              {/* @ts-expect-error */}
              <Textbox
                maxLength={3}
                label='* Bonus'
                text={dataApoliceSeguroVeiculo.vlrBonus}
                onChangedValue={(vlrBonus: string) =>
                  setDataApoliceSeguroVeiculo({
                    ...dataApoliceSeguroVeiculo,
                    vlrBonus,
                  })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <Button
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Success}
                template={Button.Templates.Quick}
                onClick={onAdicionarApoliceSeguroVeiculos}
                text='Adicionar'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <GridView
                ref={gridViewApoliceSeguroVeiculo}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columnsVeiculos}
                showPagination={false}
                showSelectSizes={false}
                offlineData
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </CSDManutPage>
  );
}
