import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Textbox,
  Switch,
  Textarea,
  Autocomplete,
  Button,
  Capture,
} from 'ui/components';

import {
  getEmailTitulo,
  saveEmailTitulo,
  deleteEmailTitulo,
  getPropriedadeTextoAutoComplete,
  getApiEnvioAutoComplete,
} from 'core/services/TEL/emailTitulo';
import { MaskTypes } from 'ui/Helpers/masks';

export default function EmailTituloItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onAdiciona = async () => {
    const texto = `${data.msgEmailTitulo} [${data.propriedadeTexto.cdPropriedade}]`;
    setData({ ...data, msgEmailTitulo: texto, propriedadeTexto: [] });
  };

  const onSearchPropriedadeTexto = async (e) => {
    const { propriedadesTexto } = await getPropriedadeTextoAutoComplete({
      noPropriedade: e,
      flgPropriedadeFinanceiro: data.flgFinanceiro,
      flgTemplateWhatsApp: data.flgTemplateWhatsApp,
    });

    return propriedadesTexto;
  };

  const onSearchApiEnvio = async (e) => {
    const { apisenvio } = await getApiEnvioAutoComplete({
      noApiEnvio: e,
    });

    return apisenvio;
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getEmailTitulo(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveEmailTitulo(data);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      setData({ ...data, msgEmailTitulo: '' });
    }

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteEmailTitulo(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Email Título'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqEmailTitulo > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={60}
            label='Código'
            text={data.cdEmailTitulo}
            onChangedValue={(cdEmailTitulo) =>
              setData({ ...data, cdEmailTitulo })
            }
          />
        </div>
        <div className='col mb-3'>
          <Textbox
            maxLength={200}
            label='Título'
            text={data.noTituloEmail}
            onChangedValue={(noTituloEmail) =>
              setData({ ...data, noTituloEmail })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Textarea
            maxLength={600}
            label='Mensagem'
            text={data.msgEmailTitulo}
            onChangedValue={(msgEmailTitulo) =>
              setData({ ...data, msgEmailTitulo })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-8'>
          <Autocomplete
            label='Propriedade Texto'
            searchDataSource={onSearchPropriedadeTexto}
            selectedItem={data.propriedadeTexto}
            onSelectItem={(propriedadeTexto) =>
              setData({
                ...data,
                propriedadeTexto,
                cdPropriedade: propriedadeTexto.cdPropriedade,
              })
            }
            dataSourceTextProperty='cdPropriedade'
          />
        </div>
        <div className='col-1 mt-4'>
          <Button
            icon='plus'
            size={BootstrapSizes.Small}
            theme={Theme.Success}
            template={Button.Templates.Default}
            onClick={onAdiciona}
            outline
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='API de Envio'
            searchDataSource={onSearchApiEnvio}
            selectedItem={data.apiEnvio}
            onSelectItem={(apiEnvio) =>
              setData({
                ...data,
                apiEnvio,
                cdApiEnvio: apiEnvio.cdApiEnvio,
                nrTeamId: undefined,
                nrBotConfigId: undefined,
                nrBotFlowId: undefined,
              })
            }
            dataSourceTextProperty='noApiEnvio'
          />
        </div>
        <div className='col'>
          {data.apiEnvio && data.apiEnvio.flgExigeParametroConfiguracao && (
            <div className='row mb-3'>
              <div className='col'>
                <Textbox
                  maxLength={10}
                  label='Team Id'
                  mask={MaskTypes.Integer}
                  text={data.nrTeamId}
                  onChangedValue={(nrTeamId) => setData({ ...data, nrTeamId })}
                />
              </div>
              <div className='col'>
                <Textbox
                  maxLength={10}
                  label='Bot Flow Id'
                  mask={MaskTypes.Integer}
                  text={data.nrBotFlowId}
                  onChangedValue={(nrBotFlowId) =>
                    setData({ ...data, nrBotFlowId })
                  }
                />
              </div>
              <div className='col'>
                <Textbox
                  maxLength={10}
                  label='Bot Config Id'
                  mask={MaskTypes.Integer}
                  text={data.nrBotConfigId}
                  onChangedValue={(nrBotConfigId) =>
                    setData({ ...data, nrBotConfigId })
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Switch
            formControl
            label='Sms'
            checked={data.flgSMS}
            onChange={(flgSMS) => setData({ ...data, flgSMS })}
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Ativo'
            checked={data.flgAtivo}
            onChange={(flgAtivo) => setData({ ...data, flgAtivo })}
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='WhatsApp'
            checked={data.flgWhatsApp}
            onChange={(flgWhatsApp) => {
              if (flgWhatsApp === false) data.noImagem = undefined;
              setData({ ...data, flgWhatsApp });
            }}
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Email'
            checked={data.flgEmail}
            onChange={(flgEmail) => setData({ ...data, flgEmail })}
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Financeiro'
            checked={data.flgFinanceiro}
            onChange={(flgFinanceiro) =>
              setData({ ...data, flgFinanceiro, propriedadeTexto: [] })
            }
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Template WhatsApp'
            checked={data.flgTemplateWhatsApp}
            onChange={(flgTemplateWhatsApp) =>
              setData({ ...data, flgTemplateWhatsApp })
            }
          />
        </div>
        <div className='col-auto'>
          <Switch
            formControl
            label='Verifica Status Conexão WhatsApp'
            checked={data.flgVerificaConexaoWhatsApp}
            onChange={(flgVerificaConexaoWhatsApp) =>
              setData({ ...data, flgVerificaConexaoWhatsApp })
            }
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Salva Ocorrência'
            checked={data.flgSalvaOcorrencia}
            onChange={(flgSalvaOcorrencia) =>
              setData({ ...data, flgSalvaOcorrencia })
            }
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Envio NFS'
            checked={data.flgMensagemEnvioNFS}
            onChange={(flgMensagemEnvioNFS) =>
              setData({ ...data, flgMensagemEnvioNFS })
            }
          />
        </div>
        {data.flgWhatsApp && (
          <div className='row mb-3'>
            <div className='col-lg-3'>
              <Capture
                cameraButtonVisible={false}
                defaultIcon={['far', 'image']}
                picture={data.noImagem}
                onPictureLoad={(noImagem) => setData({ ...data, noImagem })}
              />
            </div>
          </div>
        )}
      </div>
    </CSDManutPage>
  );
}
