import { ColetaEmbarque, ColetaEmbarqueDocumento } from 'core/models/FRO';

export default class UploadImagemFrota {
  constructor(jsonObject) {
    this.nrSeqUploadImagem = jsonObject.nrSeqUploadImagem;
    this.noImagem = jsonObject.noImagem;
    this.noTipoArquivo = jsonObject.noTipoArquivo;
    this.noTamanho = jsonObject.noTamanho;
    this.dtCadastro = jsonObject.dtCadastro;
    this.nrSeqColetaEmbarque = jsonObject.nrSeqColetaEmbarque;
    this.nrSeqManifestoCte = jsonObject.nrSeqManifestoCte;
    this.nrSeqDocumento = jsonObject.nrSeqDocumento;
    this.noArquivo = jsonObject.noArquivo;
    this.nrSeqVeiculoDisponibilidade = jsonObject.nrSeqVeiculoDisponibilidade;

    // #region Propriedades Auxiliares

    this.noBaseImagem64 = jsonObject.noBaseImagem64;
    this.status = jsonObject.status;
    this.coletaEmbarque = new ColetaEmbarque(jsonObject.coletaEmbarque ?? {});
    this.documento = new ColetaEmbarqueDocumento(jsonObject.documento ?? {});

    // #endregion
  }
}
