export default class PocPocoPerflitFormacao {
  constructor(jsonObject = {}) {
    this.nrSeqPocPocoPerflitFormacao = jsonObject.nrSeqPocPocoPerflitFormacao;
    this.nrSeqPocPoco = jsonObject.nrSeqPocPoco;
    this.nrSeqPocPerflitFormacao = jsonObject.nrSeqPocPerflitFormacao;
    this.nrInicio = jsonObject.nrInicio;
    this.nrFim = jsonObject.nrFim;
    this.noPocPerflitFormacao = jsonObject.noPocPerflitFormacao;
    this.cdPocPerflitFormacao = jsonObject.cdPocPerflitFormacao;
    this.totalFormacao = jsonObject.totalFormacao;
    this.noImagemBase64 = jsonObject.noImagemBase64;
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
