import React, { useState, useEffect, useRef } from 'react';
import { setTransaction } from 'core/services/api';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { Button, Modal, Notification, GridView, Textbox } from 'ui/components';
import {
  getAdicionaOcorrencia,
  getTituloReceberOcorrenciaPagined,
  saveTituloReceberOcorrencias,
} from 'core/services/FIN';

export default function ModalOcorrencia({
  show,
  onClose,
  tituloreceber,
  retornoSave,
  transaction,
}) {
  const [message, setMessage] = useState(null);
  const [data, setData] = useState({});
  const gridView = useRef();

  const columns = [
    {
      key: 'nrSeqTituloReceberOcorrencia',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'dtCadastro', title: 'Cadastro', format: GridView.DataTypes.Date },
    { key: 'usuario.noPessoa', title: 'Usuário' },
    { key: 'noOcorrencia', title: 'Ocorrência' },
    { key: 'nrSeqTituloReceber', title: 'NrSeqTituloReceber', visible: false },
    { key: 'nrSeqUsuarioCad', title: 'NrSeqUsuarioCad', visible: false },
    { key: 'flgOcorrenciaSMS', title: 'FlgOcorrenciaSMS', visible: false },
  ];

  const onSaveOcorrencia = async () => {
    const lista = gridView.current.getDataSource();
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);
    const retornoSaveOcorrencia = await saveTituloReceberOcorrencias(lista);
    retornoSave(retornoSaveOcorrencia);
  };

  const onAdicionarOcorrencia = async () => {
    const { data: listaAtualizada } = await getAdicionaOcorrencia(data);
    if (gridView && gridView.current)
      gridView.current.setDataSource(listaAtualizada);
    setData({ ...data, noOcorrencia: undefined });
  };

  useEffect(async () => {
    if (show) {
      const { data: ocorrencias } = await getTituloReceberOcorrenciaPagined({
        nrSeqTituloReceber: tituloreceber.nrSeqTituloReceber,
      });
      setData({ nrSeqTituloReceber: tituloreceber.nrSeqTituloReceber });
      if (gridView && gridView.current)
        gridView.current.setDataSource(ocorrencias);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      title='Ocorrências'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body className='p-4 pt-3'>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='card'>
          <div className='card-body'>
            <div className='row mb-3'>
              <div className='row mb-3'>
                <div className='col'>
                  <Textbox
                    label='Ocorrência'
                    text={data.noOcorrencia}
                    onChangedValue={(noOcorrencia) => {
                      setData({
                        ...data,
                        noOcorrencia,
                      });
                    }}
                  />
                </div>
                <div className='col-2 mt-4'>
                  <Button
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    text='Adicionar'
                    onClick={onAdicionarOcorrencia}
                  />
                </div>
              </div>

              <div className='col'>
                <GridView
                  ref={gridView}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Salvar'
          theme={Theme.Success}
          icon={['far', 'save']}
          onClick={() => onSaveOcorrencia()}
        />
        <Button
          text='Fechar'
          theme={Theme.Danger}
          icon='ban'
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
