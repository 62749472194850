import { RecalculaResumo } from 'core/services/FIN/acertoMotorista';
import { getAdiantamentoSemAcerto } from 'core/services/FIN/acertoMotoristaAdiantamento';
import React, { useRef, forwardRef, useState, useEffect } from 'react';
import { DatePicker, Button, GridView, Notification } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { ResponseStatus, Theme } from 'ui/Helpers/utils';

const Adiantamentos = forwardRef(
  ({ data, registryKey, setData, findTransaction, onOpenTransaction }) => {
    const [dataCadastroCliente, setDataCadastroCliente] = useState({});
    const [message, onSetMessage] = useState(null);
    const gridView = useRef();
    const gridView2 = useRef();

    const setMessage = (status, msg) => {
      if (msg)
        onSetMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
    };

    const columns = [
      {
        key: 'nrSeqAcertoMotoristaAdiantamento',
        type: GridView.ColumnTypes.Checkbox,
      },
      {
        key: 'dtAdiantamento',
        title: 'Data',
        format: GridView.DataTypes.Date,
      },
      {
        key: 'nrCheque',
        title: 'Nr Cheque',
      },
      {
        key: 'vlrAdiantamento',
        title: 'Valor',
        format: GridView.DataTypes.Decimal,
      },
    ];
    const columns2 = [
      {
        key: 'nrSeqAcertoMotoristaAdiantamento',
        type: GridView.ColumnTypes.Checkbox,
      },
      {
        key: 'dtAdiantamento',
        title: 'Data',
        format: GridView.DataTypes.Date,
      },
      {
        key: 'nrCheque',
        title: 'Nr Cheque',
      },
      {
        key: 'obsAdiantamento',
        title: 'Observação',
      },
      {
        key: 'vlrAdiantamento',
        title: 'Valor',
        format: GridView.DataTypes.Decimal,
      },
    ];

    const load = async () => {
      if (data !== undefined) {
        if (data.adiantamentos !== undefined) {
          if (gridView2 && gridView2.current)
            gridView2.current.setDataSource(data.adiantamentos);
        }
      }
    };

    const onSearchRight = async () => {
      const importacaocerta = [];
      let placas = [];
      let adiantamentoNaoAdicionado = [];

      const nrSeqVendaDiariaS = gridView.current
        .getCheckBoxValuesAt(0)
        ?.map((row) => row[0].value);

      if (nrSeqVendaDiariaS.length === 0) {
        onSetMessage(ResponseStatus.Error, 'Selecione um item para atualizar ');
        return;
      }

      const datasource = gridView.current.getDataSource();

      const tabelacerta = gridView2.current.getDataSource();

      if (tabelacerta !== null) {
        importacaocerta.push(...tabelacerta);
      }
      adiantamentoNaoAdicionado = gridView.current.getDataSource();

      for (let i = 0; i < nrSeqVendaDiariaS.length; ) {
        placas = adiantamentoNaoAdicionado.filter(
          (Placa) =>
            Placa.nrSeqAcertoMotoristaAdiantamento !== nrSeqVendaDiariaS[i]
        );

        adiantamentoNaoAdicionado = placas;
        i += 1;
      }

      const datasourcefiltrado = datasource.filter((item) =>
        nrSeqVendaDiariaS.includes(item.nrSeqAcertoMotoristaAdiantamento, 0)
      );

      if (datasourcefiltrado !== null) {
        for (let i = 0; i < datasourcefiltrado.length; ) {
          if (tabelacerta !== null) {
            if (tabelacerta.length > 0) {
              for (let j = 0; j < tabelacerta.length; ) {
                if (
                  datasourcefiltrado[i].nrSeqAcertoMotoristaAdiantamento ===
                    tabelacerta[j].nrSeqAcertoMotoristaAdiantamento &&
                  datasourcefiltrado[i].nrSeqAcertoMotorista > 0
                ) {
                  datasourcefiltrado.pop(datasourcefiltrado[i]);
                  tabelacerta[i].status = GridView.EnumStatus.Alterar;
                } else if (datasourcefiltrado[i].status === null) {
                  datasourcefiltrado[i].status = GridView.EnumStatus.Inserir;
                }

                j += 1;
              }
            } else {
              datasourcefiltrado[i].status = GridView.EnumStatus.Inserir;
            }
          } else {
            datasourcefiltrado[i].status = GridView.EnumStatus.Inserir;
          }
          i += 1;
        }
      }

      importacaocerta.push(...datasourcefiltrado);

      if (gridView && gridView.current)
        gridView.current.setDataSource(adiantamentoNaoAdicionado);

      if (gridView2 && gridView2.current)
        gridView2.current.setDataSource(importacaocerta);

      const nrSeqAcerto = data.nrSeqAcertoMotorista;

      const valorObj = { ...data, adiantamentos: importacaocerta };

      const obj = await RecalculaResumo(valorObj);

      if (obj.nrSeqAcertoMotorista > 0) {
        setData({
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.viagens,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: obj.outrasReceitasComissao,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalReceitas: obj.totalReceitas,
          totalPeso: obj.totalPeso,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });
      } else {
        setData({
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          nrSeqAcertoMotorista: nrSeqAcerto,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.viagens,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: obj.outrasReceitasComissao,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalPeso: obj.totalPeso,
          totalReceitas: obj.totalReceitas,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });
      }
    };

    const onSearchLeft = async () => {
      const importacaocerta = [];
      let placas = [];
      let tabelaViagemCorreta = [];

      const nrSeqVendaDiariaS = gridView2.current
        .getCheckBoxValuesAt(0)
        ?.map((row) => row[0].value);

      if (nrSeqVendaDiariaS.length === 0) {
        onSetMessage(ResponseStatus.Error, 'Selecione um item para atualizar ');
        return;
      }

      const datasource = gridView.current.getDataSource();

      const tabelacerta = gridView2.current.getDataSource();

      importacaocerta.push(...datasource);

      tabelaViagemCorreta = gridView2.current.getDataSource();

      for (let i = 0; i < nrSeqVendaDiariaS.length; ) {
        placas = tabelaViagemCorreta.filter(
          (Placa) =>
            Placa.nrSeqAcertoMotoristaAdiantamento !== nrSeqVendaDiariaS[i]
        );

        tabelaViagemCorreta = placas;
        i += 1;
      }

      const datasourcefiltrado = tabelacerta.filter((item) =>
        nrSeqVendaDiariaS.includes(item.nrSeqAcertoMotoristaAdiantamento, 0)
      );

      const datasourcefiltradoList2 = tabelacerta.filter((item) =>
        nrSeqVendaDiariaS.includes(item.nrSeqAcertoMotoristaAdiantamento, 0)
      );

      for (let i = 0; i < datasourcefiltrado.length; ) {
        if (datasourcefiltrado[i].nrSeqAcertoMotorista > 0) {
          datasourcefiltrado[i].status = GridView.EnumStatus.Alterar;
        }
        i += 1;
      }

      importacaocerta.push(...datasourcefiltrado);

      if (gridView && gridView.current)
        gridView.current.setDataSource(importacaocerta);

      for (let i = 0; i < datasourcefiltradoList2.length; ) {
        if (datasourcefiltradoList2[i].nrSeqAcertoMotorista > 0) {
          datasourcefiltradoList2[i].status = GridView.EnumStatus.Remover;
          tabelaViagemCorreta.push(datasourcefiltradoList2[i]);
        }
        i += 1;
      }

      if (gridView2 && gridView2.current)
        gridView2.current.setDataSource(tabelaViagemCorreta);

      const nrSeqAcerto = data.nrSeqAcertoMotorista;

      const valorObj = { ...data, adiantamentos: tabelaViagemCorreta };

      const obj = await RecalculaResumo(valorObj);

      if (obj.nrSeqAcertoMotorista > 0) {
        setData({
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.viagens,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: obj.outrasReceitasComissao,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalReceitas: obj.totalReceitas,
          totalPeso: obj.totalPeso,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });
      } else {
        setData({
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          nrSeqAcertoMotorista: nrSeqAcerto,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.viagens,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: obj.outrasReceitasComissao,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalPeso: obj.totalPeso,
          totalReceitas: obj.totalReceitas,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });
      }
    };

    const onClickOpenAdiantamento = async () => {
      const transactionAdiantamento = findTransaction(
        TransacationCodes.AdiantamentoMotorista
      );

      if (
        transactionAdiantamento !== null ||
        transactionAdiantamento !== undefined
      ) {
        if (data !== null || data !== undefined) {
          if (data.nrSeqPessoaMot > 0) {
            transactionAdiantamento.setValues = data;
            transactionAdiantamento.setValues.readValues = true;
          }
        }
      }

      if (transactionAdiantamento) {
        onOpenTransaction(transactionAdiantamento, true);
      }
    };

    useEffect(() => {
      (async function func() {
        load();
      })();
    }, [data.nrSeqPessoaCli, registryKey]);

    const onSearchAdiantamentos = async () => {
      let nrSeqViagem = 0;

      if (!data.nrSeqPessoaMot > 0) {
        setMessage(
          ResponseStatus.Error,
          'Não é possivel buscar um adiantamento sem selecionar o motorista'
        );
        return;
      }

      if (data.viagens !== null && data.viagens !== undefined) {
        for (let i = 0; i < data.viagens.length; ) {
          nrSeqViagem = data.viagens[i].nrSeqViagem;
          i += 1;
        }
      }
      const obj = {
        ...dataCadastroCliente,
        nrSeqViagem,
        nrSeqPessoaMot: data.nrSeqPessoaMot,
      };
      const adiantamento = await getAdiantamentoSemAcerto(obj);

      let clonedList = [];
      if (adiantamento !== undefined && adiantamento !== null) {
        clonedList = [...adiantamento];
      }

      const tabela2 = gridView2.current.getDataSource();

      if (tabela2 !== null && tabela2 !== undefined) {
        for (let j = 0; j < tabela2.length; ) {
          clonedList = clonedList.filter(
            (item) =>
              item.nrSeqAcertoMotoristaAdiantamento !==
              tabela2[j].nrSeqAcertoMotoristaAdiantamento
          );

          j += 1;
        }
      }

      if (gridView && gridView.current)
        gridView.current.setDataSource(clonedList);
    };

    return (
      <div>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => onSetMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='display-8'>2 - Adiantamentos</div>
        </div>
        <div className='card'>
          <div className='card-body'>
            <div className='row mb-3'>
              <div className='row'>
                <div className='row mb-3'>
                  <div className='col-2 mb-3'>
                    <DatePicker
                      label='De'
                      text={dataCadastroCliente.dtAdiantamento}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtAdiantamento) =>
                        setDataCadastroCliente({
                          ...dataCadastroCliente,
                          dtAdiantamento,
                        })
                      }
                    />
                  </div>
                  <div className='col-2 mb-3'>
                    <DatePicker
                      label='Ate'
                      text={dataCadastroCliente.dtAdiantamentoAte}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtAdiantamentoAte) =>
                        setDataCadastroCliente({
                          ...dataCadastroCliente,
                          dtAdiantamentoAte,
                        })
                      }
                    />
                  </div>
                  <div className='col-1 mt-3'>
                    <Button
                      outline
                      theme={Theme.Primary}
                      template={Button.Templates.Button}
                      icon='search'
                      tooltip='Pesquisar o endereço pelo CEP digitado.'
                      onClick={onSearchAdiantamentos}
                    />
                  </div>
                  <div className='col-2 mt-3'>
                    <Button
                      outline
                      theme={Theme.Primary}
                      template={Button.Templates.Button}
                      icon='plus'
                      text='Novo'
                      onClick={onClickOpenAdiantamento}
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-5'>
                  <GridView
                    ref={gridView}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
                <div className='col-1 mt-3 mb-3'>
                  <Button
                    outline
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    icon='plus'
                    text='Adicionar'
                    onClick={onSearchRight}
                  />
                  <Button
                    outline
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    icon='arrow-circle-left'
                    text='Remover'
                    onClick={onSearchLeft}
                  />
                </div>
                <div className='col-5'>
                  <GridView
                    ref={gridView2}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns2}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default Adiantamentos;
