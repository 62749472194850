import React from 'react';
import { mask, MaskTypes } from 'ui/Helpers/masks';
import { ContaChamada } from 'core/models/TEL';

function RenderTextMask(props) {
  const { label, value, masktype } = props;
  return (
    <div className='col-auto mt-3'>
      <div className='fw-bold'>{label}:</div>
      {mask(value ?? '0,00', masktype)}
    </div>
  );
}

export default function PanelResumo(props) {
  let { chamada } = props;

  if (chamada == null) chamada = new ContaChamada({});
  return (
    <div className='bg-primary text-white bg-gradient rounded-2 p-3'>
      {
        <div className='row'>
          <div className='col'>
            <div className='display-8'>Informações da Pesquisa:</div>
            <hr className='mt-1 mb-0' />
            <div className='row'>
              <RenderTextMask
                label='Total Valor Chamada'
                value={chamada.vlrTotalChamada}
              />
              <RenderTextMask
                label='Total Valor Cobrado'
                value={chamada.vlrTotalCobrado}
              />
              <RenderTextMask
                label='Total Valor Recalculo'
                value={chamada.vlrTotalRecalculado}
              />
              <RenderTextMask
                label='Quantidade'
                value={chamada.nrQuantidade}
                masktype={MaskTypes.Integer}
              />
            </div>
          </div>
        </div>
      }
      {
        <div className='row mt-3'>
          <div className='col'>
            <hr className='mt-1 mb-0' />
            <div className='row'>
              <RenderTextMask
                label='Ligações VC1'
                value={chamada.vlrTotalLigacoesVc1}
              />
              <RenderTextMask
                label='Ligações VC2'
                value={chamada.vlrTotalLigacoesVc2}
              />
              <RenderTextMask
                label='Ligações VC3'
                value={chamada.vlrTotalLigacoesVc3}
              />
              <RenderTextMask
                label='SMS:'
                value={chamada.nrSms}
                masktype={MaskTypes.Integer}
              />

              <RenderTextMask
                label='Total Dados'
                value={chamada.vlrTotalDados}
              />
            </div>
          </div>
        </div>
      }
    </div>
  );
}
