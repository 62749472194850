import MarcaaparelhoCelular from './marcaAparelhoCelular';
import Modeloaparelhocelular from './modeloAparelhoCelular';

export default class Pedidoaparelhomodelo {
  constructor(jsonObject = {}) {
    this.nrSeqPedidoAparelhoModelo = jsonObject.nrSeqPedidoAparelhoModelo;
    this.nrSeqPedidoAparelho = jsonObject.nrSeqPedidoAparelho;
    this.vlrCusto = jsonObject.vlrCusto;
    this.nrSeqModeloAparelho = jsonObject.nrSeqModeloAparelho;
    this.nrSeqMarcaAparelho = jsonObject.nrSeqMarcaAparelho;
    this.qtdeAparelho = jsonObject.qtdeAparelho;
    this.modeloAparelhoCelular = new Modeloaparelhocelular(
      jsonObject.modeloAparelhoCelular ?? {}
    );
    this.marcaAparelhoCelular = new MarcaaparelhoCelular(
      jsonObject.marcaAparelhoCelular ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
