import React, { forwardRef, useState, useRef, useEffect } from 'react';
import {
  Autocomplete,
  Button,
  GridView,
  Textbox,
  DatePicker,
  Switch,
  Panel,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { getAssociadoList } from 'core/services/ASS/associado';
import { getPlanoConvenioList } from 'core/services/ASS/planoConvenio';
import { getTipoAssociadoList } from 'core/services/ASS/tipoAssociado';
import { getMotivoDesligamentoList } from 'core/services/ASS/motivoDesligamento';
import { getVencimentoAutoComplete } from 'core/services/ASS/vencimento';
import { getConvenioAutoComplete } from 'core/services/ASS/convenio';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getInformacaoBancariaAutoComplete } from 'core/services/FIN/informacaoBancaria';
import { getCentroDeCustoAutoComplete } from 'core/services/TEL/centroDeCusto';
import { getEmpresaOrigemList } from 'core/services/SEG/empresaOrigem';
import { getEmpresaOrigemCentroList } from 'core/services/SEG/empresaOrigemCentro';
import { getEmpresaOrigemSetorList } from 'core/services/SEG/empresaOrigemSetor';

const PlanosStep = forwardRef(({ data, setData, onSetMessage, flgRural }) => {
  const gridViewPlanosAssociado = useRef();
  const gridViewMatriculas = useRef();
  const gridViewAssEmpresaOrigem = useRef();

  const [dadosPlanoConvAssociado, setDadosPlanoConvAssociado] = useState({});
  const [planoConvenioAss, setPlanoConvenioAss] = useState({});
  const [matriculaConvenioAss, setMatriculaConvenioAss] = useState({});
  const [associadoEmpresaOrigem, setAssociadoEmpresaOrigem] = useState({});

  useEffect(() => {
    setData({
      ...data,
      ...dadosPlanoConvAssociado,
    });
  }, [dadosPlanoConvAssociado]);

  useEffect(() => {
    setDadosPlanoConvAssociado({ ...data, ...dadosPlanoConvAssociado });
    if (
      gridViewPlanosAssociado &&
      gridViewPlanosAssociado.current &&
      data.associadosPlanosConvenios
    ) {
      gridViewPlanosAssociado.current.setDataSource(
        data.associadosPlanosConvenios
      );
    }

    if (
      gridViewMatriculas &&
      gridViewMatriculas.current &&
      data.matriculasAssociado
    ) {
      gridViewMatriculas.current.setDataSource(data.matriculasAssociado);
    }

    if (
      gridViewAssEmpresaOrigem &&
      gridViewAssEmpresaOrigem.current &&
      data.empresasOrigemAss
    ) {
      gridViewAssEmpresaOrigem.current.setDataSource(data.empresasOrigemAss);
    }
  }, [data.nrSeqPessoaAss]);

  const onClickAddPlano = async () => {
    if (planoConvenioAss && planoConvenioAss.planoConvenio) {
      const obj = {
        ...planoConvenioAss,
        planoConvenio: {
          ...planoConvenioAss.planoConvenio,
          convenio: planoConvenioAss.convenio,
        },
        nrSeqPessoaAss: data.nrSeqPessoaAss,
      };

      obj.status =
        planoConvenioAss.nrSeqAssocPlanoConv &&
        planoConvenioAss.nrSeqAssocPlanoConv > 0
          ? GridView.EnumStatus.Alterar
          : GridView.EnumStatus.Inserir;

      const listaPlanos = gridViewPlanosAssociado.current.getDataSource() ?? [];

      const planosDuplicados =
        listaPlanos.filter(
          (el) =>
            el.nrSeqConvenio === obj.nrSeqConvenio &&
            el.nrSeqPlanoConvenio === obj.planoConvenio.nrSeqPlanoConvenio &&
            el.status !== GridView.EnumStatus.Remover
        ) ?? [];

      if (planosDuplicados.length > 0) {
        onSetMessage(
          false,
          'O associado já possui o plano e convênio selecionados.'
        );
      } else {
        listaPlanos.push(obj);

        if (gridViewPlanosAssociado && gridViewPlanosAssociado.current)
          gridViewPlanosAssociado.current.setDataSource(listaPlanos);

        setDadosPlanoConvAssociado({
          ...dadosPlanoConvAssociado,
          associadosPlanosConvenios: listaPlanos,
        });

        setPlanoConvenioAss({});
      }
    } else {
      onSetMessage(false, 'Informe o Plano do Convênio.');
    }
  };

  const onClickEditPlanoConvenio = async (selectedValue, datasource) => {
    const planosConvLista = datasource.filter((item) => item !== selectedValue);

    if (gridViewPlanosAssociado && gridViewPlanosAssociado.current)
      gridViewPlanosAssociado.current.setDataSource(planosConvLista);

    setPlanoConvenioAss({
      ...selectedValue,
      convenio: selectedValue.planoConvenio?.convenio
        ? selectedValue.planoConvenio?.convenio
        : null,
      status: GridView.EnumStatus.Alterar,
    });
  };

  const onClickRemovePlanoConvenio = async (selectedValue, datasource) => {
    const listaPlanos = datasource.filter((el) => el !== selectedValue);

    if (selectedValue.status !== GridView.EnumStatus.Inserir) {
      selectedValue.status = GridView.EnumStatus.Remover;

      listaPlanos.push(selectedValue);
    }

    if (gridViewPlanosAssociado && gridViewPlanosAssociado.current)
      gridViewPlanosAssociado.current.setDataSource(listaPlanos);

    setDadosPlanoConvAssociado({
      ...dadosPlanoConvAssociado,
      associadosPlanosConvenios: listaPlanos,
    });
  };

  const onClickAddMatriculaConvenio = async () => {
    if (matriculaConvenioAss && matriculaConvenioAss.nrSeqConvenio) {
      const obj = {
        ...matriculaConvenioAss,
        planoConvenio: matriculaConvenioAss.planoConvenio
          ? {
              ...matriculaConvenioAss.planoConvenio,
              convenio: matriculaConvenioAss.convenio,
              nrSeqConvenio: matriculaConvenioAss.nrSeqConvenio,
            }
          : {
              nrSeqConvenio: matriculaConvenioAss.nrSeqConvenio,
              convenio: matriculaConvenioAss.convenio,
            },
        nrSeqPessoaAss: data.nrSeqPessoaAss,
      };

      obj.status =
        matriculaConvenioAss.nrSeqAssocMatriculaConv &&
        matriculaConvenioAss.nrSeqAssocMatriculaConv > 0
          ? GridView.EnumStatus.Alterar
          : GridView.EnumStatus.Inserir;

      const matriculasLista =
        gridViewMatriculas?.current?.getDataSource() ?? [];

      matriculasLista.push(obj);

      if (gridViewMatriculas && gridViewMatriculas.current)
        gridViewMatriculas.current.setDataSource(matriculasLista);

      setDadosPlanoConvAssociado({
        ...dadosPlanoConvAssociado,
        matriculasAssociado: matriculasLista,
      });

      setMatriculaConvenioAss({});
    } else {
      onSetMessage(false, 'Informe o Convênio.');
    }
  };

  const onClickEditMatricula = async (selectedValue, datasource) => {
    const matriculasLista = datasource.filter((item) => item !== selectedValue);

    if (gridViewMatriculas && gridViewMatriculas.current)
      gridViewMatriculas.current.setDataSource(matriculasLista);

    setMatriculaConvenioAss({
      ...selectedValue,
      convenio: selectedValue.planoConvenio?.convenio
        ? selectedValue.planoConvenio?.convenio
        : null,
      status: GridView.EnumStatus.Alterar,
    });
  };

  const onClickRemoveMatricula = async (selectedValue, datasource) => {
    const matriculasLista = datasource.filter((el) => el !== selectedValue);

    if (selectedValue.status !== GridView.EnumStatus.Inserir) {
      selectedValue.status = GridView.EnumStatus.Remover;

      matriculasLista.push(selectedValue);
    }

    if (gridViewMatriculas && gridViewMatriculas.current)
      gridViewMatriculas.current.setDataSource(matriculasLista);

    setDadosPlanoConvAssociado({
      ...dadosPlanoConvAssociado,
      matriculasAssociado: matriculasLista,
    });
  };

  const onClickAddEmpresaOrigem = async () => {
    if (associadoEmpresaOrigem && associadoEmpresaOrigem.nrSeqPessoaEmpOrig) {
      const obj = {
        ...associadoEmpresaOrigem,
        nrSeqPessoaAss: data.nrSeqPessoaAss,
      };

      obj.status =
        associadoEmpresaOrigem.nrSeqAssocEmpOrig &&
        associadoEmpresaOrigem.nrSeqAssocEmpOrig > 0
          ? GridView.EnumStatus.Alterar
          : GridView.EnumStatus.Inserir;

      const empresaOriLista =
        gridViewAssEmpresaOrigem?.current?.getDataSource() ?? [];

      empresaOriLista.push(obj);

      if (gridViewAssEmpresaOrigem && gridViewAssEmpresaOrigem.current)
        gridViewAssEmpresaOrigem.current.setDataSource(empresaOriLista);

      setDadosPlanoConvAssociado({
        ...dadosPlanoConvAssociado,
        empresasOrigemAss: empresaOriLista,
      });

      setAssociadoEmpresaOrigem({});
    } else {
      onSetMessage(false, 'Informe a Universidade, ou atividade.');
    }
  };

  const onClickEditEmpresaOrigem = async (selectedValue, datasource) => {
    const empresasLista = datasource.filter((item) => item !== selectedValue);

    setAssociadoEmpresaOrigem({
      ...selectedValue,
      status: GridView.EnumStatus.Alterar,
    });

    if (gridViewAssEmpresaOrigem && gridViewAssEmpresaOrigem.current)
      gridViewAssEmpresaOrigem.current.setDataSource(empresasLista);
  };

  const onClickRemoveEmpresaOrigem = async (e, datasource) => {
    const empresasLista = datasource.filter((el) => el !== e);

    if (e.status !== GridView.EnumStatus.Inserir) {
      e.status = GridView.EnumStatus.Remover;

      empresasLista.push(e);
    }

    if (gridViewAssEmpresaOrigem && gridViewAssEmpresaOrigem.current)
      gridViewAssEmpresaOrigem.current.setDataSource(empresasLista);

    setData({ ...data, empresasOrigemAss: empresasLista });
  };

  const onSearchConvenio = async (e) => {
    const { convenios } = await getConvenioAutoComplete({
      noConvenio: e,
    });
    return convenios;
  };

  const onSearchPlanoConvenio = async (e) => {
    const { data: planosConvenio } = await getPlanoConvenioList({
      noPlanoConvenio: e,
      nrSeqConvenio: planoConvenioAss?.nrSeqConvenio ?? null,
    });
    return planosConvenio;
  };

  const onSearchFormaPagamento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
    });
    return formasPagamento;
  };

  const onSearchInformacaoBancaria = async (e) => {
    const { informacoesBancarias } = await getInformacaoBancariaAutoComplete({
      noDescricao: e,
      nrSeqPessoa: data?.nrSeqPessoaAss,
    });
    return informacoesBancarias;
  };

  const onSearchAssociadoConsolidador = async (e) => {
    const { associados } = await getAssociadoList({
      noPessoa: e,
    });
    return associados;
  };

  const onSearchTipoAssociado = async (e) => {
    const { data: tiposAssociados } = await getTipoAssociadoList({
      noTipoAssociado: e,
    });
    return tiposAssociados;
  };

  const onSearchMotivoDesligamento = async (e) => {
    const { data: motivoDesligamento } = await getMotivoDesligamentoList({
      noMotivoDesligamento: e,
    });
    return motivoDesligamento;
  };

  const onSearchVencimento = async (e) => {
    const { vencimentos } = await getVencimentoAutoComplete({
      noVencimento: e,
    });
    return vencimentos;
  };

  const onSearchCentroDeCusto = async (e) => {
    const { data: centroDeCusto } = await getCentroDeCustoAutoComplete({
      noCentroDeCusto: e,
    });
    return centroDeCusto;
  };

  const onSearchAtividade = async (e) => {
    const { status, message: msg, data: empresas } = await getEmpresaOrigemList(
      {
        noEmpresaOrigem: e,
      }
    );
    onSetMessage(status, msg);
    return empresas;
  };

  const onSearchEmpresaOriCentro = async (e) => {
    const { data: centros } = await getEmpresaOrigemCentroList({
      noEmpresaOrigemCentro: e,
      nrSeqPessoaEmpOrig: associadoEmpresaOrigem?.nrSeqPessoaEmpOrig || null,
    });

    return centros;
  };

  const onSearchEmpresaOriSetor = async (e) => {
    const { data: setores } = await getEmpresaOrigemSetorList({
      noEmpresaOrigemSetor: e,
      nrSeqPessoaEmpOrig: associadoEmpresaOrigem?.nrSeqPessoaEmpOrig || null,
    });

    return setores;
  };

  const columnsPlanosConvAssociado = [
    { key: 'nrSeqAssocPlanoConv', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'planoConvenio.convenio.noConvenio',
      title: 'Convênio',
      sortable: false,
    },
    {
      key: 'planoConvenio.noPlanoConvenio',
      title: 'Plano Convênio',
      sortable: false,
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma Pagamento',
      sortable: false,
    },

    {
      key: 'dtInicioVigencia',
      title: 'Inicio Vigência',
      format: GridView.DataTypes.Date,
      sortable: false,
    },

    {
      key: 'dtFimVigencia',
      title: 'Fim Vigência',
      format: GridView.DataTypes.Date,
      sortable: false,
    },

    {
      key: 'flgFaturaConsolidador',
      title: 'Fatura Consolidador',
      format: GridView.DataTypes.Boolean,
      sortable: false,
    },
    {
      key: 'nrSeqAssocPlanoConv',
      type: GridView.ColumnTypes.Button,
      title: 'Editar',
      onClick: (e, dataSource) => onClickEditPlanoConvenio(e, dataSource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqAssocPlanoConv',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemovePlanoConvenio(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsMatriculas = [
    { key: 'NrSeqAssocMatriculaConv', type: GridView.ColumnTypes.Checkbox },
    { key: 'noMatricula', title: 'Matrícula/Contrato', sortable: false },
    {
      key: 'planoConvenio.convenio.noConvenio',
      title: 'Convênio',
      sortable: false,
    },
    {
      key: 'planoConvenio.noPlanoConvenio',
      title: 'Plano Convênio',
      sortable: false,
    },
    {
      key: 'vlrAjuste',
      title: 'Valor Ajuste',
      format: GridView.DataTypes.Money,
      sortable: false,
    },

    {
      key: 'flgAjustePercentual',
      title: 'Ajuste %',
      format: GridView.DataTypes.Boolean,
      sortable: false,
    },
    {
      key: 'centroDeCusto.noCentroDeCusto',
      title: 'Centro De Custo',
      sortable: false,
    },
    {
      key: 'flgAtivo',
      title: 'Matrícula Ativa',
      format: GridView.DataTypes.Boolean,
      sortable: false,
    },
    {
      key: 'NrSeqAssocMatriculaConv',
      type: GridView.ColumnTypes.Button,
      title: 'Editar',
      onClick: (e, dataSource) => onClickEditMatricula(e, dataSource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'NrSeqAssocMatriculaConv',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemoveMatricula(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsAssEmpresaOrigem = [
    { key: 'NrSeqPessoaEmpOrig', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'noEmpresaOrigem',
      title: 'Empresa Origem',
      sortable: false,
    },
    {
      key: 'centro.noEmpresaOrigemCentro',
      title: 'Centro',
      sortable: false,
    },
    {
      key: 'setor.noEmpresaOrigemSetor',
      title: 'Setor',
      sortable: false,
    },

    {
      key: 'dtIniVigencia',
      title: 'Início Vigência',
      format: GridView.DataTypes.Date,
      sortable: false,
    },
    {
      key: 'dtFimVigencia',
      title: 'Fim Vigência',
      format: GridView.DataTypes.Date,
      sortable: false,
    },
    {
      key: 'NrSeqPessoaEmpOrig',
      type: GridView.ColumnTypes.Button,
      title: 'Editar',
      onClick: (e, dataSource) => onClickEditEmpresaOrigem(e, dataSource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'NrSeqPessoaEmpOrig',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemoveEmpresaOrigem(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <div>
      <div className='row mb-3'>
        <div className='col-2'>
          <Textbox
            label='Código'
            text={dadosPlanoConvAssociado.cdAssociado}
            maxLength={20}
            onChangedValue={(cdAssociado) =>
              setDadosPlanoConvAssociado({
                ...dadosPlanoConvAssociado,
                cdAssociado,
              })
            }
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data Associação'
            text={dadosPlanoConvAssociado.dtAssociacao}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtAssociacao) =>
              setDadosPlanoConvAssociado({
                ...dadosPlanoConvAssociado,
                dtAssociacao,
              })
            }
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Nr. Registro'
            text={dadosPlanoConvAssociado.nrMatricula}
            maxLength={20}
            onChangedValue={(nrMatricula) =>
              setDadosPlanoConvAssociado({
                ...dadosPlanoConvAssociado,
                nrMatricula,
              })
            }
          />
        </div>
        <div className='col-2 '>
          <Switch
            formControl
            checked={dadosPlanoConvAssociado.flgAtivo}
            label='Ativo'
            onChange={(flgAtivo) =>
              setDadosPlanoConvAssociado({
                ...dadosPlanoConvAssociado,
                flgAtivo,
              })
            }
          />
        </div>

        <div className='col-2'>
          <Switch
            formControl
            checked={dadosPlanoConvAssociado.flgConsolidador}
            label='Consolidador'
            onChange={(flgConsolidador) =>
              setDadosPlanoConvAssociado({
                ...dadosPlanoConvAssociado,
                flgConsolidador,
              })
            }
          />
        </div>
        <div className='col-2'>
          <Switch
            formControl
            checked={dadosPlanoConvAssociado.flgGeraEtiqueta}
            label='Gera Etiqueta'
            onChange={(flgGeraEtiqueta) =>
              setDadosPlanoConvAssociado({
                ...dadosPlanoConvAssociado,
                flgGeraEtiqueta,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Associado Consolidador'
            searchDataSource={onSearchAssociadoConsolidador}
            selectedItem={dadosPlanoConvAssociado.consolidador}
            onSelectItem={(consolidador) =>
              setDadosPlanoConvAssociado({
                ...dadosPlanoConvAssociado,
                consolidador,
                nrSeqPessoaAssConsolidador:
                  consolidador.nrSeqPessoaAssConsolidador,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-2'>
          <Autocomplete
            label='Tipo Associado'
            searchDataSource={onSearchTipoAssociado}
            selectedItem={dadosPlanoConvAssociado.tipoAssociado}
            onSelectItem={(tipoAssociado) =>
              setDadosPlanoConvAssociado({
                ...dadosPlanoConvAssociado,
                tipoAssociado,
                nrSeqTipoAssociado: tipoAssociado.nrSeqTipoAssociado,
              })
            }
            dataSourceTextProperty='noTipoAssociado'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Motivo Desligamento'
            searchDataSource={onSearchMotivoDesligamento}
            selectedItem={dadosPlanoConvAssociado.motivoDesligamento}
            onSelectItem={(motivoDesligamento) =>
              setDadosPlanoConvAssociado({
                ...dadosPlanoConvAssociado,
                motivoDesligamento,
                nrSeqMotivoDesligamento:
                  motivoDesligamento.nrSeqMotivoDesligamento,
              })
            }
            dataSourceTextProperty='noMotivoDesligamento'
          />
        </div>
        <div className='col-2'>
          <Autocomplete
            label='Vencimento'
            searchDataSource={onSearchVencimento}
            selectedItem={dadosPlanoConvAssociado.vencimento}
            onSelectItem={(vencimento) =>
              setDadosPlanoConvAssociado({
                ...dadosPlanoConvAssociado,
                vencimento,
                nrSeqVencimento: vencimento.nrSeqVencimento,
              })
            }
            dataSourceTextProperty='noVencimento'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-2'>
          <Textbox
            label='Valor Contrato'
            text={dadosPlanoConvAssociado.vlrContrato}
            maxLength={20}
            mask={MaskTypes.Currency}
            onChangedValue={(vlrContrato) =>
              setDadosPlanoConvAssociado({
                ...dadosPlanoConvAssociado,
                vlrContrato,
              })
            }
          />
        </div>

        <div className='col-8'>
          <Textbox
            label='Observação'
            text={dadosPlanoConvAssociado.observacao}
            maxLength={200}
            onChangedValue={(observacao) =>
              setDadosPlanoConvAssociado({
                ...dadosPlanoConvAssociado,
                observacao,
              })
            }
          />
        </div>
      </div>
      <div className='mt-4 pb-5'>
        <Panel>
          <Panel.Header title='Planos do Associado' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-4'>
                <Autocomplete
                  label='Convênio'
                  searchDataSource={onSearchConvenio}
                  selectedItem={planoConvenioAss.convenio}
                  onSelectItem={(convenio) =>
                    setPlanoConvenioAss({
                      ...planoConvenioAss,
                      convenio,
                      nrSeqConvenio: convenio.nrSeqConvenio,
                    })
                  }
                  dataSourceTextProperty='noConvenio'
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  label='Plano Convênio'
                  searchDataSource={onSearchPlanoConvenio}
                  enabled={!!planoConvenioAss?.nrSeqConvenio}
                  selectedItem={planoConvenioAss.planoConvenio}
                  onSelectItem={(planoConvenio) =>
                    setPlanoConvenioAss({
                      ...planoConvenioAss,
                      planoConvenio,
                      nrSeqPlanoConvenio: planoConvenio.nrSeqPlanoConvenio,
                    })
                  }
                  dataSourceTextProperty='noPlanoConvenio'
                />
              </div>

              <div className='col-4'>
                <Autocomplete
                  label='Forma Pagamento'
                  searchDataSource={onSearchFormaPagamento}
                  selectedItem={planoConvenioAss.formaPagamento}
                  onSelectItem={(formaPagamento) =>
                    setPlanoConvenioAss({
                      ...planoConvenioAss,
                      formaPagamento,
                      nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                    })
                  }
                  dataSourceTextProperty='noFormaPagamento'
                />
              </div>
            </div>
            <div className='row row mb-3'>
              <div className='col-4'>
                <Autocomplete
                  label='Informação Bancária'
                  searchDataSource={onSearchInformacaoBancaria}
                  selectedItem={planoConvenioAss.pessoaInformacaoBancaria ?? {}}
                  onSelectItem={(pessoaInformacaoBancaria) =>
                    setPlanoConvenioAss({
                      ...planoConvenioAss,
                      pessoaInformacaoBancaria,
                      nrSeqPessoaInformacaoBancaria:
                        pessoaInformacaoBancaria.nrSeqPessoaInformacaoBancaria,
                    })
                  }
                  dataSourceTextProperty='noDescricao'
                />
              </div>

              <div className='col-2 mb-3'>
                <DatePicker
                  label='Inicio Vigência'
                  text={planoConvenioAss.dtInicioVigencia}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtInicioVigencia) =>
                    setPlanoConvenioAss({
                      ...planoConvenioAss,
                      dtInicioVigencia,
                    })
                  }
                />
              </div>

              <div className='col-2 mb-3'>
                <DatePicker
                  label='Fim Vigência'
                  text={planoConvenioAss.dtFimVigencia}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtFimVigencia) =>
                    setPlanoConvenioAss({
                      ...planoConvenioAss,
                      dtFimVigencia,
                    })
                  }
                />
              </div>

              <div className='col d-flex align-items-center'>
                <Button
                  onClick={onClickAddPlano}
                  outline
                  theme={Theme.Success}
                  template={Button.Templates.Button}
                  text='Adicionar'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <GridView
                  ref={gridViewPlanosAssociado}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsPlanosConvAssociado}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='mt-3'>
        <Panel>
          <Panel.Header title='Matrículas por Convênio' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-3'>
                <Autocomplete
                  label='Convênio'
                  searchDataSource={onSearchConvenio}
                  selectedItem={matriculaConvenioAss.convenio}
                  onSelectItem={(convenio) =>
                    setMatriculaConvenioAss({
                      ...planoConvenioAss,
                      convenio,
                      nrSeqConvenio: convenio.nrSeqConvenio,
                    })
                  }
                  dataSourceTextProperty='noConvenio'
                />
              </div>

              <div className='col-3'>
                <Autocomplete
                  label='Plano Convênio'
                  searchDataSource={onSearchPlanoConvenio}
                  enabled={!!matriculaConvenioAss.nrSeqConvenio}
                  selectedItem={matriculaConvenioAss.planoConvenio}
                  onSelectItem={(planoConvenio) =>
                    setMatriculaConvenioAss({
                      ...matriculaConvenioAss,
                      planoConvenio,
                      nrSeqPlanoConvenio: planoConvenio.nrSeqPlanoConvenio,
                    })
                  }
                  dataSourceTextProperty='noPlanoConvenio'
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Matrícula/Nr. Contrato'
                  text={matriculaConvenioAss.noMatricula}
                  maxLength={20}
                  onChangedValue={(noMatricula) =>
                    setMatriculaConvenioAss({
                      ...matriculaConvenioAss,
                      noMatricula,
                    })
                  }
                />
              </div>

              <div className='col-2'>
                <Textbox
                  label='Valor Ajuste'
                  text={matriculaConvenioAss.vlrAjuste}
                  maxLength={20}
                  mask={MaskTypes.Currency}
                  onChangedValue={(vlrAjuste) =>
                    setMatriculaConvenioAss({
                      ...matriculaConvenioAss,
                      vlrAjuste,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Switch
                  formControl
                  checked={matriculaConvenioAss.flgAjustePercentual}
                  label='Valor Ajuste %'
                  onChange={(flgAjustePercentual) =>
                    setMatriculaConvenioAss({
                      ...matriculaConvenioAss,
                      flgAjustePercentual,
                    })
                  }
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-3'>
                <Autocomplete
                  label='Forma Pagamento'
                  searchDataSource={onSearchFormaPagamento}
                  selectedItem={matriculaConvenioAss.formaPagamento}
                  onSelectItem={(formaPagamento) =>
                    setMatriculaConvenioAss({
                      ...matriculaConvenioAss,
                      formaPagamento,
                      nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                    })
                  }
                  dataSourceTextProperty='noFormaPagamento'
                />
              </div>
              <div className='col-3'>
                <Autocomplete
                  label='Informação Bancária'
                  searchDataSource={onSearchInformacaoBancaria}
                  selectedItem={matriculaConvenioAss.informacaoBancaria}
                  onSelectItem={(informacaoBancaria) =>
                    setMatriculaConvenioAss({
                      ...matriculaConvenioAss,
                      informacaoBancaria,
                      nrSeqPessoaInformacaoBancaria:
                        informacaoBancaria.nrSeqPessoaInformacaoBancaria,
                    })
                  }
                  dataSourceTextProperty='noDescricao'
                />
              </div>

              <div className='col-2'>
                <DatePicker
                  label='Inicio Vigência'
                  text={matriculaConvenioAss.dtInicioVigencia}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtInicioVigencia) =>
                    setMatriculaConvenioAss({
                      ...matriculaConvenioAss,
                      dtInicioVigencia,
                    })
                  }
                />
              </div>

              <div className='col-2'>
                <DatePicker
                  label='Fim Vigência'
                  text={matriculaConvenioAss.dtFimVigencia}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtFimVigencia) =>
                    setMatriculaConvenioAss({
                      ...matriculaConvenioAss,
                      dtFimVigencia,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Switch
                  formControl
                  checked={matriculaConvenioAss.flgAtivo}
                  label='Matrícula Ativa'
                  onChange={(flgAtivo) =>
                    setMatriculaConvenioAss({
                      ...matriculaConvenioAss,
                      flgAtivo,
                    })
                  }
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-3'>
                <Autocomplete
                  label='Centro de Custo'
                  searchDataSource={onSearchCentroDeCusto}
                  selectedItem={matriculaConvenioAss.centroDeCusto}
                  onSelectItem={(centroDeCusto) =>
                    setMatriculaConvenioAss({
                      ...matriculaConvenioAss,
                      centroDeCusto,
                      nrSeqCentroDeCusto: centroDeCusto.nrSeqCentroDeCusto,
                    })
                  }
                  dataSourceTextProperty='noCentroDeCusto'
                />
              </div>

              <div className='col mt-3'>
                <Button
                  onClick={onClickAddMatriculaConvenio}
                  outline
                  theme={Theme.Success}
                  template={Button.Templates.Button}
                  text='Adicionar'
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridViewMatriculas}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsMatriculas}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='mt-3'>
        <Panel>
          <Panel.Header title='Empresa Origem' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-4'>
                <Autocomplete
                  label={flgRural ? 'Atividade' : 'Universidade'}
                  searchDataSource={onSearchAtividade}
                  selectedItem={associadoEmpresaOrigem.empresaOrigem}
                  onSelectItem={(empresaOrigem) =>
                    setAssociadoEmpresaOrigem({
                      ...associadoEmpresaOrigem,
                      empresaOrigem,
                      nrSeqPessoaEmpOrig: empresaOrigem.nrSeqPessoaEmpOrig,
                      noEmpresaOrigem: empresaOrigem.noEmpresaOrigem,
                    })
                  }
                  dataSourceTextProperty='noEmpresaOrigem'
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  label='Centro'
                  searchDataSource={onSearchEmpresaOriCentro}
                  selectedItem={associadoEmpresaOrigem?.centro}
                  onSelectItem={(centro) =>
                    setAssociadoEmpresaOrigem({
                      ...associadoEmpresaOrigem,
                      centro,
                      nrSeqEmpresaOrigemCentro: centro.nrSeqEmpresaOrigemCentro,
                    })
                  }
                  dataSourceTextProperty='noEmpresaOrigemCentro'
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  label='Departamento'
                  searchDataSource={onSearchEmpresaOriSetor}
                  selectedItem={associadoEmpresaOrigem?.setor}
                  onSelectItem={(setor) =>
                    setAssociadoEmpresaOrigem({
                      ...associadoEmpresaOrigem,
                      setor,
                      nrSeqEmpresaOrigemSetor: setor.nrSeqEmpresaOrigemSetor,
                    })
                  }
                  dataSourceTextProperty='noEmpresaOrigemSetor'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-2 mb-3'>
                <DatePicker
                  label='Inicio Vigência'
                  text={associadoEmpresaOrigem?.dtIniVigencia}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtIniVigencia) =>
                    setAssociadoEmpresaOrigem({
                      ...associadoEmpresaOrigem,
                      dtIniVigencia,
                    })
                  }
                />
              </div>

              <div className='col-2 mb-3'>
                <DatePicker
                  label='Fim Vigência'
                  text={associadoEmpresaOrigem?.dtFimVigencia}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtFimVigencia) =>
                    setAssociadoEmpresaOrigem({
                      ...associadoEmpresaOrigem,
                      dtFimVigencia,
                    })
                  }
                />
              </div>
              <div className='col mt-2 d-flex align-items-center justify-content-end'>
                <Button
                  onClick={onClickAddEmpresaOrigem}
                  outline
                  theme={Theme.Success}
                  template={Button.Templates.Button}
                  text='Adicionar'
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridViewAssEmpresaOrigem}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsAssEmpresaOrigem}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
});

export default PlanosStep;
