import Cidade from '../SEG/cidade';

export default class TipoServicoCidade {
  constructor(jsonObject = {}) {
    this.nrSeqTipoServicoCidade = jsonObject.nrSeqTipoServicoCidade;
    this.nrSeqTipoServico = jsonObject.nrSeqTipoServico;
    this.nrSeqCidade = jsonObject.nrSeqCidade;
    this.aliqIssqn = jsonObject.aliqIssqn;
    this.flgIssqnRetido = jsonObject.flgIssQnRetido;
    this.cidade = new Cidade(jsonObject.cidade ?? {});
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
