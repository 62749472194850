import {
  DesagrupamentoTituloPagar,
  getRetornoTituloPagarVinculado,
} from 'core/services/FIN/tituloPagar';
import React, { useRef, useState, useEffect } from 'react';

import { Button, GridView, Modal, Notification } from 'ui/components';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';

export default function ModalDesagruparTitulo(props) {
  // const [data, setData] = useState();
  const [messageState, setMessage] = useState(null);
  const gridView = useRef();

  const { show, agrupamentoTituloValor } = props;

  useEffect(async () => {
    if (agrupamentoTituloValor !== undefined) {
      if (agrupamentoTituloValor.nrSeqTituloPagarFatura > 0) {
        const obj = await getRetornoTituloPagarVinculado(
          agrupamentoTituloValor
        );
        if (gridView && gridView.current) gridView.current.setDataSource(obj);
        // setData({ ...obj });
      }
    }
  }, [show]);

  const salvar = async () => {
    const { status, message: msg } = await DesagrupamentoTituloPagar(
      agrupamentoTituloValor
    );
    if (status === ResponseStatus.Success) {
      if (gridView && gridView.current) gridView.current.setDataSource(null);
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    if (status === ResponseStatus.Success) {
      props.onClose({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
  };

  const cancelar = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
    });
  };

  const columns = [
    {
      key: 'nrTit',
      title: 'Nr Documento',
    },
    {
      key: 'nrOrdem',
      title: 'Nr Ordem',
    },
    {
      key: 'cdTituloPagar',
      title: 'Cód. Tit.',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'dtContab',
      title: 'Data Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'tipoDespesa.noTipoDespesa',
      title: 'Tipo Despesa',
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma Pagamento',
    },
    {
      key: 'noObs',
      title: 'Obs',
    },
  ];

  return (
    <Modal show={show} title='Titulos Agrupados' size={BootstrapSizes.Large}>
      <Modal.Body className='p-4 pt-3'>
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>

        <div className='row'>
          <div className='col-4 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Cancelar Agrupamento'
              onClick={salvar}
            />
          </div>
          <div className='col-2 mt-3'>
            <Button
              theme={Theme.Danger}
              template={Button.Templates.Button}
              text='Sair'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
