import Pagination from 'core/models/pagination';
import { ContaCabecalho } from 'core/models/TEL';
import qs from 'qs';
import api from '../api';

const baseURL = `/TEL/RecalculaConta`;

export const postImportaConsumo = async (files, nrseqcontacabecalho) => {
  const formData = new FormData();
  formData.append('nrseqcontacabecalho', nrseqcontacabecalho);
  files.forEach((file) => {
    formData.append('files', file);
  });

  const response = await api.post(`${baseURL}`, formData);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    value,
  };
};

export const getRecalculaContaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchRecalculaContaPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ContaCabecalho(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};
export const postRecalculaContas = async (parameters) => {
  const response = await api.post(`${baseURL}/RecalcularContas`, parameters);
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    relatorio: value,
  };
};

export const postRecalculaContaImportaConsumoMVNO = async (parameters) => {
  const response = await api.post(`${baseURL}/ImportarConsumoMVNO`, parameters);
  return response.data ?? {};
};

export const postRecalculaFaturas = async (parameters) => {
  const response = await api.post(`${baseURL}/RecalcularFaturas`, parameters);
  const { status, message } = response.data ?? {};
  return {
    status,
    message,
  };
};

export const postRemoveVinculoCliente = async (parameters) => {
  const response = await api.post(
    `${baseURL}/RemoveVinculoCliente`,
    parameters
  );
  const { status, message } = response.data ?? {};
  return {
    status,
    message,
  };
};
export const postLimpaTipoContaChamada = async (parameters) => {
  const response = await api.post(
    `${baseURL}/LimpaTipoChamadaContaCabecalho`,
    parameters
  );
  const { status, message } = response.data ?? {};
  return {
    status,
    message,
  };
};
export const postSalvaDePara = async (parameters) => {
  const response = await api.post(`${baseURL}/SalvaDePara`, parameters);
  const { status, message } = response.data ?? {};
  return {
    status,
    message,
  };
};

export const postRecalculaRateioContasEmpresa = async (parameters) => {
  const response = await api.post(`${baseURL}/RecalcularRateio`, parameters);
  const { status, message } = response.data ?? {};
  return {
    status,
    message,
  };
};

export const postVerificaInconsistencias = async (parameters) => {
  const response = await api.post(
    `${baseURL}/VerificaInconsistencias`,
    parameters
  );
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    relatorio: value,
  };
};

export const printLinhasFaltantes = async (nrSeqsContaCabecalho) => {
  const response = await api.get(`${baseURL}/PrintLinhasFaltantes`, {
    params: { nrSeqsContaCabecalho },
    paramsSerializer: (params) => qs.stringify(params),
  });

  return response.data ?? {};
};
