import React, { useState, useEffect } from 'react';
import { IPneu } from 'core/interfaces/FRO/pneu';
import { getPneuList } from 'core/services/FRO/pneu';
import { getVeiculo } from 'core/services/FRO/veiculo';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { Modal, Notification, Panel, Button } from 'ui/components';
import ControlTires from 'ui/components/ControlTires/index';

interface IData {
  nrSeqVeiculo?: number;
  veiculo?: any;
  nrSeqProduto: number;
  pneus?: Array<IPneu>;
}

interface IProps {
  show: boolean;
  setLoading: any;
  data: IData;
  onClose: () => void;
}

export function ModalVincularPneuVeiculo({
  show,
  setLoading,
  data,
  onClose,
}: IProps) {
  const [message, setMessage] = useState<any>({});
  const [dataVeiculo, setDataVeiculo] = useState<IData | null>(null);

  const onSetMessage = (status: number, msg: string) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = async () => {
    setLoading(true);

    const veiculo = await getVeiculo(data?.nrSeqVeiculo);

    let pneus = [];
    if (data?.nrSeqProduto) {
      const { data: pneusList } = await getPneuList({
        nrSeqProduto: data?.nrSeqProduto,
        flgDisponivel: true,
      });
      pneus = pneusList;
    }

    setDataVeiculo({ ...data, veiculo, pneus });

    setLoading(false);
  };

  useEffect(() => {
    if (show) load();
  }, [show]);

  const handleOnClose = () => {
    onClose();
  };

  return (
    <div className='row mb-3'>
      {/* @ts-expect-error */}
      <Modal
        show={show}
        title='Vincular Pneus'
        onClose={() => handleOnClose()}
        size={BootstrapSizes.ExtraLarge}
      >
        {/* @ts-expect-error */}
        <Modal.Body>
          {message && (
            <>
              <div className='row mb-3'>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Notification
                    message={message.message}
                    theme={message.theme}
                    onClose={() => setMessage(null)}
                  />
                </div>
              </div>
            </>
          )}

          <div className='row mb-3'>
            {/* @ts-expect-error */}
            <Panel>
              {/* @ts-expect-error */}
              <Panel.Header
                title={`Veículo: ${dataVeiculo?.veiculo?.placa}`}
                theme={Theme.Primary}
              />
              <Panel.Body>
                {/* @ts-expect-error */}
                <ControlTires
                  nrSeqVeiculo={dataVeiculo?.nrSeqVeiculo}
                  onMessage={onSetMessage}
                  onSetLoading={setLoading}
                  print={false}
                />
              </Panel.Body>
            </Panel>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            text='Fechar'
            theme={Theme.Danger}
            onClick={() => handleOnClose()}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
}
