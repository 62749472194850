export default class PocPocoEntradaAgua {
  constructor(jsonObject = {}) {
    this.nrSeqPocPocoEntradaAgua = jsonObject.nrSeqPocPocoEntradaAgua;
    this.nrSeqPocPoco = jsonObject.nrSeqPocPoco;
    this.nrProfundidade = jsonObject.nrProfundidade;
    this.nrVazao = jsonObject.nrVazao;
    this.noObservacao = jsonObject.noObservacao;
    this.flgCaptadaIsolada = jsonObject.flgCaptadaIsolada;
    this.flgPorosoFraturado = jsonObject.flgPorosoFraturado;
    this.status = jsonObject.status;
  }

  get getCaptadaIsolada() {
    return this.flgCaptadaIsolada === 'C' ? 'Captada' : 'Isolada';
  }

  get getPorosoFraturado() {
    return this.flgPorosoFraturado === 'P' ? 'Poroso' : 'Fraturado';
  }

  toJson = () => JSON.stringify(this);
}
