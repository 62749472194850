export default class TipoOcorrenciaColetaEmbarque {
  constructor(jsonObject = {}) {
    this.nrSeqTipoOcorrenciaColetaEmbarque =
      jsonObject.nrSeqTipoOcorrenciaColetaEmbarque;
    this.cdTipoOcorrenciaColetaEmbarque =
      jsonObject.cdTipoOcorrenciaColetaEmbarque;
    this.noTipoOcorrenciaColetaEmbarque =
      jsonObject.noTipoOcorrenciaColetaEmbarque;
  }

  toJson = () => JSON.stringify(this);
}
