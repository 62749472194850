import ProdutoAgrupadorManutencao from 'core/models/EST/produtoAgrupadorManutencao';
import api from '../api';

const baseURL = `/EST/ProdutoAgrupador_Manutencao`;

export const getProdutoAgrupadorManutencao = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ProdutoAgrupadorManutencao(value) : {};
};
