import React, { useRef } from 'react';
import { CSDSelPage, Popover } from 'ui/components';
import { Page } from 'ui/Helpers/interfaces';
import { Theme } from 'ui/Helpers/enums';

export default function Profissao({ transaction, isActive }: Page) {
  const popover = useRef<any>();

  return (
    <CSDSelPage
      isActive={isActive}
      title='Pagina de Testes'
      transaction={transaction}
    >
      <Popover
        ref={popover}
        buttonTheme={Theme.Primary}
        hover
        placement='bottom'
        isButton={false}
      >
        <Popover.Header
          title='Titulo Teste'
          icon={['fas', 'info-circle']}
          theme={Theme.Primary}
        />
        <Popover.Body>
          testando o popover com texto grandetestando o popover com texto grande
          testando o popover com texto grande testando o popover com texto
          grande testando o popover com texto grande testando o popover com
          texto grande testando o popover com texto grande testando o popover
          com texto grande testando o popover com texto grande
        </Popover.Body>
      </Popover>
    </CSDSelPage>
  );
}
