export default class PocPocoPerflitCamada {
  constructor(jsonObject = {}) {
    this.nrSeqPocPocoPerflitCamada = jsonObject.nrSeqPocPocoPerflitCamada;
    this.nrSeqPocPoco = jsonObject.nrSeqPocPoco;
    this.nrInicio = jsonObject.nrInicio;
    this.nrFim = jsonObject.nrFim;
    this.nrSeqPocPerflitCamada = jsonObject.nrSeqPocPerflitCamada;
    this.noPocPerflitCamada = jsonObject.noPocPerflitCamada;
    this.cdPocPerflitCamada = jsonObject.cdPocPerflitCamada;
    this.noObservacao = jsonObject.noObservacao;
    this.totalCamada = jsonObject.totalCamada;
    this.noImagemBase64 = jsonObject.noImagemBase64;

    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
