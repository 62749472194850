export default class PocPocoDesenvolvimento {
  constructor(jsonObject = {}) {
    this.nrSeqPocPocoDesenvolvimento = jsonObject.nrSeqPocPocoDesenvolvimento;
    this.nrSeqPocPoco = jsonObject.nrSeqPocPoco;
    this.nrTempoStr = jsonObject.nrTempoStr;
    this.nrSeqPocMetodoDesenvolvimento =
      jsonObject.nrSeqPocMetodoDesenvolvimento;
    this.cdPocMetodoDesenvolvimento = jsonObject.cdPocMetodoDesenvolvimento;
    this.noPocMetodoDesenvolvimento = jsonObject.noPocMetodoDesenvolvimento;
    this.totalDesenvolvimentos = jsonObject.totalDesenvolvimentos;

    this.status = jsonObject.status;
  }

  get getMetodoDesenvolvimento() {
    return `${this.cdPocMetodoDesenvolvimento} - ${this.noPocMetodoDesenvolvimento}`;
  }

  toJson = () => JSON.stringify(this);
}
