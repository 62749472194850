export default class TipoOcorrenciaBoleto {
  constructor(jsonObject = {}) {
    this.nrSeqTipoOcorrenciaBoleto = jsonObject.nrSeqTipoOcorrenciaBoleto;
    this.cdTipoOcorrenciaBoleto = jsonObject.cdTipoOcorrenciaBoleto;
    this.noTipoOcorrenciaBoleto = jsonObject.noTipoOcorrenciaBoleto;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqBanco = jsonObject.nrSeqBanco;
    this.flgLiquida = jsonObject.flgLiquida;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.flgRemessa = jsonObject.flgRemessa;
    this.flgAlteraValor = jsonObject.flgAlteraValor;
    this.flgAlteraVencimento = jsonObject.flgAlteraVencimento;
    this.nrSeqTipoOcorrenciaBoletoPai = jsonObject.nrSeqTipoOcorrenciaBoletoPai;
    this.flgPossuiMotivo = jsonObject.flgPossuiMotivo;
    this.flgPedidodeBaixa = jsonObject.flgPedidodeBaixa;
    this.noLayout = jsonObject.noLayout;
    this.flgGrupo = jsonObject.flgGrupo;
    this.flgCancelamento = jsonObject.flgCancelamento;
  }

  toJson = () => JSON.stringify(this);
}
