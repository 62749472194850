import React, { useState, useEffect } from 'react';
import { /* ResponseStatus , */ BootstrapSizes, Theme } from 'ui/Helpers/utils';
import {
  Button,
  Loader,
  Modal,
  Notification,
  Autocomplete,
  Switch,
  Textbox,
} from 'ui/components';
import { ResponseStatus, StatusGrid } from 'ui/Helpers/enums.ts';
import { Message } from 'ui/Helpers/interfaces.ts';
import { getTributacaoIcmsAutoComplete } from 'core/services/FIS';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
import { IGrupoIcmsEstado } from 'core/interfaces/FIS';
import { MaskTypes } from 'ui/Helpers/masks';

const ModalGerateMassivoEstado = ({
  dataIcms,
  show,
  onClose,
  onCloseModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [data, setData] = useState<IGrupoIcmsEstado | any>({
    flgIcmsDesonerado: false,
    flgParcial: false,
    aliquotaIcms: 0,
    aliquotaIcmsDesonerado: 0,
    aliquotaFCP: 0,
    baseCalculoFCP: 0,
    baseCalculoIcms: 0,
    baseCalculoIcmsDesonerado: 0,
    nrSeqPaisOrigem: undefined,
    nrSeqPaisDestino: undefined,
  });

  const onSetMessage = (status: ResponseStatus, msg: string = ''): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  useEffect(() => {
    setData(dataIcms);
    onSetMessage(ResponseStatus.Success);
  }, [dataIcms]);

  const validateItemsGrupoIcmsEstado = (datasource: any) => {
    if (datasource.aliquotaIcms < 0 || datasource.aliquotaIcms > 100) {
      onSetMessage(ResponseStatus.Error, 'Alíquota deve ser entre 0 e 100.');
      return false;
    }

    if (
      datasource.aliquotaIcms === null ||
      datasource.aliquotaIcms === undefined
    ) {
      onSetMessage(ResponseStatus.Error, 'Informar algum valor de Alíquota.');
      return false;
    }

    if (datasource.baseCalculoIcms < 0 || datasource.baseCalculoIcms > 100) {
      onSetMessage(
        ResponseStatus.Error,
        'O Base de Cálculo deve estar entre 0 e 100.'
      );
      return false;
    }

    if (
      datasource.baseCalculoIcms === null ||
      datasource.baseCalculoIcms === undefined
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Informar algum valor de Base de Cálculo.'
      );
      return false;
    }

    if (datasource.flgIcmsDesonerado) {
      if (
        datasource.aliquotaIcmsDesonerado < 0 ||
        datasource.aliquotaIcmsDesonerado > 100
      ) {
        onSetMessage(
          ResponseStatus.Error,
          'A Aliquota Desonerada deve estar entre 0 e 100.'
        );
        return false;
      }

      if (
        datasource.baseCalculoIcmsDesonerado < 0 ||
        datasource.baseCalculoIcmsDesonerado > 100
      ) {
        onSetMessage(
          ResponseStatus.Error,
          'O Base Calculo Desonerada deve estar entre 0 e 100.'
        );
        return false;
      }
    }

    if (
      datasource.baseCalculoIcmsDesonerado === null ||
      datasource.baseCalculoIcmsDesonerado === undefined
    ) {
      datasource.baseCalculoIcmsDesonerado = 0;
    }

    if (
      datasource.aliquotaIcmsDesonerado === null ||
      datasource.aliquotaIcmsDesonerado === undefined
    ) {
      datasource.aliquotaIcmsDesonerado = 0;
    }

    if (datasource.aliquotaFCP < 0 || datasource.aliquotaFCP > 100) {
      onSetMessage(
        ResponseStatus.Error,
        'Alíquota FCP deve ser entre 0 e 100.'
      );
      return false;
    }

    if (
      datasource.aliquotaFCP === null ||
      datasource.aliquotaFCP === undefined
    ) {
      datasource.aliquotaFCP = 0;
    }

    if (datasource.baseCalculoFCP < 0 || datasource.baseCalculoFCP > 100) {
      onSetMessage(
        ResponseStatus.Error,
        'Base de Cálculo FCP deve ser entre 0 e 100.'
      );
      return false;
    }

    if (
      datasource.baseCalculoFCP === null ||
      datasource.baseCalculoFCP === undefined
    ) {
      datasource.baseCalculoFCP = 0;
    }

    if (
      datasource.nrSeqTributacaoIcms === null ||
      datasource.nrSeqTributacaoIcms === undefined
    ) {
      onSetMessage(ResponseStatus.Error, 'Campo Obrigatório: Tributação ICMS.');
      return false;
    }

    return true;
  };

  const onSave = async () => {
    setLoading(true);
    if (validateItemsGrupoIcmsEstado(data)) {
      const { estados: estadosOrigem } = await getEstadoAutoComplete({});

      const { estados: estadosDestino } = await getEstadoAutoComplete({});

      const updatedGrupoIcmsEstados = [];

      estadosOrigem.forEach((estadoOrigem: any) => {
        estadosDestino.forEach((estadoDestino: any) => {
          const newEntry = {
            ...data,
            nrSeqEstadoOrigem: estadoOrigem.nrSeqEstado,
            estadoOrigem,
            estadoDestino,
            nrSeqEstadoDestino: estadoDestino.nrSeqEstado,
            aliquotaIcms: data.aliquotaIcms,
            aliquotaIcmsDesonerado: data.aliquotaIcmsDesonerado,
            aliquotaFCP: data.aliquotaFCP,
            baseCalculoIcms: data.baseCalculoIcms,
            baseCalculoIcmsDesonerado: data.baseCalculoIcmsDesonerado,
            baseCalculoFCP: data.baseCalculoFCP,
            nrSeqTributacaoIcms: data.nrSeqTributacaoIcms,
            flgIcmsDesonerado: data.flgIcmsDesonerado,
            flgParcial: data.flgParcial,
            grupoIcmsEstados: undefined,
            grupoIcmsMensagemEstados: undefined,
            status: StatusGrid.Inserir,
          };

          updatedGrupoIcmsEstados.push(newEntry);
        });
      });
      onClose({
        grupoIcmsEstados: updatedGrupoIcmsEstados,
      });
    }

    setLoading(false);
  };

  const onSearchTributacaoIcms = async (e: any) => {
    const { data: tributacaoIcms } = await getTributacaoIcmsAutoComplete({
      noTributacaoIcms: e,
    });
    return tributacaoIcms;
  };

  return (
    <Modal
      show={show}
      size={BootstrapSizes.ExtraLarge}
      title='Informar os valores de massivo'
      onClose={onClose}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col-md-12' style={{ height: '400px' }}>
            <div className='col mt-3'>
              <div className='col-3 mb-3'>
                <Textbox
                  label='* Alíquota'
                  maxLength={7}
                  decimalPlaces={2}
                  mask={MaskTypes.DecimalCustom}
                  text={data.aliquotaIcms}
                  onChangedValue={(aliquotaIcms: string) => {
                    setData({
                      ...data,
                      aliquotaIcms,
                    });
                  }}
                />
              </div>
              <div className='col-3 mb-3'>
                <Textbox
                  label='* Base Cálculo'
                  maxLength={7}
                  decimalPlaces={2}
                  mask={MaskTypes.DecimalCustom}
                  text={data.baseCalculoIcms}
                  onChangedValue={(baseCalculoIcms: string) => {
                    setData({
                      ...data,
                      baseCalculoIcms,
                    });
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <Switch
                  formControl
                  checked={data?.flgIcmsDesonerado}
                  label='* ICMS Desonerado'
                  onChange={(flgIcmsDesonerado: boolean) =>
                    setData({
                      ...data,
                      flgIcmsDesonerado,
                    })
                  }
                />
              </div>
              {data?.flgIcmsDesonerado && (
                <div className='col-2 mb-3'>
                  <Textbox
                    label='* Alíquota Desonerado'
                    maxLength={7}
                    decimalPlaces={2}
                    mask={MaskTypes.DecimalCustom}
                    text={data.aliquotaIcmsDesonerado}
                    onChangedValue={(aliquotaIcmsDesonerado: string) => {
                      setData({
                        ...data,
                        aliquotaIcmsDesonerado,
                      });
                    }}
                  />
                </div>
              )}
              {data?.flgIcmsDesonerado && (
                <div className='col-2 mb-3'>
                  <Textbox
                    label='* Base Cálculo Desonerado'
                    maxLength={7}
                    decimalPlaces={2}
                    mask={MaskTypes.DecimalCustom}
                    text={data.baseCalculoIcmsDesonerado}
                    onChangedValue={(baseCalculoIcmsDesonerado: string) => {
                      setData({
                        ...data,
                        baseCalculoIcmsDesonerado,
                      });
                    }}
                  />
                </div>
              )}
              <div className='col-2 mb-3'>
                <Textbox
                  label='* Alíquota FCP'
                  maxLength={7}
                  decimalPlaces={2}
                  mask={MaskTypes.DecimalCustom}
                  text={data.aliquotaFCP}
                  onChangedValue={(aliquotaFCP: string) => {
                    setData({
                      ...data,
                      aliquotaFCP,
                    });
                  }}
                />
              </div>
              <div className='col-2 mb-3'>
                <Textbox
                  label='* Base Cálculo FCP'
                  maxLength={7}
                  decimalPlaces={2}
                  mask={MaskTypes.DecimalCustom}
                  text={data.baseCalculoFCP}
                  onChangedValue={(baseCalculoFCP: string) => {
                    setData({
                      ...data,
                      baseCalculoFCP,
                    });
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-5'>
                <Autocomplete
                  label='* Tributação'
                  searchDataSource={onSearchTributacaoIcms}
                  selectedItem={data.tributacaoIcms}
                  onSelectItem={(tributacaoIcms: any) => {
                    setData({
                      ...data,
                      tributacaoIcms,
                      nrSeqTributacaoIcms: tributacaoIcms.nrSeqTributacaoIcms,
                    });
                  }}
                  dataSourceTextProperty='noTributacaoIcms'
                />
              </div>
              <div className='col-1'>
                {/* @ts-expect-error */}
                <Switch
                  formControl
                  checked={data?.flgParcial}
                  label='* Parcial'
                  onChange={(flgParcial: boolean) =>
                    setData({
                      ...data,
                      flgParcial,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-3' />
      </Modal.Body>
      <Modal.Footer>
        <Button
          icon={['far', 'save']}
          text='Gerar Massivo ICMS dos Estados'
          theme={Theme.Success}
          onClick={onSave}
        />
        <Button
          icon='ban'
          text='Cancelar'
          theme={Theme.Danger}
          onClick={onCloseModal}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default ModalGerateMassivoEstado;
