import PocProdOrcamento from 'core/models/POC/pocProdOrcamento';
import ProdutoCodigo from 'core/models/EST/produtoCodigo';
import Produto from 'core/models/EST/produto';
import ProdutoUnidadeMedida from 'core/models/EST/produtoUnidadeMedida';

export default class OrdemServicoProd {
  constructor(jsonObject = {}) {
    this.nrSeqOrdemServicoProd = jsonObject.nrSeqOrdemServicoProd;
    this.nrSeqOrdemServico = jsonObject.nrSeqOrdemServico;
    this.nrSeqPocProdOrcamento = jsonObject.nrSeqPocProdOrcamento;
    this.nrSeqProduto = jsonObject.nrSeqProduto;
    this.vlrUnitario = jsonObject.vlrUnitario;
    this.qtdeItem = jsonObject.qtdeItem;
    this.vlrTotal = jsonObject.vlrTotal;
    this.vlrDesconto = jsonObject.vlrDesconto;
    this.noDescricao = jsonObject.noDescricao;
    this.status = jsonObject.status;
    this.pocProdOrcamento = new PocProdOrcamento(
      jsonObject.pocProdOrcamento ?? {}
    );
    this.produtoCodigo = new ProdutoCodigo(jsonObject.produtoCodigo ?? {});
    this.produto = new Produto(jsonObject.produto ?? {});
    this.produtoUnidadeMedida = new ProdutoUnidadeMedida(
      jsonObject.produtoUnidadeMedida ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
