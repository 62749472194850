import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getUsuarioContaCorrentePagined,
  deleteAllUsuarioContaCorrente,
} from 'core/services/FIN/usuarioContaCorrente';
import { CSDSelPage, GridView, Autocomplete } from 'ui/components';
import { getUsuarioAutoComplete } from 'core/services/SEG';
import { getContaCorrenteAutoComplete } from 'core/services/FIN';

const columns = [
  {
    key: 'nrSeqUsuario',
    type: GridView.ColumnTypes.Checkbox,
  },
  { key: 'nrSeqContaCorrente', title: 'nrSeqContaCorrente', visible: false },
  { key: 'usuario.noLogin', title: 'Usuario' },
  { key: 'listaContaCorrenteString', title: 'Conta Corrente' },
];

export default function UsuarioContaCorrente({
  transaction,
  onOpenPage,
  isActive,
}) {
  const gridView = useRef();
  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getUsuarioContaCorrentePagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getUsuarioContaCorrentePagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'UsuarioContaCorrente/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqUsuario = sourceRow.find((e) => e.key === 'nrSeqUsuario').value;

    onOpenMaintenance(nrSeqUsuario);
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchUsuario = async (e) => {
    const {
      status,
      message: msg,
      data: usuarios,
    } = await getUsuarioAutoComplete({
      noLogin: e,
    });
    if (msg) onSetMessage(status, msg);
    return usuarios;
  };

  const onSearchContaCorrente = async (e) => {
    const {
      status,
      message: msg,
      data: contas,
    } = await getContaCorrenteAutoComplete({
      noContaCorrente: e,
    });
    if (msg) onSetMessage(status, msg);
    return contas;
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);
    const nrsequsuario = selecteds.map((row) => row[0].value);
    const listanrSeqContaCorrente = gridView.current.getDataSource();

    const filteredItens = listanrSeqContaCorrente.filter((item) =>
      nrsequsuario.includes(item.nrSeqUsuario)
    );
    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllUsuarioContaCorrente(
        filteredItens
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Usuario Conta Corrente'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row'>
        <div className='row col-4'>
          <Autocomplete
            label='Usuario'
            searchDataSource={onSearchUsuario}
            selectedItem={filtros.usuario}
            onSelectItem={(usuario) => {
              setFiltros({
                ...filtros,
                usuario,
                nrSeqUsuario: usuario.nrSeqUsuario,
              });
            }}
            dataSourceTextProperty='noLogin'
          />
        </div>
        <div className='row col-4'>
          <Autocomplete
            label='Conta Corrente'
            searchDataSource={onSearchContaCorrente}
            selectedItem={filtros.contas}
            onSelectItem={(contas) => {
              setFiltros({
                ...filtros,
                contas,
                nrSeqContaCorrente: contas.nrSeqContaCorrente,
              });
            }}
            dataSourceTextProperty='noContaCorrente'
          />
        </div>

        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              onColumnSort={onColumnSort}
              onPageSizeChange={onPageSizeChange}
              onPageChange={onPageChange}
              onRowDoubleClick={onRowDoubleClick}
            />
          </div>
        </div>
      </div>
    </CSDSelPage>
  );
}
