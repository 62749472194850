import React, { useEffect, useImperativeHandle, forwardRef } from 'react';
import {
  Panel,
  Textbox,
  Switch,
  Autocomplete,
  RadioButton,
  DatePicker,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { BootstrapSizes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Theme, ResponseStatus, StatusGrid } from 'ui/Helpers/enums.ts';
import { getTransportadoraAutoComplete } from 'core/services/FRO/transportadora';
import { getMotoristaTipoVinculoAutoComplete } from 'core/services/FRO/motoristaTipoVinculo';
import { getCarteiraTipoAutoComplete } from 'core/services/FRO/carteiraTipo';

interface Props {
  motoristaData: any;
  setMotoristaData: (object: any) => void;
  onSetMessage: (status: ResponseStatus, msg: string) => void;
  status?: StatusGrid;
}

const MotoristaPanel = forwardRef(
  ({ motoristaData, setMotoristaData, onSetMessage }: Props, ref) => {
    useEffect(() => {
      const objDestinatario = { ...motoristaData };
      setMotoristaData(objDestinatario);
    }, [motoristaData.nrSeqPessoaMot]);

    useImperativeHandle(ref, () => ({
      clear() {
        setMotoristaData({});
      },
    }));

    const statusSituacao = [
      { text: 'Liberado', value: 'L' },
      { text: 'Bloqueado', value: 'B' },
      { text: 'Rejeitado', value: 'R' },
    ];

    const onSearchTransportadora = async (e: any) => {
      const {
        status,
        message: msg,
        transportadoras,
      } = await getTransportadoraAutoComplete({
        noPessoa: e,
      });
      if (msg) onSetMessage(status, msg);
      return transportadoras;
    };

    const onSearchMotoristaTipoVinculo = async (e: any) => {
      const {
        status,
        message: msg,
        motoristaTipoVinculos,
      } = await getMotoristaTipoVinculoAutoComplete({
        noPessoa: e,
      });
      if (msg) onSetMessage(status, msg);
      return motoristaTipoVinculos;
    };

    const onSearchCarteiraTipo = async (e: any) => {
      const {
        status,
        message: msg,
        carteiraTipos,
      } = await getCarteiraTipoAutoComplete({
        noPessoa: e,
      });
      if (msg) onSetMessage(status, msg);
      return carteiraTipos;
    };

    return (
      <div className='col mt-3'>
        {/* @ts-expect-error */}
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header title='Motorista' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Código'
                  maxLength={20}
                  text={motoristaData.cdMotorista}
                  onChangedValue={(cdMotorista: string) => {
                    setMotoristaData({
                      ...motoristaData,
                      cdMotorista,
                    });
                  }}
                />
              </div>
              <div className='col-3'>
                <RadioButton
                  outline
                  id='txtFlgSituacao'
                  label='Situação'
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={motoristaData?.flgSituacao}
                  onChange={(flgSituacao: string) => {
                    setMotoristaData({ ...motoristaData, flgSituacao });
                  }}
                  buttons={statusSituacao}
                />
              </div>
              <div className='col-1'>
                {/* @ts-expect-error */}
                <Switch
                  formControl
                  checked={motoristaData?.flgFuncionario}
                  label='Funcionário'
                  onChange={(flgFuncionario: boolean) =>
                    setMotoristaData({
                      ...motoristaData,
                      flgFuncionario,
                    })
                  }
                />
              </div>
              <div className='col-1'>
                {/* @ts-expect-error */}
                <Switch
                  formControl
                  checked={motoristaData?.flgTransportadora}
                  label='Transportador'
                  onChange={(flgTransportadora: boolean) =>
                    setMotoristaData({
                      ...motoristaData,
                      flgTransportadora,
                    })
                  }
                />
              </div>
              {motoristaData?.flgTransportadora && (
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Número RNTRC'
                    text={motoristaData?.nrRntrc}
                    maxLength={8}
                    onChangedValue={(nrRntrc: string) => {
                      setMotoristaData({
                        ...motoristaData,
                        nrRntrc,
                      });
                    }}
                  />
                </div>
              )}
              <div className='col-4'>
                {/* @ts-expect-error */}
                <Autocomplete
                  label='Transportadora'
                  id='txtTransportadora'
                  searchDataSource={onSearchTransportadora}
                  selectedItem={motoristaData.transportadora}
                  onSelectItem={(transportadora: any) =>
                    setMotoristaData({
                      ...motoristaData,
                      transportadora,
                      nrSeqPessoaTran: transportadora.nrSeqPessoaTran,
                    })
                  }
                  dataSourceTextProperty='noPessoa'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-4'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Apelido'
                  maxLength={60}
                  text={motoristaData.noApelido}
                  onChangedValue={(noApelido: string) => {
                    setMotoristaData({
                      ...motoristaData,
                      noApelido,
                    });
                  }}
                />
              </div>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <DatePicker
                  id='txtDtAdmissao'
                  label='Data de Admisão'
                  text={motoristaData.dtAdmissao}
                  onChange={(dtAdmissao: string) =>
                    setMotoristaData({ ...motoristaData, dtAdmissao })
                  }
                />
              </div>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <DatePicker
                  label='Data de Demissão'
                  text={motoristaData.dtDemissao}
                  onChange={(dtDemissao: string) =>
                    setMotoristaData({ ...motoristaData, dtDemissao })
                  }
                />
              </div>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Valor Salário'
                  mask={MaskTypes.DecimalCustom}
                  maxLength={10}
                  decimalPlaces={2}
                  text={motoristaData.vlrSalario}
                  onChangedValue={(vlrSalario: string) => {
                    setMotoristaData({
                      ...motoristaData,
                      vlrSalario,
                    });
                  }}
                />
              </div>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Percentual Comissão'
                  mask={MaskTypes.DecimalCustom}
                  maxLength={6}
                  decimalPlaces={2}
                  text={motoristaData.vlrComissao}
                  onChangedValue={(vlrComissao: string) => {
                    setMotoristaData({
                      ...motoristaData,
                      vlrComissao,
                    });
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <Textbox
                  id='txtNrCarteira'
                  label='Número da Carteira'
                  maxLength={20}
                  text={motoristaData.nrCarteira}
                  onChangedValue={(nrCarteira: string) => {
                    setMotoristaData({
                      ...motoristaData,
                      nrCarteira,
                    });
                  }}
                />
              </div>
              <div className='col-4'>
                {/* @ts-expect-error */}
                <Autocomplete
                  id='txtCarteiraTipo'
                  label='Carteira Tipo'
                  searchDataSource={onSearchCarteiraTipo}
                  selectedItem={motoristaData.carteiraTipo}
                  onSelectItem={(carteiraTipo: any) =>
                    setMotoristaData({
                      ...motoristaData,
                      carteiraTipo,
                      nrSeqCarteiraTipo: carteiraTipo.nrSeqCarteiraTipo,
                    })
                  }
                  dataSourceTextProperty='noCarteiraTipo'
                />
              </div>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <DatePicker
                  id='txtDtPrimeiraHabilitacao'
                  label='Primeira Habilitação'
                  text={motoristaData.dtPrimeiraHabilitacao}
                  onChange={(dtPrimeiraHabilitacao: string) =>
                    setMotoristaData({
                      ...motoristaData,
                      dtPrimeiraHabilitacao,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <DatePicker
                  id='txtDtValidade'
                  label='Data da Validade'
                  text={motoristaData.dtValidade}
                  onChange={(dtValidade: string) =>
                    setMotoristaData({
                      ...motoristaData,
                      dtValidade,
                    })
                  }
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Número PIS'
                  maxLength={11}
                  text={motoristaData.nrPis}
                  onChangedValue={(nrPis: string) => {
                    setMotoristaData({
                      ...motoristaData,
                      nrPis,
                    });
                  }}
                />
              </div>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Valor Máximo Viagem'
                  mask={MaskTypes.DecimalCustom}
                  maxLength={10}
                  decimalPlaces={2}
                  text={motoristaData.vlrMaximoViagem}
                  onChangedValue={(vlrMaximoViagem: string) => {
                    setMotoristaData({
                      ...motoristaData,
                      vlrMaximoViagem,
                    });
                  }}
                />
              </div>
              <div className='col-6'>
                {/* @ts-expect-error */}
                <Textbox
                  label='obs'
                  maxLength={200}
                  text={motoristaData.obsMotorista}
                  onChangedValue={(obsMotorista: string) => {
                    setMotoristaData({
                      ...motoristaData,
                      obsMotorista,
                    });
                  }}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header title='Vínculos' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-5'>
                {/* @ts-expect-error */}
                <Autocomplete
                  id='txtMotoristaTipoVinculo'
                  label='Tipo Vínculo'
                  searchDataSource={onSearchMotoristaTipoVinculo}
                  selectedItem={motoristaData.motoristaTipoVinculo}
                  onSelectItem={(motoristaTipoVinculo: any) =>
                    setMotoristaData({
                      ...motoristaData,
                      motoristaTipoVinculo,
                      nrSeqMotoristaTipoVinculo:
                        motoristaTipoVinculo.nrSeqMotoristaTipoVinculo,
                    })
                  }
                  dataSourceTextProperty='noMotoristaTipoVinculo'
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
);

export default MotoristaPanel;
