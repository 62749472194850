import React, { useState, useRef, useEffect } from 'react';
import { ResponseStatus, BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import { NfsFatura } from 'core/models/VEN';
import {
  Button,
  GridView,
  Loader,
  Modal,
  Notification,
  Textbox,
  DatePicker,
  Autocomplete,
} from 'ui/components';

import { getCondicaoPagamentoAutoComplete } from 'core/services/FIN/condicaoPagamento';
import { generateParcels } from 'core/services/VEN/nfsFatura';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { saveNfsFromPocOrcamento, printNfs } from 'core/services/VEN/nfs';

const ModalFaturar = ({
  nfs,
  obsFinanceiro,
  show,
  onClose,
  transaction,
  onOpenReport,
}) => {
  const gridViewFaturamento = useRef(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [dataItemFatura, setDataItemFatura] = useState(new NfsFatura());
  const [data, setData] = useState({});

  useEffect(() => {
    setData({ ...nfs, obsFinanceiro });
    setMessage({ message: '' });
    if (gridViewFaturamento.current)
      gridViewFaturamento.current.setDataSource(nfs.faturas);
  }, [nfs]);

  const onPrint = async (nfsprint) => {
    const { value } = await printNfs(nfsprint.nrSeqNfs);

    onOpenReport(transaction.noTransacao, value);
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchCondicaoPagamento = async (e) => {
    const {
      status,
      message: msg,
      condicoesPagamento,
    } = await getCondicaoPagamentoAutoComplete({
      noCondicaoPagamento: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });
    if (msg) onSetMessage(status, msg);
    return condicoesPagamento;
  };

  const onSave = async () => {
    setLoading(true);
    const {
      status,
      message: msg,
      data: nfsprint,
    } = await saveNfsFromPocOrcamento(data);

    if (status === ResponseStatus.Success) {
      await onPrint(nfsprint);
      onClose();
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    setLoading(false);
  };

  const onGenerateParcels = async () => {
    setLoading(true);
    const { status, message: msg, value: faturas } = await generateParcels(
      data
    );
    if (status === ResponseStatus.Success) {
      gridViewFaturamento.current.setDataSource(faturas);
      setData({
        ...data,
        faturas,
        vlrLiquido: faturas.reduce((total, item) => total + item.vlrParcela, 0),
      });
    } else {
      onSetMessage(status, msg);
    }
    setLoading(false);
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgTitRec: true,
    });
    onSetMessage(status, msg);
    return formasPagamento;
  };

  const onClickAddFatura = async () => {
    if (
      dataItemFatura.nrSeqFormaPagamento <= 0 ||
      dataItemFatura.nrSeqFormaPagamento === undefined
    ) {
      onSetMessage(Theme.Danger, 'Necessário informar a forma de pagamento.');
    } else if (dataItemFatura.vlrParcela <= 0) {
      onSetMessage(Theme.Danger, 'Necessário informar o valor da parcela.');
    } else if (
      dataItemFatura.dtVencimento === undefined ||
      dataItemFatura.dtVencimento === null
    ) {
      onSetMessage(Theme.Danger, 'Necessário informar a data de vencimento.');
    } else {
      const dataFaturamento = gridViewFaturamento.current.getDataSource();
      dataItemFatura.status = GridView.EnumStatus.Inserir;
      dataFaturamento.push(dataItemFatura);

      if (gridViewFaturamento && gridViewFaturamento.current) {
        gridViewFaturamento.current.setDataSource(dataFaturamento);
        setData({ ...data, faturas: dataFaturamento });
        setDataItemFatura(new NfsFatura({ vlrParcela: '' }));
      }
    }
  };

  const onClickEditFatura = async (selectedValue, datasource) => {
    const dataFaturas = datasource.filter(
      (servico) => servico !== selectedValue
    );

    const dataFatura = datasource.filter(
      (servico) => servico === selectedValue
    );

    dataFatura[0].status = GridView.EnumStatus.Alterar;
    setDataItemFatura(dataFatura[0]);

    if (gridViewFaturamento && gridViewFaturamento.current)
      gridViewFaturamento.current.setDataSource(dataFaturas);
  };

  const onClickRemoveFatura = async (selectedValue, datasource) => {
    const dataFaturas = datasource.filter(
      (servico) => servico !== selectedValue
    );

    const dataFatura = datasource.filter(
      (servico) => servico === selectedValue
    );

    dataFatura[0].status = GridView.EnumStatus.Remover;
    dataFaturas.push(dataFatura[0]);

    if (gridViewFaturamento && gridViewFaturamento.current)
      gridViewFaturamento.current.setDataSource(dataFaturas);
  };

  const columnsFaturamento = [
    { key: 'nrSeqFatura', type: GridView.ColumnTypes.Checkbox, visible: false },
    { key: 'formaPagamento.noFormaPagamento', title: 'Forma Pagamento' },
    { key: 'vlrParcela', title: 'Valor', format: GridView.DataTypes.Decimal },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },

    {
      key: 'nrSeqFatura',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditFatura(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqFatura',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveFatura(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];
  return (
    <Modal
      show={show}
      size={BootstrapSizes.ExtraLarge}
      title='Gerar Nota Fiscal de Serviço'
      onClose={onClose}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col-6 mb-3'>
            <Autocomplete
              label='Condição Pagamento'
              searchDataSource={onSearchCondicaoPagamento}
              selectedItem={data?.condicaoPagamento}
              readOnly
              onSelectItem={(condicaoPagamento) => {
                setData({
                  ...data,
                  condicaoPagamento,
                  nrSeqCondicaoPagamento:
                    condicaoPagamento.nrSeqCondicaoPagamento,
                });
              }}
              dataSourceTextProperty='noCondicaoPagamento'
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-3 '>
            <Textbox
              label='Valor Bruto'
              text={data?.vlrBruto}
              mask={MaskTypes.DecimalCustom}
              onChangedValue={(vlrBruto) => {
                const { itens } = data;
                itens[0].vlrTotal = vlrBruto;
                itens[0].valorUnitario = vlrBruto;
                setData({
                  ...data,
                  vlrBruto,
                  itens,
                });
              }}
            />
          </div>

          <div className='row mt-3'>
            <div className='col-2'>
              <Textbox
                label='Valor'
                text={dataItemFatura.vlrParcela}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrParcela) =>
                  setDataItemFatura({ ...dataItemFatura, vlrParcela })
                }
              />
            </div>

            <div className='col-2'>
              <DatePicker
                label='Vencimento'
                text={dataItemFatura.dtVencimento}
                mask={MaskTypes.Date}
                onChange={(dtVencimento) =>
                  setDataItemFatura({
                    ...dataItemFatura,
                    dtVencimento,
                  })
                }
              />
            </div>

            <div className='col-5'>
              <Autocomplete
                label='Forma de Pagamento'
                searchDataSource={onSearchFormaPagamento}
                selectedItem={dataItemFatura.formaPagamento}
                onSelectItem={(formaPagamento) =>
                  setDataItemFatura({
                    ...dataItemFatura,
                    formaPagamento,
                    nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                  })
                }
                dataSourceTextProperty='noFormaPagamento'
              />
            </div>

            <div className='col-2 mt-3'>
              <Button
                outline
                theme={Theme.Success}
                template={Button.Templates.Button}
                text='Adicionar Fatura'
                onClick={onClickAddFatura}
              />
            </div>
          </div>

          <div className='col-2 mt-3'>
            <Button
              outline
              theme={Theme.Success}
              template={Button.Templates.Button}
              text='Gerar Títulos'
              onClick={onGenerateParcels}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridViewFaturamento}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnsFaturamento}
              showSelectSizes={false}
              showPagination={false}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-3 '>
            <Textbox
              label='Valor Líquido'
              text={data?.vlrLiquido}
              mask={MaskTypes.DecimalCustom}
              readOnly
            />
          </div>
          <div className='col-5'>
            <Textbox
              label='Observações Financeira'
              text={data?.obsFinanceiro}
              onChangedValue={(noObservacaoFinanceiro) => {
                data.obsFinanceiro = noObservacaoFinanceiro;
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          icon={['far', 'save']}
          text='Gerar Nota'
          theme={Theme.Success}
          onClick={onSave}
        />
        <Button
          icon='ban'
          text='Cancelar'
          theme={Theme.Danger}
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default ModalFaturar;
