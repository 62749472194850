import api from '../api';

const baseURL = `/EST/InventarioItem`;

export const saveInventarioItem = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const ZerarItensInventario = async (data) => {
  const response = await api.post(`${baseURL}/ZerarItensInventario`, data);
  const { status, message } = response.data ?? {};

  return { status, message };
};
