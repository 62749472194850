export default class ClienteLinhaCalculatedFields {
  constructor(jsonObject = {}) {
    this.nrSeqClienteLinha = jsonObject.nrSeqClienteLinha;
    this.noPessoa = jsonObject.noPessoa;
    this.nroFoneCompleto = jsonObject.nroFoneCompleto;
    this.noMarca = jsonObject.noMarca;
    this.noModelo = jsonObject.noModelo;
    this.codNoCentroCusto = jsonObject.codNoCentroCusto;
    this.nrImei = jsonObject.nrImei;
    this.noFormaPagamento = jsonObject.noFormaPagamento;
    this.noPlano = jsonObject.noPlano;
    this.noPacoteSms = jsonObject.noPacoteSms;
    this.noBonus = jsonObject.noBonus;
    this.dtAquisicao = jsonObject.dtAquisicao;
    this.dtRenovacao = jsonObject.dtRenovacao;
    this.flgSituacaoStr = jsonObject.flgSituacaoStr;
    this.noEncargosVinculados = jsonObject.noEncargosVinculados;
    this.noPlanoRateioEmpresa = jsonObject.noPlanoRateioEmpresa;
    this.noClienteConta = jsonObject.noClienteConta;
    this.dtCancelamento = jsonObject.dtCancelamento;
    this.noOperadora = jsonObject.noOperadora;

    this.noChipFlgStatus = jsonObject.noChipFlgStatus;
    this.noChipFlgSituacao = jsonObject.noChipFlgSituacao;
    this.noEmpresa = jsonObject.noEmpresa;
    this.nrIccid = jsonObject.nrIccid;
    this.nrSeqChip = jsonObject.nrSeqChip;
    this.cdChip = jsonObject.cdChip;
  }

  toJson = () => JSON.stringify(this);
}
