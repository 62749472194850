import React, { useState, useRef } from 'react';
import {
  Modal,
  Autocomplete,
  Textbox,
  GridView,
  Button,
  Notification,
  Loader,
} from 'ui/components';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';

import { getCamposCorrecaoCteAutoComplete } from 'core/services/FRO/camposCorrecaoCte';
import { getColetaEmbarqueDocumentoAutoComplete } from 'core/services/FRO/coletaEmbarque_Documento';
import { corrigirCte } from 'core/services/FRO/coletaEmbarque';

export default function ModalCorrigirCte({ show, cte, onClose }) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [data, setData] = useState({});
  const gridView = useRef();

  const onSend = (msg, status) => {
    onClose(msg, status);
  };

  const onSetMessage = (status, msg) => {
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
  };

  const onCorrigirCte = async () => {
    setLoading(true);

    const corrigirCteGrid = gridView.current.getDataSource();

    const { status, message: msg } = await corrigirCte({
      nrSeqColetaEmbarque: cte.nrSeqColetaEmbarque,
      validate: false,
      corrigirCteGrid,
    });

    if (status === ResponseStatus.Success) {
      onSend(msg, status);
    } else if (msg) {
      onSetMessage(status, msg);
    }

    setLoading(false);
  };

  const onClickDelete = (e, datasource) => {
    const dataSource = datasource.filter((item) => item !== e);

    if (gridView && gridView.current)
      gridView.current.setDataSource(dataSource);
  };

  const columns = [
    { key: 'campoCorrecao.nomeCampo', title: 'Campo' },
    { key: 'vlrCorrecao', title: 'Valor' },
    { key: 'documentoCorrecao.nrNotaFiscal', title: 'Documento' },
    {
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDelete(e, datasource),
      theme: Theme.Danger,
      icon: ['fas', 'times'],
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Excluir',
      tooltipDirection: 'bottom',
    },
  ];

  const onAutoCompleteCampoCorrecao = async (e) => {
    const {
      status,
      message: msg,
      data: campos,
    } = await getCamposCorrecaoCteAutoComplete({
      nomeCampo: e,
    });

    if (msg) onSetMessage(status, msg);

    return campos;
  };

  const onAutoCompleteDocumentoCorrecao = async (e) => {
    const {
      status,
      message: msg,
      data: documentos,
    } = await getColetaEmbarqueDocumentoAutoComplete({
      nrNotaFiscal: e,
      nrSeqColetaEmbarque: cte.nrSeqColetaEmbarque,
    });

    if (msg) onSetMessage(status, msg);

    return documentos;
  };

  const onAdiciona = () => {
    const datasource = gridView.current.getDataSource();

    if (!data.documentoCorrecao) data.documentoCorrecao = {};

    datasource.push(data);

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);

    setData({});
  };

  return (
    <Modal
      show={show}
      title={`Correção Embarque Nr. ${cte?.cdColetaEmbarque}`}
      onClose={() => onSend()}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='row mb-3'>
            <span>
              Selecione o campo que deseja corrigir, digite o valor de correção
              e adicione.
            </span>
          </div>
          <div className='row mb-3'>
            <div className='col-3'>
              <Autocomplete
                label='Campo Correção'
                searchDataSource={onAutoCompleteCampoCorrecao}
                selectedItem={data.campoCorrecao}
                onSelectItem={(campoCorrecao) =>
                  setData({ ...data, campoCorrecao })
                }
                dataSourceTextProperty='nomeCampo'
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='Valor correção'
                text={data.vlrCorrecao}
                onChangedValue={(vlrCorrecao) =>
                  setData({ ...data, vlrCorrecao })
                }
              />
            </div>
            {data?.campoCorrecao?.listaDocumento && (
              <div className='col-3'>
                <Autocomplete
                  label='Documento Correção'
                  searchDataSource={onAutoCompleteDocumentoCorrecao}
                  selectedItem={data.documentoCorrecao}
                  onSelectItem={(documentoCorrecao) =>
                    setData({ ...data, documentoCorrecao })
                  }
                  dataSourceTextProperty='nrNotaFiscal'
                />
              </div>
            )}
            <div className='col-2 mt-4'>
              <Button
                outline
                text='Adicionar'
                icon='plus'
                theme={Theme.Success}
                template={Button.Templates.Default}
                size={BootstrapSizes.Medium}
                onClick={() => onAdiciona()}
              />
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col'>
              <GridView
                ref={gridView}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columns}
                offlineData
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Confirmar'
          icon='check'
          theme={Theme.Success}
          template={Button.Templates.Default}
          size={BootstrapSizes.Medium}
          onClick={() => onCorrigirCte()}
        />
        <Button
          text='Sair'
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          size={BootstrapSizes.Medium}
          onClick={() => onSend()}
        />
      </Modal.Footer>
    </Modal>
  );
}
