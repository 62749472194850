import React from 'react';
import { Button } from 'ui/components';
// @ts-expect-error
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';
import {
  getCNPJInformation,
  getInformationSefaz,
} from 'core/services/SEG/pessoa';
import { getEmpresaTipoDocumentoEletronicoList } from 'core/services/SEG/empresaTipoDocumentoEletronico';

interface ISearchButtonProps {
  document: string;
  ufIE: string;
  formData: any;
  show: any;
  setShow: (object: any) => void;
  setFormData: (object: any) => void;
  onSetMessage: (status: ResponseStatus, msg: string) => void;
  setLoading: (e: boolean) => void;
}

export default function SearchInformationDocumentButton({
  document,
  ufIE,
  formData,
  show,
  setShow,
  setFormData,
  onSetMessage,
  setLoading,
}: ISearchButtonProps) {
  const tipoDoc = formData?.flgFisica ? 'CPF' : 'CNPJ';

  const canTheCompanyIssueNFe = async (): Promise<boolean> => {
    const {
      data: tipoDocumentoEletronico,
    } = await getEmpresaTipoDocumentoEletronicoList({
      nrSeqEmpresa: formData.nrSeqEmpresa,
      tipoDocumentoEletronico: 0,
    });

    return tipoDocumentoEletronico && tipoDocumentoEletronico.length > 0;
  };

  const onSearchSefazDocumentInformation = async (): Promise<any> => {
    if (!ufIE || ufIE === '') {
      return onSetMessage(
        ResponseStatus.Error,
        'Necessário preencher o campo UF da Inscrição Estadual.'
      );
    }

    const { value: resposeData, message, status } = await getInformationSefaz(
      ufIE,
      document.replace(/\D/g, '')
    );

    if (status === ResponseStatus.Error && message.includes('UF')) {
      return onSetMessage(ResponseStatus.Error, message);
    }

    if (resposeData && status === ResponseStatus.Success) {
      return { ...formData, ...resposeData, nrSeqPessoa: undefined };
    }

    return null;
  };

  const onSearchCNPJInformation = async (cnpj: string): Promise<any> => {
    const { data: resposeData, status } = await getCNPJInformation(cnpj);

    if (
      (!resposeData || Object.keys(resposeData).length === 0) &&
      status !== ResponseStatus.Success
    ) {
      onSetMessage(
        ResponseStatus.Error,
        `Não foi possível encontrar informações para o ${cnpj} indicado. Será necessário preencher cada campo manualmente.`
      );
    } else {
      setFormData({
        ...formData,
        ...resposeData,
        nrSeqPessoa: undefined,
        disableDoc: true,
        disableTipoPessoa: true,
      });

      setShow({ ...show, showDocumentSearchBtn: false });
    }
  };

  const onSearchInformation = async () => {
    const tipoDocumento = formData.flgFisica ? 'CPF' : 'CNPJ';

    if (!document || document === '') {
      onSetMessage(
        ResponseStatus.Error,
        `Necessário preencher o campo ${tipoDocumento} para efetuar a busca.`
      );
    } else {
      setLoading(true);

      // verifica se empresa pode emitir NFe
      const buscaSefaz = await canTheCompanyIssueNFe();

      let objPessoaInfos;

      if (!buscaSefaz && !formData.flgFisica) {
        await onSearchCNPJInformation(document);
      } else {
        objPessoaInfos = await onSearchSefazDocumentInformation();

        if (objPessoaInfos && Object.keys(objPessoaInfos).length > 0) {
          setFormData({
            ...formData,
            ...objPessoaInfos,
            nrSeqPessoa: undefined,
            disableDoc: true,
            disableTipoPessoa: true,
            disableUfIE: true,
          });
          setShow({ ...show, showDocumentSearchBtn: false });
        } else if (!objPessoaInfos && !formData.flgFisica) {
          await onSearchCNPJInformation(document);
        } else
          onSetMessage(
            ResponseStatus.Error,
            `Não foi possível encontrar informações para o ${tipoDocumento} indicado. Será necessário preencher cada campo manualmente.`
          );
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        outline
        theme={Theme.Success}
        text={`Buscar ${tipoDoc}`}
        onClick={onSearchInformation}
      />
    </div>
  );
}
