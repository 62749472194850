import qs from 'qs';
import Pagination from 'core/models/pagination';
import RecebimentoMassivo from 'core/models/FIN/recebimentoMassivo';
import api from '../api';

const baseURL = `/FIN/RecebimentoMassivo`;

export const getRecebimentoMassivoList = async (filters) => {
  const response = await api.get(`/$RecebimentoMassivo/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new RecebimentoMassivo(json)) ?? [],
  };
};

export const getRecebimentoMassivoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new RecebimentoMassivo(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getRecebimentoMassivo = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new RecebimentoMassivo(value) : {};
};

export const saveRecebimentoMassivo = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteRecebimentoMassivo = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printRecebimentoMassivo = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printRecebimentoMassivoDuasVias = async (filters) => {
  const response = await api.get(`${baseURL}/PrintDuasVias`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelRecebimentoMassivo = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllRecebimentoMassivo = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
