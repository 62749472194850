export default class TipoPagamento {
  constructor(jsonObject = {}) {
    this.nrSeqTipoPagamento = jsonObject.nrSeqTipoPagamento;
    this.flgJuro = jsonObject.flgJuro;
    this.flgMulta = jsonObject.flgMulta;
    this.flgSaldo = jsonObject.flgSaldo;
    this.noTipoPagamento = jsonObject.noTipoPagamento;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.cdTipoPagamento = jsonObject.cdTipoPagamento;
  }

  toJson = () => JSON.stringify(this);
}
