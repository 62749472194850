import React, { useState, useEffect } from 'react';

import {
  BootstrapSizes,
  MimeTypes,
  Theme,
  ResponseStatus,
} from 'ui/Helpers/utils';
import { Button, Loader, Modal, Notification, FileUpload } from 'ui/components';
import { importarArquivoAssociados } from 'core/services/ASS/associado';

export default function ModalImportacaoAssociados({ show, onClose }) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [importedFile, setImportedFile] = useState([]);

  useEffect(() => {
    setLoading(true);
    setImportedFile([]);
    setLoading(false);
  }, []);

  const onClickImportarAssociado = async () => {
    if (!importedFile) {
      setMessage({
        message: 'Não há arquivo selecionado para importação.',
        theme: Theme.Danger,
      });
    } else {
      setLoading(true);

      const { status, message: msg } = await importarArquivoAssociados(
        importedFile[0]
      );
      if (msg)
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });

      setLoading(false);

      if (status?.ResponseStatus && status.ResponseStatus.Success) {
        onClose();
      }
    }
  };

  return (
    <Modal
      show={show}
      title=' Importar Associados'
      onClose={() => {
        setImportedFile([]);
        setMessage(null);
        onClose();
      }}
      size={BootstrapSizes.Medium}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='row mb-3'>
          <div className='col-12'>
            <FileUpload
              files={importedFile}
              onChange={(files) => setImportedFile(files)}
              allowedMimeTypes={[
                MimeTypes.Types.Excel,
                MimeTypes.Types.Txt,
                MimeTypes.Types.PDF,
              ]}
            />
          </div>
        </div>
        <div className='row justify-content-around mt-3'>
          <div className='col-2 mb-3'>
            <Button
              outline
              theme={Theme.Success}
              template={Button.Templates.Button}
              text='Importar'
              icon='fa-solid fa-cloud-check'
              onClick={onClickImportarAssociado}
            />
          </div>
          <div className='col-2 mb-3'>
            <Button
              outline
              text='Cancelar'
              theme={Theme.Danger}
              icon='fas fa-times'
              onClick={() => {
                setImportedFile([]);
                setMessage(null);
                onClose();
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
