import { TipoChamada, GrupoTipoChamada } from 'core/models/TEL';

export default class ClienteLinhaOcorrencia {
  constructor(jsonObject = {}) {
    this.nrSeqClOcorrencia = jsonObject.nrSeqClOcorrencia;

    this.nrSeqClienteLinha = jsonObject.nrSeqClienteLinha;

    this.nrSeqTipoChamada = jsonObject.nrSeqTipoChamada;

    this.nrSeqGrupoTipoChamada = jsonObject.nrSeqGrupoTipoChamada;

    this.flgAquisicao = jsonObject.flgAquisicao;

    this.noAquisicaoStr = jsonObject.noAquisicaoStr;

    this.dtOcorrencia = jsonObject.dtOcorrencia;

    this.dtOcorrenciaFormat = jsonObject.dtOcorrenciaFormat;

    this.obs = jsonObject.obs;

    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;

    this.tipoChamada = new TipoChamada(jsonObject.tipoChamada ?? {});

    this.grupoTipoChamada = new GrupoTipoChamada(
      jsonObject.grupoTipoChamada ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
