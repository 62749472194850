import React, { useState } from 'react';
import { JustifyContent, Themes, generateID } from 'ui/Helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RenderCollapsable = ({ children, panelId, closed }) => {
  const [collapsed, setCollapsed] = useState(false);

  const body = React.Children.map(children, (child) => {
    // checking isValidElement is the safe way and avoids a typescript error too

    if (React.isValidElement(child)) {
      if (
        child.type.name === 'PanelBody' ||
        child.type.name === 'PanelFooter'
      ) {
        return React.cloneElement(child, {
          collapsed,
          panelId,
        });
      }
    }
    return null;
  });

  const header = React.Children.map(children, (child) => {
    if (child.type.name === 'PanelHeader') {
      return React.cloneElement(child, {
        collapsed,
        panelId,
        onClickCollapseButton: () => setCollapsed(!collapsed),
      });
    }

    return null;
  });

  const className = ['accordion-collapse collapse', 'show'];

  if (closed) {
    className.pop();
  }

  return (
    <>
      {header}
      <div
        id={`${panelId}-body`}
        className={className.join(' ')}
        aria-labelledby={`${panelId}-header`}
        data-bs-parent={`#${panelId}`}
      >
        <div className='accordion-body'>{body}</div>
      </div>
    </>
  );
};

const Panel = ({
  className = '',
  collapsable,
  children,
  closed = false,
  panelId = generateID('pnl'),
  visible,
}) => {
  const isVisible = visible ?? true;
  return (
    isVisible && (
      <div className={`panel accordion ${className}`.trim()} id={panelId}>
        <div className='accordion-item'>
          {collapsable
            ? RenderCollapsable({ children, panelId, closed })
            : children}
        </div>
      </div>
    )
  );
};

Panel.Header = function PanelHeader({
  title,
  fontSize = '',
  align = JustifyContent.Start,
  children,
  theme,
  collapsed,
  onClickCollapseButton,
  panelId,
  classSize,
  icon,
  iconIsPopover,
}) {
  const className = ['accordion-button'];
  if (Themes.includes(theme)) className.push(`btn-${theme}`);
  if (onClickCollapseButton) className.push('collapsable');
  if (!collapsed) className.push('opened');

  let size = 'col-auto display-8';

  if (classSize) size = `col-auto ${classSize}`;

  return (
    <div className='row'>
      <h2 className={`accordion-header ${align}`} id={`${panelId}-header`}>
        <div className='col-auto'>
          <button
            className={className.join(' ')}
            type='button'
            data-bs-toggle='collapse'
            data-bs-target={`#${panelId}-body`}
            aria-expanded='true'
            aria-controls={`${panelId}-body`}
            onClick={onClickCollapseButton}
            style={{ fontSize }}
          >
            <div className={`row ${align}`} style={{ width: '100%' }}>
              <div className='row'>
                {icon && (
                  <div className='col-auto' style={{ marginTop: '4px' }}>
                    <FontAwesomeIcon icon={icon} />
                  </div>
                )}

                {iconIsPopover && children && (
                  <div className='col-auto' style={{ marginTop: '4px' }}>
                    {children}
                  </div>
                )}

                {!iconIsPopover && children && (
                  <div className='col'>{children}</div>
                )}

                <div className={size} style={{ fontSize }}>
                  {title}
                </div>
              </div>
            </div>
          </button>
        </div>
      </h2>
    </div>
  );
};

Panel.Body = function PanelBody({ children }) {
  return <div className='panel-body'>{children}</div>;
};
Panel.Footer = function PanelFooter({ children }) {
  return <div className='panel-footer'>{children}</div>;
};

export default Panel;
