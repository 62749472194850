import TipoDespesa from './tipoDespesa';
import FormaPagamento from './formaPagamento';

export default class TipoDespesaAcerto {
  constructor(jsonObject = {}) {
    this.nrSeqTipoDespesaAcerto = jsonObject.nrSeqTipoDespesaAcerto;
    this.noTipoDespesaAcerto = jsonObject.noTipoDespesaAcerto;
    this.cdTipoDespesaAcerto = jsonObject.cdTipoDespesaAcerto;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqTipoDespesa = jsonObject.nrSeqTipoDespesa;
    this.flgAbastecimento = jsonObject.flgAbastecimento;
    this.flgPedagio = jsonObject.flgPedagio;
    this.flgComissao = jsonObject.flgComissao;
    this.flgAfetaResultado = jsonObject.flgAfetaResultado;
    this.nrSeqFormaPagamentoAvista = jsonObject.nrSeqFormaPagamentoAvista;
    this.tipoDespesa = new TipoDespesa(jsonObject.tipoDespesa ?? {});
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.tipoDespesaAcertoS = jsonObject.tipoDespesaAcertoS
      ? jsonObject.tipoDespesaAcertoS.map(
          (item) => new TipoDespesaAcerto(item ?? {})
        )
      : [];
  }

  toJson = () => JSON.stringify(this);
}
