import React, { useState, useEffect } from 'react';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { Button, Loader, Modal, Notification, Textbox } from 'ui/components';
import {
  getVeiculoMarcaAutoComplete,
  saveVeiculoMarca,
} from 'core/services/FRO/veiculoMarca';

export default function ModalCadastroVeiculoMarca({
  show,
  onClose,
  data,
  setData,
}) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataModal, setDataModal] = useState({});

  const onSend = (msg) => {
    onClose(msg);
  };

  useEffect(() => {
    setDataModal({});
  }, [show]);

  const onSearchVeiculoMarca = async (e) => {
    const response = await getVeiculoMarcaAutoComplete({
      noVeiculoMarca: e,
    });

    return response.data;
  };

  const save = async () => {
    setLoading(true);

    if (dataModal.noVeiculoMarca) {
      const { status, message: msg } = await saveVeiculoMarca(dataModal);

      if (status === ResponseStatus.Success) {
        const veiculoMarcas = await onSearchVeiculoMarca(
          dataModal.noVeiculoMarca
        );

        setData({
          ...data,
          veiculoMarca: veiculoMarcas[0],
          nrSeqVeiculoMarca: veiculoMarcas[0].nrSeqVeiculoMarca,
        });

        onSend();
      } else {
        setMessage({
          message: msg,
          theme: Theme.Danger,
        });
      }
    } else {
      setMessage({
        message: 'Preencha os campos obrigatórios',
        theme: Theme.Danger,
      });
    }

    setLoading(false);
  };

  return (
    <Modal
      show={show}
      title='Cadastrar Veículo Marca'
      onClose={() => onSend()}
      size={BootstrapSizes.Large}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col mb-3'>
            <Textbox
              required
              maxLength={60}
              label='Marca'
              text={dataModal.noVeiculoMarca}
              onChangedValue={(noVeiculoMarca) =>
                setDataModal({ ...dataModal, noVeiculoMarca })
              }
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Small}
          icon={['far', 'save']}
          theme={Theme.Success}
          template={Button.Templates.Default}
          onClick={save}
          text='Cadastrar'
        />
      </Modal.Footer>
    </Modal>
  );
}
