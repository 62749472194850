import React, { useRef, useState } from 'react';
import { ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getPessoaDocumentoPaginedEmployeeDocument,
  printDocumentoFuncionario,
} from 'core/services/SEG/pessoaDocumento';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  CSDSelPage,
  GridView,
  Textbox,
  Autocomplete,
  DatePicker,
  Switch,
} from 'ui/components';
import { getTipoDocumentoPessoaAutoComplete } from 'core/services/SEG';

const columns = [
  { key: 'nrSeqPessoaDocumento', type: GridView.ColumnTypes.Checkbox },
  { key: 'noPessoa', title: 'Pessoa' },
  { key: 'tipoDocumentoPessoa.noTipoDocumentoPessoa', title: 'Tipo Documento' },
  { key: 'nrDocumento', title: 'Documento' },
  {
    key: 'dtIniValidade',
    title: 'Início da Validade',
    format: GridView.DataTypes.Date,
  },
  {
    key: 'dtFimValidade',
    title: 'Fim da Validade',
    format: GridView.DataTypes.Date,
  },
];

export default function PessoaDocumento({
  transaction,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const {
      data,
      pagination,
    } = await getPessoaDocumentoPaginedEmployeeDocument(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchTipoDocumentoPessoa = async () => {
    const {
      status,
      message: msg,
      data: tipoDocumentoPessoa,
    } = await getTipoDocumentoPessoaAutoComplete();

    if (msg) onSetMessage(status, msg);
    return tipoDocumentoPessoa;
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printDocumentoFuncionario(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Relação de Documentos Funcionários'
      loading={loading}
      onSearch={() => search()}
      onPrint={onPrint}
      transaction={transaction}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <div className='row'>
        <div className='row mb-3'>
          <div className='col-5'>
            <Textbox
              label='Nome'
              text={filtros.noPessoa}
              onChangedValue={(noPessoa) => {
                setFiltros({ ...filtros, noPessoa });
              }}
            />
          </div>
          <div className='col-5'>
            <Autocomplete
              label='Tipo Documento'
              searchDataSource={onSearchTipoDocumentoPessoa}
              selectedItem={filtros.tipoDocumentoPessoa}
              onSelectItem={(tipoDocumentoPessoa) =>
                setFiltros({
                  ...filtros,
                  tipoDocumentoPessoa,
                  nrSeqTipoDocumentoPessoa:
                    tipoDocumentoPessoa.nrSeqTipoDocumentoPessoa,
                })
              }
              dataSourceTextProperty='noTipoDocumentoPessoa'
            />
          </div>
          <div className='col'>
            <Switch
              formControl
              label='Possuem Validade '
              checked={filtros.flgValidade}
              onChange={(flgValidade) =>
                setFiltros({ ...filtros, flgValidade })
              }
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-2'>
            <DatePicker
              label='Fim da Validade de'
              text={filtros.dtFimValidadeIni}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtFimValidadeIni) =>
                setFiltros({ ...filtros, dtFimValidadeIni })
              }
            />
          </div>
          <div className='col-2'>
            <DatePicker
              label='Fim da Validade até'
              text={filtros.dtFimValidadeFim}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtFimValidadeFim) =>
                setFiltros({ ...filtros, dtFimValidadeFim })
              }
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            enableExcelExport
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            transaction={transaction}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
