import qs from 'qs';
import Pagination from 'core/models/pagination';
import PocPoco from 'core/models/POC/pocPoco';
import StatusPoco from 'core/models/POC/statusPoco';
import api from '../api';

const baseURL = `/POC/PocPoco`;

export const getPocPocoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPoco(json)) ?? [],
  };
};

export const getPocPocoList = async (filters) => {
  const response = await api.get(`/$PocPoco/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPoco(json)) ?? [],
  };
};

export const getPocPocoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPoco(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocPoco = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocPoco(value) : {};
};

export const savePocPoco = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePocPoco = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPocPoco = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  if (response.data.value)
    return {
      message: response.data.message,
      relatorio: response.data.value,
    };

  return response.data;
};

export const excelPocPoco = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocPoco = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getStatusPocoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteStatusPoco`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    statuspoco: value?.map((json) => new StatusPoco(json)) ?? [],
  };
};
