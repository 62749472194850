import { ContaChamada } from 'core/models/TEL';
import qs from 'qs';
import api from '../api';

const base = 'TEL/ContaChamada';

export const getResumoCustosLinha = async (filters) => {
  const response = await api.get(`TEL/ContaChamada/ResumoCustosLinha`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    custos: value?.map((json) => new ContaChamada(json)) ?? [],
  };
};

export const getImprimirDetalhadoContaChamada = async (parameters) => {
  const response = await api.post(`${base}/ImpressaoDetalhada`, parameters);
  return response.data ?? {};
};

export const getDetalhado = async (filters) => {
  const response = await api.get(`TEL/ContaChamada/GetDetalhado`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    pagination,
    chamadas: value?.map((json) => new ContaChamada(json)) ?? [],
  };
};

export const getResumo = async (filters) => {
  const response = await api.get(`TEL/ContaChamada/GetResumo`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    pagination,
    chamadas: value?.map((json) => new ContaChamada(json)) ?? [],
  };
};

export const deleteContasChamadas = async (ids) => {
  const response = await api.delete(`TEL/ContaChamada/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const cancelDelete = async (ids) => {
  const response = await api.delete(`TEL/ContaChamada/CancelDelete`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getContaChamada = async (id) => {
  const response = await api.get(`TEL/ContaChamada`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ContaChamada(value) : {};
};

export const saveContaChamada = async (contaChamada) => {
  const response = await api.post(`TEL/ContaChamada`, contaChamada);
  return response.data ?? {};
};

export const printContaChamada = async (filters) => {
  const response = await api.get(`TEL/ContaChamada/Print`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });

  return response.data ?? {};
};

export const excelContaChamada = async (filters) => {
  const response = await api.get(`TEL/ContaChamada/ExcelPrint`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });

  return response.data ?? {};
};
