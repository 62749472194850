import qs from 'qs';
import Pagination from 'core/models/pagination';
import VeiculoTipo from 'core/models/FRO/veiculoTipo';
import TipoVeiculoMdfe from 'core/models/FRO/tipoVeiculoMdfe';
import api from '../api';

const baseURL = `/FRO/VeiculoTipo`;

export const getVeiculoTipoList = async (filters) => {
  const response = await api.get(`/VeiculoTipo/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new VeiculoTipo(json)) ?? [],
  };
};

export const getVeiculoTipoPaginated = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPaginated`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new VeiculoTipo(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getVeiculoTipoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new VeiculoTipo(json) ?? []),
  };
};

export const getTipoVeiculoMdfeAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteTipoVeiculoMdfe`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TipoVeiculoMdfe(json) ?? []),
  };
};

export const getVeiculoTipo = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new VeiculoTipo(value) : {};
};

export const saveVeiculoTipo = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteVeiculoTipo = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printVeiculoTipo = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelVeiculoTipo = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllVeiculoTipo = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
