import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getEmpresaTipoDocumentoEletronicoPagined,
  deleteAllEmpresaTipoDocumentoEletronico,
} from 'core/services/SEG/empresaTipoDocumentoEletronico';

import { CSDSelPage, GridView } from 'ui/components';

const columns = [
  { key: 'nrSeqEmpresa', type: GridView.ColumnTypes.Checkbox },
  {
    key: 'tipoDocumentoEletronico',
    title: 'Tipo',
    visible: false,
  },
  { key: 'noTipoDocumentoEletronico', title: 'Tipo' },
  { key: 'empresa.noPessoa', title: 'Empresa' },

  {
    key: 'noCertificado',
    title: 'Certificado',
  },
  {
    key: 'flgAmbienteProducao',
    title: 'Produção',
    format: GridView.DataTypes.Boolean,
  },
  { key: 'versao', title: 'Versao' },
];

export default function EmpresaTipoDocumentoEletronico({
  transaction,
  onOpenPage,
  isActive,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getEmpresaTipoDocumentoEletronicoPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const {
        data,
        pagination,
      } = await getEmpresaTipoDocumentoEletronicoPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'DocumentoEletronico/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqEmpresa = sourceRow.find((e) => e.key === 'nrSeqEmpresa');
    const tipoDocumentoEletronico = sourceRow.find(
      (e) => e.key === 'tipoDocumentoEletronico'
    );

    const objDocumentoEletronico = {
      nrSeqEmpresa: nrSeqEmpresa ? nrSeqEmpresa.value : null,
      tipoDocumentoEletronico: tipoDocumentoEletronico
        ? tipoDocumentoEletronico.value
        : null,
    };

    onOpenMaintenance(objDocumentoEletronico);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      let valuesToDelete = [];

      valuesToDelete = selecteds.map((row) => ({
        nrSeqEmpresa: row[0].value,
        tipoDocumentoEletronico: row[1].value,
      }));
      const {
        status,
        message: msg,
      } = await deleteAllEmpresaTipoDocumentoEletronico(valuesToDelete);

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de DocumentoEletronico'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
