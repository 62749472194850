import { Pessoa } from '../SEG';

export default class CancelaFaturamentoContaTelefone {
  constructor(jsonObject = {}) {
    this.dtCadastro = jsonObject.dtCadastro;
    this.dtVencimento = jsonObject.dtVencimento;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqCentroDeCusto = jsonObject.nrSeqCentroDeCusto;
    this.nrSeqTipoReceita = jsonObject.nrSeqTipoReceita;
    this.nrSeqClienteContaCab = jsonObject.nrSeqClienteContaCab;
    this.nrSeqContaCabecalho = jsonObject.nrSeqContaCabecalho;
    this.nrSeqContratoAgrupado = jsonObject.nrSeqContratoAgrupado;
    this.nrSeqTituloReceberFatura = jsonObject.nrSeqTituloReceberFatura;
    this.nrSeqFatura = jsonObject.nrSeqFatura;
    this.noPessoa = jsonObject.noPessoa;
    this.nrLinha = jsonObject.nrLinha;
    this.nrPrefixo = jsonObject.nrPrefixo;
    this.vlrFatura = jsonObject.vlrFatura;
    this.vlrTitulo = jsonObject.vlrTitulo;
    this.pessoa = new Pessoa(jsonObject.pessoa ?? {});
  }

  get linhaFormatada() {
    if (this.nrLinha) return `(${this.nrPrefixo}) ${this.nrLinha}`;
    return '';
  }

  toJson = () => JSON.stringify(this);
}
