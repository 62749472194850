import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Textbox, Capture } from 'ui/components';
import { getBanco, saveBanco, deleteBanco } from 'core/services/FIN/banco';

export default function BancoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getBanco(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveBanco(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteBanco(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Banco'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqBanco > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-lg-3'>
          <Capture
            cameraButtonVisible={false}
            defaultIcon={['far', 'image']}
            picture={data.imagem}
            onPictureLoad={(imagem) => setData({ ...data, imagem })}
          />
        </div>
        <div className='col-lg-9'>
          <div className='row'>
            <div className='col-lg-3 mb-3'>
              <Textbox
                required
                maxLength={3}
                label='Código'
                text={data.cdBanco}
                onChangedValue={(cdBanco) => setData({ ...data, cdBanco })}
              />
            </div>
            <div className='col-lg-2 mb-3'>
              <Textbox
                maxLength={2}
                label='Digito'
                text={data.cdBancoDig}
                required
                onChangedValue={(cdBancoDig) =>
                  setData({ ...data, cdBancoDig })
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-3 mb-3'>
              <Textbox
                label='Máscara de Agência'
                text={data.agenciaMascara}
                placeholder='ex: 123-4'
                maxLength={10}
                onChangedValue={(agenciaMascara) =>
                  setData({ ...data, agenciaMascara })
                }
              />
            </div>
            <div className='col-lg-3 mb-3'>
              <Textbox
                label='Máscara de Conta Corrente'
                text={data.contaMascara}
                placeholder='ex: 12345-6'
                maxLength={16}
                onChangedValue={(contaMascara) =>
                  setData({ ...data, contaMascara })
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-8 mb-3'>
              <Textbox
                required
                maxLength={60}
                label='Nome'
                text={data.noBanco}
                onChangedValue={(noBanco) => setData({ ...data, noBanco })}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-8 mb-3'>
              <Textbox
                maxLength={60}
                label='Pagina Web'
                text={data.paginaWeb}
                required
                onChangedValue={(paginaWeb) => setData({ ...data, paginaWeb })}
              />
            </div>
          </div>
        </div>
      </div>
    </CSDManutPage>
  );
}
