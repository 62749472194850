import React, { useRef, useState } from 'react';
import {
  getFretePrecoPagined,
  printFretePreco,
  deleteAllFretePreco,
  copyFretePreco,
} from 'core/services/FRO/fretePreco';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDSelPage,
  GridView,
  Panel,
  Autocomplete,
  Button,
} from 'ui/components';
import { getClienteAutoComplete } from 'core/services/FIN';
import { GridviewColumns, Message, Page } from 'ui/Helpers/interfaces';
import { ColumnTypes, ColumnDataTypes } from 'ui/Helpers/enums';
import { getFreteMercadoriaAutoComplete } from 'core/services/FRO/freteMercadoria';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
import { getCidadeAutoComplete } from 'core/services/SEG/cidade';

const columns: Array<GridviewColumns> = [
  { key: 'nrSeqFretePreco', type: ColumnTypes.Checkbox },
  { key: 'cliente.noPessoa', title: 'Pagador', sortKey: 'noPessoaCli' },
  { key: 'pessoa.noPessoa', title: 'Pessoa', sortKey: 'noPessoa' },
  {
    key: 'freteMercadoria.noFreteMercadoria',
    title: 'Mercadoria',
    sortKey: 'noFreteMercadoria',
  },
  {
    key: 'tipoEmbarque.noTipoEmbarque',
    title: 'Tipo Embarque',
    sortKey: 'noTipoEmbarque',
  },
  {
    key: 'estadoOri.noEstado',
    title: 'Estado Ori.',
    sortKey: 'noEstadoOri',
  },
  {
    key: 'estadoDest.noEstado',
    title: 'Estado Dest.',
    sortKey: 'noEstadoDest',
  },
  { key: 'cidadeOri.noCidade', title: 'Cidade Ori.', sortKey: 'noCidadeOri' },
  {
    key: 'cidadeDest.noCidade',
    title: 'Cidade Dest.',
    sortKey: 'noCidadeDest',
  },
  {
    key: 'freteTipoCalculo.noFreteTipoCalculo',
    title: 'Tipo Calculo',
    sortKey: 'noFreteTipoCalculo',
  },
  { key: 'dtInicio', title: 'Data Inicial', format: ColumnDataTypes.Date },
  { key: 'dtFim', title: 'Data Final', format: ColumnDataTypes.Date },
  { key: 'faixaIni', title: 'Faixa Inicial' },
  { key: 'faixaFim', title: 'Faixa Fim' },
  {
    key: 'vlrCalculado',
    title: 'Valor Calculado',
    format: ColumnDataTypes.Decimal,
  },
];

export default function FretePreco({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}: Page) {
  const gridView: any = useRef();

  const [filtros, setFiltros] = useState<Object>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const search = async (params: Object | null = null): Promise<void> => {
    setLoading(true);

    const { data, pagination } = await getFretePrecoPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onColumnSort = async (sortBy: string): Promise<void> => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page: number): Promise<void> => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onSearchCliente = async (e: string): Promise<Array<any>> => {
    const { clientes }: { clientes: Array<any> } = await getClienteAutoComplete(
      {
        noPessoa: e,
      }
    );

    return clientes;
  };

  const onSearchClientePag = async (e: string): Promise<Array<any>> => {
    const { clientes }: { clientes: Array<any> } = await getClienteAutoComplete(
      {
        noPessoa: e,
      }
    );

    return clientes;
  };

  const onSearchFreteMercadoria = async (e: string) => {
    const { data: freteMercadorias } = await getFreteMercadoriaAutoComplete({
      noFreteMercadoria: e,
    });
    return freteMercadorias;
  };

  const onOpenMaintenance = (registryKey: number | null = null): void => {
    onOpenPage(
      transaction.noTransacao,
      'FretePreco/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow: Array<any>): void => {
    const nrSeqFretePreco = sourceRow.find((e) => e.key === 'nrSeqFretePreco');

    onOpenMaintenance(nrSeqFretePreco.value);
  };

  const onPrint = async (): Promise<void> => {
    setLoading(true);

    const { value } = await printFretePreco(filtros);

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onSearchCidadeOrigem = async (e: any) => {
    const { cidades: cidadesOrigem } = await getCidadeAutoComplete({
      noCidade: e,
      nrSeqEstado: filtros?.nrSeqEstadoOri ?? null,
    });
    return cidadesOrigem;
  };
  const onSearchCidadeDestino = async (e: any) => {
    const { cidades: cidadesDestino } = await getCidadeAutoComplete({
      noCidade: e,
      nrSeqEstado: filtros?.nrSeqEstadoDest ?? null,
    });
    return cidadesDestino;
  };
  const onSearchEstadoOrigem = async (e: any) => {
    const { estados: estadosColeta } = await getEstadoAutoComplete({
      noEstado: e,
    });
    return estadosColeta;
  };
  const onSearchEstadoDestino = async (e: any) => {
    const { estados: estadosEntrega } = await getEstadoAutoComplete({
      noEstado: e,
    });
    return estadosEntrega;
  };

  const onDelete = async (): Promise<void> => {
    setLoading(true);

    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllFretePreco(
        selecteds.map((row: any) => row[0].value)
      );

      setLoading(false);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);

      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onCopy = async (): Promise<void> => {
    setLoading(true);

    if (
      filtros.nrSeqPessoaCopy === undefined ||
      filtros.nrSeqPessoaCopy === null
    ) {
      setLoading(false);

      setMessage({
        message: 'Selecione um pagador.',
        theme: Theme.Danger,
      });
      return;
    }
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await copyFretePreco(
        selecteds.map((row: any) => row[0].value),
        filtros.nrSeqPessoaCopy
      );

      setLoading(false);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);

      setMessage({
        message: 'Não há registros selecionados para a copia.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Frete Preço'
      loading={loading}
      transaction={transaction}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <div className='row mb-3'>
        {/* @ts-expect-error */}
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header title='Pesquisa' theme={Theme.Primary} />
          <div className='col'>
            <Panel.Body>
              <div className='row'>
                <div className='col-4'>
                  <Autocomplete
                    label='Pagador'
                    searchDataSource={onSearchCliente}
                    selectedItem={filtros.cliente}
                    onSelectItem={(cliente: any) => {
                      setFiltros({
                        ...filtros,
                        cliente,
                        nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                      });
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
                <div className='col-3 mb-3'>
                  <Autocomplete
                    label='Frete Mercadoria'
                    searchDataSource={onSearchFreteMercadoria}
                    selectedItem={filtros.freteMercadoria}
                    onSelectItem={(freteMercadoria: any) => {
                      setFiltros({
                        ...filtros,
                        freteMercadoria,
                        nrSeqFreteMercadoria:
                          freteMercadoria.nrSeqFreteMercadoria,
                      });
                    }}
                    dataSourceTextProperty='noFreteMercadoria'
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-3'>
                  <Autocomplete
                    label='Estado Origem'
                    searchDataSource={onSearchEstadoOrigem}
                    selectedItem={filtros.estadoOri}
                    onSelectItem={(estadoOri) =>
                      setFiltros({
                        ...filtros,
                        estadoOri,
                        nrSeqEstadoOri: estadoOri.nrSeqEstado,
                      })
                    }
                    dataSourceTextProperty='noEstado'
                  />
                </div>
                <div className='col-3'>
                  <Autocomplete
                    label='Estado Destino'
                    searchDataSource={onSearchEstadoDestino}
                    selectedItem={filtros.estadoDest}
                    onSelectItem={(estadoDest) =>
                      setFiltros({
                        ...filtros,
                        estadoDest,
                        nrSeqEstadoDest: estadoDest.nrSeqEstado,
                      })
                    }
                    dataSourceTextProperty='noEstado'
                  />
                </div>
                <div className='col-3'>
                  <Autocomplete
                    label='Cidade Origem'
                    searchDataSource={onSearchCidadeOrigem}
                    selectedItem={filtros.cidadeOri}
                    onSelectItem={(cidadeOri) =>
                      setFiltros({
                        ...filtros,
                        cidadeOri,
                        nrSeqCidadeOri: cidadeOri.nrSeqCidade,
                      })
                    }
                    dataSourceTextProperty='noCidade'
                  />
                </div>
                <div className='col-3'>
                  <Autocomplete
                    label='Cidade Destino'
                    searchDataSource={onSearchCidadeDestino}
                    selectedItem={filtros.cidadeDest}
                    onSelectItem={(cidadeDest) =>
                      setFiltros({
                        ...filtros,
                        cidadeDest,
                        nrSeqCidadeDest: cidadeDest.nrSeqCidade,
                      })
                    }
                    dataSourceTextProperty='noCidade'
                  />
                </div>
              </div>
            </Panel.Body>
          </div>
        </Panel>
      </div>
      <div className='row mb-3'>
        {/* @ts-expect-error */}
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header title='Pesquisa' theme={Theme.Primary} />
          <div className='col'>
            <Panel.Body>
              <div className='row'>
                <div className='col-4'>
                  <Autocomplete
                    label='Pagador'
                    searchDataSource={onSearchClientePag}
                    selectedItem={filtros.cliente}
                    onSelectItem={(cliente: any) => {
                      setFiltros({
                        ...filtros,
                        nrSeqPessoaCopy: cliente.nrSeqPessoaCli,
                      });
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Button
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Success}
                    template={Button.Templates.Quick}
                    onClick={onCopy}
                    text='Copiar'
                  />
                </div>
              </div>
            </Panel.Body>
          </div>
        </Panel>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            transaction={transaction}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            enableExcelExport
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
