export default class PlanoDeConta {
  constructor(jsonObject = {}) {
    this.nrSeqPlanoDeConta = jsonObject.nrSeqPlanoDeConta;
    this.cdPlanoDeConta = jsonObject.cdPlanoDeConta;
    this.flgAtivo = jsonObject.flgAtivo;
    this.nrSeqPlanodecontapai = jsonObject.nrSeqPlanodecontapai;
    this.noPlanoDeContaPai = jsonObject.noPlanoDeContaPai;
    this.flgSintetico = jsonObject.flgSintetico;
    this.noPlanoDeConta = jsonObject.noPlanoDeConta;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqCentroDeCusto = jsonObject.nrSeqCentroDeCusto;
    this.noCentroDeCusto = jsonObject.noCentroDeCusto;
    this.cdCaminhoCentroDeCusto = jsonObject.cdCaminhoCentroDeCusto;
    this.centroDeCusto = jsonObject.centroDeCusto;
    this.cdResumo = jsonObject.cdResumo;
    this.nrValorPercentual = jsonObject.nrValorPercentual;
    this.flgTipoValor = jsonObject.flgTipoValor;
    this.noCaminhoCompleto = jsonObject.noCaminhoCompleto;
    this.cdCaminhoCompleto = jsonObject.cdCaminhoCompleto;
  }

  toJson = () => JSON.stringify(this);
}
