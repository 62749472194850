import qs from 'qs';
import Pagination from 'core/models/pagination';
import OrdemCompra from 'core/models/COP/ordemCompra';
import api from '../api';

const baseURL = `/COP/OrdemCompra`;

export const getOrdemCompraList = async (filters) => {
  const response = await api.get(`/$OrdemCompra/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new OrdemCompra(json)) ?? [],
  };
};

export const getOrdemCompraPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new OrdemCompra(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getOrdemCompra = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new OrdemCompra(value) : {};
};

export const saveOrdemCompra = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteOrdemCompra = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printOrdemCompra = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printOrdemCompraAgrupado = async (ids) => {
  const response = await api.get(`${baseURL}/PrintAgrupado`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const printOrdemCompraDetalhado = async (ids) => {
  const response = await api.get(`${baseURL}/PrintOrdemCompraDetalhado`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const excelOrdemCompra = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllOrdemCompra = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const printDetalhado = async (filters) => {
  const response = await api.get(`${baseURL}/PrintDetalhado`, {
    params: filters,
  });
  return response.data ?? {};
};
