import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import { CSDManutPage, Textbox, GridView, Button } from 'ui/components';
import {
  getFornecedorAtividade,
  saveFornecedorAtividadeList,
  deleteFornecedorAtividade,
  getFornecedorAtividadeList,
} from 'core/services/FIN/fornecedorAtividade';

export default function FornecedorAtividadeItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const gridView = useRef();
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [listFornecedorAtividade, setListFornecedorAtividade] = useState([]);

  const onClickEdit = (e, datasource) => {
    const objFornecedorAtividade = datasource.find((item) => item === e);

    if (objFornecedorAtividade.status !== GridView.EnumStatus.Inserir) {
      objFornecedorAtividade.status = GridView.EnumStatus.Alterar;
    }

    setData({ ...objFornecedorAtividade });

    const listaFornecedorAtividade = datasource.filter((item) => item !== e);

    if (gridView && gridView.current) {
      gridView.current.setDataSource(listaFornecedorAtividade);
    }
    setListFornecedorAtividade(listaFornecedorAtividade);
  };

  const columns = [
    {
      key: 'nrSeqFornecedorAtividade',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'cdFornecedorAtividade', title: 'Codigo' },
    { key: 'noFornecedorAtividade', title: 'Atividade' },
    {
      key: 'nrSeqFornecedorAtividade',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEdit(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
      title: 'Editar',
    },
  ];

  const onNew = () => {
    setData({});
    setListFornecedorAtividade([]);
    if (gridView && gridView.current) {
      gridView.current.setDataSource([]);
    }
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const value = await getFornecedorAtividade(registryKey);
      const fornecedorAtividades =
        listFornecedorAtividade.length > 0 ? listFornecedorAtividade : [];

      fornecedorAtividades.push(value);

      if (gridView && gridView.current) {
        gridView.current.setDataSource(fornecedorAtividades);
      }
      setData({});
      setListFornecedorAtividade(fornecedorAtividades);
      setLoading(false);
    } else {
      onNew();
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveFornecedorAtividadeList(
      listFornecedorAtividade
    );

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      onNew();
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };
  const adicionaFornecedorAtividade = () => {
    const fornecedorAtividades =
      listFornecedorAtividade.length > 0 ? listFornecedorAtividade : [];

    const fornecedorAtividadeStatus =
      data.status === undefined ? 'Inserir' : 'Alterar';

    const novaFornecedorAtividade = {
      ...data,
      status: fornecedorAtividadeStatus,
    };

    fornecedorAtividades.push(novaFornecedorAtividade);

    if (gridView && gridView.current) {
      gridView.current.setDataSource(fornecedorAtividades);
    }
    setData({});
    setListFornecedorAtividade(fornecedorAtividades);
  };

  const search = async (params = null) => {
    setLoading(true);

    const { value } = await getFornecedorAtividadeList(params ?? data);

    if (gridView && gridView.current) gridView.current.setDataSource(value);

    setLoading(false);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...data, totalByPage };
    setData(filters);
    await search(filters);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteFornecedorAtividade(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de FornecedorAtividade'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      onDelete={data?.nrSeqFornecedorAtividade > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-2 mb-3'>
          <Textbox
            maxLength={20}
            label='Codigo'
            text={data.cdFornecedorAtividade}
            onChangedValue={(cdFornecedorAtividade) =>
              setData({ ...data, cdFornecedorAtividade })
            }
          />
        </div>
        <div className='col-5 mb-3'>
          <Textbox
            maxLength={80}
            label='Atividade Fornecedor'
            text={data.noFornecedorAtividade}
            onChangedValue={(noFornecedorAtividade) =>
              setData({ ...data, noFornecedorAtividade })
            }
          />
        </div>
        <div className='col-2 mt-4 mb-3 d-flex align-itens-end'>
          <Button
            outline
            icon='plus'
            size={BootstrapSizes.Medium}
            theme={Theme.Primary}
            template={Button.Templates.Default}
            onClick={adicionaFornecedorAtividade}
            text='Adicionar'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onPageSizeChange={onPageSizeChange}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
