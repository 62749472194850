import React, { useState, useEffect } from 'react';

import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';

import {
  Button,
  Loader,
  Modal,
  FontAwesomeIcon,
  RadioButton,
  Notification,
} from 'ui/components';

import {
  ativaBloquearDados,
  ativaBloquearVoz,
  ativaBloquearSMS,
} from 'core/services/TEL';

const statusAtivarBloquearDados = [
  { text: 'Ativar', value: 'Ativado' },
  { text: 'Bloquear', value: 'Bloqueado' },
];
const statusAtivarBloquearVoz = [
  { text: 'Ativar', value: 'Ativado' },
  { text: 'Bloquear', value: 'Bloqueado' },
];
const statusAtivarBloquearSMS = [
  { text: 'Ativar', value: 'Ativado' },
  { text: 'Bloquear', value: 'Bloqueado' },
];

const ModalAtivarBloquearServicos = (props) => {
  const [loading, setLoading] = useState(false);
  const {
    ativarBloquearServicos: { linha, linhaFormatada },
  } = props;

  const [message, setMessage] = useState(null);
  const [flgAtivaBloqueia, setFlgAtivaBloqueia] = useState({
    flgAtivaBloqueiaDados: '',
    flgAtivaBloqueiaVoz: '',
    flgAtivaBloqueiaSMS: '',
    flgEnviado: false,
    linha: 0,
  });

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    else setMessage(null);
  };

  const AtivarBloquearDados = async (e) => {
    const dados = {
      Linha: e,
      Acao: flgAtivaBloqueia.flgAtivaBloqueiaDados,
    };
    return ativaBloquearDados(dados);
  };

  const AtivarBloquearSMS = async (e) => {
    const sms = {
      Linha: e,
      Acao: flgAtivaBloqueia.flgAtivaBloqueiaSMS,
    };
    return ativaBloquearSMS(sms);
  };

  const AtivarBloquearVoz = async (e) => {
    const voz = {
      Linha: e,
      Acao: flgAtivaBloqueia.flgAtivaBloqueiaVoz,
    };
    return ativaBloquearVoz(voz);
  };

  const onCancelar = async () => {
    setFlgAtivaBloqueia({ ...flgAtivaBloqueia, flgEnviado: false });
    props.onClose();
  };

  const onAtivarBloquear = async () => {
    setLoading(false);
    let retornoDados = 0;
    let retornoVoz = 0;
    let retornoSMS = 0;

    props.linhasServicos.forEach(async (element) => {
      if (
        flgAtivaBloqueia.flgAtivaBloqueiaDados &&
        flgAtivaBloqueia.flgAtivaBloqueiaDados !== ''
      )
        retornoDados = await AtivarBloquearDados(element.linha);
      if (
        flgAtivaBloqueia.flgAtivaBloqueiaVoz &&
        flgAtivaBloqueia.flgAtivaBloqueiaVoz !== ''
      )
        retornoVoz = await AtivarBloquearVoz(element.linha);
      if (
        flgAtivaBloqueia.flgAtivaBloqueiaSMS &&
        flgAtivaBloqueia.flgAtivaBloqueiaSMS !== ''
      )
        retornoSMS = await AtivarBloquearSMS(element.linha);
    });

    if (
      retornoDados.status > 0 ||
      retornoVoz.status > 0 ||
      retornoSMS.status > 0
    ) {
      onSetMessage(ResponseStatus.Error, 'Falha ao Ativar / Bloquear Linha');
    }
    onCancelar();
    setLoading(false);
  };

  const { show, onClose } = props;
  useEffect(() => {
    if (linha) {
      setLoading(false);
      setFlgAtivaBloqueia({
        ...flgAtivaBloqueia,
        flgAtivaBloqueiaDados: '',
        flgAtivaBloqueiaVoz: '',
        flgAtivaBloqueiaSMS: '',
      });
    } else if (props.linhasServicos) {
      setFlgAtivaBloqueia({
        ...flgAtivaBloqueia,
        flgAtivaBloqueiaDados: '',
        flgAtivaBloqueiaVoz: '',
        flgAtivaBloqueiaSMS: '',
      });
    }
  }, [show]);

  return (
    <Modal
      show={show}
      size={BootstrapSizes.Medium}
      title='Ativar / Bloquear Serviços'
      onClose={onClose}
    >
      <Modal.Body className='bg-light'>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.messageTheme}
              onClose={() => setMessage(undefined)}
            />
          </div>
        )}
        <div className='row mb-8'>
          <div className='row mb-8'>
            <div className='col-auto d-flex align-items-center'>
              <div className='widget-square bg-gradient-primary text-white p-3 rounded-2'>
                <FontAwesomeIcon className='fs-1' icon='phone' />
              </div>
            </div>
            <div className='col d-flex align-items-center'>
              <div className='fw-bold text-primary'>
                <span className='display-7 fw-bold'>{linhaFormatada}</span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-auto'>
              <RadioButton
                outline
                label='Dados'
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                buttons={statusAtivarBloquearDados}
                onChange={(flgAtivaBloqueiaDados) =>
                  setFlgAtivaBloqueia({
                    ...flgAtivaBloqueia,
                    flgAtivaBloqueiaDados,
                  })
                }
              />
            </div>

            <div className='col-auto'>
              <RadioButton
                outline
                label='Voz'
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                buttons={statusAtivarBloquearVoz}
                onChange={(flgAtivaBloqueiaVoz) =>
                  setFlgAtivaBloqueia({
                    ...flgAtivaBloqueia,
                    flgAtivaBloqueiaVoz,
                  })
                }
              />
            </div>
            <div className='col-auto'>
              <RadioButton
                outline
                label='SMS'
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                buttons={statusAtivarBloquearSMS}
                onChange={(flgAtivaBloqueiaSMS) =>
                  setFlgAtivaBloqueia({
                    ...flgAtivaBloqueia,
                    flgAtivaBloqueiaSMS,
                  })
                }
              />
            </div>
          </div>
        </div>
        <Loader loading={loading} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          icon='share'
          text='Enviar'
          theme={Theme.Success}
          onClick={onAtivarBloquear}
        />
        <Button
          icon='ban'
          text='Cancelar'
          theme={Theme.Danger}
          onClick={onCancelar}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAtivarBloquearServicos;
