export default class PocPocoCimentacao {
  constructor(jsonObject = {}) {
    this.nrSeqPocoCimentacao = jsonObject.nrSeqPocoCimentacao;
    this.nrSeqPocPoco = jsonObject.nrSeqPocPoco;
    this.nrInicio = jsonObject.nrInicio;
    this.nrFim = jsonObject.nrFim;
    this.nrEaInt = jsonObject.nrEaInt;
    this.nrEaExt = jsonObject.nrEaExt;
    this.totalCimentacao = jsonObject.totalCimentacao;

    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
