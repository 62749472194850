import React, { useState, useEffect } from 'react';
import { Loader, Modal } from 'ui/components';
import { getQRCode } from 'core/services/SEG/whatsapp';

export default function ModalQRCode({ show, onClose }) {
  const [state, setState] = useState({ loading: true });
  useEffect(() => {
    const loadQRCode = async () => {
      const { url } = await getQRCode();
      setState({ url, loading: false });
    };

    if (show) loadQRCode();
    else setState({ loading: true });
  }, [show]);

  return (
    <Modal show={show} title='Conexão com WhatsApp' onClose={onClose}>
      <Modal.Body className='text-center'>
        <Loader loading={state.loading} />
        <img
          src={state.url}
          className='img-thumbnail'
          alt='QR Code para conexão com Whatsapp'
        />
      </Modal.Body>
    </Modal>
  );
}
