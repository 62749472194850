import qs from 'qs';
import ChamadoClassificacao from 'core/models/HELP/chamadoClassificacao';
import Pagination from 'core/models/pagination';
import api from '../api';

const baseURL = '/HELP/ChamadoClassificacao';

export const getChamadoClassificacaoPagined = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });

  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value.map((json: any) => new ChamadoClassificacao(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getChamadoClassificacao = async (id: number) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });

  const { value } = response.data ?? {};

  return value ? new ChamadoClassificacao(value) : {};
};

export const saveChamadoClassificacao = async (data: any) => {
  const response = await api.post(`${baseURL}/`, data);

  return response.data ?? {};
};

export const deleteChamadoClassificacao = async (id: number) => {
  const response = await api.delete(`${baseURL}/`, {
    params: { id },
  });

  return response.data ?? {};
};

export const deleteAllChamadoClassificacao = async (ids: number[]) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });

  return response.data ?? {};
};

export const getChamadoClassificacaoAutoComplete = async () => {
  const response = await api.get(`${baseURL}/AutoComplete`);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    situacoesChamados:
      value?.map((json: any) => new ChamadoClassificacao(json)) ?? [],
  };
};
