import { CondicaoPagamento } from '../FIN';
import { Usuario } from '../SEG';
import Oficina from './oficina';
import Veiculo from './veiculo';

export default class ManutencaoVeiculoOS {
  constructor(jsonObject = {}) {
    this.nrSeqManutencaoVeiculoOS = jsonObject.nrSeqManutencaoVeiculoOS;
    this.cdManutencaoVeiculoOS = jsonObject.cdManutencaoVeiculoOS;
    this.nrOdometro = jsonObject.nrOdometro;
    this.dtPrevistaRetorno = jsonObject.dtPrevistaRetorno;
    this.dtSaida = jsonObject.dtSaida;
    this.dtCad = jsonObject.dtCad;
    this.dtAlteracao = jsonObject.dtAlteracao;
    this.dtAbertura = jsonObject.dtAbertura;
    this.dtFechamento = jsonObject.dtFechamento;
    this.vlrFechamentoOS = jsonObject.vlrFechamentoOS;
    this.obsOS = jsonObject.obsOS;
    this.vlrOs = jsonObject.vlrOs;
    this.vlrDeslocamento = jsonObject.vlrDeslocamento;
    this.flgConcluido = jsonObject.flgConcluido;
    this.status = jsonObject.status;
    this.cdManutencaoVeiculoOSDtAbertura =
      jsonObject.cdManutencaoVeiculoOSDtAbertura;
    // FK
    this.nrSeqManutencaoVeiculo = jsonObject.nrSeqManutencaoVeiculo;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.nrSeqCondicaoPagamento = jsonObject.nrSeqCondicaoPagamento;
    this.nrSeqPessoaOfi = jsonObject.nrSeqPessoaOfi;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.nrSeqUsuarioAlt = jsonObject.nrSeqUsuarioAlt;
    this.nrSeqUsuarioAut = jsonObject.nrSeqUsuarioAut;
    this.nrSeqUsuarioSol = jsonObject.nrSeqUsuarioSol;
    // Instancias/Objetos de FK
    // this.manutencaoVeiculo = new ManutencaoVeiculo(
    //   jsonObject.manutencaoVeiculo ?? {}
    // );
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    this.condicaoPagamento = new CondicaoPagamento(
      jsonObject.condicaoPagamento ?? {}
    );
    this.pessoaOfi = new Oficina(jsonObject.pessoaOfi ?? {});
    this.usuarioCad = new Usuario(jsonObject.usuarioCad ?? {});
    this.usuarioAlt = new Usuario(jsonObject.usuarioAlt ?? {});
    this.usuarioAut = new Usuario(jsonObject.usuarioAut ?? {});
    this.usuarioSol = new Usuario(jsonObject.usuarioSol ?? {});
    // Filtros de Periodo
    this.dtAberturaInicio = new Date();
    this.dtAberturaFim = new Date();
    this.dtCadastroInicio = new Date();
    this.dtCadastroFim = new Date();
    this.dtAlteracaoInicio = new Date();
    this.dtAlteracaoFim = new Date();
    this.dtFechamentoInicio = new Date();
    this.dtFechamentoFim = new Date();
    this.dtSaidaInicio = new Date();
    this.dtSaidaFim = new Date();
    this.dtPrevistaRetornoInicio = new Date();
    this.dtPrevistaRetornoFim = new Date();
  }

  toJson = () => JSON.stringify(this);
}
