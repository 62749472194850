import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  DatePicker,
  Switch,
  Button,
  GridView,
  Panel,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getInventario,
  saveInventario,
  deleteInventario,
  printInventario,
  efetivarInventario,
  excelInventario,
  printInventarioDiferenca,
} from 'core/services/EST/inventario';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import { getAlmoxarifadoLocalAutoCompleteComCaminho } from 'core/services/EST/almoxarifadoLocal';
import {
  getProdutosForInventario,
  getProdutosPaginatedForInventario,
} from 'core/services/EST/produto';
import { getAlmoxarifadoAutoComplete } from 'core/services/EST/almoxarifado';
import { getProdutoGrupoAutoComplete } from 'core/services/EST/produtoGrupo';
import { getProdutoSubGrupoAutoComplete } from 'core/services/EST/produtoSubGrupo';

export default function InventarioItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  onOpenReport,
}) {
  const formSubmit = useRef();
  const gridViewProduto = useRef();
  const gridViewInventario = useRef();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [showGridProduto, setShowGridProduto] = useState(false);
  const [showGridInventario, setShowGridInventario] = useState(false);
  const [itensInventario, setItensInventario] = useState([]);
  const [inventarioFilter, setInventarioFilter] = useState({});

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onNew = () => {
    setLoading(true);

    setData({
      flgAcumulaLancamento: true,
      flgBoqItemNaoPrevisto: true,
      flgExibeCusto: true,
      flgInventarioCompleto: true,
      flgExibirEstoque: true,
      flgGerarItensComSaldo: true,
      flgGerarItensSemSaldo: false,
      flgAtivo: true,
      qtdeContagem: 0,
      totalByPage: 10,
    });

    if (gridViewProduto && gridViewProduto.current) {
      gridViewProduto.current.setDataSource([]);
      setShowGridProduto(false);
    }
    if (gridViewInventario && gridViewInventario.current) {
      gridViewInventario.current.setDataSource([]);
      setShowGridInventario(false);
    }

    setInventarioFilter({});
    setItensInventario([]);

    setLoading(false);
  };

  const exibirColunaSaldo = () => {
    if (!data.flgExibirEstoque) {
      const gridColumns = gridViewInventario?.current?.getColumns();

      const saldoIndex = gridColumns.findIndex((e) => e.key === 'saldo');

      gridColumns[saldoIndex].visible = false;
    } else {
      const gridColumns = gridViewInventario?.current?.getColumns();

      const saldoIndex = gridColumns.findIndex((e) => e.key === 'saldo');

      gridColumns[saldoIndex].visible = true;
    }
  };

  const codigoItemGenerator = (list) => {
    const indexedList = list.map((item, index) => ({
      ...item,
      codigoItem: index + 1,
    }));

    return indexedList;
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      setShowGridInventario(true);

      if (gridViewProduto && gridViewProduto.current) {
        gridViewProduto.current.setDataSource([]);

        setShowGridProduto(false);
      }

      const inventory = await getInventario(registryKey);

      const indexedList = codigoItemGenerator(inventory.inventarioItens);

      if (gridViewInventario && gridViewInventario.current) {
        gridViewInventario.current.setDataSource(indexedList);

        exibirColunaSaldo();

        if (inventory.dtEfetiva != null) {
          const columns = gridViewInventario.current.getColumns();

          const excluirIndex = columns.findIndex(
            (e) => e.key === 'nrSequencia'
          );

          columns[excluirIndex].visible = false;
        }
      }

      setData({ ...inventory, totalByPage: 10, flgGerarItensComSaldo: true });

      setItensInventario(inventory.inventarioItens);

      setLoading(false);
    } else {
      onNew();
    }

    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const save = async () => {
    setLoading(true);

    if (data.dtInicio && data.dtFim) {
      const { status, message: msg } = await saveInventario(data);

      if (status === ResponseStatus.Success) formSubmit.current.reset();

      if (status === ResponseStatus.Success) {
        onNew();
      }

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    } else {
      onSetMessage(false, 'Selecione as datas de inicio e fim');
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteInventario(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onPrint = async () => {
    setLoading(true);

    let list = [];

    if (gridViewInventario && gridViewInventario.current) {
      list = gridViewInventario.current.getDataSource();
    }

    const { value } = await printInventario({
      ...data,
      inventarioItens: list,
    });

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onExcel = async () => {
    setLoading(true);

    let list = [];

    if (gridViewInventario && gridViewInventario.current) {
      list = gridViewInventario.current.getDataSource();
    }

    const { value } = await excelInventario({
      ...data,
      inventarioItens: list,
    });

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;

      const downloadLink = document.createElement('a');

      downloadLink.href = linkSource;

      downloadLink.download = `Inventario.xls`;

      downloadLink.click();
    }

    setLoading(false);
  };

  const onPrintDiferenca = async () => {
    setLoading(true);

    let list = [];

    if (gridViewInventario && gridViewInventario.current) {
      list = gridViewInventario.current.getDataSource();
    }

    const { value } = await printInventarioDiferenca({
      ...data,
      inventarioItens: list,
    });

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onSearchAlmoxarifado = async (e) => {
    const {
      status,
      message: msg,
      data: almoxarifados,
    } = await getAlmoxarifadoAutoComplete({
      noAlmoxarifado: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifados;
  };

  const onSearchAlmoxarifadoLocal = async (e) => {
    const {
      status,
      message: msg,
      almoxarifados,
    } = await getAlmoxarifadoLocalAutoCompleteComCaminho({
      noAlmoxarifadoLocal: e,
      nrSeqAlmoxarifado: data.nrSeqAlmoxarifado,
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifados;
  };

  const onSearchProdutoGrupo = async (e) => {
    const {
      status,
      message: msg,
      data: grupos,
    } = await getProdutoGrupoAutoComplete({ noProdutoGrupo: e });

    if (msg) onSetMessage(status, msg);

    return grupos;
  };

  const onSearchProdutoSubGrupo = async (e) => {
    const {
      status,
      message: msg,
      data: subGrupos,
    } = await getProdutoSubGrupoAutoComplete({
      noProdutoSubGrupo: e,
      nrSeqProdutoGrupo: data.nrSeqProdutoGrupo,
    });

    if (msg) onSetMessage(status, msg);

    return subGrupos;
  };

  const onSearchProdutos = async (params = null) => {
    setLoading(true);

    if (data.nrSeqAlmoxarifadoLocal != null) {
      const { produtos, pagination } = await getProdutosPaginatedForInventario(
        params ?? {
          noProduto: data.noProduto,
          nrSeqAlmoxarifado: data.nrSeqAlmoxarifado,
          nrSeqAlmoxarifadoLocal: data.nrSeqAlmoxarifadoLocal,
          totalByPage: data.totalByPage,
          nrSeqProdutoGrupo: data.nrSeqProdutoGrupo,
          nrSeqProdutoSubGrupo: data.nrSeqProdutoSubGrupo,
          flgAtivo: data.flgAtivo,
          flgGerarItensComSaldo: data.flgGerarItensComSaldo,
        }
      );

      if (produtos.length > 0) {
        if (!showGridProduto) {
          setShowGridProduto(true);
        }

        if (gridViewProduto && gridViewProduto.current) {
          gridViewProduto.current.setDataSource(produtos, pagination);
        }
      } else {
        if (gridViewProduto && gridViewProduto.current) {
          gridViewProduto.current.setDataSource([]);
        }

        onSetMessage(false, 'Nenhum produto encontrado ');
      }
    } else {
      onSetMessage(false, 'Almoxarifado Local Não Informado');
    }

    setLoading(false);
  };

  const onClickAddAllProdutos = async () => {
    setLoading(true);

    const { produtos } = await getProdutosForInventario({
      noProduto: data.noProduto,
      nrSeqAlmoxarifado: data.nrSeqAlmoxarifado,
      nrSeqAlmoxarifadoLocal: data.nrSeqAlmoxarifadoLocal,
      nrSeqProdutoGrupo: data.nrSeqProdutoGrupo,
      nrSeqProdutoSubGrupo: data.nrSeqProdutoSubGrupo,
      flgAtivo: data.flgAtivo,
      flgGerarItensComSaldo: data.flgGerarItensComSaldo,
    });

    if (produtos.length > 0) {
      if (!showGridInventario) {
        setShowGridInventario(true);
      }

      const inventario = gridViewInventario.current.getDataSource() ?? [];

      produtos.forEach((produto) => {
        const inventoryHasItem = inventario.find(
          (item) =>
            item.nrSeqAlmoxarifadoLocal === produto.nrSeqAlmoxarifadoLocal
        );

        if (!inventoryHasItem) {
          produto.status = GridView.EnumStatus.Inserir;
          produto.qtdeContada = null;

          inventario.push(produto);
        }
      });

      const indexedList = codigoItemGenerator([...inventario]);

      setData({
        ...data,
        inventarioItens: indexedList,
      });

      if (gridViewInventario && gridViewInventario.current) {
        gridViewInventario.current.setDataSource(indexedList);

        setItensInventario(indexedList);

        exibirColunaSaldo();
      }
    } else {
      onSetMessage(false, 'Nenhum produto encontrado');
    }

    setLoading(false);
  };

  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...data, totalByPage };
    setData(filters);

    await onSearchProdutos(filters);
  };

  const onPageChange = async (page) => {
    const filters = { ...data, page };
    setData(filters);

    await onSearchProdutos(filters);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...data, sortBy };

    setData(filters);

    await onSearchProdutos(filters);
  };

  const onClickAddProdutoToInventario = async (e, datasource) => {
    if (!showGridInventario) {
      await setShowGridInventario(true);
    }

    const gridItem = datasource.find((el) => el === e);

    gridItem.status = GridView.EnumStatus.Inserir;
    gridItem.qtdeContada = null;

    if (gridViewInventario && gridViewInventario.current) {
      const inventory = gridViewInventario.current.getDataSource();

      const inventoryHasItem = inventory.find(
        (item) =>
          item.nrSeqAlmoxarifadoLocal === gridItem.nrSeqAlmoxarifadoLocal
      );

      if (!inventoryHasItem) {
        inventory.push(gridItem);

        const indexedList = codigoItemGenerator(inventory);

        gridViewInventario.current.setDataSource(indexedList);

        exibirColunaSaldo();

        setItensInventario(indexedList);

        setData({ ...data, inventarioItens: inventory });
      } else {
        onSetMessage(false, 'Produto já foi adicionado');
      }
    } else {
      setShowGridInventario(false);
    }
  };

  const onClickRemoverInventarioItem = (e, datasource) => {
    if (data.dtEfetiva !== null) {
      const gridItem = datasource.find((el) => el === e);

      const list = datasource.filter((el) => el !== e);

      if (gridItem.nrSequencia) {
        gridItem.status = GridView.EnumStatus.Remover;

        list.push(gridItem);
      }

      const indexedList = codigoItemGenerator(list);

      if (gridViewInventario && gridViewInventario.current)
        gridViewInventario.current.setDataSource(indexedList);

      setData({ ...data, inventarioItens: list });
      setItensInventario(list);
    } else {
      onSetMessage(false, 'Não é possivel alterar um inventário efetivado');
    }
  };

  const onEfetivar = async () => {
    setLoading(true);

    const { status, message: msg } = await efetivarInventario({
      nrSeqInventario: data.nrSeqInventario,
    });

    if (status === ResponseStatus.Success) {
      load();
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onSearchAlmoxarifadoLocalInventario = () => {
    const locais = [];

    itensInventario.forEach((item) => {
      const local = locais.find(
        (e) => item.noAlmoxarifadoLocal === e.noAlmoxarifadoLocal
      );

      if (!local) {
        locais.push({ noAlmoxarifadoLocal: item.noAlmoxarifadoLocal });
      }
    });

    return locais;
  };

  const onSearchGrupoInventario = () => {
    const grupos = [];

    itensInventario.forEach((item) => {
      const grupo = grupos.find(
        (e) => item.noProdutoGrupo === e.noProdutoGrupo
      );

      if (!grupo) {
        grupos.push({ noProdutoGrupo: item.noProdutoGrupo });
      }
    });

    return grupos;
  };

  const onSearchSubGrupoInventario = () => {
    const subGrupos = [];

    itensInventario.forEach((item) => {
      const subGrupo = subGrupos.find(
        (e) => item.noProdutoSubGrupo === e.noProdutoSubGrupo
      );

      if (!subGrupo) {
        subGrupos.push({ noProdutoSubGrupo: item.noProdutoSubGrupo });
      }
    });

    return subGrupos;
  };

  const onFilterInventario = () => {
    if (gridViewInventario && gridViewInventario.current) {
      if (
        (!inventarioFilter.almoxarifadoLocal ||
          !inventarioFilter.almoxarifadoLocal?.noAlmoxarifadoLocal) &&
        (!inventarioFilter.produtoGrupo ||
          !inventarioFilter.produtoGrupo?.noProdutoGrupo) &&
        (!inventarioFilter.produtoSubGrupo ||
          !inventarioFilter.produtoSubGrupo?.noProdutoSubGrupo)
      ) {
        gridViewInventario.current.setDataSource(itensInventario);
      } else {
        const filteredInventario = [];

        itensInventario.forEach((item) => {
          let filterItem = true;

          if (
            inventarioFilter.almoxarifadoLocal &&
            inventarioFilter.almoxarifadoLocal?.noAlmoxarifadoLocal
          ) {
            if (
              item.noAlmoxarifadoLocal !==
              inventarioFilter.almoxarifadoLocal.noAlmoxarifadoLocal
            ) {
              filterItem = false;
            }
          }

          if (
            inventarioFilter.produtoGrupo &&
            inventarioFilter.produtoGrupo?.noProdutoGrupo
          ) {
            if (filterItem) {
              if (
                item.noProdutoGrupo !==
                inventarioFilter.produtoGrupo.noProdutoGrupo
              ) {
                filterItem = false;
              }
            }
          }

          if (
            inventarioFilter.produtoSubGrupo &&
            inventarioFilter.produtoSubGrupo?.noProdutoSubGrupo
          ) {
            if (filterItem) {
              if (
                item.noProdutoSubGrupo !==
                inventarioFilter.produtoSubGrupo.noProdutoSubGrupo
              ) {
                filterItem = false;
              }
            }
          }

          if (filterItem) {
            filteredInventario.push(item);
          }
        });

        if (filteredInventario.length > 0) {
          gridViewInventario.current.setDataSource(filteredInventario);
        }
      }
    }
  };

  const onInventoryColumnSort = (sortBy) => {
    const [key, order] = sortBy.split(' ');

    const list = gridViewInventario.current.getDataSource();

    const sortedList = list.sort((a, b) => {
      if (order === 'asc') {
        if (a[key] < b[key]) {
          return -1;
        }

        if (a[key] > b[key]) {
          return 1;
        }

        return 0;
      }

      if (a[key] > b[key]) {
        return -1;
      }

      if (a[key] < b[key]) {
        return 1;
      }

      return 0;
    });

    const indexedList = codigoItemGenerator(sortedList);

    gridViewInventario.current.setDataSource(indexedList, { sortBy });
  };

  const produtosColumns = [
    { key: 'noAlmoxarifadoLocal', title: 'Local' },
    { key: 'cdPeca', title: 'Código Peça' },
    { key: 'noProduto', title: 'Produto' },
    { key: 'noProdutoGrupo', title: 'Grupo' },
    { key: 'noProdutoSubGrupo', title: 'SubGrupo' },
    {
      key: 'nrSeqAlmoxarifadoLocal',
      title: 'Adicionar',
      type: GridView.ColumnTypes.Button,
      onClick: (e, dataSource) => onClickAddProdutoToInventario(e, dataSource),
      theme: Theme.Success,
      icon: 'plus',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Adicionar',
      tooltipDirection: 'bottom',
    },
  ];

  const inventarioColumns = [
    { key: 'codigoItem', title: 'Código Item', sortable: false },
    { key: 'cdPeca', title: 'Código Peça' },
    { key: 'noProduto', title: 'Produto' },
    { key: 'noProdutoGrupo', title: 'Grupo', visible: false },
    { key: 'noProdutoSubGrupo', title: 'SubGrupo', visible: false },
    { key: 'noProdutoLinha', title: 'Linha' },
    { key: 'cdProdutoUnidadeMedida', title: 'Unid', visible: false },
    {
      key: 'noAlmoxarifadoLocal',
      title: 'Local Armazenamento',
      sortable: false,
    },
    {
      key: 'qtdeContada',
      title: 'Qtde',
      sortable: false,
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'dtContagem',
      title: 'Data Contagem',
      format: GridView.DataTypes.Date,
      sortable: false,
    },
    {
      key: 'vlrCusto',
      title: 'Valor Custo',
      format: GridView.DataTypes.Money,
      sortable: false,
    },
    {
      key: 'saldo',
      title: 'Saldo',
      sortable: false,
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'nrSequencia',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemoverInventarioItem(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Inventário'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={data.nrSeqInventario && data.dtEfetiva ? null : save}
      onDelete={data.nrSeqInventario ? onExcluir : null}
      onPrint={data.nrSeqInventario ? onPrint : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {data.nrSeqInventario && (
        <ToolbarButtons>
          <ToolbarButtons.Button
            text='Excel'
            icon={['far', 'file-excel']}
            onClick={() => onExcel()}
          />
          <ToolbarButtons.Button
            text='Diferença'
            icon='print'
            onClick={() => onPrintDiferenca()}
          />
        </ToolbarButtons>
      )}

      <div className='row mb-3'>
        <div className='col-3'>
          <Textbox
            required
            maxLength={60}
            label='Descrição'
            text={data.noInventario}
            onChangedValue={(noInventario) =>
              setData({ ...data, noInventario })
            }
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Inventário Completo'
            checked={data.flgInventarioCompleto}
            onChange={(flgInventarioCompleto) =>
              setData({ ...data, flgInventarioCompleto })
            }
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Somente Itens Com Saldo'
            checked={data.flgGerarItensComSaldo}
            onChange={(flgGerarItensComSaldo) =>
              setData({
                ...data,
                flgGerarItensComSaldo,
                flgGerarItensSemSaldo:
                  flgGerarItensComSaldo && data.flgGerarItensSemSaldo
                    ? false
                    : data.flgGerarItensSemSaldo,
              })
            }
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Somente Itens Com Saldo Negativo'
            checked={data.flgGerarItensSemSaldo}
            onChange={(flgGerarItensSemSaldo) =>
              setData({
                ...data,
                flgGerarItensSemSaldo,
                flgGerarItensComSaldo:
                  data.flgGerarItensComSaldo && flgGerarItensSemSaldo
                    ? false
                    : data.flgGerarItensComSaldo,
              })
            }
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Exibe Quantidade Estoque'
            checked={data.flgExibirEstoque}
            onChange={(flgExibirEstoque) =>
              setData({ ...data, flgExibirEstoque })
            }
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Itens Ativos'
            checked={data.flgAtivo}
            onChange={(flgAtivo) => setData({ ...data, flgAtivo })}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-6'>
          <Autocomplete
            required
            label='Empresa'
            searchDataSource={onSearchEmpresa}
            selectedItem={data.empresa}
            onSelectItem={(empresa) => {
              setData({
                ...data,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col'>
          <DatePicker
            required
            label='Data Início'
            text={data.dtInicio}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtInicio) => setData({ ...data, dtInicio })}
          />
        </div>
        <div className='col'>
          <DatePicker
            required
            label='Data Fim'
            text={data.dtFim}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtFim) => setData({ ...data, dtFim })}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-6'>
          <Autocomplete
            required
            readOnly={!data.empresa}
            enabled={!!data.empresa}
            placeholder={!data.empresa && 'Selecione a Empresa'}
            label='Almoxarifado'
            searchDataSource={onSearchAlmoxarifado}
            selectedItem={data.almoxarifado}
            onSelectItem={(almoxarifado) => {
              setData({
                ...data,
                almoxarifado,
                nrSeqAlmoxarifado: almoxarifado.nrSeqAlmoxarifado,
              });
            }}
            dataSourceTextProperty='noAlmoxarifado'
          />
        </div>
        {!data.flgInventarioCompleto && !data.dtEfetiva && (
          <div className='col-6'>
            <Autocomplete
              label='Almoxarifado Local'
              readOnly={!data.almoxarifado}
              enabled={!!data.almoxarifado}
              searchDataSource={onSearchAlmoxarifadoLocal}
              selectedItem={data.almoxarifadoLocal}
              onSelectItem={(almoxarifadoLocal) => {
                setData({
                  ...data,
                  almoxarifadoLocal,
                  nrSeqAlmoxarifadoLocal:
                    almoxarifadoLocal.nrSeqAlmoxarifadoLocal,
                });
              }}
              dataSourceTextProperty='noAlmoxarifadoLocal'
            />
          </div>
        )}
      </div>

      {!data.flgInventarioCompleto && !data.dtEfetiva && (
        <div className='row mb-3 align-items-end'>
          <div className='col-3'>
            <Autocomplete
              label='Produto Grupo'
              readOnly={!data.almoxarifado}
              enabled={!!data.almoxarifado}
              searchDataSource={onSearchProdutoGrupo}
              selectedItem={data.produtoGrupo}
              onSelectItem={(produtoGrupo) =>
                setData({
                  ...data,
                  produtoGrupo,
                  nrSeqProdutoGrupo: produtoGrupo.nrSeqProdutoGrupo,
                  produtoSubGrupo: undefined,
                  nrSeqProdutoSubGrupo: undefined,
                })
              }
              dataSourceTextProperty='noProdutoGrupo'
            />
          </div>
          <div className='col-3'>
            <Autocomplete
              label='Produto SubGrupo'
              readOnly={!data.almoxarifado}
              enabled={!!data.almoxarifado}
              searchDataSource={onSearchProdutoSubGrupo}
              selectedItem={data.produtoSubGrupo}
              onSelectItem={(produtoSubGrupo) =>
                setData({
                  ...data,
                  produtoSubGrupo,
                  nrSeqProdutoSubGrupo: produtoSubGrupo.nrSeqProdutoSubGrupo,
                })
              }
              dataSourceTextProperty='noProdutoSubGrupo'
            />
          </div>
          <div className='col'>
            <Textbox
              label='Produto'
              readOnly={!data.almoxarifado}
              maxLength={100}
              text={data.noProduto}
              onChangedValue={(noProduto) => setData({ ...data, noProduto })}
            />
          </div>
          <div className='col'>
            <Button
              onClick={() => onSearchProdutos()}
              outline
              theme={Theme.Success}
              template={Button.Templates.Button}
              text='Pesquisar'
            />
          </div>
        </div>
      )}
      <div className='row mb-3'>
        {!data.flgInventarioCompleto && !data.dtEfetiva && (
          <div className='col-3'>
            <Button
              className='w-100'
              onClick={() => onClickAddAllProdutos()}
              outline
              size={BootstrapSizes.Medium}
              theme={Theme.Success}
              template={Button.Templates.Button}
              text='Adicionar Produtos ao Inventário'
            />
          </div>
        )}
        {data.nrSeqInventario && !data.dtEfetiva && (
          <div className='col-3'>
            <Button
              className='w-100'
              onClick={onEfetivar}
              outline
              size={BootstrapSizes.Medium}
              theme={Theme.Success}
              template={Button.Templates.Button}
              text='Efetivar'
            />
          </div>
        )}
      </div>

      {showGridProduto && (
        <div className='row mb-3'>
          <GridView
            ref={gridViewProduto}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={produtosColumns}
            gridSizeList={[10, 25, 50, 100]}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
          />
        </div>
      )}

      {showGridInventario && (
        <Panel>
          <Panel.Header title='Inventário Itens' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3 align-items-end'>
              <div className='col'>
                <Autocomplete
                  label='Local Armazenamento'
                  searchDataSource={onSearchAlmoxarifadoLocalInventario}
                  selectedItem={inventarioFilter.almoxarifadoLocal}
                  onSelectItem={(almoxarifadoLocal) => {
                    setInventarioFilter({
                      ...inventarioFilter,
                      almoxarifadoLocal,
                    });
                  }}
                  dataSourceTextProperty='noAlmoxarifadoLocal'
                />
              </div>
              <div className='col'>
                <Autocomplete
                  label='Grupo'
                  searchDataSource={onSearchGrupoInventario}
                  selectedItem={inventarioFilter.produtoGrupo}
                  onSelectItem={(produtoGrupo) => {
                    setInventarioFilter({ ...inventarioFilter, produtoGrupo });
                  }}
                  dataSourceTextProperty='noProdutoGrupo'
                />
              </div>
              <div className='col'>
                <Autocomplete
                  label='SubGrupo'
                  searchDataSource={onSearchSubGrupoInventario}
                  selectedItem={inventarioFilter.produtoSubGrupo}
                  onSelectItem={(produtoSubGrupo) => {
                    setInventarioFilter({
                      ...inventarioFilter,
                      produtoSubGrupo,
                    });
                  }}
                  dataSourceTextProperty='noProdutoSubGrupo'
                />
              </div>
              <div className='col'>
                <Button
                  onClick={onFilterInventario}
                  outline
                  theme={Theme.Success}
                  template={Button.Templates.Button}
                  text='Filtrar Inventário'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <GridView
                canControlVisibility
                ref={gridViewInventario}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={inventarioColumns}
                showPagination={false}
                showSelectSizes={false}
                onColumnSort={onInventoryColumnSort}
              />
            </div>
          </Panel.Body>
        </Panel>
      )}
    </CSDManutPage>
  );
}
