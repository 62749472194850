import React from 'react';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import {
  Autocomplete,
  Textbox,
  Textarea,
  RadioButton,
  DatePicker,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getCondicaoPagamentoAutoComplete } from 'core/services/FIN/condicaoPagamento';
import { getOficinaAutocomplete } from 'core/services/FRO/oficina';
import { getUsuarioAutoComplete } from 'core/services/SEG/usuario';

const ManutencaoInfosPrincipal = ({
  onSetMessage,
  data,
  setData,
  registraDataMovimento,
  setRegistraDataMovimento,
  gridViewVeiculos,
  validaSeExisteValor,
  gridViewProdutos,
}) => {
  const statusBtns = [
    {
      text: 'Pendente',
      value: 'Pendente',
    },
    {
      text: 'Concluído',
      value: 'Concluído',
    },
  ];

  const dataMovimentoBtns = [
    {
      text: 'Registrar',
      value: 'S',
    },
    {
      text: 'Não registrar',
      value: 'N',
    },
  ];

  const onSearchCondicaoPagamento = async (e) => {
    const {
      status,
      message: msg,
      condicoesPagamento,
    } = await getCondicaoPagamentoAutoComplete({
      noCondicaoPagamento: e,
    });
    if (msg) onSetMessage(status, msg);
    return condicoesPagamento;
  };

  const onSearchOficina = async (e) => {
    const { status, message: msg, oficinas } = await getOficinaAutocomplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);
    return oficinas;
  };

  const onHandleOficina = (oficina) => {
    if (
      validaSeExisteValor(oficina.nrSeqPessoaOfi) &&
      validaSeExisteValor(oficina.flgControlaEstoque)
    ) {
      if (gridViewProdutos?.current) {
        gridViewProdutos.current.setDataSource([]);
      }

      setData({
        ...data,
        produtos: [],
        pessoaOfi: oficina,
        nrSeqPessoaOfi: oficina.nrSeqPessoaOfi,
        flgOficinaInterna: oficina.flgControlaEstoque,
      });
    }
  };

  const onSearchUsuario = async (e) => {
    const response = await getUsuarioAutoComplete({
      noLogin: e,
    });
    if (response.message) onSetMessage(response.status, response.message);
    return response.data;
  };

  const onHandleStatus = (flgStatus) => {
    if (flgStatus === 'Pendente') {
      setData({
        ...data,
        flgStatus,
        flgConcluido: false,
        dtFechamento: null,
        horaFechamento: null,
      });
      if (data?.placas && gridViewVeiculos?.current) {
        const gridList = data.placas.map((item) => ({
          ...item,
          flgExibeBtn: true,
        }));
        gridViewVeiculos.current.setDataSource(gridList);
      }
    } else {
      setData({
        ...data,
        flgStatus,
        flgConcluido: true,
      });
      if (data?.placas && gridViewVeiculos?.current) {
        const gridList = data.placas.map((item) => ({
          ...item,
          flgExibeBtn: false,
        }));
        gridViewVeiculos.current.setDataSource(gridList);
      }
    }
  };

  const onCalcVlrFechamentoOS = () => {
    let valorDeslocamento = 0;
    if (data.vlrDeslocamento) valorDeslocamento = data.vlrDeslocamento;

    let valorSomaProdutos = 0;
    if (data.vlrSomaProdutos) valorSomaProdutos = data.vlrSomaProdutos;

    setData({
      ...data,
      vlrFechamentoOS: valorDeslocamento + valorSomaProdutos,
    });
  };

  return (
    <>
      <div className='row mb-3'>
        <div className='col-1'>
          <Textbox
            label='Código'
            readOnly
            text={data?.cdManutencaoVeiculoOS}
            mask={MaskTypes.Integer}
            onChangedValue={(cdManutencaoVeiculoOS) =>
              setData({ ...data, cdManutencaoVeiculoOS })
            }
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Solicitado Por'
            required
            readOnly={data?.flgStatus === 'Concluído'}
            searchDataSource={onSearchUsuario}
            selectedItem={data.usuarioSol}
            onSelectItem={(usuarioSol) => {
              setData({
                ...data,
                usuarioSol,
                nrSeqUsuarioSol: usuarioSol.nrSeqUsuario,
              });
            }}
            dataSourceTextProperty='noLogin'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Autorizado Por'
            required
            readOnly={data?.flgStatus === 'Concluído'}
            searchDataSource={onSearchUsuario}
            selectedItem={data.usuarioAut}
            onSelectItem={(usuarioAut) => {
              setData({
                ...data,
                usuarioAut,
                nrSeqUsuarioAut: usuarioAut.nrSeqUsuario,
              });
            }}
            dataSourceTextProperty='noLogin'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Oficina'
            required
            readOnly={data?.flgStatus === 'Concluído'}
            searchDataSource={onSearchOficina}
            selectedItem={data.pessoaOfi}
            onSelectItem={(pessoaOfi) => onHandleOficina(pessoaOfi)}
            dataSourceTextProperty='noOficina'
          />
        </div>
        {data?.flgOficinaInterna && (
          <div className='col d-flex flex-column align-items-start'>
            <RadioButton
              label='Data Movimento'
              outline
              size={BootstrapSizes.Small}
              theme={Theme.Primary}
              selectedButton={registraDataMovimento}
              buttons={dataMovimentoBtns}
              onChange={(value) => setRegistraDataMovimento(value)}
            />
          </div>
        )}
        <div className='col d-flex flex-column align-items-start'>
          <RadioButton
            label='Status'
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={data?.flgStatus}
            buttons={statusBtns}
            onChange={(flgStatus) => onHandleStatus(flgStatus)}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Data de Abertura'
            required
            readOnly={data?.flgStatus === 'Concluído'}
            text={data?.dtAbertura}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtAbertura) => setData({ ...data, dtAbertura })}
          />
        </div>
        <div className='col-1'>
          <Textbox
            label='Hora'
            readOnly={data?.flgStatus === 'Concluído'}
            required={data?.dtFechamento !== null}
            text={data?.horaAbertura}
            mask={MaskTypes.TimeHM}
            onChangedValue={(horaAbertura) =>
              setData({ ...data, horaAbertura })
            }
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data de Fechamento'
            readOnly={data?.flgStatus === 'Pendente'}
            text={data?.dtFechamento}
            mask={MaskTypes.Date}
            onChange={(dtFechamento) => setData({ ...data, dtFechamento })}
          />
        </div>
        <div className='col-1'>
          <Textbox
            label='Hora'
            readOnly={data?.flgStatus === 'Pendente'}
            text={data?.horaFechamento}
            mask={MaskTypes.TimeHM}
            onChangedValue={(horaFechamento) =>
              setData({ ...data, horaFechamento })
            }
          />
        </div>

        {data?.flgOficinaInterna && (
          <div className='col'>
            <Textarea
              label='Descrição do Serviço'
              readOnly={data?.flgStatus === 'Concluído'}
              text={data?.obsOS ?? ''}
              onChangedValue={(obsOS) => setData({ ...data, obsOS })}
            />
          </div>
        )}

        {!data?.flgOficinaInterna && (
          <>
            <div className='col-2'>
              <DatePicker
                label='Data de Saída'
                required
                readOnly={data?.flgStatus === 'Concluído'}
                text={data?.dtSaida}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtSaida) => setData({ ...data, dtSaida })}
              />
            </div>
            <div className='col-2'>
              <DatePicker
                label='Previsão de Retorno'
                required
                readOnly={data?.flgStatus === 'Concluído'}
                text={data?.dtPrevistaRetorno}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtPrevistaRetorno) =>
                  setData({ ...data, dtPrevistaRetorno })
                }
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Nr Título'
                readOnly={data?.flgStatus === 'Concluído'}
                text={data?.nrTituloPagar}
                onChangedValue={(nrTituloPagar) =>
                  setData({ ...data, nrTituloPagar })
                }
              />
            </div>
          </>
        )}
      </div>

      {/* Linhas 3 e 4 -> Se Oficina for EXTERNA */}
      {!data?.flgOficinaInterna && (
        <>
          <div className='row mb-3'>
            <div className='col'>
              <Autocomplete
                label='Condição de Pagamento'
                required
                readOnly={data?.flgStatus === 'Concluído'}
                searchDataSource={onSearchCondicaoPagamento}
                selectedItem={data?.condicaoPagamento}
                onSelectItem={(condicaoPagamento) => {
                  setData({
                    ...data,
                    condicaoPagamento,
                    nrSeqCondicaoPagamento:
                      condicaoPagamento.nrSeqCondicaoPagamento,
                  });
                }}
                dataSourceTextProperty='noCondicaoPagamento'
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Valor do Orçamento'
                required
                readOnly={data?.flgStatus === 'Concluído'}
                text={data?.vlrOs ?? 0}
                mask={MaskTypes.Currency}
                onChangedValue={(vlrOs) => setData({ ...data, vlrOs })}
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Valor no Fechamento'
                readOnly
                text={data?.vlrFechamentoOS ?? 0}
                mask={MaskTypes.Currency}
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Valor do Deslocamento'
                readOnly={data?.flgStatus === 'Concluído'}
                text={data?.vlrDeslocamento ?? 0}
                mask={MaskTypes.Currency}
                onBlur={onCalcVlrFechamentoOS}
                onChangedValue={(vlrDeslocamento) =>
                  setData({ ...data, vlrDeslocamento })
                }
              />
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col'>
              <Textarea
                label='Descrição do Serviço'
                readOnly={data?.flgStatus === 'Concluído'}
                text={data.obsOS ?? ''}
                onChangedValue={(obsOS) => setData({ ...data, obsOS })}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ManutencaoInfosPrincipal;
