import React, { useEffect, useState } from 'react';
import { Theme } from 'ui/Helpers/enums';
import { BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';
import {
  Modal,
  Notification,
  Button,
  Textbox,
  DatePicker,
} from 'ui/components';
import { getVeiculoRodagemAtiva } from 'core/services/FRO/veiculoRodagem';

interface IData {
  nrOdometroFinal: number | null;
  dtFim: string | null;
  confirmRemove: boolean;
}

interface IProps {
  show: boolean;
  placa: any;
  placaRemove: any;
  ultAbastecimento: any;
  setLoading: any;
  onClose: (res: IData) => void;
}

export function ModalDesvincularVeiculo({
  show,
  placa,
  placaRemove,
  ultAbastecimento,
  setLoading,
  onClose,
}: IProps) {
  const [message, setMessage] = useState<any>({});
  const [data, setData] = useState<IData>({
    nrOdometroFinal: null,
    dtFim: null,
    confirmRemove: false,
  });

  const onSetMessage = (status: number, msg: string) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = async () => {
    setLoading(true);

    const obj = {
      nrSeqVeiculo: placa.veiculoTipo?.flgPrincipal
        ? placaRemove.nrSeqVeiculo
        : placa.nrSeqVeiculo,
      nrSeqVeiculoVinc: placa.veiculoTipo?.flgPrincipal
        ? placa.nrSeqVeiculo
        : placaRemove.nrSeqVeiculo,
    };
    const response = await getVeiculoRodagemAtiva(obj);

    if (response.status === ResponseStatus.Error) {
      onSetMessage(response.status, response.message);
    } else {
      placa.veiculoRodagens = [response.data];
    }

    setData({
      nrOdometroFinal: ultAbastecimento?.nrUltimoAbastecimento ?? 0,
      dtFim: new Date().toISOString(),
      confirmRemove: false,
    });

    setLoading(false);
  };

  useEffect(() => {
    if (
      placa?.nrSeqVeiculo !== undefined &&
      placa?.nrSeqVeiculo !== null &&
      placaRemove?.nrSeqVeiculo !== undefined &&
      placaRemove?.nrSeqVeiculo !== null
    ) {
      load();
    }
  }, [show]);

  const criarDataSemHora = (inputDate: string) => {
    const formatedDate = new Date(inputDate);
    return new Date(
      formatedDate.getFullYear(),
      formatedDate.getMonth(),
      formatedDate.getDate()
    );
  };

  const validaRemovePlaca = (validaRemove: any) => {
    let isValid = true;
    let validaMessage = '';

    const odometroInicial = placa.veiculoRodagens[0].nrOdometroInicial;
    const odometroFinal = validaRemove.nrOdometroFinal;
    const dataFim = validaRemove.dtFim;
    const dataInicial = placa.veiculoRodagens[0].dtInicio;

    if (odometroFinal < odometroInicial) {
      isValid = false;
      validaMessage = `Odômetro final não pode ser menor do que o odômetro inicial do vínculo (odômetro inicial: ${odometroInicial}).`;
      return { isValid, validaMessage };
    }
    if (!dataFim) {
      isValid = false;
      validaMessage = 'Data final não informada.';
      return { isValid, validaMessage };
    }

    const dataFimFormatada = criarDataSemHora(dataFim);
    const dataInicialFormatada = criarDataSemHora(dataInicial);

    if (dataFimFormatada < dataInicialFormatada) {
      isValid = false;
      validaMessage = `Data final não pode ser anterior à data de início do vínculo (data inicial: ${
        dataInicialFormatada.toISOString().split('T')[0]
      }).`;
    }

    return { isValid, validaMessage };
  };

  const handleOnClose = (confirmRemove: boolean) => {
    const obj = { ...data, confirmRemove };
    setData({
      nrOdometroFinal: null,
      dtFim: null,
      confirmRemove: false,
    });
    onClose(obj);
  };

  return (
    //  @ts-expect-error
    <Modal
      show={show}
      title={`Informe o odômetro do veículo placa ${placa.placa}`}
      onClose={() => handleOnClose(false)}
      size={BootstrapSizes.Large}
    >
      {/* @ts-expect-error */}
      <Modal.Body>
        {message && (
          <>
            <div className='row mb-3'>
              <div className='col'>
                {/* @ts-expect-error */}
                <Notification
                  message={message.message}
                  theme={message.theme}
                  onClose={() => setMessage(null)}
                />
              </div>
            </div>
          </>
        )}
        <div className='row mb-3 align-items-end'>
          <div className='col'>
            {/* @ts-expect-error */}
            <Textbox
              label='Odômetro do Veículo Principal'
              maxLength={12}
              text={data?.nrOdometroFinal}
              onChangedValue={(nrOdometroFinal: number) =>
                setData({ ...data, nrOdometroFinal })
              }
            />
          </div>
          <div className='col'>
            <DatePicker
              label='Data Final'
              text={data?.dtFim}
              onChange={(dtFim: string) => setData({ ...data, dtFim })}
            />
          </div>
        </div>

        <div className='row mb-3 align-items-end'>
          <div className='col'>
            {/* @ts-expect-error */}
            <Textbox
              label='Último Abastecimento:'
              readOnly
              text={ultAbastecimento?.nrUltimoAbastecimento}
            />
          </div>
          <div className='col'>
            {/* @ts-expect-error */}
            <DatePicker
              label='Data do Abastecimento:'
              readOnly
              text={ultAbastecimento?.dtUltimoAbastecimento}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Remover Veículo'
          theme={Theme.Danger}
          onClick={() => {
            const { isValid, validaMessage } = validaRemovePlaca(data);
            if (isValid) {
              handleOnClose(true);
            } else {
              onSetMessage(ResponseStatus.Error, validaMessage);
            }
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
