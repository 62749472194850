import React, { useState, useEffect } from 'react';

import { Endereco } from 'core/models/SEG';
import { getEnderecoByPessoa } from 'core/services/SEG/endereco';

interface IPainelEndereco {
  NrSeqPessoa: number;
}

export default function PainelEndereco({ NrSeqPessoa }: IPainelEndereco) {
  const [endereco, setEndereco] = useState(new Endereco({}));

  const load = () => {
    if (NrSeqPessoa !== undefined)
      getEnderecoByPessoa(NrSeqPessoa).then((response) =>
        setEndereco(new Endereco({ ...response }))
      );
  };

  useEffect(() => {
    load();
  }, [NrSeqPessoa]);

  return (
    <div className='col-auto mt-3'>
      <div className='alert alert-info fs-8' role='alert'>
        <span className='fw-bold'>
          Endereço: {endereco.noRua} {endereco.numero ? ',' : ''}
          {endereco.numero}
          {endereco.bairro ? ',' : ''} {endereco.bairro}
          {endereco.cep ? ',' : ''}
          {endereco.cep} {endereco.cidade?.noCidade ? ',' : ''}
          {endereco.cidade?.noCidade}, {endereco.estado?.noEstado ? ',' : ''}
          {endereco.estado?.noEstado}
        </span>
      </div>
    </div>
  );
}
