import {
  getResultadoVeiculoPaginated,
  printResultadoVeiculo,
  printResultadoVeiculoDespesasPorCompetencia,
  printResultadoVeiculoResumo,
} from 'core/services/FRO/resultadoVeiculo';
import { getVeiculoAutoCompleteResumido } from 'core/services/FRO/veiculo';
import { getCentroDeCustoList } from 'core/services/TEL/centroDeCusto';
import React, { useRef, useState, useEffect } from 'react';
import {
  Autocomplete,
  CSDSelPage,
  DatePicker,
  GridView,
  Panel,
  RadioButton,
  Textbox,
  ToolbarButtons,
  DropdownMulti,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';

export default function ResultadoVeiculo({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({
    tipoBusca: 'Veiculo',
    sortBy: 'PlacaPrinc asc',
    tipoRelatorio: 'Resumo',
    totalByPage: 700,
    tipoPeriodo: 'C',
    filtroVeiculoProprio: 'Own',
    filtroTipoDeBusca: 'planoConta',
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});

  const dropDownCentroDeCusto = useRef();

  const onSearchCentroDeCusto = async (e) => {
    const { data: centroDeCusto } = await getCentroDeCustoList({
      noCentroDeCusto: e,
    });

    return centroDeCusto;
  };

  useEffect(() => {
    dropDownCentroDeCusto.current.loadDataSource(onSearchCentroDeCusto);
  }, []);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const validateMesAno = () => {
    const stringDeMesAno = filtros.deMesAno.split('/');

    const deMesAno = new Date(
      parseInt(stringDeMesAno[1], 10),
      parseInt(stringDeMesAno[0], 10) - 1,
      1
    );

    const stringAteMesANo = filtros.ateMesAno.split('/');

    const ateMesAno = new Date(
      parseInt(stringAteMesANo[1], 10),
      parseInt(stringAteMesANo[0], 10) - 1,
      1
    );

    if (deMesAno > ateMesAno) {
      return false;
    }

    return true;
  };

  const validateAteMesAno = () => {
    const stringAteMesANo = filtros.ateMesAno.split('/');

    const ateMesAno = new Date(
      parseInt(stringAteMesANo[1], 10),
      parseInt(stringAteMesANo[0], 10) - 1,
      1
    );

    const today = new Date();

    if (ateMesAno > today) {
      return false;
    }

    return true;
  };

  const search = async () => {
    setLoading(true);

    if (filtros.filtroVeiculoProprio !== 'All') {
      filtros.flgProprio = filtros.filtroVeiculoProprio === 'Own';
    } else {
      filtros.flgProprio = undefined;
    }

    if (filtros.tipoPeriodo === 'C') {
      if (filtros.deMesAno && filtros.ateMesAno) {
        if (validateAteMesAno()) {
          if (validateMesAno()) {
            const { data, pagination } = await getResultadoVeiculoPaginated(
              filtros
            );

            if (data.length === 0) {
              onSetMessage(false, 'Nenhum resultado encontrado no periodo');
            }

            if (gridView && gridView.current) {
              gridView.current.setDataSource(data, pagination);
            }
          } else {
            onSetMessage(
              false,
              'O campo Até Mes/Ano não pode ser menor que o De Mes/Ano'
            );
          }
        } else {
          onSetMessage(
            false,
            'A competencia Até Mes/Ano não pode ser maior que a data atual'
          );
        }
      } else {
        onSetMessage(false, 'Preencha o intervalo de busca');
      }
    } else if (filtros.tipoPeriodo === 'D') {
      if (filtros.dtSaidaIni && filtros.dtSaidaFim) {
        const dtSaidaInicial = new Date(filtros.dtSaidaIni);
        const dtSaidaFinal = new Date(filtros.dtSaidaFim);

        if (dtSaidaInicial <= dtSaidaFinal) {
          const { data, pagination } = await getResultadoVeiculoPaginated(
            filtros
          );

          if (data.length === 0) {
            onSetMessage(false, 'Nenhum resultado encontrado no periodo');
          }

          if (gridView && gridView.current) {
            gridView.current.setDataSource(data, pagination);
          }
        } else {
          onSetMessage(
            false,
            'A data inicial não pode ser maior que a data final'
          );
        }
      } else {
        onSetMessage(false, 'Preencha o intervalo de busca');
      }
    }

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onPrintResumo = async () => {
    setLoading(true);

    if (filtros.tipoPeriodo === 'C') {
      if (filtros.deMesAno && filtros.ateMesAno) {
        if (validateAteMesAno()) {
          if (validateMesAno()) {
            const { value } = await printResultadoVeiculoResumo(filtros);

            onOpenReport(transaction.noTransacao, value);
          } else {
            onSetMessage(
              false,
              'O campo Até Mes/Ano não pode ser menor que o De Mes/Ano'
            );
          }
        } else {
          onSetMessage(
            false,
            'A competencia Até Mes/Ano não pode ser maior que a data atual'
          );
        }
      } else {
        onSetMessage(false, 'Preencha o intervalo de busca');
      }
    } else if (filtros.tipoPeriodo === 'D') {
      if (filtros.dtSaidaIni && filtros.dtSaidaFim) {
        const dtSaidaInicial = new Date(filtros.dtSaidaIni);
        const dtSaidaFinal = new Date(filtros.dtSaidaFim);

        if (dtSaidaInicial <= dtSaidaFinal) {
          const { value } = await printResultadoVeiculoResumo(filtros);

          onOpenReport(transaction.noTransacao, value);
        } else {
          onSetMessage(
            false,
            'A data inicial não pode ser maior que a data final'
          );
        }
      }
    } else {
      onSetMessage(false, 'Preencha o intervalo de busca');
    }

    setLoading(false);
  };

  const onPrintAgrupado = async () => {
    setLoading(true);

    if (filtros.tipoPeriodo === 'C') {
      if (filtros.deMesAno && filtros.ateMesAno) {
        if (validateAteMesAno()) {
          if (validateMesAno()) {
            const { value } = await printResultadoVeiculo({
              ...filtros,
              tipoRelatorio: 'Agrupado',
            });

            onOpenReport(transaction.noTransacao, value);
          } else {
            onSetMessage(
              false,
              'O campo Até Mes/Ano não pode ser menor que o De Mes/Ano'
            );
          }
        } else {
          onSetMessage(
            false,
            'A competencia Até Mes/Ano não pode ser maior que a data atual'
          );
        }
      } else {
        onSetMessage(false, 'Preencha o intervalo de busca');
      }
    } else if (filtros.tipoPeriodo === 'D') {
      if (filtros.dtSaidaIni && filtros.dtSaidaFim) {
        const dtSaidaInicial = new Date(filtros.dtSaidaIni);
        const dtSaidaFinal = new Date(filtros.dtSaidaFim);

        if (dtSaidaInicial <= dtSaidaFinal) {
          const { value } = await printResultadoVeiculo({
            ...filtros,
            tipoRelatorio: 'Agrupado',
          });

          onOpenReport(transaction.noTransacao, value);
        } else {
          onSetMessage(
            false,
            'A data inicial não pode ser maior que a data final'
          );
        }
      }
    } else {
      onSetMessage(false, 'Preencha o intervalo de busca');
    }

    setLoading(false);
  };

  const onPrintDetalhe = async () => {
    setLoading(true);

    if (filtros.tipoPeriodo === 'C') {
      if (filtros.deMesAno && filtros.ateMesAno) {
        if (validateAteMesAno()) {
          if (validateMesAno()) {
            const { value } = await printResultadoVeiculo({
              ...filtros,
              tipoRelatorio: 'Detalhe',
            });

            onOpenReport(transaction.noTransacao, value);
          } else {
            onSetMessage(
              false,
              'O campo Até Mes/Ano não pode ser menor que o De Mes/Ano'
            );
          }
        } else {
          onSetMessage(
            false,
            'A competencia Até Mes/Ano não pode ser maior que a data atual'
          );
        }
      } else {
        onSetMessage(false, 'Preencha o intervalo de busca');
      }
    } else if (filtros.tipoPeriodo === 'D') {
      if (filtros.dtSaidaIni && filtros.dtSaidaFim) {
        const dtSaidaInicial = new Date(filtros.dtSaidaIni);
        const dtSaidaFinal = new Date(filtros.dtSaidaFim);

        if (dtSaidaInicial <= dtSaidaFinal) {
          const { value } = await printResultadoVeiculo({
            ...filtros,
            tipoRelatorio: 'Detalhe',
          });

          onOpenReport(transaction.noTransacao, value);
        } else {
          onSetMessage(
            false,
            'A data inicial não pode ser maior que a data final'
          );
        }
      }
    } else {
      onSetMessage(false, 'Preencha o intervalo de busca');
    }

    setLoading(false);
  };

  const onPrintDespesas = async () => {
    setLoading(true);

    if (filtros.tipoPeriodo === 'C') {
      if (filtros.deMesAno && filtros.ateMesAno) {
        if (validateAteMesAno()) {
          if (validateMesAno()) {
            const { value } = await printResultadoVeiculoDespesasPorCompetencia(
              {
                ...filtros,
                tipoRelatorio: 'Detalhe',
              }
            );

            onOpenReport(transaction.noTransacao, value);
          } else {
            onSetMessage(
              false,
              'O campo Até Mes/Ano não pode ser menor que o De Mes/Ano'
            );
          }
        } else {
          onSetMessage(
            false,
            'A competencia Até Mes/Ano não pode ser maior que a data atual'
          );
        }
      } else {
        onSetMessage(false, 'Preencha o intervalo de busca');
      }
    } else if (filtros.tipoPeriodo === 'D') {
      if (filtros.dtSaidaIni && filtros.dtSaidaFim) {
        const dtSaidaInicial = new Date(filtros.dtSaidaIni);
        const dtSaidaFinal = new Date(filtros.dtSaidaFim);

        if (dtSaidaInicial <= dtSaidaFinal) {
          const { value } = await printResultadoVeiculo({
            ...filtros,
            tipoRelatorio: 'Detalhe',
          });

          onOpenReport(transaction.noTransacao, value);
        } else {
          onSetMessage(
            false,
            'A data inicial não pode ser maior que a data final'
          );
        }
      }
    } else {
      onSetMessage(false, 'Preencha o intervalo de busca');
    }

    setLoading(false);
  };

  const onSearchVeiculo = async (e) => {
    const {
      status,
      message: msg,
      veiculos,
    } = await getVeiculoAutoCompleteResumido({
      placa: e,
      flgPrincipal: filtros.tipoBusca === 'Acerto' ? true : undefined,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const tipoBuscaButtons = [
    { text: 'Veículo', value: 'Veiculo' },
    { text: 'Por Acerto', value: 'Acerto' },
  ];

  const ordenacaoButtons = [
    { text: 'Placa', value: 'PlacaPrinc asc' },
    { text: 'Lucro', value: 'VlrLucro desc' },
  ];

  const tipoPeriodoButtons = [
    { text: 'Mes/Ano', value: 'C' },
    { text: 'Data', value: 'D' },
  ];

  const onClickPrintRelatorio = async (e) => {
    setLoading(true);

    const { value } = await printResultadoVeiculo({
      nrSeqVeiculo: e.nrSeqVeiculo,
      deMesAno: e.deMesAno,
      ateMesAno: e.ateMesAno,
      tipoBusca: e.tipoBusca,
      tipoRelatorio: 'Agrupado',
      tipoPeriodoButtons: e.tipoPeriodo,
      dtSaidaIni: e.dtSaidaIni,
      dtSaidaFim: e.dtSaidaFim,
      tipoPeriodo: e.tipoPeriodo,
    });

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ResultadoVeiculo/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow) => {
    const dataSource = gridView.current.getDataSource();
    const nrSeqVeiculo = sourceRow.find((e) => e.key === 'nrSeqVeiculo');
    const resultadoVeiculo = dataSource.find(
      (e) => e.nrSeqVeiculo === nrSeqVeiculo.value
    );
    const {
      deMesAno,
      ateMesAno,
      tipoBusca,
      tipoPeriodo,
      dtSaidaIni,
      dtSaidaFim,
    } = resultadoVeiculo;

    onOpenMaintenance({
      nrSeqVeiculo: nrSeqVeiculo.value,
      deMesAno,
      ateMesAno,
      tipoBusca,
      tipoPeriodo,
      dtSaidaIni,
      dtSaidaFim,
      flgProprio: filtros.flgProprio,
    });
  };

  const columns = [
    {
      key: 'nrSeqVeiculo',
      type: GridView.ColumnTypes.Button,
      title: 'Print',
      onClick: (e) => onClickPrintRelatorio(e),
      theme: Theme.Success,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir Agrupado',
      tooltipDirection: 'bottom',
    },
    { key: 'placaPrinc', title: 'Cavalo', sortable: false },
    { key: 'placaComp', title: 'Comp', sortable: false, visible: false },
    { key: 'placaCompB', title: 'Comp', sortable: false, visible: false },
    {
      key: 'noMotorista',
      title: 'Motorista',
      sortable: false,
    },
    {
      key: 'noPlanoDeConta',
      title: 'Plano de Conta',
      sortable: false,
      visible: false,
    },
    {
      key: 'noCentroDeCusto',
      title: 'Centro de Custo',
      sortable: false,
      visible: false,
    },
    {
      key: 'dtSaidaIni',
      title: 'Dt Saída Ini',
      sortable: false,
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'dtChegadaIni',
      title: 'Dt Chegada Ini',
      sortable: false,
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'dtChegadaFim',
      title: 'Dt Chegada Fim',
      sortable: false,
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'vlrReceitaAcerto',
      title: 'Valor Receita',
      sortable: false,
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrDespesaAcerto',
      title: 'Despesa Acerto',
      sortable: false,
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrAbastecimento',
      title: 'Abastecimento',
      sortable: false,
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrPedagio',
      title: 'Pedágio',
      sortable: false,
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrDespesaTitulos',
      title: 'Despesa Titulos',
      sortable: false,
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrDespesaManutencao',
      title: 'Despesa Manutenção',
      sortable: false,
      format: GridView.DataTypes.Money,
    },
    {
      key: 'kmPercorrido',
      title: 'KM Percorridos',
      sortable: false,
    },
    {
      key: 'vlrLucro',
      title: 'Lucro',
      sortable: false,
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrLucroPorKm',
      title: 'Lucro Por KM',
      sortable: false,
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrPercentual',
      title: 'Percentual',
      sortable: false,
      format: GridView.DataTypes.Decimal,
      decimalPlaces: 2,
    },
  ];

  const vehicleOnwnershipButtons = [
    { text: 'Todos', value: 'All' },
    { text: 'Veículo Próprio', value: 'Own' },
    { text: 'Veículo Terceiro', value: 'Others' },
  ];

  const TipoDeBusca = [
    { text: 'Plano De Conta', value: 'planoConta' },
    { text: 'Veículo Centro De Custo', value: 'veiculo' },
  ];

  const onBlurCompetencia = (e) => {
    if (e.length !== 7 && e !== '') {
      const today = new Date();
      const currentYear = today.getFullYear();
      let currentMonth = today.getMonth() + 1;

      let competencia;

      if (String(currentMonth).length === 1) {
        currentMonth = `0${currentMonth}`;
      }

      if (e === '0') {
        competencia = `${currentMonth}/${currentYear}`;
      } else if (e.length < 2 && e !== '0') {
        competencia = `0${e}/${currentYear}`;
      } else if (e.length >= 2 && e.length < 7) {
        competencia = `${e.slice(0, 2)}/${currentYear}`;
      }

      return competencia;
    }

    return e;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Resultado Veículo'
      loading={loading}
      onSearch={search}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.ButtonList
          text='Imprimir'
          icon='caret-down'
          items={[
            {
              text: 'Imprimir Resumo',
              icon: 'print',
              onClick: () => onPrintResumo(),
            },
            {
              text: 'Imprimir Agrupado',
              icon: 'print',
              onClick: () => onPrintAgrupado(),
            },
            {
              text: 'Imprimir Detalhe',
              icon: 'print',
              onClick: () => onPrintDetalhe(),
            },
            {
              text: 'Imprimir Detalhe Agrupado por Competência',
              icon: 'print',
              onClick: () => onPrintDespesas(),
            },
          ]}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Filtros' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-2'>
                <RadioButton
                  label='Tipo de Busca'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.tipoBusca}
                  buttons={tipoBuscaButtons}
                  onChange={(tipoBusca) => {
                    setFiltros({ ...filtros, tipoBusca });
                  }}
                />
              </div>
              <div className='col-2'>
                <Autocomplete
                  label='Placa'
                  searchDataSource={onSearchVeiculo}
                  selectedItem={filtros.veiculo}
                  onSelectItem={(veiculo) => {
                    setFiltros({
                      ...filtros,
                      veiculo,
                      nrSeqVeiculo: veiculo.nrSeqVeiculo,
                    });
                  }}
                  dataSourceTextProperty='placa'
                />
              </div>
              <div className='col'>
                <RadioButton
                  label='Buscar por'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.tipoPeriodo}
                  buttons={tipoPeriodoButtons}
                  onChange={(tipoPeriodo) => {
                    setFiltros({
                      ...filtros,
                      tipoPeriodo,
                      deMesAno: '',
                      ateMesAno: '',
                      dtSaidaIni: '',
                      dtSaidaFim: '',
                    });
                  }}
                />
              </div>
              {filtros.tipoPeriodo === 'C' ? (
                <>
                  <div className='col-2'>
                    <Textbox
                      required
                      label='De Mês/Ano'
                      maxLength={7}
                      mask={MaskTypes.Competence}
                      text={filtros.deMesAno}
                      onChangedValue={(deMesAno) => {
                        setFiltros({ ...filtros, deMesAno });
                      }}
                      onBlur={(_, value) => {
                        const deMesAno = onBlurCompetencia(value);

                        setFiltros({ ...filtros, deMesAno });
                      }}
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      required
                      label='Até Mês/Ano'
                      maxLength={7}
                      mask={MaskTypes.Competence}
                      text={filtros.ateMesAno}
                      onChangedValue={(ateMesAno) => {
                        setFiltros({ ...filtros, ateMesAno });
                      }}
                      onBlur={(_, value) => {
                        const ateMesAno = onBlurCompetencia(value);

                        setFiltros({ ...filtros, ateMesAno });
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className='col'>
                    <DatePicker
                      required
                      label='Data Inicial'
                      text={filtros.dtSaidaIni}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtSaidaIni) => {
                        setFiltros({ ...filtros, dtSaidaIni });
                      }}
                    />
                  </div>
                  <div className='col'>
                    <DatePicker
                      required
                      label='Data Final'
                      text={filtros.dtSaidaFim}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtSaidaFim) => {
                        setFiltros({ ...filtros, dtSaidaFim });
                      }}
                    />
                  </div>
                </>
              )}

              <div className='col'>
                <RadioButton
                  label='Ordenação'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.sortBy}
                  buttons={ordenacaoButtons}
                  onChange={(sortBy) => {
                    setFiltros({ ...filtros, sortBy });
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-4'>
                <RadioButton
                  label='Filtro Veículos'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.filtroVeiculoProprio}
                  buttons={vehicleOnwnershipButtons}
                  onChange={(filtroVeiculoProprio) => {
                    setFiltros({
                      ...filtros,
                      filtroVeiculoProprio,
                    });
                  }}
                />
              </div>
              <div className='col-3'>
                <DropdownMulti
                  label='Centro de Custo'
                  ref={dropDownCentroDeCusto}
                  dataSourcePropertyText='noCentroDeCusto'
                  dataSourcePropertyValue='nrSeqCentroDeCusto'
                  selectedItems={selectedItems.centroDeCusto ?? []}
                  onSelectItem={(centroDeCusto) => {
                    setSelectedItems({
                      ...selectedItems,
                      centroDeCusto,
                    });
                    const ids = centroDeCusto.map((el) =>
                      Number(el.nrSeqCentroDeCusto)
                    );
                    setFiltros({
                      ...filtros,
                      centroDeCustoIds: ids,
                    });
                  }}
                />
              </div>
              <div className='col-4'>
                <RadioButton
                  label='Tipo de busca'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.filtroTipoDeBusca}
                  buttons={TipoDeBusca}
                  onChange={(filtroTipoDeBusca) => {
                    setFiltros({
                      ...filtros,
                      filtroTipoDeBusca,
                    });
                  }}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>

      <div className='row mb-3'>
        <div className='col'>
          <GridView
            canControlVisibility
            enableExcelExport
            gridSizeList={[700, 1000, 1500, 2000, 5000]}
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
