import React, { useRef, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  GridView,
  Switch,
  FontAwesomeIcon,
} from 'ui/components';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { getAuditoriaContaRecurso } from 'core/services/TEL/contaRecurso';

export default function ModalResumoCustos({ recurso, show, onClose }) {
  const [filters, setFilters] = useState({ flgApenasDiferenteDeZero: true });
  const gridViewResumo = useRef();
  const [loading, setLoading] = useState(false);
  const columnsChips = [
    {
      key: 'nrSeqContaRectTipoChamada',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'tipoChamada.noTipoChamada',
      title: 'Tipo Chamada',
      visible: true,
    },
    {
      key: 'valorTotal',
      title: 'Total Original',
      visible: true,
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrRecalculado',
      title: 'Total Recalculado',
      visible: true,
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrRateio',
      title: 'Total Rateio',
      visible: true,
      format: GridView.DataTypes.Decimal,
    },
  ];

  const loadGrid = async () => {
    setLoading(true);
    if (recurso > 0) {
      const { auditoria } = await getAuditoriaContaRecurso(recurso);
      let data = auditoria;
      if (filters.flgApenasDiferenteDeZero) {
        data = auditoria.filter(
          (obj) =>
            obj.valorTotal !== 0 ||
            obj.vlrRecalculado !== 0 ||
            obj.vlrRateio !== 0
        );

        if (data.length > 0) {
          setFilters({ ...filters, nrLinha: auditoria[0].nrLinha });
        } else {
          setFilters({ ...filters, nrLinha: '' });
        }
      }

      gridViewResumo.current.setDataSource(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    (async function load() {
      loadGrid();
    })();
  }, [filters.flgApenasDiferenteDeZero]);

  useEffect(() => {
    (async function load() {
      loadGrid();
    })();
  }, [recurso]);

  return (
    <Modal
      show={show}
      title='Resumo de Custos Original e Recalculado'
      onClose={onClose}
      size={BootstrapSizes.Large}
    >
      <Modal.Body className='p-4 pt-3'>
        <div className='row mb-3'>
          <div className='col-auto d-flex align-items-center'>
            <div className='widget-square bg-gradient-primary text-white p-3 rounded-2'>
              <FontAwesomeIcon className='fs-1' icon='phone' />
            </div>
          </div>
          <div className='col d-flex align-items-center'>
            <div className='fw-bold text-primary'>
              <div>Linha:</div>
              <span className='display-7 fw-bold'>{filters.nrLinha}</span>
            </div>
          </div>

          <div className='col-auto'>
            <Switch
              formControl
              checked={!filters.flgApenasDiferenteDeZero}
              label='Motrar Todos'
              onChange={(flgApenasDiferenteDeZero) =>
                setFilters({
                  ...filters,
                  flgApenasDiferenteDeZero: !flgApenasDiferenteDeZero,
                })
              }
            />
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridViewResumo}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnsChips}
              offlineData
              showPagination={false}
              showSelectSizes={false}
              sumFields
              loading={loading}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Sair'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            setFilters({ ...filters, flgApenasDiferenteDeZero: true });
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
