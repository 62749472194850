import React, { useState, useRef, useEffect } from 'react';
import { Themes } from 'ui/Helpers/utils';

export class Step {
  constructor(jsonObject = {}) {
    this.index = jsonObject.index;
    this.title = jsonObject.title;
    this.description = jsonObject.description;
    this.content = jsonObject.content;
    this.onSubmit = jsonObject.onSubmit;
    this.onValidate = jsonObject.onValidate;
  }
}

export default function Steps(prop) {
  const { steps, theme, align, registryKey, data, setData } = prop;
  const formRef = useRef();
  const [active, setActive] = useState(steps[0]);

  const stepsClass = ['steps'];
  if (Themes.includes(theme)) stepsClass.push(`steps-${theme}`);

  useEffect(() => {
    setActive(steps[0]);
  }, [registryKey]);

  return (
    <div className={stepsClass.join(' ')}>
      <div
        className={
          align === 'vertical' ? 'step-header-vertical' : 'step-header'
        }
      >
        {steps?.map((step, idx) => (
          <>
            {step.visible && (
              <div
                role='presentation'
                className={`step${align}${
                  step.index === active.index ? ' visited' : ''
                }`}
                key={step.index}
                onClick={() => {
                  setActive(step);
                }}
              >
                {step.index && <div className='step-index'>{step.index}</div>}
                {step.title && <div className='step-title'>{step.title}</div>}
                {step.description && (
                  <div className='step-description'>{step.description}</div>
                )}
                {idx >= 0 && (
                  <div
                    ref={formRef}
                    className={`step-fill${
                      step.index === active.index ? ' visited' : ''
                    }`}
                  />
                )}
              </div>
            )}
          </>
        ))}
      </div>
      <div>
        <div className='step-content'>
          {React.createElement(active?.content, { data, setData })}
        </div>
      </div>
    </div>
  );
}
