import React, { useCallback, useEffect, useRef, useState } from 'react';
import Tipoinfrator from 'core/models/FRO/tipoinfrator';
import InfracaoGravidade from 'core/models/FRO/infracaoGravidade';
import {
  deleteInfracao,
  getInfracao,
  saveInfracao,
} from 'core/services/FRO/infracao';
import { getTipoInfratorAutoComplete } from 'core/services/FRO/tipoinfrator';
import { getInfracaoGravidadeAutoComplete } from 'core/services/FRO/infracaoGravidade';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { Autocomplete, CSDManutPage, Switch, Textbox } from 'ui/components';
import { Message, Page } from 'ui/Helpers/interfaces';
import Infracao from 'core/models/FRO/infracao';

export default function InfracaoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit: any = useRef();

  const [infracao, setInfracao] = useState<Infracao | {}>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const load = useCallback(async (): Promise<void> => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      setInfracao(await getInfracao(registryKey));

      setLoading(false);
    } else {
      setInfracao({ flgAtivo: true });
    }
  }, [registryKey]);

  useEffect((): void => {
    load();
  }, [load, registryKey, reload]);

  const onSalvar = async (): Promise<void> => {
    setLoading(true);

    const { status, message: msg } = await saveInfracao(infracao);

    const isStatusSuccess = status === ResponseStatus.Success;

    if (isStatusSuccess) {
      formSubmit.current.reset();
    }

    setInfracao(isStatusSuccess ? {} : infracao);

    setMessage({
      message: msg,
      theme: isStatusSuccess ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onExcluir = async (): Promise<void> => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const { status, message: msg } = await deleteInfracao(registryKey);

      const isStatusSuccess = ResponseStatus.Success === status;

      setInfracao(isStatusSuccess ? {} : infracao);

      setMessage({
        message: msg,
        theme: isStatusSuccess ? Theme.Success : Theme.Danger,
      });

      setLoading(false);
    }
  };

  const onBuscarTipoInfrator = async (): Promise<Array<Tipoinfrator>> => {
    const {
      tiposinfrator,
    }: {
      tiposinfrator: Array<Tipoinfrator>;
    } = await getTipoInfratorAutoComplete();

    return tiposinfrator;
  };

  const onBuscarGravidadeInfracao = async (): Promise<
    Array<InfracaoGravidade>
  > => {
    const {
      infracaogravidades,
    }: {
      infracaogravidades: Array<InfracaoGravidade>;
    } = await getInfracaoGravidadeAutoComplete();

    return infracaogravidades;
  };

  const { id: idSelecao } = PageTypes.Selection;

  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção descrição da infração'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setInfracao({ flgAtivo: true })}
      onSave={onSalvar}
      onDelete={
        'nrSeqInfracao' in infracao && infracao?.nrSeqInfracao > 0
          ? onExcluir
          : null
      }
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row mb-4'>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox
            label='Código'
            maxLength={20}
            text={'cdInfracao' in infracao ? infracao?.cdInfracao : null}
            onChangedValue={(cdInfracao: string) =>
              setInfracao({ ...infracao, cdInfracao })
            }
          />
        </div>
        <div className='col-6'>
          {/* @ts-expect-error */}
          <Textbox
            label='Descrição'
            maxLength={300}
            text={'noInfracao' in infracao ? infracao?.noInfracao : null}
            onChangedValue={(noInfracao: string) =>
              setInfracao({ ...infracao, noInfracao })
            }
          />
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col-3'>
          <Autocomplete
            label='Infrator'
            searchDataSource={onBuscarTipoInfrator}
            selectedItem={
              'tipoInfrator' in infracao ? infracao.tipoInfrator : null
            }
            onSelectItem={(tipoInfrator: Tipoinfrator) =>
              setInfracao({
                ...infracao,
                tipoInfrator,
                nrSeqTipoInfrator: tipoInfrator.nrSeqTipoInfrator,
              })
            }
            dataSourceTextProperty='noTipoInfrator'
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Gravidade'
            searchDataSource={onBuscarGravidadeInfracao}
            selectedItem={
              'infracaoGravidade' in infracao
                ? infracao.infracaoGravidade
                : null
            }
            onSelectItem={(infracaoGravidade: InfracaoGravidade) =>
              setInfracao({
                ...infracao,
                infracaoGravidade,
                nrSeqInfracaoGravidade:
                  infracaoGravidade.nrSeqInfracaoGravidade,
              })
            }
            dataSourceTextProperty='noInfracaoGravidade'
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Switch
            label='Ativo'
            formControl
            checked={'flgAtivo' in infracao && infracao?.flgAtivo}
            onChange={(flgAtivo: boolean) =>
              setInfracao({ ...infracao, flgAtivo })
            }
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
