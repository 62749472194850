import React, { useState, useRef, useEffect } from 'react';
import {
  ResponseStatus,
  BootstrapSizes,
  Theme,
  PageTypes,
} from 'ui/Helpers/utils';
import {
  Autocomplete,
  CSDSelPage,
  GridView,
  Textbox,
  RadioButton,
  ToolbarButtons,
} from 'ui/components';
import { getEmpresasUsuario } from 'core/services/SEG/empresa';
import {
  getChipAutoComplete,
  getOperadoraAutoComplete,
  search,
  printChips,
  excelChips,
} from 'core/services/TEL';
import ModalTrocarEmpresa from './modalTrocarEmpresa';
import ModalImportacaoChip from './modalImportacaoChip';
import { TipoChip, StatusChip, SituacaoChip } from './enums';

const Situacao = [
  { text: 'Todos', value: 'T' },
  { text: 'Em Uso', value: 'A' },
  { text: 'Em Estoque', value: 'E' },
  { text: 'Reserva', value: 'R' },
  { text: 'Cancelado', value: 'C' },
];

const Tipo = [
  { text: 'Todos', value: 'O' },
  { text: 'Movel', value: 'M' },
  { text: 'Fixo', value: 'F' },
  { text: 'Ramal', value: 'R' },
  { text: 'Link Net', value: 'L' },
  { text: 'Too-Free', value: 'T' },
];

const Status = [
  { text: 'Todos', value: 'T' },
  { text: 'Ativo', value: 'A' },
  { text: 'Inativo', value: 'I' },
  { text: 'Virtual', value: 'V' },
];

export default function SelecaoChip({
  isActive,
  transaction,
  onOpenPage,
  onOpenReport,
}) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [filters, setFilters] = useState({
    flgStatus: StatusChip.Todos,
    noTipo: TipoChip.Todos,
    flgSituacao: SituacaoChip.Todos,
  });
  const gridView = useRef();
  const [showTrocaEmpresa, setShowTrocaEmpresa] = useState(false);
  const [showImportacao, setShowImportacao] = useState(false);
  const [linhasSelecionadas, setLinhasSelecionadas] = useState([]);
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Chip/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onSearchEmpresa = async (e) => {
    const { empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });
    return empresas;
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqChip = sourceRow.find((item) => item.key === 'nrSeqChip');
    onOpenMaintenance(nrSeqChip.value);
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearch = async () => {
    setLoading(true);
    const obj = {
      NrLinha: filters.nrLinha,
      NrPrefixo: filters.nrPrefixo?.nrPrefixo,
      NrSeqOperadora: filters.nrSeqOperadora,
      FlgSituacao: filters.flgSituacao,
      FlgStatus: filters.flgStatus,
      NoTipo: filters.noTipo === TipoChip.Todos ? null : filters.noTipo,
      NrICCID: filters.nrIccid,
      NrSeqEmpresa: filters.nrSeqEmpresa,
    };
    const { status, chips, pagination } = await search(obj);

    onSetMessage(status, message);

    if (gridView && gridView.current)
      gridView.current.setDataSource(chips, pagination);
    setLoading(false);
  };

  const onPageChange = async (page) => {
    setFilters({ ...filters, page });
    const obj = {
      NrLinha: filters.nrLinha,
      NrPrefixo: filters.nrPrefixo?.nrPrefixo,
      NrSeqOperadora: filters.nrSeqOperadora,
      FlgSituacao: filters.Situacao,
      FlgStatus: filters.Status,
      NoTipo: filters.noTipo === TipoChip.Todos ? null : filters.noTipo,
      NrICCID: filters.nrIccid,
      NrSeqEmpresa: filters.nrSeqEmpresa,
      Page: page,
    };

    const { status, chips, pagination } = await search(obj);

    onSetMessage(status, message);

    if (gridView && gridView.current)
      gridView.current.setDataSource(chips, pagination);
    setLoading(false);
  };

  const onPageSizeChange = async (totalByPage) => {
    setFilters({ ...filters, totalByPage });
    await onSearch({ ...filters, totalByPage });
  };

  const onNew = async () => {
    onSetMessage();
    setLoading(true);

    onOpenPage(
      transaction.noTransacao,
      'Chip/manutencao',
      PageTypes.Maintenence,
      null
    );
    setLoading(false);
  };

  const onSearchPrefixo = async (prefixo) => {
    let prefixosDistintos = [];
    let prefixos = [];
    const { status, chips } = await getChipAutoComplete({
      nrPrefixo: prefixo,
    });
    onSetMessage(status, message);

    prefixos = chips.map((e) => e.nrPrefixo);

    prefixosDistintos = Array.from(new Set(prefixos));
    return prefixosDistintos.map((e) => ({ nrPrefixo: e }));
  };

  const onSearchOperadora = async (e) => {
    const { status, operadoras } = await getOperadoraAutoComplete({
      noOperadora: e,
    });

    onSetMessage(status);
    return operadoras;
  };

  const searchEmpresas = async () => {
    const { status } = await getEmpresasUsuario({});
    onSetMessage(status);
  };

  const onPrint = async () => {
    setLoading(true);

    const obj = {
      NrLinha: filters.nrLinha,
      NrPrefixo: filters.nrPrefixo?.nrPrefixo,
      NrSeqOperadora: filters.nrSeqOperadora,
      FlgSituacao: filters.Situacao,
      FlgStatus: filters.Status,
      NoTipo: filters.noTipo === TipoChip.Todos ? null : filters.noTipo,
      NrICCID: filters.nrIccid,
      NrSeqEmpresa: filters.nrSeqEmpresa,
    };

    const { value } = await printChips(obj);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onExcel = async () => {
    setLoading(true);
    const obj = {
      NrLinha: filters.nrLinha,
      NrPrefixo: filters.nrPrefixo?.nrPrefixo,
      NrSeqOperadora: filters.nrSeqOperadora,
      FlgSituacao: filters.flgSituacao,
      FlgStatus: filters.flgStatus,
      NoTipo: filters.noTipo === TipoChip.Todos ? null : filters.noTipo,
      NrICCID: filters.nrIccid,
      NrSeqEmpresa: filters.nrSeqEmpresa,
    };

    const { value, status, message: msg } = await excelChips(obj);
    onSetMessage(status, msg);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `Chips.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const onTrocarEmpresa = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Nenhuma linha selecionada para trocar de empresa.'
      );
    else {
      const linhas = selecteds.map((column) => ({
        linhaFormatada: column.find((prop) => prop.key === 'linhaFormatada')
          .value,
        nrSeqChip: column.find((prop) => prop.key === 'nrSeqChip').value,
        cdChip: column.find((prop) => prop.key === 'cdChip').value,
        status: column.find((prop) => prop.key === 'statusChip').value,
        nriccid: column.find((prop) => prop.key === 'nrIccid').value,
        operadora: column.find((prop) => prop.key === 'operadora.noOperadora')
          .value,
        empresa: column.find((prop) => prop.key === 'empresa.noPessoa').value,
        situacao: column.find((prop) => prop.key === 'situacaoChip').value,
      }));
      setLinhasSelecionadas(linhas);
      setShowTrocaEmpresa(true);
    }
  };
  const columns = [
    { key: 'nrSeqChip', type: GridView.ColumnTypes.Checkbox },
    { key: 'flgSituacao', visible: false },
    { key: 'flgStatus', visible: false },
    { key: 'noTipo', visible: false },
    { key: 'cdChip', title: 'Código' },
    { key: 'linhaFormatada', title: 'Nr. Linha' },
    { key: 'statusChip', title: 'Status' },
    { key: 'nrIccid', title: 'ICCID' },
    { key: 'operadora.noOperadora', title: 'Operadora' },
    { key: 'empresa.noPessoa', title: 'Empresa' },
    { key: 'situacaoChip', title: 'Situação' },
  ];

  useEffect(() => {
    searchEmpresas();
  }, []);

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção Chip'
      loading={loading}
      onSearch={onSearch}
      onNew={onNew}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Excel'
          icon={['far', 'file-excel']}
          onClick={() => onExcel()}
        />
        <ToolbarButtons.ButtonList
          icon='caret-down'
          items={[
            {
              text: 'Inventário',
              onClick: onExcel,
              tooltip: '',
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Trocar de Empresa'
          icon='random'
          onClick={() => onTrocarEmpresa()}
        />
        <ToolbarButtons.Button
          text='Importar Chip'
          icon='cloud-upload-alt'
          onClick={() => setShowImportacao(true)}
        />
      </ToolbarButtons>
      <div className='card'>
        <div className='card-body'>
          <div className='mb-3'>
            <div className='row'>
              <div className='col-8'>
                <Autocomplete
                  label='Empresa'
                  searchDataSource={onSearchEmpresa}
                  selectedItem={filters.empresa}
                  onSelectItem={(empresa) =>
                    setFilters({
                      ...filters,
                      empresa,
                      nrSeqEmpresa: empresa.nrSeqEmpresa,
                    })
                  }
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-4'>
                <Textbox
                  label='ICCID'
                  onChangedValue={(nrIccid) =>
                    setFilters({
                      ...filters,
                      nrIccid,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className='mb-3'>
            <div className='row'>
              <div className='col-4'>
                <Autocomplete
                  label='Prefixo'
                  searchDataSource={onSearchPrefixo}
                  selectedItem={filters.nrPrefixo}
                  onSelectItem={(nrPrefixo) =>
                    setFilters({
                      ...filters,
                      nrPrefixo,
                    })
                  }
                  dataSourceTextProperty='nrPrefixo'
                />
              </div>
              <div className='col-4'>
                <Textbox
                  label='Número'
                  maxLength={11}
                  onChangedValue={(nrLinha) =>
                    setFilters({
                      ...filters,
                      nrLinha,
                    })
                  }
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  label='Operadora'
                  searchDataSource={onSearchOperadora}
                  selectedItem={filters.operadora}
                  onSelectItem={(operadora) =>
                    setFilters({
                      ...filters,
                      operadora,
                      nrSeqOperadora: operadora.nrSeqOperadora,
                    })
                  }
                  dataSourceTextProperty='noOperadora'
                />
              </div>
            </div>
          </div>
          <div className='mb-3'>
            <div className='row'>
              <div className='col-auto'>
                <RadioButton
                  outline
                  label='Situação'
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  buttons={Situacao}
                  selectedButton={filters.flgSituacao}
                  onChange={(flgSituacao) =>
                    setFilters({
                      ...filters,
                      flgSituacao,
                    })
                  }
                />
              </div>
              <div className='col-auto'>
                <RadioButton
                  outline
                  label='Tipo'
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  buttons={Tipo}
                  selectedButton={filters.noTipo}
                  onChange={(noTipo) =>
                    setFilters({
                      ...filters,
                      noTipo,
                    })
                  }
                />
              </div>
              <div className='col-auto'>
                <RadioButton
                  outline
                  label='Status'
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  buttons={Status}
                  selectedButton={filters.flgStatus}
                  onChange={(flgStatus) =>
                    setFilters({
                      ...filters,
                      flgStatus,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
      <ModalTrocarEmpresa
        linhasSelecionadas={linhasSelecionadas}
        show={showTrocaEmpresa}
        onClose={() => {
          setShowTrocaEmpresa(false);
        }}
        onSuccess={() => {
          setMessage({
            message: 'Troca de empresa realizada com sucesso.',
            theme: Theme.Success,
          });
          setShowTrocaEmpresa(false);
        }}
      />
      <ModalImportacaoChip
        show={showImportacao}
        onClose={() => {
          setShowImportacao(false);
        }}
        onSuccess={() => {
          setMessage({
            message: 'Chips importados com sucesso.',
            theme: Theme.Success,
          });
          setShowImportacao(false);
        }}
      />
    </CSDSelPage>
  );
}
