import React, { useRef, useState } from 'react';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import {
  Autocomplete,
  CSDSelPage,
  DatePicker,
  GridView,
  Panel,
  ToolbarButtons,
} from 'ui/components';
import {
  excelDetalhamento,
  getGerenciarIntegracaoPagined,
  printDetalhamento,
} from 'core/services/FIN/gerenciarIntegracaoBancaria';
import { MaskTypes } from 'ui/Helpers/masks';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN';
import ModalIntegracaoTarget from './integracaoTarget';

/* const columns = [
  { key: 'nrCartao', visible: false },
  {
    key: 'noOcorrencia',
    title: 'Retorno',
  },
  { key: 'vlrTituloPagar', title: 'Valor', format: GridView.DataTypes.Decimal },
  { key: 'dtCad', title: 'Data', format: GridView.DataTypes.Date },
  { key: 'cdContaCorrente', title: 'Nr Cartao' },
]; */

export default function GerenciarIngracaoBancaria({
  transaction,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [modalShow, setModalShow] = useState({});
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    const { data: obj } = await getGerenciarIntegracaoPagined(params ?? data);
    if (gridView && gridView.current) gridView.current.setDataSource(obj);
  };

  const onOpenIntegracaoTarget = async () => {
    setModalShow({
      ...modalShow,
      gerarLancamento: true,
    });
  };

  const onPrintDetalhamento = async (param) => {
    setLoading(true);
    const recibo = await printDetalhamento(param.nrSeqArquivoBancario);

    onOpenReport(transaction.noTransacao, recibo);
    setLoading(false);
  };

  const onExcelDetalhamento = async (param) => {
    setLoading(true);
    const value = await excelDetalhamento(param.nrSeqArquivoBancario);

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `GerenciarIntegracao.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const columns = [
    { key: 'nrSeqArquivoBancario', visible: false },
    {
      key: 'nrSequencia',
      title: 'Sequencia',
    },
    { key: 'dtLancamento', title: 'Data', format: GridView.DataTypes.Date },
    { key: 'banco.noBanco', title: 'Banco' },
    { key: 'formaPagamento.noFormaPagamento', title: 'Forma Pagamento' },
    {
      key: 'flgRetorno',
      title: 'Arq. Retorno',
      format: GridView.DataTypes.Boolean,
    },
    {
      title: 'Detalhar',
      key: 'nrSeqArquivoBancario',
      type: GridView.ColumnTypes.Button,
      theme: Theme.Warning,
      onClick: (e, datasource) => onPrintDetalhamento(e, datasource),
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Detalhar',
      tooltipDirection: 'bottom',
    },
    {
      title: 'Det. Excel',
      key: 'nrSeqArquivoBancario',
      type: GridView.ColumnTypes.Button,
      theme: Theme.Warning,
      onClick: (e, datasource) => onExcelDetalhamento(e, datasource),
      icon: ['far', 'file-excel'],
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Det. Excel',
      tooltipDirection: 'bottom',
    },
    {
      key: '',
      title: 'Download',
    },
    {
      key: '',
      title: 'Imprimir',
    },
    {
      key: '',
      title: 'Excel',
    },
  ];

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitPag: true,
    });
    if (msg) setMessage(status, msg);
    return formasPagamento;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Gerenciador Integração Bancaria'
      loading={loading}
      onSearch={() => search()}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Gera integração'
          icon='envelope'
          onClick={onOpenIntegracaoTarget}
        />
      </ToolbarButtons>
      <Panel>
        <Panel.Header
          icon={['fas', 'user']}
          title='Filtros'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col-4 mb-3'>
              <Autocomplete
                label='Forma Pagamento'
                searchDataSource={onSearchFormaPagamento}
                selectedItem={data.formaPagamento}
                onSelectItem={(formaPagamento) => {
                  setData({
                    ...data,
                    formaPagamento,
                    nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                  });
                }}
                dataSourceTextProperty='noFormaPagamento'
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Enviado De'
                text={data.dataPagamentoDe}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dataPagamentoDe) =>
                  setData({ ...data, dataPagamentoDe })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Enviado Até'
                text={data.dataPagamentoAte}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dataPagamentoAte) =>
                  setData({ ...data, dataPagamentoAte })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
      <ModalIntegracaoTarget
        show={modalShow.gerarLancamento}
        transaction={transaction}
        gerarLancamento={modalShow.gerarLancamento}
        onSave={(gerarLancamento) => {
          setData({ ...data, gerarLancamento });
          setModalShow({
            ...modalShow,
            gerarLancamento: modalShow.gerarLancamento,
          });
        }}
        onClose={({ message: msg, theme }) => {
          setModalShow({
            ...modalShow,
            gerarLancamento: false,
          });
          if (msg != null) {
            setMessage({
              message: msg,
              theme,
            });
          }
        }}
      />
    </CSDSelPage>
  );
}
