export default class PneuCalibragem {
  constructor(jsonObject = {}) {
    this.nrSeqPneuCalibragem = jsonObject.nrSeqPneuCalibragem;
    this.nrSeqPneu = jsonObject.nrSeqPneu;
    this.dtRegistro = jsonObject.dtRegistro;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;

    // Instancias
    this.usuarioCad = jsonObject.usuarioCad;
    this.veiculo = jsonObject.veiculo;
  }

  toJson = () => JSON.stringify(this);
}
