import {
  ImportarRelatorioOperadora,
  UpdateDemandasImportadas,
} from 'core/services/HELP';
import React, { useState, useRef, useEffect } from 'react';
import {
  BootstrapSizes,
  MimeTypes,
  Theme,
  ResponseStatus,
} from 'ui/Helpers/utils';

import {
  Panel,
  Notification,
  FileUpload,
  Loader,
  Button,
  GridView,
  Modal,
} from 'ui/components';

export default function ModalImportarRelatorioOperadora({ show, onClose }) {
  const gridView = useRef();
  const gridViewForUnregistered = useRef();
  const [importedFile, setImportedFile] = useState([]);
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState();
  const [showGrid, setShowGrid] = useState({
    showUpdatable: false,
    showUnregistered: false,
  });
  const [gridItens, setGridItens] = useState([]);

  const onCloseModal = () => {
    setShowGrid({ showUpdatable: false, showUnregistered: false });
    setImportedFile([]);
    setGridItens([]);
    onClose();
  };

  useEffect(() => {
    if (showGrid.showUnregistered) {
      gridViewForUnregistered?.current?.setDataSource(gridItens);
    }

    if (showGrid.showUpdatable) {
      gridView?.current?.setDataSource(gridItens);
    }
  }, [showGrid]);

  const updateDemandasImportadas = async () => {
    setLoading(true);

    let selecteds = gridView.current.getCheckBoxValuesAt(0);
    const itens = gridView.current.getDataSource();

    selecteds = selecteds.map(
      (column) => column.find((prop) => prop.key === 'nrSeqDemanda').value
    );

    let filteredItens = itens.filter((demanda) =>
      selecteds.includes(demanda.nrSeqDemanda)
    );

    const redItens = filteredItens.filter(
      (item) => item.noCor === 'text-danger'
    );

    if (redItens.length > 0) {
      setMessage({
        theme: Theme.Danger,
        message:
          'Existe demandas que não podem ser atualizadas selecionadas. Desmaque a demanda para atualizar',
      });
    } else {
      const demandas = filteredItens.map((item) => ({
        nrSeqDemanda: item.nrSeqDemanda,
        nrSeqStatusDemanda: item.nrSeqStatusDemanda,
        nrSeqStatusDemandaNovo: item.nrSeqStatusDemandaNovo,
        nrOportunidade: item.nrOportunidade,
        noIteracoes: item.noIteracoes,
        dtEnvioOperadora: item.dtEnvioOperadora,
      }));

      const { status, message: msg } = await UpdateDemandasImportadas(demandas);

      if (status === ResponseStatus.Success) {
        filteredItens = itens.filter(
          (demanda) => !selecteds.includes(demanda.nrSeqDemanda)
        );

        gridView.current.setDataSource(filteredItens);
      }

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    setLoading(false);
  };

  const uploadFile = async () => {
    setLoading(true);

    if (importedFile.length === 0)
      setMessage({
        message: 'Não há arquivos selecionados para importação.',
        theme: Theme.Danger,
      });
    else {
      const { status, message: msg, value } = await ImportarRelatorioOperadora(
        importedFile[0]
      );

      if (status === ResponseStatus.Success) {
        if (value.length > 0) {
          if (value[0].nrSeqDemanda > 0) {
            setGridItens(value);
            setShowGrid({ showUpdatable: true, showUnregistered: false });
          } else {
            setGridItens(value);
            setShowGrid({
              showUpdatable: false,
              showUnregistered: true,
            });
          }
          setMessage({
            message: msg,
            theme: Theme.Success,
          });
        }

        if (value.length === 0) {
          setMessage({
            message: 'As demandas dos últimos 60 dias estão atualizadas',
            theme: Theme.Success,
          });
        }
      } else {
        setMessage({
          message: msg,
          theme: Theme.Danger,
        });
      }
    }

    setLoading(false);
  };

  const columns = [
    { key: 'nrSeqDemanda', type: GridView.ColumnTypes.Checkbox },
    { key: 'nrSeqDemanda', title: 'Contrato', colorText: 'noCor' },
    { key: 'chip.nrPrefixoLinha', title: 'Linha', colorText: 'noCor' },
    { key: 'cliente.noPessoa', title: 'Cliente', colorText: 'noCor' },
    {
      key: 'dtEnvioOperadora',
      title: 'Data de Envio para Operadora',
      format: GridView.DataTypes.Date,
      colorText: 'noCor',
    },
    { key: 'noStatusDemanda', title: 'Status', colorText: 'noCor' },
    {
      key: 'noStatusDemandaNovo',
      title: 'Status Importado',
      colorText: 'noCor',
    },
  ];

  const columnsForUnregistered = [
    { key: 'chip.nrPrefixoLinha', title: 'Linha' },
    { key: 'chip.nrIccid', title: 'ICCID' },
    { key: 'chipNovo.nrPrefixoLinha', title: 'Linha Provisória' },
    {
      key: 'dtEnvioOperadora',
      title: 'Data de Envio para Operadora',
      format: GridView.DataTypes.Date,
    },
    { key: 'noObservacao', title: 'Observação' },
  ];

  return (
    <Modal
      show={show}
      title='Importar Relatório da Operadora'
      onClose={onCloseModal}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body>
        <Loader loading={loading} />
        <Panel>
          <Panel.Header
            title='Importar Relatório da Operadora'
            theme={Theme.Primary}
            icon='cloud-upload-alt'
            size={BootstrapSizes.Large}
          />
          <Panel.Body>
            {message && (
              <div className='mb-3'>
                <Notification
                  floatable
                  message={message.message}
                  theme={message.theme}
                  onClose={() => setMessage(null)}
                />
              </div>
            )}
            <div className='row mb-3'>
              <div className='col mt-3'>
                <FileUpload
                  files={importedFile}
                  onChange={(files) => setImportedFile(files)}
                  allowedMimeTypes={[MimeTypes.Types.Excel]}
                />
              </div>
            </div>
            <div className='row'>
              <Panel visible={showGrid.showUnregistered}>
                <Panel.Header
                  title='Demandas Sem Registro'
                  theme={Theme.Danger}
                />
                <Panel.Body>
                  <div className='row'>
                    <div className='col'>
                      <p>
                        Não foi possivel identificar uma demanda para{' '}
                        {gridItens.length} linhas
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <GridView
                        enableExcelExport
                        ref={gridViewForUnregistered}
                        className='table-striped table-hover table-bordered table-sm'
                        dataColumns={columnsForUnregistered}
                        showSelectSizes={false}
                      />
                    </div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
            <div className='row'>
              <Panel visible={showGrid.showUpdatable}>
                <Panel.Header
                  title='Demandas Para Atualizar'
                  theme={Theme.Success}
                />
                <Panel.Body>
                  <div className='row'>
                    <div className='col'>
                      <p>
                        Foram identificadas {gridItens.length} demandas para
                        atualizar nos últimos 60 dias.
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <GridView
                        enableExcelExport
                        ref={gridView}
                        className='table-striped table-hover table-bordered table-sm'
                        dataColumns={columns}
                        showSelectSizes={false}
                      />
                    </div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
          </Panel.Body>
        </Panel>
      </Modal.Body>
      <Modal.Footer>
        {showGrid.showUpdatable ? (
          <Button
            size={BootstrapSizes.Medium}
            icon='cloud-upload-alt'
            theme={Theme.Success}
            template={Button.Templates.Default}
            onClick={updateDemandasImportadas}
            text='Atualizar Demandas'
          />
        ) : (
          <Button
            size={BootstrapSizes.Medium}
            icon='cloud-upload-alt'
            theme={Theme.Success}
            template={Button.Templates.Default}
            onClick={uploadFile}
            text='Importar'
          />
        )}
        <Button
          size={BootstrapSizes.Medium}
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          onClick={onCloseModal}
          text='Cancelar'
        />
      </Modal.Footer>
    </Modal>
  );
}
