export default class TipoRecebimento {
  constructor(jsonObject = {}) {
    this.flgJuro = jsonObject.flgJuro;
    this.flgMulta = jsonObject.flgMulta;
    this.nrSeqTipoRecebimento = jsonObject.nrSeqTipoRecebimento;
    this.flgEstorno = jsonObject.flgEstorno;
    this.flgSaldo = jsonObject.flgSaldo;
    this.noTipoRecebimento = jsonObject.noTipoRecebimento;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.cdTipoRecebimento = jsonObject.cdTipoRecebimento;
  }

  toJson = () => JSON.stringify(this);
}
