import qs from 'qs';
import Pagination from 'core/models/pagination';
import EnergiaConta from 'core/models/ENE/energiaConta';
import api from '../api';

const baseURL = `/ENE/EnergiaConta`;

export const getEnergiaContaList = async (filters: any) => {
  const response = await api.get(`/$EnergiaConta/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new EnergiaConta(json)) ?? [],
  };
};

export const getEnergiaContaPagined = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new EnergiaConta(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getEnergiaConta = async (id: number) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new EnergiaConta(value) : {};
};

export const getEnergiaContaAutoComplete = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchAutoComplete`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    contas: value?.map((json: any) => new EnergiaConta(json)) ?? [],
  };
};

export const saveEnergiaConta = async (data: EnergiaConta) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteEnergiaConta = async (id: number) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printEnergiaConta = async (filters: any) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelEnergiaConta = async (filters: any) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllEnergiaConta = async (ids: number[]) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
