import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getDeParaEncargoConvenioPagined,
  printDeParaEncargoConvenio,
  deleteAllDeParaEncargoConvenio,
} from 'core/services/ASS/deParaEncargoConvenio';
import { getConvenioAutoComplete } from 'core/services/ASS/convenio';
import { getConvenioEncargoAutoComplete } from 'core/services/ASS/convenioEncargo';
import { CSDSelPage, GridView, Autocomplete } from 'ui/components';

const columns = [
  { key: 'nrSeqDeParaEncargoConvenio', type: GridView.ColumnTypes.Checkbox },
  {
    key: 'noDeParaEncargoConvenio',
    title: 'De Para Encargo Convênio',
    sortKey: 'noDeParaEncargoConvenio',
  },
  { key: 'convenio.noConvenio', title: 'Convênio', sortKey: 'NoConvenio' },
  {
    key: 'convenioEncargo.noConvenioEncargo',
    title: 'Convênio Encargo',
    sortKey: 'NoConvenioEncargo',
  },
];

export default function deParaEncargoConvenio({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getDeParaEncargoConvenioPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getDeParaEncargoConvenioPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'DeparaEncargoConvenio/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqDeParaEncargoConvenio = sourceRow.find(
      (e) => e.key === 'nrSeqDeParaEncargoConvenio'
    );
    onOpenMaintenance(nrSeqDeParaEncargoConvenio.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printDeParaEncargoConvenio(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllDeParaEncargoConvenio(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchConvenio = async (e) => {
    const { status, message: msg, convenios } = await getConvenioAutoComplete({
      noConvenio: e,
    });
    if (msg) setMessage(status, msg);
    return convenios;
  };

  const onSearchConvenioEncargo = async (e) => {
    const {
      status,
      message: msg,
      data: convenioEncargos,
    } = await getConvenioEncargoAutoComplete({ noConvenioEncargo: e });
    if (msg) setMessage(status, msg);
    return convenioEncargos;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='De Para Encargo Convênio'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-3 mb-4'>
          <Autocomplete
            label='Convênio'
            searchDataSource={onSearchConvenio}
            selectedItem={filtros.convenio}
            onSelectItem={(convenio) => {
              setFiltros({
                ...filtros,
                convenio,
                nrSeqConvenio: convenio.nrSeqConvenio,
              });
            }}
            dataSourceTextProperty='noConvenio'
          />
        </div>
        <div className='col-3 mb-4'>
          <Autocomplete
            label='Convenio Encargo'
            searchDataSource={onSearchConvenioEncargo}
            selectedItem={filtros.convenioEncargo}
            onSelectItem={(convenioEncargo) => {
              setFiltros({
                ...filtros,
                convenioEncargo,
                nrSeqConvenioEncargo: convenioEncargo.nrSeqConvenioEncargo,
              });
            }}
            dataSourceTextProperty='noConvenioEncargo'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
            enableExcelExport
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
