import React, { useState, useRef, useEffect } from 'react';

import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  Loader,
  Modal,
  Notification,
  GridView,
} from 'ui/components';

import { changeClient } from 'core/services/TEL/clienteLinha';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';

export default function ModalAlterarCliente({ recurso, show, onClose }) {
  const gridViewAlterarCliente = useRef();
  const [data, setData] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const columnsLinhas = [
    { key: 'nrSeqClienteLinha', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'noPessoa',
      title: 'Cliente',
    },
    {
      key: 'nroFoneCompleto',
      title: 'Nr. Linha',
    },
    {
      key: 'codNoCentroCusto',
      title: 'Centro de Custo Atual',
    },
    {
      key: 'noPlano',
      title: 'Plano',
    },
    {
      key: 'dtAquisicao',
      title: 'Dt Aquis.',
    },
    {
      key: 'flgSituacaoStr',
      title: 'Situação',
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return clientes;
  };

  useEffect(() => {
    (async function load() {
      if (recurso.length > 0)
        gridViewAlterarCliente.current.setDataSource(recurso);
    })();
  }, [recurso]);

  const onSend = async () => {
    setLoading(true);
    const nrSeqClientesLinhas = recurso.map(
      (column) => column.nrSeqClienteLinha
    );

    nrSeqClientesLinhas.push(data.nrSeqPessoaCli);

    const { status, message: msg } = await changeClient(nrSeqClientesLinhas);
    setLoading(false);
    onSetMessage(status, msg);
  };

  return (
    <Modal
      show={show}
      title='Troca Cliente'
      onClose={onClose}
      size={BootstrapSizes.Large}
      icon={['far', 'comment-alt']}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col'>
            <Autocomplete
              label='Cliente'
              searchDataSource={onSearchCliente}
              selectedItem={data.cliente}
              onSelectItem={(cliente) =>
                setData({
                  ...data,
                  cliente,
                  nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                })
              }
              dataSourceTextProperty='noPessoa'
            />
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridViewAlterarCliente}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnsLinhas}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Salvar'
          theme={Theme.Success}
          icon='sms'
          onClick={onSend}
        />
        <Button
          text='Sair'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            setData({});
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
