import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  Panel,
  Textarea,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { deleteConvenioExtrato } from 'core/services/ASS/convenioExtrato';
import {
  getLancamentoAssociado,
  saveLancamentoManual,
} from 'core/services/ASS/lancamentoAssociado';
import { getVencimentoAutoComplete } from 'core/services/ASS/vencimento';
import { getConvenioAutoComplete } from 'core/services/ASS/convenio';
import {
  getAssociadoList,
  getAssociado,
  getAssociadoAutoCompleteConv,
} from 'core/services/ASS/associado';
import { getPlanoConvenioEncargoAutoComplete } from 'core/services/ASS/planoConvenioConvenioEncargo';

export default function LancamentoAssociadoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);

      const associado = await getLancamentoAssociado(registryKey);

      setData({ ...data, ...associado });
      setLoading(false);
    } else {
      setData({ recalcular: false });
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const calculaVlrAjuste = (lancamento, vlrRecalculado) => {
    const planoConv =
      lancamento.associado &&
      lancamento.associado.associadosPlanosConvenios &&
      lancamento.associado.associadosPlanosConvenios.length > 0
        ? // eslint-disable-next-line no-use-before-define
          lancamento.associado.associadosPlanosConvenios[0]
        : null;

    const matricula =
      planoConv && planoConv.associadoMatricula
        ? planoConv && planoConv.associadoMatricula
        : null;

    if (!matricula) return vlrRecalculado;

    const vlrAjuste = matricula.vlrAjuste || 0;

    const vlrAjusteMatriculaAss = matricula.flgAjustePercentual
      ? (vlrRecalculado * vlrAjuste) / 100
      : vlrAjuste;

    return vlrAjusteMatriculaAss;
  };

  const recalculaValor = async () => {
    setLoading(true);
    let vlrRecalculado = 0;
    let planoEncargoConv = null;

    const {
      message: msg,
      status,
      data: plano,
    } = await getPlanoConvenioEncargoAutoComplete({
      nrSeqConvenio: data.nrSeqConvenio,
      nrSeqPlanoConvEncargoConv: data.nrSeqPlanoConvEncargoConv,
    });
    if (status === ResponseStatus.Error && msg) onSetMessage(status, msg);
    else {
      planoEncargoConv = plano && plano.length > 0 ? plano[0] : null;

      const vlrOriginal =
        data.vlrOriginal && data.vlrOriginal > planoEncargoConv?.vlrOriginal
          ? data.vlrOriginal
          : planoEncargoConv.vlrOriginal;

      if (data.convenio?.flgTelefonia) {
        vlrRecalculado = data.vlrRecalculado ?? 0;
      } else if (planoEncargoConv) {
        if (planoEncargoConv?.flgPercentual) {
          vlrRecalculado =
            vlrOriginal +
            (vlrOriginal * planoEncargoConv?.vlrRecalculado) / 100;
        } else if (planoEncargoConv.flgRecalculo_IncluiVlrOriginal) {
          vlrRecalculado = vlrOriginal + planoEncargoConv.vlrRecalculado;
        } else {
          vlrRecalculado = planoEncargoConv.vlrRecalculado;
        }
      } else {
        vlrRecalculado = vlrOriginal;
      }

      vlrRecalculado += calculaVlrAjuste(data, vlrRecalculado);

      setData({
        ...data,
        planoConvenioEncargo: planoEncargoConv ?? data.planoConvenioEncargo,
        vlrRecalculado,
        recalcular: false,
      });
    }
    setLoading(false);
  };

  useEffect(async () => {
    if (data.recalcular) await recalculaValor();
  }, [data.nrSeqPlanoConvEncargoConv]);

  function validateForm(form) {
    let msg = '';

    switch (true) {
      case !form.nrSeqConvenio:
        msg = 'Convênio é um campo obrigatório.';
        break;
      case !form.nrSeqPessoaAss:
        msg = 'Associado é um campo obrigatório.';
        break;
      case !form.anoMes:
        msg = 'Ano/Mes é um campo obrigatório.';
        break;
      case !form.vlrOriginal:
        msg = 'Valor Original é um campo obrigatório.';
        break;
      case !form.vlrRecalculado:
        msg = 'Valor Recalculado é um campo obrigatório.';
        break;
      case !form.nrSeqPlanoConvEncargoConv:
        msg = 'Plano Encargo é um campo obrigatório.';
        break;
      case (data.nrSeqConvenioExtrato != null ||
        data.nrSeqConvenioExtrato > 0) &&
        !form.noMotivoAlteracao:
        msg = 'Necessário adicionar o motivo para alteração do lançamento.';
        break;
      default:
        msg = null;
    }
    return msg;
  }

  const save = async () => {
    setLoading(true);

    const msgValidate = validateForm(data);
    if (msgValidate) {
      onSetMessage(ResponseStatus.Error, msgValidate);
      setLoading(false);
      return;
    }

    const { status, message: msg } = await saveLancamentoManual(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteConvenioExtrato(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchVencimento = async (e) => {
    const {
      status,
      message: msg,
      vencimentos,
    } = await getVencimentoAutoComplete({ noVencimento: e });
    if (msg) onSetMessage(status, msg);
    return vencimentos;
  };

  const onSearchConvenio = async (e) => {
    const { status, message: msg, convenios } = await getConvenioAutoComplete({
      noConvenio: e,
    });
    if (msg) onSetMessage(status, msg);
    return convenios;
  };

  const onSearchAssociado = async (e) => {
    const idconv = data.nrSeqConvenio ?? 0;
    const noPessoa = e;
    const {
      status,
      message: msg,
      associados,
    } = await getAssociadoAutoCompleteConv(noPessoa, idconv);
    onSetMessage(status, msg);
    return associados;
  };

  const onSearchAssociadoConsolidador = async (e) => {
    const {
      status,
      message: msg,
      associados: consolidadores,
    } = await getAssociadoList({
      noPessoa: e,
      flgConsolidador: true,
    });
    onSetMessage(status, msg);
    return consolidadores;
  };

  const onSearchPlanoConvenioEncargo = async (e) => {
    const {
      status,
      message: msg,
      data: planosEncargos,
    } = await getPlanoConvenioEncargoAutoComplete({
      noPlanoConvEncargo: e,
      nrSeqConvenio: data.nrSeqConvenio ?? null,
    });
    onSetMessage(status, msg);
    return planosEncargos;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Lançamento Associado'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({ recalcular: false })}
      onSave={data.nrSeqTituloReceber === null ? save : null}
      onDelete={data?.nrSeqConvenio > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-4 mb-3'>
          <Textbox
            maxLength={7}
            label='Competência'
            text={data.anoMes}
            mask={MaskTypes.Competence}
            onChangedValue={(anoMes) => setData({ ...data, anoMes })}
          />
        </div>
        <div className='col-4 mb-2'>
          <Autocomplete
            label='Convênio'
            searchDataSource={onSearchConvenio}
            selectedItem={data.convenio}
            onSelectItem={(convenio) => {
              setData({
                ...data,
                convenio,
                nrSeqConvenio: convenio.nrSeqConvenio,
              });
            }}
            dataSourceTextProperty='noConvenio'
          />
        </div>
        <div className='col-4 mb-3'>
          <Autocomplete
            label='Associado'
            searchDataSource={onSearchAssociado}
            selectedItem={data.associado}
            onSelectItem={(associado) => {
              if (associado.nrSeqPessoaAssConsolidador) {
                const consolidador = getAssociado(
                  associado.nrSeqPessoaAssConsolidador
                );
                setData({
                  ...data,
                  associado,
                  nrSeqPessoaAss: associado.nrSeqPessoaAss,
                  consolidador,
                  nrSeqPessoaAssConsolidador: consolidador.nrSeqPessoaAss,
                });
              } else
                setData({
                  ...data,
                  associado,
                  nrSeqPessoaAss: associado.nrSeqPessoaAss,
                });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>

        <div className='col-4 mb-3'>
          <Autocomplete
            label='Plano Encargo'
            searchDataSource={onSearchPlanoConvenioEncargo}
            selectedItem={data.planoConvenioEncargo}
            onSelectItem={(planoConvenioEncargo) => {
              const vlrOri =
                planoConvenioEncargo.vlrOriginal &&
                planoConvenioEncargo.vlrOriginal > (data.vlrOriginal || 0)
                  ? planoConvenioEncargo.vlrOriginal
                  : data.vlrOriginal || 0;

              setData({
                ...data,
                planoConvenioEncargo,
                nrSeqPlanoConvEncargoConv:
                  planoConvenioEncargo.nrSeqPlanoConvEncargoConv,
                nrSeqPlanoConvenio: planoConvenioEncargo.nrSeqPlanoConvenio,
                vlrOriginal: vlrOri,
                recalcular: true,
              });
            }}
            dataSourceTextProperty='noPlanoConvEncargo'
          />
        </div>
        <div className='col-4 mb-3'>
          <Autocomplete
            label='Consolidador'
            searchDataSource={onSearchAssociadoConsolidador}
            readOnly
            selectedItem={data.consolidador}
            onSelectItem={(consolidador) =>
              setData({
                ...data,
                consolidador,
                nrSeqPessoaAssConsolidador: consolidador.nrSeqPessoaAss,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col mb-3'>
          <Autocomplete
            label='Vencimento'
            searchDataSource={onSearchVencimento}
            selectedItem={data.vencimento}
            visible={false}
            onSelectItem={(vencimento) => {
              setData({
                ...data,
                vencimento,
                nrSeqVencimento: vencimento.nrSeqVencimento,
              });
            }}
            dataSourceTextProperty='noVencimento'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-2 mb-3'>
          <Textbox
            maxLength={10}
            label='Valor Original'
            mask={MaskTypes.DecimalCustom}
            text={data.vlrOriginal}
            onChangedValue={(vlrOriginal) => {
              setData({
                ...data,
                vlrOriginal,
              });
            }}
            onBlur={recalculaValor}
          />
        </div>
        <div className='col-2 mb-3'>
          <Textbox
            maxLength={10}
            mask={MaskTypes.DecimalCustom}
            label='Valor Recalculado'
            text={data.vlrRecalculado}
            onChangedValue={(vlrRecalculado) =>
              setData({ ...data, vlrRecalculado })
            }
          />
        </div>
        {data.nrSeqConvenioExtrato && (
          <div className='col-4 mb-3'>
            <Textbox
              maxLength={100}
              label='Motivo Alteração'
              text={data.noMotivoAlteracao}
              onChangedValue={(noMotivoAlteracao) =>
                setData({ ...data, noMotivoAlteracao })
              }
            />
          </div>
        )}
      </div>
      <div className='row mb-3 mt-2'>
        <div className='col'>
          <Panel>
            <Panel.Header
              icon={['fas', 'user']}
              title='Informações Adicionais'
              theme={Theme.Primary}
            />
            <Panel.Body>
              <div className='row mt-3'>
                <div className='col-2 mb-3'>
                  <Textbox
                    maxLength={80}
                    label='Nome'
                    text={data.nome}
                    onChangedValue={(nome) => setData({ ...data, nome })}
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    maxLength={40}
                    label='Matrícula'
                    readOnly
                    text={data.nrMatricula}
                    onChangedValue={(nrMatricula) =>
                      setData({ ...data, nrMatricula })
                    }
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    maxLength={20}
                    label='Proposta'
                    readOnly
                    text={data.nrProposta}
                    onChangedValue={(nrProposta) =>
                      setData({ ...data, nrProposta })
                    }
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    maxLength={14}
                    label='CPF'
                    readOnly
                    text={data.cpf}
                    onChangedValue={(cpf) => setData({ ...data, cpf })}
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    maxLength={200}
                    label='Evento'
                    readOnly
                    text={data.evento}
                    onChangedValue={(evento) => setData({ ...data, evento })}
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    maxLength={6}
                    label='Prestação'
                    readOnly
                    text={data.nrPrestacao}
                    onChangedValue={(nrPrestacao) =>
                      setData({ ...data, nrPrestacao })
                    }
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    maxLength={200}
                    label='Plano'
                    readOnly
                    text={data.plano}
                    onChangedValue={(plano) => setData({ ...data, plano })}
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    maxLength={6}
                    label='Percentual Comissão'
                    readOnly
                    text={data.tipoAssociado}
                    onChangedValue={(tipoAssociado) =>
                      setData({ ...data, tipoAssociado })
                    }
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    maxLength={20}
                    label='Data Inclusão'
                    readOnly
                    text={data.dataInclusao}
                    onChangedValue={(dataInclusao) =>
                      setData({ ...data, dataInclusao })
                    }
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    maxLength={20}
                    label='Data Nascimento'
                    readOnly
                    text={data.dataNascimento}
                    onChangedValue={(dataNascimento) =>
                      setData({ ...data, dataNascimento })
                    }
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    maxLength={12}
                    label='Valor Comissão'
                    readOnly
                    text={data.vlrComissao}
                    onChangedValue={(vlrComissao) =>
                      setData({ ...data, vlrComissao })
                    }
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    maxLength={3}
                    label='Idade'
                    readOnly
                    text={data.idade}
                    onChangedValue={(idade) => setData({ ...data, idade })}
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    maxLength={3}
                    readOnly
                    label='Faixa'
                    text={data.faixa}
                    onChangedValue={(faixa) => setData({ ...data, faixa })}
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    maxLength={20}
                    label='Nr Fatura Comissão'
                    readOnly
                    text={data.faixa}
                    onChangedValue={(faixa) => setData({ ...data, faixa })}
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    maxLength={40}
                    label='Período'
                    readOnly
                    text={data.periodo}
                    onChangedValue={(periodo) => setData({ ...data, periodo })}
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    maxLength={12}
                    label='Sub Total'
                    readOnly
                    text={data.vlrSubTotal}
                    onChangedValue={(vlrSubTotal) =>
                      setData({ ...data, vlrSubTotal })
                    }
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col mb-3'>
                  <Textarea
                    maxLength={200}
                    label='Observação de Importação'
                    text={data.noObsImportacao}
                    onChangedValue={(noObsImportacao) =>
                      setData({ ...data, noObsImportacao })
                    }
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
    </CSDManutPage>
  );
}
