/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import {
  GridView,
  Textbox,
  Panel,
  Autocomplete,
  Button,
  FileUpload,
  Switch,
  DatePicker,
  Textarea,
} from 'ui/components';
import {
  PageTypes,
  JustifyContent,
  BootstrapSizes,
  MimeTypes,
} from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { ColumnTypes, Theme, ResponseStatus } from 'ui/Helpers/enums.ts';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { MaskTypes } from 'ui/Helpers/masks';
import { IDemanda, IManutencaoDemanda } from 'core/interfaces/HELP/demanda';
import {
  SearchClienteFromChip,
  getClienteAutoCompleteDemanda,
} from 'core/services/FIN/cliente';
import {
  getChipAutoCompleteMVNO,
  getChipAutoCompleteChipDemanda,
  getOperadoraAutoComplete,
  getClienteLinhasByCliente,
  getUltimoCicloLinha,
  getCentroDeCustoAutoCompleteSimples,
  getChipAutoComplete,
  getAparelhoAutoCompleteForDemanda,
} from 'core/services/TEL';
import { getPlanoVinculoCentrodeCustoEmpAutoComplete } from 'core/services/TEL/planoVinculoCentrodeCustoEmp';
import { getPlanoModeloAparelhoAutoCompleteForDemanda } from 'core/services/TEL/planoModeloAparelho';
import { BuscarTitulosVencidosDoCliente } from 'core/services/FIN/tituloReceber';
import { getPlanoAutoCompleteDemanda } from 'core/services/TEL/plano';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import { getFormaPagamentoPdvAutoComplete } from 'core/services/FIN';
import { getInformacaoBancariaAutoCompleteFiltraFormaPagamento } from 'core/services/FIN/informacaoBancaria';
import { getVencimentoAutoComplete } from 'core/services/ASS/vencimento';

interface IManutencaoDemandaTrocaNumero extends IManutencaoDemanda {}

interface IClienteMessage {
  showMessage: boolean;
  message: string;
}

interface IContratoMessage {
  cycle: string;
  message: string;
  color: string;
}

export default function ManutencaoTrocaNumero({
  data,
  setData,
  onSetMessage,
  dataListDocRemove,
  setDataListDocRemove,
  documentos = [],
  setDocumentos,
  onOpenTransaction,
  findTransaction,
  setModalAlterarFormaPagamento,
  infoPagamentoDefault,
  setInfoPagamentoDefault,
  dataChip,
  setDataChip,
  onGerarContrato,
}: IManutencaoDemandaTrocaNumero) {
  const gridView = useRef<any>();
  const [clienteMessage, setClienteMessage] = useState<IClienteMessage>({
    showMessage: false,
    message: '',
  });
  const [contratoMessage, setContratoMessage] = useState<
    // eslint-disable-next-line no-undef
    Partial<IContratoMessage> | IContratoMessage
  >({});
  const [importedFiles, setImportedFiles] = useState<any>({});
  const [endereco, setEndereco] = useState<any>({});

  const onSearchCliente = async (e: string): Promise<Array<any>> => {
    const { clientes } = await getClienteAutoCompleteDemanda({
      noPessoa: e,
      flgIncluirEndereco: true,
      flgFiltrarEmpresa: true,
    });
    return clientes;
  };

  const onSearchChip = async (e: string): Promise<Array<any>> => {
    if (data.tipoDemanda?.flgTrocaNumero) {
      const { status, message: msg, chips } = await getChipAutoComplete({
        NrPrefixoLinha: e,
        nrSeqEmpresa: data.nrSeqEmpresa,
      });

      if (status === ResponseStatus.Success) {
        onSetMessage(status, msg);
      }

      return chips;
    }

    let retorno: any = {};

    if (data.nrSeqOperadora === 20) {
      if (dataChip?.nrPrefixo === undefined || dataChip?.nrPrefixo === '') {
        onSetMessage(1, 'Informe o Prefixo.');
        return [];
      }

      retorno = await getChipAutoCompleteMVNO({
        nrSeqOperadora: data.nrSeqOperadora,
        nrLinha: e,
        nrSeqEmpresa: data.nrSeqEmpresa,
        nrPrefixo: dataChip.nrPrefixo,
        flgNumeroEspecial: dataChip.flgNumeroEspecial,
      });
    } else {
      retorno = await getChipAutoCompleteChipDemanda({
        nrSeqOperadora: data.nrSeqOperadora,
        nrLinha: e,
        nrSeqEmpresa: data.nrSeqEmpresa,
        nrPrefixo: dataChip.nrPrefixo,
        nrSeqCentroDeCusto: data.nrSeqCentroDeCusto,
      });
    }

    onSetMessage(retorno.status, retorno.message);

    return retorno.chips;
  };

  const getSuggestedDate = (cycleStart: Date) => {
    const today = new Date();

    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();

    const cycleStartDay = cycleStart.getDate();

    let currentCycleStart: Date;
    let nextCycleStart: Date;

    if (today.getDate() < cycleStartDay) {
      currentCycleStart = new Date(
        currentYear,
        currentMonth - 1,
        cycleStartDay
      );

      nextCycleStart = new Date(currentYear, currentMonth, cycleStartDay);
    } else {
      currentCycleStart = new Date(currentYear, currentMonth, cycleStartDay);

      nextCycleStart = new Date(currentYear, currentMonth + 1, cycleStartDay);
    }

    const diffToCurrentCycle: number = Math.abs(
      today.getTime() - currentCycleStart.getTime()
    );
    const diffToNextCycle: number = Math.abs(
      today.getTime() - nextCycleStart.getTime()
    );

    if (diffToCurrentCycle <= diffToNextCycle) {
      return currentCycleStart;
    }

    return nextCycleStart;
  };

  const onSelectChip = async (chip: any): Promise<void> => {
    if ('nrSeqChip' in chip) {
      const lastCycle = await getUltimoCicloLinha(chip.nrSeqChip);

      const today = new Date();

      let cliente: any;

      if (data.tipoDemanda?.flgTrocaNumero) {
        cliente = await SearchClienteFromChip(chip.nrSeqChip);
      }

      if (lastCycle) {
        const dtPerInicial = new Date(lastCycle.dtPerInicial);
        const dtPerFinal = new Date(lastCycle.dtPerFinal);

        const twoMonthsAgo = new Date();
        twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);

        const suggestedDate = getSuggestedDate(dtPerInicial);

        if (dtPerFinal <= twoMonthsAgo) {
          setContratoMessage({
            cycle: `Último ciclo importado: ${
              dtPerInicial.toLocaleString('pt-BR').split(',')[0]
            } - ${dtPerFinal.toLocaleString('pt-BR').split(',')[0]}`,
            message: `Essa linha não possui um ciclo importado recente, verifique se a linha não esta cancelada antes de prosseguir `,
            color: 'danger',
          });
        } else {
          setContratoMessage({
            cycle: `Último ciclo importado: ${
              dtPerInicial.toLocaleString('pt-BR').split(',')[0]
            } - ${dtPerFinal.toLocaleString('pt-BR').split(',')[0]}`,
            message: ``,
            color: 'primary',
          });
        }

        if (data.tipoDemanda?.flgTrocaNumero) {
          setData({
            ...data,
            cliente,
            nrSeqPessoaCli: cliente?.nrSeqPessoaCli,
            chip,
            dtSolicitacao: suggestedDate.toJSON().slice(0, 10),
          });

          setEndereco(cliente?.endereco);
        } else {
          setData({
            ...data,
            chip,
            dtSolicitacao: suggestedDate.toJSON().slice(0, 10),
          });
        }
      } else {
        setContratoMessage({
          cycle: 'Linha não possui um ciclo anterior',
          message: '',
          color: 'primary',
        });

        setData({
          ...data,
          chip,
          dtSolicitacao: today.toJSON().slice(0, 10),
        });
      }

      setDataChip({
        ...dataChip,
        chip,
        nrSeqChip: chip.nrSeqChip,
        nrLinha: chip.nrLinha,
      });
    } else {
      setContratoMessage({});

      if (data.tipoDemanda?.flgTrocaNumero) {
        setData({
          ...data,
          chip: undefined,
          cliente: undefined,
          nrSeqPessoaCli: undefined,
        });

        setEndereco({});
      } else {
        setData({ ...data, chip: undefined, nrSeqChip: undefined });
      }

      setDataChip({
        ...dataChip,
        chip: undefined,
        nrSeqChip: undefined,
        nrLinha: undefined,
      });
    }
  };

  const onClickAddDocumento = async (): Promise<void> => {
    if (importedFiles.length > 0) {
      const documento = {
        ...importedFiles[0],
        noArquivo: importedFiles[0].name,
        noTipoArquivo: importedFiles[0].type,
        noTamanho: importedFiles[0].size,
        flgExibeDownload: false,
        status: 'Inserir',
      };

      const dataSource = gridView?.current?.getDataSource() ?? [];

      dataSource.push(documento);

      if (gridView && gridView.current) {
        gridView.current.setDataSource(dataSource);
      }

      setDocumentos([...documentos, importedFiles[0]]);

      setImportedFiles([]);
    }
  };

  const onClickDownload = async (e: any): Promise<void> => {
    const downloadLink = document.createElement('a');

    if (e.noImagem !== null && e.noImagem !== '') {
      const linkSource = `data:image/png;base64, ${e.noImagem}`;
      downloadLink.href = linkSource;
      downloadLink.download = e.noArquivo;
    } else if (e.linkDownload !== null && e.linkDownload !== '') {
      downloadLink.href = e.linkDownload;
      downloadLink.target = '_blank';
    }

    downloadLink.click();
  };

  const onRemoveDocumento = async (e: any, datasource: any): Promise<void> => {
    const dataDocs = datasource.filter((doc: any) => doc !== e);

    const lista = dataListDocRemove;
    if (e.nrSeqDemandaDocumento) {
      lista.push(e.nrSeqDemandaDocumento);
    }

    setDataListDocRemove(lista);

    if (gridView && gridView.current) gridView.current.setDataSource(dataDocs);
  };

  const columns = [
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'nrSeqDemandaDocumento',
      type: ColumnTypes.Button,
      onClick: (e: any) => onClickDownload(e),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },
    {
      key: 'nrSeqDemandaDocumento',
      type: ColumnTypes.Button,
      onClick: (e: any, datasource: any) => onRemoveDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <>
      {'cycle' in contratoMessage && dataChip.nrSeqChip !== undefined && (
        <div className='row mb-3'>
          <div className='col'>
            <div className={`card border-${contratoMessage.color}`}>
              <div className={`card-body text-${contratoMessage.color}`}>
                <p className='card-text mb-0'>{contratoMessage.cycle}</p>
                {/* @ts-expect-error */}
                {contratoMessage.message.length > 0 && (
                  <p className='card-text'>{contratoMessage.message}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='row mb-3'>
        <div className='col-3'>
          <DatePicker
            label='Data Troca de Número'
            required
            readOnly={!data.flgPermiteAlterar}
            text={data.dtSolicitacao}
            onChange={(dtSolicitacao: Date | string): void => {
              setData({
                ...data,
                dtSolicitacao,
              });
            }}
          />
        </div>
        <div className='col-5'>
          {/* @ts-expect-error */}
          <Textbox label='Protocolo' readOnly text={data.noProtocolo} />
        </div>
      </div>
      {clienteMessage.showMessage && (
        <div className='row mb-2'>
          <div className='col'>
            <div className='card border-danger'>
              <div className='card-body text-danger'>
                <p className='card-text'>{clienteMessage.message}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Nr Linha'
            required
            enabled={data.flgPermiteAlterar}
            searchDataSource={onSearchChip}
            selectedItem={dataChip.chip}
            onSelectItem={async (chip: any): Promise<void> => {
              await onSelectChip(chip);
            }}
            dataSourceTextProperty='linhaFormatada'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Cliente'
            enabled={false}
            readOnly
            required
            searchDataSource={onSearchCliente}
            minLengthTextSearch={3}
            placeholder='Digite ao menos 3 caracteres para buscar Cliente'
            selectedItem={data.cliente}
            onSelectItem={async (cliente: any): Promise<void> => {
              const titulosVencidos = await BuscarTitulosVencidosDoCliente(
                cliente.nrSeqPessoaCli
              );

              if (titulosVencidos.length > 0) {
                setClienteMessage({
                  showMessage: true,
                  message: `Cliente possui título${
                    titulosVencidos.length === 1 ? '' : 's'
                  } vencido${
                    titulosVencidos.length === 1 ? '' : 's'
                  } há mais de ${
                    titulosVencidos[0].nrLimiteDiasVencidos
                  } dias, favor contatar o suporte antes de prosseguir.`,
                });
              } else {
                setClienteMessage({
                  showMessage: false,
                  message: '',
                });
              }

              setData({
                ...data,
                cliente,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
              });

              setEndereco(cliente.endereco);
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row mb-3'>
        {data.cliente?.flgFisica && (
          <div className='col-2'>
            {/* @ts-expect-error */}
            <Textbox
              label='CPF'
              text={data.cliente?.nrDocumento}
              mask={MaskTypes.CPF}
              readOnly
            />
          </div>
        )}

        {!data.cliente?.flgFisica && (
          <div className='col-2'>
            {/* @ts-expect-error */}
            <Textbox
              label='CNPJ'
              text={data.cliente?.nrDocumento}
              mask={MaskTypes.CNPJ}
              readOnly
            />
          </div>
        )}

        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox
            label='RG'
            text={data.cliente?.nrRG}
            maxLength={15}
            visible={data.cliente?.flgFisica}
            readOnly
          />
        </div>
        <div className='col'>
          {/* @ts-expect-error */}
          <Textbox
            label='Endereço'
            text={endereco?.enderecoCompleto}
            readOnly
          />
        </div>
      </div>
      {data.nrSeqDemanda && (
        <div className='row mb-3'>
          <div className='col-3'>
            <Autocomplete
              label='Nr Nova Linha'
              enabled={data.flgPermiteAlterar}
              searchDataSource={onSearchChip}
              selectedItem={data.chipNovo}
              onSelectItem={(chipNovo: any) => {
                setData({
                  ...data,
                  chipNovo,
                  nrSeqChipNovo: chipNovo.nrSeqChipNovo,
                });
              }}
              dataSourceTextProperty='linhaFormatada'
            />
          </div>
        </div>
      )}
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <Textarea
            label='Observação'
            maxLength={2000}
            readOnly={!data.flgPermiteAlterar}
            rows={5}
            text={data.noObservacao}
            onChangedValue={(noObservacao: string) => {
              setData({ ...data, noObservacao });
            }}
          />
        </div>
        <div className='col'>
          <div className='row'>
            <div className='col mt-4'>
              <FileUpload
                files={importedFiles}
                onChange={(files: any) => {
                  if (files.length > 0) {
                    if (files[0].size <= 5000000) {
                      setImportedFiles(files);
                    } else {
                      onSetMessage(
                        ResponseStatus.Error,
                        'Arquivo não pode ser maior que 5MB'
                      );
                    }
                  } else {
                    setImportedFiles(files);
                  }
                }}
                allowedMimeTypes={[
                  MimeTypes.Types.PDF,
                  MimeTypes.Types.Image,
                  MimeTypes.Types.Word,
                ]}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col mt-3 d-flex justify-content-center'>
              <Button
                text='Adicionar Documento'
                className='px-5'
                theme={Theme.Success}
                size={BootstrapSizes.Small}
                template={Button.Templates.Default}
                onClick={() => onClickAddDocumento()}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
    </>
  );
}
