import React, { useState } from 'react';
import { getPneuAutoComplete } from 'core/services/FRO/pneu';
import { getPneuModeloAutoComplete } from 'core/services/FRO/pneuModelo';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import {
  Autocomplete,
  Button,
  DatePicker,
  Modal,
  RadioButton,
} from 'ui/components';
import { BootstrapSizes, Theme } from 'ui/Helpers/enums';
import { getPneuMarcaAutoComplete } from 'core/services/FRO/pneuMarca';

interface IVeiculo {
  nrSeqVeiculo: number;
  placa: string;
}

interface IPneu {
  nrSeqPneu: number;
  cdPneu: string;
}

interface IPneuModelo {
  nrSeqPneuModelo: number;
  noPneuModelo: string;
}

interface IPneuMarca {
  nrSeqPneuMarca: number;
  noPneuMarca: string;
}

interface IFiltros {
  veiculo?: IVeiculo;
  nrSeqVeiculo?: number;
  dtInicio?: string;
  dtFim?: string;
  pneu?: IPneu;
  nrSeqPneu?: number;
  pneuModelo?: IPneuModelo;
  nrSeqPneuModelo?: number;
  pneuMarca?: IPneuMarca;
  nrSeqPneuMarca?: number;
  noTipoReport: string;
  noModeloReport: string;
}

interface IProps {
  show: boolean;
  onClose: (sendToPrint: boolean, filtros?: IFiltros) => void;
}

export default function ModalImprimeRelatorioRecapagem({
  show,
  onClose,
}: IProps) {
  const [filtros, setFiltros] = useState<IFiltros>({
    noTipoReport: 'Recapagem',
    noModeloReport: 'PorPlaca',
  });

  const onSearchAutocompleteVeiculo = async (param: string) => {
    const { veiculos } = await getVeiculoAutoComplete({ placa: param });

    return veiculos;
  };

  const onSearchAutocompletePneu = async (param: string) => {
    const { data: pneus } = await getPneuAutoComplete({ cdPneu: param });

    return pneus;
  };

  const onSearchAutocompletePneuModelo = async (param: string) => {
    const { pneuModelo } = await getPneuModeloAutoComplete({
      noPneuModelo: param,
    });

    return pneuModelo;
  };

  const onSearchAutocompletePneuMarca = async (param: string) => {
    const { pneuMarcas } = await getPneuMarcaAutoComplete({
      noPneuMarca: param,
    });

    return pneuMarcas;
  };

  const onClickPrint = (sendToPrint: boolean) => {
    onClose(sendToPrint, filtros);
  };

  const btnsTipoReport = [
    { text: 'Recapagem', value: 'Recapagem' },
    { text: 'Sucata', value: 'Sucata' },
  ];

  const btnsModeloReport = [
    { text: 'Placa', value: 'PorPlaca' },
    { text: 'Pneu', value: 'PorPneu' },
    { text: 'Modelo', value: 'PorPneuModelo' },
    { text: 'Marca', value: 'PorPneuMarca' },
    { text: 'Periodo', value: 'PorPeriodo' },
  ];

  return (
    // @ts-expect-error
    <Modal
      show={show}
      title='Filtros para Impressão de Recapagem'
      size={BootstrapSizes.Large}
      onClose={() => onClickPrint(false)}
    >
      {/* @ts-expect-error */}
      <Modal.Body>
        <div className='row mb-2'>
          <div className='col d-flex flex-column align-items-start flex-wrap-nowrap'>
            {/* @ts-expect-error */}
            <RadioButton
              label='Tipo Relatório'
              outline
              size={BootstrapSizes.Small}
              theme={Theme.Primary}
              selectedButton={filtros?.noTipoReport}
              buttons={btnsTipoReport}
              onChange={(noTipoReport: string) => {
                setFiltros({
                  ...filtros,
                  noTipoReport,
                });
              }}
            />
          </div>
          <div className='col d-flex flex-column align-items-start flex-wrap-nowrap'>
            {/* @ts-expect-error */}
            <RadioButton
              label='Modelo Relatório'
              outline
              size={BootstrapSizes.Small}
              theme={Theme.Primary}
              selectedButton={filtros?.noModeloReport}
              buttons={btnsModeloReport}
              onChange={(noModeloReport: string) => {
                setFiltros({
                  ...filtros,
                  noModeloReport,
                });
              }}
            />
          </div>
        </div>

        <div className='row mb-2'>
          <div className='col-4'>
            <DatePicker
              label='Data Início'
              text={filtros?.dtInicio}
              onChange={(dtInicio: string) =>
                setFiltros({ ...filtros, dtInicio })
              }
            />
          </div>

          <div className='col-4'>
            <DatePicker
              label='Data Fim'
              text={filtros?.dtFim}
              onChange={(dtFim: string) => setFiltros({ ...filtros, dtFim })}
            />
          </div>

          <div className='col-4'>
            <Autocomplete
              label='Veículo'
              searchDataSource={(e: string) => onSearchAutocompleteVeiculo(e)}
              selectedItem={filtros?.veiculo}
              onSelectItem={(veiculo: IVeiculo) =>
                setFiltros({
                  ...filtros,
                  veiculo,
                  nrSeqVeiculo: veiculo.nrSeqVeiculo,
                })
              }
              dataSourceTextProperty='placa'
            />
          </div>
        </div>

        <div className='row mb-4'>
          <div className='col-4'>
            <Autocomplete
              label='Pneu'
              searchDataSource={(e: string) => onSearchAutocompletePneu(e)}
              selectedItem={filtros?.pneu}
              onSelectItem={(pneu: IPneu) =>
                setFiltros({
                  ...filtros,
                  pneu,
                  nrSeqPneu: pneu.nrSeqPneu,
                })
              }
              dataSourceTextProperty='cdPneu'
            />
          </div>

          <div className='col-4'>
            <Autocomplete
              label='Pneu Modelo'
              searchDataSource={(e: string) =>
                onSearchAutocompletePneuModelo(e)
              }
              selectedItem={filtros?.pneuModelo}
              onSelectItem={(pneuModelo: IPneuModelo) =>
                setFiltros({
                  ...filtros,
                  pneuModelo,
                  nrSeqPneuModelo: pneuModelo.nrSeqPneuModelo,
                })
              }
              dataSourceTextProperty='noPneuModelo'
            />
          </div>

          <div className='col-4'>
            <Autocomplete
              label='Pneu Marca'
              searchDataSource={(e: string) => onSearchAutocompletePneuMarca(e)}
              selectedItem={filtros?.pneuMarca}
              onSelectItem={(pneuMarca: IPneuMarca) =>
                setFiltros({
                  ...filtros,
                  pneuMarca,
                  nrSeqPneuMarca: pneuMarca.nrSeqPneuMarca,
                })
              }
              dataSourceTextProperty='noPneuMarca'
            />
          </div>
        </div>

        <div className='row'>
          <div className='col d-flex justify-content-end align-items-end'>
            <Button
              text='Imprimir'
              theme={Theme.Success}
              onClick={() => onClickPrint(true)}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
