import React, { useEffect } from 'react';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { Button, Modal } from 'ui/components';

export default function ModalLocalizacao({ show, onClose }) {
  useEffect(() => {}, [show]);

  return (
    <Modal
      show={show}
      title='Localização'
      size={BootstrapSizes.Large}
      onClose={onClose}
    >
      <Modal.Body>
        <div>mapa</div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Fechar'
          icon='ban'
          theme={Theme.Danger}
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
