/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import {
  GridView,
  Textbox,
  Panel,
  Autocomplete,
  Button,
  FileUpload,
  Switch,
  DatePicker,
  RadioButton,
  Textarea,
  DropdownList,
} from 'ui/components';
import {
  PageTypes,
  JustifyContent,
  BootstrapSizes,
  MimeTypes,
} from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { ColumnTypes, Theme, ResponseStatus } from 'ui/Helpers/enums.ts';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { MaskTypes } from 'ui/Helpers/masks';
import { IDemanda, IManutencaoDemanda } from 'core/interfaces/HELP/demanda';
import {
  SearchClienteFromChip,
  getClienteAutoCompleteDemanda,
} from 'core/services/FIN/cliente';
import {
  getChipAutoCompleteMVNO,
  getChipAutoCompleteChipDemanda,
  getOperadoraAutoComplete,
  getClienteLinhasByCliente,
  getUltimoCicloLinha,
  getCentroDeCustoAutoCompleteSimples,
  getChipAutoComplete,
  getAparelhoAutoCompleteForDemanda,
} from 'core/services/TEL';
import { getScheduledDate } from 'core/services/HELP';
import { getPlanoVinculoCentrodeCustoEmpAutoComplete } from 'core/services/TEL/planoVinculoCentrodeCustoEmp';
import { getPlanoModeloAparelhoAutoCompleteForDemanda } from 'core/services/TEL/planoModeloAparelho';
import { BuscarTitulosVencidosDoCliente } from 'core/services/FIN/tituloReceber';
import { getPlanoAutoCompleteDemanda } from 'core/services/TEL/plano';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import { getFormaPagamentoPdvAutoComplete } from 'core/services/FIN';
import { getInformacaoBancariaAutoCompleteFiltraFormaPagamento } from 'core/services/FIN/informacaoBancaria';
import { getVencimentoAutoComplete } from 'core/services/ASS/vencimento';

interface IManutencaoDemandaPortabilidade extends IManutencaoDemanda {
  // onOpenTransaction: any;
  // findTransaction: any;
  // onGerarContrato: () => void;
  onSolicitaPortabilidade: () => void;
}

export default function ManutencaoPortabilidade({
  data,
  setData,
  onSetMessage,
  dataListDocRemove,
  setDataListDocRemove,
  documentos = [],
  setDocumentos,
  onOpenTransaction,
  findTransaction,
  setModalAlterarFormaPagamento,
  infoPagamentoDefault,
  setInfoPagamentoDefault,
  dataChip,
  setDataChip,
  onGerarContrato,
  setLoading,
  onSolicitaPortabilidade,
}: IManutencaoDemandaPortabilidade) {
  const gridView = useRef<any>();
  const [importedFiles, setImportedFiles] = useState<any>({});
  const [dataTitular, setDataTitular] = useState<any>({ flgMesmoTitular: 'S' });
  const [enderecoTitularOld, setEnderecoTitularOld] = useState<any>({});
  const [dropDownHorariosSource, setDropDownHorariosSource] = useState<
    Array<any>
  >([]);
  const [trocarProvisorio, setTrocarProvisorio] = useState<boolean>(false);
  const [endereco, setEndereco] = useState<any>({});
  const [hasPlanoRateioDefault, setHasPlanoRateioDefault] = useState<boolean>(
    false
  );
  const [agendarPortabilidade, setAgendarPortabilidade] = useState<boolean>(
    false
  );

  const titularButtons = [
    {
      text: 'Mesmo Titular',
      value: 'S',
    },
    {
      text: 'Portabilidade com Transferência de Titularidade',
      value: 'N',
    },
  ];

  const onTrocarProvisorio = () => {
    if (data.chipNovo)
      setData({
        ...data,
        nrPrefixoProvisorio: data.chipNovo.linhaFormatada.substring(1, 3),
      });
    if (trocarProvisorio) setTrocarProvisorio(false);
    else setTrocarProvisorio(true);
  };

  const onSearchChip = async (e: string): Promise<Array<any>> => {
    if (data.tipoDemanda?.flgTrocaNumero) {
      const { status, message: msg, chips } = await getChipAutoComplete({
        NrPrefixoLinha: e,
        nrSeqEmpresa: data.nrSeqEmpresa,
      });

      if (status === ResponseStatus.Success) {
        onSetMessage(status, msg);
      }

      return chips;
    }

    let retorno: any = {};

    if (data.nrSeqOperadora === 20) {
      if (dataChip?.nrPrefixo === undefined || dataChip?.nrPrefixo === '') {
        onSetMessage(1, 'Informe o Prefixo.');
        return [];
      }

      retorno = await getChipAutoCompleteMVNO({
        nrSeqOperadora: data.nrSeqOperadora,
        nrLinha: e,
        nrSeqEmpresa: data.nrSeqEmpresa,
        nrPrefixo: dataChip.nrPrefixo,
        flgNumeroEspecial: dataChip.flgNumeroEspecial,
      });
    } else {
      retorno = await getChipAutoCompleteChipDemanda({
        nrSeqOperadora: data.nrSeqOperadora,
        nrLinha: e,
        nrSeqEmpresa: data.nrSeqEmpresa,
        nrPrefixo: dataChip.nrPrefixo,
        nrSeqCentroDeCusto: data.nrSeqCentroDeCusto,
      });
    }

    onSetMessage(retorno.status, retorno.message);

    return retorno.chips;
  };

  const onSearchCliente = async (e: string): Promise<Array<any>> => {
    const { clientes } = await getClienteAutoCompleteDemanda({
      noPessoa: e,
      flgIncluirEndereco: true,
      flgFiltrarEmpresa: true,
    });
    return clientes;
  };

  const onSearchOperadora = async (e: string): Promise<Array<any>> => {
    const {
      status,
      message: msg,
      operadoras,
    } = await getOperadoraAutoComplete({ noOperadora: e });

    if (status === ResponseStatus.Error) {
      onSetMessage(msg, status);
      return [];
    }

    const result = operadoras.filter(
      (operadora: any) => !operadora.noOperadora.includes('Importação Manual')
    );

    // Não fazer Novo Contrato Massivo para MVNO
    if (data.tipoDemanda?.cdTipoDemanda === 'Novo Contrato Massivo') {
      return result.filter(
        (operadora: any) => !operadora.noOperadora.includes('MVNO')
      );
    }

    return result;
  };

  const onSearchCentroDeCusto = async (e: string): Promise<Array<any>> => {
    const {
      status,
      message: msg,
      data: centros,
    } = await getCentroDeCustoAutoCompleteSimples({
      codNoCentroDeCusto: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });
    onSetMessage(status, msg);
    return centros;
  };

  const onSearchPlano = async (e: string): Promise<Array<any>> => {
    const { status, message: msg, planos } = await getPlanoAutoCompleteDemanda({
      noPlano: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
      nrSeqOperadora: data.nrSeqOperadora,
      flgAgrupado: true,
    });
    if (planos.length === 0)
      onSetMessage(ResponseStatus.Error, 'Nenhum plano vinculado a empresa.');

    onSetMessage(status, msg);

    return planos;
  };

  const onSearchPlanoRateio = async (e: string): Promise<Array<any>> => {
    const { status, message: msg, planos } = await getPlanoAutoCompleteDemanda({
      noPlano: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
      flgAtivo: true,
      flgPlanoRateioEmpresa: true,
    });

    if (planos.length === 0)
      onSetMessage(ResponseStatus.Error, 'Nenhum plano vinculado a empresa.');

    onSetMessage(status, msg);

    return planos;
  };

  const getPlanoRateio = async (nrSeqTipoChamada: number): Promise<any> => {
    const { planos } = await getPlanoAutoCompleteDemanda({
      nrSeqTipoChamada,
      nrSeqEmpresa: data.nrSeqEmpresa,
      flgAtivo: true,
      flgPlanoRateioEmpresa: true,
    });

    return planos.length > 0 ? planos[0] : undefined;
  };

  const onSearchPlanoOperadoraDestino = async (
    e: string
  ): Promise<Array<any>> => {
    const { status, message: msg, planos } = await getPlanoAutoCompleteDemanda({
      noPlano: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
      // @ts-expect-error
      nrSeqOperadora: data.operadoraDestino.nrSeqOperadora,
      flgAgrupado: true,
    });

    if (planos.length === 0)
      onSetMessage(ResponseStatus.Error, 'Nenhum plano vinculado a empresa.');

    onSetMessage(status, msg);

    return planos;
  };

  const onSearchPacoteDados = async (e: string): Promise<Array<any>> => {
    if (
      data.nrSeqCentroDeCusto === undefined ||
      data.nrSeqCentroDeCusto === 0
    ) {
      onSetMessage(ResponseStatus.Error, 'Selecione o centro de custo.');
      return [];
    }
    if (data.nrSeqPlano === undefined || data.nrSeqPlano === 0) {
      onSetMessage(ResponseStatus.Error, 'Selecione o plano.');
      return [];
    }

    const { planovinculo } = await getPlanoVinculoCentrodeCustoEmpAutoComplete({
      // @ts-expect-error
      nrSeqPlano: data.plano.nrSeqPlano,
      nrSeqOperadora: data.nrSeqOperadora,
      nrSeqEmpresa: data.nrSeqEmpresa,
      nrSeqCentroDeCusto: data.nrSeqCentroDeCusto,
      noTipoChamada: e,
    });
    if (planovinculo.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Centro de Custo sem pacote vinculado.'
      );
    }
    return planovinculo;
  };

  const onAutoCompleteFormaPagamento = async (
    e: string
  ): Promise<Array<any>> => {
    const { formasPagamento } = await getFormaPagamentoPdvAutoComplete({
      noFormaPagamento: e,
    });

    return formasPagamento;
  };

  const onSearchVencimento = async (e: string): Promise<Array<any>> => {
    const {
      status,
      message: msg,
      vencimentos,
    } = await getVencimentoAutoComplete({
      noVencimento: e,
    });
    onSetMessage(status, msg);
    return vencimentos;
  };

  const onSearchInformacaoBancaria = async (): Promise<Array<any>> => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoCompleteFiltraFormaPagamento({
      nrSeqPessoa: data.cliente?.nrSeqPessoaCli,
      nrSeqFormaPagamento: data.nrSeqFormaPagamento,
    });

    onSetMessage(status, msg);

    return informacoesBancarias;
  };

  const onClickAddDocumento = async (): Promise<void> => {
    if (importedFiles.length > 0) {
      const documento = {
        ...importedFiles[0],
        noArquivo: importedFiles[0].name,
        noTipoArquivo: importedFiles[0].type,
        noTamanho: importedFiles[0].size,
        flgExibeDownload: false,
        status: 'Inserir',
      };

      const dataSource = gridView?.current?.getDataSource() ?? [];

      dataSource.push(documento);

      if (gridView && gridView.current) {
        gridView.current.setDataSource(dataSource);
      }

      setDocumentos([...documentos, importedFiles[0]]);

      setImportedFiles([]);
    }
  };

  const onClickDownload = async (e: any): Promise<void> => {
    const downloadLink = document.createElement('a');

    if (e.noImagem !== null && e.noImagem !== '') {
      const linkSource = `data:image/png;base64, ${e.noImagem}`;
      downloadLink.href = linkSource;
      downloadLink.download = e.noArquivo;
    } else if (e.linkDownload !== null && e.linkDownload !== '') {
      downloadLink.href = e.linkDownload;
      downloadLink.target = '_blank';
    }

    downloadLink.click();
  };

  const onRemoveDocumento = async (e: any, datasource: any): Promise<void> => {
    const dataDocs = datasource.filter((doc: any) => doc !== e);

    const lista = dataListDocRemove;
    if (e.nrSeqDemandaDocumento) {
      lista.push(e.nrSeqDemandaDocumento);
    }

    setDataListDocRemove(lista);

    if (gridView && gridView.current) gridView.current.setDataSource(dataDocs);
  };

  const columns = [
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'nrSeqDemandaDocumento',
      type: ColumnTypes.Button,
      onClick: (e: any) => onClickDownload(e),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },
    {
      key: 'nrSeqDemandaDocumento',
      type: ColumnTypes.Button,
      onClick: (e: any, datasource: Array<any>) =>
        onRemoveDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const searchHorariosPortabilidade = async (
    e: Date | string
  ): Promise<void> => {
    if (e || data.dtPortabilidade) {
      setLoading(true);

      const { status, message: msg, agenda } = await getScheduledDate({
        dtPortabilidade: data?.dtPortabilidade ?? e,
      });
      if (status === ResponseStatus.Error) {
        onSetMessage(status, msg);
        await getScheduledDate({});
        setDropDownHorariosSource([]);
      } else {
        setDropDownHorariosSource(agenda.horarios);
      }
    }

    setLoading(false);
  };

  return (
    <>
      <div className='row mb-3'>
        <div className='col-auto'>
          {/* @ts-expect-error */}
          <RadioButton
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={titularButtons}
            selectedButton={dataTitular.flgMesmoTitular}
            onChange={(flgMesmoTitular: string) => {
              setData({
                ...data,
                clienteTitularOld: undefined,
                nrSeqPessoaOld: undefined,
              });
              setEnderecoTitularOld({});
              setDataTitular({ ...dataTitular, flgMesmoTitular });
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <div className='card'>
            <div className='card-body'>
              <div className='row mb-3'>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Nr. Linha Portada'
                    readOnly={!data.flgPermiteAlterar}
                    required
                    text={dataChip.nrPrefixoLinha}
                    mask={MaskTypes.CellPhone}
                    onChangedValue={(nrPrefixoLinha: string) => {
                      setDataChip({
                        ...dataChip,
                        nrPrefixoLinha,
                      });
                    }}
                    onBlur={(param1: any, param2: string): void => {
                      if (param2 && param2.length < 11) {
                        onSetMessage(1, 'Informe o Prefixo.');
                      }
                    }}
                  />
                </div>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='ICCID'
                    readOnly={!data.flgPermiteAlterar}
                    text={dataChip.nrIccid}
                    maxLength={20}
                    onChangedValue={(nrIccid: string) => {
                      setDataChip({
                        ...dataChip,
                        nrIccid,
                      });
                    }}
                  />
                </div>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Nr. Linha Provisória'
                    readOnly
                    text={data.chipNovo?.linhaFormatada}
                  />
                </div>
                {data.nrSeqDemanda && data.flgPermiteAlterarLinhaProvisoria && (
                  <div className='col mt-3'>
                    <Button
                      theme={Theme.Primary}
                      template={Button.Templates.Default}
                      text='Trocar Linha Provisória'
                      onClick={onTrocarProvisorio}
                    />
                  </div>
                )}
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <DatePicker
                    label='Data de Solicitação'
                    readOnly={!data.flgPermiteAlterar}
                    text={data.dtSolicitacao}
                  />
                </div>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <DatePicker
                    label='Data de Envio Operadora'
                    readOnly={!data.flgPermiteAlterar}
                    text={data.dtEnvioOperadora}
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-1'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='DDD'
                    visible={trocarProvisorio}
                    readOnly={!data.flgPermiteAlterar}
                    text={data.nrPrefixoProvisorio}
                    onChangedValue={(nrPrefixoProvisorio: string): void => {
                      setData({
                        ...data,
                        nrPrefixoProvisorio,
                      });
                    }}
                  />
                </div>
                <div className='col-3'>
                  <Autocomplete
                    label='Pesquisar Linha'
                    visible={trocarProvisorio}
                    enabled={data.flgPermiteAlterar}
                    required
                    searchDataSource={onSearchChip}
                    selectedItem={data.chipNovo}
                    onSelectItem={(chipNovo: any) => {
                      setData({
                        ...data,
                        chipNovo,
                        nrLinhaProvisoria: chipNovo.nrLinha,
                      });
                    }}
                    dataSourceTextProperty='linhaFormatada'
                  />
                </div>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Switch
                    formControl
                    visible={trocarProvisorio}
                    label='Números Especiais'
                    checked={data.chip?.flgNumeroEspecial}
                    onChange={(flgNumeroEspecial: boolean) =>
                      setDataChip({
                        ...dataChip,
                        flgNumeroEspecial,
                      })
                    }
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <Autocomplete
                    label='Cliente'
                    enabled={data.flgPermiteAlterar}
                    required
                    searchDataSource={onSearchCliente}
                    minLengthTextSearch={3}
                    placeholder='Digite ao menos 3 caracteres para buscar Cliente'
                    selectedItem={data.cliente}
                    findTransaction={findTransaction}
                    onOpenTransaction={onOpenTransaction}
                    transactionToOpen={TransacationCodes.Cliente}
                    nrseqTransactionToOpen='nrSeqPessoaCli'
                    onSelectItem={async (cliente: any): Promise<void> => {
                      if ('nrSeqPessoaCli' in cliente) {
                        const {
                          data: clienteLinhas,
                        } = await getClienteLinhasByCliente({
                          nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                        });

                        if (clienteLinhas.length > 0) {
                          const {
                            formaPagamento,
                            vencimento,
                          } = clienteLinhas[0];

                          setInfoPagamentoDefault({
                            formaPagamento,
                            nrSeqFormaPagamento:
                              formaPagamento.nrSeqFormaPagamento,
                            vencimento,
                            nrSeqVencimento: vencimento.nrSeqVencimento,
                          });
                          setData({
                            ...data,
                            cliente,
                            nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                            formaPagamento,
                            nrSeqFormaPagamento:
                              formaPagamento.nrSeqFormaPagamento,
                            vencimento,
                            nrSeqVencimento: vencimento.nrSeqVencimento,
                          });
                        } else {
                          setInfoPagamentoDefault({});

                          setData({
                            ...data,
                            cliente,
                            nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                            formaPagamento: undefined,
                            nrSeqFormaPagamento: undefined,
                            vencimento: undefined,
                            nrSeqVencimento: undefined,
                          });
                        }

                        setEndereco(cliente.endereco);
                      } else {
                        setInfoPagamentoDefault({});

                        setData({
                          ...data,
                          cliente: undefined,
                          nrSeqPessoaCli: undefined,
                          formaPagamento: undefined,
                          nrSeqFormaPagamento: undefined,
                          vencimento: undefined,
                          nrSeqVencimento: undefined,
                        });

                        setEndereco({});
                      }
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='row mb-3'>
                  <div className='row mb-3'>
                    <div className='row'>
                      <div className='row mb-3'>
                        <div className='col'>
                          {/* @ts-expect-error */}
                          <Textbox
                            label='RG'
                            text={data.cliente?.nrRG}
                            visible={data.cliente?.flgFisica}
                            maxLength={15}
                            readOnly
                          />
                        </div>
                        <div className='col'>
                          {/* @ts-expect-error */}
                          <Textbox
                            label='CPF'
                            text={data.cliente?.nrDocumento}
                            mask={MaskTypes.CPF}
                            visible={data.cliente?.flgFisica}
                            readOnly
                          />
                        </div>
                        <div className='col'>
                          {/* @ts-expect-error */}
                          <Textbox
                            label='CNPJ'
                            text={data.cliente?.nrDocumento}
                            mask={MaskTypes.CNPJ}
                            visible={!data.cliente?.flgFisica}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col'>
                          {/* @ts-expect-error */}
                          <Textbox
                            label='Endereço'
                            text={endereco?.noRua}
                            readOnly
                          />
                        </div>
                        <div className='col-2'>
                          {/* @ts-expect-error */}
                          <Textbox
                            label='Numero'
                            text={endereco?.numero}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col'>
                          {/* @ts-expect-error */}
                          <Textbox
                            label='Bairro'
                            text={endereco?.bairro}
                            readOnly
                          />
                        </div>
                        <div className='col'>
                          {/* @ts-expect-error */}
                          <Textbox
                            label='Cidade'
                            text={endereco?.cidade?.noCidade}
                            readOnly
                          />
                        </div>
                        <div className='col'>
                          {/* @ts-expect-error */}
                          <Textbox label='Cep' text={endereco?.cep} readOnly />
                        </div>
                        <div className='col'>
                          {/* @ts-expect-error */}
                          <Textbox label='UF' readOnly />
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col'>
                          {/* @ts-expect-error */}
                          <Textbox
                            label='Email'
                            text={data.cliente?.email}
                            readOnly
                          />
                        </div>
                        <div className='col'>
                          {/* @ts-expect-error */}
                          <Textbox
                            label='Telefone'
                            text={data.cliente?.fone}
                            mask={MaskTypes.CellPhone}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {dataTitular.flgMesmoTitular === 'N' && (
                    <div className='row mb-3'>
                      <div className='row mb-2'>
                        {/* @ts-expect-error */}
                        <Panel>
                          {/* @ts-expect-error */}
                          <Panel.Header
                            title='Titularidade da Linha de Origem'
                            theme={Theme.Primary}
                            align={JustifyContent.Start}
                          />
                          <Panel.Body>
                            <div className='accordion-body'>
                              <div className='row mb-3'>
                                <div className='card'>
                                  <div className='card-body'>
                                    <div className='row mb-3'>
                                      {/* Necessario as divs a cima???? */}
                                      <div className='row'>
                                        <div className='row mb-3'>
                                          <div className='col'>
                                            <Autocomplete
                                              label='Titular'
                                              enabled={data.flgPermiteAlterar}
                                              required
                                              searchDataSource={onSearchCliente}
                                              selectedItem={
                                                data.clienteTitularOld
                                              }
                                              findTransaction={findTransaction}
                                              onOpenTransaction={
                                                onOpenTransaction
                                              }
                                              transactionToOpen={
                                                TransacationCodes.Cliente
                                              }
                                              nrseqTransactionToOpen='nrSeqPessoaCli'
                                              onSelectItem={(
                                                clienteTitularOld: any
                                              ) => {
                                                setData({
                                                  ...data,
                                                  clienteTitularOld,
                                                  nrSeqPessoaOld:
                                                    clienteTitularOld.nrSeqPessoaCli,
                                                });

                                                setEnderecoTitularOld(
                                                  clienteTitularOld.endereco
                                                );
                                              }}
                                              dataSourceTextProperty='noPessoa'
                                            />
                                          </div>
                                        </div>
                                        <div className='row mb-3'>
                                          <div className='col'>
                                            {/* @ts-expect-error */}
                                            <Textbox
                                              label='RG'
                                              text={
                                                data.clienteTitularOld?.nrRG
                                              }
                                              maxLength={15}
                                              visible={
                                                data.clienteTitularOld
                                                  ?.flgFisica
                                              }
                                              readOnly
                                            />
                                          </div>
                                          <div className='col'>
                                            {/* @ts-expect-error */}
                                            <Textbox
                                              label='CPF'
                                              text={
                                                data.clienteTitularOld
                                                  ?.nrDocumento
                                              }
                                              mask={MaskTypes.CPF}
                                              visible={
                                                data.clienteTitularOld
                                                  ?.flgFisica
                                              }
                                              readOnly
                                            />
                                          </div>
                                          <div className='col'>
                                            {/* @ts-expect-error */}
                                            <Textbox
                                              label='CNPJ'
                                              text={
                                                data.clienteTitularOld
                                                  ?.nrDocumento
                                              }
                                              mask={MaskTypes.CNPJ}
                                              visible={
                                                data.clienteTitularOld &&
                                                !data.clienteTitularOld
                                                  .flgFisica
                                              }
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                        <div className='row mb-3'>
                                          <div className='col'>
                                            {/* @ts-expect-error */}
                                            <Textbox
                                              label='Endereço'
                                              text={enderecoTitularOld?.noRua}
                                              readOnly
                                            />
                                          </div>
                                          <div className='col-2'>
                                            {/* @ts-expect-error */}
                                            <Textbox
                                              label='Numero'
                                              text={enderecoTitularOld?.numero}
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                        <div className='row mb-3'>
                                          <div className='col'>
                                            {/* @ts-expect-error */}
                                            <Textbox
                                              label='Bairro'
                                              text={enderecoTitularOld?.bairro}
                                              readOnly
                                            />
                                          </div>
                                          <div className='col'>
                                            {/* @ts-expect-error */}
                                            <Textbox
                                              label='Cidade'
                                              text={
                                                enderecoTitularOld?.cidade
                                                  ?.noCidade
                                              }
                                              readOnly
                                            />
                                          </div>
                                          <div className='col'>
                                            {/* @ts-expect-error */}
                                            <Textbox
                                              label='Cep'
                                              text={enderecoTitularOld?.cep}
                                              readOnly
                                            />
                                          </div>
                                          <div className='col'>
                                            {/* @ts-expect-error */}
                                            <Textbox
                                              label='UF'
                                              text={
                                                enderecoTitularOld?.cidade
                                                  ?.estado.cdEstado
                                              }
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                        <div className='row mb-3'>
                                          <div className='col'>
                                            {/* @ts-expect-error */}
                                            <Textbox
                                              label='Email'
                                              text={
                                                data.clienteTitularOld?.email
                                              }
                                              readOnly
                                            />
                                          </div>
                                          <div className='col'>
                                            {/* @ts-expect-error */}
                                            <Textbox
                                              label='Telefone'
                                              text={
                                                data.clienteTitularOld?.fone
                                              }
                                              mask={MaskTypes.CellPhone}
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Panel.Body>
                        </Panel>
                      </div>
                    </div>
                  )}
                  <div className='row mb-3'>
                    <div className='col'>
                      <Autocomplete
                        label='Operadora Origem'
                        enabled={data.flgPermiteAlterar}
                        required
                        searchDataSource={onSearchOperadora}
                        selectedItem={data.operadoraOrigem}
                        onSelectItem={(operadoraOrigem: any) =>
                          setData({
                            ...data,
                            operadoraOrigem,
                            nrSeqOperadoraOrigem:
                              operadoraOrigem.nrSeqOperadora,
                            plano: undefined,
                            tipoChamada: undefined,
                          })
                        }
                        dataSourceTextProperty='noOperadora'
                      />
                    </div>
                    <div className='col'>
                      <Autocomplete
                        label='Operadora Destino'
                        enabled={data.flgPermiteAlterar}
                        required
                        searchDataSource={onSearchOperadora}
                        selectedItem={data.operadoraDestino}
                        onSelectItem={(operadoraDestino: any) =>
                          setData({
                            ...data,
                            operadoraDestino,
                            nrSeqOperadoraDestino:
                              operadoraDestino.nrSeqOperadora,
                            operadora: operadoraDestino,
                            nrSeqOperadora: operadoraDestino.nrSeqOperadora,
                            plano: undefined,
                            tipoChamada: undefined,
                          })
                        }
                        dataSourceTextProperty='noOperadora'
                      />
                    </div>
                    <div className='col-4'>
                      <Autocomplete
                        label='Centro de Custo'
                        enabled={data.flgPermiteAlterar}
                        required
                        searchDataSource={onSearchCentroDeCusto}
                        selectedItem={data.centroDeCusto}
                        onSelectItem={(centroDeCusto: any) =>
                          setData({
                            ...data,
                            centroDeCusto,
                            nrSeqCentroDeCusto:
                              centroDeCusto.nrSeqCentroDeCusto,
                          })
                        }
                        dataSourceTextProperty='noCentroDeCusto'
                      />
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <div className='col'>
                      <Autocomplete
                        label='Plano'
                        enabled={
                          data.flgPermiteAlterar && data.nrSeqOperadoraDestino
                        }
                        placeholder={
                          !data.nrSeqOperadoraDestino
                            ? 'Selecione a Operadora Destino'
                            : ''
                        }
                        required
                        searchDataSource={onSearchPlanoOperadoraDestino}
                        selectedItem={data.plano}
                        onSelectItem={(plano: any) =>
                          setData({
                            ...data,
                            plano,
                            nrSeqPlano: plano.nrSeqPlano,
                            tipoChamada: undefined,
                          })
                        }
                        dataSourceTextProperty='noPlano'
                      />
                    </div>
                    <div className='col'>
                      <Autocomplete
                        label='Pacote'
                        enabled={
                          data.flgPermiteAlterar &&
                          !!data.plano?.nrSeqPlano &&
                          !!data.centroDeCusto?.nrSeqCentroDeCusto
                        }
                        placeholder={
                          !data.plano?.nrSeqPlano ||
                          !data.centroDeCusto?.nrSeqCentroDeCusto
                            ? 'Selecione Centro de Custo e o Plano'
                            : ''
                        }
                        required
                        searchDataSource={onSearchPacoteDados}
                        selectedItem={data.tipoChamada}
                        onSelectItem={async (
                          tipoChamada: any
                        ): Promise<void> => {
                          if ('nrSeqTipoChamada' in tipoChamada) {
                            const planoRateio = await getPlanoRateio(
                              tipoChamada.nrSeqTipoChamada
                            );

                            if (planoRateio) {
                              setHasPlanoRateioDefault(true);

                              setData({
                                ...data,
                                tipoChamada,
                                nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                                nrSeqTipoChamadaDados:
                                  tipoChamada.nrSeqTipoChamada,
                                vlrPacoteEncargoManual: tipoChamada.vlrVenda,
                                planoRateioEmpresa: planoRateio,
                                nrSeqPlanoRateioEmpresa: planoRateio.nrSeqPlano,
                              });
                            } else {
                              setHasPlanoRateioDefault(false);

                              setData({
                                ...data,
                                tipoChamada,
                                nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                                nrSeqTipoChamadaDados:
                                  tipoChamada.nrSeqTipoChamada,
                                vlrPacoteEncargoManual: tipoChamada.vlrVenda,
                              });
                            }
                          } else {
                            setHasPlanoRateioDefault(false);

                            setData({
                              ...data,
                              tipoChamada,
                              nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                              nrSeqTipoChamadaDados:
                                tipoChamada.nrSeqTipoChamada,
                              vlrPacoteEncargoManual: tipoChamada.vlrVenda,
                            });
                          }
                        }}
                        dataSourceTextProperty='noTipoChamada'
                      />
                    </div>
                    <div className='col-2'>
                      {/* @ts-expect-error */}
                      <Textbox
                        label='Valor Pacote'
                        visible={
                          (data.nrSeqTipoChamada ||
                            data.nrSeqTipoChamadaDados) &&
                          data.plano?.nrSeqPlano
                        }
                        text={data.vlrPacoteEncargoManual}
                        mask={MaskTypes.Decimal}
                        readOnly={
                          !data.tipoChamada.flgPermiteEditar &&
                          !data.flgPermiteAlterar
                        }
                        onChangedValue={(vlrPacoteEncargoManual: number) => {
                          setData({
                            ...data,
                            vlrPacoteEncargoManual,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <div className='col-4'>
                      <Autocomplete
                        label='Plano de Rateio Empresa'
                        searchDataSource={onSearchPlanoRateio}
                        enabled={
                          data.flgPermiteAlterar &&
                          // @ts-expect-error
                          data.empresa.nrSeqEmpresa &&
                          !hasPlanoRateioDefault
                        }
                        placeholder={
                          !data.empresa?.nrSeqEmpresa
                            ? 'Seleciona a Empresa'
                            : ''
                        }
                        selectedItem={data.planoRateioEmpresa}
                        onSelectItem={(planoRateioEmpresa: any) => {
                          setData({
                            ...data,
                            planoRateioEmpresa,
                            nrSeqPlanoRateioEmpresa:
                              planoRateioEmpresa.nrSeqPlano,
                          });
                        }}
                        dataSourceTextProperty='noPlano'
                      />
                    </div>
                    <div className='col'>
                      <Autocomplete
                        label='Forma de Pagamento'
                        enabled={data.flgPermiteAlterar}
                        required
                        searchDataSource={onAutoCompleteFormaPagamento}
                        selectedItem={data.formaPagamento}
                        onSelectItem={(formaPagamento: any): void => {
                          if (
                            formaPagamento.nrSeqFormaPagamento !== undefined &&
                            infoPagamentoDefault.nrSeqFormaPagamento !==
                              undefined
                          ) {
                            if (
                              formaPagamento.nrSeqFormaPagamento !==
                              infoPagamentoDefault.nrSeqFormaPagamento
                            ) {
                              setModalAlterarFormaPagamento({
                                show: true,
                                formaPagamento,
                              });
                            } else {
                              setData({
                                ...data,
                                formaPagamento,
                                nrSeqFormaPagamento:
                                  formaPagamento.nrSeqFormaPagamento,
                              });
                            }
                          } else {
                            setData({
                              ...data,
                              formaPagamento,
                              nrSeqFormaPagamento:
                                formaPagamento.nrSeqFormaPagamento,
                            });
                          }
                        }}
                        dataSourceTextProperty='noFormaPagamento'
                      />
                    </div>
                    <div className='col-2'>
                      <Autocomplete
                        label='Vencimento'
                        enabled={data.flgPermiteAlterar}
                        required
                        searchDataSource={onSearchVencimento}
                        selectedItem={data.vencimento}
                        onSelectItem={(vencimento: any) =>
                          setData({
                            ...data,
                            vencimento,
                            nrSeqVencimento: vencimento.nrSeqVencimento,
                          })
                        }
                        dataSourceTextProperty='noVencimento'
                      />
                    </div>
                    <div className='col'>
                      <Autocomplete
                        label='Informação Bancária'
                        enabled={data.flgPermiteAlterar}
                        // @ts-expect-error
                        visible={data.formaPagamento.flgGrupo === 'D'}
                        searchDataSource={onSearchInformacaoBancaria}
                        selectedItem={data.informacaoBancaria}
                        onSelectItem={(informacaoBancaria: any) =>
                          setData({
                            ...data,
                            informacaoBancaria,
                            nrSeqPessoaInformacaoBancaria:
                              informacaoBancaria.nrSeqPessoaInformacaoBancaria,
                          })
                        }
                        dataSourceTextProperty='noDescricao'
                      />
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col'>
                      {/* @ts-expect-error */}
                      <Textarea
                        maxLength={2000}
                        readOnly={!data.flgPermiteAlterar}
                        label='Observação'
                        text={data.noObservacao}
                        onChangedValue={(noObservacao: string) => {
                          setData({
                            ...data,
                            noObservacao,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div
                      className='col'
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        theme={Theme.Primary}
                        template={Button.Templates.Default}
                        text='Gerar Contrato'
                        onClick={onGerarContrato}
                        className='width80'
                      />
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col'>
                      <FileUpload
                        files={importedFiles}
                        onChange={(files: any): void => {
                          if (files.length > 0) {
                            if (files[0].size <= 5000000) {
                              setImportedFiles(files);
                            } else {
                              onSetMessage(
                                ResponseStatus.Error,
                                'Arquivo não pode ser maior que 5MB'
                              );
                            }
                          } else {
                            setImportedFiles(files);
                          }
                        }}
                        allowedMimeTypes={[
                          MimeTypes.Types.PDF,
                          MimeTypes.Types.Image,
                          MimeTypes.Types.Word,
                        ]}
                      />
                    </div>
                    <div className='col d-flex justify-content-center'>
                      <Button
                        text='Adicionar Documento'
                        className='px-5'
                        theme={Theme.Success}
                        size={BootstrapSizes.Medium}
                        template={Button.Templates.Default}
                        onClick={() => onClickAddDocumento()}
                      />
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col'>
                      <GridView
                        ref={gridView}
                        // @ts-expect-error
                        dataColumns={columns}
                        showPagination={false}
                        showSelectSizes={false}
                      />
                    </div>
                  </div>

                  {agendarPortabilidade && (
                    <div className='row mb-3'>
                      <div className='row mb-2'>
                        {/* @ts-expect-error */}
                        <Panel>
                          {/* @ts-expect-error */}
                          <Panel.Header
                            title='Agendar Portabilidade'
                            theme={Theme.Primary}
                            align={JustifyContent.Start}
                          />
                          <Panel.Body>
                            <div className='accordion-body'>
                              <div className='row mb-3'>
                                <div className='card'>
                                  <div className='card-body'>
                                    <div className='row mb-4'>
                                      <div className='col-2'>
                                        <DatePicker
                                          label='Data Portabilidade'
                                          readOnly={!data.flgPermiteAlterar}
                                          required
                                          text={data?.dtPortabilidade}
                                          onChange={(
                                            dtPortabilidade: Date | string
                                          ) => {
                                            if (
                                              dtPortabilidade &&
                                              dtPortabilidade !== ''
                                            )
                                              searchHorariosPortabilidade(
                                                dtPortabilidade
                                              );
                                            else {
                                              setDropDownHorariosSource([]);
                                            }
                                            setData({
                                              ...data,
                                              dtPortabilidade,
                                            });
                                          }}
                                        />
                                      </div>
                                      <div className='col-3'>
                                        <DropdownList
                                          label='Horários disponíveis'
                                          enabled={data.flgPermiteAlterar}
                                          dataSource={dropDownHorariosSource}
                                          dataSourcePropertyText='noHoraInicioFim'
                                          dataSourcePropertyValue='nrSeqHoraInicioFim'
                                          selectedItems={data.horarios ?? []}
                                          onSelectItem={(item: any) => {
                                            setData({
                                              ...data,
                                              horarios: item,
                                              noHoraInicioFim:
                                                item.noHoraInicioFim,
                                              nrSeqHoraInicioFim:
                                                item.nrSeqHoraInicioFim,
                                            });
                                          }}
                                        />
                                      </div>
                                      <div className='col mt-3'>
                                        <Button
                                          theme={Theme.Primary}
                                          template={Button.Templates.Default}
                                          text='Solicitar'
                                          onClick={() =>
                                            onSolicitaPortabilidade()
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Panel.Body>
                        </Panel>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
