export default class CarteiraTipo {
  constructor(jsonObject = {}) {
    this.nrSeqCarteiraTipo = jsonObject.nrSeqCarteiraTipo;
    this.noCarteiraTipo = jsonObject.noCarteiraTipo;
    this.cdCarteiraTipo = jsonObject.cdCarteiraTipo;
    this.obs = jsonObject.obs;
    this.nrSeqGrupoempresa = jsonObject.nrSeqGrupoempresa;
  }

  toJson = () => JSON.stringify(this);
}
