import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Textbox, Switch } from 'ui/components';

import { getCfo, saveCfo, deleteCfo } from 'core/services/FIS/cfo';

export default function CfoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getCfo(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveCfo(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteCfo(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Cfo'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqCfo > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            required
            maxLength={4}
            label='CdCfo'
            text={data.cdCfo}
            onChangedValue={(cdCfo) => setData({ ...data, cdCfo })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={500}
            label='NoCfo'
            text={data.noCfo}
            onChangedValue={(noCfo) => setData({ ...data, noCfo })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='FlgAtivo'
            checked={data.flgAtivo}
            onChange={(flgAtivo) => setData({ ...data, flgAtivo })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='FlgVenda'
            checked={data.flgVenda}
            onChange={(flgVenda) => setData({ ...data, flgVenda })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='FlgCompra'
            checked={data.flgCompra}
            onChange={(flgCompra) => setData({ ...data, flgCompra })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='FlgExcecaoICMS'
            checked={data.flgExcecaoICMS}
            onChange={(flgExcecaoICMS) => setData({ ...data, flgExcecaoICMS })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='FlgExcecaoIPI'
            checked={data.flgExcecaoIPI}
            onChange={(flgExcecaoIPI) => setData({ ...data, flgExcecaoIPI })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='FlgServico'
            checked={data.flgServico}
            onChange={(flgServico) => setData({ ...data, flgServico })}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='FlgConsumidorFinal'
            checked={data.flgConsumidorFinal}
            onChange={(flgConsumidorFinal) =>
              setData({ ...data, flgConsumidorFinal })
            }
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
