export default class ProdutoGrupoTipo {
  constructor(jsonObject = {}) {
    this.nrSeqProdutoGrupoTipo = jsonObject.nrSeqProdutoGrupoTipo;
    this.cdProdutoGrupoTipo = jsonObject.cdProdutoGrupoTipo;
    this.noProdutoGrupoTipo = jsonObject.noProdutoGrupoTipo;
    this.flgServico = jsonObject.flgServico;
  }

  toJson = () => JSON.stringify(this);
}
