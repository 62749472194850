import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Textbox, Switch } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';

import {
  getTipoDocumentoPessoa,
  saveTipoDocumentoPessoa,
  deleteTipoDocumentoPessoa,
} from 'core/services/SEG/tipoDocumentoPessoa';

export default function TipoDocumentoPessoaItem({
  registryKey,
  transaction,
  reload,
  onSelectPage,
  isActive,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({ flgControlaValidade: false });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      setData(await getTipoDocumentoPessoa(registryKey));
      setLoading(false);
    } else setData({ flgControlaValidade: false });
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveTipoDocumentoPessoa(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteTipoDocumentoPessoa(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção - Tipo Documento Pessoa'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({ flgControlaValidade: false })}
      onSave={save}
      onDelete={data?.nrSeqTipoDocumentoPessoa > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-1'>
          <Textbox
            maxLength={20}
            label='Código'
            text={data.cdTipoDocumentoPessoa}
            onChangedValue={(cdTipoDocumentoPessoa) =>
              setData({ ...data, cdTipoDocumentoPessoa })
            }
            readOnly
          />
        </div>
        <div className='col-11'>
          <Textbox
            maxLength={80}
            label='Nome'
            text={data.noTipoDocumentoPessoa}
            onChangedValue={(noTipoDocumentoPessoa) =>
              setData({ ...data, noTipoDocumentoPessoa })
            }
            required
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Aceita Imagem'
            checked={data.flgAceitaImg}
            onChange={(flgAceitaImg) => setData({ ...data, flgAceitaImg })}
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Veículo'
            checked={data.flgVeiculo}
            onChange={(flgVeiculo) => setData({ ...data, flgVeiculo })}
            tooltip='Exibir no cadastro de Veículos?'
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Cliente'
            checked={data.flgCliente}
            onChange={(flgCliente) => setData({ ...data, flgCliente })}
            tooltip='Exibir no cadastro de Clientes?'
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Funcionário'
            checked={data.flgFuncionario}
            onChange={(flgFuncionario) => setData({ ...data, flgFuncionario })}
            tooltip='Exibir no cadastro de Funcionários?'
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Motorista'
            checked={data.flgMotorista}
            onChange={(flgMotorista) => setData({ ...data, flgMotorista })}
            tooltip='Exibir no cadastro de Motoristas?'
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Associado'
            checked={data.flgAssociado}
            onChange={(flgAssociado) => setData({ ...data, flgAssociado })}
            tooltip='Exibir no cadastro de Associados?'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Transportadora'
            checked={data.flgTransportadora}
            onChange={(flgTransportadora) =>
              setData({ ...data, flgTransportadora })
            }
            tooltip='Exibir no cadastro de Transportadoras?'
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Posto Combustível'
            checked={data.flgPostoCombustivel}
            onChange={(flgPostoCombustivel) =>
              setData({ ...data, flgPostoCombustivel })
            }
            tooltip='Exibir no cadastro de Posto de Combustível?'
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Consignatário'
            checked={data.flgConsignatario}
            onChange={(flgConsignatario) =>
              setData({ ...data, flgConsignatario })
            }
            tooltip='Exibir no cadastro de Consignatários?'
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Remetente'
            checked={data.flgRemetente}
            onChange={(flgRemetente) => setData({ ...data, flgRemetente })}
            tooltip='Exibir no cadastro de Remetentes?'
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Destinatário'
            checked={data.flgDestinatario}
            onChange={(flgDestinatario) =>
              setData({ ...data, flgDestinatario })
            }
            tooltip='Exibir no cadastro de Destinatário?'
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Usuário'
            checked={data.flgUsuario}
            onChange={(flgUsuario) => setData({ ...data, flgUsuario })}
            tooltip='Exibir no cadastro de Usuário?'
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Controla Validade'
            checked={data.flgControlaValidade}
            onChange={(flgControlaValidade) =>
              setData({ ...data, flgControlaValidade })
            }
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Exibe Mobile'
            checked={data.flgExibeMobile}
            onChange={(flgExibeMobile) => setData({ ...data, flgExibeMobile })}
          />
        </div>

        <div className='col mb-2'>
          <Textbox
            label='Dias Alerta Vencimento'
            mask={MaskTypes.Integer}
            text={data.nrDiasAlertaVencimento}
            onChangedValue={(nrDiasAlertaVencimento) =>
              setData({ ...data, nrDiasAlertaVencimento })
            }
            visible={data.flgControlaValidade}
          />
        </div>

        <div className='col mb-3'>
          <Textbox
            maxLength={200}
            label='Email para Notificação'
            text={data.emailNotificacao}
            onChangedValue={(emailNotificacao) =>
              setData({ ...data, emailNotificacao })
            }
            visible={data.flgControlaValidade}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
