export default class ProdutoInventarioItem {
  constructor(jsonObject = {}) {
    this.nrSequencia = jsonObject.nrSequencia;
    this.qtdeContada = jsonObject.qtdeContada;
    this.nrSeqAlmoxarifadoLocal = jsonObject.nrSeqAlmoxarifadoLocal;
    this.dtContagem = jsonObject.dtContagem;
    this.flgDigitada = jsonObject.flgDigitada;
    this.vlrCusto = jsonObject.vlrCusto;
    this.saldo = jsonObject.saldo;
    this.nrSeqInventario = jsonObject.nrSeqInventario;
    this.nrSeqEstoqueMovimento = jsonObject.nrSeqEstoqueMovimento;
    this.nrOrdem = jsonObject.nrOrdem;
    this.diferenca = jsonObject.diferenca;

    this.noProduto = jsonObject.noProduto;
    this.noAlmoxarifadoLocal = jsonObject.noAlmoxarifadoLocal;
    this.cdPeca = jsonObject.cdPeca;
    this.noProdutoLinha = jsonObject.noProdutoLinha;
    this.noProdutoGrupo = jsonObject.noProdutoGrupo;
    this.noProdutoSubGrupo = jsonObject.noProdutoSubGrupo;
    this.cdProdutoUnidadeMedida = jsonObject.cdProdutoUnidadeMedida;
  }

  toJson = () => JSON.stringify(this);
}
