import React from 'react';
import { Button } from 'ui/components';
import { Theme } from 'ui/Helpers/utils';

const LinhasSelecionadas = (props) => {
  const linhasSelecionadas = props;

  const rows = linhasSelecionadas.linhas.map((linha) => (
    <Button
      icon='ban'
      key={linha.nrSeqChip}
      outline
      theme={Theme.Danger}
      text={`(${linha.nrPrefixo})${linha.nrLinha}`}
      onClick={() => props.onRemoveLinha(linha)}
    />
  ));
  return (
    <div>
      <div className='row mb-3'>
        <div className='col-3'>
          <span>
            Total de Linhas Selecionadas: {linhasSelecionadas.linhas.length}
          </span>
        </div>
      </div>
      <div className='d-flex flex-wrap bg-light'>{rows}</div>
    </div>
  );
};

export default LinhasSelecionadas;
