export default class ProdutoServico {
  constructor(jsonObject = {}) {
    this.nrSeqProdutoServico = jsonObject.nrSeqProdutoServico;
    this.cdProdutoServico = jsonObject.cdProdutoServico;
    this.noProdutoServico = jsonObject.noProdutoServico;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.codigoTributacaoMunicipio = jsonObject.codigoTributacaoMunicipio;
    this.codigoCnae = jsonObject.codigoCnae;
    this.noDescricao = jsonObject.noDescricao;
  }

  toJson = () => JSON.stringify(this);
}
