import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Switch, Textbox, Autocomplete } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getTituloEspeciePlanoDeConta,
  saveTituloEspeciePlanoDeConta,
  deleteTituloEspeciePlanoDeConta,
} from 'core/services/FIN/tituloEspeciePlanoDeConta';

import { getPlanoDeContaList } from 'core/services/FIN/planoDeConta';

export default function TituloEspeciePlanoDeContaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getTituloEspeciePlanoDeConta(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveTituloEspeciePlanoDeConta(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteTituloEspeciePlanoDeConta(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchTituloEspecie = async (e) => {
    const { status, message: msg, tituloEspecies } = await getPlanoDeContaList({
      noTituloEspecie: e,
    });
    if (msg) onSetMessage(status, msg);
    return tituloEspecies;
  };

  const onSearchPlanoDeContaCred = async (e) => {
    const {
      status,
      message: msg,
      planoDeContaCreds,
    } = await getPlanoDeContaList({ noPlanoDeContaCred: e });
    if (msg) onSetMessage(status, msg);
    return planoDeContaCreds;
  };

  const onSearchPlanoDeContaDeb = async (e) => {
    const {
      status,
      message: msg,
      planoDeContaDebs,
    } = await getPlanoDeContaList({ noPlanoDeContaDeb: e });
    if (msg) onSetMessage(status, msg);
    return planoDeContaDebs;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de TituloEspecie_PlanoDeConta'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqTituloEspeciePlanoDeConta > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='FlgPercentual'
            checked={data.flgPercentual}
            onChange={(flgPercentual) => setData({ ...data, flgPercentual })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            label='VlrPercentual'
            text={data.vlrPercentual}
            mask={MaskTypes.Decimal}
            onChangedValue={(vlrPercentual) =>
              setData({ ...data, vlrPercentual })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='TituloEspecie'
            searchDataSource={onSearchTituloEspecie}
            selectedItem={data.tituloEspecie}
            onSelectItem={(tituloEspecie) => {
              setData({
                ...data,
                tituloEspecie,
                nrSeqTituloEspecie: tituloEspecie.nrSeqTituloEspecie,
              });
            }}
            dataSourceTextProperty='noTituloEspecie'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='PlanoDeContaCred'
            searchDataSource={onSearchPlanoDeContaCred}
            selectedItem={data.planoDeContaCred}
            onSelectItem={(planoDeContaCred) => {
              setData({
                ...data,
                planoDeContaCred,
                nrSeqPlanoDeContaCred: planoDeContaCred.nrSeqPlanoDeContaCred,
              });
            }}
            dataSourceTextProperty='noPlanoDeContaCred'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='PlanoDeContaDeb'
            searchDataSource={onSearchPlanoDeContaDeb}
            selectedItem={data.planoDeContaDeb}
            onSelectItem={(planoDeContaDeb) => {
              setData({
                ...data,
                planoDeContaDeb,
                nrSeqPlanoDeContaDeb: planoDeContaDeb.nrSeqPlanoDeContaDeb,
              });
            }}
            dataSourceTextProperty='noPlanoDeContaDeb'
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
