import {
  getManutencaoPreventiva,
  getManutencaoPreventivaAutoComplete,
} from 'core/services/EST/manutencaoPreventiva';
import React, { forwardRef, useState, useRef, useEffect } from 'react';
import {
  Autocomplete,
  Button,
  DatePicker,
  GridView,
  Textbox,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';

const ManutencaoPreventivaStep = forwardRef(
  ({ data, setData, onSetMessage }) => {
    const gridView = useRef();
    const [dataManutPreventiva, setDataManutPreventiva] = useState({});

    useEffect(() => {
      if (gridView && gridView.current && data.veiculoManutencoesPreventiva) {
        const gridList = data.veiculoManutencoesPreventiva.map((item) => {
          if (item.manutencaoPreventiva?.tipoIntervalo?.flgTipo) {
            const gridDate = new Date(item.dtMovimento);

            const diaInicial = String(gridDate.getDate()).padStart(2, '0');
            const mesInicial = String(gridDate.getMonth() + 1).padStart(2, '0');
            const anoInicial = gridDate.getFullYear();

            const valorInicial = `${diaInicial}/${mesInicial}/${anoInicial}`;

            gridDate.setDate(
              gridDate.getDate() + item.manutencaoPreventiva?.valor
            );

            const diaFinal = String(gridDate.getDate()).padStart(2, '0');
            const mesFinal = String(gridDate.getMonth() + 1).padStart(2, '0');
            const anoFinal = gridDate.getFullYear();

            const valorFinal = `${diaFinal}/${mesFinal}/${anoFinal}`;

            return {
              ...item,
              valorInicial,
              valorFinal,
            };
          }

          const valorFinal = item.nrOdometro + item.manutencaoPreventiva?.valor;

          const valorInicial = item.nrOdometro;

          return {
            ...item,
            valorInicial,
            valorFinal,
          };
        });

        gridView.current.setDataSource(gridList);
      } else {
        gridView.current.setDataSource([]);
      }
    }, [data]);

    const onSearchManutencaoPreventiva = async (e) => {
      const {
        data: manutencoesPreventiva,
      } = await getManutencaoPreventivaAutoComplete({
        noManutencaoPreventiva: e,
      });

      return manutencoesPreventiva;
    };

    const onSelectManutencaoPreventiva = async (nrSeqManutencaoPreventiva) => {
      const manutencaoPreventiva = await getManutencaoPreventiva(
        nrSeqManutencaoPreventiva
      );

      setDataManutPreventiva({
        ...manutencaoPreventiva,
        manutencaoPreventiva,
      });
    };

    const onPickDateValorInicial = async (valorInicial) => {
      const date = new Date(valorInicial);
      date.setDate(date.getDate() + 1);

      const diaInicial = String(date.getDate()).padStart(2, '0');
      const mesInicial = String(date.getMonth() + 1).padStart(2, '0');
      const anoInicial = date.getFullYear();

      date.setDate(date.getDate() + dataManutPreventiva.valor);

      const diaFinal = String(date.getDate()).padStart(2, '0');
      const mesFinal = String(date.getMonth() + 1).padStart(2, '0');
      const anoFinal = date.getFullYear();

      const valorFinal = `${diaFinal}/${mesFinal}/${anoFinal}`;

      setDataManutPreventiva({
        ...dataManutPreventiva,
        valorInicial,
        dtMovimento: `${anoInicial}-${mesInicial}-${diaInicial}`,
        valorFinal,
      });
    };

    const onClickAddManutencaoPreventiva = async () => {
      if (
        dataManutPreventiva.nrSeqManutencaoPreventiva &&
        dataManutPreventiva.valorInicial
      ) {
        const obj = {
          ...dataManutPreventiva,
          status: dataManutPreventiva.status ?? GridView.EnumStatus.Inserir,
        };

        if (dataManutPreventiva.tipoIntervalo?.flgTipo) {
          const datePieces = obj.valorInicial.split('-');
          obj.valorInicial = `${datePieces[2]}/${datePieces[1]}/${datePieces[0]}`;
        }

        const list = gridView.current.getDataSource();
        list.push(obj);

        if (gridView && gridView.current) gridView.current.setDataSource(list);

        setData({ ...data, veiculoManutencoesPreventiva: list });
        setDataManutPreventiva({});
      } else if (!dataManutPreventiva.nrSeqManutencaoPreventiva) {
        onSetMessage(false, 'Informe a Manutenção Preventiva');
      } else if (!dataManutPreventiva.valorInicial) {
        onSetMessage(false, 'Informe o Valor Inicial');
      }
    };

    const onClickRemoveManutencaoPreventiva = async (e, datasource) => {
      const gridItem = datasource.find((el) => el === e);

      const list = datasource.filter((el) => el !== e);

      if (
        gridItem.status !== GridView.EnumStatus.Inserir &&
        gridItem.status !== GridView.EnumStatus.Alterar
      ) {
        gridItem.status = GridView.EnumStatus.Remover;

        list.push(gridItem);
      }

      if (gridView && gridView.current) gridView.current.setDataSource(list);

      setData({ ...data, veiculoManutencoesPreventiva: list });
    };

    const columns = [
      {
        key: 'manutencaoPreventiva.noManutencaoPreventiva',
        title: 'Manutenção Preventiva',
        sortable: false,
      },
      {
        key: 'manutencaoPreventiva.tipoIntervalo.noTipoIntervalo',
        title: 'Tipo Intervalo',
        sortable: false,
      },
      {
        key: 'valorInicial',
        title: 'Inicial',
        sortable: false,
      },
      {
        key: 'valorFinal',
        title: 'Final',
        sortable: false,
      },
      {
        key: 'nrSeqVeiculo_ManutencaoPrevent',
        type: GridView.ColumnTypes.Button,
        title: 'Excluir',
        onClick: (e, dataSource) =>
          onClickRemoveManutencaoPreventiva(e, dataSource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    return (
      <div>
        <div className='row'>
          <div className='col-4'>
            <Autocomplete
              label='Manutenção'
              searchDataSource={onSearchManutencaoPreventiva}
              selectedItem={dataManutPreventiva.manutencaoPreventiva}
              onSelectItem={(item) =>
                onSelectManutencaoPreventiva(item.nrSeqManutencaoPreventiva)
              }
              dataSourceTextProperty='noManutencaoPreventiva'
            />
          </div>
          {dataManutPreventiva.nrSeqManutencaoPreventiva && (
            <>
              <div className='col'>
                <Textbox
                  label='Tipo de Intervalo'
                  readOnly
                  text={dataManutPreventiva.tipoIntervalo.noTipoIntervalo}
                />
              </div>
              {!dataManutPreventiva.tipoIntervalo.flgTipo ? (
                <>
                  <div className='col'>
                    <Textbox
                      label='Inicial'
                      maxLength={10}
                      text={dataManutPreventiva.valorInicial}
                      mask={MaskTypes.Integer}
                      onChangedValue={(valorInicial) => {
                        setDataManutPreventiva({
                          ...dataManutPreventiva,
                          valorInicial,
                          valorFinal:
                            valorInicial && valorInicial >= 0
                              ? dataManutPreventiva.valor + valorInicial
                              : null,
                          nrOdometro: valorInicial,
                        });
                      }}
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='Proximo'
                      readOnly
                      text={dataManutPreventiva.valorFinal}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className='col'>
                    <DatePicker
                      label='Inicial'
                      maxLength={10}
                      mask={MaskTypes.Date}
                      text={dataManutPreventiva.valorInicial}
                      onChange={(valorInicial) => {
                        onPickDateValorInicial(valorInicial);
                      }}
                    />
                  </div>
                  <div className='col'>
                    <DatePicker
                      label='Proximo'
                      readOnly
                      maxLength={10}
                      mask={MaskTypes.Date}
                      text={dataManutPreventiva.valorFinal}
                    />
                  </div>
                </>
              )}

              <div className='col d-flex align-items-end'>
                <Button
                  onClick={onClickAddManutencaoPreventiva}
                  outline
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  text='Adicionar'
                />
              </div>
            </>
          )}
        </div>
        <div className='row'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            offlineData
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
    );
  }
);

export default ManutencaoPreventivaStep;
