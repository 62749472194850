/* eslint-disable */
export default class Tipoinfrator {
  constructor(jsonObject = {}) {
    this.nrSeqTipoInfrator = jsonObject.nrSeqTipoInfrator;
    this.cdTipoInfrator = jsonObject.cdTipoInfrator;
    this.noTipoInfrator = jsonObject.noTipoInfrator;
    this.nrSeqGrupoempresa = jsonObject.nrSeqGrupoempresa;
  }

  toJson = () => JSON.stringify(this);
}
