import React, { useState, useEffect } from 'react';
import { Loader, Modal, Notification, Textarea, Button } from 'ui/components';
import { BootstrapSizes, Theme } from 'ui/Helpers/enums';
import { Message } from 'ui/Helpers/interfaces';
import { IPneu } from 'core/interfaces/FRO/pneu';
// @ts-expect-error
import ModalPneuModeloRecapagem from './ModalPneuModeloRecapagem.tsx';

interface IProps {
  show: any;
  onClose: () => void;
}

interface IPneuSucata {
  pneu: IPneu;
  kmInicio: number;
  kmFim: number | null;
  kmAcumulado: number | null;
  dtFim: string | null;
  noObsMotivoTroca: string;
}

export default function ModalSucata({ show, onClose }: IProps) {
  const [message, setMessage] = useState<Message | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [sucata, setSucata] = useState<IPneuSucata | null>(null);
  const [modalShow, setModalShow] = useState<boolean>(false);

  useEffect(() => {
    if (show.showPneuSucata === true) {
      setLoading(true);
      setSucata(show.sucata);
      setLoading(false);
    }
  }, [show.showPneuSucata]);

  return (
    // @ts-expect-error
    <Modal
      show={show.showPneuSucata}
      title='Cadastro Rápido de Modelo de Recapagem'
      size={BootstrapSizes.Large}
      onClose={onClose}
    >
      <Loader loading={loading} />
      {message && (
        <div className='m-3'>
          {/* @ts-expect-error */}
          <Notification
            message={message.message}
            theme={message.theme}
            onClose={() => setMessage(null)}
          />
        </div>
      )}

      {/* @ts-expect-error */}
      <Modal.Body>
        <div className='row mb-2'>
          <div className='col'>
            {/* @ts-expect-error */}
            <Textarea
              label='Observação'
              text={sucata?.noObsMotivoTroca}
              onChangedValue={(noObsMotivoTroca: string) =>
                // @ts-expect-error
                setSucata({ ...sucata, noObsMotivoTroca })
              }
            />
          </div>
        </div>

        <div className='row'>
          <div className='col d-flex justify-content-end align-items-end'>
            <Button
              text='Enviar para Sucata'
              theme={Theme.Success}
              // @ts-expect-error
              onClick={() => onClose({ sucata, enviaSucata: true })}
            />
          </div>
        </div>
      </Modal.Body>

      <ModalPneuModeloRecapagem
        show={modalShow}
        onClose={() => {
          setModalShow(false);
        }}
      />
    </Modal>
  );
}
