import React from 'react';

import { Button, Modal } from 'ui/components';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';

export default function ModalConfirmDelete(props) {
  const { show, text } = props;

  const handleConfirm = async () => {
    props.onSave(false);
  };

  const cancelText = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
    });
  };

  return (
    <Modal
      show={show}
      text={text}
      title='Confirmar Exclusao'
      size={BootstrapSizes.Large}
      icon='exclamation-triangle'
    >
      <Modal.Body>{text && <span>{text}</span>}</Modal.Body>

      <Modal.Footer>
        <Button
          text='Confirmar'
          onClick={handleConfirm}
          theme={Theme.Success}
        />
        <Button text='Cancelar' onClick={cancelText} theme={Theme.Light} />
      </Modal.Footer>
    </Modal>
  );
}
