import React, { useState, useRef } from 'react';

import { getVendedorAutoComplete } from 'core/services/VEN/vendedor';

import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';

import { MaskTypes, mask } from 'ui/Helpers/masks';

import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getTipoDespesaAutoComplete } from 'core/services/FIN/tipoDespesa';
import {
  postCalcularComissaoVendedor,
  postGerarTituloPagarComissao,
} from 'core/services/VEN/pedido';
import {
  Autocomplete,
  Button,
  FontAwesomeIcon,
  GridView,
  Loader,
  Notification,
  Panel,
  Textbox,
  DatePicker,
  Switch,
} from 'ui/components';

import './calcularComissao.scss';

export default function CalcularComissao({
  className,
  findTransaction,
  onOpenTransaction,
}) {
  const gridRecursosComissao = useRef();

  const [state, setState] = useState([]);

  const [loading, setLoading] = useState(false);

  const [filtros, setFiltros] = useState({ flgPendenteComissao: true });

  const calcularComissao = async () => {
    setLoading(true);
    const { data } = await postCalcularComissaoVendedor(filtros);

    gridRecursosComissao.current.setDataSource(data);

    let cont = 0;
    let valorComissao = 0;
    for (cont = 0; cont < data.length; cont += 1)
      valorComissao += data[cont].vlrComissao;

    setFiltros({
      ...filtros,
      pedidoItens: data,
      vlrComissaoTotal: valorComissao,
      vlrBaseComissaoTotal: valorComissao,
      vlrComissaoTotalAlterado: valorComissao,
    });

    setLoading(false);
  };

  const onAutoCompleteFormaPagamento = async (e) => {
    const {
      status,
      message,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
    });

    if (message)
      setState({
        ...state,
        message,
        messageTheme:
          status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return formasPagamento;
  };
  const onAutoCompleteTipoDespesa = async (e) => {
    const { status, message, tipoDespesas } = await getTipoDespesaAutoComplete({
      noTipoDespesa: e,
    });

    if (message)
      setState({
        ...state,
        message,
        messageTheme:
          status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return tipoDespesas;
  };

  const onGerarTitulo = async () => {
    setLoading(true);
    const { status, message } = await postGerarTituloPagarComissao({
      ...filtros,
      pedidosItens: filtros.pedidoItens,
    });
    if (message)
      setState({
        ...state,
        message,
        messageTheme:
          status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    setLoading(false);
  };

  const onSearchVendedor = async (e) => {
    const { status, message, vendedores } = await getVendedorAutoComplete({
      noPessoa: e,
    });

    if (message)
      setState({
        message,
        messageTheme:
          status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return vendedores;
  };

  const onClickOpenPedido = (selectedValue) => {
    const transactionPedido = findTransaction(TransacationCodes.Pedido);

    if (transactionPedido) {
      transactionPedido.registryKey = selectedValue.nrSeqPedido;
      onOpenTransaction(transactionPedido, true);
    }
  };

  const { message, messageTheme } = state;

  const columns = [
    { key: 'nrSeqProduto', type: GridView.ColumnTypes.Checkbox },
    { key: 'nrPed', title: 'Nr Pedido' },
    { key: 'produto.noProdutoAgrupador', title: 'Produto' },
    { key: 'vlrItPed', title: 'Valor', format: GridView.DataTypes.Decimal },
    {
      key: 'qtdeItPed',
      title: 'Quantidade',
      format: GridView.DataTypes.Decimal,
    },
    { key: 'vlrItFrete', title: 'Frete', format: GridView.DataTypes.Decimal },

    { key: 'vlrTotPed', title: 'Total', format: GridView.DataTypes.Decimal },
    {
      key: 'vlrComissao',
      title: 'Comissão',
      format: GridView.DataTypes.Decimal,
    },

    {
      key: 'nrSeqPedido',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOpenPedido(e),
      theme: Theme.Success,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Exibir Pedido',
      tooltipDirection: 'bottom',
      title: 'Ped',
    },
  ];

  return (
    <div className={className}>
      <Loader loading={loading} />
      <Panel>
        <Panel.Body>
          {message && (
            <div className='mb-3'>
              <Notification
                message={message}
                theme={messageTheme}
                onClose={() => setState({ ...state, message: undefined })}
              />
            </div>
          )}

          <div className='row mb-3'>
            <div className='col-4'>
              <Autocomplete
                label='Vendedor'
                searchDataSource={onSearchVendedor}
                selectedItem={filtros.vendedor}
                dataSourceTextProperty='noPessoa'
                onSelectItem={(vendedor) =>
                  setFiltros({
                    ...filtros,
                    vendedor,
                    nrSeqPessoaVen: vendedor.nrSeqPessoa,
                  })
                }
              />
            </div>

            <div className='col-2'>
              <DatePicker
                label='Data Inicial'
                text={filtros.dtInicial}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtInicial) => {
                  setFiltros({ ...filtros, dtInicial });
                }}
              />
            </div>

            <div className='col-2'>
              <DatePicker
                label='Data'
                text={filtros.dtFinal}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtFinal) => {
                  setFiltros({ ...filtros, dtFinal });
                }}
              />
            </div>

            <div className='col-2'>
              <Switch
                formControl
                label='Comissão Pendente'
                checked={filtros.flgPendenteComissao}
                onChange={(flgPendenteComissao) =>
                  setFiltros({
                    ...filtros,
                    flgPendenteComissao,
                  })
                }
              />
            </div>

            <div className='col-auto'>
              <Button
                text='Calcular'
                size={BootstrapSizes.Small}
                theme={Theme.Secondary}
                template={Button.Templates.Quick}
                icon='search'
                onClick={calcularComissao}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>

      <div className='row bg-inactive pt-3 pb-3'>
        <div className='col'>
          <div className='bg-white'>
            <div className='row'>
              <div className='col-auto'>
                <div className='widget-square bg-gradient-primary text-white p-4 br-tl-1 br-bl-1'>
                  <FontAwesomeIcon className='fs-1' icon='cash-register' />
                </div>
              </div>
              <div className='col align-self-end'>
                <div className='row'>
                  <div className='col-auto d-flex align-items-center ms-3 me-2'>
                    <div>
                      <div>Resultado:</div>
                      <div
                        className={`fw-bolder ${
                          filtros?.vlrBaseComissaoTotal >= 0
                            ? 'text-success'
                            : 'text-danger'
                        } display-6`}
                      >
                        {mask(
                          filtros?.vlrBaseComissaoTotal ?? 0,
                          MaskTypes.Currency
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-auto d-flex align-items-end ms-3 p-3'>
                    <div>
                      <Textbox
                        label='Valor da comissão'
                        text={filtros.vlrComissaoTotalAlterado}
                        mask={MaskTypes.Decimal}
                        onChangedValue={(vlrComissaoTotalAlterado) =>
                          setFiltros({
                            ...filtros,
                            vlrComissaoTotalAlterado,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <hr className='mb-0 text-primary' />
                  </div>
                </div>

                <div className='row'>
                  <div className='col'>
                    <hr className='mb-0 text-primary' />
                  </div>
                </div>
              </div>
            </div>

            <div className='col mt-3'>
              <GridView
                ref={gridRecursosComissao}
                className='table-striped table-hover table-bordered table-sm'
                enableExcelExport
                dataColumns={columns}
                showPagination={false}
                showSelectSizes={false}
                gridSizeList={[9999]}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-3 bg-inactive'>
        <div className='col'>
          <div className='bg-white'>
            <div className='p-4 pt-3'>
              <div className='row'>
                <div className='col'>
                  <span className='display-7'>Titulo a Pagar</span>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <hr />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <div className='row mb-3'>
                    <div className='col'>
                      <Autocomplete
                        label='Forma de Pagamento'
                        searchDataSource={onAutoCompleteFormaPagamento}
                        selectedItem={filtros.formaPagamento}
                        visible={filtros.calculoComissaoPendente}
                        onSelectItem={(formaPagamento) =>
                          setFiltros({
                            ...filtros,
                            formaPagamento,
                            nrSeqFormaPagamento:
                              formaPagamento.nrSeqFormaPagamento,
                          })
                        }
                        dataSourceTextProperty='noFormaPagamento'
                      />
                    </div>
                    <div className='col'>
                      <Autocomplete
                        label='Tipo de Despesa'
                        searchDataSource={onAutoCompleteTipoDespesa}
                        selectedItem={filtros.tipoDespesa}
                        onSelectItem={(tipoDespesa) =>
                          setFiltros({
                            ...filtros,
                            tipoDespesa,
                            nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
                          })
                        }
                        dataSourceTextProperty='noTipoDespesa'
                      />
                    </div>
                    <div className='col-auto'>
                      <Textbox
                        label='Data de Vencimento'
                        text={filtros.dataVencimento}
                        maxLength={10}
                        mask={MaskTypes.Date}
                        onChangedValue={(dataVencimento) =>
                          setFiltros({ ...filtros, dataVencimento })
                        }
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <Textbox
                        label='Observações'
                        text={filtros.observacao}
                        required
                        onChangedValue={(e) =>
                          setFiltros({
                            ...filtros,
                            observacao: e,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className='col-auto d-flex align-items-center'>
                  <Button
                    text='Gerar Titulo'
                    size={BootstrapSizes.Medium}
                    theme={Theme.Success}
                    template={Button.Templates.Quick}
                    icon='file-invoice-dollar'
                    onClick={onGerarTitulo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
