import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  BootstrapSizes,
  JustifyContent,
  MimeTypes,
  PageTypes,
  ResponseStatus,
  Theme,
  toBase64,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  DatePicker,
  Switch,
  Panel,
  RadioButton,
  GridView,
  Button,
  ToolbarButtons,
  FileUpload,
  Confirm,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getTituloPagar,
  saveTituloPagar,
  deleteTituloPagar,
  getTituloPagarPlanoDeContaPagined,
  getUsuario,
  getRetornoCodigoBarras,
  getMultipag,
  RecalculaValorVeiculos,
  RatearPlanoDeContaAutomatico,
} from 'core/services/FIN/tituloPagar';
import { getTipoDespesaAutoComplete } from 'core/services/FIN/tipoDespesa';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getTituloPagarTipoDocumentoAutoComplete } from 'core/services/FIN/tituloPagarTipoDocumento';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';
import {
  getRetornoGridPlanoConta,
  getTipoDespesaPlanoDeContaTituloPagar,
} from 'core/services/FIN/tipoDespesa_PlanoDeConta';
import { TipoDespesa } from 'core/models/FIN';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { getTipoServicoBancarioAutoComplete } from 'core/services/FIN/tipoServicoBancario';
import { getFormaLancamentoEletronicoAutoComplete } from 'core/services/FIN/formaLancamentoEletronico';
import { getTipoMovimentoAutoComplete } from 'core/services/FIN/tipoMovimento';
import { getInstrucaoMovimentoAutoComplete } from 'core/services/FIN/instrucaoMovimento';
import { getTipoMovimentoRemessaAutoComplete } from 'core/services/FIN/tipoMovimentoRemessa';
import { getPessoaAutoComplete } from 'core/services/SEG';
import { Usuario } from 'core/models/SEG';
import { setTransaction } from 'core/services/api';
import ModalOcorrencias from './modalOcorrencias';
import ModalNovoTituloComSaldo from './modalNovoTituloComSaldo';
import ModalDesagruparTitulo from './ModalDesagruparTitulo';

export default function TituloPagarItem({
  registryKey,
  transaction,
  reload,
  onSelectPage,
  isActive,
  findTransaction,
  onOpenTransaction,
  onOpenReport,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({
    geraSequencia: false,
  });
  const [modalShow, setModalShow] = useState({});
  const [parcela, setParcela] = useState({ tipoParcela: 1 });
  const [edicaoParcela, setEdicaoParcela] = useState({});
  const [placa, setPlaca] = useState({});
  const [edicao, setEdicao] = useState(false);
  const [planoConta, setPlanoConta] = useState({});
  const [edicaoTipoDespesa, setEdicaoTipoDespesa] = useState(false);
  const [edicaoDespesa, setDespesa] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [importedFile, setImportedFile] = useState([]);
  const [obs, SetObs] = useState([]);
  const gridView = useRef();
  const gridView2 = useRef();
  const gridView3 = useRef();
  const gridViewDocumentos = useRef();
  const [
    showConfirmaDeleteCustoFixo,
    setShowConfirmaDeleteCustoFixo,
  ] = useState(false);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const vlrTotalPlanoContas = async (datasource) => {
    let vlrSomadoPlanoConta = 0;

    if (datasource !== null && datasource !== undefined) {
      datasource.forEach((item) => {
        if (item.vlrPlanoConta > 0) {
          vlrSomadoPlanoConta += item.vlrPlanoConta;
        } else {
          item.vlrPlanoConta = 0;
        }
      });
    }

    setPlanoConta({ vlrSomadoPlanoDeContas: vlrSomadoPlanoConta });
  };

  const savePrice = async (keyValue, value) => {
    const datasource = gridView2.current
      ? gridView2.current.getDataSource()
      : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqPlanoDeContaCred === keyValue
    );
    itemParcela.vlrPlanoConta = value;

    for (let i = 0; i < datasource.length; ) {
      if (
        datasource[i].nrSeqPlanoDeContaCred ===
        itemParcela.nrSeqPlanoDeContaCred
      ) {
        datasource[i].vlrPlanoConta = itemParcela.vlrPlanoConta;
      }
      i += 1;
    }

    vlrTotalPlanoContas(datasource);

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(datasource);
  };

  const fPrice = function (keyValue, value) {
    savePrice(keyValue, value);
  };

  const onClickEditParcela = async (selectedValue, datasource) => {
    if (selectedValue.nrSeqTituloPagar > 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel editar a parcela após a criação da mesma'
      );
      return;
    }
    const parcelaedicao = datasource.filter(
      (Pagamento) => Pagamento === selectedValue
    );

    parcelaedicao[0].status = GridView.EnumStatus.Alterar;

    setEdicaoParcela(parcelaedicao[0]);
    setEdicao(true);
  };
  const onClickRemoveParcela = async (selectedValue, datasource) => {
    if (selectedValue.nrSeqTituloPagar > 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel excluir a parcela, para a exclusão da mesma, exclua pela pagina seleção'
      );
      return;
    }
    let exclusaoParcela = [];
    if (selectedValue.nrSeqTituloPagar === 0) {
      exclusaoParcela = datasource.filter(
        (Pagamento) => Pagamento !== selectedValue
      );
    } else {
      exclusaoParcela = datasource.filter(
        (Pagamento) => Pagamento !== selectedValue
      );
      const parcelaexclusao = datasource.filter(
        (Pagamento) => Pagamento === selectedValue
      );
      parcelaexclusao[0].status = GridView.EnumStatus.Remover;
      exclusaoParcela.push(parcelaexclusao[0]);
    }
    if (gridView && gridView.current)
      gridView.current.setDataSource(exclusaoParcela);
  };
  const onClickEditPlaca = async (selectedValue, datasource) => {
    const dataFaturas = datasource.filter((fatura) => fatura !== selectedValue);
    const dataFatura = datasource.filter((fatura) => fatura === selectedValue);

    dataFatura[0].status = GridView.EnumStatus.Alterar;
    setPlaca(dataFatura[0]);
    if (gridView3 && gridView3.current)
      gridView3.current.setDataSource(dataFaturas);
  };

  const onClickRemovePlaca = async (selectedValue, datasource) => {
    let placas = [];
    if (selectedValue.nrSeqTituloPagar === undefined) {
      placas = datasource.filter((Placa) => Placa !== selectedValue);
    } else {
      placas = datasource.filter((Placa) => Placa !== selectedValue);
      const dataPlaca = datasource.filter((Placa) => Placa === selectedValue);
      dataPlaca[0].status = GridView.EnumStatus.Remover;
      placas.push(dataPlaca[0]);
    }
    if (gridView3 && gridView3.current) gridView3.current.setDataSource(placas);
  };

  const onClickDownload = async (e) => {
    const linkSource = `data:image/png;base64, ${
      e.noImagemBase64 ?? e.noImagem
    }`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = e.noArquivo;
    downloadLink.click();
  };

  const onNew = async () => {
    const usuario = await getUsuario(1);
    setData({
      empresa: usuario.empresa,
      nrSeqEmpresa: usuario.empresa.nrSeqEmpresa,
      tituloPagarTipoDocumento: usuario.tituloPagarTipoDocumento,
      nrSeqTituloPagarTipoDocumento:
        usuario.tituloPagarTipoDocumento.nrSeqTituloPagarTipoDocumento,
      geraSequencia: false,
      vlrSaldoTit: 0,
      vlrMultaPaga: 0,
      vlrJuroPago: 0,
      vlrPago: 0,
      inclusao: null,
      alteracao: null,
      vlrSomadoPlanoDeContas: 0,
      nrOrdem: undefined,
    });
    setParcela({
      tipoParcela: 1,
      vlrTitulo: 0,
      vlrDesconto: 0,
    });

    if (gridView && gridView.current) gridView.current.setDataSource(null);

    if (gridView2 && gridView2.current) gridView2.current.setDataSource(null);
    if (gridView3 && gridView3.current) gridView3.current.setDataSource(null);
    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource(null);
    setMessage(null);
    document.getElementById('txtCodigoBarra').focus();
  };

  const onRemoveDocumento = async (selectedValue, datasource) => {
    let documentos = [];
    if (selectedValue.nrSeqTituloPagarDocumento === undefined) {
      documentos = datasource.filter(
        (Documento) => Documento !== selectedValue
      );
    } else {
      documentos = datasource.filter(
        (Documento) => Documento !== selectedValue
      );
      const dataDocumento = datasource.filter(
        (Documento) => Documento === selectedValue
      );
      dataDocumento[0].status = GridView.EnumStatus.Remover;
      documentos.push(dataDocumento[0]);
    }
    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource(documentos);
  };

  const tipoCodigoDeBarras = [
    {
      text: 'Linha Digitavel',
      value: 1,
    },
    {
      text: 'Código de Barras',
      value: 2,
    },
  ];
  const tipoParcelas = [
    {
      text: 'Registro por mês:',
      value: 1,
    },
    {
      text: 'Registro por intervalo de dias:',
      value: 2,
    },
  ];

  const columns = [
    {
      key: 'nrSeqTituloPagar',
      visible: false,
    },
    {
      key: 'cdTituloPagar',
      title: 'Compromisso',
      format: GridView.DataTypes.Integer,
    },
    {
      key: 'nrTit',
      title: 'Nr. Tit',
      format: GridView.DataTypes.Integer,
    },
    {
      key: 'nrOrdem',
      title: 'Ordem',
      format: GridView.DataTypes.Integer,
    },
    {
      key: 'vlrTitulo',
      title: 'Valor',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrPago',
      title: 'Pago',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrSaldoTit',
      title: 'Saldo',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrSeqTituloPagar',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditParcela(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqTituloPagar',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveParcela(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columns2 = [
    {
      key: 'nrSeqPlanoDeContaCred',
      visible: false,
    },
    {
      key: 'planoDeContaCre.cdCaminhoCompleto',
      title: 'Plano De Contas Credito',
    },
    {
      key: 'vlrPlanoConta',
      title: 'Valor',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      readOnlyDynamic: 'flgPercentual',
      onBlur: fPrice,
      maxLength: 10,
    },
    { key: 'flgPercentual', visible: false },
  ];
  const columns3 = [
    {
      key: 'veiculo.noVeiculo',
      title: 'Placa',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'nrSeqTituloPagar',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditPlaca(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqTituloPagar',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemovePlaca(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsAnexos = [
    {
      key: 'nrSeqTituloPagarDocumento',
      visible: false,
    },
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'noObs',
      title: 'Observações',
    },
    {
      key: 'nrSeqTituloPagarDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },

    {
      key: 'nrSeqTituloPagarDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const obj = await getTituloPagar(registryKey);

      let vlrSomadoPlanoConta = 0;

      if (
        obj.tituloPagarPlanoDeConta !== null &&
        obj.tituloPagarPlanoDeConta !== undefined
      ) {
        obj.tituloPagarPlanoDeConta.forEach((item) => {
          if (item.vlrPlanoConta > 0) {
            vlrSomadoPlanoConta += item.vlrPlanoConta;
          } else {
            item.vlrPlanoConta = 0;
          }
        });
      }

      if (obj.noCodigoBarras !== null) {
        obj.tipoCdBarra = 2;
      } else {
        obj.tipoCdBarra = null;
      }
      if (obj.nrSeqUsuarioAlt > 0) {
        setData({
          tipoCdBarra: obj.tipoCdBarra,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloPagarFatura: obj.nrSeqTituloPagarFatura,
          nrSeqEmpresa: obj.nrSeqEmpresa,
          nrSeqTituloPagarTipoDocumento: obj.nrSeqTituloPagarTipoDocumento,
          cdTituloPagar: obj.cdTituloPagar,
          codigoBarras: obj.codigoBarras,
          noCodigoBarras: obj.noCodigoBarras,
          dtContaB: obj.dtContaB,
          empresa: obj.empresa,
          flgBoletoEntregue: obj.flgBoletoEntregue,
          flgPendente: obj.flgPendente,
          formaPagamento: obj.formaPagamento,
          fornecedor: obj.fornecedor,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaFor: obj.nrSeqPessoaFor,
          vlrPago: obj.vlrPago,
          noObs: obj.noObs,
          tituloPagarTipoDocumento: obj.tituloPagarTipoDocumento,
          nrSeqTipoDespesa: obj.nrSeqTipoDespesa,
          tipoDespesa: obj.tipoDespesa,
          ocorrencias: obj.ocorrencias,
          vlrSaldoTit: obj.vlrSaldoTit,
          vlrMultaPaga: obj.vlrMultaPaga,
          vlrJuroPago: obj.vlrJuroPago,
          tipoServico: obj.tipoServico,
          formaLancamentoEletronico: obj.formaLancamentoEletronico,
          tipoMovimento: obj.tipoMovimento,
          movimentoRemessa: obj.movimentoRemessa,
          pessoaSacador: obj.pessoaSacador,
          tituloPagarFatura: obj.tituloPagarFatura,
          instrucaoMovimento: obj.instrucaoMovimento,
          nrSeqUsuarioAlt: obj.nrSeqUsuarioAlt,
          nrSeqUsuarioCad: obj.nrSeqUsuarioCad,
          inclusao: `Inclusão: ${obj.usuario.noPessoa} - ${new Date(
            obj.dtCad
          ).toLocaleDateString()} `,
          alteracao: `Alteração: ${obj.usuarioAlteracao.noPessoa} - ${new Date(
            obj.dtCad
          ).toLocaleDateString()} `,

          nrSeqTipoServicoBancario: obj.nrSeqTipoServicoBancario,
          nrSeqFormaLancamentoEletronico: obj.nrSeqFormaLancamentoEletronico,
          nrSeqTipoMovimento: obj.nrSeqTipoMovimento,
          nrSeqInstrucaoMovimento: obj.nrSeqInstrucaoMovimento,
          nrSeqTipoMovimentoRemessa: obj.nrSeqTipoMovimentoRemessa,
          nrSeqPessoaSacador: obj.nrSeqPessoaSacador,
        });
      } else {
        setData({
          tipoCdBarra: obj.tipoCdBarra,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloPagarFatura: obj.nrSeqTituloPagarFatura,
          nrSeqEmpresa: obj.nrSeqEmpresa,
          nrSeqTituloPagarTipoDocumento: obj.nrSeqTituloPagarTipoDocumento,
          cdTituloPagar: obj.cdTituloPagar,
          codigoBarras: obj.codigoBarras,
          noCodigoBarras: obj.noCodigoBarras,
          dtContaB: obj.dtContaB,
          empresa: obj.empresa,
          flgBoletoEntregue: obj.flgBoletoEntregue,
          flgPendente: obj.flgPendente,
          formaPagamento: obj.formaPagamento,
          fornecedor: obj.fornecedor,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaFor: obj.nrSeqPessoaFor,
          vlrPago: obj.vlrPago,
          noObs: obj.noObs,
          tituloPagarTipoDocumento: obj.tituloPagarTipoDocumento,
          nrSeqTipoDespesa: obj.nrSeqTipoDespesa,
          tipoDespesa: obj.tipoDespesa,
          ocorrencias: obj.ocorrencias,
          vlrSaldoTit: obj.vlrSaldoTit,
          vlrMultaPaga: obj.vlrMultaPaga,
          vlrJuroPago: obj.vlrJuroPago,
          tipoServico: obj.tipoServico,
          formaLancamentoEletronico: obj.formaLancamentoEletronico,
          tipoMovimento: obj.tipoMovimento,
          movimentoRemessa: obj.movimentoRemessa,
          pessoaSacador: obj.pessoaSacador,
          tituloPagarFatura: obj.tituloPagarFatura,
          instrucaoMovimento: obj.instrucaoMovimento,
          nrSeqUsuarioAlt: obj.nrSeqUsuarioAlt,
          nrSeqUsuarioCad: obj.nrSeqUsuarioCad,
          inclusao: `Inclusão: ${obj.usuario.noPessoa} - ${new Date(
            obj.dtCad
          ).toLocaleDateString()} `,
          alteracao: null,
          nrSeqTipoServicoBancario: obj.nrSeqTipoServicoBancario,
          nrSeqFormaLancamentoEletronico: obj.nrSeqFormaLancamentoEletronico,
          nrSeqTipoMovimento: obj.nrSeqTipoMovimento,
          nrSeqInstrucaoMovimento: obj.nrSeqInstrucaoMovimento,
          nrSeqTipoMovimentoRemessa: obj.nrSeqTipoMovimentoRemessa,
          nrSeqPessoaSacador: obj.nrSeqPessoaSacador,
        });
      }
      setParcela({
        nrTit: obj.nrTit,
        nrOrdem: obj.nrOrdem,
        dtVencimento: obj.dtVencimento,
        vlrTitulo: obj.vlrTitulo,
        vlrDesconto: obj.vlrDesconto,
        flgGeraSequencia: obj.flgGeraSequencia,
      });
      setImportedFile({ documentos: obj.documentos });
      if (obj.parcelas !== undefined) {
        if (obj.parcelas.length > 1) {
          if (gridView && gridView.current)
            gridView.current.setDataSource(obj.parcelas);
        }
      }

      setPlanoConta({ vlrSomadoPlanoDeContas: vlrSomadoPlanoConta });

      if (
        obj.tituloPagarPlanoDeConta !== null ||
        obj.tituloPagarPlanoDeConta !== undefined
      ) {
        if (gridView2 && gridView2.current)
          gridView2.current.setDataSource(obj.tituloPagarPlanoDeConta);
      } else if (gridView2 && gridView2.current) {
        gridView2.current.setDataSource({});
      }

      if (gridView3 && gridView3.current)
        gridView3.current.setDataSource(obj.veiculos);
      if (gridViewDocumentos && gridViewDocumentos.current)
        gridViewDocumentos.current.setDataSource(obj.documentos);

      if (obj.nrSeqTituloPagarFatura > 0) {
        onSetMessage(
          ResponseStatus.Error,
          'Título com faturas, será permitida a alteração apenas dos vencimentos e dos valores de desconto, juros e multa.'
        );
      }
      if (obj.vlrPago > 0) {
        onSetMessage(
          ResponseStatus.Error,
          'Título com pagamentos, será permitido somente a adição de documentos'
        );
      }

      setLoading(false);
    } else {
      const usuario = await getUsuario(1);
      setData({
        empresa: usuario.empresa,
        nrSeqEmpresa: usuario.empresa.nrSeqEmpresa,
        tituloPagarTipoDocumento: usuario.tituloPagarTipoDocumento,
        nrSeqTituloPagarTipoDocumento:
          usuario.tituloPagarTipoDocumento.nrSeqTituloPagarTipoDocumento,
        geraSequencia: false,
        vlrSaldoTit: 0,
        vlrMultaPaga: 0,
        vlrJuroPago: 0,
        vlrPago: 0,
        inclusao: null,
        alteracao: null,
      });
      setParcela({
        tipoParcela: 1,
        vlrTitulo: 0,
        vlrDesconto: 0,
      });

      setPlanoConta({ vlrSomadoPlanoDeContas: 0 });

      if (gridView && gridView.current) gridView.current.setDataSource(null);

      if (gridView2 && gridView2.current) gridView2.current.setDataSource(null);
      if (gridView3 && gridView3.current) gridView3.current.setDataSource(null);
      if (gridViewDocumentos && gridViewDocumentos.current)
        gridViewDocumentos.current.setDataSource(null);
      setMessage(null);
      document.getElementById('txtCodigoBarra').focus();
    }
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  useEffect(async () => {
    if (importedFile.length > 0) {
      let noImagemBase64 = await toBase64(importedFile[0]);
      noImagemBase64 = String(noImagemBase64).substring(
        String(noImagemBase64).indexOf(',') + 1
      );

      const documento = {
        noArquivo: importedFile[0].name,
        noTipoArquivo: importedFile[0].type,
        noTamanho: String(importedFile[0].size),
        flgExibeDownload: false,
        nrSeqTituloPagar: data.nrSeqTituloPagar,
        tipoDocumento: data.tipoDocumento,
        noObs: obs.noObs,
        noImagemBase64,
        status: GridView.EnumStatus.Inserir,
      };
      let documentos = [];
      if (gridViewDocumentos && gridViewDocumentos.current)
        documentos = gridViewDocumentos.current.getDataSource() ?? [];

      documentos.push(documento);

      if (gridViewDocumentos && gridViewDocumentos.current)
        gridViewDocumentos.current.setDataSource(documentos);

      setImportedFile({});
      SetObs({});
    }
  }, [importedFile]);

  const save = async () => {
    setLoading(true);
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);

    const lista = gridView.current ? gridView.current.getDataSource() : [];
    const despesa = gridView2.current ? gridView2.current.getDataSource() : [];
    const placas = gridView3.current ? gridView3.current.getDataSource() : [];
    const documentos = gridViewDocumentos.current
      ? gridViewDocumentos.current.getDataSource()
      : [];

    const obj = {
      ...parcela,
      ...data,
      nrSeqTransacao: transaction.nrSeqTransacao,
      parcelas: lista,
      tituloPagarPlanoDeConta: despesa,
      veiculos: placas,
      documentos,
    };
    if (obj.tituloPagarPlanoDeConta !== null) {
      for (let i = 0; i < obj.tituloPagarPlanoDeConta.length; ) {
        obj.tituloPagarPlanoDeConta[i].vlrTitulo =
          obj.tituloPagarPlanoDeConta[i].vlrPlanoConta;
        i += 1;
      }
    }

    const { status, message: msg, value } = await saveTituloPagar(obj);

    let usuario = new Usuario();

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      usuario = await getUsuario(1);
    }

    setData(
      status === ResponseStatus.Success
        ? {
            empresa: obj.empresa,
            nrSeqEmpresa: obj.nrSeqEmpresa,
            tituloPagarTipoDocumento: usuario.tituloPagarTipoDocumento,
            nrSeqTituloPagarTipoDocumento:
              usuario.tituloPagarTipoDocumento.nrSeqTituloPagarTipoDocumento,
            geraSequencia: false,
            vlrSaldoTit: 0,
            vlrMultaPaga: 0,
            vlrJuroPago: 0,
            vlrPago: 0,
            inclusao: null,
            alteracao: null,
            dtContaB: obj.dtContaB,
            nrOrdem: undefined,
          }
        : data
    );
    setParcela(
      status === ResponseStatus.Success
        ? { tipoParcela: 1, vlrTitulo: 0, vlrDesconto: 0 }
        : parcela
    );
    setEdicaoParcela(status === ResponseStatus.Success ? {} : edicaoParcela);
    if (status === ResponseStatus.Success) {
      if (gridView && gridView.current) gridView.current.setDataSource(null);

      if (gridView2 && gridView2.current) gridView2.current.setDataSource(null);
      if (gridView3 && gridView3.current) gridView3.current.setDataSource(null);
      if (gridViewDocumentos && gridViewDocumentos.current)
        gridViewDocumentos.current.setDataSource(null);
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    if (value !== undefined) {
      onOpenReport(transaction.noTransacao, value);
    }
    document.getElementById('txtCodigoBarra').focus();
    setLoading(false);
  };

  const onExcluir = async (flgConfirmaExclusao = false) => {
    let nrSeqTituloPagar = 0;
    if (registryKey > 0) {
      nrSeqTituloPagar = registryKey;
    }

    if (flgConfirmaExclusao === true) {
      nrSeqTituloPagar = data.nrSeqTituloPagar;
    }

    if (nrSeqTituloPagar > 0) {
      setLoading(true);
      const { status, message: msg } = await deleteTituloPagar(
        nrSeqTituloPagar,
        flgConfirmaExclusao
      );

      if (msg === 'ConfirmaExclusaoCustoFixo') {
        setShowConfirmaDeleteCustoFixo(true);
      } else {
        setShowConfirmaDeleteCustoFixo(false);
        setData(status === ResponseStatus.Success ? {} : data);
        setParcela(status === ResponseStatus.Success ? {} : parcela);
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
      }

      setLoading(false);
    }
  };

  const onSearchTipoDespesa = async (e) => {
    const {
      status,
      message: msg,
      tipoDespesas,
    } = await getTipoDespesaAutoComplete({
      noTipoDespesa: e,
    });
    if (msg) onSetMessage(status, msg);
    return tipoDespesas;
  };

  const onSearchVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const OnSearchFornecedor = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaFors,
    } = await getFornecedorAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return pessoaFors;
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitPag: true,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const onSearchTituloPagarTipoDocumento = async (e) => {
    const {
      status,
      message: msg,
      data: tituloPagarTipoDocumentos,
    } = await getTituloPagarTipoDocumentoAutoComplete({
      noTituloPagarTipoDocumento: e,
    });
    if (msg) onSetMessage(status, msg);
    return tituloPagarTipoDocumentos;
  };

  const onAdicionarParcelas = async () => {
    const lista = [];

    if (parcela.status !== GridView.EnumStatus.Alterar) {
      parcela.status = GridView.EnumStatus.Inserir;
    }
    const obj = await getTituloPagarPlanoDeContaPagined(parcela);

    if (obj.message) {
      setMessage({
        message: obj.message,
        theme:
          obj.status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      return;
    }
    lista.push(...obj.data);
    if (gridView && gridView.current) gridView.current.setDataSource(lista);
  };
  const onAdicionarPlaca = async () => {
    const lista = gridView3.current.getDataSource();
    const listaValidacao = [];

    if (placa.nrSeqVeiculo === null || placa.nrSeqVeiculo === undefined) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar um veiculo, sem selecionar o veiculo'
      );
      return;
    }

    if (
      placa.vlrPlaca === 0 ||
      placa.vlrPlaca === null ||
      placa.vlrPlaca === undefined
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar um veiculo, sem colocar o valor'
      );
      return;
    }

    lista.forEach((elem) => listaValidacao.push(elem));
    listaValidacao.push(placa);
    if (placa.status !== GridView.EnumStatus.Alterar) {
      placa.status = GridView.EnumStatus.Inserir;
    }
    placa.vlrTitulo = placa.vlrPlaca;
    lista.push(placa);
    if (gridView3 && gridView3.current) gridView3.current.setDataSource(lista);
    setPlaca({ vlrPlaca: 0 });
  };

  const onAdicionarParcelasEditadas = async () => {
    if (edicaoParcela.status !== GridView.EnumStatus.Alterar) {
      edicaoParcela.status = GridView.EnumStatus.Inserir;
    }

    const gridcompleta = gridView.current
      ? gridView.current.getDataSource()
      : [];

    const itemParcela = gridcompleta.find(
      (item) => item.nrOrdem === edicaoParcela.nrOrdem
    );
    itemParcela.dtVencimento = edicaoParcela.dtVencimento;
    itemParcela.vlrTitulo = edicaoParcela.vlrTitulo;
    itemParcela.vlrSaldoTit =
      edicaoParcela.vlrTitulo -
      edicaoParcela.vlrDesconto -
      edicaoParcela.vlrPago;
    itemParcela.noCodigoBarras = edicaoParcela.noCodigoBarras;

    if (gridView && gridView.current)
      gridView.current.setDataSource(gridcompleta);
    setEdicao(false);
  };
  const onAdicionarTipoDespesa = async () => {
    if (edicaoDespesa.status !== GridView.EnumStatus.Alterar) {
      edicaoDespesa.status = GridView.EnumStatus.Inserir;
    }

    const gridcompleta = gridView2.current
      ? gridView2.current.getDataSource()
      : [];

    const itemParcela = gridcompleta.find(
      (item) =>
        item.nrSeqTipoDespesa_PlanoDeConta ===
        edicaoDespesa.nrSeqTipoDespesa_PlanoDeConta
    );
    itemParcela.vlrPlanoConta = edicaoDespesa.vlrPlanoConta;

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(gridcompleta);
    setEdicaoTipoDespesa(false);
  };

  const Recalcula = async (tipoDespesa) => {
    if (tipoDespesa !== undefined) {
      data.tipoDespesa = tipoDespesa;
    }
    if (data.tipoDespesa === undefined) {
      data.tipoDespesa = new TipoDespesa();
    }
    if (data.tipoDespesa.nrSeqTipoDespesa > 0) {
      const { data: planoconta } = await getTipoDespesaPlanoDeContaTituloPagar({
        nrSeqTipoDespesa: data.tipoDespesa.nrSeqTipoDespesa,
      });
      for (let i = 0; i < planoconta.length; ) {
        planoconta[i].vlrTitulo = parcela.vlrTitulo;
        planoconta[i].vlrDesconto = parcela.vlrDesconto;
        i += 1;
      }
      if (planoconta.length > 0) {
        if (data.tipoDespesa.planoDeContas.length === 0) {
          data.tipoDespesa.planoDeContas.push(...planoconta);
        } else {
          for (let i = 0; i < data.tipoDespesa.planoDeContas.length; ) {
            data.tipoDespesa.planoDeContas[i] = planoconta.vlrPlanoConta;
            i += 1;
          }
        }
        const obj = await getRetornoGridPlanoConta(planoconta);
        if (gridView2 && gridView2.current)
          gridView2.current.setDataSource(obj);
      } else if (gridView2 && gridView2.current)
        gridView2.current.setDataSource(null);
    } else if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(null);

    if (data.tipoDespesa?.flgVinculaVeiculo === true) {
      setPlaca({
        vlrPlaca: parcela.vlrTitulo - parcela.vlrDesconto,
      });
    }
  };

  const CodigoBarra = async (e) => {
    if (data.noCodigoBarras !== undefined) {
      if (data.noCodigoBarras !== null) {
        e = data;
        const obj = await getRetornoCodigoBarras(e);

        if (obj.nrSeqTituloPagarFatura > 0) {
          if (parcela.vlrTitulo === obj.vlrTitulo) {
            if (obj.nrSeqFormaLancamentoEletronico > 0) {
              setData({
                ...data,
                noCodigoBarras: obj.noCodigoBarras,
                tipoCdBarra: obj.tipoCdBarra,
                nrSeqFormaLancamentoEletronico:
                  obj.nrSeqFormaLancamentoEletronico,
                formaLancamentoEletronico: obj.formaLancamentoEletronico,
              });
            } else {
              setData({
                ...data,
                noCodigoBarras: obj.noCodigoBarras,
                tipoCdBarra: obj.tipoCdBarra,
              });
            }
            if (obj.dtVencimento !== undefined) {
              if (obj.dtVencimento !== null) {
                setParcela({
                  ...parcela,
                  vlrTitulo: obj.vlrTitulo,
                  dtVencimento: obj.dtVencimento,
                  vlrDesconto: obj.vlrDesconto,
                });
              } else {
                setParcela({
                  ...parcela,
                });
              }
            } else {
              setParcela({
                ...parcela,
              });
            }
          } else {
            setData({ ...data, noCodigoBarras: null });
            onSetMessage(
              ResponseStatus.Error,
              'Você não pode inserir um codigo de barras onde o valor do boleto não é igual o do titulo cadastrado'
            );
          }
        } else {
          if (obj.nrSeqFormaLancamentoEletronico > 0) {
            setData({
              ...data,
              noCodigoBarras: obj.noCodigoBarras,
              tipoCdBarra: obj.tipoCdBarra,
              nrSeqFormaLancamentoEletronico:
                obj.nrSeqFormaLancamentoEletronico,
              formaLancamentoEletronico: obj.formaLancamentoEletronico,
            });
          } else {
            setData({
              ...data,
              noCodigoBarras: obj.noCodigoBarras,
              tipoCdBarra: obj.tipoCdBarra,
            });
          }
          if (obj.dtVencimento !== undefined) {
            if (obj.dtVencimento !== null) {
              setParcela({
                ...parcela,
                vlrTitulo: obj.vlrTitulo,
                dtVencimento: obj.dtVencimento,
                vlrDesconto: obj.vlrDesconto,
              });
            } else {
              setParcela({
                ...parcela,
              });
            }
          } else {
            setParcela({
              ...parcela,
            });
          }
        }
      }
    }
  };

  const CodigoBarraEdicaoParcela = async (e) => {
    if (edicaoParcela.noCodigoBarras !== undefined) {
      if (edicaoParcela.noCodigoBarras !== null) {
        e = edicaoParcela;
        const obj = await getRetornoCodigoBarras(e);

        if (obj.nrSeqFormaLancamentoEletronico > 0) {
          setEdicaoParcela({
            ...edicaoParcela,
            noCodigoBarras: obj.noCodigoBarras,
            tipoCdBarra: obj.tipoCdBarra,
            nrSeqFormaLancamentoEletronico: obj.nrSeqFormaLancamentoEletronico,
            formaLancamentoEletronico: obj.formaLancamentoEletronico,
          });
        } else {
          setEdicaoParcela({
            ...edicaoParcela,
            noCodigoBarras: obj.noCodigoBarras,
            tipoCdBarra: obj.tipoCdBarra,
          });
        }
        if (obj.dtVencimento !== undefined) {
          if (obj.dtVencimento !== null) {
            setEdicaoParcela({
              ...edicaoParcela,
              vlrTitulo: obj.vlrTitulo,
              dtVencimento: obj.dtVencimento,
              vlrDesconto: obj.vlrDesconto,
            });
          } else {
            setEdicaoParcela({
              ...edicaoParcela,
            });
          }
        } else {
          setEdicaoParcela({
            ...edicaoParcela,
          });
        }
      }
    }
  };

  const onOpenOcorrecias = async () => {
    setModalShow({
      ...modalShow,
      enviarFaturasPorEmail: true,
      ocorrencias: data.ocorrencias,
    });
  };
  const onOpenNovoTitulo = async () => {
    setModalShow({
      ...modalShow,
      novoTitulo: true,
    });
  };
  const onOpenTituloPagarAgrupado = async () => {
    setModalShow({
      ...modalShow,
      TituloPagarAgrupado: true,
    });
  };

  const onOpenPagamento = (selectedValue) => {
    const transactionContaChamada = findTransaction(
      TransacationCodes.Pagamento
    );

    if (transactionContaChamada) {
      transactionContaChamada.registryKey = selectedValue;

      onOpenTransaction(transactionContaChamada, true);
    }
  };

  const onSearchTipoDeServico = async (e) => {
    const {
      status,
      message: msg,
      data: tipodeservico,
    } = await getTipoServicoBancarioAutoComplete({
      noTipoServicoBancario: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return tipodeservico;
  };
  const onSearchFormaDeLancamento = async (e) => {
    const {
      status,
      message: msg,
      data: formaDeLancamento,
    } = await getFormaLancamentoEletronicoAutoComplete({
      noFormaLancamentoEletronico: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return formaDeLancamento;
  };
  const onSearchTipoDeMovimento = async (e) => {
    const {
      status,
      message: msg,
      data: tipoDeMovimento,
    } = await getTipoMovimentoAutoComplete({
      noTipoMovimento: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return tipoDeMovimento;
  };

  const multipag = async (e) => {
    if (data.formaPagamento?.flgGrupo === 'E') {
      e = data;
      const obj = await getMultipag({
        ...data,
      });
      setData({
        ...data,
        tipoServico: obj.tipoServico,
        nrSeqTipoServicoBancario: obj.nrSeqTipoServicoBancario,
        tipoMovimento: obj.tipoMovimento,
        nrSeqTipoMovimento: obj.nrSeqTipoMovimento,
        formaLancamentoEletronico: obj.formaLancamentoEletronico,
        nrSeqFormaLancamentoEletronico: obj.nrSeqFormaLancamentoEletronico,
        instrucaoMovimento: obj.instrucaoMovimento,
        nrSeqInstrucaoMovimento: obj.nrSeqInstrucaoMovimento,
        formaPagamento: data.formaPagamento,
        nrSeqFormaPagamento: data.formaPagamento.nrSeqFormaPagamento,
        movimentoRemessa: obj.movimentoRemessa,
        nrSeqTipoMovimentoRemessa: obj.nrSeqTipoMovimentoRemessa,
      });

      return obj;
    }
    setData({
      ...e,
      formaPagamento: data.formaPagamento,
      nrSeqFormaPagamento: data.formaPagamento.nrSeqFormaPagamento,
    });
    return true;
  };

  const onSearchInstrucaoDeMovimento = async (e) => {
    const {
      status,
      message: msg,
      data: instrucaoDeMovimento,
    } = await getInstrucaoMovimentoAutoComplete({
      noInstrucaoMovimento: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return instrucaoDeMovimento;
  };
  const onSearchMovimentoRemessa = async (e) => {
    const {
      status,
      message: msg,
      data: TipoMovimentoRemessa,
    } = await getTipoMovimentoRemessaAutoComplete({
      noTipoMovimentoRemessa: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return TipoMovimentoRemessa;
  };
  const onSearchSacadorAvalista = async (e) => {
    const { status, message: msg, data: Pessoa } = await getPessoaAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return Pessoa;
  };

  const RecalcularValorPlacas = async () => {
    setLoading(true);
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);

    const placas = gridView3.current ? gridView3.current.getDataSource() : [];

    const obj = {
      ...parcela,
      ...data,
      nrSeqTransacao: transaction.nrSeqTransacao,
      veiculos: placas,
    };

    const value = await RecalculaValorVeiculos(obj);

    if (gridView3 && gridView3.current)
      gridView3.current.setDataSource(value.value.veiculos);

    setLoading(false);
  };
  const RatearPlanoDeContas = async () => {
    setLoading(true);
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);

    const despesa = gridView2.current ? gridView2.current.getDataSource() : [];

    const placas = gridView3.current ? gridView3.current.getDataSource() : [];

    const obj = {
      ...parcela,
      ...data,
      nrSeqTransacao: transaction.nrSeqTransacao,
      tituloPagarPlanoDeConta: despesa,
      veiculos: placas,
    };

    const value = await RatearPlanoDeContaAutomatico(obj);

    let vlrSomadoPlanoConta = 0;

    if (
      value.value.tituloPagarPlanoDeConta !== null &&
      value.value.tituloPagarPlanoDeConta !== undefined
    ) {
      value.value.tituloPagarPlanoDeConta.forEach((item) => {
        if (item.vlrPlanoConta > 0) {
          vlrSomadoPlanoConta += item.vlrPlanoConta;
        } else {
          item.vlrPlanoConta = 0;
        }
      });
    }

    setPlanoConta({ vlrSomadoPlanoDeContas: vlrSomadoPlanoConta });

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(value.value.tituloPagarPlanoDeConta);

    setLoading(false);
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Titulo Pagar'
      loading={loading}
      transaction={transaction}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      onDelete={data?.nrSeqTituloPagar > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <ToolbarButtons>
        {data.nrSeqTituloPagar > 0 && (
          <ToolbarButtons.Button
            text='Ocorrências'
            icon={['far', 'comment-alt']}
            onClick={onOpenOcorrecias}
          />
        )}
        {data.tipoCdBarra === 3 && (
          <ToolbarButtons.Button
            text='Novo Titulo c/ Saldo'
            icon='layer-group'
            onClick={onOpenNovoTitulo}
          />
        )}
        {data.nrSeqTituloPagar > 0 && (
          <ToolbarButtons.Button
            text='Visualizar/Efetuar Pagamento'
            icon='layer-group'
            onClick={() => onOpenPagamento(data.nrSeqTituloPagar)}
          />
        )}
        {data.nrSeqTituloPagar > 0 && (
          <ToolbarButtons.Button
            text='Titulos Agrupados'
            icon='layer-group'
            onClick={onOpenTituloPagarAgrupado}
          />
        )}
      </ToolbarButtons>
      <Confirm
        show={showConfirmaDeleteCustoFixo}
        title='Confirmar Delete Custo Fixo'
        titleIcon='exclamation-triangle'
        text='O titulo a pagar esta vinculado a um custo fixo, deseja exclui-lo mesmo assim?'
        handleConfirm={() => {
          onExcluir(true);
        }}
        handleCancel={() => setShowConfirmaDeleteCustoFixo(false)}
      />
      {data.nrSeqTituloPagarFatura == null && (
        <div className='row'>
          <div className='col-6 mb-3'>
            <Autocomplete
              label='Empresa'
              required
              searchDataSource={onSearchEmpresa}
              selectedItem={data.empresa}
              onSelectItem={(empresa) => {
                setData({
                  ...data,
                  empresa,
                  nrSeqEmpresa: empresa.nrSeqEmpresa,
                });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
          <div className='col-3 mb-3'>
            <Autocomplete
              required
              label='Tipo'
              searchDataSource={onSearchTituloPagarTipoDocumento}
              selectedItem={data.tituloPagarTipoDocumento}
              onSelectItem={(tituloPagarTipoDocumento) => {
                setData({
                  ...data,
                  tituloPagarTipoDocumento,
                  nrSeqTituloPagarTipoDocumento:
                    tituloPagarTipoDocumento.nrSeqTituloPagarTipoDocumento,
                });
              }}
              dataSourceTextProperty='noTituloPagarTipoDocumento'
            />
          </div>
          <div className='col-3 mb-3'>
            <Textbox
              maxLength={20}
              required
              readOnly
              label='Nr. Compromisso'
              text={data.cdTituloPagar}
            />
          </div>
        </div>
      )}
      {data.nrSeqTituloPagarFatura > 0 && (
        <div className='row'>
          <div className='col-6 mb-3'>
            <Autocomplete
              label='Empresa'
              readOnly
              searchDataSource={onSearchEmpresa}
              selectedItem={data.empresa}
              onSelectItem={(empresa) => {
                setData({
                  ...data,
                  empresa,
                  nrSeqEmpresa: empresa.nrSeqEmpresa,
                });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
          <div className='col-3 mb-3'>
            <Autocomplete
              readOnly
              label='Tipo'
              searchDataSource={onSearchTituloPagarTipoDocumento}
              selectedItem={data.tituloPagarTipoDocumento}
              onSelectItem={(tituloPagarTipoDocumento) => {
                setData({
                  ...data,
                  tituloPagarTipoDocumento,
                  nrSeqTituloPagarTipoDocumento:
                    tituloPagarTipoDocumento.nrSeqTituloPagarTipoDocumento,
                });
              }}
              dataSourceTextProperty='noTituloPagarTipoDocumento'
            />
          </div>
          <div className='col-3 mb-3'>
            <Textbox
              maxLength={20}
              readOnly
              label='Nr. Compromisso'
              text={data.cdTituloPagar}
            />
          </div>
        </div>
      )}
      <div className='row'>
        <div className='col-8 mb-3'>
          <Textbox
            maxLength={56}
            label='Codigo de Barras'
            id='txtCodigoBarra'
            text={data.noCodigoBarras}
            onChangedValue={(noCodigoBarras) =>
              setData({ ...data, noCodigoBarras })
            }
            onBlur={CodigoBarra}
          />
        </div>
        <div className='col-4'>
          <RadioButton
            label='Tipo Códido de Barras'
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={data.tipoCdBarra}
            onChange={(tipoCdBarra) => {
              setData({
                ...data,
                tipoCdBarra,
              });
            }}
            buttons={tipoCodigoDeBarras}
          />
        </div>
      </div>
      {data.nrSeqTituloPagarFatura > 0 && (
        <div className='row'>
          <div className='col-8 mb-3'>
            <Autocomplete
              label='Fornecedor'
              required
              readOnly
              searchDataSource={OnSearchFornecedor}
              selectedItem={data.fornecedor}
              onSelectItem={(fornecedor) => {
                setData({
                  ...data,
                  fornecedor,
                  nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                });
              }}
              dataSourceTextProperty='nomeDocumentoCidadeEstado'
              findTransaction={findTransaction}
              onOpenTransaction={onOpenTransaction}
              transactionToOpen={TransacationCodes.Fornecedor}
              nrseqTransactionToOpen='nrSeqPessoaFor'
            />
          </div>
        </div>
      )}
      {data.nrSeqTituloPagarFatura == null && (
        <div className='row'>
          <div className='col-8 mb-3'>
            <Autocomplete
              label='Fornecedor'
              required
              searchDataSource={OnSearchFornecedor}
              selectedItem={data.fornecedor}
              onSelectItem={(fornecedor) => {
                setData({
                  ...data,
                  fornecedor,
                  nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                });
              }}
              dataSourceTextProperty='nomeDocumentoCidadeEstado'
              findTransaction={findTransaction}
              onOpenTransaction={onOpenTransaction}
              transactionToOpen={TransacationCodes.Fornecedor}
              nrseqTransactionToOpen='nrSeqPessoaFor'
            />
          </div>
        </div>
      )}
      {data.nrSeqTituloPagarFatura > 0 && (
        <div className='row'>
          <div className='col-4 mb-3'>
            <Textbox
              maxLength={30}
              required
              readOnly
              label='Nr. Documento'
              text={parcela.nrTit}
              onChangedValue={(nrTit) => setParcela({ ...parcela, nrTit })}
            />
          </div>

          <div className='col mb-3'>
            <Textbox
              label='Ordem'
              readOnly
              maxLength={2}
              text={parcela.nrOrdem}
              mask={MaskTypes.Integer}
              onChangedValue={(nrOrdem) => setParcela({ ...parcela, nrOrdem })}
            />
          </div>
          <div className='col mb-3'>
            <DatePicker
              label='Vencimento'
              required
              text={parcela.dtVencimento}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtVencimento) =>
                setParcela({ ...parcela, dtVencimento })
              }
            />
          </div>
          <div className='col mb-3'>
            <DatePicker
              label='Contabil'
              readOnly
              required
              text={data.dtContaB}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtContaB) => setData({ ...data, dtContaB })}
            />
          </div>
        </div>
      )}
      {data.nrSeqTituloPagarFatura == null && (
        <div className='row'>
          <div className='col-4 mb-3'>
            <Textbox
              maxLength={30}
              required
              label='Nr. Documento'
              text={parcela.nrTit}
              onChangedValue={(nrTit) => setParcela({ ...parcela, nrTit })}
            />
          </div>

          <div className='col mb-3'>
            <Textbox
              label='Ordem'
              maxLength={2}
              text={parcela.nrOrdem}
              mask={MaskTypes.Integer}
              onChangedValue={(nrOrdem) => setParcela({ ...parcela, nrOrdem })}
            />
          </div>
          <div className='col mb-3'>
            <DatePicker
              label='Vencimento'
              required
              text={parcela.dtVencimento}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtVencimento) =>
                setParcela({ ...parcela, dtVencimento })
              }
            />
          </div>
          <div className='col mb-3'>
            <DatePicker
              label='Contabil'
              text={data.dtContaB}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtContaB) => setData({ ...data, dtContaB })}
            />
          </div>
        </div>
      )}

      <div className='row'>
        {data.nrSeqTituloPagarFatura > 0 && (
          <div className='col-5 mb-3'>
            <Textbox
              label='Valor Titulo'
              required
              readOnly
              textRight
              text={parcela.vlrTitulo}
              mask={MaskTypes.DecimalCustom}
              onChangedValue={(vlrTitulo) => {
                setParcela({ ...parcela, vlrTitulo });
              }}
              onBlur={Recalcula}
            />
          </div>
        )}

        {data.nrSeqTituloPagarFatura == null && (
          <div className='col-5 mb-3'>
            <Textbox
              label='Valor Titulo'
              required
              textRight
              text={parcela.vlrTitulo}
              mask={MaskTypes.DecimalCustom}
              onChangedValue={(vlrTitulo) => {
                setParcela({ ...parcela, vlrTitulo });
              }}
              onBlur={Recalcula}
            />
          </div>
        )}
      </div>

      <div className='row'>
        {data.nrSeqTituloPagarFatura > 0 && (
          <div className='col-7 mb-3'>
            <Autocomplete
              label='Tipo Despesa'
              required
              searchDataSource={onSearchTipoDespesa}
              onSelectItem={async (tipoDespesa) => {
                setData({
                  ...data,
                  tipoDespesa,
                  nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
                });
                Recalcula(tipoDespesa);
              }}
              selectedItem={data.tipoDespesa}
              dataSourceTextProperty='noTipoDespesa'
              findTransaction={findTransaction}
              onOpenTransaction={onOpenTransaction}
              transactionToOpen={TransacationCodes.TipoDespesa}
              nrseqTransactionToOpen='nrSeqTipoDespesa'
            />
          </div>
        )}
        {data.nrSeqTituloPagarFatura == null && (
          <div className='col-7 mb-3'>
            <Autocomplete
              label='Tipo Despesa'
              required
              searchDataSource={onSearchTipoDespesa}
              onSelectItem={async (tipoDespesa) => {
                setData({
                  ...data,
                  tipoDespesa,
                  nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
                });
                Recalcula(tipoDespesa);
              }}
              selectedItem={data.tipoDespesa}
              dataSourceTextProperty='noTipoDespesa'
              findTransaction={findTransaction}
              onOpenTransaction={onOpenTransaction}
              transactionToOpen={TransacationCodes.TipoDespesa}
              nrseqTransactionToOpen='nrSeqTipoDespesa'
            />
          </div>
        )}
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Entregue'
            checked={data.flgBoletoEntregue}
            onChange={(flgBoletoEntregue) =>
              setData({ ...data, flgBoletoEntregue })
            }
          />
        </div>

        {data.tipoDespesa?.flgVinculaVeiculo && (
          <Panel>
            <Panel.Header
              title='Veiculo'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col-4  mb-3'>
                  <Autocomplete
                    label='Placa'
                    searchDataSource={onSearchVeiculo}
                    selectedItem={placa.veiculo}
                    onSelectItem={(veiculo) => {
                      setPlaca({
                        ...placa,
                        veiculo,
                        nrSeqVeiculo: veiculo.nrSeqVeiculo,
                      });
                    }}
                    dataSourceTextProperty='noVeiculo'
                  />
                </div>

                <div className='col-4  mb-3'>
                  <Textbox
                    label='Valor Veiculo:'
                    textRight
                    mask={MaskTypes.DecimalCustom}
                    text={placa.vlrPlaca}
                    onChangedValue={(vlrPlaca) =>
                      setPlaca({ ...placa, vlrPlaca })
                    }
                  />
                </div>
                <div className='col-4 mt-3'>
                  <Button
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    text='Adicionar'
                    onClick={onAdicionarPlaca}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-4 mt-3'>
                  <Button
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    text='Recalcular Valor Placas'
                    onClick={RecalcularValorPlacas}
                  />
                </div>
                <div className='col-5 mt-3'>
                  <Button
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    text='Ratear automaticamente ao plano de contas'
                    onClick={RatearPlanoDeContas}
                  />
                </div>
              </div>
              <div className='col'>
                <GridView
                  ref={gridView3}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns3}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </Panel.Body>
          </Panel>
        )}
      </div>
      <div className='row'>
        {data.nrSeqTituloPagarFatura > 0 && (
          <div className='col mb-3'>
            <Autocomplete
              label='Forma Pagamento'
              searchDataSource={onSearchFormaPagamento}
              required
              selectedItem={data.formaPagamento}
              onSelectItem={(formaPagamento) => {
                data.formaPagamento = formaPagamento;
                data.nrSeqFormaPagamento = formaPagamento.nrSeqFormaPagamento;
                multipag(data);
              }}
              dataSourceTextProperty='noFormaPagamento'
            />
          </div>
        )}
        {data.nrSeqTituloPagarFatura == null && (
          <div className='col mb-3'>
            <Autocomplete
              label='Forma Pagamento'
              searchDataSource={onSearchFormaPagamento}
              required
              selectedItem={data.formaPagamento}
              onSelectItem={(formaPagamento) => {
                data.formaPagamento = formaPagamento;
                data.nrSeqFormaPagamento = formaPagamento.nrSeqFormaPagamento;
                multipag(data);
              }}
              dataSourceTextProperty='noFormaPagamento'
            />
          </div>
        )}
        <div className='col mb-3'>
          <Switch
            formControl
            label='Gera Sequencia'
            checked={parcela.flgGeraSequencia}
            onChange={(flgGeraSequencia) =>
              setParcela({ ...parcela, flgGeraSequencia })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='A Vista '
            checked={data.flgAVista}
            onChange={(flgAVista) => setData({ ...data, flgAVista })}
          />
        </div>
      </div>

      {data.flgAVista && data.formaPagamento?.flgGrupo === 'C' && (
        <Panel className='row mb-3'>
          <Panel.Header
            icon='envelope'
            title='Dados Cheque'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row'>
              <div className='col-6 mb-3'>
                <DatePicker
                  label='Data Cheque'
                  required
                  text={data.dtCheque}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtCheque) => setData({ ...data, dtCheque })}
                />
              </div>

              {data.flgAVista &&
                data.formaPagamento?.flgGrupo === 'C' &&
                !data.formaPagamento?.flgControlaCheque && (
                  <div className='col-6 mb-3'>
                    <Textbox
                      label='Nr. Cheque'
                      required
                      text={data.nrCheque}
                      onChangedValue={(nrCheque) =>
                        setData({ ...data, nrCheque })
                      }
                    />
                  </div>
                )}
            </div>
          </Panel.Body>
        </Panel>
      )}

      {data.formaPagamento?.flgGrupo === 'E' && (
        <Panel className='row mb-3'>
          <Panel.Header
            icon={['fas', 'file']}
            title='Dados Complementares para pagamento eletrônico'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />

          <Panel.Body>
            {data.nrSeqTituloPagarFatura > 0 &&
              data.tituloPagarFatura?.flgRemessaPendente && (
                <div className='row mb-3'>
                  <div className='col-3 mb-3'>
                    <Autocomplete
                      required
                      readOnly
                      label='Tipo de serviço'
                      searchDataSource={onSearchTipoDeServico}
                      selectedItem={data.tipoServico}
                      onSelectItem={(tipoServico) => {
                        setData({
                          ...data,
                          tipoServico,
                          nrSeqTipoServicoBancario:
                            tipoServico.nrSeqTipoServicoBancario,
                        });
                      }}
                      dataSourceTextProperty='noTipoServicoBancario'
                    />
                  </div>
                  <div className='col-3 mb-3'>
                    <Autocomplete
                      required
                      readOnly
                      label='Forma de lançamento'
                      searchDataSource={onSearchFormaDeLancamento}
                      selectedItem={data.formaLancamentoEletronico}
                      onSelectItem={(formaLancamentoEletronico) => {
                        setData({
                          ...data,
                          formaLancamentoEletronico,
                          nrSeqFormaLancamentoEletronico:
                            formaLancamentoEletronico.nrSeqFormaLancamentoEletronico,
                        });
                      }}
                      dataSourceTextProperty='noFormaLancamentoEletronico'
                    />
                  </div>
                  <div className='col-3 mb-3'>
                    <Autocomplete
                      required
                      readOnly
                      label='Tipo de movimento'
                      searchDataSource={onSearchTipoDeMovimento}
                      selectedItem={data.tipoMovimento}
                      onSelectItem={(tipoMovimento) => {
                        setData({
                          ...data,
                          tipoMovimento,
                          nrSeqTipoMovimento: tipoMovimento.nrSeqTipoMovimento,
                        });
                      }}
                      dataSourceTextProperty='noTipoMovimento'
                    />
                  </div>
                  <div className='col-3 mb-3'>
                    <Autocomplete
                      required
                      readOnly
                      label='Instrução de movimento'
                      searchDataSource={onSearchInstrucaoDeMovimento}
                      selectedItem={data.instrucaoMovimento}
                      onSelectItem={(instrucaoMovimento) => {
                        setData({
                          ...data,
                          instrucaoMovimento,
                          nrSeqInstrucaoMovimento:
                            instrucaoMovimento.nrSeqInstrucaoMovimento,
                        });
                      }}
                      dataSourceTextProperty='noInstrucaoMovimento'
                    />
                  </div>
                </div>
              )}
            {data.nrSeqTituloPagarFatura > 0 &&
              !data.tituloPagarFatura?.flgRemessaPendente && (
                <div className='row mb-3'>
                  <div className='col-3 mb-3'>
                    <Autocomplete
                      required
                      label='Tipo de serviço'
                      searchDataSource={onSearchTipoDeServico}
                      selectedItem={data.tipoServico}
                      onSelectItem={(tipoServico) => {
                        setData({
                          ...data,
                          tipoServico,
                          nrSeqTipoServicoBancario:
                            tipoServico.nrSeqTipoServicoBancario,
                        });
                      }}
                      dataSourceTextProperty='noTipoServicoBancario'
                    />
                  </div>
                  <div className='col-3 mb-3'>
                    <Autocomplete
                      required
                      label='Forma de lançamento'
                      searchDataSource={onSearchFormaDeLancamento}
                      selectedItem={data.formaLancamentoEletronico}
                      onSelectItem={(formaLancamentoEletronico) => {
                        setData({
                          ...data,
                          formaLancamentoEletronico,
                          nrSeqFormaLancamentoEletronico:
                            formaLancamentoEletronico.nrSeqFormaLancamentoEletronico,
                        });
                      }}
                      dataSourceTextProperty='noFormaLancamentoEletronico'
                    />
                  </div>
                  <div className='col-3 mb-3'>
                    <Autocomplete
                      required
                      label='Tipo de movimento'
                      searchDataSource={onSearchTipoDeMovimento}
                      selectedItem={data.tipoMovimento}
                      onSelectItem={(tipoMovimento) => {
                        setData({
                          ...data,
                          tipoMovimento,
                          nrSeqTipoMovimento: tipoMovimento.nrSeqTipoMovimento,
                        });
                      }}
                      dataSourceTextProperty='noTipoMovimento'
                    />
                  </div>
                  <div className='col-3 mb-3'>
                    <Autocomplete
                      required
                      label='Instrução de movimento'
                      searchDataSource={onSearchInstrucaoDeMovimento}
                      selectedItem={data.instrucaoMovimento}
                      onSelectItem={(instrucaoMovimento) => {
                        setData({
                          ...data,
                          instrucaoMovimento,
                          nrSeqInstrucaoMovimento:
                            instrucaoMovimento.nrSeqInstrucaoMovimento,
                        });
                      }}
                      dataSourceTextProperty='noInstrucaoMovimento'
                    />
                  </div>
                </div>
              )}
            {data.nrSeqTituloPagarFatura == null && (
              <div className='row mb-3'>
                <div className='col-3 mb-3'>
                  <Autocomplete
                    required
                    label='Tipo de serviço'
                    searchDataSource={onSearchTipoDeServico}
                    selectedItem={data.tipoServico}
                    onSelectItem={(tipoServico) => {
                      setData({
                        ...data,
                        tipoServico,
                        nrSeqTipoServicoBancario:
                          tipoServico.nrSeqTipoServicoBancario,
                      });
                    }}
                    dataSourceTextProperty='noTipoServicoBancario'
                  />
                </div>
                <div className='col-3 mb-3'>
                  <Autocomplete
                    required
                    label='Forma de lançamento'
                    searchDataSource={onSearchFormaDeLancamento}
                    selectedItem={data.formaLancamentoEletronico}
                    onSelectItem={(formaLancamentoEletronico) => {
                      setData({
                        ...data,
                        formaLancamentoEletronico,
                        nrSeqFormaLancamentoEletronico:
                          formaLancamentoEletronico.nrSeqFormaLancamentoEletronico,
                      });
                    }}
                    dataSourceTextProperty='noFormaLancamentoEletronico'
                  />
                </div>
                <div className='col-3 mb-3'>
                  <Autocomplete
                    required
                    label='Tipo de movimento'
                    searchDataSource={onSearchTipoDeMovimento}
                    selectedItem={data.tipoMovimento}
                    onSelectItem={(tipoMovimento) => {
                      setData({
                        ...data,
                        tipoMovimento,
                        nrSeqTipoMovimento: tipoMovimento.nrSeqTipoMovimento,
                      });
                    }}
                    dataSourceTextProperty='noTipoMovimento'
                  />
                </div>
                <div className='col-3 mb-3'>
                  <Autocomplete
                    required
                    label='Instrução de movimento'
                    searchDataSource={onSearchInstrucaoDeMovimento}
                    selectedItem={data.instrucaoMovimento}
                    onSelectItem={(instrucaoMovimento) => {
                      setData({
                        ...data,
                        instrucaoMovimento,
                        nrSeqInstrucaoMovimento:
                          instrucaoMovimento.nrSeqInstrucaoMovimento,
                      });
                    }}
                    dataSourceTextProperty='noInstrucaoMovimento'
                  />
                </div>
              </div>
            )}
            {data.nrSeqTituloPagarFatura > 0 &&
              !data.tituloPagarFatura?.flgRemessaPendente && (
                <div className='row mb-3'>
                  <div className='col-3 mb-3'>
                    <Autocomplete
                      required
                      label='Movimento Remessa'
                      searchDataSource={onSearchMovimentoRemessa}
                      selectedItem={data.movimentoRemessa}
                      onSelectItem={(movimentoRemessa) => {
                        setData({
                          ...data,
                          movimentoRemessa,
                          nrSeqTipoMovimentoRemessa:
                            movimentoRemessa.nrSeqTipoMovimentoRemessa,
                        });
                      }}
                      dataSourceTextProperty='noTipoMovimentoRemessa'
                    />
                  </div>
                  <div className='col-3 mb-3'>
                    <Autocomplete
                      label='Sacador Avalista'
                      searchDataSource={onSearchSacadorAvalista}
                      selectedItem={data.pessoaSacador}
                      onSelectItem={(pessoaSacador) => {
                        setData({
                          ...data,
                          pessoaSacador,
                          nrSeqPessoaSacador: pessoaSacador.nrSeqPessoa,
                        });
                      }}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                </div>
              )}
            {data.nrSeqTituloPagarFatura > 0 &&
              data.tituloPagarFatura?.flgRemessaPendente && (
                <div className='row mb-3'>
                  <div className='col-3 mb-3'>
                    <Autocomplete
                      required
                      readOnly
                      label='Movimento Remessa'
                      searchDataSource={onSearchMovimentoRemessa}
                      selectedItem={data.movimentoRemessa}
                      onSelectItem={(movimentoRemessa) => {
                        setData({
                          ...data,
                          movimentoRemessa,
                          nrSeqTipoMovimentoRemessa:
                            movimentoRemessa.nrSeqTipoMovimentoRemessa,
                        });
                      }}
                      dataSourceTextProperty='noTipoMovimentoRemessa'
                    />
                  </div>
                  <div className='col-3 mb-3'>
                    <Autocomplete
                      label='Sacador Avalista'
                      readOnly
                      searchDataSource={onSearchSacadorAvalista}
                      selectedItem={data.pessoaSacador}
                      onSelectItem={(pessoaSacador) => {
                        setData({
                          ...data,
                          pessoaSacador,
                          nrSeqPessoaSacador: pessoaSacador.nrSeqPessoa,
                        });
                      }}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                </div>
              )}
            {data.nrSeqTituloPagarFatura == null && (
              <div className='row mb-3'>
                <div className='col-3 mb-3'>
                  <Autocomplete
                    required
                    label='Movimento Remessa'
                    searchDataSource={onSearchMovimentoRemessa}
                    selectedItem={data.movimentoRemessa}
                    onSelectItem={(movimentoRemessa) => {
                      setData({
                        ...data,
                        movimentoRemessa,
                        nrSeqTipoMovimentoRemessa:
                          movimentoRemessa.nrSeqTipoMovimentoRemessa,
                      });
                    }}
                    dataSourceTextProperty='noTipoMovimentoRemessa'
                  />
                </div>
                <div className='col-3 mb-3'>
                  <Autocomplete
                    label='Sacador Avalista'
                    searchDataSource={onSearchSacadorAvalista}
                    selectedItem={data.pessoaSacador}
                    onSelectItem={(pessoaSacador) => {
                      setData({
                        ...data,
                        pessoaSacador,
                        nrSeqPessoaSacador: pessoaSacador.nrSeqPessoa,
                      });
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
              </div>
            )}
          </Panel.Body>
        </Panel>
      )}

      {parcela.flgGeraSequencia && (
        <div className='col-12'>
          <Panel>
            <Panel.Header
              title='Sequencia'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col-4'>
                  <RadioButton
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    selectedButton={parcela.tipoParcela}
                    onChange={(tipoParcela) => {
                      setParcela({
                        ...parcela,
                        tipoParcela,
                      });
                    }}
                    buttons={tipoParcelas}
                  />
                </div>
                <div className='col-2  mb-3'>
                  <Textbox
                    label='Parcelas:'
                    mask={MaskTypes.Integer}
                    text={parcela.nrParcela}
                    onChangedValue={(nrParcela) =>
                      setParcela({ ...parcela, nrParcela })
                    }
                  />
                </div>
                {parcela.tipoParcela === 2 && (
                  <div className='col-2 mb-3'>
                    <Textbox
                      maxLength={400}
                      visible={parcela.registrodia}
                      label='Dias:'
                      text={data.nrDias}
                      mask={MaskTypes.Integer}
                      onChangedValue={(nrDias) =>
                        setParcela({ ...parcela, nrDias })
                      }
                    />
                  </div>
                )}
                <div className='col-2 mt-3'>
                  <Button
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    text='Adicionar'
                    onClick={onAdicionarParcelas}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      )}
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={400}
            label='Obs.'
            text={data.noObs}
            onChangedValue={(noObs) => setData({ ...data, noObs })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <Panel>
            <Panel.Header
              title='Rateio do plano de Contas'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-9'>
                  <GridView
                    ref={gridView2}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns2}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-4 mb-3'>
                  <Textbox
                    label='Valor Somado Plano De Contas'
                    readOnly
                    mask={MaskTypes.DecimalCustom}
                    text={planoConta.vlrSomadoPlanoDeContas}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
          {edicaoTipoDespesa && (
            <Panel>
              <Panel.Header
                title='Editar Sequencia'
                theme={Theme.Light}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row'>
                  <div className='col-2  mb-3'>
                    <Textbox
                      label='Valor:'
                      mask={MaskTypes.DecimalCustom}
                      text={edicaoDespesa?.vlrPlanoConta}
                      onChangedValue={(vlrPlanoConta) =>
                        setDespesa({ ...edicaoDespesa, vlrPlanoConta })
                      }
                    />
                  </div>
                  <div className='col-2 mt-3'>
                    <Button
                      theme={Theme.Primary}
                      template={Button.Templates.Button}
                      text='Adicionar'
                      onClick={onAdicionarTipoDespesa}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          )}
          <div className='row mb-3'>
            <div className='col-3 mb-3'>
              <Textbox
                label='Valor Pago'
                textRight
                readOnly
                text={data.vlrPago}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrPago) => setData({ ...data, vlrPago })}
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Valor Multa Pago'
                textRight
                readOnly
                text={data.vlrMultaPaga}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrMultaPaga) =>
                  setData({ ...data, vlrMultaPaga })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Valor Juros Pago'
                textRight
                readOnly
                text={data.vlrJuroPago}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrJuroPago) =>
                  setData({ ...data, vlrJuroPago })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Saldo Título'
                textRight
                readOnly
                text={data.vlrSaldoTit}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrSaldoTit) =>
                  setData({ ...data, vlrSaldoTit })
                }
              />
            </div>
          </div>
          <div className='col-12 mb-3'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>
      </div>
      {edicao && (
        <Panel>
          <Panel.Header
            title='Editar Sequencia'
            theme={Theme.Light}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row'>
              <div className='col-2  mb-3'>
                <Textbox
                  label='Valor:'
                  mask={MaskTypes.DecimalCustom}
                  text={edicaoParcela.vlrTitulo}
                  onChangedValue={(vlrTitulo) =>
                    setEdicaoParcela({ ...edicaoParcela, vlrTitulo })
                  }
                />
              </div>
              <div className='col-3 mb-3'>
                <DatePicker
                  label='Vencimento'
                  text={edicaoParcela.dtVencimento}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtVencimento) =>
                    setEdicaoParcela({ ...edicaoParcela, dtVencimento })
                  }
                />
              </div>
              <div className='col-3 mb-3'>
                <Textbox
                  label='Ordem'
                  readOnly
                  maxLength={3}
                  text={edicaoParcela.nrOrdem}
                  mask={MaskTypes.Integer}
                />
              </div>
              <div className='col-3 mb-3'>
                <Textbox
                  maxLength={56}
                  label='Codigo de Barras'
                  text={edicaoParcela.noCodigoBarras}
                  onChangedValue={(noCodigoBarras) =>
                    setEdicaoParcela({ ...edicaoParcela, noCodigoBarras })
                  }
                  onBlur={CodigoBarraEdicaoParcela}
                />
              </div>
              <div className='col-1 mt-3'>
                <Button
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  text='Adicionar'
                  onClick={onAdicionarParcelasEditadas}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      )}

      <div className='row mb-3'>
        <div className='col'>
          <div className='row mb-2'>
            <Panel>
              <Panel.Header
                icon={['fas', 'file']}
                title='Documentos Anexados ao Titulo Pagar'
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />

              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-4 mb-3'>
                    <Textbox
                      maxLength={400}
                      label='Observações dos documentos:'
                      text={obs.noObs}
                      onChangedValue={(noObs) => {
                        obs.noObs = noObs;
                      }}
                    />
                  </div>
                  <div className='col-8 mb-3'>
                    <FileUpload
                      files={importedFile}
                      onChange={(files) => setImportedFile(files)}
                      allowedMimeTypes={[
                        MimeTypes.Types.PDF,
                        MimeTypes.Types.Image,
                        MimeTypes.Types.Word,
                      ]}
                    />
                  </div>
                </div>

                <div className='row mb-3'>
                  <div className='col'>
                    <GridView
                      ref={gridViewDocumentos}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columnsAnexos}
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      </div>

      {data.nrSeqTituloPagar > 0 && (
        <div className='row mb-3'>
          <span>{data.inclusao}</span>
        </div>
      )}
      {data.nrSeqTituloPagar > 0 && data.nrSeqUsuarioAlt > 0 && (
        <div className='row mb-3'>
          <span>{data.alteracao}</span>
        </div>
      )}
      <ModalOcorrencias
        show={modalShow.enviarFaturasPorEmail}
        ocorrencias={modalShow.ocorrencias}
        onSave={(ocorrencias) => {
          setData({ ...data, ocorrencias });
          setModalShow({ ...modalShow, ocorrencias: data.ocorrencias });
        }}
        onClose={() => {
          setModalShow({
            ...modalShow,
            enviarFaturasPorEmail: false,
          });
        }}
      />
      <ModalDesagruparTitulo
        show={modalShow.TituloPagarAgrupado}
        agrupamentoTituloValor={data}
        /* onSave={(ocorrencias) => {
          setData({ ...data, ocorrencias });
          setModalShow({ ...modalShow, ocorrencias: data.ocorrencias });
        }} */
        onClose={() => {
          setModalShow({
            ...modalShow,
            TituloPagarAgrupado: false,
          });
        }}
      />
      <ModalNovoTituloComSaldo
        show={modalShow.novoTitulo}
        novoTitulo={modalShow.novoTitulo}
        onClose={() => {
          setModalShow({
            ...modalShow,
            novoTitulo: false,
          });
        }}
      />
    </CSDManutPage>
  );
}
