import React, { useState, useEffect, useRef } from 'react';
import JSZip from 'jszip';
import {
  CSDSelPage,
  Textbox,
  Panel,
  DatePicker,
  DropdownList,
  RadioButton,
  Switch,
  Autocomplete,
  GridView,
  Button,
} from 'ui/components';
import {
  Theme,
  JustifyContent,
  MaskTypes,
  ResponseStatus,
  BootstrapSizes,
  ColumnDataTypes,
  ColumnTypes,
} from 'ui/Helpers/enums';
import {
  Page,
  Message,
  GridviewColumns,
  GridViewCurrent,
} from 'ui/Helpers/interfaces';
import { IRadioButton } from 'ui/components/interfaces';

import {
  getEmpresaAutoComplete,
  getEmpresaDefaultUsuario,
} from 'core/services/SEG';
import {
  getRemetenteAutoComplete,
  getDestinatarioAutoComplete,
} from 'core/services/FRO/remetente_Destinatario';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';
import {
  getDownloadXml,
  downloadCte,
  downloadNfs,
  downloadNfe,
  downloadNfeNfv,
} from 'core/services/FRO/downloadXml';

import { Empresa } from 'core/models/SEG';
import {
  ColetaRemetente,
  ColetaDestinatario,
  ColetaEmbarque,
  Motorista,
} from 'core/models/FRO';
import { Nfs } from 'core/models/VEN';
import { NFEImportada } from 'core/models/COP';
import { Cliente } from 'core/models/FIN';
import { FiltersDownloadXml } from 'core/interfaces/FRO';

import Nfv from 'core/models/VEN/nfv';

export default function DownloadXml({ transaction, isActive }: Page) {
  const [dropDownEmpresas, setDropDownEmpresas] = useState<Empresa[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [filters, setFilters] = useState<FiltersDownloadXml>({
    nrCteNfe: '',
    dtInicial: '',
    dtFinal: '',
    empresa: new Empresa(),
    flgTipoNota: 'cte',
    statusCte: 'all',
    autorizados: true,
    geraXml: true,
    geraPdf: false,
    geraAgrupado: false,
    remetente: new ColetaRemetente(),
    destinatario: new ColetaDestinatario(),
    cliente: new Cliente(),
  });
  const gridViewColetaEmbarque = useRef<GridViewCurrent<ColetaEmbarque>>();
  const gridViewNfs = useRef<GridViewCurrent<Nfs>>();
  const gridViewNfe = useRef<GridViewCurrent<NFEImportada>>();
  const gridViewNfeNfv = useRef<GridViewCurrent<Nfv>>();

  const onSetMessage = (status: ResponseStatus, msg: string): void => {
    let theme;

    if (status === ResponseStatus.Success) theme = Theme.Success;
    else if (status === ResponseStatus.Warning) theme = Theme.Warning;
    else theme = Theme.Danger;

    if (msg)
      setMessage({
        message: msg,
        theme,
      });
  };

  const onSearchEmpresas = async (): Promise<void> => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete();

    if (msg) onSetMessage(status, msg);

    setDropDownEmpresas(empresas);
  };

  const load = async (): Promise<void> => {
    setLoading(true);

    await onSearchEmpresas();

    const { status, message: msg, empresas } = await getEmpresaDefaultUsuario();

    setFilters({
      ...filters,
      empresa: empresas[0],
      nrSeqEmpresa: empresas[0].nrSeqEmpresa,
    });

    if (msg) onSetMessage(status, msg);

    setLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  const buttonsTipoNota: IRadioButton[] = [
    { text: 'CTe', value: 'cte' },
    { text: 'NFe', value: 'nfe' },
    { text: 'NFs', value: 'nfs' },
    { text: 'NFe Venda', value: 'nfenfv' },
  ];

  const buttonsStatusCte: IRadioButton[] = [
    { text: 'Todos', value: 'all' },
    { text: 'Autorizados', value: 'aut' },
    { text: 'Cancelados', value: 'can' },
    { text: 'Autorizados / Cancelados', value: 'autcan' },
  ];

  const onAutoCompleteRemetente = async (
    e: string
  ): Promise<ColetaRemetente[]> => {
    const {
      status,
      message: msg,
      data: remetentes,
    } = await getRemetenteAutoComplete({
      nrSeqEmpresa: filters.empresa?.nrSeqEmpresa,
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return remetentes;
  };

  const onAutoCompleteDestinatario = async (
    e: string
  ): Promise<ColetaDestinatario[]> => {
    const {
      status,
      message: msg,
      data: destinatarios,
    } = await getDestinatarioAutoComplete({
      flgTodos: true,
      nrSeqEmpresa: filters.empresa?.nrSeqEmpresa,
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return destinatarios;
  };

  const onAutoCompleteCliente = async (e: string): Promise<Cliente[]> => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return clientes;
  };

  const columnsColetaEmbarque: GridviewColumns[] = [
    {
      key: 'nrSeqColetaEmbarque',
      type: ColumnTypes.Checkbox,
    },
    { key: 'cdColetaEmbarque', title: 'Cód.' },
    { key: 'cliente.noPessoa', title: 'Cliente' },
    { key: 'motorista.noPessoa', title: 'Motorista' },
    { key: 'dtCad', title: 'Cadastro', format: ColumnDataTypes.Date },
    { key: 'dtEmissao', title: 'Emissão', format: ColumnDataTypes.Date },
    { key: 'stringDocumentos', title: 'Documentos' },
    { key: 'vlrDoc', title: 'Total Doc.', format: ColumnDataTypes.Decimal },
    { key: 'vlrFrete', title: 'Frete', format: ColumnDataTypes.Decimal },
    { key: 'vlrTotal', title: 'Total', format: ColumnDataTypes.Decimal },
    {
      key: 'statusCteGrid.string',
      title: 'Status',
      color: 'statusCteGrid.color',
    },
  ];

  const columnsNfs: GridviewColumns[] = [
    {
      key: 'nrSeqNfs',
      type: ColumnTypes.Checkbox,
    },
    {
      key: 'nrNfs',
      title: 'Nr. NFs',
    },
    { key: 'serieNFs', title: 'Série' },
    { key: 'dtCad', title: 'Cadastro', format: ColumnDataTypes.Date },
    { key: 'dtEmissao', title: 'Autorização', format: ColumnDataTypes.Date },
    { key: 'cliente.noPessoa', title: 'Cliente' },
    {
      key: 'vlrBruto',
      title: 'Vlr Bru',
      format: ColumnDataTypes.Decimal,
    },
    {
      key: 'vlrLiquido',
      title: 'Vlr Líq',
      format: ColumnDataTypes.Decimal,
    },
    {
      key: 'noStatusAutorizacao',
      title: 'Status',
      colorText: 'noColorStatusAutorizacao',
    },
  ];

  const columnsNfe: GridviewColumns[] = [
    { key: 'nrSeqNfeImportada', type: ColumnTypes.Checkbox },
    { key: 'nfeImportadaTipo.noNfeImportadaTipo', title: 'Tipo' },
    { key: 'noFornecedor', title: 'Fornecedor' },
    { key: 'noSerie', title: 'Série' },
    { key: 'noNumero', title: 'Número' },
    { key: 'noCliente', title: 'Cliente' },

    { key: 'dtNfe', title: 'Emissão', format: ColumnDataTypes.Date },
    { key: 'chNfe', title: 'Chave' },
    { key: 'noCte', title: 'CTE' },
    { key: 'vlrNfe', title: 'Valor', format: ColumnDataTypes.Decimal },
  ];

  const columnsNfeNfv: GridviewColumns[] = [
    { key: 'nrSeqNfv', type: ColumnTypes.Checkbox },
    { key: 'nrNfv', title: 'Número' },
    { key: 'cliente.noPessoa', title: 'Cliente' },
    { key: 'dtCad', title: 'Cadastro', format: ColumnDataTypes.Date },
    {
      key: 'dtEmissao',
      title: 'Emissão',
      format: ColumnDataTypes.Date,
    },
    { key: 'vlrNfv', title: 'Total', format: ColumnDataTypes.Decimal },

    {
      key: 'noStatus',
      title: 'Status',
    },
  ];

  const onSearch = async (): Promise<void> => {
    setLoading(true);

    const { status, message: msg, data, pagination } = await getDownloadXml({
      autorizados: filters.autorizados,
      geraXml: filters.geraXml,
      geraPdf: filters.geraPdf,
      geraAgrupado: filters.geraAgrupado,
      nrCteNfe: filters.nrCteNfe,
      dtInicial: filters.dtInicial,
      dtFinal: filters.dtFinal,
      nrSeqEmpresa: filters.nrSeqEmpresa,
      nrSeqPessoaDes: filters.nrSeqPessoaDes,
      nrSeqPessoaRem: filters.nrSeqPessoaRem,
      nrSeqPessoaCli: filters.nrSeqPessoaCli,
      nrSeqPessoaMot: filters.nrSeqPessoaMot,
      flgTipoNota: filters.flgTipoNota,
      statusCte: filters.statusCte,
    });

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      if (filters.flgTipoNota === 'cte') {
        data.forEach((item: any) => {
          // @ts-expect-error
          item.statusCteGrid = ColetaEmbarque.EnumStatusCte[item.statusCte];
        });

        if (gridViewColetaEmbarque && gridViewColetaEmbarque.current) {
          gridViewColetaEmbarque.current.setDataSource(data, pagination);
        }
      } else if (filters.flgTipoNota === 'nfs') {
        if (gridViewNfs && gridViewNfs.current) {
          gridViewNfs.current.setDataSource(data, pagination);
        }
      } else if (filters.flgTipoNota === 'nfe') {
        if (gridViewNfe && gridViewNfe.current) {
          gridViewNfe.current.setDataSource(data, pagination);
        }
      } else if (filters.flgTipoNota === 'nfenfv') {
        if (gridViewNfeNfv && gridViewNfeNfv.current) {
          gridViewNfeNfv.current.setDataSource(data, pagination);
        }
      }
    }

    setLoading(false);
  };

  const convertBase64ToBlob = (base64: string, zip?: boolean): Blob => {
    const byteCharacters = atob(base64);
    const byteArray = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }

    const type = zip ? 'application/zip' : 'application/pdf';

    const pdfBlob = new Blob([byteArray], {
      type,
    });

    return pdfBlob;
  };

  const generateZipNfs = async (): Promise<JSZip> => {
    const zip = new JSZip();

    const selectedsValues = gridViewNfs.current
      ?.getCheckBoxValuesAt(0)
      .map((row) => row[0].value);

    if (selectedsValues?.length === 0) {
      onSetMessage(ResponseStatus.Error, 'Selecione ao menos uma NF.');

      return zip;
    }

    const { status, message: msg, data } = await downloadNfs({
      selectedsValues,
      flgTipoNota: filters.flgTipoNota,
      dtInicial: filters.dtInicial,
      dtFinal: filters.dtFinal,
      nrSeqEmpresa: filters.nrSeqEmpresa,
      geraXml: filters.geraXml,
      geraPdf: filters.geraPdf,
      geraAgrupado: filters.geraAgrupado,
    });

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      const zipUrl = URL.createObjectURL(
        convertBase64ToBlob(data.fileContent, true)
      );
      const downloadLink = document.createElement('a');
      downloadLink.href = zipUrl;
      downloadLink.download = data.fileName;
      downloadLink.click();
      URL.revokeObjectURL(zipUrl);
    }

    return zip;
  };

  const generateZipCte = async (): Promise<void> => {
    const selectedsValues = gridViewColetaEmbarque.current
      ?.getCheckBoxValuesAt(0)
      .map((row) => row[0].value);

    if (selectedsValues?.length === 0) {
      onSetMessage(ResponseStatus.Error, 'Selecione ao menos um CTe.');

      return;
    }

    const { status, message: msg, data } = await downloadCte({
      selectedsValues,
      nrSeqEmpresa: filters.nrSeqEmpresa,
      flgTipoNota: filters.flgTipoNota,
      dtInicial: filters.dtInicial,
      dtFinal: filters.dtFinal,
      geraXml: filters.geraXml,
      geraPdf: filters.geraPdf,
      geraAgrupado: filters.geraAgrupado,
    });

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      const zipUrl = URL.createObjectURL(
        convertBase64ToBlob(data.fileContent, true)
      );
      const downloadLink = document.createElement('a');
      downloadLink.href = zipUrl;
      downloadLink.download = data.fileName;
      downloadLink.click();
      URL.revokeObjectURL(zipUrl);
    }
  };

  const generateZipNfe = async (): Promise<JSZip> => {
    const zip = new JSZip();

    const selectedsValues = gridViewNfe.current
      ?.getCheckBoxValuesAt(0)
      .map((row) => row[0].value);

    if (selectedsValues?.length === 0) {
      onSetMessage(ResponseStatus.Error, 'Selecione ao menos uma nota.');

      return zip;
    }

    const { status, message: msg, data } = await downloadNfe({
      selectedsValues,
      flgTipoNota: filters.flgTipoNota,
      dtFinal: filters.dtFinal,
      dtInicial: filters.dtInicial,
      geraXml: filters.geraXml,
      geraPdf: filters.geraPdf,
    });

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      const zipUrl = URL.createObjectURL(
        convertBase64ToBlob(data.fileContent, true)
      );
      const downloadLink = document.createElement('a');
      downloadLink.href = zipUrl;
      downloadLink.download = data.fileName;
      downloadLink.click();
      URL.revokeObjectURL(zipUrl);
    }

    return zip;
  };

  const generateZipNfeNfv = async (): Promise<JSZip> => {
    const zip = new JSZip();

    const selectedsValues = gridViewNfeNfv.current
      ?.getCheckBoxValuesAt(0)
      .map((row) => row[0].value);

    if (selectedsValues?.length === 0) {
      onSetMessage(ResponseStatus.Error, 'Selecione ao menos uma nota.');

      return zip;
    }

    const { status, message: msg, data } = await downloadNfeNfv({
      selectedsValues,
      flgTipoNota: filters.flgTipoNota,
      dtFinal: filters.dtFinal,
      dtInicial: filters.dtInicial,
      geraXml: filters.geraXml,
      geraPdf: filters.geraPdf,
    });

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      const zipUrl = URL.createObjectURL(
        convertBase64ToBlob(data.fileContent, true)
      );
      const downloadLink = document.createElement('a');
      downloadLink.href = zipUrl;
      downloadLink.download = data.fileName;
      downloadLink.click();
      URL.revokeObjectURL(zipUrl);
    }

    return zip;
  };

  const onClickDownload = async () => {
    setLoading(true);

    if (filters.flgTipoNota === 'cte') await generateZipCte();
    else if (filters.flgTipoNota === 'nfs') await generateZipNfs();
    else if (filters.flgTipoNota === 'nfe') await generateZipNfe();
    else if (filters.flgTipoNota === 'nfenfv') await generateZipNfeNfv();

    setLoading(false);
  };

  const onAutoCompleteMotorista = async (e: string) => {
    const { status, message: msg, motoristas } = await getMotoristaAutoComplete(
      {
        noPessoa: e,
      }
    );

    if (msg) onSetMessage(status, msg);

    return motoristas;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      loading={loading}
      message={message}
      onSearch={onSearch}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row'>
        {/* @ts-expect-error */}
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header
            icon='download'
            title='Download XML'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row'>
              <div className='row mb-3'>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Nr. Documento'
                    text={filters.nrCteNfe}
                    onChangedValue={(nrCteNfe: string) =>
                      setFilters({ ...filters, nrCteNfe })
                    }
                  />
                </div>
                <div className='col-2'>
                  <DatePicker
                    label='Data Inicial'
                    text={filters.dtInicial}
                    // @ts-expect-error
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dtInicial: string) =>
                      setFilters({ ...filters, dtInicial })
                    }
                  />
                </div>
                <div className='col-2'>
                  <DatePicker
                    label='Data Final'
                    text={filters.dtFinal}
                    // @ts-expect-error
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dtFinal: string) =>
                      setFilters({ ...filters, dtFinal })
                    }
                  />
                </div>
                <div className='col'>
                  <DropdownList
                    label='Empresa'
                    dataSource={dropDownEmpresas}
                    dataSourcePropertyText='noPessoa'
                    dataSourcePropertyValue='nrSeqEmpresa'
                    selectedItems={filters.empresa ?? []}
                    onSelectItem={(empresa: Empresa) => {
                      setFilters({
                        ...filters,
                        empresa,
                        nrSeqEmpresa: empresa?.nrSeqEmpresa,
                      });
                    }}
                  />
                </div>
              </div>
              {filters.flgTipoNota === 'cte' && (
                <div className='row mb-3'>
                  <div className='col-6'>
                    <Autocomplete
                      label='Remetente'
                      searchDataSource={onAutoCompleteRemetente}
                      selectedItem={filters.remetente}
                      onSelectItem={(remetente: ColetaRemetente) =>
                        setFilters({
                          ...filters,
                          remetente,
                          nrSeqPessoaRem: remetente?.nrSeqPessoaRem,
                        })
                      }
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                  <div className='col-6'>
                    <Autocomplete
                      label='Destinatário'
                      searchDataSource={onAutoCompleteDestinatario}
                      selectedItem={filters.destinatario}
                      onSelectItem={(destinatario: ColetaDestinatario) =>
                        setFilters({
                          ...filters,
                          destinatario,
                          nrSeqPessoaDes: destinatario?.nrSeqPessoaDes,
                        })
                      }
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                </div>
              )}
              <div className='row mb-3'>
                {filters.flgTipoNota !== 'nfe' && (
                  <div className='col-5'>
                    <Autocomplete
                      label='Cliente'
                      searchDataSource={onAutoCompleteCliente}
                      selectedItem={filters.cliente}
                      onSelectItem={(cliente: Cliente) =>
                        setFilters({
                          ...filters,
                          cliente,
                          nrSeqPessoaCli: cliente?.nrSeqPessoaCli,
                        })
                      }
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                )}
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <RadioButton
                    outline
                    label='Tipo de nota para impressão'
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    buttons={buttonsTipoNota}
                    selectedButton={filters.flgTipoNota}
                    onChange={(flgTipoNota: string) =>
                      setFilters({ ...filters, flgTipoNota })
                    }
                  />
                </div>
                {filters.flgTipoNota !== 'cte' && (
                  <div className='col-1'>
                    {/* @ts-expect-error */}
                    <Switch
                      formControl
                      checked={filters.autorizados}
                      label='Autorizados'
                      onChange={(autorizados: boolean) =>
                        setFilters({ ...filters, autorizados })
                      }
                    />
                  </div>
                )}
                <div className='col-1'>
                  {/* @ts-expect-error */}
                  <Switch
                    formControl
                    checked={filters.geraXml}
                    label='Gera XML'
                    onChange={(geraXml: boolean) =>
                      setFilters({ ...filters, geraXml })
                    }
                  />
                </div>
                <div className='col-1'>
                  {/* @ts-expect-error */}
                  <Switch
                    formControl
                    checked={filters.geraPdf}
                    label='Gera PDF'
                    onChange={(geraPdf: boolean) =>
                      setFilters({ ...filters, geraPdf })
                    }
                  />
                </div>
                {filters.geraPdf &&
                  (filters.flgTipoNota === 'cte' ||
                    filters.flgTipoNota === 'nfs') && (
                    <div className='col-2'>
                      {/* @ts-expect-error */}
                      <Switch
                        formControl
                        checked={filters.geraAgrupado}
                        label='Gera Agrupado'
                        onChange={(geraAgrupado: boolean) =>
                          setFilters({ ...filters, geraAgrupado })
                        }
                      />
                    </div>
                  )}
              </div>
              {filters.flgTipoNota === 'cte' && (
                <div className='row'>
                  <div className='col-5'>
                    <Autocomplete
                      label='Motorista'
                      searchDataSource={onAutoCompleteMotorista}
                      selectedItem={filters.motorista}
                      onSelectItem={(motorista: Motorista) => {
                        setFilters({
                          ...filters,
                          nrSeqPessoaMot: motorista?.nrSeqPessoaMot,
                        });
                      }}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                  <div className='col-4'>
                    {/* @ts-expect-error */}
                    <RadioButton
                      outline
                      label='Status CTe'
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      buttons={buttonsStatusCte}
                      selectedButton={filters.statusCte}
                      onChange={(statusCte: string) => {
                        setFilters({ ...filters, statusCte });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </Panel.Body>
        </Panel>
        {(gridViewColetaEmbarque.current?.getDataSource()?.length > 0 ||
          gridViewNfs.current?.getDataSource()?.length > 0 ||
          gridViewNfe.current?.getDataSource()?.length > 0 ||
          gridViewNfeNfv.current?.getDataSource()?.length > 0) && (
          <div className='row mt-3'>
            <div className='col-3'>
              <Button
                outline
                icon='download'
                text='Download'
                theme={Theme.Success}
                onClick={onClickDownload}
              />
            </div>
          </div>
        )}
        {filters.flgTipoNota === 'cte' && (
          <GridView
            ref={gridViewColetaEmbarque}
            // @ts-expect-error
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columnsColetaEmbarque}
            gridSizeList={[50000]}
            sumFields
            offlineData
            showSelectSizes={false}
          />
        )}
        {filters.flgTipoNota === 'nfs' && (
          <GridView
            ref={gridViewNfs}
            // @ts-expect-error
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columnsNfs}
            gridSizeList={[50000]}
            sumFields
            offlineData
            showSelectSizes={false}
          />
        )}
        {filters.flgTipoNota === 'nfe' && (
          <GridView
            ref={gridViewNfe}
            // @ts-expect-error
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columnsNfe}
            gridSizeList={[50000]}
            sumFields
            offlineData
            showSelectSizes={false}
          />
        )}
        {filters.flgTipoNota === 'nfenfv' && (
          <GridView
            ref={gridViewNfeNfv}
            // @ts-expect-error
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columnsNfeNfv}
            gridSizeList={[50000]}
            sumFields
            offlineData
            showSelectSizes={false}
          />
        )}
      </div>
    </CSDSelPage>
  );
}
