import qs from 'qs';
import Pagination from 'core/models/pagination';
import VendedorNivel from 'core/models/VEN/vendedorNivel';
import api from '../api';

const baseURL = `/VEN/VendedorNivel`;

export const getVendedorNivelList = async (filters: any) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new VendedorNivel(json)) ?? [],
  };
};

export const getVendedorNivelPagined = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json: any) => new VendedorNivel(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getVendedorNivel = async (id: number) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new VendedorNivel(value) : {};
};

export const saveVendedorNivel = async (data: any) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteVendedorNivel = async (id: number) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printVendedorNivel = async (filters: any) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelVendedorNivel = async (filters: any) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllVendedorNivel = async (ids: Array<number>) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getVendedorNivelAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/SearchAutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    nivel: value?.map((json) => new VendedorNivel(json)) ?? [],
  };
};
