export default class ProdutoMarca {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqProdutoMarca = jsonObject.nrSeqProdutoMarca;
    this.cdProdutoMarca = jsonObject.cdProdutoMarca;
    this.noProdutoMarca = jsonObject.noProdutoMarca;
  }

  toJson = () => JSON.stringify(this);
}
