import { Empresa } from '../SEG';
import Cliente from './cliente';
import FormaPagamento from './formaPagamento';

export default class Fatura {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqFatura = jsonObject.nrSeqFatura;
    this.cdFatura = jsonObject.cdFatura;
    this.vlrFatura = jsonObject.vlrFatura;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.dtFatura = jsonObject.dtFatura;
    this.vlrDesconto = jsonObject.vlrDesconto;
    this.vlrAcrescimo = jsonObject.vlrAcrescimo;
    this.nossoNumero = jsonObject.nossoNumero;
    this.noStatusFatura = jsonObject.noStatusFatura;
    this.noEmpresa = jsonObject.noEmpresa;
    this.noCliente = jsonObject.noCliente;

    this.dtVencimento = jsonObject.dtVencimento;
    this.nrSeqTituloReceberFatura = jsonObject.nrSeqTituloReceberFatura;

    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.cliente = new Cliente(jsonObject.cliente ?? {});

    this.dtVencimentoIni = jsonObject.dtVencimentoIni;
    this.dtVencimentoFim = jsonObject.dtVencimentoFim;

    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
  }

  toJson = () => JSON.stringify(this);
}
