export default class VendedorTelefonia {
  constructor(jsonObject = {}) {
    this.flgDescontaAviso = jsonObject.flgDescontaAviso;
    this.flgDescontaCustoNf = jsonObject.flgDescontaCustoNf;
    this.flgDescontaCustoRateio = jsonObject.flgDescontaCustoRateio;
    this.flgDescontaCustoTaxaBancaria = jsonObject.flgDescontaCustoTaxaBancaria;
    this.flgParcelaUnica = jsonObject.flgParcelaUnica;
    this.flgPercentual = jsonObject.flgPercentual;
    this.noPessoa = jsonObject.noPessoa;
    this.nrSeqPessoaVen = jsonObject.nrSeqPessoaVen;
    this.nrSeqVendedor_Telefonia = jsonObject.nrSeqVendedor_Telefonia;
    this.vlrComissao = jsonObject.vlrComissao;
    this.nrSeqsCentroDeCusto = jsonObject.nrSeqsCentroDeCusto;
    this.flgDescontaParcelaAberta = jsonObject.flgDescontaParcelaAberta;
  }

  get flgDescontaAvisoFormatado() {
    return this.flgDescontaAviso === true ? 'Sim' : 'Não';
  }

  get flgDescontaCustoNfFormatado() {
    return this.flgDescontaCustoNf === true ? 'Sim' : 'Não';
  }

  get flgDescontaCustoTaxaBancariaFormatado() {
    return this.flgDescontaCustoTaxaBancaria === true ? 'Sim' : 'Não';
  }

  get flgDescontaCustoRateioFormatado() {
    return this.flgDescontaCustoRateio === true ? 'Sim' : 'Não';
  }

  get flgParcelaUnicaFormatado() {
    return this.flgParcelaUnica === true ? 'Sim' : 'Não';
  }

  get flgPercentualFormatado() {
    return this.flgPercentual === true ? 'Sim' : 'Não';
  }

  get flgDescontaParcelaAbertaFormatado() {
    return this.flgDescontaParcelaAberta === true ? 'Sim' : 'Não';
  }

  toJson = () => JSON.stringify(this);
}
