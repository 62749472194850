import React, { useRef, useState, useEffect } from 'react';
import { getVeiculoMarcaAutoComplete } from 'core/services/FRO/veiculoMarca';
import {
  getVeiculoModeloAutoComplete,
  saveVeiculoModelo,
} from 'core/services/FRO/veiculoModelo';
import { MaskTypes } from 'ui/Helpers/masks';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  GridView,
  Loader,
  Modal,
  Notification,
  Panel,
  Switch,
  Textbox,
} from 'ui/components';
import VeiculoModeloEixo from 'core/models/FRO/veiculoModeloEixo';
import { getEixoAutoComplete } from 'core/services/FRO/eixo';

export default function ModalCadastroVeiculoModelo({
  show,
  onClose,
  data,
  setData,
}) {
  const gridView = useRef();
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataModal, setDataModal] = useState({});

  const onSend = (msg) => {
    onClose(msg);
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  useEffect(() => {
    if (gridView && gridView.current) {
      gridView.current.setDataSource([]);
    }

    if (data.veiculoMarca) {
      setDataModal({
        flgAtivo: true,
        veiculoMarca: data.veiculoMarca,
        nrSeqVeiculoMarca: data.nrSeqVeiculoMarca,
      });
    } else {
      setDataModal({ flgAtivo: true });
    }
  }, [show]);

  const onSearchVeiculoMarca = async (e) => {
    const {
      status,
      message: msg,
      data: veiculoMarcas,
    } = await getVeiculoMarcaAutoComplete({ noVeiculoMarca: e });
    if (msg) onSetMessage(status, msg);
    return veiculoMarcas;
  };

  const onSearchVeiculoModelo = async (e) => {
    const response = await getVeiculoModeloAutoComplete({
      noVeiculoModelo: e,
      nrSeqVeiculoMarca: data.nrSeqVeiculoMarca,
    });
    return response.data;
  };

  const save = async () => {
    setLoading(true);

    const eixoS = gridView.current ? gridView.current.getDataSource() : [];

    const obj = {
      nrSeqVeiculoModelo: dataModal.nrSeqVeiculoModelo,
      noVeiculoModelo: dataModal.noVeiculoModelo,
      cdVeiculoModelo: dataModal.cdVeiculoModelo,
      nrSeqVeiculoMarca: dataModal.nrSeqVeiculoMarca,
      vlrTara: dataModal.vlrTara,
      flgAtivo: dataModal.flgAtivo,
      veiculoMarca: dataModal.veiculoMarca,
      veiculoModeloEixos: eixoS,
    };

    if (dataModal.noVeiculoModelo && dataModal.nrSeqVeiculoMarca) {
      const { status, message: msg } = await saveVeiculoModelo(obj);

      if (status === ResponseStatus.Success) {
        const veiculoModelos = await onSearchVeiculoModelo(
          dataModal.noVeiculoModelo
        );

        setData({
          ...data,
          veiculoModelo: veiculoModelos[0],
          nrSeqVeiculoModelo: veiculoModelos[0].nrSeqVeiculoModelo,
        });

        onSend();
      } else {
        setMessage({
          message: msg,
          theme: Theme.Danger,
        });
      }
    } else {
      setMessage({
        message: 'Preencha os campos obrigatórios',
        theme: Theme.Danger,
      });
    }

    setLoading(false);
  };

  const onAdicionaEixo = async () => {
    if (dataModal.nrSeqEixo && dataModal.nrOrdem) {
      const obj = new VeiculoModeloEixo({
        nrOrdem: dataModal.nrOrdem,
        nrSeqEixo: dataModal.eixo.nrSeqEixo,
        eixo: dataModal.eixo,
      });

      const list = gridView.current.getDataSource() ?? [];

      list.push(obj);

      if (gridView && gridView.current) gridView.current.setDataSource(list);
    } else {
      onSetMessage(false, 'Selecione o Eixo e preencha a Ordem para adicionar');
    }
  };

  const onClickRemoveEixo = (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);
  };

  const onSearchEixo = async (e) => {
    const { status, message: msg, data: eixoSS } = await getEixoAutoComplete({
      noEixo: e,
    });
    if (msg) onSetMessage(status, msg);
    return eixoSS;
  };

  const columns = [
    { key: 'nrSeqVeiculoModelo', type: GridView.ColumnTypes.Checkbox },
    { key: 'eixo.noEixo', title: 'Eixo', sortable: false },
    { key: 'nrOrdem', title: 'Ordem', sortable: false },
    {
      key: 'nrSeqVeiculoModelo',
      title: 'Remover',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveEixo(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <Modal
      show={show}
      title='Cadastrar Veículo Modelo'
      onClose={() => onSend()}
      size={BootstrapSizes.Large}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col-6'>
            <Textbox
              required
              maxLength={60}
              label='Nome'
              text={dataModal.noVeiculoModelo}
              onChangedValue={(noVeiculoModelo) =>
                setDataModal({ ...dataModal, noVeiculoModelo })
              }
            />
          </div>
          <div className='col-1'>
            <Switch
              formControl
              label='Ativo'
              checked={dataModal.flgAtivo}
              onChange={(flgAtivo) => setDataModal({ ...dataModal, flgAtivo })}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-6 mb-3'>
            <Autocomplete
              required
              label='Marca'
              searchDataSource={onSearchVeiculoMarca}
              selectedItem={dataModal.veiculoMarca}
              onSelectItem={(veiculoMarca) => {
                setDataModal({
                  ...dataModal,
                  veiculoMarca,
                  nrSeqVeiculoMarca: veiculoMarca.nrSeqVeiculoMarca,
                });
              }}
              dataSourceTextProperty='noVeiculoMarca'
            />
          </div>
          <div className='col-3'>
            <Textbox
              required
              label='Valor Tara'
              text={dataModal.vlrTara}
              mask={MaskTypes.Decimal}
              onChangedValue={(vlrTara) =>
                setDataModal({ ...dataModal, vlrTara })
              }
            />
          </div>
        </div>
        <div className='row mb-3'>
          <Panel>
            <Panel.Header title='Eixos' theme={Theme.Primary} />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-5'>
                  <Autocomplete
                    label='Eixo'
                    searchDataSource={onSearchEixo}
                    selectedItem={dataModal.eixo}
                    onSelectItem={(eixo) => {
                      setDataModal({
                        ...dataModal,
                        eixo,
                        nrSeqEixo: eixo.nrSeqEixo,
                      });
                    }}
                    dataSourceTextProperty='noEixo'
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Ordem'
                    text={dataModal.nrOrdem}
                    mask={MaskTypes.Integer}
                    onChangedValue={(nrOrdem) =>
                      setDataModal({ ...dataModal, nrOrdem })
                    }
                  />
                </div>
                <div className='col d-flex align-items-end'>
                  <Button
                    icon='plus'
                    text='Adicionar'
                    size={BootstrapSizes.Medium}
                    theme={Theme.Success}
                    template={Button.Templates.Default}
                    onClick={onAdicionaEixo}
                    outline
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <GridView
                    ref={gridView}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Small}
          icon={['far', 'save']}
          theme={Theme.Success}
          template={Button.Templates.Default}
          onClick={save}
          text='Cadastrar'
        />
      </Modal.Footer>
    </Modal>
  );
}
