import ExtratoCategoria from './extratoCategoria';
import FormaPagamento from './formaPagamento';
import TipoDespesa from './tipoDespesa';
import TituloEspecie from './tituloEspecie';

export default class TipoTituloExtrato {
  constructor(jsonObject = {}) {
    this.nrSeqTipoTituloExtrato = jsonObject.nrSeqTipoTituloExtrato;
    this.flgTituloPagar = jsonObject.flgTituloPagar;
    this.flgTituloReceber = jsonObject.flgTituloReceber;
    this.noTituloExtrato = jsonObject.noTituloExtrato;
    this.flgAcerto = jsonObject.flgAcerto;
    this.nrSeqTipoDespesa = jsonObject.nrSeqTipoDespesa;
    this.nrSeqTituloEspecie = jsonObject.nrSeqTituloEspecie;
    this.flgLiquidaTituloPagar = jsonObject.flgLiquidaTituloPagar;
    this.flgLiquidaTituloReceber = jsonObject.flgLiquidaTituloReceber;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.flgAdiantamento = jsonObject.flgAdiantamento;
    this.nrSeqExtratoCategoriaRec = jsonObject.nrSeqExtratoCategoriaRec;
    this.nrSeqExtratoCategoriaPag = jsonObject.nrSeqExtratoCategoriaPag;
    this.flgAceitaFechado = jsonObject.flgAceitaFechado;
    this.tipoDespesa = new TipoDespesa(jsonObject.tipoDespesa ?? {});
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.tituloEspecie = new TituloEspecie(jsonObject.tituloEspecie ?? {});
    this.extratoCategoriaPag = new ExtratoCategoria(
      jsonObject.extratoCategoriaPag ?? {}
    );
    this.extratoCategoriaRec = new ExtratoCategoria(
      jsonObject.extratoCategoriaRec ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
