import { Empresa } from '../SEG';

export default class TipoEmbarqueEmpresa {
  nrSeqTipoEmbarque: number;

  nrSeqEmpresa: number;

  empresa: Empresa;

  status: string;

  constructor(jsonObject: TipoEmbarqueEmpresa) {
    this.nrSeqTipoEmbarque = jsonObject.nrSeqTipoEmbarque;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.empresa = jsonObject.empresa;
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
