import ContaCorrente from './contaCorrente';

export default class PrevisaoFinanceira {
  constructor(jsonObject = {}) {
    this.nrSeqContaCorrente = jsonObject.nrSeqContaCorrente;
    this.noContaCorrente = jsonObject.noContaCorrente;
    this.data = jsonObject.data;
    this.dataMesAno = jsonObject.dataMesAno;
    this.vlrTituloPagar = jsonObject.vlrTituloPagar;
    this.vlrTituloReceber = jsonObject.vlrTituloReceber;
    this.contaCorrente = new ContaCorrente(jsonObject.contaCorrente ?? {});
    this.dataInicial = jsonObject.dataInicial;
    this.dataFinal = jsonObject.dataFinal;
    this.valor = jsonObject.valor;
    this.saldo = jsonObject.saldo;
  }

  toJson = () => JSON.stringify(this);
}
