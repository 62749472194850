export const TipoChip = {
  Todos: 'O',
  Movel: 'M',
  Fixo: 'F',
  Ramal: 'R',
  LinkNet: 'L',
  TooFree: 'T',
};

export const StatusChip = {
  Todos: 'T',
  Ativo: 'A',
  Inativo: 'I',
  Virtual: 'V',
};

export const SituacaoChip = {
  Todos: 'T',
  EmUso: 'A',
  EmEstoque: 'E',
  Reserva: 'R',
  Cancelado: 'C',
};
