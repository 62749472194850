import Empresa from '../SEG/empresa';
import Vendedor from './vendedor';

export default class VendedorComissao {
  constructor(jsonObject = {}) {
    this.nrSeqPessoaVen = jsonObject.nrSeqPessoaVen;
    this.noPessoaVen = jsonObject.noPessoaVen;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqProdutoGrupo = jsonObject.nrSeqProdutoGrupo;
    this.noProdutoGrupo = jsonObject.noProdutoGrupo;
    this.nrSeqProdutoSubGrupo = jsonObject.nrSeqProdutoSubGrupo;
    this.noProdutoSubGrupo = jsonObject.noProdutoSubGrupo;
    this.percentualInicial = jsonObject.percentualInicial;
    this.percentualFinal = jsonObject.percentualFinal;
    this.percentualComissao = jsonObject.percentualComissao;
    this.nrSeqVendedorComissao = jsonObject.nrSeqVendedorComissao;
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.vendedor = new Vendedor(jsonObject.vendedor ?? {});
  }

  toJson = () => JSON.stringify(this);
}
