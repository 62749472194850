import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  DatePicker,
  Textbox,
  Button,
  GridView,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getLancamentoOcorrencia,
  saveLancamentoOcorrencia,
  deleteLancamentoOcorrencia,
} from 'core/services/FRO/lancamentoOcorrencia';

import {
  getMotorista,
  getMotoristaAutoComplete,
} from 'core/services/FRO/motorista';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import {
  getTipoOcorrencia,
  getTipoOcorrenciaAutoComplete,
} from 'core/services/FRO/tipoOcorrencia';
import {
  uploadDocumento,
  downloadFile,
  excelVeiculoOcorrenciaDocumento,
} from 'core/services/FRO/veiculoOcorrenciaDocumento';

import VeiculoOcorrenciaDocumento from 'core/models/FRO/veiculoOcorrenciaDocumento';

import ModalDocumentoOcorrencia from './modalDocumentoOcorrencia';

export default function LancamentoOcorrenciaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [show, setShow] = useState(false);
  const [documentos, setDocumentos] = useState([]);
  const modalDocumentoRef = useRef({});

  const gridView = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onDownloadExcel = async () => {
    setLoading(true);

    const { value: base64 } = await excelVeiculoOcorrenciaDocumento(data);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'VeiculoOcorrenciaDocumento.csv';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onClickDownload = async (e) => {
    setLoading(true);

    const { value, status, message: msg } = await downloadFile(
      e.nrSeqVeiculoOcorrenciaDocumento
    );
    onSetMessage(status, msg);
    if (value) {
      const linkSource = `data:image/png;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = e.noArquivo;
      downloadLink.click();
    }

    setLoading(false);
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);

      const ocorrencia = await getLancamentoOcorrencia(registryKey);

      setData(ocorrencia);
      setDocumentos(ocorrencia.veiculoOcorrenciaDocumento);
      if (gridView && gridView.current)
        gridView.current.setDataSource(ocorrencia.veiculoOcorrenciaDocumento);

      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  const columns = [
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'nrSeqVeiculoOcorrenciaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },
  ];

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const save = async () => {
    setLoading(true);

    const { status, message: msg, value } = await saveLancamentoOcorrencia(
      data
    );

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();

      const files = modalDocumentoRef.current.param;

      files.forEach(async (file) => {
        await uploadDocumento(file, value);
      });

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      if (gridView && gridView.current) gridView.current.setDataSource([]);
    }

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteLancamentoOcorrencia(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchPessoaMot = async (e) => {
    const { status, message: msg, motoristas } = await getMotoristaAutoComplete(
      {
        noPessoa: e,
      }
    );

    if (msg) onSetMessage(status, msg);
    return motoristas;
  };

  const onSearchVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onSearchTipoOcorrencia = async (e) => {
    const {
      status,
      message: msg,
      tipoOcorrencias,
    } = await getTipoOcorrenciaAutoComplete({ noTipoOcorrencia: e });
    if (msg) onSetMessage(status, msg);
    return tipoOcorrencias;
  };

  const onClean = () => {
    setData({});
    if (gridView && gridView.current) gridView.current.setDataSource([]);
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Lançamento Ocorrência'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onClean({})}
      onSave={save}
      onDelete={data?.nrSeqVeiculoOcorrencia > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      onDownloadExcel={onDownloadExcel}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-6'>
          <div className='row mb-3'>
            <div className='col-auto'>
              <DatePicker
                label='Data da Ocorrência'
                required
                text={data.dtOcorrencia}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtOcorrencia) => setData({ ...data, dtOcorrencia })}
              />
            </div>
            <div className='col-auto'>
              <Textbox
                required
                mask={MaskTypes.Time}
                label='Hora da Ocorrência'
                text={data.hrOcorrencia}
                onChangedValue={(hrOcorrencia) =>
                  setData({ ...data, hrOcorrencia })
                }
              />
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-12'>
              <Autocomplete
                label='Tipo de Ocorrência'
                required
                searchDataSource={onSearchTipoOcorrencia}
                selectedItem={data.tipoOcorrencia}
                onSelectItem={(tipoOcorrencia) => {
                  getTipoOcorrencia(tipoOcorrencia.nrSeqTipoOcorrencia).then(
                    (result) => {
                      setData({
                        ...data,
                        tipoOcorrencia: result,
                        nrSeqTipoOcorrencia: tipoOcorrencia.nrSeqTipoOcorrencia,
                      });
                    }
                  );
                }}
                dataSourceTextProperty='noTipoOcorrencia'
              />
            </div>
          </div>
          {data.tipoOcorrencia?.flgExigePlaca && (
            <div className='row mb-3'>
              <div className='col-12'>
                <Autocomplete
                  label='Veiculo'
                  searchDataSource={onSearchVeiculo}
                  required
                  selectedItem={data.veiculo}
                  onSelectItem={(veiculo) => {
                    setData({
                      ...data,
                      veiculo,
                      nrSeqVeiculo: veiculo.nrSeqVeiculo,
                    });
                  }}
                  dataSourceTextProperty='noVeiculo'
                />
              </div>
            </div>
          )}
        </div>
        <div className='col-6'>
          <div className='card'>
            <div className='card-body'>
              <div className='row mb-4'>
                <div className='col-12'>
                  <Autocomplete
                    label='Motorista'
                    required
                    searchDataSource={onSearchPessoaMot}
                    selectedItem={data.motorista}
                    onSelectItem={(motorista) => {
                      setData({
                        ...data,
                        motorista,
                      });
                      getMotorista(motorista.nrSeqPessoaMot).then((result) => {
                        setData({
                          ...data,
                          motorista: result,
                          nrSeqPessoaMot: motorista.nrSeqPessoaMot,
                        });
                      });
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <div className='col-4'>
                  <Textbox
                    readOnly
                    label='CPF'
                    text={data.motorista?.nrCpf}
                    mask={MaskTypes.CPF}
                  />
                </div>
                <div className='col-4'>
                  <Textbox
                    readOnly
                    label='CNH'
                    text={data.motorista?.nrCarteira}
                  />
                </div>
                <div className='col-4'>
                  <Textbox
                    readOnly
                    label='Vencimento CNH'
                    text={data.motorista?.dtValidadeFormatada}
                    mask={MaskTypes.Date}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={200}
            label='Observação'
            text={data.descricao}
            onChangedValue={(descricao) => setData({ ...data, descricao })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Button
            outline
            text='Upload Documentos'
            size={BootstrapSizes.Small}
            theme={Theme.Success}
            template={Button.Templates.Default}
            onClick={() => setShow(true)}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
          />
        </div>
      </div>
      <ModalDocumentoOcorrencia
        show={show}
        ref={modalDocumentoRef}
        onClose={() => {
          setShow(false);

          const files = modalDocumentoRef.current.param.map(
            (element) =>
              new VeiculoOcorrenciaDocumento({
                noArquivo: element.name,
                flgExibeDownload: false,
                nrSeqVeiculoOcorrenciaDocumento: 0,
              })
          );
          const lista = [];
          documentos.forEach((item) => {
            lista.push(item);
          });

          files.forEach((item) => {
            lista.push(item);
          });

          setDocumentos(lista);
          if (gridView && gridView.current)
            gridView.current.setDataSource(lista);
        }}
      />
    </CSDManutPage>
  );
}
