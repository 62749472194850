import { UploadAtualizar } from 'core/services/FRO/abastecimento';
import { getTipoCombustivelList } from 'core/services/FRO/tipoCombustivel';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import React, { useRef, useState, useEffect } from 'react';

import {
  Autocomplete,
  Button,
  GridView,
  Modal,
  Notification,
  Panel,
} from 'ui/components';
import {
  Theme,
  BootstrapSizes,
  ResponseStatus,
  JustifyContent,
} from 'ui/Helpers/utils';

export default function ModalImportacaoAbastecimento(props) {
  const [messageState, setMessage] = useState(null);
  const [data, setData] = useState({});
  const gridView = useRef();
  const gridView2 = useRef();
  const [loading, setLoading] = useState();

  const { show, importacaoAbastecimento } = props;

  const cancelar = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
    });
  };

  useEffect(() => {
    gridView?.current?.setDataSource(importacaoAbastecimento);
  }, [show]);

  /* const onAdicionarOcorrencia = async () => {
    if (data.noOcorrencia === undefined) {
      onSetMessage(
        ResponseStatus.Error,
        'Adicione qual o motivo da ocorrência'
      );
      return;
    }
    let lista = gridView.current.getDataSource();
    if (!lista) lista = [];
    data.dtCadastro = dateNow();
    const obj = await getValidacaoModalOcorrencia(data);
    data.usuario.noLogin = obj.usuario.noLogin;
    data.nrSeqUsuarioCad = obj.usuario.nrSeqUsuario;
    lista.push(data);
    if (gridView && gridView.current) gridView.current.setDataSource(lista);
    setData(new TituloPagarOcorrencia());
  }; */

  const importacaocerta = [];
  const itenscomerro = [];
  let placas = [];
  let tabelacomerroatualizada = [];

  const onAtualizarItens = async () => {
    setLoading(true);
    const nrSeqVendaDiariaS = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    if (nrSeqVendaDiariaS.length === 0) {
      setMessage(ResponseStatus.Error, 'Selecione um item para atualizar ');
      return;
    }

    const datasource = gridView.current.getDataSource();

    const tabelacerta = gridView2.current.getDataSource();

    importacaocerta.push(...tabelacerta);

    tabelacomerroatualizada = gridView.current.getDataSource();

    for (let i = 0; i < nrSeqVendaDiariaS.length; ) {
      placas = tabelacomerroatualizada.filter(
        (Placa) => Placa.contador !== nrSeqVendaDiariaS[i]
      );

      tabelacomerroatualizada = placas;
      i += 1;
    }

    if (gridView && gridView.current) gridView.current.setDataSource(placas);

    itenscomerro.push(...tabelacomerroatualizada);

    const datasourcefiltrado = datasource.filter((item) =>
      nrSeqVendaDiariaS.includes(item.contador, 0)
    );

    const obj = { ...data, abastecimentoS: datasourcefiltrado };

    const importacaoabastecimento = await UploadAtualizar(obj);

    if (importacaoabastecimento.data.abastecimentoS.length > 0) {
      for (
        let i = 0;
        i < importacaoabastecimento.data.abastecimentoS.length;

      ) {
        if (
          importacaoabastecimento.data.abastecimentoS[i].status === 'Importar'
        ) {
          importacaocerta.push(importacaoabastecimento.data.abastecimentoS[i]);
        } else
          itenscomerro.push(importacaoabastecimento.data.abastecimentoS[i]);

        i += 1;
      }
      if (gridView && gridView.current)
        gridView.current.setDataSource(itenscomerro);

      if (gridView2 && gridView2.current)
        gridView2.current.setDataSource(importacaocerta);
    }
    setLoading(false);
  };

  const onSearchTipoCombustivel = async (e) => {
    const { data: tiposCombustivel } = await getTipoCombustivelList({
      noTipoCombustivel: e,
    });
    return tiposCombustivel;
  };
  const onSearchVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
    });
    if (msg) setMessage(status, msg);
    return veiculos;
  };

  const columns = [
    { key: 'contador', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'noPosto',
      title: 'Posto',
    },
    {
      key: 'dtAbastecimento',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'veiculo.placa',
      title: 'Placa',
      format: GridView.DataTypes.Text,
      colorText: 'noColorVeiculo',
    },
    {
      key: 'noMotorista',
      title: 'Motorista',
      format: GridView.DataTypes.Text,
    },
    {
      key: 'tipoCombustivel.noTipoCombustivel',
      title: 'Tipo Combustível',
      format: GridView.DataTypes.Text,
      colorText: 'noColorTipoCombustivel',
    },
    { key: 'nrDocumento', title: 'Documento', colorText: 'noColorNotaFiscal' },
    { key: 'qtdeLitro', title: 'Litros', format: GridView.DataTypes.Decimal },
    {
      key: 'vlrLitro',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotal',
      title: 'Total',
      format: GridView.DataTypes.Decimal,
    },
  ];
  const columns2 = [
    { key: 'contador', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'noPosto',
      title: 'Posto',
    },
    {
      key: 'dtAbastecimento',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'veiculo.placa',
      title: 'Placa',
      format: GridView.DataTypes.Text,
      colorText: 'noColorVeiculo',
    },
    {
      key: 'noMotorista',
      title: 'Motorista',
      format: GridView.DataTypes.Text,
    },
    {
      key: 'tipoCombustivel.noTipoCombustivel',
      title: 'Tipo Combustível',
      format: GridView.DataTypes.Text,
      colorText: 'noColorTipoCombustivel',
    },
    { key: 'nrDocumento', title: 'Documento', colorText: 'noColorNotaFiscal' },
    { key: 'qtdeLitro', title: 'Litros', format: GridView.DataTypes.Decimal },
    {
      key: 'vlrLitro',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotal',
      title: 'Total',
      format: GridView.DataTypes.Decimal,
    },
  ];

  return (
    <Modal
      show={show}
      title='Importação abastecimento'
      size={BootstrapSizes.Large}
      icon='envelope'
      loading={loading}
    >
      <Modal.Body className='p-4 pt-3'>
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
        <Panel>
          <Panel.Header
            title='Atualização dos itens'
            theme={Theme.Light}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row'>
              <div className='col-4 mb-3'>
                <Autocomplete
                  label='Tipo Combustivel'
                  searchDataSource={onSearchTipoCombustivel}
                  selectedItem={data.tipoCombustivel}
                  onSelectItem={(tipoCombustivel) => {
                    setData({
                      ...data,
                      tipoCombustivel,
                      nrSeqTipoCombustivel:
                        tipoCombustivel.nrSeqTipoCombustivel,
                    });
                  }}
                  dataSourceTextProperty='noTipoCombustivel'
                />
              </div>
              <div className='col-4 mb-3'>
                <Autocomplete
                  label='Placa'
                  searchDataSource={onSearchVeiculo}
                  selectedItem={data.veiculo}
                  onSelectItem={(veiculo) => {
                    setData({
                      ...data,
                      veiculo,
                      nrSeqVeiculo: veiculo.nrSeqVeiculo,
                    });
                  }}
                  dataSourceTextProperty='noVeiculo'
                />
              </div>
              <div className='col-2 mt-3'>
                <Button
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  text='Corrigir Itens'
                  onClick={onAtualizarItens}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
        <div className='col'>
          <GridView
            ref={gridView2}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns2}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>

        <div className='row'>
          <div className='col-2 mt-3'>
            <Button
              theme={Theme.Danger}
              template={Button.Templates.Button}
              text='Sair'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
