import qs from 'qs';
import Pagination from 'core/models/pagination';
import Nfv from 'core/models/VEN/nfv';
import api from '../api';

const baseURL = `/VEN/Nfv`;

export const getNfvList = async (filters) => {
  const response = await api.get(`/$Nfv/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Nfv(json)) ?? [],
  };
};

export const getNfvAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Nfv(json)) ?? [],
  };
};

export const getNfvPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Nfv(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getNfv = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Nfv(value) : {};
};

export const saveNfv = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteNfv = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printNfv = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelNfv = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllNfv = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const postDownload = async (data) => {
  const response = await api.post(`${baseURL}/Download`, data);
  return response.data ?? {};
};

export const postDisable = async (data) => {
  const response = await api.post(`${baseURL}/Disable`, data);
  return response.data ?? {};
};

export const postCancel = async (data) => {
  const response = await api.post(`${baseURL}/Cancel`, data);
  return response.data ?? {};
};

export const postTransmitNFCe = async (data) => {
  const response = await api.post(`${baseURL}/TransmitNFCe`, data);
  return response.data ?? {};
};

export const printNFCe = async (filters) => {
  const response = await api.get(`${baseURL}/PrintNFCe`, {
    params: filters,
  });
  return response.data ?? {};
};

export const postTransmitNFe = async (data) => {
  const response = await api.post(`${baseURL}/TransmitNFe`, data);
  return response.data ?? {};
};

export const postTransmit = async (data) => {
  const response = await api.post(`${baseURL}/Transmit`, data);
  return response.data ?? {};
};

export const printNFe = async (filters) => {
  const response = await api.get(`${baseURL}/PrintNFe`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printNF = async (filters) => {
  const response = await api.get(`${baseURL}/PrintNF`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printNfvForNfvAvulsa = async (filters) => {
  const response = await api.get(`${baseURL}/PrintNFv`, {
    params: filters,
  });
  return response.data ?? {};
};
