import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Textbox, Autocomplete, Switch } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';

import {
  getPocProdOrcamento,
  savePocProdOrcamento,
  deletePocProdOrcamento,
} from 'core/services/POC/pocProdOrcamento';

import { getPocGrupoProdOrcamentoAutoComplete } from 'core/services/POC/pocGrupoProdOrcamento';
import { getProdutoAutoComplete } from 'core/services/EST/produto';
import { getPocProdOrcamentoTipoAutoComplete } from 'core/services/POC/pocProdOrcamentoTipo';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';

export default function PocProdOrcamentoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  findTransaction,
  onOpenTransaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getPocProdOrcamento(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const onSetMessage = (status, messageshow) => {
    if (messageshow)
      setMessage({
        message: messageshow,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await savePocProdOrcamento(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();
    setData(
      status === ResponseStatus.Success
        ? {
            vlrBase: '',
          }
        : data
    );

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onSearchGrupoOrcamento = async (e) => {
    const {
      status,
      message: msg,
      data: grupos,
    } = await getPocGrupoProdOrcamentoAutoComplete({
      cdPocGrupoProdOrcamento: e,
    });
    onSetMessage(status, msg);
    return grupos;
  };
  const onSearchProdutoOrcamentoTipo = async (e) => {
    const {
      status,
      message: msg,
      data: tipos,
    } = await getPocProdOrcamentoTipoAutoComplete({
      noPocProdOrcamentoTipo: e,
    });
    onSetMessage(status, msg);
    return tipos;
  };

  const onSearchProduto = async (e) => {
    const {
      status,
      message: msg,
      data: produtos,
    } = await getProdutoAutoComplete({
      noSearchField: e,
    });
    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deletePocProdOrcamento(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      transaction={transaction}
      isActive={isActive}
      title='Manutenção de Produtos Orçamento'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqPocProdOrcamento > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <div className='row'>
        <div className='col-4 mb-3'>
          <Textbox
            maxLength={200}
            required
            label='Produto'
            text={data.noPocProdOrcamento}
            onChangedValue={(noPocProdOrcamento) =>
              setData({ ...data, noPocProdOrcamento })
            }
          />
        </div>

        <div className='col-2 mb-3'>
          <Textbox
            label='Ordem'
            mask={MaskTypes.Integer}
            text={data.nrOrdem}
            onChangedValue={(nrOrdem) => setData({ ...data, nrOrdem })}
          />
        </div>
        <div className='col-auto'>
          <Switch
            formControl
            label='Inativo'
            checked={data.flgInativo}
            onChange={(flgInativo) =>
              setData({
                ...data,
                flgInativo,
              })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-2 mb-3'>
          <Textbox
            maxLength={3}
            label='Unidade'
            required
            text={data.cdUnidade}
            onChangedValue={(cdUnidade) => setData({ ...data, cdUnidade })}
          />
        </div>
        <div className='col-2 mb-3'>
          <Textbox
            label='Valor Base'
            required
            text={data.vlrBase}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(vlrBase) => setData({ ...data, vlrBase })}
          />
        </div>
        <div className='col-2 mb-3'>
          <Textbox
            label='Diâm. (pol)'
            mask={MaskTypes.Textbox}
            text={data.nrDiametropol}
            onChangedValue={(nrDiametropol) =>
              setData({ ...data, nrDiametropol })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-6 mb-3'>
          <Textbox
            maxLength={200}
            label='Complemento'
            text={data.noProdComplemento}
            onChangedValue={(noProdComplemento) =>
              setData({ ...data, noProdComplemento })
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-6 mb-3'>
          <Autocomplete
            label='Grupo Orçamento'
            searchDataSource={onSearchGrupoOrcamento}
            selectedItem={data.grupoOrcamento}
            onSelectItem={(grupoOrcamento) =>
              setData({
                ...data,
                grupoOrcamento,
                nrSeqPocGrupoProdOrcamento:
                  grupoOrcamento.nrSeqPocGrupoProdOrcamento,
              })
            }
            dataSourceTextProperty='cdPocGrupoProdOrcamento'
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-6 mb-3'>
          <Autocomplete
            label='Tipo'
            searchDataSource={onSearchProdutoOrcamentoTipo}
            selectedItem={data.tipo}
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transactionToOpen={TransacationCodes.TipoProdutoOrcamento}
            nrseqTransactionToOpen='nrSeqPocProdOrcamentoTipo'
            onSelectItem={(tipo) =>
              setData({
                ...data,
                tipo,
                nrSeqPocProdOrcamentoTipo: tipo.nrSeqPocProdOrcamentoTipo,
              })
            }
            dataSourceTextProperty='noPocProdOrcamentoTipo'
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-6'>
          <Autocomplete
            label='Produto'
            searchDataSource={onSearchProduto}
            selectedItem={data?.produto}
            onSelectItem={(produto) => {
              setData({
                ...data,
                produto,
                nrSeqProduto: produto.nrSeqProduto,
              });
            }}
            dataSourceTextProperty='noProduto'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-auto'>
          <Switch
            formControl
            label='Permite Editar'
            checked={data.flgPermiteEditar}
            onChange={(flgPermiteEditar) =>
              setData({
                ...data,
                flgPermiteEditar,
              })
            }
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
