export default class PneuSulcagem {
  constructor(jsonObject = {}) {
    this.nrSeqPneuSulcagem = jsonObject.nrSeqPneuSulcagem;
    this.nrSeqPneu = jsonObject.nrSeqPneu;
    this.nrSulco = jsonObject.nrSulco;
    this.dtRegistro = jsonObject.dtRegistro;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;

    // Instancias
    this.usuarioCad = jsonObject.usuarioCad;
    this.veiculo = jsonObject.veiculo;
  }

  toJson = () => JSON.stringify(this);
}
