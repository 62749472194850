export default class TipoMovimentoRemessa {
  constructor(jsonObject = {}) {
    this.nrSeqTipoMovimentoRemessa = jsonObject.nrSeqTipoMovimentoRemessa;
    this.cdTipoMovimentoRemessa = jsonObject.cdTipoMovimentoRemessa;
    this.noTipoMovimentoRemessa = jsonObject.noTipoMovimentoRemessa;
    this.noFormatoEnvio = jsonObject.noFormatoEnvio;
    this.flgAtivo = jsonObject.flgAtivo;
    this.nrSeqBanco = jsonObject.nrSeqBanco;
  }

  toJson = () => JSON.stringify(this);
}
