export default class TipoReceitaAcerto {
  constructor(jsonObject = {}) {
    this.nrSeqTipoReceitaAcerto = jsonObject.nrSeqTipoReceitaAcerto;
    this.cdTipoReceitaAcerto = jsonObject.cdTipoReceitaAcerto;
    this.noTipoReceitaAcerto = jsonObject.noTipoReceitaAcerto;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqTituloEspecie = jsonObject.nrSeqTituloEspecie;
    this.flgAfetResultado = jsonObject.flgAfetResultado;
    this.flgConhecimentoEletronico = jsonObject.flgConhecimentoEletronico;
    this.flgGeraSequencia = jsonObject.flgGeraSequencia;
    this.nrSeqFormaPagamentoAVista = jsonObject.nrSeqFormaPagamentoAVista;
  }

  toJson = () => JSON.stringify(this);
}
