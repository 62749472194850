import React from 'react';
import {
  Theme,
  ResponseStatus,
  BootstrapSizes,
  MaskTypes,
} from 'ui/Helpers/enums';
import { Textbox, Panel, Autocomplete, RadioButton } from 'ui/components';
import { getBancoAutoComplete } from 'core/services/FIN/banco';

interface Props {
  formData?: any;
  setFormData?: any;
  onSetMessage: (status: ResponseStatus, msg: string) => void;
}

const tipoconta = [
  {
    text: 'Corrente',
    value: 'C',
  },
  {
    text: 'Poupança',
    value: 'P',
  },
];

const tipoPix = [
  {
    text: 'CNPJ',
    value: 'C',
  },
  {
    text: 'CPF',
    value: 'P',
  },
  {
    text: 'Telefone',
    value: 'T',
  },
  {
    text: 'Email',
    value: 'E',
  },
];

const Pix = ({ formData, setFormData }: Props) => {
  if (formData.informacoesBancarias?.tpPix === 'E') {
    return (
      <div className='row'>
        <div className='col-11'>
          {/* @ts-expect-error */}
          <Textbox
            label='Pix'
            id='txtPix'
            text={formData?.informacoesBancarias?.cdPix}
            maxLength={32}
            onChangedValue={(cdPix: any) => {
              setFormData({
                ...formData,
                informacoesBancarias: {
                  ...formData?.informacoesBancarias,
                  cdPix,
                },
              });
            }}
          />
        </div>
      </div>
    );
  }

  let maskPix = MaskTypes.CNPJ;
  let maxLengthPix = 18;

  if (formData.informacoesBancarias?.tpPix === 'P') {
    maskPix = MaskTypes.CPF;
    maxLengthPix = 14;
  }

  if (formData.informacoesBancarias?.tpPix === 'T') {
    maskPix = MaskTypes.CellPhone;
    maxLengthPix = 11;
  }

  return (
    <div className='row'>
      <div className='col-11'>
        {/* @ts-expect-error */}
        <Textbox
          label='Pix'
          id='txtPix'
          text={formData?.informacoesBancarias?.cdPix}
          mask={maskPix}
          maxLength={maxLengthPix}
          onChangedValue={(cdPix: any) => {
            setFormData({
              ...formData,
              informacoesBancarias: {
                ...formData?.informacoesBancarias,
                cdPix,
              },
            });
          }}
        />
      </div>
    </div>
  );
};

const PainelInformacaoBancaria = ({
  formData,
  setFormData,
  onSetMessage,
}: Props) => {
  const onSearchBanco = async (e: string) => {
    const { status, message: msg, bancos } = await getBancoAutoComplete({
      noBanco: e,
    });

    onSetMessage(status, msg);

    return bancos;
  };

  const formatarStringComPadrao = (
    cdDigitado: string,
    codigoMascara: string
  ) => {
    if (codigoMascara && codigoMascara.length > 0) {
      const numerosCdDigitado = cdDigitado.replace(/[^\d]/g, '');
      const numeroDigitosPadrao = (
        codigoMascara.match(/\d/g) ||
        codigoMascara.match(/[a-zA-Z]/g) ||
        []
      ).length;

      if (numeroDigitosPadrao < codigoMascara.length) {
        let cdFormatado = '';
        for (let i = 0; i < numerosCdDigitado.length; i += 1) {
          if (i === codigoMascara.length - 2) {
            cdFormatado += `-${numerosCdDigitado[i]}`;
          } else {
            cdFormatado += numerosCdDigitado[i];
          }
        }

        return cdFormatado;
      }
    }
    return cdDigitado;
  };

  return (
    <div className='row mt-3'>
      {/* @ts-expect-error */}
      <Panel>
        {/* @ts-expect-error */}
        <Panel.Header title='Informações Bancárias' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-3'>
              {/* @ts-expect-error */}
              <RadioButton
                label='Tipo de Conta'
                id='txtTipoConta'
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={formData?.informacoesBancarias?.tpContaCorrente}
                buttons={tipoconta}
                onChange={(tpContaCorrente: any) => {
                  setFormData({
                    ...formData,
                    informacoesBancarias: {
                      ...formData.informacoesBancarias,
                      tpContaCorrente,
                    },
                  });
                }}
              />
            </div>
            <div className='col-4'>
              <Autocomplete
                label='Banco'
                id='txtBanco'
                searchDataSource={onSearchBanco}
                selectedItem={formData?.informacoesBancarias?.banco}
                onSelectItem={(banco: any) => {
                  setFormData({
                    ...formData,
                    informacoesBancarias: {
                      ...formData.informacoesBancarias,
                      banco,
                      nrSeqBanco: banco.nrSeqBanco,
                    },
                  });
                }}
                dataSourceTextProperty='noBanco'
              />
            </div>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <Textbox
                label='Agência'
                id='txtAgencia'
                readOnly={!formData?.informacoesBancarias?.banco}
                maxLength={8}
                text={formData?.informacoesBancarias?.cdAgencia}
                onChangedValue={(cdAgencia: string) =>
                  setFormData({
                    ...formData,
                    informacoesBancarias: {
                      ...formData.informacoesBancarias,
                      cdAgencia: formatarStringComPadrao(
                        cdAgencia,
                        formData?.informacoesBancarias?.banco?.agenciaMascara
                      ),
                    },
                  })
                }
              />
            </div>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <Textbox
                label='Conta'
                id='txtConta'
                maxLength={20}
                readOnly={!formData?.informacoesBancarias?.banco}
                text={formData?.informacoesBancarias?.cdContaCorrente}
                onChangedValue={(cdContaCorrente: string) =>
                  setFormData({
                    ...formData,
                    informacoesBancarias: {
                      ...formData.informacoesBancarias,
                      cdContaCorrente: formatarStringComPadrao(
                        cdContaCorrente,
                        formData?.informacoesBancarias?.banco?.contaMascara
                      ),
                    },
                  })
                }
              />
            </div>
            <div className='col-4 mt-3'>
              {/* @ts-expect-error */}
              <RadioButton
                label='Tipo de Pix'
                id='txtTipoPix'
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={formData?.informacoesBancarias?.tpPix}
                buttons={tipoPix}
                onChange={(tpPix: any) => {
                  setFormData({
                    ...formData,
                    informacoesBancarias: {
                      ...formData?.informacoesBancarias,
                      tpPix,
                    },
                  });
                }}
              />
            </div>
            <div className='col-8 mt-3'>
              {/* @ts-expect-error */}
              <Pix formData={formData} setFormData={setFormData} />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </div>
  );
};

export default PainelInformacaoBancaria;
