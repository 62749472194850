import React, { useState, useEffect } from 'react';
import {
  Modal,
  Switch,
  Autocomplete,
  Textbox,
  Button,
  Notification,
  ToolbarButtons,
} from 'ui/components';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { getAlmoxarifadoAutoComplete } from 'core/services/EST/almoxarifado';
import { getProdutoLinhaAutoComplete } from 'core/services/EST/produtoLinha';
import { getProdutoMarcaAutoComplete } from 'core/services/EST/produtoMarca';
import { getProdutoAutoComplete } from 'core/services/EST/produto';
import { getAlmoxarifadoLocalAutoComplete } from 'core/services/EST/almoxarifadoLocal';
import { getProdutoGrupoAutoComplete } from 'core/services/EST/produtoGrupo';
import { getProdutoOrigemAutoComplete } from 'core/services/EST/produtoOrigem.tsx';
import { getProdutoUnidadeMedidaAutoComplete } from 'core/services/EST/produtoUnidadeMedida';
import { generateCodigoInterno } from 'core/services/EST/produtoCodigo';
import { saveProdutoNf } from 'core/services/COP/nfItem';

export default function ModalEditarNovoProduto(props) {
  const { show, data: produtos, fornecedor } = props;
  const [data, setData] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [readOnlyVincula, setReadOnlyVincula] = useState(false);

  const onSend = (status, msg, novoProduto) => {
    props.onClose(status, msg, novoProduto);
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = async () => {
    if (produtos !== undefined) {
      setData({
        fornecedor,
        vinculaProduto: true,
        cdFornecedor: produtos.noCodigo,
        descFornecedor: produtos.produto.noProduto,
        noProduto: produtos.produto.noProduto,
        ncm: produtos.cdNcm,
        produtoLinha: produtos.produto.produtoAgrupador.produtoLinha,
        produtoMarca: produtos.produto.produtoAgrupador.produtoMarca,
        localArmazenagem: produtos.produto.produtoAgrupador.localArmazenagem,
        almoxarifado: produtos.produto.produtoAgrupador.almoxarifado,
        qtd: produtos.qtdeItNf,
        nrOrdem: produtos.nrOrdem,
        vlrBaseCalcCofins: produtos.vlrBaseCalcCofins,
        vlrBaseCalcIcms: produtos.vlrBaseCalcIcms,
        vlrBaseCalcIpi: produtos.vlrBaseCalcIpi,
        vlrBaseCalcPis: produtos.vlrBaseCalcPis,
        vlrBcST: produtos.vlrBcST,
        vlrCofins: produtos.vlrCofins,
        vlrDesc: produtos.vlrDesc,
        vlrDespesa: produtos.vlrDespesa,
        vlrFrete: produtos.vlrFrete,
        vlrIcms: produtos.vlrIcms,
        vlrIcmsFrete: produtos.vlrIcmsFrete,
        vlrIcmsST: produtos.vlrIcmsST,
        vlrIpi: produtos.vlrIpi,
        vlrPIcmsST: produtos.vlrPIcmsST,
        vlrPis: produtos.vlrPis,
        vlrServico: produtos.vlrServico,
        vlrTotal: produtos.vlrTotal,
        vlrUnit: produtos.vlrUnit,
        corProduto: produtos.corProduto,
      });
      if (data.vinculaProduto === true) {
        setReadOnlyVincula(false);
      }
    }
  };

  const vincularProduto = (obj) => {
    if (obj.vinculaProduto === true) {
      setReadOnlyVincula(false);
    } else {
      setReadOnlyVincula(true);
    }
  };

  const validaProduto = () => {
    if (data.cdFornecedor === undefined) {
      setMessage({
        message: 'Campo Obrigatório: Código Fornecedor.',
        theme: Theme.Danger,
      });
      return false;
    }
    if (data.produtoMarca === undefined) {
      setMessage({
        message: 'Campo obrigatório: Marca.',
        theme: Theme.Danger,
      });
      return false;
    }

    if (data.vinculaProduto) {
      if (
        data.produtoAgrupador === undefined &&
        data.produtoAgrupador === null
      ) {
        setMessage({
          message: 'Campo obrigatório: Agrupador.',
          theme: Theme.Danger,
        });
        return false;
      }
    }
    if (!data.vinculaProduto) {
      if (data.ncm === undefined) {
        setMessage({
          message: 'Campo obrigatório: NCM.',
          theme: Theme.Danger,
        });
        return false;
      }

      if (data.produtoMarca === undefined) {
        setMessage({
          message: 'Campo obrigatório: Marca.',
          theme: Theme.Danger,
        });
        return false;
      }

      if (data.produtoLinha === undefined) {
        setMessage({
          message: 'Campo obrigatório: Linha.',
          theme: Theme.Danger,
        });
        return false;
      }

      if (data.almoxarifado === undefined) {
        setMessage({
          message: 'Campo obrigatório: Almoxarifado.',
          theme: Theme.Danger,
        });
        return false;
      }

      if (data.localArmazenagem === undefined) {
        setMessage({
          message: 'Campo obrigatório: Local Armazenagem.',
          theme: Theme.Danger,
        });
        return false;
      }

      if (
        data.produtoGrupo?.nrSeqProdutoGrupo === undefined &&
        !data.vinculaProduto
      ) {
        setMessage({
          message: 'Campo obrigatório: Grupo',
          theme: Theme.Danger,
        });
        return false;
      }

      if (
        data.produtoOrigem?.nrSeqProdutoOrigem === undefined &&
        !data.vinculaProduto
      ) {
        setMessage({
          message: 'Campo obrigatório: Origem.',
          theme: Theme.Danger,
        });
        return false;
      }

      if (
        data.produtoUnidadeMedida?.nrSeqProdutoUnidadeMedida === undefined &&
        !data.vinculaProduto
      ) {
        setMessage({
          message: 'Campo obrigatório: Unidade Medida',
          theme: Theme.Danger,
        });
        return false;
      }
    }

    return true;
  };

  const save = async () => {
    if (validaProduto()) {
      const { status, message: msg, data: novoProduto } = await saveProdutoNf(
        data
      );

      if (status === ResponseStatus.Success) {
        onSend(status, msg, novoProduto);
      } else {
        setMessage({
          message: msg,
          theme: Theme.Danger,
        });
      }
    }
  };

  useEffect(() => {
    load();
  }, [show]);

  const onGerarCodigoInterno = async () => {
    setLoading(true);

    const cdInterno = await generateCodigoInterno(data);

    setData({ ...data, cdInterno });

    setLoading(false);
  };

  const onSearchAlmoxarifado = async (e) => {
    const {
      data: almoxarifados,
      status,
      message: msg,
    } = await getAlmoxarifadoAutoComplete({
      noAlmoxarifado: e,
    });

    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return almoxarifados;
  };

  const onSearchProduto = async (e) => {
    const {
      status,
      message: msg,
      data: produto,
    } = await getProdutoAutoComplete({
      noSearchField: e,
    });

    if (msg) onSetMessage(status, msg);
    return produto;
  };

  const onSearchProdutoLinha = async (e) => {
    const {
      data: produtosLinha,
      status,
      message: msg,
    } = await getProdutoLinhaAutoComplete({
      noProdutoLinha: e,
    });

    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return produtosLinha;
  };

  const onSearchProdutoMarca = async (e) => {
    const {
      data: produtosMarca,
      status,
      message: msg,
    } = await getProdutoMarcaAutoComplete({
      noProdutoMarca: e,
    });
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    return produtosMarca;
  };

  const onAutoCompleteLocalArmazenagem = async () => {
    if (!data.nrSeqAlmoxarifado && !data.vinculaProduto)
      setMessage({
        message: 'Campo Obrigatório: Almoxarifado.',
        theme: Theme.Danger,
      });

    const {
      almoxarifados,
      status,
      message: msg,
    } = await getAlmoxarifadoLocalAutoComplete({
      nrSeqAlmoxarifado: data.nrSeqAlmoxarifado,
      flgAtivo: true,
      FlgNotItem: true,
    });

    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return almoxarifados;
  };

  const onSearchGrupoProduto = async (e) => {
    const {
      data: grupos,
      status,
      message: msg,
    } = await getProdutoGrupoAutoComplete({
      noProdutoGrupo: e,
    });

    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return grupos;
  };

  const onSearchOrigemProduto = async (e) => {
    const {
      data: origens,
      status,
      message: msg,
    } = await getProdutoOrigemAutoComplete({
      noProdutoOrigem: e,
    });

    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return origens;
  };

  const onSearchUniMedProduto = async (e) => {
    const {
      data: unidadesMedida,
      status,
      message: msg,
    } = await getProdutoUnidadeMedidaAutoComplete({
      noProdutoUnidadeMedida: e,
    });

    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return unidadesMedida;
  };

  return (
    <Modal
      show={show}
      title='Cadastro Rápido de Produto'
      loading={loading}
      onClose={() => onSend()}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-5'>
          <div className='row mb-3'>
            <div className='col-4 mt-1'>
              <span>Deseja vincular o código a um produto já existente?</span>
            </div>
            <div className='col'>
              <Switch
                formControl
                checked={data.vinculaProduto}
                onChange={(vinculaProduto) => {
                  const obj = {
                    ...data,
                    vinculaProduto,
                  };
                  setData(obj);
                  vincularProduto(obj);
                }}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-5'>
              <Autocomplete
                label='Filtrar Agrupador Por Almoxarifado'
                searchDataSource={onSearchAlmoxarifado}
                visible={readOnlyVincula}
                selectedItem={data.filtroAlmoxarifado}
                onSelectItem={(filtroAlmoxarifado) =>
                  setData({ ...data, filtroAlmoxarifado })
                }
                dataSourceTextProperty='noAlmoxarifado'
              />
            </div>
          </div>
          <div className='row mb-3'>
            {data.vinculaProduto && (
              <div className='col-12'>
                <Autocomplete
                  label='Produto'
                  searchDataSource={onSearchProduto}
                  selectedItem={data.produto}
                  onSelectItem={(produto) => {
                    setData({
                      ...data,
                      produto,
                      nrSeqProduto: produto.nrSeqProduto,
                    });
                  }}
                  dataSourceTextProperty='noProduto'
                />
              </div>
            )}
          </div>
          <div className='row mb-3'>
            <div className='col-3'>
              <Textbox
                label='Código Fornecedor'
                text={data.cdFornecedor}
                visible={readOnlyVincula}
                onChangedValue={(cdFornecedor) =>
                  setData({ ...data, cdFornecedor })
                }
              />
            </div>
            <div className='col-5'>
              <Textbox
                label='Descrição Fornecedor'
                visible={readOnlyVincula}
                text={data.descFornecedor}
                onChangedValue={(descFornecedor) =>
                  setData({ ...data, descFornecedor })
                }
              />
            </div>
            <div className='col-1'>
              <ToolbarButtons>
                <ToolbarButtons.Button
                  text='Gerar Código Interno'
                  visible={readOnlyVincula}
                  icon='plus'
                  onClick={() => onGerarCodigoInterno()}
                />
              </ToolbarButtons>
            </div>
            <div className='col-3'>
              <Textbox
                label='Código Interno'
                visible={readOnlyVincula}
                text={data.cdInterno}
                onChangedValue={(cdInterno) => setData({ ...data, cdInterno })}
              />
            </div>
          </div>
          {!data.vinculaProduto && (
            <div className='row mb-3'>
              <div className='col'>
                <Textbox
                  label='Nome do Produto'
                  text={data.noProduto}
                  onChangedValue={(noProduto) =>
                    setData({ ...data, noProduto })
                  }
                />
              </div>
            </div>
          )}
          {!data.vinculaProduto && (
            <div className='row mb-3'>
              <div className='col-4'>
                <Autocomplete
                  label='Grupo'
                  searchDataSource={onSearchGrupoProduto}
                  required
                  selectedItem={data.produtoGrupo}
                  onSelectItem={(produtoGrupo) =>
                    setData({
                      ...data,
                      produtoGrupo,
                      nrSeqProdutoGrupo: produtoGrupo.nrSeqProdutoGrupo,
                    })
                  }
                  dataSourceTextProperty='noProdutoGrupo'
                />
              </div>
              <div className='col-3'>
                <Autocomplete
                  label='Origem'
                  searchDataSource={onSearchOrigemProduto}
                  required
                  selectedItem={data.produtoOrigem}
                  onSelectItem={(produtoOrigem) =>
                    setData({
                      ...data,
                      produtoOrigem,
                      nrSeqProdutoOrigem: produtoOrigem.nrSeqProdutoOrigem,
                    })
                  }
                  dataSourceTextProperty='noProdutoOrigem'
                />
              </div>
              <div className='col-5'>
                <Autocomplete
                  label='Unidade Medida'
                  searchDataSource={onSearchUniMedProduto}
                  required
                  selectedItem={data.produtoUnidadeMedida}
                  onSelectItem={(produtoUnidadeMedida) =>
                    setData({
                      ...data,
                      produtoUnidadeMedida,
                      nrSeqProdutoUnidadeMedida:
                        produtoUnidadeMedida.nrSeqProdutoUnidadeMedida,
                    })
                  }
                  dataSourceTextProperty='noProdutoUnidadeMedida'
                />
              </div>
            </div>
          )}
          <div className='row mb-3'>
            <div className='col-4'>
              <Textbox
                label='NCM'
                visible={readOnlyVincula}
                text={data.ncm}
                onChangedValue={(ncm) => setData({ ...data, ncm })}
              />
            </div>
            <div className='col-4'>
              <Autocomplete
                label='Linha'
                searchDataSource={onSearchProdutoLinha}
                visible={readOnlyVincula}
                required
                selectedItem={data.produtoLinha}
                onSelectItem={(produtoLinha) =>
                  setData({
                    ...data,
                    produtoLinha,
                    nrSeqProdutoLinha: produtoLinha.nrSeqProdutoLinha,
                  })
                }
                dataSourceTextProperty='noProdutoLinha'
              />
            </div>
            <div className='col-4'>
              <Autocomplete
                label='Marca'
                searchDataSource={onSearchProdutoMarca}
                required
                visible={readOnlyVincula}
                selectedItem={data.produtoMarca}
                onSelectItem={(produtoMarca) =>
                  setData({
                    ...data,
                    produtoMarca,
                    nrSeqProdutoMarca: produtoMarca.nrSeqProdutoMarca,
                  })
                }
                dataSourceTextProperty='noProdutoMarca'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-5'>
              <Autocomplete
                label='Almoxarifado'
                searchDataSource={onSearchAlmoxarifado}
                selectedItem={data.almoxarifado}
                visible={readOnlyVincula}
                required
                onSelectItem={(almoxarifado) =>
                  setData({
                    ...data,
                    almoxarifado,
                    nrSeqAlmoxarifado: almoxarifado.nrSeqAlmoxarifado,
                  })
                }
                dataSourceTextProperty='noAlmoxarifado'
              />
            </div>
            <div className='col-7'>
              <Autocomplete
                label='Local Armazenagem'
                searchDataSource={onAutoCompleteLocalArmazenagem}
                required
                visible={readOnlyVincula}
                selectedItem={data.localArmazenagem}
                onSelectItem={(localArmazenagem) =>
                  setData({
                    ...data,
                    localArmazenagem,
                    nrSeqAlmoxarifadoLocal:
                      localArmazenagem.nrSeqAlmoxarifadoLocal,
                  })
                }
                dataSourceTextProperty='noAlmoxarifadoLocal'
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Small}
          icon={['far', 'save']}
          theme={Theme.Success}
          template={Button.Templates.Default}
          onClick={() => save()}
          text='Salvar'
        />
        <Button
          size={BootstrapSizes.Small}
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          onClick={() => onSend()}
          text='Sair'
        />
      </Modal.Footer>
    </Modal>
  );
}
