import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Switch, RadioButton, Textbox } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { Theme } from 'ui/Helpers/utils';

const Ordenar = [
  { text: 'Ordem Núm. Celular', value: 'OrdemNumCelular' },
  { text: 'Ordem Núm. Cliente', value: 'OrdemNumCliente' },
  { text: 'Ordem Plano', value: 'OrdemPlano' },
];

const GerarArquivo = [
  { text: 'Único', value: 'N' },
  { text: 'Individual', value: 'S' },
];

const FormatoNomeArquivoPDF = [
  { text: '[NUMEROCELULAR].PDF', value: 'NUMEROCELULARPDF' },
  { text: '[NOMECLIENTE_NUMERO].PDF', value: 'NOMECLIENTENUMEROPDF' },
  {
    text: '[MES_ANO_NOMECLIENTE_NUMERO].PDF',
    value: 'MESANONOMECLIENTENUMEROPDF',
  },
];

const PanelGerarContasPDF = forwardRef(
  ({ props, exibirPaginaBranca }, param) => {
    const [parametros, setParametros] = useState({});
    useImperativeHandle(param, () => ({
      param: parametros,
    }));

    useEffect(() => {
      setParametros({
        ...parametros,
        flg7geracontaspdf: props.flg7geracontaspdf,
        flg7gerporcentrodecusto: props.flg7gerporcentrodecusto,
        flg7gercontamaiordoquezero: props.flg7gercontamaiordoquezero,
        flg7buscarlinhassememailcadastrado:
          props.flg7buscarlinhassememailcadastrado,
        flg7paginabranca: props.flg7paginabranca,
        flg7Ordem: props.flg7Ordem,
        nr7quantregistros: props.nr7quantregistros,
        flg7individual: props.flg7individual,
        flg7FormatoArquivo: props.flg7FormatoArquivo,
      });
    }, [props]);

    return (
      <div>
        <div className='row mb-3'>
          <div className='col-2'>
            <Switch
              formControl
              label='Gerar'
              checked={parametros.flg7geracontaspdf}
              onChange={(e) =>
                setParametros({ ...parametros, flg7geracontaspdf: e })
              }
            />
          </div>
          <div className='col-auto'>
            <Switch
              formControl
              label='Por Centro de Custo'
              checked={parametros.flg7gerporcentrodecusto}
              onChange={(e) =>
                setParametros({ ...parametros, flg7gerporcentrodecusto: e })
              }
            />
          </div>
          <div className='col-auto'>
            <Switch
              formControl
              label='Apenas contas maior do que zero'
              checked={parametros.flg7gercontamaiordoquezero}
              onChange={(e) =>
                setParametros({ ...parametros, flg7gercontamaiordoquezero: e })
              }
            />
          </div>
          <div className='col-auto'>
            <Switch
              formControl
              label='Buscar somente linhas sem e-mail cadastrado'
              checked={parametros.flg7buscarlinhassememailcadastrado}
              onChange={(e) =>
                setParametros({
                  ...parametros,
                  flg7buscarlinhassememailcadastrado: e,
                })
              }
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-6'>
            <RadioButton
              outline
              label='Ordenado por:'
              theme={Theme.Primary}
              buttons={Ordenar}
              selectedButton={parametros.flg7Ordem}
              onChange={(flg7Ordem) =>
                setParametros({
                  ...parametros,
                  flg7Ordem,
                })
              }
            />
          </div>
          <div className='col-2'>
            <Textbox
              label='Quantidade Registros'
              text={parametros.nr7quantregistros}
              mask={MaskTypes.Inteiro}
            />
          </div>
          {exibirPaginaBranca && (
            <div className='col-2'>
              <Switch
                formControl
                label='Pagina em Branco'
                tooltip='Importar Arquivo'
                checked={parametros.flg7paginabranca}
                onChange={(e) =>
                  setParametros({ ...parametros, flg7paginabranca: e })
                }
              />
            </div>
          )}
        </div>
        <div className='row mb-3'>
          <div className='row mb-3'>
            <div className='col-4'>
              <span> Gerar Arquivo</span>
            </div>
            <div className='card'>
              <div className='card-body'>
                <div className='row mb-3'>
                  <div className='col-3'>
                    <RadioButton
                      outline
                      theme={Theme.Primary}
                      buttons={GerarArquivo}
                      selectedButton={parametros.flg7individual}
                      onChange={(flg7individual) =>
                        setParametros({
                          ...parametros,
                          flg7individual,
                        })
                      }
                    />
                  </div>
                </div>

                <div className='row mb-3'>
                  <div className='col-auto'>
                    <RadioButton
                      outline
                      label='Formato Nome Arquivo.PDF'
                      theme={Theme.Primary}
                      buttons={FormatoNomeArquivoPDF}
                      selectedButton={parametros.flg7FormatoArquivo}
                      onChange={(flg7FormatoArquivo) =>
                        setParametros({
                          ...parametros,
                          flg7FormatoArquivo,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default PanelGerarContasPDF;
