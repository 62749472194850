import qs from 'qs';
import Pagination from 'core/models/pagination';
import TituloPagar from 'core/models/FIN/tituloPagar';
import api from '../api';

const baseURL = `/FIN/TituloPagar`;

export const AgrupamentoTituloPagar = async (data) => {
  const response = await api.post(`${baseURL}/AgrupamentoTituloPagar`, data);
  return response.data ?? {};
};
export const AlteracaoVencimentoTituloPagar = async (data) => {
  const response = await api.post(
    `${baseURL}/AlteracaoVencimentoTituloPagar`,
    data
  );
  return response.data ?? {};
};

export const AlteracaoFormaPagamentoTituloPagar = async (data) => {
  const response = await api.post(
    `${baseURL}/AlteracaoFormaPagamentoTituloPagar`,
    data
  );
  return response.data ?? {};
};
export const DesagrupamentoTituloPagar = async (data) => {
  const response = await api.post(`${baseURL}/DesagrupamentoTituloPagar`, data);
  return response.data ?? {};
};

export const getTituloPagarList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloPagar(json)) ?? [],
  };
};
export const SearchTituloPagamentoMassivo = async (data) => {
  const response = await api.post(
    `${baseURL}/SearchTituloPagamentoMassivo`,
    data
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloPagar(json)) ?? [],
  };
};
export const SearchTituloVencimentoMassivo = async (data) => {
  const response = await api.post(
    `${baseURL}/SearchTituloVencimentoMassivo`,
    data
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloPagar(json)) ?? [],
  };
};
export const SearchTituloAgrupamentoFinanceiro = async (data) => {
  const response = await api.post(
    `${baseURL}/SearchTituloAgrupamentoFinanceiro`,
    data
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloPagar(json)) ?? [],
  };
};

export const getTituloPagarPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloPagar(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getTituloPagarPlanoDeContaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/TituloPagarPlanoDeContaPagined`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloPagar(json)) ?? [],
  };
};
export const getValidacaoModalOcorrencia = async (filters) => {
  const response = await api.get(`${baseURL}/GetValidacaoModalOcorrencia`, {
    params: filters,
  });
  const { value } = response.data ?? {};

  return value ? new TituloPagar(value) : {};
};

export const getRetornoCodigoBarras = async (data) => {
  const response = await api.post(`${baseURL}/getRetornoCodigoBarra`, data);
  return response.data.value ?? {};
};
export const getRetornoTituloPagarVinculado = async (data) => {
  const response = await api.post(
    `${baseURL}/getRetornoTituloPagarVinculado`,
    data
  );
  return response.data.value ?? {};
};

export const getMultipag = async (data) => {
  const response = await api.post(`${baseURL}/multipag`, data);
  return response.data.value ?? {};
};

export const getTituloPagar = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new TituloPagar(value) : {};
};
export const getUsuario = async (id) => {
  const response = await api.get(`${baseURL}/GetUsuario`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new TituloPagar(value) : {};
};

export const saveTituloPagar = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};
export const RecalculaValorVeiculos = async (data) => {
  const response = await api.post(`${baseURL}/RecalcularVeiculos`, data);
  return response.data ?? {};
};
export const RatearPlanoDeContaAutomatico = async (data) => {
  const response = await api.post(
    `${baseURL}/RatearPlanoDeContaAutomatico`,
    data
  );
  return response.data ?? {};
};

export const deleteTituloPagar = async (id, flgConfirmaExclusao) => {
  const response = await api.delete(`${baseURL}/`, {
    params: { id, flgConfirmaExclusao },
  });
  return response.data ?? {};
};

export const printTituloPagar = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const printTituloPagarFornecedor = async (filters) => {
  const response = await api.get(`${baseURL}/PrintFornecedor`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const printTituloPagarVencimento = async (filters) => {
  const response = await api.get(`${baseURL}/Vencimento`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const printTituloPagarVencimentoSemObs = async (filters) => {
  const response = await api.get(`${baseURL}/VencimentoSemObs`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const printTituloPagarTipoDespesa = async (filters) => {
  const response = await api.get(`${baseURL}/TipoDespesa`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const printTituloPagarTipoDespesaTotais = async (filters) => {
  const response = await api.get(`${baseURL}/TipoDespesaTotais`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const printTituloPagarTipoDespesaDetalhado = async (filters) => {
  const response = await api.get(`${baseURL}/TipoDespesaDetalhado`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const onPrintDataContabil = async (filters) => {
  const response = await api.get(`${baseURL}/Contabil`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const onPrintPlanoDeConta = async (filters) => {
  const response = await api.get(`${baseURL}/PlanoConta`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const printTituloPagarVencimentoCheque = async (filters) => {
  const response = await api.get(`${baseURL}/VencimentoCheque`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const printTituloPagarFornecedorAgrupado = async (filters) => {
  const response = await api.get(`${baseURL}/FornecedorAgrupado`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const printTituloPagarFornecedorDetalhado = async (filters) => {
  const response = await api.get(`${baseURL}/FornecedorDetalhado`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const printTituloPagarFornecedorCheque = async (filters) => {
  const response = await api.get(`${baseURL}/FornecedorCheque`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const excelTituloPagar = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const excelContabilTituloPagar = async (filters) => {
  const response = await api.get(`${baseURL}/ContabilExcel`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const excelPlanoContaTituloPagar = async (filters) => {
  const response = await api.get(`${baseURL}/PlanoContaExcel`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const ExcelTituloPagarFornecedor = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelFornecedor`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const ExcelTituloPagarVencimento = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelVencimento`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const ExcelTituloPagarVencimentoSemObs = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelVencimentoSemObs`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const ExcelTituloPagarTipoDespesa = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelTipoDespesa`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const ExcelTituloPagarVencimentoCheque = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelVencimentoCheque`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const ExcelTituloPagarFornecedorAgrupado = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelFornecedorAgrupado`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const ExcelTituloPagarFornecedorDetalhado = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelFornecedorDetalhado`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const ExcelTituloPagarFornecedorCheque = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelFornecedorCheque`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const deleteAllTituloPagar = async (ids, flgConfirmaExclusao) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids, flgConfirmaExclusao },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getCartaFreteInfo = async (id) => {
  const response = await api.get(`${baseURL}/BuscarCartaFrete`, {
    params: { id },
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloPagar(json)) ?? [],
  };
};
