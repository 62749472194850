export default class EnergiaDistribuidora {
  nrSeqEnergiaDistribuidora: number;

  noEnergiaDistribuidora: string;

  cdEnergiaDistribuidora: string;

  constructor(jsonObject: EnergiaDistribuidora) {
    this.nrSeqEnergiaDistribuidora = jsonObject.nrSeqEnergiaDistribuidora;
    this.noEnergiaDistribuidora = jsonObject.noEnergiaDistribuidora;
    this.cdEnergiaDistribuidora = jsonObject.cdEnergiaDistribuidora;
  }

  toJson = () => JSON.stringify(this);
}
