import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  JustifyContent,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  Switch,
  RadioButton,
  Panel,
} from 'ui/components';
import {
  getTipoNfvAvulsa,
  saveTipoNfvAvulsa,
  deleteTipoNfvAvulsa,
} from 'core/services/VEN/tipoNfvAvulsa';

import { getGrupoCofinsList } from 'core/services/FIS/grupoCofins';
import { getGrupoIPIList } from 'core/services/FIS/grupoIPI';
import { getGrupoIcmsList } from 'core/services/FIS/grupoIcms';
import { getGrupoPISList } from 'core/services/FIS/grupoPIS';
import { getGrupoIssqnList } from 'core/services/FIS/grupoIssqn';
import { getCfoAutoComplete } from 'core/services/FIS/cfo';
import { getEstoqueTipoAutoComplete } from 'core/services/EST/estoqueTipo';

export const BootstrapSizes = {
  ExtraSmall: 'xs',
  Small: 'sm',
  Medium: 'md',
  Large: 'lg',
  ExtraLarge: 'xl',
};

export default function TipoNfvAvulsaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [visible, setVisible] = useState(true);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSetMureg = (estoque) => {
    if (estoque) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  };

  const statusFinalidade = [
    { text: '1- NFe Normal', value: '1' },
    { text: '2- NFe Complementar', value: '2' },
    { text: '3- NFe de Ajuste', value: '3' },
  ];

  const statusEntrada = [
    { text: 'Entrada', value: true },
    { text: 'Saída', value: false },
  ];
  /*
  const statusEntrada = () => {
    return data.flgEntrada ? 'Entrada' : 'Saída';
  }
*/
  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getTipoNfvAvulsa(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveTipoNfvAvulsa(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteTipoNfvAvulsa(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchGrupoCoFins = async (e) => {
    const {
      status,
      message: msg,
      data: grupoCoFinss,
    } = await getGrupoCofinsList({
      noGrupoCoFins: e,
    });
    if (msg) onSetMessage(status, msg);
    return grupoCoFinss;
  };

  const onSearchGrupoIcms = async (e) => {
    const { status, message: msg, data: grupoIcmss } = await getGrupoIcmsList({
      noGrupoIcms: e,
    });
    if (msg) onSetMessage(status, msg);
    return grupoIcmss;
  };

  const onSearchGrupoIpi = async (e) => {
    const { status, message: msg, data: grupoIpis } = await getGrupoIPIList({
      noGrupoIpi: e,
    });
    if (msg) onSetMessage(status, msg);
    return grupoIpis;
  };

  const onSearchGrupoIssQn = async (e) => {
    const { status, message: msg, data: grupoIssQns } = await getGrupoIssqnList(
      {
        noGrupoIssQn: e,
      }
    );
    if (msg) onSetMessage(status, msg);
    return grupoIssQns;
  };

  const onSearchCfo = async (e) => {
    const { status, message: msg, cfos: cfo } = await getCfoAutoComplete({
      noCfo: e,
    });
    if (msg) onSetMessage(status, msg);
    return cfo;
  };

  const onSearchGrupoPis = async (e) => {
    const { status, message: msg, data: grupoPiss } = await getGrupoPISList({
      noGrupoPis: e,
    });
    if (msg) onSetMessage(status, msg);
    return grupoPiss;
  };

  const onSearchEstoqueTipo = async (e) => {
    const {
      status,
      message: msg,
      data: estoqueTipos,
    } = await getEstoqueTipoAutoComplete({
      noEstoqueTipo: e,
    });
    if (msg) onSetMessage(status, msg);
    return estoqueTipos;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Tipo de Nota Fiscal Avulsa'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqTipoNfvAvulsa > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            readOnly
            maxLength={10}
            label='Código'
            text={data.cdTipoNfvAvulsa}
            onChangedValue={(cdTipoNfvAvulsa) =>
              setData({ ...data, cdTipoNfvAvulsa })
            }
          />
        </div>
        <div className='col mb-3'>
          <Textbox
            maxLength={3}
            label='Série Nfv'
            text={data.serieNfv}
            onChangedValue={(serieNfv) => setData({ ...data, serieNfv })}
          />
        </div>
        <div className='col mb-3'>
          <Textbox
            maxLength={60}
            label='Descrição'
            text={data.noTipoNfvAvulsa}
            onChangedValue={(noTipoNfvAvulsa) =>
              setData({ ...data, noTipoNfvAvulsa })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col mb-12'>
          <Panel>
            <Panel.Header
              title='Cfo no Estado'
              icon={['fa', 'briefcase']}
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col mb-3'>
                  <Autocomplete
                    label='Cfo no Estado'
                    searchDataSource={onSearchCfo}
                    selectedItem={data.cfoEst}
                    onSelectItem={(cfoEst) => {
                      setData({
                        ...data,
                        cfoEst,
                        nrSeqCfoEst: cfoEst.nrSeqCfo,
                      });
                    }}
                    dataSourceTextProperty='noCfo'
                  />
                </div>
                <div className='col mb-3'>
                  <Autocomplete
                    label='Cfo no Estado Inverso'
                    searchDataSource={onSearchCfo}
                    selectedItem={data.cfoInv}
                    onSelectItem={(cfoInv) => {
                      setData({
                        ...data,
                        cfoInv,
                        nrSeqCfoEstInverso: cfoInv.nrSeqCfo,
                      });
                    }}
                    dataSourceTextProperty='noCfo'
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col mb-12'>
          <Panel>
            <Panel.Header
              title='Cfo Fora do Estado (País)'
              icon={['fa', 'briefcase']}
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col mb-3'>
                  <Autocomplete
                    label='Cfo Fora do Estado (País)'
                    searchDataSource={onSearchCfo}
                    selectedItem={data.cfoPais}
                    onSelectItem={(cfoPais) => {
                      setData({
                        ...data,
                        cfoPais,
                        nrSeqCfoPais: cfoPais.nrSeqCfo,
                      });
                    }}
                    dataSourceTextProperty='noCfo'
                  />
                </div>
                <div className='col mb-3'>
                  <Autocomplete
                    label='Cfo Fora do Estado Inverso (País)'
                    searchDataSource={onSearchCfo}
                    selectedItem={data.cfoPaisInv}
                    onSelectItem={(cfoPaisInv) => {
                      setData({
                        ...data,
                        cfoPaisInv,
                        nrSeqCfoPaisInverso: cfoPaisInv.nrSeqCfo,
                      });
                    }}
                    dataSourceTextProperty='noCfo'
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col mb-12'>
          <Panel>
            <Panel.Header
              title='Cfo Fora do País'
              icon={['fa', 'briefcase']}
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col mb-3'>
                  <Autocomplete
                    label='Cfo Fora do País'
                    searchDataSource={onSearchCfo}
                    selectedItem={data.cfoExt}
                    onSelectItem={(cfoExt) => {
                      setData({
                        ...data,
                        cfoExt,
                        nrSeqCfoExt: cfoExt.nrSeqCfo,
                      });
                    }}
                    dataSourceTextProperty='noCfo'
                  />
                </div>
                <div className='col mb-3'>
                  <Autocomplete
                    label='Cfo Fora do País Inverso'
                    searchDataSource={onSearchCfo}
                    selectedItem={data.cfoExtInv}
                    onSelectItem={(cfoExtInv) => {
                      setData({
                        ...data,
                        cfoExtInv,
                        nrSeqCfoExtInverso: cfoExtInv.nrSeqCfo,
                      });
                    }}
                    dataSourceTextProperty='noCfo'
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col mb-12'>
          <Panel>
            <Panel.Header
              title='Exceção'
              icon={['fa', 'briefcase']}
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col mb-3'>
                  <Autocomplete
                    label='Grupo COFINS'
                    searchDataSource={onSearchGrupoCoFins}
                    selectedItem={data.grupoCofins}
                    onSelectItem={(grupoCofins) => {
                      setData({
                        ...data,
                        grupoCofins,
                        nrSeqGrupoCofins: grupoCofins.nrSeqGrupoCofins,
                      });
                    }}
                    dataSourceTextProperty='noGrupoCofins'
                  />
                </div>
                <div className='col mb-3'>
                  <Autocomplete
                    label='Grupo ICMS'
                    searchDataSource={onSearchGrupoIcms}
                    selectedItem={data.grupoIcms}
                    onSelectItem={(grupoIcms) => {
                      setData({
                        ...data,
                        grupoIcms,
                        nrSeqGrupoIcms: grupoIcms.nrSeqGrupoIcms,
                      });
                    }}
                    dataSourceTextProperty='noGrupoIcms'
                  />
                </div>
                <div className='col mb-3'>
                  <Autocomplete
                    label='Grupo IPI'
                    searchDataSource={onSearchGrupoIpi}
                    selectedItem={data.grupoIPI}
                    onSelectItem={(grupoIPI) => {
                      setData({
                        ...data,
                        grupoIPI,
                        nrSeqGrupoIPI: grupoIPI.nrSeqGrupoIPI,
                      });
                    }}
                    dataSourceTextProperty='noGrupoIPI'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col mb-3'>
                  <Autocomplete
                    label='Grupo ISSQN'
                    searchDataSource={onSearchGrupoIssQn}
                    selectedItem={data.grupoIssqn}
                    onSelectItem={(grupoIssqn) => {
                      setData({
                        ...data,
                        grupoIssqn,
                        nrSeqGrupoIssqn: grupoIssqn.nrSeqGrupoIssqn,
                      });
                    }}
                    dataSourceTextProperty='noGrupoIssqn'
                  />
                </div>
                <div className='col mb-3'>
                  <Autocomplete
                    label='Grupo PIS'
                    searchDataSource={onSearchGrupoPis}
                    selectedItem={data.grupoPis}
                    onSelectItem={(grupoPis) => {
                      setData({
                        ...data,
                        grupoPis,
                        nrSeqGrupoPIS: grupoPis.nrSeqGrupoPIS,
                      });
                    }}
                    dataSourceTextProperty='noGrupoPIS'
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Ativo'
            checked={data.flgAtivo}
            onChange={(flgAtivo) => setData({ ...data, flgAtivo })}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Detalhamento Manual'
            checked={data.flgImpostoManual}
            onChange={(flgImpostoManual) =>
              setData({ ...data, flgImpostoManual })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Transferência'
            checked={data.flgTransferencia}
            onChange={(flgTransferencia) =>
              setData({ ...data, flgTransferencia })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Valida Preço Custo'
            checked={data.flgValidaPrecoCusto}
            onChange={(flgValidaPrecoCusto) =>
              setData({ ...data, flgValidaPrecoCusto })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Cupom Fiscal'
            checked={data.flgCupomFiscal}
            onChange={(flgCupomFiscal) => setData({ ...data, flgCupomFiscal })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Frete Soma ICMS'
            checked={data.flgSomaIcms}
            onChange={(flgSomaIcms) => setData({ ...data, flgSomaIcms })}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Termo de Impressão'
            checked={data.flgImpressaoTermo}
            onChange={(flgImpressaoTermo) =>
              setData({ ...data, flgImpressaoTermo })
            }
          />
        </div>
        <div className='col mb-3'>
          <RadioButton
            outline
            label='Tipo Nota'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={statusEntrada}
            selectedButton={data.flgEntrada}
            onChange={(flgEntrada) => setData({ ...data, flgEntrada })}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Movimenta Estoque'
            checked={data.flgGeraEstq}
            onChange={(flgGeraEstq) => {
              onSetMureg(flgGeraEstq);
            }}
          />
        </div>
        <div className='row'>
          <div className='col mb-3'>
            <RadioButton
              outline
              label='Finalidade Nota'
              size={BootstrapSizes.Small}
              theme={Theme.Primary}
              buttons={statusFinalidade}
              selectedButton={data.flgFinalidade}
              onChange={(flgFinalidade) => setData({ ...data, flgFinalidade })}
            />
          </div>
          <div className='col mb-3'>
            <Autocomplete
              label='Tipo de Estoque'
              searchDataSource={onSearchEstoqueTipo}
              readOnly={visible}
              selectedItem={data.estoqueTipo}
              onSelectItem={(estoqueTipo) => {
                setData({
                  ...data,
                  estoqueTipo,
                  nrSeqEstoqueTipo: estoqueTipo.nrSeqEstoqueTipo,
                });
              }}
              dataSourceTextProperty='noEstoqueTipo'
            />
          </div>
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Nota de Devolução'
            checked={data.flgDevolucao}
            onChange={(flgDevolucao) => setData({ ...data, flgDevolucao })}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Possui Orçamento'
            checked={data.flgPossuiOrcamento}
            onChange={(flgPossuiOrcamento) =>
              setData({ ...data, flgPossuiOrcamento })
            }
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
