import qs from 'qs';
import Pagination from 'core/models/pagination';
import { ProfissaoTs as Profissao } from 'core/models/SEG';
import api from '../api';

export const getProfissaoAutoComplete = async (filters: any): Promise<any> => {
  const response = await api.get(`/Profissao/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    profissoes: value?.map((json: any) => new Profissao(json)) ?? [],
  };
};

export const getProfissaoList = async (filters: any): Promise<any> => {
  const response = await api.get(`/Profissao/Search`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    profissoes: value?.map((json: any) => new Profissao(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getProfissaoTs = async (id: number): Promise<Profissao> => {
  const response = await api.get(`/Profissao/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return new Profissao(value);
};

export const saveProfissao = async (
  profissao: Profissao | null
): Promise<any> => {
  const response = await api.post(`/Profissao/`, profissao);
  return response.data ?? {};
};

export const deleteProfissao = async (id: number): Promise<any> => {
  const response = await api.delete(`/Profissao/`, { params: { id } });
  return response.data ?? {};
};

export const printProfissoes = async (filters: any): Promise<any> => {
  const response = await api.get(`/Profissao/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelProfissoes = async (filters: any): Promise<any> => {
  const response = await api.get(`/Profissao/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteProfissoes = async (ids: Array<number>): Promise<any> => {
  const response = await api.delete(`/Profissao/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
