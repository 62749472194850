import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getProdutoSubGrupoPagined,
  printProdutoSubGrupo,
  deleteAllProdutoSubGrupo,
  getProdutoSubGrupoList,
} from 'core/services/EST/produtoSubGrupo';
import { CSDSelPage, GridView, Autocomplete } from 'ui/components';
import { getProdutoGrupoList } from 'core/services/EST/produtoGrupo';

const columns = [
  { key: 'nrSeqProdutoSubGrupo', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdProdutoSubGrupo', title: 'Código' },
  { key: 'noProdutoSubGrupo', title: 'Produto SubGrupo' },
  { key: 'noProdutoGrupo', title: 'Produto Grupo' },
  {
    key: 'vlrCusto',
    title: 'Valor Custo',
    format: GridView.DataTypes.Money,
  },
];

export default function ProdutoSubGrupo({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();
  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);
    const { subgrupos, pagination } = await getProdutoSubGrupoPagined(
      params ?? filtros
    );
    if (gridView && gridView.current)
      gridView.current.setDataSource(subgrupos, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { subgrupos, pagination } = await getProdutoSubGrupoPagined();
      if (gridView && gridView.current)
        gridView.current.setDataSource(subgrupos, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ProdutoSubGrupo/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqProdutoSubGrupo = sourceRow.find(
      (e) => e.key === 'nrSeqProdutoSubGrupo'
    );
    onOpenMaintenance(nrSeqProdutoSubGrupo.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printProdutoSubGrupo(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onSearchProdutoGrupo = async (e) => {
    const { produtogrupos } = await getProdutoGrupoList({
      noProdutoGrupo: e,
    });

    return produtogrupos;
  };
  const onSearchProdutoSubGrupo = async (e) => {
    const { produtosubgrupos } = await getProdutoSubGrupoList({
      noProdutoSubGrupo: e,
    });

    return produtosubgrupos;
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllProdutoSubGrupo(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Produto SubGrupo'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-6 mb-3'>
          <Autocomplete
            label='Produto Grupo'
            searchDataSource={onSearchProdutoGrupo}
            selectedItem={filtros.produtogrupo}
            onSelectItem={(produtogrupo) => {
              setFiltros({
                ...filtros,
                produtogrupo,
                nrSeqProdutoGrupo: produtogrupo.nrSeqProdutoGrupo,
              });
            }}
            dataSourceTextProperty='noProdutoGrupo'
          />
        </div>
        <div className='col-6 mb-3'>
          <Autocomplete
            label='Produto Sub Grupo'
            searchDataSource={onSearchProdutoSubGrupo}
            selectedItem={filtros.produtosubgrupos}
            onSelectItem={(produtosubgrupos) => {
              setFiltros({
                ...filtros,
                produtosubgrupos,
                nrSeqProdutoSubGrupo: produtosubgrupos.nrSeqProdutoSubGrupo,
              });
            }}
            dataSourceTextProperty='noProdutoSubGrupo'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
