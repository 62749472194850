export default class CamposCorrecaoCte {
  constructor(jsonObject = {}) {
    this.grupoXmlCte = jsonObject.grupoXmlCte;
    this.campoXmlCte = jsonObject.campoXmlCte;
    this.nomeCampo = jsonObject.nomeCampo;
    this.listaDocumento = jsonObject.listaDocumento;
  }

  toJson = () => JSON.stringify(this);
}
