import qs from 'qs';
import Pagination from 'core/models/pagination';
import FreteTerceiro from 'core/models/FRO/freteTerceiro';
import api from '../api';

const baseURL = `/FRO/FreteTerceiro`;

export const getFreteTerceiroList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new FreteTerceiro(json)) ?? [],
  };
};

export const getFreteTerceiroPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new FreteTerceiro(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getFreteTerceiro = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new FreteTerceiro(value) : {};
};

export const saveFreteTerceiro = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteFreteTerceiro = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printFreteTerceiro = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printFreteTerceiroCliente = async (filters) => {
  const response = await api.get(`${baseURL}/PrintPorCliente`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printFreteTerceiroProprietario = async (filters) => {
  const response = await api.get(`${baseURL}/PrintPorProprietario`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printFreteTerceiroRelTributos = async (filters) => {
  const response = await api.get(`${baseURL}/PrintRelatorioTributos`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelFreteTerceiro = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printFreteTerceiroItem = async (filters) => {
  const response = await api.get(`${baseURL}/PrintFreteTerceiro`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllFreteTerceiro = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const searchValeMotorista = async (data) => {
  const response = await api.get(`${baseURL}/SearchValeMotorista`, data);
  return response.data ?? {};
};

export const printAutorizacaoAdto = async (data) => {
  const response = await api.get(`${baseURL}/PrintAutorizacaoAdto`, {
    params: data,
  });
  return response.data ?? {};
};

export const printAutorizacaoSaldo = async (data) => {
  const response = await api.get(`${baseURL}/PrintAutorizacaoSaldo`, {
    params: data,
  });
  return response.data ?? {};
};

export const printAdiantamento = async (data) => {
  const response = await api.get(`${baseURL}/PrintAdiantamento`, {
    params: data,
  });
  return response.data ?? {};
};

export const imprimirCiot = async (id) => {
  const response = await api.get(`${baseURL}/ImprimirCiot`, { params: { id } });
  return response.data ?? {};
};

export const gerarCiot = async (data) => {
  const response = await api.post(`${baseURL}/GerarCiot`, data);
  return response.data ?? {};
};

export const cancelarCiot = async (data) => {
  const response = await api.post(`${baseURL}/CancelarCiot`, data);
  return response.data ?? {};
};

export const retificarCiot = async (data) => {
  const response = await api.post(`${baseURL}/RetificarCiot`, data);
  return response.data ?? {};
};

export const encerrarCiot = async (data) => {
  const response = await api.post(`${baseURL}/EncerrarCiot`, data);
  return response.data ?? {};
};

export const onCalculaTributos = async (data) => {
  const response = await api.post(`${baseURL}/CalculaTributos`, data);
  return response.data ?? {};
};

export const onCancelaFreteTerceiro = async (data) => {
  const response = await api.post(`${baseURL}/CancelaFreteTerceiro`, data);
  return response.data ?? {};
};

export const onGetValoresPedagios = async (data) => {
  const response = await api.post(`${baseURL}/SimularRotaValePedagio`, data);
  return response.data ?? {};
};

export const onEmitirValePedagio = async (data) => {
  const response = await api.post(`${baseURL}/EmitirValePedagio`, data);
  return response.data ?? {};
};

export const saveObservacaoFreteTerceiro = async (data) => {
  const response = await api.post(
    `${baseURL}/SaveObservacaoFreteTerceiro`,
    data
  );
  return response.data ?? {};
};

export const onConsultarProviderValePedagio = async (data) => {
  const response = await api.post(`${baseURL}/ConsultarProviderVP`, data);
  return response.data ?? {};
};
