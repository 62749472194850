import React, { useEffect, useRef } from 'react';

import { Button, GridView, Modal } from 'ui/components';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';

export default function ModalConfirmDeleteSeveralAbastecimentos(props) {
  const { show, text, abastecimentoS } = props;
  const gridView = useRef();

  const handleConfirm = async () => {
    props.onSave(true);
  };

  const cancelText = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
    });
  };

  useEffect(async () => {
    if (abastecimentoS !== undefined) {
      if (abastecimentoS !== null) {
        if (gridView && gridView.current)
          gridView.current.setDataSource(abastecimentoS);
      }
    }
  }, [show]);

  const columns = [
    {
      key: 'nrDocumento',
      title: 'Nr Documento',
    },
    {
      key: 'dtAbastecimento',
      title: 'Data Abastecimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'veiculo.noVeiculo',
      title: 'Veiculo',
    },
    {
      key: 'motorista.noPessoaMot',
      title: 'Motorista',
    },
    {
      key: 'vlrLitro',
      title: 'Valor Litro',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'qtdeLitro',
      title: 'Quantidade',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotal',
      title: 'Total',
      format: GridView.DataTypes.Decimal,
    },
  ];

  return (
    <Modal
      show={show}
      text={text}
      title='Confirmar Exclusao'
      size={BootstrapSizes.Large}
      icon='exclamation-triangle'
    >
      <Modal.Body>{text && <span>{text}</span>}</Modal.Body>

      <div className='col-11'>
        <GridView
          ref={gridView}
          className='table-striped table-hover table-bordered table-sm'
          dataColumns={columns}
          showPagination={false}
          showSelectSizes={false}
        />
      </div>

      <Modal.Footer>
        <Button
          text='Confirmar'
          onClick={handleConfirm}
          theme={Theme.Success}
        />
        <Button text='Cancelar' onClick={cancelText} theme={Theme.Light} />
      </Modal.Footer>
    </Modal>
  );
}
