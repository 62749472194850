import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  JustifyContent,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getAparelho,
  saveAparelho,
  deleteAparelho,
} from 'core/services/TEL/aparelho';
import { getMarcaAparelhoAutoComplete } from 'core/services/TEL/marcaAparelhoCelular';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  Panel,
  Button,
  RadioButton,
  DatePicker,
} from 'ui/components';
import { getModeloaparelhocelularList } from 'core/services/TEL/modeloAparelhoCelular';
import { getOperadoraAutoComplete } from 'core/services/TEL/operadora';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getAparelhoStatusAutoComplete } from 'core/services/TEL/aparelhoStatus';

const FormaAquisicao = [
  { text: 'Comodato', value: 'C' },
  { text: 'A vista/Locação', value: 'V' },
];

const TipoAparelho = [
  { text: 'Celular', value: 'C' },
  { text: 'Modem', value: 'M' },
];

export default function AparelhoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [tipoAparelho, setTipoAarelho] = useState({ flgTipoAparelho: 'C' });
  const [formaAquisicao, setAquisicao] = useState({ flgFormaAquisicao: 'V' });

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const retorno = await getAparelho(registryKey);
      setData(retorno);
      setTipoAarelho(retorno);
      setAquisicao(retorno);
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const obj = {
      cdAparelho: data.cdAparelho,
      nrSeqAparelho: data.nrSeqAparelho,
      nrImei: data.nrImei,
      nrSeqPessoaFor: data.nrSeqPessoaFor,
      nrSeqPessoaCli: data.nrSeqPessoaCli,
      noAparelho: data.noAparelho,
      nrSeqPedidoAparelhoModelo: data.nrSeqPedidoAparelhoModelo,
      flgStatus: data.flgStatus,
      nrMesesGarantia: data.nrMesesGarantia,
      observacao: data.observacao,
      modeloAparelhoCelular: data.modeloAparelhoCelular,
      nrSeqModeloAparelho: data.nrSeqModeloAparelho,
      vlrPrecoCusto: data.vlrPrecoCusto,
      nrSerial: data.nrSerial,
      nrNotaFiscal: data.nrNotaFiscal,
      dtNotaFiscal: data.dtNotaFiscal,
      noItensInclusos: data.noItensInclusos,
      nrSeqEmpresa: data.nrSeqEmpresa,
      nrSeqAparelhoStatus: data.nrSeqAparelhoStatus,
      flgFormaAquisicao: formaAquisicao.flgFormaAquisicao,
      flgTipoAparelho: tipoAparelho.flgTipoAparelho,
      nrSeqOperadora: data.nrSeqOperadora,
      nrSeqPedidoAparelho: data.nrSeqPedidoAparelho,
    };
    const { status, message: msg } = await saveAparelho(obj);
    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };
  const saveFast = async () => {
    setLoading(true);
    const { status, message: msg } = await saveAparelho(data);
    if (status === ResponseStatus.Error);
    if (status === ResponseStatus.Success);
    setData({
      ...data,
      nrImei: '',
      nrSerial: '',
    });
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteAparelho(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };
  const onSearchMarcaAparelho = async (e) => {
    const {
      status,
      message: msg,
      data: marcaAparelhos,
    } = await getMarcaAparelhoAutoComplete({ noMarcaAparelho: e });
    if (msg) onSetMessage(status, msg);
    return marcaAparelhos;
  };
  const onSearchModeloAparelho = async (e) => {
    if (!data.nrSeqMarcaAparelho) {
      onSetMessage(ResponseStatus.Error, 'Marca deve ser selecionada');
      return [];
    }
    const {
      status,
      message: msg,
      data: modeloAparelhos,
    } = await getModeloaparelhocelularList({
      noModeloAparelho: e,
      nrSeqMarcaAparelho: data.marcaAparelhoCelular.nrSeqMarcaAparelho,
    });
    if (msg) onSetMessage(status, msg);
    return modeloAparelhos;
  };

  const onSearchOperadora = async (e) => {
    const {
      status,
      message: msg,
      operadoras,
    } = await getOperadoraAutoComplete({ noOperadora: e });
    if (msg) onSetMessage(status, msg);
    return operadoras;
  };

  const onSearchAparelhoStatus = async (e) => {
    const {
      status,
      message: msg,
      aparelho,
    } = await getAparelhoStatusAutoComplete({ noAparelhoStatus: e });
    if (msg) onSetMessage(status, msg);
    return aparelho;
  };

  const onSearchFornecedor = async (e) => {
    const {
      status,
      message: msg,
      data: fornecedor,
    } = await getFornecedorAutoComplete({
      nrSeqPessoaFor: e,
    });
    if (msg) onSetMessage(status, msg);
    return fornecedor;
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Aparelho'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqAparelho > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <Panel>
        <Panel.Header
          title='Cadastro Aparelho'
          theme={Theme.Light}
          align={JustifyContent.Start}
        />
        <Panel.Body>
          <div className='row mt-3'>
            <div className='col-3'>
              <Textbox
                maxLength={20}
                label='Código Aparelho'
                text={data.cdAparelho}
                onChangedValue={(cdAparelho) =>
                  setData({ ...data, cdAparelho })
                }
              />
            </div>
            <div className='col-3'>
              <Autocomplete
                label='Aparelho Status'
                required
                searchDataSource={onSearchAparelhoStatus}
                selectedItem={data.aparelhoStatus}
                onSelectItem={(aparelhoStatus) => {
                  setData({
                    ...data,
                    aparelhoStatus,
                    nrSeqAparelhoStatus: aparelhoStatus.nrSeqAparelhoStatus,
                  });
                }}
                dataSourceTextProperty='noAparelhoStatus'
              />
            </div>
            <div className='col-3'>
              <Autocomplete
                label='Empresa'
                searchDataSource={onSearchEmpresa}
                required
                selectedItem={data.empresa}
                onSelectItem={(empresa) => {
                  setData({
                    ...data,
                    empresa,
                    nrSeqEmpresa: empresa.nrSeqEmpresa,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-3'>
              <Autocomplete
                label='Marca Aparelho'
                required
                searchDataSource={onSearchMarcaAparelho}
                selectedItem={data.marcaAparelhoCelular}
                onSelectItem={(marcaAparelhoCelular) => {
                  setData({
                    ...data,
                    marcaAparelhoCelular,
                    nrSeqMarcaAparelho: marcaAparelhoCelular.nrSeqMarcaAparelho,
                  });
                }}
                dataSourceTextProperty='noMarcaAparelho'
              />
            </div>

            <div className='col-3'>
              <Autocomplete
                label='Modelo Aparelho'
                required
                searchDataSource={onSearchModeloAparelho}
                selectedItem={data.modeloAparelhoCelular}
                onSelectItem={(modeloAparelhoCelular) => {
                  setData({
                    ...data,
                    modeloAparelhoCelular,
                    nrSeqModeloAparelho:
                      modeloAparelhoCelular.nrSeqModeloAparelho,
                  });
                }}
                dataSourceTextProperty='noModeloAparelho'
              />
            </div>
            <div className='col-3'>
              <Autocomplete
                label='Operadora'
                required
                searchDataSource={onSearchOperadora}
                selectedItem={data.operadora}
                onSelectItem={(operadora) => {
                  setData({
                    ...data,
                    operadora,
                    nrSeqOperadora: operadora.nrSeqOperadora,
                  });
                }}
                dataSourceTextProperty='noOperadora'
              />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-3'>
              <Textbox
                maxLength={4}
                label='Meses Garantia'
                text={data.nrMesesGarantia}
                onChangedValue={(nrMesesGarantia) =>
                  setData({ ...data, nrMesesGarantia })
                }
              />
            </div>
            <div className='col-5'>
              <Textbox
                maxLength={800}
                label='Itens Inclusos'
                text={data.noItensInclusos}
                onChangedValue={(noItensInclusos) =>
                  setData({ ...data, noItensInclusos })
                }
              />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-3'>
              <Textbox
                maxLength={4000}
                label='Observação'
                text={data.observacao}
                onChangedValue={(observacao) =>
                  setData({ ...data, observacao })
                }
              />
            </div>
            <div className='col-2'>
              <RadioButton
                outline
                label='Tipo Aparelho'
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                buttons={TipoAparelho}
                selectedButton={tipoAparelho.flgTipoAparelho}
                onChange={(flgTipoAparelho) =>
                  setTipoAarelho({
                    ...tipoAparelho,
                    flgTipoAparelho,
                  })
                }
              />
            </div>
            <div className='col-3'>
              <RadioButton
                outline
                label='Forma Aquisição'
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                buttons={FormaAquisicao}
                selectedButton={formaAquisicao.flgFormaAquisicao}
                onChange={(flgFormaAquisicao) =>
                  setAquisicao({
                    ...formaAquisicao,
                    flgFormaAquisicao,
                  })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header
            title='Cadastro Rápido de Aparelho'
            theme={Theme.Light}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-3'>
                <Textbox
                  maxLength={30}
                  label='Imei'
                  text={data.nrImei}
                  onChangedValue={(nrImei) => setData({ ...data, nrImei })}
                />
              </div>
              <div className='col-3'>
                <Textbox
                  maxLength={20}
                  label='Serial'
                  text={data.nrSerial}
                  onChangedValue={(nrSerial) => setData({ ...data, nrSerial })}
                />
              </div>
              <div className='col-2 mt-3'>
                <Button
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  icon='check'
                  text='salvar'
                  onClick={() => saveFast()}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
        <Panel>
          <Panel.Header
            title='Informações Nota Fiscal'
            theme={Theme.Light}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-auto'>
              <div className='col-3'>
                <Textbox
                  maxLength={20}
                  label='Nota Fiscal'
                  text={data.nrNotaFiscal}
                  onChangedValue={(nrNotaFiscal) =>
                    setData({ ...data, nrNotaFiscal })
                  }
                />
              </div>
              <div className='col-3'>
                <Textbox
                  label='Valor Custo'
                  text={data.vlrPrecoCusto}
                  mask={MaskTypes.Decimal}
                  onChangedValue={(vlrPrecoCusto) =>
                    setData({ ...data, vlrPrecoCusto })
                  }
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Data Nota Fiscal'
                  required
                  text={data.dtNotaFiscal}
                  mask={MaskTypes.Date}
                  onChange={(dtNotaFiscal) =>
                    setData({ ...data, dtNotaFiscal })
                  }
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  label='Fornecedor'
                  searchDataSource={onSearchFornecedor}
                  required
                  selectedItem={data.fornecedor}
                  onSelectItem={(fornecedor) => {
                    setData({
                      ...data,
                      fornecedor,
                      nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    </CSDManutPage>
  );
}
