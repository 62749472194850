import Pagination from 'core/models/pagination';
import EmpresaTipoDocumentoEletronico from 'core/models/SEG/empresaTipoDocumentoEletronico';
import api from '../api';

const baseURL = `/SEG/EmpresaTipoDocumentoEletronico`;

export const getEmpresaTipoDocumentoEletronicoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new EmpresaTipoDocumentoEletronico(json)) ?? [],
  };
};

export const getEmpresaTipoDocumentoEletronicoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new EmpresaTipoDocumentoEletronico(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const postEmpresaTipoDocumentoEletronico = async (filters) => {
  const response = await api.post(`${baseURL}/Find`, filters);
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    data: value ?? {},
  };
};

export const installCertificate = async (filters) => {
  const response = await api.post(`${baseURL}/InstallCertificate`, filters);
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    data: value ?? {},
  };
};

export const downloadCertificate = async (filters) => {
  const response = await api.get(`${baseURL}/DownloadCertificate`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};

export const saveEmpresaTipoDocumentoEletronico = async (data) => {
  const response = await api.post(`${baseURL}/Save`, data);
  return response.data ?? {};
};

export const deleteEmpresaTipoDocumentoEletronico = async (filters) => {
  const response = await api.post(`${baseURL}/Delete`, filters);
  return response.data ?? {};
};

export const printEmpresaTipoDocumentoEletronico = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelEmpresaTipoDocumentoEletronico = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    filters,
  });
  return response.data ?? {};
};

export const deleteAllEmpresaTipoDocumentoEletronico = async (filters) => {
  const response = await api.post(`${baseURL}/DeleteAll`, filters);
  return response.data ?? {};
};
export const certificateValidation = async () => {
  const response = await api.get(`${baseURL}/CertificateValidation`);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};
