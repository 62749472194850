import { NFEImportada } from 'core/models/COP';

export default class NFEImportadaResult extends NFEImportada {
  flgNotaCompraCompleta: boolean;

  flgPermiteManifestar: boolean;

  noCte: string;

  noSerie: string;

  noNumero: string;

  constructor(jsonObject: NFEImportadaResult) {
    super(jsonObject);
    this.flgNotaCompraCompleta = jsonObject.flgNotaCompraCompleta;
    this.flgPermiteManifestar = jsonObject.flgPermiteManifestar;
    this.noCte = jsonObject.noCte;
    this.noSerie = jsonObject.noSerie;
    this.noNumero = jsonObject.noNumero;
  }
}
