import { Usuario } from '../SEG';

export default class TituloPagarOcorrencia {
  constructor(jsonObject = {}) {
    this.nrSeqTituloPagarOcorrencia = jsonObject.nrSeqTituloPagarOcorrencia;
    this.noOcorrencia = jsonObject.noOcorrencia;
    this.dtCadastro = jsonObject.dtCadastro;
    this.nrSeqTituloPagar = jsonObject.nrSeqTituloPagar;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.usuario = new Usuario(jsonObject.usuario ?? {});
  }

  toJson = () => JSON.stringify(this);
}
