import React, { useState, useEffect } from 'react';
import { ResponseStatus, BootstrapSizes, Theme } from 'ui/Helpers/utils';
import {
  getContratoAgrupadoList,
  getContratoList,
  getFaturaList,
} from 'core/services/TEL/contrato';
import { Autocomplete, Switch } from 'ui/components';

export default function PanelSelecaoContratos({
  contratoAgrupado,
  contrato,
  fatura,
  onContratoAgrupadoChange,
  onContratoChange,
  onFaturaChange,
  onMessage,
  Simple,
  visible,
  visibleFatura,
  normal,
  justActives,
}) {
  const [isSimple, setIsSimple] = useState(false);
  const [isContratoAgrupado, setIsContratoAgrupado] = useState(
    !Simple && !normal
  );
  const [contratoAgrupadoState, setContratoAgrupado] = useState(null);
  const [contratoState, setContrato] = useState(contrato);
  const [faturaState, setFatura] = useState(fatura);

  useEffect(() => {
    setContratoAgrupado(contratoAgrupado);
  }, [contratoAgrupado]);

  useEffect(() => {
    setContrato(contrato);
  }, [contrato]);

  useEffect(() => {
    setFatura(fatura);
  }, [fatura]);

  useEffect(() => {
    setIsSimple(Simple);
  }, [Simple]);

  const isVisible = visible ?? true;
  const isVisibleFatura = visibleFatura ?? true;

  const onSearchContratoAgrupado = async (e) => {
    const {
      status,
      message,
      contratosAgrupados,
    } = await getContratoAgrupadoList({ noContratoAgrupado: e });

    if (message && onMessage)
      onMessage(
        message,
        status === ResponseStatus.Success ? Theme.Success : Theme.Danger
      );

    return contratosAgrupados;
  };
  const onSearchContrato = async (e) => {
    setFatura(null);
    const { status, message, contratos } = await getContratoList({
      cdNoCliente: e,
      flgApenasAtivos: justActives ?? false,
    });

    if (message && onMessage)
      onMessage(
        message,
        status === ResponseStatus.Success ? Theme.Success : Theme.Danger
      );

    return contratos;
  };
  const onSearchFatura = async () => {
    if (!contratoState || !contratoState.nrSeqClienteContaCab) {
      if (onMessage) onMessage('Não há contrato selecionado.', Theme.Warning);
      return [];
    }
    const { status, message, faturas } = await getFaturaList({
      nrSeqClienteContaCab: contratoState.nrSeqClienteContaCab,
    });

    if (message && onMessage)
      onMessage(
        message,
        status === ResponseStatus.Success ? Theme.Success : Theme.Danger
      );

    return faturas;
  };

  const handleContratoAgrupadoChange = (e) => {
    setIsContratoAgrupado(e);
    if (!e && onContratoAgrupadoChange) onContratoAgrupadoChange({});
    if (e && onFaturaChange) {
      setContrato({});
      onFaturaChange({});
    }
  };

  return isVisible ? (
    <div className='row'>
      <div className='col-auto'>
        <Switch
          visible={!isSimple}
          label='Agrupado'
          size={BootstrapSizes.Large}
          checked={isContratoAgrupado}
          formControl
          onChange={(e) => handleContratoAgrupadoChange(e)}
        />
      </div>
      <div className='col'>
        <Autocomplete
          label='Contrato Agrupado'
          visible={isContratoAgrupado && !isSimple}
          searchDataSource={onSearchContratoAgrupado}
          selectedItem={contratoAgrupadoState}
          onSelectItem={(item) => {
            setContratoAgrupado(item);
            if (onContratoAgrupadoChange) onContratoAgrupadoChange(item);
          }}
          dataSourceTextProperty='noContratoAgrupado'
        />
        <div className='row'>
          <div className='col-7'>
            <Autocomplete
              label='Contrato'
              visible={!isContratoAgrupado}
              searchDataSource={onSearchContrato}
              selectedItem={contratoState}
              onSelectItem={(item) => {
                if (onContratoChange) onContratoChange(item);
                setContrato(item);
              }}
              dataSourceTextProperty='cdNoCliente'
            />
          </div>
          <div className='col'>
            <Autocomplete
              label='Fatura'
              visible={!isContratoAgrupado && isVisibleFatura}
              searchDataSource={onSearchFatura}
              selectedItem={faturaState}
              onSelectItem={(item) => {
                if (onFaturaChange) onFaturaChange(item);
                setFatura(item);
              }}
              dataSourceTextProperty='periodoFatura'
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
