import qs from 'qs';
import Pagination from 'core/models/pagination';
import MotoristaTipoVinculo from 'core/models/FRO/motoristaTipoVinculo';
import api from '../api';

const baseURL = `/FRO/MotoristaTipoVinculo`;

export const getMotoristaTipoVinculoList = async (filters) => {
  const response = await api.get(`/$MotoristaTipoVinculo/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new MotoristaTipoVinculo(json)) ?? [],
  };
};

export const getMotoristaTipoVinculoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new MotoristaTipoVinculo(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getMotoristaTipoVinculo = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new MotoristaTipoVinculo(value) : {};
};

export const saveMotoristaTipoVinculo = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteMotoristaTipoVinculo = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printMotoristaTipoVinculo = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelMotoristaTipoVinculo = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllMotoristaTipoVinculo = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getMotoristaTipoVinculoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    motoristaTipoVinculos:
      value?.map((json) => new MotoristaTipoVinculo(json)) ?? [],
  };
};
