import qs from 'qs';
import Pagination from 'core/models/pagination';
import VeiculoModeloEixo from 'core/models/FRO/veiculoModeloEixo';
import api from '../api';

const baseURL = `/FRO/VeiculoModeloEixo`;

export const getVeiculoModeloEixoList = async (filters) => {
  const response = await api.get(`/${baseURL}/VeiculoModeloEixo/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new VeiculoModeloEixo(json)) ?? [],
  };
};

export const getSearchByVehicle = async (filters) => {
  const response = await api.get(`${baseURL}/SearchByVehicle`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new VeiculoModeloEixo(json)) ?? [],
  };
};

export const getVeiculoModeloEixoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new VeiculoModeloEixo(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getVeiculoModeloEixo = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new VeiculoModeloEixo(value) : {};
};

export const saveVeiculoModeloEixo = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteVeiculoModeloEixo = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printVeiculoModeloEixo = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelVeiculoModeloEixo = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllVeiculoModeloEixo = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
