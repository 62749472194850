import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  Autocomplete,
  GridView,
  Button,
  Notification,
  Loader,
} from 'ui/components';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';

import { getTipoOcorrenciaColetaEmbarqueAutoComplete } from 'core/services/FRO/tipoOcorrenciaColetaEmbarque';
import {
  saveColetaEmbarqueOcorrencia,
  deleteColetaEmbarqueOcorrencia,
  getColetaEmbarqueOcorrenciaList,
} from 'core/services/FRO/coletaEmbarqueOcorrencia';

export default function ModalOcorrenciasCte({ show, cte, onClose }) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [data, setData] = useState({});
  const gridView = useRef();

  const onSetMessage = (status, msg) => {
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
  };

  const onLoadOcorrencias = async () => {
    setLoading(true);
    const {
      status,
      message: msg,
      data: value,
    } = await getColetaEmbarqueOcorrenciaList({
      nrSeqColetaEmbarque: cte?.nrSeqColetaEmbarque,
    });

    if (status === ResponseStatus.Success) {
      if (gridView && gridView.current) gridView.current.setDataSource(value);
    } else if (msg) {
      onSetMessage(status, msg);
    }

    setLoading(false);
  };
  const onClickDelete = async (e, datasource) => {
    const dataSource = datasource.filter((item) => item !== e);
    if (e.nrSeqColetaEmbarqueOcorrencia) {
      const { status } = await deleteColetaEmbarqueOcorrencia(
        e.nrSeqColetaEmbarqueOcorrencia
      );

      if (status === ResponseStatus.Success) {
        if (gridView && gridView.current)
          gridView.current.setDataSource(dataSource);
      } else {
        onSetMessage(status, 'Ocorreu um erro ao excluir o registro.');
      }
    } else if (gridView && gridView.current)
      gridView.current.setDataSource(dataSource);
  };

  const onClickAdd = async () => {
    setLoading(true);

    console.log(data);
    const { status, message: msg } = await saveColetaEmbarqueOcorrencia({
      nrSeqColetaEmbarque: cte.nrSeqColetaEmbarque,
      nrSeqTipoOcorrenciaColetaEmbarque: data.nrSeqTipoOcorrenciaColetaEmbarque,
    });

    if (status === ResponseStatus.Success) {
      onLoadOcorrencias();

      setData({});
    } else if (msg) {
      onSetMessage(status, msg);
    }

    setLoading(false);
  };

  const columns = [
    {
      key: 'nrSeqTipoColetaEmbarqueOcorrencia',
      title: 'nrSeqTipoColetaEmbarqueOcorrencia',
      visible: false,
    },
    { key: 'dtOcorrencia', title: 'Data', format: GridView.DataTypes.DateTime },
    { key: 'usuario.noPessoa', title: 'Usuário' },
    {
      key: 'tipoOcorrencia.noTipoOcorrenciaColetaEmbarque',
      title: 'Tipo',
    },
    {
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDelete(e, datasource),
      theme: Theme.Danger,
      icon: ['fas', 'times'],
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Excluir',
      tooltipDirection: 'bottom',
    },
  ];

  const onAutoCompleteTipoOcorrenciaColetaEmbarque = async (e) => {
    const {
      status,
      message: msg,
      data: value,
    } = await getTipoOcorrenciaColetaEmbarqueAutoComplete({
      noTipoOcorrenciaColetaEmbarque: e,
    });

    if (msg) onSetMessage(status, msg);

    return value;
  };

  useEffect(() => {
    onLoadOcorrencias();
  }, [cte?.nrSeqColetaEmbarque]);

  return (
    <Modal
      show={show}
      title={`Ocorrências Cte Nr. ${cte?.cdColetaEmbarque}`}
      onClose={() => onClose()}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col-6'>
            <Autocomplete
              label='Tipo Ocorrência'
              searchDataSource={onAutoCompleteTipoOcorrenciaColetaEmbarque}
              selectedItem={data.tipoOcorrenciaColetaEmbarque}
              onSelectItem={(tipoOcorrenciaColetaEmbarque) =>
                setData({
                  ...data,
                  tipoOcorrenciaColetaEmbarque,
                  nrSeqTipoOcorrenciaColetaEmbarque:
                    tipoOcorrenciaColetaEmbarque.nrSeqTipoOcorrenciaColetaEmbarque,
                })
              }
              dataSourceTextProperty='noTipoOcorrenciaColetaEmbarque'
            />
          </div>

          <div className='col-3 mt-4'>
            <Button
              outline
              text='Adicionar'
              icon='plus'
              theme={Theme.Success}
              template={Button.Templates.Default}
              size={BootstrapSizes.Medium}
              onClick={() => onClickAdd()}
            />
          </div>
        </div>
        <div className='row mb-5'>
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              offlineData
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Sair'
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          size={BootstrapSizes.Medium}
          onClick={() => onClose()}
        />
      </Modal.Footer>
    </Modal>
  );
}
