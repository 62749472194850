import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  RadioButton,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getFornecedorIntegracao,
  saveFornecedorIntegracao,
  deleteFornecedorIntegracao,
} from 'core/services/SEG/fornecedorIntegracao';

import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';

export default function FornecedorIntegracaoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({ noLayout: '1' });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const tipoLayout = [
    {
      text: 'Excel',
      value: '1',
    },
    {
      text: 'Via E-Mail',
      value: '2',
    },
    {
      text: 'Site',
      value: '3',
    },
    {
      text: 'Texto Email',
      value: '4',
    },
  ];

  const tipoNfe = [
    {
      text: 'Somente NF-E',
      value: '1',
    },
    {
      text: 'Somente NFC-E',
      value: '2',
    },
    {
      text: 'Ambos',
      value: '3',
    },
  ];

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      setData(await getFornecedorIntegracao(registryKey));
      setLoading(false);
    } else setData({ noLayout: '1' });
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveFornecedorIntegracao(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteFornecedorIntegracao(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchPessoaFor = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaFors,
    } = await getFornecedorAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return pessoaFors;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Fornecedor Integracao'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({ noLayout: '1' })}
      onSave={save}
      onDelete={data?.nrSeqFornecedorIntegracao > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        {data?.noLayout !== '4' && (
          <div className='col-4 mb-3'>
            <Autocomplete
              label='Fornecedor'
              searchDataSource={onSearchPessoaFor}
              selectedItem={data.fornecedor}
              onSelectItem={(fornecedor) => {
                setData({
                  ...data,
                  fornecedor,
                  nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                });
              }}
              dataSourceTextProperty='nomeDocumentoCidadeEstado'
            />
          </div>
        )}
        <div className='col-4 mb-3'>
          <RadioButton
            label='Tipo de integração'
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={data.noLayout}
            onChange={(noLayout) => {
              setData({
                ...data,
                noLayout,
              });
            }}
            buttons={tipoLayout}
          />
        </div>
        {data?.noLayout === '4' && (
          <div className='col-4 mb-3'>
            <Textbox
              label='E-Mail'
              text={data.noEndereco}
              onChangedValue={(noEndereco) => setData({ ...data, noEndereco })}
            />
          </div>
        )}
      </div>
      <div className='row'>
        {data?.noLayout === '3' && (
          <div className='col-4 mb-3'>
            <Textbox
              maxLength={50}
              label='Usuario'
              text={data.noUsuario}
              onChangedValue={(noUsuario) => setData({ ...data, noUsuario })}
            />
          </div>
        )}
        {data?.noLayout === '3' && (
          <div className='col-4 mb-3'>
            <Textbox
              maxLength={50}
              label='Senha'
              text={data.noSenha}
              onChangedValue={(noSenha) => setData({ ...data, noSenha })}
            />
          </div>
        )}
        {data?.noLayout === '3' && (
          <div className='col-4 mb-3'>
            <Textbox
              label='Porta'
              text={data.nrPorta}
              mask={MaskTypes.Integer}
              onChangedValue={(nrPorta) => setData({ ...data, nrPorta })}
            />
          </div>
        )}
      </div>
      <div className='row'>
        {data?.noLayout === '3' && (
          <div className='col-4 mb-3'>
            <Textbox
              maxLength={500}
              label='Endereco'
              text={data.noEndereco}
              onChangedValue={(noEndereco) => setData({ ...data, noEndereco })}
            />
          </div>
        )}
        {data?.noLayout === '3' && (
          <div className='col-4 mb-3'>
            <Textbox
              maxLength={30}
              label='Operacao'
              text={data.noOperacao}
              onChangedValue={(noOperacao) => setData({ ...data, noOperacao })}
            />
          </div>
        )}
        {data?.noLayout === '3' && (
          <div className='col-4 mb-3'>
            <Textbox
              maxLength={30}
              label='Arquivo'
              text={data.noArquivo}
              onChangedValue={(noArquivo) => setData({ ...data, noArquivo })}
            />
          </div>
        )}
      </div>
      <div className='row'>
        {data?.noLayout === '3' && (
          <div className='col-4 mb-3'>
            <Textbox
              maxLength={200}
              label='Local Origem'
              text={data.noLocalOrigem}
              onChangedValue={(noLocalOrigem) =>
                setData({ ...data, noLocalOrigem })
              }
            />
          </div>
        )}
        {data?.noLayout === '3' && (
          <div className='col-4 mb-3'>
            <Textbox
              maxLength={200}
              label='Local Destino'
              text={data.noLocalDestino}
              onChangedValue={(noLocalDestino) =>
                setData({ ...data, noLocalDestino })
              }
            />
          </div>
        )}
        {data?.noLayout === '3' && (
          <div className='col-4 mb-3'>
            <Textbox
              maxLength={1}
              label='Ambiente Produção'
              text={data.flgAmbProducao}
              onChangedValue={(flgAmbProducao) =>
                setData({ ...data, flgAmbProducao })
              }
            />
          </div>
        )}
        {data?.noLayout === '2' && (
          <div className='col-4'>
            <RadioButton
              label='Modo nota fiscal'
              outline
              size={BootstrapSizes.Small}
              theme={Theme.Primary}
              selectedButton={data.modNfe}
              onChange={(modNfe) => {
                setData({
                  ...data,
                  modNfe,
                });
              }}
              buttons={tipoNfe}
            />
          </div>
        )}
      </div>
    </CSDManutPage>
  );
}
