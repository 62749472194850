import ConvenioEncargo from 'core/models/ASS/convenioEncargo';
import TituloEspecie from '../FIN/tituloEspecie';

export default class PlanoConvenioConvenioEncargo {
  constructor(jsonObject = {}) {
    this.nrSeqPlanoConvEncargoConv = jsonObject.nrSeqPlanoConvEncargoConv;
    this.nrSeqPlanoConvenio = jsonObject.nrSeqPlanoConvenio;
    this.nrSeqConvenioEncargo = jsonObject.nrSeqConvenioEncargo;
    this.vlrOriginal = jsonObject.vlrOriginal;
    this.vlrRecalculado = jsonObject.vlrRecalculado;
    this.flgRepassa = jsonObject.flgRepassa;
    this.flgExibeExtrato = jsonObject.flgExibeExtrato;
    this.flgPercentual = jsonObject.flgPercentual;
    this.flgAplicaValorAjuste = jsonObject.flgAplicaValorAjuste;
    this.flgGeraComissao = jsonObject.flgGeraComissao;
    this.flgRepassaVlrNegativo = jsonObject.flgRepassaVlrNegativo;
    this.nrSeqTituloEspecie = jsonObject.nrSeqTituloEspecie;
    this.tituloEspecie = new TituloEspecie(jsonObject.tituloEspecie ?? {});
    this.convenioEncargo = new ConvenioEncargo(
      jsonObject.convenioEncargo ?? {}
    );
    this.flgRecalculo_IncluiVlrOriginal =
      jsonObject.flgRecalculo_IncluiVlrOriginal;
  }

  toJson() {
    return JSON.stringify(this);
  }
}
