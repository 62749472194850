class EnvioProcessamentoContas {
  constructor(jsonObject = {}) {
    this.nrseqparamenvioprocessamentoconta =
      jsonObject.nrseqparamenvioprocessamentoconta;
    this.flg1sintfinan = jsonObject.flg1sintfinan;
    this.flg2arqintegr = jsonObject.flg2arqintegr;
    this.flge3recibo = jsonObject.flge3recibo;
    this.flg4analservligacoes = jsonObject.flg4analservligacoes;
    this.flg5finansubsidio = jsonObject.flg5finansubsidio;
    this.flg6sintetcentcusto = jsonObject.flg6sintetcentcusto;
    this.flg7geracontaspdf = jsonObject.flg7geracontaspdf;
    this.flg1gerporplano = jsonObject.flg1gerporplano;
    this.flg1ordnomecliente = jsonObject.flg1ordnomecliente;
    this.flgo1rdnumlinha = jsonObject.flgo1rdnumlinha;
    this.flg1gercominfbanc = jsonObject.flg1gercominfbanc;
    this.flg1gercommatr = jsonObject.flg1gercommatr;
    this.flg1gercomcod = jsonObject.flg1gercomcod;
    this.flg1gercomemail = jsonObject.flg1gercomemail;
    this.flg1gercomcentrocusto = jsonObject.flg1gercomcentrocusto;
    this.nr4ordnum = jsonObject.nr4ordnum;
    this.flg4comvlrorigvlrrecalc = jsonObject.flg4comvlrorigvlrrecalc;
    this.flg4comvlrorig = jsonObject.flg4comvlrorig;
    this.flg4comvlrrecalc = jsonObject.flg4comvlrrecalc;
    this.flg5gerporcentrodecusto = jsonObject.flg5gerporcentrodecusto;
    this.flg7gerporcentrodecusto = jsonObject.flg7gerporcentrodecusto;
    this.flg7unicoarquivo = jsonObject.flg7unicoarquivo;
    this.flg7individual = jsonObject.flg7individual;
    this.nr7quantregistros = jsonObject.nr7quantregistros;
    this.flg7ordnumcelular = jsonObject.flg7ordnumcelular;
    this.flg7ordnumcliente = jsonObject.flg7ordnumcliente;
    this.flg7ordplano = jsonObject.flg7ordplano;
    this.flg4pesqvlroriginal = jsonObject.flg4pesqvlroriginal;
    this.flg4pesqvlrrecalc = jsonObject.flg4pesqvlrrecalc;
    this.flg7individualnumerocelular = jsonObject.flg7individualnumerocelular;
    this.flg7individualnomeclientenumero =
      jsonObject.flg7individualnomeclientenumero;
    this.flg7mesanonomeclientenumero = jsonObject.flg7mesanonomeclientenumero;
    this.flg1contfat = jsonObject.flg1contfat;
    this.flg2contfat = jsonObject.flg2contfat;
    this.flg3contfat = jsonObject.flg3contfat;
    this.flg4contfat = jsonObject.flg4contfat;
    this.flg5contfat = jsonObject.flg5contfat;
    this.flg6contfat = jsonObject.flg6contfat;
    this.flg7contfat = jsonObject.flg7contfat;
    this.flg1contagrup = jsonObject.flg1contagrup;
    this.flg2contagrup = jsonObject.flg2contagrup;
    this.flg3contagrup = jsonObject.flg3contagrup;
    this.flg4contagrup = jsonObject.flg4contagrup;
    this.flg5contagrup = jsonObject.flg5contagrup;
    this.flg6contagrup = jsonObject.flg6contagrup;
    this.notitulo = jsonObject.notitulo;
    this.nomensagem = jsonObject.nomensagem;
    this.flg1gerporcentrodecusto = jsonObject.flg1gerporcentrodecusto;
    this.flg7contagrup = jsonObject.flg7contagrup;
    this.flg4pesqvlrrecalculado = jsonObject.flg4pesqvlrrecalculado;
    this.flg6gerporcentrodecusto = jsonObject.flg6gerporcentrodecusto;
    this.email = jsonObject.email;
    this.flg7gercontamaiordoquezero = jsonObject.flg7gercontamaiordoquezero;
    this.flg7buscarlinhassememailcadastrado =
      jsonObject.flg7buscarlinhassememailcadastrado;
    this.nrseq2plano = jsonObject.nrseq2plano;
    this.flg2porplano = jsonObject.flg2porplano;
    this.flg2agruparrfaturas = jsonObject.flg2agruparrfaturas;
    this.vlr1recalculomaior = jsonObject.vlr1recalculomaior;
    this.flgvlr1recalculomaior = jsonObject.flgvlr1recalculomaior;
    this.flggerapdfcoddownload = jsonObject.flggerapdfcoddownload;
    this.nogerapdfcoddownloadtemplate = jsonObject.nogerapdfcoddownloadtemplate;
    this.flg1gercomclienteconta = jsonObject.flg1gercomclienteconta;
    this.flg7paginabranca = jsonObject.flg7paginabranca;
    this.flg7individualcontratonomeclientenumero =
      jsonObject.flg7individualcontratonomeclientenumero;
    this.flg7geracontamaiordoquezero = jsonObject.flg7geracontamaiordoquezero;
    this.flgcontrolecentrocusto = jsonObject.flgcontrolecentrocusto;
    this.flg4RelatorioCom = jsonObject.flg4RelatorioCom;
    this.flg7FormatoArquivo = jsonObject.flg7FormatoArquivo;
    this.flg7Ordem = jsonObject.flg7Ordem;
    this.flg1GerarPor = jsonObject.flg1GerarPor;
  }

  toJson = () => JSON.stringify(this);
}
export default EnvioProcessamentoContas;
