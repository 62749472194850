import GrauParentesco from 'core/models/SEG/grauParentesco';

export default class PessoaReferencias {
  constructor(jsonObject = {}) {
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.noReferencia = jsonObject.noReferencia;
    this.fone = jsonObject.fone;
    this.flgPessoal = jsonObject.flgPessoal;
    this.nrSeqGrauParentesco = jsonObject.nrSeqGrauParentesco;
    this.nrSeqPessoaReferencias = jsonObject.nrSeqPessoaReferencias;
    this.grauParentesco = new GrauParentesco(jsonObject.grauParentesco ?? {});
  }

  toJson = () => JSON.stringify(this);
}
