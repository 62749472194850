import EncargoManual from '../TEL/encargoManual';
import GrupoTipoChamada from '../TEL/grupoTipoChamada';
import Usuario from '../SEG/usuario';
import DocumentoConfiguracao from '../SEG/documentoConfiguracao';
import Perfil from '../SEG/perfil';

export default class TipoDemanda {
  constructor(jsonObject = {}) {
    this.nrSeqTipoDemanda = jsonObject.nrSeqTipoDemanda;
    this.noTipoDemanda = jsonObject.noTipoDemanda;
    this.flgExibePacote = jsonObject.flgExibePacote;
    this.flgExibeChip = jsonObject.flgExibeChip;
    this.noPagina = jsonObject.noPagina;
    this.flgUploadArquivo = jsonObject.flgUploadArquivo;
    this.cdDocumentoConfiguracao = jsonObject.cdDocumentoConfiguracao;
    this.noTituloMensagem = jsonObject.noTituloMensagem;
    this.noCorpoMensagem = jsonObject.noCorpoMensagem;
    this.flgEncargoManual = jsonObject.flgEncargoManual;
    this.noAjuda = jsonObject.noAjuda;
    this.flgProtocolo = jsonObject.flgProtocolo;
    this.nrSeqTipoDemandaPai = jsonObject.nrSeqTipoDemandaPai;
    this.flgSincronizaCelebre = jsonObject.flgSincronizaCelebre;
    this.nrSeqUsuarioAtendimento = jsonObject.nrSeqUsuarioAtendimento;
    this.flgExibePacoteSMS = jsonObject.flgExibePacoteSMS;
    this.flgAtivo = jsonObject.flgAtivo;
    this.flgTrocaTitularidade = jsonObject.flgTrocaTitularidade;
    this.flgGeraMassivo = jsonObject.flgGeraMassivo;
    this.nrSeqEncargoManual = jsonObject.nrSeqEncargoManual;
    this.flgPortabilidade = jsonObject.flgPortabilidade;
    this.flgNrLinhaNova = jsonObject.flgNrLinhaNova;
    this.flgCancelaClienteLinha = jsonObject.flgCancelaClienteLinha;
    this.flgEfetivaAutomaticamente = jsonObject.flgEfetivaAutomaticamente;
    this.flgDevolveAparelhoEstoque = jsonObject.flgDevolveAparelhoEstoque;
    this.nrSeqGrupoTipoChamada = jsonObject.nrSeqGrupoTipoChamada;
    this.flgTrocaAparelho = jsonObject.flgTrocaAparelho;
    this.noEmailAlertaStatus = jsonObject.noEmailAlertaStatus;
    this.flgObrigaUploadEfetivacao = jsonObject.flgObrigaUploadEfetivacao;
    this.flgTrocaNumero = jsonObject.flgTrocaNumero;
    this.flgConvenio = jsonObject.flgConvenio;
    this.flgExecutaEfetivacaoDestino = jsonObject.flgExecutaEfetivacaoDestino;
    this.flgExibeCentroCusto = jsonObject.flgExibeCentroCusto;
    this.qtdMesesRenovacao = jsonObject.qtdMesesRenovacao;
    this.flgPacoteEncargoManual = jsonObject.flgPacoteEncargoManual;
    this.flgMureg = jsonObject.flgMureg;
    this.flgGeraTT = jsonObject.flgGeraTT;
    this.flgVersaoOld = jsonObject.flgVersaoOld;
    this.tipoDemandaPai = jsonObject.tipoDemandaPai;
    this.flgBloqueioInadimplente = jsonObject.flgBloqueioInadimplente;
    this.documento = new DocumentoConfiguracao(jsonObject.documento ?? {});
    this.usuario = new Usuario(jsonObject.usuario ?? {});
    this.encargoManual = new EncargoManual(jsonObject.encargoManual ?? {});
    this.grupoTipoChamada = new GrupoTipoChamada(
      jsonObject.grupoTipoChamada ?? {}
    );
    this.flgRevertePortabilidade = jsonObject.flgRevertePortabilidade;
    this.cdTipoDemanda = jsonObject.cdTipoDemanda;
    this.nrSeqPerfil = jsonObject.nrSeqPerfil;
    this.perfil = new Perfil(jsonObject.perfil ?? {});
    this.perfisAutorizados = (jsonObject.perfisAutorizados ?? []).map(
      (perfil) => new Perfil(perfil)
    );
  }

  toJson = () => JSON.stringify(this);
}
