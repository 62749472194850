import React, { useState } from 'react';
import { SortDirections, generateID } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import { FontAwesomeIcon, Textbox } from 'ui/components';
import GridView from './index'; // eslint-disable-line import/no-cycle

const THead = (props) => {
  const {
    dataColumns,
    onColumnSort,
    sortBy,
    onColumnHeaderCheck,
    canControlVisibility,
    lightSelection,
  } = props;
  const hasFilters = dataColumns.filter((column) => column.filterable);
  const [filterState, setFilterState] = useState(
    new Array(hasFilters?.length ?? 0)
  );

  const onFilter = (value, column, index) => {
    const newFilters = [...filterState];
    newFilters[index] = value;
    setFilterState(newFilters);

    if (props.onGridFilter) props.onGridFilter(value, column);
  };

  const columnsVisibility = canControlVisibility && (
    <tr>
      <th colSpan={dataColumns.length}>
        {dataColumns.map((column, index) => {
          if (
            column.type === GridView.ColumnTypes.Checkbox ||
            column.type === GridView.ColumnTypes.Button
          )
            return null;

          const btnClass = ['gridview-btn-visibility'];
          if (column.visible === false) btnClass.push('hidded');

          return (
            <div
              role='presentation'
              key={generateID('gvwHd')}
              className={btnClass.join(' ')}
              onClick={() => props.onToggleColumnVisibility(index)}
            >
              <FontAwesomeIcon icon='eye' />
              {column.title}
            </div>
          );
        })}
      </th>
    </tr>
  );

  const filters =
    !hasFilters || hasFilters.length <= 0 ? null : (
      <tr data-tools>
        {dataColumns.map((column, index) => {
          if (column.visible === false) return null;

          if (column.filterable)
            return (
              <td key={generateID('gvwHd')}>
                <Textbox
                  text={filterState[index]}
                  placeholder={`filtrar por ${column.title}`}
                  onBlur={(_, value) => {
                    onFilter(value, column, index);
                  }}
                />
              </td>
            );
          return <td key={generateID('gvwHd')}> </td>;
        })}
      </tr>
    );

  const columns = dataColumns.map((column, columnIndex) => {
    if (column.visible === false) return null;

    const thProps = { key: columnIndex };
    thProps.valign = 'middle';
    let sortable = column.sortable ?? true;
    let content = column.title;
    if (
      column.mask === MaskTypes.DecimalCustom ||
      column.mask === MaskTypes.Decimal
    )
      thProps.textRight = true;

    if (column.format === GridView.DataTypes.Decimal) thProps.textRight = true;

    if (column.type === GridView.ColumnTypes.Checkbox && column.title) {
      content = column.title;
    } else if (column.type === GridView.ColumnTypes.Checkbox) {
      thProps['data-tools'] = true;
      thProps.width = '1%';
      thProps.className += ' px-3';
      sortable = false;

      const checkProps = {
        id: `${column.key}_0_0`,
        className: 'form-check-input',
        type: 'checkbox',
        onChange: (e) => onColumnHeaderCheck(columnIndex, e.target.checked),
      };

      if (!lightSelection) checkProps.checked = column.state ?? false;

      content = React.createElement('input', checkProps);
    } else if (column.type === GridView.ColumnTypes.Button)
      thProps['data-tools'] = true;

    if (sortable) {
      const auxDirection = sortBy?.endsWith(` ${SortDirections.Asc}`)
        ? SortDirections.Desc
        : SortDirections.Asc;
      const sortDirection =
        sortBy?.indexOf(column.sortKey ?? column.key) < 0
          ? SortDirections.Asc
          : auxDirection;

      thProps.onClick = () =>
        onColumnSort &&
        onColumnSort(
          `${column.sortKey ?? column.key} ${sortDirection}`,
          columnIndex
        );
      thProps.className = 'cursor-pointer';
    }

    const sortByIcon =
      sortBy && sortBy?.indexOf(column.key) >= 0 ? (
        <FontAwesomeIcon
          className='ms-1 text-primary'
          icon={`sort-amount-${
            sortBy.endsWith(` ${SortDirections.Asc}`) ? 'up' : 'down'
          }`}
        />
      ) : (
        <FontAwesomeIcon className='text-muted' icon='sort-amount-up' />
      );

    let classNameColumn = 'd-flex justify-content-between align-items-center';
    if (thProps.textRight) {
      classNameColumn = 'text-end justify-content-between align-items-center';
    }
    return React.createElement(
      'th',
      thProps,
      <div className={classNameColumn}>
        <span>{content}</span>
        {!sortable ? null : sortByIcon}
      </div>
    );
  });

  return (
    <thead>
      {columnsVisibility}
      <tr>{columns}</tr>
      {filters}
    </thead>
  );
};
export default THead;
