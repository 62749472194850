import React, { useEffect, useState } from 'react';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { Button, Modal, Switch } from 'ui/components';

export default function ModalImprimir({ show, onClose, onPrint }) {
  const [filters, setFilters] = useState({
    flgRelEnsaioBombeamento: true,
    flgRelAnaliseAgua: true,
    flgRelTecnico: true,
    flgRelPerfilLitologico: true,
  });
  useEffect(() => {
    setFilters({
      flgRelEnsaioBombeamento: true,
      flgRelAnaliseAgua: true,
      flgRelTecnico: true,
      flgRelPerfilLitologico: true,
    });
  }, [show]);

  return (
    <Modal
      show={show}
      title='Impressão'
      size={BootstrapSizes.Small}
      onClose={onClose}
    >
      <Modal.Body>
        <div className='row'>
          <div className='col'>
            <Switch
              formControl
              label='Relatório Técnico'
              checked={filters.flgRelTecnico}
              onChange={(flgRelTecnico) =>
                setFilters({
                  ...filters,
                  flgRelTecnico,
                })
              }
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <Switch
              formControl
              label='Ensaio de Bombeamento'
              checked={filters.flgRelEnsaioBombeamento}
              onChange={(flgRelEnsaioBombeamento) =>
                setFilters({
                  ...filters,
                  flgRelEnsaioBombeamento,
                })
              }
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <Switch
              formControl
              label='Análise de Água'
              checked={filters.flgRelAnaliseAgua}
              onChange={(flgRelAnaliseAgua) =>
                setFilters({
                  ...filters,
                  flgRelAnaliseAgua,
                })
              }
            />
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <Switch
              formControl
              label='Perfil Litológico'
              checked={filters.flgRelPerfilLitologico}
              onChange={(flgRelPerfilLitologico) =>
                setFilters({
                  ...filters,
                  flgRelPerfilLitologico,
                })
              }
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Imprimir'
          icon='check'
          theme={Theme.Success}
          onClick={() => onPrint(filters)}
        />
        <Button
          text='Fechar'
          icon='ban'
          theme={Theme.Danger}
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
