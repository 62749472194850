import PrioridadeChamado from 'core/models/HELP/prioridadeChamado';
import api from '../api';

const baseURL = '/HELP/PrioridadeChamado';

export const getPrioridadeChamadoAutoComplete = async () => {
  const response = await api.get(`${baseURL}/AutoComplete`);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    prioridadesChamados:
      value?.map((json: any) => new PrioridadeChamado(json)) ?? [],
  };
};
