import PessoaDocumento from 'core/models/SEG/pessoaDocumento';

export default class Transportadora {
  constructor(jsonObject = {}) {
    this.noPessoa = jsonObject.noPessoa;
    this.nrSeqPessoaTran = jsonObject.nrSeqPessoaTran;
    this.cdTransportadora = jsonObject.cdTransportadora;
    this.noTransportadora = jsonObject.noTransportadora;
    this.flgControlaVeiculo = jsonObject.flgControlaVeiculo;
    this.flgControlaMotorista = jsonObject.flgControlaMotorista;
    this.flgAtivo = jsonObject.flgAtivo;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgTipo = jsonObject.flgTipo;
    this.nrSeqPessoaTran = jsonObject.nrSeqPessoaTran;
    this.nrRntrc = jsonObject.nrRntrc;
    this.nrSeqTipoTransportadora = jsonObject.nrSeqTipoTransportadora;
    this.noNomeRep = jsonObject.noNomeRep;
    this.noCpfRep = jsonObject.noCpfRep;
    this.flgTipoTransporte = jsonObject.flgTipoTransporte;
    this.nrTipoTransportadora = jsonObject.nrTipoTransportadora;

    this.transportadoraDocumentos =
      jsonObject.transportadoraDocumentos?.map(
        (el) => new PessoaDocumento(el)
      ) ?? [];
  }

  toJson = () => JSON.stringify(this);
}
