import qs from 'qs';
import Pagination from 'core/models/pagination';
import { EmailTitulo } from 'core/models/TEL';
import PropriedadeTexto from 'core/models/TEL/propriedadeTexto';
import ApiEnvio from 'core/models/TEL/apiEnvio';
import api from '../api';

const baseURL = `/TEL/EmailTitulo`;

export const getPropriedadeTextoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompletePropriedadeTexto`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    propriedadesTexto: value?.map((json) => new PropriedadeTexto(json)) ?? [],
  };
};

export const getApiEnvioAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteApiEnvio`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    apisenvio: value?.map((json) => new ApiEnvio(json)) ?? [],
  };
};

export const getEmailTituloList = async (filters) => {
  const response = await api.get(`/$EmailTitulo/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new EmailTitulo(json)) ?? [],
  };
};

export const getEmailTituloPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new EmailTitulo(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getEmailTitulo = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new EmailTitulo(value) : {};
};

export const saveEmailTitulo = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteEmailTitulo = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printEmailTitulo = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelEmailTitulo = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllEmailTitulo = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

const emailTituloAutoComplete = async (data) => {
  const response = await api.get(`TEL/emailTitulo/AutoComplete`, {
    params: data,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    emails: value?.map((json) => new EmailTitulo(json)) ?? [],
  };
};

export const getEmailTituloAutoComplete = async (data) =>
  emailTituloAutoComplete({
    ...data,
  });

export const getSMSContaAutoComplete = async (data) =>
  emailTituloAutoComplete({
    ...data,
    flgSMS: true,
  });

export const getWhatsAppContaAutoComplete = async (data) =>
  emailTituloAutoComplete({
    ...data,
    flgWhatsApp: true,
  });
