import ColetaRemetente from 'core/models/FRO/coletaRemetente';
import ColetaDestinatario from 'core/models/FRO/coletaDestinatario';
import ColetaConsignatario from 'core/models/FRO/coletaConsignatario';
import Motorista from 'core/models/FRO/motorista';
import Veiculo from 'core/models/FRO/veiculo';
import ColetaOrdemTransporte from 'core/models/FRO/coletaOrdemTransporte';
import EspecieFrete from 'core/models/FRO/especieFrete';
import FreteMercadoria from 'core/models/FRO/freteMercadoria';
import TipoFrete from 'core/models/FRO/tipoFrete';

export default class Coleta {
  constructor(jsonObject = {}) {
    this.nrSeqColeta = jsonObject.nrSeqColeta;
    this.dtColeta = jsonObject.dtColeta;
    this.hrColeta = jsonObject.hrColeta;
    this.cdColeta = jsonObject.cdColeta;
    this.dtCad = jsonObject.dtCad;
    this.nrSeqColetaEtapa = jsonObject.nrSeqColetaEtapa;
    this.dtEmissao = jsonObject.dtEmissao;
    this.dtRealizacao = jsonObject.dtRealizacao;
    this.nrSeqPessoaRem = jsonObject.nrSeqPessoaRem;
    this.nrSeqPessoaDes = jsonObject.nrSeqPessoaDes;
    this.pesoBruto = jsonObject.pesoBruto;
    this.qtdeColeta = jsonObject.qtdeColeta;
    this.nrSeqProdutoUnidadeMedida = jsonObject.nrSeqProdutoUnidadeMedida;
    this.vlrProjetado = jsonObject.vlrProjetado;
    this.descColeta = jsonObject.descColeta;
    this.obsColeta = jsonObject.obsColeta;
    this.nrSeqTipoFrete = jsonObject.nrSeqTipoFrete;
    this.noApanhador = jsonObject.noApanhador;
    this.nrSeqTipoEmbarque = jsonObject.nrSeqTipoEmbarque;
    this.nrSeqColetaEmbarque = jsonObject.nrSeqColetaEmbarque;
    this.nrSeqVeiculoPrinc = jsonObject.nrSeqVeiculoPrinc;
    this.nrSeqPessoaMot = jsonObject.nrSeqPessoaMot;
    this.nrSeqVeiculoComp = jsonObject.nrSeqVeiculoComp;
    this.cdPlaca = jsonObject.cdPlaca;
    this.nrSeqPessoaConsigOri = jsonObject.nrSeqPessoaConsigOri;
    this.nrSeqPessoaconsigDest = jsonObject.nrSeqPessoaconsigDest;
    this.nrSeqFreteMercadoria = jsonObject.nrSeqFreteMercadoria;
    this.nrSeqEspecieFrete = jsonObject.nrSeqEspecieFrete;
    this.nrSeqCondicaoPagamento = jsonObject.nrSeqCondicaoPagamento;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.flgCarga = jsonObject.flgCarga;
    this.nrSeqFreteTipoCalculo = jsonObject.nrSeqFreteTipoCalculo;
    this.telefone = jsonObject.telefone;
    this.dtSolicitacao = jsonObject.dtSolicitacao;
    this.vlrAprovado = jsonObject.vlrAprovado;
    this.vlrMercadoria = jsonObject.vlrMercadoria;
    this.dtCancel = jsonObject.dtCancel;
    this.nrSeqVeiculoCompa = jsonObject.nrSeqVeiculoCompa;
    this.nrSeqVeiculoCompB = jsonObject.nrSeqVeiculoCompB;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.empresa = jsonObject.empresa ?? {};
    this.cliente = jsonObject.cliente ?? {};

    // #region Propriedades Auxiliares
    this.noOrdemColeta = jsonObject.noOrdemColeta;
    this.remetente = new ColetaRemetente(jsonObject.remetente ?? {});
    this.destinatario = new ColetaDestinatario(jsonObject.destinatario ?? {});
    this.consignatarioOrigem = new ColetaConsignatario(
      jsonObject.consignatarioOrigem ?? {}
    );
    this.consignatarioDestino = new ColetaConsignatario(
      jsonObject.consignatarioDestino ?? {}
    );
    this.motorista = new Motorista(jsonObject.motorista ?? {});
    this.veiculoPrincipal = new Veiculo(jsonObject.veiculoPrincipal ?? {});
    this.veiculoCompA = new Veiculo(jsonObject.veiculoCompA ?? {});
    this.veiculoCompB = new Veiculo(jsonObject.veiculoCompB ?? {});
    this.infoCarga = jsonObject.infoCarga
      ? jsonObject.infoCarga.map(
          (item) => new ColetaOrdemTransporte(item ?? {})
        )
      : [];
    this.especieFrete = new EspecieFrete(jsonObject.especieFrete ?? {});
    this.freteMercadoria = new FreteMercadoria(
      jsonObject.freteMercadoria ?? {}
    );
    this.tipoFrete = new TipoFrete(jsonObject.tipoFrete ?? {});
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
