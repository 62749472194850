import React, { useState } from 'react';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import {
  Button,
  Loader,
  Modal,
  Notification,
  Textbox,
  Autocomplete,
} from 'ui/components';

export default function ModalPrintDeclaracaoIR({
  show,
  onClose,
  infoDeclaraoIR,
  setInfoDeclaraoIR,
  onClickConfirm,
  onSearchConvenio,
}) {
  const [message, setMessage] = useState(null);

  const onConfirm = async () => {
    if (!infoDeclaraoIR.anoRef) {
      setMessage({
        message: 'Necessário preencher o ano de referência.',
        theme: Theme.Danger,
      });
    } else if (!infoDeclaraoIR.nrSeqConvenio) {
      setMessage({
        message: 'Necessário selecionar o convênio.',
        theme: Theme.Danger,
      });
    } else {
      onClickConfirm();

      onClose();
    }
  };

  return (
    <Modal
      show={show}
      title='Declaração Imposto de Renda'
      onClose={() => {
        onClose();
      }}
      size={BootstrapSizes.Large}
    >
      <Modal.Body className='p-3 pt-3'>
        <Loader />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='row justify-content-evenly mb-4'>
          <div className='col-4 '>
            <Textbox
              label='Ano de Referência'
              maxLength={4}
              text={infoDeclaraoIR?.anoRef}
              onChangedValue={(anoRef) =>
                setInfoDeclaraoIR({ ...infoDeclaraoIR, anoRef })
              }
            />
          </div>

          <div className='col-4'>
            <Autocomplete
              label='Convênio'
              searchDataSource={onSearchConvenio}
              selectedItem={infoDeclaraoIR?.convenio}
              onSelectItem={(convenio) =>
                setInfoDeclaraoIR({
                  ...infoDeclaraoIR,
                  convenio,
                  nrSeqConvenio: convenio.nrSeqConvenio,
                })
              }
              dataSourceTextProperty='noConvenio'
            />
          </div>
        </div>
        <div className='row justify-content-evenly mt-4'>
          <div className='col-2 mt-4'>
            <Button
              outline
              theme={Theme.Success}
              template={Button.Templates.Button}
              text='Imprimir'
              onClick={onConfirm}
            />
          </div>
          <div className='col-2 mt-4'>
            <Button
              outline
              text='Cancelar'
              theme={Theme.Danger}
              onClick={() => {
                onClose();
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
