/* eslint-disable import/extensions */
import { Cliente } from '../FIN';
import { Empresa, Usuario } from '../SEG';
import AreaChamado from './areaChamado';
import ChamadoClassificacao from './chamadoClassificacao';
import ChamadoDocumento from './chamadoDocumento';
import ChamadoMovimentacao from './chamadoMovimentacao';
import PrioridadeChamado from './prioridadeChamado';
import SituacaoChamado from './situacaoChamado';
import TipoChamado from './tipoChamado';

export default class Chamado {
  nrSeqChamado: number;

  nrSeqUsuarioCad: number;

  nrSeqEmpresa: number;

  nrSeqProspect: number;

  nrSeqPessoaCli: number;

  nrSeqTipoChamado: number;

  nrSeqPrioridadeChamado: number;

  nrSeqSituacaoChamado: number;

  nrSeqGrupoEmpresa: number;

  nrSeqTransacaoErro: number;

  nrSeqUsuarioOp: number;

  nrSeqChamadoCliente: number;

  nrSeqChamadoClassificacao: number;

  cdChamado: string;

  descricao: string;

  noContato: string;

  noTituloChamado: string;

  noBase: string;

  noUrlBase: string;

  noEmailContato: string;

  dtCad: Date;

  dtAbertura: Date;

  dtAtendimento: Date;

  dtFechamento: Date;

  dtPrazoConclusao: Date;

  tipoChamado: TipoChamado;

  areaChamado: AreaChamado;

  situacaoChamado: SituacaoChamado;

  empresa: Empresa;

  cliente: Cliente;

  prioridadeChamado: PrioridadeChamado;

  usuarioCad: Usuario;

  usuarioOp: Usuario;

  chamadoClassificacao: ChamadoClassificacao;

  chamadoDocumento: ChamadoDocumento[];

  chamadoMovimentacao: ChamadoMovimentacao[];

  chamadosFilhos: any[];

  chamadoDemanda: any;

  nrSeqChamadoPai: number;

  flgImportadoEmail: boolean;

  flgImportadoWhatsApp: boolean;

  flgPermiteEditar: boolean;

  constructor(jsonObject: Chamado) {
    this.nrSeqChamado = jsonObject.nrSeqChamado;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqProspect = jsonObject.nrSeqProspect;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.nrSeqTipoChamado = jsonObject.nrSeqTipoChamado;
    this.nrSeqPrioridadeChamado = jsonObject.nrSeqPrioridadeChamado;
    this.nrSeqSituacaoChamado = jsonObject.nrSeqSituacaoChamado;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqTransacaoErro = jsonObject.nrSeqTransacaoErro;
    this.nrSeqUsuarioOp = jsonObject.nrSeqUsuarioOp;
    this.nrSeqChamadoCliente = jsonObject.nrSeqChamadoCliente;
    this.nrSeqChamadoClassificacao = jsonObject.nrSeqChamadoClassificacao;
    this.cdChamado = jsonObject.cdChamado;
    this.descricao = jsonObject.descricao;
    this.noContato = jsonObject.noContato;
    this.noTituloChamado = jsonObject.noTituloChamado;
    this.noBase = jsonObject.noBase;
    this.noUrlBase = jsonObject.noUrlBase;
    this.noEmailContato = jsonObject.noEmailContato;
    this.dtCad = jsonObject.dtCad;
    this.dtAbertura = jsonObject.dtAbertura;
    this.dtAtendimento = jsonObject.dtAtendimento;
    this.dtFechamento = jsonObject.dtFechamento;
    this.dtPrazoConclusao = jsonObject.dtPrazoConclusao;
    this.tipoChamado = jsonObject.tipoChamado;
    this.areaChamado = jsonObject.areaChamado;
    this.situacaoChamado = jsonObject.situacaoChamado;
    this.empresa = jsonObject.empresa;
    this.cliente = jsonObject.cliente;
    this.prioridadeChamado = jsonObject.prioridadeChamado;
    this.usuarioCad = jsonObject.usuarioCad;
    this.usuarioOp = jsonObject.usuarioOp;
    this.chamadoClassificacao = jsonObject.chamadoClassificacao;
    this.chamadoDocumento = jsonObject.chamadoDocumento;
    this.chamadoMovimentacao = jsonObject.chamadoMovimentacao;
    this.nrSeqChamadoPai = jsonObject.nrSeqChamadoPai;
    this.flgImportadoEmail = jsonObject.flgImportadoEmail;
    this.flgImportadoWhatsApp = jsonObject.flgImportadoWhatsApp;
    this.flgPermiteEditar = jsonObject.flgPermiteEditar;
    this.chamadoDemanda = jsonObject.chamadoDemanda;
    this.chamadosFilhos = jsonObject.chamadosFilhos;
  }

  toJson = () => JSON.stringify(this);
}
