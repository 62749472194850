import React, { useState, useRef, useCallback, useEffect } from 'react';
import { CSDManutPage, Notification } from 'ui/components';
import { PageTypes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';

// eslint-disable-next-line import/no-unresolved
import CustomerRegistration from 'ui/components/CustomerRegistration';
import {
  getVendedor,
  saveVendedor,
  deleteVendedor,
} from 'core/services/VEN/vendedor';
import { IVendedor } from 'core/interfaces/VEN';
// @ts-expect-error
import VendedorPanel from './vendedorPanel.tsx';
// @ts-expect-error
import PessoaReferenciaPanel from './pessoaReferenciaPanel.tsx';

interface IVendedorState extends IVendedor {
  noTipoTransporte?: string;
  noTipoTransportadora?: number;
}

export default function Vendedor({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit = useRef<any>();
  const customerRegistrationRef = useRef<any>(null);
  const pessoaReferencias = useRef<any>(null);
  const vendedorPanel = useRef<any>(null);

  const [vendedorData, setVendedorData] = useState<IVendedorState | any>({
    flgFisica: true,
    flgAtivo: true,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [warning, setWarning] = useState<Message | null>(null);

  const clearChildCustomer = () => {
    customerRegistrationRef?.current?.clear();
  };

  const clearPessoaReferenciasPanel = () => {
    pessoaReferencias?.current?.clear();
  };

  const fillPessoaReferenciasPanel = () => {
    pessoaReferencias?.current?.fillGrid();
  };

  const fillVendedorPanel = () => {
    vendedorPanel?.current?.fillGrid();
  };

  const clearVendedorPanel = () => {
    vendedorPanel?.current?.clear();
  };

  const getDataCustomer = () => customerRegistrationRef?.current?.getData();

  const getDataReferenciasGrid = () =>
    pessoaReferencias?.current?.getDataGrid();

  const onNew = (): void => {
    clearChildCustomer();
    clearPessoaReferenciasPanel();
    clearVendedorPanel();

    setVendedorData({
      flgFisica: true,
      flgAtivo: true,
      pessoa: {
        nrSeqPessoa: undefined,
      },
      vendedorReferencias: [],
      remetenteDestinatario: [],
    });
  };

  const searchById = async (nrSeqPessoa: any) => {
    if (nrSeqPessoa && Number.isInteger(nrSeqPessoa)) {
      setLoading(true);
      const retornoVendedor: IVendedor | any = await getVendedor(nrSeqPessoa);

      if ('nrSeqPessoaVen' in retornoVendedor) {
        setVendedorData({
          ...retornoVendedor,
          pessoa: { nrSeqPessoa: retornoVendedor.nrSeqPessoaVen },
        });
        if (
          retornoVendedor.vendedorReferencias &&
          retornoVendedor.vendedorReferencias.length > 0
        ) {
          fillPessoaReferenciasPanel();
        } else {
          clearPessoaReferenciasPanel();
        }
        if (
          (retornoVendedor.vendedorEquipes &&
            retornoVendedor.vendedorEquipes.length > 0) ||
          (retornoVendedor.vendedorEstados &&
            retornoVendedor.vendedorEstados.length > 0)
        ) {
          fillVendedorPanel();
        } else {
          clearVendedorPanel();
        }
      }
    } else {
      onNew();
    }

    setMessage(null);
  };

  const load = useCallback(async () => {
    await searchById(registryKey);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const onSetMessage = (status: ResponseStatus, msg: string = ''): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const save = async () => {
    setLoading(true);
    const pessoa = getDataCustomer();

    const referencias = getDataReferenciasGrid();

    if (referencias !== undefined && referencias.length > 0) {
      referencias.forEach((referencia) => {
        if (referencia.flgPessoal === 'Não') {
          referencia.flgPessoal = false;
        }
      });
    }
    const { status, message: msg, value } = await saveVendedor({
      ...vendedorData,
      ...pessoa,
      flgFisica: pessoa.flgFisica,
      vendedorReferencias: referencias,
    });

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    const text = value && value.length > 0 ? value.join() : null;

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();

      setWarning({ message: text, theme: Theme.Warning });
      onNew();
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteVendedor(registryKey);

      setVendedorData(status === ResponseStatus.Success ? {} : vendedorData);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Vendedor'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      onDelete={vendedorData?.nrSeqPessoaVen > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {warning && (
        <div className='mb-3'>
          {/* @ts-expect-error */}
          <Notification
            message={warning.message}
            theme={warning.theme}
            onClose={() => setWarning(null)}
          />
        </div>
      )}
      <div className='row mt-2'>
        <div className='col mb-2'>
          <CustomerRegistration
            nrSeqPessoa={vendedorData.pessoa?.nrSeqPessoa}
            data={vendedorData}
            setData={setVendedorData}
            setLoading={setLoading}
            onSetMessage={onSetMessage}
            config={{
              panelTitle: 'Informações Vendedor',
            }}
            getDataOrigem={searchById}
            ref={customerRegistrationRef}
          >
            {[
              <>
                <div className='row mb-2'>
                  <div className='col'>
                    <VendedorPanel
                      vendedorData={vendedorData}
                      setVendedorData={setVendedorData}
                      onSetMessage={onSetMessage}
                      ref={vendedorPanel}
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col'>
                    <PessoaReferenciaPanel
                      vendedorData={vendedorData}
                      setVendedorData={setVendedorData}
                      onSetMessage={onSetMessage}
                      ref={pessoaReferencias}
                    />
                  </div>
                </div>
              </>,
            ]}
          </CustomerRegistration>
        </div>
      </div>
    </CSDManutPage>
  );
}
