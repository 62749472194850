import React, {
  forwardRef,
  ForwardedRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';
import { Modal, Loader, FileUpload, Button, Notification } from 'ui/components';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/enums';
import { Message } from 'ui/Helpers/interfaces';
import { MimeTypes } from 'ui/Helpers/utils';

import { importCteSubcontratado } from 'core/services/FRO/viagem';

import { ColetaEmbarque } from 'core/models/FRO';

interface Props {
  show: boolean;
  onClose: () => void;
  onImportaCte: (cte: ColetaEmbarque) => void;
}

const ModalImportaSubcontratado = forwardRef(
  ({ show, onClose, onImportaCte }: Props, param: ForwardedRef<any>) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<Message | null>(null);
    const [files, setFiles] = useState<Array<any>>([]);

    useImperativeHandle(param, () => ({
      param: files,
    }));

    const load = () => {
      setLoading(true);

      if (show) {
        setFiles([]);
      }

      setLoading(false);
    };

    useEffect(() => {
      load();
    }, [show]);

    const onSend = () => {
      onClose();
    };

    const importaCte = async () => {
      setLoading(true);

      if (files.length > 0) {
        const {
          status,
          message: msg,
          data: newCte,
        } = await importCteSubcontratado(files[0]);

        if (msg)
          setMessage({
            message: msg,
            theme:
              status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
          });

        if (status === ResponseStatus.Success) onImportaCte(newCte);
      }

      setLoading(false);
    };

    return (
      // @ts-expect-error
      <Modal
        show={show}
        title='Importar CT-e Subcontratado'
        icon='cloud-upload-alt'
        size={BootstrapSizes.Large}
        onClose={() => onSend()}
      >
        <Loader loading={loading} />
        {message && (
          // @ts-expect-error
          <Notification message={message.message} theme={message.theme} />
        )}
        {/* @ts-expect-error */}
        <Modal.Body>
          <div className='row'>
            <span>
              *Importe o <b>XML</b> do CT-e Subcontratado.
            </span>
          </div>
          <div className='row p-2'>
            <FileUpload
              files={files}
              onChange={(items: any) => {
                setFiles(items);
              }}
              allowedMimeTypes={[MimeTypes.Types.XML]}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            text='Importar'
            icon='cloud-upload-alt'
            theme={Theme.Success}
            size={BootstrapSizes.Medium}
            onClick={importaCte}
          />
          <Button
            text='Sair'
            icon={['fas', 'times']}
            theme={Theme.Danger}
            size={BootstrapSizes.Medium}
            onClick={() => onSend()}
          />
        </Modal.Footer>
      </Modal>
    );
  }
);

export default ModalImportaSubcontratado;
