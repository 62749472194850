import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Modal, FileUpload, Button, Loader, Notification } from 'ui/components';
import { BootstrapSizes, Theme, MimeTypes } from 'ui/Helpers/utils';
import { postImportNf } from 'core/services/COP/nf';

const ModalImportarNF = forwardRef(({ props, show, onClose }, param) => {
  const [loading, setLoading] = useState(false);
  const [importedFile, setImportedFile] = useState([]);
  const [message, setMessage] = useState(null);

  useImperativeHandle(param, () => ({
    param: importedFile,
  }));

  const importFile = async () => {
    setLoading(true);

    if (importedFile.length > 0) {
      const { status, message: msg, nota } = await postImportNf(
        importedFile[0]
      );

      onClose(nota, msg, status);
      setImportedFile([]);
    } else {
      setMessage({
        message: 'Não há arquivos selecionados para importação.',
        theme: Theme.Danger,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (show) setImportedFile([]);
  }, [show, props]);

  const onSend = () => {
    onClose();
  };

  return (
    <Modal
      show={show}
      title='Importar XML NFE'
      onClose={() => onSend()}
      size={BootstrapSizes.Large}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='row mb-3'>
            <div className='col'>
              <FileUpload
                files={importedFile}
                onChange={(files) => {
                  setImportedFile(files);
                  setMessage(null);
                }}
                allowedMimeTypes={[MimeTypes.Types.XML]}
                dropzone
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Small}
          icon='cloud-upload-alt'
          theme={Theme.Success}
          template={Button.Templates.Default}
          onClick={importFile}
          text='Importar'
        />
        <Button
          size={BootstrapSizes.Small}
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          onClick={() => onSend()}
          text='Sair'
        />
      </Modal.Footer>
    </Modal>
  );
});

export default ModalImportarNF;
