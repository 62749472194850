import React, { useRef, useState, useEffect } from 'react';
import { setTransaction } from 'core/services/api';

import {
  Autocomplete,
  Button,
  DatePicker,
  GridView,
  Modal,
  Notification,
  Switch,
  Textbox,
  RadioButton,
} from 'ui/components';
import {
  Theme,
  BootstrapSizes,
  formatCurrentDate,
  ResponseStatus,
} from 'ui/Helpers/utils';
import { mask, MaskTypes } from 'ui/Helpers/masks';
import {
  getBancoAutoComplete,
  getFormaPagamentoAutoComplete,
} from 'core/services/FIN';
import {
  postCalculaJurosMultaMassivo,
  receberPagamento,
  validaTitulosAdiantamentos,
  postCalculaDescontoTitulosAdiantamentos,
} from 'core/services/FIN/tituloReceber';

function RenderTextMask(props) {
  const { label, value, masktype } = props;
  return (
    <div className='col-auto mt-3'>
      <div className='fw-bold'>
        {label}: {mask(value ?? 0, masktype ?? MaskTypes.Currency)}
      </div>
    </div>
  );
}
export default function ModalrecebimentoMassivo(props) {
  const [data, setData] = useState({
    receberParcial: false,
    flgTemAdtos: false,
    flgAplicaDescontoAdtos: 'N',
  });
  const [loading, setLoading] = useState(false);
  const [somado, setSomado] = useState();
  const [messageState, setMessage] = useState(null);
  const gridView = useRef();
  const gridView2 = useRef();

  const { show, recebimentoMassivo, transaction } = props;

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  useEffect(async () => {
    if (recebimentoMassivo !== undefined) {
      let sum = 0;
      let saldo = 0;
      let juro = 0;
      let multa = 0;

      const { data: titulosAdtos } = await validaTitulosAdiantamentos(
        recebimentoMassivo
      );

      for (let i = 0; i < recebimentoMassivo.length; ) {
        saldo += recebimentoMassivo[i].vlrSaldoTit;
        sum += recebimentoMassivo[i].vlrTitulo;
        juro += recebimentoMassivo[i].vlrJuroRecebido;
        multa += recebimentoMassivo[i].vlrMultaRecebida;
        recebimentoMassivo[i].vlrRecebido = recebimentoMassivo[i].vlrSaldoTit;
        i += 1;
      }

      setData({
        ...data,
        receberParcial: false,
        dtVencimento: recebimentoMassivo[0].dtVencimento,
        vlrTitulo: sum,
        vlrTotalaReceber: saldo,
        vlrPendente: saldo,
        vlrSaldoTit: saldo,
        vlrDesconto: 0,
        vlrMultas: multa,
        vlrJuros: juro,
        vlrJuroCalculado: 0,
        vlrMultaCalculado: 0,
        formaPagamento: recebimentoMassivo[0].formaPagamento,
        nrSeqFormaPagamento: recebimentoMassivo[0].nrSeqFormaPagamento,
        dtRecebimento: formatCurrentDate(),
        vlrDescontoAdtos: 0,
        flgTemAdtos: titulosAdtos.length > 0,
      });

      gridView?.current?.setDataSource(recebimentoMassivo);
    }
  }, [show]);

  useEffect(() => {
    if (data.receberParcial === true) {
      gridView?.current?.setDataSource(null);
      gridView2?.current?.setDataSource(recebimentoMassivo);
    } else {
      gridView?.current?.setDataSource(recebimentoMassivo);
      gridView2?.current?.setDataSource(null);
    }
  }, [data.receberParcial]);

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitRec: true,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };
  const onSearchBanco = async (e) => {
    const { status, message: msg, bancos } = await getBancoAutoComplete({
      noFormaPagamento: e,
    });
    if (msg) onSetMessage(status, msg);
    return bancos;
  };

  const salvar = async () => {
    let lista = [];
    if (data.receberParcial === true) {
      lista = gridView2.current ? gridView2.current.getDataSource() : [];
    } else {
      lista = gridView.current ? gridView.current.getDataSource() : [];
    }
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);
    const obj = {
      ...data,
      nrSeqTransacao: transaction.nrSeqTransacao,
      tituloReceberS: lista,
      flgReceberDescontoAdtos: data.flgAplicaDescontoAdtos === 'S',
      vlrDesconto: data.vlrDesconto + data.vlrDescontoAdtos,
    };
    const retorno = await receberPagamento(obj);

    setData(retorno.data.status === ResponseStatus.Success ? {} : data);

    if (retorno.data.status === ResponseStatus.Error) {
      setMessage({
        message: retorno.data.message,
        theme:
          retorno.data.status === ResponseStatus.Success
            ? Theme.Success
            : Theme.Danger,
      });
    }
    setLoading(false);

    if (retorno.data.status === ResponseStatus.Success) {
      if (retorno.data.message !== 'Recebimento salvo com sucesso.') {
        props.imprimirRecibo(retorno.data.message);
      } else {
        props.onClose({
          message: retorno.data.message,
          theme:
            retorno.data.status === ResponseStatus.Success
              ? Theme.Success
              : Theme.Danger,
        });
      }
    }
  };

  const cancelar = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
    });
  };

  const columns = [
    {
      key: 'nrTit',
      title: 'Nr Tit',
    },
    {
      key: 'nrOrdem',
      title: 'Nr Ordem',
    },
    {
      key: 'cliente.noPessoa',
      title: 'Cliente',
    },
    {
      key: 'nrTit',
      title: 'Documento',
    },
    {
      key: 'tituloEspecie.noEspecie',
      title: 'Receita',
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor Título',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrRecebido',
      title: 'Recebido',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrSaldoTit',
      title: 'Saldo',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrJuroRecebido',
      title: 'Juros',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMultaRecebida',
      title: 'Multa',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'dtVencimento',
      title: 'Data Vencimento',
      format: GridView.DataTypes.Date,
    },
  ];

  const vlrSomaTotalRecebido = async (datasource) => {
    let vlrSomadoRecebido = 0;

    if (datasource !== null && datasource !== undefined) {
      datasource.forEach((item) => {
        if (item.vlrRecebido > 0) {
          vlrSomadoRecebido += item.vlrRecebido;
        } else {
          item.vlrRecebido = 0;
        }
        if (item.vlrMultaCalculado > 0) {
          vlrSomadoRecebido += item.vlrMultaCalculado;
        } else {
          item.vlrMultaCalculado = 0;
        }
        if (item.vlrJuroCalculado > 0) {
          vlrSomadoRecebido += item.vlrJuroCalculado;
        } else {
          item.vlrJuroCalculado = 0;
        }
        if (item.vlrDescontoCalculado > 0) {
          vlrSomadoRecebido -= item.vlrDescontoCalculado;
        } else {
          item.vlrDescontoCalculado = 0;
        }
      });
    }

    setSomado({ vlrSomadoAPagar: vlrSomadoRecebido });
  };

  const saveReceber = async (keyValue, value) => {
    const datasource = gridView2.current
      ? gridView2.current.getDataSource()
      : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTituloReceber === keyValue
    );
    itemParcela.vlrRecebido = value;

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].nrSeqTituloReceber === itemParcela.nrSeqTituloReceber) {
        datasource[i].vlrRecebido = itemParcela.vlrRecebido;
      }
      i += 1;
    }

    vlrSomaTotalRecebido(datasource);

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(datasource);
  };
  const saveMulta = async (keyValue, value) => {
    const datasource = gridView2.current
      ? gridView2.current.getDataSource()
      : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTituloReceber === keyValue
    );
    itemParcela.vlrMultaCalculado = value;

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].nrSeqTituloReceber === itemParcela.nrSeqTituloReceber) {
        datasource[i].vlrMultaCalculado = itemParcela.vlrMultaCalculado;
      }
      i += 1;
    }

    vlrSomaTotalRecebido(datasource);

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(datasource);
  };
  const saveJuro = async (keyValue, value) => {
    const datasource = gridView2.current
      ? gridView2.current.getDataSource()
      : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTituloReceber === keyValue
    );
    itemParcela.vlrJuroCalculado = value;

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].nrSeqTituloReceber === itemParcela.nrSeqTituloReceber) {
        datasource[i].vlrJuroCalculado = itemParcela.vlrJuroCalculado;
      }
      i += 1;
    }

    vlrSomaTotalRecebido(datasource);

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(datasource);
  };
  const saveDesconto = async (keyValue, value) => {
    const datasource = gridView2.current
      ? gridView2.current.getDataSource()
      : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTituloReceber === keyValue
    );
    itemParcela.vlrDescontoCalculado = value;

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].nrSeqTituloReceber === itemParcela.nrSeqTituloReceber) {
        datasource[i].vlrDescontoCalculado = itemParcela.vlrDescontoCalculado;
      }
      i += 1;
    }

    vlrSomaTotalRecebido(datasource);

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(datasource);
  };

  const fsaveReceber = function (keyValue, value) {
    saveReceber(keyValue, value);
  };
  const fsaveMulta = function (keyValue, value) {
    saveMulta(keyValue, value);
  };
  const fsaveJuro = function (keyValue, value) {
    saveJuro(keyValue, value);
  };
  const fsaveDesconto = function (keyValue, value) {
    saveDesconto(keyValue, value);
  };
  const columns2 = [
    {
      key: 'nrSeqTituloReceber',
      visible: false,
    },
    {
      key: 'nrTit',
      title: 'Nr Tit',
    },
    {
      key: 'dtVencimento',
      title: 'Data Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'cliente.noPessoa',
      title: 'Cliente',
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor Do Titulo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrSaldoTit',
      title: 'Valor Saldo',
      format: GridView.DataTypes.Decimal,
    },

    {
      key: 'vlrRecebido',
      title: 'Vlr Recebido',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fsaveReceber,
      maxLength: 10,
    },
    {
      key: 'vlrMultaCalculado',
      title: 'Vlr Multa',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fsaveMulta,
      maxLength: 10,
    },
    {
      key: 'vlrJuroCalculado',
      title: 'Vlr Juro',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fsaveJuro,
      maxLength: 10,
    },
    {
      key: 'vlrDescontoCalculado',
      title: 'Vlr desconto',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fsaveDesconto,
      maxLength: 10,
    },
  ];

  const RecalculaValores = async () => {
    if (data.vlrJuroCalculado === undefined) {
      data.vlrJuroCalculado = 0;
    }
    if (data.vlrMultaCalculado === undefined) {
      data.vlrMultaCalculado = 0;
    }
    if (data.vlrDesconto === undefined) {
      data.vlrDesconto = 0;
    }

    const valores =
      data.vlrJuroCalculado +
      data.vlrMultaCalculado -
      data.vlrDesconto -
      data.vlrDescontoAdtos;

    const vlrareceber = data.vlrSaldoTit + valores;

    setData({
      ...data,
      vlrTotalaReceber: vlrareceber,
    });
  };

  const onCalcularJuroMulta = async () => {
    const newData = {
      ...data,
    };
    const retorno = await postCalculaJurosMultaMassivo(newData);
    if (retorno.status === ResponseStatus.Error)
      onSetMessage(retorno.status, retorno.message);
    else {
      if (retorno.value.vlrJuroCalculado === undefined) {
        retorno.value.vlrJuroCalculado = 0;
      }
      if (retorno.value.vlrMultaCalculado === undefined) {
        retorno.value.vlrMultaCalculado = 0;
      }
      if (retorno.value.vlrDesconto === undefined) {
        retorno.value.vlrDesconto = 0;
      }

      const valores =
        retorno.value.vlrJuroCalculado +
        retorno.value.vlrMultaCalculado -
        retorno.value.vlrDesconto -
        data.vlrDescontoAdtos;

      const vlrareceber = data.vlrTitulo + valores;

      setData({
        ...data,
        vlrJuroCalculado: retorno.value.vlrJuroCalculado,
        vlrMultaCalculado: retorno.value.vlrMultaCalculado,
        vlrDesconto: retorno.value.vlrDesconto,
        vlrTotalaReceber: vlrareceber,
      });
    }
  };

  const aplicaDesconto = [
    { text: 'Sim', value: 'S' },
    { text: 'Não', value: 'N' },
  ];

  return (
    <Modal
      loading={loading}
      show={show}
      title='Recebimento Massivo'
      size={BootstrapSizes.ExtraLarge}
      icon='money'
    >
      <Modal.Body className='p-4 pt-3'>
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        {!data.receberParcial && (
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        )}
        {data.receberParcial && (
          <div className='col'>
            <GridView
              ref={gridView2}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns2}
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        )}
        <div className='row'>
          <div className='col-sm-12'>
            <div className='alert alert-info fs-8' role='alert'>
              <div className='row'>
                <div className='col-4 mb-3 float-right'>
                  <RenderTextMask label='Valor Total' value={data?.vlrTitulo} />
                </div>
                <div className='col-4 mb-3 float-right'>
                  <RenderTextMask
                    label='Saldo Total'
                    value={data?.vlrSaldoTit}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-12 mb-3 float-right'>
                  Os valores de encargos acima mostrados, já estão inclusos no
                  saldo a receber, portanto só deve-se informar os campos de
                  encargos abaixo, caso queira receber encargos além dos ja
                  informados. Os valores de multa e juros serão rateados entre
                  os titulos vencidos proporcionalmente ao valor do titulo.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-2 mb-3'>
            <Switch
              formControl
              label='Receber Parcial'
              checked={data.receberParcial}
              onChange={(receberParcial) =>
                setData({ ...data, receberParcial })
              }
            />
          </div>
          {!data.receberParcial && data?.flgTemAdtos && (
            <div className='col-2 d-flex flex-column align-items-start flex-wrap-nowrap'>
              <RadioButton
                label='Desconto Adtos'
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={data.flgAplicaDescontoAdtos}
                buttons={aplicaDesconto}
                onChange={async (flgAplicaDescontoAdtos) => {
                  if (flgAplicaDescontoAdtos === 'S') {
                    const {
                      value: descontoAdtos,
                    } = await postCalculaDescontoTitulosAdiantamentos(
                      recebimentoMassivo
                    );

                    const totalReceberComDesconto =
                      data.vlrPendente +
                      data.vlrJuroCalculado +
                      data.vlrMultaCalculado -
                      data.vlrDesconto -
                      descontoAdtos;

                    setData({
                      ...data,
                      flgAplicaDescontoAdtos,
                      vlrDescontoAdtos: descontoAdtos,
                      vlrTotalaReceber: totalReceberComDesconto,
                    });
                  } else {
                    const totalReceberSemDesconto =
                      data.vlrPendente +
                      data.vlrJuroCalculado +
                      data.vlrMultaCalculado -
                      data.vlrDesconto;

                    setData({
                      ...data,
                      flgAplicaDescontoAdtos,
                      vlrDescontoAdtos: 0,
                      vlrTotalaReceber: totalReceberSemDesconto,
                    });
                  }
                }}
              />
            </div>
          )}
        </div>

        <div className='row'>
          <div className='col-3 mb-3'>
            <DatePicker
              label='Dt Recebimento'
              text={data?.dtRecebimento}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtRecebimento) => setData({ ...data, dtRecebimento })}
            />
          </div>
          <div className='col-6 mb-3'>
            <Autocomplete
              label='Forma Recebimento'
              searchDataSource={onSearchFormaPagamento}
              required
              selectedItem={data?.formaPagamento}
              onSelectItem={(formaPagamento) => {
                setData({
                  ...data,
                  formaPagamento,
                  nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                });
              }}
              dataSourceTextProperty='noFormaPagamento'
            />
          </div>
          <div className='col-3 mt-3'>
            <Button
              text='Calcular Juros/Multa'
              theme={Theme.Primary}
              template={Button.Templates.Button}
              tooltip='Calcular Juros/Multa conforme configuração da Forma Pagamento/Recebimento'
              onClick={() => onCalcularJuroMulta()}
            />
          </div>
        </div>
        <div className='row'>
          {data?.formaPagamento?.flgGrupo === 'C' && (
            <div className='col-4 mb-3'>
              <DatePicker
                label='Data Cheque'
                required
                text={data?.dtCheque}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtCheque) => setData({ ...data, dtCheque })}
              />
            </div>
          )}
          {data?.formaPagamento?.flgGrupo === 'C' &&
            !data?.formaPagamento?.flgControlaCheque && (
              <div className='col-4 mb-3'>
                <Textbox
                  label='Nr. Cheque'
                  text={data?.nrCheque}
                  onChangedValue={(nrCheque) => setData({ ...data, nrCheque })}
                />
              </div>
            )}
          {data?.formaPagamento?.flgGrupo === 'C' && (
            <div className='col-4 mb-3'>
              <Autocomplete
                label='Banco'
                searchDataSource={onSearchBanco}
                required
                selectedItem={data?.banco}
                onSelectItem={(banco) => {
                  setData({
                    ...data,
                    banco,
                    nrSeqBanco: banco.nrSeqBanco,
                  });
                }}
                dataSourceTextProperty='noBanco'
              />
            </div>
          )}
        </div>
        <div className='row mb-3'>
          {data?.receberParcial && (
            <div className='row'>
              <div className='col-4 mb-3'>
                <Textbox
                  label='Valor somado a pagar'
                  readOnly
                  text={somado?.vlrSomadoAPagar}
                  mask={MaskTypes.DecimalCustom}
                />
              </div>
            </div>
          )}
          {!data.receberParcial && (
            <div className='col-2 mb-3'>
              <Textbox
                label='Valor Pendente'
                readOnly
                text={data?.vlrPendente}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrPendente) =>
                  setData({ ...data, vlrPendente })
                }
              />
            </div>
          )}
          {!data.receberParcial && (
            <div className='col-2 mb-3'>
              <Textbox
                label='Valor Multa'
                text={data?.vlrMultaCalculado}
                onBlur={RecalculaValores}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrMultaCalculado) =>
                  setData({ ...data, vlrMultaCalculado })
                }
              />
            </div>
          )}
          {!data.receberParcial && (
            <div className='col-2 mb-3'>
              <Textbox
                label='Valor Juros'
                text={data?.vlrJuroCalculado}
                onBlur={RecalculaValores}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrJuroCalculado) =>
                  setData({ ...data, vlrJuroCalculado })
                }
              />
            </div>
          )}
          {!data.receberParcial && (
            <div className='col-2 mb-3'>
              <Textbox
                label='Valor Desconto'
                text={data?.vlrDesconto}
                mask={MaskTypes.DecimalCustom}
                onBlur={RecalculaValores}
                onChangedValue={(vlrDesconto) => {
                  const vlrDescontoTotal = vlrDesconto + data.vlrDescontoAdtos;
                  const totalReceberComDesconto =
                    data.vlrTotalaReceber - vlrDescontoTotal;
                  setData({
                    ...data,
                    vlrDesconto,
                    vlrTotalaReceber: totalReceberComDesconto,
                  });
                }}
              />
            </div>
          )}
          {!data.receberParcial && data?.flgAplicaDescontoAdtos === 'S' && (
            <div className='col-2 mb-3'>
              <Textbox
                label='Desconto Adtos'
                readOnly
                text={data?.vlrDescontoAdtos}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrDescontoAdtos) =>
                  setData({ ...data, vlrDescontoAdtos })
                }
              />
            </div>
          )}
          {!data.receberParcial && (
            <div className='col-2 mb-3'>
              <Textbox
                label='Total a receber'
                readOnly
                text={data?.vlrTotalaReceber}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrTotalaReceber) =>
                  setData({ ...data, vlrTotalaReceber })
                }
              />
            </div>
          )}
        </div>
        <div className='row'>
          <div className='col-12 mb-3'>
            <Textbox
              label='Obs'
              text={data?.noObs}
              onChangedValue={(noObs) => setData({ ...data, noObs })}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-4 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Receber Titulo'
              onClick={salvar}
            />
          </div>
          <div className='col-2 mt-3'>
            <Button
              theme={Theme.Danger}
              template={Button.Templates.Button}
              text='Cancelar'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
