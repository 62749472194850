import qs from 'qs';
import Pagination from 'core/models/pagination';
import OrdemServicoProd from 'core/models/FIN/ordemServicoProd';
import api from '../api';

const baseURL = `/FIN/OrdemServicoProd`;

export const getOrdemServicoProdList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new OrdemServicoProd(json)) ?? [],
  };
};

export const getOrdemServicoProdPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new OrdemServicoProd(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getOrdemServicoProd = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new OrdemServicoProd(value) : {};
};

export const saveOrdemServicoProd = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteOrdemServicoProd = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printOrdemServicoProd = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelOrdemServicoProd = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllOrdemServicoProd = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
