import { geraBoleto } from 'core/services/HELP';
import React, { useEffect, useState } from 'react';
import { MaskTypes } from 'ui/Helpers/masks';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { Button, Loader, Modal, Notification, Textbox } from 'ui/components';

export default function ModalGeraBoleto({
  show,
  onClose,
  data,
  setData,
  transaction,
  onOpenReport,
  dataMassivo,
}) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [boletoData, setBoletoData] = useState({});
  const [qtdTotalChip, setQtdTotalChips] = useState(0);

  const onHandleClose = () => {
    setBoletoData({});
    setLoading(false);
    setMessage(null);
    onClose();
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onGerarBoleto = async () => {
    if (!loading) {
      setLoading(true);

      const { status, message: msg, demanda } = await geraBoleto(data);

      if (status === ResponseStatus.Success) {
        onSetMessage(status, msg);

        setData(demanda);
        onOpenReport(transaction.noTransacao, demanda.documento);

        onHandleClose();
      } else {
        onSetMessage(status, msg);
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    setData({
      ...data,
      vlrVenda:
        qtdTotalChip * (boletoData.valorChip ?? 0) +
        (boletoData.valorFrete ?? 0),
    });
  }, [boletoData.valorChip, boletoData.valorFrete]);

  useEffect(() => {
    const total = dataMassivo.reduce((acc, curr) => acc + curr.qtdChips, 0);

    setQtdTotalChips(total);
  }, [show]);

  return (
    <Modal
      show={show}
      title='Gerar Boleto'
      onClose={() => onHandleClose()}
      size={BootstrapSizes.ExtraLarge}
      icon={['far', 'comment-alt']}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col'>
            <Textbox label='Quantidade de Chips' text={qtdTotalChip} readOnly />
          </div>
          <div className='col'>
            <Textbox
              label='Valor por Chip'
              maxLength={20}
              text={boletoData.valorChip}
              mask={MaskTypes.Currency}
              onChangedValue={(valorChip) => {
                setBoletoData({ ...boletoData, valorChip });
              }}
            />
          </div>
          <div className='col'>
            <Textbox
              label='Valor Frete'
              maxLength={20}
              text={boletoData.valorFrete}
              mask={MaskTypes.Currency}
              onChangedValue={(valorFrete) => {
                setBoletoData({ ...boletoData, valorFrete });
              }}
            />
          </div>
          <div className='col'>
            <Textbox
              label='Valor Total'
              maxLength={20}
              readOnly
              disabledTabIndex
              mask={MaskTypes.Currency}
              text={data.vlrVenda}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Gerar Boleto'
          theme={Theme.Success}
          onClick={onGerarBoleto}
        />

        <Button
          text='Fechar'
          theme={Theme.Danger}
          onClick={() => {
            onHandleClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
