import React, { useEffect, useRef } from 'react';
import { Theme } from 'ui/Helpers/utils';
import { GridView, Panel } from 'ui/components';

export default function LinhasInconsistenciaDeValorPagoOperadora({ param }) {
  const gridView = useRef();

  const columns = [
    {
      key: 'vlrPagoOperadora',
      title: 'VlrPagoOperadora',
    },
    {
      key: 'vlrOriginalCalculado',
      title: 'VlrOriginalCalculado',
    },
    {
      key: 'vlrDiferenca',
      title: 'VlrDiferenca',
    },
  ];

  useEffect(() => {
    if (gridView && gridView.current) gridView.current.setDataSource(param);
  }, []);

  return (
    <div className='row my-3'>
      <Panel>
        <Panel.Header
          title='O Valor Pago Operadora está diferente do Valor Original Calculado'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col'>
              <GridView
                ref={gridView}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columns}
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </div>
  );
}
