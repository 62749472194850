import ArquivoBancario from './arquivoBancario';
import FormaPagamento from './formaPagamento';
import TituloReceberFatura from './tituloReceberFatura';

export default class TituloReceberFaturaOcorrencia {
  constructor(jsonObject = {}) {
    this.nrSeqTituloReceberFatura = jsonObject.nrSeqTituloReceberFatura;
    this.nrSeqTipoOcorrenciaBoleto = jsonObject.nrSeqTipoOcorrenciaBoleto;
    this.nrSeqArquivoBancario = jsonObject.nrSeqArquivoBancario;
    this.dtOcorrencia = jsonObject.dtOcorrencia;
    this.noOcorrencia = jsonObject.noOcorrencia;
    this.numeroDocumento = jsonObject.numeroDocumento;
    this.vlrTitulo = jsonObject.vlrTitulo;
    this.vlrPago = jsonObject.vlrPago;
    this.vlrJuro = jsonObject.vlrJuro;
    this.nossoNumero = jsonObject.nossoNumero;
    this.vlrDesconto = jsonObject.vlrDesconto;
    this.nrArquivoBancario = jsonObject.nrArquivoBancario;
    this.arquivoBancario = new ArquivoBancario(
      jsonObject.arquivoBancario ?? {}
    );
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.tituloReceberFatura = new TituloReceberFatura(
      jsonObject.tituloReceberFatura ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
