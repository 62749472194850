export default class TransacaoPagina {
  constructor(jsonObject = {}) {
    this.nrSeqTransacaoPagina = jsonObject.nrSeqTransacaoPagina;
    this.noPaginaWeb = jsonObject.noPaginaWeb;
    this.flgPrincipal = jsonObject.flgPrincipal;
    this.noPaginaWebRet = jsonObject.noPaginaWebRet;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqTransacao = jsonObject.nrSeqTransacao;
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
