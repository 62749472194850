import React, { useRef, useEffect, useState } from 'react';
import api from 'core/services/api';
import { Loader } from 'ui/components';

export default function OldPage({
  path,
  reload,
  resize,
  name,
  sourcePath,
  register = [],
  registryKey = '',

  className,
  parameters = [],
}) {
  const iframeRef = useRef();
  const [iframeHeight, setIframeHeight] = useState(0);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(0);

  const onAuthorizeFrame = async () => {
    const response = await api.get('/Usuario/GetRefreshToken');
    setToken(response.data?.state);
  };

  const ResizeIframe = () => {
    if (iframeRef?.current?.contentWindow?.document?.body?.scrollHeight)
      setIframeHeight(
        `${iframeRef.current.contentWindow.document.body.scrollHeight}px`
      );
  };

  const finishedOpen = () => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  let src = `${path.replace('../Sistema', '').replace(sourcePath, '')}`;
  if (src.indexOf('FRWRK') < 0) src = `${sourcePath}/${src}`;

  const host = window.location.host.split('.')[0];
  let url = '';

  url = `/old?AutoLogin=true&Token=${encodeURIComponent(
    token
  )}&host=${host}&mvcproject=true&PaginaRedir=${encodeURIComponent(
    `Sistema/${src}`
  )}`;

  let registry = false;

  if (registryKey && registryKey !== '' && register !== []) {
    const pkey = registryKey[0].toUpperCase() + registryKey.slice(1);
    url += `?${registryKey}=${register[registryKey]}&FormMode=Edit&PKey=${pkey}`;
    registry = true;
  } else if (parameters.length > 0) {
    url += '?';
  }

  if (parameters.length > 0) {
    parameters.forEach((item) => {
      if (registry) url += '&';
      url += `${item.name}=${item.value}`;
    });
  }

  useEffect(async () => {
    setLoading(true);
    await onAuthorizeFrame();
  }, [path, register[registryKey]]);

  useEffect(() => {
    iframeRef?.current?.contentWindow?.location?.reload(true);

    ResizeIframe();
  }, [reload]);

  useEffect(() => {
    ResizeIframe();
  }, [resize]);

  return (
    <>
      <Loader loading={loading} />
      {token && (
        <iframe
          ref={iframeRef}
          className={`iframe-old-pages ${className ?? 'd-active'}`}
          name={name}
          src={url}
          onLoad={() => {
            ResizeIframe();
            finishedOpen();
          }}
          title={name}
          style={{ height: iframeHeight }}
          id={Date.now()}
        />
      )}
    </>
  );
}
