import qs from 'qs';
import Pagination from 'core/models/pagination';
import VeiculoModelo from 'core/models/FRO/veiculoModelo';
import api from '../api';

const baseURL = `/FRO/VeiculoModelo`;

export const getVeiculoModeloAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new VeiculoModelo(json)) ?? [],
  };
};

export const getVeiculoModeloList = async (filters) => {
  const response = await api.get(`/$VeiculoModelo/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new VeiculoModelo(json)) ?? [],
  };
};

export const getVeiculoModeloPaginated = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPaginated`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new VeiculoModelo(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getVeiculoModelo = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new VeiculoModelo(value) : {};
};

export const saveVeiculoModelo = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteVeiculoModelo = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printVeiculoModelo = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelVeiculoModelo = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllVeiculoModelo = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
