import React, { useRef, useState } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  CSDSelPage,
  GridView,
  Autocomplete,
  RadioButton,
  DatePicker,
  Panel,
  ToolbarButtons,
} from 'ui/components';
import {
  getRequisicaoMovEstoquePagined,
  printRequisicaoMovEstoque,
  deleteAllRequisicaoMovEstoque,
  excelRequisicaoMovEstoque,
} from 'core/services/EST/requisicaoMovEstoque';
import { getProdutoGrupoAutoComplete } from 'core/services/EST/produtoGrupo';
import { getProdutoSubGrupoAutoComplete } from 'core/services/EST/produtoSubGrupo';
import { getProdutoAgrupadorAutoComplete } from 'core/services/EST/produtoAgrupador';
import { getAlmoxarifadoAutoComplete } from 'core/services/EST/almoxarifado';
import { getFuncionarioAutoComplete } from 'core/services/SEG/funcionario';

export default function RequisicaoMovEstoque({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({ noStatus: 'T' });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const columns = [
    { key: 'nrSeqRequisacaoMovEstoque', type: GridView.ColumnTypes.Checkbox },
    { key: 'cdRequisacaoMovEstoque', title: 'Requisição', sortable: false },
    {
      key: 'dtMovimento',
      title: 'Data Movimento',
      format: GridView.DataTypes.Date,
      sortable: false,
    },
    { key: 'noDocumento', title: 'Descrição', sortable: false },
    {
      key: 'almoxarifado.noAlmoxarifado',
      title: 'Almoxarifado',
      sortable: false,
    },
  ];

  const StatusProdutos = [
    { text: 'Todos', value: 'T' },
    { text: 'Ativos', value: 'A' },
    { text: 'Inativos', value: 'I' },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    const {
      data,
      pagination,
      message: msg,
      status,
    } = await getRequisicaoMovEstoquePagined(params ?? filtros);

    if (msg) {
      onSetMessage(status, msg);
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'MovimentacaoEstoque/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqRequisacaoMovEstoque = sourceRow.find(
      (e) => e.key === 'nrSeqRequisacaoMovEstoque'
    );
    onOpenMaintenance(nrSeqRequisacaoMovEstoque.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printRequisicaoMovEstoque(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDownloadExcel = async () => {
    setLoading(true);

    const { value: base64 } = await excelRequisicaoMovEstoque(filtros);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'RequisicaoMovEstoque.xls';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllRequisicaoMovEstoque(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchAlmoxarifadoEmpresaAutoComplete = async (e) => {
    const {
      status,
      message: msg,
      data: almoxarifados,
    } = await getAlmoxarifadoAutoComplete({
      noAlmoxarifado: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifados;
  };

  const onSearchProdutoAgrupador = async (e) => {
    const {
      status,
      message: msg,
      data: produtoAgrupador,
    } = await getProdutoAgrupadorAutoComplete({
      NoSearchField: e,
      nrSeqProdutoGrupo: filtros.nrSeqProdutoGrupo,
      nrSeqAlmoxarifado: filtros.nrSeqAlmoxarifado,
    });
    if (msg) onSetMessage(status, msg);
    return produtoAgrupador;
  };

  const onSearchProdutoGrupo = async (e) => {
    const {
      status,
      message: msg,
      data: produtoGrupo,
    } = await getProdutoGrupoAutoComplete({
      noProdutoGrupo: e,
    });
    if (msg) onSetMessage(status, msg);
    return produtoGrupo;
  };

  const onSearchProdutoSubGrupo = async (e) => {
    const {
      status,
      message: msg,
      data: produtoSubGrupo,
    } = await getProdutoSubGrupoAutoComplete({
      noProdutoSubGrupo: e,
      nrSeqProdutoGrupo: filtros.nrSeqProdutoGrupo,
    });
    if (msg) onSetMessage(status, msg);
    return produtoSubGrupo;
  };

  const onSearchFuncionario = async (e) => {
    const {
      status,
      message: msg,
      funcionarios,
    } = await getFuncionarioAutoComplete({ noPessoa: e });
    if (msg) onSetMessage(status, msg);
    return funcionarios;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção Movimentação de Estoque'
      loading={loading}
      onSearch={search}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Excel'
          icon='file-excel'
          onClick={onDownloadExcel}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col'>
          <Panel>
            <Panel.Header
              icon={['fas', 'user']}
              title='Filtros'
              theme={Theme.Primary}
            />
            <Panel.Body>
              <div className='row mb-3 mt-3'>
                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Empresa'
                    searchDataSource={onSearchAlmoxarifadoEmpresaAutoComplete}
                    selectedItem={filtros.almoxarifado}
                    onSelectItem={(almoxarifado) => {
                      setFiltros({
                        ...filtros,
                        almoxarifado,
                        nrSeqAlmoxarifado: almoxarifado.nrSeqAlmoxarifado,
                      });
                    }}
                    dataSourceTextProperty='noAlmoxarifado'
                  />
                </div>

                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Grupo'
                    searchDataSource={onSearchProdutoGrupo}
                    selectedItem={filtros.produtoGrupo}
                    onSelectItem={(produtoGrupo) => {
                      setFiltros({
                        ...filtros,
                        produtoGrupo,
                        nrSeqProdutoGrupo: produtoGrupo.nrSeqProdutoGrupo,
                      });
                    }}
                    dataSourceTextProperty='noProdutoGrupo'
                  />
                </div>

                <div className='col-2 mb-3'>
                  <DatePicker
                    label='Data Inicial'
                    required
                    text={filtros.dataInicial}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dataInicial) =>
                      setFiltros({ ...filtros, dataInicial })
                    }
                  />
                </div>

                <div className='col-2 mb-3'>
                  <DatePicker
                    label='Data Final'
                    text={filtros.dataFinal}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dataFinal) =>
                      setFiltros({ ...filtros, dataFinal })
                    }
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-4'>
                  <Autocomplete
                    label='Produto Agrupador'
                    searchDataSource={onSearchProdutoAgrupador}
                    selectedItem={filtros.produtoAgrupador}
                    onSelectItem={(produtoAgrupador) => {
                      setFiltros({
                        ...filtros,
                        produtoAgrupador,
                        nrSeqProdutoAgrupador:
                          produtoAgrupador.nrSeqProdutoAgrupador,
                      });
                    }}
                    dataSourceTextProperty='noProduto'
                  />
                </div>

                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Sub Grupo'
                    searchDataSource={onSearchProdutoSubGrupo}
                    selectedItem={filtros.produtoSubGrupo}
                    onSelectItem={(produtoSubGrupo) => {
                      setFiltros({
                        ...filtros,
                        produtoSubGrupo,
                        nrSeqProdutoSubGrupo:
                          produtoSubGrupo.nrSeqProdutoSubGrupo,
                      });
                    }}
                    dataSourceTextProperty='noProdutoSubGrupo'
                  />
                </div>
                <div className='col-4 mb-3'>
                  <RadioButton
                    outline
                    label='Tipo Produtos'
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    buttons={StatusProdutos}
                    selectedButton={filtros.noStatus}
                    onChange={(status) =>
                      setFiltros({
                        ...filtros,
                        noStatus: status,
                      })
                    }
                  />
                </div>

                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Funcionário'
                    searchDataSource={onSearchFuncionario}
                    selectedItem={filtros.funcionario}
                    onSelectItem={(funcionario) => {
                      setFiltros({
                        ...filtros,
                        funcionario,
                        nrSeqPessoaFun: funcionario.nrSeqPessoaFun,
                      });
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
