import React, { useState, useRef, useEffect } from 'react';

import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { Button, Loader, Modal, Notification, GridView } from 'ui/components';

import {
  confirmDemand,
  efetivarDemandaSemIntegracaoVivoMvno,
  returnDemand,
} from 'core/services/HELP/demanda';

export default function ModalProtocolo({ demandas, show, onClose }) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showBotao, setShowBotao] = useState(false);

  const gridView = useRef();

  const saveProtocol = async (nrSeqDemanda, noProtocolo, row) => {
    const datasource = gridView.current.getBuildedDataSource();

    datasource[row - 1][10].value = noProtocolo;

    gridView.current.setBuildedDataSource(datasource);
  };

  const saveObservations = async (nrSeqDemanda, noObservacao, row) => {
    const datasource = gridView.current.getBuildedDataSource();

    datasource[row - 1][11].value = noObservacao;

    gridView.current.setBuildedDataSource(datasource);
  };

  const saveNewNumber = async (nrSeqDemanda, novoNumero, row) => {
    const datasource = gridView.current.getBuildedDataSource();

    datasource[row - 1][12].value = novoNumero
      .replace('(', '')
      .replace(')', '')
      .replace(' ', '')
      .replace('-', '')
      .replace('.', '');

    gridView.current.setBuildedDataSource(datasource);
  };

  const fProtocol = function (keyValue, value, row) {
    saveProtocol(keyValue, value, row);
  };

  const fObservations = function (keyValue, value, row) {
    saveObservations(keyValue, value, row);
  };

  const fNewNumber = function (keyValue, value, row) {
    saveNewNumber(keyValue, value, row);
  };

  useEffect(() => {
    (async function load() {
      setMessage(null);

      setShowBotao(false);

      if (demandas.length > 0) {
        gridView?.current?.setDataSource(demandas);
      }
    })();
  }, [demandas]);

  const columns = [
    {
      key: 'nrSeqDemanda',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'nrSeqDemanda', title: 'Contrato' },
    { key: 'noEmpresa', title: 'Empresa', visible: false },
    { key: 'operadoraDestino.noOperadora', title: 'Operadora' },
    { key: 'plano.noPlano', title: 'Plano', visible: false },
    { key: 'noTipoChamada', title: 'Pacote', visible: false },
    {
      key: 'tipoDemanda.noTipoDemanda',
      title: 'Tipo',
    },

    { key: 'chip.linhaFormatada', title: 'NrLinha' },
    { key: 'noCliente', title: 'Cliente' },

    { key: 'noStatusDemanda', title: 'Status' },

    {
      key: 'noProtocolo',
      title: 'Protocolo',
      maxLength: 80,
      type: GridView.ColumnTypes.Textbox,
      onBlur: fProtocol,
    },
    {
      key: 'noObservacoes',
      title: 'Observações',
      type: GridView.ColumnTypes.Textbox,
      maxLength: 2000,
      onBlur: fObservations,
    },
    {
      key: 'novoNumero',
      title: 'Novo Número',
      type: GridView.ColumnTypes.Textbox,
      maxLength: 16,
      onBlur: fNewNumber,
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onEfetivarDemandaSemIntegracao = async () => {
    setLoading(true);

    const demandasefetivacao = demandas;

    const datasource = gridView.current.getBuildedDataSource();

    for (let cont = 0; cont < datasource.length; cont += 1) {
      demandasefetivacao[cont].noProtocolo = datasource[cont][10].value;
      demandasefetivacao[cont].noObservacao = datasource[cont][11].value;
      demandasefetivacao[cont].novoNumero = datasource[cont][12].value;
    }

    const { status, message: msg } = await efetivarDemandaSemIntegracaoVivoMvno(
      demandasefetivacao
    );

    setLoading(false);

    onSetMessage(status, msg);
  };

  const onSendConfirm = async () => {
    setLoading(true);

    const demandasefetivacao = demandas;

    const datasource = gridView.current.getBuildedDataSource();
    for (let cont = 0; cont < datasource.length; cont += 1) {
      demandasefetivacao[cont].noProtocolo = datasource[cont][10].value;
      demandasefetivacao[cont].noObservacao = datasource[cont][11].value;
      demandasefetivacao[cont].novoNumero = datasource[cont][12].value;
    }

    const { status, message: msg } = await confirmDemand(demandasefetivacao);

    if (status === ResponseStatus.Error) {
      if (
        demandas.every(
          (demanda) =>
            ((demanda.tipoDemanda?.cdTipoDemanda?.includes('Bloqueio') ||
              demanda.tipoDemanda?.cdTipoDemanda === 'Desbloqueio') &&
              (demanda.operadoraDestino?.noOperadora?.toUpperCase() ===
                'VIVO' ||
                demanda.operadoraDestino?.noOperadora
                  .toUpperCase()
                  .includes('MVNO'))) ||
            ((demanda.tipoDemanda?.cdTipoDemanda === 'Novo Contrato' ||
              demanda.tipoDemanda?.cdTipoDemanda === 'Portabilidade' ||
              demanda.tipoDemanda?.cdTipoDemanda === 'Alteracao de Contrato') &&
              demanda.operadoraDestino?.noOperadora
                .toUpperCase()
                .includes('MVNO'))
        )
      ) {
        setShowBotao(true);
      }
    }
    onSetMessage(status, msg);

    setLoading(false);
  };

  const onSendReturn = async () => {
    setLoading(true);
    const demandasDevolucao = demandas;

    const datasource = gridView.current.getBuildedDataSource();
    for (let cont = 0; cont < datasource.length; cont += 1) {
      demandasDevolucao[cont].noProtocolo = datasource[cont][10].value;
      demandasDevolucao[cont].noObservacao = datasource[cont][11].value ?? '';
      demandasDevolucao[cont].novoNumero = datasource[cont][12].value;
    }

    if (demandasDevolucao.every((demanda) => demanda.noObservacao.length > 0)) {
      const { status, message: msg } = await returnDemand(demandasDevolucao);

      onSetMessage(status, msg);
    } else {
      onSetMessage(
        ResponseStatus.Danger,
        'O motivo da devolução deve ser informado na coluna de Observações'
      );
    }

    setLoading(false);
  };

  return (
    <Modal
      show={show}
      title='Registrar Protocolo'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
      icon={['far', 'comment-alt']}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='row'>
          <div className='col-12'>
            <GridView
              ref={gridView}
              dataColumns={columns}
              offlineData
              showSelectSizes={false}
              showPagination={false}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <p>
              {gridView?.current?.getDataSource().length} demanda
              {gridView?.current?.getDataSource().length === 1 ? '' : 's'}{' '}
              selecionada
              {gridView?.current?.getDataSource().length === 1 ? '' : 's'}
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {showBotao && (
          <Button
            text='Efetivar Demandas Sem Integracao'
            theme={Theme.Warning}
            icon='exclamation'
            onClick={onEfetivarDemandaSemIntegracao}
            tooltip='Verique o erro na Iteracao antes de usar essa opção. A efetivação das demandas não ira refletir na operadora'
          />
        )}
        <Button
          text='Efetivar Demandas'
          theme={Theme.Success}
          icon='check'
          onClick={onSendConfirm}
        />
        <Button
          text='Devolver Demandas'
          theme={Theme.Success}
          icon='share'
          onClick={onSendReturn}
        />

        <Button
          text='Fechar'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
