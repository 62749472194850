import React, { useState, useEffect } from 'react';

import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { Button, Modal, Notification } from 'ui/components';

export default function ModalEnvioEmail({ show, onClose }) {
  const [message, setMessage] = useState(null);

  useEffect(() => {}, [show]);

  return (
    <Modal
      show={show}
      title='Envio Email'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body className='p-4 pt-3'>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button text='Enviar' theme={Theme.Success} icon={['far', 'save']} />
        <Button
          text='Fechar'
          theme={Theme.Danger}
          icon='ban'
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
