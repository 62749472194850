import { EstadoCivil } from 'core/models/SEG';
import api from '../api';

export const getEstadoCivilAutoComplete = async (filters) => {
  const response = await api.get(`/EstadoCivil/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    estadosCivis: value?.map((json) => new EstadoCivil(json)) ?? [],
  };
};
