import qs from 'qs';
import Pagination from 'core/models/pagination';
import ManutencaoVeiculo from 'core/models/FRO/manutencaoVeiculo';
import api from '../api';

const baseURL = `/FRO/ManutencaoVeiculo`;

export const getManutencaoVeiculoList = async (filters) => {
  const response = await api.get(`/$ManutencaoVeiculo/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ManutencaoVeiculo(json)) ?? [],
  };
};

export const GetManutencaoVeiculoSemAcerto = async (filters) => {
  const response = await api.post(
    `${baseURL}/GetManutencaoVeiculoSemAcerto`,
    filters
  );
  const { value } = response.data ?? {};
  return value;
};

export const getManutencaoVeiculoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ManutencaoVeiculo(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getManutencaoVeiculo = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ManutencaoVeiculo(value) : {};
};

export const saveManutencaoVeiculo = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteManutencaoVeiculo = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printManutencaoVeiculo = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printManutencaoVeiculoReport = async (filters) => {
  const response = await api.get(`${baseURL}/PrintReport`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printManutencaoVeiculoReportPorOficina = async (filters) => {
  const response = await api.get(`${baseURL}/PrintReportPorOficina`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printManutencaoVeiculoReportPorOficinaCompra = async (filters) => {
  const response = await api.get(`${baseURL}/PrintReportPorOficinaCompra`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printManutencaoVeiculoReportPorPlaca = async (filters) => {
  const response = await api.get(`${baseURL}/PrintReportPorPlaca`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelManutencaoVeiculo = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelPrintReport`, {
    params: filters,
  });
  return response.data.value ?? {};
};

export const excelManutencaoVeiculoPorOficina = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelPrintReportPorOficina`, {
    params: filters,
  });
  return response.data.value ?? {};
};

export const excelManutencaoVeiculoPorOficinaCompra = async (filters) => {
  const response = await api.get(
    `${baseURL}/ExcelPrintReportPorOficinaCompra`,
    {
      params: filters,
    }
  );
  return response.data.value ?? {};
};

export const excelManutencaoVeiculoPorPlaca = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelPrintReportPorPlaca`, {
    params: filters,
  });
  return response.data.value ?? {};
};

export const deleteAllManutencaoVeiculo = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getUsuario = async (id) => {
  const response = await api.get(`${baseURL}/GetUsuario`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ManutencaoVeiculo(value) : {};
};
