import React, {
  useState,
  forwardRef,
  ForwardedRef,
  useImperativeHandle,
} from 'react';
import {
  Popover as PopoverBootstrap,
  Button,
  OverlayTrigger,
  OverlayTriggerProps,
  // @ts-expect-error
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Theme } from 'ui/Helpers/enums';

interface PropsChildrens {
  theme?: Theme;
  children?: React.ReactNode;
  icon?: IconProp;
  title?: string;
}

interface Props extends PropsChildrens {
  buttonTheme?: Theme;
  icon?: IconProp;
  title?: string;
  hover?: boolean;
  outline?: boolean;
  placement?: OverlayTriggerProps['placement'];
  isButton?: boolean;
}

const Popover = forwardRef(
  (
    {
      children,
      buttonTheme = Theme.Primary,
      icon = ['fas', 'info-circle'],
      hover,
      outline,
      placement,
      isButton = true,
    }: Props,
    ref: ForwardedRef<any>
  ) => {
    const [show, setShow] = useState(false);
    const variantButton = outline ? `outline-${buttonTheme}` : buttonTheme;

    const popoverComponent = (
      <PopoverBootstrap id='popover-basic' className='csd-popover'>
        {children}
      </PopoverBootstrap>
    );

    useImperativeHandle(ref, () => ({
      close: () => {
        setShow(false);
      },
    }));

    return (
      <OverlayTrigger
        show={show}
        rootClose
        onToggle={() => setShow(!show)}
        trigger={hover ? 'hover' : 'click'}
        placement={placement ?? 'bottom'}
        overlay={popoverComponent}
      >
        <span className='d-inline-block'>
          {isButton ? (
            <Button variant={variantButton}>
              <FontAwesomeIcon icon={icon} />
            </Button>
          ) : (
            <FontAwesomeIcon icon={icon} />
          )}
        </span>
      </OverlayTrigger>
    );
  }
);

const Header = ({ icon, title, theme = Theme.Primary }: PropsChildrens) => (
  <>
    {title || icon ? (
      <PopoverBootstrap.Header
        as='h3'
        className={`csd-popover-header-${theme}`}
      >
        {icon && (
          <FontAwesomeIcon icon={icon} className='csd-popover-header-icon' />
        )}
        {title}
      </PopoverBootstrap.Header>
    ) : null}
  </>
);

const Body = ({ children }: PropsChildrens) => (
  <PopoverBootstrap.Body className='csd-popover-body'>
    {children}
  </PopoverBootstrap.Body>
);

const Footer = ({ children }: PropsChildrens) => (
  <div className='modal-footer'>
    <hr />
    {children}
  </div>
);

export default Object.assign(Popover, { Body, Footer, Header });
