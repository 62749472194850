import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import { Button, Modal, Textarea, Loader, Notification } from 'ui/components';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';
import { saveAprovaReprova } from 'core/services/FIN/aprovaFinanceiro';

const ModalReprovar = forwardRef(
  ({ pedido, show, onClose, onSuccess }, param) => {
    const [observacao, setObservacao] = useState({});
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    useImperativeHandle(param, () => ({
      param: observacao,
    }));

    const onSetMessage = (status, msg) => {
      if (msg)
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
    };
    useEffect(() => {
      setObservacao(pedido);
    }, [pedido]);

    const onSave = async () => {
      if (observacao.noObs === '' || observacao.noObs === null)
        onSetMessage(ResponseStatus.Error, 'Informe a observação.');
      else {
        setLoading(true);
        const obj = { ...observacao, flgAprovada: false };

        const { data: retorno, status, message: msg } = await saveAprovaReprova(
          obj
        );

        if (status === ResponseStatus.Success) {
          onSuccess(retorno);
          onClose();
        } else {
          setMessage({
            message: msg,
            theme: Theme.Danger,
          });
        }

        setLoading(false);
      }
    };

    return (
      <Modal
        show={show}
        message={message}
        loading={loading}
        title='Reprovar Orçamento'
        icon={['far', 'comment-alt']}
        onClose={onClose}
        size={BootstrapSizes.Medium}
      >
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <Modal.Body>
          <Textarea
            label='Observação'
            text={observacao.noObs}
            onChangedValue={(nomensagem) => {
              setObservacao({ ...observacao, noObs: nomensagem });
            }}
            maxLength={200}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            text='Reprovar'
            theme={Theme.Success}
            icon={['far', 'save']}
            onClick={() => {
              onSave();
            }}
          />
          <Button
            text='Cancelar'
            theme={Theme.Danger}
            icon='ban'
            onClick={() => {
              setObservacao({});
              onClose();
            }}
          />
        </Modal.Footer>
      </Modal>
    );
  }
);

export default ModalReprovar;
