import React, { useEffect, useRef, useState } from 'react';
import {
  deleteAllChamado,
  downloadBase,
  getChamadoPagined,
  printChamado,
} from 'core/services/HELP/chamado';
import SituacaoChamado from 'core/models/HELP/situacaoChamado';
import { getSituacaoChamadoAutoComplete } from 'core/services/HELP/situacaoChamado';
import {
  ColumnTypes,
  Theme,
  ColumnDataTypes,
  ResponseStatus,
} from 'ui/Helpers/enums';
import { GridviewColumns, Message, Page } from 'ui/Helpers/interfaces';
import { PageTypes } from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  CSDSelPage,
  DatePicker,
  DropdownMulti,
  GridView,
  Panel,
  Textbox,
} from 'ui/components';
import { getAreaChamadoAutoComplete } from 'core/services/HELP/areaChamado';
import AreaChamado from 'core/models/HELP/areaChamado';
import TipoChamado from 'core/models/HELP/tipoChamado';
import { getTipoChamadoAutoComplete } from 'core/services/HELP/tipoChamado';
import { Cliente } from 'core/models/FIN';
import { getClienteAutoComplete } from 'core/services/FIN';
import { Usuario } from 'core/models/SEG';
import { getUsuarioAutoComplete } from 'core/services/SEG';

const columns: Array<GridviewColumns> = [
  { key: 'nrSeqChamado', type: ColumnTypes.Checkbox },
  { key: 'cdChamado', title: 'Código', sortable: false },
  { key: 'tipoChamado.noTipoChamado', title: 'Tipo Chamado' },
  {
    key: 'chamadoClassificacao.noChamadoClassificacao',
    title: 'Classificação',
  },
  {
    key: 'dtAbertura',
    title: 'Abertura',
    format: ColumnDataTypes.Date,
  },
  {
    key: 'dtPrazoConclusao',
    title: 'Prazo',
    format: ColumnDataTypes.Date,
  },
  {
    key: 'dtFechamento',
    title: 'Conclusão',
    format: ColumnDataTypes.Date,
  },
  { key: 'situacaoChamado.noSituacaoChamado', title: 'Status' },
  { key: 'cliente.noPessoa', title: 'Cliente' },
  { key: 'descricao', title: 'Descrição' },
  { key: 'usuarioCad.noPessoa', title: 'Usuario Cad' },
  { key: 'usuarioOp.noPessoa', title: 'Responsável' },
];

export default function Chamado({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}: Page) {
  const gridView: any = useRef();
  const dropDownStatus: any = useRef();

  const [filters, setFilters] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [selectedArea, setSelectedArea] = useState<AreaChamado>(
    new AreaChamado()
  );
  const [selectedTipo, setSelectedTipo] = useState<TipoChamado>(
    new TipoChamado()
  );
  const [selectedItems, setSelectedItems] = useState<any>({});
  const [noBase, setNoBase] = useState<string>('');

  const search = async (params: Object | null = null): Promise<void> => {
    setLoading(true);

    const { data, pagination } = await getChamadoPagined(params ?? filters);

    if (gridView && gridView.current) {
      gridView.current.setDataSource(data, pagination);
    }

    setLoading(false);
  };

  const onColumnSort = (sortBy: string): void => {
    const filtros = { ...filters, sortBy };

    setFilters(filtros);

    search(filtros);
  };

  const onPageSizeChange = (totalByPage: number): void => {
    const filtros = { ...filters, totalByPage };

    setFilters(filtros);

    search(filtros);
  };

  const onPageChange = (page: number): void => {
    const filtros = { ...filters, page };

    setFilters(filtros);

    search(filtros);
  };

  const onOpenMaintenance = (registryKey: number | null): void => {
    onOpenPage(
      transaction.noTransacao,
      'Chamado/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow: Array<any>): void => {
    const nrSeqChamado = sourceRow.find((e) => e.key === 'nrSeqChamado');

    onOpenMaintenance(nrSeqChamado.value);
  };

  const onDelete = async () => {
    setLoading(true);

    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllChamado(
        selecteds.map((row: any) => row[0].value)
      );

      setLoading(false);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      search();
    } else {
      setLoading(false);

      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onPrint = async (): Promise<void> => {
    setLoading(true);

    const { value } = await printChamado(filters);

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onSearchAreaChamado = async (e: string): Promise<AreaChamado[]> => {
    const {
      areasChamado,
    }: { areasChamado: AreaChamado[] } = await getAreaChamadoAutoComplete({
      noAreaChamado: e,
    });

    return areasChamado;
  };

  const onSearchTipoChamado = async (e: string): Promise<TipoChamado[]> => {
    if (selectedArea.nrSeqAreaChamado === 0) {
      setMessage({
        message: 'É necessário selecionar uma area.',
        theme: Theme.Warning,
      });

      return [];
    }

    const {
      tiposChamado,
    }: { tiposChamado: TipoChamado[] } = await getTipoChamadoAutoComplete({
      noTipoChamado: e,
      nrSeqAreaChamado: selectedArea.nrSeqAreaChamado,
    });

    return tiposChamado;
  };

  const onSearchSituacaoChamado = async (
    e: string = ''
  ): Promise<SituacaoChamado[]> => {
    const {
      situacoesChamados,
    }: {
      situacoesChamados: Array<SituacaoChamado>;
    } = await getSituacaoChamadoAutoComplete({ noSituacaoChamado: e });

    return situacoesChamados;
  };

  const onSearchCliente = async (e: string): Promise<Cliente[]> => {
    const { clientes }: { clientes: Cliente[] } = await getClienteAutoComplete({
      noPessoa: e,
    });

    return clientes;
  };

  const onSearchUsuario = async (e: string): Promise<Array<Usuario>> => {
    const { data }: { data: Array<Usuario> } = await getUsuarioAutoComplete({
      noLogin: e,
    });

    return data;
  };

  const onClickDownloadBase = async (): Promise<void> => {
    const { data, msg } = await downloadBase(noBase);

    if (msg) {
      setMessage({
        message: msg,
        theme: Theme.Danger,
      });

      return;
    }

    const a = document.createElement('a');
    a.href = data;
    a.download = noBase;
    document.body.appendChild(a);
    a.click();
    a.remove();
    setNoBase('');
  };

  useEffect(() => {
    const resp = JSON.parse(String(sessionStorage.getItem('@dataAuth'))).user;

    setFilters({ ...filters, resp, nrSeqUsuarioOp: resp.nrSeqUsuario });

    dropDownStatus.current.loadDataSource(onSearchSituacaoChamado);
  }, []);

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Chamado'
      loading={loading}
      message={message}
      onNew={onOpenMaintenance}
      onSearch={() => search()}
      onDelete={onDelete}
      onPrint={onPrint}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      {/* @ts-expect-error */}
      <Panel className='mb-3'>
        {/* @ts-expect-error */}
        <Panel.Header title='Filtros' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-4'>
              <Autocomplete
                label='Area'
                searchDataSource={onSearchAreaChamado}
                selectedItem={selectedArea}
                onSelectItem={(area: AreaChamado) => setSelectedArea(area)}
                dataSourceTextProperty='noAreaChamado'
              />
            </div>
            <div className='col-5'>
              <Autocomplete
                label='Tipo de chamado'
                searchDataSource={onSearchTipoChamado}
                selectedItem={selectedTipo}
                onSelectItem={(tipo: TipoChamado) => {
                  setSelectedTipo(tipo);
                  setFilters({
                    ...filters,
                    nrSeqTipoChamado: tipo.nrSeqTipoChamado,
                  });
                }}
                dataSourceTextProperty='noTipoChamado'
              />
            </div>
            <div className='col-3'>
              <DropdownMulti
                // @ts-expect-error
                label='Status'
                ref={dropDownStatus}
                dataSourcePropertyText='noSituacaoChamado'
                dataSourcePropertyValue='nrSeqSituacaoChamado'
                selectedItems={selectedItems.item ?? []}
                onSelectItem={(item: any) => {
                  setSelectedItems({ ...selectedItems, item });
                  setFilters({
                    ...filters,
                    nrSeqsSituacaoChamado: item
                      ?.map((x: SituacaoChamado) => x.nrSeqSituacaoChamado)
                      .join(', '),
                  });
                }}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-9'>
              <Autocomplete
                label='Cliente'
                searchDataSource={onSearchCliente}
                selectedItem={filters.cliente}
                onSelectItem={(cliente: Cliente) => {
                  setFilters({
                    ...filters,
                    cliente,
                    nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-3'>
              {/* @ts-expect-error */}
              <Textbox
                label='Código'
                maxLength={60}
                onChangedValue={(cdChamado: string) =>
                  setFilters({ ...filters, cdChamado })
                }
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-4'>
              <Autocomplete
                label='Responsável'
                searchDataSource={onSearchUsuario}
                selectedItem={filters.resp}
                onSelectItem={(usuario: Usuario) => {
                  setFilters({
                    ...filters,
                    resp: usuario,
                    nrSeqUsuarioOp: usuario.nrSeqUsuario,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <DatePicker
                label='Aberto de'
                onChange={(dtAbertura: Date) =>
                  setFilters({ ...filters, dtAbertura })
                }
              />
            </div>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <DatePicker
                label='Aberto até'
                onChange={(dtAberturaAte: Date) =>
                  setFilters({
                    ...filters,
                    dtAberturaAte: `${dtAberturaAte} 23:59:59.999`,
                  })
                }
              />
            </div>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <DatePicker
                label='Concluido de'
                onChange={(dtFechamento: Date) =>
                  setFilters({ ...filters, dtFechamento })
                }
              />
            </div>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <DatePicker
                label='Concluido até'
                onChange={(dtFechamentoAte: Date) =>
                  setFilters({
                    ...filters,
                    dtFechamentoAte: `${dtFechamentoAte} 23:59:59.999`,
                  })
                }
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-4'>
              <Autocomplete
                label='Usuário Cad'
                searchDataSource={onSearchUsuario}
                selectedItem={filters.cad}
                onSelectItem={(usuario: Usuario) => {
                  setFilters({
                    ...filters,
                    cad: usuario,
                    nrSeqUsuarioCad: usuario.nrSeqUsuario,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Textbox
                label='Base'
                text={noBase}
                onChangedValue={(nomeBase: string) => {
                  setNoBase(nomeBase);
                }}
              />
            </div>
            <div className='col-2 d-flex align-items-end pb-1'>
              <Button
                text='Download'
                onClick={onClickDownloadBase}
                theme={Theme.Success}
                size='sm'
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div>
        <GridView
          ref={gridView}
          // @ts-expect-error
          dataColumns={columns}
          onColumnSort={onColumnSort}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          onRowDoubleClick={onRowDoubleClick}
          transaction={transaction}
          enableExcelExport
        />
      </div>
    </CSDSelPage>
  );
}
