import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getConvenioPagined,
  printConvenio,
  deleteAllConvenio,
} from 'core/services/ASS/convenio';
import { getVencimentoAutoComplete } from 'core/services/ASS/vencimento';
import {
  CSDSelPage,
  GridView,
  Textbox,
  Autocomplete,
  Switch,
} from 'ui/components';

const columns = [
  { key: 'nrSeqConvenio', type: GridView.ColumnTypes.Checkbox },
  { key: 'noConvenio', title: 'Convênio' },
  { key: 'nrSeqVencimento', title: 'Código Vencimento' },
  { key: 'flgFinanceiro', title: 'Financeiro' },
  { key: 'flgComissaoSemFinanceiro', title: 'Comissão sem  Financeiro' },
  { key: 'vlrAdministracao', title: 'Administração' },
  { key: 'flgFundoInvestimento', title: 'Fundo Investimento' },
  { key: 'flgAcademia', title: 'Academia' },
  { key: 'cdConvenio', title: 'Código do Convênio' },
  { key: 'flgConvenioMassivo', title: 'Convênio Massivo' },
];

export default function Convenio({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);
    const obj = { ...filtros };
    if (obj.Convenio) {
      obj.convenio = undefined;
    }
    if (params) {
      params = undefined;
    }

    const { status, message: msg, data, pagination } = await getConvenioPagined(
      params ?? filtros
    );

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getConvenioPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Convenio/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqConvenio = sourceRow.find((e) => e.key === 'nrSeqConvenio');
    onOpenMaintenance(nrSeqConvenio.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printConvenio(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllConvenio(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchVencimento = async (e) => {
    const {
      status,
      message: msg,
      vencimentos,
    } = await getVencimentoAutoComplete({ noVencimento: e });
    if (msg) onSetMessage(status, msg);
    return vencimentos;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Convênio'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col'>
          <Textbox
            maxLength={10}
            label='Convênio'
            text={filtros.noConvenio}
            onChangedValue={(noConvenio) =>
              setFiltros({ ...filtros, noConvenio })
            }
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Vencimento'
            searchDataSource={onSearchVencimento}
            selectedItem={filtros.vencimento}
            onSelectItem={(vencimento) => {
              setFiltros({
                ...filtros,
                vencimento,
                nrSeqVencimento: vencimento.nrSeqVencimento,
              });
            }}
            dataSourceTextProperty='noVencimento'
          />
        </div>
        <div className='col'>
          <Textbox
            maxLength={10}
            label='Código Convênio'
            text={filtros.cdConvenio}
            onChangedValue={(cdConvenio) =>
              setFiltros({ ...filtros, cdConvenio })
            }
          />
        </div>
        <div className='col'>
          <Textbox
            label='Valor Administração'
            text={filtros.vlrAdministracao}
            mask={MaskTypes.Decimal}
            onChangedValue={(vlrAdministracao) =>
              setFiltros({ ...filtros, vlrAdministracao })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <Switch
            formControl
            label='Financeiro'
            checked={filtros.flgFinanceiro}
            onChange={(flgFinanceiro) =>
              setFiltros({ ...filtros, flgFinanceiro })
            }
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Comissão sem Financeiro'
            checked={filtros.flgComissaoSemFinanceiro}
            onChange={(flgComissaoSemFinanceiro) =>
              setFiltros({ ...filtros, flgComissaoSemFinanceiro })
            }
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Fundo Investimento'
            checked={filtros.flgFundoInvestimento}
            onChange={(flgFundoInvestimento) =>
              setFiltros({ ...filtros, flgFundoInvestimento })
            }
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Academia'
            checked={filtros.flgAcademia}
            onChange={(flgAcademia) => setFiltros({ ...filtros, flgAcademia })}
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Convênio Massivo'
            checked={filtros.flgConvenioMassivo}
            onChange={(flgConvenioMassivo) =>
              setFiltros({ ...filtros, flgConvenioMassivo })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            enableExcelExport
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
