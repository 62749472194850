import Pessoa from 'core/models/SEG/pessoa';
import Cliente from 'core/models/FIN/cliente';
import Cidade from 'core/models/SEG/cidade';
import Estado from 'core/models/SEG/estado';
import FreteMercadoria from 'core/models/FRO/freteMercadoria';
import TipoEmbarque from 'core/models/FRO/tipoEmbarque';
import FreteTipoCalculo from 'core/models/FRO/freteTipoCalculo';
import AuditoriaFretePreco from 'core/models/FRO/auditoriaFretePreco';

export default class FretePreco {
  constructor(jsonObject = {}) {
    this.nrSeqFretePreco = jsonObject.nrSeqFretePreco;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.nrSeqFreteMercadoria = jsonObject.nrSeqFreteMercadoria;
    this.nrSeqCidadeOri = jsonObject.nrSeqCidadeOri;
    this.nrSeqCidadeDest = jsonObject.nrSeqCidadeDest;
    this.nrSeqEstadoOri = jsonObject.nrSeqEstadoOri;
    this.nrSeqEstadoDest = jsonObject.nrSeqEstadoDest;
    this.nrSeqFreteTipoCalculo = jsonObject.nrSeqFreteTipoCalculo;
    this.dtInicio = jsonObject.dtInicio;
    this.dtFim = jsonObject.dtFim;
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.faixaFim = jsonObject.faixaFim;
    this.faixaIni = jsonObject.faixaIni;
    this.vlrCalculado = jsonObject.vlrCalculado;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgValorFixo = jsonObject.flgValorFixo;
    this.flgIcms = jsonObject.flgIcms;
    this.nrSeqTipoEmbarque = jsonObject.nrSeqTipoEmbarque;
    this.vlrInicial = jsonObject.vlrInicial;
    this.vlrFinal = jsonObject.vlrFinal;
    this.vlrPercentual = jsonObject.vlrPercentual;
    this.vlrTarifaAdicional = jsonObject.vlrTarifaAdicional;
    this.pessoa = new Pessoa(jsonObject.pessoa ?? {});
    this.cliente = new Cliente(jsonObject.cliente ?? {});
    this.freteMercadoria = new FreteMercadoria(
      jsonObject.freteMercadoria ?? {}
    );
    this.tipoEmbarque = new TipoEmbarque(jsonObject.tipoEmbarque ?? {});
    this.freteTipoCalculo = new FreteTipoCalculo(
      jsonObject.freteTipoCalculo ?? {}
    );
    this.cidadeOri = new Cidade(jsonObject.cidadeOri ?? {});
    this.cidadeDest = new Cidade(jsonObject.cidadeDest ?? {});
    this.estadoOri = new Estado(jsonObject.estadoOri ?? {});
    this.estadoDest = new Estado(jsonObject.estadoDest ?? {});
    this.auditoriaFretePrecos = jsonObject.auditoriaFretePrecos
      ? jsonObject.auditoriaFretePrecos.map(
          (item) => new AuditoriaFretePreco(item ?? {})
        )
      : [];
  }

  toJson = () => JSON.stringify(this);
}
