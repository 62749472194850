import React, { useState, useRef, useEffect } from 'react';

import {
  Autocomplete,
  Button,
  Loader,
  Modal,
  GridView,
  Notification,
} from 'ui/components';
import { Theme, ResponseStatus, BootstrapSizes } from 'ui/Helpers/utils';

import {
  getChip,
  changeChipOperator,
  getChipAutoComplete,
} from 'core/services/TEL/chip';

import { getOperadoraAutoComplete } from 'core/services/TEL/operadora';

export default function ModalAlterarEmpresaChip({ recurso, show, onClose }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const gridViewAlterarOperadoraChip = useRef();
  const columnsLinhas = [
    { key: 'nrSeqChip', type: GridView.ColumnTypes.Checkbox },

    {
      key: 'cdChip',
      title: 'Código',
      visible: true,
    },
    {
      key: 'linhaFormatada',
      title: 'Nr. Linha',
      visible: true,
    },
    {
      key: 'flgStatusStr',
      title: 'Status',
      visible: true,
    },
    {
      key: 'nrIccid',
      title: 'NrICCID',
      visible: true,
    },
    {
      key: 'operadora.noOperadora',
      title: 'Operadora',
      visible: true,
    },

    {
      key: 'empresa.noPessoa',
      title: 'Empresa Atual',
      visible: true,
    },
    {
      key: 'flgSituacaoStr',
      title: 'Situação',
      visible: true,
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg) {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
  };

  const onSearchOperadora = async (e) => {
    const { status, message: msg, operadoras } = await getOperadoraAutoComplete(
      {
        noOperadora: e,
      }
    );
    onSetMessage(status, msg);
    return operadoras;
  };

  const onSearchChip = async (e) => {
    const { status, message: msg, chips } = await getChipAutoComplete({
      nrPrefixoLinha: e,
    });

    onSetMessage(status, msg);

    return chips;
  };

  useEffect(() => {
    (async function load() {
      if (recurso.length > 0)
        gridViewAlterarOperadoraChip.current.setDataSource(recurso);
    })();
  }, [recurso]);

  const onSend = async () => {
    setLoading(true);
    const nrSeqChips = recurso.map((column) => column.nrSeqChip);

    nrSeqChips.push(data.nrSeqOperadora);

    const { status, message: msg } = await changeChipOperator(nrSeqChips);

    setLoading(false);
    onSetMessage(status, msg);
  };

  const onClickAdicionar = async () => {
    const id = data.nrSeqChip;
    const chip = await getChip(id);

    recurso.push(chip);
    gridViewAlterarOperadoraChip.current.setDataSource(recurso);
  };

  return (
    <Modal
      show={show}
      title='Alterar Operadora do Chip'
      onClose={onClose}
      size={BootstrapSizes.Large}
      message={message}
      icon={['far', 'comment-alt']}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col-5'>
            <Autocomplete
              label='Operadora'
              searchDataSource={onSearchOperadora}
              selectedItem={data.operadora}
              onSelectItem={(operadora) =>
                setData({
                  ...data,
                  operadora,
                  nrSeqOperadora: operadora.nrSeqOperadora,
                })
              }
              dataSourceTextProperty='noOperadora'
            />
          </div>

          <div className='col-4'>
            <Autocomplete
              label='Nº Linha (Chip)'
              required
              searchDataSource={onSearchChip}
              selectedItem={data.chip}
              onSelectItem={(chip) => {
                setData({
                  ...data,
                  chip,
                  nrSeqChip: chip.nrSeqChip,
                });
              }}
              dataSourceTextProperty='linhaFormatada'
            />
          </div>

          <div className='col-auto'>
            <Button
              outline
              theme={Theme.Success}
              template={Button.Templates.Button}
              text='Adicionar'
              onClick={onClickAdicionar}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridViewAlterarOperadoraChip}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnsLinhas}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Salvar'
          theme={Theme.Success}
          icon='sms'
          onClick={onSend}
        />
        <Button
          text='Sair'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            setData({});
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
