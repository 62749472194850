import React, { useEffect, useState } from 'react';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { Button, Modal } from 'ui/components';

export default function ModalLog({ show, onClose, msg }) {
  const [mensagem, setMensagem] = useState();
  useEffect(async () => {
    setMensagem(msg);
  }, [show]);

  return (
    <Modal
      show={show}
      title='Log Erro Sistema'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body className='p-4 pt-3'>{mensagem}</Modal.Body>
      <Modal.Footer>
        <Button
          text='Fechar'
          theme={Theme.Danger}
          icon='ban'
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
