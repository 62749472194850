export default class ResumoSinteticoFinanceiroRecurso {
  constructor(jsonObject = {}) {
    this.nrSeqContaRecurso = jsonObject.nrSeqContaRecurso ?? '';
    this.contrato = jsonObject.contrato ?? '';
    this.dtPerInicial = jsonObject.dtPerInicial ?? '';
    this.dtPerFinal = jsonObject.dtPerFinal ?? '';
    this.noPessoaCli = jsonObject.noPessoaCli ?? '';
    this.noClienteConta = jsonObject.noClienteConta ?? '';
    this.matricula = jsonObject.matricula ?? '';
    this.codigoCliente = jsonObject.codigoCliente ?? '';
    this.cdCentroDeCusto = jsonObject.cdCentroDeCusto ?? '';
    this.noCentroDeCusto = jsonObject.noCentroDeCusto ?? '';
    this.email = jsonObject.email ?? '';
    this.noFormaPagamento = jsonObject.noFormaPagamento ?? '';
    this.cdAgencia = jsonObject.cdAgencia ?? '';
    this.cdContaCorrente = jsonObject.cdContaCorrente ?? '';
    this.noPlano = jsonObject.noPlano ?? '';
    this.noLinhaDigitavel = jsonObject.noLinhaDigitavel ?? '';
    this.noPlanoRateio = jsonObject.noPlanoRateio ?? '';
    this.noEmpresa = jsonObject.noEmpresa ?? '';
    this.nrPrefixo = jsonObject.nrPrefixo ?? '';
    this.nrLinha = jsonObject.nrLinha ?? '';
    this.noPlanoDeConta = jsonObject.noPlanoDeConta ?? '';
    this.vlrOriginal = jsonObject.vlrOriginal ?? '';
    this.vlrCusto = jsonObject.vlrCusto ?? '';
    this.vlrRateioEmpresa = jsonObject.vlrRateioEmpresa ?? '';
    this.vlrTotalRecalculo = jsonObject.vlrTotalRecalculo ?? '';
    this.vlrResultado = jsonObject.vlrResultado ?? '';
    this.vlrResultadoRateio = jsonObject.vlrResultadoRateio ?? '';
    this.vlrSubsidio = jsonObject.vlrSubsidio ?? '';
    this.nrDocumento = jsonObject.nrDocumento ?? '';
    this.noRua = jsonObject.noRua ?? '';
    this.numero = jsonObject.numero ?? '';
    this.complemento = jsonObject.complemento ?? '';
    this.bairro = jsonObject.bairro ?? '';
    this.cep = jsonObject.cep ?? '';
    this.cidade = jsonObject.cidade ?? '';
    this.uF = jsonObject.uF ?? '';
    this.nrSeqTituloReceber = jsonObject.nrSeqTituloReceber ?? '';
    this.noTituloRecebido = jsonObject.noTituloRecebido ?? '';
    this.dtVencimentoTituloReceber = jsonObject.dtVencimentoTituloReceber ?? '';
    this.ocorrencias = jsonObject.ocorrencias ?? '';
    this.nrSeqFatura = jsonObject.nrSeqFatura;
    this.vlrTaxasBancarias = jsonObject.vlrTaxasBancarias;
    this.vlrTaxaSMS = jsonObject.vlrTaxaSMS;
    this.vlrResultadoRateioComissao = jsonObject.vlrResultadoRateioComissao;

    this.qtdeTotalVoz = jsonObject.qtdeTotalVoz;
    this.qtdeTotalDados = jsonObject.qtdeTotalDados;
    this.qtdeTotalSms = jsonObject.qtdeTotalSms;

    this.noPacoteDados = jsonObject.noPacoteDados;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.statusMVNO = jsonObject.statusMVNO;
    this.dataAtivacaoMVNO = jsonObject.dataAtivacaoMVNO;
  }

  get cdContratoDTFatura() {
    return `${this.contrato}: ${new Date(this.dtPerInicial).toLocaleDateString(
      'pt-BR'
    )} à ${new Date(this.dtPerFinal).toLocaleDateString('pt-BR')}`;
  }

  get codNoCentroCusto() {
    return `${this.cdCentroDeCusto}- ${this.noCentroDeCusto}`;
  }

  get infoBancaria() {
    return `${this.cdAgencia}- ${this.cdContaCorrente}`;
  }

  get numeroCompleto() {
    return `(${this.nrPrefixo})${this.nrLinha}`;
  }

  get logradouro() {
    return `${this.noRua} ${this.numero}, ${this.complemento}, ${this.bairro} - ${this.cidade}/${this.uF} - CEP: ${this.cep}`;
  }

  get bgStatusFinanceiro() {
    if (this.noTituloRecebido === 'Pago') return 'table-success';
    if (this.noTituloRecebido === 'Em Aberto') return 'table-danger';

    return '';
  }

  toJson = () => JSON.stringify(this);
}
