export default class DominioGeologico {
  constructor(jsonObject = {}) {
    this.nrSeqPocDominioGeologico = jsonObject.nrSeqPocDominioGeologico;
    this.cdPocDominioGeologico = jsonObject.cdPocDominioGeologico;
    this.noPocDominioGeologico = jsonObject.noPocDominioGeologico;
    this.flgAtivo = jsonObject.flgAtivo;
  }

  toJson = () => JSON.stringify(this);
}
