export default class ClienteContaCabecalho {
  constructor(jsonObject = {}) {
    this.nrSeqClienteContaCab = jsonObject.nrSeqClienteContaCab;
    this.noCliente = jsonObject.noCliente;
    this.cdCliente = jsonObject.cdCliente;
    this.nrSeqOperadora = jsonObject.nrSeqOperadora;
    this.cdClienteContaCab = jsonObject.cdClienteContaCab;
  }

  get cdNoCliente() {
    return `${this.cdCliente} - ${this.noCliente}`;
  }

  toJson = () => JSON.stringify(this);
}
