import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  DatePicker,
  GridView,
  Switch,
  Button,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getTabelaPreco,
  saveTabelaPreco,
  deleteTabelaPreco,
} from 'core/services/VEN/tabelaPreco';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getProdutoAutoComplete } from 'core/services/EST/produto';

export default function TabelaPrecoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [produtoPreco, setProdutoPreco] = useState({});
  const [produtosPreco, setProdutosPreco] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const gridView = useRef();

  const onClickRemoveProdutoPreco = async (e, datasource) => {
    const dataProdutosPreco = datasource.filter((produto) => produto !== e);
    if (gridView && gridView.current)
      gridView.current.setDataSource(dataProdutosPreco);

    setProdutosPreco(dataProdutosPreco);
  };

  const onClickEditProduto = async (e, datasource) => {
    const dataProdutos = datasource.filter((produto) => produto !== e);
    const dataproduto = datasource.filter((produto) => produto === e);

    const produtoItem = {
      nrSeqProduto: dataproduto[0].nrSeqProduto,
      noProdutoAgrupador: dataproduto[0].noProdutoAgrupador,
    };

    setProdutoPreco({
      ...dataproduto[0],
      produto: produtoItem,
    });

    setProdutosPreco(dataProdutos);

    if (gridView && gridView.current)
      gridView.current.setDataSource(dataProdutos);
  };

  const columns = [
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Checkbox,
      title: 'Produto',
      visible: false,
    },
    { key: 'nrSeqUsuario', title: 'NrSeqUsuario', visible: false },
    { key: 'noProdutoAgrupador', title: 'Produto' },
    {
      key: 'vlrOriginal',
      title: 'Valor Original',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrMinimo',
      title: 'Valor Mínimo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrPromocao',
      title: 'Valor Promoção',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrVenda',
      title: 'Valor Venda',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'nrSeqTabelaPreco',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditProduto(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqTabelaPreco',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveProdutoPreco(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      const tabelaPreco = await getTabelaPreco(registryKey);
      setData(tabelaPreco);
      setProdutosPreco(tabelaPreco.tabelaPrecoProduto);
      if (gridView && gridView.current)
        gridView.current.setDataSource(tabelaPreco.tabelaPrecoProduto);
      setLoading(false);
    } else setData({ ...data, flgPromocao: false });
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchProduto = async (e) => {
    const { status, message: msg, produtos } = await getProdutoAutoComplete({
      noSearchField: e,
    });
    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const save = async () => {
    setLoading(true);
    const obj = {
      tabelaPrecoProduto: produtosPreco,
      nrSeqGrupoempresa: 0,
      nrSeqTabelaPreco: data.nrSeqTabelaPreco,
      cdTabelaPreco: 0,
      noTabelaPreco: data.noTabelaPreco,
      dtInivalidade: data.dtInivalidade,
      dtFimvalidade: data.dtFimvalidade,
      nrSeqEmpresa: data.nrSeqEmpresa,
    };
    const { status, message: msg } = await saveTabelaPreco(obj);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteTabelaPreco(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };
  const onAdicionar = () => {
    const lista = produtosPreco ?? [];
    const obj = {
      nrSeqTabelaPreco: data.nrSeqTabelaPreco ?? 0,
      nrSeqProduto: produtoPreco.nrSeqProduto,
      noProdutoAgrupador: produtoPreco.produto.noProdutoAgrupador,
      vlrOriginal: produtoPreco.vlrOriginal,
      vlrMinimo: produtoPreco.vlrMinimo,
      vlrPromocao: produtoPreco.vlrPromocao,
      flgPromocao: produtoPreco.flgPromocao,
      vlrVenda: produtoPreco.vlrVenda,
    };

    lista.push(obj);
    setProdutosPreco(lista);
    setData({ ...data, tabelaPrecoProduto: lista });
    if (gridView && gridView.current) gridView.current.setDataSource(lista);
    setProdutoPreco({});
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Tabela Preco'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqTabelaPreco > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-3'>
          <Textbox
            label='Código'
            readOnly
            text={data.cdTabelaPreco}
            mask={MaskTypes.Integer}
            onChangedValue={(cdTabelaPreco) =>
              setData({ ...data, cdTabelaPreco })
            }
          />
        </div>
        <div className='col-6'>
          <Textbox
            maxLength={60}
            label='Descrição'
            text={data.noTabelaPreco}
            onChangedValue={(noTabelaPreco) =>
              setData({ ...data, noTabelaPreco })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-3'>
          <DatePicker
            label='Início Validade'
            text={data.dtInivalidade}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtInivalidade) => setData({ ...data, dtInivalidade })}
          />
        </div>
        <div className='col-3'>
          <DatePicker
            label='Fim Validade'
            text={data.dtFimvalidade}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtFimvalidade) => setData({ ...data, dtFimvalidade })}
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Empresa'
            searchDataSource={onSearchEmpresa}
            selectedItem={data.empresa}
            onSelectItem={(empresa) => {
              setData({
                ...data,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-8'>
          <div className='card'>
            <div className='card-body'>
              <div className='row mb-3'>
                <div className='col-12'>
                  <Autocomplete
                    label='Produto'
                    searchDataSource={onSearchProduto}
                    selectedItem={produtoPreco.produto}
                    onSelectItem={(produto) => {
                      setProdutoPreco({
                        ...produtoPreco,
                        produto,
                        nrSeqProduto: produto.nrSeqProduto,
                      });
                    }}
                    dataSourceTextProperty='noProdutoAgrupador'
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-4'>
                  <Textbox
                    label='Valor Original'
                    text={produtoPreco.vlrOriginal}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrOriginal) =>
                      setProdutoPreco({ ...produtoPreco, vlrOriginal })
                    }
                  />
                </div>
                <div className='col-4'>
                  <Textbox
                    label='Valor Mínimo'
                    text={produtoPreco.vlrMinimo}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrMinimo) =>
                      setProdutoPreco({ ...produtoPreco, vlrMinimo })
                    }
                  />
                </div>
                <div className='col-4'>
                  <Textbox
                    label='Valor Venda'
                    text={produtoPreco.vlrVenda}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrVenda) =>
                      setProdutoPreco({ ...produtoPreco, vlrVenda })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-4'>
          <div className='card'>
            <div className='card-body'>
              <div className='row mb-3'>
                <div className='col-12'>
                  <Switch
                    formControl
                    label='Promoção'
                    checked={produtoPreco.flgPromocao}
                    onChange={(flgPromocao) =>
                      setProdutoPreco({
                        ...produtoPreco,
                        flgPromocao,
                        vlrPromocao: 0,
                      })
                    }
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-6'>
                  {produtoPreco.flgPromocao ? (
                    <Textbox
                      label='Valor Promoção'
                      text={produtoPreco.vlrPromocao}
                      mask={MaskTypes.Decimal}
                      onChangedValue={(vlrPromocao) =>
                        setProdutoPreco({ ...produtoPreco, vlrPromocao })
                      }
                    />
                  ) : (
                    <Textbox
                      label='Valor Promoção'
                      text={produtoPreco.vlrPromocao}
                      readOnly
                      mask={MaskTypes.Decimal}
                      onChangedValue={(vlrPromocao) =>
                        setProdutoPreco({ ...produtoPreco, vlrPromocao })
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-3'>
          <Button
            outline
            text='Adicionar'
            size={BootstrapSizes.Medium}
            theme={Theme.Success}
            template={Button.Templates.Default}
            onClick={onAdicionar}
          />
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col'>
          <GridView
            offlineData
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            gridSizeList={[5000]}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
