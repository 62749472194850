import Empresa from 'core/models/SEG/empresa';
import TituloEspecie from '../FIN/tituloEspecie';
import Cfo from '../FIS/cfo';
import GrupoIcms from '../FIS/grupoIcms';
// eslint-disable-next-line import/extensions
import TipoEmbarqueEmpresa from './tipoEmbarqueEmpresa';
import TipoEmbarqueEstado from './tipoEmbarqueEstado';

export default class TipoEmbarque {
  constructor(jsonObject = {}) {
    this.nrSeqTipoEmbarque = jsonObject.nrSeqTipoEmbarque;
    this.noTipoEmbarque = jsonObject.noTipoEmbarque;
    this.cdTipoEmbarque = jsonObject.cdTipoEmbarque;
    this.nrSeqCfoEst = jsonObject.nrSeqCfoEst;
    this.nrSeqCfoPais = jsonObject.nrSeqCfoPais;
    this.nrSeqCfoEstDif = jsonObject.nrSeqCfoEstDif;
    this.nrSeqCfoPaisDif = jsonObject.nrSeqCfoPaisDif;
    this.nrSeqTituloEspecie = jsonObject.nrSeqTituloEspecie;
    this.flgGeraTitulo = jsonObject.flgGeraTitulo;
    this.serie = jsonObject.serie;
    this.flgAtivo = jsonObject.flgAtivo;
    this.flgColeta = jsonObject.flgColeta;
    this.flgManual = jsonObject.flgManual;
    this.flgEmiteDocumento = jsonObject.flgEmiteDocumento;
    this.flgEmiteCrc = jsonObject.flgEmiteCrc;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgDefinePrecoEstado = jsonObject.flgDefinePrecoEstado;
    this.flgProprio = jsonObject.flgProprio;
    this.flgTipoDeCalculoPeso = jsonObject.flgTipoDeCalculoPeso;
    this.nrSeqGrupoIcmsCif = jsonObject.nrSeqGrupoIcmsCif;
    this.nrSeqGrupoIcmsFob = jsonObject.nrSeqGrupoIcmsFob;
    this.flgAdiconaIcmsPreco = jsonObject.flgAdiconaIcmsPreco;
    this.flgDesconsiderarPedagioDaBaseIcms =
      jsonObject.flgDesconsiderarPedagioDaBaseIcms;
    this.flgPrecoPorEstadoNaoNormalizarPesoQuantidade =
      jsonObject.flgPrecoPorEstadoNaoNormalizarPesoQuantidade;
    this.flgSubtrairPedagioFrete = jsonObject.flgSubtrairPedagioFrete;

    // #region Propriedades Auxiliares
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.cfoEst = new Cfo(jsonObject.cfoEst ?? {});
    this.cfoPais = new Cfo(jsonObject.cfoPais ?? {});
    this.cfoEstDif = new Cfo(jsonObject.cfoEstDif ?? {});
    this.cfoPaisDif = new Cfo(jsonObject.cfoPaisDif ?? {});
    this.tituloEspecie = new TituloEspecie(jsonObject.tituloEspecie ?? {});
    this.grupoIcmsFob = new GrupoIcms(jsonObject.grupoIcmsFob ?? {});
    this.grupoIcmsCif = new GrupoIcms(jsonObject.grupoIcmsCif ?? {});
    this.tipoEmbarqueEstados = (jsonObject.tipoEmbarqueEstados ?? []).map(
      (vmex) => new TipoEmbarqueEstado(vmex)
    );
    this.empresas = jsonObject.empresas
      ? jsonObject.empresas.map((item) => new Empresa(item ?? {}))
      : [];
    this.tipoEmbarqueEmpresas = (jsonObject.tipoEmbarqueEmpresas ?? []).map(
      (vmex) => new TipoEmbarqueEmpresa(vmex)
    );

    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
