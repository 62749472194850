/* eslint-disable import/extensions */
import { formatAnoMesRef } from './energiaConta';

export default class EnergiaContaConsumo {
  nrSeqEnergiaConta: number;

  anoMesRef: number;

  anoMesRefFormated: string;

  vlrConsumo: number;

  nrDias: number;

  nrSeqEnergiaConsumo: number;

  status: string;

  constructor(jsonObject: EnergiaContaConsumo) {
    this.nrSeqEnergiaConta = jsonObject.nrSeqEnergiaConta;
    this.anoMesRef = jsonObject.anoMesRef;
    this.anoMesRefFormated = formatAnoMesRef(jsonObject.anoMesRef.toString());
    this.vlrConsumo = jsonObject.vlrConsumo;
    this.nrDias = jsonObject.nrDias;
    this.nrSeqEnergiaConsumo = jsonObject.nrSeqEnergiaConsumo;
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
