import Veiculo from 'core/models/FRO/veiculo';

export default class ApoliceSeguroVeiculo {
  constructor(jsonObject = {}) {
    this.nrSeqApoliceSeguroVeiculo = jsonObject.nrSeqApoliceSeguroVeiculo;
    this.vlrBonus = jsonObject.vlrBonus;
    this.nrSeqApoliceSeguro = jsonObject.nrSeqApoliceSeguro;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
  }

  toJson = () => JSON.stringify(this);
}
