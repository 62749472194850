import React, { useRef, useEffect, useState } from 'react';
import { Modal, GridView, Textbox, Notification, Loader } from 'ui/components';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { getManifestoMdfeList } from 'core/services/FRO/manifestoMdfe';
// @ts-expect-error
import { ColumnDataTypes, ColumnTypes } from 'ui/Helpers/enums.ts';

import { ManifestoMdfe } from 'core/models/FRO';

interface Props {
  data: any;
  show: boolean;
  onClose: () => void;
}

export default function ModalDetalhes({
  data: manifestoCte,
  show,
  onClose,
}: Props) {
  const [data, setData] = useState<any>(manifestoCte);
  const [message, setMessage] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const gridView = useRef<any>();

  const load = async (): Promise<void> => {
    setLoading(true);

    if (show) {
      const {
        status,
        message: msg,
        data: manifestos,
      } = await getManifestoMdfeList({
        nrSeqManifestoCte: manifestoCte.nrSeqManifestoCte,
      });

      if (msg)
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });

      if (status === ResponseStatus.Success) {
        if (gridView && gridView.current)
          gridView.current.setDataSource(manifestos);

        setData({
          ...data,
          nrChaveMdfe: manifestos[0]?.nrChaveMdfe,
        });
      }
    }

    setLoading(false);
  };

  useEffect((): void => {
    (async (): Promise<void> => {
      await load();
    })();
  }, [show]);

  const onSend = (): void => {
    onClose();
  };

  const onDownloadXml = (e: ManifestoMdfe): void => {
    if (e.noXml) {
      const xmlURI = encodeURIComponent(e.noXml);
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:text/plain;charset=utf-8,${xmlURI}`;
      downloadLink.download = `${e.cdTipoSolicitacao}-${e.nrChaveMdfe}.xml`;
      downloadLink.click();
    } else {
      setMessage({
        message: 'XML não encontrado.',
        theme: Theme.Danger,
      });
    }
  };

  const columns: Array<any> = [
    {
      key: 'dtSolicitacao',
      title: 'Data Solicitação',
      format: ColumnDataTypes.DateTime,
    },
    { key: 'cdTipoSolicitacao', title: 'Solicitação' },
    {
      key: 'dtAutorizacao',
      title: 'Data Autorização',
      format: ColumnDataTypes.DateTime,
    },
    { key: 'nrProtAutorizacao', title: 'Protocolo de Autorização' },
    {
      key: 'nrSeqColetaEmbarqueCTE',
      title: 'Download XML',
      type: ColumnTypes.Button,
      onClick: (e: any) => onDownloadXml(e),
      theme: Theme.Info,
      icon: ['fa', 'download'],
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download XML',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    // @ts-expect-error
    <Modal
      show={show}
      title='Detalhes MDFe'
      onClose={onSend}
      size={BootstrapSizes.ExtraLarge}
    >
      {/* @ts-expect-error */}
      <Modal.Body>
        {message && (
          <div className='mb-3'>
            {/* @ts-expect-error */}
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <Loader loading={loading} />
        <div className='row'>
          <div className='row mb-3'>
            <div className='col-5'>
              {/* @ts-expect-error */}
              <Textbox label='Chave MDFe' readOnly text={data?.nrChaveMdfe} />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <GridView
                ref={gridView}
                // @ts-expect-error
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columns}
                offlineData
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
