import React, { useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getConvenioMassivoPaginated,
  printConvenioMassivo,
  deleteAllConvenioMassivo,
} from 'core/services/ASS/convenioMassivo';
import {
  Autocomplete,
  CSDSelPage,
  DatePicker,
  GridView,
  Textbox,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getConvenioAutoComplete } from 'core/services/ASS/convenio';
import { getAssociadoList } from 'core/services/ASS/associado';

const columns = [
  { key: 'nrSeqConvenioMassivo', type: GridView.ColumnTypes.Checkbox },
  {
    key: 'convenio.noConvenio',
    title: 'Convênio',
    sortKey: 'convenio.noConvenio',
  },
  { key: 'anoMesIni', title: 'AnoMes Inicio' },
  { key: 'anoMesFim', title: 'AnoMes Fim' },
  {
    key: 'associado.noPessoa',
    title: 'Associado',
    sortKey: 'pessoaAss.noPessoa',
  },
  { key: 'vlrParcela', title: 'Parcela', format: GridView.DataTypes.Money },
  { key: 'qtdParcela', title: 'N° de Parcelas' },
  { key: 'vlrTotal', title: 'Total', format: GridView.DataTypes.Money },
  { key: 'noObs', title: 'Observação' },
];

const formatarCompetencia = (str) => {
  const list = str.split('');

  list.splice(4, 0, '/');

  return list.join('');
};

export default function ConvenioMassivo({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const validatePeriodoCad = () => {
    const dtCadInicialPieces = filtros.dtCadInicial.split('/');

    const dataCadInicial = new Date(
      parseInt(dtCadInicialPieces[2], 10),
      parseInt(dtCadInicialPieces[1], 10) - 1,
      parseInt(dtCadInicialPieces[0], 10)
    );

    const dtCadFinalPieces = filtros.dtCadFinal.split('/');

    const dataCadFinal = new Date(
      parseInt(dtCadFinalPieces[2], 10),
      parseInt(dtCadFinalPieces[1], 10) - 1,
      parseInt(dtCadFinalPieces[0], 10)
    );

    return dataCadInicial <= dataCadFinal;
  };

  const search = async (params = null) => {
    setLoading(true);

    if (filtros.dtCadInicial || filtros.dtCadFinal) {
      if (filtros.dtCadInicial && filtros.dtCadFinal) {
        if (validatePeriodoCad) {
          const { data, pagination } = await getConvenioMassivoPaginated(
            params ?? filtros
          );

          data.forEach((e) => {
            e.anoMesIni = formatarCompetencia(e.anoMesIni);
            e.anoMesFim = formatarCompetencia(e.anoMesFim);
          });

          if (gridView && gridView.current)
            gridView.current.setDataSource(data, pagination);
        }
      } else {
        onSetMessage(
          false,
          'Insira o inicio e final do periodo de busca da Data de Cadastro'
        );
      }
    } else {
      const { data, pagination } = await getConvenioMassivoPaginated(
        params ?? filtros
      );

      data.forEach((e) => {
        e.anoMesIni = formatarCompetencia(e.anoMesIni);
        e.anoMesFim = formatarCompetencia(e.anoMesFim);
      });

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);
    }

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ConvenioMassivo/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqConvenioMassivo = sourceRow.find(
      (e) => e.key === 'nrSeqConvenioMassivo'
    );
    onOpenMaintenance(nrSeqConvenioMassivo.value);
  };

  const onPrint = async () => {
    setLoading(true);

    const { value } = await printConvenioMassivo(filtros);

    if (value) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(false, 'Erro ao Imprimir Relatório');
    }

    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllConvenioMassivo(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onBlurCompetencia = (e) => {
    if (e.length !== 7 && e !== '') {
      const today = new Date();
      const currentYear = today.getFullYear();
      let currentMonth = today.getMonth() + 1;

      let competencia;

      if (String(currentMonth).length === 1) {
        currentMonth = `0${currentMonth}`;
      }

      if (e === '0') {
        competencia = `${currentMonth}/${currentYear}`;
      } else if (e.length < 2 && e !== '0') {
        competencia = `0${e}/${currentYear}`;
      } else if (e.length >= 2 && e.length < 7) {
        competencia = `${e.slice(0, 2)}/${currentYear}`;
      }

      return competencia;
    }

    return e;
  };

  const onSearchConvenio = async (e) => {
    const { status, message: msg, convenios } = await getConvenioAutoComplete({
      noConvenio: e,
      flgConvenioMassivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return convenios;
  };

  const onSearchAssociado = async (e) => {
    const { status, message: msg, associados } = await getAssociadoList({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return associados;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Convênio Massivo'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Convenio'
            searchDataSource={onSearchConvenio}
            selectedItem={filtros.convenio}
            onSelectItem={(convenio) => {
              setFiltros({
                ...filtros,
                convenio,
                nrSeqConvenio: convenio.nrSeqConvenio,
              });
            }}
            dataSourceTextProperty='noConvenio'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Associado'
            searchDataSource={onSearchAssociado}
            selectedItem={filtros.associado}
            onSelectItem={(associado) => {
              setFiltros({
                ...filtros,
                associado,
                nrSeqPessoaAss: associado.nrSeqPessoaAss,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-2'>
          <Textbox
            label='Competência'
            maxLength={7}
            mask={MaskTypes.Competence}
            text={filtros.competencia}
            onChangedValue={(competencia) => {
              setFiltros({ ...filtros, competencia });
            }}
            onBlur={(_, value) => {
              const competencia = onBlurCompetencia(value);

              setFiltros({ ...filtros, competencia });
            }}
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data de Cadastro Inicial'
            text={filtros.dtCadInicial}
            mask={MaskTypes.Date}
            onChange={(dtCadInicial) =>
              setFiltros({ ...filtros, dtCadInicial })
            }
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data de Cadastro Final'
            text={filtros.dtCadFinal}
            mask={MaskTypes.Date}
            onChange={(dtCadFinal) => setFiltros({ ...filtros, dtCadFinal })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            enableExcelExport
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
