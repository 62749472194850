import qs from 'qs';
import Pagination from 'core/models/pagination';
import PocPocoDesenvolvimento from 'core/models/POC/pocPocoDesenvolvimento';
import api from '../api';

const baseURL = `/POC/PocPocoDesenvolvimento`;

export const getPocPocoDesenvolvimentoList = async (filters) => {
  const response = await api.get(`/$PocPocoDesenvolvimento/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPocoDesenvolvimento(json)) ?? [],
  };
};

export const getPocPocoDesenvolvimentoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPocoDesenvolvimento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocPocoDesenvolvimento = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocPocoDesenvolvimento(value) : {};
};

export const savePocPocoDesenvolvimento = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePocPocoDesenvolvimento = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPocPocoDesenvolvimento = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocPocoDesenvolvimento = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocPocoDesenvolvimento = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const sortPocPocoDesenvolvimento = async (data) => {
  const response = await api.post(`${baseURL}/Sort`, data);
  const { value } = response.data ?? {};
  return {
    totalDesenvolvimentos: value?.totalDesenvolvimentos,
    listaDesenvolvimento:
      value?.desenvolvimentos.map((json) => new PocPocoDesenvolvimento(json)) ??
      [],
  };
};
