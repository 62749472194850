import React, { useRef, useState, useEffect } from 'react';

import {
  Button,
  DatePicker,
  GridView,
  Modal,
  Notification,
} from 'ui/components';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import { AlteracaoVencimentoTituloPagar } from 'core/services/FIN/tituloPagar';

export default function ModalAlteracaoVencimento(props) {
  const [data, setData] = useState();
  const [messageState, setMessage] = useState(null);
  const gridView = useRef();

  const { show, alteracaoVencimentoValor } = props;

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  useEffect(() => {
    if (alteracaoVencimentoValor !== undefined) {
      setData({ ...data });
    }
    gridView?.current?.setDataSource(alteracaoVencimentoValor);
  }, [show]);

  const cancelar = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
    });
  };

  const salvar = async () => {
    const lista = gridView.current.getDataSource();

    const obj = {
      TituloPagarAgrupado: lista,
      dtVencimento: data.dtVencimento,
    };

    const { status, message: msg } = await AlteracaoVencimentoTituloPagar(obj);

    if (status === ResponseStatus.Success) {
      if (gridView && gridView.current) gridView.current.setDataSource(null);
    }
    onSetMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    if (status === ResponseStatus.Success) {
      props.onClose({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
  };

  const columns = [
    {
      key: 'nrTit',
      title: 'Nr Tit',
    },
    {
      key: 'nrOrdem',
      title: 'Nr Ordem',
    },
    {
      key: 'fornecedor.noFornecedor',
      title: 'Fornecedor',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor Do Titulo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrPago',
      title: 'Valor Pago',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrSaldoTit',
      title: 'Valor Saldo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'dtVencimento',
      title: 'Data Vencimento',
      format: GridView.DataTypes.Date,
    },
  ];

  return (
    <Modal
      show={show}
      title='Alteração Data Vencimento'
      size={BootstrapSizes.Large}
    >
      <Modal.Body className='p-4 pt-3'>
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='row mb-3'>
          <div className='col-4 mb-3'>
            <DatePicker
              label='Nova Data De Vencimento'
              text={data?.dtVencimento}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtVencimento) => setData({ ...data, dtVencimento })}
            />
          </div>
        </div>

        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>

        <div className='row'>
          <div className='col-4 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Salvar'
              onClick={salvar}
            />
          </div>
          <div className='col-2 mt-3'>
            <Button
              theme={Theme.Danger}
              template={Button.Templates.Button}
              text='Sair'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
