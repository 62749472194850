import { Almoxarifado } from 'core/models/EST';

export default class AlmoxarifadoLocal {
  constructor(jsonObject = {}) {
    this.nrNivel = jsonObject.nrNivel;
    this.nrSeq = jsonObject.nrSeq;
    this.nrSeqAlmoxarifadoLocal = jsonObject.nrSeqAlmoxarifadoLocal;
    this.flgAtivo = jsonObject.flgAtivo;
    this.nrSeqAlmoxarifadoLocalPai = jsonObject.nrSeqAlmoxarifadoLocalPai;
    this.nrSeqAlmoxarifado = jsonObject.nrSeqAlmoxarifado;
    this.noAlmoxarifadoLocal = jsonObject.noAlmoxarifadoLocal;
    this.flgItem = jsonObject.flgItem;
    this.flgSomenteComSaldo = jsonObject.flgSomenteComSaldo;
    this.qteMaximo = jsonObject.qteMaximo;
    this.qteMinimo = jsonObject.qteMinimo;
    this.vlrBaseServico = jsonObject.vlrBaseServico;
    this.saldo = jsonObject.saldo;
    this.almoxarifado = new Almoxarifado(jsonObject.almoxarifado ?? {});

    // #region Propriedades auxiliares
    this.noProduto = jsonObject.noProduto;
    this.noLocalCompleto = jsonObject.noLocalCompleto;
    this.nrSeqAlmoxarifadoLocalOld = jsonObject.nrSeqAlmoxarifadoLocalOld;
    this.saldoAtual = jsonObject.saldoAtual;
    this.noColorPendencia = jsonObject.noColorPendencia;
    this.noStatusQuantidade = jsonObject.noStatusQuantidade;
    this.flgItemProduto = jsonObject.flgItemProduto;
    this.nrSeqProduto = jsonObject.nrSeqProduto;
    //  #endregion
  }

  toJson = () => JSON.stringify(this);
}
