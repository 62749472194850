import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes } from 'ui/Helpers/utils';
import { CSDManutPage, Textbox } from 'ui/components';

import {
  getProfissaoTs,
  saveProfissao,
  deleteProfissao,
  // @ts-expect-error
} from 'core/services/SEG/profissaoTs.ts';

// @ts-expect-error
import { Message, Page } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { ResponseStatus, Theme } from 'ui/Helpers/enums.ts';
import { ProfissaoTs as Profissao } from 'core/models/SEG';

export default function ProfissaoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit = useRef<any>();
  const [profissao, setProfissao] = useState<Profissao | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const load = useCallback(async (): Promise<void> => {
    if (registryKey) {
      setLoading(true);

      const profissaoData: Profissao = await getProfissaoTs(registryKey);

      setProfissao(profissaoData);
      setLoading(false);
    } else setProfissao(null);
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func(): Promise<void> {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func(): Promise<void> {
      await load();
    })();
  }, [load, reload]);

  const save = async (): Promise<void> => {
    setLoading(true);
    const { status, message: msg } = await saveProfissao(profissao);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setProfissao(status === ResponseStatus.Success ? null : profissao);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async (): Promise<void> => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteProfissao(registryKey);

      setProfissao(status === ResponseStatus.Success ? null : profissao);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Profissões'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setProfissao(null)}
      onSave={save}
      onDelete={profissao && profissao?.nrSeqProfissao > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-4'>
          {/* @ts-ignore */}
          <Textbox
            label='Código'
            text={profissao?.cdProfissao ?? ' '}
            onChangedValue={(cdProfissao: string) => {
              if (profissao)
                setProfissao({
                  ...profissao,
                  cdProfissao,
                });
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-7'>
          {/* @ts-ignore */}
          <Textbox
            label='Descrição'
            text={profissao?.noProfissao}
            required
            onChangedValue={(noProfissao: string) => {
              if (profissao) setProfissao({ ...profissao, noProfissao });
            }}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
