import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  JustifyContent,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  Switch,
  Panel,
} from 'ui/components';
import {
  getTipoDespesaAcerto,
  saveTipoDespesaAcerto,
  deleteTipoDespesaAcerto,
} from 'core/services/FIN/tipoDespesaAcerto';

import { getTipoDespesaAutoComplete } from 'core/services/FIN/tipoDespesa';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getEmpresaDefaultUsuario } from 'core/services/SEG/empresa';

export default function TipoDespesaAcertoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({
    flgPedagio: false,
    flgComissao: false,
    flgAfetaResultado: false,
    flgAbastecimento: false,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  const onNew = () => {
    setData({
      flgPedagio: false,
      flgComissao: false,
      flgAfetaResultado: false,
      flgAbastecimento: false,
    });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const objTipoDespesaAcerto = await getTipoDespesaAcerto(registryKey);

      if (objTipoDespesaAcerto != null) {
        setData(objTipoDespesaAcerto);
      }
      setLoading(false);
    } else {
      onNew();
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveTipoDespesaAcerto(data);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      onNew();
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteTipoDespesaAcerto(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchTipoDespesa = async (e) => {
    const {
      status,
      message: msg,
      tipoDespesas,
    } = await getTipoDespesaAutoComplete({ noTipoDespesa: e });
    if (msg) onSetMessage(status, msg);
    return tipoDespesas;
  };

  const onSearchFormaPagamentoAVista = async (e) => {
    const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;
    const { empresas } = await getEmpresaDefaultUsuario({
      nrSeqUsuario: sessionUser.nrSeqUsuario,
    });

    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitPag: true,
      nrSeqEmpresa: empresas[0].nrSeqEmpresa,
    });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Tipo Despesa Acerto'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      onDelete={data?.nrSeqTipoDespesaAcerto > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <Panel>
          <Panel.Header
            title='Manutenção'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row'>
              <div className='col-2 mb-3'>
                <Textbox
                  maxLength={20}
                  label='Codigo'
                  text={data.cdTipoDespesaAcerto}
                  onChangedValue={(cdTipoDespesaAcerto) =>
                    setData({ ...data, cdTipoDespesaAcerto })
                  }
                />
              </div>
              <div className='col-2 mb-3'>
                <Textbox
                  maxLength={100}
                  label='Nome Tipo Despesa Acerto'
                  text={data.noTipoDespesaAcerto}
                  onChangedValue={(noTipoDespesaAcerto) =>
                    setData({ ...data, noTipoDespesaAcerto })
                  }
                />
              </div>

              <div className='col-4 mb-3'>
                <Autocomplete
                  label='TipoDespesa'
                  searchDataSource={onSearchTipoDespesa}
                  selectedItem={data.tipoDespesa}
                  onSelectItem={(tipoDespesa) => {
                    setData({
                      ...data,
                      tipoDespesa,
                      nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
                    });
                  }}
                  dataSourceTextProperty='noTipoDespesa'
                />
              </div>
              <div className='col-4 mb-3'>
                <Autocomplete
                  label='Forma Pagamento A Vista'
                  searchDataSource={onSearchFormaPagamentoAVista}
                  selectedItem={data.formaPagamento}
                  onSelectItem={(formaPagamento) => {
                    setData({
                      ...data,
                      formaPagamento,
                      nrSeqFormaPagamentoAvista:
                        formaPagamento.nrSeqFormaPagamento,
                    });
                  }}
                  dataSourceTextProperty='noFormaPagamento'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-2 mb-3'>
                <Switch
                  formControl
                  label='FlgPedagio'
                  checked={data.flgPedagio}
                  onChange={(flgPedagio) => setData({ ...data, flgPedagio })}
                />
              </div>
              <div className='col-2 mb-3'>
                <Switch
                  formControl
                  label='Abastecimento'
                  checked={data.flgAbastecimento}
                  onChange={(flgAbastecimento) =>
                    setData({ ...data, flgAbastecimento })
                  }
                />
              </div>
              <div className='col-2 mb-3'>
                <Switch
                  formControl
                  label='FlgComissao'
                  checked={data.flgComissao}
                  onChange={(flgComissao) => setData({ ...data, flgComissao })}
                />
              </div>
              <div className='col-2 mb-3'>
                <Switch
                  formControl
                  label='FlgAfetaResultado'
                  checked={data.flgAfetaResultado}
                  onChange={(flgAfetaResultado) =>
                    setData({ ...data, flgAfetaResultado })
                  }
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    </CSDManutPage>
  );
}
