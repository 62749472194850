export default class ResultadoVeiculo {
  constructor(jsonObject) {
    this.placaPrinc = jsonObject.placaPrinc;
    this.placaComp = jsonObject.placaComp;
    this.placaCompB = jsonObject.placaCompB;
    this.noMotorista = jsonObject.noMotorista;
    this.dtSaidaIni = jsonObject.dtSaidaIni;
    this.dtSaidaFim = jsonObject.dtSaidaFim;
    this.dtChegadaIni = jsonObject.dtChegadaIni;
    this.dtChegadaFim = jsonObject.dtChegadaFim;
    this.vlrReceitaAcerto = jsonObject.vlrReceitaAcerto;
    this.vlrDespesaAcerto = jsonObject.vlrDespesaAcerto;
    this.vlrAbastecimento = jsonObject.vlrAbastecimento;
    this.vlrDespesaTitulos = jsonObject.vlrDespesaTitulos;
    this.vlrDespesaManutencao = jsonObject.vlrDespesaManutencao;
    this.vlrLucro = jsonObject.vlrLucro;
    this.vlrLucroPorKm = jsonObject.vlrLucroPorKm;
    this.vlrPercentual = jsonObject.vlrPercentual;
    this.vlrPedagio = jsonObject.vlrPedagio;
    this.kmPercorrido = jsonObject.kmPercorrido;
    this.noPlanoDeConta = jsonObject.noPlanoDeConta;
    this.noCentroDeCusto = jsonObject.noCentroDeCusto;

    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.deMesAno = jsonObject.deMesAno;
    this.ateMesAno = jsonObject.ateMesAno;
    this.tipoBusca = jsonObject.tipoBusca;
    this.tipoPeriodo = jsonObject.tipoPeriodo;

    this.vlrAbastecimentoVinculado = jsonObject.vlrAbastecimentoVinculado;
    this.vlrAbastecimentoNaoVinculado = jsonObject.vlrAbastecimentoNaoVinculado;

    this.receitas = jsonObject.receitas ?? [];
    this.despesas = jsonObject.despesas ?? [];
    this.acertosMotoristaFromVeiculo =
      jsonObject.acertosMotoristaFromVeiculo ?? [];
  }

  toJson = () => JSON.stringify(this);
}
