import qs from 'qs';
import Pagination from 'core/models/pagination';
import PocPerflitFormacao from 'core/models/POC/pocPerflitFormacao';
import api from '../api';

const baseURL = `/POC/PocPerflitFormacao`;

export const getPocPerflitFormacaoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    formacao: value?.map((json) => new PocPerflitFormacao(json)) ?? [],
  };
};

export const getPocPerflitFormacaoList = async (filters) => {
  const response = await api.get(`/$PocPerflitFormacao/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPerflitFormacao(json)) ?? [],
  };
};

export const getPocPerflitFormacaoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPerflitFormacao(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocPerflitFormacao = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocPerflitFormacao(value) : {};
};

export const savePocPerflitFormacao = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePocPerflitFormacao = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPocPerflitFormacao = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocPerflitFormacao = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocPerflitFormacao = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
