import qs from 'qs';
import Pagination from 'core/models/pagination';
import ChecklistFrotaRetorno from 'core/models/FRO/checklistFrotaRetorno';
import ChecklistFrotaRetornoModel from 'core/models/FRO/checklistFrotaRetornoModel';
import api from '../api';

const baseURL = `/FRO/ChecklistFrotaRetorno`;

export const getChecklistFrotaRetornoList = async (filters) => {
  const response = await api.get(`/$ChecklistFrotaRetorno/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ChecklistFrotaRetorno(json)) ?? [],
  };
};

export const getChecklistFrotaRetornoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ChecklistFrotaRetorno(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getChecklistFrotaRetorno = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ChecklistFrotaRetorno(value) : {};
};
export const getChecklistFrotaManutencaoRetorno = async (filters) => {
  const response = await api.post(
    `${baseURL}/SearchChecklistFrotaManutencao`,
    filters
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ChecklistFrotaRetornoModel(json)) ?? [],
  };
};

export const saveChecklistFrotaRetorno = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteChecklistFrotaRetorno = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printChecklistFrotaRetorno = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelChecklistFrotaRetorno = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllChecklistFrotaRetorno = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
