import Chip from './chip';

export default class GrupoLinhaChip {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoLinhaChip = jsonObject.nrSeqGrupoLinhaChip;
    this.nrSeqChip = jsonObject.nrSeqChip;
    this.nrSeqGrupoLinha = jsonObject.nrSeqGrupoLinha;
    this.noObservacoes = jsonObject.noObservacoes;

    this.chip = new Chip(jsonObject.chip ?? {});
  }

  toJson = () => JSON.stringify(this);
}
