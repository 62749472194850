import Convenio from './convenio';
import TipoConvenioEncargo from './tipoConvenioEncargo';

export default class ConvenioEncargo {
  constructor(jsonObject = {}) {
    this.nrSeqConvenioEncargo = jsonObject.nrSeqConvenioEncargo;
    this.nrSeqConvenio = jsonObject.nrSeqConvenio;
    this.nrSeqTipoConvenioEncargo = jsonObject.nrSeqTipoConvenioEncargo;
    this.noConvenioEncargo = jsonObject.noConvenioEncargo;
    this.flgOrigemConvenio = jsonObject.flgOrigemConvenio;
    this.convenio = new Convenio(jsonObject.convenio ?? {});
    this.tipoConvenioEncargo = new TipoConvenioEncargo(
      jsonObject.tipoConvenioEncargo ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
