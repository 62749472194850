import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus } from 'ui/Helpers/utils';
import { getResultadoOrcamentoPagined } from 'core/services/POC/pocOrcamento';
import {
  CSDSelPage,
  GridView,
  Autocomplete,
  Textbox,
  DatePicker,
  DropdownMulti,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getPocTipoOrcamentoAutoComplete } from 'core/services/POC/pocTipoOrcamento';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';

const columns = [
  { key: 'nrSeqPocOrcamento', visible: false },
  { key: 'cdProposta', title: 'Código Orçamento' },
  { key: 'noPessoa', title: 'Pessoa' },
  { key: 'documento', title: 'Documento' },
  { key: 'noLocalObra', title: 'Local da Obra' },
  {
    key: 'vlrTotalOrcamento',
    title: 'Orçamento',
    format: GridView.DataTypes.Decimal,
  },
];

export default function ResultadoOrcamento({
  transaction,
  onOpenPage,
  isActive,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});
  const dropDownCliente = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getResultadoOrcamentoPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getResultadoOrcamentoPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ResultadoOrcamento/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqPocOrcamento = sourceRow.find(
      (e) => e.key === 'nrSeqPocOrcamento'
    );
    onOpenMaintenance(nrSeqPocOrcamento.value);
  };

  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
      flgIncluirEndereco: false,
    });
    onSetMessage(status, msg);
    return clientes;
  };

  const onSearchTipoOrcamento = async (e) => {
    const {
      status,
      message: msg,
      data: tipos,
    } = await getPocTipoOrcamentoAutoComplete({
      noTipoOrcamento: e,
    });
    onSetMessage(status, msg);
    return tipos;
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  useEffect(() => {
    dropDownCliente.current.loadDataSource(onSearchCliente);
  }, []);

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Resultado Orçamento'
      loading={loading}
      onSearch={() => search()}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-6'>
          <DropdownMulti
            label='Cliente'
            ref={dropDownCliente}
            dataSourcePropertyText='noPessoa'
            dataSourcePropertyValue='nrSeqPessoaCli'
            selectedItems={selectedItems.cliente ?? []}
            onSelectItem={(cliente) => {
              setSelectedItems({ ...selectedItems, cliente });
              setFiltros({
                ...filtros,
                nrSeqPessoaCliS: cliente?.map((p) => p.nrSeqPessoaCli),
              });
            }}
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Código Proposta'
            text={filtros.cdProposta}
            onChangedValue={(cdProposta) => {
              setFiltros({
                ...filtros,
                cdProposta,
              });
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-5'>
          <Autocomplete
            label='Tipo Orçamento'
            searchDataSource={onSearchTipoOrcamento}
            selectedItem={filtros.tipoOrcamento}
            onSelectItem={(tipoOrcamento) => {
              filtros.nrSeqPocTipoOrcamento = setFiltros({
                ...filtros,
                tipoOrcamento,
                nrSeqPocTipoOrcamento: tipoOrcamento.nrSeqPocTipoOrcamento,
              });
            }}
            dataSourceTextProperty='noPocTipoOrcamento'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-2 mb-3'>
          <DatePicker
            label='Validade Orçamento Início'
            text={filtros.dtValidadeInicio}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtValidadeInicio) => {
              setFiltros({
                ...filtros,
                dtValidadeInicio,
              });
            }}
          />
        </div>
        <div className='col-2 mb-3'>
          <DatePicker
            label='Validade Orçamento Fim'
            text={filtros.dtValidadeFim}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtValidadeFim) => {
              setFiltros({
                ...filtros,
                dtValidadeFim,
              });
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
