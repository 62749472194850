import qs from 'qs';
import LancamentoAssociado from 'core/models/ASS/lancamentoAssociado';
import { ContaCabecalho } from 'core/models/TEL';
import { MimeTypes } from 'ui/Helpers/utils';
import Pagination from 'core/models/pagination';
import api from '../api';

const baseURL = `/ASS/LancamentoAssociado`;

export const getLancamentosPaged = async (filters) => {
  const response = await api.get(`${baseURL}/SearchLancamentosPaged`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  const lancamentos = value?.lancamentos ?? [];
  const resumo = value?.resumo ?? {};

  return {
    status,
    message,
    data: lancamentos?.map((json) => new LancamentoAssociado(json)),
    resumo,
    pagination: new Pagination(pagination),
  };
};

export const getLancamentoAssociado = async (id) => {
  const response = await api.get(`${baseURL}/GetLancamentoAssociado`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new LancamentoAssociado(value) : {};
};

export const importarArquivoConvenio = async (file, data) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('extension', MimeTypes.getExtension(file.type));
  formData.append('nrSeqConvenio', data.nrSeqConvenio);
  formData.append('anoMes', data.anoMes);

  const response = await api.post(`${baseURL}/ImportarConvenio`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data ?? {};
};

export const getLancamentosConvenioManual = async (filters) => {
  const response = await api.get(`${baseURL}/SearchAssPlanoConLanManual`, {
    params: filters,
  });
  const { value } = response.data ?? {};
  return value ? new LancamentoAssociado(value) : {};
};

export const saveLancamentoManual = async (data) => {
  const response = await api.post(`${baseURL}/SaveLancamentoManual`, data);
  return response.data ?? {};
};

export const gerarLancamentos = async (data) => {
  const response = await api.post(`${baseURL}/GerarLancamentos`, data);
  return response.data ?? {};
};

export const getContratosPendenteGeracaoConvenio = async (idOperadora) => {
  const response = await api.get(
    `${baseURL}/SearchContratosPendenteGeracaoConvenio`,
    {
      params: { idOperadora },
    }
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ContaCabecalho(json)) ?? [],
  };
};

export const associar = async (data) => {
  const response = await api.post(`${baseURL}/Associar`, data);
  return response.data ?? {};
};

export const deleteAllBySearch = async (filters) => {
  const response = await api.delete(`${baseURL}/DeleteAllBySearch`, {
    params: filters,
  });
  return response.data ?? {};
};

export const atualizar = async (filters) => {
  const response = await api.post(`${baseURL}/Atualizar`, filters);

  return response.data ?? {};
};

export const exportar = async (filters) => {
  const response = await api.get(`${baseURL}/ExportarLancamentos`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printRelFinanceiro = async (filters) => {
  const response = await api.get(`${baseURL}/PrintRelFinanceiro`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const printBoletoDetalhadoAssociado = async (filters) => {
  const response = await api.get(`${baseURL}/PrintBoletoDetalhadoAssociado`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printBoletoAssociado = async (filters) => {
  const response = await api.get(`${baseURL}/PrintBoletoAssociado`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printRelatorioMensalAssociadoDetalhado = async (filters) => {
  const response = await api.get(
    `${baseURL}/PrintLancamentoMensalAssociadoDetalhado`,
    {
      params: filters,
    }
  );
  return response.data ?? {};
};

export const printResultadoPorCompetencia = async (filters) => {
  const response = await api.get(`${baseURL}/PrintResultadoPorCompetencia`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printRelFinanceiroAgrupado = async (filters) => {
  const response = await api.get(`${baseURL}/PrintRelFinanceiroAgrupado`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printRelAgrupadoTitular = async (filters) => {
  const response = await api.get(`${baseURL}/PrintRelAgrupadoTitular`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printRelMensalCompetencia = async (filters) => {
  const response = await api.get(`${baseURL}/PrintRelMensalCompetencia`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printRelMensalSemMatricula = async (filters) => {
  const response = await api.get(`${baseURL}/PrintRelMensalSemMatricula`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printRelComissoes = async (filters) => {
  const response = await api.get(`${baseURL}/PrintRelComissoes`, {
    params: filters,
  });
  return response.data ?? {};
};
export const printRelBonificacao = async (filters) => {
  const response = await api.get(`${baseURL}/PrintRelBonificacao`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printRelComissoesPorVendedor = async (filters) => {
  const response = await api.get(`${baseURL}/PrintRelComissoesPorVendedor`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printRelLancamentoAssociado = async (filters) => {
  const response = await api.get(`${baseURL}/PrintRelLancamentoAssociado`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printRelLancamentoMensalPorAssociado = async (filters) => {
  const response = await api.get(
    `${baseURL}/PrintRelLancamentoMensalPorAssociado`,
    {
      params: filters,
    }
  );
  return response.data ?? {};
};
