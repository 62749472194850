import qs from 'qs';
import Pagination from 'core/models/pagination';
import DemandaDocumento from 'core/models/HELP/demandaDocumento';
import { MimeTypes } from 'ui/Helpers/utils';
import api from '../api';

const baseURL = `/HELP/DemandaDocumento`;

export const uploadDocumento = async (file, data) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('extension', MimeTypes.getExtension(file.type));
  formData.append('nrseqdemanda', data);
  const response = await api.post(`${baseURL}/UploadContrato`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    retorno: value,
  };
};

export const uploadDocumentos = async (files, data) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i += 1) {
    formData.append('files', files[i]);
    formData.append('extension', MimeTypes.getExtension(files[i].type));
    formData.append('nrseqdemanda', data);
  }

  const response = await api.post(`${baseURL}/UploadDocumentos`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const { status, message } = response.data ?? {};

  return {
    status,
    message,
  };
};

export const getDemandaDocumentoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    documentos: value?.map((json) => new DemandaDocumento(json)) ?? [],
  };
};

export const getDemandaDocumentoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new DemandaDocumento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getDemandaDocumento = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new DemandaDocumento(value) : {};
};

export const saveDemandaDocumento = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteDemandaDocumento = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printDemandaDocumento = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelDemandaDocumento = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllDemandaDocumento = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
