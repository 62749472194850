import React, {
  forwardRef,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {
  GridView,
  Textbox,
  Autocomplete,
  Button,
  Textarea,
} from 'ui/components';
import { Theme, BootstrapSizes } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import { getPocMetodoDesenvolvimentoAutoComplete } from 'core/services/POC/metodoDesenvolvimento';
import PocPocoDesenvolvimento from 'core/models/POC/pocPocoDesenvolvimento';
import { sortPocPocoDesenvolvimento } from 'core/services/POC/pocPocoDesenvolvimento';

import { TransacationCodes } from 'ui/Helpers/transactionCodes';

const CadastroDesenvolvimento = forwardRef(
  ({
    onOpenTransaction,
    findTransaction,
    setData,
    data,
    onValidaCampoObrigatorioDesenvolvimento,
  }) => {
    const gridView = useRef();
    const [dataDesenvolvimento, setDataDesenvolvimento] = useState(
      new PocPocoDesenvolvimento({ nrInicio: 0 })
    );

    const [totalDesenvolvimentos, setTotalDesenvolvimentos] = useState();

    const load = useCallback(async () => {
      const listaDesenvolvimento = data.desenvolvimentos ?? [];
      listaDesenvolvimento.sort((a, b) => (a.nrTempo > b.nrTempo ? 1 : -1));

      const lista = { desenvolvimentos: listaDesenvolvimento };
      const dataordened = await sortPocPocoDesenvolvimento(lista);

      setTotalDesenvolvimentos(dataordened.totalDesenvolvimentos);

      if (gridView && gridView.current)
        gridView.current.setDataSource(listaDesenvolvimento);
    }, []);

    useEffect(() => {
      (async function func() {
        await load();
      })();
    }, []);

    const onAdicionarDesenvolvimento = async () => {
      if (!onValidaCampoObrigatorioDesenvolvimento(dataDesenvolvimento)) return;
      const listaDesenvolvimento = gridView.current.getDataSource() ?? [];

      const obj = new PocPocoDesenvolvimento({
        ...dataDesenvolvimento,
        nrSeqPocPoco: data.nrSeqPocPoco,
        status: dataDesenvolvimento.status ?? GridView.EnumStatus.Inserir,
      });

      listaDesenvolvimento.push(obj);

      const listaOrdenada = {
        desenvolvimentos: listaDesenvolvimento,
      };
      const dataordened = await sortPocPocoDesenvolvimento(listaOrdenada);

      setTotalDesenvolvimentos(dataordened.totalDesenvolvimentos);
      setData({
        ...data,
        desenvolvimentos: listaDesenvolvimento,
      });

      data.desenvolvimentos = listaDesenvolvimento;

      if (gridView && gridView.current)
        gridView.current.setDataSource(listaDesenvolvimento);
      setDataDesenvolvimento({});
    };

    const onEditarDesenvolvimento = async (e, datasource) => {
      const dataDevS = datasource.filter((desenv) => desenv !== e);
      const dataDev = datasource.filter((desenv) => desenv === e);

      const desenvItem = {
        nrSeqPocMetodoDesenvolvimento: dataDev[0].nrSeqPocMetodoDesenvolvimento,
        noPocMetodoDesenvolvimento: dataDev[0].noPocMetodoDesenvolvimento,
        cdPocMetodoDesenvolvimento: dataDev[0].cdPocMetodoDesenvolvimento,
      };

      setDataDesenvolvimento({
        ...dataDev[0],
        status: GridView.EnumStatus.Alterar,
        desenvolvimento: desenvItem,
      });

      if (gridView && gridView.current)
        gridView.current.setDataSource(dataDevS);
    };

    const onRemoveDesenvolvimento = async (e, datasource) => {
      const dataDesS = datasource.filter((des) => des !== e);
      const obj = { ...e, status: GridView.EnumStatus.Remover };
      dataDesS.push(obj);
      if (gridView && gridView.current)
        gridView.current.setDataSource(dataDesS);
      setData({
        ...data,
        desenvolvimentos: dataDesS,
      });

      data.desenvolvimentos = dataDesS;

      const newlist = {
        desenvolvimentos: dataDesS.filter((des) => des.status !== 'Remover'),
      };
      const listOrdened = await sortPocPocoDesenvolvimento(newlist);
      setTotalDesenvolvimentos(listOrdened.totalDesenvolvimentos);
    };

    const onSearchDesenvolvimento = async (e) => {
      const { desenvolvimento } = await getPocMetodoDesenvolvimentoAutoComplete(
        {
          noPocMetodoDesenvolvimento: e,
        }
      );
      return desenvolvimento;
    };

    const columns = [
      {
        key: 'nrSeqPocPocoDesenvolvimento',
        visible: false,
      },
      { key: 'nrSeqPocPoco', title: 'NrSeqPocPoco', visible: false },
      {
        key: 'getMetodoDesenvolvimento',
        title: 'Método de Desenvolvimento',
      },
      { key: 'nrTempoStr', title: 'Tempo' },
      {
        key: 'nrSeqPocPocoDesenvolvimento',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onEditarDesenvolvimento(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },
      {
        key: 'nrSeqPocPocoDesenvolvimento',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemoveDesenvolvimento(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    return (
      <div>
        <div className='row mb-3'>
          <div className='display-8'>7 - Desenvolvimento</div>
        </div>
        <div className='row'>
          <div className='col'>
            <div className='card'>
              <div className='card-body'>
                <div className='row mb-3'>
                  <div className='col-6'>
                    <Autocomplete
                      label='Método de Desenvolvimento'
                      searchDataSource={onSearchDesenvolvimento}
                      selectedItem={dataDesenvolvimento.desenvolvimento}
                      findTransaction={findTransaction}
                      onOpenTransaction={onOpenTransaction}
                      transactionToOpen={
                        TransacationCodes.MetodoDesenvolvimento
                      }
                      nrseqTransactionToOpen='nrSeqPocMetodoDesenvolvimento'
                      onSelectItem={(desenvolvimento) =>
                        setDataDesenvolvimento({
                          ...dataDesenvolvimento,
                          desenvolvimento,
                          nrSeqPocMetodoDesenvolvimento:
                            desenvolvimento.nrSeqPocMetodoDesenvolvimento,
                          cdPocMetodoDesenvolvimento:
                            desenvolvimento.cdPocMetodoDesenvolvimento,
                          noPocMetodoDesenvolvimento:
                            desenvolvimento.noPocMetodoDesenvolvimento,
                        })
                      }
                      dataSourceTextProperty='noPocMetodoDesenvolvimento'
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Tempo'
                      text={dataDesenvolvimento.nrTempoStr ?? ''}
                      mask={MaskTypes.TimeHM}
                      onChangedValue={(nrTempoStr) =>
                        setDataDesenvolvimento({
                          ...dataDesenvolvimento,
                          nrTempoStr,
                        })
                      }
                    />
                  </div>

                  <div className='col-auto mt-3'>
                    <Button
                      theme={Theme.Primary}
                      template={Button.Templates.Button}
                      text='Adicionar'
                      onClick={onAdicionarDesenvolvimento}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col'>
                    <GridView
                      ref={gridView}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columns}
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  </div>

                  <div className='row mb-3'>
                    <div
                      className='col'
                      style={{
                        textAlign: 'right',
                        borderTop: '1px solid #0000002b',
                        borderBottom: '1px solid #0000002b',
                        paddingTop: '5px',
                      }}
                    >
                      <h6
                        style={{
                          padding: '2px',
                          color: '#605959',
                        }}
                      >
                        Total: {totalDesenvolvimentos}
                      </h6>
                    </div>
                  </div>
                  <div className='row' style={{ paddingBottom: '450px' }}>
                    <div className='col'>
                      <Textarea
                        maxLength={200}
                        label='Observação'
                        text={data.noPocPocoDesenvolvimentoObs}
                        onChangedValue={(noPocPocoDesenvolvimentoObs) => {
                          data.noPocPocoDesenvolvimentoObs = noPocPocoDesenvolvimentoObs;
                          setData({
                            ...data,
                            noPocPocoDesenvolvimentoObs,
                            salvarEtapa: true,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default CadastroDesenvolvimento;
