/* eslint-disable */
import qs from 'qs';
import Pagination from 'core/models/pagination';
import Tipoinfrator from 'core/models/FRO/tipoinfrator';
import api from '../api';

const baseURL = `/FRO/Tipoinfrator`;

export const getTipoinfratorList = async (filters) => {
  const response = await api.get(`/$Tipoinfrator/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Tipoinfrator(json)) ?? [],
  };

};
export const getTipoInfratorAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    tiposinfrator: value?.map((json) => new Tipoinfrator(json)) ?? [],
  };
};


export const getTipoinfratorPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Tipoinfrator(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getTipoinfrator = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Tipoinfrator(value) : {};
};

export const saveTipoinfrator = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteTipoinfrator = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printTipoinfrator = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelTipoinfrator = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllTipoinfrator = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
