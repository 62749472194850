import React from 'react';

import { changePassword } from 'core/services/SEG/usuario';
import { Button, Form, Modal, Notification, Textbox } from 'ui/components';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.formSubmit = React.createRef();
  }

  onModalChangePassClose = () => {
    this.setState({
      currentPassword: undefined,
      newPassword: undefined,
      reNewPassword: undefined,
    });

    const { onModalClose } = this.props;
    onModalClose();
  };

  onChangePass = async () => {
    const { currentPassword, newPassword, reNewPassword } = this.state;

    const { type, message } = await changePassword({
      oldpassword: currentPassword,
      newpassword: newPassword,
      newpassowrd2: reNewPassword,
    });
    if (type === 'ERROR')
      this.setState({ messages: { message, theme: 'danger' } });
    else {
      this.formSubmit.current.reset();
      this.setState({
        currentPassword: undefined,
        newPassword: undefined,
        reNewPassword: undefined,
        messages: { message, theme: 'success' },
      });
    }
  };

  render() {
    const {
      currentPassword,
      newPassword,
      reNewPassword,
      messages,
    } = this.state;
    const { show } = this.props;
    return !show ? null : (
      <Form ref={this.formSubmit} onSubmit={this.onChangePass}>
        <Modal
          show='true'
          title='Alterar senha'
          onClose={this.onModalChangePassClose}
        >
          <Modal.Body>
            {messages && (
              <Notification message={messages.message} theme={messages.theme} />
            )}

            <div className='mb-3'>
              <span htmlFor='inputCurrentPassword' className='form-label'>
                Senha Atual
                <Textbox
                  id='inputCurrentPassword'
                  type='password'
                  text={currentPassword}
                  required
                  onChangedValue={(e) => this.setState({ currentPassword: e })}
                />
              </span>
            </div>
            <div className='mb-3'>
              <span htmlFor='inputNewPassword' className='form-label'>
                Nova Senha
              </span>
              <Textbox
                id='inputNewPassword'
                type='password'
                text={newPassword}
                required
                onChangedValue={(e) => this.setState({ newPassword: e })}
              />
            </div>
            <div className='mb-3'>
              <span htmlFor='inputReNewPassword' className='form-label'>
                Confirmação Nova Senha
                <Textbox
                  id='inputReNewPassword'
                  type='password'
                  text={reNewPassword}
                  required
                  onChangedValue={(e) => this.setState({ reNewPassword: e })}
                />
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              theme='danger'
              icon='ban'
              text='Sair'
              onClick={this.onModalChangePassClose}
            />
            <Button
              theme='success'
              icon={['far', 'save']}
              text='Alterar Senha'
              type='submit'
            />
          </Modal.Footer>
        </Modal>
      </Form>
    );
  }
}

export default ChangePassword;
