import Empresa from './empresa';
import Perfil from './perfil';

export default class UsuarioPerfil {
  constructor(jsonObject = {}) {
    this.nrSeqPerfil = jsonObject.nrSeqPerfil;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.perfil = new Perfil(jsonObject.perfil ?? {});

    // Auxiliares
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
