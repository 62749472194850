import React, { useRef, useState } from 'react';
import { PageTypes } from 'ui/Helpers/utils';

// @ts-expect-error
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { ColumnTypes, Theme, ResponseStatus } from 'ui/Helpers/enums.ts';
import {
  getParametroPaged,
  deleteAllParametro,
} from 'core/services/SEG/parametro';
import { CSDSelPage, GridView, Textbox } from 'ui/components';

export default function Parametro({ transaction, onOpenPage, isActive }: Page) {
  const gridView = useRef<any>();

  const [filtros, setFiltros] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const columns: Array<GridviewColumns> = [
    { key: 'noParametro', type: ColumnTypes.Checkbox },
    { key: 'noParametro', title: 'Parâmetro' },
    { key: 'noConteudo', title: 'Conteúdo' },
    { key: 'descricao', title: 'Descrição' },
    { key: 'flgTipo', title: 'Flg Tipo' },
    { key: 'tamanho', title: 'Tamanho' },
    { key: 'nrSeqGrupoEmpresa', title: 'GrupoEmpresa' },
  ];

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getParametroPaged(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onColumnSort = async (sortBy: number): Promise<void> => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page: number): Promise<void> => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null): void => {
    onOpenPage(
      transaction.noTransacao,
      'Parametro/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow: any): void => {
    const noParametro = sourceRow.find((e: any) => e.key === 'noParametro');
    onOpenMaintenance(noParametro.value);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllParametro(
        selecteds.map((row: any) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Parametro'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-ignore */}
          <Textbox
            label='Parâmetro'
            required
            maxLength={4000}
            text={filtros?.noParametro}
            onChangedValue={(noParametro: string) =>
              setFiltros({ ...filtros, noParametro })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
