import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Textbox, Textarea } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getConvenioMassivo,
  saveConvenioMassivo,
  deleteConvenioMassivo,
} from 'core/services/ASS/convenioMassivo';
import { getConvenioAutoComplete } from 'core/services/ASS/convenio';
import { getAssociadoList } from 'core/services/ASS/associado';

const anoMesToMesAno = (anoMes) => {
  const ano = anoMes.slice(0, 4);
  const mes = anoMes.slice(4);

  return `${mes}/${ano}`;
};

export default function ConvenioMassivoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onNew = () => {
    setData({ noObs: '' });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const convenio = await getConvenioMassivo(registryKey);

      convenio.anoMesIni = anoMesToMesAno(convenio.anoMesIni);
      convenio.anoMesFim = anoMesToMesAno(convenio.anoMesFim);

      setData(convenio);

      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const validateAnoMes = () => {
    const anoMesIniPieces = data.anoMesIni.split('/');

    const dtIni = new Date(
      parseInt(anoMesIniPieces[1], 10),
      parseInt(anoMesIniPieces[0], 10) - 1,
      1
    );

    const anoMesFimPieces = data.anoMesFim.split('/');

    const dtFim = new Date(
      parseInt(anoMesFimPieces[1], 10),
      parseInt(anoMesFimPieces[0], 10) - 1,
      1
    );

    return dtIni <= dtFim;
  };

  const save = async () => {
    setLoading(true);

    if (validateAnoMes) {
      const { status, message: msg } = await saveConvenioMassivo(data);

      if (status === ResponseStatus.Success) {
        formSubmit.current.reset();

        onNew();
      }

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    } else {
      onSetMessage(
        false,
        'O campo De Mes/Ano não pode ser maior que o Até Mes/Ano'
      );
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteConvenioMassivo(registryKey);

      if (status === ResponseStatus.Success) {
        onNew();
      }

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      setLoading(false);
    }
  };

  const onSearchConvenio = async (e) => {
    const { status, message: msg, convenios } = await getConvenioAutoComplete({
      noConvenio: e,
      flgConvenioMassivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return convenios;
  };

  const onSearchAssociado = async (e) => {
    const { status, message: msg, associados } = await getAssociadoList({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return associados;
  };

  const onBlurCompetencia = (e) => {
    if (e.length !== 7 && e !== '') {
      const today = new Date();
      const currentYear = today.getFullYear();
      let currentMonth = today.getMonth() + 1;

      let competencia;

      if (String(currentMonth).length === 1) {
        currentMonth = `0${currentMonth}`;
      }

      if (e === '0') {
        competencia = `${currentMonth}/${currentYear}`;
      } else if (e.length < 2 && e !== '0') {
        competencia = `0${e}/${currentYear}`;
      } else if (e.length >= 2 && e.length < 7) {
        competencia = `${e.slice(0, 2)}/${currentYear}`;
      }

      return competencia;
    }

    return e;
  };

  const treatMonth = (month) => month + 1;

  const getCompetenciaFinal = (competenciaInicial, meses) => {
    const datePieces = competenciaInicial.split('/');
    const dataInicial = new Date(datePieces[1], datePieces[0] - 1, 1);
    const dataFinal = dataInicial;
    dataFinal.setMonth(dataInicial.getMonth() + (meses - 1));

    const anoFinal = dataFinal.getFullYear();
    const mesFinal = treatMonth(dataFinal.getMonth());

    return `${mesFinal.toString().padStart(2, '0')}/${anoFinal}`;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Convênio Massivo'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      onDelete={data?.nrSeqConvenioMassivo > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Convenio'
            searchDataSource={onSearchConvenio}
            selectedItem={data.convenio}
            onSelectItem={(convenio) => {
              setData({
                ...data,
                convenio,
                nrSeqConvenio: convenio.nrSeqConvenio,
              });
            }}
            dataSourceTextProperty='noConvenio'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Associado'
            searchDataSource={onSearchAssociado}
            selectedItem={data.associado}
            onSelectItem={(associado) => {
              setData({
                ...data,
                associado,
                nrSeqPessoaAss: associado.nrSeqPessoaAss,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-2'>
          <Textbox
            required
            label='De Mês/Ano'
            maxLength={7}
            mask={MaskTypes.Competence}
            text={data.anoMesIni}
            onChangedValue={(anoMesIni) => {
              setData({ ...data, anoMesIni });
            }}
            onBlur={(_, value) => {
              const anoMesIni = onBlurCompetencia(value);

              setData({
                ...data,
                anoMesIni,
                anoMesFim: data.qtdParcela
                  ? getCompetenciaFinal(anoMesIni, data.qtdParcela)
                  : undefined,
              });
            }}
          />
        </div>

        <div className='col-2 mb-3'>
          <Textbox
            required
            maxLength={3}
            label='N° de Parcelas'
            text={data.qtdParcela}
            mask={MaskTypes.Integer}
            onChangedValue={(qtdParcela) =>
              setData({
                ...data,
                qtdParcela,
                vlrTotal: data.vlrParcela
                  ? data.vlrParcela * qtdParcela
                  : undefined,
                anoMesFim: data.anoMesIni
                  ? getCompetenciaFinal(data.anoMesIni, qtdParcela)
                  : undefined,
              })
            }
          />
        </div>
        <div className='col'>
          <Textbox
            label='Valor Parcela'
            required
            text={data.vlrParcela}
            mask={MaskTypes.Currency}
            onChangedValue={(vlrParcela) => {
              setData({
                ...data,
                vlrParcela,
                vlrTotal: data.qtdParcela
                  ? vlrParcela * data.qtdParcela
                  : undefined,
              });
            }}
          />
        </div>
        <div className='col-2'>
          <Textbox
            readOnly
            label='Até Mês/Ano'
            mask={MaskTypes.Competence}
            text={data.anoMesFim}
          />
        </div>
        <div className='col'>
          <Textbox
            label='Valor Total'
            readOnly
            text={data.vlrTotal}
            mask={MaskTypes.Currency}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col mb-3'>
          <Textarea
            maxLength={300}
            label='Observações'
            text={data.noObs}
            onChangedValue={(noObs) => setData({ ...data, noObs })}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
