import { Empresa } from '../SEG';

export default class CondicaoPagamentoEmpresa {
  constructor(jsonObject = {}) {
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqCondicaoPagamento = jsonObject.nrSeqCondicaoPagamento;
    this.nrSeqCondicaoPagamentoEmpresa =
      jsonObject.nrSeqCondicaoPagamentoEmpresa;
    this.empresa = new Empresa(jsonObject.empresa ?? {});
  }

  toJson = () => JSON.stringify(this);
}
