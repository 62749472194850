import Agencia from './agencia';
import Banco from './banco';

export default class ContaCorrente {
  constructor(jsonObject = {}) {
    this.nrSeqContaCorrente = jsonObject.nrSeqContaCorrente;
    this.noContaCorrente = jsonObject.noContaCorrente;
    this.flgVinculaBanco = jsonObject.flgVinculaBanco;
    this.nrSeqAgencia = jsonObject.nrSeqAgencia;
    this.cdContaCorrente = jsonObject.cdContaCorrente;
    this.dtInicio = jsonObject.dtInicio;
    this.saldoInicial = jsonObject.saldoInicial;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.floatPrevisao = jsonObject.floatPrevisao;
    this.cdContaCorrenteDig = jsonObject.cdContaCorrenteDig;
    this.noModeloCheque = jsonObject.noModeloCheque;
    this.agencia = new Agencia(jsonObject.agencia ?? {});
    this.banco = new Banco(jsonObject.banco ?? {});
    this.noBanco = jsonObject.noBanco;
  }

  toJson = () => JSON.stringify(this);
}
