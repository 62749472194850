import { devolveDemanda } from 'core/services/HELP';
import React, { useState } from 'react';

import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { Button, Loader, Modal, Notification, Textbox } from 'ui/components';

export default function ModalDevolverDemanda({
  nrSeqDemanda,
  show,
  onClose,
  onSetMessage,
  data,
  setData,
}) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [noIteracao, setNoIteracao] = useState('');

  const returnDemanda = async () => {
    setLoading(true);

    if (noIteracao.length > 0) {
      const { status, message: msg, demanda } = await devolveDemanda({
        nrSeqDemanda,
        noObservacao: noIteracao,
      });

      if (status === ResponseStatus.Success) {
        onSetMessage(status, msg);

        setData({ ...data, ...demanda });

        onClose();
      } else {
        setMessage({ theme: status, message: msg });
      }
    }

    setLoading(false);
  };

  return (
    <Modal
      show={show}
      title='Devolver Demanda'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
      icon={['far', 'comment-alt']}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <>
            <div className='row mb-3'>
              <div className='col'>
                <Notification
                  message={message.message}
                  theme={message.theme}
                  onClose={() => setMessage(null)}
                />
              </div>
            </div>
          </>
        )}
        <div className='row'>
          <div className='col'>
            <p>Insira o motivo da devolução.</p>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <Textbox
              text={noIteracao}
              placeholder='...'
              onChangedValue={(value) => setNoIteracao(value)}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Devolver Demanda'
          theme={Theme.Success}
          onClick={returnDemanda}
        />

        <Button
          text='Fechar'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
