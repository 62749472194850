import EquipamentoMarca from 'core/models/FRO/equipamentoMarca';
import api from '../api';

const baseURL = `/FRO/EquipamentoMarca`;

export const getEquipamentoMarcaAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    equipamentoMarcas: value?.map((json) => new EquipamentoMarca(json)) ?? [],
  };
};
