import { getUsuarioPerfilAutoComplete } from 'core/services/SEG/usuarioPerfil';
import React from 'react';
import {
  Modal,
  Button,
  DatePicker,
  Autocomplete,
  Loader,
  Notification,
  RadioButton,
} from 'ui/components';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/enums';
import { Message } from 'ui/Helpers/interfaces';

interface IResponseOnClose {
  isPrint: boolean;
  dataFilters?: any;
}
interface IProps {
  show: boolean;
  unidade: number;
  onClose: (res: IResponseOnClose) => void;
}

const ModalRelTributos = ({ show, unidade, onClose }: IProps) => {
  const [message, setMessage] = React.useState<Message | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [filters, setFilters] = React.useState<any>({
    btnPessoaFisica: 'S',
    flgFiltraPorPessoaFisica: true,
  });

  const onSetMessage = (status: ResponseStatus, msg: string): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchUnidadeOrigem = async (e: number) => {
    setLoading(true);

    const {
      status,
      message: msg,
      data: unidades,
    } = await getUsuarioPerfilAutoComplete({
      nrSeqTransacao: e,
    });

    const empresas: any = [];

    unidades.forEach((item: any) => {
      empresas.push(item.empresa);
    });

    if (msg) onSetMessage(status, msg);

    setLoading(false);

    return empresas;
  };

  const btnsPessoaFisica = [
    { text: 'Sim', value: 'S' },
    { text: 'Não', value: 'N' },
  ];

  return (
    // @ts-expect-error
    <Modal
      show={show}
      title='Relatório de Tributos'
      size={BootstrapSizes.Large}
      onClose={onClose}
    >
      <Loader loading={loading} />
      {message && (
        <div className='m-3'>
          {/* @ts-expect-error */}
          <Notification
            message={message.message}
            theme={message.theme}
            onClose={() => setMessage(null)}
          />
        </div>
      )}

      {/* @ts-expect-error */}
      <Modal.Body>
        <div className='row mb-2'>
          <div className='col'>
            <Autocomplete
              label='Unidade Origem'
              searchDataSource={() => onSearchUnidadeOrigem(unidade)}
              selectedItem={filters.empresa}
              onSelectItem={(empresa: any) => {
                setFilters({
                  ...filters,
                  empresa,
                  nrSeqEmpresa: empresa.nrSeqEmpresa,
                });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
          <div className='col-3'>
            {/* @ts-expect-error */}
            <RadioButton
              label='Filtra por Pessoa Física'
              outline
              size={BootstrapSizes.Small}
              theme={Theme.Primary}
              selectedButton={filters.btnPessoaFisica}
              buttons={btnsPessoaFisica}
              onChange={(btnPessoaFisica: string) => {
                setFilters({
                  ...filters,
                  btnPessoaFisica,
                  flgFiltraPorPessoaFisica: btnPessoaFisica === 'S',
                });
              }}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-3'>
            <DatePicker
              label='Data Emissão CTE Inicial'
              text={filters?.dtEmissaoCteInicio}
              /* @ts-expect-error */
              onChange={(dtEmissaoCteInicio) =>
                setFilters({ ...filters, dtEmissaoCteInicio })
              }
            />
          </div>
          <div className='col-3'>
            <DatePicker
              label='Data Emissão CTE Final'
              text={filters?.dtEmissaoCteFim}
              /* @ts-expect-error */
              onChange={(dtEmissaoCteFim) =>
                setFilters({ ...filters, dtEmissaoCteFim })
              }
            />
          </div>
          <div className='col-1 d-flex align-items-end'>
            <Button
              text='Imprimir'
              theme={Theme.Success}
              onClick={() => onClose({ isPrint: true, dataFilters: filters })}
            />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          text='Fechar'
          theme={Theme.Danger}
          onClick={() => onClose({ isPrint: false })}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default ModalRelTributos;
