import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  CSDManutPage,
  GridView,
  Panel,
  Autocomplete,
  Switch,
  DatePicker,
  Textbox,
  Button,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  ResponseStatus,
  Theme,
  JustifyContent,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getUsuarioList } from 'core/services/SEG/usuario';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';
import { getCidadeAutoComplete } from 'core/services/SEG/cidade';
import {
  getComissaoMotoristaSearchWithTravelInformation,
  saveComissaoMotoristaList,
} from 'core/services/FRO/comissaoMotorista';

export default function ComissaoMotorista({
  transaction,
  isActive,
  onSelectPage,
  registryKey,
}) {
  const formSubmit = useRef();
  const [message, setMessage] = useState(null);
  const gridView = useRef();
  const [usuario, setUsuario] = useState({});
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({});
  const [listComissaoMot, setListComissaoMot] = useState([]);

  const onClickEdit = (e, datasource) => {
    const objComissao = datasource.find((item) => item === e);

    if (objComissao.status !== GridView.EnumStatus.Inserir) {
      objComissao.status = GridView.EnumStatus.Alterar;
    }

    setData({ ...objComissao, flgNota: true, flgVale: false });

    const listaComissao = datasource.filter((item) => item !== e);

    if (gridView && gridView.current)
      gridView.current.setDataSource(listaComissao);
  };

  const columns = [
    {
      key: 'nrSeqComissaoMotorista',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'veiculo.placa', title: 'Placa', visible: false },
    { key: 'motorista.noPessoa', title: 'Motorista', visible: false },
    { key: 'dtViagem', title: 'Data', format: GridView.DataTypes.Date },
    {
      key: 'vlrTarifa',
      title: 'Vlr Tarifa',
      format: GridView.DataTypes.Decimal,
      visible: false,
    },
    { key: 'nrNotaFiscal', title: 'Conhecimento' },
    { key: 'notaTransportada', title: 'Nota' },
    { key: 'peso', title: 'Peso', format: GridView.DataTypes.Decimal },
    { key: 'vlrFrete', title: 'Vlr Frete', format: GridView.DataTypes.Decimal },
    { key: 'vlrTotal', title: 'Vlr Total' },
    { key: 'vlrVale', title: 'Vlr Vale' },
    { key: 'noObservacao', title: 'Observacao' },
    { key: 'cidadeOri.noCidade', title: 'Cidade Origem' },
    { key: 'cidadeDes.noCidade', title: 'Cidade Destino' },
    {
      key: 'nrSeqComissaoMotorista',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEdit(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
      title: 'Editar',
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  const onNew = () => {
    setData({
      flgNota: true,
      flgVale: false,
    });
    if (gridView && gridView.current) {
      gridView.current.setDataSource([]);
    }
  };

  const load = useCallback(async () => {
    // Converte registryKey para um número inteiro
    const id = parseInt(registryKey, 10);

    if (Number.isInteger(id)) {
      setLoading(true);

      const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;
      const user = await getUsuarioList(sessionUser);
      setUsuario(user.data[0]);

      const value = await getComissaoMotoristaSearchWithTravelInformation(id);

      const comissaoData = value.data;

      if (comissaoData.length > 0) {
        const motoristaInfo = comissaoData[0].motorista;
        const veiculoInfo = comissaoData[0].veiculo;

        setData({
          ...data,
          flgNota: true,
          flgVale: false,
          motorista: motoristaInfo,
          veiculoPrinc: veiculoInfo,
        });

        if (gridView && gridView.current) {
          gridView.current.setDataSource(comissaoData);
        }
      }
      setLoading(false);
    } else {
      onNew();
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  const onSearchVeiculoCavalo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
      nrSeqGrupoEmpresa: usuario.nrSeqGrupoEmpresa,
    });
    if (msg) setMessage(status, msg);
    return veiculos;
  };
  const onSearchPessoaMot = async (e) => {
    const {
      status,
      message: msg,
      motoristas,
    } = await getMotoristaAutoComplete({ noPessoa: e });
    if (msg) onSetMessage(status, msg);
    return motoristas;
  };
  const onSearchCidade = async (e) => {
    const { status, message: msg, cidades } = await getCidadeAutoComplete({
      noCidade: e,
    });
    onSetMessage(status, msg);
    return cidades;
  };
  const adicionaComissao = () => {
    const comissoes = listComissaoMot.length > 0 ? listComissaoMot : [];

    const comissoesStatus = data.status === undefined ? 'Inserir' : 'Alterar';

    const novaComissao = {
      ...data,
      status: comissoesStatus,
    };

    comissoes.push(novaComissao);

    if (gridView && gridView.current) {
      gridView.current.setDataSource(comissoes);
    }
    setData({ flgNota: true, flgVale: false });
    setListComissaoMot(comissoes);
  };

  const save = async () => {
    setLoading(true);

    const { status, message: msg } = await saveComissaoMotoristaList(
      listComissaoMot
    );

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      onNew();
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  return (
    <CSDManutPage
      isActive={isActive}
      title='VIAGEM MOTORISTA 30'
      loading={loading}
      onBack={() => onSelectPage()}
      onNew={onNew}
      onSave={save}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <Panel className='mb-3'>
        <Panel.Header
          title='Seleção de Comissão Motorista'
          theme={Theme.Light}
          align={JustifyContent.Start}
        />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-2'>
              <Autocomplete
                label='Veiculo'
                searchDataSource={onSearchVeiculoCavalo}
                selectedItem={data.veiculoPrinc}
                onSelectItem={(veiculoPrinc) => {
                  setData({
                    ...data,
                    veiculoPrinc,
                    nrSeqVeiculo: veiculoPrinc.nrSeqVeiculo,
                    noPlaca: veiculoPrinc.noPlaca,
                  });
                }}
                dataSourceTextProperty='noVeiculo'
              />
            </div>
            <div className='col-4'>
              <Autocomplete
                label='Motorista'
                searchDataSource={onSearchPessoaMot}
                selectedItem={data.motorista}
                onSelectItem={(motorista) => {
                  setData({
                    ...data,
                    motorista,
                    nrSeqPessoaMot: motorista.nrSeqPessoaMot,
                    noMotorista: motorista.noMotorista,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
          </div>
          <fieldset
            style={{
              border: '1px solid #cfd4d8',
              padding: '10px',
              borderRadius: '3px',
            }}
          >
            <legend>Pequisa:</legend>
            <div className='row mb-3'>
              <div className='col-2'>
                <Switch
                  formControl
                  label='Nota'
                  checked={data.flgNota}
                  onChange={(flgNota) =>
                    setData({
                      ...data,
                      flgNota,
                      flgVale: !data.flgVale,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Switch
                  formControl
                  label='Vale'
                  checked={data.flgVale}
                  onChange={(flgVale) =>
                    setData({
                      ...data,
                      flgVale,
                      flgNota: !data.flgNota,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Data'
                  text={data.dtViagem}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtViagem) => {
                    setData({ ...data, dtViagem });
                  }}
                />
              </div>
              <div className='col-2'>
                <Textbox
                  readOnly={data.flgVale}
                  label='Numero Nota Fiscal'
                  text={data.notaTransportada}
                  onChangedValue={(notaTransportada) => {
                    setData({ ...data, notaTransportada });
                  }}
                />
              </div>
              <div className='col-2'>
                <Textbox
                  readOnly={data.flgVale}
                  label='Numero Conhecimento'
                  text={data.nrNotaFiscal}
                  onChangedValue={(nrNotaFiscal) => {
                    setData({ ...data, nrNotaFiscal });
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-2'>
                <Textbox
                  readOnly={data.flgVale}
                  label='Peso'
                  text={data.peso}
                  mask={MaskTypes.Decimal}
                  onChangedValue={(peso) => {
                    setData({
                      ...data,
                      peso,
                      vlrFrete:
                        data.peso !== '' && data.vlrTarifa !== ''
                          ? (data.peso / 1000) * data.vlrTarifa
                          : 0,
                    });
                  }}
                />
              </div>
              <div className='col-2'>
                <Textbox
                  readOnly={data.flgVale}
                  label='Tarifa'
                  text={data.vlrTarifa}
                  mask={MaskTypes.Decimal}
                  onChangedValue={(vlrTarifa) => {
                    setData({
                      ...data,
                      vlrTarifa,
                      vlrFrete:
                        data.peso !== '' && data.vlrTarifa !== ''
                          ? (data.peso / 1000) * data.vlrTarifa
                          : 0,
                    });
                  }}
                />
              </div>
              <div className='col-2'>
                <Textbox
                  readOnly={data.flgNota}
                  label='Obs'
                  text={data.noObservacao}
                  onChangedValue={(noObservacao) => {
                    setData({ ...data, noObservacao });
                  }}
                />
              </div>
              <div className='col-2'>
                <Textbox
                  readOnly={data.flgNota || data.flgVale}
                  label='Valor Frete'
                  text={data.vlrFrete}
                  mask={MaskTypes.Decimal}
                  onChangedValue={(vlrFrete) => {
                    setData({ ...data, vlrFrete });
                  }}
                />
              </div>
              <div className='col-2'>
                <Textbox
                  readOnly={data.flgNota}
                  label='Valor Vale'
                  text={data.vlrVale}
                  onChangedValue={(vlrVale) => {
                    setData({ ...data, vlrVale });
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-3'>
                <Autocomplete
                  readOnly={data.flgVale}
                  enabled={!data.flgVale}
                  label='Cidade Origem'
                  searchDataSource={onSearchCidade}
                  selectedItem={data.cidadeOri}
                  onSelectItem={(cidadeOri) =>
                    setData({
                      ...data,
                      cidadeOri,
                      nrSeqCidadeOri: cidadeOri.nrSeqCidade,
                      noCidadeOri: cidadeOri.noCidade,
                    })
                  }
                  dataSourceTextProperty='noCidade'
                />
              </div>
              <div className='col-3'>
                <Autocomplete
                  readOnly={data.flgVale}
                  enabled={!data.flgVale}
                  label='Cidade Destino'
                  searchDataSource={onSearchCidade}
                  selectedItem={data.cidadeDes}
                  onSelectItem={(cidadeDes) =>
                    setData({
                      ...data,
                      cidadeDes,
                      nrSeqCidadeDes: cidadeDes.nrSeqCidade,
                      noCidadeDes: cidadeDes.noCidade,
                    })
                  }
                  dataSourceTextProperty='noCidade'
                />
              </div>
              <div className='col mt-2 d-flex align-itens-end'>
                <Button
                  outline
                  icon='plus'
                  size={BootstrapSizes.Medium}
                  theme={Theme.Primary}
                  template={Button.Templates.Default}
                  onClick={adicionaComissao}
                  text='Adicionar'
                />
              </div>
            </div>
          </fieldset>
        </Panel.Body>
      </Panel>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            transaction={transaction}
            showSelectSizes={false}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
