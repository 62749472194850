import React, { useState, useRef, useEffect } from 'react';

import {
  Button,
  CSDBasePageContext,
  Loader,
  Modal,
  Notification,
  GridView,
  Textarea,
} from 'ui/components';
import { Theme, ResponseStatus, BootstrapSizes } from 'ui/Helpers/utils';

import {
  obterOcorrenciasPorTitulo,
  obterOcorrenciasFaturaPorTitulo,
  salvarOcorrencias,
} from 'core/services/FIN';

const columnsFatura = [
  { key: 'numeroDocumento', title: 'Cd. Tit', sortable: false },
  {
    key: 'nrSeqTituloReceberFatura',
    title: 'Forma Recebimento',
    sortable: false,
  },
  { key: 'nossoNumero', title: 'Nosso Numero', sortable: false },
  { key: 'nrSeqTituloReceberFatura', title: 'Vencimento', sortable: false },
  { key: 'vlrPago', title: 'Valor', sortable: false },
  {
    key: 'dtOcorrencia',
    title: 'Data Ocorrência',
    sortable: false,
    format: GridView.DataTypes.Date,
  },
  { key: 'noOcorrencia', title: 'Ocorrência', sortable: false },
];

const columns = [
  {
    key: 'dtCadastro',
    title: 'Cadastro',
    sortable: false,
    format: GridView.DataTypes.Date,
  },
  { key: 'noPessoaUsuarioCad', title: 'Usuário', sortable: false },
  { key: 'noOcorrencia', title: 'Descrição da Ocorrência', sortable: false },
];

const ModalOcorrencias = ({ nrSeqTituloReceber, onSave, show, onClose }) => {
  const gridViewFatura = useRef();
  const gridView = useRef();

  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [noOcorrencia, setNoOcorrencia] = useState(undefined);
  const [tituloReceberOcorrencias, setTituloReceberOcorrencias] = useState([]);
  const [
    tituloReceberFaturaOcorrencias,
    setTituloReceberFaturaOcorrencias,
  ] = useState([]);

  const context = React.useContext(CSDBasePageContext);
  const { user } = context ?? [];

  useEffect(() => {
    setNoOcorrencia(undefined);

    (async function onLoad() {
      if (nrSeqTituloReceber) {
        setLoading(true);
        const {
          status: statusTitulo,
          message: messageTitulo,
          ocorrencias: ocorrenciasTitulo,
        } = await obterOcorrenciasPorTitulo({ nrSeqTituloReceber });
        if (messageTitulo)
          setMessage({
            message: messageTitulo,
            theme:
              statusTitulo === ResponseStatus.Success
                ? Theme.Success
                : Theme.Danger,
          });

        setTituloReceberOcorrencias(ocorrenciasTitulo);

        const {
          status: statusFatura,
          message: messageFatura,
          ocorrencias: ocorrenciasFatura,
        } = await obterOcorrenciasFaturaPorTitulo({ nrSeqTituloReceber });
        if (messageFatura)
          setMessage({
            message: messageFatura,
            theme:
              statusFatura === ResponseStatus.Success
                ? Theme.Success
                : Theme.Danger,
          });

        setTituloReceberFaturaOcorrencias(ocorrenciasFatura);
        setLoading(false);
      }
    })();
  }, [nrSeqTituloReceber]);

  useEffect(() => {
    if (gridViewFatura && gridViewFatura.current)
      gridViewFatura.current.setDataSource(tituloReceberFaturaOcorrencias);

    if (gridView && gridView.current)
      gridView.current.setDataSource(tituloReceberOcorrencias);
  }, [tituloReceberFaturaOcorrencias, tituloReceberOcorrencias]);

  const handleAddOcorrencia = () => {
    if (!noOcorrencia?.trim()) {
      setMessage({
        message: 'Obrigatório motivo da ocorrência.',
        theme: Theme.Danger,
      });
      return;
    }

    tituloReceberOcorrencias.push({
      dtCadastro: new Date(),
      nrSeqUsuario: user.nrSeqUsuario,
      noPessoaUsuarioCad: user.noPessoa,
      noOcorrencia,
      nrSeqTituloReceber,
    });

    setTituloReceberOcorrencias(tituloReceberOcorrencias);
    gridView.current.setDataSource(tituloReceberOcorrencias);
    setNoOcorrencia(undefined);
  };

  const handleSalvarOcorrencias = async () => {
    setLoading(true);
    const { status, message: msg } = await salvarOcorrencias(
      tituloReceberOcorrencias.filter((p) => !p.nrSeqTituloReceberOcorrencia)
    );
    if (status === ResponseStatus.Success && onSave) onSave(msg);
    else setMessage({ message: msg, theme: Theme.Danger });
    setLoading(false);
  };

  return (
    <Modal
      show={show}
      title='Ocorrências do Titulo'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
      icon={['far', 'comment-dots']}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col'>
            <div className='alert alert-info'>
              <h3>Ocorrências Bancarias</h3>
              <GridView
                ref={gridViewFatura}
                className='table-condensed text-primary'
                dataColumns={columnsFatura}
                showSelectSizes={false}
                showPagination={false}
              />
            </div>
          </div>
        </div>
        <div className='row mb-3 d-flex align-items-center'>
          <div className='col'>
            <Textarea
              label='Descrição da Ocorrência'
              text={noOcorrencia}
              onChangedValue={(e) => setNoOcorrencia(e)}
            />
          </div>
          <div className='col-auto'>
            <Button
              theme={Theme.Success}
              icon='plus'
              template={Button.Templates.Quick}
              onClick={handleAddOcorrencia}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-condensed table-bordered'
              dataColumns={columns}
              showSelectSizes={false}
              showPagination={false}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Salvar'
          theme={Theme.Success}
          icon={['far', 'save']}
          onClick={handleSalvarOcorrencias}
        />
        <Button
          text='Cancelar'
          theme={Theme.Danger}
          icon='ban'
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalOcorrencias;
