export default class CstPis {
  nrSeqCstPis: number;

  cdCstPis: string;

  noCstPis: string;

  nrSeqGrupoEmpresa: number;

  constructor(jsonObject: CstPis) {
    this.nrSeqCstPis = jsonObject.nrSeqCstPis;
    this.cdCstPis = jsonObject.cdCstPis;
    this.noCstPis = jsonObject.noCstPis;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
