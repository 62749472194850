import qs from 'qs';
import Pagination from 'core/models/pagination';
import { NFEImportadaTipo } from 'core/models/COP';
import api from '../api';

const baseURL = `/COP/NfeImportadaTipo`;

export const getNfeImportadaTipoAutoComplete = async (filters: any) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data:
      value?.map((json: NFEImportadaTipo) => new NFEImportadaTipo(json)) ?? [],
  };
};

export const getNfeImportadaTipoList = async (filters: any) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data:
      value?.map((json: NFEImportadaTipo) => new NFEImportadaTipo(json)) ?? [],
  };
};

export const getNfeImportadaTipoPagined = async (filters: any) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data:
      value?.map((json: NFEImportadaTipo) => new NFEImportadaTipo(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getNfeImportadaTipo = async (id: number) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new NFEImportadaTipo(value) : {};
};

export const saveNfeImportadaTipo = async (data: any) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteNfeImportadaTipo = async (id: number) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printNfeImportadaTipo = async (filters: any) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelNfeImportadaTipo = async (filters: any) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllNfeImportadaTipo = async (ids: Array<number>) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
