import qs from 'qs';
import { MimeTypes } from 'ui/Helpers/utils';
import Pagination from 'core/models/pagination';
import PocPocoDocumentoAnaliseAgua from 'core/models/POC/pocPocoDocumentoAnaliseAgua';
import api from '../api';

const baseURL = `/POC/PocPocoDocumentoAnaliseAgua`;

export const uploadDocumento = async (file, data) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('extension', MimeTypes.getExtension(file.type));
  formData.append('nrseqpocpoco', data);
  const response = await api.post(`${baseURL}/UploadDocumento`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    retorno: value,
  };
};

export const getPocPocoDocumentoAnaliseAguaList = async (filters) => {
  const response = await api.get(`/$PocPocoDocumentoAnaliseAgua/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPocoDocumentoAnaliseAgua(json)) ?? [],
  };
};

export const getPocPocoDocumentoAnaliseAguaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPocoDocumentoAnaliseAgua(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocPocoDocumentoAnaliseAgua = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocPocoDocumentoAnaliseAgua(value) : {};
};

export const savePocPocoDocumentoAnaliseAgua = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePocPocoDocumentoAnaliseAgua = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPocPocoDocumentoAnaliseAgua = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocPocoDocumentoAnaliseAgua = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocPocoDocumentoAnaliseAgua = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const downloadFile = async (id) => {
  const response = await api.get(`${baseURL}/DownloadFile`, {
    params: { id },
  });
  return response.data ?? {};
};
