import qs from 'qs';
import Pagination from 'core/models/pagination';
import PocEnsaioRecuperacao from 'core/models/POC/pocEnsaioRecuperacao';
import api from '../api';

const baseURL = `/POC/PocEnsaioRecuperacao`;

export const getPocEnsaioRecuperacaoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocEnsaioRecuperacao(json)) ?? [],
  };
};

export const getPocEnsaioRecuperacaoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocEnsaioRecuperacao(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocEnsaioRecuperacao = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocEnsaioRecuperacao(value) : {};
};

export const savePocEnsaioRecuperacao = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePocEnsaioRecuperacao = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const deletePocEnsaioRecuperacaoTimesEmpty = async (id) => {
  const response = await api.delete(`${baseURL}/deleteTimesEmpty`, {
    params: { id },
  });
  return response.data ?? {};
};

export const printPocEnsaioRecuperacao = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocEnsaioRecuperacao = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocEnsaioRecuperacao = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const updateNivelRecuperacao = async (data) => {
  const response = await api.post(`${baseURL}/updateNivel`, data);
  const { message, status, value: recuperacao } = response.data;
  return { message, status, value: new PocEnsaioRecuperacao(recuperacao) };
};

export const updateTempoRecuperacao = async (data) => {
  const response = await api.post(`${baseURL}/updateTempo`, data);
  return response.data ?? {};
};

export const updateRecuperacao = async (data) => {
  const response = await api.post(`${baseURL}/updateRecuperacao`, data);
  return response.data ?? {};
};
