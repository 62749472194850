import { ClienteLinhaCalculatedFields, ClienteLinha } from 'core/models/TEL';
import { MimeTypes } from 'ui/Helpers/utils';
import qs from 'qs';
import api from '../api';

const base = 'TEL/ClienteLinha';

export const getClienteLinhaList = async (filters) => {
  const response = await api.get(`${base}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    linhas: value?.map((json) => new ClienteLinha(json)) ?? [],
  };
};

export const getClienteLinhaListCalculatedFields = async (filters) => {
  const response = await api.get(`${base}/SearchCalculatedFields`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    pagination,
    linhas: value?.map((json) => new ClienteLinhaCalculatedFields(json)) ?? [],
  };
};

export const getClienteLinha = async (id) => {
  const response = await api.get(`${base}`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ClienteLinha(value) : {};
};

export const getClienteLinhasByCliente = async (filters) => {
  const response = await api.get(`${base}/SearchClienteLinhas`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ClienteLinha(json)) ?? [],
  };
};

export const getClienteLinhasAutoCompleteResumido = async (filters) => {
  const response = await api.get(`${base}/AutoCompleteResumido`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ClienteLinha(json)) ?? [],
  };
};

export const saveClienteLinha = async (cliente) => {
  const response = await api.post(`${base}`, cliente);
  return response.data ?? {};
};

export const deleteClienteLinha = async (id) => {
  const response = await api.delete(`${base}`, { params: { id } });
  return response.data ?? {};
};

export const deleteClienteLinhas = async (ids) => {
  const response = await api.delete(`${base}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const printClienteLinhas = async (filters) => {
  const response = await api.get(`${base}/Print`, {
    params: filters,
  });

  return response.data ?? {};
};

export const excelClienteLinhas = async (filters) => {
  const response = await api.get(`${base}/ExcelPrint`, {
    params: filters,
  });

  return response.data ?? {};
};

export const printServicos = async (filters) => {
  const response = await api.get(`${base}/PrintServicos`, {
    params: filters,
  });

  return response.data ?? {};
};

export const excelServicos = async (filters) => {
  const response = await api.get(`${base}/ExcelServicos`, {
    params: filters,
  });

  return response.data ?? {};
};

export const getClienteLinhaAutoComplete = async (filters) => {
  const response = await api.get(`${base}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    linhas: value?.map((json) => new ClienteLinha(json)) ?? [],
  };
};

export const changePaymentMethod = async (data) => {
  const response = await api.put(`${base}/ChangePaymentMethod`, data);

  return response.data ?? {};
};

export const addChargeManual = async (data) => {
  const response = await api.post(`${base}/AddChargeManual`, data);

  return response.data ?? {};
};

export const changeClient = async (data) => {
  const response = await api.put(`${base}/ChangeClient`, data);

  return response.data ?? {};
};

export const changeCostCenter = async (data) => {
  const response = await api.post(`${base}/ChangeCostCenter`, data);

  return response.data ?? {};
};

export const changePlanApportionment = async (data) => {
  const response = await api.put(`${base}/ChangePlanApportionment`, data);

  return response.data ?? {};
};

export const changePlan = async (data) => {
  const response = await api.post(`${base}/ChangePlan`, data);

  return response.data ?? {};
};

export const importClientLine = async (file, data) => {
  const formData = new FormData();
  if (file) {
    formData.append('file', file);
    formData.append('extension', MimeTypes.getExtension(file.type));
  }

  formData.append('flgMantemCliente', data.flgMantemCliente);
  formData.append('flgImportarArquivo', data.flgImportarArquivo);
  formData.append('flgMantemCentroDeCusto', data.flgMantemCentroDeCusto);
  formData.append('flgCancelaAtivos', data.flgCancelaAtivos);
  formData.append('nrSeqPlano', data.nrSeqPlano);
  formData.append('dtAquisicao', data.dtAquisicao);
  formData.append('nrSeqPessoaCli', data.nrSeqPessoaCli);
  formData.append('nrSeqPessoaCli', data.nrSeqChip);
  formData.append('nrSeqCentroDeCusto', data.nrSeqCentroDeCusto);
  formData.append('nrSeqChip', data.nrSeqChip);

  const response = await api.post(`${base}/ImportClientLine`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data ?? {};
};

export const getClienteLinhasParaDesbloqueio = async () => {
  const response = await api.get(`${base}/SearchClienteLinhasParaDesbloquear`);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ClienteLinha(json)) ?? [],
  };
};

export const IncluirLinhasGrupo = async (data) => {
  const response = await api.post(`${base}/IncluirLinhasGrupo`, data);

  const { status, message } = response.data;

  return { status, message };
};
