import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  JustifyContent,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  Switch,
  GridView,
  Button,
  Panel,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getAgrupamentoFinanceiro,
  saveAgrupamentoFinanceiro,
  deleteAgrupamentoFinanceiro,
} from 'core/services/FIN/agrupamentoFinanceiro';

import AgrupamentoFinanceiroContacor from 'core/models/FIN/agrupamentoFinanceiroContacor';
import { getContaCorrenteAutoComplete } from 'core/services/FIN/contaCorrente';

export default function AgrupamentoFinanceiroItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const gridViewCorrente = useRef();
  const [message, setMessage] = useState(null);
  const [dataCorrente, setDataCorrente] = useState(
    new AgrupamentoFinanceiroContacor({})
  );

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const obj = await getAgrupamentoFinanceiro(registryKey);
      setData(obj);
      if (gridViewCorrente && gridViewCorrente.current)
        gridViewCorrente.current.setDataSource(
          obj.agrupamentoFinanceiroContaCors
        );
      setLoading(false);
    } else {
      setData({});
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const agrupamentofinanceirocontacors =
      gridViewCorrente.current.getDataSource() ?? [];
    const obj = {
      ...data,
      agrupamentoFinanceiroContaCors: agrupamentofinanceirocontacors,
    };
    const { status, message: msg } = await saveAgrupamentoFinanceiro(obj);

    if (status === ResponseStatus.Success) {
      setData({});
      setDataCorrente({});
      if (gridViewCorrente && gridViewCorrente.current)
        gridViewCorrente.current.setDataSource([]);
      formSubmit.current.reset();
    }

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onSearchCorrente = async (e) => {
    const {
      status,
      message: msg,
      data: contaCorrente,
    } = await getContaCorrenteAutoComplete({ noContaCorrente: e });
    onSetMessage(status, msg);
    return contaCorrente;
  };

  const onClickAddContaCorrente = () => {
    const list = gridViewCorrente.current.getDataSource() ?? {};
    dataCorrente.status = GridView.EnumStatus.Inserir;
    if (list) {
      const vinculo = list.filter(
        (corrente) =>
          corrente.nrSeqContaCorrente === dataCorrente.nrSeqContaCorrente
      );
      if (vinculo.length > 0) {
        onSetMessage(1, 'Almoxarifado já vinculado neste produto.');
        return;
      }
    }

    list.push(dataCorrente);
    if (gridViewCorrente && gridViewCorrente.current)
      gridViewCorrente.current.setDataSource(list);
  };

  const onClickRemoveCorrente = async (e) => {
    const list = gridViewCorrente.current.getDataSource();
    list.filter((elemento) => elemento === e)[0].status =
      GridView.EnumStatus.Remover;

    if (gridViewCorrente && gridViewCorrente.current)
      gridViewCorrente.current.setDataSource(list);
  };

  const columns = [
    { key: 'contaCorrente.cdContaCorrente', title: 'Código' },
    {
      key: 'contaCorrente.noContaCorrente',
      title: 'Conta Corrente',
    },
    {
      key: 'nrSeqAgrupamentoFinanceiro',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveCorrente(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      // size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteAgrupamentoFinanceiro(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Agrupamento Financeiro'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqAgrupamentoFinanceiro > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-6 mb-3'>
          <Textbox
            required
            maxLength={60}
            label='Descrição'
            text={data.noAgrupamentoFinanceiro}
            onChangedValue={(noAgrupamentoFinanceiro) =>
              setData({ ...data, noAgrupamentoFinanceiro })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-4 mb-3'>
          <Textbox
            required
            label='Ordem'
            text={data.nrOrdem}
            mask={MaskTypes.Integer}
            onChangedValue={(nrOrdem) => setData({ ...data, nrOrdem })}
          />
        </div>
        <div className='col-1 mb-3'>
          <Switch
            required
            formControl
            label='Debito'
            checked={data.flgDebito}
            onChange={(flgDebito) => setData({ ...data, flgDebito })}
          />
        </div>
        <div className='col-3 mb-3'>
          <Switch
            required
            formControl
            label='Credito'
            checked={data.flgCredito}
            onChange={(flgCredito) => setData({ ...data, flgCredito })}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col mb-12'>
          <Panel>
            <Panel.Header
              title='Contas Correntes'
              icon={['fa', 'briefcase']}
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-3'>
                  <Autocomplete
                    label='Conta Corrente'
                    searchDataSource={onSearchCorrente}
                    selectedItem={dataCorrente.contaCorrente}
                    onSelectItem={(contaCorrente) => {
                      setDataCorrente({
                        ...dataCorrente,
                        contaCorrente,
                        nrSeqContaCorrente: contaCorrente.nrSeqContaCorrente,
                      });
                    }}
                    dataSourceTextProperty='noContaCorrente'
                  />
                </div>

                <div className='col-2 mt-3'>
                  <Button
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Adicionar'
                    onClick={onClickAddContaCorrente}
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-12'>
                  <GridView
                    ref={gridViewCorrente}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns}
                    offlineData
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
    </CSDManutPage>
  );
}
