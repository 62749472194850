import qs from 'qs';
import Pagination from 'core/models/pagination';
import PneuDimensao from 'core/models/FRO/pneuDimensao';
import api from '../api';

const baseURL = `/FRO/PneuDimensao`;

export const getPneuDimensaoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PneuDimensao(json)) ?? [],
  };
};

export const getPneuDimensaoList = async (filters) => {
  const response = await api.get(`/$PneuDimensao/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PneuDimensao(json)) ?? [],
  };
};

export const getPneuDimensaoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PneuDimensao(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPneuDimensao = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PneuDimensao(value) : {};
};

export const savePneuDimensao = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePneuDimensao = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPneuDimensao = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPneuDimensao = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPneuDimensao = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
