export default class Perfil {
  constructor(jsonObject = {}) {
    this.nrSeqPerfil = jsonObject.nrSeqPerfil;
    this.cdPerfil = jsonObject.cdPerfil;
    this.noPerfil = jsonObject.noPerfil;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgAdminMobile = jsonObject.flgAdminMobile;
  }

  toJson = () => JSON.stringify(this);
}
