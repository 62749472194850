import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Textbox, Switch } from 'ui/components';
import Tree from 'ui/components/Treeview';
// import { MaskTypes } from 'ui/Helpers/masks';
import {
  getCentroDeCusto,
  saveCentroDeCusto,
  deleteCentroDeCusto,
  getCentroDeCustoPaiTreeview,
} from 'core/services/TEL/centroDeCusto';

export default function CentroDeCustoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [treeData, setTree] = useState();

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getCentroDeCusto(registryKey));

      const { centros } = await getCentroDeCustoPaiTreeview();
      centros.forEach((item) => {
        item.label = item.noCentroDeCusto;
      });

      const linhas = centros.map((column) => ({
        label: column.noCentroDeCusto,
        key: column.nrSeqCentroDeCusto,
        root: !column.nrSeqCentroDeCustoPai > 0,
        children: centros
          .filter(
            (item) => item.nrSeqCentroDeCustoPai === column.nrSeqCentroDeCusto
          )
          .map((column2) => ({
            label: column2.noCentroDeCusto,
            key: column2.nrSeqCentroDeCusto,
            root: !column2.nrSeqCentroDeCustoPai > 0,
            children: centros
              .filter(
                (item) =>
                  item.nrSeqCentroDeCustoPai === column2.nrSeqCentroDeCusto
              )
              .map((column3) => ({
                label: column3.noCentroDeCusto,
                key: column3.nrSeqCentroDeCusto,
                root: !column3.nrSeqCentroDeCustoPai > 0,
                children: centros
                  .filter(
                    (item) =>
                      item.nrSeqCentroDeCustoPai === column3.nrSeqCentroDeCusto
                  )
                  .map((column4) => ({
                    label: column4.noCentroDeCusto,
                    key: column4.nrSeqCentroDeCusto,
                    root: !column4.nrSeqCentroDeCustoPai > 0,
                    children: centros
                      .filter(
                        (item) =>
                          item.nrSeqCentroDeCustoPai ===
                          column4.nrSeqCentroDeCusto
                      )
                      .map((column5) => ({
                        label: column5.noCentroDeCusto,
                        key: column5.nrSeqCentroDeCusto,
                        root: !column5.nrSeqCentroDeCustoPai > 0,
                        children: centros.filter(
                          (item) =>
                            item.nrSeqCentroDeCustoPai ===
                            column5.nrSeqCentroDeCusto
                        ),
                      })),
                  })),
              })),
          })),
      }));
      setTree(linhas);

      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);

    const { status, message: msg } = await saveCentroDeCusto(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteCentroDeCusto(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Centro de Custo'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqCentroDeCusto > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            required
            maxLength={200}
            label='Descrição'
            text={data.noCentroDeCusto}
            onChangedValue={(noCentroDeCusto) =>
              setData({ ...data, noCentroDeCusto })
            }
          />
        </div>
        <div className='col-3'>
          <Textbox
            required
            maxLength={20}
            label='Código'
            text={data.cdCentroDeCusto}
            onChangedValue={(cdCentroDeCusto) =>
              setData({ ...data, cdCentroDeCusto })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-auto'>
          <Switch
            formControl
            label='Ativo'
            checked={data.flgAtivo}
            onChange={(flgAtivo) => setData({ ...data, flgAtivo })}
          />
        </div>
        <div className='col-auto'>
          <Switch
            formControl
            label='Sintético'
            checked={data.flgSintetico}
            onChange={(flgSintetico) => setData({ ...data, flgSintetico })}
          />
        </div>
      </div>
      <div className='row' label='Centro de Custos Pai'>
        <div className='col-12 mb-3'>
          <Tree
            label='Centro de Custo Pai'
            data={treeData}
            selectedKey={data.nrSeqCentroDeCustoPai}
            onChangedValue={(nrSeqCentroDeCustoPai) =>
              setData({ ...data, nrSeqCentroDeCustoPai })
            }
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
