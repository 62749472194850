import React, { useRef, useState } from 'react';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getRelatorioBraskemPagined,
  printRelatorioBraskem,
  excelRelatorioBraskem,
  printColetaEmbarque,
} from 'core/services/FRO/coletaEmbarque';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
import { getCidadeAutoComplete } from 'core/services/SEG/cidade';
import {
  CSDSelPage,
  Autocomplete,
  Textbox,
  DatePicker,
  ToolbarButtons,
  GridView,
} from 'ui/components';

export default function relatorioBraskem({
  transaction,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();
  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onClickImprimirCte = async (e) => {
    setLoading(true);

    const { status, message: msg, value } = await printColetaEmbarque({
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
    });

    if (status === ResponseStatus.Success)
      onOpenReport(transaction.noTransacao, value);
    else if (msg) onSetMessage(status, msg);

    setLoading(false);
  };

  const columns = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'noDestinatario',
      title: 'Destinatario',
    },
    {
      key: 'nrNotaFiscal',
      title: 'Nota',
    },
    {
      key: 'noCidadeEntrega',
      title: 'Local Entrega',
    },
    {
      key: 'noUFEntrega',
      title: 'UF',
    },
    {
      key: 'nrOt',
      title: 'OT',
    },
    {
      key: 'nrRemessa',
      title: 'Remessa',
    },
    {
      key: 'dtEmissao',
      title: 'Data Emissao',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'dtPrevisaoEntrega',
      title: 'Data Previsao',
      format: GridView.DataTypes.Date,
    },
    { key: 'noPlaca', title: 'Placa' },
    {
      key: 'nrCte',
      title: 'Nr Cte',
    },
    {
      key: 'statusCTEstr',
      title: 'Status CTE',
      bgClassProperty: 'noColorStatus',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];

  const search = async (params = null) => {
    setLoading(true);

    const {
      status,
      message: msg,
      data,
      pagination,
    } = await getRelatorioBraskemPagined(params ?? filtros);
    if (msg) onSetMessage(status, msg);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printRelatorioBraskem(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onDownloadExcel = async () => {
    setLoading(true);

    const { value: base64 } = await excelRelatorioBraskem(filtros);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'RelatorioBraskem.xls';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onAutoCompleteCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return clientes;
  };
  const onAutoCompleteEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return empresas;
  };

  const onSearchVeiculoCavalo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
      flgPrincipal: true,
    });
    if (msg) setMessage(status, msg);
    return veiculos;
  };

  const onSearchCidadeDestino = async (e) => {
    const {
      status,
      message: msg,
      cidades: cidadesDestino,
    } = await getCidadeAutoComplete({
      noCidade: e,
      nrSeqEstado: filtros?.nrSeqEstadoDes ?? null,
    });
    onSetMessage(status, msg);
    return cidadesDestino;
  };

  const onSearchEstadoDestino = async (e) => {
    const {
      status,
      message: msg,
      estados: estadosEntrega,
    } = await getEstadoAutoComplete({
      noEstado: e,
    });
    onSetMessage(status, msg);
    return estadosEntrega;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Braskem Ot 30'
      loading={loading}
      onSearch={() => search()}
      message={message}
      onMessagerClose={() => setMessage(null)}
      onPrint={onPrint}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Excel'
          icon='file-excel'
          onClick={onDownloadExcel}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Unid. Origem'
            searchDataSource={onAutoCompleteEmpresa}
            selectedItem={filtros.unidOrigem}
            onSelectItem={(unidOrigem) => {
              setFiltros({
                ...filtros,
                unidOrigem,
                nrSeqEmpresa: unidOrigem.nrSeqEmpresa,
                nrSeqGrupoEmpresa: unidOrigem.nrSeqGrupoEmpresa,
                noEmpresa: unidOrigem.noPessoa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data Inicial'
            text={filtros.dtInicial}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtInicial) => {
              setFiltros({ ...filtros, dtInicial });
            }}
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data Final'
            text={filtros.dtFinal}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtFinal) => {
              setFiltros({ ...filtros, dtFinal });
            }}
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Nr Ot'
            text={filtros.nrOt}
            onChangedValue={(nrOt) => {
              setFiltros({ ...filtros, nrOt });
            }}
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Nr Remessa'
            text={filtros.nrRemessa}
            onChangedValue={(nrRemessa) => {
              setFiltros({ ...filtros, nrRemessa });
            }}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Nome do Cliente'
            searchDataSource={onAutoCompleteCliente}
            selectedItem={filtros.cliente}
            onSelectItem={(cliente) => {
              setFiltros({
                ...filtros,
                cliente,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                noCliente: cliente.noPessoa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-2'>
          <Autocomplete
            label='Placa'
            searchDataSource={onSearchVeiculoCavalo}
            selectedItem={filtros.veiculoPrinc}
            onSelectItem={(veiculoPrinc) => {
              setFiltros({
                ...filtros,
                veiculoPrinc,
                nrSeqVeiculo: veiculoPrinc.nrSeqVeiculo,
                noPlaca: veiculoPrinc.noPlaca,
              });
            }}
            dataSourceTextProperty='noVeiculo'
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Nota Fiscal'
            text={filtros.nrFiscal}
            onChangedValue={(nrFiscal) => {
              setFiltros({ ...filtros, nrFiscal });
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Cidade Destino'
            searchDataSource={onSearchCidadeDestino}
            selectedItem={filtros.cidadeDestino}
            onSelectItem={(cidadeDestino) =>
              setFiltros({
                ...filtros,
                cidadeDestino,
                nrSeqCidadeDes: cidadeDestino.nrSeqCidade,
              })
            }
            dataSourceTextProperty='noCidade'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Estado Destino'
            searchDataSource={onSearchEstadoDestino}
            selectedItem={filtros.estadoDestino}
            onSelectItem={(estadoDestino) =>
              setFiltros({
                ...filtros,
                estadoDestino,
                nrSeqEstadoDes: estadoDestino.nrSeqEstado,
              })
            }
            dataSourceTextProperty='noEstado'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            enableExcelExport
            gridSizeList={[50000]}
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            notAllowChangePage
            showSelectedSizes={false}
            transaction={transaction}
            sumFields
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
