import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Textbox, Switch } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getTipoServicoCidade,
  saveTipoServicoCidade,
  deleteTipoServicoCidade,
} from 'core/services/VEN/tipoServicoCidade';

import { getTipoServicoAutoComplete } from 'core/services/GProj/tipoServico';
import { getCidadeAutoComplete } from 'core/services/SEG/cidade';

export default function TipoServicoCidadeItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getTipoServicoCidade(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveTipoServicoCidade(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteTipoServicoCidade(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchTipoServico = async (e) => {
    const {
      status,
      message: msg,
      data: tipoServicos,
    } = await getTipoServicoAutoComplete({ noTipoServico: e });
    if (msg) onSetMessage(status, msg);
    return tipoServicos;
  };

  const onSearchCidade = async (e) => {
    const { status, message: msg, cidades } = await getCidadeAutoComplete({
      noCidade: e,
    });
    if (msg) onSetMessage(status, msg);
    return cidades;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de TipoServicoCidade'
      transaction={transaction}
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqTipoServicoCidade > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Tipo Servico'
            searchDataSource={onSearchTipoServico}
            selectedItem={data.tipoServico}
            onSelectItem={(tipoServico) => {
              setData({
                ...data,
                tipoServico,
                nrSeqTipoServico: tipoServico.nrSeqTipoServico,
              });
            }}
            dataSourceTextProperty='noTipoServico'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Cidade'
            searchDataSource={onSearchCidade}
            selectedItem={data.cidade}
            onSelectItem={(cidade) => {
              setData({
                ...data,
                cidade,
                nrSeqCidade: cidade.nrSeqCidade,
              });
            }}
            dataSourceTextProperty='noCidade'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Textbox
            label='Alíquota Issqn'
            text={data.aLIQISSQN}
            mask={MaskTypes.Decimal}
            onChangedValue={(aLIQISSQN) => setData({ ...data, aLIQISSQN })}
          />
        </div>

        <div className='col'>
          <Switch
            formControl
            label='Issqn Retido'
            checked={data.fLGISSQNRETIDO}
            onChange={(fLGISSQNRETIDO) => setData({ ...data, fLGISSQNRETIDO })}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
