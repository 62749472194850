import React, { useRef, useState } from 'react';

import {
  Autocomplete,
  Button,
  DatePicker,
  GridView,
  Loader,
  Modal,
  Notification,
} from 'ui/components';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import { getClienteAutoComplete } from 'core/services/FIN';
import {
  getSearchReceitasModal,
  getVinculaReceitaAcerto,
} from 'core/services/FIN/acertoMotoristaReceita';

export default function ModalReceitas(props) {
  const [filtros, setFiltros] = useState();
  const [loading, setLoading] = useState(false);
  const [messageState, setMessage] = useState(null);
  const gridView = useRef();

  const { show, data } = props;

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const salvar = async () => {
    setLoading(true);

    const receitaS = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    if (receitaS.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Selecione um item para vincular ao acerto'
      );
      return;
    }
    const obj = {
      nrSeqAcertoMotorista: data.nrSeqAcertoMotorista,
      nrSeqAcertoMotoristaReceitaS: receitaS,
    };

    const receita = await getVinculaReceitaAcerto(obj);

    if (receita.status === ResponseStatus.Success) {
      setMessage({
        message: receita.message,
        theme:
          receita.status === ResponseStatus.Success
            ? Theme.Success
            : Theme.Danger,
      });

      props.onClose({
        message: receita.message,
        theme: Theme.Success,
        value: receita.data,
      });
    }

    setLoading(false);
  };

  const cancelar = async () => {
    props.onClose({
      message: null,
      theme: null,
    });
  };

  const columns = [
    {
      key: 'nrSeqAcertoMotoristaReceita',
      type: GridView.ColumnTypes.Checkbox,
    },

    {
      key: 'nrDocumento',
      title: 'Nr. Documento',
    },
    {
      key: 'dtReceita',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'vlrReceita',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'tipoReceitaAcerto.noTipoReceitaAcerto',
      title: 'Tipo Receita',
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma Pagamento',
    },
    {
      key: 'cliente.noPessoa',
      title: 'Cliente',
      format: GridView.DataTypes.Text,
    },
    {
      key: 'enderecoRemetente.noCidadeEstado',
      title: 'Origem',
    },
    {
      key: 'enderecoDestino.noCidadeEstado',
      title: 'Destino',
    },
    {
      key: 'noObservacao',
      title: 'Obs',
    },
  ];

  const onSearchCliente = async (e) => {
    const { clientes } = await getClienteAutoComplete({
      noPessoa: e,
      flgFiltrarEmpresa: true,
    });
    return clientes;
  };

  const onBuscarReceitas = async () => {
    setLoading(true);

    const { data: list } = await getSearchReceitasModal(filtros);

    if (gridView && gridView.current) gridView.current.setDataSource(list);

    setLoading(false);
  };

  return (
    <Modal
      loading={loading}
      show={show}
      title='Geração de valores'
      size={BootstrapSizes.Large}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => onSetMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='col-4'>
            <Autocomplete
              label='Cliente'
              searchDataSource={onSearchCliente}
              selectedItem={filtros?.cliente}
              onSelectItem={(cliente) => {
                setFiltros({
                  ...filtros,
                  cliente,
                  nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
          <div className='col-4'>
            <DatePicker
              label='Receita De'
              text={filtros?.dtReceitaDe}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtReceitaDe) =>
                setFiltros({ ...filtros, dtReceitaDe })
              }
            />
          </div>
          <div className='col-4'>
            <DatePicker
              label='Receita Até'
              text={filtros?.dtReceitaAte}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtReceitaAte) =>
                setFiltros({ ...filtros, dtReceitaAte })
              }
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-4 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Mostrar'
              onClick={onBuscarReceitas}
            />
          </div>
        </div>

        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>

        <div className='row'>
          <div className='col-12 mt-3 text-end'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Vincular Receitas Selecionadas'
              onClick={salvar}
            />
            <Button
              style={{ marginLeft: '5px' }}
              theme={Theme.Danger}
              template={Button.Templates.Button}
              text='Cancelar'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
