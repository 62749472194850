import ConvenioEncargo from './convenioEncargo';
import Convenio from './convenio';
import PlanoConvenioConvenioEncargo from './planoConvenioConvenioEncargo';

export default class DeParaEncargoConvenio {
  constructor(jsonObject = {}) {
    this.nrSeqDeParaEncargoConvenio = jsonObject.nrSeqDeParaEncargoConvenio;
    this.noDeParaEncargoConvenio = jsonObject.noDeParaEncargoConvenio;
    this.nrSeqPlanoConvEncargoConv = jsonObject.nrSeqPlanoConvEncargoConv;
    this.nrSeqConvenio = jsonObject.nrSeqConvenio;
    this.nrSeqConvenioEncargo = jsonObject.nrSeqConvenioEncargo;
    this.convenio = new Convenio(jsonObject.convenio ?? {});
    this.convenioEncargo = new ConvenioEncargo(
      jsonObject.convenioEncargo ?? {}
    );
    this.planoConvenioEncargo = new PlanoConvenioConvenioEncargo(
      jsonObject.planoConvenioEncargo ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
