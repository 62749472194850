import React, { useState } from 'react';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  Autocomplete,
  Button,
  Loader,
  Modal,
  Notification,
  Textbox,
  Switch,
  RadioButton,
} from 'ui/components';
import {
  gerarLancamentos,
  getContratosPendenteGeracaoConvenio,
} from 'core/services/ASS/lancamentoAssociado';
import { getConvenioAutoComplete } from 'core/services/ASS/convenio';
import { getContratoList, getFaturaList } from 'core/services/TEL/contrato';
import { getContratoAgrupadoClienteList } from 'core/services/TEL/contratoAgrupado';

export default function ModalGerarLancamento({ show, onClose }) {
  const [dataModalLancamento, setDataModalLancamento] = useState({
    flgTelefonia: false,
    tipoContratoTel: 'F',
  });
  const [dataContaCabecalho, setDataContaCabecalho] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const btsTipoContratoFinanceiro = [
    { text: 'Fatura', value: 'F' },
    { text: 'Agrupado', value: 'A' },
  ];

  const onClickGerarLancamento = async () => {
    if (!dataModalLancamento.nrSeqConvenio) {
      setMessage({
        message: 'Necessário preencher o campo Convênio.',
        theme: Theme.Danger,
      });
      return;
    }
    if (!dataModalLancamento.anoMes) {
      setMessage({
        message: 'Necessário preencher o campo Competência.',
        theme: Theme.Danger,
      });
      return;
    }

    if (
      !dataModalLancamento.anoMes &&
      !dataModalLancamento.convenio?.flgLancamentoDiario
    ) {
      setMessage({
        message: 'Necessário preencher o campo Competência.',
        theme: Theme.Danger,
      });
      return;
    }

    dataModalLancamento.vlrCorrecao = dataModalLancamento.vlrCorrecao ?? 0;
    setLoading(true);

    const { status, message: msg } = await gerarLancamentos(
      dataModalLancamento
    );

    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    if (status === ResponseStatus.Success) {
      setDataModalLancamento({});
    }

    setLoading(false);
  };

  async function filtrarPorPropriedadeComum(array1, array2, propriedadeComum) {
    // Cria um Set com os valores da propriedadeComum no array1
    const valoresArray2 = new Set(array2.map((obj) => obj[propriedadeComum]));

    // Filtra os objetos do array2 cuja propriedadeComum está presente no Set
    const resultados = [];
    array1.forEach((obj) => {
      if (valoresArray2.has(obj[propriedadeComum])) resultados.push(obj);
    });

    return resultados;
  }
  const onSearchConvenio = async (e) => {
    const { status, message: msg, convenios } = await getConvenioAutoComplete({
      noConvenio: e,
    });
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    return convenios;
  };

  const onSearchContratosPendentes = async (e) => {
    const {
      message: msg,
      data: contratos,
    } = await getContratosPendenteGeracaoConvenio(e);

    if (!contratos) setMessage({ message: msg, theme: Theme.Danger });

    setDataContaCabecalho(contratos);
  };

  //  busca constratos agrupados
  const onSearchContratoAgrupado = async (e) => {
    const {
      status,
      message: msg,
      contratosAgrupados,
    } = await getContratoAgrupadoClienteList({ noContratoAgrupado: e });

    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    return filtrarPorPropriedadeComum(
      contratosAgrupados,
      dataContaCabecalho,
      'nrSeqContaCabecalho'
    );
  };

  //  retorna lista de ClienteContaCabecalho
  const onSearchClienteContaContrato = async (e) => {
    const { status, message: msg, contratos } = await getContratoList({
      noCliente: e,
    });

    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    const resultado = await filtrarPorPropriedadeComum(
      contratos,
      dataContaCabecalho,
      'nrSeqClienteContaCab'
    );
    return resultado;
  };

  //  retorna lista de ContaCabecalho de acordo com contrato selecionado
  const onSearchFaturasCliente = async () => {
    if (!dataModalLancamento.clienteContaCab?.nrSeqClienteContaCab) {
      setMessage({
        message: 'Necessário selecionar o contrato primeiro.',
        theme: Theme.Danger,
      });
      return undefined;
    }
    const { status, message: msg, faturas } = await getFaturaList({
      nrSeqClienteContaCab:
        dataModalLancamento.clienteContaCab?.nrSeqClienteContaCab,
    });

    if (!faturas)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return faturas;
  };

  return (
    <Modal
      show={show}
      title='Gerar Lançamento'
      onClose={onClose}
      size={BootstrapSizes.Large}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row justify-content-between'>
          <div className='col-2 mb-3'>
            <Textbox
              maxLength={7}
              label='Competência'
              text={dataModalLancamento.anoMes}
              mask={MaskTypes.Competence}
              onChangedValue={(anoMes) => {
                setDataModalLancamento({
                  ...dataModalLancamento,
                  anoMes,
                });
              }}
            />
          </div>

          <div className='col-4 mb-3'>
            <Autocomplete
              label='Convênio'
              searchDataSource={onSearchConvenio}
              selectedItem={dataModalLancamento.convenio}
              onSelectItem={(convenio) => {
                setDataModalLancamento({
                  ...dataModalLancamento,
                  convenio,
                  nrSeqConvenio: convenio.nrSeqConvenio,
                  flgTelefonia: convenio.flgTelefonia,
                  flgConvenioMassivo: convenio?.flgConvenioMassivo,
                });

                if (
                  convenio &&
                  convenio.flgTelefonia &&
                  convenio.nrSeqOperadora != null
                ) {
                  onSearchContratosPendentes(convenio.nrSeqOperadora);
                }
              }}
              dataSourceTextProperty='noConvenio'
            />
          </div>
          {!dataModalLancamento.flgConvenioMassivo && (
            <div className='col-3 mb-3'>
              <Switch
                formControl
                label='Idêntico ao mês anterior'
                checked={dataModalLancamento.flgLancamentoIdenticoMesAnt}
                onChange={(flgLancamentoIdenticoMesAnt) =>
                  setDataModalLancamento({
                    ...dataModalLancamento,
                    flgLancamentoIdenticoMesAnt,
                  })
                }
              />
            </div>
          )}

          {dataModalLancamento.convenio?.flgConvenioMassivo && (
            <div className='col-3 mb-3'>
              <Switch
                formControl
                label='Cobrança Recorrente'
                checked={dataModalLancamento.flgConvenioMassivo}
                onChange={(flgConvenioMassivo) =>
                  setDataModalLancamento({
                    ...dataModalLancamento,
                    flgConvenioMassivo,
                  })
                }
              />
            </div>
          )}
        </div>
        {dataModalLancamento.flgLancamentoIdenticoMesAnt && (
          <div className='row'>
            <div className='col-2 mb-3'>
              <Textbox
                maxLength={10}
                label='Valor correção'
                text={dataModalLancamento.vlrCorrecao}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrCorrecao) => {
                  setDataModalLancamento({
                    ...dataModalLancamento,
                    vlrCorrecao,
                  });
                }}
              />
            </div>
            <div className='col-3 mb-3'>
              <Switch
                formControl
                label='Percentual'
                checked={dataModalLancamento.flgPercentual}
                onChange={(flgPercentual) =>
                  setDataModalLancamento({
                    ...dataModalLancamento,
                    flgPercentual,
                  })
                }
              />
            </div>
          </div>
        )}
        <div className='row'>
          {dataModalLancamento.convenio?.flgTelefonia && (
            <div className='col-3 text-center mb-3'>
              <RadioButton
                outline
                label='Status'
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                buttons={btsTipoContratoFinanceiro}
                selectedButton={dataModalLancamento.tipoContratoTel}
                onChange={(status) =>
                  setDataModalLancamento({
                    ...dataModalLancamento,
                    tipoContratoTel: status,
                  })
                }
              />
            </div>
          )}
          {dataModalLancamento.convenio?.flgTelefonia &&
            dataModalLancamento.tipoContratoTel === 'A' && (
              <div className='col-4 mb-3'>
                <Autocomplete
                  label='Contrato Agrupado'
                  searchDataSource={onSearchContratoAgrupado}
                  visible={
                    dataModalLancamento.tipoContratoTel &&
                    dataModalLancamento.tipoContratoTel === 'A'
                  }
                  selectedItem={dataModalLancamento.contratoAgrupado}
                  onSelectItem={(contratoAgrupado) => {
                    setDataModalLancamento({
                      ...dataModalLancamento,
                      contratoAgrupado,
                      nrSeqContratoAgrupado:
                        contratoAgrupado.nrSeqContratoAgrupado,
                    });
                  }}
                  dataSourceTextProperty='noContratoAgrupado'
                />
              </div>
            )}
          {dataModalLancamento.convenio?.flgTelefonia &&
            dataModalLancamento.tipoContratoTel === 'F' && (
              <>
                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Contrato'
                    searchDataSource={onSearchClienteContaContrato}
                    selectedItem={dataModalLancamento.clienteContaCab}
                    onSelectItem={(clienteContaCab) => {
                      setDataModalLancamento({
                        ...dataModalLancamento,
                        clienteContaCab,
                        nrSeqClienteContaCab:
                          clienteContaCab.nrSeqClienteContaCab,
                        nrSeqOperadora: clienteContaCab.nrSeqOperadora,
                      });
                    }}
                    dataSourceTextProperty='cdNoCliente'
                  />
                </div>
                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Fatura'
                    visible={
                      dataModalLancamento.tipoContratoTel &&
                      dataModalLancamento.tipoContratoTel === 'F'
                    }
                    searchDataSource={onSearchFaturasCliente}
                    selectedItem={dataModalLancamento.contaCabecalho}
                    onSelectItem={(contaCabecalho) => {
                      setDataModalLancamento({
                        ...dataModalLancamento,
                        contaCabecalho,
                        nrSeqContaCabecalho: contaCabecalho.nrSeqContaCabecalho,
                      });
                    }}
                    dataSourceTextProperty='periodoContaTotalLinhas'
                  />
                </div>
              </>
            )}
        </div>

        <div className='row justify-content-end mt-3 mb-4'>
          <div className='col-3 mb-3'>
            <Button
              outline
              theme={Theme.Success}
              text='Gerar Lançamento'
              icon='fa-solid fa-cloud-check'
              onClick={onClickGerarLancamento}
            />
          </div>
          <div className='col-3 mb-3'>
            <Button
              outline
              text='Cancelar'
              theme={Theme.Danger}
              icon='fas fa-times'
              onClick={() => {
                setDataModalLancamento({});
                onClose();
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
