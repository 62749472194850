export default class CstIpi {
  nrSeqCstIpi: number;

  cdCstIpi: string;

  noCstIpi: string;

  nrSeqGrupoEmpresa: number;

  nrSeqCstIpiInverso: number;

  constructor(jsonObject: CstIpi) {
    this.nrSeqCstIpi = jsonObject.nrSeqCstIpi;
    this.cdCstIpi = jsonObject.cdCstIpi;
    this.noCstIpi = jsonObject.noCstIpi;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqCstIpiInverso = jsonObject.nrSeqCstIpiInverso;
  }

  toJson = () => JSON.stringify(this);
}
