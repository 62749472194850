import React, { useRef, useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  Panel,
  Modal,
  Button,
  CSDLineGraphic,
  CSDBarGraphic,
  CSDPieGraphic,
  Notification,
  Loader,
  Textbox,
  GridView,
  Switch,
} from 'ui/components';
import {
  Theme,
  JustifyContent,
  BootstrapSizes,
  ResponseStatus,
} from 'ui/Helpers/enums';
import { Message } from 'ui/Helpers/interfaces';

import {
  getGraphics,
  searchGrids,
} from 'core/services/FIN/dashboardFinanceiro';

interface Props {
  show: boolean;
  optionsBar: any;
  optionsPie: any;
  optionsCurvaAbc: any;
  columnsAbcClientes: any;
  columnsAbcFornecedores: any;
  columnsDespesasReceitas: any;
  filters: any;
  onClose: () => void;
}

export default function ModalImpressaoGraficos({
  show,
  optionsBar,
  optionsPie,
  optionsCurvaAbc,
  columnsAbcClientes,
  columnsAbcFornecedores,
  columnsDespesasReceitas,
  filters,
  onClose,
}: Props) {
  const dashboardPrint = useRef<any>();
  const resultadoMensal = useRef();
  const resultadoGeral = useRef();
  const tendenciaGeral = useRef();
  const despesas = useRef();
  const receitas = useRef();
  const receberVencidosVencer = useRef();
  const curvaAbcClientes = useRef();
  const curvaAbcFornecedores = useRef();
  const gridViewCurvaAbcClientes = useRef<any>();
  const gridViewCurvaAbcFornecedores = useRef<any>();
  const gridViewDespesasReceitas = useRef<any>();

  const [message, setMessage] = useState<Message | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [switchs, setSwitchs] = useState<any>({});

  const EnumGraficos = Object.freeze({
    ResultadoMensal: 0,
    ResultadoGeral: 1,
    Despesas: 2,
    Receitas: 3,
    ReceberVencidosVencer: 4,
    TendenciaGeral: 5,
    CurvaAbcClientes: 6,
    CurvaAbcFornecedores: 7,
  });

  const onSetMessage = (status: any, msg: any) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const graphics = [
    <CSDLineGraphic ref={tendenciaGeral} options={optionsBar} />,
    <CSDBarGraphic ref={resultadoMensal} options={optionsBar} />,
    <CSDBarGraphic ref={resultadoGeral} options={optionsBar} />,
    <CSDBarGraphic ref={despesas} options={optionsBar} />,
    <CSDBarGraphic ref={receitas} options={optionsBar} />,
    <CSDPieGraphic ref={receberVencidosVencer} options={optionsPie} />,
    <CSDBarGraphic ref={curvaAbcClientes} options={optionsCurvaAbc} />,
    <CSDBarGraphic ref={curvaAbcFornecedores} options={optionsCurvaAbc} />,
  ];

  const configuraGrafico = (datasource: any) => {
    let data: any = {};

    if (datasource.dataSets?.length > 1) {
      data = {
        labels: datasource.labels,
        datasets: datasource.dataSets.map((item: any) => {
          item = {
            data:
              item.dataInteger?.length > 0
                ? item.dataInteger
                : item.dataDecimal,
            label: item.label,
            theme: item.theme,
            yAxisID: 'y',
          };

          return item;
        }),
      };
    } else {
      data = {
        labels: datasource.labels,
        datasets: [
          {
            data:
              datasource.integerData?.length > 0
                ? datasource.integerData
                : datasource.decimalData,
            themes: datasource.themes,
            theme: datasource.theme ?? Theme.Primary,
          },
        ],
      };
    }

    if (datasource.labelDataSet) {
      data.datasets.forEach((item: any) => {
        item.label = datasource.labelDataSet;
        item.theme = datasource.theme ?? Theme.Info;
      });
    }

    return data;
  };

  const carregaGrafico = (data: any, enumGrafico: any) => {
    const graficos: any = {
      [EnumGraficos.ResultadoMensal]: resultadoMensal.current,
      [EnumGraficos.ResultadoGeral]: resultadoGeral.current,
      [EnumGraficos.Despesas]: despesas.current,
      [EnumGraficos.Receitas]: receitas.current,
      [EnumGraficos.ReceberVencidosVencer]: receberVencidosVencer.current,
      [EnumGraficos.TendenciaGeral]: tendenciaGeral.current,
      [EnumGraficos.CurvaAbcClientes]: curvaAbcClientes.current,
      [EnumGraficos.CurvaAbcFornecedores]: curvaAbcFornecedores.current,
    };

    graficos[enumGrafico]?.setDataSource(data);
  };

  const carregaGraficos = (datasource: any) => {
    for (let i = 0; i < datasource.length; i += 1) {
      const data = configuraGrafico(datasource[i]);

      carregaGrafico(data, datasource[i].enumGrafico);
    }
  };

  const montaGridsCurvaAbc = (data: any) => {
    for (let i = 0; i < data.length; i += 1) {
      const dataSet = data[i];
      const objectList = [];
      let totalValue = 0;

      if (dataSet.decimalData.length > 0)
        totalValue = dataSet.decimalData.reduce(
          (sum: any, item: any) => sum + item
        );

      for (let y = 0; y < dataSet.decimalData.length; y += 1) {
        const object: any = {};

        object.value = dataSet.decimalData[y];
        object.title = dataSet.labels[y];
        object.percentage = `${(
          (dataSet.decimalData[y] / totalValue) *
          100
        ).toFixed(1)}%`;

        objectList.push(object);
      }

      if (dataSet.enumGrafico === EnumGraficos.CurvaAbcClientes) {
        gridViewCurvaAbcClientes.current?.setDataSource(objectList);
      } else {
        gridViewCurvaAbcFornecedores.current?.setDataSource(objectList);
      }
    }
  };

  const load = async () => {
    setLoading(true);

    const { status, message: msg, data } = await getGraphics(filters);

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      carregaGraficos(data);

      const {
        status: statusGrids,
        message: msgGrids,
        data: dataGrids,
      } = await searchGrids(filters);

      montaGridsCurvaAbc(
        data.filter(
          (item: any) =>
            item.enumGrafico === EnumGraficos.CurvaAbcFornecedores ||
            item.enumGrafico === EnumGraficos.CurvaAbcClientes
        )
      );

      if (msgGrids) onSetMessage(statusGrids, msgGrids);

      if (statusGrids === ResponseStatus.Success) {
        if (gridViewDespesasReceitas && gridViewDespesasReceitas.current) {
          gridViewDespesasReceitas.current.setDataSource(
            dataGrids.dadosGridDespesaReceita
          );
        }
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (show) {
      load();
    }
  }, [show]);

  const onSend = () => {
    onClose();
  };

  return (
    // @ts-expect-error
    <Modal
      show={show}
      title='Impressão dos Gráficos'
      icon='print'
      size={BootstrapSizes.Large}
      onClose={() => onSend()}
    >
      <Loader loading={loading} />
      {message && (
        <div className='m-3'>
          {/* @ts-expect-error */}
          <Notification
            message={message.message}
            theme={message.theme}
            onClose={() => setMessage(null)}
          />
        </div>
      )}
      {/* @ts-expect-error */}
      <Modal.Body>
        <div className='row mx-3'>
          <div className='col-2'>
            {/* @ts-expect-error */}
            <Switch
              formControl
              checked={switchs?.imprimeFiltros}
              label='Imprimir Filtros'
              onChange={(imprimeFiltros: boolean) =>
                setSwitchs({ ...switchs, imprimeFiltros })
              }
            />
          </div>
          <div className='col'>
            {/* @ts-expect-error */}
            <Switch
              formControl
              checked={switchs?.imprimeGrids}
              label='Imprimir Grids'
              onChange={(imprimeGrids: boolean) =>
                setSwitchs({ ...switchs, imprimeGrids })
              }
            />
          </div>
        </div>
        <div ref={dashboardPrint} className='row m-3'>
          {switchs?.imprimeFiltros && (
            <div className='row mb-4'>
              <div className='col'>
                {/* @ts-expect-error */}
                <Textbox
                  readOnly
                  label='Empresa'
                  text={filters.empresa?.noPessoa}
                />
              </div>
            </div>
          )}
          {switchs?.imprimeFiltros && (
            <div className='row mb-4 justify-content-end'>
              <div className='col-4'>
                {/* @ts-expect-error */}
                <Textbox
                  readOnly
                  label='Data Contábil Inicial'
                  text={filters.dtInicial}
                />
              </div>
              <div className='col-4'>
                {/* @ts-expect-error */}
                <Textbox
                  readOnly
                  label='Data Contábil Final'
                  text={filters.dtFinal}
                />
              </div>
            </div>
          )}
          <div className='row mb-3'>
            <div className='col'>
              {/* @ts-expect-error */}
              <Panel>
                {/* @ts-expect-error */}
                <Panel.Header
                  title='Receita, Despesa e Resultado'
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                />
                <Panel.Body>
                  <div className='row justify-content-center'>
                    <div className='col'>{graphics[0]}</div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              {/* @ts-expect-error */}
              <Panel>
                {/* @ts-expect-error */}
                <Panel.Header
                  title='Resultado Mensal'
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                />
                <Panel.Body>
                  <div className='row justify-content-center'>
                    <div className='col'>{graphics[1]}</div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              {/* @ts-expect-error */}
              <Panel>
                {/* @ts-expect-error */}
                <Panel.Header
                  title='Resultado Geral'
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                />
                <Panel.Body>
                  <div className='row justify-content-center'>
                    <div className='col'>{graphics[2]}</div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              {/* @ts-expect-error */}
              <Panel>
                {/* @ts-expect-error */}
                <Panel.Header
                  title='Despesas'
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                />
                <Panel.Body>
                  <div className='row justify-content-center'>
                    <div className='col'>{graphics[3]}</div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              {/* @ts-expect-error */}
              <Panel>
                {/* @ts-expect-error */}
                <Panel.Header
                  title='Receitas'
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                />
                <Panel.Body>
                  <div className='row justify-content-center'>
                    <div className='col'>{graphics[4]}</div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              {/* @ts-expect-error */}
              <Panel>
                {/* @ts-expect-error */}
                <Panel.Header
                  title='A receber vencidos / A vencer'
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                />
                <Panel.Body>
                  <div className='row justify-content-center'>
                    <div className='col'>{graphics[5]}</div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              {/* @ts-expect-error */}
              <Panel>
                {/* @ts-expect-error */}
                <Panel.Header
                  title='Curva ABC - Clientes'
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                />
                <Panel.Body>
                  <div className='row justify-content-center'>
                    <div className='col'>{graphics[6]}</div>
                  </div>
                  {switchs?.imprimeGrids && (
                    <div className='row'>
                      <div className='col'>
                        <GridView
                          ref={gridViewCurvaAbcClientes}
                          // @ts-expect-error
                          dataColumns={columnsAbcClientes}
                          showSelectSizes={false}
                          showPagination={false}
                          offlineData
                        />
                      </div>
                    </div>
                  )}
                </Panel.Body>
              </Panel>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              {/* @ts-expect-error */}
              <Panel>
                {/* @ts-expect-error */}
                <Panel.Header
                  title='Curva ABC - Fornecedores'
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                />
                <Panel.Body>
                  <div className='row justify-content-center'>
                    <div className='col'>{graphics[7]}</div>
                  </div>
                  {switchs?.imprimeGrids && (
                    <div className='row'>
                      <div className='col'>
                        <GridView
                          ref={gridViewCurvaAbcFornecedores}
                          // @ts-expect-error
                          dataColumns={columnsAbcFornecedores}
                          showSelectSizes={false}
                          showPagination={false}
                          offlineData
                        />
                      </div>
                    </div>
                  )}
                </Panel.Body>
              </Panel>
            </div>
          </div>
          {switchs?.imprimeGrids && (
            <div className='row mb-3'>
              <div className='col'>
                {/* @ts-expect-error */}
                <Panel>
                  {/* @ts-expect-error */}
                  <Panel.Header
                    title='Despesas/Receitas por Competência'
                    theme={Theme.Primary}
                    align={JustifyContent.Start}
                  />
                  <Panel.Body>
                    <div className='row'>
                      <div className='col'>
                        <GridView
                          ref={gridViewDespesasReceitas}
                          // @ts-expect-error
                          dataColumns={columnsDespesasReceitas}
                          showSelectSizes={false}
                          showPagination={false}
                          offlineData
                        />
                      </div>
                    </div>
                  </Panel.Body>
                </Panel>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Imprimir'
          icon='print'
          theme={Theme.Success}
          onClick={useReactToPrint({
            content: () => dashboardPrint.current,
          })}
        />
      </Modal.Footer>
    </Modal>
  );
}
