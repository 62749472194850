import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Textbox } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getProspect,
  saveProspect,
  deleteProspect,
} from 'core/services/POC/prospect';

import { getCidadeAutoComplete } from 'core/services/SEG/cidade';

export default function ProspectItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getProspect(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveProspect(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteProspect(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchCidade = async (e) => {
    const { status, message: msg, cidades } = await getCidadeAutoComplete({
      noCidade: e,
    });
    if (msg) onSetMessage(status, msg);
    return cidades;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Prospect'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqProspect > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-4 col mb-3'>
          <Textbox
            maxLength={80}
            label='Fantasia'
            text={data.noFantasia}
            onChangedValue={(noFantasia) => setData({ ...data, noFantasia })}
          />
        </div>

        <div className='col-4 col mb-3'>
          <Textbox
            maxLength={80}
            label='Razão Social'
            text={data.razaoSocial}
            onChangedValue={(razaoSocial) => setData({ ...data, razaoSocial })}
          />
        </div>

        <div className='col-4 col mb-3'>
          <Textbox
            maxLength={14}
            label='Cnpj'
            mask={MaskTypes.CNPJ}
            text={data.nrCnpj}
            onChangedValue={(nrCnpj) => setData({ ...data, nrCnpj })}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-4 col mb-3'>
          <Textbox
            maxLength={80}
            label='Rua'
            text={data.noRua}
            onChangedValue={(noRua) => setData({ ...data, noRua })}
          />
        </div>

        <div className='col-1 col mb-3'>
          <Textbox
            label='Numero'
            text={data.numero}
            mask={MaskTypes.Integer}
            onChangedValue={(numero) => setData({ ...data, numero })}
          />
        </div>

        <div className='col-2 col mb-3'>
          <Textbox
            maxLength={80}
            label='Bairro'
            text={data.bairro}
            onChangedValue={(bairro) => setData({ ...data, bairro })}
          />
        </div>

        <div className='col-3 col mb-3'>
          <Autocomplete
            label='Cidade'
            searchDataSource={onSearchCidade}
            selectedItem={data.cidade}
            onSelectItem={(cidade) => {
              setData({
                ...data,
                cidade,
                nrSeqCidade: cidade.nrSeqCidade,
              });
            }}
            dataSourceTextProperty='noCidade'
          />
        </div>

        <div className='col-2 col mb-3'>
          <Textbox
            maxLength={8}
            label='Cep'
            text={data.cep}
            onChangedValue={(cep) => setData({ ...data, cep })}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={40}
            label='Complemento'
            text={data.complemento}
            onChangedValue={(complemento) => setData({ ...data, complemento })}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={400}
            label='Observações'
            text={data.obsProspect}
            onChangedValue={(obsProspect) => setData({ ...data, obsProspect })}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
