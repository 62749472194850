import GrupoLinhaChip from './grupoLinhaChip';

export default class GrupoLinha {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoLinha = jsonObject.nrSeqGrupoLinha;
    this.noDescricao = jsonObject.noDescricao;
    this.noObservacoes = jsonObject.noObservacoes;
    this.grupoLinhasChip = (jsonObject.grupoLinhasChip ?? []).map(
      (e) => new GrupoLinhaChip(e)
    );
  }

  toJson = () => JSON.stringify(this);
}
