import qs from 'qs';
import Pagination from 'core/models/pagination';
import EmpresaOrigemSetor from 'core/models/SEG/empresaOrigemSetor';
import api from '../api';

const baseURL = `/SEG/EmpresaOrigemSetor`;

export const getEmpresaOrigemSetorList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new EmpresaOrigemSetor(json)) ?? [],
  };
};
export const getEmpresaOrigemSetorAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    empresas: value?.map((json) => new EmpresaOrigemSetor(json)) ?? [],
  };
};

export const getEmpresaOrigemSetorPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new EmpresaOrigemSetor(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getEmpresaOrigemSetor = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new EmpresaOrigemSetor(value) : {};
};

export const saveEmpresaOrigemSetor = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteEmpresaOrigemSetor = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printEmpresaOrigemSetor = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelEmpresaOrigemSetor = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllEmpresaOrigemSetor = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
