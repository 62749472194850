export default class ColetaOrdemTransporte {
  constructor(jsonObject = {}) {
    this.nrSeqColetaOrdemTransporte = jsonObject.nrSeqColetaOrdemTransporte;
    this.nrRemessa = jsonObject.nrRemessa;
    this.nrOrdemTransporte = jsonObject.nrOrdemTransporte;
    this.noObservacao = jsonObject.noObservacao;
    this.nrSeqColeta = jsonObject.nrSeqColeta;
    this.nrQuantidade = jsonObject.nrQuantidade;
    this.vlrPeso = jsonObject.vlrPeso;
    this.nrDocumento = jsonObject.nrDocumento;
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
