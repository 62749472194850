import Pais from './pais';

export default class Estado {
  constructor(jsonObject = {}) {
    this.cdEstado = jsonObject.cdEstado;
    this.nrSeqEstado = jsonObject.nrSeqEstado;
    this.nrSeqPais = jsonObject.nrSeqPais;
    this.noEstado = jsonObject.noEstado;
    this.cdIbge = jsonObject.cdIbge;
    this.pais = new Pais(jsonObject.pais ?? {});
  }

  toJson = () => JSON.stringify(this);
}
