export default class ApiEnvio {
  constructor(jsonObject = {}) {
    this.cdApiEnvio = jsonObject.cdApiEnvio;
    this.noApiEnvio = jsonObject.noApiEnvio;
    this.flgExigeParametroConfiguracao =
      jsonObject.flgExigeParametroConfiguracao;
  }

  toJson = () => JSON.stringify(this);
}
