import Modeloaparelhocelular from './modeloAparelhoCelular';

export default class PlanoModeloAparelho {
  constructor(jsonObject = {}) {
    this.nrSeqPlanoModeloAparelho = jsonObject.nrSeqPlanoModeloAparelho;
    this.nrSeqPlano = jsonObject.nrSeqPlano;
    this.nrSeqModeloAparelho = jsonObject.nrSeqModeloAparelho;
    this.vlrTotal = jsonObject.vlrTotal;
    this.nrParcelas = jsonObject.nrParcelas;
    this.flgAutorizaEditar = jsonObject.flgAutorizaEditar;
    this.nrSeqTipoChamandamin = jsonObject.nrSeqTipoChamandamin;

    this.modeloAparelhoCelular = new Modeloaparelhocelular(
      jsonObject.modeloAparelhoCelular ?? {}
    );
    this.noModeloAparelho = jsonObject.noModeloAparelho;
  }

  toJson = () => JSON.stringify(this);
}
