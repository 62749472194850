/* eslint-disable import/extensions */
import { Cliente } from '../FIN';
import { Endereco } from '../SEG';
import EnergiaConta from './energiaConta';
import EnergiaDistribuidora from './energiaDistribuidora';
import EnergiaTipoTarifa from './energiaTipoTarifa';
import EnergiaUnidadeConsumidoraUsina from './energiaUnidadeConsumidoraUsina';

enum tipoFornecimento {
  MON = 'Monofásico',
  BI = 'Bifásico',
  TRI = 'Trifásico',
}

const manageTipoFornecimento = (tipo: string = ''): string => {
  if (tipo?.includes('MON')) {
    return tipoFornecimento.MON;
  }

  if (tipo?.includes('BI')) {
    return tipoFornecimento.BI;
  }

  if (tipo?.includes('TRI')) {
    return tipoFornecimento.TRI;
  }

  return '';
};

export default class EnergiaUnidadeConsumidora {
  nrSeqEnergiaUnidadeConsumidora: number;

  nrSeqPessoaCli: number;

  nrSeqEnergiaTipoTarifa: number;

  nrSeqEndereco: number;

  nrSeqEnergiaDistribuidora: number;

  nrUc: string;

  nrClienteUc: string;

  tipoFornecimento: string;

  tipoFornecimentoExtenso: string;

  nrTensao: number;

  nrTensaoMin: number;

  nrTensaoMax: number;

  noLogin: string;

  noSenha: string;

  cliente: Cliente;

  tipoTarifa: EnergiaTipoTarifa;

  distribuidora: EnergiaDistribuidora;

  endereco: Endereco;

  contas: EnergiaConta[];

  flgGeradora: boolean;

  nrGeracaoMensal: number;

  nrTarifaLocacao: number;

  noTipoUsina: string;

  noModalidadeCompensacao: string;

  ucsUsina: EnergiaUnidadeConsumidoraUsina[];

  constructor(jsonObject: EnergiaUnidadeConsumidora) {
    this.nrSeqEnergiaUnidadeConsumidora =
      jsonObject.nrSeqEnergiaUnidadeConsumidora;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.nrSeqEnergiaTipoTarifa = jsonObject.nrSeqEnergiaTipoTarifa;
    this.nrSeqEndereco = jsonObject.nrSeqEndereco;
    this.nrSeqEnergiaDistribuidora = jsonObject.nrSeqEnergiaDistribuidora;
    this.nrUc = jsonObject.nrUc;
    this.nrClienteUc = jsonObject.nrClienteUc;
    this.tipoFornecimento = jsonObject.tipoFornecimento;
    this.tipoFornecimentoExtenso = manageTipoFornecimento(
      jsonObject.tipoFornecimento
    );
    this.nrTensao = jsonObject.nrTensao;
    this.nrTensaoMin = jsonObject.nrTensaoMin;
    this.nrTensaoMax = jsonObject.nrTensaoMax;
    this.noLogin = jsonObject.noLogin;
    this.noSenha = jsonObject.noSenha;
    this.cliente = jsonObject.cliente;
    this.tipoTarifa = jsonObject.tipoTarifa;
    this.distribuidora = jsonObject.distribuidora;
    this.endereco = jsonObject.endereco;
    this.contas =
      jsonObject.contas?.map((conta) => new EnergiaConta(conta)) ?? [];
    this.flgGeradora = jsonObject.flgGeradora;
    this.nrGeracaoMensal = jsonObject.nrGeracaoMensal;
    this.nrTarifaLocacao = jsonObject.nrTarifaLocacao;
    this.noTipoUsina = jsonObject.noTipoUsina;
    this.noModalidadeCompensacao = jsonObject.noModalidadeCompensacao;
    this.ucsUsina =
      jsonObject.ucsUsina?.map(
        (uc) => new EnergiaUnidadeConsumidoraUsina(uc)
      ) ?? [];
  }

  toJson = () => JSON.stringify(this);
}
