export default class ManutencaoChecklistFrotaDocumento {
  constructor(jsonObject = {}) {
    this.nrSeqManutencaoChecklistFrotaDocumento =
      jsonObject.nrSeqManutencaoChecklistFrotaDocumento;
    this.noImagem = jsonObject.noImagem;
    this.tipo = jsonObject.tipo;
    this.tamanho = jsonObject.tamanho;
    this.nrDocumento = jsonObject.nrDocumento;
    this.noDocumento = jsonObject.noDocumento;
    this.nrSeqManutencaoChecklist = jsonObject.nrSeqManutencaoChecklist;
  }

  toJson = () => JSON.stringify(this);
}
