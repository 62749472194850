import React, { useState, useEffect, useRef } from 'react';
import { Themes, generateID } from 'ui/Helpers/utils';

const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

const Notification = ({
  message,
  theme,
  onClose,
  messages = [],
  floatable,
}) => {
  const notiGroup = useRef();
  const [float, setFloat] = useState(false);
  const [defaultPosition, setDefaultPosition] = useState(0);

  if (message) messages.push({ message, theme });

  const handleScroll = (event) => {
    if (event.target.scrollTop > defaultPosition && !float) {
      setFloat(true);
    } else if (event.target.scrollTop <= defaultPosition && float) {
      setFloat(false);
    }
  };

  useEffect(() => {
    const scrollBox = document.getElementById('main-content');

    if (floatable) {
      if (defaultPosition <= 0) {
        setDefaultPosition(notiGroup.current?.offsetTop);
        if (scrollBox?.scrollTop > defaultPosition) setFloat(true);
      }

      scrollBox?.addEventListener('scroll', handleScroll);
    }

    return () => {
      scrollBox?.removeEventListener('scroll', handleScroll);
    };
  }, [floatable, defaultPosition, float]);

  useEffect(() => {
    setTimeout(() => {
      if (onClose) onClose();
    }, 20000);
  });

  const groupedMessages = groupBy(messages, (mes) => mes.theme);
  const notiGroupClass = ['notification-group'];
  if (float) notiGroupClass.push('float');

  return (
    <div ref={notiGroup} className={notiGroupClass.join(' ')}>
      {Array.from(groupedMessages)?.map((item) => {
        const msgtheme = item[0];
        const msgs = item[1];

        const className = ['alert', 'alert-dismissible', 'fade', 'show'];
        if (Themes.includes(msgtheme)) className.push(`alert-${msgtheme}`);

        return (
          <div key={generateID('msgs')} className={className.join(' ')}>
            {msgs.length > 1 && (
              <ul>
                {msgs.map((msg) => (
                  <li key={generateID('msg')}>{msg.message}</li>
                ))}
              </ul>
            )}
            {msgs.length === 1 && msgs[0].message}
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='alert'
              aria-label='Close'
              onClick={onClose}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Notification;
