import qs from 'qs';
import Pagination from 'core/models/pagination';
import Transportadora from 'core/models/FRO/transportadora';
import api from '../api';

const baseURL = `/FRO/Transportadora`;

export const getTransportadoraList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Transportadora(json)) ?? [],
  };
};

export const getTransportadoraPaged = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPaged`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Transportadora(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getTransportadora = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Transportadora(value) : {};
};

export const saveTransportadora = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteTransportadora = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printTransportadora = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelTransportadora = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllTransportadora = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getTransportadoraAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    transportadoras: value?.map((json) => new Transportadora(json)) ?? [],
  };
};
