import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { getMenus } from 'core/services/SEG/menu';
import {
  getTransacoesIniciais,
  getTransacaoPaginaConfiguracaoNoPaginaWeb,
  getTransacaoPaginaPermissaoList,
} from 'core/services/SEG/';
import { PageTypes, Theme } from 'ui/Helpers/utils';
import { CorsManager } from 'ui/Helpers/cors';
import {
  Button,
  CSDBasePage,
  FontAwesomeIcon,
  Loader,
  Notification,
  OldPage,
} from 'ui/components';
import 'bootstrap/dist/js/bootstrap.bundle';
import Header from './header';
import Menu from './menu';

function getTransacoes(menu) {
  let transacoesConcat = [];
  (menu.menus ?? []).forEach((p) => {
    transacoesConcat = transacoesConcat.concat(getTransacoes(p) ?? []);
  });

  return transacoesConcat.concat(menu.transacoes ?? []);
}

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedTransactions: [],
      hideMenu: false,
      pages: [],
    };
  }

  async componentDidMount() {
    if (document.body.classList.contains('login'))
      document.body.classList.remove('login');

    const menus = await getMenus();
    const { transacoes: openedTransactions } = await getTransacoesIniciais();

    this.setState({
      menus,
      pages: [
        {
          pageId: 'PDFViewer',
          component: lazy(() => import(`ui/components/PDFViewer`)),
        },
      ],
    });

    openedTransactions.forEach((transacao) => {
      const findtransaction = this.findTransactionObject(transacao);
      this.onOpenTransaction(findtransaction, undefined, undefined, false);
    });

    window.addEventListener(
      'message',
      (e) => {
        if (e.data.constructor === 'string'.constructor) {
          const { openedTransactions: otState } = this.state;
          const { openedTransactions: ot, message, messageTheme } = CorsManager(
            {
              obj: e,
              paramTransactions: otState,
              onOpenReport: this.onOpenReport,
            }
          );

          if (message)
            this.setState({ openedTransactions: ot, message, messageTheme });
          else this.setState({ openedTransactions: ot });
        }
      },
      false
    );
  }

  findTransaction = (transactionCode) => {
    const { menus } = this.state;

    let transacoes = [];
    (menus ?? []).forEach((p) => {
      transacoes = transacoes.concat(getTransacoes(p) ?? []);
    });

    return transacoes?.find((p) => p.cdTransacao === transactionCode);
  };

  findTransactionObject = (transaction) => {
    const { menus } = this.state;

    let transacoes = [];
    (menus ?? []).forEach((p) => {
      transacoes = transacoes.concat(getTransacoes(p) ?? []);
    });

    return transacoes?.find(
      (p) => p.nrSeqTransacao === transaction.nrSeqTransacao
    );
  };

  addComponent = (pages, pageDir, pagePath, pageType) => {
    const pageId = pageType?.id?.startsWith(PageTypes.Report.id)
      ? pagePath
      : `${pageDir}/${pagePath}`;

    if (
      pagePath &&
      pagePath.toLowerCase().indexOf('.aspx') < 0 &&
      !pages.some((p) => p.pageId === pageId)
    ) {
      try {
        require(`ui/pages/${pageId}`); // eslint-disable-line global-require, import/no-dynamic-require

        pages.push({
          pageId,
          component: lazy(() => import(`ui/pages/${pageId}`)),
        });
      } catch {
        this.setState({
          message: 'Página não encontrada, contate o suporte.',
          messageTheme: Theme.Danger,
        });
        return null;
      }
    }

    return pageId;
  };

  onOpenTransaction = async (
    transacao,
    manut,
    name,
    loadTransactionConfig = true,
    selAndManut = false
  ) => {
    const { openedTransactions, pages } = this.state;
    const {
      nrSeqTransacao,
      noTransacao,
      noDirFonte,
      pages: paginas,
      registryKey,
      register,
      permissao,
      setValues,
    } = transacao;

    let transacaoPagina = {};
    if (paginas.length > 1) {
      transacaoPagina = paginas.find((p) => p.flgPrincipal);
    } else {
      [transacaoPagina] = paginas;
    }

    const path = transacaoPagina.noPaginaWeb;
    const pageId = this.addComponent(pages, noDirFonte, path);
    if (!pageId) return;

    let transaction = null;

    for (let index = 0; index < openedTransactions.length; index += 1) {
      if (
        openedTransactions[index] &&
        openedTransactions[index].noTransacao === noTransacao
      ) {
        transaction = openedTransactions[index];
      } else if (openedTransactions[index]) {
        if (
          openedTransactions[index].noTransacao !== `${noTransacao} PENDING`
        ) {
          openedTransactions[index].active = false;
        }
      }
    }
    if (transaction) {
      if (register) {
        if (register[registryKey] !== transaction.register[registryKey]) {
          openedTransactions.pop(transaction);
          transaction = false;
        }
      } else if (registryKey > 0 && registryKey !== undefined) {
        if (transaction.registryKey) {
          if (registryKey !== transaction.registryKey) {
            openedTransactions.pop(transaction);
            transaction = false;
          }
        }
      }
    }

    if (!transaction) {
      const noTransacaoPending = `${noTransacao} PENDING`;

      const transPending = {
        nrSeqTransacao,
        noTransacao: noTransacaoPending,
        noDirFonte,
        active: true,
        pages: paginas,
      };

      openedTransactions.push(transPending);

      let settings = [];
      let settingsActions = [];

      if (loadTransactionConfig) {
        const {
          configuracoes,
        } = await getTransacaoPaginaConfiguracaoNoPaginaWeb({
          noPaginaWeb: transacaoPagina.noPaginaWeb,
        });

        const {
          data: configuracoesActions,
        } = await getTransacaoPaginaPermissaoList({
          noPaginaWeb: transacaoPagina.noPaginaWeb,
        });

        settings = configuracoes;
        settingsActions = configuracoesActions;
      }

      openedTransactions.pop(transPending);

      const trans = {
        nrSeqTransacao,
        noTransacao,
        noDirFonte,
        active: true,
        pages: paginas,
        permissao,
      };

      if (setValues !== undefined && setValues !== null) {
        if (setValues.readValues === true) {
          trans.setValues = setValues;
          setValues.readValues = false;
        }
      }
      if (registryKey !== undefined) trans.registryKey = registryKey;
      if (register !== undefined) trans.register = register;

      const pagesAdd = [];

      if (!manut || selAndManut) {
        pagesAdd.push({
          transaction: trans,
          nrSeqTransacaoPagina: transacaoPagina.nrSeqTransacaoPagina,
          transacaoPagina,
          pageId,
          path,
          settings,
          settingsActions,
          active: !manut,
          reload: false,
          flgPrincipal: transacaoPagina.flgPrincipal,
          ...PageTypes.Selection,
        });
      }

      if (manut === true) {
        let transacaoPaginaManut = {};

        if (!name) {
          transacaoPaginaManut = paginas.find(
            (p) =>
              p.flgPrincipal === false &&
              (p.noPaginaWeb.toLowerCase().includes('_item') ||
                p.noPaginaWeb.toLowerCase().includes('manutencao'))
          );
        } else {
          transacaoPaginaManut = paginas.find(
            (p) =>
              p.flgPrincipal === false &&
              p.noPaginaWeb.toLowerCase().includes(name.toLowerCase())
          );
        }

        const pathManut = transacaoPaginaManut.noPaginaWeb;
        const pageIdManut = this.addComponent(pages, noDirFonte, pathManut);

        const {
          configuracoes: settingsManut,
        } = await getTransacaoPaginaConfiguracaoNoPaginaWeb({
          noPaginaWeb: pathManut,
        });

        const {
          data: settingsActionsManut,
        } = await getTransacaoPaginaPermissaoList({
          noPaginaWeb: pathManut,
        });

        pagesAdd.push({
          transaction: trans,
          transacaoPagina: transacaoPaginaManut,
          pageId: pageIdManut,
          path: pathManut,
          nrSeqTransacaoPagina: transacaoPaginaManut.nrSeqTransacaoPagina,
          settings: settingsManut,
          settingsActions: settingsActionsManut,
          active: manut,
          reload: false,
          ...PageTypes.Maintenence,
        });
      }

      openedTransactions.push({
        ...trans,
        pages: pagesAdd,
      });
    } else {
      transaction.active = true;
      if (registryKey !== undefined) transaction.registryKey = registryKey;
      const page = transaction.pages.find(
        (p) => p && p.id === PageTypes.Selection.id && p.path !== path
      );

      if (page && path) page.path = path;
    }

    this.setState({ openedTransactions, pages });
  };

  onCloseTransaction = (transacao) => {
    const { openedTransactions } = this.state;

    // Gambi para nunca trocar o array de posição, (quando é removido um item do meio ele reorganiza fazendo dar reload nas pages)
    const pageIndex = openedTransactions.indexOf(transacao);
    if (pageIndex + 1 === openedTransactions.length) {
      // se esta removendo o ultimo tenta limpar o array sem reorganizar, excluindo sempre os ultimos
      openedTransactions.splice(pageIndex, 1);

      while (
        openedTransactions.length > 0 &&
        openedTransactions[openedTransactions.length - 1] === null
      ) {
        openedTransactions.splice(openedTransactions.length - 1, 1);
      }
    } else openedTransactions[pageIndex] = null;

    if (openedTransactions.length && transacao.active)
      openedTransactions[openedTransactions.length - 1].active = true;

    this.setState({ openedTransactions });
  };

  onOpenReport = (transactionName, data, pageType = PageTypes.Report) => {
    this.onOpenPage(transactionName, 'PDFViewer', pageType, data);
  };

  onOpenPage = async (
    transactionName,
    pagePath,
    pageType,
    data,
    icon,
    title
  ) => {
    const { openedTransactions, pages } = this.state;

    if (pageType === PageTypes.Other) {
      if (icon) pageType.icon = icon;
      if (title) pageType.title = title;
    }

    const transaction = openedTransactions.find(
      (item) => item && item.noTransacao === transactionName
    );

    let hasPage = false;
    for (let i = 0; i < pages.length; i += 1) {
      const item = transaction.pages[i];

      if (item && item.id === pageType.id) {
        hasPage = true;

        if (item.id.startsWith(PageTypes.Report.id)) item.src = data;
        else {
          item.registryKey = data;
          item.path = pagePath;
        }
        item.active = true;
        item.reload = !item.reload ?? true;
      } else if (item) item.active = false;
    }

    if (!hasPage) {
      const { noDirFonte, pages: paginas } = transaction;
      const pageId = this.addComponent(pages, noDirFonte, pagePath, pageType);
      if (!pageId) return;

      const newPage = {
        pageId,
        path: pagePath,
        active: true,
        ...pageType,
        ...transaction,
      };

      const {
        configuracoes: settings,
      } = await getTransacaoPaginaConfiguracaoNoPaginaWeb({
        noPaginaWeb: pagePath,
      });

      const {
        data: settingsActionsManut,
      } = await getTransacaoPaginaPermissaoList({
        noPaginaWeb: pagePath,
      });

      newPage.settings = settings;
      newPage.settingsActions = settingsActionsManut;

      const transacaoPagina = paginas.find((p) => p.noPaginaWeb === pagePath);

      if (transacaoPagina) {
        newPage.nrSeqTransacaoPagina = transacaoPagina?.nrSeqTransacaoPagina;
      }

      if (pageType.id.startsWith(PageTypes.Report.id)) newPage.src = data;
      else newPage.registryKey = data;
      transaction.pages.push(newPage);
    }

    this.setState({ openedTransactions, pages });
  };

  onSelectPage = (pageId) => {
    const { openedTransactions } = this.state;

    const transaction = openedTransactions.find((item) => item?.active);

    for (let index = 0; index < transaction.pages.length; index += 1) {
      const item = transaction.pages[index];
      item.active = item.id === pageId;
    }

    this.setState({ openedTransactions });
  };

  render() {
    const {
      openedTransactions,
      userNotifications,
      menus,
      hideMenu,
      message,
      messageTheme,
    } = this.state;
    const {
      authentication: { user },
    } = this.props;

    const activeTransaction = openedTransactions.find(
      (transaction) => transaction?.active ?? false
    );

    const rendered = openedTransactions.map((transaction) => {
      if (transaction) {
        return transaction.pages?.map((page) => {
          const props = {
            user,
            reload: page.reload,
            isActive: transaction.active && page.active,
          };

          if (transaction.registryKey && !page.registryKey)
            props.registryKey = transaction.registryKey;
          else props.registryKey = page.registryKey;

          if (transaction.register) props.register = transaction.register;

          if (!transaction.active || !page.active) props.className = 'd-none';
          let pathPage = page.path;
          if (pathPage === undefined) pathPage = page.noPaginaWeb;

          if (pathPage.toLowerCase().indexOf('.aspx') > 0)
            return React.createElement(OldPage, {
              ...props,
              resize: page.resize ?? true,
              key: page.nrSeqTransacaoPagina,
              sourcePath: transaction.noDirFonte,
              path: pathPage,
            });

          const { pages } = this.state;
          const Comp = pages.find((p) => p.pageId === page.pageId).component;

          return (
            <CSDBasePage
              key={page.nrSeqTransacaoPagina}
              page={{ ...page, user }}
            >
              {React.createElement(Comp, {
                ...props,
                src: page.src,
                transaction,
                onOpenPage: this.onOpenPage,
                onOpenTransaction: this.onOpenTransaction,
                onOpenReport: this.onOpenReport,
                findTransaction: this.findTransaction,
                onSelectPage: this.onSelectPage,
              })}
            </CSDBasePage>
          );
        });
      }

      return null;
    });

    return (
      <div className='main-content-box'>
        <div className='main-content-menu'>
          <Menu
            menus={menus}
            onOpenTransaction={this.onOpenTransaction}
            hideMenu={hideMenu}
          />
        </div>
        <div id='main-content' className='main-content'>
          <Header
            openedTransactions={openedTransactions}
            userNotifications={userNotifications}
            onCloseTransaction={this.onCloseTransaction}
            onOpenTransaction={this.onOpenTransaction}
            onToggleMenu={() => this.setState({ hideMenu: !hideMenu })}
          />
          <div className='main-content-container'>
            {message && (
              <div className='mb-3'>
                <Notification
                  message={message}
                  theme={messageTheme}
                  onClose={() => this.setState({ message: undefined })}
                />
              </div>
            )}
            <ul className='nav nav-tabs' id='myTab' role='tablist'>
              {activeTransaction?.pages
                ?.sort((a, b) => {
                  if (a.index < b.index) return -1;
                  return 1;
                })
                .map((page) => (
                  <li
                    key={page.nrSeqTransacaoPagina}
                    className='nav-item'
                    role='presentation'
                  >
                    <Button
                      data-bs-toggle='tab'
                      role='tab'
                      aria-selected={page.active}
                      className={`btn btn-link nav-link ${
                        page.active ? 'active' : ''
                      }`}
                      onClick={() => {
                        if (page.id === 'report') {
                          if (document.getElementById('report') != null) {
                            document.getElementById(
                              'report'
                            ).src = document.getElementById('report').src;
                          }
                        }
                        this.onSelectPage(page.id);
                      }}
                    >
                      {page.icon && (
                        <FontAwesomeIcon className='me-2' icon={page.icon} />
                      )}
                      {page.title}
                    </Button>
                  </li>
                ))}
            </ul>

            <div className='tab-content' id='myTabContent'>
              <div
                className='tab-pane fade show active'
                id='home'
                role='tabpanel'
                aria-labelledby='home-tab'
              >
                <Suspense fallback={<Loader loading />}>{rendered}</Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (store) => ({
  authentication: store.authState.authentication,
});
export default connect(mapStateToProps, null)(Home);
