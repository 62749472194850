import qs from 'qs';
import Pagination from 'core/models/pagination';
import TipoMovimentoRemessa from 'core/models/FIN/tipoMovimentoRemessa';
import api from '../api';

const baseURL = `/FIN/TipoMovimentoRemessa`;

export const getTipoMovimentoRemessaList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TipoMovimentoRemessa(json)) ?? [],
  };
};
export const getTipoMovimentoRemessaAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TipoMovimentoRemessa(json)) ?? [],
  };
};

export const getTipoMovimentoRemessaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TipoMovimentoRemessa(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getTipoMovimentoRemessa = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new TipoMovimentoRemessa(value) : {};
};

export const saveTipoMovimentoRemessa = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteTipoMovimentoRemessa = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printTipoMovimentoRemessa = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelTipoMovimentoRemessa = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllTipoMovimentoRemessa = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
