import * as actions from '../actions/actionTypes';

export const STARTING = '@starting';
export const AUTH_DATA = '@dataAuth';

const initialState = {
  isAuthenticated:
    sessionStorage.getItem(AUTH_DATA) &&
    JSON.parse(sessionStorage.getItem(AUTH_DATA)).isAuthenticated,
  starting: sessionStorage.getItem(STARTING),
  authentication: JSON.parse(sessionStorage.getItem(AUTH_DATA)),
};

export const authReducer = (state = initialState, action) => {
  let authentication = {};

  switch (action.type) {
    case actions.AUTH_LOGIN:
      authentication = {
        firstaccess: true,
        refreshing: false,
        isAuthenticated: true,
        lastrefresh: new Date(),
        user: action.user,
      };

      sessionStorage.setItem(AUTH_DATA, JSON.stringify(authentication));
      sessionStorage.setItem(STARTING, true);

      return {
        ...state,
        isAuthenticated: authentication.isAuthenticated,
        starting: authentication.starting,
        authentication,
      };
    case actions.AUTH_LOGOUT:
      sessionStorage.removeItem(AUTH_DATA);
      sessionStorage.removeItem(STARTING, true);

      return {
        ...state,
        isAuthenticated: false,
        starting: false,
        authentication: null,
      };
    case actions.AUTH_REFRESH:
      authentication = JSON.parse(sessionStorage.getItem(AUTH_DATA));
      authentication.firstaccess = false;
      authentication.refreshing = false;
      authentication.isAuthenticated = true;
      authentication.lastrefresh = new Date();

      sessionStorage.setItem(AUTH_DATA, JSON.stringify(authentication));

      return authentication;
    default:
      return state;
  }
};
