import React, { useEffect, useState, forwardRef, useRef } from 'react';
import {
  ResponseStatus,
  BootstrapSizes,
  Theme,
  toBase64,
  JustifyContent,
  MimeTypes,
} from 'ui/Helpers/utils';
import {
  Autocomplete,
  Textbox,
  DatePicker,
  RadioButton,
  Switch,
  Capture,
  DropdownList,
  Panel,
  Button,
  GridView,
  Textarea,
  FileUpload,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { ColumnDataTypes, StatusGrid, ColumnTypes } from 'ui/Helpers/enums';
import { getEstadoCivilAutoComplete } from 'core/services/SEG/estadocivil';
import { getProfissaoAutoComplete } from 'core/services/SEG/profissao';
import { getTipoDocumentoPessoaAutoComplete } from 'core/services/SEG';
import { getEnvioPadraoAutoComplete } from 'core/services/TEL/envioPadrao';
import { getCidadeAutoComplete, getCep } from 'core/services/SEG/cidade';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
import { getPaisAutoComplete } from 'core/services/SEG/pais';
import { getBancoAutoComplete } from 'core/services/FIN/banco';

const DetalhesAssociado = forwardRef(({ data, setData, onSetMessage }) => {
  const gridViewHistoricoContatos = useRef();
  const gridViewEndereco = useRef();
  const gridViewInfoBancaria = useRef();
  const gridViewDocumentos = useRef();

  const [dadosAssociado, setDadosAssociado] = useState({ noTipoPessoa: 'F' });
  const [documentos, setDocumentos] = useState(null);
  const [picture, setPicture] = useState(null);

  const [dropDownListaSexos, setDropDownListaSexos] = useState();
  const [enderecoAss, setEnderecoAss] = useState({});
  const [contatos, setContatos] = useState({});
  const [historicoContato, setHistoricoContato] = useState({});
  const [informacaoBancaria, setInformacaoBancaria] = useState({});
  const [documentData, setDocumentData] = useState({});
  const [importedFile, setImportedFile] = useState([]);

  const tipoPessoa = [
    {
      text: 'Física',
      value: 'F',
    },
    {
      text: 'Jurídica ',
      value: 'J',
    },
  ];

  const listaSexos = [
    {
      text: 'Masculino',
      value: 1,
      showTextOnSelect: false,
    },
    {
      text: 'Feminino',
      value: 2,
      showTextOnSelect: false,
    },

    {
      text: 'Outro',
      value: 3,
      showTextOnSelect: false,
    },
  ];

  const tipoconta = [
    {
      text: 'Corrente',
      value: 'C',
    },
    {
      text: 'Poupança',
      value: 'P',
    },
  ];

  useEffect(() => {
    const dadosPessoaAss =
      dadosAssociado !== null ? dadosAssociado : data.pessoa;

    if (documentos && documentos.nrCpf && documentos.nrCpf.length > 14) {
      dadosPessoaAss.pessoaJuridica = { ...documentos };
    } else {
      dadosPessoaAss.pessoaFisica = { ...documentos };
    }

    setData({
      ...data,
      ...dadosAssociado,
      enderecos: dadosPessoaAss?.enderecos,
      contato: contatos,
      pessoa: {
        ...dadosPessoaAss,
        profissao: documentos?.profissao,
      },
      noImagemBase64: picture,
    });
  }, [dadosAssociado, documentos, contatos, picture]);

  useEffect(() => {
    setData({
      ...data,
      contato: contatos,
    });
  }, [contatos]);

  useEffect(() => {
    setDadosAssociado({
      ...dadosAssociado,
      ...data.pessoa,
      noPessoa: data.noPessoa,
    });

    let sexoPessoa = null;

    if (data.pessoa && data.pessoa.pessoaFisica) {
      sexoPessoa = listaSexos.filter(
        (el) => el.value === data.pessoa.pessoaFisica.nrSeqSexo
      );
    }

    setDocumentos({
      ...data.pessoa?.pessoaFisica,
      sexo: sexoPessoa && sexoPessoa.length > 0 ? sexoPessoa[0] : null,
    });

    setContatos({ ...data.contato });

    setPicture(data?.noImagemBase64 || null);

    setDropDownListaSexos(listaSexos);

    if (gridViewEndereco && gridViewEndereco.current) {
      gridViewEndereco.current.setDataSource(data.enderecos ?? []);
    }

    if (gridViewInfoBancaria && gridViewInfoBancaria.current) {
      gridViewInfoBancaria.current.setDataSource(
        data.informacoesBancarias ?? []
      );
    }

    if (gridViewDocumentos && gridViewDocumentos.current) {
      gridViewDocumentos.current.setDataSource(data.associadoDocumentos ?? []);
    }
  }, [data.nrSeqPessoaAss]);

  const onSearchProfissao = async (e) => {
    const { profissoes } = await getProfissaoAutoComplete({
      noProfissao: e,
    });

    return profissoes;
  };

  const onSearchEstadoCivil = async (e) => {
    const { estadosCivis } = await getEstadoCivilAutoComplete({
      noEstadoCivil: e,
    });

    return estadosCivis;
  };

  const onSearchEnvioPadrao = async (e) => {
    const { envios } = await getEnvioPadraoAutoComplete({
      noEnvioPadrao: e,
    });

    return envios;
  };

  const onSearchCep = async () => {
    if (enderecoAss.cep && enderecoAss.cep.length > 4) {
      const enderecoInfos = await getCep({
        cep: enderecoAss.cep,
      });

      if (enderecoInfos) {
        setEnderecoAss({
          ...enderecoAss,
          cep: enderecoInfos.cep,
          bairro: enderecoInfos.bairro,
          noRua: enderecoInfos.noRua,
          cidade: enderecoInfos.cidade,
          nrSeqCidade: enderecoInfos.cidade.nrSeqCidade,
          noCidade: enderecoInfos.cidade.noCidade,
          estado: {
            ...enderecoInfos.cidade.estado,
            nrSeqPais: enderecoInfos.cidade.estado.pais.nrSeqPais,
          },
          pais: enderecoInfos.cidade.estado.pais,
        });
      }
    }
  };

  const onSearchPais = async (e) => {
    const { status, message: msg, paises } = await getPaisAutoComplete({
      noPais: e,
    });
    onSetMessage(status, msg);
    return paises;
  };

  const onSearchEstado = async (e) => {
    const { status, message: msg, estados } = await getEstadoAutoComplete({
      noEstado: e,
      nrSeqPais: enderecoAss?.pais?.nrSeqPais ?? null,
    });
    onSetMessage(status, msg);
    return estados;
  };

  const onSearchCidade = async (e) => {
    const { status, message: msg, cidades } = await getCidadeAutoComplete({
      noCidade: e,
      nrSeqEstado: enderecoAss.estado?.nrSeqEstado ?? null,
    });
    onSetMessage(status, msg);
    return cidades;
  };

  const onSearchBanco = async (e) => {
    const { status, message: msg, bancos } = await getBancoAutoComplete({
      noBanco: e,
    });
    onSetMessage(status, msg);
    return bancos;
  };

  const onAddHistoricoContato = async () => {
    const listaHistoricoContato =
      gridViewHistoricoContatos.current.getDataSource() || [];

    if (!historicoContato.descricao) {
      onSetMessage(ResponseStatus.Error, 'Necessário adiconar descrição.');
    }

    const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;
    listaHistoricoContato.push({
      ...historicoContato,
      usuario: sessionUser,
      dataContato: Date.now(),
    });

    if (gridViewHistoricoContatos && gridViewHistoricoContatos.current) {
      gridViewHistoricoContatos?.current?.setDataSource(listaHistoricoContato);
    }

    setHistoricoContato({});
  };

  const onSearchTipoDocumentoPessoa = async (e: any) => {
    const res = await getTipoDocumentoPessoaAutoComplete({
      noTipoDocumentoPessoa: e,
      flgAssociado: true,
    });

    return res.data;
  };

  const onClickAddEndereco = async () => {
    if (enderecoAss) {
      const obj = {
        ...enderecoAss,
        status:
          enderecoAss.nrSeqEndereco > 0
            ? GridView.EnumStatus.Alterar
            : GridView.EnumStatus.Inserir,
      };

      const listaEndereco = gridViewEndereco.current.getDataSource();
      listaEndereco.push(obj);

      if (gridViewEndereco && gridViewEndereco.current)
        gridViewEndereco.current.setDataSource(listaEndereco);

      setEnderecoAss({});

      setDadosAssociado({
        ...dadosAssociado,
        enderecos: listaEndereco,
      });
    } else {
      onSetMessage(false, 'Informe o Plano do Convênio.');
    }
  };

  const onClickEditEndereco = async (selectedValue, datasource) => {
    const listaEndereco = datasource.filter((item) => item !== selectedValue);

    setEnderecoAss({
      ...selectedValue,
      status:
        enderecoAss.nrSeqEndereco > 0 ? GridView.EnumStatus.Alterar : null,
    });

    if (gridViewEndereco && gridViewEndereco.current)
      gridViewEndereco.current.setDataSource(listaEndereco);
  };

  const onClickRemoveEndereco = async (selectedValue, datasource) => {
    const listaEndereco = datasource.filter((el) => el !== selectedValue);

    if (selectedValue.status !== GridView.EnumStatus.Inserir) {
      selectedValue.status = GridView.EnumStatus.Remover;

      listaEndereco.push(selectedValue);
    }

    if (gridViewEndereco && gridViewEndereco.current)
      gridViewEndereco.current.setDataSource(listaEndereco);

    setDadosAssociado({
      ...dadosAssociado,
      enderecos: listaEndereco,
    });
  };

  const onClickAddInfoBancaria = async () => {
    if (informacaoBancaria) {
      const obj = {
        ...informacaoBancaria,
        status:
          informacaoBancaria.nrSeqPessoaInformacaoBancaria > 0
            ? GridView.EnumStatus.Alterar
            : GridView.EnumStatus.Inserir,
      };

      const listaInfoBancaria = gridViewInfoBancaria.current.getDataSource();
      listaInfoBancaria.push(obj);

      if (gridViewInfoBancaria && gridViewInfoBancaria.current)
        gridViewInfoBancaria.current.setDataSource(listaInfoBancaria);

      setInformacaoBancaria({});

      setDadosAssociado({
        ...dadosAssociado,
        informacoesBancarias: listaInfoBancaria,
      });
    } else {
      onSetMessage(false, 'Adicione as informações bancarias do associado.');
    }
  };

  const onClickEditInfoBancaria = async (selectedValue, datasource) => {
    const listaInfoBancaria = datasource.filter(
      (item) => item !== selectedValue
    );

    setInformacaoBancaria({
      ...selectedValue,
      status:
        informacaoBancaria.nrSeqPessoaInformacaoBancaria > 0
          ? GridView.EnumStatus.Alterar
          : null,
    });

    if (gridViewInfoBancaria && gridViewInfoBancaria.current)
      gridViewInfoBancaria.current.setDataSource(listaInfoBancaria);
  };

  const onClickRemoveInfoBancaria = async (selectedValue, datasource) => {
    const listaInfoBancaria = datasource.filter((el) => el !== selectedValue);

    if (selectedValue.status !== GridView.EnumStatus.Inserir) {
      selectedValue.status = GridView.EnumStatus.Remover;

      listaInfoBancaria.push(selectedValue);
    }

    if (gridViewInfoBancaria && gridViewInfoBancaria.current)
      gridViewInfoBancaria.current.setDataSource(listaInfoBancaria);

    setDadosAssociado({
      ...dadosAssociado,
      informacoesBancarias: listaInfoBancaria,
    });
  };

  const columnsContato = [
    { key: 'dataContato', title: 'Data Contato', sortable: false },
    { key: 'nrSeqUsuario', title: 'Cód. Usuário', sortable: false },
    { key: 'usuario.noUsuario', title: 'Usuário', sortable: false },
    { key: 'descricao', title: 'Descrição', sortable: false },
  ];

  const columnsEndereco = [
    {
      key: 'nrSeqEndereco',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'noRua', title: 'Endereço' },

    { key: 'numero', title: ' Número' },
    { key: 'complemento', title: 'Complemento' },
    {
      key: 'bairro',
      title: 'Bairro',
    },
    { key: 'noCidade', title: 'Cidade' },

    { key: 'cep', title: 'CEP' },

    {
      key: 'nrSeqEndereco',
      type: GridView.ColumnTypes.Button,
      title: 'Editar',
      onClick: (e, dataSource) => onClickEditEndereco(e, dataSource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqEndereco',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemoveEndereco(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsInfoBancarias = [
    {
      key: 'nrSeqPessoaInformacaoBancaria',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'banco.noBanco', title: 'Banco' },

    { key: 'cdAgencia', title: 'Agência' },
    { key: 'cdContaCorrente', title: 'Conta Corrente' },
    {
      key: 'tpContaCorrente',
      title: 'Tipo Conta',
    },
    { key: 'noUsuarioCad', title: 'Cadastrado Por' },
    {
      key: 'dtCad',
      title: 'Data Cadastro',
      format: GridView.DataTypes.Date,
    },
    { key: 'noUsuarioAlt', title: 'Atualizado Por' },
    {
      key: 'dtAlt',
      title: 'Data Alteração',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrSeqPessoaInformacaoBancaria',
      type: GridView.ColumnTypes.Button,
      title: 'Editar',
      onClick: (e, dataSource) => onClickEditInfoBancaria(e, dataSource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqPessoaInformacaoBancaria',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemoveInfoBancaria(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onClickAddDocumento = async () => {
    let validadeDeAcordo = true;

    // #region validação se controla validade
    const controlaDtValidade =
      documentData.tipoDocumentoPessoa &&
      documentData.tipoDocumentoPessoa?.flgControlaValidade;

    if (controlaDtValidade) {
      validadeDeAcordo =
        !!documentData.dtIniValidade && !!documentData.dtFimValidade;
    }

    // #endregion

    if (
      documentData.tipoDocumentoPessoa &&
      documentData.nrDocumento &&
      validadeDeAcordo
    ) {
      const obj = {
        ...documentData,
        status: documentData.status ?? StatusGrid.Inserir,
        flgExibeDownload: false,
      };

      if (obj.status === StatusGrid.Inserir && importedFile.length > 0) {
        const noImagemBase64 = await toBase64(importedFile[0]);

        const noImagem = String(noImagemBase64).substring(
          String(noImagemBase64).indexOf(',') + 1
        );

        obj.noImagem = noImagem;
        obj.tipo = importedFile[0].type;
        obj.tamanho = String(importedFile[0].size);
        obj.flgExibeDownload = true;
        obj.file = importedFile;
      }

      const list = gridViewDocumentos.current.getDataSource();
      list.push(obj);

      if (gridViewDocumentos && gridViewDocumentos.current)
        gridViewDocumentos.current.setDataSource(list);

      setDocumentData({});
      setImportedFile([]);
      setDadosAssociado({
        ...dadosAssociado,
        associadoDocumentos: list,
      });
    } else if (!documentData.nrDocumento) {
      onSetMessage(ResponseStatus.Error, 'Informe o Número do Documento');
    } else if (!documentData.tipoDocumentoPessoa) {
      onSetMessage(ResponseStatus.Error, 'Informe o Tipo Documento');
    } else if (
      documentData.tipoDocumentoPessoa?.flgControlaValidade &&
      (!documentData.dtIniValidade || !documentData.dtFimValidade)
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Informe as Datas de Inicio e Fim da Validade'
      );
    }
  };

  const onClickEditDocumento = (e: any, datasource: any[]) => {
    const obj = datasource.find((item) => item === e);

    if (obj.status !== StatusGrid.Inserir) {
      obj.status = StatusGrid.Alterar;
    }

    setDocumentData({ ...obj });

    const listaEquipamentos = datasource.filter((item) => item !== e);

    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource(listaEquipamentos);
  };

  const onClickRemoveDocumento = async (e: any) => {
    const list = gridViewDocumentos.current.getDataSource();

    list.filter((el: any) => el === e)[0].status = StatusGrid.Remover;

    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource(list);
  };

  const onClickDownload = async (e: { tipo: any, noImagem: string }) => {
    const linkSource = `data:${e.tipo};base64, ${e.noImagem}`;

    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = e.tipoDocumentoPessoa.noTipoDocumentoPessoa;
    downloadLink.click();
  };

  const onClickBaixarTodos = async () => {
    const list = gridViewDocumentos.current.getDataSource();

    list.forEach((e: { noImagem: string, status: StatusGrid }) => {
      if (e.noImagem && e.status !== StatusGrid.Remover) {
        const linkSource = `data:${e.tipo};base64, ${e.noImagem}`;

        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = e.tipoDocumentoPessoa.noTipoDocumentoPessoa;
        downloadLink.click();
      }
    });
  };

  const columns = [
    {
      key: 'tipoDocumentoPessoa.noTipoDocumentoPessoa',
      title: 'Tipo',
      sortable: false,
    },
    { key: 'nrDocumento', title: 'Número', sortable: false },
    {
      key: 'dtIniValidade',
      title: 'Inicio da Vigência',
      sortable: false,
      format: ColumnDataTypes.Date,
    },
    {
      key: 'dtFimValidade',
      title: 'Fim da Vigência',
      sortable: false,
      format: ColumnDataTypes.Date,
    },
    {
      key: 'obs',
      title: 'Observação',
      sortable: false,
    },
    {
      key: 'nrSeqPessoaDocumento_btnDownload',
      type: ColumnTypes.Button,
      title: 'Baixar',
      onClick: (e: any) => onClickDownload(e),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgExibeDownload',
    },
    {
      key: 'nrSeqPessoaDocumento_btnEdit',
      type: ColumnTypes.Button,
      onClick: (e: any, datasource: any) => onClickEditDocumento(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
      title: 'Editar',
    },
    {
      key: 'nrSeqPessoaDocumento_btnExcluir',
      type: ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e: any) => onClickRemoveDocumento(e),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <div className='mb-5 pb-5'>
      <Panel>
        <Panel.Header title='Documentos' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row'>
            <div className='col-3'>
              <Capture picture={picture} onPictureLoad={(e) => setPicture(e)} />
            </div>

            <div className='col-9'>
              <div className='row mb-3'>
                <div className='col-5'>
                  <Textbox
                    label='Nome'
                    text={dadosAssociado.noPessoa}
                    maxLength={200}
                    onChangedValue={(noPessoa) =>
                      setDadosAssociado({
                        ...dadosAssociado,
                        noPessoa,
                      })
                    }
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='CPF'
                    text={documentos?.nrCpf}
                    maxLength={20}
                    onChangedValue={(nrCpf) =>
                      setDocumentos({ ...documentos, nrCpf })
                    }
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='Identidade'
                    text={documentos?.nrRg}
                    maxLength={20}
                    onChangedValue={(nrRg) =>
                      setDocumentos({ ...documentos, nrRg })
                    }
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-3'>
                  <DatePicker
                    label='Data Nascimento'
                    text={documentos?.dtNascimento}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dtNascimento) =>
                      setDocumentos({ ...documentos, dtNascimento })
                    }
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Orgão Emissor'
                    text={documentos?.rgOrgaoEmissor}
                    maxLength={5}
                    onChangedValue={(rgOrgaoEmissor) =>
                      setDocumentos({
                        ...documentos,
                        rgOrgaoEmissor,
                      })
                    }
                  />
                </div>
                <div className='col-3'>
                  <DatePicker
                    label='Data Emissão RG'
                    text={documentos?.dtEmissaOrg}
                    mask={MaskTypes.Date}
                    maxLength={10}
                    onChange={(dtEmissaOrg) =>
                      setDocumentos({ ...documentos, dtEmissaOrg })
                    }
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='UF RG'
                    text={documentos?.ufRg}
                    maxLength={2}
                    onChangedValue={(ufRg) =>
                      setDocumentos({ ...documentos, ufRg })
                    }
                  />
                </div>

                <div className='col-2'>
                  <Switch
                    formControl
                    checked={dadosAssociado?.pessoa?.flgGeraESocial}
                    label='Gera eSocial'
                    onChange={(flgGeraESocial) =>
                      setDadosAssociado({
                        ...dadosAssociado,
                        flgGeraESocial,
                      })
                    }
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-2'>
                  <RadioButton
                    label='Tipo de Pessoa'
                    outline
                    size={BootstrapSizes.Small}
                    buttons={tipoPessoa}
                    theme={Theme.Primary}
                    selectedButton={dadosAssociado?.noTipoPessoa}
                    onChange={(tipo) => {
                      setDadosAssociado({
                        ...dadosAssociado,
                        noTipoPessoa: tipo,
                        flgFisica: tipo.value === 'F',
                      });
                    }}
                  />
                </div>

                <div className='col-3'>
                  <Autocomplete
                    label='Estado Civil'
                    searchDataSource={onSearchEstadoCivil}
                    visible={documentos?.flgFisica}
                    selectedItem={documentos?.estadoCivil}
                    onSelectItem={(estadoCivil) =>
                      setDocumentos({
                        ...documentos,
                        estadoCivil,
                        nrSeqEstadoCivil: estadoCivil.nrSeqEstadoCivil,
                      })
                    }
                    dataSourceTextProperty='noEstadoCivil'
                  />
                </div>
                <div className='col-3'>
                  <Autocomplete
                    label='Profissão'
                    searchDataSource={onSearchProfissao}
                    selectedItem={documentos?.profissao}
                    visible={documentos?.flgFisica}
                    onSelectItem={(profissao) =>
                      setDocumentos({
                        ...documentos,
                        profissao,
                        nrSeqProfissao: profissao.nrSeqProfissao,
                      })
                    }
                    dataSourceTextProperty='noProfissao'
                  />
                </div>

                <div className='col-2'>
                  <DropdownList
                    label='Sexo'
                    dataSource={dropDownListaSexos}
                    dataSourcePropertyText='text'
                    dataSourcePropertyValue='value'
                    selectedItems={documentos?.sexo ?? []}
                    onSelectItem={(item) => {
                      setDocumentos({
                        ...documentos,
                        sexo: item,
                        nrSeqSexo: item.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-5'>
                  <Textbox
                    maxLength={200}
                    label='Pai'
                    text={documentos?.noPai}
                    onChangedValue={(noPai) => {
                      setDocumentos({
                        ...documentos,
                        noPai,
                      });
                    }}
                  />
                </div>
                <div className='col-5'>
                  <Textbox
                    maxLength={200}
                    label='Mãe'
                    text={documentos?.noMae}
                    onChangedValue={(noMae) => {
                      setDocumentos({
                        ...documentos,
                        noMae,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Panel.Body>
      </Panel>

      <div className='row mt-3'>
        <Panel>
          <Panel.Header title='Endereço' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-2'>
                <Textbox
                  label='Cep'
                  text={enderecoAss.cep}
                  maxLength={9}
                  onChangedValue={(cep) => {
                    setEnderecoAss({
                      ...enderecoAss,
                      cep,
                    });
                  }}
                />
              </div>
              <div className='col-1 mt-3'>
                <Button
                  outline
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  icon='search'
                  tooltip='Pesquisar o endereço pelo CEP digitado.'
                  onClick={onSearchCep}
                />
              </div>
              <div className='col-4'>
                <Textbox
                  label='Endereço'
                  text={enderecoAss.noRua}
                  maxLength={80}
                  onChangedValue={(noRua) => {
                    setEnderecoAss({
                      ...enderecoAss,
                      noRua,
                    });
                  }}
                />
              </div>

              <div className='col-2'>
                <Textbox
                  label='Número'
                  text={enderecoAss.numero}
                  maxLength={20}
                  onChangedValue={(numero) =>
                    setEnderecoAss({
                      ...enderecoAss,
                      numero,
                    })
                  }
                />
              </div>

              <div className='col-3'>
                <Textbox
                  label='Complemento'
                  text={enderecoAss.complemento}
                  maxLength={40}
                  onChangedValue={(complemento) =>
                    setEnderecoAss({
                      ...enderecoAss,
                      complemento,
                    })
                  }
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-3'>
                <Textbox
                  label='Bairro'
                  text={enderecoAss.bairro}
                  maxLength={60}
                  onChangedValue={(bairro) => {
                    setEnderecoAss({
                      ...enderecoAss,
                      bairro,
                    });
                  }}
                />
              </div>

              <div className='col-3'>
                <Autocomplete
                  label='País'
                  searchDataSource={onSearchPais}
                  selectedItem={enderecoAss?.pais}
                  onSelectItem={(pais) => {
                    setEnderecoAss({
                      ...enderecoAss,
                      pais,
                    });
                  }}
                  dataSourceTextProperty='noPais'
                />
              </div>

              <div className='col-3'>
                <Autocomplete
                  label='Estado'
                  searchDataSource={onSearchEstado}
                  selectedItem={enderecoAss?.estado}
                  onSelectItem={(estado) => {
                    setEnderecoAss({
                      ...enderecoAss,
                      estado,
                    });
                  }}
                  dataSourceTextProperty='noEstado'
                />
              </div>

              <div className='col-3'>
                <Autocomplete
                  label='Cidade'
                  searchDataSource={onSearchCidade}
                  selectedItem={enderecoAss?.cidade}
                  onSelectItem={(cidade) => {
                    setEnderecoAss({
                      ...enderecoAss,
                      cidade,
                      nrSeqCidade: cidade.nrSeqCidade,
                      noCidade: cidade.noCidade,
                    });
                  }}
                  dataSourceTextProperty='noCidade'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col mt-2 d-flex align-items-center justify-content-end'>
                <Button
                  onClick={onClickAddEndereco}
                  outline
                  theme={Theme.Success}
                  template={Button.Templates.Button}
                  text='Adicionar'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <GridView
                  ref={gridViewEndereco}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsEndereco}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mt-3'>
        <Panel>
          <Panel.Header title='Contatos' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-4'>
                <Textbox
                  label='E-mail'
                  text={contatos.email}
                  onChangedValue={(email) =>
                    setContatos({ ...contatos, email })
                  }
                />
              </div>
              <div className='col-4'>
                <Textbox
                  label='Site'
                  text={contatos.site}
                  onChangedValue={(site) => setContatos({ ...contatos, site })}
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Fone Empresa'
                  text={contatos.foneEmp}
                  onChangedValue={(foneEmp) =>
                    setContatos({ ...contatos, foneEmp })
                  }
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Ramal'
                  text={contatos.ramal}
                  onChangedValue={(ramal) =>
                    setContatos({ ...contatos, ramal })
                  }
                />
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-2'>
                <Textbox
                  label='Fone Contato'
                  text={contatos.fone}
                  onChangedValue={(fone) => setContatos({ ...contatos, fone })}
                />
              </div>

              <div className='col-2'>
                <Textbox
                  label='Celular'
                  text={contatos.celular}
                  onChangedValue={(celular) =>
                    setContatos({ ...contatos, celular })
                  }
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  label='Envio Padrão'
                  searchDataSource={onSearchEnvioPadrao}
                  selectedItem={contatos.envioPadrao}
                  onSelectItem={(envioPadrao) =>
                    setContatos({
                      ...contatos,
                      envioPadrao,
                      flgEnvioPadrao:
                        envioPadrao.nrEnvioPadrao ?? envioPadrao.flgEnvioPadrao,
                    })
                  }
                  dataSourceTextProperty='noEnvioPadrao'
                />
              </div>
            </div>
            <div className='card mt-4'>
              <div className='card-body'>
                <h5 className='card-title'>Histórico de Contatos</h5>

                <div className='row'>
                  <div className='col-10'>
                    <Textbox
                      label='Descrição'
                      text={contatos?.descricao}
                      onChangedValue={(descricao) =>
                        setContatos({ ...contatos, descricao })
                      }
                    />
                  </div>
                  <div className='col-2 mt-4'>
                    <Button
                      outline
                      theme={Theme.Primary}
                      template={Button.Templates.Button}
                      icon='plus'
                      onClick={onAddHistoricoContato}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col'>
                    <GridView
                      ref={gridViewHistoricoContatos}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columnsContato}
                      offlineData
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mt-3'>
        <Panel>
          <Panel.Header title='Informação Bancária' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-2'>
                <RadioButton
                  label='Tipo de Conta'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={informacaoBancaria.tpContaCorrente}
                  buttons={tipoconta}
                  onChange={(tpContaCorrente) => {
                    setInformacaoBancaria({
                      ...informacaoBancaria,
                      tpContaCorrente,
                    });
                  }}
                />
              </div>

              <div className='col-4'>
                <Autocomplete
                  label='Banco'
                  searchDataSource={onSearchBanco}
                  selectedItem={informacaoBancaria.banco}
                  onSelectItem={(banco) =>
                    setInformacaoBancaria({
                      ...informacaoBancaria,
                      banco,
                      nrSeqBanco: banco.nrSeqBanco,
                    })
                  }
                  dataSourceTextProperty='noBanco'
                />
              </div>

              <div className='col-2'>
                <Textbox
                  label='Agência'
                  text={informacaoBancaria.cdAgencia}
                  maxLength={8}
                  onChangedValue={(cdAgencia) => {
                    setInformacaoBancaria({
                      ...informacaoBancaria,
                      cdAgencia,
                    });
                  }}
                />
              </div>

              <div className='col-2'>
                <Textbox
                  label='Conta'
                  text={informacaoBancaria.cdContaCorrente}
                  maxLength={20}
                  onChangedValue={(cdContaCorrente) => {
                    setInformacaoBancaria({
                      ...informacaoBancaria,
                      cdContaCorrente,
                    });
                  }}
                />
              </div>
              <div className='col mt-3'>
                <Button
                  onClick={onClickAddInfoBancaria}
                  outline
                  theme={Theme.Success}
                  template={Button.Templates.Button}
                  text='Adicionar'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <GridView
                  ref={gridViewInfoBancaria}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsInfoBancarias}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mt-3'>
        {/* @ts-expect-error */}
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header
            title='Documentos Associado'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-3'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Número do Documento'
                  text={documentData.nrDocumento}
                  maxLength={20}
                  onChangedValue={(nrDocumento: string) =>
                    setDocumentData({ ...documentData, nrDocumento })
                  }
                />
              </div>
              <div className='col-5'>
                <Autocomplete
                  label='Tipo Documento'
                  searchDataSource={onSearchTipoDocumentoPessoa}
                  selectedItem={documentData.tipoDocumentoPessoa}
                  onSelectItem={(tipoDocumentoPessoa: any) =>
                    setDocumentData({
                      ...documentData,
                      tipoDocumentoPessoa,
                      nrSeqTipoDocumentoPessoa:
                        tipoDocumentoPessoa.nrSeqTipoDocumentoPessoa,
                    })
                  }
                  dataSourceTextProperty='noTipoDocumentoPessoa'
                />
              </div>
              {/* Perido de Validade */}
              {documentData.tipoDocumentoPessoa?.flgControlaValidade && (
                <>
                  <div className='col-2'>
                    {/* @ts-expect-error */}
                    <DatePicker
                      label='Inicio da Vigência'
                      text={documentData.dtIniValidade}
                      onChange={(dtIniValidade: string) =>
                        setDocumentData({ ...documentData, dtIniValidade })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    {/* @ts-expect-error */}
                    <DatePicker
                      label='Fim da Vigência'
                      text={documentData.dtFimValidade}
                      onChange={(dtFimValidade: string) =>
                        setDocumentData({ ...documentData, dtFimValidade })
                      }
                    />
                  </div>
                </>
              )}
            </div>
            {/* Upload de Imagem */}
            {documentData.tipoDocumentoPessoa?.flgAceitaImg && (
              <div className='row mb-3'>
                <div className='col'>
                  <FileUpload
                    files={importedFile}
                    onChange={(files: any) => {
                      setImportedFile(files);
                      setDocumentData({ ...documentData, importedFile: files });
                    }}
                    allowedMimeTypes={[
                      MimeTypes.Types.Image,
                      MimeTypes.Types.PDF,
                      MimeTypes.Types.Word,
                    ]}
                    maxFiles={1}
                    dropzone
                  />
                </div>
              </div>
            )}
            <div className='row mb-3'>
              <div className='col-10'>
                {/* @ts-expect-error */}
                <Textarea
                  label='Observações'
                  text={documentData.obs}
                  maxLength={200}
                  onChangedValue={(obs: string) =>
                    setDocumentData({ ...documentData, obs })
                  }
                />
              </div>
              <div className='col d-flex align-items-end'>
                <Button
                  onClick={onClickAddDocumento}
                  outline
                  theme={Theme.Success}
                  text='Adicionar'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridViewDocumentos}
                  // @ts-expect-error
                  dataColumns={columns}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
            {gridViewDocumentos &&
              gridViewDocumentos.current &&
              gridViewDocumentos.current
                .getDataSource()
                .findIndex(
                  (el: { flgExibeDownload: boolean, status: StatusGrid }) =>
                    el.flgExibeDownload === true &&
                    el.status !== StatusGrid.Remover
                ) !== -1 && (
                <div className='row'>
                  <div className='col d-flex justify-content-end'>
                    <Button
                      onClick={onClickBaixarTodos}
                      outline
                      theme={Theme.Success}
                      text='Baixar Todos'
                    />
                  </div>
                </div>
              )}
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
});

export default DetalhesAssociado;
