import qs from 'qs';
import Pagination from 'core/models/pagination';
import RequisicaoMovEstoque from 'core/models/EST/requisicaoMovEstoque';
import api from '../api';

const baseURL = `/EST/RequisicaoMovEstoque`;

export const getRequisicaoMovEstoqueList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new RequisicaoMovEstoque(json)) ?? [],
  };
};

export const getRequisicaoMovEstoquePagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new RequisicaoMovEstoque(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getRequisicaoMovEstoque = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new RequisicaoMovEstoque(value) : {};
};

export const saveRequisicaoMovEstoque = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteRequisicaoMovEstoque = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printRequisicaoMovEstoque = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelRequisicaoMovEstoque = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllRequisicaoMovEstoque = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const printRequisicaoMovEstoqueDetalhado = async (filters) => {
  const response = await api.get(`${baseURL}/PrintDetalhado`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printDocEntregaEPI = async (id) => {
  const response = await api.get(`${baseURL}/PrintFormularioEPI`, {
    params: { id },
  });
  return response.data ?? {};
};
