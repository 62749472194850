export default class Cfo {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.cdCfo = jsonObject.cdCfo;
    this.nrSeqCfo = jsonObject.nrSeqCfo;
    this.noCfo = jsonObject.noCfo;
    this.flgAtivo = jsonObject.flgAtivo;
    this.flgVenda = jsonObject.flgVenda;
    this.flgCompra = jsonObject.flgCompra;
    this.flgExcecaoICMS = jsonObject.flgExcecaoICMS;
    this.flgExcecaoIPI = jsonObject.flgExcecaoIPI;
    this.flgServico = jsonObject.flgServico;
    this.nrSeqCfoInverso = jsonObject.nrSeqCfoInverso;
    this.flgConsumidorFinal = jsonObject.flgConsumidorFinal;
  }

  get cfoCompleto() {
    if (this.cdCfo) return `${this.cdCfo} ${this.noCfo}`;
    return '';
  }

  toJson = () => JSON.stringify(this);
}
