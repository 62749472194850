import EstoqueMovimento from './estoqueMovimento';

export default class ConsultaMovimentoEstoque extends EstoqueMovimento {
  constructor(jsonObject = {}) {
    super(jsonObject);
    this.nrSeqAlmoxarifado = jsonObject.nrSeqAlmoxarifado;
    this.nrSeqProdutoGrupo = jsonObject.nrSeqProdutoGrupo;
    this.statusProduto = jsonObject.statusProduto;
    this.flgBuscaCodigoPeca = jsonObject.flgBuscaCodigoPeca;
    this.flgAgrupaMovAlmoxarifado = jsonObject.flgAgrupaMovAlmoxarifado;
    this.nrSeqProduto = jsonObject.nrSeqProduto;
    this.saldo = jsonObject.saldo;
    this.placa = jsonObject.placa;
    this.quantidadeInicial = jsonObject.quantidadeInicial;
    this.vlrSaldoTotal = jsonObject.vlrSaldoTotal;
    this.quantidadeEntrada = jsonObject.quantidadeEntrada;
    this.quantidadeSaida = jsonObject.quantidadeSaida;
    this.noPessoaForCli = jsonObject.noPessoaForCli;
    this.nrMovimentos = jsonObject.nrMovimentos;

    //  #region objetos auxiliares
    this.almoxarifado = jsonObject.almoxarifado ?? {};
    this.almoxarifadoLocal = jsonObject.almoxarifadoLocal ?? {};
    this.estoqueTipo = jsonObject.estoqueTipo ?? {};
    this.produto = jsonObject.produto ?? {};
    this.produtoGrupo = jsonObject.produtoGrupo ?? {};
    this.empresa = jsonObject.empresa ?? {};

    //  #endregion
  }

  toJson = () => JSON.stringify(this);
}
