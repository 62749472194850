import Pessoa from 'core/models/SEG/pessoa';
import PessoaDocumento from 'core/models/SEG/pessoaDocumento';
import Empresa from 'core/models/SEG/empresa';
import Estado from 'core/models/SEG/estado';
import PessoaReferencias from 'core/models/SEG/pessoaReferencias';

export default class Funcionario extends Pessoa {
  constructor(jsonObject = {}) {
    super(jsonObject);
    this.cdFuncionario = jsonObject.cdFuncionario;
    this.dtAdmissao = jsonObject.dtAdmissao;
    this.dtDemissao = jsonObject.dtDemissao;
    this.nrSeqPessoaFun = jsonObject.nrSeqPessoaFun;
    this.vlrComissao = jsonObject.vlrComissao;
    this.vlrSalario = jsonObject.vlrSalario;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.noCtps = jsonObject.noCtps;
    this.noSerieCtps = jsonObject.noSerieCtps;
    this.nrSeqEstadoCtps = jsonObject.nrSeqEstadoCtps;
    this.noNis = jsonObject.noNis;
    this.nrTipoJornadaTrabalho = jsonObject.nrTipoJornadaTrabalho;
    this.obs = jsonObject.obs;
    this.noPessoa = jsonObject.noPessoa;
    this.flgMotorista = jsonObject.flgMotorista;
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.estadoCtps = new Estado(jsonObject.estadoCtps ?? {});
    this.flgGeraCustoRecorrente = jsonObject.flgGeraCustoRecorrente;
    this.funcionarioDocumentos =
      jsonObject.funcionarioDocumentos?.map((el) => new PessoaDocumento(el)) ??
      [];
    this.funcionarioReferencias =
      jsonObject.funcionarioReferencias?.map(
        (el) => new PessoaReferencias(el)
      ) ?? [];
  }

  toJson = () => JSON.stringify(this);
}
