import Fornecedor from 'core/models/FIN/fornecedor';

export default class ProdutoCodigo {
  constructor(jsonObject = {}) {
    this.cdProdutoCodigo = jsonObject.cdProdutoCodigo;
    this.nrSeqProduto = jsonObject.nrSeqProduto;
    this.flgPrincipal = jsonObject.flgPrincipal;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqTipoVenda = jsonObject.nrSeqTipoVenda;
    this.cdPeca = jsonObject.cdPeca;
    this.noProdutoCodigo = jsonObject.noProdutoCodigo;
    this.flgSpedFiscalEntrada = jsonObject.flgSpedFiscalEntrada;
    this.nrSeqPessoaFor = jsonObject.nrSeqPessoaFor;
    this.flgVenda = jsonObject.flgVenda;
    this.fornecedor = new Fornecedor(jsonObject.fornecedor ?? new Fornecedor());
  }

  toJson = () => JSON.stringify(this);
}
