import React, { useState, useEffect } from 'react';
import { setTransaction } from 'core/services/api';

import { Button, Modal, Notification, Textbox } from 'ui/components';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import { AlteracaoDia } from 'core/services/FIN/custoFixo';

export default function ModalAlteracaoDeDia(props) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [messageState, setMessage] = useState(null);
  const { show, alteracoesDia, transaction } = props;

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  useEffect(() => {
    if (alteracoesDia !== undefined) {
      setData({ alteracoesDia });
    }
  }, [show]);

  const salvar = async () => {
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);

    const obj = {
      ...data,
      nrSeqTransacao: transaction.nrSeqTransacao,
      nrSeqCustoFixoS: alteracoesDia,
    };

    if (obj.diaVencimento < 1) {
      onSetMessage(
        ResponseStatus.Error,
        'O dia do vencimento não pode ser menor que 1.'
      );
      return;
    }
    if (obj.diaVencimento > 31) {
      onSetMessage(
        ResponseStatus.Error,
        'O dia do vencimento não pode ser maior que 31.'
      );
      return;
    }

    const { status, message: msg } = await AlteracaoDia(obj);

    setData(status === ResponseStatus.Success ? {} : data);
    onSetMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
    if (status === ResponseStatus.Success) {
      props.onClose({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
    if (status === ResponseStatus.Error) {
      onSetMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
  };

  const cancelar = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
    });
  };

  return (
    <Modal
      loading={loading}
      show={show}
      title='Alteração do dia de vencimento'
      size={BootstrapSizes.Large}
      icon='envelope'
    >
      <Modal.Body className='p-4 pt-3'>
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='col-3 mb-3'>
            <Textbox
              label='Dia Vencimento Novo'
              text={data?.diaVencimento}
              mask={MaskTypes.Integer}
              onChangedValue={(diaVencimento) =>
                setData({ ...data, diaVencimento })
              }
            />
          </div>
        </div>

        <div className='row col-7'>
          <div className='col-4'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Alterar Dia'
              onClick={salvar}
            />
          </div>
          <div className='col-3 '>
            <Button
              theme={Theme.Danger}
              template={Button.Templates.Button}
              text='Cancelar'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
