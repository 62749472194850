import React, { useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getAcertoMotoristaAdiantamentoPagined,
  printAcertoMotoristaAdiantamento,
  deleteAllAcertoMotoristaAdiantamento,
  excelAcertoMotoristaAdiantamento,
} from 'core/services/FIN/acertoMotoristaAdiantamento';
import {
  Autocomplete,
  CSDSelPage,
  DatePicker,
  GridView,
  Textbox,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';

const columns = [
  {
    key: 'nrSeqAcertoMotoristaAdiantamento',
    type: GridView.ColumnTypes.Checkbox,
  },
  { key: 'nrSeqAcertoMotoristaAdiantamento', title: 'Código' },
  { key: 'pessoaMot.noPessoa', title: 'Motorista', sortKey: 'noPessoa' },
  { key: 'dtAdiantamento', title: 'Data', format: GridView.DataTypes.Date },
  {
    key: 'vlrAdiantamento',
    title: 'VlrAdiantamento',
    format: GridView.DataTypes.Decimal,
  },
];

export default function AcertoMotoristaAdiantamento({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getAcertoMotoristaAdiantamentoPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'AcertoMotoristaAdiantamento/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqAcertoMotoristaAdiantamento = sourceRow.find(
      (e) => e.key === 'nrSeqAcertoMotoristaAdiantamento'
    );
    onOpenMaintenance(nrSeqAcertoMotoristaAdiantamento.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const value = await printAcertoMotoristaAdiantamento(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onDownloadExcel = async () => {
    setLoading(true);

    const { value: base64 } = await excelAcertoMotoristaAdiantamento(filtros);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'AcertoMotoristaAdiantamento.csv';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const {
        status,
        message: msg,
      } = await deleteAllAcertoMotoristaAdiantamento(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };
  const onSearchPessoaMot = async (e) => {
    const {
      status,
      message: msg,
      motoristas,
    } = await getMotoristaAutoComplete({ noPessoa: e });
    if (msg) setMessage(status, msg);
    return motoristas;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de AcertoMotoristaAdiantamento'
      transaction={transaction}
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      onDownloadExcel={onDownloadExcel}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <div className='row'>
        <div className='col-3 mb-3'>
          <Textbox
            maxLength={20}
            mask={MaskTypes.Integer}
            label='Código'
            text={filtros.nrSeqAcertoMotoristaAdiantamento}
            onChangedValue={(nrSeqAcertoMotoristaAdiantamento) =>
              setFiltros({
                ...filtros,
                nrSeqAcertoMotoristaAdiantamento,
              })
            }
          />
        </div>
        <div className='col-4 mb-3'>
          <Autocomplete
            label='Motorista'
            searchDataSource={onSearchPessoaMot}
            selectedItem={filtros.pessoaMot}
            onSelectItem={(pessoaMot) => {
              setFiltros({
                ...filtros,
                pessoaMot,
                nrSeqPessoaMot: pessoaMot.nrSeqPessoaMot,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-2 mb-3'>
          <DatePicker
            label='De'
            text={filtros.dtAdiantamento}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtAdiantamento) =>
              setFiltros({ ...filtros, dtAdiantamento })
            }
          />
        </div>
        <div className='col-2 mb-3'>
          <DatePicker
            label='Até'
            text={filtros.dtAdiantamentoAte}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtAdiantamentoAte) =>
              setFiltros({ ...filtros, dtAdiantamentoAte })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
