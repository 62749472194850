import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Textbox } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getVencimento,
  saveVencimento,
  deleteVencimento,
} from 'core/services/ASS/vencimento';

export default function VencimentoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getVencimento(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveVencimento(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteVencimento(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Vencimento'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqVencimento > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
      ref={formSubmit}
    >
      <div className='row mb-3'>
        <div className='col-4'>
          <Textbox
            required
            maxLength={20}
            label='Codigo vencimento'
            text={data.cdVencimento}
            onChangedValue={(cdVencimento) =>
              setData({ ...data, cdVencimento })
            }
          />
        </div>
        <div className='col-4 mb-3'>
          <Textbox
            required
            label='Dia do vencimento'
            maxLength={20}
            text={data.diaVencimento}
            mask={MaskTypes.Date}
            onChangedValue={(diaVencimento) =>
              setData({ ...data, diaVencimento })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-4 mb-3'>
          <Textbox
            maxLength={100}
            label='Vencimento'
            text={data.noVencimento}
            onChangedValue={(noVencimento) =>
              setData({ ...data, noVencimento })
            }
          />
        </div>
        <div className='col-4 mb-3'>
          <Textbox
            maxLength={1}
            label='Tipo vencimento'
            text={data.flgTipoVencimento}
            onChangedValue={(flgTipoVencimento) =>
              setData({ ...data, flgTipoVencimento })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-4 mb-3'>
          <Textbox
            maxLength={1}
            label='Tipo dia'
            text={data.flgTipoDia}
            onChangedValue={(flgTipoDia) => setData({ ...data, flgTipoDia })}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
