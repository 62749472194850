import PneuMarca from './pneuMarca';

export default class PneuModelo {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqPneuModelo = jsonObject.nrSeqPneuModelo;
    this.noPneuModelo = jsonObject.noPneuModelo;
    this.cdPneuModelo = jsonObject.cdPneuModelo;
    this.nrSeqPneuMarca = jsonObject.nrSeqPneuMarca;
    this.pneuMarca = new PneuMarca(jsonObject.pneuMarca ?? {});
  }

  toJson = () => JSON.stringify(this);
}
