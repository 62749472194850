import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  Switch,
  DatePicker,
  Panel,
  Button,
  GridView,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getCondicaoPagamento,
  saveCondicaoPagamento,
  deleteCondicaoPagamento,
} from 'core/services/FIN/condicaoPagamento';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import { getTabelaPrecoAutoComplete } from 'core/services/VEN/tabelaPreco';
import { getTipoVendaAutoComplete } from 'core/services/VEN/tipoVenda';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import CondicaoPagamentoFormaPagamen from 'core/models/FIN/condicaoPagamento_FormaPagamen';
import CondicaoPagamentoEmpresa from 'core/models/FIN/condicaoPagamento_Empresa';
import { getEmpresaDefaultUsuario } from 'core/services/SEG/empresa';

export default function CondicaoPagamentoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [formaDePagamento, setFormaDePagamento] = useState(
    new CondicaoPagamentoFormaPagamen({ flgDataFixa: false })
  );
  const [condicaoPagamentoEmpresa, setCondicaoPagamentoEmpresa] = useState(
    new CondicaoPagamentoEmpresa()
  );
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [itemAlterarIndex, setItemAlterarIndex] = useState();

  const gridViewEmpresa = useRef();
  const gridViewFormaPagamento = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onNew = async () => {
    setData({});

    const { empresas } = await getEmpresaDefaultUsuario({});

    setFormaDePagamento(
      new CondicaoPagamentoFormaPagamen({
        flgDataFixa: false,
        percentual: null,
        empresa: empresas[0],
        nrSeqEmpresa: empresas[0].nrSeqEmpresa,
      })
    );

    setCondicaoPagamentoEmpresa(
      new CondicaoPagamentoEmpresa({
        empresa: empresas[0],
        nrSeqEmpresa: empresas[0].nrSeqEmpresa,
      })
    );

    if (gridViewEmpresa && gridViewEmpresa.current)
      gridViewEmpresa.current.setDataSource(null);

    if (gridViewFormaPagamento && gridViewFormaPagamento.current)
      gridViewFormaPagamento.current.setDataSource(null);
    setMessage(null);
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const obj = await getCondicaoPagamento(registryKey);

      setData(obj);

      setFormaDePagamento(obj.formaPagamentos);
      setCondicaoPagamentoEmpresa(obj.empresas);

      if (gridViewFormaPagamento && gridViewFormaPagamento.current)
        gridViewFormaPagamento.current.setDataSource(obj.formaPagamentos);

      if (gridViewEmpresa && gridViewEmpresa.current)
        gridViewEmpresa.current.setDataSource(obj.empresas);

      setLoading(false);
    } else {
      const { empresas } = await getEmpresaDefaultUsuario({});

      setData({});
      setFormaDePagamento(
        new CondicaoPagamentoFormaPagamen({
          flgDataFixa: false,
          percentual: null,
          empresa: empresas[0],
          nrSeqEmpresa: empresas[0].nrSeqEmpresa,
        })
      );

      setCondicaoPagamentoEmpresa(
        new CondicaoPagamentoEmpresa({
          empresa: empresas[0],
          nrSeqEmpresa: empresas[0].nrSeqEmpresa,
        })
      );

      if (gridViewEmpresa && gridViewEmpresa.current)
        gridViewEmpresa.current.setDataSource([]);

      if (gridViewFormaPagamento && gridViewFormaPagamento.current)
        gridViewFormaPagamento.current.setDataSource([]);
      setMessage(null);
    }
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const lista = gridViewEmpresa.current
      ? gridViewEmpresa.current.getDataSource()
      : [];
    const lista2 = gridViewFormaPagamento.current
      ? gridViewFormaPagamento.current.getDataSource()
      : [];

    const obj = {
      ...data,
      formaPagamentos: lista2,
      empresas: lista,
    };

    const { status, message: msg } = await saveCondicaoPagamento(obj);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();

      setData(status === ResponseStatus.Success ? {} : data);
      setFormaDePagamento(
        status === ResponseStatus.Success
          ? {
              flgDataFixa: false,
              percentual: null,
            }
          : lista2
      );

      if (gridViewFormaPagamento && gridViewFormaPagamento.current)
        gridViewFormaPagamento.current.setDataSource([]);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      setLoading(false);
      setCondicaoPagamentoEmpresa(
        status === ResponseStatus.Success ? {} : lista
      );

      if (gridViewEmpresa && gridViewEmpresa.current)
        gridViewEmpresa.current.setDataSource([]);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      setLoading(false);
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteCondicaoPagamento(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setFormaDePagamento(
        status === ResponseStatus.Success ? {} : formaDePagamento
      );
      setCondicaoPagamentoEmpresa(
        status === ResponseStatus.Success ? {} : condicaoPagamentoEmpresa
      );
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      if (gridViewEmpresa && gridViewEmpresa.current)
        gridViewEmpresa.current.setDataSource([]);

      if (gridViewFormaPagamento && gridViewFormaPagamento.current)
        gridViewFormaPagamento.current.setDataSource([]);

      setLoading(false);
    }
  };

  const onSearchTabelaPreco = async (e) => {
    const {
      status,
      message: msg,
      data: tabelaprecos,
    } = await getTabelaPrecoAutoComplete({
      noTabelaPreco: e,
    });

    if (msg) onSetMessage(status, msg);
    return tabelaprecos;
  };

  const onSearchTipoVenda = async (e) => {
    const { status, message: msg, tipos } = await getTipoVendaAutoComplete({
      noTipoVenda: e,
    });
    if (msg) onSetMessage(status, msg);
    return tipos;
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
    });

    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const onAddDescricaoFormaPagamento = async () => {
    const textFormaPagamento = formaDePagamento.descricaoFormaPagamento;
    const listaDataGridFormaPagamento = gridViewFormaPagamento.current.getDataSource();

    if (listaDataGridFormaPagamento && listaDataGridFormaPagamento.length > 0) {
      const itensRemover = listaDataGridFormaPagamento.filter(
        (p) => p.status === GridView.EnumStatus.Remover
      );

      if (itensRemover.length !== listaDataGridFormaPagamento.length) {
        onSetMessage(
          ResponseStatus.Error,
          'Já existem formas de pagamento cadastradas para esta condição. Removendo os itens da grid será possível adicionar a forma de pagamento por meio da descrição. '
        );
        return;
      }
    }

    if (
      textFormaPagamento !== '' &&
      formaDePagamento.status !== GridView.EnumStatus.Alterar
    ) {
      //  trata nrDias, parcelas e percentual;
      const parcelas = textFormaPagamento.split('/');

      // trata erro por adição de caractere fora do esperado
      const regex = /^\d+(\/\d+)*$/;

      if (!regex.test(textFormaPagamento)) {
        onSetMessage(
          ResponseStatus.Error,
          'Não foi possível ler a descrição para forma de pagamento. Confira se o valor esta de acordo com o exemplo.'
        );
        return;
      }

      const qtdParcelas = parcelas.length;
      const percentual = parseFloat(((100 / qtdParcelas) * 100) / 100);

      let listFormaPagamentoParcelas = [];
      listFormaPagamentoParcelas = listaDataGridFormaPagamento.filter(
        (p) =>
          p.status === GridView.EnumStatus.Remover &&
          p.nrSeqCondicaoPagamento !== null
      );

      parcelas.forEach((parcela, index) => {
        const formaPag = new CondicaoPagamentoFormaPagamen();

        formaPag.nrDias = Number(parcela);
        formaPag.nrParcela = index;
        formaPag.percentual = percentual;
        formaPag.nrSeqEmpresa = formaDePagamento.nrSeqEmpresa;
        formaPag.nrSeqFormaPagamento =
          formaDePagamento.formaPagamento.nrSeqFormaPagamento;

        formaPag.taxaAdm = 0;
        formaPag.outrasDespesas = 0;
        formaPag.flgDataFixa = false;
        formaPag.empresa = formaDePagamento.empresa;
        formaPag.formaPagamento = formaDePagamento.formaPagamento;
        formaPag.status = GridView.EnumStatus.Inserir;

        listFormaPagamentoParcelas.push(formaPag);
      });

      if (100 % qtdParcelas !== 0) {
        const diferenca = parseFloat(100 - (qtdParcelas - 1) * percentual);
        listFormaPagamentoParcelas[qtdParcelas - 1].percentual = diferenca;
      }

      if (gridViewFormaPagamento && gridViewFormaPagamento.current)
        gridViewFormaPagamento.current.setDataSource(
          listFormaPagamentoParcelas
        );
    }
  };

  const onAdicionarFormaDePagamento = async () => {
    if (formaDePagamento.nrSeqEmpresa == null) {
      onSetMessage(ResponseStatus.Error, 'Informe a empresa');
      return;
    }

    if (formaDePagamento.nrSeqFormaPagamento == null) {
      onSetMessage(ResponseStatus.Error, 'Informe a Forma de Pagamento');
      return;
    }

    if (formaDePagamento.descricaoFormaPagamento) {
      onAddDescricaoFormaPagamento();
    } else {
      if (
        formaDePagamento.nrDias == null &&
        formaDePagamento.dataFixa == null
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o número de dias');
        return;
      }

      if (formaDePagamento.percentual == null) {
        onSetMessage(ResponseStatus.Error, 'Informe o percentual');
        return;
      }

      if (formaDePagamento.taxaAdm == null) {
        formaDePagamento.taxaAdm = 0;
      }
      if (formaDePagamento.outrasDespesas == null) {
        formaDePagamento.outrasDespesas = 0;
      }
      if (
        formaDePagamento.flgDataFixa === true &&
        formaDePagamento.dataFixa === null
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe um dia Fixo.');
        return;
      }

      if (
        formaDePagamento.flgDataFixa === true &&
        formaDePagamento.dataFixa !== null
      ) {
        formaDePagamento.nrDias = undefined;
      }

      const lista = gridViewFormaPagamento.current.getDataSource();
      const listaValidacao = [];

      if (lista) {
        lista.forEach((elem) => listaValidacao.push(elem));
      }

      if (formaDePagamento.status !== GridView.EnumStatus.Alterar) {
        formaDePagamento.status = GridView.EnumStatus.Inserir;
        listaValidacao.push(formaDePagamento);
      }

      if (formaDePagamento.status === GridView.EnumStatus.Alterar) {
        listaValidacao.splice(itemAlterarIndex, 0, formaDePagamento);
      }

      lista.push(formaDePagamento);

      if (gridViewFormaPagamento && gridViewFormaPagamento.current)
        gridViewFormaPagamento.current.setDataSource(listaValidacao);

      setFormaDePagamento({
        flgDataFixa: false,
        percentual: null,
        nrSeqEmpresa: condicaoPagamentoEmpresa.nrSeqEmpresa,
        empresa: condicaoPagamentoEmpresa.empresa,
      });
    }
  };

  const onAdicionarEmpresa = async () => {
    if (condicaoPagamentoEmpresa.nrSeqEmpresa == null) {
      onSetMessage(ResponseStatus.Error, 'Informe a empresa');
      return;
    }

    const lista = gridViewEmpresa.current.getDataSource();
    const listaValidacao = [];

    if (lista) {
      lista.forEach((elem) => listaValidacao.push(elem));
    }

    if (condicaoPagamentoEmpresa.status !== GridView.EnumStatus.Alterar) {
      condicaoPagamentoEmpresa.status = GridView.EnumStatus.Inserir;
    }

    listaValidacao.push(condicaoPagamentoEmpresa);
    lista.push(condicaoPagamentoEmpresa);

    if (gridViewEmpresa && gridViewEmpresa.current)
      gridViewEmpresa.current.setDataSource(lista);
    setCondicaoPagamentoEmpresa({});
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });

    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onClickEditEmpresa = async (selectedValue, datasource) => {
    const condicaoPagamentos = datasource.filter(
      (Pagamento) => Pagamento !== selectedValue
    );
    const condicaoPagamento = datasource.filter(
      (Pagamento) => Pagamento === selectedValue
    );

    condicaoPagamento[0].status = GridView.EnumStatus.Alterar;
    setCondicaoPagamentoEmpresa(condicaoPagamento[0]);
    if (gridViewEmpresa && gridViewEmpresa.current)
      gridViewEmpresa.current.setDataSource(condicaoPagamentos);
  };

  const onClickRemoveEmpresa = async (selectedValue, datasource) => {
    let condicaoPagamentos = [];

    if (selectedValue.nrSeqEmpresa === undefined) {
      condicaoPagamentos = datasource.filter(
        (Pagamento) => Pagamento !== selectedValue
      );
    } else {
      condicaoPagamentos = datasource.filter(
        (Pagamento) => Pagamento !== selectedValue
      );
      const condicaoPagamento = datasource.filter(
        (Pagamento) => Pagamento === selectedValue
      );
      condicaoPagamento[0].status = GridView.EnumStatus.Remover;
      condicaoPagamentos.push(condicaoPagamento[0]);
    }

    if (gridViewEmpresa && gridViewEmpresa.current)
      gridViewEmpresa.current.setDataSource(condicaoPagamentos);
  };

  const onClickEditFormaPagamento = async (selectedValue, datasource) => {
    const condicaoPagamentos = datasource.filter(
      (Pagamento) => Pagamento !== selectedValue
    );

    const condicaoPagamento = datasource.filter(
      (Pagamento) => Pagamento === selectedValue
    );
    const indexItem = datasource.indexOf(condicaoPagamento[0]);

    setItemAlterarIndex(indexItem);

    condicaoPagamento[0].status = GridView.EnumStatus.Alterar;
    setFormaDePagamento(condicaoPagamento[0]);

    if (gridViewFormaPagamento && gridViewFormaPagamento.current)
      gridViewFormaPagamento.current.setDataSource(condicaoPagamentos);
  };

  const onClickRemoveFormaPagamento = async (selectedValue, datasource) => {
    const parcelas =
      datasource.filter((Pagamento) => Pagamento !== selectedValue) || [];

    if (selectedValue.nrSeqCondicaoPagamento) {
      parcelas.unshift({
        ...selectedValue,
        status: GridView.EnumStatus.Remover,
      });
    }

    if (gridViewFormaPagamento && gridViewFormaPagamento.current)
      gridViewFormaPagamento.current.setDataSource(parcelas);
  };

  const columns = [
    {
      key: 'empresa.noPessoa',
      title: 'Empresa',
      format: GridView.DataTypes.String,
    },
    {
      key: 'nrSeqEmpresa',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditEmpresa(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqEmpresa',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveEmpresa(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columns2 = [
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma Pagamento',
    },
    {
      key: 'nrDias',
      title: 'Nr. de Dias',
      format: GridView.DataTypes.Integer,
    },
    {
      key: 'dataFixa',
      title: 'Data Fixa',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'percentual',
      title: 'Percentual',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'taxaAdm',
      title: 'Taxa de Administração',
      format: GridView.DataTypes.Currency,
    },
    {
      key: 'empresa.noPessoa',
      title: 'Empresa',
      format: GridView.DataTypes.String,
    },
    {
      key: 'nrSeqFormaPagamento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditFormaPagamento(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqFormaPagamento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveFormaPagamento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Condição de Pagamento'
      loading={loading}
      transaction={transaction}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      onDelete={data?.nrSeqCondicaoPagamento > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <Panel>
        <Panel.Header
          icon={['fas', 'user']}
          title='Condição de Pagamento'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col-5 mb-3'>
              <Textbox
                required
                maxLength={200}
                label='Descrição Condição de Pagamento'
                text={data.noCondicaoPagamento}
                onChangedValue={(noCondicaoPagamento) =>
                  setData({ ...data, noCondicaoPagamento })
                }
              />
            </div>

            <div className='col-3 mb-3'>
              <DatePicker
                label='Data de Validade'
                text={data.dtValidade}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtValidade) => setData({ ...data, dtValidade })}
              />
            </div>

            <div className='col-2 mb-3'>
              <Textbox
                readOnly
                label='Código'
                text={data.cdCondicaoPagamento}
                mask={MaskTypes.Integer}
                onChangedValue={(cdCondicaoPagamento) =>
                  setData({ ...data, cdCondicaoPagamento })
                }
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-2 mb-3'>
              <Switch
                formControl
                label='Recebimento'
                checked={data.flgTitRec}
                onChange={(flgTitRec) => setData({ ...data, flgTitRec })}
              />
            </div>
            <div className='col-2 mb-3'>
              <Switch
                formControl
                label='Pagamento'
                checked={data.flgTitPag}
                onChange={(flgTitPag) => setData({ ...data, flgTitPag })}
              />
            </div>
            <div className='col-2 mb-3'>
              <Switch
                formControl
                label='PDV'
                checked={data.flgPdv}
                onChange={(flgPdv) => setData({ ...data, flgPdv })}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-3 mb-3'>
              <Textbox
                label='Dias de Tolerância'
                visible={false}
                text={data.diasTolerancia}
                mask={MaskTypes.Integer}
                onChangedValue={(diasTolerancia) =>
                  setData({ ...data, diasTolerancia })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              <Autocomplete
                label='Tabela Preço'
                visible={false}
                searchDataSource={onSearchTabelaPreco}
                selectedItem={data.tabelaPreco}
                onSelectItem={(tabelaPreco) => {
                  setData({
                    ...data,
                    tabelaPreco,
                    nrSeqTabelaPreco: tabelaPreco.nrSeqTabelaPreco,
                  });
                }}
                dataSourceTextProperty='noTabelaPreco'
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-4 mb-3'>
              <Autocomplete
                label='Tipo de Venda'
                visible={false}
                searchDataSource={onSearchTipoVenda}
                selectedItem={data.tipoVenda}
                onSelectItem={(tipoVenda) => {
                  setData({
                    ...data,
                    tipoVenda,
                    nrSeqTipoVenda: tipoVenda.nrSeqTipoVenda,
                  });
                }}
                dataSourceTextProperty='noTipoVenda'
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                visible={false}
                label='Índice de Correção'
                text={data.indiceCorrecao}
                mask={MaskTypes.Integer}
                onChangedValue={(indiceCorrecao) =>
                  setData({ ...data, indiceCorrecao })
                }
              />
            </div>

            <div className='col-2 mb-3'>
              <Textbox
                visible={false}
                label='Outras Despesas'
                text={data.outrasDespesas}
                mask={MaskTypes.Currency}
                onChangedValue={(outrasDespesas) =>
                  setData({ ...data, outrasDespesas })
                }
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-6 mb-3'>
              <div className='row'>
                <div className='col-4 mb-3'>
                  <Textbox
                    visible={false}
                    label='Valor Máximo'
                    text={data.valorMaximo}
                    mask={MaskTypes.Currency}
                    onChangedValue={(valorMaximo) =>
                      setData({ ...data, valorMaximo })
                    }
                  />
                </div>
                <div className='col-4 mb-3'>
                  <Textbox
                    visible={false}
                    label='Valor Mínimo'
                    text={data.valorMinimo}
                    mask={MaskTypes.Currency}
                    onChangedValue={(valorMinimo) =>
                      setData({ ...data, valorMinimo })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <Panel>
        <Panel.Header
          icon={['fas', 'user']}
          title='Forma de Pagamento Empresa'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col-3 mb-3'>
              <Autocomplete
                label='Empresa'
                searchDataSource={onSearchEmpresa}
                selectedItem={formaDePagamento.empresa}
                onSelectItem={(empresa) => {
                  setFormaDePagamento({
                    ...formaDePagamento,
                    empresa,
                    nrSeqEmpresa: empresa.nrSeqEmpresa,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-3 mb-3'>
              <Autocomplete
                label='Forma de Pagamento'
                searchDataSource={onSearchFormaPagamento}
                selectedItem={formaDePagamento.formaPagamento}
                onSelectItem={(formaPagamento) => {
                  setFormaDePagamento({
                    ...formaDePagamento,
                    formaPagamento,
                    nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                  });
                }}
                dataSourceTextProperty='noFormaPagamento'
              />
            </div>
            <div className='col-4 mb-3'>
              <Textbox
                label='Descrição Parcelas'
                maxLength={500}
                placeholder='Ex: 30/60/90'
                text={formaDePagamento.descricaoFormaPagamento}
                onChangedValue={(descricaoFormaPagamento) => {
                  setFormaDePagamento({
                    ...formaDePagamento,
                    descricaoFormaPagamento,
                  });
                }}
              />
            </div>
          </div>

          {!formaDePagamento.descricaoFormaPagamento && (
            <>
              <div className='row'>
                <div className='col-2 mb-3'>
                  <Textbox
                    label='Percentual'
                    visible={!formaDePagamento.descricaoFormaPagamento}
                    decimalPlaces={2}
                    mask={MaskTypes.DecimalCustom}
                    text={formaDePagamento.percentual}
                    onChangedValue={(percentual) =>
                      setFormaDePagamento({ ...formaDePagamento, percentual })
                    }
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    visible={!formaDePagamento.flgDataFixa}
                    label='Nr. Dias'
                    text={formaDePagamento.nrDias}
                    mask={MaskTypes.Integer}
                    onChangedValue={(nrDias) =>
                      setFormaDePagamento({ ...formaDePagamento, nrDias })
                    }
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Switch
                    formControl
                    label='Data Fixa'
                    checked={formaDePagamento.flgDataFixa}
                    onChange={(flgDataFixa) =>
                      setFormaDePagamento({ ...formaDePagamento, flgDataFixa })
                    }
                  />
                </div>
                {formaDePagamento.flgDataFixa && (
                  <div className='col-3 mb-3'>
                    <DatePicker
                      label='Data Fixa'
                      text={formaDePagamento.dataFixa}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dataFixa) =>
                        setFormaDePagamento({ ...formaDePagamento, dataFixa })
                      }
                    />
                  </div>
                )}
              </div>

              <div className='row'>
                <div className='col-2 mb-3'>
                  <Textbox
                    visible={false}
                    label='Outras Despesas'
                    text={formaDePagamento.outrasDespesas}
                    mask={MaskTypes.Currency}
                    onChangedValue={(outrasDespesas) =>
                      setFormaDePagamento({
                        ...formaDePagamento,
                        outrasDespesas,
                      })
                    }
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    visible={false}
                    label='Taxa Administração'
                    text={formaDePagamento.taxaAdm}
                    mask={MaskTypes.Currency}
                    onChangedValue={(taxaAdm) =>
                      setFormaDePagamento({ ...formaDePagamento, taxaAdm })
                    }
                  />
                </div>
              </div>
            </>
          )}
          <div className='row'>
            <div className='col-2 mt-3'>
              <Button
                theme={Theme.Primary}
                template={Button.Templates.Button}
                text='Adicionar'
                onClick={onAdicionarFormaDePagamento}
              />
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col'>
              <GridView
                ref={gridViewFormaPagamento}
                className='table-striped table-hover table-bordered table-sm'
                showPagination={false}
                dataColumns={columns2}
                showSelectSizes={false}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <Panel>
        <Panel.Header
          icon={['fas', 'user']}
          title='Empresas'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col-6 mb-3'>
              <Autocomplete
                label='Empresa'
                searchDataSource={onSearchEmpresa}
                selectedItem={condicaoPagamentoEmpresa.empresas}
                onSelectItem={(empresa) => {
                  setCondicaoPagamentoEmpresa({
                    ...condicaoPagamentoEmpresa,
                    empresa,
                    nrSeqEmpresa: empresa.nrSeqEmpresa,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-6 mt-3'>
              <Button
                theme={Theme.Primary}
                template={Button.Templates.Button}
                text='Adicionar'
                onClick={onAdicionarEmpresa}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              <GridView
                ref={gridViewEmpresa}
                className='table-striped table-hover table-bordered table-sm'
                showPagination={false}
                dataColumns={columns}
                showSelectSizes={false}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </CSDManutPage>
  );
}
