import Pessoa from 'core/models/SEG/pessoa';
import PessoaDocumento from 'core/models/SEG/pessoaDocumento';
import ColetaConsignatario from 'core/models/FRO/coletaConsignatario';
import ColetaTipoDocumento from 'core/models/FRO/coletaTipoDocumento';
import RemetenteDestinatario from 'core/models/FRO/remetente_Destinatario';
import { Endereco } from 'core/models/SEG';

export default class ColetaDestinatario extends Pessoa {
  constructor(jsonObject = {}) {
    super(jsonObject);
    this.nrSeqPessoaDes = jsonObject.nrSeqPessoaDes;
    this.cdColetaDestinatario = jsonObject.cdColetaDestinatario;
    this.flgAtivo = jsonObject.flgAtivo;
    this.nrSeqPessoaConsig = jsonObject.nrSeqPessoaConsig;
    this.endereco = new Endereco(jsonObject.endereco ?? {});
    this.coletaConsignatario = new ColetaConsignatario(
      jsonObject.coletaConsignatario ?? {}
    );
    this.coletaTipoDocumento = new ColetaTipoDocumento(
      jsonObject.coletaTipoDocumento ?? {}
    );
    this.remetenteDestinatario = jsonObject.remetenteDestinatario
      ? jsonObject.remetenteDestinatario.map(
          (item) => new RemetenteDestinatario(item ?? {})
        )
      : [];
    this.destinatarioDocumentos =
      jsonObject.destinatarioDocumentos?.map((el) => new PessoaDocumento(el)) ??
      [];
    // propriedades auxiliares
    this.noPessoaDes = jsonObject.noPessoaDes;
  }

  toJson = () => JSON.stringify(this);
}
