import AssociadoEmpresaOrigem from './associadoEmpresaOrigem';

export default class Associado {
  constructor(jsonObject = {}) {
    this.cdAssociado = jsonObject.cdAssociado;
    this.dtAssociacao = jsonObject.dtAssociacao;
    this.dtDesligamento = jsonObject.dtDesligamento;
    this.nrMatricula = jsonObject.nrMatricula;
    this.nrRessss = jsonObject.nrRessss;
    this.flgAtivo = jsonObject.flgAtivo;
    this.nrSeqPessoaAss = jsonObject.nrSeqPessoaAss;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqTipoAssociado = jsonObject.nrSeqTipoAssociado;
    this.nrSeqMotivoDesligamento = jsonObject.nrSeqMotivoDesligamento;
    this.flgConsolidador = jsonObject.flgConsolidador;
    this.nrSeqPessoaAssConsolidador = jsonObject.nrSeqPessoaAssConsolidador;
    this.observacao = jsonObject.observacao;
    this.flgGeraEtiqueta = jsonObject.flgGeraEtiqueta;
    this.vlrContrato = jsonObject.vlrContrato;
    this.nrSeqVencimento = jsonObject.nrSeqVencimento;
    this.noPessoa = jsonObject.noPessoa;
    this.noPessoaIniciais = jsonObject.noPessoaIniciais;

    // #region props auxiliares

    this.noEndereco = jsonObject.noEndereco;
    this.nrCpf = jsonObject.nrCpf;
    this.noImagemBase64 = jsonObject.noImagemBase64;
    // #endregion

    // #region objs auxiliares
    this.consolidador = jsonObject.consolidador ?? {};
    this.pessoa = jsonObject.pessoa ?? {};
    this.contato = jsonObject.contato ?? {};
    this.empresasOrigemAss = jsonObject.empresasOrigemAss
      ? jsonObject.empresasOrigemAss.map(
          (item) => new AssociadoEmpresaOrigem(item ?? {})
        )
      : [];

    this.tipoAssociado = jsonObject.tipoAssociado ?? {};

    this.dependentes = jsonObject.dependentes ?? [];
    this.associadosPlanosConvenios = jsonObject.associadosPlanosConvenios ?? [];
    this.matriculasAssociado = jsonObject.matriculasAssociado ?? [];
    this.enderecos = jsonObject.enderecos ?? [];
    this.informacoesBancarias = jsonObject.informacoesBancarias ?? [];
    this.vendedoresAssociado = jsonObject.vendedoresAssociado ?? [];
    this.associadoDocumentos = jsonObject.associadoDocumentos ?? [];

    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
