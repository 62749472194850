import qs from 'qs';
import Pagination from 'core/models/pagination';
import RemetenteDestinatario from 'core/models/FRO/remetente_Destinatario';
import ColetaRemetente from 'core/models/FRO/coletaRemetente';
import ColetaDestinatario from 'core/models/FRO/coletaDestinatario';
import api from '../api';

const baseURL = `/FRO/Remetente_Destinatario`;

export const getRemetenteDestinatarioList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new RemetenteDestinatario(json)) ?? [],
  };
};

export const getRemetenteDestinatarioAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new RemetenteDestinatario(json)) ?? [],
  };
};

export const getRemetenteAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteRemetente`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ColetaRemetente(json)) ?? [],
  };
};

export const getDestinatarioAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteDestinatario`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ColetaDestinatario(json)) ?? [],
  };
};
export const getDestinatarioSemRemetenteAutoComplete = async (filters) => {
  const response = await api.get(
    `${baseURL}/AutoCompleteDestinatarioSemRemetente`,
    {
      params: filters,
    }
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ColetaDestinatario(json)) ?? [],
  };
};

export const getRemetenteDestinatarioPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new RemetenteDestinatario(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getRemetenteDestinatario = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new RemetenteDestinatario(value) : {};
};

export const saveRemetenteDestinatario = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteRemetenteDestinatario = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printRemetenteDestinatario = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelRemetenteDestinatario = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllRemetenteDestinatario = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const buscaCamposViagem = async (filters) => {
  const response = await api.get(`${baseURL}/BuscaCamposViagem`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};
