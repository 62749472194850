import React from 'react';
import { Modal, Button } from 'ui/components';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';

export default function ModalAvisoFerias({ show, data, onClose }) {
  const { viagem } = data ?? {};

  const onSend = (continuar) => {
    onClose(continuar, data);
  };

  return (
    <Modal
      show={show}
      title='Aviso'
      onClose={() => onSend()}
      size={BootstrapSizes.Large}
    >
      <Modal.Body>
        <div className='row'>
          <div className='row mb-2'>
            <span>O Veículo de placa: {viagem?.veiculoPrincipal?.placa}</span>
          </div>
          <div className='row mb-2'>
            <span>
              O Motorista {viagem?.motorista?.noPessoa} encontra-se em férias
            </span>
          </div>
          <div className='row mb-2'>
            <span>Deseja continuar?</span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Small}
          icon='check'
          theme={Theme.Success}
          onClick={() => onSend(true)}
          text='Continuar'
        />
        <Button
          size={BootstrapSizes.Small}
          icon={['fas', 'times']}
          theme={Theme.Danger}
          onClick={() => onSend(false)}
          text='Cancelar'
        />
      </Modal.Footer>
    </Modal>
  );
}
