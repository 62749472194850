import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Button,
  Loader,
  Modal,
  Notification,
  Switch,
  Textbox,
} from 'ui/components';
import { Theme, ResponseStatus, BootstrapSizes } from 'ui/Helpers/utils';
import {
  IncluirLinhasGrupo,
  getGrupoLinhaAutoComplete,
} from 'core/services/TEL';

export default function ModalIncluirLinhasGrupo({
  recursos,
  show,
  onSave,
  onClose,
}) {
  const [data, setData] = useState({ isGrupoNovo: false });
  const [messageState, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData({ isGrupoNovo: false });
  }, [show]);

  const onAutoCompleteGrupoLinha = async (e) => {
    const { status, message, gruposLinhas } = await getGrupoLinhaAutoComplete({
      noDescricao: e,
    });

    if (status !== ResponseStatus.Success)
      setMessage({
        message,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return gruposLinhas;
  };

  const handleSave = async () => {
    setLoading(true);
    const param = {
      ...data,
      nrSeqChips: recursos?.map((p) => p.nrSeqChip),
    };
    const { status, message } = await IncluirLinhasGrupo(param);

    setLoading(false);

    if (status === ResponseStatus.Success) {
      onSave(status, message);
      onClose();
    } else
      setMessage({
        message,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  return (
    <Modal
      show={show}
      title='Incluir Linhas no Grupo'
      onClose={onClose}
      size={BootstrapSizes.Large}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <p className='h6 mb-4'>
          Selecione um grupo para inserção das linhas ou informe o nome do novo
          grupo
        </p>

        <div className='row mb-3'>
          <div className='col-auto'>
            <Switch
              label='Novo Grupo'
              size={BootstrapSizes.Large}
              checked={data.isGrupoNovo}
              formControl
              onChange={(e) => setData({ isGrupoNovo: e })}
            />
          </div>
          <div className='col'>
            <Textbox
              label='Nome do Novo Grupo'
              required
              visible={data.isGrupoNovo}
              text={data.nomeGrupoLinha}
              onChangedValue={(e) => setData({ ...data, nomeGrupoLinha: e })}
            />
            <Autocomplete
              label='Grupo de Linhas'
              required
              visible={!data.isGrupoNovo}
              searchDataSource={onAutoCompleteGrupoLinha}
              selectedItem={data.grupoLinha}
              onSelectItem={(e) => {
                setData({
                  ...data,
                  grupoLinha: e,
                  nrSeqGrupoLinha: e.nrSeqGrupoLinha,
                });
              }}
              dataSourceTextProperty='noDescricao'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            {recursos?.map((recurso) => (
              <div
                key={recurso.nrSeqChip}
                className='badge bg-secondary me-2 p-2 fs-6 mb-2'
              >
                {recurso.numeroCompleto}
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Salvar'
          theme={Theme.Success}
          icon={['far', 'save']}
          onClick={handleSave}
        />
        <Button
          text='Cancelar'
          theme={Theme.Danger}
          icon='ban'
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
