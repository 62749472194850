import Motorista from 'core/models/FRO/motorista';
import Veiculo from 'core/models/FRO/veiculo';

export default class TipoOcorrencia {
  constructor(jsonObject = {}) {
    this.nrSeqTipoOcorrencia = jsonObject.nrSeqTipoOcorrencia;
    this.cdTipoOcorrencia = jsonObject.cdTipoOcorrencia;
    this.noTipoOcorrencia = jsonObject.noTipoOcorrencia;
    this.flgCulpado = jsonObject.flgCulpado;
    this.flgUtilizaSeguro = jsonObject.flgUtilizaSeguro;
    this.flgExigePlaca = jsonObject.flgExigePlaca;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.noPessoa = jsonObject.noPessoa;
    this.motorista = new Motorista(jsonObject.empresa);
    this.veiculo = new Veiculo(jsonObject.veiculo);
  }

  toJson = () => JSON.stringify(this);
}
