import React, { useRef, useEffect } from 'react';
import { GridView } from 'ui/components';
import { ColumnTypes, ColumnDataTypes } from 'ui/Helpers/enums';
import { GridViewCurrent } from 'ui/Helpers/interfaces';

interface Props {
  receita: boolean;
  dataSource: Array<any>;
}

export default function StepReceitasDespesas({ receita, dataSource }: Props) {
  const gridView = useRef<GridViewCurrent<any>>();

  useEffect(() => {
    if (gridView && gridView.current)
      gridView.current.setDataSource(dataSource);
  }, []);

  const columnsDespesa = [
    { key: 'nrSeqTituloPagar', type: ColumnTypes.Checkbox },
    { key: 'cdTituloPagar', title: 'Compromisso' },
    { key: 'nrTit', title: 'Nr. Tit' },
    { key: 'nrOrdem', title: 'Ordem' },
    {
      key: 'fornecedor.noFornecedor',
      title: 'Fornecedor',
    },
    {
      key: 'tipoDespesa.noTipoDespesa',
      title: 'Tipo Despesa',
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma Pagamento',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor',
      format: ColumnDataTypes.Decimal,
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: ColumnDataTypes.Date,
    },
  ];

  const columnsReceita = [
    { key: 'nrSeqTituloReceber', type: ColumnTypes.Checkbox },
    { key: 'nrTit', title: 'NrTit' },
    { key: 'nrOrdem', title: 'NrOrdem' },
    {
      key: 'cliente.noPessoa',
      title: 'Cliente',
    },
    {
      key: 'tituloEspecie.noEspecie',
      title: 'Receita',
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor',
      format: ColumnDataTypes.Money,
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: ColumnDataTypes.Date,
    },

    { key: 'noObs', title: 'NoObs', visible: false },
    {
      key: 'dtRecebimento',
      title: 'Recebimento',
      format: ColumnDataTypes.Date,
    },
  ];

  return (
    <div className='row'>
      <div className='col'>
        <GridView
          ref={gridView}
          // @ts-expect-error
          dataColumns={receita ? columnsReceita : columnsDespesa}
          gridSizeList={[10000]}
          offlineData
          sumFields
          showSelectSizes={false}
          showPagination={false}
        />
      </div>
    </div>
  );
}
