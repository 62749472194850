import qs from 'qs';
import Pagination from 'core/models/pagination';
import TituloPagarCustoFixo from 'core/models/FIN/tituloPagarCustoFixo';
import api from '../api';

const baseURL = `/FIN/TituloPagarCustoFixo`;

export const getTituloPagarCustoFixoList = async (filters) => {
  const response = await api.get(`/$TituloPagarCustoFixo/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloPagarCustoFixo(json)) ?? [],
  };
};

export const gerarLancamento = async (filters) => {
  const response = await api.get(`/FIN/CustoFixo/GerarLancamento`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloPagarCustoFixo(json)) ?? [],
  };
};

/* export const SalvarModalGeraTitulos = async (filters) => {
  const response = await api.get(`/FIN/CustoFixo/SalvarModalGeraTitulos`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloPagarCustoFixo(json)) ?? [],
  };
}; */

export const SalvarModalGeraTitulos = async (data) => {
  const response = await api.post(
    `/FIN/CustoFixo/SalvarModalGeraTitulos`,
    data
  );
  return response.data ?? {};
};

export const getTituloPagarCustoFixoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloPagarCustoFixo(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getTituloPagarCustoFixo = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new TituloPagarCustoFixo(value) : {};
};

export const saveTituloPagarCustoFixo = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteTituloPagarCustoFixo = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printTituloPagarCustoFixo = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelTituloPagarCustoFixo = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllTituloPagarCustoFixo = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
