import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  DatePicker,
  Textbox,
  Panel,
  GridView,
  RadioButton,
  Button,
  Switch,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getRequisicaoMovEstoque,
  printDocEntregaEPI,
  saveRequisicaoMovEstoque,
  deleteRequisicaoMovEstoque,
  printRequisicaoMovEstoqueDetalhado,
} from 'core/services/EST/requisicaoMovEstoque';
import { getEstoqueTipoAutoComplete } from 'core/services/EST/estoqueTipo';
import { getAlmoxarifadoAutoComplete } from 'core/services/EST/almoxarifado';
import { getProdutoAutoComplete } from 'core/services/EST/produto';
import {
  getAlmoxarifadoLocalAutoComplete,
  getBalance,
  getBalanceByProductGeneral,
} from 'core/services/EST/almoxarifadoLocal';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getNfItemByproduto } from 'core/services/COP/nfItem';
import { getFuncionarioAutoComplete } from 'core/services/SEG/funcionario';

export default function RequisicaoMovEstoqueItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  onOpenReport,
}) {
  const formSubmit = useRef();
  const gridViewReqEstoqueItem = useRef();
  const [dataReqMovEstoque, setDataReqMovEstoque] = useState({
    flgSaidaEPIFuncionario: false,
    flgTransferenciaAlmoxarifados: false,
    statusAlmoxarifados: 'T',
  });
  const [reqEstoqueItem, setReqEstoqueItem] = useState({});
  const [statusReq, setStatusReq] = useState(null);
  const [transferenciaEstoques, setTransferenciaEstoques] = useState({
    almoxarifado: {},
    almoxarifadoLocal: {},
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const EnumEstoqueTipoMovimento = Object.freeze({
    Entrada: 'E',
    Saida: 'S',
  });

  const btnStatusAlmoxarifados = [
    { text: 'Todos', value: 'T' },
    { text: 'Ativos', value: 'A' },
    { text: 'Inativos', value: 'I' },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onNew = async () => {
    setLoading(true);
    setDataReqMovEstoque({
      flgSaidaEPIFuncionario: false,
      flgTransferenciaAlmoxarifados: false,
      statusAlmoxarifados: 'T',
    });
    setReqEstoqueItem({});
    setStatusReq(null);
    setTransferenciaEstoques({
      almoxarifado: {},
      almoxarifadoLocal: {},
    });
    if (gridViewReqEstoqueItem && gridViewReqEstoqueItem.current)
      gridViewReqEstoqueItem.current.setDataSource([]);

    setLoading(false);
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const dataReq = await getRequisicaoMovEstoque(registryKey);

      if (
        dataReq.requisicoesMovEstoqueItem &&
        dataReq.requisicoesMovEstoqueItem.length > 0
      ) {
        dataReq.requisicoesMovEstoqueItem.forEach((item) => {
          item.flgStatusVisible = dataReq?.status === null;
        });
        dataReq.statusAlmoxarifados = 'T';
        dataReq.flgTransferenciaAlmoxarifados =
          dataReq.estoqueTipo.noSinal === 'T';
      }

      if (gridViewReqEstoqueItem && gridViewReqEstoqueItem.current)
        gridViewReqEstoqueItem.current.setDataSource(
          dataReq.requisicoesMovEstoqueItem
        );

      setDataReqMovEstoque(dataReq);
      setStatusReq(dataReq.status);
      setReqEstoqueItem({});
      setTransferenciaEstoques({
        almoxarifado: {},
        almoxarifadoLocal: {},
      });
      setMessage(null);
      setLoading(false);
    } else {
      await onNew();
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const itensSalvar = gridViewReqEstoqueItem.current.getDataSource();

    const obj = {
      ...dataReqMovEstoque,
      requisicoesMovEstoqueItem: itensSalvar,
    };

    const { status, message: msg, value } = await saveRequisicaoMovEstoque(obj);

    if (status === ResponseStatus.Success && obj.flgGeraDocumento) {
      const { value: print } = await printDocEntregaEPI(
        value.nrSeqRequisacaoMovEstoque
      );

      if (print) {
        onOpenReport(transaction.noTransacao, print);
      } else {
        onSetMessage(
          ResponseStatus.Error,
          'Erro ao gerar documento de entrega de EPIs'
        );
      }
    }
    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      await onNew();
      setMessage({
        message: msg,
        theme: Theme.Success,
      });
    } else setMessage({ message: msg, theme: Theme.Danger });

    setLoading(false);
  };

  const onPrintDocEPI = async () => {
    setLoading(true);

    const { value } = await printDocEntregaEPI(registryKey);

    if (value) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(
        ResponseStatus.Error,
        'Erro ao gerar documento de entrega de EPIs'
      );
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteRequisicaoMovEstoque(
        registryKey
      );

      if (status === ResponseStatus.Success) {
        await onNew();
        setMessage({
          message: msg,
          theme: Theme.Success,
        });
        setLoading(false);
      } else {
        setMessage({
          message: msg,
          theme: Theme.Danger,
        });
        setLoading(false);
      }
    }
  };

  const onPrint = async () => {
    if (registryKey) {
      setLoading(true);
      const {
        nrSeqAlmoxarifadoLocal,
        almoxarifadoLocal,
        statusEstoqueTipo,
        produto,
        produtoAgrupador,
        requisicoesMovEstoqueItem,
        estoqueTipo,
        ...obj
      } = dataReqMovEstoque;

      const { value } = await printRequisicaoMovEstoqueDetalhado(obj);

      onOpenReport(transaction.noTransacao, value);
      setLoading(false);
    }
  };

  const onSearchEstoqueTipo = async (e) => {
    const {
      status,
      message: msg,
      data: estoqueTipo,
    } = await getEstoqueTipoAutoComplete({
      noEstoqueTipo: e,
      flgLancamentoManual: true,
    });
    if (msg) onSetMessage(status, msg);
    return estoqueTipo;
  };

  const onSearchFuncionario = async (e) => {
    const {
      status,
      message: msg,
      funcionarios,
    } = await getFuncionarioAutoComplete({ noPessoa: e });
    if (msg) onSetMessage(status, msg);
    return funcionarios;
  };

  const onSearchProduto = async (e) => {
    const {
      status,
      message: msg,
      data: produtos,
    } = await getProdutoAutoComplete({
      noSearchField: e,
      flgAtivo: true,
      nrSeqAlmoxarifado: dataReqMovEstoque.nrSeqAlmoxarifado,
    });
    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const getFlgStatusAlmoxarifado = () => {
    let flgstatus = null;
    if (dataReqMovEstoque.statusAlmoxarifados !== 'T') {
      flgstatus = dataReqMovEstoque.statusAlmoxarifados === 'A';
    }
    return flgstatus;
  };

  const onSearchAlmoxarifadoLocal = async (e) => {
    const {
      status,
      message: msg,
      almoxarifados: almoxarifadoLocal,
    } = await getAlmoxarifadoLocalAutoComplete({
      noAlmoxarifado: e,
      nrSeqAlmoxarifado: dataReqMovEstoque.nrSeqAlmoxarifado,
      nrSeqProduto: reqEstoqueItem.nrSeqProduto,
      flgAtivo: getFlgStatusAlmoxarifado(),
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifadoLocal;
  };

  const onSearchInfoCompleteProduto = async (produto) => {
    setLoading(true);

    const infoproduto = await getNfItemByproduto(produto.nrSeqProduto);
    if (infoproduto) {
      const {
        almoxarifados: almoxarifadoLocal,
      } = await getAlmoxarifadoLocalAutoComplete({
        nrSeqAlmoxarifado: dataReqMovEstoque.nrSeqAlmoxarifado,
        nrSeqProduto: produto.nrSeqProduto,
        flgAtivo: getFlgStatusAlmoxarifado(),
      });

      if (almoxarifadoLocal && almoxarifadoLocal.length > 0) {
        const valorTotal =
          reqEstoqueItem.quantidade && reqEstoqueItem.vlrUnit && produto
            ? reqEstoqueItem.quantidade * reqEstoqueItem.vlrUnit
            : null;
        const cdProduto = produto.produtoUnidadeMedida
          ? produto.produtoUnidadeMedida.cdProdutoUnidadeMedida
          : null;

        const saldoalmox = await getBalanceByProductGeneral(
          produto.nrSeqProduto
        );

        setReqEstoqueItem({
          ...reqEstoqueItem,
          nrSeqProduto: produto.nrSeqProduto,
          produto,
          vlrUnit: infoproduto.vlrUnit,
          almoxarifadoLocal:
            almoxarifadoLocal.length === 1
              ? almoxarifadoLocal[0]
              : almoxarifadoLocal,
          cdProdutoUnidadeMedida: cdProduto,
          saldo: saldoalmox,
          nrSeqAlmoxarifadoLocal:
            almoxarifadoLocal.length === 1
              ? almoxarifadoLocal[0].nrSeqAlmoxarifadoLocal
              : null,
          nrSeqAlmoxarifadoLocalPai:
            almoxarifadoLocal.length === 1
              ? almoxarifadoLocal[0].nrSeqAlmoxarifadoLocal
              : null,
          quantidade:
            reqEstoqueItem.quantidade && produto
              ? reqEstoqueItem.quantidade
              : null,
          vrlTotal: valorTotal,
        });
      } else {
        onSetMessage(
          false,
          'Não foram encontrados almoxarifados para os filtros indicados.'
        );
      }
    }
    setLoading(false);
  };

  const onSearchAlmoxarifadoAutoComplete = async (e) => {
    const {
      status,
      message: msg,
      data: almoxarifados,
    } = await getAlmoxarifadoAutoComplete({
      noAlmoxarifado: e,
      nrSeqEmpresa: dataReqMovEstoque?.nrSeqEmpresa || null,
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifados;
  };

  const onSearchEmpresa = async (e) => {
    const {
      status,
      message: msg,
      empresas: empresa,
    } = await getEmpresaAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresa;
  };

  const onSearchAlmoxarifadoLocalDestino = async (e) => {
    const {
      almoxarifados: almoxarifadoLocalDestino,
    } = await getAlmoxarifadoLocalAutoComplete({
      noAlmoxarifado: e,
      nrSeqAlmoxarifado: transferenciaEstoques.almoxarifado?.nrSeqAlmoxarifado,
      nrSeqProduto: reqEstoqueItem?.nrSeqProduto,
      flgAtivo: getFlgStatusAlmoxarifado(),
    });
    if (almoxarifadoLocalDestino.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Não há locais cadastrados para este produto neste almoxarifado.'
      );
      return [];
    }
    const almoxarifadosDestino = almoxarifadoLocalDestino.filter(
      (almox) =>
        almox.nrSeqAlmoxarifadoLocal !== reqEstoqueItem.nrSeqAlmoxarifadoLocal
    );

    if (almoxarifadosDestino?.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Não há outros locais cadastrados para este produto neste almoxarifado.'
      );
      return [];
    }
    return almoxarifadosDestino;
  };

  const onSearchAlmoxarifadoAutoCompleteDestino = async (e) => {
    const {
      status,
      message: msg,
      data: almoxarifados,
    } = await getAlmoxarifadoAutoComplete({
      noAlmoxarifado: e,
      nrSeqEmpresa: dataReqMovEstoque?.nrSeqEmpresa,
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifados;
  };

  async function onSearchInfoAlmoxarifadoLocal(almoxarifadoLocal) {
    const saldoalmox = await getBalance(
      almoxarifadoLocal.nrSeqAlmoxarifadoLocal
    );

    setReqEstoqueItem({
      ...reqEstoqueItem,
      almoxarifadoLocal,
      nrSeqAlmoxarifadoLocalPai: almoxarifadoLocal.nrSeqAlmoxarifadoLocalPai,
      nrSeqAlmoxarifadoLocal: almoxarifadoLocal.nrSeqAlmoxarifadoLocal,
      saldo: saldoalmox || 0,
    });
  }

  function calculaValorTotal(qtd) {
    const vtotal = parseFloat(qtd) * reqEstoqueItem.vlrUnit;
    setReqEstoqueItem({
      ...reqEstoqueItem,
      quantidade: qtd,
      vrlTotal: parseFloat(vtotal),
    });
  }

  const onAddReqItem = async () => {
    const dataProdutosReqItem =
      gridViewReqEstoqueItem.current.getDataSource() || [];

    if (!statusReq === null) {
      onSetMessage(ResponseStatus.Error, statusReq);
      return;
    }

    if (!reqEstoqueItem.nrSeqAlmoxarifadoLocal) {
      onSetMessage(ResponseStatus.Error, 'Necessário informar o almoxarifado.');
      return;
    }
    if (!reqEstoqueItem.nrSeqProduto) {
      onSetMessage(ResponseStatus.Error, 'Necessário informar o produto.');
      return;
    }
    if (!reqEstoqueItem.quantidade) {
      onSetMessage(ResponseStatus.Error, 'Necessário informar a quantidade.');
      return;
    }
    if (!reqEstoqueItem.vlrUnit) {
      onSetMessage(ResponseStatus.Error, 'Necessário informar o valor.');
      return;
    }

    if (!dataReqMovEstoque.estoqueTipo) {
      onSetMessage(
        ResponseStatus.Error,
        'Selecione uma opção de Estoque Tipo para poder adicionar itens.'
      );
      return;
    }

    if (
      reqEstoqueItem.quantidade > reqEstoqueItem.saldo &&
      (dataReqMovEstoque.estoqueTipo.noSinal === '-' ||
        dataReqMovEstoque.estoqueTipo.noSinal === 'T')
    ) {
      const itensRepetidos = dataProdutosReqItem.filter(
        (item) =>
          item.nrSeqProduto === reqEstoqueItem.nrSeqProduto &&
          item.nrSeqAlmoxarifadoLocal === reqEstoqueItem.nrSeqAlmoxarifadoLocal
      );

      let qtdTotal = 0;
      if (itensRepetidos.length > 0) {
        itensRepetidos.forEach((item) => {
          qtdTotal += item.quantidade;
        });
        if (qtdTotal + reqEstoqueItem.quantidade > reqEstoqueItem.saldo) {
          onSetMessage(
            ResponseStatus.Error,
            'A soma da quantidade solicitada de itens repetidos é maior que o saldo em estoque do produto.'
          );
          return;
        }
      }

      if (!reqEstoqueItem.nrSeqRequisicaoMovEstoqueItem) {
        onSetMessage(
          ResponseStatus.Error,
          'Quantidade maior que o saldo em estoque do produto.'
        );
        return;
      }

      const item = dataReqMovEstoque.requisicoesMovEstoqueItem.filter(
        (r) =>
          r.nrSeqRequisicaoMovEstoqueItem ===
          reqEstoqueItem.nrSeqRequisicaoMovEstoqueItem
      );

      if (
        reqEstoqueItem.quantidade > item[0].quantidade + reqEstoqueItem.saldo &&
        (dataReqMovEstoque.estoqueTipo.noSinal === '-' ||
          dataReqMovEstoque.estoqueTipo.noSinal === 'T')
      ) {
        onSetMessage(
          ResponseStatus.Error,
          'Quantidade maior que o saldo em estoque do produto.'
        );
        return;
      }
    }

    if (reqEstoqueItem.status !== GridView.EnumStatus.Alterar) {
      reqEstoqueItem.status = GridView.EnumStatus.Inserir;
    }

    const novoItem = {
      quantidade: reqEstoqueItem.quantidade,
      vlrUnit: reqEstoqueItem.vlrUnit,
      vrlTotal: reqEstoqueItem.vrlTotal,
      nrSeqAlmoxarifadoLocal:
        reqEstoqueItem.almoxarifadoLocal.nrSeqAlmoxarifadoLocal,
      nrSeqProduto: reqEstoqueItem.nrSeqProduto,
      cdProdutoUnidadeMedida: reqEstoqueItem.cdProdutoUnidadeMedida,
      saldo: reqEstoqueItem.saldo,
      produto: reqEstoqueItem.produto,
      almoxarifadoLocal: reqEstoqueItem.almoxarifadoLocal,
      status: reqEstoqueItem.status,
      nrSeqRequisicaoMovEstoqueItem: reqEstoqueItem.nrSeqRequisicaoMovEstoqueItem
        ? reqEstoqueItem.nrSeqRequisicaoMovEstoqueItem
        : 0,
      nrSeqRequisacaoMovEstoque: reqEstoqueItem.nrSeqRequisacaoMovEstoque
        ? reqEstoqueItem.nrSeqRequisacaoMovEstoque
        : null,
      nrSeqEstoqueMovimento: reqEstoqueItem.nrSeqEstoqueMovimento
        ? reqEstoqueItem.nrSeqEstoqueMovimento
        : null,
    };

    if (
      transferenciaEstoques.almoxarifado.nrSeqAlmoxarifado &&
      transferenciaEstoques.almoxarifadoLocal.nrSeqAlmoxarifadoLocal
    ) {
      novoItem.statusEstoqueTipo = EnumEstoqueTipoMovimento.Saida;

      dataProdutosReqItem.push(novoItem);

      const {
        nrSeqAlmoxarifadoLocal,
        almoxarifadoLocal,
        statusEstoqueTipo,
        ...itemDestino
      } = novoItem;

      itemDestino.almoxarifadoLocal = {
        ...transferenciaEstoques.almoxarifadoLocal,
        almoxarifado: transferenciaEstoques.almoxarifado,
      };
      itemDestino.statusEstoqueTipo = EnumEstoqueTipoMovimento.Entrada;
      itemDestino.nrSeqAlmoxarifadoLocal =
        transferenciaEstoques.almoxarifadoLocal.nrSeqAlmoxarifadoLocal;

      dataProdutosReqItem.push(itemDestino);
    } else {
      dataProdutosReqItem.push(novoItem);
    }

    if (gridViewReqEstoqueItem && gridViewReqEstoqueItem.current)
      gridViewReqEstoqueItem.current.setDataSource(dataProdutosReqItem);

    setReqEstoqueItem({
      quantidade: 0,
      vlrUnit: 0,
      vrlTotal: 0,
      nrSeqAlmoxarifadoLocal: 0,
      nrSeqProduto: 0,
      produto: {},
      almoxarifadoLocal: {},
    });
    setTransferenciaEstoques({
      visible: false,
      almoxarifado: {},
      almoxarifadoLocal: {},
    });
  };

  const onClickEditReqItem = async (selectedValue, datasource) => {
    if (!statusReq === null) {
      onSetMessage(ResponseStatus.Error, statusReq);
      return;
    }

    const dataReqItens = datasource.filter((item) => item !== selectedValue);

    const dataItem = selectedValue;
    dataItem.status = GridView.EnumStatus.Alterar;

    if (!dataItem.saldo) {
      dataItem.saldo = await getBalance(dataItem.nrSeqAlmoxarifadoLocal);
    }

    setReqEstoqueItem({
      ...dataItem,
    });

    if (gridViewReqEstoqueItem && gridViewReqEstoqueItem.current)
      gridViewReqEstoqueItem.current.setDataSource(dataReqItens);
  };

  const onClickRemoveReqItem = async (selectedValue, datasource) => {
    if (!statusReq === null) {
      onSetMessage(ResponseStatus.Error, statusReq);
      return;
    }
    const dataReqItens = datasource.filter((item) => item !== selectedValue);

    const obj = { ...selectedValue, status: GridView.EnumStatus.Remover };

    if (
      obj.nrSeqRequisicaoMovEstoqueItem &&
      obj.nrSeqRequisicaoMovEstoqueItem > 0
    ) {
      dataReqItens.unshift(obj);
    }

    if (gridViewReqEstoqueItem && gridViewReqEstoqueItem.current)
      gridViewReqEstoqueItem.current.setDataSource(dataReqItens);
  };

  const columns = [
    {
      key: 'nrSeqRequisicaoMovEstoqueItem',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'produto.noProduto',
      title: 'Produto',
      sortKey: 'noProduto',
      sortable: false,
    },
    {
      key: 'quantidade',
      title: 'Quantidade',
      sortable: false,
    },
    {
      key: 'vlrUnit',
      title: 'Valor Unitário',
      sortable: false,
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vrlTotal',
      title: 'Valor Total',
      sortable: false,
      format: GridView.DataTypes.Money,
    },
    {
      key: 'nrSeqRequisicaoMovEstoqueItem',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgStatusVisible',
      onClick: (e, datasource) => onClickEditReqItem(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqRequisicaoMovEstoqueItem',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgStatusVisible',
      onClick: (e, datasource) => onClickRemoveReqItem(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Movimentação Estoque'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={!dataReqMovEstoque.status ? save : null}
      onDelete={
        dataReqMovEstoque?.nrSeqRequisacaoMovEstoque > 0 &&
        !dataReqMovEstoque.status
          ? onExcluir
          : null
      }
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {dataReqMovEstoque.nrSeqRequisacaoMovEstoque > 0 &&
        dataReqMovEstoque.flgSaidaEPIFuncionario && (
          <ToolbarButtons>
            <ToolbarButtons.Button
              text='Gerar Documento EPI'
              icon='file-alt'
              onClick={onPrintDocEPI}
            />
          </ToolbarButtons>
        )}
      {dataReqMovEstoque.status && (
        <div className='card border-danger mb-3'>
          <div className='card-body'>
            <span className='card-text'>{dataReqMovEstoque.status}</span>
          </div>
        </div>
      )}

      <div className='row mb-3'>
        <div className='col'>
          <Panel>
            <Panel.Header
              icon={['fas', 'user']}
              title='Manutenção Movimentação Estoque'
              theme={Theme.Primary}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col-4 mb-3'>
                  <Textbox
                    maxLength={80}
                    required
                    label='Documento'
                    readOnly={dataReqMovEstoque?.status}
                    text={dataReqMovEstoque.noDocumento}
                    onChangedValue={(noDocumento) =>
                      setDataReqMovEstoque({
                        ...dataReqMovEstoque,
                        noDocumento,
                      })
                    }
                  />
                </div>

                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Estoque Tipo'
                    readOnly={dataReqMovEstoque?.status}
                    searchDataSource={onSearchEstoqueTipo}
                    selectedItem={dataReqMovEstoque.estoqueTipo}
                    onSelectItem={(estoqueTipo) => {
                      setDataReqMovEstoque({
                        ...dataReqMovEstoque,
                        estoqueTipo,
                        nrSeqEstoqueTipo: estoqueTipo.nrSeqEstoqueTipo,
                      });
                    }}
                    dataSourceTextProperty='noEstoqueTipo'
                  />
                </div>

                <div className='col-3 mb-3'>
                  <DatePicker
                    label='Data Movimento'
                    required
                    readOnly={dataReqMovEstoque.status != null}
                    text={dataReqMovEstoque.dtMovimento}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dtMovimento) =>
                      setDataReqMovEstoque({
                        ...dataReqMovEstoque,
                        dtMovimento,
                      })
                    }
                  />
                </div>

                <div className='col-1 mb-3'>
                  <Textbox
                    maxLength={80}
                    readOnly
                    label='Requisição'
                    text={dataReqMovEstoque.cdRequisacaoMovEstoque}
                    onChangedValue={(cdRequisacaoMovEstoque) =>
                      setDataReqMovEstoque({
                        ...dataReqMovEstoque,
                        cdRequisacaoMovEstoque,
                      })
                    }
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Almoxarifado'
                    readOnly={dataReqMovEstoque?.status}
                    searchDataSource={onSearchAlmoxarifadoAutoComplete}
                    selectedItem={dataReqMovEstoque.almoxarifado}
                    onSelectItem={(almoxarifado) => {
                      setDataReqMovEstoque({
                        ...dataReqMovEstoque,
                        almoxarifado,
                        nrSeqAlmoxarifado: almoxarifado.nrSeqAlmoxarifado,
                      });
                    }}
                    dataSourceTextProperty='noAlmoxarifado'
                  />
                </div>

                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Empresa'
                    readOnly={dataReqMovEstoque?.status}
                    searchDataSource={onSearchEmpresa}
                    selectedItem={dataReqMovEstoque.empresa}
                    onSelectItem={(empresa) => {
                      setDataReqMovEstoque({
                        ...dataReqMovEstoque,
                        empresa,
                        nrSeqEmpresa: empresa.nrSeqEmpresa,
                      });
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>

                <div className='col-4 mb-3'>
                  <Textbox
                    maxLength={80}
                    label='Observações'
                    readOnly={dataReqMovEstoque?.status}
                    text={dataReqMovEstoque.observacao}
                    onChangedValue={(observacao) =>
                      setDataReqMovEstoque({ ...dataReqMovEstoque, observacao })
                    }
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-4 mb-3'>
                  <RadioButton
                    outline
                    label='Status Almoxarifado'
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    buttons={btnStatusAlmoxarifados}
                    selectedButton={dataReqMovEstoque.statusAlmoxarifados}
                    onChange={(statusAlmox) =>
                      setDataReqMovEstoque({
                        ...dataReqMovEstoque,
                        statusAlmoxarifados: statusAlmox,
                      })
                    }
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Switch
                    formControl
                    label='Transf. Almoxarifado'
                    size={BootstrapSizes.Medium}
                    checked={dataReqMovEstoque.flgTransferenciaAlmoxarifados}
                    onChange={(flgTransferenciaAlmoxarifados) =>
                      setDataReqMovEstoque({
                        ...dataReqMovEstoque,
                        flgTransferenciaAlmoxarifados,
                      })
                    }
                  />
                </div>
                {dataReqMovEstoque.estoqueTipo?.noSinal === '-' && (
                  <div className='col-2 mb-3'>
                    <Switch
                      formControl
                      label='Saída EPI'
                      size={BootstrapSizes.Medium}
                      checked={dataReqMovEstoque.flgSaidaEPIFuncionario}
                      onChange={(flgSaidaEPIFuncionario) =>
                        setDataReqMovEstoque({
                          ...dataReqMovEstoque,
                          flgSaidaEPIFuncionario,
                        })
                      }
                    />
                  </div>
                )}

                {dataReqMovEstoque.flgSaidaEPIFuncionario && (
                  <div className='col-4 mb-3'>
                    <Autocomplete
                      label='Funcionário'
                      searchDataSource={onSearchFuncionario}
                      selectedItem={dataReqMovEstoque.funcionario}
                      onSelectItem={(funcionario) => {
                        setDataReqMovEstoque({
                          ...dataReqMovEstoque,
                          funcionario,
                          nrSeqPessoaFun: funcionario.nrSeqPessoaFun,
                        });
                      }}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                )}
                {dataReqMovEstoque.flgSaidaEPIFuncionario &&
                  !dataReqMovEstoque.nrSeqRequisacaoMovEstoque && (
                    <div className='col-3 mb-3'>
                      <Switch
                        formControl
                        label='Gerar Documento Retirada EPI'
                        size={BootstrapSizes.Medium}
                        checked={dataReqMovEstoque.flgGeraDocumento}
                        onChange={(flgGeraDocumento) =>
                          setDataReqMovEstoque({
                            ...dataReqMovEstoque,
                            flgGeraDocumento,
                          })
                        }
                      />
                    </div>
                  )}
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>

      {!dataReqMovEstoque.status && (
        <div className='row mb-3'>
          <div className='col'>
            <Panel>
              <Panel.Header
                icon={['fas', 'user']}
                title='Adicionar Produtos'
                theme={Theme.Primary}
              />
              <Panel.Body>
                <div className='row'>
                  <div className='col-4 mb-3'>
                    <Autocomplete
                      label='Produto'
                      searchDataSource={onSearchProduto}
                      selectedItem={reqEstoqueItem.produto}
                      onSelectItem={(produto) => {
                        onSearchInfoCompleteProduto(produto);
                      }}
                      dataSourceTextProperty='noProduto'
                    />
                  </div>

                  <div className='col-4 mb-3'>
                    <Autocomplete
                      label='Local'
                      searchDataSource={onSearchAlmoxarifadoLocal}
                      selectedItem={reqEstoqueItem.almoxarifadoLocal}
                      onSelectItem={(almoxarifadoLocal) => {
                        onSearchInfoAlmoxarifadoLocal(almoxarifadoLocal);
                      }}
                      dataSourceTextProperty='noAlmoxarifadoLocal'
                    />
                  </div>

                  <div className='col-2 mb-3'>
                    <Textbox
                      maxLength={80}
                      readOnly
                      label='Unidade Medida'
                      text={reqEstoqueItem.cdProdutoUnidadeMedida}
                      onChangedValue={(cdProdutoUnidadeMedida) =>
                        setReqEstoqueItem({
                          ...reqEstoqueItem,
                          cdProdutoUnidadeMedida,
                        })
                      }
                    />
                  </div>
                  <div className='col-2 mb-3'>
                    <Textbox
                      maxLength={10}
                      readOnly
                      label='Saldo'
                      text={reqEstoqueItem.saldo}
                      onChangedValue={(saldo) =>
                        setReqEstoqueItem({ ...reqEstoqueItem, saldo })
                      }
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-2 mb-3'>
                    <Textbox
                      maxLength={80}
                      label='Valor unitário'
                      mask={MaskTypes.Currency}
                      text={reqEstoqueItem.vlrUnit}
                      onChangedValue={(vlrUnit) => {
                        const valorCal = reqEstoqueItem.quantidade
                          ? vlrUnit * reqEstoqueItem.quantidade
                          : null;
                        setReqEstoqueItem({
                          ...reqEstoqueItem,
                          vlrUnit,
                          vrlTotal: valorCal,
                        });
                      }}
                    />
                  </div>

                  <div className='col-2 mb-3'>
                    <Textbox
                      maxLength={10}
                      label='Quantidade'
                      mask={MaskTypes.DecimalCustom}
                      text={reqEstoqueItem.quantidade}
                      onChangedValue={(quantidade) => {
                        setReqEstoqueItem({ ...reqEstoqueItem, quantidade });
                        calculaValorTotal(quantidade);
                      }}
                    />
                  </div>

                  <div className='col-2 mb-3'>
                    <Textbox
                      maxLength={10}
                      label='Valor Total'
                      mask={MaskTypes.Currency}
                      text={reqEstoqueItem.vrlTotal}
                      onChangedValue={(vrlTotal) => {
                        setReqEstoqueItem({ ...reqEstoqueItem, vrlTotal });
                      }}
                    />
                  </div>
                </div>

                {dataReqMovEstoque?.flgTransferenciaAlmoxarifados && (
                  <div className='row'>
                    <div className='col-4 mb-3'>
                      <Autocomplete
                        label='Almoxarifado Destino'
                        searchDataSource={
                          onSearchAlmoxarifadoAutoCompleteDestino
                        }
                        selectedItem={transferenciaEstoques.almoxarifado}
                        onSelectItem={(almoxarifado) => {
                          setTransferenciaEstoques({
                            ...transferenciaEstoques,
                            almoxarifado,
                            nrSeqAlmoxarifado: almoxarifado.nrSeqAlmoxarifado,
                          });
                        }}
                        dataSourceTextProperty='noAlmoxarifado'
                      />
                    </div>
                    <div className='col-4 mb-3'>
                      <Autocomplete
                        label='Local Destino'
                        searchDataSource={onSearchAlmoxarifadoLocalDestino}
                        selectedItem={transferenciaEstoques.almoxarifadoLocal}
                        onSelectItem={(almoxarifadoLocal) => {
                          setTransferenciaEstoques({
                            ...transferenciaEstoques,
                            almoxarifadoLocal,
                            nrSeqAlmoxarifadoLocalPai:
                              almoxarifadoLocal.nrSeqAlmoxarifadoLocalPai,
                          });
                        }}
                        dataSourceTextProperty='noAlmoxarifadoLocal'
                      />
                    </div>
                  </div>
                )}

                <div className='row'>
                  <div className='col-2 mt-3'>
                    <Button
                      outline
                      theme={Theme.Success}
                      template={Button.Templates.Button}
                      text='Adicionar'
                      onClick={onAddReqItem}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      )}

      <div className='row'>
        <div className='col-12'>
          <GridView
            ref={gridViewReqEstoqueItem}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
