import qs from 'qs';
import PrevisaoFinanceira from 'core/models/FIN/previsaoFinanceira';
import api from '../api';

const baseURL = `/FIN/PrevisaoFinanceira`;

export const getCurrentBalance = async (filters) => {
  const response = await api.get(`${baseURL}/SearchCurrentBalance`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PrevisaoFinanceira(json)) ?? [],
  };
};

export const getFinancialForecast = async (filters) => {
  const response = await api.get(`${baseURL}/SearchFinancialForecast`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value } = response.data ?? {};

  const valoresPrevistos = value?.valoresPrevistos ?? [];
  const resumo = value?.resumo ?? {};

  return {
    status,
    message,
    data: valoresPrevistos?.map((json) => new PrevisaoFinanceira(json)) ?? [],
    resumo,
  };
};

export const getPrevisaoFinanceira = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value;
};

export const print = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
