import React, { useState, useRef, useEffect } from 'react';

import { Steps, CSDManutPage, ToolbarButtons } from 'ui/components';
import { PageTypes, Theme, ResponseStatus } from 'ui/Helpers/utils';

import {
  savePocPoco,
  printPocPoco,
  getPocPoco,
} from 'core/services/POC/pocPoco';
import { uploadDocumento } from 'core/services/POC/pocPocoDocumentoAnaliseAgua';

import { PocPoco } from 'core/models/POC';

import CadastroCliente from './cadastroCliente';
import CadastroPoco from './cadastroPoco';
import CadastroPerfuracao from './cadastroPerfuracao';

import CadastroPerfilLitologico from './cadastroPerfilLitologico';
import CadastroRevestimentoFiltro from './cadastroRevestimentoFiltro';
import CadastroCimentacaoPrefiltro from './cadastroCimentacaoPrefiltro';
import CadastroDesenvolvimento from './cadastroDesenvolvimento';
import CadastroDocumentoAnaliseAgua from './cadastroDocumentoAnaliseAgua';
import CadastroDadoHidraulico from './cadastroDadoHidraulico';
import CadastroEquipInstalado from './cadastroEquipInstalado';
import ModalImprimir from './modalImprimir';
import CadastroDocumentosPoco from './cadastroDocumentosPoco';
import DesenhoControlePoco from './desenhoControlePoco';

export default function ControlePocoFiltro({
  isActive,
  onOpenTransaction,
  findTransaction,
  registryKey,
  onSelectPage,
  transaction,
}) {
  const [dataPoco, setDataPoco] = useState(
    new PocPoco({
      flgClassificacaoPoco: 'PR',
      flgTipoPoco: 'P',
      flgPocoProprio: true,
    })
  );

  const cadastroClienteRef = useRef({});
  const cadastroPocoRef = useRef({});
  const cadastroPerfuracaoRef = useRef({});
  const cadastroRevestimentoFiltroRef = useRef({});
  const cadastroPerfilLitologicoRef = useRef({});
  const cadastroCimentacaoPrefiltroRef = useRef({});
  const cadastroDesenvolvimentoRef = useRef({});
  const cadastroDocumentoAnaliseAguaRef = useRef({});
  const cadastroDadoHidraulicoRef = useRef({});
  const cadastroEquipInstaladaRef = useRef({});
  const cadastroDocumentosAuxiliaresPocoRef = useRef({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stepVisible, setStepVisible] = useState(false);
  const [showImprimir, setShowImprimir] = useState(false);
  const stepPoco = useRef();

  const reset = true;

  function getData() {
    return dataPoco;
  }

  function inicioFimInvalido(nrInicio, nrFim) {
    const invalido = true;

    if (
      nrInicio === undefined ||
      nrFim === undefined ||
      nrInicio === '' ||
      nrFim === ''
    ) {
      setMessage({
        message: 'Informe o inicio e o fim.',
        theme: Theme.Danger,
      });
      return invalido;
    }

    if (nrInicio > nrFim) {
      setMessage({
        message: 'O inicio não pode ser maior que o fim.',
        theme: Theme.Danger,
      });
      return invalido;
    }

    return false;
  }

  function validaCampoObrigatorioPerfuracao(e) {
    if (!e.nrDiametropol) {
      setMessage({
        message: 'Informe o diâmetro da perfuração.',
        theme: Theme.Danger,
      });
      return false;
    }

    if (!e.nrSeqPocMetodoPerfuracao) {
      setMessage({
        message: 'Informe o método de perfuração.',
        theme: Theme.Danger,
      });
      return false;
    }

    return true;
  }

  function validaCampoObrigatorioCamada(e) {
    if (!e.cdPocPerflitCamada) {
      setMessage({
        message: 'Informe a Camada.',
        theme: Theme.Danger,
      });
      return false;
    }

    return true;
  }

  function validaCampoObrigatorioBomba(e) {
    if (!e.dtInstalacao) {
      setMessage({
        message: 'Informe a Data de Instalação.',
        theme: Theme.Danger,
      });
      return false;
    }

    if (e.noTipoBomba === undefined || e.noTipoBomba === '') {
      setMessage({
        message: 'Informe o tipo da bomba.',
        theme: Theme.Danger,
      });
      return false;
    }

    return true;
  }

  function validaCampoObrigatorioTubo(e) {
    if (!e.dtInstalacao) {
      setMessage({
        message: 'Informe a Data de Instalação.',
        theme: Theme.Danger,
      });
      return false;
    }

    if (!e.noDiametroTubulacao) {
      setMessage({
        message: 'Informe o Diâm. Tubul. (pol).',
        theme: Theme.Danger,
      });
      return false;
    }

    if (!e.nrSeqPocTubulacaoAducao) {
      setMessage({
        message: 'Informe a Tubulação.',
        theme: Theme.Danger,
      });
      return false;
    }

    if (!e.nrMetragem) {
      setMessage({
        message: 'Informe a Metragem..',
        theme: Theme.Danger,
      });
      return false;
    }

    return true;
  }

  function validaCampoObrigatorioCabo(e) {
    if (!e.dtInstalacao) {
      setMessage({
        message: 'Informe a Data de Instalação.',
        theme: Theme.Danger,
      });
      return false;
    }

    if (!e.nrSeqPocCabo) {
      setMessage({
        message: 'Informe o Cabo.',
        theme: Theme.Danger,
      });
      return false;
    }

    if (!e.nrMetragem) {
      setMessage({
        message: 'Informe a Metragem..',
        theme: Theme.Danger,
      });
      return false;
    }

    return true;
  }

  function validaCampoObrigatorioEntragaAgua(e) {
    if (!e.nrProfundidade || e.nrProfundidade <= 0) {
      setMessage({
        message: 'Informe a Profundidade.',
        theme: Theme.Danger,
      });
      return false;
    }
    if (!e.nrVazao || e.nrVazao <= 0) {
      setMessage({
        message: 'Informe a Vazão.',
        theme: Theme.Danger,
      });
      return false;
    }

    return true;
  }

  function validaCampoObrigatorioFormacao(e) {
    if (!e.cdPocPerflitFormacao) {
      setMessage({
        message: 'Informe a Formação.',
        theme: Theme.Danger,
      });
      return false;
    }

    return true;
  }

  function validaCampoObrigatorioPrefiltro(e) {
    if (!e.nrEaInt) {
      setMessage({
        message: 'Informe o Espaço Anular Interno.',
        theme: Theme.Danger,
      });
      return false;
    }

    if (!e.nrEaExt) {
      setMessage({
        message: 'Informe o Espaço Anular Externo.',
        theme: Theme.Danger,
      });
      return false;
    }
    if (!e.cdPocTipoPrefiltro) {
      setMessage({
        message: 'Informe o Prefiltro.',
        theme: Theme.Danger,
      });
      return false;
    }
    if (!e.cdPocGranulometria) {
      setMessage({
        message: 'Informe a Granulometria.',
        theme: Theme.Danger,
      });
      return false;
    }

    return true;
  }

  function validaCampoObrigatorioCimentacao(e) {
    if (!e.nrEaInt) {
      setMessage({
        message: 'Informe o Espaço Anular Interno.',
        theme: Theme.Danger,
      });
      return false;
    }
    if (!e.nrEaExt) {
      setMessage({
        message: 'Informe o Espaço Anular Externo.',
        theme: Theme.Danger,
      });
      return false;
    }

    return true;
  }

  function validaCampoObrigatorioRevestimento(e) {
    if (!e.nrDiametropol) {
      setMessage({
        message: 'Informe o diâmetro da revestimento.',
        theme: Theme.Danger,
      });
      return false;
    }
    if (e.flgTipoObjeto === 'F') {
      if (!e.nrSeqPocTipoFiltro) {
        setMessage({
          message: 'Informe o filtro.',
          theme: Theme.Danger,
        });
        return false;
      }

      if (!e.nrSlot) {
        setMessage({
          message: 'Informe o slot.',
          theme: Theme.Danger,
        });
        return false;
      }

      return true;
    }
    if (e.flgTipoObjeto === 'R') {
      if (!e.nrSeqPocMaterialRevestimento) {
        setMessage({
          message: 'Informe o material de revestimento.',
          theme: Theme.Danger,
        });
        return false;
      }
    }

    return true;
  }

  function validaCampoObrigatorioDesenvolvimento(e) {
    if (!e.cdPocMetodoDesenvolvimento) {
      setMessage({
        message: 'Informe o Método de Desenvolvimento.',
        theme: Theme.Danger,
      });
      return false;
    }
    if (!e.nrTempoStr || e.nrTempoStr.length < 5) {
      setMessage({
        message: 'Informe o Tempo de Desenvolvimento.',
        theme: Theme.Danger,
      });
      return false;
    }

    return true;
  }

  function validaCampoObrigatorioEnsaio(e) {
    if (!e.dtInicioEnsaio || !e.dtFimEnsaio) {
      setMessage({
        message: 'Informe as datas de inicio e fim do ensaio.',
        theme: Theme.Danger,
      });
      return false;
    }

    return true;
  }
  const messageNenhumEnsaio = () => {
    setMessage({
      message: 'Nenhum ensaio com bombeamentos e recuperações.',
      theme: Theme.Danger,
    });
  };

  function campoNuloOuVazio(e) {
    if (e === '' || e === undefined || e == null) return true;
    return false;
  }
  function validaCalculoBombeamento(e) {
    if (
      e.nrBombeioStr === '00:00' ||
      e.nrDescansoStr === '00:00' ||
      e.nrRegimeBombeamentoStr === '00:00'
    ) {
      setMessage({
        message:
          'Para realizar o cálculo é necessário que um campo esteja sem informação de tempo..',
        theme: Theme.Danger,
      });
      return true;
    }
    if (
      campoNuloOuVazio(e.nrBombeioStr) &&
      campoNuloOuVazio(e.nrDescansoStr) &&
      campoNuloOuVazio(e.nrRegimeBombeamentoStr)
    ) {
      setMessage({
        message: 'Preencha dois campos para calcular.',
        theme: Theme.Danger,
      });
      return true;
    }
    if (
      e.nrBombeioStr === '00:00' &&
      e.nrDescansoStr === '00:00' &&
      e.nrRegimeBombeamentoStr === '00:00'
    ) {
      setMessage({
        message: 'Preencha dois campos para calcular.',
        theme: Theme.Danger,
      });
      return true;
    }
    if (e.nrDescansoStr === '00:00' && e.nrRegimeBombeamentoStr === '00:00') {
      setMessage({
        message: 'Preencha dois campos para calcular.',
        theme: Theme.Danger,
      });
      return true;
    }
    if (e.nrBombeioStr === '00:00' && e.nrDescansoStr === '00:00') {
      setMessage({
        message: 'Preencha dois campos para calcular.',
        theme: Theme.Danger,
      });
      return true;
    }
    if (e.nrBombeioStr === '00:00' && e.nrRegimeBombeamentoStr === '00:00') {
      setMessage({
        message: 'Preencha dois campos para calcular.',
        theme: Theme.Danger,
      });
      return true;
    }
    if (
      campoNuloOuVazio(e.nrDescansoStr) &&
      campoNuloOuVazio(e.nrRegimeBombeamentoStr)
    ) {
      setMessage({
        message: 'Preencha dois campos para calcular.',
        theme: Theme.Danger,
      });
      return true;
    }
    if (
      campoNuloOuVazio(e.nrBombeioStr) &&
      campoNuloOuVazio(e.nrRegimeBombeamentoStr)
    ) {
      setMessage({
        message: 'Preencha dois campos para calcular.',
        theme: Theme.Danger,
      });
      return true;
    }
    if (campoNuloOuVazio(e.nrBombeioStr) && campoNuloOuVazio(e.nrDescansoStr)) {
      setMessage({
        message: 'Preencha dois campos para calcular.',
        theme: Theme.Danger,
      });
      return true;
    }

    return false;
  }

  const save = async () => {
    setLoading(true);

    const { status, message: msg, value: nrseq } = await savePocPoco(dataPoco);
    const files = dataPoco.documentosUpload ?? [];
    if (files.length > 0) {
      files.forEach(async (file) => {
        await uploadDocumento(file, dataPoco.nrSeqPocPoco);
      });
    }

    if (status === ResponseStatus.Success) {
      setStepVisible(true);
      setMessage({
        message: msg,
        theme: Theme.Success,
      });

      const dataPocoSaved = await getPocPoco(nrseq);
      setDataPoco(dataPocoSaved);
      document.getElementsByClassName('visited')[0].click();
    } else {
      setStepVisible(false);
      setMessage({
        message: msg,
        theme: Theme.Danger,
      });
    }

    setLoading(false);
  };

  const StepCadastroCliente = () => (
    <div>
      <CadastroCliente
        onOpenTransaction={onOpenTransaction}
        findTransaction={findTransaction}
        registryKey={registryKey}
        ref={cadastroClienteRef}
        data={dataPoco}
        setData={setDataPoco}
      />
    </div>
  );

  const StepCadastroPoco = () => (
    <div>
      <CadastroPoco
        onOpenTransaction={onOpenTransaction}
        findTransaction={findTransaction}
        registryKey={registryKey}
        ref={cadastroPocoRef}
        data={dataPoco}
        setData={setDataPoco}
        onSetLoading={(flgLoading) => setLoading(flgLoading)}
      />
    </div>
  );

  const StepCadastroPerfuracao = () => (
    <div>
      <CadastroPerfuracao
        data={dataPoco}
        setData={setDataPoco}
        onInicioFimInvalido={(inicio, fim) => inicioFimInvalido(inicio, fim)}
        onValidaCampoObrigatorio={(e) => validaCampoObrigatorioPerfuracao(e)}
        onOpenTransaction={onOpenTransaction}
        findTransaction={findTransaction}
        onErro={(msg) => {
          setMessage({
            message: msg,
            theme: Theme.Danger,
          });
        }}
        ref={cadastroPerfuracaoRef}
      />
    </div>
  );

  const StepPerfilLitologico = () => (
    <div>
      <CadastroPerfilLitologico
        data={dataPoco}
        setData={setDataPoco}
        onInicioFimInvalido={(inicio, fim) => inicioFimInvalido(inicio, fim)}
        onValidaCampoObrigatorioCamada={(e) => validaCampoObrigatorioCamada(e)}
        onValidaCampoObrigatorioFormacao={(e) =>
          validaCampoObrigatorioFormacao(e)
        }
        onOpenTransaction={onOpenTransaction}
        findTransaction={findTransaction}
        onErro={(msg) => {
          setMessage({
            message: msg,
            theme: Theme.Danger,
          });
        }}
        ref={cadastroPerfilLitologicoRef}
      />
    </div>
  );

  const StepRevestimentoFiltro = () => (
    <div>
      <CadastroRevestimentoFiltro
        setData={setDataPoco}
        data={dataPoco}
        onInicioFimInvalido={(inicio, fim) => inicioFimInvalido(inicio, fim)}
        onValidaCampoObrigatorioRevestimento={(e) =>
          validaCampoObrigatorioRevestimento(e)
        }
        onOpenTransaction={onOpenTransaction}
        findTransaction={findTransaction}
        onErro={(msg) => {
          setMessage({
            message: msg,
            theme: Theme.Danger,
          });
        }}
        ref={cadastroRevestimentoFiltroRef}
      />
    </div>
  );

  const StepCimentacaoPreFiltro = () => (
    <div>
      <CadastroCimentacaoPrefiltro
        data={dataPoco}
        setData={setDataPoco}
        onInicioFimInvalido={(inicio, fim) => inicioFimInvalido(inicio, fim)}
        onValidaCampoObrigatorioPrefiltro={(e) =>
          validaCampoObrigatorioPrefiltro(e)
        }
        onValidaCampoObrigatorioCimentacao={(e) =>
          validaCampoObrigatorioCimentacao(e)
        }
        onOpenTransaction={onOpenTransaction}
        findTransaction={findTransaction}
        onErro={(msg) => {
          setMessage({
            message: msg,
            theme: Theme.Danger,
          });
        }}
        ref={cadastroCimentacaoPrefiltroRef}
      />
    </div>
  );

  const StepCadastroDesenvolvimento = () => (
    <div>
      <CadastroDesenvolvimento
        data={dataPoco}
        setData={setDataPoco}
        onValidaCampoObrigatorioDesenvolvimento={(e) =>
          validaCampoObrigatorioDesenvolvimento(e)
        }
        onOpenTransaction={onOpenTransaction}
        findTransaction={findTransaction}
        onErro={(msg) => {
          setMessage({
            message: msg,
            theme: Theme.Danger,
          });
        }}
        ref={cadastroDesenvolvimentoRef}
      />
    </div>
  );

  const StepCadastroDocumentoAnaliseAgua = () => (
    <div>
      <CadastroDocumentoAnaliseAgua
        data={dataPoco}
        setData={setDataPoco}
        onErro={(msg) => {
          setMessage({
            message: msg,
            theme: Theme.Danger,
          });
        }}
        ref={cadastroDocumentoAnaliseAguaRef}
      />
    </div>
  );

  const StepCadastroDadoHidraulico = () => (
    <div>
      <CadastroDadoHidraulico
        data={dataPoco}
        setData={setDataPoco}
        onValidaCampoObrigatorioEnsaio={(e) => validaCampoObrigatorioEnsaio(e)}
        onValidaCalculoBombeamento={(e) => validaCalculoBombeamento(e)}
        onValidaEntradaAgua={(e) => validaCampoObrigatorioEntragaAgua(e)}
        onMessageNenhumEnsaio={() => messageNenhumEnsaio()}
        onErro={(msg) => {
          setMessage({
            message: msg,
            theme: Theme.Danger,
          });
        }}
        onSetLoading={(flgLoading) => setLoading(flgLoading)}
        ref={cadastroDadoHidraulicoRef}
      />
    </div>
  );

  const StepEquipInstalado = () => (
    <div>
      <CadastroEquipInstalado
        data={dataPoco}
        setData={setDataPoco}
        onValidaCampoObrigatorioBomba={(e) => validaCampoObrigatorioBomba(e)}
        onValidaCampoObrigatorioTubo={(e) => validaCampoObrigatorioTubo(e)}
        onValidaCampoObrigatorioCabo={(e) => validaCampoObrigatorioCabo(e)}
        getData={getData}
        save={save}
        registryKey={registryKey}
        onErro={(msg) => {
          setMessage({
            message: msg,
            theme: Theme.Danger,
          });
        }}
        ref={cadastroEquipInstaladaRef}
      />
    </div>
  );

  const StepCadastroDocumentoAuxiliaresPoco = () => (
    <div>
      <CadastroDocumentosPoco
        data={dataPoco}
        setData={setDataPoco}
        onErro={(msg) => {
          setMessage({
            message: msg,
            theme: Theme.Danger,
          });
        }}
        ref={cadastroDocumentosAuxiliaresPocoRef}
      />
    </div>
  );

  const StepDesenhoPoco = () => (
    <div>
      <DesenhoControlePoco data={dataPoco} setData={setDataPoco} />
    </div>
  );

  const step1CadastroCliente = React.createElement(StepCadastroCliente);
  const step2CadastroPoco = React.createElement(StepCadastroPoco);
  const step3CadastroPerfucacao = React.createElement(StepCadastroPerfuracao);
  const step4CadastroPerfilLitologico = React.createElement(
    StepPerfilLitologico
  );

  const step5CadastroRevestimentoFiltro = React.createElement(
    StepRevestimentoFiltro
  );

  const step6Anular = React.createElement(StepCimentacaoPreFiltro);

  const step7Desenvolvimento = React.createElement(StepCadastroDesenvolvimento);

  const step8AnaliseAgua = React.createElement(
    StepCadastroDocumentoAnaliseAgua
  );

  const step9DadosHidraulicos = React.createElement(StepCadastroDadoHidraulico);

  const step10EquipamentosInstalados = React.createElement(StepEquipInstalado);

  const step11DocumentosAuxiliares = React.createElement(
    StepCadastroDocumentoAuxiliaresPoco
  );

  const step12DesenhoPoco = React.createElement(StepDesenhoPoco);

  useEffect(() => {
    (async function func() {
      if (Number.isInteger(registryKey)) {
        const poco = await getPocPoco(registryKey);
        setDataPoco(poco);
        setStepVisible(true);
      } else {
        setDataPoco(await getPocPoco(0));

        setStepVisible(false);
      }
    })();
  }, [registryKey]);

  const onPrint = async (param) => {
    setShowImprimir(false);
    setLoading(true);
    const { relatorio, message: msg } = await printPocPoco({
      nrSeqPocPoco: dataPoco.nrSeqPocPoco,
      ...param,
    });
    setLoading(false);
    if (msg) {
      setMessage({
        message: msg,
        theme: Theme.Danger,
      });
    }
    if (relatorio) {
      const linkSource = `data:image/pdf;base64, ${relatorio}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `poco_${dataPoco.cdPocPoco}.pdf`;
      downloadLink.click();
    }
  };

  const closeMessageErro = () => {
    setMessage(null);
  };

  const steps = [
    {
      index: 1,
      title: 'Cliente',
      visible: true,
      content: () => step1CadastroCliente,
    },
    {
      index: 2,
      title: 'Obra/Poço',
      visible: true,
      content: () => step2CadastroPoco,
    },
    {
      index: 3,
      title: 'Perfuração',
      visible: stepVisible,
      content: () => step3CadastroPerfucacao,
    },
    {
      index: 4,
      title: 'Perfil Litológico',
      visible: stepVisible,
      content: () => step4CadastroPerfilLitologico,
    },
    {
      index: 5,
      title: 'Revestimento / Filtro',
      visible: stepVisible,
      content: () => step5CadastroRevestimentoFiltro,
    },
    {
      index: 6,
      title: 'Anular',
      visible: stepVisible,
      content: () => step6Anular,
    },
    {
      index: 7,
      title: 'Desenvolvimento',
      visible: stepVisible,
      content: () => step7Desenvolvimento,
    },
    {
      index: 8,
      title: 'Documentos Analise Água',
      visible: stepVisible,
      content: () => step8AnaliseAgua,
    },
    {
      index: 9,
      title: 'Dados Hidráulicos',
      visible: stepVisible,
      content: () => step9DadosHidraulicos,
    },
    {
      index: 10,
      title: 'Equipamentos Instalados',
      visible: stepVisible,
      content: () => step10EquipamentosInstalados,
    },
    {
      index: 11,
      title: 'Documentos Anexados',
      visible: stepVisible,
      content: () => step11DocumentosAuxiliares,
    },

    {
      index: 12,
      title: 'Desenho Poço',
      visible: stepVisible,
      content: () => step12DesenhoPoco,
    },
  ];

  const { id: idSelecao } = PageTypes.Selection;
  const removeValidate = true;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Controle Poço'
      message={message}
      loading={loading}
      onSave={save}
      onBack={() => onSelectPage(idSelecao)}
      onMessagerClose={() => closeMessageErro()}
      removeValidate={removeValidate}
      transaction={transaction}
    >
      <ToolbarButtons>
        {onPrint && (
          <ToolbarButtons.Button
            text='Imprimir'
            icon='print'
            onClick={() => setShowImprimir(true)}
          />
        )}
      </ToolbarButtons>
      <div>
        <div>
          <Steps
            propriedade={1}
            steps={steps}
            theme={Theme.Primary}
            reset={reset}
            align='vertical'
            registryKey={dataPoco.nrSeqPocPoco}
            data={dataPoco}
            setData={setDataPoco}
            getData={getData}
            ref={stepPoco}
          />
        </div>
      </div>

      <ModalImprimir
        show={showImprimir}
        onClose={() => setShowImprimir(false)}
        onPrint={(param) => onPrint(param)}
      />
    </CSDManutPage>
  );
}
