import qs from 'qs';
import Pagination from 'core/models/pagination';
import PocPocoPerflitFormacao from 'core/models/POC/pocPocoPerflitFormacao';
import api from '../api';

const baseURL = `/POC/pocPocoPerflitFormacao`;

export const sortPocPocoPerflitFormacao = async (data) => {
  const response = await api.post(`${baseURL}/Sort`, data);
  const { value } = response.data ?? {};
  return {
    listaFormacao: value?.map((json) => new PocPocoPerflitFormacao(json)) ?? [],
  };
};

export const getPocPocoPerflitFormacaoList = async (filters) => {
  const response = await api.get(`/$PocPocoPerflitFormacao/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPocoPerflitFormacao(json)) ?? [],
  };
};

export const getPocPocoPerflitFormacaoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPocoPerflitFormacao(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocPocoPerflitFormacao = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocPocoPerflitFormacao(value) : {};
};

export const savePocPocoPerflitFormacao = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePocPocoPerflitFormacao = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPocPocoPerflitFormacao = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocPocoPerflitFormacao = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocPocoPerflitFormacao = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
