import { PocPocoTipoDocumento } from 'core/models/POC';

export default class PocPocoDocumento {
  constructor(jsonObject = {}) {
    this.nrSeqPocPocoDocumento = jsonObject.nrSeqPocPocoDocumento;
    this.nrSeqPocPoco = jsonObject.nrSeqPocPoco;
    this.noImagem = jsonObject.noImagem;
    this.noTipoArquivo = jsonObject.noTipoArquivo;
    this.noTamanho = jsonObject.noTamanho;
    this.dtCadastro = jsonObject.dtCadastro;
    this.noArquivo = jsonObject.noArquivo;
    this.nrSeqPocPocoTipodocumento = jsonObject.nrSeqPocPocoTipodocumento;
    this.tipoDocumento = new PocPocoTipoDocumento(
      jsonObject.tipoDocumento ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
