import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { Switch, Autocomplete, DatePicker } from 'ui/components';
import { getPlanoAutoComplete } from 'core/services/TEL/plano';
import { MaskTypes } from 'ui/Helpers/masks';

const PanelArquivoIntegracao = forwardRef(
  ({ props, exibirDataFaturamento }, param) => {
    const [parametros, setParametros] = useState({});
    useImperativeHandle(param, () => ({
      param: parametros,
    }));

    useEffect(() => {
      setParametros({
        ...parametros,
        flg2arqintegr: props.flg2arqintegr,
        flg2agruparrfaturas: props.flg2agruparrfaturas,
        flg2porplano: props.flg2porplano,
        nrseq2plano: props.nrseq2plano,
      });
    }, [props]);

    const onSearchPlano = async (e) => {
      const { planos } = await getPlanoAutoComplete({
        noPlano: e,
        FlgOrderbyNome: true,
      });
      return planos;
    };

    return (
      <div>
        <div className='row mb-3'>
          <div className='col-3'>
            <Switch
              formControl
              label='Gerar'
              checked={parametros.flg2arqintegr}
              onChange={(e) =>
                setParametros({
                  ...parametros,
                  flg2arqintegr: e,
                })
              }
            />
          </div>
          <div className='col-3'>
            <Switch
              formControl
              label='Agrupar Faturas'
              checked={parametros.flg2agruparrfaturas}
              onChange={(e) =>
                setParametros({
                  ...parametros,
                  flg2agruparrfaturas: e,
                })
              }
            />
          </div>
          <div className='col-3'>
            <Switch
              formControl
              label='Gerar arquivo por plano'
              checked={parametros.flg2porplano}
              onChange={(e) =>
                setParametros({
                  ...parametros,
                  flg2porplano: e,
                })
              }
            />
          </div>
        </div>
        {exibirDataFaturamento ? (
          <div className='row mb-3'>
            <div className='col-6'>
              <Autocomplete
                label='Plano (Campo Opcional)'
                searchDataSource={onSearchPlano}
                selectedItem={parametros.nrSeq2Plano}
                onSelectItem={(plano) =>
                  setParametros({
                    ...parametros,
                    noPlano: plano.noPlano,
                    nrseq2plano: plano.nrSeqPlano,
                  })
                }
                dataSourceTextProperty='noPlano'
              />
            </div>
            <div className='col-3'>
              <DatePicker
                label='Data de Faturamento'
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtFaturamento) => {
                  setParametros({ ...parametros, dtFaturamento });
                }}
              />
            </div>
          </div>
        ) : (
          <div className='row mb-3'>
            <div className='col-6'>
              <Autocomplete
                label='Plano (Campo Opcional)'
                searchDataSource={onSearchPlano}
                selectedItem={parametros.nrSeq2Plano}
                onSelectItem={(plano) =>
                  setParametros({
                    ...parametros,
                    noPlano: plano.noPlano,
                    nrseq2plano: plano.nrSeqPlano,
                  })
                }
                dataSourceTextProperty='noPlano'
              />
            </div>
          </div>
        )}
      </div>
    );
  }
);
export default PanelArquivoIntegracao;
