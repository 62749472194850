import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import {
  GridView,
  Textbox,
  Button,
  RadioButton,
  DatePicker,
  Textarea,
  Autocomplete,
  Switch,
} from 'ui/components';
import {
  Theme,
  BootstrapSizes,
  timeToDecimal,
  formatNumber,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import PocPocoEntradaAgua from 'core/models/POC/pocPocoEntradaAgua';
import PocPocoDadosHidraulicos from 'core/models/POC/pocPocoDadosHidraulicos';
import {
  postDadosFinais,
  getPocPocoCalculateHydraulic,
} from 'core/services/POC/pocPocoDadosHidraulicos';
import {
  getTecnicaMedidaAutoComplete,
  getTipoEnsaioAutoComplete,
  savePocPocoPocEnsaio,
  deletePocPocoPocEnsaio,
  getPocPocoPocEnsaioPagined,
} from 'core/services/POC/pocPocoPocEnsaio';
import {
  updateNivelBombeamento,
  updateVazaoBombeamento,
  updateTempoBombeamento,
  deletePocEnsaioBombeamento,
  deletePocEnsaioBombeamentoTimesEmpty,
  savePocEnsaioBombeamento,
  getPocEnsaioBombeamentoList,
} from 'core/services/POC/pocEnsaioBombeamento';

import {
  updateNivelRecuperacao,
  updateTempoRecuperacao,
  deletePocEnsaioRecuperacao,
  deletePocEnsaioRecuperacaoTimesEmpty,
  savePocEnsaioRecuperacao,
  getPocEnsaioRecuperacaoList,
} from 'core/services/POC/pocEnsaioRecuperacao';

import PocPocoPocEnsaio from 'core/models/POC/pocPocoPocEnsaio';
import PocEnsaioBombeamento from 'core/models/POC/pocEnsaioBombeamento';
import PocEnsaioRecuperacao from 'core/models/POC/pocEnsaioRecuperacao';
import PocEnsaioDadosHidraulicos from 'core/models/POC/pocEnsaioDadosHidraulicos';
import {
  getPocEnsaioDadosHidraulicosList,
  savePocEnsaioDadosHidraulicos,
} from 'core/services/POC/pocEnsaioDadosHidraulicos';

import ModalEquipamento from './modalEquipamento';

const Tipo = [
  {
    text: 'Captada',
    value: 'C',
  },
  {
    text: 'Isolada',
    value: 'I',
  },
];

const Aquifero = [
  {
    text: 'Poroso',
    value: 'P',
  },
  {
    text: 'Fraturado',
    value: 'F',
  },
];
const DadosHidraulicos = [
  {
    text: 'Entrada de Água',
    value: 'EntradaAgua',
  },
  {
    text: 'Ensaio',
    value: 'Ensaio',
  },
  {
    text: 'Dados Finais',
    value: 'DadosFinais',
  },
];

const CadastroDadoHidraulico = forwardRef(
  ({
    data,
    setData,
    onValidaCampoObrigatorioEnsaio,
    onValidaCalculoBombeamento,
    onMessageNenhumEnsaio,
    onValidaEntradaAgua,
    onSetLoading,
    onErro,
  }) => {
    const [showModalEquipamento, setModalEquipamento] = useState(false);
    const gridViewEntradaAgua = useRef();
    const gridViewBombeamento = useRef();
    const gridViewRecuperacao = useRef();
    const gridViewDadoFinalEnsaio = useRef();
    const [visible, setVisible] = useState(true);
    const gridViewEnsaio = useRef();
    const [parametroModal, setParametroModal] = useState();
    const [dataEntradaAgua, setDataEntradaAgua] = useState(
      new PocPocoEntradaAgua({
        flgCaptadaIsolada: 'C',
        flgPorosoFraturado: 'P',
        getCaptadaIsolada: 'Captada',
        getPorosoFraturado: 'Poroso',
      })
    );

    const { dadosHidraulicos } = data;

    const dataRecuperacao = new PocEnsaioRecuperacao({ nrTempoStr: '00:00' });

    const dataBombeamento = new PocEnsaioBombeamento({ nrTempoStr: '00:00' });

    const [dataDadoHidraulico, setDataDadoHidraulico] = useState(
      new PocPocoDadosHidraulicos()
    );
    const [dataDadosHidraulicos, setDadosHidraulicos] = useState({
      flgDadosHidraulicos: 'EntradaAgua',
    });

    const [dataEnsaio, setDataEnsaio] = useState({});
    const [dataEnsaioSelecionado, setDataEnsaioSelecionado] = useState();
    const [
      dataEnsaioDadosHidraulicos,
      setDataEnsaioDadosHidraulicos,
    ] = useState([]);

    const setInputGridVazao = (id, row, col, value) => {
      const elem = document.getElementById(`${id}_${row}_${col}`);

      if (elem) {
        const input = elem.querySelector('input');

        if (input) {
          input.value = formatNumber(value);
        }
      }
    };

    function totalProdutividade(time, vazao) {
      if (time === '' || vazao === '') return 0;
      if (time === undefined || vazao === undefined) return 0;
      return timeToDecimal(time) * vazao;
    }

    const totalVazao = (lista) => {
      const newlist = lista.filter(
        (e) => e.status !== 'Remover' && e.flgCaptadaIsolada !== 'I'
      );
      const nrVazoes = newlist.map((e) => e.nrVazao);
      let total = 0;
      if (nrVazoes.length > 0) {
        total = Number(nrVazoes.reduce((a, b) => a + b, 0).toFixed(2));
        dadosHidraulicos.nrVazaoEnsaioComp = total;

        setDataDadoHidraulico({
          ...dadosHidraulicos,
          nrVazaoEnsaioComp: total,
        });
      } else {
        dadosHidraulicos.nrVazaoEnsaioComp = 0;

        setDataDadoHidraulico({
          ...dadosHidraulicos,
          nrVazaoEnsaioComp: 0,
        });
      }
    };

    const saveField = async (objct) => {
      const obj = new PocEnsaioDadosHidraulicos({
        ...dataEnsaioDadosHidraulicos,
        nrSeqPocEnsaio: dataEnsaioSelecionado.nrSeqPocEnsaio,
      });
      await savePocEnsaioDadosHidraulicos(objct ?? obj);
    };

    const load = useCallback(async () => {
      if (gridViewEntradaAgua && gridViewEntradaAgua.current)
        gridViewEntradaAgua.current.setDataSource(data.entradasAgua);

      if (gridViewEnsaio && gridViewEnsaio.current)
        gridViewEnsaio.current.setDataSource(data.ensaios);

      setDataDadoHidraulico({
        ...dadosHidraulicos,
        nrVazaoEnsaioComp: data.totalEntradasAgua,
      });
    });

    useEffect(() => {
      (async function func() {
        await load();

        if (gridViewEnsaio && gridViewEnsaio.current)
          gridViewEnsaio.current.setDataSource([]);

        if (dataDadosHidraulicos.flgDadosHidraulicos === 'Ensaio') {
          setDataEnsaio({});
          const ensaios = await getPocPocoPocEnsaioPagined({
            nrSeqPocPoco: data.nrSeqPocPoco,
          });
          if (gridViewEnsaio && gridViewEnsaio.current)
            gridViewEnsaio.current.setDataSource(ensaios.data);
        }

        if (dataDadosHidraulicos.flgDadosHidraulicos === 'DadosFinais') {
          const ensaios = await getPocPocoPocEnsaioPagined({
            nrSeqPocPoco: data.nrSeqPocPoco,
          });

          const obj = {
            ensaios: ensaios.data,
            nrSeqEnsaioSelecionado: ensaios.data[0]?.nrSeqPocEnsaio ?? 0,
          };
          const { dadosFinais } = await postDadosFinais(obj);
          if (dadosFinais.ensaios) {
            if (dadosFinais.ensaios.length > 1) {
              setVisible(true);
            } else {
              setVisible(false);
            }
          }
          if (dadosFinais.ensaios && dadosFinais.ensaios.length > 0) {
            if (gridViewDadoFinalEnsaio && gridViewDadoFinalEnsaio.current)
              gridViewDadoFinalEnsaio.current.setDataSource(
                dadosFinais.ensaios
              );
            setDataEnsaioSelecionado(dadosFinais.ensaios[0]);
            setDataEnsaioDadosHidraulicos({
              ...dadosFinais.ensaios[0].dadoFinal,
            });
            setDataDadoHidraulico({
              ...dataDadoHidraulico,
              ...dadosFinais.ensaios[0].dadoFinal,
            });
          } else {
            onMessageNenhumEnsaio();
            setDataEnsaioDadosHidraulicos({});
          }
        }
      })();
    }, [dataDadosHidraulicos.flgDadosHidraulicos]);

    const onAdicionarEntradaAgua = async () => {
      if (!onValidaEntradaAgua(dataEntradaAgua)) return;
      dataEntradaAgua.status =
        dataEntradaAgua.status ?? GridView.EnumStatus.Inserir;
      dataEntradaAgua.nrSeqPocPoco = data.nrSeqPocPoco;

      const dataInsert = new PocPocoEntradaAgua({
        ...dataEntradaAgua,
        getCaptadaIsolada:
          dataEntradaAgua.flgCaptadaIsolada === 'C' ? 'Captada' : 'Isolada',
        getPorosoFraturado:
          dataEntradaAgua.flgPorosoFraturado === 'P' ? 'Poroso' : 'Fraturado',
      });

      data.entradasAgua.push(dataInsert);

      if (gridViewEntradaAgua && gridViewEntradaAgua.current)
        gridViewEntradaAgua.current.setDataSource(data.entradasAgua);

      setDataEntradaAgua(
        new PocPocoEntradaAgua({
          flgCaptadaIsolada: dataEntradaAgua.flgCaptadaIsolada,
          flgPorosoFraturado: dataEntradaAgua.flgPorosoFraturado,
        })
      );

      document.getElementById('profundidade').focus();

      totalVazao(data.entradasAgua);
    };

    const onAdicionarBombeamento = async () => {
      onSetLoading(true);

      const obj = new PocEnsaioBombeamento({
        ...dataBombeamento,
        nrSeqPocEnsaio: dataEnsaio.nrSeqPocEnsaio,
      });

      const resp = await savePocEnsaioBombeamento(obj);

      if (resp.value.nrSeqPocEnsaioBombeamento > 0) {
        const listaBombeamentos = await getPocEnsaioBombeamentoList({
          nrSeqPocEnsaio: obj.nrSeqPocEnsaio,
        });

        if (gridViewBombeamento && gridViewBombeamento.current)
          gridViewBombeamento.current.setDataSource(listaBombeamentos.data);

        const listaRecuperacoes = await getPocEnsaioRecuperacaoList({
          nrSeqPocEnsaio: obj.nrSeqPocEnsaio,
        });

        if (gridViewRecuperacao && gridViewRecuperacao.current)
          gridViewRecuperacao.current.setDataSource(listaRecuperacoes.data);

        setData({
          ...data,
          bombeamentos: listaBombeamentos,
        });
      }
      onSetLoading(false);
    };

    const onAdicionarRecuperacao = async () => {
      onSetLoading(true);

      const obj = new PocEnsaioRecuperacao({
        ...dataRecuperacao,
        nrSeqPocEnsaio: dataEnsaio.nrSeqPocEnsaio,
      });

      const resp = await savePocEnsaioRecuperacao(obj);

      if (resp.value.nrSeqPocEnsaioRecuperacao > 0) {
        const listaRecuperacoes = await getPocEnsaioRecuperacaoList({
          nrSeqPocEnsaio: obj.nrSeqPocEnsaio,
        });

        if (gridViewRecuperacao && gridViewRecuperacao.current)
          gridViewRecuperacao.current.setDataSource(listaRecuperacoes.data);

        setData({
          ...data,
          recuperacoes: listaRecuperacoes,
        });
      }
      onSetLoading(false);
    };

    const onAdicionarEnsaio = async () => {
      if (!onValidaCampoObrigatorioEnsaio(dataEnsaio)) return;

      let listaEnsaio = gridViewEnsaio.current.getDataSource();
      const obj = new PocPocoPocEnsaio({
        ...dataEnsaio,
        nrSeqPocPoco: data.nrSeqPocPoco,
        flgVisibleBombRecup: true,
      });

      onSetLoading(true);
      const resp = await savePocPocoPocEnsaio(obj);

      if (resp.value.nrSeqPocEnsaio > 0) {
        listaEnsaio = listaEnsaio.filter(
          (ensaio) => ensaio.nrSeqPocEnsaio !== resp.value.nrSeqPocEnsaio
        );
        listaEnsaio.push(resp.value);

        if (gridViewBombeamento && gridViewBombeamento.current)
          gridViewBombeamento.current.setDataSource([]);

        if (gridViewRecuperacao && gridViewRecuperacao.current)
          gridViewRecuperacao.current.setDataSource([]);

        setData({
          ...data,
          ensaios: listaEnsaio,
        });
      }

      if (gridViewEnsaio && gridViewEnsaio.current)
        gridViewEnsaio.current.setDataSource(listaEnsaio);

      setDataEnsaio({
        dataEnsaio: new PocPocoPocEnsaio({
          dtInicioEnsaio: '',
          dtFimEnsaio: '',
        }),

        noObservacaoEnsaio: '',
      });
      onSetLoading(false);
    };

    const onAdicionarEquipamento = async (e) => {
      onSetLoading(true);
      setParametroModal(e.nrSeqPocEnsaio);
      setModalEquipamento(true);
      onSetLoading(false);
    };

    const onEditarEnsaio = async (e, datasource) => {
      onSetLoading(true);
      const dataEnsaioS = datasource.filter((ensaio) => ensaio !== e);
      const dataEns = datasource.filter((ensaio) => ensaio === e);

      const { tiposEnsaio } = await getTipoEnsaioAutoComplete({});
      const { tecnicasMedida } = await getTecnicaMedidaAutoComplete({});

      const tipoensaio = tiposEnsaio.filter(
        (p) => p.flgTipoEnsaio === dataEns[0].flgTipoEnsaio
      );
      const tecnicamedida = tecnicasMedida.filter(
        (p) => p.noTecnicaMedida === dataEns[0].noTecnicaMedida
      );

      const tipoensaioItem = { ...tipoensaio[0] };
      const tecnicamedidaItem = { ...tecnicamedida[0] };

      const obj = {
        ...dataEns[0],
        tiposEnsaio: tipoensaioItem,
        tecnicasMedida: tecnicamedidaItem,
      };

      dataEnsaio.nrSeqPocEnsaio = obj.nrSeqPocEnsaio;
      setDataEnsaio(obj);

      if (gridViewEnsaio && gridViewEnsaio.current)
        gridViewEnsaio.current.setDataSource(dataEnsaioS);

      const listaBombeamentos = await getPocEnsaioBombeamentoList({
        nrSeqPocEnsaio: obj.nrSeqPocEnsaio,
      });

      const listaRecuperacoes = await getPocEnsaioRecuperacaoList({
        nrSeqPocEnsaio: obj.nrSeqPocEnsaio,
      });

      if (gridViewBombeamento && gridViewBombeamento.current) {
        gridViewBombeamento.current.setDataSource(listaBombeamentos.data);
      }

      if (gridViewRecuperacao && gridViewRecuperacao.current) {
        gridViewRecuperacao.current.setDataSource(listaRecuperacoes.data);
      }

      onSetLoading(false);
    };

    const onRemoveEnsaio = async (e, datasource) => {
      onSetLoading(true);
      const dataEnsaioS = datasource.filter((ensaio) => ensaio !== e);
      const obj = { ...e };

      const resp = await deletePocPocoPocEnsaio(obj.nrSeqPocEnsaio);
      if (resp.value) {
        const poco = data;
        if (gridViewEnsaio && gridViewEnsaio.current)
          gridViewEnsaio.current.setDataSource(dataEnsaioS);

        setData({ ...poco, ensaios: dataEnsaioS, salvarEtapa: true });
      }
      onSetLoading(false);
    };

    const onEditarEntradaAgua = async (e) => {
      const objRemove = data.entradasAgua.filter((entAgua) => entAgua === e);

      const index = data.entradasAgua.indexOf(objRemove[0]);
      data.entradasAgua.splice(index, 1);

      objRemove[0].status = GridView.EnumStatus.Alterar;

      setDataEntradaAgua(objRemove[0]);
      totalVazao(data.entradasAgua);

      if (gridViewEntradaAgua && gridViewEntradaAgua.current)
        gridViewEntradaAgua.current.setDataSource(data.entradasAgua);
    };

    const onRemoveEntradaAgua = async (e) => {
      const objRemove = data.entradasAgua.filter((entAgua) => entAgua === e);

      const index = data.entradasAgua.indexOf(objRemove[0]);
      data.entradasAgua.splice(index, 1);

      objRemove[0].status = GridView.EnumStatus.Remover;

      data.entradasAgua.push(objRemove[0]);

      if (gridViewEntradaAgua && gridViewEntradaAgua.current)
        gridViewEntradaAgua.current.setDataSource(data.entradasAgua);

      setData({ ...data, entradasAgua: data.entradasAgua });
      totalVazao(data.entradasAgua);
    };

    const onRemoveBombeamento = async (e) => {
      onSetLoading(true);
      const obj = { ...e };

      const resp = await deletePocEnsaioBombeamento(
        obj.nrSeqPocEnsaioBombeamento
      );

      if (resp) {
        const listaBombeamentos = await getPocEnsaioBombeamentoList({
          nrSeqPocEnsaio: obj.nrSeqPocEnsaio,
        });

        const listaRecuperacoes = await getPocEnsaioRecuperacaoList({
          nrSeqPocEnsaio: obj.nrSeqPocEnsaio,
        });

        if (gridViewBombeamento && gridViewBombeamento.current)
          gridViewBombeamento.current.setDataSource(listaBombeamentos.data);

        if (gridViewRecuperacao && gridViewRecuperacao.current)
          gridViewRecuperacao.current.setDataSource(listaRecuperacoes.data);

        setData({
          ...data,
          bombeamentos: listaBombeamentos.data,
        });
        onSetLoading(false);
      }
    };

    const onRemoveBombeamentoTimesEmpty = async () => {
      onSetLoading(true);

      const resp = await deletePocEnsaioBombeamentoTimesEmpty(
        dataEnsaio.nrSeqPocEnsaio
      );

      if (resp) {
        const listaBombeamentos = await getPocEnsaioBombeamentoList({
          nrSeqPocEnsaio: dataEnsaio.nrSeqPocEnsaio,
        });

        const listaRecuperacoes = await getPocEnsaioRecuperacaoList({
          nrSeqPocEnsaio: dataEnsaio.nrSeqPocEnsaio,
        });

        if (gridViewBombeamento && gridViewBombeamento.current)
          gridViewBombeamento.current.setDataSource(listaBombeamentos.data);

        if (gridViewRecuperacao && gridViewRecuperacao.current)
          gridViewRecuperacao.current.setDataSource(listaRecuperacoes.data);

        setData({
          ...data,
          bombeamentos: listaBombeamentos.data,
        });
        onSetLoading(false);
      }
    };

    const onRemoveRecuperacao = async (e) => {
      onSetLoading(true);

      const obj = { ...e, status: GridView.EnumStatus.Remover };
      const resp = await deletePocEnsaioRecuperacao(
        obj.nrSeqPocEnsaioRecuperacao
      );
      if (resp) {
        const listaBombeamentos = await getPocEnsaioBombeamentoList({
          nrSeqPocEnsaio: obj.nrSeqPocEnsaio,
        });

        const listaRecuperacoes = await getPocEnsaioRecuperacaoList({
          nrSeqPocEnsaio: obj.nrSeqPocEnsaio,
        });

        if (gridViewBombeamento && gridViewBombeamento.current)
          gridViewBombeamento.current.setDataSource(listaBombeamentos.data);

        if (gridViewRecuperacao && gridViewRecuperacao.current)
          gridViewRecuperacao.current.setDataSource(listaRecuperacoes.data);

        setData({
          ...data,
          recuperacoes: listaRecuperacoes,
        });
      }
      onSetLoading(false);
    };

    const onRemoveRecuperacaoTimesEmpty = async () => {
      onSetLoading(true);

      const resp = await deletePocEnsaioRecuperacaoTimesEmpty(
        dataEnsaio.nrSeqPocEnsaio
      );
      if (resp) {
        const listaBombeamentos = await getPocEnsaioBombeamentoList({
          nrSeqPocEnsaio: dataEnsaio.nrSeqPocEnsaio,
        });

        const listaRecuperacoes = await getPocEnsaioRecuperacaoList({
          nrSeqPocEnsaio: dataEnsaio.nrSeqPocEnsaio,
        });

        if (gridViewBombeamento && gridViewBombeamento.current)
          gridViewBombeamento.current.setDataSource(listaBombeamentos.data);

        if (gridViewRecuperacao && gridViewRecuperacao.current)
          gridViewRecuperacao.current.setDataSource(listaRecuperacoes.data);

        setData({
          ...data,
          recuperacoes: listaRecuperacoes,
        });
      }
      onSetLoading(false);
    };

    const onSearchTipoEnsaio = async () => {
      const { tiposEnsaio } = await getTipoEnsaioAutoComplete({});
      const tipoEnsaioItem = {
        noTipoEnsaio: tiposEnsaio[0].noTipoEnsaio,
        nrSeqTipoEnsaio: tiposEnsaio[0].nrSeqTipoEnsaio,
      };
      setDataEnsaio({
        ...dataEnsaio,
        tiposEnsaio: tipoEnsaioItem,
      });
      return tiposEnsaio;
    };
    const onSearchTecnicaMedida = async () => {
      const { tecnicasMedida } = await getTecnicaMedidaAutoComplete({});
      const tecnicaMedidaItem = {
        noTecnicaMedida: tecnicasMedida[0].noTecnicaMedida,
        nrSeqTecnicaMedida: tecnicasMedida[0].nrSeqTecnicaMedida,
      };
      setDataEnsaio({
        ...dataEnsaio,
        tecnicasMedida: tecnicaMedidaItem,
      });
      return tecnicasMedida;
    };

    const onCalcularProjetoBombeamento = async () => {
      if (onValidaCalculoBombeamento(dataEnsaioDadosHidraulicos)) return;
      onSetLoading(true);
      const retorno = await getPocPocoCalculateHydraulic(
        dataEnsaioDadosHidraulicos
      );
      onSetLoading(false);
      setDataEnsaioDadosHidraulicos({
        ...dataEnsaioDadosHidraulicos,
        nrBombeioStr: retorno.nrBombeioStr,
        nrDescansoStr: retorno.nrDescansoStr,
        nrRegimeBombeamentoStr: retorno.nrRegimeBombeamentoStr,
      });
      dataEnsaioDadosHidraulicos.nrBombeioStr = retorno.nrBombeioStr;
      dataEnsaioDadosHidraulicos.nrDescansoStr = retorno.nrDescansoStr;
      dataEnsaioDadosHidraulicos.nrRegimeBombeamentoStr =
        retorno.nrRegimeBombeamentoStr;
      saveField();
    };

    const saveNivelBombeamento = async (
      nrSeqPocEnsaioBombeamento,
      nrNivel,
      row
    ) => {
      if (nrSeqPocEnsaioBombeamento > 0) {
        const dataNivel = {
          nrSeqPocEnsaioBombeamento,
          nrNivel: parseFloat(nrNivel),
        };

        const datasource = gridViewBombeamento.current.getBuildedDataSource();
        const datasourceRecuperacao = gridViewRecuperacao.current.getBuildedDataSource();

        const { status, message, value } = await updateNivelBombeamento(
          dataNivel
        );

        if (status === 0) {
          if (value.reload) {
            onSetLoading(true);
            const listaBombeamentos = await getPocEnsaioBombeamentoList({
              nrSeqPocEnsaio: value.nrSeqPocEnsaio,
            });

            const listaRecuperacoes = await getPocEnsaioRecuperacaoList({
              nrSeqPocEnsaio: value.nrSeqPocEnsaio,
            });

            for (
              let cont = 0;
              cont < listaBombeamentos.data.length;
              cont += 1
            ) {
              datasource[cont][4].value = formatNumber(
                listaBombeamentos.data[cont].nrRebaixamento
              );
              setInputGridVazao(
                'nrRebaixamento',
                cont + 1,
                4,
                listaBombeamentos.data[cont].nrRebaixamento
              );
            }

            for (
              let cont = 0;
              cont < listaRecuperacoes.data.length;
              cont += 1
            ) {
              datasourceRecuperacao[cont][3].value = formatNumber(
                listaRecuperacoes.data[cont].nrNivel
              );
              datasourceRecuperacao[cont][4].value = formatNumber(
                listaRecuperacoes.data[cont].nrRecuperacao
              );
              setInputGridVazao(
                'nrNivel',
                cont + 1,
                3,
                listaRecuperacoes.data[cont].nrNivel
              );
              setInputGridVazao(
                'nrRecuperacao',
                cont + 1,
                4,
                listaRecuperacoes.data[cont].nrRecuperacao
              );
            }

            onSetLoading(false);
          } else {
            datasource[row - 1][2].value = value.nrNivel;
            datasource[row - 1][4].value = value.nrRebaixamento;

            setInputGridVazao('nrRebaixamento', row, 4, value.nrRebaixamento);

            if (gridViewBombeamento && gridViewBombeamento.current)
              gridViewBombeamento.current.setBuildedDataSource(datasource);
          }
        } else {
          onErro(message);
        }
      }
    };

    const saveNivelRecuperacao = async (
      nrSeqPocEnsaioRecuperacao,
      nrNivel,
      row
    ) => {
      const dataNivel = {
        nrSeqPocEnsaioRecuperacao,
        nrNivel: parseFloat(nrNivel),
      };

      const datasource = gridViewRecuperacao.current.getBuildedDataSource();

      if (nrSeqPocEnsaioRecuperacao > 0) {
        const { status, message, value } = await updateNivelRecuperacao(
          dataNivel
        );

        if (status === 0) {
          if (value.reload) {
            onSetLoading(true);

            const listaRecuperacoes = await getPocEnsaioRecuperacaoList({
              nrSeqPocEnsaio: value.nrSeqPocEnsaio,
            });

            for (
              let cont = 0;
              cont < listaRecuperacoes.data.length;
              cont += 1
            ) {
              datasource[cont][3].value = formatNumber(
                listaRecuperacoes.data[cont].nrNivel
              );
              datasource[cont][4].value = formatNumber(
                listaRecuperacoes.data[cont].nrRecuperacao
              );
              setInputGridVazao(
                'nrNivel',
                cont + 1,
                3,
                listaRecuperacoes.data[cont].nrNivel
              );
              setInputGridVazao(
                'nrRecuperacao',
                cont + 1,
                4,
                listaRecuperacoes.data[cont].nrRecuperacao
              );
            }
            if (gridViewRecuperacao && gridViewRecuperacao.current)
              gridViewRecuperacao.current.setBuildedDataSource(datasource);

            onSetLoading(false);
          } else {
            datasource[row - 1][3].value = formatNumber(value.nrNive);
            datasource[row - 1][4].value = formatNumber(value.nrRecuperacao);

            setInputGridVazao('nrRecuperacao', row, 4, value.nrRecuperacao);

            if (gridViewRecuperacao && gridViewRecuperacao.current)
              gridViewRecuperacao.current.setBuildedDataSource(datasource);
          }
        } else {
          onErro(message);
        }
      }
    };

    const saveTempoRecuperacao = async (
      nrSeqPocEnsaioRecuperacao,
      nrTempoStr,
      row
    ) => {
      const dataTempo = {
        nrSeqPocEnsaioRecuperacao,
        nrTempoStr,
      };

      const datasource = gridViewRecuperacao.current.getBuildedDataSource();

      if (nrSeqPocEnsaioRecuperacao > 0) {
        updateTempoRecuperacao(dataTempo);
      }

      datasource[row - 1][1].value = nrTempoStr;

      gridViewRecuperacao.current.setBuildedDataSource(datasource);
    };

    const saveNivelVazao = async (nrSeqPocEnsaioBombeamento, nrVazao, row) => {
      const dataVazao = {
        nrSeqPocEnsaioBombeamento,
        nrVazao: parseFloat(nrVazao),
      };

      const datasource = gridViewBombeamento.current.getBuildedDataSource();

      if (nrSeqPocEnsaioBombeamento > 0) {
        updateVazaoBombeamento(dataVazao);
      }

      const elem = document
        .getElementById(`nrVazao_${row}_3`)
        .querySelector('input');

      const vazao = parseFloat(formatNumber(elem.value));

      datasource[row - 1][3].value = vazao;

      gridViewBombeamento.current.setBuildedDataSource(datasource);
    };

    const saveTempo = async (nrSeqPocEnsaioBombeamento, nrTempoStr, row) => {
      const dataTempo = {
        nrSeqPocEnsaioBombeamento,
        nrTempoStr,
      };

      const datasource = gridViewBombeamento.current.getBuildedDataSource();

      if (nrSeqPocEnsaioBombeamento > 0) {
        updateTempoBombeamento(dataTempo);
      }

      datasource[row - 1][5].value = nrTempoStr;

      gridViewBombeamento.current.setBuildedDataSource(datasource);
    };

    const fNivelBombeamento = function a(keyValue, value, row) {
      saveNivelBombeamento(keyValue, value, row);
    };

    const fNivelVazao = function a(keyValue, value, row) {
      saveNivelVazao(keyValue, value, row);
    };

    const fTempo = (keyValue, value, row) => {
      saveTempo(keyValue, value, row);
    };

    const fNivelRecuperacao = (keyValue, value, row) => {
      saveNivelRecuperacao(keyValue, value, row);
    };

    const fTempoRecuperacao = (keyValue, value, row) => {
      saveTempoRecuperacao(keyValue, value, row);
    };

    const onSelectEnsaio = async (e) => {
      onSetLoading(true);
      const ensaios = await getPocPocoPocEnsaioPagined({
        nrSeqPocPoco: data.nrSeqPocPoco,
      });
      const { dadosFinais } = await postDadosFinais({
        ensaios: ensaios.data,
        nrSeqEnsaioSelecionado: e.nrSeqPocEnsaio,
      });
      if (dadosFinais.ensaios && dadosFinais.ensaios.length > 0) {
        if (gridViewDadoFinalEnsaio && gridViewDadoFinalEnsaio.current)
          gridViewDadoFinalEnsaio.current.setDataSource(dadosFinais.ensaios);
      }
      setDataEnsaioSelecionado(e);
      const obj = new PocEnsaioDadosHidraulicos({
        nrSeqPocEnsaio: e.nrSeqPocEnsaio,
      });
      const dadoFinal = await getPocEnsaioDadosHidraulicosList(obj);
      setDataEnsaioDadosHidraulicos(dadoFinal.data[0]);
      onSetLoading(false);
    };

    const columnsBombeamento = [
      {
        key: 'nrSeqPocEnsaioBombeamento',
        visible: false,
        title: '',
      },

      {
        key: 'nrTempoStr',
        title: 'Tempo',
        type: GridView.ColumnTypes.Textbox,
        mask: MaskTypes.TimeHM,
        required: false,
        onBlur: fTempo,
      },

      {
        key: 'nrNivel',
        title: 'Nivel',
        format: GridView.DataTypes.Decimal,
        type: GridView.ColumnTypes.Textbox,
        mask: MaskTypes.DecimalCustom,
        onBlur: fNivelBombeamento,
      },

      {
        key: 'nrVazao',
        title: 'Vazão',
        format: GridView.DataTypes.Decimal,
        type: GridView.ColumnTypes.Textbox,
        mask: MaskTypes.DecimalCustom,
        onBlur: fNivelVazao,
      },
      {
        key: 'nrRebaixamento',
        title: 'Rebaixamento',
        format: GridView.DataTypes.Decimal,
        type: GridView.ColumnTypes.Textbox,
        mask: MaskTypes.DecimalCustom,
        readOnly: true,
        disabledTabIndex: true,
      },

      {
        key: 'nrSeqPocPocoBombeamento',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemoveBombeamento(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    const columnsRecuperacao = [
      { key: 'nrSeqPocEnsaioRecuperacao', visible: false },
      { key: 'nrSeqPocEnsaioRecuperacao', visible: false },

      {
        key: 'nrTempoStr',
        title: 'TempoRec',
        type: GridView.ColumnTypes.Textbox,
        mask: MaskTypes.TimeHM,
        onBlur: fTempoRecuperacao,
      },

      {
        key: 'nrNivel',
        title: 'NivelRec',
        format: GridView.DataTypes.Decimal,
        type: GridView.ColumnTypes.Textbox,
        mask: MaskTypes.DecimalCustom,
        onBlur: fNivelRecuperacao,
      },

      {
        key: 'nrRecuperacao',
        title: 'Recup.',
        format: GridView.DataTypes.Decimal,
        type: GridView.ColumnTypes.Textbox,
        mask: MaskTypes.DecimalCustom,
        readOnly: true,
        disabledTabIndex: true,
      },

      {
        key: 'nrSeqPocPocoRecuperacao',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemoveRecuperacao(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    const columnsEntradaAgua = [
      { key: 'nrSeqPocPocoEntradaAgua', visible: false },
      { key: 'nrSeqPocPoco', title: 'NrSeqPocPoco', visible: false },
      { key: 'nrProfundidade', title: 'Profundidade (m)' },
      { key: 'nrVazao', title: 'Vazão', format: GridView.DataTypes.Decimal },
      { key: 'getCaptadaIsolada', title: 'Tipo' },
      { key: 'getPorosoFraturado', title: 'Aquífero' },
      {
        key: 'nrSeqPocPocoEntradaAgua',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onEditarEntradaAgua(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },
      {
        key: 'nrSeqPocPocoEntradaAgua',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemoveEntradaAgua(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    const columnsEnsaio = [
      { key: 'nrSeqPocEnsaio', visible: false },
      {
        key: 'dtInicioEnsaio',
        title: 'Data Início',
        format: GridView.DataTypes.Date,
      },
      {
        key: 'dtFimEnsaio',
        title: 'Data Término',
        format: GridView.DataTypes.Date,
      },
      { key: 'noExecutor', title: 'Executor' },
      { key: 'noTecnicaMedida', title: 'Técnica Medida' },
      { key: 'noTipoEnsaio', title: 'Tipo Ensaio' },
      {
        key: 'noObservacaoEnsaio',
        title: 'NoObservacaoEnsaio',
        visible: false,
      },
      { key: 'nrSeqPocPoco', title: 'NrSeqPocPoco', visible: false },
      {
        key: 'nrSeqPocEnsaio',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onEditarEnsaio(e, datasource),
        theme: Theme.Warning,
        title: 'Editar',
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },
      {
        key: 'nrSeqPocEnsaio',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemoveEnsaio(e, datasource),
        theme: Theme.Danger,
        title: 'Remover',
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
      {
        key: 'nrSeqPocEnsaio',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onAdicionarEquipamento(e, datasource),
        theme: Theme.Info,
        title: 'Equipamento',
        icon: 'tasks',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Adicionar Equipamento',
        tooltipDirection: 'bottom',
      },
    ];

    const columnsDadoFinalEnsaio = [
      { key: 'nrSeqPocEnsaio', visible: false },
      {
        key: 'nrSeqPocEnsaio',
        type: GridView.ColumnTypes.Button,
        theme: Theme.Info,
        icon: 'check',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltipDirection: 'bottom',
        visibleDynamic: 'flgEnsaioSelecionado',
      },
      {
        key: 'dtInicioEnsaio',
        title: 'Data Início',
        format: GridView.DataTypes.Date,
      },
      { key: 'nrVazao', title: 'Vazão', format: GridView.DataTypes.Decimal },
      {
        key: 'nrNivelEstatico',
        title: 'Nível Estático',
        format: GridView.DataTypes.Decimal,
      },
      {
        key: 'nrNivelDinamico',
        title: 'Nível Dinâmico',
        format: GridView.DataTypes.Decimal,
      },
      {
        key: 'nrRebaixamento',
        title: 'Rebaixamento',
        format: GridView.DataTypes.Decimal,
      },

      {
        key: 'nrSeqPocEnsaio',
        type: GridView.ColumnTypes.Button,
        onClick: (e) => onSelectEnsaio(e),
        theme: Theme.Info,
        text: 'Selecionar',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Selecionar Ensaio',
        tooltipDirection: 'bottom',
      },
    ];

    return (
      <div>
        <div className='row mb-3'>
          <div className='display-8'>9 - Dados Hidráulicos</div>
        </div>
        <div className='row mb-3'>
          <div className='card'>
            <div className='card-body'>
              <div className='col'>
                <RadioButton
                  outline
                  size={BootstrapSizes.Large}
                  theme={Theme.Primary}
                  buttons={DadosHidraulicos}
                  selectedButton={dataDadosHidraulicos.flgDadosHidraulicos}
                  onChange={(flgDadosHidraulicos) =>
                    setDadosHidraulicos({
                      ...dataDadosHidraulicos,
                      flgDadosHidraulicos,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {dataDadosHidraulicos.flgDadosHidraulicos === 'EntradaAgua' && (
          <div className='row mb-3'>
            <div className='card'>
              <div className='card-body'>
                <div className='row mb-3'>
                  <div className='col'>
                    <div className='row mb-3'>
                      <div className='display-8'>Entrada de Água</div>
                    </div>
                    <div className='row'>
                      <div className='col'>
                        <div className='card'>
                          <div className='card-body'>
                            <div className='row mb-3'>
                              <div className='col-2'>
                                <Textbox
                                  id='profundidade'
                                  label='Profundidade (m)'
                                  text={dataEntradaAgua.nrProfundidade ?? 0}
                                  mask={MaskTypes.DecimalCustom}
                                  decimalPlaces={2}
                                  onChangedValue={(nrProfundidade) =>
                                    setDataEntradaAgua({
                                      ...dataEntradaAgua,
                                      nrProfundidade,
                                    })
                                  }
                                />
                              </div>
                              <div className='col'>
                                <Textbox
                                  label='Vazão'
                                  text={dataEntradaAgua.nrVazao ?? 0}
                                  mask={MaskTypes.DecimalCustom}
                                  decimalPlaces={2}
                                  onChangedValue={(nrVazao) =>
                                    setDataEntradaAgua({
                                      ...dataEntradaAgua,
                                      nrVazao,
                                    })
                                  }
                                />
                              </div>
                              <div className='col-auto mt-4'>
                                <RadioButton
                                  outline
                                  size={BootstrapSizes.Small}
                                  theme={Theme.Primary}
                                  buttons={Tipo}
                                  selectedButton={
                                    dataEntradaAgua.flgCaptadaIsolada
                                  }
                                  onChange={(flgCaptadaIsolada) => {
                                    setDataEntradaAgua({
                                      ...dataEntradaAgua,
                                      flgCaptadaIsolada,
                                      getCaptadaIsolada:
                                        flgCaptadaIsolada === 'C'
                                          ? 'Captada'
                                          : 'Isolada',
                                    });
                                  }}
                                />
                              </div>
                              <div className='col-auto mt-4'>
                                <RadioButton
                                  outline
                                  size={BootstrapSizes.Small}
                                  theme={Theme.Primary}
                                  buttons={Aquifero}
                                  selectedButton={
                                    dataEntradaAgua.flgPorosoFraturado
                                  }
                                  onChange={(flgPorosoFraturado) => {
                                    setDataEntradaAgua({
                                      ...dataEntradaAgua,
                                      flgPorosoFraturado,
                                      getPorosoFraturado:
                                        flgPorosoFraturado === 'P'
                                          ? 'Poroso'
                                          : 'Fraturado',
                                    });
                                  }}
                                />
                              </div>
                              <div className='col mt-3'>
                                <Button
                                  theme={Theme.Primary}
                                  template={Button.Templates.Button}
                                  text='Adicionar'
                                  onClick={onAdicionarEntradaAgua}
                                  className='width100'
                                />
                              </div>
                            </div>
                            <div className='row mb-3'>
                              <div className='col'>
                                <GridView
                                  ref={gridViewEntradaAgua}
                                  className='table-striped table-hover table-bordered table-sm'
                                  dataColumns={columnsEntradaAgua}
                                  showSelectSizes={false}
                                  showPagination={false}
                                />
                              </div>
                            </div>
                            <div className='row mb-2'>
                              <div className='col'>
                                <div className='row mb-3'>
                                  <div className='display-8'>
                                    Ensaio com compressor
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='card'>
                                    <div className='card-body'>
                                      <div className='row mb-3'>
                                        <div className='col'>
                                          <DatePicker
                                            label='Data de Ensaio'
                                            text={dadosHidraulicos.dtEnsaioComp}
                                            maxLength={10}
                                            mask={MaskTypes.Date}
                                            onChange={(dtEnsaioComp) => {
                                              dadosHidraulicos.dtEnsaioComp = dtEnsaioComp;
                                              setData({
                                                ...data,
                                                dadosHidraulicos: {
                                                  ...dadosHidraulicos,
                                                  dtEnsaioComp,
                                                },
                                              });
                                            }}
                                          />
                                        </div>
                                        <div className='col'>
                                          <Textbox
                                            label='Vazão (m³/h)'
                                            text={
                                              dataDadoHidraulico.nrVazaoEnsaioComp ??
                                              0
                                            }
                                            mask={MaskTypes.Decimal}
                                            readOnly
                                          />
                                        </div>
                                        <div className='col'>
                                          <Textbox
                                            label='Nível Estático (m)'
                                            text={
                                              data.dadosHidraulicos
                                                .nrNivelEstaticoComp
                                            }
                                            mask={MaskTypes.DecimalCustom}
                                            decimalPlaces={2}
                                            onChangedValue={(
                                              nrNivelEstaticoComp
                                            ) => {
                                              dadosHidraulicos.nrNivelEstaticoComp = nrNivelEstaticoComp;
                                              setData({
                                                ...data,
                                                dadosHidraulicos: {
                                                  ...dadosHidraulicos,
                                                  nrNivelEstaticoComp,
                                                },
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='row mb-3'>
                              <div className='col'>
                                <Textarea
                                  maxLength={250}
                                  label='Observações'
                                  text={data.noPocPocoEntradaAguaObs}
                                  onChangedValue={(noPocPocoEntradaAguaObs) => {
                                    data.noPocPocoEntradaAguaObs = noPocPocoEntradaAguaObs;
                                    setData({
                                      ...data,
                                      noPocPocoEntradaAguaObs,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {dataDadosHidraulicos.flgDadosHidraulicos === 'Ensaio' && (
          <div className='row mb-3'>
            <div className='card'>
              <div className='card-body'>
                <div className='row '>
                  <div className='col'>
                    <GridView
                      ref={gridViewEnsaio}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columnsEnsaio}
                      showSelectSizes={false}
                      showPagination={false}
                    />
                  </div>
                </div>

                <div className='row mb-3'>
                  <div className='col'>
                    <div className='card'>
                      <div className='card-body'>
                        <div className='row mb-3'>
                          <div className='display-8'>Dados do Ensaio</div>
                        </div>
                        <div className='row mb-3'>
                          <div className='col-3'>
                            <DatePicker
                              label='Data Início'
                              text={dataEnsaio.dtInicioEnsaio}
                              maxLength={10}
                              mask={MaskTypes.Date}
                              onChange={(dtInicioEnsaio) => {
                                setDataEnsaio({
                                  ...dataEnsaio,
                                  dtInicioEnsaio,
                                });
                              }}
                            />
                          </div>
                          <div className='col-3'>
                            <DatePicker
                              label='Data Fim'
                              text={dataEnsaio.dtFimEnsaio}
                              maxLength={10}
                              mask={MaskTypes.Date}
                              onChange={(dtFimEnsaio) => {
                                setDataEnsaio({
                                  ...dataEnsaio,
                                  dtFimEnsaio,
                                });
                              }}
                            />
                          </div>
                          <div className='col'>
                            <Textbox
                              label='Executor'
                              text={dataEnsaio.noExecutor}
                              maxLength={100}
                              onChangedValue={(noExecutor) => {
                                dataEnsaio.noExecutor = noExecutor;
                              }}
                            />
                          </div>
                        </div>
                        <div className='row mb-3'>
                          <div className='col'>
                            <Autocomplete
                              label='Técnica Medida'
                              searchDataSource={onSearchTecnicaMedida}
                              selectedItem={dataEnsaio.tecnicasMedida}
                              onSelectItem={(tecnicasMedida) =>
                                setDataEnsaio({
                                  ...dataEnsaio,
                                  tecnicasMedida,
                                  noTecnicaMedida:
                                    tecnicasMedida.noTecnicaMedida,
                                  nrSeqTecnicaMedida:
                                    tecnicasMedida.nrSeqTecnicaMedida,
                                })
                              }
                              dataSourceTextProperty='noTecnicaMedida'
                            />
                          </div>
                          <div className='col'>
                            <Autocomplete
                              label='Tipo Ensaio'
                              searchDataSource={onSearchTipoEnsaio}
                              selectedItem={dataEnsaio.tiposEnsaio}
                              onSelectItem={(tiposEnsaio) =>
                                setDataEnsaio({
                                  ...dataEnsaio,
                                  tiposEnsaio,
                                  flgTipoEnsaio: tiposEnsaio.flgTipoEnsaio,
                                  noTipoEnsaio: tiposEnsaio.noTipoEnsaio,
                                  nrSeqTipoEnsaio: tiposEnsaio.nrSeqTipoEnsaio,
                                })
                              }
                              dataSourceTextProperty='noTipoEnsaio'
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col mb-3'>
                            <Textarea
                              maxLength={250}
                              label='Observação'
                              text={dataEnsaio.noObservacaoEnsaio}
                              onChangedValue={(noObservacaoEnsaio) => {
                                dataEnsaio.noObservacaoEnsaio = noObservacaoEnsaio;
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {dataEnsaio.nrSeqPocEnsaio > 0 && (
                  <>
                    <div className='card'>
                      <div className='card-body'>
                        <div className='row mb-3'>
                          <div className='row'>
                            <div className='col'>
                              <div className='row mb-3'>
                                <div className='display-8'>Bombeamento</div>
                              </div>
                              <div className='row'>
                                <div className='col'>
                                  <div className='row'>
                                    <div className='col'>
                                      <GridView
                                        ref={gridViewBombeamento}
                                        className='table-striped table-hover table-bordered table-sm'
                                        dataColumns={columnsBombeamento}
                                        showSelectSizes={false}
                                        showPagination={false}
                                        offlineData
                                      />
                                    </div>
                                  </div>

                                  <div className='row mb-3'>
                                    <div className='col'>
                                      <Button
                                        theme={Theme.Danger}
                                        template={Button.Templates.Button}
                                        text='Remover tempos sem medição'
                                        onClick={onRemoveBombeamentoTimesEmpty}
                                      />
                                    </div>
                                  </div>

                                  <div className='row mb-3'>
                                    <div className='col-3'>
                                      <Textbox
                                        label='Tempo'
                                        mask={MaskTypes.TimeHM}
                                        text={dataBombeamento.nrTempoStr ?? ''}
                                        onChangedValue={(nrTempoStr) => {
                                          dataBombeamento.nrTempoStr = nrTempoStr;
                                        }}
                                      />
                                    </div>
                                    <div className='col-3'>
                                      <Textbox
                                        label='Nível'
                                        text={dataBombeamento.nrNivel ?? 0}
                                        mask={MaskTypes.DecimalCustom}
                                        decimalPlaces={2}
                                        onChangedValue={(nrNivel) => {
                                          dataBombeamento.nrNivel = nrNivel;
                                        }}
                                      />
                                    </div>

                                    <div className='col-3'>
                                      <Textbox
                                        label='Vazão'
                                        text={dataBombeamento.nrVazao ?? 0}
                                        mask={MaskTypes.DecimalCustom}
                                        decimalPlaces={2}
                                        onChangedValue={(nrVazao) => {
                                          dataBombeamento.nrVazao = nrVazao;
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className='row mb-3'>
                                    <div
                                      className='col'
                                      style={{ textAlign: 'center' }}
                                    >
                                      <Button
                                        theme={Theme.Primary}
                                        template={Button.Templates.Button}
                                        text='Adicionar'
                                        onClick={onAdicionarBombeamento}
                                        className='width80'
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='col-4'>
                              <div className='row mb-3'>
                                <div className='display-8'>Recuperação</div>
                              </div>
                              <div className='row mb-3'>
                                <div className='col'>
                                  <div className='col'>
                                    <GridView
                                      ref={gridViewRecuperacao}
                                      className='table-striped table-hover table-bordered table-sm'
                                      dataColumns={columnsRecuperacao}
                                      showSelectSizes={false}
                                      showPagination={false}
                                    />
                                  </div>

                                  <div className='row mb-3'>
                                    <div className='col'>
                                      <Button
                                        theme={Theme.Danger}
                                        template={Button.Templates.Button}
                                        text='Remover tempos sem medição'
                                        onClick={onRemoveRecuperacaoTimesEmpty}
                                      />
                                    </div>
                                  </div>

                                  <div className='row mb-3'>
                                    <div className='col'>
                                      <Textbox
                                        label='Tempo'
                                        mask={MaskTypes.TimeHM}
                                        text={dataRecuperacao.nrTempoStr ?? ''}
                                        onChangedValue={(nrTempoStr) => {
                                          dataRecuperacao.nrTempoStr = nrTempoStr;
                                        }}
                                      />
                                    </div>
                                    <div className='col'>
                                      <Textbox
                                        label='Nível'
                                        text={dataRecuperacao.nrNivel ?? 0}
                                        mask={MaskTypes.DecimalCustom}
                                        decimalPlaces={2}
                                        onChangedValue={(nrNivel) => {
                                          dataRecuperacao.nrNivel = nrNivel;
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className='row mb-3'>
                                    <div className='col'>
                                      <Button
                                        theme={Theme.Primary}
                                        template={Button.Templates.Button}
                                        text='Adicionar'
                                        onClick={onAdicionarRecuperacao}
                                        className='width100'
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className='row mb-3'>
                <div className='col mt-3' style={{ textAlign: 'center' }}>
                  <Button
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    text='Gravar Ensaio'
                    onClick={onAdicionarEnsaio}
                    className='width80'
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {dataDadosHidraulicos.flgDadosHidraulicos === 'DadosFinais' && (
          <div className='row mb-3'>
            <div className='card'>
              <div className='card-body'>
                <div className='row mb-3'>
                  <div className='display-8'>Ensaio com bomba</div>
                  <div className='row mb-3'>
                    <div className='col'>
                      <GridView
                        ref={gridViewDadoFinalEnsaio}
                        className='table-striped table-hover table-bordered table-sm'
                        dataColumns={columnsDadoFinalEnsaio}
                        showSelectSizes={false}
                        showPagination={false}
                      />
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col'>
                      <div className='row mb-3'>
                        <div className='display-8'>Cálculo Hidráulico</div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col'>
                          <div className='card'>
                            <div className='card-body'>
                              <div className='row mb-3'>
                                <div className='col-2'>
                                  <Textbox
                                    label='Cap. Campo(m³/h/m)'
                                    text={
                                      dataEnsaioDadosHidraulicos.nrCapacidadeCampo
                                    }
                                    mask={MaskTypes.DecimalCustom}
                                    decimalPlaces={6}
                                    onChangedValue={(nrCapacidadeCampo) => {
                                      setDataEnsaioDadosHidraulicos({
                                        ...dataEnsaioDadosHidraulicos,
                                        nrCapacidadeCampo,
                                      });
                                    }}
                                    readOnly
                                  />
                                </div>
                                <div className='col-2'>
                                  <Textbox
                                    label='Trasmis. selec. (m²/h)'
                                    text={
                                      dataEnsaioDadosHidraulicos.nrTransmissividade
                                    }
                                    mask={MaskTypes.DecimalCustom}
                                    decimalPlaces={6}
                                    onChangedValue={(nrTransmissividade) => {
                                      setDataEnsaioDadosHidraulicos({
                                        ...dataEnsaioDadosHidraulicos,
                                        nrTransmissividade,
                                      });
                                    }}
                                    onBlur={() => {
                                      saveField();
                                    }}
                                  />
                                </div>

                                <div className='col'>
                                  <Textbox
                                    label='Método'
                                    text={
                                      dataEnsaioDadosHidraulicos.noMetodoRecuperacao ??
                                      ''
                                    }
                                    onChangedValue={(noMetodoRecuperacao) => {
                                      setDataEnsaioDadosHidraulicos({
                                        ...dataEnsaioDadosHidraulicos,
                                        noMetodoRecuperacao,
                                      });
                                    }}
                                    onBlur={() => {
                                      saveField();
                                    }}
                                  />
                                </div>
                              </div>

                              <div className='row mb-3'>
                                <div className='col-2'>
                                  <Textbox
                                    label='Cap. Calc. (m³/h/m)'
                                    text={
                                      dataEnsaioDadosHidraulicos.nrCapacidadeCalculada
                                    }
                                    mask={MaskTypes.DecimalCustom}
                                    decimalPlaces={3}
                                    onChangedValue={(nrCapacidadeCalculada) => {
                                      setDataEnsaioDadosHidraulicos({
                                        ...dataEnsaioDadosHidraulicos,
                                        nrCapacidadeCalculada,
                                      });
                                    }}
                                    onBlur={() => {
                                      saveField();
                                    }}
                                  />
                                </div>
                                <div className='col-2'>
                                  <Textbox
                                    label='VazMáx(m³/h)'
                                    text={
                                      dataEnsaioDadosHidraulicos.nrVazaoRecomendada
                                    }
                                    mask={MaskTypes.DecimalCustom}
                                    decimalPlaces={3}
                                    onChangedValue={(nrVazaoRecomendada) => {
                                      setDataEnsaioDadosHidraulicos({
                                        ...dataEnsaioDadosHidraulicos,
                                        nrVazaoRecomendada,
                                      });
                                    }}
                                    onBlur={() => {
                                      saveField();
                                    }}
                                  />
                                </div>
                                <div className='col-2'>
                                  <Textbox
                                    label='Nív.Dinâm(m)'
                                    text={
                                      dataEnsaioDadosHidraulicos.nrNivelRecomendado
                                    }
                                    mask={MaskTypes.DecimalCustom}
                                    decimalPlaces={3}
                                    onChangedValue={(nrNivelRecomendado) => {
                                      setDataEnsaioDadosHidraulicos({
                                        ...dataEnsaioDadosHidraulicos,
                                        nrNivelRecomendado,
                                      });
                                    }}
                                    onBlur={() => {
                                      saveField();
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col'>
                          <div className='row mb-3'>
                            <div className='display-8'>Projeto Bombeamento</div>
                          </div>
                          <div className='row mb-3'>
                            <div className='col'>
                              <div className='card'>
                                <div className='card-body'>
                                  <div className='row mb-3'>
                                    <div className='col-2'>
                                      <Textbox
                                        label='Regime bomb. (h/dia)'
                                        text={
                                          dataEnsaioDadosHidraulicos.nrRegimeBombeamentoStr ??
                                          ''
                                        }
                                        mask={MaskTypes.TimeHM}
                                        onChangedValue={(
                                          nrRegimeBombeamentoStr
                                        ) => {
                                          setDataEnsaioDadosHidraulicos({
                                            ...dataEnsaioDadosHidraulicos,
                                            nrRegimeBombeamentoStr,
                                            nrProducaoBombeamento: totalProdutividade(
                                              nrRegimeBombeamentoStr,
                                              dataEnsaioDadosHidraulicos.nrVazaoBombeamento
                                            ),
                                          });
                                        }}
                                        onBlur={() => {
                                          saveField();
                                        }}
                                      />
                                    </div>
                                    <div className='col-2'>
                                      <Textbox
                                        label='Bombeio'
                                        text={
                                          dataEnsaioDadosHidraulicos.nrBombeioStr ??
                                          ''
                                        }
                                        mask={MaskTypes.TimeHM}
                                        onChangedValue={(nrBombeioStr) => {
                                          setDataEnsaioDadosHidraulicos({
                                            ...dataEnsaioDadosHidraulicos,
                                            nrBombeioStr,
                                          });
                                        }}
                                        onBlur={() => {
                                          saveField();
                                        }}
                                      />
                                    </div>
                                    <div className='col-2'>
                                      <Textbox
                                        label='Descanso'
                                        text={
                                          dataEnsaioDadosHidraulicos.nrDescansoStr ??
                                          ''
                                        }
                                        mask={MaskTypes.TimeHM}
                                        onChangedValue={(nrDescansoStr) => {
                                          setDataEnsaioDadosHidraulicos({
                                            ...dataEnsaioDadosHidraulicos,
                                            nrDescansoStr,
                                          });
                                        }}
                                        onBlur={() => {
                                          saveField();
                                        }}
                                      />
                                    </div>
                                    <div className='col mt-3'>
                                      <Button
                                        theme={Theme.Primary}
                                        template={Button.Templates.Button}
                                        text='Calcular'
                                        onClick={onCalcularProjetoBombeamento}
                                      />
                                    </div>
                                  </div>
                                  <div className='row mb-3'>
                                    <div className='col-2'>
                                      <Textbox
                                        label='Vazão (m³/h)'
                                        text={
                                          dataEnsaioDadosHidraulicos.nrVazaoBombeamento
                                        }
                                        mask={MaskTypes.DecimalCustom}
                                        decimalPlaces={3}
                                        onChangedValue={(
                                          nrVazaoBombeamento
                                        ) => {
                                          setDataEnsaioDadosHidraulicos({
                                            ...dataEnsaioDadosHidraulicos,
                                            nrVazaoBombeamento,
                                            nrProducaoBombeamento: totalProdutividade(
                                              dataEnsaioDadosHidraulicos.nrRegimeBombeamentoStr,
                                              nrVazaoBombeamento
                                            ),
                                          });
                                        }}
                                        onBlur={() => {
                                          saveField();
                                        }}
                                      />
                                    </div>
                                    <div className='col-2'>
                                      <Textbox
                                        label='Nív. Dinâm.(m)'
                                        text={
                                          dataEnsaioDadosHidraulicos.nrNivelDinamicoBombeamento
                                        }
                                        mask={MaskTypes.DecimalCustom}
                                        decimalPlaces={3}
                                        onChangedValue={(
                                          nrNivelDinamicoBombeamento
                                        ) => {
                                          setDataEnsaioDadosHidraulicos({
                                            ...dataEnsaioDadosHidraulicos,
                                            nrNivelDinamicoBombeamento,
                                          });
                                        }}
                                        onBlur={() => {
                                          saveField();
                                        }}
                                      />
                                    </div>
                                    <div className='col-2'>
                                      <Textbox
                                        label='Prod. (m³/dia)'
                                        text={
                                          dataEnsaioDadosHidraulicos.nrProducaoBombeamento
                                        }
                                        mask={MaskTypes.DecimalCustom}
                                        decimalPlaces={3}
                                        onChangedValue={(
                                          nrProducaoBombeamento
                                        ) => {
                                          setDataEnsaioDadosHidraulicos({
                                            ...dataEnsaioDadosHidraulicos,
                                            nrProducaoBombeamento,
                                          });
                                        }}
                                        readOnly
                                      />
                                    </div>

                                    <div className='col-3'>
                                      <Switch
                                        formControl
                                        label='Bombeamento Recomendado'
                                        visible={visible}
                                        checked={
                                          dataEnsaioDadosHidraulicos.flgBombeamentoRecomendado
                                        }
                                        onChange={(
                                          flgBombeamentoRecomendado
                                        ) => {
                                          const obj = {
                                            ...dataEnsaioDadosHidraulicos,
                                            nrSeqPocEnsaio:
                                              dataEnsaioSelecionado.nrSeqPocEnsaio,
                                            flgBombeamentoRecomendado,
                                          };
                                          setDataEnsaioDadosHidraulicos(obj);
                                          saveField(obj);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <ModalEquipamento
          show={showModalEquipamento}
          nrSeqPocEnsaio={parametroModal}
          onClose={() => setModalEquipamento(false)}
          onSetLoading={(flgLoading) => onSetLoading(flgLoading)}
        />
      </div>
    );
  }
);
export default CadastroDadoHidraulico;
