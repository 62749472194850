/* eslint-disable no-unused-vars */
import { SearchAniversariantes } from 'core/services/FIN';
import {
  getEmpresaDefaultUsuario,
  getEmpresasUsuario,
} from 'core/services/SEG';
import React, { forwardRef, useEffect, useState, useRef } from 'react';
import {
  Autocomplete,
  Button,
  DatePicker,
  GridView,
  Panel,
} from 'ui/components';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';

const today = new Date();

const AniversariantesStep = forwardRef(
  ({ data, setData, setLoading, onSetMessage }) => {
    const gridViewAniversariantes = useRef();

    const [aniversariantes, setAniversariantes] = useState([]);
    const [filters, setFilters] = useState({
      dtInicio: today.toJSON().slice(0, 10),
      dtFim: today.toJSON().slice(0, 10),
      empresa: data.empresa,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    const getAniversariantes = async () => {
      const { clientes } = await SearchAniversariantes(filters);

      const list = clientes.map((item) => {
        const { nrSeqPessoa, noPessoa, noImagemBase64 } = item;

        const dtNascimento = new Date(item.dtNascimento);

        const age = today.getFullYear() - dtNascimento.getFullYear();

        return { nrSeqPessoa, noPessoa, age, dtNascimento, noImagemBase64 };
      });

      gridViewAniversariantes?.current?.setDataSource(list);

      setAniversariantes(list);
    };

    useEffect(async () => {
      setLoading(true);

      // await getGraficoData();

      await getAniversariantes();

      setLoading(false);
    }, []);

    const onSearchEmpresa = async (e) => {
      const { status, message: msg, empresas } = await getEmpresasUsuario({
        noPessoa: e,
      });

      onSetMessage(status, msg);

      return empresas;
    };

    useEffect(async () => {
      if (!data.nrSeqEmpresa) {
        const { empresas } = await getEmpresaDefaultUsuario();

        setFilters({
          ...filters,
          empresa: empresas[0],
          nrSeqEmpresa: empresas[0].nrSeqEmpresa,
        });

        setData({
          ...data,
          empresa: empresas[0],
          nrSeqEmpresa: empresas[0].nrSeqEmpresa,
        });
      }
    }, []);

    return (
      <>
        <div className='row mb-3'>
          <div className='col'>
            <Autocomplete
              label='Empresa'
              searchDataSource={onSearchEmpresa}
              selectedItem={filters.empresa}
              onSelectItem={(empresa) => {
                setFilters({
                  ...filters,
                  empresa,
                  nrSeqEmpresa: empresa.nrSeqEmpresa,
                });

                setData({
                  ...data,
                  empresa,
                  nrSeqEmpresa: empresa.nrSeqEmpresa,
                });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <Panel>
              <Panel.Header title='Aniversariantes' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row mb-4'>
                  <div className='col-2'>
                    <DatePicker
                      label='Data de Aniversario De'
                      text={filters.dtInicio}
                      onChange={(dtInicio) =>
                        setFilters({ ...filters, dtInicio })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Data de Aniversario Até'
                      text={filters.dtFim}
                      onChange={(dtFim) => setFilters({ ...filters, dtFim })}
                    />
                  </div>
                  <div className='col-3 d-flex align-items-end'>
                    <Button
                      text='Buscar Novo Periodo'
                      onClick={async () => {
                        await getAniversariantes();
                      }}
                      theme={Theme.Primary}
                      template={Button.Templates.Default}
                      icon='search'
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  {aniversariantes.map((item, index) => (
                    <div className='col-2 mb-3'>
                      <div className='card h-100 justify-content-between align-items-center bg-info border border-warning'>
                        <h6
                          className='card-text my-1'
                          style={{ textAlign: 'center' }}
                        >
                          {item.noPessoa}
                        </h6>
                        <div className='card-body '>
                          <img
                            className='card-img rounded img-fluid'
                            src={item.noImagemBase64}
                            alt='Sem Imagem'
                          />
                          <div className='card-text d-flex justify-content-center align-items-center'>
                            <span className='card-text pt-1 '>
                              Faz {item.age} anos em{' '}
                              {String(item.dtNascimento.getDate()).padStart(
                                2,
                                '0'
                              )}
                              /
                              {String(
                                item.dtNascimento.getMonth() + 1
                              ).padStart(2, '0')}
                            </span>
                          </div>
                        </div>
                        <div className='card-footer w-100'>
                          <Button
                            icon='envelope'
                            outline
                            className='p-0 m-0 h-100 w-50  '
                            theme={Theme.Success}
                            size={BootstrapSizes.Large}
                          />
                          <Button
                            icon='sms'
                            size={BootstrapSizes.Large}
                            outline
                            className='p-0 m-0 h-100 w-50'
                            theme={Theme.Success}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      </>
    );
  }
);

export default AniversariantesStep;
