import React, { useEffect } from 'react';
import { Panel, Switch, Autocomplete } from 'ui/components';
// @ts-expect-error
import { Theme } from 'ui/Helpers/enums.ts';
import { getCondicaoPagamentoAutoComplete } from 'core/services/FIN/condicaoPagamento';
import { getPlanoDeContaAutoComplete } from 'core/services/FIN/planoDeConta';
import { getFornecedorAtividadeAutoComplete } from 'core/services/FIN/fornecedorAtividade';

interface Props {
  fornecedorData: any;
  setFornecedorData: (object: any) => void;
}

const RemetentePanel = ({ fornecedorData, setFornecedorData }: Props) => {
  useEffect(() => {
    const objFornecedor = { ...fornecedorData };

    setFornecedorData(objFornecedor);
  }, [fornecedorData.nrSeqPessoaFor]);

  const onSearchCondicaoPagamento = async (e: any) => {
    const { condicoesPagamento } = await getCondicaoPagamentoAutoComplete({
      noCondicaoPagamento: e,
    });
    return condicoesPagamento;
  };

  const onSearchPlanoDeConta = async (e: any) => {
    const { planodecontas } = await getPlanoDeContaAutoComplete({
      noPlanoDeConta: e,
    });
    return planodecontas;
  };

  const onSearchAtividadeFornecedor = async (e: any) => {
    const { fornecedorAtividades } = await getFornecedorAtividadeAutoComplete({
      noFornecedorAtividade: e,
    });
    return fornecedorAtividades;
  };

  return (
    <div className='col mt-3'>
      {/* @ts-expect-error */}
      <Panel>
        {/* @ts-expect-error */}
        <Panel.Header title='Fornecedor' theme={Theme.Primary} />

        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-1 mb-3'>
              <Switch
                formControl
                label='Ativo'
                checked={fornecedorData.flgAtivo}
                onChange={(flgAtivo) =>
                  setFornecedorData({ ...fornecedorData, flgAtivo })
                }
              />
            </div>
            <div className='col-3 mb-2'>
              <Autocomplete
                label='Atividade Fornecedor'
                id='ddlAtividadeFornecedor'
                searchDataSource={onSearchAtividadeFornecedor}
                selectedItem={fornecedorData.fornecedorAtividade}
                onSelectItem={(fornecedorAtividade) => {
                  setFornecedorData({
                    ...fornecedorData,
                    fornecedorAtividade,
                    nrSeqFornecedorAtividade:
                      fornecedorAtividade.nrSeqFornecedorAtividade,
                    noFornecedorAtividade:
                      fornecedorAtividade.noFornecedorAtividade,
                  });
                }}
                dataSourceTextProperty='noFornecedorAtividade'
              />
            </div>

            <div className='col-4 mb-3'>
              <Autocomplete
                label='Condição Pagamento'
                id='ddlCondicaoPagamento'
                searchDataSource={onSearchCondicaoPagamento}
                selectedItem={fornecedorData.condicaoPagamento}
                onSelectItem={(condicaoPagamento) => {
                  setFornecedorData({
                    ...fornecedorData,
                    condicaoPagamento,
                    nrSeqCondicaoPagamento:
                      condicaoPagamento.nrSeqCondicaoPagamento,
                  });
                }}
                dataSourceTextProperty='noCondicaoPagamento'
              />
            </div>
            <div className='col-4 mb-3'>
              <Autocomplete
                label='Plano De Conta'
                id='ddlPlanoDeConta'
                searchDataSource={onSearchPlanoDeConta}
                selectedItem={fornecedorData.planoDeConta}
                onSelectItem={(planoDeConta) => {
                  setFornecedorData({
                    ...fornecedorData,
                    planoDeConta,
                    nrSeqPlanoDeConta: planoDeConta.nrSeqPlanoDeConta,
                  });
                }}
                dataSourceTextProperty='noPlanoDeConta'
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </div>
  );
};

export default RemetentePanel;
