export default class PocPocoPocTubulacaoAducao {
  constructor(jsonObject = {}) {
    this.nrSeqPocPocoPocTubulacaoAducao =
      jsonObject.nrSeqPocPocoPocTubulacaoAducao;
    this.nrSeqPocPoco = jsonObject.nrSeqPocPoco;
    this.dtInstalacao = jsonObject.dtInstalacao;
    this.noDiametroTubulacao = jsonObject.noDiametroTubulacao;
    this.noDiametroTubulacaomm = jsonObject.noDiametroTubulacaomm;
    this.nrSeqPocTubulacaoAducao = jsonObject.nrSeqPocTubulacaoAducao;
    this.nrMetragem = jsonObject.nrMetragem;
    this.noFornecimento = jsonObject.noFornecimento;
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
