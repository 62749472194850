import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Textbox } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getChecklistGrupo,
  saveChecklistGrupo,
  deleteChecklistGrupo,
} from 'core/services/FRO/checklistGrupo';

export default function ChecklistGrupoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const objChecklistGrupo = await getChecklistGrupo(registryKey);
      setData(objChecklistGrupo);
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    data.status = data.nrSeqChecklistGrupo > 0 ? 'Alterar' : 'Inserir';

    setLoading(true);
    const { status, message: msg } = await saveChecklistGrupo(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteChecklistGrupo(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de ChecklistGrupo'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqChecklistGrupo > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-2 mb-3'>
          <Textbox
            readOnly
            label='Codigo'
            text={data.cdGrupo}
            mask={MaskTypes.Integer}
            onChangedValue={(cdGrupo) => setData({ ...data, cdGrupo })}
          />
        </div>
        <div className='col-5 mb-3'>
          <Textbox
            required
            maxLength={200}
            label='Nome'
            text={data.noGrupo}
            onChangedValue={(noGrupo) => setData({ ...data, noGrupo })}
          />
        </div>
        <div className='col-2 mb-3'>
          <Textbox
            required
            label='Nr. Ordem'
            text={data.nrOrdem}
            mask={MaskTypes.Integer}
            onChangedValue={(nrOrdem) => setData({ ...data, nrOrdem })}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
