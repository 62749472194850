import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  BootstrapSizes,
  JustifyContent,
  MimeTypes,
  PageTypes,
  ResponseStatus,
  Theme,
  toBase64,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Switch,
  Textbox,
  DatePicker,
  Panel,
  FileUpload,
  GridView,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  geraSequenciaTipoReceita,
  getTipoReceitaAcertoList,
} from 'core/services/FIN/tipoReceitaAcerto';
import {
  getClienteAutoComplete,
  getFormaPagamentoAutoComplete,
} from 'core/services/FIN';

import {
  getRemetenteAutoComplete,
  getDestinatarioAutoComplete,
} from 'core/services/FRO/remetente_Destinatario';
import {
  getAcertoMotoristaReceita,
  saveAcertoMotoristaReceita,
  deleteAcertoMotoristaReceita,
} from 'core/services/FIN/acertoMotoristaReceita';
import { getCidadeEstadoAutoComplete } from 'core/services/SEG';
import { setTransaction } from 'core/services/api';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';

export default function AcertoMotoristaReceitaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, onSetMessage] = useState(null);
  const [importedFile, setImportedFile] = useState([]);
  const [liberaDocumento, setLiberaDocumento] = useState({});
  const gridViewDocumentos = useRef();
  const [obs, SetObs] = useState([]);

  const setMessage = (status, msg) => {
    if (msg)
      onSetMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const obj = await getAcertoMotoristaReceita(registryKey);

      setData(obj);
      if (gridViewDocumentos && gridViewDocumentos.current)
        gridViewDocumentos.current.setDataSource(obj.documentos);

      setLoading(false);
    } else {
      setData({ peso: 0, tarifa: 0, vlrReceita: 0 });

      if (gridViewDocumentos && gridViewDocumentos.current)
        gridViewDocumentos.current.setDataSource([]);
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  useEffect(() => {
    if (data.tarifa > 0) {
      if (data.peso > 0) {
        const vlrReceitaTotal = (data.peso / 1000) * data.tarifa;
        setData({ ...data, vlrReceita: vlrReceitaTotal });
      }
    }
  }, [data.tarifa]);

  useEffect(() => {
    if (data.tarifa > 0) {
      if (data.peso > 0) {
        const vlrReceitaTotal = (data.peso / 1000) * data.tarifa;
        setData({ ...data, vlrReceita: vlrReceitaTotal });
      }
    }
  }, [data.peso]);

  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return clientes;
  };
  const onSearchCidadeOrigem = async (e) => {
    const { status, message: msg, cidades } = await getCidadeEstadoAutoComplete(
      {
        noCidade: e,
      }
    );
    if (msg) onSetMessage(status, msg);
    return cidades;
  };
  const onSearchCidadeDestino = async (e) => {
    const { status, message: msg, cidades } = await getCidadeEstadoAutoComplete(
      {
        noCidade: e,
      }
    );
    if (msg) onSetMessage(status, msg);
    return cidades;
  };
  const onSearchTipoReceita = async (e) => {
    const {
      status,
      message: msg,
      data: tipoReceita,
    } = await getTipoReceitaAcertoList({
      noTipoReceita: e,
    });
    if (msg) onSetMessage(status, msg);
    return tipoReceita;
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const onAutoCompleteDestinatario = async (e) => {
    const {
      status,
      message: msg,
      data: destinatarios,
    } = await getDestinatarioAutoComplete({
      flgTodos: true,
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return destinatarios;
  };

  const onAutoCompleteRemetente = async (e) => {
    const {
      status,
      message: msg,
      data: remetentes,
    } = await getRemetenteAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return remetentes;
  };

  const onNew = async () => {
    setData({ peso: 0, tarifa: 0, vlrReceita: 0 });
    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource([]);
  };

  const save = async () => {
    setLoading(true);

    setTransaction(transaction ? transaction.nrSeqTransacao : 0);

    const documentos = gridViewDocumentos.current
      ? gridViewDocumentos.current.getDataSource()
      : [];

    const obj = {
      ...data,
      nrSeqTransacao: transaction.nrSeqTransacao,
      documentos,
    };

    const { status, message: msg } = await saveAcertoMotoristaReceita(obj);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      onNew();
    }

    onSetMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteAcertoMotoristaReceita(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onClickDownload = async (e) => {
    const linkSource = `data:image/png;base64, ${
      e.noImagemBase64 ?? e.noImagem
    }`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = e.noArquivo;
    downloadLink.click();
  };

  const onRemoveDocumento = async (selectedValue, datasource) => {
    let documentos = [];
    if (selectedValue.nrSeqAcertoMotoristaReceita === undefined) {
      documentos = datasource.filter(
        (Documento) => Documento !== selectedValue
      );
    } else {
      documentos = datasource.filter(
        (Documento) => Documento !== selectedValue
      );
      const dataDocumento = datasource.filter(
        (Documento) => Documento === selectedValue
      );
      dataDocumento[0].status = GridView.EnumStatus.Remover;
      documentos.push(dataDocumento[0]);
    }
    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource(documentos);
  };

  const columnsAnexos = [
    {
      key: 'nrSeqAcertoMotoristaReceita',
      visible: false,
    },
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'noObs',
      title: 'Observações',
    },
    {
      key: 'nrSeqAcertoMotoristaReceita',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },

    {
      key: 'nrSeqAcertoMotoristaReceita',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  useEffect(async () => {
    if (importedFile.length > 0) {
      let noImagemBase64 = await toBase64(importedFile[0]);
      noImagemBase64 = String(noImagemBase64).substring(
        String(noImagemBase64).indexOf(',') + 1
      );

      const documento = {
        noArquivo: importedFile[0].name,
        noTipoArquivo: importedFile[0].type,
        noTamanho: String(importedFile[0].size),
        flgExibeDownload: false,
        nrSeqTituloPagar: data.nrSeqTituloPagar,
        tipoDocumento: data.tipoDocumento,
        noObs: obs.noObs,
        noImagemBase64,
        status: GridView.EnumStatus.Inserir,
      };
      let documentos = [];
      if (gridViewDocumentos && gridViewDocumentos.current)
        documentos = gridViewDocumentos.current.getDataSource() ?? [];

      documentos.push(documento);

      if (gridViewDocumentos && gridViewDocumentos.current)
        gridViewDocumentos.current.setDataSource(documentos);

      setImportedFile({});
      SetObs({});
    }
  }, [importedFile]);

  useEffect(() => {
    (async function func() {
      if (data !== null && data !== undefined) {
        if (data.nrSeqTipoReceitaAcerto > 0) {
          if (
            data.tipoReceitaAcerto !== null &&
            data.tipoReceitaAcerto !== undefined
          ) {
            if (data.tipoReceitaAcerto.flgGeraSequencia) {
              const tipoReceita = await geraSequenciaTipoReceita(
                data.tipoReceitaAcerto
              );

              setData({
                ...data,
                nrDocumento: tipoReceita.nrDocumentoSequencia,
              });

              setLiberaDocumento({ flgLiberaDocumento: true });
            } else {
              setLiberaDocumento({ flgLiberaDocumento: false });
            }
          } else {
            setLiberaDocumento({ flgLiberaDocumento: false });
          }
        } else {
          setLiberaDocumento({ flgLiberaDocumento: false });
        }
      } else {
        setLiberaDocumento({ flgLiberaDocumento: false });
      }
    })();
  }, [data.nrSeqTipoReceitaAcerto]);

  const onSearchVeiculo = async (e) => {
    const { message: msg, veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
    });
    if (msg);
    return veiculos;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de AcertoMotoristaReceita'
      loading={loading}
      transaction={transaction}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      onDelete={data?.nrSeqAcertoMotoristaReceita > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <div className='row'>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='A Vista'
            checked={data.flgAvista}
            onChange={(flgAvista) => setData({ ...data, flgAvista })}
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Gera Comissão'
            checked={data.flgGeraComissao}
            onChange={(flgGeraComissao) =>
              setData({ ...data, flgGeraComissao })
            }
          />
        </div>
      </div>
      <div className='row'>
        <Panel>
          <Panel.Header
            title='Dados Veiculo '
            theme={Theme.Light}
            align={JustifyContent.Start}
          />

          <Panel.Body>
            <div className='row'>
              <div className='col-5 mb-3'>
                <Autocomplete
                  label='Placa'
                  required
                  searchDataSource={onSearchVeiculo}
                  selectedItem={data.veiculo}
                  onSelectItem={(veiculo) => {
                    setData({
                      ...data,
                      veiculo,
                      nrSeqVeiculo: veiculo.nrSeqVeiculo,
                    });
                  }}
                  dataSourceTextProperty='noVeiculo'
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>

      <div className='row'>
        <Panel>
          <Panel.Header
            title='Dados Cliente'
            theme={Theme.Light}
            align={JustifyContent.Start}
          />

          <Panel.Body>
            <div className='row'>
              <div className='col-4  mb-3'>
                <Autocomplete
                  label='Cliente'
                  required
                  searchDataSource={onSearchCliente}
                  selectedItem={data.cliente}
                  onSelectItem={(cliente) => {
                    setData({
                      ...data,
                      cliente,
                      nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-2  mb-3'>
                <Textbox
                  label='Referência'
                  readOnly
                  text={data.referenciaCliente}
                  onChangedValue={(referenciaCliente) =>
                    setData({ ...data, referenciaCliente })
                  }
                />
              </div>
              <div className='col-2  mb-3'>
                <Textbox
                  label='Contato'
                  readOnly
                  text={data.contatoCliente}
                  onChangedValue={(contatoCliente) =>
                    setData({ ...data, contatoCliente })
                  }
                />
              </div>
              <div className='col-2  mb-3'>
                <Textbox
                  label='Número'
                  text={data.numeroCliente}
                  onChangedValue={(numeroCliente) =>
                    setData({ ...data, numeroCliente })
                  }
                />
              </div>
              <div className='col-2  mb-3'>
                <Textbox
                  label='Tipo Documento'
                  text={data.tipoDocumentoCliente}
                  onChangedValue={(tipoDocumentoCliente) =>
                    setData({
                      ...data,
                      tipoDocumentoCliente,
                    })
                  }
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row'>
        <Panel>
          <Panel.Header
            title='Dados Carga'
            theme={Theme.Light}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row'>
              <div className='col-4  mb-3'>
                <Textbox
                  label='Peso'
                  required
                  text={data.peso}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(peso) => setData({ ...data, peso })}
                />
              </div>
              <div className='col-4  mb-3'>
                <Autocomplete
                  label='Cidade Origem'
                  required
                  searchDataSource={onSearchCidadeOrigem}
                  selectedItem={data.enderecoRemetente}
                  onSelectItem={(enderecoRemetente) => {
                    setData({
                      ...data,
                      enderecoRemetente,
                      nrSeqCidadeOri: enderecoRemetente.nrSeqCidade,
                    });
                  }}
                  dataSourceTextProperty='noCidadeEstado'
                />
              </div>
              <div className='col-4  mb-3'>
                <Autocomplete
                  label='Cidade Destino'
                  required
                  searchDataSource={onSearchCidadeDestino}
                  selectedItem={data.enderecoDestino}
                  onSelectItem={(enderecoDestino) => {
                    setData({
                      ...data,
                      enderecoDestino,
                      nrSeqCidadeDes: enderecoDestino.nrSeqCidade,
                    });
                  }}
                  dataSourceTextProperty='noCidadeEstado'
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row'>
        <Panel>
          <Panel.Header
            title='Dados Receita'
            theme={Theme.Light}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row'>
              <div className='col-3  mb-3'>
                <Autocomplete
                  label='Tipo Receita Acerto'
                  required
                  searchDataSource={onSearchTipoReceita}
                  selectedItem={data.tipoReceitaAcerto}
                  onSelectItem={(tipoReceitaAcerto) => {
                    setData({
                      ...data,
                      tipoReceitaAcerto,
                      nrSeqTipoReceitaAcerto:
                        tipoReceitaAcerto.nrSeqTipoReceitaAcerto,
                    });
                  }}
                  dataSourceTextProperty='noTipoReceitaAcerto'
                />
              </div>
              <div className='col-3  mb-3'>
                <Textbox
                  label='Nr. Documento'
                  required
                  readOnly={liberaDocumento.flgLiberaDocumento}
                  text={data.nrDocumento}
                  onChangedValue={(nrDocumento) =>
                    setData({ ...data, nrDocumento })
                  }
                />
              </div>

              <div className='col-2 mb-3'>
                <DatePicker
                  label='Dt Vencimento'
                  required
                  text={data.dtReceita}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtReceita) => setData({ ...data, dtReceita })}
                />
              </div>
              <div className='col-2 mb-3'>
                <DatePicker
                  label='Dt Frete '
                  required
                  text={data.dtFrete}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtFrete) => setData({ ...data, dtFrete })}
                />
              </div>
              {!data.flgAvista && (
                <div className='col-2 mb-3'>
                  <Switch
                    formControl
                    label='Recebido '
                    checked={data.flgLiquidaTitulo}
                    onChange={(flgLiquidaTitulo) =>
                      setData({ ...data, flgLiquidaTitulo })
                    }
                  />
                </div>
              )}
            </div>
            <div className='row'>
              <div className='col-4  mb-3'>
                <Textbox
                  label='Tarifa'
                  text={data.tarifa}
                  textRight
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(tarifa) => setData({ ...data, tarifa })}
                />
              </div>
              <div className='col-4  mb-3'>
                <Textbox
                  label='Valor'
                  required
                  textRight
                  mask={MaskTypes.DecimalCustom}
                  text={data.vlrReceita}
                  onChangedValue={(vlrReceita) =>
                    setData({ ...data, vlrReceita })
                  }
                />
              </div>

              {!data.flgAvista && (
                <div className='col-4  mb-3'>
                  <Autocomplete
                    label='Forma Pagamento'
                    required
                    searchDataSource={onSearchFormaPagamento}
                    selectedItem={data.formaPagamento}
                    onSelectItem={(formaPagamento) => {
                      setData({
                        ...data,
                        formaPagamento,
                        nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                      });
                    }}
                    dataSourceTextProperty='noFormaPagamento'
                  />
                </div>
              )}

              <div className='row'>
                <div className='col-6'>
                  <Autocomplete
                    label='Remetente'
                    searchDataSource={onAutoCompleteRemetente}
                    selectedItem={data.remetente}
                    onSelectItem={(remetente) => {
                      setData({
                        ...data,
                        remetente,
                        nrSeqPessoaRem: remetente.nrSeqPessoaRem,
                      });
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
                <div className='col-5'>
                  <Autocomplete
                    label='Destinatário'
                    searchDataSource={onAutoCompleteDestinatario}
                    selectedItem={data.destinatario}
                    onSelectItem={(destinatario) => {
                      setData({
                        ...data,
                        destinatario,
                        nrSeqPessoaDes: destinatario.nrSeqPessoaDes,
                      });
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
              </div>
            </div>
          </Panel.Body>
        </Panel>
        <div className='row'>
          <div className='col-5  mb-3'>
            <Textbox
              label='Observação'
              text={data.noObservacao}
              onChangedValue={(noObservacao) =>
                setData({ ...data, noObservacao })
              }
            />
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col'>
            <div className='row mb-2'>
              <Panel>
                <Panel.Header
                  icon={['fas', 'file']}
                  title='Documentos Anexados a receita'
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                />

                <Panel.Body>
                  <div className='row mb-3'>
                    <div className='col-4 mb-3'>
                      <Textbox
                        maxLength={400}
                        label='Observações dos documentos:'
                        text={obs.noObs}
                        onChangedValue={(noObs) => {
                          obs.noObs = noObs;
                        }}
                      />
                    </div>
                    <div className='col-8 mb-3'>
                      <FileUpload
                        files={importedFile}
                        onChange={(files) => setImportedFile(files)}
                        allowedMimeTypes={[
                          MimeTypes.Types.PDF,
                          MimeTypes.Types.Image,
                          MimeTypes.Types.Word,
                        ]}
                      />
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <div className='col'>
                      <GridView
                        ref={gridViewDocumentos}
                        className='table-striped table-hover table-bordered table-sm'
                        dataColumns={columnsAnexos}
                        showPagination={false}
                        showSelectSizes={false}
                      />
                    </div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
          </div>
        </div>
      </div>
    </CSDManutPage>
  );
}
