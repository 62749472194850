import AlmoxarifadoLocal from 'core/models/EST/almoxarifadoLocal';
import TipoCombustivel from 'core/models/FRO/tipoCombustivel';

export default class PostoCombustivelTanque {
  constructor(jsonObject = {}) {
    this.nrSeqTipoCombustivel = jsonObject.nrSeqTipoCombustivel;
    this.qtdeCapacidade = jsonObject.qtdeCapacidade;
    this.qtdeMinima = jsonObject.qtdeMinima;
    this.qtdeMaxima = jsonObject.qtdeMaxima;
    this.flgAtivo = jsonObject.flgAtivo;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqPostoCombustivelTanque = jsonObject.nrSeqPostoCombustivelTanque;
    this.qtdeInicial = jsonObject.qtdeInicial;
    this.nrSeqPessoaPos = jsonObject.nrSeqPessoaPos;
    this.noPostoCombustivelTanque = jsonObject.noPostoCombustivelTanque;
    this.nrSeqAlmoxarifadoLocal = jsonObject.nrSeqAlmoxarifadoLocal;
    this.almoxarifadoLocal = new AlmoxarifadoLocal(
      jsonObject.almoxarifadoLocal ?? {}
    );
    this.tipoCombustivel = new TipoCombustivel(
      jsonObject.tipoCombustivel ?? {}
    );
    // #region
    this.status = jsonObject.status;
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
