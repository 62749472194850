import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  JustifyContent,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  DatePicker,
  GridView,
  Textbox,
  Textarea,
  Panel,
  Button,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getColeta,
  saveColeta,
  deleteColeta,
  printColetaReport,
} from 'core/services/FRO/coleta';
import { getConsignatarioAutoComplete } from 'core/services/FRO/coletaConsignatario';
import { getTipoFreteAutoComplete } from 'core/services/FRO/tipoFrete';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getFreteMercadoriaAutoComplete } from 'core/services/FRO/freteMercadoria';
import { getEspecieFreteAutoComplete } from 'core/services/FRO/especieFrete';
import { getPessoaAutoCompleteRemDesCli } from 'core/services/SEG/pessoa';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import ColetaOrdemTransporte from 'core/models/FRO/coletaOrdemTransporte';

export default function ColetaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  onOpenReport,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [dataInfoCarga, setDataInfoCarga] = useState(
    new ColetaOrdemTransporte()
  );
  const gridViewInfoCarga = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onNew = () => {
    const today = new Date().toJSON().slice(0, 10);
    setData({ dtEmissao: today });
    setDataInfoCarga({});
    if (gridViewInfoCarga && gridViewInfoCarga.current)
      gridViewInfoCarga.current.setDataSource([]);
    formSubmit.current.reset();
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printColetaReport({
      nrSeqColeta: data.nrSeqColeta,
    });

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const retorno = await getColeta(registryKey);
      setData(retorno);
      if (gridViewInfoCarga && gridViewInfoCarga.current) {
        gridViewInfoCarga.current.setDataSource(retorno.infoCarga);
      }
      setLoading(false);
    } else onNew();
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const infoCargas = gridViewInfoCarga.current.getDataSource() ?? [];
    const obj = { ...data, infoCarga: infoCargas };
    const { status, message: msg } = await saveColeta(obj);

    if (status === ResponseStatus.Success) {
      setData({});
      setDataInfoCarga({});
      if (gridViewInfoCarga && gridViewInfoCarga.current)
        gridViewInfoCarga.current.setDataSource([]);
      formSubmit.current.reset();
    }

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteColeta(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchPessoarem = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaRems,
    } = await getPessoaAutoCompleteRemDesCli({
      noPessoa: e,
      noPessoaTipo: 'REM',
    });
    if (msg) onSetMessage(status, msg);
    return pessoaRems;
  };

  const onSearchPessoades = async (e) => {
    const {
      status,
      message: msg,
      data: pessoadess,
    } = await getPessoaAutoCompleteRemDesCli({
      noPessoa: e,
      noPessoaTipo: 'DES',
    });
    if (msg) onSetMessage(status, msg);
    return pessoadess;
  };

  const onSearchTipoFrete = async (e) => {
    const {
      status,
      message: msg,
      tiposFrete,
    } = await getTipoFreteAutoComplete({ noTipoFrete: e });
    if (msg) onSetMessage(status, msg);
    return tiposFrete;
  };

  const onSearchVeiculoPrinc = async (e) => {
    const {
      status,
      message: msg,
      veiculos: veiculoPrincs,
    } = await getVeiculoAutoComplete({ placa: e });
    if (msg) onSetMessage(status, msg);
    return veiculoPrincs;
  };

  const onSearchPessoaMot = async (e) => {
    const {
      status,
      message: msg,
      motoristas,
    } = await getMotoristaAutoComplete({ noPessoaMot: e });
    if (msg) onSetMessage(status, msg);
    return motoristas;
  };

  const onSearchPessoaConsigOri = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaConsigOris,
    } = await getConsignatarioAutoComplete({ noPessoa: e });
    if (msg) onSetMessage(status, msg);
    return pessoaConsigOris;
  };

  const onSearchPessoaConsigDest = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaConsigDests,
    } = await getConsignatarioAutoComplete({ noPessoa: e });
    if (msg) onSetMessage(status, msg);
    return pessoaConsigDests;
  };

  const onSearchFreteMercadoria = async (e) => {
    const {
      status,
      message: msg,
      data: freteMercadorias,
    } = await getFreteMercadoriaAutoComplete({ noFreteMercadoria: e });
    if (msg) onSetMessage(status, msg);
    return freteMercadorias;
  };

  const onSearchEspecieFrete = async (e) => {
    const {
      status,
      message: msg,
      data: especiesFrete,
    } = await getEspecieFreteAutoComplete({ noEspecieFrete: e });
    if (msg) onSetMessage(status, msg);
    return especiesFrete;
  };

  const onSearchPessoaCli = async (e) => {
    const {
      status,
      message: msg,
      data: pessoasCli,
    } = await getPessoaAutoCompleteRemDesCli({
      noPessoa: e,
      noPessoaTipo: 'CLI',
    });
    if (msg) onSetMessage(status, msg);
    return pessoasCli;
  };

  const onSearchVeiculoCompa = async (e) => {
    const {
      status,
      message: msg,
      veiculos: veiculoCompas,
    } = await getVeiculoAutoComplete({ placa: e });
    if (msg) onSetMessage(status, msg);
    return veiculoCompas;
  };

  const onSearchVeiculoCompb = async (e) => {
    const {
      status,
      message: msg,
      veiculos: veiculoCompbs,
    } = await getVeiculoAutoComplete({ placa: e });
    if (msg) onSetMessage(status, msg);
    return veiculoCompbs;
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const columnsInfoCarga = [
    {
      key: 'nrSeqColetaOrdemTransporte',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'nrQuantidade',
      title: 'Quantidade',
    },
    {
      key: 'nrOrdemTransporte',
      title: 'Ordem Transp.',
    },
    {
      key: 'nrRemessa',
      title: 'Remessa',
    },
    {
      key: 'nrDocumento',
      title: 'Documento',
    },
    {
      key: 'vlrPeso',
      title: 'Peso',
    },
    {
      key: 'noObservacao',
      title: 'Observação',
    },
  ];

  const onAdicionarColeta = async () => {
    const list = gridViewInfoCarga.current.getDataSource();

    if (data.status !== GridView.EnumStatus.Alterar) {
      dataInfoCarga.status = GridView.EnumStatus.Inserir;
    }

    const newData = new ColetaOrdemTransporte({
      nrOrdemTransporte: dataInfoCarga.nrOrdemTransporte,
      nrQuantidade: dataInfoCarga.nrQuantidade,
      vlrPeso: dataInfoCarga.vlrPeso,
      nrDocumento: dataInfoCarga.nrDocumento,
      nrRemessa: dataInfoCarga.nrRemessa,
      noObservacao: dataInfoCarga.noObservacao,
      status: dataInfoCarga.status,
    });

    list.push(newData);
    if (gridViewInfoCarga && gridViewInfoCarga.current)
      gridViewInfoCarga.current.setDataSource(list);
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Coleta'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => {
        setData({});
        setDataInfoCarga({});
        gridViewInfoCarga.current.setDataSource([]);
      }}
      onSave={save}
      onPrint={onPrint}
      onDelete={data?.nrSeqColeta > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-2 mb-3'>
          <Textbox
            maxLength={20}
            label='Código'
            text={data.cdColeta}
            onChangedValue={(cdColeta) => setData({ ...data, cdColeta })}
            readOnly
          />
        </div>
        <div className='col-2 mb-3'>
          <DatePicker
            label='Data Emissão'
            text={data.dtEmissao}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtEmissao) => setData({ ...data, dtEmissao })}
            readOnly
          />
        </div>
        <div className='col-2 mb-3'>
          <DatePicker
            label='Data Coleta'
            text={data.dtColeta}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtColeta) => setData({ ...data, dtColeta })}
          />
        </div>
        <div className='col-2 mb-3'>
          <Textbox
            maxLength={6}
            label='Hora Coleta'
            text={data.hrColeta}
            mask={MaskTypes.TimeHM}
            onChangedValue={(hrColeta) => setData({ ...data, hrColeta })}
          />
        </div>
        <div className='col-2 mb-3'>
          <DatePicker
            label='Data Cancelamento'
            text={data.dtCancel}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtCancel) => setData({ ...data, dtCancel })}
            readOnly
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='Unidade Origem'
            searchDataSource={onSearchEmpresa}
            selectedItem={data.empresa}
            onSelectItem={(empresa) => {
              setData({
                ...data,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-2 mb-3'>
          <Autocomplete
            label='Tipo de Frete'
            searchDataSource={onSearchTipoFrete}
            selectedItem={data.tipoFrete}
            onSelectItem={(tipoFrete) => {
              setData({
                ...data,
                tipoFrete,
                nrSeqTipoFrete: tipoFrete.nrSeqTipoFrete,
              });
            }}
            dataSourceTextProperty='noTipoFrete'
          />
        </div>
        <div className='col-3 mb-3'>
          <Autocomplete
            label='Espécie Frete'
            tooltip='Teste'
            searchDataSource={onSearchEspecieFrete}
            selectedItem={data.especieFrete}
            onSelectItem={(especieFrete) => {
              setData({
                ...data,
                especieFrete,
                nrSeqEspecieFrete: especieFrete.nrSeqEspecieFrete,
              });
            }}
            dataSourceTextProperty='noEspecieFrete'
          />
        </div>
        <div className='col-3 mb-3'>
          <Autocomplete
            label='Frete Mercadoria'
            searchDataSource={onSearchFreteMercadoria}
            selectedItem={data.freteMercadoria}
            onSelectItem={(freteMercadoria) => {
              setData({
                ...data,
                freteMercadoria,
                nrSeqFreteMercadoria: freteMercadoria.nrSeqFreteMercadoria,
              });
            }}
            dataSourceTextProperty='noFreteMercadoria'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-6 mb-3'>
          <Autocomplete
            label='Motorista'
            searchDataSource={onSearchPessoaMot}
            selectedItem={data.motorista}
            onSelectItem={(motorista) => {
              setData({
                ...data,
                motorista,
                nrSeqPessoaMot: motorista.nrSeqPessoaMot,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col mb-3'>
          <Autocomplete
            label='Veic. Princ.'
            searchDataSource={onSearchVeiculoPrinc}
            selectedItem={data.veiculoPrincipal}
            onSelectItem={(veiculoPrinc) => {
              setData({
                ...data,
                veiculoPrinc,
                nrSeqVeiculoPrinc: veiculoPrinc.nrSeqVeiculo,
              });
            }}
            dataSourceTextProperty='placa'
          />
        </div>
        <div className='col mb-3'>
          <Autocomplete
            label='Veic. Comp. A'
            searchDataSource={onSearchVeiculoCompa}
            selectedItem={data.veiculoCompA}
            onSelectItem={(veiculoCompA) => {
              setData({
                ...data,
                veiculoCompA,
                nrSeqVeiculoCompA: veiculoCompA.nrSeqVeiculo,
              });
            }}
            dataSourceTextProperty='placa'
          />
        </div>
        <div className='col mb-3'>
          <Autocomplete
            label='Veic. Comp. B'
            searchDataSource={onSearchVeiculoCompb}
            selectedItem={data.veiculoCompB}
            onSelectItem={(veiculoCompB) => {
              setData({
                ...data,
                veiculoCompB,
                nrSeqVeiculoCompB: veiculoCompB.nrSeqVeiculo,
              });
            }}
            dataSourceTextProperty='placa'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='Remetente'
            searchDataSource={onSearchPessoarem}
            selectedItem={data.remetente}
            onSelectItem={(remetente) => {
              setData({
                ...data,
                remetente,
                nrSeqPessoaRem: remetente.nrSeqPessoa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='Destinatário'
            searchDataSource={onSearchPessoades}
            selectedItem={data.destinatario}
            onSelectItem={(destinatario) => {
              setData({
                ...data,
                destinatario,
                nrSeqPessoaDes: destinatario.nrSeqPessoa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='Consignatário Origem (Local de Coleta)'
            searchDataSource={onSearchPessoaConsigOri}
            selectedItem={data.consignatarioOrigem}
            onSelectItem={(pessoaConsigOri) => {
              setData({
                ...data,
                pessoaConsigOri,
                nrSeqPessoaConsigOri: pessoaConsigOri.nrSeqPessoaConsig,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='Consignatário Destino (Local de Entrega)'
            searchDataSource={onSearchPessoaConsigDest}
            selectedItem={data.consignatarioDestino}
            onSelectItem={(pessoaConsigDest) => {
              setData({
                ...data,
                pessoaConsigDest,
                nrSeqPessoaConsigDest: pessoaConsigDest.nrSeqPessoaConsig,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='Tomador (Cliente)'
            searchDataSource={onSearchPessoaCli}
            selectedItem={data.cliente}
            onSelectItem={(pessoaCli) => {
              setData({
                ...data,
                pessoaCli,
                nrSeqPessoaCli: pessoaCli.nrSeqPessoa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Textarea
            maxLength={2000}
            label='Observação'
            text={data.obsColeta}
            onChangedValue={(obsColeta) => setData({ ...data, obsColeta })}
            rows={4}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <Panel>
            <Panel.Header
              title='Informações da Carga'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-3 mb-3'>
                  <Textbox
                    label='Quantidade'
                    mask={MaskTypes.Decimal}
                    onChangedValue={(nrQuantidade) =>
                      setDataInfoCarga({ ...dataInfoCarga, nrQuantidade })
                    }
                  />
                </div>
                <div className='col-3 mb-3'>
                  <Textbox
                    label='Ordem Transp.'
                    // mask={MaskTypes.Integer}
                    onChangedValue={(nrOrdemTransporte) =>
                      setDataInfoCarga({ ...dataInfoCarga, nrOrdemTransporte })
                    }
                  />
                </div>
                <div className='col-3 mb-3'>
                  <Textbox
                    label='Número Remessa'
                    // mask={MaskTypes.Integer}
                    onChangedValue={(nrRemessa) =>
                      setDataInfoCarga({ ...dataInfoCarga, nrRemessa })
                    }
                  />
                </div>
                <div className='col-3 mb-3'>
                  <Textbox
                    label='Número Documento'
                    // mask={MaskTypes.Integer}
                    onChangedValue={(nrDocumento) =>
                      setDataInfoCarga({ ...dataInfoCarga, nrDocumento })
                    }
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-3 mb-3'>
                  <Textbox
                    label='Peso'
                    mask={MaskTypes.Decimal}
                    onChangedValue={(vlrPeso) =>
                      setDataInfoCarga({ ...dataInfoCarga, vlrPeso })
                    }
                  />
                </div>
                <div className='col mb-3'>
                  <Textbox
                    maxLength={400}
                    label='Descrição'
                    onChangedValue={(noObservacao) =>
                      setDataInfoCarga({ ...dataInfoCarga, noObservacao })
                    }
                  />
                </div>
                <div className='col-2 d-flex justify-content-end pt-4 pb-4'>
                  <Button
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    className='btn'
                    text='Adicionar'
                    onClick={onAdicionarColeta}
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-12'>
                  <GridView
                    ref={gridViewInfoCarga}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsInfoCarga}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
    </CSDManutPage>
  );
}
