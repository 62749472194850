export default class ManifestoMdfe {
  constructor(jsonObject = {}) {
    this.nrSeqManifestoMdfe = jsonObject.nrSeqManifestoMdfe;
    this.cdTipoSolicitacao = jsonObject.cdTipoSolicitacao;
    this.nrLote = jsonObject.nrLote;
    this.dtSolicitacao = jsonObject.dtSolicitacao;
    this.nrChaveMdfe = jsonObject.nrChaveMdfe;
    this.cStat = jsonObject.cStat;
    this.nrProtAutorizacao = jsonObject.nrProtAutorizacao;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.dtAutorizacao = jsonObject.dtAutorizacao;
    this.nrSeqManifestoCte = jsonObject.nrSeqManifestoCte;
    this.nrProtLote = jsonObject.nrProtLote;
    this.dtRecbtoLote = jsonObject.dtRecbtoLote;
    this.noJustificativa = jsonObject.noJustificativa;
    this.dtChancela = jsonObject.dtChancela;
    this.noProtocoloChancela = jsonObject.noProtocoloChancela;
    this.noXml = jsonObject.noXml;
  }

  toJson = () => JSON.stringify(this);
}
