export default class ChecklistGrupo {
  constructor(jsonObject = {}) {
    this.nrSeqChecklistGrupo = jsonObject.nrSeqChecklistGrupo;
    this.noGrupo = jsonObject.noGrupo;
    this.cdGrupo = jsonObject.cdGrupo;
    this.nrSeqChecklistGrupoPai = jsonObject.nrSeqChecklistGrupoPai;
    this.nrNivel = jsonObject.nrNivel;
    this.nrOrdem = jsonObject.nrOrdem;
  }

  toJson = () => JSON.stringify(this);
}
