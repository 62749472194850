import { GrupoLinha } from 'core/models/TEL';
import Pagination from 'core/models/pagination';
import { MimeTypes } from 'ui/Helpers/utils';
import qs from 'qs';
import api from '../api';

const baseURL = 'TEL/GrupoLinha';

export const getGrupoLinhaAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    gruposLinhas: value?.map((json) => new GrupoLinha(json)) ?? [],
  };
};

export const incluirGrupoLinha = async (data) => {
  const response = await api.post(`${baseURL}/incluirGrupoLinha`, data);
  const { status, message } = response.data ?? {};

  return {
    status,
    message,
  };
};

export const getGrupoLinha = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};

  return new GrupoLinha(value ?? {});
};

export const getGrupoLinhaPaginated = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPaginated`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new GrupoLinha(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const saveGrupoLinha = async (data) => {
  const response = await api.post(`${baseURL}/`, data);

  return {
    status: response.data.status,
    message: response.data.message,
    grupoLinha: response.data.value ? new GrupoLinha(response.data.value) : {},
  };
};

export const deleteGrupoLinha = async (id) => {
  const response = await api.delete(`${baseURL}`, {
    params: { id },
  });

  return response.data ?? {};
};

export const deleteAllGrupoLinha = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });

  return response.data ?? {};
};

export const importarLinhas = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('extension', MimeTypes.getExtension(file.type));

  const response = await api.post(`${baseURL}/ImportarLinhas`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    value,
  };
};
