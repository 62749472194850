import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Autocomplete,
  Button,
  DatePicker,
  Switch,
  GridView,
  Textbox,
  Panel,
} from 'ui/components';
import {
  Theme,
  ResponseStatus,
  BootstrapSizes,
  ColumnTypes,
  StatusGrid,
} from 'ui/Helpers/enums';
import { JustifyContent } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
// @ts-expect-error
import { GridviewColumns } from 'ui/Helpers/interfaces.ts';
import { ICliente } from 'core/interfaces/FIN';
import ContatoAdicional from 'core/models/SEG/contatoAdicional';
import { getPessoaSetorAutoComplete } from 'core/services/SEG/pessoaSetor';

interface IDocumentsPanelProps {
  clienteData: ICliente;
  setClienteData: (e: Object) => void;
  onSetMessage: (status: ResponseStatus, msg: string) => void;
}

const ContatoAdicionalPanel = forwardRef(
  (
    { clienteData, setClienteData, onSetMessage }: IDocumentsPanelProps,
    ref
  ) => {
    const gridViewContatos = useRef<any>();

    const [
      contatoAdicionalData,
      setContatoAdicionalData,
    ] = useState<ContatoAdicional>({});

    const fillGridView = () => {
      if (
        gridViewContatos &&
        gridViewContatos.current &&
        clienteData.contatosAdicionais &&
        clienteData.contatosAdicionais.length > 0
      ) {
        const gridList = clienteData.contatosAdicionais.map((item) => {
          if (item.noImagem) {
            item.flgExibeDownload = true;
          } else {
            item.flgExibeDownload = false;
          }
          return item;
        });
        gridViewContatos.current.setDataSource(gridList);
      }
    };

    useImperativeHandle(ref, () => ({
      clear() {
        setContatoAdicionalData({});
        gridViewContatos?.current?.setDataSource([]);
      },
      fillGrid() {
        fillGridView();
      },
      getDataGrid() {
        const listaDocsGrid = gridViewContatos?.current?.getDataSource() ?? [];
        return listaDocsGrid;
      },
    }));

    const onSearchSetor = async (e: any) => {
      const { setores } = await getPessoaSetorAutoComplete({
        noPessoaSetor: e,
      });

      return setores;
    };

    const validaContatoAdicional = () => {
      if (!contatoAdicionalData.nome) {
        onSetMessage(ResponseStatus.Error, 'Preencha o Nome do Contato');

        return false;
      }

      if (!contatoAdicionalData.flgRepresentanteLegal) {
        if (!contatoAdicionalData.nrSeqPessoaSetor) {
          onSetMessage(ResponseStatus.Error, 'Informar o Tipo de Contato.');

          return false;
        }

        if (!contatoAdicionalData.email && !contatoAdicionalData.fone) {
          onSetMessage(
            ResponseStatus.Error,
            'Preencha o Email ou Telefone do Contato'
          );

          return false;
        }
      }

      if (contatoAdicionalData.flgRepresentanteLegal) {
        if (!contatoAdicionalData.nrCpf) {
          onSetMessage(
            ResponseStatus.Error,
            'Preencha o CPF do Representante Legal'
          );

          return false;
        }

        if (!contatoAdicionalData.celular && !contatoAdicionalData.email) {
          onSetMessage(
            ResponseStatus.Error,
            'Preencha o Email ou o Celular do Representante Legal'
          );

          return false;
        }

        if (!contatoAdicionalData.dtNascimento) {
          onSetMessage(
            ResponseStatus.Error,
            'Preencha a Data de Nascimento do Representante Legal'
          );

          return false;
        }
      }

      return true;
    };

    const onClickAddContato = async () => {
      if (validaContatoAdicional()) {
        const obj = {
          ...contatoAdicionalData,
          status: contatoAdicionalData.status ?? StatusGrid.Inserir,
        };
        const list = gridViewContatos.current.getDataSource();
        list.push(obj);

        if (gridViewContatos && gridViewContatos.current)
          gridViewContatos.current.setDataSource(list);

        setClienteData({
          ...clienteData,
          contatosAdicionais: list,
        });
        setContatoAdicionalData({});
      }
    };

    const onClickEditContatoAdicional = (e: any, datasource: any[]) => {
      const obj = datasource.find((item) => item === e);

      if (obj.status !== StatusGrid.Inserir) {
        obj.status = StatusGrid.Alterar;
      }

      setContatoAdicionalData({ ...obj });

      const listaEquipamentos = datasource.filter((item) => item !== e);

      if (gridViewContatos && gridViewContatos.current)
        gridViewContatos.current.setDataSource(listaEquipamentos);
    };

    const onClickRemoveContatoAdicional = async (e: any) => {
      const list = gridViewContatos.current.getDataSource();

      list.filter((el: any) => el === e)[0].status = StatusGrid.Remover;

      if (gridViewContatos && gridViewContatos.current)
        gridViewContatos.current.setDataSource(list);
    };

    const columns: Array<GridviewColumns> = [
      {
        key: 'nrSeqContatoAdicional',
        title: 'nrSeqContatoAdicional',
        visible: false,
      },
      { key: 'nome', title: 'Nome' },
      { key: 'fone', title: 'Telefone' },
      { key: 'email', title: 'E-mail' },
      { key: 'setor.noPessoaSetor', title: 'Tipo de Contato' },
      {
        key: 'celular',
        title: 'Celular',
        format: GridView.DataTypes.CellPhone,
      },

      { key: 'nrCpf', title: 'CPF' },
      {
        key: 'dtNascimento',
        title: 'Data de Nascimento',
        format: GridView.DataTypes.Date,
      },
      {
        key: 'flgRepresentanteLegal',
        title: 'Representante Legal',
      },
      {
        key: 'nrSeqContatoAdicional',
        type: ColumnTypes.Button,
        onClick: (e: any, datasource: any) =>
          onClickEditContatoAdicional(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
        title: 'Editar',
      },
      {
        key: 'nrSeqContatoAdicional',
        type: ColumnTypes.Button,
        title: 'Excluir',
        onClick: (e: any) => onClickRemoveContatoAdicional(e),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    return (
      <div className='col mt-3'>
        {/* @ts-expect-error */}
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header
            title='Contato Adicional'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-3'>
                <Autocomplete
                  label='Tipo de Contato'
                  searchDataSource={onSearchSetor}
                  selectedItem={contatoAdicionalData.setor}
                  onSelectItem={(setor: any) =>
                    setContatoAdicionalData({
                      ...contatoAdicionalData,
                      setor,
                      nrSeqPessoaSetor: setor.nrSeqPessoaSetor,
                    })
                  }
                  dataSourceTextProperty='noPessoaSetor'
                />
              </div>
              {!clienteData.flgFisica && (
                <div className='col-2'>
                  <Switch
                    label='Representante Legal'
                    formControl
                    checked={contatoAdicionalData.flgRepresentanteLegal}
                    tooltip='Informa se o contato é um representante legal do Cliente'
                    onChange={(flgRepresentanteLegal) => {
                      setContatoAdicionalData({
                        ...contatoAdicionalData,
                        flgRepresentanteLegal,
                      });
                    }}
                  />
                </div>
              )}
              {contatoAdicionalData.flgRepresentanteLegal && (
                <>
                  <div className='col-3'>
                    <Textbox
                      label='CPF'
                      text={contatoAdicionalData.nrCpf}
                      mask={MaskTypes.CPF}
                      onChangedValue={(nrCpf: string) => {
                        setContatoAdicionalData({
                          ...contatoAdicionalData,
                          nrCpf,
                        });
                      }}
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Celular'
                      mask={MaskTypes.CellPhone}
                      maxLength={20}
                      text={contatoAdicionalData.celular}
                      onChangedValue={(celular: string) =>
                        setContatoAdicionalData({
                          ...contatoAdicionalData,
                          celular,
                        })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Data de Nascimento'
                      text={contatoAdicionalData.dtNascimento}
                      mask={MaskTypes.Date}
                      onChange={(dtNascimento: Date) => {
                        setContatoAdicionalData({
                          ...contatoAdicionalData,
                          dtNascimento,
                        });
                      }}
                    />
                  </div>
                </>
              )}

              <div className='col-3'>
                <Textbox
                  label='Nome'
                  text={contatoAdicionalData.nome}
                  onChangedValue={(nome: string) =>
                    setContatoAdicionalData({ ...contatoAdicionalData, nome })
                  }
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Telefone'
                  mask={MaskTypes.CellPhone}
                  maxLength={20}
                  text={contatoAdicionalData.fone}
                  onChangedValue={(fone: string) =>
                    setContatoAdicionalData({
                      ...contatoAdicionalData,
                      fone,
                    })
                  }
                />
              </div>
              <div className='col-3'>
                <Textbox
                  label='E-mail'
                  text={contatoAdicionalData.email}
                  onChangedValue={(email: string) =>
                    setContatoAdicionalData({ ...contatoAdicionalData, email })
                  }
                />
              </div>
              <div className='col-2 widget-square p-3'>
                <Button
                  outline
                  className='px-4'
                  size={BootstrapSizes.Small}
                  theme={Theme.Success}
                  template={Button.Templates.Quick}
                  onClick={onClickAddContato}
                  text='Adicionar'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <GridView
                  ref={gridViewContatos}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
);

export default ContatoAdicionalPanel;
