import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Textbox,
  GridView,
  Button,
  Autocomplete,
  Switch,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getPocGrupoProdOrcamento,
  savePocGrupoProdOrcamento,
  deletePocGrupoProdOrcamento,
} from 'core/services/POC/pocGrupoProdOrcamento';

import { getPocTipoOrcamentoAutoComplete } from 'core/services/POC/pocTipoOrcamento';

export default function PocGrupoProdOrcamentoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [nome, setNome] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const gridView = useRef();

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const obj = await getPocGrupoProdOrcamento(registryKey);
      setNome(obj);
      if (gridView && gridView.current)
        gridView.current.setDataSource(obj.nrSeqPocTipoOrcamentoS);
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const onAdicionarPlaca = async () => {
    const lista = gridView.current.getDataSource();
    const listaValidacao = [];
    lista.forEach((elem) => listaValidacao.push(elem));
    listaValidacao.push(data);
    if (data.status !== GridView.EnumStatus.Alterar) {
      data.status = GridView.EnumStatus.Inserir;
    }
    lista.push(data);
    if (gridView && gridView.current) gridView.current.setDataSource(lista);
    setData({});
  };

  const onClickEditTipoOrcamento = async (selectedValue, datasource) => {
    const dataFaturas = datasource.filter((fatura) => fatura !== selectedValue);
    const dataFatura = datasource.filter((fatura) => fatura === selectedValue);

    dataFatura[0].status = GridView.EnumStatus.Alterar;
    setData(dataFatura[0]);
    if (gridView && gridView.current)
      gridView.current.setDataSource(dataFaturas);
  };

  const onClickRemoveTipoOrcamento = async (selectedValue, datasource) => {
    let placas = [];
    if (selectedValue.nrSeqPocGrupoProdOrcamento === undefined) {
      placas = datasource.filter((Placa) => Placa !== selectedValue);
    } else {
      placas = datasource.filter((Placa) => Placa !== selectedValue);
      const dataPlaca = datasource.filter((Placa) => Placa === selectedValue);
      dataPlaca[0].status = GridView.EnumStatus.Remover;
      placas.push(dataPlaca[0]);
    }
    if (gridView && gridView.current) gridView.current.setDataSource(placas);
  };

  const columns = [
    {
      key: 'nrSeqPocGrupoProdOrcamento',
      visible: false,
    },
    {
      key: 'nrOrdem',
      title: 'Ordem',
      format: GridView.DataTypes.Integer,
    },
    {
      key: 'tipoOrcamento.noPocTipoOrcamento',
      title: 'Tipo Orçamento',
    },
    {
      key: 'nrSeqTituloPagar',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditTipoOrcamento(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqTituloPagar',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveTipoOrcamento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchTipoOrcamento = async (e) => {
    const {
      status,
      message: msg,
      data: tipos,
    } = await getPocTipoOrcamentoAutoComplete({
      noTipoOrcamento: e,
    });
    onSetMessage(status, msg);
    return tipos;
  };

  const save = async () => {
    setLoading(true);

    const lista = gridView.current ? gridView.current.getDataSource() : [];

    const obj = {
      ...nome,
      ...data,
      nrSeqTransacao: transaction.nrSeqTransacao,
      nrSeqPocTipoOrcamentoS: lista,
    };
    const { status, message: msg } = await savePocGrupoProdOrcamento(obj);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deletePocGrupoProdOrcamento(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      transaction={transaction}
      isActive={isActive}
      title='Manutenção de Grupo Produto Orçamento'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqPocGrupoProdOrcamento > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <div className='row'>
        <div className='col-5 mb-3'>
          <Textbox
            maxLength={30}
            required
            label='Grupo Orçamento'
            text={nome.cdPocGrupoProdOrcamento}
            onChangedValue={(cdPocGrupoProdOrcamento) =>
              setNome({ ...nome, cdPocGrupoProdOrcamento })
            }
          />
        </div>
        <div className='col-1'>
          <Switch
            formControl
            label='DIP'
            checked={nome.flgDIP}
            onChange={(flgDIP) => {
              setNome({ ...nome, flgDIP });
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-2 mb-3'>
          <Textbox
            label='Ordem'
            text={data.nrOrdem}
            mask={MaskTypes.Integer}
            onChangedValue={(nrOrdem) => setData({ ...data, nrOrdem })}
          />
        </div>
        <div className='col-4 mb-3'>
          <Autocomplete
            label='Tipo Orçamento'
            searchDataSource={onSearchTipoOrcamento}
            selectedItem={data.tipoOrcamento}
            onSelectItem={(tipoOrcamento) => {
              setData({
                ...data,
                tipoOrcamento,
                nrSeqPocTipoOrcamento: tipoOrcamento.nrSeqPocTipoOrcamento,
              });
            }}
            dataSourceTextProperty='noPocTipoOrcamento'
          />
        </div>
        <div className='col-4 mt-3'>
          <Button
            theme={Theme.Primary}
            template={Button.Templates.Button}
            text='Adicionar'
            onClick={onAdicionarPlaca}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-12 mb-3'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
