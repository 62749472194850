import EmpresaOrigemCentro from '../SEG/empresaOrigemCentro';
import EmpresaOrigemSetor from '../SEG/empresaOrigemSetor';

export default class AssociadoEmpresaOrigem {
  constructor(jsonObject = {}) {
    this.nrSeqPessoaAss = jsonObject.nrSeqPessoaAss;
    this.nrSeqPessoaEmpOrig = jsonObject.nrSeqPessoaEmpOrig;
    this.nrSeqAssocEmpOrig = jsonObject.nrSeqAssocEmpOrig;
    this.dtIniVigencia = jsonObject.dtIniVigencia;
    this.dtFimVigencia = jsonObject.dtFimVigencia;
    this.nrSeqEmpresaOrigemSetor = jsonObject.nrSeqEmpresaOrigemSetor;
    this.nrSeqEmpresaOrigemCentro = jsonObject.nrSeqEmpresaOrigemCentro;
    this.cdEmpresaOrigem = jsonObject.cdEmpresaOrigem;
    this.noEmpresaOrigem = jsonObject.noEmpresaOrigem;
    this.setor = new EmpresaOrigemSetor(jsonObject.setor ?? {});
    this.centro = new EmpresaOrigemCentro(jsonObject.centro ?? {});
  }

  toJson = () => JSON.stringify(this);
}
