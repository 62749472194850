import qs from 'qs';
import Pagination from 'core/models/pagination';
import ContaCorrente from 'core/models/FIN/contaCorrente';
import api from '../api';

const baseURL = `/FIN/ContaCorrente`;

export const getContaCorrenteList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ContaCorrente(json)) ?? [],
  };
};
export const getContaCorrenteAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ContaCorrente(json)) ?? [],
  };
};

export const getContaCorrenteByUser = async (filters) => {
  const response = await api.get(`${baseURL}/SearchByUser`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ContaCorrente(json)) ?? [],
  };
};

export const getContaCorrentePagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ContaCorrente(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getContaCorrente = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ContaCorrente(value) : {};
};

export const saveContaCorrente = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteContaCorrente = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printContaCorrente = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelContaCorrente = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllContaCorrente = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
