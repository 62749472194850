export default class Prospect {
  constructor(jsonObject = {}) {
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.nrSeqProspect = jsonObject.nrSeqProspect;
    this.cdProspect = jsonObject.cdProspect;
    this.noFantasia = jsonObject.noFantasia;
    this.razaoSocial = jsonObject.razaoSocial;
    this.numero = jsonObject.numero;
    this.cep = jsonObject.cep;
    this.bairro = jsonObject.bairro;
    this.complemento = jsonObject.complemento;
    this.noRua = jsonObject.noRua;
    this.nrSeqCidade = jsonObject.nrSeqCidade;
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.nrCnpj = jsonObject.nrCnpj;
    this.dtCadastro = jsonObject.dtCadastro;
    this.obsProspect = jsonObject.obsProspect;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
