import { SendEmail } from 'core/services/HELP';
import React, { useState } from 'react';

import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { Button, Loader, Modal, Notification, Textbox } from 'ui/components';

export default function ModalEnviarEmail({
  nrSeqDemandasSelecionadas = [],
  show,
  onClose,
}) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataEmail, setDataEmail] = useState('');

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onEnviarEmail = async () => {
    setLoading(true);

    if (dataEmail.trim().length > 0) {
      const { status, message: msg } = await SendEmail(
        nrSeqDemandasSelecionadas,
        dataEmail
      );

      onSetMessage(status, msg);
    } else {
      onSetMessage(false, 'Insira o email para envio');
    }

    setLoading(false);
  };

  return (
    <Modal
      show={show}
      title='Enviar solicitação por e-mail'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
      icon={['far', 'comment-alt']}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <>
            <div className='row mb-3'>
              <div className='col'>
                <Notification
                  message={message.message}
                  theme={message.theme}
                  onClose={() => setMessage(null)}
                />
              </div>
            </div>
          </>
        )}
        <div className='row'>
          <div className='col'>
            <p>O Email será enviado conforme os itens selecionados na lista.</p>
            <p>
              Informe o e-mail de destino da solicitação. Caso deseje enviar
              para mais de um remetente, separe-os por ponto-virgula ( ; )
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <Textbox
              text={dataEmail}
              placeholder='email_1@provedor.com; email_2@provedor.com ...'
              onChangedValue={(email) => setDataEmail(email)}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Enviar Email'
          theme={
            nrSeqDemandasSelecionadas.length > 0
              ? Theme.Success
              : Theme.Inactive
          }
          onClick={nrSeqDemandasSelecionadas.length > 0 ? onEnviarEmail : null}
        />

        <Button
          text='Fechar'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
