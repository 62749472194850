export default class AreaChamado {
  nrSeqAreaChamado: number;

  cdAreaChamado: string;

  noAreaChamado: string;

  nrSeqGrupoEmpresa: number;

  constructor(jsonObject: AreaChamado | {} = {}) {
    this.nrSeqAreaChamado =
      'nrSeqAreaChamado' in jsonObject ? jsonObject.nrSeqAreaChamado : 0;
    this.cdAreaChamado =
      'cdAreaChamado' in jsonObject ? jsonObject.cdAreaChamado : '';
    this.noAreaChamado =
      'noAreaChamado' in jsonObject ? jsonObject.noAreaChamado : '';
    this.nrSeqGrupoEmpresa =
      'nrSeqGrupoEmpresa' in jsonObject ? jsonObject.nrSeqGrupoEmpresa : 0;
  }

  toJson = () => JSON.stringify(this);
}
