import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Loader } from 'ui/components';
import { generateID } from 'ui/Helpers/utils';

const DropdownMulti = React.forwardRef((props, ref) => {
  const {
    dataSourcePropertyText,
    dataSourcePropertyValue,
    label,
    selectedItems = [],
    onSelectItem,
    enabled,
    id = generateID(),
    visible,
  } = props;

  const [viewing, setViewing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  let currentDataSource = dataSource;

  useEffect(() => {
    setSelectAll(
      dataSource.length > 0 && selectedItems.length === currentDataSource.length
    );
  }, [dataSource, selectedItems]);

  useImperativeHandle(ref, () => ({
    async loadDataSource(onLoadDataSource) {
      setLoading(true);
      if (onLoadDataSource) {
        const source = await onLoadDataSource();
        setDataSource(source ?? []);
      }
      setLoading(false);
    },
    reset() {
      setDataSource([]);
    },
  }));

  const onDropDownListMouseEnter = () => {
    if (viewing) document.body.onclick = null;
  };

  const onDropDownListMouseLeave = () => {
    if (viewing)
      document.body.onclick = () => {
        setViewing(false);
        document.body.onclick = null;
      };
  };

  const onDropDownListItemClick = (item) => {
    if (onSelectItem) {
      const newList = selectedItems.includes(item)
        ? selectedItems.filter(
            (p) => p[dataSourcePropertyValue] !== item[dataSourcePropertyValue]
          ) ?? []
        : [...selectedItems, item];

      onSelectItem(newList);
    }
  };

  const filterDataSource = (text = filterText) => {
    currentDataSource =
      dataSource.filter((p) =>
        p[dataSourcePropertyText].match(new RegExp(text, 'i'))
      ) ?? [];

    return currentDataSource;
  };

  const onToogleCheckAll = (value) => {
    const { checked } = value.target;

    setSelectAll(checked);

    if (checked) {
      const filteredDataSource = filterDataSource(filterText);

      onSelectItem(filteredDataSource);
    } else onSelectItem([]);
  };

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilterText(value);
  };

  const isVisible = visible ?? true;

  return isVisible ? (
    <>
      {label && (
        <label className='form-label' htmlFor={id}>
          {label}
        </label>
      )}
      <div
        id={id}
        className='dropdown dropdown-multi'
        disabled={enabled ?? true ? '' : 'disabled'}
        onMouseLeave={onDropDownListMouseLeave}
        onMouseEnter={onDropDownListMouseEnter}
      >
        <button
          className='form-select'
          type='button'
          data-bs-toggle='dropdown'
          aria-expanded='false'
          id={`dropdownMenu-${id}`}
          data-bs-auto-close='outside'
        >
          <div className='dropdown-display'>
            {selectedItems && selectedItems.length > 0
              ? selectedItems.map((item, index) => (
                  <span className='display-item'>
                    {item[dataSourcePropertyText]}
                    {index + 1 !== selectedItems.length ? ', ' : ''}
                  </span>
                ))
              : '...'}
          </div>
        </button>
        <ul
          className='dropdown-menu dropdown-view'
          aria-labelledby={`dropdownMenu-${id}`}
        >
          <Loader loading={loading} />
          <li className='dropdown-view-toolbox'>
            <div className='bordered-input-group'>
              <div className='checkbox-container'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='item-check-todos'
                  checked={selectAll}
                  onChange={onToogleCheckAll}
                  title='Selecionar Todos'
                />
              </div>
              <input
                type='text'
                className='form-control'
                placeholder=' Filtrar...'
                value={filterText}
                onChange={handleFilterChange}
              />
            </div>
          </li>
          {filterDataSource().map((item, index) => (
            <li
              style={{ padding: '0', margin: '0' }}
              key={`${item[dataSourcePropertyText]}_${item[dataSourcePropertyValue]}`}
            >
              <button
                className='form-check dropdown-multi-item dropdown-item w-100'
                type='button'
                style={{ paddingLeft: '3rem' }}
                onClick={() => onDropDownListItemClick(item)}
              >
                <div>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    tabIndex={-1}
                    id={`item-check-${index}-${id}`}
                    onChange={() => onDropDownListItemClick(item)}
                    checked={
                      selectedItems &&
                      selectedItems.some(
                        (p) =>
                          p[dataSourcePropertyValue] ===
                          item[dataSourcePropertyValue]
                      )
                    }
                  />
                </div>
                <div>
                  <label
                    className='form-check-label'
                    htmlFor={`item-check-${index}`}
                  >
                    {item[dataSourcePropertyText]}
                  </label>
                </div>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  ) : null;
});

export default DropdownMulti;
