import ContratoPendenteFaturamento from 'core/models/GProj/contratoPendenteFaturamento';
import ContratoServicoFatura from 'core/models/GProj/contratoServicoFatura';
import ContratoServico from 'core/models/GProj/contratoServico';
import VendedorTelefoniaComissao from 'core/models/TEL/vendedorTelefoniaComissao';
import api from '../api';

export const getFaturamentoPendenteList = async (filters) => {
  const response = await api.get(`/ContratoServico/PendentesFaturamento`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    pagination,
    contratosPendentesFaturamento:
      value?.map((json) => new ContratoPendenteFaturamento(json)) ?? [],
  };
};

export const gerarParcelas = async (filters) => {
  const response = await api.post(`/ContratoServico/GerarParcelas`, filters);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    parcelas: value?.map((json) => new ContratoServicoFatura(json)) ?? [],
  };
};

export const getContratoServico = async (filters) => {
  const response = await api.get(`/ContratoServico/Get`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    contratoServico: value ? new ContratoServico(value) : {},
  };
};

export const faturarContrato = async (filters) => {
  const response = await api.post(`/ContratoServico/FaturarContrato`, filters);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    fatura: value,
  };
};

export const postCalcularComissao = async (filters) => {
  const response = await api.post(`/ContratoServico/CalcularComissao`, filters);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};

export const postGerarTituloPagarComissao = async (filters) => {
  const response = await api.post(
    `/ContratoServico/GerarTituloPagarComissao`,
    filters
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};

export const postVerificaSeComissaoPendenteParaFaturar = async (filters) => {
  const response = await api.post(
    `/ContratoServico/VerificaSeComissaoPendenteParaFaturar`,
    filters
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};

export const postBuscarComissaoCalculada = async (filters) => {
  const response = await api.post(
    `/ContratoServico/BuscarComissaoCalculada`,
    filters
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    comissoes: value?.map((json) => new VendedorTelefoniaComissao(json)) ?? [],
  };
};

export const postExcluirComissaoCalculada = async (filters) => {
  const response = await api.post(
    `/ContratoServico/ExcluirComissaoCalculada`,
    filters
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};
