import { Cliente } from 'core/models/FIN';
import Pagination from 'core/models/pagination';
import qs from 'qs';
import { MimeTypes } from 'ui/Helpers/utils';
import Vendedor from '../../models/VEN/vendedor';
import api from '../api';

export const getClientePagined = async (filters) => {
  const response = await api.get(`/Cliente/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    clientes: value?.map((json) => new Cliente(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getClienteAutoComplete = async (filters) => {
  const response = await api.get(`/Cliente/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    clientes: value?.map((json) => new Cliente(json)) ?? [],
  };
};
export const getClienteAutoCompleteDemanda = async (filters) => {
  const response = await api.get(`/Cliente/SearchAutoCompleteDemanda`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    clientes: value?.map((json) => new Cliente(json)) ?? [],
  };
};

export const getClienteFromCentroDeCustoByEmpresa = async (filters) => {
  const response = await api.get(`/Cliente/SearchFromCentroDeCustoByEmpresa`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    clientes: value?.map((json) => new Cliente(json)) ?? [],
  };
};

export const getClienteAutoCompleteSeller = async (filters) => {
  const response = await api.get(`/Cliente/AutoCompleteSeller`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    clientes: value?.map((json) => new Cliente(json)) ?? [],
  };
};

export const getClienteList = async (filters) => {
  const response = await api.get(`/Cliente/Search`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    clientes: value?.map((json) => new Cliente(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getClienteListWithPermission = async (filters) => {
  const response = await api.get(`/Cliente/SearchClienteWithPermission`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    clientes: value?.map((json) => new Cliente(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getClienteCPF = async (cpf) => {
  const response = await api.get(`/Cliente/GetByCPF`, {
    params: cpf,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    cliente: new Cliente(value),
  };
};

export const getClienteCNPJ = async (cpnj) => {
  const response = await api.get(`/Cliente/GetByCNPJ`, {
    params: cpnj,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    cliente: new Cliente(value),
  };
};

export const getCliente = async (id) => {
  const response = await api.get(`/Cliente/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  const vendedor = new Vendedor();
  const cliente = value ? new Cliente(value) : {};
  if (value?.vendedor) {
    vendedor.noPessoa = value.vendedor.noPessoa;
    vendedor.nrSeqPessoa = value.vendedor.nrSeqPessoaVen;

    cliente.vendedor = vendedor;
  }

  return cliente;
};

export const getClientePessoa = async (id) => {
  const response = await api.get(`Cliente/GetCliente`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Cliente(value) : {};
};

export const saveCliente = async (cliente) => {
  const response = await api.post(`/Cliente/`, cliente);
  return response.data ?? {};
};

export const saveClientePessoa = async (cliente) => {
  const response = await api.post(`/Cliente/SaveClientePessoa`, cliente);
  return response.data ?? {};
};

export const getValidateSelectSeller = async () => {
  const response = await api.get(`/Cliente/validateSelectSeller`);
  return response.data ?? {};
};

export const deleteCliente = async (id) => {
  const response = await api.delete(`/Cliente/`, { params: { id } });
  return response.data ?? {};
};

export const gerateUser = async (data) => {
  const response = await api.post(`/Cliente/GerateUser`, data);
  return response.data ?? {};
};

export const deleteClientes = async (ids) => {
  const response = await api.delete(`/Cliente/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const printClientes = async (filters) => {
  const response = await api.get(`/Cliente/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printClientesPessoa = async (filters) => {
  const response = await api.get(`/Cliente/PrintCliente`, {
    params: filters,
  });
  return response.data ?? {};
};

export const getParametroClienteEmpresa = async () => {
  const response = await api.get(`/Cliente/GetParametroClienteEmpresa`, {});
  const { value } = response.data ?? {};
  return value;
};

export const postUploadClientes = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('extension', MimeTypes.getExtension(file.type));

  const response = await api.post(`Cliente/Upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    clientes: value?.map((json) => new Cliente(json)) ?? [],
  };
};

export const SearchClienteFromChip = async (nrSeqChip) => {
  const response = await api.get('Cliente/SearchFromChip', {
    params: { nrSeqChip },
  });

  const { value } = response.data ?? {};

  return value ? new Cliente(value) : {};
};

export const SearchAniversariantes = async (filters) => {
  const response = await api.get(`/Cliente/SearchAniversariantes`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    clientes: value?.map((json) => new Cliente(json)) ?? [],
  };
};
