import React, { useEffect } from 'react';
import { Button, Modal } from 'ui/components';
import {
  Theme,
  BootstrapSizes,
  formatNumber,
  formatDate,
} from 'ui/Helpers/utils';

export default function modalInformacoesNFEImportada(props) {
  const { data } = props;

  useEffect(() => {}, [data]);

  const onSend = async () => {
    props.onClose();
  };

  const { show, onClose } = props;
  return (
    <Modal
      show={show}
      title='Informações da Nota Fiscal Eletrônica'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body>
        <div className='row mb-1'>
          <div className='col-2'>
            <strong>Tipo Evento</strong>
          </div>
          <div className='col-5'>
            <span>{data.nfeImportadaTipo.noNfeImportadaTipo}</span>
          </div>

          <div className='col-1'>
            <strong>Empresa</strong>
          </div>
          <div className='col-4'>
            <span>{data.empresa.noPessoa}</span>
          </div>
        </div>

        <div className='row mb-1'>
          <div className='col-2'>
            <strong>Chave Eletrônica</strong>
          </div>
          <div className='col-5'>
            <span>{data.chNfe}</span>
          </div>

          <div className='col-1'>
            <strong>NSU</strong>
          </div>
          <div className='col-4'>
            <span>{data.nonsu}</span>
          </div>
        </div>

        <div className='row mb-1'>
          <div className='col-2'>
            <strong>Fornecedor</strong>
          </div>
          <div className='col-5'>
            <span>{data.noFornecedor}</span>
          </div>
          <div className='col-1'>
            <strong>Cadastro</strong>
          </div>
          <div className='col-3'>
            <span>{formatDate(data.dtCad)}</span>
          </div>
        </div>

        <div className='row mb-1'>
          <div className='col-2'>
            <strong>Valor</strong>
          </div>
          <div className='col-5'>
            <span>{formatNumber(data.vlrNfe)}</span>
          </div>

          <div className='col-1'>
            <strong>Documento</strong>
          </div>
          <div className='col-4'>
            <span>{data.nrDocumento}</span>
          </div>
        </div>

        <div className='row mb-1'>
          <div className='col-2'>
            <strong>Usuário</strong>
          </div>
          <div className='col-5'>
            <span>{data.usuario.noLogin}</span>
          </div>

          <div className='col-1'>
            <strong>Pend(Manif)</strong>
          </div>
          <div className='col-3'>
            <span>{data.flgPendenteManifestacao ? 'Sim' : 'Não'}</span>
          </div>
        </div>

        <div className='row mb-1'>
          <div className='col-2'>
            <strong>TpEvento</strong>
          </div>
          <div className='col-5'>
            <span>{data.nrTpEvento}</span>
          </div>

          <div className='col-1'>
            <strong>Dt Evento</strong>
          </div>
          <div className='col-3'>
            <span>{formatDate(data.dtNfe)}</span>
          </div>
        </div>

        <div className='row mb-1'>
          <div className='col-2'>
            <strong>Descrição do Evento</strong>
          </div>
          <div className='col-10'>
            <span>{data.noDescricaoEvento}</span>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button text='Fechar' theme={Theme.Success} onClick={onSend} />
      </Modal.Footer>
    </Modal>
  );
}
