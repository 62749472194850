import React, { useState, useRef, useEffect } from 'react';
import {
  Autocomplete,
  CSDSelPage,
  DropdownMulti,
  GridView,
  RadioButton,
  ToolbarButtons,
} from 'ui/components';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';

import {
  getContratoAgrupadoList,
  getChipAutoComplete,
  getContaRecursoAutoComplete,
  getPlanoAutoComplete,
  getResumoSinteticoParaClientes,
  getCentroDeCustoAutoComplete,
  postGerarPDFConta,
  postImprimirResumoSinteticoParaClientes,
  postExcelResumoSinteticoParaClientes,
} from 'core/services/TEL';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import {
  getFormaPagamentoAutoComplete,
  getClienteAutoComplete,
  postGerarPDFBoleto,
} from 'core/services/FIN';
import ModalOcorrencias from 'ui/pages/FIN/TituloReceber/modalOcorrencias';
import PanelResumo from './panelResumo';
import ModalEnviarFaturasPorEmail from './modalEnviarFaturasPorEmail';
import ModalEnviarFaturasPorSMS from './modalEnviarFaturasPorSMS';
import ModalEnviarFaturasPorWhats from './modalEnviarFaturasPorWhats';

const billStatus = [
  { text: 'Todos', value: 'T' },
  { text: 'Abertos', value: 'A' },
  { text: 'Não Gerados', value: 'N' },
  { text: 'Pagos', value: 'P' },
];

const SelecaoResumoSinteticoParaClientes = (props) => {
  const gridView = useRef();
  const dropDownEmpresas = useRef();
  const dropDownCentroCustos = useRef();

  const [messageState, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ sortBy: 'noPessoaCli asc' }); // filtro default por nome do cliente
  const [resumoSintetico, setResumoSintetico] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [totalLinhasFiltradas, setTotalLinhasFiltradas] = useState(0);
  const [modalShow, setModalShow] = useState({});
  const [selectedRecursos, setSelectedRecursos] = useState([]);
  const [recursoOcorrencia, setRecursoOcorrencia] = useState(null);

  const onSetMessage = (status, message) => {
    if (message)
      setMessage({
        message,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    else setMessage(null);
  };

  useEffect(() => {
    const loadResult = (result) => {
      const { status, message, ...obj } = result;

      if (message) return onSetMessage(status, message);
      return Object.values(obj)[0];
    };

    dropDownEmpresas.current.loadDataSource(async () => {
      const result = await getEmpresaAutoComplete({
        filtrarPorUsuario: true,
      });
      return loadResult(result);
    });
    dropDownCentroCustos.current.loadDataSource(async () => {
      const result = await getCentroDeCustoAutoComplete({
        MontarArvore: true,
      });
      return loadResult(result);
    });
  }, []);

  const onSearch = async () => {
    if (
      !filters.nrSeqContratoAgrupado &&
      !filters.nrSeqChip &&
      !filters.nrSeqPessoaCli
    )
      onSetMessage(
        ResponseStatus.Error,
        'Obrigatório filtro de Contrato Agrupado OU Cliente OU Numero da Linha.'
      );
    else {
      onSetMessage();
      setLoading(true);

      const {
        status,
        message,
        resumoSintetico: result,
        pagination,
      } = await getResumoSinteticoParaClientes(filters);

      onSetMessage(status, message);

      const { recursos, ...resumo } = result;

      if (gridView && gridView.current)
        gridView.current.setDataSource(recursos, pagination);

      setResumoSintetico(resumo);
      setTotalLinhasFiltradas(pagination?.totalRows ?? 0);

      setLoading(false);
    }
  };

  const onPageChange = async (page) => {
    setFilters({ ...filters, page });

    await onSearch({ ...filters, page });
  };
  const onColumnSort = async (sortBy) => {
    setFilters({ ...filters, sortBy });

    await onSearch({ ...filters, sortBy });
  };
  const onPageSizeChange = async (totalByPage) => {
    setFilters({ ...filters, totalByPage });
    await onSearch({ ...filters, totalByPage });
  };

  const onSearchContratoAgrupado = async (noContratoAgrupado) => {
    const {
      status,
      message,
      contratosAgrupados,
    } = await getContratoAgrupadoList({ noContratoAgrupado });
    onSetMessage(status, message);
    return contratosAgrupados;
  };

  const onAutoCompleteNumeroLinha = async (nrPrefixoLinha) => {
    if (filters.contratoAgrupado) {
      const {
        status,
        message,
        contaRecursos,
      } = await getContaRecursoAutoComplete({
        nrPrefixoLinha,
        nrSeqContratoAgrupado: filters.nrSeqContratoAgrupado,
      });
      onSetMessage(status, message);
      return contaRecursos;
    }

    const { status, message, chips } = await getChipAutoComplete({
      nrPrefixoLinha,
    });
    onSetMessage(status, message);
    return chips;
  };

  const onAutoCompleteFormaPagamento = async (noFormaPagamento) => {
    const {
      status,
      message,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento,
    });
    onSetMessage(status, message);
    return formasPagamento;
  };

  const onAutoCompleteCliente = async (noPessoa) => {
    const { status, message, clientes } = await getClienteAutoComplete({
      noPessoa,
    });
    onSetMessage(status, message);
    return clientes;
  };

  const onAutoCompletePlano = async (noPlano) => {
    const { status, message, planos } = await getPlanoAutoComplete({
      noPlano,
    });
    onSetMessage(status, message);
    return planos;
  };

  const onClickPrintConta = async (selectedValue) => {
    const { transaction, onOpenReport } = props;
    setLoading(true);
    const { status, message, pathConta } = await postGerarPDFConta([
      selectedValue.nrSeqContaRecurso,
    ]);

    onSetMessage(status, message);
    setLoading(false);
    if (!message) onOpenReport(transaction.noTransacao, pathConta);
  };

  const handleClickImprimir = async () => {
    const { transaction, onOpenReport } = props;
    setLoading(true);
    const {
      value,
      status,
      message,
    } = await postImprimirResumoSinteticoParaClientes({
      ...filters,
      visibleColumns: gridView.current
        .getColumns()
        .filter((p) => p.visible !== false)
        .map((p) => p.key),
    });

    onSetMessage(status, message);

    if (value) onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const handleClickImprimirBoletosEContas = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    const { transaction, onOpenReport } = props;
    setLoading(true);

    const boleto = await postGerarPDFBoleto(
      selecteds.map(
        (row) => row.find((column) => column.key === 'nrSeqFatura').value
      )
    );

    if (boleto.length) onOpenReport(transaction.noTransacao, boleto);

    const {
      status: statusPDFConta,
      message: messagePDFConta,
      pathConta,
    } = await postGerarPDFConta(
      selecteds.map(
        (row) => row.find((column) => column.key === 'nrSeqContaRecurso').value
      )
    );

    if (messagePDFConta) onSetMessage(statusPDFConta, messagePDFConta);
    else
      onOpenReport(transaction.noTransacao, pathConta, {
        id: 'report2',
        title: 'Conta em PDF',
        icon: 'print',
        index: 5,
      });

    setLoading(false);
  };

  const handleClickImprimirBoleto = async (e) => {
    const { transaction, onOpenReport } = props;
    setLoading(true);

    const nrseqs = [];
    nrseqs.push(e.nrSeqFatura);
    const boleto = await postGerarPDFBoleto(nrseqs);

    onOpenReport(transaction.noTransacao, boleto);

    setLoading(false);
  };

  const handleClickExcel = async () => {
    setLoading(true);
    const {
      value: base64,
      status,
      message,
    } = await postExcelResumoSinteticoParaClientes({
      ...filters,
      visibleColumns: gridView.current
        .getColumns()
        .filter((p) => p.visible !== false)
        .map((p) => p.key),
    });

    onSetMessage(status, message);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `ResumoSintetico.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const onOpenEnviarEmail = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Nenhuma linha selecionada para enviar email.'
      );
    else {
      const recursos = selecteds.map((column) => ({
        nrSeqContaRecurso: column.find(
          (prop) => prop.key === 'nrSeqContaRecurso'
        ).value,
        numeroCompleto: column.find((prop) => prop.key === 'numeroCompleto')
          .value,
      }));

      setSelectedRecursos(recursos);
      setModalShow({ ...modalShow, enviarFaturasPorEmail: true });
    }
  };

  const onOpenEnviarSMS = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Nenhuma linha selecionada para enviar SMS.'
      );
    else {
      const recursos = selecteds.map((column) => ({
        nrSeqContaRecurso: column.find(
          (prop) => prop.key === 'nrSeqContaRecurso'
        ).value,
        numeroCompleto: column.find((prop) => prop.key === 'numeroCompleto')
          .value,
      }));

      setSelectedRecursos(recursos);
      setModalShow({ ...modalShow, enviarFaturasPorSMS: true });
    }
  };

  const onOpenEnviarWhats = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Nenhuma linha selecionada para enviar Whatsapp.'
      );
    else {
      const recursos = selecteds.map((column) => ({
        nrSeqContaRecurso: column.find(
          (prop) => prop.key === 'nrSeqContaRecurso'
        ).value,
        numeroCompleto: column.find((prop) => prop.key === 'numeroCompleto')
          .value,
      }));

      setSelectedRecursos(recursos);
      setModalShow({ ...modalShow, enviarFaturasPorWhats: true });
    }
  };

  const columns = [
    { key: 'nrSeqContaRecurso', type: GridView.ColumnTypes.Checkbox },
    { key: 'cdContratoDTFatura', title: 'Contrato / Fatura', visible: false },
    { key: 'noPessoaCli', title: 'Cliente' },
    { key: 'noClienteConta', title: 'Cliente Conta', visible: false },
    { key: 'matricula', title: 'Matrícula', visible: false },
    { key: 'codNoCentroCusto', title: 'Centro de Custo', visible: false },
    { key: 'email', title: 'E-mail', visible: false },
    { key: 'noFormaPagamento', title: 'Forma Pagamento', visible: false },
    { key: 'infoBancaria', title: 'Infos Bancárias', visible: false },
    { key: 'noPlano', title: 'Plano' },
    { key: 'noEmpresa', title: 'Empresa', visible: false },
    { key: 'numeroCompleto', title: 'Número' },
    {
      key: 'qtdeTotalVoz',
      title: 'Voz',
      visible: false,
    },
    { key: 'qtdeTotalDados', title: 'Dados', visible: false },
    { key: 'qtdeTotalSms', title: 'SMS', visible: false },
    { key: 'noPacoteDados', title: 'Pacote Dados', visible: false },
    {
      key: 'vlrRateioEmpresa',
      title: 'Vlr. Rateio',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotalRecalculo',
      title: 'Conta Recalculada',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTaxasBancarias',
      title: 'Taxas Bancarias',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTaxaSMS',
      title: 'Taxas SMS',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrResultadoRateioComissao',
      title: 'Resultado',
      format: GridView.DataTypes.Decimal,
    },
    { key: 'nrDocumento', title: 'Nº Documento', visible: false },
    { key: 'logradouro', title: 'Logradouro', visible: false },
    {
      key: 'dtVencimentoTituloReceber',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'noTituloRecebido',
      title: 'Pago',
      bgClassProperty: 'bgStatusFinanceiro',
    },
    {
      key: 'ocorrencias',
      title: 'Ocorrências',
    },
    {
      key: 'nrSeqFatura',
      type: GridView.ColumnTypes.Button,
      onClick: handleClickImprimirBoleto,
      onVisible: (e) => e.nrSeqFatura > 0,
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir Boleto',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqContaRecurso',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => {
        setModalShow({ ...modalShow, ocorrencias: true });
        setRecursoOcorrencia(e.nrSeqTituloReceber);
      },
      theme: Theme.Secondary,
      icon: ['far', 'comment-dots'],
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Gerar / Visualizar Ocorrencias',
      tooltipDirection: 'bottom',
    },

    {
      key: 'nrSeqContaRecurso',
      type: GridView.ColumnTypes.Button,
      onClick: onClickPrintConta,
      theme: Theme.Primary,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir Conta Detalhada',
      tooltipDirection: 'bottom',
    },
  ];

  const { isActive, transaction } = props;
  return (
    <CSDSelPage
      isActive={isActive}
      title='Resumo Sintético Clientes'
      loading={loading}
      onSearch={() => onSearch()}
      message={messageState}
      onMessagerClose={() => setMessage(null)}
      onPrint={handleClickImprimir}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.ButtonList
          icon='caret-down'
          items={[
            {
              text: 'Imprimir Contas / Boletos',
              onClick: handleClickImprimirBoletosEContas,
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Excel'
          icon={['far', 'file-excel']}
          onClick={handleClickExcel}
        />
        <ToolbarButtons.Button
          text='Enviar Email'
          icon='envelope'
          onClick={onOpenEnviarEmail}
          tooltip='Envia email para os clientes conforme email cadastrado no cliente e mensagem a selecionar'
        />
        <ToolbarButtons.Button
          text='Enviar SMS'
          icon={['far', 'comment-alt']}
          onClick={onOpenEnviarSMS}
          tooltip='Envia SMS para os clientes conforme mensagem a selecionar'
        />
        <ToolbarButtons.Button
          text='Enviar Whatsapp'
          icon={['fab', 'whatsapp']}
          onClick={onOpenEnviarWhats}
          tooltip='Envia Whatsapp para os clientes conforme mensagem a selecionar'
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Contrato Agrupado'
            searchDataSource={onSearchContratoAgrupado}
            selectedItem={selectedItems.contratoAgrupado}
            onSelectItem={(contratoAgrupado) => {
              setSelectedItems({ ...selectedItems, contratoAgrupado });
              setFilters({
                ...filters,
                nrSeqContratoAgrupado: contratoAgrupado.nrSeqContratoAgrupado,
              });
            }}
            dataSourceTextProperty='noContratoAgrupado'
          />
        </div>
        <div className='col'>
          <DropdownMulti
            label='Empresas do chip'
            ref={dropDownEmpresas}
            dataSourcePropertyText='noPessoa'
            dataSourcePropertyValue='nrSeqEmpresa'
            selectedItems={selectedItems.empresas ?? []}
            onSelectItem={(empresas) => {
              setSelectedItems({ ...selectedItems, empresas });
              setFilters({
                ...filters,
                empresas: empresas?.map((p) => p.nrSeqEmpresa),
              });
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Número da Linha'
            searchDataSource={onAutoCompleteNumeroLinha}
            selectedItem={selectedItems.chipOuContaRecurso}
            onSelectItem={(chipOuContaRecurso) => {
              setSelectedItems({ ...selectedItems, chipOuContaRecurso });
              setFilters({
                ...filters,
                nrSeqChip: chipOuContaRecurso.nrSeqChip,
              });
            }}
            dataSourceTextProperty='linhaFormatada'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Forma de Pagamento'
            searchDataSource={onAutoCompleteFormaPagamento}
            selectedItem={selectedItems.formaPagamento}
            onSelectItem={(formaPagamento) => {
              setSelectedItems({ ...selectedItems, formaPagamento });
              setFilters({
                ...filters,
                nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
              });
            }}
            dataSourceTextProperty='noFormaPagamento'
          />
        </div>
        <div className='col'>
          <DropdownMulti
            label='Centros de Custo'
            ref={dropDownCentroCustos}
            dataSourcePropertyText='noCentroDeCusto'
            dataSourcePropertyValue='nrSeqCentroDeCusto'
            selectedItems={selectedItems.centrosCusto ?? []}
            onSelectItem={(centrosCusto) => {
              setSelectedItems({ ...selectedItems, centrosCusto });
              setFilters({
                ...filters,
                centrosCusto: centrosCusto?.map((p) => p.nrSeqCentroDeCusto),
              });
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Cliente'
            searchDataSource={onAutoCompleteCliente}
            selectedItem={selectedItems.cliente}
            onSelectItem={(cliente) => {
              setSelectedItems({ ...selectedItems, cliente });
              setFilters({
                ...filters,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Plano'
            searchDataSource={onAutoCompletePlano}
            dataSourceTextProperty='noPlano'
            selectedItem={selectedItems.plano}
            onSelectItem={(plano) => {
              setSelectedItems({ ...selectedItems, plano });
              setFilters({
                ...filters,
                planos: [plano.nrSeqPlano],
              });
            }}
          />
        </div>
        <div className='col-auto'>
          <RadioButton
            label='Status do Titulo'
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={billStatus}
            selectedButton={filters.filtroInfoPgto || 'T'}
            onChange={(filtroInfoPgto) =>
              setFilters({
                ...filters,
                filtroInfoPgto,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <PanelResumo
            resumoParaClientes={resumoSintetico}
            totalLinhasFiltradas={totalLinhasFiltradas}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            enableExcelExport
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            canControlVisibility
            onPageChange={onPageChange}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            transaction={transaction}
          />
        </div>
      </div>
      <ModalEnviarFaturasPorEmail
        show={modalShow.enviarFaturasPorEmail}
        recursos={selectedRecursos}
        onClose={() =>
          setModalShow({ ...modalShow, enviarFaturasPorEmail: false })
        }
        onSave={(status, message) => onSetMessage(status, message)}
      />
      <ModalEnviarFaturasPorSMS
        show={modalShow.enviarFaturasPorSMS}
        recursos={selectedRecursos}
        onClose={() =>
          setModalShow({ ...modalShow, enviarFaturasPorSMS: false })
        }
        onSave={(status, message) => onSetMessage(status, message)}
      />
      <ModalEnviarFaturasPorWhats
        show={modalShow.enviarFaturasPorWhats}
        recursos={selectedRecursos}
        onClose={() =>
          setModalShow({ ...modalShow, enviarFaturasPorWhats: false })
        }
        onSave={(status, message) => onSetMessage(status, message)}
      />
      <ModalOcorrencias
        show={modalShow.ocorrencias}
        nrSeqTituloReceber={recursoOcorrencia}
        onClose={() => {
          setRecursoOcorrencia(null);
          setModalShow({ ...modalShow, ocorrencias: false });
        }}
        onSave={(message) => {
          setMessage({ message, theme: Theme.Success });
          setRecursoOcorrencia(null);
          setModalShow({ ...modalShow, ocorrencias: false });
        }}
      />
    </CSDSelPage>
  );
};

export default SelecaoResumoSinteticoParaClientes;
