import { GetManutencaoVeiculoSemAcerto } from 'core/services/FRO/manutencaoVeiculo';
import React, { useRef, forwardRef, useState, useEffect } from 'react';
import { DatePicker, Button, GridView, Notification } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { ResponseStatus, Theme } from 'ui/Helpers/utils';
import { RecalculaResumo } from 'core/services/FIN/acertoMotorista';
import ModalGeraManutencaoVeiculo from './ModalGeraManutencaoVeiculo';

const ManutencaoModal = forwardRef(({ data, registryKey, setData, acerto }) => {
  const [dataCadastroCliente, setDataCadastroCliente] = useState({});
  const [modalShow, setModalShow] = useState({});
  const gridView = useRef();
  const gridView2 = useRef();
  const [message, onSetMessage] = useState(null);

  const setMessage = (status, msg) => {
    if (msg)
      onSetMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const columns = [
    {
      key: 'nrSeqManutencaoVeiculo',
      type: GridView.ColumnTypes.Checkbox,
    },
    {
      key: 'oficina.noPessoa',
      title: 'Oficina',
    },
    {
      key: 'nrDocumento',
      title: 'Doc',
    },
    {
      key: 'dtDocumento',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'vlrTotal',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
  ];
  const columns2 = [
    {
      key: 'nrSeqManutencaoVeiculo',
      type: GridView.ColumnTypes.Checkbox,
    },
    {
      key: 'oficina.noPessoa',
      title: 'Oficina',
    },
    {
      key: 'nrDocumento',
      title: 'Doc',
    },
    {
      key: 'dtDocumento',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'vlrTotal',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
  ];

  const load = async () => {
    if (data !== undefined) {
      if (data.manutencaoVeiculoS !== undefined) {
        if (gridView2 && gridView2.current)
          gridView2.current.setDataSource(data.manutencaoVeiculoS);
      }
    }
  };

  const onSearchManutencao = async () => {
    if (!data.nrSeqVeiculoPrinc > 0) {
      setMessage(
        ResponseStatus.Error,
        'Não é possivel buscar uma manutenção sem selecionar o veiculo'
      );
      return;
    }

    const obj = {
      ...dataCadastroCliente,
      nrSeqVeiculo: data.nrSeqVeiculoPrinc,
      nrSeqVeiculoComp: data.nrSeqVeiculoComp,
      nrSeqVeiculoCompB: data.nrSeqVeiculoCompB,
      nrSeqVeiculoCompC: data.nrSeqVeiculoCompC,
    };
    const manutencaoVeiculoS = await GetManutencaoVeiculoSemAcerto(obj);

    let clonedList = [];
    if (manutencaoVeiculoS !== undefined && manutencaoVeiculoS !== null) {
      clonedList = [...manutencaoVeiculoS];
    }
    const tabela2 = gridView2.current.getDataSource();

    if (tabela2 !== null && tabela2 !== undefined) {
      for (let j = 0; j < tabela2.length; ) {
        clonedList = clonedList.filter(
          (item) =>
            item.nrSeqManutencaoVeiculo !== tabela2[j].nrSeqManutencaoVeiculo
        );

        j += 1;
      }
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(clonedList);
  };

  const onSearchRight = async () => {
    const importacaocerta = [];
    let placas = [];
    let manutencaoNaoAdicionada = [];

    const nrSeqVendaDiariaS = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    if (nrSeqVendaDiariaS.length === 0) {
      onSetMessage(ResponseStatus.Error, 'Selecione um item para atualizar ');
      return;
    }

    const datasource = gridView.current.getDataSource();

    const tabelacerta = gridView2.current.getDataSource();

    if (tabelacerta !== null) {
      if (tabelacerta.length > 0) {
        importacaocerta.push(...tabelacerta);
      }
    }

    manutencaoNaoAdicionada = gridView.current.getDataSource();

    for (let i = 0; i < nrSeqVendaDiariaS.length; ) {
      placas = manutencaoNaoAdicionada.filter(
        (Placa) => Placa.nrSeqManutencaoVeiculo !== nrSeqVendaDiariaS[i]
      );

      manutencaoNaoAdicionada = placas;
      i += 1;
    }

    const datasourcefiltrado = datasource.filter((item) =>
      nrSeqVendaDiariaS.includes(item.nrSeqManutencaoVeiculo, 0)
    );

    if (datasourcefiltrado !== null) {
      for (let i = 0; i < datasourcefiltrado.length; ) {
        if (tabelacerta !== null) {
          for (let j = 0; j < tabelacerta.length; ) {
            if (
              datasourcefiltrado[i].nrSeqManutencaoVeiculo ===
                tabelacerta[j].nrSeqManutencaoVeiculo &&
              datasourcefiltrado[i].nrSeqAcertoMotorista > 0
            ) {
              datasourcefiltrado.pop(datasourcefiltrado[i]);
              tabelacerta[i].status = GridView.EnumStatus.Alterar;
            } else if (datasourcefiltrado[i].status === null) {
              datasourcefiltrado[i].status = GridView.EnumStatus.Inserir;
            }

            j += 1;
          }
        }
        i += 1;
      }
    }

    importacaocerta.push(...datasourcefiltrado);

    if (gridView && gridView.current)
      gridView.current.setDataSource(manutencaoNaoAdicionada);

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(importacaocerta);

    const nrSeqAcerto = data.nrSeqAcertoMotorista;

    const valorObj = { ...data, manutencaoVeiculoS: importacaocerta };

    const obj = await RecalculaResumo(valorObj);

    if (obj.nrSeqAcertoMotorista > 0) {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: obj.outrasReceitasComissao,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalReceitas: obj.totalReceitas,
        totalPeso: obj.totalPeso,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    } else {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        nrSeqAcertoMotorista: nrSeqAcerto,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: obj.outrasReceitasComissao,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalPeso: obj.totalPeso,
        totalReceitas: obj.totalReceitas,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    }
  };

  const onClickOpenManutencaoVeiculo = async () => {
    setModalShow({
      ...modalShow,
      geraManutencao: true,
      geraManutencaoValor: data,
      vlrAcerto: acerto,
    });
  };

  const onSearchLeft = async () => {
    const importacaocerta = [];
    let placas = [];
    let manutencaoAdicionada = [];

    const nrSeqVendaDiariaS = gridView2.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    if (nrSeqVendaDiariaS.length === 0) {
      onSetMessage(ResponseStatus.Error, 'Selecione um item para atualizar ');
      return;
    }

    const datasource = gridView.current.getDataSource();

    const tabelacerta = gridView2.current.getDataSource();

    importacaocerta.push(...datasource);

    manutencaoAdicionada = gridView2.current.getDataSource();

    for (let i = 0; i < nrSeqVendaDiariaS.length; ) {
      placas = manutencaoAdicionada.filter(
        (Placa) => Placa.nrSeqManutencaoVeiculo !== nrSeqVendaDiariaS[i]
      );

      manutencaoAdicionada = placas;
      i += 1;
    }

    const datasourcefiltrado = tabelacerta.filter((item) =>
      nrSeqVendaDiariaS.includes(item.nrSeqManutencaoVeiculo, 0)
    );

    const datasourcefiltradoList2 = tabelacerta.filter((item) =>
      nrSeqVendaDiariaS.includes(item.nrSeqManutencaoVeiculo, 0)
    );

    for (let i = 0; i < datasourcefiltrado.length; ) {
      if (datasourcefiltrado[i].nrSeqAcertoMotorista > 0) {
        datasourcefiltrado[i].status = GridView.EnumStatus.Alterar;
      }
      i += 1;
    }

    importacaocerta.push(...datasourcefiltrado);

    if (gridView && gridView.current)
      gridView.current.setDataSource(importacaocerta);

    for (let i = 0; i < datasourcefiltradoList2.length; ) {
      if (datasourcefiltradoList2[i].nrSeqAcertoMotorista > 0) {
        datasourcefiltradoList2[i].status = GridView.EnumStatus.Remover;
        manutencaoAdicionada.push(datasourcefiltradoList2[i]);
      }
      i += 1;
    }

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(manutencaoAdicionada);

    const nrSeqAcerto = data.nrSeqAcertoMotorista;

    const valorObj = { ...data, manutencaoVeiculoS: manutencaoAdicionada };

    const obj = await RecalculaResumo(valorObj);

    if (obj.nrSeqAcertoMotorista > 0) {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: obj.outrasReceitasComissao,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalReceitas: obj.totalReceitas,
        totalPeso: obj.totalPeso,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    } else {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        nrSeqAcertoMotorista: nrSeqAcerto,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: obj.outrasReceitasComissao,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalPeso: obj.totalPeso,
        totalReceitas: obj.totalReceitas,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    }
  };

  useEffect(() => {
    (async function func() {
      load();
    })();
  }, [data.nrSeqPessoaCli, registryKey]);

  return (
    <div>
      {message && (
        <div className='mb-3'>
          <Notification
            message={message.message}
            theme={message.theme}
            onClose={() => onSetMessage(null)}
          />
        </div>
      )}
      <div className='row mb-3'>
        <div className='display-8'>4 - Manutenção</div>
      </div>
      <div className='card'>
        <div className='card-body'>
          <div className='row mb-3'>
            <div className='row'>
              <div className='row mb-3'>
                <div className='col-2 mb-3'>
                  <DatePicker
                    label='De'
                    text={dataCadastroCliente.dtManutencaoInicial}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dtManutencaoInicial) =>
                      setDataCadastroCliente({
                        ...dataCadastroCliente,
                        dtManutencaoInicial,
                      })
                    }
                  />
                </div>
                <div className='col-2 mb-3'>
                  <DatePicker
                    label='Ate'
                    text={dataCadastroCliente.dtManutencaofinal}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dtManutencaofinal) =>
                      setDataCadastroCliente({
                        ...dataCadastroCliente,
                        dtManutencaofinal,
                      })
                    }
                  />
                </div>
                <div className='col-1 mt-3'>
                  <Button
                    outline
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    icon='search'
                    onClick={onSearchManutencao}
                  />
                </div>
                <div className='col-2 mt-3'>
                  <Button
                    outline
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    icon='plus'
                    text='Novo'
                    onClick={onClickOpenManutencaoVeiculo}
                  />
                </div>
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-5'>
                <GridView
                  ref={gridView}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
              <div className='col-1 mt-3 mb-3'>
                <Button
                  outline
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  icon='plus'
                  text='Adicionar'
                  onClick={onSearchRight}
                />
                <Button
                  outline
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  icon='arrow-circle-left'
                  text='Remover'
                  onClick={onSearchLeft}
                />
              </div>
              <div className='col-5'>
                <GridView
                  ref={gridView2}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns2}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
            <ModalGeraManutencaoVeiculo
              show={modalShow.geraManutencao}
              geraManutencaoValor={modalShow.geraManutencaoValor}
              vlrAcerto={modalShow.vlrAcerto}
              onSave={(geraManutencaoValor) => {
                setData({ ...data, geraManutencaoValor });
                setModalShow({
                  ...modalShow,
                  geraManutencaoValor: modalShow.geraManutencaoValor,
                });
              }}
              onClose={({ message: msg, theme }) => {
                setModalShow({
                  ...modalShow,
                  geraManutencao: false,
                });
                onSetMessage({
                  message: msg,
                  theme,
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
export default ManutencaoModal;
