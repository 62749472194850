import React, { useState, useEffect, useRef } from 'react';
import { dateNow, BootstrapSizes, Theme } from 'ui/Helpers/utils';
import {
  Button,
  Loader,
  Modal,
  Notification,
  Autocomplete,
  Textbox,
  DatePicker,
  GridView,
} from 'ui/components';
import { ResponseStatus, ColumnTypes } from 'ui/Helpers/enums.ts';
import { Message, GridviewColumns } from 'ui/Helpers/interfaces.ts';
import {
  getApoliceSeguroSearchAutoComplete,
  gerateApoliceSeguro,
} from 'core/services/FRO/apoliceSeguro';
import { getTipoSeguroAutoComplete } from 'core/services/FRO/tipoSeguro';
import { getAverbadoraSeguroAutoComplete } from 'core/services/FRO/averbadoraSeguro';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { IApoliceSeguro } from 'core/interfaces/FRO';
import { MaskTypes } from 'ui/Helpers/masks';

const ModalGerateMassivoApoliceSeguro = ({ show, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [data, setData] = useState<IApoliceSeguro | any>({});
  const gridViewEmpresa = useRef<any>();

  const onSetMessage = (status: ResponseStatus, msg: string = ''): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const loadEmpresas = async () => {
    setLoading(true);
    try {
      const { empresas } = await getEmpresaAutoComplete({});
      if (gridViewEmpresa.current) {
        gridViewEmpresa.current.setDataSource(empresas);
      }
      setData({
        vlrFranquia: 0,
        vlrIndPessoal: 0,
        vlrIndMaterial: 0,
        vlrApolice: 0,
      });
    } catch (error) {
      onSetMessage(ResponseStatus.Error, 'Erro ao carregar empresas.');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (show) {
      loadEmpresas();
    }
  }, [show]);

  const validateItemsApoliceSeguro = (datasource: any) => {
    if (!datasource.nrSeqPessoaFor) {
      onSetMessage(ResponseStatus.Error, 'Selecionar o Fornecedor.');
      return false;
    }

    if (!datasource.nrSeqAverbadoraSeguro) {
      onSetMessage(ResponseStatus.Error, 'Selecionar a Seguradora.');
      return false;
    }

    if (!datasource.nrSeqTipoSeguro) {
      onSetMessage(ResponseStatus.Error, 'Selecionar a Tipo Seguradora.');
      return false;
    }

    if (datasource.nrApolice === null || datasource.nrApolice === undefined) {
      onSetMessage(ResponseStatus.Error, 'Informar o Código.');
      return false;
    }

    if (
      datasource.dtInicioVigencia === null ||
      datasource.dtInicioVigencia === undefined
    ) {
      onSetMessage(ResponseStatus.Error, 'Informar a data inicio de vigencia.');
      return false;
    }

    if (
      datasource.dtFimVigencia === null ||
      datasource.dtFimVigencia === undefined
    ) {
      onSetMessage(ResponseStatus.Error, 'Informar a data inicio de vigencia.');
      return false;
    }

    return true;
  };

  const onSave = async () => {
    setLoading(true);
    const selecteds = gridViewEmpresa.current.getCheckBoxValuesAt(0);

    if (validateItemsApoliceSeguro(data)) {
      if (selecteds.length > 0) {
        const { status, message: msg } = await gerateApoliceSeguro({
          ...data,
          ids: selecteds.map((row: any) => row[0].value),
        });
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
      } else {
        setMessage({
          message:
            'Não há registros selecionados de empresas para a geração de apolices.',
          theme: Theme.Danger,
        });
      }
    }

    setLoading(false);
  };

  const onSearchFornecedor = async (e: any) => {
    const { data: fornecedor } = await getFornecedorAutoComplete({
      noPessoa: e,
    });
    return fornecedor;
  };

  const onSearchApoliceSeguroPai = async (e: any) => {
    const { data: apoliceSeguroPai } = await getApoliceSeguroSearchAutoComplete(
      {
        nrApolice: e,
        dtInicioVigencia: dateNow(),
        dtFimVigencia: dateNow(),
      }
    );

    return apoliceSeguroPai;
  };

  const onSearchAverbadoraSeguro = async (e: any) => {
    const { data: averboSeguro } = await getAverbadoraSeguroAutoComplete({
      noAverboSeguro: e,
    });

    return averboSeguro;
  };

  const onSearchTipoSeguro = async (e: any) => {
    const { data: tipoSeguros } = await getTipoSeguroAutoComplete({
      noTipoSeguro: e,
    });

    return tipoSeguros;
  };

  const columnsEmpresa: Array<GridviewColumns> = [
    { key: 'nrSeqPessoaEmp', type: ColumnTypes.Checkbox },
    {
      key: 'noPessoa',
      title: 'Empresa',
    },
  ];

  return (
    <Modal
      show={show}
      size={BootstrapSizes.ExtraLarge}
      title='Informar os valores de massivo'
      onClose={onClose}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col-md-12' style={{ height: '400px' }}>
            <div className='col mt-3'>
              <div className='col-5 mb-3'>
                <Autocomplete
                  label='Fornecedor (Seguradora)'
                  searchDataSource={onSearchFornecedor}
                  selectedItem={data?.fornecedor}
                  onSelectItem={(fornecedor: any) => {
                    setData({
                      ...data,
                      fornecedor,
                      nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-5'>
                <Autocomplete
                  label='Apólice Associada (Pai)'
                  searchDataSource={onSearchApoliceSeguroPai}
                  selectedItem={data?.apoliceSeguroPai}
                  onSelectItem={(apoliceSeguroPai: any) => {
                    setData({
                      ...data,
                      apoliceSeguroPai,
                      nrSeqApoliceSeguroPai:
                        apoliceSeguroPai.nrSeqApoliceSeguro,
                    });
                  }}
                  dataSourceTextProperty='noAutoComplete'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-3'>
                <Autocomplete
                  label='Seguradora'
                  searchDataSource={onSearchAverbadoraSeguro}
                  selectedItem={data?.averbadoraSeguro}
                  onSelectItem={(averbadoraSeguro: any) => {
                    setData({
                      ...data,
                      averbadoraSeguro,
                      nrSeqAverbadoraSeguro:
                        averbadoraSeguro.nrSeqAverbadoraSeguro,
                    });
                  }}
                  dataSourceTextProperty='noAverbadoraSeguro'
                />
              </div>
              <div className='col-3'>
                <Autocomplete
                  label='Tipo Seguro'
                  searchDataSource={onSearchTipoSeguro}
                  selectedItem={data?.tipoSeguro}
                  onSelectItem={(tipoSeguro: any) => {
                    setData({
                      ...data,
                      tipoSeguro,
                      nrSeqTipoSeguro: tipoSeguro.nrSeqTipoSeguro,
                    });
                  }}
                  dataSourceTextProperty='noTipoSeguro'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-3 mb-3'>
                {/* @ts-expect-error */}
                <Textbox
                  maxLength={40}
                  label='* Código'
                  text={data?.nrApolice}
                  onChangedValue={(nrApolice: string) =>
                    setData({ ...data, nrApolice })
                  }
                />
              </div>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <DatePicker
                  label='Data Início Vigência'
                  text={data?.dtInicioVigencia}
                  onChange={(dtInicioVigencia: string) =>
                    setData({ ...data, dtInicioVigencia })
                  }
                />
              </div>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <DatePicker
                  label='Data Término Vigência'
                  text={data?.dtFimVigencia}
                  onChange={(dtFimVigencia: string) =>
                    setData({ ...data, dtFimVigencia })
                  }
                />
              </div>
              <div className='col-3 mb-3'>
                {/* @ts-expect-error */}
                <Textbox
                  maxLength={9}
                  label='* Valor Franquia'
                  mask={MaskTypes.DecimalCustom}
                  text={data?.vlrFranquia}
                  onChangedValue={(vlrFranquia: string) =>
                    setData({ ...data, vlrFranquia })
                  }
                />
              </div>
              <div className='col-3 mb-3'>
                {/* @ts-expect-error */}
                <Textbox
                  maxLength={9}
                  label='* Valor Indenização Pessoal'
                  mask={MaskTypes.DecimalCustom}
                  text={data?.vlrIndPessoal}
                  onChangedValue={(vlrIndPessoal: string) =>
                    setData({ ...data, vlrIndPessoal })
                  }
                />
              </div>
              <div className='col-3 mb-3'>
                {/* @ts-expect-error */}
                <Textbox
                  maxLength={9}
                  label='* Valor Indenização Material'
                  mask={MaskTypes.DecimalCustom}
                  text={data?.vlrIndMaterial}
                  onChangedValue={(vlrIndMaterial: string) =>
                    setData({ ...data, vlrIndMaterial })
                  }
                />
              </div>
              <div className='col-3 mb-3'>
                {/* @ts-expect-error */}
                <Textbox
                  maxLength={9}
                  label='* Valor Apólice'
                  mask={MaskTypes.DecimalCustom}
                  text={data?.vlrApolice}
                  onChangedValue={(vlrApolice: string) =>
                    setData({ ...data, vlrApolice })
                  }
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <GridView
                  ref={gridViewEmpresa}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsEmpresa}
                  showPagination={false}
                  showSelectSizes={false}
                  offlineData
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-3' />
      </Modal.Body>
      <Modal.Footer>
        <Button
          icon={['far', 'save']}
          text='Gerar Massivo Apólice Seguro'
          theme={Theme.Success}
          onClick={onSave}
        />
        <Button
          icon='ban'
          text='Cancelar'
          theme={Theme.Danger}
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default ModalGerateMassivoApoliceSeguro;
