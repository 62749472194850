import React, { useRef } from 'react';
import { MimeTypes } from 'ui/Helpers/utils';
import Dropzone from './dropzone';
import Single from './single';

function getIconByFileType(fileType) {
  if (MimeTypes.isPdf(fileType))
    return { icon: ['far', 'file-pdf'], className: 'text-danger' };
  if (MimeTypes.isExcel(fileType))
    return { icon: ['far', 'file-excel'], className: 'text-success' };
  if (MimeTypes.isImage(fileType))
    return { icon: ['far', 'image'], className: 'text-warning' };
  if (MimeTypes.isWord(fileType))
    return { icon: ['far', 'file-word'], className: 'text-info' };
  if (MimeTypes.isXml(fileType))
    return { icon: ['far', 'file-code'], className: 'text-primary' };

  return { icon: 'question', className: 'text-secondary' };
}

export default function FileUpload(props) {
  const fileupload = useRef();
  const { allowedMimeTypes = [], dropzone = false, files } = props;
  let { visible } = props;
  visible = visible ?? true;

  const inputFileCustomProps = {
    ref: fileupload,
    className: 'd-none',
    type: 'file',
  };
  if (dropzone) inputFileCustomProps.multiple = 'multiple';

  if (allowedMimeTypes.length !== 0)
    inputFileCustomProps.accept = MimeTypes.getAcceptedTypes(allowedMimeTypes);

  return visible ? (
    <>
      {React.createElement('input', inputFileCustomProps)}
      {React.createElement(dropzone ? Dropzone : Single, {
        ...props,
        files: files ?? [],
        ref: fileupload,
        getIconByFileType,
      })}
    </>
  ) : null;
}
