import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { Panel, Autocomplete, GridView, Button, Textbox } from 'ui/components';
import { BootstrapSizes } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
// @ts-expect-error
import { Theme, ResponseStatus, StatusGrid } from 'ui/Helpers/enums.ts';
import { getPerfilAutoComplete } from 'core/services/SEG/perfil';
import { getPerfilDeAcessoAutoComplete } from 'core/services/SEG/perfilDeAcesso';
import { getEmpresaAutoCompleteWithOutPerfil } from 'core/services/SEG/empresa';
import { getCentroDeCustoAutoCompleteSimples } from 'core/services/TEL/centroDeCusto';
import { getTransacaAutoComplete } from 'core/services/SEG/transacao';
import UsuarioPerfil from 'core/models/SEG/usuarioPerfil';
import UsuarioTransacaoInicial from 'core/models/SEG/usuario_TransacaoInicial';
import UsuarioCentroDeCusto from 'core/models/FIS/usuario_CentroDeCusto';

interface Props {
  usuarioData: any;
  setUsuarioData: (object: any) => void;
  onSetMessage: (status: ResponseStatus, msg: string) => void;
  status?: StatusGrid;
}

const UsuarioPanel = forwardRef(
  ({ usuarioData, setUsuarioData, onSetMessage }: Props, ref) => {
    useEffect(() => {
      const objUsuario = { ...usuarioData };
      setUsuarioData(objUsuario);
    }, [usuarioData.nrSeqPessoaUsr]);

    const [usuarioPerfilData, setUsuarioPerfilData] = useState(
      new UsuarioPerfil({})
    );
    const [usuarioTransacaoData, setUsuarioTransacaoData] = useState(
      new UsuarioTransacaoInicial({})
    );
    const [usuarioCentroDeCustoData, setUsuarioCentroDeCustoData] = useState(
      new UsuarioCentroDeCusto({})
    );
    const gridViewUsuarioPerfil = useRef();
    const gridViewUsuarioTransacao = useRef();
    const gridViewUsuarioCentroDeCusto = useRef();

    const fillGridView = () => {
      if (
        gridViewUsuarioPerfil &&
        gridViewUsuarioPerfil.current &&
        usuarioData.usuarioPerfils &&
        usuarioData.usuarioPerfils.length > 0
      ) {
        const gridList = usuarioData.usuarioPerfils.map((item) => item);
        gridViewUsuarioPerfil.current.setDataSource(gridList);
      }

      if (
        gridViewUsuarioTransacao &&
        gridViewUsuarioTransacao.current &&
        usuarioData.usuarioTransacaoIniciais &&
        usuarioData.usuarioTransacaoIniciais.length > 0
      ) {
        const gridListTransacao = usuarioData.usuarioTransacaoIniciais.map(
          (item) => item
        );
        gridViewUsuarioTransacao.current.setDataSource(gridListTransacao);
      }

      if (
        gridViewUsuarioCentroDeCusto &&
        gridViewUsuarioCentroDeCusto.current &&
        usuarioData.usuarioCentroDeCustos &&
        usuarioData.usuarioCentroDeCustos.length > 0
      ) {
        const gridListContaCorrente = usuarioData.usuarioCentroDeCustos.map(
          (item) => item
        );
        gridViewUsuarioCentroDeCusto.current.setDataSource(
          gridListContaCorrente
        );
      }
    };

    useImperativeHandle(ref, () => ({
      clear() {
        setUsuarioPerfilData({});
        gridViewUsuarioPerfil?.current?.setDataSource([]);

        setUsuarioTransacaoData({});
        gridViewUsuarioTransacao?.current?.setDataSource([]);

        setUsuarioCentroDeCustoData({});
        gridViewUsuarioCentroDeCusto?.current?.setDataSource([]);
      },
      fillGrid() {
        fillGridView();
      },
      getDataGrid() {
        const listaUsuarioPerfils =
          gridViewUsuarioPerfil?.current?.getDataSource() ?? [];
        const listaUsuarioTransacoes =
          gridViewUsuarioTransacao?.current?.getDataSource() ?? [];
        const listaUsuarioCentroDeCustos =
          gridViewUsuarioCentroDeCusto?.current?.getDataSource() ?? [];

        return {
          listaUsuarioPerfils,
          listaUsuarioTransacoes,
          listaUsuarioCentroDeCustos,
        };
      },
    }));

    const onSearchPerfilDeAcesso = async (e: any) => {
      const {
        perfilDeAcesso: perfilDeAcess,
      } = await getPerfilDeAcessoAutoComplete({ noPerfilDeAcesso: e });

      return perfilDeAcess;
    };

    const onSearchPerfil = async (e) => {
      const { perfis } = await getPerfilAutoComplete({ noPerfil: e });

      return perfis;
    };

    const onSearchEmpresa = async (e) => {
      const {
        status,
        message: msg,
        empresas,
      } = await getEmpresaAutoCompleteWithOutPerfil({
        noEmpresa: e,
      });
      if (msg) onSetMessage(status, msg);
      return empresas;
    };

    const onSearchCentroDeCusto = async (e) => {
      const {
        status,
        message: msg,
        data: centros,
      } = await getCentroDeCustoAutoCompleteSimples({
        codNoCentroDeCusto: e,
      });
      if (msg) onSetMessage(status, msg);
      return centros;
    };

    const onSearchTransacao = async (e: any) => {
      const {
        status,
        message: msg,
        transacoes,
      } = await getTransacaAutoComplete({
        noTransacao: e,
      });

      if (msg) onSetMessage(status, msg);
      return transacoes;
    };

    const onRemoveUsuarioPerfil = async (e, datasource) => {
      const index = datasource.findIndex((item) => item === e);
      datasource[index].status = GridView.EnumStatus.Remover;

      if (gridViewUsuarioPerfil && gridViewUsuarioPerfil.current)
        gridViewUsuarioPerfil.current.setDataSource(datasource);
    };

    const onAdicionarUsuarioPerfil = async () => {
      if (
        usuarioPerfilData.nrSeqPerfil > 0 &&
        usuarioPerfilData.nrSeqEmpresa >= 0
      ) {
        const obj = {
          ...usuarioPerfilData,
          status: usuarioPerfilData.status ?? StatusGrid.Inserir,
        };

        const list = gridViewUsuarioPerfil.current.getDataSource();
        list.push(obj);

        if (gridViewUsuarioPerfil && gridViewUsuarioPerfil.current)
          gridViewUsuarioPerfil.current.setDataSource(list);

        setUsuarioData({
          ...usuarioData,
          usuarioPerfils: list,
        });
        setUsuarioPerfilData({});
      } else if (
        usuarioPerfilData.nrSeqPerfil === undefined ||
        usuarioPerfilData.nrSeqPerfil === null
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o Perfil');
      } else if (
        usuarioPerfilData.nrSeqEmpresa === undefined ||
        usuarioPerfilData.nrSeqEmpresa === null
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe a Empresa');
      }
    };

    const onClickEditUsuarioTransacao = async (selectedValue, datasource) => {
      const dataUsuarioTransacao = datasource.find(
        (item) => item === selectedValue
      );

      if (dataUsuarioTransacao.status !== 'Inserir')
        dataUsuarioTransacao.status = GridView.EnumStatus.Alterar;

      const dataProdutos = datasource.filter(
        (produto) => produto !== selectedValue
      );

      setUsuarioTransacaoData(dataUsuarioTransacao);

      if (gridViewUsuarioTransacao && gridViewUsuarioTransacao.current)
        gridViewUsuarioTransacao.current.setDataSource(dataProdutos);
    };

    const onRemoveUsuarioTransacao = async (e, datasource) => {
      const index = datasource.findIndex((item) => item === e);
      datasource[index].status = GridView.EnumStatus.Remover;

      if (gridViewUsuarioTransacao && gridViewUsuarioTransacao.current)
        gridViewUsuarioTransacao.current.setDataSource(datasource);
    };

    const onAdicionarUsuarioTransacao = async () => {
      if (usuarioTransacaoData.nrSeqTransacao > 0) {
        const obj = {
          ...usuarioTransacaoData,
          status: usuarioTransacaoData.status ?? StatusGrid.Inserir,
        };

        const list = gridViewUsuarioTransacao.current.getDataSource();
        list.push(obj);

        if (gridViewUsuarioPerfil && gridViewUsuarioTransacao.current)
          gridViewUsuarioTransacao.current.setDataSource(list);

        setUsuarioData({
          ...usuarioData,
          usuarioTransacaoIniciais: list,
        });
        setUsuarioTransacaoData({});
      } else if (
        usuarioTransacaoData.nrSeqTransacao === undefined ||
        usuarioTransacaoData.nrSeqTransacao === null
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o Menu');
      } else if (
        usuarioTransacaoData.nrOrdem === undefined ||
        usuarioTransacaoData.nrOrdem === null
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe a ordem');
      }
    };

    const onClickEditCentroDeCusto = async (selectedValue, datasource) => {
      const dataUsuarioPerfil = datasource.find(
        (item) => item === selectedValue
      );
      if (dataUsuarioPerfil.status !== 'Inserir')
        dataUsuarioPerfil.status = GridView.EnumStatus.Alterar;

      const dataProdutos = datasource.filter(
        (produto) => produto !== selectedValue
      );

      setUsuarioCentroDeCustoData(dataUsuarioPerfil);

      if (gridViewUsuarioCentroDeCusto && gridViewUsuarioCentroDeCusto.current)
        gridViewUsuarioCentroDeCusto.current.setDataSource(dataProdutos);
    };

    const onRemoveCentroDeCusto = async (e, datasource) => {
      const index = datasource.findIndex((item) => item === e);
      datasource[index].status = GridView.EnumStatus.Remover;

      if (gridViewUsuarioCentroDeCusto && gridViewUsuarioCentroDeCusto.current)
        gridViewUsuarioCentroDeCusto.current.setDataSource(datasource);
    };

    const onAdicionarCentroDeCusto = async () => {
      if (usuarioCentroDeCustoData.nrSeqCentroDeCusto > 0) {
        const obj = {
          ...usuarioCentroDeCustoData,
          status: usuarioCentroDeCustoData.status ?? StatusGrid.Inserir,
        };

        const list = gridViewUsuarioCentroDeCusto.current.getDataSource();
        list.push(obj);

        if (
          gridViewUsuarioCentroDeCusto &&
          gridViewUsuarioCentroDeCusto.current
        )
          gridViewUsuarioCentroDeCusto.current.setDataSource(list);

        setUsuarioData({
          ...usuarioData,
          usuarioCentroDeCustos: list,
        });
        setUsuarioCentroDeCustoData({});
      } else if (
        usuarioCentroDeCustoData.nrSeqCentroDeCusto === undefined ||
        usuarioCentroDeCustoData.nrSeqCentroDeCusto === null
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o Centro De Custo');
      }
    };

    const columns = [
      {
        key: 'nrSeqUsuario',
        title: 'nrSeqUsuario',
        visible: false,
      },
      {
        key: 'perfil.noPerfil',
        title: 'Perfil',
      },
      { key: 'empresa.noPessoa', title: 'Empresa' },
      {
        key: 'nrSeqPessoaUsr',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemoveUsuarioPerfil(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    const columnsTransacao = [
      {
        key: 'nrSeqUsuario',
        title: 'nrSeqUsuario',
        visible: false,
      },
      {
        key: 'transacao.noTransacaoCompleto',
        title: 'Menu',
      },
      {
        key: 'nrOrdem',
        title: 'Ordem',
      },
      {
        key: 'nrSeqPessoaUsr',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onClickEditUsuarioTransacao(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },
      // ADICIONANDO BOTÃO DE DELEÇÃO
      {
        key: 'nrSeqPessoaUsr',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemoveUsuarioTransacao(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    const columnsCentroDeCusto = [
      {
        key: 'nrSeqUsuario',
        title: 'nrSeqUsuario',
        visible: false,
      },
      {
        key: 'centroDeCusto.noCentroDeCusto',
        title: 'Centro De Custo',
      },
      {
        key: 'nrSeqPessoaUsr',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onClickEditCentroDeCusto(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },
      // ADICIONANDO BOTÃO DE DELEÇÃO
      {
        key: 'nrSeqPessoaUsr',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemoveCentroDeCusto(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    return (
      <div className='col mt-3'>
        {/* @ts-expect-error */}
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header title='Usuário' theme={Theme.Primary} />

          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-5'>
                {/* @ts-expect-error */}
                <Autocomplete
                  label='Perfil De Acesso'
                  searchDataSource={onSearchPerfilDeAcesso}
                  selectedItem={usuarioData.perfilDeAcesso}
                  onSelectItem={(perfilDeAcesso: any) =>
                    setUsuarioData({
                      ...usuarioData,
                      perfilDeAcesso,
                      nrSeqPerfilDeAcesso: perfilDeAcesso.nrSeqPerfilDeAcesso,
                    })
                  }
                  dataSourceTextProperty='noPerfilDeAcesso'
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
        <div className='row mb-3'>
          <div className='col-12 mb-3'>
            <Panel>
              {/* @ts-expect-error */}
              <Panel.Header
                title='Perfil de Usuário na Empresa'
                theme={Theme.Primary}
              />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col'>
                    <Autocomplete
                      label='Perfil'
                      searchDataSource={onSearchPerfil}
                      selectedItem={usuarioPerfilData.perfil}
                      onSelectItem={(perfil) => {
                        setUsuarioPerfilData({
                          ...usuarioPerfilData,
                          perfil,
                          nrSeqPerfil: perfil.nrSeqPerfil,
                        });
                      }}
                      dataSourceTextProperty='noPerfil'
                    />
                  </div>
                  <div className='col-5 mb-3'>
                    <Autocomplete
                      label='Empresa'
                      searchDataSource={onSearchEmpresa}
                      selectedItem={usuarioPerfilData.empresa}
                      onSelectItem={(empresa) => {
                        setUsuarioPerfilData({
                          ...usuarioPerfilData,
                          empresa,
                          nrSeqEmpresa: empresa.nrSeqEmpresa,
                        });
                      }}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                  <div className='col-2 mb-3'>
                    <Button
                      outline
                      size={BootstrapSizes.Small}
                      theme={Theme.Success}
                      template={Button.Templates.Quick}
                      onClick={onAdicionarUsuarioPerfil}
                      text='Adicionar'
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <GridView
                      ref={gridViewUsuarioPerfil}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columns}
                      showPagination={false}
                      showSelectSizes={false}
                      offlineData
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-12 mb-3'>
            <Panel>
              {/* @ts-expect-error */}
              <Panel.Header
                title='Páginas Abertas ao Entrar no Sistema'
                theme={Theme.Primary}
              />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-3 mb-3'>
                    <Autocomplete
                      label='Menu'
                      searchDataSource={onSearchTransacao}
                      selectedItem={usuarioTransacaoData.transacao}
                      onSelectItem={(transacao) => {
                        setUsuarioTransacaoData({
                          ...usuarioTransacaoData,
                          transacao,
                          nrSeqTransacao: transacao.nrSeqTransacao,
                        });
                      }}
                      dataSourceTextProperty='noTransacaoCompleto'
                    />
                  </div>
                  <div className='col-4'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='Ordem'
                      text={usuarioTransacaoData?.nrOrdem}
                      mask={MaskTypes.Integer}
                      maxLength={2}
                      onChangedValue={(nrOrdem: string) => {
                        setUsuarioTransacaoData({
                          ...usuarioTransacaoData,
                          nrOrdem,
                        });
                      }}
                    />
                  </div>
                  <div className='col-2 mb-3'>
                    <Button
                      outline
                      size={BootstrapSizes.Small}
                      theme={Theme.Success}
                      template={Button.Templates.Quick}
                      onClick={onAdicionarUsuarioTransacao}
                      text='Adicionar'
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <GridView
                      ref={gridViewUsuarioTransacao}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columnsTransacao}
                      showPagination={false}
                      showSelectSizes={false}
                      offlineData
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-12 mb-3'>
            <Panel>
              {/* @ts-expect-error */}
              <Panel.Header
                title='Vincular Usuário a Centro de Custos'
                theme={Theme.Primary}
              />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col'>
                    <Autocomplete
                      label='Centro de Custo'
                      searchDataSource={onSearchCentroDeCusto}
                      selectedItem={usuarioCentroDeCustoData.centroDeCusto}
                      onSelectItem={(centroDeCusto) =>
                        setUsuarioCentroDeCustoData({
                          ...usuarioCentroDeCustoData,
                          centroDeCusto,
                          nrSeqCentroDeCusto: centroDeCusto.nrSeqCentroDeCusto,
                        })
                      }
                      dataSourceTextProperty='noCentroDeCusto'
                    />
                  </div>
                  <div className='col-2 mb-3'>
                    <Button
                      outline
                      size={BootstrapSizes.Small}
                      theme={Theme.Success}
                      template={Button.Templates.Quick}
                      onClick={onAdicionarCentroDeCusto}
                      text='Adicionar'
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <GridView
                      ref={gridViewUsuarioCentroDeCusto}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columnsCentroDeCusto}
                      showPagination={false}
                      showSelectSizes={false}
                      offlineData
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
);

export default UsuarioPanel;
