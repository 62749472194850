import { Usuario } from '../SEG';
import ContaCorrenteTransferencia from './contaCorrenteTransferencia';
import TipoExtratoTransferencia from './tipoExtratoTransferencia';

export default class ExtratoTransferencia {
  constructor(jsonObject = {}) {
    this.nrSeqExtratoTransferencia = jsonObject.nrSeqExtratoTransferencia;
    this.dtCad = jsonObject.dtCad;
    this.dtDocumento = jsonObject.dtDocumento;
    this.vlrMovimento = jsonObject.vlrMovimento;
    this.nrSeqContaCorrenteTransferenci =
      jsonObject.nrSeqContaCorrenteTransferenci;
    this.nrSeqExtratoOri = jsonObject.nrSeqExtratoOri;
    this.nrSeqExtratoDest = jsonObject.nrSeqExtratoDest;
    this.nrSeqPagamentoMassivo = jsonObject.nrSeqPagamentoMassivo;
    this.flgPagamentoMassivo = jsonObject.flgPagamentoMassivo;
    this.nrSeqTipoExtratotransferencia =
      jsonObject.nrSeqTipoExtratotransferencia;
    this.dtDebitoOrigem = jsonObject.dtDebitoOrigem;
    this.dtCreditoDestino = jsonObject.dtCreditoDestino;
    this.nrSeqUsuarioDebitoOri = jsonObject.nrSeqUsuarioDebitoOri;
    this.nrSeqUsuarioCreditoDest = jsonObject.nrSeqUsuarioCreditoDest;
    this.nrSeqCheque = jsonObject.nrSeqCheque;
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.nrIdentificador = jsonObject.nrIdentificador;
    this.nrSeqPlanoDeContaCred = jsonObject.nrSeqPlanoDeContaCred;
    this.nrSeqPlanoDeContaDeb = jsonObject.nrSeqPlanoDeContaDeb;
    this.transferencia = new ContaCorrenteTransferencia(
      jsonObject.transferencia ?? {}
    );
    this.tipoExtratoTransferencia = new TipoExtratoTransferencia(
      jsonObject.tipoExtratoTransferencia ?? {}
    );
    this.usuario = new Usuario(jsonObject.usuario ?? {});
    this.noColorDest = jsonObject.noColorDest;
    this.noColorCred = jsonObject.noColorCred;
  }

  toJson = () => JSON.stringify(this);
}
