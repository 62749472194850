import React, { useState, useRef } from 'react';
import {
  CSDSelPage,
  GridView,
  Textbox,
  RadioButton,
  Autocomplete,
  ToolbarButtons,
} from 'ui/components';
import { PageTypes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import {
  ColumnTypes,
  Theme,
  ResponseStatus,
  BootstrapSizes,
} from 'ui/Helpers/enums.ts';
import {
  getClientePagined,
  deleteClientes,
  printClientesPessoa,
} from 'core/services/FIN/cliente';
import { getVendedorAutoComplete } from 'core/services/VEN/vendedor';
import { getCidadeAutoComplete } from 'core/services/SEG/cidade';
import ModalImportacaoClientes from 'ui/pages/FIN/CadastroRapidoCliente30/modalImportacaoClientes';

export interface IClienteFilters {
  noPessoa?: string;
  flgExcel?: boolean;
  SortBy?: number;
  totalByPage?: number;
  page?: any;
}

export default function CadastroRapidoCliente({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}: Page) {
  const gridView = useRef<any>();

  const [filtros, setFiltros] = useState<IClienteFilters>({
    typePerson: 'T',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [showImportacao, setShowImportacao] = useState(false);

  const columns: Array<GridviewColumns> = [
    { key: 'nrSeqPessoaCli', type: ColumnTypes.Checkbox },
    { key: 'noPessoa', title: 'Nome' },
    { key: 'cdCliente02', title: 'Matrícula' },
    { key: 'cdCliente', title: 'Código' },
    { key: 'email', title: 'E-mail' },
  ];

  const typePerson = [
    { text: 'Todos', value: 'T' },
    { text: 'Física', value: 'F' },
    { text: 'Jurídica', value: 'J' },
  ];

  const search = async (params = null): Promise<void> => {
    setLoading(true);

    const { clientes, pagination } = await getClientePagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(clientes, pagination);

    setLoading(false);
  };

  const onOpenMaintenance = (registryKey = null): void => {
    onOpenPage(
      transaction.noTransacao,
      'CadastroRapidoCliente/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onColumnSort = async (SortBy: number): Promise<void> => {
    setFiltros({ ...filtros, SortBy });
    await search();
  };

  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    setFiltros({ ...filtros, totalByPage });
    await search();
  };

  const onPageChange = async (page: any): Promise<void> => {
    setFiltros({ ...filtros, page });
    await search();
  };

  const onRowDoubleClick = (sourceRow: any): void => {
    const nrSeqPessoaCli = sourceRow.find(
      (e: any) => e.key === 'nrSeqPessoaCli'
    );

    onOpenMaintenance(nrSeqPessoaCli.value);
  };

  const onSearchVendedor = async (e: any) => {
    const { vendedores } = await getVendedorAutoComplete({
      noPessoa: e,
    });
    return vendedores;
  };

  const onSearchCidade = async (e: any) => {
    const { cidades } = await getCidadeAutoComplete({
      noCidade: e,
    });
    return cidades;
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteClientes(
        selecteds.map((row: any) => row[0].value)
      );
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      await search();
    } else {
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onPrint = async () => {
    setLoading(true);

    const { value } = await printClientesPessoa(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Cadastro Rápido de Cliente'
      loading={loading}
      onSearch={search}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Importar Cliente'
          icon='cloud-upload-alt'
          onClick={() => setShowImportacao(true)}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col-5'>
          {/* @ts-expect-error */}
          <Textbox
            label='Nome/ Razão Social'
            text={filtros.noPessoa}
            maxLength={200}
            onChangedValue={(noPessoa: string) =>
              setFiltros({ ...filtros, noPessoa })
            }
          />
        </div>
        <div className='col-5'>
          {/* @ts-expect-error */}
          <Textbox
            label='Código'
            text={filtros.cdCliente}
            onChangedValue={(cdCliente: string) =>
              setFiltros({ ...filtros, cdCliente })
            }
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-6'>
          <Autocomplete
            label='Vendedor'
            searchDataSource={onSearchVendedor}
            selectedItem={filtros.vendedor}
            onSelectItem={(vendedor: any) =>
              setFiltros({
                ...filtros,
                vendedor,
                nrSeqPessoaVen: vendedor.nrSeqPessoa,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Cidade'
            searchDataSource={onSearchCidade}
            selectedItem={filtros.cidade}
            onSelectItem={(cidade: any) =>
              setFiltros({
                ...filtros,
                cidade,
                nrSeqCidade: cidade.nrSeqCidade,
              })
            }
            dataSourceTextProperty='noCidade'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-3'>
          <RadioButton
            label='Tipo de Pessoa'
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={filtros.typePerson}
            buttons={typePerson}
            onChange={(obj) =>
              setFiltros({
                ...filtros,
                typePerson: obj,
              })
            }
          />
        </div>
        {filtros.typePerson === 'J' && (
          <>
            <div className='col-3'>
              <Textbox
                label='Jurídica'
                text={filtros.nrCNPJ}
                onChangedValue={(nrCNPJ) =>
                  setFiltros({ ...filtros, nrCNPJ, nrCpf: '' })
                }
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='Fantasia'
                text={filtros.noFantasia}
                onChangedValue={(noFantasia) =>
                  setFiltros({ ...filtros, noFantasia })
                }
              />
            </div>
          </>
        )}
        {filtros.typePerson === 'F' && (
          <div className='col-3'>
            <Textbox
              label='Física'
              text={filtros.nrCPF}
              onChangedValue={(nrCPF) =>
                setFiltros({ ...filtros, nrCPF, nrCNPJ: '' })
              }
            />
          </div>
        )}
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            onColumnSort={onColumnSort}
            enableExcelExport
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            canControlVisibility
            transaction={transaction}
          />
        </div>
      </div>
      <ModalImportacaoClientes
        show={showImportacao}
        onCancel={() => setShowImportacao(false)}
        onSuccess={(clientes) => {
          setMessage({
            message:
              'Arquivo processado, confira o arquivo erros.txt para verificar se houve algum erro de importação.',
            theme: Theme.Success,
          });
          gridView.current.setDataSource(clientes);
          setShowImportacao(false);
        }}
      />
    </CSDSelPage>
  );
}
