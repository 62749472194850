export default class LogErroSistema {
  constructor(jsonObject = {}) {
    this.nrSeqLogErroSistema = jsonObject.nrSeqLogErroSistema;
    this.dtCad = jsonObject.dtCad;
    this.dtLogErroSistema = jsonObject.dtLogErroSistema;
    this.dtLogErroSistemaStr = jsonObject.dtLogErroSistemaStr;
    this.noMensagem = jsonObject.noMensagem;
    this.noMensagemTruncado = jsonObject.noMensagemTruncado;
    this.noCaminho = jsonObject.noCaminho;
  }

  toJson = () => JSON.stringify(this);
}
