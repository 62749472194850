import qs from 'qs';
import Pagination from 'core/models/pagination';
import ManutencaoVeiculoOS from 'core/models/FRO/manutencaoVeiculoOS';
import api from '../api';

const baseURL = `/FRO/ManutencaoVeiculoOS`;

export const getManutencaoVeiculoOSList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ManutencaoVeiculoOS(json)) ?? [],
  };
};

export const getManutencaoVeiculoOSPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ManutencaoVeiculoOS(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getFilteredManutencaoVeiculoOSPagined = async (filters) => {
  const response = await api.post(`${baseURL}/SearchPaginedFilters`, filters);
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ManutencaoVeiculoOS(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getManutencaoVeiculoOS = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ManutencaoVeiculoOS(value) : {};
};
export const getManutencaoVeiculoOSFindOs = async (id) => {
  const response = await api.get(`${baseURL}/FindOs`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  const datasouce = value ? new ManutencaoVeiculoOS(value) : {};
  return datasouce;
};

export const saveManutencaoVeiculoOS = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const createManutencaoVeiculoOS = async (data) => {
  const response = await api.post(`${baseURL}/Create`, data);

  return response.data ?? {};
};

export const deleteManutencaoVeiculoOS = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printOSManutencaoVeiculoOS = async (filters) => {
  const response = await api.post(`${baseURL}/PrintOS`, filters);
  return response.data ?? {};
};

export const printReportManutencaoVeiculoOS = async (filters) => {
  const response = await api.post(`${baseURL}/PrintReport`, filters);
  return response.data ?? {};
};

export const excelManutencaoVeiculoOS = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllManutencaoVeiculoOS = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getManutencaoVeiculoOSAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ManutencaoVeiculoOS(json)) ?? [],
  };
};

export const getManutencaoVeiculoOSAutoCompleteByOpenDate = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteByOpenDate`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ManutencaoVeiculoOS(json)) ?? [],
  };
};
