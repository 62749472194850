import qs from 'qs';
import Pagination from 'core/models/pagination';
import PneuMarca from 'core/models/FRO/pneuMarca';
import api from '../api';

const baseURL = `/FRO/PneuMarca`;

export const getPneuMarcaAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    pneuMarcas: value?.map((json) => new PneuMarca(json)) ?? [],
  };
};

export const getPneuMarcaList = async (filters) => {
  const response = await api.get(`/$PneuMarca/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PneuMarca(json)) ?? [],
  };
};

export const getPneuMarcaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PneuMarca(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPneuMarca = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PneuMarca(value) : {};
};

export const savePneuMarca = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePneuMarca = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPneuMarca = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPneuMarca = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPneuMarca = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
