import React, { useEffect, useRef } from 'react';

import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { Button, Modal, GridView } from 'ui/components';
import { searchDetailsMovementQuery } from 'core/services/EST/estoqueMovimento';

export default function ModalProdutoAgrupadoDetalhado({
  show,
  ProdutoAgrupadoDetalhadoRef,
  onClose,
  transaction,
}) {
  const gridViewGroup = useRef();
  const searchDetailsMovement = async (datasource) => {
    const { data: movimentos } = await searchDetailsMovementQuery(datasource);
    if (movimentos != null) {
      if (gridViewGroup && gridViewGroup.current)
        gridViewGroup.current.setDataSource(movimentos);
    }
  };

  useEffect(async () => {
    if (Object.keys(ProdutoAgrupadoDetalhadoRef).length > 0) {
      searchDetailsMovement(ProdutoAgrupadoDetalhadoRef);
    }
  }, [show]);

  const columnsGroup = [
    {
      key: 'nrSeqAlmoxarifadoLocal',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtMovimento',
      title: '1° Movimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'estoqueTipo.noEstoqueTipo',
      title: 'Tipo',
      sortKey: 'noEstoqueTipo',
    },
    { key: 'nrDoc', title: 'Nr Documento' },
    { key: 'obsMov', title: 'Observação' },
    {
      key: 'produto.noProduto',
      title: 'Produto',
      format: GridView.DataTypes.Text,
      sortKey: 'noProduto',
      sortable: false,
    },
    {
      key: 'produto.cdProdutoUnidadeMedida',
      title: 'Unid. Medida',
      sortKey: 'cdProdutoUnidadeMedida',
      sortable: false,
      visible: false,
    },
    {
      key: 'almoxarifadoLocal.noAlmoxarifadoLocal',
      title: 'Almoxarifado',
      sortKey: 'noAlmoxarifadoLocal',
      sortable: false,
    },
    {
      key: 'quantidadeInicial',
      title: 'Quantidade Inicial',
      format: GridView.DataTypes.Decimal,
      visible: false,
    },
    {
      key: 'quantidadeEntrada',
      title: 'Quantidade Entrada',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'quantidadeSaida',
      title: 'Quantidade Saída',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrUnit',
      title: 'Maior Valor Unitário',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrSaldoTotal',
      title: 'Valor Saldo Total',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'saldo',
      title: 'Saldo',
      format: GridView.DataTypes.Decimal,
    },
  ];
  return (
    <Modal
      show={show}
      title='Consulta Produto Detalhado'
      onClose={onClose}
      size={BootstrapSizes.Large}
    >
      <Modal.Body className='p-12 pt-4'>
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              ref={gridViewGroup}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnsGroup}
              offlineData
              sortOffline
              showSelectSizes={false}
              showPagination
              canControlVisibility
              transaction={transaction}
              sumFields
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Sair'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
