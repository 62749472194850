import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  Button,
  Notification,
  DropdownList,
  Loader,
  Panel,
  Textbox,
  Switch,
  GridView,
  FileUpload,
} from 'ui/components';
import {
  BootstrapSizes,
  Theme,
  ResponseStatus,
  dateNow,
  JustifyContent,
  toBase64,
  MimeTypes,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/enums';

import { getColetaEmbarqueList } from 'core/services/FRO/coletaEmbarque';
import { dropDownListCidadeEncerramento } from 'core/services/FRO/manifestoCTE';
import { closeMdfe } from 'core/services/FRO/manifestoMdfe';

import UploadImagemFrota from 'core/models/FRO/uploadImagemFrota';

function GenerateRandomText() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';

  let word = '';

  for (let i = 0; i < 5; i += 1) {
    const index = Math.floor(Math.random() * characters.length);
    word += characters[index];
  }

  return word;
}

export default function ModalEncerraManifesto(props) {
  const { show, manifesto, manifestoNfv } = props;
  const [loading, setLoading] = useState(false);
  const [randomText, setRandomtext] = useState(GenerateRandomText());
  const [data, setData] = useState({ flgComCarga: false });
  const [importedFiles, setImportedFiles] = useState([]);
  const [message, setMessage] = useState(null);
  const [painelEncerraMesmoDia, setPainelEncerraMesmoDia] = useState(false);
  const [input, setInput] = useState('');
  const [dropDownCidades, setDropDownCidades] = useState([]);
  const gridView = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const searchCidades = async () => {
    const {
      status,
      message: msg,
      data: cidades,
    } = await dropDownListCidadeEncerramento(manifesto);

    if (msg) onSetMessage(status, msg);

    setDropDownCidades(cidades);

    if (cidades.length === 1) return cidades[0];

    return null;
  };

  const getDocumentos = async () => {
    const { status, message: msg, data: ctes } = await getColetaEmbarqueList({
      nrSeqViagem: manifesto?.nrSeqViagem,
    });

    if (msg) onSetMessage(status, msg);

    const documentos = [];

    if (ctes && ctes?.length > 0) {
      ctes.forEach((cte) => {
        cte.documentos.forEach((documento) => {
          const doc = {
            nrSeqUploadImagem: Number(
              `${cte.nrSeqColetaEmbarque}${documento.nrSeqDocumento}`
            ),
            nrSeqColetaEmbarque: cte?.nrSeqColetaEmbarque,
            cdColetaEmbarque: cte?.cdColetaEmbarque,
            nrSeqDocumento: documento?.nrSeqDocumento,
            nrDocumento: documento?.nrNotaFiscal,
            nrSeqManifestoCte: manifesto.nrSeqManifestoCte,
          };

          documentos.push(doc);
        });
      });
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(documentos);
  };

  useEffect(async () => {
    setLoading(true);

    let cidadeEncerramento = null;

    if (show) {
      cidadeEncerramento = await searchCidades();

      if (!manifestoNfv) await getDocumentos();
    }

    const objData = {
      ...manifesto,
      nrSeqCidadeEncerramento: cidadeEncerramento?.nrSeqCidade,
      cidadeEncerramento,
    };

    setData(objData);

    setPainelEncerraMesmoDia({
      ...painelEncerraMesmoDia,
      visible: manifesto?.dtEmissao.slice(0, 10) === dateNow(),
    });

    setLoading(false);
  }, [show]);

  const onSend = () => {
    props.onClose();
  };

  const onCloseMdfe = async () => {
    setLoading(true);

    const dataClose = { ...data };

    if (!manifestoNfv) {
      let imagens = gridView.current?.getDataSource();
      imagens = imagens.map((imagem) => new UploadImagemFrota(imagem));

      dataClose.uploadImagens = imagens;
    }

    const { status, message: msg, data: manifestoCte } = await closeMdfe(
      dataClose
    );

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      props.onClose(msg, manifestoCte);
    }

    setLoading(false);
  };

  const onConfirm = async () => {
    if (painelEncerraMesmoDia.visible) {
      if (randomText.localeCompare(input) === 0) {
        await onCloseMdfe();
      } else {
        setMessage({
          message: 'Tente novamente.',
          theme: Theme.Warning,
        });
      }

      setInput('');
    } else {
      await onCloseMdfe();
    }
  };

  const onClickUploadImagem = async () => {
    if (importedFiles.length > 0) {
      const seleteds = gridView.current
        .getCheckBoxValuesAt(0)
        .map((row) => row[0].value);

      if (seleteds.length <= 0) {
        onSetMessage(1, 'Necessário selecionar um documento.');
        return;
      }

      if (seleteds.length > 1) {
        onSetMessage(1, 'Selecione apenas um documento.');
        return;
      }

      let noBaseImagem64 = await toBase64(importedFiles[0]);
      noBaseImagem64 = String(noBaseImagem64).substring(
        String(noBaseImagem64).indexOf(',') + 1
      );

      const documento = {
        noArquivo: importedFiles[0].name,
        noTipoArquivo: importedFiles[0].type,
        noTamanho: String(importedFiles[0].size),
        noBaseImagem64,
        status: GridView.EnumStatus.Inserir,
      };

      let documentosGrid = gridView.current.getDataSource();

      documentosGrid = documentosGrid.map((item) => {
        if (item.nrSeqUploadImagem === seleteds[0])
          item = { ...item, ...documento };

        return item;
      });

      if (gridView && gridView.current) {
        gridView.current.setDataSource(documentosGrid);
      }

      setImportedFiles([]);
    }
  };

  const columns = [
    { key: 'nrSeqUploadImagem', type: GridView.ColumnTypes.Checkbox },
    { key: 'cdColetaEmbarque', title: 'Cd. Cte' },
    { key: 'nrDocumento', title: 'Nr. Documento' },
    { key: 'noArquivo', title: 'Nome Arquivo' },
  ];

  return (
    <Modal
      show={show}
      title='Encerrar Manifesto Eletrônico (MDFe)'
      onClose={() => onSend()}
      size={BootstrapSizes.Large}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='row mb-3'>
            <div className='col'>
              <DropdownList
                label='Cidade Encerramento'
                dataSource={dropDownCidades}
                dataSourcePropertyText='cidadeEstado'
                dataSourcePropertyValue='nrSeqCidade'
                selectedItems={data?.cidadeEncerramento ?? []}
                onSelectItem={(item) =>
                  setData({
                    ...data,
                    cidadeEncerramento: item,
                    nrSeqCidadeEncerramento: item.nrSeqCidade,
                  })
                }
              />
            </div>
          </div>
          {!manifestoNfv && (
            <div className='row mb-3'>
              <Panel>
                <Panel.Header
                  icon={['fas', 'keyboard']}
                  title='Detalhes MDFe'
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                />
                <Panel.Body>
                  <div className='row mb-3'>
                    <div className='col-2'>
                      <Switch
                        formControl
                        label='Tem Devolução?'
                        checked={data.flgTemDevolucao}
                        onChange={(flgTemDevolucao) =>
                          setData({ ...data, flgTemDevolucao })
                        }
                      />
                    </div>
                    {data.flgTemDevolucao && (
                      <div className='col-4'>
                        <Textbox
                          label='Itens devolução'
                          text={data.itensDevolucao}
                          onChangedValue={(itensDevolucao) =>
                            setData({ ...data, itensDevolucao })
                          }
                        />
                      </div>
                    )}
                    <div className='col-2'>
                      <Textbox
                        label='Qtd. Pallets'
                        text={data.qtdPallets}
                        mask={MaskTypes.Integer}
                        onChangedValue={(qtdPallets) =>
                          setData({ ...data, qtdPallets })
                        }
                      />
                    </div>
                    {data.qtdPallets &&
                      data.qtdPallets !== 0 &&
                      data.qtdPallets > 0 && (
                        <div className='col-4'>
                          <Textbox
                            label='Onde ficou os Pallets'
                            text={data.localPallets}
                            onChangedValue={(localPallets) =>
                              setData({ ...data, localPallets })
                            }
                          />
                        </div>
                      )}
                  </div>
                  <div className='row mb-3'>
                    <div className='col-3'>
                      <Switch
                        formControl
                        label='Com carga (Status)'
                        checked={data.flgComCarga}
                        onChange={(flgComCarga) =>
                          setData({ ...data, flgComCarga })
                        }
                      />
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-9'>
                      <FileUpload
                        files={importedFiles}
                        onChange={(files) => setImportedFiles(files)}
                        allowedMimeTypes={[
                          MimeTypes.Types.PDF,
                          MimeTypes.Types.Image,
                          MimeTypes.Types.Word,
                        ]}
                      />
                    </div>
                    <div className='col-3 mt-2'>
                      <Button
                        icon='cloud-upload-alt'
                        theme={Theme.Success}
                        onClick={onClickUploadImagem}
                        text='Adicionar'
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <GridView
                        ref={gridView}
                        className='table-striped table-hover table-bordered table-sm'
                        dataColumns={columns}
                        showSelectSizes={false}
                        showPagination={false}
                      />
                    </div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
          )}
          {painelEncerraMesmoDia.visible && (
            <div className='row'>
              <div className='col-12'>
                <div className='alert alert-info fs-6' role='alert'>
                  <div className='row mb-2'>
                    <span>
                      Tem certeza que deseja encerrar este manifesto? Após
                      encerrado não será possível cancelar os CTes desta viagem.
                    </span>
                  </div>
                  <div className='row'>
                    <span>
                      Para confirmar o encerramento digite os caracteres abaixo
                      e aperte para confirmar.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {painelEncerraMesmoDia.visible && (
            <div className='row mb-3'>
              <Panel>
                <Panel.Header
                  icon={['fas', 'keyboard']}
                  title='Confirme os caracteres'
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                />
                <Panel.Body>
                  <div className='row'>
                    <div className='row mb-3 justify-content-center'>
                      <div className='col-2'>
                        <h2 className='fw-bold' style={{ color: '#008000' }}>
                          {randomText}
                        </h2>
                      </div>
                      <div className='col-1 mt-1'>
                        <Button
                          outline
                          icon={['fas', 'random']}
                          size={BootstrapSizes.Small}
                          theme={Theme.Primary}
                          template={Button.Templates.Default}
                          tooltip='Gerar Nova Sequencia'
                          tooltipDirection='bottom'
                          onClick={() => setRandomtext(GenerateRandomText())}
                        />
                      </div>
                    </div>
                    <div className='row mb-3 justify-content-center'>
                      <div className='col-5'>
                        <Textbox
                          text={input}
                          onChangedValue={(item) => setInput(item)}
                        />
                      </div>
                    </div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Confirmar'
          icon={['far', 'save']}
          theme={Theme.Success}
          template={Button.Templates.Default}
          size={BootstrapSizes.Medium}
          onClick={() => onConfirm()}
        />
        <Button
          text='Sair'
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          size={BootstrapSizes.Medium}
          onClick={() => onSend()}
        />
      </Modal.Footer>
    </Modal>
  );
}
