import Pagination from 'core/models/pagination';
import { VeiculoDisponibilidade } from 'core/models/FRO';
import { FiltrosVeiculoDisponibilidade } from 'core/interfaces/FRO/veiculoDisponibilidade';
import { ApiResponse } from 'ui/Helpers/interfaces';
import api from '../api';

const baseURL = '/FRO/VeiculoDisponibilidade';

export const getVeiculoDisponibilidadePagined = async (
  filters: FiltrosVeiculoDisponibilidade
): Promise<ApiResponse> => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });

  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data:
      value?.map(
        (json: VeiculoDisponibilidade) => new VeiculoDisponibilidade(json)
      ) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const buscaUltimaViagem = async (
  filters: FiltrosVeiculoDisponibilidade
): Promise<ApiResponse> => {
  const response = await api.get(`${baseURL}/BuscaUltimaViagem`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};
