import React, {
  forwardRef,
  ForwardedRef,
  useState,
  useImperativeHandle,
} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { convertThemeToRgb } from 'ui/Helpers/utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface IDataSource {
  labels: Array<string>;
  datasets: Array<any>;
}

interface Props {
  options: any;
  onClick?: (object: any) => void;
  borderWidth?: number;
}

const LineGraphic = forwardRef(
  ({ options, onClick, borderWidth = 2 }: Props, ref: ForwardedRef<any>) => {
    const [dataSource, setDataSource] = useState<IDataSource>({
      labels: [],
      datasets: [],
    });
    const [optionsSource, setOptionsSource] = useState(options);
    let dataSourceAtRunTime: IDataSource;

    const handleOnclick = (event: any, elements: any) => {
      if (!onClick) return;

      if (elements.length > 0) {
        const { datasetIndex: dataSetIndex, index } = elements[0];

        const object = {
          dataset: dataSourceAtRunTime.datasets[dataSetIndex].label,
          label: dataSourceAtRunTime.labels[index],
          value: dataSourceAtRunTime.datasets[dataSetIndex].data[index],
        };

        onClick(object);
      }
    };

    const updateDataSource = (newDataSource: IDataSource) => {
      newDataSource.datasets.forEach((dataSet: any) => {
        if (!dataSet.backgroundColor) {
          const { arrayRgb } = convertThemeToRgb(dataSet.theme);
          delete dataSet.theme;
          dataSet.backgroundColor = `rgba(${arrayRgb[0]}, ${arrayRgb[1]}, ${arrayRgb[2]})`;
        }

        dataSet.borderColor = dataSet.backgroundColor;
        dataSet.borderWidth = borderWidth;
      });

      setDataSource(newDataSource);
      dataSourceAtRunTime = newDataSource;
    };

    useImperativeHandle(ref, () => ({
      setDataSource(newDataSource: IDataSource) {
        updateDataSource(newDataSource);

        setOptionsSource({
          ...optionsSource,
          onClick: (event: any, elements: any) =>
            handleOnclick(event, elements),
        });
      },
    }));

    return (
      <>
        {dataSource.datasets.length > 0 && (
          <Line data={dataSource} options={optionsSource} />
        )}
      </>
    );
  }
);

export default LineGraphic;
