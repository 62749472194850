export default class Nfse {
  constructor(jsonObject = {}) {
    this.nrSeqNfse = jsonObject.nrSeqNfse;
    this.nrSeqNfs = jsonObject.nrSeqNfs;
    this.codigoVerificacao = jsonObject.codigoVerificacao;
    this.dtEmissao = jsonObject.dtEmissao;
    this.cdTiposolicitacao = jsonObject.cdTiposolicitacao;
    this.nrSeqUsuariocad = jsonObject.nrSeqUsuariocad;
    this.dtRetornolote = jsonObject.dtRetornolote;
    this.nrLote = jsonObject.nrLote;
    this.protocoloLote = jsonObject.protocoloLote;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrNfse = jsonObject.nrNfse;
    this.noXml = jsonObject.noXml;
    this.noRetornoLote = jsonObject.noRetornoLote;
    this.noRetornoNfs = jsonObject.noRetornoNfs;
    this.cdMotivoCancelamento = jsonObject.cdMotivoCancelamento;
    this.noMotivoCancelamento = jsonObject.noMotivoCancelamento;
  }

  toJson = () => JSON.stringify(this);
}
