export default class PocPocoBombaInstalada {
  constructor(jsonObject = {}) {
    this.nrSeqPocPocoBombaInstalada = jsonObject.nrSeqPocPocoBombaInstalada;
    this.dtInstalacao = jsonObject.dtInstalacao;
    this.noTipoBomba = jsonObject.noTipoBomba;
    this.noFornecimento = jsonObject.noFornecimento;
    this.noMarca = jsonObject.noMarca;
    this.noModelo = jsonObject.noModelo;
    this.noDiametro = jsonObject.noDiametro;
    this.nrPotencia = jsonObject.nrPotencia;
    this.nrEstagios = jsonObject.nrEstagios;
    this.nrVoltagem = jsonObject.nrVoltagem;
    this.nrConsumo = jsonObject.nrConsumo;
    this.noRede = jsonObject.noRede;
    this.nrProfundidade = jsonObject.nrProfundidade;
    this.noCaboEletrico = jsonObject.noCaboEletrico;
    this.noObservacoesEquip = jsonObject.noObservacoesEquip;
    this.nrSeqPocPoco = jsonObject.nrSeqPocPoco;

    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
