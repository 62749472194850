export default class ClienteMotivoInativo {
  constructor(jsonObject = {}) {
    this.nrSeqClienteMotivoInativo = jsonObject.nrSeqClienteMotivoInativo;
    this.noClienteMotivoInativo = jsonObject.noClienteMotivoInativo;
    this.cdClienteMotivoInativo = jsonObject.cdClienteMotivoInativo;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
