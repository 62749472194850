export default class PocCabo {
  constructor(jsonObject = {}) {
    this.nrSeqPocCabo = jsonObject.nrSeqPocCabo;
    this.cdPocCabo = jsonObject.cdPocCabo;
    this.noDescricao = jsonObject.noDescricao;
    this.flgAtivo = jsonObject.flgAtivo;
  }

  toJson = () => JSON.stringify(this);
}
