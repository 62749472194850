import React from 'react';
import { mask, MaskTypes } from 'ui/Helpers/masks';

function RenderTextMask(props) {
  const { label, value, masktype } = props;
  return (
    <div className='col-3 mt-2'>
      <div className='fw-bold'>{label}</div>
      {mask(value ?? '', masktype ?? null)}
    </div>
  );
}

export default function ResumoInformacoes(props) {
  const { associadoInfo } = props;
  return (
    <div className='bg-primary text-white bg-gradient rounded-2 p-3'>
      <div className='row justify-contente-between'>
        <RenderTextMask label='Código: ' value={associadoInfo?.cdAssociado} />
        <RenderTextMask label='Nome: ' value={associadoInfo?.noPessoa} />
        <RenderTextMask
          label='Data Cadastro: '
          value={associadoInfo?.dtAssociacao}
          masktype={MaskTypes.Date}
        />
        <RenderTextMask
          label='Consolidador: '
          value={associadoInfo?.consolidador?.noPessoa}
        />
      </div>
    </div>
  );
}
