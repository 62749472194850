import qs from 'qs';
import Pagination from 'core/models/pagination';
import Produto from 'core/models/EST/produto';
import ProdutoInventarioItem from 'core/models/EST/produtoInventarioItemModal';
import api from '../api';

const baseURL = `/EST/Produto`;

export const getProdutoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Produto(json)) ?? [],
  };
};

export const getProdutoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Produto(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getProduto = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Produto(value) : {};
};

export const saveProduto = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};
export const postMoverProduct = async (data) => {
  const response = await api.post(`${baseURL}/MoverProduct`, data);
  return response.data ?? {};
};

export const deleteProduto = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printProduto = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelProduto = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllProduto = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getProdutoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Produto(json)) ?? [],
  };
};
export const getProdutoAutoCompleteByCodigos = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteByCodigos`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    produtos: value?.map((json) => new Produto(json)) ?? [],
  };
};

export const getProdutosPaginatedForInventario = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPaginatedForInventario`, {
    params: filters,
  });

  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    produtos: value?.map((json) => new ProdutoInventarioItem(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getProdutosForInventario = async (pk) => {
  const response = await api.get(`${baseURL}/SearchForInventario`, {
    params: pk,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    produtos: value?.map((json) => new ProdutoInventarioItem(json)) ?? [],
  };
};

export const getCusto = async (nrSeqProduto) => {
  const response = await api.get(`${baseURL}/GetCusto`, {
    params: { nrSeqProduto },
  });
  const { value } = response.data ?? {};
  return value;
};
