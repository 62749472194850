import React from 'react';
import { FontAwesomeIcon } from 'ui/components';

export default function DropdownButton(props) {
  const { dropdownItems, text, icon, selectedItem, onSelectItem } = props;

  return (
    <div className='dropdown'>
      <button
        type='button'
        className='btn btn-inactive text-primary bordered dropdown-toggle'
        data-bs-toggle='dropdown'
        aria-expanded='false'
      >
        {icon && <FontAwesomeIcon icon={icon} />}
        {text}

        {selectedItem && (
          <>
            {selectedItem.icon && <FontAwesomeIcon icon={selectedItem.icon} />}
            {(selectedItem.showTextOnSelect ?? true) && selectedItem.text}
          </>
        )}
      </button>

      <ul className='dropdown-menu' aria-labelledby='dropdownMenuLink'>
        {dropdownItems &&
          dropdownItems.map((item) => (
            <li key={item.text}>
              <button
                type='button'
                className='dropdown-item'
                onClick={() => onSelectItem && onSelectItem(item)}
              >
                {item.icon && <FontAwesomeIcon icon={item.icon} />}
                {item.text && <span>{item.text}</span>}
              </button>
            </li>
          ))}
      </ul>
    </div>
  );
}
