import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';

import {
  CSDManutPage,
  Autocomplete,
  Switch,
  DatePicker,
  Textbox,
  Cheque,
  Button,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getCheque,
  saveCheque,
  deleteCheque,
  cancelCheque,
} from 'core/services/FIN/cheque';

import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getBancoAutoComplete } from 'core/services/FIN/banco';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';
import { getChequeSituacaoAutoComplete } from 'core/services/FIN/chequeSituacao';
import { getContaCorrenteAutoComplete } from 'core/services/FIN/contaCorrente';

export default function ChequeItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getCheque(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const onPrint = async () => {
    setLoading(true);

    setLoading(false);
  };

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveCheque(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteCheque(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return clientes;
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onSearchBanco = async (e) => {
    const { status, message: msg, bancos } = await getBancoAutoComplete({
      noBanco: e,
    });
    if (msg) onSetMessage(status, msg);
    return bancos;
  };

  const onSearchChequeSituacao = async (e) => {
    const {
      status,
      message: msg,
      situacoes,
    } = await getChequeSituacaoAutoComplete({
      noChequeSituacao: e,
    });
    if (msg) onSetMessage(status, msg);
    return situacoes;
  };

  const onSearchContaCorrente = async (e) => {
    const {
      status,
      message: msg,
      data: contas,
    } = await getContaCorrenteAutoComplete({
      noContaCorrente: e,
    });
    if (msg) onSetMessage(status, msg);
    return contas;
  };

  const onClickCancelCheque = async () => {
    if (!data.noMotivoCancelamento) {
      onSetMessage(ResponseStatus.Warning, 'Insira o Motivo do Cancelamento');
      return;
    }

    setLoading(true);
    const { status, message: msg } = await cancelCheque(data);

    if (status === 0) {
      setData({
        ...data,
        nrSeqChequeSituacao: 1,
        chequeSituacao: {
          nrSeqChequeSituacao: 1,
          noChequeSituacao: 'Cancelado',
        },
      });
    }

    setLoading(false);
    onSetMessage(status, msg);
  };

  const { id: idSelecao } = PageTypes.Selection;

  const componentRef = useRef();

  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Cheque'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqCheque > 0 ? onExcluir : null}
      message={message}
      onPrint={onPrint}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-5 mb-3'>
          <Autocomplete
            enabled={!data.nrSeqCheque > 0}
            label='Empresa'
            searchDataSource={onSearchEmpresa}
            selectedItem={data.empresa}
            onSelectItem={(empresa) => {
              setData({
                ...data,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>

        <div className='col-2'>
          <Textbox
            maxLength={40}
            label='Número'
            text={data.nrCheque}
            onChangedValue={(nrCheque) => setData({ ...data, nrCheque })}
          />
        </div>

        <div className='col mb-3'>
          <Switch
            formControl
            label='Recebido'
            checked={data.flgReceber}
            onChange={(flgReceber) => setData({ ...data, flgReceber })}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-3 '>
          <Autocomplete
            enabled={!data.nrSeqCheque > 0}
            label='Banco'
            searchDataSource={onSearchBanco}
            selectedItem={data.bancoCheque}
            onSelectItem={(bancoCheque) => {
              setData({
                ...data,
                bancoCheque,
                nrSeqBanco: bancoCheque.nrSeqBanco,
                banco: bancoCheque.cdBanco,
              });
            }}
            dataSourceTextProperty='noBanco'
          />
        </div>
        <div className='col-3 mb-3'>
          <Textbox
            maxLength={30}
            label='Agência'
            text={data.noAgencia}
            onChangedValue={(noAgencia) => setData({ ...data, noAgencia })}
          />
        </div>

        <div className='col-3 mb-3'>
          <Autocomplete
            label='Conta'
            searchDataSource={onSearchContaCorrente}
            selectedItem={data.contaCorrente}
            onSelectItem={(contaCorrente) => {
              setData({
                ...data,
                contaCorrente,
                nrSeqContaCorrente: contaCorrente.nrSeqContaCorrente,
              });
            }}
            dataSourceTextProperty='noContaCorrente'
          />
        </div>

        <div className='col-1 mb-3'>
          <Textbox
            maxLength={1}
            label='Tipo'
            text={data.tipo}
            onChangedValue={(tipo) => setData({ ...data, tipo })}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Cheque Situação'
            enabled={data.nrSeqChequeSituacao !== 3}
            searchDataSource={onSearchChequeSituacao}
            selectedItem={data.chequeSituacao}
            onSelectItem={(chequeSituacao) => {
              setData({
                ...data,
                chequeSituacao,
                nrSeqChequeSituacao: chequeSituacao.nrSeqChequeSituacao,
              });
            }}
            dataSourceTextProperty='noChequeSituacao'
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data do Cheque'
            text={data.dtDocumento}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtDocumento) => setData({ ...data, dtDocumento })}
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data Vencimento'
            text={data.dtVencimento}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtVencimento) => setData({ ...data, dtVencimento })}
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Valor'
            maxLength={12}
            text={data.vlrCheque}
            mask={MaskTypes.Decimal}
            onChangedValue={(vlrCheque) => setData({ ...data, vlrCheque })}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-9'>
          <Textbox
            maxLength={300}
            label='Descrição'
            text={data.noCheque}
            onChangedValue={(noCheque) => setData({ ...data, noCheque })}
          />
        </div>
      </div>
      <div className='row  mb-5'>
        <div className='col-6'>
          <Autocomplete
            enabled={!data.nrSeqCheque > 0}
            label='Cliente'
            searchDataSource={onSearchCliente}
            selectedItem={data.clienteCheque}
            onSelectItem={(clienteCheque) => {
              setData({
                ...data,
                clienteCheque,
                nrSeqPessoa: clienteCheque.nrSeqPessoaCli,
                cliente: clienteCheque.noPessoa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      {(data.nrSeqChequeSituacao === 3 && data.nrSeqCheque > 0) ||
      data.noMotivoCancelamento ? (
        <div className='row mb-5'>
          <div className='col-6'>
            <Textbox
              maxLength={300}
              label='Motivo do Cancelamento'
              text={data.noMotivoCancelamento}
              onChangedValue={(noMotivoCancelamento) =>
                setData({ ...data, noMotivoCancelamento })
              }
            />
          </div>
        </div>
      ) : null}

      {data.nrSeqChequeSituacao === 3 && data.nrSeqCheque > 0 && (
        <div className='row  mb-5'>
          <div className='col-6'>
            <Button
              outline
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Cancelar Liquidação do Cheque'
              onClick={onClickCancelCheque}
            />
          </div>
        </div>
      )}

      {data.flgPermiteImpressao && (
        <div className='row'>
          <div className='col-9'>
            <Cheque ref={componentRef} model={data} />
          </div>
        </div>
      )}

      {!data.flgPermiteImpressao && (
        <div className='row'>
          <div className='col-9'>
            <span>Existe cheque anterior não impresso</span>
          </div>
        </div>
      )}
    </CSDManutPage>
  );
}
