import React, { useState, useImperativeHandle } from 'react';
import {
  BootstrapSizes,
  generateID,
  Theme,
  ResponseStatus,
} from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  Form,
  Loader,
  Modal,
  Notification,
  RadioButton,
  Textbox,
} from 'ui/components';
import { MaskTypes, unMask } from 'ui/Helpers/masks';
import { getEmpresasUsuario } from 'core/services/SEG';
import { saveChip, getOperadoraAutoComplete } from 'core/services/TEL';
import { Chip } from 'core/models/TEL';

const tipoLinhaButtons = [
  { text: 'Móvel', value: 'M' },
  { text: 'Fixo', value: 'F' },
  { text: 'Ramal', value: 'R' },
  { text: 'Link Net', value: 'L' },
  { text: 'Toll-free', value: 'T' },
];
const CadastroRapidoChipContent = React.forwardRef(
  ({ handleNewItemSaved }, ref) => {
    const [data, setData] = useState({ noTipo: 'M' });
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});

    const onSearchOperadora = async () => {
      const {
        status,
        message: msg,
        operadoras,
      } = await getOperadoraAutoComplete({});

      if (status === ResponseStatus.Error) {
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
        return [];
      }
      return operadoras;
    };

    const onSearchEmpresa = async () => {
      const { status, message: msg, empresas } = await getEmpresasUsuario({});

      if (status === ResponseStatus.Error) {
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
        return [];
      }
      return empresas;
    };

    const handleFormSubmit = async () => {
      setLoading(true);

      const unmasked = unMask(data.nrLinha, MaskTypes.CellPhone);

      const { status, message: msg, value } = await saveChip({
        ...data,
        nrPrefixo: unmasked.substring(0, 2),
        nrLinha: unmasked.substring(2),
      });

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      if (status === ResponseStatus.Success)
        handleNewItemSaved(new Chip(value));

      setLoading(false);
    };

    return (
      <Form ref={ref} onSubmit={handleFormSubmit}>
        <Loader loading={loading} />
        <Notification message={message.message} theme={message.theme} />

        <div className='row'>
          <div className='col-lg-7 mb-3'>
            <Autocomplete
              label='Empresa'
              required
              searchDataSource={onSearchEmpresa}
              selectedItem={data.empresa}
              onSelectItem={(empresa) =>
                setData({
                  ...data,
                  empresa,
                  nrSeqEmpresa: empresa.nrSeqEmpresa,
                })
              }
              dataSourceTextProperty='noPessoa'
            />
          </div>
          <div className='col-lg-5 mb-3'>
            <Autocomplete
              label='Operadora'
              required
              searchDataSource={onSearchOperadora}
              selectedItem={data.operadora}
              onSelectItem={(operadora) =>
                setData({
                  ...data,
                  operadora,
                  nrSeqOperadora: operadora.nrSeqOperadora,
                })
              }
              dataSourceTextProperty='noOperadora'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg'>
            <div className='row'>
              <div className='col-lg-6 mb-3'>
                <Textbox
                  label='Nr Linha'
                  required
                  mask={MaskTypes.CellPhone}
                  text={data.nrLinha}
                  onChangedValue={(nrLinha) => setData({ ...data, nrLinha })}
                />
              </div>
              <div className='col-lg-6 mb-3'>
                <Textbox
                  label='ICCID'
                  text={data.nrICCID}
                  onChange={(nrICCID) => setData({ ...data, nrICCID })}
                />
              </div>
            </div>
          </div>
          <div className='col-auto mb-3'>
            <RadioButton
              label='Tipo Linha'
              required
              theme={Theme.Primary}
              buttons={tipoLinhaButtons}
              selectedButton={data.noTipo}
              onChange={(noTipo) =>
                setData({
                  ...data,
                  noTipo,
                })
              }
            />
          </div>
        </div>
      </Form>
    );
  }
);

const CadastroRapidoChip = React.forwardRef(({ handleNewItemSaved }, ref) => {
  const [show, setShow] = useState(false);
  const form = React.createRef();

  useImperativeHandle(ref, () => ({
    Show() {
      setShow(true);
    },
  }));

  return (
    <div ref={ref}>
      <Modal
        size={BootstrapSizes.ExtraLarge}
        show={show}
        key={generateID()}
        title='Cadastro Rápido de Chip'
        onClose={() => setShow(false)}
      >
        <Modal.Body>
          <CadastroRapidoChipContent
            ref={form}
            handleNewItemSaved={(data) => {
              if (handleNewItemSaved) handleNewItemSaved(data);
              setShow(false);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            text='Salvar'
            theme={Theme.Success}
            onClick={() => {
              form.current?.submit();
            }}
          />
          <Button
            text='Sair'
            onClick={() => setShow(false)}
            theme={Theme.Light}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
});
export default CadastroRapidoChip;
