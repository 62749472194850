export default class TipoConvenioEncargo {
  constructor(jsonObject = {}) {
    this.nrSeqTipoConvenioEncargo = jsonObject.nrSeqTipoConvenioEncargo;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.cdTipoConvenioEncargo = jsonObject.cdTipoConvenioEncargo;
    this.noTipoConvenioEncargo = jsonObject.noTipoConvenioEncargo;
  }

  toJson = () => JSON.stringify(this);
}
