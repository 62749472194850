import React, { useState, useEffect } from 'react';
import {
  Modal,
  Textbox,
  Button,
  Notification,
  Loader,
  Switch,
} from 'ui/components';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import { Message } from 'ui/Helpers/interfaces';
import { ResponseStatus } from 'ui/Helpers/enums';
import ColetaConsignatario from 'core/models/FRO/coletaConsignatario';

import { getPessoaAutoComplete } from 'core/services/SEG/pessoa';
import { saveColetaConsignatario } from 'core/services/FRO/coletaConsignatario';

interface Props {
  show: boolean;
  onClose: (status?: ResponseStatus, msg?: string) => void;
}

export default function ModalCadastroConsignatario({ show, onClose }: Props) {
  const [data, setData] = useState(new ColetaConsignatario({}));
  const [message, setMessage] = useState<Message | null>(null);
  const [loading, setLoading] = useState<Boolean>(false);
  const [showFieldsfields, setShowFieldsfields] = useState(false);

  const onClickCadastrar = async (): Promise<void> => {
    setLoading(true);

    const { status, message: msg } = await saveColetaConsignatario(data);

    if (status === ResponseStatus.Success) {
      setData(new ColetaConsignatario({}));

      onClose(status, msg);
    } else if (msg) {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    setLoading(false);
  };

  const onSend = (): void => {
    setData(new ColetaConsignatario({}));

    onClose();
  };

  const onBlurCnpj = async (documento: String): Promise<void> => {
    if (!documento) return;

    let dataSource = new ColetaConsignatario({});

    if (documento.length >= 14) {
      setLoading(true);

      const {
        status,
        message: msg,
        data: pessoa,
      }: any = await getPessoaAutoComplete({
        flgFisica: false,
        nrCnpj: documento,
      });

      if (msg)
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });

      if (status === ResponseStatus.Success && pessoa.length > 0) {
        dataSource = {
          ...data,
          nrSeqPessoa: pessoa[0].nrSeqPessoa,
          noPessoa: pessoa[0].noPessoa,
        };
      } else if (!msg) {
        setMessage({
          message: 'CNPJ não encontrado no sistema.',
          theme: Theme.Danger,
        });
      }

      setLoading(false);
    }

    dataSource.documento = documento;

    setData(dataSource);
  };

  useEffect(() => {
    setShowFieldsfields(data.nrSeqPessoa && data.nrSeqPessoa > 0);
  }, [data]);

  return (
    // @ts-expect-error
    <Modal
      show={show}
      title='Cadastrar Consignatário'
      onClose={() => onSend()}
      size={BootstrapSizes.Large}
    >
      {/* @ts-expect-error */}
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            {/* @ts-expect-error */}
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='row mb-3'>
            <div className='col-5'>
              {/* @ts-expect-error */}
              <Textbox
                label='CNPJ'
                readOnly={showFieldsfields}
                placeholder='Informe o CNPJ para seguir com o cadastro'
                mask={MaskTypes.CNPJ}
                text={data.documento}
                onBlur={onBlurCnpj}
                onChangedValue={(documento: String) => {
                  onBlurCnpj(documento);

                  setData({
                    ...data,
                    documento,
                  });
                }}
              />
            </div>
            {showFieldsfields && (
              <div className='col-7'>
                {/* @ts-expect-error */}
                <Textbox label='Consgignatário' readOnly text={data.noPessoa} />
              </div>
            )}
          </div>
          {showFieldsfields && (
            <div className='row'>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <Switch
                  label='Ativo'
                  checked={data.flgAtivo}
                  formControl
                  onChange={(flgAtivo: Boolean) => {
                    setData({
                      ...data,
                      flgAtivo,
                    });
                  }}
                />
              </div>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <Switch
                  label='Origem'
                  checked={data.flgOrigem}
                  formControl
                  onChange={(flgOrigem: Boolean) => {
                    setData({
                      ...data,
                      flgOrigem,
                    });
                  }}
                />
              </div>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <Switch
                  label='Destino'
                  checked={data.flgDestino}
                  formControl
                  onChange={(flgDestino: Boolean) => {
                    setData({
                      ...data,
                      flgDestino,
                    });
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Cadastrar'
          icon={['far', 'save']}
          template={Button.Templates.Default}
          theme={Theme.Success}
          size={BootstrapSizes.Medium}
          onClick={() => onClickCadastrar()}
        />
        <Button
          text='Sair'
          icon={['fas', 'times']}
          template={Button.Templates.Default}
          theme={Theme.Danger}
          size={BootstrapSizes.Medium}
          onClick={() => onSend()}
        />
      </Modal.Footer>
    </Modal>
  );
}
