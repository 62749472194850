import Pessoa from 'core/models/SEG/pessoa';
import PessoaReferencias from 'core/models/SEG/pessoaReferencias';
import Empresa from 'core/models/SEG/empresa';
import VendedorRegiao from 'core/models/VEN/vendedorRegiao';
import VendedorNivel from 'core/models/VEN/vendedorNivel';
import VendedorTelefonia from 'core/models/VEN/vendedorTelefonia';
import VendedorEquipe from 'core/models/FIN/vendedorEquipe';
import VendedorEstado from 'core/models/FIN/vendedor_Estado';

export default class Vendedor extends Pessoa {
  constructor(jsonObject = {}) {
    super(jsonObject);
    this.nrSeqPessoaVen = jsonObject.nrSeqPessoaVen;
    this.nrSeqVendedorTelefonia = jsonObject.nrSeqVendedorTelefonia;
    this.flgLicitacao = jsonObject.flgLicitacao;
    this.cdVendedor = jsonObject.cdVendedor;
    this.flgAtivo = jsonObject.flgAtivo;
    this.comissao = jsonObject.comissao;
    this.flgComissao = jsonObject.flgComissao;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgEquipe = jsonObject.flgEquipe;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.flgComissaoTelevenda = jsonObject.flgComissaoTelevenda;
    this.comissaoTelevenda = jsonObject.comissaoTelevenda;
    this.nrSeqVendedorNivel = jsonObject.nrSeqVendedorNivel;
    this.nrSeqVendedorRegiao = jsonObject.nrSeqVendedorRegiao;
    this.flgLicitacao = jsonObject.flgLicitacao;
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.vendedorRegiao = new VendedorRegiao(jsonObject.vendedorRegiao ?? {});
    this.nivel = new VendedorNivel(jsonObject.nivel ?? {});
    this.vendedorTelefonia = new VendedorTelefonia(
      jsonObject.vendedorTelefonia ?? {}
    );
    this.vendedorReferencias =
      jsonObject.vendedorReferencias?.map((el) => new PessoaReferencias(el)) ??
      [];
    this.vendedorEquipes =
      jsonObject.vendedorEquipes?.map((el) => new VendedorEquipe(el)) ?? [];
    this.vendedorEstados =
      jsonObject.vendedorEstados?.map((el) => new VendedorEstado(el)) ?? [];
  }

  toJson = () => JSON.stringify(this);
}
