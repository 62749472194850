import { Motorista } from '../FRO';
import FormaPagamento from './formaPagamento';

export default class AcertoMotoristaAdiantamento {
  constructor(jsonObject = {}) {
    this.nrSeqAcertoMotoristaAdiantamento =
      jsonObject.nrSeqAcertoMotoristaAdiantamento;
    this.dtAdiantamento = jsonObject.dtAdiantamento;
    this.vlrAdiantamento = jsonObject.vlrAdiantamento;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqAcertoMotorista = jsonObject.nrSeqAcertoMotorista;
    this.nrSeqCheque = jsonObject.nrSeqCheque;
    this.nrSeqTituloPagar = jsonObject.nrSeqTituloPagar;
    this.obsAdiantamento = jsonObject.obsAdiantamento;
    this.nrSeqPessoaMot = jsonObject.nrSeqPessoaMot;
    this.nrSeqTituloReceber = jsonObject.nrSeqTituloReceber;
    this.nrSeqFreteTerceiro = jsonObject.nrSeqFreteTerceiro;
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.pessoaMot = new Motorista(jsonObject.pessoaMot ?? {});
    this.nrCheque = jsonObject.nrCheque;
    this.dtCheque = jsonObject.dtCheque;
    this.tituloPagar = jsonObject.tituloPagar;
    this.tituloReceber = jsonObject.tituloReceber;
  }

  toJson = () => JSON.stringify(this);
}
