import React, { useState, useEffect } from 'react';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';
import {
  Button,
  Loader,
  Modal,
  DropdownList,
  Textbox,
  Notification,
} from 'ui/components';
import { getGruposLinha, moverLinhaGrupo } from 'core/services/TEL';
import { MaskTypes } from 'ui/Helpers/masks';

const ModalMoverLinhaGrupo = ({ linhas, show, onClose }) => {
  const [loading, setLoading] = useState(true);
  const [grupos, setGrupos] = useState([]);
  const [state, setState] = useState({});
  const [message, setMessage] = useState();

  const BuscaGrupos = async (linha) => {
    const { listaGrupos } = await getGruposLinha({ linha });
    if (listaGrupos.length > 0) setGrupos(listaGrupos);

    setLoading(false);
  };

  useEffect(() => {
    setState({});
    if (linhas.length > 0) {
      setLoading(true);
      BuscaGrupos(linhas[0].linha);
    }
  }, [show]);

  const onCancelar = async () => {
    onClose();
  };

  const onMoverLinhaGrupo = async () => {
    setLoading(true);

    const { status, message: msg } = await moverLinhaGrupo({
      grupo: state.grupo.nome,
      linhas: linhas.map((p) => p.linha),
      cotaEmGB: state.cota,
    });

    setLoading(false);

    if (status === ResponseStatus.Error) {
      setMessage({
        message: msg,
        theme: Theme.Danger,
      });
    } else onClose(msg);
  };

  return (
    <Modal
      show={show}
      size={BootstrapSizes.Medium}
      title='Mover Linhas de Grupo'
      onClose={onClose}
    >
      <Modal.Body>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage()}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col'>
            <DropdownList
              label='Grupo'
              dataSource={grupos}
              dataSourcePropertyText='nomeCotaDisponivel'
              selectedItems={state.grupo ?? []}
              onSelectItem={(item) =>
                setState({
                  ...state,
                  grupo: item,
                })
              }
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <Textbox
              label='Cota em GB'
              mask={MaskTypes.Decimal}
              onChangedValue={(cota) => setState({ ...state, cota })}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <div className='alert alert-info'>
              <h5 className='alert-heading'>Linhas:</h5>
              {linhas.map((p) => p.linha).join(', ')}
            </div>
          </div>
        </div>
        <Loader loading={loading} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          icon='share'
          text='Mover Linhas'
          theme={Theme.Success}
          onClick={onMoverLinhaGrupo}
        />
        <Button
          icon='ban'
          text='Cancelar'
          theme={Theme.Danger}
          onClick={onCancelar}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalMoverLinhaGrupo;
