import CentroDeCusto from 'core/models/TEL/centroDeCusto';

export default class UsuarioCentroDeCusto {
  constructor(jsonObject = {}) {
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.nrSeqCentroDeCusto = jsonObject.nrSeqCentroDeCusto;
    this.centroDeCusto = new CentroDeCusto(jsonObject.centroDeCusto ?? {});
  }

  toJson = () => JSON.stringify(this);
}
