export default class VeiculoMarca {
  constructor(jsonObject = {}) {
    this.nrSeqVeiculoMarca = jsonObject.nrSeqVeiculoMarca;
    this.cdVeiculoMarca = jsonObject.cdVeiculoMarca;
    this.noVeiculoMarca = jsonObject.noVeiculoMarca;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
