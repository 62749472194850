import { Estado } from '../SEG';

export default class AcertoMotoristaPercentualReducao {
  constructor(jsonObject = {}) {
    this.nrSeqEstadoOri = jsonObject.nrSeqEstadoOri;
    this.nrSeqEstadoDest = jsonObject.nrSeqEstadoDest;
    this.vlrPercentualComissao = jsonObject.vlrPercentualComissao;
    this.vlrPercentualReducao = jsonObject.vlrPercentualReducao;
    this.nrSeqAcertoMotoristaPercentualReducao =
      jsonObject.nrSeqAcertoMotoristaPercentualReducao;
    this.estadoOri = new Estado(jsonObject.estadoOri ?? {});
    this.estadoDest = new Estado(jsonObject.estadoDest ?? {});
    this.noEstadoOri = jsonObject.noEstadoOri;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.cliente = jsonObject.cliente;
    this.flgVinculaRemetente = jsonObject.flgVinculaRemetente;
  }

  toJson = () => JSON.stringify(this);
}
