import Pneu from 'core/models/FRO/pneu';
import PneuRodagem from 'core/models/FRO/pneuRodagem';

export default class PneuVeiculo {
  constructor(jsonObject = {}) {
    this.nrSeqPneuVeiculo = jsonObject.nrSeqPneuVeiculo;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.nrOrdem = jsonObject.nrOrdem;
    this.dtInicio = jsonObject.dtInicio;
    this.dtFim = jsonObject.dtFim;
    this.nrSeqEixo = jsonObject.nrSeqEixo;
    this.nrSeqPneu = jsonObject.nrSeqPneu;
    this.kmInicio = jsonObject.kmInicio;
    this.kmFim = jsonObject.kmFim;
    this.kmAcumulado = jsonObject.kmAcumulado;
    this.nrSeqPneuMotivoTroca = jsonObject.nrSeqPneuMotivoTroca;
    this.nrSulco = jsonObject.nrSulco;
    this.noObsMotivoTroca = jsonObject.noObsMotivoTroca;
    this.pneu = new Pneu(jsonObject.pneu ?? {});
    this.pneuRodagem = new PneuRodagem(jsonObject.pneuRodagem ?? {});
    this.pneuRecapagem = jsonObject.pneuRecapagem;
  }

  toJson = () => JSON.stringify(this);
}
