import { Empresa, Pessoa } from '../SEG';
import FormaLancamentoEletronico from './formaLancamentoEletronico';
import FormaPagamento from './formaPagamento';
import Fornecedor from './fornecedor';
import InstrucaoMovimento from './instrucaoMovimento';
import TipoDespesa from './tipoDespesa';
import TipoDespesaPlanoDeConta from './tipoDespesa_PlanoDeConta';
import TipoMovimento from './tipoMovimento';
import TipoMovimentoRemessa from './tipoMovimentoRemessa';
import TipoServicoBancario from './tipoServicoBancario';
import TituloPagarTipoDocumento from './tituloPagarTipoDocumento';

export default class CustoFixo {
  constructor(jsonObject = {}) {
    this.nrSeqCustoFixo = jsonObject.nrSeqCustoFixo;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqPessoaFor = jsonObject.nrSeqPessoaFor;
    this.flgAtivo = jsonObject.flgAtivo;
    this.vlrCusto = jsonObject.vlrCusto;
    this.nrSeqTituloPagarTipoDocumento =
      jsonObject.nrSeqTituloPagarTipoDocumento;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.diaVencimento = jsonObject.diaVencimento;
    this.nrSeqTipoDespesa = jsonObject.nrSeqTipoDespesa;
    this.noDetalhe = jsonObject.noDetalhe;
    this.nrTit = jsonObject.nrTit;
    this.nrSeqPlanoDeConta = jsonObject.nrSeqPlanoDeConta;
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.fornecedor = new Fornecedor(jsonObject.fornecedor ?? {});
    this.tipoDespesa = new TipoDespesa(jsonObject.tipoDespesa ?? {});
    this.tituloPagarTipoDocumento = new TituloPagarTipoDocumento(
      jsonObject.tituloPagarTipoDocumento ?? {}
    );
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.planoDeConta = new TipoDespesaPlanoDeConta(
      jsonObject.planoDeConta ?? {}
    );
    this.tipoServico = new TipoServicoBancario(jsonObject.tipoServico ?? {});
    this.formaLancamentoEletronico = new FormaLancamentoEletronico(
      jsonObject.formaLancamentoEletronico ?? {}
    );
    this.tipoMovimento = new TipoMovimento(jsonObject.tipoMovimento ?? {});
    this.instrucaoMovimento = new InstrucaoMovimento(
      jsonObject.instrucaoMovimento ?? {}
    );
    this.movimentoRemessa = new TipoMovimentoRemessa(
      jsonObject.movimentoRemessa ?? {}
    );
    this.pessoaSacador = new Pessoa(jsonObject.pessoaSacador ?? {});
    this.nrSeqFormaLancamentoEletronico =
      jsonObject.nrSeqFormaLancamentoEletronico;
    this.nrSeqTipoMovimentoRemessa = jsonObject.nrSeqTipoMovimentoRemessa;
    this.nrSeqTipoServicoBancario = jsonObject.nrSeqTipoServicoBancario;
    this.nrSeqPessoaSacador = jsonObject.nrSeqPessoaSacador;
    this.nrSeqInstrucaoMovimento = jsonObject.nrSeqInstrucaoMovimento;
    this.nrSeqTipoMovimento = jsonObject.nrSeqTipoMovimento;
  }

  toJson = () => JSON.stringify(this);
}
