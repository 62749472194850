import React, { useState } from 'react';
import { Modal, Button, Notification, Loader } from 'ui/components';
import { BootstrapSizes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';
// @ts-expect-error
import { Message } from 'ui/Helpers/interfaces.ts';

import ColetaEmbarque from 'core/models/FRO/coletaEmbarque';

import { cancelamentoExtemporaneo } from 'core/services/FRO/coletaEmbarque';

interface Props {
  show: Boolean;
  cte: ColetaEmbarque;
  onClose: (x: any) => void;
}

export default function ModalCancelamentoExtemporaneo({
  show,
  cte,
  onClose,
}: Props) {
  const [message, setMessage] = useState<Message | null>(null);
  const [loading, setLoading] = useState<Boolean>(false);

  const onClickCancelar = async () => {
    setLoading(true);

    const { status, message: msg, data }: any = await cancelamentoExtemporaneo(
      cte
    );

    if (status !== ResponseStatus.Success) {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    } else {
      onClose(data);
    }

    setLoading(false);
  };

  const onSend = () => {
    onClose({});
  };

  return (
    // @ts-expect-error
    <Modal
      show={show}
      title={`Cancelamento Extemporâneo (CTe ${cte?.cdColetaEmbarque})`}
      onClose={() => onSend()}
    >
      {/* @ts-expect-error */}
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            {/* @ts-expect-error */}
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mx-1'>
          <div className='alert alert-primary' role='alert'>
            Para efetuar o <b>cancelamento extemporâneo</b> é preciso abrir um
            protocolo diretamente na Secretaria da Fazenda ou pelo site.
            <br />
            <br /> Caso haja MDF-e <b>autorizado ou encerrado</b>, não poderá
            ser efetuado o cancelamento. Uma segunda alternativa neste caso
            seria gerar um CT-e de substituição com o valor de R$0,01.
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Cancelar'
          icon='ban'
          template={Button.Templates.Default}
          theme={Theme.Success}
          size={BootstrapSizes.Medium}
          onClick={() => onClickCancelar()}
        />
        <Button
          text='Sair'
          icon={['fas', 'times']}
          template={Button.Templates.Default}
          theme={Theme.Danger}
          size={BootstrapSizes.Medium}
          onClick={() => onSend()}
        />
      </Modal.Footer>
    </Modal>
  );
}
