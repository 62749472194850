import React, { useState, useEffect, useRef } from 'react';
import {
  BootstrapSizes,
  MimeTypes,
  Theme,
  ResponseStatus,
} from 'ui/Helpers/utils';
import { getPostoCombustivelAutoCompleteWithIntegration } from 'core/services/FRO/postoCombustivel';
import {
  postUploadAbastecimentos,
  postImportAbastecimentos,
  postGenerateFinancialAbastecimentos,
} from 'core/services/FRO/abastecimento';
import { getEmpresasUsuario } from 'core/services/SEG/empresa';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';

import { MaskTypes } from 'ui/Helpers/masks';

import {
  Button,
  FileUpload,
  Loader,
  Modal,
  Notification,
  Autocomplete,
  DatePicker,
  GridView,
  Textbox,
} from 'ui/components';

export default function ModalImportacaoAbastecimentos({ show, onCancel }) {
  const [importedFile, setImportedFile] = useState([]);
  const [message, onSetMessage] = useState();
  const [loading, setLoading] = useState();
  const [data, setData] = useState({});
  const [valorTotal, setValorTotal] = useState(0);
  const gridView = useRef();

  useEffect(() => {
    setImportedFile([]);
    onSetMessage(null);
    setLoading(false);
  }, [show]);

  const onClickRemoveAbastecimento = async (selectedValue, datasource) => {
    const dataSourceFiltered = datasource.filter(
      (elem) => elem !== selectedValue
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(dataSourceFiltered);
  };

  const columns = [
    {
      key: 'dtAbastecimento',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'veiculo.placa',
      title: 'Placa',
      format: GridView.DataTypes.Text,
      colorText: 'noColorVeiculo',
    },
    {
      key: 'noMotorista',
      title: 'Motorista',
      format: GridView.DataTypes.Text,
    },
    {
      key: 'tipoCombustivel.noTipoCombustivel',
      title: 'Tipo Combustível',
      format: GridView.DataTypes.Text,
      colorText: 'noColorTipoCombustivel',
    },
    { key: 'nrDocumento', title: 'Documento' },
    { key: 'qtdeLitro', title: 'Litros', format: GridView.DataTypes.Decimal },
    {
      key: 'vlrLitro',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotal',
      title: 'Total',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'nrSeqAbastecimento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveAbastecimento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onSearchPostoCombustivel = async (e) => {
    const {
      status,
      message: msg,
      data: postos,
    } = await getPostoCombustivelAutoCompleteWithIntegration({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return postos;
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({ noFormaPagamento: e });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const uploadFile = async () => {
    setLoading(true);

    if (!importedFile)
      onSetMessage({
        message: 'Não há arquivos selecionados para importação.',
        theme: Theme.Danger,
      });
    else {
      const {
        status,
        message: msg,
        data: abastecimentos,
        pagination,
      } = await postUploadAbastecimentos(
        importedFile[0],
        data.nrSeqPessoaPos,
        data.nrSeqFormaPagamento,
        data.dtVencimento
      );

      onSetMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      if (status === ResponseStatus.Success) {
        if (gridView && gridView.current)
          gridView.current.setDataSource(abastecimentos, pagination);
      }

      let total = 0;
      abastecimentos.forEach((item) => {
        if (!item.noColorVeiculo && !item.noColorTipoCombustivel)
          total += item.vlrTotal;
      });
      setValorTotal(total);
    }
    setLoading(false);
  };

  const postImport = async () => {
    setLoading(true);

    const abastecimentosImport = gridView.current.getDataSource();

    const { status, message: msg } = await postImportAbastecimentos(
      abastecimentosImport
    );

    onSetMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const postGenerateFinancial = async () => {
    setLoading(true);

    const abastecimentosImport = gridView.current.getDataSource();
    if (abastecimentosImport.length > 0) {
      abastecimentosImport[0].nrSeqEmpresa = data.nrSeqEmpresa;
      abastecimentosImport[0].nrDocumentoTituloPagar = data.nrDocumento;
    }

    const { status, message: msg } = await postGenerateFinancialAbastecimentos(
      abastecimentosImport
    );

    onSetMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  return (
    <Modal
      show={show}
      title='Importação de Abastecimentos'
      icon='cloud-upload-alt'
      size={BootstrapSizes.Large}
      onClose={onCancel}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => onSetMessage()}
            />
          </div>
        )}

        <div className='row mb-3'>
          <div className='col-8'>
            <Autocomplete
              label='Posto Combustível'
              searchDataSource={onSearchPostoCombustivel}
              selectedItem={data.postoCombustivel}
              onSelectItem={(postoCombustivel) => {
                setData({
                  ...data,
                  postoCombustivel,
                  nrSeqPessoaPos: postoCombustivel.nrSeqPessoaPos,
                });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-8'>
            <Autocomplete
              label='Empresa'
              searchDataSource={onSearchEmpresa}
              selectedItem={data.empresa}
              onSelectItem={(empresa) => {
                setData({
                  ...data,
                  empresa,
                  nrSeqEmpresa: empresa.nrSeqEmpresa,
                });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
          <div className='col-3'>
            <Textbox
              maxLength={20}
              label='Documento'
              text={data.nrDocumento}
              onChangedValue={(nrDocumento) =>
                setData({ ...data, nrDocumento })
              }
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-8 mb-3'>
            <Autocomplete
              label='Forma Pagamento'
              searchDataSource={onSearchFormaPagamento}
              selectedItem={data.formaPagamento}
              onSelectItem={(formaPagamento) => {
                setData({
                  ...data,
                  formaPagamento,
                  nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                });
              }}
              dataSourceTextProperty='noFormaPagamento'
            />
          </div>

          <div className='col-4'>
            <DatePicker
              label='Vencimento'
              text={data.dtVencimento}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtVencimento) => setData({ ...data, dtVencimento })}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <FileUpload
              files={importedFile}
              onChange={(files) => setImportedFile(files)}
              allowedMimeTypes={[MimeTypes.Types.Excel]}
            />
          </div>
        </div>

        <div className='row mt-5  mb-4'>
          <div className='col-12 text-center'>
            <Button
              text='Carregar Arquivo'
              icon='folder-open'
              theme={Theme.Success}
              onClick={uploadFile}
            />
            <Button
              text='Importar Abastecimentos'
              icon='list'
              theme={Theme.Secondary}
              onClick={postImport}
              style={{ marginLeft: '5px' }}
            />
            <Button
              text='Gerar Título a Pagar'
              icon='check'
              theme={Theme.Warning}
              onClick={postGenerateFinancial}
              style={{ marginLeft: '5px' }}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridView}
              dataColumns={columns}
              className='table-striped table-hover table-bordered table-sm'
              showSelectSizes={false}
              sortOffline
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <span className='form-label text-right ' style={{ marginRight: '60%' }}>
          <b>Valor Total: {valorTotal.toLocaleString('pt-BR')}</b>
        </span>

        <Button
          text='Cancelar'
          icon='ban'
          theme={Theme.Danger}
          onClick={onCancel}
          style={{ marginLeft: '5px' }}
        />
      </Modal.Footer>
    </Modal>
  );
}
