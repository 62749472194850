import qs from 'qs';
import Pagination from 'core/models/pagination';
import PocMetodoPerfuracao from 'core/models/POC/perfuracao';
import api from '../api';

const baseURL = `/POC/Perfuracao`;

export const getPerfuracaoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    perfuracao: value?.map((json) => new PocMetodoPerfuracao(json)) ?? [],
  };
};

export const getPocMetodoPerfuracaoList = async (filters) => {
  const response = await api.get(`/$PocMetodoPerfuracao/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocMetodoPerfuracao(json)) ?? [],
  };
};

export const getPocMetodoPerfuracaoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocMetodoPerfuracao(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocMetodoPerfuracao = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocMetodoPerfuracao(value) : {};
};

export const savePocMetodoPerfuracao = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePocMetodoPerfuracao = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPocMetodoPerfuracao = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocMetodoPerfuracao = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocMetodoPerfuracao = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
