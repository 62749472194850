export default class GrupoCofins {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoCofins = jsonObject.nrSeqGrupoCofins;
    this.cdGrupoCofins = jsonObject.cdGrupoCofins;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.aliquota = jsonObject.aliquota;
    this.baseCalculo = jsonObject.baseCalculo;
    this.noGrupoCofins = jsonObject.noGrupoCofins;
    this.nrSeqCstCofins = jsonObject.nrSeqCstCofins;
  }

  toJson = () => JSON.stringify(this);
}
