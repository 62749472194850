import Veiculo from './veiculo';

export default class VeiculoRodagem {
  constructor(jsonObject = {}) {
    this.nrSeqVeiculoRodagem = jsonObject.nrSeqVeiculoRodagem;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.nrSeqVeiculoVinc = jsonObject.nrSeqVeiculoVinc;
    this.dtInicio = jsonObject.dtInicio;
    this.dtFim = jsonObject.dtFim;
    this.nrOdometroInicial = jsonObject.nrOdometroInicial;
    this.nrOdometroFinal = jsonObject.nrOdometroFinal;
    this.nrOdometroAcumulado = jsonObject.kmRodado;

    // Instâncias
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    this.veiculoVinc = new Veiculo(jsonObject.veiculoVinc ?? {});

    // Propriedades Auxiliares
    this.nrSeqVeiculosVinculados = jsonObject.nrSeqVeiculosVinculados;
  }

  toJson = () => JSON.stringify(this);
}
