import ChecklistValor from 'core/models/FRO/checklistValor';

export default class ChecklistFrotaRetorno {
  constructor(jsonObject = {}) {
    this.nrSeqChecklistFrotaRetorno = jsonObject.nrSeqChecklistFrotaRetorno;
    this.nrSeqChecklistValor = jsonObject.nrSeqChecklistValor;
    this.noComplemento = jsonObject.noComplemento;
    this.nrSeqManutencaoChecklist = jsonObject.nrSeqManutencaoChecklist;
    this.nrSeqUsuarioConf = jsonObject.nrSeqUsuarioConf;
    this.nrSeqChecklistCampos = jsonObject.nrSeqChecklistCampos;

    // #region Propriedades Auxiliares
    this.checklistValor = new ChecklistValor(jsonObject.checklistValor ?? {});

    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
