import qs from 'qs';
import Pagination from 'core/models/pagination';
import { MimeTypes } from 'ui/Helpers/utils';
import Nf from 'core/models/COP/nf';
import api from '../api';

const baseURL = `/COP/Nf`;

export const getNfAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Nf(json)) ?? [],
  };
};

export const getNfList = async (filters) => {
  const response = await api.get(`${baseURL}/Nf/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Nf(json)) ?? [],
  };
};

export const getSearchPendingControlMerchandiseList = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPendingControlMerchandise`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Nf(json)) ?? [],
  };
};

export const getNfPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Nf(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getNf = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { status, value, message } = response.data ?? {};
  return {
    status,
    message,
    data: value ? new Nf(value) : {},
  };
};

export const saveNf = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  const { status, value, message } = response.data ?? {};
  return {
    status,
    message,
    data: value ? new Nf(value) : {},
  };
};

export const getNotaPelaChave = async (filters) => {
  const response = await api.get(`${baseURL}/BuscaNotaPelaChave`, {
    params: filters,
  });

  const { status, message, value } = response.data;

  return {
    status,
    message,
    data: value ? new Nf(value) : {},
  };
};

export const ImportNfs = async (filters) => {
  const response = await api.get(`${baseURL}/ImportNfs`, {
    params: filters,
  });

  const { status, message, value } = response.data;

  return {
    status,
    message,
    data: value ? new Nf(value) : {},
  };
};

// PARTE DO ZIP
export const ImportNfsViaZip = async (file) => {
  const formData = new FormData();
  formData.append('file', file.file);
  formData.append('nrSeqEmpresa', file.nrSeqEmpresa);
  formData.append('nrSeqNftipo', file.nrSeqNftipo);
  formData.append('nrSeqProduto', file.nrSeqProduto);

  const response = await api.post(`${baseURL}/ImportNfsViaZip`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data ?? {};
};

export const effect = async (nf) => {
  const response = await api.post(`${baseURL}/Effect`, nf);
  const { status, message, value } = response.data;
  return {
    status,
    message,
    data: value ? new Nf(value) : {},
  };
};

export const cancel = async (nf) => {
  const response = await api.post(`${baseURL}/Cancel`, nf);
  const { status, message, value } = response.data;
  return {
    status,
    message,
    data: value ? new Nf(value) : {},
  };
};

export const RatearPlanoDeContaAutomaticoNf = async (data) => {
  const response = await api.post(
    `${baseURL}/RatearPlanoDeContaAutomaticoNf`,
    data
  );
  return response.data ?? {};
};

export const verificaUltimoProduto = async (filters) => {
  const response = await api.get(`${baseURL}/VerificaUltimoProduto`, {
    params: filters,
  });
  const { status, message } = response.data;

  return {
    status,
    message,
  };
};

export const deleteNf = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printNf = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const excelNf = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllNf = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const onImportFiles = async (files) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i += 1) {
    formData.append('files', files[i]);
    formData.append('extension', MimeTypes.getExtension(files[i].type));
  }

  const response = await api.post(`${baseURL}/ImportXMLs`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const { value, status } = response.data;

  return {
    status,
    data: value,
  };
};

export const postImportNf = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('extension', MimeTypes.getExtension(file.type));

  const response = await api.post(`${baseURL}/ImportNf`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    nota: new Nf(value) ?? {},
  };
};

export const getImportNfeImportada = async (id) => {
  const response = await api.get(`${baseURL}/ImportNfeImportada`, {
    params: { id },
  });

  const { status, message, value } = response.data ?? {};

  return {
    message,
    status,
    data: value ? new Nf(value) : {},
  };
};

export const generateTituloFrete = async (data) => {
  const response = await api.post(`${baseURL}/GerarTituloFrete`, data);
  const { status, message, value } = response.data;

  return {
    status,
    message,
    data: new Nf(value ?? {}),
  };
};

export const certificateValidation = async () => {
  const response = await api.get(`${baseURL}/CertificateValidation`);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};
