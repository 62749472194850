import React from 'react';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import {
  Autocomplete,
  Textbox,
  DatePicker,
  GridView,
  Button,
  Panel,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN';
import ManutencaoVeiculoFatura from 'core/models/FRO/manutencaoVeiculo_Fatura';

const ManutencaoInfosFaturas = ({
  setLoading,
  onSetMessage,
  prevDataRef,
  data,
  setData,
  dataFatura,
  setDataFatura,
  gridViewFaturas,
}) => {
  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
    });

    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const onAdicionaFatura = async () => {
    if (
      dataFatura.dtVencimento != null &&
      dataFatura.vlrParcela != null &&
      dataFatura.nrSeqFormaPagamento != null
    ) {
      setLoading(true);

      const faturas = [...data.faturas];

      const obj = dataFatura;
      const fatura = new ManutencaoVeiculoFatura({
        ...obj,
        status: !dataFatura.status ? GridView.EnumStatus.Inserir : obj.status,
        nrSeq: !obj.nrSeq ? faturas.length + 1 : obj.nrSeq,
      });
      faturas.push(fatura);

      if (gridViewFaturas && gridViewFaturas.current) {
        gridViewFaturas.current.setDataSource(faturas);
      }

      const dataArray = prevDataRef.current;
      setData({ ...dataArray, faturas });
      setDataFatura({});

      setLoading(false);
    }
  };

  const onClickEditFatura = async (selectedValue, datasource) => {
    const obj = datasource.find((item) => item === selectedValue);

    if (!obj.status) {
      obj.status = GridView.EnumStatus.Alterar;
    }

    const faturas = datasource.filter((fatura) => fatura !== selectedValue);

    if (gridViewFaturas && gridViewFaturas.current) {
      gridViewFaturas.current.setDataSource(faturas);
    }

    const dataArray = prevDataRef.current;
    setDataFatura({ ...obj });
    setData({ ...dataArray, faturas });
  };

  const onClickRemoveFatura = async (selectedValue, datasource) => {
    const itemRemover = datasource.find((item) => item === selectedValue);
    itemRemover.status = GridView.EnumStatus.Remover;

    const faturas = datasource.filter((item) => item !== selectedValue);

    if (gridViewFaturas && gridViewFaturas.current) {
      gridViewFaturas.current.setDataSource(faturas);
    }

    const dataArray = prevDataRef.current;
    setData({ ...dataArray });
  };

  const columnsFaturasOnNew = [
    { key: 'nrSeq', title: 'Sequência' },
    { key: 'vlrParcela', title: 'Valor', format: GridView.DataTypes.Money },
    { key: 'formaPagamento.noFormaPagamento', title: 'Forma de Pagamento' },
    {
      key: 'dtVencimento',
      title: 'Data de vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrSeq',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditFatura(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgExibeBtn',
    },
    {
      key: 'nrSeq',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveFatura(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgExibeBtn',
    },
  ];

  return (
    <>
      {data?.pessoaOfi?.nrSeqPessoaOfi && !data?.pessoaOfi?.flgControlaEstoque && (
        <Panel className='mb-3'>
          <Panel.Header title='Faturas' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row'>
              <div className='col-3 mb-3'>
                <Textbox
                  label='Valor da Parcela'
                  readOnly={data?.flgStatus === 'Concluído'}
                  maxLength={20}
                  mask={MaskTypes.Currency}
                  text={dataFatura.vlrParcela}
                  onChangedValue={(vlrParcela) =>
                    setDataFatura({
                      ...dataFatura,
                      vlrParcela,
                    })
                  }
                />
              </div>

              <div className='col mb-3'>
                <Autocomplete
                  label='Forma de Pagamento'
                  readOnly={data?.flgStatus === 'Concluído'}
                  searchDataSource={onSearchFormaPagamento}
                  selectedItem={dataFatura.formaPagamento}
                  onSelectItem={(formaPagamento) => {
                    setDataFatura({
                      ...dataFatura,
                      formaPagamento,
                      nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                    });
                  }}
                  dataSourceTextProperty='noFormaPagamento'
                />
              </div>

              <div className='col-2 mb-2'>
                <DatePicker
                  label='Data de Vencimento'
                  readOnly={data?.flgStatus === 'Concluído'}
                  text={dataFatura.dtVencimento}
                  mask={MaskTypes.Date}
                  onChange={(dtVencimento) =>
                    setDataFatura({
                      ...dataFatura,
                      dtVencimento,
                    })
                  }
                />
              </div>

              <div className='col-2 btn-group mr-2'>
                <Button
                  text='Adicionar'
                  className='mb-3 mt-4'
                  icon='plus'
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  template={Button.Templates.Default}
                  onClick={onAdicionaFatura}
                  outline
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <GridView
                  ref={gridViewFaturas}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsFaturasOnNew}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      )}
    </>
  );
};

export default ManutencaoInfosFaturas;
