export default class SolicitanteCompra {
  constructor(jsonObject = {}) {
    this.cdSolicitante = jsonObject.cdSolicitante;
    this.flgAtivo = jsonObject.flgAtivo;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqPessoaSol = jsonObject.nrSeqPessoaSol;
    this.noPessoa = jsonObject.noPessoa;
  }

  toJson = () => JSON.stringify(this);
}
