import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Textbox, DatePicker } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getPerfilDeAcesso,
  savePerfilDeAcesso,
  deletePerfilDeAcesso,
} from 'core/services/SEG/perfilDeAcesso';

export default function PerfilDeAcessoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  // const onSetMessage = (status, msg) => {
  //   if (msg)
  //     setMessage({
  //       message: msg,
  //       theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
  //     });
  // };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getPerfilDeAcesso(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await savePerfilDeAcesso(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deletePerfilDeAcesso(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Perfil de Acesso'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqPerfilDeAcesso > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-2 mb-4'>
          <DatePicker
            label='Data Inicial'
            readOnly
            text={data.dtHoraInicial}
            mask={MaskTypes.Date}
            onChange={(dtHoraInicial) => setData({ ...data, dtHoraInicial })}
          />
        </div>

        <div className='col-2 mb-4'>
          <DatePicker
            label='Data Final'
            text={data.dtHoraFinal}
            readOnly
            mask={MaskTypes.Date}
            onChange={(dtHoraFinal) => setData({ ...data, dtHoraFinal })}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-6 mb-4'>
          <Textbox
            maxLength={50}
            label='Nome do Perfil'
            text={data.noPerfilDeAcesso}
            onChangedValue={(noPerfilDeAcesso) =>
              setData({ ...data, noPerfilDeAcesso })
            }
          />
        </div>

        <div className='col-2 mb-4'>
          <Textbox
            label='Numero do dia da semana'
            maxLength={15}
            text={data.nrDayOfWeek}
            mask={MaskTypes.Integer}
            onChangedValue={(nrDayOfWeek) => setData({ ...data, nrDayOfWeek })}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
