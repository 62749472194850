export default class TituloEspeciePlanoDeConta {
  constructor(jsonObject = {}) {
    this.flgPercentual = jsonObject.flgPercentual;
    this.vlrPercentual = jsonObject.vlrPercentual;
    this.nrSeqTituloEspeciePlanoDeConta =
      jsonObject.nrSeqTituloEspeciePlanoDeConta;
    this.nrSeqTituloEspecie = jsonObject.nrSeqTituloEspecie;
    this.nrSeqPlanoDeContaCred = jsonObject.nrSeqPlanoDeContaCred;
    this.nrSeqPlanoDeContaDeb = jsonObject.nrSeqPlanoDeContaDeb;
    this.planoDeContaDeb = jsonObject.planoDeContaDeb ?? undefined;
    this.planoDeContaCre = jsonObject.planoDeContaCre ?? undefined;
  }

  toJson = () => JSON.stringify(this);
}
