export default class ContratoAgrupado {
  constructor(jsonObject = {}) {
    this.nrSeqContratoAgrupado = jsonObject.nrSeqContratoAgrupado;
    this.cdContratoAgrupado = jsonObject.cdContratoAgrupado;
    this.noContratoAgrupado = jsonObject.noContratoAgrupado;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.noCompetencia = jsonObject.noCompetencia;
  }

  toJson = () => JSON.stringify(this);
}
