import React, { useState, useRef, useEffect } from 'react';
import { Modal, Steps, Loader, Notification } from 'ui/components';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/enums';
import { Message } from 'ui/Helpers/interfaces';

import { detalhamentoReceitasDespesas } from 'core/services/FIN/dashboardFinanceiro';

import StepReceitasDespesas from 'ui/pages/FIN/DashboardFinanceiro/stepReceitasDespesas';

interface Props {
  show: boolean;
  onClose: () => void;
  filters: any;
}

export default function ModalDetalhesReceitasDespesas({
  show,
  onClose,
  filters,
}: Props) {
  const [message, setMessage] = useState<Message | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const stepReceitasDespesas = useRef();

  const load = async () => {
    setLoading(true);

    if (show) {
      const {
        status,
        message: msg,
        data: retorno,
      } = await detalhamentoReceitasDespesas(filters);

      if (msg)
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });

      if (status === ResponseStatus.Success) {
        setData({
          receitas: retorno.receitas,
          despesas: retorno.despesas,
        });
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    load();
  }, [show]);

  const onSend = (): void => {
    setData({
      receitas: [],
      despesas: [],
    });

    onClose();
  };

  const receitas = () => (
    <StepReceitasDespesas receita dataSource={data.receitas} />
  );
  const despesas = () => (
    <StepReceitasDespesas receita={false} dataSource={data.despesas} />
  );

  const stepReceitas = React.createElement(receitas);
  const stepDespesas = React.createElement(despesas);

  const steps = [
    {
      index: 1,
      title: 'Receitas',
      visible: true,
      content: () => stepReceitas,
    },
    {
      index: 2,
      title: 'Despesas',
      visible: true,
      content: () => stepDespesas,
    },
  ];

  return (
    // @ts-expect-error
    <Modal
      show={show}
      title='Detalhes Receitas e Despesas'
      icon='list'
      size={BootstrapSizes.ExtraLarge}
      onClose={() => onSend()}
    >
      {/* @ts-expect-error */}
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='m-3'>
            {/* @ts-expect-error */}
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        {data.receitas &&
          data.receitas?.length > 0 &&
          data.despesas &&
          data.despesas?.length > 0 && (
            <Steps
              propriedade={1}
              steps={steps}
              theme={Theme.Primary}
              align='horizontal'
              ref={stepReceitasDespesas}
            />
          )}
      </Modal.Body>
    </Modal>
  );
}
