class ContaDetalhada {
  constructor(jsonObject = {}) {
    this.nrSeqContaRecurso = jsonObject.nrSeqContaRecurso;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.nrContrato = jsonObject.nrContrato;
    this.nrLinhaCompleto = jsonObject.nrLinhaCompleto;
    this.noPessoa = jsonObject.noPessoa;
    this.cdCliente02 = jsonObject.cdCliente02;
    this.noCentroDeCusto = jsonObject.noCentroDeCusto;
    this.periodo = jsonObject.periodo;
    this.noPlano = jsonObject.noPlano;
    this.noPlanoRateioEmpresa = jsonObject.noPlanoRateioEmpresa;
    this.noFormaPagamento = jsonObject.noFormaPagamento;

    this.vlrTotal = jsonObject.vlrTotal;
    this.vlrRateioEmpresa = jsonObject.vlrRateioEmpresa;
    this.vlrRecalculado = jsonObject.vlrRecalculado;

    this.qtdeTotalVoz = jsonObject.qtdeTotalVoz;
    this.qtdeTotalDados = jsonObject.qtdeTotalDados;
    this.qtdeTotalSms = jsonObject.qtdeTotalSms;

    this.noStatusFinanceiro = jsonObject.noStatusFinanceiro;
    this.dtVencimento = jsonObject.dtVencimento;
    this.noColor = jsonObject.noColor;
    this.ocorrencias = jsonObject.ocorrencias;
  }

  toJson = () => JSON.stringify(this);
}
export default ContaDetalhada;
