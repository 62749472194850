import React, { useEffect, useState, useRef } from 'react';
import { Theme } from 'ui/Helpers/enums';
import { BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';
import { Modal, Notification, Button, GridView } from 'ui/components';
// @ts-expect-error
import { GridviewColumns } from 'ui/Helpers/interfaces.ts';
import { getAbastecimentoList } from 'core/services/FRO/abastecimento';
import { getVeiculoRodagemList } from 'core/services/FRO/veiculoRodagem';

interface IProps {
  setLoading: any;
  modalShow: {
    show: boolean;
    veiculo: any;
    tipoHistorico: string;
  };
  onClose: () => void;
}

export default function ModalVeiculosRodagens({
  setLoading,
  modalShow,
  onClose,
}: IProps) {
  const gridViewAbastecimento = useRef<any | null>(null);
  const gridViewRodagem = useRef<any | null>(null);

  const [message, setMessage] = useState<any>({});
  const [data, setData] = useState<any>({
    flgAbastecimento: true,
    flgRodagem: true,
  });

  const onSetMessage = (status: number, msg: string) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const formataDadosHistorico = (historico: any[]): any[] => {
    const historicoFormatado: any[] = [];

    if (!modalShow.veiculo.flgPrincipal) {
      historico.forEach((item: any) => {
        if (item.nrOdometroFinal === null) {
          item.nrOdometroFinal = '';
          item.nrOdometroAcumulado = '';
        } else {
          item.nrOdometroAcumulado =
            item.nrOdometroFinal - item.nrOdometroInicial;
        }

        if (item.dtFim === null) {
          item.statusRodagem = 'Rodando';
        } else {
          item.statusRodagem = 'Finalizada';
        }

        historicoFormatado.push(item);
      });
    } else {
      historico.forEach((item: any) => historicoFormatado.push(item));
    }

    return historicoFormatado;
  };

  const load = async () => {
    setLoading(true);

    let historico = [];

    const response =
      modalShow.veiculo.flgPrincipal === true
        ? await getAbastecimentoList({
            nrSeqVeiculo: modalShow.veiculo.nrSeqVeiculo,
          })
        : await getVeiculoRodagemList({
            nrSeqVeiculo: modalShow.veiculo.nrSeqVeiculo,
          });

    if (response.status === ResponseStatus.Error) {
      onSetMessage(response.status, response.status);
    } else {
      historico = response.data;
    }

    const historicoFormatado = formataDadosHistorico(historico);

    if (
      modalShow.veiculo.flgPrincipal &&
      gridViewAbastecimento &&
      gridViewAbastecimento.current
    ) {
      setData({ flgAbastecimento: true, flgRodagem: false });
      gridViewAbastecimento.current.setDataSource(historicoFormatado);
    }

    if (
      !modalShow.veiculo.flgPrincipal &&
      gridViewRodagem &&
      gridViewRodagem.current
    ) {
      setData({ flgAbastecimento: false, flgRodagem: true });
      gridViewRodagem.current.setDataSource(historicoFormatado);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!isNaN(modalShow?.veiculo?.nrSeqVeiculo)) {
      load();
    }
  }, [modalShow.show]);

  const columnAbastecimento: GridviewColumns[] = [
    {
      key: 'dtAbastecimento',
      title: 'Data Abastecimento',
      // @ts-expect-error
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrOdometro',
      title: 'Odômetro',
    },
    {
      key: 'noPosto',
      title: 'Posto',
    },
    {
      key: 'quilometragemPercorrida',
      title: 'Odômetro Acumulado',
      // @ts-expect-error
      format: GridView.DataTypes.Integer,
    },
  ];

  const columnRodagem: GridviewColumns[] = [
    {
      key: 'veiculoVinc.placa',
      title: 'Vinculada à Placa',
    },
    {
      key: 'dtInicio',
      title: 'Data Início',
      // @ts-expect-error
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrOdometroInicial',
      title: 'Odômetro Inicial',
    },
    {
      key: 'dtFim',
      title: 'Data Final',
      // @ts-expect-error
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrOdometroFinal',
      title: 'Odômetro Final',
    },
    {
      key: 'nrOdometroAcumulado',
      title: 'Odômetro Acumulado',
    },
    {
      key: 'statusRodagem',
      title: 'Status',
    },
  ];

  return (
    //  @ts-expect-error
    <Modal
      show={modalShow.show}
      title={`Histórico de ${modalShow?.tipoHistorico} do veíclo placa ${modalShow?.veiculo?.placa}`}
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      {/* @ts-expect-error */}
      <Modal.Body>
        {message && (
          <>
            <div className='row mb-3'>
              <div className='col'>
                {/* @ts-expect-error */}
                <Notification
                  message={message.message}
                  theme={message.theme}
                  onClose={() => setMessage(null)}
                />
              </div>
            </div>
          </>
        )}

        {data?.flgAbastecimento && (
          <div className='row mb-3'>
            <GridView
              ref={gridViewAbastecimento}
              //  @ts-expect-error
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnAbastecimento}
              onPageSizeChange={false}
              showSelectSizes={false}
              showPagination={false}
            />
          </div>
        )}

        {data?.flgRodagem && (
          <div className='row mb-3'>
            <GridView
              ref={gridViewRodagem}
              //  @ts-expect-error
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnRodagem}
              onPageSizeChange={false}
              showSelectSizes={false}
              showPagination={false}
            />
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button text='Fechar' theme={Theme.Danger} onClick={onClose} />
      </Modal.Footer>
    </Modal>
  );
}
