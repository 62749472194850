import ContaCorrente from './contaCorrente';
import ExtratoCategoria from './extratoCategoria';
import PlanoDeConta from './planoDeConta';

export default class ContaCorrenteTransferencia {
  constructor(jsonObject = {}) {
    this.nrSeqContaCorrenteTransferenci =
      jsonObject.nrSeqContaCorrenteTransferenci;
    this.cdContaCorrenteTransferencia = jsonObject.cdContaCorrenteTransferencia;
    this.noContaCorrenteTransferencia = jsonObject.noContaCorrenteTransferencia;
    this.nrSeqContaCorrenteDest = jsonObject.nrSeqContaCorrenteDest;
    this.nrSeqContaCorrenteOri = jsonObject.nrSeqContaCorrenteOri;
    this.nrSeqExtratoCategoria = jsonObject.nrSeqExtratoCategoria;
    this.nrSeqPlanoContaCred = jsonObject.nrSeqPlanoContaCred;
    this.nrSeqPlanoContaDeb = jsonObject.nrSeqPlanoContaDeb;
    this.contaCorrenteOri = new ContaCorrente(
      jsonObject.contaCorrenteOri ?? {}
    );
    this.contaCorrenteDest = new ContaCorrente(
      jsonObject.contaCorrenteDest ?? {}
    );
    this.extratoCategoria = new ExtratoCategoria(
      jsonObject.extratoCategoria ?? {}
    );
    this.planoDeContaDeb = new PlanoDeConta(jsonObject.planoDeContaDeb ?? {});
    this.planoDeContaCred = new PlanoDeConta(jsonObject.planoDeContaCred ?? {});
    /*  this.noPlanoContaCred = jsonObject.noPlanoDeContaCred;
    this.noExtratoCategoria = jsonObject.noExtratoCategoria;
    this.noContaCorrenteOri = jsonObject.noContaCorrenteOri;
    this.noContaCorrenteDest = jsonObject.noContaCorrenteDest; 

    this.contaCorrenteDest = jsonObject.contaCorrenteDest;
    this.contaCorrenteOri = jsonObject.contaCorrenteOri; */
  }

  toJson = () => JSON.stringify(this);
}
