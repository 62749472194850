import { PacoteSms } from 'core/models/TEL';
import api from '../api';

const getPacoteSmsAutoComplete = async (filters) => {
  const response = await api.get(`PacoteSms/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    pacotes: value?.map((json) => new PacoteSms(json)) ?? [],
  };
};

export { getPacoteSmsAutoComplete };
