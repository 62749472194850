import React, { useCallback, useEffect, useRef, useState } from 'react';
import ChamadoClassificacao from 'core/models/HELP/chamadoClassificacao';
import {
  deleteChamadoClassificacao,
  getChamadoClassificacao,
  saveChamadoClassificacao,
} from 'core/services/HELP/chamadoClassificacao';
import { ResponseStatus, Theme } from 'ui/Helpers/enums';
import { Message, Page } from 'ui/Helpers/interfaces';
import { PageTypes } from 'ui/Helpers/utils';
import { CSDManutPage, Textbox } from 'ui/components';

export default function ChamadoClassificacaoItem({
  registryKey,
  reload,
  isActive,
  onSelectPage,
  transaction,
}: Page) {
  const formSubmit: any = useRef();

  const [chamadoClassificacao, setChamadoClassificacao] = useState<
    ChamadoClassificacao | {}
  >({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const load = useCallback(async (): Promise<void> => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const obj = await getChamadoClassificacao(registryKey);

      setChamadoClassificacao(obj);

      setLoading(false);
    } else {
      setChamadoClassificacao({});
    }
  }, [registryKey]);

  useEffect(() => {
    load();
  }, [load, registryKey, reload]);

  const save = async (): Promise<void> => {
    setLoading(true);

    const { status, message: msg } = await saveChamadoClassificacao(
      chamadoClassificacao
    );

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
    }

    setChamadoClassificacao(
      status === ResponseStatus.Success ? {} : chamadoClassificacao
    );

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onExcluir = async (): Promise<void> => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const { status, message: msg } = await deleteChamadoClassificacao(
        registryKey
      );

      setChamadoClassificacao(
        status === ResponseStatus.Success ? {} : chamadoClassificacao
      );

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;

  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Classificação do Chamado'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setChamadoClassificacao({})}
      onSave={save}
      onDelete={
        'nrSeqChamadoClassificacao' in chamadoClassificacao &&
        chamadoClassificacao?.nrSeqChamadoClassificacao > 0
          ? onExcluir
          : null
      }
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {/* @ts-expect-error */}
      <Textbox
        label='Classificação do Chamado'
        maxLength={150}
        required
        text={
          'noChamadoClassificacao' in chamadoClassificacao
            ? chamadoClassificacao.noChamadoClassificacao
            : null
        }
        onChangedValue={(noChamadoClassificacao: string) =>
          setChamadoClassificacao({
            ...chamadoClassificacao,
            noChamadoClassificacao,
          })
        }
      />
    </CSDManutPage>
  );
}
