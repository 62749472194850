import Estado from '../SEG/estado';
import Pais from '../SEG/pais';

export default class TipoEmbarqueEstado {
  constructor(jsonObject = {}) {
    this.vlrTonelada = jsonObject.vlrTonelada;
    this.nrSeqTipoEmbarque = jsonObject.nrSeqTipoEmbarque;
    this.nrSeqEstado = jsonObject.nrSeqEstado;
    this.estado = new Estado(jsonObject.estado ?? {});
    this.pais = new Pais(jsonObject.pais ?? {});
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
