import qs from 'qs';
import Pagination from 'core/models/pagination';
import Pessoa from 'core/models/SEG/pessoa';
import api from '../api';

const baseURL = `/SEG/Pessoa`;

export const getInformationSefaz = async (uf, document) => {
  const response = await api.get(`${baseURL}/GetInformationSefaz`, {
    params: { uf, document },
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    value: new Pessoa(value),
  };
};

export const getPessoaList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Pessoa(json)) ?? [],
  };
};

export const getPessoaAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: new Pessoa(value),
  };
};

export const getPessoaAutoCompleteRemDesCli = async (filters) => {
  const response = await api.get(`${baseURL}/SearchAutoCompleteRemDesCli`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    data: value?.map((json) => new Pessoa(json)) ?? [],
  };
};

export const getPessoaAutoCompleteFornecedorECliente = async (filters) => {
  const response = await api.get(
    `${baseURL}/SearchAutoCompleteFornecedorECliente`,
    {
      params: filters,
    }
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Pessoa(json)) ?? [],
  };
};

export const getPessoaAutoCompleteTipoNome = async (filters) => {
  const response = await api.get(`${baseURL}/SearchAutoCompleteTipoNome`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Pessoa(json)) ?? [],
  };
};

export const getPessoaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Pessoa(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPessoa = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Pessoa(value) : {};
};

export const getWithImage = async (id) => {
  const response = await api.get(`${baseURL}/GetWithImage`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Pessoa(value) : {};
};

export const getInfoPessoaByDoc = async (pessoa) => {
  const response = await api.get(
    `${baseURL}/SearchInformationByDocumentPerson`,
    {
      params: pessoa,
    }
  );

  const { status, message, value } = response.data;
  return {
    status,
    message,
    data: value ? new Pessoa(value) : {},
  };
};

export const savePessoa = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const saveAllPersonInformation = async (data) => {
  const response = await api.post(`${baseURL}/SaveAllPersonInformation`, data);
  return response.data ?? {};
};

export const deletePessoa = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPessoa = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPessoa = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPessoa = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getCNPJInformation = async (cnpj) => {
  const response = await api.get(`${baseURL}/GetPessoaReceitaWS`, {
    params: { cnpj },
  });

  const { value, message, status } = response.data ?? {};
  return {
    message,
    status,
    data: value ? new Pessoa(value) : {},
  };
};

export const checkDocumentIsValid = async (filters) => {
  const response = await api.get(`${baseURL}/VerifyThatTheDocumentIsValid`, {
    params: filters,
  });
  return response.data ?? {};
};
