import ManutencaoPreventiva from '../EST/manutencaoPreventiva';
import TipoIntervalo from './tipoIntervalo';
// import Veiculo from './veiculo';

export default class VeiculoManutencaoPreventiva {
  constructor(jsonObject = {}) {
    this.nrSeqVeiculo_ManutencaoPrevent =
      jsonObject.nrSeqVeiculo_ManutencaoPrevent;
    this.nrSeqManutencaoPreventiva = jsonObject.nrSeqManutencaoPreventiva;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.flgAtivo = jsonObject.flgAtivo;
    this.dtMovimento = jsonObject.dtMovimento;
    this.nrOdometro = jsonObject.nrOdometro;
    this.nrSeqManutencaoVeiculo = jsonObject.nrSeqManutencaoVeiculo;
    this.flgModelo = jsonObject.flgModelo;
    this.nrSeqVeiculo_ManutencaoPreventiva =
      jsonObject.nrSeqVeiculo_ManutencaoPreventiva;
    this.manutencaoPreventiva = new ManutencaoPreventiva(
      jsonObject.manutencaoPreventiva ?? {}
    );

    // props auxiliares
    this.tipoIntervalo = new TipoIntervalo(jsonObject.tipoIntervalo ?? {});
    this.status = jsonObject.status;
    this.placa = jsonObject.placa;
    this.ultimoOdometroNoSistema = jsonObject.ultimoOdometroNoSistema;
    this.ultimaDataNoSistema = jsonObject.ultimaDataNoSistema;
    this.odometroRestanteManutencao = jsonObject.odometroRestanteManutencao;
    this.diasRestantesManutencao = jsonObject.diasRestantesManutencao;
    this.mediaDias = jsonObject.mediaDias;
    this.noColorPendencia = jsonObject.noColorPendencia;
    this.inicialGrid = jsonObject.inicialGrid;
    this.finalGrid = jsonObject.finalGrid;
    this.statusManutencaoPreventiva = jsonObject.statusManutencaoPreventiva;
    this.proximaManutencao = jsonObject.proximaManutencao;
    this.faltaKmManutencao = jsonObject.faltaKmManutencao;
    this.ultimaInfoManutencao = jsonObject.ultimaInfoManutencao;
    this.flgStatusManutencaoPreventiva =
      jsonObject.flgStatusManutencaoPreventiva;
  }

  toJson = () => JSON.stringify(this);
}
