import { TipoDespesa } from '../FIN';
import EstoqueTipo from '../EST/estoqueTipo';
import Oficina from '../FRO/oficina';

export default class NfTipo {
  constructor(jsonObject = {}) {
    this.nrSeqTipoDespesa = jsonObject.nrSeqTipoDespesa;
    this.cdNfTipo = jsonObject.cdNfTipo;
    this.noNfTipo = jsonObject.noNfTipo;
    this.nrSeqNfTipo = jsonObject.nrSeqNfTipo;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgGeraLoteEstoqueMovimento = jsonObject.flgGeraLoteEstoqueMovimento;
    this.nrSeqEstoqueTipo = jsonObject.nrSeqEstoqueTipo;
    this.flgObrigaFatura = jsonObject.flgObrigaFatura;
    this.modeloNf = jsonObject.modeloNf;
    this.especieNf = jsonObject.especieNf;
    this.serieNf = jsonObject.serieNf;
    this.nrSeqPessoaOfi = jsonObject.nrSeqPessoaOfi;
    this.flgGeraPendencia = jsonObject.flgGeraPendencia;
    this.flgManutecaoVeiculo = jsonObject.flgManutecaoVeiculo;
    this.flgExportaSped = jsonObject.flgExportaSped;
    this.flgAtivo = jsonObject.flgAtivo;
    this.flgVinculaOrcamento = jsonObject.flgVinculaOrcamento;
    this.nrSeqEstoqueTipoSaida = jsonObject.nrSeqEstoqueTipoSaida;
    this.flgCartaFreteAbastecimento = jsonObject.flgCartaFreteAbastecimento;
    this.flgRetemImpostos = jsonObject.flgRetemImpostos;
    this.flgConsTipoDespesaPedido = jsonObject.flgConsTipoDespesaPedido;
    this.tipoDespesa = new TipoDespesa(jsonObject.tipoDespesa ?? {});
    this.estoqueTipo = new EstoqueTipo(jsonObject.estoqueTipo ?? {});
    this.estoqueTipoSaida = new EstoqueTipo(jsonObject.estoqueTipoSaida ?? {});
    this.oficina = new Oficina(jsonObject.oficina ?? {});
  }

  toJson = () => JSON.stringify(this);
}
