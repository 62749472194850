export default class PneuVida {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqPneuVida = jsonObject.nrSeqPneuVida;
    this.cdPneuVida = jsonObject.cdPneuVida;
    this.noPneuVida = jsonObject.noPneuVida;
  }

  toJson = () => JSON.stringify(this);
}
