import ColetaTipoDocumento from 'core/models/FRO/coletaTipoDocumento';
import Estado from 'core/models/SEG/estado';

export default class ColetaEmbarqueDocumento {
  constructor(jsonObject = {}) {
    this.nrSeqColetaEmbarque = jsonObject.nrSeqColetaEmbarque;
    this.nrNotaFiscal = jsonObject.nrNotaFiscal;
    this.dtEmissao = jsonObject.dtEmissao;
    this.serie = jsonObject.serie;
    this.nrSeqDocumento = jsonObject.nrSeqDocumento;
    this.vlrNota = jsonObject.vlrNota;
    this.modelo = jsonObject.modelo;
    this.quantidade = jsonObject.quantidade;
    this.peso = jsonObject.peso;
    this.flgRemetente = jsonObject.flgRemetente;
    this.nrCnpj = jsonObject.nrCnpj;
    this.iEstadual = jsonObject.iEstadual;
    this.nrSeqEstadoIE = jsonObject.nrSeqEstadoIE;
    this.vlrIcms = jsonObject.vlrIcms;
    this.vbCst = jsonObject.vbCst;
    this.vlrCst = jsonObject.vlrCst;
    this.cfOp = jsonObject.cfOp;
    this.cdTipoDocumento = jsonObject.cdTipoDocumento;
    this.nrNfe = jsonObject.nrNfe;
    this.vbcIcms = jsonObject.vbcIcms;
    this.nrRemessa = jsonObject.nrRemessa;
    this.nrOrdemTransporte = jsonObject.nrOrdemTransporte;
    this.infoComplementar = jsonObject.infoComplementar;
    this.nrSeqNfeImportada = jsonObject.nrSeqNfeImportada;
    this.nrSeqNf = jsonObject.nrSeqNf;
    this.nrSeqFreteMercadoria = jsonObject.nrSeqFreteMercadoria;

    // #region Propriedades Auxiliares
    this.tipoDocumento = new ColetaTipoDocumento(
      jsonObject.tipoDocumento ?? {}
    );
    this.ufIe = new Estado(jsonObject.ufIe ?? {});
    this.produtosNfe = jsonObject.produtosNfe ?? [];
    this.chaveNfe = jsonObject.chaveNfe;

    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
