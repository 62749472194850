import qs from 'qs';
import Pagination from 'core/models/pagination';
import MarcaaparelhoCelular from 'core/models/TEL/marcaAparelhoCelular';
import api from '../api';

const baseURL = `/TEL/MarcaAparelhoCelular`;

export const getMarcaAparelhoCelularList = async (filters) => {
  const response = await api.get(`/$MarcaAparelhoCelular/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new MarcaaparelhoCelular(json)) ?? [],
  };
};

export const getMarcaAparelhoCelularPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new MarcaaparelhoCelular(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};
export const getMarcaAparelhoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new MarcaaparelhoCelular(json)) ?? [],
  };
};

export const getMarcaAparelhoCelular = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new MarcaaparelhoCelular(value) : {};
};

export const saveMarcaAparelhoCelular = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteMarcaAparelhoCelular = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printMarcaAparelhoCelular = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelMarcaAparelhoCelular = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllMarcaAparelhoCelular = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
