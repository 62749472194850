import FormaPagamento from 'core/models/FIN/formaPagamento';
import TipoDespesa from 'core/models/FIN/tipoDespesa';
import PostoCombustivelTanque from 'core/models/FRO/postoCombustivelTanque';
import PostoCombustivelTipoCombustiv from 'core/models/FRO/postoCombustivel_TipoCombustiv';
import PessoaDocumento from 'core/models/SEG/pessoaDocumento';
import Pessoa from 'core/models/SEG/pessoa';
import { Endereco } from 'core/models/SEG';

export default class PostoCombustivel extends Pessoa {
  constructor(jsonObject = {}) {
    super(jsonObject);
    this.flgContasPagar = jsonObject.flgContasPagar;
    this.nrSeqPessoaPos = jsonObject.nrSeqPessoaPos;
    this.flgAtivo = jsonObject.flgAtivo;
    this.cdPostoCombustivel = jsonObject.cdPostoCombustivel;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqTipoDespesa = jsonObject.nrSeqTipoDespesa;
    this.diaPagamento = jsonObject.diaPagamento;
    this.flgProprio = jsonObject.flgProprio;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.noPessoa = jsonObject.noPessoa;
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.tipoDespesa = new TipoDespesa(jsonObject.tipoDespesa ?? {});
    this.postoCombustivelDocumentos = jsonObject.postoCombustivelDocumentos
      ? jsonObject.postoCombustivelDocumentos.map(
          (item) => new PessoaDocumento(item ?? {})
        )
      : [];
    this.postoCombustivelTipoCombustiveis = jsonObject.postoCombustivelTipoCombustiveis
      ? jsonObject.postoCombustivelTipoCombustiveis.map(
          (item) => new PostoCombustivelTipoCombustiv(item ?? {})
        )
      : [];
    this.postoCombustivelTanques = jsonObject.postoCombustivelTanques
      ? jsonObject.postoCombustivelTanques.map(
          (item) => new PostoCombustivelTanque(item ?? {})
        )
      : [];
    this.endereco = new Endereco(jsonObject.endereco ?? {});
  }

  toJson = () => JSON.stringify(this);
}
