import React from 'react';
import { Button } from 'ui/components';
import { Theme } from 'ui/Helpers/utils';

const LinhasNaoRecuperadas = (props) => {
  const linhasNaoRecuperadas = props;
  const rows = linhasNaoRecuperadas.linhas.map((linha) => (
    <Button outline theme={Theme.Danger} text={linha} />
  ));
  return (
    <div>
      <div className='row mb-3'>
        <div className='col-3'>
          <span>
            Total de Linhas Não Recuperadas:{' '}
            {linhasNaoRecuperadas.linhas.length}
          </span>
        </div>
      </div>
      <div className='row mb-12' />
      <div className='d-flex flex-wrap bg-light'>{rows}</div>
    </div>
  );
};

export default LinhasNaoRecuperadas;
