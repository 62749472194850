import React, { useState, useRef, useEffect, useCallback } from 'react';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import {
  Button,
  GridView,
  Loader,
  Modal,
  Notification,
  Textbox,
} from 'ui/components';

import { MaskTypes } from 'ui/Helpers/masks';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { getNfItemList } from 'core/services/COP/nfItem';

const ModalItensEntrda = ({
  show,
  nrSeqProduto,
  nrSeqPocOrcamento,
  findTransaction,
  onOpenTransaction,
  onClose,
}) => {
  const gridViewProdutosEntrada = useRef();
  const [vlrTotal, setVlrTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);

  const load = useCallback(async () => {
    if (Number.isInteger(nrSeqPocOrcamento)) {
      setLoading(true);
      const { data: value } = await getNfItemList({
        nrSeqProduto,
        nrSeqPocOrcamento,
      });

      const totalEntrada = value.reduce((acc, item) => acc + item.vlrTotal, 0);
      setVlrTotal(totalEntrada);

      if (gridViewProdutosEntrada && gridViewProdutosEntrada.current)
        gridViewProdutosEntrada.current.setDataSource(value);
      setLoading(false);
    }
  }, [nrSeqPocOrcamento]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, nrSeqPocOrcamento]);

  const onOpenNf = (datasource) => {
    const transactionOpen = findTransaction(
      TransacationCodes.NotaFiscalEntrada
    );

    if (transactionOpen) {
      transactionOpen.registryKey = datasource.nrSeqNf;
      onOpenTransaction(transactionOpen, true);
    } else {
      setMessage({
        message: 'Não foi possível abrir a Nota Fiscal de Entrada.',
        theme: Theme.Danger,
      });
    }
  };

  const columnsProdutosEntrada = [
    { key: 'nrSeqProduto', title: 'nrSeqProduto', visible: false },
    { key: 'nf.nrForNf', title: 'Nota' },
    { key: 'produto.noProduto', title: 'Produto', colorText: 'corProduto' },
    {
      key: 'localArmazenagem.noAlmoxarifadoLocal',
      title: 'Almoxarifado',
      colorText: 'corProduto',
    },
    {
      key: 'vlrFrete',
      title: 'Frete',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
      decimalPlaces: 6,
    },
    {
      key: 'vlrDesc',
      title: 'Desconto',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
      decimalPlaces: 6,
    },
    {
      key: 'qtdeItNf',
      title: 'Quantidade',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
      decimalPlaces: 6,
    },
    {
      key: 'vlrUnit',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
      decimalPlaces: 6,
    },
    {
      key: 'vlrTotal',
      title: 'Total',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
    },
    {
      key: 'nrSeqNf',
      type: GridView.ColumnTypes.Button,
      onClick: (datasource) => onOpenNf(datasource),
      theme: Theme.Warning,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Importar',
      tooltipDirection: 'bottom',
      title: 'Abrir',
    },
  ];
  return (
    <Modal
      show={show}
      size={BootstrapSizes.ExtraLarge}
      title='Itens não agrupado'
      onClose={onClose}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mt-5'>
          <div className='col'>
            <GridView
              ref={gridViewProdutosEntrada}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnsProdutosEntrada}
              showSelectSizes={false}
              showPagination={false}
            />
          </div>
        </div>
        <div className='row mb-2 justify-content-end'>
          <div className='col-3 mb-1'>
            <Textbox
              label={
                <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                  Valor Total Diretamente à Obra
                </span>
              }
              text={vlrTotal}
              style={{ width: '100%' }}
              mask={MaskTypes.Currency}
              readOnly
            />
          </div>
        </div>
        <div className='row mt-5' />
      </Modal.Body>
      <Modal.Footer>
        <Button
          icon='ban'
          text='Fechar'
          theme={Theme.Danger}
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default ModalItensEntrda;
