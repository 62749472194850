import VeiculoMarca from './veiculoMarca';
import VeiculoModeloEixo from './veiculoModeloEixo';

export default class VeiculoModelo {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqVeiculoModelo = jsonObject.nrSeqVeiculoModelo;
    this.noVeiculoModelo = jsonObject.noVeiculoModelo;
    this.cdVeiculoModelo = jsonObject.cdVeiculoModelo;
    this.nrSeqVeiculoMarca = jsonObject.nrSeqVeiculoMarca;
    this.vlrTara = jsonObject.vlrTara;
    this.flgAtivo = jsonObject.flgAtivo;
    this.nrOrdem = jsonObject.nrOrdem;
    this.veiculoMarca = new VeiculoMarca(jsonObject.veiculoMarca ?? {});
    this.veiculoModeloEixo = new VeiculoModeloEixo(
      jsonObject.veiculoModeloEixo ?? {}
    );
    this.veiculoModeloEixoS = (jsonObject.veiculoModeloEixoS ?? []).map(
      (vmex) => new VeiculoModeloEixo(vmex)
    );
    this.noTipoOdometro = jsonObject.noTipoOdometro;
  }

  toJson = () => JSON.stringify(this);
}
