import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
  MimeTypes,
  toBase64,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  Switch,
  DatePicker,
  GridView,
  Panel,
  Button,
  ToolbarButtons,
  FileUpload,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getOrdemCompra,
  saveOrdemCompra,
  deleteOrdemCompra,
  printDetalhado,
} from 'core/services/COP/ordemCompra';
import { getProdutoAutoComplete } from 'core/services/EST/produto';
import { getNfItemByproduto } from 'core/services/COP/nfItem';
import { getBalanceByProductGeneral } from 'core/services/EST/almoxarifadoLocal';
import { getCompradorAutoComplete } from 'core/services/COP/comprador';
import { getSolicitanteCompraAutoComplete } from 'core/services/COP/solicitanteCompra';
import {
  getEmpresaAutoComplete,
  getEmpresaDefaultUsuario,
} from 'core/services/SEG/empresa';
import { PedidoCompraItem } from 'core/models/VEN';
import PedidoCompraDocumento from 'core/models/VEN/pedidoCompraDocumento';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getPedidoCompraTipoDespesaDefault } from 'core/services/VEN/pedidoCompra';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import ModalPedidoCompra from './modalPedidoCompra';

export default function OrdemCompraItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  onOpenReport,
  transaction,
  findTransaction,
  onOpenTransaction,
}) {
  const formSubmit = useRef();
  const gridViewOrdemItens = useRef();
  const gridViewDocumentos = useRef();

  const [data, setData] = useState({});
  const [ordemCompraProduto, setOrdemCompraProduto] = useState({});
  const [ordemCompraDoc, setOrdemCompraDoc] = useState({});
  const [importedFile, setImportedFile] = useState([]);
  const [dataPedidoCompra, setDataPedidoCompra] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const EnumStatusOrcamentoCompra = Object.freeze({
    Cancelado: 'Cancelado',
    Pendente: 'Pendente',
    Efetivado: 'Efetivado',
    Parcial: 'Parcialmente Efetivado',
  });

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  function dataAtualFormatada() {
    const dataAtual = new Date();
    const dia = dataAtual.getDate().toString().padStart(2, '0');
    const mes = (dataAtual.getMonth() + 1).toString().padStart(2, '0');
    const ano = dataAtual.getFullYear();
    return `${ano}-${mes}-${dia}`;
  }

  const onNew = async () => {
    setLoading(true);

    const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;

    const { data: solicitante } = await getSolicitanteCompraAutoComplete({
      noPessoa: sessionUser.noPessoa,
    });

    const pessoasol =
      solicitante && solicitante.length > 0
        ? solicitante.find((s) => s.noPessoa === sessionUser.noPessoa)
        : null;
    const { empresas } = await getEmpresaDefaultUsuario({});

    setData({
      empresa: empresas[0],
      nrSeqEmpresa: empresas[0].nrSeqEmpresa,
      pessoaSol: pessoasol || {},
      nrSeqPessoaSol: pessoasol ? pessoasol.nrSeqPessoaSol : null,
      dtPedido: dataAtualFormatada(),
    });

    setOrdemCompraProduto({ produto: null });
    setOrdemCompraDoc({});
    setMessage(null);

    if (gridViewOrdemItens && gridViewOrdemItens.current)
      gridViewOrdemItens.current.setDataSource([]);

    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource([]);

    setLoading(false);
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const dataOrdemCompra = await getOrdemCompra(registryKey);

      if (gridViewOrdemItens && gridViewOrdemItens.current)
        gridViewOrdemItens.current.setDataSource(dataOrdemCompra.ordensCompra);

      setData(dataOrdemCompra);
      setOrdemCompraProduto({ produto: null });
      setOrdemCompraDoc({});

      if (dataOrdemCompra.documentos && dataOrdemCompra.documentos.length > 0) {
        if (gridViewDocumentos && gridViewDocumentos.current)
          gridViewDocumentos.current.setDataSource(dataOrdemCompra.documentos);
      } else {
        gridViewDocumentos?.current?.setDataSource([]);
      }

      setLoading(false);
    } else onNew();
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);

    const itensSalvar = gridViewOrdemItens.current.getDataSource();
    const documentosSalvar = gridViewDocumentos.current.getDataSource() || [];

    const objSave = {
      ...data,
      ordensCompra: itensSalvar,
      documentos: documentosSalvar,
    };

    const { status, message: msg } = await saveOrdemCompra(objSave);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      setData({});
      setOrdemCompraProduto({ produto: null });
      gridViewOrdemItens.current.setDataSource([]);
      gridViewDocumentos.current.setDataSource([]);
    }

    onSetMessage(status, msg);
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteOrdemCompra(registryKey);

      if (status === ResponseStatus.Success) {
        setData({});
        setOrdemCompraProduto({ produto: null });
        gridViewOrdemItens.current.setDataSource([]);
      }
      await onNew();
      onSetMessage(status, msg);
      setLoading(false);
    }
  };

  const onPrint = async () => {
    if (registryKey) {
      setLoading(true);

      const { value } = await printDetalhado({ nrSeqOrdemCompra: registryKey });

      onOpenReport(transaction.noTransacao, value);
      setLoading(false);
    }
  };

  const onSearchComprador = async (e) => {
    const {
      status,
      message: msg,
      data: pessoas,
    } = await getCompradorAutoComplete({
      noPessoa: e,
      nrSeqEmpresa: data.nrSeqEmpresa || null,
    });

    if (msg) onSetMessage(status, msg);
    return pessoas;
  };

  const onSearchSolicitante = async (e) => {
    const {
      status,
      message: msg,
      data: pessoas,
    } = await getSolicitanteCompraAutoComplete({
      noPessoa: e,
      nrSeqEmpresa: data.nrSeqEmpresa || null,
    });
    if (msg) onSetMessage(status, msg);
    return pessoas;
  };

  const onSearchUnidade = async (e) => {
    const {
      status,
      message: msg,
      empresas: empresa,
    } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresa;
  };

  const onAutoCompleteVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onSearchProduto = async (e) => {
    const {
      status,
      message: msg,
      data: produtos,
    } = await getProdutoAutoComplete({
      noSearchField: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const onSearchInfoCompleteProduto = async (produto) => {
    setLoading(true);
    const infoProduto = await getNfItemByproduto(produto.nrSeqProduto);

    const saldoalmox = await getBalanceByProductGeneral(produto.nrSeqProduto);

    setOrdemCompraProduto({
      ...ordemCompraProduto,
      nrSeqProduto: produto.nrSeqProduto,
      produto,
      vlrUnitario: infoProduto.vlrUnit,
      saldoProduto: saldoalmox,
    });

    setLoading(false);
  };

  const onAddOrdemCompraProduto = async () => {
    const dataProdutosOrdemCompra =
      gridViewOrdemItens.current.getDataSource() || [];

    if (!ordemCompraProduto.produto.nrSeqProduto) {
      onSetMessage(ResponseStatus.Error, 'Necessário informar o produto.');
      return;
    }

    if (!ordemCompraProduto.quantidade) {
      onSetMessage(ResponseStatus.Error, 'Necessário informar a quantidade.');
      return;
    }
    //  validação se produto já existe na solicitação
    const produtosRepetidos = dataProdutosOrdemCompra.filter(
      (x) => x.nrSeqProduto === ordemCompraProduto.nrSeqProduto
    );

    if (produtosRepetidos.length > 0) {
      if (
        produtosRepetidos.find((x) => x.nrSeqVeiculo === null) &&
        !ordemCompraProduto.nrSeqVeiculo
      ) {
        onSetMessage(
          ResponseStatus.Error,
          'Produto já adicionado à solicitação de compra para estoque. Associe o produto a um veículo para poder adicioná-lo.'
        );
        return;
      }

      if (
        produtosRepetidos.find(
          (x) => x.nrSeqVeiculo === ordemCompraProduto.nrSeqVeiculo
        )
      ) {
        onSetMessage(
          ResponseStatus.Error,
          'Produto já adicionado à solicitação de compra com vínculo a mesmo veículo.'
        );
        return;
      }
    }

    if (ordemCompraProduto.status !== GridView.EnumStatus.Alterar) {
      ordemCompraProduto.status = GridView.EnumStatus.Inserir;
    } else if (
      ordemCompraProduto.documentos &&
      ordemCompraProduto.documentos.length > 0
    ) {
      const listaGridDocs = gridViewDocumentos?.current?.getDataSource();

      ordemCompraProduto.documentos.forEach((doc) => {
        const indexDoc = listaGridDocs.indexOf(doc);

        doc.status =
          doc.nrSeqOrdemCompraDocumento !== null
            ? GridView.EnumStatus.Alterar
            : GridView.EnumStatus.Inserir;
        doc.nrSeqProduto = ordemCompraProduto.nrSeqProduto;
        doc.produto = ordemCompraProduto.produto;

        listaGridDocs[indexDoc] = doc;
      });

      gridViewDocumentos?.current?.setDataSource(listaGridDocs);

      ordemCompraProduto.documentos = null;
    }

    const vlrTotalPrev =
      ordemCompraProduto.quantidade * ordemCompraProduto.vlrUnitario;

    const novoProduto = {
      ...ordemCompraProduto,
      quantidade: ordemCompraProduto.quantidade || 0,
      vlrUnitario: ordemCompraProduto.vlrUnitario || 0,
      saldoProduto: ordemCompraProduto.saldoProduto || 0,
      vlrTotalPrevisto: vlrTotalPrev,
    };

    dataProdutosOrdemCompra.push(novoProduto);

    if (gridViewOrdemItens && gridViewOrdemItens.current) {
      gridViewOrdemItens.current.setDataSource(dataProdutosOrdemCompra);
    }

    setOrdemCompraProduto({
      quantidade: null,
      vlrUnit: null,
      saldoProduto: null,
      nrSeqProduto: null,
      produto: {},
    });
  };

  const onClickEditOrdemCompraItem = async (selectedValue, datasource) => {
    const dataOrdemItens = datasource.filter((item) => item !== selectedValue);

    const docsAnexados = gridViewDocumentos.current
      .getDataSource()
      ?.filter(
        (doc) =>
          doc.nrSeqProduto === selectedValue.nrSeqProduto ||
          doc.produto.nrSeqProduto === selectedValue.nrSeqProduto
      );

    if (docsAnexados.length > 0) {
      selectedValue.documentos = docsAnexados;
    }

    if (!selectedValue.vlrUnitario || selectedValue.vlrUnitario === 0) {
      const infoProduto = await getNfItemByproduto(selectedValue.nrSeqProduto);
      selectedValue.vlrUnitario = infoProduto.vlrUnit;
    }

    if (!selectedValue.saldoProduto || selectedValue.saldoProduto === 0) {
      const saldoalmox = await getBalanceByProductGeneral(
        selectedValue.nrSeqProduto
      );
      selectedValue.saldoProduto = saldoalmox;
    }

    setOrdemCompraProduto({
      ...selectedValue,
      status: GridView.EnumStatus.Alterar,
    });

    if (gridViewOrdemItens && gridViewOrdemItens.current)
      gridViewOrdemItens.current.setDataSource(dataOrdemItens);
  };

  const onClickRemoveOrdemCompraItem = async (selectedValue, datasource) => {
    const dataOrdemItens = datasource.filter((item) => item !== selectedValue);

    const obj = { ...selectedValue, status: GridView.EnumStatus.Remover };

    if (obj.nrSeqOrdemCompraItem && obj.nrSeqOrdemCompraItem > 0) {
      dataOrdemItens.unshift(obj);
    }

    if (gridViewOrdemItens && gridViewOrdemItens.current)
      gridViewOrdemItens.current.setDataSource(dataOrdemItens);
  };

  const onClickDownload = async (e) => {
    const linkSource = `data:image/png;base64, ${
      e.noImagemBase64 ?? e.noImagem
    }`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = e.noArquivo;
    downloadLink.click();
  };

  const onClickAddDocumento = async (selectedValue) => {
    setOrdemCompraDoc({
      nrSeqProduto: selectedValue.nrSeqProduto,
      produto: selectedValue.produto,
    });
  };

  const onClickRemoveOrdemCompraDocumento = async (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource(datasource);
  };

  const onClickOpenPedidoCompra = async (e) => {
    setLoading(true);

    const transactionOpen = findTransaction(TransacationCodes.PedidoCompra);

    if (transactionOpen) {
      transactionOpen.registryKey = e.nrSeqPedidoCompra;
      onOpenTransaction(transactionOpen, true);
    } else {
      onSetMessage(
        ResponseStatus.Error,
        'Não foi possível abrir o pedido de compra.'
      );
    }

    setLoading(false);
  };

  const columns = [
    {
      key: 'nrSeqOrdemCompraItem',
      type: GridView.ColumnTypes.Checkbox,
    },
    {
      key: 'produto.noProduto',
      title: 'Produto',
      sortable: false,
    },
    {
      key: 'produto.produtoMarca.noProdutoMarca',
      title: 'Marca',
      sortable: false,
    },
    {
      key: 'quantidade',
      title: 'Quantidade',
      format: GridView.DataTypes.Decimal,
      decimalPlaces: 2,
      sortable: false,
    },
    {
      key: 'vlrUnitario',
      title: 'Valor Unitário',
      format: GridView.DataTypes.Money,
      sortable: false,
    },
    {
      key: 'vlrTotalPrevisto',
      title: 'Valor Total',
      format: GridView.DataTypes.Money,
      sortable: false,
    },
    {
      key: 'saldoProduto',
      title: 'Saldo Produto',
      sortable: false,
    },
    {
      key: 'veiculo.placa',
      title: 'Veículo',
      sortable: false,
    },
    {
      key: 'obsProduto',
      title: 'Observação',
      sortable: false,
      visible: false,
    },
    {
      key: 'statusCompra',
      title: 'Status',
      sortable: false,
      colorText: 'noStatusCompraCor',
    },
    {
      key: 'nrSeqPedidoCompra',
      title: 'Cód. Compra',
      sortable: false,
      visible: false,
    },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickAddDocumento(e, datasource),
      theme: Theme.Success,
      icon: 'cloud-upload-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Adicionar Imagens.',
    },
    {
      key: 'nrSeqPedidoCompra',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOpenPedidoCompra(e),
      theme: Theme.Secondary,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Abrir Pedido de Compra do produto.',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqOrdemCompraItem',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditOrdemCompraItem(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqOrdemCompraItem',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveOrdemCompraItem(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsImgsProdutos = [
    {
      key: 'produto.noProduto',
      title: 'Produto',
      sortable: false,
    },
    { key: 'noArquivo', title: 'Documento', sortable: false },
    { key: 'noObs', title: 'Observações Documento', sortable: false },
    {
      key: 'nrSeqOrdemCompraDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
    },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) =>
        onClickRemoveOrdemCompraDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onNewPedidoCompra = async () => {
    const selecteds = gridViewOrdemItens.current.getCheckBoxValuesAt(0);

    if (selecteds === null || selecteds.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        `Necessário selecionar ao menos um item para gerar o pedido de compra.`
      );
      return;
    }

    if (!data.nrSeqOrdemCompra) {
      onSetMessage(
        ResponseStatus.Error,
        `Necessário salvar a solicitação antes de gerar o pedido de compra.`
      );
      return;
    }

    let valorTotalPedido = 0;

    const ordemSelecionada = data;
    const infopedido = {};

    const objSelecteds = selecteds.map((item, index) => {
      const dataItem = gridViewOrdemItens.current
        .getDataSource()
        .find((x) => x.nrSeqOrdemCompraItem === item[0].value);

      valorTotalPedido += dataItem.vlrTotalPrevisto;

      const produtoMarca = dataItem.produto?.produtoMarca ?? {};

      const pedidoItem = new PedidoCompraItem({
        nrSeqPedidoCompra: dataItem.nrSeqPedidoCompra || 0,
        produto: dataItem.produto,
        produtoMarca,
        nrSeqProduto: dataItem.nrSeqProduto,
        nrSeqProdutoMarca: produtoMarca.nrSeqProdutoMarca || null,
        quantidade: dataItem.quantidade,
        vlrUnitario: dataItem.vlrUnitario,
        vlrTotalItem: dataItem.vlrTotalPrevisto,
        noObservacao: dataItem.obsProduto,
        vlrSaldoQuantidade: dataItem.saldoProduto,
        nrSeqVeiculo: dataItem.nrSeqVeiculo || null,
        veiculo: dataItem.veiculo?.nrSeqVeiculo ? dataItem.veiculo : {},
        vlrDesconto: 0,
        vlrFrete: 0,
        vlrIPI: 0,
        vlrIPIPorcentagem: 0,
        nrOrdem: index + 1,
      });
      return pedidoItem;
    });

    const produtosComPedido = objSelecteds.filter(
      (p) => p.nrSeqPedidoCompra > 0
    );

    if (produtosComPedido.length > 0) {
      onSetMessage(
        ResponseStatus.Error,
        `Existem produtos já vinculados a um pedido de compra entre os itens selecionados. 
        Selecione apenas itens com pedido de compra com status Pendente.`
      );
      return;
    }

    const tipoDespesaDefault = await getPedidoCompraTipoDespesaDefault();

    if (tipoDespesaDefault.tipoDespesa?.nrSeqTipoDespesa > 0) {
      infopedido.tipoDespesa = tipoDespesaDefault.tipoDespesa;
      infopedido.nrSeqTipoDespesa =
        tipoDespesaDefault.tipoDespesa?.nrSeqTipoDespesa;
    }

    infopedido.nrSeqPedidoCompra = 0;
    infopedido.pedidoCompraItens = objSelecteds;
    infopedido.nrSeqEmpresa = data.empresa?.nrSeqEmpresa;
    infopedido.empresa = data.empresa;
    infopedido.pessoaSol = data.pessoaSol;
    infopedido.nrSeqPessoaSol = data.nrSeqPessoaSol;
    infopedido.comprador = data.pessoaCop;
    infopedido.nrSeqPessoaCop = data.nrSeqPessoaCop;
    infopedido.vlrFretePedido = 0;
    infopedido.vlrPedido = valorTotalPedido;

    const documentos = gridViewDocumentos.current.getDataSource() || [];

    if (documentos.length > 0) {
      const documents = [];

      selecteds.forEach((item) => {
        documentos.forEach((doc) => {
          if (doc.nrSeqProduto === item[0].value) {
            documents.push(
              new PedidoCompraDocumento({
                noArquivo: doc.noArquivo,
                noTipoArquivo: doc.noTipoArquivo,
                noTamanho: doc.noTamanho,
                noObservacao: doc.produto.noProduto,
                noImageBase64: doc.noImagem,
                nrSeqPedidoCompra: 0,
                nrSeqPedidoCompraDocumento: 0,
                status: GridView.EnumStatus.Inserir,
              })
            );
          }
        });
      });

      infopedido.documentos = documents;
    }

    const objModal = {
      ordensSelecionadas: [ordemSelecionada],
      informacoesPedido: infopedido,
    };

    setDataPedidoCompra(objModal);
    setModalShow(true);
  };

  useEffect(async () => {
    if (importedFile.length > 0) {
      let noImageBase64 = await toBase64(importedFile[0]);
      noImageBase64 = String(noImageBase64).substring(
        String(noImageBase64).indexOf(',') + 1
      );

      const produtoDoc = {
        nrSeqProduto: ordemCompraDoc.produto.nrSeqProduto,
        noProduto: ordemCompraDoc.produto.noProduto,
        nrSeqProdutoAgrupador: ordemCompraDoc.produto.nrSeqProdutoAgrupador,
      };

      let nomeArquivo = importedFile[0].name;
      if (nomeArquivo.includes(' ')) {
        nomeArquivo = nomeArquivo.replace(/ /g, '_');
      }

      const documento = {
        noArquivo: nomeArquivo,
        noTipoArquivo: importedFile[0].type,
        noTamanho: String(importedFile[0].size),
        noObs: ordemCompraDoc.noObs,
        noImagem: noImageBase64,
        status: GridView.EnumStatus.Inserir,
        produto: produtoDoc,
        nrSeqProduto: produtoDoc.nrSeqProduto,
        nrSeqOrdemCompra: data.nrSeqOrdemCompra || null,
      };

      let lista = [];

      if (gridViewDocumentos && gridViewDocumentos.current)
        lista = gridViewDocumentos.current.getDataSource();

      lista.push(documento);

      if (gridViewDocumentos && gridViewDocumentos.current)
        gridViewDocumentos.current.setDataSource(lista);

      setImportedFile([]);
      setOrdemCompraDoc({});
    }
  }, [importedFile]);

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Ordem Compra'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      onDelete={data?.nrSeqOrdemCompra > 0 ? onExcluir : null}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Gerar Pedido de compra'
          icon='plus'
          onClick={onNewPedidoCompra}
        />
      </ToolbarButtons>
      <Panel>
        <div className='row'>
          <div className='col-12 mb-4'>
            <Panel.Header
              icon={['fas', 'user']}
              title='Ordem Compra'
              theme={Theme.Primary}
            />
          </div>
        </div>
        <Panel.Body>
          <div className='row'>
            <div className='col-4 mb-4'>
              <Autocomplete
                label='Unidade'
                searchDataSource={onSearchUnidade}
                selectedItem={data.empresa}
                onSelectItem={(empresa) => {
                  setData({
                    ...data,
                    empresa,
                    nrSeqEmpresa: empresa.nrSeqEmpresa,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>

            <div className='col-4 mb-3'>
              <Autocomplete
                label='Solicitante'
                searchDataSource={onSearchSolicitante}
                selectedItem={data.pessoaSol}
                onSelectItem={(pessoaSol) => {
                  setData({
                    ...data,
                    pessoaSol,
                    nrSeqPessoaSol: pessoaSol.nrSeqPessoaSol,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>

            <div className='col-4 mb-3'>
              <Autocomplete
                label='Comprador'
                searchDataSource={onSearchComprador}
                selectedItem={data.pessoaCop}
                onSelectItem={(pessoaCop) => {
                  setData({
                    ...data,
                    pessoaCop,
                    nrSeqPessoaCop: pessoaCop.nrSeqPessoaCop,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-4 mb-3'>
              <Textbox
                label='Observação'
                maxLength={120}
                text={data.obs}
                onChangedValue={(obs) => setData({ ...data, obs })}
              />
            </div>

            <div className='col-3 mb-3'>
              <DatePicker
                label='Data Pedido'
                text={data.dtPedido}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtPedido) => setData({ ...data, dtPedido })}
              />
            </div>

            <div className='col-3 mb-3'>
              <Textbox
                label='Status Pedido'
                visible={data.nrSeqOrdemCompra > 0}
                readOnly
                text={data.statusOrdemCompra}
                onChangedValue={(statusOrdemCompra) =>
                  setData({ ...data, statusOrdemCompra })
                }
              />
            </div>

            <div className='col-2 mb-3'>
              <Textbox
                label='Nr. Ordem'
                visible={data.nrSeqOrdemCompra > 0}
                readOnly
                text={data.nrSeqOrdemCompra}
                onChangedValue={(nrSeqOrdemCompra) =>
                  setData({ ...data, nrSeqOrdemCompra })
                }
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-4 mb-3'>
              <Switch
                formControl
                visible={
                  data.statusOrdemCompra === EnumStatusOrcamentoCompra.Pendente
                }
                label='Cancelar Ordem'
                size={BootstrapSizes.Medium}
                checked={data.flgOrdemCancelada}
                onChange={(flgOrdemCancelada) =>
                  setData({ ...data, flgOrdemCancelada })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div className='row mb-3'>
        <div className='col'>
          <Panel>
            <Panel.Header
              icon={['fas', 'user']}
              title='Adicionar Produtos'
              theme={Theme.Primary}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col-5 mb-3'>
                  <Autocomplete
                    label='Produto'
                    searchDataSource={onSearchProduto}
                    selectedItem={ordemCompraProduto.produto}
                    onSelectItem={(produto) => {
                      onSearchInfoCompleteProduto(produto);
                    }}
                    dataSourceTextProperty='noProduto'
                    findTransaction={findTransaction}
                    onOpenTransaction={onOpenTransaction}
                    transactionToOpen={TransacationCodes.Produto}
                    nrseqTransactionToOpen='nrSeqProduto'
                  />
                </div>

                <div className='col-2 mb-3'>
                  <Textbox
                    label='Valor Unitário'
                    maxLength={40}
                    decimalPlaces={4}
                    mask={MaskTypes.Currency}
                    text={ordemCompraProduto.vlrUnitario}
                    onChangedValue={(vlrUnitario) =>
                      setOrdemCompraProduto({
                        ...ordemCompraProduto,
                        vlrUnitario,
                      })
                    }
                  />
                </div>

                <div className='col-2 mb-3'>
                  <Textbox
                    label='Quantidade'
                    text={ordemCompraProduto.quantidade}
                    mask={MaskTypes.DecimalCustom}
                    decimalPlaces={2}
                    onChangedValue={(quantidade) =>
                      setOrdemCompraProduto({
                        ...ordemCompraProduto,
                        quantidade,
                      })
                    }
                  />
                </div>

                <div className='col-2 mb-3'>
                  <Textbox
                    label='Saldo Produto'
                    readOnly
                    text={ordemCompraProduto.saldoProduto}
                    mask={MaskTypes.Integer}
                    onChangedValue={(saldoProduto) =>
                      setOrdemCompraProduto({
                        ...ordemCompraProduto,
                        saldoProduto,
                      })
                    }
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Placa do Veículo'
                    searchDataSource={onAutoCompleteVeiculo}
                    selectedItem={ordemCompraProduto.veiculo}
                    onSelectItem={(veiculo) =>
                      setOrdemCompraProduto({
                        ...ordemCompraProduto,
                        veiculo,
                        nrSeqVeiculo: veiculo.nrSeqVeiculo,
                      })
                    }
                    dataSourceTextProperty='placa'
                  />
                </div>
                <div className='col-4 mb-3'>
                  <Textbox
                    label='Observações Produto'
                    text={ordemCompraProduto.obsProduto}
                    onChangedValue={(obsProduto) =>
                      setOrdemCompraProduto({
                        ...ordemCompraProduto,
                        obsProduto,
                      })
                    }
                  />
                </div>

                <div className='col-2 mb-3'>
                  <Textbox
                    label='Cód. Pedido Compra'
                    readOnly
                    visible={
                      data.statusOrdemCompra !==
                      EnumStatusOrcamentoCompra.Cancelada
                    }
                    mask={MaskTypes.Integer}
                    text={ordemCompraProduto.nrSeqPedidoCompra}
                    onChangedValue={(nrSeqPedidoCompra) =>
                      setOrdemCompraProduto({
                        ...ordemCompraProduto,
                        nrSeqPedidoCompra,
                      })
                    }
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-2 mt-3'>
                  <Button
                    outline
                    theme={Theme.Success}
                    icon='plus'
                    template={Button.Templates.Button}
                    text='Adicionar'
                    onClick={onAddOrdemCompraProduto}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>

      <div className='row'>
        <div className='col-12'>
          <GridView
            ref={gridViewOrdemItens}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
            canControlVisibility
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <Panel>
            <Panel.Header
              icon={['fas', 'user']}
              title='Anexos'
              theme={Theme.Primary}
            />
            <Panel.Body>
              {ordemCompraDoc.nrSeqProduto && (
                <div className='row'>
                  <div className='col-3 mb-3'>
                    <Autocomplete
                      label='Produto'
                      searchDataSource={onSearchProduto}
                      selectedItem={ordemCompraDoc.produto}
                      onSelectItem={(produto) => {
                        setOrdemCompraDoc({
                          ...ordemCompraDoc,
                          produto,
                          nrSeqProduto: produto.nrSeqProduto,
                        });
                      }}
                      dataSourceTextProperty='noProduto'
                    />
                  </div>
                  <div className='col-3 mb-3'>
                    <Textbox
                      label='Observação Documento'
                      text={ordemCompraDoc.noObs}
                      onChangedValue={(noObs) =>
                        setOrdemCompraDoc({
                          ...ordemCompraDoc,
                          noObs,
                        })
                      }
                    />
                  </div>
                  <div className='col-6 mb-3'>
                    <FileUpload
                      files={importedFile}
                      onChange={(files) => {
                        setImportedFile(files);
                      }}
                      allowedMimeTypes={[
                        MimeTypes.Types.Image,
                        MimeTypes.Types.PDF,
                      ]}
                    />
                  </div>
                </div>
              )}

              <div className='row'>
                <div className='col mb-3'>
                  <GridView
                    ref={gridViewDocumentos}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsImgsProdutos}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalPedidoCompra
            data={dataPedidoCompra}
            show={modalShow}
            onSave={(msg, status) => {
              onSetMessage(status, msg);

              onSelectPage(idSelecao);
            }}
            onClose={() => {
              setModalShow(false);
            }}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
