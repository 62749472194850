export default class PerfilDeAcesso {
  constructor(jsonObject = {}) {
    this.nrSeqPerfilDeAcesso = jsonObject.nrSeqPerfilDeAcesso;
    this.noPerfilDeAcesso = jsonObject.noPerfilDeAcesso;
    this.dtHoraInicial = jsonObject.dtHoraInicial;
    this.dtHoraFinal = jsonObject.dtHoraFinal;
    this.nrSeqPerfilDeAcessoIntervalo = jsonObject.nrSeqPerfilDeAcessoIntervalo;
    this.nrDayOfWeek = jsonObject.nrDayOfWeek;
  }

  toJson = () => JSON.stringify(this);
}
