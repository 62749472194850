import { saveMotoristaTipoDesconto } from 'core/services/FIN/acertoMotorista';
import { getTipoDescontoList } from 'core/services/FIN/tipoDesconto';
import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Button,
  DatePicker,
  Modal,
  Notification,
  Textbox,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';

export default function ModalDesconto(props) {
  const [dataCadastroCliente, setDataCadastroCliente] = useState({});
  const [message, onSetMessage] = useState(null);

  const { show, vlrDesconto } = props;

  const load = async () => {};

  useEffect(() => {
    (async function func() {
      load();
    })();
  }, [show]);

  const onSearchTipoDesconto = async (e) => {
    const {
      status,
      message: msg,
      data: tipoDesconto,
    } = await getTipoDescontoList({
      noTipoDesconto: e,
    });
    if (msg) onSetMessage(status, msg);
    return tipoDesconto;
  };

  const save = async () => {
    const obj = {
      ...vlrDesconto,
      motoristaTipoDesconto: dataCadastroCliente,
    };
    const { status, value } = await saveMotoristaTipoDesconto(obj);

    setDataCadastroCliente(
      status === ResponseStatus.Success ? {} : dataCadastroCliente
    );

    if (status === ResponseStatus.Success) {
      props.onClose({
        value,
        message: '',
        theme: ResponseStatus.Success,
      });
    }
  };

  const fechar = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
      value: null,
    });
  };

  return (
    <Modal show={show} title='Modal Desconto' size={BootstrapSizes.Large}>
      <Modal.Body className='p-4 pt-3'>
        <div>
          {message && (
            <div className='mb-3'>
              <Notification
                message={message.message}
                theme={message.theme}
                onClose={() => onSetMessage(null)}
              />
            </div>
          )}

          <div className='row mb-3'>
            <div className='col-3 mb-3'>
              <DatePicker
                label='Inicio vig.'
                text={dataCadastroCliente.dtIniVigencia}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtIniVigencia) =>
                  setDataCadastroCliente({
                    ...dataCadastroCliente,
                    dtIniVigencia,
                  })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              <DatePicker
                label='Fim Vig'
                text={dataCadastroCliente.dtFimVigencia}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtFimVigencia) =>
                  setDataCadastroCliente({
                    ...dataCadastroCliente,
                    dtFimVigencia,
                  })
                }
              />
            </div>
            <div className='col-6 mb-4'>
              <Autocomplete
                label='Tipo Desconto'
                searchDataSource={onSearchTipoDesconto}
                selectedItem={dataCadastroCliente.tipoDesconto}
                onSelectItem={(tipoDesconto) => {
                  setDataCadastroCliente({
                    ...dataCadastroCliente,
                    tipoDesconto,
                    nrSeqTipoDesconto: tipoDesconto.nrSeqTipoDesconto,
                  });
                }}
                dataSourceTextProperty='noTipoDesconto'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-6 mb-3'>
              <Textbox
                label='Obs'
                text={dataCadastroCliente?.noObservacao}
                onChangedValue={(noObservacao) =>
                  setDataCadastroCliente({
                    ...dataCadastroCliente,
                    noObservacao,
                  })
                }
              />
            </div>
            <div className='row mb-3'>
              <div className='col-4 mt-3'>
                <Button
                  outline
                  text='Salvar'
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  icon='fa-thin fa-pencil-mechanical'
                  onClick={save}
                />
              </div>
              <div className='col-4 mt-3'>
                <Button
                  outline
                  text='Fechar'
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  icon='fa-thin fa-pencil-mechanical'
                  onClick={fechar}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
