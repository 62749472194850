import React, { useState, useEffect } from 'react';

import {
  BootstrapSizes,
  MimeTypes,
  Theme,
  ResponseStatus,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  Autocomplete,
  Button,
  Loader,
  Modal,
  Notification,
  FileUpload,
  Textbox,
} from 'ui/components';
import { importarArquivoConvenio } from 'core/services/ASS/lancamentoAssociado';
import { getConvenioAutoComplete } from 'core/services/ASS/convenio';

export default function ModalImportacaoConvenio({ convenio, show, onClose }) {
  const [dataModal, setDataModal] = useState({
    flgImportarArquivo: false,
  });
  const [message, setMessage] = useState(null);
  const [warning, setWarning] = useState(null);
  const [loading, setLoading] = useState(false);
  const [importedFile, setImportedFile] = useState([]);

  useEffect(() => {
    (async function load() {
      setImportedFile([]);
    })();
  }, [convenio]);

  const onClickImportarConvenio = async () => {
    if (!importedFile) {
      setMessage({
        message: 'Não há arquivos selecionados para importação.',
        theme: Theme.Danger,
      });
      return;
    }

    if (!dataModal.nrSeqConvenio) {
      setMessage({
        message: 'Necessário preencher o campo Convênio.',
        theme: Theme.Danger,
      });
      return;
    }

    if (!dataModal.anoMes && !dataModal.convenio?.flgLancamentoDiario) {
      setMessage({
        message: 'Necessário preencher o campo Competência.',
        theme: Theme.Danger,
      });
      return;
    }
    setLoading(true);

    const { status, message: msg, value } = await importarArquivoConvenio(
      importedFile[0],
      dataModal
    );

    let text = '';

    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    if (status === ResponseStatus.Success && value.length > 0) {
      text = value.join();
    }
    if (text)
      setWarning({
        message: text,
        theme: Theme.Warning,
      });
    setLoading(false);

    if (status?.ResponseStatus && status.ResponseStatus.Success) {
      setDataModal({});
      onClose();
    }
  };

  const onSearchConvenio = async (e) => {
    const { status, message: msg, convenios } = await getConvenioAutoComplete({
      noConvenio: e,
    });
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    return convenios;
  };

  return (
    <Modal
      show={show}
      title='Importar Associado Convênio'
      onClose={() => {
        setDataModal({});
        onClose();
      }}
      size={BootstrapSizes.Large}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        {warning && (
          <div className='mb-3'>
            <Notification
              message={warning.message}
              theme={warning.theme}
              onClose={() => setWarning(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col-2 mb-3'>
            <Textbox
              maxLength={7}
              label='Competência'
              text={dataModal.anoMes}
              mask={MaskTypes.Competence}
              onChangedValue={(anoMes) => {
                setDataModal({ ...dataModal, anoMes });
              }}
            />
          </div>

          <div className='col-4 mb-3'>
            <Autocomplete
              label='Convênio'
              searchDataSource={onSearchConvenio}
              selectedItem={dataModal.convenio}
              onSelectItem={(convenioSelected) => {
                setDataModal({
                  ...dataModal,
                  convenio: convenioSelected,
                  nrSeqConvenio: convenioSelected.nrSeqConvenio,
                });
              }}
              dataSourceTextProperty='noConvenio'
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-12'>
            <FileUpload
              files={importedFile}
              onChange={(file) => setImportedFile(file)}
              allowedMimeTypes={[
                MimeTypes.Types.Excel,
                MimeTypes.Types.PDF,
                MimeTypes.Types.Txt,
                MimeTypes.Types.Word,
              ]}
            />
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-2 mb-3'>
            <Button
              outline
              theme={Theme.Success}
              template={Button.Templates.Button}
              text='Importar'
              icon='fa-solid fa-cloud-check'
              onClick={onClickImportarConvenio}
            />
          </div>
          <div className='col-2 mb-3'>
            <Button
              outline
              text='Cancelar'
              theme={Theme.Danger}
              icon='fas fa-times'
              onClick={() => {
                setDataModal({});
                onClose();
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
