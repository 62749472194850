import qs from 'qs';
import Pagination from 'core/models/pagination';
import ContratoServico from 'core/models/GProj/contratoServico';
import api from '../api';

const baseURL = `/ContratoServico`;

export const getContratoServicoList = async (filters) => {
  const response = await api.get(`/${baseURL}/ContratoServico/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ContratoServico(json)) ?? [],
  };
};

export const getContratoServicoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ContratoServico(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getContratoServico = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ContratoServico(value) : {};
};

export const saveContratoServico = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteContratoServico = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printContratoServico = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelContratoServico = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllContratoServico = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getContratoServicoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ContratoServico(json)) ?? [],
  };
};

export const getContratoServicoFullAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteFull`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ContratoServico(json)) ?? [],
  };
};
