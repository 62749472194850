export default class ProdutoOrigem {
  constructor(jsonObject = {}) {
    this.nrSeqProdutoOrigem = jsonObject.nrSeqProdutoOrigem;
    this.noProdutoOrigem = jsonObject.noProdutoOrigem;
    this.cdProdutoOrigem = jsonObject.cdProdutoOrigem;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.grupoempresa = jsonObject.grupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
