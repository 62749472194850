import Usuario from '../SEG/usuario';

export default class DemandaIteracao {
  constructor(jsonObject = {}) {
    this.nrSeqDemandaIteracao = jsonObject.nrSeqDemandaIteracao;
    this.noIteracao = jsonObject.noIteracao;
    this.nrSeqDemanda = jsonObject.nrSeqDemanda;
    this.nrSeqStatusDemanda = jsonObject.nrSeqStatusDemanda;
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.dtIteracao = jsonObject.dtIteracao;
    this.flgVisualizado = jsonObject.flgVisualizado;
    this.nrProcedimento = jsonObject.nrProcedimento;
    this.noStatusDemanda = jsonObject.noStatusDemanda;
    this.status = jsonObject.status;
    this.noUsuarioIteracao = jsonObject.noUsuarioIteracao;

    this.usuario = new Usuario(jsonObject.usuario ?? {});
  }

  toJson = () => JSON.stringify(this);
}
