import { Empresa } from 'core/models/SEG';

export default class PagamentoMassivo {
  constructor(jsonObject = {}) {
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqGrupoempresa = jsonObject.nrSeqGrupoempresa;
    this.nrSeqPagamentoMassivo = jsonObject.nrSeqPagamentoMassivo;
    this.dtCad = jsonObject.dtCad;
    this.nrIdentificado = jsonObject.nrIdentificado;
    this.vlrSaldoPago = jsonObject.vlrSaldoPago;
    this.vlrMulta = jsonObject.vlrMulta;
    this.vlrJuro = jsonObject.vlrJuro;
    this.vlrTotal = jsonObject.vlrTotal;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.dtPagamento = jsonObject.dtPagamento;
    this.pagamentoMassivoItemS = jsonObject.pagamentoMassivoItemS;
    this.tituloPagarS = jsonObject.tituloPagarS;
  }

  toJson = () => JSON.stringify(this);
}
