import qs from 'qs';
import Pagination from 'core/models/pagination';
import ComissaoMotorista from 'core/models/FRO/comissaoMotorista';
import api from '../api';

const baseURL = `/FRO/ComissaoMotorista`;

export const getComissaoMotoristaList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ComissaoMotorista(json)) ?? [],
  };
};

export const getComissaoMotoristaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ComissaoMotorista(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};
export const getComissaoMotorista = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ComissaoMotorista(value) : {};
};
export const getComissaoMotoristaSearchWithTravelInformation = async (id) => {
  const response = await api.get(`${baseURL}/SearchWithTravelInformation`, {
    params: { id },
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ComissaoMotorista(json)) ?? [],
  };
};

export const saveComissaoMotorista = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};
export const saveComissaoMotoristaList = async (data) => {
  const response = await api.post(`${baseURL}/SaveComissaoMotoristaList`, data);
  return response.data ?? {};
};

export const deleteComissaoMotorista = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printComissaoMotorista = async (data) => {
  const response = await api.post(`${baseURL}/Print`, data);
  return response.data ?? {};
};
export const printComissaoMotoristaVales = async (data) => {
  const response = await api.post(`${baseURL}/PrintVales`, data);
  return response.data ?? {};
};

export const excelComissaoMotorista = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllComissaoMotorista = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const geraComissaoMotorista = async (data) => {
  const response = await api.post(`${baseURL}/GeraComissaoMotorista`, data);
  return response.data ?? {};
};
