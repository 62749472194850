import React from 'react';
import { MaskTypes } from 'ui/Helpers/masks';
import { BootstrapSizes, Theme, JustifyContent } from 'ui/Helpers/utils';
import { Modal, Textbox, Panel, Button } from 'ui/components';

export default function ModalDetalhesProduto(props) {
  const { show, data } = props;

  const onSend = () => {
    props.onClose(data);
  };

  return (
    <Modal
      show={show}
      title='Detalhes do Produto'
      onClose={() => onSend()}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body>
        <div className='row'>
          <div className='row mb-3'>
            <div className='col-3'>
              <Textbox
                label='NCM'
                text={data.cdNcm}
                onChangedValue={(cdNcm) => {
                  data.cdNcm = cdNcm;
                }}
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='CFO'
                text={data.cfo?.cdCfo}
                onChangedValue={(cdCfo) => {
                  data.cfo.cdCfo = cdCfo;
                }}
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='Unidade'
                readOnly
                text={() => {
                  if (
                    data.produto.produtoUnidadeMedida.cdProdutoUnidadeMedida !==
                    undefined
                  ) {
                    return `${data.produto.produtoUnidadeMedida.cdProdutoUnidadeMedida} - ${data.produto.produtoUnidadeMedida.noProdutoUnidadeMedida}`;
                  }

                  return '';
                }}
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='Despesa'
                text={data.vlrDespesa}
                mask={MaskTypes.DecimalCustom}
                decimalPlaces={4}
                onChangedValue={(vlrDespesa) => {
                  data.vlrDespesa = vlrDespesa;
                }}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-3'>
              <Textbox
                label='Valor ST'
                text={data.vlrIcmsST}
                mask={MaskTypes.DecimalCustom}
                decimalPlaces={4}
                onChangedValue={(vlrIcmsST) => {
                  data.vlrIcmsST = vlrIcmsST;
                }}
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='ICMS Frete'
                text={data.vlrIcmsFrete}
                mask={MaskTypes.DecimalCustom}
                decimalPlaces={4}
                onChangedValue={(vlrIcmsFrete) => {
                  data.vlrIcmsFrete = vlrIcmsFrete;
                }}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-12'>
              <Textbox
                label='Observação'
                text={data.observacao}
                onChangedValue={(observacao) => {
                  data.observacao = observacao;
                }}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-3'>
              <Panel>
                <Panel.Header
                  title='ICMS'
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                />
                <Panel.Body>
                  <div className='row'>
                    <div className='col-6 mb-2'>
                      <Textbox
                        label='CST'
                        text={data.cdCstIcms}
                        onChangedValue={(cdCstIcms) => {
                          data.cdCstIcms = cdCstIcms;
                        }}
                      />
                    </div>
                    <div className='col-6 mb-2'>
                      <Textbox
                        label='% Base'
                        text={data.percBaseCalcIcms}
                        mask={MaskTypes.DecimalCustom}
                        decimalPlaces={2}
                        onChangedValue={(percBaseCalcIcms) => {
                          data.percBaseCalcIcms = percBaseCalcIcms;
                        }}
                      />
                    </div>
                    <div className='col-6 mb-2'>
                      <Textbox
                        label='Aliquota'
                        text={data.aliqIcms}
                        mask={MaskTypes.DecimalCustom}
                        decimalPlaces={2}
                        onChangedValue={(aliqIcms) => {
                          data.aliqIcms = aliqIcms;
                        }}
                      />
                    </div>
                    <div className='col-6 mb-2'>
                      <Textbox
                        label='Valor Base'
                        text={data.vlrBaseCalcIcms}
                        mask={MaskTypes.DecimalCustom}
                        decimalPlaces={2}
                        onChangedValue={(vlrBaseCalcIcms) => {
                          data.vlrBaseCalcIcms = vlrBaseCalcIcms;
                        }}
                      />
                    </div>
                    <div className='col-12'>
                      <Textbox
                        label='Valor ICMS'
                        text={data.vlrIcms}
                        mask={MaskTypes.DecimalCustom}
                        decimalPlaces={2}
                        onChangedValue={(vlrIcms) => {
                          data.vlrIcms = vlrIcms;
                        }}
                      />
                    </div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
            <div className='col-3'>
              <Panel>
                <Panel.Header
                  title='IPI'
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                />
                <Panel.Body>
                  <div className='row'>
                    <div className='col-6 mb-2'>
                      <Textbox
                        label='CST'
                        text={data.cdCstIpi}
                        onChangedValue={(cdCstIpi) => {
                          data.cdCstIpi = cdCstIpi;
                        }}
                      />
                    </div>
                    <div className='col-6 mb-2'>
                      <Textbox
                        label='% Base'
                        text={data.percBaseCalcIpi}
                        mask={MaskTypes.DecimalCustom}
                        decimalPlaces={2}
                        onChangedValue={(percBaseCalcIpi) => {
                          data.percBaseCalcIpi = percBaseCalcIpi;
                        }}
                      />
                    </div>
                    <div className='col-6 mb-2'>
                      <Textbox
                        label='Aliquota'
                        text={data.aliqIpi}
                        mask={MaskTypes.DecimalCustom}
                        decimalPlaces={2}
                        onChangedValue={(aliqIpi) => {
                          data.aliqIpi = aliqIpi;
                        }}
                      />
                    </div>
                    <div className='col-6 mb-2'>
                      <Textbox
                        label='Valor Base'
                        text={data.vlrBaseCalcIpi}
                        mask={MaskTypes.DecimalCustom}
                        decimalPlaces={2}
                        onChangedValue={(vlrBaseCalcIpi) => {
                          data.vlrBaseCalcIpi = vlrBaseCalcIpi;
                        }}
                      />
                    </div>
                    <div className='col-12'>
                      <Textbox
                        label='Valor IPI'
                        text={data.vlrIpi}
                        mask={MaskTypes.DecimalCustom}
                        decimalPlaces={2}
                        onChangedValue={(vlrIpi) => {
                          data.vlrIpi = vlrIpi;
                        }}
                      />
                    </div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
            <div className='col-3'>
              <Panel>
                <Panel.Header
                  title='PIS'
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                />
                <Panel.Body>
                  <div className='row'>
                    <div className='col-6 mb-2'>
                      <Textbox
                        label='CST'
                        text={data.cdCstPis}
                        onChangedValue={(cdCstPis) => {
                          data.cdCstPis = cdCstPis;
                        }}
                      />
                    </div>
                    <div className='col-6 mb-2'>
                      <Textbox
                        label='% Base'
                        text={data.percBaseCalcPis}
                        mask={MaskTypes.DecimalCustom}
                        decimalPlaces={2}
                        onChangedValue={(percBaseCalcPis) => {
                          data.percBaseCalcPis = percBaseCalcPis;
                        }}
                      />
                    </div>
                    <div className='col-6 mb-2'>
                      <Textbox
                        label='Aliquota'
                        text={data.aliqPis}
                        mask={MaskTypes.DecimalCustom}
                        decimalPlaces={2}
                        onChangedValue={(aliqPis) => {
                          data.aliqPis = aliqPis;
                        }}
                      />
                    </div>
                    <div className='col-6 mb-2'>
                      <Textbox
                        label='Valor Base'
                        text={data.vlrBaseCalcPis}
                        mask={MaskTypes.DecimalCustom}
                        decimalPlaces={2}
                        onChangedValue={(vlrBaseCalcPis) => {
                          data.vlrBaseCalcPis = vlrBaseCalcPis;
                        }}
                      />
                    </div>
                    <div className='col-12'>
                      <Textbox
                        label='Valor PIS'
                        text={data.vlrPis}
                        mask={MaskTypes.DecimalCustom}
                        decimalPlaces={2}
                        onChangedValue={(vlrPis) => {
                          data.vlrPis = vlrPis;
                        }}
                      />
                    </div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
            <div className='col-3'>
              <Panel>
                <Panel.Header
                  title='COFINS'
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                />
                <Panel.Body>
                  <div className='row'>
                    <div className='col-6 mb-2'>
                      <Textbox
                        label='CST'
                        text={data.cdCstCofins}
                        onChangedValue={(cdCstCofins) => {
                          data.cdCstCofins = cdCstCofins;
                        }}
                      />
                    </div>
                    <div className='col-6 mb-2'>
                      <Textbox
                        label='% Base'
                        text={data.percBaseCalcCofins}
                        decimalPlaces={2}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={(percBaseCalcCofins) => {
                          data.percBaseCalcCofins = percBaseCalcCofins;
                        }}
                      />
                    </div>
                    <div className='col-6 mb-2'>
                      <Textbox
                        label='Aliquota'
                        text={data.aliqCofins}
                        mask={MaskTypes.DecimalCustom}
                        decimalPlaces={2}
                        onChangedValue={(aliqCofins) => {
                          data.aliqCofins = aliqCofins;
                        }}
                      />
                    </div>
                    <div className='col-6 mb-2'>
                      <Textbox
                        label='Valor Base'
                        text={data.vlrBaseCalcCofins}
                        mask={MaskTypes.DecimalCustom}
                        decimalPlaces={2}
                        onChangedValue={(vlrBaseCalcCofins) => {
                          data.vlrBaseCalcCofins = vlrBaseCalcCofins;
                        }}
                      />
                    </div>
                    <div className='col-12'>
                      <Textbox
                        label='Valor Cofins'
                        text={data.vlrCofins}
                        mask={MaskTypes.DecimalCustom}
                        decimalPlaces={2}
                        onChangedValue={(vlrCofins) => {
                          data.vlrCofins = vlrCofins;
                        }}
                      />
                    </div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Medium}
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          onClick={() => onSend()}
          text='Sair'
        />
      </Modal.Footer>
    </Modal>
  );
}
