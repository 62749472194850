import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getTituloEspeciePagined,
  printTituloEspecie,
  deleteAllTituloEspecie,
  // getTituloEspecieList,
} from 'core/services/FIN/tituloEspecie';
import { Textbox, CSDSelPage, GridView } from 'ui/components';

const columns = [
  { key: 'nrSeqTituloEspecie', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdTituloEspecie', title: 'Código' },
  { key: 'noEspecie', title: 'Descrição' },
  { key: 'flgInativo', title: 'Inativo' },
];

export default function TituloEspecie({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getTituloEspeciePagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getTituloEspeciePagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'TituloEspecie/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqTituloEspecie = sourceRow.find(
      (e) => e.key === 'nrSeqTituloEspecie'
    );
    onOpenMaintenance(nrSeqTituloEspecie.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printTituloEspecie(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllTituloEspecie(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };
  /*
  const onSearchTituloEspecie = async (e) => {
    const { data } = await getTituloEspecieList({
      noEspecie: e,
    });

    return data;
  };
  */

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Titulo Especie'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-4'>
          <Textbox
            maxLength={80}
            label='Nome do Titulo Especie'
            text={filtros.noEspecie}
            onChangedValue={(noEspecie) =>
              setFiltros({ ...filtros, noEspecie })
            }
          />
        </div>
      </div>
      <GridView
        enableExcelExport
        ref={gridView}
        className='table-striped table-hover table-bordered table-sm'
        dataColumns={columns}
        onColumnSort={onColumnSort}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        onRowDoubleClick={onRowDoubleClick}
        transaction={transaction}
      />
    </CSDSelPage>
  );
}
