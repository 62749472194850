import qs from 'qs';
import Pagination from 'core/models/pagination';
import ColetaDestinatario from 'core/models/FRO/coletaDestinatario';
import api from '../api';

const baseURL = `/FRO/ColetaDestinatario`;

export const getColetaDestinatarioList = async (filters) => {
  const response = await api.get(`/$ColetaDestinatario/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ColetaDestinatario(json)) ?? [],
  };
};

export const getColetaDestinatarioPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ColetaDestinatario(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getColetaDestinatario = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ColetaDestinatario(value) : {};
};

export const saveColetaDestinatario = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteColetaDestinatario = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printColetaDestinatario = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelColetaDestinatario = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllColetaDestinatario = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
