import React, { useRef, useState, useEffect } from 'react';

import {
  Autocomplete,
  Button,
  GridView,
  Modal,
  Notification,
} from 'ui/components';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';
import {
  AlteracaoFormaPagamentoTituloPagar,
  getMultipag,
} from 'core/services/FIN/tituloPagar';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN';
import { getPessoaAutoComplete } from 'core/services/SEG';
import { getTipoMovimentoRemessaAutoComplete } from 'core/services/FIN/tipoMovimentoRemessa';
import { getInstrucaoMovimentoAutoComplete } from 'core/services/FIN/instrucaoMovimento';
import { getTipoMovimentoAutoComplete } from 'core/services/FIN/tipoMovimento';
import { getFormaLancamentoEletronicoAutoComplete } from 'core/services/FIN/formaLancamentoEletronico';
import { getTipoServicoBancarioAutoComplete } from 'core/services/FIN/tipoServicoBancario';

export default function ModalAlteracaoFormaPagamento(props) {
  const [data, setData] = useState();
  const [messageState, setMessage] = useState(null);
  const gridView = useRef();

  const { show, alteracaoFormaPagamentoValor } = props;

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  useEffect(() => {
    if (alteracaoFormaPagamentoValor !== undefined) {
      setData({ ...data });
    }
    gridView?.current?.setDataSource(alteracaoFormaPagamentoValor);
  }, [show]);

  const cancelar = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
    });
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitPag: true,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const salvar = async () => {
    const lista = gridView.current.getDataSource();

    const obj = {
      ...data,
      TituloPagarAgrupado: lista,
    };

    const { status, message: msg } = await AlteracaoFormaPagamentoTituloPagar(
      obj
    );

    if (status === ResponseStatus.Success) {
      if (gridView && gridView.current) gridView.current.setDataSource(null);
    }
    onSetMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    if (status === ResponseStatus.Success) {
      props.onClose({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
  };

  const onSearchTipoDeServico = async (e) => {
    const {
      status,
      message: msg,
      data: tipodeservico,
    } = await getTipoServicoBancarioAutoComplete({
      noTipoServicoBancario: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return tipodeservico;
  };
  const onSearchFormaDeLancamento = async (e) => {
    const {
      status,
      message: msg,
      data: formaDeLancamento,
    } = await getFormaLancamentoEletronicoAutoComplete({
      noFormaLancamentoEletronico: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return formaDeLancamento;
  };
  const onSearchTipoDeMovimento = async (e) => {
    const {
      status,
      message: msg,
      data: tipoDeMovimento,
    } = await getTipoMovimentoAutoComplete({
      noTipoMovimento: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return tipoDeMovimento;
  };

  const onSearchInstrucaoDeMovimento = async (e) => {
    const {
      status,
      message: msg,
      data: instrucaoDeMovimento,
    } = await getInstrucaoMovimentoAutoComplete({
      noInstrucaoMovimento: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return instrucaoDeMovimento;
  };
  const onSearchMovimentoRemessa = async (e) => {
    const {
      status,
      message: msg,
      data: TipoMovimentoRemessa,
    } = await getTipoMovimentoRemessaAutoComplete({
      noTipoMovimentoRemessa: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return TipoMovimentoRemessa;
  };
  const onSearchSacadorAvalista = async (e) => {
    const { status, message: msg, data: Pessoa } = await getPessoaAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return Pessoa;
  };

  const multipag = async (e) => {
    if (data.formaPagamento?.flgGrupo === 'E') {
      e = data;
      const obj = await getMultipag({
        ...data,
      });
      setData({
        ...data,
        tipoServico: obj.tipoServico,
        nrSeqTipoServicoBancario: obj.nrSeqTipoServicoBancario,
        tipoMovimento: obj.tipoMovimento,
        nrSeqTipoMovimento: obj.nrSeqTipoMovimento,
        formaLancamentoEletronico: obj.formaLancamentoEletronico,
        nrSeqFormaLancamentoEletronico: obj.nrSeqFormaLancamentoEletronico,
        instrucaoMovimento: obj.instrucaoMovimento,
        nrSeqInstrucaoMovimento: obj.nrSeqInstrucaoMovimento,
        formaPagamento: data.formaPagamento,
        nrSeqFormaPagamento: data.formaPagamento.nrSeqFormaPagamento,
        movimentoRemessa: obj.movimentoRemessa,
        nrSeqTipoMovimentoRemessa: obj.nrSeqTipoMovimentoRemessa,
      });

      return obj;
    }
    setData({
      ...e,
      formaPagamento: data.formaPagamento,
      nrSeqFormaPagamento: data.formaPagamento.nrSeqFormaPagamento,
    });
    return true;
  };

  const columns = [
    {
      key: 'nrTit',
      title: 'Nr Tit',
    },
    {
      key: 'nrOrdem',
      title: 'Nr Ordem',
    },
    {
      key: 'fornecedor.noFornecedor',
      title: 'Fornecedor',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor Do Titulo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrPago',
      title: 'Valor Pago',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrSaldoTit',
      title: 'Valor Saldo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'dtVencimento',
      title: 'Data Vencimento',
      format: GridView.DataTypes.Date,
    },
  ];

  return (
    <Modal
      show={show}
      title='Alteração Forma Pagamento'
      size={BootstrapSizes.Large}
    >
      <Modal.Body className='p-4 pt-3'>
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='row mb-3'>
          <div className='col-5 mb-3'>
            <Autocomplete
              label='Forma Pagamento'
              searchDataSource={onSearchFormaPagamento}
              required
              selectedItem={data?.formaPagamento}
              onSelectItem={(formaPagamento) => {
                data.formaPagamento = formaPagamento;
                data.nrSeqFormaPagamento = formaPagamento.nrSeqFormaPagamento;
                multipag(data);
              }}
              dataSourceTextProperty='noFormaPagamento'
            />
          </div>
        </div>
        {data?.formaPagamento?.flgGrupo === 'E' && (
          <div className='row mb-3'>
            <div className='col-3 mb-3'>
              <Autocomplete
                required
                label='Tipo de serviço'
                searchDataSource={onSearchTipoDeServico}
                selectedItem={data?.tipoServico}
                onSelectItem={(tipoServico) => {
                  setData({
                    ...data,
                    tipoServico,
                    nrSeqTipoServicoBancario:
                      tipoServico.nrSeqTipoServicoBancario,
                  });
                }}
                dataSourceTextProperty='noTipoServicoBancario'
              />
            </div>
            <div className='col-3 mb-3'>
              <Autocomplete
                required
                label='Forma de lançamento'
                searchDataSource={onSearchFormaDeLancamento}
                selectedItem={data?.formaLancamentoEletronico}
                onSelectItem={(formaLancamentoEletronico) => {
                  setData({
                    ...data,
                    formaLancamentoEletronico,
                    nrSeqFormaLancamentoEletronico:
                      formaLancamentoEletronico.nrSeqFormaLancamentoEletronico,
                  });
                }}
                dataSourceTextProperty='noFormaLancamentoEletronico'
              />
            </div>
            <div className='col-3 mb-3'>
              <Autocomplete
                required
                label='Tipo de movimento'
                searchDataSource={onSearchTipoDeMovimento}
                selectedItem={data?.tipoMovimento}
                onSelectItem={(tipoMovimento) => {
                  setData({
                    ...data,
                    tipoMovimento,
                    nrSeqTipoMovimento: tipoMovimento.nrSeqTipoMovimento,
                  });
                }}
                dataSourceTextProperty='noTipoMovimento'
              />
            </div>
            <div className='col-3 mb-3'>
              <Autocomplete
                required
                label='Instrução de movimento'
                searchDataSource={onSearchInstrucaoDeMovimento}
                selectedItem={data?.instrucaoMovimento}
                onSelectItem={(instrucaoMovimento) => {
                  setData({
                    ...data,
                    instrucaoMovimento,
                    nrSeqInstrucaoMovimento:
                      instrucaoMovimento.nrSeqInstrucaoMovimento,
                  });
                }}
                dataSourceTextProperty='noInstrucaoMovimento'
              />
            </div>
          </div>
        )}
        {data?.formaPagamento?.flgGrupo === 'E' && (
          <div className='row mb-3'>
            <div className='col-3 mb-3'>
              <Autocomplete
                required
                label='Movimento Remessa'
                searchDataSource={onSearchMovimentoRemessa}
                selectedItem={data?.movimentoRemessa}
                onSelectItem={(movimentoRemessa) => {
                  setData({
                    ...data,
                    movimentoRemessa,
                    nrSeqTipoMovimentoRemessa:
                      movimentoRemessa.nrSeqTipoMovimentoRemessa,
                  });
                }}
                dataSourceTextProperty='noTipoMovimentoRemessa'
              />
            </div>
            <div className='col-3 mb-3'>
              <Autocomplete
                label='Sacador Avalista'
                searchDataSource={onSearchSacadorAvalista}
                selectedItem={data?.pessoaSacador}
                onSelectItem={(pessoaSacador) => {
                  setData({
                    ...data,
                    pessoaSacador,
                    nrSeqPessoaSacador: pessoaSacador.nrSeqPessoa,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
          </div>
        )}

        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>

        <div className='row'>
          <div className='col-4 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Salvar'
              onClick={salvar}
            />
          </div>
          <div className='col-2 mt-3'>
            <Button
              theme={Theme.Danger}
              template={Button.Templates.Button}
              text='Sair'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
