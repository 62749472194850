export default class PocPerflitFormacao {
  constructor(jsonObject = {}) {
    this.nrSeqPocPerflitFormacao = jsonObject.nrSeqPocPerflitFormacao;
    this.cdPocPerflitFormacao = jsonObject.cdPocPerflitFormacao;
    this.noPocPerflitFormacao = jsonObject.noPocPerflitFormacao;
    this.noImagem = jsonObject.noImagem;
    this.noImagemBase64 = jsonObject.noImagemBase64;
    this.noTipoArquivo = jsonObject.noTipoArquivo;
    this.noArquivo = jsonObject.noArquivo;
    this.noTamanho = jsonObject.noTamanho;
    this.flgAtivo = jsonObject.flgAtivo;
  }

  toJson = () => JSON.stringify(this);
}
