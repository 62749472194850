import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Textbox, Capture } from 'ui/components';

import {
  getPneuSituacao,
  savePneuSituacao,
  deletePneuSituacao,
} from 'core/services/FRO/pneuSituacao';

export default function PneuSituacaoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getPneuSituacao(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await savePneuSituacao(data);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
    }

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deletePneuSituacao(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Pneu Situacao'
      transaction={transaction}
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqPneuSituacao > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <div className='row'>
        <div className='col-lg-3'>
          <Capture
            defaultIcon={['far', 'image']}
            picture={data.imagem}
            onPictureLoad={(imagem) => setData({ ...data, imagem })}
          />
        </div>
        <div className='row'>
          <div className='col mb-3'>
            <Textbox
              maxLength={20}
              label='Código'
              text={data.cdPneuSituacao}
              onChangedValue={(cdPneuSituacao) =>
                setData({ ...data, cdPneuSituacao })
              }
            />
          </div>
        </div>
        <div className='row'>
          <div className='col mb-3'>
            <Textbox
              maxLength={60}
              label='Descrição'
              text={data.noPneuSituacao}
              onChangedValue={(noPneuSituacao) =>
                setData({ ...data, noPneuSituacao })
              }
            />
          </div>
        </div>
      </div>
    </CSDManutPage>
  );
}
