import qs from 'qs';
import Pagination from 'core/models/pagination';
import TabelaPreco from 'core/models/VEN/tabelaPreco';
import api from '../api';

const baseURL = `/VEN/TabelaPreco`;

export const getTabelaPrecoList = async (filters) => {
  const response = await api.get(`/$TabelaPreco/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TabelaPreco(json)) ?? [],
  };
};

export const getTabelaPrecoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TabelaPreco(json)) ?? [],
  };
};

export const getTabelaPrecoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TabelaPreco(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getMinimumPrice = async (filters) => {
  const response = await api.get(`${baseURL}/MinimumPrice`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    price: value,
  };
};

export const getSellPrice = async (filters) => {
  const response = await api.get(`${baseURL}/SellPrice`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    price: value,
  };
};

export const getTabelaPreco = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new TabelaPreco(value) : {};
};

export const saveTabelaPreco = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteTabelaPreco = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printTabelaPreco = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelTabelaPreco = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllTabelaPreco = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
