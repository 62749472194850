import React, { useEffect, useState, useRef } from 'react';
import { BootstrapSizes } from 'ui/Helpers/utils';
import { Button, FontAwesomeIcon } from 'ui/components';

import styles from './toolbarButtons.module.css';

const ToolbarButtons = ({ fixedOnTop, children }) => {
  const toolbarRef = useRef();
  const [onTop, setOnTop] = useState(false);
  const scrollHandler = () => {
    if (fixedOnTop) {
      if (toolbarRef) {
        if (toolbarRef.current) {
          const distanciaTopo = toolbarRef.current.getBoundingClientRect().top;

          if (distanciaTopo < 0) {
            setOnTop(true);
          } else {
            setOnTop(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler, true);
  }, []);

  const style = `toolbar btn-group ${onTop ? styles.fixedOnTop : ''}`;
  return (
    <div
      className='toolbar btn-group'
      role='group'
      data-toolbar
      ref={toolbarRef}
    >
      <div className={style}>{children}</div>
    </div>
  );
};

ToolbarButtons.Button = (props) => {
  const { theme, size, id } = props;
  const btnProps = { ...props };
  btnProps.theme = theme ?? 'primary';
  btnProps.size = size ?? BootstrapSizes.Small;

  if (id) btnProps.id = id;

  return React.createElement(Button, btnProps);
};

ToolbarButtons.ButtonList = ({ theme, size, icon, text, items }) => {
  const className = ['btn'];
  className.push(`btn-${theme ?? 'primary'}`);
  className.push(`btn-${size ?? BootstrapSizes.Small}`);

  return (
    <div className='dropdown'>
      <button
        type='button'
        className={className.join(' ')}
        data-bs-toggle='dropdown'
        aria-expanded='false'
      >
        {icon && <FontAwesomeIcon icon={icon} />}
        {text && <span>{text}</span>}
      </button>
      <ul className='dropdown-menu' aria-labelledby='dropdownMenuLink'>
        {items &&
          items.map((item) => (
            <li key={item.text}>
              <button
                type='button'
                className='dropdown-item'
                onClick={() => item.onClick && item.onClick(item)}
              >
                {item.icon && <FontAwesomeIcon icon={item.icon} />}
                {item.text && <span>{item.text}</span>}
              </button>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default ToolbarButtons;
