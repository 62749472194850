export default class AdjudicacaoIcms {
  constructor(jsonObject = {}) {
    this.nrSeqAdjudicacaoIcms = jsonObject.nrSeqAdjudicacaoIcms;
    this.nrSeqProduto = jsonObject.nrSeqProduto;
    this.nrSeqNfv = jsonObject.nrSeqNfv;
    this.vlrIcms = jsonObject.vlrIcms;
    this.vlrIcmsCst = jsonObject.vlrIcmsCst;
    this.nrSeqNfvAvulsa = jsonObject.nrSeqNfvAvulsa;

    this.noProduto = jsonObject.noProduto;
  }

  toJson = () => JSON.stringify(this);
}
