import qs from 'qs';
import Pagination from 'core/models/pagination';
import AssociadoPlanoConvenio from 'core/models/ASS/associadoPlanoConvenio';
import api from '../api';

const baseURL = `/ASS/Associado_PlanoConvenio`;

export const getAssociadoPlanoConvenioList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AssociadoPlanoConvenio(json)) ?? [],
  };
};

export const getAssociadoPlanoConvenioPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AssociadoPlanoConvenio(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getAssociadoPlanoConvenioByAssociadoId = async (
  nrSeqPessoaAss
) => {
  const response = await api.get(`${baseURL}/SearchByAss`, {
    params: { nrSeqPessoaAss },
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AssociadoPlanoConvenio(json)) ?? [],
  };
};

export const getAssociadoPlanoConvenio = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new AssociadoPlanoConvenio(value) : {};
};

export const saveAssociadoPlanoConvenio = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const saveAllAssociadoPlanosConvenio = async (data) => {
  const response = await api.post(`${baseURL}/SaveAll`, data);
  return response.data ?? {};
};

export const deleteAssociadoPlanoConvenio = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printAssociadoPlanoConvenio = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelAssociadoPlanoConvenio = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllAssociadoPlanoConvenio = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
