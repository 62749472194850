export default class Pagination {
  constructor(jsonObject = {}) {
    this.page = jsonObject?.page;
    this.totalByPage = jsonObject?.totalByPage;
    this.totalRows = jsonObject?.totalRows;
    this.sortBy = jsonObject?.sortBy;
  }

  toJson = () => JSON.stringify(this);
}
