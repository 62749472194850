export default class PostoCombustivelBomba {
  constructor(jsonObject = {}) {
    this.nrSeqPostoBomba = jsonObject.nrSeqPostoBomba;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.cdBomba = jsonObject.cdBomba;
    this.nrSeqPostoCombustivelTanque = jsonObject.nrSeqPostoCombustivelTanque;
    this.nrOdometroInicial = jsonObject.nrOdometroInicial;
    this.flgAtivo = jsonObject.flgAtivo;
  }

  toJson = () => JSON.stringify(this);
}
