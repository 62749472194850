export default class NfsImpostos {
  constructor(jsonObject = {}) {
    this.vlrLiquido = jsonObject.vlrLiquido;
    this.vVlrBruto = jsonObject.vVlrBruto;
    this.vlrTotalRetido = jsonObject.vlrTotalRetido;

    this.vlrBaseCalIssqn = jsonObject.vlrBaseCalIssqn;
    this.vlrBaseCalCsll = jsonObject.vlrBaseCalCsll;
    this.vlrBaseCalCofins = jsonObject.vlrBaseCalCofins;
    this.vlrBaseCalPis = jsonObject.vlrBaseCalPis;
    this.vlrtBaseCalIRPJ = jsonObject.vlrtBaseCalIRPJ;
    this.vlrBaseCalINSS = jsonObject.vlrBaseCalINSS;
    this.vlrBaseCalIRPJ = jsonObject.vlrBaseCalIRPJ;
    this.vlrBaseCalDeson = jsonObject.vlrBaseCalDeson;

    this.aliqCofins = jsonObject.aliqCofins;
    this.aliqPis = jsonObject.aliqPis;
    this.aliqCsllRetido = jsonObject.aliqCsllRetido;
    this.aliqCsllContador = jsonObject.aliqCsllContador;
    this.aliqIrpjRetido = jsonObject.aliqIrpjRetido;
    this.aliqIrpjContador = jsonObject.aliqIrpjContador;
    this.aliqDesonContador = jsonObject.aliqDesonContador;
    this.aliqIssqn = jsonObject.aliqIssqn;
    this.aliqInss = jsonObject.aliqInss;

    this.flgCofinsRetido = jsonObject.flgCofinsRetido;
    this.flgPisRetido = jsonObject.flgPisRetido;
    this.flgCsllRetido = jsonObject.flgCsllRetido;
    this.flgIssqnRetido = jsonObject.flgIssqnRetido;
    this.flgIssqnRetidoManual = jsonObject.flgIssqnRetidoManual;

    this.vlrIrpjRetido = jsonObject.vlrIrpjRetido;
    this.vlrCofins = jsonObject.vlrCofins;
    this.vlrCsllRetido = jsonObject.vlrCsllRetido;
    this.vlrCsllContador = jsonObject.vlrCsllContador;
    this.vlrInss = jsonObject.vlrInss;
    this.vlrIssqn = jsonObject.vlrIssqn;
    this.vlrIrpjContador = jsonObject.vlrIrpjContador;
    this.vlrDesonContador = jsonObject.vlrDesonContador;
    this.vlrPis = jsonObject.vlrPis;
    this.vlrOutrasRetencoes = jsonObject.vlrOutrasRetencoes;
    this.noMensagem = jsonObject.noMensagem;
  }

  toJson = () => JSON.stringify(this);
}
