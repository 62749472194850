export default class ProdutoSubGrupo {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoempresa = jsonObject.nrSeqGrupoempresa;
    this.nrSeqProdutoSubGrupo = jsonObject.nrSeqProdutoSubGrupo;
    this.noProdutoSubGrupo = jsonObject.noProdutoSubGrupo;
    this.nrSeqProdutoGrupo = jsonObject.nrSeqProdutoGrupo;
    this.cdProdutoSubGrupo = jsonObject.cdProdutoSubGrupo;
    this.noProdutoGrupo = jsonObject.noProdutoGrupo;
    this.vlrCusto = jsonObject.vlrCusto;
  }

  toJson = () => JSON.stringify(this);
}
