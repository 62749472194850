class AreaChamada {
  constructor(jsonObject = {}) {
    this.nrSeqAreaChamada = jsonObject.nrSeqAreaChamada;
    this.cdAreaChamada = jsonObject.cdAreaChamada;
    this.noAreaChamada = jsonObject.noAreaChamada;
    this.flgVc1 = jsonObject.flgVc1;
    this.flgVc2 = jsonObject.flgVc2;
    this.flgVc3 = jsonObject.flgVc3;
  }

  toJson = () => JSON.stringify(this);
}
export default AreaChamada;
