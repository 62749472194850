import UsuarioNotificacao from 'core/models/SEG/usuarioNotificacao.ts';
import api from '../api';

const baseURL = `/SEG/UsuarioNotificacao`;

export const getUsuarioNotificacaoList = async (filters: any): Promise<any> => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    notificacao: value?.map((json: any) => new UsuarioNotificacao(json)) ?? [],
  };
};

export const postSetVisualizada = async (data: any) => {
  const response = await api.post(`${baseURL}/SetVisualizada`, data);
  return response.data ?? {};
};
