// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import {
  faArrowCircleLeft,
  faAsterisk,
  faAt,
  faBackward,
  faBalanceScale,
  faBan,
  faBars,
  faBell,
  faBriefcase,
  faDownload,
  faExclamation,
  faBroom,
  faCalculator,
  faCamera,
  faCaretDown,
  faCashRegister,
  faChartBar,
  faChartLine,
  faCheck,
  faForward,
  faTruck,
  faFile,
  faFileInvoice,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faClipboardList,
  faCloudUploadAlt,
  faCode,
  faCogs,
  faColumns,
  faCopy,
  faDesktop,
  faDollarSign,
  faDolly,
  faEdit,
  faEnvelope,
  faEquals,
  faEraser,
  faEye,
  faEyeSlash,
  faExclamationTriangle,
  faFastBackward,
  faFastForward,
  faFlagCheckered,
  faFileInvoiceDollar,
  faFolderOpen,
  faGreaterThanEqual,
  faHandHoldingWater,
  faInfoCircle,
  faKey,
  faKeyboard,
  faLayerGroup,
  faLessThanEqual,
  faLifeRing,
  faList,
  faLightbulb,
  faMoneyCheck,
  faNotEqual,
  faPaperPlane,
  faPencilAlt,
  faPhone,
  faPlay,
  faPlus,
  faPrint,
  faProjectDiagram,
  faQuestion,
  faRandom,
  faSearch,
  faShare,
  faSms,
  faSnowman,
  faSlidersH,
  faSignal,
  faSignInAlt,
  faSignOutAlt,
  faShoePrints,
  faShoppingBasket,
  faSortAmountDown,
  faSortAmountUp,
  faStepBackward,
  faStepForward,
  faStoreAlt,
  faSyncAlt,
  faTasks,
  faTh,
  faThLarge,
  faThList,
  faTimes,
  faTrashAlt,
  faUndoAlt,
  faUser,
  faUsers,
  faUserSecret,
  faViruses,
  faWifi,
  faMinus,
  faExchangeAlt,
  faExclamationCircle,
  faAngleRight,
  faAngleDown,
  faAngleUp,
} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import {
  faCalendarAlt,
  faCommentAlt,
  faCommentDots,
  faCreditCard,
  faFileAlt,
  faFileExcel,
  faFilePdf,
  faFileWord,
  faFileCode,
  faImage,
  faSave,
  faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faAngleRight,
  faAngleDown,
  faAngleUp,
  faArrowCircleLeft,
  faAsterisk,
  faAt,
  faBackward,
  faBalanceScale,
  faBan,
  faBars,
  faBell,
  faBriefcase,
  faDownload,
  faExclamation,
  faExclamationCircle,
  faBroom,
  faCalculator,
  faCamera,
  faCalendarAlt,
  faCaretDown,
  faCashRegister,
  faChartBar,
  faChartLine,
  faCheck,
  faTruck,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faClipboardList,
  faCloudUploadAlt,
  faCode,
  faCogs,
  faColumns,
  faCopy,
  faCommentAlt,
  faCommentDots,
  faCreditCard,
  faDesktop,
  faDollarSign,
  faDolly,
  faEdit,
  faEnvelope,
  faEquals,
  faEraser,
  faFileInvoice,
  faEye,
  faEyeSlash,
  faExclamationTriangle,
  faFastBackward,
  faFastForward,
  faFlagCheckered,
  faFile,
  faFileAlt,
  faFileExcel,
  faFilePdf,
  faFileWord,
  faFileCode,
  faFileInvoiceDollar,
  faFolderOpen,
  faForward,
  faGreaterThanEqual,
  faHandHoldingWater,
  faImage,
  faInfoCircle,
  faKey,
  faKeyboard,
  faLayerGroup,
  faLessThanEqual,
  faLifeRing,
  faList,
  faLightbulb,
  faMoneyCheck,
  faNotEqual,
  faPaperPlane,
  faPencilAlt,
  faPhone,
  faPlay,
  faPlus,
  faPrint,
  faProjectDiagram,
  faQuestion,
  faRandom,
  faSave,
  faSearch,
  faSignal,
  faSignInAlt,
  faSignOutAlt,
  faSms,
  faSnowman,
  faShare,
  faShoePrints,
  faShoppingBasket,
  faSortAmountDown,
  faSortAmountUp,
  faStepBackward,
  faSlidersH,
  faStepForward,
  faStoreAlt,
  faSyncAlt,
  faTimesCircle,
  faTasks,
  faTh,
  faThLarge,
  faThList,
  faTimes,
  faTrashAlt,
  faUndoAlt,
  faUser,
  faUsers,
  faUserSecret,
  faViruses,
  faHandHoldingWater,
  faWhatsapp,
  faWifi,
  faMinus,
  faExchangeAlt
);

export const listIcons = () => {
  let icons = [];

  icons = icons.concat(
    Object.keys(library.definitions.fab).map((icon) => ({
      key: icon,
      value: `['fab', '${icon}']`,
    }))
  );

  icons = icons.concat(
    Object.keys(library.definitions.far).map((icon) => ({
      key: icon,
      value: `['far', '${icon}']`,
    }))
  );

  icons = icons.concat(
    Object.keys(library.definitions.fas).map((icon) => ({
      key: icon,
      value: icon,
    }))
  );

  return icons.sort((a, b) => a.key.localeCompare(b.key));
};
