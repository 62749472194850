import React, { useState, useEffect } from 'react';

import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { Button, Modal, Notification, Autocomplete } from 'ui/components';
import { getAlmoxarifadoAutoComplete } from 'core/services/EST/almoxarifado';
import {
  getProdutoAutoComplete,
  postMoverProduct,
  getProduto,
} from 'core/services/EST/produto';
import { getAlmoxarifadoLocalAutoCompleteComCaminho } from 'core/services/EST/almoxarifadoLocal';

export default function ModalMoverProduto({ onClose, show, nrseqproduto }) {
  const [message, setMessage] = useState(null);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (nrseqproduto > 0) {
        const { data: produto } = await getProduto(nrseqproduto);
        if (produto != null) {
          setData((prevData) => ({
            ...prevData,
            produtoOld: produto,
            nrSeqProdutoOld: produto.nrSeqProduto,
          }));
        }
      }
    };

    if (show) {
      fetchData();
    }
  }, [nrseqproduto, show]);

  const onSearchProdutoOld = async (e) => {
    if (nrseqproduto > 0) {
      const {
        status,
        message: msg,
        data: produtos,
      } = await getProdutoAutoComplete({
        noSearchField: e,
        nrSeqProduto: nrseqproduto,
      });
      if (msg) onSetMessage(status, msg);
      return produtos;
    }
    return [];
  };
  const onSearchProdutoNew = async (e) => {
    if (e.length < 3) {
      onSetMessage(ResponseStatus.Error, 'Digite ao menos 3 caracteres');
      return [];
    }
    const {
      status,
      message: msg,
      data: produtos,
    } = await getProdutoAutoComplete({
      noSearchField: e,
    });
    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const onSearchAlmoxarifado = async (e) => {
    if (data.nrSeqProdutoOld <= 0) {
      onSetMessage(ResponseStatus.Error, 'Escolha o produto no Mover De:');
      return [];
    }
    const {
      data: almoxarifados,
      status,
      message: msg,
    } = await getAlmoxarifadoAutoComplete({
      noAlmoxarifado: e,
      nrSeqProduto: data.nrSeqProdutoOld,
    });

    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return almoxarifados;
  };
  const onSearchAlmoxarifadoNew = async (e) => {
    if (data.nrSeqProdutoNew <= 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Escolha o novo produto no Mover para:'
      );
      return [];
    }

    const {
      data: almoxarifados,
      status,
      message: msg,
    } = await getAlmoxarifadoAutoComplete({
      noAlmoxarifado: e,
      nrSeqProduto: data.nrSeqProdutoNew,
    });
    if (almoxarifados.length <= 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Novo produto não possui almoxarifado'
      );
      return [];
    }

    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return almoxarifados;
  };
  const onSearchAlmoxarifadoLocalNew = async (e) => {
    if (data.nrSeqProdutoNew <= 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Escolha o novo produto no Mover para:'
      );
      return [];
    }
    if (data.nrSeqAlmoxarifadoNew <= 0) {
      onSetMessage(ResponseStatus.Error, 'Escolha o novo almoxarifado:');
      return [];
    }
    const {
      almoxarifados,
      status,
      message: msg,
    } = await getAlmoxarifadoLocalAutoCompleteComCaminho({
      noAlmoxarifado: e,
      nrSeqProduto: data.nrSeqProdutoNew,
      nrSeqAlmoxarifado: data.nrSeqAlmoxarifadoNew,
      flgItemProduto: true,
    });

    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return almoxarifados;
  };
  const onSearchAlmoxarifadoLocalOld = async (e) => {
    if (data.nrSeqProdutoOld <= 0) {
      onSetMessage(ResponseStatus.Error, 'Escolha o produto no Mover De:');
      return [];
    }
    if (data.nrSeqAlmoxarifado <= 0) {
      onSetMessage(ResponseStatus.Error, 'Escolha  almoxarifado a ser Movido:');
      return [];
    }
    const {
      almoxarifados,
      status,
      message: msg,
    } = await getAlmoxarifadoLocalAutoCompleteComCaminho({
      noAlmoxarifado: e,
      nrSeqProduto: data.nrSeqProdutoOld,
      nrSeqAlmoxarifado: data.nrSeqAlmoxarifado,
      flgItemProduto: true,
    });

    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    return almoxarifados;
  };
  const onMove = async () => {
    setLoading(true);
    const filtros = {
      nrSeqAlmoxarifado: data.nrSeqAlmoxarifado,
      nrSeqAlmoxarifadoNew: data.nrSeqAlmoxarifadoNew,
      nrSeqAlmoxarifadoLocal: data.nrSeqAlmoxarifadoLocal,
      nrSeqAlmoxarifadoLocalNew: data.nrSeqAlmoxarifadoLocalNew,
      nrSeqProdutoNew: data.nrSeqProdutoNew,
      nrSeqProdutoOld: data.nrSeqProdutoOld,
      noProdutoNew: data.produtoNew.noProduto,
      noProdutoOld: data.produtoOld.noProduto,
    };
    const { status, message: msg } = await postMoverProduct(filtros);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setData({});
    setLoading(false);
  };

  return (
    <Modal
      show={show}
      loading={loading}
      title='Mover produto'
      onClose={onClose}
      size={BootstrapSizes.Large}
    >
      <Modal.Body className='p-12 pt-4'>
        <div className='row mb-3'>
          <div className='col-6'>
            <Autocomplete
              label='Mover de:'
              searchDataSource={onSearchProdutoOld}
              selectedItem={data.produtoOld}
              onSelectItem={(produtoOld) => {
                setData({
                  ...data,
                  produtoOld,
                  nrSeqProdutoOld: produtoOld.nrSeqProduto,
                });
              }}
              dataSourceTextProperty='noProduto'
            />
          </div>
          <div className='col-6'>
            <Autocomplete
              label='Mover para:'
              searchDataSource={onSearchProdutoNew}
              selectedItem={data.produtoNew}
              onSelectItem={(produtoNew) => {
                setData({
                  ...data,
                  produtoNew,
                  nrSeqProdutoNew: produtoNew.nrSeqProduto,
                });
              }}
              dataSourceTextProperty='noProduto'
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-6'>
            <Autocomplete
              label='Almoxarifado do produto a ser movido:'
              searchDataSource={onSearchAlmoxarifado}
              selectedItem={data.almoxarifado}
              onSelectItem={(almoxarifado) => {
                setData({
                  ...data,
                  almoxarifado,
                  nrSeqAlmoxarifado: almoxarifado.nrSeqAlmoxarifado,
                });
              }}
              dataSourceTextProperty='noAlmoxarifado'
            />
          </div>
          <div className='col-6'>
            <Autocomplete
              label='Selecione o novo almoxarifado'
              searchDataSource={onSearchAlmoxarifadoNew}
              selectedItem={data.almoxarifadoNew}
              onSelectItem={(almoxarifadoNew) => {
                setData({
                  ...data,
                  almoxarifadoNew,
                  nrSeqAlmoxarifadoNew: almoxarifadoNew.nrSeqAlmoxarifado,
                });
              }}
              dataSourceTextProperty='noAlmoxarifado'
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-6'>
            <Autocomplete
              label='Local do produto a ser movido.'
              searchDataSource={onSearchAlmoxarifadoLocalOld}
              selectedItem={data.almoxarifadoLocal}
              onSelectItem={(almoxarifadoLocal) => {
                setData({
                  ...data,
                  almoxarifadoLocal,
                  nrSeqAlmoxarifadoLocal:
                    almoxarifadoLocal.nrSeqAlmoxarifadoLocal,
                });
              }}
              dataSourceTextProperty='noAlmoxarifadoLocal'
            />
          </div>
          <div className='col-6'>
            <Autocomplete
              label='Selecione o novo local'
              searchDataSource={onSearchAlmoxarifadoLocalNew}
              selectedItem={data.almoxarifadoLocalNew}
              onSelectItem={(almoxarifadoLocalNew) => {
                setData({
                  ...data,
                  almoxarifadoLocalNew,
                  nrSeqAlmoxarifadoLocalNew:
                    almoxarifadoLocalNew.nrSeqAlmoxarifadoLocal,
                });
              }}
              dataSourceTextProperty='noAlmoxarifadoLocal'
            />
          </div>
        </div>

        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Mover'
          theme={Theme.Success}
          onClick={() => {
            onMove();
          }}
        />
        <Button
          text='Sair'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            setData({});
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
