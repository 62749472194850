class GrupoTipoChamada {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoTipoChamada = jsonObject.nrSeqGrupoTipoChamada;
    this.cdGrupoTipoChamada = jsonObject.cdGrupoTipoChamada;
    this.noGrupoTipoChamada = jsonObject.noGrupoTipoChamada;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgPlano = jsonObject.flgPlano;
  }

  toJson = () => JSON.stringify(this);
}
export default GrupoTipoChamada;
