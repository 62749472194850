import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete, Button, Modal, GridView } from 'ui/components';
import { ResponseStatus, Theme, BootstrapSizes } from 'ui/Helpers/utils';
import { getEmpresasUsuario } from 'core/services/SEG/empresa';
import { changeAparelho } from 'core/services/TEL';

export default function TrocarEmpresaModal({
  linhasSelecionadas,
  show,
  onClose,
}) {
  const gridViewLinhas = useRef();
  const [data, setData] = useState({});
  const [messageState, setMessage] = useState(null);
  const columns = [
    { key: 'nrSeqAparelho', visible: false },
    { key: 'cdAparelho', title: 'Código' },
    { key: 'noModeloAparelho', title: 'Modelo' },
    { key: 'noMarcaAparelho', title: 'Marca' },
    { key: 'nrSerial', title: 'Serial' },
    { key: 'nrImei', title: 'Imei' },
    { key: 'noOperadora', title: 'Operadora' },
    { key: 'noPessoa', title: 'Empresa' },
  ];
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSave = async () => {
    const nrSeqAparelhos = linhasSelecionadas.map((e) => e.nrSeqAparelho);
    const obj = { nrSeqs: nrSeqAparelhos, nrSeqEmpresa: data.nrSeqEmpresa };
    const { status, message: msg } = await changeAparelho(obj);
    if (status === ResponseStatus.Success) {
      onClose();
    }
    onSetMessage(status, msg);
  };

  const onSearchEmpresa = async (e) => {
    const { empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });
    return empresas;
  };

  const searchEmpresas = async () => {
    const { status, message, empresas } = await getEmpresasUsuario({});
    onSetMessage(status, message);

    return empresas;
  };

  useEffect(() => {
    searchEmpresas();
    if (gridViewLinhas && gridViewLinhas.current) {
      if (linhasSelecionadas.length > 0)
        gridViewLinhas.current.setDataSource(linhasSelecionadas);
    }
  }, [linhasSelecionadas]);

  return (
    <Modal
      show={show}
      size={BootstrapSizes.Large}
      title='Trocar de Empresa'
      onClose={onClose}
      message={messageState}
    >
      <Modal.Body className='bg-light'>
        <div className='col-6'>
          <Autocomplete
            label='Empresa'
            searchDataSource={onSearchEmpresa}
            selectedItem={data.empresa}
            onSelectItem={(empresa) =>
              setData({
                ...data,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='row mt-3'>
          <div className='col'>
            <GridView
              ref={gridViewLinhas}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          theme={Theme.Primary}
          icon='save'
          text='Save'
          onClick={onSave}
        />
        <Button
          theme={Theme.Primary}
          icon='times'
          text='Fechar'
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
