import { GrupoIcmsEstado, GrupoIcmsMensagemEstado } from 'core/models/FIS';

export default class GrupoIcms {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.cdGrupoIcms = jsonObject.cdGrupoIcms;
    this.nrSeqGrupoIcms = jsonObject.nrSeqGrupoIcms;
    this.noGrupoIcms = jsonObject.noGrupoIcms;
    this.grupoIcmsEstados =
      jsonObject.grupoIcmsEstados?.map((el) => new GrupoIcmsEstado(el)) ?? [];

    this.grupoIcmsMensagemEstados =
      jsonObject.grupoIcmsMensagemEstados?.map(
        (el) => new GrupoIcmsMensagemEstado(el)
      ) ?? [];
  }

  toJson = () => JSON.stringify(this);
}
