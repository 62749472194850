import AgrupamentoFinanceiroContacor from './agrupamentoFinanceiroContacor';

export default class AgrupamentoFinanceiro {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqAgrupamentoFinanceiro = jsonObject.nrSeqAgrupamentoFinanceiro;
    this.noAgrupamentoFinanceiro = jsonObject.noAgrupamentoFinanceiro;
    this.flgDebito = jsonObject.flgDebito;
    this.flgCredito = jsonObject.flgCredito;
    this.nrOrdem = jsonObject.nrOrdem;
    this.agrupamentoFinanceiroContaCors = (
      jsonObject.agrupamentoFinanceiroContaCors ?? []
    ).map((vmex) => new AgrupamentoFinanceiroContacor(vmex));
  }

  toJson = () => JSON.stringify(this);
}
