import FormaPagamento from './formaPagamento';

export default class RecebimentoMassivo {
  constructor(jsonObject = {}) {
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqRecebimentoMassivo = jsonObject.nrSeqRecebimentoMassivo;
    this.dtCad = jsonObject.dtCad;
    this.vlrTotal = jsonObject.vlrTotal;
    this.nrIdentificado = jsonObject.nrIdentificado;
    this.vlrSaldoRecebido = jsonObject.vlrSaldoRecebido;
    this.vlrJuro = jsonObject.vlrJuro;
    this.vlrMulta = jsonObject.vlrMulta;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.obs = jsonObject.obs;
    this.empresa = jsonObject.empresa;
    this.itensRecebimentoMassivo = jsonObject.itensRecebimentoMassivo;
    this.tituloReceberS = jsonObject.tituloReceberS;
    this.dtRecebimento = jsonObject.dtRecebimento;
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
  }

  toJson = () => JSON.stringify(this);
}
