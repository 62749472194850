import React, { useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getAcertoMotoristaReceitaPagined,
  printAcertoMotoristaReceita,
  deleteAllAcertoMotoristaReceita,
  excelAcertoMotoristaReceita,
} from 'core/services/FIN/acertoMotoristaReceita';
import {
  Autocomplete,
  CSDSelPage,
  DatePicker,
  GridView,
  Panel,
} from 'ui/components';
import { getClienteAutoComplete } from 'core/services/FIN';
import { MaskTypes } from 'ui/Helpers/masks';

const columns = [
  {
    key: 'nrSeqAcertoMotoristaReceita',
    type: GridView.ColumnTypes.Checkbox,
  },

  {
    key: 'nrDocumento',
    title: 'Nr. Documento',
  },
  {
    key: 'dtReceita',
    title: 'Data',
    format: GridView.DataTypes.Date,
  },
  {
    key: 'vlrReceita',
    title: 'Valor',
    format: GridView.DataTypes.Decimal,
  },
  {
    key: 'tipoReceitaAcerto.noTipoReceitaAcerto',
    title: 'Tipo Receita',
  },
  {
    key: 'formaPagamento.noFormaPagamento',
    title: 'Forma Pagamento',
  },
  {
    key: 'cliente.noPessoa',
    title: 'Cliente',
    format: GridView.DataTypes.Text,
  },
  {
    key: 'enderecoRemetente.noCidadeEstado',
    title: 'Origem',
  },
  {
    key: 'enderecoDestino.noCidadeEstado',
    title: 'Destino',
  },
  {
    key: 'noObservacao',
    title: 'Obs',
  },
];

export default function AcertoMotoristaReceita({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getAcertoMotoristaReceitaPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'AcertoMotoristaReceita/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqAcertoMotoristaReceita = sourceRow.find(
      (e) => e.key === 'nrSeqAcertoMotoristaReceita'
    );
    onOpenMaintenance(nrSeqAcertoMotoristaReceita.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const value = await printAcertoMotoristaReceita(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDownloadExcel = async () => {
    setLoading(true);
    const value = await excelAcertoMotoristaReceita(filtros);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'AcertoMotoristaReceita.xls';
      downloadLink.click();
    }
    setLoading(false);
  };

  const onSearchCliente = async (e) => {
    const { clientes } = await getClienteAutoComplete({
      noPessoa: e,
      flgFiltrarEmpresa: true,
    });
    return clientes;
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllAcertoMotoristaReceita(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Acerto Motorista Receita'
      loading={loading}
      transaction={transaction}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      onDownloadExcel={onDownloadExcel}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <Panel>
        <Panel.Header
          icon={['fas', 'user']}
          title='Filtros'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col-6'>
              <Autocomplete
                label='Cliente'
                searchDataSource={onSearchCliente}
                selectedItem={filtros.cliente}
                nrseqTransactionToOpen='nrSeqPessoaCli'
                onSelectItem={(cliente) => {
                  setFiltros({
                    ...filtros,
                    cliente,
                    nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-2'>
              <DatePicker
                label='Receita De'
                text={filtros.dtReceitaDe}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtReceitaDe) =>
                  setFiltros({ ...filtros, dtReceitaDe })
                }
              />
            </div>
            <div className='col-2'>
              <DatePicker
                label='Receita Até'
                text={filtros.dtReceitaAte}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtReceitaAte) =>
                  setFiltros({ ...filtros, dtReceitaAte })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>

      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
