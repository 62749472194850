import React, { useState, useEffect } from 'react';

import { Autocomplete, Button, Modal, Notification } from 'ui/components';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';

export default function ModalAlterarPlacas(props) {
  const [messageState, setMessage] = useState(null);
  const [dataAlteraPlacas, setDataAlteraPlacas] = useState({});

  const { show, data } = props;

  useEffect(async () => {
    if (data !== null && data !== undefined) {
      setDataAlteraPlacas({
        nrSeqVeiculoComp: data.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: data.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: data.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: data.nrSeqVeiculoPrinc,
        veiculoComp: data.veiculoComp,
        veiculoCompB: data.veiculoCompB,
        veiculoCompC: data.veiculoCompC,
        veiculo: data.veiculo,
      });
    }
  }, [show]);

  useEffect(async () => {
    if (
      dataAlteraPlacas.veiculo !== null &&
      dataAlteraPlacas.veiculo !== undefined &&
      dataAlteraPlacas.veiculoComp !== null &&
      dataAlteraPlacas.veiculoComp !== undefined &&
      dataAlteraPlacas.veiculoCompB !== null &&
      dataAlteraPlacas.veiculoCompB !== undefined &&
      dataAlteraPlacas.veiculoCompC !== null &&
      dataAlteraPlacas.veiculoCompC !== undefined &&
      dataAlteraPlacas.veiculoCompC.noVeiculo !== null &&
      dataAlteraPlacas.veiculoCompC.noVeiculo !== undefined
    ) {
      const placaVin = `${dataAlteraPlacas.veiculo.noVeiculo} - ${dataAlteraPlacas.veiculoComp.noVeiculo} - ${dataAlteraPlacas.veiculoCompB.noVeiculo} - ${dataAlteraPlacas.veiculoCompC.noVeiculo} `;
      setDataAlteraPlacas({ ...dataAlteraPlacas, placasVinculadas: placaVin });
    } else if (
      dataAlteraPlacas.veiculo !== null &&
      dataAlteraPlacas.veiculo !== undefined &&
      dataAlteraPlacas.veiculoComp !== null &&
      dataAlteraPlacas.veiculoComp !== undefined &&
      dataAlteraPlacas.veiculoCompB !== null &&
      dataAlteraPlacas.veiculoCompB !== undefined &&
      dataAlteraPlacas.veiculoCompB.noVeiculo !== null &&
      dataAlteraPlacas.veiculoCompB.noVeiculo !== undefined
    ) {
      const placaVin = `${dataAlteraPlacas.veiculo.noVeiculo} - ${dataAlteraPlacas.veiculoComp.noVeiculo} - ${dataAlteraPlacas.veiculoCompB.noVeiculo} `;
      setDataAlteraPlacas({ ...dataAlteraPlacas, placasVinculadas: placaVin });
    } else if (
      dataAlteraPlacas.veiculo !== null &&
      dataAlteraPlacas.veiculo !== undefined &&
      dataAlteraPlacas.veiculoComp !== null &&
      dataAlteraPlacas.veiculoComp !== undefined &&
      dataAlteraPlacas.veiculoComp.noVeiculo !== null &&
      dataAlteraPlacas.veiculoComp.noVeiculo !== undefined
    ) {
      const placaVin = `${dataAlteraPlacas.veiculo.noVeiculo} - ${dataAlteraPlacas.veiculoComp.noVeiculo}`;
      setDataAlteraPlacas({ ...dataAlteraPlacas, placasVinculadas: placaVin });
    } else if (
      dataAlteraPlacas.veiculo !== null &&
      dataAlteraPlacas.veiculo !== undefined
    ) {
      const placaVin = `${dataAlteraPlacas.veiculo.noVeiculo} `;
      setDataAlteraPlacas({ ...dataAlteraPlacas, placasVinculadas: placaVin });
    }
  }, [
    dataAlteraPlacas.nrSeqVeiculoComp,
    dataAlteraPlacas.nrSeqVeiculoCompB,
    dataAlteraPlacas.nrSeqVeiculoCompC,
  ]);

  const onSearchVeiculoComp = async (e) => {
    const { message: msg, veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
    });
    if (msg);
    return veiculos;
  };
  const onSearchVeiculoCompB = async (e) => {
    const { message: msg, veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
    });
    if (msg);
    return veiculos;
  };
  const onSearchVeiculoCompC = async (e) => {
    const { message: msg, veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
    });
    if (msg);
    return veiculos;
  };

  const cancelar = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
    });
  };

  const onSend = async () => {
    props.onSave({ ...dataAlteraPlacas });
  };

  return (
    <Modal
      show={show}
      title='Alterar Placas'
      size={BootstrapSizes.Large}
      icon='money'
    >
      <Modal.Body className='p-4 pt-3'>
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col mb-3'>
            <Autocomplete
              label='Placa Princ'
              readOnly
              selectedItem={data?.veiculo}
              dataSourceTextProperty='noVeiculo'
            />
          </div>
          <div className='col mb-3'>
            <Autocomplete
              label='Placa Comp'
              searchDataSource={onSearchVeiculoComp}
              selectedItem={dataAlteraPlacas?.veiculoComp}
              onSelectItem={(veiculoComp) => {
                setDataAlteraPlacas({
                  ...dataAlteraPlacas,
                  veiculoComp,
                  nrSeqVeiculoComp: veiculoComp.nrSeqVeiculo,
                });
              }}
              dataSourceTextProperty='noVeiculo'
            />
          </div>

          <div className='col mb-3'>
            <Autocomplete
              label='Placa CompB'
              searchDataSource={onSearchVeiculoCompB}
              selectedItem={dataAlteraPlacas?.veiculoCompB}
              onSelectItem={(veiculoCompB) => {
                setDataAlteraPlacas({
                  ...dataAlteraPlacas,
                  veiculoCompB,
                  nrSeqVeiculoCompB: veiculoCompB.nrSeqVeiculo,
                });
              }}
              dataSourceTextProperty='noVeiculo'
            />
          </div>
          <div className='col mb-3'>
            <Autocomplete
              label='Placa CompC'
              searchDataSource={onSearchVeiculoCompC}
              selectedItem={dataAlteraPlacas?.veiculoCompC}
              onSelectItem={(veiculoCompC) => {
                setDataAlteraPlacas({
                  ...dataAlteraPlacas,
                  veiculoCompC,
                  nrSeqVeiculoCompC: veiculoCompC.nrSeqVeiculo,
                });
              }}
              dataSourceTextProperty='noVeiculo'
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-2 mt-3'>
            <Button
              size={BootstrapSizes.Medium}
              theme={Theme.Primary}
              template={Button.Templates.Default}
              onClick={onSend}
              text='Salvar'
            />
          </div>
          <div className='col-2 mt-3'>
            <Button
              theme={Theme.Danger}
              icon={['fas', 'times']}
              template={Button.Templates.Button}
              text='Sair'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
