import api from '../api';

const baseURL = '/ENE/ImportaFaturaEnergia';

export const importaFaturaEnergia = async (
  files: File[],
  document: string,
  nrDescontoOfertado: number,
  nrSeqPocTipoOrcamento: number,
  login: string,
  senha: string
) => {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append('files', file);
  });

  document = document
    .replaceAll('-', '')
    .replaceAll('.', '')
    .replaceAll('/', '');

  const response = await api.post(`${baseURL}`, formData, {
    params: {
      document,
      nrDescontoOfertado,
      nrSeqPocTipoOrcamento,
      login,
      senha,
    },
  });

  const { status, message } = response.data ?? {};

  return {
    status,
    message,
  };
};

export const buscaNrDocumentoFatura = async (
  files: File[]
): Promise<{ status: number; message: string; value: string }> => {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append('files', file);
  });

  const response = await api.post(
    `${baseURL}/BuscaNrDocumentoFatura`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    value,
  };
};
