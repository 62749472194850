import { getFormaPagamentoAutoComplete } from 'core/services/FIN';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import { PrintDateReference } from 'core/services/FIN/tituloReceber';
import React, { useEffect, useRef, useState } from 'react';

import {
  Autocomplete,
  Button,
  DatePicker,
  DropdownMulti,
  Modal,
  Notification,
  RadioButton,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';

export default function ModalImpressaoToledo(props) {
  const [messageState, setMessage] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});
  const dropDownFormaPagamento = useRef();
  const [filtros, setFiltros] = useState({
    tipoItemTituloReceber: 'recebimento',
    noRelatorio: 'PDF',
  });

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const { show } = props;

  const onSearchTipo = async (e) => {
    const { data: tituloReceberEspecie } = await getTituloEspecieAutoComplete({
      noEspecie: e,
    });

    return tituloReceberEspecie;
  };

  const onSearchFormaRecebimento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitRec: true,
    });

    return formasPagamento;
  };

  useEffect(() => {
    setFiltros({ tipoItemTituloReceber: 'recebimento', noRelatorio: 'PDF' });
    setSelectedItems({});
    if (
      dropDownFormaPagamento.current !== null &&
      dropDownFormaPagamento.current !== undefined
    ) {
      dropDownFormaPagamento.current.loadDataSource(onSearchFormaRecebimento);
    }
  }, [show]);

  const tipoTitulo = [
    {
      text: 'Data de Recebimento',
      value: 'recebimento',
    },
    {
      text: 'Data de Credito',
      value: 'credito',
    },
  ];

  const tipoImpressao = [
    {
      text: 'PDF',
      value: 'PDF',
    },
    {
      text: 'Excel',
      value: 'Excel',
    },
  ];

  const cancelar = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
    });
  };

  const onSend = async () => {
    if (
      filtros.dtVencimentoAt === null ||
      filtros.dtVencimentoAt === undefined
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel fazer a impressão sem selecionar uma data de vencimento'
      );
      return;
    }

    if (
      filtros.dtVencimentoDe === null ||
      filtros.dtVencimentoDe === undefined
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel fazer a impressão sem selecionar uma data de vencimento'
      );
      return;
    }

    if (filtros.dtRecebimento === null || filtros.dtRecebimento === undefined) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel fazer a impressão sem selecionar uma data de referência'
      );
      return;
    }

    const obj = { ...filtros };
    const value = await PrintDateReference(obj);

    if (value !== null && value !== undefined) {
      props.imprimirTitulos(value, obj.noRelatorio);
    }
  };

  return (
    <Modal show={show} title='Impressão' size={BootstrapSizes.Large}>
      <Modal.Body className='p-4 pt-3'>
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col-5'>
            <RadioButton
              label='Filtrar Por Itens Em Aberto'
              outline
              size={BootstrapSizes.Small}
              theme={Theme.Primary}
              selectedButton={filtros.tipoItemTituloReceber}
              buttons={tipoTitulo}
              onChange={(tipoItemTituloReceber) => {
                setFiltros({
                  ...filtros,
                  tipoItemTituloReceber,
                });
              }}
            />
          </div>
          <div className='col-3'>
            <DatePicker
              label='Vencimento De'
              text={filtros.dtVencimentoDe}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtVencimentoDe) =>
                setFiltros({ ...filtros, dtVencimentoDe })
              }
            />
          </div>
          <div className='col-3'>
            <DatePicker
              label='Vencimento Até'
              text={filtros.dtVencimentoAt}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtVencimentoAt) =>
                setFiltros({ ...filtros, dtVencimentoAt })
              }
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-3'>
            <DatePicker
              label='Data de referência'
              text={filtros.dtRecebimento}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtRecebimento) =>
                setFiltros({ ...filtros, dtRecebimento })
              }
            />
          </div>
          <div className='col-4'>
            <Autocomplete
              label='Tipo Receita'
              searchDataSource={onSearchTipo}
              selectedItem={filtros?.tituloEspecie}
              onSelectItem={(tituloEspecie) => {
                setFiltros({
                  ...filtros,
                  tituloEspecie,
                  nrSeqTituloEspecie: tituloEspecie.nrSeqTituloEspecie,
                });
              }}
              dataSourceTextProperty='noEspecie'
            />
          </div>
          <div className='col-4'>
            <DropdownMulti
              label='Forma De Recebimento'
              ref={dropDownFormaPagamento}
              dataSourcePropertyText='noFormaPagamento'
              dataSourcePropertyValue='nrSeqFormaPagamento'
              selectedItems={selectedItems?.formaPagamento ?? []}
              onSelectItem={(formaPagamento) => {
                setSelectedItems({ ...selectedItems, formaPagamento });
                setFiltros({
                  ...filtros,
                  nrSeqFormaPagamentos: formaPagamento?.map(
                    (p) => p.nrSeqFormaPagamento
                  ),
                });
              }}
            />
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-5'>
            <RadioButton
              label='Tipo Impressão'
              outline
              size={BootstrapSizes.Small}
              theme={Theme.Primary}
              selectedButton={filtros.noRelatorio}
              buttons={tipoImpressao}
              onChange={(noRelatorio) => {
                setFiltros({
                  ...filtros,
                  noRelatorio,
                });
              }}
            />
          </div>
          <div className='col-3 mt-3'>
            <Button
              size={BootstrapSizes.Medium}
              theme={Theme.Primary}
              template={Button.Templates.Default}
              onClick={onSend}
              text='Imprimir'
              icon='print'
            />
          </div>
          <div className='col-2 mt-3'>
            <Button
              theme={Theme.Danger}
              icon={['fas', 'times']}
              template={Button.Templates.Button}
              text='Sair'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
