import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  BootstrapSizes,
  MimeTypes,
  PageTypes,
  toBase64,
} from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  CSDManutPage,
  DatePicker,
  ToolbarButtons,
  FileUpload,
  GridView,
  Panel,
  Textarea,
  Textbox,
} from 'ui/components';
// @ts-expect-error
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces.ts';
import {
  ResponseStatus,
  Theme,
  ColumnDataTypes,
  // @ts-expect-error
} from 'ui/Helpers/enums.ts';
import { MaskTypes } from 'ui/Helpers/masks';
import { getAreaChamadoAutoComplete } from 'core/services/HELP/areaChamado';
import { getTipoChamadoAutoComplete } from 'core/services/HELP/tipoChamado';
import { getClienteAutoComplete } from 'core/services/FIN';
import {
  getUsuarioAutoComplete,
  getEmpresaAutoComplete,
} from 'core/services/SEG';
import { getTipoDemandaAutoComplete } from 'core/services/HELP';
import { getSituacaoChamadoAutoComplete } from 'core/services/HELP/situacaoChamado';
import { getChamadoClassificacaoAutoComplete } from 'core/services/HELP/chamadoClassificacao';
import { getPrioridadeChamadoAutoComplete } from 'core/services/HELP/prioridadeChamado';
import { saveChamadoDemandas, getChamado } from 'core/services/HELP/chamado';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
// @ts-expect-error
import ModalResponderEmail from './modalResponderEmail.tsx';

interface IShowModal {
  show: boolean;
  data?: Array<any>;
}

export default function ChamadoAutomaticoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  onOpenTransaction,
  findTransaction,
}: Page) {
  const formSubmit = useRef<any>();
  const movimentacaoGridView = useRef<any>();
  const documentoGridView = useRef<any>();
  const gridViewChamadosFilhos = useRef<any>();
  const gridViewChamadoDemanda = useRef<any>();

  const [data, setData] = useState<any>({});
  const [dataChamadoFilho, setDataChamadoFilho] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [importedFiles, setImportedFiles] = useState<Array<File>>([]);
  const [iteracao, setIteracao] = useState<string>('');
  const [dataDemanda, setDataDemanda] = useState<any>({});
  const [
    showModalResponderEmail,
    setShowModalResponderEmail,
  ] = useState<IShowModal>({ show: false });

  const onNew = (): void => {
    const today = new Date().toJSON().slice(0, 10);
    const usuarioOp = JSON.parse(String(sessionStorage.getItem('@dataAuth')))
      .user;

    setData({
      dtCad: today,
      dtPrazoConclusao: today,
      usuarioOp,
      nrSeqUsuarioOp: usuarioOp.nrSeqUsuario,
      descricao: '',
    });
    setIteracao('');
    setImportedFiles([]);
    setMessage(null);
    setDataDemanda({});
    setDataChamadoFilho({});
    gridViewChamadosFilhos?.current?.setDataSource([]);
    movimentacaoGridView?.current?.setDataSource([]);
    documentoGridView?.current?.setDataSource([]);
  };

  const onSetMessage = (status: number, messageShow: string): void => {
    if (messageShow)
      setMessage({
        message: messageShow,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchAreaChamado = async (e: string): Promise<Array<any>> => {
    const {
      areasChamado,
    }: { areasChamado: Array<any> } = await getAreaChamadoAutoComplete({
      noAreaChamado: e,
    });

    return areasChamado;
  };

  const onSearchTipoChamado = async (e: string): Promise<Array<any>> => {
    const {
      tiposChamado,
    }: { tiposChamado: Array<any> } = await getTipoChamadoAutoComplete({
      noTipoChamado: e,
      nrSeqAreaChamado: data.nrSeqAreaChamado,
    });

    return tiposChamado;
  };

  const onSearchSituacaoChamado = async (e: string): Promise<Array<any>> => {
    const {
      situacoesChamados,
    }: {
      situacoesChamados: Array<any>;
    } = await getSituacaoChamadoAutoComplete({ noSituacaoChamado: e });

    return situacoesChamados;
  };

  const onSearchPrioridadeChamado = async (): Promise<Array<any>> => {
    const {
      prioridadesChamados,
    }: {
      prioridadesChamados: Array<any>;
    } = await getPrioridadeChamadoAutoComplete();

    return prioridadesChamados;
  };

  const onSearchCliente = async (e: string): Promise<Array<any>> => {
    const { clientes }: { clientes: Array<any> } = await getClienteAutoComplete(
      {
        noPessoa: e,
      }
    );

    return clientes;
  };

  const onSearchUsuario = async (e: string): Promise<Array<any>> => {
    const {
      data: usuarios,
    }: { data: Array<any> } = await getUsuarioAutoComplete({
      noLogin: e,
    });

    return usuarios;
  };

  const onSearchChamadoClassificacao = async (): Promise<Array<any>> => {
    const {
      situacoesChamados,
    }: {
      situacoesChamados: Array<any>;
    } = await getChamadoClassificacaoAutoComplete();

    return situacoesChamados;
  };

  const onSearchEmpresa = async (e: string): Promise<Array<any>> => {
    const { empresas }: { empresas: Array<any> } = await getEmpresaAutoComplete(
      {
        noPessoa: e,
      }
    );

    return empresas;
  };

  const onClickRemoveFromGridView = async (e: any, datasource: any) => {
    const gridItem = datasource.find((el: any) => el === e);

    const list = datasource.filter((el: any) => el !== e);

    if (gridItem.status !== 'Inserir') {
      gridItem.status = 'Remover';

      list.push(gridItem);
    }

    if (documentoGridView && documentoGridView.current) {
      documentoGridView.current.setDataSource(list);
    }
  };

  const downloadDocument = (e: any): void => {
    const downloadLink = document.createElement('a');

    if (e.blDocumento !== null && e.blDocumento !== '') {
      const linkSource = `data:image/png;base64, ${e.blDocumento}`;
      downloadLink.href = linkSource;
      downloadLink.download = e.noArquivo;
    } else if (e.linkDownload !== null && e.linkDownload !== '') {
      downloadLink.href = e.linkDownload;
      downloadLink.target = '_blank';
    }

    downloadLink.click();
  };

  // const onClickDownload = async (e) => {
  //   const downloadLink = document.createElement('a');

  //   if (e.noImagem !== null && e.noImagem !== '') {
  //     const linkSource = `data:image/png;base64, ${e.noImagem}`;
  //     downloadLink.href = linkSource;
  //     downloadLink.download = e.noArquivo;
  //   } else if (e.linkDownload !== null && e.linkDownload !== '') {
  //     downloadLink.href = e.linkDownload;
  //     downloadLink.target = '_blank';
  //   }

  //   downloadLink.click();
  // };

  const documentoColumns: Array<GridviewColumns> = [
    { key: 'noDocumento', title: 'Documento' },
    {
      key: 'nrSeqChamadoDocumento',
      title: 'Download',
      // @ts-expect-error
      type: GridView.ColumnTypes.Button,
      onClick: (e: any) => downloadDocument(e),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqChamadoDocumento',
      title: 'Remover',
      // @ts-expect-error
      type: GridView.ColumnTypes.Button,
      onClick: (e: any, dataSource: any) =>
        onClickRemoveFromGridView(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsChamadosFilhos: Array<GridviewColumns> = [
    { key: 'nrSeqChamado', title: 'Código' },
    { key: 'areaChamado.noAreaChamado', title: 'Área' },
    { key: 'tipoChamado.noTipoChamado', title: 'Tipo Chamado' },
    {
      key: 'chamadoClassificacao.noChamadoClassificacao',
      title: 'Classificação',
    },
    { key: 'prioridadeChamado.noPrioridade', title: 'Prioridade' },
    { key: 'situacaoChamado.noSituacaoChamado', title: 'Status' },
    { key: 'dtPrazoConclusao', title: 'Prazo', format: ColumnDataTypes.Date },
    { key: 'usuarioOp.noPessoa', title: 'Responsável' },
  ];

  const columnsMovimentacoes: Array<GridviewColumns> = [
    { key: 'nrSeqChamadoMovimentacao', title: 'Código' },
    { key: 'descricao', title: 'Descrição' },
    {
      key: 'dtMovimentacao',
      title: 'Data Movimentação',
      format: ColumnDataTypes.Date,
    },
    { key: 'usuario.noPessoa', title: 'Responsável' },
  ];

  const columnsChamadoDemanda: Array<GridviewColumns> = [
    { key: 'nrSeqDemanda', title: 'Contrato Demanda' },
    { key: 'tipoDemanda.noTipoDemanda', title: 'Tipo Demanda' },
    { key: 'statusDemanda.noStatusDemanda', title: 'Status' },
  ];

  const onRowDoubleClickDemanda = (sourceRow: any) => {
    const nrSeqDemanda = sourceRow.find((e: any) => e.key === 'nrSeqDemanda');

    const demandaTransaction = findTransaction(TransacationCodes.Demanda);
    demandaTransaction.registryKey = nrSeqDemanda.value;

    onOpenTransaction(demandaTransaction, true, undefined, undefined, true);
  };

  const load = useCallback(
    async (nrSeqChamado = null) => {
      if (nrSeqChamado !== null) {
        setLoading(true);

        onNew();

        const obj: any = await getChamado(nrSeqChamado);

        setData(obj);

        documentoGridView?.current?.setDataSource(obj.chamadoDocumento ?? []);

        movimentacaoGridView?.current?.setDataSource(
          obj.chamadoMovimentacao ?? []
        );

        gridViewChamadosFilhos?.current?.setDataSource(
          obj.chamadosFilhos ?? []
        );

        gridViewChamadoDemanda?.current?.setDataSource(
          obj.chamadoDemanda ?? []
        );

        setLoading(false);
      } else if (Number.isInteger(registryKey)) {
        setLoading(true);

        onNew();

        const obj: any = await getChamado(registryKey);

        setData(obj);

        setDataChamadoFilho({});

        setIteracao('');

        documentoGridView?.current?.setDataSource(obj.chamadoDocumento ?? []);

        movimentacaoGridView?.current?.setDataSource(
          obj.chamadoMovimentacao ?? []
        );

        gridViewChamadosFilhos?.current?.setDataSource(
          obj.chamadosFilhos ?? []
        );

        const demandas = obj.chamadoDemanda?.map((e: any) => e.demanda) ?? [];

        gridViewChamadoDemanda?.current?.setDataSource(demandas);

        setLoading(false);
      } else {
        onNew();
      }
      setMessage(null);
    },
    [registryKey]
  );

  useEffect((): void => {
    load();
  }, [load, registryKey, reload]);

  const onRowDoubleClick = (sourceRow: Array<any>): void => {
    const nrSeqChamado = sourceRow.find((e) => e.key === 'nrSeqChamado');

    if (nrSeqChamado !== undefined) {
      load(nrSeqChamado.value);
    }
  };

  const onAddDocumento = async (): Promise<void> => {
    let noDocBase64 = await toBase64(importedFiles[0]);
    noDocBase64 = String(noDocBase64).substring(
      String(noDocBase64).indexOf(',') + 1
    );

    const obj = {
      noDocumento: importedFiles[0].name,
      noTipoDocumento: importedFiles[0].type,
      nrTamanhoEmBytes: importedFiles[0].size,
      blDocumento: noDocBase64,
      status: 'Inserir',
    };

    const list = documentoGridView.current.getDataSource();

    if (list.length > 4) {
      setMessage({
        message: 'Limite de 5 documentos alcançado.',
        theme: Theme.Warning,
      });

      return;
    }

    // @ts-expect-error
    obj.status = GridView.EnumStatus.Inserir;

    list.push(obj);

    if (documentoGridView && documentoGridView.current) {
      documentoGridView.current.setDataSource(list);
    }
  };

  useEffect(() => {
    if (importedFiles.length > 0) {
      onAddDocumento();

      setImportedFiles([]);
    }
  }, [importedFiles]);

  const onAddChamadoFilho = (): void => {
    const {
      nrSeqChamado,
      nrSeqEmpresa,
      empresa,
      nrSeqPessoaCli,
      cliente,
      contato,
      noEmailContato,
    } = data;

    const chamadoFilho = {
      ...dataChamadoFilho,
      nrSeqChamadoPai: nrSeqChamado,
      nrSeqEmpresa,
      empresa,
      nrSeqPessoaCli,
      cliente,
      contato,
      noEmailContato,
      status: 'Inserir',
    };

    const dataSource = gridViewChamadosFilhos?.current?.getDataSource() ?? [];

    gridViewChamadosFilhos?.current?.setDataSource([
      ...dataSource,
      chamadoFilho,
    ]);
  };

  const validateChamadoToSave = (): boolean => {
    if (
      !data.nrSeqTipoChamado ||
      !data.nrSeqEmpresa ||
      !data.nrSeqPessoaCli ||
      !data.nrSeqPrioridadeChamado ||
      !data.nrSeqSituacaoChamado ||
      !data.nrSeqChamadoClassificacao ||
      !data.dtPrazoConclusao ||
      data.dtPrazoConclusao === ''
    ) {
      return false;
    }

    return true;
  };

  const onSave = async (): Promise<boolean> => {
    setLoading(true);

    if (!validateChamadoToSave()) {
      onSetMessage(ResponseStatus.Error, 'Preencha os campos obrigatório');

      setLoading(false);

      return false;
    }

    const obj = { ...data };

    const chamadosFilhos =
      gridViewChamadosFilhos?.current?.getDataSource() ?? [];

    obj.chamadosFilhos = chamadosFilhos.filter(
      (e: any) => e.status === 'Inserir'
    );

    const movimentocoes = movimentacaoGridView?.current?.getDataSource() ?? [];

    obj.chamadoMovimentacao = movimentocoes.filter(
      (e: any) => e.status === 'Inserir'
    );

    const docs = documentoGridView?.current?.getDataSource() ?? [];

    obj.chamadoDocumento = docs.filter((e: any) => e.status === 'Inserir');

    const { value, status, message: msg } = await saveChamadoDemandas(obj);

    onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      setData(value);

      if (value.nrSeqChamadoPai == null) {
        gridViewChamadosFilhos?.current?.setDataSource(
          value.chamadosFilhos ?? []
        );
      }

      movimentacaoGridView?.current?.setDataSource(
        value.chamadoMovimentacao ?? []
      );

      documentoGridView?.current?.setDataSource(value.chamadoDocumento ?? []);

      setLoading(false);

      return true;
    }

    setLoading(false);

    return false;
  };

  const onAddMovimentacao = (): void => {
    const dataSource = movimentacaoGridView?.current?.getDataSource() ?? [];
    const today = new Date().toJSON().slice(0, 10);
    const { user } = JSON.parse(String(sessionStorage.getItem('@dataAuth')));

    const obj = {
      descricao: iteracao,
      nrSeqUsuario: user.nrSeqUser,
      usuario: user,
      dtMovimentacao: today,
      nrSeqChamado: data.nrSeqChamado,
      nrSeqSituacaoChamado: data.nrSeqSituacaoChamado,
      dtFimMovimentacao: today,
      status: 'Inserir',
    };

    dataSource.push(obj);

    movimentacaoGridView.current.setDataSource(dataSource);

    setIteracao('');
  };

  const onOpenDemanda = async (): Promise<void> => {
    setLoading(true);

    if (data.nrSeqChamado && data.cdChamado) {
      if (dataDemanda.nrSeqTipoDemanda) {
        const obj = {
          ...dataDemanda,
          nrSeqChamado: data.nrSeqChamado,
          nrSeqPessoaCli: data.nrSeqPessoaCli,
          cliente: data.cliente,
          nrSeqEmpresa: data.nrSeqEmpresa,
          empresa: data.empresa,
        };

        const demandaTransaction = await findTransaction(
          TransacationCodes.Demanda
        );

        demandaTransaction.registryKey = obj;

        onOpenTransaction(demandaTransaction, true, undefined, undefined, true);

        setDataDemanda({});
      } else {
        onSetMessage(ResponseStatus.Error, 'Selecione o Tipo Demanda');
      }
    }

    setLoading(false);
  };

  const onSearchTipoDemanda = async (e: string) => {
    const { tiposDemanda } = await getTipoDemandaAutoComplete({
      noTipoDemanda: e,
      flgAtivo: true,
    });

    return tiposDemanda;
  };

  const onOpenChamadoPai = () => {
    if (data.nrSeqChamadoPai != null) {
      load(data.nrSeqChamadoPai);
    }
  };

  useEffect(() => {
    if (showModalResponderEmail.data) {
      if (showModalResponderEmail.data.length > 0) {
        movimentacaoGridView?.current?.setDataSource(
          showModalResponderEmail.data
        );
      }
    }
  }, [showModalResponderEmail.data]);

  const { id: idSelecao } = PageTypes.Selection;
  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Chamado'
      loading={loading}
      message={message}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={onSave}
      // onDelete={onDelete}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {/* @ts-expect-error */}
      <ToolbarButtons>
        {data.nrSeqChamadoPai && (
          <ToolbarButtons.Button
            text='Abrir Chamado de Origem'
            onClick={() => onOpenChamadoPai()}
          />
        )}
        {data.nrSeqChamado && (
          <ToolbarButtons.Button
            text='Responder Email'
            onClick={() => setShowModalResponderEmail({ show: true })}
          />
        )}
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col-1'>
          {/* @ts-expect-error */}
          <Textbox label='Código' readOnly text={data?.cdChamado} />
        </div>
        <div className='col'>
          {/* @ts-expect-error */}
          <Textbox
            label='Título Chamado'
            text={data.noTituloChamado}
            readOnly={!data.flgPermiteEditar}
            onChangedValue={(noTituloChamado: string) => {
              setData({ ...data, noTituloChamado });
            }}
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Área'
            enabled={data.flgPermiteEditar}
            searchDataSource={onSearchAreaChamado}
            selectedItem={data.areaChamado}
            onSelectItem={(areaChamado: any) => {
              setData({
                ...data,
                areaChamado,
                nrSeqAreaChamado: areaChamado.nrSeqAreaChamado,
                nrSeqTipoChamado: undefined,
                tipoChamado: undefined,
              });
            }}
            dataSourceTextProperty='noAreaChamado'
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Tipo de Chamado'
            required
            enabled={
              data.flgPermiteEditar &&
              data.areaChamado?.nrSeqAreaChamado !== null &&
              data.areaChamado?.nrSeqAreaChamado !== undefined
            }
            searchDataSource={onSearchTipoChamado}
            selectedItem={data.tipoChamado}
            onSelectItem={(tipoChamado: any) => {
              setData({
                ...data,
                tipoChamado,
                nrSeqTipoChamado: tipoChamado.nrSeqTipoChamado,
              });
            }}
            dataSourceTextProperty='noTipoChamado'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <Textarea
            label='Descrição'
            maxLength={4000}
            rows={8}
            readOnly={!data.flgPermiteEditar}
            text={data.descricao}
            onChangedValue={(descricao: string) =>
              setData({ ...data, descricao })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-6'>
          <Autocomplete
            label='Empresa'
            required
            enabled={data.flgPermiteEditar}
            searchDataSource={onSearchEmpresa}
            selectedItem={data.empresa}
            onSelectItem={(empresa: any) =>
              setData({
                ...data,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-6'>
          <Autocomplete
            label='Cliente'
            required
            enabled={data.flgPermiteEditar}
            searchDataSource={onSearchCliente}
            selectedItem={data.cliente}
            onSelectItem={(cliente: any) => {
              setData({
                ...data,
                cliente,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                noEmailContato:
                  data.nrSeqChamado &&
                  (data.flgImportadoEmail || data.flgImportadoWhatsApp)
                    ? data.noEmailContato
                    : cliente.email,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Data de abertura'
            readOnly
            text={data.dtAbertura}
            onChange={(dtAbertura: string) => setData({ ...data, dtAbertura })}
          />
        </div>
        <div className='col-2'>
          <Autocomplete
            label='Prioridade'
            required
            enabled={data.flgPermiteEditar}
            searchDataSource={onSearchPrioridadeChamado}
            selectedItem={data.prioridadeChamado}
            onSelectItem={(prioridadeChamado: any) =>
              setData({
                ...data,
                prioridadeChamado,
                nrSeqPrioridadeChamado:
                  prioridadeChamado.nrSeqPrioridadeChamado,
              })
            }
            dataSourceTextProperty='noPrioridade'
          />
        </div>
        <div className='col-2'>
          <Autocomplete
            label='Status'
            searchDataSource={onSearchSituacaoChamado}
            selectedItem={data.situacaoChamado}
            onSelectItem={(situacaoChamado: any) => {
              setData({
                ...data,
                situacaoChamado,
                nrSeqSituacaoChamado: situacaoChamado.nrSeqSituacaoChamado,
              });
            }}
            dataSourceTextProperty='noSituacaoChamado'
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Prazo de conclusão'
            required
            readOnly={!data.flgPermiteEditar}
            text={data.dtPrazoConclusao}
            onChange={(dtPrazoConclusao: string) =>
              setData({
                ...data,
                dtPrazoConclusao,
              })
            }
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Responsável'
            enabled={data.flgPermiteEditar}
            searchDataSource={onSearchUsuario}
            selectedItem={data.usuarioOp}
            onSelectItem={(usuarioOp: any) =>
              setData({
                ...data,
                usuarioOp,
                nrSeqUsuarioOp: usuarioOp.nrSeqUsuario,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          {/* @ts-expect-error */}
          <Textbox
            label='Contato'
            maxLength={80}
            readOnly={!data.flgPermiteEditar}
            text={data.noContato}
            onChangedValue={(noContato: string) =>
              setData({ ...data, noContato })
            }
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Classificação do chamado'
            required
            enabled={data.flgPermiteEditar}
            searchDataSource={onSearchChamadoClassificacao}
            selectedItem={data.chamadoClassificacao}
            onSelectItem={(chamadoClassificacao: any) =>
              setData({
                ...data,
                chamadoClassificacao,
                nrSeqChamadoClassificacao:
                  chamadoClassificacao.nrSeqChamadoClassificacao,
              })
            }
            dataSourceTextProperty='noChamadoClassificacao'
          />
        </div>
        <div className='col-4'>
          {/* @ts-expect-error */}
          <Textbox
            label='E-mail Contato'
            maxLength={200}
            readOnly={!data.flgPermiteEditar}
            text={data.noEmailContato}
            onChangedValue={(noEmailContato: string) =>
              setData({ ...data, noEmailContato })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        {/* @ts-expect-error */}
        <Panel className='mb-3'>
          {/* @ts-expect-error */}
          <Panel.Header title='Documentos' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-6'>
                <GridView
                  ref={documentoGridView}
                  // @ts-expect-error
                  dataColumns={documentoColumns}
                  showSelectSizes={false}
                  showPagination={false}
                />
              </div>
              <div className='col-5'>
                <FileUpload
                  files={importedFiles}
                  onChange={(files: Array<File>) => {
                    setImportedFiles(files);
                  }}
                  visible={data.flgPermiteEditar}
                  allowedMimeTypes={[
                    MimeTypes.Types.PDF,
                    MimeTypes.Types.Image,
                    MimeTypes.Types.Word,
                    MimeTypes.Types.Txt,
                    MimeTypes.Types.Excel,
                  ]}
                  maxFiles={5}
                  dropzone
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>

      {data.nrSeqChamado && !data.nrSeqChamadoPai && (
        <div className='row mb-3'>
          {/* @ts-expect-error */}
          <Panel className='mb-3'>
            {/* @ts-expect-error */}
            <Panel.Header title='Chamados Filhos' theme={Theme.Primary} />
            <Panel.Body>
              {data.flgPermiteEditar && (
                <>
                  <div className='row mb-3'>
                    <div className='col'>
                      <Autocomplete
                        label='Area'
                        searchDataSource={onSearchAreaChamado}
                        selectedItem={dataChamadoFilho.areaChamado}
                        onSelectItem={(areaChamado: any) => {
                          setDataChamadoFilho({
                            ...dataChamadoFilho,
                            areaChamado,
                            nrSeqAreaChamado: areaChamado.nrSeqAreaChamado,
                            nrSeqTipoChamado: undefined,
                            tipoChamado: undefined,
                          });
                        }}
                        dataSourceTextProperty='noAreaChamado'
                      />
                    </div>
                    <div className='col'>
                      <Autocomplete
                        label='Tipo de chamado'
                        searchDataSource={onSearchTipoChamado}
                        enabled={
                          dataChamadoFilho.nrSeqAreaChamado !== null &&
                          dataChamadoFilho.nrSeqAreaChamado !== undefined
                        }
                        selectedItem={dataChamadoFilho.tipoChamado}
                        onSelectItem={(tipoChamado: any) => {
                          setDataChamadoFilho({
                            ...dataChamadoFilho,
                            tipoChamado,
                            nrSeqTipoChamado: tipoChamado.nrSeqTipoChamado,
                          });
                        }}
                        dataSourceTextProperty='noTipoChamado'
                      />
                    </div>
                    <div className='col'>
                      <Autocomplete
                        label='Classificação do chamado'
                        searchDataSource={onSearchChamadoClassificacao}
                        selectedItem={dataChamadoFilho.chamadoClassificacao}
                        onSelectItem={(chamadoClassificacao: any) =>
                          setDataChamadoFilho({
                            ...dataChamadoFilho,
                            chamadoClassificacao,
                            nrSeqChamadoClassificacao:
                              chamadoClassificacao.nrSeqChamadoClassificacao,
                          })
                        }
                        dataSourceTextProperty='noChamadoClassificacao'
                      />
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col'>
                      {/* @ts-expect-error */}
                      <Textarea
                        label='Descrição'
                        maxLength={4000}
                        rows={4}
                        text={dataChamadoFilho.descricao}
                        onChangedValue={(descricao: string) =>
                          setDataChamadoFilho({
                            ...dataChamadoFilho,
                            descricao,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-2'>
                      <Autocomplete
                        label='Prioridade'
                        searchDataSource={onSearchPrioridadeChamado}
                        selectedItem={dataChamadoFilho.prioridadeChamado}
                        onSelectItem={(prioridadeChamado: any) =>
                          setDataChamadoFilho({
                            ...dataChamadoFilho,
                            prioridadeChamado,
                            nrSeqPrioridadeChamado:
                              prioridadeChamado.nrSeqPrioridadeChamado,
                          })
                        }
                        dataSourceTextProperty='noPrioridade'
                      />
                    </div>
                    <div className='col-2'>
                      <Autocomplete
                        label='Status'
                        searchDataSource={onSearchSituacaoChamado}
                        selectedItem={dataChamadoFilho.situacaoChamado}
                        onSelectItem={(situacaoChamado: any) => {
                          setDataChamadoFilho({
                            ...dataChamadoFilho,
                            situacaoChamado,
                            nrSeqSituacaoChamado:
                              situacaoChamado.nrSeqSituacaoChamado,
                          });
                        }}
                        dataSourceTextProperty='noSituacaoChamado'
                      />
                    </div>
                    <div className='col-2'>
                      <DatePicker
                        label='Prazo de Conclusão'
                        text={dataChamadoFilho.dtPrazoConclusao}
                        onChange={(dtPrazoConclusao: string) =>
                          setDataChamadoFilho({
                            ...dataChamadoFilho,
                            dtPrazoConclusao,
                          })
                        }
                      />
                    </div>
                    <div className='col'>
                      <Autocomplete
                        label='Responsável'
                        searchDataSource={onSearchUsuario}
                        selectedItem={dataChamadoFilho.usuarioOp}
                        onSelectItem={(usuarioOp: any) =>
                          setDataChamadoFilho({
                            ...dataChamadoFilho,
                            usuarioOp,
                            nrSeqUsuarioOp: usuarioOp.nrSeqUsuario,
                          })
                        }
                        dataSourceTextProperty='noPessoa'
                      />
                    </div>
                    <div className='col d-flex align-items-end'>
                      <Button
                        text='Adicionar Chamado Filho'
                        outline
                        icon='plus'
                        size={BootstrapSizes.Large}
                        theme={Theme.Success}
                        onClick={() => {
                          onAddChamadoFilho();
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className='row mb-3'>
                <div className='col'>
                  <GridView
                    ref={gridViewChamadosFilhos}
                    // @ts-expect-error
                    dataColumns={columnsChamadosFilhos}
                    onRowDoubleClick={onRowDoubleClick}
                    showSelectSizes={false}
                    showPagination={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      )}
      {data.nrSeqChamado && (
        <div className='row mb-3'>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header title='Demandas Vinculadas' theme={Theme.Primary} />
            <Panel.Body>
              {data.flgPermiteEditar && (
                <div className='row mb-3'>
                  <div className='col-3'>
                    <Autocomplete
                      label='Tipo de Demanda'
                      searchDataSource={onSearchTipoDemanda}
                      selectedItem={dataDemanda.tipoDemanda}
                      onSelectItem={(tipoDemanda: any) => {
                        if ('nrSeqTipoDemanda' in tipoDemanda) {
                          setDataDemanda({
                            ...dataDemanda,
                            nrSeqTipoDemanda: tipoDemanda.nrSeqTipoDemanda,
                            tipoDemanda,
                          });
                        } else {
                          setDataDemanda({});
                        }
                      }}
                      dataSourceTextProperty='noTipoDemanda'
                    />
                  </div>
                  {dataDemanda.tipoDemanda &&
                    !dataDemanda.tipoDemanda?.flgGeraMassivo && (
                      <div className='col'>
                        {/* @ts-expect-error */}
                        <Textbox
                          label='Número da Linha'
                          mask={MaskTypes.CellPhone}
                          text={dataDemanda.nrPrefixoLinha}
                          onChangedValue={(nrPrefixoLinha: string) => {
                            setDataDemanda({
                              ...dataDemanda,
                              nrPrefixoLinha,
                            });
                          }}
                          onBlur={(param1: any, param2: string) => {
                            if (param2 && param2.length < 11) {
                              onSetMessage(1, 'Informe o Prefixo.');
                            }
                          }}
                        />
                      </div>
                    )}
                  <div className='col d-flex align-items-end'>
                    <Button
                      text='Abrir Demanda'
                      outline
                      size={BootstrapSizes.Large}
                      theme={Theme.Success}
                      onClick={() => onOpenDemanda()}
                    />
                  </div>
                </div>
              )}
              <div className='row mb-3'>
                <GridView
                  ref={gridViewChamadoDemanda}
                  // @ts-expect-error
                  dataColumns={columnsChamadoDemanda}
                  onRowDoubleClick={onRowDoubleClickDemanda}
                  showSelectSizes={false}
                  showPagination={false}
                />
              </div>
            </Panel.Body>
          </Panel>
        </div>
      )}
      {data.nrSeqChamado && (
        // @ts-expect-error
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header title='Movimentações' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col'>
                {/* @ts-expect-error */}
                <Textarea
                  label='Iteração no Chamado'
                  maxLength={4000}
                  rows={2}
                  text={iteracao}
                  onChangedValue={(descricao: string) => setIteracao(descricao)}
                />
              </div>
              <div className='col-2 d-flex align-items-end'>
                <Button
                  text='Adicionar Iteração'
                  outline
                  icon='plus'
                  size={BootstrapSizes.Large}
                  theme={Theme.Success}
                  onClick={() => {
                    onAddMovimentacao();
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <GridView
                ref={movimentacaoGridView}
                // @ts-expect-error
                dataColumns={columnsMovimentacoes}
                showSelectSizes={false}
                showPagination={false}
              />
            </div>
          </Panel.Body>
        </Panel>
      )}

      <ModalResponderEmail
        show={showModalResponderEmail.show}
        data={data}
        onClose={() =>
          setShowModalResponderEmail({
            ...showModalResponderEmail,
            show: false,
          })
        }
        onSetMessage={(status: number, msg: string) =>
          onSetMessage(status, msg)
        }
        setModalData={(e: Array<any>) =>
          setShowModalResponderEmail({ ...showModalResponderEmail, data: e })
        }
      />
    </CSDManutPage>
  );
}
