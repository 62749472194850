import { Cliente } from 'core/models/FIN';
import { PocOrcamento } from 'core/models/POC';
import NfNfv from 'core/models/VEN/nfNfv';
import { TipoNfvAvulsa, NfvAvulsaItem } from 'core/models/VEN';
import { Cfo } from 'core/models/FIS';
import { Empresa, Estado } from 'core/models/SEG';
import { TipoFrete, Transportadora, Veiculo } from 'core/models/FRO';
import Nfv from 'core/models/VEN/nfv';
import NfvChaveNfe from './nfvChaveNfe';
import OrdemServico from '../FIN/ordemServico';

export default class NfvAvulsa {
  nrSeqNfvAvulsa: number;

  nrSeqUsuario: number;

  nrSeqEmpresa: number;

  nrNfvAvulsa: number;

  dtNfvAvulsa: Date;

  dtCad: Date;

  dtCancel: Date;

  dtEmis: Date;

  vlrNfvAvulsa: number;

  vlrDesc: number;

  vlrFrete: number;

  nrSeqTipoFrete: number;

  nrSeqPessoaCli: number;

  nrSeqPessoaVen: number;

  nrSeqAlmoxarifado: number;

  nrSeqNfvAvulsaPai: number;

  nrSeqVeiculo: number;

  qtdeFrete: number;

  noEspecieFrete: string;

  pesoBrutoFrete: number;

  pesoLiquidoFrete: number;

  nrSeqEndEntrega: number;

  vlrComissao: number;

  nrSeqPessoaTran: number;

  nrSeqPessoaTrar: number;

  perComissao: number;

  nrSeqCfo: number;

  nrSeqPocOrcamento: number;

  obsNfv: string;

  nrSeqTipoVenda: number;

  dtVenda: Date;

  nrSeqTipoNfvAvulsa: number;

  nrSeqEmpresaDest: number;

  dtSaida: Date;

  dtSaidaString: string;

  placa: string;

  flgIssqnCidadeOrigem: boolean;

  nrSeqEstadoVeiculo: number;

  rntrc: string;

  vlrDespesas: number;

  vlrTotOperacao: number;

  tipoPagamentoOperacao: number;

  qtdeItNfvTotal: number;

  cliente: Cliente;

  empresa: Empresa;

  tipoNfvAvulsa: TipoNfvAvulsa;

  nfv: Nfv;

  itens: Array<NfvAvulsaItem>;

  cfo: Cfo;

  tipoFrete: TipoFrete;

  transportadora: Transportadora;

  transportadoraRedespacho: Transportadora;

  estadoVeiculo: Estado;

  orcamento: PocOrcamento;

  veiculo: Veiculo;

  nfvChaveNfe: NfvChaveNfe;

  noTipoDevolucao: string;

  noAutoComplete: String;

  ordemServico: OrdemServico;

  notas: Array<NfNfv>;

  nrSeqOrdemServico: number;

  flgVeiculoCadastrado: boolean;

  status: String;

  constructor(jsonObject: NfvAvulsa) {
    this.nrSeqNfvAvulsa = jsonObject.nrSeqNfvAvulsa;
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrNfvAvulsa = jsonObject.nrNfvAvulsa;
    this.dtNfvAvulsa = jsonObject.dtNfvAvulsa;
    this.dtCad = jsonObject.dtCad;
    this.dtCancel = jsonObject.dtCancel;
    this.dtEmis = jsonObject.dtEmis;
    this.vlrNfvAvulsa = jsonObject.vlrNfvAvulsa;
    this.vlrDesc = jsonObject.vlrDesc;
    this.vlrFrete = jsonObject.vlrFrete;
    this.nrSeqTipoFrete = jsonObject.nrSeqTipoFrete;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.nrSeqPessoaVen = jsonObject.nrSeqPessoaVen;
    this.nrSeqAlmoxarifado = jsonObject.nrSeqAlmoxarifado;
    this.nrSeqNfvAvulsaPai = jsonObject.nrSeqNfvAvulsaPai;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.qtdeFrete = jsonObject.qtdeFrete;
    this.noEspecieFrete = jsonObject.noEspecieFrete;
    this.pesoBrutoFrete = jsonObject.pesoBrutoFrete;
    this.pesoLiquidoFrete = jsonObject.pesoLiquidoFrete;
    this.nrSeqEndEntrega = jsonObject.nrSeqEndEntrega;
    this.vlrComissao = jsonObject.vlrComissao;
    this.nrSeqPessoaTran = jsonObject.nrSeqPessoaTran;
    this.nrSeqPessoaTrar = jsonObject.nrSeqPessoaTrar;
    this.perComissao = jsonObject.perComissao;
    this.nrSeqCfo = jsonObject.nrSeqCfo;
    this.obsNfv = jsonObject.obsNfv;
    this.nrSeqTipoVenda = jsonObject.nrSeqTipoVenda;
    this.dtVenda = jsonObject.dtVenda;
    this.nrSeqTipoNfvAvulsa = jsonObject.nrSeqTipoNfvAvulsa;
    this.nrSeqPocOrcamento = jsonObject.nrSeqPocOrcamento;
    this.nrSeqEmpresaDest = jsonObject.nrSeqEmpresaDest;
    this.dtSaida = jsonObject.dtSaida;
    this.dtSaidaString = jsonObject.dtSaidaString;
    this.placa = jsonObject.placa;
    this.flgIssqnCidadeOrigem = jsonObject.flgIssqnCidadeOrigem;
    this.nrSeqEstadoVeiculo = jsonObject.nrSeqEstadoVeiculo;
    this.rntrc = jsonObject.rntrc;
    this.vlrDespesas = jsonObject.vlrDespesas;
    this.vlrTotOperacao = jsonObject.vlrTotOperacao;
    this.tipoPagamentoOperacao = jsonObject.tipoPagamentoOperacao;
    this.qtdeItNfvTotal = jsonObject.qtdeItNfvTotal;
    this.nrSeqOrdemServico = jsonObject.nrSeqOrdemServico;
    this.flgVeiculoCadastrado = jsonObject.flgVeiculoCadastrado;
    this.noTipoDevolucao = jsonObject.noTipoDevolucao;
    this.status = jsonObject.status;

    // #region Propriedades Auxiliares
    this.cliente = new Cliente(jsonObject.cliente ?? {});
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.tipoNfvAvulsa = new TipoNfvAvulsa(jsonObject.tipoNfvAvulsa ?? {});
    this.nfv = new Nfv(jsonObject.nfv ?? {});
    this.itens = jsonObject.itens?.map((json) => new NfvAvulsaItem(json)) ?? [];
    this.cfo = new Cfo(jsonObject.cfo ?? {});
    this.notas = jsonObject.notas?.map((json) => new NfNfv(json)) ?? [];
    this.nfvChaveNfe = new NfvChaveNfe(jsonObject.nfvChaveNfe ?? {});
    this.tipoFrete = new TipoFrete(jsonObject.tipoFrete ?? {});
    this.transportadora = new Transportadora(jsonObject.transportadora ?? {});
    this.estadoVeiculo = new Estado(jsonObject.estadoVeiculo ?? {});
    this.orcamento = new PocOrcamento(jsonObject.orcamento ?? {});
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    this.transportadoraRedespacho = new Transportadora(
      jsonObject.transportadoraRedespacho ?? {}
    );
    this.noAutoComplete = jsonObject.noAutoComplete;
    this.ordemServico = new OrdemServico(jsonObject.ordemServico ?? {});
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
