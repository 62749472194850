import { PageTypes, Theme } from 'ui/Helpers/utils';

const getPageType = (oldPageType) => {
  let pageType = null;
  if (oldPageType === 'SELPAGE') {
    pageType = PageTypes.Selection;
  } else if (oldPageType === 'MANUTPAGE') {
    pageType = PageTypes.Maintenence;
  } else if (oldPageType === 'RELPAGE') {
    pageType = PageTypes.Report;
  } else if (oldPageType === 'PDFPAGE') {
    pageType = PageTypes.Report;
  } else pageType = PageTypes.Other;

  return pageType;
};

const getMessageTheme = (messageType) => {
  if (messageType === 'success') return Theme.Success;
  if (messageType === 'error') return Theme.Danger;
  if (messageType === 'information') return Theme.Info;

  return Theme.Primary;
};

const findTransaction = (openedTransactions, transaction) =>
  openedTransactions?.find(
    (e) =>
      e &&
      ((transaction === '-' && e.active) ||
        e.noTransacao === transaction ||
        e.noTransacao === transaction.name)
  );

const CorsManager = ({ obj, paramTransactions, onOpenReport }) => {
  const openedTransactions = paramTransactions;

  if (obj.data === 'resizeIframe') {
    const transaction = findTransaction(openedTransactions, '-');
    const page = transaction?.pages?.find((item) => item.active);
    if (page) page.resize = !page.resize ?? true;
  } else {
    try {
      const objJson = JSON.parse(obj.data);

      if (objJson?.event === 'AddOuterTab') {
        let transaction = findTransaction(
          openedTransactions,
          objJson.transaction
        );

        for (let i = 0; i < openedTransactions.length; i += 1) {
          if (openedTransactions[i]) openedTransactions[i].active = false;
        }

        if (transaction) {
          transaction.active = true;
          transaction.pages = objJson.transaction.openedPages.map((page) => {
            const newPage = {
              path: page.src,
              active: page.isActive,
              ...getPageType(page.pageType),
            };
            return newPage;
          });
        } else {
          transaction = {
            nrSeqTransacao: objJson.transaction.id,
            noTransacao: objJson.transaction.name,
            noDirFonte: objJson.transaction.activePage.src.split('/')[2],
            active: true,
            pages: objJson.transaction.openedPages.map((page) => {
              const newPage = {
                path: page.src,
                active: page.isActive,
                ...getPageType(page.pageType),
              };
              return newPage;
            }),
          };

          openedTransactions.push(transaction);
        }
      } else if (objJson?.event === 'addInnerTab') {
        const transaction = findTransaction(
          openedTransactions,
          objJson.transaction
        );

        const pageType = getPageType(objJson.pageType);

        for (let i = 0; i < transaction.pages.length; i += 1) {
          transaction.pages[i].active = false;
        }

        let reload = false;
        transaction.pages = transaction.pages.filter((item) => {
          if (item.id.toString() === pageType.id.toString())
            reload = !item.reload;
          return item.id.toString() !== pageType.id.toString();
        });

        if (pageType === PageTypes.Report && objJson.src.indexOf('///') >= 0) {
          objJson.src =
            (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
              ? 'old/'
              : '') + objJson.src.split('///')[1];
          if (objJson.src.indexOf('.pdf') >= 0) {
            onOpenReport(transaction.noTransacao, objJson.src);

            return { openedTransactions };
          }
        }

        transaction.pages.push({
          ...pageType,
          path: objJson.src,
          active: true,
          reload,
        });
      } else if (objJson?.event === 'selectInnerTab') {
        const transaction = findTransaction(
          openedTransactions,
          objJson.transaction
        );

        const pageType = getPageType(objJson.pageType);

        for (let i = 0; i < transaction.pages.length; i += 1) {
          transaction.pages[i].active = transaction.pages[i].id === pageType.id;
        }
      } else if (objJson?.event === 'showMessage') {
        const messageTheme = getMessageTheme(
          objJson.message.type || objJson.type
        );
        return { openedTransactions, message: objJson.message, messageTheme };
      } else if (objJson?.event === 'modal') {
        if (document.getElementById('main-content')) {
          document.getElementById('main-content').scrollTop = 0;
        }
      } else if (objJson?.event === 'logout') {
        console.log('logout');
      }
    } catch (e) {
      console.log('erro converter objeto cors', e, obj);
    }
  }

  return { openedTransactions };
};

export { CorsManager };
