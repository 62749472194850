import PocGrupoProdOrcamento from 'core/models/POC/pocGrupoProdOrcamento';
import Produto from 'core/models/EST/produtoAgrupador';
import PocProdOrcamentoTipo from 'core/models/POC/pocProdOrcamentoTipo';

export default class PocProdOrcamento {
  constructor(jsonObject = {}) {
    this.nrSeqPocProdOrcamento = jsonObject.nrSeqPocProdOrcamento;
    this.nrSeqPocGrupoProdOrcamento = jsonObject.nrSeqPocGrupoProdOrcamento;
    this.cdUnidade = jsonObject.cdUnidade;
    this.noPocProdOrcamento = jsonObject.noPocProdOrcamento;
    this.vlrBase = jsonObject.vlrBase;
    this.nrSeqProduto = jsonObject.nrSeqProduto;
    this.nrOrdem = jsonObject.nrOrdem;
    this.flgPermiteEditar = jsonObject.flgPermiteEditar;
    this.flgInativo = jsonObject.flgInativo;
    this.nrDiametromm = jsonObject.nrDiametromm;
    this.nrDiametropol = jsonObject.nrDiametropol;
    this.nrSeqPocProdOrcamentoTipo = jsonObject.nrSeqPocProdOrcamentoTipo;
    this.noProdComplemento = jsonObject.noProdComplemento;
    this.tipo = new PocProdOrcamentoTipo(jsonObject.tipo ?? {});
    this.grupoOrcamento = new PocGrupoProdOrcamento(
      jsonObject.grupoOrcamento ?? {}
    );
    this.produto = new Produto(jsonObject.produto ?? {});
  }

  toJson = () => JSON.stringify(this);
}
