import {
  Veiculo,
  TipoCombustivel,
  PostoCombustivel,
  PostoCombustivelBomba,
  Motorista,
  PostoCombustivelTanque,
} from 'core/models/FRO';

import { PocOrcamento } from 'core/models/POC';

import {
  TituloPagar,
  FormaPagamento,
  Fornecedor,
  TipoDespesa,
  AcertoMotorista,
} from 'core/models/FIN';

export default class Abastecimentos {
  constructor(jsonObject = {}) {
    this.nrSeqAbastecimento = jsonObject.nrSeqAbastecimento;
    this.dtAbastecimento = jsonObject.dtAbastecimento;
    this.nrOdometro = jsonObject.nrOdometro;
    this.nrQuilometragem = jsonObject.nrQuilometragem;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.qtdeLitro = jsonObject.qtdeLitro;
    this.vlrLitro = jsonObject.vlrLitro;
    this.vlrDespesa = jsonObject.vlrDespesa;
    this.hora = jsonObject.hora;
    this.vlrTotal = jsonObject.vlrTotal;
    this.nrSeqTituloPagar = jsonObject.nrSeqTituloPagar;
    this.nrSeqPocOrcamento = jsonObject.nrSeqPocOrcamento;
    this.dtVencimento = jsonObject.dtVencimento;
    this.dtCad = jsonObject.dtCad;
    this.nrSeqAbastecimentoAnt = jsonObject.nrSeqAbastecimentoAnt;
    this.vlrMediaConsumo = jsonObject.vlrMediaConsumo;
    this.nrDocumento = jsonObject.nrDocumento;
    this.quilometragemPercorrida = jsonObject.quilometragemPercorrida;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqPessoaPos = jsonObject.nrSeqPessoaPos;
    this.nrSeqTipoCombustivel = jsonObject.nrSeqTipoCombustivel;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgTanqueCheio = jsonObject.flgTanqueCheio;
    this.nrSeqPessoaMot = jsonObject.nrSeqPessoaMot;
    this.nrSeqPostoBomba = jsonObject.nrSeqPostoBomba;
    this.vlrDesconto = jsonObject.vlrDesconto;
    this.nrSeqPessoaFor = jsonObject.nrSeqPessoaFor;
    this.nrSeqAcertoMotorista = jsonObject.nrSeqAcertoMotorista;
    this.flgAvistaAcerto = jsonObject.flgAvistaAcerto;
    this.noObs = jsonObject.noObs;
    this.orcamento = new PocOrcamento(jsonObject.orcamento ?? {});
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    this.tipoCombustivel = new TipoCombustivel(
      jsonObject.tipoCombustivel ?? {}
    );
    this.motorista = new Motorista(jsonObject.motorista ?? {});
    this.noColorMotorista = jsonObject.noColorMotorista;
    this.noColorPosto = jsonObject.noColorPosto;
    this.postoCombustivelBomba = new PostoCombustivelBomba(
      jsonObject.postoCombustivelBomba ?? {}
    );
    this.postoCombustivel = new PostoCombustivel(
      jsonObject.postoCombustivel ?? {}
    );
    this.motorista = new Motorista(jsonObject.motorista ?? {});

    this.tituloPagar = new TituloPagar(jsonObject.tituloPagar ?? {});
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.fornecedor = new Fornecedor(jsonObject.fornecedor ?? {});

    this.noColorVeiculo = jsonObject.noColorVeiculo;
    this.noColorTipoCombustivel = jsonObject.noColorTipoCombustivel;
    this.noMotorista = jsonObject.noMotorista;
    this.status = jsonObject.status;
    this.nrSeqVendaDiaria = jsonObject.nrSeqVendaDiaria;
    this.abastecimentoS = jsonObject.abastecimentoS;
    this.noColorNotaFiscal = jsonObject.noColorNotaFiscal;
    this.noPosto = jsonObject.noPosto;
    this.naoLidos = jsonObject.naoLidos;
    this.ticketLog = jsonObject.ticketLog;
    this.postoBomba = new PostoCombustivelBomba(jsonObject.postoBomba ?? {});
    this.postoCombustivelTanque = new PostoCombustivelTanque(
      jsonObject.postoCombustivelTanque ?? {}
    );
    this.nrSeqPostoCombustivelTanque = jsonObject.nrSeqPostoCombustivelTanque;
    this.acertoMotorista = new AcertoMotorista(
      jsonObject.acertoMotorista ?? {}
    );
    this.tipoDespesa = new TipoDespesa(jsonObject.tipoDespesa ?? {});
    this.nrSeqTipoDespesa = jsonObject.nrSeqTipoDespesa;
    this.tituloPagarPlanoDeConta = jsonObject.tituloPagarPlanoDeConta;
    this.nrHorimetro = jsonObject.nrHorimetro;
  }

  toJson = () => JSON.stringify(this);
}
