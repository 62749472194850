import { React, forwardRef, useEffect, useState } from 'react';
import { DesenhoPoco, Textbox } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';

const DesenhoControlePoco = forwardRef(({ data, setData }) => {
  const [escala, setEscala] = useState(
    data.nrEscalaDesenho === 0 ? 100 : data.nrEscalaDesenho
  );

  const label = '';

  function setBase64Desenho() {
    const ctx = document.getElementById('canvas');

    const base64 = ctx
      .toDataURL('image/jpeg', 1.0)
      .replace('data:image/jpeg;base64,', '');

    data.noImagemDesenho = base64;
    setData({
      ...data,
      noImagemDesenho: base64,
      nrEscalaDesenho: data.nrEscalaDesenho,
    });
    data.noImagemDesenho = base64;
  }

  useEffect(() => {
    setTimeout(() => {
      setBase64Desenho();
    }, 1000);
  });

  return (
    <div>
      <div className='row mb-3'>
        <div className='display-8'>12 - Desenho Poço</div>
        {label}
      </div>

      <div className='row mb-3'>
        <div className='col-1'>
          <div style={{ position: 'absolute', top: '1010px' }}>
            fim página__
          </div>
        </div>
        <div className='col-2'>
          <Textbox
            maxLength={20}
            mask={MaskTypes.Integer}
            label='Escala Vertical (%)'
            text={escala}
            onChangedValue={(novaescala) => {
              data.nrEscalaDesenho = novaescala;
              setData({
                ...data,
                nrEscalaDesenho: novaescala,
              });
              setEscala(novaescala);
            }}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-12'>
          <DesenhoPoco data={data} escala={escala} />
        </div>
      </div>
    </div>
  );
});
export default DesenhoControlePoco;
