import { GrupoTipoChamada } from 'core/models/TEL';
import api from '../api';

const getGrupoTipoChamadaAutoComplete = async (filters) => {
  const response = await api.get(`/GrupoTipoChamada/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    grupos: value?.map((json) => new GrupoTipoChamada(json)) ?? [],
  };
};

export { getGrupoTipoChamadaAutoComplete };
