import PessoaInformacaoBancaria from './pessoaInformacaoBancaria';
import Endereco from '../SEG/endereco';

import { Pessoa } from '../SEG';
import CondicaoPagamento from './condicaoPagamento';
import PlanoDeConta from './planoDeConta';
import Cidade from '../SEG/cidade';
import Estado from '../SEG/estado';
import Contato from './contato';
import FornecedorAtividade from './fornecedorAtividade';

export default class Fornecedor {
  constructor(jsonObject = {}) {
    this.nrSeqCondicaoPagamento = jsonObject.nrSeqCondicaoPagamento;
    this.nrSeqPessoaFor = jsonObject.nrSeqPessoaFor;
    this.cdFornecedor = jsonObject.cdFornecedor;
    this.flgAtivo = jsonObject.flgAtivo;
    this.flgSituacaoFornecedor = jsonObject.flgSituacaoFornecedor;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqPlanoDeConta = jsonObject.nrSeqPlanoDeConta;
    this.noPessoa = jsonObject.noPessoa;
    this.informacaoBancaria = new PessoaInformacaoBancaria(
      jsonObject.informacaoBancaria ?? {}
    );
    this.cidade = new Cidade(jsonObject.cidade ?? {});
    this.estado = new Estado(jsonObject.estado ?? {});
    this.contato = new Contato(jsonObject.contato ?? {});
    this.fornecedorAtividade = new FornecedorAtividade(
      jsonObject.fornecedorAtividade ?? {}
    );
    this.pessoaJuridica = jsonObject.pessoaJuridica ?? {};
    this.endereco = jsonObject.endereco
      ? new Endereco(jsonObject.endereco)
      : new Endereco();
    this.pessoa = new Pessoa(jsonObject.pessoa ?? {});
    this.condicaoPagamento = new CondicaoPagamento(
      jsonObject.condicaoPagamento ?? {}
    );
    this.planoDeConta = new PlanoDeConta(jsonObject.planoDeConta ?? {});
    this.nrCPF = jsonObject.nrCPF;
    this.nrRG = jsonObject.nrRG;
    this.fone = jsonObject.fone;
    this.dtNascimento = jsonObject.dtNascimento;
    this.email = jsonObject.email;
    this.nrSeqProfissao = jsonObject.nrSeqProfissao;
    this.flgFisica = jsonObject.flgFisica ?? true;
    this.nrCNPJ = jsonObject.nrCNPJ;
    this.iEstadual = jsonObject.iEstadual;
    this.noFantasia = jsonObject.noFantasia;
    this.noRazaoSocial = jsonObject.noRazaoSocial;
    this.nrSeqSexo = jsonObject.nrSeqSexo;
    this.noFornecedor = jsonObject.noFornecedor;
    this.nomeDocumentoCidadeEstado = jsonObject.nomeDocumentoCidadeEstado;
    this.emailNFE = jsonObject.emailNFE;

    // #region Propriedades Auxiliares
    this.documento = jsonObject.documento;
    this.flgNotEmptyNoPessoa = jsonObject.flgNotEmptyNoPessoa;
    this.nrSeqFornecedorAtividade = jsonObject.nrSeqFornecedorAtividade;
    this.noFornecedorAtividade = jsonObject.noFornecedorAtividade;
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
