import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Textbox,
  Switch,
  Autocomplete,
  GridView,
  Button,
} from 'ui/components';
import {
  getChecklistFrota,
  saveChecklistFrota,
  deleteChecklistFrota,
} from 'core/services/FRO/checklistFrota';
import { getChecklistGrupoAutoComplete } from 'core/services/FRO/checklistGrupo';

const checklistTipos = [
  { Key: 0, noChecklistTipo: 'Checkbox', nrSeqChecklistTipo: 1 },
  { Key: 1, noChecklistTipo: 'Texto', nrSeqChecklistTipo: 2 },
];

export default function ChecklistFrotaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const gridView = useRef();
  const gridViewChecklistValor = useRef();
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [checklistCampos, setChecklistCampos] = useState({});
  const [checklistValores, setChecklistValores] = useState({});

  const [listChecklistValores, setListChecklistValores] = useState([]);
  const [listChecklistCampos, setListChecklistCampos] = useState([]);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onClickRemoveChecklistValor = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);
    const updatedList = datasource.filter((el) => el !== e);
    gridItem.status = GridView.EnumStatus.Remover;
    if (
      gridItem.status !== GridView.EnumStatus.Inserir &&
      gridItem.status !== GridView.EnumStatus.Alterar
    ) {
      if (gridViewChecklistValor && gridViewChecklistValor.current) {
        gridViewChecklistValor.current.setDataSource(updatedList);
      }

      setListChecklistValores(updatedList.map((valor) => ({ ...valor })));
    }
  };
  const onClickRemoveChecklistCampos = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);
    const updatedList = datasource.filter((el) => el !== e);

    if (
      gridItem.status !== GridView.EnumStatus.Inserir &&
      gridItem.status !== GridView.EnumStatus.Alterar
    ) {
      setListChecklistCampos((prevList) =>
        prevList.map((item) =>
          item === e ? { ...item, status: GridView.EnumStatus.Remover } : item
        )
      );
    }

    if (gridView && gridView.current) {
      gridView.current.setDataSource(updatedList);
    }
  };

  const columnsChecklistValor = [
    {
      key: 'nrSeqChecklistValor',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'cdChecklist', title: 'CdChecklist', visible: false },
    { key: 'valor', title: 'Valor' },
    {
      key: 'nrSeqChecklistCampos',
      title: 'NrSeqChecklistCampos',
      visible: false,
    },
    {
      key: 'nrSeqManutencaoChecklistFrotaVeiculo',
      title: 'NrSeqManutencaoChecklistFrotaVeiculo',
      visible: false,
    },
    {
      key: 'nrSeqChecklistValor',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemoveChecklistValor(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];
  const columns = [
    {
      key: 'nrSeqChecklistCampos',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'cdTipoCampo', title: 'CdTipoCampo', visible: false },
    { key: 'noCampo', title: 'Campo' },
    { key: 'noGrupo', title: 'Grupo' },
    {
      key: 'nrSeqChecklistFrota',
      title: 'NrSeqChecklistFrota',
      visible: false,
    },
    {
      key: 'nrSeqChecklistGrupo',
      title: 'NrSeqChecklistGrupo',
      visible: false,
    },
    { key: 'nrOrdem', title: 'NrOrdem', visible: false },
    {
      key: 'flgObrigaConfManutencao',
      title: 'FlgObrigaConfManutencao',
      visible: false,
    },
    { key: 'flgVeiculo', title: 'FlgVeiculo', visible: false },
    { key: 'listChecklistValores', title: 'ChecklistValores', visible: false },
    {
      key: 'nrSeqChecklistCampos',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemoveChecklistCampos(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      const obj = await getChecklistFrota(registryKey);
      setData(obj);
      setListChecklistCampos(obj.listChecklistCampos);
      if (gridView && gridView.current) {
        gridView.current.setDataSource(obj.listChecklistCampos);
      }
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);
  useEffect(() => {
    if (gridViewChecklistValor && gridViewChecklistValor.current) {
      gridViewChecklistValor.current.setDataSource(listChecklistValores);
    }
  }, [listChecklistValores]);

  useEffect(() => {
    if (checklistCampos.nrSeqChecklistTipo !== 1) {
      if (gridViewChecklistValor && gridViewChecklistValor.current) {
        gridViewChecklistValor.current.setDataSource([]);
      }
      setListChecklistValores([]);
      setChecklistValores({});
    }
  }, [checklistCampos.nrSeqChecklistTipo]);

  const save = async () => {
    setLoading(true);
    data.listChecklistCampos = listChecklistCampos;
    const { status, message: msg } = await saveChecklistFrota(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteChecklistFrota(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchChecklistGrupo = async (e) => {
    const {
      status,
      message: msg,
      data: checklistGrupos,
    } = await getChecklistGrupoAutoComplete({
      noGrupo: e,
    });
    if (msg) onSetMessage(status, msg);
    return checklistGrupos;
  };

  const onSearchChecklistTipo = async (searchText) => {
    const searchTerm = searchText.toLowerCase();

    const filteredTiposImpressao = checklistTipos.filter((checklistTipo) =>
      checklistTipo.noChecklistTipo.toLowerCase().includes(searchTerm)
    );

    return filteredTiposImpressao;
  };
  const onClickAddValor = async () => {
    const novoValor = { ...checklistValores, status: 'Inserir' };
    setListChecklistValores((prevList) => [...prevList, novoValor]);

    setChecklistValores({});
  };

  const onClickAddCampos = async () => {
    const campos = listChecklistCampos ?? [];
    const novoCampo = { ...checklistCampos, status: 'Inserir' };

    if (checklistCampos.nrSeqChecklistTipo === 2) {
      const valores = listChecklistValores ?? [];
      const novoValor = {
        ...checklistValores,
        valor: 'OBS',
        flgIncluiObs: false,
        status: 'Inserir',
      };
      valores.push(novoValor);
      novoCampo.listChecklistValores = valores;
    } else {
      const listaValores = [...listChecklistValores];
      novoCampo.listChecklistValores = listaValores;
    }
    campos.push(novoCampo);
    setListChecklistCampos(campos);
    if (gridView && gridView.current) {
      gridView.current.setDataSource(campos);
    }

    setListChecklistValores([]);
    if (gridViewChecklistValor && gridViewChecklistValor.current) {
      gridViewChecklistValor.current.setDataSource([]);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de ChecklistFrota'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqChecklistFrota > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={800}
            label='Nome Checklist'
            text={data.noChecklistFrota}
            onChangedValue={(noChecklistFrota) =>
              setData({ ...data, noChecklistFrota })
            }
          />
        </div>

        <div className='col mb-3'>
          <Textbox
            maxLength={80}
            label='Nome Layout'
            text={data.noLayout}
            onChangedValue={(noLayout) => setData({ ...data, noLayout })}
          />
        </div>

        <div className='col mb-3'>
          <Textbox
            maxLength={200}
            label='Titulo'
            text={data.noTitulo}
            onChangedValue={(noTitulo) => setData({ ...data, noTitulo })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Exibe Logo Empresa:'
            checked={data.flgImagemEmpresa}
            onChange={(flgImagemEmpresa) =>
              setData({ ...data, flgImagemEmpresa })
            }
          />
        </div>

        <div className='col mb-3'>
          <Switch
            formControl
            label='Manutenção preventiva Obrigatório:'
            checked={data.flgManutencaoPreventiva}
            onChange={(flgManutencaoPreventiva) =>
              setData({ ...data, flgManutencaoPreventiva })
            }
          />
        </div>

        <div className='col mb-3'>
          <Switch
            formControl
            label='Imprimir Todos os Itens:'
            checked={data.flgImprimeTodosItens}
            onChange={(flgImprimeTodosItens) =>
              setData({ ...data, flgImprimeTodosItens })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Incluir Motorista:'
            checked={data.flgMotorista}
            onChange={(flgMotorista) => setData({ ...data, flgMotorista })}
          />
        </div>
      </div>
      <fieldset
        style={{
          border: '1px solid #cfd4d8',
          padding: '10px',
          borderRadius: '3px',
        }}
      >
        <legend>Campos:</legend>
        <div className='row'>
          <div className='col mb-3'>
            <Textbox
              maxLength={200}
              label='Nome do Campo'
              text={data.noCampo}
              onChangedValue={(noCampo) =>
                setChecklistCampos({ ...checklistCampos, noCampo })
              }
            />
          </div>
          <div className='col-4 mb-3'>
            <Autocomplete
              label='Grupo'
              searchDataSource={onSearchChecklistGrupo}
              selectedItem={checklistCampos.checklistGrupo}
              onSelectItem={(checklistGrupo) => {
                setChecklistCampos({
                  ...checklistCampos,
                  nrSeqChecklistGrupo: checklistGrupo.nrSeqChecklistGrupo,
                  noGrupo: checklistGrupo.noGrupo,
                });
              }}
              dataSourceTextProperty='noGrupo'
            />
          </div>
          <div className='col-4'>
            <Autocomplete
              label='Tipo:'
              searchDataSource={onSearchChecklistTipo}
              selectedItem={checklistCampos.checklistTipo}
              onSelectItem={(checklistTipo) => {
                setChecklistCampos({
                  ...checklistCampos,
                  nrSeqChecklistTipo: checklistTipo.nrSeqChecklistTipo,
                  noChecklistTipo: checklistTipo.noChecklistTipo,
                });
              }}
              dataSourceTextProperty='noChecklistTipo'
              dataSource={checklistTipos}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-2 mb-3'>
            <Textbox
              maxLength={200}
              label='Ordem:'
              text={checklistCampos.nrOrdem}
              onChangedValue={(nrOrdem) =>
                setChecklistCampos({ ...checklistCampos, nrOrdem })
              }
            />
          </div>
          {checklistCampos.nrSeqChecklistTipo === 1 && (
            <div className='col-2 mb-3'>
              <Textbox
                maxLength={200}
                label='Valor'
                text={checklistValores.valor}
                onChangedValue={(valor) =>
                  setChecklistValores({ ...checklistValores, valor })
                }
              />
            </div>
          )}
          {checklistCampos.nrSeqChecklistTipo === 1 && (
            <div className='col mb-3'>
              <Switch
                formControl
                label='Exibe Observação'
                checked={checklistValores.flgIncluiObs}
                onChange={(flgIncluiObs) =>
                  setChecklistValores({ ...checklistValores, flgIncluiObs })
                }
              />
            </div>
          )}
          {checklistCampos.nrSeqChecklistTipo === 1 && (
            <div className='col-3 mt-3'>
              <Button
                onClick={onClickAddValor}
                outline
                theme={Theme.Success}
                template={Button.Templates.Button}
                text='Adicionar Valor'
              />
            </div>
          )}
          <div className='col mt-2 d-flex align-items-center justify-content-end'>
            <Button
              onClick={onClickAddCampos}
              outline
              theme={Theme.Success}
              template={Button.Templates.Button}
              text='Adicionar'
            />
          </div>
        </div>
        <div className='row'>
          {checklistCampos.nrSeqChecklistTipo === 1 &&
            listChecklistValores.length > 0 && (
              <div className='col-3'>
                <GridView
                  ref={gridViewChecklistValor}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsChecklistValor}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            )}
        </div>

        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
            />
          </div>
        </div>
      </fieldset>
    </CSDManutPage>
  );
}
