export default class SituacaoChamado {
  nrSeqSituacaoChamado: number;

  nrSeqGrupoEmpresa: number;

  cdSituacaoChamado: string;

  noSituacaoChamado: string;

  flgDependenciaExterna: boolean;

  constructor(jsonObject: SituacaoChamado) {
    this.nrSeqSituacaoChamado = jsonObject.nrSeqSituacaoChamado;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.cdSituacaoChamado = jsonObject.cdSituacaoChamado;
    this.noSituacaoChamado = jsonObject.noSituacaoChamado;
    this.flgDependenciaExterna = jsonObject.flgDependenciaExterna;
  }

  toJson = () => JSON.stringify(this);
}
