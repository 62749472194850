import React, { useState, useEffect, useRef } from 'react';
import { setTransaction } from 'core/services/api';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  Button,
  Modal,
  Notification,
  GridView,
  DatePicker,
} from 'ui/components';
import { postUpdateVencimentoDebito } from 'core/services/FIN';

export default function ModalAlteraVencimentoDebito({
  show,
  onClose,
  transaction,
  faturas,
  retornoSave,
}) {
  const [message, setMessage] = useState(null);
  const [data, setData] = useState({});
  const gridView = useRef();

  const columns = [
    { key: 'nrSeqFatura', type: GridView.ColumnTypes.Checkbox, visible: false },
    { key: 'nrSeqGrupoEmpresa', title: 'NrSeqGrupoEmpresa', visible: false },
    { key: 'nrSeqEmpresa', title: 'NrSeqEmpresa', visible: false },
    { key: 'cdFatura', title: 'Nr. Fat' },
    {
      key: 'cliente.noPessoa',
      title: 'Cliente',
    },
    {
      key: 'empresa.noPessoa',
      title: 'Empresa',
    },

    {
      title: 'Vencimento',
      visible: false,
      key: 'dtVencimento',
      format: GridView.DataTypes.Date,
    },

    {
      key: 'noFormaPagamento',
      title: 'Forma de Pagamento',
      visible: false,
    },
    {
      key: 'vlrFatura',
      title: 'Valor',
      format: GridView.DataTypes.Money,
    },
    { key: 'nrSeqPessoaCli', title: 'NrSeqPessoaCli', visible: false },
    {
      key: 'dtFatura',
      title: 'DtFatura',
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'vlrDesconto',
      title: 'VlrDesconto',
      format: GridView.DataTypes.Money,
      visible: false,
    },
    {
      key: 'vlrAcrescimo',
      title: 'VlrAcrescimo',
      format: GridView.DataTypes.Money,
      visible: false,
    },
  ];

  const onSaveAlteraVencimento = async () => {
    const lista = gridView.current.getDataSource();
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);
    const obj = { faturas: lista, dtVencimento: data.dtVencimento };
    const retorno = await postUpdateVencimentoDebito(obj);
    retornoSave(retorno);
  };

  useEffect(async () => {
    if (show) {
      if (gridView && gridView.current) gridView.current.setDataSource(faturas);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      title='Registrar Alteração Vencimento Débito'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body className='p-4 pt-3'>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col-2'>
            <DatePicker
              label='Vencimento'
              text={data.dtVencimento}
              mask={MaskTypes.Date}
              onChange={(dtVencimento) => setData({ ...data, dtVencimento })}
            />
          </div>
        </div>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Alterar Vencimento'
          theme={Theme.Success}
          icon={['far', 'save']}
          onClick={() => onSaveAlteraVencimento()}
        />
        <Button
          text='Fechar'
          theme={Theme.Danger}
          icon='ban'
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
