import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  JustifyContent,
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Panel,
  GridView,
  ToolbarButtons,
  Button,
} from 'ui/components';

import {
  getUsuarioContaCorrenteList,
  saveUsuarioContaCorrente,
} from 'core/services/FIN/usuarioContaCorrente';
import { getContaCorrenteAutoComplete } from 'core/services/FIN';
import { getUsuarioAutoComplete } from 'core/services/SEG';

export default function UsuarioContaCorrenteItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({ usuario: {} });
  const [objContaCorrente, setObjContaCorrente] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const gridView = useRef();

  const onClickRemove = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    gridItem.status = GridView.EnumStatus.Remover;

    if (gridView && gridView.current) {
      gridView.current.setDataSource(datasource);
    }
  };

  const columns = [
    {
      key: 'nrSeqContaCorrente',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'nrSeqUsuario', title: 'NrSeqUsuario', visible: false },
    { key: 'noContaCorrente', title: 'Conta Corrente' },
    {
      key: 'nrSeqContaCorrente',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemove(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  const onNew = () => {
    setData({});
    if (gridView && gridView.current) {
      gridView.current.setDataSource([]);
    }
    setObjContaCorrente({});
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const { data: usuarioContaCorrente } = await getUsuarioContaCorrenteList({
        nrSeqUsuario: registryKey,
      });

      if (usuarioContaCorrente != null) {
        if (usuarioContaCorrente[0].contaCorrenteS != null) {
          if (gridView && gridView.current) {
            gridView.current.setDataSource(
              usuarioContaCorrente[0].contaCorrenteS
            );
          }
        }
      }

      setData({
        usuario: usuarioContaCorrente[0].usuario,
        nrSeqUsuario: registryKey,
      });

      setLoading(false);
    } else {
      onNew();
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);
  useEffect(async () => {
    if (data.nrSeqUsuario > 0) {
      const { data: usuarioContaCorrente } = await getUsuarioContaCorrenteList({
        nrSeqUsuario: data.nrSeqUsuario,
      });

      if (usuarioContaCorrente != null) {
        if (usuarioContaCorrente[0].contaCorrenteS != null) {
          if (gridView && gridView.current)
            gridView.current.setDataSource(
              usuarioContaCorrente[0].contaCorrenteS
            );
        }
      }
    }
  }, [data.nrSeqUsuario]);
  const search = async () => {
    setLoading(true);
    if (!data.nrSeqUsuario) {
      onSetMessage(false, 'Necessário selecionar o Usuario.');
    } else {
      const { data: usuarioContaCorrente } = await getUsuarioContaCorrenteList({
        nrSeqUsuario: data.nrSeqUsuario,
        nrSeqContaCorrente: objContaCorrente.nrSeqContacorrente,
      });

      if (usuarioContaCorrente != null) {
        if (usuarioContaCorrente[0].contaCorrenteS != null) {
          if (gridView && gridView.current)
            gridView.current.setDataSource(
              usuarioContaCorrente[0].contaCorrenteS
            );
        }
      }
    }

    setLoading(false);
  };

  const save = async () => {
    setLoading(true);
    const contas = gridView.current.getDataSource();
    const obj = { ...data, contaCorrenteS: contas };
    const { status, message: msg } = await saveUsuarioContaCorrente(obj);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      onNew();
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onSearchContaCorrente = async (e) => {
    const {
      status,
      message: msg,
      data: contaCorrente,
    } = await getContaCorrenteAutoComplete({
      noContaCorrente: e,
    });
    if (msg) onSetMessage(status, msg);
    return contaCorrente;
  };

  const onSearchUsuario = async (e) => {
    const {
      status,
      message: msg,
      data: usuarios,
    } = await getUsuarioAutoComplete({
      noLogin: e,
    });
    if (msg) onSetMessage(status, msg);
    return usuarios;
  };
  const onClickAdd = async () => {
    const novoValor = objContaCorrente.contaCorrente;
    const sourcedata = [...(gridView.current.getDataSource() || [])];

    novoValor.status = 'Inserir';

    if (!data.nrSeqUsuario) {
      return onSetMessage(ResponseStatus.Warning, 'Selecione Um Usuario');
    }
    if (!novoValor.nrSeqContaCorrente) {
      return onSetMessage(
        ResponseStatus.Warning,
        'Selecione Uma Conta Corrente'
      );
    }
    const valorExistente = sourcedata.find(
      (item) =>
        item.nrSeqContaCorrente === novoValor.nrSeqContaCorrente &&
        item.status === novoValor.status
    );

    if (valorExistente) {
      return onSetMessage(
        ResponseStatus.Warning,
        'Conta corrente já existe na lista'
      );
    }

    sourcedata.push(novoValor);
    if (gridView && gridView.current) {
      gridView.current.setDataSource(sourcedata);
    }
    return true;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Usuario Conta Corrente'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Pesquisar'
          icon={['far', 'seach']}
          onClick={() => search()}
        />
      </ToolbarButtons>
      <Panel>
        <Panel.Body>
          <div className='row'>
            <div className='col-4'>
              <Autocomplete
                label='Usuario'
                enabled={!data.nrSeqUsuario > 0}
                searchDataSource={onSearchUsuario}
                selectedItem={data.usuario}
                onSelectItem={(usuario) => {
                  setData({
                    ...data,
                    usuario,
                    nrSeqUsuario: usuario.nrSeqUsuario,
                  });
                }}
                dataSourceTextProperty='noLogin'
              />
            </div>
            <div className='col-4'>
              <Autocomplete
                label='Conta Corrente'
                searchDataSource={onSearchContaCorrente}
                selectedItem={objContaCorrente.contaCorrente}
                onSelectItem={(contaCorrente) => {
                  setObjContaCorrente({
                    ...objContaCorrente,
                    contaCorrente,
                    nrSeqContaCorrente: contaCorrente.nrSeqContaCorrente,
                  });
                }}
                dataSourceTextProperty='noContaCorrente'
              />
            </div>

            <div className='col-3 mt-3'>
              <Button
                onClick={onClickAdd}
                outline
                theme={Theme.Success}
                template={Button.Templates.Button}
                text='Adicionar'
              />
            </div>
          </div>
        </Panel.Body>
        <Panel>
          <Panel.Header
            title='Conta corrente'
            theme={Theme.Light}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row'>
              <div className='col'>
                <GridView
                  ref={gridView}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </Panel>
    </CSDManutPage>
  );
}
