import { Empresa, Usuario, Pessoa } from '../SEG';
import FormaLancamentoEletronico from './formaLancamentoEletronico';
import FormaPagamento from './formaPagamento';
import Fornecedor from './fornecedor';
import TipoDespesa from './tipoDespesa';
import TituloPagarTipoDocumento from './tituloPagarTipoDocumento';
import TipoMovimento from './tipoMovimento';
import TipoMovimentoRemessa from './tipoMovimentoRemessa';
import TipoServicoBancario from './tipoServicoBancario';
import InstrucaoMovimento from './instrucaoMovimento';
import TituloPagarFatura from './tituloPagarFatura';

export default class TituloPagar {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.nrSeqTituloPagar = jsonObject.nrSeqTituloPagar;
    this.vlrTitulo = jsonObject.vlrTitulo;
    this.dtVencimento = jsonObject.dtVencimento;
    this.dtCad = jsonObject.dtCad;
    this.vlrPago = jsonObject.vlrPago;
    this.vlrSaldoTit = jsonObject.vlrSaldoTit;
    this.vlrDesconto = jsonObject.vlrDesconto;
    this.nrTit = jsonObject.nrTit;
    this.nrOrdem = jsonObject.nrOrdem;
    this.noObs = jsonObject.noObs;
    this.vlrJuroPago = jsonObject.vlrJuroPago;
    this.nrSeqTipoDespesa = jsonObject.nrSeqTipoDespesa;
    this.nrSeqPessoaFor = jsonObject.nrSeqPessoaFor;
    this.vlrJuroDia = jsonObject.vlrJuroDia;
    this.vlrMulta = jsonObject.vlrMulta;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.vlrMultaPaga = jsonObject.vlrMultaPaga;
    this.tipoOrigem = jsonObject.tipoOrigem;
    this.nrSeqOrigem = jsonObject.nrSeqOrigem;
    this.flgBoletoEntregue = jsonObject.flgBoletoEntregue;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.cdTituloPagar = jsonObject.cdTituloPagar;
    this.nrSeqTituloPagarTipoDocumento =
      jsonObject.nrSeqTituloPagarTipoDocumento;
    this.nrSeqUsuarioAlt = jsonObject.nrSeqUsuarioAlt;
    this.dtContaB = jsonObject.dtContaB;
    this.dtAlteracao = jsonObject.dtAlteracao;
    this.nrSeqTituloReceber = jsonObject.nrSeqTituloReceber;
    this.nrSeqTituloPagarFatura = jsonObject.nrSeqTituloPagarFatura;
    this.nrSeqPendenciaFinanceira = jsonObject.nrSeqPendenciaFinanceira;
    this.codigoBarras = jsonObject.codigoBarras;
    this.flgPendente = jsonObject.flgPendente;
    this.noCodigoBarras = jsonObject.noCodigoBarras;
    this.nrSeqTipoServicoBancario = jsonObject.nrSeqTipoServicoBancario;
    this.nrSeqFormaLancamentoEletronico =
      jsonObject.nrSeqFormaLancamentoEletronico;
    this.nrSeqTipoMovimento = jsonObject.nrSeqTipoMovimento;
    this.nrSeqInstrucaoMovimento = jsonObject.nrSeqInstrucaoMovimento;
    this.nrSeqTipoMovimentoRemessa = jsonObject.nrSeqTipoMovimentoRemessa;
    this.nrSeqPessoaSacador = jsonObject.nrSeqPessoaSacador;
    this.pagamento = jsonObject.pagamento ?? {};
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.fornecedor = new Fornecedor(jsonObject.fornecedor ?? {});
    this.tipoDespesa = new TipoDespesa(jsonObject.tipoDespesa ?? {});
    this.tituloPagarPlanoDeConta = jsonObject.tituloPagarPlanoDeConta;
    this.tituloPagarTipoDocumento = new TituloPagarTipoDocumento(
      jsonObject.tituloPagarTipoDocumento ?? {}
    );
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.parcelas = jsonObject.parcelas;
    this.status = jsonObject.status;
    this.veiculos = jsonObject.veiculos;
    this.usuario = new Usuario(jsonObject.usuario ?? {});
    this.usuarioAlteracao = new Usuario(jsonObject.usuarioAlteracao ?? {});
    this.ocorrencias = jsonObject.ocorrencias;
    this.flgAVista = jsonObject.flgAVista;
    this.vlrTotal = jsonObject.vlrTotal;
    this.dtPagamento = jsonObject.dtPagamento;
    this.documentos = jsonObject.documentos;
    this.tipoServico = new TipoServicoBancario(jsonObject.tipoServico ?? {});
    this.formaLancamentoEletronico = new FormaLancamentoEletronico(
      jsonObject.formaLancamentoEletronico ?? {}
    );
    this.tipoMovimento = new TipoMovimento(jsonObject.tipoMovimento ?? {});
    this.instrucaoMovimento = new InstrucaoMovimento(
      jsonObject.instrucaoMovimento ?? {}
    );
    this.movimentoRemessa = new TipoMovimentoRemessa(
      jsonObject.movimentoRemessa ?? {}
    );
    this.pessoaSacador = new Pessoa(jsonObject.pessoaSacador ?? {});
    this.tituloPagarFatura = new TituloPagarFatura(
      jsonObject.tituloPagarFatura ?? {}
    );
    this.vlrPagoTotal = jsonObject.vlrPagoTotal;
    this.vlrSaldoTotal = jsonObject.vlrSaldoTotal;
    this.vlrSomadoTotal = jsonObject.vlrSomadoTotal;
    this.planoConta = jsonObject.planoConta;
    this.tipoDespesaPlanoDeContaS = jsonObject.tipoDespesaPlanoDeContaS;
    this.noVeiculos = jsonObject.noVeiculos;
    this.pagamentos = jsonObject.pagamentos ?? [];
  }

  toJson = () => JSON.stringify(this);
}
