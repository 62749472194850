import NfNfv from 'core/models/VEN/nfNfv';
import NfvChaveNfe from 'core/models/VEN/nfvChaveNfe';
import Cfo from 'core/models/FIS/cfo';
import Cliente from '../FIN/cliente';

export default class Nfv {
  constructor(jsonObject = {}) {
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqNfv = jsonObject.nrSeqNfv;
    this.nrNfv = jsonObject.nrNfv;
    this.serieNfv = jsonObject.serieNfv;
    this.nrSeqPedido = jsonObject.nrSeqPedido;
    this.vlrFrete = jsonObject.vlrFrete;
    this.vlrDespesas = jsonObject.vlrDespesas;
    this.vlrIrrf = jsonObject.vlrIrrf;
    this.vlrNfv = jsonObject.vlrNfv;
    this.vlrDesconto = jsonObject.vlrDesconto;
    this.dtEmissao = jsonObject.dtEmissao;
    this.dtCad = jsonObject.dtCad;
    this.vlrBaseCalcIcms = jsonObject.vlrBaseCalcIcms;
    this.vlrBasecalIpi = jsonObject.vlrBasecalIpi;
    this.vlrIcms = jsonObject.vlrIcms;
    this.vlrIpi = jsonObject.vlrIpi;
    this.obsNfv = jsonObject.obsNfv;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.qtdeFrete = jsonObject.qtdeFrete;
    this.noEspeciefrete = jsonObject.noEspeciefrete;
    this.pesoBrutoFrete = jsonObject.pesoBrutoFrete;
    this.pesoLiquidoFrete = jsonObject.pesoLiquidoFrete;
    this.nrSeqTipoFrete = jsonObject.nrSeqTipoFrete;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.nrSeqPessoaVen = jsonObject.nrSeqPessoaVen;
    this.nrSeqTabelaPreco = jsonObject.nrSeqTabelaPreco;
    this.nrSeqCfo = jsonObject.nrSeqCfo;
    this.nrSeqAlmoxarifado = jsonObject.nrSeqAlmoxarifado;
    this.nrSeqPessoaMot = jsonObject.nrSeqPessoaMot;
    this.dtCancel = jsonObject.dtCancel;
    this.nrSeqNfvAvulsa = jsonObject.nrSeqNfvAvulsa;
    this.flgSaida = jsonObject.flgSaida;
    this.vlrCofins = jsonObject.vlrCofins;
    this.vlrPis = jsonObject.vlrPis;
    this.placa = jsonObject.placa;
    this.dtSaida = jsonObject.dtSaida;
    this.vBCst = jsonObject.vBCst;
    this.vST = jsonObject.vST;
    this.nrSeqNfvPai = jsonObject.nrSeqNfvPai;
    this.nrSeqRomaneio = jsonObject.nrSeqRomaneio;
    this.finNFe = jsonObject.finNFe;
    this.nrSeqPessoaTrar = jsonObject.nrSeqPessoaTrar;
    this.nrSeqPessoaTran = jsonObject.nrSeqPessoaTran;
    this.nrSeqEndEntrega = jsonObject.nrSeqEndEntrega;
    this.nrSeqTipoVenda = jsonObject.nrSeqTipoVenda;
    this.rNTRC = jsonObject.rNTRC;
    this.nrSeqEstadoVeiculo = jsonObject.nrSeqEstadoVeiculo;
    this.nrSeqEmpresaDest = jsonObject.nrSeqEmpresaDest;
    this.vlrIcmsDiferido = jsonObject.vlrIcmsDiferido;
    this.vlrIcmsDesonerado = jsonObject.vlrIcmsDesonerado;
    this.tpEmis = jsonObject.tpEmis;
    this.noStatus = jsonObject.noStatus;

    this.flgPendenteCancelar = jsonObject.flgPendenteCancelar;
    this.flgPendenteTransmitir = jsonObject.flgPendenteTransmitir;
    this.flgPendenteInutilizar = jsonObject.flgPendenteInutilizar;
    this.flgPendenteDownload = jsonObject.flgPendenteDownload;

    this.noMotivoInutilizacao = jsonObject.noMotivoInutilizacao;
    this.notasVinculadas =
      jsonObject.notasVinculadas?.map((json) => new NfNfv(json)) ?? [];
    this.chavesVinculadas = new NfvChaveNfe(jsonObject.ChavesVinculadas ?? {});
    this.cliente = new Cliente(jsonObject.cliente ?? {});
    this.cfo = new Cfo(jsonObject.cfo ?? {});
    this.dtEmissaoInicial = jsonObject.dtEmissaoInicial;
    this.dtEmissaoFinal = jsonObject.dtEmissaoFinal;
  }

  toJson = () => JSON.stringify(this);
}
