import VeiculoEquipamento from 'core/models/FRO/veiculoEquipamento';
import { getManutencaoPreventivaAutoComplete } from 'core/services/EST/manutencaoPreventiva';
import { getEquipamentoAutoComplete } from 'core/services/FRO/equipamento';
import { getEquipamentoMarcaAutoComplete } from 'core/services/FRO/equipamentoMarca';
import React, { forwardRef, useState, useRef, useEffect } from 'react';
import {
  Autocomplete,
  Button,
  GridView,
  Textarea,
  Textbox,
  DatePicker,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';

const EquipamentoVeiculoStep = forwardRef(({ data, setData, onSetMessage }) => {
  const gridView = useRef();
  const [dataEquipamento, setDataEquipamento] = useState({});

  useEffect(() => {
    setDataEquipamento({ nrSeqVeiculo: data.nrSeqVeiculo });
    if (gridView && gridView.current && data.veiculoEquipamentos) {
      for (let cont = 0; cont < data.veiculoEquipamentos.length; cont += 1) {
        if (
          !data.veiculoEquipamentos[cont].manutencaoPreventiva ||
          data.veiculoEquipamentos[cont].manutencaoPreventiva === null
        )
          data.veiculoEquipamentos[cont].manutencaoPreventiva = {};
      }

      gridView.current.setDataSource(data.veiculoEquipamentos);
    } else {
      gridView.current.setDataSource([]);
    }
  }, []);

  const onSearchEquipamento = async (e) => {
    const { equipamentos } = await getEquipamentoAutoComplete({
      noEquipamento: e,
    });

    return equipamentos;
  };

  const onSearchMarcaEquipamento = async (e) => {
    const { equipamentoMarcas } = await getEquipamentoMarcaAutoComplete({
      noEquipamentoMarca: e,
    });

    return equipamentoMarcas;
  };
  const onSearchManutencaoPreventiva = async (e) => {
    const {
      data: manutencaopreventiva,
    } = await getManutencaoPreventivaAutoComplete({
      noManutencaoPreventiva: e,
    });

    return manutencaopreventiva;
  };

  const onClickAddEquipamento = async () => {
    if (
      dataEquipamento.idEquipamento &&
      dataEquipamento.equipamento &&
      dataEquipamento.dtInstalacao
    ) {
      const obj = new VeiculoEquipamento({
        ...dataEquipamento,
        status: dataEquipamento.status ?? GridView.EnumStatus.Inserir,
      });

      const list = gridView.current.getDataSource();
      list.push(obj);

      if (gridView && gridView.current) gridView.current.setDataSource(list);

      setData({ ...data, veiculoEquipamentos: list });
      setDataEquipamento({});
    } else if (!dataEquipamento.idEquipamento) {
      onSetMessage(false, 'Informe o ID');
    } else if (!dataEquipamento.equipamento) {
      onSetMessage(false, 'Informe o Equipamento');
    } else if (!dataEquipamento.dtInstalacao) {
      onSetMessage(false, 'Informe a Data de Instalação');
    }
  };

  const onClickEditEquipamento = (e, datasource) => {
    const objEquipamento = datasource.find((item) => item === e);

    if (objEquipamento.status !== GridView.EnumStatus.Inserir) {
      objEquipamento.status = GridView.EnumStatus.Alterar;
    }

    setDataEquipamento({ ...objEquipamento });

    const listaEquipamentos = datasource.filter((item) => item !== e);

    if (gridView && gridView.current)
      gridView.current.setDataSource(listaEquipamentos);
  };

  const onClickRemoveEquipamento = async (e) => {
    const list = gridView.current.getDataSource();

    list.filter((el) => el === e)[0].status = GridView.EnumStatus.Remover;

    if (gridView && gridView.current) gridView.current.setDataSource(list);

    setData({ ...data, veiculoEquipamentos: list });
  };

  const columns = [
    { key: 'idEquipamento', title: 'Id', sortable: false },
    { key: 'equipamento.noEquipamento', title: 'Equipamento', sortable: false },
    {
      key: 'equipamentoMarca.noEquipamentoMarca',
      title: 'Marca',
      sortable: false,
    },
    {
      key: 'manutencaoPreventiva.noManutencaoPreventiva',
      title: 'Manutenção Preventiva',
      sortable: false,
    },
    { key: 'noLogin', title: 'Login', sortable: false },
    { key: 'noSenha', title: 'Senha', sortable: false },
    { key: 'noObservacao', title: 'Observação', sortable: false },
    {
      key: 'nrSeqVeiculoEquipamento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditEquipamento(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
      title: 'Editar',
    },
    {
      key: 'nrSeqVeiculoEquipamento',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemoveEquipamento(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <div>
      <div className='row mb-3 algin-items-end'>
        <div className='col-2'>
          <Textbox
            label='Id'
            maxLength={20}
            text={dataEquipamento.idEquipamento}
            onChangedValue={(idEquipamento) =>
              setDataEquipamento({ ...dataEquipamento, idEquipamento })
            }
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Equipamento'
            searchDataSource={onSearchEquipamento}
            selectedItem={dataEquipamento.equipamento}
            onSelectItem={(equipamento) => {
              setDataEquipamento({
                ...dataEquipamento,
                equipamento,
                nrSeqEquipamento: equipamento.nrSeqEquipamento,
              });
            }}
            dataSourceTextProperty='noEquipamento'
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data de Instalação'
            text={dataEquipamento.dtInstalacao}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtInstalacao) =>
              setDataEquipamento({ ...dataEquipamento, dtInstalacao })
            }
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data de Desinstalação'
            text={dataEquipamento.dtDesinstalacao}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtDesinstalacao) =>
              setDataEquipamento({ ...dataEquipamento, dtDesinstalacao })
            }
          />
        </div>
      </div>
      <div className='row mb-3 algin-items-end'>
        <div className='col-2'>
          <Textbox
            label='Valor Mensalidade'
            text={dataEquipamento.vlrMensalidade}
            mask={MaskTypes.Currency}
            onChangedValue={(vlrMensalidade) =>
              setDataEquipamento({ ...dataEquipamento, vlrMensalidade })
            }
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Dia de Vencimento'
            text={dataEquipamento.diaVencimento}
            maxLength={2}
            mask={MaskTypes.Integer}
            onChangedValue={(diaVencimento) =>
              setDataEquipamento({ ...dataEquipamento, diaVencimento })
            }
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Marca Equipamento'
            searchDataSource={onSearchMarcaEquipamento}
            selectedItem={dataEquipamento.equipamentoMarca}
            onSelectItem={(equipamentoMarca) =>
              setDataEquipamento({
                ...dataEquipamento,
                equipamentoMarca,
                nrSeqEquipamentoMarca: equipamentoMarca.nrSeqEquipamentoMarca,
              })
            }
            dataSourceTextProperty='noEquipamentoMarca'
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Login'
            text={dataEquipamento.noLogin}
            maxLength={50}
            onChangedValue={(noLogin) =>
              setDataEquipamento({ ...dataEquipamento, noLogin })
            }
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Senha'
            text={dataEquipamento.noSenha}
            maxLength={50}
            onChangedValue={(noSenha) =>
              setDataEquipamento({ ...dataEquipamento, noSenha })
            }
          />
        </div>
      </div>
      <div className='row mb-3 algin-items-end'>
        <div className='col-3'>
          <Autocomplete
            label='Manutenção Preventiva'
            searchDataSource={onSearchManutencaoPreventiva}
            selectedItem={dataEquipamento.manutencaoPreventiva}
            onSelectItem={(manutencaoPreventiva) =>
              setDataEquipamento({
                ...dataEquipamento,
                manutencaoPreventiva,
                nrSeqManutencaoPreventiva:
                  manutencaoPreventiva.nrSeqManutencaoPreventiva,
              })
            }
            dataSourceTextProperty='noManutencaoPreventiva'
          />
        </div>
        <div className='col-7'>
          <Textarea
            label='Observações'
            text={dataEquipamento.noObservacao}
            rows={2}
            onChangedValue={(noObservacao) =>
              setDataEquipamento({ ...dataEquipamento, noObservacao })
            }
          />
        </div>

        <div className='col pt-4 d-flex align-items-start justify-content-center'>
          <Button
            onClick={onClickAddEquipamento}
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Adicionar'
          />
        </div>
      </div>
      <div className='row'>
        <GridView
          ref={gridView}
          className='table-striped table-hover table-bordered table-sm'
          dataColumns={columns}
          offlineData
          showPagination={false}
          showSelectSizes={false}
        />
      </div>
    </div>
  );
});

export default EquipamentoVeiculoStep;
