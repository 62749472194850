import { getDownloadNFS } from 'core/services/VEN/nfs';
import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Button } from 'ui/components';
import { Theme } from 'ui/Helpers/utils';

export default function DownloadNFS() {
  const { idNFS } = useParams();
  const [nfs, setNfs] = useState('');
  const [boleto, setBoleto] = useState('');
  const [xml, setXML] = useState('');
  const [data, setData] = useState<Object>({});
  const [corTexto, setCorTexto] = useState('black');
  const [icone, setIcone] = useState('copy');
  const [texto, setTexto] = useState('Copia e Cola PIX');

  useEffect((): void => {
    async function load() {
      const codigodownload = idNFS;
      const { data: ret } = await getDownloadNFS(codigodownload);
      if (ret) {
        setData(ret);
        setBoleto(ret.boletoBase54);
        setNfs(ret.nfsBase64);
        setXML(ret.nfsXML64);
      }
    }
    load();
  }, []);

  const downloadBoleto = () => {
    if (boleto) {
      const linkSource = `data:application/pdf;base64, ${boleto}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'Boleto.pdf';
      downloadLink.click();
    }
  };

  const downloadNFS = () => {
    if (nfs) {
      const linkSource = `data:application/pdf;base64, ${nfs}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'NotaFiscal.pdf';
      downloadLink.click();
    }
  };

  const downloadXML = () => {
    if (xml) {
      const linkSource = `data:application/pdf;base64, ${xml}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'xml.zip';
      downloadLink.click();
    }
  };

  const trocarCorTexto = () => {
    setCorTexto('green');
  };

  const trocarIcone = () => {
    setIcone('check');
  };

  const trocarTexto = () => {
    setTexto('Copiado!');
  };

  return (
    <div>
      {boleto && (
        <div>
          <div className='row mb-5 mt-5'>
            <div
              className='col'
              style={{
                textAlign: 'center',
              }}
            >
              <span
                style={{
                  textAlign: 'center',
                  fontSize: '50px',
                }}
              >
                {data.cliente}
              </span>
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col'>
              <div className='card border-dark '>
                <div className='card-body'>
                  <div className='row mb-3'>
                    <div className='col-4'>
                      <div className='row mt-4'>
                        <div
                          style={{
                            textAlign: 'center',
                            fontSize: '36px',
                          }}
                        >
                          Emissor
                        </div>
                        <div
                          style={{
                            textAlign: 'center',
                          }}
                          className='col'
                        >
                          <span
                            style={{ fontWeight: 'bold', fontSize: '36px' }}
                          >
                            {data.empresa}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='col-4 mt-5'>
                      <div className='row '>
                        <div className='row mb-3'>
                          <div
                            style={{
                              textAlign: 'center',
                            }}
                            className='card border-danger mb-3'
                          >
                            <div className='card-body'>
                              <span
                                className='card-text'
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '36px',
                                  color: 'darkblue',
                                }}
                              >
                                {data.valor}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              textAlign: 'center',
                            }}
                            className='col'
                          >
                            <span
                              style={{
                                fontWeight: 'bold',
                                fontSize: '20px',
                                color: 'darkblue',
                              }}
                            >
                              Valor da Fatura
                            </span>
                          </div>
                        </div>

                        <div className='row mb-3'>
                          <div
                            style={{
                              textAlign: 'center',
                            }}
                            className='card border-danger mb-3'
                          >
                            <div className='card-body'>
                              <span
                                className='card-text'
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '36px',
                                  color: 'darkblue',
                                }}
                              >
                                {data.vencimento}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              textAlign: 'center',
                            }}
                            className='col'
                          >
                            <span
                              style={{
                                fontWeight: 'bold',
                                fontSize: '20px',
                                color: 'darkblue',
                              }}
                            >
                              Vencimento
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-4'>
                      <div className='row mb-3'>
                        <div
                          className='col'
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          <img
                            src={data.qrcodeBase64}
                            className='img-thumbnail'
                            alt='QR Code Boleto'
                          />
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div
                          className='col'
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          {/* *trocar a cor do texto ao clicar no botão */}

                          <Button
                            style={{
                              fontSize: '15px',
                              textAlign: 'center',
                              backgroundColor: 'white',
                              color: corTexto,
                            }}
                            theme={Theme.Primary}
                            text={texto}
                            icon={icone}
                            className='width50'
                            onClick={() => {
                              navigator.clipboard.writeText(data.emv);
                              trocarCorTexto();
                              trocarIcone();
                              trocarTexto();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              <Button
                theme={Theme.Primary}
                text='Nota Fiscal'
                icon='download'
                className='width100'
                onClick={() => downloadNFS()}
              />
            </div>

            <div className='col'>
              <Button
                theme={Theme.Primary}
                text='XML'
                icon='download'
                className='width100'
                onClick={() => downloadXML()}
              />
            </div>
            <div className='col'>
              <Button
                theme={Theme.Success}
                text='Boleto'
                icon='download'
                className='width100'
                onClick={() => downloadBoleto()}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
