import React, { useState, useRef } from 'react';
import {
  CSDSelPage,
  GridView,
  Textbox,
  RadioButton,
  Autocomplete,
} from 'ui/components';
import { PageTypes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import {
  ColumnTypes,
  Theme,
  ResponseStatus,
  BootstrapSizes,
} from 'ui/Helpers/enums.ts';
import { getTransportadoraAutoComplete } from 'core/services/FRO/transportadora';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import {
  getMotoristaPagined,
  deleteAllMotorista,
  printMotorista,
} from 'core/services/FRO/motorista';

export interface IMotoristaFilters {
  noPessoa?: string;
  flgExcel?: boolean;
  SortBy?: number;
  totalByPage?: number;
  page?: any;
}

export default function Motorista({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}: Page) {
  const gridView = useRef<any>();

  const [filtros, setFiltros] = useState<IMotoristaFilters>({
    flgSituacao: 'T',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const columns: Array<GridviewColumns> = [
    { key: 'nrSeqPessoaMot', type: ColumnTypes.Checkbox },
    { key: 'cdMotorista', title: 'Código' },
    { key: 'noPessoa', title: 'Nome', sortKey: 'noPessoa' },
    { key: 'flgSituacao', title: 'Situação' },
  ];

  const Status = [
    { text: 'Liberado', value: 'L' },
    { text: 'Bloqueado', value: 'B' },
    { text: 'Rejeitado', value: 'R' },
    { text: 'Todos', value: 'T' },
  ];

  const search = async (params = null): Promise<void> => {
    setLoading(true);

    const { data, pagination } = await getMotoristaPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onOpenMaintenance = (registryKey = null): void => {
    onOpenPage(
      transaction.noTransacao,
      'Motorista/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onColumnSort = async (SortBy: number): Promise<void> => {
    setFiltros({ ...filtros, SortBy });
    await search();
  };

  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    setFiltros({ ...filtros, totalByPage });
    await search();
  };

  const onPageChange = async (page: any): Promise<void> => {
    setFiltros({ ...filtros, page });
    await search();
  };

  const onRowDoubleClick = (sourceRow: any): void => {
    const nrSeqPessoaMot = sourceRow.find(
      (e: any) => e.key === 'nrSeqPessoaMot'
    );

    onOpenMaintenance(nrSeqPessoaMot.value);
  };

  const onSearchTransportadora = async (e: any) => {
    const { transportadoras } = await getTransportadoraAutoComplete({
      noPessoa: e,
    });

    return transportadoras;
  };

  const onSearchVeiculo = async (e) => {
    const { veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
    });
    return veiculos;
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllMotorista(
        selecteds.map((row: any) => row[0].value)
      );
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      await search();
    } else {
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printMotorista(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Motorista'
      loading={loading}
      onSearch={search}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      {/* @ts-expect-error */}
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <Textbox
            label='Nome/ Razão Social'
            text={filtros.noPessoa}
            maxLength={200}
            onChangedValue={(noPessoa: string) =>
              setFiltros({ ...filtros, noPessoa })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          {/* @ts-expect-error */}
          <Autocomplete
            label='Transportadora'
            searchDataSource={onSearchTransportadora}
            selectedItem={filtros.transportadora}
            onSelectItem={(transportadora: any) =>
              setFiltros({
                ...filtros,
                transportadora,
                nrSeqPessoaTran: transportadora.nrSeqPessoaTran,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-4  mb-3'>
          <Autocomplete
            label='Placa'
            searchDataSource={onSearchVeiculo}
            selectedItem={filtros.veiculo}
            onSelectItem={(veiculo) => {
              setFiltros({
                ...filtros,
                veiculo,
                nrSeqVeiculo: veiculo.nrSeqVeiculo,
              });
            }}
            dataSourceTextProperty='noVeiculo'
          />
        </div>
        <div className='col-auto'>
          <RadioButton
            outline
            label='Status'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={Status}
            selectedButton={filtros.flgSituacao}
            onChange={(status) =>
              setFiltros({
                ...filtros,
                flgSituacao: status,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            onColumnSort={onColumnSort}
            enableExcelExport
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
