import qs from 'qs';
import Pagination from 'core/models/pagination';
import ExtratoTransferencia from 'core/models/FIN/extratoTransferencia';
import api from '../api';

const baseURL = `/FIN/ExtratoTransferencia`;

export const getExtratoTransferenciaList = async (filters) => {
  const response = await api.get(`/$ExtratoTransferencia/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ExtratoTransferencia(json)) ?? [],
  };
};

export const getExtratoTransferenciaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ExtratoTransferencia(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getExtratoTransferencia = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ExtratoTransferencia(value) : {};
};

export const saveExtratoTransferencia = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const CreditarDestino = async (data) => {
  const response = await api.post(`${baseURL}/CreditarDestino`, data);
  return response.data ?? {};
};

export const DebitarOrigem = async (data) => {
  const response = await api.post(`${baseURL}/DebitarOrigem`, data);
  return response.data ?? {};
};

export const deleteExtratoTransferencia = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printExtratoTransferencia = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const excelExtratoTransferencia = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllExtratoTransferencia = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
