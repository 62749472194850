import { SefazNfeEvento } from 'core/models/VEN';
import api from '../api';

const baseURL = `/VEN/SefazNfe`;

export const getManifestEventAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteEventManifest`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new SefazNfeEvento(json)) ?? [],
  };
};
