import React, { useState } from 'react';
import { Modal, Loader, Notification, Button } from 'ui/components';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';

import { inutilizarCte } from 'core/services/FRO/viagem';

export default function ModalInutilizarCte({ show, cte, onClose }) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onInutilizaCte = async () => {
    setLoading(true);

    const { status, message: msg, data } = await inutilizarCte(cte);

    if (status !== ResponseStatus.Success) {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    } else {
      onClose(data);
    }

    setLoading(false);
  };
  const onSend = () => {
    onClose();
  };

  return (
    <Modal
      show={show}
      title={`Inutilizar CTE Nr. ${cte?.cdColetaEmbarque}`}
      onClose={() => onSend()}
      size={BootstrapSizes.Medium}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <h6 className='fw-normal mt-3'>
            <span>
              Este CTE será alterado para o valor de R$0,01. Deseja continuar
            </span>
          </h6>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Small}
          icon='check'
          theme={Theme.Success}
          template={Button.Templates.Default}
          onClick={() => onInutilizaCte()}
          text='Confirmar'
        />
        <Button
          size={BootstrapSizes.Small}
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          onClick={() => onSend()}
          text='Sair'
        />
      </Modal.Footer>
    </Modal>
  );
}
