/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Theme, ResponseStatus } from 'ui/Helpers/utils';
import { CSDSelPage, Steps, Autocomplete } from 'ui/components';
import {
  getEmpresasUsuario,
  getEmpresaDefaultUsuario,
} from 'core/services/SEG';
import AniversariantesStep from './Steps/aniversariantesStep';
import DemandasStep from './Steps/demandaStep';
import TitulosStep from './Steps/titulosStep';

export default function DashboardPendencia({
  transaction,
  onOpenPage,
  isActive,
  onOpenTransaction,
  findTransaction,
  onOpenReport,
}) {
  const aniversariantesStepRef = useRef();
  const demandasStepRef = useRef();
  const titulosStepRef = useRef();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const StepHome = () => (
    <div>
      <AniversariantesStep
        ref={aniversariantesStepRef}
        data={data}
        setData={setData}
        setLoading={setLoading}
      />
    </div>
  );

  const StepDemandas = () => (
    <div>
      <DemandasStep
        ref={demandasStepRef}
        data={data}
        setData={setData}
        setLoading={setLoading}
        onOpenTransaction={onOpenTransaction}
        findTransaction={findTransaction}
        onSetMessage={onSetMessage}
      />
    </div>
  );

  const StepTitulos = () => (
    <div>
      <TitulosStep
        ref={titulosStepRef}
        data={data}
        setData={setData}
        setLoading={setLoading}
        onOpenReport={onOpenReport}
        transaction={transaction}
        onSetMessage={onSetMessage}
      />
    </div>
  );

  const step3Aniversariantes = React.createElement(StepHome);
  const step2Demandas = React.createElement(StepDemandas);
  const step1Titulos = React.createElement(StepTitulos);

  const steps = [
    {
      index: 1,
      title: 'Títulos',
      visible: true,
      content: () => step1Titulos,
    },

    {
      index: 2,
      title: 'Demandas Devolvidas',
      visible: true,
      content: () => step2Demandas,
    },
    {
      index: 3,
      title: 'Aniversariantes',
      visible: true,
      content: () => step3Aniversariantes,
    },
  ];

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });

    onSetMessage(status, msg);

    return empresas;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Dashboard Cliente'
      loading={loading}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <Steps
          propriedade={1}
          steps={steps}
          theme={Theme.Primary}
          align='horizontal'
          data={data}
          setData={setData}
        />
      </div>
    </CSDSelPage>
  );
}
