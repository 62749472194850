import Fornecedor from '../FIN/fornecedor';
import Motorista from './motorista';
import Veiculo from './veiculo';
import { Empresa, Cidade, Usuario } from '../SEG';
import ColetaEmbarque from './coletaEmbarque';
import Transportadora from './transportadora';
import FreteMercadoria from './freteMercadoria';
import { FormaPagamento } from '../FIN';
import TipoCartaoVinculo from './tipoCartao_Vinculo';
import FreteTerceiroFatura from './freteTerceiroFatura';
import AcertoMotoristaAdiantamento from '../FIN/acertoMotoristaAdiantamento';

export default class FreteTerceiro {
  constructor(jsonObject = {}) {
    this.nrSeqFreteTerceiro = jsonObject.nrSeqFreteTerceiro;
    this.dtEmissao = jsonObject.dtEmissao;
    this.peso = jsonObject.peso;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.nrSeqPessoaMot = jsonObject.nrSeqPessoaMot;
    this.nrSeqCidadeDest = jsonObject.nrSeqCidadeDest;
    this.vlrFrete = jsonObject.vlrFrete;
    this.vlrPrecoKilo = jsonObject.vlrPrecoKilo;
    this.nrSeqFreteMercadoria = jsonObject.nrSeqFreteMercadoria;
    this.vlrAdiantamento = jsonObject.vlrAdiantamento;
    this.vlrSaldo = jsonObject.vlrSaldo;
    this.obs = jsonObject.obs;
    this.obsComplementar = jsonObject.obsComplementar;
    this.dtCadastro = jsonObject.dtCadastro;
    this.dtAlteracao = jsonObject.dtAlteracao;
    this.nroOrdem = jsonObject.nroOrdem;
    this.vlrSestSenat = jsonObject.vlrSestSenat;
    this.motivoDesconto = jsonObject.motivoDesconto;
    this.vlrDesconto = jsonObject.vlrDesconto;
    this.vlrInssAutonomo = jsonObject.vlrInssAutonomo;
    this.vlrInssEmpresa = jsonObject.vlrInssEmpresa;
    this.vlrIrrf = jsonObject.vlrIrrf;
    this.vlrTerceiro = jsonObject.vlrTerceiro;
    this.nrSeqFreteTerceiroSituacao = jsonObject.nrSeqFreteTerceiroSituacao;
    this.dtPrevistaEntrega = jsonObject.dtPrevistaEntrega;
    this.dtEntrega = jsonObject.dtEntrega;
    this.noCiot = jsonObject.noCiot;
    this.nrSeqFormaPagamentoAdto = jsonObject.nrSeqFormaPagamentoAdto;
    this.dtPagamentoAdto = jsonObject.dtPagamentoAdto;
    this.dtPagamentoSaldo = jsonObject.dtPagamentoSaldo;
    this.nrSeqTipoCartao_Vinculo = jsonObject.nrSeqTipoCartao_Vinculo;
    this.nrProtocoloCiot = jsonObject.nrProtocoloCiot;
    this.flgCancelado = jsonObject.flgCancelado;
    this.nrSeqPessoaInformacaoBancaria =
      jsonObject.nrSeqPessoaInformacaoBancaria;
    this.nrSeqStatusPagtoAdto = jsonObject.nrSeqStatusPagtoAdto;
    this.nrSeqStatusPagtoSaldo = jsonObject.nrSeqStatusPagtoSaldo;
    this.motivoCancelamento = jsonObject.motivoCancelamento;
    this.vlrDescarga = jsonObject.vlrDescarga;
    this.vlrAbatimento = jsonObject.vlrAbatimento;
    this.noAbatimento = jsonObject.noAbatimento;
    this.nrSeqCidadeOri = jsonObject.nrSeqCidadeOri;
    this.nrSeqPessoaProp = jsonObject.nrSeqPessoaProp;
    this.nrSeqFormaPagamentoSaldo = jsonObject.nrSeqFormaPagamentoSaldo;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.nrSeqUnidadeEmissora = jsonObject.nrSeqUnidadeEmissora;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.nrSeqUsuarioAlt = jsonObject.nrSeqUsuarioAlt;
    this.dtEntregaCiot = jsonObject.dtEntregaCiot;
    this.dtEncerramentoCiot = jsonObject.dtEncerramentoCiot;
    this.noCiotEncerramento = jsonObject.noCiotEncerramento;
    this.noStatusCiot = jsonObject.noStatusCiot;
    this.flgTaCarregado = jsonObject.flgTaCarregado;
    this.dtCancelamentoCiot = jsonObject.dtCancelamentoCiot;
    this.noMotivoCancelamentoCiot = jsonObject.noMotivoCancelamentoCiot;
    this.flgCiotDeclaradoPag = jsonObject.flgCiotDeclaradoPag;
    this.flgCiotDeclaradoViagem = jsonObject.flgCiotDeclaradoViagem;
    this.dtEfetivacao = jsonObject.dtEfetivacao;
    this.vlrAbastecimento = jsonObject.vlrAbastecimento;
    this.dtRecebimentoAdto = jsonObject.dtRecebimentoAdto;
    this.dtRecebimentoSaldo = jsonObject.dtRecebimentoSaldo;
    this.cteConcat = jsonObject.cteConcat;
    this.flgCalculaAbastecimento = jsonObject.flgCalculaAbastecimento;

    // auxiliares
    this.fornecedorVale = new Fornecedor(jsonObject.fornecedorVale ?? {});
    this.motorista = new Motorista(jsonObject.motorista ?? {});
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.coletaEmbarqueLista =
      jsonObject.coletaEmbarqueLista?.map((json) => new ColetaEmbarque(json)) ??
      [];
    this.proprietario = new Transportadora(jsonObject.proprietario ?? {});
    this.cidadeOrigem = new Cidade(jsonObject.cidadeOrigem ?? {});
    this.cidadeDestino = new Cidade(jsonObject.cidadeDestino ?? {});
    this.freteMercadoria = new FreteMercadoria(
      jsonObject.freteMercadoria ?? {}
    );
    this.formaPagamentoAdto = new FormaPagamento(
      jsonObject.formaPagamentoAdto ?? {}
    );
    this.fornecedorAdto = new Fornecedor(jsonObject.fornecedorAdto ?? {});
    this.formaPagamentoSaldo = new FormaPagamento(
      jsonObject.formaPagamentoSaldo ?? {}
    );
    this.fornecedorSaldo = new Fornecedor(jsonObject.fornecedorSaldo ?? {});
    this.usuarioCad = new Usuario(jsonObject.usuarioCad ?? {});
    this.usuarioAlt = new Usuario(jsonObject.usuarioAlt ?? {});
    this.tipoCartaoVinculo = new TipoCartaoVinculo(
      jsonObject.tipoCartaoVinculo ?? {}
    );
    this.faturaLista =
      jsonObject.faturaLista?.map((json) => new FreteTerceiroFatura(json)) ??
      [];
    this.acertoMotoristaLista =
      jsonObject.acertoMotoristaLista?.map(
        (json) => new AcertoMotoristaAdiantamento(json)
      ) ?? [];
    this.nrSeqEstado = jsonObject.nrSeqEstado;
    this.status = jsonObject.status;
    this.statusColorText = jsonObject.statusColorText;
    this.flgCiotPend = jsonObject.flgCiotPend;
    this.flgAdtoEmAberto = jsonObject.flgAdtoEmAberto;
    this.flgPgtoEmAberto = jsonObject.flgPgtoEmAberto;
    this.flgEmitidos = jsonObject.flgEmitidos;
    this.flgSomenteNaoEmitidos = jsonObject.flgSomenteNaoEmitidos;
    this.flgAgrupados = jsonObject.flgAgrupados;
    this.flgOptanteSimples = jsonObject.flgOptanteSimples;
    this.flgPessoaFisica = jsonObject.flgPessoaFisica;
    this.flgCampoHabilitado = jsonObject.flgCampoHabilitado;
    this.totalVale = jsonObject.totalVale;
    this.obsVale = jsonObject.obsVale;
    this.noObsCiot = jsonObject.noObsCiot;
    this.dtChequeSaldo = jsonObject.dtChequeSaldo;
    this.nrChequeSaldo = jsonObject.nrChequeSaldo;
    this.dtCheque = jsonObject.dtCheque;
    this.nrCheque = jsonObject.nrCheque;
    this.noTipoIntegracao = jsonObject.noTipoIntegracao;
    this.noEmpresaIntegracao = jsonObject.noEmpresaIntegracao;
    this.listaChequesAdto = jsonObject.listaChequesAdto;
  }

  toJson = () => JSON.stringify(this);
}
