import ContaCorrente from 'core/models/FIN/contaCorrente';
import Usuario from 'core/models/SEG/usuario';

export default class UsuarioContaCorrente {
  constructor(jsonObject = {}) {
    this.nrSeqContaCorrente = jsonObject.nrSeqContaCorrente;
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.usuario = new Usuario(jsonObject.usuario ?? {});
    this.contaCorrente = new ContaCorrente(jsonObject.contaCorrente ?? {});

    // #region Propriedades Auxiliares
    this.nrSeqContaCorrenteS = jsonObject.nrSeqContaCorrenteS;
    this.listaContaCorrenteString = jsonObject.listaContaCorrenteString;
    this.contaCorrenteS = jsonObject.contaCorrenteS
      ? jsonObject.contaCorrenteS.map((item) => new ContaCorrente(item ?? {}))
      : [];
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
