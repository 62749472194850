import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Textbox, Switch } from 'ui/components';
import {
  getConvenioEncargo,
  saveConvenioEncargo,
  deleteConvenioEncargo,
} from 'core/services/ASS/convenioEncargo';

import { getConvenioAutoComplete } from 'core/services/ASS/convenio';
import { getTipoConvenioEncargoAutoComplete } from 'core/services/ASS/tipoConvenioEncargo';

export default function ConvenioEncargoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getConvenioEncargo(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveConvenioEncargo(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteConvenioEncargo(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchConvenio = async (e) => {
    const { status, message: msg, convenios } = await getConvenioAutoComplete({
      noConvenio: e,
    });
    if (msg) onSetMessage(status, msg);
    return convenios;
  };

  const onSearchTipoConvenioEncargo = async (e) => {
    const {
      status,
      message: msg,
      tipoConvenioEncargos,
    } = await getTipoConvenioEncargoAutoComplete({ noTipoConvenioEncargo: e });
    if (msg) onSetMessage(status, msg);
    return tipoConvenioEncargos;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção - Convênio Encargo'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqConvenioEncargo > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='Convênio'
            searchDataSource={onSearchConvenio}
            selectedItem={data.convenio}
            onSelectItem={(convenio) => {
              setData({
                ...data,
                convenio,
                nrSeqConvenio: convenio.nrSeqConvenio,
              });
            }}
            dataSourceTextProperty='noConvenio'
          />
        </div>
        <div className='col mb-3'>
          <Autocomplete
            label='Tipo Convênio Encargo'
            searchDataSource={onSearchTipoConvenioEncargo}
            selectedItem={data.tipoConvenioEncargo}
            onSelectItem={(tipoConvenioEncargo) => {
              setData({
                ...data,
                tipoConvenioEncargo,
                nrSeqTipoConvenioEncargo:
                  tipoConvenioEncargo.nrSeqTipoConvenioEncargo,
              });
            }}
            dataSourceTextProperty='noTipoConvenioEncargo'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-10 mb-3'>
          <Textbox
            maxLength={60}
            label='Nome'
            text={data.noConvenioEncargo}
            onChangedValue={(noConvenioEncargo) =>
              setData({ ...data, noConvenioEncargo })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Origem Convênio'
            checked={data.flgOrigemConvenio}
            onChange={(flgOrigemConvenio) =>
              setData({ ...data, flgOrigemConvenio })
            }
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
