import React, { useEffect, useState } from 'react';
import { Modal, Button, Textbox, Switch, DatePicker } from 'ui/components';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import ColetaEmbarque from 'core/models/FRO/coletaEmbarque';
import { MaskTypes } from 'ui/Helpers/masks';

export default function ModalDetalhesConhecimento(props) {
  const { show, data } = props;
  const [coletaEmbarque, setColetaEmbarque] = useState(new ColetaEmbarque({}));

  useEffect(() => {
    if (data !== undefined) setColetaEmbarque(data);
  }, [show]);

  const onSend = () => {
    props.onClose();
  };

  return (
    <Modal
      show={show}
      title='Detalhes do Conhecimento'
      onClose={() => onSend()}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body>
        <div className='row'>
          <div className='row mb-3'>
            <div className='col-3'>
              <Textbox
                label='Tipo de Embarque'
                readOnly
                text={coletaEmbarque.tipoEmbarque?.noTipoEmbarque}
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Tipo de Frete'
                readOnly
                text={coletaEmbarque.tipoFrete?.noTipoFrete}
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='Espécie de Frete'
                readOnly
                text={coletaEmbarque.especieFrete?.noEspecieFrete}
              />
            </div>
            <div className='col-4'>
              <Textbox
                label='Mercadoria Principal'
                readOnly
                text={coletaEmbarque.mercadoriaPrincipal?.noFreteMercadoria}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-8'>
              <Textbox
                label='Chave do CTE Referenciado'
                readOnly
                text={coletaEmbarque.chaveCteRef}
              />
            </div>
            <div className='col-2'>
              <Switch
                formControl
                label='Comissão'
                checked={coletaEmbarque.flgComissao}
              />
            </div>
            <div className='col-2'>
              <Switch
                formControl
                label='Seguro'
                checked={coletaEmbarque.flgGeraSeguro}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-2'>
              <Textbox
                label='Tarifa'
                readOnly
                mask={MaskTypes.DecimalCustom}
                text={coletaEmbarque.vlrTarifa}
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Frete'
                readOnly
                text={coletaEmbarque.vlrfrete}
                mask={MaskTypes.DecimalCustom}
              />
            </div>
            <div className='col-2'>
              <Textbox label='Marca' readOnly text={coletaEmbarque.noMarca} />
            </div>
            <div className='col-3'>
              <Textbox
                label='Apólice de Seguro'
                readOnly
                text={coletaEmbarque.apoliceSeguro?.noAutoComplete}
              />
            </div>
            <div className='col-3'>
              <DatePicker
                label='Previsão Entrega'
                readOnly
                text={coletaEmbarque.dtPrevisaoEntrega}
                maxLength={10}
                mask={MaskTypes.Date}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-2'>
              <Textbox
                label='Remessa'
                readOnly
                text={coletaEmbarque.vlrAdValorRem}
                mask={MaskTypes.DecimalCustom}
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Despacho'
                readOnly
                text={coletaEmbarque.vlrDespacho}
                mask={MaskTypes.DecimalCustom}
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Outros'
                readOnly
                text={coletaEmbarque.vlrOutros}
                mask={MaskTypes.DecimalCustom}
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Pedágio'
                readOnly
                text={coletaEmbarque.vlrPedagio}
                mask={MaskTypes.DecimalCustom}
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='SEC'
                readOnly
                text={coletaEmbarque.vlrSec}
                mask={MaskTypes.DecimalCustom}
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='SEC/CAT'
                readOnly
                text={coletaEmbarque.vlrSecCat}
                mask={MaskTypes.DecimalCustom}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-2'>
              <Textbox
                label='Base ICMS'
                readOnly
                text={coletaEmbarque.vlrBaseCalIcms}
                mask={MaskTypes.DecimalCustom}
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Aliquota ICMS'
                readOnly
                text={coletaEmbarque.aliqIcms}
                mask={MaskTypes.DecimalCustom}
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Valor ICMS'
                readOnly
                text={coletaEmbarque.vlrIcms}
                mask={MaskTypes.DecimalCustom}
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Quantidade'
                readOnly
                text={coletaEmbarque.qtdeEmbarque}
                mask={MaskTypes.DecimalCustom}
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Frete Alterado'
                readOnly
                text={coletaEmbarque.vlrFretePeso}
                mask={MaskTypes.DecimalCustom}
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Total Frete'
                readOnly
                text={coletaEmbarque.vlrTotal}
                mask={MaskTypes.DecimalCustom}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              <Textbox
                label='Observação'
                readOnly
                text={coletaEmbarque.observacao}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-4'>
              <Textbox label='CFO' readOnly text={coletaEmbarque.cfo?.noCfo} />
            </div>
            <div className='col-8'>
              <Textbox
                label='Mensagem CFO'
                readOnly
                text={coletaEmbarque.cfoMensagem}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              <Textbox
                label='Mensagem Grupo ICMS'
                readOnly
                text={coletaEmbarque.grupoIcmsMnsg}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Medium}
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          onClick={() => onSend()}
          text='Sair'
        />
      </Modal.Footer>
    </Modal>
  );
}
