import React, { useEffect, useState } from 'react';
import { ResponseStatus, Theme } from 'ui/Helpers/enums';
import { BootstrapSizes } from 'ui/Helpers/utils';
import {
  Modal,
  Notification,
  Button,
  Panel,
  Autocomplete,
  Textbox,
  RadioButton,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { postGerarFaturamentoMassivo } from 'core/services/FRO/conhecimentosFaturar';
import { ICondicaoPagamento } from 'core/interfaces/FIN/condicaoPagamento';
import { IFormaPagamento } from 'core/interfaces/FIN/formaPagamento';
import { IConhecimentosFaturar } from 'core/interfaces/FRO/conhecimentosFaturar';
import {
  getCondicaoPagamentoAutoComplete,
  getFormaPagamentoAutoComplete,
} from '../../../../core/services/FIN';

interface IModalGerarFaturamentoMassivo {
  show: boolean;
  onClose: any;
  coletasLista: any;
}

interface IData {
  vlrTotalFatura: number;
  dataBaseVenc: IConhecimentosFaturar['dataBaseVenc'];
  condicaoPagamento: ICondicaoPagamento;
  nrSeqCondicaoPagamento: ICondicaoPagamento['nrSeqCondicaoPagamento'];
  formaPagamento: IFormaPagamento;
  nrSeqFormaPagamento: IFormaPagamento['nrSeqFormaPagamento'];
}

export default function ModalGerarFaturamentoMassivo({
  show,
  onClose,
  coletasLista,
}: IModalGerarFaturamentoMassivo) {
  const [message, setMessage] = useState<any>({});
  // eslint-disable-next-line no-undef
  const [data, setData] = useState<Partial<IData>>({
    vlrTotalFatura: 0,
    dataBaseVenc: 'DtEmissao',
    condicaoPagamento: undefined,
    nrSeqCondicaoPagamento: undefined,
    formaPagamento: undefined,
    nrSeqFormaPagamento: undefined,
  });

  const buttonsDataBaseVencimento = [
    { text: 'Data de Emissão', value: 'DtEmissao' },
    { text: 'Data de Hoje', value: 'DtHoje' },
  ];

  useEffect(() => {
    if (coletasLista !== undefined && coletasLista.length > 0) {
      const vlrTotalFatura = coletasLista.reduce(
        (acum: number, item: any) => acum + item.vlrTotal,
        0
      );
      setData({ ...data, vlrTotalFatura });
    }
  }, [coletasLista]);

  const onSetMessage = (status: number, messageShow: string) => {
    if (messageShow)
      setMessage({
        message: messageShow,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchCondicaoPagamento = async (e: any) => {
    const {
      status,
      message: msg,
      condicoesPagamento,
    } = await getCondicaoPagamentoAutoComplete({
      noCondicaoPagamento: e,
      flgTitRec: true,
    });
    if (msg) onSetMessage(status, msg);
    return condicoesPagamento;
  };

  const onSearchFormaPagamento = async (e: any) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noformaPagamento: e,
    });
    onSetMessage(status, msg);
    return formasPagamento;
  };

  const onGerarFaturamentoMassivo = async (): Promise<void> => {
    const conhecimentosFaturar: IConhecimentosFaturar[] = [];
    coletasLista.forEach(
      (elem: {
        vlrTotal: number;
        empresa: { nrSeqEmpresa: number };
        cliente: { nrSeqPessoaCli: number };
      }) => {
        const faturaObj = {
          cliente: elem.cliente,
          formaPagamento: data.formaPagamento,
        };

        const conhecimento: IConhecimentosFaturar = {
          conhecimentos: [elem],
          parcelas: [],
          vlrTotalFatura: elem.vlrTotal,
          fatura: faturaObj,
          nrSeqEmpresa: elem.empresa.nrSeqEmpresa,
          empresa: elem.empresa,
          nrSeqPessoacli: elem.cliente.nrSeqPessoaCli,
          cliente: elem.cliente,
          nrSeqCondicaoPagamento: data.nrSeqCondicaoPagamento,
          condicaoPagamento: data.condicaoPagamento,
          nrSeqFormaPagamento: data.nrSeqFormaPagamento,
          formaPagamento: data.formaPagamento,
          dataBaseVenc: data.dataBaseVenc,
        };
        conhecimentosFaturar.push(conhecimento);
      }
    );

    const { status, message: msg } = await postGerarFaturamentoMassivo(
      conhecimentosFaturar
    );

    if (status === ResponseStatus.Success) {
      onSetMessage(status, msg);
      setData({
        vlrTotalFatura: 0,
        dataBaseVenc: data.dataBaseVenc,
        condicaoPagamento: undefined,
        nrSeqCondicaoPagamento: undefined,
        formaPagamento: undefined,
        nrSeqFormaPagamento: undefined,
      });
    } else {
      onSetMessage(status, msg);
    }
  };

  return (
    //  @ts-expect-error
    <Modal
      show={show}
      title='Gerar Faturamento Massivo'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      {/* @ts-expect-error */}
      <Modal.Body>
        {message && (
          <>
            <div className='row mb-3'>
              <div className='col'>
                {/* @ts-expect-error */}
                <Notification
                  message={message.message}
                  theme={message.theme}
                  onClose={() => setMessage(null)}
                />
              </div>
            </div>
          </>
        )}

        {/* FORMAS DE PAGAMENTO */}
        <div className='row mb-3'>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header title='Formas de Pagamento' theme={Theme.Primary} />
            <Panel.Body>
              {/* Linha 1 */}
              <div className='row mb-3'>
                <div className='col-2 mb-3'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Total a Faturar:'
                    maxLength={20}
                    readOnly
                    text={data?.vlrTotalFatura}
                    mask={MaskTypes.Currency}
                  />
                </div>

                <div className='col'>
                  {/* @ts-expect-error */}
                  <RadioButton
                    label='Data de Vencimento com base:'
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    selectedButton={data?.dataBaseVenc}
                    buttons={buttonsDataBaseVencimento}
                    onChange={(
                      dataBaseVenc: IConhecimentosFaturar['dataBaseVenc']
                    ) => setData({ ...data, dataBaseVenc })}
                  />
                </div>

                <div className='col'>
                  <Autocomplete
                    label='Condições de Pagamento:'
                    searchDataSource={onSearchCondicaoPagamento}
                    selectedItem={data?.condicaoPagamento}
                    onSelectItem={(condicaoPagamento: ICondicaoPagamento) =>
                      setData({
                        ...data,
                        condicaoPagamento,
                        nrSeqCondicaoPagamento:
                          condicaoPagamento?.nrSeqCondicaoPagamento,
                      })
                    }
                    dataSourceTextProperty='noCondicaoPagamento'
                  />
                </div>

                <div className='col'>
                  <Autocomplete
                    label='Forma de Pagamento:'
                    searchDataSource={onSearchFormaPagamento}
                    selectedItem={data?.formaPagamento}
                    onSelectItem={(formaPagamento: IFormaPagamento) =>
                      setData({
                        ...data,
                        formaPagamento,
                        nrSeqFormaPagamento:
                          formaPagamento?.nrSeqFormaPagamento,
                      })
                    }
                    dataSourceTextProperty='noFormaPagamento'
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Gerar Faturamento'
          theme={Theme.Success}
          onClick={onGerarFaturamentoMassivo}
        />
        <Button text='Cancelar' theme={Theme.Danger} onClick={onClose} />
      </Modal.Footer>
    </Modal>
  );
}
