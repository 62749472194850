export default class EnergiaContaEncargo {
  nrSeqEnergiaContaEncargo: number;

  noEnergiaContaEncargo: string;

  cdEnergiaContaEncargo: string;

  unidadeMedidaEncargo: string;

  qtdeFaturada: number;

  vlrTarifa: number;

  vlrTarifaTributos: number;

  vlrTotal: number;

  vlrBaseCalIcms: number;

  vlrAliqIcms: number;

  vlrIcms: number;

  vlrPis: number;

  vlrCofins: number;

  nrSeqEnergiaConta: number;

  constructor(jsonObject: EnergiaContaEncargo) {
    this.nrSeqEnergiaContaEncargo = jsonObject.nrSeqEnergiaContaEncargo;
    this.noEnergiaContaEncargo = jsonObject.noEnergiaContaEncargo;
    this.cdEnergiaContaEncargo = jsonObject.cdEnergiaContaEncargo;
    this.unidadeMedidaEncargo = jsonObject.unidadeMedidaEncargo;
    this.qtdeFaturada = jsonObject.qtdeFaturada;
    this.vlrTarifa = jsonObject.vlrTarifa;
    this.vlrTarifaTributos = jsonObject.vlrTarifaTributos;
    this.vlrTotal = jsonObject.vlrTotal;
    this.vlrBaseCalIcms = jsonObject.vlrBaseCalIcms;
    this.vlrAliqIcms = jsonObject.vlrAliqIcms;
    this.vlrIcms = jsonObject.vlrIcms;
    this.vlrPis = jsonObject.vlrPis;
    this.vlrCofins = jsonObject.vlrCofins;
    this.nrSeqEnergiaConta = jsonObject.nrSeqEnergiaConta;
  }

  toJson = () => JSON.stringify(this);
}
