import Endereco from 'core/models/SEG/endereco';
import PessoaFisica from 'core/models/SEG/pessoaFisica';
import PessoaJuridica from 'core/models/SEG/pessoaJuridica';
import PessoaEndereco from './pessoaEndereco';
import Contato from '../FIN/contato';

export default class Pessoa {
  constructor(jsonObject = {}) {
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.flgFisica = jsonObject.flgFisica;
    this.noPessoa = jsonObject.noPessoa;
    this.dtAlteracao = jsonObject.dtAlteracao;
    this.dtCadastro = jsonObject.dtCadastro;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgRamoAgropecuario = jsonObject.flgRamoAgropecuario;
    this.flgContribuinteICMS = jsonObject.flgContribuinteICMS;
    this.flgEnviaContabilidade = jsonObject.flgEnviaContabilidade;
    this.flgSubstitutoTributario = jsonObject.flgSubstitutoTributario;
    this.flgGeraESocial = jsonObject.flgGeraESocial;
    this.noFantasia = jsonObject.noFantasia;
    this.nrRG = jsonObject.nrRG;
    this.nrPIS = jsonObject.nrPIS;
    this.pessoaFisica = new PessoaFisica(jsonObject.pessoaFisica ?? {});
    this.pessoaJuridica = new PessoaJuridica(jsonObject.pessoaJuridica ?? {});
    this.pessoaEndereco = new PessoaEndereco(jsonObject.pessoaEndereco ?? {});
    this.endereco = new Endereco(jsonObject.endereco ?? {});
    this.informacoesBancarias = jsonObject.informacoesBancarias ?? {};
    this.enderecoAdicionais = jsonObject.enderecoAdicionais
      ? jsonObject.enderecoAdicionais.map((item) => new Endereco(item ?? {}))
      : [];

    // #region Propriedades Auxiliares
    this.documento = jsonObject.documento;
    this.pessoaImagem = jsonObject.pessoaImagem ?? {};
    this.contato = new Contato(jsonObject.contato ?? {});
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
