import TipoCombustivel from 'core/models/FRO/tipoCombustivel';

export default class PostoCombustivelTipoCombustiv {
  constructor(jsonObject = {}) {
    this.nrSeqPessoaPos = jsonObject.nrSeqPessoaPos;
    this.nrSeqTipoCombustivel = jsonObject.nrSeqTipoCombustivel;
    this.vlrLitro = jsonObject.vlrLitro;
    this.status = jsonObject.status;
    this.tipoCombustivel = new TipoCombustivel(
      jsonObject.tipoCombustivel ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
