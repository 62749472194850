import React, { useState, useRef, useEffect } from 'react';
import {
  BootstrapSizes,
  ResponseStatus,
  Theme,
  dateNow,
} from 'ui/Helpers/utils';
import { MaskTypes, mask } from 'ui/Helpers/masks';
import {
  Modal,
  DropdownList,
  DatePicker,
  Textbox,
  Notification,
  Button,
  GridView,
  Loader,
} from 'ui/components';
import ColetaFatura from 'core/models/FRO/coletaEmbarqueFatura';

import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';

export default function ModalFaturas(props) {
  const { show, data } = props;
  const gridViewFaturas = useRef();
  const [dropDownFormaPagamento, setDropDownFormaPagamento] = useState([]);
  const [message, setMessage] = useState(null);
  const [totalFaturas, setTotalFaturas] = useState(0);
  const [loading, setLoading] = useState(false);
  const [coletaFatura, setColetaFatura] = useState(
    new ColetaFatura({
      dtVencimento: dateNow(),
    })
  );

  const onSend = () => {
    setTotalFaturas(0);

    if (gridViewFaturas && gridViewFaturas.current) {
      const faturas = gridViewFaturas.current.getDataSource();
      props.onSave(faturas);
    }

    props.onClose();
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const recalculaTotalFaturas = (datasource) => {
    let total = 0;

    for (let i = 0; i < datasource.length; i += 1) {
      if (datasource[i].status !== 'Remover') {
        total += datasource[i].vlrFatura;
      }
    }

    return total;
  };

  const searchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
    });

    if (msg) onSetMessage(status, msg);

    return formasPagamento;
  };

  const load = async () => {
    setLoading(true);

    if (data !== undefined) {
      if (gridViewFaturas && gridViewFaturas.current) {
        gridViewFaturas.current.setDataSource(data);

        setTotalFaturas(recalculaTotalFaturas(data));
      }

      setDropDownFormaPagamento(await searchFormaPagamento());
    }

    setLoading(false);
  };

  useEffect(() => {
    load();
  }, [show]);

  const onAdicionaFatura = () => {
    const dataSource = gridViewFaturas.current.getDataSource();
    coletaFatura.status = coletaFatura.status ?? GridView.EnumStatus.Inserir;
    dataSource.push(coletaFatura);

    if (gridViewFaturas && gridViewFaturas.current)
      gridViewFaturas.current.setDataSource(dataSource);

    setTotalFaturas(totalFaturas + coletaFatura.vlrFatura);

    setColetaFatura(
      new ColetaFatura({
        dtVencimento: dateNow(),
        vlrFatura: null,
      })
    );
  };

  const onClickEditFatura = (e, datasource) => {
    const dataFatura = datasource.find((item) => item === e);
    dataFatura.status = GridView.EnumStatus.Alterar;

    const dataFaturas = datasource.filter((item) => item !== e);

    if (gridViewFaturas && gridViewFaturas.current)
      gridViewFaturas.current.setDataSource(dataFaturas);

    setTotalFaturas(recalculaTotalFaturas(dataFaturas));

    setColetaFatura(dataFatura);
  };

  const onClickRemoveFatura = (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (gridViewFaturas && gridViewFaturas.current)
      gridViewFaturas.current.setDataSource(datasource);

    setTotalFaturas(recalculaTotalFaturas(datasource));
  };

  const columnsFaturas = [
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'vlrFatura',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
    { key: 'formaPagamento.noFormaPagamento', title: 'Forma Pagamento' },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditFatura(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveFatura(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onBlurNrDias = () => {
    setLoading(true);

    const dataSplit = coletaFatura.dtVencimento.split('-');

    const dataFinal = new Date(dataSplit[0], dataSplit[1] - 1, dataSplit[2]);
    dataFinal.setDate(dataFinal.getDate() + coletaFatura.nrDias);

    setColetaFatura({
      ...coletaFatura,
      dtVencimento: dataFinal.toISOString().split('T')[0],
    });

    setLoading(false);
  };

  return (
    <Modal
      show={show}
      title='Adicionar Fatura'
      onClose={() => onSend()}
      size={BootstrapSizes.ExtraLarge}
      icon={['far', 'credit-card']}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='alert alert-info' role='alert'>
          <div className='row'>
            <div className='col-4 fs-6'>
              <span className='fw-bold'>Resumo Fatura</span>
            </div>
            <div className='col-4 mt-1 float-right fs-8'>
              <div className='col-auto'>
                <span className='fw-bold'>
                  Total Faturas: {mask(totalFaturas, MaskTypes.Currency)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='row mb-3'>
            <div className='col-3'>
              <DatePicker
                label='Vencimento'
                text={coletaFatura.dtVencimento}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtVencimento) =>
                  setColetaFatura({
                    ...coletaFatura,
                    dtVencimento,
                  })
                }
              />
            </div>
            <div className='col-1'>
              <Textbox
                label='Dias'
                onBlur={onBlurNrDias}
                text={coletaFatura.nrDias}
                mask={MaskTypes.Integer}
                onChangedValue={(nrDias) => {
                  setColetaFatura({
                    ...coletaFatura,
                    nrDias,
                  });
                }}
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='Valor Parcela'
                text={coletaFatura.vlrFatura}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrFatura) =>
                  setColetaFatura({
                    ...coletaFatura,
                    vlrFatura,
                  })
                }
              />
            </div>
            <div className='col-3'>
              <DropdownList
                label='Forma Pagamento'
                dataSource={dropDownFormaPagamento}
                dataSourcePropertyText='noFormaPagamento'
                dataSourcePropertyValue='nrSeqFormaPagamento'
                selectedItems={coletaFatura.formaPagamento ?? []}
                onSelectItem={(formaPagamento) =>
                  setColetaFatura({
                    ...coletaFatura,
                    nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                    formaPagamento,
                  })
                }
              />
            </div>
            <div className='col mt-4'>
              <Button
                icon='plus'
                text='Adicionar Fatura'
                theme={Theme.Success}
                template={Button.Templates.Default}
                onClick={onAdicionaFatura}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <GridView
                ref={gridViewFaturas}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columnsFaturas}
                offlineData
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Medium}
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          onClick={() => onSend()}
          text='Sair'
        />
      </Modal.Footer>
    </Modal>
  );
}
