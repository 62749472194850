import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Theme, ResponseStatus } from 'ui/Helpers/utils';
import { CSDManutPage, Textarea, Textbox, Button } from 'ui/components';
import { EnvioProcessamentoContas } from 'core/models/TEL';

import {
  getEnvioProcessamentoContas,
  postEnvioProcessamentoContas,
} from 'core/services/TEL/envioprocessamentocontas';

import ModalQRCode from 'ui/components/WhatsApp/modalQRCode';

import PanelRelSinteticoFinanceiro from './panelRelSinteticoFinanceiro';
import PanelArquivoIntegracao from './panelArquivoIntegracao';
import PanelInformacoesGerais from './panelInformacoesGerais';
import PanelRelAnaliticoServicosLigacoes from './panelRelAnaliticoServicosLigacoes';
import PanelRelFinanceiroSubsidio from './panelRelFinanceiroSubsidio';
import PanelGerarContasPDF from './panelGerarContasPDF';

export default function SelecaoParametroEnvioProcessamentoConta({
  isActive,
  transaction,
}) {
  const [parametros, setParametros] = useState({});
  const [state, setState] = useState({ viewQrCode: false, viewStatus: false });
  const [loading, setLoading] = useState(false);
  const [messageState, setMessage] = useState(null);

  const panelArquivoIntegracaoRef = useRef({});
  const panelRelSinteticoFinanceiroRef = useRef({});
  const panelInformacoesGeraisRef = useRef({});
  const panelRelAnaliticoServicosLigacoesRef = useRef({});
  const panelGerarContasPDFRef = useRef({});
  const panelRelFinanceiroSubsidioRef = useRef({});

  const exibirDataFaturamento = false;
  const exibirPaginaBranca = false;

  const searchParametroEnvioProcessamentoConta = async () => {
    const param = await getEnvioProcessamentoContas();
    setParametros(param);
  };

  const loadStatus = useCallback(async () => {}, []);

  useEffect(() => {
    setParametros(new EnvioProcessamentoContas());
    searchParametroEnvioProcessamentoConta();
  }, []);

  const set = () => {
    setState({ ...state, viewQrCode: true });
  };

  const onSave = async () => {
    const obj = {
      email: parametros.email,
      notitulo: parametros.notitulo,
      nomensagem: parametros.nomensagem,
      nrseqparamenvioprocessamentoconta:
        parametros.nrseqparamenvioprocessamentoconta,
      ...panelRelAnaliticoServicosLigacoesRef.current.param,
      ...panelInformacoesGeraisRef.current.param,
      ...panelArquivoIntegracaoRef.current.param,
      ...panelRelSinteticoFinanceiroRef.current.param,
      ...panelGerarContasPDFRef.current.param,
      ...panelRelFinanceiroSubsidioRef.current.param,
    };

    const { status, message } = await postEnvioProcessamentoContas(obj);

    setMessage({
      message,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
    searchParametroEnvioProcessamentoConta();
  };

  return (
    <CSDManutPage
      isActive={isActive}
      onSave={onSave}
      loading={loading}
      message={messageState}
      transaction={transaction}
      title='Parametros Envio Processamento Contas'
    >
      <div className='row mb-3'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body'>
              <div className='row mb-3'>
                <div className='col-6'>
                  <Textbox
                    label='E-mails de Destino'
                    text={parametros.email}
                    onChangedValue={(email) =>
                      setParametros({
                        ...parametros,
                        email,
                      })
                    }
                  />
                </div>
                <div className='col-4'>
                  <Textbox
                    label='Título E-mail'
                    text={parametros.notitulo}
                    onChangedValue={(notitulo) =>
                      setParametros({
                        ...parametros,
                        notitulo,
                      })
                    }
                  />
                </div>
                <div className='col-2'>
                  <Textbox label='Celular Cliente' />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-12'>
                  <Textarea
                    label={
                      'Mensagem do E-mail (Dica: a-Para tags html utilizar "%" ao invés de "<" e " & " ao invés de ">"; b-Utilizar a tag %assinatura & e fechar com %/assinatura& para informar a assinatura do e-mail)'
                    }
                    text={parametros.nomensagem}
                    onChangedValue={(nomensagem) =>
                      setParametros({
                        ...parametros,
                        nomensagem,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-12'>
        <div className='card'>
          <div className='card-body'>
            <PanelInformacoesGerais
              props={parametros}
              ref={panelInformacoesGeraisRef}
            />
          </div>
        </div>
      </div>
      <div className='col-12'>
        <div className='card'>
          <div className='card-body'>
            <Button
              theme={Theme.Info}
              template={Button.Templates.Default}
              text='Gerar QRCode'
              onClick={set}
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='accordion' id='accordionRelSinteticoFinanceiro'>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='relSinteticoFinanceiro'>
                <button
                  className='accordion-button col-12'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseRelSinteticoFinanceiro'
                  aria-expanded='true'
                  aria-controls='collapseRelSinteticoFinanceiro'
                >
                  Relatório Sintético Financeiro
                </button>
              </h2>
              <div
                id='collapseRelSinteticoFinanceiro'
                className='accordion-collapse collapse show'
              >
                <div className='accordion-body'>
                  <PanelRelSinteticoFinanceiro
                    props={parametros}
                    ref={panelRelSinteticoFinanceiroRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='accordion' id='accordionArquivoIntegracao'>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='arquivoIntegracao'>
                <button
                  className='accordion-button col-12'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseArquivoIntegracao'
                  aria-expanded='true'
                  aria-controls='collapseArquivoIntegracao'
                >
                  Arquivo de Integração
                </button>
              </h2>
              <div
                id='collapseArquivoIntegracao'
                className='accordion-collapse collapse show'
              >
                <div className='accordion-body'>
                  <PanelArquivoIntegracao
                    props={parametros}
                    exibirDataFaturamento={exibirDataFaturamento}
                    ref={panelArquivoIntegracaoRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='accordion' id='accordionRelAnaliticoServicoLigacoes'>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='relAnaliticoServicoLigacoes'>
                <button
                  className='accordion-button col-12'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseRelAnaliticoServicoLigacoes'
                  aria-expanded='true'
                  aria-controls='collapseRelAnaliticoServicoLigacoes'
                >
                  Relatório Analitico Serviços e Ligações
                </button>
              </h2>
              <div
                id='collapseRelAnaliticoServicoLigacoes'
                className='accordion-collapse collapse show'
              >
                <div className='accordion-body'>
                  <PanelRelAnaliticoServicosLigacoes
                    props={parametros}
                    ref={panelRelAnaliticoServicosLigacoesRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='accordion' id='accordionRelFinanceiroSubsidio'>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='relFinanceiroSubsidio'>
                <button
                  className='accordion-button col-12'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseRelFinanceiroSubsidio'
                  aria-expanded='true'
                  aria-controls='collapseRelFinanceiroSubsidio'
                >
                  Relatório Financeiro com Subsidio
                </button>
              </h2>
              <div
                id='collapseRelFinanceiroSubsidio'
                className='accordion-collapse collapse show'
              >
                <div className='accordion-body'>
                  <PanelRelFinanceiroSubsidio
                    props={parametros}
                    ref={panelRelFinanceiroSubsidioRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='accordion' id='accordionGerarContasPDF'>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='gerarContasPDF'>
                <button
                  className='accordion-button col-12'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseGerarContasPDF'
                  aria-expanded='true'
                  aria-controls='collapseGerarContasPDF'
                >
                  Gera contas PDF
                </button>
              </h2>
              <div
                id='collapseGerarContasPDF'
                className='accordion-collapse collapse show'
              >
                <div className='accordion-body'>
                  <PanelGerarContasPDF
                    props={parametros}
                    exibirPaginaBranca={exibirPaginaBranca}
                    ref={panelGerarContasPDFRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalQRCode
        show={state.viewQrCode}
        onClose={() => {
          setState({ ...state, viewQrCode: false });
          loadStatus();
        }}
      />
    </CSDManutPage>
  );
}
