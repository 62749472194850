import qs from 'qs';
import Pagination from 'core/models/pagination';
import TipoCargaMdfe from 'core/models/FRO/tipoCargaMdfe';
import api from '../api';

const baseURL = `/FRO/TipoCargaMdfe`;

export const getTipoCargaMdfeList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TipoCargaMdfe(json)) ?? [],
  };
};

export const getTipoCargaMdfeAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TipoCargaMdfe(json)) ?? [],
  };
};

export const getTipoCargaMdfePagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TipoCargaMdfe(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getTipoCargaMdfe = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new TipoCargaMdfe(value) : {};
};

export const saveTipoCargaMdfe = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteTipoCargaMdfe = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printTipoCargaMdfe = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelTipoCargaMdfe = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllTipoCargaMdfe = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
