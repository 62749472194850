import React, { useState } from 'react';

import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { Button, Modal, Textarea } from 'ui/components';

export default function ModalIteracao({ show, onClose, onEnvioIteracao }) {
  const [data, setData] = useState();
  const onEnviar = () => {
    onEnvioIteracao(data.noIteracao);
  };

  return (
    <Modal
      show={show}
      title='Adicionar Iteração'
      onClose={onClose}
      size={BootstrapSizes.Large}
    >
      <Modal.Body className='p-4 pt-3'>
        <div className='row mb-3'>
          <div className='col'>
            <Textarea
              maxLength={1000}
              text={data.noIteracao}
              onChangedValue={(noIteracao) => {
                setData({
                  ...data,
                  noIteracao,
                });
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Sim'
          theme={Theme.Success}
          icon={['far', 'save']}
          onClick={() => onEnviar()}
        />
        <Button
          text='Fechar'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
