import React, { useRef, forwardRef, useEffect } from 'react';

import { FontAwesomeIcon } from 'ui/components';
import { MimeTypes, generateID } from 'ui/Helpers/utils';

function preventDefaults(e) {
  e.preventDefault();
  e.stopPropagation();
}
function dragEnter(e) {
  preventDefaults(e);
  e.currentTarget.classList.add('hover');
}
function dragLeave(e) {
  preventDefaults(e);
  e.currentTarget.classList.remove('hover');
}

function applyEffectClass(element) {
  element.current.classList.add('effect');
  setTimeout(() => {
    element.current.classList.remove('effect');
  }, 400);
}
function getAllowedMimeTypesText(allowedMimeTypes) {
  if (allowedMimeTypes.length === 0) return '';

  return (
    <>
      <span> do(s) tipo(s) (</span>
      <b>
        {allowedMimeTypes
          .map((p) => (MimeTypes.isValid(p) ? p : 'TIPO INVÁLIDO'))
          .join(', ')}
      </b>
      <span>)</span>
    </>
  );
}

const Dropzone = forwardRef((props, ref) => {
  const filezone = useRef();
  const maxFilesText = useRef();

  const {
    files,
    onChange,
    maxFiles = 5,
    allowedMimeTypes = [],
    getIconByFileType,
  } = props;

  const handleDrop = (e) => {
    let applyErrorEffect = false;
    e.currentTarget.classList.remove('hover');

    e.preventDefault();
    e.stopPropagation();

    const validFiles =
      allowedMimeTypes.length === 0
        ? e.dataTransfer.files
        : MimeTypes.getValidFiles([...e.dataTransfer.files], allowedMimeTypes);
    if (validFiles.length !== e.dataTransfer.files.length)
      applyErrorEffect = true;

    let newFiles = [...files, ...validFiles];
    if (newFiles.length > maxFiles) {
      applyErrorEffect = true;
      newFiles = newFiles.slice(0, maxFiles);
    }

    if (applyErrorEffect) applyEffectClass(maxFilesText);

    onChange(newFiles);
  };

  useEffect(() => {
    if (ref.current) {
      if (!ref.current.onChange) {
        ref.current.onchange = (e) => {
          e.preventDefault();
          e.stopPropagation();

          let newFiles = [...files, ...e.target.files];
          if (newFiles.length > maxFiles) {
            newFiles = newFiles.slice(0, maxFiles);
            applyEffectClass(maxFilesText);
          }

          onChange(newFiles);
        };
      }
    }
  }, [ref.current]);

  const inputFileCustomProps = {};
  if (allowedMimeTypes.length !== 0)
    inputFileCustomProps.accept = MimeTypes.getAcceptedTypes(allowedMimeTypes);

  return (
    <div
      ref={filezone}
      role='presentation'
      className='file-upload file-upload-dropzone'
      onClick={() => ref.current.click()}
      onDragEnter={dragEnter}
      onDragOver={dragEnter}
      onDragLeave={dragLeave}
      onDrop={handleDrop}
    >
      {files.length > 0 &&
        Array.from(files).map((file) => {
          const { icon, className } = getIconByFileType(file.type);
          return (
            <div
              role='presentation'
              className='dropzone-file'
              title={file.name}
              onClick={preventDefaults}
              key={generateID('dzf')}
            >
              <FontAwesomeIcon
                icon='times'
                className='dropzone-file-close'
                onClick={() => {
                  onChange(files.filter((p) => p !== file));
                }}
              />
              <FontAwesomeIcon icon={icon} className={className} />
              <span className='dropzone-file-name'>{file.name}</span>
              <span className='dropzone-file-size'>{`(${file.size} KB)`}</span>
            </div>
          );
        })}
      {files.length === 0 && (
        <div className='dropzone-text'>
          <p className='title'>
            <span className='highlight'>Arraste</span> arquivos para esta area
            ou <span className='highlight'>clique</span> para adicionar.
          </p>

          <p className='subtitle'>
            Os arquivos aqui adicionados, não são importados automaticamente, é
            preciso realizar a ação de importação.
          </p>
        </div>
      )}
      <span ref={maxFilesText} className='dropzone-maxFiles'>
        Este upload está limitado a{' '}
        <b>{maxFiles.toString().padStart(2, '0')}</b> arquivo(s)
        {getAllowedMimeTypesText(allowedMimeTypes)}.
      </span>
      <span ref={maxFilesText} className='dropzone-maxFiles'>
        Quantidade de Arquivos Anexados{' '}
        <b>{files.length.toString().padStart(2, '0')}</b>
      </span>
    </div>
  );
});

export default Dropzone;
