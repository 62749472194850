import PlanoModeloAparelho from 'core/models/TEL/planoModeloAparelho';
import api from '../api';

const baseURL = '/TEL/PlanoModeloAparelho';

export const getPlanoModeloAparelhoAutoCompleteForDemanda = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteForDemanda`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    value: value?.map((json) => new PlanoModeloAparelho(json)) ?? [],
  };
};
