import { TipoVenda } from '../VEN';
import TabelaPreco from '../VEN/tabelaPreco';
import CondicaoPagamentoEmpresa from './condicaoPagamento_Empresa';
import CondicaoPagamentoFormaPagamen from './condicaoPagamento_FormaPagamen';

export default class CondicaoPagamento {
  constructor(jsonObject = {}) {
    this.nrSeqCondicaoPagamento = jsonObject.nrSeqCondicaoPagamento;
    this.cdCondicaoPagamento = jsonObject.cdCondicaoPagamento;
    this.noCondicaoPagamento = jsonObject.noCondicaoPagamento;
    this.flgTitRec = jsonObject.flgTitRec;
    this.flgTitPag = jsonObject.flgTitPag;
    this.flgPdv = jsonObject.flgPdv;
    this.nrSeqTabelaPreco = jsonObject.nrSeqTabelaPreco;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.diasTolerancia = jsonObject.diasTolerancia;
    this.indiceCorrecao = jsonObject.indiceCorrecao;
    this.valorMaximo = jsonObject.valorMaximo;
    this.outrasDespesas = jsonObject.outrasDespesas;
    this.valorMinimo = jsonObject.valorMinimo;
    this.nrSeqTipoVenda = jsonObject.nrSeqTipoVenda;
    this.dtValidade = jsonObject.dtValidade;
    this.formaPagamentos = (jsonObject.formaPagamentos ?? []).map(
      (formaPagamento) => new CondicaoPagamentoFormaPagamen(formaPagamento)
    );
    this.empresas = (jsonObject.empresas ?? []).map(
      (empresas) => new CondicaoPagamentoEmpresa(empresas)
    );
    this.tipoVenda = new TipoVenda(jsonObject.tipoVenda ?? {});
    this.tabelaPreco = new TabelaPreco(jsonObject.tabelaPreco ?? {});
  }

  toJson = () => JSON.stringify(this);
}
