import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Switch, RadioButton } from 'ui/components';
import { Theme } from 'ui/Helpers/utils';

const FiltrarPor = [
  { text: 'Contrato/Fatura', value: 'S' },
  { text: 'Contrato Agrupado', value: 'N' },
];

const Opcao = [
  { text: 'Considerar. Vlr. Orig.', value: 'S' },
  { text: 'Considerar. Vlr. Recal.', value: 'N' },
];

const RelatorioCom = [
  { text: 'Vlr. Original / Recalculado', value: 'VlrOriginalRecalculado' },
  { text: 'Vlr. Original', value: 'VlrOriginal' },
  { text: 'Vlr. Recalculado', value: 'VlrRecalculado' },
];

const Ordenar = [
  { text: 'VC1', value: 1 },
  { text: 'VC1MO', value: 2 },
  { text: 'VC2VC3', value: 3 },
  { text: 'VC1VC2VC3MO', value: 4 },
  { text: 'VC2VC3FOO', value: 5 },
];

const PanelRelAnaliticoServicosLigacoes = forwardRef(({ props }, param) => {
  const [parametros, setParametros] = useState({});

  useImperativeHandle(param, () => ({
    param: parametros,
  }));

  useEffect(() => {
    setParametros({
      ...parametros,
      flg4RelatorioCom: props.flg4RelatorioCom,
      flg4analservligacoes: props.flg4analservligacoes,
      flg4contfat: props.flg4contfat,
      flg4pesqvlroriginal: props.flg4pesqvlroriginal,
      nr4ordnum: props.nr4ordnum,
    });
  }, [props]);

  const onOpcao = (opcao) => {
    if (opcao === 'N')
      setParametros({
        ...parametros,
        flg4pesqvlrrecalculado: 'S',
        flg4pesqvlroriginal: 'N',
      });
    else
      setParametros({
        ...parametros,
        flg4pesqvlrrecalculado: 'N',
        flg4pesqvlroriginal: 'S',
      });
  };

  return (
    <div>
      <div className='row mb-3'>
        <div className='col-2'>
          <Switch
            formControl
            label='Gerar'
            checked={parametros.flg4analservligacoes}
            onChange={(e) =>
              setParametros({ ...parametros, flg4analservligacoes: e })
            }
          />
        </div>
        <div className='col-4'>
          <RadioButton
            outline
            label='Filtrado por:'
            theme={Theme.Primary}
            buttons={FiltrarPor}
            selectedButton={parametros.flg4contfat}
            onChange={(flg4contfat) =>
              setParametros({
                ...parametros,
                flg4contfat,
              })
            }
          />
        </div>
        <div className='col-6'>
          <RadioButton
            outline
            label='Opção'
            theme={Theme.Primary}
            buttons={Opcao}
            selectedButton={parametros.flg4pesqvlroriginal}
            onChange={(e) => onOpcao(e)}
            onClick={(e) => onOpcao(e)}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-6'>
          <RadioButton
            outline
            label='Relatório Com:'
            theme={Theme.Primary}
            buttons={RelatorioCom}
            selectedButton={parametros.flg4RelatorioCom}
            onChange={(flg4RelatorioCom) =>
              setParametros({
                ...parametros,
                flg4RelatorioCom,
              })
            }
          />
        </div>
        <div className='col-6'>
          <RadioButton
            outline
            label='Ordenar por:'
            theme={Theme.Primary}
            buttons={Ordenar}
            selectedButton={parametros.nr4ordnum}
            onChange={(nr4ordnum) =>
              setParametros({
                ...parametros,
                nr4ordnum,
              })
            }
          />
        </div>
      </div>
    </div>
  );
});
export default PanelRelAnaliticoServicosLigacoes;
