export default class VeiculoFuncao {
  constructor(jsonObject = {}) {
    this.nrSeqVeiculoFuncao = jsonObject.nrSeqVeiculoFuncao;
    this.noVeiculoFuncao = jsonObject.noVeiculoFuncao;
    this.cdVeiculoFuncao = jsonObject.cdVeiculoFuncao;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
