import Profissao from 'core/models/SEG/profissao';
import EstadoCivil from 'core/models/SEG/estadocivil';

export default class PessoaFisica {
  constructor(jsonObject = {}) {
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.nrCpf = jsonObject.nrCpf;
    this.nrSeqEstadoCivil = jsonObject.nrSeqEstadoCivil;
    this.nrSeqProfissao = jsonObject.nrSeqProfissao;
    this.nrSeqSexo = jsonObject.nrSeqSexo;
    this.noPai = jsonObject.noPai;
    this.noMae = jsonObject.noMae;
    this.dtNascimento = jsonObject.dtNascimento;
    this.nrRg = jsonObject.nrRg;
    this.nrSeqGrupoempresa = jsonObject.nrSeqGrupoempresa;
    this.ufRg = jsonObject.ufRg;
    this.dtEmissaOrg = jsonObject.dtEmissaOrg;
    this.rgOrgaoEmissor = jsonObject.rgOrgaoEmissor;
    this.iEstadual = jsonObject.iEstadual;
    this.nrSeqEstadoIe = jsonObject.nrSeqEstadoIe;
    this.nrPis = jsonObject.nrPis;
    this.profissao = new Profissao(jsonObject.profissao ?? {});
    this.estadoCivil = new EstadoCivil(jsonObject.estadoCivil ?? {});
  }

  toJson = () => JSON.stringify(this);
}
