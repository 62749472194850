import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Textbox } from 'ui/components';
import {
  getInfracaoGravidade,
  saveInfracaoGravidade,
  deleteInfracaoGravidade,
} from 'core/services/FRO/infracaoGravidade';

export default function InfracaoGravidadeItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getInfracaoGravidade(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveInfracaoGravidade(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteInfracaoGravidade(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };
  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Infração Gravidade'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqInfracaoGravidade > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-2 mb-3'>
          <Textbox
            maxLength={60}
            label='Infração Gravidade'
            text={data.noInfracaoGravidade}
            onChangedValue={(noInfracaoGravidade) =>
              setData({ ...data, noInfracaoGravidade })
            }
          />
        </div>
        <div className='col-2 mb-3'>
          <Textbox
            maxLength={20}
            label='Código'
            text={data.cdInfracaoGravidade}
            onChangedValue={(cdInfracaoGravidade) =>
              setData({ ...data, cdInfracaoGravidade })
            }
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
