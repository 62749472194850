import React, { useEffect, useState, useRef } from 'react';
import { Modal, Notification, Loader, GridView } from 'ui/components';
import {
  Message,
  GridViewCurrent,
  GridviewColumns,
} from 'ui/Helpers/interfaces';
import {
  ResponseStatus,
  Theme,
  ColumnTypes,
  BootstrapSizes,
  ColumnDataTypes,
} from 'ui/Helpers/enums';

import {
  getColetaEmbarqueDocumentoList,
  downloadPdfDocument,
  downloadXmlDocument,
} from 'core/services/FRO/coletaEmbarque_Documento';

import { ColetaEmbarqueDocumento } from 'core/models/FRO';

interface Props {
  show: boolean;
  nrSeqColetaEmbarque: number;
  onClose: () => void;
}

export default function ModaDownloadNfes({
  show,
  nrSeqColetaEmbarque,
  onClose,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const gridViewDocuments = useRef<GridViewCurrent<ColetaEmbarqueDocumento>>();

  const onSetMessage = (status: number, msg: string): void => {
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
  };

  const load = async (): Promise<void> => {
    setLoading(true);

    const {
      status,
      message: msg,
      data: documents,
    } = await getColetaEmbarqueDocumentoList({ nrSeqColetaEmbarque });

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      if (gridViewDocuments && gridViewDocuments.current)
        gridViewDocuments.current.setDataSource(documents);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (show) {
      load();
    }
  }, [show]);

  const onSend = (): void => {
    onClose();
  };

  const onClickDonwloadPdf = async (
    e: ColetaEmbarqueDocumento
  ): Promise<void> => {
    const { data } = await downloadPdfDocument({
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
      nrSeqDocumento: e.nrSeqDocumento,
    });

    if (data) {
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:application/pdf;base64, ${data}`;
      downloadLink.download = `${e.nrNotaFiscal}.pdf`;
      downloadLink.click();
    }
  };

  const onClickDonwloadXml = async (
    e: ColetaEmbarqueDocumento
  ): Promise<void> => {
    const { data } = await downloadXmlDocument({
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
      nrSeqDocumento: e.nrSeqDocumento,
    });

    if (data) {
      const dataURI = encodeURIComponent(data);
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:text/plain;charset=utf-8,${dataURI}`;
      downloadLink.download = `${e.nrNotaFiscal}.xml`;
      downloadLink.click();
    }
  };

  const columns: Array<GridviewColumns> = [
    {
      key: 'nrSeqDocumento',
      visible: false,
    },
    {
      key: 'nrNotaFiscal',
      title: 'Nr. Nota',
    },
    {
      key: 'chaveNfe',
      title: 'Chave',
    },
    {
      key: 'vlrNota',
      title: 'Vlr. Nota',
      format: ColumnDataTypes.Decimal,
    },
    {
      key: 'nrSeqDocumento',
      title: 'PDF',
      type: ColumnTypes.Button,
      onClick: (e: ColetaEmbarqueDocumento) => onClickDonwloadPdf(e),
      theme: Theme.Danger,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download PDF',
    },
    {
      key: 'nrSeqDocumento',
      title: 'XML',
      type: ColumnTypes.Button,
      onClick: (e: ColetaEmbarqueDocumento) => onClickDonwloadXml(e),
      theme: Theme.Warning,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download XML',
    },
  ];

  return (
    // @ts-expect-error
    <Modal
      show={show}
      icon='download'
      title='Download NFes'
      size={BootstrapSizes.Large}
      onClose={() => onSend()}
    >
      {/* @ts-expect-error */}
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          // @ts-expect-error
          <Notification message={message.message} theme={message.theme} />
        )}
        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridViewDocuments}
              // @ts-expect-error
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              showSelectSizes={false}
              showPagination={false}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
