import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
  JustifyContent,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  DatePicker,
  Button,
  GridView,
  ToolbarButtons,
  Panel,
  Switch,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getPedido,
  savePedido,
  cancelOrder,
  generateParcels,
  printPedido,
  getNew,
  postConfirm,
  printTags,
} from 'core/services/VEN/pedido';

import { TransacationCodes } from 'ui/Helpers/transactionCodes';

import {
  postTransmitNFCe,
  postTransmitNFe,
  printNFe,
  printNFCe,
} from 'core/services/VEN/Nfv';

import { printDocumentsOrder } from 'core/services/FIN/fatura';

import { getMinimumPrice, getSellPrice } from 'core/services/VEN/tabelaPreco';
import { getEmpresasUsuario } from 'core/services/SEG/empresa';

import {
  getBalanceByProduct,
  getBalanceByProductGeneral,
} from 'core/services/EST/almoxarifadoLocal';
import { getVendedorAutoComplete } from 'core/services/VEN/vendedor';

import { getClienteAutoCompleteSeller } from 'core/services/FIN/cliente';

import { getBancoAutoComplete } from 'core/services/FIN/banco';

import { getCondicaoPagamentoAutoComplete } from 'core/services/FIN/condicaoPagamento';

import { getTipoFreteAutoComplete } from 'core/services/FRO/tipoFrete';

import { getTransportadoraAutoComplete } from 'core/services/FRO/transportadora';

import { getProdutoAutoComplete } from 'core/services/EST/produto';

import { PedidoFatura, PedidoItem } from 'core/models/VEN';

import { Endereco } from 'core/models/SEG';

import { Cheque } from 'core/models/FIN';

import {
  getFormaPagamentoAutoComplete,
  getFormaPagamentoPdvAutoComplete,
} from 'core/services/FIN/formaPagamento';

import {
  getPedidoEtapaAutoComplete,
  getFirstStep,
} from 'core/services/VEN/pedidoEtapa';

import { getTipoVendaAutoComplete } from 'core/services/VEN/tipoVenda';

export default function PedidoVendaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  onOpenReport,
  transaction,
  onOpenTransaction,
  findTransaction,
}) {
  const formSubmit = useRef();

  const [pedidoEtapa, setPedidoEtapa] = useState({ flgItens: true });
  const [data, setData] = useState({
    pedidoEtapa: {
      ...pedidoEtapa,
      flgParcelas: false,
      flgTransporte: false,
      flgDesconto: false,
      flgAutorizaDesconto: false,
      flgGeraNota: false,
      flgPedidoEtapa: false,
    },
  });

  const [dataTotalPedido, setDataTotalPedido] = useState({});

  const [dataCheque, setDataCheque] = useState(new Cheque());
  const [dataItemPedido, setDataItemPedido] = useState(new PedidoItem());
  const [dataItemFatura, setDataItemFatura] = useState(new PedidoFatura());
  const [enderecoCliente, setEnderecoCliente] = useState(new Endereco());
  const [produtoUnidadeMedida, setProdutoUnidadeMedida] = useState('');
  const [totalDescontoGeralReadOnly, settotalDescontoGeralReadOnly] = useState(
    false
  );

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const gridViewProdutos = useRef();
  const gridViewFaturamento = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const calculaTotalProduto = (vlrItPed, qtdeItPed) => vlrItPed * qtdeItPed;

  const calculaTotalProdutosPedido = (dataProdutos) => {
    let cont = 0;
    let total = 0;
    if (dataProdutos) {
      for (cont = 0; cont < dataProdutos.length; cont += 1)
        total += dataProdutos[cont].vlrItPed * dataProdutos[cont].qtdeItPed;
    }

    return total;
  };

  const calculaTotalFaturasPedido = (dataFaturas) => {
    let cont = 0;
    let total = 0;
    if (dataFaturas) {
      for (cont = 0; cont < dataFaturas.length; cont += 1)
        total += dataFaturas[cont].vlrParcela;
    }

    return total;
  };

  const onGetMinimumPrice = async (e) => {
    const { status, message: msg, price } = await getMinimumPrice({
      nrSeqProduto: e,
    });
    if (msg) onSetMessage(status, msg);
    return price;
  };

  const calculaTotalDescontoPedidoProdutos = (dataProdutos) => {
    let cont = 0;
    let total = 0;
    if (dataProdutos) {
      for (cont = 0; cont < dataProdutos.length; cont += 1) {
        total += dataProdutos[cont].vlrDesc;
      }
      if (total > 0) {
        settotalDescontoGeralReadOnly(true);
      } else {
        settotalDescontoGeralReadOnly(false);
      }
    }

    return total;
  };

  const calculaTotalPedido = (dataProdutos) => {
    let cont = 0;
    let total = 0;
    if (dataProdutos) {
      for (cont = 0; cont < dataProdutos.length; cont += 1)
        total += calculaTotalProduto(
          dataProdutos[cont].vlrItPed,
          dataProdutos[cont].qtdeItPed
        );
    }

    return total;
  };

  const calculaTotais = (dataProdutos, dataFaturas) => {
    const vlrTotalProdutos = calculaTotalProdutosPedido(dataProdutos);
    const vlrTotalFaturas = calculaTotalFaturasPedido(dataFaturas);
    const vlrTotalDescontoProdutos = calculaTotalDescontoPedidoProdutos(
      dataProdutos
    );

    const vlrTotalPedido = calculaTotalPedido(dataProdutos);
    data.vlrPed = vlrTotalPedido;
    setDataTotalPedido({
      ...dataTotalPedido,
      vlrTotalProdutos,

      vlrTotalDescontoProdutos,
      vlrTotalPedido,
      vlrTotalFaturas,
    });
  };

  const onSearchBanco = async (e) => {
    const { status, message: msg, bancos } = await getBancoAutoComplete({
      noBanco: e,
    });
    if (msg) onSetMessage(status, msg);
    return bancos;
  };

  const onGetSellPrice = async ({ nrSeqProduto }) => {
    const { status, message: msg, price } = await getSellPrice({
      nrSeqProduto,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });
    if (msg) onSetMessage(status, msg);
    return price;
  };

  const onClickEditProduto = async (selectedValue, datasource) => {
    const saldo = await getBalanceByProduct(
      selectedValue.nrSeqProduto,
      data.nrSeqEmpresa
    );

    const saldoGeral = await getBalanceByProductGeneral(
      selectedValue.nrSeqProduto
    );

    const dataProdutos = datasource.filter(
      (produto) => produto !== selectedValue
    );

    setDataItemPedido({
      ...selectedValue,
      saldo,
      saldoGeral,
      status: GridView.EnumStatus.Alterar,
    });

    if (gridViewProdutos && gridViewProdutos.current)
      gridViewProdutos.current.setDataSource(dataProdutos);

    setProdutoUnidadeMedida(
      selectedValue.produtoUnidadeMedida?.cdProdutoUnidadeMedida ?? ''
    );
  };

  const onClickRemoveProduto = async (selectedValue, datasource) => {
    const dataProdutos = datasource.filter(
      (produto) => produto.nrSeqProduto !== selectedValue.nrSeqProduto
    );
    if (gridViewProdutos && gridViewProdutos.current)
      gridViewProdutos.current.setDataSource(dataProdutos);
    if (!data.flgPedidoSimplificado) {
      const dataFaturas = gridViewFaturamento.current.getDataSource();
      calculaTotais(dataProdutos, dataFaturas);
    }
  };

  const onClickEditFatura = async (selectedValue, datasource) => {
    const dataFaturas = datasource.filter(
      (fatura) =>
        fatura.nrSeqPedidoFatura !== selectedValue.nrSeqPedidoFatura ||
        fatura.dtVencimento !== selectedValue.dtVencimento ||
        fatura.vlrParcela !== selectedValue.vlrParcela
    );
    const dataFatura = datasource.filter(
      (fatura) =>
        fatura.nrSeqPedidoFatura === selectedValue.nrSeqPedidoFatura &&
        fatura.dtVencimento === selectedValue.dtVencimento &&
        fatura.vlrParcela === selectedValue.vlrParcela
    );

    setDataItemFatura(dataFatura[0]);
    if (gridViewFaturamento && gridViewFaturamento.current)
      gridViewFaturamento.current.setDataSource(dataFaturas);
  };

  const onClickRemoveFatura = async (selectedValue, datasource) => {
    const dataFaturas = datasource.filter(
      (fatura) =>
        fatura.nrSeqFormaPagamento !== selectedValue.nrSeqFormaPagamento ||
        fatura.dtVencimento !== selectedValue.dtVencimento ||
        fatura.vlrParcela !== selectedValue.vlrParcela
    );
    if (gridViewFaturamento && gridViewFaturamento.current)
      gridViewFaturamento.current.setDataSource(dataFaturas);

    const dataProdutos = gridViewProdutos.current.getDataSource();

    calculaTotais(dataProdutos, dataFaturas);
  };

  const columns = [
    { key: 'nrSeqProduto', type: GridView.ColumnTypes.Checkbox },
    { key: 'produto.noProdutoAgrupador', title: 'Produto' },
    { key: 'vlrItPed', title: 'Valor', format: GridView.DataTypes.Decimal },
    {
      key: 'qtdeItPed',
      title: 'Quantidade',
      format: GridView.DataTypes.Decimal,
    },
    { key: 'vlrItFrete', title: 'Frete', format: GridView.DataTypes.Decimal },

    { key: 'vlrTotPed', title: 'Total', format: GridView.DataTypes.Decimal },

    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditProduto(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveProduto(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsFaturamento = [
    { key: 'nrSeqPedidoFatura', type: GridView.ColumnTypes.Checkbox },
    { key: 'formaPagamento.noFormaPagamento', title: 'Forma Pagamento' },
    { key: 'vlrParcela', title: 'Valor', format: GridView.DataTypes.Decimal },
    {
      key: 'flgLiquida',
      title: 'Liquida',
      format: GridView.DataTypes.Boolean,
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrSeqPedidoFatura',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditFatura(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqPedidoFatura',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveFatura(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const pedido = await getPedido(registryKey);
      setData(pedido);

      if (gridViewProdutos && gridViewProdutos.current) {
        gridViewProdutos.current.setDataSource(pedido.itens);
      }

      if (gridViewFaturamento && gridViewFaturamento.current) {
        gridViewFaturamento.current.setDataSource(pedido.faturas);
      }

      setDataItemPedido({
        ...dataItemPedido,
        vlrItPed: 0,
        produto: {},
        nrSeqProduto: 0,
        almoxarifadoLocal: {},
        nrSeqAlmoxarifadoLocal: undefined,
        saldo: 0,
        saldoGeral: 0,
      });
      setDataItemFatura(new PedidoFatura());

      const { etapas } = await getPedidoEtapaAutoComplete({
        nrSeqPedidoEtapa: pedido.nrSeqPedidoEtapa,
      });

      setPedidoEtapa(etapas[0]);

      calculaTotais(pedido.itens, pedido.faturas);

      const { clientes } = await getClienteAutoCompleteSeller({
        nrSeqPessoa: pedido.nrSeqPessoaCli,
        flgIncluirEndereco: true,
      });

      setEnderecoCliente(clientes[0].endereco);

      setLoading(false);
    } else {
      setDataTotalPedido({});
      setDataItemPedido({
        ...dataItemPedido,
        vlrItPed: 0,
        produto: {},
        nrSeqProduto: 0,
        almoxarifadoLocal: {},
        nrSeqAlmoxarifadoLocal: undefined,
        saldo: 0,
        saldoGeral: 0,
      });
      setDataItemFatura(new PedidoFatura());
      setEnderecoCliente(new Endereco());

      const pedidoEtapaA = await getFirstStep();
      setPedidoEtapa(pedidoEtapaA);

      const pedidoA = await getNew();

      setData(pedidoA);

      if (gridViewProdutos && gridViewProdutos.current) {
        gridViewProdutos.current.setDataSource([]);
      }

      if (gridViewFaturamento && gridViewFaturamento.current) {
        gridViewFaturamento.current.setDataSource([]);
      }
    }

    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);

    data.itens = gridViewProdutos.current.getDataSource();
    data.faturas = gridViewFaturamento.current.getDataSource();

    data.flgLicitacao = data.vendedor.flgLicitacao;
    data.cheque = dataCheque;
    const { status, message: msg } = await savePedido(data);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      setDataTotalPedido({});
      gridViewProdutos.current.setDataSource([]);
      if (!data.flgPedidoSimplificado)
        gridViewFaturamento.current.setDataSource([]);

      setDataItemPedido({
        ...dataItemPedido,
        vlrItPed: 0,
        produto: {},
        nrSeqProduto: 0,
        almoxarifadoLocal: {},
        nrSeqAlmoxarifadoLocal: undefined,
        saldo: 0,
        saldoGeral: 0,
      });
      setDataItemFatura(new PedidoFatura());
    }

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onCancelOrder = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await cancelOrder(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onConfirm = async () => {
    const { status, message: msg } = await postConfirm(data);
    if (msg) onSetMessage(status, msg);
  };

  const onTransmitNFCe = async () => {
    const { status, message: msg } = await postTransmitNFCe(data);
    if (msg) onSetMessage(status, msg);
  };

  const onTransmitNFe = async () => {
    const { status, message: msg } = await postTransmitNFe(data);
    if (msg) onSetMessage(status, msg);
  };

  const onGenerateParcels = async () => {
    setLoading(true);

    if (data.nrSeqCondicaoPagamento === undefined) {
      onSetMessage(
        Theme.Danger,
        'Necessário informar a condição de pagamento.'
      );
    } else if (data.nrSeqEmpresa === undefined) {
      onSetMessage(Theme.Danger, 'Necessário informar a empresa.');
    }

    const dataFaturas = await generateParcels(
      data.nrSeqCondicaoPagamento,
      data.nrSeqEmpresa,
      data.vlrPed - data.vlrDesc
    );

    if (gridViewFaturamento && gridViewFaturamento.current)
      gridViewFaturamento.current.setDataSource(dataFaturas);

    const dataProdutos = gridViewProdutos.current.getDataSource();

    calculaTotais(dataProdutos, dataFaturas);

    setLoading(false);
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresasUsuario({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onSearchVendedor = async (e) => {
    const { status, message: msg, vendedores } = await getVendedorAutoComplete({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return vendedores;
  };

  const onSearchPedidoEtapa = async () => {
    const { status, message: msg, etapas } = await getPedidoEtapaAutoComplete();
    onSetMessage(status, msg);
    return etapas;
  };

  const onSearchTipoVenda = async () => {
    const { status, message: msg, tipos } = await getTipoVendaAutoComplete();
    onSetMessage(status, msg);
    return tipos;
  };

  const onSearchCondicaoPagamento = async (e) => {
    const {
      status,
      message: msg,
      condicoesPagamento,
    } = await getCondicaoPagamentoAutoComplete({
      noCondicaoPagamento: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });
    onSetMessage(status, msg);
    return condicoesPagamento;
  };

  const onSearchTransportadora = async (e) => {
    const {
      status,
      message: msg,
      transportadoras,
    } = await getTransportadoraAutoComplete({ noTransportadora: e });
    if (msg) onSetMessage(status, msg);
    return transportadoras;
  };

  const onSearchProduto = async (e) => {
    const {
      status,
      message: msg,
      data: produtos,
    } = await getProdutoAutoComplete({
      noSearchField: e,
    });
    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const onSearchTipoFrete = async (e) => {
    const {
      status,
      message: msg,
      tiposFrete,
    } = await getTipoFreteAutoComplete({ noTipoFrete: e });
    if (msg) onSetMessage(status, msg);
    return tiposFrete;
  };

  const onSearchCliente = async (e) => {
    const {
      status,
      message: msg,
      clientes,
    } = await getClienteAutoCompleteSeller({
      noPessoa: e,
      flgIncluirEndereco: true,
    });
    onSetMessage(status, msg);
    return clientes;
  };
  const onSearchFormaPagamento = async (e) => {
    if (!data.flgPedidoSimplificado) {
      const {
        status,
        message: msg,
        formasPagamento,
      } = await getFormaPagamentoAutoComplete({
        noFormaPagamento: e,
      });
      onSetMessage(status, msg);
      return formasPagamento;
    }
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoPdvAutoComplete({
      noFormaPagamento: e,
    });
    onSetMessage(status, msg);
    return formasPagamento;
  };

  function RenderTextMask({ value }) {
    try {
      return value.enderecoCompleto ? (
        <div className='col-auto mt-3'>
          <div className='alert alert-info fs-8' role='alert'>
            <span className='fw-bold'>Endereço: {value.enderecoCompleto}</span>
          </div>
        </div>
      ) : null;
    } catch {
      return null;
    }
  }

  const onClickAddProduto = async () => {
    let dataProdutos = gridViewProdutos.current.getDataSource();

    if (data.nrSeqEmpresa === undefined) {
      onSetMessage(
        Theme.Danger,
        'Para adicionar o produto é necessário informar a empresa.'
      );
    } else if (
      dataItemPedido.nrSeqProduto === '' ||
      dataItemPedido.nrSeqProduto === undefined
    ) {
      onSetMessage(Theme.Danger, 'Produto precisa ser informado.');
    } else if (
      dataItemPedido.qtdeItPed === undefined ||
      dataItemPedido.qtdeItPed <= 0
    ) {
      onSetMessage(Theme.Danger, 'Quantidade precisa ser informada');
    } else if (
      dataItemPedido.vlrItPed === undefined ||
      dataItemPedido.vlrItPed <= 0
    ) {
      onSetMessage(Theme.Danger, 'Valor do item precisa ser informado.');
    } else if (
      dataItemPedido.saldo < dataItemPedido.qtdeItPed &&
      dataItemPedido.saldoGeral < dataItemPedido.qtdeItPed &&
      data.tipoVenda.flgGeraEstq &&
      !data.flgLicitacao
    ) {
      onSetMessage(Theme.Danger, 'Quantidade em estoque insuficiente.');
    } else {
      dataItemPedido.vlrTotPed = calculaTotalProduto(
        dataItemPedido.vlrItPed,
        dataItemPedido.qtdeItPed,
        dataItemPedido.vlrItFrete,
        dataItemPedido.vlrDesc
      );

      let produtoRepetido = false;
      if (dataProdutos !== undefined) {
        const validacao = dataProdutos.filter(
          (produto) => produto.nrSeqProduto === dataItemPedido.nrSeqProduto
        );
        if (validacao.length > 0) produtoRepetido = true;
      }

      if (produtoRepetido) {
        onSetMessage(
          Theme.Danger,
          'O mesmo produto não pode ser adicionado duas vezes'
        );
      } else {
        const price = await onGetMinimumPrice(dataItemPedido.nrSeqProduto);
        if (price > dataItemPedido.vlrItPed) {
          dataItemPedido.vlrDesc =
            (price - dataItemPedido.vlrItPed) * dataItemPedido.qtdeItPed;
        }
        if (dataProdutos === undefined) dataProdutos = [];
        dataProdutos.push(dataItemPedido);

        if (gridViewProdutos && gridViewProdutos.current)
          gridViewProdutos.current.setDataSource(dataProdutos);

        setDataItemPedido({});

        const dataFaturas = gridViewFaturamento.current.getDataSource();

        calculaTotais(dataProdutos, dataFaturas);
      }
    }
  };

  const onClickAddFatura = async () => {
    let dataFaturas = gridViewFaturamento.current.getDataSource();
    if (
      dataItemFatura.vlrParcela === '' ||
      dataItemFatura.vlrParcela === undefined
    ) {
      onSetMessage(Theme.Danger, 'Valor da parcela precisa ser informado.');
    } else if (dataItemFatura.dtVencimento === undefined) {
      onSetMessage(Theme.Danger, 'Data de vencimento precisa ser informada.');
    } else if (
      dataItemFatura.nrSeqFormaPagamento === undefined ||
      dataItemFatura.nrSeqFormaPagamento === ''
    ) {
      onSetMessage(Theme.Danger, 'Forma de pagamento precisa ser informada.');
    } else {
      let validacao = [];
      if (dataFaturas !== undefined) {
        validacao = dataFaturas.filter(
          (fatura) =>
            fatura.vlrParcela === dataItemFatura.vlrParcela &&
            fatura.nrSeqFormaPagamento === dataItemFatura.nrSeqFormaPagamento &&
            fatura.dtVencimento === dataItemFatura.dtVencimento
        );
      } else {
        dataFaturas = [];
      }

      if (validacao.length > 0) {
        onSetMessage(
          Theme.Danger,
          'A mesma parcela não pode ser adicionada duas vezes'
        );
      } else {
        dataFaturas.push(dataItemFatura);

        if (gridViewFaturamento && gridViewFaturamento.current)
          gridViewFaturamento.current.setDataSource(dataFaturas);

        setDataItemFatura(new PedidoFatura());

        const dataProdutos = gridViewProdutos.current.getDataSource();

        calculaTotais(dataProdutos, dataFaturas);
      }
    }
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printPedido(data.nrSeqPedido);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onPrintNFCe = async () => {
    setLoading(true);
    const { value } = await printNFCe({ nrSeqPedido: data.nrSeqPedido });

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onPrintNFe = async () => {
    setLoading(true);
    const { value } = await printNFe({ nrSeqPedido: data.nrSeqPedido });

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onPrintDocumentOrder = async () => {
    setLoading(true);
    const { value } = await printDocumentsOrder({
      nrSeqPedido: data.nrSeqPedido,
    });

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onPrintTags = async () => {
    setLoading(true);
    const { value: etiquetas, message: msg, status } = await printTags(
      data.nrSeqPedido
    );

    if (etiquetas) {
      onOpenReport(transaction.noTransacao, etiquetas);
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
    setLoading(false);
  };

  const { id: idSelecao } = PageTypes.Selection;

  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Pedido'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onPrint={onPrint}
      onSave={save}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
      ref={formSubmit}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Cancelar Pedido'
          icon='arrow-circle-left'
          onClick={data?.nrSeqPedido > 0 ? onCancelOrder : null}
          visible={data.dtCancel === null}
        />
      </ToolbarButtons>

      <div className='row mb-2'>
        <Panel>
          <Panel.Header
            icon={['fas', 'user']}
            title='Informações do Pedido'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />

          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-3'>
                <Autocomplete
                  label='Pedido Etapa'
                  searchDataSource={onSearchPedidoEtapa}
                  selectedItem={data.pedidoEtapa}
                  onSelectItem={(pedidoEtapa_) => {
                    setData({
                      ...data,
                      pedidoEtapa: pedidoEtapa_,
                      nrSeqPedidoEtapa: pedidoEtapa_.nrSeqPedidoEtapa,
                    });
                    setPedidoEtapa({
                      pedidoEtapa_,
                    });
                  }}
                  dataSourceTextProperty='noPedidoEtapa'
                />
              </div>

              <div className='col-3'>
                <Autocomplete
                  label='Tipo Venda'
                  searchDataSource={onSearchTipoVenda}
                  selectedItem={data.tipoVenda}
                  onSelectItem={(tipoVenda_) => {
                    setData({
                      ...data,
                      tipoVenda: tipoVenda_,
                      nrSeqTipoVenda: tipoVenda_.nrSeqTipoVenda,
                    });
                  }}
                  dataSourceTextProperty='noTipoVenda'
                />
              </div>

              <div className='col-2'>
                <DatePicker
                  label='Data Cadastro'
                  text={data.dtCad}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtCad) => {
                    setData({ ...data, dtCad });
                  }}
                  readOnly
                />
              </div>

              <div className='col-2'>
                <Textbox
                  label='Número Pedido'
                  readOnly
                  text={data.nrPed}
                  mask={MaskTypes.Integer}
                  onChangedValue={(nrPed) => setData({ ...data, nrPed })}
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-6'>
                <Autocomplete
                  label='Empresa'
                  searchDataSource={onSearchEmpresa}
                  selectedItem={data.empresa}
                  onSelectItem={(empresa) => {
                    setData({
                      ...data,
                      empresa,
                      nrSeqEmpresa: empresa.nrSeqEmpresa,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>

              <div className='col-4'>
                <Autocomplete
                  label='Vendedor'
                  enabled={false}
                  searchDataSource={onSearchVendedor}
                  selectedItem={data.vendedor}
                  onSelectItem={(vendedor) =>
                    setData({
                      ...data,
                      vendedor,
                      nrSeqPessoaVen: vendedor.nrSeqPessoa,
                    })
                  }
                  dataSourceTextProperty='noPessoa'
                />
              </div>

              <div className='col-2'>
                <Textbox
                  maxLength={20}
                  label='Nr Pedido Representante'
                  text={data.nrPedRepresentante}
                  onChangedValue={(nrPedRepresentante) =>
                    setData({ ...data, nrPedRepresentante })
                  }
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-6'>
                <Autocomplete
                  label='Cliente'
                  searchDataSource={onSearchCliente}
                  selectedItem={data.cliente}
                  findTransaction={findTransaction}
                  onOpenTransaction={onOpenTransaction}
                  transactionToOpen={TransacationCodes.Cliente}
                  nrseqTransactionToOpen='nrSeqPessoaCli'
                  onSelectItem={(cliente) => {
                    const { endereco } = cliente;
                    setData({
                      ...data,
                      cliente,
                      nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                    });
                    setEnderecoCliente(endereco);
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-6'>
                <RenderTextMask value={enderecoCliente} />
              </div>

              <div className='col-6'>
                <Autocomplete
                  label='Condição de Pagamento'
                  searchDataSource={onSearchCondicaoPagamento}
                  selectedItem={data.condicaoPagamento}
                  onSelectItem={(condicaoPagamento) => {
                    setData({
                      ...data,
                      condicaoPagamento,
                      nrSeqCondicaoPagamento:
                        condicaoPagamento.nrSeqCondicaoPagamento,
                    });
                  }}
                  dataSourceTextProperty='noCondicaoPagamento'
                />
              </div>
            </div>

            {data.condicaoPagamento?.noCondicaoPagamento
              ?.toUpperCase()
              .includes('CHEQUE') && (
              <div className='row mb-3'>
                <div className='col-2'>
                  <Textbox
                    maxLength={300}
                    label='Número do Cheque'
                    text={dataCheque.nrCheque}
                    onChangedValue={(nrCheque) =>
                      setDataCheque({ ...dataCheque, nrCheque })
                    }
                  />
                </div>

                <div className='col-2'>
                  <DatePicker
                    label='Vencimento Cheque'
                    text={dataCheque.dtVencimento}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dtVencimento) =>
                      setDataCheque({
                        ...dataCheque,
                        dtVencimento,
                      })
                    }
                  />
                </div>

                <div className='col-4'>
                  <Autocomplete
                    label='Banco'
                    searchDataSource={onSearchBanco}
                    selectedItem={dataCheque.banco}
                    onSelectItem={(banco) => {
                      setDataCheque({
                        ...dataCheque,

                        nrSeqBanco: banco.nrSeqBanco,
                      });
                    }}
                    dataSourceTextProperty='noBanco'
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    maxLength={300}
                    label='Agência'
                    text={dataCheque.noAgencia}
                    onChangedValue={(noAgencia) =>
                      setDataCheque({
                        ...dataCheque,
                        noAgencia,
                      })
                    }
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    maxLength={300}
                    label='Conta'
                    text={dataCheque.noConta}
                    onChangedValue={(noConta) =>
                      setDataCheque({ ...dataCheque, noConta })
                    }
                  />
                </div>
              </div>
            )}
          </Panel.Body>
        </Panel>
      </div>

      <div className='row mb-2'>
        <Panel>
          <Panel.Header
            icon={['fa', 'briefcase']}
            title='Relação de Produtos do Pedido'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />

          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-6'>
                <Autocomplete
                  label='Produto'
                  searchDataSource={onSearchProduto}
                  selectedItem={dataItemPedido.produto}
                  onSelectItem={(produto) => {
                    onGetSellPrice({
                      nrSeqProduto: produto.nrSeqProduto,
                    }).then((result) => {
                      getBalanceByProduct(
                        produto.nrSeqProduto,
                        data.nrSeqEmpresa
                      ).then((saldo) => {
                        getBalanceByProductGeneral(produto.nrSeqProduto).then(
                          (saldoGeral) =>
                            setDataItemPedido({
                              ...dataItemPedido,
                              vlrItPed: result,
                              produto,
                              nrSeqProduto: produto.nrSeqProduto,
                              almoxarifadoLocal: {},
                              nrSeqAlmoxarifadoLocal: undefined,
                              saldo,
                              saldoGeral,
                            })
                        );
                      });

                      try {
                        setProdutoUnidadeMedida(
                          produto.produtoUnidadeMedida.cdProdutoUnidadeMedida
                        );
                      } catch {
                        setProdutoUnidadeMedida('');
                      }
                    });
                  }}
                  dataSourceTextProperty='noProduto'
                />
              </div>

              <div className='col-2'>
                <Textbox label='Unid' readOnly text={produtoUnidadeMedida} />
              </div>
              <div className='col-2'>
                <Textbox
                  maxLength={300}
                  label='Saldo'
                  mask={MaskTypes.Decimal}
                  text={dataItemPedido.saldo}
                  readOnly
                />
              </div>
              <div className='col-2'>
                <Textbox
                  maxLength={300}
                  label='Sld. Geral'
                  mask={MaskTypes.Decimal}
                  text={dataItemPedido.saldoGeral}
                  readOnly
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-2'>
                <Textbox
                  label='Valor Unitário'
                  text={dataItemPedido.vlrItPed}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(vlrItPed) =>
                    setDataItemPedido({
                      ...dataItemPedido,
                      vlrItPed,
                      vlrTotPed: calculaTotalProduto(
                        vlrItPed,
                        dataItemPedido.qtdeItPed,
                        dataItemPedido.vlrItFrete,
                        dataItemPedido.vlrDesc
                      ),
                    })
                  }
                />
              </div>

              <div className='col-2'>
                <Textbox
                  label='Quantidade'
                  text={dataItemPedido.qtdeItPed}
                  mask={MaskTypes.Integer}
                  onChangedValue={(qtdeItPed) =>
                    setDataItemPedido({
                      ...dataItemPedido,
                      qtdeItPed,
                      vlrTotPed: calculaTotalProduto(
                        dataItemPedido.vlrItPed,
                        qtdeItPed,
                        dataItemPedido.vlrItFrete,
                        dataItemPedido.vlrDesc
                      ),
                    })
                  }
                />
              </div>

              <div className='col-2'>
                <Textbox
                  label='Total'
                  mask={MaskTypes.Decimal}
                  text={dataItemPedido.vlrTotPed}
                  readOnly
                />
              </div>

              <div className='col-2 mt-3'>
                <Button
                  outline
                  theme={Theme.Success}
                  template={Button.Templates.Button}
                  text='Adicionar'
                  onClick={onClickAddProduto}
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-12'>
                <GridView
                  ref={gridViewProdutos}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns}
                  offlineData
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>

      <div className='row mb-2'>
        <Panel>
          <Panel.Header
            title='Faturamento do Pedido'
            icon={['fa', 'check']}
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />

          <Panel.Body>
            <div className='row mb-3'>
              <div className='row mb-3'>
                <div className='col-2 mt-3'>
                  <Button
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Gerar Parcelas'
                    onClick={onGenerateParcels}
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-2'>
                  <Textbox
                    maxLength={300}
                    label='Valor'
                    text={dataItemFatura.vlrParcela}
                    mask={MaskTypes.Decimal}
                    onChangedValue={(vlrParcela) =>
                      setDataItemFatura({ ...dataItemFatura, vlrParcela })
                    }
                  />
                </div>

                <div className='col-2'>
                  <DatePicker
                    label='Vencimento'
                    text={dataItemFatura.dtVencimento}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dtVencimento) =>
                      setDataItemFatura({
                        ...dataItemFatura,
                        dtVencimento,
                      })
                    }
                  />
                </div>

                <div className='col-5'>
                  <Autocomplete
                    label='Forma de Pagamento'
                    searchDataSource={onSearchFormaPagamento}
                    selectedItem={dataItemFatura.formaPagamento}
                    onSelectItem={(formaPagamento) =>
                      setDataItemFatura({
                        ...dataItemFatura,
                        formaPagamento,
                        nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                      })
                    }
                    dataSourceTextProperty='noFormaPagamento'
                  />
                </div>

                <div className='col-1 mb-3'>
                  <Switch
                    formControl
                    label='Líquida'
                    checked={dataItemFatura.flgLiquida}
                    onChange={(flgLiquida) => {
                      setDataItemFatura({ ...dataItemFatura, flgLiquida });
                    }}
                  />
                </div>

                <div className='col-2 mt-3'>
                  <Button
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Adicionar'
                    onClick={onClickAddFatura}
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-12'>
                  <GridView
                    ref={gridViewFaturamento}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsFaturamento}
                    offlineData
                  />
                </div>
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>

      <div className='row mb-2'>
        <Panel visible={!data.flgPedidoSimplificado}>
          <Panel.Header
            icon={['fa', 'truck']}
            title='Transportadora'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />

          <Panel.Body>
            <div className='accordion-body'>
              <div className='row mb-3'>
                <div className='col-6'>
                  <Autocomplete
                    label='Transportadora'
                    searchDataSource={onSearchTransportadora}
                    selectedItem={data.transportadora}
                    onSelectItem={(transportadora) => {
                      setData({
                        ...data,
                        transportadora,
                        nrSeqPessoaTran: transportadora.nrSeqPessoaTran,
                      });
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
                <div className='col-3'>
                  <Autocomplete
                    label='Tipo Frete'
                    searchDataSource={onSearchTipoFrete}
                    selectedItem={data.tipoFrete}
                    onSelectItem={(tipoFrete) => {
                      setData({
                        ...data,
                        tipoFrete,
                        nrSeqTipoFrete: tipoFrete.nrSeqTipoFrete,
                      });
                    }}
                    dataSourceTextProperty='noTipoFrete'
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Valor Frete'
                    text={data.vlrFrete}
                    mask={MaskTypes.Decimal}
                    onChangedValue={(vlrFrete) => {
                      setData({ ...data, vlrFrete });
                    }}
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-3'>
                  <Textbox
                    label='Espécie Frete'
                    text={data.noEspecieFrete}
                    onChangedValue={(noEspecieFrete) => {
                      setData({ ...data, noEspecieFrete });
                    }}
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='Qtd. Frete'
                    text={data.qtdeFrete}
                    mask={MaskTypes.Decimal}
                    onChangedValue={(qtdeFrete) => {
                      setData({ ...data, qtdeFrete });
                    }}
                  />
                </div>
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      {!data.flgPedidoSimplificado && data.nrNfv && (
        <div className='row mb-2'>
          <Panel>
            <Panel.Header
              icon={['fa', 'file']}
              title='Nota Fiscal'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />

            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-2 mt-3'>
                  <Button
                    visible={false}
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Gerar Nota Fiscal'
                    onClick={onConfirm}
                  />
                </div>

                <div className='col-2 mt-3'>
                  <Button
                    visible={false}
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Transmitir Nota Fiscal'
                    onClick={onTransmitNFe}
                  />
                </div>

                <div className='col-2 mt-3'>
                  <Button
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Imprimir Nota Fiscal'
                    onClick={onPrintNFe}
                  />
                </div>

                <div className='col-2 mt-3'>
                  <Button
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Imprimir Etiquetas'
                    onClick={onPrintTags}
                  />
                </div>

                <div className='col-2 mt-3'>
                  <Button
                    visible={data.faturas && data.faturas.length > 0}
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    text='Imprimir Fatura'
                    onClick={onPrintDocumentOrder}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      )}

      <div className='row mb-2'>
        <Panel visible={data.flgPedidoSimplificado}>
          <Panel.Header
            icon={['fa', 'file']}
            title='Cupom Fiscal'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />

          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-2 mt-3'>
                <Button
                  outline
                  theme={Theme.Success}
                  template={Button.Templates.Button}
                  text='Gerar Nota Fiscal'
                  onClick={onConfirm}
                />
              </div>
              <div className='col-2 mt-3'>
                <Button
                  outline
                  theme={Theme.Success}
                  template={Button.Templates.Button}
                  text='Transmitir Nota Fiscal'
                  onClick={onTransmitNFCe}
                />
              </div>

              <div className='col-2 mt-3'>
                <Button
                  outline
                  theme={Theme.Success}
                  template={Button.Templates.Button}
                  text='Imprimir Nota Fiscal'
                  onClick={onPrintNFCe}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>

      <div className='row mb-3'>
        <div className='col-12'>
          <Textbox
            maxLength={300}
            label='Observações Pedido'
            text={data.obsPed}
            onChangedValue={(obsPed) => setData({ ...data, obsPed })}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-2'>
          <Textbox
            visible={!data.flgPedidoSimplificado}
            label='Total Produtos'
            text={dataTotalPedido.vlrTotalProdutos}
            mask={MaskTypes.Decimal}
            readOnly
          />
        </div>
        <div className='col-2'>
          <Textbox
            visible={!data.flgPedidoSimplificado}
            label='Total Frete'
            text={data.vlrFrete}
            mask={MaskTypes.Decimal}
            readOnly
          />
        </div>
        <div className='col-2'>
          <Textbox
            visible={!data.flgPedidoSimplificado}
            label='Total Desconto Geral'
            text={data.vlrDesc}
            onChangedValue={(vlrDesc) => setData({ ...data, vlrDesc })}
            mask={MaskTypes.Decimal}
            readOnly={totalDescontoGeralReadOnly}
          />
        </div>

        <div className='col-2'>
          <Textbox
            visible={!data.flgPedidoSimplificado}
            label='Total Desconto Produtos'
            text={dataTotalPedido.vlrTotalDescontoProdutos}
            mask={MaskTypes.Decimal}
            readOnly
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Total Pedido'
            text={dataTotalPedido.vlrTotalPedido}
            mask={MaskTypes.Decimal}
            readOnly
          />
        </div>

        <div className='col-2'>
          <Textbox
            label='Total Faturas'
            text={dataTotalPedido.vlrTotalFaturas}
            mask={MaskTypes.Decimal}
            readOnly
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
