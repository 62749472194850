import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Steps, ToolbarButtons } from 'ui/components';
import {
  saveAss,
  deleteAssociado,
  getAssociadoCompleteInfoById,
  printAssociadoCarteirinha,
  printAssociadoDeclaracao,
  printAssociadoDeclaracaoIRPF,
  printAssociadoRelMensalDetalhado,
  printAssociadoRelMensal,
} from 'core/services/ASS/associado';
import { getEmpresaDefaultUsuario } from 'core/services/SEG/empresa';
import { getConvenioAutoComplete } from 'core/services/ASS/convenio';
import DetalhesAssociado from './detalhesAssociado';
import ResumoInformacoes from './resumoInformacoes';
import PlanosStep from './planosStep';
import DependentesStep from './dependentesStep';
import RelMensalLancamentosStep from './relMensalLancamentos';
import OutrosStep from './outrosStep';
import ModalPrintCartao from './modalPrintCartao';
import ModalPrintDeclaracaoIR from './modalPrintDeclaracaoIR';

export default function AssociadoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  onOpenReport,
  transaction,
}) {
  const formSubmit = useRef();
  const stepAssociado = useRef();

  const associadoDetalhes = useRef();
  const planosAssociado = useRef();
  const dependentes = useRef();
  const lancamentos = useRef();
  const outrosStep = useRef();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [modalShow, setModalShow] = useState({
    modalCartao: false,
    modalDeclaracaoIR: false,
  });
  const [empresaUsuario, setEmpresaUsuario] = useState(null);
  const [infoDeclaraoIR, setInfoDeclaraoIR] = useState(null);

  const reset = true;

  function getData() {
    return data;
  }

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const associado = await getAssociadoCompleteInfoById(registryKey);

      setData(associado);

      setLoading(false);
    } else {
      const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;
      const { empresas } = await getEmpresaDefaultUsuario({
        nrSeqUsuario: sessionUser.nrSeqUsuario,
      });

      if (empresas && empresas.length > 0) {
        const objEmpresa = empresas[0];
        objEmpresa.flgRural = objEmpresa.noPessoa
          ?.toUpperCase()
          .includes('SINDICATO RURAL');
        setEmpresaUsuario(objEmpresa);
      }

      setData({});
    }
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  function ajustaDadosParaSalvar(dados) {
    if (dados.dependentes && dados.dependentes.length > 0) {
      const listaDependentesDeduplicada = [];

      dados.dependentes.forEach((item) => {
        const duplicated =
          listaDependentesDeduplicada.findIndex(
            (objArr) =>
              item.nrSeqAssociadoDependente ===
                objArr.nrSeqAssociadoDependente ||
              item.noDependente === objArr.noDependente
          ) > -1;

        if (!duplicated) {
          listaDependentesDeduplicada.push(item);
        }
      });

      dados.dependentes = listaDependentesDeduplicada;
    }

    if (dados.pessoa && dados.pessoaFisica && dados.pessoa.pessoaFisica) {
      if (dados.pessoa.pessoaFisica !== dados.pessoaFisica) {
        dados.pessoa.pessoaFisica = dados.pessoaFisica;
      }
    }

    if (
      dados.informacoesBancarias &&
      dados.pessoa &&
      dados.pessoa.informacoesBancarias
    ) {
      dados.pessoa.informacoesBancarias = null;
    }

    if (
      dados.associadoDocumentos &&
      dados.pessoa &&
      dados.pessoa.associadoDocumentos
    ) {
      dados.pessoa.associadoDocumentos = null;
    }

    if (dados.filtroBuscaLancamento) dados.filtroBuscaLancamento = undefined;
    if (dados.lancamentosAssociado) dados.lancamentosAssociado = undefined;
    if (dados.dadosDependente) dados.dadosDependente = undefined;

    return dados;
  }

  const save = async () => {
    const dadosASalvar = ajustaDadosParaSalvar(data);
    setLoading(true);

    const { status, message: msg } = await saveAss(dadosASalvar);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : dadosASalvar);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteAssociado(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onClickPrintCartao = async () => {
    setModalShow({ ...modalShow, modalCartao: true });
  };

  const onClickPrintDeclaracaoIR = async () => {
    setModalShow({ ...modalShow, modalDeclaracaoIR: true });
  };

  const onPrintDeclaracaoAssociado = async () => {
    setLoading(true);
    const { status, message: msg, value } = await printAssociadoDeclaracao(
      data?.nrSeqPessoaAss
    );

    if (value) {
      onOpenReport(transaction.noTransacao, value);
    } else onSetMessage(status, msg);

    setLoading(false);
  };

  const onPrintCarteirinhaAssociado = async () => {
    const obj = {
      nrSeqPessoaAss: data.nrSeqPessoaAss,
      DtValidadeCarteiraAss: data.dataValidade,
    };

    setLoading(true);
    const { status, message: msg, value } = await printAssociadoCarteirinha(
      obj
    );
    if (value) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(status, msg);
    }

    setLoading(false);
  };

  const onPrintDeclaracaoAnualIR = async () => {
    const infoRef = {
      id: data.nrSeqPessoaAss,
      anoRef: infoDeclaraoIR.anoRef,
      nrSeqConvenio: infoDeclaraoIR.nrSeqConvenio,
    };

    setLoading(true);
    const { value, status, message: msg } = await printAssociadoDeclaracaoIRPF(
      infoRef
    );

    if (value) {
      onOpenReport(transaction.noTransacao, value);
      setInfoDeclaraoIR({});
    } else {
      onSetMessage(
        status,
        msg || 'Erro ao imprimir declaração de Importo de Renda.'
      );
    }

    setLoading(false);
  };

  const onPrintRelLancamentoMensalDetalhado = async (info) => {
    setLoading(true);

    const {
      status,
      message: msg,
      value,
    } = await printAssociadoRelMensalDetalhado(info);

    if (value) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      onSetMessage(
        status,
        msg || 'Erro ao imprimir relatório de lançamentos detalhados.'
      );
    }

    setLoading(false);
  };

  const onPrintRelLancamentoMensal = async (info) => {
    setLoading(true);

    const { status, message: msg, value } = await printAssociadoRelMensal(info);

    if (value) onOpenReport(transaction.noTransacao, value);
    else onSetMessage(status, msg);

    setLoading(false);
  };

  const onSearchConvenio = async (e) => {
    const { convenios } = await getConvenioAutoComplete({
      noConvenio: e,
    });
    return convenios;
  };

  const StepDetalhes = () => (
    <div>
      <DetalhesAssociado
        registryKey={registryKey}
        ref={associadoDetalhes}
        data={data}
        setData={setData}
        onSetMessage={onSetMessage}
      />
    </div>
  );

  const StepPlanosAssociado = () => (
    <div>
      <PlanosStep
        registryKey={registryKey}
        ref={planosAssociado}
        data={data}
        setData={setData}
        onSetMessage={onSetMessage}
        flgRural={empresaUsuario?.flgRural}
      />
    </div>
  );

  const StepDependentes = () => (
    <div>
      <DependentesStep
        registryKey={registryKey}
        ref={dependentes}
        data={data}
        setData={setData}
        onSetMessage={onSetMessage}
      />
    </div>
  );

  const StepRelLancamentos = () => (
    <div>
      <RelMensalLancamentosStep
        registryKey={registryKey}
        ref={lancamentos}
        data={data}
        setData={setData}
        onSetMessage={onSetMessage}
        onPrintRelMensalDetalhado={onPrintRelLancamentoMensalDetalhado}
        onPrintRelMensal={onPrintRelLancamentoMensal}
      />
    </div>
  );

  const StepOutros = () => (
    <div>
      <OutrosStep
        registryKey={registryKey}
        ref={outrosStep}
        data={data}
        setData={setData}
        onSetMessage={onSetMessage}
      />
    </div>
  );

  const step1Detalhes = React.createElement(StepDetalhes);
  const step2Planos = React.createElement(StepPlanosAssociado);
  const step3Dependentes = React.createElement(StepDependentes);
  const step4RelLancamentos = React.createElement(StepRelLancamentos);
  const step5Outros = React.createElement(StepOutros);

  const steps = [
    {
      index: 1,
      title: 'Detalhes Cadastro',
      visible: true,
      content: () => step1Detalhes,
    },
    {
      index: 2,
      title: 'Planos Associado',
      visible: true,
      content: () => step2Planos,
    },
    {
      index: 3,
      title: 'Dependentes',
      visible: true,
      content: () => step3Dependentes,
    },
    {
      index: 4,
      title: 'Relatório Mensal',
      visible: data?.nrSeqPessoaAss,
      content: () => step4RelLancamentos,
    },
    {
      index: 5,
      title: 'Outros',
      visible: true,
      content: () => step5Outros,
    },
  ];

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Associado'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqPessoaAss > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.ButtonList
          text='Imprimir'
          icon='caret-down'
          items={[
            {
              text: 'Declaração',
              onClick: () => onPrintDeclaracaoAssociado(),
            },
            {
              text: 'Cartão',
              onClick: () => onClickPrintCartao(),
            },
            {
              text: 'Declaração IR Anual',
              onClick: () => onClickPrintDeclaracaoIR(),
            },
          ]}
        />
      </ToolbarButtons>
      <div className='row mt-2'>
        <div className='col mb-4'>
          <ResumoInformacoes associadoInfo={data} />
        </div>
      </div>
      <div>
        <Steps
          propriedade={1}
          steps={steps}
          theme={Theme.Primary}
          reset={reset}
          align='horizontal'
          registryKey={data.nrSeqPessoaAss}
          data={data}
          setData={setData}
          getData={getData}
          ref={stepAssociado}
        />
      </div>
      <ModalPrintCartao
        show={modalShow.modalCartao}
        dataAss={data}
        setData={setData}
        onClickConfirm={onPrintCarteirinhaAssociado}
        onClose={() => {
          setModalShow({ ...modalShow, modalCartao: false });
        }}
      />

      <ModalPrintDeclaracaoIR
        show={modalShow.modalDeclaracaoIR}
        infoDeclaraoIR={infoDeclaraoIR}
        setInfoDeclaraoIR={setInfoDeclaraoIR}
        onSearchConvenio={onSearchConvenio}
        onClickConfirm={onPrintDeclaracaoAnualIR}
        onClose={() => {
          setModalShow({ ...modalShow, modalDeclaracaoIR: false });
        }}
      />
    </CSDManutPage>
  );
}
