import qs from 'qs';
import Pagination from 'core/models/pagination';
import TituloReceber from 'core/models/FIN/tituloReceber';
import api from '../api';

const baseURL = `/FIN/TituloReceber`;

export const postValidaParcelaTituloReceber = async (data) => {
  const response = await api.post(
    `${baseURL}/ValidaParcelaTituloReceber`,
    data
  );
  return response.data ?? {};
};

export const getUsuarioTituloReceber = async (id) => {
  const response = await api.get(`${baseURL}/GetUsuario`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new TituloReceber(value) : {};
};

export const postParcelaTituloReceber = async (data) => {
  const response = await api.post(`${baseURL}/ParcelaTituloReceber`, data);
  return response.data ?? {};
};

export const postParcelaTituloReceberRepactuacao = async (data) => {
  const response = await api.post(
    `${baseURL}/ParcelaTituloReceberRepactuacao`,
    data
  );
  return response.data ?? {};
};

export const getTituloReceberList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloReceber(json)) ?? [],
  };
};

export const searchRecebimentoMassivo = async (filters) => {
  const response = await api.get(`${baseURL}/SearchRecebimentoMassivo`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloReceber(json)) ?? [],
  };
};

export const receberPagamento = async (data) => {
  const response = await api.post(`${baseURL}/ReceberPagamento`, data);
  return response ?? {};
};

export const SearchTituloReceberMassivo = async (data) => {
  const response = await api.post(
    `${baseURL}/SearchTituloRecebimentoMassivo`,
    data
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloReceber(json)) ?? [],
  };
};

export const getTituloReceberAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloReceber(json)) ?? [],
  };
};

export const getParcells = async (filters) => {
  const response = await api.get(`${baseURL}/Parcells`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloReceber(json)) ?? [],
  };
};

export const postCalculaJurosMulta = async (data) => {
  const response = await api.post(`${baseURL}/CalculateJurosMulta`, data);
  return response.data ?? {};
};

export const postCalculaJurosMultaMassivo = async (data) => {
  const response = await api.post(
    `${baseURL}/CalculateJurosMultaMassivo`,
    data
  );
  return response.data ?? {};
};

export const postCalculatePenaltyParcel = async (data) => {
  const response = await api.post(`${baseURL}/CalculatePenaltyParcel`, data);
  return response.data ?? {};
};

export const postCalculatePenaltyParcelManual = async (data) => {
  const response = await api.post(
    `${baseURL}/CalculatePenaltyParcelManual`,
    data
  );
  return response.data ?? {};
};

export const postCalculaEGeraValoresFaturas = async (data) => {
  const response = await api.post(
    `${baseURL}/CalculaEGeraValoresFaturas`,
    data
  );
  return response.data ?? {};
};

export const postCalculaDescontoTitulosAdiantamentos = async (data) => {
  const response = await api.post(
    `${baseURL}/CalculaDescontoTitulosAdiantamentos`,
    data
  );
  return response.data ?? {};
};

export const getTituloReceberPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloReceber(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getTituloReceber = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  return response.data ?? {};
};

export const printRecibo = async (id) => {
  const response = await api.get(`${baseURL}/PrintRecibo`, {
    params: { nrseqrecebimento: id },
  });
  return response.data ?? {};
};

export const getImprimirDetalhado = async (id) => {
  const response = await api.post(`${baseURL}/getImprimirDetalhado`, id);
  return response ?? {};
};

export const saveTituloReceber = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const postReceberTitulo = async (data) => {
  const response = await api.post(`${baseURL}/Receive`, data);
  return response ?? {};
};

export const saveListTituloReceber = async (data) => {
  const response = await api.post(`${baseURL}/SaveListTituloReceber`, data);
  return response.data ?? {};
};

export const deleteTituloReceber = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printTituloReceber = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const PrintDateReference = async (filters) => {
  const response = await api.get(`${baseURL}/PrintDateReference`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const printRepactuacaoDocumento = async (data) => {
  const response = await api.post(`${baseURL}/PrintRepactuacaoDocumento`, data);
  return response.data ?? {};
};

export const printDetalhamentoRenegociacaoDocumento = async (data) => {
  const response = await api.post(
    `${baseURL}/PrintDetalhamentoRenegociacaoDocumento`,
    data
  );
  return response.data ?? {};
};

export const PrintContratoParcelamento = async (data) => {
  const response = await api.post(`${baseURL}/PrintContratoParcelamento`, data);
  return response.data ?? {};
};

export const excelTituloReceber = async (filters) => {
  const response = await api.get(`${baseURL}/PrintExcel`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const deleteAllTituloReceber = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const DesagrupamentoTituloReceber = async (data) => {
  const response = await api.post(
    `${baseURL}/DesagrupamentoTituloReceber`,
    data
  );
  return response.data ?? {};
};

export const getRetornoTituloReceberVinculado = async (data) => {
  const response = await api.post(
    `${baseURL}/getRetornoTituloReceberVinculado`,
    data
  );
  return response.data.value ?? {};
};

export const BuscarTitulosVencidosDoCliente = async (nrSeqCliente) => {
  const response = await api.get(`${baseURL}/BuscarTitulosVencidosDoCliente`, {
    params: { nrSeqCliente },
  });
  const { value } = response.data ?? {};

  return value?.map((json) => new TituloReceber(json)) ?? [];
};

export const validaTitulosAdiantamentos = async (data) => {
  const response = await api.post(
    `${baseURL}/ValidaTitulosAdiantamentos`,
    data
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloReceber(json)) ?? [],
  };
};

export const getTituloReceberListForFluxoCaixa = async (filters) => {
  const response = await api.get(`${baseURL}/SearchForFluxoCaixa`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloReceber(json)) ?? [],
  };
};
