import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, Theme } from 'ui/Helpers/utils';
import {
  getOperadoraPaginated,
  printOperadora,
} from 'core/services/TEL/operadora';
import { CSDSelPage, GridView, Textbox, Panel } from 'ui/components';

const columns = [
  { key: 'nrSeqOperadora', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdOperadora', title: 'Código', sortable: false },
  { key: 'noOperadora', title: 'Operadora', sortable: false },
  {
    key: 'vlrMensalidade',
    title: 'Mensalidade',
    sortable: false,
    format: GridView.DataTypes.Money,
  },
];

export default function Operadora({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getOperadoraPaginated(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getOperadoraPaginated({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }

    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Operadora/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqOperadora = sourceRow.find((e) => e.key === 'nrSeqOperadora');
    onOpenMaintenance(nrSeqOperadora.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printOperadora(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  return (
    <CSDSelPage
      enableExcelExport
      isActive={isActive}
      title='Seleção de Operadora'
      loading={loading}
      onSearch={() => search()}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Filtros' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row'>
              <div className='col'>
                <Textbox
                  label='Nome Operadora'
                  text={filtros.noOperadora}
                  onChangedValue={(noOperadora) =>
                    setFiltros({ ...filtros, noOperadora })
                  }
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
