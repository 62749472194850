import React, { useState, useRef, useEffect } from 'react';
import {
  CSDSelPage,
  GridView,
  ToolbarButtons,
  DatePicker,
  Switch,
  Textbox,
} from 'ui/components';
import { PageTypes } from 'ui/Helpers/utils';
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces.ts';
import {
  ColumnTypes,
  Theme,
  ResponseStatus,
  ColumnDataTypes,
} from 'ui/Helpers/enums.ts';

import {
  getApoliceSeguroPagined,
  printApoliceSeguro,
  deleteAllApoliceSeguro,
} from 'core/services/FRO/apoliceSeguro';
import ModalGerateMassivoApoliceSeguro from './modalGerateMassvioApoliceSeguro.tsx';

export interface IApoliceSeguroFilters {
  noPessoa?: string;
  flgExcel?: boolean;
  SortBy?: number;
  totalByPage?: number;
  page?: any;
}

export default function ApoliceSeguro({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}: Page) {
  const gridView = useRef<any>();

  const [filtros, setFiltros] = useState<IApoliceSeguroFilters>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [modalShow, setModalShow] = useState({});

  const columns: Array<GridviewColumns> = [
    { key: 'nrSeqApoliceSeguro', type: ColumnTypes.Checkbox },
    { key: 'nrApolice', title: 'Numero Apólice' },
    {
      key: 'fornecedor.noPessoa',
      title: 'Fornecedor',
      sortKey: 'noPessoa',
    },
    {
      key: 'pessoa.noPessoa',
      title: 'Beneficiário',
      sortKey: 'noPessoa',
    },
    {
      key: 'dtInicioVigencia',
      title: 'Data Inicio',
      format: ColumnDataTypes.Date,
    },
    {
      key: 'dtFimVigencia',
      title: 'Data Término?',
      format: ColumnDataTypes.Date,
    },
    {
      key: 'vlrFranquia',
      title: 'Valor Franquia',
    },
    {
      key: 'vlrIndPessoal',
      title: 'Valor Ind. Pessoal',
    },
    {
      key: 'vlrIndMaterial',
      title: 'Valor Ind. Material',
    },
    {
      key: 'vlrApolice',
      title: 'Valor Apólice',
    },
  ];

  const search = async (params = null): Promise<void> => {
    setLoading(true);

    const { data, pagination } = await getApoliceSeguroPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getApoliceSeguroPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onOpenMaintenance = (registryKey = null): void => {
    onOpenPage(
      transaction.noTransacao,
      'ApoliceSeguro/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onColumnSort = async (SortBy: number): Promise<void> => {
    setFiltros({ ...filtros, SortBy });
    await search();
  };

  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    setFiltros({ ...filtros, totalByPage });
    await search();
  };

  const onPageChange = async (page: any): Promise<void> => {
    setFiltros({ ...filtros, page });
    await search();
  };

  const onRowDoubleClick = (sourceRow: any): void => {
    const nrSeqPessoaPos = sourceRow.find(
      (e: any) => e.key === 'nrSeqApoliceSeguro'
    );

    onOpenMaintenance(nrSeqPessoaPos.value);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllApoliceSeguro(
        selecteds.map((row: any) => row[0].value)
      );
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      await search();
    } else {
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printApoliceSeguro(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const gerateMassivo = async () => {
    setLoading(true);

    setModalShow((prevState) => ({
      ...prevState,
      gerateMassivo: true,
    }));

    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Apólice de Seguro'
      loading={loading}
      onSearch={search}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Gerar Massivo'
          icon={['far', 'file-alt']}
          onClick={() => gerateMassivo()}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col-3 mb-3'>
          {/* @ts-expect-error */}
          <Textbox
            maxLength={40}
            label='Código'
            text={filtros.nrApolice}
            onChangedValue={(nrApolice: string) =>
              setFiltros({ ...filtros, nrApolice })
            }
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <DatePicker
            label='Data Início Vigência'
            text={filtros.dtInicioVigencia}
            onChange={(dtInicioVigencia: string) =>
              setFiltros({ ...filtros, dtInicioVigencia })
            }
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <DatePicker
            label='Data Término Vigência'
            text={filtros.dtFimVigencia}
            onChange={(dtFimVigencia: string) =>
              setFiltros({ ...filtros, dtFimVigencia })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Apólices Ativas'
            checked={filtros.flgApolicesAtivos}
            onChange={(flgApolicesAtivos: boolean) =>
              setFiltros({ ...filtros, flgApolicesAtivos })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            onColumnSort={onColumnSort}
            enableExcelExport
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-4'>
          {/* Chama a modal aqui */}
          <ModalGerateMassivoApoliceSeguro
            show={modalShow?.gerateMassivo}
            onClose={() => {
              setModalShow({ ...modalShow, gerateMassivo: false });
            }}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
