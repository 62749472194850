import { FormaPagamento } from 'core/models/FIN';
import api from '../api';

// const baseURL = `/FIN/FormaPagamento`;

export const getFormaPagamentoAutoComplete = async (filters) => {
  const response = await api.get(`/FormaPagamento/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    formasPagamento: value?.map((json) => new FormaPagamento(json)) ?? [],
  };
};

export const getFormaPagamentoPdvAutoComplete = async (filters) => {
  const response = await api.get(`/FormaPagamento/AutoCompletePdv`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    formasPagamento: value?.map((json) => new FormaPagamento(json)) ?? [],
  };
};
