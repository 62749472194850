export default class PocPocoPrefiltro {
  constructor(jsonObject = {}) {
    this.nrSeqPocoPrefiltro = jsonObject.nrSeqPocoPrefiltro;
    this.nrSeqPocTipoPrefiltro = jsonObject.nrSeqPocTipoPrefiltro;
    this.nrSeqPocPoco = jsonObject.nrSeqPocPoco;
    this.nrInicio = jsonObject.nrInicio;
    this.nrFim = jsonObject.nrFim;
    this.nrSeqPocGranulometria = jsonObject.nrSeqPocGranulometria;
    this.nrEaInt = jsonObject.nrEaInt;
    this.nrEaExt = jsonObject.nrEaExt;
    this.nrSeqPocGranulometria = jsonObject.nrSeqPocGranulometria;
    this.cdPocGranulometria = jsonObject.cdPocGranulometria;
    this.noPocGranulometria = jsonObject.noPocGranulometria;
    this.nrSeqPocTipoPrefiltro = jsonObject.nrSeqPocTipoPrefiltro;
    this.cdPocTipoPrefiltro = jsonObject.cdPocTipoPrefiltro;
    this.noPocTipoPrefiltro = jsonObject.noPocTipoPrefiltro;
    this.totalPrefiltro = jsonObject.totalPrefiltro;

    this.status = jsonObject.status;
  }

  get getPrefiltro() {
    return `${this.cdPocTipoPrefiltro} - ${this.noPocTipoPrefiltro}`;
  }

  toJson = () => JSON.stringify(this);
}
