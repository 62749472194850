export const SortDirections = { Asc: 'asc', Desc: 'desc' };
export const BootstrapSizes = {
  ExtraSmall: 'xs',
  Small: 'sm',
  Medium: 'md',
  Large: 'lg',
  ExtraLarge: 'xl',
};
export const PageTypes = {
  Selection: { id: 'selection', title: 'Seleção', icon: 'list', index: 0 },
  Maintenence: {
    id: 'maintenance',
    title: 'Edição',
    icon: 'edit',
    index: 1,
  },
  Report: { id: 'report', title: 'Relatório', icon: 'print', index: 2 },
  Other: { id: 'other', index: 3 },
};
export const ResponseStatus = { Success: 0, Error: 1, Warning: 2 };

export const Themes = [
  'primary',
  'success',
  'danger',
  'warning',
  'info',
  'light',
  'secondary',
  'dark',
  'inactive',
];

export const Theme = {
  Primary: 'primary',
  Success: 'success',
  Danger: 'danger',
  Warning: 'warning',
  Info: 'info',
  Light: 'light',
  Secondary: 'secondary',
  Dark: 'dark',
  Inactive: 'inactive',
};

export const JustifyContent = {
  Start: 'justify-content-start',
  End: 'justify-content-end',
};

export class MimeTypes {
  static Types = {
    Zip: 'zip',
    PDF: 'pdf',
    Word: 'word',
    Excel: 'excel',
    Image: 'image',
    XML: 'xml',
    Txt: 'txt',
    Pfx: 'pfx',
  };

  static types = [
    {
      name: this.Types.PDF,
      type: 'application/pdf',
      extension: '.pdf',
      isPdf: true,
    },
    {
      name: this.Types.Txt,
      type: 'application/txt',
      extension: '.txt',
      isPdf: true,
    },
    {
      name: this.Types.Excel,
      type: 'application/vnd.ms-excel',
      extension: '.xls',
      isExcel: true,
    },
    {
      name: this.Types.Excel,
      type: 'text/csv',
      extension: '.csv',
      isExcel: true,
    },
    {
      name: this.Types.Excel,
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      extension: '.xlsx',
      isExcel: true,
    },
    {
      name: this.Types.Image,
      type: 'image/gif',
      extension: '.gif',
      isImage: true,
    },
    {
      name: this.Types.Image,
      type: 'image/png',
      extension: '.png',
      isImage: true,
    },
    {
      name: this.Types.Image,
      type: 'image/jpeg',
      extension: '.jpg',
      isImage: true,
    },
    {
      name: this.Types.Image,
      type: 'image/bmp',
      extension: '.bmp',
      isImage: true,
    },
    {
      name: this.Types.Image,
      type: 'image/webp',
      extension: '.webp',
      isImage: true,
    },
    {
      name: this.Types.Word,
      type: 'application/msword',
      extension: '.doc',
      isWord: true,
    },
    {
      name: this.Types.Word,
      type:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      extension: '.docx',
      isWord: true,
    },
    {
      name: this.Types.XML,
      type: 'text/xml',
      extension: '.xml',
      isXml: true,
    },
    {
      name: this.Types.PDF,
      type: 'text/pfx',
      extension: '.pfx',
      isXml: true,
    },
    {
      name: this.Types.Zip,
      type: 'application/zip',
      extension: '.zip',
      isZip: true,
    },
  ];

  static isZip = (type) =>
    this.types.find((p) => p.type === type)?.isZip ?? false;

  static isPdf = (type) =>
    this.types.find((p) => p.type === type)?.isPdf ?? false;

  static isExcel = (type) =>
    this.types.find((p) => p.type === type)?.isExcel ?? false;

  static isWord = (type) =>
    this.types.find((p) => p.type === type)?.isWord ?? false;

  static isImage = (type) =>
    this.types.find((p) => p.type === type)?.isImage ?? false;

  static isXml = (type) =>
    this.types.find((p) => p.type === type)?.isXml ?? false;

  static isValid = (type) =>
    Object.values(this.Types).find((p) => p === type) !== undefined;

  static getValidFiles = (files, allowedTypes) =>
    files.filter((x) =>
      allowedTypes.includes(this.types.find((p) => p.type === x.type).name)
    ) ?? [];

  static getAcceptedTypes = (allowedTypes) => {
    const accept = [];
    this.types.forEach((x) => {
      if (allowedTypes.includes(x.name)) {
        if (!accept.includes(x.extension)) accept.push(x.extension);
        if (!accept.includes(x.type)) accept.push(x.type);
      }
    });
    return accept.join(', ');
  };

  static getExtension = (mimeType) =>
    this.types.find((p) => p.type === mimeType)?.extension;
}

let lastId = 0;
export const generateID = (prefix = 'id') => {
  lastId += 1;
  return `${prefix}${lastId}`;
};

export const removeSpaces = (text) => text.replace(/\s+/g, '');

export const timeToDecimal = (t) => {
  if (/^\d{1,2}\w+\d{1,2}$/gi.test(t)) {
    return t;
  }

  const [hour, min] = t.split(':');

  const dec = parseInt((min / 6) * 10, 10);

  const parte1 = parseInt(hour, 10);
  const parte2 = dec < 10 ? '0' : '';
  const parte3 = `${parte2}${dec}`;

  return parseFloat(`${parte1}.${parte3}`);
};

export const formatDate = (date) =>
  date ? new Date(date).toLocaleString('pt-BR') : '';

export const formatNumber = (num) => {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return Number(num).toLocaleString('pt', options);
};

export const formatStringNumberToFloat = (num) => {
  if (!num) return 0;
  let resp = String(num);

  if (resp.includes('.') && resp.includes(',')) {
    resp = num.replace('.', '');
  }
  resp = parseFloat(resp.replaceAll(',', '.'));

  return resp;
};

export const firstLetterUpperCase = (word) => {
  if (!word) return '';
  let strword = String(word);
  if (strword.length === 1) return strword.toUpperCase();

  strword = strword.toLocaleLowerCase();
  strword = strword.substring(0, 1).toUpperCase() + strword.substring(1);
  strword = strword.trim();
  return strword;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export const dateNow = () => {
  const date = new Date().toLocaleDateString('fr-CA');
  return date;
};

export const limitText = (word, lenght) => word.substring(0, lenght);

export const isVowel = (letter) => {
  if (letter.toLocaleLowerCase() === 'a') return true;
  if (letter.toLocaleLowerCase() === 'e') return true;
  if (letter.toLocaleLowerCase() === 'i') return true;
  if (letter.toLocaleLowerCase() === 'o') return true;
  if (letter.toLocaleLowerCase() === 'u') return true;
  return false;
};

export const formatCurrentDate = (days) => {
  const today = new Date();
  if (days) today.setDate(today.getDate() + days);
  return today.toISOString().slice(0, 10);
};

export const limitTextSplitLine = (word, lenght, lines) => {
  const resp = [];
  let words = [];
  let textPending = '';
  textPending = word.trim();
  let textAdd = '';
  let textpendingNext = '';
  let contLine = 0;
  words = textPending.split(' ');
  for (let cont = 0; cont < words.length; cont += 1) {
    if (textpendingNext) {
      textAdd = textpendingNext;
      textpendingNext = '';
    }
    if ((textAdd + words[cont]).length >= lenght) {
      resp.push(textAdd);
      textAdd = '';
      contLine += 1;
      textpendingNext = words[cont];

      if (contLine === lines) return resp;
    } else textAdd += ` ${words[cont]} `;
  }

  if (textAdd.length > 0) resp.push(textAdd);

  return resp;
};

export const convertThemeToRgb = (theme) => {
  let arrayRgb = [];
  let borderRgb = [];

  if (theme === Theme.Success) {
    arrayRgb = ['25', '135', '84'];
    borderRgb = ['16', '84', '53'];
  } else if (theme === Theme.Danger) {
    arrayRgb = ['220', '53', '69'];
    borderRgb = ['138', '33', '43'];
  } else if (theme === Theme.Warning) {
    arrayRgb = ['255', '193', '7'];
    borderRgb = ['159', '121', '4'];
  } else if (theme === Theme.Secondary) {
    arrayRgb = ['173', '181', '189'];
    borderRgb = ['108', '113', '118'];
  } else if (theme === Theme.Info) {
    arrayRgb = ['13', '202', '240'];
    borderRgb = ['8', '126', '150'];
  } else if (theme === Theme.Dark) {
    arrayRgb = ['28', '28', '28'];
    borderRgb = ['0', '0', '0'];
  } else {
    arrayRgb = ['13', '110', '253'];
    borderRgb = ['8', '69', '158'];
  }

  return {
    arrayRgb,
    borderRgb,
  };
};
