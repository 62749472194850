import React from 'react';

import Axis from '../Axis';

import style from './index.module.css';

function BodyCar({ data = [], onClick, nrSeqVeiculo }) {
  let filledTires = 0;

  const click = (current) => onClick({ ...current, nrSeqVeiculo });

  const fillBodyStructure = () => (
    <div className={style.body_structure_area_without_tire}>
      <div className={style.body_structure_area_without_tire_left} />
      <div className={style.body_structure_area_without_tire_right} />
    </div>
  );

  return (
    <div className={style.bodyCar}>
      {data.map((element, index) => {
        const eixo = (
          <>
            <Axis
              modeloEixo={element}
              filledTires={filledTires}
              onClick={click}
              nrSeqVeiculo={nrSeqVeiculo}
              first={index === 0}
              last={index === data.length - 1}
            />
            {index !== data.length - 1 ? fillBodyStructure() : ''}
          </>
        );
        const { qtdePneuEsquerda, qtdePneuDireita } = element.eixo;
        filledTires += qtdePneuEsquerda + qtdePneuDireita;
        return eixo;
      })}
    </div>
  );
}

export default BodyCar;
