import Funcionario from 'core/models/SEG/funcionario';

export default class OrdemServicoTecnico {
  constructor(jsonObject = {}) {
    this.nrSeqOrdemServicoTecnico = jsonObject.nrSeqOrdemServicoTecnico;
    this.nrSeqOrdemServico = jsonObject.nrSeqOrdemServico;
    this.nrSeqPessoaFun = jsonObject.nrSeqPessoaFun;
    this.noTecnico = jsonObject.noTecnico;
    this.funcionario = new Funcionario(jsonObject.funcionario ?? {});
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
