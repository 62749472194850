import React, { forwardRef, useState, useEffect } from 'react';
import { Textbox, Autocomplete } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { Endereco } from 'core/models/SEG';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';

import { TransacationCodes } from 'ui/Helpers/transactionCodes';

const CadastroCliente = forwardRef(
  ({ onOpenTransaction, findTransaction, data, setData, registryKey }) => {
    const [endereco, setEndereco] = useState(new Endereco());
    const [dataCadastroCliente, setDataCadastroCliente] = useState(data);

    const onSearchCliente = async (e) => {
      const { clientes } = await getClienteAutoComplete({
        noPessoa: e,
        flgIncluirEndereco: true,
      });
      return clientes;
    };

    const load = async () => {
      if (Number.isInteger(registryKey)) {
        setEndereco(data.cliente?.endereco ?? {});
      }
    };

    useEffect(() => {
      (async function func() {
        load();
      })();
    }, [data.nrSeqPessoaCli, registryKey]);

    return (
      <div>
        <div className='row mb-3'>
          <div className='display-8'>1 - Cliente</div>
        </div>
        <div className='card'>
          <div className='card-body'>
            <div className='row mb-3'>
              <div className='row'>
                <div className='row mb-3'>
                  <div className='col'>
                    <Autocomplete
                      label='Cliente'
                      searchDataSource={onSearchCliente}
                      selectedItem={dataCadastroCliente.cliente}
                      findTransaction={findTransaction}
                      onOpenTransaction={onOpenTransaction}
                      transactionToOpen={TransacationCodes.Cliente}
                      nrseqTransactionToOpen='nrSeqPessoaCli'
                      onSelectItem={(cliente) => {
                        dataCadastroCliente.nrSeqPessoaCli =
                          cliente.nrSeqPessoaCli;
                        dataCadastroCliente.cliente = cliente;

                        setDataCadastroCliente({
                          ...dataCadastroCliente,
                        });

                        setData({
                          ...dataCadastroCliente,
                        });

                        setEndereco(cliente.endereco ?? new Endereco());
                      }}
                      enabled={!dataCadastroCliente.nrSeqPocPoco > 0}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col'>
                    <Textbox
                      label='RG'
                      text={dataCadastroCliente.cliente.nrRG}
                      visible={dataCadastroCliente.cliente.flgFisica}
                      maxLength={15}
                      readOnly
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='CPF'
                      text={dataCadastroCliente.cliente.nrDocumento}
                      mask={MaskTypes.CPF}
                      visible={dataCadastroCliente.cliente.flgFisica}
                      readOnly
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='CNPJ'
                      text={dataCadastroCliente.cliente.nrDocumento}
                      mask={MaskTypes.CNPJ}
                      visible={!dataCadastroCliente.cliente.flgFisica}
                      readOnly
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col'>
                    <Textbox label='Endereço' text={endereco.noRua} readOnly />
                  </div>
                  <div className='col-2'>
                    <Textbox label='Numero' text={endereco.numero} readOnly />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col'>
                    <Textbox label='Bairro' text={endereco.bairro} readOnly />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='Cidade'
                      text={endereco.cidade?.noCidade}
                      readOnly
                    />
                  </div>
                  <div className='col'>
                    <Textbox label='Cep' text={endereco.cep} readOnly />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='UF'
                      text={endereco.cidade?.estado.cdEstado}
                      readOnly
                    />
                  </div>
                </div>
                <div className='row mb-3' style={{ paddingBottom: '250px' }}>
                  <div className='col'>
                    <Textbox
                      label='Email'
                      text={dataCadastroCliente.cliente.email}
                      readOnly
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='Telefone'
                      text={dataCadastroCliente.cliente.fone}
                      mask={MaskTypes.CellPhone}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default CadastroCliente;
