import React from 'react';

import logo from './logoBancoDoBrasil.jpg';

const ChequeBancoDoBrasil = (props) => {
  const { model } = props;
  const {
    vlrCheque,
    nrCheque,
    noValorPorExtenso,
    dayFormatted,
    monthFormatted,
    yearFormatted,
    contaCorrente,
  } = model;
  const { agencia } = contaCorrente;

  return (
    <div className='styleBordaExternaBancoDoBrasil'>
      <div className='row'>
        <div className='col-1 stylePadrao'>
          <span> Comp</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>Banco</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span className='textosimples'>Agência</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>DV</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>C1</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>Conta</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>C2</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>Série</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>No</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>C3</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>R$</span>
        </div>
        <div className='col-1 text-left'>
          <span className='styleValorCheque'>{vlrCheque.toFixed(2)}</span>
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col-1 text-left stylePadrao'>
          <span>010</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>{agencia ? agencia.banco.cdBanco : ''}</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>{agencia ? agencia.cdAgenciaFormatted : ''}</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>1</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>4</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>{contaCorrente ? contaCorrente.cdContaCorrente : ''}</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>2</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>001</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>{nrCheque}</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>4</span>
        </div>
        <div className='col-1 text-left stylePadrao'>
          <span>-</span>
        </div>
      </div>

      <div className='row'>
        <div className='col styleIdentifacaoEmpresa'>
          Pague por este cheque a quantia de
        </div>
      </div>

      <div className='row mb-4'>
        <div className='col styleValo styleValorPorExtenso'>
          <span>{noValorPorExtenso}</span>
        </div>
      </div>

      <div className='row mb-4'>
        <div className='col-5 logo'>
          <img src={logo} alt='logo' />
        </div>

        <div className='col-7'>
          <div className='row'>
            <div className='col'>
              <span className='styleDataEmissaoDia'>{dayFormatted}</span>
            </div>
            <div className='col'>
              <span className='stylePadrao'>de</span>
            </div>

            <div className='col-4'>
              <span className='styleDataEmissaoMes'>{monthFormatted}</span>
            </div>

            <div className='col'>
              <span className='stylePadrao'>de</span>
            </div>

            <div className='col'>
              <span className='styleDataEmissaoAno'>{yearFormatted}</span>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-5 styleIdentifacaoEmpresa'>Empresa Canoas RS</div>
        <div className='col styleLinhaAssinatura' />
      </div>

      <div className='row'>
        <div className='col-5 styleIdentifacaoEmpresa'>
          <span>00.000.000/5420-83</span>
        </div>
        <div className='col styleIdentifacaoEmpresa'>
          <span>TRANSPORTADORA CADOMAR LTDA</span>
        </div>
      </div>

      <div className='row'>
        <div className='col-5 styleIdentifacaoEmpresa'>
          <span>01-CARTEIRA 01</span>
        </div>
        <div className='col styleIdentifacaoEmpresa'>
          <span>CNPJ 88.325.816/0001-36</span>
        </div>
      </div>

      <div className='row'>
        <div className='col-5 styleIdentifacaoEmpresa'>
          <span>R. IPIRANGA, 95-7ANDAR</span>
        </div>
        <div className='col styleIdentifacaoEmpresa'>
          <span> CLIENTE BANCÁRIO DESDE 07/2000</span>
        </div>
      </div>
    </div>
  );
};

export default ChequeBancoDoBrasil;
