import qs from 'qs';
import Pagination from 'core/models/pagination';
import Prospect from 'core/models/POC/prospect';
import api from '../api';

const baseURL = `/POC/Prospect`;

export const getProspectList = async (filters) => {
  const response = await api.get(`/$Prospect/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Prospect(json)) ?? [],
  };
};

export const getProspectPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Prospect(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getProspectAutoComplete = async (id) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: { id },
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    prospects: value?.map((json) => new Prospect(json)) ?? [],
  };
};

export const getProspect = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Prospect(value) : {};
};

export const saveProspect = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteProspect = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printProspect = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelProspect = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllProspect = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
