import React, { useRef, useEffect, useState } from 'react';
import {
  Carousel,
  CSDBarGraphic,
  CSDPieGraphic,
  CSDManutPage,
} from 'ui/components';
import { Theme, ResponseStatus } from 'ui/Helpers/utils';

import {
  getGraficos,
  getDashboardTransporte,
} from 'core/services/FRO/dashboardAbastecimento';

const EnumGraficos = Object.freeze({
  EstadoColeta: 0,
  ValorEstadoColeta: 1,
  EstadoEntrega: 2,
  ValorEstadoEntrega: 3,
  PesoEstadoEntrega: 4,
  PesoCarregado: 5,
  FaturamentoPorCliente: 6,
  CarregamentoCteMes: 7,
  PesoMes: 8,
  CarregamentoCteAno: 9,
  TotalPeso: 10,
  NumeroEmbarques: 11,
  TotalLitros: 12,
  valorMedioPosto: 13,
  valorPorVeiculo: 14,
  valorMedioPorModelo: 15,
  valorMediaPorVeiculo: 16,
  valorMediaPorEstado: 17,
});

export default function DashboardSlide({ isActive, reload, registryKey }) {
  const filters = registryKey;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [graficos, setGraficos] = useState([]);
  const estadoColeta = useRef();
  const estadoEntrega = useRef();
  const valorEstadoColeta = useRef();
  const valorEstadoEntrega = useRef();
  const pesoEstadoEntrega = useRef();
  const pesoCarregado = useRef();
  const faturamentoPorCliente = useRef();
  const carregamentoCteMes = useRef();
  const carregamentoCteAno = useRef();
  const totalPeso = useRef();
  const numeroEmbarques = useRef();
  const totalLitros = useRef();
  const valorMedioPosto = useRef();
  const valorPorVeiculo = useRef();
  const valorMedioPorModelo = useRef();
  const valorMediaPorVeiculo = useRef();
  const valorMediaPorEstado = useRef();
  const pesoMes = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const configuraGrafico = (grafico, type) => {
    let dataGrafico = {};

    if (type === 1) {
      dataGrafico = {
        labels: grafico.labels,
        datasets: [
          {
            data:
              grafico.integerData.length > 0
                ? grafico.integerData
                : grafico.decimalData,
            themes: grafico.themes,
            theme: grafico.theme ?? Theme.Primary,
          },
        ],
      };

      if (grafico.labelDataSet)
        dataGrafico.datasets.forEach((item) => {
          item.label = grafico.labelDataSet;
        });
    } else {
      dataGrafico = {
        labels: grafico.labels,
        datasets: grafico.dataSets.map((dataSet) => {
          if (dataSet.dataInteger.length > 0)
            dataSet.data = dataSet.dataInteger;
          else dataSet.data = dataSet.dataDecimal;

          return dataSet;
        }),
      };
    }

    return dataGrafico;
  };

  const carregaGrafico = (dataGrafico, enumGrafico) => {
    const datasource = {
      [EnumGraficos.PesoCarregado]: pesoCarregado.current,
      [EnumGraficos.TotalPeso]: totalPeso.current,
      [EnumGraficos.NumeroEmbarques]: numeroEmbarques.current,
      [EnumGraficos.FaturamentoPorCliente]: faturamentoPorCliente.current,
      [EnumGraficos.CarregamentoCteMes]: carregamentoCteMes.current,
      [EnumGraficos.PesoMes]: pesoMes.current,
      [EnumGraficos.EstadoColeta]: estadoColeta.current,
      [EnumGraficos.EstadoEntrega]: estadoEntrega.current,
      [EnumGraficos.ValorEstadoColeta]: valorEstadoColeta.current,
      [EnumGraficos.ValorEstadoEntrega]: valorEstadoEntrega.current,
      [EnumGraficos.PesoEstadoEntrega]: pesoEstadoEntrega.current,
      [EnumGraficos.CarregamentoCteAno]: carregamentoCteAno.current,
      [EnumGraficos.TotalLitros]: totalLitros.current,
      [EnumGraficos.valorMedioPosto]: valorMedioPosto.current,
      [EnumGraficos.valorPorVeiculo]: valorPorVeiculo.current,
      [EnumGraficos.valorMedioPorModelo]: valorMedioPorModelo.current,
      [EnumGraficos.valorMediaPorVeiculo]: valorMediaPorVeiculo.current,
      [EnumGraficos.valorMediaPorEstado]: valorMediaPorEstado.current,
    };

    datasource[enumGrafico]?.setDataSource(dataGrafico);
  };

  const optionsPieGraphic = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const { dataIndex } = tooltipItem;
            const currentValue = tooltipItem.dataset.data[dataIndex];
            const total = tooltipItem.dataset.data.reduce(
              (sum, item) => sum + item
            );

            const percentage = parseFloat(
              ((currentValue / total) * 100).toFixed(1)
            );

            return ` ${percentage}% (${currentValue})`;
          },
        },
      },
    },
  };

  const optionsBarGraphic = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const { formattedValue } = tooltipItem;

            return `Valor: ${formattedValue}`;
          },
        },
        legend: {
          position: 'bottom',
        },
      },
    },
  };

  const validaRenderizacaoDoGrafico = (enumGrafico, data) => {
    switch (enumGrafico) {
      case EnumGraficos.PesoCarregado:
        return !!data.pesoCarregado;

      case EnumGraficos.TotalPeso:
        return !!data.totalPeso;

      case EnumGraficos.NumeroEmbarques:
        return !!data.numeroEmbarques;

      case EnumGraficos.FaturamentoPorCliente:
        return !!data.faturamentoPorCliente;

      case EnumGraficos.CarregamentoCteMes:
        return !!data.carregamentoCteMes;

      case EnumGraficos.PesoMes:
        return !!data.pesoMes;

      case EnumGraficos.EstadoColeta:
        return !!data.estadoColeta;

      case EnumGraficos.EstadoEntrega:
        return !!data.estadoEntrega;

      case EnumGraficos.ValorEstadoColeta:
        return !!data.valorEstadoColeta;

      case EnumGraficos.ValorEstadoEntrega:
        return !!data.valorEstadoEntrega;

      case EnumGraficos.PesoEstadoEntrega:
        return !!data.pesoEstadoEntrega;

      case EnumGraficos.CarregamentoCteAno:
        return !!data.carregamentoCteAno;

      case EnumGraficos.TotalLitros:
        return !!data.totalLitros;

      case EnumGraficos.valorMedioPosto:
        return !!data.valorMedioPosto;
      case EnumGraficos.valorPorVeiculo:
        return !!data.valorPorVeiculo;
      case EnumGraficos.valorMedioPorModelo:
        return !!data.valorMedioPorModelo;
      case EnumGraficos.valorMediaPorVeiculo:
        return !!data.valorMediaPorVeiculo;
      case EnumGraficos.valorMediaPorEstado:
        return !!data.valorMediaPorEstado;

      default:
        return false;
    }
  };

  const adicionaNaLista = (enumGrafico, data) => {
    const graficosAux = {
      [EnumGraficos.PesoCarregado]: pesoCarregado,
      [EnumGraficos.TotalPeso]: totalPeso,
      [EnumGraficos.NumeroEmbarques]: numeroEmbarques,
      [EnumGraficos.FaturamentoPorCliente]: faturamentoPorCliente,
      [EnumGraficos.CarregamentoCteMes]: carregamentoCteMes,
      [EnumGraficos.PesoMes]: pesoMes,
      [EnumGraficos.EstadoColeta]: estadoColeta,
      [EnumGraficos.EstadoEntrega]: estadoEntrega,
      [EnumGraficos.ValorEstadoColeta]: valorEstadoColeta,
      [EnumGraficos.ValorEstadoEntrega]: valorEstadoEntrega,
      [EnumGraficos.PesoEstadoEntrega]: pesoEstadoEntrega,
      [EnumGraficos.CarregamentoCteAno]: carregamentoCteAno,
      [EnumGraficos.TotalLitros]: totalLitros,
      [EnumGraficos.valorMedioPosto]: valorMedioPosto,
      [EnumGraficos.valorPorVeiculo]: valorPorVeiculo,
      [EnumGraficos.valorMedioPorModelo]: valorMedioPorModelo,
      [EnumGraficos.valorMediaPorVeiculo]: valorMediaPorVeiculo,
      [EnumGraficos.valorMediaPorEstado]: valorMediaPorEstado,
    };

    let componente = null;

    if (validaRenderizacaoDoGrafico(enumGrafico, data)) {
      componente = graficosAux[enumGrafico];
    }

    if (!componente) return null;

    const isPieChart =
      enumGrafico === EnumGraficos.EstadoColeta ||
      enumGrafico === EnumGraficos.EstadoEntrega ||
      enumGrafico === EnumGraficos.ValorEstadoColeta ||
      enumGrafico === EnumGraficos.ValorEstadoEntrega;

    const ComponenteFinal = isPieChart ? CSDPieGraphic : CSDBarGraphic;
    const options = isPieChart ? optionsPieGraphic : optionsBarGraphic;
    const className = isPieChart ? 'col-4' : 'col-8';

    return (
      <div className={className}>
        <ComponenteFinal ref={componente} options={options} />
      </div>
    );
  };

  const carregaGraficos = async (datasource, data) => {
    const graficosAux = [];

    for (let i = 0; i < datasource.length; i += 1) {
      const type = i > 8 ? 2 : 1;

      const dataGrafico = configuraGrafico(datasource[i], type);

      const graficoAux = adicionaNaLista(datasource[i].enumGrafico, data);

      if (graficoAux !== null) graficosAux.push(graficoAux);

      carregaGrafico(dataGrafico, datasource[i].enumGrafico);
    }

    setGraficos(graficosAux);
  };

  const load = async () => {
    setLoading(true);

    if (filters) {
      const { data: result, status, message: msg } = await getGraficos(filters);

      if (msg) onSetMessage(status, msg);

      if (status === ResponseStatus.Success) {
        const {
          status: statusData,
          message: msgData,
          data: dashboardTransporte,
        } = await getDashboardTransporte();

        if (statusData === ResponseStatus.Success) {
          await carregaGraficos(result, dashboardTransporte);
        }

        if (msgData) onSetMessage(statusData, msgData);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    load();
  }, [reload]);

  return (
    <CSDManutPage isActive={isActive} message={message} loading={loading}>
      <div className='row'>
        <Carousel items={graficos} indicators interval={5000} />
      </div>
    </CSDManutPage>
  );
}
