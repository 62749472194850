import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Textbox } from 'ui/components';
import {
  getPneuModelo,
  savePneuModelo,
  deletePneuModelo,
} from 'core/services/FRO/pneuModelo';
import { getPneuMarcaAutoComplete } from 'core/services/FRO/pneuMarca';

export default function PneuModeloItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getPneuModelo(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await savePneuModelo(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deletePneuModelo(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchPneuMarca = async (e) => {
    const {
      status,
      message: msg,
      pneuMarcas,
    } = await getPneuMarcaAutoComplete({ noPneuMarca: e });
    if (msg) onSetMessage(status, msg);
    return pneuMarcas;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Pneu Modelo'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqPneuModelo > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={20}
            label='Código'
            text={data.cdPneuModelo}
            onChangedValue={(cdPneuModelo) =>
              setData({ ...data, cdPneuModelo })
            }
          />
        </div>
        <div className='col-8 mb-3'>
          <Textbox
            maxLength={60}
            label='Descrição'
            text={data.noPneuModelo}
            onChangedValue={(noPneuModelo) =>
              setData({ ...data, noPneuModelo })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='Marca'
            searchDataSource={onSearchPneuMarca}
            selectedItem={data.pneuMarca}
            onSelectItem={(pneuMarca) => {
              setData({
                ...data,
                pneuMarca,
                nrSeqPneuMarca: pneuMarca.nrSeqPneuMarca,
              });
            }}
            dataSourceTextProperty='noPneuMarca'
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
