import React, { useRef, useState, useEffect } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  getFilteredManutencaoVeiculoOSPagined,
  printReportManutencaoVeiculoOS,
  excelManutencaoVeiculoOS,
  deleteAllManutencaoVeiculoOS,
  printManutencaoVeiculoOSReportPorOficina,
  printManutencaoVeiculoOSReportPorOficinaCompra,
  printManutencaoVeiculoOSReportPorPlaca,
  closeOS,
} from 'core/services/FRO/ordemServico_Manutencao';
import {
  CSDSelPage,
  GridView,
  Panel,
  Textbox,
  RadioButton,
  Autocomplete,
  DatePicker,
  Button,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getVeiculoAutoCompleteResumido } from 'core/services/FRO/veiculo';
import { getCondicaoPagamentoAutoComplete } from 'core/services/FIN/condicaoPagamento';
import { getOficinaAutocomplete } from 'core/services/FRO/oficina';
import { getUsuarioAutoComplete } from 'core/services/SEG/usuario';
import { getProdutoAutoComplete } from 'core/services/EST/produto';
import { getProdutoGrupoAutoComplete } from 'core/services/EST/produtoGrupo';
import { searchProdutoSubGrupoAutoComplete } from 'core/services/EST/produtoSubGrupo';

// #region COLUNAS GRID
const columns = [
  {
    key: 'nrSeqManutencaoVeiculoOS',
    type: GridView.ColumnTypes.Checkbox,
    colorText: 'noColorPendencia',
  },
  {
    key: 'cdManutencaoVeiculoOS',
    title: 'Código',
    colorText: 'noColorPendencia',
  },
  {
    key: 'dtAbertura',
    title: 'Data de Abertura',
    format: GridView.DataTypes.Date,
    colorText: 'noColorPendencia',
  },
  {
    key: 'placa',
    title: 'Veículo',
    sortKey: 'nrSeqVeiculo',
    colorText: 'noColorPendencia',
  },
  {
    key: 'noVeiculosVinculados',
    title: 'Veículos Vinculados',
    sortable: false,
    colorText: 'noColorPendencia',
  },
  { key: 'nrOdometro', title: 'Odômetro', colorText: 'noColorPendencia' },
  {
    key: 'noOficina',
    title: 'Oficina',
    sortKey: 'noPessoa',
    colorText: 'noColorPendencia',
  },
  {
    key: 'cidadeUF',
    title: 'Cidade/UF',
  },
  {
    key: 'vlrOs',
    title: 'Valor Orçado',
    visible: false,
    format: GridView.DataTypes.Money,
    colorText: 'noColorPendencia',
  },
  {
    key: 'vlrFechamentoOS',
    title: 'Valor de Fechamento',
    format: GridView.DataTypes.Money,
    colorText: 'noColorPendencia',
  },
  {
    key: 'noCondicaoPagamento',
    title: 'Condição Pagamento',
    sortKey: 'noCondicaoPagamento',
    visible: false,
    colorText: 'noColorPendencia',
  },
  {
    key: 'dtCad',
    title: 'Data de Cadastro',
    visible: false,
    format: GridView.DataTypes.Date,
    colorText: 'noColorPendencia',
  },
  {
    key: 'dtSaida',
    title: 'Data de Saída',
    format: GridView.DataTypes.Date,
    colorText: 'noColorPendencia',
  },
  {
    key: 'dtPrevistaRetorno',
    title: 'Previsão de Retorno',
    format: GridView.DataTypes.Date,
    colorText: 'noColorPendencia',
  },
  {
    key: 'dtAlteracao',
    title: 'Data de Alteração',
    visible: false,
    format: GridView.DataTypes.Date,
    colorText: 'noColorPendencia',
  },
  {
    key: 'dtFechamento',
    title: 'Data de Fechamento',
    visible: false,
    format: GridView.DataTypes.Date,
    colorText: 'noColorPendencia',
  },
  {
    key: 'noDataTempoPendente',
    title: 'Dias Pendente',
    visible: true,
    colorText: 'noColorPendencia',
  },
  {
    key: 'noPessoaMot',
    title: 'Motorista',
  },
  {
    key: 'noUsuarioCad',
    title: 'Cadastrado Por',
    sortKey: 'noUsuarioCad',
    visible: false,
    sortable: false,
    colorText: 'noColorPendencia',
  },
  {
    key: 'noUsuarioAlt',
    title: 'Alterado Por',
    sortKey: 'noUsuarioAlt',
    visible: false,
    sortable: false,
    colorText: 'noColorPendencia',
  },
  {
    key: 'noUsuarioAut',
    title: 'Autorizado Por',
    sortKey: 'noUsuarioAut',
    visible: false,
    sortable: false,
    colorText: 'noColorPendencia',
  },
  {
    key: 'noUsuarioSol',
    title: 'Solicitado Por',
    sortKey: 'noUsuarioSol',
    colorText: 'noColorPendencia',
    visible: false,
    sortable: false,
  },
  {
    key: 'noStatusManutencao',
    title: 'Manutenção',
    sortable: false,
    colorText: 'noColorPendencia',
  },
  {
    key: 'obsOS',
    title: 'Observações',
    sortable: false,
    visible: false,
  },
  {
    key: 'noProdutosConcat',
    title: 'Produtos',
    sortable: false,
    visible: false,
  },
  {
    key: 'status',
    title: 'Status',
    sortable: false,
  },
];
// #endregion

function newDateFormat() {
  const newDate = new Date();
  const day = newDate.getDate().toString().padStart(2, '0');
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const year = newDate.getFullYear();
  return `${year}-${month}-${day}T00:00:00.0`;
}

export default function ManutencaoVeiculoOS({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  // #region INICIALIZADORES STATES, REFS E EFFECTS
  const gridView = useRef();

  const [filtros, setFiltros] = useState({
    flgTipoOficina: 'Todas',
    flgStatus: 'N',
    flgConcluido: false,
    dtCadastroInicio: newDateFormat(),
    dtCadastroFim: newDateFormat(),
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [maisFiltros, setMaisFiltros] = useState(false);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  // #endregion

  // #region FUNÇÕES DE LOAD E SEARCH
  const search = async () => {
    setLoading(true);

    const {
      status,
      message: msg,
      data,
      pagination,
    } = await getFilteredManutencaoVeiculoOSPagined(filtros);

    if (msg) onSetMessage(status, msg);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  useEffect(() => {
    async function load() {
      setLoading(true);
      // await search();
      setLoading(false);
    }
    load();
  }, []);

  const searchOnParams = async (params) => {
    setLoading(true);

    const {
      status,
      message: msg,
      data,
      pagination,
    } = await getFilteredManutencaoVeiculoOSPagined(params);

    if (msg) onSetMessage(status, msg);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  // #endregion

  // #region FUNÇÕES BÁSICAS
  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await searchOnParams(filters);
  };

  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await searchOnParams(filters);
  };

  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await searchOnParams(filters);
  };

  const onOpenMaintenance = async (registryKey = null) => {
    setLoading(true);

    await onOpenPage(
      transaction.noTransacao,
      'OrdemServico_Manutencao/manutencao',
      PageTypes.Maintenence,
      registryKey
    );

    setLoading(false);
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqManutencaoVeiculoOS = sourceRow.find(
      (e) => e.key === 'nrSeqManutencaoVeiculoOS'
    );
    onOpenMaintenance(nrSeqManutencaoVeiculoOS.value);
  };

  const onPrint = async () => {
    setLoading(true);

    const { value } = await printReportManutencaoVeiculoOS(filtros);

    if (value) {
      const downloadLink = document.createElement('a');

      downloadLink.href = `data:application/pdf;base64, ${value}`;

      downloadLink.download = `OS_Impressao.pdf`;

      downloadLink.click();
    }

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onPrintPorOficina = async () => {
    setLoading(true);
    const { value } = await printManutencaoVeiculoOSReportPorOficina(filtros);

    if (value) {
      const downloadLink = document.createElement('a');

      downloadLink.href = `data:application/pdf;base64, ${value}`;

      downloadLink.download = `OS_PorOficina.pdf`;

      downloadLink.click();
    }

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onPrintPorOficinaCompra = async () => {
    setLoading(true);
    const { value } = await printManutencaoVeiculoOSReportPorOficinaCompra(
      filtros
    );

    if (value) {
      const downloadLink = document.createElement('a');

      downloadLink.href = `data:application/pdf;base64, ${value}`;

      downloadLink.download = `OS_PorOficinaCompra.pdf`;

      downloadLink.click();
    }

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onPrintPorPlaca = async () => {
    setLoading(true);
    const { value } = await printManutencaoVeiculoOSReportPorPlaca(filtros);

    if (value) {
      const downloadLink = document.createElement('a');

      downloadLink.href = `data:application/pdf;base64, ${value}`;

      downloadLink.download = `OS_PorPlaca.pdf`;

      downloadLink.click();
    }

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDownloadExcel = async () => {
    setLoading(true);

    const { value } = await excelManutencaoVeiculoOS(filtros);

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'ManutencaoVeiculoOS.xls';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllManutencaoVeiculoOS(
        selecteds.map((row) => row[0].value)
      );

      await search();
      setLoading(false);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    } else {
      setLoading(false);

      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onConcluirOS = async (input) => {
    const response = await closeOS(input);
    return response;
  };

  const onConcluirOrdensServiços = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    const promises = [];
    for (let i = 0; i < selecteds.length; i += 1) {
      const obj = {
        nrSeqManutencaoVeiculoOS: selecteds[i][0].value,
        cdManutencaoVeiculoOS: selecteds[i][1].value,
      };
      promises.push(onConcluirOS(obj));
    }

    const results = await Promise.all(promises);

    const osNaoConcluidas = [];
    for (let i = 0; i < results.length; i += 1) {
      if (results[i].status === ResponseStatus.Error) {
        osNaoConcluidas.push(results[i].value);
      }
    }

    if (osNaoConcluidas.length > 0) {
      onSetMessage(
        ResponseStatus.Error,
        `Erro ao concluir as Ordens de Serviço Manutenção: ${osNaoConcluidas.join(
          ' - '
        )}. Tente encerrar manualmente abrindo a OS.`
      );
    } else {
      onSetMessage(
        ResponseStatus.Success,
        'Ordens de Serviço Manutenção concluídas com sucesso.'
      );
    }

    setLoading(false);

    await searchOnParams(filtros);
  };
  // #endregion

  // #region FILTROS
  const onSearchVeiculo = async (e) => {
    const {
      status,
      message: msg,
      veiculos,
    } = await getVeiculoAutoCompleteResumido({
      placa: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onSearchCondicaoPagamento = async (e) => {
    const {
      status,
      message: msg,
      condicoesPagamento,
    } = await getCondicaoPagamentoAutoComplete({ noCondicaoPagamento: e });
    if (msg) onSetMessage(status, msg);
    return condicoesPagamento;
  };

  const onSearchOficina = async (e) => {
    const { status, message: msg, oficinas } = await getOficinaAutocomplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);
    return oficinas;
  };

  const onSearchUsuario = async (e) => {
    const response = await getUsuarioAutoComplete({
      noLogin: e,
    });
    if (response.message) onSetMessage(response.status, response.message);
    return response.data;
  };

  const onSearchProduto = async (e) => {
    const {
      status,
      message: msg,
      data: produtos,
    } = await getProdutoAutoComplete({
      noSearchField: e,
      nrSeqProduto: filtros.nrSeqProduto,
    });
    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const onSearchProdutoGrupo = async (e) => {
    const {
      status,
      message: msg,
      data: produtoGrupos,
    } = await getProdutoGrupoAutoComplete({
      noSearchField: e,
      nrSeqProdutoGrupo: filtros.nrSeqProdutoGrupo,
    });
    if (msg) onSetMessage(status, msg);
    return produtoGrupos;
  };

  const onSearchProdutoSubGrupo = async (e) => {
    const {
      status,
      message: msg,
      data: produtoSubGrupos,
    } = await searchProdutoSubGrupoAutoComplete({
      noSearchField: e,
      nrSeqProdutoSubGrupo: filtros.nrSeqProdutoSubGrupo,
    });
    if (msg) onSetMessage(status, msg);
    return produtoSubGrupos;
  };

  const tipoOficina = [
    { text: 'Todas', value: 'Todas' },
    { text: 'Internas', value: 'Internas' },
    { text: 'Externas', value: 'Externas' },
  ];

  const status = [
    {
      text: 'Todos',
      value: 'All',
    },
    {
      text: 'Pendente',
      value: 'N',
    },
    {
      text: 'Concluído',
      value: 'S',
    },
  ];
  // #endregion

  return (
    <CSDSelPage
      title='Ordem de Serviço - Manutenção'
      isActive={isActive}
      transaction={transaction}
      loading={loading}
      onSearch={search}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      {/* Botão de Impressão EXCEL */}
      <ToolbarButtons>
        <ToolbarButtons.ButtonList
          onPrint
          icon='caret-down'
          items={[
            {
              text: 'Imprimir por Oficina',
              onClick: () => onPrintPorOficina('Agrupado'),
            },
            {
              text: 'Imprimir por Oficina - Compras',
              onClick: () => onPrintPorOficinaCompra('Agrupado'),
            },
            {
              text: 'Imprimir por Placa',
              onClick: () => onPrintPorPlaca('Agrupado'),
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Excel'
          icon={['far', 'file-excel']}
          onClick={() => onDownloadExcel('Padrao')}
        />
        <ToolbarButtons.Button
          text='Concluir'
          onClick={() => onConcluirOrdensServiços()}
        />
      </ToolbarButtons>

      {/* FILTROS */}
      <div className='row mb-3'>
        <Panel className='mb-2'>
          <Panel.Header title='Filtros' theme={Theme.Primary} />
          <Panel.Body>
            {/* Linha 1 */}
            <div className='row mb-3'>
              {/* Veículo */}
              <div className='col-2'>
                <Autocomplete
                  label='Veículo'
                  searchDataSource={onSearchVeiculo}
                  selectedItem={filtros.veiculo}
                  onSelectItem={(veiculo) => {
                    setFiltros({
                      ...filtros,
                      veiculo,
                      nrSeqVeiculo: veiculo.nrSeqVeiculo,
                    });
                  }}
                  dataSourceTextProperty='noVeiculo'
                />
              </div>

              {/* Datas Cadastro */}
              <div className='col-2'>
                <DatePicker
                  label='Cadastro De:'
                  text={filtros.dtCadastroInicio}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtCadastroInicio) => {
                    setFiltros({ ...filtros, dtCadastroInicio });
                  }}
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Cadastro Até:'
                  text={filtros.dtCadastroFim}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtCadastroFim) => {
                    setFiltros({ ...filtros, dtCadastroFim });
                  }}
                />
              </div>

              {/* Tipo Oficina */}
              <div className='col-2 d-flex flex-column align-items-start flex-wrap-nowrap'>
                <RadioButton
                  label='Oficinas'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.flgTipoOficina}
                  buttons={tipoOficina}
                  onChange={(flgTipoOficina) => {
                    setFiltros({
                      ...filtros,
                      flgTipoOficina,
                    });
                  }}
                />
              </div>

              {/* Status OS */}
              <div className='col-3 d-flex flex-column align-items-start flex-wrap-nowrap'>
                <RadioButton
                  label='Status da OS'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.flgStatus}
                  buttons={status}
                  onChange={(flgStatus) => {
                    let flgConcluidoAux;

                    if (flgStatus === 'S') {
                      flgConcluidoAux = true;
                    } else if (flgStatus === 'N') {
                      flgConcluidoAux = false;
                    } else {
                      flgConcluidoAux = undefined;
                    }

                    setFiltros({
                      ...filtros,
                      flgStatus,
                      flgConcluido: flgConcluidoAux,
                    });
                  }}
                />
              </div>

              {/* Botão Mais/Menos filtros */}
              <div className='col-1 d-flex justify-content-end align-items-end'>
                <Button
                  outline
                  theme={Theme.Primary}
                  template={Button.Templates.Quick}
                  icon={maisFiltros ? 'minus' : 'plus'}
                  text='Filtros'
                  onClick={() => setMaisFiltros((oldValue) => !oldValue)}
                />
              </div>
            </div>

            {/* MAIS FILTROS */}
            {maisFiltros && (
              <>
                {/* Linha 2 */}
                <div className='row mb-3'>
                  {/* Código */}
                  <div className='col-2'>
                    <Textbox
                      maxLength={8}
                      label='Código'
                      text={filtros.cdManutencaoVeiculoOS}
                      mask={MaskTypes.Integer}
                      onChangedValue={(cdManutencaoVeiculoOS) => {
                        if (cdManutencaoVeiculoOS === '') {
                          cdManutencaoVeiculoOS = null;
                        }
                        setFiltros({ ...filtros, cdManutencaoVeiculoOS });
                      }}
                    />
                  </div>

                  {/* Odômetro */}
                  <div className='col-1'>
                    <Textbox
                      label='Odômetro'
                      text={filtros.nrOdometro}
                      mask={MaskTypes.Integer}
                      onChangedValue={(nrOdometro) => {
                        if (nrOdometro === '') {
                          nrOdometro = null;
                        }
                        setFiltros({ ...filtros, nrOdometro });
                      }}
                    />
                  </div>

                  {/* Observação */}
                  <div className='col'>
                    <Textbox
                      label='Observação OS'
                      text={filtros.obsOS}
                      onChangedValue={(obsOS) => {
                        setFiltros({ ...filtros, obsOS });
                      }}
                    />
                  </div>
                </div>

                {/* Linha 3 */}
                <div className='row mb-3'>
                  {/* Oficina */}
                  <div className='col'>
                    <Autocomplete
                      label='Oficina'
                      searchDataSource={onSearchOficina}
                      selectedItem={filtros.pessoaOfi}
                      onSelectItem={(pessoaOfi) => {
                        setFiltros({
                          ...filtros,
                          pessoaOfi,
                          nrSeqPessoaOfi: pessoaOfi.nrSeqPessoaOfi,
                        });
                      }}
                      dataSourceTextProperty='noOficina'
                    />
                  </div>

                  {/* Condição de Pagamento */}
                  <div className='col'>
                    <Autocomplete
                      label='Condição de Pagamento'
                      searchDataSource={onSearchCondicaoPagamento}
                      selectedItem={filtros.condicaoPagamento}
                      onSelectItem={(condicaoPagamento) => {
                        setFiltros({
                          ...filtros,
                          condicaoPagamento,
                          nrSeqCondicaoPagamento:
                            condicaoPagamento.nrSeqCondicaoPagamento,
                        });
                      }}
                      dataSourceTextProperty='noCondicaoPagamento'
                    />
                  </div>

                  {/* Valor Orçamento */}
                  <div className='col-2'>
                    <Textbox
                      label='Valor no Orçamento'
                      text={filtros.vlrOs}
                      mask={MaskTypes.Currency}
                      onChangedValue={(vlrOs) => {
                        if (vlrOs === 0) {
                          vlrOs = null;
                        }
                        setFiltros({ ...filtros, vlrOs });
                      }}
                    />
                  </div>

                  {/* Valor Fechamento */}
                  <div className='col-2'>
                    <Textbox
                      label='Valor no Fechamento'
                      text={filtros.vlrFechamentoOS}
                      mask={MaskTypes.Currency}
                      onChangedValue={(vlrFechamentoOS) => {
                        if (vlrFechamentoOS === 0) {
                          vlrFechamentoOS = null;
                        }
                        setFiltros({ ...filtros, vlrFechamentoOS });
                      }}
                    />
                  </div>
                </div>

                {/* Linha 4 */}
                <div className='row mb-3'>
                  {/* Produto */}
                  <div className='col'>
                    <Autocomplete
                      label='Produtos'
                      searchDataSource={onSearchProduto}
                      selectedItem={filtros.produto}
                      onSelectItem={(produto) => {
                        setFiltros({
                          ...filtros,
                          produto,
                          nrSeqProduto: produto.nrSeqProduto,
                        });
                      }}
                      dataSourceTextProperty='noProduto'
                    />
                  </div>
                  <div className='col'>
                    <Autocomplete
                      label='Produto Grupo'
                      searchDataSource={onSearchProdutoGrupo}
                      selectedItem={filtros.produtoGrupo}
                      onSelectItem={(produtoGrupo) => {
                        setFiltros({
                          ...filtros,
                          produtoGrupo,
                          nrSeqProdutoGrupo: produtoGrupo.nrSeqProdutoGrupo,
                        });
                      }}
                      dataSourceTextProperty='noProdutoGrupo'
                    />
                  </div>
                  <div className='col'>
                    <Autocomplete
                      label='Produto Subgrupo'
                      searchDataSource={onSearchProdutoSubGrupo}
                      selectedItem={filtros.produtoSubGrupo}
                      onSelectItem={(produtoSubGrupo) => {
                        setFiltros({
                          ...filtros,
                          produtoSubGrupo,
                          nrSeqProdutoSubGrupo:
                            produtoSubGrupo.nrSeqProdutoSubGrupo,
                        });
                      }}
                      dataSourceTextProperty='noProdutoSubGrupo'
                    />
                  </div>

                  {/* Nota Fiscal de Entrada */}
                  <div className='col-2'>
                    <Textbox
                      label='Nota Fiscal de Entrada'
                      text={filtros.nf}
                      mask={MaskTypes.Integer}
                      onChangedValue={(nrSeqNf) => {
                        setFiltros({ ...filtros, nrSeqNf });
                      }}
                    />
                  </div>

                  {/* Data de Abertura da Manutenção */}
                  <div className='col-2'>
                    <DatePicker
                      label='Abertura da Manutenção:'
                      text={filtros.dtAberturaManutencaoInicio}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtAberturaManutencaoInicio) => {
                        setFiltros({ ...filtros, dtAberturaManutencaoInicio });
                      }}
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Até:'
                      text={filtros.dtAberturaManutencaoFim}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtAberturaManutencaoFim) => {
                        setFiltros({ ...filtros, dtAberturaManutencaoFim });
                      }}
                    />
                  </div>
                </div>

                {/* Linha 5 - Filtros de Users */}
                <div className='row mb-4'>
                  <div className='col'>
                    <Autocomplete
                      label='Cadastrado Por'
                      searchDataSource={onSearchUsuario}
                      selectedItem={filtros.usuarioCad}
                      onSelectItem={(usuarioCad) => {
                        setFiltros({
                          ...filtros,
                          usuarioCad,
                          nrSeqUsuarioCad: usuarioCad.nrSeqUsuario,
                        });
                      }}
                      dataSourceTextProperty='noLogin'
                    />
                  </div>
                  <div className='col'>
                    <Autocomplete
                      label='Solicitado Por'
                      searchDataSource={onSearchUsuario}
                      selectedItem={filtros.usuarioSol}
                      onSelectItem={(usuarioSol) => {
                        setFiltros({
                          ...filtros,
                          usuarioSol,
                          nrSeqUsuarioSol: usuarioSol.nrSeqUsuario,
                        });
                      }}
                      dataSourceTextProperty='noLogin'
                    />
                  </div>
                  <div className='col'>
                    <Autocomplete
                      label='Autorizado Por'
                      searchDataSource={onSearchUsuario}
                      selectedItem={filtros.usuarioAut}
                      onSelectItem={(usuarioAut) => {
                        setFiltros({
                          ...filtros,
                          usuarioAut,
                          nrSeqUsuarioAut: usuarioAut.nrSeqUsuario,
                        });
                      }}
                      dataSourceTextProperty='noLogin'
                    />
                  </div>
                  <div className='col'>
                    <Autocomplete
                      label='Alterado Por'
                      searchDataSource={onSearchUsuario}
                      selectedItem={filtros.usuarioAlt}
                      onSelectItem={(usuarioAlt) => {
                        setFiltros({
                          ...filtros,
                          usuarioAlt,
                          nrSeqUsuarioAlt: usuarioAlt.nrSeqUsuario,
                        });
                      }}
                      dataSourceTextProperty='noLogin'
                    />
                  </div>
                </div>

                {/* Linha 6 - Filtros por Periodo */}
                <div className='row mb-3'>
                  {/* Data Abertura da OS */}
                  <div className='col'>
                    <div className='card'>
                      <div className='card-header bg-light px-3 pt-2 py-2 '>
                        <p className='my-0'>Data de Abertura da OS</p>
                      </div>
                      <div className='card-body'>
                        <div className='row mb-2'>
                          <DatePicker
                            label='De:'
                            text={filtros.dtAberturaInicio}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtAberturaInicio) => {
                              setFiltros({ ...filtros, dtAberturaInicio });
                            }}
                          />
                        </div>
                        <div className='row mb-2'>
                          <DatePicker
                            label='Até:'
                            text={filtros.dtAberturaFim}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtAberturaFim) => {
                              setFiltros({ ...filtros, dtAberturaFim });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Data de Saida */}
                  <div className='col'>
                    <div className='card' style={{ height: '100%' }}>
                      <div className='card-header bg-light px-3 pt-2 py-2 '>
                        <p className='my-0'>Data de Saída</p>
                      </div>
                      <div className='card-body'>
                        <div className='row mb-2'>
                          <DatePicker
                            label='De:'
                            text={filtros.dtSaidaInicio}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtSaidaInicio) => {
                              setFiltros({ ...filtros, dtSaidaInicio });
                            }}
                          />
                        </div>
                        <div className='row mb-2'>
                          <DatePicker
                            label='Até:'
                            text={filtros.dtSaidaFim}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtSaidaFim) => {
                              setFiltros({ ...filtros, dtSaidaFim });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Data Prevista de Retorno */}
                  <div className='col'>
                    <div className='card'>
                      <div className='card-header bg-light px-3 pt-2 py-2 '>
                        <p className='my-0'>Previsão de Retorno</p>
                      </div>
                      <div className='card-body'>
                        <div className='row mb-2'>
                          <DatePicker
                            label='De:'
                            text={filtros.dtPrevistaRetornoInicio}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtPrevistaRetornoInicio) => {
                              setFiltros({
                                ...filtros,
                                dtPrevistaRetornoInicio,
                              });
                            }}
                          />
                        </div>
                        <div className='row mb-2'>
                          <DatePicker
                            label='Até:'
                            text={filtros.dtPrevistaRetornoFim}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtPrevistaRetornoFim) => {
                              setFiltros({
                                ...filtros,
                                dtPrevistaRetornoFim,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Data de Alteração */}
                  <div className='col'>
                    <div className='card'>
                      <div className='card-header bg-light px-3 pt-2 py-2 '>
                        <p className='my-0'>Data de Alteração</p>
                      </div>
                      <div className='card-body'>
                        <div className='row mb-2'>
                          <DatePicker
                            label='De:'
                            text={filtros.dtAlteracaoInicio}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtAlteracaoInicio) => {
                              setFiltros({
                                ...filtros,
                                dtAlteracaoInicio,
                              });
                            }}
                          />
                        </div>
                        <div className='row mb-2'>
                          <DatePicker
                            label='Até:'
                            text={filtros.dtAlteracaoFim}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtAlteracaoFim) => {
                              setFiltros({
                                ...filtros,
                                dtAlteracaoFim,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Data de Fechamento da OS */}
                  <div className='col'>
                    <div className='card'>
                      <div className='card-header bg-light px-3 pt-2 py-2 '>
                        <p className='my-0'>Data de Fechamento da OS</p>
                      </div>
                      <div className='card-body'>
                        <div className='row mb-2'>
                          <DatePicker
                            label='De:'
                            text={filtros.dtFechamentoInicio}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtFechamentoInicio) => {
                              setFiltros({
                                ...filtros,
                                dtFechamentoInicio,
                              });
                            }}
                          />
                        </div>
                        <div className='row mb-2'>
                          <DatePicker
                            label='Até:'
                            text={filtros.dtFechamentoFim}
                            maxLength={10}
                            mask={MaskTypes.Date}
                            onChange={(dtFechamentoFim) => {
                              setFiltros({
                                ...filtros,
                                dtFechamentoFim,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Panel.Body>
        </Panel>
      </div>

      <div className='row'>
        <GridView
          enableExcelExport
          className='table-striped table-hover table-bordered table-sm'
          canControlVisibility
          ref={gridView}
          dataColumns={columns}
          onColumnSort={onColumnSort}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          onRowDoubleClick={onRowDoubleClick}
          transaction={transaction}
          sumFields
        />
      </div>
    </CSDSelPage>
  );
}
