import React, { useState, useRef, useEffect } from 'react';

import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  Loader,
  Modal,
  Notification,
  GridView,
  Textbox,
} from 'ui/components';

import { MaskTypes } from 'ui/Helpers/masks';

import { getEncargoManualAutoComplete } from 'core/services/TEL/encargoManual';
import { addChargeManual } from 'core/services/TEL/clienteLinha';

export default function ModalAdicionarEncargo({ recurso, show, onClose }) {
  const gridViewAdicionarEncargo = useRef();

  const [data, setData] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const columnsLinhas = [
    { key: 'nrSeqClienteLinha', type: GridView.ColumnTypes.Checkbox },

    {
      key: 'nroFoneCompleto',
      title: 'Nr. Linha',
    },
    {
      key: 'noChipFlgStatus',
      title: 'Status',
    },
    {
      key: 'noOperadora',
      title: 'Operadora',
    },
    {
      key: 'noChipFlgSituacao',
      title: 'Situação',
    },
    {
      key: 'noEncargosVinculados',
      title: 'Encargo Manual',
    },
  ];
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  const onSearchEncargoManual = async (e) => {
    const {
      status,
      message: msg,
      encargos,
    } = await getEncargoManualAutoComplete({
      noEncargoManual: e,
    });
    onSetMessage(status, msg);
    return encargos;
  };

  useEffect(() => {
    (async function load() {
      if (recurso.length > 0)
        gridViewAdicionarEncargo.current.setDataSource(recurso);
    })();
  }, [recurso]);

  const onSend = async () => {
    setLoading(true);
    const nrSeqClientesLinhas = recurso.map(
      (column) => column.nrSeqClienteLinha
    );

    const recursos = {
      nrSeqClientesLinhas,
      nrSeqEncargoManual: data.nrSeqEncargoManual,
      dtIniEncargoManual: data.dtIniEncargoManual,
      dtFimEncargoManual: data.dtFimEncargoManual,
      vlrEncargo: data.vlrEncargo,
    };

    const { status, message: msg } = await addChargeManual(recursos);
    setLoading(false);
    onSetMessage(status, msg);
  };

  return (
    <Modal
      show={show}
      title='Adicionar Encargo Manual'
      onClose={onClose}
      size={BootstrapSizes.Large}
      icon={['far', 'comment-alt']}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='row'>
          <div className='col-sm-4'>
            <Autocomplete
              label='Encargo Manual'
              searchDataSource={onSearchEncargoManual}
              selectedItem={data.encargoManual}
              onSelectItem={(encargoManual) =>
                setData({
                  ...data,
                  nrSeqEncargoManual: encargoManual.nrSeqEncargoManual,
                  encargoManual,
                })
              }
              dataSourceTextProperty='noEncargoManual'
            />
          </div>

          <div className='col-sm-3'>
            <Textbox
              label='Data Inicio Vigência'
              text={data.dtIniEncargoManual}
              maxLength={10}
              mask={MaskTypes.Date}
              onChangedValue={(dtIniEncargoManual) =>
                setData({
                  ...data,
                  dtIniEncargoManual,
                })
              }
            />
          </div>

          <div className='col-sm-3'>
            <Textbox
              label='Data Fim Vigência'
              text={data.dtFimEncargoManual}
              maxLength={10}
              mask={MaskTypes.Date}
              onChangedValue={(dtFimEncargoManual) =>
                setData({
                  ...data,
                  dtFimEncargoManual,
                })
              }
            />
          </div>

          <div className='col-sm-2'>
            <Textbox
              label='Valor'
              text={data.vlrEncargo}
              mask={MaskTypes.Decimal}
              onChangedValue={(vlrEncargo) =>
                setData({
                  ...data,
                  vlrEncargo,
                })
              }
            />
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col'>
            <GridView
              ref={gridViewAdicionarEncargo}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnsLinhas}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Salvar'
          theme={Theme.Success}
          icon='sms'
          onClick={onSend}
        />
        <Button
          text='Sair'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            setData({});
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
