import api from '../api';

const baseURL = `/FIN/ExtratoFinanceiro`;

/* export const getExtratoFinanceiroPagined = async (data) => {
  const response = await api.post(`${baseURL}/SearchPagined`, data);
  return response.data ?? {};
}; */

export const getExtratoFinanceiroPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const deleteAllExtratoFinanceiro = async (data) => {
  const response = await api.post(`${baseURL}/DeleteAll`, data);
  return response.data ?? {};
};

export const conciliarExtratoFinanceiro = async (data) => {
  const response = await api.post(`${baseURL}/Conciliar`, data);
  return response.data ?? {};
};

export const Print = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};
