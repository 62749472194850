import React, { useState, useRef, useEffect } from 'react';
import {
  ResponseStatus,
  BootstrapSizes,
  Theme,
  JustifyContent,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  Autocomplete,
  Button,
  GridView,
  Loader,
  Modal,
  Notification,
  Panel,
  Switch,
  Textbox,
} from 'ui/components';
import { getTipoServicoAutoComplete } from 'core/services/GProj/tipoServico';
import { getContratoServicoFullAutoComplete } from 'core/services/GProj/contratoServico';
import NfsItem from 'core/models/VEN/nfsItem';
import { Nfs, NfsImpostos } from 'core/models/VEN';
import { getProdutoServicoAutoComplete } from 'core/services/VEN/produtoServico';
import {
  calculateTaxes,
  recalculateTaxes,
  saveNfs,
  transmitNfs,
  postDownloadNFSFatura,
} from 'core/services/VEN/nfs';

const ModalGerarNFS = ({
  show,
  param,
  onSuccessFaturarContrato,
  onCloseClick,
}) => {
  const gridViewServicos = useRef(null);
  const [state, setParams] = useState({ loading: true });
  const [dataNFS, setDataNFS] = useState(
    new Nfs({ vlrLiquido: 0, impostos: new NfsImpostos() })
  );
  const [dataItemServico, setDataNFSItemServico] = useState(
    new NfsItem({ vlrDesconto: 0 })
  );
  const [exibirBotaoTransmitir, setExibirBotaoTransmitir] = useState();

  useEffect(async () => {
    if (show) {
      setParams({
        ...state,
        loading: true,
        nrSeqPessoaCli: param.nrSeqPessoaCli,
        nrSeqEmpresa: param.nrSeqEmpresa,
      });

      setDataNFS({
        ...dataNFS,
        nrSeqPessoaCli: param.nrSeqPessoaCli,
        nrSeqEmpresa: param.nrSeqEmpresa,
        nrSeqFatura: param.nrSeqFatura,
        faturas: {
          ...param.faturamento,
          vlrParcela: param.faturamento[0].vlrParcelaFaturamento,
        },
        impostos: new NfsImpostos({
          vlrBaseCalIssqn: 0,
          aliqIssqn: 0,
          vlrIssqn: 0,
          vlrOutrasRetencoes: 0,
          vlrBaseCalPis: 0,
          aliqPis: 0,
          vlrPis: 0,
          vlrBaseCalCofins: 0,
          aliqCofins: 0,
          vlrCofins: 0,
          vlrBaseCalCsll: 0,
          aliqCsllRetido: 0,
          vlrCsllRetido: 0,
          aliqCsllContador: 0,
          vlrCsllContador: 0,
          vlrBaseCalIRPJ: 0,
          aliqIrpjRetido: 0,
          vlrIrpjRetido: 0,
          aliqIrpjContador: 0,
          vlrIrpjContador: 0,
          vlrBaseCalINSS: 0,
          aliqInss: 0,
          vlrInss: 0,
          vlrBaseCalDeson: 0,
          aliqDesonContador: 0,
          vlrDesonContador: 0,
        }),
      });

      setParams({
        ...state,
        loading: false,
        nrSeqPessoaCli: param.nrSeqPessoaCli,
        nrSeqEmpresa: param.nrSeqEmpresa,
      });
    } else {
      onCloseClick();
    }
  }, [show]);

  const onSearchTipoServico = async (e) => {
    const {
      status,
      message: msg,
      data: servicos,
    } = await getTipoServicoAutoComplete({
      noTipoServico: e,
      FlgGeraNfs: true,
    });
    if (msg) onSuccessFaturarContrato(status, msg);
    return servicos;
  };

  const setContratoServico = async (contratoServicoNFS) => {
    const itemServico = {
      noDescricao: contratoServicoNFS.noContratoServico,
      quantidade: contratoServicoNFS.vlrBase,
      valorUnitario: contratoServicoNFS.vlrUnitario,
      vlrTotal:
        contratoServicoNFS.vlrBase *
        (contratoServicoNFS.vlrUnitario ? contratoServicoNFS.vlrUnitario : 0),
    };

    setDataNFSItemServico({
      ...itemServico,
      contratoServicoNFS,
      tipoServico: contratoServicoNFS.tipoServico,
      nrSeqTipoServico: contratoServicoNFS.nrSeqTipoServico,
      produtoServico: contratoServicoNFS.produtoServico,
      nrSeqProdutoServico: contratoServicoNFS.nrSeqProdutoServico,
      condicaoPagamento: contratoServicoNFS.condicaoPagamento,
      nrSeqCondicaoPagamento: contratoServicoNFS.nrSeqCondicaoPagamento,
      nrSeqContratoServico: contratoServicoNFS.nrSeqContratoServico,
    });

    setDataNFS({
      ...dataNFS,
      condicaoPagamento: contratoServicoNFS.condicaoPagamento,
      nrSeqCondicaoPagamento: contratoServicoNFS.nrSeqCondicaoPagamento,
    });
  };

  const onSearchProdutoServico = async (e) => {
    const {
      status,
      message: msg,
      data: servicos,
    } = await getProdutoServicoAutoComplete({
      noProdutoServico: e,
    });
    if (msg) onSuccessFaturarContrato(status, msg);
    return servicos;
  };

  const onSearchContratoServico = async (e) => {
    const {
      status,
      message: msg,
      data: contratos,
    } = await getContratoServicoFullAutoComplete({
      noContratoServico: e,
      flgAtivo: true,
      nrSeqPessoaCli: state.nrSeqPessoaCli,
    });
    if (msg) onSuccessFaturarContrato(status, msg);
    return contratos;
  };

  const onCalculateTaxes = async (nfs) => {
    console.log(nfs);
    const obj1 = new Nfs({
      itens: nfs.itens,
      vlrLiquido: 0,
      impostos: new NfsImpostos(),
      nrSeqPessoaCli: dataNFS.nrSeqPessoaCli,
      nrSeqEmpresa: dataNFS.nrSeqEmpresa,
    });
    const { status, message: msg, value: nf } = await calculateTaxes(obj1);
    if (status === ResponseStatus.Success) {
      setDataNFS(nf);
    } else {
      onSuccessFaturarContrato(status, msg);
    }
  };

  const onRecalculateTaxes = async (nfs) => {
    dataNFS.nrSeqPessoaCli = param.nrSeqPessoaCli;
    dataNFS.nrSeqEmpresa = param.nrSeqEmpresa;
    const { status, message: msg, value: nf } = await recalculateTaxes(
      nfs ?? dataNFS
    );
    if (status === ResponseStatus.Success) {
      setDataNFS(nf);
    } else {
      onSuccessFaturarContrato(status, msg);
    }
  };

  const onClickRemoveServico = async (selectedValue, datasource) => {
    const dataServicos = datasource.filter(
      (servico) => servico !== selectedValue
    );

    const dataServico = datasource.filter(
      (servico) => servico === selectedValue
    );

    dataServico[0].status = GridView.EnumStatus.Remover;
    dataServicos.push(dataServico[0]);

    if (gridViewServicos && gridViewServicos.current)
      gridViewServicos.current.setDataSource(dataServicos);
  };

  const onClickEditServico = async (selectedValue, datasource) => {
    const dataServicos = datasource.filter(
      (servico) => servico.nrSeqNfsItem !== selectedValue.nrSeqNfsItem
    );

    const dataServico = datasource.filter(
      (servico) => servico.nrSeqNfsItem === selectedValue.nrSeqNfsItem
    );
    dataServico[0].status = GridView.EnumStatus.Alterar;
    setDataNFSItemServico(dataServico[0]);

    if (gridViewServicos && gridViewServicos.current)
      gridViewServicos.current.setDataSource(dataServicos);
  };

  const onClickAddServico = async () => {
    if (!state.nrSeqEmpresa) {
      onSuccessFaturarContrato(
        Theme.Danger,
        'Necessário informar a empresa para adicionar o serviço.'
      );
      return;
    }

    if (!state.nrSeqPessoaCli) {
      onSuccessFaturarContrato(
        Theme.Danger,
        'Necessário informar o cliente para adicionar o serviço.'
      );
      return;
    }

    if (
      dataItemServico.nrSeqTipoServico <= 0 ||
      dataItemServico.nrSeqTipoServico === undefined
    ) {
      onSuccessFaturarContrato(
        Theme.Danger,
        'Necessário informar o tipo de serviço.'
      );
    }
    if (
      dataItemServico.nrSeqProdutoServico <= 0 ||
      dataItemServico.nrSeqProdutoServico === undefined
    ) {
      onSuccessFaturarContrato(
        Theme.Danger,
        'Necessário informar o produto serviço.'
      );
    } else if (dataItemServico.quantidade <= 0 || !dataItemServico.quantidade) {
      onSuccessFaturarContrato(
        Theme.Danger,
        'Necessário informar a quantidade.'
      );
    } else if (!dataItemServico.valorUnitario) {
      onSuccessFaturarContrato(
        Theme.Danger,
        'Necessário informar o valor unitário.'
      );
    } else {
      let dataServicos = gridViewServicos.current.getDataSource();
      if (!dataServicos) dataServicos = [];

      if (!dataItemServico.vlrDesconto) dataItemServico.vlrDesconto = 0;
      if (!dataItemServico.status)
        dataItemServico.status = GridView.EnumStatus.Inserir;

      dataServicos.push(dataItemServico);

      if (gridViewServicos && gridViewServicos.current) {
        gridViewServicos.current.setDataSource(dataServicos);

        setDataNFS({
          ...dataNFS,
          itens: dataServicos,
        });

        setDataNFSItemServico(
          new NfsItem({
            quantidade: 0,
            valorUnitario: 0,
            vlrTotal: 0,
            vlrDesconto: 0,
          })
        );
      }

      await onCalculateTaxes({
        ...dataNFS,
        itens: dataServicos,
      });
    }
  };

  const columnsServicos = [
    {
      key: 'nrSeqNfsItem',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },

    { key: 'produtoServico.noProdutoServico', title: 'Serviço' },
    { key: 'tipoServico.noTipoServico', title: 'Tipo Serviço' },
    { key: 'noDescricao', title: 'Descrição' },
    {
      key: 'quantidade',
      title: 'Quantidade',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'valorUnitario',
      title: 'Valor Unitário',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotal',
      title: 'Valor Total',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrDesconto',
      title: 'Valor Desconto',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'nrSeqNfsItem',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditServico(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqNfsItem',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveServico(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onGerar = async () => {
    setParams({
      ...state,
      loading: true,
    });
    param.faturamento.forEach((item) => {
      item.vlrParcela = item.vlrParcelaFaturamento;
      item.nrSeqFatura = param.nrSeqFatura;
    });
    dataNFS.faturas = param.faturamento;
    dataNFS.itens = gridViewServicos.current.getDataSource();
    dataNFS.vlrParcela = param.faturamento[0].vlrParcelaFaturamento;
    dataNFS.flgContratoServico = true;
    dataNFS.flgIssqnCidadeOrigem = true;
    const { status, message: msg, data: nfs } = await saveNfs(dataNFS);

    setParams({
      ...state,
      message: msg,
      loading: false,
      messageTheme:
        status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    if (status === ResponseStatus.Success) {
      setDataNFS(nfs);
      setExibirBotaoTransmitir(true);
    }
  };
  const downloadBoleto = (value) => {
    const linkSource = `data:application/pdf;base64, ${value}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = 'Boleto.pdf';
    downloadLink.click();
  };

  const downloadNFS = (value) => {
    const linkSource = `data:application/pdf;base64, ${value}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = 'NotaFiscal.pdf';
    downloadLink.click();
  };

  const downloadXML = (value) => {
    const linkSource = `data:application/pdf;base64, ${value}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = 'xml.zip';
    downloadLink.click();
  };
  const onTransmitir = async () => {
    setParams({
      ...state,
      loading: true,
    });

    const { status, message: msg } = await transmitNfs(dataNFS);

    if (status === ResponseStatus.Error) {
      setParams({
        ...state,
        message: msg,
        loading: false,
        messageTheme:
          status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    } else {
      setParams({
        ...state,
        message: 'Transmissão da NFS realizada com sucesso.',
        loading: false,
        messageTheme: Theme.Success,
      });
      const { data: ret } = await postDownloadNFSFatura(dataNFS);
      if (ret) {
        downloadBoleto(ret.boletoBase54);
        downloadNFS(ret.nfsBase64);
        downloadXML(ret.nfsXML64);
      }
      setParams({
        ...state,
        loading: false,
      });
    }
  };

  return (
    <Modal
      show
      size={BootstrapSizes.ExtraLarge}
      title='Nota Fiscal de Serviço'
      onClose={onCloseClick}
    >
      <Modal.Body>
        <Loader loading={state.loading} />
        {state.message && (
          <div className='mb-3'>
            <Notification
              message={state.message}
              theme={state.messageTheme}
              onClose={() => setParams({ ...state, message: undefined })}
            />
          </div>
        )}

        <div className='row mb-3'>
          <div className='col'>
            <Panel>
              <Panel.Header
                title='Serviços'
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col'>
                    <Autocomplete
                      label='Contrato'
                      searchDataSource={onSearchContratoServico}
                      selectedItem={dataItemServico.contratoServicoNFS}
                      onSelectItem={(contratoServicoNFS) => {
                        setContratoServico(contratoServicoNFS);
                      }}
                      dataSourceTextProperty='noContratoServico'
                    />
                  </div>
                  <div className='col'>
                    <Autocomplete
                      label='Tipo de Serviço'
                      searchDataSource={onSearchTipoServico}
                      selectedItem={dataItemServico.tipoServico}
                      onSelectItem={(tipoServico) => {
                        setDataNFSItemServico({
                          ...dataItemServico,
                          tipoServico,
                          nrSeqTipoServico: tipoServico.nrSeqTipoServico,
                          nrSeqProdutoServico: tipoServico.nrSeqProdutoServico,
                          produtoServico: tipoServico.produtoServico,
                        });

                        setDataNFS({
                          ...dataNFS,
                          flgIssqnCidadeOrigem:
                            tipoServico.flgIssqnCidadeOrigem,
                        });
                      }}
                      dataSourceTextProperty='noTipoServico'
                    />
                  </div>
                  <div className='col'>
                    <Autocomplete
                      label='Serviço'
                      searchDataSource={onSearchProdutoServico}
                      selectedItem={dataItemServico.produtoServico}
                      onSelectItem={(produtoServico) => {
                        setDataNFSItemServico({
                          ...dataItemServico,
                          produtoServico,

                          nrSeqProdutoServico:
                            produtoServico.nrSeqProdutoServico,
                        });
                      }}
                      dataSourceTextProperty='noProdutoServico'
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col'>
                    <Textbox
                      label='Quantidade'
                      text={dataItemServico.quantidade}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(quantidade) =>
                        setDataNFSItemServico({
                          ...dataItemServico,
                          quantidade,
                          vlrTotal:
                            quantidade *
                            (dataItemServico.valorUnitario
                              ? dataItemServico.valorUnitario
                              : 0),
                        })
                      }
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='Valor Unitário'
                      text={dataItemServico.valorUnitario}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(valorUnitario) =>
                        setDataNFSItemServico({
                          ...dataItemServico,
                          valorUnitario,
                          vlrTotal:
                            valorUnitario *
                            (dataItemServico.quantidade
                              ? dataItemServico.quantidade
                              : 0),
                        })
                      }
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='Valor Desconto'
                      text={dataItemServico.vlrDesconto}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(vlrDesconto) =>
                        setDataNFSItemServico({
                          ...dataItemServico,
                          vlrDesconto,
                        })
                      }
                    />
                  </div>
                  <div className='col-6'>
                    <Textbox
                      label='Descrição do Serviço'
                      text={dataItemServico.noDescricao}
                      onChangedValue={(noDescricao) =>
                        setDataNFSItemServico({
                          ...dataItemServico,
                          noDescricao,
                        })
                      }
                    />
                  </div>
                </div>

                <div className='row mb-3'>
                  <div className='col'>
                    <Button
                      outline
                      theme={Theme.Success}
                      template={Button.Templates.Button}
                      text='Adicionar Serviço'
                      className='width100'
                      onClick={onClickAddServico}
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-12'>
                    <GridView
                      ref={gridViewServicos}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columnsServicos}
                      offlineData
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  </div>
                </div>

                <div className='row mb-3'>
                  <div className='col-2'>
                    <Textbox
                      label='Valor Líquido'
                      text={dataNFS.vlrLiquido}
                      mask={MaskTypes.DecimalCustom}
                      readOnly
                      onChangedValue={(vlrLiquido) =>
                        setDataNFS({ ...dataNFS, vlrLiquido })
                      }
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <Panel>
              <Panel.Header
                icon={['fa', 'list']}
                title='Tributos'
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-1'>
                    <Switch
                      formControl
                      label='Issqn Retido'
                      checked={dataNFS.impostos?.flgIssqnRetido}
                      onChange={(flgIssqnRetido) => {
                        const nfs = dataNFS;
                        nfs.impostos.flgIssqnRetido = flgIssqnRetido;
                        onRecalculateTaxes(nfs);
                      }}
                    />
                  </div>
                  <div className='col-1'>
                    <Switch
                      formControl
                      label='Issqn Reti. Manu.'
                      checked={dataNFS.impostos?.flgIssqnRetidoManual}
                      onChange={(flgIssqnRetidoManual) => {
                        const nfs = dataNFS;
                        nfs.impostos.flgIssqnRetidoManual = flgIssqnRetidoManual;
                        onRecalculateTaxes(nfs);
                      }}
                    />
                  </div>

                  <div className='col-3'>
                    <Textbox
                      label='Base Cálculo do ISSQN'
                      text={dataNFS.impostos?.vlrBaseCalIssqn}
                      mask={MaskTypes.DecimalCustom}
                      readOnly
                      disabledTabIndex
                    />
                  </div>

                  <div className='col-3'>
                    <Textbox
                      label='Alíquota ISSQN'
                      text={dataNFS.impostos?.aliqIssqn}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(aliqIssqn) => {
                        const { impostos } = dataNFS;
                        setDataNFS({
                          ...dataNFS,
                          impostos: { ...impostos, aliqIssqn },
                        });
                      }}
                      onBlur={onRecalculateTaxes}
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Valor ISSQN'
                      disabledTabIndex
                      text={dataNFS.impostos?.vlrIssqn}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(vlrIssqn) => {
                        const { impostos } = dataNFS;
                        setDataNFS({
                          ...dataNFS,
                          impostos: { ...impostos, vlrIssqn },
                        });
                      }}
                      readOnly
                    />
                  </div>

                  <div className='col-2'>
                    <Textbox
                      label='Outras Retenções'
                      disabledTabIndex
                      text={dataNFS.impostos?.vlrOutrasRetencoes}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(vlrOutrasRetencoes) => {
                        const { impostos } = dataNFS;
                        setDataNFS({
                          ...dataNFS,
                          impostos: { ...impostos, vlrOutrasRetencoes },
                        });
                      }}
                      readOnly
                    />
                  </div>
                </div>

                <div className='row mb-3'>
                  <div className='col-2'>
                    <Switch
                      formControl
                      label='Pis Retido:'
                      checked={dataNFS.impostos?.flgPisRetido}
                      onChange={(flgPisRetido) => {
                        const nfs = dataNFS;
                        nfs.impostos.flgPisRetido = flgPisRetido;
                        onRecalculateTaxes(nfs);
                      }}
                    />
                  </div>
                  <div className='col-3'>
                    <Textbox
                      label='Base Cálculo do Pis'
                      text={dataNFS.impostos?.vlrBaseCalPis}
                      mask={MaskTypes.DecimalCustom}
                      readOnly
                      disabledTabIndex
                    />
                  </div>

                  <div className='col-3'>
                    <Textbox
                      label='Alíquota Pis'
                      text={dataNFS.impostos?.aliqPis}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(aliqPis) => {
                        const { impostos } = dataNFS;
                        setDataNFS({
                          ...dataNFS,
                          impostos: { ...impostos, aliqPis },
                        });
                      }}
                      onBlur={onRecalculateTaxes}
                    />
                  </div>

                  <div className='col-3'>
                    <Textbox
                      label='Valor Pis'
                      text={dataNFS.impostos?.vlrPis}
                      mask={MaskTypes.DecimalCustom}
                      readOnly
                      disabledTabIndex
                    />
                  </div>
                </div>

                <div className='row mb-3'>
                  <div className='col-2'>
                    <Switch
                      formControl
                      label='Cofins Retido:'
                      checked={dataNFS.impostos?.flgCofinsRetido}
                      onChange={(flgCofinsRetido) => {
                        const nfs = dataNFS;
                        nfs.impostos.flgCofinsRetido = flgCofinsRetido;
                        onRecalculateTaxes(nfs);
                      }}
                    />
                  </div>
                  <div className='col-3'>
                    <Textbox
                      label='Base Cálculo do Cofins'
                      text={dataNFS.impostos?.vlrBaseCalCofins}
                      mask={MaskTypes.DecimalCustom}
                      readOnly
                      disabledTabIndex
                    />
                  </div>

                  <div className='col-3'>
                    <Textbox
                      label='Alíquota Cofins'
                      text={dataNFS.impostos?.aliqCofins}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(aliqCofins) => {
                        const { impostos } = dataNFS;
                        setDataNFS({
                          ...dataNFS,
                          impostos: { ...impostos, aliqCofins },
                        });
                      }}
                      onBlur={onRecalculateTaxes}
                    />
                  </div>
                  <div className='col-3'>
                    <Textbox
                      label='Valor Cofins'
                      text={dataNFS.impostos?.vlrCofins}
                      mask={MaskTypes.DecimalCustom}
                      readOnly
                      disabledTabIndex
                    />
                  </div>
                </div>

                <div className='row mb-3'>
                  <div className='col-2'>
                    <Switch
                      formControl
                      label='Csll Retido:'
                      checked={dataNFS.impostos?.flgCsllRetido}
                      onChange={(flgCsllRetido) => {
                        const nfs = dataNFS;
                        nfs.impostos.flgCsllRetido = flgCsllRetido;
                        onRecalculateTaxes(nfs);
                      }}
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Base Cálculo do Csll'
                      text={dataNFS.impostos?.vlrBaseCalCsll}
                      mask={MaskTypes.DecimalCustom}
                      readOnly
                      disabledTabIndex
                    />
                  </div>

                  <div className='col-2'>
                    <Textbox
                      label='Aliq Csll Retido'
                      text={dataNFS.impostos?.aliqCsllRetido}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(aliqCsllRetido) => {
                        const { impostos } = dataNFS;
                        setDataNFS({
                          ...dataNFS,
                          impostos: { ...impostos, aliqCsllRetido },
                        });
                      }}
                      onBlur={onRecalculateTaxes}
                    />
                  </div>

                  <div className='col-2'>
                    <Textbox
                      label='Vlr Csll Retido'
                      text={dataNFS.impostos?.vlrCsllRetido}
                      mask={MaskTypes.DecimalCustom}
                      readOnly
                      disabledTabIndex
                    />
                  </div>

                  <div className='col-2'>
                    <Textbox
                      label='Aliq Csll Contador'
                      text={dataNFS.impostos?.aliqCsllContador}
                      mask={MaskTypes.DecimalCustom}
                      readOnly={!dataNFS.impostos?.flgCsllRetido}
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Vlr Csll Contador'
                      text={dataNFS.impostos?.vlrCsllContador}
                      mask={MaskTypes.DecimalCustom}
                      readOnly
                      disabledTabIndex
                    />
                  </div>
                </div>

                <div className='row mb-3'>
                  <div className='col-2 offset-2'>
                    <Textbox
                      label='Base Cálculo do IRPJ'
                      text={dataNFS.impostos?.vlrBaseCalIRPJ}
                      mask={MaskTypes.DecimalCustom}
                      readOnly
                      disabledTabIndex
                    />
                  </div>

                  <div className='col-2'>
                    <Textbox
                      label='Aliq IRPJ Retido'
                      text={dataNFS.impostos?.aliqIrpjRetido}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(aliqIrpjRetido) => {
                        const { impostos } = dataNFS;
                        setDataNFS({
                          ...dataNFS,
                          impostos: { ...impostos, aliqIrpjRetido },
                        });
                      }}
                      onBlur={onRecalculateTaxes}
                    />
                  </div>

                  <div className='col-2'>
                    <Textbox
                      label='Vlr IRPJ Retido'
                      text={dataNFS.impostos?.vlrIrpjRetido}
                      mask={MaskTypes.DecimalCustom}
                      readOnly
                      disabledTabIndex
                    />
                  </div>

                  <div className='col-2'>
                    <Textbox
                      label='Aliq IRPJ Contador'
                      text={dataNFS.impostos?.aliqIrpjContador}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(aliqIrpjContador) => {
                        const { impostos } = dataNFS;
                        setDataNFS({
                          ...dataNFS,
                          impostos: { ...impostos, aliqIrpjContador },
                        });
                      }}
                      onBlur={onRecalculateTaxes}
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Vlr IRPJ Contador'
                      text={dataNFS.impostos?.vlrIrpjContador}
                      mask={MaskTypes.DecimalCustom}
                      readOnly
                      disabledTabIndex
                    />
                  </div>
                </div>

                <div className='row mb-3'>
                  <div className='col-3 offset-2'>
                    <Textbox
                      label='Base Cálculo do INSS'
                      text={dataNFS.impostos?.vlrBaseCalINSS}
                      mask={MaskTypes.DecimalCustom}
                      readOnly
                      disabledTabIndex
                    />
                  </div>

                  <div className='col-3'>
                    <Textbox
                      label='Alíquota Inss'
                      text={dataNFS.impostos?.aliqInss}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(aliqInss) => {
                        const { impostos } = dataNFS;
                        setDataNFS({
                          ...dataNFS,
                          impostos: { ...impostos, aliqInss },
                        });
                      }}
                      onBlur={onRecalculateTaxes}
                    />
                  </div>

                  <div className='col-3'>
                    <Textbox
                      label='Valor Inss'
                      text={dataNFS.impostos?.vlrInss}
                      mask={MaskTypes.DecimalCustom}
                      readOnly
                      disabledTabIndex
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-3 offset-2'>
                    <Textbox
                      label='Base Cálculo do Deson'
                      text={dataNFS.impostos?.vlrBaseCalDeson}
                      mask={MaskTypes.DecimalCustom}
                      readOnly
                      disabledTabIndex
                    />
                  </div>

                  <div className='col-3'>
                    <Textbox
                      label='Aliq IR Deson Contador'
                      text={dataNFS.impostos?.aliqDesonContador}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(aliqDesonContador) => {
                        const { impostos } = dataNFS;
                        setDataNFS({
                          ...dataNFS,
                          impostos: { ...impostos, aliqDesonContador },
                        });
                      }}
                      onBlur={onRecalculateTaxes}
                    />
                  </div>

                  <div className='col-3'>
                    <Textbox
                      label='Vlr Deson Contador'
                      text={dataNFS.impostos?.vlrDesonContador}
                      mask={MaskTypes.DecimalCustom}
                      readOnly
                      disabledTabIndex
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!exibirBotaoTransmitir && (
          <Button
            icon={['far', 'save']}
            text='Gerar'
            theme={Theme.Success}
            onClick={onGerar}
          />
        )}
        {exibirBotaoTransmitir && (
          <Button
            icon={['fa', 'share']}
            text='Transmitir'
            theme={Theme.Primary}
            onClick={onTransmitir}
          />
        )}
        <Button
          icon='ban'
          text='Cancelar'
          theme={Theme.Danger}
          onClick={onCloseClick}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default ModalGerarNFS;
