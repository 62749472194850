import TipoCarroceriaMdfe from './tipoCarroceriaMdfe';

export default class TipoCarroceria {
  constructor(jsonObject = {}) {
    this.nrSeqTipoCarroceria = jsonObject.nrSeqTipoCarroceria;
    this.noTipoCarroceria = jsonObject.noTipoCarroceria;
    this.cdTipoCarroceria = jsonObject.cdTipoCarroceria;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.tpCarMdfe = jsonObject.tpCarMdfe;
    this.tipoCarroceriaMdfe = new TipoCarroceriaMdfe(
      jsonObject.tipoCarroceriaMdfe ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
