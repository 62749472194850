import React, { useState, useRef } from 'react';
import {
  CSDSelPage,
  GridView,
  Textbox,
  Autocomplete,
  DatePicker,
  RadioButton,
  Panel,
  Switch,
} from 'ui/components';
import { PageTypes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import {
  ColumnTypes,
  Theme,
  ResponseStatus,
  ColumnDataTypes,
  BootstrapSizes,
} from 'ui/Helpers/enums.ts';

import {
  getFuncionarioPagined,
  printFuncionarios,
  deleteAllFuncionario,
} from 'core/services/SEG/funcionario';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';

import { MaskTypes } from 'ui/Helpers/masks';

export interface IFuncionarioFilters {
  noPessoa?: string;
  nrSeqEmpresa?: number;
  SortBy?: number;
  totalByPage?: number;
  page?: any;
}

export default function Funcionario({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}: Page) {
  const gridView = useRef<any>();

  const [filtros, setFiltros] = useState<IFuncionarioFilters>({
    noStatus: 'T',
    flgExibeProfissao: true,
    flgExibePaiMae: true,
    flgExibeEndereco: true,
    flgExibeCidade: true,
    flgExibeEstado: true,
    flgExibeDemissao: true,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const columns: Array<GridviewColumns> = [
    { key: 'nrSeqPessoaFun', type: ColumnTypes.Checkbox },
    { key: 'cdFuncionario', title: 'Código' },
    { key: 'noPessoa', title: 'Nome', sortKey: 'noPessoa' },
    { key: 'pessoaFisica.nrRg', title: 'RG', sortKey: 'nrRG' },
    { key: 'pessoaFisica.nrCpf', title: 'CPF', sortKey: 'nrCpf' },
    {
      key: 'pessoaFisica.profissao.noProfissao',
      title: 'Profissão',
      sortKey: 'noProfissao',
    },
    {
      key: 'dtAdmissao',
      title: 'Admissão',
      format: ColumnDataTypes.Date,
    },
    { key: 'pessoaFisica.noPai', title: 'Pai', sortKey: 'noPai' },
    { key: 'pessoaFisica.noMae', title: 'Mãe', sortKey: 'noMae' },
    { key: 'endereco.noRua', title: 'Endereço', sortKey: 'noRua' },
    { key: 'endereco.cidade.noCidade', title: 'Cidade', sortKey: 'noCidade' },
    {
      key: 'endereco.cidade.estado.noEstado',
      title: 'Estado',
      sortKey: 'noEstado',
    },
    { key: 'dtDemissao', title: 'Demissão', format: ColumnDataTypes.Date },
  ];

  const search = async (params = null): Promise<void> => {
    setLoading(true);

    const { data, pagination } = await getFuncionarioPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onOpenMaintenance = (registryKey = null): void => {
    onOpenPage(
      transaction.noTransacao,
      'Funcionario/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const StatusFuncionario = [
    { text: 'Ativos', value: 'A' },
    { text: 'Demitidos', value: 'D' },
    { text: 'Todos', value: 'T' },
  ];

  const onColumnSort = async (SortBy: number): Promise<void> => {
    setFiltros({ ...filtros, SortBy });
    await search();
  };

  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    setFiltros({ ...filtros, totalByPage });
    await search();
  };

  const onPageChange = async (page: any): Promise<void> => {
    setFiltros({ ...filtros, page });
    await search();
  };

  const onRowDoubleClick = (sourceRow: any): void => {
    const nrSeqPessoaFun = sourceRow.find(
      (e: any) => e.key === 'nrSeqPessoaFun'
    );

    onOpenMaintenance(nrSeqPessoaFun.value);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllFuncionario(
        selecteds.map((row: any) => row[0].value)
      );
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      await search();
    } else {
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    return empresas;
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printFuncionarios(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Funcionário'
      loading={loading}
      onSearch={search}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      {/* @ts-expect-error */}
      <div className='row mb-3'>
        <div className='col-5'>
          {/* @ts-expect-error */}
          <Textbox
            label='Nome/ Razão Social'
            text={filtros.noPessoa}
            maxLength={200}
            onChangedValue={(noPessoa: string) =>
              setFiltros({ ...filtros, noPessoa })
            }
          />
        </div>
        <div className='col-5'>
          <Autocomplete
            label='Empresa'
            searchDataSource={onSearchEmpresa}
            selectedItem={filtros?.empresa}
            onSelectItem={(empresa: any) => {
              setFiltros({
                ...filtros,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Admissão de'
            text={filtros.dtAdmissaoIni}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtAdmissaoIni) =>
              setFiltros({ ...filtros, dtAdmissaoIni })
            }
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Admissão até'
            text={filtros.dtAdmissaoFim}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtAdmissaoFim) =>
              setFiltros({ ...filtros, dtAdmissaoFim })
            }
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Demissão de'
            text={filtros.dtDemissaoIni}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtDemissaoIni) =>
              setFiltros({ ...filtros, dtDemissaoIni })
            }
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Demissão até'
            text={filtros.dtDemissaoFim}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtDemissaoFim) =>
              setFiltros({ ...filtros, dtDemissaoFim })
            }
          />
        </div>
        <div className='col-auto'>
          <RadioButton
            outline
            label='Status'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={StatusFuncionario}
            selectedButton={filtros.noStatus}
            onChange={(status) =>
              setFiltros({
                ...filtros,
                noStatus: status,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='row mb-3'>
          <Panel>
            <Panel.Header
              title='Preferências de Impressão'
              theme={Theme.Primary}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col'>
                  <Switch
                    formControl
                    label='Exibir Profissão'
                    checked={filtros.flgExibeProfissao}
                    onChange={(flgExibeProfissao) => {
                      setFiltros({ ...filtros, flgExibeProfissao });
                    }}
                  />
                </div>
                <div className='col'>
                  <Switch
                    formControl
                    label='Exibir Pai e Mãe'
                    checked={filtros.flgExibePaiMae}
                    onChange={(flgExibePaiMae) => {
                      setFiltros({ ...filtros, flgExibePaiMae });
                    }}
                  />
                </div>
                <div className='col'>
                  <Switch
                    formControl
                    label='Exibir Endereço'
                    checked={filtros.flgExibeEndereco}
                    onChange={(flgExibeEndereco) => {
                      setFiltros({ ...filtros, flgExibeEndereco });
                    }}
                  />
                </div>
                <div className='col'>
                  <Switch
                    formControl
                    label='Exibir Cidade'
                    checked={filtros.flgExibeCidade}
                    onChange={(flgExibeCidade) => {
                      setFiltros({ ...filtros, flgExibeCidade });
                    }}
                  />
                </div>
                <div className='col'>
                  <Switch
                    formControl
                    label='Exibir Estado'
                    checked={filtros.flgExibeEstado}
                    onChange={(flgExibeEstado) => {
                      setFiltros({ ...filtros, flgExibeEstado });
                    }}
                  />
                </div>
                <div className='col'>
                  <Switch
                    formControl
                    label='Exibir Data de Demissão'
                    checked={filtros.flgExibeDemissao}
                    onChange={(flgExibeDemissao) =>
                      setFiltros({ ...filtros, flgExibeDemissao })
                    }
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            onColumnSort={onColumnSort}
            enableExcelExport
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            canControlVisibility
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
