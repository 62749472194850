import { ContratoAgrupado } from 'core/models/TEL/';
import ContratoAgrupadoCliente from 'core/models/TEL/contratoAgrupadoCliente';
import api from '../api';

export const getContratoAgrupadoAutoComplete = async (filters) => {
  const response = await api.get(`/ContratoAgrupado/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    contratos: value?.map((json) => new ContratoAgrupado(json)) ?? [],
  };
};

export const getContratoAgrupadoClienteList = async (filters) => {
  const response = api.get(`/ContratoAgrupado/SearchContratoAgrupado`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    contratos: value?.map((json) => new ContratoAgrupadoCliente(json)) ?? [],
  };
};
