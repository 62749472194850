import { Produto, ProdutoOrigem, AlmoxarifadoLocal } from 'core/models/EST';
import {
  Cfo,
  TributacaoIcms,
  CstIpi,
  CstPis,
  CstCofins,
} from 'core/models/FIS';

import { DBO } from 'core/models/SEG';

import { NfvAvulsa } from 'core/models/VEN';

export default class NfvAvulsaItem extends DBO {
  nrSeqNfvAvulsaItem: number;

  nrSeqNfvAvulsa: number;

  nrSeqProduto: number;

  qtdeItNfv: number;

  qtdeCancel: number;

  qtdeSaldoItem: number;

  qtdeRetorno: number;

  qtdeEnvio: number;

  vlrItNfv: number;

  vlrDesc: number;

  vlrTotNfv: number;

  flgTipo: boolean;

  vlrIcms: number;

  vlrIpi: number;

  aliqIcms: number;

  aliqIpi: number;

  percBaseCalcIpi: number;

  percBaseCalcIcms: number;

  vlrBaseCalcIcms: number;

  vlrBaseCalcIpi: number;

  aliqPis: number;

  vlrBaseCalcPis: number;

  vlrPis: number;

  aliqCofins: number;

  vlrBaseCalcCofins: number;

  vlrCofins: number;

  nrSeqCstCofins: number;

  nrSeqCstIpi: number;

  nrSeqCstPis: number;

  nrSeqTributacaoIcms: number;

  nrSeqLoteEstoqueMovimento: number;

  nrSeqProdutoOrigem: number;

  nrSeqAlmoxarifadoLocal: number;

  nrSeqCfo: number;

  flgIcmsParcial: boolean;

  vlrOutrasDespesasAcessorias: number;

  aliqIcmsDesonerado: number;

  vlrBaseCalcIcmsDesonerado: number;

  percBaseCalcIcmsDesonerado: number;

  vlrIcmsDesonerado: number;

  aliqFCP: number;

  percBaseCalcFCP: number;

  vlrBaseCalcFCP: number;

  vlrFCP: number;

  produto: Produto;

  cfo: Cfo;

  vlrSubstituicao: number;

  vlrBSubstituicao: number;

  produtoOrigem: ProdutoOrigem;

  tributacaoIcms: TributacaoIcms;

  cstIpi: CstIpi;

  cstPis: CstPis;

  cstCofins: CstCofins;

  almoxarifadoLocal: AlmoxarifadoLocal;

  nfvAvulsa: NfvAvulsa;

  dtMovimento: Date;

  noStatusMovimento: string;

  constructor(jsonObject: NfvAvulsaItem) {
    super(jsonObject);
    this.nrSeqNfvAvulsaItem = jsonObject.nrSeqNfvAvulsaItem;
    this.nrSeqNfvAvulsa = jsonObject.nrSeqNfvAvulsa;
    this.nrSeqProduto = jsonObject.nrSeqProduto;
    this.qtdeItNfv = jsonObject.qtdeItNfv;
    this.qtdeCancel = jsonObject.qtdeCancel;
    this.qtdeSaldoItem = jsonObject.qtdeSaldoItem;
    this.qtdeRetorno = jsonObject.qtdeRetorno;
    this.qtdeEnvio = jsonObject.qtdeEnvio;
    this.vlrItNfv = jsonObject.vlrItNfv;
    this.vlrDesc = jsonObject.vlrDesc;
    this.vlrTotNfv = jsonObject.vlrTotNfv;
    this.flgTipo = jsonObject.flgTipo;
    this.vlrIcms = jsonObject.vlrIcms;
    this.vlrIpi = jsonObject.vlrIpi;
    this.aliqIcms = jsonObject.aliqIcms;
    this.aliqIpi = jsonObject.aliqIpi;
    this.percBaseCalcIpi = jsonObject.percBaseCalcIpi;
    this.percBaseCalcIcms = jsonObject.percBaseCalcIcms;
    this.vlrBaseCalcIcms = jsonObject.vlrBaseCalcIcms;
    this.vlrBaseCalcIpi = jsonObject.vlrBaseCalcIpi;
    this.aliqPis = jsonObject.aliqPis;
    this.vlrBaseCalcPis = jsonObject.vlrBaseCalcPis;
    this.vlrPis = jsonObject.vlrPis;
    this.aliqCofins = jsonObject.aliqCofins;
    this.vlrBaseCalcCofins = jsonObject.vlrBaseCalcCofins;
    this.vlrCofins = jsonObject.vlrCofins;
    this.nrSeqCstCofins = jsonObject.nrSeqCstCofins;
    this.nrSeqCstIpi = jsonObject.nrSeqCstIpi;
    this.nrSeqCstPis = jsonObject.nrSeqCstPis;
    this.nrSeqTributacaoIcms = jsonObject.nrSeqTributacaoIcms;
    this.nrSeqLoteEstoqueMovimento = jsonObject.nrSeqLoteEstoqueMovimento;
    this.nrSeqProdutoOrigem = jsonObject.nrSeqProdutoOrigem;
    this.nrSeqAlmoxarifadoLocal = jsonObject.nrSeqAlmoxarifadoLocal;
    this.nrSeqCfo = jsonObject.nrSeqCfo;
    this.flgIcmsParcial = jsonObject.flgIcmsParcial;
    this.vlrOutrasDespesasAcessorias = jsonObject.vlrOutrasDespesasAcessorias;
    this.aliqIcmsDesonerado = jsonObject.aliqIcmsDesonerado;
    this.vlrBaseCalcIcmsDesonerado = jsonObject.vlrBaseCalcIcmsDesonerado;
    this.percBaseCalcIcmsDesonerado = jsonObject.percBaseCalcIcmsDesonerado;
    this.vlrIcmsDesonerado = jsonObject.vlrIcmsDesonerado;
    this.aliqFCP = jsonObject.aliqFCP;
    this.percBaseCalcFCP = jsonObject.percBaseCalcFCP;
    this.vlrBaseCalcFCP = jsonObject.vlrBaseCalcFCP;
    this.vlrFCP = jsonObject.vlrFCP;
    this.vlrSubstituicao = jsonObject.vlrSubstituicao;
    this.dtMovimento = jsonObject.dtMovimento;
    this.vlrBSubstituicao = jsonObject.vlrBSubstituicao;
    this.noStatusMovimento = jsonObject.noStatusMovimento;
    this.nfvAvulsa = new NfvAvulsa(jsonObject.nfvAvulsa ?? {});
    this.produto = new Produto(jsonObject.produto ?? {});
    this.cfo = new Cfo(jsonObject.cfo ?? {});
    this.produtoOrigem = new ProdutoOrigem(jsonObject.produtoOrigem ?? {});
    this.tributacaoIcms = new TributacaoIcms(jsonObject.tributacaoIcms ?? {});
    this.cstIpi = new CstIpi(jsonObject.cstIpi ?? {});
    this.cstPis = new CstPis(jsonObject.cstPis ?? {});
    this.cstCofins = new CstCofins(jsonObject.cstCofins ?? {});
    this.almoxarifadoLocal = new AlmoxarifadoLocal(
      jsonObject.almoxarifadoLocal ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
