import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  Textbox,
  Autocomplete,
  Notification,
  Panel,
  Button,
  GridView,
  Loader,
} from 'ui/components';
import {
  BootstrapSizes,
  ResponseStatus,
  Theme,
  JustifyContent,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';

import ManifestoCte from 'core/models/FRO/manifestoCTE';

import { getRotaTransporteAutoComplete } from 'core/services/FRO/rotaTransporte';
import { getTipoCargaMdfeAutoComplete } from 'core/services/FRO/tipoCargaMdfe';
import {
  printManifestoCTE,
  gerarManifesto,
  saveManifestoCTE,
  GetMensagemViagensEmAndamento,
  deleteManifestoCTE,
} from 'core/services/FRO/manifestoCTE';
import { efetivarMdfe } from 'core/services/FRO/manifestoMdfe';

import ModalCancelMdfe from 'ui/pages/FRO/GerenciarMdfe/modalCancelMdfe';
import ModalEncerraManifesto from 'ui/pages/FRO/GerenciarMdfe/modalEncerraManifesto';
import ModalAvisoManifesto from 'ui/pages/FRO/GerenciarMdfe/modalAvisoManifesto';

export default function ModalManifestarViagem(props) {
  const { show, data: retorno } = props;
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalAvisoManifesto, setModalAvisoManifesto] = useState({});
  const [data, setData] = useState(new ManifestoCte({}));
  const [liberaRotas, setLiberaRotas] = useState(false);
  const [modalCancelaMdfe, setModalCancelalMdfe] = useState({});
  const [modalEncerraManifesto, setModalEncerraManifesto] = useState({});
  const gridViewManifestoEletronico = useRef();
  const gridViewManifesto = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const loadTipoCargaMdfePadrao = async (e) => {
    const {
      status,
      message: msg,
      data: tipoCargas,
    } = await getTipoCargaMdfeAutoComplete({
      noTipoCargaMdfe: e,
      flgApenasAtivos: true,
      flgApenasPadrao: true,
    });

    if (msg) onSetMessage(status, msg);

    const dataSource = { ...data };

    if (tipoCargas.length > 0) {
      const [firstCarga] = tipoCargas;

      dataSource.nrSeqTipoCargaMdfe = firstCarga.nrSeqTipoCargaMdfe;
      dataSource.tipoCarga = firstCarga;
    }

    if (e?.noCiot) dataSource.nrCiot = e.noCiot;

    setData(dataSource);

    return tipoCargas;
  };

  const loadMensagemViagensEmAndamento = async () => {
    if (!retorno) return;
    if (!retorno.manifestos) return;

    if (retorno.manifestos.length > 0) {
      const { message: msg } = await GetMensagemViagensEmAndamento(
        retorno.manifestos[0].nrSeqVeiculo
      );

      if (msg) onSetMessage(ResponseStatus.Warning, msg);
    }
  };

  const load = () => {
    if (retorno?.manifestoEletronico) {
      if (gridViewManifestoEletronico && gridViewManifestoEletronico.current)
        gridViewManifestoEletronico.current.setDataSource(retorno.manifestos);
    } else if (retorno?.manifesto) {
      if (gridViewManifesto && gridViewManifesto.current)
        gridViewManifesto.current.setDataSource(retorno.manifestos);
    }

    loadTipoCargaMdfePadrao(retorno);
    loadMensagemViagensEmAndamento();
  };

  useEffect(() => {
    load();
  }, [show]);

  const onSend = () => {
    props.onClose();
  };

  const onClickImprimir = async (e) => {
    setLoading(true);

    const { value } = await printManifestoCTE({
      nrSeqManifestoCte: e.nrSeqManifestoCte,
    });

    props.onOpenReport(value);

    setLoading(false);
  };

  const onClickEditManifesto = (e, datasource) => {
    const dataManifesto = datasource.find((item) => item === e);
    dataManifesto.status = GridView.EnumStatus.Alterar;

    const dataManifestos = datasource.filter((item) => item !== e);

    if (gridViewManifestoEletronico && gridViewManifestoEletronico.current)
      gridViewManifestoEletronico.current.setDataSource(dataManifestos);

    setLiberaRotas(true);

    setData(dataManifesto);
  };

  const onClickEfetivarManifesto = async (e) => {
    setLoading(true);

    const { status, message: msg, value } = await efetivarMdfe(e);

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      let datasource = gridViewManifestoEletronico.current.getDataSource();

      datasource = datasource.map((item) => {
        if (item.nrSeqManifestoCte === e.nrSeqManifestoCte) {
          item.statusMdfe = value.statusMdfe;
          item.colorBackgroundStatus = value.colorBackgroundStatus;
          item.statusMdfeString = value.statusMdfeString;
          item.flgMostraEfetivar = false;
        }

        return item;
      });

      if (gridViewManifestoEletronico && gridViewManifestoEletronico.current)
        gridViewManifestoEletronico.current.setDataSource(datasource);
    } else if (value.xmlEnvio) {
      const xmlURI = encodeURIComponent(value.xmlEnvio);
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:text/plain;charset=utf-8,${xmlURI}`;
      downloadLink.download = `xmlMdfeEnvio.xml`;
      downloadLink.click();
    }

    setLoading(false);
  };

  const onClickCancelarManifesto = (e) => {
    setLoading(true);

    setModalCancelalMdfe({ ...modalCancelaMdfe, show: true, data: e });

    setLoading(false);
  };

  const manifestoEncerrado = (object, msg) => {
    setLoading(true);

    let gridDataSource = gridViewManifestoEletronico.current.getDataSource();

    gridDataSource = gridDataSource.map((item) => {
      if (item.nrSeqManifestoCte === object.nrSeqManifestoCte) return object;

      return item;
    });

    if (gridViewManifestoEletronico && gridViewManifestoEletronico.current)
      gridViewManifestoEletronico.current.setDataSource(gridDataSource);

    if (msg) onSetMessage(ResponseStatus.Success, msg);

    setLoading(false);
  };

  const onEncerraManifesto = (manifesto) => {
    setModalAvisoManifesto({
      ...modalAvisoManifesto,
      show: false,
    });

    setModalEncerraManifesto({
      ...modalEncerraManifesto,
      show: true,
      data: manifesto,
    });
  };

  const onClickCloseManifest = (e) => {
    const dtNow = new Date().toISOString().slice(0, -1);

    if (e.dtEmissao !== dtNow) {
      setModalAvisoManifesto({ ...modalAvisoManifesto, show: true, data: e });
    } else {
      setModalEncerraManifesto({
        ...modalEncerraManifesto,
        show: true,
        data: e,
      });
    }
  };

  const onClickDeleteManifesto = async (e, datasource) => {
    setLoading(true);

    const { status, message: msg } = await deleteManifestoCTE(
      e.nrSeqManifestoCte
    );

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      datasource = datasource.filter((item) => item !== e);

      if (gridViewManifestoEletronico && gridViewManifestoEletronico.current)
        gridViewManifestoEletronico.current.setDataSource(datasource);
    }

    setLoading(false);
  };

  const onAutoCompleteRotas = async (search) => {
    const {
      status,
      message: msg,
      data: rotas,
    } = await getRotaTransporteAutoComplete({
      nrSeqEstadoOri: search?.nrSeqEstadoOri ?? data.nrSeqEstadoOri,
      nrSeqEstadoDest: search?.nrSeqEstadoDest ?? data.nrSeqEstadoDest,
    });

    if (msg) onSetMessage(status, msg);

    return rotas;
  };

  const fOnSelectItem = async (keyValue, value) => {
    setLoading(true);

    const dataSource = gridViewManifestoEletronico.current.getDataSource();

    const manifestoCteGrid = dataSource.find(
      (item) => item.nrSeqManifestoCte === keyValue
    );

    manifestoCteGrid.rotaTransporte = value;

    if (value.nrSeqRotaTransporte) {
      manifestoCteGrid.nrSeqRotaTransporte = value.nrSeqRotaTransporte;

      const { status, message: msg } = await saveManifestoCTE(manifestoCteGrid);

      if (msg) onSetMessage(status, msg);
    }

    setLoading(false);
  };

  const onAutoCompleteGridview = async (e, keyValue) => {
    const dataSource = gridViewManifestoEletronico.current.getDataSource();

    const gridItem = dataSource.find(
      (item) => item.nrSeqManifestoCte === keyValue
    );

    return onAutoCompleteRotas({
      nrSeqEstadoOri: gridItem.nrSeqEstadoOri,
      nrSeqEstadoDest: gridItem.nrSeqEstadoDest,
    });
  };

  const columnsManifestoEletronico = [
    { key: 'nrSeqManifestoCte', visible: false },
    { key: 'cdManifestoCte', title: 'Cód.' },
    { key: 'nrCiot', title: 'Ciot' },
    { key: 'dtEmissao', title: 'Emissão', format: GridView.DataTypes.Date },
    { key: 'estadoOrigem.cdEstado', title: 'UF Ori' },
    { key: 'estadoDestino.cdEstado', title: 'UF Dest' },
    {
      key: 'rotaTransporte',
      title: 'Rota Completa',
      type: GridView.ColumnTypes.Autocomplete,
      dataSourceTextProperty: 'noRotaCompleta',
      searchDataSource: onAutoCompleteGridview,
      onSelectItem: fOnSelectItem,
    },
    {
      key: 'statusMdfeString',
      title: 'Status',
      color: 'colorBackgroundStatus',
    },
    {
      key: 'nrSeqManifestoCte',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostraEfetivar',
      onClick: (e, datasource) => onClickEfetivarManifesto(e, datasource),
      theme: Theme.Success,
      icon: 'share',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Efetivar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqManifestoCte',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostraCancelar',
      onClick: (e, datasource) => onClickCancelarManifesto(e, datasource),
      theme: Theme.Danger,
      icon: 'ban',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Cancelar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqManifestoCte',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostraEncerrar',
      onClick: (e) => onClickCloseManifest(e),
      theme: Theme.Warning,
      icon: 'flag-checkered',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Encerrar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqManifestoCte',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimir(e),
      theme: Theme.Primary,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqManifestoCte',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostraEditar',
      onClick: (e, datasource) => onClickEditManifesto(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqManifestoCte',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostraExcluir',
      onClick: (e, datasource) => onClickDeleteManifesto(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Excluir',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsManifesto = [
    { key: 'cdManifestoCte', title: 'Cód.' },
    { key: 'dtEmissao', title: 'Emissão', format: GridView.DataTypes.Date },
    { key: 'estadoOrigem.cdEstado', title: 'UF Ori' },
    { key: 'estadoDestino.cdEstado', title: 'UF Dest' },
    {
      key: 'dtEncerramento',
      title: 'Encerramento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrSeqManifestoCte',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimir(e),
      theme: Theme.Primary,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
  ];

  const onAutoCompleteTipoCarga = async (e) => {
    const {
      status,
      message: msg,
      data: tipoCargas,
    } = await getTipoCargaMdfeAutoComplete({
      noTipoCargaMdfe: e,
      flgApenasAtivos: true,
    });

    if (msg) onSetMessage(status, msg);

    return tipoCargas;
  };

  const onClickGeraManifesto = async () => {
    setLoading(true);

    const obj = {
      ...data,
      nrSeqViagem: retorno?.viagem.nrSeqViagem,
      divCiotVisible: retorno?.divCiotVisible,
    };

    const {
      status,
      messages: msgs,
      data: manifestoGerado,
    } = await gerarManifesto(obj);

    if (msgs && msgs?.length > 0) setMessages(msgs);

    if (status === ResponseStatus.Success) {
      if (retorno.manifestoEletronico) {
        const datasource = gridViewManifestoEletronico.current.getDataSource();
        const dataFinal = datasource.concat(manifestoGerado);

        if (gridViewManifestoEletronico && gridViewManifestoEletronico.current)
          gridViewManifestoEletronico.current.setDataSource(dataFinal);
      } else if (retorno.manifesto) {
        const datasource = gridViewManifesto.current.getDataSource();
        const dataFinal = datasource.concat(manifestoGerado);

        if (gridViewManifesto && gridViewManifesto.current)
          gridViewManifesto.current.setDataSource(dataFinal);
      }
      setData(new ManifestoCte({}));
    }

    setLoading(false);
  };

  const onClickAdicionarNaGrid = async () => {
    await saveManifestoCTE(data);
    const datasource = gridViewManifestoEletronico.current.getDataSource();
    datasource.push(data);

    if (gridViewManifestoEletronico && gridViewManifestoEletronico.current)
      gridViewManifestoEletronico.current.setDataSource(datasource);

    setLiberaRotas(false);

    setData(new ManifestoCte({}));
  };

  const cancelSuccess = (datasource) => {
    if (datasource) {
      const gridDataSource = gridViewManifestoEletronico.current.getDataSource();

      gridDataSource.forEach((item) => {
        if (item.nrSeqManifestoCte === datasource.nrSeqManifestoCte) {
          item.statusMdfe = datasource.statusMdfe;
          item.colorBackgroundStatus = datasource.colorBackgroundStatus;
          item.statusMdfeString = datasource.statusMdfeString;
        }
      });

      if (gridViewManifestoEletronico && gridViewManifestoEletronico.current)
        gridViewManifestoEletronico.current.setDataSource(gridDataSource);
    }
  };

  return (
    <Modal
      show={show}
      title={retorno?.titleModal}
      onClose={() => onSend()}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        {messages?.length > 0 && (
          <Notification
            floatable
            messages={messages}
            onClose={() => setMessages([])}
          />
        )}
        {retorno?.divCiotVisible && (
          <div className='alert alert-info' role='alert'>
            <div className='row'>
              <div className='col fs-6'>
                <span className='fw-bold'>
                  Caso não preenchido o número CIOT será encaminhado para a
                  sefaz os cnpjs das empresas contratantes do transporte.
                </span>
              </div>
            </div>
          </div>
        )}
        <div className='row'>
          <div className='row mb-3'>
            <div className='col-2'>
              <Textbox
                label='Número do CIOT'
                text={data?.nrCiot}
                maxLength={12}
                onChangedValue={(nrCiot) => setData({ ...data, nrCiot })}
              />
            </div>
            <div className='col-5'>
              <Autocomplete
                label='Tipo de Carga Predominante (com maior valor em nota fiscal)'
                searchDataSource={onAutoCompleteTipoCarga}
                selectedItem={data?.tipoCarga}
                onSelectItem={(tipoCarga) =>
                  setData({
                    ...data,
                    tipoCarga,
                    nrSeqTipoCargaMdfe: tipoCarga.nrSeqTipoCargaMdfe,
                  })
                }
                dataSourceTextProperty='noTipoCargaMdfe'
              />
            </div>
            {liberaRotas && (
              <div className='col-5'>
                <Autocomplete
                  label='Rotas Disponiveis'
                  searchDataSource={onAutoCompleteRotas}
                  selectedItem={data?.rotaTransporte}
                  onSelectItem={(rotaTransporte) =>
                    setData({
                      ...data,
                      rotaTransporte,
                      nrSeqRotaTransporte: rotaTransporte.nrSeqRotaTransporte,
                    })
                  }
                  dataSourceTextProperty='noRotaCompleta'
                />
              </div>
            )}
          </div>
          {retorno?.painelMdfeValePedagioVisible && (
            <div className='row mb-3'>
              <Panel>
                <Panel.Header
                  icon='list'
                  title='Informações Vale Pedágio'
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                />
                <Panel.Body>
                  <div className='row'>
                    {!retorno?.geraAdiantamento && (
                      <div className='col-3'>
                        <Textbox
                          label='Cnpj For. Vale'
                          text={data?.noValePedagioCnpjForn}
                          onChangedValue={(noValePedagioCnpjForn) =>
                            setData({ ...data, noValePedagioCnpjForn })
                          }
                        />
                      </div>
                    )}
                    {!retorno?.geraAdiantamento && (
                      <div className='col-3'>
                        <Textbox
                          label='Cnpj Pag. Vale'
                          text={data?.noValePedagioCnpjPag}
                          onChangedValue={(noValePedagioCnpjPag) =>
                            setData({ ...data, noValePedagioCnpjPag })
                          }
                        />
                      </div>
                    )}
                    {!retorno?.geraAdiantamento && (
                      <div className='col-3'>
                        <Textbox
                          label='Núm. Compra'
                          text={data?.noValePedagioNumCompra}
                          onChangedValue={(noValePedagioNumCompra) =>
                            setData({ ...data, noValePedagioNumCompra })
                          }
                        />
                      </div>
                    )}
                    <div className='col-3'>
                      <Textbox
                        label='Vlr. Vale'
                        required={retorno?.geraAdiantamento}
                        text={data?.vlrValePedagio}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={(vlrValePedagio) =>
                          setData({ ...data, vlrValePedagio })
                        }
                      />
                    </div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
          )}
          <div className='row mb-3'>
            <div className='col'>
              <Button
                outline
                text='Gerar Manifesto'
                theme={Theme.Success}
                template={Button.Templates.Default}
                size={BootstrapSizes.Medium}
                onClick={() => onClickGeraManifesto()}
              />
            </div>
            {liberaRotas && (
              <div className='col'>
                <Button
                  outline
                  text='Adicionar'
                  theme={Theme.Success}
                  template={Button.Templates.Default}
                  size={BootstrapSizes.Medium}
                  onClick={() => onClickAdicionarNaGrid()}
                />
              </div>
            )}
          </div>
          {retorno?.manifestoEletronico && (
            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridViewManifestoEletronico}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsManifestoEletronico}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          )}
          {retorno?.manifesto && (
            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridViewManifesto}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsManifesto}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          )}
        </div>
        <div className='row'>
          <div className='col'>
            <ModalCancelMdfe
              show={modalCancelaMdfe?.show}
              data={modalCancelaMdfe?.data}
              onClose={(datasource) => {
                setModalCancelalMdfe({ ...modalCancelaMdfe, show: false });

                cancelSuccess(datasource);
              }}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <ModalEncerraManifesto
              show={modalEncerraManifesto?.show}
              manifesto={modalEncerraManifesto?.data}
              onClose={(msg, manifesto) => {
                setModalEncerraManifesto({
                  ...modalEncerraManifesto,
                  show: false,
                  data: null,
                });

                if (manifesto) manifestoEncerrado(manifesto, msg);
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalAvisoManifesto
              show={modalAvisoManifesto?.show}
              onClose={(encerra) => {
                if (encerra) onEncerraManifesto(modalAvisoManifesto?.data);
                else
                  setModalAvisoManifesto({
                    ...modalAvisoManifesto,
                    show: false,
                  });
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
