import qs from 'qs';
import Pagination from 'core/models/pagination';
import ProdutoServico from 'core/models/VEN/produtoServico';
import api from '../api';

const baseURL = `/VEN/ProdutoServico`;

export const getProdutoServicoList = async (filters) => {
  const response = await api.get(`/$ProdutoServico/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ProdutoServico(json)) ?? [],
  };
};

export const getProdutoServicoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ProdutoServico(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getProdutoServico = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ProdutoServico(value) : {};
};

export const saveProdutoServico = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteProdutoServico = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printProdutoServico = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelProdutoServico = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllProdutoServico = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getProdutoServicoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ProdutoServico(json)) ?? [],
  };
};
