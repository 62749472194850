import { getVencimentoAutoComplete } from 'core/services/ASS/vencimento';
import { getClienteAutoCompleteDemanda } from 'core/services/FIN';
import { getInformacaoBancariaAutoCompleteFiltraFormaPagamento } from 'core/services/FIN/informacaoBancaria';
import {
  getCentroDeCustoAutoCompleteSimples,
  getChipAutoCompleteChipDemanda,
  getOperadoraAutoComplete,
  getPlanoAutoCompleteDemanda,
  getTipoChamadaAutoCompleteResumido,
} from 'core/services/TEL';
import { getPlanoVinculoCentrodeCustoEmpAutoComplete } from 'core/services/TEL/planoVinculoCentrodeCustoEmp';
import React, { useEffect, useState, useRef } from 'react';
import { MaskTypes, mask } from 'ui/Helpers/masks';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import {
  BootstrapSizes,
  MimeTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  DatePicker,
  FileUpload,
  GridView,
  RadioButton,
  Textarea,
  Textbox,
} from 'ui/components';

export default function ManutencaoNovoContratoMassivo({
  data,
  setData,
  onSetMessage,
  setDataListDocRemove,
  documentos = [],
  setDocumentos,
  onOpenTransaction,
  findTransaction,
  dataMassivo,
  setDataMassivo,
  clienteMessage,
  contratoMessage,
  onSelectCliente,
  onSelectFormaPagamento,
  setLoading,
  onAutoCompleteFormaPagamento,
}) {
  const gridView = useRef();
  const gridViewDocumentos = useRef();
  const [importedFile, setImportedFile] = useState([]);
  const [hasPlanoRateioDefault, setHasPlanoRateioDefault] = useState(false);
  const [gridHasItem, setGridHasItem] = useState(false);
  const [dataChip, setDataChip] = useState({});
  const [addLinhasMassiva, setAddLinhasMassiva] = useState('F');
  const [linhasPortadas, setLinhasPortadas] = useState('');

  const onSearchPacoteDados = async (e) => {
    if (data.nrSeqPlano === undefined || data.nrSeqPlano === 0) {
      onSetMessage(ResponseStatus.Error, 'Selecione o Plano.');
      return [];
    }

    if (!data.nrSeqEmpresa) {
      onSetMessage(ResponseStatus.Error, 'Selecione a Empresa');

      return [];
    }
    if (data.nrSeqCentroDeCusto) {
      const {
        planovinculo,
      } = await getPlanoVinculoCentrodeCustoEmpAutoComplete({
        nrSeqPlano: data.plano?.nrSeqPlano,
        nrSeqOperadora: data.nrSeqOperadoraDestino,
        nrSeqEmpresa: data.nrSeqEmpresa,
        nrSeqCentroDeCusto: data.nrSeqCentroDeCusto,
        noTipoChamada: e,
      });
      if (planovinculo.length === 0) {
        onSetMessage(
          ResponseStatus.Error,
          'Centro de Custo sem pacote vinculado nessa Empresa.'
        );
      }

      return planovinculo;
    }
    const { tiposChamadas } = await getTipoChamadaAutoCompleteResumido({
      nrSeqPlano: data.plano?.nrSeqPlano,
      nrSeqOperadora: data.nrSeqOperadoraDestino,
      flgBuscaEncargo: true,
      noTipoChamada: e,
    });

    if (tiposChamadas.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Nenhum pacote de dados encontrado para esse plano'
      );
    }

    return tiposChamadas;
  };

  const onSearchCliente = async (e) => {
    const { clientes } = await getClienteAutoCompleteDemanda({
      noPessoa: e,
      flgIncluirEndereco: true,
      flgFiltrarEmpresa: true,
    });
    return clientes;
  };

  const onSearchChip = async (e) => {
    const {
      status,
      message: msg,
      chips,
    } = await getChipAutoCompleteChipDemanda({
      nrSeqOperadora: data.nrSeqOperadora,
      nrLinha: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
      nrSeqCentroDeCusto: data.nrSeqCentroDeCusto,
    });

    onSetMessage(status, msg);

    return chips;
  };

  const onSearchPlanoRateio = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoCompleteDemanda({
      noPlano: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
      flgAtivo: true,
      flgPlanoRateioEmpresa: true,
    });

    if (planos.length === 0)
      onSetMessage(ResponseStatus.Error, 'Nenhum plano vinculado a empresa.');

    onSetMessage(status, msg);

    return planos;
  };

  const getPlanoRateio = async (nrSeqTipoChamada) => {
    const { planos } = await getPlanoAutoCompleteDemanda({
      nrSeqTipoChamada,
      nrSeqEmpresa: data.nrSeqEmpresa,
      flgAtivo: true,
      flgPlanoRateioEmpresa: true,
    });

    return planos.length > 0 ? planos[0] : undefined;
  };

  const onSearchPlanoOperadoraDestino = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoCompleteDemanda({
      noPlano: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
      nrSeqOperadora: data.operadoraDestino.nrSeqOperadora,
      flgAgrupado: true,
    });

    if (planos.length === 0)
      onSetMessage(ResponseStatus.Error, 'Nenhum plano vinculado a empresa.');

    onSetMessage(status, msg);

    return planos;
  };

  const onSearchCentroDeCusto = async (e) => {
    const {
      status,
      message: msg,
      data: centros,
    } = await getCentroDeCustoAutoCompleteSimples({
      codNoCentroDeCusto: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    onSetMessage(status, msg);

    return centros;
  };

  const onSearchOperadora = async (e) => {
    const {
      status,
      message: msg,
      operadoras,
    } = await getOperadoraAutoComplete({ noOperadora: e });

    if (status === ResponseStatus.Error) {
      onSetMessage(status, msg);
      return [];
    }

    // Não fazer Novo Contrato Massivo para MVNO
    if (
      data.tipoDemanda?.flgNrLinhaNova &&
      !data.tipoDemanda?.flgPortabilidade &&
      !data.tipoDemanda?.flgGeraTT &&
      data.tipoDemanda?.flgGeraMassivo === true
    ) {
      return operadoras.filter(
        (operadora) =>
          !operadora.noOperadora.includes('MVNO') &&
          !operadora.noOperadora.includes('Oi') &&
          !operadora.noOperadora.includes('Importação Manual')
      );
    }

    return operadoras;
  };

  // const onAutoCompleteFormaPagamento = async (e) => {
  //   const { formasPagamento } = await getFormaPagamentoPdvAutoComplete({
  //     noFormaPagamento: e,
  //   });

  //   return formasPagamento;
  // };

  const onSearchVencimento = async (e) => {
    const {
      status,
      message: msg,
      vencimentos,
    } = await getVencimentoAutoComplete({
      noVencimento: e,
    });
    onSetMessage(status, msg);
    return vencimentos;
  };

  const onSearchInformacaoBancaria = async () => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoCompleteFiltraFormaPagamento({
      nrSeqPessoa: data.cliente?.nrSeqPessoaCli,
      nrSeqFormaPagamento: data.nrSeqFormaPagamento,
    });
    onSetMessage(status, msg);
    return informacoesBancarias;
  };

  const removerLinhaContratoMassivo = (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const list = datasource.filter((el) => el !== e);

    if (gridItem.status !== GridView.EnumStatus.Inserir) {
      gridItem.status = GridView.EnumStatus.Remover;

      list.push(gridItem);
    }

    if (list.length === 0) {
      setGridHasItem(false);
    }

    setDataMassivo(list);
  };

  const columns = [
    { key: 'chip.nrPrefixoLinha', title: 'Número' },
    { key: 'plano.noPlano', title: 'Plano' },
    { key: 'operadoraDestino.noOperadora', title: 'Operadora Destino' },
    { key: 'tipoChamada.noTipoChamada', title: 'Pacote de Dados' },
    { key: 'centroDeCusto.noCentroDeCusto', title: 'Centro de Custo' },
    { key: 'formaPagamento.noFormaPagamento', title: 'Forma de Pagamento' },
    {
      key: 'nrSeqDemanda',
      type: GridView.ColumnTypes.Button,
      onClick: (e, dataSource) => removerLinhaContratoMassivo(e, dataSource),
      title: 'Excluir',
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onClickAddDocumento = async () => {
    if (importedFile.length > 0) {
      const documento = {
        ...importedFile,
        noArquivo: importedFile[0].name,
        noTipoArquivo: importedFile[0].type,
        noTamanho: importedFile[0].size,
        flgExibeDownload: false,
        status: GridView.EnumStatus.Inserir,
      };

      const dataSource = gridViewDocumentos?.current?.getDataSource() ?? [];

      dataSource.push(documento);

      if (gridViewDocumentos && gridViewDocumentos.current) {
        gridViewDocumentos.current.setDataSource(dataSource);
      }

      setImportedFile([]);

      setDocumentos([...documentos, importedFile[0]]);
    }
  };

  const onClickDownload = async (e) => {
    const downloadLink = document.createElement('a');

    if (e.noImagem !== null && e.noImagem !== '') {
      const linkSource = `data:image/png;base64, ${e.noImagem}`;
      downloadLink.href = linkSource;
      downloadLink.download = e.noArquivo;
    } else if (e.linkDownload !== null && e.linkDownload !== '') {
      downloadLink.href = e.linkDownload;
      downloadLink.target = '_blank';
    }

    downloadLink.click();
  };

  const onRemoverDocumento = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const list = datasource.filter((el) => el !== e);

    if (gridItem.nrSeqDemandaDocumento) {
      gridItem.status = GridView.EnumStatus.Remover;

      list.push(gridItem);

      const nrSeqsToRemove = list
        .filter((item) => item.status === GridView.EnumStatus.Remover)
        .map((item) => item.nrSeqDemandaDocumento);

      setDataListDocRemove(nrSeqsToRemove);
    } else {
      const docList = documentos.filter(
        (el) =>
          el.name !== gridItem.name &&
          el.type !== gridItem.type &&
          el.size !== gridItem.size
      );
      setDocumentos(docList);
    }

    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource(list);
  };

  const columnsDocumentos = [
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoverDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const addMultipleLinesButtons = [
    { text: 'Adicionar uma Linha', value: 'F' },
    { text: 'Adicionar Lista de Linhas', value: 'V' },
  ];

  useEffect(() => {
    if (gridView && gridView.current) {
      gridView.current.setDataSource(dataMassivo);
    }

    if (dataMassivo.length > 0) {
      setGridHasItem(true);
    } else {
      setGridHasItem(false);
    }
  }, [dataMassivo]);

  useEffect(async () => {
    setLoading(true);

    if (data.nrSeqTipoChamada) {
      const planoRateio = await getPlanoRateio(data.nrSeqTipoChamada);

      if (planoRateio) {
        setHasPlanoRateioDefault(true);

        if (!data.nrSeqPlanoRateioEmpresa) {
          setData({
            ...data,
            planoRateioEmpresa: planoRateio,
            nrSeqPlanoRateioEmpresa: planoRateio?.nrSeqPlano,
          });
        }
      }
    }

    if (data.documentos) {
      if (data.documentos.length > 0) {
        if (gridViewDocumentos && gridViewDocumentos.current) {
          gridViewDocumentos.current.setDataSource(data.documentos);
        }
      }
    }

    setLoading(false);
  }, []);

  const addLinhasContratoMassivo = () => {
    const dataSource = [...dataMassivo];

    if (
      linhasPortadas.length > 0 &&
      data.nrSeqPessoaCli &&
      data.nrSeqOperadoraDestino &&
      data.nrSeqCentroDeCusto &&
      data.nrSeqTipoChamada &&
      data.nrSeqFormaPagamento &&
      data.nrSeqVencimento
    ) {
      const listaLinhas = linhasPortadas
        .replace(/ {2}/g, '\t')
        .replace(/[()\-,. ]/g, '')
        .split(/\r?\n/)
        .map((e) => e.split(/\r?\t| +/))
        .filter((e) => e.length >= 1 && e[0] !== '');

      const linhasValidas = listaLinhas.every(
        (linha) => linha[0].length === 11
      );

      if (linhasValidas) {
        listaLinhas.forEach((line) => {
          const maskedPrefixoLinha = mask(line[0], MaskTypes.CellPhone);

          const linhaExistente = dataSource.find(
            (e) =>
              (e.chip.nrPrefixoLinha === maskedPrefixoLinha ||
                e.chip.nrPrefixoLinha === line[0]) &&
              e.status !== GridView.EnumStatus.Remover
          );

          if (!linhaExistente) {
            const obj = {
              ...data,
              chip: {
                nrPrefixoLinha: maskedPrefixoLinha,
              },
              status: GridView.EnumStatus.Inserir,
            };

            dataSource.push(obj);
          } else {
            onSetMessage(
              false,
              `Linha ${maskedPrefixoLinha} já foi adicionada`
            );
            return;
          }

          setDataMassivo(dataSource);

          if (!gridHasItem) {
            setGridHasItem(true);
          }

          setDataChip({});
          setLinhasPortadas('');
        });
      } else {
        onSetMessage(false, 'Verifique se todas as linhas estão corretas.');
      }
    } else {
      onSetMessage(false, 'Preencha todos os campos obrigatórios');
    }
  };

  const addLinha = () => {
    const dataSource = [...dataMassivo];

    if (
      dataChip.nrSeqChip &&
      data.nrSeqPessoaCli &&
      data.nrSeqOperadoraDestino &&
      data.nrSeqCentroDeCusto &&
      data.nrSeqTipoChamada &&
      data.nrSeqFormaPagamento &&
      data.nrSeqVencimento
    ) {
      const linhaExistente = dataSource.find(
        (e) =>
          (e.chip.nrPrefixoLinha === dataChip.nrPrefixoLinha ||
            e.chip.nrPrefixoLinha.replace(/[()\-,. ]/g, '') ===
              dataChip.nrPrefixoLinha.replace(/[()\-,. ]/g, '')) &&
          e.status !== GridView.EnumStatus.Remover
      );

      if (!linhaExistente) {
        const obj = {
          ...data,
          chip: dataChip.chip,
          status: GridView.EnumStatus.Inserir,
        };

        dataSource.push(obj);

        setDataMassivo(dataSource);

        if (!gridHasItem) {
          setGridHasItem(true);
        }

        setDataChip({});
      } else {
        onSetMessage(false, 'Linha já foi adicionada');
      }
    } else {
      onSetMessage(false, 'Preencha todos os campos obrigatórios');
    }
  };

  return (
    <>
      {clienteMessage.showMessage && (
        <div className='row mb-2'>
          <div className='col'>
            <div className='card border-danger'>
              <div className='card-body text-danger'>
                <p className='card-text'>{clienteMessage.message}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Novo Titular'
            required
            enabled={!gridHasItem}
            searchDataSource={onSearchCliente}
            minLengthTextSearch={3}
            placeholder='Digite ao menos 3 caracteres para buscar Cliente'
            selectedItem={data.cliente}
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transactionToOpen={TransacationCodes.Cliente}
            nrseqTransactionToOpen='nrSeqPessoaCli'
            onSelectItem={(cliente) => {
              data.nrSeqPessoaCli = cliente.nrSeqPessoaCli ?? undefined;
              data.cliente = cliente;

              onSelectCliente(cliente);
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-2'>
          <Autocomplete
            label='Operadora Destino'
            enabled={data.flgPermiteAlterar}
            required
            searchDataSource={onSearchOperadora}
            selectedItem={data.operadoraDestino}
            onSelectItem={(operadoraDestino) =>
              setData({
                ...data,
                operadoraDestino,
                nrSeqOperadoraDestino: operadoraDestino.nrSeqOperadora,
                nrSeqOperadora: operadoraDestino.nrSeqOperadora,
                plano: undefined,
                tipoChamada: undefined,
              })
            }
            dataSourceTextProperty='noOperadora'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Centro de Custo'
            enabled={data.flgPermiteAlterar && !!data.empresa?.nrSeqEmpresa}
            placeholder={!data.empresa?.nrSeqEmpresa && 'Seleciona a Empresa'}
            searchDataSource={onSearchCentroDeCusto}
            selectedItem={data.centroDeCusto}
            onSelectItem={(centroDeCusto) =>
              setData({
                ...data,
                centroDeCusto,
                nrSeqCentroDeCusto: centroDeCusto.nrSeqCentroDeCusto,
              })
            }
            dataSourceTextProperty='noCentroDeCusto'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Plano'
            enabled={
              data.flgPermiteAlterar &&
              !!data.empresa?.nrSeqEmpresa &&
              !!data.operadoraDestino?.nrSeqOperadora
            }
            placeholder={
              !data.operadoraDestino?.nrSeqOperadora &&
              'Selecione a Operadora Destino'
            }
            required
            searchDataSource={onSearchPlanoOperadoraDestino}
            selectedItem={data.plano}
            onSelectItem={(plano) =>
              setData({
                ...data,
                plano,
                nrSeqPlano: plano.nrSeqPlano,
                tipoChamada: {},
                nrSeqTipoChamada: undefined,
              })
            }
            dataSourceTextProperty='noPlano'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Pacote'
            enabled={data.flgPermiteAlterar && !!data.plano?.nrSeqPlano}
            placeholder={!data.plano?.nrSeqPlano && 'Selecione o Plano'}
            required
            searchDataSource={onSearchPacoteDados}
            selectedItem={data.tipoChamada}
            onSelectItem={async (tipoChamada) => {
              if ('nrSeqTipoChamada' in tipoChamada) {
                const planoRateio = await getPlanoRateio(
                  tipoChamada.nrSeqTipoChamada
                );

                if (planoRateio) {
                  setHasPlanoRateioDefault(true);

                  setData({
                    ...data,
                    tipoChamada,
                    nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                    nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                    vlrVenda: tipoChamada.vlrVenda ?? tipoChamada.vlrEncargo,
                    vlrPacoteEncargoManual:
                      tipoChamada.vlrVenda ?? tipoChamada.vlrEncargo,
                    planoRateioEmpresa: planoRateio,
                    nrSeqPlanoRateioEmpresa: planoRateio.nrSeqPlano,
                  });
                } else {
                  setHasPlanoRateioDefault(false);

                  setData({
                    ...data,
                    tipoChamada,
                    nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                    nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                    vlrVenda: tipoChamada.vlrVenda ?? tipoChamada.vlrEncargo,
                    vlrPacoteEncargoManual:
                      tipoChamada.vlrVenda ?? tipoChamada.vlrEncargo,
                  });
                }
              } else {
                setHasPlanoRateioDefault(false);

                setData({
                  ...data,
                  tipoChamada,
                  nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                  nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                  noPacoteDados: tipoChamada.noTipoChamada,
                  vlrVenda: tipoChamada.vlrVenda ?? tipoChamada.vlrEncargo,
                  vlrPacoteEncargoManual:
                    tipoChamada.vlrVenda ?? tipoChamada.vlrEncargo,
                });
              }
            }}
            dataSourceTextProperty='noTipoChamada'
          />
        </div>
        <div className='col-1'>
          <Textbox
            label='Valor Pacote'
            text={data.vlrVenda}
            mask={MaskTypes.Decimal}
            readOnly={!data.tipoChamada?.flgPermiteEditar}
            onChangedValue={(vlrVenda) => {
              setData({
                ...data,
                vlrVenda,
              });
            }}
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Plano de Rateio Empresa'
            searchDataSource={onSearchPlanoRateio}
            tooltip={data.planoRateioEmpresa?.noPlano ?? ''}
            enabled={
              data.flgPermiteAlterar &&
              !!data.empresa?.nrSeqEmpresa &&
              !hasPlanoRateioDefault
            }
            placeholder={!data.empresa?.nrSeqEmpresa && 'Seleciona a Empresa'}
            selectedItem={data.planoRateioEmpresa}
            onSelectItem={(planoRateioEmpresa) => {
              setData({
                ...data,
                planoRateioEmpresa,
                nrSeqPlanoRateioEmpresa: planoRateioEmpresa.nrSeqPlano,
              });
            }}
            dataSourceTextProperty='noPlano'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Forma de Pagamento'
            enabled={data.flgPermiteAlterar}
            required
            searchDataSource={onAutoCompleteFormaPagamento}
            selectedItem={data.formaPagamento}
            onSelectItem={(formaPagamento) => {
              data.formaPagamento = formaPagamento;
              data.nrSeqFormaPagamento =
                formaPagamento.nrSeqFormaPagamento ?? undefined;

              onSelectFormaPagamento(formaPagamento);
            }}
            dataSourceTextProperty='noFormaPagamento'
          />
        </div>
        <div className='col-2'>
          <Autocomplete
            label='Vencimento'
            enabled={data.flgPermiteAlterar && !gridHasItem}
            required
            searchDataSource={onSearchVencimento}
            selectedItem={data.vencimento}
            onSelectItem={(vencimento) =>
              setData({
                ...data,
                vencimento,
                nrSeqVencimento: vencimento.nrSeqVencimento,
              })
            }
            dataSourceTextProperty='noVencimento'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Informação Bancária'
            enabled={data.flgPermiteAlterar && !gridHasItem}
            visible={
              data.nrSeqFormaPagamento !== null &&
              data.nrSeqFormaPagamento !== undefined &&
              data.formaPagamento?.flgGrupo === 'D'
            }
            searchDataSource={onSearchInformacaoBancaria}
            selectedItem={data.informacaoBancaria}
            onSelectItem={(informacaoBancaria) =>
              setData({
                ...data,
                informacaoBancaria,
                nrSeqPessoaInformacaoBancaria:
                  informacaoBancaria.nrSeqPessoaInformacaoBancaria,
              })
            }
            dataSourceTextProperty='noDescricao'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col'>
          <Textarea
            label='Observação'
            maxLength={2000}
            rows={3}
            text={data.noObservacao}
            onChangedValue={(noObservacao) => {
              setData({ ...data, noObservacao });
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-auto'>
          <RadioButton
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={addMultipleLinesButtons}
            selectedButton={addLinhasMassiva}
            onChange={(value) => {
              setAddLinhasMassiva(value);
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Data de Início de Contrato'
            required
            readOnly={!(data.flgPermiteAlterar && !gridHasItem)}
            maxLength={10}
            text={data.dtSolicitacao}
            mask={MaskTypes.Date}
            onChange={(dtSolicitacao) => {
              setData({
                ...data,
                dtSolicitacao,
              });
            }}
          />
        </div>
        {'cycle' in contratoMessage && dataChip.nrSeqChip !== undefined && (
          <div className='col'>
            <div className={`card border-${contratoMessage.color}`}>
              <div className={`card-body text-${contratoMessage.color}`}>
                <p className='card-text mb-0'>{contratoMessage.cycle}</p>
                {contratoMessage.message.length > 0 && (
                  <p className='card-text'>{contratoMessage.message}</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {addLinhasMassiva === 'V' ? (
        <div className='row'>
          <div className='col'>
            <Textarea
              label='N° Linhas '
              maxLength={2000}
              rows={3}
              placeholder='Insira a lista com n° das linhas'
              required
              readOnly={!data.flgPermiteAlterar}
              text={linhasPortadas}
              onChangedValue={(linhas) => setLinhasPortadas(linhas)}
            />
          </div>
          <div className='col-2'>
            <Button
              outline
              visible={data.flgPermiteAlterar}
              icon='plus'
              className='mt-4'
              size={BootstrapSizes.Medium}
              theme={Theme.Success}
              onClick={addLinhasContratoMassivo}
              text='Adicionar Linhas'
            />
          </div>
        </div>
      ) : (
        <div className='row'>
          <div className='col-3'>
            <Autocomplete
              label='Nr Linha'
              enabled={
                data.flgPermiteAlterar &&
                !!data.operadoraDestino?.nrSeqOperadora
              }
              placeholder={
                !data.operadoraDestino?.nrSeqOperadora &&
                'Selecione a Operadora Destino'
              }
              searchDataSource={onSearchChip}
              selectedItem={dataChip.chip}
              onSelectItem={(chip) => {
                setDataChip({
                  ...dataChip,
                  chip,
                  nrSeqChip: chip.nrSeqChip,
                  nrLinha: chip.nrLinha,
                  nrPrefixo: chip.nrPrefixo,
                  nrPrefixoLinha: chip.linhaFormatada,
                });
              }}
              dataSourceTextProperty='linhaFormatada'
            />
          </div>
          <div className='col-2'>
            <Button
              outline
              visible={data.flgPermiteAlterar}
              icon='plus'
              className='mt-4'
              size={BootstrapSizes.Medium}
              theme={Theme.Success}
              onClick={addLinha}
              text='Adicionar Linha'
            />
          </div>
        </div>
      )}
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
        <div className='row MB-3'>
          <div className='col'>
            <p>
              Foram encontrados {gridView?.current?.getDataSource().length}{' '}
              registros
            </p>
          </div>
        </div>
      </div>
      <div className='col'>
        <div className='row mt-4 mb-3'>
          <div className='col-6'>
            <FileUpload
              files={importedFile}
              onChange={(files) => {
                if (files.length > 0) {
                  if (files[0].size <= 5000000) {
                    setImportedFile(files);
                  } else {
                    onSetMessage(false, 'Arquivo não pode ser maior que 5MB');
                  }
                } else {
                  setImportedFile(files);
                }
              }}
              allowedMimeTypes={[
                MimeTypes.Types.PDF,
                MimeTypes.Types.Image,
                MimeTypes.Types.Word,
              ]}
            />
          </div>
          <div className='col d-flex justify-content-center'>
            <Button
              text='Adicionar Documento'
              className='px-5'
              theme={Theme.Success}
              size={BootstrapSizes.Medium}
              template={Button.Templates.Button}
              onClick={() => onClickAddDocumento()}
            />
          </div>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridViewDocumentos}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columnsDocumentos}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
    </>
  );
}
