import React, { useRef, useState, useEffect } from 'react';
import { setTransaction } from 'core/services/api';

import {
  Autocomplete,
  Button,
  DatePicker,
  GridView,
  Modal,
  Notification,
  Switch,
  Textbox,
} from 'ui/components';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN';
import { savePagamento } from 'core/services/FIN/pagamento';

export default function ModalPagamentoMassivo(props) {
  const [data, setData] = useState();
  const [somado, setSomado] = useState();
  const [loading, setLoading] = useState(false);
  const [messageState, setMessage] = useState(null);
  const gridView = useRef();
  const gridView2 = useRef();

  const { show, pagamentoMassivo, transaction } = props;

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  useEffect(() => {
    if (data?.pagarParcial === true) {
      gridView?.current?.setDataSource(null);
      gridView2?.current?.setDataSource(pagamentoMassivo);
    } else {
      gridView?.current?.setDataSource(pagamentoMassivo);
      gridView2?.current?.setDataSource(null);
    }
  }, [data?.pagarParcial]);

  useEffect(() => {
    if (pagamentoMassivo !== undefined) {
      let sum = 0;
      for (let i = 0; i < pagamentoMassivo.length; ) {
        sum += pagamentoMassivo[i].vlrSaldoTit;
        pagamentoMassivo[i].vlrPagoTotal = pagamentoMassivo[i].vlrSaldoTit;
        i += 1;
      }
      setData({
        ...data,
        pagarParcial: false,
        vlrTotal: sum,
        formaPagamento: pagamentoMassivo[0].formaPagamento,
        nrSeqFormaPagamento: pagamentoMassivo[0].nrSeqFormaPagamento,
        dtPagamento: pagamentoMassivo[0].dtVencimento,
      });
      setSomado({ vlrSomadoAPagar: sum });
    }
    gridView?.current?.setDataSource(pagamentoMassivo);
  }, [show]);

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitPag: true,
      nrSeqEmpresa: pagamentoMassivo[0].nrSeqEmpresa,
    });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const salvar = async () => {
    let lista = [];
    if (data.pagarParcial === true) {
      lista = gridView2.current ? gridView2.current.getDataSource() : [];
    } else {
      lista = gridView.current ? gridView.current.getDataSource() : [];
    }
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);
    const obj = {
      ...data,
      nrSeqTransacao: transaction.nrSeqTransacao,
      pagamentoMassivo: lista,
    };
    const { status, message: msg } = await savePagamento(obj);

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
    if (status === ResponseStatus.Success) {
      props.onClose({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
  };

  const cancelar = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
    });
  };

  const columns = [
    {
      key: 'nrSeqTituloPagar',
      visible: false,
    },
    {
      key: 'nrTit',
      title: 'Nr Tit',
    },
    {
      key: 'nrOrdem',
      title: 'Nr Ordem',
    },
    {
      key: 'fornecedor.noFornecedor',
      title: 'Fornecedor',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor Do Titulo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrPago',
      title: 'Valor Pago',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrSaldoTit',
      title: 'Valor Saldo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'dtVencimento',
      title: 'Data Vencimento',
      format: GridView.DataTypes.Date,
    },
  ];

  const vlrSomaTotalPago = async (datasource) => {
    let vlrSomadoPlanoConta = 0;

    if (datasource !== null && datasource !== undefined) {
      datasource.forEach((item) => {
        if (item.vlrPagoTotal > 0) {
          vlrSomadoPlanoConta += item.vlrPagoTotal;
        } else {
          item.vlrPagoTotal = 0;
        }
        if (item.vlrMulta > 0) {
          vlrSomadoPlanoConta += item.vlrMulta;
        } else {
          item.vlrMulta = 0;
        }
        if (item.vlrJuroDia > 0) {
          vlrSomadoPlanoConta += item.vlrJuroDia;
        } else {
          item.vlrJuroDia = 0;
        }
        if (item.vlrDescontoCalculado > 0) {
          vlrSomadoPlanoConta -= item.vlrDescontoCalculado;
        } else {
          item.vlrDescontoCalculado = 0;
        }
      });
    }

    setSomado({ vlrSomadoAPagar: vlrSomadoPlanoConta });
  };

  const savePagar = async (keyValue, value) => {
    if (value === undefined || value === null) {
      value = 0;
    }

    const datasource = gridView2.current
      ? gridView2.current.getDataSource()
      : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTituloPagar === keyValue
    );
    itemParcela.vlrPagoTotal = value;

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].nrSeqTituloPagar === itemParcela.nrSeqTituloPagar) {
        datasource[i].vlrPagoTotal = itemParcela.vlrPagoTotal;
      }
      i += 1;
    }

    vlrSomaTotalPago(datasource);

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(datasource);
  };
  const saveMulta = async (keyValue, value) => {
    if (value === undefined || value === null) {
      value = 0;
    }

    const datasource = gridView2.current
      ? gridView2.current.getDataSource()
      : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTituloPagar === keyValue
    );
    itemParcela.vlrMulta = value;

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].nrSeqTituloPagar === itemParcela.nrSeqTituloPagar) {
        datasource[i].vlrMulta = itemParcela.vlrMulta;
      }
      i += 1;
    }

    vlrSomaTotalPago(datasource);

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(datasource);
  };
  const saveJuro = async (keyValue, value) => {
    if (value === undefined || value === null) {
      value = 0;
    }

    const datasource = gridView2.current
      ? gridView2.current.getDataSource()
      : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTituloPagar === keyValue
    );
    itemParcela.vlrJuroDia = value;

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].nrSeqTituloPagar === itemParcela.nrSeqTituloPagar) {
        datasource[i].vlrJuroDia = itemParcela.vlrJuroDia;
      }
      i += 1;
    }

    vlrSomaTotalPago(datasource);

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(datasource);
  };
  const saveDesconto = async (keyValue, value) => {
    if (value === undefined || value === null) {
      value = 0;
    }

    const datasource = gridView2.current
      ? gridView2.current.getDataSource()
      : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTituloPagar === keyValue
    );
    itemParcela.vlrDescontoCalculado = value;

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].nrSeqTituloPagar === itemParcela.nrSeqTituloPagar) {
        datasource[i].vlrDescontoCalculado = itemParcela.vlrDescontoCalculado;
      }
      i += 1;
    }

    vlrSomaTotalPago(datasource);

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(datasource);
  };

  const fsavePagar = function (keyValue, value) {
    savePagar(keyValue, value);
  };
  const fsaveMulta = function (keyValue, value) {
    saveMulta(keyValue, value);
  };
  const fsaveJuro = function (keyValue, value) {
    saveJuro(keyValue, value);
  };
  const fsaveDesconto = function (keyValue, value) {
    saveDesconto(keyValue, value);
  };

  const columns2 = [
    {
      key: 'nrSeqTituloPagar',
      visible: false,
    },
    {
      key: 'nrTit',
      title: 'Nr Tit',
    },
    {
      key: 'dtVencimento',
      title: 'Data Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'fornecedor.noFornecedor',
      title: 'Fornecedor',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor Do Titulo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrSaldoTit',
      title: 'Valor Saldo',
      format: GridView.DataTypes.Decimal,
    },

    {
      key: 'vlrPagoTotal',
      title: 'Vlr a pagar',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fsavePagar,
      maxLength: 10,
    },
    {
      key: 'vlrMulta',
      title: 'Vlr Multa',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fsaveMulta,
      maxLength: 10,
    },
    {
      key: 'vlrJuroDia',
      title: 'Vlr Juro',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fsaveJuro,
      maxLength: 10,
    },
    {
      key: 'vlrDescontoCalculado',
      title: 'Vlr desconto',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fsaveDesconto,
      maxLength: 10,
    },
  ];

  return (
    <Modal
      loading={loading}
      show={show}
      title='Pagamento Massivo'
      size={BootstrapSizes.Large}
      icon='money'
    >
      <Modal.Body className='p-4 pt-3'>
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        {!data?.pagarParcial && (
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        )}
        {data?.pagarParcial && (
          <div className='col'>
            <GridView
              ref={gridView2}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns2}
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        )}
        <div className='row'>
          <div className='col-3 mb-3'>
            <Switch
              formControl
              label='Pagar Parcial'
              checked={data?.pagarParcial}
              onChange={(pagarParcial) => setData({ ...data, pagarParcial })}
            />
          </div>
          <div className='col-3 mb-3'>
            <Textbox
              label='Valor Total'
              readOnly
              text={data?.vlrTotal}
              mask={MaskTypes.DecimalCustom}
              onChangedValue={(vlrTotal) => setData({ ...data, vlrTotal })}
            />
          </div>
          <div className='col-3 mb-3'>
            <DatePicker
              label='Dt Pagamento'
              text={data?.dtPagamento}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtPagamento) => setData({ ...data, dtPagamento })}
            />
          </div>
          <div className='col-3 mb-3'>
            <Autocomplete
              label='Forma Pagamento'
              searchDataSource={onSearchFormaPagamento}
              required
              selectedItem={data?.formaPagamento}
              onSelectItem={(formaPagamento) => {
                setData({
                  ...data,
                  formaPagamento,
                  nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                });
              }}
              dataSourceTextProperty='noFormaPagamento'
            />
          </div>
        </div>
        {data?.pagarParcial && (
          <div className='row'>
            <div className='col-4 mb-3'>
              <Textbox
                label='Valor somado a pagar'
                readOnly
                text={somado?.vlrSomadoAPagar}
                mask={MaskTypes.DecimalCustom}
              />
            </div>
          </div>
        )}
        <div className='row'>
          {data?.formaPagamento?.flgGrupo === 'C' && (
            <div className='col-6 mb-3'>
              <DatePicker
                label='Data Cheque'
                required
                text={data?.dtCheque}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtCheque) => setData({ ...data, dtCheque })}
              />
            </div>
          )}
          {data?.formaPagamento?.flgGrupo === 'C' &&
            !data?.formaPagamento?.flgControlaCheque && (
              <div className='col-6 mb-3'>
                <Textbox
                  label='Nr. Cheque'
                  text={data?.nrCheque}
                  onChangedValue={(nrCheque) => setData({ ...data, nrCheque })}
                />
              </div>
            )}
        </div>
        <div className='row mb-3'>
          {!data?.pagarParcial && (
            <div className='col-4 mb-3'>
              <Textbox
                label='Valor Multa'
                text={data?.vlrMulta}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrMulta) => setData({ ...data, vlrMulta })}
              />
            </div>
          )}
          {!data?.pagarParcial && (
            <div className='col-4 mb-3'>
              <Textbox
                label='Valor Juros'
                text={data?.vlrJuro}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrJuro) => setData({ ...data, vlrJuro })}
              />
            </div>
          )}
          {!data?.pagarParcial && (
            <div className='col-4 mb-3'>
              <Textbox
                label='Valor Desconto'
                text={data?.vlrDesconto}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrDesconto) =>
                  setData({ ...data, vlrDesconto })
                }
              />
            </div>
          )}
        </div>

        <div className='row'>
          <div className='col-4 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Pagar Titulo'
              onClick={salvar}
            />
          </div>
          <div className='col-2 mt-3'>
            <Button
              theme={Theme.Danger}
              template={Button.Templates.Button}
              text='Cancelar'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
