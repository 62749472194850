import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  JustifyContent,
  MimeTypes,
  BootstrapSizes,
} from 'ui/Helpers/utils';

import {
  CSDManutPage,
  Autocomplete,
  DatePicker,
  Panel,
  OldPage,
  Textbox,
  Button,
  FileUpload,
  GridView,
  ToolbarButtons,
  Switch,
  Textarea,
  DropdownList,
  RadioButton,
} from 'ui/components';

import { MaskTypes } from 'ui/Helpers/masks';

import {
  geraTT,
  getDemanda,
  getDemandaList,
  getScheduledDate,
  getTipoDemandaResumida,
  getTipoDemandaAutoComplete,
  postGeraContrato,
  postGeraContratoMassivo,
  postPortability,
  postSolicitaMVNO,
  saveDemanda,
  saveDemandaMassiva,
  getDemandaPaginedWithTeleCall,
  refletirDemanda,
} from 'core/services/HELP';
import { getEmpresasUsuario } from 'core/services/SEG';

import { TransacationCodes } from 'ui/Helpers/transactionCodes';

import {
  SearchClienteFromChip,
  getClienteAutoCompleteDemanda,
} from 'core/services/FIN/cliente';
import {
  getChipAutoCompleteMVNO,
  getChipAutoCompleteChipDemanda,
  getOperadoraAutoComplete,
  getClienteLinhasByCliente,
  getUltimoCicloLinha,
  getCentroDeCustoAutoCompleteSimples,
  getChipAutoComplete,
  getAparelhoAutoCompleteForDemanda,
  getTipoChamadaAutoCompleteResumido,
} from 'core/services/TEL';
import { getPlanoAutoCompleteDemanda } from 'core/services/TEL/plano';

import { getPlanoVinculoCentrodeCustoEmpAutoComplete } from 'core/services/TEL/planoVinculoCentrodeCustoEmp';
import { getFormaPagamentoPdvAutoComplete } from 'core/services/FIN';
import { getVencimentoAutoComplete } from 'core/services/ASS/vencimento';
import { getInformacaoBancariaAutoCompleteFiltraFormaPagamento } from 'core/services/FIN/informacaoBancaria';

import DemandaIteracao from 'core/models/HELP/demandaIteracao';
import DemandaDocumento from 'core/models/HELP/demandaDocumento';
import {
  getDemandaDocumentoList,
  uploadDocumento,
  uploadDocumentos,
} from 'core/services/HELP/demandaDocumento';
import { getDemandaIteracaoPagined } from 'core/services/HELP/demandaIteracao';
import { Endereco } from 'core/models/SEG';
import { BuscarTitulosVencidosDoCliente } from 'core/services/FIN/tituloReceber';
import { getPlanoModeloAparelhoAutoCompleteForDemanda } from 'core/services/TEL/planoModeloAparelho';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import { Chip } from 'core/models/TEL';

import ModalAgendaPortabilidade from './modalAgendaPortabilidade';
import ModalMsgEnvioOperadora from './modalMsgEnvioOperadora';
import ModalAlterarFormaPagamento from './modalAlterarFormaPagamento';
import ManutencaoDefault from './ManutencaoByTipoDemanda/default';
import ManutencaoNovoContratoMassivo from './ManutencaoByTipoDemanda/novoContratoMassivo';
import ManutencaoPortabilidadeMassivo from './ManutencaoByTipoDemanda/portabilidadeMassiva';
import ManutencaoTTMassivo from './ManutencaoByTipoDemanda/ttMassiva';
import ManutencaoRevertePortabilidade from './ManutencaoByTipoDemanda/revertePortabilidade';
import ManutencaoAlteracaoContratoMassivo from './ManutencaoByTipoDemanda/alteracaoContratoMassivo';
import ModalDevolverDemanda from './modalDevolverDemanda';
import ManutencaoAlteracaoContrato from './ManutencaoByTipoDemanda/alteracaoContrato';
import ModalContratoClickSign from './modalDocumentoClickSign';
import ManutencaoTT from './ManutencaoByTipoDemanda/ttMesmaOperadora';
import ManutencaoSolicitacaoChip from './ManutencaoByTipoDemanda/solicitacaoChipVirgem';
import ManutencaoTrocaTitularidade from './ManutencaoByTipoDemanda/trocaTitularidade';
import ManutencaoRetencao from './ManutencaoByTipoDemanda/retencao';
import ManutencaoSolicitacaoLinha from './ManutencaoByTipoDemanda/solicitacaoLinha.tsx';
import ModalConfirmarCancelamento from './modalConfirmarCancelamento';
import ManutencaoReciclarChip from './ManutencaoByTipoDemanda/reciclarChip.tsx';
import ManutencaoAdicionarPacoteDeDadosAdicional from './ManutencaoByTipoDemanda/adicionarPacoteDeDadosAdicional.tsx';
import ImportadorDocumentosDemanda from './importadorDocumentosDemanda';
import ManutencaoVerificarConsumoMVNO from './ManutencaoByTipoDemanda/verificarConsumo.tsx';

const Titular = [
  {
    text: 'Mesmo Titular',
    value: 'S',
  },
  {
    text: 'Portabilidade com Transferência de Titularidade',
    value: 'N',
  },
];

export default function DemandaItem({
  onOpenReport,
  transaction,
  registryKey,
  reload,
  onSelectPage,
  isActive,
  onOpenTransaction,
  findTransaction,
}) {
  const formSubmit = useRef();
  const gridView = useRef();
  const gridViewIteracao = useRef();
  const [message, setMessage] = useState(null);
  const [data, setData] = useState({
    dtCad: new Date().toJSON().slice(0, 10),
    noObservacao: '',
  });
  const [loading, setLoading] = useState(false);
  const [resumo, setResumo] = useState({});
  const [importedFile, setImportedFile] = useState([]);
  const [importedFiles, setImportedFiles] = useState([]);
  const [endereco, setEndereco] = useState();
  const [enderecoTitularOld, setEnderecoTitularOld] = useState();
  const [dataChip, setDataChip] = useState({});
  const [dataMassivo, setDataMassivo] = useState([]);
  const [modalPortabilidadeShow, setModalPortabilidadeShow] = useState(false);
  const [modalMsgEnvioOperadoraShow, setModalMsgEnvioOperadoraShow] = useState(
    false
  );
  const [modalAlterarFormaPagamento, setModalAlterarFormaPagamento] = useState({
    show: false,
  });
  const [showModalDevolverDemanda, setShowModalDevolverDemanda] = useState(
    false
  );
  const [gridHasItem, setGridHasItem] = useState(false);
  const [hasPlanoRateioDefault, setHasPlanoRateioDefault] = useState(false);
  const [dropDownHorariosSource, setDropDownHorariosSource] = useState([]);
  const [dataListDocRemove, setDataListDocRemove] = useState([]);
  const [dataListIteracao, setDataListIteracao] = useState({ iteracoes: [] });
  const [documentos, setDocumentos] = useState([]);
  const [exibeBotaoEnvioOperadora, setExibeBotaoEnvioOperadora] = useState(
    false
  );
  const [dataTitular, setDataTitular] = useState({ flgMesmoTitular: 'S' });
  const [trocarProvisorio, setTrocarProvisorio] = useState(false);
  const [infoPagamentoDefault, setInfoPagamentoDefault] = useState({});
  const [contratoMessage, setContratoMessage] = useState({ message: 'texto' });
  const [clienteMessage, setClienteMessage] = useState({
    showMessage: false,
    message: '',
  });
  const [darEntrada, setDarEntrada] = useState(false);
  const [tituloReceber, setTituloReceber] = useState({});
  const [
    showModalGeraContratoClickSign,
    setShowModalGeraContratoClickSign,
  ] = useState({ show: false, docType: '' });
  const [
    showModalConfirmarCancelamento,
    setShowModalConfirmarCancelamento,
  ] = useState(false);

  let voltarStatusDemanda = false;

  const somenteleitura = true;

  const agendarportabilidade = false;

  const onSetMessage = (status, messageShow) => {
    if (messageShow)
      setMessage({
        message: messageShow,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onNew = () => {
    const today = new Date().toJSON().slice(0, 10);

    setData({ dtCad: today, flgPossuiDocumentoAnexo: false, noObservacao: '' });
    setDataChip({});
    setEndereco({});
    setEnderecoTitularOld({});
    setDataListIteracao({ iteracoes: [] });
    setImportedFile([]);
    setImportedFiles([]);
    setDataTitular({ flgMesmoTitular: 'S' });
    setContratoMessage({ showMessage: false });
    setClienteMessage({ showMessage: false, message: '' });
    setGridHasItem(false);
    setHasPlanoRateioDefault(false);
    setDataMassivo([]);
    // setEmpresaData({});

    voltarStatusDemanda = false;

    if (gridViewIteracao && gridViewIteracao.current)
      gridViewIteracao.current.setDataSource([]);

    setMessage(null);
  };

  const searchHorariosPortabilidade = async (e) => {
    if (e || data.dtPortabilidade) {
      setLoading(true);
      const { status, message: msg, agenda } = await getScheduledDate({
        dtPortabilidade: data?.dtPortabilidade ?? e,
      });
      if (status === ResponseStatus.Error) {
        onSetMessage(status, msg);
        await getScheduledDate({});
        setDropDownHorariosSource([]);
      } else {
        setDropDownHorariosSource(agenda.horarios);
      }
    }

    setLoading(false);
  };

  const onClickAddDocumento = async () => {
    if (importedFiles.length > 0) {
      const documento = {
        ...importedFiles[0],
        noArquivo: importedFiles[0].name,
        noTipoArquivo: importedFiles[0].type,
        noTamanho: importedFiles[0].size,
        flgExibeDownload: false,
        status: GridView.EnumStatus.Inserir,
      };

      const dataSource = gridView?.current?.getDataSource() ?? [];

      dataSource.push(documento);

      if (gridView && gridView.current) {
        gridView.current.setDataSource(dataSource);
      }

      setDocumentos([...documentos, importedFiles[0]]);

      setImportedFiles([]);
    }
  };

  const onClickDownload = async (e) => {
    const downloadLink = document.createElement('a');

    if (e.noImagem !== null && e.noImagem !== '') {
      const linkSource = `data:image/png;base64, ${e.noImagem}`;
      downloadLink.href = linkSource;
      downloadLink.download = e.noArquivo;
    } else if (e.linkDownload !== null && e.linkDownload !== '') {
      downloadLink.href = e.linkDownload;
      downloadLink.target = '_blank';
    }

    downloadLink.click();
  };

  const onRemoveDocumento = async (e, datasource) => {
    const dataDocs = datasource.filter((doc) => doc !== e);

    const lista = dataListDocRemove;
    if (e.nrSeqDemandaDocumento) lista.push(e.nrSeqDemandaDocumento);

    setDataListDocRemove(lista);

    if (gridView && gridView.current) gridView.current.setDataSource(dataDocs);
  };

  const columns = [
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onClickRemoveIteracao = async (e) => {
    let list = gridViewIteracao.current.getDataSource();

    list = list.filter((el) => el !== e);

    if (gridViewIteracao && gridViewIteracao.current)
      gridViewIteracao.current.setDataSource(list);

    setDataListIteracao({ ...dataListIteracao, iteracoes: list });
  };

  const columnsIteracao = [
    {
      key: 'nrSeqDemandaIteracao',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'noIteracao', title: 'Iteração' },
    { key: 'nrSeqDemanda', title: 'NrSeqDemanda', visible: false },
    { key: 'noStatusDemanda', title: 'Status' },
    { key: 'nrSeqUsuario', title: 'NrSeqUsuario', visible: false },
    {
      key: 'dtIteracao',
      title: 'Data Iteração',
      format: GridView.DataTypes.Date,
    },
    { key: 'flgVisualizado', title: 'FlgVisualizado', visible: false },
    { key: 'nrProcedimento', title: 'NrProcedimento', visible: false },
    { key: 'usuario.noLogin', title: 'Usuário', sortKey: 'noLogin' },
    {
      key: 'nrSeqDemandaIteracao',
      type: GridView.ColumnTypes.Button,
      onClick: (e, dataSource) => onClickRemoveIteracao(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
      visibleDynamic: 'showRemoveButton',
    },
  ];

  const onSearchPacoteDados = async (e) => {
    if (data.nrSeqPlano === undefined || data.nrSeqPlano === 0) {
      onSetMessage(ResponseStatus.Error, 'Selecione o Plano.');
      return [];
    }

    if (!data.nrSeqEmpresa) {
      onSetMessage(ResponseStatus.Error, 'Selecione a Empresa');

      return [];
    }
    if (data.nrSeqCentroDeCusto) {
      const {
        planovinculo,
      } = await getPlanoVinculoCentrodeCustoEmpAutoComplete({
        nrSeqPlano: data.plano?.nrSeqPlano,
        nrSeqOperadora: data.nrSeqOperadoraDestino,
        nrSeqEmpresa: data.nrSeqEmpresa,
        nrSeqCentroDeCusto: data.nrSeqCentroDeCusto,
        noTipoChamada: e,
      });
      if (planovinculo.length === 0) {
        onSetMessage(
          ResponseStatus.Error,
          'Centro de Custo sem pacote vinculado nessa Empresa.'
        );
      }

      return planovinculo;
    }

    const { tiposChamadas } = await getTipoChamadaAutoCompleteResumido({
      nrSeqPlano: data.plano?.nrSeqPlano,
      nrSeqOperadora: data.nrSeqOperadoraDestino,
      flgBuscaEncargo: true,
      noTipoChamada: e,
    });

    if (tiposChamadas.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Nenhum pacote de dados encontrado para esse plano'
      );
    }

    return tiposChamadas;
  };

  const onSearchAparelho = async (e) => {
    const {
      status,
      message: msg,
      aparelhos,
    } = await getAparelhoAutoCompleteForDemanda({
      nrSeqPlano: data.nrSeqPlano,
      nrSeqModeloAparelho: data.nrSeqModeloAparelho,
      nrImei: e,
    });

    onSetMessage(status, msg);
    return aparelhos;
  };

  const onSearchPlanoModeloAparelho = async (e) => {
    const {
      status,
      message: msg,
      value,
    } = await getPlanoModeloAparelhoAutoCompleteForDemanda({
      nrSeqPlano: data.nrSeqPlano,
      noModeloAparelho: e,
      nrSeqTipoChamandaMin: data.nrSeqTipoChamada,
    });

    onSetMessage(status, msg);

    return value;
  };

  const onSearchCliente = async (e) => {
    const { clientes } = await getClienteAutoCompleteDemanda({
      noPessoa: e,
      flgIncluirEndereco: true,
      flgFiltrarEmpresa: true,
    });
    return clientes;
  };

  const onSearchChip = async (e) => {
    if (data.tipoDemanda?.flgTrocaNumero) {
      const { status, message: msg, chips } = await getChipAutoComplete({
        NrPrefixoLinha: e,
        nrSeqEmpresa: data.nrSeqEmpresa,
      });

      if (status === ResponseStatus.Success) {
        onSetMessage(status, msg);
      }

      return chips;
    }

    let retorno = {};

    if (data.nrSeqOperadora === 20) {
      if (dataChip?.nrPrefixo === undefined || dataChip?.nrPrefixo === '') {
        onSetMessage(1, 'Informe o Prefixo.');
        return [];
      }

      retorno = await getChipAutoCompleteMVNO({
        nrSeqOperadora: data.nrSeqOperadora,
        nrLinha: e,
        nrSeqEmpresa: data.nrSeqEmpresa,
        nrPrefixo: dataChip.nrPrefixo,
        flgNumeroEspecial: dataChip.flgNumeroEspecial,
      });
    } else {
      retorno = await getChipAutoCompleteChipDemanda({
        nrSeqOperadora: data.nrSeqOperadora,
        nrLinha: e,
        nrSeqEmpresa: data.nrSeqEmpresa,
        nrPrefixo: dataChip.nrPrefixo,
        nrSeqCentroDeCusto: data.nrSeqCentroDeCusto,
      });
    }

    onSetMessage(retorno.status, retorno.message);

    return retorno.chips;
  };

  const getSuggestedDate = (cycleStart) => {
    const today = new Date();

    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();

    const cycleStartDay = cycleStart.getDate();

    let currentCycleStart;
    let nextCycleStart;

    if (today.getDate() < cycleStartDay) {
      currentCycleStart = new Date(
        currentYear,
        currentMonth - 1,
        cycleStartDay
      );

      nextCycleStart = new Date(currentYear, currentMonth, cycleStartDay);
    } else {
      currentCycleStart = new Date(currentYear, currentMonth, cycleStartDay);

      nextCycleStart = new Date(currentYear, currentMonth + 1, cycleStartDay);
    }

    const diffToCurrentCycle = Math.abs(today - currentCycleStart);
    const diffToNextCycle = Math.abs(today - nextCycleStart);

    if (diffToCurrentCycle <= diffToNextCycle) {
      return currentCycleStart;
    }

    return nextCycleStart;
  };

  const onSelectChip = async (
    chip,
    getClienteFromChip = false,
    atualizaData = true,
    atualizaDtSolicitacao = true
  ) => {
    setLoading(true);

    let objData = {
      chip,
      nrSeqChip: chip.nrSeqChip,
    };

    if ('nrSeqChip' in chip) {
      if (getClienteFromChip) {
        const cliente = await SearchClienteFromChip(chip.nrSeqChip);

        objData.cliente = cliente;
        objData.nrSeqPessoaCli = cliente.nrSeqPessoaCli;

        if (cliente.nrSeqEmpresa) {
          objData.empresa = cliente.empresa;
          objData.nrSeqEmpresa = cliente.nrSeqEmpresa;
        }
      }

      const lastCycle = await getUltimoCicloLinha(chip.nrSeqChip);

      if (lastCycle) {
        const dtPerInicial = new Date(lastCycle.dtPerInicial);
        const dtPerFinal = new Date(lastCycle.dtPerFinal);

        const twoMonthsAgo = new Date();
        twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);

        if (dtPerFinal <= twoMonthsAgo) {
          setContratoMessage({
            cycle: `Último ciclo importado: ${
              dtPerInicial.toLocaleString('pt-BR').split(',')[0]
            } - ${dtPerFinal.toLocaleString('pt-BR').split(',')[0]}`,
            message: `Essa linha não possui um ciclo importado recente, verifique se a linha não esta cancelada antes de prosseguir `,
            color: 'danger',
          });
        } else {
          setContratoMessage({
            cycle: `Último ciclo importado: ${
              dtPerInicial.toLocaleString('pt-BR').split(',')[0]
            } - ${dtPerFinal.toLocaleString('pt-BR').split(',')[0]}`,
            message: ``,
            color: 'primary',
          });
        }

        if (atualizaDtSolicitacao) {
          const suggestedDate = getSuggestedDate(dtPerInicial);

          objData.dtSolicitacao = suggestedDate.toJSON().slice(0, 10);
        }
      } else {
        setContratoMessage({
          cycle: 'Linha não possui um ciclo anterior',
          message: '',
          color: 'primary',
        });

        const today = new Date();

        objData.dtSolicitacao = today.toJSON().slice(0, 10);
      }

      if (atualizaData) {
        setData({ ...data, ...objData });
      }

      setDataChip({
        ...dataChip,
        chip,
        nrSeqChip: chip.nrSeqChip,
        nrLinha: chip.nrLinha,
        nrPrefixo: chip.nrPrefixo,
        nrPrefixoLinha: chip.nrPrefixoLinha,
        linhaFormatada: chip.linhaFormatada,
        nrSeqOperadora: chip.nrSeqOperadora,
        operadora: chip.operadora,
      });

      if (getClienteFromChip && data.tipoDemanda?.flgTrocaNumero) {
        setEndereco(objData.cliente?.endereco);
      }
    } else {
      setContratoMessage({});

      objData = { ...objData, chip: undefined, nrSeqChip: undefined };

      if (getClienteFromChip) {
        objData.cliente = undefined;
        objData.nrSeqPessoaCli = undefined;

        if (data.tipoDemanda?.flgTrocaNumero) {
          setEndereco({});
        }
      }

      if (atualizaData) {
        setData({ ...data, ...objData });
      }

      setDataChip({
        ...dataChip,
        chip: undefined,
        nrSeqChip: undefined,
        nrLinha: undefined,
        nrPrefixo: undefined,
        nrPrefixoLinha: undefined,
      });
    }

    setLoading(false);

    return objData;
  };

  const onSearchPlano = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoCompleteDemanda({
      noPlano: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
      nrSeqOperadora: data.nrSeqOperadora,
      flgAgrupado: true,
    });
    if (planos.length === 0)
      onSetMessage(ResponseStatus.Error, 'Nenhum plano vinculado a empresa.');

    onSetMessage(status, msg);

    return planos;
  };

  const onSearchPlanoRateio = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoCompleteDemanda({
      noPlano: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
      flgAtivo: true,
      flgPlanoRateioEmpresa: true,
    });

    if (planos.length === 0)
      onSetMessage(ResponseStatus.Error, 'Nenhum plano vinculado a empresa.');

    onSetMessage(status, msg);

    return planos;
  };

  const getPlanoRateio = async (nrSeqTipoChamada) => {
    if (!data.nrSeqEmpresa) {
      onSetMessage(ResponseStatus.Error, 'Informe a Empresa');

      return undefined;
    }

    const { planos } = await getPlanoAutoCompleteDemanda({
      nrSeqTipoChamada,
      nrSeqEmpresa: data.nrSeqEmpresa,
      flgAtivo: true,
      flgPlanoRateioEmpresa: true,
    });

    return planos.length > 0 ? planos[0] : undefined;
  };

  const onSearchPlanoOperadoraDestivo = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoCompleteDemanda({
      noPlano: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
      nrSeqOperadora: data.operadoraDestino.nrSeqOperadora,
      flgAgrupado: true,
    });

    if (planos.length === 0)
      onSetMessage(ResponseStatus.Error, 'Nenhum plano vinculado a empresa.');

    onSetMessage(status, msg);

    return planos;
  };

  const onSearchCentroDeCusto = async (e) => {
    if (!data.nrSeqEmpresa) {
      onSetMessage(ResponseStatus.Error, 'Informe a Empresa');

      return [];
    }

    const {
      status,
      message: msg,
      data: centros,
    } = await getCentroDeCustoAutoCompleteSimples({
      codNoCentroDeCusto: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    onSetMessage(status, msg);

    return centros;
  };

  const onSearchOperadora = async (e) => {
    const {
      status,
      message: msg,
      operadoras,
    } = await getOperadoraAutoComplete({ noOperadora: e });

    if (status === ResponseStatus.Error) {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      return [];
    }

    const result = operadoras.filter(
      (operadora) => !operadora.noOperadora.includes('Importação Manual')
    );

    // Não fazer Novo Contrato Massivo para MVNO
    if (data.tipoDemanda?.cdTipoDemanda === 'Novo Contrato Massivo') {
      return result.filter(
        (operadora) => !operadora.noOperadora.includes('MVNO')
      );
    }

    return result;
  };

  const onAutoCompleteFormaPagamento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoPdvAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
    });

    return formasPagamento;
  };

  const onSearchVencimento = async (e) => {
    const {
      status,
      message: msg,
      vencimentos,
    } = await getVencimentoAutoComplete({
      noVencimento: e,
    });
    onSetMessage(status, msg);
    return vencimentos;
  };

  const onSearchInformacaoBancaria = async () => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoCompleteFiltraFormaPagamento({
      nrSeqPessoa: data.cliente?.nrSeqPessoaCli,
      nrSeqFormaPagamento: data.nrSeqFormaPagamento,
    });
    onSetMessage(status, msg);
    return informacoesBancarias;
  };

  const onSearchInformacaoBancariaEntrada = async () => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoCompleteFiltraFormaPagamento({
      nrSeqPessoa: data.cliente?.nrSeqPessoaCli,
      nrSeqFormaPagamento: tituloReceber.nrSeqFormaPagamento,
    });
    onSetMessage(status, msg);
    return informacoesBancarias;
  };

  const onSearchTituloEspecie = async (e) => {
    const {
      status,
      message: msg,
      data: titulos,
    } = await getTituloEspecieAutoComplete({
      noEspecie: e,
    });

    onSetMessage(status, msg);

    return titulos;
  };

  const onGeraTT = async () => {
    setLoading(true);

    let { nrSeqDemanda } = data;

    if (data.tipoDemanda?.flgGeraMassivo) {
      if (data.nrSeqDemandaPrincipal != null) {
        nrSeqDemanda = data.nrSeqDemandaPrincipal;
      }
    }

    const { status, message: msg, value: doc } = await geraTT(nrSeqDemanda);

    if (status === ResponseStatus.Success) {
      const linkSource = `data:image/pdf;base64, ${doc}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `TermoTranferencia.pdf`;
      downloadLink.click();

      onSetMessage(
        ResponseStatus.Success,
        'TT gerado com sucesso, verique o seus downloads'
      );
    } else {
      onSetMessage(status, msg);
    }

    setLoading(false);
  };

  const exibeIteracao = async () => {
    if (data.nrSeqDemanda > 0) {
      const {
        data: demandaIteracao,
        pagination,
      } = await getDemandaIteracaoPagined({
        nrSeqDemanda: data.nrSeqDemanda,
      });

      demandaIteracao.forEach((item) => {
        item.showRemoveButton = false;
      });

      setDataListIteracao({ iteracoes: demandaIteracao, pagination });
    }
  };

  const saveDocumentos = async (nrSeqDemanda) => {
    const files = documentos.filter((doc) => doc instanceof File);

    const { status, message: msg } = await uploadDocumentos(
      files,
      nrSeqDemanda
    );

    if (status === ResponseStatus.Success) {
      const docs = documentos.filter((doc) => !(doc instanceof File));

      setDocumentos(docs);
    } else {
      onSetMessage(status, msg);
    }
  };

  const validaCamposAparelho = () => {
    if (data.nrSeqModeloAparelho != null) {
      if (data.nrSeqAparelho == null) {
        onSetMessage(ResponseStatus.Danger, 'Selecione o IMEI do Aparelho');

        return false;
      }

      if (!data.nrParcelas || isNaN(data.nrParcelas)) {
        onSetMessage(ResponseStatus.Danger, 'Insira o Número de Parcelas');

        return false;
      }

      if (darEntrada) {
        if (!tituloReceber.vlrTitulo) {
          onSetMessage(ResponseStatus.Danger, '');

          return false;
        }

        if (!tituloReceber.dtVencimento) {
          onSetMessage(ResponseStatus.Danger, 'Selecione a Data de Vencimento');

          return false;
        }

        if (!tituloReceber.nrSeqTipoChamada) {
          onSetMessage(ResponseStatus.Danger, 'Selecione o Tipo Receita');

          return false;
        }

        if (!tituloReceber.nrSeqFormaPagamento) {
          onSetMessage(
            ResponseStatus.Danger,
            'Selecione a Forma de Pagamento da Entrada'
          );

          return false;
        }

        if (tituloReceber.nrSeqFormaPagamento) {
          if (tituloReceber.formaPagamento?.flgGrupo === 'D') {
            if (!tituloReceber.nrSeqPessoaInformacaoBancaria) {
              onSetMessage(
                ResponseStatus.Danger,
                'Selecione a Informação Bancária'
              );

              return false;
            }
          }
        }
      }
    }

    return true;
  };

  const onSave = async (inLoading = false) => {
    setExibeBotaoEnvioOperadora(false);

    if (!inLoading) setLoading(true);

    let chip = {};

    if (Object.keys(dataChip).length > 0) {
      if ('chip' in dataChip) {
        if (Object.keys(dataChip.chip).length > 0) {
          const {
            nrSeqChip,
            nrPrefixo,
            nrLinha,
            nrIccid,
            nrPrefixoLinha,
          } = dataChip.chip;

          chip = {
            nrSeqChip,
            nrPrefixo,
            nrLinha,
            nrIccid,
            nrPrefixoLinha,
          };
        }
      }

      if ('nrIccid' in dataChip) {
        chip.nrIccid = dataChip.nrIccid;
      }

      if ('nrPrefixo' in dataChip) {
        chip.nrPrefixo = dataChip.nrPrefixo;
      }

      if ('nrLinha' in dataChip) {
        chip.nrLinha = dataChip.nrLinha;
      }

      if ('nrPrefixoLinha' in dataChip) {
        chip.nrPrefixoLinha = dataChip.nrPrefixoLinha;
      }
    }

    data.chip = chip;

    data.idsDocRemove = dataListDocRemove;

    if (!validaCamposAparelho) {
      setLoading(false);

      return;
    }

    if (darEntrada) {
      data.tituloReceber = tituloReceber;
    }

    // Demanda com Status Devolvida
    if (data.nrSeqStatusDemanda === 4) {
      if (
        documentos.some((doc) => doc instanceof File) &&
        !dataListIteracao.iteracoes.some(
          (iteracao) => iteracao.status === GridView.EnumStatus.Inserir
        )
      ) {
        const interacao = {
          noIteracao: 'Documento adicionado',
          nrSeqDemanda: data.nrSeqDemanda,
          nrSeqStatusDemanda: data.nrSeqStatusDemanda,
          noStatusDemanda: data.statusDemanda.noStatusDemanda,
          status: GridView.EnumStatus.Inserir,
        };

        dataListIteracao.iteracoes = [interacao, ...dataListIteracao.iteracoes];
      }

      if (
        !dataListIteracao.iteracoes.some(
          (iteracao) => iteracao.status === GridView.EnumStatus.Inserir
        )
      ) {
        onSetMessage(
          false,
          'Adicione uma iteração com a resolução para alterar o Status de volta para solicitado'
        );

        setLoading(false);

        return;
      }
    }

    data.noIteracoes = dataListIteracao.iteracoes;

    if (data.nrSeqStatusDemanda === 3 && data.nrSeqDemanda > 0) {
      if (documentos.length > 0) {
        const nrSeqDemandaPrincipal =
          data.nrSeqDemandaPrincipal !== null &&
          data.nrSeqDemandaPrincipal !== 0
            ? data.nrSeqDemandaPrincipal
            : data.nrSeqDemanda;

        await saveDocumentos(nrSeqDemandaPrincipal);
      }
    } else {
      const {
        status,
        message: retornosavedemanda,
        demanda,
      } = await saveDemanda({
        ...data,
        documentos: null,
      });

      if (status === ResponseStatus.Error) {
        onSetMessage(status, retornosavedemanda);
      } else {
        setData(demanda);

        setDataChip({ ...demanda.chip, chip: demanda.chip });

        if (
          (demanda.statusDemanda.noStatusDemanda === 'Solicitada' ||
            demanda.statusDemanda.noStatusDemanda ===
              'Agendar Portabilidade') &&
          demanda.nrSeqOperadoraDestino === 20 &&
          demanda.tipoDemanda.flgPortabilidade &&
          demanda.flgPossuiDocumentoAnexo
        ) {
          setModalPortabilidadeShow(true);
        } else if (
          (demanda.statusDemanda.noStatusDemanda === 'Solicitada' ||
            demanda.statusDemanda.noStatusDemanda ===
              'Enviada para Operadora') &&
          demanda.nrSeqOperadora === 20 &&
          demanda.tipoDemanda.flgNrLinhaNova &&
          demanda.flgPossuiDocumentoAnexo
        ) {
          setModalMsgEnvioOperadoraShow(true);
        }

        exibeIteracao();

        if (demanda.chipNovo) {
          setData({
            ...demanda,
            nrPrefixoProvisorio: demanda.chipNovo.linhaFormatada.substring(
              1,
              3
            ),
          });
        }

        if (documentos.length > 0) {
          const nrSeqDemandaPrincipal =
            demanda.nrSeqDemandaPrincipal !== null &&
            demanda.nrSeqDemandaPrincipal !== 0
              ? demanda.nrSeqDemandaPrincipal
              : demanda.nrSeqDemanda;

          await saveDocumentos(nrSeqDemandaPrincipal);
        }

        onSetMessage(status, 'Demanda Salva com sucesso');
      }
    }

    if (!inLoading) setLoading(false);
  };

  const onTrocarProvisorio = () => {
    if (data.chipNovo?.nrSeqChip)
      setData({
        ...data,
        nrPrefixoProvisorio: data.chipNovo.linhaFormatada.substring(1, 3),
      });

    if (trocarProvisorio) setTrocarProvisorio(false);
    else setTrocarProvisorio(true);
  };

  const onGerarContrato = async () => {
    if (!data.flgPermiteAlterar) return;

    setLoading(true);

    await onSave(true).then(async () => {
      const { status, message: msg, demanda } = await postGeraContrato({
        ...data,
        chip: {
          ...dataChip,
          nrPrefixo:
            data.operadora === 20 ? dataChip.nrPrefixo : data.chip.nrPrefixo,
        },
      });

      if (status === ResponseStatus.Success) {
        if (demanda.documento)
          onOpenReport(transaction.noTransacao, demanda.documento);

        setResumo({
          protocolo: '',
          nome: data.cliente?.noPessoa,
          operadora: data.operadora?.noOperadora,
          plano: data.plano?.noPlano,
          linha: dataChip.nrLinha,
        });

        setData({
          ...demanda,
          cliente: data.cliente,
        });

        setDataChip(demanda.chip);
      }

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    });

    setLoading(false);
  };

  const onSolicitaPortabilidade = async (param) => {
    const obj = {
      nrSeqDemanda: param.nrSeqDemanda,
      dtPortabilidade: param.dtPortabilidade,
      noHoraInicioFim: param.noHoraInicioFim,
    };

    setLoading(true);

    await postSolicitaMVNO(data.nrSeqDemanda).then(
      async (retornoSolicitacao) => {
        if (retornoSolicitacao.status === ResponseStatus.Error) {
          onSetMessage(retornoSolicitacao.status, retornoSolicitacao.message);
        } else {
          await postPortability(obj).then(async (retornoAgendamento) => {
            if (retornoAgendamento.status === ResponseStatus.Error) {
              onSetMessage(
                retornoAgendamento.status,
                retornoAgendamento.message
              );
            } else {
              const demanda = await getDemanda(data.nrSeqDemanda);
              setData(demanda);
              if (demanda.status === ResponseStatus.Success) {
                onSetMessage(demanda.status, demanda.message);
              }
            }
          });
        }
      }
    );

    setLoading(false);
  };

  const onSolicitaMVNO = async () => {
    setLoading(true);
    if (data.flgPortabilidade) setModalPortabilidadeShow(true);
    else {
      await postSolicitaMVNO(data.nrSeqDemanda).then(
        async (retornosolicitacao) => {
          setData(await getDemanda(data.nrSeqDemanda));
          onSetMessage(retornosolicitacao.status, retornosolicitacao.msg);
        }
      );
    }

    setLoading(false);
  };

  const onAdicionarIteracao = async () => {
    if (!data.flgPermiteAlterar) return;

    const lista = dataListIteracao.iteracoes ?? [];

    const obj = new DemandaIteracao({
      noIteracao: data.noIteracao,
      nrSeqDemanda: data.nrSeqDemanda,
      nrSeqStatusDemanda: data.nrSeqStatusDemanda,
      noStatusDemanda: data.statusDemanda.noStatusDemanda,
      status: GridView.EnumStatus.Inserir,
    });

    obj.showRemoveButton = true;

    dataListIteracao.iteracoes.unshift(obj);

    voltarStatusDemanda = true;

    if (gridViewIteracao && gridViewIteracao.current)
      gridViewIteracao.current.setDataSource(lista);

    setData({ ...data, noIteracao: '' });
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return empresas;
  };

  const onSearchTipoDemanda = async (e) => {
    onNew();

    const {
      status,
      message: msg,
      tiposDemanda,
    } = await getTipoDemandaAutoComplete({
      noTipoDemanda: e,
      flgAtivo: true,
    });
    onSetMessage(status, msg);
    return tiposDemanda;
  };

  const onExibeModalPortabilidade = async () => {
    if (data.flgPossuiDocumentoAnexo === false) {
      onSetMessage(
        ResponseStatus.Error,
        'É preciso anexar o contrato para agendar a portabilidade.'
      );
      return;
    }
    setModalPortabilidadeShow(true);
  };

  const onSaveMassivo = async () => {
    setLoading(true);

    if (data.nrSeqStatusDemanda === 4) {
      if (
        documentos.some((doc) => doc instanceof File) &&
        !dataListIteracao.iteracoes.some(
          (iteracao) => iteracao.status === GridView.EnumStatus.Inserir
        )
      ) {
        const interacao = {
          noIteracao: 'Documento adicionado',
          nrSeqDemanda: data.nrSeqDemanda,
          nrSeqStatusDemanda: data.nrSeqStatusDemanda,
          noStatusDemanda: data.statusDemanda.noStatusDemanda,
          status: GridView.EnumStatus.Inserir,
        };

        dataListIteracao.iteracoes = [interacao, ...dataListIteracao.iteracoes];
      }
    }

    if (data.nrSeqDemanda > 0 && data.tipoDemanda.flgPortabilidade) {
      data.idsDocRemove = dataListDocRemove;

      // add doc em demanda efetivada
      if (data.nrSeqStatusDemanda === 3) {
        if (documentos.length > 0) {
          const nrSeqDemandaPrincipal =
            data.nrSeqDemandaPrincipal !== null &&
            data.nrSeqDemandaPrincipal !== 0
              ? data.nrSeqDemandaPrincipal
              : data.nrSeqDemanda;

          await saveDocumentos(nrSeqDemandaPrincipal);
        }
      } else {
        data.noIteracoes = dataListIteracao.iteracoes;

        const { status, message: msg, demanda } = await saveDemanda({
          ...data,
          documentos: null,
        });

        if (status === ResponseStatus.Error) {
          onSetMessage(status, msg);
        } else {
          setData(demanda);

          setDataChip({ ...demanda.chip, chip: demanda.chip });

          exibeIteracao();

          if (demanda.chipNovo) {
            setData({
              ...demanda,
              nrPrefixoProvisorio: demanda.chipNovo.linhaFormatada.substring(
                1,
                3
              ),
              nrLinhaProvisoria: demanda.chipNovo.linhaFormatada.substring(2),
            });
          }

          if (documentos.length > 0) {
            const nrSeqDemandaPrincipal =
              demanda.nrSeqDemandaPrincipal !== null &&
              demanda.nrSeqDemandaPrincipal !== 0
                ? demanda.nrSeqDemandaPrincipal
                : demanda.nrSeqDemanda;

            await saveDocumentos(nrSeqDemandaPrincipal);
          }

          if (voltarStatusDemanda) {
            onSetMessage(
              status,
              'Demanda Salva com sucesso. Favor avisar que a demanda foi atualizada'
            );
          } else {
            onSetMessage(status, 'Demanda Salva com sucesso');
          }
        }
      }

      if (
        dataMassivo.includes(
          (e) =>
            e.status === GridView.EnumStatus.Inserir ||
            e.status === GridView.EnumStatus.Alterar
        ) ||
        dataListDocRemove.length > 0
      ) {
        if (dataMassivo.length > 0) {
          const demandaPrincipalIndex = dataMassivo.findIndex(
            (e) => e.nrSeqDemandaPrincipal == null
          );

          dataMassivo[demandaPrincipalIndex].idsDocRemove = dataListDocRemove;
          dataMassivo[demandaPrincipalIndex].documentos = null;

          dataMassivo.forEach((item) => {
            const {
              nrSeqChip,
              nrPrefixo,
              nrLinha,
              nrPrefixoLinha,
              nrIccid,
              nrSeqOperadora,
            } = item.chip;

            const chip = {
              nrSeqChip,
              nrPrefixo,
              nrLinha,
              nrPrefixoLinha,
              nrIccid,
              nrSeqOperadora,
            };

            item.chip = chip;

            delete item.formaPagamento;
            delete item.tipoChamada;
          });

          const { status, message: msg, demanda } = await saveDemandaMassiva(
            dataMassivo
          );

          if (status === ResponseStatus.Success) {
            setDataMassivo(demanda.demandasFilhas);
          }

          onSetMessage(status, msg);
        }
      }
    } else if (dataMassivo.length > 0) {
      const demandaPrincipalIndex = dataMassivo.findIndex(
        (e) => e.nrSeqDemandaPrincipal == null
      );
      dataMassivo[demandaPrincipalIndex].idsDocRemove = dataListDocRemove;
      dataMassivo[demandaPrincipalIndex].documentos = null;
      dataMassivo[demandaPrincipalIndex].noIteracoes =
        dataListIteracao.iteracoes;

      dataMassivo.forEach((item) => {
        const {
          nrSeqChip,
          nrPrefixo,
          nrLinha,
          nrIccid,
          nrSeqOperadora,
          nrPrefixoLinha,
        } = item.chip;

        const chip = {
          nrSeqChip,
          nrPrefixo,
          nrLinha,
          nrIccid,
          nrSeqOperadora: nrSeqOperadora ?? 0,
          nrPrefixoLinha: nrPrefixoLinha ?? '',
        };

        item.chip = chip;

        delete item.formaPagamento;
        delete item.tipoChamada;
      });

      const { status, message: msg, demanda } = await saveDemandaMassiva(
        dataMassivo
      );

      if (status === ResponseStatus.Success) {
        setData(demanda);

        setDataMassivo(demanda.demandasFilhas);

        const nrSeqDemandaPrincipal =
          demanda.nrSeqDemandaPrincipal !== null &&
          demanda.nrSeqDemandaPrincipal !== 0
            ? demanda.nrSeqDemandaPrincipal
            : demanda.nrSeqDemanda;

        await saveDocumentos(nrSeqDemandaPrincipal);
      }

      onSetMessage(status, msg);
    } else {
      onSetMessage(false, 'Nenhuma linha adicionada');
    }

    setLoading(false);
  };

  const onGerarContratoMassivo = async () => {
    setLoading(true);

    if (data.nrSeqDemanda) {
      const { status, demanda } = await postGeraContratoMassivo(data);

      if (status === ResponseStatus.Success) {
        if (demanda.documento) {
          onOpenReport(transaction.noTransacao, demanda.documento);
        }
      }
    }

    setLoading(false);
  };

  const onReverterPortabilidade = async () => {
    setLoading(true);

    const today = new Date().toJSON().slice(0, 10);

    const obj = {
      empresa: data.empresa,
      nrSeqEmpresa: data.nrSeqEmpresa,
      dtCad: today,
      dtSolicitacao: today,
      nrSeqChip: data.nrSeqChip,
      chip: data.chip,
      nrSeqPessoaCli: data.nrSeqPessoaCli,
      cliente: data.cliente,
      nrSeqOperadoraDestino: data.nrSeqOperadoraOrigem,
      operadoraDestino: data.operadoraOrigem,
      nrSeqOperadoraOrigem: data.nrSeqOperadoraDestino,
      operadoraOrigem: data.operadoraDestino,
      nrSeqCentroDeCusto: data.centroDeCusto.nrSeqCentroDeCusto,
      centroDeCusto: data.centroDeCusto,
      nrSeqFormaPagamento: data.formaPagamento.nrSeqFormaPagamento,
      formaPagamento: data.formaPagamento,
      nrSeqVencimento: data.vencimento.nrSeqVencimento,
      vencimento: data.vencimento,
      nrSeqPessoaInformacaoBancaria: data.nrSeqPessoaInformacaoBancaria,
      informacaoBancaria: data.informacaoBancaria,
      flgPermiteAlterar: true,
    };

    onNew();

    const { tiposDemanda } = await getTipoDemandaAutoComplete({
      noTipoDemanda: 'Portabilidade para Operadora Origem',
    });

    const tipoDemanda = tiposDemanda[0];

    obj.tipoDemanda = tipoDemanda;
    obj.nrSeqTipoDemanda = tipoDemanda.nrSeqTipoDemanda;

    setData(obj);
    setDataChip({
      chip: obj.chip,
    });

    setLoading(false);
  };

  const onReturnDemanda = () => {
    setShowModalDevolverDemanda(true);
  };

  const onSelectCliente = async (cliente, atualizaData = true) => {
    setLoading(true);

    let objData = { cliente, nrSeqPessoaCli: cliente.nrSeqPessoaCli };

    if (cliente.nrSeqPessoaCli) {
      const { data: clienteLinhas } = await getClienteLinhasByCliente({
        nrSeqPessoaCli: cliente.nrSeqPessoaCli,
      });

      const titulosVencidos = await BuscarTitulosVencidosDoCliente(
        cliente.nrSeqPessoaCli
      );

      if (cliente.nrSeqEmpresa) {
        objData = {
          ...objData,
          empresa: cliente.empresa,
          nrSeqEmpresa: cliente.nrSeqEmpresa,
        };
      }

      if (titulosVencidos.length > 0) {
        setClienteMessage({
          showMessage: true,
          message: `Cliente possui título${
            titulosVencidos.length === 1 ? '' : 's'
          } vencido${titulosVencidos.length === 1 ? '' : 's'} há mais de ${
            titulosVencidos[0].nrLimiteDiasVencidos
          } dias, favor contatar o suporte antes de prosseguir.`,
        });

        objData.flgClienteInadimplente = true;
      } else {
        setClienteMessage({
          showMessage: false,
          message: '',
        });

        objData.flgClienteInadimplente = false;
      }

      if (
        data.tipoDemanda?.flgNrLinhaNova ||
        data.tipoDemanda?.cdTipoDemanda === 'Troca de Titularidade' ||
        data.tipoDemanda?.cdTipoDemanda === 'Alteracao de Contrato' ||
        data.tipoDemanda?.cdTipoDemanda === 'Alteracao de Contrato Massivo'
      ) {
        if (clienteLinhas.length > 0) {
          const { formaPagamento, vencimento } = clienteLinhas[0];

          setInfoPagamentoDefault({
            formaPagamento,
            nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
            vencimento,
            nrSeqVencimento: vencimento.nrSeqVencimento,
          });

          objData = {
            ...objData,
            formaPagamento,
            nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
            vencimento,
            nrSeqVencimento: vencimento.nrSeqVencimento,
          };
        } else {
          setInfoPagamentoDefault({});

          objData = {
            ...objData,
            formaPagamento: undefined,
            nrSeqFormaPagamento: undefined,
            vencimento: undefined,
            nrSeqVencimento: undefined,
          };
        }
      }

      if (atualizaData) {
        setData({ ...data, ...objData });
      }
    } else {
      setClienteMessage({});
      setInfoPagamentoDefault({});

      objData = {
        ...objData,
        formaPagamento: undefined,
        nrSeqFormaPagamento: undefined,
        vencimento: undefined,
        nrSeqVencimento: undefined,
      };

      if (atualizaData) {
        setData({
          ...data,
          ...objData,
        });
      }
    }

    setLoading(false);

    return objData;
  };

  const onSelectFormaPagamento = async (
    formaPagamento,
    atualizaData = true
  ) => {
    setLoading(true);

    let buscarInformacaoBancaria = true;

    let objData = {
      formaPagamento,
      nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
    };

    if (formaPagamento.nrSeqFormaPagamento) {
      if (infoPagamentoDefault.nrSeqFormaPagamento) {
        if (
          formaPagamento.nrSeqFormaPagamento !==
          infoPagamentoDefault.nrSeqFormaPagamento
        ) {
          buscarInformacaoBancaria = false;

          setModalAlterarFormaPagamento({
            show: true,
            formaPagamento,
          });
        }
      }

      if (buscarInformacaoBancaria) {
        if (formaPagamento.flgGrupo === 'D' && data.nrSeqPessoaCli) {
          const {
            informacoesBancarias,
          } = await getInformacaoBancariaAutoCompleteFiltraFormaPagamento({
            nrSeqPessoa: data.cliente?.nrSeqPessoaCli,
            nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
          });

          if (informacoesBancarias.length === 1) {
            objData = {
              informacaoBancaria: informacoesBancarias[0],
              nrSeqPessoaInformacaoBancaria:
                informacoesBancarias[0].nrSeqPessoaInformacaoBancaria,
            };
          } else {
            objData = {
              informacaoBancaria: undefined,
              nrSeqPessoaInformacaoBancaria: undefined,
            };
          }
        } else {
          objData = {
            informacaoBancaria: undefined,
            nrSeqPessoaInformacaoBancaria: undefined,
          };
        }
      }
    } else {
      objData = {
        formaPagamento: undefined,
        nrSeqFormaPagamento: undefined,
        informacaoBancaria: undefined,
        nrSeqPessoaInformacaoBancaria: undefined,
      };
    }

    if (atualizaData) {
      setData({ ...data, ...objData });
    }

    setLoading(false);

    return objData;
  };

  const searchDemandaWithTeleCall = async () => {
    setLoading(true);

    const {
      data: demandas,
      message: msg,
      status,
    } = await getDemandaPaginedWithTeleCall({
      nrSeqDemanda: data.nrSeqDemanda,
    });

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      if (demandas.length === 1) {
        const demanda = await getDemanda(demandas[0].nrSeqDemanda);

        setEndereco(demanda.cliente?.endereco);

        setEnderecoTitularOld(demanda.clienteTitularOld?.endereco);

        const obj = {
          ...demanda,
          nrSeqTipoChamadaDados: demanda.tipoChamada?.nrSeqTipoChamada,
          tipoDemanda: demanda.tipoDemanda,
          dtCad: demanda.dtCad === null ? demanda.dtSolicitacao : demanda.dtCad,
        };

        setData(obj);

        setDataChip({
          chip: demanda.chip,
          nrIccid: demanda.chip?.nrIccid,
          nrLinha: demanda.chip?.nrLinha,
          nrPrefixo: demanda.chip?.nrPrefixo,
          nrPrefixoLinha: demanda.chip?.nrPrefixoLinha,
          nrSeqChip: demanda.chip?.nrSeqChip,
          flgNumeroEspecial: demanda.chip?.flgNumeroEspecial,
        });

        setDocumentos(demanda.documentos);

        if (gridView && gridView.current)
          gridView.current.setDataSource(demanda.documentos);

        if (demanda.nrSeqPessoaOld > 0)
          setDataTitular({ flgMesmoTitular: 'N' });
        else setDataTitular({ flgMesmoTitular: 'S' });

        setResumo({
          protocolo: '',
          nome: demanda.cliente && demanda.cliente.noPessoa,
          operadora: demanda.operadora && demanda.operadora.noOperadora,
          plano: demanda.plano && demanda.plano.noPlano,
          linha: demanda.chip && demanda.chip.nrLinha,
        });

        if (demanda.tipoDemanda?.flgGeraMassivo) {
          setDataMassivo(demanda.demandasFilhas);
        }
      }
    }

    setLoading(false);
  };

  useEffect(async () => {
    if (importedFile.length > 0 && data.nrSeqDemanda > 0) {
      await uploadDocumento(importedFile[0], data.nrSeqDemanda);

      const documento = new DemandaDocumento({
        noArquivo: importedFile[0].name,
        noTipoArquivo: importedFile[0].type,
        noTamanho: importedFile[0].size,
        flgExibeDownload: false,
      });

      const lista = [];
      lista.push(documento);
      const obj = { nrSeqDemanda: data.nrSeqDemanda };
      const listaDocumentos = await getDemandaDocumentoList(obj);
      if (gridView && gridView.current)
        gridView.current.setDataSource(listaDocumentos.documentos);
      setImportedFile([]);
    }
  }, [importedFile]);

  const load = useCallback(async () => {
    setLoading(true);

    setTrocarProvisorio(false);

    onNew();

    if (Number.isInteger(registryKey)) {
      const demanda = await getDemanda(registryKey);

      const {
        data: demandaIteracao,
        pagination,
      } = await getDemandaIteracaoPagined({
        nrSeqDemanda: registryKey,
      });

      setEndereco(demanda.cliente?.endereco);

      setEnderecoTitularOld(demanda.clienteTitularOld?.endereco);

      const obj = {
        ...demanda,
        nrSeqTipoChamadaDados: demanda.tipoChamada?.nrSeqTipoChamada,
        tipoDemanda: demanda.tipoDemanda,
        dtCad: demanda.dtCad === null ? demanda.dtSolicitacao : demanda.dtCad,
      };

      setData(obj);

      if (demanda.tituloReceber != null) {
        setTituloReceber(demanda.tituloReceber);
        setDarEntrada(true);
      }

      setDataChip({
        chip: demanda.chip,
        nrIccid: demanda.chip?.nrIccid,
        nrLinha: demanda.chip?.nrLinha,
        nrPrefixo: demanda.chip?.nrPrefixo,
        nrPrefixoLinha: demanda.chip?.nrPrefixoLinha,
        nrSeqChip: demanda.chip?.nrSeqChip,
        linhaFormatada: demanda.chip?.linhaFormatada,
        flgNumeroEspecial: demanda.chip?.flgNumeroEspecial,
        operadora: demanda.chip?.operadora,
        nrSeqOperadora: demanda.chip?.nrSeqOperadora,
      });

      setDocumentos(demanda.documentos);

      if (gridView && gridView.current)
        gridView.current.setDataSource(demanda.documentos);

      demandaIteracao.forEach((item) => {
        item.showRemoveButton = false;
      });

      if (gridViewIteracao && gridViewIteracao.current)
        gridViewIteracao.current.setDataSource(demandaIteracao, pagination);

      setDataListIteracao({ iteracoes: demandaIteracao, pagination });

      if (demanda.nrSeqPessoaOld > 0) setDataTitular({ flgMesmoTitular: 'N' });
      else setDataTitular({ flgMesmoTitular: 'S' });

      setResumo({
        protocolo: '',
        nome: demanda.cliente && demanda.cliente.noPessoa,
        operadora: demanda.operadora && demanda.operadora.noOperadora,
        plano: demanda.plano && demanda.plano.noPlano,
        linha: demanda.chip && demanda.chip.nrLinha,
      });

      if (demanda.tipoDemanda?.flgGeraMassivo) {
        setDataMassivo(demanda.demandasFilhas);
      }
    } else if ('nrSeqTipoDemanda' in registryKey) {
      const tipoDemanda = await getTipoDemandaResumida(
        registryKey.nrSeqTipoDemanda
      );

      data.tipoDemanda = tipoDemanda;
      data.nrSeqTipoDemanda = tipoDemanda.nrSeqTipoDemanda;

      if (!tipoDemanda.flgVersaoOld) {
        await getDemandaList({
          nrSeqTipoDemanda: tipoDemanda.nrSeqTipoDemanda,
          flgVersaoOld: tipoDemanda.flgVersaoOld,
        }).then(async (result) => {
          let objData = {
            ...result.demandas[0],
            nrSeqTipoDemanda: tipoDemanda.nrSeqTipoDemanda,
            tipoDemanda,
            nrSeqChamado: registryKey.nrSeqChamado,
          };

          if ('nrPrefixoLinha' in registryKey) {
            const objChip = { nrPrefixoLinha: registryKey.nrPrefixoLinha };

            if (!tipoDemanda.flgPortabilidade) {
              const { chips } = await getChipAutoComplete({
                NrPrefixoLinha: registryKey.nrPrefixoLinha,
              });

              if (chips.length === 1) {
                const [chip] = chips;
                objChip.chip = chip;

                const objDataFromChip = await onSelectChip(chip, true, false);

                objData = {
                  ...objData,
                  ...objDataFromChip,
                };

                let objDataFromCliente = {};

                if (objDataFromChip.nrSeqPessoaCli) {
                  objDataFromCliente = await onSelectCliente(
                    objDataFromChip.cliente,
                    false
                  );
                } else {
                  objDataFromCliente = await onSelectCliente(
                    registryKey.cliente,
                    false
                  );
                }

                objData = {
                  ...objData,
                  ...objDataFromCliente,
                };

                if ('formaPagamento' in objDataFromCliente) {
                  const objDataFromFormaPagamento = await onSelectFormaPagamento(
                    objDataFromCliente.formaPagamento,
                    false
                  );

                  objData = { ...objData, ...objDataFromFormaPagamento };
                }
              } else {
                setDataChip(objChip);
              }
            } else {
              setDataChip(objChip);
            }
          }

          if (!objData.cliente || !objData.nrSeqPessoaCli) {
            const objDataFromCliente = await onSelectCliente(
              registryKey.cliente,
              false
            );

            objData = {
              ...objData,
              ...objDataFromCliente,
            };

            if ('formaPagamento' in objDataFromCliente) {
              const objDataFromFormaPagamento = await onSelectFormaPagamento(
                objDataFromCliente.formaPagamento,
                false
              );

              objData = { ...objData, ...objDataFromFormaPagamento };
            }
          }

          if (!objData.empresa || !objData.nrSeqEmpresa) {
            objData.nrSeqEmpresa = registryKey.nrSeqEmpresa;
            objData.empresa = registryKey.empresa;
          }

          setData(objData);
        });
      } else {
        let objData = {
          nrSeqTipoDemanda: tipoDemanda.nrSeqTipoDemanda,
          tipoDemanda,
          nrSeqChamado: registryKey.nrSeqChamado,
        };

        if ('nrPrefixoLinha' in registryKey) {
          const objChip = { nrPrefixoLinha: registryKey.nrPrefixoLinha };

          if (!tipoDemanda.flgPortabilidade) {
            const { chips } = await getChipAutoComplete({
              NrPrefixoLinha: registryKey.nrPrefixoLinha,
            });

            if (chips.length === 1) {
              const [chip] = chips;
              objChip.chip = chip;

              const objDataFromChip = await onSelectChip(chip, true, false);

              let objDataFromCliente = {};

              if (objDataFromChip.nrSeqPessoaCli) {
                objDataFromCliente = await onSelectCliente(
                  objDataFromChip.cliente,
                  false
                );
              } else {
                const { clientes } = await getClienteAutoCompleteDemanda({
                  nrSeqPessoa: registryKey.nrSeqPessoaCli,
                });

                const [cliente] = clientes;

                objDataFromCliente = await onSelectCliente(cliente, false);
              }

              objData = {
                ...objData,
                ...objDataFromCliente,
              };

              if ('formaPagamento' in objDataFromCliente) {
                const objDataFromFormaPagamento = await onSelectFormaPagamento(
                  objDataFromCliente.formaPagamento,
                  false
                );

                objData = { ...objData, ...objDataFromFormaPagamento };
              }
            } else {
              setDataChip(objChip);
            }
          } else {
            setDataChip(objChip);
          }
        }

        if (!objData.cliente || !objData.nrSeqPessoaCli) {
          const { clientes } = await getClienteAutoCompleteDemanda({
            nrSeqPessoa: registryKey.nrSeqPessoaCli,
          });

          const [cliente] = clientes;

          const objDataFromCliente = await onSelectCliente(cliente, false);

          objData = {
            ...objData,
            ...objDataFromCliente,
          };

          if ('formaPagamento' in objDataFromCliente) {
            const objDataFromFormaPagamento = await onSelectFormaPagamento(
              objDataFromCliente.formaPagamento,
              false
            );

            objData = { ...objData, ...objDataFromFormaPagamento };
          }
        }

        if (!objData.empresa || !objData.nrSeqEmpresa) {
          objData.nrSeqEmpresa = registryKey.nrSeqEmpresa;
          objData.empresa = registryKey.empresa;
        }

        setData(objData);
      }
    }

    setLoading(false);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  useEffect(() => {
    const chip = new Chip({ ...dataChip.chip, ...dataChip });

    setData({ ...data, chip, nrSeqChip: dataChip.nrSeqChip });
  }, [dataChip]);

  useEffect(async () => {
    exibeIteracao();
  }, [data.nrSeqDemanda]);

  useEffect(() => {
    exibeIteracao();
  }, [data.noIteracoes]);

  useEffect(() => {
    if (gridViewIteracao && gridViewIteracao.current) {
      gridViewIteracao.current.setDataSource(
        dataListIteracao.iteracoes,
        dataListIteracao.pagination
      );
    }
  }, [dataListIteracao.iteracoes]);

  const onRefletirDemanda = async () => {
    setLoading(true);

    const { status, message: msg } = await refletirDemanda({
      nrSeqDemanda: data.nrSeqDemandaPrincipal
        ? data.nrSeqDemandaPrincipal
        : data.nrSeqDemanda,
    });

    onSetMessage(status, msg);

    setLoading(false);
  };

  const { id: idSelecao } = PageTypes.Selection;

  return (
    <CSDManutPage
      isActive={isActive}
      title='Demanda'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {!!data.tipoDemanda && data.tipoDemanda?.flgVersaoOld === false && (
        <ToolbarButtons>
          {data.nrSeqDemanda > 0 &&
            data.nrSeqStatusDemanda !== 3 &&
            data.nrSeqStatusDemanda !== 5 && (
              <ToolbarButtons.Button
                text='Cancelar'
                icon='cancel'
                onClick={() => setShowModalConfirmarCancelamento(true)}
              />
            )}
          {data.nrSeqDemanda > 0 &&
            data.nrSeqStatusDemanda !== 3 &&
            data.nrSeqStatusDemanda !== 4 &&
            data.nrSeqStatusDemanda !== 5 && (
              <ToolbarButtons.Button
                text='Devolver'
                onClick={() => onReturnDemanda()}
              />
            )}
          {data.tipoDemanda?.cdDocumentoConfiguracao &&
            data.flgPermiteAlterar &&
            data.nrSeqDemanda > 0 && (
              <>
                <ToolbarButtons.Button
                  text='Gerar Contrato'
                  onClick={() =>
                    !data.tipoDemanda?.flgGeraMassivo
                      ? onGerarContrato()
                      : onGerarContratoMassivo()
                  }
                />
                <ToolbarButtons.Button
                  text='Contrato Click Sign'
                  onClick={() =>
                    setShowModalGeraContratoClickSign({
                      show: true,
                      docType: 'Contrato',
                    })
                  }
                />
              </>
            )}
          {data.tipoDemanda?.flgPortabilidade &&
            data.nrSeqOperadoraDestino === 20 &&
            data.nrSeqDemanda > 0 &&
            ![3, 4, 5].includes(data.nrSeqStatusDemanda) && (
              <ToolbarButtons.Button
                text='Verificar Portabilidade'
                onClick={() => searchDemandaWithTeleCall()}
              />
            )}
          {data?.nrSeqDemanda > 0 &&
            data.flgPermiteAlterar &&
            data?.tipoDemanda?.flgGeraTT && (
              <>
                <ToolbarButtons.Button
                  text='Gera TT'
                  onClick={() => onGeraTT()}
                />
                <ToolbarButtons.Button
                  text='Gera TT via ClickSign'
                  onClick={() =>
                    setShowModalGeraContratoClickSign({
                      show: true,
                      docType: 'TT',
                    })
                  }
                />
              </>
            )}
          {exibeBotaoEnvioOperadora &&
            data.nrSeqStatusDemanda !== 3 &&
            data.nrSeqStatusDemanda !== 5 && (
              <ToolbarButtons.Button
                text='Enviar Operadora'
                onClick={() => setModalMsgEnvioOperadoraShow(true)}
              />
            )}
          {data.nrSeqOperadoraDestino === 20 &&
            data.tipoDemanda?.flgPortabilidade &&
            data?.nrSeqStatusDemanda !== 3 &&
            data?.nrSeqStatusDemanda !== 5 &&
            data?.nrSeqStatusDemanda !== 9 &&
            data?.flgPossuiDocumentoAnexo && (
              <ToolbarButtons.Button
                text='Agendar Portabilidade'
                onClick={() => onExibeModalPortabilidade(true)}
              />
            )}
          {data.nrSeqStatusDemanda !== 5 &&
            data.tipoDemanda?.cdTipoDemanda !== 'Verificar Consumo MVNO' && (
              <ToolbarButtons.Button
                text='Salvar'
                icon='Save'
                onClick={() =>
                  data.tipoDemanda?.flgGeraMassivo ? onSaveMassivo() : onSave()
                }
              />
            )}
          {data.nrSeqStatusDemanda === 3 &&
            data.operadoraDestino.flgRevertePortabilidade &&
            data.tipoDemanda?.flgPortabilidade &&
            !data.tipoDemanda?.flgRevertePortabilidade && (
              <ToolbarButtons.Button
                text='Reverter Portabilidade'
                onClick={() => onReverterPortabilidade()}
              />
            )}
          {/* remover apos teste */}
          {data.nrSeqStatusDemanda === 3 &&
            data.nrSeqDemanda &&
            data.nrSeqOperadoraDestino === 20 && (
              <ToolbarButtons.Button
                text='Criar Demanda na Abrutelecom'
                onClick={() => onRefletirDemanda()}
              />
            )}
        </ToolbarButtons>
      )}
      <div className='row mb-3'>
        <div className='col'>
          <div className='card'>
            <div className='card-body'>
              <div className='row mb-3'>
                <div className='col-5'>
                  <Autocomplete
                    label='Tipo de Demanda'
                    required
                    enabled={
                      !Number.isInteger(registryKey) ||
                      (data.flgPermiteAlterar &&
                        data.tipoDemanda?.flgVersaoOld === false)
                    }
                    searchDataSource={onSearchTipoDemanda}
                    selectedItem={data.tipoDemanda}
                    onSelectItem={async (tipoDemanda) => {
                      setLoading(true);

                      onNew();

                      if ('nrSeqTipoDemanda' in tipoDemanda) {
                        if (!tipoDemanda.flgVersaoOld) {
                          await getDemandaList({
                            nrSeqTipoDemanda: tipoDemanda.nrSeqTipoDemanda,
                            flgVersaoOld: false,
                          }).then((result) => {
                            setData({
                              ...result.demandas[0],
                              nrSeqTipoDemanda: tipoDemanda.nrSeqTipoDemanda,
                            });
                          });
                        } else {
                          setData({
                            ...data,
                            tipoDemanda,
                          });
                        }
                      }

                      setLoading(false);
                    }}
                    dataSourceTextProperty='noTipoDemanda'
                  />
                </div>
                <div className='col-2'>
                  <DatePicker
                    label='Data de Cadastro'
                    readOnly
                    text={data.dtCad}
                    maxLength={10}
                    mask={MaskTypes.Date}
                  />
                </div>
                <div className='col-1'>
                  <Textbox
                    label='Chamado'
                    text={data.nrSeqChamado}
                    onChangedValue={(nrSeqChamado) => {
                      setData({ ...data, nrSeqChamado });
                    }}
                  />
                </div>
              </div>
              <div className='row mb-3 '>
                <div className='col'>
                  <Autocomplete
                    label='Empresa'
                    enabled={
                      data.flgPermiteAlterar &&
                      data.tipoDemanda?.flgVersaoOld === false &&
                      !gridHasItem
                    }
                    required
                    searchDataSource={onSearchEmpresa}
                    selectedItem={data.empresa}
                    onSelectItem={(empresa) => {
                      setData({
                        ...data,
                        empresa,
                        nrSeqEmpresa: empresa.nrSeqEmpresa,
                      });
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
                {data?.dtPrevistaRenovacao && (
                  <div className='col-2'>
                    <DatePicker
                      label='Previsão de Renovação'
                      text={data?.dtPrevistaRenovacao}
                      readOnly={somenteleitura}
                    />
                  </div>
                )}
                {data?.dtPortabilidadeStr && (
                  <div
                    className='col-2 mt-2 widget-square bg-gradient-primary text-white p-4'
                    style={{ textAlign: 'center' }}
                  >
                    Data Agendada Portabilidade: {data.dtPortabilidadeStr}
                  </div>
                )}
                <div
                  className='col-2 mt-2 widget-square bg-gradient-primary text-white p-4 rounded'
                  style={{ textAlign: 'center' }}
                >
                  Status da Demanda: {data.statusDemanda?.noStatusDemanda}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(dataListIteracao.iteracoes.length > 0 ||
        data.nrSeqStatusDemanda === 4 ||
        (data.tipoDemanda?.cdTipoDemanda === 'Retencao de Linhas' &&
          data.nrSeqDemanda)) && (
        <div className='row mb-3'>
          <Panel>
            <Panel.Header
              title='Iterações'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              {(data.nrSeqStatusDemanda === 4 ||
                (data.tipoDemanda?.cdTipoDemanda === 'Retencao de Linhas' &&
                  data.nrSeqDemanda)) && (
                <div className='row mb-3'>
                  <div className='col'>
                    <Textbox
                      label='Iteração'
                      text={data.noIteracao}
                      enabled={!data?.flgPermiteAlterar}
                      onChangedValue={(noIteracao) => {
                        setData({
                          ...data,
                          noIteracao,
                        });
                      }}
                    />
                  </div>
                  <div className='col-2 mt-4'>
                    <Button
                      theme={Theme.Primary}
                      template={Button.Templates.Default}
                      icon='plus'
                      text='Adicionar'
                      onClick={onAdicionarIteracao}
                      className='width100'
                    />
                  </div>
                </div>
              )}
              <div className='row mb-3'>
                <div className='col'>
                  <GridView
                    ref={gridViewIteracao}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsIteracao}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      )}
      {data.tipoDemanda?.nrSeqEncargoManual && (
        <div className='row mb-3'>
          <div className='col-5'>
            <Autocomplete
              label='Encargo'
              selectedItem={data.tipoDemanda?.encargoManual}
              enabled={false}
              readOnly
              dataSourceTextProperty='noEncargoManual'
            />
          </div>
          <div className='col-2'>
            <Switch
              label='Cobrar do Cliente?'
              formControl
              checked={data.flgCobrarEncargoTipoDemanda}
              onChange={(flgCobrarEncargoTipoDemanda) =>
                setData({ ...data, flgCobrarEncargoTipoDemanda })
              }
            />
          </div>
          <div className='col-2'>
            <Textbox
              visible={data.flgCobrarEncargoTipoDemanda}
              label='Valor'
              text={
                data.vlrEncargoTipoDemanda !== null &&
                data.vlrEncargoTipoDemanda !== undefined
                  ? data.vlrEncargoTipoDemanda
                  : data.tipoDemanda?.encargoManual?.vlrEncargo
              }
              mask={MaskTypes.Decimal}
              onChangedValue={(vlrEncargoTipoDemanda) =>
                setData({ ...data, vlrEncargoTipoDemanda })
              }
            />
          </div>
        </div>
      )}
      {(() => {
        // Gera -> Novo Contrato
        if (
          !data.tipoDemanda?.flgVersaoOld &&
          data.tipoDemanda?.cdTipoDemanda === 'Novo Contrato'
        ) {
          return (
            <>
              <div className='row mb-4'>
                <div className='col'>
                  <Panel>
                    <Panel.Header
                      title='Cliente'
                      theme={Theme.Primary}
                      align={JustifyContent.Start}
                    />

                    <Panel.Body>
                      <div className='accordion-body'>
                        <div className='card'>
                          <div className='card-body'>
                            {clienteMessage.showMessage && (
                              <div className='row mb-2'>
                                <div className='col'>
                                  <div className='card border-danger'>
                                    <div className='card-body text-danger'>
                                      <p className='card-text'>
                                        {clienteMessage.message}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className='row mb-3'>
                              <div className='col'>
                                <Autocomplete
                                  label='Cliente'
                                  enabled={data.flgPermiteAlterar}
                                  required
                                  searchDataSource={onSearchCliente}
                                  minLengthTextSearch={3}
                                  placeholder='Digite ao menos 3 caracteres para buscar Cliente'
                                  selectedItem={data.cliente}
                                  findTransaction={findTransaction}
                                  onOpenTransaction={onOpenTransaction}
                                  transactionToOpen={TransacationCodes.Cliente}
                                  nrseqTransactionToOpen='nrSeqPessoaCli'
                                  onSelectItem={(cliente) => {
                                    data.nrSeqPessoaCli =
                                      cliente.nrSeqPessoaCli ?? undefined;
                                    data.cliente = cliente;

                                    onSelectCliente(cliente);

                                    setEndereco(
                                      cliente.endereco ?? new Endereco()
                                    );
                                  }}
                                  dataSourceTextProperty='noPessoa'
                                />
                              </div>
                            </div>
                            <div className='row mb-3'>
                              <div className='col'>
                                <Textbox
                                  label='RG'
                                  text={data.cliente?.nrRG}
                                  maxLength={15}
                                  visible={data.cliente?.flgFisica}
                                  readOnly
                                />
                              </div>
                              {data.cliente?.flgFisica && (
                                <div className='col'>
                                  <Textbox
                                    label='CPF'
                                    text={data.cliente?.nrDocumento}
                                    mask={MaskTypes.CPF}
                                    readOnly
                                  />
                                </div>
                              )}

                              {!data.cliente?.flgFisica && (
                                <div className='col'>
                                  <Textbox
                                    label='CNPJ'
                                    text={data.cliente?.nrDocumento}
                                    mask={MaskTypes.CNPJ}
                                    readOnly
                                  />
                                </div>
                              )}
                            </div>
                            <div className='row mb-3'>
                              <div className='col'>
                                <Textbox
                                  label='Endereço'
                                  text={endereco?.noRua}
                                  readOnly
                                />
                              </div>
                              <div className='col-2'>
                                <Textbox
                                  label='Numero'
                                  text={endereco?.numero}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className='row mb-3'>
                              <div className='col'>
                                <Textbox
                                  label='Bairro'
                                  text={endereco?.bairro}
                                  readOnly
                                />
                              </div>
                              <div className='col'>
                                <Textbox
                                  label='Cidade'
                                  text={endereco?.cidade?.noCidade}
                                  readOnly
                                />
                              </div>
                              <div className='col'>
                                <Textbox
                                  label='Cep'
                                  text={endereco?.cep}
                                  readOnly
                                />
                              </div>
                              <div className='col'>
                                <Textbox
                                  label='UF'
                                  text={endereco?.cidade?.estado.cdEstado}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className='row mb-3'>
                              <div className='col'>
                                <Textbox
                                  label='Email'
                                  text={data.cliente?.email}
                                  readOnly
                                />
                              </div>
                              <div className='col'>
                                <Textbox
                                  label='Telefone'
                                  text={data.cliente?.fone}
                                  mask={MaskTypes.CellPhone}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Panel.Body>
                  </Panel>
                </div>
              </div>
              <div className='row mb-4'>
                <div className='col'>
                  <Panel>
                    <Panel.Header
                      title='Plano / Linha / Aparelho'
                      theme={Theme.Primary}
                      align={JustifyContent.Start}
                    />

                    <Panel.Body>
                      <div className='accordion-body'>
                        <div className='row mb-3'>
                          <div className='col'>
                            <div className='card'>
                              <div className='card-body'>
                                <div className='row mb-3'>
                                  <div className='row mb-3'>
                                    <div className='col'>
                                      <Autocomplete
                                        label='Operadora'
                                        enabled={data.flgPermiteAlterar}
                                        required
                                        searchDataSource={onSearchOperadora}
                                        selectedItem={data.operadora}
                                        onSelectItem={(operadora) => {
                                          setData({
                                            ...data,
                                            operadora,
                                            nrSeqOperadoraDestino:
                                              operadora.nrSeqOperadora,
                                            nrSeqOperadora:
                                              operadora.nrSeqOperadora,
                                            plano: {},
                                            nrSeqPlano: undefined,
                                            tipoChamada: {},
                                            nrSeqTipoChamada: undefined,
                                            chip: undefined,
                                            nrSeqChip: undefined,
                                          });
                                          setDataChip({});
                                        }}
                                        dataSourceTextProperty='noOperadora'
                                      />
                                    </div>
                                    <div className='col'>
                                      <Autocomplete
                                        label='Centro de Custo'
                                        enabled={
                                          data.flgPermiteAlterar &&
                                          !!data.empresa?.nrSeqEmpresa
                                        }
                                        placeholder={
                                          !data.empresa?.nrSeqEmpresa
                                            ? 'Seleciona a Empresa'
                                            : ''
                                        }
                                        searchDataSource={onSearchCentroDeCusto}
                                        selectedItem={data.centroDeCusto}
                                        onSelectItem={(centroDeCusto) =>
                                          setData({
                                            ...data,
                                            centroDeCusto,
                                            nrSeqCentroDeCusto:
                                              centroDeCusto.nrSeqCentroDeCusto,
                                          })
                                        }
                                        dataSourceTextProperty='noCentroDeCusto'
                                      />
                                    </div>
                                    <div className='col'>
                                      <Autocomplete
                                        label='Plano'
                                        enabled={
                                          data.flgPermiteAlterar &&
                                          !!data.empresa?.nrSeqEmpresa &&
                                          !!data.operadora?.nrSeqOperadora
                                        }
                                        placeholder={
                                          !data.empresa?.nrSeqEmpresa ||
                                          !data.operadoraDestino?.nrSeqOperadora
                                            ? 'Selecione a Empresa e Operadora'
                                            : ''
                                        }
                                        required
                                        searchDataSource={onSearchPlano}
                                        selectedItem={data.plano}
                                        onSelectItem={(plano) =>
                                          setData({
                                            ...data,
                                            plano,
                                            nrSeqPlano: plano.nrSeqPlano,
                                            tipoChamada: {},
                                            nrSeqTipoChamada: undefined,
                                          })
                                        }
                                        dataSourceTextProperty='noPlano'
                                      />
                                    </div>
                                  </div>
                                  <div className='row mb-3'>
                                    <div className='col-4'>
                                      <Autocomplete
                                        label='Pacote'
                                        enabled={
                                          data.flgPermiteAlterar &&
                                          !!data.plano?.nrSeqPlano
                                        }
                                        required
                                        placeholder={
                                          !data.plano?.nrSeqPlano
                                            ? 'Seleciona o Plano'
                                            : ''
                                        }
                                        searchDataSource={onSearchPacoteDados}
                                        selectedItem={data.tipoChamada}
                                        onSelectItem={async (tipoChamada) => {
                                          if (
                                            'nrSeqTipoChamada' in tipoChamada
                                          ) {
                                            const planoRateio = await getPlanoRateio(
                                              tipoChamada.nrSeqTipoChamada
                                            );

                                            if (planoRateio) {
                                              setHasPlanoRateioDefault(true);

                                              setData({
                                                ...data,
                                                tipoChamada,
                                                nrSeqTipoChamada:
                                                  tipoChamada.nrSeqTipoChamada,
                                                nrSeqTipoChamadaDados:
                                                  tipoChamada.nrSeqTipoChamada,
                                                vlrVenda:
                                                  tipoChamada.vlrVenda ??
                                                  tipoChamada.vlrEncargo,
                                                vlrPacoteEncargoManual:
                                                  tipoChamada.vlrVenda ??
                                                  tipoChamada.vlrEncargo,
                                                planoRateioEmpresa: planoRateio,
                                                nrSeqPlanoRateioEmpresa:
                                                  planoRateio.nrSeqPlano,
                                                chip: undefined,
                                                planoModeloAparelho: undefined,
                                                nrSeqModeloAparelho: undefined,
                                                parcelamentoAparelho: undefined,
                                                aparelho: undefined,
                                                nrSeqAparelho: undefined,
                                              });
                                            } else {
                                              setHasPlanoRateioDefault(false);

                                              setData({
                                                ...data,
                                                tipoChamada,
                                                nrSeqTipoChamada:
                                                  tipoChamada.nrSeqTipoChamada,
                                                nrSeqTipoChamadaDados:
                                                  tipoChamada.nrSeqTipoChamada,
                                                vlrVenda:
                                                  tipoChamada.vlrVenda ??
                                                  tipoChamada.vlrEncargo,
                                                vlrPacoteEncargoManual:
                                                  tipoChamada.vlrVenda ??
                                                  tipoChamada.vlrEncargo,
                                                planoRateioEmpresa: undefined,
                                                nrSeqPlanoRateioEmpresa: undefined,
                                                chip: undefined,
                                                planoModeloAparelho: undefined,
                                                nrSeqModeloAparelho: undefined,
                                                parcelamentoAparelho: undefined,
                                                aparelho: undefined,
                                                nrSeqAparelho: undefined,
                                              });
                                            }
                                          } else {
                                            setHasPlanoRateioDefault(false);

                                            setData({
                                              ...data,
                                              tipoChamada,
                                              nrSeqTipoChamada:
                                                tipoChamada.nrSeqTipoChamada,
                                              nrSeqTipoChamadaDados:
                                                tipoChamada.nrSeqTipoChamada,
                                              noPacoteDados:
                                                tipoChamada.noTipoChamada,
                                              vlrVenda:
                                                tipoChamada.vlrVenda ??
                                                tipoChamada.vlrEncargo,
                                              vlrPacoteEncargoManual:
                                                tipoChamada.vlrVenda ??
                                                tipoChamada.vlrEncargo,
                                              planoRateioEmpresa: undefined,
                                              nrSeqPlanoRateioEmpresa: undefined,
                                              chip: undefined,
                                            });
                                          }

                                          setDarEntrada(false);
                                          setDataChip({});
                                        }}
                                        dataSourceTextProperty='noTipoChamada'
                                      />
                                    </div>
                                    <div className='col-2'>
                                      <Textbox
                                        label='Valor Pacote'
                                        readOnly={
                                          !data.flgPermiteAlterar ||
                                          !data.tipoChamada?.flgPermiteEditar
                                        }
                                        text={data.vlrVenda}
                                        mask={MaskTypes.Decimal}
                                        onChangedValue={(vlrVenda) => {
                                          setData({
                                            ...data,
                                            vlrVenda,
                                          });
                                        }}
                                      />
                                    </div>
                                    <div className='col'>
                                      <Autocomplete
                                        label='Plano de Rateio Empresa'
                                        searchDataSource={onSearchPlanoRateio}
                                        enabled={
                                          data.flgPermiteAlterar &&
                                          !!data.empresa?.nrSeqEmpresa &&
                                          !hasPlanoRateioDefault
                                        }
                                        placeholder={
                                          !data.empresa?.nrSeqEmpresa
                                            ? 'Seleciona a Empresa'
                                            : ''
                                        }
                                        selectedItem={data.planoRateioEmpresa}
                                        onSelectItem={(planoRateioEmpresa) => {
                                          setData({
                                            ...data,
                                            planoRateioEmpresa,
                                            nrSeqPlanoRateioEmpresa:
                                              planoRateioEmpresa.nrSeqPlano,
                                          });
                                        }}
                                        dataSourceTextProperty='noPlano'
                                      />
                                    </div>
                                  </div>
                                  <div className='row mb-3'>
                                    <div className='col-1'>
                                      <Textbox
                                        label='DDD'
                                        enabled={data.flgPermiteAlterar}
                                        visible={
                                          (data.nrSeqTipoChamada > 0 ||
                                            data.nrSeqTipoChamadaDados > 0) &&
                                          data.plano?.nrSeqPlano > 0 &&
                                          data.nrSeqOperadora === 20
                                        }
                                        text={dataChip.nrPrefixo}
                                        onChangedValue={(nrPrefixo) => {
                                          setDataChip({
                                            ...dataChip,
                                            nrPrefixo,
                                          });
                                        }}
                                      />
                                    </div>
                                    <div className='col-3'>
                                      <Autocomplete
                                        label='Pesquisar Linha'
                                        enabled={
                                          data.flgPermiteAlterar &&
                                          !!data.empresa?.nrSeqEmpresa
                                        }
                                        required
                                        searchDataSource={onSearchChip}
                                        selectedItem={dataChip.chip}
                                        onSelectItem={async (chip) => {
                                          await onSelectChip(chip);
                                        }}
                                        dataSourceTextProperty='linhaFormatada'
                                      />
                                    </div>
                                    {(data.nrSeqTipoChamada > 0 ||
                                      data.nrSeqTipoChamadaDados > 0) &&
                                      data.plano?.nrSeqPlano > 0 &&
                                      data.nrSeqOperadora === 20 && (
                                        <div className='col-auto'>
                                          <Switch
                                            formControl
                                            label='Números Especiais'
                                            checked={
                                              data.chip?.flgNumeroEspecial
                                            }
                                            onChange={(flgNumeroEspecial) =>
                                              setDataChip({
                                                ...dataChip,
                                                flgNumeroEspecial,
                                              })
                                            }
                                          />
                                        </div>
                                      )}
                                    <div className='col-3'>
                                      <Textbox
                                        required={
                                          data.operadora?.nrSeqOperadora !==
                                            1 &&
                                          data.operadora?.nrSeqOperadora !==
                                            2 &&
                                          data.operadora?.nrSeqOperadora !== 6
                                        }
                                        label='ICCID'
                                        enabled={data.flgPermiteAlterar}
                                        maxLength={20}
                                        text={dataChip.nrIccid}
                                        onChangedValue={(nrIccid) => {
                                          setDataChip({
                                            ...dataChip,
                                            nrIccid,
                                          });

                                          setData({
                                            ...data,
                                            chip: data.chip,
                                          });
                                        }}
                                      />
                                    </div>
                                    <div className='col'>
                                      <Autocomplete
                                        label='Modelos de Aparelhos em Estoque'
                                        enabled={data.flgPermiteAlterar}
                                        visible={data.plano?.nrSeqPlano > 0}
                                        searchDataSource={
                                          onSearchPlanoModeloAparelho
                                        }
                                        selectedItem={data.planoModeloAparelho}
                                        onSelectItem={(planoModeloAparelho) => {
                                          setData({
                                            ...data,
                                            planoModeloAparelho,
                                            nrSeqModeloAparelho:
                                              planoModeloAparelho.nrSeqModeloAparelho,
                                            parcelamentoAparelho: {
                                              nrParcelas:
                                                planoModeloAparelho.nrParcelas,
                                              vlrOriginal:
                                                planoModeloAparelho.vlrTotal,
                                            },
                                            aparelho: undefined,
                                            nrSeqAparelho: undefined,
                                          });

                                          setDarEntrada(false);
                                          setTituloReceber({});
                                        }}
                                        dataSourceTextProperty='noModeloAparelho'
                                      />
                                    </div>
                                  </div>
                                  {data.planoModeloAparelho
                                    ?.nrSeqModeloAparelho != null && (
                                    <>
                                      <div className='row mb-3'>
                                        <div className='col'>
                                          <Autocomplete
                                            label='IMEI do Aparelho'
                                            enabled={data.flgPermiteAlterar}
                                            required={
                                              !!data.nrSeqModeloAparelho
                                            }
                                            visible={
                                              data.planoModeloAparelho
                                                ?.nrSeqModeloAparelho != null
                                            }
                                            searchDataSource={onSearchAparelho}
                                            selectedItem={data.aparelho}
                                            onSelectItem={(aparelho) =>
                                              setData({
                                                ...data,
                                                aparelho,
                                                nrSeqAparelho:
                                                  aparelho.nrSeqAparelho,
                                              })
                                            }
                                            dataSourceTextProperty='nrImei'
                                          />
                                        </div>
                                        <div className='col-1'>
                                          <Textbox
                                            label='Parcelas'
                                            required={
                                              !!data.nrSeqModeloAparelho
                                            }
                                            readOnly={
                                              !data.flgPermiteAlterar &&
                                              !data.parcelamentoAparelho
                                                ?.flgAutorizaEditar
                                            }
                                            text={
                                              data.parcelamentoAparelho
                                                ?.nrParcelas
                                            }
                                            mask={MaskTypes.Integer}
                                            onChangedValue={(nrParcelas) =>
                                              setData({
                                                ...data,
                                                parcelamentoAparelho: {
                                                  ...data.parcelamentoAparelho,
                                                  nrParcelas,
                                                },
                                              })
                                            }
                                          />
                                        </div>
                                        <div className='col'>
                                          <Textbox
                                            label='Valor Aparelho'
                                            readOnly
                                            text={
                                              data.parcelamentoAparelho
                                                ?.vlrOriginal
                                            }
                                            mask={MaskTypes.Currency}
                                          />
                                        </div>
                                        <div className='col'>
                                          <Textbox
                                            label='Valor Parcela'
                                            readOnly
                                            text={
                                              data.parcelamentoAparelho
                                                ?.vlrOriginal /
                                              data.parcelamentoAparelho
                                                ?.nrParcelas
                                            }
                                            mask={MaskTypes.Currency}
                                          />
                                        </div>
                                        <div className='col-2'>
                                          <Switch
                                            formControl
                                            label='Dar Entrada'
                                            checked={darEntrada}
                                            onChange={(value) => {
                                              setDarEntrada(value);
                                              setTituloReceber({});
                                            }}
                                          />
                                        </div>
                                      </div>
                                      {darEntrada && (
                                        <div className='row mb-3'>
                                          <div className='col'>
                                            <Textbox
                                              label='Valor da Entrada'
                                              required={darEntrada}
                                              text={
                                                tituloReceber.vlrTitulo ?? 0
                                              }
                                              onChangedValue={(vlrTitulo) =>
                                                setTituloReceber({
                                                  ...tituloReceber,
                                                  vlrTitulo,
                                                })
                                              }
                                              mask={MaskTypes.Decimal}
                                            />
                                          </div>
                                          <div className='col-2'>
                                            <DatePicker
                                              label='Vencimento da Entrada'
                                              required={darEntrada}
                                              text={tituloReceber.dtVencimento}
                                              maxLength={10}
                                              mask={MaskTypes.Date}
                                              onChange={(dtVencimento) =>
                                                setTituloReceber({
                                                  ...tituloReceber,
                                                  dtVencimento,
                                                })
                                              }
                                            />
                                          </div>
                                          <div className='col'>
                                            <Autocomplete
                                              label='Tipo Receita'
                                              required={darEntrada}
                                              searchDataSource={
                                                onSearchTituloEspecie
                                              }
                                              selectedItem={
                                                tituloReceber.tituloEspecie
                                              }
                                              onSelectItem={(tituloEspecie) =>
                                                setTituloReceber({
                                                  ...tituloReceber,
                                                  tituloEspecie,
                                                  nrSeqTituloEspecie:
                                                    tituloEspecie.nrSeqTituloEspecie,
                                                })
                                              }
                                              dataSourceTextProperty='noEspecie'
                                            />
                                          </div>
                                          <div className='col'>
                                            <Autocomplete
                                              label='Forma Pagamento da Entrada'
                                              required={darEntrada}
                                              searchDataSource={
                                                onAutoCompleteFormaPagamento
                                              }
                                              selectedItem={
                                                tituloReceber.formaPagamento
                                              }
                                              onSelectItem={(formaPagamento) =>
                                                setTituloReceber({
                                                  ...tituloReceber,
                                                  formaPagamento,
                                                  nrSeqFormaPagamento:
                                                    formaPagamento.nrSeqFormaPagamento,
                                                })
                                              }
                                              dataSourceTextProperty='noFormaPagamento'
                                            />
                                          </div>
                                          <div className='col'>
                                            <Autocomplete
                                              label='Informação Bancária'
                                              required={
                                                tituloReceber.formaPagamento
                                                  ?.flgGrupo === 'D'
                                              }
                                              visible={
                                                tituloReceber.formaPagamento !==
                                                  undefined &&
                                                tituloReceber.formaPagamento
                                                  ?.flgGrupo === 'D'
                                              }
                                              searchDataSource={
                                                onSearchInformacaoBancariaEntrada
                                              }
                                              selectedItem={
                                                tituloReceber.informacaoBancaria
                                              }
                                              onSelectItem={(
                                                informacaoBancaria
                                              ) =>
                                                setTituloReceber({
                                                  ...tituloReceber,
                                                  informacaoBancaria,
                                                  nrSeqPessoaInformacaoBancaria:
                                                    informacaoBancaria.nrSeqPessoaInformacaoBancaria,
                                                })
                                              }
                                              dataSourceTextProperty='noDescricao'
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Panel.Body>
                  </Panel>
                </div>
              </div>
              <div className='row mb-4'>
                <div className='col'>
                  <Panel>
                    <Panel.Header
                      title='Informação Pagamento'
                      theme={Theme.Primary}
                      align={JustifyContent.Start}
                    />

                    <Panel.Body>
                      <div className='accordion-body'>
                        <div className='row mb-3'>
                          <div className='col'>
                            <div className='card'>
                              <div className='card-body'>
                                <div className='row mb-3'>
                                  <div className='row'>
                                    <div className='row mb-3'>
                                      <div className='col'>
                                        <Autocomplete
                                          label='Forma de Pagamento'
                                          enabled={data.flgPermiteAlterar}
                                          required
                                          searchDataSource={
                                            onAutoCompleteFormaPagamento
                                          }
                                          selectedItem={data.formaPagamento}
                                          onSelectItem={(formaPagamento) => {
                                            data.formaPagamento = formaPagamento;
                                            data.nrSeqFormaPagamento =
                                              formaPagamento.nrSeqFormaPagamento ??
                                              undefined;

                                            onSelectFormaPagamento(
                                              formaPagamento
                                            );
                                          }}
                                          dataSourceTextProperty='noFormaPagamento'
                                        />
                                      </div>
                                      <div className='col-2'>
                                        <Autocomplete
                                          label='Vencimento'
                                          enabled={data.flgPermiteAlterar}
                                          required
                                          searchDataSource={onSearchVencimento}
                                          selectedItem={data.vencimento}
                                          onSelectItem={(vencimento) =>
                                            setData({
                                              ...data,
                                              vencimento,
                                              nrSeqVencimento:
                                                vencimento.nrSeqVencimento,
                                            })
                                          }
                                          dataSourceTextProperty='noVencimento'
                                        />
                                      </div>
                                      <div className='col'>
                                        <Autocomplete
                                          label='Informação Bancária'
                                          enabled={data.flgPermiteAlterar}
                                          visible={
                                            data.nrSeqFormaPagamento !== null &&
                                            data.nrSeqFormaPagamento !==
                                              undefined &&
                                            data.formaPagamento?.flgGrupo ===
                                              'D'
                                          }
                                          searchDataSource={
                                            onSearchInformacaoBancaria
                                          }
                                          selectedItem={data.informacaoBancaria}
                                          onSelectItem={(informacaoBancaria) =>
                                            setData({
                                              ...data,
                                              informacaoBancaria,
                                              nrSeqPessoaInformacaoBancaria:
                                                informacaoBancaria.nrSeqPessoaInformacaoBancaria,
                                            })
                                          }
                                          dataSourceTextProperty='noDescricao'
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Panel.Body>
                  </Panel>
                </div>
              </div>
              <div className='row mb-4'>
                <div className='col'>
                  <Panel>
                    <Panel.Header
                      title='Contrato'
                      theme={Theme.Primary}
                      align={JustifyContent.Start}
                    />

                    <Panel.Body>
                      <div className='accordion-body'>
                        <div className='row mb-3'>
                          <div className='col'>
                            <div className='card'>
                              <div className='card-body'>
                                {clienteMessage.showMessage && (
                                  <div className='row mb-2'>
                                    <div className='col'>
                                      <div className='card border-danger'>
                                        <div className='card-body text-danger'>
                                          <p className='card-text'>
                                            {clienteMessage.message}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div className='row mb-3'>
                                  <div className='col-2'>
                                    <DatePicker
                                      label='Data de Início de Contrato'
                                      required
                                      readOnly={!data.flgPermiteAlterar}
                                      maxLength={10}
                                      text={data.dtSolicitacao}
                                      mask={MaskTypes.Date}
                                      onChange={(dtSolicitacao) => {
                                        setData({
                                          ...data,
                                          dtSolicitacao,
                                        });
                                      }}
                                    />
                                  </div>
                                  {'cycle' in contratoMessage &&
                                    dataChip.nrSeqChip !== undefined && (
                                      <div className='col'>
                                        <div
                                          className={`card border-${contratoMessage.color}`}
                                        >
                                          <div
                                            className={`card-body text-${contratoMessage.color}`}
                                          >
                                            <p className='card-text mb-0'>
                                              {contratoMessage.cycle}
                                            </p>
                                            {contratoMessage.message.length >
                                              0 && (
                                              <p className='card-text'>
                                                {contratoMessage.message}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                </div>
                                <div className='row mb-3'>
                                  <div className='col-2 align-self-center'>
                                    <Button
                                      theme={Theme.Primary}
                                      template={Button.Templates.Button}
                                      text='Gerar Contrato'
                                      onClick={onGerarContrato}
                                    />
                                  </div>

                                  <div className='col-6'>
                                    <FileUpload
                                      files={importedFiles}
                                      onChange={(files) => {
                                        if (files.length > 0) {
                                          if (files[0].size <= 5000000) {
                                            setImportedFiles(files);
                                          } else {
                                            onSetMessage(
                                              false,
                                              'Arquivo não pode ser maior que 5MB'
                                            );
                                          }
                                        } else {
                                          setImportedFiles(files);
                                        }
                                      }}
                                      allowedMimeTypes={[
                                        MimeTypes.Types.PDF,
                                        MimeTypes.Types.Image,
                                        MimeTypes.Types.Word,
                                      ]}
                                    />
                                  </div>
                                  <div className='col align-self-center'>
                                    <Button
                                      text='Adicionar Documento'
                                      className='px-5'
                                      theme={Theme.Success}
                                      template={Button.Templates.Button}
                                      onClick={() => onClickAddDocumento()}
                                    />
                                  </div>
                                </div>
                                <div className='row mb-3'>
                                  <div className='col'>
                                    <GridView
                                      ref={gridView}
                                      className='table-striped table-hover table-bordered table-sm'
                                      dataColumns={columns}
                                      showPagination={false}
                                      showSelectSizes={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Panel.Body>
                  </Panel>
                </div>
              </div>
              <div className='row mb-4'>
                <div className='col'>
                  <Panel>
                    <Panel.Header
                      title='Resumo'
                      theme={Theme.Primary}
                      align={JustifyContent.Start}
                    />

                    <Panel.Body>
                      <div className='accordion-body'>
                        <div className='row mb-3'>
                          <div className='col'>
                            <div className='card'>
                              <div className='card-body'>
                                <div className='row mb-3'>
                                  <div className='row'>
                                    <div className='row mb-3'>
                                      <div className='col'>
                                        <h5>Dados do Contrato</h5>
                                        <div className='row mb-3'>
                                          <div className='col'>
                                            <Textbox
                                              label='Nome'
                                              text={resumo.nome}
                                              readOnly
                                            />
                                          </div>
                                          <div
                                            className='col-2 mt-2 widget-square bg-gradient-primary text-white p-3'
                                            style={{
                                              textAlign: 'center',
                                            }}
                                          >
                                            Protocolo:
                                            {resumo.protocolo ?? ''}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='row mb-3'>
                                      <div className='col'>
                                        <h5>Dados do Plano</h5>
                                        <div className='row mb-3'>
                                          <div className='col'>
                                            <Textbox
                                              label='Operadora'
                                              text={resumo.operadora}
                                              readOnly
                                            />
                                          </div>
                                          <div className='col'>
                                            <Textbox
                                              label='Plano'
                                              text={resumo.plano}
                                              readOnly
                                            />
                                          </div>
                                          <div className='col-2'>
                                            <Textbox
                                              label='Linha'
                                              text={resumo.linha}
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Panel.Body>
                  </Panel>
                </div>
              </div>
            </>
          );
        }
        // Gera -> Novo Contrato Massivo
        if (
          !data.tipoDemanda?.flgVersaoOld &&
          data.tipoDemanda?.cdTipoDemanda === 'Novo Contrato Massivo'
        ) {
          return (
            <ManutencaoNovoContratoMassivo
              data={data}
              setData={setData}
              onSetMessage={onSetMessage}
              setDataListDocRemove={setDataListDocRemove}
              documentos={documentos}
              setDocumentos={setDocumentos}
              onOpenTransaction={onOpenTransaction}
              findTransaction={findTransaction}
              setModalAlterarFormaPagamento={setModalAlterarFormaPagamento}
              dataMassivo={dataMassivo}
              setDataMassivo={setDataMassivo}
              clienteMessage={clienteMessage}
              contratoMessage={contratoMessage}
              infoPagamentoDefault={infoPagamentoDefault}
              onSelectCliente={onSelectCliente}
              onSelectFormaPagamento={onSelectFormaPagamento}
              setLoading={setLoading}
              onAutoCompleteFormaPagamento={onAutoCompleteFormaPagamento}
            />
          );
        }
        // Gera -> Portabilidade
        if (
          !data.tipoDemanda?.flgVersaoOld &&
          data.tipoDemanda?.cdTipoDemanda === 'Portabilidade'
        ) {
          return (
            <div>
              <div className='row mb-3'>
                <div className='col-auto'>
                  <RadioButton
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    buttons={Titular}
                    selectedButton={dataTitular.flgMesmoTitular}
                    onChange={(flgMesmoTitular) => {
                      setData({
                        ...data,
                        clienteTitularOld: {},
                        nrSeqPessoaOld: undefined,
                      });
                      setEnderecoTitularOld({});
                      setDataTitular({ ...dataTitular, flgMesmoTitular });
                    }}
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <div className='card'>
                    <div className='card-body'>
                      <div className='row mb-3'>
                        <div className='col-2'>
                          <Textbox
                            label='Nr. Linha Portada'
                            readOnly={!data.flgPermiteAlterar}
                            required
                            text={dataChip.nrPrefixoLinha}
                            mask={MaskTypes.CellPhone}
                            onChangedValue={(nrPrefixoLinha) => {
                              setDataChip({
                                ...dataChip,
                                nrPrefixoLinha,
                              });
                            }}
                            onBlur={(param1, param2) => {
                              if (param2 && param2.length < 11) {
                                onSetMessage(1, 'Informe o Prefixo.');
                              }
                            }}
                          />
                        </div>
                        <div className='col-2'>
                          <Textbox
                            label='ICCID'
                            readOnly={!data.flgPermiteAlterar}
                            text={dataChip.nrIccid}
                            maxLength={20}
                            onChangedValue={(nrIccid) => {
                              setDataChip({
                                ...dataChip,
                                nrIccid,
                              });
                              setData({ ...data, nrIccid });
                            }}
                          />
                        </div>
                        <div className='col-2'>
                          <Textbox
                            label='Nr. Linha Provisória'
                            text={data.chipNovo?.nrPrefixoLinha}
                            readOnly={
                              !data.flgPermiteAlterar &&
                              data.flgPermiteAlterarLinhaProvisoria
                            }
                            mask={MaskTypes.CellPhone}
                            onChangedValue={(nrPrefixoLinhaProvisorio) => {
                              const nrPrefixoLinha = nrPrefixoLinhaProvisorio.replace(
                                /\D/g,
                                ''
                              );
                              const nrPrefixoProvisorio = nrPrefixoLinha.substring(
                                0,
                                2
                              );

                              const nrLinhaProvisoria = nrPrefixoLinha.substring(
                                2
                              );

                              setData({
                                ...data,
                                nrPrefixoProvisorio,
                                nrLinhaProvisoria,
                                chipNovo: { nrPrefixoLinha },
                              });
                            }}
                            onBlur={(param1, param2) => {
                              if (param2 && param2.length < 11) {
                                onSetMessage(1, 'Informe o Prefixo.');
                              }
                            }}
                          />
                        </div>
                        {data.nrSeqDemanda > 0 &&
                          data.flgPermiteAlterarLinhaProvisoria && (
                            <div className='col mt-3'>
                              <Button
                                theme={Theme.Primary}
                                template={Button.Templates.Button}
                                text='Trocar Linha Provisória'
                                onClick={onTrocarProvisorio}
                              />
                            </div>
                          )}
                        <div className='col-2'>
                          <DatePicker
                            label='Data de Solicitação'
                            readOnly={!data.flgPermiteAlterar}
                            text={data.dtSolicitacao}
                            maxLength={10}
                            mask={MaskTypes.Date}
                          />
                        </div>
                        <div className='col-2'>
                          <DatePicker
                            label='Data de Envio Operadora'
                            readOnly={!data.flgPermiteAlterar}
                            text={data.dtEnvioOperadora}
                            maxLength={10}
                            mask={MaskTypes.Date}
                          />
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-1'>
                          <Textbox
                            label='DDD'
                            visible={trocarProvisorio}
                            enabled={data.flgPermiteAlterar}
                            text={data.nrPrefixoProvisorio}
                            onChangedValue={(nrPrefixoProvisorio) => {
                              setData({
                                ...data,
                                nrPrefixoProvisorio,
                              });
                            }}
                          />
                        </div>
                        <div className='col-3'>
                          <Autocomplete
                            label='Pesquisar Linha'
                            visible={trocarProvisorio}
                            enabled={data.flgPermiteAlterar}
                            required
                            searchDataSource={onSearchChip}
                            selectedItem={data.chipProvisorio}
                            onSelectItem={(chipProvisorio) => {
                              setData({
                                ...data,
                                chipProvisorio,
                                nrLinhaProvisoria: chipProvisorio.nrLinha,
                              });
                            }}
                            dataSourceTextProperty='linhaFormatada'
                          />
                        </div>
                        <div className='col-auto'>
                          <Switch
                            formControl
                            visible={trocarProvisorio}
                            label='Números Especiais'
                            checked={data.chip?.flgNumeroEspecial}
                            onChange={(flgNumeroEspecial) =>
                              setDataChip({
                                ...dataChip,
                                flgNumeroEspecial,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col'>
                          <Autocomplete
                            label='Cliente'
                            enabled={data.flgPermiteAlterar}
                            required
                            searchDataSource={onSearchCliente}
                            minLengthTextSearch={3}
                            placeholder='Digite ao menos 3 caracteres para buscar Cliente'
                            selectedItem={data.cliente}
                            findTransaction={findTransaction}
                            onOpenTransaction={onOpenTransaction}
                            transactionToOpen={TransacationCodes.Cliente}
                            nrseqTransactionToOpen='nrSeqPessoaCli'
                            onSelectItem={async (cliente) => {
                              data.nrSeqPessoaCli =
                                cliente.nrSeqPessoaCli ?? undefined;
                              data.cliente = cliente;

                              onSelectCliente(cliente);

                              setEndereco(cliente.endereco ?? new Endereco());
                            }}
                            dataSourceTextProperty='noPessoa'
                          />
                        </div>
                      </div>

                      <div className='row mb-3'>
                        <div className='row mb-3'>
                          <div className='row mb-3'>
                            <div className='row'>
                              <div className='row mb-3'>
                                <div className='col'>
                                  <Textbox
                                    label='RG'
                                    text={data.cliente?.nrRG}
                                    visible={data.cliente?.flgFisica}
                                    maxLength={15}
                                    readOnly
                                    disabledTabIndex
                                  />
                                </div>
                                <div className='col'>
                                  <Textbox
                                    label='CPF'
                                    text={data.cliente?.nrDocumento}
                                    mask={MaskTypes.CPF}
                                    visible={data.cliente?.flgFisica}
                                    readOnly
                                    disabledTabIndex
                                  />
                                </div>
                                <div className='col'>
                                  <Textbox
                                    label='CNPJ'
                                    text={data.cliente?.nrDocumento}
                                    mask={MaskTypes.CNPJ}
                                    visible={!data.cliente?.flgFisica}
                                    readOnly
                                    disabledTabIndex
                                  />
                                </div>
                              </div>
                              <div className='row mb-3'>
                                <div className='col'>
                                  <Textbox
                                    label='Endereço'
                                    text={endereco?.noRua}
                                    readOnly
                                    disabledTabIndex
                                  />
                                </div>
                                <div className='col-2'>
                                  <Textbox
                                    label='Numero'
                                    text={endereco?.numero}
                                    readOnly
                                    disabledTabIndex
                                  />
                                </div>
                              </div>
                              <div className='row mb-3'>
                                <div className='col'>
                                  <Textbox
                                    label='Bairro'
                                    text={endereco?.bairro}
                                    readOnly
                                    disabledTabIndex
                                  />
                                </div>
                                <div className='col'>
                                  <Textbox
                                    label='Cidade'
                                    text={endereco?.cidade?.noCidade}
                                    readOnly
                                    disabledTabIndex
                                  />
                                </div>
                                <div className='col'>
                                  <Textbox
                                    label='Cep'
                                    text={endereco?.cep}
                                    readOnly
                                    disabledTabIndex
                                  />
                                </div>
                                <div className='col'>
                                  <Textbox
                                    label='UF'
                                    readOnly
                                    disabledTabIndex
                                  />
                                </div>
                              </div>
                              <div className='row mb-3'>
                                <div className='col'>
                                  <Textbox
                                    label='Email'
                                    text={data.cliente?.email}
                                    readOnly
                                    disabledTabIndex
                                  />
                                </div>
                                <div className='col'>
                                  <Textbox
                                    label='Telefone'
                                    text={data.cliente?.fone}
                                    mask={MaskTypes.CellPhone}
                                    readOnly
                                    disabledTabIndex
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {dataTitular.flgMesmoTitular === 'N' && (
                            <div className='row mb-3'>
                              <div className='row mb-2'>
                                <Panel>
                                  <Panel.Header
                                    title='Titularidade da Linha de Origem'
                                    theme={Theme.Primary}
                                    align={JustifyContent.Start}
                                  />
                                  <Panel.Body>
                                    <div className='row mb-3'>
                                      <div className='col'>
                                        <Autocomplete
                                          label='Titular'
                                          enabled={data.flgPermiteAlterar}
                                          required
                                          searchDataSource={onSearchCliente}
                                          minLengthTextSearch={3}
                                          placeholder='Digite ao menos 3 caracteres para buscar Cliente'
                                          selectedItem={data.clienteTitularOld}
                                          findTransaction={findTransaction}
                                          onOpenTransaction={onOpenTransaction}
                                          transactionToOpen={
                                            TransacationCodes.Cliente
                                          }
                                          nrseqTransactionToOpen='nrSeqPessoaCli'
                                          onSelectItem={(clienteTitularOld) => {
                                            setData({
                                              ...data,
                                              clienteTitularOld,
                                              nrSeqPessoaOld:
                                                clienteTitularOld.nrSeqPessoaCli,
                                            });

                                            setEnderecoTitularOld(
                                              clienteTitularOld.endereco ??
                                                new Endereco()
                                            );
                                          }}
                                          dataSourceTextProperty='noPessoa'
                                        />
                                      </div>
                                    </div>
                                    <div className='row mb-3'>
                                      <div className='col'>
                                        <Textbox
                                          label='RG'
                                          text={data.clienteTitularOld?.nrRG}
                                          maxLength={15}
                                          visible={
                                            data.clienteTitularOld?.flgFisica
                                          }
                                          readOnly
                                          disabledTabIndex
                                        />
                                      </div>
                                      <div className='col'>
                                        <Textbox
                                          label='CPF'
                                          text={
                                            data.clienteTitularOld?.nrDocumento
                                          }
                                          mask={MaskTypes.CPF}
                                          visible={
                                            data.clienteTitularOld?.flgFisica
                                          }
                                          readOnly
                                          disabledTabIndex
                                        />
                                      </div>
                                      <div className='col'>
                                        <Textbox
                                          label='CNPJ'
                                          text={
                                            data.clienteTitularOld?.nrDocumento
                                          }
                                          mask={MaskTypes.CNPJ}
                                          visible={
                                            !data.clienteTitularOld.flgFisica
                                          }
                                          readOnly
                                          disabledTabIndex
                                        />
                                      </div>
                                    </div>
                                    <div className='row mb-3'>
                                      <div className='col'>
                                        <Textbox
                                          label='Endereço'
                                          text={enderecoTitularOld?.noRua}
                                          readOnly
                                          disabledTabIndex
                                        />
                                      </div>
                                      <div className='col-2'>
                                        <Textbox
                                          label='Numero'
                                          text={enderecoTitularOld?.numero}
                                          readOnly
                                          disabledTabIndex
                                        />
                                      </div>
                                    </div>
                                    <div className='row mb-3'>
                                      <div className='col'>
                                        <Textbox
                                          label='Bairro'
                                          text={enderecoTitularOld?.bairro}
                                          readOnly
                                          disabledTabIndex
                                        />
                                      </div>
                                      <div className='col'>
                                        <Textbox
                                          label='Cidade'
                                          text={
                                            enderecoTitularOld?.cidade?.noCidade
                                          }
                                          readOnly
                                          disabledTabIndex
                                        />
                                      </div>
                                      <div className='col'>
                                        <Textbox
                                          label='Cep'
                                          text={enderecoTitularOld?.cep}
                                          readOnly
                                          disabledTabIndex
                                        />
                                      </div>
                                      <div className='col'>
                                        <Textbox
                                          label='UF'
                                          text={
                                            enderecoTitularOld?.cidade?.estado
                                              .cdEstado
                                          }
                                          readOnly
                                          disabledTabIndex
                                        />
                                      </div>
                                    </div>
                                    <div className='row mb-3'>
                                      <div className='col'>
                                        <Textbox
                                          label='Email'
                                          text={data.clienteTitularOld?.email}
                                          readOnly
                                          disabledTabIndex
                                        />
                                      </div>
                                      <div className='col'>
                                        <Textbox
                                          label='Telefone'
                                          text={data.clienteTitularOld?.fone}
                                          mask={MaskTypes.CellPhone}
                                          readOnly
                                          disabledTabIndex
                                        />
                                      </div>
                                    </div>
                                  </Panel.Body>
                                </Panel>
                              </div>
                            </div>
                          )}
                          <div className='row mb-3'>
                            <div className='col'>
                              <Autocomplete
                                label='Operadora Origem'
                                enabled={data.flgPermiteAlterar}
                                required
                                searchDataSource={onSearchOperadora}
                                selectedItem={data.operadoraOrigem}
                                onSelectItem={(operadoraOrigem) =>
                                  setData({
                                    ...data,
                                    operadoraOrigem,
                                    nrSeqOperadoraOrigem:
                                      operadoraOrigem.nrSeqOperadora,
                                    plano: {},
                                    tipoChamada: {},
                                  })
                                }
                                dataSourceTextProperty='noOperadora'
                              />
                            </div>
                            <div className='col'>
                              <Autocomplete
                                label='Operadora Destino'
                                enabled={data.flgPermiteAlterar}
                                required
                                searchDataSource={onSearchOperadora}
                                selectedItem={data.operadoraDestino}
                                onSelectItem={(operadoraDestino) =>
                                  setData({
                                    ...data,
                                    operadoraDestino,
                                    nrSeqOperadoraDestino:
                                      operadoraDestino.nrSeqOperadora,
                                    operadora: operadoraDestino,
                                    nrSeqOperadora:
                                      operadoraDestino.nrSeqOperadora,
                                    plano: {},
                                    tipoChamada: {},
                                  })
                                }
                                dataSourceTextProperty='noOperadora'
                              />
                            </div>
                            <div className='col-4'>
                              <Autocomplete
                                label='Centro de Custo'
                                enabled={data.flgPermiteAlterar}
                                searchDataSource={onSearchCentroDeCusto}
                                selectedItem={data.centroDeCusto}
                                onSelectItem={(centroDeCusto) =>
                                  setData({
                                    ...data,
                                    centroDeCusto,
                                    nrSeqCentroDeCusto:
                                      centroDeCusto.nrSeqCentroDeCusto,
                                  })
                                }
                                dataSourceTextProperty='noCentroDeCusto'
                              />
                            </div>
                          </div>

                          <div className='row mb-3'>
                            <div className='col'>
                              <Autocomplete
                                label='Plano'
                                enabled={data.flgPermiteAlterar}
                                required
                                searchDataSource={onSearchPlanoOperadoraDestivo}
                                selectedItem={data.plano}
                                onSelectItem={(plano) =>
                                  setData({
                                    ...data,
                                    plano,
                                    nrSeqPlano: plano.nrSeqPlano,
                                    tipoChamada: {},
                                  })
                                }
                                dataSourceTextProperty='noPlano'
                              />
                            </div>
                            <div className='col'>
                              <Autocomplete
                                label='Pacote'
                                enabled={
                                  data.flgPermiteAlterar &&
                                  !!data.plano?.nrSeqPlano
                                }
                                placeholder={
                                  !data.plano?.nrSeqPlano
                                    ? 'Selecione e o Plano'
                                    : ''
                                }
                                required
                                searchDataSource={onSearchPacoteDados}
                                selectedItem={data.tipoChamada}
                                onSelectItem={async (tipoChamada) => {
                                  if ('nrSeqTipoChamada' in tipoChamada) {
                                    const planoRateio = await getPlanoRateio(
                                      tipoChamada.nrSeqTipoChamada
                                    );

                                    if (planoRateio) {
                                      setHasPlanoRateioDefault(true);

                                      setData({
                                        ...data,
                                        tipoChamada,
                                        nrSeqTipoChamada:
                                          tipoChamada.nrSeqTipoChamada,
                                        nrSeqTipoChamadaDados:
                                          tipoChamada.nrSeqTipoChamada,
                                        vlrVenda:
                                          tipoChamada.vlrVenda ??
                                          tipoChamada.vlrEncargo,
                                        vlrPacoteEncargoManual:
                                          tipoChamada.vlrVenda ??
                                          tipoChamada.vlrEncargo,
                                        planoRateioEmpresa: planoRateio,
                                        nrSeqPlanoRateioEmpresa:
                                          planoRateio.nrSeqPlano,
                                      });
                                    } else {
                                      setHasPlanoRateioDefault(false);

                                      setData({
                                        ...data,
                                        tipoChamada,
                                        nrSeqTipoChamada:
                                          tipoChamada.nrSeqTipoChamada,
                                        nrSeqTipoChamadaDados:
                                          tipoChamada.nrSeqTipoChamada,
                                        vlrVenda:
                                          tipoChamada.vlrVenda ??
                                          tipoChamada.vlrEncargo,
                                        vlrPacoteEncargoManual:
                                          tipoChamada.vlrVenda ??
                                          tipoChamada.vlrEncargo,
                                      });
                                    }
                                  } else {
                                    setHasPlanoRateioDefault(false);

                                    setData({
                                      ...data,
                                      tipoChamada,
                                      nrSeqTipoChamada:
                                        tipoChamada.nrSeqTipoChamada,
                                      nrSeqTipoChamadaDados:
                                        tipoChamada.nrSeqTipoChamada,
                                      noPacoteDados: tipoChamada.noTipoChamada,
                                      vlrVenda:
                                        tipoChamada.vlrVenda ??
                                        tipoChamada.vlrEncargo,
                                      vlrPacoteEncargoManual:
                                        tipoChamada.vlrVenda ??
                                        tipoChamada.vlrEncargo,
                                    });
                                  }
                                }}
                                dataSourceTextProperty='noTipoChamada'
                              />
                            </div>
                            <div className='col-2'>
                              <Textbox
                                label='Valor Pacote'
                                visible={
                                  (data.nrSeqTipoChamada > 0 ||
                                    data.nrSeqTipoChamadaDados > 0) &&
                                  data.plano?.nrSeqPlano > 0
                                }
                                text={data.vlrVenda}
                                mask={MaskTypes.Decimal}
                                readOnly={
                                  !data.tipoChamada.flgPermiteEditar &&
                                  !data.flgPermiteEditar
                                }
                                onChangedValue={(vlrVenda) => {
                                  setData({
                                    ...data,
                                    vlrVenda,
                                    vlrPacoteEncargoManual: vlrVenda,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className='row mb-3'>
                            <div className='col-4'>
                              <Autocomplete
                                label='Plano de Rateio Empresa'
                                searchDataSource={onSearchPlanoRateio}
                                enabled={
                                  data.flgPermiteAlterar &&
                                  !!data.empresa?.nrSeqEmpresa &&
                                  !hasPlanoRateioDefault
                                }
                                placeholder={
                                  !data.empresa?.nrSeqEmpresa
                                    ? 'Seleciona a Empresa'
                                    : ''
                                }
                                selectedItem={data.planoRateioEmpresa}
                                onSelectItem={(planoRateioEmpresa) => {
                                  setData({
                                    ...data,
                                    planoRateioEmpresa,
                                    nrSeqPlanoRateioEmpresa:
                                      planoRateioEmpresa.nrSeqPlano,
                                  });
                                }}
                                dataSourceTextProperty='noPlano'
                              />
                            </div>
                            <div className='col'>
                              <Autocomplete
                                label='Forma de Pagamento'
                                enabled={data.flgPermiteAlterar}
                                required
                                searchDataSource={onAutoCompleteFormaPagamento}
                                selectedItem={data.formaPagamento}
                                onSelectItem={(formaPagamento) => {
                                  data.formaPagamento = formaPagamento;
                                  data.nrSeqFormaPagamento =
                                    formaPagamento.nrSeqFormaPagamento ??
                                    undefined;

                                  onSelectFormaPagamento(formaPagamento);
                                }}
                                dataSourceTextProperty='noFormaPagamento'
                              />
                            </div>
                            <div className='col-2'>
                              <Autocomplete
                                label='Vencimento'
                                enabled={data.flgPermiteAlterar}
                                required
                                searchDataSource={onSearchVencimento}
                                selectedItem={data.vencimento}
                                onSelectItem={(vencimento) =>
                                  setData({
                                    ...data,
                                    vencimento,
                                    nrSeqVencimento: vencimento.nrSeqVencimento,
                                  })
                                }
                                dataSourceTextProperty='noVencimento'
                              />
                            </div>
                            <div className='col'>
                              <Autocomplete
                                label='Informação Bancária'
                                enabled={data.flgPermiteAlterar}
                                visible={
                                  data.nrSeqFormaPagamento !== null &&
                                  data.nrSeqFormaPagamento !== undefined &&
                                  data.formaPagamento?.flgGrupo === 'D'
                                }
                                searchDataSource={onSearchInformacaoBancaria}
                                selectedItem={data.informacaoBancaria}
                                onSelectItem={(informacaoBancaria) =>
                                  setData({
                                    ...data,
                                    informacaoBancaria,
                                    nrSeqPessoaInformacaoBancaria:
                                      informacaoBancaria.nrSeqPessoaInformacaoBancaria,
                                  })
                                }
                                dataSourceTextProperty='noDescricao'
                              />
                            </div>
                          </div>
                          <div className='row mb-3'>
                            <div className='col'>
                              <Textarea
                                maxLength={2000}
                                readOnly={!data.flgPermiteAlterar}
                                label='Observação'
                                text={data.noObservacao}
                                onChangedValue={(noObservacao) => {
                                  setData({
                                    ...data,
                                    noObservacao,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className='row mb-3'>
                            <div
                              className='col'
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              <Button
                                theme={Theme.Primary}
                                template={Button.Templates.Button}
                                text='Gerar Contrato'
                                onClick={onGerarContrato}
                                className='width80'
                              />
                            </div>
                          </div>
                          <div className='row mb-3'>
                            <div className='col'>
                              <FileUpload
                                files={importedFiles}
                                onChange={(files) => {
                                  if (files.length > 0) {
                                    if (files[0].size <= 5000000) {
                                      setImportedFiles(files);
                                    } else {
                                      onSetMessage(
                                        false,
                                        'Arquivo não pode ser maior que 5MB'
                                      );
                                    }
                                  } else {
                                    setImportedFiles(files);
                                  }
                                }}
                                allowedMimeTypes={[
                                  MimeTypes.Types.PDF,
                                  MimeTypes.Types.Image,
                                  MimeTypes.Types.Word,
                                ]}
                              />
                            </div>
                            <div className='col d-flex justify-content-center'>
                              <Button
                                text='Adicionar Documento'
                                className='px-5'
                                theme={Theme.Success}
                                size={BootstrapSizes.Medium}
                                template={Button.Templates.Button}
                                onClick={() => onClickAddDocumento()}
                              />
                            </div>
                          </div>
                          <div className='row mb-3'>
                            <div className='col'>
                              <GridView
                                ref={gridView}
                                className='table-striped table-hover table-bordered table-sm'
                                dataColumns={columns}
                                showPagination={false}
                                showSelectSizes={false}
                              />
                            </div>
                          </div>

                          {agendarportabilidade && (
                            <div className='row mb-3'>
                              <div className='row mb-2'>
                                <Panel>
                                  <Panel.Header
                                    title='Agendar Portabilidade'
                                    theme={Theme.Primary}
                                    align={JustifyContent.Start}
                                  />
                                  <Panel.Body>
                                    <div className='accordion-body'>
                                      <div className='row mb-3'>
                                        <div className='card'>
                                          <div className='card-body'>
                                            <div className='row mb-4'>
                                              <div className='col-2'>
                                                <DatePicker
                                                  label='Data Portabilidade'
                                                  enabled={
                                                    data.flgPermiteAlterar
                                                  }
                                                  required
                                                  text={data?.dtPortabilidade}
                                                  maxLength={10}
                                                  mask={MaskTypes.Date}
                                                  onChange={(
                                                    dtPortabilidade
                                                  ) => {
                                                    if (
                                                      dtPortabilidade &&
                                                      dtPortabilidade !== ''
                                                    )
                                                      searchHorariosPortabilidade(
                                                        dtPortabilidade
                                                      );
                                                    else {
                                                      setDropDownHorariosSource(
                                                        []
                                                      );
                                                    }
                                                    setData({
                                                      ...data,
                                                      dtPortabilidade,
                                                    });
                                                  }}
                                                />
                                              </div>
                                              <div className='col-3'>
                                                <DropdownList
                                                  label='Horários disponíveis'
                                                  enabled={
                                                    data.flgPermiteAlterar
                                                  }
                                                  dataSource={
                                                    dropDownHorariosSource
                                                  }
                                                  dataSourcePropertyText='noHoraInicioFim'
                                                  dataSourcePropertyValue='nrSeqHoraInicioFim'
                                                  selectedItems={
                                                    data.horarios ?? []
                                                  }
                                                  onSelectItem={(item) => {
                                                    setData({
                                                      ...data,
                                                      horarios: item,
                                                      noHoraInicioFim:
                                                        item.noHoraInicioFim,
                                                      nrSeqHoraInicioFim:
                                                        item.nrSeqHoraInicioFim,
                                                    });
                                                  }}
                                                />
                                              </div>
                                              <div className='col mt-3'>
                                                <Button
                                                  theme={Theme.Primary}
                                                  template={
                                                    Button.Templates.Button
                                                  }
                                                  text='Solicitar'
                                                  onClick={() =>
                                                    onSolicitaPortabilidade()
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Panel.Body>
                                </Panel>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
        // Gera -> Portabilidade Massiva
        if (
          !data.tipoDemanda?.flgVersaoOld &&
          data.tipoDemanda?.cdTipoDemanda === 'Portabilidade Massivo'
        ) {
          return (
            <ManutencaoPortabilidadeMassivo
              data={data}
              setData={setData}
              onSetMessage={onSetMessage}
              setDataListDocRemove={setDataListDocRemove}
              documentos={documentos}
              setDocumentos={setDocumentos}
              onOpenTransaction={onOpenTransaction}
              findTransaction={findTransaction}
              setModalAlterarFormaPagamento={setModalAlterarFormaPagamento}
              setModalPortabilidadeShow={setModalPortabilidadeShow}
              dataMassivo={dataMassivo}
              setDataMassivo={setDataMassivo}
              setLoading={setLoading}
              onSelectCliente={onSelectCliente}
              onSelectFormaPagamento={onSelectFormaPagamento}
              clienteMessage={clienteMessage}
              onAutoCompleteFormaPagamento={onAutoCompleteFormaPagamento}
            />
          );
        }
        // Gera -> Troca de Numero
        if (
          !data.tipoDemanda?.flgVersaoOld &&
          data.tipoDemanda?.cdTipoDemanda === 'Troca de Numero'
        ) {
          return (
            <>
              {'cycle' in contratoMessage && dataChip.nrSeqChip !== undefined && (
                <div className='row mb-3'>
                  <div className='col'>
                    <div className={`card border-${contratoMessage.color}`}>
                      <div
                        className={`card-body text-${contratoMessage.color}`}
                      >
                        <p className='card-text mb-0'>
                          {contratoMessage.cycle}
                        </p>
                        {contratoMessage.message.length > 0 && (
                          <p className='card-text'>{contratoMessage.message}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className='row mb-3'>
                <div className='col-3'>
                  <DatePicker
                    label='Data Troca de Número'
                    required
                    readOnly={!data.flgPermiteAlterar}
                    maxLength={10}
                    text={data.dtSolicitacao}
                    mask={MaskTypes.Date}
                    onChange={(dtSolicitacao) => {
                      setData({
                        ...data,
                        dtSolicitacao,
                      });
                    }}
                  />
                </div>
                <div className='col-5'>
                  <Textbox label='Protocolo' readOnly text={data.noProtocolo} />
                </div>
              </div>
              {clienteMessage.showMessage && (
                <div className='row mb-2'>
                  <div className='col'>
                    <div className='card border-danger'>
                      <div className='card-body text-danger'>
                        <p className='card-text'>{clienteMessage.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className='row mb-3'>
                <div className='col-3'>
                  <Autocomplete
                    label='Nr Linha'
                    required
                    enabled={data.flgPermiteAlterar}
                    searchDataSource={onSearchChip}
                    selectedItem={dataChip.chip}
                    onSelectItem={async (chip) => {
                      await onSelectChip(chip, true);
                    }}
                    dataSourceTextProperty='linhaFormatada'
                  />
                </div>
                <div className='col'>
                  <Autocomplete
                    label='Cliente'
                    enabled={false}
                    readOnly
                    required
                    searchDataSource={onSearchCliente}
                    minLengthTextSearch={3}
                    placeholder='Digite ao menos 3 caracteres para buscar Cliente'
                    selectedItem={data.cliente}
                    onSelectItem={async (cliente) => {
                      data.nrSeqPessoaCli = cliente.nrSeqPessoaCli ?? undefined;
                      data.cliente = cliente;

                      onSelectCliente(cliente);

                      setEndereco(cliente.endereco ?? new Endereco());
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
              </div>
              <div className='row mb-3'>
                {data.cliente?.flgFisica && (
                  <div className='col-2'>
                    <Textbox
                      label='CPF'
                      text={data.cliente?.nrDocumento}
                      mask={MaskTypes.CPF}
                      readOnly
                    />
                  </div>
                )}

                {!data.cliente?.flgFisica && (
                  <div className='col-2'>
                    <Textbox
                      label='CNPJ'
                      text={data.cliente?.nrDocumento}
                      mask={MaskTypes.CNPJ}
                      readOnly
                    />
                  </div>
                )}

                <div className='col-2'>
                  <Textbox
                    label='RG'
                    text={data.cliente?.nrRG}
                    maxLength={15}
                    visible={data.cliente?.flgFisica}
                    readOnly
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Endereço'
                    text={endereco?.enderecoCompleto}
                    readOnly
                  />
                </div>
              </div>
              {data.nrSeqDemanda > 0 && (
                <div className='row mb-3'>
                  <div className='col-3'>
                    <Autocomplete
                      label='Nr Nova Linha'
                      enabled={data.flgPermiteAlterar}
                      searchDataSource={onSearchChip}
                      selectedItem={data.chipNovo}
                      onSelectItem={(chipNovo) => {
                        setData({
                          ...data,
                          chipNovo,
                          nrSeqChipNovo: chipNovo.nrSeqChipNovo,
                        });
                      }}
                      dataSourceTextProperty='linhaFormatada'
                    />
                  </div>
                </div>
              )}
              <div className='row mb-3'>
                <div className='col'>
                  <Textarea
                    label='Observação'
                    maxLength={2000}
                    readOnly={!data.flgPermiteAlterar}
                    rows={5}
                    text={data.noObservacao}
                    onChangedValue={(noObservacao) => {
                      setData({ ...data, noObservacao });
                    }}
                  />
                </div>
                <div className='col'>
                  <div className='row'>
                    <div className='col mt-4'>
                      <FileUpload
                        files={importedFiles}
                        onChange={(files) => {
                          if (files.length > 0) {
                            if (files[0].size <= 5000000) {
                              setImportedFiles(files);
                            } else {
                              onSetMessage(
                                false,
                                'Arquivo não pode ser maior que 5MB'
                              );
                            }
                          } else {
                            setImportedFiles(files);
                          }
                        }}
                        allowedMimeTypes={[
                          MimeTypes.Types.PDF,
                          MimeTypes.Types.Image,
                          MimeTypes.Types.Word,
                        ]}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col mt-3 d-flex justify-content-center'>
                      <Button
                        text='Adicionar Documento'
                        className='px-5'
                        theme={Theme.Success}
                        size={BootstrapSizes.Small}
                        template={Button.Templates.Button}
                        onClick={() => onClickAddDocumento()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <GridView
                    ref={gridView}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </>
          );
        }
        // Gera -> Migração PF para PJ Massiva
        if (
          !data.tipoDemanda?.flgVersaoOld &&
          data.tipoDemanda?.cdTipoDemanda === 'Migracao PF para PJ Massivo'
        ) {
          return (
            <ManutencaoTTMassivo
              data={data}
              setData={setData}
              onSetMessage={onSetMessage}
              setDataListDocRemove={setDataListDocRemove}
              documentos={documentos}
              setDocumentos={setDocumentos}
              onOpenTransaction={onOpenTransaction}
              findTransaction={findTransaction}
              setModalAlterarFormaPagamento={setModalAlterarFormaPagamento}
              setModalPortabilidadeShow={setModalPortabilidadeShow}
              dataMassivo={dataMassivo}
              setDataMassivo={setDataMassivo}
              setLoading={setLoading}
              onSelectCliente={onSelectCliente}
              onSelectFormaPagamento={onSelectFormaPagamento}
              clienteMessage={clienteMessage}
              onAutoCompleteFormaPagamento={onAutoCompleteFormaPagamento}
            />
          );
        }
        // Gera -> Migração Pf para Pj
        if (
          !data.tipoDemanda?.flgVersaoOld &&
          data.tipoDemanda?.cdTipoDemanda === 'Migracao de PF para PJ'
        ) {
          return (
            <ManutencaoTT
              data={data}
              setData={setData}
              onSetMessage={onSetMessage}
              setDataListDocRemove={setDataListDocRemove}
              documentos={documentos}
              setDocumentos={setDocumentos}
              onOpenTransaction={onOpenTransaction}
              findTransaction={findTransaction}
              setModalPortabilidadeShow={setModalPortabilidadeShow}
              setLoading={setLoading}
              setDataChip={setDataChip}
              dataChip={dataChip}
              onSelectCliente={onSelectCliente}
              onSelectFormaPagamento={onSelectFormaPagamento}
              clienteMessage={clienteMessage}
              onAutoCompleteFormaPagamento={onAutoCompleteFormaPagamento}
            />
          );
        }
        // Gera -> Portabilidade para Operadora Origem
        if (
          !data.tipoDemanda?.flgVersaoOld &&
          data.tipoDemanda?.cdTipoDemanda ===
            'Portabilidade para Operadora Origem'
        ) {
          return (
            <ManutencaoRevertePortabilidade
              data={data}
              setData={setData}
              onSetMessage={onSetMessage}
              setDataListDocRemove={setDataListDocRemove}
              documentos={documentos}
              setDocumentos={setDocumentos}
              onOpenTransaction={onOpenTransaction}
              findTransaction={findTransaction}
              setLoading={setLoading}
              setModalAlterarFormaPagamento={setModalAlterarFormaPagamento}
              onGerarContrato={onGerarContrato}
              onSolicitaPortabilidade={onSolicitaPortabilidade}
              onSelectCliente={onSelectCliente}
              onSelectFormaPagamento={onSelectFormaPagamento}
              onAutoCompleteFormaPagamento={onAutoCompleteFormaPagamento}
            />
          );
        }
        // Gera -> Alteração Contrato
        if (
          !data.tipoDemanda?.flgVersaoOld &&
          data.tipoDemanda?.cdTipoDemanda === 'Alteracao de Contrato'
        ) {
          return (
            <ManutencaoAlteracaoContrato
              data={data}
              setData={setData}
              onSetMessage={onSetMessage}
              setDataListDocRemove={setDataListDocRemove}
              documentos={documentos}
              setDocumentos={setDocumentos}
              onOpenTransaction={onOpenTransaction}
              findTransaction={findTransaction}
              onSelectCliente={onSelectCliente}
              onSelectFormaPagamento={onSelectFormaPagamento}
              setLoading={setLoading}
              onAutoCompleteFormaPagamento={onAutoCompleteFormaPagamento}
            />
          );
        }
        // Gera -> Alteração Contrato Massivo
        if (
          !data.tipoDemanda?.flgVersaoOld &&
          data.tipoDemanda?.cdTipoDemanda === 'Alteracao de Contrato Massivo'
        ) {
          return (
            <ManutencaoAlteracaoContratoMassivo
              data={data}
              setData={setData}
              onSetMessage={onSetMessage}
              setDataListDocRemove={setDataListDocRemove}
              documentos={documentos}
              setDocumentos={setDocumentos}
              onOpenTransaction={onOpenTransaction}
              findTransaction={findTransaction}
              setModalAlterarFormaPagamento={setModalAlterarFormaPagamento}
              dataMassivo={dataMassivo}
              setDataMassivo={setDataMassivo}
              setLoading={setLoading}
              onSelectCliente={onSelectCliente}
              onSelectFormaPagamento={onSelectFormaPagamento}
              clienteMessage={clienteMessage}
              onAutoCompleteFormaPagamento={onAutoCompleteFormaPagamento}
            />
          );
        }
        // Gera -> Solicitação de Chips Virgens
        if (
          !data.tipoDemanda?.flgVersaoOld &&
          data.tipoDemanda?.cdTipoDemanda === 'Solicitacao Chips Virgem'
        ) {
          return (
            <ManutencaoSolicitacaoChip
              data={data}
              setData={setData}
              onSetMessage={onSetMessage}
              addFiles
              setDataListDocRemove={setDataListDocRemove}
              documentos={documentos}
              dataMassivo={dataMassivo}
              setDataMassivo={setDataMassivo}
              setDocumentos={setDocumentos}
              setLoading={setLoading}
              onOpenReport={onOpenReport}
              transaction={transaction}
              findTransaction={findTransaction}
              onOpenTransaction={onOpenTransaction}
              // onSelectCliente={onSelectCliente}
            />
          );
        }
        // Gera => Troca de Titularidade
        if (
          !data.tipoDemanda?.flgVersaoOld &&
          data.tipoDemanda?.cdTipoDemanda === 'Troca de Titularidade'
        ) {
          return (
            <ManutencaoTrocaTitularidade
              addFiles={data.tipoDemanda?.flgUploadArquivo}
              data={data}
              setData={setData}
              onSetMessage={onSetMessage}
              setDataListDocRemove={setDataListDocRemove}
              documentos={documentos}
              setDocumentos={setDocumentos}
              setLoading={setLoading}
              clienteMessage={clienteMessage}
              onSelectCliente={onSelectCliente}
              onSelectFormaPagamento={onSelectFormaPagamento}
              onAutoCompleteFormaPagamento={onAutoCompleteFormaPagamento}
            />
          );
        }
        // Gera -> Retenção de Linhas
        if (
          !data.tipoDemanda?.flgVersaoOld &&
          data.tipoDemanda?.cdTipoDemanda === 'Retencao de Linhas'
        ) {
          return (
            <ManutencaoRetencao
              addFiles={data.tipoDemanda?.flgUploadArquivo}
              data={data}
              setData={setData}
              onSetMessage={onSetMessage}
              setDataListDocRemove={setDataListDocRemove}
              documentos={documentos}
              setDocumentos={setDocumentos}
              setLoading={setLoading}
              dataMassivo={dataMassivo}
              setDataMassivo={setDataMassivo}
              clienteMessage={clienteMessage}
              findTransaction={findTransaction}
              onOpenTransaction={onOpenTransaction}
              onSelectCliente={onSelectCliente}
              onSelectChip={onSelectChip}
            />
          );
        }
        // Gera -> Solicitação de Linha
        if (
          !data.tipoDemanda?.flgVersaoOld &&
          data.tipoDemanda?.cdTipoDemanda === 'Solicitacao Linha'
        ) {
          return (
            <ManutencaoSolicitacaoLinha
              data={data}
              setData={setData}
              onSetMessage={onSetMessage}
            />
          );
        }
        // Ger -> Reciclar Chip
        if (
          !data.tipoDemanda?.flgVersaoOld &&
          data.tipoDemanda?.cdTipoDemanda === 'Reciclar Chip'
        ) {
          return (
            <ManutencaoReciclarChip
              data={data}
              setData={setData}
              onSetMessage={onSetMessage}
              setLoading={setLoading}
            />
          );
        }
        // Gera -> Adicionar Pacote de Dados Adicional
        if (
          !data.tipoDemanda?.flgVersaoOld &&
          data.tipoDemanda?.cdTipoDemanda ===
            'Adicionar Pacote de Dados Adicional'
        ) {
          return (
            <>
              <ManutencaoAdicionarPacoteDeDadosAdicional
                data={data}
                setData={setData}
                onSetMessage={onSetMessage}
              />
              {(data.tipoDemanda?.flgUploadArquivo ||
                data.tipoDemanda?.flgObrigaUpload) && (
                <ImportadorDocumentosDemanda
                  documentos={documentos}
                  setDocumentos={setDocumentos}
                  setDataListDocRemove={setDataListDocRemove}
                  onSetMessage={onSetMessage}
                />
              )}
            </>
          );
        }
        // Gera -> Verificação Consumo MVNO
        if (
          !data.tipoDemanda?.flgVersaoOld &&
          data.tipoDemanda?.cdTipoDemanda === 'Verificar Consumo MVNO'
        ) {
          return (
            <ManutencaoVerificarConsumoMVNO
              data={data}
              setData={setData}
              onSetMessage={onSetMessage}
            />
          );
        }
        if (data.tipoDemanda && !data.tipoDemanda?.flgVersaoOld) {
          // Telas Genericas
          return (
            <ManutencaoDefault
              addFiles={data.tipoDemanda?.flgUploadArquivo}
              data={data}
              setData={setData}
              onSetMessage={onSetMessage}
              setDataListDocRemove={setDataListDocRemove}
              documentos={documentos}
              setDocumentos={setDocumentos}
              clienteMessage={clienteMessage}
              contratoMessage={contratoMessage}
              onSelectChip={onSelectChip}
              onSelectCliente={onSelectCliente}
              // setLoading={setLoading}
            />
          );
        }
        // Gera telas não migradas
        if (data.tipoDemanda?.flgVersaoOld) {
          return (
            <OldPage
              sourcePath='HELP'
              path='Demanda_Item.aspx'
              registryKey={data.nrSeqDemanda ? 'nrSeqDemanda' : ''}
              register={data}
              parameters={[
                {
                  name: 'nrSeqTipoDemanda',
                  value: data.tipoDemanda.nrSeqTipoDemanda,
                },
              ]}
            />
          );
        }
        return <div />;
      })()}

      <ModalAgendaPortabilidade
        show={modalPortabilidadeShow}
        demanda={data}
        novocontrato={data?.tipoDemanda?.flgNovoContrato}
        nrseq={data.nrSeqDemanda}
        onClose={() => {
          setModalPortabilidadeShow(false);
        }}
        onAgendaPortabilidade={(param) => {
          setModalPortabilidadeShow(false);
          onSolicitaPortabilidade(param);
        }}
        dataPortab={data?.dtPrevistaPortabilidade}
      />
      <ModalMsgEnvioOperadora
        show={modalMsgEnvioOperadoraShow}
        onClose={async () => {
          setModalMsgEnvioOperadoraShow(false);
          setData(await getDemanda(data.nrSeqDemanda));
        }}
        onEnvioOperadora={() => {
          setModalMsgEnvioOperadoraShow(false);
          onSolicitaMVNO();
        }}
      />
      <ModalAlterarFormaPagamento
        show={modalAlterarFormaPagamento.show}
        onClose={() =>
          setModalAlterarFormaPagamento({
            show: false,
          })
        }
        formaPagamento={modalAlterarFormaPagamento.formaPagamento}
        formaPagamentoDefault={infoPagamentoDefault.formaPagamento}
        data={data}
        setData={setData}
      />
      <ModalDevolverDemanda
        show={showModalDevolverDemanda}
        onClose={() => setShowModalDevolverDemanda(false)}
        nrSeqDemanda={data.nrSeqDemanda}
        onSetMessage={onSetMessage}
        data={data}
        setData={setData}
      />
      <ModalContratoClickSign
        show={showModalGeraContratoClickSign.show}
        onClose={() => setShowModalGeraContratoClickSign({ show: false })}
        docType={showModalGeraContratoClickSign.docType}
        demanda={data}
        onSetMessage={onSetMessage}
      />
      <ModalConfirmarCancelamento
        show={showModalConfirmarCancelamento}
        onClose={() => setShowModalConfirmarCancelamento(false)}
        data={data}
        setData={setData}
        onSetMessage={onSetMessage}
      />
    </CSDManutPage>
  );
}
