import { ContaCabecalho } from 'core/models/TEL';

export default class ContaRecurso {
  constructor(jsonObject = {}) {
    this.nrSeqContaRecurso = jsonObject.nrSeqContaRecurso;
    this.noPessoa = jsonObject.noPessoa;
    this.numeroCompleto = jsonObject.numeroCompleto;
    this.noPlano = jsonObject.noPlano;
    this.email = jsonObject.email;
    this.vlrTotalRecalculo = jsonObject.vlrTotalRecalculo;
    this.nrPrefixo = jsonObject.nrPrefixo;
    this.nrLinha = jsonObject.nrLinha;
    this.noEnvioPadrao = jsonObject.noEnvioPadrao;
    this.noFormaPagamento = jsonObject.noFormaPagamento;
    this.nrSeqClienteLinha = jsonObject.nrSeqClienteLinha;
    this.nrSeqContaCabecalho = jsonObject.nrSeqContaCabecalho;
    this.flgNaoFaturado = jsonObject.flgNaoFaturado;
    this.nrSeqTituloReceberFatura = jsonObject.nrSeqTituloReceberFatura;
    this.noCentroDeCusto = jsonObject.noCentroDeCusto;

    this.dtFatura = jsonObject.dtFatura;
    this.dtVencimento = jsonObject.dtVencimento;

    this.contaCabecalho = new ContaCabecalho(jsonObject.contaCabecalho ?? {});
  }

  get linhaFormatada() {
    if (this.nrPrefixo && this.nrLinha)
      return `(${this.nrPrefixo}) ${this.nrLinha}`;
    return '';
  }

  toJson = () => JSON.stringify(this);
}
