import React, { useState } from 'react';
import { getInformacaoBancariaAutoCompleteFiltraFormaPagamento } from 'core/services/FIN/informacaoBancaria';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { Button, Loader, Modal } from 'ui/components';

export default function ModalAlterarFormaPagamento({
  show,
  onClose,
  formaPagamento,
  formaPagamentoDefault,
  data,
  setData,
}) {
  const [loading, setLoading] = useState(false);

  const onYes = async () => {
    setLoading(true);

    if (formaPagamento.flgGrupo === 'D' && data.nrSeqPessoaCli) {
      const {
        informacoesBancarias,
      } = await getInformacaoBancariaAutoCompleteFiltraFormaPagamento({
        nrSeqPessoa: data.cliente?.nrSeqPessoaCli,
        nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
      });

      if (informacoesBancarias.length === 1) {
        setData({
          ...data,
          formaPagamento,
          nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
          informacaoBancaria: informacoesBancarias[0],
          nrSeqPessoaInformacaoBancaria:
            informacoesBancarias[0].nrSeqPessoaInformacaoBancaria,
        });
      } else {
        setData({
          ...data,
          formaPagamento,
          nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
          informacaoBancaria: undefined,
          nrSeqPessoaInformacaoBancaria: undefined,
        });
      }
    } else {
      setData({
        ...data,
        formaPagamento,
        nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
        informacaoBancaria: undefined,
        nrSeqPessoaInformacaoBancaria: undefined,
      });
    }

    setLoading(false);

    onClose();
  };

  const closeModal = async () => {
    setLoading(true);

    if (formaPagamentoDefault.flgGrupo === 'D' && data.nrSeqPessoaCli) {
      const {
        informacoesBancarias,
      } = await getInformacaoBancariaAutoCompleteFiltraFormaPagamento({
        nrSeqPessoa: data.cliente?.nrSeqPessoaCli,
        nrSeqFormaPagamento: formaPagamentoDefault.nrSeqFormaPagamento,
      });

      if (informacoesBancarias.length === 1) {
        setData({
          ...data,
          formaPagamento: formaPagamentoDefault,
          nrSeqFormaPagamento: formaPagamentoDefault.nrSeqFormaPagamento,
          informacaoBancaria: informacoesBancarias[0],
          nrSeqPessoaInformacaoBancaria:
            informacoesBancarias[0].nrSeqPessoaInformacaoBancaria,
        });
      } else {
        setData({
          ...data,
          formaPagamento: formaPagamentoDefault,
          nrSeqFormaPagamento: formaPagamentoDefault.nrSeqFormaPagamento,
          informacaoBancaria: undefined,
          nrSeqPessoaInformacaoBancaria: undefined,
        });
      }
    } else {
      setData({
        ...data,
        formaPagamento: formaPagamentoDefault,
        nrSeqFormaPagamento: formaPagamentoDefault.nrSeqFormaPagamento,
        informacaoBancaria: undefined,
        nrSeqPessoaInformacaoBancaria: undefined,
      });
    }

    setLoading(false);

    onClose();
  };

  return (
    <Modal
      show={show}
      title='Alterar Forma de Pagamento?'
      onClose={closeModal}
      size={BootstrapSizes.Large}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        <h4>Cliente já possui uma forma de pagamento cadastrada.</h4>
        <h4>
          {' '}
          Deseja cadastrar uma forma de pagamento diferente para esse contrato?
        </h4>
      </Modal.Body>
      <Modal.Footer>
        <Button text='Sim' theme={Theme.Success} onClick={() => onYes()} />
        <Button text='Não' theme={Theme.Danger} onClick={() => closeModal()} />
      </Modal.Footer>
    </Modal>
  );
}
