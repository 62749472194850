import qs from 'qs';
import Pagination from 'core/models/pagination';
import LogEnvioSms from 'core/models/SEG/logEnvioSms';
import api from '../api';

const baseURL = `/SEG/LogEnvioSms`;

export const getLogEnvioSmsList = async (filters) => {
  const response = await api.get(`/$LogEnvioSms/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new LogEnvioSms(json)) ?? [],
  };
};

export const getLogEnvioSmsPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new LogEnvioSms(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getLogEnvioSms = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new LogEnvioSms(value) : {};
};

export const saveLogEnvioSms = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteLogEnvioSms = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printLogEnvioSms = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelLogEnvioSms = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllLogEnvioSms = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
