import PneuVeiculo from 'core/models/FRO/pneuVeiculo';

export default class Eixo {
  constructor(jsonObject = {}) {
    this.nrSeqEixo = jsonObject.nrSeqEixo;
    this.cdEixo = jsonObject.cdEixo;
    this.noEixo = jsonObject.noEixo;
    this.flgVertical = jsonObject.flgVertical;
    this.qtdePneuEsquerda = jsonObject.qtdePneuEsquerda;
    this.flgRoda = jsonObject.flgRoda;
    this.qtdePneuDireita = jsonObject.qtdePneuDireita;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgTracao = jsonObject.flgTracao;

    // propriedades auxiliares
    this.pneus = jsonObject.pneus?.map((item) => new PneuVeiculo(item)) ?? [];
  }

  toJson = () => JSON.stringify(this);
}
