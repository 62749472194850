import { getFormaPagamentoAutoComplete } from 'core/services/FIN';
import { SaveWithFinancial } from 'core/services/FIN/acertoMotoristaTitulo';
import { getTipoTituloExtratoList } from 'core/services/FIN/tipoTituloExtrato';
import React, { useEffect, useState } from 'react';

import {
  Autocomplete,
  Button,
  DatePicker,
  Modal,
  Notification,
  Textbox,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';

export default function ModalExtrato(props) {
  const [messageState, setMessage] = useState(null);
  const [dataExtratoFinanceiro, setDataExtratoFinanceiro] = useState({});

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const { show, dataExtrato } = props;

  useEffect(() => {
    setDataExtratoFinanceiro({});
  }, [show]);

  const cancelar = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
    });
  };

  const onSend = async () => {
    const obj = {
      nrSeqAcertoMotorista: dataExtrato.nrSeqAcertoMotorista,
      vlrPago: dataExtratoFinanceiro.vlrPago,
      dtCheque: dataExtratoFinanceiro.dtCheque,
      nrCheque: dataExtratoFinanceiro.nrCheque,
      nrSeqFormaPagamento: dataExtratoFinanceiro.nrSeqFormaPagamento,
      noObservacoesTitulo: dataExtratoFinanceiro.noObservacoesTitulo,
      nrSeqPessoaMot: dataExtrato.nrSeqPessoaMot,
      nrSeqTipoTituloExtrato: dataExtratoFinanceiro.nrSeqTipoTituloExtrato,
      dtVencimento: dataExtratoFinanceiro.dtVencimento,
    };

    const { status, message: msg } = await SaveWithFinancial(obj);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    if (status === ResponseStatus.Success) {
      props.onClose({
        message: '',
        theme: ResponseStatus.Success,
      });
    }
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const onSearchTipoTituloExtrato = async (e) => {
    const { status, message: msg, data } = await getTipoTituloExtratoList({
      noTituloExtrato: e,
    });
    if (msg) onSetMessage(status, msg);
    return data;
  };

  return (
    <Modal show={show} title='Extrato Financeiro' size={BootstrapSizes.Large}>
      <Modal.Body className='p-4 pt-3'>
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col-6 mb-3'>
            <Autocomplete
              label='Tipo Titulo Extrato'
              searchDataSource={onSearchTipoTituloExtrato}
              selectedItem={dataExtratoFinanceiro?.tipoTituloExtrato}
              onSelectItem={(tipoTituloExtrato) => {
                setDataExtratoFinanceiro({
                  ...dataExtratoFinanceiro,
                  tipoTituloExtrato,
                  nrSeqTipoTituloExtrato:
                    tipoTituloExtrato.nrSeqTipoTituloExtrato,
                });
              }}
              dataSourceTextProperty='noTituloExtrato'
            />
          </div>
          <div className='col-6 mb-3'>
            <Autocomplete
              label='Forma de Pagamento'
              required
              searchDataSource={onSearchFormaPagamento}
              selectedItem={dataExtratoFinanceiro?.formaPagamento}
              onSelectItem={(formaPagamento) => {
                setDataExtratoFinanceiro({
                  ...dataExtratoFinanceiro,
                  formaPagamento,
                  nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                });
              }}
              dataSourceTextProperty='noFormaPagamento'
            />
          </div>
        </div>
        <div className='row'>
          {dataExtratoFinanceiro.formaPagamento?.flgGrupo === 'C' && (
            <div className='col-6 mb-3'>
              <DatePicker
                label='Data Cheque'
                required
                text={dataExtratoFinanceiro.dtCheque}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtCheque) =>
                  setDataExtratoFinanceiro({
                    ...dataExtratoFinanceiro,
                    dtCheque,
                  })
                }
              />
            </div>
          )}
          {dataExtratoFinanceiro.formaPagamento?.flgGrupo === 'C' &&
            !dataExtratoFinanceiro.formaPagamento?.flgControlaCheque && (
              <div className='col-6 mb-3'>
                <Textbox
                  label='Nr. Cheque'
                  text={dataExtratoFinanceiro.nrCheque}
                  onChangedValue={(nrCheque) =>
                    setDataExtratoFinanceiro({
                      ...dataExtratoFinanceiro,
                      nrCheque,
                    })
                  }
                />
              </div>
            )}
        </div>
        <div className='row mb-3'>
          <div className='col-6 mb-3'>
            <DatePicker
              label='Vencimento'
              text={dataExtratoFinanceiro?.dtVencimento}
              required
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtVencimento) =>
                setDataExtratoFinanceiro({
                  ...dataExtratoFinanceiro,
                  dtVencimento,
                })
              }
            />
          </div>
          <div className='col-6 mb-3'>
            <Textbox
              label='Valor'
              required
              textRight
              text={dataExtratoFinanceiro?.vlrPago}
              mask={MaskTypes.DecimalCustom}
              onChangedValue={(vlrPago) => {
                setDataExtratoFinanceiro({
                  ...dataExtratoFinanceiro,
                  vlrPago,
                });
              }}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-12 mb-3'>
            <Textbox
              label='Historico'
              required
              text={dataExtratoFinanceiro?.noObservacoesTitulo}
              onChangedValue={(noObservacoesTitulo) => {
                setDataExtratoFinanceiro({
                  ...dataExtratoFinanceiro,
                  noObservacoesTitulo,
                });
              }}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-3 mt-3'>
            <Button
              size={BootstrapSizes.Medium}
              theme={Theme.Primary}
              template={Button.Templates.Default}
              onClick={onSend}
              text='Salvar'
              icon='save'
            />
          </div>
          <div className='col-3 mt-3'>
            <Button
              theme={Theme.Danger}
              icon={['fas', 'times']}
              template={Button.Templates.Button}
              text='Sair'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
