class PacoteSms {
  constructor(jsonObject = {}) {
    this.nrSeqPacoteSms = jsonObject.nrSeqPacoteSms;
    this.noPacoteSms = jsonObject.noPacoteSms;
    this.nrSms = jsonObject.nrSms;
    this.noDescricaoConta = jsonObject.noDescricaoConta;
    this.noDescricaoConta2 = jsonObject.noDescricaoConta2;
    this.vlrPacote = jsonObject.vlrPacote;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqTipoChamada = jsonObject.nrSeqTipoChamada;
    this.flgPacoteCompartilhado = jsonObject.flgPacoteCompartilhado;
  }

  toJson = () => JSON.stringify(this);
}
export default PacoteSms;
