import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  Textbox,
  Autocomplete,
  Notification,
  Panel,
  Button,
  GridView,
  DatePicker,
  Switch,
  FileUpload,
  Loader,
} from 'ui/components';
import {
  BootstrapSizes,
  ResponseStatus,
  Theme,
  JustifyContent,
  MimeTypes,
  toBase64,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';
import { getTipoDespesaAutoComplete } from 'core/services/FIN/tipoDespesa';
import { getProdutoAutoComplete } from 'core/services/EST/produto';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getCompradorAutoComplete } from 'core/services/COP/comprador';
import { savePedidoCompra } from 'core/services/VEN/pedidoCompra';
import { PedidoCompraItem } from 'core/models/VEN';
import { generateParcels } from 'core/services/VEN/pedido';
import { getCondicaoPagamentoAutoComplete } from 'core/services/FIN/condicaoPagamento';
import { saveOrdemCompra } from 'core/services/COP/ordemCompra';
import PedidoCompraDocumento from 'core/models/VEN/pedidoCompraDocumento';

export default function ModalPedidoCompra(props) {
  const { show, data } = props;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [dataPedidoCompra, setDataPedidoCompra] = useState({
    flgGerarFinanceiro: false,
  });
  const [dataProduto, setDataProduto] = useState({});
  const [pedidoCompraDoc, setPedidoCompraDoc] = useState({});
  const [importedFilePedido, setImportedFilePedido] = useState([]);
  const [dadosOrdemCompra, setDadosOrdemCompra] = useState(null);
  const [dataSourceProdutos, setDataSourceProdutos] = useState([]);
  const gridViewProdutos = useRef();
  const gridViewFaturamento = useRef();
  const gridViewAnexos = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = () => {
    const dadosPedido = data.informacoesPedido;
    setDataPedidoCompra({ ...dataPedidoCompra, ...dadosPedido });
    setDadosOrdemCompra(data.ordensSelecionadas);
    setDataProduto({});

    setMessage(null);

    if (dadosPedido?.pedidoCompraItens) {
      if (gridViewProdutos && gridViewProdutos.current)
        gridViewProdutos.current.setDataSource(dadosPedido.pedidoCompraItens);
    }

    if (dadosPedido?.documentos) {
      if (gridViewAnexos && gridViewAnexos.current)
        gridViewAnexos.current.setDataSource(dadosPedido.documentos);
    }
  };

  const recalculaValoresPedido = () => {
    const lista = gridViewProdutos?.current?.getDataSource() ?? [];
    const totalItens = lista
      .filter((value) => value.vlrUnitario > 0)
      .map((produtoitem) => new PedidoCompraItem(produtoitem).vlrTotalItem);

    const somaDesconto = lista.map(
      (produtoitem) => new PedidoCompraItem(produtoitem).vlrDesconto
    );

    const vlrDescontoPedido = somaDesconto.reduce((a, b) => a + b, 0);
    const vlrPedido =
      totalItens.reduce((a, b) => a + b, 0) +
      (dataPedidoCompra.vlrFretePedido || 0) -
      (vlrDescontoPedido || 0);

    return [vlrDescontoPedido, vlrPedido];
  };

  useEffect(() => {
    load();
  }, [show]);

  useEffect(() => {
    const [vlrDescontoPedido, vlrPedido] = recalculaValoresPedido();
    setDataPedidoCompra({ ...dataPedidoCompra, vlrDescontoPedido, vlrPedido });
  }, [dataSourceProdutos]);

  const onSend = () => {
    setDataPedidoCompra({});
    setDataProduto({});
    setDataSourceProdutos([]);
    setDadosOrdemCompra(null);

    props.onClose();
  };

  const atualizarOrdensSelecionadas = async (resultadoPedido) => {
    const ordensSelecionadas = dadosOrdemCompra;

    resultadoPedido.pedidoCompraItens.forEach((item) => {
      ordensSelecionadas.forEach((ordem) => {
        const indexItem = ordem.ordensCompra.findIndex(
          (p) =>
            p.nrSeqProduto === item.nrSeqProduto &&
            p.nrSeqVeiculo === item.nrSeqVeiculo
        );

        if (indexItem > -1) {
          ordem.ordensCompra[indexItem].nrSeqPedidoCompra =
            resultadoPedido.nrSeqPedidoCompra;
          ordem.ordensCompra[indexItem].vlrUnitario = item.vlrUnitario;
          ordem.ordensCompra[indexItem].vlrTotal =
            item.vlrUnitario * ordem.ordensCompra[indexItem].qtdProduto;
          ordem.ordensCompra[indexItem].vlrFrete = item.vlrFrete;
          ordem.ordensCompra[indexItem].status = GridView.EnumStatus.Alterar;
        }
      });
    });

    let statusFinal = ResponseStatus.Success;

    ordensSelecionadas.forEach(async (ordem) => {
      const { status } = await saveOrdemCompra(ordem);
      if (status !== ResponseStatus.Success) statusFinal = status;
    });

    if (statusFinal === ResponseStatus.Success) {
      setMessage('Pedido de compra e solicitações salvos com sucesso.');
      setDadosOrdemCompra(null);
    } else {
      onSetMessage(
        statusFinal,
        'Erro ao salvar pedido de compra e solicitações.'
      );
    }
    return statusFinal;
  };

  const save = async () => {
    const produtos = gridViewProdutos.current
      .getDataSource()
      .map((produto) => new PedidoCompraItem(produto));

    if (produtos.length === 0) {
      onSetMessage(
        Theme.Danger,
        'Informe produtos para gerar o pedido de compra.'
      );
      return;
    }

    if (
      dataProduto &&
      dataProduto.nrSeqProduto &&
      dataProduto.status === GridView.EnumStatus.Alterar
    ) {
      onSetMessage(
        Theme.Danger,
        'Adicione o item em edição à lista para poder salvar o pedido de compra.'
      );
      return;
    }

    setLoading(true);
    const faturas = gridViewFaturamento.current.getDataSource();

    const anexos = gridViewAnexos.current
      .getDataSource()
      .map((anexo) => new PedidoCompraDocumento(anexo));

    const obj = {
      ...dataPedidoCompra,
      pedidoCompraItens: produtos,
      pedidoCompraFaturas: faturas,
      documentos: anexos,
    };

    const vlrFretePedido = dataPedidoCompra.vlrFretePedido || 0;

    if (vlrFretePedido > 0) {
      const qtdItens = produtos.length;
      const vlrFreteUni = Math.round((vlrFretePedido / qtdItens) * 100) / 100;

      obj.pedidoCompraItens.forEach((item) => {
        item.vlrFrete = parseFloat(vlrFreteUni.toFixed(2));
      });

      if (vlrFretePedido % qtdItens !== 0) {
        const diff = (vlrFretePedido - vlrFreteUni * qtdItens).toFixed(2);
        obj.pedidoCompraItens[qtdItens - 1].vlrFrete = parseFloat(
          (vlrFreteUni + parseFloat(diff)).toFixed(2)
        );
      }
    }

    const { status, message: msg, data: value } = await savePedidoCompra(obj);

    if (status === ResponseStatus.Success) {
      const statusPostOrdem = await atualizarOrdensSelecionadas(value);
      if (statusPostOrdem === ResponseStatus.Success) {
        onSend();
        props.onSave(statusPostOrdem, message);
      } else {
        onSetMessage(statusPostOrdem, message);
      }
    } else {
      onSetMessage(status, msg);
    }

    setLoading(false);
  };

  const onSearchProduto = async (e) => {
    const {
      status,
      message: msg,
      data: produtos,
    } = await getProdutoAutoComplete({
      noSearchField: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const onSearchPessoaFor = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaFors,
    } = await getFornecedorAutoComplete({
      noPessoa: e,
      flgNotEmptyNoPessoa: true,
      flgFisica: false,
    });
    if (msg) onSetMessage(status, msg);
    return pessoaFors;
  };

  const onAutoCompleteTipoDespesa = async (e) => {
    const {
      status,
      message: msg,
      tipoDespesas,
    } = await getTipoDespesaAutoComplete({
      noTipoDespesa: e,
    });

    if (msg) onSetMessage(status, msg);

    return tipoDespesas;
  };

  const onSearchComprador = async (e) => {
    const {
      status,
      message: msg,
      data: comprador,
    } = await getCompradorAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return comprador;
  };

  const onAutoCompleteVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onSearchCondicaoPagamento = async (e) => {
    const {
      status,
      message: msg,
      condicoesPagamento,
    } = await getCondicaoPagamentoAutoComplete({
      noCondicaoPagamento: e,
      flgTitPag: true,
    });
    if (msg) onSetMessage(status, msg);
    return condicoesPagamento;
  };

  const calculateFreteValorProdutoTotal = (vlrFretePedido) => {
    let valuesProds = 0;
    const dataSource = gridViewProdutos.current.getDataSource();
    dataSource.forEach((item) => {
      valuesProds += item.vlrTotalItem;
    });
    return valuesProds + vlrFretePedido;
  };

  const calculateTotalItem = ({
    quantidade,
    vlrUnitario,
    vlrDesconto,
    vlrIPI,
    vlrIPIPorcentagem,
  }) => {
    const total1 = (quantidade || 0) * (vlrUnitario || 0);
    const total2 = total1 + total1 * ((vlrIPIPorcentagem || 0) / 100);
    const total3 = total2 - (vlrDesconto || 0) + (vlrIPI || 0);

    return total3;
  };

  const calculateIPI = ({ quantidade, vlrUnitario, vlrIPIPorcentagem }) =>
    (vlrIPIPorcentagem / 100) * vlrUnitario * quantidade;

  const calculateIPIPercentage = ({ quantidade, vlrUnitario, vlrIPI }) => {
    const aux1 = parseFloat(vlrIPI);
    const aux2 = parseFloat(vlrUnitario) * parseFloat(quantidade);
    const total = (aux1 / aux2) * 100.0;
    return total;
  };

  const onAdicionarProduto = () => {
    if (!dataProduto.nrSeqProduto) {
      onSetMessage(1, 'Necessário selecionar o produto');
      return;
    }

    if (dataProduto.quantidade === 0 || !dataProduto.quantidade) {
      onSetMessage(1, 'Necessário informar a quantidade do produto');
      return;
    }

    if (dataProduto.vlrUnitario === 0 || !dataProduto.vlrUnitario) {
      onSetMessage(1, 'Necessário informar o valor unitário do produto');
      return;
    }

    const lista = gridViewProdutos.current.getDataSource() ?? [];

    //  validação se produto já existe no pedido
    const produtosRepetidos = lista.filter(
      (x) => x.nrSeqProduto === dataProduto.nrSeqProduto
    );
    if (produtosRepetidos.length > 0) {
      if (
        produtosRepetidos.find((x) => !x.nrSeqVeiculo) &&
        !dataProduto.nrSeqVeiculo
      ) {
        onSetMessage(
          ResponseStatus.Error,
          `Produto já adicionado ao pedido de compra para estoque.
           Associe o produto a um veículo para poder adicioná-lo.`
        );
        return;
      }

      if (
        produtosRepetidos.find(
          (x) => x.nrSeqVeiculo === dataProduto.nrSeqVeiculo
        )
      ) {
        onSetMessage(
          ResponseStatus.Error,
          'Produto já adicionado ao pedido de compra com vínculo ao mesmo veículo.'
        );
        return;
      }
    }
    setLoading(true);

    const totalItens = lista
      .filter((value) => value.vlrUnitario > 0)
      .map((produtoitem) => new PedidoCompraItem(produtoitem).vlrTotalItem);

    const somaDesconto = lista.map(
      (produtoitem) => new PedidoCompraItem(produtoitem).vlrDesconto
    );
    if (!dataProduto.veiculo) {
      dataProduto.veiculo = {};
    }
    if (!dataProduto.produtoMarca) {
      dataProduto.produtoMarca = {};
    }

    dataProduto.vlrTotalItem = parseFloat(dataProduto.vlrTotalItem);

    const vlrDescontoPedido =
      dataProduto.vlrDesconto + somaDesconto.reduce((a, b) => a + b, 0);

    const vlrPedido =
      dataProduto.vlrTotalItem +
      totalItens.reduce((a, b) => a + b, 0) +
      (dataPedidoCompra.vlrFretePedido || 0) -
      (vlrDescontoPedido || 0);

    if (dataProduto.status !== GridView.EnumStatus.Alterar) {
      dataProduto.status = GridView.EnumStatus.Inserir;
      dataProduto.nrOrdem = lista.length + 1;
      lista.push(dataProduto);
    } else if (
      dataProduto.nrOrdem &&
      dataProduto.status === GridView.EnumStatus.Alterar
    ) {
      const indexItem = dataProduto.nrOrdem - 1;

      lista.splice(indexItem, 0, dataProduto);
    }

    if (gridViewProdutos && gridViewProdutos.current)
      gridViewProdutos.current.setDataSource(lista);

    setDataPedidoCompra({
      ...dataPedidoCompra,
      vlrPedido,
      vlrDescontoPedido,
    });

    setDataProduto({
      produto: {},
      quantidade: 0,
      vlrIPIPorcentagem: 0,
      vlrTotalItem: 0,
      vlrFrete: 0,
      vlrIPI: 0,
      vlrDesconto: 0,
      vlrUnitario: 0,
    });

    setLoading(false);
  };

  const onClickRemoveProduto = async (selectedValue, datasource) => {
    setLoading(true);
    const dataProdutos = datasource.filter(
      (produto) => produto !== selectedValue
    );

    dataProdutos.forEach((prod, index) => {
      prod.nrOrdem = index + 1;
    });

    if (gridViewProdutos && gridViewProdutos.current)
      gridViewProdutos.current.setDataSource(dataProdutos);

    setDataSourceProdutos(dataProdutos);

    setLoading(false);
  };

  const onClickEditProduto = async (selectedValue, datasource) => {
    setLoading(true);
    const dataProds = datasource.filter((produto) => produto !== selectedValue);

    setDataProduto({ ...selectedValue, status: GridView.EnumStatus.Alterar });

    if (gridViewProdutos && gridViewProdutos.current)
      gridViewProdutos.current.setDataSource(dataProds);

    setDataSourceProdutos(dataProds);

    setLoading(false);
  };

  const onGenerateParcels = async () => {
    if (!dataPedidoCompra.nrSeqCondicaoPagamento) {
      onSetMessage(
        Theme.Danger,
        'Necessário informar a condição de pagamento.'
      );
      return;
    }

    if (!dataPedidoCompra.nrSeqEmpresa) {
      onSetMessage(Theme.Danger, 'Necessário informar a empresa.');
      return;
    }

    if (dataPedidoCompra.vlrPedido === 0) {
      onSetMessage(
        Theme.Danger,
        'Necessário adicionar os produtos do pedido antes de gerar as parcelas.'
      );
      return;
    }
    setLoading(true);

    const dataFaturas = await generateParcels(
      dataPedidoCompra.nrSeqCondicaoPagamento,
      dataPedidoCompra.nrSeqEmpresa,
      dataPedidoCompra.vlrPedido
    );

    if (gridViewFaturamento && gridViewFaturamento.current)
      gridViewFaturamento.current.setDataSource(dataFaturas);

    setLoading(false);
  };

  const onClickRemoveFatura = async (e, datasource) => {
    const dataFaturas = datasource.filter((fatura) => fatura !== e);

    if (gridViewFaturamento && gridViewFaturamento.current)
      gridViewFaturamento.current.setDataSource(dataFaturas);
  };

  const onClickDownload = async (e) => {
    const linkSource = `data:image/png;base64, ${
      e.noImagemBase64 ?? e.noImagem
    }`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = e.noArquivo;
    downloadLink.click();
  };

  const onClickRemoveDocumento = async (e, datasource) => {
    const dataDocumentos = datasource.filter((doc) => doc !== e);

    if (gridViewAnexos && gridViewAnexos.current)
      gridViewAnexos.current.setDataSource(dataDocumentos);
  };

  const columnsprodutos = [
    { key: 'nrSeqProduto', title: 'nrSeqProduto', visible: false },
    { key: 'produto.noProduto', title: 'Produto', sortable: false },
    { key: 'produtoMarca.noProdutoMarca', title: 'Marca', sortable: false },
    {
      key: 'quantidade',
      title: 'Quantidade',
      format: GridView.DataTypes.Decimal,
      decimalPlaces: 2,
      sortable: false,
    },
    {
      key: 'vlrUnitario',
      title: 'Valor Unitário',
      format: GridView.DataTypes.Money,
      sortable: false,
    },
    {
      key: 'vlrFrete',
      title: 'Valor Frete',
      format: GridView.DataTypes.Money,
      sortable: false,
    },
    {
      key: 'vlrIPIPorcentagem',
      title: '% IPI',
      format: GridView.DataTypes.Decimal,
      sortable: false,
    },
    {
      key: 'vlrIPI',
      title: 'Valor IPI',
      format: GridView.DataTypes.Money,
      sortable: false,
    },
    {
      key: 'vlrDesconto',
      title: 'Valor Desconto',
      format: GridView.DataTypes.Money,
      sortable: false,
    },
    {
      key: 'vlrTotalItem',
      title: 'Valor Total',
      format: GridView.DataTypes.Money,
      sortable: false,
    },
    {
      key: 'noObservacao',
      title: 'Observação',
      sortable: false,
    },
    {
      key: 'veiculo.placa',
      title: 'Veiculo',
      sortable: false,
    },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditProduto(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveProduto(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsFaturamento = [
    { key: 'nrSeqPedidoFatura', type: GridView.ColumnTypes.Checkbox },
    { key: 'formaPagamento.noFormaPagamento', title: 'Forma Pagamento' },
    {
      key: 'vlrParcela',
      title: 'Valor',
      format: GridView.DataTypes.Money,
      sortable: false,
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },

    {
      key: 'nrSeqPedidoFatura',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveFatura(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsAnexos = [
    { key: 'noArquivo', title: 'Documento' },
    { key: 'noObservacao', title: 'Observações' },
    {
      key: 'nrSeqPedidoCompraDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
    },
    {
      key: 'nrSeqPedidoCompraDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onAddDocumento = async () => {
    if (importedFilePedido.length > 0) {
      let noImageBase64 = await toBase64(importedFilePedido[0]);
      noImageBase64 = String(noImageBase64).substring(
        String(noImageBase64).indexOf(',') + 1
      );

      const documento = new PedidoCompraDocumento({
        noArquivo: importedFilePedido[0].name,
        noTipoArquivo: importedFilePedido[0].type,
        noTamanho: String(importedFilePedido[0].size),
        noObservacao: pedidoCompraDoc.noObservacao,
        noImageBase64,
        status: GridView.EnumStatus.Inserir,
      });

      let lista = [];

      if (gridViewAnexos && gridViewAnexos.current)
        lista = gridViewAnexos.current.getDataSource();

      lista.push(documento);

      if (gridViewAnexos && gridViewAnexos.current)
        gridViewAnexos.current.setDataSource(lista);

      setImportedFilePedido([]);
    }
  };

  return (
    <Modal
      show={show}
      title={data?.titleModal}
      onClose={() => onSend()}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row justify-content-start'>
          <div className='col-2 mb-3'>
            <Button
              outline
              text='Salvar'
              theme={Theme.Success}
              template={Button.Templates.Default}
              size={BootstrapSizes.Medium}
              onClick={save}
            />
          </div>
          <div className='col-2 mb-3'>
            <Button
              outline
              text='Sair'
              theme={Theme.Danger}
              template={Button.Templates.Default}
              size={BootstrapSizes.Medium}
              onClick={onSend}
            />
          </div>
        </div>
        <div className='row'>
          <Panel>
            <Panel.Header
              icon='list'
              title='Informações do Pedido'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Comprador'
                    required
                    searchDataSource={onSearchComprador}
                    selectedItem={dataPedidoCompra.comprador}
                    onSelectItem={(comprador) => {
                      setDataPedidoCompra({
                        ...dataPedidoCompra,
                        comprador,
                        nrSeqPessoaCop: comprador.nrSeqPessoaCop,
                      });
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Fornecedor'
                    searchDataSource={onSearchPessoaFor}
                    required
                    selectedItem={dataPedidoCompra.fornecedor}
                    onSelectItem={(fornecedor) => {
                      setDataPedidoCompra({
                        ...dataPedidoCompra,
                        fornecedor,
                        nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                      });
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>

                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Tipo de Despesa'
                    required
                    searchDataSource={onAutoCompleteTipoDespesa}
                    selectedItem={dataPedidoCompra.tipoDespesa}
                    onSelectItem={(tipoDespesa) =>
                      setDataPedidoCompra({
                        ...dataPedidoCompra,
                        tipoDespesa,
                        nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
                      })
                    }
                    dataSourceTextProperty='noTipoDespesa'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-3 mb-3'>
                  <DatePicker
                    label='Data de Emissão'
                    text={dataPedidoCompra.dtEmissao}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dtEmissao) =>
                      setDataPedidoCompra({
                        ...dataPedidoCompra,
                        dtEmissao,
                      })
                    }
                  />
                </div>
                <div className='col-3 mb-3'>
                  <DatePicker
                    label='Data de Entrega'
                    text={dataPedidoCompra.dtEntrega}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dtEntrega) =>
                      setDataPedidoCompra({
                        ...dataPedidoCompra,
                        dtEntrega,
                      })
                    }
                  />
                </div>
                <div className='col-3 mb-3'>
                  <Textbox
                    label='Observação Pedido'
                    text={dataPedidoCompra.noObservacaoGeral}
                    onChangedValue={(noObservacaoGeral) => {
                      setDataPedidoCompra({
                        ...dataPedidoCompra,
                        noObservacaoGeral,
                      });
                    }}
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    label='Valor Frete do Pedido'
                    text={dataPedidoCompra.vlrFretePedido}
                    maxLength={40}
                    mask={MaskTypes.Currency}
                    onChangedValue={(vlrFretePedido) => {
                      setDataPedidoCompra({
                        ...dataPedidoCompra,
                        vlrFretePedido,
                        vlrPedido: calculateFreteValorProdutoTotal(
                          vlrFretePedido
                        ),
                      });
                    }}
                  />
                </div>

                <div className='col-2 mb-3'>
                  <Textbox
                    label='Valor Total de Desconto'
                    readOnly
                    text={dataPedidoCompra.vlrDescontoPedido}
                    maxLength={40}
                    mask={MaskTypes.Currency}
                    onChangedValue={(vlrDescontoPedido) =>
                      setDataPedidoCompra({
                        ...dataPedidoCompra,
                        vlrDescontoPedido,
                      })
                    }
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    label='Valor do Pedido'
                    readOnly
                    text={dataPedidoCompra.vlrPedido}
                    maxLength={22}
                    decimalPlaces={3}
                    mask={MaskTypes.Currency}
                    onChangedValue={(vlrPedido) =>
                      setDataPedidoCompra({
                        ...dataPedidoCompra,
                        vlrPedido,
                      })
                    }
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        <div className='row'>
          <Panel>
            <Panel.Header
              icon={['fa', 'briefcase']}
              title='Produtos'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Produto'
                    searchDataSource={onSearchProduto}
                    selectedItem={dataProduto.produto}
                    onSelectItem={(produto) => {
                      setDataProduto({
                        ...dataProduto,
                        produto,
                        nrSeqProduto: produto.nrSeqProduto,
                      });
                    }}
                    dataSourceTextProperty='noProduto'
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Quantidade'
                    text={dataProduto.quantidade}
                    mask={MaskTypes.DecimalCustom}
                    decimalPlaces={2}
                    onChangedValue={(quantidade) =>
                      setDataProduto({
                        ...dataProduto,
                        quantidade,
                        vlrTotalItem: calculateTotalItem({
                          ...dataProduto,
                          quantidade,
                        }),
                        vlrIPI: calculateIPI({
                          ...dataProduto,
                          quantidade,
                        }),
                      })
                    }
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Valor do Produto'
                    text={dataProduto.vlrUnitario}
                    maxLength={40}
                    decimalPlaces={4}
                    mask={MaskTypes.Currency}
                    onChangedValue={(vlrUnitario) =>
                      setDataProduto({
                        ...dataProduto,
                        vlrUnitario,
                        vlrTotalItem: calculateTotalItem({
                          ...dataProduto,
                          vlrUnitario,
                        }),
                        vlrIPI: calculateIPI({
                          ...dataProduto,
                          vlrUnitario,
                        }),
                      })
                    }
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Valor Frete'
                    text={dataProduto.vlrFrete}
                    maxLength={40}
                    readOnly
                    mask={MaskTypes.Currency}
                    onChangedValue={(vlrFrete) =>
                      setDataProduto({
                        ...dataProduto,
                        vlrFrete,
                      })
                    }
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Percentual IPI'
                    text={dataProduto.vlrIPIPorcentagem}
                    mask={MaskTypes.DecimalCustom}
                    decimalPlaces={2}
                    onChangedValue={(vlrIPIPorcentagem) =>
                      setDataProduto({
                        ...dataProduto,
                        vlrIPIPorcentagem,
                        vlrIPI: calculateIPI({
                          ...dataProduto,
                          vlrIPIPorcentagem: vlrIPIPorcentagem ?? 0,
                        }),

                        vlrTotalItem: calculateTotalItem({
                          ...dataProduto,
                          vlrIPIPorcentagem,
                          vlrIPI: 0,
                        }),
                      })
                    }
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Valor IPI'
                    text={dataProduto.vlrIPI}
                    maxLength={40}
                    mask={MaskTypes.Currency}
                    onChangedValue={(vlrIPI) => {
                      setDataProduto({
                        ...dataProduto,
                        vlrIPI,
                        vlrIPIPorcentagem: calculateIPIPercentage({
                          ...dataProduto,
                          vlrIPI: vlrIPI ?? 0,
                        }),
                        vlrTotalItem: calculateTotalItem({
                          ...dataProduto,
                          vlrIPI,
                          vlrIPIPorcentagem: 0,
                        }),
                      });
                    }}
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Valor de Desconto'
                    text={dataProduto.vlrDesconto}
                    maxLength={40}
                    mask={MaskTypes.Currency}
                    onChangedValue={(vlrDesconto) =>
                      setDataProduto({
                        ...dataProduto,
                        vlrDesconto,
                        vlrTotalItem:
                          dataProduto.quantidade * dataProduto.vlrUnitario +
                          dataProduto.vlrIPI -
                          vlrDesconto,
                      })
                    }
                  />
                </div>

                <div className='col-4 mb-3'>
                  <Textbox
                    label='Observações Produto'
                    text={dataProduto.noObservacao}
                    onChangedValue={(noObservacao) =>
                      setDataProduto({
                        ...dataProduto,
                        noObservacao,
                      })
                    }
                  />
                </div>

                {(dataPedidoCompra.tipoDespesa?.flgVinculaVeiculo ||
                  dataProduto.veiculo) && (
                  <div className='col-2'>
                    <Autocomplete
                      label='Placa do Veículo'
                      searchDataSource={onAutoCompleteVeiculo}
                      selectedItem={dataProduto.veiculo}
                      onSelectItem={(veiculo) =>
                        setDataProduto({
                          ...dataProduto,
                          veiculo,
                          nrSeqVeiculo: veiculo.nrSeqVeiculo,
                        })
                      }
                      dataSourceTextProperty='placa'
                    />
                  </div>
                )}
                <div className='col-2'>
                  <Textbox
                    label='Valor Total'
                    readOnly
                    decimalPlaces={4}
                    text={dataProduto.vlrTotalItem}
                    mask={MaskTypes.Currency}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-auto'>
                  <Button
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Success}
                    template={Button.Templates.Button}
                    onClick={onAdicionarProduto}
                    text='Adicionar'
                  />
                </div>
              </div>

              <div className='row mt-4'>
                <div className='col mb-2 '>
                  <GridView
                    ref={gridViewProdutos}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsprodutos}
                    offlineData
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        <div className='row mb-3'>
          <Panel>
            <Panel.Header
              icon={['fa', 'check']}
              title='Faturamento'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col-2 mb-2'>
                  <Switch
                    formControl
                    label='Gerar Financeiro'
                    checked={dataPedidoCompra.flgGerarFinanceiro}
                    onChange={(flgGerarFinanceiro) =>
                      setDataPedidoCompra({
                        ...dataPedidoCompra,
                        flgGerarFinanceiro,
                      })
                    }
                  />
                </div>
              </div>

              {dataPedidoCompra.flgGerarFinanceiro && (
                <div className='row'>
                  <div className='col-2 mt-3'>
                    <Button
                      outline
                      theme={Theme.Success}
                      template={Button.Templates.Button}
                      text='Gerar Parcelas'
                      onClick={onGenerateParcels}
                    />
                  </div>
                  <div className='col-4 mb-3'>
                    <Autocomplete
                      label='Condição de Pagamento'
                      searchDataSource={onSearchCondicaoPagamento}
                      selectedItem={dataPedidoCompra.condicaoPagamento}
                      onSelectItem={(condicaoPagamento) => {
                        setDataPedidoCompra({
                          ...dataPedidoCompra,
                          condicaoPagamento,
                          nrSeqCondicaoPagamento:
                            condicaoPagamento.nrSeqCondicaoPagamento,
                        });
                      }}
                      dataSourceTextProperty='noCondicaoPagamento'
                    />
                  </div>
                </div>
              )}
              <div className='row mt-3'>
                <div className='col mb-3'>
                  <GridView
                    ref={gridViewFaturamento}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsFaturamento}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        <div className='row mt-3'>
          <Panel>
            <Panel.Header
              icon={['fas', 'file']}
              title='Anexos'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col-4 mb-3'>
                  <Textbox
                    label='Observação Documento'
                    text={pedidoCompraDoc.noObservacao}
                    onChangedValue={(noObservacao) =>
                      setPedidoCompraDoc({
                        ...pedidoCompraDoc,
                        noObservacao,
                      })
                    }
                  />
                </div>
                <div className='col-6 mb-3'>
                  <FileUpload
                    files={importedFilePedido}
                    onChange={(files) => setImportedFilePedido(files)}
                    allowedMimeTypes={[
                      MimeTypes.Types.PDF,
                      MimeTypes.Types.Image,
                    ]}
                  />
                </div>

                <div className='col-2 mt-3'>
                  <Button
                    outline
                    theme={Theme.Success}
                    icon='plus'
                    template={Button.Templates.Button}
                    text='Adicionar'
                    onClick={onAddDocumento}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col mb-3'>
                  <GridView
                    ref={gridViewAnexos}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsAnexos}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </Modal.Body>
    </Modal>
  );
}
