import React, { useRef, useState, useEffect } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';

import {
  GridView,
  CSDSelPage,
  Autocomplete,
  DatePicker,
  Textbox,
  Switch,
  DropdownMulti,
} from 'ui/components';

import {
  getPocPocoPagined,
  getStatusPocoAutoComplete,
} from 'core/services/POC/pocPoco';

import { getClienteAutoComplete } from 'core/services/FIN';
import { getCidadeAutoComplete } from 'core/services/SEG';
import { getDominioGeologicoAutoComplete } from 'core/services/POC/dominioGeologico';
import { getPerfuratrizAutoComplete } from 'core/services/POC/perfuratriz';

const columns = [
  { key: 'nrSeqPocPoco', type: GridView.ColumnTypes.Checkbox },
  { key: 'getTipoPoco', title: 'Tipo' },
  { key: 'cdPocPoco', title: 'Código' },
  { key: 'cliente.noPessoa', title: 'Cliente' },
  { key: 'cliente.nrDocumento', title: 'CPF/CNPJ' },
  {
    key: 'dtCadastro',
    title: 'Data Cadastro',
    format: GridView.DataTypes.Date,
    visible: false,
  },
  {
    key: 'getDataInicio',
    title: 'Data Início Poço',
  },
  {
    key: 'getDataFim',
    title: 'Data Fim Poço',
  },
  { key: 'endereco.noCidade', title: 'Cidade do Poço' },
  { key: 'nrProfundidade', title: 'Profundidade (mts)' },
  { key: 'nrVazao', title: 'Vazão (m)' },
  {
    key: 'dominioGeologico.noPocDominioGeologico',
    title: 'Dominio Geologico',
    sortKey: 'noPocDominioGeologico',
  },
  {
    key: 'perfuratriz.noPocPerfuratriz',
    title: 'Perfuratriz',
    sortKey: 'noPocPerfuratriz',
  },
  { key: 'noStatusPoco', title: 'Status' },
];

export default function ControlePocoFiltro({
  transaction,
  isActive,
  onOpenPage,
}) {
  const gridView = useRef();
  const dropDownCliente = useRef();
  const [filtros, setFiltros] = useState({ flgPocoProprio: true });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});

  const onSetMessage = (status, messageshow) => {
    if (messageshow)
      setMessage({
        message: messageshow,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  const onSearchDominioGeologico = async (e) => {
    const { dominioGeologico } = await getDominioGeologicoAutoComplete({
      noPocDominioGeologico: e,
    });
    return dominioGeologico;
  };

  const onSearchPerfuratriz = async (e) => {
    const { perfuratriz } = await getPerfuratrizAutoComplete({
      NoPocPerfuratriz: e,
    });
    return perfuratriz;
  };

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getPocPocoPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ControlePoco/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return clientes;
  };

  const onSearchCidade = async (e) => {
    const { status, message: msg, cidades } = await getCidadeAutoComplete({
      flgCidadePoco: true,
      noCidade: e,
    });
    onSetMessage(status, msg);
    return cidades;
  };

  const onSearchStatusPoco = async (e) => {
    const { statuspoco } = await getStatusPocoAutoComplete({
      noStatusPoco: e,
    });

    return statuspoco;
  };
  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqPocPoco = sourceRow.find((e) => e.key === 'nrSeqPocPoco');
    onOpenMaintenance(nrSeqPocPoco.value);
  };

  useEffect(() => {
    async function load() {
      setLoading(true);
      const { data, pagination } = await getPocPocoPagined(filtros ?? {});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      if (dropDownCliente && dropDownCliente.current)
        dropDownCliente.current.loadDataSource(await onSearchCliente);

      setLoading(false);
    }
    load();
  }, []);

  return (
    <CSDSelPage
      isActive={isActive}
      loading={loading}
      message={message}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      title='Seleção Controle Poço'
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-6'>
          <DropdownMulti
            label='Cliente'
            ref={dropDownCliente}
            dataSourcePropertyText='noPessoa'
            dataSourcePropertyValue='nrSeqPessoaCli'
            selectedItems={selectedItems.clientes ?? []}
            onSelectItem={(clientes) => {
              setSelectedItems({ ...selectedItems, clientes });
              setFiltros({
                ...filtros,
                nrSeqsPessoaCli: clientes.map((item) => item.nrSeqPessoaCli),
              });
            }}
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Código'
            text={filtros.cdPocPoco}
            onChangedValue={(cdPocPoco) =>
              setFiltros({
                ...filtros,
                cdPocPoco,
              })
            }
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Poço próprio'
            checked={filtros.flgPocoProprio}
            onChange={(flgPocoProprio) =>
              setFiltros({
                ...filtros,
                flgPocoProprio,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Cidade'
            searchDataSource={onSearchCidade}
            selectedItem={filtros.cidade}
            onSelectItem={(cidade) =>
              setFiltros({
                ...filtros,
                cidade,
                nrSeqCidade: cidade.nrSeqCidade,
              })
            }
            dataSourceTextProperty='noCidade'
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Status'
            searchDataSource={onSearchStatusPoco}
            selectedItem={filtros.statuspoco}
            onSelectItem={(statuspoco) =>
              setFiltros({
                ...filtros,
                statuspoco,
                flgStatusPoco: statuspoco.flgStatusPoco,
              })
            }
            dataSourceTextProperty='noStatusPoco'
          />
        </div>
        <div className='col-auto'>
          <DatePicker
            label='Data de Inicio'
            text={filtros.dtInicioPoco}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtInicioPoco) =>
              setFiltros({
                ...filtros,
                dtInicioPoco,
              })
            }
          />
        </div>
        <div className='col-auto'>
          <DatePicker
            label='Data de Termino'
            text={filtros.dtFimPoco}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtFimPoco) =>
              setFiltros({
                ...filtros,
                dtFimPoco,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-6'>
          <Autocomplete
            label='Domínio Geológico'
            searchDataSource={onSearchDominioGeologico}
            selectedItem={filtros.dominioGeologico}
            onSelectItem={(dominioGeologico) =>
              setFiltros({
                ...filtros,
                dominioGeologico,
                nrSeqPocDominioGeologico:
                  dominioGeologico.nrSeqPocDominioGeologico,
              })
            }
            dataSourceTextProperty='noPocDominioGeologico'
          />
        </div>
        <div className='col-6'>
          <Autocomplete
            label='Perfuratriz'
            searchDataSource={onSearchPerfuratriz}
            selectedItem={filtros.perfuratriz}
            onSelectItem={(perfuratriz) =>
              setFiltros({
                ...filtros,
                perfuratriz,
                nrSeqPocPerfuratriz: perfuratriz.nrSeqPocPerfuratriz,
              })
            }
            dataSourceTextProperty='noPocPerfuratriz'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
            enableExcelExport
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
