import ExtratoCategoria from './extratoCategoria';
import TituloEspeciePlanoDeConta from './tituloEspeciePlanoDeConta';

export default class TituloEspecie {
  constructor(jsonObject = {}) {
    this.nrSeqTituloEspecie = jsonObject.nrSeqTituloEspecie;
    this.noEspecie = jsonObject.noEspecie;
    this.flgGeraCod = jsonObject.flgGeraCod;
    this.flgPaga = jsonObject.flgPaga;
    this.cdTituloEspecie = jsonObject.cdTituloEspecie;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqExtratoCategoria = jsonObject.nrSeqExtratoCategoria;
    this.flgPlanoContaCred = jsonObject.flgPlanoContaCred;
    this.flgPlanoContaDeb = jsonObject.flgPlanoContaDeb;
    this.flgExportaTitReceber = jsonObject.flgExportaTitReceber;
    this.flgExportaRecebimento = jsonObject.flgExportaRecebimento;
    this.noPaginaDetalhamento = jsonObject.noPaginaDetalhamento;
    this.flgConhecimentoEletronico = jsonObject.flgConhecimentoEletronico;
    this.flgExibeNoParcelamento = jsonObject.flgExibeNoParcelamento;
    this.flgInativo = jsonObject.flgInativo;
    this.flgExportaAcumuladoMes = jsonObject.flgExportaAcumuladoMes;
    this.flgExportaContaBilAgrupado = jsonObject.flgExportaContaBilAgrupado;
    this.flgExportaContaBilApenasLiquidado =
      jsonObject.flgExportaContaBilApenasLiquidado;
    this.flgPrevisao = jsonObject.flgPrevisao;
    this.extratocategoria = new ExtratoCategoria(
      jsonObject.extratoCategoria ?? {}
    );
    this.planoDeContas = (jsonObject.planoDeContas ?? []).map(
      (planoDeContas) => new TituloEspeciePlanoDeConta(planoDeContas)
    );
  }

  toJson = () => JSON.stringify(this);
}
