import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Autocomplete,
  Button,
  RadioButton,
  GridView,
  Textbox,
  Panel,
} from 'ui/components';
import {
  Theme,
  ResponseStatus,
  BootstrapSizes,
  ColumnTypes,
  StatusGrid,
} from 'ui/Helpers/enums';
import { JustifyContent } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
// @ts-expect-error
import { GridviewColumns } from 'ui/Helpers/interfaces.ts';
import { IFuncionario } from 'core/interfaces/SEG';
import PessoaReferencias from 'core/models/SEG/pessoaReferencias';
import { getGrauParentescoList } from 'core/services/SEG/grauParentesco';

interface IDocumentsPanelProps {
  funcionarioData: IFuncionario;
  setFuncionarioData: (e: Object) => void;
  onSetMessage: (status: ResponseStatus, msg: string) => void;
}

const PessoaReferencialPanel = forwardRef(
  (
    { funcionarioData, setFuncionarioData, onSetMessage }: IDocumentsPanelProps,
    ref
  ) => {
    const gridView = useRef<any>();

    const [
      pessoaReferencialData,
      setPessoaReferencialData,
    ] = useState<PessoaReferencias>({});

    const TipoReferencia = [
      { text: 'Pessoal', value: true },
      { text: 'Profissional', value: 'Não' },
    ];

    const fillGridView = () => {
      if (
        gridView &&
        gridView.current &&
        funcionarioData.funcionarioReferencias &&
        funcionarioData.funcionarioReferencias.length > 0
      ) {
        const gridList = funcionarioData.funcionarioReferencias.map((item) => {
          if (item.noImagem) {
            item.flgExibeDownload = true;
          } else {
            item.flgExibeDownload = false;
          }
          return item;
        });
        gridView.current.setDataSource(gridList);
      }
    };

    useImperativeHandle(ref, () => ({
      clear() {
        setPessoaReferencialData({});
        gridView?.current?.setDataSource([]);
      },
      fillGrid() {
        fillGridView();
      },
      getDataGrid() {
        const listaDocsGrid = gridView?.current?.getDataSource() ?? [];
        return listaDocsGrid;
      },
    }));

    const onSearchParentesco = async (e: any) => {
      const { data: grausParentesco } = await getGrauParentescoList({
        noGrauParentesco: e,
      });

      return grausParentesco;
    };

    const onClickAddReferencia = async () => {
      if (
        (pessoaReferencialData.flgPessoal ||
          !pessoaReferencialData.flgPessoal) &&
        pessoaReferencialData.noReferencia &&
        pessoaReferencialData.fone &&
        pessoaReferencialData.nrSeqGrauParentesco
      ) {
        const obj = {
          ...pessoaReferencialData,
          status: pessoaReferencialData.status ?? StatusGrid.Inserir,
        };
        const list = gridView.current.getDataSource();
        list.push(obj);

        if (gridView && gridView.current) gridView.current.setDataSource(list);

        setFuncionarioData({
          ...funcionarioData,
          funcionarioReferencias: list,
        });
        setPessoaReferencialData({});
      } else if (
        pessoaReferencialData.flgPessoal === null ||
        pessoaReferencialData.flgPessoal === undefined
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o Tipo Referencia');
      } else if (
        pessoaReferencialData.noReferencia === null ||
        pessoaReferencialData.noReferencia === undefined
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o nome');
      } else if (
        pessoaReferencialData.fone === null ||
        pessoaReferencialData.fone === undefined
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o Telefone');
      } else if (
        pessoaReferencialData.nrSeqGrauParentesco === null ||
        pessoaReferencialData.nrSeqGrauParentesco === undefined
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o Grau Parentesco');
      }
    };

    const onClickEditPessoaReferencial = (e: any, datasource: any[]) => {
      const obj = datasource.find((item) => item === e);

      if (obj.status !== StatusGrid.Inserir) {
        obj.status = StatusGrid.Alterar;
      }

      setPessoaReferencialData({ ...obj });

      const listaEquipamentos = datasource.filter((item) => item !== e);

      if (gridView && gridView.current)
        gridView.current.setDataSource(listaEquipamentos);
    };

    const onClickRemovePessoaReferencial = async (e: any) => {
      const list = gridView.current.getDataSource();

      list.filter((el: any) => el === e)[0].status = StatusGrid.Remover;

      if (gridView && gridView.current) gridView.current.setDataSource(list);
    };

    const columns: Array<GridviewColumns> = [
      {
        key: 'noReferencia',
        title: 'Nome',
        sortable: false,
      },
      { key: 'fone', title: 'Telefone', sortable: false },
      { key: 'grauParentesco.noGrauParentesco', title: 'Grau Parentesco' },
      {
        key: 'flgPessoal',
        title: 'Pessoal',
        sortable: false,
      },
      {
        key: 'nrSeqPessoaReferencial_btnEdit',
        type: ColumnTypes.Button,
        onClick: (e: any, datasource: any) =>
          onClickEditPessoaReferencial(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
        title: 'Editar',
      },
      {
        key: 'nrSeqPessoaReferencial_btnExcluir',
        type: ColumnTypes.Button,
        title: 'Excluir',
        onClick: (e: any) => onClickRemovePessoaReferencial(e),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    return (
      <div className='col mt-3'>
        {/* @ts-expect-error */}
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header
            title='Referências'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-auto'>
                <RadioButton
                  outline
                  label='Status'
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  buttons={TipoReferencia}
                  selectedButton={pessoaReferencialData.flgPessoal}
                  onChange={(statusPessoal) =>
                    setPessoaReferencialData({
                      ...pessoaReferencialData,
                      flgPessoal: statusPessoal,
                    })
                  }
                />
              </div>
              <div className='col-4'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Nome'
                  text={pessoaReferencialData.noReferencia}
                  maxLength={80}
                  onChangedValue={(noReferencia: string) =>
                    setPessoaReferencialData({
                      ...pessoaReferencialData,
                      noReferencia,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Telefone'
                  maxLength={20}
                  mask={MaskTypes.CellPhone}
                  text={pessoaReferencialData.fone}
                  onChangedValue={(fone: string) =>
                    setPessoaReferencialData({
                      ...pessoaReferencialData,
                      fone,
                    })
                  }
                />
              </div>
              <div className='col-3'>
                <Autocomplete
                  label='Grau Parentesco'
                  searchDataSource={onSearchParentesco}
                  selectedItem={pessoaReferencialData.grauParentesco}
                  onSelectItem={(grauParentesco) =>
                    setPessoaReferencialData({
                      ...pessoaReferencialData,
                      grauParentesco,
                      nrSeqGrauParentesco: grauParentesco.nrSeqGrauParentesco,
                      noGrauParentesco: grauParentesco.noGrauParentesco,
                    })
                  }
                  dataSourceTextProperty='noGrauParentesco'
                />
              </div>
              <div className='col d-flex align-items-end'>
                <Button
                  onClick={onClickAddReferencia}
                  outline
                  theme={Theme.Success}
                  text='Adicionar'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridView}
                  // @ts-expect-error
                  dataColumns={columns}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
);

export default PessoaReferencialPanel;
