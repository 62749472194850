import qs from 'qs';
import Pagination from 'core/models/pagination';
import TipoConvenioEncargo from 'core/models/ASS/tipoConvenioEncargo';
import api from '../api';

const baseURL = `/ASS/TipoConvenioEncargo`;

export const getTipoConvenioEncargoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TipoConvenioEncargo(json)) ?? [],
  };
};

export const getTipoConvenioEncargoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    tipoConvenioEncargos:
      value?.map((json) => new TipoConvenioEncargo(json)) ?? [],
  };
};

export const getTipoConvenioEncargoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TipoConvenioEncargo(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getTipoConvenioEncargo = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new TipoConvenioEncargo(value) : {};
};

export const saveTipoConvenioEncargo = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteTipoConvenioEncargo = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printTipoConvenioEncargo = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelTipoConvenioEncargo = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllTipoConvenioEncargo = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
