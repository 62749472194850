import React, { useState, useRef, useEffect } from 'react';
import { ResponseStatus, BootstrapSizes, Theme } from 'ui/Helpers/utils';
import {
  Button,
  GridView,
  Loader,
  Modal,
  Notification,
  Autocomplete,
} from 'ui/components';

import {
  // saveOrdemServico,
  geraOrdemServico,
} from 'core/services/FIN/ordemServico';

import { getFuncionarioList } from 'core/services/SEG/funcionario';

const ModalTecnicos = ({
  nrSeqPocOrcamento,
  show,
  transaction,
  onOpenReport,
  onClose,
}) => {
  const gridViewTecnicos = useRef(null);
  const [dataTecnico, setDataTecnico] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    setData(nrSeqPocOrcamento);
    setMessage({ message: '' });
    if (gridViewTecnicos.current)
      gridViewTecnicos.current.setDataSource(nrSeqPocOrcamento);
  }, [nrSeqPocOrcamento]);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchTecnico = async (e) => {
    const { status, message: msg, data: tecnicos } = await getFuncionarioList({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return tecnicos;
  };
  /*
  const onGravar = () => {
    const gridTecnicos = gridViewTecnicos.current.getDataSource();
    tecnico(gridTecnicos);
  };
  
  const onOrdemServico = async () => {
    setLoading(true);

    const { value } = await geraOrdemServico(data);

    onOpenReport(transaction.noTransacao, value.pdf);
    setData({ ...data });
    setLoading(false);
  };
  */

  const onSave = async () => {
    setLoading(true);
    const tecnicos = gridViewTecnicos.current.getDataSource();

    const obj = {
      nrSeqPocOrcamento,
      tecnicos,
    };

    const {
      status,
      message: msg,
      data: tecnicosprint,
    } = await geraOrdemServico(obj);

    onOpenReport(transaction.noTransacao, tecnicosprint.pdf);
    setData({ ...data, status: tecnicosprint.status });
    setLoading(false);

    if (status === ResponseStatus.Success) {
      // await geraOrdemServico(tecnicosprint);
      onClose(tecnicosprint);
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    setLoading(false);
  };

  const onAdicionaTecnico = () => {
    if (dataTecnico.funcionario.noPessoa) {
      dataTecnico.noTecnico = dataTecnico.funcionario.noPessoa;
      dataTecnico.status = GridView.EnumStatus.Inserir;
      const listaTecnicos = gridViewTecnicos.current.getDataSource() ?? [];
      listaTecnicos.push(dataTecnico);

      listaTecnicos.sort((a, b) => a.noTecnico.localeCompare(b.noTecnico));

      if (gridViewTecnicos && gridViewTecnicos.current)
        gridViewTecnicos.current.setDataSource(listaTecnicos);
    }

    setDataTecnico({
      ...dataTecnico,
      funcionario: {},
    });
  };

  const onRemoveTecnico = async (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (gridViewTecnicos && gridViewTecnicos.current)
      gridViewTecnicos.current.setDataSource(datasource);
  };

  const columnsTecnicos = [
    {
      key: 'nrSeqOrdemServicoTecnico',
      title: 'nrSeqOrdemServicoTecnico',
      visible: false,
    },
    { key: 'funcionario.noPessoa', title: 'Técnico' },
    {
      key: 'nrSeqOrdemServicoTecnico',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveTecnico(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];
  return (
    <Modal
      show={show}
      size={BootstrapSizes.ExtraLarge}
      title='Informar os técnicos da Ordem de Serviço'
      onClose={onClose}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col-3 mb-3'>
            <Autocomplete
              label='Técnico'
              searchDataSource={onSearchTecnico}
              selectedItem={dataTecnico.funcionario}
              onSelectItem={(funcionario) => {
                setDataTecnico({
                  ...dataTecnico,
                  funcionario,
                  nrSeqPessoaFun: funcionario.nrSeqPessoaFun,
                });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
          <div className='col-2 mt-3'>
            <Button
              outline
              theme={Theme.Success}
              template={Button.Templates.Button}
              text='Adicionar'
              onClick={onAdicionaTecnico}
            />
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col'>
            <GridView
              ref={gridViewTecnicos}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnsTecnicos}
              showSelectSizes={false}
              showPagination={false}
            />
          </div>
        </div>
        <div className='row mt-5' />
      </Modal.Body>
      <Modal.Footer>
        <Button
          icon={['far', 'save']}
          text='Gerar Os'
          theme={Theme.Success}
          onClick={onSave}
        />
        <Button
          icon='ban'
          text='Cancelar'
          theme={Theme.Danger}
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default ModalTecnicos;
