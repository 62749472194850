import React from 'react';

// @ts-expect-error
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';
import { MaskTypes } from 'ui/Helpers/masks';

import { Textbox, Panel, Autocomplete } from 'ui/components';
import { getEnvioPadraoAutoComplete } from 'core/services/TEL/envioPadrao';

interface Props {
  data?: any;
  setData?: any;
  contato?: any;
  formdata?: any;
  onSetMessage: (status: ResponseStatus, msg: string) => void;
  validaEnvio: (
    contato: any,
    validaComMsg: boolean,
    objEnvioPadrao: any
  ) => boolean;
}

const PainelContato = ({ data, setData, onSetMessage, validaEnvio }: Props) => {
  const onSearchEnvioPadrao = async (e: string) => {
    const { envios } = await getEnvioPadraoAutoComplete({
      noEnvioPadrao: e,
    });

    return envios;
  };

  const isEmailValid = async () => {
    /* eslint no-control-regex: "error" */
    const re = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;

    if (!re.test(data?.contato?.email)) {
      onSetMessage(
        ResponseStatus.Error,
        'O e-mail indicado possui formato inválido.'
      );
    }
  };

  const isEmailNfeValid = async () => {
    /* eslint no-control-regex: "error" */
    const re = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;

    if (!re.test(data?.contato?.emailNfe)) {
      onSetMessage(
        ResponseStatus.Error,
        'O e-mail indicado possui formato inválido.'
      );
    }
  };

  return (
    <div className='row mt-3'>
      {/* @ts-expect-error */}
      <Panel>
        {/* @ts-expect-error */}
        <Panel.Header title='Contato' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Textbox
                label='E-mail'
                id='txtEmail'
                type='email'
                maxLength={250}
                text={data?.contato?.email}
                onChangedValue={(email: string) =>
                  setData({ ...data, contato: { ...data.contato, email } })
                }
                onBlur={() => isEmailValid()}
              />
            </div>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Textbox
                label='E-mail Nfe'
                id='txtEmail'
                type='emailNfe'
                maxLength={250}
                text={data?.contato?.emailNfe}
                onChangedValue={(emailNfe: string) =>
                  setData({ ...data, contato: { ...data.contato, emailNfe } })
                }
                onBlur={() => isEmailNfeValid()}
              />
            </div>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Textbox
                label='Site'
                id='txtSite'
                maxLength={40}
                text={data?.contato?.site}
                onChangedValue={(site: string) =>
                  setData({ ...data, contato: { ...data.contato, site } })
                }
              />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-4'>
              <Autocomplete
                label='Envio Padrão'
                id='ddlEnvioPadrao'
                searchDataSource={onSearchEnvioPadrao}
                selectedItem={data?.contato?.envioPadrao}
                onSelectItem={(envioPadrao: any) => {
                  let validaenviopadrao;
                  if (data.contato)
                    validaenviopadrao = validaEnvio(
                      data.contato,
                      true,
                      envioPadrao
                    );
                  setData({
                    ...data,
                    contato: {
                      ...data.contato,
                      envioPadrao: validaenviopadrao ? envioPadrao : null,
                      FlgEnvioPadrao:
                        envioPadrao.nrEnvioPadrao ?? envioPadrao.FlgEnvioPadrao,
                    },
                  });
                }}
                dataSourceTextProperty='noEnvioPadrao'
              />
            </div>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <Textbox
                label='Fone Empresa'
                id='txtFoneEmpresa'
                maxLength={20}
                mask={MaskTypes.CellPhone}
                text={data?.contato?.foneEmp}
                onChangedValue={(foneEmp: string) =>
                  setData({
                    ...data,
                    contato: { ...data.contato, foneEmp },
                  })
                }
              />
            </div>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <Textbox
                label='Fone Contato'
                id='txtFoneContato'
                maxLength={20}
                mask={MaskTypes.CellPhone}
                text={data?.contato?.fone}
                onChangedValue={(fone: string) =>
                  setData({ ...data, contato: { ...data.contato, fone } })
                }
              />
            </div>

            <div className='col-2'>
              {/* @ts-expect-error */}
              <Textbox
                label='Celular'
                id='txtCelular'
                maxLength={20}
                mask={MaskTypes.CellPhone}
                text={data?.contato?.celular}
                onChangedValue={(celular: string) =>
                  setData({
                    ...data,
                    contato: { ...data.contato, celular },
                  })
                }
              />
            </div>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <Textbox
                label='VoIP'
                id='txtVoIP'
                maxLength={20}
                text={data?.contato?.voip}
                onChangedValue={(voip: string) =>
                  setData({
                    ...data,
                    contato: { ...data.contato, voip },
                  })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </div>
  );
};

export default PainelContato;
