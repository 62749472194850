export default class UsuarioNotificacao {
  nrSeqUsuarioNotificacao: number;

  dtCad: Date;

  noMensagem: string;

  dtVisualizacao: Date;

  nrSeqUsuario: number;

  constructor(jsonObject: UsuarioNotificacao) {
    this.nrSeqUsuarioNotificacao = jsonObject.nrSeqUsuarioNotificacao;
    this.dtCad = jsonObject.dtCad;
    this.noMensagem = jsonObject.noMensagem;
    this.dtVisualizacao = jsonObject.dtVisualizacao;
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
  }

  toJson = () => JSON.stringify(this);
}
