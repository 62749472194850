import ContratoAgrupado from 'core/models/TEL/contratoAgrupado';
import ClienteContaCabecalho from 'core/models/TEL/clienteContaCabecalho';
import ContaCabecalho from 'core/models/TEL/contaCabecalho';
import api from '../api';

export const getContratoAgrupadoList = async (filters) => {
  const response = await api.get(`/Contrato/SearchContratosAgrupados`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    contratosAgrupados: value?.map((json) => new ContratoAgrupado(json)) ?? [],
  };
};

export const getContratoList = async (filters) => {
  const response = await api.get(`/Contrato/SearchContratos`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    contratos: value?.map((json) => new ClienteContaCabecalho(json)) ?? [],
  };
};

export const getContrato = async (id) => {
  const response = await api.get(`/Contrato/`, {
    params: { nrSeqClienteContaCab: id },
  });
  const { value } = response.data ?? {};
  return value ? new ContaCabecalho(value) : {};
};

export const getFaturaList = async (filters) => {
  const response = await api.get(`/Contrato/SearchFaturas`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    faturas: value?.map((json) => new ContaCabecalho(json)) ?? [],
  };
};
