import qs from 'qs';
import Pagination from 'core/models/pagination';
import TipoOcorrenciaColetaEmbarque from 'core/models/FRO/tipoOcorrenciaColetaEmbarque';
import api from '../api';

const baseURL = `/FRO/TipoOcorrenciaColetaEmbarque`;

export const getTipoOcorrenciaColetaEmbarqueList = async (filters) => {
  const response = await api.get(
    `${baseURL}/TipoOcorrenciaColetaEmbarque/Search`,
    {
      params: filters,
    }
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TipoOcorrenciaColetaEmbarque(json)) ?? [],
  };
};

export const getTipoOcorrenciaColetaEmbarquePagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TipoOcorrenciaColetaEmbarque(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getTipoOcorrenciaColetaEmbarque = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new TipoOcorrenciaColetaEmbarque(value) : {};
};

export const getTipoOcorrenciaColetaEmbarqueAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    data: value?.map((json) => new TipoOcorrenciaColetaEmbarque(json)) ?? [],
  };
};

export const saveTipoOcorrenciaColetaEmbarque = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteTipoOcorrenciaColetaEmbarque = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printTipoOcorrenciaColetaEmbarque = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelTipoOcorrenciaColetaEmbarque = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllTipoOcorrenciaColetaEmbarque = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
