import React, { useEffect, useRef, useState } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  JustifyContent,
} from 'ui/Helpers/utils';
import {
  getComissaoMotoristaPagined,
  printComissaoMotorista,
  printComissaoMotoristaVales,
  deleteAllComissaoMotorista,
  geraComissaoMotorista,
} from 'core/services/FRO/comissaoMotorista';
import {
  CSDSelPage,
  GridView,
  Panel,
  Autocomplete,
  Switch,
  DatePicker,
  Button,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getUsuarioList } from 'core/services/SEG/usuario';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';
import { getTipoDescontoPagined } from 'core/services/FIN/tipoDesconto';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';

const columns = [
  { key: 'nrSeqComissaoMotorista', type: GridView.ColumnTypes.Checkbox },
  { key: 'nrTit', title: 'Titulo' },
  { key: 'nrSeqTituloPagar', title: 'NrSeqTituloPagar', visible: false },
  { key: 'veiculo.placa', title: 'Placa' },
  { key: 'motorista.noPessoa', title: 'Motorista' },
  { key: 'dtViagem', title: 'Data', format: GridView.DataTypes.Date },
  { key: 'nrNotaFiscal', title: 'Conhecimento' },
  { key: 'notaTransportada', title: 'Nota' },
  { key: 'peso', title: 'Peso' },
  { key: 'vlrFrete', title: 'Vlr Frete' },
  { key: 'vlrTotal', title: 'Vlr Total' },
  { key: 'vlrVale', title: 'Vlr Vale' },
  { key: 'noObservacao', title: 'Observacao' },
  { key: 'cidadeOri.noCidade', title: 'Cidade Origem' },
  { key: 'cidadeDes.noCidade', title: 'Cidade Destino' },
];

export default function ComissaoMotorista({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [listaComissaoMotorista, setListaComissaoMotorista] = useState({
    listIdsgridView: [],
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [tiposDesconto, setTiposDesconto] = useState([]);
  const [usuario, setUsuario] = useState({});
  const [selecionarTodosMarcado, setSelecionarTodosMarcado] = useState(false);
  const mostrarBotao = false;
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    setListaComissaoMotorista(filtros);

    const { data, pagination } = await getComissaoMotoristaPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load(params = null) {
      setLoading(true);

      const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;
      const user = await getUsuarioList(sessionUser);
      setUsuario(user.data[0]);
      let response = [];
      response = await getTipoDescontoPagined(params ?? filtros);

      if (response && response.data) {
        const tiposDescontoComSelecao = response.data.map(
          (tipoDesconto, index) => ({
            ...tipoDesconto,
            flgTipoDescontoSelecionado: index === 0,
          })
        );

        setTiposDesconto(tiposDescontoComSelecao);
      }

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ComissaoMotorista/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqComissaoMotorista = sourceRow.find(
      (e) => e.key === 'nrSeqComissaoMotorista'
    );
    onOpenMaintenance(nrSeqComissaoMotorista.value);
  };

  const onPrint = async () => {
    setLoading(true);
    if (filtros.nrSeqPessoaMot > 0) {
      const selecteds = gridView.current.getCheckBoxValuesAt(0);
      filtros.nrSeqEmpresa = usuario.nrSeqEmpresaDefault;
      filtros.tiposDesconto = tiposDesconto;
      filtros.listIdsgridView = selecteds.map((row) => row[0].value);
      const value = await printComissaoMotorista(filtros);

      onOpenReport(transaction.noTransacao, value);
      setLoading(false);
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há Motorista selecionado para Impressão.',
        theme: Theme.Danger,
      });
    }
  };
  const onPrintVales = async () => {
    setLoading(true);
    if (filtros.nrSeqPessoaMot > 0) {
      const selecteds = gridView.current.getCheckBoxValuesAt(0);
      filtros.nrSeqEmpresa = usuario.nrSeqEmpresaDefault;
      filtros.tiposDesconto = tiposDesconto;
      filtros.listIdsgridView = selecteds.map((row) => row[0].value);
      const value = await printComissaoMotoristaVales(filtros);

      onOpenReport(transaction.noTransacao, value);
      setLoading(false);
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há Motorista selecionado para Impressão.',
        theme: Theme.Danger,
      });
    }
  };
  const gerarTituloPagar = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);
    listaComissaoMotorista.nrSeqEmpresa = usuario.nrSeqEmpresaDefault;
    listaComissaoMotorista.listIdsgridView = selecteds.map(
      (row) => row[0].value
    );
    if (selecteds.length > 0) {
      setListaComissaoMotorista((prevLista) => ({
        ...prevLista,
        listIdsgridView: selecteds.map((row) => row[0].value),
      }));
      const { status, message: msg } = await geraComissaoMotorista(
        listaComissaoMotorista
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para gerar Comissao.',
        theme: Theme.Danger,
      });
    }
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllComissaoMotorista(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };
  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noformaPagamento: e,
      nrSeqGrupoEmpresa: usuario.nrSeqGrupoEmpresa,
      nrSeqEmpresa: usuario.nrSeqEmpresaDefault,
      flgTitPag: true,
    });
    onSetMessage(status, msg);
    return formasPagamento;
  };

  const onSearchPessoaMot = async (e) => {
    const {
      status,
      message: msg,
      motoristas,
    } = await getMotoristaAutoComplete({ noPessoa: e });
    if (msg) onSetMessage(status, msg);
    return motoristas;
  };

  const onSearchVeiculoCavalo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
      nrSeqGrupoEmpresa: usuario.nrSeqGrupoEmpresa,
    });
    if (msg) setMessage(status, msg);
    return veiculos;
  };
  const onSelecionarTodosChange = (flgTipoDescontoSelecionado) => {
    const updatedTiposDesconto = tiposDesconto.map((tipoDesconto) => ({
      ...tipoDesconto,
      flgTipoDescontoSelecionado,
    }));
    setTiposDesconto(updatedTiposDesconto);
    setSelecionarTodosMarcado(flgTipoDescontoSelecionado);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='VIAGEM MOTORISTA 30'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Imprimir Vales'
          icon='print'
          onClick={() => onPrintVales()}
        />
      </ToolbarButtons>
      <Panel className='mb-3'>
        <Panel.Header
          title='Seleção de Comissão Motorista'
          theme={Theme.Light}
          align={JustifyContent.Start}
        />
        <Panel.Body>
          <fieldset
            style={{
              border: '1px solid #cfd4d8',
              padding: '10px',
              borderRadius: '3px',
            }}
          >
            <legend>Pequisa:</legend>
            <div className='row mb-3'>
              <div className='col-2'>
                <Autocomplete
                  label='Placa'
                  searchDataSource={onSearchVeiculoCavalo}
                  selectedItem={filtros.veiculoPrinc}
                  onSelectItem={(veiculoPrinc) => {
                    setFiltros({
                      ...filtros,
                      veiculoPrinc,
                      nrSeqVeiculo: veiculoPrinc.nrSeqVeiculo,
                      noPlaca: veiculoPrinc.noPlaca,
                    });
                  }}
                  dataSourceTextProperty='noVeiculo'
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  label='Motorista'
                  searchDataSource={onSearchPessoaMot}
                  selectedItem={filtros.motorista}
                  onSelectItem={(motorista) => {
                    setFiltros({
                      ...filtros,
                      motorista,
                      nrSeqPessoaMot: motorista.nrSeqPessoaMot,
                      noMotorista: motorista.noMotorista,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-2'>
                <Switch
                  formControl
                  label='Buscar Acertos:'
                  checked={filtros.flgBuscaAcertos}
                  onChange={(flgBuscaAcertos) =>
                    setFiltros({ ...filtros, flgBuscaAcertos })
                  }
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Data Inicial'
                  text={filtros.dtPeriodoIni}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtPeriodoIni) => {
                    setFiltros({ ...filtros, dtPeriodoIni });
                  }}
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Data Final'
                  text={filtros.dtPeriodoFim}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtPeriodoFim) => {
                    setFiltros({ ...filtros, dtPeriodoFim });
                  }}
                />
              </div>
            </div>
          </fieldset>
          <fieldset
            style={{
              border: '1px solid #cfd4d8',
              padding: '10px',
              marginTop: '10px',
              borderRadius: '3px',
            }}
          >
            <legend>Desconto:</legend>
            <div className='row mb-3'>
              <div className='col-3'>
                <Switch
                  formControl
                  label='Selecionar Todos'
                  checked={selecionarTodosMarcado}
                  onChange={() => {
                    onSelecionarTodosChange(!selecionarTodosMarcado);
                  }}
                />
              </div>

              {tiposDesconto.map((tipoDesconto, index) => (
                <div className='col-3' key={tipoDesconto.nrSeqTipoDesconto}>
                  <Switch
                    formControl
                    label={tipoDesconto.noTipoDesconto}
                    checked={tipoDesconto.flgTipoDescontoSelecionado}
                    disabled={selecionarTodosMarcado}
                    onChange={(flgTipoDescontoSelecionado) => {
                      const updatedTiposDesconto = tiposDesconto.map(
                        (item, i) => ({
                          ...item,
                          flgTipoDescontoSelecionado:
                            i === index
                              ? flgTipoDescontoSelecionado
                              : item.flgTipoDescontoSelecionado,
                        })
                      );
                      setTiposDesconto(updatedTiposDesconto);
                    }}
                  />
                </div>
              ))}
              <div className='col-2'>
                <Switch
                  formControl
                  label='Ocultar Zerados'
                  checked={filtros.flgOcultarZerados}
                  onChange={(flgOcultarZerados) =>
                    setFiltros({ ...filtros, flgOcultarZerados })
                  }
                />
              </div>
            </div>
          </fieldset>
          <div className='row mb-3'>
            <div className='col-3'>
              <Autocomplete
                label='Forma Pagamento'
                searchDataSource={onSearchFormaPagamento}
                selectedItem={filtros.formaPagamento}
                onSelectItem={(formaPagamento) =>
                  setListaComissaoMotorista({
                    ...listaComissaoMotorista,
                    formaPagamento,
                    nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                  })
                }
                dataSourceTextProperty='noFormaPagamento'
              />
            </div>
            <div className='col-2'>
              <DatePicker
                label='Periodo'
                text={filtros.dtPeriodoTitulo}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtPeriodoTitulo) => {
                  setListaComissaoMotorista({
                    ...listaComissaoMotorista,
                    dtPeriodoTitulo,
                  });
                }}
              />
            </div>
            <div
              className='col-2'
              style={{
                paddingTop: '20px',
              }}
            >
              {mostrarBotao && (
                <Button
                  text='Gerar Titulo Pagar'
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  icon='save'
                  onClick={gerarTituloPagar}
                />
              )}
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
