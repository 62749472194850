import React, {
  forwardRef,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {
  GridView,
  Textbox,
  Autocomplete,
  Button,
  Textarea,
} from 'ui/components';
import { Theme, BootstrapSizes } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';

import PocPocoPrefiltro from 'core/models/POC/pocPocoPrefiltro';
import PocPocoCimentacao from 'core/models/POC/pocPocoCimentacao';
import { getPocTipoPrefiltroAutoComplete } from 'core/services/POC/tipoPrefiltro';
import { getGranulometricaAutoComplete } from 'core/services/POC/pocGranulometria';

import { sortPocPocoCimentacao } from 'core/services/POC/pocPocoCimentacao';
import { sortPocPocoPrefiltro } from 'core/services/POC/pocPocoPrefiltro';

const CadastroCimentacaoPrefiltro = forwardRef(
  ({
    data,
    setData,
    onInicioFimInvalido,
    onValidaCampoObrigatorioCimentacao,
    onValidaCampoObrigatorioPrefiltro,
  }) => {
    const gridViewPrefiltro = useRef();
    const gridViewCimentacao = useRef();

    const [dataPrefiltro, setDataPrefiltro] = useState(
      new PocPocoPrefiltro({})
    );
    const [dataCimentacao, setDataCimentacao] = useState(
      new PocPocoCimentacao({})
    );

    const [totalPrefiltro, setTotalPrefiltros] = useState();
    const [totalCimentacoes, setTotalCimentacoes] = useState();

    const calculaTotalItens = (lista, gridlista) => {
      let itens = [];
      if (gridlista === 'prefiltro') {
        itens = lista
          .filter((item) => item.status !== GridView.EnumStatus.Remover)
          .map((item) => new PocPocoPrefiltro(item).totalPrefiltro);
        return itens
          .reduce((a, b) => a + b, 0)
          .toFixed(2)
          .replace('.', ',');
      }

      itens = lista
        .filter((item) => item.status !== GridView.EnumStatus.Remover)
        .map((item) => new PocPocoCimentacao(item).totalCimentacao);
      return itens
        .reduce((a, b) => a + b, 0)
        .toFixed(2)
        .replace('.', ',');
    };

    const onSearchPrefiltro = async (e) => {
      const { prefiltros } = await getPocTipoPrefiltroAutoComplete({
        noPocTipoPrefiltro: e,
      });
      return prefiltros;
    };

    const onSearchGranulometria = async (e) => {
      const { granulometrica } = await getGranulometricaAutoComplete({
        noPocGranulometria: e,
      });
      return granulometrica;
    };

    const onAdicionarPrefiltro = async () => {
      if (onInicioFimInvalido(dataPrefiltro.nrInicio, dataPrefiltro.nrFim))
        return;
      if (!onValidaCampoObrigatorioPrefiltro(dataPrefiltro)) return;

      const listaPrefiltro = gridViewPrefiltro.current.getDataSource();
      const listaCimentacao = gridViewCimentacao.current.getDataSource();

      const obj = new PocPocoPrefiltro({
        ...dataPrefiltro,
        nrSeqPocPoco: data.nrSeqPocPoco,
        status: dataPrefiltro.status ?? GridView.EnumStatus.Inserir,
      });

      listaPrefiltro.push(obj);

      setDataPrefiltro(
        new PocPocoPrefiltro({ nrInicio: dataPrefiltro.nrFim, nrFim: '' })
      );

      const listaOrdenada = { prefiltros: listaPrefiltro };
      const dataordened = await sortPocPocoPrefiltro(listaOrdenada);

      if (gridViewPrefiltro && gridViewPrefiltro.current)
        gridViewPrefiltro.current.setDataSource(dataordened.listaPrefiltro);

      setTotalPrefiltros(calculaTotalItens(listaPrefiltro, 'prefiltro'));

      data.prefiltros = listaPrefiltro;

      setData({
        ...data,
        prefiltros: listaPrefiltro,
        cimentacoes: listaCimentacao,
      });

      document.getElementById('inicioPrefiltro').focus();
    };

    const onAdicionarCimentacao = async () => {
      if (onInicioFimInvalido(dataCimentacao.nrInicio, dataCimentacao.nrFim))
        return;
      if (!onValidaCampoObrigatorioCimentacao(dataCimentacao)) return;

      const listaCimentacao = gridViewCimentacao.current.getDataSource();
      const listaPrefiltro = gridViewPrefiltro.current.getDataSource();

      const obj = new PocPocoCimentacao({
        ...dataCimentacao,
        nrSeqPocPoco: data.nrSeqPocPoco,
        status: dataCimentacao.status ?? GridView.EnumStatus.Inserir,
      });

      listaCimentacao.push(obj);

      setDataCimentacao(new PocPocoCimentacao({ nrInicio: '', nrFim: '' }));

      const listaOrdenada = { cimentacoes: listaCimentacao };
      const dataordened = await sortPocPocoCimentacao(listaOrdenada);

      if (gridViewCimentacao && gridViewCimentacao.current)
        gridViewCimentacao.current.setDataSource(dataordened.listaCimentacao);

      setTotalCimentacoes(calculaTotalItens(listaCimentacao, 'cimentacao'));

      data.cimentacoes = listaCimentacao;

      setData({
        ...data,
        cimentacoes: listaCimentacao,
        prefiltros: listaPrefiltro,
      });

      document.getElementById('inicioCimentacao').focus();
    };

    const onEditarPrefiltro = async (e, datasource) => {
      const dataPrefS = datasource.filter((pre) => pre !== e);
      const dataPref = datasource.filter((pre) => pre === e);

      const prefiltroItem = {
        nrSeqPocTipoPrefiltro: dataPref[0].nrSeqPocTipoPrefiltro,
        noPocTipoPrefiltro: dataPref[0].noPocTipoPrefiltro,
      };

      const granulometriaItem = {
        nrSeqPocGranulometria: dataPref[0].nrSeqPocGranulometria,
        noPocGranulometria: dataPref[0].noPocGranulometria,
      };

      setDataPrefiltro({
        ...dataPref[0],
        status: GridView.EnumStatus.Alterar,
        prefiltro: prefiltroItem,
        granulometria: granulometriaItem,
      });

      if (dataPrefS.length > 1) {
        const obj = { ...data, prefiltros: dataPrefS };
        const dataordened = await sortPocPocoPrefiltro(obj);

        if (gridViewPrefiltro && gridViewPrefiltro.current)
          gridViewPrefiltro.current.setDataSource(dataordened.listaPrefiltro);

        setTotalPrefiltros(calculaTotalItens(dataPrefS, 'prefiltro'));
      } else {
        if (gridViewPrefiltro && gridViewPrefiltro.current)
          gridViewPrefiltro.current.setDataSource(dataPrefS);

        setTotalPrefiltros(calculaTotalItens(dataPrefS, 'prefiltro'));
      }
    };

    const onEditarCimentacao = async (e, datasource) => {
      const dataCimentacaoS = datasource.filter(
        (perfuracao) => perfuracao !== e
      );
      const dataCi = datasource.filter((cimentacao) => cimentacao === e);

      const cimentacaoItem = {
        nrSeqPocCimentacao: dataCi[0].nrSeqPocCimentacao,
        noPocCimentacao: dataCi[0].noPocCimentacao,
      };

      setDataCimentacao({
        ...dataCi[0],
        status: GridView.EnumStatus.Alterar,
        cimentacao: cimentacaoItem,
      });

      if (dataCimentacaoS.length > 0) {
        if (gridViewCimentacao && gridViewCimentacao.current)
          gridViewCimentacao.current.setDataSource(dataCimentacaoS);

        setTotalCimentacoes(calculaTotalItens(dataCimentacaoS, 'cimentacao'));
      } else {
        if (gridViewCimentacao && gridViewCimentacao.current)
          gridViewCimentacao.current.setDataSource(dataCimentacaoS);

        setTotalCimentacoes(calculaTotalItens(dataCimentacaoS, 'cimentacao'));
      }
    };

    const onRemoverPrefiltro = async (e, datasource) => {
      const dataPrefiltroS = datasource.filter((prefiltro) => prefiltro !== e);

      setTotalPrefiltros(calculaTotalItens(dataPrefiltroS, 'prefiltro'));

      const obj = { ...e, status: GridView.EnumStatus.Remover };
      dataPrefiltroS.push(obj);
      if (gridViewPrefiltro && gridViewPrefiltro.current)
        gridViewPrefiltro.current.setDataSource(dataPrefiltroS);

      setData({
        ...data,
        prefiltros: dataPrefiltroS,
        cimentacoes: gridViewCimentacao.current.getDataSource(),
      });

      data.prefiltros = dataPrefiltroS;
    };

    const onRemoverCimentacao = async (e, datasource) => {
      const dataCimentacaoS = datasource.filter(
        (cimentacao) => cimentacao !== e
      );
      setTotalCimentacoes(calculaTotalItens(dataCimentacaoS, 'cimentacao'));

      const obj = { ...e, status: GridView.EnumStatus.Remover };
      dataCimentacaoS.push(obj);
      if (gridViewCimentacao && gridViewCimentacao.current)
        gridViewCimentacao.current.setDataSource(dataCimentacaoS);

      setData({
        ...data,
        cimentacoes: dataCimentacaoS,
        prefiltros: gridViewPrefiltro.current.getDataSource(),
      });

      data.cimentacoes = dataCimentacaoS;
    };

    const columnsPrefiltro = [
      {
        key: 'nrSeqPocoPrefiltro',
        type: GridView.ColumnTypes.Checkbox,
        visible: false,
      },
      {
        key: 'nrSeqPocTipoPrefiltro',
        title: 'NrSeqPocTipoPrefiltro',
        visible: false,
      },
      { key: 'nrSeqPocPoco', title: 'NrSeqPocPoco', visible: false },
      { key: 'nrInicio', title: 'Inicio', format: GridView.DataTypes.Decimal },
      { key: 'nrFim', title: 'Fim', format: GridView.DataTypes.Decimal },
      {
        key: 'nrSeqPocGranulometria',
        title: 'NrSeqPocGranulometria',
        visible: false,
      },
      { key: 'nrEaInt', title: 'EA Interno' },
      { key: 'nrEaExt', title: 'EA Externo' },
      { key: 'getPrefiltro', title: 'Prefiltro' },
      { key: 'noPocGranulometria', title: 'Granulometria' },
      { key: 'totalPrefiltro', title: 'Total' },
      {
        key: 'nrSeqPocoCimentacao',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onEditarPrefiltro(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },
      {
        key: 'nrSeqPocoCimentacao',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemoverPrefiltro(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    const columnsCimentacao = [
      {
        key: 'nrSeqPocoCimentacao',
        type: GridView.ColumnTypes.Checkbox,
        visible: false,
      },
      { key: 'nrSeqPocPoco', title: 'NrSeqPocPoco', visible: false },
      { key: 'nrInicio', title: 'Inicio', format: GridView.DataTypes.Decimal },
      { key: 'nrFim', title: 'Fim', format: GridView.DataTypes.Decimal },
      { key: 'nrEaInt', title: 'EA Interno' },
      { key: 'nrEaExt', title: 'EA Externo' },
      { key: 'totalCimentacao', title: 'Total' },
      {
        key: 'nrSeqPocoCimentacao',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onEditarCimentacao(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },
      {
        key: 'nrSeqPocoCimentacao',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemoverCimentacao(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    const load = useCallback(async () => {
      if (gridViewPrefiltro && gridViewPrefiltro.current)
        gridViewPrefiltro.current.setDataSource(data.prefiltros);

      setTotalPrefiltros(calculaTotalItens(data.prefiltros, 'prefiltro'));

      if (gridViewCimentacao && gridViewCimentacao.current)
        gridViewCimentacao.current.setDataSource(data.cimentacoes);

      setTotalCimentacoes(calculaTotalItens(data.cimentacoes, 'cimentacao'));
    }, []);

    useEffect(() => {
      (async function func() {
        await load();
      })();
    }, []);

    return (
      <div>
        <div className='row mb-3'>
          <div className='display-8'>6 - Anular</div>
        </div>
        <div className='row'>
          <div className='card'>
            <div className='card-body'>
              <div className='row'>
                <div className='bg text-black bg-gradient rounded-2 p-3'>
                  <div className='row mb-3'>
                    <div className='display-8'>Cimentação</div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <div className='card'>
                        <div className='card-body'>
                          <div className='row mb-3'>
                            <div className='col'>
                              <Textbox
                                id='inicioCimentacao'
                                label='Inicio (m)'
                                text={dataCimentacao.nrInicio}
                                maxLength={8}
                                mask={MaskTypes.DecimalCustom}
                                decimalPlaces={2}
                                onChangedValue={(nrInicio) =>
                                  setDataCimentacao({
                                    ...dataCimentacao,
                                    nrInicio,
                                  })
                                }
                              />
                            </div>
                            <div className='col'>
                              <Textbox
                                label='Fim (m)'
                                text={dataCimentacao.nrFim}
                                maxLength={8}
                                mask={MaskTypes.DecimalCustom}
                                decimalPlaces={2}
                                onChangedValue={(obj) =>
                                  setDataCimentacao({
                                    ...dataCimentacao,
                                    nrFim: obj,
                                    totalCimentacao:
                                      obj > dataCimentacao.nrInicio
                                        ? parseFloat(
                                            (
                                              obj - dataCimentacao.nrInicio
                                            ).toFixed(2)
                                          )
                                        : 0,
                                  })
                                }
                              />
                            </div>
                            <div className='col'>
                              <Textbox
                                label='EA Int.'
                                text={dataCimentacao.nrEaInt ?? ''}
                                maxLength={8}
                                onChangedValue={(nrEaInt) =>
                                  setDataCimentacao({
                                    ...dataCimentacao,
                                    nrEaInt,
                                  })
                                }
                              />
                            </div>
                            <div className='col'>
                              <Textbox
                                label='EA Ext.'
                                text={dataCimentacao.nrEaExt ?? ''}
                                maxLength={8}
                                onChangedValue={(nrEaExt) =>
                                  setDataCimentacao({
                                    ...dataCimentacao,
                                    nrEaExt,
                                  })
                                }
                              />
                            </div>

                            <div className='col-auto mt-3'>
                              <Button
                                theme={Theme.Primary}
                                template={Button.Templates.Button}
                                text='Adicionar'
                                onClick={onAdicionarCimentacao}
                              />
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col'>
                              <GridView
                                ref={gridViewCimentacao}
                                className='table-striped table-hover table-bordered table-sm'
                                dataColumns={columnsCimentacao}
                                showPagination={false}
                                showSelectSizes={false}
                              />
                            </div>
                            <div className='row mb-3'>
                              <div
                                className='col'
                                style={{
                                  textAlign: 'right',
                                  borderTop: '1px solid #0000002b',
                                  borderBottom: '1px solid #0000002b',
                                  paddingTop: '5px',
                                }}
                              >
                                <h6
                                  style={{
                                    padding: '2px',
                                    color: '#605959',
                                  }}
                                >
                                  Total: {totalCimentacoes}
                                </h6>
                              </div>
                            </div>
                            <div className='row mb-3'>
                              <div className='col'>
                                <Textarea
                                  maxLength={200}
                                  label='Observação'
                                  text={data.noPocPocoCimentacaoObs}
                                  onChangedValue={(noPocPocoCimentacaoObs) => {
                                    data.noPocPocoCimentacaoObs = noPocPocoCimentacaoObs;
                                    setData({
                                      ...data,
                                      noPocPocoCimentacaoObs,
                                      salvarEtapa: true,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='bg text-black bg-gradient rounded-2 p-3'>
                  <div className='row mb-3'>
                    <div className='display-8'>PreFiltro</div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <div className='card'>
                        <div className='card-body'>
                          <div className='row mb-3'>
                            <div className='col'>
                              <Textbox
                                id='inicioPrefiltro'
                                label='Inicio(m)'
                                text={dataPrefiltro.nrInicio}
                                maxLength={8}
                                mask={MaskTypes.DecimalCustom}
                                decimalPlaces={2}
                                onChangedValue={(nrInicio) =>
                                  setDataPrefiltro({
                                    ...dataPrefiltro,
                                    nrInicio,
                                  })
                                }
                              />
                            </div>
                            <div className='col'>
                              <Textbox
                                label='Fim(m)'
                                text={dataPrefiltro.nrFim}
                                maxLength={8}
                                mask={MaskTypes.DecimalCustom}
                                decimalPlaces={2}
                                onChangedValue={(obj) =>
                                  setDataPrefiltro({
                                    ...dataPrefiltro,
                                    nrFim: obj,
                                    totalPrefiltro:
                                      obj > dataPrefiltro.nrInicio
                                        ? parseFloat(
                                            (
                                              obj - dataPrefiltro.nrInicio
                                            ).toFixed(2)
                                          )
                                        : 0,
                                  })
                                }
                              />
                            </div>
                            <div className='col'>
                              <Textbox
                                label='EA Int.'
                                text={dataPrefiltro.nrEaInt ?? ''}
                                maxLength={8}
                                onChangedValue={(nrEaInt) =>
                                  setDataPrefiltro({
                                    ...dataPrefiltro,
                                    nrEaInt,
                                  })
                                }
                              />
                            </div>
                            <div className='col'>
                              <Textbox
                                label='EA Ext.'
                                text={dataPrefiltro.nrEaExt ?? ''}
                                maxLength={8}
                                onChangedValue={(nrEaExt) =>
                                  setDataPrefiltro({
                                    ...dataPrefiltro,
                                    nrEaExt,
                                  })
                                }
                              />
                            </div>
                            <div className='col-3'>
                              <Autocomplete
                                label='Prefiltro'
                                searchDataSource={onSearchPrefiltro}
                                selectedItem={dataPrefiltro.prefiltro}
                                onSelectItem={(prefiltro) =>
                                  setDataPrefiltro({
                                    ...dataPrefiltro,
                                    prefiltro,
                                    cdPocTipoPrefiltro:
                                      prefiltro.cdPocTipoPrefiltro,
                                    nrSeqPocTipoPrefiltro:
                                      prefiltro.nrSeqPocTipoPrefiltro,
                                    noPocTipoPrefiltro:
                                      prefiltro.noPocTipoPrefiltro,
                                  })
                                }
                                dataSourceTextProperty='noPocTipoPrefiltro'
                              />
                            </div>
                            <div className='col-3'>
                              <Autocomplete
                                label='Granulometria'
                                searchDataSource={onSearchGranulometria}
                                selectedItem={dataPrefiltro.granulometria}
                                onSelectItem={(granulometria) =>
                                  setDataPrefiltro({
                                    ...dataPrefiltro,
                                    granulometria,
                                    cdPocGranulometria:
                                      granulometria.cdPocGranulometria,
                                    nrSeqPocGranulometria:
                                      granulometria.nrSeqPocGranulometria,
                                    noPocGranulometria:
                                      granulometria.noPocGranulometria,
                                  })
                                }
                                dataSourceTextProperty='noPocGranulometria'
                              />
                            </div>
                            <div className='col-auto mt-3'>
                              <Button
                                theme={Theme.Primary}
                                template={Button.Templates.Button}
                                text='Adicionar'
                                onClick={onAdicionarPrefiltro}
                              />
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col'>
                              <GridView
                                ref={gridViewPrefiltro}
                                className='table-striped table-hover table-bordered table-sm'
                                dataColumns={columnsPrefiltro}
                                showPagination={false}
                                showSelectSizes={false}
                              />
                            </div>
                            <div className='row mb-3'>
                              <div
                                className='col'
                                style={{
                                  textAlign: 'right',
                                  borderTop: '1px solid #0000002b',
                                  borderBottom: '1px solid #0000002b',
                                  paddingTop: '5px',
                                }}
                              >
                                <h6
                                  style={{
                                    padding: '2px',
                                    color: '#605959',
                                  }}
                                >
                                  Total: {totalPrefiltro}
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col'>
                              <Textarea
                                maxLength={200}
                                label='Observação'
                                text={data.noPocPocoPrefiltroObs}
                                onChangedValue={(noPocPocoPrefiltroObs) => {
                                  data.noPocPocoPrefiltroObs = noPocPocoPrefiltroObs;
                                  setData({
                                    ...data,
                                    noPocPocoPrefiltroObs,
                                    salvarEtapa: true,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default CadastroCimentacaoPrefiltro;
