export default class Perfuratriz {
  constructor(jsonObject = {}) {
    this.nrSeqPocPerfuratriz = jsonObject.nrSeqPocPerfuratriz;
    this.cdPocPerfuratriz = jsonObject.cdPocPerfuratriz;
    this.noPocPerfuratriz = jsonObject.noPocPerfuratriz;
    this.flgAtivo = jsonObject.flgAtivo;
  }

  toJson = () => JSON.stringify(this);
}
