import React, { useEffect, useState } from 'react';
import { Modal } from 'ui/components';
import { BootstrapSizes } from 'ui/Helpers/utils';
import NotificacaoUsuario from './notificacoes';

export default function ModalUsuarioNotificacao({
  show,
  onClose,
  onCloseSetVisualizada,
  userNotifications,
}) {
  const [loading, setLoading] = useState(false);
  const [notificacoes, setNotificacoes] = useState([]);

  const loadGrid = async () => {
    setLoading(true);
    setNotificacoes(userNotifications);

    setLoading(false);
  };

  useEffect(() => {
    (async function load() {
      loadGrid();
    })();
  }, [show]);

  const setVisualizada = async (e) => {
    onCloseSetVisualizada(e);
  };

  return (
    <Modal
      show={show}
      loading={loading}
      title='Notificações'
      onClose={onClose}
      size={BootstrapSizes.Medium}
    >
      <Modal.Body className='p-4 pt-3'>
        {notificacoes.length > 0 && (
          <NotificacaoUsuario
            notificacoes={notificacoes}
            setVisualizada={setVisualizada}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
