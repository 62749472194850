import qs from 'qs';
import Pagination from 'core/models/pagination';
import ApoliceSeguro from 'core/models/FRO/apoliceSeguro';
import api from '../api';

const baseURL = `/FRO/ApoliceSeguro`;

export const getApoliceSeguroList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ApoliceSeguro(json)) ?? [],
  };
};

export const getApoliceSeguroAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ApoliceSeguro(json)) ?? [],
  };
};

export const getApoliceSeguroPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ApoliceSeguro(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getApoliceSeguro = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ApoliceSeguro(value) : {};
};

export const saveApoliceSeguro = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteApoliceSeguro = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printApoliceSeguro = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelApoliceSeguro = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllApoliceSeguro = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getApoliceSeguroSearchAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/SearchAutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ApoliceSeguro(json)) ?? [],
  };
};

export const gerateApoliceSeguro = async (data) => {
  const response = await api.post(`${baseURL}/GerateMassiveApolice`, data);
  return response.data ?? {};
};
