import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  MimeTypes,
  PageTypes,
  ResponseStatus,
  Theme,
  toBase64,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Textbox,
  Switch,
  Autocomplete,
  Textarea,
  Panel,
  FileUpload,
  GridView,
  Button,
} from 'ui/components';

import {
  getPocTipoOrcamento,
  savePocTipoOrcamento,
  deletePocTipoOrcamento,
} from 'core/services/POC/pocTipoOrcamento';

import { getTipoServicoAutoComplete } from 'core/services/GProj/tipoServico';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN';
import { getParametro } from 'core/services/SEG/parametro';
import { BootstrapSizes } from 'ui/Helpers/enums';

export default function PocTipoOrcamentoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const documentosGridView = useRef();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [isOrcEne, setIsOrcEne] = useState(false);
  const [file, setFile] = useState();
  const [flgFisica, setFlgFisica] = useState(true);

  useEffect(() => {
    const getParameter = async () => {
      const parametro = await getParametro('ORC_ENERGIA');

      if (parametro) {
        setIsOrcEne(parametro.noConteudo === 'S');
      }
    };

    getParameter();
  }, []);

  const onAddDocumeto = async () => {
    if (file.length > 0) {
      let noDocBase64 = await toBase64(file[0]);

      noDocBase64 = String(noDocBase64).substring(
        String(noDocBase64).indexOf(',') + 1
      );

      let nomeArquivo = file[0].name;

      if (nomeArquivo.length > 60) {
        const posicaoPonto = nomeArquivo.lastIndexOf('.');

        const nome = nomeArquivo.slice(0, posicaoPonto);
        const extensao = nomeArquivo.slice(posicaoPonto);

        const reducao = nome.length - 56;

        nomeArquivo = `${nome.slice(0, -reducao).trim()}${extensao}`;

        setMessage({
          message: 'O nome do arquivo foi ajustado porque era muito extenso.',
          theme: Theme.Warning,
        });
      }

      const doc = {
        noDocumento: nomeArquivo,
        documento: noDocBase64,
        flgFisica,
        status: GridView.EnumStatus.Inserir,
      };

      const list = documentosGridView.current.getDataSource();

      list.push(doc);

      if (documentosGridView && documentosGridView.current) {
        documentosGridView.current.setDataSource(list);
      }

      setFile(undefined);
    }
  };

  const onClickRemoveDocumento = async (e) => {
    const list = documentosGridView.current.getDataSource();

    list.filter((elemento) => elemento === e)[0].status =
      GridView.EnumStatus.Remover;

    if (documentosGridView && documentosGridView.current) {
      documentosGridView.current.setDataSource(list);
    }
  };

  const onNew = () => {
    setData({});

    if (documentosGridView && documentosGridView.current) {
      documentosGridView.current.setDataSource([]);
    }
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);

      const poctipoorcamento = await getPocTipoOrcamento(registryKey);

      setData(poctipoorcamento);

      if (documentosGridView && documentosGridView.current) {
        documentosGridView.current.setDataSource(poctipoorcamento.documentos);
      }

      setLoading(false);
    } else {
      onNew();
    }

    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, isOrcEne]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const save = async () => {
    setLoading(true);

    if (documentosGridView && documentosGridView.current) {
      data.documentos = documentosGridView.current.getDataSource();
    }

    const { status, message: msg } = await savePocTipoOrcamento(data);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      onNew();
    } else {
      setData(data);
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onSearchTituloEspecie = async (e) => {
    const {
      status,
      message: msg,
      data: tituloEspecies,
    } = await getTituloEspecieAutoComplete({ noEspecie: e, flgPrevisao: true });
    if (msg) onSetMessage(status, msg);
    return tituloEspecies;
  };

  const onSearchTipoServico = async (e) => {
    const {
      status,
      message: msg,
      data: tiposervicos,
    } = await getTipoServicoAutoComplete({ noTipoServico: e });
    if (msg) onSetMessage(status, msg);
    return tiposervicos;
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
    });
    if (msg);
    return formasPagamento;
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deletePocTipoOrcamento(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const documentosColumns = [
    { key: 'noDocumento', title: 'Documentos' },
    { key: 'flgFisica', title: 'Pessoa Física' },
    {
      key: 'nrSeqPocTipoOrcamentoDocumento',
      title: 'Remover',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickRemoveDocumento(e),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const { id: idSelecao } = PageTypes.Selection;

  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Tipo de Orçamento'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      onDelete={data?.nrSeqPocTipoOrcamento > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-4 mb-3'>
          <Textbox
            maxLength={100}
            label='Tipo Orcamento'
            text={data.noPocTipoOrcamento}
            onChangedValue={(noPocTipoOrcamento) =>
              setData({ ...data, noPocTipoOrcamento })
            }
          />
        </div>
        <div className='col-2'>
          <Switch
            formControl
            label='Gera Poço'
            checked={data.flgGeraPoco}
            onChange={(flgGeraPoco) => setData({ ...data, flgGeraPoco })}
          />
        </div>
        <div className='col-2'>
          <Switch
            formControl
            label='Manutenção'
            checked={data.flgManutencao}
            onChange={(flgManutencao) => setData({ ...data, flgManutencao })}
          />
        </div>
        <div className='col-2'>
          <Switch
            formControl
            label='Incluir Itens na Nota'
            checked={data.flgIncluirDescricaoItensNota}
            onChange={(flgIncluirDescricaoItensNota) =>
              setData({ ...data, flgIncluirDescricaoItensNota })
            }
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Tipo Servico'
            searchDataSource={onSearchTipoServico}
            selectedItem={data.tipoServico}
            onSelectItem={(tipoServico) => {
              setData({
                ...data,
                tipoServico,
                nrSeqTipoServico: tipoServico.nrSeqTipoServico,
              });
            }}
            dataSourceTextProperty='noTipoServico'
          />
        </div>
        <div className='col-4 mb-3'>
          <Textbox
            maxLength={100}
            label='Modelo Documento'
            text={data.noModeloDocumento}
            onChangedValue={(noModeloDocumento) =>
              setData({ ...data, noModeloDocumento })
            }
          />
        </div>
        <div className='col-4 mb-3'>
          <Autocomplete
            label='Tipo Receita'
            searchDataSource={onSearchTituloEspecie}
            selectedItem={data.tituloEspecie}
            onSelectItem={(tituloEspecie) => {
              setData({
                ...data,
                tituloEspecie,
                nrSeqTituloEspecie: tituloEspecie.nrSeqTituloEspecie,
              });
            }}
            dataSourceTextProperty='noEspecie'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Forma Pagamento'
            searchDataSource={onSearchFormaPagamento}
            selectedItem={data.formaPagamento}
            onSelectItem={(formaPagamento) => {
              setData({
                ...data,
                formaPagamento,
                nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
              });
            }}
            dataSourceTextProperty='noFormaPagamento'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-12'>
          <Textarea
            label='Observação'
            maxLength={5000}
            text={data.noObservacao}
            onChangedValue={(noObservacao) =>
              setData({ ...data, noObservacao })
            }
          />
        </div>
      </div>

      {isOrcEne && (
        <div className='row mb-3'>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header title='Contratos' theme={Theme.Primary} />
            <Panel.Body>
              <div className='row align-items-center'>
                <div className='col-5'>
                  <FileUpload
                    files={file}
                    onChange={(items) => {
                      setFile(items);
                    }}
                    allowedMimeTypes={[MimeTypes.Types.PDF]}
                  />
                </div>
                <div className='col-1'>
                  <Switch
                    formControl
                    label='Pessoa Física'
                    checked={flgFisica}
                    onChange={(flg) => setFlgFisica(flg)}
                  />
                </div>
                <div className='col-2'>
                  <Button
                    size={BootstrapSizes.Medium}
                    theme={Theme.Primary}
                    text='Adicionar Documento'
                    icon='plus'
                    onClick={onAddDocumeto}
                  />
                </div>
              </div>
              <div>
                <GridView
                  ref={documentosGridView}
                  dataColumns={documentosColumns}
                  showSelectSizes={false}
                  showPagination={false}
                />
              </div>
            </Panel.Body>
          </Panel>
        </div>
      )}
    </CSDManutPage>
  );
}
