import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Textbox,
  DatePicker,
  FontAwesomeIcon,
} from 'ui/components';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';

export default function ModalEditarParcela(props) {
  const { show, data, onClose, onConfirm } = props;
  const [parcela, setParcela] = useState({});

  useEffect(() => {
    if (show) {
      setParcela(data);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      icon='edit'
      title='Editar Parcela'
      size={BootstrapSizes.Large}
      onClose={() => onClose(data)}
    >
      <Modal.Body>
        <div className='row'>
          <div className='row mb-3'>
            <div className='col-3'>
              <Textbox label='Ordem' readOnly text={parcela.nrOrdem} />
            </div>
            <div className='col-3'>
              <DatePicker
                label='Dt. Vencimento'
                text={parcela.dtVencimento}
                onChange={(dtVencimento) => {
                  setParcela({ ...parcela, dtVencimento });
                }}
              />
            </div>
            <div className='col-6'>
              <Textbox
                label='Vlr. Parcela'
                text={parcela.vlrSaldoTit}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrSaldoTit) => {
                  setParcela({
                    ...parcela,
                    vlrSaldoTit,
                    vlrTitulo: vlrSaldoTit,
                  });
                }}
              />
            </div>
          </div>
          <div className='row mx-1'>
            <div className='alert alert-warning' role='alert'>
              <div className='row'>
                <div className='col fs-6'>
                  <FontAwesomeIcon icon='exclamation' className='me-1' />
                  <span className='ml-2 fw-bold'>
                    Ao salvar titulo não será mais possível editar as parcelas.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          icon='check'
          text='Confirmar'
          theme={Theme.Success}
          size={BootstrapSizes.Medium}
          onClick={() => onConfirm(parcela)}
        />
        <Button
          icon='ban'
          text='Cancelar'
          theme={Theme.Danger}
          size={BootstrapSizes.Medium}
          onClick={() => onClose(data)}
        />
      </Modal.Footer>
    </Modal>
  );
}
