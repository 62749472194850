export default class GrupoIssqn {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqGrupoIssqn = jsonObject.nrSeqGrupoIssqn;
    this.aliquota = jsonObject.aliquota;
    this.cdGrupoIssqn = jsonObject.cdGrupoIssqn;
    this.baseCalculo = jsonObject.baseCalculo;
    this.noGrupoIssqn = jsonObject.noGrupoIssqn;
  }

  toJson = () => JSON.stringify(this);
}
