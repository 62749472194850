import {
  AtualizarAbastecimentos,
  SalvarAbastecimentos,
} from 'core/services/FRO/abastecimento';
import { getPostoCombustivelAutoComplete } from 'core/services/FRO/postoCombustivel';
import { getPostoCombustivelBombaList } from 'core/services/FRO/postoCombustivelBomba';
import { getPostoCombustivelTanqueList } from 'core/services/FRO/postoCombustivelTanque';
import React, { useRef, useState, useEffect } from 'react';
import { setTransaction } from 'core/services/api';

import {
  Autocomplete,
  Button,
  GridView,
  Modal,
  Notification,
} from 'ui/components';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';

export default function ModalGeraAbastecimento(props, transaction) {
  const [messageState, setMessage] = useState(null);
  const [data, setData] = useState({});
  const gridView = useRef();

  const { show } = props;

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  useEffect(async () => {
    const value = await AtualizarAbastecimentos();
    if (value !== undefined) {
      if (value !== null) {
        if (gridView && gridView.current) gridView.current.setDataSource(value);

        if (value.length > 0) {
          setData({
            nrSeqPessoaPos: value[0].nrSeqPessoaPos,
            postoCombustivel: value[0].postoCombustivel,
            nrSeqPostoBomba: value[0].nrSeqPostoBomba,
            postoBomba: value[0].postoBomba,
            nrSeqPostoCombustivelTanque: value[0].nrSeqPostoCombustivelTanque,
            postoCombustivelTanque: value[0].postoCombustivelTanque,
          });
        }
      }
    }
  }, [show]);

  const salvar = async () => {
    const abastecimentos = gridView.current
      ? gridView.current.getDataSource()
      : [];
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);

    if (abastecimentos === undefined || abastecimentos === null) {
      setMessage({
        theme: Theme.Danger,
        message: 'Para Salvar o item, precisa ter um abastecimento',
      });
      return;
    }

    if (abastecimentos.length === 0) {
      setMessage({
        theme: Theme.Danger,
        message: 'Para Salvar o item, precisa ter um abastecimento',
      });
      return;
    }

    const obj = {
      ...data,
      AbastecimentoS: abastecimentos,
      nrSeqTransacao: transaction.nrSeqTransacao,
    };

    const { status, message: msg } = await SalvarAbastecimentos(obj);
    if (status === ResponseStatus.Success) {
      if (gridView && gridView.current) gridView.current.setDataSource(null);
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    if (status === ResponseStatus.Success) {
      props.onClose({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
  };

  const cancelar = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
    });
  };

  const onSearchPessoaPos = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaPoss,
    } = await getPostoCombustivelAutoComplete({
      noPessoa: e,
      flgAtivo: true,
      flgProprio: true,
    });
    if (msg) onSetMessage(status, msg);
    return pessoaPoss;
  };
  const onSearchTanqueCombustivel = async (e) => {
    const {
      status,
      message: msg,
      data: postoCombustivelTanque,
    } = await getPostoCombustivelTanqueList({
      noPostoCombustivelTanque: e,
      nrSeqPessoaPos: data.nrSeqPessoaPos,
      flgAtivo: true,
    });
    if (msg) setMessage(status, msg);
    return postoCombustivelTanque;
  };
  const onSearchPostoBomba = async (e) => {
    const {
      status,
      message: msg,
      data: postoCombustivelBomba,
    } = await getPostoCombustivelBombaList({
      cdBomba: e,
      nrSeqPostoCombustivelTanque: data.nrSeqPostoCombustivelTanque,
      flgAtivo: true,
    });
    if (msg) setMessage(status, msg);
    return postoCombustivelBomba;
  };

  const onRemoveAbastecimento = async (selectedValue, datasource) => {
    let documentos = [];

    documentos = datasource.filter((Documento) => Documento !== selectedValue);

    if (gridView && gridView.current)
      gridView.current.setDataSource(documentos);
  };

  const columns = [
    { key: 'nrDocumento', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'nrDocumento',
      title: 'Nr Documento',
    },
    {
      key: 'dtAbastecimento',
      title: 'Data Abastecimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'veiculo.placa',
      title: 'Veiculo',
    },
    {
      key: 'motorista.noPessoaMot',
      title: 'Motorista',
    },
    {
      key: 'vlrLitro',
      title: 'Valor Litro',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'qtdeLitro',
      title: 'Quantidade',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotal',
      title: 'Total',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'nrDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveAbastecimento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  /* const onRemoverAbastecimentos = async () => {
    const abastecimentos = gridView.current
      ? gridView.current.getDataSource()
      : [];

    const selecteds = gridView.current.getCheckBoxValuesAt(0);
    let exclusaoParcela = [];

    for (let i = 0; i < selecteds.length; ) {
      exclusaoParcela = abastecimentos.filter(
        (Abastecimento) => Abastecimento !== selecteds[i].value
      );

      if (gridView && gridView.current)
        gridView.current.setDataSource(exclusaoParcela);
      i += 1;
    }
  }; */

  return (
    <Modal
      show={show}
      title='Gerar Abastecimentos'
      size={BootstrapSizes.Large}
      icon='money'
    >
      <Modal.Body className='p-4 pt-3'>
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
        <div className='row'>
          <div className='col-4 mb-3'>
            <Autocomplete
              label='Posto'
              required
              searchDataSource={onSearchPessoaPos}
              selectedItem={data?.postoCombustivel}
              onSelectItem={(postoCombustivel) => {
                setData({
                  ...data,
                  postoCombustivel,
                  nrSeqPessoaPos: postoCombustivel.nrSeqPessoaPos,
                });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
          {data?.postoCombustivel?.flgProprio === true && (
            <div className='col-3 mb-3'>
              <Autocomplete
                label='Tanque'
                required
                searchDataSource={onSearchTanqueCombustivel}
                selectedItem={data?.postoCombustivelTanque}
                onSelectItem={(postoCombustivelTanque) => {
                  setData({
                    ...data,
                    postoCombustivelTanque,
                    nrSeqPostoCombustivelTanque:
                      postoCombustivelTanque.nrSeqPostoCombustivelTanque,
                  });
                }}
                dataSourceTextProperty='noPostoCombustivelTanque'
              />
            </div>
          )}
          {data?.postoCombustivel?.flgProprio === true && (
            <div className='col-3 mb-3'>
              <Autocomplete
                label='Bomba'
                required
                searchDataSource={onSearchPostoBomba}
                selectedItem={data?.postoBomba}
                onSelectItem={(postoBomba) => {
                  setData({
                    ...data,
                    postoBomba,
                    nrSeqPostoBomba: postoBomba.nrSeqPostoBomba,
                  });
                }}
                dataSourceTextProperty='cdBomba'
              />
            </div>
          )}
        </div>

        <div className='row'>
          <div className='col-4 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Gerar Abastecimentos'
              onClick={salvar}
            />
          </div>
          <div className='col-2 mt-3'>
            <Button
              theme={Theme.Danger}
              template={Button.Templates.Button}
              text='Sair'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
