import React from 'react';
import { mask, MaskTypes } from 'ui/Helpers/masks';

function RenderTextMask(props) {
  const { label, value, masktype } = props;
  return (
    <div className='col-2 mt-3'>
      <div className='fw-bold'>{label}</div>
      {mask(value ?? 0, masktype ?? MaskTypes.Currency)}
    </div>
  );
}

export default function PanelResumo(props) {
  const { convenioExtrato } = props;
  return (
    <div className='bg-primary text-white bg-gradient rounded-2 p-3'>
      <div className='row'>
        <RenderTextMask
          label='Total de Lançamentos'
          value={convenioExtrato?.vlrTotalLancamentos}
          masktype={MaskTypes.Integer}
        />
        <RenderTextMask
          label='Total do Valor Original'
          value={convenioExtrato?.vlrTotalOri}
        />
        <RenderTextMask
          label='Total Recalculado'
          value={convenioExtrato?.vlrTotalRecalculado}
        />
        <RenderTextMask
          label='Total Valor Resultado'
          value={convenioExtrato?.vlrTotalResultado}
        />
        <RenderTextMask
          label='Total Comissão'
          value={convenioExtrato?.vlrTotalComissao}
        />
      </div>
    </div>
  );
}
