import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  CSDManutPage,
  Panel,
  Textbox,
  Autocomplete,
  Switch,
  GridView,
  Button,
  ToolbarButtons,
} from 'ui/components';
import { PageTypes, BootstrapSizes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { Theme, ResponseStatus, StatusGrid } from 'ui/Helpers/enums.ts';
import {
  getGrupoIcms,
  saveGrupoIcms,
  deleteGrupoIcms,
} from 'core/services/FIS/grupoIcms';
import { getTributacaoIcmsAutoComplete } from 'core/services/FIS';
import {
  IGrupoIcms,
  IGrupoIcmsEstado,
  IGrupoIcmsMensagemEstado,
} from 'core/interfaces/FIS';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
import { getPaisAutoComplete } from 'core/services/SEG/pais';
import { getCfoMensagemAutoComplete } from 'core/services/FIS/cfoMensagem';
import { MaskTypes } from 'ui/Helpers/masks';
import ModalGerateMassivoEstado from './modalGerateMassvioEstado.tsx';

export default function GrupoIcms({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit = useRef<any>();
  const [modalShow, setModalShow] = useState({});
  const [data, setData] = useState<IGrupoIcms | any>({});
  const [dataGrupoIcmsEstado, setDataGrupoIcmsEstado] = useState<
    IGrupoIcmsEstado | any
  >({
    flgIcmsDesonerado: false,
    flgParcial: false,
    aliquotaIcms: 0,
    aliquotaIcmsDesonerado: 0,
    aliquotaFCP: 0,
    baseCalculoFCP: 0,
    baseCalculoIcms: 0,
    baseCalculoIcmsDesonerado: 0,
    nrSeqPaisOrigem: undefined,
    nrSeqPaisDestino: undefined,
  });
  const [
    dataGrupoIcmsMensagemEstado,
    setDataGrupoIcmsMensagemEstado,
  ] = useState<IGrupoIcmsMensagemEstado | any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [readOnly, setReadOnly] = useState(false);
  const [readOnlyMensagem, setReadOnlyMensagem] = useState(false);
  const gridViewGrupoIcmsEstado = useRef();
  const gridViewGrupoIcmsMensagemEstado = useRef();

  const onSetMessage = (status: ResponseStatus, msg: string = ''): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onNew = (): void => {
    setData({});
    setDataGrupoIcmsEstado({
      flgIcmsDesonerado: false,
      flgParcial: false,
      aliquotaIcms: 0,
      aliquotaIcmsDesonerado: 0,
      aliquotaFCP: 0,
      baseCalculoFCP: 0,
      baseCalculoIcms: 0,
      baseCalculoIcmsDesonerado: 0,
      nrSeqPaisOrigem: undefined,
      nrSeqPaisDestino: undefined,
    });
    setDataGrupoIcmsMensagemEstado({
      nrSeqPaisOrigem: undefined,
      nrSeqPaisDestino: undefined,
    });
    if (gridViewGrupoIcmsEstado && gridViewGrupoIcmsEstado.current)
      gridViewGrupoIcmsEstado.current.setDataSource([]);

    if (
      gridViewGrupoIcmsMensagemEstado &&
      gridViewGrupoIcmsMensagemEstado.current
    )
      gridViewGrupoIcmsMensagemEstado.current.setDataSource([]);
    setReadOnly(false);
    setReadOnlyMensagem(false);
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const retorno = await getGrupoIcms(registryKey);
      setData(retorno);
      if (gridViewGrupoIcmsEstado && gridViewGrupoIcmsEstado.current)
        gridViewGrupoIcmsEstado.current.setDataSource(
          retorno.grupoIcmsEstados ?? []
        );
      if (
        gridViewGrupoIcmsMensagemEstado &&
        gridViewGrupoIcmsMensagemEstado.current
      )
        gridViewGrupoIcmsMensagemEstado.current.setDataSource(
          retorno.grupoIcmsMensagemEstados ?? []
        );
      setLoading(false);
    } else {
      onNew();
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);

    if (
      data.grupoIcmsEstados !== undefined &&
      data.grupoIcmsEstados.length > 0
    ) {
      for (let i = 0; i < data.grupoIcmsEstados.length; i += 1) {
        const item = data.grupoIcmsEstados[i];

        if (
          item.status !== null &&
          item.status !== undefined &&
          item.status === 'Inserir'
        ) {
          item.estadoOrigem = undefined;
          item.estadoDestino = undefined;
          item.paisOrigem = undefined;
          item.paisDestino = undefined;
          item.tributacaoIcms = undefined;
        }
      }
    }

    const { status, message: msg } = await saveGrupoIcms(data);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();

      setData(status === ResponseStatus.Success ? {} : data);

      if (gridViewGrupoIcmsEstado && gridViewGrupoIcmsEstado.current)
        gridViewGrupoIcmsEstado.current.setDataSource([]);

      if (
        gridViewGrupoIcmsMensagemEstado &&
        gridViewGrupoIcmsMensagemEstado.current
      )
        gridViewGrupoIcmsMensagemEstado.current.setDataSource([]);
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteGrupoIcms(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchEstadoOrigem = async (e: any) => {
    const { estados: estadosColeta } = await getEstadoAutoComplete({
      noEstado: e,
      nrSeqPais: dataGrupoIcmsEstado.nrSeqPaisOrigem ?? null,
    });
    return estadosColeta;
  };
  const onSearchEstadoDestino = async (e: any) => {
    const { estados: estadosEntrega } = await getEstadoAutoComplete({
      noEstado: e,
      nrSeqPais: dataGrupoIcmsEstado.nrSeqPaisDestino ?? null,
    });
    return estadosEntrega;
  };

  const onSearchPaisOrigem = async (e: any) => {
    const { paises: paisesColeta } = await getPaisAutoComplete({
      noPais: e,
    });
    return paisesColeta;
  };

  const onSearchPaisDestino = async (e: any) => {
    const { paises: paisesDestino } = await getPaisAutoComplete({
      noPais: e,
    });
    return paisesDestino;
  };

  const onSearchTributacaoIcms = async (e: any) => {
    const { data: tributacaoIcms } = await getTributacaoIcmsAutoComplete({
      noTributacaoIcms: e,
    });
    return tributacaoIcms;
  };

  const onSearchEstadoMensagemOrigem = async (e: any) => {
    const { estados: estadosColeta } = await getEstadoAutoComplete({
      noEstado: e,
      nrSeqPais: dataGrupoIcmsMensagemEstado.nrSeqPaisOrigem ?? null,
    });
    return estadosColeta;
  };
  const onSearchEstadoMensagemDestino = async (e: any) => {
    const { estados: estadosEntrega } = await getEstadoAutoComplete({
      noEstado: e,
      nrSeqPais: dataGrupoIcmsMensagemEstado.nrSeqPaisDestino ?? null,
    });
    return estadosEntrega;
  };

  const onSearchPaisMensagemOrigem = async (e: any) => {
    const { paises: paisesDestino } = await getPaisAutoComplete({
      noPais: e,
    });
    return paisesDestino;
  };

  const onSearchPaisMensagemDestino = async (e: any) => {
    const { paises: paisesDestino } = await getPaisAutoComplete({
      noPais: e,
    });
    return paisesDestino;
  };

  const onSearchCfo = async (e: any) => {
    const { cfoMensagem } = await getCfoMensagemAutoComplete({
      noCfoMensagem: e,
    });
    return cfoMensagem;
  };

  const validateItemsGrupoIcmsEstado = (datasource) => {
    if (!datasource.nrSeqPaisOrigem && !datasource.paisOrigem?.nrSeqPais) {
      onSetMessage(
        ResponseStatus.Error,
        'Campo Obrigatório: País do Estado de Origem.'
      );
      return false;
    }

    if (!datasource.nrSeqEstadoOrigem) {
      onSetMessage(
        ResponseStatus.Error,
        'Campo Obrigatório: Estado de Origem.'
      );
      return false;
    }

    if (!datasource.nrSeqPaisDestino && !datasource.paisDestino?.nrSeqPais) {
      onSetMessage(
        ResponseStatus.Error,
        'Campo Obrigatório: País do Estado de Destino.'
      );
      return false;
    }

    if (!datasource.nrSeqEstadoDestino) {
      onSetMessage(
        ResponseStatus.Error,
        'Campo Obrigatório: Estado de Destino.'
      );
      return false;
    }

    if (datasource.aliquotaIcms < 0 || datasource.aliquotaIcms > 100) {
      onSetMessage(ResponseStatus.Error, 'Alíquota deve ser entre 0 e 100.');
      return false;
    }

    if (
      datasource.aliquotaIcms === null ||
      datasource.aliquotaIcms === undefined
    ) {
      onSetMessage(ResponseStatus.Error, 'Informar algum valor de Alíquota.');
      return false;
    }

    if (datasource.baseCalculoIcms < 0 || datasource.baseCalculoIcms > 100) {
      onSetMessage(
        ResponseStatus.Error,
        'O Base de Cálculo deve estar entre 0 e 100.'
      );
      return false;
    }

    if (
      datasource.baseCalculoIcms === null ||
      datasource.baseCalculoIcms === undefined
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Informar algum valor de Base de Cálculo.'
      );
      return false;
    }

    if (datasource.flgIcmsDesonerado) {
      if (
        datasource.aliquotaIcmsDesonerado < 0 ||
        datasource.aliquotaIcmsDesonerado > 100
      ) {
        onSetMessage(
          ResponseStatus.Error,
          'A Aliquota Desonerada deve estar entre 0 e 100.'
        );
        return false;
      }

      if (
        datasource.baseCalculoIcmsDesonerado < 0 ||
        datasource.baseCalculoIcmsDesonerado > 100
      ) {
        onSetMessage(
          ResponseStatus.Error,
          'O Base Calculo Desonerada deve estar entre 0 e 100.'
        );
        return false;
      }
    }

    if (
      datasource.baseCalculoIcmsDesonerado === null ||
      datasource.baseCalculoIcmsDesonerado === undefined
    ) {
      datasource.baseCalculoIcmsDesonerado = 0;
    }

    if (
      datasource.aliquotaIcmsDesonerado === null ||
      datasource.aliquotaIcmsDesonerado === undefined
    ) {
      datasource.aliquotaIcmsDesonerado = 0;
    }

    if (datasource.aliquotaFCP < 0 || datasource.aliquotaFCP > 100) {
      onSetMessage(
        ResponseStatus.Error,
        'Alíquota FCP deve ser entre 0 e 100.'
      );
      return false;
    }

    if (
      datasource.aliquotaFCP === null ||
      datasource.aliquotaFCP === undefined
    ) {
      datasource.aliquotaFCP = 0;
    }

    if (datasource.baseCalculoFCP < 0 || datasource.baseCalculoFCP > 100) {
      onSetMessage(
        ResponseStatus.Error,
        'Base de Cálculo FCP deve ser entre 0 e 100.'
      );
      return false;
    }

    if (
      datasource.baseCalculoFCP === null ||
      datasource.baseCalculoFCP === undefined
    ) {
      datasource.baseCalculoFCP = 0;
    }

    if (
      datasource.nrSeqTributacaoIcms === null ||
      datasource.nrSeqTributacaoIcms === undefined
    ) {
      onSetMessage(ResponseStatus.Error, 'Campo Obrigatório: Tributação ICMS.');
      return false;
    }

    if (datasource.status !== 'Alterar') {
      if (data.grupoIcmsEstados && data.grupoIcmsEstados.length > 0) {
        const hasDuplicateState = data.grupoIcmsEstados.some(
          (item) =>
            item.nrSeqEstadoOrigem === datasource.nrSeqEstadoOrigem &&
            item.nrSeqEstadoDestino === datasource.nrSeqEstadoDestino
        );

        if (hasDuplicateState) {
          onSetMessage(
            ResponseStatus.Error,
            'Os Estados de Origem e Destino já foram cadastrados.'
          );
          return false;
        }
      }
    }

    return true;
  };

  const onAdicionarGrupoIcmsEstado = async () => {
    setReadOnly(false);
    if (validateItemsGrupoIcmsEstado(dataGrupoIcmsEstado)) {
      const obj = {
        ...dataGrupoIcmsEstado,
        status: dataGrupoIcmsEstado.status ?? StatusGrid.Inserir,
      };

      const list = gridViewGrupoIcmsEstado.current.getDataSource();
      list.push(obj);

      if (gridViewGrupoIcmsEstado && gridViewGrupoIcmsEstado.current) {
        gridViewGrupoIcmsEstado.current.setDataSource(list);
      }

      setData({
        ...data,
        grupoIcmsEstados: list,
      });
      setDataGrupoIcmsEstado({
        aliquotaIcms: 0,
        aliquotaIcmsDesonerado: 0,
        aliquotaFCP: 0,
        baseCalculoFCP: 0,
        baseCalculoIcms: 0,
        baseCalculoIcmsDesonerado: 0,
        nrSeqPaisOrigem: undefined,
        nrSeqPaisDestino: undefined,
        flgParcial: false,
      });
    }
  };

  const onClickEditEstado = async (selectedValue, datasource) => {
    const dataGrupoIcmsEstados = datasource.find(
      (item) => item === selectedValue
    );
    if (dataGrupoIcmsEstados.status !== 'Inserir') {
      dataGrupoIcmsEstados.status = GridView.EnumStatus.Alterar;
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }

    const dataProdutos = datasource.filter(
      (produto) => produto !== selectedValue
    );

    setDataGrupoIcmsEstado(dataGrupoIcmsEstados);

    if (gridViewGrupoIcmsEstado && gridViewGrupoIcmsEstado.current)
      gridViewGrupoIcmsEstado.current.setDataSource(dataProdutos);
  };

  const onRemoveGrupoIcmsEstado = async (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (gridViewGrupoIcmsEstado && gridViewGrupoIcmsEstado.current)
      gridViewGrupoIcmsEstado.current.setDataSource(datasource);
  };

  const columnsGrupoIcmsEstado = [
    {
      key: 'nrSeqGrupoIcms',
      title: 'nrSeqGrupoIcms',
      visible: false,
    },
    {
      key: 'estadoOrigem.noEstado',
      title: 'Origem',
    },
    {
      key: 'estadoDestino.noEstado',
      title: 'Destino',
    },
    {
      key: 'tributacaoIcms.noTributacaoIcms',
      title: 'Tributação Icms',
    },
    {
      key: 'baseCalculoIcms',
      title: 'Base Cálculo ICMS',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'aliquotaIcms',
      title: 'Alíquota ICMS',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'baseCalculoIcmsDesonerado',
      title: 'Base Cálc. ICMS Des.',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'aliquotaIcmsDesonerado',
      title: 'Alíq. ICMS Des.',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'aliquotaFCP',
      title: 'Alíquota FCP.',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'baseCalculoFCP',
      title: 'Base Cálc. FCP.',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'noObservacoes',
      title: 'Observações',
    },
    { key: 'flgParcial', title: 'Parcial', format: GridView.DataTypes.Boolean },
    {
      key: 'flgIcmsDesonerado',
      title: 'flgIcmsDesonerado',
      visible: false,
    },
    {
      key: 'nrSeqGrupoIcms',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditEstado(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqGrupoIcms',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveGrupoIcmsEstado(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const validateItemsGrupoIcmsMensagemEstado = (datasource) => {
    if (!datasource.nrSeqPaisOrigem && !datasource.paisOrigem?.nrSeqPais) {
      onSetMessage(
        ResponseStatus.Error,
        'Campo Obrigatório de Mensagem: País do Estado de Origem.'
      );
      return false;
    }

    if (!datasource.nrSeqEstadoOrigem) {
      onSetMessage(
        ResponseStatus.Error,
        'Campo Obrigatório de Mensagem: Estado de Origem.'
      );
      return false;
    }
    if (!datasource.nrSeqPaisDestino && !datasource.paisDestino?.nrSeqPais) {
      onSetMessage(
        ResponseStatus.Error,
        'Campo Obrigatório de Mensagem: País do Estado de Destino.'
      );
      return false;
    }

    if (!datasource.nrSeqEstadoDestino) {
      onSetMessage(
        ResponseStatus.Error,
        'Campo Obrigatório de Mensagem: Estado de Destino.'
      );
      return false;
    }

    if (!datasource.nrSeqCfoMensagem) {
      onSetMessage(
        ResponseStatus.Error,
        'Campo Obrigatório de Mensagem: CFO Mensagem.'
      );
      return false;
    }

    if (datasource.status !== 'Alterar') {
      if (
        data.grupoIcmsMensagemEstados &&
        data.grupoIcmsMensagemEstados.length > 0
      ) {
        const hasDuplicateState = data.grupoIcmsMensagemEstados.some(
          (item) =>
            item.nrSeqEstadoOrigem === datasource.nrSeqEstadoOrigem &&
            item.nrSeqEstadoDestino === datasource.nrSeqEstadoDestino
        );

        if (hasDuplicateState) {
          onSetMessage(
            ResponseStatus.Error,
            'Os Estados de Origem e Destino já foram cadastrados.'
          );
          return false;
        }
      }
    }

    return true;
  };

  const verificarMensagemEstados = (mensagemEstado, grupoIcmsEstados) => {
    if (!grupoIcmsEstados || grupoIcmsEstados.length === 0) {
      return false;
    }

    return grupoIcmsEstados.some(
      (estado) =>
        estado.nrSeqEstadoOrigem === mensagemEstado.nrSeqEstadoOrigem &&
        estado.nrSeqEstadoDestino === mensagemEstado.nrSeqEstadoDestino
    );
  };

  const onAdicionarGrupoIcmsMensagemEstado = async () => {
    setReadOnlyMensagem(false);
    if (validateItemsGrupoIcmsMensagemEstado(dataGrupoIcmsMensagemEstado)) {
      const isMatchFound = verificarMensagemEstados(
        dataGrupoIcmsMensagemEstado,
        data.grupoIcmsEstados
      );

      if (!isMatchFound) {
        onSetMessage(
          ResponseStatus.Error,
          'Não existe esta relação entre estados no grupo icms.'
        );
        return false;
      }

      const obj = {
        ...dataGrupoIcmsMensagemEstado,
        status: dataGrupoIcmsMensagemEstado.status ?? StatusGrid.Inserir,
      };

      const list = gridViewGrupoIcmsMensagemEstado.current.getDataSource();
      list.push(obj);

      if (
        gridViewGrupoIcmsMensagemEstado &&
        gridViewGrupoIcmsMensagemEstado.current
      ) {
        gridViewGrupoIcmsMensagemEstado.current.setDataSource(list);
      }

      setData({
        ...data,
        grupoIcmsMensagemEstados: list,
      });
      setDataGrupoIcmsMensagemEstado({
        nrSeqPaisOrigem: undefined,
        nrSeqPaisDestino: undefined,
      });

      return true;
    }

    return false;
  };

  const onClickEditMensagemEstado = async (selectedValue, datasource) => {
    const dataGrupoIcmsMensagemEstados = datasource.find(
      (item) => item === selectedValue
    );
    if (dataGrupoIcmsMensagemEstados.status !== 'Inserir') {
      dataGrupoIcmsMensagemEstados.status = GridView.EnumStatus.Alterar;
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }

    const dataProdutos = datasource.filter(
      (produto) => produto !== selectedValue
    );

    setDataGrupoIcmsMensagemEstado(dataGrupoIcmsMensagemEstados);

    if (
      gridViewGrupoIcmsMensagemEstado &&
      gridViewGrupoIcmsMensagemEstado.current
    )
      gridViewGrupoIcmsMensagemEstado.current.setDataSource(dataProdutos);

    setReadOnlyMensagem(true);
  };

  const onRemoveGrupoIcmsMensagemEstado = async (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (
      gridViewGrupoIcmsMensagemEstado &&
      gridViewGrupoIcmsMensagemEstado.current
    )
      gridViewGrupoIcmsMensagemEstado.current.setDataSource(datasource);
  };

  const columnsGrupoIcmsMensagemEstado = [
    {
      key: 'nrSeqGrupoIcms',
      title: 'nrSeqGrupoIcms',
      visible: false,
    },
    {
      key: 'estadoOrigem.noEstado',
      title: 'Origem',
    },
    {
      key: 'estadoDestino.noEstado',
      title: 'Destino',
    },
    {
      key: 'cfoMensagem.noCfoMensagem',
      title: 'CFO Mensagem',
    },
    {
      key: 'nrSeqGrupoIcms',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditMensagemEstado(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqGrupoIcms',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) =>
        onRemoveGrupoIcmsMensagemEstado(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const gerateMassivo = async () => {
    setLoading(true);

    setModalShow({ ...modalShow, gerateMassivo: true });

    setLoading(false);
  };

  const onAdicionarTodosGrupoIcmsEstado = (datasource) => {
    setLoading(true);
    const list = gridViewGrupoIcmsEstado.current.getDataSource();

    datasource.grupoIcmsEstados.forEach((grupoIcms) => {
      const exists = list.some(
        (item) =>
          item.nrSeqEstadoOrigem === grupoIcms.nrSeqEstadoOrigem &&
          item.nrSeqEstadoDestino === grupoIcms.nrSeqEstadoDestino
      );

      if (!exists) {
        const obj = {
          ...grupoIcms,
          status: grupoIcms.status ?? StatusGrid.Inserir,
        };
        list.push(obj);
      }
    });

    setData({
      ...data,
      grupoIcmsEstados: list,
    });

    if (gridViewGrupoIcmsEstado && gridViewGrupoIcmsEstado.current) {
      gridViewGrupoIcmsEstado.current.setDataSource(list);
    }

    setLoading(false);
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Grupos de ICMS'
      loading={loading}
      transaction={transaction}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => {
        onNew();
      }}
      onSave={save}
      onDelete={data?.nrSeqGrupoIcms > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Gerar Massivo'
          icon={['far', 'file-alt']}
          onClick={() => gerateMassivo()}
        />
      </ToolbarButtons>
      <Panel>
        <Panel.Header title='Dados do Grupo' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-3 mb-3'>
              <Textbox
                maxLength={3}
                label='* Código'
                mask={MaskTypes.Integer}
                text={data.cdGrupoIcms}
                onChangedValue={(cdGrupoIcms: number) =>
                  setData({ ...data, cdGrupoIcms })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                maxLength={40}
                label='* Descrição'
                text={data.noGrupoIcms}
                onChangedValue={(noGrupoIcms: string) =>
                  setData({ ...data, noGrupoIcms })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <Panel>
        <Panel.Header title='Estado Origem' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-3'>
              <Autocomplete
                label='* País'
                searchDataSource={onSearchPaisOrigem}
                selectedItem={dataGrupoIcmsEstado.paisOrigem}
                onSelectItem={(paisOrigem: any) => {
                  setDataGrupoIcmsEstado({
                    ...dataGrupoIcmsEstado,
                    paisOrigem,
                    nrSeqPaisOrigem: paisOrigem.nrSeqPais,
                  });
                }}
                dataSourceTextProperty='noPais'
              />
            </div>
            <div className='col-3'>
              <Autocomplete
                label='* Estado Origem'
                searchDataSource={onSearchEstadoOrigem}
                readOnly={readOnly}
                selectedItem={dataGrupoIcmsEstado.estadoOrigem}
                onSelectItem={(estadoOrigem: any) =>
                  setDataGrupoIcmsEstado({
                    ...dataGrupoIcmsEstado,
                    estadoOrigem,
                    nrSeqEstadoOrigem: estadoOrigem.nrSeqEstado,
                  })
                }
                dataSourceTextProperty='noEstado'
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <Panel>
        <Panel.Header title='Estado Destino' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-3'>
              <Autocomplete
                label='* País'
                searchDataSource={onSearchPaisDestino}
                selectedItem={dataGrupoIcmsEstado.paisDestino}
                onSelectItem={(paisDestino: any) => {
                  setDataGrupoIcmsEstado({
                    ...dataGrupoIcmsEstado,
                    paisDestino,
                    nrSeqPaisDestino: paisDestino.nrSeqPais,
                  });
                }}
                dataSourceTextProperty='noPais'
              />
            </div>
            <div className='col-3'>
              <Autocomplete
                label='* Estado Destino'
                searchDataSource={onSearchEstadoDestino}
                readOnly={readOnly}
                selectedItem={dataGrupoIcmsEstado.estadoDestino}
                onSelectItem={(estadoDestino: any) =>
                  setDataGrupoIcmsEstado({
                    ...dataGrupoIcmsEstado,
                    estadoDestino,
                    nrSeqEstadoDestino: estadoDestino.nrSeqEstado,
                  })
                }
                dataSourceTextProperty='noEstado'
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='* Alíquota'
                maxLength={7}
                decimalPlaces={2}
                mask={MaskTypes.DecimalCustom}
                text={dataGrupoIcmsEstado.aliquotaIcms}
                onChangedValue={(aliquotaIcms: string) => {
                  setDataGrupoIcmsEstado({
                    ...dataGrupoIcmsEstado,
                    aliquotaIcms,
                  });
                }}
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='* Base Cálculo'
                maxLength={7}
                decimalPlaces={2}
                mask={MaskTypes.DecimalCustom}
                text={dataGrupoIcmsEstado.baseCalculoIcms}
                onChangedValue={(baseCalculoIcms: string) => {
                  setDataGrupoIcmsEstado({
                    ...dataGrupoIcmsEstado,
                    baseCalculoIcms,
                  });
                }}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={dataGrupoIcmsEstado?.flgIcmsDesonerado}
                label='* ICMS Desonerado'
                onChange={(flgIcmsDesonerado: boolean) =>
                  setDataGrupoIcmsEstado({
                    ...dataGrupoIcmsEstado,
                    flgIcmsDesonerado,
                  })
                }
              />
            </div>
            {dataGrupoIcmsEstado?.flgIcmsDesonerado && (
              <div className='col-2 mb-3'>
                <Textbox
                  label='* Alíquota Desonerado'
                  maxLength={7}
                  decimalPlaces={2}
                  mask={MaskTypes.DecimalCustom}
                  text={dataGrupoIcmsEstado.aliquotaIcmsDesonerado}
                  onChangedValue={(aliquotaIcmsDesonerado: string) => {
                    setDataGrupoIcmsEstado({
                      ...dataGrupoIcmsEstado,
                      aliquotaIcmsDesonerado,
                    });
                  }}
                />
              </div>
            )}
            {dataGrupoIcmsEstado?.flgIcmsDesonerado && (
              <div className='col-2 mb-3'>
                <Textbox
                  label='* Base Cálculo Desonerado'
                  maxLength={7}
                  decimalPlaces={2}
                  mask={MaskTypes.DecimalCustom}
                  text={dataGrupoIcmsEstado.baseCalculoIcmsDesonerado}
                  onChangedValue={(baseCalculoIcmsDesonerado: string) => {
                    setDataGrupoIcmsEstado({
                      ...dataGrupoIcmsEstado,
                      baseCalculoIcmsDesonerado,
                    });
                  }}
                />
              </div>
            )}
            <div className='col-2 mb-3'>
              <Textbox
                label='* Alíquota FCP'
                maxLength={7}
                decimalPlaces={2}
                mask={MaskTypes.DecimalCustom}
                text={dataGrupoIcmsEstado.aliquotaFCP}
                onChangedValue={(aliquotaFCP: string) => {
                  setDataGrupoIcmsEstado({
                    ...dataGrupoIcmsEstado,
                    aliquotaFCP,
                  });
                }}
              />
            </div>
            <div className='col-2 mb-3'>
              <Textbox
                label='* Base Cálculo FCP'
                maxLength={7}
                decimalPlaces={2}
                mask={MaskTypes.DecimalCustom}
                text={dataGrupoIcmsEstado.baseCalculoFCP}
                onChangedValue={(baseCalculoFCP: string) => {
                  setDataGrupoIcmsEstado({
                    ...dataGrupoIcmsEstado,
                    baseCalculoFCP,
                  });
                }}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-5'>
              <Autocomplete
                label='* Tributação'
                searchDataSource={onSearchTributacaoIcms}
                selectedItem={dataGrupoIcmsEstado.tributacaoIcms}
                onSelectItem={(tributacaoIcms: any) => {
                  setDataGrupoIcmsEstado({
                    ...dataGrupoIcmsEstado,
                    tributacaoIcms,
                    nrSeqTributacaoIcms: tributacaoIcms.nrSeqTributacaoIcms,
                  });
                }}
                dataSourceTextProperty='noTributacaoIcms'
              />
            </div>
            <div className='col-1'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={dataGrupoIcmsEstado?.flgParcial}
                label='* Parcial'
                onChange={(flgParcial: boolean) =>
                  setDataGrupoIcmsEstado({
                    ...dataGrupoIcmsEstado,
                    flgParcial,
                  })
                }
              />
            </div>
            <div className='col-6 mb-3'>
              <Textbox
                maxLength={200}
                label='* Observações'
                text={dataGrupoIcmsEstado.noObservacoes}
                onChangedValue={(noObservacoes: string) =>
                  setDataGrupoIcmsEstado({
                    ...dataGrupoIcmsEstado,
                    noObservacoes,
                  })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <Button
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Success}
                template={Button.Templates.Quick}
                onClick={onAdicionarGrupoIcmsEstado}
                text='Adicionar'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <GridView
                ref={gridViewGrupoIcmsEstado}
                className='table-striped table-hover table-bordered table-sm'
                gridSizeList={[1000]}
                dataColumns={columnsGrupoIcmsEstado}
                showPagination={false}
                showSelectSizes={false}
                offlineData
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <Panel>
        <Panel.Header title='Mensagem' theme={Theme.Primary} />
        <Panel.Body>
          <Panel>
            <Panel.Header title='Estado Origem' theme={Theme.Primary} />
            <Panel.Body>
              <div className='row'>
                <div className='col-3'>
                  <Autocomplete
                    label='País Origem'
                    searchDataSource={onSearchPaisMensagemOrigem}
                    selectedItem={dataGrupoIcmsMensagemEstado.paisOrigem}
                    onSelectItem={(paisOrigem: any) => {
                      setDataGrupoIcmsMensagemEstado({
                        ...dataGrupoIcmsMensagemEstado,
                        paisOrigem,
                        nrSeqPaisOrigem: paisOrigem.nrSeqPais,
                      });
                    }}
                    dataSourceTextProperty='noPais'
                  />
                </div>
                <div className='col-3'>
                  <Autocomplete
                    label='Estado Origem'
                    searchDataSource={onSearchEstadoMensagemOrigem}
                    readOnly={readOnlyMensagem}
                    selectedItem={dataGrupoIcmsMensagemEstado.estadoOrigem}
                    onSelectItem={(estadoOrigem: any) =>
                      setDataGrupoIcmsMensagemEstado({
                        ...dataGrupoIcmsMensagemEstado,
                        estadoOrigem,
                        nrSeqEstadoOrigem: estadoOrigem.nrSeqEstado,
                      })
                    }
                    dataSourceTextProperty='noEstado'
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
          <Panel>
            <Panel.Header title='Estado Destino' theme={Theme.Primary} />
            <Panel.Body>
              <div className='row'>
                <div className='col-3'>
                  <Autocomplete
                    label='País Destino'
                    searchDataSource={onSearchPaisMensagemDestino}
                    selectedItem={dataGrupoIcmsMensagemEstado.paisDestino}
                    onSelectItem={(paisDestino: any) => {
                      setDataGrupoIcmsMensagemEstado({
                        ...dataGrupoIcmsMensagemEstado,
                        paisDestino,
                        nrSeqPaisDestino: paisDestino.nrSeqPais,
                      });
                    }}
                    dataSourceTextProperty='noPais'
                  />
                </div>
                <div className='col-3'>
                  <Autocomplete
                    label='Estado Destino'
                    searchDataSource={onSearchEstadoMensagemDestino}
                    readOnly={readOnlyMensagem}
                    selectedItem={dataGrupoIcmsMensagemEstado.estadoDestino}
                    onSelectItem={(estadoDestino: any) =>
                      setDataGrupoIcmsMensagemEstado({
                        ...dataGrupoIcmsMensagemEstado,
                        estadoDestino,
                        nrSeqEstadoDestino: estadoDestino.nrSeqEstado,
                      })
                    }
                    dataSourceTextProperty='noEstado'
                  />
                </div>
                <div className='col mb-3'>
                  <Autocomplete
                    label='CFO Mensagem'
                    searchDataSource={onSearchCfo}
                    selectedItem={dataGrupoIcmsMensagemEstado.cfoMensagem}
                    onSelectItem={(cfoMensagem: any) => {
                      setDataGrupoIcmsMensagemEstado({
                        ...dataGrupoIcmsMensagemEstado,
                        cfoMensagem,
                        nrSeqCfoMensagem: cfoMensagem.nrSeqCfoMensagem,
                      });
                    }}
                    dataSourceTextProperty='noCfoMensagem'
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Button
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Success}
                    template={Button.Templates.Quick}
                    onClick={onAdicionarGrupoIcmsMensagemEstado}
                    text='Adicionar'
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>

          {/* GridView */}
          <div className='row'>
            <div className='col-12'>
              <GridView
                ref={gridViewGrupoIcmsMensagemEstado}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columnsGrupoIcmsMensagemEstado}
                showPagination={false}
                showSelectSizes={false}
                offlineData
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div className='row'>
        <div className='col-4'>
          <ModalGerateMassivoEstado
            show={modalShow.gerateMassivo}
            dataIcms={data}
            onClose={(dataIcms) => {
              setModalShow({ ...modalShow, gerateMassivo: false });
              onAdicionarTodosGrupoIcmsEstado(dataIcms);
            }}
            onCloseModal={() => {
              setModalShow({ ...modalShow, gerateMassivo: false });
            }}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
