export default class ImportacaoCampo {
  constructor(jsonObject = {}) {
    this.nrSeqImportacaoCampo = jsonObject.nrSeqImportacaoCampo;
    this.nrSeqImportacao = jsonObject.nrSeqImportacao;
    this.noCampo = jsonObject.noCampo;
    this.nrOrdem = jsonObject.nrOrdem;
    this.noTabelaEstrangeira = jsonObject.noTabelaEstrangeira;
    this.noTabelaEstrangeiraCampo = jsonObject.noTabelaEstrangeiraCampo;
    this.noTabelaEstrangeiraChave = jsonObject.noTabelaEstrangeiraChave;
    this.noTipo = jsonObject.noTipo;
    this.noValorPadrao = jsonObject.noValorPadrao;
    this.flgChavePrimar = jsonObject.flgChavePrimar;
    this.flgSequencia = jsonObject.flgSequencia;
    this.flgIgnorarSeVazio = jsonObject.flgIgnorarSeVazio;
    this.flgUnico = jsonObject.flgUnico;
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
