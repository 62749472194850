import React, { useState } from 'react';
import {
  Modal,
  Switch,
  Panel,
  Textbox,
  Button,
  Autocomplete,
  Notification,
  Loader,
} from 'ui/components';
import {
  BootstrapSizes,
  Theme,
  JustifyContent,
  ResponseStatus,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';

import { getCfoAutoComplete } from 'core/services/FIS/cfo';
import {
  enviaComplemento,
  calcularEmbarqueTributacao,
} from 'core/services/FRO/coletaEmbarque';

import ColetaEmbarque from 'core/models/FRO/coletaEmbarque';

export default function ModalGeraComplemento({ show, onClose, cte }) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [data, setData] = useState({
    flgComplemento: true,
  });

  const onSetMessage = (status, msg) => {
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
  };

  const onSend = () => {
    onClose();
  };

  const onEnviaComplemento = async () => {
    if (!data.vlrFrete) {
      onSetMessage(1, 'Necessário preencher o campo valor frete.');

      return;
    }

    setLoading(true);

    const obj = new ColetaEmbarque({
      ...data,
      nrSeqColetaEmbarque: cte.nrSeqColetaEmbarque,
    });

    const { status, message: msg } = await enviaComplemento(obj);

    if (status === ResponseStatus.Success) {
      setData({ flgComplemento: true });

      onClose(status, msg);
    } else if (msg) {
      onSetMessage(status, msg);
    }

    setLoading(false);
  };

  const onCalcularEmbarqueTributacao = async () => {
    setLoading(true);

    if (data.flgCalculaTributos) {
      const {
        status,
        message: msg,
        data: embarqueCalculado,
      } = await calcularEmbarqueTributacao({
        nrSeqColetaEmbarque: cte.nrSeqColetaEmbarque,
        vlrFrete: data.vlrFrete,
      });

      setData({
        ...data,
        vlrIcms: embarqueCalculado.vlrIcms,
        vlrBaseCalIcms: embarqueCalculado.vlrBaseCalIcms,
        aliqIcms: embarqueCalculado.aliqIcms,
      });

      if (msg) onSetMessage(status, msg);
    }

    setLoading(false);
  };

  const onSearchCfo = async (e) => {
    const { status, message: msg, cfos } = await getCfoAutoComplete({
      noCfo: e,
    });

    if (msg) onSetMessage(status, msg);

    return cfos;
  };

  return (
    <Modal
      show={show}
      title='Gerar Complemento ou Estorno'
      onClose={() => onSend()}
      size={BootstrapSizes.Large}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col-3'>
            <Switch
              formControl
              label='Complemento'
              checked={data?.flgComplemento}
              onChange={(flgComplemento) =>
                setData({
                  ...data,
                  flgComplemento,
                })
              }
            />
          </div>
        </div>
        <Panel>
          <Panel.Header
            title={data?.flgComplemento ? 'Complemento' : 'Estorno'}
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            {data.flgComplemento && (
              <div className='row'>
                <div className='row mb-3'>
                  <div className='col-4'>
                    <Switch
                      formControl
                      label='Calcula Tributos Automático'
                      checked={data.flgCalculaTributos}
                      onChange={(flgCalculaTributos) =>
                        setData({ ...data, flgCalculaTributos })
                      }
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-3'>
                    <Textbox
                      label='Valor Frete:'
                      text={data.vlrFrete}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(vlrFrete) =>
                        setData({ ...data, vlrFrete })
                      }
                      onBlur={onCalcularEmbarqueTributacao}
                    />
                  </div>
                  <div className='col-3'>
                    <Textbox
                      label='Base ICMS:'
                      readOnly={data.flgCalculaTributos}
                      text={data.vlrBaseCalIcms}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(vlrBaseCalIcms) =>
                        setData({ ...data, vlrBaseCalIcms })
                      }
                    />
                  </div>
                  <div className='col-3'>
                    <Textbox
                      label='Aliq. ICMS:'
                      readOnly={data.flgCalculaTributos}
                      text={data.aliqIcms}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(aliqIcms) =>
                        setData({ ...data, aliqIcms })
                      }
                    />
                  </div>
                  <div className='col-3'>
                    <Textbox
                      label='Valor ICMS:'
                      readOnly={data.flgCalculaTributos}
                      text={data.vlrIcms}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(vlrIcms) =>
                        setData({ ...data, vlrIcms })
                      }
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <Textbox
                      label='Observação'
                      text={data.observacao}
                      onChangedValue={(observacao) =>
                        setData({ ...data, observacao })
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            {!data.flgComplemento && (
              <div className='row mb-3'>
                <div className='col'>
                  <Autocomplete
                    label='CFO'
                    searchDataSource={onSearchCfo}
                    selectedItem={data.cfo}
                    onSelectItem={(cfo) =>
                      setData({ ...data, cfo, nrSeqCfo: cfo.nrSeqCfo })
                    }
                    dataSourceTextProperty='noCfo'
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Observação'
                    text={data.observacao}
                    onChangedValue={(observacao) =>
                      setData({ ...data, observacao })
                    }
                  />
                </div>
              </div>
            )}
          </Panel.Body>
        </Panel>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text={data?.flgComplemento ? 'Enviar Complemento' : 'Enviar Estorno'}
          icon='paper-plane'
          theme={Theme.Success}
          template={Button.Templates.Default}
          size={BootstrapSizes.Medium}
          onClick={onEnviaComplemento}
        />
        <Button
          text='Sair'
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          size={BootstrapSizes.Medium}
          onClick={onSend}
        />
      </Modal.Footer>
    </Modal>
  );
}
