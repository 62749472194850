import React, { useRef, useState, useEffect } from 'react';

import {
  Autocomplete,
  Button,
  Confirm,
  DatePicker,
  GridView,
  Modal,
  Notification,
  Panel,
  Textbox,
} from 'ui/components';
import {
  Theme,
  BootstrapSizes,
  ResponseStatus,
  JustifyContent,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getFormaPagamentoAutoComplete,
  getTipoDespesaList,
} from 'core/services/FIN';
import { AgrupamentoTituloPagar } from 'core/services/FIN/tituloPagar';
import { TipoDespesa } from 'core/models/FIN';
import {
  getRetornoGridPlanoConta,
  getTipoDespesaPlanoDeContaTituloPagar,
} from 'core/services/FIN/tipoDespesa_PlanoDeConta';
import Loader from 'ui/components/Loader';

export default function ModalAgrupamentoTitulo(props) {
  const [data, setData] = useState();
  const [somados, setSomados] = useState({
    flgDeixaSalvar: true,
    vlrSomado: 0,
  });
  const [loading, setLoading] = useState(false);
  const [messageState, setMessage] = useState(null);
  const [showConfirmaAlteracao, setShowConfirmaAlteracao] = useState(false);
  const gridView = useRef();
  const gridView2 = useRef();
  const gridView3 = useRef();

  const { show, agrupamentoTituloValor } = props;

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  useEffect(() => {
    if (agrupamentoTituloValor !== undefined) {
      let sum = 0;
      let soma = 0;
      for (let i = 0; i < agrupamentoTituloValor.length; ) {
        sum += agrupamentoTituloValor[i].vlrTitulo;
        soma += agrupamentoTituloValor[i].vlrSaldoTit;
        i += 1;
      }

      if (gridView3 && gridView3.current)
        gridView3.current.setDataSource(
          agrupamentoTituloValor[0].tipoDespesaPlanoDeContaS
        );

      setData({
        ...data,
        vlrSaldo: sum,
        vlrSaldoTit: soma,
        tipoDespesa: agrupamentoTituloValor[0].tipoDespesa,
        nrSeqTipoDespesa: agrupamentoTituloValor[0].nrSeqTipoDespesa,
      });
      setSomados({ flgDeixaSalvar: true, vlrSomado: 0 });
    }
    gridView2?.current?.setDataSource(agrupamentoTituloValor);
  }, [show]);

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({ noFormaPagamento: e });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const Recalcula = async (tipoDespesa) => {
    if (tipoDespesa !== undefined) {
      data.tipoDespesa = tipoDespesa;
    }
    if (data.tipoDespesa === undefined) {
      data.tipoDespesa = new TipoDespesa();
    }
    if (data.tipoDespesa.nrSeqTipoDespesa > 0) {
      const { data: planoconta } = await getTipoDespesaPlanoDeContaTituloPagar({
        nrSeqTipoDespesa: data.tipoDespesa.nrSeqTipoDespesa,
      });
      for (let i = 0; i < planoconta.length; ) {
        planoconta[i].vlrTitulo = data.vlrSaldo;
        i += 1;
      }
      if (planoconta.length > 0) {
        if (data.tipoDespesa.planoDeContas.length === 0) {
          data.tipoDespesa.planoDeContas.push(...planoconta);
        } else {
          for (let i = 0; i < data.tipoDespesa.planoDeContas.length; ) {
            data.tipoDespesa.planoDeContas[i] = planoconta.vlrPlanoConta;
            i += 1;
          }
        }
        const obj = await getRetornoGridPlanoConta(planoconta);
        if (gridView3 && gridView3.current)
          gridView3.current.setDataSource(obj);
      } else if (gridView3 && gridView3.current)
        gridView3.current.setDataSource(null);
    } else if (gridView3 && gridView3.current)
      gridView3.current.setDataSource(null);
  };

  const onAdicionarTituloAgrupado = async () => {
    if (data.nrTit === null || data.nrTit === undefined) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar um agrupamento, sem adicionar o Nr Documento'
      );
      return;
    }

    if (
      data.nrSeqFormaPagamento === null ||
      data.nrSeqFormaPagamento === undefined ||
      data.nrSeqFormaPagamento === 0
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar um agrupamento, sem adicionar a Forma de Pagamento'
      );
      return;
    }

    if (data.dtVencimento === null || data.dtVencimento === undefined) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar um agrupamento, sem adicionar a Dt Vencimento'
      );
      return;
    }

    if (
      data.vlrTitulo === null ||
      data.vlrTitulo === undefined ||
      data.vlrTitulo === 0
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar um agrupamento, sem adicionar o Valor do titulo'
      );
      return;
    }

    const lista = gridView.current.getDataSource();
    const listaValidacao = [];
    lista.forEach((elem) => listaValidacao.push(elem));
    listaValidacao.push(data);
    if (data.status !== GridView.EnumStatus.Alterar) {
      data.status = GridView.EnumStatus.Inserir;
    }

    lista.push(data);

    if (lista !== undefined) {
      let sum = 0;
      for (let i = 0; i < lista.length; ) {
        sum += lista[i].vlrTitulo;
        i += 1;
      }
      somados.vlrSomado = sum;
      // setSomados({ ...somados, vlrSomado: sum });
      // Recalcula(data.tipoDespesa);
    }

    if (gridView && gridView.current) gridView.current.setDataSource(lista);
    setData({
      vlrTitulo: 0,
      nrSeqTipoDespesa: data.nrSeqTipoDespesa,
      tipoDespesa: data.tipoDespesa,
      vlrSaldo: data.vlrSaldo,
    });
  };

  const cancelar = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
    });
  };

  const salvar = async (flgConfirmaAlteracao = false) => {
    setLoading(true);
    somados.flgDeixaSalvar = false;

    const lista = gridView.current.getDataSource();
    const titulosagrupados = gridView2.current.getDataSource();
    const despesa = gridView3.current ? gridView3.current.getDataSource() : [];

    const obj = {
      tituloPagarAgrupado: titulosagrupados,
      titulosAgrupados: lista,
      tituloPagarPlanoDeConta: despesa,
      nrSeqTipoDespesa: data.nrSeqTipoDespesa,
      vlrTotal: data.vlrSaldo,
      flgConfirmaAlteracao,
    };

    if (obj.tituloPagarPlanoDeConta !== null) {
      for (let i = 0; i < obj.tituloPagarPlanoDeConta.length; ) {
        obj.tituloPagarPlanoDeConta[i].vlrTitulo =
          obj.tituloPagarPlanoDeConta[i].vlrPlanoConta;
        i += 1;
      }
    }

    const { status, message: msg } = await AgrupamentoTituloPagar(obj);

    if (msg === 'ConfirmaAlteracao') {
      setShowConfirmaAlteracao(true);
    } else {
      setShowConfirmaAlteracao(false);
    }

    if (status === ResponseStatus.Success) {
      if (gridView && gridView.current) gridView.current.setDataSource(null);

      if (gridView2 && gridView2.current) gridView2.current.setDataSource(null);
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    if (status === ResponseStatus.Success) {
      props.onClose({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    } else {
      somados.flgDeixaSalvar = true;
    }
    setLoading(false);
  };

  const onClickRemoverTituloAgrupado = async (selectedValue, datasource) => {
    let titulos = [];
    if (selectedValue.nrSeqTituloPagarFatura === undefined) {
      titulos = datasource.filter((Titulo) => Titulo !== selectedValue);
    } else {
      titulos = datasource.filter((Titulo) => Titulo !== selectedValue);
      const dataTitulo = datasource.filter(
        (Titulo) => Titulo === selectedValue
      );
      dataTitulo[0].status = GridView.EnumStatus.Remover;
      titulos.push(dataTitulo[0]);
    }
    if (gridView && gridView.current) gridView.current.setDataSource(titulos);
  };

  const columns = [
    {
      key: 'nrTit',
      title: 'Nr Documento',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'dtVencimento',
      title: 'Data Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrSeqTituloPagarFatura',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoverTituloAgrupado(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];
  const columns2 = [
    {
      key: 'nrTit',
      title: 'Nr Tit',
    },
    {
      key: 'nrOrdem',
      title: 'Nr Ordem',
    },
    {
      key: 'fornecedor.noFornecedor',
      title: 'Fornecedor',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor Do Titulo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrPago',
      title: 'Valor Pago',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrSaldoTit',
      title: 'Valor Saldo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'dtVencimento',
      title: 'Data Vencimento',
      format: GridView.DataTypes.Date,
    },
  ];

  const savePrice = async (keyValue, value) => {
    const datasource = gridView3.current
      ? gridView3.current.getDataSource()
      : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTipoDespesa_PlanoDeConta === keyValue
    );
    itemParcela.vlrPlanoConta = value;

    for (let i = 0; i < datasource.length; ) {
      if (
        datasource[i].nrSeqTipoDespesa_PlanoDeConta ===
        itemParcela.nrSeqTipoDespesa_PlanoDeConta
      ) {
        datasource[i].vlrPlanoConta = itemParcela.vlrPlanoConta;
      }
      i += 1;
    }
  };

  const fPrice = function (keyValue, value) {
    savePrice(keyValue, value);
  };

  const columns3 = [
    {
      key: 'nrSeqTipoDespesa_PlanoDeConta',
      visible: false,
    },
    {
      key: 'planoDeContaCre.cdCaminhoCompleto',
      title: 'Plano De Contas Credito',
    },
    {
      key: 'vlrPlanoConta',
      title: 'Valor',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      readOnlyDynamic: 'flgPercentual',
      onBlur: fPrice,
      maxLength: 10,
    },
    { key: 'flgPercentual', visible: false },
  ];

  const onSearchTipoDespesa = async (e) => {
    const {
      status,
      message: msg,
      data: tipoDespesas,
    } = await getTipoDespesaList({
      noTipoDespesa: e,
    });
    if (msg) onSetMessage(status, msg);
    return tipoDespesas;
  };

  return (
    <Modal
      show={show}
      title='Agrupamento de Titulos'
      size={BootstrapSizes.Large}
    >
      <Confirm
        show={showConfirmaAlteracao}
        title='Confirmar Alteração Titulo Pagar'
        titleIcon='exclamation-triangle'
        text='Você confirma que quer alterar o tipo de despesa por um diferente do titulo original?'
        handleConfirm={() => {
          salvar(true);
        }}
        handleCancel={() => setShowConfirmaAlteracao(false)}
      />
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='row'>
          <div className='col-4 mb-3'>
            <Autocomplete
              label='Tipo Despesa'
              searchDataSource={onSearchTipoDespesa}
              selectedItem={data?.tipoDespesa}
              onSelectItem={async (tipoDespesa) => {
                setData({
                  ...data,
                  tipoDespesa,
                  nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
                });
                Recalcula(tipoDespesa);
              }}
              dataSourceTextProperty='noTipoDespesa'
            />
          </div>
          <div className='col-4 mb-3'>
            <Textbox
              label='Nr. Documento'
              text={data?.nrTit}
              onChangedValue={(nrTit) => setData({ ...data, nrTit })}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-4 mb-3'>
            <Textbox
              label='Valores dos Titulos'
              readOnly
              text={data?.vlrSaldo}
              mask={MaskTypes.DecimalCustom}
              onChangedValue={(vlrSaldo) => setData({ ...data, vlrSaldo })}
            />
          </div>
          <div className='col-5 mb-3'>
            <Autocomplete
              label='Forma Pagamento'
              searchDataSource={onSearchFormaPagamento}
              required
              selectedItem={data?.formaPagamento}
              onSelectItem={(formaPagamento) => {
                setData({
                  ...data,
                  formaPagamento,
                  nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                });
              }}
              dataSourceTextProperty='noFormaPagamento'
            />
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-4 mb-3'>
            <DatePicker
              label='Dt Vencimento'
              text={data?.dtVencimento}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtVencimento) => setData({ ...data, dtVencimento })}
            />
          </div>
          <div className='col-4 mb-3'>
            <Textbox
              label='Valor'
              text={data?.vlrTitulo}
              mask={MaskTypes.DecimalCustom}
              onChangedValue={(vlrTitulo) => setData({ ...data, vlrTitulo })}
            />
          </div>
          <div className='col-4 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Adicionar'
              onClick={onAdicionarTituloAgrupado}
            />
          </div>
          <div className='col-4 mb-3'>
            <Textbox
              label='Valor Somado da grid'
              readOnly
              text={somados?.vlrSomado}
              mask={MaskTypes.DecimalCustom}
              onChangedValue={(vlrSomado) =>
                setSomados({ ...somados, vlrSomado })
              }
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>
            <Panel>
              <Panel.Header
                title='Rateio do plano de Contas'
                theme={Theme.Light}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-9'>
                    <GridView
                      ref={gridView3}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columns3}
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>

        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
        <div className='col'>
          <GridView
            ref={gridView2}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns2}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>

        <div className='row'>
          {somados.flgDeixaSalvar && (
            <div className='col-4 mt-3'>
              <Button
                theme={Theme.Primary}
                template={Button.Templates.Button}
                text='Salvar'
                onClick={() => salvar(false)}
              />
            </div>
          )}
          <div className='col-2 mt-3'>
            <Button
              theme={Theme.Danger}
              template={Button.Templates.Button}
              text='Sair'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
