import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { MaskTypes } from 'ui/Helpers/masks';
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';
import { Textbox, Panel, Autocomplete, Button } from 'ui/components';
import { getCidadeAutoComplete, getCep } from 'core/services/SEG/cidade';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
import { getPaisAutoComplete } from 'core/services/SEG/pais';
import { Endereco, Cidade, Pais, Estado } from 'core/models/SEG';

interface Props {
  formData?: any;
  setFormData: (object: any) => void;
  endereco?: Endereco | Array<Endereco>;
  setLoading: (e: boolean) => void;
  onSetMessage: (status: ResponseStatus, msg: string) => void;
}

const PainelEndereco = forwardRef(
  ({ formData, setLoading, onSetMessage, setFormData }: Props, ref) => {
    const [address, setAddress] = useState<Endereco | any>({});

    function isEmpty(obj: any) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    useImperativeHandle(ref, () => ({
      clear() {
        setAddress({});
      },
    }));

    useEffect(() => {
      if (formData.endereco !== address) {
        setAddress(formData.endereco);
      } else setAddress({});
    }, [formData.endereco?.nrSeqEndereco]);

    useEffect(() => {
      if (address && !isEmpty(address)) {
        setFormData({ ...formData, endereco: address });
      }
    }, [address]);

    const onSearchCep = async (): Promise<void> => {
      if (address.cep && address.cep.length > 4) {
        setLoading(true);
        const enderecoInfos: any = await getCep({
          cep: address.cep,
        });

        if (enderecoInfos) {
          setAddress({
            nrSeqEndereco: address.nrSeqEndereco,
            cep: enderecoInfos.cep,
            bairro: enderecoInfos.bairro,
            noRua: enderecoInfos.noRua,
            cidade: enderecoInfos.cidade,
            nrSeqCidade: enderecoInfos.cidade?.nrSeqCidade,
            noCidade: enderecoInfos.cidade?.noCidade,
            estado: {
              ...enderecoInfos.cidade?.estado,
              nrSeqPais: enderecoInfos.cidade?.estado?.pais?.nrSeqPais,
            },
            pais: enderecoInfos.cidade?.estado?.pais,
          });
        }
        setLoading(false);
      }
    };

    const onSearchPais = async (e: string) => {
      const { status, message: msg, paises } = await getPaisAutoComplete({
        noPais: e,
      });
      onSetMessage(status, msg);
      return paises;
    };

    const onSearchEstado = async (e: string) => {
      const { status, message: msg, estados } = await getEstadoAutoComplete({
        noEstado: e,
        nrSeqPais: address?.pais?.nrSeqPais ?? null,
      });

      if (msg && status) onSetMessage(status, msg);
      return estados;
    };

    const onSearchCidade = async (e: string) => {
      const { status, message: msg, cidades } = await getCidadeAutoComplete({
        noCidade: e,
        nrSeqEstado: address.estado?.nrSeqEstado ?? null,
      });

      onSetMessage(status, msg);
      return cidades;
    };

    return (
      <div className='row mt-3'>
        {/* @ts-expect-error */}
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header title='Endereço' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Cep'
                  id='txtCep'
                  text={address?.cep}
                  maxLength={9}
                  onChangedValue={(cep: string) => {
                    setAddress({
                      ...address,
                      cep,
                    });
                  }}
                />
              </div>
              <div className='col-1 mt-3'>
                <Button
                  outline
                  theme={Theme.Primary}
                  icon='search'
                  tooltip='Pesquisar o endereço pelo CEP digitado.'
                  onClick={onSearchCep}
                />
              </div>
              <div className='col-4'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Rua'
                  id='txtRua'
                  text={address?.noRua}
                  maxLength={80}
                  onChangedValue={(noRua: string) => {
                    setAddress({
                      ...address,
                      noRua,
                    });
                  }}
                />
              </div>

              <div className='col-2'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Número'
                  id='txtNumero'
                  mask={MaskTypes.Integer}
                  text={address?.numero}
                  maxLength={20}
                  onChangedValue={(numero: any) => {
                    const numeroAsString =
                      typeof numero === 'number' ? String(numero) : numero;

                    setAddress({
                      ...address,
                      numero: numeroAsString,
                    });
                  }}
                />
              </div>

              <div className='col-3'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Complemento'
                  id='txtComplemento'
                  text={address?.complemento}
                  maxLength={100}
                  onChangedValue={(complemento: string) =>
                    setAddress({
                      ...address,
                      complemento,
                    })
                  }
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-3'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Bairro'
                  id='txtBairro'
                  text={address?.bairro}
                  maxLength={60}
                  onChangedValue={(bairro: string) => {
                    setAddress({
                      ...address,
                      bairro,
                    });
                  }}
                />
              </div>

              <div className='col-3'>
                <Autocomplete
                  label='País'
                  id='txtPais'
                  searchDataSource={onSearchPais}
                  selectedItem={address?.pais}
                  onSelectItem={(pais: Pais) => {
                    setAddress({
                      ...address,
                      pais,
                      nrSeqPais: pais.nrSeqPais,
                    });
                  }}
                  dataSourceTextProperty='noPais'
                />
              </div>

              <div className='col-3'>
                <Autocomplete
                  label='Estado'
                  id='txtEstado'
                  searchDataSource={onSearchEstado}
                  selectedItem={address?.estado}
                  onSelectItem={(estado: Estado) => {
                    setAddress({
                      ...address,
                      estado,
                      nrSeqEstado: estado.nrSeqEstado,
                    });
                  }}
                  dataSourceTextProperty='noEstado'
                />
              </div>

              <div className='col-3'>
                <Autocomplete
                  label='Cidade'
                  id='txtCidade'
                  searchDataSource={onSearchCidade}
                  selectedItem={address?.cidade}
                  onSelectItem={(cidade: Cidade) => {
                    setAddress({
                      ...address,
                      cidade,
                      nrSeqCidade: cidade.nrSeqCidade,
                      noCidade: cidade.noCidade,
                    });
                  }}
                  dataSourceTextProperty='noCidade'
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
);

export default PainelEndereco;
