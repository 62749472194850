import Veiculo from '../FRO/veiculo';
import Produto from '../EST/produto';
import ProdutoMarca from '../EST/produtoMarca';
import AlmoxarifadoLocal from '../EST/almoxarifadoLocal';

export default class PedidoCompraItem {
  constructor(jsonObject = {}) {
    this.nrSeqPedidoCompra = jsonObject.nrSeqPedidoCompra;
    this.nrSeqProduto = jsonObject.nrSeqProduto;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.nrSeqProdutoMarca = jsonObject.nrSeqProdutoMarca;
    this.quantidade = jsonObject.quantidade;
    this.vlrUnitario = jsonObject.vlrUnitario;
    this.vlrTotalItem = jsonObject.vlrTotalItem;
    this.vlrFrete = jsonObject.vlrFrete;
    this.vlrSaldoQuantidade = jsonObject.vlrSaldoQuantidade;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.vlrDesconto = jsonObject.vlrDesconto;
    this.noObservacao = jsonObject.noObservacao;
    this.vlrIPI = jsonObject.vlrIPI;
    this.vlrIPIPorcentagem = jsonObject.vlrIPIPorcentagem;
    this.nrOrdem = jsonObject.nrOrdem;
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    this.produto = new Produto(jsonObject.produto ?? {});
    this.produtoMarca = new ProdutoMarca(jsonObject.produtoMarca ?? {});
    this.nrSeqAlmoxarifadoLocal = jsonObject.nrSeqAlmoxarifadoLocal;
    this.localArmazenagem = new AlmoxarifadoLocal(
      jsonObject.almoxarifadoLocal ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
