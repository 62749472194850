import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Textbox } from 'ui/components';
// import { MaskTypes } from 'ui/Helpers/masks';
import {
  getContaCorrenteTransferencia,
  saveContaCorrenteTransferencia,
  deleteContaCorrenteTransferencia,
} from 'core/services/FIN/contaCorrenteTransferencia';

import { getContaCorrenteAutoComplete } from 'core/services/FIN/contaCorrente';
import { getExtratoCategoriaAutoComplete } from 'core/services/FIN/extratoCategoria';
import { getPlanoDeContaAutoComplete } from 'core/services/FIN/planoDeConta';

export default function ContaCorrenteTransferenciaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const obj = await getContaCorrenteTransferencia(registryKey);
      setData(obj);
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveContaCorrenteTransferencia(data);
    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteContaCorrenteTransferencia(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchContaCorrenteOri = async (e) => {
    const contaCorrenteOri = await getContaCorrenteAutoComplete({
      noContaCorrente: e,
    });
    if (contaCorrenteOri.msg)
      onSetMessage(contaCorrenteOri.status, contaCorrenteOri.msg);

    return contaCorrenteOri.data;
  };

  const onSearchContaCorrenteDest = async (e) => {
    const contaCorrenteDest = await getContaCorrenteAutoComplete({
      noContaCorrente: e,
    });
    if (contaCorrenteDest.msg)
      onSetMessage(contaCorrenteDest.status, contaCorrenteDest.msg);

    return contaCorrenteDest.data;
  };

  const onSearchExtratoCategoria = async (e) => {
    const extratoCategorias = await getExtratoCategoriaAutoComplete({
      noExtratoCategoria: e,
    });
    if (extratoCategorias.msg)
      onSetMessage(extratoCategorias.status, extratoCategorias.msg);
    return extratoCategorias.extratocategorias;
  };

  const onSearchPlanoContaCred = async (e) => {
    const planoDeContaCred = await getPlanoDeContaAutoComplete({
      noPlanoDeConta: e,
    });
    if (planoDeContaCred.msg)
      onSetMessage(planoDeContaCred.status, planoDeContaCred.msg);
    return planoDeContaCred.planodecontas;
  };

  const onSearchPlanoContaDeb = async (e) => {
    const planoDeContaDeb = await getPlanoDeContaAutoComplete({
      noPlanoDeConta: e,
    });
    if (planoDeContaDeb.msg)
      onSetMessage(planoDeContaDeb.status, planoDeContaDeb.msg);
    return planoDeContaDeb.planodecontas;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Conta Corrente Transferência'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqContaCorrenteTransferenci > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-3'>
          <Textbox
            maxLength={20}
            label='Código da Transferência'
            text={data.cdContaCorrenteTransferencia}
            onChangedValue={(cdContaCorrenteTransferencia) =>
              setData({ ...data, cdContaCorrenteTransferencia })
            }
          />
        </div>
        <div className='col-6'>
          <Textbox
            maxLength={80}
            label='Descrição'
            text={data.noContaCorrenteTransferencia}
            onChangedValue={(noContaCorrenteTransferencia) =>
              setData({ ...data, noContaCorrenteTransferencia })
            }
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Extrato da Categoria'
            searchDataSource={onSearchExtratoCategoria}
            selectedItem={data.extratoCategoria}
            onSelectItem={(extratoCategoria) => {
              setData({
                ...data,
                extratoCategoria,
                nrSeqExtratoCategoria: extratoCategoria.nrSeqExtratoCategoria,
              });
            }}
            dataSourceTextProperty='noExtratoCategoria'
          />
        </div>
      </div>

      <div className='row mb-2'>
        <div className='col-6'>
          <Autocomplete
            label='Conta Corrente Destino'
            searchDataSource={onSearchContaCorrenteDest}
            selectedItem={data.contaCorrenteDest}
            onSelectItem={(contaCorrenteDest) => {
              setData({
                ...data,
                contaCorrenteDest,
                nrSeqContaCorrenteDest: contaCorrenteDest.nrSeqContaCorrente,
              });
            }}
            dataSourceTextProperty='noContaCorrente'
          />
        </div>

        <div className='col-6'>
          <Autocomplete
            label='Conta Corrente Origem'
            searchDataSource={onSearchContaCorrenteOri}
            selectedItem={data.contaCorrenteOri}
            onSelectItem={(contaCorrenteOri) => {
              setData({
                ...data,
                contaCorrenteOri,
                nrSeqContaCorrenteOri: contaCorrenteOri.nrSeqContaCorrente,
              });
            }}
            dataSourceTextProperty='noContaCorrente'
          />
        </div>
      </div>

      <div className='row mb-2'>
        <div className='col-6'>
          <Autocomplete
            label='Plano de Conta Crédito'
            searchDataSource={onSearchPlanoContaCred}
            selectedItem={data.planoDeContaCred}
            onSelectItem={(planoDeContaCred) => {
              setData({
                ...data,
                planoDeContaCred,
                nrSeqPlanoContaCred: planoDeContaCred.nrSeqPlanoDeConta,
              });
            }}
            dataSourceTextProperty='noPlanoDeConta'
          />
        </div>
        <div className='col-6'>
          <Autocomplete
            label='Plano de Conta Débito'
            searchDataSource={onSearchPlanoContaDeb}
            selectedItem={data.planoDeContaDeb}
            onSelectItem={(planoDeContaDeb) => {
              setData({
                ...data,
                planoDeContaDeb,
                nrSeqPlanoContaDeb: planoDeContaDeb.nrSeqPlanoDeConta,
              });
            }}
            dataSourceTextProperty='noPlanoDeConta'
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
