import NfItem from 'core/models/COP/nfItem';
import { NfvAvulsaItem, NfsFatura, NfsImpostos } from 'core/models/VEN';
import Abastecimento from 'core/models/FRO/abastecimento';

export default class PocResultadoOrcamento {
  constructor(jsonObject = {}) {
    this.nrSeqPocOrcamento = jsonObject.nrSeqPocOrcamento;
    this.nrSeqPocOrcamentoPai = jsonObject.nrSeqPocOrcamentoPai;
    this.noPocOrcamentoStatus = jsonObject.noPocOrcamentoStatus;
    this.cdProposta = jsonObject.cdProposta;
    this.vlrTotalOrcamento = jsonObject.vlrTotalOrcamento;
    this.vlrCustoProdutos = jsonObject.vlrCustoProdutos;
    this.vlrSaldoOrcamento = jsonObject.vlrSaldoOrcamento;
    this.noPessoa = jsonObject.noPessoa;
    this.nrCnpj = jsonObject.nrCnpj;
    this.noLocalObra = jsonObject.noLocalObra;
    this.nrOrdem = jsonObject.nrOrdem;
    this.dtValidade = jsonObject.dtValidade;
    this.vlrTotalNotaEntrada = jsonObject.vlrTotalNotaEntrada;
    this.vlrTotalNotaSaida = jsonObject.vlrTotalNotaSaida;
    this.vlrTotalObra = jsonObject.vlrTotalObra;
    this.vlrTotalAbastecimentos = jsonObject.vlrTotalAbastecimentos;
    this.vlrTotalImposto = jsonObject.vlrTotalImposto;
    this.vlrFaturado = jsonObject.vlrFaturado;
    this.nrSeqPocTipoOrcamento = jsonObject.nrSeqPocTipoOrcamento;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.nrSeqEndereco = jsonObject.nrSeqEndereco;
    this.noCliente = jsonObject.noCliente;
    this.noPoco = jsonObject.noPoco;
    this.noPocTipoOrcamento = jsonObject.noPocTipoOrcamento;
    this.documento = jsonObject.documento;
    this.flgRelNotasFiscais = jsonObject.flgRelNotasFiscais;
    this.flgRelAbastecimento = jsonObject.flgRelAbastecimento;
    this.flgRelMovimentoSaida = jsonObject.flgRelMovimentoSaida;
    this.abastecimentos = jsonObject.abastecimentos
      ? jsonObject.abastecimentos.map((item) => new Abastecimento(item ?? {}))
      : [];
    this.itensEntrada = jsonObject.itensEntrada
      ? jsonObject.itensEntrada.map((item) => new NfItem(item ?? {}))
      : [];
    this.itensSaida = jsonObject.itensSaida
      ? jsonObject.itensSaida.map((item) => new NfvAvulsaItem(item ?? {}))
      : [];
    this.faturas = jsonObject.faturas
      ? jsonObject.faturas.map((item) => new NfsFatura(item ?? {}))
      : [];
    this.impostos =
      jsonObject.impostos !== undefined && jsonObject.impostos !== null
        ? new NfsImpostos(jsonObject.impostos)
        : new NfsImpostos();
  }

  toJson = () => JSON.stringify(this);
}
