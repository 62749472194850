export default class CfoMensagem {
  constructor(jsonObject = {}) {
    this.nrSeqCfoMensagem = jsonObject.nrSeqCfoMensagem;
    this.cdCfoMensagem = jsonObject.cdCfoMensagem;
    this.noCfoMensagem = jsonObject.noCfoMensagem;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
