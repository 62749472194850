import qs from 'qs';
import Pagination from 'core/models/pagination';
import PocDominioGeologico from 'core/models/POC/dominioGeologico';
import api from '../api';

const baseURL = `POC/DominioGeologico`;

export const getDominioGeologicoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    dominioGeologico: value?.map((json) => new PocDominioGeologico(json)) ?? [],
  };
};

export const getPocDominioGeologicoList = async (filters) => {
  const response = await api.get(`/$PocDominioGeologico/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocDominioGeologico(json)) ?? [],
  };
};

export const getPocDominioGeologicoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocDominioGeologico(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocDominioGeologico = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocDominioGeologico(value) : {};
};

export const savePocDominioGeologico = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePocDominioGeologico = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPocDominioGeologico = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocDominioGeologico = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocDominioGeologico = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
