import qs from 'qs';
import Pagination from 'core/models/pagination';
import PlanoVinculoCentrodeCustoEmp from 'core/models/TEL/planoVinculoCentrodeCustoEmp';
import api from '../api';

const baseURL = `/TEL/PlanoVinculoCentrodeCustoEmp`;

export const getPlanoVinculoCentrodeCustoEmpAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    planovinculo:
      value?.map((json) => new PlanoVinculoCentrodeCustoEmp(json)) ?? [],
  };
};

export const getPlanoVinculoCentrodeCustoEmpList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PlanoVinculoCentrodeCustoEmp(json)) ?? [],
  };
};

export const getPlanoVinculoCentrodeCustoEmpPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PlanoVinculoCentrodeCustoEmp(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPlanoVinculoCentrodeCustoEmp = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PlanoVinculoCentrodeCustoEmp(value) : {};
};

export const savePlanoVinculoCentrodeCustoEmp = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePlanoVinculoCentrodeCustoEmp = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPlanoVinculoCentrodeCustoEmp = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPlanoVinculoCentrodeCustoEmp = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPlanoVinculoCentrodeCustoEmp = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
