import React, { forwardRef, useState, useEffect, useRef } from 'react';
import { BootstrapSizes, Theme, firstLetterUpperCase } from 'ui/Helpers/utils';

import { MaskTypes } from 'ui/Helpers/masks';

import {
  GridView,
  Textbox,
  DatePicker,
  Autocomplete,
  RadioButton,
  Button,
} from 'ui/components';

import { getTipoBombaAutoComplete } from 'core/services/POC/tipoBomba';
import Textarea from 'ui/components/Textarea';
import { getQuadroComandoAutoComplete } from 'core/services/POC/pocQuadroComando';
import { getTubulacaoAducaoAutoComplete } from 'core/services/POC/pocTubulacaoAducao';

import {
  getPocCabo,
  getPocCaboAutoComplete,
} from '../../../../core/services/POC/pocCabo';

import { getMetricConversion } from '../../../../core/services/POC/pocPocoPerfuracao';
import PocPocoPocTubulacaoAducao from '../../../../core/models/POC/pocPocoPocTubulacaoAducao';
import { getPocTubulacaoAducao } from '../../../../core/services/POC/pocTubulacaoAducao';

import { TipoBomba } from './enums';
import {
  getTipoRedeAutoComplete,
  getTipoVoltagemAutoComplete,
} from '../../../../core/services/POC/tipoBomba';

const Fornecimento = [
  {
    text: 'Hidrogeo',
    value: 'Hidrogeo',
  },
  {
    text: 'Cliente',
    value: 'Cliente',
  },
];
const EquipamentoInstalado = [
  {
    text: 'Quadro de Comando / Bombas',
    value: 'Bomba',
  },
  {
    text: 'Tubos e Cabos',
    value: 'TubosCabos',
  },
];

const CadastroEquipInstalado = forwardRef(
  ({
    data,
    setData,
    onValidaCampoObrigatorioBomba,
    onValidaCampoObrigatorioTubo,
    onValidaCampoObrigatorioCabo,
  }) => {
    const gridViewBombas = useRef();
    const gridViewTubos = useRef();
    const gridViewCabos = useRef();

    const [dataBomba, setDataBomba] = useState({
      noFornecimento: 'Hidrogeo',
    });
    const [dataTubo, setDataTubo] = useState({
      noFornecimento: 'Hidrogeo',
    });

    const [dataCabo, setDataCabo] = useState({
      noFornecimento: 'Hidrogeo',
    });

    const [dataEquipInstalado, setDataEquipInstalado] = useState({
      flgEquipamentoInstalado: 'Bomba',
    });

    const { quadroComando } = data;

    const [dataQuadroComando, setDataQuadroComando] = useState(
      data.quadroComando
    );
    const [tipoVoltagemQuadroComando, setTipoVoltagemQuadroComando] = useState(
      data.quadroComando.tiposVoltagem
    );
    const [dataQuadroComandos, setDataQuadroComandos] = useState(
      data.quadroComando.quadroComandos
    );

    const onSearchQuandroComando = async (e) => {
      const { quadroscomando } = await getQuadroComandoAutoComplete({
        cdPocQuadroComando: e,
      });
      return quadroscomando;
    };

    const onSearchTubos = async (e) => {
      const { tubos } = await getTubulacaoAducaoAutoComplete({
        noDescricao: e,
      });
      return tubos;
    };

    const onSearchCabos = async (e) => {
      const { cabos } = await getPocCaboAutoComplete({
        noDescricao: e,
      });
      return cabos;
    };

    const onSearchTipoBomba = async () => {
      const { tiposbomba } = await getTipoBombaAutoComplete({});
      const tipoBombaItem = {
        noTipoBomba: tiposbomba[0].noTipoBomba,
        nrSeqTipoBomba: tiposbomba[0].nrSeqTipoBomba,
      };
      setDataBomba({ ...dataBomba, tiposbomba: tipoBombaItem });
      return tiposbomba;
    };

    const onSearchTipoRede = async () => {
      const { tiposrede } = await getTipoRedeAutoComplete({});
      const tipoRedeItem = {
        noTipoRede: tiposrede[0].noTipoRede,
        nrSeqTipoRede: tiposrede[0].nrSeqTipoRede,
      };
      setDataBomba({ ...dataBomba, tiposrede: tipoRedeItem });
      return tiposrede;
    };

    const onSearchTipoVoltagem = async () => {
      const { tiposvoltagem } = await getTipoVoltagemAutoComplete({});

      return tiposvoltagem;
    };

    const onAdicionarBomba = async () => {
      if (!onValidaCampoObrigatorioBomba(dataBomba)) return;

      dataBomba.nrSeqPocPoco = data.nrSeqPocPoco;
      dataBomba.status = dataBomba.status ?? GridView.EnumStatus.Inserir;

      data.bombas.push(dataBomba);

      if (gridViewBombas && gridViewBombas.current)
        gridViewBombas.current.setDataSource(data.bombas);

      setDataBomba({
        tiposbomba: {},
        noFornecimento: 'Hidrogeo',
      });
    };

    const onAdicionarTubo = async () => {
      if (!onValidaCampoObrigatorioTubo(dataTubo)) return;

      const obj = new PocPocoPocTubulacaoAducao({
        ...dataTubo,
        nrSeqPocPoco: data.nrSeqPocPoco,
        noFornecimento: !dataTubo.noFornecimento
          ? 'Hidrogeo'
          : dataTubo.noFornecimento,
        status: dataTubo.status ?? GridView.EnumStatus.Inserir,
      });

      data.tubos.push(obj);

      setDataTubo({
        dtInstalacao: dataTubo.dtInstalacao,
        noFornecimento: dataTubo.noFornecimento,
        nrMetragem: '',
      });

      gridViewTubos.current.setDataSource(data.tubos);
    };

    const onAdicionarCabo = async () => {
      if (!onValidaCampoObrigatorioCabo(dataCabo)) return;

      dataCabo.nrSeqPocPoco = data.nrSeqPocPoco;
      dataCabo.status = dataCabo.status ?? GridView.EnumStatus.Inserir;
      data.cabos.push(dataCabo);

      if (gridViewCabos && gridViewCabos.current)
        gridViewCabos.current.setDataSource(data.cabos);

      setDataCabo({
        noFornecimento: dataCabo.noFornecimento,
        dtInstalacao: dataCabo.dtInstalacao,
        nrMetragem: '',
      });

      document.getElementById('tipoCabo').focus();
    };

    const onEditarBomba = async (selectedvalue) => {
      const objRemove = data.bombas.filter((e) => e === selectedvalue);

      const { tiposvoltagem } = await getTipoVoltagemAutoComplete({});
      const { tiposrede } = await getTipoRedeAutoComplete({});
      objRemove[0].noRede = firstLetterUpperCase(objRemove[0].noRede);
      const rede = tiposrede.filter(
        (p) => p.noTipoRede === objRemove[0].noRede
      );
      const voltagem = tiposvoltagem.filter(
        (p) => p.nrTipoVoltagem === objRemove[0].nrVoltagem
      );
      const tipoBombaItem = {
        noTipoBomba: objRemove[0].noTipoBomba,
        nrSeqTipoBomba: objRemove[0].nrSeqTipoBomba,
        flgFornecimento: objRemove[0].flgFornecimento,
      };
      const tipoRedeItem = rede[0];
      const tipoVoltagemItem = voltagem[0];

      objRemove[0].noTipoBomba = firstLetterUpperCase(objRemove[0].noTipoBomba);
      objRemove[0].noFornecimento = firstLetterUpperCase(
        objRemove[0].noFornecimento
      );
      objRemove[0].status = GridView.EnumStatus.Alterar;
      objRemove[0].tiposbomba = tipoBombaItem;
      objRemove[0].tiposrede = tipoRedeItem;
      objRemove[0].tiposvoltagem = tipoVoltagemItem;

      setDataBomba(objRemove[0]);

      const index = data.bombas.indexOf(objRemove[0]);
      data.bombas.splice(index, 1);

      if (gridViewBombas && gridViewBombas.current)
        gridViewBombas.current.setDataSource(data.bombas);
    };

    const onEditarTubo = async (selectedValue) => {
      const objremove = data.tubos.filter((e) => selectedValue === e);

      const tubulacao = await getPocTubulacaoAducao(
        objremove[0].nrSeqPocTubulacaoAducao
      );

      const tuboItem = {
        nrSeqPocTubulacaoAducao: tubulacao.nrSeqPocTubulacaoAducao,
        noDescricao: tubulacao.noDescricao,
        cdPocTubulacaoAducao: tubulacao.cdPocTubulacaoAducao,
      };

      objremove[0].tubo = tuboItem;
      objremove[0].status = GridView.EnumStatus.Alterar;

      const index = data.tubos.indexOf(objremove[0]);
      data.tubos.splice(index, 1);

      setDataTubo(objremove[0]);

      if (gridViewTubos && gridViewTubos.current)
        gridViewTubos.current.setDataSource(data.tubos);
    };

    const onEditarCabo = async (selectedValue) => {
      const objRemove = data.cabos.filter((e) => e === selectedValue);

      const poccabo = await getPocCabo(objRemove[0].nrSeqPocCabo);
      const caboItem = {
        noDescricao: poccabo.noDescricao,
        nrSeqPocCabo: poccabo.nrSeqPocCabo,
        cdPocCabo: poccabo.cdPocCabo,
      };
      objRemove[0].status = GridView.EnumStatus.Alterar;
      objRemove[0].cabo = caboItem;

      setDataCabo(objRemove[0]);

      const index = data.cabos.indexOf(objRemove[0]);
      data.cabos.splice(index, 1);

      if (gridViewCabos && gridViewCabos.current)
        gridViewCabos.current.setDataSource(data.cabos);
    };
    const onRemoverBomba = async (selectedValue) => {
      const objRemove = data.bombas.filter((e) => e === selectedValue);
      const index = data.bombas.indexOf(objRemove[0]);
      data.bombas.splice(index, 1);
      objRemove[0].status = GridView.EnumStatus.Remover;

      data.bombas.push(objRemove[0]);

      if (gridViewBombas && gridViewBombas.current)
        gridViewBombas.current.setDataSource(data.bombas);
    };
    const onRemoverTubo = async (selectedValue) => {
      const objremove = data.tubos.filter((e) => e === selectedValue);
      const index = data.tubos.indexOf(objremove[0]);
      data.tubos.splice(index, 1);

      objremove[0].status = GridView.EnumStatus.Remover;
      data.tubos.push(objremove[0]);

      if (gridViewTubos && gridViewTubos.current)
        gridViewTubos.current.setDataSource(data.tubos);
    };

    const onRemoverCabo = async (selectedValue) => {
      const objRemove = data.cabos.filter((e) => e === selectedValue);
      const index = data.cabos.indexOf(objRemove[0]);
      data.cabos.splice(index, 1);

      objRemove[0].status = GridView.EnumStatus.Remover;
      data.cabos.push(objRemove[0]);

      if (gridViewCabos && gridViewCabos.current)
        gridViewCabos.current.setDataSource(data.cabos);
    };

    useEffect(() => {
      (async function func() {
        if (dataEquipInstalado.flgEquipamentoInstalado === 'TubosCabos') {
          if (gridViewCabos && gridViewCabos.current)
            gridViewCabos.current.setDataSource(data.cabos);

          if (gridViewTubos && gridViewTubos.current)
            gridViewTubos.current.setDataSource(data.tubos);

          if (gridViewCabos && gridViewCabos.current)
            gridViewCabos.current.setDataSource(data.cabos);
        } else if (dataEquipInstalado.flgEquipamentoInstalado === 'Bomba') {
          if (gridViewBombas && gridViewBombas.current);
          gridViewBombas.current.setDataSource(data.bombas);
        }
      })();
    }, [dataEquipInstalado.flgEquipamentoInstalado]);

    const columnsbomba = [
      {
        key: 'nrSeqPocPocoBombaInstalada',
        visible: false,
      },
      {
        key: 'dtInstalacao',
        title: 'Instalacão',
        format: GridView.DataTypes.Date,
      },
      { key: 'noTipoBomba', title: 'Tipo de Bomba' },
      { key: 'nnSeqTipoBomba', title: 'nnSeqTipoBomba', visible: false },
      { key: 'noFornecimento', title: 'Fornecimento' },
      { key: 'noMarca', title: 'Marca' },
      { key: 'noModelo', title: 'Modelo' },
      { key: 'noDiametro', title: 'NoDiametro', visible: false },
      { key: 'nrPotencia', title: 'NrPotencia', visible: false },
      { key: 'nrEstagios', title: 'NrEstagios', visible: false },
      { key: 'nrVoltagem', title: 'NrVoltagem', visible: false },
      { key: 'nrConsumo', title: 'NrConsumo', visible: false },
      { key: 'noRede', title: 'NoRede', visible: false },
      { key: 'nrProfundidade', title: 'Profundidade' },
      { key: 'noCaboEletrico', title: 'NoCaboEletrico', visible: false },
      { key: 'flgFornecimento', title: 'flgFornecimento', visible: false },
      {
        key: 'noObservacoesEquip',
        title: 'NoObservacoesEquip',
        visible: false,
      },
      { key: 'nrSeqPocPoco', title: 'NrSeqPocPoco', visible: false },

      {
        key: 'nrSeqPocPocoBombaInstalada',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onEditarBomba(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },

      {
        key: 'nrSeqPocPocoBombaInstalada',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemoverBomba(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    const columnsTubos = [
      {
        key: 'nrSeqPocPocoPocTubulacaoAducao',
        visible: false,
      },
      {
        key: 'cdPocPocoPocTubulacaoAducao',
        visible: false,
      },
      { key: 'nrSeqPocPoco', title: 'NrSeqPocPoco', visible: false },
      {
        key: 'dtInstalacao',
        title: 'Instalação',
        format: GridView.DataTypes.Date,
      },
      { key: 'noDiametroTubulacao', title: 'Diâmetro Tubulação (pol)' },
      { key: 'noDiametroTubulacaomm', title: 'Diâmetro Tubulação (mm)' },
      {
        key: 'nrSeqPocTubulacaoAducao',
        title: 'NrSeqPocTubulacaoAducao',
        visible: false,
      },
      { key: 'nrMetragem', title: 'Metragem' },
      { key: 'noFornecimento', title: 'Fornecimento' },

      {
        key: 'nrSeqPocPocoPocTubulacaoAducao',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onEditarTubo(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },

      {
        key: 'nrSeqPocPocoPocTubulacaoAducao',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemoverTubo(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    const columnsCabos = [
      { key: 'nrSeqPocPocoPocCabo', visible: false },
      { key: 'nrSeqPocPoco', title: 'NrSeqPocPoco', visible: false },
      {
        key: 'dtInstalacao',
        title: 'Instalação',
        format: GridView.DataTypes.Date,
      },
      { key: 'nrSeqPocCabo', title: 'NrSeqPocCabo', visible: false },
      {
        key: 'cdSeqPocPocoPocCabo',
        visible: false,
      },
      { key: 'nrMetragem', title: 'Metragem' },
      { key: 'noFornecimento', title: 'Fornecimento' },

      {
        key: 'nrSeqPocPocoPocCabo',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onEditarCabo(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },

      {
        key: 'nrSeqPocPocoPocCabo',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemoverCabo(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    return (
      <div>
        <div className='row mb-3'>
          <div className='display-8'>10 - Equipamentos Instalados</div>
        </div>
        <div className='row mb-3'>
          <div className='card'>
            <div className='card-body'>
              <div className='row mb-3'>
                <div className='col'>
                  <RadioButton
                    outline
                    size={BootstrapSizes.Large}
                    theme={Theme.Primary}
                    buttons={EquipamentoInstalado}
                    selectedButton={dataEquipInstalado.flgEquipamentoInstalado}
                    onChange={(flgEquipamentoInstalado) =>
                      setDataEquipInstalado({
                        ...dataEquipInstalado,
                        flgEquipamentoInstalado,
                      })
                    }
                  />
                </div>
              </div>
              {dataEquipInstalado.flgEquipamentoInstalado === 'Bomba' && (
                <div>
                  <div className='row'>
                    <div className='card'>
                      <div className='card-body'>
                        <div className='row mb-3'>
                          <div className='row mb-3'>
                            <div className='display-8'>Quadro de Comando</div>
                          </div>
                          <div className='card'>
                            <div className='card-body'>
                              <div className='row mb-3'>
                                <div className='col-2'>
                                  <DatePicker
                                    label='Instalação'
                                    text={dataQuadroComando.dtInstalacao}
                                    maxLength={10}
                                    mask={MaskTypes.Date}
                                    onChange={(dtInstalacao) => {
                                      setDataQuadroComando({
                                        ...dataQuadroComando,
                                        dtInstalacao,
                                      });

                                      setData({
                                        ...data,
                                        quadroComando: {
                                          ...quadroComando,
                                          dtInstalacao,
                                          nrVoltagem: Number(
                                            tipoVoltagemQuadroComando?.noTipoVoltagem
                                          ),
                                          nrSeqPocQuadroComando:
                                            dataQuadroComandos?.nrSeqPocQuadroComando,
                                          quadroComandos: dataQuadroComandos,
                                          tiposVoltagem: tipoVoltagemQuadroComando,
                                          noFornecimento:
                                            dataQuadroComando.noFornecimento,
                                          noPocPocoEquipamentoObs:
                                            dataQuadroComando.noPocPocoEquipamentoObs,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                                <div className='col'>
                                  <RadioButton
                                    outline
                                    label='Fornecimento'
                                    size={BootstrapSizes.Small}
                                    theme={Theme.Primary}
                                    buttons={Fornecimento}
                                    selectedButton={
                                      dataQuadroComando.noFornecimento ??
                                      'Hidrogeo'
                                    }
                                    onChange={(noFornecimento) => {
                                      setDataQuadroComando({
                                        ...dataQuadroComando,
                                        noFornecimento,
                                      });

                                      setData({
                                        ...data,
                                        quadroComando: {
                                          ...quadroComando,
                                          noFornecimento,
                                          nrSeqPocQuadroComando:
                                            dataQuadroComandos?.nrSeqPocQuadroComando,
                                          nrVoltagem: Number(
                                            tipoVoltagemQuadroComando.noTipoVoltagem
                                          ),
                                          quadroComandos: dataQuadroComandos,
                                          tiposVoltagem: tipoVoltagemQuadroComando,
                                          dtInstalacao:
                                            dataQuadroComando.dtInstalacao,
                                          noPocPocoEquipamentoObs:
                                            dataQuadroComando.noPocPocoEquipamentoObs,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>

                              <div className='row mb-3'>
                                <div className='col-2'>
                                  <Autocomplete
                                    label='Voltagem (V)'
                                    searchDataSource={onSearchTipoVoltagem}
                                    selectedItem={tipoVoltagemQuadroComando}
                                    onSelectItem={(tiposVoltagem) => {
                                      setTipoVoltagemQuadroComando(
                                        tiposVoltagem
                                      );

                                      setDataQuadroComando({
                                        ...dataQuadroComando,
                                        tiposVoltagem,
                                      });

                                      setData({
                                        ...data,
                                        quadroComando: {
                                          ...quadroComando,
                                          nrVoltagem: Number(
                                            tiposVoltagem.noTipoVoltagem
                                          ),
                                          nrSeqPocQuadroComando:
                                            dataQuadroComandos?.nrSeqPocQuadroComando,
                                          tiposVoltagem,
                                          quadroComandos: dataQuadroComandos,
                                          noFornecimento:
                                            dataQuadroComando.noFornecimento,
                                          dtInstalacao:
                                            dataQuadroComando.dtInstalacao,
                                          noPocPocoEquipamentoObs:
                                            dataQuadroComando.noPocPocoEquipamentoObs,
                                        },
                                      });
                                    }}
                                    dataSourceTextProperty='noTipoVoltagem'
                                  />
                                </div>
                                <div className='col-2'>
                                  <Autocomplete
                                    label='Quadro'
                                    searchDataSource={onSearchQuandroComando}
                                    selectedItem={dataQuadroComandos}
                                    onSelectItem={(quadroComandos) => {
                                      setDataQuadroComandos({
                                        ...dataQuadroComandos,
                                        nrSeqPocQuadroComando:
                                          quadroComandos.nrSeqPocQuadroComando,
                                        noChave: quadroComandos.noChave,
                                        noModelo: quadroComandos.noModelo,
                                        noTipoVoltagem:
                                          quadroComandos.noTipoVoltagem,
                                        cdPocQuadroComando:
                                          quadroComandos.cdPocQuadroComando,
                                      });

                                      setDataQuadroComandos(quadroComandos);

                                      setData({
                                        ...data,
                                        quadroComando: {
                                          ...quadroComando,
                                          nrSeqPocQuadroComando:
                                            quadroComandos.nrSeqPocQuadroComando,
                                          status: 'Alterar',
                                          nrVoltagem: Number(
                                            tipoVoltagemQuadroComando.noTipoVoltagem
                                          ),
                                          noFornecimento:
                                            dataQuadroComando.noFornecimento,
                                          quadroComandos,
                                          tiposVoltagem: tipoVoltagemQuadroComando,
                                          dtInstalacao:
                                            dataQuadroComando.dtInstalacao,
                                          noPocPocoEquipamentoObs:
                                            dataQuadroComando.noPocPocoEquipamentoObs,
                                        },
                                      });
                                    }}
                                    dataSourceTextProperty='cdPocQuadroComando'
                                  />
                                </div>
                                <div className='col mb-3'>
                                  <Textbox
                                    readOnly
                                    maxLength={30}
                                    label='Tipo Voltagem'
                                    text={dataQuadroComandos?.noTipoVoltagem}
                                  />
                                </div>
                                <div className='col'>
                                  <Textbox
                                    readOnly
                                    maxLength={100}
                                    label='Chave'
                                    text={dataQuadroComandos?.noChave}
                                  />
                                </div>
                                <div className='col'>
                                  <Textbox
                                    readOnly
                                    maxLength={100}
                                    label='Modelo'
                                    text={dataQuadroComandos?.noModelo}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='row mb-3'>
                          <div className='display-8'>Bomba</div>
                        </div>
                        <div className='row'>
                          <div className='card'>
                            <div className='card-body'>
                              <div className='row mb-3'>
                                <div className='col-2'>
                                  <DatePicker
                                    label='Instalação'
                                    text={dataBomba.dtInstalacao}
                                    maxLength={10}
                                    mask={MaskTypes.Date}
                                    onChange={(dtInstalacao) =>
                                      setDataBomba({
                                        ...dataBomba,
                                        dtInstalacao,
                                      })
                                    }
                                  />
                                </div>
                                <div className='col'>
                                  <Autocomplete
                                    label='Tipo bomba'
                                    searchDataSource={onSearchTipoBomba}
                                    selectedItem={dataBomba.tiposbomba}
                                    onSelectItem={(tiposbomba) =>
                                      setDataBomba({
                                        ...dataBomba,
                                        noTipoBomba: tiposbomba.noTipoBomba,
                                      })
                                    }
                                    dataSourceTextProperty='noTipoBomba'
                                  />
                                </div>
                                <div className='col'>
                                  <RadioButton
                                    outline
                                    label='Fornecimento'
                                    size={BootstrapSizes.Small}
                                    theme={Theme.Primary}
                                    buttons={Fornecimento}
                                    selectedButton={dataBomba.noFornecimento}
                                    onChange={(noFornecimento) =>
                                      setDataBomba({
                                        ...dataBomba,
                                        noFornecimento,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {dataBomba.noTipoBomba === TipoBomba.Submersa && (
                          <div className='row'>
                            <div className='card'>
                              <div className='card-body'>
                                <div className='row mb-3'>
                                  <div className='col'>
                                    <Textbox
                                      maxLength={100}
                                      label='Marca'
                                      text={dataBomba.noMarca}
                                      onChangedValue={(noMarca) =>
                                        setDataBomba({
                                          ...dataBomba,
                                          noMarca,
                                        })
                                      }
                                    />
                                  </div>
                                  <div className='col'>
                                    <Textbox
                                      maxLength={100}
                                      label='Modelo'
                                      text={dataBomba.noModelo}
                                      onChangedValue={(noModelo) =>
                                        setDataBomba({
                                          ...dataBomba,
                                          noModelo,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                <div className='row mb-3'>
                                  <div className='col'>
                                    <Textbox
                                      maxLength={10}
                                      label='Diâmetro (pol)'
                                      text={dataBomba.noDiametro}
                                      onChangedValue={(noDiametro) =>
                                        setDataBomba({
                                          ...dataBomba,
                                          noDiametro,
                                        })
                                      }
                                    />
                                  </div>
                                  <div className='col'>
                                    <Textbox
                                      label='Potência (hp)'
                                      required
                                      text={dataBomba.nrPotencia}
                                      mask={MaskTypes.DecimalCustom}
                                      onChangedValue={(nrPotencia) =>
                                        setDataBomba({
                                          ...dataBomba,
                                          nrConsumo: nrPotencia * 0.746,
                                          nrPotencia,
                                        })
                                      }
                                    />
                                  </div>
                                  <div className='col'>
                                    <Textbox
                                      required
                                      label='Estágios'
                                      text={dataBomba.nrEstagios}
                                      mask={MaskTypes.Integer}
                                      onChangedValue={(nrEstagios) =>
                                        setDataBomba({
                                          ...dataBomba,
                                          nrEstagios,
                                        })
                                      }
                                    />
                                  </div>
                                </div>

                                <div className='row mb-3'>
                                  <div className='col'>
                                    <Autocomplete
                                      label='Voltagem (V)'
                                      searchDataSource={onSearchTipoVoltagem}
                                      selectedItem={dataBomba.tiposvoltagem}
                                      onSelectItem={(tiposvoltagem) =>
                                        setDataBomba({
                                          ...dataBomba,
                                          tiposvoltagem,
                                          nrVoltagem: Number(
                                            tiposvoltagem.noTipoVoltagem
                                          ),
                                        })
                                      }
                                      dataSourceTextProperty='noTipoVoltagem'
                                    />
                                  </div>
                                  <div className='col'>
                                    <Textbox
                                      label='Consumo (KW)'
                                      required
                                      text={dataBomba.nrConsumo}
                                      mask={MaskTypes.DecimalCustom}
                                      onChangedValue={(nrConsumo) =>
                                        setDataBomba({
                                          ...dataBomba,
                                          nrConsumo,
                                        })
                                      }
                                    />
                                  </div>
                                  <div className='col'>
                                    <Autocomplete
                                      label='Rede'
                                      searchDataSource={onSearchTipoRede}
                                      selectedItem={dataBomba.tiposrede}
                                      onSelectItem={(tiposrede) =>
                                        setDataBomba({
                                          ...dataBomba,
                                          tiposrede,
                                          noRede: tiposrede.noTipoRede,
                                        })
                                      }
                                      dataSourceTextProperty='noTipoRede'
                                    />
                                  </div>
                                </div>
                                <div className='row mb-3'>
                                  <div className='col'>
                                    <Textbox
                                      label='Profundidade (mts)'
                                      required
                                      text={dataBomba.nrProfundidade}
                                      mask={MaskTypes.DecimalCustoml}
                                      onChangedValue={(nrProfundidade) =>
                                        setDataBomba({
                                          ...dataBomba,
                                          nrProfundidade: Number(
                                            nrProfundidade
                                          ),
                                        })
                                      }
                                    />
                                  </div>
                                  <div className='col'>
                                    <Textbox
                                      maxLength={10}
                                      label='Cabo Elétrico (mm)'
                                      text={dataBomba.noCaboEletrico}
                                      onChangedValue={(noCaboEletrico) =>
                                        setDataBomba({
                                          ...dataBomba,
                                          noCaboEletrico,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {dataBomba.noTipoBomba === TipoBomba.Catavento && (
                          <div className='row'>
                            <div className='card'>
                              <div className='card-body'>
                                <div className='row mb-3'>
                                  <div className='col'>
                                    <Textbox
                                      maxLength={100}
                                      label='Marca'
                                      text={dataBomba.noMarca}
                                      onChangedValue={(noMarca) =>
                                        setDataBomba({ ...dataBomba, noMarca })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {(dataBomba.noTipoBomba === TipoBomba.Compressor ||
                          dataBomba.noTipoBomba ===
                            TipoBomba.BombaInjetora) && (
                          <div className='row'>
                            <div className='card'>
                              <div className='card-body'>
                                <div className='row mb-3'>
                                  <div className='col'>
                                    <Textbox
                                      maxLength={100}
                                      label='Marca'
                                      text={dataBomba.noMarca}
                                      onChangedValue={(noMarca) =>
                                        setDataBomba({ ...dataBomba, noMarca })
                                      }
                                    />
                                  </div>
                                </div>
                                <div className='row mb-3'>
                                  <div className='col'>
                                    <Textbox
                                      label='Potência'
                                      required
                                      text={dataBomba.nrPotencia}
                                      mask={MaskTypes.DecimalCustom}
                                      onChangedValue={(nrPotencia) =>
                                        setDataBomba({
                                          ...dataBomba,
                                          nrPotencia,
                                        })
                                      }
                                    />
                                  </div>
                                </div>

                                <div className='row mb-3'>
                                  <div className='col'>
                                    <Autocomplete
                                      label='Voltagem (V)'
                                      searchDataSource={onSearchTipoVoltagem}
                                      selectedItem={dataBomba.tiposvoltagem}
                                      onSelectItem={(tiposvoltagem) =>
                                        setDataBomba({
                                          ...dataBomba,
                                          tiposvoltagem,
                                          nrVoltagem: Number(
                                            tiposvoltagem.noTipoVoltagem
                                          ),
                                        })
                                      }
                                      dataSourceTextProperty='noTipoVoltagem'
                                    />
                                  </div>

                                  <div className='col'>
                                    <Autocomplete
                                      label='Rede'
                                      searchDataSource={onSearchTipoRede}
                                      selectedItem={dataBomba.tiposrede}
                                      onSelectItem={(tiposrede) =>
                                        setDataBomba({
                                          ...dataBomba,
                                          tiposrede,
                                          noRede: tiposrede.noTipoRede,
                                        })
                                      }
                                      dataSourceTextProperty='noTipoRede'
                                    />
                                  </div>
                                </div>
                                <div className='row mb-3'>
                                  <div className='col'>
                                    <Textbox
                                      label='Profundidade'
                                      required
                                      text={dataBomba.nrProfundidade}
                                      mask={MaskTypes.DecimalCustom}
                                      onChangedValue={(nrProfundidade) =>
                                        setDataBomba({
                                          ...dataBomba,
                                          nrProfundidade,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='row'>
                        <div className='col' style={{ textAlign: 'center' }}>
                          <Button
                            theme={Theme.Primary}
                            template={Button.Templates.Button}
                            text='Adicionar'
                            onClick={onAdicionarBomba}
                            className='width80'
                          />
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col'>
                          <GridView
                            ref={gridViewBombas}
                            className='table-striped table-hover table-bordered table-sm'
                            dataColumns={columnsbomba}
                            showPagination={false}
                            showSelectSizes={false}
                          />
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col'>
                          <Textarea
                            maxLength={250}
                            label='Observações'
                            text={data.noPocPocoEquipamentoObs}
                            onChangedValue={(noPocPocoEquipamentoObs) => {
                              data.noPocPocoEquipamentoObs = noPocPocoEquipamentoObs;
                              setData({
                                ...data,
                                quadroComando: {
                                  ...quadroComando,
                                  dtInstalacao: dataQuadroComando.dtInstalacao,
                                  nrVoltagem: Number(
                                    tipoVoltagemQuadroComando?.noTipoVoltagem
                                  ),
                                  nrSeqPocQuadroComando:
                                    dataQuadroComandos?.nrSeqPocQuadroComando,
                                  quadroComandos: dataQuadroComandos,
                                  tiposVoltagem: tipoVoltagemQuadroComando,
                                  noFornecimento:
                                    dataQuadroComando.noFornecimento,
                                  noPocPocoEquipamentoObs,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {dataEquipInstalado.flgEquipamentoInstalado === 'TubosCabos' && (
                <div>
                  <div className='row mb-3'>
                    <div className='row mb-3'>
                      <div className='display-8'>Tubos</div>
                    </div>
                    <div className='row'>
                      <div className='card'>
                        <div className='card-body'>
                          <div className='row mb-3'>
                            <div className='col-3'>
                              <div className='col-auto'>
                                <RadioButton
                                  outline
                                  label='Fornecimento'
                                  size={BootstrapSizes.Small}
                                  theme={Theme.Primary}
                                  buttons={Fornecimento}
                                  selectedButton={dataTubo.noFornecimento}
                                  onChange={(noFornecimento) =>
                                    setDataTubo({
                                      ...dataTubo,
                                      noFornecimento,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className='row mb-3'>
                            <div className='col-2'>
                              <DatePicker
                                label='Instalação'
                                text={dataTubo.dtInstalacao}
                                maxLength={10}
                                mask={MaskTypes.Date}
                                onChange={(dtInstalacao) =>
                                  setDataTubo({ ...dataTubo, dtInstalacao })
                                }
                              />
                            </div>
                            <div className='col-2'>
                              <Textbox
                                id='diametroTubulacao'
                                maxLength={10}
                                label='Diâm.Tubul.(pol)'
                                text={dataTubo.noDiametroTubulacao}
                                onChangedValue={(noDiametroTubulacao) =>
                                  setDataTubo({
                                    ...dataTubo,
                                    noDiametroTubulacao,
                                  })
                                }
                                onBlur={async (param1, param2, param3) => {
                                  const obj = {
                                    diametropol: param2,
                                    param1,
                                    param3,
                                  };
                                  const { value } = await getMetricConversion(
                                    obj.diametropol
                                  );
                                  setDataTubo({
                                    ...dataTubo,
                                    noDiametroTubulacaomm: value.toString(),
                                  });
                                }}
                              />
                            </div>
                            <div className='col-2'>
                              <Textbox
                                label='Diâm.Tubul.(mm)'
                                text={dataTubo.noDiametroTubulacaomm ?? ''}
                                readOnly
                              />
                            </div>
                            <div className='col-4'>
                              <Autocomplete
                                label='Tubulação'
                                searchDataSource={onSearchTubos}
                                selectedItem={dataTubo.tubo}
                                onSelectItem={(tubo) => {
                                  setDataTubo({
                                    ...dataTubo,
                                    tubo,
                                    nrSeqPocTubulacaoAducao:
                                      tubo.nrSeqPocTubulacaoAducao,
                                    noDescricao: tubo.noDescricao,
                                  });
                                }}
                                dataSourceTextProperty='noDescricao'
                              />
                            </div>
                            <div className='col-2'>
                              <Textbox
                                label='Metragem'
                                text={dataTubo.nrMetragem}
                                mask={MaskTypes.DecimalCustom}
                                onChangedValue={(nrMetragem) =>
                                  setDataTubo({ ...dataTubo, nrMetragem })
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className='row mb-3'>
                          <div className='col' style={{ textAlign: 'center' }}>
                            <Button
                              theme={Theme.Primary}
                              template={Button.Templates.Button}
                              text='Adicionar'
                              onClick={onAdicionarTubo}
                              className='width80'
                            />
                          </div>
                        </div>

                        <div className='row mb-3'>
                          <div className='col'>
                            <GridView
                              ref={gridViewTubos}
                              className='table-striped table-hover table-bordered table-sm'
                              dataColumns={columnsTubos}
                              showPagination={false}
                              showSelectSizes={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='row mb-3'>
                      <div className='display-8'>Cabos</div>
                    </div>
                    <div className='row'>
                      <div className='card'>
                        <div className='card-body'>
                          <div className='row mb-3'>
                            <div className='col-2'>
                              <DatePicker
                                label='Instalação'
                                text={dataCabo.dtInstalacao}
                                maxLength={10}
                                mask={MaskTypes.Date}
                                onChange={(dtInstalacao) =>
                                  setDataCabo({ ...dataCabo, dtInstalacao })
                                }
                              />
                            </div>
                            <div className='col-4'>
                              <Autocomplete
                                id='tipoCabo'
                                label='Cabo'
                                searchDataSource={onSearchCabos}
                                selectedItem={dataCabo.cabo}
                                onSelectItem={(cabo) => {
                                  setDataCabo({
                                    ...dataCabo,
                                    cabo,
                                    nrSeqPocCabo: cabo.nrSeqPocCabo,
                                    noDescricao: cabo.noDescricao,
                                  });
                                }}
                                dataSourceTextProperty='noDescricao'
                              />
                            </div>
                            <div className='col'>
                              <Textbox
                                label='Metragem'
                                text={dataCabo.nrMetragem}
                                mask={MaskTypes.DecimalCustom}
                                onChangedValue={(nrMetragem) =>
                                  setDataCabo({ ...dataCabo, nrMetragem })
                                }
                              />
                            </div>
                            <div className='col'>
                              <RadioButton
                                outline
                                label='Fornecimento'
                                size={BootstrapSizes.Small}
                                theme={Theme.Primary}
                                buttons={Fornecimento}
                                selectedButton={dataCabo.noFornecimento}
                                onChange={(noFornecimento) =>
                                  setDataCabo({
                                    ...dataCabo,
                                    noFornecimento,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className='row mb-3'>
                          <div className='col' style={{ textAlign: 'center' }}>
                            <Button
                              theme={Theme.Primary}
                              template={Button.Templates.Button}
                              text='Adicionar'
                              onClick={onAdicionarCabo}
                              className='width80'
                            />
                          </div>
                        </div>

                        <div className='row mb-3'>
                          <div className='col'>
                            <GridView
                              ref={gridViewCabos}
                              className='table-striped table-hover table-bordered table-sm'
                              dataColumns={columnsCabos}
                              showPagination={false}
                              showSelectSizes={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default CadastroEquipInstalado;
