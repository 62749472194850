import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Textbox } from 'ui/components';
import {
  getDeParaEncargoConvenio,
  saveDeParaEncargoConvenio,
  deleteDeParaEncargoConvenio,
} from 'core/services/ASS/deParaEncargoConvenio';

import { getPlanoConvenioEncargoAutoComplete } from 'core/services/ASS/planoConvenioConvenioEncargo';
import { getConvenioEncargoAutoComplete } from 'core/services/ASS/convenioEncargo';

export default function DeparaEncargoConvenioItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      const loadData = await getDeParaEncargoConvenio(registryKey);
      setData(loadData);
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveDeParaEncargoConvenio(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteDeParaEncargoConvenio(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchPlanoConvEncargoConv = async (e) => {
    const {
      status,
      message: msg,
      data: planoConvenioEncargos,
    } = await getPlanoConvenioEncargoAutoComplete({
      noPlanoConvEncargo: e,
    });
    if (msg) onSetMessage(status, msg);
    return planoConvenioEncargos;
  };

  const onSearchConvenioEncargo = async (e) => {
    const {
      status,
      message: msg,
      convenioEncargos,
    } = await getConvenioEncargoAutoComplete({ noConvenioEncargo: e });
    if (msg) onSetMessage(status, msg);
    return convenioEncargos;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção - De Para Encargo Convênio'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqDeparaEncargoConvenio > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={200}
            label='De Para - Convênio Encargo'
            text={data.noDeParaEncargoConvenio}
            onChangedValue={(noDeParaEncargoConvenio) =>
              setData({ ...data, noDeParaEncargoConvenio })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={200}
            label='Convênio'
            text={data.convenio?.noConvenio}
            readOnly
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='Convênio - Plano Convênio'
            searchDataSource={onSearchPlanoConvEncargoConv}
            selectedItem={data.planoConvenioEncargo}
            onSelectItem={(planoConvenioEncargo) => {
              setData({
                ...data,
                planoConvenioEncargo,
                nrSeqPlanConvenioEncargo:
                  planoConvenioEncargo.nrSeqPlanoConvenioEncargo,
              });
            }}
            dataSourceTextProperty='noPlanoConvEncargo'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='Convênio - Encargo'
            searchDataSource={onSearchConvenioEncargo}
            selectedItem={data.convenioEncargo}
            onSelectItem={(convenioEncargo) => {
              setData({
                ...data,
                convenioEncargo,
                nrSeqConvenioEncargo: convenioEncargo.nrSeqConvenioEncargo,
              });
            }}
            dataSourceTextProperty='noConvenioEncargo'
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
