import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  Button,
  GridView,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getVendedorComissao,
  saveVendedorComissao,
  saveVendedorComissaoCopia,
} from 'core/services/VEN/vendedorComissao';
import { getEmpresasUsuario } from 'core/services/SEG/empresa';
import { getVendedorAutoComplete } from 'core/services/VEN/vendedor';
import { getProdutoGrupoList } from 'core/services/EST/produtoGrupo';
import { getProdutoSubGrupoList } from 'core/services/EST/produtoSubGrupo';

export default function VendedorComissaoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [dataList, setDataList] = useState([]);
  const [dataListRemove, setDataListRemove] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const gridView = useRef();

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      const { tabela } = await getVendedorComissao(registryKey);
      setDataList(tabela);
      if (gridView && gridView.current) gridView.current.setDataSource(tabela);
      setData({
        ...data,
        vendedor: tabela[0]?.vendedor,
        empresa: tabela[0]?.empresa,
      });

      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const onSave = async () => {
    setLoading(true);
    const obj = {
      VendedorComissaoList: dataList,
      NrSeqsRemove: dataListRemove,
    };
    const { status, message: msg } = await saveVendedorComissao(obj);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      setData({});
      setDataList([]);
      if (gridView && gridView.current) gridView.current.setDataSource([]);
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onSaveCopy = async () => {
    setLoading(true);
    const obj = {
      NrSeqEmpresa: data.empresa.nrSeqEmpresa,
      NrSeqPessoa: data.vendedor.nrSeqPessoa,
      VendedorComissaoList: dataList,
    };
    const { status, message: msg } = await saveVendedorComissaoCopia(obj);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onAdicionar = () => {
    const lista = dataList ?? [];
    const obj = {
      ...data,
      nrSeqEmpresa: data.empresa?.nrSeqEmpresa,
      noProdutoGrupo: data.produtoGrupo.noProdutoGrupo,
      noProdutoSubGrupo: data.produtoSubGrupo.noProdutoSubGrupo,
      nrSeqPessoaVen: data.vendedor?.nrSeqPessoa,
    };
    lista.push(obj);
    setDataList(lista);
    setData({
      ...data,
      produtoGrupo: [],
      produtoSubGrupo: [],
      percentualComissao: '',
      percentualFinal: '',
      percentualInicial: '',
    });
    if (gridView && gridView.current) gridView.current.setDataSource(lista);
  };

  const onSearchVendedor = async (e) => {
    const { status, message: msg, vendedores } = await getVendedorAutoComplete({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return vendedores;
  };

  const onSearchEmpresa = async () => {
    const { status, message: msg, empresas } = await getEmpresasUsuario({});

    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onSearchProdutoGrupo = async (e) => {
    const { produtogrupos } = await getProdutoGrupoList({
      noProdutoGrupo: e,
    });

    return produtogrupos;
  };

  const onSearchProdutoSubGrupo = async (e) => {
    const { produtoSubgrupos } = await getProdutoSubGrupoList({
      noProdutoGrupo: e,
    });

    return produtoSubgrupos;
  };

  const onClickRemoveComissao = async (e, datasource) => {
    const dataProdutosComissao = datasource.filter((produto) => produto !== e);

    const nrseqsremove = datasource.filter((produto) => produto === e);
    dataListRemove.push(nrseqsremove[0].nrSeqVendedorComissao);

    setDataListRemove(dataListRemove);
    if (gridView && gridView.current)
      gridView.current.setDataSource(dataProdutosComissao);

    setDataList(dataProdutosComissao);
  };

  const onClickEditComissao = async (e, datasource) => {
    const dataProdutos = datasource.filter((produto) => produto !== e);

    const dataproduto = datasource.filter((produto) => produto === e);
    const produtoG = {
      nrSeqProdutoGrupo: dataproduto[0].nrSeqProdutoGrupo,
      noProdutoGrupo: dataproduto[0].noProdutoGrupo,
    };
    const produtoSG = {
      nrSeqProdutoSubGrupo: dataproduto[0].nrSeqProdutoSubGrupo,
      noProdutoSubGrupo: dataproduto[0].noProdutoSubGrupo,
    };

    setData({
      ...dataproduto[0],
      produtoGrupo: produtoG,
      produtoSubGrupo: produtoSG,
    });

    setDataList(dataProdutos);

    if (gridView && gridView.current)
      gridView.current.setDataSource(dataProdutos);
  };

  const { id: idSelecao } = PageTypes.Selection;

  const columns = [
    { key: 'nrSeqVendedorComissao', type: GridView.ColumnTypes.Checkbox },
    { key: 'nrSeqPessoaVen', title: 'Vendedor', visible: false },
    { key: 'vendedor.noPessoa', title: 'Vendedor' },
    { key: 'empresa.noPessoa', title: 'Empresa' },
    { key: 'nrSeqEmpresa', title: 'NrSeqEmpresa', visible: false },
    { key: 'nrSeqProdutoGrupo', title: 'NrSeqProdutoGrupo', visible: false },
    { key: 'noProdutoGrupo', title: 'Produto Grupo' },
    {
      key: 'nrSeqProdutoSubGrupo',
      title: 'NrSeqProdutoSubGrupo',
      visible: false,
    },
    { key: 'noProdutoSubGrupo', title: 'Produto Subgrupo' },
    { key: 'percentualInicial', title: 'Percentual Inicial' },
    { key: 'percentualFinal', title: 'Percentual Final' },
    { key: 'percentualComissao', title: 'Percentual Comissão' },
    {
      key: 'nrSeqVendedorComissao',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditComissao(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqVendedorComissao',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveComissao(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Vendedor Comissão'
      loading={loading}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Voltar'
          icon='arrow-circle-left'
          onClick={() => onSelectPage(idSelecao)}
        />
        <ToolbarButtons.Button
          text='Salvar'
          icon={['far', 'save']}
          onClick={() => onSave()}
        />
        <ToolbarButtons.Button
          text='Salvar Cópia'
          icon={['far', 'save']}
          onClick={() => onSaveCopy()}
        />
      </ToolbarButtons>

      <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='Vendedor'
            searchDataSource={onSearchVendedor}
            selectedItem={data.vendedor}
            onSelectItem={(vendedor) =>
              setData({
                ...data,
                vendedor,
                nrSeqPessoa: vendedor.nrSeqPessoa,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col mb-3'>
          <Autocomplete
            label='Empresa'
            searchDataSource={onSearchEmpresa}
            selectedItem={data.empresa}
            onSelectItem={(empresa) =>
              setData({
                ...data,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>

      <div className='card'>
        <div className='card-body'>
          <div className='row'>
            <div className='col'>
              <div className='bg-white'>
                <div className='row'>
                  <div className='col align-self-end'>
                    <div className='row'>
                      <div className='row'>
                        <div className='row mb-3'>
                          <div className='col-6'>
                            <div>
                              <Autocomplete
                                label='Produto Grupo'
                                searchDataSource={onSearchProdutoGrupo}
                                selectedItem={data.produtoGrupo}
                                onSelectItem={(produtoGrupo) => {
                                  setData({
                                    ...data,
                                    produtoGrupo,
                                    nrSeqProdutoGrupo:
                                      produtoGrupo.nrSeqProdutoGrupo,
                                  });
                                }}
                                dataSourceTextProperty='noProdutoGrupo'
                              />
                            </div>
                          </div>
                          <div className='col-6'>
                            <Autocomplete
                              label='Produto Subgrupo'
                              searchDataSource={onSearchProdutoSubGrupo}
                              selectedItem={data.produtoSubGrupo}
                              onSelectItem={(produtoSubGrupo) => {
                                setData({
                                  ...data,
                                  produtoSubGrupo,
                                  nrSeqProdutoSubGrupo:
                                    produtoSubGrupo.nrSeqProdutoSubGrupo,
                                });
                              }}
                              dataSourceTextProperty='noProdutoSubGrupo'
                            />
                          </div>
                        </div>
                        <div className='row mb-3'>
                          <div className='col-4'>
                            <Textbox
                              label='Percentual Inicial'
                              text={data.percentualInicial}
                              mask={MaskTypes.Decimal}
                              onChangedValue={(percentualInicial) =>
                                setData({ ...data, percentualInicial })
                              }
                            />
                          </div>
                          <div className='col-4'>
                            <Textbox
                              label='Percentual Final'
                              text={data.percentualFinal}
                              mask={MaskTypes.Decimal}
                              onChangedValue={(percentualFinal) =>
                                setData({ ...data, percentualFinal })
                              }
                            />
                          </div>
                          <div className='col-4'>
                            <Textbox
                              label='Percentual Comissao'
                              text={data.percentualComissao}
                              mask={MaskTypes.Decimal}
                              onChangedValue={(percentualComissao) =>
                                setData({ ...data, percentualComissao })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-auto'>
                    <div className='widget-square text-white p-4 br-tl-1 br-bl-1'>
                      <Button
                        outline
                        size={BootstrapSizes.Small}
                        theme={Theme.Success}
                        template={Button.Templates.Quick}
                        onClick={onAdicionar}
                        icon='plus'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col'>
          <GridView
            offlineData
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
