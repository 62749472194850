import Pessoa from 'core/models/SEG/pessoa';
import Cliente from 'core/models/FIN/cliente';
import Cidade from 'core/models/SEG/cidade';
import Estado from 'core/models/SEG/estado';
import FreteMercadoria from 'core/models/FRO/freteMercadoria';
import TipoEmbarque from 'core/models/FRO/tipoEmbarque';
import FreteTipoCalculo from 'core/models/FRO/freteTipoCalculo';

export default class AuditoriaFretePreco {
  constructor(jsonObject = {}) {
    this.nrSeqAuditoriaFretePreco = jsonObject.nrSeqAuditoriaFretePreco;
    this.dtAtualizacao = jsonObject.dtAtualizacao;
    this.nrSeqPessoaAtualizacao = jsonObject.nrSeqPessoaAtualizacao;
    this.nrSeqFretePreco = jsonObject.nrSeqFretePreco;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.nrSeqFreteMercadoria = jsonObject.nrSeqFreteMercadoria;
    this.nrSeqCidadeOri = jsonObject.nrSeqCidadeOri;
    this.nrSeqCidadeDest = jsonObject.nrSeqCidadeDest;
    this.nrSeqEstadoOri = jsonObject.nrSeqEstadoOri;
    this.nrSeqEstadoDest = jsonObject.nrSeqEstadoDest;
    this.nrSeqFreteTipoCalculo = jsonObject.nrSeqFreteTipoCalculo;
    this.dtInicio = jsonObject.dtInicio;
    this.dtFim = jsonObject.dtFim;
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.faixaFim = jsonObject.faixaFim;
    this.faixaIni = jsonObject.faixaIni;
    this.vlrCalculado = jsonObject.vlrCalculado;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqTipoEmbarque = jsonObject.nrSeqTipoEmbarque;
    this.vlrInicial = jsonObject.vlrInicial;
    this.vlrFinal = jsonObject.vlrFinal;
    this.vlrPercentual = jsonObject.vlrPercentual;
    this.pessoa = new Pessoa(jsonObject.pessoa ?? {});
    this.pessoaAtualizacao = new Pessoa(jsonObject.pessoaAtualizacao ?? {});
    this.cliente = new Cliente(jsonObject.cliente ?? {});
    this.freteMercadoria = new FreteMercadoria(
      jsonObject.freteMercadoria ?? {}
    );
    this.tipoEmbarque = new TipoEmbarque(jsonObject.tipoEmbarque ?? {});
    this.freteTipoCalculo = new FreteTipoCalculo(
      jsonObject.freteTipoCalculo ?? {}
    );
    this.cidadeOri = new Cidade(jsonObject.cidadeOri ?? {});
    this.cidadeDest = new Cidade(jsonObject.cidadeDest ?? {});
    this.estadoOri = new Estado(jsonObject.estadoOri ?? {});
    this.estadoDest = new Estado(jsonObject.estadoDest ?? {});
  }

  toJson = () => JSON.stringify(this);
}
