export default class PocGranulometrica {
  constructor(jsonObject = {}) {
    this.nrSeqPocGranulometria = jsonObject.nrSeqPocGranulometria;
    this.cdPocGranulometria = jsonObject.cdPocGranulometria;
    this.noPocGranulometria = jsonObject.noPocGranulometria;
    this.flgAtivo = jsonObject.flgAtivo;
  }

  toJson = () => JSON.stringify(this);
}
