import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  ResponseStatus,
  Theme,
  BootstrapSizes,
  PageTypes,
} from 'ui/Helpers/utils';
import { getCategoriaLigacaoAutoComplete } from 'core/services/TEL/categoriaLigacao';
import { getCentroDeCustoAutoComplete } from 'core/services/TEL/centroDeCusto';
import { getTipoChamadaAutoComplete } from 'core/services/TEL/tipoChamada';
import { getGrupoLinhaAutoComplete } from 'core/services/TEL/grupoLinha';
import PanelSelecaoContratos from 'ui/pages/TEL/panelSelecaoContratos';
import { getContaRecursoAutoComplete } from 'core/services/TEL/contaRecurso';
import { getGrupoTipoChamadaAutoComplete } from 'core/services/TEL/grupoTipoChamada';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getDetalhado,
  getResumo,
  deleteContasChamadas,
  cancelDelete,
  printContaChamada,
  excelContaChamada,
} from 'core/services/TEL/contaChamada';
import { getPlanoAutoComplete } from 'core/services/TEL/plano';

import {
  Autocomplete,
  CSDSelPage,
  GridView,
  DropdownMulti,
  RadioButton,
  Textbox,
  DropdownButton,
  ToolbarButtons,
  Switch,
} from 'ui/components';

import PanelResumo from './panelResumo';
import ModalInluirLinhasGrupo from '../ResumoSintetico/modalInluirLinhasGrupo';

const mathOperators = [
  {
    icon: ' ',
    text: '',
    value: '',
    showTextOnSelect: false,
  },
  {
    icon: 'greater-than-equal',
    text: 'Maior igual que',
    value: '>=',
    showTextOnSelect: false,
  },
  {
    icon: 'less-than-equal',
    text: 'Menor igual que',
    value: '<=',
    showTextOnSelect: false,
  },
  { icon: 'equals', text: 'Igual', value: '=', showTextOnSelect: false },
  {
    icon: 'not-equal',
    text: 'Diferente',
    value: '<>',
    showTextOnSelect: false,
  },
];

const FilterTextOperator = ({
  label,
  text,
  onChangedValue,
  onSelectItem,
  filterProp,
}) => {
  const selected =
    mathOperators.find((item) => item.value === filterProp) ?? mathOperators[0];
  return (
    <Textbox
      label={label}
      text={text}
      mask={MaskTypes.Decimal}
      showClear
      onChangedValue={(e) => onChangedValue(e, selected.value)}
      prependRender={
        <DropdownButton
          dropdownItems={mathOperators}
          selectedItem={selected}
          onSelectItem={onSelectItem}
        />
      }
    />
  );
};

export default function ContaChamada({
  isActive,
  registryKey,
  reload,
  onOpenPage,
  transaction,
  onOpenReport,
}) {
  const ordenarPor = [
    { text: 'Data, Hora', value: 'dataHora' },
    { text: 'Número Telefone', value: 'numeroTelefone' },
    { text: 'Tipo de Chamada', value: 'tipoChamada' },
    { text: 'Valor', value: 'valor' },
  ];
  const [selectedRecursos, setSelectedRecursos] = useState([]);
  const [modalShow, setModalShow] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [resume, setResume] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    ordenarPor: 'dataHora',
  });
  const contratosativos = true;
  const [normal, setNormal] = useState({});
  const gridView = useRef();
  const dropDownCentrosCusto = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchTipoChamada = async (e) => {
    const {
      status,
      message: msg,
      tiposchamadas,
    } = await getTipoChamadaAutoComplete({
      noTipoChamada: e,
      nrSeqContaCabecalho: filters.nrSeqContaCabecalho,
      nrSeqContratoAgrupado: filters.nrSeqContratoAgrupado,
      flgTipoChamadaVinculadoFatura: filters.flgTipoChamadaVinculadoFatura,
    });
    onSetMessage(status, msg);
    return tiposchamadas;
  };

  const onSearchGrupoLinhas = async (e) => {
    const {
      status,
      message: msg,
      gruposLinhas,
    } = await getGrupoLinhaAutoComplete({
      noDescricao: e,
    });
    onSetMessage(status, msg);
    return gruposLinhas;
  };

  const searchCentroDeCusto = async () => {
    const {
      status,
      message: msg,
      data: centros,
    } = await getCentroDeCustoAutoComplete({});

    if (msg) onSetMessage(status, msg);

    return centros;
  };

  const onSearchGrupoTipoChamada = async (e) => {
    const {
      status,
      message: msg,
      grupos,
    } = await getGrupoTipoChamadaAutoComplete({
      noGrupoTipoChamada: e,
    });
    onSetMessage(status, msg);
    return grupos;
  };

  const onSearchTipoLigacao = async () => {
    const {
      status,
      message: msg,
      categorias,
    } = await getCategoriaLigacaoAutoComplete({});
    onSetMessage(status, msg);
    return categorias;
  };

  const onSearchContaRecurso = async (e) => {
    const {
      status,
      message: msg,
      contaRecursos,
    } = await getContaRecursoAutoComplete({
      nrPrefixoLinha: e,
      nrSeqContaCabecalho: filters.nrSeqContaCabecalho,
      nrSeqContratoAgrupado: filters.nrSeqContratoAgrupado,
    });

    onSetMessage(status, msg);

    return contaRecursos;
  };
  const onSearch = async (filterPage) => {
    onSetMessage();
    setLoading(true);

    filters.flgResumo = false;
    if (filterPage) {
      filters.page = filterPage.page;
      filters.totalByPage = filterPage.totalByPage;
      filters.sortBy = filterPage.sortBy;
    }

    const { status, message: msg, chamadas, pagination } = await getDetalhado(
      filters
    );

    filters.flgResumo = true;
    const { chamadas: resume_ } = await getResumo(filters);
    setResume(resume_[0]);

    onSetMessage(status, msg);

    if (gridView && gridView.current)
      gridView.current.setDataSource(chamadas, pagination);

    setLoading(false);
  };
  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const { contaRecursos } = await getContaRecursoAutoComplete({
        nrSeqContaRecurso: registryKey,
        flgIncluirContratoFatura: true,
      });
      filters.nrSeqContaRecurso = contaRecursos[0].nrSeqContaRecurso;
      filters.nrSeqContaCabecalho = contaRecursos[0].nrSeqContaCabecalho;

      setSelectedItems({
        ...selectedItems,
        fatura: contaRecursos[0].contaCabecalho,
        contratoAgrupado: contaRecursos[0].contaCabecalho.clienteContaCabecalho,
        contrato: contaRecursos[0].contaCabecalho.clienteContaCabecalho,
      });

      setFilters({
        ...filters,
        nrSeqContaCabecalho: contaRecursos[0].nrSeqContaCabecalho,
        nrSeqContaRecurso: contaRecursos[0].nrSeqContaRecurso,
        contaRecurso: contaRecursos[0],
      });
      onSearch();
      setLoading(false);

      setNormal(true);
    }
  }, [registryKey]);

  const onSearchPlano = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoComplete({
      noPlano: e,
    });
    onSetMessage(status, msg);
    return planos;
  };

  const onPageChange = async (page) => {
    setFilters({ ...filters, page });

    await onSearch({ ...filters, page });
  };
  const onColumnSort = async (sortBy) => {
    setFilters({ ...filters, sortBy });
    await onSearch({ ...filters, sortBy });
  };
  const onPageSizeChange = async (totalByPage) => {
    setFilters({ ...filters, totalByPage });
    await onSearch({ ...filters, totalByPage });
  };

  const onCancelDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await cancelDelete(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      onSetMessage(status, msg);

      await onSearch();
    } else {
      setLoading(false);
      setMessage({
        message:
          'Não há registros selecionados para para cancelamento de exclusão',
        theme: Theme.Danger,
      });
    }
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteContasChamadas(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      onSetMessage(status, msg);

      await onSearch();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onOpenIncluirLinhasGrupo = () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Nenhuma linha selecionada para adicionar a grupo de linhas.'
      );
    else {
      const recursos = selecteds.map((column) => ({
        nrSeqContaRecurso: column.find(
          (prop) => prop.key === 'nrSeqContaRecurso'
        ).value,
      }));

      setSelectedRecursos(recursos);
      setModalShow({ ...modalShow, incluirLinhasGrupo: true });
    }
  };

  const onOpenMaintenance = (reg = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ContaChamada/manutencao',
      PageTypes.Maintenence,
      reg
    );
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqContaChamada = sourceRow.find(
      (e) => e.key === 'nrSeqContaChamada'
    );
    onOpenMaintenance(nrSeqContaChamada.value);
  };

  const onPrint = async () => {
    setLoading(true);

    filters.flgResumo = false;
    const { value } = await printContaChamada(filters);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onExcelChamada = async () => {
    setLoading(true);
    filters.flgResumo = false;
    const { value, status, message: msg } = await excelContaChamada(filters);
    onSetMessage(status, msg);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `ContaChamada.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const columns = [
    {
      key: 'nrSeqContaChamada',
      type: GridView.ColumnTypes.Checkbox,
      sortable: true,
    },
    {
      key: 'cdContratoDtFatura',
      title: 'Contrato / Fatura',
      visible: false,
      sortable: true,
    },
    { key: 'nrOrigemComp', title: 'Nr. Origem', sortable: true },
    {
      key: 'dtHoraChamada',
      title: 'Data',
      format: GridView.DataTypes.DateTime,
      sortable: true,
    },
    { key: 'noCategoriaFEB', title: 'Cat. FEB', sortable: true },
    { key: 'noCategoriaXLS', title: 'Cat. XLS', sortable: true },
    { key: 'noTipoXLS', title: 'TipoXLS', sortable: true },
    { key: 'noLocalidadeChamadaXLS', title: 'Localidade', sortable: true },
    { key: 'areaChamada.noAreaChamada', title: 'Area', sortable: true },
    { key: 'tipoChamada.noTipoChamada', title: 'Tipo Chamada', sortable: true },
    { key: 'numeroDestinoCompleto', title: 'Nr. Destino', sortable: true },
    { key: 'duracaoXLS', title: 'Duração', sortable: true },
    {
      key: 'vlrMinuto',
      title: 'Vlr. Min.',
      format: GridView.DataTypes.Decimal,
      sortable: true,
    },
    {
      key: 'vlrChamadaXLS',
      title: 'Vlr. Cham.',
      format: GridView.DataTypes.Decimal,
      sortable: true,
    },
    {
      key: 'vlrCobradoXLS',
      title: 'Vlr. Cobr.',
      format: GridView.DataTypes.Decimal,
      sortable: true,
    },
    {
      key: 'vlrCobradoRecalculo',
      title: 'Vlr. Rec',
      format: GridView.DataTypes.Decimal,
      sortable: true,
    },
    {
      key: 'vlrRateioEmpresa',
      title: 'Vlr. Rateio',
      format: GridView.DataTypes.Decimal,
      visible: false,
      sortable: true,
    },
    {
      key: 'categoriaLigacao.noCategoriaLigacao',
      title: 'Cat. Ligação',
      visible: false,
      sortable: true,
    },
    {
      key: 'nrSeqContaRecurso',
      title: 'nrSeqContaRecurso',
      visible: false,
      sortable: true,
    },
  ];

  useEffect(() => {
    dropDownCentrosCusto.current.loadDataSource(searchCentroDeCusto);
  }, []);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  return (
    <CSDSelPage
      isActive={isActive}
      title='Relatório Conta Chamada'
      loading={loading}
      onSearch={() => onSearch()}
      onDelete={onDelete}
      onPrint={() => onPrint()}
      onNew={onOpenMaintenance}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Excel'
          icon={['far', 'file-excel']}
          onClick={() => onExcelChamada()}
        />

        <ToolbarButtons.Button
          text='Cancelar Exclusão Lógica'
          icon='trash-alt'
          onClick={() => onCancelDelete()}
        />

        <ToolbarButtons.Button
          text='Incluir Linhas Grupo'
          icon='sync-alt'
          onClick={onOpenIncluirLinhasGrupo}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col-8'>
          <div className='row mb-3'>
            <div className='col-12'>
              <PanelSelecaoContratos
                justActives={contratosativos}
                normal={normal}
                contratoAgrupado={selectedItems.contratoAgrupado}
                onContratoAgrupadoChange={(contratoAgrupado) => {
                  setSelectedItems({ ...selectedItems, contratoAgrupado });
                  setFilters({
                    ...filters,
                    nrSeqContratoAgrupado:
                      contratoAgrupado.nrSeqContratoAgrupado,
                  });
                }}
                contrato={selectedItems.contratoAgrupado}
                onContratoChange={(contrato) => {
                  setSelectedItems({ ...selectedItems, contrato });
                }}
                fatura={selectedItems.fatura}
                onFaturaChange={(fatura) => {
                  setSelectedItems({ ...selectedItems, fatura });
                  setFilters({
                    ...filters,
                    nrSeqContaCabecalho: fatura.nrSeqContaCabecalho,
                  });
                }}
                onMessage={(msg, theme) => setMessage({ message: msg, theme })}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-6'>
              <Autocomplete
                label='Tipo Chamada'
                searchDataSource={onSearchTipoChamada}
                selectedItem={filters.tipoChamada}
                onSelectItem={(tipoChamada) => {
                  setFilters({
                    ...filters,
                    tipoChamada,
                    nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                  });
                }}
                dataSourceTextProperty='noTipoChamada'
              />
            </div>

            <div className='col-6'>
              <Autocomplete
                label='Grupo Linhas'
                searchDataSource={onSearchGrupoLinhas}
                selectedItem={filters.grupoLinha}
                onSelectItem={(grupoLinha) =>
                  setFilters({
                    ...filters,
                    grupoLinha,
                    nrSeqGrupoLinha: grupoLinha.nrSeqGrupoLinha,
                  })
                }
                dataSourceTextProperty='noDescricao'
              />
            </div>
          </div>
          <div className='row mb-6'>
            <div className='col-4'>
              <DropdownMulti
                label='Centro de Custos'
                ref={dropDownCentrosCusto}
                dataSourcePropertyText='noCentroDeCusto'
                dataSourcePropertyValue='nrSeqCentroDeCusto'
                selectedItems={selectedItems.centrosdecustos ?? []}
                onSelectItem={(centrosdecustos) => {
                  setSelectedItems({ ...selectedItems, centrosdecustos });
                  setFilters({
                    ...filters,
                    nrSeqCentrosDeCustos: centrosdecustos?.map(
                      (p) => p.nrSeqCentroDeCusto
                    ),
                  });
                }}
              />
            </div>

            <div className='col-4'>
              <Autocomplete
                label='Grupo Tipo Chamada'
                searchDataSource={onSearchGrupoTipoChamada}
                selectedItem={filters.grupoTipoChamada}
                onSelectItem={(grupoTipoChamada) => {
                  setFilters({
                    ...filters,
                    grupoTipoChamada,
                    nrSeqGrupoTipoChamada:
                      grupoTipoChamada.nrSeqGrupoTipoChamada,
                  });
                }}
                dataSourceTextProperty='noGrupoTipoChamada'
              />
            </div>

            <div className='col-4'>
              <Autocomplete
                label='Tipo Ligação'
                searchDataSource={onSearchTipoLigacao}
                selectedItem={filters.categoriaLigacao}
                onSelectItem={(categoriaLigacao) => {
                  setFilters({
                    ...filters,
                    categoriaLigacao,
                    nrSeqCategoriaLigacao:
                      categoriaLigacao.nrSeqCategoriaLigacao,
                  });
                }}
                dataSourceTextProperty='noCategoriaLigacao'
              />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-6'>
              <Autocomplete
                label='Prefixo / Número:'
                searchDataSource={onSearchContaRecurso}
                selectedItem={filters.contaRecurso}
                onSelectItem={(contaRecurso) =>
                  setFilters({
                    ...filters,
                    contaRecurso,
                    nrSeqContaRecurso: contaRecurso.nrSeqContaRecurso,
                  })
                }
                dataSourceTextProperty='linhaFormatada'
              />
            </div>

            <div className='col-6'>
              <Autocomplete
                label='Plano'
                searchDataSource={onSearchPlano}
                selectedItem={filters.plano}
                onSelectItem={(plano) =>
                  setFilters({
                    ...filters,
                    plano,
                    nrSeqPlano: plano.nrSeqPlano,
                  })
                }
                dataSourceTextProperty='noPlano'
              />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-3'>
              <Textbox
                label='Fatura de:'
                text={filters.dtFaturaIni}
                maxLength={10}
                mask={MaskTypes.Date}
                onChangedValue={(dtFaturaIni) =>
                  setFilters({
                    ...filters,
                    dtFaturaIni,
                  })
                }
              />
            </div>

            <div className='col-3'>
              <Textbox
                label='até:'
                text={filters.dtFaturaFim}
                maxLength={10}
                mask={MaskTypes.Date}
                onChangedValue={(dtFaturaFim) =>
                  setFilters({ ...filters, dtFaturaFim })
                }
              />
            </div>

            <div className='col-6'>
              <RadioButton
                label='Ordenação:'
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={filters.ordenarPor}
                buttons={ordenarPor}
                onChange={(ordenarPorchange) =>
                  setFilters({
                    ...filters,
                    ordenarPor: ordenarPorchange,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className='col-4'>
          <div className='row mb-3'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <div className='display-8 mb-2'>Filtros Adicionais:</div>
                  <div className='row mb-2'>
                    <div className='col'>
                      <FilterTextOperator
                        label='Valor Chamada'
                        text={filters.vlrChamadaFiltroAdicional}
                        onChangedValue={(e) =>
                          setFilters({
                            ...filters,
                            vlrChamadaFiltroAdicional: e,
                          })
                        }
                        filterProp={filters.noFiltroAdicionalOperacaoVlrChamada}
                        onSelectItem={(item) =>
                          setFilters({
                            ...filters,
                            noFiltroAdicionalOperacaoVlrChamada: item.value,
                            vlrChamadaFiltroAdicional:
                              filters.vlrChamadaFiltroAdicional ?? 0,
                          })
                        }
                      />
                    </div>
                    <div className='col'>
                      <FilterTextOperator
                        label='Valor Cobrado'
                        text={filters.vlrCobradoFiltroAdicional}
                        onChangedValue={(e) =>
                          setFilters({
                            ...filters,
                            vlrCobradoFiltroAdicional: e,
                          })
                        }
                        filterProp={filters.noFiltroAdicionalOperacaoVlrCobrado}
                        onSelectItem={(item) =>
                          setFilters({
                            ...filters,
                            noFiltroAdicionalOperacaoVlrCobrado: item.value,
                            vlrCobradoFiltroAdicional:
                              filters.vlrCobradoFiltroAdicional ?? 0,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className='row mb-2'>
                    <div className='col'>
                      <FilterTextOperator
                        label='Valor Recalculo:'
                        text={filters.vlrRecalculoFiltroAdicional}
                        onChangedValue={(e) =>
                          setFilters({
                            ...filters,
                            vlrRecalculoFiltroAdicional: e,
                          })
                        }
                        filterProp={
                          filters.noFiltroAdicionalOperacaoVlrRecalculo
                        }
                        onSelectItem={(item) =>
                          setFilters({
                            ...filters,
                            noFiltroAdicionalOperacaoVlrRecalculo: item.value,
                            vlrRecalculoFiltroAdicional:
                              filters.vlrRecalculoFiltroAdicional ?? 0,
                          })
                        }
                      />
                    </div>
                    <div className='col'>
                      <FilterTextOperator
                        label='Valor do Minuto'
                        text={filters.vlrMinutoFiltroAdicional}
                        onChangedValue={(e) =>
                          setFilters({
                            ...filters,
                            vlrMinutoFiltroAdicional: e,
                          })
                        }
                        filterProp={filters.noFiltroAdicionalOperacaoVlrMinuto}
                        onSelectItem={(item) =>
                          setFilters({
                            ...filters,
                            noFiltroAdicionalOperacaoVlrMinuto: item.value,
                            vlrMinutoFiltroAdicional:
                              filters.vlrMinutoFiltroAdicional ?? 0,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <div className='display-8 mb-2'>Filtros Especiais:</div>
                  <div className='row mb-2'>
                    <div className='col'>
                      <Switch
                        formControl
                        label='Reg. classif. em branco'
                        onChange={(flgSemVinculo) =>
                          setFilters({
                            ...filters,
                            flgSemVinculo,
                          })
                        }
                      />
                    </div>
                    <div className='col'>
                      <Switch
                        formControl
                        label='Tipo Cham. Vinc. fatura'
                        onChange={(flgTipoChamadaVinculadoFatura) =>
                          setFilters({
                            ...filters,
                            flgTipoChamadaVinculadoFatura,
                          })
                        }
                      />
                    </div>

                    <div className='col'>
                      <Switch
                        formControl
                        label='Exclusão. Lógica'
                        onChange={(flgExclusaoLogica) =>
                          setFilters({
                            ...filters,
                            flgExclusaoLogica,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-2'>
        <div className='col'>
          <PanelResumo chamada={resume} />
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            canControlVisibility
            onPageChange={onPageChange}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
      <ModalInluirLinhasGrupo
        show={modalShow.incluirLinhasGrupo}
        recursos={selectedRecursos}
        onClose={() =>
          setModalShow({ ...modalShow, incluirLinhasGrupo: false })
        }
        onSave={(status, msg) => onSetMessage(status, msg)}
      />
    </CSDSelPage>
  );
}
