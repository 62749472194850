import qs from 'qs';
import Pagination from 'core/models/pagination';
import Funcionario from 'core/models/SEG/funcionario';
import api from '../api';

const baseURL = `/SEG/Funcionario`;

export const getFuncionarioList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Funcionario(json)) ?? [],
  };
};

export const getFuncionarioPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Funcionario(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getFuncionario = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Funcionario(value) : {};
};

export const saveFuncionario = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteFuncionario = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printFuncionarios = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelFuncionario = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllFuncionario = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getFuncionarioAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    funcionarios: value?.map((json) => new Funcionario(json)) ?? [],
  };
};

export const printFuncionario = async (filters) => {
  const response = await api.get(`${baseURL}/PrintFuncionario`, {
    params: filters,
  });
  return response.data ?? {};
};
