import React, {
  forwardRef,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { GridView, FileUpload, Autocomplete } from 'ui/components';
import { Theme, BootstrapSizes, MimeTypes } from 'ui/Helpers/utils';

import {
  deletePocPocoDocumento,
  getPocPocoDocumentoPagined,
  uploadDocumento,
} from 'core/services/POC/pocPocoDocumento';

import { getPocPocoTipoDocumentoAutoComplete } from 'core/services/POC/pocPocoTipoDocumento';
import PocPocoDocumento from 'core/models/POC/pocPocoDocumento';
import PocPocoTipoDocumento from 'core/models/POC/pocPocoTipoDocumento';

const CadastroDocumentosPoco = forwardRef(({ data, setData }) => {
  const gridView = useRef();

  const [importedFile, setImportedFile] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const [dataTipoDocumento, setDataTipoDocumento] = useState(
    new PocPocoTipoDocumento({
      noPocPocoTipoDocumento: 'Autorização Prévia',
      nrSeqPocPocoTipoDocumento: 1,
    })
  );
  const load = useCallback(async () => {
    const obj = { nrSeqPocPoco: data.nrSeqPocPoco };
    const listaDocumentos = await getPocPocoDocumentoPagined(obj);
    setDocumentos(listaDocumentos.data);
    if (gridView && gridView.current)
      gridView.current.setDataSource(listaDocumentos.data);
  }, []);

  const onSearchTipoDocumento = async (e) => {
    const { data: tipos } = await getPocPocoTipoDocumentoAutoComplete({
      noPocPocoTipoDocumento: e,
    });

    return tipos;
  };

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, []);

  useEffect(async () => {
    if (importedFile.length > 0) {
      await uploadDocumento(
        importedFile[0],
        data.nrSeqPocPoco,
        dataTipoDocumento.nrSeqPocPocoTipoDocumento
      );

      const documento = new PocPocoDocumento({
        noArquivo: importedFile[0].name,
        noTipoArquivo: importedFile[0].type,
        noTamanho: importedFile[0].size,
        flgExibeDownload: false,
      });

      const lista = [];
      lista.push(documento);
      const obj = { nrSeqPocPoco: data.nrSeqPocPoco };
      const listaDocumentos = await getPocPocoDocumentoPagined(obj);
      setDocumentos(documentos);
      if (gridView && gridView.current)
        gridView.current.setDataSource(listaDocumentos.data);
    }
  }, [importedFile]);

  const onClickDownload = async (e) => {
    const linkSource = `data:image/png;base64, ${e.noImagem}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = e.noArquivo;
    downloadLink.click();
  };

  const onRemoveDocumento = async (e, datasource) => {
    const docS = datasource.filter((doc) => doc !== e);

    setDocumentos(docS);
    if (gridView && gridView.current) gridView.current.setDataSource(docS);

    setData({
      ...data,
    });
    deletePocPocoDocumento(e.nrSeqPocPocoDocumento);
  };

  const columns = [
    { key: 'noArquivo', title: 'Documento' },
    { key: 'tipoDocumento.noPocPocoTipoDocumento', title: 'Tipo Documento' },
    {
      key: 'nrSeqPocPocoDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },
    {
      key: 'nrSeqPocPocoDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <div>
      <div className='row mb-3'>
        <div className='display-8'>11 - Documentos Anexados</div>
      </div>
      <div className='row  mb-3'>
        <div className='col'>
          <div className='card'>
            <div className='card-body'>
              <div className='row mb-3'>
                <div className='col-6'>
                  <Autocomplete
                    label='Tipo Documento'
                    searchDataSource={onSearchTipoDocumento}
                    selectedItem={dataTipoDocumento}
                    onSelectItem={(tipoDocumento) =>
                      setDataTipoDocumento(tipoDocumento)
                    }
                    dataSourceTextProperty='noPocPocoTipoDocumento'
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col'>
                  <FileUpload
                    files={importedFile}
                    onChange={(files) => setImportedFile(files)}
                    allowedMimeTypes={[
                      MimeTypes.Types.PDF,
                      MimeTypes.Types.Image,
                      MimeTypes.Types.Word,
                    ]}
                  />
                </div>
              </div>
              <div className='row mb-3' style={{ paddingBottom: '450px' }}>
                <div className='col'>
                  <GridView
                    ref={gridView}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default CadastroDocumentosPoco;
