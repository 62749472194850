import React, { useEffect, useRef, useState } from 'react';
import { PageTypes } from 'ui/Helpers/utils';

import { CSDSelPage, GridView, Textbox } from 'ui/components';

import { getPerfilPagined } from 'core/services/SEG/perfil';
import { excelPermissao } from 'core/services/SEG/permissao';

const columns = [
  { key: 'nrSeqPerfil', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdPerfil', title: 'Código Perfil' },
  { key: 'noPerfil', title: 'Perfil' },
];

export default function Permissao({ transaction, onOpenPage, isActive }) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getPerfilPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);
    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getPerfilPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Permissao/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqPerfil = sourceRow.find((e) => e.key === 'nrSeqPerfil');
    onOpenMaintenance(nrSeqPerfil.value);
  };

  const onDownloadExcel = async () => {
    setLoading(true);

    const { value: base64 } = await excelPermissao(filtros);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'Permissao.csv';
      downloadLink.click();
    }

    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Permissao'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDownloadExcel={onDownloadExcel}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-6'>
          <Textbox
            maxLength={60}
            label='Perfil'
            text={filtros.noPerfil}
            onChangedValue={(noPerfil) => setFiltros({ ...filtros, noPerfil })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
