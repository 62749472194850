import { Empresa } from '../SEG';
import Aparelho from './aparelho';
import PedidoAparelhoAparelho from './pedidoAparelhoAparelho';
import Pedidoaparelhomodelo from './pedidoAparelhoModelo';

export default class PedidoAparelho {
  constructor(jsonObject = {}) {
    this.nrSeqPedidoAparelho = jsonObject.nrSeqPedidoAparelho;
    this.nrSeqAparelho = jsonObject.nrSeqAparelho;
    this.nrAditivo = jsonObject.nrAditivo;
    this.nrCtPrincipal = jsonObject.nrCtPrincipal;
    this.flgStatus = jsonObject.flgStatus;
    this.flgStatusStr = jsonObject.flgStatusStr;
    this.dtPedido = jsonObject.dtPedido;
    this.dtEntrega = jsonObject.dtEntrega;
    this.noTituloPedido = jsonObject.noTituloPedido;
    this.noDescricaoPedido = jsonObject.noDescricaoPedido;
    this.vlrTotalPedido = jsonObject.vlrTotalPedido;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.pedidoAparelhoModelo = (jsonObject.pedidoAparelhoModelo ?? []).map(
      (pedidoAparelhoModelos) => new Pedidoaparelhomodelo(pedidoAparelhoModelos)
    );
    this.aparelhos = (jsonObject.aparelhos ?? []).map(
      (aparelho) => new Aparelho(aparelho)
    );
    this.pedidoaparelhoAparelho = (jsonObject.aparelho ?? []).map(
      (pedidoaparelhoAparelho) =>
        new PedidoAparelhoAparelho(pedidoaparelhoAparelho)
    );
  }

  toJson = () => JSON.stringify(this);
}
