import React, { useState, useEffect } from 'react';
import { getResumoCustosLinha } from 'core/services/TEL';
import { Theme, ResponseStatus } from 'ui/Helpers/utils';
import { mask, MaskTypes } from 'ui/Helpers/masks';
import {
  Button,
  FontAwesomeIcon,
  Loader,
  Modal,
  Notification,
} from 'ui/components';

export default function ModalResumoCustosLinha({ recurso, onClose }) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [linha, setLinha] = useState('');
  const [messageState, setMessage] = useState(null);
  const [resumoCustos, setResumoCustos] = useState([]);

  useEffect(() => {
    async function buscarResumos() {
      const { numeroCompleto, nrSeqContaRecurso } = recurso;
      if (nrSeqContaRecurso) {
        setLinha(numeroCompleto);
        setShow(true);
        setLoading(true);
        const { status, message, custos } = await getResumoCustosLinha({
          nrSeqContaRecurso,
        });

        if (message) {
          setMessage({
            message,
            theme:
              status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
          });
        } else {
          setResumoCustos(custos);
        }
        setLoading(false);
      } else setShow(false);
    }
    setResumoCustos([]);
    buscarResumos();
  }, [recurso]);

  return (
    <Modal
      show={show}
      title='Resumo de Custos Original e Recalculado'
      onClose={onClose}
    >
      <Modal.Body className='bg-light'>
        <Loader loading={loading} />
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col-auto d-flex align-items-center'>
            <div className='widget-square bg-gradient-primary text-white p-3 rounded-2'>
              <FontAwesomeIcon className='fs-1' icon='phone' />
            </div>
          </div>
          <div className='col d-flex align-items-center'>
            <div className='fw-bold text-primary'>
              <div>Linha:</div>
              <span className='display-7 fw-bold'>{linha}</span>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <table className='table table-sm table-striped'>
              <thead className='table-primary'>
                <tr>
                  <th className='text-center'>Tipo Chamada</th>
                  <th className='text-center'>Total Original</th>
                  <th className='text-center'>Total Recalculado</th>
                  <th className='text-center'>Total Rateio</th>
                </tr>
              </thead>
              <tbody>
                {(resumoCustos ?? []).map((p) => (
                  <tr key={p.noTipoChamada}>
                    <td>{p.noTipoChamada}</td>
                    <td align='right'>
                      {mask(p.vlrCobradoXLS, MaskTypes.Currency)}
                    </td>
                    <td align='right'>
                      {mask(p.vlrCobradoRecalculo, MaskTypes.Currency)}
                    </td>

                    <td align='right'>
                      {mask(p.vlrRateioEmpresa, MaskTypes.Currency)}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot className='table-primary'>
                <tr>
                  <th className='text-end'>Total</th>
                  <th className='text-end'>
                    {mask(
                      resumoCustos?.reduce((a, b) => a + b.vlrCobradoXLS, 0),
                      MaskTypes.Currency
                    )}
                  </th>
                  <th className='text-end'>
                    {mask(
                      resumoCustos?.reduce(
                        (a, b) => a + b.vlrCobradoRecalculo,
                        0
                      ) ?? 0,
                      MaskTypes.Currency
                    )}
                  </th>
                  <th className='text-end'>
                    {mask(
                      resumoCustos?.reduce(
                        (a, b) => a + b.vlrRateioEmpresa,
                        0
                      ) ?? 0,
                      MaskTypes.Currency
                    )}
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          theme={Theme.Primary}
          icon='times'
          text='Fechar'
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
