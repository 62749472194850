import React, {
  forwardRef,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {
  GridView,
  Textbox,
  Autocomplete,
  Button,
  RadioButton,
  Textarea,
} from 'ui/components';
import { Theme, BootstrapSizes } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import { getFiltroAutoComplete } from 'core/services/POC/tipoFiltro';
import { getMaterialRevestimentoAutoComplete } from 'core/services/POC/materialRevestimento';
import { sortPocPocoRevestimento } from 'core/services/POC/pocPocoRevestimento';
import { getMetricConversion } from 'core/services/POC/pocPocoPerfuracao';
import PocPocoRevestimento from 'core/models/POC/pocPocoRevestimento';

const TipoRevestimento = [
  { text: 'Tubo', value: 'R' },
  { text: 'Filtro', value: 'F' },
];

const CadastroRevestimentoFiltro = forwardRef(
  ({
    data,
    setData,
    onInicioFimInvalido,
    onValidaCampoObrigatorioRevestimento,
  }) => {
    const gridView = useRef();
    const [dataRevestimento, setDataRevestimento] = useState(
      new PocPocoRevestimento({ flgTipoObjeto: 'R', nrInicio: 0 })
    );
    const [totalRevestimento, setTotalRevestimento] = useState();
    const [totalFiltro, setTotalFiltro] = useState();

    const totalRevestimentos = (lista) => {
      const newlist = lista.filter(
        (e) => e.status !== 'Remover' && e.flgTipoObjeto !== 'F'
      );
      const nrFins = newlist.map((e) => e.nrFim - e.nrInicio);
      let total = 0;
      if (nrFins.length > 0) {
        total = nrFins.reduce((a, b) => a + b, 0).toFixed(2);
        setTotalRevestimento(total.replace('.', ','));
      } else {
        setTotalRevestimento('0,00');
      }
    };

    const totalFiltros = (lista) => {
      const newlist = lista.filter(
        (e) => e.status !== 'Remover' && e.flgTipoObjeto !== 'R'
      );
      const nrFins = newlist.map((e) => e.nrFim - e.nrInicio);
      let total = 0;
      if (nrFins.length > 0) {
        total = nrFins.reduce((a, b) => a + b, 0).toFixed(2);
        setTotalFiltro(total.replace('.', ','));
      } else {
        setTotalFiltro('0,00');
      }
    };

    const onSearchMaterialRevestimento = async (e) => {
      const {
        materialRevestimento,
      } = await getMaterialRevestimentoAutoComplete({
        noPocMaterialRevestimento: e,
      });
      return materialRevestimento;
    };

    const onSearchFiltro = async (e) => {
      const { pocFiltros } = await getFiltroAutoComplete({
        noPocTipoFiltro: e,
      });
      return pocFiltros;
    };

    const load = useCallback(async () => {
      if (data.revestimentos?.length > 1) {
        const listaOrdenada = { revestimentos: data.revestimentos };
        const novalistaordenada = await sortPocPocoRevestimento(listaOrdenada);
        if (gridView && gridView.current)
          gridView.current.setDataSource(novalistaordenada.listaRevestimento);
      } else {
        gridView.current.setDataSource(data.revestimentos);
      }

      totalRevestimentos(
        data?.revestimentos.length > 0 ? data.revestimentos : []
      );
      totalFiltros(data?.revestimentos.length > 0 ? data.revestimentos : []);
    }, []);

    useEffect(() => {
      (async function func() {
        await load();
      })();
    }, []);

    const onAdicionar = async () => {
      if (
        onInicioFimInvalido(dataRevestimento.nrInicio, dataRevestimento.nrFim)
      )
        return;
      if (!onValidaCampoObrigatorioRevestimento(dataRevestimento)) return;

      const listaRevestimento = gridView.current.getDataSource() ?? [];

      const obj = new PocPocoRevestimento({
        ...dataRevestimento,
        nrSeqPocPoco: dataRevestimento.nrSeqPocPoco,
        status: dataRevestimento.status ?? GridView.EnumStatus.Inserir,
      });

      const { value } = await getMetricConversion(obj.nrDiametropol);

      obj.nrDiametromm = value;

      listaRevestimento.push(obj);

      if (listaRevestimento?.length > 1) {
        const listOrdenada = {
          revestimentos: listaRevestimento,
        };

        const dataordened = await sortPocPocoRevestimento(listOrdenada);

        if (gridView && gridView.current)
          gridView.current.setDataSource(dataordened.listaRevestimento);

        totalRevestimentos(listaRevestimento);
        totalFiltros(listaRevestimento);

        data.revestimentos = dataordened.listaRevestimento;

        setData({
          ...data,
          revestimentos: dataordened.listaRevestimento,
        });
      } else {
        if (gridView && gridView.current)
          gridView.current.setDataSource(listaRevestimento);

        totalRevestimentos(listaRevestimento);
        totalFiltros(listaRevestimento);

        data.revestimentos = listaRevestimento;
        setData({
          ...data,
          revestimentos: listaRevestimento,
          salvarEtapa: true,
        });
      }

      document.getElementById('inicio').focus();
      setDataRevestimento({
        ...dataRevestimento,
        nrFim: '',
        nrInicio: dataRevestimento.nrFim,
        materialRevestimento: {},
        nrSeqPocTipoFiltro: 0,
        nrSeqPocMaterialRevestimento: 0,
        filtro: {},
        nrSlot: undefined,
      });
    };

    const onEditar = async (e, datasource) => {
      const dataRevS = datasource.filter((revestimento) => revestimento !== e);
      const dataRev = datasource.filter((revestimento) => revestimento === e);

      const filtroItem = {
        nrSeqPocTipoFiltro: dataRev[0].nrSeqPocTipoFiltro,
        noPocTipoFiltro: dataRev[0].noPocTipoFiltro,
        cdPocTipoFiltro: dataRev[0].cdPocTipoFiltro,
      };
      const materialRevestimentoItem = {
        nrSeqPocMaterialRevestimento: dataRev[0].nrSeqPocMaterialRevestimento,
        noPocMaterialRevestimento: dataRev[0].noPocMaterialRevestimento,
        cdPocMaterialRevestimento: dataRev[0].cdPocMaterialRevestimento,
      };

      setDataRevestimento({
        ...dataRev[0],
        filtro: filtroItem,
        status: GridView.EnumStatus.Alterar,
        materialRevestimento: materialRevestimentoItem,
      });

      totalRevestimentos(dataRevS);
      totalFiltros(dataRevS);

      if (gridView && gridView.current)
        gridView.current.setDataSource(dataRevS);
    };

    const onRemove = async (e, datasource) => {
      const dataRevS = datasource.filter((revestimento) => revestimento !== e);
      totalRevestimentos(dataRevS);
      totalFiltros(dataRevS);
      const obj = { ...e, status: GridView.EnumStatus.Remover };
      dataRevS.push(obj);

      if (gridView && gridView.current)
        gridView.current.setDataSource(dataRevS);

      data.revestimentos = dataRevS;
      setData({ ...data, revestimentos: dataRevS });
    };

    const columns = [
      {
        key: 'nrSeqPocPocoRevestimento',
        type: GridView.ColumnTypes.Checkbox,
        visible: false,
      },
      { key: 'nrSeqPocPoco', title: 'NrSeqPocPoco', visible: false },
      { key: 'nrInicio', title: 'Inicio', format: GridView.DataTypes.Decimal },
      { key: 'nrFim', title: 'Fim', format: GridView.DataTypes.Decimal },
      {
        key: 'nrDiametropol',
        title: 'Diâmetro (pol)',
        format: GridView.DataTypes.Inch,
      },
      {
        key: 'nrDiametromm',
        title: 'Diâmetro (mm)',
        format: GridView.DataTypes.Decimal,
      },
      { key: 'getTipoMaterial', title: 'Tipo' },
      { key: 'getMaterial', title: 'Material' },
      { key: 'nrSlot', title: 'Slot' },
      { key: 'total', title: 'Total', format: GridView.DataTypes.Decimal },
      {
        key: 'NrSeqPocTipoFiltro',
        title: 'NrSeqPocTipoFiltro',
        visible: false,
      },
      {
        key: 'nrSeqMaterialRevestimento',
        title: 'NrSeqMaterialRevestimento',
        visible: false,
      },
      {
        key: 'nrSeqPocPocoRevestimento',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onEditar(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },
      {
        key: 'nrSeqPocPocoRevestimento',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemove(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    return (
      <div>
        <div className='row mb-3'>
          <div className='display-8'>5 - Revestimento / Filtro</div>
        </div>
        <div className='card'>
          <div className='card-body'>
            <div className='row mb-3'>
              <div className='col-8'>
                <div className='row'>
                  <div className='col'>
                    <Textbox
                      id='inicio'
                      label='Inicio (m)'
                      text={dataRevestimento.nrInicio}
                      maxLength={8}
                      mask={MaskTypes.DecimalCustom}
                      decimalPlaces={2}
                      onChangedValue={(nrInicio) =>
                        setDataRevestimento({
                          ...dataRevestimento,
                          nrInicio,
                        })
                      }
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='Fim (m)'
                      text={dataRevestimento.nrFim ?? 0}
                      maxLength={8}
                      mask={MaskTypes.DecimalCustom}
                      decimalPlaces={2}
                      onChangedValue={(obj) =>
                        setDataRevestimento({
                          ...dataRevestimento,
                          nrFim: obj,
                          total:
                            obj > dataRevestimento.nrInicio
                              ? parseFloat(
                                  (obj - dataRevestimento.nrInicio).toFixed(2)
                                )
                              : 0,
                        })
                      }
                      onBlur={(obj) => {
                        if (obj <= dataRevestimento.nrInicio)
                          setDataRevestimento({
                            ...dataRevestimento,
                            nrFim: 0,
                            nrInicio: 0,
                          });
                      }}
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='Diâm. (pol)'
                      text={dataRevestimento.nrDiametropol ?? ''}
                      maxLength={8}
                      onChangedValue={(nrDiametropol) =>
                        setDataRevestimento({
                          ...dataRevestimento,
                          nrDiametropol,
                        })
                      }
                      onBlur={async (param1, param2, param3) => {
                        const obj = {
                          diametropol: param2,
                          param1,
                          param3,
                        };
                        const { value } = await getMetricConversion(
                          obj.diametropol
                        );
                        dataRevestimento.nrDiametromm = value;
                        document.getElementById('diametromm').value = value;
                      }}
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='Diâm. (mm)'
                      id='diametromm'
                      text={dataRevestimento.nrDiametromm ?? ''}
                      readOnly
                    />
                  </div>
                  <div className='col-auto'>
                    <RadioButton
                      id='revfiltro'
                      outline
                      label='Tipo'
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      buttons={TipoRevestimento}
                      selectedButton={dataRevestimento.flgTipoObjeto}
                      onChange={async (flgTipoObjeto) => {
                        dataRevestimento.flgTipoObjeto = flgTipoObjeto;
                        const { value } = await getMetricConversion(
                          dataRevestimento.nrDiametropol
                        );
                        dataRevestimento.nrDiametromm = value;
                        setDataRevestimento({
                          ...dataRevestimento,
                          flgTipoObjeto,
                          nrSeqPocTipoFiltro: 0,
                          filtro: {},
                          nrSeqPocMaterialRevestimento: 0,
                          materialRevestimento: {},
                          nrSlot: undefined,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              {dataRevestimento.flgTipoObjeto === 'F' && (
                <div className='col-4'>
                  <div className='row'>
                    <div className='col'>
                      <Autocomplete
                        label='Filtro'
                        searchDataSource={onSearchFiltro}
                        visible={dataRevestimento.flgTipoObjeto === 'F'}
                        selectedItem={dataRevestimento.filtro}
                        onSelectItem={(filtro) =>
                          setDataRevestimento({
                            ...dataRevestimento,
                            filtro,
                            noPocTipoFiltro: filtro.noPocTipoFiltro,
                            cdPocTipoFiltro: filtro.cdPocTipoFiltro,
                            nrSlot: filtro.nrSlot,
                            nrSeqPocTipoFiltro: filtro.nrSeqPocTipoFiltro,
                            noPocMaterialRevestimento: '',
                            cdPocMaterialRevestimento: 0,
                            nrSeqPocMaterialRevestimento: undefined,
                          })
                        }
                        dataSourceTextProperty='noPocTipoFiltro'
                      />
                    </div>
                    <div className='col-4'>
                      <Textbox
                        label='Slot'
                        text={dataRevestimento.nrSlot}
                        maxLength={8}
                        mask={MaskTypes.DecimalCustom}
                        onChangedValue={(nrSlot) =>
                          setDataRevestimento({
                            ...dataRevestimento,
                            nrSlot: Number(nrSlot),
                          })
                        }
                        visible={dataRevestimento.flgTipoObjeto === 'F'}
                      />
                    </div>
                  </div>
                </div>
              )}
              {dataRevestimento.flgTipoObjeto === 'R' && (
                <div className='col'>
                  <div className='row'>
                    <div className='col'>
                      <Autocomplete
                        label='Material de Revestimento'
                        searchDataSource={onSearchMaterialRevestimento}
                        visible={dataRevestimento.flgTipoObjeto === 'R'}
                        selectedItem={dataRevestimento.materialRevestimento}
                        onSelectItem={(materialRevestimento) =>
                          setDataRevestimento({
                            ...dataRevestimento,
                            materialRevestimento,
                            noPocMaterialRevestimento:
                              materialRevestimento.noPocMaterialRevestimento,
                            cdPocMaterialRevestimento:
                              materialRevestimento.cdPocMaterialRevestimento,
                            nrSeqPocMaterialRevestimento:
                              materialRevestimento.nrSeqPocMaterialRevestimento,
                            nrSeqPocTipoFiltro: undefined,
                            noPocTipoFiltro: '',
                            cdPocTipoFiltro: 0,
                            nrSlot: undefined,
                          })
                        }
                        dataSourceTextProperty='noPocMaterialRevestimento'
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className='row mb-3'>
              <div className='col' style={{ textAlign: 'center' }}>
                <Button
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  text='Adicionar'
                  onClick={onAdicionar}
                  className='width80'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <div className='row mb-3'>
                  <div className='col'>
                    <GridView
                      ref={gridView}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columns}
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  </div>
                  <div className='row mb-3'>
                    <div
                      className='col'
                      style={{
                        textAlign: 'right',
                        borderTop: '1px solid #0000002b',
                        borderBottom: '1px solid #0000002b',
                        paddingTop: '5px',
                      }}
                    >
                      <h6
                        style={{
                          padding: '2px',
                          color: '#605959',
                        }}
                      >
                        Total Filtros: {totalFiltro}
                      </h6>
                    </div>
                    <div
                      className='col'
                      style={{
                        textAlign: 'right',
                        borderTop: '1px solid #0000002b',
                        borderBottom: '1px solid #0000002b',
                        paddingTop: '5px',
                      }}
                    >
                      <h6
                        style={{
                          padding: '2px',
                          color: '#605959',
                        }}
                      >
                        Total Revestimentos: {totalRevestimento}
                      </h6>
                    </div>
                  </div>
                  <div className='row mb-3' style={{ paddingBottom: '250px' }}>
                    <div className='col'>
                      <Textarea
                        maxLength={200}
                        label='Observação'
                        text={data.noPocPocoRevestimentoObs}
                        onChangedValue={(noPocPocoRevestimentoObs) => {
                          data.noPocPocoRevestimentoObs = noPocPocoRevestimentoObs;
                          setData({
                            ...data,
                            noPocPocoRevestimentoObs,
                            salvarEtapa: true,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default CadastroRevestimentoFiltro;
