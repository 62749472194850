import React from 'react';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import {
  Autocomplete,
  Textbox,
  DatePicker,
  GridView,
  Button,
  Panel,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { validaEBuscaOSsPendentesPorPlaca } from 'core/services/FRO/ordemServico_Manutencao';

import {
  getVeiculo,
  getVeiculoAutoCompleteResumido,
} from 'core/services/FRO/veiculo';
import { getVeiculoManutencaoPreventivaPendentesByListaDeVeiculos } from 'core/services/FRO/veiculo_ManutencaoPreventiva';
import ManutencaoVeiculoPlaca from 'core/models/FRO/manutencaoVeiculoPlaca';
import { getUltimoAbastecimento } from 'core/services/FRO/abastecimento';

const ManutencaoInfosVeiculos = ({
  setLoading,
  onSetMessage,
  setMessage,
  prevDataRef,
  data,
  setData,
  dataVeiculo,
  setDataVeiculo,
  setPreventivasList,
  gridViewVeiculos,
  gridViewManutencaoPreventiva,
  gridViewManutencaoPreventivaOnNew,
}) => {
  const onSearchVeiculo = async (e) => {
    const {
      status,
      message: msg,
      veiculos,
    } = await getVeiculoAutoCompleteResumido({
      placa: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onSelectVeiculo = async (obj) => {
    const veiculo = await getVeiculo(obj.nrSeqVeiculo, true);

    const dataArray = prevDataRef.current;

    if (veiculo.nrSeqVeiculo !== dataArray.nrSeqVeiculo)
      setData({
        ...dataArray,
        veiculo,
        nrSeqVeiculo: veiculo.nrSeqVeiculo,
      });

    return veiculo;
  };

  const onClickEditVeiculo = async (selectedValue, datasource) => {
    setLoading(true);

    const obj = datasource.find((item) => item === selectedValue);

    if (
      obj.nrSeqManutencaoVeiculo === null ||
      obj.nrSeqManutencaoVeiculo === undefined ||
      obj.status === 'Novo'
    ) {
      obj.status = GridView.EnumStatus.Inserir;
    } else {
      obj.status = GridView.EnumStatus.Alterar;
    }

    const veiculos = datasource.filter((veiculo) => veiculo !== selectedValue);

    if (gridViewVeiculos && gridViewVeiculos.current) {
      gridViewVeiculos.current.setDataSource(veiculos);
    }

    const veiculoFound = await getVeiculo(obj.nrSeqVeiculo);

    const { data: abastecimento } = await getUltimoAbastecimento(
      veiculoFound.nrSeqVeiculo
    );

    setDataVeiculo({
      veiculo: veiculoFound,
      nrOdometro: obj.nrOdometro,
      placa: veiculoFound.placa,
      nrSeqVeiculo: obj.nrSeqVeiculo,
      ultOdometro: abastecimento.nrOdometro,
      ultAbastecimento: abastecimento.dtAbastecimento,
      status: obj.status,
      nrSeqManutencaoVeiculo: obj.nrSeqManutencaoVeiculo,
      nrSeqGrupoEmpresa: obj.nrSeqGrupoEmpresa,
    });

    const dataArray = prevDataRef.current;
    setData({ ...dataArray, placas: veiculos });

    setLoading(false);
  };

  const onClickRemoveVeiculo = async (selectedValue, datasource) => {
    const itemRemover = datasource.find((item) => item === selectedValue);
    itemRemover.status = GridView.EnumStatus.Remover;

    const veiculos = datasource.filter((veiculo) => veiculo !== selectedValue);

    if (gridViewVeiculos && gridViewVeiculos.current)
      gridViewVeiculos.current.setDataSource(veiculos);

    const dataArray = prevDataRef.current;
    setData({ ...dataArray });
  };

  const onPreencheGridVeiculos = (veiculo) => {
    const veiculosArray = [];

    const newManutencaoVeiculoPlaca = new ManutencaoVeiculoPlaca({
      veiculo,
      placa: veiculo.placa,
      nrSeqVeiculo: veiculo.nrSeqVeiculo,
      nrOdometro: dataVeiculo.nrOdometro,
      status: GridView.EnumStatus.Inserir,
      ultimaDataNoSistema: data.dtAbertura,
    });

    veiculosArray.push(newManutencaoVeiculoPlaca);

    const veiculoArray = [...veiculo.veiculosVinculados];

    veiculoArray.forEach((item) => {
      const novoManutencaoVeiculoPlaca = new ManutencaoVeiculoPlaca({
        veiculo: item,
        placa: item.placa,
        nrSeqVeiculo: item.nrSeqVeiculo,
        nrOdometro: 0,
        status: GridView.EnumStatus.Inserir,
        ultimaDataNoSistema: data.dtAbertura,
      });

      veiculosArray.push(novoManutencaoVeiculoPlaca);
    });

    return veiculosArray;
  };

  const validaSeVeiculoTemOsPendente = async () => {
    let osPendenteCodigoDiferente = false;
    const isOficinaInterna = data.flgOficinaInterna;

    const { data: pendentes } = await validaEBuscaOSsPendentesPorPlaca({
      cdManutencaoVeiculoOS: data.cdManutencaoVeiculoOS,
      nrSeqVeiculo: dataVeiculo.nrSeqVeiculo,
    });

    if (pendentes.length > 0) {
      for (let i = 0; i < pendentes.length; i += 1) {
        const isPendenteOficinaInterna =
          pendentes[i].pessoaOfi.flgControlaEstoque;
        if (
          pendentes[i].cdManutencaoVeiculoOS !== data.cdManutencaoVeiculoOS &&
          isPendenteOficinaInterna === isOficinaInterna
        ) {
          osPendenteCodigoDiferente = true;

          setMessage({
            message: `A Ordem de Serviço pendente nº${pendentes[i].cdManutencaoVeiculoOS} já possui a placa ${dataVeiculo.veiculo.placa}.`,
            theme: Theme.Danger,
          });

          return osPendenteCodigoDiferente;
        }
      }
    }

    return osPendenteCodigoDiferente;
  };

  const onSearchPreventivasLista = async (veiculosPlacas) => {
    const listaVeiculos = [];

    for (let i = 0; i < veiculosPlacas.length; i += 1) {
      const objBusca = {
        nrSeqVeiculo: veiculosPlacas[i].nrSeqVeiculo,
        odometroAtual: veiculosPlacas[i].nrOdometro,
        dtDocumento: data.dtAbertura,
      };

      listaVeiculos.push(objBusca);
    }

    const {
      data: manutencoesPreventivas,
    } = await getVeiculoManutencaoPreventivaPendentesByListaDeVeiculos(
      listaVeiculos
    );

    manutencoesPreventivas.forEach((preventiva) => {
      if (preventiva.manutencaoPreventiva.tipoIntervalo.flgTipo === false) {
        preventiva.odometroRestanteManutencao = `${preventiva.odometroRestanteManutencao} KM`;
      } else {
        preventiva.odometroRestanteManutencao = `${preventiva.odometroRestanteManutencao} dias`;
      }
    });

    return manutencoesPreventivas;
  };

  const onAdicionaVeiculo = async () => {
    if (dataVeiculo.veiculo.nrSeqVeiculo != null) {
      setLoading(true);

      if (dataVeiculo.status === 'Novo') {
        const veiculo = await onSelectVeiculo(dataVeiculo.veiculo);
        const veiculosPlacas = onPreencheGridVeiculos(veiculo);

        if (gridViewVeiculos && gridViewVeiculos.current) {
          gridViewVeiculos.current.setDataSource(veiculosPlacas);
        }

        const preventivas = await onSearchPreventivasLista(veiculosPlacas);

        if (
          gridViewManutencaoPreventivaOnNew &&
          gridViewManutencaoPreventivaOnNew.current
        )
          gridViewManutencaoPreventivaOnNew.current.setDataSource(preventivas);

        const placasArray = veiculosPlacas;
        if (data.placas.length > 0) {
          data.placas.forEach((placa) => {
            placasArray.push(placa);
          });
        }

        const dataArray = prevDataRef.current;

        setData({
          ...dataArray,
          placas: placasArray,
          nrOdometro: dataVeiculo.nrOdometro,
        });
        setPreventivasList(preventivas);
        setDataVeiculo({});
      } else {
        const veiculo = await onSelectVeiculo(dataVeiculo.veiculo);

        const newManutencaoVeiculoPlaca = new ManutencaoVeiculoPlaca({
          veiculo,
          placa: veiculo.placa,
          nrSeqVeiculo: dataVeiculo.nrSeqVeiculo,
          nrOdometro: dataVeiculo.nrOdometro,
          status: dataVeiculo.status,
          ultimaDataNoSistema: data.dtAbertura,
          nrSeqManutencaoVeiculo: dataVeiculo.nrSeqManutencaoVeiculo,
          nrSeqGrupoEmpresa: dataVeiculo.nrSeqGrupoEmpresa,
        });

        const placasArray = data.placas;
        placasArray.push(newManutencaoVeiculoPlaca);

        if (gridViewVeiculos && gridViewVeiculos.current) {
          gridViewVeiculos.current.setDataSource(placasArray);
        }

        const preventivas = await onSearchPreventivasLista(placasArray);

        if (
          gridViewManutencaoPreventivaOnNew &&
          gridViewManutencaoPreventivaOnNew.current
        ) {
          gridViewManutencaoPreventivaOnNew.current.setDataSource(preventivas);
        }

        const dataArray = prevDataRef.current;

        if (dataArray.nrSeqVeiculo === newManutencaoVeiculoPlaca.nrSeqVeiculo) {
          setData({
            ...dataArray,
            placas: placasArray,
            nrOdometro: dataVeiculo.nrOdometro,
          });
        } else {
          setData({
            ...dataArray,
            placas: placasArray,
          });
        }
        setPreventivasList(preventivas);
        setDataVeiculo({});
      }
    }

    setLoading(false);
  };

  const onClickRemoveManutencaoAfetada = async (selectedValue, datasource) => {
    const itemRemover = datasource.find((item) => item === selectedValue);
    itemRemover.status = GridView.EnumStatus.Remover;

    const manutencoesAfetadas = datasource.filter(
      (afetada) => afetada !== selectedValue
    );

    if (gridViewManutencaoPreventiva && gridViewManutencaoPreventiva.current)
      gridViewManutencaoPreventiva.current.setDataSource(manutencoesAfetadas);

    const dataArray = prevDataRef.current;
    setData({ ...dataArray });
  };

  const onHandlePlaca = async (veiculo) => {
    if (veiculo.nrSeqVeiculo != null) {
      setLoading(true);

      const { data: abastecimento } = await getUltimoAbastecimento(
        veiculo.nrSeqVeiculo
      );

      setDataVeiculo({
        ...dataVeiculo,
        veiculo,
        placa: veiculo.placa,
        nrSeqVeiculo: veiculo.nrSeqVeiculo,
        ultOdometro: abastecimento.nrOdometro,
        ultAbastecimento: abastecimento.dtAbastecimento,
        status: 'Novo',
      });

      setLoading(false);
    }
  };

  const onClickAdicionarVeiculo = async () => {
    if (data.pessoaOfi?.nrSeqPessoaOfi) {
      setLoading(true);

      const pendentes = await validaSeVeiculoTemOsPendente();
      if (!pendentes) onAdicionaVeiculo();

      setLoading(false);
    } else {
      setMessage({
        message: 'Selecione a Oficina primeiro.',
        theme: Theme.Warning,
      });
    }
  };

  const columnsVeiculos = [
    { key: 'veiculo.placa', title: 'Placa' },
    { key: 'nrOdometro', title: 'Odômetro' },
    { key: 'veiculo.veiculoModelo.noVeiculoModelo', title: 'Modelo' },
    {
      key: 'veiculo.veiculoModelo.veiculoMarca.noVeiculoMarca',
      title: 'Marca',
    },
    {
      key: 'nrSeqVeiculo',
      title: 'Editar',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditVeiculo(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgExibeBtn',
    },
    {
      key: 'nrSeqVeiculo',
      title: 'Excluir',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveVeiculo(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgExibeBtn',
    },
  ];

  const columnsManutencaoPreventivaOnNew = [
    {
      key: 'nrSeqVeiculo_ManutencaoPrevent',
      type: GridView.ColumnTypes.Checkbox,
    },
    {
      key: 'placa',
      title: 'Placa',
      colorText: 'noColorPendencia',
    },
    {
      key: 'manutencaoPreventiva.noManutencaoPreventiva',
      title: 'Manutenção Preventiva',
      colorText: 'noColorPendencia',
    },
    {
      key: 'ultimoOdometroNoSistema',
      title: 'Último Odômetro',
      colorText: 'noColorPendencia',
    },
    {
      key: 'ultimaDataNoSistema',
      title: 'Data Últ. Odômetro',
      format: GridView.DataTypes.Date,
      colorText: 'noColorPendencia',
    },
    {
      key: 'manutencaoPreventiva.valor',
      title: 'Manutenção Estimada',
      colorText: 'noColorPendencia',
    },
    {
      key: 'odometroRestanteManutencao',
      title: 'Próxima Troca em',
      colorText: 'noColorPendencia',
    },
    { key: 'status', title: 'Status', colorText: 'noColorPendencia' },
  ];

  const columnsManutencaoPreventiva = [
    {
      key: 'placa',
      title: 'Placa',
    },
    {
      key: 'manutencaoPreventiva.noManutencaoPreventiva',
      title: 'Manutenção Preventiva',
    },
    { key: 'nrOdometro', title: 'Último Odômetro' },
    {
      key: 'dtMovimento',
      title: 'Data Últ. Odômetro',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'manutencaoPreventiva.nrSeqVeiculo_ManutencaoPreventiva',
      title: 'Excluir',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveManutencaoAfetada(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgExibeBtn',
    },
  ];

  return (
    <Panel className='mb-3'>
      <Panel.Header title='Veículos' theme={Theme.Primary} />
      <Panel.Body>
        <div className='row'>
          <div className='col mb-3'>
            <Autocomplete
              label='Placa'
              readOnly={data?.flgStatus === 'Concluído'}
              searchDataSource={onSearchVeiculo}
              selectedItem={dataVeiculo?.veiculo}
              onSelectItem={(veiculo) => onHandlePlaca(veiculo)}
              dataSourceTextProperty='placa'
            />
          </div>

          <div className='col mb-3'>
            <Textbox
              label='Odômetro'
              readOnly={data?.flgStatus === 'Concluído'}
              maxLength={20}
              text={dataVeiculo?.nrOdometro}
              mask={MaskTypes.Integer}
              onChangedValue={(item) =>
                setDataVeiculo({
                  ...dataVeiculo,
                  nrOdometro: item,
                })
              }
            />
          </div>

          <div className='col mb-3'>
            <Textbox
              label='Último Odômetro'
              maxLength={20}
              readOnly
              text={dataVeiculo?.ultOdometro}
              mask={MaskTypes.Integer}
              onChangedValue={(item) =>
                setDataVeiculo({
                  ...dataVeiculo,
                  ultOdometro: item.ultOdometro,
                })
              }
            />
          </div>

          <div className='col mb-3'>
            <DatePicker
              label='Último Abastecimento'
              text={dataVeiculo?.ultAbastecimento}
              mask={MaskTypes.Date}
              readOnly
            />
          </div>

          <div className='col-2 btn-group mr-2'>
            <Button
              text='Adicionar'
              className='mb-3 mt-4'
              icon='plus'
              size={BootstrapSizes.Small}
              theme={Theme.Success}
              template={Button.Templates.Default}
              onClick={onClickAdicionarVeiculo}
              outline
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>
            <GridView
              ref={gridViewVeiculos}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnsVeiculos}
              offlineData
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>
      </Panel.Body>

      <Panel>
        <Panel.Header title='Manutenções Preventivas' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row'>
            <div className='mb-3'>
              <GridView
                ref={gridViewManutencaoPreventivaOnNew}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columnsManutencaoPreventivaOnNew}
                offlineData
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>

      {data?.nrSeqManutencaoVeiculoOS && (
        <Panel>
          <Panel.Header title='Manutenções Afetadas' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row'>
              <div className='mb-3'>
                <GridView
                  ref={gridViewManutencaoPreventiva}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsManutencaoPreventiva}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      )}
    </Panel>
  );
};

export default ManutencaoInfosVeiculos;
