import { TipoDocumentoPessoa } from 'core/models/SEG';

export default class PessoaDocumento {
  constructor(jsonObject = {}) {
    this.nrSeqPessoaDocumento = jsonObject.nrSeqPessoaDocumento;
    this.nrSeqTipoDocumentoPessoa = jsonObject.nrSeqTipoDocumentoPessoa;
    this.noImagem = jsonObject.noImagem;
    this.tipo = jsonObject.tipo;
    this.tamanho = jsonObject.tamanho;
    this.obs = jsonObject.obs;
    this.nrDocumento = jsonObject.nrDocumento;
    this.dtFimValidade = jsonObject.dtFimValidade;
    this.dtIniValidade = jsonObject.dtIniValidade;
    this.dtCad = jsonObject.dtCad;
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.cdPessoaTipo = jsonObject.cdPessoaTipo;

    this.noPessoa = jsonObject.noPessoa;
    this.celular = jsonObject.celular;
    this.placa = jsonObject.placa;

    this.file = jsonObject.file;
    this.flgExibeDownload = jsonObject.flgExibeDownload;

    this.tipoDocumentoPessoa = new TipoDocumentoPessoa(
      jsonObject.tipoDocumentoPessoa ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
