class TipoChamada {
  constructor(jsonObject = {}) {
    this.nrSeqTipoChamada = jsonObject.nrSeqTipoChamada;
    this.noTipoChamada = jsonObject.noTipoChamada;

    this.flgPermiteEditar = jsonObject.flgPermiteEditar;
    this.vlrEncargo = jsonObject.vlrEncargo;
  }

  toJson = () => JSON.stringify(this);
}
export default TipoChamada;
