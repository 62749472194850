import React, { useState, useEffect } from 'react';
import LogoCelebre from 'ui/theme/img/LogoCelebreCab.png';
import MenuItem from './menuItem';
import MenuTransacao from './menuTransacao';

function getTransacoes(menu) {
  let transacoesConcat = [];
  (menu.menus ?? []).forEach((p) => {
    transacoesConcat = transacoesConcat.concat(getTransacoes(p) ?? []);
  });

  return transacoesConcat.concat(menu.transacoes ?? []);
}

export default function Menu(props) {
  const [filterText, setFilterText] = useState('');
  const [transacoes, setTransacoes] = useState([]);
  const [transacoesFiltered, setTransacoesFiltered] = useState([]);

  const { menus, onOpenTransaction, hideMenu } = props;

  useEffect(() => {
    let transacoesConcat = [];
    (menus ?? []).forEach((p) => {
      transacoesConcat = transacoesConcat.concat(getTransacoes(p) ?? []);
    });

    setTransacoes(transacoesConcat);
  }, [menus]);

  useEffect(() => {
    setTransacoesFiltered(
      transacoes.filter(
        (p) =>
          p.noTransacao.toLowerCase().indexOf(filterText.toLowerCase()) >= 0
      )
    );
  }, [filterText, transacoes]);

  return (
    <div className={`sidebar-box${hideMenu ? ' minified' : ''}`}>
      <div className='sidebar'>
        <div className='brand'>
          <img
            alt='Célebre Soluções Digitais'
            src={LogoCelebre}
            className='img-responsive'
          />
        </div>
        <input
          placeholder='Buscar por...'
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        <ul className='menu'>
          {!filterText &&
            (menus ?? []).map((menu) => (
              <MenuItem
                key={menu.uniqueId}
                menu={menu}
                onOpenTransaction={onOpenTransaction}
              />
            ))}
          {filterText &&
            transacoesFiltered.map((transacao) => (
              <MenuTransacao
                key={transacao.nrSeqTransacao}
                transacao={transacao}
                onOpenTransaction={onOpenTransaction}
                filteredText={filterText}
              />
            ))}
        </ul>
      </div>
    </div>
  );
}
