import React, { useState } from 'react';
import { Modal, Button, Notification, Loader } from 'ui/components';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { effect } from 'core/services/COP/nf';

export default function ModalEfetivarNota(props) {
  const { show, data } = props;
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSend = (msg) => {
    props.onClose(msg, props.data);
  };

  const onEfetivarNota = async () => {
    setLoading(true);

    if (data.chaveNfe === null && data.tipoNotaFiscal?.flgExportaSped) {
      setMessage({
        message: 'Chave da NFe em branco.',
        theme: Theme.Danger,
      });
    } else {
      const { status, message: msg } = await effect(data);

      if (status === ResponseStatus.Success) {
        onSend(msg);
      } else {
        setMessage({
          message: msg,
          theme: Theme.Danger,
        });
      }
    }

    setLoading(false);
  };

  return (
    <Modal
      show={show}
      title='Deseja Efetivar a Nota?'
      onClose={() => onSend()}
      size={BootstrapSizes.Large}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <p>
            <span>
              Deseja Efetivar a Nota? É possível a efetivação em outro momento,
              para isto clique em não efetivar.
            </span>
          </p>
          <p>
            <span>
              Os itens constantes na Nota Fiscal de Entrada, somente serão
              movimentadas no Estoque após a Efetivação da Nota.
            </span>
          </p>
          <p>
            Para a movimentação do Financeiro é necessário Aprovar a Nota no
            Menu de Aprovação.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Small}
          icon={['far', 'save']}
          theme={Theme.Success}
          template={Button.Templates.Default}
          onClick={onEfetivarNota}
          text='Efetivar'
        />
        <Button
          size={BootstrapSizes.Small}
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          onClick={() => onSend()}
          text='Não Efetivar'
        />
      </Modal.Footer>
    </Modal>
  );
}
