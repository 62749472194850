export default class PocQuadroComando {
  constructor(jsonObject = {}) {
    this.nrSeqPocQuadroComando = jsonObject.nrSeqPocQuadroComando;
    this.cdPocQuadroComando = jsonObject.cdPocQuadroComando;
    this.noTipoVoltagem = jsonObject.noTipoVoltagem;
    this.noChave = jsonObject.noChave;
    this.noModelo = jsonObject.noModelo;
    this.flgAtivo = jsonObject.flgAtivo;
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
