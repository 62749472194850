import React from 'react';
import { mask, MaskTypes } from 'ui/Helpers/masks';

function RenderTextMask(props) {
  const { label, value, masktype } = props;
  return (
    <div className='col-auto mt-3'>
      <div className='fw-bold'>{label}:</div>
      {mask(value ?? 0, masktype ?? MaskTypes.Currency)}
    </div>
  );
}

export default function PanelResumo(props) {
  const { resumoSintetico, totalLinhasFiltradas, resumoParaClientes } = props;
  return (
    <div className='bg-primary text-white bg-gradient rounded-2 p-3'>
      {resumoSintetico && (
        <div className='row'>
          <div className='col'>
            <div className='display-8'>Valores referente as Faturas</div>
            <hr className='mt-1 mb-0' />
            <div className='row'>
              <RenderTextMask
                label='Pago Operadora'
                value={resumoSintetico.valorPagoOperadora}
              />
              <RenderTextMask
                label='Pago Operadora Final'
                value={resumoSintetico.valorPagoOperadoraFinal}
              />
              <RenderTextMask
                label='Total Recalculado'
                value={resumoSintetico.valorTotalRecalculado}
              />
              <RenderTextMask
                label='Contestado na Fatura'
                value={resumoSintetico.valorContestadoDentroFatura}
              />
              <RenderTextMask
                label='Contestado fora da Fatura'
                value={resumoSintetico.valorConstestadoForaFatura}
              />
              <RenderTextMask
                label='Resultado Final'
                value={resumoSintetico.resultadoFinal}
              />
              <RenderTextMask
                label='Qnt. Linhas'
                value={resumoSintetico.quantidadeDeLinhas}
                masktype={MaskTypes.Integer}
              />
            </div>
          </div>
        </div>
      )}
      {resumoSintetico && (
        <div className='row mt-3'>
          <div className='col'>
            <div className='display-8'>Valores referente linhas filtradas</div>
            <hr className='mt-1 mb-0' />
            <div className='row'>
              <RenderTextMask
                label='Total Original'
                value={resumoSintetico.valorOriginalCalculado}
              />
              <RenderTextMask
                label='Total Recalculado'
                value={resumoSintetico.totalValorRecalculo}
              />
              <RenderTextMask
                label='Resultado'
                value={resumoSintetico.resultadoCalculado}
              />
              <RenderTextMask
                label='Total Rateio'
                value={resumoSintetico.valorTotalRateio}
              />
              <RenderTextMask
                label='Resultado Rateio'
                value={resumoSintetico.resultadoRateio}
              />
              <RenderTextMask
                label='Qnt. Linhas'
                value={totalLinhasFiltradas}
                masktype={MaskTypes.Integer}
              />
            </div>
          </div>
        </div>
      )}
      {resumoParaClientes && (
        <div className='row'>
          <div className='col'>
            <div className='display-8'>
              Totalizadores referente as linhas pesquisadas
            </div>
            <hr className='mt-1 mb-0' />
            <div className='row'>
              <RenderTextMask
                label='Valor Pago Operadora Rateio'
                value={resumoParaClientes.valorTotalRateio}
              />
              <RenderTextMask
                label='Total Valor Recalculo'
                value={resumoParaClientes.totalValorRecalculo}
              />
              <RenderTextMask
                label='Resultado Líquido'
                value={resumoParaClientes.vlrTotalResultadoRateioComissao}
              />
              <RenderTextMask
                label='Total Custo Taxa Bancária'
                value={resumoParaClientes.vlrTotalTaxaBancaria}
              />
              <RenderTextMask
                label='Total Custo SMS'
                value={resumoParaClientes.vlrTotalTaxaSMS}
              />
              <RenderTextMask
                label='Resultado Bruto'
                value={resumoParaClientes.vlrTotalResultadoRateioComissaoBruto}
              />
              <RenderTextMask
                label='Qnt. Linhas'
                value={totalLinhasFiltradas ?? 0}
                masktype={MaskTypes.Integer}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
