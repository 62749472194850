import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getTransacaoPagined,
  printTransacao,
  deleteAllTransacao,
} from 'core/services/SEG/transacao';
import {
  CSDSelPage,
  GridView,
  Textbox,
  Autocomplete,
  Switch,
  Panel,
} from 'ui/components';
import { getMenuAutoComplete } from 'core/services/SEG/menu';

const columns = [
  { key: 'nrSeqTransacao', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdTransacao', title: 'Código Transação' },
  { key: 'noTransacao', title: 'Transação' },
  { key: 'noPaginas', title: 'Páginas' },
  { key: 'noDirFonte', title: 'Fonte' },
  { key: 'menu.noMenu', title: 'Menu', sortKey: 'noMenu' },
  { key: 'nrOrdem', title: 'Ordem' },
  { key: 'flgMobile', title: 'Mobile' },
];

export default function Transacao({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getTransacaoPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getTransacaoPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Transacao/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqTransacao = sourceRow.find((e) => e.key === 'nrSeqTransacao');
    onOpenMaintenance(nrSeqTransacao.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printTransacao(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllTransacao(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchMenu = async (e) => {
    const result = await getMenuAutoComplete({
      noMenu: e,
    });
    return result.data;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Transacao'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      {/* Filtros */}
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Filtros' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col'>
                <Textbox
                  maxLength={40}
                  label='Transação'
                  text={filtros.noTransacao}
                  onChangedValue={(noTransacao) =>
                    setFiltros({
                      ...filtros,
                      noTransacao,
                    })
                  }
                />
              </div>
              <div className='col'>
                <Textbox
                  maxLength={100}
                  label='Página'
                  text={filtros.noPaginaWeb}
                  onChangedValue={(noPaginaWeb) => {
                    setFiltros({ ...filtros, noPaginaWeb });
                  }}
                />
              </div>
              <div className='col-2'>
                <Textbox
                  maxLength={60}
                  label='Diretório Fonte'
                  text={filtros.noDirFonte}
                  onChangedValue={(noDirFonte) =>
                    setFiltros({
                      ...filtros,
                      noDirFonte,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Autocomplete
                  label='Menu'
                  searchDataSource={onSearchMenu}
                  selectedItem={filtros.menu}
                  onSelectItem={(menu) => {
                    setFiltros({
                      ...filtros,
                      menu,
                      nrSeqMenu: menu.nrSeqMenu,
                    });
                  }}
                  dataSourceTextProperty='noMenu'
                />
              </div>
              <div className='col-1'>
                <Textbox
                  maxLength={10}
                  label='Ordem'
                  text={filtros.nrOrdem}
                  onChangedValue={(nrOrdem) =>
                    setFiltros({
                      ...filtros,
                      nrOrdem,
                    })
                  }
                />
              </div>

              <div className='col-1'>
                <Textbox
                  maxLength={40}
                  label='Código'
                  text={filtros.cdTransacao}
                  onChangedValue={(cdTransacao) =>
                    setFiltros({
                      ...filtros,
                      cdTransacao,
                    })
                  }
                />
              </div>

              <div className='col'>
                <Switch
                  formControl
                  checked={filtros.flgMobile}
                  label='Mobile'
                  onChange={(flgMobile) =>
                    setFiltros({
                      ...filtros,
                      flgMobile,
                    })
                  }
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>

      {/* Grid */}
      <div className='row'>
        <div className='col'>
          <GridView
            enableExcelExport
            canControlVisibility
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
