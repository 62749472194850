import api from '../api';

const baseURL = `/FRO/PneuModeloRecapagem`;

export const savePneuModeloRecapagem = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const getPneuModeloRecapagemAutocomplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? [],
  };
};
