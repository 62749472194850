import Veiculo from './veiculo';
import AcertoMotorista from '../FIN/acertoMotorista';
import TituloPagar from '../FIN/tituloPagar';

export default class Pedagio {
  constructor(jsonObject = {}) {
    this.placa = jsonObject.placa;
    this.dtPedagio = jsonObject.dtPedagio;
    this.horaPedagio = jsonObject.dtPedagio;
    this.noTag = jsonObject.noTag;
    this.nrCategoria = jsonObject.nrCategoria;
    this.noRodovia = jsonObject.noRodovia;
    this.noPraca = jsonObject.noPraca;
    this.vlrPedagio = jsonObject.vlrPedagio;
    this.flgFaturado = jsonObject.flgFaturado;
    this.flgManual = jsonObject.flgManual;
    this.dtCadastro = jsonObject.dtCadastro;
    this.dtAlteracao = jsonObject.dtAlteracao;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.nrSeqUsuarioAlt = jsonObject.nrSeqUsuarioAlt;
    this.vlrImportado = jsonObject.vlrImportado;
    this.nrSeqPedagio = jsonObject.nrSeqPedagio;
    this.nrSeqAcertoMotorista = jsonObject.nrSeqAcertoMotorista;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.nrSeqTituloPagar = jsonObject.nrSeqTituloPagar;
    this.nrSeqPessoafor = jsonObject.nrSeqPessoafor;
    this.flgDuplicado = jsonObject.flgDuplicado;
    this.nrSeqExtrato = jsonObject.nrSeqExtrato;
    this.flgTipoMovimentoCartao = jsonObject.flgTipoMovimentoCartao;
    this.noNumerocartao = jsonObject.noNumerocartao;
    this.numeroCartao = jsonObject.numeroCartao;
    this.noObs = jsonObject.noObs;
    this.flgDescontaMotorista = jsonObject.flgDescontaMotorista;
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    this.acertoMotorista = new AcertoMotorista(
      jsonObject.acertoMotorista ?? {}
    );
    this.tituloPagar = new TituloPagar(jsonObject.tituloPagar ?? {});
  }

  toJson = () => JSON.stringify(this);
}
