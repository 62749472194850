export default class PlanoVinculoCentrodeCustoEmp {
  constructor(jsonObject = {}) {
    this.nrSeqPlanoRateio = jsonObject.nrSeqPlanoRateio;
    this.nrSeqPlano = jsonObject.nrSeqPlano;
    this.nrSeqCentrodeCusto = jsonObject.nrSeqCentrodeCusto;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.vlrVenda = jsonObject.vlrVenda;
    this.nrSeqTipoChamada = jsonObject.nrSeqTipoChamada;
    this.nrSeqPlanoVinculoccEmp = jsonObject.nrSeqPlanoVinculoccEmp;
    this.vlrRateioEmpresa = jsonObject.vlrRateioEmpresa;
    this.noTipoChamada = jsonObject.noTipoChamada;
    this.vlrVenda = jsonObject.vlrVenda;
    this.flgPermiteEditar = jsonObject.flgPermiteEditar;
  }

  toJson = () => JSON.stringify(this);
}
