import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Textbox, Switch } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getExtratoGrupoCategoria,
  saveExtratoGrupoCategoria,
  deleteExtratoGrupoCategoria,
} from 'core/services/FIN/extratoGrupoCategoria';

export default function ExtratoGrupoCategoriaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getExtratoGrupoCategoria(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveExtratoGrupoCategoria(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteExtratoGrupoCategoria(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Extrato Grupo Categoria'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqExtratoGrupoCategoria > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-5 mb-3'>
          <Textbox
            readOnly
            label='Código'
            text={data.cdExtratoGrupoCategoria}
            mask={MaskTypes.Integer}
            onChangedValue={(cdExtratoGrupoCategoria) =>
              setData({ ...data, cdExtratoGrupoCategoria })
            }
          />
        </div>
        <div className='col-5 mb-3'>
          <Textbox
            required
            maxLength={40}
            label='Descrição'
            text={data.noExtratoGrupoCategoria}
            onChangedValue={(noExtratoGrupoCategoria) =>
              setData({ ...data, noExtratoGrupoCategoria })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Debitar'
            checked={data.flgDebitar}
            onChange={(flgDebitar) => setData({ ...data, flgDebitar })}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
