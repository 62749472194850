import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  ResponseStatus,
  Theme,
  BootstrapSizes,
  JustifyContent,
} from 'ui/Helpers/utils';
import {
  Autocomplete,
  CSDManutPage,
  Panel,
  Textbox,
  RadioButton,
  GridView,
  Button,
} from 'ui/components';
import { getEmpresasUsuario } from 'core/services/SEG/empresa';
import { Chip } from 'core/models/TEL';
import {
  getOperadoraAutoComplete,
  getChip,
  searchPacoteDados,
  searchPlanoOperadora,
  getChipPai,
  saveChip,
  deleteChip,
} from 'core/services/TEL';
import { MaskTypes } from 'ui/Helpers/masks';
import { TipoChip, StatusChip, SituacaoChip } from './enums';

const Situacao = [
  { text: 'Em Uso', value: 'A' },
  { text: 'Em Estoque', value: 'E' },
  { text: 'Reserva', value: 'R' },
  { text: 'Cancelado', value: 'C' },
];

const Tipo = [
  { text: 'Movel', value: 'M' },
  { text: 'Fixo', value: 'F' },
  { text: 'Ramal', value: 'R' },
  { text: 'Link Net', value: 'L' },
  { text: 'Too-Free', value: 'T' },
];

const Status = [
  { text: 'Ativo', value: 'A' },
  { text: 'Inativo', value: 'I' },
  { text: 'Virtual', value: 'V' },
];

export default function ChipItem({
  registryKey,
  isActive,
  reload,
  transaction,
}) {
  const [filters, setFilters] = useState({
    flgStatus: StatusChip.Ativo,
    noTipo: TipoChip.Movel,
    flgSituacao: SituacaoChip.EmUso,
  });
  const [messageState, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chip, setChip] = useState({});
  const [dadosChipOperadora, setDadosChipOperadora] = useState([]);
  const gridView = useRef();

  const onRemovePlanoPacote = (e, datasource) => {
    setDadosChipOperadora([]);
    const data = datasource.filter((plano) => plano !== e);
    if (gridView && gridView.current) gridView.current.setDataSource(data);
    setDadosChipOperadora(data);
  };

  const columns = [
    { key: 'nrSeqChipVinculoOperadora', visible: false },
    { key: 'dtCadastro', visible: false },
    { key: 'dtInicioVigencia', title: 'Início' },
    { key: 'noPlano', title: 'Plano' },
    { key: 'nrSeqTipoChamadaPlano', visible: false },
    { key: 'noPacoteDados', title: 'Pacote de Dados' },
    { key: 'nrSeqTipoChamadaDados', visible: false },
    {
      key: 'nrSeqChipVinculoOperadora',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemovePlanoPacote(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remove Plano e Pacote',
      tooltipDirection: 'bottom',
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const searchOperadora = async (e) => {
    const { status, message, operadoras } = await getOperadoraAutoComplete({
      noOperadora: e,
    });

    onSetMessage(status, message);

    return operadoras;
  };

  const searchEmpresas = async (e) => {
    const { status, message, empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });
    onSetMessage(status, message);

    return empresas;
  };

  const onSearch = async (nrSeqChip) => {
    onSetMessage();
    setLoading(true);

    const chipSelecionado = await getChip(nrSeqChip);
    setChip(chipSelecionado);

    setDadosChipOperadora(chipSelecionado.vinculosOperadora);
    if (gridView && gridView.current)
      gridView.current.setDataSource(chipSelecionado.vinculosOperadora);
    setLoading(false);
  };

  const onSearchPai = async () => {
    const { chips } = await getChipPai({
      nrPrefixoLinha: filters.nrPrefixoLinha,
    });

    return chips;
  };

  const onSearchPlanoOperadora = async (e) => {
    const obj = {
      nrSeqOperadora: chip.nrSeqOperadora,
      noTipoChamada: e,
    };
    const { tiposChamada } = await searchPlanoOperadora(obj);
    return tiposChamada;
  };

  const onSearchPacoteDados = async (e) => {
    const obj = {
      nrSeqOperadora: chip.nrSeqOperadora,
      noTipoChamada: e,
    };
    const { tiposChamada } = await searchPacoteDados(obj);
    return tiposChamada;
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);
    return empresas;
  };
  const onAdicionar = () => {
    const obj = {
      nrSeqChipVinculoOperadora: 0,
      noPacoteDados: filters.noPacoteDados,
      nrSeqTipoChamadaDados: filters.nrSeqTipoChamadaDados,
      noPlano: filters.noPlano,
      nrSeqTipoChamadaPlano: filters.nrSeqTipoChamadaPlano,
      dtInicioVigencia: filters.dataInicioVigencia,
      dtCadastro: new Date(),
    };
    const lista = dadosChipOperadora;
    const listaDadosChipOperadora = lista.concat(obj);
    setDadosChipOperadora(listaDadosChipOperadora);
    if (gridView && gridView.current)
      gridView.current.setDataSource(listaDadosChipOperadora);
  };
  const onDelete = async () => {
    onSetMessage();
    setLoading(true);

    const { status, message } = await deleteChip(chip.nrSeqChip);

    setMessage({
      message,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    if (status === ResponseStatus.Success) setChip(new Chip());
    setLoading(false);
  };

  function tipoMovelouFixo() {
    const tipo = chip.noTipo ?? filters.Tipo;

    if (tipo === 'M' || tipo === 'F') return true;
    return false;
  }

  const onSave = async () => {
    onSetMessage();
    setLoading(true);

    chip.nrPrefixo = chip.nrPrefixo === '' ? undefined : chip.nrPrefixo;
    chip.nrLinha = chip.nrLinha === '' ? undefined : chip.nrLinha;

    const prefixo = tipoMovelouFixo()
      ? chip.nrPrefixo ?? filters.nrPrefixoLinha?.trim().substring(1, 3)
      : chip.nrPrefixo ?? filters.nrPrefixo;

    const linha = tipoMovelouFixo()
      ? chip.nrLinha ?? filters.nrPrefixoLinha?.trim().substring(4, 16)
      : chip.nrLinha ?? filters.nrLinha;

    const obj = {
      ...chip,
      nrPrefixo: prefixo,
      nrLinha: linha,
      noTipo: chip.noTipo,
      flgStatus: chip.flgStatus,
      flgSituacao: chip.flgSituacao,
      ChipVinculosOperadora: dadosChipOperadora,
    };
    const { status, message } = await saveChip(obj);

    setMessage({
      message,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    if (status === ResponseStatus.Success) {
      setChip({});
      setDadosChipOperadora([]);
      setFilters({});
      if (gridView && gridView.current) gridView.current.setDataSource([]);
    }

    setLoading(false);
  };
  const onSaveCadastroRapido = async () => {
    onSetMessage();
    setLoading(true);
    if (filters.nrPrefixoLinha && filters.nrPrefixoLinha.trim().length < 11)
      onSetMessage(ResponseStatus.Error, 'Informe o Prefixo e a Linha');
    else {
      const obj = {
        ...chip,
        nrPrefixo: filters.nrPrefixoLinha
          ? filters.nrPrefixoLinha
          : chip.nrPrefixo,
        nrLinha: filters.nrPrefixoLinha ? filters.nrPrefixoLinha : chip.nrLinha,

        ChipVinculosOperadora: dadosChipOperadora,
      };
      const { status, message } = await saveChip(obj);

      if (status === ResponseStatus.Success) {
        setChip({
          ...chip,
          linhaFormatada: '',
          noIdIntegracao: '',
          nrPrefixo: '',
          nrLinha: '',
          nrIccid: '',
          noObs: '',
        });
      }

      setMessage({
        message,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onAdicionarChip = () => {
    onSaveCadastroRapido();
    setFilters({ ...filters, nrPrefixoLinha: '' });
  };

  const onSetNovo = () => {
    const novo = new Chip({
      flgSituacao: SituacaoChip.EmUso,
      flgStatus: StatusChip.Ativo,
      noTipo: TipoChip.Movel,
    });
    setChip({ novo });
  };

  const load = useCallback(async () => {
    if (registryKey) {
      onSearch(registryKey);
    } else {
      setChip({
        ...chip,
        flgSituacao: SituacaoChip.EmUso,
        flgStatus: StatusChip.Ativo,
        noTipo: TipoChip.Movel,
      });
    }

    searchEmpresas();
    searchOperadora();
  }, [registryKey]);

  useEffect(() => {
    setDadosChipOperadora([]);
    (async function func() {
      await load();
    })();
  }, [registryKey, reload]);

  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Chip'
      loading={loading}
      message={messageState}
      onMessagerClose={() => setMessage(null)}
      onNew={() => onSetNovo()}
      onSave={onSave}
      transaction={transaction}
      onDelete={onDelete}
    >
      <div className='mb-3'>
        <div className='row'>
          <div className='col-2'>
            <Textbox
              label='Código'
              text={chip.cdChip}
              onChangedValue={(codigo) =>
                setFilters({
                  ...filters,
                  codigo,
                })
              }
            />
          </div>
          <div className='col-3'>
            <Autocomplete
              label='Operadora'
              searchDataSource={searchOperadora}
              selectedItem={chip.operadora}
              onSelectItem={(operadora) =>
                setChip({
                  ...chip,
                  operadora,
                  nrSeqOperadora: operadora.nrSeqOperadora,
                })
              }
              dataSourceTextProperty='noOperadora'
            />
          </div>
          <div className='col-4'>
            <Autocomplete
              label='Empresa'
              searchDataSource={onSearchEmpresa}
              selectedItem={chip.empresa}
              onSelectItem={(empresa) =>
                setChip({
                  ...chip,
                  empresa,
                  nrSeqEmpresa: empresa.nrSeqEmpresa,
                })
              }
              dataSourceTextProperty='noPessoa'
            />
          </div>
          <div className='col-3'>
            <Autocomplete
              label='Chip Pai'
              searchDataSource={onSearchPai}
              selectedItem={chip.nrSeqChip}
              onSelectItem={(linha) =>
                setChip({
                  ...chip,
                  nrSeqChipPai: linha.nrSeqChip,
                })
              }
              dataSourceTextProperty='linhaFormatada'
            />
          </div>
        </div>
      </div>
      <div className='mb-3'>
        <div className='row'>
          <div className='col-4'>
            <RadioButton
              outline
              label='Situação'
              size={BootstrapSizes.Small}
              theme={Theme.Primary}
              buttons={Situacao}
              selectedButton={chip.flgSituacao}
              onChange={(flgSituacao) =>
                setChip({
                  ...chip,
                  flgSituacao,
                })
              }
            />
          </div>
          <div className='col-4'>
            <RadioButton
              outline
              label='Tipo'
              size={BootstrapSizes.Small}
              theme={Theme.Primary}
              buttons={Tipo}
              selectedButton={chip.noTipo}
              onChange={(noTipo) =>
                setChip({
                  ...chip,
                  noTipo,
                })
              }
            />
          </div>
          <div className='col-4'>
            <RadioButton
              outline
              label='Status'
              size={BootstrapSizes.Small}
              theme={Theme.Primary}
              buttons={Status}
              selectedButton={chip.flgStatus}
              onChange={(flgStatus) =>
                setChip({
                  ...chip,
                  flgStatus,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className='mb-3'>
        {chip.cdChip ? (
          <div>
            <Panel>
              <Panel.Header
                title='Cadastro Chip'
                theme={Theme.Light}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row'>
                  <div className='col'>
                    <div className='bg-white'>
                      <div className='row'>
                        <div className='col align-self-end'>
                          <div className='row'>
                            <div className='row'>
                              <div className='row mb-3'>
                                {tipoMovelouFixo() ? (
                                  <div className='row '>
                                    <div className='col-4'>
                                      <div>
                                        <Textbox
                                          label='Nr. Linha'
                                          text={chip.nrPrefixoLinha}
                                          mask={MaskTypes.CellPhone}
                                          onChangedValue={(nrPrefixoLinha) =>
                                            setFilters({
                                              ...filters,
                                              nrPrefixoLinha,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className='col-4'>
                                      <div>
                                        <Textbox
                                          label='ICCID'
                                          text={chip.nrIccid}
                                          onChangedValue={(nrIccid) =>
                                            setChip({
                                              ...chip,
                                              nrIccid,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className='col-4'>
                                      <div>
                                        <Textbox
                                          label='Código Integração'
                                          text={chip.noIdIntegracao}
                                          onChangedValue={(noIdIntegracao) =>
                                            setChip({
                                              ...chip,
                                              noIdIntegracao,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className='row'>
                                    <div className='col-1'>
                                      <div>
                                        <Textbox
                                          label='Nr. Prefixo'
                                          maxLength={2}
                                          mask={MaskTypes.Integer}
                                          text={chip.nrPrefixo}
                                          onChangedValue={(nrPrefixo) =>
                                            setFilters({
                                              ...filters,
                                              nrPrefixo,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className='col-3'>
                                      <div>
                                        <Textbox
                                          label='Nr. Linha'
                                          text={chip.nrLinha}
                                          onChangedValue={(nrLinha) =>
                                            setFilters({
                                              ...filters,
                                              nrLinha,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className='col-4'>
                                      <div>
                                        <Textbox
                                          label='ICCID'
                                          text={chip.nrIccid}
                                          onChangedValue={(nrIccid) =>
                                            setChip({
                                              ...chip,
                                              nrIccid,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className='col-4'>
                                      <div>
                                        <Textbox
                                          label='Código Integração'
                                          text={chip.noIdIntegracao}
                                          onChangedValue={(noIdIntegracao) =>
                                            setFilters({
                                              ...filters,
                                              noIdIntegracao,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div className='row mb-3'>
                                <div className='row '>
                                  <div>
                                    <Textbox
                                      label='Observações'
                                      text={chip.noObs}
                                      onChangedValue={(noObs) =>
                                        setFilters({
                                          ...filters,
                                          noObs,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        ) : (
          <div>
            <div className='row mb-3'>
              <Panel>
                <Panel.Header
                  title='Cadastro Rápido de Chip'
                  theme={Theme.Light}
                  align={JustifyContent.Start}
                />
                <Panel.Body>
                  <div className='row'>
                    <div className='col'>
                      <div className='bg-white'>
                        <div className='row'>
                          <div className='col align-self-end'>
                            <div className='row'>
                              <div className='row mb-3'>
                                {tipoMovelouFixo() ? (
                                  <div className='row '>
                                    <div className='col-4'>
                                      <div>
                                        <Textbox
                                          label='Nr. Linha'
                                          text={chip.nrPrefixoLinha}
                                          mask={MaskTypes.CellPhone}
                                          maxLength={11}
                                          onChangedValue={(nrPrefixoLinha) =>
                                            setFilters({
                                              ...filters,
                                              nrPrefixoLinha,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className='col-4'>
                                      <div>
                                        <Textbox
                                          label='ICCID'
                                          text={chip.nrIccid}
                                          onChangedValue={(nrIccid) =>
                                            setChip({
                                              ...chip,
                                              nrIccid,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className='col-4'>
                                      <div>
                                        <Textbox
                                          label='Código Integração'
                                          text={chip.noIdIntegracao}
                                          onChangedValue={(noIdIntegracao) =>
                                            setChip({
                                              ...chip,
                                              noIdIntegracao,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className='row'>
                                    <div className='col-1'>
                                      <div>
                                        <Textbox
                                          label='Nr. Prefixo'
                                          maxLength={2}
                                          text={chip.nrPrefixo}
                                          onChangedValue={(nrPrefixo) =>
                                            setFilters({
                                              ...filters,
                                              nrPrefixo,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className='col-3'>
                                      <div>
                                        <Textbox
                                          label='Nr. Linha'
                                          text={chip.nrLinha}
                                          maxLength={11}
                                          onChangedValue={(nrLinha) =>
                                            setFilters({
                                              ...filters,
                                              nrLinha,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className='col-4'>
                                      <div>
                                        <Textbox
                                          label='ICCID'
                                          text={chip.nrIccid}
                                          onChangedValue={(nrIccid) =>
                                            setChip({
                                              ...chip,
                                              nrIccid,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className='col-4'>
                                      <div>
                                        <Textbox
                                          label='Código Integração'
                                          text={chip.noIdIntegracao}
                                          onChangedValue={(noIdIntegracao) =>
                                            setFilters({
                                              ...filters,
                                              noIdIntegracao,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className='row mb-3'>
                                <div className='row'>
                                  <Textbox
                                    label='Observações'
                                    text={chip.noObs}
                                    onChangedValue={(noObs) =>
                                      setChip({
                                        ...chip,
                                        noObs,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-auto'>
                            <div className='widget-square text-white p-4 br-tl-1 br-bl-1'>
                              <Button
                                outline
                                size={BootstrapSizes.Small}
                                theme={Theme.Success}
                                template={Button.Templates.Quick}
                                icon='plus'
                                onClick={onAdicionarChip}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
          </div>
        )}
      </div>
      <div className='mb-3'>
        <Panel>
          <Panel.Header
            title='Informações da Nota Fiscal'
            theme={Theme.Light}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-3'>
                <Textbox
                  label='NotaFiscal'
                  text={chip.nrNotaFiscal}
                  onChangedValue={(nrNotaFiscal) =>
                    setChip({
                      ...chip,
                      nrNotaFiscal,
                    })
                  }
                />
              </div>
              <div className='col-3'>
                <Textbox
                  label='Data Nota Fiscal'
                  maxLength={10}
                  mask={MaskTypes.Date}
                  text={chip.dtNotaFiscalStr}
                  onChangedValue={(dtNotaFiscalStr) =>
                    setChip({
                      ...chip,
                      dtNotaFiscalStr,
                    })
                  }
                />
              </div>
              <div className='col-3'>
                <Textbox
                  label='Valor de Custo R$'
                  text={chip.vlrPrecoCusto}
                  mask={MaskTypes.Decimal}
                  onChangedValue={(vlrPrecoCusto) =>
                    setChip({
                      ...chip,
                      vlrPrecoCusto,
                    })
                  }
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='mb-3'>
        <Panel>
          <Panel.Header
            title='Dados do CHIP na Operadora'
            theme={Theme.Light}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col'>
                <Autocomplete
                  label='Plano Operadora'
                  searchDataSource={onSearchPlanoOperadora}
                  selectedItem={filters.tipoChamada}
                  onSelectItem={(tipoChamada) =>
                    setFilters({
                      ...filters,
                      tipoChamada,
                      noPlano: tipoChamada.noTipoChamada,
                      nrSeqTipoChamadaPlano: tipoChamada.nrSeqTipoChamada,
                    })
                  }
                  dataSourceTextProperty='noTipoChamada'
                />
              </div>
              <div className='col'>
                <Autocomplete
                  label='Pacote Dados Operadora'
                  searchDataSource={onSearchPacoteDados}
                  selectedItem={filters.pacoteDados}
                  onSelectItem={(pacoteDados) =>
                    setFilters({
                      ...filters,
                      pacoteDados,
                      nrSeqTipoChamadaDados: pacoteDados.nrSeqTipoChamada,
                      noPacoteDados: pacoteDados.noTipoChamada,
                    })
                  }
                  dataSourceTextProperty='noTipoChamada'
                />
              </div>
              <div className='col'>
                <Textbox
                  label='Inicio Vigência'
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChangedValue={(dataInicioVigencia) =>
                    setFilters({ ...filters, dataInicioVigencia })
                  }
                />
              </div>
              <div className='col-auto'>
                <Button
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Success}
                  template={Button.Templates.Quick}
                  text='Adicionar'
                  onClick={onAdicionar}
                />
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col'>
                <GridView
                  ref={gridView}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    </CSDManutPage>
  );
}
