import Empresa from '../SEG/empresa';
import PedidoCompraItem from './pedidoCompraItem';
import CondicaoPagamento from '../FIN/condicaoPagamento';
import Comprador from '../COP/comprador';
import Fornecedor from '../FIN/fornecedor';
import TipoDespesa from '../FIN/tipoDespesa';
import FormaPagamento from '../FIN/formaPagamento';
import PedidoCompraFatura from './pedidoCompraFatura';
import PedidoCompraDocumento from './pedidoCompraDocumento';

export default class PedidoCompra {
  constructor(jsonObject = {}) {
    this.nrSeqPedidoCompra = jsonObject.nrSeqPedidoCompra;
    this.nrPedidoCompra = jsonObject.nrPedidoCompra;
    this.dtEmissao = jsonObject.dtEmissao;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.cdPedidoCompra = jsonObject.cdPedidoCompra;
    this.cdPedidoCompraMaisValor = jsonObject.cdPedidoCompraMaisValor;
    this.cdPedidoCompraFor = jsonObject.cdPedidoCompraFor;
    this.vlrPedido = jsonObject.vlrPedido;
    this.vlrFretePedido = jsonObject.vlrFretePedido;
    this.vlrDescontoPedido = jsonObject.vlrDescontoPedido;
    this.vlrProduto = jsonObject.vlrProduto;
    this.dtEntrega = jsonObject.dtEntrega;
    this.dtVencimento = jsonObject.dtVencimento;
    this.nrSeqPessoaCop = jsonObject.nrSeqPessoaCop;
    this.nrSeqCondicaoPagamento = jsonObject.nrSeqCondicaoPagamento;
    this.cdPedidoCompraFor = jsonObject.cdPedidoCompraFor;
    this.dtCancel = jsonObject.dtCancel;
    this.dtCadastro = jsonObject.dtCadastro;
    this.dtRecebimento = jsonObject.dtRecebimento;
    this.nrSeqPessoaFor = jsonObject.nrSeqPessoaFor;
    this.noPessoaFor = jsonObject.noPessoaFor;
    this.noPessoaCop = jsonObject.noPessoaCop;
    this.nrSeqTipoDespesa = jsonObject.nrSeqTipoDespesa;
    this.noObservacaoGeral = jsonObject.noObservacaoGeral;
    this.status = jsonObject.status;
    this.situacao = jsonObject.situacao;
    this.noColor = jsonObject.noColor;
    this.noColorSituacao = jsonObject.noColorSituacao;
    this.nrSeqUsuarioEncerramento = jsonObject.nrSeqUsuarioEncerramento;
    this.nrSeqUsuarioCancelamento = jsonObject.nrSeqUsuarioCancelamento;
    this.dtCancelamento = jsonObject.dtCancelamento;
    this.dtEncerramento = jsonObject.dtEncerramento;
    this.possuiNf = jsonObject.possuiNf;
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.condicaoPagamento = new CondicaoPagamento(
      jsonObject.condicaoPagamento ?? {}
    );
    this.fornecedor = new Fornecedor(jsonObject.fornecedor ?? {});
    this.comprador = new Comprador(jsonObject.comprador ?? {});
    this.tipoDespesa = new TipoDespesa(jsonObject.tipoDespesa ?? {});
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.pedidoCompraItens = (jsonObject.pedidoCompraItens ?? []).map(
      (produtoitem) => new PedidoCompraItem(produtoitem)
    );
    this.pedidoCompraFaturas = (jsonObject.pedidoCompraFaturas ?? []).map(
      (pedidocomprafatura) => new PedidoCompraFatura(pedidocomprafatura)
    );

    this.noStatusPedido = jsonObject.noStatusPedido;
    this.noCorStatusPedido = jsonObject.noCorStatusPedido;
    this.noSituacaoPedido = jsonObject.noSituacaoPedido;
    this.noCorSituacaoPedido = jsonObject.noCorSituacaoPedido;

    this.flgGerarFinanceiro = jsonObject.flgGerarFinanceiro;
    this.flgPermiteAlterar = jsonObject.flgPermiteAlterar;
    this.dtCadastroStr = jsonObject.dtCadastroStr;
    this.dtEmissaoStr = jsonObject.dtEmissaoStr;
    this.dtEntregaStr = jsonObject.dtEntregaStr;
    this.noMotivoCancelamento = jsonObject.noMotivoCancelamento;
    this.documentos = jsonObject.documentos
      ? jsonObject.documentos.map(
          (item) => new PedidoCompraDocumento(item ?? {})
        )
      : [];
  }

  get getdtEmissaoFormatada() {
    if (this.dtEmissaoIni) {
      return `${new Date(this.dtEmissaoIni).toLocaleDateString('pt-BR')}`;
    }
    return '';
  }

  get getdtCadastroFormatada() {
    if (this.dtCadastro) {
      return `${new Date(this.dtCadastro).toLocaleDateString('pt-BR')}`;
    }
    return '';
  }

  toJson = () => JSON.stringify(this);
}
