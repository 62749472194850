import React, { useState, useRef, useEffect } from 'react';

import {
  BootstrapSizes,
  MimeTypes,
  Theme,
  ResponseStatus,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  Autocomplete,
  Button,
  Loader,
  Modal,
  Notification,
  GridView,
  Switch,
  Textbox,
  FileUpload,
} from 'ui/components';

import {
  importClientLine,
  deleteClienteLinha,
} from 'core/services/TEL/clienteLinha';

import { getClienteAutoComplete } from 'core/services/FIN/cliente';
import { getPlanoAutoComplete } from 'core/services/TEL/plano';
import { getChipAutoComplete } from 'core/services/TEL/chip';
import { getCentroDeCustoAutoComplete } from 'core/services/TEL/centroDeCusto';

export default function ModalGerarClienteLinhaMassivo({
  recurso,
  show,
  onClose,
}) {
  const [data, setData] = useState({
    flgMantemCentroDeCusto: true,
    flgImportarArquivo: false,
  });
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const gridViewGerarClienteLinhaMassivo = useRef();
  const [importedFile, setImportedFile] = useState([]);
  const msgUpload =
    'O arquivo a ser importado deverá ser um arquivo csv constando os ' +
    'numeros das linhas (prefixo + numero da linha), separados por ";"' +
    'Ex. 51999999999;55988888888;47977777777';
  const onClickRemoveClienteLinha = async (selectedvalue) => {
    setLoading(true);
    const { nrSeqClienteLinha } = selectedvalue;

    const { status, message: msg } = await deleteClienteLinha(
      nrSeqClienteLinha
    );

    let recursos = gridViewGerarClienteLinhaMassivo.current.getDataSource();

    if (status === ResponseStatus.Success) {
      recursos = recursos.filter(
        (item) => item.nrSeqClienteLinha !== nrSeqClienteLinha
      );
    }

    if (
      gridViewGerarClienteLinhaMassivo &&
      gridViewGerarClienteLinhaMassivo.current
    ) {
      gridViewGerarClienteLinhaMassivo.current.setDataSource(recursos);
    }
    setLoading(false);
    setMessage({
      msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
  };

  const columnsClientes = [
    { key: 'nrSeqClienteLinha', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'noPessoa',
      title: 'Cliente',
      visible: true,
    },
    {
      key: 'nroFoneCompleto',
      title: 'Linha',
      visible: true,
    },
    {
      key: 'noPlano',
      title: 'Plano',
      visible: true,
    },
    {
      key: 'dtAquisicao',
      title: 'Data Aquis.',
      visible: true,
      format: GridView.DataTypes.Date,
    },

    {
      key: 'nrSeqClienteLinha',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickRemoveClienteLinha(e),

      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover Cliente Linha',
      tooltipDirection: 'bottom',
    },
  ];
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onClickAdicionar = async () => {
    setLoading(true);
    const { status, message: msg, value } = await importClientLine(
      importedFile[0],
      data
    );

    if (value) {
      const recursos = gridViewGerarClienteLinhaMassivo.current.getDataSource();

      value.forEach((item) => recursos.push(item));

      if (
        gridViewGerarClienteLinhaMassivo &&
        gridViewGerarClienteLinhaMassivo.current
      )
        gridViewGerarClienteLinhaMassivo.current.setDataSource(recursos);
    }

    setLoading(false);
    onSetMessage(status, msg);
  };

  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return clientes;
  };

  const onSearchChip = async (e) => {
    const { status, message: msg, chips } = await getChipAutoComplete({
      nrPrefixoLinha: e,
    });

    onSetMessage(status, msg);

    return chips;
  };

  const onSearchCentroDeCusto = async (e) => {
    const {
      status,
      message: msg,
      data: centros,
    } = await getCentroDeCustoAutoComplete({
      codNoCentroDeCusto: e,
    });
    onSetMessage(status, msg);
    return centros;
  };

  const onSearchPlano = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoComplete({
      noPlano: e,
    });
    onSetMessage(status, msg);
    return planos;
  };

  useEffect(() => {
    (async function load() {
      setImportedFile([]);
    })();
  }, [recurso]);

  return (
    <Modal
      show={show}
      title='Gerar Cliente Linha Massivo'
      onClose={onClose}
      size={BootstrapSizes.Large}
      icon={['far', 'comment-alt']}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='row'>
          <div className='col-sm-12'>
            <div className='alert alert-info fs-8' role='alert'>
              <p>
                O cliente linha será salvo automaticamente ao clicar no botão
                adicionar, e ao clicar no botão remover o mesmo será excluso do
                banco de dados.
              </p>
            </div>
            .
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-2'>
            <Switch
              formControl
              label='Persiste Cliente'
              checked={data.flgMantemCliente}
              onChange={(flgMantemCliente) => {
                setData({
                  ...data,
                  flgMantemCliente,
                });
              }}
            />
          </div>

          <div className='col-sm-2'>
            <Switch
              formControl
              label='Importa Arquivo'
              checked={data.flgImportarArquivo}
              onChange={(flgImportarArquivo) => {
                setData({
                  ...data,
                  flgImportarArquivo,
                });
              }}
            />
          </div>

          <div className='col-sm-3'>
            <Switch
              formControl
              label='Mantém centro de custo'
              checked={data.flgMantemCentroDeCusto}
              onChange={(flgMantemCentroDeCusto) => {
                setData({
                  ...data,
                  flgMantemCentroDeCusto,
                });
              }}
            />
          </div>

          <div className='col-sm-2'>
            <Switch
              formControl
              label='Cancela Ativos'
              checked={data.flgCancelaAtivos}
              onChange={(flgCancelaAtivos) => {
                setData({
                  ...data,
                  flgCancelaAtivos,
                });
              }}
            />
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-8'>
            <Autocomplete
              label='Plano'
              required
              searchDataSource={onSearchPlano}
              selectedItem={data.plano}
              onSelectItem={(plano) => {
                setData({
                  ...data,
                  plano,
                  nrSeqPlano: plano.nrSeqPlano,
                });
              }}
              dataSourceTextProperty='noPlano'
            />
          </div>

          <div className='col-4'>
            <Textbox
              label='Dt Aquisição'
              text={data.dtAquisicao}
              maxLength={10}
              mask={MaskTypes.Date}
              onChangedValue={(dtAquisicao) =>
                setData({
                  ...data,
                  dtAquisicao,
                })
              }
            />
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-8'>
            <Autocomplete
              label='Cliente'
              required
              searchDataSource={onSearchCliente}
              selectedItem={data.cliente}
              onSelectItem={(cliente) => {
                setData({
                  ...data,
                  cliente,
                  nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>

          <div className='col-4'>
            <Autocomplete
              label='Linha'
              required
              searchDataSource={onSearchChip}
              selectedItem={data.chip}
              onSelectItem={(chip) => {
                setData({
                  ...data,
                  chip,
                  nrSeqChip: chip.nrSeqChip,
                });
              }}
              dataSourceTextProperty='linhaFormatada'
            />
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-8'>
            <Autocomplete
              label='Centro de Custo'
              searchDataSource={onSearchCentroDeCusto}
              selectedItem={setData.centroDeCusto}
              visible={!data.flgMantemCentroDeCusto}
              onSelectItem={(centroDeCusto) =>
                setData({
                  ...setData,
                  nrSeqCentroDeCusto: centroDeCusto.nrSeqCentroDeCusto,
                  centroDeCusto,
                })
              }
              dataSourceTextProperty='noCentroDeCusto'
            />
          </div>
        </div>

        {data.flgImportarArquivo && (
          <div className='row mb-3'>
            <div className='alert alert-info fs-8' role='alert'>
              <p>{msgUpload}</p>
            </div>
            <div className='col-12'>
              <FileUpload
                files={importedFile}
                onChange={(file) => setImportedFile(file)}
                allowedMimeTypes={[MimeTypes.Types.Excel]}
              />
            </div>
          </div>
        )}
        <div className='mb-3 d-grid gap-1'>
          <Button
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Adicionar'
            onClick={onClickAdicionar}
          />
        </div>

        <div className='row mb-3'>
          <div className='col'>
            <GridView
              ref={gridViewGerarClienteLinhaMassivo}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnsClientes}
              offlineData
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Sair'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            setData({});
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
