import qs from 'qs';
import Pagination from 'core/models/pagination';
import Fornecedor from 'core/models/FIN/fornecedor';
import api from '../api';

const baseURL = `/FIN/Fornecedor`;

export const getFornecedorList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    pessoaFors: value?.map((json) => new Fornecedor(json)) ?? [],
  };
};

export const getFornecedorPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Fornecedor(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getCpfCnpj = async (data) => {
  const response = await api.post(`${baseURL}/BuscaCpfCnpj`, data);
  return response.data.value ?? {};
};

export const getFornecedor = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Fornecedor(value) : {};
};

export const saveFornecedor = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteFornecedor = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printFornecedor = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelFornecedor = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllFornecedor = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getFornecedorAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Fornecedor(json)) ?? [],
  };
};

export const getOnlySupplierInformation = async (id) => {
  const response = await api.get(
    `${baseURL}/SearchOnlyForSupplierInformationById`,
    {
      params: { id },
    }
  );
  const { value } = response.data ?? {};
  return value ? new Fornecedor(value) : {};
};

export const SaveFromCustomerComponent = async (data) => {
  const response = await api.post(`${baseURL}/SaveFromCustomerComponent`, data);
  return response.data ?? {};
};
