import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  DatePicker,
  Button,
  GridView,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getInventario } from 'core/services/EST/inventario';
import {
  ZerarItensInventario,
  saveInventarioItem,
} from 'core/services/EST/inventarioItem';

export default function InventarioContagemItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const gridView = useRef();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [filter, setFilter] = useState({});

  const exibirColunaSaldo = () => {
    if (!data.flgExibirEstoque) {
      const gridColumns = gridView?.current?.getColumns();

      const saldoIndex = gridColumns.findIndex((e) => e.key === 'saldo');

      gridColumns[saldoIndex].visible = false;
    } else {
      const gridColumns = gridView?.current?.getColumns();

      const saldoIndex = gridColumns.findIndex((e) => e.key === 'saldo');

      gridColumns[saldoIndex].visible = true;
    }
  };

  const onSetMessage = (status, messageshow) => {
    if (messageshow)
      setMessage({
        message: messageshow,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    setLoading(loading);
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const inventario = await getInventario(registryKey);

      const dtContagem = new Date().toJSON().slice(0, 10);

      inventario.inventarioItens.forEach((item) => {
        item.currentDtContagem = dtContagem;

        if (item.vlrCusto > 0) {
          item.flgExisteVlrCusto = true;
        } else {
          item.flgExisteVlrCusto = false;
        }
      });

      setData({ ...inventario, dtContagem });

      if (gridView && gridView.current) {
        gridView.current.setDataSource(inventario.inventarioItens);

        exibirColunaSaldo();
      }

      setLoading(false);
    }

    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const onSearchAlmoxarifadoLocal = () => {
    const locais = [];

    data.inventarioItens.forEach((item) => {
      const local = locais.find(
        (e) => item.noAlmoxarifadoLocal === e.noAlmoxarifadoLocal
      );

      if (!local) {
        locais.push({ noAlmoxarifadoLocal: item.noAlmoxarifadoLocal });
      }
    });

    return locais;
  };

  const onSearchProdutoGrupo = () => {
    const grupos = [];

    data.inventarioItens.forEach((item) => {
      const grupo = grupos.find(
        (e) => item.noProdutoGrupo === e.noProdutoGrupo
      );

      if (!grupo) {
        grupos.push({ noProdutoGrupo: item.noProdutoGrupo });
      }
    });

    return grupos;
  };

  const onSearchProdutoSubGrupo = () => {
    const subGrupos = [];

    data.inventarioItens.forEach((item) => {
      const subGrupo = subGrupos.find(
        (e) => item.noProdutoSubGrupo === e.noProdutoSubGrupo
      );

      if (!subGrupo) {
        subGrupos.push({ noProdutoSubGrupo: item.noProdutoSubGrupo });
      }
    });

    return subGrupos;
  };

  const onFilterInventario = () => {
    if (gridView && gridView.current) {
      if (
        (!filter.almoxarifadoLocal ||
          !filter.almoxarifadoLocal?.noAlmoxarifadoLocal) &&
        (!filter.produtoGrupo || !filter.produtoGrupo?.noProdutoGrupo) &&
        (!filter.produtoSubGrupo || !filter.produtoSubGrupo?.noProdutoSubGrupo)
      ) {
        gridView.current.setDataSource(data.inventarioItens);
      } else {
        const filteredInventario = [];

        data.inventarioItens.forEach((item) => {
          let filterItem = true;

          if (
            filter.almoxarifadoLocal &&
            filter.almoxarifadoLocal?.noAlmoxarifadoLocal
          ) {
            if (
              item.noAlmoxarifadoLocal !==
              filter.almoxarifadoLocal.noAlmoxarifadoLocal
            ) {
              filterItem = false;
            }
          }

          if (filter.produtoGrupo && filter.produtoGrupo?.noProdutoGrupo) {
            if (filterItem) {
              if (item.noProdutoGrupo !== filter.produtoGrupo.noProdutoGrupo) {
                filterItem = false;
              }
            }
          }

          if (
            filter.produtoSubGrupo &&
            filter.produtoSubGrupo?.noProdutoSubGrupo
          ) {
            if (filterItem) {
              if (
                item.noProdutoSubGrupo !==
                filter.produtoSubGrupo.noProdutoSubGrupo
              ) {
                filterItem = false;
              }
            }
          }

          if (filterItem) {
            filteredInventario.push(item);
          }
        });

        if (filteredInventario.length > 0) {
          gridView.current.setDataSource(filteredInventario);
        }
      }
    }
  };

  const onColumnSort = (sortBy) => {
    const [key, order] = sortBy.split(' ');

    const list = gridView.current.getDataSource();

    const sortedList = list.sort((a, b) => {
      if (order === 'asc') {
        if (a[key] < b[key]) {
          return -1;
        }

        if (a[key] > b[key]) {
          return 1;
        }

        return 0;
      }

      if (a[key] > b[key]) {
        return -1;
      }

      if (a[key] < b[key]) {
        return 1;
      }

      return 0;
    });

    gridView.current.setDataSource(sortedList, { sortBy });
  };

  const updateInventarioItem = async (keyValue, value, row) => {
    const dataSource = gridView.current.getDataSource();

    const gridItem = dataSource.find((item) => item.nrSequencia === keyValue);

    if (
      gridItem.qtdeContada !== value ||
      gridItem.dtContagem !== gridItem.currentDtContagem
    ) {
      gridItem.dtContagem = gridItem.currentDtContagem;
      gridItem.qtdeContada = value;

      const dataSourceBuilded = gridView.current.getBuildedDataSource();

      const dtContagemPieces = gridItem.dtContagem.split('-');
      const dtContagem = `${dtContagemPieces[2]}/${dtContagemPieces[1]}/${dtContagemPieces[0]}`;

      dataSourceBuilded[row - 1][8].value = gridItem.qtdeContada;
      dataSourceBuilded[row - 1][9].value = dtContagem;

      gridView?.current?.setBuildedDataSource(dataSourceBuilded);

      document.getElementById(`dtContagem_${row}_9`).innerHTML = dtContagem;

      await saveInventarioItem({
        ...gridItem,
        flgDigitada: true,
      });
    }
  };

  const fInventarioItem = (keyValue, value, row) => {
    if (value >= 0 && value !== '') {
      updateInventarioItem(keyValue, value, row);
    }
  };

  const updateVlrCusto = async (keyValue, value, row) => {
    const dataSource = gridView.current.getDataSource();

    const gridItem = dataSource.find((item) => item.nrSequencia === keyValue);

    if (gridItem.vlrCusto === 0) {
      gridItem.vlrCusto = value;

      const dataSourceBuilded = gridView.current.getBuildedDataSource();

      dataSourceBuilded[row - 1][10].value = gridItem.vlrCusto;

      gridView?.current?.setBuildedDataSource(dataSourceBuilded);

      await saveInventarioItem({
        ...gridItem,
        flgDigitada: true,
      });
    }
  };

  const fVlrCusto = (keyValue, value, row) => {
    if (value >= 0 && value !== '') {
      updateVlrCusto(keyValue, value, row);
    }
  };

  const inventarioColumns = [
    { key: 'nrSequencia', visible: false, title: '' },
    { key: 'cdPeca', title: 'Código Peça' },
    { key: 'noProduto', title: 'Produto' },
    { key: 'noProdutoGrupo', title: 'Grupo', visible: false },
    { key: 'noProdutoSubGrupo', title: 'SubGrupo', visible: false },
    { key: 'noProdutoLinha', title: 'Linha' },
    { key: 'cdProdutoUnidadeMedida', title: 'Unid' },
    {
      key: 'noAlmoxarifadoLocal',
      title: 'Local Armazenamento',
      sortable: false,
    },
    {
      key: 'qtdeContada',
      title: 'Quantidade',
      sortable: false,
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      onBlur: fInventarioItem,
    },
    {
      key: 'dtContagem',
      title: 'Data Contagem',
      format: GridView.DataTypes.Date,
      sortable: false,
    },
    {
      key: 'vlrCusto',
      title: 'Valor Custo',
      sortable: false,
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      onBlur: fVlrCusto,
      readOnlyDynamic: 'vlrCusto',
    },
    {
      key: 'saldo',
      title: 'Saldo',
      sortable: false,
      format: GridView.DataTypes.Decimal,
    },
  ];

  const onZerarInventario = async () => {
    setLoading(true);

    const { status, message: msg } = await ZerarItensInventario({
      nrSeqInventario: data.nrSeqInventario,
      dtContagem: data.dtContagem,
    });

    if (status === ResponseStatus.Success) {
      await load();
    }

    onSetMessage(status, msg);

    setLoading(false);
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Inventário Contagem'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row mb-3 align-items-end  '>
        <div className='col-4'>
          <Textbox readOnly label='Inventário' text={data.noInventario} />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data da Contagem'
            text={data.dtContagem}
            onChange={(dtContagem) => {
              setData({ ...data, dtContagem });

              const list = gridView.current.getDataSource();

              list.forEach((item) => {
                item.currentDtContagem = dtContagem;
              });

              gridView.current.setDataSource(list);
            }}
            mask={MaskTypes.Date}
          />
        </div>
        <div className='col-3'>
          <Button
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Zerar Inventário'
            onClick={onZerarInventario}
          />
        </div>
      </div>
      <div className='row mb-3 align-items-end'>
        <div className='col'>
          <Autocomplete
            label='Local Armazenamento'
            searchDataSource={onSearchAlmoxarifadoLocal}
            selectedItem={filter.almoxarifadoLocal}
            onSelectItem={(almoxarifadoLocal) => {
              setFilter({
                ...filter,
                almoxarifadoLocal,
              });
              console.log(data);
            }}
            dataSourceTextProperty='noAlmoxarifadoLocal'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Grupo'
            searchDataSource={onSearchProdutoGrupo}
            selectedItem={filter.produtoGrupo}
            onSelectItem={(produtoGrupo) => {
              setFilter({ ...filter, produtoGrupo });
            }}
            dataSourceTextProperty='noProdutoGrupo'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='SubGrupo'
            searchDataSource={onSearchProdutoSubGrupo}
            selectedItem={filter.produtoSubGrupo}
            onSelectItem={(produtoSubGrupo) => {
              setFilter({
                ...filter,
                produtoSubGrupo,
              });
            }}
            dataSourceTextProperty='noProdutoSubGrupo'
          />
        </div>
        <div className='col'>
          <Button
            onClick={onFilterInventario}
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Filtrar Inventario'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <GridView
          canControlVisibility
          ref={gridView}
          className='table-striped table-hover table-bordered table-sm'
          dataColumns={inventarioColumns}
          showPagination={false}
          showSelectSizes={false}
          onColumnSort={onColumnSort}
        />
      </div>
    </CSDManutPage>
  );
}
