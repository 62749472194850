import qs from 'qs';
import Pagination from 'core/models/pagination';
import ManutencaoVeiculoProduto from 'core/models/FRO/manutencaoVeiculo_Produto';
import api from '../api';

const baseURL = `/FRO/ManutencaoVeiculo_Produto`;

export const getManutencaoVeiculoProdutoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ManutencaoVeiculoProduto(json)) ?? [],
  };
};

export const getManutencaoVeiculoProdutoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ManutencaoVeiculoProduto(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getManutencaoVeiculoProduto = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ManutencaoVeiculoProduto(value) : {};
};

export const saveManutencaoVeiculoProduto = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteManutencaoVeiculoProduto = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printManutencaoVeiculoProduto = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelManutencaoVeiculoProduto = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllManutencaoVeiculoProduto = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
