import qs from 'qs';
import Pagination from 'core/models/pagination';
import Pedido from 'core/models/VEN/pedido';
import PedidoSearch from 'core/models/VEN/pedidoSearch';
import api from '../api';

const baseURL = `/VEN/Pedido`;

export const getPedidoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PedidoSearch(json)) ?? [],
  };
};

export const getPedidoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PedidoSearch(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPedido = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Pedido(value) : {};
};

export const GetIcmsInformation = async (id) => {
  const response = await api.get(`${baseURL}/GetIcmsInformation`, {
    params: { id },
  });

  return response.data;
};

export const savePedido = async (data) => {
  const response = await api.post(`${baseURL}/save`, data);
  return response.data ?? {};
};

export const deletePedido = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPedido = async (id) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: { id },
  });
  return response.data ?? {};
};

export const excelPedido = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPedido = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const postCalcularComissaoVendedor = async (filters) => {
  const response = await api.post(
    `${baseURL}/CalcularComissaoVendedor`,
    filters
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};

export const postGerarTituloPagarComissao = async (filters) => {
  const response = await api.post(
    `${baseURL}/GerarTituloPagarComissao`,
    filters
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};

export const cancelOrder = async (id) => {
  const response = await api.get(`${baseURL}/CancelOrder`, {
    params: { id },
  });
  return response.data ?? {};
};

export const generateParcels = async (
  nrSeqCondicaoPagamento,
  nrSeqEmpresa,
  valorTotal
) => {
  const response = await api.get(`${baseURL}/GenerateParcels`, {
    params: { nrSeqCondicaoPagamento, nrSeqEmpresa, valorTotal },
  });
  const { value } = response.data ?? {};
  return value;
};

export const getNew = async () => {
  const response = await api.get(`${baseURL}/GetNew`);
  const { value } = response.data ?? {};
  return value ? new Pedido(value) : {};
};

export const changeCompanyOrder = async (data) => {
  const response = await api.post(`${baseURL}/changeCompanyOrder`, data);
  return response.data ?? {};
};

export const postConfirm = async (filters) => {
  const response = await api.post(`${baseURL}/Confirm`, filters);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};

export const printTags = async (id) => {
  const response = await api.get(`${baseURL}/PrintTags`, {
    params: { id },
  });
  return response.data ?? {};
};

export const getClientPrinters = async (filters) => {
  const response = await api.get(`${baseURL}/SearchClientPrinters`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => ({ noImpressora: json })) ?? [],
  };
};
