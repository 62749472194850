import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Theme,
  ResponseStatus,
  BootstrapSizes,
  PageTypes,
} from 'ui/Helpers/utils';
import {
  DatePicker,
  Autocomplete,
  Textbox,
  GridView,
  Button,
  CSDManutPage,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getBancoAutoComplete,
  getFormaPagamentoAutoComplete,
} from 'core/services/FIN';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import { setTransaction } from 'core/services/api';
import {
  deleteRecebimento,
  getRecebimento,
  saveRecebimento,
} from 'core/services/FIN/recebimento';
import { getTipoRecebimentoAutoComplete } from 'core/services/FIN/tipoRecebimento';
import { printRecibo } from 'core/services/FIN/tituloReceber';

export default function RecebimentoItem({
  onSelectPage,
  registryKey,
  reload,
  isActive,
  transaction,
  onOpenReport,
}) {
  const [message, setMessage] = useState(null);
  const [data, setData] = useState({});
  const [recebimento, setRecebimento] = useState({});
  const [loading, setLoading] = useState(false);
  const gridView = useRef();
  const formSubmit = useRef();

  const onSearchFormaPagamento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitRec: true,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    return formasPagamento;
  };
  const onSearchTipoRecebimento = async (e) => {
    const {
      status,
      message: msg,
      data: tipoRecebimento,
    } = await getTipoRecebimentoAutoComplete({ noTipoRecebimento: e });
    if (msg) setMessage(status, msg);
    return tipoRecebimento;
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteRecebimento(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchBanco = async (e) => {
    const { status, message: msg, bancos } = await getBancoAutoComplete({
      noBanco: e,
    });
    if (msg) onSetMessage(status, msg);
    return bancos;
  };

  const save = async () => {
    if (data.nrSeqTituloReceber === undefined) {
      onSetMessage(
        ResponseStatus.Error,
        'Recebimento sem titulo a receber vinculado'
      );
      return;
    }
    setLoading(true);
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);
    const lista = gridView.current ? gridView.current.getDataSource() : [];

    const obj = {
      nrSeqTransacao: transaction.nrSeqTransacao,
      tituloReceber: data,
      recebimentos: lista,
    };
    const { status, message: msg, value } = await saveRecebimento(obj);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    if (status === ResponseStatus.Success) {
      setData({
        tituloReceber: value?.tituloReceber,
        nrSeqTituloReceber: value.tituloReceber?.nrSeqTituloReceber,
        nrTit: value?.tituloReceber?.nrTit,
        nrSeqFormaPagamento: value?.tituloReceber?.nrSeqFormaPagamento,
        formaPagamento: value?.tituloReceber?.formaPagamento,
        cliente: value?.tituloReceber?.cliente,
        dtVencimento: value?.tituloReceber?.dtVencimento,
        vlrTitulo: value?.tituloReceber?.vlrTitulo,
        vlrMultaRecebida: value?.tituloReceber?.vlrMultaRecebida,
        vlrJuroRecebido: value?.tituloReceber?.vlrJuroRecebido,
        vlrSaldoTit: value?.tituloReceber?.vlrSaldoTit,
      });

      const recebimentos = [];

      if (value.recebimentos != null) {
        value.recebimentos.forEach((item) => {
          if (item.status !== 'Remover') {
            recebimentos.push(item);
          }
        });

        if (gridView && gridView.current)
          gridView.current.setDataSource(recebimentos);
      }

      if (msg !== 'Registro salvo com sucesso.') {
        onOpenReport(transaction.noTransacao, msg);
      } else {
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
      }
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    setLoading(false);
  };

  const onClickRemoveRecebimento = async (selectedValue, datasource) => {
    let tituloEspecies = [];
    if (selectedValue.nrSeqRecebimento === undefined) {
      tituloEspecies = datasource.filter(
        (tituloEspecie) => tituloEspecie !== selectedValue
      );
    } else {
      tituloEspecies = datasource.filter(
        (tituloEspecie) => tituloEspecie !== selectedValue
      );
      const tituloespecie = datasource.filter(
        (tituloEspecie) => tituloEspecie === selectedValue
      );
      tituloespecie[0].status = GridView.EnumStatus.Remover;
      tituloEspecies.push(tituloespecie[0]);
    }
    if (gridView && gridView.current)
      gridView.current.setDataSource(tituloEspecies);
  };
  const onPrintRecibo = async (param) => {
    setLoading(true);
    const recibo = await printRecibo(param.nrSeqRecebimento);

    onOpenReport(transaction.noTransacao, recibo);
    setLoading(false);
  };

  const columns = [
    {
      key: 'dtRecebimento',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'dtCredito',
      title: 'Data Credito',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma Recebimento',
    },
    {
      key: 'vlrSaldoRecebido',
      title: 'Saldo Recebido',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrJuro',
      title: 'Vlr Juro Recebido',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMulta',
      title: 'Multa Recebida',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrDesconto',
      title: ' VlrDesconto',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrTotal',
      title: 'Total',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'nrIdentificado',
      title: 'Num.Identificação',
    },
    {
      key: 'obs',
      title: 'Obs',
    },
    {
      key: 'noCheque',
      title: 'Cheque',
    },
    {
      key: 'nrCheque',
      title: 'Impr.Cheque',
    },
    {
      key: 'usuario.noLogin',
      title: 'Usuario',
    },
    {
      key: 'nrSeqRecebimento',
      type: GridView.ColumnTypes.Button,
      theme: Theme.Warning,
      onClick: (e, datasource) => onPrintRecibo(e, datasource),
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir Recibo',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqRecebimento',
      type: GridView.ColumnTypes.Button,
      theme: Theme.Danger,
      onClick: (e, datasource) => onClickRemoveRecebimento(e, datasource),
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onSearchEmpresa = async (e) => {
    const { empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    return empresas;
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const obj = await getRecebimento(registryKey);
      setData({
        tituloReceber: obj?.tituloReceber,
        nrSeqTituloReceber: obj.tituloReceber?.nrSeqTituloReceber,
        nrTit: obj?.tituloReceber?.nrTit,
        nrSeqFormaPagamento: obj?.tituloReceber?.nrSeqFormaPagamento,
        formaPagamento: obj?.tituloReceber?.formaPagamento,
        cliente: obj?.tituloReceber?.cliente,
        dtVencimento: obj?.tituloReceber?.dtVencimento,
        vlrTitulo: obj?.tituloReceber?.vlrTitulo,
        vlrMultaRecebida: obj?.tituloReceber?.vlrMultaRecebida,
        vlrJuroRecebido: obj?.tituloReceber?.vlrJuroRecebido,
        vlrSaldoTit: obj?.tituloReceber?.vlrSaldoTit,
        vlrDesconto: obj?.tituloReceber?.vlrDesconto,
      });

      setRecebimento({
        empresa: obj.tituloReceber?.empresa,
        nrSeqFormaPagamento: obj.tituloReceber?.nrSeqFormaPagamento,
        nrSeqEmpresa: obj.tituloReceber?.nrSeqEmpresa,
        dtRecebimento: obj?.tituloReceber?.dtVencimento,
        formaPagamento: obj.tituloReceber?.formaPagamento,
        vlrSaldoRecebido: obj.tituloReceber?.vlrSaldoTit,
        vlrMulta: 0,
        vlrJuro: 0,
      });
      if (obj.recebimentos != null) {
        if (gridView && gridView.current)
          gridView.current.setDataSource(obj.recebimentos);
      }
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const onAdicionarRecebimento = async () => {
    if (recebimento.dtRecebimento === undefined) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar sem a data de pagamento'
      );
      return;
    }
    if (recebimento.vlrSaldoRecebido === undefined) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar sem o valor do pagamento'
      );
      return;
    }
    if (recebimento.vlrSaldoRecebido > data.vlrSaldoTit) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar um valor maior que o saldo do titulo'
      );
      return;
    }

    if (recebimento.vlrDesconto > data.vlrSaldoTit) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar um valor desconto maior que o saldo do titulo'
      );
      return;
    }

    if (recebimento.vlrMulta === undefined) {
      recebimento.vlrMulta = 0;
    }
    if (recebimento.vlrJuro === undefined) {
      recebimento.vlrJuro = 0;
    }

    if (recebimento.vlrDesconto === undefined) {
      recebimento.vlrDesconto = 0;
    }

    let lista = gridView.current.getDataSource();
    if (lista === undefined) {
      lista = [];
    }
    recebimento.vlrTotal =
      recebimento.vlrSaldoRecebido +
      recebimento.vlrJuro +
      recebimento.vlrMulta -
      recebimento.vlrDesconto;
    if (recebimento.status !== GridView.EnumStatus.Alterar) {
      recebimento.status = GridView.EnumStatus.Inserir;
    }
    lista.push(recebimento);
    if (gridView && gridView.current) gridView.current.setDataSource(lista);
    setRecebimento({
      vlrJuro: 0,
      vlrMulta: 0,
      vlrSaldoRecebido: 0,
      vlrDesconto: 0,
    });
  };

  const { id: idSelecao } = PageTypes.Selection;

  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Recebimento'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqRecebimento > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row mb-6'>
        <div className='card mb-6'>
          <div className='card-body mb-6'>
            <div className='row mb-3'>
              <div className='row mb-3'>
                <div className='col-4'>
                  <Textbox label='Documento/Ordem' text={data.nrTit} readOnly />
                </div>
                <div className='col-4'>
                  <Textbox
                    label='Cliente'
                    text={data.cliente?.noPessoa}
                    readOnly
                  />
                </div>
                <div className='col-4'>
                  <DatePicker
                    label='Vencimento'
                    readOnly
                    text={data.dtVencimento}
                    mask={MaskTypes.Date}
                    onChange={(dtVencimento) =>
                      setData({
                        ...data,
                        dtVencimento,
                      })
                    }
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <Textbox
                    label='Total Titulo'
                    text={data?.vlrTitulo}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Desconto'
                    text={data?.vlrDesconto}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Multa'
                    text={data?.vlrMultaRecebida}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Juros'
                    text={data?.vlrJuroRecebido}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Saldo Titulo'
                    text={data?.vlrSaldoTit}
                    mask={MaskTypes.DecimalCustom}
                    readOnly
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <span>
                  * Os valores de encargos acima mostrados, já estão inclusos no
                  saldo a receber, portanto só deve-se informar os campos de
                  encargos abaixo, caso queira receber encargos além dos ja
                  informados
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-6'>
          <Autocomplete
            label='Empresa'
            searchDataSource={onSearchEmpresa}
            selectedItem={recebimento?.empresa}
            onSelectItem={(empresa) => {
              setRecebimento({
                ...recebimento,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              });
            }}
            dataSourceTextProperty='noPessoa'
            enabled={data?.flgPermiteAlterar}
          />
        </div>

        <div className='col-6'>
          <Autocomplete
            label='Forma Recebimento'
            searchDataSource={onSearchFormaPagamento}
            selectedItem={recebimento.formaPagamento}
            onSelectItem={(formaPagamento) => {
              setRecebimento({
                ...recebimento,
                formaPagamento,
                nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
              });
            }}
            dataSourceTextProperty='noFormaPagamento'
          />
        </div>
      </div>

      <div className='row'>
        {recebimento.formaPagamento?.flgGrupo === 'C' && (
          <div className='col-6 mb-3'>
            <DatePicker
              label='Data Cheque'
              required
              text={recebimento.dtCheque}
              maxLength={10}
              mask={MaskTypes.Date}
              onChange={(dtCheque) =>
                setRecebimento({ ...recebimento, dtCheque })
              }
            />
          </div>
        )}
        {recebimento.formaPagamento?.flgGrupo === 'C' &&
          !recebimento.formaPagamento?.flgControlaCheque && (
            <div className='col-6 mb-3'>
              <Textbox
                label='Nr. Cheque'
                text={recebimento.nrCheque}
                onChangedValue={(nrCheque) =>
                  setRecebimento({ ...recebimento, nrCheque })
                }
              />
            </div>
          )}
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <DatePicker
            label='Recebimento'
            text={recebimento.dtRecebimento}
            mask={MaskTypes.Date}
            onChange={(dtRecebimento) =>
              setRecebimento({
                ...recebimento,
                dtRecebimento,
              })
            }
          />
        </div>
        <div className='col'>
          <DatePicker
            label='Credito'
            text={recebimento.dtCredito}
            mask={MaskTypes.Date}
            onChange={(dtCredito) =>
              setRecebimento({
                ...recebimento,
                dtCredito,
              })
            }
          />
        </div>
        <div className='col'>
          <Textbox
            label='Numero Identificação'
            maxLength={40}
            text={recebimento.nrIdentificado}
            onChangedValue={(nrIdentificado) =>
              setRecebimento({
                ...recebimento,
                nrIdentificado,
              })
            }
          />
        </div>
        <div className='col'>
          <Textbox
            label='Total a Receber'
            text={recebimento.vlrSaldoRecebido}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(vlrSaldoRecebido) =>
              setRecebimento({
                ...recebimento,
                vlrSaldoRecebido,
              })
            }
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Valor Desconto'
            text={recebimento.vlrDesconto}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(vlrDesconto) =>
              setRecebimento({
                ...recebimento,
                vlrDesconto,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Tipo Recebimento'
            selectedItem={recebimento.tipoRecebimento}
            searchDataSource={onSearchTipoRecebimento}
            onSelectItem={(tipoRecebimento) => {
              setRecebimento({
                ...recebimento,
                tipoRecebimento,
                nrSeqTipoRecebimento: tipoRecebimento.nrSeqTipoRecebimento,
              });
            }}
            dataSourceTextProperty='noTipoRecebimento'
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Valor Multa'
            text={recebimento.vlrMulta}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(vlrMulta) =>
              setRecebimento({
                ...recebimento,
                vlrMulta,
              })
            }
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Valor Juros'
            text={recebimento.vlrJuro}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(vlrJuro) =>
              setRecebimento({
                ...recebimento,
                vlrJuro,
              })
            }
          />
        </div>
        <div className='col'>
          <DatePicker
            label='Data Estorno'
            text={recebimento.dtEstorno}
            mask={MaskTypes.Date}
          />
        </div>
      </div>
      {data.flgExibeCheque && (
        <div className='row mb-3'>
          <div className='col'>
            <DatePicker
              label='Data Cheque'
              text={recebimento.dtCheque}
              mask={MaskTypes.Date}
              onChange={(dtCheque) =>
                setRecebimento({ ...recebimento, dtCheque })
              }
            />
          </div>
          <div className='col-2'>
            <Textbox
              label='Número Cheque'
              text={recebimento.nrCheque}
              onChangedValue={(nrCheque) =>
                setRecebimento({
                  ...recebimento,
                  nrCheque,
                })
              }
            />
          </div>
          <div className='col-4'>
            <Autocomplete
              label='Banco'
              searchDataSource={onSearchBanco}
              selectedItem={recebimento.banco}
              onSelectItem={(banco) => {
                setRecebimento({
                  ...recebimento,
                  banco,
                  nrSeqBanco: banco.nrSeqBanco,
                });
              }}
              dataSourceTextProperty='noBanco'
            />
          </div>
          <div className='col'>
            <Textbox
              label='Agencia'
              text={recebimento?.noAgencia}
              onChangedValue={(noAgencia) =>
                setRecebimento({
                  ...recebimento,
                  noAgencia,
                })
              }
            />
          </div>
          <div className='col'>
            <Textbox
              label='Conta'
              text={recebimento?.noConta}
              onChangedValue={(noConta) =>
                setRecebimento({
                  ...recebimento,
                  noConta,
                })
              }
            />
          </div>
        </div>
      )}
      <div className='col-3 mt-3'>
        <Button
          theme={Theme.Primary}
          template={Button.Templates.Button}
          text='Adicionar'
          onClick={onAdicionarRecebimento}
        />
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
