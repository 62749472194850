import qs from 'qs';
import Pagination from 'core/models/pagination';
import PlanoDeConta from 'core/models/FIN/planoDeConta';
import api from '../api';

const baseURL = `/FIN/PlanoDeConta`;

export const getPlanoDeContaList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    planodecontas: value?.map((json) => new PlanoDeConta(json)) ?? [],
  };
};

export const getPlanoDeContaAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    planodecontas: value?.map((json) => new PlanoDeConta(json)) ?? [],
  };
};

export const getPlanoDeContaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });

  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PlanoDeConta(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPlanoDeConta = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};

  return value ? new PlanoDeConta(value) : {};
};

export const savePlanoDeConta = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePlanoDeConta = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPlanoDeConta = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPlanoDeConta = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data.value ?? {};
};

export const deleteAllPlanoDeConta = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getPlanoDeContaPaiAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompletePai`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PlanoDeConta(json)) ?? [],
  };
};

export const getPlanoDeCustoPaiTreeview = async (filters) => {
  const response = await api.get(`${baseURL}/Treeview`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    planos: value?.map((json) => new PlanoDeConta(json)) ?? [],
  };
};
