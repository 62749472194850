export default class LogEnvioSms {
  constructor(jsonObject = {}) {
    this.nrSeqLogEnvioSms = jsonObject.nrSeqLogEnvioSms;
    this.nrTelefone = jsonObject.nrTelefone;
    this.noMensagem = jsonObject.noMensagem;
    this.noStatus = jsonObject.noStatus;
    this.noStatusRetorno = jsonObject.noStatusRetorno;
    this.dtCad = jsonObject.dtCad;
    this.nrSeqContaRecurso = jsonObject.nrSeqContaRecurso;
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.nrTelefoneOrigem = jsonObject.nrTelefoneOrigem;
    this.dtCadInicio = jsonObject.dtCadInicio;
    this.dtCadFim = jsonObject.dtCadFim;
    this.dtCadStr = jsonObject.dtCadStr;
    this.noEmailOrigem = jsonObject.noEmailOrigem;
    this.noEmailDestino = jsonObject.noEmailDestino;
    this.noOrigem = jsonObject.noOrigem;
    this.noDestino = jsonObject.noDestino;
    this.noMeioEnvioMensagem = jsonObject.noMeioEnvioMensagem;
  }

  toJson = () => JSON.stringify(this);
}
