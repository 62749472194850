import React, { useState, useRef, useEffect } from 'react';

import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  Loader,
  Modal,
  Notification,
  GridView,
  Switch,
  Textbox,
} from 'ui/components';

import { MaskTypes } from 'ui/Helpers/masks';
import { changeCostCenter } from 'core/services/TEL/clienteLinha';

import { getCentroDeCustoAutoComplete } from 'core/services/TEL/centroDeCusto';

export default function ModalAlterarCentroDeCusto({ recurso, show, onClose }) {
  const [data, setData] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const gridViewAlterarCentroDeCusto = useRef();

  const columnsLinhas = [
    { key: 'NrSeqClienteLinha', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'noPessoa',
      title: 'Cliente',
    },
    {
      key: 'nroFoneCompleto',
      title: 'Nr. Linha',
    },
    {
      key: 'codNoCentroCusto',
      title: 'Centro de Custo Atual',
    },
    {
      key: 'noPlano',
      title: 'Plano',
    },
    {
      key: 'dtAquisicao',
      title: 'Dt Aquis.',
    },
    {
      key: 'flgSituacaoStr',
      title: 'Situação',
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchCentroDeCusto = async (e) => {
    const {
      status,
      message: msg,
      data: centros,
    } = await getCentroDeCustoAutoComplete({
      codNoCentroDeCusto: e,
    });
    onSetMessage(status, msg);
    return centros;
  };
  useEffect(() => {
    (async function load() {
      if (recurso.length > 0) {
        setData({ flgCancelarClienteLinha: false });
        gridViewAlterarCentroDeCusto.current.setDataSource(recurso);
      }
    })();
  }, [recurso]);

  const onSend = async () => {
    setLoading(true);
    const nrSeqClientesLinhas = recurso.map(
      (column) => column.nrSeqClienteLinha
    );

    const recursos = {
      nrSeqClientesLinhas,
      nrSeqCentroDeCusto: data.nrSeqCentroDeCusto,
      flgCancelarClienteLinha: data.flgCancelarClienteLinha,
      dtAquisicao: data.dtAquisicao,
    };

    const { status, message: msg } = await changeCostCenter(recursos);
    setLoading(false);
    onSetMessage(status, msg);
  };

  return (
    <Modal
      show={show}
      title='Troca Centro de Custo Cliente Linha'
      onClose={onClose}
      size={BootstrapSizes.Large}
      icon={['far', 'comment-alt']}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col'>
            <Autocomplete
              label='Centro de Custo'
              searchDataSource={onSearchCentroDeCusto}
              selectedItem={data.centroDeCusto}
              onSelectItem={(centroDeCusto) =>
                setData({
                  ...data,
                  centroDeCusto,
                  nrSeqCentroDeCusto: centroDeCusto.nrSeqCentroDeCusto,
                })
              }
              dataSourceTextProperty='noCentroDeCusto'
            />
          </div>

          <div className='col-auto'>
            <Switch
              formControl
              label='Cancelar Cliente Linha'
              onChange={(flgCancelarClienteLinha) =>
                setData({
                  ...data,
                  flgCancelarClienteLinha,
                })
              }
            />
          </div>

          <div className='col-sm-3'>
            <Textbox
              label='Data Aquisição'
              text={data.dtAquisicao}
              visible={data.flgCancelarClienteLinha}
              maxLength={10}
              mask={MaskTypes.Date}
              onChangedValue={(dtAquisicao) =>
                setData({
                  ...data,
                  dtAquisicao,
                })
              }
            />
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridViewAlterarCentroDeCusto}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnsLinhas}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Salvar'
          theme={Theme.Success}
          icon='sms'
          onClick={onSend}
        />
        <Button
          text='Cancelar'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            setData({});
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
