export default class PocOrcamentoStatus {
  constructor(jsonObject = {}) {
    this.nrSeqPocOrcamentoStatus = jsonObject.nrSeqPocOrcamentoStatus;
    this.noPocOrcamentoStatus = jsonObject.noPocOrcamentoStatus;
    this.nrOrdem = jsonObject.nrOrdem;
    this.noColor = jsonObject.noColor;
  }

  toJson = () => JSON.stringify(this);
}
