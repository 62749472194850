export default class EspecieFrete {
  constructor(jsonObject = {}) {
    this.nrSeqEspecieFrete = jsonObject.nrSeqEspecieFrete;
    this.cdEspecieFrete = jsonObject.cdEspecieFrete;
    this.noEspecieFrete = jsonObject.noEspecieFrete;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
