import React, { useState, useImperativeHandle, useRef } from 'react';

const Form = React.forwardRef(
  ({ className, children, onValidate, onSubmit, removeValidate }, ref) => {
    const [classState, setClassState] = useState('needs-validation');
    const form = useRef(ref?.current ?? null);
    const handleValidate = (e) => {
      e.preventDefault();
      e.stopPropagation();

      if ((!onValidate || onValidate()) && e.target.checkValidity()) {
        if (onSubmit) onSubmit();
      }

      if (!removeValidate) setClassState(`${classState} was-validated`);
    };

    useImperativeHandle(ref, () => ({
      reset() {
        if (!removeValidate)
          setClassState(classState.replace(' was-validated', ''));
      },
      submit() {
        form.current.dispatchEvent(
          new Event('submit', { cancelable: true, bubbles: true })
        );
      },
    }));

    const classes = [classState];

    if (className) classes.push(className);
    return (
      <form
        ref={form}
        noValidate
        className={classes.join(' ')}
        onSubmit={handleValidate}
      >
        {children}
      </form>
    );
  }
);

export default Form;
