import qs from 'qs';
import Pagination from 'core/models/pagination';
import ProdutoAgrupador from 'core/models/EST/produtoAgrupador';
import api from '../api';

const baseURL = `/EST/ProdutoAgrupador`;

export const getProdutoAgrupadorList = async (filters) => {
  const response = await api.get(`/$ProdutoAgrupador/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ProdutoAgrupador(json)) ?? [],
  };
};

export const getProdutoAgrupadorPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ProdutoAgrupador(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getProdutoAgrupador = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ProdutoAgrupador(value) : {};
};

export const getProdutoConfig = async () => {
  const response = await api.get(`${baseURL}/getProdutoConfig`);
  const { value } = response.data ?? {};
  return value;
};

export const saveProdutoAgrupador = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteProdutoAgrupador = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printProdutoAgrupador = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelProdutoAgrupador = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllProdutoAgrupador = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getProdutoAgrupadorAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ProdutoAgrupador(json)) ?? [],
  };
};

export const getProdutoAgrupadorAutoCompleteDetalhado = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteDetalhado`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ProdutoAgrupador(json)) ?? [],
  };
};
