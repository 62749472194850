import { Chip } from 'core/models/TEL';
import { MimeTypes } from 'ui/Helpers/utils';
import api from '../api';

const base = 'TEL/Chip';

export const getChipAutoComplete = async (filters) => {
  const response = await api.get(`${base}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    chips: value?.map((json) => new Chip(json)) ?? [],
  };
};

export const getChipAutoCompleteChipDemanda = async (filters) => {
  const response = await api.get(`${base}/AutoCompleteChipDemanda`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    chips: value?.map((json) => new Chip(json)) ?? [],
  };
};

export const getChipAutoCompleteMVNO = async (filters) => {
  const response = await api.get(`${base}/AutoCompleteMVNO`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    chips: value?.map((json) => new Chip(json)) ?? [],
  };
};

export const getChipAutoCompleteDemanda = async (filters) => {
  const response = await api.get(`${base}/AutoCompleteDemanda`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    chips: value?.map((json) => new Chip(json)) ?? [],
  };
};

export const getChip = async (id) => {
  const response = await api.get(`${base}`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Chip(value) : {};
};

export const changeChipOperator = async (data) => {
  const response = await api.put(`${base}/ChangeChipOperator`, data);

  return response.data ?? {};
};

export const changeChipCompany = async (data) => {
  const response = await api.put(`${base}/changeChipCompany`, data);

  return response.data ?? {};
};

export const getChipPai = async (filters) => {
  const response = await api.get(`${base}/GetChipPai`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    chips: value?.map((json) => new Chip(json)) ?? [],
  };
};

export const getPrefixos = async (filters) => {
  const response = await api.get(`${base}/SearchPrefixo`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    chips: value?.map((json) => new Chip(json)) ?? [],
  };
};

export const printChips = async (filters) => {
  const response = await api.get(`${base}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const search = async (filters) => {
  const response = await api.get(`${base}/Search`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    pagination,
    chips: value?.map((json) => new Chip(json)) ?? [],
  };
};

export const saveChip = async (payload) => {
  const response = await api.post(`${base}/Salvar`, payload);
  return response.data ?? {};
};

export const saveChipVirgem = async (payload) => {
  const response = await api.post(`${base}/SalvarChipVirgem`, payload);
  const { status, message, value } = response.data;

  return { status, message, value };
};

export const deleteChip = async (id) => {
  const response = await api.delete(`${base}/Excluir`, {
    params: { id },
  });
  return response.data ?? {};
};

export const trocaEmpresa = async (data) => {
  const response = await api.post(`${base}/TrocarEmpresa`, data);
  return response.data ?? {};
};

export const importarChip = async (file, data) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('extension', MimeTypes.getExtension(file.type));
  formData.append('nrSeqEmpresa', data.nrSeqEmpresa);
  formData.append('nrSeqOperadora', data.nrSeqOperadora);
  formData.append('flgStatus', data.flgStatus);
  formData.append('flgSituacao', data.flgSituacao);
  formData.append('noTipo', data.noTipo);
  const response = await api.post(`${base}/ImportarChip`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    retorno: value,
  };
};

export const excelChips = async (filters) => {
  const response = await api.get(`${base}/ExcelPrint`, {
    params: filters,
  });

  return response.data ?? {};
};

export const searchLinhasParada = async (filters) => {
  const response = await api.get(`${base}/SearchLinhasParada`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Chip(json)) ?? [],
  };
};
