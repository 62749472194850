import React, { useEffect } from 'react';
import EnergiaContaConsumo from 'core/models/ENE/energiaContaConsumo';
import EnergiaConta from 'core/models/ENE/energiaConta';
import { GridView, Modal } from 'ui/components';
import { ColumnTypes, StatusGrid } from 'ui/Helpers/enums';
import { GridviewColumns } from 'ui/Helpers/interfaces';

interface IProps {
  conta: EnergiaConta | undefined;
  show: boolean;
  gridView: any;
  onClose: () => void;
}

export const ModalDetalhamentoConsumoFatura = ({
  conta,
  gridView,
  onClose,
  show,
}: IProps) => {
  useEffect(() => {
    if (gridView && gridView.current && conta !== undefined) {
      gridView.current.setDataSource(conta.consumos);
    }
  }, [show]);

  const handleChangeConsumo = (key: string, value: number) => {
    const consumos: EnergiaContaConsumo[] = gridView.current.getDataSource();

    consumos.forEach((item) => {
      if (item.anoMesRefFormated === key) {
        item.vlrConsumo = value;
        item.status = StatusGrid.Alterar;
      }
    });

    if (gridView && gridView.current) {
      gridView.current.setDataSource(consumos);
    }
  };

  const columnsConsumos: GridviewColumns[] = [
    {
      key: 'anoMesRefFormated',
      title: 'Ano/Mês Ref.',
      sortKey: 'anoMesRef',
      sortable: false,
    },
    { key: 'nrDias', title: 'Qtd. Dias Consumo', sortable: false },
    {
      key: 'vlrConsumo',
      title: 'Consumo (kWh)',
      sortable: false,
      type: conta !== undefined ? ColumnTypes.Textbox : undefined,
      onBlur: (key: string, value: number) => handleChangeConsumo(key, value),
    },
  ];

  return (
    // @ts-expect-error
    <Modal show={show} title='Consumos da fatura' onClose={onClose}>
      {/* @ts-expect-error */}
      <Modal.Body>
        <GridView
          ref={gridView}
          // @ts-expect-error
          dataColumns={columnsConsumos}
          showSelectSizes={false}
          showPagination={false}
        />
      </Modal.Body>
    </Modal>
  );
};
