import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Switch,
  Panel,
  Autocomplete,
  Textbox,
  GridView,
  Button,
  ToolbarButtons,
} from 'ui/components';
import {
  deletePermissaoFromUser,
  getPermissaoPagined,
  savePermissao,
} from 'core/services/SEG/permissao';
import { getPerfil } from 'core/services/SEG/perfil';
import { getTransacaoList } from 'core/services/SEG/transacao';

const columns = [
  { key: 'nrSeqTransacao', type: GridView.ColumnTypes.Checkbox },
  { key: 'transacao.noTransacao', title: 'Menu' },
  { key: 'flgNovo', title: 'Novo' },
  { key: 'flgExcluir', title: 'Excluir' },
  { key: 'flgSalvar', title: 'Salvar' },
  { key: 'flgPesquisar', title: 'Pesquisar' },
  { key: 'flgImprimir', title: 'Imprimir' },
  { key: 'flgAbrir', title: 'Abrir' },
];

export default function PermissaoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [filtros, setFiltros] = useState({});
  const [data, setData] = useState({
    flgAbrir: false,
    flgNovo: false,
    flgExcluir: false,
    flgPesquisar: false,
    flgSalvar: false,
    flgImprimir: false,
  });
  const [allChecked, setAllChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const gridView = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onNew = async () => {
    setData({});

    if (gridView && gridView.current) gridView.current.setDataSource(null);

    setMessage(null);
  };

  const resetFormFields = async () => {
    setData({
      ...data,
      flgAbrir: false,
      flgNovo: false,
      flgExcluir: false,
      flgPesquisar: false,
      flgSalvar: false,
      flgImprimir: false,
      transacao: {},
    });
  };

  const onCheckAllFlgs = async (all) => {
    const switchFlgTodos = all;

    if (switchFlgTodos) {
      setData({
        ...data,
        flgAbrir: true,
        flgNovo: true,
        flgExcluir: true,
        flgPesquisar: true,
        flgSalvar: true,
        flgImprimir: true,
      });
    } else {
      setData({
        ...data,
        flgAbrir: false,
        flgNovo: false,
        flgExcluir: false,
        flgPesquisar: false,
        flgSalvar: false,
        flgImprimir: false,
      });
    }
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const perfil = await getPerfil(registryKey);

      setData({
        ...data,
        noPerfil: perfil.noPerfil,
        nrSeqPerfil: perfil.nrSeqPerfil,
      });

      setFiltros({
        ...filtros,
        noPerfil: perfil.noPerfil,
        nrSeqPerfil: perfil.nrSeqPerfil,
      });

      const retorno = await getPermissaoPagined({ nrSeqPerfil: registryKey });

      if (gridView && gridView.current)
        gridView.current.setDataSource(retorno.data);

      setLoading(false);
    } else resetFormFields();
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const save = async () => {
    const listPermissoesSalvar = gridView.current
      .getDataSource()
      .filter((item) => item.status === GridView.EnumStatus.Inserir);

    if (listPermissoesSalvar.length < 1) {
      onSetMessage(
        ResponseStatus.Error,
        'Não há novas permissões para salvar.'
      );
      return;
    }

    setLoading(true);

    const { status, message: msg } = await savePermissao(listPermissoesSalvar);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onSearchTransacao = async (e) => {
    const { status, message: msg, data: transacoes } = await getTransacaoList({
      noTransacao: e,
      nrSeqPerfil: data.nrSeqPerfil,
    });

    if (msg) onSetMessage(status, msg);
    return transacoes;
  };

  const search = async (params = null) => {
    setLoading(true);

    const { data: noTransacaoAuxiliar, pagination } = await getPermissaoPagined(
      params ?? data
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(noTransacaoAuxiliar, pagination);

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const transacoesIds = selecteds.map((row) => row[0].value);

      const { status, message: msg } = await deletePermissaoFromUser(
        transacoesIds,
        data.nrSeqPerfil
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onAdicionarPermissao = async () => {
    if (data.nrSeqTransacao === null) {
      onSetMessage(
        ResponseStatus.Error,
        'É necessário selecionar uma opção no Menu para adicionar permissão.'
      );
      return;
    }

    const lista = gridView.current.getDataSource() || [];
    const listaAux = [];

    const obj = { ...data, status: GridView.EnumStatus.Inserir };

    if (
      lista.filter((p) => p.nrSeqTransacao === obj.nrSeqTransacao).length > 0
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Menu selecionado já consta na lista de permissões do perfil.'
      );
      return;
    }

    lista.push(obj);
    listaAux.push(obj);
    if (gridView && gridView.current) gridView.current.setDataSource(lista);

    setAllChecked(false);
    await resetFormFields();
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Permissao'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      onSearch={() => search()}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
      ref={formSubmit}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Remove Trans Marcados'
          icon={['far', 'trash']}
          onClick={() => onDelete()}
        />
      </ToolbarButtons>
      <Panel className='mb-5'>
        <Panel.Header
          icon={['fas', 'user']}
          title='Menu Permissão'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-8'>
              <Textbox
                readOnly
                label='Perfil'
                text={filtros.noPerfil}
                onChangedValue={(noPerfil) => setData({ ...filtros, noPerfil })}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-8 mb-3'>
              <Autocomplete
                label='Menu'
                searchDataSource={onSearchTransacao}
                selectedItem={data.transacao}
                onSelectItem={(transacao) => {
                  setData({
                    ...data,
                    transacao,
                    nrSeqTransacao: transacao.nrSeqTransacao,
                  });
                }}
                dataSourceTextProperty='noTransacao'
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-10'>
              <div className='row'>
                <div className='col-2 mb-3'>
                  <Switch
                    formControl
                    label='Todos'
                    checked={allChecked}
                    onChange={(check) => {
                      setAllChecked(check);
                      onCheckAllFlgs(check);
                    }}
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Switch
                    formControl
                    label='Novo'
                    checked={data.flgNovo}
                    onChange={(flgNovo) => setData({ ...data, flgNovo })}
                  />
                </div>

                <div className='col-2 mb-3'>
                  <Switch
                    formControl
                    label='Excluir'
                    checked={data.flgExcluir}
                    onChange={(flgExcluir) => setData({ ...data, flgExcluir })}
                  />
                </div>

                <div className='col-2 mb-3'>
                  <Switch
                    formControl
                    label='Salvar'
                    checked={data.flgSalvar}
                    onChange={(flgSalvar) => setData({ ...data, flgSalvar })}
                  />
                </div>

                <div className='col-2 mb-3'>
                  <Switch
                    formControl
                    label='Pesquisar'
                    checked={data.flgPesquisar}
                    onChange={(flgPesquisar) =>
                      setData({ ...data, flgPesquisar })
                    }
                  />
                </div>

                <div className='col-2 mb-3'>
                  <Switch
                    formControl
                    label='Imprimir'
                    checked={data.flgImprimir}
                    onChange={(flgImprimir) =>
                      setData({ ...data, flgImprimir })
                    }
                  />
                </div>
              </div>
            </div>

            <div className='col-1'>
              <div className='row'>
                <div className='col mb-3'>
                  <Switch
                    formControl
                    label='Abrir'
                    checked={data.flgAbrir}
                    onChange={(flgAbrir) => setData({ ...data, flgAbrir })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <Button
                outline
                theme={Theme.Success}
                template={Button.Templates.Button}
                text='Adicionar'
                onClick={onAdicionarPermissao}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>

      <div className='row'>
        <div className='col'>
          <GridView
            enableExcelExport
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
