import TipoServico from '../GProj/tipoServico';
import ContratoServico from '../GProj/contratoServico';
import ProdutoServico from './produtoServico';

export default class NfsItem {
  constructor(jsonObject = {}) {
    this.nrSeqNfs = jsonObject.nrSeqNfs;
    this.nrSeqNfsItem = jsonObject.nrSeqNfsItem;
    this.nrSeqProdutoServico = jsonObject.nrSeqProdutoServico;
    this.quantidade = jsonObject.quantidade;
    this.valorUnitario = jsonObject.valorUnitario;
    this.noDescricao = jsonObject.noDescricao;
    this.vlrTotal = jsonObject.vlrTotal;
    this.vlrDesconto = jsonObject.vlrDesconto;
    this.nrSeqContratoServico = jsonObject.nrSeqContratoServico;
    this.nrSeqTituloReceber = jsonObject.nrSeqTituloReceber;
    this.nrSeqTipoServico = jsonObject.nrSeqTipoServico;

    this.tipoServico =
      jsonObject.tipoServico !== undefined && jsonObject.tipoServico !== null
        ? new TipoServico(jsonObject.tipoServico)
        : new TipoServico();

    this.contratoServico =
      jsonObject.contratoServico !== undefined &&
      jsonObject.contratoServico !== null
        ? new ContratoServico(jsonObject.contratoServico)
        : new ContratoServico();

    this.produtoServico =
      jsonObject.produtoServico !== undefined &&
      jsonObject.produtoServico !== null
        ? new ProdutoServico(jsonObject.produtoServico)
        : new ProdutoServico();
  }

  toJson = () => JSON.stringify(this);
}
