import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  getFretePreco,
  saveFretePreco,
  deleteFretePreco,
} from 'core/services/FRO/fretePreco';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  Switch,
  DatePicker,
  GridView,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces';
import { ColumnDataTypes } from 'ui/Helpers/enums';
import FretePreco from 'core/models/FRO/fretePreco';
import { getClienteAutoComplete } from 'core/services/FIN';
import {
  getPessoaAutoCompleteRemDesCli,
  getCidadeAutoComplete,
} from 'core/services/SEG';
import { getFreteTipoCalculoAutoComplete } from 'core/services/FRO/freteTipoCalculo';
import { getTipoEmbarqueAutoComplete } from 'core/services/FRO/tipoEmbarque';
import { getFreteMercadoriaAutoComplete } from 'core/services/FRO/freteMercadoria';

export default function FretePrecoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit: any = useRef();
  const [data, setData] = useState<FretePreco | {}>({});
  const gridView: any = useRef();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const onNew = (): void => {
    formSubmit.current.reset();
    setData({ vlrCalculado: 0, vlrTarifaAdicional: 0 });
    gridView?.current?.setDataSource([]);
  };

  const load = useCallback(async (): Promise<void> => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const obj: FretePreco | {} = await getFretePreco(registryKey);

      setData(obj);

      if (gridView && gridView.current) {
        gridView.current.setDataSource(
          'auditoriaFretePrecos' in obj ? obj.auditoriaFretePrecos : null
        );
      }
      setLoading(false);
    } else {
      onNew();
    }

    setMessage(null);
  }, [registryKey]);

  useEffect((): void => {
    load();
  }, [load, registryKey, reload]);

  const save = async (): Promise<void> => {
    setLoading(true);
    const { status, message: msg } = await saveFretePreco(data);
    if (status === ResponseStatus.Success) {
      onNew();
    } else {
      setData(data);
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onExcluir = async (): Promise<void> => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const { status, message: msg } = await deleteFretePreco(registryKey);

      if (status === ResponseStatus.Success) {
        onNew();
      } else {
        setData(data);
      }

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      setLoading(false);
    }
  };

  const onSearchCliente = async (e: string): Promise<Array<any>> => {
    const { clientes }: { clientes: Array<any> } = await getClienteAutoComplete(
      {
        noPessoa: e,
      }
    );

    return clientes;
  };

  const onSearchPessoa = async (e: any) => {
    const { data: pessoaRems } = await getPessoaAutoCompleteRemDesCli({
      noPessoa: e,
      noPessoaTipo: 'REM',
    });
    return pessoaRems;
  };

  const onSearchTipoEmbarque = async (e: string) => {
    const { data: tiposEmbarque } = await getTipoEmbarqueAutoComplete({
      noTipoEmbarque: e,
    });

    return tiposEmbarque;
  };

  const onSearchFreteMercadoria = async (e: string) => {
    const { data: freteMercadorias } = await getFreteMercadoriaAutoComplete({
      noFreteMercadoria: e,
    });
    return freteMercadorias;
  };

  const onSearchEstado = async (e: string) => {
    const { estados } = await getEstadoAutoComplete({
      noEstado: e,
    });
    return estados;
  };

  const onSearchCidadeOri = async (e: string) => {
    const { cidades } = await getCidadeAutoComplete({
      noCidade: e,
      nrSeqEstado: data.nrSeqEstadoOri,
    });

    return cidades;
  };

  const onSearchCidadeDest = async (e: string) => {
    const { cidades } = await getCidadeAutoComplete({
      noCidade: e,
      nrSeqEstado: data.nrSeqEstadoDest,
    });

    return cidades;
  };

  const onSearchFreteTipoCalculo = async (e: string) => {
    const { tipoCalculos } = await getFreteTipoCalculoAutoComplete({
      noFreteTipoCalculo: e,
    });

    return tipoCalculos;
  };

  const columns: Array<GridviewColumns> = [
    { key: 'pessoaAtualizacao.noPessoa', title: 'Usuário' },
    { key: 'cliente.noPessoa', title: 'Pagador' },
    { key: 'pessoa.noPessoa', title: 'Pessoa' },
    { key: 'freteMercadoria.noFreteMercadoria', title: 'Mercadoria' },
    { key: 'estadoOri.noEstado', title: 'Estado Ori.' },
    { key: 'estadoDest.noEstado', title: 'Estado Dest.' },
    { key: 'cidadeOri.noCidade', title: 'Cidade Ori.' },
    { key: 'cidadeDest.noCidade', title: 'Cidade Dest.' },
    { key: 'freteTipoCalculo.noFreteTipoCalculo', title: 'Tipo Calculo' },
    { key: 'dtInicio', title: 'Data Inicial', format: ColumnDataTypes.Date },
    { key: 'dtFim', title: 'Data Final', format: ColumnDataTypes.Date },
    { key: 'faixaIni', title: 'Faixa Inicial' },
    { key: 'faixaFim', title: 'Faixa Fim' },
    {
      key: 'vlrCalculado',
      title: 'Valor Calculado',
      format: ColumnDataTypes.Decimal,
    },
  ];

  const { id: idSelecao } = PageTypes.Selection;

  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Frete Preço'
      transaction={transaction}
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      onDelete={
        'nrSeqFretePreco' in data && data?.nrSeqFretePreco > 0
          ? onExcluir
          : null
      }
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Pagador'
            searchDataSource={onSearchCliente}
            selectedItem={data.cliente}
            onSelectItem={(cliente: any) => {
              setData({
                ...data,
                cliente,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Pessoa'
            searchDataSource={onSearchPessoa}
            selectedItem={data.pessoa}
            onSelectItem={(pessoa: any) => {
              setData({
                ...data,
                pessoa,
                nrSeqPessoa: pessoa.nrSeqPessoa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Tipo Embarque'
            searchDataSource={onSearchTipoEmbarque}
            selectedItem={data.tipoEmbarque}
            onSelectItem={(tipoEmbarque: any) => {
              setData({
                ...data,
                tipoEmbarque,
                nrSeqTipoEmbarque: tipoEmbarque.nrSeqTipoEmbarque,
              });
            }}
            dataSourceTextProperty='noTipoEmbarque'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-3 mb-3'>
          <Autocomplete
            label='Mercadoria'
            searchDataSource={onSearchFreteMercadoria}
            selectedItem={data.freteMercadoria}
            onSelectItem={(freteMercadoria: any) => {
              setData({
                ...data,
                freteMercadoria,
                nrSeqFreteMercadoria: freteMercadoria.nrSeqFreteMercadoria,
              });
            }}
            dataSourceTextProperty='noFreteMercadoria'
          />
        </div>
        <div className='col-4 mb-3'>
          <Autocomplete
            label='Estado Origem'
            searchDataSource={onSearchEstado}
            selectedItem={data.estadoOri}
            onSelectItem={(estadoOri: any) => {
              setData({
                ...data,
                estadoOri,
                nrSeqEstadoOri: estadoOri.nrSeqEstado,
              });
            }}
            dataSourceTextProperty='noEstado'
          />
        </div>
        <div className='col-4 mb-3'>
          <Autocomplete
            label='Estado Destino'
            searchDataSource={onSearchEstado}
            selectedItem={data.estadoDest}
            onSelectItem={(estadoDest: any) => {
              setData({
                ...data,
                estadoDest,
                nrSeqEstadoDest: estadoDest.nrSeqEstado,
              });
            }}
            dataSourceTextProperty='noEstado'
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Cidade Origem'
            searchDataSource={onSearchCidadeOri}
            selectedItem={data.cidadeOri}
            onSelectItem={(cidadeOri: any) =>
              setData({
                ...data,
                cidadeOri,
                nrSeqCidadeOri: cidadeOri.nrSeqCidade,
              })
            }
            dataSourceTextProperty='noCidade'
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Cidade Destino'
            searchDataSource={onSearchCidadeDest}
            selectedItem={data.cidadeDest}
            onSelectItem={(cidadeDest: any) =>
              setData({
                ...data,
                cidadeDest,
                nrSeqCidadeDest: cidadeDest.nrSeqCidade,
              })
            }
            dataSourceTextProperty='noCidade'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Data Início'
            text={data.dtInicio}
            mask={MaskTypes.Date}
            onChange={(dtInicio) => setData({ ...data, dtInicio })}
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data Fim'
            text={data.dtFim}
            mask={MaskTypes.Date}
            onChange={(dtFim) => setData({ ...data, dtFim })}
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox
            label='Faixa Inicial'
            mask={MaskTypes.Integer}
            maxLength={8}
            text={data?.faixaIni}
            onChangedValue={(faixaIni: string) => {
              setData({
                ...data,
                faixaIni,
              });
            }}
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox
            label='Faixa Final'
            mask={MaskTypes.Integer}
            maxLength={8}
            text={data?.faixaFim}
            onChangedValue={(faixaFim: string) => {
              setData({
                ...data,
                faixaFim,
              });
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Tipo de Calculo'
            searchDataSource={onSearchFreteTipoCalculo}
            selectedItem={data.freteTipoCalculo}
            onSelectItem={(freteTipoCalculo: any) => {
              setData({
                ...data,
                freteTipoCalculo,
                nrSeqFreteTipoCalculo: freteTipoCalculo.nrSeqFreteTipoCalculo,
              });
            }}
            dataSourceTextProperty='noFreteTipoCalculo'
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox
            label='Valor Calculado'
            mask={MaskTypes.DecimalCustom}
            decimalPlaces={2}
            maxLength={8}
            text={data?.vlrCalculado}
            onChangedValue={(vlrCalculado: string) => {
              setData({
                ...data,
                vlrCalculado,
              });
            }}
          />
        </div>
        <div className='col mb-3'>
          {/* @ts-expect-error */}
          <Switch
            formControl
            label='ICMS'
            checked={'flgIcms' in data ? data.flgIcms : null}
            onChange={(flgIcms: boolean) => setData({ ...data, flgIcms })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-auto'>
          {/* @ts-expect-error */}
          <Switch
            formControl
            label='Valor Fixo'
            checked={'flgValorFixo' in data ? data.flgValorFixo : null}
            onChange={(flgValorFixo: boolean) =>
              setData({ ...data, flgValorFixo })
            }
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox
            label='Valor Fixo Adicional'
            mask={MaskTypes.DecimalCustom}
            decimalPlaces={2}
            maxLength={8}
            text={data?.vlrTarifaAdicional}
            onChangedValue={(vlrTarifaAdicional: string) => {
              setData({
                ...data,
                vlrTarifaAdicional,
              });
            }}
          />
        </div>
        <div className='col-9'>
          <span>
            Valor Fixo somado ao valor calculado. Utilizado apenas quando o
            campo Frete Fixo estiver desmarcado.
          </span>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-12'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
            sortOffline
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
