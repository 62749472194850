import ChecklistValor from 'core/models/FRO/checklistValor';

export default class ChecklistCampos {
  constructor(jsonObject = {}) {
    this.nrSeqChecklistCampos = jsonObject.nrSeqChecklistCampos;
    this.cdTipoCampo = jsonObject.cdTipoCampo;
    this.noCampo = jsonObject.noCampo;
    this.nrSeqChecklistFrota = jsonObject.nrSeqChecklistFrota;
    this.nrSeqChecklistGrupo = jsonObject.nrSeqChecklistGrupo;
    this.nrOrdem = jsonObject.nrOrdem;
    this.flgObrigaConfManutencao = jsonObject.flgObrigaConfManutencao;
    this.flgVeiculo = jsonObject.flgVeiculo;

    // #region Propriedades Auxiliares
    this.nrSeqChecklistTipo = jsonObject.nrSeqChecklistTipo;
    this.nrSeqChecklistValorRetorno = jsonObject.nrSeqChecklistValorRetorno;
    this.nrSeqChecklistValor = jsonObject.nrSeqChecklistValor;
    this.noChecklistTipo = jsonObject.noChecklistTipo;
    this.noGrupo = jsonObject.noGrupo;
    this.noComplemento = jsonObject.noComplemento;
    this.checklistValor = jsonObject.checklistValor;
    this.listChecklistValores = jsonObject.listChecklistValores
      ? jsonObject.listChecklistValores.map(
          (item) => new ChecklistValor(item ?? {})
        )
      : [];
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
