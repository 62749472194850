import qs from 'qs';
import Pagination from 'core/models/pagination';
import ChecklistValor from 'core/models/FRO/checklistValor';
import api from '../api';

const baseURL = `/FRO/ChecklistValor`;

export const getChecklistValorList = async (filters) => {
  const response = await api.get(`/$ChecklistValor/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ChecklistValor(json)) ?? [],
  };
};

export const getChecklistValorPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ChecklistValor(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getChecklistValor = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ChecklistValor(value) : {};
};

export const saveChecklistValor = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteChecklistValor = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printChecklistValor = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelChecklistValor = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllChecklistValor = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
