import React, { useState, useEffect } from 'react';
import {
  BootstrapSizes,
  MimeTypes,
  Theme,
  ResponseStatus,
} from 'ui/Helpers/utils';
import { getEmpresasUsuario } from 'core/services/SEG/empresa';
import {
  Autocomplete,
  Button,
  FileUpload,
  Loader,
  Modal,
  Notification,
  RadioButton,
} from 'ui/components';
import { getOperadoraAutoComplete, importarChip } from 'core/services/TEL';
import { SituacaoChip, TipoChip } from './enums';

const Situacao = [
  { text: 'Todos', value: 'T' },
  { text: 'Em Uso', value: 'A' },
  { text: 'Em Estoque', value: 'E' },
  { text: 'Reserva', value: 'R' },
  { text: 'Cancelado', value: 'C' },
];

const Tipo = [
  { text: 'Movel', value: 'M' },
  { text: 'Fixo', value: 'F' },
  { text: 'Ramal', value: 'R' },
  { text: 'Link Net', value: 'L' },
  { text: 'Too-Free', value: 'T' },
];

const Status = [
  { text: 'Todos', value: 'T' },
  { text: 'Ativo', value: 'A' },
  { text: 'Inativo', value: 'I' },
  { text: 'Virtual', value: 'V' },
];

export default function ModalImportacaoChip({ show, onClose, onSuccess }) {
  const [importedFile, setImportedFile] = useState([]);
  const [message, setMessages] = useState();
  const [loading, setLoading] = useState();

  const [filters, setFilters] = useState({});

  const onSearchEmpresa = async (e) => {
    const { empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });
    return empresas;
  };

  useEffect(() => {
    setImportedFile([]);
    setMessages(null);
    setLoading(false);
  }, [show]);

  const onSearchOperadora = async (e) => {
    const { operadoras } = await getOperadoraAutoComplete({
      noOperadora: e,
    });

    return operadoras;
  };

  const uploadFile = async () => {
    setLoading(true);
    if (!importedFile)
      setMessages({
        message: 'Não há arquivos selecionados para importação.',
        theme: Theme.Danger,
      });
    else {
      const dadosChip = {
        nrSeqEmpresa: filters.nrSeqEmpresa,
        nrSeqOperadora: filters.nrSeqOperadora,
        flgStatus: filters.status ?? '',
        flgSituacao: filters.situacao ?? SituacaoChip.EmUso,
        noTipo: filters.noTipo ?? TipoChip.LinkNet,
      };

      const { status, message: msg, retorno } = await importarChip(
        importedFile[0],
        dadosChip
      );

      if (status !== ResponseStatus.Success)
        setMessages({
          message: msg,
          theme:
            status === ResponseStatus.Warning ? Theme.Warning : Theme.Danger,
        });
      else onSuccess(retorno);
    }
    setLoading(false);
  };

  return (
    <Modal
      show={show}
      title='Importação de Chip'
      icon='cloud-upload-alt'
      size={BootstrapSizes.ExtraLarge}
      onClose={onClose}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessages()}
            />
          </div>
        )}

        <div className='alert alert-info fs-8' role='alert'>
          <div className='row' align='justify' style={{ fontSize: '9px' }}>
            <p> Descrição do arquivo importado.</p>
            <p>
              O arquivo a ser importado deverá ser um arquivo csv constando
              obrigatoriamente o CHIP e o ICCID. Abaixo a descrição dos campos
              aceitos na importação:
            </p>
            <p>
              A primeira linha do arquivo deve conter o cabeçalho de cada
              coluna, o nome deve estar EXATAMENTE como o detalhamento abaixo.
            </p>
            <p>
              CHIP Campo obrigatório contendo o CHIP(Prefixo + número), contendo
              apenas números.
            </p>
            <p>
              ICCID Campo obrigatório contendo o ICCID, contendo apenas números.
            </p>
          </div>
        </div>
        <FileUpload
          files={importedFile}
          onChange={(files) => setImportedFile(files)}
          allowedMimeTypes={[MimeTypes.Types.Excel]}
        />
        <div className='row mb-3'>
          <div align='justify' style={{ fontSize: '13px' }}>
            Limite de 1 arquivos sendo que cada arquivo deve possuir o tamanho
            máximo de 5 MB.
          </div>
        </div>

        <div className='row mb-3'>
          <div className='row'>
            <div className='col-6'>
              <div>
                <Autocomplete
                  label='Empresa'
                  searchDataSource={onSearchEmpresa}
                  selectedItem={filters.empresa}
                  onSelectItem={(empresa) =>
                    setFilters({
                      ...filters,
                      empresa,
                      nrSeqEmpresa: empresa.nrSeqEmpresa,
                    })
                  }
                  dataSourceTextProperty='noPessoa'
                />
              </div>
            </div>
            <div className='col-6'>
              <div>
                <Autocomplete
                  label='Operadora'
                  searchDataSource={onSearchOperadora}
                  selectedItem={filters.operadora}
                  onSelectItem={(operadora) =>
                    setFilters({
                      ...filters,
                      operadora,
                      nrSeqOperadora: operadora.nrSeqOperadora,
                    })
                  }
                  dataSourceTextProperty='noOperadora'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='mb-3'>
          <div className='row'>
            <div className='col-4'>
              <div>
                <RadioButton
                  outline
                  label='Status'
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  buttons={Status}
                  selectedButton={filters.flgStatus}
                  onChange={(flgStatus) =>
                    setFilters({
                      ...filters,
                      flgStatus,
                    })
                  }
                />
              </div>
            </div>
            <div className='col-4'>
              <div>
                <RadioButton
                  outline
                  label='Situação'
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  buttons={Situacao}
                  selectedButton={filters.flgSituacao}
                  onChange={(flgSituacao) =>
                    setFilters({
                      ...filters,
                      flgSituacao,
                    })
                  }
                />
              </div>
            </div>
            <div className='col-4'>
              <div>
                <RadioButton
                  outline
                  label='Tipo'
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  buttons={Tipo}
                  selectedButton={filters.noTipo}
                  onChange={(noTipo) =>
                    setFilters({
                      ...filters,
                      noTipo,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Importar'
          icon='check'
          theme={Theme.Success}
          onClick={uploadFile}
        />
        <Button
          text='Cancelar'
          icon='ban'
          theme={Theme.Danger}
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
