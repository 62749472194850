import { EnvioPadrao } from 'core/models/TEL';
import api from '../api';

export const getEnvioPadraoAutoComplete = async (filters) => {
  const response = await api.get(`EnvioPadrao/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    envios: value?.map((json) => new EnvioPadrao(json)) ?? [],
  };
};
