import React, { useState, useEffect } from 'react';
import {
  getTipoCarroceriaAutoComplete,
  saveTipoCarroceria,
  getTipoCarroceriaMdfeAutoComplete,
} from 'core/services/FRO/tipoCarroceria';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  Button,
  Modal,
  Notification,
  Textbox,
  Autocomplete,
} from 'ui/components';

export default function ModalCadastroTipoCarroceria({
  show,
  onClose,
  data,
  setData,
}) {
  const [message, setMessage] = useState(null);
  const [dataModal, setDataModal] = useState({});

  const onSend = (msg) => {
    onClose(msg);
  };

  useEffect(() => {
    setDataModal({});
  }, [show]);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchTipoCarroceria = async (e) => {
    const response = await getTipoCarroceriaAutoComplete({
      noTipoCarroceria: e,
    });

    return response.data;
  };

  const onSearchTipoCarroceriaMdfe = async (e) => {
    const {
      status,
      message: msg,
      data: tipoveiculomdfe,
    } = await getTipoCarroceriaMdfeAutoComplete(e);
    if (msg) onSetMessage(status, msg);
    return tipoveiculomdfe;
  };

  const save = async () => {
    if (dataModal.noTipoCarroceria && dataModal.tpCarMdfe) {
      const { status, message: msg } = await saveTipoCarroceria(dataModal);

      if (status === ResponseStatus.Success) {
        const tipoCarrocerias = await onSearchTipoCarroceria(
          dataModal.noTipoCarroceria
        );

        setData({
          ...data,
          tipoCarroceria: tipoCarrocerias[0],
          nrSeqTipoCarroceria: tipoCarrocerias[0].nrSeqTipoCarroceria,
        });

        setDataModal({});

        onSend();
      } else {
        setMessage({
          message: msg,
          theme: Theme.Danger,
        });
      }
    } else {
      setMessage({
        message: 'Preencha os campos obrigátorios',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <Modal
      show={show}
      title='Cadastrar Tipo Carroceria'
      onClose={() => onSend()}
      size={BootstrapSizes.Large}
    >
      <Modal.Body>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col-5'>
            <Textbox
              required
              maxLength={60}
              label='Nome'
              text={dataModal.noTipoCarroceria}
              onChangedValue={(noTipoCarroceria) =>
                setDataModal({ ...dataModal, noTipoCarroceria })
              }
            />
          </div>
          <div className='col-5'>
            <Autocomplete
              label='Código de Carga MDFE'
              required
              searchDataSource={onSearchTipoCarroceriaMdfe}
              selectedItem={dataModal.tipoCarroceriaMdfe}
              onSelectItem={(tipoCarroceriaMdfe) => {
                setDataModal({
                  ...dataModal,
                  tpCarMdfe: tipoCarroceriaMdfe.cdTipoCarroceriaMDFe,
                });
              }}
              dataSourceTextProperty='noTipoCarroceriaMDFe'
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Small}
          icon={['far', 'save']}
          theme={Theme.Success}
          template={Button.Templates.Default}
          onClick={save}
          text='Cadastrar'
        />
      </Modal.Footer>
    </Modal>
  );
}
