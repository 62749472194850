import { Empresa, Pessoa, Usuario } from '../SEG';
import ContaCorrente from './contaCorrente';
import ExtratoCategoria from './extratoCategoria';

export default class Extrato {
  constructor(jsonObject = {}) {
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.nrSeqExtrato = jsonObject.nrSeqExtrato;
    this.nrSeqContaCorrente = jsonObject.nrSeqContaCorrente;
    this.dtCad = jsonObject.dtCad;
    this.dtDocumento = jsonObject.dtDocumento;
    this.vlrDocumento = jsonObject.vlrDocumento;
    this.flgSinal = jsonObject.flgSinal;
    this.nrDocumento = jsonObject.nrDocumento;
    this.vlrCalculado = jsonObject.vlrCalculado;
    this.flgConsolidado = jsonObject.flgConsolidado;
    this.nrSeqExtratoCategoria = jsonObject.nrSeqExtratoCategoria;
    this.nrSeqTransacao = jsonObject.nrSeqTransacao;
    this.nrSeqTitInt = jsonObject.nrSeqTitInt;
    this.nrSeqOrdemInt = jsonObject.nrSeqOrdemInt;
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.obs = jsonObject.obs ?? '';
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqCheque = jsonObject.nrSeqCheque;
    this.nrSeqGrupoExtrato = jsonObject.nrSeqGrupoExtrato;
    this.dtCaixa = jsonObject.dtCaixa;
    this.nrGrupoDocumento = jsonObject.nrGrupoDocumento;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqPagamentoMassivoItem = jsonObject.nrSeqPagamentoMassivoItem;
    this.nrSeqRecebimentoMassivoItem = jsonObject.nrSeqRecebimentoMassivoItem;
    this.nrSeqUsuarioAlt = jsonObject.nrSeqUsuarioAlt;
    this.dtAlteracao = jsonObject.dtAlteracao;

    this.contaCorrente = new ContaCorrente(jsonObject.contaCorrente ?? {});
    this.extratoCategoria = new ExtratoCategoria(
      jsonObject.extratoCategoria ?? {}
    );
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.pessoa = new Pessoa(jsonObject.empresa ?? {});
    this.noDespesaReceita = jsonObject.noDespesaReceita;
    this.noPessoa = jsonObject.noPessoa ?? '';
    this.nrDocumentoPessoa = jsonObject.nrDocumentoPessoa;
    this.saldo = jsonObject.saldo;
    this.cheque = jsonObject.cheque;
    this.vlrCredito = jsonObject.vlrCredito;
    this.vlrDebito = jsonObject.vlrDebito;
    this.noCor = jsonObject.noColor;
    this.saldoCor = jsonObject.saldoCor;
    this.flgPermiteAlterar = jsonObject.flgPermiteAlterar;
    this.nrIdentificado = jsonObject.nrIdentificado;
    this.nrCte = jsonObject.nrCte;
    this.usuarioCad = new Usuario(jsonObject.usuarioCad ?? {});
    this.usuarioAlt = new Usuario(jsonObject.usuarioAlt ?? {});
  }

  toJson = () => JSON.stringify(this);
}
