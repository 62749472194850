import {
  Cfo,
  GrupoIPI,
  GrupoIssqn,
  GrupoPIS,
  GrupoCofins,
} from 'core/models/FIS';
import GrupoIcms from 'core/models/FIS/grupoIcms';
import EstoqueTipo from '../EST/estoqueTipo';

export default class TipoNfvAvulsa {
  constructor(jsonObject = {}) {
    this.nrSeqTipoNfvAvulsa = jsonObject.nrSeqTipoNfvAvulsa;
    this.cdTipoNfvAvulsa = jsonObject.cdTipoNfvAvulsa;
    this.noTipoNfvAvulsa = jsonObject.noTipoNfvAvulsa;
    this.serieNfv = jsonObject.serieNfv;
    this.flgGeraEstq = jsonObject.flgGeraEstq;
    this.flgAtivo = jsonObject.flgAtivo;
    this.nrSeqGrupoCoFins = jsonObject.nrSeqGrupoCoFins;
    this.nrSeqGrupoIcms = jsonObject.nrSeqGrupoIcms;
    this.nrSeqGrupoIpi = jsonObject.nrSeqGrupoIpi;
    this.nrSeqGrupoIssQn = jsonObject.nrSeqGrupoIssQn;
    this.flgEntrada = jsonObject.flgEntrada;
    this.flgImpostoManual = jsonObject.flgImpostoManual;
    this.flgImpressaoTermo = jsonObject.flgImpressaoTermo;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqCfoEst = jsonObject.nrSeqCfoEst;
    this.nrSeqCfoExt = jsonObject.nrSeqCfoExt;
    this.nrSeqCfoPais = jsonObject.nrSeqCfoPais;
    this.nrSeqGrupoPis = jsonObject.nrSeqGrupoPis;
    this.flgTransferencia = jsonObject.flgTransferencia;
    this.nrSeqCfoEstInverso = jsonObject.nrSeqCfoEstInverso;
    this.nrSeqCfoExtInverso = jsonObject.nrSeqCfoExtInverso;
    this.nrSeqCfoPaisInverso = jsonObject.nrSeqCfoPaisInverso;
    this.nrSeqEstoqueTipo = jsonObject.nrSeqEstoqueTipo;
    this.flgFinalidade = jsonObject.flgFinalidade;
    this.flgValidaPrecoCusto = jsonObject.flgValidaPrecoCusto;
    this.flgDevolucao = jsonObject.flgDevolucao;
    this.flgSomaIcms = jsonObject.flgSomaIcms;
    this.flgCupomFiscal = jsonObject.flgCupomFiscal;
    this.flgPossuiOrcamento = jsonObject.flgPossuiOrcamento;
    this.flgRetornoMercadoria = jsonObject.flgRetornoMercadoria;
    this.estoqueTipo = new EstoqueTipo(jsonObject.estoqueTipo ?? {});
    this.grupoIcms = new GrupoIcms(jsonObject.grupoIcms ?? {});
    this.grupoIPI = new GrupoIPI(jsonObject.grupoIPI ?? {});
    this.grupoIssqn = new GrupoIssqn(jsonObject.grupoIssqn ?? {});
    this.grupoPis = new GrupoPIS(jsonObject.grupoPis ?? {});
    this.grupoCofins = new GrupoCofins(jsonObject.grupoCofins ?? {});
    this.cfoEst = new Cfo(jsonObject.cfoEst ?? {});
    this.cfoExt = new Cfo(jsonObject.cfoExt ?? {});
    this.cfoPais = new Cfo(jsonObject.cfoPais ?? {});
    this.cfoInv = new Cfo(jsonObject.cfoInv ?? {});
    this.cfoExtInv = new Cfo(jsonObject.cfoExtInv ?? {});
    this.cfoPaisInv = new Cfo(jsonObject.cfoPaisInv ?? {});
  }

  toJson = () => JSON.stringify(this);
}
