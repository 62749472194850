import { getOperadoraAutoComplete } from 'core/services/TEL';
import { postImportaConta } from 'core/services/TEL/importaConta';
import React, { useState } from 'react';
import {
  Autocomplete,
  CSDSelPage,
  DatePicker,
  FileUpload,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes, ResponseStatus, Theme } from 'ui/Helpers/enums';
import { Message, Page } from 'ui/Helpers/interfaces';

export default function ImportaConta({ transaction, isActive }: Page) {
  const [files, setFiles] = useState<Array<any>>([]);
  const [filtros, setFiltros] = useState<Array<any>>();
  const [message, setMessage] = useState<Message | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onImportaConta = async () => {
    setLoading(true);
    if (!filtros) onSetMessage(ResponseStatus.Error, 'Selecione a operadora.');
    else {
      const { status, message: msg } = await postImportaConta(
        files,
        filtros.nrSeqOperadora,
        filtros.dtVencimento
      );
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onSearchOperadora = async (e) => {
    const {
      status,
      message: msg,
      operadoras,
    } = await getOperadoraAutoComplete({ noOperadora: e });
    if (msg) onSetMessage(status, msg);
    return operadoras;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Importa Conta'
      transaction={transaction}
      message={message}
      loading={loading}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Importar Conta'
          icon='sync-alt'
          onClick={() => onImportaConta()}
        />
      </ToolbarButtons>
      <div>
        <div className='row mb-3'>
          <div className='col-auto'>
            <Autocomplete
              label='Operadora'
              required
              searchDataSource={onSearchOperadora}
              selectedItem={filtros?.operadora}
              onSelectItem={(operadora) => {
                setFiltros({
                  ...filtros,
                  operadora,
                  nrSeqOperadora: operadora.nrSeqOperadora,
                });
              }}
              dataSourceTextProperty='noOperadora'
            />
          </div>
          <div className='col-auto'>
            <DatePicker
              label='Data de Vencimento'
              text={filtros?.dtVencimento}
              mask={MaskTypes.Date}
              onChange={(dtVencimento) =>
                setFiltros({ ...filtros, dtVencimento })
              }
            />
          </div>
        </div>
        <div className='row mb-3'>
          <FileUpload
            files={files}
            onChange={(items: any) => {
              setFiles(items);
              setMessage(null);
            }}
            maxFiles={500}
            dropzone
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
