export default class PocPocoPocCabo {
  constructor(jsonObject = {}) {
    this.nrSeqPocPocoPocCabo = jsonObject.nrSeqPocPocoPocCabo;
    this.nrSeqPocPoco = jsonObject.nrSeqPocPoco;
    this.dtInstalacao = jsonObject.dtInstalacao;
    this.nrSeqPocCabo = jsonObject.nrSeqPocCabo;
    this.nrMetragem = jsonObject.nrMetragem;
    this.noFornecimento = jsonObject.noFornecimento;
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
