import qs from 'qs';
import Pagination from 'core/models/pagination';
import PocPocoDocumento from 'core/models/POC/pocPocoDocumento';
import { MimeTypes } from 'ui/Helpers/utils';
import api from '../api';

const baseURL = `/POC/PocPocoDocumento`;

export const getPocPocoDocumentoList = async (filters) => {
  const response = await api.get(`/${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPocoDocumento(json)) ?? [],
  };
};

export const uploadDocumento = async (
  file,
  nrseqpocpoco,
  nrseqpocpocotipodocumento
) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('extension', MimeTypes.getExtension(file.type));
  formData.append('nrseqpocpoco', nrseqpocpoco);
  formData.append('nrseqpocpocotipodocumento', nrseqpocpocotipodocumento);
  const response = await api.post(`${baseURL}/UploadDocumento`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    retorno: value,
  };
};

export const getPocPocoDocumentoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPocoDocumento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocPocoDocumento = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocPocoDocumento(value) : {};
};

export const savePocPocoDocumento = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePocPocoDocumento = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPocPocoDocumento = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocPocoDocumento = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocPocoDocumento = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
