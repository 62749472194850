import React, { useState, useEffect } from 'react';
import {
  DatePicker,
  Modal,
  Textbox,
  Button,
  Notification,
} from 'ui/components';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';

import { anularCte } from 'core/services/FRO/coletaEmbarque';

export default function ModalAnulacaoCte({ show, onClose, cte }) {
  const [data, setData] = useState({});
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (show && cte.nrSeqColetaEmbarque)
      setData({ ...data, obsAnulacao: `REF CTE ${cte?.cdColetaEmbarque}` });
  }, [show]);

  const onAnularCte = async () => {
    const { status, message: msg } = await anularCte({
      nrSeqColetaEmbarque: cte.nrSeqColetaEmbarque,
      obsAnulacao: data.obsAnulacao,
      dtEventoDesacordo: data.dtEventoDesacordo,
    });

    if (msg) {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
  };

  const onSend = () => {
    onClose();
  };

  return (
    <Modal
      show={show}
      title='Anulação do Embarque'
      onClose={() => onSend()}
      size={BootstrapSizes.Large}
    >
      <Modal.Body>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-5'>
          <div className='row mb-3'>
            <div className='col-5'>
              <DatePicker
                label='Data de Registro do Evento de Desacordo'
                text={data.dtEventoDesacordo}
                maxLength={10}
                mask={MaskTypes.Types}
                onChange={(dtEventoDesacordo) =>
                  setData({ ...data, dtEventoDesacordo })
                }
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              <Textbox
                label='Observação  da Anulação'
                text={data.obsAnulacao}
                onChangedValue={(obsAnulacao) =>
                  setData({ ...data, obsAnulacao })
                }
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Medium}
          icon='check'
          theme={Theme.Success}
          template={Button.Templates.Default}
          onClick={() => onAnularCte()}
          text='Confirmar'
        />
        <Button
          size={BootstrapSizes.Medium}
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          onClick={() => onSend()}
          text='Sair'
        />
      </Modal.Footer>
    </Modal>
  );
}
