import { getPlanoDeContaAutoComplete } from 'core/services/FIN/planoDeConta';
import { getTipoCarroceriaAutoComplete } from 'core/services/FRO/tipoCarroceria';
import { getTransportadoraAutoComplete } from 'core/services/FRO/transportadora';
import { getVeiculoCorAutoComplete } from 'core/services/FRO/veiculoCor';
import { getVeiculoMarcaAutoComplete } from 'core/services/FRO/veiculoMarca';
import { getVeiculoModeloAutoComplete } from 'core/services/FRO/veiculoModelo';
import { getVeiculoTipoAutoComplete } from 'core/services/FRO/veiculoTipo';
import {
  getCidadeEstadoAutoComplete,
  getEstadoAutoComplete,
  getPaisAutoComplete,
} from 'core/services/SEG';
import React, { forwardRef, useEffect, useState, useRef } from 'react';
import {
  Autocomplete,
  DatePicker,
  Switch,
  Textarea,
  Textbox,
  Panel,
  GridView,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { Theme } from 'ui/Helpers/utils';
import ModalCadastroTipoCarroceria from './modalCadastroTipoCarroceria';
import ModalCadastroVeiculoCor from './modalCadastroVeiculoCor';
import ModalCadastroVeiculoMarca from './modalCadastroVeiculoMarca';
import ModalCadastroVeiculoModelo from './modalCadastroVeiculoModelo';
import ModalCadastroVeiculoTipo from './modalCadastroVeiculoTipo';

const DetalhesVeiculoStep = forwardRef(({ data, setData }) => {
  const [veiculoData, setVeiculoData] = useState(data);
  const [showModalTipoCarroceria, setShowModalTipoCarroceria] = useState(false);
  const [showModalVeiculoCor, setShowModalVeiculoCor] = useState(false);
  const [showModalVeiculoMarca, setShowModalVeiculoMarca] = useState(false);
  const [showModalVeiculoModelo, setShowModalVeiculoModelo] = useState(false);
  const [showModalVeiculoTipo, setShowModalVeiculoTipo] = useState(false);

  const gridViewPneusAtivos = useRef();

  const columnsPneusAtivos = [
    { key: 'pneu.cdPneu', title: 'Pneu' },
    { key: 'kmInicio', title: 'Km Início' },
    { key: 'dtInicio', title: 'Data Início', format: GridView.DataTypes.Date },
    { key: 'noPneuModelo', title: 'Modelo' },
    { key: 'noPneuMarca', title: 'Marca' },
  ];

  const complementaColunas = (dadosPneus) => {
    if (Array.isArray(dadosPneus)) {
      dadosPneus.forEach((item) => {
        const modelo = item.pneu.pneuModelo?.noPneuModelo;
        item.noPneuModelo = modelo !== undefined ? modelo : 'Não cadastrado';

        const marca = item.pneu.pneuModelo?.pneuMarca?.noPneuMarca;
        item.noPneuMarca = marca !== undefined ? marca : 'Não cadastrado';
      });
    }

    return dadosPneus;
  };

  useEffect(async () => {
    const dataPneusEdited = complementaColunas(data.pneusAtivos);

    if (gridViewPneusAtivos?.current)
      gridViewPneusAtivos.current.setDataSource(dataPneusEdited);

    setData({ ...data, ...veiculoData });
  }, [veiculoData]);

  // #region OnSearchs
  const onSearchTransportadora = async (e) => {
    const response = await getTransportadoraAutoComplete({
      noPessoa: e,
    });

    return response.transportadoras;
  };

  const onSearchVeiculoModelo = async (e) => {
    const response = await getVeiculoModeloAutoComplete({
      noVeiculoModelo: e,
      nrSeqVeiculoMarca: veiculoData.nrSeqVeiculoMarca,
    });
    return response.data;
  };

  const onSearchVeiculoMarca = async (e) => {
    const response = await getVeiculoMarcaAutoComplete({
      noVeiculoMarca: e,
    });

    return response.data;
  };

  const onSearchVeiculoTipo = async (e) => {
    const response = await getVeiculoTipoAutoComplete({ noVeiculoTipo: e });

    return response.data;
  };

  const onSearchPais = async (e) => {
    const response = await getPaisAutoComplete({
      noPais: e,
    });

    return response.paises;
  };

  const onSearchEstado = async (e) => {
    const response = await getEstadoAutoComplete({
      noEstado: e,
      nrSeqPais: veiculoData.nrSeqPais,
    });

    return response.estados;
  };

  const onSearchCidade = async (e) => {
    const response = await getCidadeEstadoAutoComplete({
      noCidade: e,
      nrSeqEstado: veiculoData.nrSeqEstado,
    });

    return response.cidades;
  };

  const onSearchVeiculoCor = async (e) => {
    const response = await getVeiculoCorAutoComplete({
      noVeiculoCor: e,
    });

    return response.veiculoCores;
  };

  const onSearchTipoCarroceria = async (e) => {
    const response = await getTipoCarroceriaAutoComplete({
      noTipoCarroceria: e,
    });

    return response.data;
  };

  const onSearchPlanoDeConta = async (e) => {
    const response = await getPlanoDeContaAutoComplete({
      noPlanoDeConta: e,
    });

    return response.planodecontas;
  };
  // #endregion

  return (
    <div className='mb-5 pb-5'>
      <div className='row mb-3 align-items-end'>
        <div className='col-2'>
          <Textbox
            maxLength={20}
            label='Código'
            text={veiculoData.cdVeiculo}
            onChangedValue={(cdVeiculo) => {
              setVeiculoData({
                ...veiculoData,
                cdVeiculo,
              });
            }}
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Nome Veiculo'
            maxLength={60}
            text={veiculoData.noVeiculo}
            onChangedValue={(noVeiculo) => {
              setVeiculoData({
                ...veiculoData,
                noVeiculo: noVeiculo.toUpperCase(),
              });
            }}
          />
        </div>
        <div className='col-5'>
          <Autocomplete
            label='Transportadora'
            required
            searchDataSource={onSearchTransportadora}
            selectedItem={veiculoData.transportadora}
            onSelectItem={(transportadora) => {
              setVeiculoData({
                ...veiculoData,
                transportadora,
                nrSeqPessoaTran: transportadora.nrSeqPessoaTran,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-1'>
          <Switch
            formControl
            label='Ativo'
            checked={veiculoData.flgAtivo}
            onChange={(flgAtivo) => {
              setVeiculoData({
                ...veiculoData,
                flgAtivo,
              });
            }}
          />
        </div>
        <div className='col-1'>
          <Switch
            formControl
            label='Próprio'
            checked={veiculoData.flgProprio}
            onChange={(flgProprio) => {
              setVeiculoData({ ...veiculoData, flgProprio });
            }}
          />
        </div>
        <div className='col-1'>
          <Switch
            formControl
            label='Alienado'
            checked={veiculoData.flgAlienado}
            onChange={(flgAlienado) => {
              if (flgAlienado) {
                setVeiculoData({
                  ...veiculoData,
                  flgAlienado,
                });
              } else {
                setVeiculoData({
                  ...veiculoData,
                  flgAlienado,
                  noAlienado: undefined,
                  dtFimAlienado: undefined,
                });
              }
            }}
          />
        </div>
      </div>
      <div className='row mb-3 align-items-end'>
        <div className='col-2'>
          <Textbox
            maxLength={7}
            label='Placa'
            required
            text={veiculoData.placa}
            onChangedValue={(placa) => {
              setVeiculoData({
                ...veiculoData,
                placa: placa.toUpperCase(),
              });
            }}
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Marca'
            searchDataSource={onSearchVeiculoMarca}
            selectedItem={veiculoData.veiculoMarca}
            onSelectItem={(veiculoMarca) => {
              setVeiculoData({
                ...veiculoData,
                veiculoMarca,
                nrSeqVeiculoMarca: veiculoMarca.nrSeqVeiculoMarca,
                veiculoModelo: undefined,
                nrSeqVeiculoModelo: undefined,
              });
            }}
            dataSourceTextProperty='noVeiculoMarca'
            openModal={setShowModalVeiculoMarca}
          />
        </div>
        <div className='col'>
          <Autocomplete
            readOnly={!veiculoData.veiculoMarca?.nrSeqVeiculoMarca}
            enabled={!!veiculoData.veiculoMarca?.nrSeqVeiculoMarca}
            placeholder={
              !veiculoData.veiculoMarca?.nrSeqVeiculoMarca &&
              'Selecione a Marca'
            }
            label='Modelo'
            required
            searchDataSource={onSearchVeiculoModelo}
            selectedItem={veiculoData.veiculoModelo}
            onSelectItem={(veiculoModelo) => {
              setVeiculoData({
                ...veiculoData,
                veiculoModelo,
                nrSeqVeiculoModelo: veiculoModelo.nrSeqVeiculoModelo,
              });
            }}
            dataSourceTextProperty='noVeiculoModelo'
            openModal={setShowModalVeiculoModelo}
          />
        </div>
        <div className='col-3'>
          <div className='row'>
            <div className='col'>
              <Textbox
                label='Ano de Fabricação'
                text={veiculoData.anoFabricacao}
                maxLength={4}
                mask={MaskTypes.Integer}
                onChangedValue={(anoFabricacao) =>
                  setVeiculoData({ ...veiculoData, anoFabricacao })
                }
              />
            </div>
            <div className='col'>
              <Textbox
                label='Ano do Modelo'
                text={veiculoData.anoModelo}
                maxLength={4}
                mask={MaskTypes.Integer}
                onChangedValue={(anoModelo) =>
                  setVeiculoData({ ...veiculoData, anoModelo })
                }
              />
            </div>
          </div>
        </div>

        <div className='col-2'>
          <Textbox
            label='Chassi'
            maxLength={40}
            text={veiculoData.nrChassi}
            onChangedValue={(nrChassi) =>
              setVeiculoData({ ...veiculoData, nrChassi })
            }
          />
        </div>
      </div>
      <div className='row mb-3 align-items-end'>
        <div className='col-2'>
          <Autocomplete
            label='Pais da Placa'
            searchDataSource={onSearchPais}
            selectedItem={veiculoData.pais}
            onSelectItem={(pais) => {
              setVeiculoData({
                ...veiculoData,
                pais,
                nrSeqPais: pais.nrSeqPais,
                estado: null,
                cidade: null,
              });
            }}
            dataSourceTextProperty='noPais'
          />
        </div>
        <div className='col-2'>
          <Autocomplete
            label='Estado da Placa'
            readOnly={!veiculoData.pais?.nrSeqPais}
            enabled={!!veiculoData.pais?.nrSeqPais}
            placeholder={!veiculoData.pais?.nrSeqPais && 'Selecione o País'}
            searchDataSource={onSearchEstado}
            selectedItem={veiculoData.estado}
            onSelectItem={(estado) => {
              setVeiculoData({
                ...veiculoData,
                estado,
                nrSeqEstado: estado.nrSeqEstado,
                cidade: null,
              });
            }}
            dataSourceTextProperty='noEstado'
          />
        </div>
        <div className='col-2'>
          <Autocomplete
            label='Cidade da Placa'
            required
            readOnly={!veiculoData.estado?.nrSeqEstado}
            enabled={!!veiculoData.estado?.nrSeqEstado}
            placeholder={
              !veiculoData.estado?.nrSeqEstado && 'Selecione o Estado'
            }
            searchDataSource={onSearchCidade}
            selectedItem={veiculoData.cidade}
            onSelectItem={(cidade) => {
              setVeiculoData({
                ...veiculoData,
                cidade,
                nrSeqCidade: cidade.nrSeqCidade,
              });
            }}
            dataSourceTextProperty='noCidade'
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Odômetro Inicial'
            text={veiculoData.nrOdometroInicial ?? 0}
            maxLength={12}
            mask={MaskTypes.Integer}
            onChangedValue={(nrOdometroInicial) =>
              setVeiculoData({ ...veiculoData, nrOdometroInicial })
            }
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Odômetro Máximo'
            text={veiculoData.nrOdometroMaximo ?? 0}
            maxLength={12}
            mask={MaskTypes.Integer}
            onChangedValue={(nrOdometroMaximo) =>
              setVeiculoData({ ...veiculoData, nrOdometroMaximo })
            }
          />
        </div>
      </div>
      <div className='row mb-3 align-items-end'>
        <div className='col-2'>
          <DatePicker
            label='Data de Aquisição'
            text={veiculoData.dtAquisicao}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtAquisicao) =>
              setVeiculoData({ ...veiculoData, dtAquisicao })
            }
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Veículo Cor'
            searchDataSource={onSearchVeiculoCor}
            selectedItem={veiculoData.veiculoCor}
            onSelectItem={(veiculoCor) => {
              setVeiculoData({
                ...veiculoData,
                veiculoCor,
                nrSeqVeiculoCor: veiculoCor.nrSeqVeiculoCor,
              });
            }}
            dataSourceTextProperty='noVeiculoCor'
            openModal={setShowModalVeiculoCor}
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Tipo Veículo'
            required
            searchDataSource={onSearchVeiculoTipo}
            selectedItem={veiculoData.veiculoTipo}
            onSelectItem={(veiculoTipo) => {
              setVeiculoData({
                ...veiculoData,
                veiculoTipo,
                nrSeqVeiculoTipo: veiculoTipo.nrSeqVeiculoTipo,
              });
            }}
            dataSourceTextProperty='noVeiculoTipo'
            openModal={setShowModalVeiculoTipo}
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Tipo Carroceria'
            searchDataSource={onSearchTipoCarroceria}
            selectedItem={veiculoData.tipoCarroceria}
            onSelectItem={(tipoCarroceria) => {
              setVeiculoData({
                ...veiculoData,
                tipoCarroceria,
                nrSeqTipoCarroceria: tipoCarroceria.nrSeqTipoCarroceria,
              });
            }}
            dataSourceTextProperty='noTipoCarroceria'
            openModal={setShowModalTipoCarroceria}
          />
        </div>
      </div>
      <div className='row mb-3 align-items-end'>
        <div className='col-2'>
          <Textbox
            label='Renavan'
            maxLength={40}
            text={veiculoData.nrRenavan}
            onChangedValue={(nrRenavan) =>
              setVeiculoData({ ...veiculoData, nrRenavan })
            }
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Carga Máx(kg)'
            maxLength={12}
            text={veiculoData.nrCargaMaxima}
            mask={MaskTypes.Integer}
            onChangedValue={(nrCargaMaxima) =>
              setVeiculoData({ ...veiculoData, nrCargaMaxima })
            }
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Cap. Tanque'
            maxLength={12}
            text={veiculoData.nrCapacidadeDeTanque}
            mask={MaskTypes.Decimal}
            onChangedValue={(nrCapacidadeDeTanque) =>
              setVeiculoData({ ...veiculoData, nrCapacidadeDeTanque })
            }
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Valor Compra'
            maxLength={13}
            text={veiculoData.vlrCompra}
            mask={MaskTypes.Currency}
            onChangedValue={(vlrCompra) =>
              setVeiculoData({ ...veiculoData, vlrCompra })
            }
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Valor Mercado'
            maxLength={13}
            text={veiculoData.vlrMercado}
            mask={MaskTypes.Currency}
            onChangedValue={(vlrMercado) =>
              setVeiculoData({ ...veiculoData, vlrMercado })
            }
          />
        </div>
      </div>
      <div className='row mb-3 align-items-end'>
        <div className='col-3'>
          <Textbox
            label='Alienado à Empresa'
            readOnly={!veiculoData.flgAlienado}
            maxLength={80}
            required={veiculoData.flgAlienado}
            text={veiculoData.noAlienado}
            onChangedValue={(noAlienado) =>
              setVeiculoData({ ...veiculoData, noAlienado })
            }
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Fim da Alienação'
            required={veiculoData.flgAlienado}
            readOnly={!veiculoData.flgAlienado}
            text={veiculoData.dtFimAlienado}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtFimAlienado) =>
              setVeiculoData({ ...veiculoData, dtFimAlienado })
            }
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data de Venda'
            text={veiculoData.dtVenda}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtVenda) => setVeiculoData({ ...veiculoData, dtVenda })}
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Valor de Venda'
            text={veiculoData.vlrVenda}
            mask={MaskTypes.Currency}
            onChangedValue={(vlrVenda) =>
              setVeiculoData({ ...veiculoData, vlrVenda })
            }
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Plano de Conta'
            searchDataSource={onSearchPlanoDeConta}
            selectedItem={veiculoData.planoDeConta}
            onSelectItem={(planoDeConta) =>
              setVeiculoData({
                ...veiculoData,
                planoDeConta,
                nrSeqPlanoDeConta: planoDeConta.nrSeqPlanoDeConta,
              })
            }
            dataSourceTextProperty='noPlanoDeConta'
          />
        </div>
      </div>
      <div className='row mb-3 align-items-end'>
        <div className='col'>
          <Textarea
            label='Observação'
            rows={4}
            maxLength={500}
            text={veiculoData.noObservacao}
            onChangedValue={(noObservacao) => {
              setVeiculoData({ ...veiculoData, noObservacao });
            }}
          />
        </div>
      </div>

      {data?.pneusAtivos?.length > 0 && (
        <div className='row'>
          <Panel className='mb-3'>
            <Panel.Header title='Pneus' theme={Theme.Primary} />
            <Panel.Body>
              <div className='row'>
                <div className='col-12'>
                  <GridView
                    ref={gridViewPneusAtivos}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsPneusAtivos}
                    offlineData
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      )}

      <div className='row'>
        <div className='col'>
          <ModalCadastroTipoCarroceria
            show={showModalTipoCarroceria}
            onClose={() => setShowModalTipoCarroceria(false)}
            data={veiculoData}
            setData={setVeiculoData}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalCadastroVeiculoCor
            show={showModalVeiculoCor}
            onClose={() => setShowModalVeiculoCor(false)}
            data={veiculoData}
            setData={setVeiculoData}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalCadastroVeiculoMarca
            show={showModalVeiculoMarca}
            onClose={() => setShowModalVeiculoMarca(false)}
            data={veiculoData}
            setData={setVeiculoData}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalCadastroVeiculoModelo
            show={showModalVeiculoModelo}
            onClose={() => setShowModalVeiculoModelo(false)}
            data={veiculoData}
            setData={setVeiculoData}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalCadastroVeiculoTipo
            show={showModalVeiculoTipo}
            onClose={() => setShowModalVeiculoTipo(false)}
            data={veiculoData}
            setData={setVeiculoData}
          />
        </div>
      </div>
    </div>
  );
});

export default DetalhesVeiculoStep;
