import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Theme, ResponseStatus, BootstrapSizes } from 'ui/Helpers/utils';
import { CSDManutPage, GridView, Panel } from 'ui/components';

import { getPrevisaoFinanceira } from 'core/services/FIN/previsaoFinanceira';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';

export default function FornecedorItem({
  registryKey,
  reload,
  isActive,
  transaction,
  onOpenTransaction,
  findTransaction,
}) {
  const gridView = useRef();
  const gridView2 = useRef();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const formSubmit = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onClickVerDespesa = async (datasource) => {
    setLoading(true);
    const transactionToOpen = findTransaction(TransacationCodes.TituloPagar);

    if (transactionToOpen) {
      transactionToOpen.registryKey = datasource.nrSeqTituloPagar;
      setLoading(false);
      onOpenTransaction(transactionToOpen, true);
    } else {
      setLoading(false);
      onSetMessage(
        ResponseStatus.Error,
        `Não foi possível abrir o Titulo a Pagar cód.: ${datasource.cdTituloPagar}.`
      );
    }
  };

  const columnsTituloPagar = [
    { key: 'nrSeqTituloPagar', visible: false },
    { key: 'cdTituloPagar', title: 'Compromisso' },
    { key: 'nrTit', title: 'Nr. Tit' },
    { key: 'nrOrdem', title: 'Ordem' },
    {
      key: 'fornecedor.noFornecedor',
      title: 'Fornecedor',
      sortKey: 'noFornecedor',
    },
    {
      key: 'tipoDespesa.noTipoDespesa',
      title: 'Tipo Despesa',
      sortKey: 'noTipoDespesa',
    },

    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma Pagamento',
      sortKey: 'noFormaPagamento',
    },
    {
      key: 'vlrSaldoTit',
      title: 'Valor Saldo',
      format: GridView.DataTypes.Decimal,
    },

    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrSeqTituloPagar',
      title: 'Ver',
      type: GridView.ColumnTypes.Button,
      onClick: (datasource) => onClickVerDespesa(datasource),
      theme: Theme.Warning,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Ver Despesa',
      tooltipDirection: 'bottom',
    },
  ];

  const onClickVerReceita = async (datasource) => {
    setLoading(true);
    const transactionToOpen = findTransaction(TransacationCodes.TituloReceber);

    if (transactionToOpen) {
      transactionToOpen.registryKey = datasource.nrSeqTituloReceber;
      setLoading(false);
      onOpenTransaction(transactionToOpen, true);
    } else {
      setLoading(false);
      onSetMessage(
        ResponseStatus.Error,
        `Não foi possível abrir o Titulo a Receber cód.: ${datasource.cdTituloReceber}.`
      );
    }
  };

  const columnsTituloReceber = [
    { key: 'nrSeqTituloReceber', visible: false },
    { key: 'nrTit', title: 'Nr. Tit' },
    { key: 'nrOrdem', title: 'Ordem' },
    {
      key: 'cliente.noPessoa',
      title: 'Cliente',
      sortKey: 'noPessoa',
    },
    {
      key: 'tituloEspecie.noEspecie',
      title: 'Tipo Receita',
      sortKey: 'noEspecie',
    },

    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma Pagamento',
      sortKey: 'noFormaPagamento',
    },
    {
      key: 'vlrSaldoTit',
      title: 'Valor Saldo',
      format: GridView.DataTypes.Decimal,
    },

    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrSeqTituloReceber',
      title: 'Ver',
      type: GridView.ColumnTypes.Button,
      onClick: (datasource) => onClickVerReceita(datasource),
      theme: Theme.Warning,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Ver Receita',
      tooltipDirection: 'bottom',
    },
  ];

  const load = useCallback(async () => {
    if (registryKey !== null && registryKey !== undefined) {
      setLoading(true);
      const obj = await getPrevisaoFinanceira(registryKey);

      if (gridView && gridView.current)
        gridView.current.setDataSource(obj.tituloPagarS);

      if (gridView2 && gridView2.current)
        gridView2.current.setDataSource(obj.tituloReceberS);
      setLoading(false);
    }
    onSetMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  return (
    <CSDManutPage
      isActive={isActive}
      loading={loading}
      message={message}
      ref={formSubmit}
      transaction={transaction}
    >
      <Panel>
        <div className='row'>
          <div className='col-12 mb-4'>
            <Panel.Header
              icon={['fas', 'user']}
              title='Titulos a pagar'
              theme={Theme.Primary}
            />
          </div>
        </div>
        <Panel.Body>
          <div className='row mt-2'>
            <div className='col'>
              <GridView
                ref={gridView}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columnsTituloPagar}
                offlineData
                showPagination={false}
                showSelectSizes={false}
                sumFields
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <Panel>
        <div className='row'>
          <div className='col-12 mb-4'>
            <Panel.Header
              icon={['fas', 'user']}
              title='Titulos a receber'
              theme={Theme.Primary}
            />
          </div>
        </div>
        <Panel.Body>
          <div className='row mt-2'>
            <div className='col'>
              <GridView
                ref={gridView2}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columnsTituloReceber}
                offlineData
                showPagination={false}
                showSelectSizes={false}
                sumFields
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </CSDManutPage>
  );
}
