import React, { useState, useRef, useEffect } from 'react';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  Modal,
  Autocomplete,
  Textbox,
  DatePicker,
  Button,
  GridView,
  Notification,
} from 'ui/components';
import { getPneuVidaAutoComplete } from 'core/services/FRO/pneuVida';
import { getPneuSituacaoAutoComplete } from 'core/services/FRO/pneuSituacao';
import { getParametro } from 'core/services/SEG/parametro';

export default function ModalDetalhesPneu({
  show,
  data,
  produtos,
  quantidade,
  onClose,
  onSave,
}) {
  const gridViewPneus = useRef();
  const [message, setMessage] = useState(null);
  const [pneu, setPneu] = useState({
    flgCdPneuAutomatico: false,
  });
  const [pneusRegistrados, setPneusRegistrados] = useState(false);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const save = async () => {
    const listPneus = gridViewPneus.current.getDataSource();

    if (listPneus.length < quantidade) {
      setMessage({
        message: `Voce deve registrar todos pneus. Falta registrar ${
          quantidade - listPneus.length
        }`,
        theme: Theme.Danger,
      });
    } else if (listPneus.length > quantidade) {
      setMessage({
        message: `Voce registrou ${listPneus.length - quantidade} pneu${
          listPneus.length - quantidade === 1 ? '' : 's'
        } a mais`,
        theme: Theme.Danger,
      });
    } else {
      onSave(listPneus, true);
      onClose();
    }
  };

  const onCloseModal = () => {
    const listPneus = gridViewPneus.current.getDataSource();

    onSave(listPneus, false);
    onClose();
  };

  const onSearchPneuVida = async (e) => {
    const {
      status,
      message: msg,
      data: pneusVida,
    } = await getPneuVidaAutoComplete({ noPneuVida: e });
    if (msg) onSetMessage(status, msg);
    return pneusVida;
  };

  const onSearchPneuSituacao = async (e) => {
    const {
      status,
      message: msg,
      data: pneusVida,
    } = await getPneuSituacaoAutoComplete({ noPneuVida: e });
    if (msg) onSetMessage(status, msg);
    return pneusVida;
  };

  const validatePneuData = (dataSource) => {
    if (
      !dataSource.nrSeqProduto ||
      !dataSource.dtCompra ||
      !dataSource.cdPneu ||
      !dataSource.nrSeqPneuVida ||
      !dataSource.nrSeqPneuSituacao
    ) {
      setMessage({
        message: 'Preencha todos os campos.',
        theme: Theme.Danger,
      });

      return false;
    }

    return true;
  };

  const buscaParametroCdPneuAutomatico = async () => {
    const parametro = await getParametro('CODIGO_PNEU_AUTOMATICO', 1);

    if (parametro.noConteudo === 'S') return true;

    return false;
  };

  useEffect(async () => {
    if (gridViewPneus && gridViewPneus.current) {
      gridViewPneus.current.setDataSource(data.pneus ?? []);

      if (data.pneus && data.pneus.length !== quantidade) {
        setPneusRegistrados(false);
      }
    }

    if (show) {
      const geraCdPneuAutomatico = await buscaParametroCdPneuAutomatico();

      setPneu({ flgCdPneuAutomatico: geraCdPneuAutomatico });
    }
  }, [show]);

  const onClickAddPneu = () => {
    const pneus = gridViewPneus.current.getDataSource() ?? [];

    if (!pneu.status) pneu.status = GridView.EnumStatus.Inserir;
    if (validatePneuData(pneu)) {
      delete pneu.flgCdPneuAutomatico;
      pneus.push(pneu);

      if (gridViewPneus && gridViewPneus.current) {
        gridViewPneus.current.setDataSource(pneus);
      }
      if (pneus.length === quantidade) {
        setPneusRegistrados(true);
      } else {
        setPneusRegistrados(false);
      }
      setPneu({ flgCdPneuAutomatico: false });
    }
  };

  const onClickRemoverPneu = (e, datasource) => {
    const list = datasource.filter((el) => el !== e);

    if (gridViewPneus && gridViewPneus.current)
      gridViewPneus.current.setDataSource(list);

    if (list.length === quantidade) {
      setPneusRegistrados(true);
    } else {
      setPneusRegistrados(false);
    }
  };

  const searchProduto = () => produtos;

  const columns = [
    { key: 'nrSeqPneu', title: 'NrSeqPneu', visible: false },
    { key: 'nrSeqProduto', title: 'NrSeqProduto', visible: false },
    { key: 'cdPneu', title: 'Código Pneu' },
    { key: 'nrDot', title: 'Nr. Dot' },
    { key: 'numeroSerie', title: 'Nr. Série' },
    { key: 'kmAcumulada', title: 'Km Acumulada' },
    { key: 'nrSulcoInicial', title: 'Nr. Sulco Inicial' },
    { key: 'nrSulcoFinal', title: 'Nr. Sulco Final' },
    { key: 'dtTermino', title: 'Dt. Término', format: GridView.DataTypes.Date },
    { key: 'dtCompra', title: 'Dt. Compra', format: GridView.DataTypes.Date },
    { key: 'pneuVida.noPneuVida', title: 'Vida' },
    { key: 'pneuSituacao.noPneuSituacao', title: 'Situação' },
    {
      key: 'nrSeqPneu',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoverPneu(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <Modal
      show={show}
      title='Efetivar Pneus'
      onClose={onCloseModal}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row pb-5'>
          <div className='row mb-3 align-items-end'>
            <div className='col-6'>
              <Autocomplete
                label='Produto'
                searchDataSource={searchProduto}
                selectedItem={pneu.produto}
                onSelectItem={(produto) => {
                  setPneu({
                    ...pneu,
                    produto,
                    nrSeqProduto: produto.nrSeqProduto,
                  });
                }}
                dataSourceTextProperty='noProduto'
              />
            </div>
            <div className='col-2'>
              <DatePicker
                label='Data Compra'
                text={pneu.dtCompra}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtCompra) => setPneu({ ...pneu, dtCompra })}
              />
            </div>
            <div className='col-2'>
              <DatePicker
                label='Data Término'
                text={pneu.dtTermino}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtTermino) => setPneu({ ...pneu, dtTermino })}
              />
            </div>
          </div>
          <div className='row mb-3 align-items-end'>
            <div className='col-2'>
              <Textbox
                label='Código Pneu'
                readOnly={pneu?.flgCdPneuAutomatico}
                maxLength={20}
                text={pneu.cdPneu}
                onChangedValue={(cdPneu) => setPneu({ ...pneu, cdPneu })}
              />
            </div>

            <div className='col-2'>
              <Textbox
                maxLength={20}
                label='Nr. Série'
                text={pneu.numeroSerie}
                onChangedValue={(numeroSerie) =>
                  setPneu({ ...pneu, numeroSerie })
                }
              />
            </div>

            <div className='col-2'>
              <Textbox
                maxLength={10}
                label='Nr. Dot'
                text={pneu.nrDot}
                onChangedValue={(nrDot) => setPneu({ ...pneu, nrDot })}
              />
            </div>

            <div className='col-2'>
              <Textbox
                label='Nr. Sulco Inicial'
                text={pneu.nrSulcoInicial}
                mask={MaskTypes.Decimal}
                onChangedValue={(nrSulcoInicial) =>
                  setPneu({ ...pneu, nrSulcoInicial })
                }
              />
            </div>

            <div className='col-2'>
              <Textbox
                label='Nr. Sulco Final'
                text={pneu.nrSulcoFinal}
                mask={MaskTypes.Decimal}
                onChangedValue={(nrSulcoFinal) =>
                  setPneu({ ...pneu, nrSulcoFinal })
                }
              />
            </div>
          </div>

          <div className='row mb-3 align-items-end'>
            <div className='col-2'>
              <Textbox
                label='Km Acumulada'
                text={pneu.kmAcumulada}
                mask={MaskTypes.Integer}
                onChangedValue={(kmAcumulada) =>
                  setPneu({ ...pneu, kmAcumulada })
                }
              />
            </div>
            <div className='col-4'>
              <Autocomplete
                label='Pneu Vida'
                searchDataSource={onSearchPneuVida}
                selectedItem={pneu.pneuVida}
                onSelectItem={(pneuVida) => {
                  setPneu({
                    ...pneu,
                    pneuVida,
                    nrSeqPneuVida: pneuVida.nrSeqPneuVida,
                  });
                }}
                dataSourceTextProperty='noPneuVida'
              />
            </div>
            <div className='col-4'>
              <Autocomplete
                label='Pneu Situação'
                searchDataSource={onSearchPneuSituacao}
                selectedItem={pneu.pneuSituacao}
                onSelectItem={(pneuSituacao) => {
                  setPneu({
                    ...pneu,
                    pneuSituacao,
                    nrSeqPneuSituacao: pneuSituacao.nrSeqPneuSituacao,
                  });
                }}
                dataSourceTextProperty='noPneuSituacao'
              />
            </div>

            <div className='col d-flex justify-content-end'>
              <Button
                outline
                icon='plus'
                size={BootstrapSizes.Medium}
                theme={Theme.Success}
                template={Button.Templates.Default}
                onClick={onClickAddPneu}
                text='Adicionar'
              />
            </div>
          </div>

          <div className='row'>
            <div className='col'>
              <GridView
                ref={gridViewPneus}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columns}
                offlineData
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Medium}
          theme={pneusRegistrados ? Theme.Success : Theme.Inactive}
          template={Button.Templates.Default}
          onClick={save}
          text='Efetivar'
        />
      </Modal.Footer>
    </Modal>
  );
}
