import { ColetaRemetente, Veiculo } from '../FRO';
import { Cidade } from '../SEG';
import Cliente from './cliente';
import FormaPagamento from './formaPagamento';
import TipoReceitaAcerto from './tipoReceitaAcerto';

export default class AcertoMotoristaReceita {
  constructor(jsonObject = {}) {
    this.nrSeqAcertoMotoristaReceita = jsonObject.nrSeqAcertoMotoristaReceita;
    this.flgAvista = jsonObject.flgAvista;
    this.nrDocumento = jsonObject.nrDocumento;
    this.dtFrete = jsonObject.dtFrete;
    this.vlrReceita = jsonObject.vlrReceita;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqTipoReceitaAcerto = jsonObject.nrSeqTipoReceitaAcerto;
    this.nrSeqAcertoMotorista = jsonObject.nrSeqAcertoMotorista;
    this.dtReceita = jsonObject.dtReceita;
    this.vlrComissao = jsonObject.vlrComissao;
    this.vlrBaseComissao = jsonObject.vlrBaseComissao;
    this.vlrPercentualComissao = jsonObject.vlrPercentualComissao;
    this.flgGeraComissao = jsonObject.flgGeraComissao;
    this.nrSeqTituloReceber = jsonObject.nrSeqTituloReceber;
    this.dtCheque = jsonObject.dtCheque;
    this.nrCheque = jsonObject.nrCheque;
    this.noObservacao = jsonObject.noObservacao;
    this.flgLiquidaTitulo = jsonObject.flgLiquidaTitulo;
    this.nrSeqRecebimento = jsonObject.nrSeqRecebimento;
    this.peso = jsonObject.peso;
    this.nrSeqCidadeOri = jsonObject.nrSeqCidadeOri;
    this.nrSeqCidadeDes = jsonObject.nrSeqCidadeDes;
    this.referenciaCliente = jsonObject.referenciaCliente;
    this.contatoCliente = jsonObject.contatoCliente;
    this.numeroCliente = jsonObject.numeroCliente;
    this.documentos = jsonObject.documentos;
    this.tipoDocumentoCliente = jsonObject.tipoDocumentoCliente;
    this.nrSeqPessoaRem = jsonObject.nrSeqPessoaRem;
    this.nrSeqPessoaDes = jsonObject.nrSeqPessoaDes;
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    this.cliente = new Cliente(jsonObject.cliente ?? {});
    this.enderecoDestino = new Cidade(jsonObject.enderecoDestino ?? {});
    this.enderecoRemetente = new Cidade(jsonObject.enderecoRemetente ?? {});
    this.tipoReceitaAcerto = new TipoReceitaAcerto(
      jsonObject.tipoReceitaAcerto ?? {}
    );
    this.remetente = new ColetaRemetente(jsonObject.remetente ?? {});
    this.destinatario = new ColetaRemetente(jsonObject.destinatario ?? {});
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
  }

  toJson = () => JSON.stringify(this);
}
