import React, { useState, useEffect, useRef } from 'react';
import { setTransaction } from 'core/services/api';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import {
  Button,
  Modal,
  Notification,
  DatePicker,
  Autocomplete,
  Textbox,
  GridView,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN';
import {
  postParcelaTituloReceber,
  postCalculatePenaltyParcel,
} from 'core/services/FIN/tituloReceber';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import TituloReceber from 'core/models/FIN/tituloReceber';
import { getInformacaoBancariaAutoComplete } from 'core/services/FIN/informacaoBancaria';

export default function ModalParcelarTitulo({
  show,
  onClose,
  titulos,
  transaction,
  retornoSave,
}) {
  const [message, setMessage] = useState(null);
  const [data, setData] = useState({
    vlrMultaCalculado: 0,
    vlrJuroCalculado: 0,
  });
  const [somados, setSomados] = useState({
    flgDeixaSalvar: true,
    vlrSomado: 0,
  });

  const gridViewAgrupadosNovosParaGerar = useRef();
  const gridViewAgrupadosSelecao = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onClickRemover = (e) => {
    const lista = gridViewAgrupadosNovosParaGerar.current.getDataSource();
    const listafiltrada = lista.filter((item) => item !== e);

    if (
      gridViewAgrupadosNovosParaGerar &&
      gridViewAgrupadosNovosParaGerar.current
    )
      gridViewAgrupadosNovosParaGerar.current.setDataSource(listafiltrada);
  };

  const onAdicionarParcela = async () => {
    if (!data.nrSeqEmpresa) {
      onSetMessage(Theme.Danger, 'Necessário informar a empresa');
      return;
    }

    if (!data.nrSeqTituloEspecie) {
      onSetMessage(Theme.Danger, 'Necessário informar o título espécie');
      return;
    }

    if (!data.nrSeqFormaPagamento) {
      onSetMessage(Theme.Danger, 'Necessário informar a forma de pagamento');
      return;
    }

    if (!data.dtVencimento) {
      onSetMessage(Theme.Danger, 'Necessário informar o vencimento');
      return;
    }

    if (!data.vlrTitulo || data.vlrTitulo <= 0) {
      onSetMessage(Theme.Danger, 'Necessário informar o valor');
      return;
    }

    const obj = new TituloReceber({ ...data });
    const lista = gridViewAgrupadosNovosParaGerar.current.getDataSource() ?? [];
    lista.push(obj);

    if (
      gridViewAgrupadosNovosParaGerar &&
      gridViewAgrupadosNovosParaGerar.current
    ) {
      gridViewAgrupadosNovosParaGerar.current.setDataSource(lista);
    }

    if (lista !== undefined) {
      let sum = 0;
      for (let i = 0; i < lista.length; ) {
        sum += lista[i].vlrTitulo;
        i += 1;
      }
      somados.vlrSomado = sum;
    }

    setData({
      ...obj,
      vlrTitulo: 0,
      dtVencimento: undefined,
    });
  };

  const onSearchInformacaoBancaria = async () => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoComplete({
      nrSeqPessoa: titulos[0].nrSeqPessoaCli,
    });
    onSetMessage(status, msg);
    return informacoesBancarias;
  };

  const columnsAgrupadosNovosParaGerar = [
    {
      key: 'nrSeqTituloReceber',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'nrTit', title: 'NrTit', visible: false },
    { key: 'nrOrdem', title: 'NrOrdem', visible: false },
    {
      key: 'cliente.noPessoa',
      title: 'Cliente',
      visible: false,
    },
    {
      key: 'tituloEspecie.noEspecie',
      title: 'Receita',
      visible: false,
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrRecebido',
      title: 'Pago',
      format: GridView.DataTypes.Money,
      visible: false,
    },
    {
      key: 'vlrSaldoTit',
      title: 'Saldo',
      format: GridView.DataTypes.Money,
      visible: false,
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'dtVencimentoStr',
      visible: false,
    },
    { key: 'noObs', title: 'NoObs', visible: false },
    {
      key: 'dtContab',
      title: 'Contabil',
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'dtRecebimento',
      title: 'Recebimento',
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'nrSeqTituloReceberFatura',
      title: 'NrSeqTituloReceberFatura',
      visible: false,
    },
    {
      key: 'nrSeqTituloReceber',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickRemover(e),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltipDirection: 'bottom',
      title: 'Remover',
    },
  ];
  const columnsAgrupadosSelecao = [
    {
      key: 'nrSeqTituloReceber',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'nrTit', title: 'NrTit' },
    { key: 'nrOrdem', title: 'NrOrdem' },
    {
      key: 'cliente.noPessoa',
      title: 'Cliente',
    },
    {
      key: 'tituloEspecie.noEspecie',
      title: 'Receita',
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma',
      visible: false,
    },
    {
      key: 'nrDiasEmAtraso',
      title: 'Atraso',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrRecebido',
      title: 'Recebido',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrSaldoTit',
      title: 'Saldo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'dtVencimentoStr',
      visible: false,
    },
    { key: 'noObs', title: 'NoObs', visible: false },
    {
      key: 'dtContab',
      title: 'Contabil',
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'dtRecebimento',
      title: 'Recebimento',
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'nrSeqTituloReceberFatura',
      title: 'NrSeqTituloReceberFatura',
      visible: false,
    },
  ];

  const onSearchFormaPagamento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitRec: true,
    });

    return formasPagamento;
  };

  const onCalcularJuroMulta = async () => {
    const tituloReceberAgrupadosSelecao = gridViewAgrupadosSelecao.current.getDataSource();

    const { status, message: msg, value } = await postCalculatePenaltyParcel(
      tituloReceberAgrupadosSelecao
    );
    const { vlrJuroCalculado, vlrMultaCalculado, vlrTotalReceber } = value;
    if (status === ResponseStatus.Error) {
      onSetMessage(Theme.Danger, msg);
    } else {
      setData({
        ...data,
        vlrJuroCalculado,
        vlrMultaCalculado,
        vlrTotalReceber,
      });
    }
  };

  const onSalvar = async () => {
    const tituloReceberAgrupadosSelecao = gridViewAgrupadosSelecao.current.getDataSource();
    const tituloReceberAgrupadosNovosParaGerar = gridViewAgrupadosNovosParaGerar.current.getDataSource();
    const obj = {
      tituloReceberAgrupadosSelecao,
      tituloReceberAgrupadosNovosParaGerar,
    };
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);

    const retornoSaveParcela = await postParcelaTituloReceber(obj);

    if (retornoSaveParcela.status === ResponseStatus.Error) {
      onSetMessage(Theme.Danger, retornoSaveParcela.message);
    }

    retornoSave(retornoSaveParcela);
  };

  const onSearchTipo = async (e) => {
    const { data: tituloReceberEspecie } = await getTituloEspecieAutoComplete({
      noEspecie: e,
      flgExibeNoParcelamento: true,
    });

    return tituloReceberEspecie;
  };

  const onSearchEmpresa = async (e) => {
    const { empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    return empresas;
  };

  const calculaTotalTitulosParcelamento = () => {
    const total = titulos.reduce(
      (acum, item) => acum + item.vlrTitulo - item.vlrRecebido,
      0
    );
    return total;
  };
  useEffect(async () => {
    if (show) {
      setData({
        vlrMultaCalculado: 0,
        vlrJuroCalculado: 0,
        vlrTitulo: 0,
        nrSeqEmpresa: titulos[0].nrSeqEmpresa,
        empresa: titulos[0].empresa,
        vlrTotalTituloAntigo: calculaTotalTitulosParcelamento(),
      });
      if (gridViewAgrupadosSelecao && gridViewAgrupadosSelecao.current)
        gridViewAgrupadosSelecao.current.setDataSource(titulos);

      setSomados({ flgDeixaSalvar: true, vlrSomado: 0 });
    }
  }, [show]);

  const RecalculaValores = async () => {
    if (data.vlrJuroCalculado === undefined) {
      data.vlrJuroCalculado = 0;
    }
    if (data.vlrMultaCalculado === undefined) {
      data.vlrMultaCalculado = 0;
    }

    if (data.vlrJuroCalculado === null) {
      data.vlrJuroCalculado = 0;
    }
    if (data.vlrMultaCalculado === null) {
      data.vlrMultaCalculado = 0;
    }

    const valores = data.vlrJuroCalculado + data.vlrMultaCalculado;

    const vlrareceber = data.vlrTotalTituloAntigo + valores;

    setData({
      ...data,
      vlrTotalReceber: vlrareceber,
    });
  };

  return (
    <Modal
      show={show}
      title='Parcelar Titulo Receber'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body className='p-4 pt-3'>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-6'>
          <div className='card'>
            <div className='card-body'>
              <div className='row mb-3'>
                <div className='row mb-3'>
                  <div className='col'>
                    <Autocomplete
                      label='Empresa'
                      searchDataSource={onSearchEmpresa}
                      selectedItem={data.empresa}
                      onSelectItem={(empresa) => {
                        setData({
                          ...data,
                          empresa,
                          nrSeqEmpresa: empresa.nrSeqEmpresa,
                        });
                      }}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                  <div className='col-3'>
                    <Autocomplete
                      label='Tipo Receita'
                      searchDataSource={onSearchTipo}
                      selectedItem={data.tituloEspecie}
                      onSelectItem={(tituloEspecie) => {
                        setData({
                          ...data,
                          tituloEspecie,
                          nrSeqTituloEspecie: tituloEspecie.nrSeqTituloEspecie,
                        });
                      }}
                      dataSourceTextProperty='noEspecie'
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col'>
                    <Textbox
                      label='Valor'
                      text={data?.vlrTotalTituloAntigo}
                      mask={MaskTypes.DecimalCustom}
                      readOnly
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Juros'
                      text={data.vlrJuroCalculado}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(vlrJuroCalculado) =>
                        setData({
                          ...data,
                          vlrJuroCalculado,
                        })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Multa'
                      text={data.vlrMultaCalculado}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(vlrMultaCalculado) =>
                        setData({
                          ...data,
                          vlrMultaCalculado,
                        })
                      }
                      onBlur={RecalculaValores}
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='Valor'
                      text={data?.vlrTotalReceber}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(vlrTotalReceber) =>
                        setData({
                          ...data,
                          vlrTotalReceber,
                        })
                      }
                      readOnly
                    />
                  </div>
                  <div className='col-2 mt-3'>
                    <Button
                      theme={Theme.Primary}
                      template={Button.Templates.Button}
                      text='Calcular Juros'
                      onClick={() => onCalcularJuroMulta()}
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-4'>
                    <Autocomplete
                      label='Forma de Recebimento'
                      searchDataSource={onSearchFormaPagamento}
                      selectedItem={data.formaPagamento}
                      onSelectItem={(formaPagamento) => {
                        setData({
                          ...data,
                          formaPagamento,
                          nrSeqFormaPagamento:
                            formaPagamento.nrSeqFormaPagamento,
                        });
                      }}
                      dataSourceTextProperty='noFormaPagamento'
                    />
                  </div>

                  <div className='col-2'>
                    <DatePicker
                      label='Vencimento'
                      text={data.dtVencimento}
                      mask={MaskTypes.Date}
                      onChange={(dtVencimento) =>
                        setData({ ...data, dtVencimento })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Valor'
                      text={data?.vlrTitulo}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(vlrTitulo) =>
                        setData({
                          ...data,
                          vlrTitulo,
                        })
                      }
                    />
                  </div>
                  <div className='col-2 mb-3'>
                    <Textbox
                      label='Valor Somado da grid'
                      readOnly
                      text={somados?.vlrSomado}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(vlrSomado) =>
                        setSomados({ ...somados, vlrSomado })
                      }
                    />
                  </div>

                  {data.formaPagamento?.flgGrupo === 'D' && (
                    <div className='col-4'>
                      <Autocomplete
                        label='Informação Bancária'
                        searchDataSource={onSearchInformacaoBancaria}
                        selectedItem={data.informacaoBancaria}
                        onSelectItem={(informacaoBancaria) =>
                          setData({
                            ...data,
                            informacaoBancaria,
                            nrSeqPessoaInformacaoBancaria:
                              informacaoBancaria.nrSeqPessoaInformacaoBancaria,
                          })
                        }
                        dataSourceTextProperty='noDescricao'
                      />
                    </div>
                  )}
                </div>

                <div className='row mb-3'>
                  <div className='col-4 mt-3'>
                    <Button
                      theme={Theme.Primary}
                      template={Button.Templates.Button}
                      text='Adicionar Parcela'
                      onClick={() => onAdicionarParcela()}
                    />
                  </div>
                </div>

                <div className='row mb-3'>
                  <div className='col'>
                    <GridView
                      ref={gridViewAgrupadosNovosParaGerar}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columnsAgrupadosNovosParaGerar}
                      showPagination={false}
                      transaction={transaction}
                      showSelectSizes={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              <GridView
                ref={gridViewAgrupadosSelecao}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columnsAgrupadosSelecao}
                showPagination={false}
                transaction={transaction}
                showSelectSizes={false}
              />
            </div>
          </div>
          <div className='row'>
            {message && (
              <div className='mb-3'>
                <Notification
                  message={message.message}
                  theme={message.theme}
                  onClose={() => setMessage(null)}
                />
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Salvar'
          theme={Theme.Success}
          icon={['far', 'save']}
          onClick={() => onSalvar()}
        />
        <Button
          text='Fechar'
          theme={Theme.Danger}
          icon='ban'
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
