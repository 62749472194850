import React from 'react';

// @ts-expect-error
import { Theme } from 'ui/Helpers/enums.ts';
import { MaskTypes } from 'ui/Helpers/masks';

import { Textbox, Panel } from 'ui/components';

interface Props {
  data?: any;
  setData?: any;
  contato?: any;
  formdata?: any;
}

const PainelContato = ({ data, setData }: Props) => (
  <div className='row mt-3'>
    {/* @ts-expect-error */}
    <Panel>
      {/* @ts-expect-error */}
      <Panel.Header title='Contato' theme={Theme.Primary} />
      <Panel.Body>
        <div className='row mb-3'>
          <div className='col-4'>
            {/* @ts-expect-error */}
            <Textbox
              label='Site'
              id='txtSite'
              maxLength={40}
              text={data?.contato?.site}
              onChangedValue={(site: string) =>
                setData({ ...data, contato: { ...data.contato, site } })
              }
            />
          </div>
          <div className='col-2'>
            {/* @ts-expect-error */}
            <Textbox
              label='Fone Empresa'
              id='txtFoneEmpresa'
              maxLength={20}
              mask={MaskTypes.CellPhone}
              text={data?.contato?.foneEmp}
              onChangedValue={(foneEmp: string) =>
                setData({
                  ...data,
                  contato: { ...data.contato, foneEmp },
                })
              }
            />
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-2'>
            {/* @ts-expect-error */}
            <Textbox
              label='Fone Contato'
              id='txtFoneContato'
              maxLength={20}
              mask={MaskTypes.CellPhone}
              text={data?.contato?.fone}
              onChangedValue={(fone: string) =>
                setData({ ...data, contato: { ...data.contato, fone } })
              }
            />
          </div>
          <div className='col-2'>
            {/* @ts-expect-error */}
            <Textbox
              label='VoIP'
              id='txtVoIP'
              maxLength={20}
              text={data?.contato?.voip}
              onChangedValue={(voip: string) =>
                setData({
                  ...data,
                  contato: { ...data.contato, voip },
                })
              }
            />
          </div>
        </div>
      </Panel.Body>
    </Panel>
  </div>
);

export default PainelContato;
