import React, { useEffect, useRef, useState } from 'react';
import {
  ResponseStatus,
  Theme,
  BootstrapSizes,
  formatCurrentDate,
  removeSpaces,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';

import { TransacationCodes } from 'ui/Helpers/transactionCodes';

import {
  getNFEImportadaPagined,
  getSearchNewDocuments,
  getSearchNewDocumentsService,
  getSearchNewDocumentsMissing,
  downloadXML,
  downloadPdf,
} from 'core/services/COP/nfeimportada.tsx';

import { getNfeImportadaTipoAutoComplete } from 'core/services/COP/nfeImportadaTipo.tsx';

import { getEmpresasUsuario } from 'core/services/SEG/empresa';

import NFEImportada from 'core/models/COP/nfeimportada.tsx';

import {
  CSDSelPage,
  GridView,
  DatePicker,
  Autocomplete,
  Textbox,
  ToolbarButtons,
  RadioButton,
} from 'ui/components';

import ModalInformacoesNFEImportada from 'ui/pages/COP/NFEImportada/modalInformacoesNFEImportada';
import ModalManifestarNFE from 'ui/pages/COP/NFEImportada/modalManifestarNFE';

export default function NFEImportadaSelecao({
  isActive,
  onOpenTransaction,
  findTransaction,
  transaction,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({
    dtNfeInicial: formatCurrentDate(-1),
    dtNfeFinal: formatCurrentDate(),
    buscaNfeImportada: 'N',
    tipoBuscaNotas: 'N',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [modalInformacoes, setModalInformacoes] = useState({
    show: false,
    data: new NFEImportada({}),
  });
  const [modalManifestar, setModalManifestar] = useState({
    show: false,
    data: new NFEImportada({}),
  });

  const onClickDownloadNF = async (selectedValue) => {
    const { nrSeqNfeImportada, chNfe, nfeImportadaTipo } = selectedValue;

    const { data } = await downloadXML(nrSeqNfeImportada);

    if (data) {
      const dataURI = encodeURIComponent(data);
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:text/plain;charset=utf-8,${dataURI}`;
      downloadLink.download = `${chNfe}_${removeSpaces(
        nfeImportadaTipo.noNfeImportadaTipo
      )}.xml`;
      downloadLink.click();
    }
  };

  const onClickDownloadPdf = async (selectedValue) => {
    const { nrSeqNfeImportada, chNfe, nfeImportadaTipo } = selectedValue;

    const { data } = await downloadPdf(nrSeqNfeImportada);

    if (data) {
      const downloadLink = document.createElement('a');

      downloadLink.href = `data:application/pdf;base64, ${data}`;

      downloadLink.download = `${chNfe}_${removeSpaces(
        nfeImportadaTipo.noNfeImportadaTipo
      )}.pdf`;

      downloadLink.click();
    }
  };

  const onClickInformacoesNF = async (selectedValue) => {
    setModalInformacoes({ show: true, data: selectedValue });
  };

  const onClickManifestarNF = async (selectedValue) => {
    setModalManifestar({ show: true, data: selectedValue });
  };

  const onClickImportNF = async (selectedValue) => {
    const transactionToOpen = TransacationCodes.NotaFiscalEntrada;
    const transactionOpen = findTransaction(transactionToOpen);
    transactionOpen.registryKey = 'nrSeqNfeImportada';
    transactionOpen.register = selectedValue;
    onOpenTransaction(transactionOpen, true);
  };

  const tipoNfeImportada = [
    {
      text: 'Sim',
      value: 'S',
    },
    {
      text: 'Não',
      value: 'N',
    },
  ];

  const tipoBusca = [
    {
      text: 'Sim',
      value: 'S',
    },
    {
      text: 'Não',
      value: 'N',
    },
  ];

  const columns = [
    { key: 'nrSeqNfeImportada', type: GridView.ColumnTypes.Checkbox },
    { key: 'nfeImportadaTipo.noNfeImportadaTipo', title: 'Tipo' },
    { key: 'noFornecedor', title: 'Fornecedor' },
    { key: 'noSerie', title: 'Série' },
    { key: 'noNumero', title: 'Número' },
    { key: 'noCliente', title: 'Cliente' },

    { key: 'dtNfe', title: 'Emissão', format: GridView.DataTypes.Date },
    { key: 'chNfe', title: 'Chave' },
    { key: 'noCte', title: 'CTE' },
    { key: 'vlrNfe', title: 'Valor', format: GridView.DataTypes.Decimal },

    {
      key: 'nrSeqNfeImportada',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickImportNF(e, datasource),
      theme: Theme.Warning,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Importar',
      tooltipDirection: 'bottom',
      title: 'Abrir',
      visibleDynamic: 'flgNotaCompraCompleta',
    },

    {
      key: 'nrSeqNfeImportada',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownloadNF(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download XML',
      tooltipDirection: 'bottom',
      title: 'XML',
    },

    {
      key: 'nrSeqNfeImportada',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownloadPdf(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download PDF',
      tooltipDirection: 'bottom',
      title: 'PDF',
    },

    {
      key: 'nrSeqNfeImportada',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickInformacoesNF(e, datasource),
      theme: Theme.Info,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Informações',
      tooltipDirection: 'bottom',
      title: 'Info',
    },

    {
      key: 'nrSeqNfeImportada',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickManifestarNF(e, datasource),
      theme: Theme.Primary,
      icon: 'check',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Manifestar',
      tooltipDirection: 'bottom',
      title: 'Manif',
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchNewDocuments = async () => {
    const { status, message: msg } = await getSearchNewDocuments({
      nrSeqEmpresa: filtros.nrSeqEmpresa,
    });
    if (msg) onSetMessage(status, msg);
  };

  const onSearchNewDocumentsMissing = async () => {
    const { status, message: msg } = await getSearchNewDocumentsMissing({
      nrSeqEmpresa: filtros.nrSeqEmpresa,
      dtNfeInicial: filtros.dtNfeInicial,
      dtNfeFinal: filtros.dtNfeFinal,
    });
    if (msg) onSetMessage(status, msg);
  };

  const onSearchNewDocumentsService = async () => {
    const { status, message: msg, data } = await getSearchNewDocumentsService({
      nrSeqEmpresa: filtros.nrSeqEmpresa,
    });
    if (msg) onSetMessage(status, msg);
    return data;
  };

  const onSearchNfeImportadaTipo = async (e) => {
    const {
      status,
      message: msg,
      data: tipos,
    } = await getNfeImportadaTipoAutoComplete({
      noNfeImportadaTipo: e,
    });
    if (msg) onSetMessage(status, msg);
    return tipos;
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onClickBuscarNotas = async () => {
    setLoading(true);
    await onSearchNewDocuments();
    setLoading(false);
  };

  const onClickBuscarNotasFaltantes = async () => {
    setLoading(true);
    await onSearchNewDocumentsMissing();
    setLoading(false);
  };

  const search = async (params = null) => {
    setLoading(true);

    const {
      data,
      pagination,
      message: msg,
      status,
    } = await getNFEImportadaPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);

    if (msg) onSetMessage(status, msg);
  };

  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getNFEImportadaPagined(filtros);

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  useEffect(() => {
    if (filtros.nrSeqEmpresa) {
      setInterval(async () => {
        const { data } = await onSearchNewDocumentsService();
        if (data) if (data.length > 0) search();
      }, 3600000);
    }
  }, [filtros.nrSeqEmpresa]);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Notas Eletronicas'
      loading={loading}
      onSearch={() => search()}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Buscar Notas Sefaz'
          icon={['fa', 'share']}
          onClick={() => onClickBuscarNotas()}
        />
        <ToolbarButtons.Button
          text='Preencher NSU Faltantes Sefaz'
          icon={['fa', 'share']}
          onClick={() => onClickBuscarNotasFaltantes()}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col-8'>
          <Autocomplete
            label='Empresa'
            searchDataSource={onSearchEmpresa}
            selectedItem={filtros.empresa}
            onSelectItem={(empresa) => {
              setFiltros({
                ...filtros,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-4 mb-4'>
          <RadioButton
            label='Buscar Somente Notas Não Importadas'
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={filtros.buscaNfeImportada}
            buttons={tipoNfeImportada}
            onChange={(buscaNfeImportada) => {
              setFiltros({
                ...filtros,
                buscaNfeImportada,
              });
            }}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Tipo'
            searchDataSource={onSearchNfeImportadaTipo}
            selectedItem={filtros.tipo}
            onSelectItem={(tipo) => {
              setFiltros({
                ...filtros,
                tipo,
                nrSeqNfeImportadaTipo: tipo.nrSeqNfeImportadaTipo,
              });
            }}
            dataSourceTextProperty='noNfeImportadaTipo'
          />
        </div>
        <div className='col-4'>
          <Textbox
            maxLength={44}
            label='Chave NFE'
            text={filtros.chNfe}
            onChangedValue={(chNfe) => setFiltros({ ...filtros, chNfe })}
          />
        </div>
        <div className='col-4 mb-4'>
          <RadioButton
            label='Buscar das mais antigas as mais novas'
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={filtros.tipoBuscaNotas}
            buttons={tipoBusca}
            onChange={(tipoBuscaNotas) => {
              setFiltros({
                ...filtros,
                tipoBuscaNotas,
              });
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Emissão Inicial'
            text={filtros.dtNfeInicial}
            mask={MaskTypes.Date}
            onChange={(dtNfeInicial) =>
              setFiltros({
                ...filtros,
                dtNfeInicial,
              })
            }
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Emissão Final'
            text={filtros.dtNfeFinal}
            mask={MaskTypes.Date}
            onChange={(dtNfeFinal) =>
              setFiltros({
                ...filtros,
                dtNfeFinal,
              })
            }
          />
        </div>

        <div className='col-4'>
          <Textbox
            maxLength={44}
            label='Fornecedor'
            text={filtros.noPessoaFor}
            onChangedValue={(noPessoaFor) =>
              setFiltros({ ...filtros, noPessoaFor })
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <GridView
            enableExcelExport
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            transaction={transaction}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <ModalInformacoesNFEImportada
            show={modalInformacoes.show}
            data={modalInformacoes.data}
            onClose={() =>
              setModalInformacoes({ show: false, data: new NFEImportada({}) })
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <ModalManifestarNFE
            show={modalManifestar.show}
            data={modalManifestar.data}
            onClose={() =>
              setModalManifestar({ show: false, data: new NFEImportada({}) })
            }
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
