export default class EstoqueTipo {
  constructor(jsonObject = {}) {
    this.nrSeqEstoqueTipo = jsonObject.nrSeqEstoqueTipo;
    this.cdEstoqueTipo = jsonObject.cdEstoqueTipo;
    this.noEstoqueTipo = jsonObject.noEstoqueTipo;
    this.noSinal = jsonObject.noSinal;
    this.flgContaMovimento = jsonObject.flgContaMovimento;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.noPaginaWeb = jsonObject.noPaginaWeb;
    this.flgLancamentoManual = jsonObject.flgLancamentoManual;
    this.flgServico = jsonObject.flgServico;
    this.cdTransacao = jsonObject.cdTransacao;
  }

  toJson = () => JSON.stringify(this);
}
