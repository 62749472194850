import { EncargoManual } from 'core/models/TEL';
import api from '../api';

const getEncargoManualAutoComplete = async (filters) => {
  const response = await api.get(`TEL/EncargoManual/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    encargos: value?.map((json) => new EncargoManual(json)) ?? [],
  };
};

export { getEncargoManualAutoComplete };
