import qs from 'qs';
import Pagination from 'core/models/pagination';
import PocPocoPerflitCamada from 'core/models/POC/pocPocoPerflitCamada';
import api from '../api';

const baseURL = `/POC/PocPocoPerflitCamada`;

export const sortPocPocoPerflitCamada = async (data) => {
  const response = await api.post(`${baseURL}/Sort`, data);
  const { value } = response.data ?? {};
  return {
    listaCamada: value?.map((json) => new PocPocoPerflitCamada(json)) ?? [],
  };
};

export const getPococoPerflitCamadaList = async (filters) => {
  const response = await api.get(`/$PocPocoPerflitCamada/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPocoPerflitCamada(json)) ?? [],
  };
};

export const getPocPocoPerflitCamadaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocPocoPerflitCamada(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocPocoPerflitCamada = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocPocoPerflitCamada(value) : {};
};

export const savePocPocoPerflitCamada = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePocPocoPerflitCamada = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPocPocoPerflitCamada = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocPocoPerflitCamada = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocPocoPerflitCamada = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
