import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { MaskTypes } from 'ui/Helpers/masks';
import { BootstrapSizes } from 'ui/Helpers/utils';
import { Theme, ResponseStatus, StatusGrid } from 'ui/Helpers/enums.ts';
import { Textbox, Panel, Autocomplete, Button, GridView } from 'ui/components';
import { getCidadeAutoComplete, getCep } from 'core/services/SEG/cidade';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
import { getPaisAutoComplete } from 'core/services/SEG/pais';
import { getTipoEnderecoAutoComplete } from 'core/services/SEG/tipoEndereco';
import { Endereco, Cidade, Pais, Estado, TipoEndereco } from 'core/models/SEG';

interface Props {
  formData?: any;
  setFormData: (object: any) => void;
  endereco?: Endereco | Array<Endereco>;
  setLoading: (e: boolean) => void;
  onSetMessage: (status: ResponseStatus, msg: string) => void;
}

const PainelEndereco = forwardRef(
  ({ formData, setLoading, onSetMessage, setFormData }: Props, ref) => {
    const [address, setAddress] = useState<Endereco | any>({});

    const [enderecoAdicionaisData, setEnderecoAdicionaisData] = useState(
      new Endereco({})
    );
    const gridViewEnderecoAdicionais = useRef();

    const fillGridView = () => {
      if (
        gridViewEnderecoAdicionais &&
        gridViewEnderecoAdicionais.current &&
        formData.enderecoAdicionais &&
        formData.enderecoAdicionais.length > 0
      ) {
        const gridList = formData.enderecoAdicionais.map((item) => item);
        gridViewEnderecoAdicionais.current.setDataSource(gridList);
      }
    };

    function isEmpty(obj: any) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    useImperativeHandle(ref, () => ({
      clear() {
        setAddress({});
        setEnderecoAdicionaisData({});
        gridViewEnderecoAdicionais?.current?.setDataSource([]);
      },
      clearEnderecoAdicional() {
        setEnderecoAdicionaisData({});
        gridViewEnderecoAdicionais?.current?.setDataSource([]);
      },
      fillGrid() {
        fillGridView();
      },
      getDataGrid() {
        const listaEnderecoAdiconais =
          gridViewEnderecoAdicionais?.current?.getDataSource() ?? [];
        return listaEnderecoAdiconais;
      },
    }));

    useEffect(() => {
      if (
        formData.endereco !== address ||
        formData.enderecoAdiconais ||
        formData.enderecoAdiconais?.length > 0
      ) {
        setAddress(formData.endereco);
        setEnderecoAdicionaisData(formData.enderecoAdiconais);
      } else setAddress({});
    }, [formData.endereco?.nrSeqEndereco]);

    useEffect(() => {
      if (address && !isEmpty(address)) {
        setFormData({
          ...formData,
          endereco: address,
        });
      }
    }, [address]);

    const onSearchCep = async (): Promise<void> => {
      if (address.cep && address.cep.length > 4) {
        setLoading(true);
        const enderecoInfos: any = await getCep({
          cep: address.cep,
        });

        if (enderecoInfos) {
          setAddress({
            nrSeqEndereco: address.nrSeqEndereco,
            cep: enderecoInfos.cep,
            bairro: enderecoInfos.bairro,
            noRua: enderecoInfos.noRua,
            cidade: enderecoInfos.cidade,
            nrSeqCidade: enderecoInfos.cidade?.nrSeqCidade,
            noCidade: enderecoInfos.cidade?.noCidade,
            estado: {
              ...enderecoInfos.cidade?.estado,
              nrSeqPais: enderecoInfos.cidade?.estado?.pais?.nrSeqPais,
            },
            pais: enderecoInfos.cidade?.estado?.pais,
          });
        }
        setLoading(false);
      }
    };

    const onSearchCepEnderecoAdicionais = async (): Promise<void> => {
      if (enderecoAdicionaisData.cep && enderecoAdicionaisData.cep.length > 4) {
        setLoading(true);
        const enderecoInfos: any = await getCep({
          cep: enderecoAdicionaisData.cep,
        });

        if (enderecoInfos) {
          setEnderecoAdicionaisData({
            nrSeqEndereco: enderecoAdicionaisData.nrSeqEndereco,
            cep: enderecoInfos.cep,
            bairro: enderecoInfos.bairro,
            noRua: enderecoInfos.noRua,
            cidade: enderecoInfos.cidade,
            nrSeqCidade: enderecoInfos.cidade?.nrSeqCidade,
            noCidade: enderecoInfos.cidade?.noCidade,
            estado: {
              ...enderecoInfos.cidade?.estado,
              nrSeqPais: enderecoInfos.cidade?.estado?.pais?.nrSeqPais,
            },
            pais: enderecoInfos.cidade?.estado?.pais,
          });
        }
        setLoading(false);
      }
    };

    const onSearchPais = async (e: string) => {
      const { status, message: msg, paises } = await getPaisAutoComplete({
        noPais: e,
      });
      onSetMessage(status, msg);
      return paises;
    };

    const onSearchEstado = async (e: string) => {
      const { status, message: msg, estados } = await getEstadoAutoComplete({
        noEstado: e,
        nrSeqPais: address?.pais?.nrSeqPais ?? null,
      });

      if (msg && status) onSetMessage(status, msg);
      return estados;
    };

    const onSearchCidade = async (e: string) => {
      if (
        address === undefined ||
        address.estado === undefined ||
        Object.keys(address.estado).length === 0
      ) {
        onSetMessage(
          ResponseStatus.Error,
          'Informar o estado antes de pesquisar a cidade'
        );
        return [];
      }
      const { status, message: msg, cidades } = await getCidadeAutoComplete({
        noCidade: e,
        nrSeqEstado: address.estado?.nrSeqEstado ?? null,
      });

      onSetMessage(status, msg);
      return cidades;
    };

    const onAdicionarEnderecoAdicionais = async () => {
      if (
        enderecoAdicionaisData &&
        Object.keys(enderecoAdicionaisData).length > 0
      ) {
        if (
          enderecoAdicionaisData.nrSeqTipoEndereco === undefined ||
          enderecoAdicionaisData.nrSeqTipoEndereco === null
        ) {
          onSetMessage(ResponseStatus.Error, 'Informe o tipo de endereço');
          return;
        }

        if (enderecoAdicionaisData.nrSeqTipoEndereco === 1) {
          onSetMessage(
            ResponseStatus.Error,
            'Já possui um endereço com este tipo de endereço'
          );
          return;
        }

        const listEnderecos = gridViewEnderecoAdicionais.current.getDataSource();

        if (listEnderecos && listEnderecos.length > 0) {
          for (let i = 0; i < listEnderecos.length; i += 1) {
            const endereco = listEnderecos[i];

            if (
              endereco.nrSeqTipoEndereco ===
                enderecoAdicionaisData.nrSeqTipoEndereco &&
              endereco.status !== 'Remover'
            ) {
              onSetMessage(
                ResponseStatus.Error,
                'Já possui um endereço com este tipo de endereço'
              );
              return;
            }
          }
        }

        const obj = {
          ...enderecoAdicionaisData,
          status: enderecoAdicionaisData.status ?? StatusGrid.Inserir,
        };

        const list = gridViewEnderecoAdicionais.current.getDataSource();
        list.push(obj);

        if (gridViewEnderecoAdicionais && gridViewEnderecoAdicionais.current)
          gridViewEnderecoAdicionais.current.setDataSource(list);

        setFormData({
          ...formData,
          enderecoAdicionais: list,
        });
        setEnderecoAdicionaisData({});
      } else {
        onSetMessage(ResponseStatus.Error, 'Informe algum Endereço');
      }
    };

    const onClickEditEnderecoAdicionais = async (selectedValue, datasource) => {
      const dataOrdemRemDes = datasource.find((item) => item === selectedValue);
      if (
        dataOrdemRemDes.nrSeqEndereco === undefined ||
        dataOrdemRemDes.nrSeqEndereco === null
      ) {
        dataOrdemRemDes.status = StatusGrid.Inserir;
      } else {
        dataOrdemRemDes.status = StatusGrid.Alterar;
      }

      const dataProdutos = datasource.filter(
        (produto) => produto !== selectedValue
      );

      setEnderecoAdicionaisData(dataOrdemRemDes);

      if (gridViewEnderecoAdicionais && gridViewEnderecoAdicionais.current)
        gridViewEnderecoAdicionais.current.setDataSource(dataProdutos);
    };

    const onRemoveEnderecoAdicionais = async (e, datasource) => {
      const index = datasource.findIndex((item) => item === e);
      datasource[index].status = StatusGrid.Remover;

      if (gridViewEnderecoAdicionais && gridViewEnderecoAdicionais.current)
        gridViewEnderecoAdicionais.current.setDataSource(datasource);
    };

    const columns = [
      {
        key: 'nrSeqPessoaRem',
        title: 'nrSeqPessoaRem',
        visible: false,
      },
      {
        key: 'cep',
        title: 'CEP',
      },
      { key: 'noRua', title: 'Rua' },
      { key: 'numero', title: 'Número' },
      { key: 'complemento', title: 'Complemento' },
      { key: 'bairro', title: 'Bairro' },
      { key: 'pais.noPais', title: 'Pais' },
      { key: 'estado.noEstado', title: 'Estado' },
      { key: 'cidade.noCidade', title: 'Cidade' },
      { key: 'tipoEndereco.noTipoEndereco', title: 'Tipo de Endereço' },
      {
        key: 'nrSeqPessoaRem',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) =>
          onClickEditEnderecoAdicionais(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },
      // ADICIONANDO BOTÃO DE DELEÇÃO
      {
        key: 'nrSeqPessoaRem',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemoveEnderecoAdicionais(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    const onSearchTipoEnderecoAutoComplete = async (e: any) => {
      const { tipoEndereco } = await getTipoEnderecoAutoComplete({
        noTipoEndereco: e,
      });

      return tipoEndereco;
    };

    return (
      <div className='row mt-3'>
        {/* @ts-expect-error */}
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header title='Endereço' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Cep'
                  id='txtCep'
                  text={address?.cep}
                  maxLength={9}
                  onChangedValue={(cep: string) => {
                    setAddress({
                      ...address,
                      cep,
                    });
                  }}
                />
              </div>
              <div className='col-1 mt-3'>
                <Button
                  outline
                  theme={Theme.Primary}
                  icon='search'
                  tooltip='Pesquisar o endereço pelo CEP digitado.'
                  onClick={onSearchCep}
                />
              </div>
              <div className='col-4'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Rua'
                  id='txtRua'
                  text={address?.noRua}
                  maxLength={80}
                  onChangedValue={(noRua: string) => {
                    setAddress({
                      ...address,
                      noRua,
                    });
                  }}
                />
              </div>

              <div className='col-2'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Número'
                  id='txtNumero'
                  mask={MaskTypes.Integer}
                  text={address?.numero}
                  maxLength={20}
                  onChangedValue={(numero: any) => {
                    const numeroAsString =
                      typeof numero === 'number' ? String(numero) : numero;

                    setAddress({
                      ...address,
                      numero: numeroAsString,
                    });
                  }}
                />
              </div>

              <div className='col-3'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Complemento'
                  id='txtComplemento'
                  text={address?.complemento}
                  maxLength={100}
                  onChangedValue={(complemento: string) =>
                    setAddress({
                      ...address,
                      complemento,
                    })
                  }
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-3'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Bairro'
                  id='txtBairro'
                  text={address?.bairro}
                  maxLength={60}
                  onChangedValue={(bairro: string) => {
                    setAddress({
                      ...address,
                      bairro,
                    });
                  }}
                />
              </div>

              <div className='col-3'>
                <Autocomplete
                  label='País'
                  id='txtPais'
                  searchDataSource={onSearchPais}
                  selectedItem={address?.pais}
                  onSelectItem={(pais: Pais) => {
                    setAddress({
                      ...address,
                      pais,
                      nrSeqPais: pais.nrSeqPais,
                    });
                  }}
                  dataSourceTextProperty='noPais'
                />
              </div>

              <div className='col-3'>
                <Autocomplete
                  label='Estado'
                  id='txtEstado'
                  searchDataSource={onSearchEstado}
                  selectedItem={address?.estado}
                  onSelectItem={(estado: Estado) => {
                    setAddress({
                      ...address,
                      estado,
                      nrSeqEstado: estado.nrSeqEstado,
                    });
                  }}
                  dataSourceTextProperty='noEstado'
                />
              </div>

              <div className='col-3'>
                <Autocomplete
                  label='Cidade'
                  id='txtCidade'
                  searchDataSource={onSearchCidade}
                  selectedItem={address?.cidade}
                  onSelectItem={(cidade: Cidade) => {
                    setAddress({
                      ...address,
                      cidade,
                      nrSeqCidade: cidade.nrSeqCidade,
                      noCidade: cidade.noCidade,
                    });
                  }}
                  dataSourceTextProperty='noCidade'
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>

        <div className='row mt-3'>
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header title='Endereços Adicionais' theme={Theme.Primary} />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Cep'
                    text={enderecoAdicionaisData?.cep}
                    maxLength={9}
                    onChangedValue={(cep: string) => {
                      setEnderecoAdicionaisData({
                        ...enderecoAdicionaisData,
                        cep,
                      });
                    }}
                  />
                </div>
                <div className='col-1 mt-3'>
                  <Button
                    outline
                    theme={Theme.Primary}
                    icon='search'
                    tooltip='Pesquisar o endereço pelo CEP digitado.'
                    onClick={onSearchCepEnderecoAdicionais}
                  />
                </div>
                <div className='col-4'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Rua'
                    text={enderecoAdicionaisData?.noRua}
                    maxLength={80}
                    onChangedValue={(noRua: string) => {
                      setEnderecoAdicionaisData({
                        ...enderecoAdicionaisData,
                        noRua,
                      });
                    }}
                  />
                </div>

                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Número'
                    id='txtNumero'
                    mask={MaskTypes.Integer}
                    text={enderecoAdicionaisData?.numero}
                    maxLength={20}
                    onChangedValue={(numero: any) => {
                      const numeroAsString =
                        typeof numero === 'number' ? String(numero) : numero;

                      setEnderecoAdicionaisData({
                        ...enderecoAdicionaisData,
                        numero: numeroAsString,
                      });
                    }}
                  />
                </div>

                <div className='col-3'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Complemento'
                    text={enderecoAdicionaisData?.complemento}
                    maxLength={100}
                    onChangedValue={(complemento: string) =>
                      setEnderecoAdicionaisData({
                        ...enderecoAdicionaisData,
                        complemento,
                      })
                    }
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-3'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Bairro'
                    text={enderecoAdicionaisData?.bairro}
                    maxLength={60}
                    onChangedValue={(bairro: string) => {
                      setEnderecoAdicionaisData({
                        ...enderecoAdicionaisData,
                        bairro,
                      });
                    }}
                  />
                </div>

                <div className='col-3'>
                  <Autocomplete
                    label='País'
                    searchDataSource={onSearchPais}
                    selectedItem={enderecoAdicionaisData?.pais}
                    onSelectItem={(pais: Pais) => {
                      setEnderecoAdicionaisData({
                        ...enderecoAdicionaisData,
                        pais,
                        nrSeqPais: pais.nrSeqPais,
                      });
                    }}
                    dataSourceTextProperty='noPais'
                  />
                </div>

                <div className='col-3'>
                  <Autocomplete
                    label='Estado'
                    searchDataSource={onSearchEstado}
                    selectedItem={enderecoAdicionaisData?.estado}
                    onSelectItem={(estado: Estado) => {
                      setEnderecoAdicionaisData({
                        ...enderecoAdicionaisData,
                        estado,
                        nrSeqEstado: estado.nrSeqEstado,
                      });
                    }}
                    dataSourceTextProperty='noEstado'
                  />
                </div>
                <div className='col-3'>
                  <Autocomplete
                    label='Cidade'
                    searchDataSource={onSearchCidade}
                    selectedItem={enderecoAdicionaisData?.cidade}
                    onSelectItem={(cidade: Cidade) => {
                      setEnderecoAdicionaisData({
                        ...enderecoAdicionaisData,
                        cidade,
                        nrSeqCidade: cidade.nrSeqCidade,
                        noCidade: cidade.noCidade,
                      });
                    }}
                    dataSourceTextProperty='noCidade'
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-3'>
                  <Autocomplete
                    label='* Tipo Endereço'
                    searchDataSource={onSearchTipoEnderecoAutoComplete}
                    selectedItem={enderecoAdicionaisData?.tipoEndereco}
                    onSelectItem={(tipoEndereco: TipoEndereco) => {
                      setEnderecoAdicionaisData({
                        ...enderecoAdicionaisData,
                        tipoEndereco,
                        nrSeqTipoEndereco: tipoEndereco.nrSeqTipoEndereco,
                      });
                    }}
                    dataSourceTextProperty='noTipoEndereco'
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Button
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Success}
                    template={Button.Templates.Quick}
                    onClick={onAdicionarEnderecoAdicionais}
                    text='Adicionar'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <GridView
                    ref={gridViewEnderecoAdicionais}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns}
                    showPagination={false}
                    showSelectSizes={false}
                    offlineData
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
    );
  }
);

export default PainelEndereco;
