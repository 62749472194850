import qs from 'qs';
import { ContaCabecalho } from 'core/models/TEL';
import CancelaFaturamentoContaTelefone from 'core/models/TEL/CancelaFaturamentoContaTelefone';
import api from '../api';

const base = 'TEL/ContaRecurso';

export const getFaturarContaTelefone = async (filters) => {
  const response = await api.get(`${base}/SearchFaturarContaTelefone`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, pagination, value } = response.data ?? {};

  return {
    status,
    message,
    pagination,
    contasCabecalho: value?.map((json) => new ContaCabecalho(json)) ?? [],
  };
};

export const getCancelaFaturaContaTelefone = async (filters) => {
  const response = await api.get(`${base}/SearchCancelaFaturaContaTelefone`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, pagination, value } = response.data ?? {};

  return {
    status,
    message,
    pagination,
    contas:
      value?.map((json) => new CancelaFaturamentoContaTelefone(json)) ?? [],
  };
};

export const getPrintFaturaContaTelefone = async (filters) => {
  const response = await api.get(`${base}/SearchPrintFaturaContaTelefone`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, pagination, value } = response.data ?? {};

  return {
    status,
    message,
    pagination,
    contas:
      value?.map((json) => new CancelaFaturamentoContaTelefone(json)) ?? [],
  };
};

export const getFaturaImprimirBoletos = async (filters) => {
  const response = await api.get(`${base}/ImprimirBoleto`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, pagination, value } = response.data ?? {};

  return {
    status,
    message,
    pagination,
    contasCabecalho: value?.map((json) => new ContaCabecalho(json)) ?? [],
  };
};

export const postFaturarContaTelefone = async (data) => {
  const response = await api.post(`${base}/FaturarContaTelefone`, data);
  return response.data ?? {};
};

export const postCancelaFaturaContaTelefone = async (data) => {
  const response = await api.post(`${base}/CancelaFaturaContaTelefone`, data);
  return response.data ?? {};
};

export const postImprimirBoletosFaturaTelefone = async (data) => {
  const response = await api.post(
    `${base}/ImprimirBoletosFaturaTelefone`,
    data
  );
  return response.data ?? {};
};
