export default class PocEnsaioRecuperacao {
  constructor(jsonObject = {}) {
    this.nrSeqPocEnsaioRecuperacao = jsonObject.nrSeqPocEnsaioRecuperacao;
    this.nrTempo = jsonObject.nrTempo;
    this.nrTempoStr = jsonObject.nrTempoStr;
    this.nrNivel = jsonObject.nrNivel;
    this.nrRecuperacao = jsonObject.nrRecuperacao;
    this.nrSeqPocEnsaio = jsonObject.nrSeqPocEnsaio;
    this.reload = jsonObject.reload;
  }

  toJson = () => JSON.stringify(this);
}
