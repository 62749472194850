import qs from 'qs';
import Pagination from 'core/models/pagination';
import ManutencaoChecklistFrotaDocumento from 'core/models/FRO/manutencaoChecklistFrotaDocumento';
import { MimeTypes } from 'ui/Helpers/utils';
import { FormDataType } from 'ui/Helpers/enums.ts';
import api from '../api';

const baseURL = `/FRO/ManutencaoChecklistFrotaDocumento`;

export const getManutencaoChecklistFrotaDocumentoList = async (filters) => {
  const response = await api.get(`/$ManutencaoChecklistFrotaDocumento/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data:
      value?.map((json) => new ManutencaoChecklistFrotaDocumento(json)) ?? [],
  };
};

export const getManutencaoChecklistFrotaDocumentoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data:
      value?.map((json) => new ManutencaoChecklistFrotaDocumento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getManutencaoChecklistFrotaDocumento = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ManutencaoChecklistFrotaDocumento(value) : {};
};

export const saveManutencaoChecklistFrotaDocumento = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteManutencaoChecklistFrotaDocumento = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printManutencaoChecklistFrotaDocumento = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelManutencaoChecklistFrotaDocumento = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllManutencaoChecklistFrotaDocumento = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const ImportaManutencaochecklistFrotaDocumentos = async (
  data,
  files
) => {
  const formData = new FormData();

  formData.append(FormDataType.Json, JSON.stringify(data));

  files.forEach((file) => {
    formData.append(FormDataType.Files, file);
    formData.append(FormDataType.Extension, MimeTypes.getExtension(file.type));
  });

  const response = await api.post(
    `${baseURL}/ImportaManutencaochecklistFrotaDocumentos`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data:
      value?.map((json) => new ManutencaoChecklistFrotaDocumento(json)) ?? [],
  };
};
