export default class TipoCartao {
  constructor(jsonObject = {}) {
    this.nrSeqTipoCartao = jsonObject.nrSeqTipoCartao;
    this.cdTipoCartao = jsonObject.cdTipoCartao;
    this.noTipoCartao = jsonObject.noTipoCartao;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgMotorista = jsonObject.flgMotorista;
    this.flgVeiculo = jsonObject.flgVeiculo;
    this.flgSolicitaSenha = jsonObject.flgSolicitaSenha;
    this.flgSolicitaSaldo = jsonObject.flgSolicitaSaldo;
    this.noIntegracao = jsonObject.noIntegracao;
    this.flgExibeCte = jsonObject.flgExibeCte;
    this.flfGeraContaCorrente = jsonObject.flfGeraContaCorrente;
    this.nrSeqContaCorrente = jsonObject.nrSeqContaCorrente;
  }

  toJson = () => JSON.stringify(this);
}
