import qs from 'qs';
import Pagination from 'core/models/pagination';
import MaterialRevestimento from 'core/models/POC/materialRevestimento';
import api from '../api';

const baseURL = `/POC/MaterialRevestimento`;

export const getMaterialRevestimentoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    materialRevestimento:
      value?.map((json) => new MaterialRevestimento(json)) ?? [],
  };
};

export const getPocMaterialRevestimentoList = async (filters) => {
  const response = await api.get(`${baseURL}/MaterialRevestimento/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new MaterialRevestimento(json)) ?? [],
  };
};

export const getPocMaterialRevestimentoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new MaterialRevestimento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocMaterialRevestimento = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new MaterialRevestimento(value) : {};
};

export const savePocMaterialRevestimento = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePocMaterialRevestimento = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPocMaterialRevestimento = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocMaterialRevestimento = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocMaterialRevestimento = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
