import qs from 'qs';
import Pagination from 'core/models/pagination';
import ContatoAdicional from 'core/models/SEG/contatoAdicional';
import api from '../api';

const baseURL = `/SEG/ContatoAdicional`;

export const getContatoAdicionalList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ContatoAdicional(json)) ?? [],
  };
};

export const getContatoAdicionalAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/SearchAutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ContatoAdicional(json)) ?? [],
  };
};

export const getContatoAdicionalPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ContatoAdicional(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getContatoAdicional = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ContatoAdicional(value) : {};
};

export const saveContatoAdicional = async (data) => {
  const response = await api.post(`${baseURL}/`, data);

  return response.data ?? {};
};

export const deleteContatoAdicional = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printContatoAdicional = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelContatoAdicional = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllContatoAdicional = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getRepresentantesLegais = async (filters) => {
  const response = await api.get(`${baseURL}/SearchRepresentantesLegais`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ContatoAdicional(json)) ?? [],
  };
};
