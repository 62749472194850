import React, { useRef, useState } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  getChequePagined,
  printCheque,
  deleteAllCheque,
  excelCheque,
} from 'core/services/FIN/cheque';
import {
  CSDSelPage,
  GridView,
  DatePicker,
  Autocomplete,
  RadioButton,
  Textbox,
  Switch,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getChequeSituacaoAutoComplete } from 'core/services/FIN/chequeSituacao';
import { getBancoAutoComplete } from 'core/services/FIN/banco';
import ModalCompensarCheque from './modalCompensarCheque';

export default function Cheque({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();
  const [filtros, setFiltros] = useState({ flgTipo: 'pago' });
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState({});

  const onClickConfirmCheque = async (e) => {
    setLoading(true);
    setModalShow({ ...modalShow, compensarCheque: true, chequeCompensar: e });

    setLoading(false);
  };

  const tipo = [
    { text: 'Pago', value: 'pago' },
    { text: 'Recebido', value: 'recebido' },
  ];

  const columns = [
    { key: 'nrSeqCheque', type: GridView.ColumnTypes.Checkbox },
    { key: 'dtDocumento', title: 'Emissão', format: GridView.DataTypes.Date },
    {
      key: 'dtCompensacao',
      title: 'Compensação',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    { key: 'cliente', title: 'Cliente' },
    { key: 'noDescricaoCheque', title: 'Descrição' },
    { key: 'noBanco', title: 'Banco' },
    { key: 'vlrCheque', title: 'Valor', format: GridView.DataTypes.Decimal },
    { key: 'nrCheque', title: 'Número' },
    { key: 'noChequeSituacao', title: 'Situação' },

    {
      key: 'flgImpresso',
      title: 'Impresso',
      format: GridView.DataTypes.Boolean,
    },
    {
      key: 'flgPermiteImpressao',
      title: 'Imprimir',
      format: GridView.DataTypes.Boolean,
    },

    {
      key: 'nrSeqCheque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickConfirmCheque(e),
      theme: Theme.Primary,
      icon: 'check',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Compensar Cheque',
      tooltipDirection: 'bottom',
      title: 'Compensar',
      visibleDynamic: 'flgPendenteCompensar',
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getChequePagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Cheque/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onSearchChequeSituacao = async () => {
    const {
      status,
      message: msg,
      situacoes,
    } = await getChequeSituacaoAutoComplete();
    onSetMessage(status, msg);
    return situacoes;
  };

  const onSearchBanco = async () => {
    const { status, message: msg, bancos } = await getBancoAutoComplete();
    onSetMessage(status, msg);
    return bancos;
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqCheque = sourceRow.find((e) => e.key === 'nrSeqCheque');
    onOpenMaintenance(nrSeqCheque.value);
  };

  const onPrintFornecedor = async () => {
    setLoading(true);
    const value = await printCheque({ ...filtros, flgOrdenar: 'F' });

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onPrintVencimento = async () => {
    setLoading(true);
    const value = await printCheque({ ...filtros, flgOrdenar: 'V' });

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onDownloadExcel = async () => {
    const { value: base64 } = await excelCheque(filtros);

    return { base64, fileName: 'cheque.csv' };
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllCheque(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Cheque'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onDownloadExcel={onDownloadExcel}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.ButtonList
          onPrintFornecedorAgrupado
          text='Imprimir'
          icon='print'
          items={[
            {
              text: 'Fornecedor',
              onClick: () => onPrintFornecedor(),
            },
            {
              text: 'Vencimento ',
              onClick: () => onPrintVencimento(),
            },
          ]}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col-2'>
          <RadioButton
            label='Tipo:'
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={filtros.flgTipo}
            buttons={tipo}
            onChange={(flgTipo_) =>
              setFiltros({
                ...filtros,
                flgTipo: flgTipo_,
              })
            }
          />
        </div>

        <div className='col-2'>
          <Textbox
            label='NrCheque:'
            text={filtros.nrCheque}
            onChangedValue={(nrCheque) =>
              setFiltros({
                ...filtros,
                nrCheque,
              })
            }
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Emissão de'
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtRecebimentoDe) =>
              setFiltros({ ...filtros, dtRecebimentoDe })
            }
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Emissão até'
            text={filtros.dtRecebimentoAte}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtRecebimentoAte) =>
              setFiltros({ ...filtros, dtRecebimentoAte })
            }
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Vencimento de'
            text={filtros.dtVencimentoDe}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtVencimentoDe) =>
              setFiltros({ ...filtros, dtVencimentoDe })
            }
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Vencimento até'
            text={filtros.dtVencimentoAte}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtVencimentoAte) =>
              setFiltros({ ...filtros, dtVencimentoAte })
            }
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Compensação de'
            text={filtros.dtCompensacaoDe}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtCompensacaoDe) =>
              setFiltros({ ...filtros, dtCompensacaoDe })
            }
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Compensação até'
            text={filtros.dtCompensacaoAte}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtCompensacaoAte) =>
              setFiltros({ ...filtros, dtCompensacaoAte })
            }
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Situação'
            searchDataSource={onSearchChequeSituacao}
            selectedItem={filtros.situacao}
            onSelectItem={(situacao_) => {
              setFiltros({
                ...filtros,
                situacao: situacao_,
                nrSeqChequeSituacao: situacao_.nrSeqChequeSituacao,
              });
            }}
            dataSourceTextProperty='noChequeSituacao'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Banco'
            searchDataSource={onSearchBanco}
            selectedItem={filtros.banco}
            onSelectItem={(banco_) => {
              setFiltros({
                ...filtros,
                banco: banco_,
                nrSeqBanco: banco_.nrSeqBanco,
              });
            }}
            dataSourceTextProperty='noBanco'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-auto'>
          <Switch
            formControl
            label='Impressão Pendente'
            checked={filtros.flgImpressaoPendente}
            onChange={(flgImpressaoPendente) =>
              setFiltros({
                ...filtros,
                flgImpressaoPendente,
              })
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            showSelectSizes={false}
            sumFields
            transaction={transaction}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <ModalCompensarCheque
            show={modalShow.compensarCheque}
            cheque={modalShow.chequeCompensar}
            onClose={() => {
              setModalShow({ ...modalShow, compensarCheque: false });
            }}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
