import Veiculo from 'core/models/FRO/veiculo';
import Infracao from 'core/models/FRO/infracao';
import Motorista from 'core/models/FRO/motorista';
import TituloPagar from 'core/models/FIN/tituloPagar';
import TituloReceber from 'core/models/FIN/tituloReceber';
import FormaPagamento from 'core/models/FIN/formaPagamento';
import TipoDespesa from 'core/models/FIN/tipoDespesa';
import TituloPagarTipoDocumento from 'core/models/FIN/tituloPagarTipoDocumento';
import Cidade from 'core/models/SEG/cidade';
import Empresa from 'core/models/SEG/empresa';

export default class VeiculoInfracao {
  constructor(jsonObject = {}) {
    this.nrSeqVeiculo_Infracao = jsonObject.nrSeqVeiculo_Infracao;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.nrSeqInfracao = jsonObject.nrSeqInfracao;
    this.dtOcorrencia = jsonObject.dtOcorrencia;
    this.dtVencimento = jsonObject.dtVencimento;
    this.vlrInfracao = jsonObject.vlrInfracao;
    this.nrSeqPessoaMot = jsonObject.nrSeqPessoaMot;
    this.obsInfracao = jsonObject.obsInfracao;
    this.nrSeqTituloPagar = jsonObject.nrSeqTituloPagar;
    this.nrSeqTituloReceber = jsonObject.nrSeqTituloReceber;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.hrOcorrencia = jsonObject.hrOcorrencia;
    this.nrSeqCidade = jsonObject.nrSeqCidade;
    this.nrPonto = jsonObject.nrPonto;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    this.infracao = new Infracao(jsonObject.infracao ?? {});
    this.motorista = new Motorista(jsonObject.motorista ?? {});
    this.tituloPagar = new TituloPagar(jsonObject.tituloPagar ?? {});
    this.tituloReceber = new TituloReceber(jsonObject.tituloReceber ?? {});
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.cidade = new Cidade(jsonObject.cidade ?? {});
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.tipoDespesa = new TipoDespesa(jsonObject.tipoDespesa ?? {});
    this.tituloPagarTipoDocumento = new TituloPagarTipoDocumento(
      jsonObject.tituloPagarTipoDocumento ?? {}
    );
    this.flgGeraTituloReceber = jsonObject.flgGeraTituloReceber;
    this.nrSeqTipoDespesa = jsonObject.nrSeqTipoDespesa;
    this.nrSeqTituloPagarTipoDocumento =
      jsonObject.nrSeqTituloPagarTipoDocumento;
  }

  toJson = () => JSON.stringify(this);
}
