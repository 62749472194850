export default class EncargoManual {
  constructor(jsonObject = {}) {
    this.nrSeqEncargoManual = jsonObject.nrSeqEncargoManual;
    this.noEncargoManual = jsonObject.noEncargoManual;
    this.descricaoConta1 = jsonObject.descricaoConta1;
    this.descricaoConta2 = jsonObject.descricaoConta2;
    this.vlrEncargo = jsonObject.vlrEncargo;
    this.nrSeqTipoChamada = jsonObject.nrSeqTipoChamada;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;

    this.noEncargoManual = jsonObject.noEncargoManual;
  }

  toJson = () => JSON.stringify(this);
}
