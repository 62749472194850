import VeiculoFuncao from './veiculoFuncao';
import TipoVeiculoMdfe from './tipoVeiculoMdfe';

export default class VeiculoTipo {
  constructor(jsonObject = {}) {
    this.nrSeqVeiculoTipo = jsonObject.nrSeqVeiculoTipo;
    this.noVeiculoTipo = jsonObject.noVeiculoTipo;
    this.cdVeiculoTipo = jsonObject.cdVeiculoTipo;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgPrincipal = jsonObject.flgPrincipal;
    this.flgAceitaVinculo = jsonObject.flgAceitaVinculo;
    this.nrSeqVeiculoFuncao = jsonObject.nrSeqVeiculoFuncao;
    this.tipoRodadoMdfe = jsonObject.tipoRodadoMdfe;
    this.flgAceitaFerramenta = jsonObject.flgAceitaFerramenta;
    this.nrEixos = jsonObject.nrEixos;
    this.veiculoFuncao = new VeiculoFuncao(jsonObject.veiculoFuncao ?? {});
    this.tipoVeiculoMdfe = new TipoVeiculoMdfe(
      jsonObject.tipoVeiculoMdfe ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
