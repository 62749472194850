import React, { useRef, useState } from 'react';
import {
  PageTypes,
  ResponseStatus,
  BootstrapSizes,
  Theme,
} from 'ui/Helpers/utils';

import {
  getClienteListWithPermission,
  deleteClientes,
  printClientes,
} from 'core/services/FIN/cliente';

import { getVendedorAutoComplete } from 'core/services/VEN/vendedor';

import { getCidadeAutoComplete } from 'core/services/SEG/cidade';

import {
  CSDSelPage,
  GridView,
  RadioButton,
  Textbox,
  ToolbarButtons,
  Autocomplete,
} from 'ui/components';
import ModalImportacaoClientes from './modalImportacaoClientes';

const columns = [
  { key: 'nrSeqPessoaCli', type: GridView.ColumnTypes.Checkbox },
  { key: 'noPessoa', title: 'Nome' },
  { key: 'cdCliente02', title: 'Matrícula' },
  { key: 'cdCliente', title: 'Código' },
  { key: 'email', title: 'E-mail' },
];

const typePerson = [
  { text: 'Todos', value: 'T' },
  { text: 'Física', value: 'F' },
  { text: 'Jurídica', value: 'J' },
];

export default function CadastroRapidoCliente({
  isActive,
  transaction,
  onOpenPage,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({ typePerson: 'T' });
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState(null);
  const [showImportacao, setShowImportacao] = useState(false);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessages({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchVendedor = async (e) => {
    const { status, message: msg, vendedores } = await getVendedorAutoComplete({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return vendedores;
  };

  const onSearchCidade = async (e) => {
    const { status, message: msg, cidades } = await getCidadeAutoComplete({
      noCidade: e,
    });
    onSetMessage(status, msg);
    return cidades;
  };

  const search = async () => {
    setLoading(true);

    const { clientes, pagination } = await getClienteListWithPermission(
      filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(clientes, pagination);

    setLoading(false);
  };

  const onColumnSort = async (SortBy) => {
    setFiltros({ ...filtros, SortBy });
    await search();
  };
  const onPageSizeChange = async (totalByPage) => {
    setFiltros({ ...filtros, totalByPage });
    await search();
  };
  const onPageChange = async (page) => {
    setFiltros({ ...filtros, page });
    await search();
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'CadastroRapidoCliente30/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqPessoa = sourceRow.find((e) => e.key === 'nrSeqPessoaCli');
    onOpenMaintenance(nrSeqPessoa.value);
  };

  const onPrint = async () => {
    setLoading(true);

    const { value } = await printClientes(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message } = await deleteClientes(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessages({
        message,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessages({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Cadastro Rapido de Cliente'
      loading={loading}
      onSearch={search}
      onDelete={onDelete}
      onNew={onOpenMaintenance}
      onPrint={onPrint}
      message={messages}
      onMessagerClose={() => setMessages(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Importar Cliente'
          icon='cloud-upload-alt'
          onClick={() => setShowImportacao(true)}
        />
      </ToolbarButtons>

      <div className='row mb-3'>
        <div className='col-6'>
          <Textbox
            label='Nome'
            maxLength={200}
            text={filtros.noPessoa}
            onChangedValue={(e) => setFiltros({ ...filtros, noPessoa: e })}
          />
        </div>
        <div className='col-3'>
          <Textbox
            label='Código'
            text={filtros.cdCliente0102}
            onChangedValue={(e) => setFiltros({ ...filtros, cdCliente0102: e })}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-6'>
          <Autocomplete
            label='Vendedor'
            searchDataSource={onSearchVendedor}
            selectedItem={filtros.vendedor}
            onSelectItem={(vendedor) =>
              setFiltros({
                ...filtros,
                vendedor,
                nrSeqPessoaVen: vendedor.nrSeqPessoa,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>

        <div className='col-3'>
          <Autocomplete
            label='Cidade'
            searchDataSource={onSearchCidade}
            selectedItem={filtros.cidade}
            onSelectItem={(cidade) =>
              setFiltros({
                ...filtros,
                cidade,
                nrSeqCidade: cidade.nrSeqCidade,
              })
            }
            dataSourceTextProperty='noCidade'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-3'>
          <RadioButton
            label='Tipo de Pessoa'
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={filtros.typePerson}
            buttons={typePerson}
            onChange={(obj) =>
              setFiltros({
                ...filtros,
                typePerson: obj,
              })
            }
          />
        </div>
        {filtros.typePerson === 'J' && (
          <>
            <div className='col-3'>
              <Textbox
                label='Jurídica'
                text={filtros.nrCNPJ}
                onChangedValue={(nrCNPJ) =>
                  setFiltros({ ...filtros, nrCNPJ, nrCpf: '' })
                }
              />
            </div>
            <div className='col-3'>
              <Textbox
                label='Fantasia'
                text={filtros.noFantasia}
                onChangedValue={(noFantasia) =>
                  setFiltros({ ...filtros, noFantasia })
                }
              />
            </div>
          </>
        )}
        {filtros.typePerson === 'F' && (
          <div className='col-3'>
            <Textbox
              label='Física'
              text={filtros.nrCPF}
              onChangedValue={(nrCPF) =>
                setFiltros({ ...filtros, nrCPF, nrCNPJ: '' })
              }
            />
          </div>
        )}
      </div>

      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            showSelectSizes={false}
            transaction={transaction}
          />
        </div>
      </div>
      <ModalImportacaoClientes
        show={showImportacao}
        onCancel={() => setShowImportacao(false)}
        onSuccess={(clientes) => {
          setMessages({
            message:
              'Arquivo processado, confira o arquivo erros.txt para verificar se houve algum erro de importação.',
            theme: Theme.Success,
          });
          gridView.current.setDataSource(clientes);
          setShowImportacao(false);
        }}
      />
    </CSDSelPage>
  );
}
