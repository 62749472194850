export default class ConvenioExtrato {
  constructor(jsonObject = {}) {
    this.nrSeqConvenioExtrato = jsonObject.nrSeqConvenioExtrato;
    this.nrSeqConvenio = jsonObject.nrSeqConvenio;
    this.nrSeqPessoaAss = jsonObject.nrSeqPessoaAss;
    this.anoMes = jsonObject.anoMes;
    this.nrSeqPlanoConvEncargoConv = jsonObject.nrSeqPlanoConvEncargoConv;
    this.vlrOriginal = jsonObject.vlrOriginal;
    this.vlrRecalculado = jsonObject.vlrRecalculado;
    this.nrSeqTituloReceber = jsonObject.nrSeqTituloReceber;
    this.nrMatricula = jsonObject.nrMatricula;
    this.nome = jsonObject.nome;
    this.cpf = jsonObject.cpf;
    this.evento = jsonObject.evento;
    this.plano = jsonObject.plano;
    this.dependencias = jsonObject.dependencias;
    this.inclusao = jsonObject.inclusao;
    this.nascimento = jsonObject.nascimento;
    this.idade = jsonObject.idade;
    this.faixa = jsonObject.faixa;
    this.periodo = jsonObject.periodo;
    this.subtotal = jsonObject.subtotal;
    this.nrSeqPessoaAssConsolidador = jsonObject.nrSeqPessoaAssConsolidador;
    this.noMotivoAlteracao = jsonObject.noMotivoAlteracao;
    this.nrSeqUsuarioAlt = jsonObject.nrSeqUsuarioAlt;
    this.dtCadastro = jsonObject.dtCadastro;
    this.nrProposta = jsonObject.nrProposta;
    this.nrPrestacao = jsonObject.nrPrestacao;
    this.percentualComissao = jsonObject.percentualComissao;
    this.vlrComissao = jsonObject.vlrComissao;
    this.nrFaturaComissao = jsonObject.nrFaturaComissao;
    this.nrSeqConvenioExtratoComissao = jsonObject.nrSeqConvenioExtratoComissao;
    this.dtRegistro = jsonObject.dtRegistro;
    this.noObsImportacao = jsonObject.noObsImportacao;
    this.nrSeqTituloPagar = jsonObject.nrSeqTituloPagar;
    this.nrSeqContaRecurso = jsonObject.nrSeqContaRecurso;
    this.vlrPercentualFundo = jsonObject.vlrPercentualFundo;
    this.vlrDescontoConvenio = jsonObject.vlrDescontoConvenio;
    this.vlrDescontoEmpresa = jsonObject.vlrDescontoEmpresa;
    this.vlrPercentualDescontoConvenio =
      jsonObject.vlrPercentualDescontoConvenio;
    this.vlrPercentualDescontoEmpresa = jsonObject.vlrPercentualDescontoEmpresa;
    this.nrSeqPessoaVen = jsonObject.nrSeqPessoaVen;
    this.vlrPercentualComissao = jsonObject.vlrPercentualComissao;
    this.vlrLucroFundo = jsonObject.vlrLucroFundo;

    this.noPessoa = jsonObject.noPessoa;
    this.email = jsonObject.email;
    this.celular = jsonObject.celular;
    this.flgApenasComEmail = jsonObject.flgApenasComEmail;
    this.flgApenasNaoEnviados = jsonObject.flgApenasNaoEnviados;
    this.flgApenasContaMaiorZero = jsonObject.flgApenasContaMaiorZero;
    this.codigoMatricula = jsonObject.codigoMatricula;
    this.noConvenio = jsonObject.noConvenio;
    this.noEnvioPadrao = jsonObject.noEnvioPadrao;
    this.anoMesStr = jsonObject.anoMesStr;
    this.dataAtual = jsonObject.dataAtual;
    this.vlrRecalculadoTotal = jsonObject.vlrRecalculadoTotal;

    this.noPessoaIniciais = jsonObject.noPessoaIniciais;
  }

  toJson = () => JSON.stringify(this);
}
