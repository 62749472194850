import React, { useState, useRef, useEffect } from 'react';
import { ResponseStatus, Theme } from 'ui/Helpers/utils';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';

import { getCentroDeCustoAutoComplete } from 'core/services/TEL/centroDeCusto';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';

import {
  Autocomplete,
  Button,
  CSDSelPage,
  DatePicker,
  GridView,
  Textbox,
  ToolbarButtons,
  DropdownMulti,
} from 'ui/components';

import PanelSelecaoContratos from 'ui/pages/TEL/panelSelecaoContratos';
import {
  getFaturarContaTelefone,
  postFaturarContaTelefone,
} from 'core/services/TEL/faturarContaTelefone';
import { ContaRecurso } from 'core/models/TEL';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  postGerarPDFBoleto,
  postPrintBoletosFaturaTelefone,
} from 'core/services/FIN';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import ModalCancelaFaturamento from './modalCancelaFaturamento';
import ModalImprimirBoletos from './modalImprimirBoletos';

function RenderTextMask(props) {
  const { label } = props;
  return (
    <div className='col-auto mt-3'>
      <div className='fw-bold'>{label}</div>
    </div>
  );
}

export default function FaturarContaTelefone({ isActive, transaction }) {
  const dropDownCentroCusto = useRef();
  const [filters, setFilters] = useState(
    new ContaRecurso({ flgNaoFaturado: true })
  );
  const [data, setData] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [
    showModalCancelaFaturamento,
    setShowModalCancelaFaturamento,
  ] = useState(false);
  const [showModalImprimeBoleto, setShowModalImprimeBoleto] = useState(false);
  const gridView = useRef();

  const msglinhas = 'Total Linhas: ';
  const msgvalor = 'Total Reais: R$';
  const [totalLinhas, setTotalLinhas] = useState(msglinhas);
  const [totalReais, setTotalReais] = useState(msgvalor);

  const onCalculaTotais = (sourceRow) => {
    let totalL = 0;
    let totalV = 0;
    if (sourceRow[0].state !== undefined) {
      const selecteds = gridView.current.getCheckBoxValuesAt(0);
      if (!selecteds || selecteds.length <= 0) {
        setTotalLinhas(msglinhas + 0);
        setTotalReais(msgvalor + 0);
      } else {
        const linhas = selecteds.map((column) => ({
          nrSeqContaCabecalho: column.find(
            (prop) => prop.key === 'nrSeqContaCabecalho'
          ).value,
          totalRecalculado: column.find(
            (prop) => prop.key === 'totalRecalculado'
          ).value,
          totalLinhasNaoFaturadasFiltradas: column.find(
            (prop) => prop.key === 'totalLinhasNaoFaturadasFiltradas'
          ).value,
        }));
        for (let i = 0; i < linhas.length; ) {
          totalV += Number(linhas[i].totalRecalculado);
          totalL += Number(linhas[i].totalLinhasNaoFaturadasFiltradas);

          i += 1;
        }

        setTotalLinhas(msglinhas + totalL);
        setTotalReais(msgvalor + totalV.toFixed(2));
      }
    }
  };

  const columns = [
    {
      key: 'nrSeqContaCabecalho',
      type: GridView.ColumnTypes.Checkbox,
    },
    { key: 'cdContratoDTFatura', title: 'Contrato / Fatura' },
    { key: 'dtVencimentoExtensoMesAno', title: 'Mês Fatura' },
    { key: 'totalLinhas', title: 'Total Linhas' },
    { key: 'totalLinhasFaturadas', title: 'Faturadas' },
    { key: 'totalLinhasNaoFaturadas', title: 'Não Faturadas' },
    {
      key: 'totalLinhasNaoFaturadasFiltradas',
      title: 'Não Faturadas Filtradas',
    },
    {
      key: 'vlrTotal',
      title: 'Valor Total Conta',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'totalRecalculado',
      title: 'totalRecalculado',
      visible: false,
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const loadResult = (result) => {
    const { status, message: msg, ...obj } = result;

    if (msg) return onSetMessage(status, msg);

    return Object.values(obj)[0];
  };

  const onImprimirBoletosSelecionados = async (faturas) => {
    setLoading(true);
    const boleto = await postGerarPDFBoleto(faturas);
    if (boleto) {
      const linkSource = `data:application/pdf;base64, ${boleto}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'Boletos.pdf';
      downloadLink.click();
    }
    setLoading(false);
  };

  const onImprimirBoletos = async (param) => {
    setLoading(true);
    const boleto = await postPrintBoletosFaturaTelefone(param);
    if (boleto) {
      const linkSource = `data:application/pdf;base64, ${boleto}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'Boletos.pdf';
      downloadLink.click();
    }
    setLoading(false);
  };

  const handleLoadCentroCusto = async () => {
    const result = await getCentroDeCustoAutoComplete({
      nrSeqContratoAgrupado: filters.nrSeqContratoAgrupado,
      nrSeqContaCabecalho: filters.nrSeqContaCabecalho,
      MontarArvore: true,
    });
    return loadResult(result);
  };

  const onSearch = async () => {
    setLoading(true);
    const {
      status,
      message: msg,
      contasCabecalho,
      pagination,
    } = await getFaturarContaTelefone(filters);
    setTotalLinhas(msglinhas + 0);
    setTotalReais(msgvalor + 0);

    onSetMessage(status, msg);

    if (gridView && gridView.current)
      gridView.current.setDataSource(contasCabecalho, pagination);

    setLoading(false);
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });

    onSetMessage(status, msg);
    return clientes;
  };

  const onFaturarContaTelefone = async () => {
    onSetMessage();
    setLoading(true);

    const param = {
      ...data,
      nrSeqPessoaCli: filters.nrSeqPessoaCli,
      nrSeqFormaPagamento: filters.nrSeqFormaPagamento,
      nrSeqCentroDeCusto: filters.nrSeqCentroDeCusto,
      nrSeqCentrosDeCustos: filters.nrSeqCentrosDeCustos,
      nrSeqsContaCabecalho: gridView.current
        .getCheckBoxValuesAt(0)
        ?.map((row) => row[0].value),
    };

    const { status, message: msg } = await postFaturarContaTelefone(param);

    if (status === ResponseStatus.Error) {
      onSetMessage(status, msg);
      setLoading(false);
    } else {
      const { contasCabecalho, pagination } = await getFaturarContaTelefone(
        filters
      );
      setTotalLinhas(msglinhas + 0);
      setTotalReais(msgvalor + 0);
      if (gridView && gridView.current)
        gridView.current.setDataSource(contasCabecalho, pagination);
      onSetMessage(status, msg);
      setLoading(false);
    }
  };

  const onSearchTipo = async (e) => {
    const { data: tituloReceberEspecie } = await getTituloEspecieAutoComplete({
      noEspecie: e,
    });

    return tituloReceberEspecie;
  };

  useEffect(() => {}, []);
  useEffect(() => {
    if (filters.nrSeqContaCabecalho > 0 || filters.nrSeqContratoAgrupado > 0) {
      dropDownCentroCusto.current.loadDataSource(handleLoadCentroCusto);
    } else {
      dropDownCentroCusto.current.reset();
    }
  }, [filters.nrSeqContaCabecalho, filters.nrSeqContratoAgrupado]);

  const onAutoCompleteFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
    });

    onSetMessage(status, msg);

    return formasPagamento;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Faturar Conta Telefone'
      transaction={transaction}
      loading={loading}
      onSearch={onSearch}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Imprimir Boleto'
          icon='print'
          onClick={() => setShowModalImprimeBoleto(true)}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col'>
          <PanelSelecaoContratos
            justActives
            onContratoAgrupadoChange={(contratoAgrupado) =>
              setFilters({
                ...filters,
                nrSeqContratoAgrupado: contratoAgrupado.nrSeqContratoAgrupado,
              })
            }
            onFaturaChange={(fatura) =>
              setFilters({
                ...filters,
                nrSeqContaCabecalho: fatura.nrSeqContaCabecalho,
              })
            }
            onMessage={(msg, theme) => setMessage({ message: msg, theme })}
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Cliente'
            searchDataSource={onSearchCliente}
            selectedItem={selectedItems.cliente}
            onSelectItem={(cliente) => {
              setSelectedItems({ ...selectedItems, cliente });
              setFilters({
                ...filters,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Forma de Recebimento'
            searchDataSource={onAutoCompleteFormaPagamento}
            selectedItem={selectedItems.formaPagamento}
            onSelectItem={(formaPagamento) => {
              setSelectedItems({ ...selectedItems, formaPagamento });
              setFilters({
                ...filters,
                nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
              });
            }}
            dataSourceTextProperty='noFormaPagamento'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Empresa'
            searchDataSource={onSearchEmpresa}
            selectedItem={filters.empresa}
            onSelectItem={(empresa) => {
              setFilters({
                ...filters,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-6'>
          <DropdownMulti
            label='Centro de Custo'
            ref={dropDownCentroCusto}
            dataSourcePropertyText='noCentroDeCusto'
            dataSourcePropertyValue='nrSeqCentroDeCusto'
            selectedItems={selectedItems.centrosCusto ?? []}
            onSelectItem={(centrosCusto) => {
              setSelectedItems({ ...selectedItems, centrosCusto });
              setFilters({
                ...filters,
                nrSeqCentrosDeCustos: centrosCusto?.map(
                  (p) => p.nrSeqCentroDeCusto
                ),
              });
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            gridSizeList={false}
            onPageSizeChange={false}
            showSelectSizes={false}
            showPagination={false}
            onRowRender={onCalculaTotais}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='bg-primary text-white bg-gradient rounded-2 p-3'>
          <div className='row'>
            <div className='col'>
              <div className='display-8'>
                Conforme os filtros aplicados, serão faturadas
              </div>
              <hr className='mt-1 mb-0' />
              <div className='row'>
                <RenderTextMask label={totalLinhas} />
                <RenderTextMask label={totalReais} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-2'>
          <Textbox
            label='Competência'
            text={data.noCompetencia}
            mask={MaskTypes.Competence}
            onChangedValue={(noCompetencia) =>
              setData({
                ...data,
                noCompetencia,
              })
            }
          />
        </div>
        <div className='col'>
          <Textbox
            label='Dia Limite'
            text={data.nrDiaLimite}
            mask={MaskTypes.Integer}
            onChangedValue={(nrDiaLimite) =>
              setData({
                ...data,
                nrDiaLimite,
              })
            }
          />
        </div>
        <div className='col'>
          <DatePicker
            label='Vencimento'
            text={data.dtVencimento}
            mask={MaskTypes.Date}
            onChange={(dtVencimento) => setData({ ...data, dtVencimento })}
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Tipo Receita'
            searchDataSource={onSearchTipo}
            selectedItem={data.tituloEspecie}
            onSelectItem={async (tituloEspecie) => {
              setData({
                ...data,
                tituloEspecie,
                nrSeqTituloEspecie: tituloEspecie.nrSeqTituloEspecie,
              });
            }}
            dataSourceTextProperty='noEspecie'
            nrseqTransactionToOpen='nrSeqTituloEspecie'
          />
        </div>
        <div className='col-3 mt-4'>
          <Button
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Faturar'
            className='width100'
            onClick={onFaturarContaTelefone}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalCancelaFaturamento
            show={showModalCancelaFaturamento}
            onClose={() => setShowModalCancelaFaturamento(false)}
          />
        </div>
        <div className='col'>
          <ModalImprimirBoletos
            show={showModalImprimeBoleto}
            onClose={() => setShowModalImprimeBoleto(false)}
            onImprimirBoletosSelecionados={(faturas) => {
              setShowModalImprimeBoleto(false);
              onImprimirBoletosSelecionados(faturas);
            }}
            onImprimirBoletos={(faturas) => {
              setShowModalImprimeBoleto(false);
              onImprimirBoletos(faturas);
            }}
            dropDownCentroCusto={dropDownCentroCusto}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
