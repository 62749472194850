import { getTipoCartaoAutoComplete } from 'core/services/FRO/tipoCartao';
import React, { forwardRef, useState, useRef, useEffect } from 'react';
import {
  Autocomplete,
  Button,
  DatePicker,
  GridView,
  Textbox,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';

const CartaoStep = forwardRef(({ data, setData, onSetMessage }) => {
  const gridView = useRef();
  const [cartaoData, setCartaoData] = useState({});

  useEffect(() => {
    if (gridView && gridView.current && data.tiposCartaoVinculo) {
      gridView.current.setDataSource(data.tiposCartaoVinculo);
    }
  }, []);

  const onSearchTipoCartao = async (e) => {
    const res = await getTipoCartaoAutoComplete({
      noTipoCartao: e,
      flgVeiculo: true,
    });

    return res.data;
  };

  const onClickAddCartao = async () => {
    if (
      cartaoData.tipoCartao &&
      cartaoData.numeroCartao &&
      cartaoData.tipoCartao?.flgSolicitaSenha === !!cartaoData.noSenha &&
      cartaoData.tipoCartao?.flgSolicitaSaldo === !!cartaoData.vlrSaldo
    ) {
      const obj = {
        ...cartaoData,
        status: cartaoData.status ?? GridView.EnumStatus.Inserir,
      };

      const list = gridView.current.getDataSource();
      list.push(obj);

      if (gridView && gridView.current) gridView.current.setDataSource(list);

      setData({ ...data, tiposCartaoVinculo: list });
      setCartaoData({});
    } else if (!cartaoData.tipoCartao) {
      onSetMessage(false, 'Informe o Tipo de Cartão');
    } else if (!cartaoData.numeroCartao) {
      onSetMessage(false, 'Informe o Número do Cartão');
    } else if (cartaoData.tipoCartao?.flgSolicitaSenha && !cartaoData.noSenha) {
      onSetMessage(false, 'Informe a Senha');
    } else if (
      cartaoData.tipoCartao?.flgSolicitaSaldo &&
      !cartaoData.vlrSaldo
    ) {
      onSetMessage(false, 'Informe o Saldo');
    }
  };

  const onClickRemoveCartao = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const list = datasource.filter((el) => el !== e);

    if (gridItem.status !== GridView.EnumStatus.Inserir) {
      gridItem.status = GridView.EnumStatus.Remover;

      list.push(gridItem);
    }

    if (gridView && gridView.current) gridView.current.setDataSource(list);

    setData({ ...data, tiposCartaoVinculo: list });
  };

  const columns = [
    { key: 'tipoCartao.noTipoCartao', title: 'Tipo Cartão', sortable: false },
    { key: 'numeroCartao', title: 'Número do Cartão', sortable: false },
    {
      key: 'dtInicio',
      title: 'Inicio da Vigência',
      sortable: false,
      format: GridView.DataTypes.Date,
    },
    {
      key: 'dtFim',
      title: 'Fim da Vigência',
      sortable: false,
      format: GridView.DataTypes.Date,
    },
    {
      key: 'vlrSaldo',
      title: 'Saldo',
      sortable: false,
      format: GridView.DataTypes.Money,
    },
    {
      key: 'nrSeqCartao',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemoveCartao(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <div>
      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Tipo de Cartão'
            searchDataSource={onSearchTipoCartao}
            selectedItem={cartaoData.tipoCartao}
            onSelectItem={(tipoCartao) =>
              setCartaoData({
                ...cartaoData,
                tipoCartao,
                nrSeqTipoCartao: tipoCartao.nrSeqTipoCartao,
              })
            }
            dataSourceTextProperty='noTipoCartao'
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Número do Cartão'
            maxLength={20}
            text={cartaoData.numeroCartao}
            onChangedValue={(numeroCartao) =>
              setCartaoData({ ...cartaoData, numeroCartao })
            }
          />
        </div>
        {cartaoData.tipoCartao?.flgSolicitaSenha && (
          <div className='col-2'>
            <Textbox
              label='Senha'
              maxLength={20}
              text={cartaoData.noSenha}
              onChangedValue={(noSenha) =>
                setCartaoData({ ...cartaoData, noSenha })
              }
            />
          </div>
        )}
        {cartaoData.tipoCartao?.flgSolicitaSaldo && (
          <div className='col-2'>
            <Textbox
              label='Saldo'
              maxLength={20}
              text={cartaoData.vlrSaldo}
              mask={MaskTypes.Currency}
              onChangedValue={(vlrSaldo) =>
                setCartaoData({ ...cartaoData, vlrSaldo })
              }
            />
          </div>
        )}
      </div>
      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Inicio da Vigência'
            text={cartaoData.dtInicio}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtInicio) => setCartaoData({ ...cartaoData, dtInicio })}
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Fim da Vigência'
            text={cartaoData.dtFim}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtFim) => setCartaoData({ ...cartaoData, dtFim })}
          />
        </div>
        <div className='col d-flex align-items-end'>
          <Button
            onClick={onClickAddCartao}
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Adicionar'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            offlineData
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
    </div>
  );
});

export default CartaoStep;
