import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes } from 'ui/Helpers/utils';
import { CSDManutPage, Textbox } from 'ui/components';
import {
  getAreaChamado,
  saveAreaChamado,
  deleteAreaChamado,
} from 'core/services/HELP/areaChamado';
import { Message, Page } from 'ui/Helpers/interfaces';
import AreaChamado from 'core/models/HELP/areaChamado';
import { ResponseStatus, Theme } from 'ui/Helpers/enums';

export default function AreaChamadoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit: any = useRef();
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      setData(await getAreaChamado(registryKey));

      setLoading(false);
    } else {
      setData(new AreaChamado());
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    load();
  }, [load, registryKey, reload]);

  const save = async (): Promise<void> => {
    setLoading(true);

    const { status, message: msg } = await saveAreaChamado(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? new AreaChamado() : data);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onExcluir = async (): Promise<void> => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const { status, message: msg } = await deleteAreaChamado(registryKey);

      setData(status === ResponseStatus.Success ? new AreaChamado() : data);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;

  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Área de Chamado'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData(new AreaChamado())}
      onSave={save}
      onDelete={data?.nrSeqAreaChamado > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-3'>
          {/* @ts-expect-error */}
          <Textbox
            maxLength={20}
            readOnly
            label='Código'
            text={data.cdAreaChamado}
            onChangedValue={(cdAreaChamado: string) =>
              setData({ ...data, cdAreaChamado })
            }
          />
        </div>
        <div className='col'>
          {/* @ts-expect-error */}
          <Textbox
            maxLength={100}
            required
            label='Área de chamado'
            text={data.noAreaChamado}
            onChangedValue={(noAreaChamado: string) =>
              setData({ ...data, noAreaChamado })
            }
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
