import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import { CSDManutPage, Textbox, Switch } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getEstoqueTipo,
  saveEstoqueTipo,
  deleteEstoqueTipo,
} from 'core/services/EST/estoqueTipo';

export default function EstoqueTipoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getEstoqueTipo(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);

    const obj = { ...data };
    if (data.flgTransferenciaEntreEstoques) {
      obj.noSinal = 'T';
    }
    const { status, message: msg } = await saveEstoqueTipo(obj);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : obj);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteEstoqueTipo(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção - Tipo de Movimento de Estoque'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={onExcluir}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-4 mb-3'>
          <Textbox
            maxLength={80}
            label='Descrição Tipo Movimento de Estoque'
            text={data.noEstoqueTipo}
            onChangedValue={(noEstoqueTipo) =>
              setData({ ...data, noEstoqueTipo })
            }
          />
        </div>

        <div className='col-3 mb-3'>
          <Textbox
            maxLength={40}
            label='Cód. Transação'
            text={data.cdTransacao}
            onChangedValue={(cdTransacao) => setData({ ...data, cdTransacao })}
          />
        </div>
        <div className='col-3 mb-3'>
          <Textbox
            maxLength={200}
            label='Parametros Página'
            text={data.noPaginaWeb}
            onChangedValue={(noPaginaWeb) => setData({ ...data, noPaginaWeb })}
          />
        </div>
        <div className='col-2 mb-3'>
          <Textbox
            maxLength={2}
            readOnly={data?.nrSeqEstoqueTipo > 0}
            mask={MaskTypes.Integer}
            label='Cód. Estoque Tipo'
            text={data.cdEstoqueTipo}
            onChangedValue={(cdEstoqueTipo) =>
              setData({ ...data, cdEstoqueTipo })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-2 mb-3'>
          <Textbox
            maxLength={300}
            visible={!data?.flgTransferenciaEntreEstoques}
            label='Sinal'
            text={data.noSinal}
            onChangedValue={(noSinal) => setData({ ...data, noSinal })}
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Conta Movimento'
            size={BootstrapSizes.Medium}
            checked={data.flgContaMovimento}
            onChange={(flgContaMovimento) =>
              setData({ ...data, flgContaMovimento })
            }
          />
        </div>

        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Lançamento Manual'
            size={BootstrapSizes.Medium}
            checked={data.flgLancamentoManual}
            onChange={(flgLancamentoManual) =>
              setData({ ...data, flgLancamentoManual })
            }
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Serviço'
            size={BootstrapSizes.Medium}
            checked={data.flgServico}
            onChange={(flgServico) => setData({ ...data, flgServico })}
          />
        </div>

        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Transferência Entre Estoques'
            size={BootstrapSizes.Medium}
            checked={data.flgTransferenciaEntreEstoques}
            onChange={(flgTransferenciaEntreEstoques) =>
              setData({ ...data, flgTransferenciaEntreEstoques })
            }
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
