export default class VeiculoOcorrenciaDocumento {
  constructor(jsonObject = {}) {
    this.nrSeqVeiculoOcorrenciaDocumento =
      jsonObject.nrSeqVeiculoOcorrenciaDocumento;
    this.nrSeqVeiculoOcorrencia = jsonObject.nrSeqVeiculoOcorrencia;
    this.noImagem = jsonObject.noImagem;
    this.noTipoArquivo = jsonObject.noTipoArquivo;
    this.noTamanho = jsonObject.noTamanho;
    this.dtCadastro = jsonObject.dtCadastro;
    this.noObservacao = jsonObject.noObservacao;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.noArquivo = jsonObject.noArquivo;
    this.flgExibeDownload = jsonObject.flgExibeDownload;
  }

  toJson = () => JSON.stringify(this);
}
