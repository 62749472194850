import React from 'react';
import { QrReader } from 'react-qr-reader';

const QRCodeScanner = ({ onQRCodeScanned }) => {
  const handleResult = (result, error) => {
    if (result) {
      onQRCodeScanned(result?.text);
    }

    if (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <QrReader
        onResult={handleResult}
        style={{ width: '100%', height: 'auto' }}
      />
    </div>
  );
};

export default QRCodeScanner;
