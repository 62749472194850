import React, { useRef, useState, useEffect } from 'react';

import { Button, GridView, Modal, Notification } from 'ui/components';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';
import { mask, MaskTypes } from 'ui/Helpers/masks';
import {
  SearchColetaEmbarqueRecebimento,
  updateColetaEmbarqueFatura,
} from 'core/services/FRO/coletaEmbarque';

export default function ModalVisualizarCTE(props) {
  const [loading, setLoading] = useState(false);
  const [messageState, setMessage] = useState(null);
  const gridView = useRef();
  const [totalSelecionadoSaldo, setTotalSelecionadoSaldo] = useState(0);
  const [totalCTEs, setTotalCTEs] = useState(0);
  const [totalCTEsPagos, setTotalCTEsPagos] = useState(0);

  const { show, coletaEmbarqueS, nrSeqTituloReceber } = props;

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  function RenderTextMask(obj) {
    const { label, value, masktype } = obj;
    return (
      <div className='col-auto mt-3'>
        <div className='fw-bold'>
          {label}: {mask(value ?? 0, masktype ?? MaskTypes.Currency)}
        </div>
      </div>
    );
  }

  const atualizarSaldo = async () => {
    const searchTituloReceber = nrSeqTituloReceber;

    const titulosreceber = await SearchColetaEmbarqueRecebimento(
      searchTituloReceber
    );
    if (titulosreceber.data !== null && titulosreceber.data !== undefined) {
      let vlrPago = 0;
      let vlrTotalCte = 0;

      titulosreceber.data.forEach((item) => {
        if (item.flgLiquidado === true) {
          item.status = 'Pago';
          vlrPago += item.vlrFrete;
          vlrTotalCte += item.vlrFrete;
        } else {
          item.status = 'Em Aberto';
          vlrTotalCte += item.vlrFrete;
        }
      });

      setTotalCTEs(vlrTotalCte);
      setTotalCTEsPagos(vlrPago);
    }

    gridView?.current?.setDataSource(titulosreceber.data);
  };

  useEffect(() => {
    if (coletaEmbarqueS !== null && coletaEmbarqueS !== undefined) {
      let vlrPago = 0;
      let vlrTotalCte = 0;

      coletaEmbarqueS.forEach((item) => {
        if (item.flgLiquidado === true) {
          item.status = 'Pago';
          vlrPago += item.vlrFrete;
          vlrTotalCte += item.vlrFrete;
        } else {
          item.status = 'Em Aberto';
          vlrTotalCte += item.vlrFrete;
        }
      });

      setTotalCTEs(vlrTotalCte);
      setTotalCTEsPagos(vlrPago);
    }
    gridView?.current?.setDataSource(coletaEmbarqueS);
  }, [show]);

  const onCalculaTotais = () => {
    const nrSeqTituloReceberS = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    const Listatitulos = gridView.current.getDataSource();

    const total = nrSeqTituloReceberS.reduce((acc, numero) => {
      const titulo = Listatitulos.find((t) => t.nrSeqColetaEmbarque === numero);
      return titulo ? acc + titulo.vlrFrete : acc;
    }, 0);

    setTotalSelecionadoSaldo(total);
  };

  const salvar = async () => {
    let lista = [];

    const coletaS = gridView.current ? gridView.current.getDataSource() : [];

    const nrSeqColetaEmbarque = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    lista = coletaS.filter((obj) =>
      nrSeqColetaEmbarque.includes(obj.nrSeqColetaEmbarque)
    );

    // const obj = { coletaEmbarqueS: lista };

    const { status, message: msg } = await updateColetaEmbarqueFatura(lista);

    const searchTituloReceber = nrSeqTituloReceber;

    const titulosreceber = await SearchColetaEmbarqueRecebimento(
      searchTituloReceber
    );

    if (titulosreceber.data !== null && titulosreceber.data !== undefined) {
      let vlrPago = 0;
      let vlrTotalCte = 0;

      titulosreceber.data.forEach((item) => {
        if (item.flgLiquidado === true) {
          item.status = 'Pago';
          vlrPago += item.vlrFrete;
          vlrTotalCte += item.vlrFrete;
        } else {
          item.status = 'Em Aberto';
          vlrTotalCte += item.vlrFrete;
        }
      });

      setTotalCTEs(vlrTotalCte);
      setTotalCTEsPagos(vlrPago);
    }

    gridView?.current?.setDataSource(titulosreceber.data);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const cancelar = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
    });
  };

  const columns = [
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Checkbox,
    },
    {
      key: 'cdColetaEmbarque',
      title: 'CTEs',
    },

    {
      key: 'vlrFrete',
      title: 'Valor Do Titulo',
      format: GridView.DataTypes.Decimal,
    },

    {
      key: 'status',
      title: 'Status',
    },
  ];

  return (
    <Modal
      loading={loading}
      show={show}
      title='Para seu controle, marque os CTEs já liquidados desta fatura.'
      size={BootstrapSizes.Large}
    >
      <Modal.Body className='p-4 pt-3'>
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => onSetMessage(null)}
            />
          </div>
        )}
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
            onRowRender={onCalculaTotais}
          />
        </div>

        <div className='row mb-3'>
          <div className='col-4 mb-3 float-right'>
            <RenderTextMask label='Total dos CTEs' value={totalCTEs} />
          </div>
          <div className='col-4 mb-3 float-right'>
            <RenderTextMask
              label='Total dos CTEs Selecionados'
              value={totalSelecionadoSaldo}
            />
          </div>
          <div className='col-4 mb-3 float-right'>
            <RenderTextMask
              label='Total dos CTEs Pagos'
              value={totalCTEsPagos}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-5 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Atualizar Saldo Ctes Selecionados'
              onClick={atualizarSaldo}
            />
          </div>
          <div className='col-3 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Salvar'
              onClick={salvar}
            />
          </div>
          <div className='col-2 mt-3'>
            <Button
              theme={Theme.Danger}
              template={Button.Templates.Button}
              text='Cancelar'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
