import qs from 'qs';
import Pagination from 'core/models/pagination';
import TipoDespesaPlanoDeConta from 'core/models/FIN/tipoDespesa_PlanoDeConta';
import api from '../api';

const baseURL = `/FIN/TipoDespesa_PlanoDeConta`;

export const getTipoDespesaPlanoDeContaList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TipoDespesaPlanoDeConta(json)) ?? [],
  };
};

export const getTipoDespesaPlanoDeContaTituloPagar = async (filters) => {
  const response = await api.get(
    `${baseURL}/getTipoDespesaPlanoDeContaTituloPagar`,
    {
      params: filters,
    }
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TipoDespesaPlanoDeConta(json)) ?? [],
  };
};

export const getRetornoGridPlanoConta = async (data) => {
  const response = await api.post(`${baseURL}/getRetornoGridPlanoConta`, data);
  return response.data.value ?? {};
};

export const getTipoDespesaPlanoDeContaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TipoDespesaPlanoDeConta(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getTipoDespesaPlanoDeConta = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new TipoDespesaPlanoDeConta(value) : {};
};

export const saveTipoDespesaPlanoDeConta = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteTipoDespesaPlanoDeConta = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printTipoDespesaPlanoDeConta = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelTipoDespesaPlanoDeConta = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllTipoDespesaPlanoDeConta = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
