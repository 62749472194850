import React from 'react';
import { Modal, Button } from 'ui/components';
import { BootstrapSizes, Theme } from 'ui/Helpers/enums';

import { alteraManifestoParaOffline } from 'core/services/FRO/manifestoMdfe';

import ManifestoCTE from 'core/models/FRO/manifestoCTE';

interface Props {
  show: boolean;
  onClose: (status?: number, message?: string) => void;
  data: ManifestoCTE;
}

export default function ModalEffectOffline({ show, onClose, data }: Props) {
  const onSend = async () => {
    onClose();
  };

  const onConfirm = async () => {
    const { status, message } = await alteraManifestoParaOffline({
      nrSeqManifestoCte: data.nrSeqManifestoCte,
    });

    onClose(status, message);
  };

  return (
    // @ts-expect-error
    <Modal
      show={show}
      title='Efetivar Manifesto Offline'
      onClose={onSend}
      size={BootstrapSizes.Medium}
    >
      {/* @ts-expect-error */}
      <Modal.Body>
        <div className='row'>
          <div className='col'>
            Deseja alterar a efetivação desse manifesto para Offline?
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Small}
          icon={['far', 'save']}
          theme={Theme.Success}
          template={Button.Templates.Default}
          onClick={onConfirm}
          text='Confirmar'
        />
      </Modal.Footer>
    </Modal>
  );
}
