export default class VendedorEquipe {
  constructor(jsonObject = {}) {
    this.nrSeqVendedorEquipe = jsonObject.nrSeqVendedorEquipe;
    this.fone = jsonObject.fone;
    this.email = jsonObject.email;
    this.detalhe = jsonObject.detalhe;
    this.nome = jsonObject.nome;
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.nrSeqPessoaVen = jsonObject.nrSeqPessoaVen;
  }

  toJson = () => JSON.stringify(this);
}
