import Transacao from './transacao';

export default class Menu {
  constructor(jsonObject = {}) {
    this.uniqueId = Date.now() + Math.random();
    this.nrSeqMenu = jsonObject.nrSeqMenu;
    this.noMenu = jsonObject.noMenu;
    this.nrNivel = jsonObject.nrNivel;
    this.nrOrdem = jsonObject.nrOrdem;
    this.nrSeqMenuPai = jsonObject.nrSeqMenuPai;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.cssImagem = jsonObject.cssImagem;
    this.isHidden = jsonObject.isHidden;
    this.menus = (jsonObject.menus ?? []).map((menu) => new Menu(menu));
    this.transacoes = (jsonObject.transacoes ?? []).map(
      (transacao) => new Transacao(transacao)
    );
  }

  toJson = () => JSON.stringify(this);
}
