export default class PedidoCompraDocumento {
  constructor(jsonObject = {}) {
    this.nrSeqPedidoCompraDocumento = jsonObject.nrSeqPedidoCompraDocumento;
    this.nrSeqPedidoCompra = jsonObject.nrSeqPedidoCompra;
    this.noImagem = jsonObject.noImagem;
    this.noTipoArquivo = jsonObject.noTipoArquivo;
    this.noTamanho = jsonObject.noTamanho;
    this.noArquivo = jsonObject.noArquivo;
    this.noObservacao = jsonObject.noObservacao;
    this.noImageBase64 = jsonObject.noImageBase64;
  }

  toJson = () => JSON.stringify(this);
}
