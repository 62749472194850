export default class TipoCombustivel {
  constructor(jsonObject = {}) {
    this.nrSeqTipoCombustivel = jsonObject.nrSeqTipoCombustivel;
    this.noTipoCombustivel = jsonObject.noTipoCombustivel;
    this.cdTipoCombustivel = jsonObject.cdTipoCombustivel;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqProduto = jsonObject.nrSeqProduto;
    this.flgCompoeMediaGeral = jsonObject.flgCompoeMediaGeral;
    this.flgPrincipal = jsonObject.flgPrincipal;
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
