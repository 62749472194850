import qs from 'qs';
import Pagination from 'core/models/pagination';
import Profissao from 'core/models/SEG/profissao';
import api from '../api';

export const getProfissaoAutoComplete = async (filters) => {
  const response = await api.get(`/Profissao/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    profissoes: value?.map((json) => new Profissao(json)) ?? [],
  };
};

export const getProfissaoList = async (filters) => {
  const response = await api.get(`/Profissao/Search`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    profissoes: value?.map((json) => new Profissao(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getProfissao = async (id) => {
  const response = await api.get(`/Profissao/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Profissao(value) : {};
};

export const saveProfissao = async (profissao) => {
  const response = await api.post(`/Profissao/`, profissao);
  return response.data ?? {};
};

export const deleteProfissao = async (id) => {
  const response = await api.delete(`/Profissao/`, { params: { id } });
  return response.data ?? {};
};

export const printProfissoes = async (filters) => {
  const response = await api.get(`/Profissao/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelProfissoes = async (filters) => {
  const response = await api.get(`/Profissao/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteProfissoes = async (ids) => {
  const response = await api.delete(`/Profissao/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
