export default class PocTipoPrefiltro {
  constructor(jsonObject = {}) {
    this.nrSeqPocTipoPrefiltro = jsonObject.nrSeqPocTipoPrefiltro;
    this.cdPocTipoPrefiltro = jsonObject.cdPocTipoPrefiltro;
    this.noPocTipoPrefiltro = jsonObject.noPocTipoPrefiltro;
    this.flgAtivo = jsonObject.flgAtivo;
  }

  toJson = () => JSON.stringify(this);
}
