import qs from 'qs';
import Pagination from 'core/models/pagination';
import TipoReceitaAcerto from 'core/models/FIN/tipoReceitaAcerto';
import api from '../api';

const baseURL = `/FIN/TipoReceitaAcerto`;

export const getTipoReceitaAcertoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TipoReceitaAcerto(json)) ?? [],
  };
};

export const getTipoReceitaAcertoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TipoReceitaAcerto(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getTipoReceitaAcerto = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new TipoReceitaAcerto(value) : {};
};

export const saveTipoReceitaAcerto = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};
export const geraSequenciaTipoReceita = async (data) => {
  const response = await api.post(`${baseURL}/GeraSequenciaTipoReceita`, data);
  return response.data.value ?? {};
};

export const deleteTipoReceitaAcerto = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printTipoReceitaAcerto = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelTipoReceitaAcerto = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllTipoReceitaAcerto = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
