import React, { useEffect, useRef, useState } from 'react';
import { ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  CSDSelPage,
  GridView,
  Textbox,
  DatePicker,
  Autocomplete,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getLogEnvioSmsPagined,
  printLogEnvioSms,
} from 'core/services/SEG/logEnvioSms';
import { getEnvioPadraoAutoComplete } from 'core/services/TEL';

const columns = [
  {
    key: 'nrSeqLogEnvioSms',
    type: GridView.ColumnTypes.Checkbox,
    visible: false,
  },
  { key: 'noOrigem', title: 'Origem' },
  { key: 'noDestino', title: 'Destino' },
  { key: 'noMensagem', title: 'Mensagem' },
  { key: 'noStatus', title: 'Status' },
  { key: 'noStatusRetorno', title: 'Status Retorno' },
  { key: 'dtCadStr', title: 'Data Envio' },
  { key: 'noMeioEnvioMensagem', title: 'Meio Envio' },
];

export default function LogEnvioSms({ transaction, isActive, onOpenReport }) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getLogEnvioSmsPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getLogEnvioSmsPagined({});

      setFiltros({
        ...filtros,
        dtCadInicio: data[0].dtCadInicio,
        dtCadFim: data[0].dtCadFim,
      });
      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printLogEnvioSms(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchEnvioPadrao = async (e) => {
    const { status, message: msg, envios } = await getEnvioPadraoAutoComplete({
      noEnvioPadrao: e,
    });
    onSetMessage(status, msg);
    return envios;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção Log Envio Mensagem'
      loading={loading}
      onSearch={() => search()}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-2'>
          <Textbox
            label='Nr. Origem'
            onChangedValue={(nrTelefoneOrigem) =>
              setFiltros({
                ...filtros,
                nrTelefoneOrigem,
              })
            }
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Nr. Destino'
            onChangedValue={(nrTelefone) =>
              setFiltros({
                ...filtros,
                nrTelefone,
              })
            }
          />
        </div>
        <div className='col'>
          <Textbox
            label='Email Origem'
            onChangedValue={(noEmailOrigem) =>
              setFiltros({
                ...filtros,
                noEmailOrigem,
              })
            }
          />
        </div>
        <div className='col'>
          <Textbox
            label='Email Destino'
            onChangedValue={(noEmailDestino) =>
              setFiltros({
                ...filtros,
                noEmailDestino,
              })
            }
          />
        </div>
        <div className='col-2'>
          <Autocomplete
            label='Envio Padrão'
            searchDataSource={onSearchEnvioPadrao}
            selectedItem={filtros.envioPadrao}
            onSelectItem={(envioPadrao) =>
              setFiltros({
                ...filtros,
                envioPadrao,
                noMeioEnvioMensagem: envioPadrao.noEnvioPadrao,
              })
            }
            dataSourceTextProperty='noEnvioPadrao'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Textbox
            label='Status'
            onChangedValue={(noStatus) =>
              setFiltros({
                ...filtros,
                noStatus,
              })
            }
          />
        </div>
        <div className='col'>
          <Textbox
            label='Status Retorno'
            onChangedValue={(noStatusRetorno) =>
              setFiltros({
                ...filtros,
                noStatusRetorno,
              })
            }
          />
        </div>
        <div className='col'>
          <DatePicker
            label='Data Início'
            text={filtros.dtCadInicio}
            mask={MaskTypes.Date}
            onChange={(dtCadInicio) => setFiltros({ ...filtros, dtCadInicio })}
          />
        </div>
        <div className='col'>
          <DatePicker
            label='Data Fim'
            text={filtros.dtCadFim}
            mask={MaskTypes.Date}
            onChange={(dtCadFim) => setFiltros({ ...filtros, dtCadFim })}
          />
        </div>
        <div className='col-4'>
          <Textbox
            label='Mensagem'
            onChangedValue={(noMensagem) =>
              setFiltros({
                ...filtros,
                noMensagem,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            enableExcelExport
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={false}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            canControlVisibility
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
