import TransacaoPagina from 'core/models/SEG/transacaoPagina';
import Permissao from 'core/models/SEG/permissao';
import TransacaoTipo from 'core/models/SEG/transacaoTipo';
import TransacaoPaginaConfiguracao from 'core/models/SEG/transacaoPaginaConfiguracao';

export default class Transacao {
  constructor(jsonObject = {}) {
    this.nrSeqTransacao = jsonObject.nrSeqTransacao;
    this.cdTransacao = jsonObject.cdTransacao;
    this.noTransacao = jsonObject.noTransacao;
    this.nrSeqMenu = jsonObject.nrSeqMenu;
    this.menu = jsonObject.menu ?? {};
    this.nrOrdem = jsonObject.nrOrdem;
    this.noDirFonte = jsonObject.noDirFonte;
    this.flgMobile = jsonObject.flgMobile;
    this.noTransacaoCompleto = jsonObject.noTransacaoCompleto;
    this.nrSeqTransacaoTipo = jsonObject.nrSeqTransacaoTipo;
    this.transacaoTipo = new TransacaoTipo(jsonObject.transacaoTipo ?? {});
    this.permissao = new Permissao(jsonObject.permissao ?? {});
    this.pages = (jsonObject.paginas ?? []).map(
      (transacaoPagina) => new TransacaoPagina(transacaoPagina)
    );
    this.pagesConfig = (jsonObject.paginasConfiguracoes ?? []).map(
      (config) => new TransacaoPaginaConfiguracao(config)
    );

    this.noPaginas = jsonObject.noPaginas;
  }

  toJson = () => JSON.stringify(this);
}
