import qs from 'qs';
import Pagination from 'core/models/pagination';
import Extrato from 'core/models/FIN/extrato';
import api from '../api';

const baseURL = `/FIN/Extrato`;

export const getExtratoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Extrato(json)) ?? [],
  };
};

export const getExtratoPaginated = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPaginated`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Extrato(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getExtrato = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Extrato(value) : {};
};

export const saveExtrato = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteExtrato = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printExtrato = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const excelExtrato = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const printExtratoContabil = async (filters) => {
  const response = await api.get(`${baseURL}/PrintContabil`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const excelExtratoContabil = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelContabil`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const printExtratoContabil2 = async (filters) => {
  const response = await api.get(`${baseURL}/PrintContabil2`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const excelExtratoContabil2 = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelContabil2`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const printExtratoAgrupado = async (filters) => {
  const response = await api.get(`${baseURL}/PrintAgrupado`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const printExtratoContas = async (filters) => {
  const response = await api.get(`${baseURL}/PrintExtratoContas`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const excelExtratoContas = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelExtratoContas`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const printExtratoConferenciaSaldo = async (filters) => {
  const response = await api.get(`${baseURL}/PrintConferenciaSaldo`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const excelExtratoConferenciaSaldo = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelConferenciaSaldo`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const deleteAllExtrato = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
