import React, { useState, useEffect } from 'react';

import {
  Autocomplete,
  Button,
  Modal,
  Notification,
  Textbox,
} from 'ui/components';
import { Theme, BootstrapSizes, ResponseStatus } from 'ui/Helpers/utils';
import { getOficinaAutocomplete } from 'core/services/FRO/oficina';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN';
import { getProdutoAgrupadorAutoCompleteDetalhado } from 'core/services/EST/produtoAgrupador';
import { getProdutoAutoCompleteByCodigos } from 'core/services/EST/produto';
import { getAlmoxarifadoAutoComplete } from 'core/services/EST/almoxarifado';
import {
  getAlmoxarifadoLocalAutoComplete,
  getBalance,
  getCusto,
} from 'core/services/EST/almoxarifadoLocal';
import { createFromAcertoMotorista } from 'core/services/FRO/ordemServico_Manutencao';
import { MaskTypes } from 'ui/Helpers/masks';

export default function ModalGeraManutencaoVeiculo(props) {
  const [modalManutencao, setModalManutencao] = useState();
  const [messageState, setMessage] = useState(null);

  const { show, geraManutencaoValor, vlrAcerto } = props;

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  useEffect(() => {
    if (geraManutencaoValor !== undefined && geraManutencaoValor !== null) {
      if (vlrAcerto !== undefined && vlrAcerto !== null) {
        setModalManutencao({
          nrSeqVeiculo: geraManutencaoValor.nrSeqVeiculoPrinc,
          veiculo: geraManutencaoValor.veiculo,
          dtAbertura: vlrAcerto.dtSaida,
          nrOdometro: vlrAcerto.nrOdomentroFinal,
        });
      } else {
        setModalManutencao({
          nrSeqVeiculo: geraManutencaoValor.nrSeqVeiculoPrinc,
          veiculo: geraManutencaoValor.veiculo,
          nrOdometro: 1,
        });
      }
    } else if (vlrAcerto !== undefined && vlrAcerto !== null) {
      setModalManutencao({
        nrSeqVeiculo: geraManutencaoValor.nrSeqVeiculoPrinc,
        veiculo: geraManutencaoValor.veiculoPrinc,
        dtAbertura: vlrAcerto.dtSaida,
        nrOdometro: vlrAcerto.nrOdomentroFinal,
      });
    }
  }, [show]);

  const cancelar = async () => {
    props.onClose({
      message: '',
      theme: ResponseStatus.Success,
    });
  };

  const salvar = async () => {
    if (
      modalManutencao.nrSeqVeiculo === null ||
      modalManutencao.nrSeqVeiculo === undefined
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar uma manutenção sem adicionar um veiculo ao acerto'
      );
      return;
    }
    if (
      modalManutencao.nrOdometro === null ||
      modalManutencao.nrOdometro === undefined
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar uma manutenção sem adicionar um odometro ao acerto'
      );
      return;
    }
    if (
      modalManutencao.nrSeqPessoaOfi === null ||
      modalManutencao.nrSeqPessoaOfi === undefined
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar uma manutenção sem adicionar uma oficina'
      );
      return;
    }
    if (
      modalManutencao.nrSeqFormaPagamento === null ||
      modalManutencao.nrSeqFormaPagamento === undefined
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar uma manutenção sem adicionar uma forma de pagamento'
      );
      return;
    }
    if (
      modalManutencao.vlrOs === null ||
      modalManutencao.vlrOs === undefined ||
      modalManutencao.vlrOs === 0
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar uma manutenção sem adicionar um valor'
      );
      return;
    }

    const obj = {
      ...modalManutencao,
    };

    const { status, message: msg } = await createFromAcertoMotorista(obj);

    onSetMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    if (status === ResponseStatus.Success) {
      props.onClose({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitPag: true,
    });
    if (msg);
    return formasPagamento;
  };

  const onSearchOficina = async (e) => {
    const { message: msg, oficinas } = await getOficinaAutocomplete({
      noPessoa: e,
    });
    if (msg);
    return oficinas;
  };

  const onSearchProdutoAgrupador = async (e) => {
    const {
      status,
      message: msg,
      data: produtoAgrupador,
    } = await getProdutoAgrupadorAutoCompleteDetalhado({
      noSearchGlobal: e,
    });

    if (msg) onSetMessage(status, msg);
    return produtoAgrupador;
  };

  const onSearchProdutos = async (nrSeqProdutoAgrupador) => {
    const {
      status,
      message: msg,
      produtos,
    } = await getProdutoAutoCompleteByCodigos({
      nrSeqProdutoAgrupador,
    });

    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const onSearchAlmoxarifado = async (nrSeqProduto) => {
    const { data: almoxarifados } = await getAlmoxarifadoAutoComplete({
      nrSeqProduto,
    });

    return almoxarifados;
  };

  const onSearchAlmoxarifadoLocal = async (nrSeqProduto, nrSeqAlmoxarifado) => {
    const {
      status,
      message: msg,
      almoxarifados,
    } = await getAlmoxarifadoLocalAutoComplete({
      nrSeqProduto,
      nrSeqAlmoxarifado,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifados;
  };

  const preencheCamposProduto = async (produtoAgrupador) => {
    if (!modalManutencao.pessoaOfi.flgControlaEstoque) {
      if (produtoAgrupador.nrSeqProdutoAgrupador) {
        const produtoCodigo = await onSearchProdutos(
          produtoAgrupador.nrSeqProdutoAgrupador
        );

        if (produtoCodigo.length > 1) {
          setModalManutencao({
            ...modalManutencao,
            produtoAgrupador,
            nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
          });
        } else {
          setModalManutencao({
            ...modalManutencao,
            produtoAgrupador,
            nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
            produto: produtoCodigo[0],
            nrSeqProduto: produtoCodigo[0].nrSeqProduto,
          });
        }
      }
    }

    if (modalManutencao.pessoaOfi.flgControlaEstoque) {
      if (produtoAgrupador.nrSeqProdutoAgrupador) {
        const produtoCodigo = await onSearchProdutos(
          produtoAgrupador.nrSeqProdutoAgrupador
        );

        if (produtoCodigo.length > 1 || produtoCodigo.length === 0) {
          setModalManutencao({
            veiculo: modalManutencao.veiculo,
            nrSeqVeiculo: modalManutencao.nrSeqVeiculo,
            produtoAgrupador,
            nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
          });
        } else {
          const almoxarifado = await onSearchAlmoxarifado(
            produtoCodigo[0].nrSeqProduto
          );

          if (almoxarifado.length === 0) {
            onSetMessage(
              2,
              'Esse Produto não tem local de armazenagem. Selecione outro produto.'
            );
          } else {
            const almoxarifadoLocal = await onSearchAlmoxarifadoLocal(
              produtoCodigo[0].nrSeqProduto,
              almoxarifado[0].nrSeqAlmoxarifado
            );

            if (
              almoxarifadoLocal.length > 1 ||
              almoxarifadoLocal.length === 0
            ) {
              setModalManutencao({
                ...modalManutencao,
                produtoAgrupador,
                nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
                produto: produtoCodigo[0],
                nrSeqProduto: produtoCodigo[0].nrSeqProduto,
                almoxarifado: almoxarifado[0],
                nrSeqAlmoxarifado: almoxarifado[0].nrSeqAlmoxarifado,
              });
            } else {
              const saldoAtual = await getBalance(
                almoxarifadoLocal[0].nrSeqAlmoxarifadoLocal
              );

              const custoUnitario = await getCusto(
                almoxarifadoLocal[0].nrSeqAlmoxarifadoLocal,
                modalManutencao.dtAbertura
              );

              setModalManutencao({
                ...modalManutencao,
                produtoAgrupador,
                nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
                produto: produtoCodigo[0],
                nrSeqProduto: produtoCodigo[0].nrSeqProduto,
                almoxarifado: almoxarifado[0],
                nrSeqAlmoxarifado: almoxarifado[0].nrSeqAlmoxarifado,
                estoque: saldoAtual,
                vlrUnitario: custoUnitario,
                almoxarifadoLocal: almoxarifadoLocal[0],
                nrSeqAlmoxarifadoLocal:
                  almoxarifadoLocal[0].nrSeqAlmoxarifadoLocal,
              });
            }
          }
        }
      } else {
        setModalManutencao({
          veiculo: modalManutencao.veiculo,
          produtos: null,
        });
      }
    }
  };

  return (
    <Modal
      show={show}
      title='Gera Manutenção veiculo'
      size={BootstrapSizes.Large}
    >
      <Modal.Body className='p-4 pt-3'>
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='row mb-3'>
          <div className='col-4 mb-3'>
            <Autocomplete
              label='Oficina'
              required
              searchDataSource={onSearchOficina}
              selectedItem={modalManutencao?.pessoaOfi}
              onSelectItem={(pessoaOfi) => {
                setModalManutencao({
                  ...modalManutencao,
                  pessoaOfi,
                  nrSeqPessoaOfi: pessoaOfi.nrSeqPessoaOfi,
                });
              }}
              dataSourceTextProperty='noOficina'
            />
          </div>
          <div className='col mb-3'>
            <Autocomplete
              label='Produto Agrupador'
              searchDataSource={onSearchProdutoAgrupador}
              selectedItem={modalManutencao?.produtoAgrupador}
              onSelectItem={async (produtoAgrupador) => {
                if (Object.keys(produtoAgrupador).length === 0) {
                  setModalManutencao({
                    ...modalManutencao,
                    produtoAgrupador: null,
                  });
                } else {
                  preencheCamposProduto(produtoAgrupador);
                }
              }}
              dataSourceTextProperty='noProdutoAgrupador'
            />
          </div>
          <div className='col-4 mb-3'>
            <Textbox
              label='Valor'
              required
              mask={MaskTypes.DecimalCustom}
              text={modalManutencao?.vlrOs}
              onChangedValue={(vlrOs) => {
                setModalManutencao({
                  ...modalManutencao,
                  vlrOs,
                });
              }}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-4 mb-3'>
            <Autocomplete
              label='Forma Pagamento'
              required
              searchDataSource={onSearchFormaPagamento}
              selectedItem={modalManutencao?.formaPagamento}
              onSelectItem={(formaPagamento) => {
                setModalManutencao({
                  ...modalManutencao,
                  formaPagamento,
                  nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                });
              }}
              dataSourceTextProperty='noFormaPagamento'
            />
          </div>
          <div className='col-4 mb-3'>
            <Textbox
              label='Nr Documento'
              required
              text={modalManutencao?.nrDocumento}
              onChangedValue={(nrDocumento) => {
                setModalManutencao({
                  ...modalManutencao,
                  nrDocumento,
                });
              }}
            />
          </div>
          <div className='col-4 mb-3'>
            <Textbox
              label='Observação'
              text={modalManutencao?.obsOS}
              onChangedValue={(obsOS) => {
                setModalManutencao({
                  ...modalManutencao,
                  obsOS,
                });
              }}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-4 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Salvar'
              onClick={salvar}
            />
          </div>
          <div className='col-2 mt-3'>
            <Button
              theme={Theme.Danger}
              template={Button.Templates.Button}
              text='Sair'
              onClick={cancelar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
