export default class TipoServicoBancario {
  constructor(jsonObject = {}) {
    this.nrSeqTipoServicoBancario = jsonObject.nrSeqTipoServicoBancario;
    this.cdTipoServicoBancario = jsonObject.cdTipoServicoBancario;
    this.noTipoServicoBancario = jsonObject.noTipoServicoBancario;
    this.noFormatoEnvio = jsonObject.noFormatoEnvio;
    this.flgAtivo = jsonObject.flgAtivo;
    this.nrSeqBanco = jsonObject.nrSeqBanco;
  }

  toJson = () => JSON.stringify(this);
}
