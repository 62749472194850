export default class PocTubulacaoAducao {
  constructor(jsonObject = {}) {
    this.nrSeqPocTubulacaoAducao = jsonObject.nrSeqPocTubulacaoAducao;
    this.cdPocTubulacaoAducao = jsonObject.cdPocTubulacaoAducao;
    this.noDescricao = jsonObject.noDescricao;
    this.flgAtivo = jsonObject.flgAtivo;
  }

  toJson = () => JSON.stringify(this);
}
