import { Empresa } from 'core/models/SEG';
import qs from 'qs';
import Pagination from 'core/models/pagination';
import api from '../api';

const baseURL = `/SEG/Empresa`;

export const getEmpresaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Empresa(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getEmpresa = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { status, value, message } = response.data ?? {};
  return {
    status,
    message,
    data: value ? new Empresa(value) : {},
  };
};

export const getEmpresaNrSeqPessoaEmp = async (id) => {
  const response = await api.get(`/SEG/Empresa/GetNrSeqPessoaEmp`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Empresa(value) : {};
};

export const getEmpresasList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    empresas: value?.map((json) => new Empresa(json)) ?? [],
  };
};

export const saveEmpresa = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteEmpresa = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const getEmpresaAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    empresas: value?.map((json) => new Empresa(json)) ?? [],
  };
};

export const getEmpresaAutoCompleteWithOutPerfil = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteWithOutPerfil`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    empresas: value?.map((json) => new Empresa(json)) ?? [],
  };
};

export const getEmpresasUsuario = async (filters) => {
  const response = await api.get(`/SEG/Empresa/GetEmpresasUsuario`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    empresas: value?.map((json) => new Empresa(json)) ?? [],
  };
};

export const getEmpresaDefaultUsuario = async (filters) => {
  const response = await api.get(`/SEG/Empresa/GetEmpresaDefaultUsuario`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    empresas: value?.map((json) => new Empresa(json)) ?? [],
  };
};

export const deleteAllEmpresa = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const printEmpresa = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};
