export default class MetodoDesenvolvimento {
  constructor(jsonObject = {}) {
    this.nrSeqPocMetodoDesenvolvimento =
      jsonObject.nrSeqPocMetodoDesenvolvimento;
    this.cdPocMetodoDesenvolvimento = jsonObject.cdPocMetodoDesenvolvimento;
    this.noPocMetodoDesenvolvimento = jsonObject.noPocMetodoDesenvolvimento;
    this.flgAtivo = jsonObject.flgAtivo;
  }

  toJson = () => JSON.stringify(this);
}
