import qs from 'qs';
import Pagination from 'core/models/pagination';
import VeiculoFuncao from 'core/models/FRO/veiculoFuncao';
import api from '../api';

const baseURL = `/FRO/VeiculoFuncao`;

export const getVeiculoFuncaoList = async (filters) => {
  const response = await api.get(`/$VeiculoFuncao/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new VeiculoFuncao(json)) ?? [],
  };
};

export const getVeiculoFuncaoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new VeiculoFuncao(json)) ?? [],
  };
};

export const getVeiculoFuncaoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new VeiculoFuncao(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getVeiculoFuncao = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new VeiculoFuncao(value) : {};
};

export const saveVeiculoFuncao = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteVeiculoFuncao = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printVeiculoFuncao = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelVeiculoFuncao = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllVeiculoFuncao = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
