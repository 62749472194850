import qs from 'qs';
import Pagination from 'core/models/pagination';
import PneuVeiculo from 'core/models/FRO/pneuVeiculo';
import api from '../api';

const baseURL = `/FRO/PneuVeiculo`;

export const getPneuVeiculoList = async (filters) => {
  const response = await api.get(`/${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PneuVeiculo(json)) ?? [],
  };
};

export const getPneuVeiculoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PneuVeiculo(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPneuVeiculo = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PneuVeiculo(value) : {};
};

export const savePneuVeiculo = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const includeTire = async (data) => {
  const response = await api.post(`${baseURL}/IncludeTire`, data);
  return response.data ?? {};
};

export const removeTire = async (data) => {
  const response = await api.post(`${baseURL}/RemoveTire`, data);
  return response.data ?? {};
};

export const retreadTire = async (data) => {
  const response = await api.post(`${baseURL}/RetreadTire`, data);
  return response.data ?? {};
};

export const wasteTire = async (data) => {
  const response = await api.post(`${baseURL}/WasteTire`, data);
  return response.data ?? {};
};

export const tireRotation = async (data) => {
  const response = await api.post(`${baseURL}/TireRotation`, data);
  return response.data ?? {};
};

export const deletePneuVeiculo = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPneuVeiculo = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPneuVeiculo = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPneuVeiculo = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getPneusAtivosByVeiculo = async (id) => {
  const response = await api.get(`${baseURL}/SearchPneusAtivosByVeiculo`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value;
};

export const saveSulcagem = async (data) => {
  const response = await api.post(`${baseURL}/SaveSulcagem`, data);
  return response.data ?? {};
};

export const savePneusSulcagens = async (data) => {
  const response = await api.post(`${baseURL}/SavePneusSulcagens`, data);
  return response.data ?? {};
};

export const PrintReports = async (filters) => {
  const response = await api.post(`${baseURL}/PrintReports`, filters);
  return response.data ?? {};
};
