import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  getResultadoVeiculo,
  printResultadoVeiculo,
  printResultadoVeiculoDespesasPorCompetencia,
} from 'core/services/FRO/resultadoVeiculo';
import { getManutencaoVeiculoOSList } from 'core/services/FRO/ordemServico_Manutencao';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  PageTypes,
  Theme,
  BootstrapSizes,
  ResponseStatus,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  DatePicker,
  GridView,
  Panel,
  Textbox,
  ToolbarButtons,
} from 'ui/components';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';

export default function ResultadoVeiculoItem({
  onOpenReport,
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  findTransaction,
  onOpenTransaction,
}) {
  const gridViewReceita = useRef();
  const gridViewDespesa = useRef();
  const gridViewAcertos = useRef();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    setLoading(true);

    const resultadoVeiculo = await getResultadoVeiculo({
      ...registryKey,
      tipoRelatorio: 'Detalhe',
    });

    if (gridViewReceita && gridViewReceita.current) {
      gridViewReceita.current.setDataSource(resultadoVeiculo.receitas);
    }

    if (gridViewDespesa && gridViewDespesa.current) {
      gridViewDespesa.current.setDataSource(resultadoVeiculo.despesas);
    }

    if (gridViewAcertos && gridViewAcertos.current) {
      gridViewAcertos.current.setDataSource(
        resultadoVeiculo.acertosMotoristaFromVeiculo
      );
    }

    setData({ ...resultadoVeiculo });

    setLoading(false);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const onPrintAgrupado = async () => {
    setLoading(true);

    const { value } = await printResultadoVeiculo({
      nrSeqVeiculo: data.nrSeqVeiculo,
      deMesAno: data.deMesAno,
      ateMesAno: data.ateMesAno,
      tipoBusca: data.tipoBusca,
      tipoRelatorio: 'Agrupado',
      tipoPeriodo: data.tipoPeriodo,
      dtSaidaIni: data.dtSaidaIni,
      dtSaidaFim: data.dtSaidaFim,
    });

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onPrintDetalhe = async () => {
    setLoading(true);

    const { value } = await printResultadoVeiculo({
      nrSeqVeiculo: data.nrSeqVeiculo,
      deMesAno: data.deMesAno,
      ateMesAno: data.ateMesAno,
      tipoBusca: data.tipoBusca,
      tipoRelatorio: 'Detalhe',
      tipoPeriodo: data.tipoPeriodo,
      dtSaidaIni: data.dtSaidaIni,
      dtSaidaFim: data.dtSaidaFim,
    });

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onPrintDespesas = async () => {
    setLoading(true);

    const { value } = await printResultadoVeiculoDespesasPorCompetencia({
      nrSeqVeiculo: data.nrSeqVeiculo,
      deMesAno: data.deMesAno,
      ateMesAno: data.ateMesAno,
      tipoBusca: data.tipoBusca,
      tipoRelatorio: 'Detalhe',
      tipoPeriodo: data.tipoPeriodo,
      dtSaidaIni: data.dtSaidaIni,
      dtSaidaFim: data.dtSaidaFim,
    });

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const columnsReceita = [
    { key: 'descricao', title: 'Descrição', sortable: false },
    {
      key: 'valor',
      title: 'Valor',
      sortable: false,
      format: GridView.DataTypes.Money,
    },
  ];

  const onFindTransaction = (datasource) => {
    let transactionToOpen = null;

    if (datasource.noTipoItemDespesa === 'Abastecimento') {
      transactionToOpen = findTransaction(TransacationCodes.Abastecimento);
    }

    if (datasource.noTipoItemDespesa === 'TituloPagar') {
      transactionToOpen = findTransaction(TransacationCodes.TituloPagar);
    }

    if (datasource.noTipoItemDespesa === 'AcertoMotorista') {
      transactionToOpen = findTransaction(TransacationCodes.AcertoMotorista);
    }

    if (datasource.noTipoItemDespesa === 'ManutencaoVeiculo') {
      transactionToOpen = findTransaction(
        TransacationCodes.OrdemServicoManutencao
      );
    }

    return transactionToOpen;
  };

  const onClickVerDespesa = async (datasource) => {
    const transactionToOpen = onFindTransaction(datasource);

    if (datasource.noTipoItemDespesa === 'ManutencaoVeiculo') {
      const {
        message: msg,
        data: manutencoesVeiculoOS,
      } = await getManutencaoVeiculoOSList({
        nrSeqManutencaoVeiculo: datasource.nrSeqItemDespesa,
      });

      if (manutencoesVeiculoOS.length === 0)
        return onSetMessage(ResponseStatus.Error, msg);

      if (manutencoesVeiculoOS.length > 0)
        datasource.nrSeqItemDespesa =
          manutencoesVeiculoOS[0].nrSeqManutencaoVeiculoOS;
    }

    if (transactionToOpen) {
      transactionToOpen.registryKey = datasource.nrSeqItemDespesa;
      onOpenTransaction(transactionToOpen, true);
    } else {
      onSetMessage(
        ResponseStatus.Error,
        `Não foi possível abrir o ${datasource.noTipoItemDespesa}.`
      );
    }

    return 0;
  };

  const columnsDespesas = [
    { key: 'nrSeqItemDespesa', title: 'Nr. Despesa', visible: false },
    { key: 'noTipoItemDespesa', title: 'Tipo de Despesa', visible: false },
    { key: 'descricao', title: 'Descrição', sortable: false },
    {
      key: 'valor',
      title: 'Valor',
      sortable: false,
      format: GridView.DataTypes.Money,
    },
    {
      key: 'nrSeqItemDespesa',
      title: 'Ver',
      type: GridView.ColumnTypes.Button,
      onClick: (datasource) => onClickVerDespesa(datasource),
      theme: Theme.Warning,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Ver Acerto',
      tooltipDirection: 'bottom',
    },
  ];

  const onClickVerAcerto = (datasource) => {
    const transactionOpen = findTransaction(TransacationCodes.AcertoMotorista);

    if (transactionOpen) {
      transactionOpen.registryKey = datasource.nrSeqAcertoMotorista;
      onOpenTransaction(transactionOpen, true);
    } else {
      onSetMessage(
        ResponseStatus.Error,
        'Não foi possível abrir o Acerto Motorista.'
      );
    }
  };

  const columnAcertos = [
    { key: 'nrSeqAcertoMotorista', title: 'Acerto' },
    { key: 'noMotorista', title: 'Motorista' },
    { key: 'placaPrinc', title: 'Cavalo' },
    { key: 'placaComp', title: 'Comp' },
    { key: 'placaCompB', title: 'Comp' },
    { key: 'placaCompC', title: 'Comp' },
    {
      key: 'nrSeqAcertoMotorista',
      title: 'Ver',
      type: GridView.ColumnTypes.Button,
      onClick: (datasource) => onClickVerAcerto(datasource),
      theme: Theme.Warning,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Ver Acerto',
      tooltipDirection: 'bottom',
    },
  ];

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Resultado Veiculo Detalhado'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      transaction={transaction}
      message={message}
    >
      <ToolbarButtons>
        <ToolbarButtons.ButtonList
          text='Imprimir'
          icon='caret-down'
          items={[
            {
              text: 'Imprimir Agrupado',
              icon: 'print',
              onClick: () => onPrintAgrupado(),
            },
            {
              text: 'Imprimir Detalhe',
              icon: 'print',
              onClick: () => onPrintDetalhe(),
            },
            {
              text: 'Imprimir Despesas',
              icon: 'print',
              onClick: () => onPrintDespesas(),
            },
          ]}
        />
      </ToolbarButtons>
      <div className='row mb-3 align-items-end'>
        <div className='col-2'>
          <Textbox readOnly label='Placa Principal' text={data.placaPrinc} />
        </div>
        <div className='col-2'>
          <Textbox readOnly label='Placa Comp' text={data.placaComp} />
        </div>
        <div className='col-2'>
          <Textbox readOnly label='Placa CompB' text={data.placaCompB} />
        </div>
        {data.tipoPeriodo === 'C' ? (
          <>
            <div className='col-2'>
              <Textbox
                readOnly
                label='De'
                text={data.deMesAno}
                mask={MaskTypes.Competence}
              />
            </div>
            <div className='col-2'>
              <Textbox
                readOnly
                label='Até'
                text={data.ateMesAno}
                mask={MaskTypes.Competence}
              />
            </div>
          </>
        ) : (
          <>
            <div className='col'>
              <DatePicker
                label='De'
                readOnly
                text={data.dtSaidaIni}
                mask={MaskTypes.Date}
              />
            </div>
            <div className='col'>
              <DatePicker
                label='Até'
                readOnly
                text={data.dtSaidaFim}
                mask={MaskTypes.Date}
              />
            </div>
          </>
        )}
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Textbox readOnly label='Motorista' text={data.noMotorista} />
        </div>
        <div className='col'>
          <Textbox readOnly label='Plano De Conta' text={data.noPlanoDeConta} />
        </div>
        <div className='col'>
          <DatePicker
            readOnly
            label='Data de Saída Inicial'
            text={data.dtSaidaIni}
            mask={MaskTypes.Date}
          />
        </div>
        <div className='col'>
          <DatePicker
            readOnly
            label='Data de Chegada Inicial'
            text={data.dtChegadaIni}
            mask={MaskTypes.Date}
          />
        </div>
        <div className='col'>
          <DatePicker
            readOnly
            label='Data de Chegada Final'
            text={data.dtChegadaFim}
            mask={MaskTypes.Date}
          />
        </div>
      </div>
      <div className='row mb-3 align-items-end'>
        <div className='col'>
          <Textbox readOnly label='Km Percorridos' text={data.kmPercorrido} />
        </div>
        <div className='col'>
          <Textbox
            readOnly
            label='Lucro'
            text={data.vlrLucro}
            mask={MaskTypes.Currency}
          />
        </div>
        <div className='col'>
          <Textbox
            readOnly
            label='Lucro por Km'
            text={data.vlrLucroPorKm}
            mask={MaskTypes.Currency}
          />
        </div>
        <div className='col'>
          <Textbox
            readOnly
            label='Percentual de Lucro'
            text={data.vlrPercentual}
            mask={MaskTypes.Decimal}
          />
        </div>
      </div>
      <div className='row mb-3 align-items-end'>
        <Panel>
          <Panel.Header title='Receitas' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3 align-items-end'>
              <div className='col-3'>
                <Textbox
                  readOnly
                  label='Valor Total Receita Acerto'
                  text={data.vlrReceitaAcerto}
                  mask={MaskTypes.Currency}
                />
              </div>
            </div>

            <div className='row mb-3 align-items-end'>
              <GridView
                ref={gridViewReceita}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columnsReceita}
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mb-3 align-items-end'>
        <Panel>
          <Panel.Header title='Despesas' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3 align-items-end'>
              <div className='col-3'>
                <Textbox
                  readOnly
                  label='Valor Total Despesa Acerto'
                  text={
                    data.vlrDespesaAcerto +
                    data.vlrAbastecimentoVinculado +
                    data.vlrAbastecimentoNaoVinculado
                  }
                  mask={MaskTypes.Currency}
                />
              </div>
              <div className='col-3'>
                <Textbox
                  readOnly
                  label='Valor Total Despesa Titulos'
                  text={data.vlrDespesaTitulos}
                  mask={MaskTypes.Currency}
                />
              </div>
              <div className='col-3'>
                <Textbox
                  readOnly
                  label='Valor Total Manuteções Não Vinculadas'
                  text={data.vlrDespesaManutencao}
                  mask={MaskTypes.Currency}
                />
              </div>
              <div className='col-3'>
                <Textbox
                  readOnly
                  label='Valor Total de Despesas'
                  text={
                    data.vlrDespesaTitulos +
                    data.vlrDespesaAcerto +
                    data.vlrDespesaManutencao +
                    data.vlrAbastecimentoVinculado +
                    data.vlrAbastecimentoNaoVinculado
                  }
                  mask={MaskTypes.Currency}
                />
              </div>
            </div>
            <div className='row mb-3 align-items-end'>
              <GridView
                ref={gridViewDespesa}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columnsDespesas}
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Acertos' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3 align-items-end'>
              <GridView
                ref={gridViewAcertos}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columnAcertos}
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </Panel.Body>
        </Panel>
      </div>
    </CSDManutPage>
  );
}
