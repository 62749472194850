import { getFormaPagamentoAutoComplete } from 'core/services/FIN';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import { getCentroDeCustoAutoComplete } from 'core/services/TEL';
import { getPrintFaturaContaTelefone } from 'core/services/TEL/faturarContaTelefone';
import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Modal,
  Autocomplete,
  DatePicker,
  Loader,
  Notification,
  GridView,
  DropdownMulti,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import PanelSelecaoContratos from '../panelSelecaoContratos';

const columns = [
  {
    key: 'nrSeqTituloReceberFatura',
    type: GridView.ColumnTypes.Checkbox,
  },
  { key: 'linhaFormatada', title: 'Nº Linha', visible: false },
  { key: 'pessoa.noPessoa', title: 'Cliente' },
  {
    key: 'vlrFatura',
    title: 'Valor',
    format: GridView.DataTypes.Decimal,
    visible: false,
  },
  { key: 'vlrFatura', title: 'Valor', format: GridView.DataTypes.Money },
  { key: 'dtVencimento', title: 'Vencimento', format: GridView.DataTypes.Date },
  { key: 'nrSeqFatura', title: 'nrSeqFatura', visible: false },
];

export default function ModalCancelaFaturamento({
  show,
  onClose,
  onImprimirBoletosSelecionados,
  onImprimirBoletos,
  dropDownCentroCusto,
}) {
  const [data, setData] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(null);
  const gridView = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  useEffect(() => {
    if (show) {
      setData({});
      setSelectedItems({});
    }
  }, [show]);

  const loadResult = (result) => {
    const { status, message: msg, ...obj } = result;

    if (msg) return onSetMessage(status, msg);

    return Object.values(obj)[0];
  };

  const handleLoadCentroCusto = async () => {
    const result = await getCentroDeCustoAutoComplete({
      nrSeqContratoAgrupado: data.nrSeqContratoAgrupado,
      nrSeqContaCabecalho: data.nrSeqContaCabecalho,
      MontarArvore: true,
    });
    return loadResult(result);
  };

  useEffect(() => {
    if (data.nrSeqContaCabecalho > 0 || data.nrSeqContratoAgrupado > 0) {
      dropDownCentroCusto.current.loadDataSource(handleLoadCentroCusto);
    } else {
      dropDownCentroCusto.current.reset();
    }
  }, [data.nrSeqContaCabecalho, data.nrSeqContratoAgrupado]);

  const onSearchPrintFaturaContaTelefone = async () => {
    onSetMessage();
    setLoading(true);

    const {
      status,
      message: msg,
      contas,
      pagination,
    } = await getPrintFaturaContaTelefone(data);

    onSetMessage(status, msg);

    if (gridView && gridView.current)
      gridView.current.setDataSource(contas, pagination);

    setLoading(false);
  };

  const onSearchTipo = async (e) => {
    const { data: tituloReceberEspecie } = await getTituloEspecieAutoComplete({
      noEspecie: e,
    });

    return tituloReceberEspecie;
  };

  const onAutoCompleteFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
    });

    onSetMessage(status, msg);

    return formasPagamento;
  };

  const onImprimirFaturasSelecionadas = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);
    const faturas = selecteds.map((row) => row[6].value);
    onImprimirBoletosSelecionados(faturas);
  };

  const onImprimirFaturas = async () => {
    onImprimirBoletos(data);
  };

  return (
    <Modal
      show={show}
      title='Imprimir Boletos'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body className='p-4 pt-3'>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <Loader loading={loading} />
        <div className='row mb-3'>
          <div className='col'>
            <PanelSelecaoContratos
              justActives
              onContratoAgrupadoChange={(contratoAgrupado) =>
                setData({
                  ...data,
                  nrSeqContratoAgrupado: contratoAgrupado.nrSeqContratoAgrupado,
                })
              }
              onFaturaChange={(fatura) =>
                setData({
                  ...data,
                  nrSeqContaCabecalho: fatura.nrSeqContaCabecalho,
                })
              }
              onMessage={(msg, theme) => setMessage({ message: msg, theme })}
            />
          </div>
          <div className='col'>
            <DatePicker
              label='Cadastro'
              text={data.dtCadastro}
              mask={MaskTypes.Date}
              onChange={(dtCadastro) =>
                setData({
                  ...data,
                  dtCadastro,
                })
              }
            />
          </div>
          <div className='col'>
            <DatePicker
              label='Vencimento'
              text={data.dtVencimento}
              mask={MaskTypes.Date}
              onChange={(dtVencimento) =>
                setData({
                  ...data,
                  dtVencimento,
                })
              }
            />
          </div>
          <div className='col'>
            <Autocomplete
              label='Forma de Recebimento'
              searchDataSource={onAutoCompleteFormaPagamento}
              selectedItem={selectedItems.formaPagamento}
              onSelectItem={(formaPagamento) => {
                setSelectedItems({
                  ...selectedItems,
                  formaPagamento,
                });
                setData({
                  ...data,
                  nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                });
              }}
              dataSourceTextProperty='noFormaPagamento'
            />
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col'>
            <DropdownMulti
              label='Centro de Custo'
              ref={dropDownCentroCusto}
              dataSourcePropertyText='noCentroDeCusto'
              dataSourcePropertyValue='nrSeqCentroDeCusto'
              selectedItems={selectedItems.centrosCusto ?? []}
              onSelectItem={(centrosCusto) => {
                setSelectedItems({ ...selectedItems, centrosCusto });
                setData({
                  ...data,
                  nrSeqCentrosDeCustos: centrosCusto?.map(
                    (p) => p.nrSeqCentroDeCusto
                  ),
                });
              }}
            />
          </div>
          <div className='col'>
            <Autocomplete
              label='Tipo Receita'
              searchDataSource={onSearchTipo}
              selectedItem={data.tituloEspecie}
              onSelectItem={async (tituloEspecie) => {
                setData({
                  ...data,
                  tituloEspecie,
                  nrSeqTituloEspecie: tituloEspecie.nrSeqTituloEspecie,
                });
              }}
              dataSourceTextProperty='noEspecie'
              nrseqTransactionToOpen='nrSeqTituloEspecie'
            />
          </div>
          <div className='col mt-4'>
            <Button
              theme={Theme.Info}
              template={Button.Templates.Button}
              text='Pesquisar'
              className='width100'
              onClick={onSearchPrintFaturaContaTelefone}
            />
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col'>
            <GridView
              ref={gridView}
              onPageSizeChange={false}
              onPageChange={false}
              showPagination={false}
              showSelectSizes={false}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Imprimir Boletos Selecionados'
          theme={Theme.Success}
          onClick={() => {
            onImprimirFaturasSelecionadas();
          }}
        />
        <Button
          text='Imprimir Todos'
          theme={Theme.Success}
          onClick={() => {
            onImprimirFaturas();
          }}
        />
        <Button
          text='Sair'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
