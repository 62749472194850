import Estado from 'core/models/SEG/estado';

export default class VendedorEstado {
  constructor(jsonObject = {}) {
    this.nrSeqPessoaVen = jsonObject.nrSeqPessoaVen;
    this.nrSeqEstado = jsonObject.nrSeqEstado;
    this.estado = new Estado(jsonObject.estado ?? {});
  }

  toJson = () => JSON.stringify(this);
}
