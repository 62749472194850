import qs from 'qs';
import Pagination from 'core/models/pagination';
import ManifestoCTE from 'core/models/FRO/manifestoCTE';
import api from '../api';

const baseURL = `/FRO/ManifestoCTE`;

export const getManifestoCTEList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ManifestoCTE(json)) ?? [],
  };
};

export const dropDownListCidadeEncerramento = async (data) => {
  const response = await api.post(
    `${baseURL}/DropDownListCidadeEncerramento`,
    data
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? [],
  };
};

export const dropDownListCidadeEncerramentoMdfes = async (ids) => {
  const response = await api.get(
    `${baseURL}/DropDownListCidadeEncerramentoMdfes`,
    {
      params: { ids },
      paramsSerializer: (params) => qs.stringify(params),
    }
  );

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? [],
  };
};

export const getManifestoCTEPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ManifestoCTE(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getManifestoNfvPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchManifestoNfvPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ManifestoCTE(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getManifestoCTE = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ManifestoCTE(value) : {};
};

export const saveManifestoCTE = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const saveManifestoNfv = async (data) => {
  const response = await api.post(`${baseURL}/SaveManifestoNfv`, data);
  return response.data ?? {};
};

export const validaEncerramento = async (data) => {
  const response = await api.post(`${baseURL}/ValidateClosing`, data);

  const { value } = response.data ?? {};

  return value;
};

export const deleteManifestoCTE = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printManifestoCTE = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const downloadImageManifestoCTE = async (filters) => {
  const response = await api.get(`${baseURL}/DownloadImage`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelManifestoCTE = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllManifestoCTE = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const gerarManifesto = async (data) => {
  const response = await api.post(`${baseURL}/GerarManifesto`, data);

  const { status, messages, value } = response.data ?? {};

  return {
    status,
    messages,
    data: value?.map((json) => new ManifestoCTE(json)) ?? [],
  };
};

export const GetMensagemViagensEmAndamento = async (nrseqveiculo) => {
  const response = await api.get(`${baseURL}/GetMensagemViagensEmAndamento`, {
    params: { nrseqveiculo },
  });

  const { status, message } = response.data ?? {};

  return {
    status,
    message,
  };
};
