import qs from 'qs';
import VeiculoRodagem from 'core/models/FRO/veiculoRodagem';
import api from '../api';

const baseURL = `/FRO/VeiculoRodagem`;

export const getVeiculoRodagemList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, { params: filters });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new VeiculoRodagem(json)) ?? [],
  };
};

export const getVinculadosByVeiculoPrincipal = async (filters) => {
  const response = await api.get(
    `${baseURL}/SearchVinculadosByVeiculoPrincipal`,
    { params: filters, paramsSerializer: (params) => qs.stringify(params) }
  );

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new VeiculoRodagem(json)) ?? [],
  };
};

export const getVeiculoRodagemAtiva = async (filters) => {
  const response = await api.get(`${baseURL}/SearchVeiculoRodagemAtiva`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return { status, message, data: value ? new VeiculoRodagem(value) : {} };
};

export const saveVeiculoRodagem = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};
