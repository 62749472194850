import ManifestoCTE from 'core/models/FRO/manifestoCTE';
import Empresa from 'core/models/SEG/empresa';
import Veiculo from 'core/models/FRO/veiculo';
import ColetaEmbarque from 'core/models/FRO/coletaEmbarque';
import Motorista from 'core/models/FRO/motorista';
import Vendedor from 'core/models/VEN/vendedor';
import ColetaConsignatario from 'core/models/FRO/coletaConsignatario';

export default class RelatorioViagem {
  constructor(jsonObject = {}) {
    this.nrSeqTipoImpressao = jsonObject.nrSeqTipoImpressao;
    this.noTipoImpressao = jsonObject.noTipoImpressao;
    this.flgStatusAverbacao = jsonObject.flgStatusAverbacao;
    this.flgContratoTipoCTE = jsonObject.flgContratoTipoCTE;
    this.flgExisteArquivo = jsonObject.flgExisteArquivo;
    this.flgFiltroFinanceiro = jsonObject.flgFiltroFinanceiro;
    this.flgReceitaAcerto = jsonObject.flgReceitaAcerto;
    this.flgStatusAutorizados = jsonObject.flgStatusAutorizados;
    this.dtInicial = jsonObject.dtInicial;
    this.dtFinal = jsonObject.dtFinal;
    this.statusCTEstr = jsonObject.statusCTEstr;
    this.dtEmissao = jsonObject.dtEmissao;
    this.dtVencimentoColetaEmbarqueFatura =
      jsonObject.dtVencimentoColetaEmbarqueFatura;
    this.cdColetaEmbarque = jsonObject.cdColetaEmbarque;
    this.cdCfo = jsonObject.cdCfo;
    this.cdFatura = jsonObject.cdFatura;
    this.noPlaca = jsonObject.noPlaca;
    this.noPlacaCamp = jsonObject.noPlacaCamp;
    this.nrSeqApoliceSeguro = jsonObject.nrSeqApoliceSeguro;
    this.flgCancelado = jsonObject.flgCancelado;
    this.nrNotaFiscal = jsonObject.nrNotaFiscal;
    this.flgAnulado = jsonObject.flgAnulado;
    this.flgComplementar = jsonObject.flgComplementar;
    this.flgSeguro = jsonObject.flgSeguro;
    this.vlrMercadoria = jsonObject.vlrMercadoria;
    this.vlrPedagio = jsonObject.vlrPedagio;
    this.vlrTotal = jsonObject.vlrTotal;
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.nrSeqFreteMercadoria = jsonObject.nrSeqFreteMercadoria;
    this.nrseqTipoFrete = jsonObject.nrseqTipoFrete;
    this.nrSeqVeiculoComp = jsonObject.nrSeqVeiculoComp;
    this.nrSeqMotorista = jsonObject.nrSeqMotorista;
    this.nrSeqPessoaProp = jsonObject.nrSeqPessoaProp;
    this.nrSeqPessoaVen = jsonObject.nrSeqPessoaVen;
    this.noPlacaComp = jsonObject.noPlacaComp;
    this.noEspecieFrete = jsonObject.noEspecieFrete;
    this.noColetaEmbarqueComplementar = jsonObject.noColetaEmbarqueComplementar;
    this.qtdeEmbarque = jsonObject.qtdeEmbarque;
    this.quantidadeNotas = jsonObject.quantidadeNotas;
    this.pesoBruto = jsonObject.pesoBruto;
    this.baseCalIcms = jsonObject.baseCalIcms;
    this.aliqIcms = jsonObject.aliqIcms;
    this.vlrTarifa = jsonObject.vlrTarifa;
    this.vlrIcms = jsonObject.vlrIcms;
    this.vlrFreteTerceiro = jsonObject.vlrFreteTerceiro;
    this.nrSeqViagem = jsonObject.nrSeqViagem;
    this.cdViagem = jsonObject.cdViagem;
    this.noUsuario = jsonObject.noUsuario;
    this.noCliente = jsonObject.noCliente;
    this.noMotorista = jsonObject.noMotorista;
    this.noVendedor = jsonObject.noVendedor;
    this.noRemetente = jsonObject.noRemetente;
    this.noProduto = jsonObject.noProduto;
    this.noDestinatario = jsonObject.noDestinatario;
    this.noFreteMercadoria = jsonObject.noFreteMercadoria;
    this.noCidadeColeta = jsonObject.noCidadeColeta;
    this.noCidadeEntrega = jsonObject.noCidadeEntrega;
    this.noUFColeta = jsonObject.noUFColeta;
    this.noUFEntrega = jsonObject.noUFEntrega;
    this.noTipoFrete = jsonObject.noTipoFrete;
    this.noMarca = jsonObject.noMarca;
    this.noEmpresa = jsonObject.noEmpresa;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqPessoaMot = jsonObject.nrSeqPessoaMot;
    this.nrSeqPessoaTran = jsonObject.nrSeqPessoaTran;
    this.noPessoaTran = jsonObject.noPessoaTran;
    this.nrSeqPessoaRem = jsonObject.nrSeqPessoaRem;
    this.nrSeqPessoaDes = jsonObject.nrSeqPessoDes;
    this.nrSeqCidadeOri = jsonObject.nrSeqCidadeOri;
    this.nrSeqCidadeDes = jsonObject.nrSeqCidadeDes;
    this.nrSeqEstadoOri = jsonObject.nrSeqEstadoOri;
    this.nrSeqEstadoDes = jsonObject.nrSeqEstadoDes;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.nrCte = jsonObject.nrCte;
    this.nrChaveCTE = jsonObject.nrChaveCTE;
    this.tipoEmissaoCTE = jsonObject.tipoEmissaoCTE;
    this.nrFiscal = jsonObject.nrFiscal;
    this.nrClienteDocumento = jsonObject.nrClienteDocumento;
    this.nrSeqColetaEmbarque = jsonObject.nrSeqColetaEmbarque;
    this.nrTit = jsonObject.nrTit;
    this.valorEmAberto = jsonObject.valorEmAberto;
    this.sacado = jsonObject.sacado;
    this.vencimento = jsonObject.vencimento;
    this.noColorStatus = jsonObject.noColorStatus;
    this.nrOt = jsonObject.nrOt;
    this.nrRemessa = jsonObject.nrRemessa;
    this.dtPrevisaoEntrega = jsonObject.dtPrevisaoEntrega;
    this.nrSeqPessoaConsigOri = jsonObject.nrSeqPessoaConsigOri;
    this.nrSeqPessoaConsigDest = jsonObject.nrSeqPessoaConsigDest;
    this.noPessoaConsigOri = jsonObject.noPessoaConsigOri;
    this.noPessoaConsigDest = jsonObject.noPessoaConsigDest;
    this.flgLiquidado = jsonObject.flgLiquidado;
    this.noFormaPagamento = jsonObject.noFormaPagamento;
    this.nossoNumero = jsonObject.nossoNumero;
    this.dtVencimento = jsonObject.dtVencimento;

    // ... outras propriedades ...
    this.manifestoCte = new ManifestoCTE(jsonObject.manifestoCte ?? {});
    this.unidOrigem = new Empresa(jsonObject.unidOrigem ?? {});
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    this.motorista = new Motorista(jsonObject.motorista ?? {});
    this.vendedor = new Vendedor(jsonObject.vendedor ?? {});
    this.coletaEmbarque = new ColetaEmbarque(jsonObject.coletaEmbarque ?? {});
    this.conhecimentos = jsonObject.conhecimentos
      ? jsonObject.conhecimentos.map((item) => new ColetaEmbarque(item ?? {}))
      : [];
    this.origemConsig = new ColetaConsignatario(jsonObject.origemConsig ?? {});
    this.destinoConsig = new ColetaConsignatario(
      jsonObject.destinoConsig ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
