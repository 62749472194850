import Usuario from 'core/models/SEG/usuario';
import ManutencaoVeiculoOS from 'core/models/FRO/manutencaoVeiculoOS';
import ManutencaoChecklistFrotaVeiculo from 'core/models/FRO/manutencaoChecklistFrotaVeiculo';
import Motorista from 'core/models/FRO/motorista';
import ManutencaoChecklistFrotaDocumento from 'core/models/FRO/manutencaoChecklistFrotaDocumento';
import ChecklistFrotaRetorno from 'core/models/FRO/checklistFrotaRetorno';
import ChecklistFrota from 'core/models/FRO/checklistFrota';
import ChecklistValor from 'core/models/FRO/checklistValor';
import VeiculoManutencaoPreventiva from 'core/models/FRO/veiculo_ManutencaoPreventiva';
import ChecklistFrotaRetornoModel from 'core/models/FRO/checklistFrotaRetornoModel';

export default class ManutencaoChecklistFrota {
  constructor(jsonObject = {}) {
    this.nrSeqManutencaoChecklist = jsonObject.nrSeqManutencaoChecklist;
    this.cdManutencaoChecklist = jsonObject.cdManutencaoChecklist;
    this.nrSeqChecklistFrota = jsonObject.nrSeqChecklistFrota;
    this.dtChecklist = jsonObject.dtChecklist;
    this.nrSeqPessoaMot = jsonObject.nrSeqPessoaMot;
    this.dtCad = jsonObject.dtCad;
    this.nrSeqManutencaoVeiculo = jsonObject.nrSeqManutencaoVeiculo;
    this.dtExecucao = jsonObject.dtExecucao;
    this.obs = jsonObject.obs;
    this.dtFechamento = jsonObject.dtFechamento;
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.dtSaidaChecklist = jsonObject.dtSaidaChecklist;
    this.dtConclusaoPreenchimento = jsonObject.dtConclusaoPreenchimento;
    this.nrSeqManutencaoVeiculoOS = jsonObject.nrSeqManutencaoVeiculoOS;

    // #region Propriedades Auxiliares
    this.noStatus = jsonObject.noStatus;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.dtCheckListIni = jsonObject.dtCheckListIni;
    this.dtCheckListFim = jsonObject.dtCheckListFim;
    this.horaSaidaChecklist = jsonObject.horaSaidaChecklist;
    this.ultimoOdometro = jsonObject.ultimoOdometro;
    this.placas = jsonObject.placas;
    this.flgBuscaVeiculoVinculado = jsonObject.flgBuscaVeiculoVinculado;
    this.cdMManutencaoVeiculoOS = jsonObject.cdMManutencaoVeiculoOS;
    this.usuario = new Usuario(jsonObject.usuario ?? {});
    this.manutencaoVeiculoOS = new ManutencaoVeiculoOS(
      jsonObject.manutencaoVeiculoOS ?? {}
    );
    this.manutencaoChecklistFrotaVeiculo = new ManutencaoChecklistFrotaVeiculo(
      jsonObject.manutencaoChecklistFrotaVeiculo ?? {}
    );
    this.motorista = new Motorista(jsonObject.motorista ?? {});
    this.checklistFrota = new ChecklistFrota(jsonObject.checklistFrota ?? {});

    this.manutencaoChecklistFrotaVeiculo = new ManutencaoChecklistFrotaVeiculo(
      jsonObject.manutencaoChecklistFrotaVeiculo ?? {}
    );
    this.veiculo_ManutencaoPreventivaS = jsonObject.veiculo_ManutencaoPreventivaS
      ? jsonObject.veiculo_ManutencaoPreventivaS.map(
          (item) => new VeiculoManutencaoPreventiva(item ?? {})
        )
      : [];
    this.manutencaoChecklistFrotaDocumentoS = jsonObject.manutencaoChecklistFrotaDocumentoS
      ? jsonObject.manutencaoChecklistFrotaDocumentoS.map(
          (item) => new ManutencaoChecklistFrotaDocumento(item ?? {})
        )
      : [];
    this.checklistFrotaRetornoS = jsonObject.checklistFrotaRetornoS
      ? jsonObject.checklistFrotaRetornoS.map(
          (item) => new ChecklistFrotaRetorno(item ?? {})
        )
      : [];
    this.checklistRetornos = jsonObject.checklistRetornos
      ? jsonObject.checklistRetornos.map(
          (item) => new ChecklistFrotaRetornoModel(item ?? {})
        )
      : [];
    this.checklistOcorrencias = jsonObject.checklistOcorrencias
      ? jsonObject.checklistOcorrencias.map(
          (item) => new ChecklistValor(item ?? {})
        )
      : [];
    this.manutencaoChecklistFrotaVeiculoS = jsonObject.manutencaoChecklistFrotaVeiculoS
      ? jsonObject.manutencaoChecklistFrotaVeiculoS.map(
          (item) => new ManutencaoChecklistFrotaVeiculo(item ?? {})
        )
      : [];

    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
