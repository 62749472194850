import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';

import { CSDManutPage, Textbox, Autocomplete, DatePicker } from 'ui/components';
import PanelSelecaoContratos from 'ui/pages/TEL/panelSelecaoContratos';

import {} from 'core/services/TEL/clienteLinha';
import { getTipoChamadaAutoComplete } from 'core/services/TEL/tipoChamada';
import { getAreaChamadaAutoComplete } from 'core/services/TEL/areaChamada';
import {
  getContaChamada,
  saveContaChamada,
} from 'core/services/TEL/contaChamada';
import { getContaRecursoAutoComplete } from 'core/services/TEL/contaRecurso';

import { ContaChamada } from 'core/models/TEL';

import { MaskTypes } from 'ui/Helpers/masks';

export default function ContaChamadaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();

  const [message, setMessage] = useState(null);
  const [contaChamada, setContaChamada] = useState({});
  const [selectedItems, setSelectedItems] = useState({});

  const [loading, setLoading] = useState(false);
  const contratosativos = true;
  const onSetMessage = (status, messageshow) => {
    if (messageshow)
      setMessage({
        message: messageshow,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const register = await getContaChamada(registryKey);
      setContaChamada(register);
      setLoading(false);
    } else {
      setLoading(false);

      setContaChamada(new ContaChamada());
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);

    const { status, message: msg } = await saveContaChamada(contaChamada);

    onSetMessage(status, msg);

    setLoading(false);
  };

  const { id: idSelecao } = PageTypes.Selection;

  const onSearchTipoChamada = async (e) => {
    const {
      status,
      message: msg,
      tiposchamadas,
    } = await getTipoChamadaAutoComplete({
      noTipoChamada: e,
    });
    onSetMessage(status, msg);
    return tiposchamadas;
  };

  const onSearchAreaChamada = async (e) => {
    const {
      status,
      message: msg,
      areaschamadas,
    } = await getAreaChamadaAutoComplete({
      noAreaChamada: e,
    });
    onSetMessage(status, msg);
    return areaschamadas;
  };

  const onSearchContaRecurso = async (e) => {
    const {
      status,
      message: msg,
      contaRecursos,
    } = await getContaRecursoAutoComplete({
      nrPrefixoLinha: e,
      nrSeqContaCabecalho: contaChamada.nrSeqContaCabecalho,
    });

    onSetMessage(status, msg);

    return contaRecursos;
  };

  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção do Conta Chamada'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => {}}
      onSave={save}
      message={message}
      transaction={transaction}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <div className='row mb-3'>
        <div className='col-12'>
          <PanelSelecaoContratos
            justActives={contratosativos}
            visible={!Number.isInteger(registryKey)}
            Simple
            contratoAgrupado={selectedItems.contratoAgrupado}
            onContratoAgrupadoChange={(contratoAgrupado) => {
              setSelectedItems({ ...selectedItems, contratoAgrupado });
              setContaChamada({
                ...contaChamada,
                nrSeqContratoAgrupado: contratoAgrupado.nrSeqContratoAgrupado,
              });
            }}
            contrato={selectedItems.contratoAgrupado}
            onContratoChange={(contrato) => {
              setSelectedItems({ ...selectedItems, contrato });
            }}
            fatura={selectedItems.fatura}
            onFaturaChange={(fatura) => {
              setSelectedItems({ ...selectedItems, fatura });
              setContaChamada({
                ...contaChamada,
                nrSeqContaCabecalho: fatura.nrSeqContaCabecalho,
              });
            }}
            onMessage={(msg, theme) => setMessage({ message: msg, theme })}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-6'>
          <Autocomplete
            label='Detalhe Nro Origem'
            searchDataSource={onSearchContaRecurso}
            selectedItem={contaChamada.contaRecurso}
            onSelectItem={(contaRecurso) =>
              setContaChamada({
                ...contaChamada,
                contaRecurso,
                nrSeqContaRecurso: contaRecurso.nrSeqContaRecurso,
              })
            }
            dataSourceTextProperty='linhaFormatada'
          />
        </div>

        <div className='col-6'>
          <Textbox
            label='Número Destino'
            text={contaChamada.setNumeroDestinoCompleto}
            mask={MaskTypes.CellPhone}
            onChangedValue={(setNumeroDestinoCompleto) =>
              setContaChamada({ ...contaChamada, setNumeroDestinoCompleto })
            }
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-3'>
          <DatePicker
            label='Data'
            text={contaChamada.dtChamada}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtChamada) => {
              setContaChamada({ ...contaChamada, dtChamada });
            }}
          />
        </div>

        <div className='col-3'>
          <Textbox
            label='Hora'
            text={contaChamada.horaChamada}
            mask={MaskTypes.Time}
            onChangedValue={(horaChamada) =>
              setContaChamada({ ...contaChamada, horaChamada })
            }
          />
        </div>

        <div className='col-3'>
          <Textbox
            label='Duração'
            text={contaChamada.duracaoXLS}
            mask={MaskTypes.Time}
            onChangedValue={(duracaoXLS) =>
              setContaChamada({ ...contaChamada, duracaoXLS })
            }
          />
        </div>

        <div className='col-3'>
          <Textbox
            label='Duração Conv'
            text={contaChamada.duracaoXLSConv}
            mask={MaskTypes.Time}
            onChangedValue={(duracaoXLSConv) =>
              setContaChamada({ ...contaChamada, duracaoXLSConv })
            }
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-3'>
          <Textbox
            label='Valor'
            text={contaChamada.vlrChamadaXLS}
            mask={MaskTypes.Decimal}
            onChangedValue={(vlrChamadaXLS) =>
              setContaChamada({ ...contaChamada, vlrChamadaXLS })
            }
          />
        </div>

        <div className='col-3'>
          <Textbox
            label='Cobrado'
            text={contaChamada.vlrCobradoXLS}
            mask={MaskTypes.Decimal}
            onChangedValue={(vlrCobradoXLS) =>
              setContaChamada({ ...contaChamada, vlrCobradoXLS })
            }
          />
        </div>

        <div className='col-3'>
          <Textbox
            label='Valor Recálculo'
            text={contaChamada.vlrChamadaRecalculo}
            mask={MaskTypes.Decimal}
            onChangedValue={(vlrChamadaRecalculo) =>
              setContaChamada({ ...contaChamada, vlrChamadaRecalculo })
            }
          />
        </div>

        <div className='col-3'>
          <Textbox
            label='Valor Cobrado Recálculo'
            text={contaChamada.vlrCobradoRecalculo}
            mask={MaskTypes.Decimal}
            onChangedValue={(vlrCobradoRecalculo) =>
              setContaChamada({ ...contaChamada, vlrCobradoRecalculo })
            }
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-3'>
          <Textbox
            label='Tarifa'
            text={contaChamada.vlrTarifaOperXLS}
            mask={MaskTypes.Decimal}
            onChangedValue={(vlrTarifaOperXLS) =>
              setContaChamada({ ...contaChamada, vlrTarifaOperXLS })
            }
          />
        </div>

        <div className='col-3'>
          <Textbox
            label='Localidade'
            text={contaChamada.noLocalidadeChamadaXLS}
            onChangedValue={(noLocalidadeChamadaXLS) =>
              setContaChamada({ ...contaChamada, noLocalidadeChamadaXLS })
            }
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-4'>
          <Textbox
            label='Título'
            text={contaChamada.noCategoriaFEB}
            onChangedValue={(noCategoriaFEB) =>
              setContaChamada({ ...contaChamada, noCategoriaFEB })
            }
          />
        </div>

        <div className='col-4'>
          <Textbox
            label='Sub-Título'
            text={contaChamada.noCategoriaXLS}
            onChangedValue={(noCategoriaXLS) =>
              setContaChamada({ ...contaChamada, noCategoriaXLS })
            }
          />
        </div>

        <div className='col-4'>
          <Textbox
            label='Pré-Título'
            text={contaChamada.noTipoXLS}
            onChangedValue={(noTipoXLS) =>
              setContaChamada({ ...contaChamada, noTipoXLS })
            }
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Área'
            searchDataSource={onSearchAreaChamada}
            selectedItem={contaChamada.areaChamada}
            onSelectItem={(areaChamada) => {
              setContaChamada({
                ...contaChamada,
                areaChamada,
                nrSeqAreaChamada: areaChamada.nrSeqAreaChamada,
              });
            }}
            dataSourceTextProperty='noAreaChamada'
          />
        </div>

        <div className='col-4'>
          <Autocomplete
            label='Tipo Chamada Associado'
            searchDataSource={onSearchTipoChamada}
            selectedItem={contaChamada.tipoChamada}
            onSelectItem={(tipoChamada) => {
              setContaChamada({
                ...contaChamada,
                tipoChamada,
                nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
              });
            }}
            dataSourceTextProperty='noTipoChamada'
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
