export default class Banco {
  constructor(jsonObject = {}) {
    this.cdBanco = jsonObject.cdBanco;
    this.nrSeqBanco = jsonObject.nrSeqBanco;
    this.paginaWeb = jsonObject.paginaWeb;
    this.noBanco = jsonObject.noBanco;
    this.imagem = jsonObject.imagem;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.cdBancoDig = jsonObject.cdBancoDig;
    this.agenciaMascara = jsonObject.agenciaMascara;
    this.contaMascara = jsonObject.contaMascara;
  }

  toJson = () => JSON.stringify(this);
}
