export default class ChequeSituacao {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqChequeSituacao = jsonObject.nrSeqChequeSituacao;
    this.noChequeSituacao = jsonObject.noChequeSituacao;
    this.cdChequeSituacao = jsonObject.cdChequeSituacao;
    this.flgConsolidaExtrato = jsonObject.flgConsolidaExtrato;
  }

  toJson = () => JSON.stringify(this);
}
