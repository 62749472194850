import qs from 'qs';
import Pagination from 'core/models/pagination';
import CentroDeCusto from 'core/models/TEL/centroDeCusto';
import api from '../api';

const baseURL = `/TEL/CentroDeCusto`;

export const getCentroDeCustoList = async (filters) => {
  const response = await api.get(`${baseURL}/SearchList`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new CentroDeCusto(json)) ?? [],
  };
};

export const getCentroDeCustoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });

  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new CentroDeCusto(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getCentroDeCusto = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new CentroDeCusto(value) : {};
};

export const saveCentroDeCusto = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteCentroDeCusto = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const deleteAllCentroDeCusto = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getCentroDeCustoPaiAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompletePai`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new CentroDeCusto(json)) ?? [],
  };
};

export const getCentroDeCustoPaiTreeview = async (filters) => {
  const response = await api.get(`${baseURL}/Treeview`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    centros: value?.map((json) => new CentroDeCusto(json)) ?? [],
  };
};

export const printCentroDeCusto = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelCentroDeCusto = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data.value ?? {};
};

export const deleteAllPlanoDeConta = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

// Usado em outras telas
export const getCentroDeCustoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new CentroDeCusto(json)) ?? [],
  };
};

export const getCentroDeCustoAutoCompleteSimples = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteSimples`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new CentroDeCusto(json)) ?? [],
  };
};
// ===========================================================
