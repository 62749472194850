import React, { useState } from 'react';
import { Modal, Textbox, Button, Loader, Notification } from 'ui/components';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import { distribuirPedagio } from 'core/services/FRO/viagem';

export default function ModalDistribuirPedagio({
  show,
  onClose,
  updateGrid,
  data,
}) {
  const [vlrPedagio, setVlrPedagio] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onClickConfirm = async () => {
    if (!vlrPedagio) {
      setMessage({
        message: 'Necessário informar o valor do pedágio.',
        theme: Theme.Danger,
      });

      return;
    }

    setLoading(true);

    const { status, message: msg, data: datasource } = await distribuirPedagio({
      conhecimentos: data.conhecimentos,
      vlrPedagio,
    });

    if (status === ResponseStatus.Success) {
      updateGrid(datasource, msg);
    } else if (msg) {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    setLoading(false);
  };

  const onSend = () => {
    onClose();
  };

  return (
    <Modal
      show={show}
      title='Distribuir Pedágio'
      onClose={() => onSend()}
      size={BootstrapSizes.Medium}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='row mb-3'>
            <div className='alert alert-primary mx-2' role='alert'>
              <b>
                O valor informado de pedágio será distribuído entre os ctes de
                forma proporcional ao valor do frete.
              </b>
            </div>
          </div>
          <div className='row'>
            <div className='col-5'>
              <Textbox
                label='Valor Pedágio'
                required
                text={vlrPedagio}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(item) => setVlrPedagio(item)}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Medium}
          icon='check'
          theme={Theme.Success}
          template={Button.Templates.Default}
          onClick={() => onClickConfirm()}
          text='Confirmar'
        />
        <Button
          size={BootstrapSizes.Medium}
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          onClick={() => onSend()}
          text='Sair'
        />
      </Modal.Footer>
    </Modal>
  );
}
