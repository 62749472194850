export default class ColetaEmbarqueCTE {
  constructor(jsonObject = {}) {
    this.nrSeqColetaEmbarqueCTE = jsonObject.nrSeqColetaEmbarqueCTE;
    this.nrChaveCTE = jsonObject.nrChaveCTE;
    this.nrLote = jsonObject.nrLote;
    this.cdTipoSolicitacao = jsonObject.cdTipoSolicitacao;
    this.dtSolicitacao = jsonObject.dtSolicitacao;
    this.dtRecebimentoLote = jsonObject.dtRecebimentoLote;
    this.loteXMotivo = jsonObject.loteXMotivo;
    this.loteCStat = jsonObject.loteCStat;
    this.retenviCTEStat = jsonObject.retenviCTEStat;
    this.retenviCTEXMotivo = jsonObject.retenviCTEXMotivo;
    this.retenviCTENRec = jsonObject.retenviCTENRec;
    this.retenviCTEDHrecbt = jsonObject.retenviCTEDHrecbt;
    this.retconsreciCTEDHrecbto = jsonObject.retconsreciCTEDHrecbto;
    this.retconsreciCTENProt = jsonObject.retconsreciCTENProt;
    this.retconsreciCTEXMotivo = jsonObject.retconsreciCTEXMotivo;
    this.retconsrecicteCStat = jsonObject.retconsrecicteCStat;
    this.retconsreciCTEDIgval = jsonObject.retconsreciCTEDIgval;
    this.serieCTE = jsonObject.serieCTE;
    this.motivoCancelamento = jsonObject.motivoCancelamento;
    this.nrSeqColetaEmbarque = jsonObject.nrSeqColetaEmbarque;
    this.nrCte = jsonObject.nrCte;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.noXml = jsonObject.noXml;
  }

  toJson = () => JSON.stringify(this);
}
