/* eslint-disable import/extensions */
import EnergiaUnidadeConsumidora from './energiaUnidadeConsumidora';

export default class EnergiaUnidadeConsumidoraUsina {
  nrSeqEnergiaUnidadeConsumidora: number;

  nrSeqEnergiaUnidadeConsumidoraUsina: number;

  status: string;

  energiaUnidadeConsumidora: EnergiaUnidadeConsumidora;

  constructor(jsonObject: EnergiaUnidadeConsumidoraUsina) {
    this.nrSeqEnergiaUnidadeConsumidora =
      jsonObject.nrSeqEnergiaUnidadeConsumidora;
    this.nrSeqEnergiaUnidadeConsumidoraUsina =
      jsonObject.nrSeqEnergiaUnidadeConsumidoraUsina;
    this.status = jsonObject.status;
    this.energiaUnidadeConsumidora = new EnergiaUnidadeConsumidora(
      jsonObject.energiaUnidadeConsumidora ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
