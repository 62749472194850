import React, { useRef, useState } from 'react';
import { PageTypes, Theme } from 'ui/Helpers/utils';
import { getInventarioPaginated } from 'core/services/EST/inventario';
import {
  Autocomplete,
  CSDSelPage,
  DatePicker,
  GridView,
  Panel,
  Textbox,
} from 'ui/components';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import { MaskTypes } from 'ui/Helpers/masks';

const columns = [
  {
    key: 'nrSeqInventario',
    type: GridView.ColumnTypes.Checkbox,
    visible: false,
  },
  { key: 'cdInventario', title: 'Código' },
  { key: 'noInventario', title: 'Inventário' },
  { key: 'dtInicio', title: 'Data Início', format: GridView.DataTypes.Date },
  { key: 'dtFim', title: 'Data Fim', format: GridView.DataTypes.Date },
];

export default function Inventario({ transaction, onOpenPage, isActive }) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getInventarioPaginated(
      params ?? { ...filtros, flgPendente: true }
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'InventarioContagem/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqInventario = sourceRow.find((e) => e.key === 'nrSeqInventario');
    onOpenMaintenance(nrSeqInventario.value);
  };

  const onSearchEmpresa = async (e) => {
    const { empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });

    return empresas;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Inventário Contagem'
      loading={loading}
      onSearch={() => search()}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Filtros' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3 align-items-end'>
              <div className='col-3'>
                <Textbox
                  label='Nome'
                  text={filtros.noInventario}
                  maxLength={60}
                  onChangedValue={(noInventario) =>
                    setFiltros({ ...filtros, noInventario })
                  }
                />
              </div>
              <div className='col'>
                <Autocomplete
                  label='Empresa'
                  searchDataSource={onSearchEmpresa}
                  selectedItem={filtros.empresa}
                  onSelectItem={(empresa) =>
                    setFiltros({
                      ...filtros,
                      empresa,
                      nrSeqEmpresa: empresa.nrSeqEmpresa,
                    })
                  }
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-4'>
                <div className='row'>
                  <div className='col'>
                    <DatePicker
                      label='Data Inicial'
                      text={filtros.dtInicial}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtInicial) =>
                        setFiltros({ ...filtros, dtInicial })
                      }
                    />
                  </div>
                  <div className='col'>
                    <DatePicker
                      label='Data Final'
                      text={filtros.dtFinal}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtFinal) =>
                        setFiltros({ ...filtros, dtFinal })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
