import { TipoChamada } from 'core/models/TEL';
import api from '../api';

export const getTipoChamadaAutoComplete = async (filters) => {
  const response = await api.get(`/TipoChamada/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    tiposchamadas: value?.map((json) => new TipoChamada(json)) ?? [],
  };
};

export const getTipoChamadaAutoCompleteResumido = async (filters) => {
  const response = await api.get(`/TipoChamada/AutoCompleteResumido`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    tiposChamadas: value?.map((json) => new TipoChamada(json)) ?? [],
  };
};

export const getPacotesAdicionaisAutoComplete = async (filters) => {
  const response = await api.get(`/TipoChamada/AutoCompletePacotesAdicionais`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    tiposChamadas: value?.map((json) => new TipoChamada(json)) ?? [],
  };
};

export const searchPlanoOperadora = async (filters) => {
  const response = await api.get(`/TipoChamada/SearchPlanoOperadora`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    tiposChamada: value?.map((json) => new TipoChamada(json)) ?? [],
  };
};

export const searchPacoteDados = async (filters) => {
  const response = await api.get(`/TipoChamada/SearchPacoteDados`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    tiposChamada: value?.map((json) => new TipoChamada(json)) ?? [],
  };
};
