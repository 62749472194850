import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Textbox, Autocomplete, Switch } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getVeiculoTipo,
  saveVeiculoTipo,
  deleteVeiculoTipo,
  getTipoVeiculoMdfeAutoComplete,
} from 'core/services/FRO/veiculoTipo';

import { getVeiculoFuncaoAutoComplete } from 'core/services/FRO/veiculoFuncao';

export default function VeiculoTipoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onNew = () => {
    setData({
      flgPrincipal: false,
      flgAceitaFerramenta: false,
      flgAceitaVinculo: false,
    });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      setData(await getVeiculoTipo(registryKey));
      setLoading(false);
    } else {
      onNew();
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveVeiculoTipo(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const { status, message: msg } = await deleteVeiculoTipo(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchVeiculoFuncao = async (e) => {
    const {
      status,
      message: msg,
      data: veiculoFuncaos,
    } = await getVeiculoFuncaoAutoComplete({ noVeiculoFuncao: e });
    if (msg) onSetMessage(status, msg);
    return veiculoFuncaos;
  };

  const onSearchTipoVeiculoMdfe = async (e) => {
    const {
      status,
      message: msg,
      data: tipoveiculomdfe,
    } = await getTipoVeiculoMdfeAutoComplete(e);
    if (msg) onSetMessage(status, msg);
    return tipoveiculomdfe;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Veiculo Tipo'
      transaction={transaction}
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      onDelete={data?.nrSeqVeiculoTipo > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <div className='row .mb-3'>
        <div className='col-2'>
          <Textbox
            maxLength={18}
            label='Código'
            text={data.cdVeiculoTipo}
            onChangedValue={(cdVeiculoTipo) =>
              setData({ ...data, cdVeiculoTipo })
            }
          />
        </div>
        <div className='col-4'>
          <Textbox
            required
            maxLength={60}
            label='Nome '
            text={data.noVeiculoTipo}
            onChangedValue={(noVeiculoTipo) =>
              setData({ ...data, noVeiculoTipo })
            }
          />
        </div>
        <div className='col-4'>
          <div className='row'>
            <div className='col'>
              <Switch
                formControl
                label='Principal'
                checked={data.flgPrincipal}
                onChange={(flgPrincipal) => setData({ ...data, flgPrincipal })}
              />
            </div>
            <div className='col'>
              <Switch
                formControl
                label='Aceita Vínculo'
                checked={data.flgAceitaVinculo}
                onChange={(flgAceitaVinculo) =>
                  setData({ ...data, flgAceitaVinculo })
                }
              />
            </div>
            <div className='col'>
              <Switch
                formControl
                label='Aceita Ferramenta'
                checked={data.flgAceitaFerramenta}
                onChange={(flgAceitaFerramenta) =>
                  setData({ ...data, flgAceitaFerramenta })
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            required
            label='Tipo Rodado MDFE'
            searchDataSource={onSearchTipoVeiculoMdfe}
            selectedItem={data.tipoVeiculoMdfe}
            onSelectItem={(tipoVeiculoMdfe) => {
              setData({
                ...data,
                tipoVeiculoMdfe,
                tipoRodadoMdfe: tipoVeiculoMdfe.cdNoTipoVeiculoMDFE,
              });
            }}
            dataSourceTextProperty='noTipoVeiculoMDFE'
          />
        </div>
        <div className='col-2'>
          <Textbox
            required
            label='Número Eixos'
            text={data.nrEixos}
            mask={MaskTypes.Integer}
            onChangedValue={(nrEixos) => setData({ ...data, nrEixos })}
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            required
            label='Veiculo Função'
            searchDataSource={onSearchVeiculoFuncao}
            selectedItem={data.veiculoFuncao}
            onSelectItem={(veiculoFuncao) => {
              setData({
                ...data,
                veiculoFuncao,
                nrSeqVeiculoFuncao: veiculoFuncao.nrSeqVeiculoFuncao,
              });
            }}
            dataSourceTextProperty='noVeiculoFuncao'
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
