import React, { useEffect, useRef, useState } from 'react';
import {
  Theme,
  MimeTypes,
  ResponseStatus,
  BootstrapSizes,
  JustifyContent,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  CSDSelPage,
  GridView,
  FileUpload,
  Button,
  Autocomplete,
  ToolbarButtons,
  Textbox,
  Panel,
} from 'ui/components';

import {
  postUploadImportacao,
  getImportacaoPagined,
  getImportacaoAutoComplete,
  saveImportacao,
  saveTabela,
  getImportacao,
  excelImportacao,
} from 'core/services/SEG/importacao';
import { getImportacaoCampoList } from 'core/services/SEG/importacaoCampo';
import { getImportacaoSequenciaList } from 'core/services/SEG/importacaoSequencia';
import ImportacaoSequencia from 'core/models/SEG/importacaoSequencia';

export default function Importacao({ transaction, isActive, show }) {
  const gridView = useRef();
  const gridViewSequencia = useRef();
  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [importedFile, setImportedFile] = useState([]);
  const [dataSequencia, setDataSequencia] = useState(
    new ImportacaoSequencia({})
  );

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async () => {
    setLoading(true);

    const { data } = await getImportacaoPagined();
    return data;

    // if (gridView && gridView.current)
    //   gridView.current.setDataSource(data, pagination);
  };

  useEffect(() => {
    setImportedFile([]);
  }, [show]);

  const saveTable = async () => {
    setLoading(true);

    if (filtros.noImportacao === undefined) {
      onSetMessage(ResponseStatus.Error, 'Informar o nome da importação');
      setLoading(false);
      return false;
    }

    if (filtros.noTabela === undefined) {
      onSetMessage(ResponseStatus.Error, 'Informar o nome da tabela');
      setLoading(false);
      return false;
    }
    const { status, message: msg } = await saveTabela({
      noTabela: filtros.noTabela,
      noImportacao: filtros.noImportacao,
      flgAuxiliar: false,
    });

    if (status === ResponseStatus.Success) {
      setFiltros({});
      if (gridView && gridView.current) gridView.current.setDataSource([]);
    }

    setFiltros(status === ResponseStatus.Success ? {} : filtros);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);

    return true;
  };

  const onDownloadExcel = async () => {
    setLoading(true);
    const { value: base64 } = await excelImportacao(filtros);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'Importacao.csv';
      downloadLink.click();
    }

    setLoading(false);
    return true;
  };

  const saveCampos = async () => {
    setLoading(true);

    let importacaoCampos;

    if (gridView && gridView.current) {
      importacaoCampos = gridView.current.getDataSource();

      const transformedCampos = importacaoCampos.map((item) => {
        item.flgSequencia = item.flgSequencia === 'S';
        item.flgIgnorarSeVazio = item.flgIgnorarSeVazio === 'S';
        item.flgUnico = item.flgUnico === 'S';

        return item;
      });

      importacaoCampos = transformedCampos;
    }

    let importacaoSequencia;

    if (gridViewSequencia && gridViewSequencia.current) {
      importacaoSequencia = gridViewSequencia.current.getDataSource();
    }

    const obj = {
      ...filtros,
      importacaoCampos,
      importacaoSequencia,
    };

    const { status, message: msg } = await saveImportacao(obj);

    if (status === ResponseStatus.Success) {
      setFiltros({});
      if (gridView && gridView.current) gridView.current.setDataSource([]);
      if (gridViewSequencia && gridViewSequencia.current)
        gridViewSequencia.current.setDataSource([]);
    }

    setFiltros(status === ResponseStatus.Success ? {} : filtros);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    //  const selecteds = gridView.current.getCheckBoxValuesAt(0);

    // if (selecteds.length > 0) {
    //   const { status, message: msg } = await deleteAllCidade(
    //    selecteds.map((row) => row[0].value)
    //   );

    setLoading(false);
    /*  setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
*/
    await search();
    // } else {
    setLoading(false);
    setMessage({
      message: 'Não há registros selecionados para a exclusão.',
      theme: Theme.Danger,
    });
    // }
  };

  const onClickAddSequencia = () => {
    const list = gridViewSequencia.current.getDataSource();
    dataSequencia.status = GridView.EnumStatus.Inserir;
    dataSequencia.nrSeqImportacao = filtros.nrSeqImportacao;

    list.push(dataSequencia);
    if (gridViewSequencia && gridViewSequencia.current)
      gridViewSequencia.current.setDataSource(list);
  };

  const onSearchImportacao = async (e) => {
    const {
      status,
      message: msg,
      data: importacao,
    } = await getImportacaoAutoComplete({
      noImportacao: e,
    });

    if (msg) setMessage(status, msg);
    return importacao;
  };

  const onSearchImportacaoVinculada = async (e) => {
    const {
      status,
      message: msg,
      data: importacao,
    } = await getImportacaoAutoComplete({
      noImportacao: e,
    });

    if (msg) setMessage(status, msg);
    return importacao;
  };

  const uploadFile = async () => {
    setLoading(true);

    if (!importedFile)
      setMessage({
        message: 'Não há arquivos selecionados para importação.',
        theme: Theme.Danger,
      });
    else {
      const { status, message: msg } = await postUploadImportacao(
        filtros.nrSeqImportacao,
        importedFile[0]
      );

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onClickAddCampo = () => {
    const list = gridView.current.getDataSource();

    if (!filtros.status) filtros.status = GridView.EnumStatus.Inserir;

    const novoFiltro = { ...filtros };

    list.push(novoFiltro);

    if (gridView && gridView.current) gridView.current.setDataSource(list);
  };

  const onRemover = async (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);
  };

  const onRemoverSequencia = async (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (gridViewSequencia && gridViewSequencia.current)
      gridViewSequencia.current.setDataSource(datasource);
  };

  const fNoCampo = function (keyValue, value) {
    const datasource = gridView.current.getDataSource();
    datasource.forEach((item) => {
      if (item.noCampo === keyValue) {
        item.noCampo = value;
      }
    });
    if (gridView && gridView.current) {
      gridView.current.setDataSource(datasource);
    }
  };

  const fOrdem = function (keyValue, value) {
    const datasource = gridView.current.getDataSource();
    datasource.forEach((item) => {
      if (item.noCampo === keyValue) {
        item.nrOrdem = parseInt(value, 10);
      }
    });
    if (gridView && gridView.current) {
      gridView.current.setDataSource(datasource);
    }
  };

  const fTabelaEstrangeira = function (keyValue, value) {
    const datasource = gridView.current.getDataSource();
    datasource.forEach((item) => {
      if (item.noCampo === keyValue) {
        item.noTabelaEstrangeira = value;
      }
    });
    if (gridView && gridView.current) {
      gridView.current.setDataSource(datasource);
    }
  };

  const fEstrangeiraCampo = function (keyValue, value) {
    const datasource = gridView.current.getDataSource();
    datasource.forEach((item) => {
      if (item.noCampo === keyValue) {
        item.noTabelaEstrangeiraCampo = value;
      }
    });
    if (gridView && gridView.current) {
      gridView.current.setDataSource(datasource);
    }
  };

  const fEstranegeiraChave = function (keyValue, value) {
    const datasource = gridView.current.getDataSource();
    datasource.forEach((item) => {
      if (item.noCampo === keyValue) {
        item.noTabelaEstrangeiraChave = value;
      }
    });
    if (gridView && gridView.current) {
      gridView.current.setDataSource(datasource);
    }
  };

  const fTipo = function (keyValue, value) {
    const datasource = gridView.current.getDataSource();
    datasource.forEach((item) => {
      if (item.noCampo === keyValue) {
        item.noTipo = value;
      }
    });
    if (gridView && gridView.current) {
      gridView.current.setDataSource(datasource);
    }
  };

  const fValorPadrao = function (keyValue, value) {
    const datasource = gridView.current.getDataSource();
    datasource.forEach((item) => {
      if (item.noCampo === keyValue) {
        item.noValorPadrao = value;
      }
    });
    if (gridView && gridView.current) {
      gridView.current.setDataSource(datasource);
    }
  };

  const fSequencia = function (keyValue, value) {
    const datasource = gridView.current.getDataSource();
    datasource.forEach((item) => {
      if (item.noCampo === keyValue) {
        item.flgSequencia = value;
      }
    });
    if (gridView && gridView.current) {
      gridView.current.setDataSource(datasource);
    }
  };

  const fIgnorarSeVazio = function (keyValue, value) {
    const datasource = gridView.current.getDataSource();
    datasource.forEach((item) => {
      if (item.noCampo === keyValue) {
        item.flgIgnorarSeVazio = value;
      }
    });
    if (gridView && gridView.current) {
      gridView.current.setDataSource(datasource);
    }
  };

  const fUnico = function (keyValue, value) {
    const datasource = gridView.current.getDataSource();
    datasource.forEach((item) => {
      if (item.noCampo === keyValue) {
        item.flgUnico = value;
      }
    });
    if (gridView && gridView.current) {
      gridView.current.setDataSource(datasource);
    }
  };

  const columns = [
    {
      key: 'noCampo',
      title: 'Campo',
      type: GridView.ColumnTypes.Textbox,
      format: GridView.DataTypes.DecimalCustom,
      maxLength: 40,
      onBlur: fNoCampo,
    },
    {
      key: 'nrOrdem',
      title: 'Ordem',
      type: GridView.ColumnTypes.Textbox,
      format: GridView.DataTypes.DecimalCustom,
      maxLength: 40,
      onBlur: fOrdem,
    },
    {
      key: 'noTabelaEstrangeira',
      title: 'Tabela Estrangeira',
      type: GridView.ColumnTypes.Textbox,
      format: GridView.DataTypes.DecimalCustom,
      maxLength: 40,
      onBlur: fTabelaEstrangeira,
    },
    {
      key: 'noTabelaEstrangeiraCampo',
      title: 'Tabela Estrangeira Campo',
      type: GridView.ColumnTypes.Textbox,
      format: GridView.DataTypes.DecimalCustom,
      maxLength: 40,
      onBlur: fEstrangeiraCampo,
    },
    {
      key: 'noTabelaEstrangeiraChave',
      title: 'Chave Tabela Estrangeira',
      type: GridView.ColumnTypes.Textbox,
      format: GridView.DataTypes.DecimalCustom,
      maxLength: 40,
      onBlur: fEstranegeiraChave,
    },
    {
      key: 'noTipo',
      title: 'Tipo',
      type: GridView.ColumnTypes.Textbox,
      format: GridView.DataTypes.DecimalCustom,
      maxLength: 40,
      onBlur: fTipo,
    },
    {
      key: 'noValorPadrao',
      title: 'Valor Padrão',
      type: GridView.ColumnTypes.Textbox,
      format: GridView.DataTypes.DecimalCustom,
      maxLength: 40,
      onBlur: fValorPadrao,
    },
    {
      key: 'flgSequencia',
      title: 'Sequencia (S/N)',
      type: GridView.ColumnTypes.Textbox,
      format: GridView.DataTypes.DecimalCustom,
      maxLength: 40,
      onBlur: fSequencia,
    },
    {
      key: 'flgIgnorarSeVazio',
      title: 'Ignorar Vazio (S/N)',
      type: GridView.ColumnTypes.Textbox,
      format: GridView.DataTypes.DecimalCustom,
      maxLength: 40,
      onBlur: fIgnorarSeVazio,
    },
    {
      key: 'flgUnico',
      title: 'Único (S/N)',
      type: GridView.ColumnTypes.Textbox,
      format: GridView.DataTypes.DecimalCustom,
      maxLength: 40,
      onBlur: fUnico,
    },
    {
      key: 'nrSeqImportacaoCampo',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemover(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsSequencia = [
    {
      key: 'importacao.noImportacao',
      title: 'Importacao Vinculada',
    },
    {
      key: 'nrOrdem',
      title: 'Ordem',
    },
    {
      key: 'nrSeqImportacaoCampo',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoverSequencia(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Importação'
      loading={loading}
      onSearch={() => search()}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Salvar'
          icon={['fa', 'save']}
          onClick={() => saveCampos()}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col-4 mb-3'>
          <Autocomplete
            label='Importação'
            searchDataSource={onSearchImportacao}
            selectedItem={filtros.importacao}
            onSelectItem={async (importacao) => {
              if (importacao.nrSeqImportacao !== undefined) {
                const importacoes = await getImportacao(
                  importacao.nrSeqImportacao
                );
                const { data: importacaoCampo } = await getImportacaoCampoList({
                  nrSeqImportacao: importacao.nrSeqImportacao,
                });

                const updatedImportacaoCampo = importacaoCampo.map((item) => {
                  item.flgSequencia = item.flgSequencia ? 'S' : 'N';
                  item.flgIgnorarSeVazio = item.flgIgnorarSeVazio ? 'S' : 'N';
                  item.flgUnico = item.flgUnico ? 'S' : 'N';
                  return item;
                });

                const {
                  data: importacaoSequencia,
                } = await getImportacaoSequenciaList({
                  nrSeqImportacao: importacao.nrSeqImportacao,
                });

                gridView.current.setDataSource(updatedImportacaoCampo);
                gridViewSequencia.current.setDataSource(importacaoSequencia);
                setFiltros(importacoes);
              } else {
                gridView.current.setDataSource([]);
                gridViewSequencia.current.setDataSource([]);
              }
            }}
            dataSourceTextProperty='noImportacao'
          />
        </div>
        <div className='col-3'>
          <Button
            outline
            size={BootstrapSizes.Medium}
            theme={Theme.Success}
            template={Button.Templates.Quick}
            onClick={onDownloadExcel}
            text='Excel da Importação'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Textbox
            label='Nome da Importação'
            text={filtros.noImportacao}
            onChangedValue={(noImportacao) =>
              setFiltros({ ...filtros, noImportacao })
            }
          />
        </div>
        <div className='col-4'>
          <Textbox
            label='Nome da Tabela'
            text={filtros.noTabela}
            onChangedValue={(noTabela) => setFiltros({ ...filtros, noTabela })}
          />
        </div>
        <div className='col-3 mt-2'>
          <Button
            outline
            size={BootstrapSizes.Medium}
            theme={Theme.Success}
            template={Button.Templates.Quick}
            onClick={saveTable}
            text='Criar Tabela'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header
            icon={['fas', 'shopping-basket']}
            title='Vínculo'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='row mb-3'>
                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Importação Vinculada'
                    searchDataSource={onSearchImportacaoVinculada}
                    selectedItem={dataSequencia.importacao}
                    onSelectItem={(importacao) => {
                      setDataSequencia({
                        ...dataSequencia,
                        importacao,
                        nrSeqImportacaoVinculada: importacao.nrSeqImportacao,
                      });
                    }}
                    dataSourceTextProperty='noImportacao'
                  />
                </div>
                <div className='col-3'>
                  <Textbox
                    label='Número da Ordem'
                    text={dataSequencia.nrOrdem}
                    mask={MaskTypes.Integer}
                    onChangedValue={(nrOrdem) =>
                      setDataSequencia({ ...dataSequencia, nrOrdem })
                    }
                  />
                </div>
                <div className='col-3 mt-2'>
                  <Button
                    outline
                    size={BootstrapSizes.Medium}
                    theme={Theme.Success}
                    template={Button.Templates.Quick}
                    onClick={onClickAddSequencia}
                    text='Adicionar Sequência'
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <GridView
                    ref={gridViewSequencia}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsSequencia}
                    transaction={transaction}
                  />
                </div>
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            transaction={transaction}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-3 mt-2'>
          <Button
            outline
            size={BootstrapSizes.Medium}
            theme={Theme.Success}
            template={Button.Templates.Quick}
            onClick={onClickAddCampo}
            text='Criar Campo'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-'>
          <FileUpload
            files={importedFile}
            onChange={(files) => setImportedFile(files)}
            allowedMimeTypes={[MimeTypes.Types.Txt]}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-3'>
          <Button
            text='Carregar Arquivo'
            icon='folder-open'
            theme={Theme.Success}
            onClick={uploadFile}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
