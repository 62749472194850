import Veiculo from 'core/models/FRO/veiculo';

export default class ManutencaoChecklistFrotaVeiculo {
  constructor(jsonObject = {}) {
    this.nrSeqManutencaoChecklistFrotaVeiculo =
      jsonObject.nrSeqManutencaoChecklistFrotaVeiculo;
    this.nrSeqManutencaoChecklist = jsonObject.nrSeqManutencaoChecklist;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.nrOdometro = jsonObject.nrOdometro;

    // #region Propriedades Auxiliares
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
