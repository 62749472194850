import { Pessoa } from '../SEG';
import FormaPagamento from './formaPagamento';
import TipoPagamento from './tipoPagamento';
import TituloPagar from './tituloPagar';

export default class Pagamento {
  constructor(jsonObject = {}) {
    this.nrSeqPagamento = jsonObject.nrSeqPagamento;
    this.nrSeqExtratoCategoria = jsonObject.nrSeqExtratoCategoria;
    this.vlrTotal = jsonObject.vlrTotal;
    this.nrSeqContaCorrente = jsonObject.nrSeqContaCorrente;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.dtPagamento = jsonObject.dtPagamento;
    this.nrIdentificado = jsonObject.nrIdentificado;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqExtrato = jsonObject.nrSeqExtrato;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqTitulopagar = jsonObject.nrSeqTitulopagar;
    this.vlrMulta = jsonObject.vlrMulta;
    this.vlrSaldoPago = jsonObject.vlrSaldoPago;
    this.vlrJuro = jsonObject.vlrJuro;
    this.nrSeqCheque = jsonObject.nrSeqCheque;
    this.nrSeqPagamentoMassivo = jsonObject.nrSeqPagamentoMassivo;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.nrSeqTipoPagamento = jsonObject.nrSeqTipoPagamento;
    this.pagamento = jsonObject.pagamento;
    this.formaPagamento = new FormaPagamento(jsonObject.formaPagamento ?? {});
    this.tipoPagamento = new TipoPagamento(jsonObject.tipoPagamento ?? {});
    this.tituloPagar = new TituloPagar(jsonObject.tituloPagar ?? {});
    this.pessoa = new Pessoa(jsonObject.pessoa ?? {});
    this.vlrSaldo = jsonObject.vlrSaldo;
    this.dtCheque = jsonObject.dtCheque;
    this.nrCheque = jsonObject.nrCheque;
    this.noCheque = jsonObject.noCheque;
  }

  toJson = () => JSON.stringify(this);
}
