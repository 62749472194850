export default class PlanoConvenioConvenioEncargo {
  constructor(jsonObject = {}) {
    this.nrSeqPlanoConvEncargoConv = jsonObject.nrSeqPlanoConvEncargoConv;
    this.nrSeqPlanoConvenio = jsonObject.nrSeqPlanoConvenio;
    this.nrSeqConvenioEncargo = jsonObject.nrSeqConvenioEncargo;
    this.vlrOriginal = jsonObject.vlrOriginal;
    this.vlrRecalculado = jsonObject.vlrRecalculado;
    this.flgRepassa = jsonObject.flgRepassa;
    this.flgExibeExtrato = jsonObject.flgExibeExtrato;
    this.flgPercentual = jsonObject.flgPercentual;
    this.flgAplicaValorAjuste = jsonObject.flgAplicaValorAjuste;
    this.flgRecalculo_IncluiVlrOriginal =
      jsonObject.flgRecalculo_IncluiVlrOriginal;
    this.flgGeraComissao = jsonObject.flgGeraComissao;
    this.flgRepassaVlrNegativo = jsonObject.flgRepassaVlrNegativo;
    this.nrSeqTituloEspecie = jsonObject.nrSeqTituloEspecie;

    this.noPlanoConvEncargo = jsonObject.noPlanoConvEncargo;
    this.planoConvenio = jsonObject.planoConvenio ?? {};
    this.convenioEncargo = jsonObject.convenioEncargo ?? {};
  }

  toJson = () => JSON.stringify(this);
}
