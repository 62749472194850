import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Textbox, Switch } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getPocPocoRevestimento,
  savePocPocoRevestimento,
  deletePocPocoRevestimento,
} from 'core/services/POC/pocPocoRevestimento';

import { getPocPocoAutoComplete } from 'core/services/POC/pocPoco';
import { getPocTipoPrefiltroAutoComplete } from 'core/services/POC/tipoPrefiltro';
import { getMaterialRevestimentoAutoComplete } from 'core/services/POC/materialRevestimento';

export default function PocPocoRevestimentoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getPocPocoRevestimento(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await savePocPocoRevestimento(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deletePocPocoRevestimento(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchPocPoco = async (e) => {
    const { status, message: msg, pocPocos } = await getPocPocoAutoComplete({
      noPocPoco: e,
    });
    if (msg) onSetMessage(status, msg);
    return pocPocos;
  };

  const onSearchPocTipoPrefiltro = async (e) => {
    const {
      status,
      message: msg,
      pocTipoPrefiltros,
    } = await getPocTipoPrefiltroAutoComplete({ noPocTipoPrefiltro: e });
    if (msg) onSetMessage(status, msg);
    return pocTipoPrefiltros;
  };

  const onSearchMaterialRevestimento = async (e) => {
    const {
      status,
      message: msg,
      materialRevestimentos,
    } = await getMaterialRevestimentoAutoComplete({
      noMaterialRevestimento: e,
    });
    if (msg) onSetMessage(status, msg);
    return materialRevestimentos;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de PocPocoRevestimento'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqPocPocoRevestimento > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='PocPoco'
            searchDataSource={onSearchPocPoco}
            selectedItem={data.pocPoco}
            onSelectItem={(pocPoco) => {
              setData({
                ...data,
                pocPoco,
                nrSeqPocPoco: pocPoco.nrSeqPocPoco,
              });
            }}
            dataSourceTextProperty='noPocPoco'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            label='NrInicio'
            required
            text={data.nrInicio}
            mask={MaskTypes.Decimal}
            onChangedValue={(nrInicio) => setData({ ...data, nrInicio })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            label='NrFim'
            required
            text={data.nrFim}
            mask={MaskTypes.Decimal}
            onChangedValue={(nrFim) => setData({ ...data, nrFim })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='FlgTipoObjeto'
            checked={data.flgTipoObjeto}
            onChange={(flgTipoObjeto) => setData({ ...data, flgTipoObjeto })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={8}
            label='NrDiametropol'
            text={data.nrDiametropol}
            onChangedValue={(nrDiametropol) =>
              setData({ ...data, nrDiametropol })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            label='NrDiametromm'
            required
            text={data.nrDiametromm}
            mask={MaskTypes.Decimal}
            onChangedValue={(nrDiametromm) =>
              setData({ ...data, nrDiametromm })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='PocTipoPrefiltro'
            searchDataSource={onSearchPocTipoPrefiltro}
            selectedItem={data.pocTipoPrefiltro}
            onSelectItem={(pocTipoPrefiltro) => {
              setData({
                ...data,
                pocTipoPrefiltro,
                nrSeqPocTipoPrefiltro: pocTipoPrefiltro.nrSeqPocTipoPrefiltro,
              });
            }}
            dataSourceTextProperty='noPocTipoPrefiltro'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Autocomplete
            label='MaterialRevestimento'
            searchDataSource={onSearchMaterialRevestimento}
            selectedItem={data.materialRevestimento}
            onSelectItem={(materialRevestimento) => {
              setData({
                ...data,
                materialRevestimento,
                nrSeqMaterialRevestimento:
                  materialRevestimento.nrSeqMaterialRevestimento,
              });
            }}
            dataSourceTextProperty='noMaterialRevestimento'
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
