import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getPaisAutoComplete,
  getPaisPagined,
  printPais,
  excelPais,
} from 'core/services/SEG/pais';
import { CSDSelPage, GridView, Autocomplete } from 'ui/components';

const columns = [
  { key: 'nrSeqPais', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdPais', title: 'Código País' },
  { key: 'noPais', title: 'Pais' },
  { key: 'cdIbge', title: 'Código Ibge' },
];

export default function Pais({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [pais, setPais] = useState();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getPaisPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getPaisPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Pais/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqPais = sourceRow.find((e) => e.key === 'nrSeqPais');
    onOpenMaintenance(nrSeqPais.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printPais(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onDownloadExcel = async () => {
    setLoading(true);

    const { value: base64 } = await excelPais(filtros);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'Pais.csv';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onSearchPais = async (e) => {
    const { status, message: msg, paises } = await getPaisAutoComplete({
      noPais: e,
    });

    setPais(paises);
    onSetMessage(status, msg);
    return paises;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de País'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onPrint={onPrint}
      onDownloadExcel={onDownloadExcel}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='País'
            searchDataSource={onSearchPais}
            selectedItem={pais}
            onSelectItem={(obj) => {
              setFiltros(obj);
            }}
            dataSourceTextProperty='noPais'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
