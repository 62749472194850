import PocProdOrcamento from 'core/models/POC/pocProdOrcamento';

export default class PocOrcamentoProd {
  nrSeqPocOrcamento: number;

  nrSeqPocProdOrcamento: number;

  qtdeProOrcamento: number;

  vlrUnitario: number;

  vlrTotal: number;

  nrSeqPocOrcamentoProd: number;

  noProdComplemento: string;

  flgIncluirOrcamento: boolean;

  produto: PocProdOrcamento;

  vlrDesconto: number;

  flgCustoCliente: boolean;

  flgRemovido: boolean;

  nrOrdem: number;

  constructor(jsonObject: PocOrcamentoProd) {
    this.nrSeqPocOrcamento = jsonObject.nrSeqPocOrcamento;
    this.nrSeqPocProdOrcamento = jsonObject.nrSeqPocProdOrcamento;
    this.qtdeProOrcamento = jsonObject.qtdeProOrcamento;
    this.vlrUnitario = jsonObject.vlrUnitario;
    this.vlrTotal = jsonObject.vlrTotal;
    this.nrSeqPocOrcamentoProd = jsonObject.nrSeqPocOrcamentoProd;
    this.noProdComplemento = jsonObject.noProdComplemento;
    this.flgIncluirOrcamento = jsonObject.flgIncluirOrcamento;
    this.vlrDesconto = jsonObject.vlrDesconto;
    this.flgCustoCliente = jsonObject.flgCustoCliente;
    this.flgRemovido = jsonObject.flgRemovido;
    this.nrOrdem = jsonObject.nrOrdem;

    this.produto = new PocProdOrcamento(jsonObject.produto ?? {});
  }

  toJson = () => JSON.stringify(this);
}
