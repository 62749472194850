import ResumoSinteticoFinanceiroRecurso from './resumoSinteticoFinanceiroRecurso';

export default class ResumoSinteticoFinanceiro {
  constructor(jsonObject = {}) {
    // Propriedades de Fatura
    this.valorPagoOperadora = jsonObject.valorPagoOperadora;
    this.valorContestacaoDentroFatura = jsonObject.valorContestacaoDentroFatura;
    this.valorContestacaoForaFatura = jsonObject.valorContestacaoForaFatura;
    this.valorPagoOperadoraFinal = jsonObject.valorPagoOperadoraFinal;
    this.valorTotalOriginal = jsonObject.valorTotalOriginal;
    this.valorTotalRecalculado = jsonObject.valorTotalRecalculado;
    this.quantidadeDeLinhas = jsonObject.quantidadeDeLinhas;
    // Propriedades de Linhas
    this.resultadoCalculado = jsonObject.resultadoCalculado;
    this.resultadoFinal = jsonObject.resultadoFinal;
    this.resultadoRateio = jsonObject.resultadoRateio;
    this.totalValorRecalculo = jsonObject.totalValorRecalculo;
    this.valorOriginalCalculado = jsonObject.valorOriginalCalculado;
    this.valorTotalRateio = jsonObject.valorTotalRateio;
    this.vlrTotalTaxaSMS = jsonObject.vlrTotalTaxaSMS;
    this.vlrTotalTaxaBancaria = jsonObject.vlrTotalTaxaBancaria;
    this.vlrTotalResultadoRateioComissao =
      jsonObject.vlrTotalResultadoRateioComissao;
    this.vlrTotalResultadoRateioComissaoBruto =
      jsonObject.vlrTotalResultadoRateioComissaoBruto;

    this.recursos = (jsonObject.recursos ?? []).map(
      (recurso) => new ResumoSinteticoFinanceiroRecurso(recurso)
    );
  }
}
