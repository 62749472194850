import React, { useState } from 'react';

import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { Button, Modal, Loader, Notification, Textbox } from 'ui/components';

import { CancelPocOrcamento } from 'core/services/POC/pocOrcamento';

export default function ModalCancelarOrcamento({ show, onClose, data }) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSendToCancel = async () => {
    setLoading(true);
    const {
      message: msg,
      status,
      data: dataOrcamento,
    } = await CancelPocOrcamento(data);
    if (status === ResponseStatus.Success) {
      onClose(msg, dataOrcamento);
    } else {
      setMessage({
        message: msg,
        theme: Theme.Danger,
      });
    }

    setLoading(false);
  };

  return (
    <Modal
      show={show}
      title='Cancelar Orçamento'
      onClose={onClose}
      size={BootstrapSizes.Large}
      icon={['far', 'comment-alt']}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='row'>
          <div className='row mb-2'>
            <Textbox
              label='Motivo do Cancelamento'
              required
              text={data.noMotivoCancelamento}
              onChangedValue={(noMotivoCancelamento) => {
                data.noMotivoCancelamento = noMotivoCancelamento;
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Confirmar'
          theme={Theme.Success}
          onClick={() => {
            onSendToCancel();
          }}
        />
        <Button
          text='Cancelar'
          theme={Theme.Danger}
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
