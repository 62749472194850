export default class EnergiaTipoTarifa {
  nrSeqEnergiaTipoTarifa: number;

  noEnergiaTipoTarifa: string;

  cdEnergiaTipoTarifa: string;

  constructor(jsonObject: EnergiaTipoTarifa) {
    this.nrSeqEnergiaTipoTarifa = jsonObject.nrSeqEnergiaTipoTarifa;
    this.noEnergiaTipoTarifa = jsonObject.noEnergiaTipoTarifa;
    this.cdEnergiaTipoTarifa = jsonObject.cdEnergiaTipoTarifa;
  }

  toJson = () => JSON.stringify(this);
}
