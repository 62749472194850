import React, { useEffect, useState } from 'react';
import { Theme } from 'ui/Helpers/utils';
import { Button } from 'ui/components';

export default function NotificacaoUsuario({ setVisualizada, notificacoes }) {
  const [itens, setItens] = useState();

  function notificacaoUsuario() {
    if (notificacoes.length === 0) return null;

    const rows = notificacoes.map((not) => (
      <Button
        key={not.nrSeqUsuarioNotificacao}
        outline
        theme={Theme.Danger}
        text={not.noMensagem}
        onClick={() => setVisualizada(not)}
      />
    ));
    setItens(rows);

    return rows;
  }

  useEffect(() => {
    (async function load() {
      notificacaoUsuario();
    })();
  }, []);

  return (
    <div>
      <div className='d-flex flex-wrap bg-light'>{itens}</div>
    </div>
  );
}
