export default class PocPocoPerfuracao {
  constructor(jsonObject = {}) {
    this.nrSeqPocPocoPerfuracao = jsonObject.nrSeqPocPocoPerfuracao;
    this.nrSeqPocPoco = jsonObject.nrSeqPocPoco;
    this.nrInicio = jsonObject.nrInicio;
    this.nrFim = jsonObject.nrFim;
    this.nrDiametropol = jsonObject.nrDiametropol;
    this.nrDiametromm = jsonObject.nrDiametromm;
    this.nrSeqPocMetodoPerfuracao = jsonObject.nrSeqPocMetodoPerfuracao;
    this.total = jsonObject.total;
    this.noPocMetodoPerfuracao = jsonObject.noPocMetodoPerfuracao;

    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
