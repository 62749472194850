import qs from 'qs';
import Pagination from 'core/models/pagination';
import DemandaIteracao from 'core/models/HELP/demandaIteracao';
import api from '../api';

const baseURL = `/HELP/DemandaIteracao`;

export const getDemandaIteracaoList = async (filters) => {
  const response = await api.get(`/$DemandaIteracao/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new DemandaIteracao(json)) ?? [],
  };
};

export const getDemandaIteracaoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new DemandaIteracao(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getDemandaIteracao = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new DemandaIteracao(value) : {};
};

export const saveDemandaIteracao = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteDemandaIteracao = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printDemandaIteracao = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelDemandaIteracao = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllDemandaIteracao = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
