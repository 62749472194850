import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  CSDSelPage,
  GridView,
  Textbox,
  Autocomplete,
  ToolbarButtons,
} from 'ui/components';
import {
  getAparelhoPagined,
  deleteAllAparelho,
} from 'core/services/TEL/aparelho';

import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getMarcaAparelhoAutoComplete } from 'core/services/TEL/marcaAparelhoCelular';
import { getAparelhoStatusAutoComplete } from 'core/services/TEL/aparelhoStatus';
import { getModeloaparelhocelularAutoComplete } from 'core/services/TEL/modeloAparelhoCelular';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';
import { pedidoAutoComplete } from 'core/services/TEL/pedidoAparelho';
import { getClienteAutoComplete } from 'core/services/FIN';
import TrocarEmpresaModal from './trocaEmpresa';

const columns = [
  { key: 'nrSeqAparelho', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdAparelho', title: 'Código Aparelho' },
  {
    key: 'marcaAparelhoCelular.noMarcaAparelho',
    title: 'Marca Aparelho',
  },
  { key: 'modeloAparelhoCelular.noModeloAparelho', title: ' Modelo Aparelho' },
  { key: 'empresa.noPessoa', title: ' Empresa' },
  { key: 'flgTipoAparelhoStr', title: 'Tipo Aparelho', visible: false },
  { key: 'vlrPrecoCusto', title: 'Preço Custo', visible: false },
  { key: 'aparelhoStatus.noAparelhoStatus', title: 'Status' },
  { key: 'nrImei', title: 'Imei' },
  { key: 'nrSerial', title: 'Serial', visible: false },
  { key: 'nrNotaFiscal', title: 'Nota Fiscal' },
  { key: 'noClienteConta', title: 'Cliente' },
  { key: 'observacao', title: 'Observação' },
  { key: 'noTituloPedido', title: 'Pedido' },
  { key: 'fornecedor.noPessoa', title: 'Fornecedor', visible: false },
  { key: 'operadora.noOperadora', title: 'Operadora', visible: false },
  {
    key: 'dtNotaFiscal',
    title: ' Data Nota Fiscal',
    format: GridView.DataTypes.Date,
  },
];

export default function Aparelho({ transaction, onOpenPage, isActive }) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({ flgTipoAparelho: 'C' });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [showTrocaEmpresa, setShowTrocaEmpresa] = useState(false);
  const [linhasSelecionadas, setLinhasSelecionadas] = useState([]);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getAparelhoPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  const onTrocarEmpresa = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);
    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Nenhuma linha selecionada para trocar de empresa.'
      );
    else {
      const linhas = selecteds.map((column) => ({
        nrSeqAparelho: column.find((prop) => prop.key === 'nrSeqAparelho')
          .value,
        cdAparelho: column.find((prop) => prop.key === 'cdAparelho').value,
        noModeloAparelho: column.find(
          (prop) => prop.key === 'modeloAparelhoCelular.noModeloAparelho'
        ).value,
        noMarcaAparelho: column.find(
          (prop) => prop.key === 'marcaAparelhoCelular.noMarcaAparelho'
        ).value,
        nrSerial: column.find((prop) => prop.key === 'nrSerial').value,
        nrImei: column.find((prop) => prop.key === 'nrImei').value,
        noOperadora: column.find((prop) => prop.key === 'operadora.noOperadora')
          .value,
        noPessoa: column.find((prop) => prop.key === 'empresa.noPessoa').value,
      }));
      setLinhasSelecionadas(linhas);
      setShowTrocaEmpresa(true, filtros);
    }
  };

  useEffect(() => {
    async function load() {
      setLoading(true);
      const { data, pagination } = await getAparelhoPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onSearchPedido = async (e) => {
    const {
      status,
      message: msg,
      data: tituloPedido,
    } = await pedidoAutoComplete({ noTituloPedido: e });
    if (msg) onSetMessage(status, msg);
    return tituloPedido;
  };

  const onSearchMarcaAparelho = async (e) => {
    const {
      status,
      message: msg,
      data: marcaAparelhos,
    } = await getMarcaAparelhoAutoComplete({ noMarcaAparelho: e });
    if (msg) onSetMessage(status, msg);
    return marcaAparelhos;
  };
  const onSearchCliente = async (e) => {
    const { clientes } = await getClienteAutoComplete({
      noPessoa: e,
      flgFiltrarAparelho: true,
    });
    return clientes;
  };
  const onSearchModeloAparelho = async (e) => {
    if (!filtros.nrSeqMarcaAparelho) {
      onSetMessage(ResponseStatus.Error, 'Marca deve ser selecionada');
      return [];
    }
    const {
      status,
      message: msg,
      data: modeloAparelhos,
    } = await getModeloaparelhocelularAutoComplete({
      noModeloAparelho: e,
      nrSeqMarcaAparelho: filtros.marcaAparelhoCelular
        ? filtros.marcaAparelhoCelular.nrSeqMarcaAparelho
        : 0,
    });
    if (msg) onSetMessage(status, msg);
    return modeloAparelhos;
  };
  const onSearchAparelhoStatus = async (e) => {
    const {
      status,
      message: msg,
      aparelho,
    } = await getAparelhoStatusAutoComplete({ noAparelhoStatus: e });
    if (msg) onSetMessage(status, msg);
    return aparelho;
  };

  const onSearchFornecedor = async (e) => {
    const {
      status,
      message: msg,
      data: fornecedor,
    } = await getFornecedorAutoComplete({
      noPessoa: e,
      nrSeqPessoaFor: e,
    });
    if (msg) onSetMessage(status, msg);
    return fornecedor;
  };
  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };
  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Aparelho/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqAparelho = sourceRow.find((e) => e.key === 'nrSeqAparelho');
    onOpenMaintenance(nrSeqAparelho.value);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllAparelho(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      transaction={transaction}
      isActive={isActive}
      title='Seleção de Aparelho'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Trocar de Empresa'
          icon='random'
          onClick={() => onTrocarEmpresa()}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col-2'>
          <Textbox
            maxLength={20}
            label='Código Aparelho'
            text={filtros.cdAparelho}
            onChangedValue={(cdAparelho) =>
              setFiltros({ ...filtros, cdAparelho })
            }
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Marca Aparelho'
            searchDataSource={onSearchMarcaAparelho}
            selectedItem={filtros.marcaAparelhoCelular}
            onSelectItem={(marcaAparelhoCelular) => {
              setFiltros({
                ...filtros,
                marcaAparelhoCelular,
                nrSeqMarcaAparelho: marcaAparelhoCelular.nrSeqMarcaAparelho,
              });
            }}
            dataSourceTextProperty='noMarcaAparelho'
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Modelo Aparelho'
            searchDataSource={onSearchModeloAparelho}
            selectedItem={filtros.modeloAparelhoCelular}
            onSelectItem={(modeloAparelhoCelular) => {
              setFiltros({
                ...filtros,
                modeloAparelhoCelular,
                nrSeqModeloAparelho: modeloAparelhoCelular.nrSeqModeloAparelho,
              });
            }}
            dataSourceTextProperty='noModeloAparelho'
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Fornecedor'
            searchDataSource={onSearchFornecedor}
            selectedItem={filtros.fornecedor}
            onSelectItem={(fornecedor) => {
              setFiltros({
                ...filtros,
                fornecedor,
                nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Empresa'
            searchDataSource={onSearchEmpresa}
            selectedItem={filtros.empresa}
            onSelectItem={(empresa) => {
              setFiltros({
                ...filtros,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Pedido'
            searchDataSource={onSearchPedido}
            selectedItem={filtros.pedidoAparelho}
            onSelectItem={(pedidoAparelho) => {
              setFiltros({
                ...filtros,
                pedidoAparelho,
                nrSeqPedidoAparelho: pedidoAparelho.nrSeqPedidoAparelho,
              });
            }}
            dataSourceTextProperty='noTituloPedido'
          />
        </div>

        <div className='col-3'>
          <Autocomplete
            label='Cliente'
            searchDataSource={onSearchCliente}
            selectedItem={filtros.cliente}
            nrseqTransactionToOpen='nrSeqPessoaCli'
            onSelectItem={(cliente) => {
              setFiltros({
                ...filtros,
                cliente,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-2'>
          <Autocomplete
            label='Aparelho Status'
            searchDataSource={onSearchAparelhoStatus}
            selectedItem={filtros.aparelhoStatus}
            onSelectItem={(aparelhoStatus) => {
              setFiltros({
                ...filtros,
                aparelhoStatus,
                nrSeqAparelhoStatus: aparelhoStatus.nrSeqAparelhoStatus,
              });
            }}
            dataSourceTextProperty='noAparelhoStatus'
          />
        </div>
      </div>
      <div className='row mb-1'>
        <div className='col-3'>
          <Textbox
            maxLength={20}
            label='Serial'
            text={filtros.nrSerial}
            onChangedValue={(nrSerial) => setFiltros({ ...filtros, nrSerial })}
          />
        </div>

        <div className='col-3'>
          <Textbox
            maxLength={20}
            label='Nota Fiscal'
            text={filtros.nrNotaFiscal}
            onChangedValue={(nrNotaFiscal) =>
              setFiltros({ ...filtros, nrNotaFiscal })
            }
          />
        </div>
        <div className='col-3'>
          <Textbox
            maxLength={30}
            label='Imei'
            text={filtros.nrImei}
            onChangedValue={(nrImei) => setFiltros({ ...filtros, nrImei })}
          />
        </div>
      </div>
      <div className='row'>
        <TrocarEmpresaModal
          linhasSelecionadas={linhasSelecionadas}
          show={showTrocaEmpresa}
          onClose={async () => {
            setShowTrocaEmpresa(false);
            setLoading(true);
            await search(filtros);
            setLoading(false);
          }}
          onSuccess={() => {
            setMessage({
              message: 'Troca de empresa realizada com sucesso.',
              theme: Theme.Success,
            });
            setShowTrocaEmpresa(false);
          }}
        />
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            transaction={transaction}
            canControlVisibility
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            enableExcelExport
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
