import React, { useEffect } from 'react';
import { Panel, Textbox, Autocomplete, Switch } from 'ui/components';
import { getCondicaoPagamentoAutoComplete } from 'core/services/FIN/condicaoPagamento';
import { getTipoVendaAutoComplete } from 'core/services/VEN/tipoVenda';
import { getTransportadoraAutoComplete } from 'core/services/FRO/transportadora';
import { getVendedorAutoComplete } from 'core/services/VEN/vendedor';
import { getPlanoDeContaAutoComplete } from 'core/services/FIN/planoDeConta';
import { getTipoEmbarqueAutoComplete } from 'core/services/FRO/tipoEmbarque';
import { getLimiteCreditoAutoComplete } from 'core/services/FIN/limiteCredito';
import { getClienteMotivoInativoAutoComplete } from 'core/services/FIN/clienteMotivoInativo';
import { getEmpresasUsuario } from 'core/services/SEG/empresa';
// @ts-expect-error
import { Theme } from 'ui/Helpers/enums.ts';
import { MaskTypes } from 'ui/Helpers/masks';

interface Props {
  clienteData: any;
  setClienteData: (object: any) => void;
  clienteEmpresa: any;
}

const ClientePanel = ({
  clienteData,
  setClienteData,
  clienteEmpresa,
}: Props) => {
  const onSearchCondicaoPagamento = async (e: any) => {
    const { condicoesPagamento } = await getCondicaoPagamentoAutoComplete({
      noCondicaoPagamento: e,
    });
    return condicoesPagamento;
  };

  const onSearchTipoVenda = async (e: any) => {
    const { tipos } = await getTipoVendaAutoComplete({
      noTipoVenda: e,
    });
    return tipos;
  };

  const onSearchTransportadora = async (e: any) => {
    const { transportadoras } = await getTransportadoraAutoComplete({
      noPessoa: e,
    });
    return transportadoras;
  };

  const onSearchVendedor = async (e: any) => {
    const { vendedores } = await getVendedorAutoComplete({
      noPessoa: e,
    });

    return vendedores;
  };

  const onSearchLimiteCredito = async (e: any) => {
    const { limiteCredito } = await getLimiteCreditoAutoComplete({
      noLimiteCredito: e,
    });

    return limiteCredito;
  };

  const onSearchClienteMotivoInativo = async (e: any) => {
    const { clienteMotivoInativo } = await getClienteMotivoInativoAutoComplete({
      noClienteMotivoInativo: e,
    });

    return clienteMotivoInativo;
  };

  const onSearchPlanoContaCred = async (e: any) => {
    const planoDeContaCred = await getPlanoDeContaAutoComplete({
      noPlanoDeConta: e,
    });

    return planoDeContaCred.planodecontas;
  };

  const onSearchTipoEmbarque = async (e: any) => {
    const { data: tiposEmbarque } = await getTipoEmbarqueAutoComplete({
      noTipoEmbarque: e,
    });

    return tiposEmbarque;
  };

  const onSearchEmpresa = async (e: any) => {
    const { empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });
    return empresas;
  };

  useEffect(() => {
    const objcliente = { ...clienteData };

    setClienteData(objcliente);
  }, [clienteData.nrSeqPessoaCli]);

  return (
    <div className='col mt-3'>
      {/* @ts-expect-error */}
      <Panel>
        {/* @ts-expect-error */}
        <Panel.Header title='Cliente' theme={Theme.Primary} />

        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-1'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={clienteData?.flgAtivo}
                label='Ativo'
                onChange={(flgAtivo: boolean) =>
                  setClienteData({
                    ...clienteData,
                    flgAtivo,
                  })
                }
              />
            </div>
            <div className='col-3'>
              {/* @ts-expect-error */}
              <Textbox
                id='txtCdCliente'
                label='Código'
                text={clienteData?.cdCliente}
                maxLength={20}
                onChangedValue={(cdCliente: string) => {
                  setClienteData({
                    ...clienteData,
                    cdCliente,
                  });
                }}
              />
            </div>
            <div className='col-3'>
              {/* @ts-expect-error */}
              <Textbox
                id='txtCdCliente02'
                label='Matrícula'
                text={clienteData?.cdCliente02}
                maxLength={20}
                onChangedValue={(cdCliente02: string) => {
                  setClienteData({
                    ...clienteData,
                    cdCliente02,
                  });
                }}
              />
            </div>
            {!clienteData.flgAtivo && (
              <div className='col-4'>
                <Autocomplete
                  label='Motivo de Inativo'
                  searchDataSource={onSearchClienteMotivoInativo}
                  selectedItem={clienteData.clienteMotivoInativo}
                  onSelectItem={(clienteMotivoInativo: any) => {
                    setClienteData({
                      ...clienteData,
                      clienteMotivoInativo,
                      nrSeqClienteMotivoInativo:
                        clienteMotivoInativo.nrSeqClienteMotivoInativo,
                    });
                  }}
                  dataSourceTextProperty='noClienteMotivoInativo'
                />
              </div>
            )}
            <div className='col-2'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={clienteData?.flgConfirmaAssinatura}
                label='Confirma Assinatura'
                onChange={(flgConfirmaAssinatura: boolean) =>
                  setClienteData({
                    ...clienteData,
                    flgConfirmaAssinatura,
                  })
                }
              />
            </div>
            <div className='col-1'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={clienteData?.flgVenda}
                label='Venda'
                onChange={(flgVenda: boolean) =>
                  setClienteData({
                    ...clienteData,
                    flgVenda,
                  })
                }
              />
            </div>
            <div className='col-1'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={clienteData?.flgFrota}
                label='Frota'
                onChange={(flgFrota: boolean) =>
                  setClienteData({
                    ...clienteData,
                    flgFrota,
                  })
                }
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={clienteData?.fglEnviaCTEPDF}
                label='Envia CTE Pdf'
                onChange={(fglEnviaCTEPDF: boolean) =>
                  setClienteData({
                    ...clienteData,
                    fglEnviaCTEPDF,
                  })
                }
              />
            </div>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={clienteData?.flgEnviaCTEXML}
                label='Envia CTE XML'
                onChange={(flgEnviaCTEXML: boolean) =>
                  setClienteData({
                    ...clienteData,
                    flgEnviaCTEXML,
                  })
                }
              />
            </div>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={clienteData?.flgEnviaNFEPDF}
                label='Envia NFE Pdf'
                onChange={(flgEnviaNFEPDF: boolean) =>
                  setClienteData({
                    ...clienteData,
                    flgEnviaNFEPDF,
                  })
                }
              />
            </div>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={clienteData?.flgEnviaNFEXML}
                label='Envia NFE Xml'
                onChange={(flgEnviaNFEXML: boolean) =>
                  setClienteData({
                    ...clienteData,
                    flgEnviaNFEXML,
                  })
                }
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-4 mb-3'>
              <Autocomplete
                label='Condição Pagamento'
                id='ddlCondicaoPagamento'
                searchDataSource={onSearchCondicaoPagamento}
                selectedItem={clienteData.condicaoPagamento}
                onSelectItem={(condicaoPagamento: any) => {
                  setClienteData({
                    ...clienteData,
                    condicaoPagamento,
                    nrSeqCondicaoPagamento:
                      condicaoPagamento.nrSeqCondicaoPagamento,
                  });
                }}
                dataSourceTextProperty='noCondicaoPagamento'
              />
            </div>
            <div className='col-3'>
              <Autocomplete
                label='Tipo Venda'
                searchDataSource={onSearchTipoVenda}
                selectedItem={clienteData.tipoVenda}
                onSelectItem={(tipoVenda: any) => {
                  setClienteData({
                    ...clienteData,
                    tipoVenda,
                    nrSeqTipoVenda: tipoVenda.nrSeqTipoVenda,
                  });
                }}
                dataSourceTextProperty='noTipoVenda'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-4'>
              <Autocomplete
                label='Limite Crédito'
                searchDataSource={onSearchLimiteCredito}
                selectedItem={clienteData.limiteCredito}
                onSelectItem={(limiteCredito: any) =>
                  setClienteData({
                    ...clienteData,
                    limiteCredito,
                    nrSeqLimiteCredito: limiteCredito.nrSeqLimiteCredito,
                  })
                }
                dataSourceTextProperty='noLimiteCredito'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-4'>
              <Autocomplete
                label='Representante'
                searchDataSource={onSearchVendedor}
                selectedItem={clienteData.vendedor}
                onSelectItem={(vendedor: any) =>
                  setClienteData({
                    ...clienteData,
                    vendedor,
                    nrSeqPessoaVen: vendedor.nrSeqPessoa,
                  })
                }
                dataSourceTextProperty='noPessoa'
              />
            </div>
            {clienteEmpresa && (
              <div className='col-6'>
                <Autocomplete
                  label='Empresa'
                  searchDataSource={onSearchEmpresa}
                  visible={clienteEmpresa}
                  selectedItem={clienteData.empresa}
                  onSelectItem={(empresa: any) =>
                    setClienteData({
                      ...clienteData,
                      empresa,
                      nrSeqEmpresa: empresa.nrSeqEmpresa,
                    })
                  }
                  dataSourceTextProperty='noPessoa'
                />
              </div>
            )}
          </div>
          <div className='row mb-3'>
            <div className='col-4'>
              <Autocomplete
                label='Transportadora'
                id='txtTransportadora'
                searchDataSource={onSearchTransportadora}
                selectedItem={clienteData.transportadora}
                onSelectItem={(transportadora: any) =>
                  setClienteData({
                    ...clienteData,
                    transportadora,
                    nrSeqPessoaTra: transportadora.nrSeqPessoaTran,
                  })
                }
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-4'>
              <Autocomplete
                label='ReDesempenho'
                id='txtTransportadora'
                searchDataSource={onSearchTransportadora}
                selectedItem={clienteData.transportadoraRedespacho}
                onSelectItem={(transportadoraRedespacho: any) =>
                  setClienteData({
                    ...clienteData,
                    transportadoraRedespacho,
                    nrSeqPessoaRes: transportadoraRedespacho.nrSeqPessoaTran,
                  })
                }
                dataSourceTextProperty='noPessoa'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-3'>
              {/* @ts-expect-error */}
              <Textbox
                label='Usuário CIOT'
                maxLength={50}
                text={clienteData?.noLoginCIOT}
                onChangedValue={(noLoginCIOT: string) => {
                  setClienteData({
                    ...clienteData,
                    noLoginCIOT,
                  });
                }}
              />
            </div>
            <div className='col-3'>
              {/* @ts-expect-error */}
              <Textbox
                label='Senha CIOT'
                maxLength={50}
                text={clienteData?.noSenhaCIOT}
                onChangedValue={(noSenhaCIOT: string) => {
                  setClienteData({
                    ...clienteData,
                    noSenhaCIOT,
                  });
                }}
              />
            </div>
            <div className='col-3'>
              {/* @ts-expect-error */}
              <Textbox
                label='CNPJ Matriz CIOT'
                maxLength={14}
                text={clienteData?.nrCnpjMatrizCiot}
                onChangedValue={(nrCnpjMatrizCiot: string) => {
                  setClienteData({
                    ...clienteData,
                    nrCnpjMatrizCiot,
                  });
                }}
              />
            </div>
            <div className='col-3'>
              {/* @ts-expect-error */}
              <Textbox
                label='Sequencial CIOT'
                mask={MaskTypes.Integer}
                maxLength={8}
                text={clienteData?.nrSequencialCiot}
                onChangedValue={(nrSequencialCiot: string) => {
                  setClienteData({
                    ...clienteData,
                    nrSequencialCiot,
                  });
                }}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-6'>
              <Autocomplete
                label='Plano de Conta'
                searchDataSource={onSearchPlanoContaCred}
                selectedItem={clienteData.planoDeConta}
                onSelectItem={(planoDeConta) => {
                  setClienteData({
                    ...clienteData,
                    planoDeConta,
                    nrSeqPlanoDeConta: planoDeConta.nrSeqPlanoDeConta,
                  });
                }}
                dataSourceTextProperty='noPlanoDeConta'
              />
            </div>
            <div className='col-4'>
              <Autocomplete
                label='Tipo Embarque'
                searchDataSource={onSearchTipoEmbarque}
                selectedItem={clienteData.tipoEmbarque}
                onSelectItem={(tipoEmbarque: any) => {
                  setClienteData({
                    ...clienteData,
                    tipoEmbarque,
                    nrSeqTipoEmbarque: tipoEmbarque.nrSeqTipoEmbarque,
                  });
                }}
                dataSourceTextProperty='noTipoEmbarque'
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </div>
  );
};

export default ClientePanel;
