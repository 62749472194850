import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  DatePicker,
  Textbox,
  Panel,
  DropdownMulti,
  GridView,
  Button,
  ToolbarButtons,
} from 'ui/components';
import { mask, MaskTypes } from 'ui/Helpers/masks';
import {
  getPagamentoMassivo,
  savePagamentoMassivo,
  deletePagamentoMassivo,
  printPagamentoMassivo,
} from 'core/services/FIN/pagamentoMassivo';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';
import { getTituloPagarTipoDocumentoAutoComplete } from 'core/services/FIN/tituloPagarTipoDocumento';
import {
  getFormaPagamentoAutoComplete,
  getTipoDespesaAutoComplete,
} from 'core/services/FIN';
import { setTransaction } from 'core/services/api';
import { getTituloPagarList } from 'core/services/FIN/tituloPagar';

export default function PagamentoMassivoItem({
  registryKey,
  reload,
  onSelectPage,
  transaction,
  onOpenReport,
  isActive,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [totalSelecionadoSaldo, setTotalSelecionadoSaldo] = useState(0);
  const [totalMultaSelecionado, setTotalMultaSelecionado] = useState(0);
  const [totalJuroSelecionado, setTotalJuroSelecionado] = useState(0);
  const [totalDescontoSelecionado, setTotalDescontoSelecionadoSaldo] = useState(
    0
  );
  const [selectedItems, setSelectedItems] = useState({});
  const [identificado, setIdentificado] = useState({});
  const [message, setMessage] = useState(null);
  const dropDownFormaPagamento = useRef();
  const dropDownTipoDespesa = useRef();
  const gridView = useRef();
  const gridView2 = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onCalculaTotais = () => {
    const nrSeqTituloPagarS = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    const Listatitulos = gridView.current.getDataSource();

    if (Listatitulos !== null && Listatitulos !== undefined) {
      Listatitulos.forEach((item) => {
        if (item.vlrTotalPagar === null || item.vlrTotalPagar === undefined) {
          item.vlrTotalPagar = 0;
        }
        if (item.vlrJuroPago === null || item.vlrJuroPago === undefined) {
          item.vlrJuroPago = 0;
        }
        if (item.vlrMultaPaga === null || item.vlrMultaPaga === undefined) {
          item.vlrMultaPaga = 0;
        }
        if (
          item.vlrDescontoCalculado === null ||
          item.vlrDescontoCalculado === undefined
        ) {
          item.vlrDescontoCalculado = 0;
        }
      });

      const total = nrSeqTituloPagarS.reduce((acc, numero) => {
        const titulo = Listatitulos.find((t) => t.nrSeqTituloPagar === numero);
        return titulo ? acc + titulo.vlrTotalPagar : acc;
      }, 0);

      const vlrJuro = nrSeqTituloPagarS.reduce((acc, numero) => {
        const titulo = Listatitulos.find((t) => t.nrSeqTituloPagar === numero);
        return titulo ? acc + titulo.vlrJuroPago : acc;
      }, 0);

      const vlrMulta = nrSeqTituloPagarS.reduce((acc, numero) => {
        const titulo = Listatitulos.find((t) => t.nrSeqTituloPagar === numero);
        return titulo ? acc + titulo.vlrMultaPaga : acc;
      }, 0);

      const vlrDesconto = nrSeqTituloPagarS.reduce((acc, numero) => {
        const titulo = Listatitulos.find((t) => t.nrSeqTituloPagar === numero);
        return titulo ? acc + titulo.vlrDescontoCalculado : acc;
      }, 0);

      // const vlrTotalSomado = total + vlrMulta + vlrJuro - vlrDesconto;

      setTotalSelecionadoSaldo(total);
      setTotalMultaSelecionado(vlrMulta);
      setTotalJuroSelecionado(vlrJuro);
      setTotalDescontoSelecionadoSaldo(vlrDesconto);
    }
  };

  const saveReceber = async (keyValue, value) => {
    const datasource = gridView.current ? gridView.current.getDataSource() : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTituloPagar === keyValue
    );
    itemParcela.vlrTotalPagar = value;

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].nrSeqTituloPagar === itemParcela.nrSeqTituloPagar) {
        datasource[i].vlrTotalPagar = itemParcela.vlrTotalPagar;
      }
      i += 1;
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);
  };
  const saveMulta = async (keyValue, value) => {
    const datasource = gridView.current ? gridView.current.getDataSource() : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTituloPagar === keyValue
    );
    itemParcela.vlrMultaPaga = value;

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].nrSeqTituloPagar === itemParcela.nrSeqTituloPagar) {
        datasource[i].vlrMultaPaga = itemParcela.vlrMultaPaga;
      }
      i += 1;
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);
  };

  const onPrint = async () => {
    setLoading(true);

    const obj = { ...data };

    const value = await printPagamentoMassivo(obj);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const saveJuro = async (keyValue, value) => {
    const datasource = gridView.current ? gridView.current.getDataSource() : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTituloPagar === keyValue
    );
    itemParcela.vlrJuroPago = value;

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].nrSeqTituloPagar === itemParcela.nrSeqTituloPagar) {
        datasource[i].vlrJuroPago = itemParcela.vlrJuroPago;
      }
      i += 1;
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);
  };
  const saveDesconto = async (keyValue, value) => {
    const datasource = gridView.current ? gridView.current.getDataSource() : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTituloPagar === keyValue
    );
    itemParcela.vlrDescontoCalculado = value;

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].nrSeqTituloPagar === itemParcela.nrSeqTituloPagar) {
        datasource[i].vlrDescontoCalculado = itemParcela.vlrDescontoCalculado;
      }
      i += 1;
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);
  };

  const fsaveReceber = function (keyValue, value) {
    saveReceber(keyValue, value);
  };
  const fsaveMulta = function (keyValue, value) {
    saveMulta(keyValue, value);
  };
  const fsaveJuro = function (keyValue, value) {
    saveJuro(keyValue, value);
  };
  const fsaveDesconto = function (keyValue, value) {
    saveDesconto(keyValue, value);
  };

  const onClickRemovePagamento = async (selectedValue, datasource) => {
    let tituloEspecies = [];
    if (selectedValue.nrSeqPagamento === undefined) {
      tituloEspecies = datasource.filter(
        (tituloEspecie) => tituloEspecie !== selectedValue
      );
    } else {
      tituloEspecies = datasource.filter(
        (tituloEspecie) => tituloEspecie !== selectedValue
      );
      const tituloespecie = datasource.filter(
        (tituloEspecie) => tituloEspecie === selectedValue
      );
      tituloespecie[0].status = GridView.EnumStatus.Remover;
      tituloEspecies.push(tituloespecie[0]);
    }
    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(tituloEspecies);
  };

  const columns2 = [
    {
      key: 'nrSeqPagamento',
      visible: false,
    },
    {
      key: 'dtPagamento',
      title: 'Dt Pag',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrCheque',
      title: 'NrCheque',
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma',
    },

    {
      key: 'vlrSaldoPago',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrDesconto',
      title: 'Vlr desconto',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrJuro',
      title: 'Juro',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrMulta',
      title: 'Multa',
      format: GridView.DataTypes.Decimal,
    },

    {
      key: 'vlrTotal',
      title: 'Total',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'nrSeqPagamento',
      type: GridView.ColumnTypes.Button,
      theme: Theme.Danger,
      onClick: (e, datasource) => onClickRemovePagamento(e, datasource),
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columns = [
    { key: 'nrSeqTituloPagar', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrTit',
      title: 'Titulo',
    },
    {
      key: 'nrOrdem',
      title: 'Ordem',
    },

    {
      key: 'fornecedor.noFornecedor',
      title: 'Fornecedor',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor Do Titulo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrPago',
      title: 'Vlr Pago',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrSaldoTit',
      title: 'Valor Saldo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotalPagar',
      title: 'Vlr a Pagar',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fsaveReceber,
      maxLength: 10,
    },

    {
      key: 'vlrDescontoCalculado',
      title: 'Vlr desc',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fsaveDesconto,
      maxLength: 10,
    },
    {
      key: 'vlrJuroPago',
      title: 'Vlr Juro',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fsaveJuro,
      maxLength: 10,
    },
    {
      key: 'vlrMultaPaga',
      title: 'Vlr Multa',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fsaveMulta,
      maxLength: 10,
    },
  ];

  const onAdicionarPagamento = async () => {
    if (data.dtPagamento === undefined) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar sem a data de pagamento'
      );
      return;
    }
    if (data.vlrSaldoPago === undefined) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar sem o valor do pagamento'
      );
      return;
    }
    if (data.vlrSaldoPago === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar sem o valor do pagamento'
      );
      return;
    }

    if (data.vlrDesconto > data.vlrSaldoPago) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar um valor desconto maior que o saldo do titulo'
      );
      return;
    }
    if (
      data.nrSeqFormaPagamento === null ||
      data.nrSeqFormaPagamento === undefined
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Para adicionar a grid informe a forma de pagamento'
      );
      return;
    }

    if (data.vlrMulta === undefined) {
      data.vlrMulta = 0;
    }
    if (data.vlrJuro === undefined) {
      data.vlrJuro = 0;
    }

    if (data.vlrDesconto === undefined) {
      data.vlrDesconto = 0;
    }

    let lista = gridView2.current.getDataSource();
    if (lista === undefined || lista === null) {
      lista = [];
    }
    data.vlrTotal =
      data.vlrSaldoPago + data.vlrJuro + data.vlrMulta - data.vlrDesconto;
    if (data.status !== GridView.EnumStatus.Alterar) {
      data.status = GridView.EnumStatus.Inserir;
    }
    lista.push(data);
    if (gridView2 && gridView2.current) gridView2.current.setDataSource(lista);
    setData({
      vlrJuro: 0,
      vlrMulta: 0,
      vlrSaldoPago: 0,
      vlrDesconto: 0,
    });
  };

  const search = async (params = null) => {
    setLoading(true);

    filtros.tipoTituloPagar = 'A';

    const { data: tit } = await getTituloPagarList(params ?? filtros);

    tit.forEach((item) => {
      item.vlrTotalPagar = item.vlrSaldoTit;
    });

    if (gridView && gridView.current) gridView.current.setDataSource(tit);

    setLoading(false);
  };

  function RenderTextMask(props) {
    const { label, value, masktype } = props;
    return (
      <div className='col-auto mt-3'>
        <div className='fw-bold'>
          {label}: {mask(value ?? 0, masktype ?? MaskTypes.Currency)}
        </div>
      </div>
    );
  }

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      setLoading(true);
      const obj = await getPagamentoMassivo(registryKey);

      obj.tituloPagarS.forEach((item) => {
        item.vlrDescontoCalculado = item.vlrDesconto;
      });

      setData({ nrSeqPagamentoMassivo: obj.nrSeqPagamentoMassivo });
      if (gridView && gridView.current)
        gridView.current.setDataSource(obj.tituloPagarS);
      if (gridView2 && gridView2.current)
        gridView2.current.setDataSource(obj.pagamentoMassivoItemS);

      setLoading(false);

      setLoading(false);
    } else {
      setData({
        vlrJuro: 0,
        vlrMulta: 0,
        vlrSaldoPago: 0,
        vlrDesconto: 0,
      });
      setFiltros({});
      if (gridView && gridView.current) gridView.current.setDataSource(null);
      if (gridView2 && gridView2.current) gridView2.current.setDataSource(null);
    }
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);

    setTransaction(transaction ? transaction.nrSeqTransacao : 0);

    const tituloPag = [];

    const tituloPagar = gridView.current
      ? gridView.current.getDataSource()
      : [];

    const nrSeqTituloPagar = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    if (nrSeqTituloPagar.length === 0) {
      setLoading(false);
      onSetMessage(
        ResponseStatus.Error,
        'Selecione um item para fazer o recebimento'
      );
      return;
    }

    for (let i = 0; i < nrSeqTituloPagar.length; ) {
      for (let j = 0; j < tituloPagar.length; ) {
        if (tituloPagar[j].nrSeqTituloPagar === nrSeqTituloPagar[i]) {
          tituloPag.push(tituloPagar[j]);
        }

        j += 1;
      }
      i += 1;
    }

    const massivoItem = gridView2.current
      ? gridView2.current.getDataSource()
      : [];

    const obj = {
      ...data,
      ...identificado,
      nrSeqTransacao: transaction.nrSeqTransacao,
      pagamentoMassivoItemS: massivoItem,
      tituloPagarS: tituloPag,
    };
    const { status, message: msg } = await savePagamentoMassivo(obj);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setFiltros(status === ResponseStatus.Success ? {} : filtros);
    setData(
      status === ResponseStatus.Success
        ? {
            vlrJuro: 0,
            vlrMulta: 0,
            vlrSaldoPago: 0,
            vlrDesconto: 0,
          }
        : data
    );

    if (status === ResponseStatus.Success) {
      if (gridView && gridView.current) gridView.current.setDataSource(null);
      if (gridView2 && gridView2.current) gridView2.current.setDataSource(null);
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deletePagamentoMassivo(
        registryKey
      );

      setFiltros(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onSearchTipoDespesa = async (e) => {
    const { tipoDespesas } = await getTipoDespesaAutoComplete({
      noTipoDespesa: e,
    });

    return tipoDespesas;
  };
  const onSearchFormaPagamento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgTitPag: true,
    });

    return formasPagamento;
  };

  useEffect(() => {
    dropDownFormaPagamento.current.loadDataSource(onSearchFormaPagamento);
    dropDownTipoDespesa.current.loadDataSource(onSearchTipoDespesa);
  }, []);

  const onSearchTipo = async (e) => {
    const {
      data: tituloPagarTipoDocumento,
    } = await getTituloPagarTipoDocumentoAutoComplete({
      noTituloPagarTipoDocumento: e,
    });

    return tituloPagarTipoDocumento;
  };
  const onSearchFornecedor = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaFors,
    } = await getFornecedorAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return pessoaFors;
  };

  const onLimpaCampo = async () => {
    setFiltros({
      vlrTitulo: 0,
    });
    setSelectedItems({});
  };

  const onNew = () => {
    setData({
      vlrJuro: 0,
      vlrMulta: 0,
      vlrSaldoRecebido: 0,
      vlrDesconto: 0,
    });
    setFiltros({});
    if (gridView && gridView.current) gridView.current.setDataSource(null);
    if (gridView2 && gridView2.current) gridView2.current.setDataSource(null);

    setTotalSelecionadoSaldo(0);
    setTotalMultaSelecionado(0);
    setTotalJuroSelecionado(0);
    setTotalDescontoSelecionadoSaldo(0);
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de PagamentoMassivo'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew({})}
      onSearch={() => search()}
      transaction={transaction}
      onDelete={data?.nrSeqPagamentoMassivo > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <ToolbarButtons>
        {data.nrSeqPagamentoMassivo === null ||
          (data.nrSeqPagamentoMassivo === undefined && (
            <ToolbarButtons.Button
              text='Salvar'
              icon={['far', 'save']}
              onClick={() => save()}
              type='submit'
            />
          ))}

        {data.nrSeqPagamentoMassivo === null ||
          (data.nrSeqPagamentoMassivo === undefined && (
            <ToolbarButtons.Button
              text='Pesquisar'
              icon='search'
              onClick={() => search()}
            />
          ))}
        <ToolbarButtons.Button
          text='Imprimir'
          icon='print'
          onClick={() => onPrint('Padrao')}
        />
      </ToolbarButtons>
      <Panel>
        <div className='row'>
          <div className='col-12 mb-4'>
            <Panel.Header
              icon={['fas', 'user']}
              title='Filtros'
              theme={Theme.Primary}
              onClickCollapseButton={onLimpaCampo}
            />
          </div>
        </div>
        <Panel.Body>
          <div className='row'>
            <div className='col-4 mb-4'>
              <Autocomplete
                label='Fornecedor'
                searchDataSource={onSearchFornecedor}
                selectedItem={filtros.fornecedor}
                onSelectItem={(fornecedor) => {
                  setFiltros({
                    ...filtros,
                    fornecedor,
                    nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                  });
                }}
                dataSourceTextProperty='nomeDocumentoCidadeEstado'
              />
            </div>
            <div className='col-4 mb-4'>
              <Autocomplete
                label='Tipo'
                searchDataSource={onSearchTipo}
                selectedItem={filtros.tituloPagarTipoDocumento}
                onSelectItem={(tituloPagarTipoDocumento) => {
                  setFiltros({
                    ...filtros,
                    tituloPagarTipoDocumento,
                    nrSeqTituloPagarTipoDocumento:
                      tituloPagarTipoDocumento.nrSeqTituloPagarTipoDocumento,
                  });
                }}
                dataSourceTextProperty='noTituloPagarTipoDocumento'
              />
            </div>
            <div className='col-4 mb-4'>
              <Autocomplete
                label='Empresa'
                searchDataSource={onSearchEmpresa}
                selectedItem={filtros.empresa}
                onSelectItem={(empresa) => {
                  setFiltros({
                    ...filtros,
                    empresa,
                    nrSeqEmpresa: empresa.nrSeqEmpresa,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-2 mb-3'>
              <Textbox
                maxLength={20}
                label='Nr. Documento'
                text={filtros.nrTit}
                onChangedValue={(nrTit) =>
                  setFiltros({
                    ...filtros,
                    nrTit,
                  })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <Textbox
                maxLength={20}
                label='Nr. Compromisso'
                text={filtros.cdTituloPagar}
                onChangedValue={(cdTituloPagar) =>
                  setFiltros({
                    ...filtros,
                    cdTituloPagar,
                  })
                }
              />
            </div>

            <div className='col-4'>
              <DropdownMulti
                label='Forma De Pagamento'
                ref={dropDownFormaPagamento}
                dataSourcePropertyText='noFormaPagamento'
                dataSourcePropertyValue='nrSeqFormaPagamento'
                selectedItems={selectedItems.formaPagamento ?? []}
                onSelectItem={(formaPagamento) => {
                  setSelectedItems({ ...selectedItems, formaPagamento });
                  setFiltros({
                    ...data,
                    nrSeqFormaPagamentos: formaPagamento?.map(
                      (p) => p.nrSeqFormaPagamento
                    ),
                  });
                }}
              />
            </div>

            <div className='col-4'>
              <DropdownMulti
                label='Tipo Despesa'
                ref={dropDownTipoDespesa}
                dataSourcePropertyText='noTipoDespesa'
                dataSourcePropertyValue='nrSeqTipoDespesa'
                selectedItems={selectedItems.tipoDespesa ?? []}
                onSelectItem={(tipoDespesa) => {
                  setSelectedItems({ ...selectedItems, tipoDespesa });
                  setFiltros({
                    ...filtros,
                    nrSeqTipoDespesaS: tipoDespesa?.map(
                      (p) => p.nrSeqTipoDespesa
                    ),
                  });
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-3 mb-3'>
              <Textbox
                maxLength={20}
                label='Vlr Título'
                textRight
                text={filtros.vlrTitulo}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrTitulo) =>
                  setFiltros({
                    ...filtros,
                    vlrTitulo,
                  })
                }
              />
            </div>

            <div className='col-2 mb-3'>
              <Textbox
                maxLength={20}
                label='Nota'
                text={filtros.nota}
                mask={MaskTypes.Integer}
                onChangedValue={(nota) =>
                  setFiltros({
                    ...filtros,
                    nota,
                  })
                }
              />
            </div>

            <div className='col-6 mb-3'>
              <Textbox
                label='Obs'
                text={filtros?.noObs}
                onChangedValue={(noObs) =>
                  setFiltros({
                    ...filtros,
                    noObs,
                  })
                }
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Vencimento De'
                text={filtros.dtVencimentoDe}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtVencimentoDe) =>
                  setFiltros({ ...filtros, dtVencimentoDe })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Vencimento Até'
                text={filtros.dtVencimentoAt}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtVencimentoAt) =>
                  setFiltros({ ...filtros, dtVencimentoAt })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Contabil De'
                text={filtros.contabilDe}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(contabilDe) =>
                  setFiltros({ ...filtros, contabilDe })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Contabil Até'
                text={filtros.contabilAte}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(contabilAte) =>
                  setFiltros({ ...filtros, contabilAte })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Cadastro De'
                text={filtros.dtCadastro}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtCadastro) =>
                  setFiltros({ ...filtros, dtCadastro })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Cadastro Até'
                text={filtros.dtCadastroAte}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtCadastroAte) =>
                  setFiltros({ ...filtros, dtCadastroAte })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div className='row mb-3'>
        <Panel>
          <div className='row mb-3'>
            <div className='col-12'>
              <Panel.Header
                class='fa-solid fa-eraser'
                title='Titulos que serão Pagos'
                theme={Theme.Primary}
              />
            </div>
          </div>
          <Panel.Body>
            <div className='col'>
              <GridView
                ref={gridView}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columns}
                showPagination={false}
                showSelectSizes={false}
                onRowRender={onCalculaTotais}
              />
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row'>
        <div className='col-sm-12'>
          <div className='alert alert-info fs-8' role='alert'>
            <div className='row'>
              <div className='col-3 mb-3 float-right'>
                <RenderTextMask
                  label='Total Selecionado'
                  value={totalSelecionadoSaldo}
                />
              </div>
              <div className='col-3 mb-3 float-right'>
                <RenderTextMask
                  label='Total Desconto'
                  value={totalDescontoSelecionado}
                />
              </div>
              <div className='col-3 mb-3 float-right'>
                <RenderTextMask
                  label='Total Juro'
                  value={totalJuroSelecionado}
                />
              </div>
              <div className='col-3 mb-3 float-right'>
                <RenderTextMask
                  label='Total Multa'
                  value={totalMultaSelecionado}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-3'>
        <Panel>
          <div className='row mb-3'>
            <div className='col-12'>
              <Panel.Header
                class='fa-solid fa-eraser'
                title='Dados do Pagamento'
                theme={Theme.Primary}
              />
            </div>
          </div>
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-12'>
                <Textbox
                  label='Número Identificação'
                  text={identificado.nrIdentificado}
                  onChangedValue={(nrIdentificado) =>
                    setIdentificado({
                      ...identificado,
                      nrIdentificado,
                    })
                  }
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-2'>
                <DatePicker
                  label='Dt Pagamento'
                  text={data.dtPagamento}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtPagamento) => setData({ ...data, dtPagamento })}
                />
              </div>

              <div className='col-8 mb-3'>
                <Autocomplete
                  label='Forma Pagamento'
                  searchDataSource={onSearchFormaPagamento}
                  selectedItem={data?.formaPagamento}
                  onSelectItem={(formaPagamento) => {
                    setData({
                      ...data,
                      formaPagamento,
                      nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                    });
                  }}
                  dataSourceTextProperty='noFormaPagamento'
                />
              </div>
            </div>
            <div className='row'>
              {data.formaPagamento?.flgGrupo === 'C' && (
                <div className='col-6 mb-3'>
                  <DatePicker
                    label='Data Cheque'
                    required
                    text={data.dtCheque}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dtCheque) => setData({ ...data, dtCheque })}
                  />
                </div>
              )}
              {data.formaPagamento?.flgGrupo === 'C' &&
                !data.formaPagamento?.flgControlaCheque && (
                  <div className='col-6 mb-3'>
                    <Textbox
                      label='Nr. Cheque'
                      text={data.nrCheque}
                      onChangedValue={(nrCheque) =>
                        setData({ ...data, nrCheque })
                      }
                    />
                  </div>
                )}
            </div>
            <div className='row mb-3'>
              <div className='col-2'>
                <Textbox
                  label='Vlr Pagamento'
                  text={data.vlrSaldoPago}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(vlrSaldoPago) =>
                    setData({
                      ...data,
                      vlrSaldoPago,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Vlr Desconto'
                  text={data.vlrDesconto}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(vlrDesconto) =>
                    setData({
                      ...data,
                      vlrDesconto,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Vlr Juros'
                  text={data.vlrJuro}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(vlrJuro) =>
                    setData({
                      ...data,
                      vlrJuro,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Vlr Multa'
                  text={data.vlrMulta}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(vlrMulta) =>
                    setData({
                      ...data,
                      vlrMulta,
                    })
                  }
                />
              </div>

              <div className='col-2 mt-3'>
                <Button
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  text='Adicionar'
                  onClick={onAdicionarPagamento}
                />
              </div>
            </div>
            <div className='col'>
              <GridView
                ref={gridView2}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columns2}
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </Panel.Body>
        </Panel>
      </div>
    </CSDManutPage>
  );
}
