import { Veiculo, Motorista, UploadImagemFrota } from 'core/models/FRO';
import { Estado } from 'core/models/SEG';

export default class VeiculoDisponibilidade {
  nrSeqVeiculoDisponibilidade: number;

  flgTemDevolucao: boolean;

  itensDevolucao: string;

  qtdPallets: number;

  localPallets: string;

  flgComCarga: boolean;

  nrSeqVeiculo: number;

  dtDisponibilidade: Date;

  flgDisponivel: boolean;

  // #region Propriedades Auxiliares

  veiculo: Veiculo;

  motorista: Motorista;

  estadoDestino: Estado;

  flgDisponivelColor: string;

  uploadImagens: Array<UploadImagemFrota>;

  buscaDisponibilidade: boolean;

  noCidade: string;

  // #endregion

  constructor(jsonObject: VeiculoDisponibilidade) {
    this.nrSeqVeiculoDisponibilidade = jsonObject.nrSeqVeiculoDisponibilidade;
    this.flgTemDevolucao = jsonObject.flgTemDevolucao;
    this.itensDevolucao = jsonObject.itensDevolucao;
    this.qtdPallets = jsonObject.qtdPallets;
    this.localPallets = jsonObject.localPallets;
    this.flgComCarga = jsonObject.flgComCarga;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.dtDisponibilidade = jsonObject.dtDisponibilidade;
    this.flgDisponivel = jsonObject.flgDisponivel;

    // #region Propriedades Auxiliares

    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    this.motorista = new Motorista(jsonObject.motorista ?? {});
    this.estadoDestino = new Estado(jsonObject.estadoDestino ?? {});
    this.flgDisponivelColor = jsonObject.flgDisponivelColor;
    this.uploadImagens = jsonObject.uploadImagens
      ? jsonObject.uploadImagens.map(
          (item) => new UploadImagemFrota(item ?? {})
        )
      : [];
    this.noCidade = jsonObject.noCidade;
    this.buscaDisponibilidade = jsonObject.buscaDisponibilidade;

    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
