import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Theme } from 'ui/Helpers/utils';
import { getGrupoLinhaAutoComplete, getLinhasGrupo } from 'core/services/TEL/';
import { Autocomplete, Button } from 'ui/components';
import LinhasSelecionadas from './linhasSelecionadas';

const PanelGestaoContaFiltroGrupoLinha = forwardRef((props, lin) => {
  const [filters, setFilters] = useState({});
  const [linhasSelecionadas, setLinhas] = useState({
    linha: [],
  });
  const [selectedItems, setSelectedItems] = useState({});

  const onAutoCompleteGrupoLinha = async (e) => {
    const { gruposLinhas } = await getGrupoLinhaAutoComplete({
      noDescricao: e,
    });

    return gruposLinhas;
  };

  const onRemoveLinha = (e) => {
    const position = linhasSelecionadas.linha.indexOf(e);
    linhasSelecionadas.linha.splice(position, 1);
    setLinhas({ ...linhasSelecionadas, linhasSelecionadas });
  };

  useImperativeHandle(lin, () => ({ lin: linhasSelecionadas }));

  const searchLinhasGrupo = async () => {
    const { linhas } = await getLinhasGrupo({
      nrSeqGrupoLinha: filters.nrSeqGrupoLinha,
    });

    return linhas;
  };

  const onAdicionarLinha = async (nrseqgrupolinha) => {
    const chips = await searchLinhasGrupo(nrseqgrupolinha);

    const linhas = chips.map((linha) => linha);
    linhasSelecionadas.linha = linhas;
    setLinhas({ ...linhasSelecionadas, linhasSelecionadas });
  };

  return (
    <div className='row'>
      <div className='mb-3'>
        <div className='row'>
          <div className='col-6'>
            <Autocomplete
              label='Grupo de Linha'
              searchDataSource={onAutoCompleteGrupoLinha}
              selectedItem={selectedItems.grupoLinha}
              onSelectItem={(grupoLinha) => {
                setSelectedItems({ ...selectedItems, grupoLinha });
                setFilters({
                  ...filters,
                  nrSeqGrupoLinha: grupoLinha.nrSeqGrupoLinha,
                });
              }}
              dataSourceTextProperty='noDescricao'
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='mb-3'>
          <Button
            outline
            theme={Theme.Success}
            template={Button.Templates.Default}
            text='Adicionar'
            onClick={onAdicionarLinha}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='accordion' id='accordionExample'>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingOne'>
                <button
                  className='accordion-button col-12'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseOne'
                  aria-expanded='true'
                  aria-controls='collapseOne'
                >
                  Linhas Adicionadas
                </button>
              </h2>
              <div
                id='collapseOne'
                className='accordion-collapse collapse show'
              >
                <div className='accordion-body'>
                  <LinhasSelecionadas
                    linhas={linhasSelecionadas.linha}
                    onRemoveLinha={onRemoveLinha}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PanelGestaoContaFiltroGrupoLinha;
