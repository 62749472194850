export default class ContratoAgrupadoCliente {
  constructor(jsonObject = {}) {
    this.nrSeqClienteContaCabecalho = jsonObject.nrSeqClienteContaCabecalho;
    this.nrSeqContaCabecalho = jsonObject.nrSeqContaCabecalho;
    this.contratoAgrupado = jsonObject.contratoAgrupado ?? {};
    this.clienteContaCabecalho = jsonObject.cliente ?? {};
  }

  toJson = () => JSON.stringify(this);
}
