import React, { useState } from 'react';

import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { Button, Modal, DropdownList } from 'ui/components';

const justificativas = [
  { Key: 0, valor: 'Erro de cadastro', nrSeqJustificativa: 1 },
  { Key: 1, valor: 'Erro de digitação na revisão', nrSeqJustificativa: 2 },
  { Key: 2, valor: 'Veiculo cadastrado errado', nrSeqJustificativa: 3 },
];

export default function ModalExcluirOcorrencia({ onClose, show, onConfirme }) {
  // const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  return (
    <Modal
      show={show}
      title='Justificativa'
      onClose={onClose}
      size={BootstrapSizes.Large}
    >
      <Modal.Body className='p-12 pt-4'>
        <div className='row mb-3'>
          <div className='col-6'>
            <DropdownList
              label='Qual o motivo para deletar o registro?'
              dataSource={justificativas}
              dataSourcePropertyText='valor'
              dataSourcePropertyValue='nrSeqJustificativa'
              selectedItems={data.justificativaS ?? []}
              onSelectItem={(item) => {
                setData({
                  ...data,
                  justificativaS: item,
                  nrSeqJustificativa: item.nrSeqJustificativa,
                  valor: item.valor,
                });
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Confirmar'
          theme={Theme.Success}
          onClick={() => {
            onConfirme(data);
          }}
        />
        <Button
          text='Sair'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
