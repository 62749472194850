import React, { useEffect } from 'react';
import { Panel, RadioButton, Textbox, Switch } from 'ui/components';
// @ts-expect-error
import { Theme } from 'ui/Helpers/enums.ts';
import { BootstrapSizes } from 'ui/Helpers/utils';

interface Props {
  transportadoraData: any;
  setTransportadoraData: (object: any) => void;
}

const TransportadoraPanel = ({
  transportadoraData,
  setTransportadoraData,
}: Props) => {
  const tipoTransporte = [
    {
      text: 'Normal',
      value: 'N',
    },
    {
      text: 'Redespacho ',
      value: 'R',
    },
    {
      text: 'Todos',
      value: 'T',
    },
  ];

  const tipoTransportadora = [
    {
      text: 'ETC',
      value: 1,
    },
    { text: 'TAC', value: 2 },
    { text: 'CTC', value: 3 },
  ];

  useEffect(() => {
    const objTransportadora = { ...transportadoraData };
    const tipo = objTransportadora.flgTipo || 'N';

    const tipotransporte = tipoTransporte.find((el) => el.value === tipo);
    objTransportadora.noTipoTransporte = tipotransporte?.value;

    const nrTipoTransportadora = objTransportadora.nrTipoTransportadora || 1;

    const tipotransportadora = tipoTransportadora.find(
      (el) => el.value === nrTipoTransportadora
    );

    objTransportadora.noTipoTransportadora = tipotransportadora?.value;

    setTransportadoraData(objTransportadora);
  }, [transportadoraData.nrSeqPessoaTran]);

  return (
    <div className='col mt-3'>
      {/* @ts-expect-error */}
      <Panel>
        {/* @ts-expect-error */}
        <Panel.Header title='Transportadora' theme={Theme.Primary} />

        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <Textbox
                label='Número RNTRC'
                text={transportadoraData?.nrRntrc}
                maxLength={8}
                onChangedValue={(nrRntrc: string) => {
                  setTransportadoraData({
                    ...transportadoraData,
                    nrRntrc,
                  });
                }}
              />
            </div>
            <div className='col-1'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={transportadoraData?.flgAtivo}
                label='Ativo'
                onChange={(flgAtivo: boolean) =>
                  setTransportadoraData({
                    ...transportadoraData,
                    flgAtivo,
                  })
                }
              />
            </div>
            <div className='col-3'>
              {/* @ts-expect-error */}
              <RadioButton
                label='Tipo'
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={transportadoraData?.noTipoTransporte}
                buttons={tipoTransporte}
                onChange={(tipo: any) => {
                  setTransportadoraData({
                    ...transportadoraData,
                    noTipoTransporte: tipo,
                    // flgTipoTransporte: tipo,
                    flgTipo: tipo,
                  });
                }}
              />
            </div>

            <div className='col-2'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={transportadoraData?.flgControlaMotorista}
                label='Controla Motorista'
                onChange={(flgControlaMotorista: boolean) =>
                  setTransportadoraData({
                    ...transportadoraData,
                    flgControlaMotorista,
                  })
                }
              />
            </div>
            <div className='col-2'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={transportadoraData?.flgControlaVeiculo}
                label='Controla Veículo'
                onChange={(flgControlaVeiculo: boolean) =>
                  setTransportadoraData({
                    ...transportadoraData,
                    flgControlaVeiculo,
                  })
                }
              />
            </div>

            <div className='col-2'>
              {/* @ts-expect-error */}
              <RadioButton
                label='Tipo Transportadora'
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                buttons={tipoTransportadora}
                selectedButton={transportadoraData?.noTipoTransportadora}
                onChange={(tipo: any) => {
                  setTransportadoraData({
                    ...transportadoraData,
                    noTipoTransportadora: tipo,
                    nrTipoTransportadora: tipo,
                  });
                }}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </div>
  );
};

export default TransportadoraPanel;
