export default class PessoaJuridica {
  constructor(jsonObject = {}) {
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.nrCnpj = jsonObject.nrCnpj;
    this.iEstadual = jsonObject.iEstadual;
    this.ieMunicipal = jsonObject.ieMunicipal;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqEstadoIe = jsonObject.nrSeqEstadoIe;
    this.flgEmpresaPublica = jsonObject.flgEmpresaPublica;
    this.flgComercialExportadora = jsonObject.flgComercialExportadora;
    this.flgOptanteSimples = jsonObject.flgOptanteSimples;
    this.noInscricaoSuframa = jsonObject.noInscricaoSuframa;
    this.noFantasia = jsonObject.noFantasia;
  }

  toJson = () => JSON.stringify(this);
}
