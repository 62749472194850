import React, { useState, useEffect } from 'react';
import {
  BootstrapSizes,
  MimeTypes,
  Theme,
  ResponseStatus,
} from 'ui/Helpers/utils';
import { postUploadClientes } from 'core/services/FIN/cliente';
import { Button, FileUpload, Loader, Modal, Notification } from 'ui/components';

export default function ModalImportacaoClientes({ show, onCancel, onSuccess }) {
  const [importedFile, setImportedFile] = useState([]);
  const [message, setMessages] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    setImportedFile([]);
    setMessages(null);
    setLoading(false);
  }, [show]);

  const download = (filename, text) => {
    const element = document.createElement('a');

    const link = `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`;

    element.setAttribute('href', link);

    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const uploadFile = async () => {
    setLoading(true);
    if (!importedFile)
      setMessages({
        message: 'Não há arquivos selecionados para importação.',
        theme: Theme.Danger,
      });
    else {
      const { status, message: msg, clientes } = await postUploadClientes(
        importedFile[0]
      );

      if (status !== ResponseStatus.Success)
        setMessages({
          message: msg,
          theme:
            status === ResponseStatus.Warning ? Theme.Warning : Theme.Danger,
        });
      else {
        onSuccess(clientes);
        download('erros.txt', msg);
      }
    }
    setLoading(false);
  };

  return (
    <Modal
      show={show}
      title='Importação de Clientes'
      icon='cloud-upload-alt'
      size={BootstrapSizes.Large}
      onClose={onCancel}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessages()}
            />
          </div>
        )}
        <div className='alert alert-info fs-8' role='alert'>
          <b>Descrição do arquivo importado</b>
          <p>
            O arquivo a ser importado deverá ser um arquivo csv constando
            obrigatoriamente o <b>CPF</b> e se desejado, qualquer outra campo.
            Abaixo a descrição dos campos aceitos na importação:
          </p>
          <p>
            A primeira linha do arquivo deve conter o cabeçalho de cada coluna,
            o nome deve estar EXATAMENTE como o detalhamento abaixo.
          </p>
          <b>CPF</b> Campo obrigatório contendo o CPF do cliente.
          <br /> <b>NOME</b> Campo contendo o Nome do cliente.
          <br /> <b>RG</b> Campo contendo o RG do cliente.
          <br /> <b>MATRICULA</b> Campo contendo a Matricula do cliente.
          <br /> <b>CODIGO</b> Campo contendo o Código do cliente.
          <br />
          <b>NASCIMENTO</b> Campo contendo a Data de Nascimento do cliente no
          formato <b>DD/MM/AAAA</b>.
          <br />
          <b>CEP</b> Campo contendo o CEP do cliente no formato
          <b> 00000-000</b>.
          <br />
          <b>BAIRRO</b> Campo contendo o Bairro do cliente.
          <br />
          <b>ENDERECO</b> Campo contendo a Rua/Avenida em que reside o cliente.
          <br />
          <b>NUMERO</b> Campo contendo o número da casa em que reside o cliente.
        </div>

        <FileUpload
          files={importedFile}
          onChange={(files) => setImportedFile(files)}
          allowedMimeTypes={[MimeTypes.Types.Excel]}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Importar'
          icon='check'
          theme={Theme.Success}
          onClick={uploadFile}
        />
        <Button
          text='Cancelar'
          icon='ban'
          theme={Theme.Danger}
          onClick={onCancel}
        />
      </Modal.Footer>
    </Modal>
  );
}
