import qs from 'qs';
import Pagination from 'core/models/pagination';
import ColetaRemetente from 'core/models/FRO/coletaRemetente';
import api from '../api';

const baseURL = `/FRO/ColetaRemetente`;

export const getColetaRemetenteList = async (filters) => {
  const response = await api.get(`/$ColetaRemetente/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ColetaRemetente(json)) ?? [],
  };
};

export const getColetaRemetentePagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ColetaRemetente(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getColetaRemetente = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ColetaRemetente(value) : {};
};

export const saveColetaRemetente = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const getRemetenteCPF = async (cpf) => {
  const response = await api.get(`${baseURL}/GetByCPF`, {
    params: cpf,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    coletaRemetente: new ColetaRemetente(value),
  };
};

export const getRemetenteCNPJ = async (cnpj) => {
  const response = await api.get(`${baseURL}/GetByCNPJ`, {
    params: cnpj,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    coletaRemetente: new ColetaRemetente(value),
  };
};

export const deleteColetaRemetente = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printColetaRemetente = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelColetaRemetente = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllColetaRemetente = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
