import qs from 'qs';
import Pagination from 'core/models/pagination';
import AcertoMotoristaTitulo from 'core/models/FIN/acertoMotoristaTitulo';
import api from '../api';

const baseURL = `/FIN/AcertoMotoristaTitulo`;

export const getAcertoMotoristaTituloList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AcertoMotoristaTitulo(json)) ?? [],
  };
};

export const GetAcertoMotoristaTituloSemAcerto = async (filters) => {
  const response = await api.post(
    `${baseURL}/GetAcertoMotoristaTituloSemAcerto`,
    filters
  );
  const { value } = response.data ?? {};
  return value;
};

export const SaveWithFinancial = async (data) => {
  const response = await api.post(`${baseURL}/SaveWithFinancial`, data);
  return response.data ?? {};
};

export const getAcertoMotoristaTituloPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AcertoMotoristaTitulo(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getAcertoMotoristaTitulo = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new AcertoMotoristaTitulo(value) : {};
};

export const saveAcertoMotoristaTitulo = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteAcertoMotoristaTitulo = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printAcertoMotoristaTitulo = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelAcertoMotoristaTitulo = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllAcertoMotoristaTitulo = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
