import InfracaoGravidade from './infracaoGravidade';
import Tipoinfrator from './tipoinfrator';

export default class Infracao {
  nrSeqInfracao: number;

  noInfracao: string;

  cdInfracao: string;

  nrSeqInfracaoGravidade: number;

  nrSeqTipoInfrator: number;

  nrSeqGrupoEmpresa: number;

  flgAtivo: boolean;

  tipoInfrator: Tipoinfrator;

  infracaoGravidade: InfracaoGravidade;

  constructor(jsonObject: Infracao) {
    this.nrSeqInfracao = jsonObject.nrSeqInfracao;
    this.noInfracao = jsonObject.noInfracao;
    this.cdInfracao = jsonObject.cdInfracao;
    this.nrSeqInfracaoGravidade = jsonObject.nrSeqInfracaoGravidade;
    this.nrSeqTipoInfrator = jsonObject.nrSeqTipoInfrator;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgAtivo = jsonObject.flgAtivo;
    this.tipoInfrator = jsonObject.tipoInfrator;
    this.infracaoGravidade = jsonObject.infracaoGravidade;
  }

  toJson = () => JSON.stringify(this);
}
