import MarcaaparelhoCelular from './marcaAparelhoCelular';

export default class Modeloaparelhocelular {
  constructor(jsonObject = {}) {
    this.nrSeqModeloAparelho = jsonObject.nrSeqModeloAparelho;
    this.nrSeqMarcaAparelho = jsonObject.nrSeqMarcaAparelho;
    this.cdModeloAparelho = jsonObject.cdModeloAparelho;
    this.noModeloAparelho = jsonObject.noModeloAparelho;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.marcaAparelhoCelular = new MarcaaparelhoCelular(
      jsonObject.MarcaAparelhoCelular ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
