import React, { useRef, useState, useEffect } from 'react';

import { Button, GridView, Modal, Notification, Textbox } from 'ui/components';
import {
  Theme,
  BootstrapSizes,
  dateNow,
  ResponseStatus,
} from 'ui/Helpers/utils';
import { getValidacaoModalOcorrencia } from 'core/services/FIN/tituloPagar';
import TituloPagarOcorrencia from 'core/models/FIN/tituloPagarOcorrencia';

export default function ModalOcorrencias(props) {
  const [data, setData] = useState(new TituloPagarOcorrencia());
  const [messageState, setMessage] = useState(null);
  const gridView = useRef();

  const { show, onSave, ocorrencias } = props;

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  useEffect(() => {
    gridView?.current?.setDataSource(ocorrencias);
  }, [show]);

  const onClickRemoveOcorrencia = async (selectedValue, datasource) => {
    let exclusaoParcela = [];
    if (selectedValue.nrSeqTituloPagarOcorrencia === undefined) {
      exclusaoParcela = datasource.filter(
        (Pagamento) => Pagamento !== selectedValue
      );
    } else {
      exclusaoParcela = datasource.filter(
        (Pagamento) => Pagamento !== selectedValue
      );
      const parcelaexclusao = datasource.filter(
        (Pagamento) => Pagamento === selectedValue
      );
      parcelaexclusao[0].status = GridView.EnumStatus.Remover;
      exclusaoParcela.push(parcelaexclusao[0]);
    }
    if (gridView && gridView.current)
      gridView.current.setDataSource(exclusaoParcela);
  };

  const onAdicionarOcorrencia = async () => {
    if (data.noOcorrencia === undefined) {
      onSetMessage(
        ResponseStatus.Error,
        'Adicione qual o motivo da ocorrência'
      );
      return;
    }
    let lista = gridView.current.getDataSource();
    if (!lista) lista = [];
    data.dtCadastro = dateNow();
    const obj = await getValidacaoModalOcorrencia(data);
    data.usuario.noLogin = obj.usuario.noLogin;
    data.nrSeqUsuarioCad = obj.usuario.nrSeqUsuario;
    lista.push(data);
    if (gridView && gridView.current) gridView.current.setDataSource(lista);
    setData(new TituloPagarOcorrencia());
  };

  const salvar = async () => {
    const lista = gridView?.current?.getDataSource();
    onSave(lista);
    props.onClose();
  };
  const columns = [
    {
      key: 'dtCadastro',
      title: 'Cadastro',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'usuario.noLogin',
      title: 'Usuario',
    },
    {
      key: 'noOcorrencia',
      title: 'Ocorrência',
    },
    {
      key: 'nrSeqTituloPagarOcorrencia',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveOcorrencia(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <Modal
      show={show}
      title='Ocorrências'
      size={BootstrapSizes.Large}
      icon='envelope'
    >
      <Modal.Body className='p-4 pt-3'>
        {messageState && (
          <div className='mb-3'>
            <Notification
              message={messageState.message}
              theme={messageState.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col-10 mb-3'>
            <Textbox
              label='Ocorrências'
              text={data.noOcorrencia}
              onChangedValue={(noOcorrencia) =>
                setData({ ...data, noOcorrencia })
              }
            />
          </div>
          <div className='col-2 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Adicionar'
              onClick={onAdicionarOcorrencia}
            />
          </div>
        </div>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>

        <div className='row'>
          <div className='col-2 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Salvar'
              onClick={salvar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
