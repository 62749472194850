import Chip from 'core/models/TEL/chip';
import CentroDeCusto from 'core/models/TEL/centroDeCusto';

export default class VendedorTelefoniaComissao {
  constructor(jsonObject = {}) {
    this.nrSeqContaRecurso = jsonObject.nrseqContaRecurso;
    this.nrSeqChip = jsonObject.nrSeqChip;
    this.nrSeqVendedorTelefonia = jsonObject.nrSeqVendedorTelefonia;
    this.nrSeqTituloPagar = jsonObject.nrSeqTituloPagar;
    this.dtCad = jsonObject.dtCad;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.vlrComissao = jsonObject.vlrComissao;
    this.nrSeqVendedorTelefoniaComissao =
      jsonObject.nrSeqVendedorTelefoniaComissao;
    this.nrSeqContratoAgrupado = jsonObject.nrSeqContratoAgrupado;
    this.nrSeqCentroDeCusto = jsonObject.nrSeqCentroDeCusto;
    this.chip = new Chip(jsonObject.chip ?? {});
    this.centroDeCusto = new CentroDeCusto(jsonObject.centroDeCusto ?? {});
  }

  toJson = () => JSON.stringify(this);
}
