export const MaskTypes = {
  Competence: 0,
  Currency: 1,
  Decimal: 2,
  Integer: 3,
  Date: 4,
  CPF: 5,
  CNPJ: 6,
  CellPhone: 7,
  Time: 8,
  DecimalCustom: 9,
  TimeHM: 10,
  Inch: 11,
};

const getDecimalCustomValue = (text, decimalPlaces) => {
  let output = String(text);

  if (decimalPlaces) {
    if (output.includes('.') && output.includes(',')) {
      output = output.replace('.', '');
    }
    output = Number(output.replace(',', '.')).toFixed(decimalPlaces);
    output = String(output).replace('.', ',');
  }

  return output;
};

const getDecimalValue = (text, decimalPlaces = 2) => {
  const isNegative = text.toString().indexOf('-') >= 0;
  let output =
    (typeof text === 'number' ? text.toFixed(decimalPlaces) : text)
      ?.toString()
      .replace(/\D/g, '') ?? '0';

  const newPlaces = decimalPlaces - output.length;
  const exp = new RegExp(`^(\\d+)(\\d{${decimalPlaces}}).*`);

  if (output.length <= decimalPlaces)
    output = `0.${'0'.repeat(newPlaces < 0 ? 0 : newPlaces)}${output}`;
  else output = output.replace(exp, '$1.$2');

  if (isNegative) output *= -1;
  return output;
};

const maskCompetence = (text) => {
  const output = text?.replace(/\D/g, ''); // Remove all non digits
  return output?.replace(/^(\d{2})(\d{1,4}).*/, '$1/$2'); // add a slash between 2º and 3º digit
};
const maskDate = (text) => {
  const output = text?.replace(/\D/g, ''); // Remove all non digits
  if (output.length > 4)
    return output?.replace(/^(\d{2})(\d{2})(\d{1,4}).*/, '$1/$2/$3');
  if (output.length > 2) return output?.replace(/^(\d{2})(\d{1,2}).*/, '$1/$2');

  return output;
};

const maskTime = (text) => {
  const output = text?.replace(/\D/g, ''); // Remove all non digits
  if (output.length > 4)
    return output?.replace(/^(\d{2})(\d{2})(\d{2}).*/, '$1:$2:$3');

  return output;
};

const maskTimeHM = (text) => {
  const output = text?.replace(/\D/g, ''); // Remove all non digits
  if (output.length > 2) return output?.replace(/^(\d{2})(\d{2}).*/, '$1:$2');

  return output;
};

const maskCPF = (text) => {
  if (text) {
    if (String(text) !== '[object Object]') {
      const output = text?.replace(/\D/g, '');
      return output?.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3.$4');
    }
    return '';
  }
  return '';
};

const maskCNPJ = (text) => {
  if (text) {
    if (String(text) !== '[object Object]') {
      const output = text?.replace(/\D/g, '');
      return output?.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
        '$1.$2.$3/$4-$5'
      );
    }
    return '';
  }
  return '';
};

const maskCellPhone = (text) => {
  if (text) {
    if (String(text) !== '[object Object]') {
      const output = text?.replace(/\D/g, '');
      return output?.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3');
    }
    return '';
  }
  return '';
};

const maskCurrency = (text, decimalPlaces = 2) => {
  const output = getDecimalValue(text, decimalPlaces);

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
  return formatter.format(output);
};
const maskDecimalCustom = (text, decimalPlaces = 2, onchange) => {
  const output = getDecimalCustomValue(text, decimalPlaces, onchange);

  return output;
};
const maskDecimal = (text, decimalPlaces = 2) => {
  const output = getDecimalValue(text);
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
  return formatter.format(output);
};
const maskInteger = (text) => {
  const output =
    (typeof text === 'number' ? text.toFixed(0) : text)
      ?.toString()
      .replace(/\D/g, '') ?? '0';

  return parseInt(output, 10);
};
export const mask = (text, maskType, decimalPlaces, onchange, zeroInBlank) => {
  if (text === undefined || text === null || text === '') return '';

  if (maskType === MaskTypes.Competence) return maskCompetence(text);
  if (maskType === MaskTypes.Currency) return maskCurrency(text, decimalPlaces);
  if (maskType === MaskTypes.Decimal) return maskDecimal(text, decimalPlaces);
  if (maskType === MaskTypes.DecimalCustom && !onchange) {
    return maskDecimalCustom(text, decimalPlaces, onchange, zeroInBlank);
  }

  if (maskType === MaskTypes.Integer) return maskInteger(text);
  if (maskType === MaskTypes.Date) return maskDate(text);
  if (maskType === MaskTypes.CPF) return maskCPF(text);
  if (maskType === MaskTypes.CNPJ) return maskCNPJ(text);
  if (maskType === MaskTypes.CellPhone) return maskCellPhone(text);
  if (maskType === MaskTypes.Time) return maskTime(text);
  if (maskType === MaskTypes.TimeHM) return maskTimeHM(text);
  return text;
};
export const unMask = (text, maskType) => {
  if (text === '' && maskType === MaskTypes.DecimalCustom) return undefined;
  if (!text) return '';

  if (maskType === MaskTypes.Competence) return text;
  if (
    maskType === MaskTypes.Currency ||
    maskType === MaskTypes.Decimal ||
    maskType === MaskTypes.DecimalCustom
  ) {
    return parseFloat(
      text
        .replaceAll('.', '')
        .replace(',', '.')
        .replace(/[^0-9-.]/g, '')
    );
  }
  if (maskType === MaskTypes.CellPhone) return text.replace(/[^0-9]/g, '');
  return text;
};
