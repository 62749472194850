import React from 'react';

import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { Button, Modal } from 'ui/components';

export default function ModalMsgEnvioOperadora({
  show,
  onClose,
  onEnvioOperadora,
}) {
  const onEnviar = () => {
    onEnvioOperadora();
  };

  return (
    <Modal
      show={show}
      title='Enviar para Operadora MVNO'
      onClose={onClose}
      size={BootstrapSizes.Medium}
    >
      <Modal.Body className='p-4 pt-3'>
        <h4>Deseja enviar a demanda para a operadora MVNO?</h4>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Sim'
          theme={Theme.Success}
          icon={['far', 'save']}
          onClick={() => onEnviar()}
        />
        <Button
          text='Fechar'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
