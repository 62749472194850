export default class Perfuracao {
  constructor(jsonObject = {}) {
    this.nrSeqPocMetodoPerfuracao = jsonObject.nrSeqPocMetodoPerfuracao;
    this.cdPocMetodoPerfuracao = jsonObject.cdPocMetodoPerfuracao;
    this.noPocMetodoPerfuracao = jsonObject.noPocMetodoPerfuracao;
    this.flgAtivo = jsonObject.flgAtivo;
  }

  toJson = () => JSON.stringify(this);
}
