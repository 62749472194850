import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';
import { Autocomplete, Button, GridView } from 'ui/components';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { Motorista } from 'core/models/FRO';

const MotoristaStep = forwardRef(({ data, setData, onSetMessage }) => {
  const gridView = useRef();
  const [dataMotorista, setDataMotorista] = useState();

  useEffect(() => {
    if (gridView && gridView.current && data.motoristas) {
      gridView.current.setDataSource(data.motoristas);
    }
  }, []);

  const onSearchMotorista = async (e) => {
    const response = await getMotoristaAutoComplete({
      noPessoa: e,
    });

    return response.motoristas;
  };

  const onClickAddMotorista = async () => {
    if (dataMotorista) {
      const obj = new Motorista({
        ...dataMotorista,
        status: GridView.EnumStatus.Inserir,
      });

      const list = gridView.current.getDataSource();
      list.push(obj);

      if (gridView && gridView.current) gridView.current.setDataSource(list);

      setData({ ...data, motoristas: list });
      setDataMotorista(new Motorista());
    } else {
      onSetMessage(false, 'Informe o Motorista');
    }
  };

  const onClickRemoveMotorista = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const list = datasource.filter((el) => el !== e);

    if (gridItem.status !== GridView.EnumStatus.Inserir) {
      gridItem.status = GridView.EnumStatus.Remover;

      list.push(gridItem);
    }

    if (gridView && gridView.current) gridView.current.setDataSource(list);

    setData({ ...data, motoristas: list });
  };

  const columns = [
    { key: 'nrSeqPessoaMot', visible: false },
    { key: 'noPessoa', title: 'Motorista', sortable: false },
    {
      key: 'nrSeqMotorista',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemoveMotorista(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <div>
      <div className='row mb-3 algin-items-end'>
        <div className='col'>
          <Autocomplete
            label='Motorista'
            searchDataSource={onSearchMotorista}
            selectedItem={dataMotorista}
            onSelectItem={(motorista) => setDataMotorista(motorista)}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col d-flex align-items-end'>
          <Button
            onClick={onClickAddMotorista}
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Adicionar'
          />
        </div>
      </div>
      <div className='row'>
        <GridView
          ref={gridView}
          className='table-striped table-hover table-bordered table-sm'
          dataColumns={columns}
          offlineData
          showPagination={false}
          showSelectSizes={false}
        />
      </div>
    </div>
  );
});

export default MotoristaStep;
