import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { Autocomplete, Button, DatePicker, GridView } from 'ui/components';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { getCentroDeCustoAutoComplete } from 'core/services/TEL';
import { MaskTypes } from 'ui/Helpers/masks';

const VinculoCentroDeCustos = forwardRef(({ data, setData, onSetMessage }) => {
  const gridView = useRef();
  const [placa, setPlaca] = useState({});

  useEffect(() => {
    if (gridView && gridView.current && data.centroDeCustoVinculados) {
      gridView.current.setDataSource(data.centroDeCustoVinculados);
    }
    setPlaca({});
  }, []);

  const onSearchCentroDeCusto = async (e) => {
    const { data: CentroDeCusto } = await getCentroDeCustoAutoComplete({
      noCentroDeCusto: e,
    });

    return CentroDeCusto;
  };

  const onClickAddPlaca = async () => {
    if (
      placa.nrSeqCentroDeCusto === null ||
      placa.nrSeqCentroDeCusto === undefined
    ) {
      onSetMessage(false, 'Informe o centro de custo para prosseguir');
      return;
    }
    if (placa.dtInicial === null || placa.dtInicial === undefined) {
      onSetMessage(false, 'Informe a data inicial para prosseguir');
      return;
    }
    if (placa.dtFinal === null || placa.dtFinal === undefined) {
      onSetMessage(false, 'Informe a data final para prosseguir');
      return;
    }
    const obj = {
      ...placa,
    };

    if (obj.nrSeqVeiculoCentroDeCusto > 0) {
      obj.status = GridView.EnumStatus.Alterar;
    } else {
      obj.status = GridView.EnumStatus.Inserir;
    }

    const list = gridView.current.getDataSource();
    list.push(obj);

    if (gridView && gridView.current) gridView.current.setDataSource(list);

    setPlaca({});
    setData({ ...data, centroDeCustoVinculados: list });
  };

  const onClickEditParcela = async (selectedValue, datasource) => {
    const dataFaturas = datasource.filter((fatura) => fatura !== selectedValue);
    const dataFatura = datasource.filter((fatura) => fatura === selectedValue);

    if (dataFatura[0].nrSeqVeiculoCentroDeCusto > 0) {
      dataFatura[0].status = GridView.EnumStatus.Alterar;
    }
    setPlaca(dataFatura[0]);
    if (gridView && gridView.current)
      gridView.current.setDataSource(dataFaturas);
  };

  const onClickRemovePlaca = async (selectedValue, datasource) => {
    let placas = [];
    if (selectedValue.nrSeqVeiculoCentroDeCusto === undefined) {
      placas = datasource.filter((Placa) => Placa !== selectedValue);
    } else {
      placas = datasource.filter((Placa) => Placa !== selectedValue);
      const dataPlaca = datasource.filter((Placa) => Placa === selectedValue);
      dataPlaca[0].status = GridView.EnumStatus.Remover;
      placas.push(dataPlaca[0]);
    }

    if (gridView && gridView.current) gridView.current.setDataSource(placas);

    setData({ ...data, centroDeCustoVinculados: placas });
  };

  const columns = [
    // { key: 'placa', title: 'Veículo', sortable: false },
    {
      key: 'centroDeCusto.noCentroDeCusto',
      title: 'Centro de custo',
      sortable: false,
    },
    {
      key: 'dtInicial',
      title: 'Data inicial',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'dtFinal',
      title: 'Data Final',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrSeqVeiculoCentroDeCusto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditParcela(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqVeiculoCentroDeCusto',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemovePlaca(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <div>
      <div className='row mb-3'>
        <div className='col-6'>
          <Autocomplete
            label='Centro de custo'
            searchDataSource={onSearchCentroDeCusto}
            selectedItem={placa.centroDeCusto}
            onSelectItem={(centroDeCusto) => {
              setPlaca({
                ...placa,
                centroDeCusto,
                nrSeqCentroDeCusto: centroDeCusto.nrSeqCentroDeCusto,
              });
            }}
            dataSourceTextProperty='noCentroDeCusto'
          />
        </div>
        <div className='col'>
          <DatePicker
            label='Dt Inicial'
            maxLength={10}
            text={placa.dtInicial}
            mask={MaskTypes.Date}
            onChange={(dtInicial) => setPlaca({ ...placa, dtInicial })}
          />
        </div>
        <div className='col'>
          <DatePicker
            label='Dt Final'
            maxLength={10}
            text={placa.dtFinal}
            mask={MaskTypes.Date}
            onChange={(dtFinal) => setPlaca({ ...placa, dtFinal })}
          />
        </div>
        <div className='col d-flex align-items-end'>
          <Button
            onClick={onClickAddPlaca}
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Adicionar'
          />
        </div>
      </div>
      <div className='row'>
        <GridView
          ref={gridView}
          className='table-striped table-hover table-bordered table-sm'
          dataColumns={columns}
          offlineData
          showPagination={false}
          showSelectSizes={false}
        />
      </div>
    </div>
  );
});

export default VinculoCentroDeCustos;
