import React, { useEffect, useState } from 'react';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { Button, Modal, Autocomplete, Notification } from 'ui/components';
import { gerateUser } from 'core/services/FIN/cliente';
import Usuario from 'core/models/SEG/usuario';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getPerfilAutoComplete } from 'core/services/SEG/perfil';
import { ResponseStatus } from 'ui/Helpers/enums.ts';
import { Message } from 'ui/Helpers/interfaces.ts';

export default function ModalGerateUser({ show, ids, onClose }: any) {
  const [usuarioData, setUsuarioData] = useState(new Usuario({}));
  const [message, setMessage] = useState<Message | null>(null);

  const onGerateUser = async () => {
    if (ids.length > 0) {
      if (!usuarioData.nrSeqPerfil || !usuarioData.nrSeqEmpresaDefault) {
        setMessage({
          message: 'Informar o perfil e a empresa para gerar o usuário.',
          theme: Theme.Danger,
        });
        return;
      }

      const { status, message: msg } = await gerateUser({
        ...usuarioData,
        ids: ids.map((row: any) => row[0].value),
      });
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    } else {
      setMessage({
        message: 'Não há registros selecionados para a geração de usuários.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchEmpresaUsuario = async (e: any) => {
    const { empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    return empresas;
  };

  const onSearchPerfil = async (e: any) => {
    const { perfis } = await getPerfilAutoComplete({ noPerfil: e });

    return perfis;
  };

  useEffect(() => {
    setUsuarioData({});
  }, [show]);

  return (
    <Modal
      show={show}
      title='Gerar Usuário'
      size={BootstrapSizes.Large}
      onClose={onClose}
    >
      {message && (
        <div className='mb-3'>
          <Notification
            message={message.message}
            theme={message.theme}
            onClose={() => setMessage(null)}
          />
        </div>
      )}
      <Modal.Body>
        <div className='row mt-2'>
          <div className='col-5'>
            <Autocomplete
              label=' * Empresa'
              searchDataSource={onSearchEmpresaUsuario}
              selectedItem={usuarioData.empresa}
              onSelectItem={(empresa: any) => {
                setUsuarioData({
                  ...usuarioData,
                  empresa,
                  nrSeqEmpresaDefault: empresa.nrSeqEmpresa,
                });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
          <div className='col-5'>
            <Autocomplete
              label='* Perfil Padrão'
              searchDataSource={onSearchPerfil}
              selectedItem={usuarioData.perfil}
              onSelectItem={(perfil: any) => {
                setUsuarioData({
                  ...usuarioData,
                  perfil,
                  nrSeqPerfil: perfil.nrSeqPerfil,
                });
              }}
              dataSourceTextProperty='noPerfil'
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Gerar Usuário'
          icon='check'
          theme={Theme.Success}
          onClick={() => onGerateUser(usuarioData)}
        />
        <Button
          text='Fechar'
          icon='ban'
          theme={Theme.Danger}
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
