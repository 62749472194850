import { IFormaPagamento } from 'core/interfaces/FIN/formaPagamento';
import React, { useEffect, useState, useRef } from 'react';
import {
  Modal,
  Loader,
  Notification,
  Button,
  DatePicker,
  Textbox,
  Autocomplete,
  GridView,
} from 'ui/components';
import {
  BootstrapSizes,
  Theme,
  ResponseStatus,
  // JustifyContent,
} from 'ui/Helpers/enums';
import { Message } from 'ui/Helpers/interfaces';
import { MaskTypes } from 'ui/Helpers/masks';

interface IProps {
  show: boolean;
  data: any;
  setData: (newData: any) => void;
  onClose: () => void;
}

interface ICheque {
  vlrCheque: number;
  formaPagamento: IFormaPagamento | null;
  dtCheque: string | null;
}

interface IGridView {
  setDataSource: (data: ICheque[]) => void;
  getDataSource: () => ICheque[];
}

export default function ModalAdicionarAdtosCheques({
  show,
  data,
  setData,
  onClose,
}: IProps) {
  const [message, setMessage] = useState<Message | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [cheque, setCheque] = useState<ICheque | null>();

  const gridView = useRef<IGridView>();

  const onSetMessage = (status: ResponseStatus, msg: string): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = async () => {
    setLoading(true);

    if (data?.vlrAdiantamento) {
      onSetMessage(
        ResponseStatus.Success,
        `Valor Adiantamento é de R$ ${data.vlrAdiantamento}`
      );

      const chequeAdto: ICheque = {
        vlrCheque: data.vlrAdiantamento,
        formaPagamento: data.formaPagamentoAdto,
        dtCheque: data.dtCheque,
      };

      setCheque({
        vlrCheque: 0,
        formaPagamento: data.formaPagamentoAdto,
        dtCheque: data.dtCheque,
      });

      if (gridView?.current) gridView.current.setDataSource([chequeAdto]);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (show) {
      load();
    }
  }, [show]);

  const validateTotalAmountAdtos = (chequeList: ICheque[]): boolean => {
    const sumAdtos = chequeList.reduce((acc, elem) => acc + elem.vlrCheque, 0);

    if (sumAdtos > data.vlrAdiantamento) {
      onSetMessage(
        ResponseStatus.Error,
        'Valor total dos cheques não pode ser maior que o valor do Adiantamento.'
      );

      return false;
    }

    return true;
  };

  const onHandleAddCheque = () => {
    if (gridView.current) {
      const chequeList = gridView.current.getDataSource();

      if (cheque?.vlrCheque) {
        const chequeAdd: ICheque = { ...cheque };
        chequeList.push(chequeAdd);

        if (validateTotalAmountAdtos(chequeList)) {
          gridView.current.setDataSource(chequeList);
        } else {
          const chequeListOld = chequeList.filter(
            (item: ICheque) => item !== chequeAdd
          );

          gridView.current.setDataSource(chequeListOld);
        }
      } else {
        onSetMessage(ResponseStatus.Error, 'Insira o vaor do cheque.');
      }
    }
  };

  const onClickEditCheque = (e: ICheque, datasource: any) => {
    const chequeEdit = datasource.find((item: ICheque) => item === e);
    const chequesList = datasource.filter((item: ICheque) => item !== e);

    setCheque({ ...chequeEdit });
    if (gridView?.current) gridView.current.setDataSource(chequesList);
  };

  const onClickRemoveCheque = (e: ICheque, datasource: any) => {
    const chequeList = datasource.filter((item: ICheque) => item !== e);

    if (gridView?.current) gridView.current.setDataSource(chequeList);
  };

  const onHandleClose = () => {
    const listaChequesAdto: any[] = [];

    const chequesList: ICheque[] = gridView.current?.getDataSource() || [];

    chequesList.forEach((item: ICheque) => {
      const newTituloPagar = {
        formaPagamento: item.formaPagamento,
        nrseqFormaPagamento: item.formaPagamento?.nrSeqFormaPagamento,
        vlrTitulo: item.vlrCheque,
        dtVencimento: item.dtCheque,
      };

      listaChequesAdto.push(newTituloPagar);
    });

    if (listaChequesAdto.length > 1) setData({ ...data, listaChequesAdto });

    onClose();
  };

  const columnsCheque = [
    {
      title: 'Valor do Cheque',
      key: 'vlrCheque',
      /* @ts-expect-error */
      format: GridView.DataTypes.Money,
    },
    {
      title: 'Data do Cheque',
      key: 'dtCheque',
      /* @ts-expect-error */
      format: GridView.DataTypes.Date,
    },
    {
      title: 'Editar',
      key: 'editCheque',
      /* @ts-expect-error */
      type: GridView.ColumnTypes.Button,
      /* @ts-expect-error */
      onClick: (e, datasource) => onClickEditCheque(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      title: 'Remover',
      key: 'removeCheque',
      /* @ts-expect-error */
      type: GridView.ColumnTypes.Button,
      /* @ts-expect-error */
      onClick: (e, datasource) => onClickRemoveCheque(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    // @ts-expect-error
    <Modal
      show={show}
      title='Cheques de Adiantamento'
      size={BootstrapSizes.Large}
      onClose={onClose}
    >
      <Loader loading={loading} />
      {message && (
        <div className='m-3'>
          {/* @ts-expect-error */}
          <Notification
            message={message.message}
            theme={message.theme}
            onClose={() => setMessage(null)}
          />
        </div>
      )}

      {/* @ts-expect-error */}
      <Modal.Body>
        <div className='row mb-2'>
          <div className='col-2'>
            {/* @ts-expect-error */}
            <Textbox
              label='Valor Adto'
              mask={MaskTypes.DecimalCustom}
              text={data?.vlrAdiantamento}
              readOnly
            />
          </div>

          <div className='col'>
            <Autocomplete
              label='Forma Pagamento'
              selectedItem={cheque?.formaPagamento}
              dataSourceTextProperty='noFormaPagamento'
              readOnly
            />
          </div>

          <div className='col-2'>
            {/* @ts-expect-error */}
            <Textbox
              label='Valor Cheque'
              mask={MaskTypes.DecimalCustom}
              text={cheque?.vlrCheque}
              /* @ts-expect-error */
              onChangedValue={(vlrCheque) =>
                /* @ts-expect-error */
                setCheque({ ...cheque, vlrCheque })
              }
            />
          </div>

          <div className='col-3'>
            <DatePicker
              label='Data Cheque'
              text={cheque?.dtCheque}
              /* @ts-expect-error */
              onChange={(dtCheque) => setCheque({ ...cheque, dtCheque })}
            />
          </div>

          <div className='col-1 d-flex align-items-end'>
            <Button
              // text='Adicionar'
              icon='plus'
              theme={Theme.Success}
              onClick={onHandleAddCheque}
            />
          </div>
        </div>

        <div className='row'>
          <GridView
            /* @ts-expect-error */
            className='table-striped table-hover table-bordered table-sm'
            ref={gridView}
            dataColumns={columnsCheque}
            showSelectSizes={false}
            showPagination={false}
          />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button text='Fechar' theme={Theme.Success} onClick={onHandleClose} />
      </Modal.Footer>
    </Modal>
  );
}
