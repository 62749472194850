import React, { useRef, useState } from 'react';
import { ResponseStatus, Theme } from 'ui/Helpers/utils';
import { getAlmoxarifadoAutoComplete } from 'core/services/EST/almoxarifado';
import {
  getAlmoxarifadoLocalAutoComplete,
  getAlmoxarifadoLocalPaginedRelSaldoProduto,
  printAlmoxarifadoLocalRelSaldoProduto,
  excelAlmoxarifadoLocalRelSaldoProduto,
} from 'core/services/EST/almoxarifadoLocal';
import { getProdutoAutoComplete } from 'core/services/EST/produto';
import {
  CSDSelPage,
  GridView,
  Autocomplete,
  Switch,
  ToolbarButtons,
} from 'ui/components';

const columns = [
  {
    key: 'nrSeqAlmoxarifadoLocal',
    type: GridView.ColumnTypes.Checkbox,
    visible: false,
  },
  {
    key: 'almoxarifado.noAlmoxarifado',
    title: 'Almoxarifado',
  },
  { key: 'noLocalCompleto', title: 'Caminho' },
  {
    key: 'noProduto',
    title: 'Produto',
  },
  {
    key: 'saldoAtual',
    title: 'Saldo',
  },
];

export default function RelAlmoxarifadoLocalSaldoItem({
  transaction,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async () => {
    setLoading(true);

    const {
      data,
      pagination,
    } = await getAlmoxarifadoLocalPaginedRelSaldoProduto({
      nrSeqProduto: filtros.nrSeqProduto,
      nrSeqAlmoxarifado: filtros.nrSeqAlmoxarifado,
      nrSeqAlmoxarifadoLocal: filtros.nrSeqAlmoxarifadoLocal,
      flgSomenteComSaldo: filtros.flgSomenteComSaldo,
    });

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  const onSearchAlmoxarifadoAutoComplete = async (e) => {
    const {
      status,
      message: msg,
      data: almoxarifados,
    } = await getAlmoxarifadoAutoComplete({
      noAlmoxarifado: e,
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifados;
  };
  const onSearchAlmoxarifadoLocalAutoComplete = async (e) => {
    if (!filtros.nrSeqAlmoxarifado) {
      onSetMessage(ResponseStatus.Error, 'Selecione um almoxarifado');
      return [];
    }
    if (!filtros.nrSeqProduto) {
      onSetMessage(ResponseStatus.Error, 'Selecione um produto');
      return [];
    }
    const {
      status,
      message: msg,
      almoxarifados,
    } = await getAlmoxarifadoLocalAutoComplete({
      noAlmoxarifado: e,
      flgAtivo: true,
      flgNotItem: true,
      nrSeqProduto: filtros.nrSeqProduto,
      nrSeqAlmoxarifado: filtros.nrSeqAlmoxarifado,
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifados;
  };
  const onSearchProduto = async (e) => {
    if (!filtros.nrSeqAlmoxarifado) {
      onSetMessage(ResponseStatus.Error, 'Selecione o almoxarifado');
      return [];
    }
    if (e.length < 3) {
      onSetMessage(ResponseStatus.Error, 'Digite ao menos 3 caracteres');
      return [];
    }

    const {
      status,
      message: msg,
      data: produtos,
    } = await getProdutoAutoComplete({
      noSearchField: e,
      flgAtivo: true,
      nrSeqAlmoxarifado: filtros.nrSeqAlmoxarifado,
    });
    if (msg) onSetMessage(status, msg);
    return produtos;
  };
  const onPrint = async (param) => {
    setLoading(true);
    const obj = { ...filtros, noRelatorio: param };
    const { value } = await printAlmoxarifadoLocalRelSaldoProduto(obj);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onDownloadExcel = async (param) => {
    setLoading(true);
    const obj = { ...filtros, noRelatorio: param };
    const { value: base64 } = await excelAlmoxarifadoLocalRelSaldoProduto(obj);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'AlmoxarifadoLocalRelSaldoProduto.csv';
      downloadLink.click();
    }

    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Relatório Saldo Produto'
      loading={loading}
      onSearch={() => search()}
      onPrint={() => onPrint('Imprimir')}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.ButtonList
          icon='caret-down'
          items={[
            { text: 'Imprimir 2', onClick: () => onPrint('Imprimir2') },
            {
              text: 'Saldo Por Nota',
              onClick: () => onPrint('SaldoPorNota'),
            },
            {
              text: 'Saldo Por Nota 2',
              onClick: () => onPrint('SaldoPorNota2'),
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Excel'
          icon={['far', 'file-excel']}
          onClick={() => onDownloadExcel('Excel')}
        />
        <ToolbarButtons.ButtonList
          icon='caret-down'
          items={[
            {
              text: 'Excel 2',
              onClick: () => onDownloadExcel('Excel2'),
            },
          ]}
        />
      </ToolbarButtons>
      <div className='row'>
        <div className='col-5 mb-2'>
          <Autocomplete
            label='Almoxarifado'
            searchDataSource={onSearchAlmoxarifadoAutoComplete}
            selectedItem={filtros.almoxarifado}
            onSelectItem={(almoxarifado) => {
              setFiltros({
                ...filtros,
                almoxarifado,
                nrSeqAlmoxarifado: almoxarifado.nrSeqAlmoxarifado,
              });
            }}
            dataSourceTextProperty='noAlmoxarifado'
          />
        </div>

        <div className='col-5 mb-2'>
          <Autocomplete
            label='Produtos'
            searchDataSource={onSearchProduto}
            selectedItem={filtros.produto}
            onSelectItem={(produto) => {
              setFiltros({
                ...filtros,
                produto,
                nrSeqProduto: produto.nrSeqProduto,
              });
            }}
            dataSourceTextProperty='noProduto'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-5  mb-2'>
          <Autocomplete
            label='Almoxarifado Local'
            searchDataSource={onSearchAlmoxarifadoLocalAutoComplete}
            selectedItem={filtros.almoxarifadoLocal}
            onSelectItem={(almoxarifadoLocal) => {
              setFiltros({
                ...filtros,
                almoxarifadoLocal,
                nrSeqAlmoxarifadoLocal:
                  almoxarifadoLocal.nrSeqAlmoxarifadoLocal,
                noAlmoxarifadoLocal: almoxarifadoLocal.noAlmoxarifadoLocal,
              });
            }}
            dataSourceTextProperty='noAlmoxarifadoLocal'
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Somente com Saldo'
            checked={filtros.flgSomenteComSaldo}
            onChange={(flgSomenteComSaldo) =>
              setFiltros({ ...filtros, flgSomenteComSaldo })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
