import React, { useState, useEffect, useRef } from 'react';
import { setTransaction } from 'core/services/api';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  Button,
  Modal,
  Notification,
  GridView,
  DatePicker,
  Autocomplete,
} from 'ui/components';
import { postSaveOcorrencia } from 'core/services/FIN';
import { getTipoOcorrenciaBoletoAutoComplete } from 'core/services/FIN/tipoOcorrenciaBoleto';

export default function ModalGeraOcorrencia({
  show,
  onClose,
  transaction,
  faturas,
  retornoSave,
}) {
  const [message, setMessage] = useState(null);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const gridView = useRef();

  const columns = [
    { key: 'nrSeqFatura', type: GridView.ColumnTypes.Checkbox, visible: false },
    { key: 'nrSeqGrupoEmpresa', title: 'NrSeqGrupoEmpresa', visible: false },
    { key: 'nrSeqEmpresa', title: 'NrSeqEmpresa', visible: false },
    { key: 'cdFatura', title: 'Nr. Fat' },
    {
      key: 'cliente.noPessoa',
      title: 'Cliente',
    },
    {
      key: 'empresa.noPessoa',
      title: 'Empresa',
    },

    {
      title: 'Vencimento',
      visible: false,
      key: 'dtVencimento',
      format: GridView.DataTypes.Date,
    },

    {
      key: 'noFormaPagamento',
      title: 'Forma de Pagamento',
      visible: false,
    },
    {
      key: 'vlrFatura',
      title: 'Valor',
      format: GridView.DataTypes.Money,
    },
    { key: 'nrSeqPessoaCli', title: 'NrSeqPessoaCli', visible: false },
    {
      key: 'dtFatura',
      title: 'DtFatura',
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'vlrDesconto',
      title: 'VlrDesconto',
      format: GridView.DataTypes.Money,
      visible: false,
    },
    {
      key: 'vlrAcrescimo',
      title: 'VlrAcrescimo',
      format: GridView.DataTypes.Money,
      visible: false,
    },
  ];

  const onSearchTipoOcorrencia = async (e) => {
    const nrSeqsFaturas = faturas.map((item) => item.nrSeqFatura);
    const { tiposocorrencia } = await getTipoOcorrenciaBoletoAutoComplete({
      noTipoOcorrenciaBoleto: e,
      nrSeqFormaPagamento: data.nrSeqFormaPagamento,
      flgRemessa: true,
      nrSeqFatura: nrSeqsFaturas[0],
    });

    return tiposocorrencia;
  };

  const onSaveGeraOcorrencia = async () => {
    setLoading(true);
    const lista = gridView.current.getDataSource();
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);
    const obj = {
      faturas: lista,
      nrSeqTipoOcorrenciaBoleto: data.nrSeqTipoOcorrenciaBoleto,
      dtVencimento: data.dtVencimento,
    };
    const retorno = await postSaveOcorrencia(obj);
    setLoading(false);
    retornoSave(retorno);
  };

  useEffect(async () => {
    if (show) {
      if (gridView && gridView.current) gridView.current.setDataSource(faturas);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      loading={loading}
      title='Registrar Ocorrência de Boletos'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body className='p-4 pt-3'>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3' style={{ height: '130px' }}>
          <div className='col-4'>
            <Autocomplete
              label='Tipo Ocorrência'
              searchDataSource={onSearchTipoOcorrencia}
              selectedItem={data.tipoocorrenciaboleto}
              onSelectItem={(tipoocorrenciaboleto) => {
                setData({
                  ...data,
                  tipoocorrenciaboleto,
                  nrSeqTipoOcorrenciaBoleto:
                    tipoocorrenciaboleto.nrSeqTipoOcorrenciaBoleto,
                });
              }}
              dataSourceTextProperty='noTipoOcorrenciaBoleto'
            />
          </div>
          {data.tipoocorrenciaboleto?.flgAlteraVencimento && (
            <div className='col'>
              <DatePicker
                label='Vencimento'
                text={data.dtVencimento}
                mask={MaskTypes.Date}
                onChange={(dtVencimento) => setData({ ...data, dtVencimento })}
              />
            </div>
          )}
        </div>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Registra Ocorrências'
          theme={Theme.Success}
          icon={['far', 'save']}
          onClick={() => onSaveGeraOcorrencia()}
        />
        <Button
          text='Fechar'
          theme={Theme.Danger}
          icon='ban'
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
