import qs from 'qs';
import Pagination from 'core/models/pagination';
import AprovaFinanceiro from 'core/models/FIN/aprovaFinanceiro';
import api from '../api';

const baseURL = `/FIN/aprovaFinanceiro`;

export const getAprovaFinanceiroList = async (filters) => {
  const response = await api.get(`/$aprovaFinanceiro/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AprovaFinanceiro(json)) ?? [],
  };
};

export const getAprovaFinanceiroPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    dataPendencias: value?.map((json) => new AprovaFinanceiro(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getAprovaFinanceiro = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new AprovaFinanceiro(value) : {};
};

export const saveAprovaReprova = async (data) => {
  const response = await api.post(`${baseURL}/Approves`, data);

  const { data: dataaprov, status, message } = response.data ?? {};

  return { data: dataaprov, status, message };
};

export const saveCancela = async (data) => {
  const response = await api.post(`${baseURL}/Cancel`, data);

  const { data: dataaprov, status, message } = response.data ?? {};

  return { data: dataaprov, status, message };
};

export const deleteAprovaFinanceiro = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printAprovaFinanceiro = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelAprovaFinanceiro = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllAprovaFinanceiro = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
