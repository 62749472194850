import React, { useRef, useState } from 'react';
import {
  PageTypes,
  Theme,
  ResponseStatus,
  BootstrapSizes,
} from 'ui/Helpers/utils';

import {
  getProdutoAgrupadorPagined,
  printProdutoAgrupador,
} from 'core/services/EST/produtoAgrupador';
import {
  CSDSelPage,
  GridView,
  Textbox,
  Autocomplete,
  RadioButton,
  ToolbarButtons,
} from 'ui/components';

import { getProdutoGrupoAutoComplete } from 'core/services/EST/produtoGrupo';
import { getProdutoSubGrupoAutoComplete } from 'core/services/EST/produtoSubGrupo';
import { getAlmoxarifadoLocalAutoComplete } from 'core/services/EST/almoxarifadoLocal';
import { getAlmoxarifadoAutoComplete } from 'core/services/EST/almoxarifado';
import { ProdutoCodigo } from 'core/models/EST';
import PanelEtiquetasProdutos from './PanelEtiquetasProdutos';

export default function Produto({ transaction, onOpenPage, isActive }) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({
    noStatus: 'A',
    noMostrarSaldo: 'N',
    noUltimoValor: 'N',
    noUltimoDataCompra: 'N',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const [dataProdutoEtiquetas, setDataProdutoEtiquetas] = useState({
    gerarEtiquetas: false,
    dataEtiquetas: [],
  });

  const Status = [
    { text: 'Todos', value: 'T' },
    { text: 'Ativos', value: 'A' },
    { text: 'Inativos', value: 'I' },
  ];

  const Saldo = [
    { text: 'Sim', value: 'S' },
    { text: 'Não', value: 'N' },
  ];

  const UltimoValor = [
    { text: 'Sim', value: 'S' },
    { text: 'Não', value: 'N' },
  ];

  const UltimaData = [
    { text: 'Sim', value: 'S' },
    { text: 'Não', value: 'N' },
  ];

  const columns = [
    { key: 'nrSeqProduto', type: GridView.ColumnTypes.Checkbox },
    { key: 'noProduto', title: 'Produto' },
    { key: 'produtoUnidadeMedida.cdProdutoUnidadeMedida', title: 'Unidade' },
    {
      key: 'produtoMarca.noProdutoMarca',
      title: 'Marca',
      sortKey: 'noProdutoMarca',
    },
    {
      key: 'produtoGrupo.noProdutoGrupo',
      title: 'Grupo',
      sortKey: 'noProdutoGrupo',
    },
    {
      key: 'produtoSubGrupo.noProdutoSubGrupo',
      title: 'Sub Grupo',
      sortKey: 'noProdutoSubGrupo',
    },
    { key: 'cdPeca', title: 'Código Peça' },
    { key: 'cdProdutoCodigo', title: 'Código Produto' },
    { key: 'noEan', title: 'EAN' },
    { key: 'noCodigoANP', title: 'Código ANP', visible: false },
    { key: 'noDescricaoANP', title: 'Descrição ANP', visible: false },
    {
      key: 'noValorFinal',
      title: 'Último Valor',
    },
    {
      key: 'dtUltimaCompra',
      title: 'Data Última Compra',
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'noSaldo',
      title: 'Saldo',
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchProdutoGrupo = async (e) => {
    const {
      status,
      message: msg,
      data: grupos,
    } = await getProdutoGrupoAutoComplete({
      noProdutoGrupo: e,
    });
    if (msg) onSetMessage(status, msg);
    return grupos;
  };

  const onSearchProdutoSubGrupo = async (e) => {
    const {
      status,
      message: msg,
      data: subGrupos,
    } = await getProdutoSubGrupoAutoComplete({
      noProdutoSubGrupo: e,
      nrSeqProdutoGrupo: filtros.nrSeqProdutoGrupo,
    });
    if (msg) onSetMessage(status, msg);
    return subGrupos;
  };

  const onSearchAlmoxarifadoAutoComplete = async (e) => {
    const {
      status,
      message: msg,
      almoxarifados,
    } = await getAlmoxarifadoLocalAutoComplete({
      noAlmoxarifado: e,
      flgAtivo: true,
      flgNotItem: true,
      nrSeqAlmoxarifado: filtros.nrSeqAlmoxarifado,
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifados;
  };

  const onSearchAlmoxarifadoEmpresaAutoComplete = async (e) => {
    const {
      status,
      message: msg,
      data: almoxarifados,
    } = await getAlmoxarifadoAutoComplete({
      noAlmoxarifado: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifados;
  };
  const onPrint = async () => {
    setLoading(true);
    if (!filtros.nrSeqAlmoxarifadoLocal) {
      onSetMessage(false, 'Necessário selecionar o Almoxarifado Local.');
    } else if (!filtros.nrSeqProdutoGrupo) {
      onSetMessage(false, 'Necessário selecionar o Grupo.');
    } else {
      const { value } = await printProdutoAgrupador(filtros);
      if (value) {
        const linkSource = `data:image/pdf;base64, ${value}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = `DetalhesProdutos.pdf`;
        downloadLink.click();
      } else {
        onSetMessage(false, 'Erro ao Imprimir Relatório');
      }
    }

    setLoading(false);
  };

  const search = async (params = null) => {
    setLoading(true);

    const {
      data,
      pagination,
      message: msg,
      status,
    } = await getProdutoAgrupadorPagined(params ?? filtros);
    if (msg) {
      onSetMessage(status, msg);
    } else {
      onSetMessage(null, null);
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Produto/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqProduto = sourceRow.find((e) => e.key === 'nrSeqProduto');
    onOpenMaintenance(nrSeqProduto.value);
  };

  const onPrintEtiquetas = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);
    const objSelecteds = [];

    selecteds.forEach((item) => {
      objSelecteds.push(
        new ProdutoCodigo({
          nrSeqProduto: item.find((e) => e.key === 'nrSeqProduto').value,
          noProdutoCodigo: item.find((e) => e.key === 'noProduto').value,
          cdProdutoCodigo: item.find((e) => e.key === 'cdProdutoCodigo').value,
          flgPrincipal: true,
          nrSeqGrupoEmpresa: 0,
          cdPeca: item.find((e) => e.key === 'cdPeca').value,
        })
      );
    });

    setDataProdutoEtiquetas({
      gerarEtiquetas: true,
      dataEtiquetas: objSelecteds,
    });
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Produto'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Gerar Etiquetas'
          icon='print'
          onClick={onPrintEtiquetas}
        />
      </ToolbarButtons>

      {dataProdutoEtiquetas.gerarEtiquetas && (
        <div className='row mb-3'>
          <div className='col mb-12'>
            <PanelEtiquetasProdutos
              visible={dataProdutoEtiquetas.gerarEtiquetas}
              data={dataProdutoEtiquetas.dataEtiquetas}
              onClose={() => {
                setDataProdutoEtiquetas({
                  ...dataProdutoEtiquetas,
                  gerarEtiquetas: false,
                });
              }}
              setMessage={setMessage}
              setLoading={setLoading}
            />
          </div>
        </div>
      )}

      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Almoxarifado Empresa'
            searchDataSource={onSearchAlmoxarifadoEmpresaAutoComplete}
            selectedItem={filtros.almoxarifado}
            onSelectItem={(almoxarifado) => {
              setFiltros({
                ...filtros,
                almoxarifado,
                nrSeqAlmoxarifado: almoxarifado.nrSeqAlmoxarifado,
              });
            }}
            dataSourceTextProperty='noAlmoxarifado'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Almoxarifado Local'
            searchDataSource={onSearchAlmoxarifadoAutoComplete}
            selectedItem={filtros.almoxarifadoLocal}
            onSelectItem={(almoxarifadoLocal) => {
              setFiltros({
                ...filtros,
                almoxarifadoLocal,
                nrSeqAlmoxarifadoLocal:
                  almoxarifadoLocal.nrSeqAlmoxarifadoLocal,
              });
            }}
            dataSourceTextProperty='noAlmoxarifadoLocal'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-8'>
          <Textbox
            label='Produto'
            text={filtros.noProduto}
            onChangedValue={(noProduto) =>
              setFiltros({
                ...filtros,
                noProduto,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Textbox
            label='Código da Peça'
            text={filtros.cdPeca}
            onChangedValue={(cdPeca) =>
              setFiltros({
                ...filtros,
                cdPeca,
              })
            }
          />
        </div>
        <div className='col-4'>
          <Textbox
            label='Código do Produto'
            text={filtros.cdProdutoCodigo}
            onChangedValue={(cdProdutoCodigo) =>
              setFiltros({
                ...filtros,
                cdProdutoCodigo,
              })
            }
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Grupo'
            searchDataSource={onSearchProdutoGrupo}
            selectedItem={filtros.produtoGrupo}
            onSelectItem={(produtoGrupo) => {
              setFiltros({
                ...filtros,
                produtoGrupo,
                nrSeqProdutoGrupo: produtoGrupo.nrSeqProdutoGrupo,
              });
            }}
            dataSourceTextProperty='noProdutoGrupo'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Sub Grupo'
            searchDataSource={onSearchProdutoSubGrupo}
            selectedItem={filtros.produtoSubGrupo}
            onSelectItem={(produtoSubGrupo) => {
              setFiltros({
                ...filtros,
                produtoSubGrupo,
                nrSeqProdutoSubGrupo: produtoSubGrupo.nrSeqProdutoSubGrupo,
              });
            }}
            dataSourceTextProperty='noProdutoSubGrupo'
          />
        </div>
      </div>

      <div className='row mb-4'>
        <div className='col-4'>
          <Textbox
            label='Pesquisa Geral (Produto e códigos) usar %'
            text={filtros.noSearchGlobal}
            onChangedValue={(noSearchGlobal) =>
              setFiltros({
                ...filtros,
                noSearchGlobal,
              })
            }
          />
        </div>
        <div className='col-auto'>
          <RadioButton
            outline
            label='Status'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={Status}
            selectedButton={filtros.noStatus}
            onChange={(status) =>
              setFiltros({
                ...filtros,
                noStatus: status,
              })
            }
          />
        </div>
        <div className='col-auto'>
          <RadioButton
            outline
            label='Mostrar Saldo Estoque'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={Saldo}
            selectedButton={filtros.noMostrarSaldo}
            onChange={(saldo) =>
              setFiltros({
                ...filtros,
                noMostrarSaldo: saldo,
              })
            }
          />
        </div>
        <div className='col-auto'>
          <RadioButton
            outline
            label='Mostrar Último Valor Compra'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={UltimoValor}
            selectedButton={filtros.noUltimoValor}
            onChange={(ultimoValor) =>
              setFiltros({
                ...filtros,
                noUltimoValor: ultimoValor,
              })
            }
          />
        </div>
        <div className='col-auto'>
          <RadioButton
            outline
            label='Data Última Compra'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={UltimaData}
            selectedButton={filtros.noUltimoDataCompra}
            onChange={(ultimaData) =>
              setFiltros({
                ...filtros,
                noUltimoDataCompra: ultimaData,
              })
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            canControlVisibility
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            enableExcelExport
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
