import ChecklistValor from 'core/models/FRO/checklistValor';

export default class ChecklistFrotaRetornoModel {
  constructor(jsonObject = {}) {
    this.nrSeqChecklistFrotaRetorno = jsonObject.nrSeqChecklistFrotaRetorno;
    this.nrSeqChecklistValor = jsonObject.nrSeqChecklistValor;
    this.noComplemento = jsonObject.noComplemento;
    this.nrSeqManutencaoChecklist = jsonObject.nrSeqManutencaoChecklist;
    this.nrSeqUsuarioConf = jsonObject.nrSeqUsuarioConf;
    this.nrSeqChecklistCampos = jsonObject.nrSeqChecklistCampos;
    this.noCampo = jsonObject.noCampo;
    this.noGrupo = jsonObject.noGrupo;
    this.cdTipoCampo = jsonObject.cdTipoCampo;
    this.nrSeqChecklistFrota = jsonObject.nrSeqChecklistFrota;
    this.nrSeqChecklistGrupo = jsonObject.nrSeqChecklistGrupo;
    this.checklistValor = new ChecklistValor(jsonObject.checklistValor ?? {});
    this.checklistValores = jsonObject.checklistValores
      ? jsonObject.checklistValores.map(
          (item) => new ChecklistValor(item ?? {})
        )
      : [];
  }

  toJson = () => JSON.stringify(this);
}
