import Pagination from 'core/models/pagination';
import { TituloReceberFaturaOcorrencia } from 'core/models/FIN';
import api from '../api';

const baseURL = 'FIN/TituloReceberFaturaOcorrencia';

export const postTituloReceberFaturaOcorrenciaByTitulosFatura = async (
  parameters
) => {
  const response = await api.post(
    `${baseURL}/SearchByTitulosFatura`,
    parameters
  );

  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloReceberFaturaOcorrencia(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getTituloReceberFaturaOcorrenciaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new TituloReceberFaturaOcorrencia(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const obterOcorrenciasFaturaPorTitulo = async (filters) => {
  const response = await api.get(`${baseURL}/ObterOcorrenciasPorTitulo`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    ocorrencias:
      value?.map((json) => new TituloReceberFaturaOcorrencia(json)) ?? [],
  };
};
