export default class PocPocoDocumentoAnaliseAgua {
  constructor(jsonObject = {}) {
    this.nrSeqPocPocoDocumentoAnaliseAgua =
      jsonObject.nrSeqPocPocoDocumentoAnaliseAgua;
    this.nrSeqPocPoco = jsonObject.nrSeqPocPoco;
    this.noImagem = jsonObject.noImagem;
    this.noTipoArquivo = jsonObject.noTipoArquivo;
    this.noTamanho = jsonObject.noTamanho;
    this.dtCadastro = jsonObject.dtCadastro;
    this.noArquivo = jsonObject.noArquivo;
    this.flgExibeDownload = jsonObject.flgExibeDownload;
  }

  toJson = () => JSON.stringify(this);
}
