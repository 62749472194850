import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  Textarea,
  RadioButton,
  DatePicker,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getManutencaoVeiculoOS,
  createManutencaoVeiculoOS,
  printOSManutencaoVeiculoOS,
  deleteManutencaoVeiculoOS,
} from 'core/services/FRO/manutencaoVeiculoOS';

import {
  getVeiculo,
  getVeiculoAutoCompleteResumido,
} from 'core/services/FRO/veiculo';
import { getCondicaoPagamentoAutoComplete } from 'core/services/FIN/condicaoPagamento';
import { getOficinaAutocomplete } from 'core/services/FRO/oficina';
import { getUsuarioAutoComplete } from 'core/services/SEG/usuario';

const dateOnlyFormat = (str) => {
  const date = new Date(str);
  const f = new Intl.DateTimeFormat('pt-br', {
    dateStyle: 'short',
  });

  return f.format(date);
};

export default function ManutencaoVeiculoOSItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  onOpenReport,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [editable, setEditable] = useState(true);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onNew = async () => {
    const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;

    const { condicoesPagamento } = await getCondicaoPagamentoAutoComplete();

    const today = new Date().toJSON().slice(0, 10);

    setData({
      usuarioSol: sessionUser,
      nrSeqUsuarioSol: sessionUser.nrSeqUsuario,
      usuarioAut: sessionUser,
      nrSeqUsuarioAut: sessionUser.nrSeqUsuario,
      condicaoPagamento: condicoesPagamento[0],
      nrSeqCondicaoPagamento: condicoesPagamento[0].nrSeqCondicaoPagamento,
      dtAbertura: today,
    });

    setEditable(true);
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const res = await getManutencaoVeiculoOS(registryKey);

      setData(res);

      if (res.flgConcluido) {
        setEditable(false);
      } else {
        setEditable(true);
      }

      setLoading(false);
    } else {
      onNew();
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const onPrint = async (obj) => {
    setLoading(true);
    if ('nrSeqManutencaoVeiculoOS' in obj) {
      const { value } = await printOSManutencaoVeiculoOS(obj);

      onOpenReport(transaction.noTransacao, value);
    } else {
      const { value } = await printOSManutencaoVeiculoOS(data);

      onOpenReport(transaction.noTransacao, value);
    }

    setLoading(false);
  };

  const save = async () => {
    setLoading(true);
    const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;

    let obj = { ...data };

    if (data.nrSeqManutencaoVeiculoOS) {
      obj = {
        ...obj,
        usuarioAlt: sessionUser,
        nrSeqUsuarioAlt: sessionUser.nrSeqUsuario,
      };
    } else {
      obj = {
        ...obj,
        usuarioCad: sessionUser,
        nrSeqUsuarioCad: sessionUser.nrSeqUsuario,
      };
    }

    if (!data.flgConcluido || data.flgConcluido === 'N') {
      obj = { ...obj, flgConcluido: false };
    } else {
      obj = { ...obj, flgConcluido: true };
    }

    const { status, message: msg, value } = await createManutencaoVeiculoOS(
      obj
    );

    if (data.flgConcluido === 'S' && status === ResponseStatus.Success) {
      await onPrint(value);
    }

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteManutencaoVeiculoOS(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  // #region SEARCH de AUTOCOMPLETES

  const onSearchVeiculo = async (e) => {
    const {
      status,
      message: msg,
      veiculos,
    } = await getVeiculoAutoCompleteResumido({
      placa: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onSelectVeiculo = async (obj) => {
    const veiculo = await getVeiculo(obj.nrSeqVeiculo);

    setData({ ...data, veiculo, nrSeqVeiculo: veiculo.nrSeqVeiculo });
  };

  const onSearchCondicaoPagamento = async (e) => {
    const {
      status,
      message: msg,
      condicoesPagamento,
    } = await getCondicaoPagamentoAutoComplete({ noCondicaoPagamento: e });
    if (msg) onSetMessage(status, msg);
    return condicoesPagamento;
  };

  const onSearchOficina = async (e) => {
    const { status, message: msg, oficinas } = await getOficinaAutocomplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);
    return oficinas;
  };

  const onSearchUsuario = async (e) => {
    const response = await getUsuarioAutoComplete({
      noLogin: e,
    });
    if (response.message) onSetMessage(response.status, response.message);
    return response.data;
  };
  // #endregion

  const status = [
    {
      text: 'Pendente',
      value: 'N',
    },
    {
      text: 'Concluído',
      value: 'S',
    },
  ];

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Manutenção Veículo OS'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={editable ? save : null}
      onDelete={data?.nrSeqManutencaoVeiculoOS > 0 ? onExcluir : null}
      onPrint={Number.isInteger(registryKey) ? onPrint : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row align-items-end'>
        {/* Codigo da Manutencao OS */}
        <div className='col-1 mb-3'>
          <Textbox
            readOnly
            label='Código'
            text={data.cdManutencaoVeiculoOS}
            mask={MaskTypes.Integer}
            onChangedValue={(cdManutencaoVeiculoOS) =>
              setData({ ...data, cdManutencaoVeiculoOS })
            }
          />
        </div>
        {/* Usuario Solicitante */}
        <div className='col mb-3'>
          <Autocomplete
            required
            readOnly={data.status === 'Concluído'}
            label='Solicitado Por'
            searchDataSource={onSearchUsuario}
            selectedItem={data.usuarioSol}
            onSelectItem={(usuarioSol) => {
              setData({
                ...data,
                usuarioSol,
                nrSeqUsuarioSol: usuarioSol.nrSeqUsuario,
              });
            }}
            dataSourceTextProperty='noLogin'
          />
        </div>
        {/* Usuario que Autorizou */}
        <div className='col mb-3'>
          <Autocomplete
            required
            readOnly={data.status === 'Concluído'}
            label='Autorizado Por'
            searchDataSource={onSearchUsuario}
            selectedItem={data.usuarioAut}
            onSelectItem={(usuarioAut) => {
              setData({
                ...data,
                usuarioAut,
                nrSeqUsuarioAut: usuarioAut.nrSeqUsuario,
              });
            }}
            dataSourceTextProperty='noLogin'
          />
        </div>
        {/* Oficina */}
        <div className='col-5 mb-3'>
          <Autocomplete
            required
            readOnly={data.status === 'Concluído'}
            label='Oficina'
            searchDataSource={onSearchOficina}
            selectedItem={data.pessoaOfi}
            onSelectItem={(pessoaOfi) => {
              setData({
                ...data,
                pessoaOfi,
                nrSeqPessoaOfi: pessoaOfi.nrSeqPessoaOfi,
              });
            }}
            dataSourceTextProperty='noOficina'
          />
        </div>
        {/* Radio de Status */}
        <div className='col mb-3 d-flex flex-column align-items-center'>
          <RadioButton
            readOnly={data.status === 'Concluído'}
            label='Status'
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={data.flgConcluido ? 'S' : 'N'}
            buttons={status}
            onChange={(flgConcluido) => {
              setData({
                ...data,
                flgConcluido,
              });
            }}
          />
        </div>
      </div>

      <div className='row align-items-end'>
        {/* Data Abertura da OS */}
        <div className='col-2 mb-3'>
          <DatePicker
            required
            readOnly={data.status === 'Concluído'}
            label='Data de Abertura'
            text={data.dtAbertura}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtAbertura) => setData({ ...data, dtAbertura })}
          />
        </div>
        {/* Data Fechamento */}
        <div className='col-2 mb-3'>
          <DatePicker
            readOnly
            label='Data de Fechamento'
            text={data.dtFechamento}
            mask={MaskTypes.Date}
          />
        </div>
        {/* Data Saida */}
        <div className='col-2 mb-3'>
          <DatePicker
            required
            readOnly={data.status === 'Concluído'}
            label='Data de Saída'
            text={data.dtSaida}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtSaida) => setData({ ...data, dtSaida })}
          />
        </div>
        {/* Previsao de Retorno */}
        <div className='col-2 mb-3'>
          <DatePicker
            required
            readOnly={data.status === 'Concluído'}
            label='Previsão de Retorno'
            text={data.dtPrevistaRetorno}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtPrevistaRetorno) =>
              setData({ ...data, dtPrevistaRetorno })
            }
          />
        </div>
        {/* Condicao Pagamento */}
        <div className='col mb-3'>
          <Autocomplete
            required
            readOnly={data.status === 'Concluído'}
            label='Condição de Pagamento'
            searchDataSource={onSearchCondicaoPagamento}
            selectedItem={data.condicaoPagamento}
            onSelectItem={(condicaoPagamento) => {
              setData({
                ...data,
                condicaoPagamento,
                nrSeqCondicaoPagamento:
                  condicaoPagamento.nrSeqCondicaoPagamento,
              });
            }}
            dataSourceTextProperty='noCondicaoPagamento'
          />
        </div>
      </div>

      <div className='row align-items-end'>
        {/* Veiculo */}
        <div className='col-2 mb-3'>
          <Autocomplete
            required
            readOnly={data.status === 'Concluído'}
            label='Veículo'
            searchDataSource={onSearchVeiculo}
            selectedItem={data.veiculo}
            onSelectItem={(veiculo) => {
              onSelectVeiculo(veiculo);
            }}
            dataSourceTextProperty='placa'
          />
        </div>
        {/* Veiculos Vinculados */}
        <div className='col-2 mb-3'>
          <Textbox
            readOnly
            disabledTabIndex
            label='Veículos Vinculados'
            text={data.veiculo?.noVeiculosVinculados}
          />
        </div>
        {/* Odômetro */}
        <div className='col-2 mb-3'>
          <Textbox
            required
            readOnly={data.status === 'Concluído'}
            label='Odômetro'
            text={data.nrOdometro}
            mask={MaskTypes.Integer}
            onChangedValue={(nrOdometro) => setData({ ...data, nrOdometro })}
          />
        </div>
        {/* Valor OS */}
        <div className='col-2 mb-3'>
          <Textbox
            required
            readOnly={data.status === 'Concluído'}
            label='Valor do Orçamento'
            text={data.vlrOs ?? 0}
            mask={MaskTypes.Currency}
            onChangedValue={(vlrOs) => setData({ ...data, vlrOs })}
          />
        </div>
        {/* Valor Fechamento OS */}
        <div className='col-2 mb-3'>
          <Textbox
            readOnly={data.status === 'Concluído'}
            label='Valor no Fechamento'
            text={data.vlrFechamentoOS ?? 0}
            mask={MaskTypes.Currency}
            onChangedValue={(vlrFechamentoOS) =>
              setData({ ...data, vlrFechamentoOS })
            }
          />
        </div>
        {/* Valor Deslocamento */}
        <div className='col-2 mb-3'>
          <Textbox
            readOnly={data.status === 'Concluído'}
            label='Valor do Deslocamento'
            text={data.vlrDeslocamento ?? 0}
            mask={MaskTypes.Currency}
            onChangedValue={(vlrDeslocamento) =>
              setData({ ...data, vlrDeslocamento })
            }
          />
        </div>
      </div>

      <div className='row align-items-end'>
        {/* Obs OS */}
        <div className='col mb-3'>
          <Textarea
            readOnly={data.status === 'Concluído'}
            label='Descrição do Serviço'
            text={data.obsOS ?? ''}
            onChangedValue={(obsOS) => setData({ ...data, obsOS })}
          />
        </div>
      </div>
      {!!data.nrSeqManutencaoVeiculoOS && (
        <div className='row'>
          <p className='bg-inactive py-1'>
            {' '}
            Cadastrado por: {data.usuarioCad.noLogin} -{' '}
            {dateOnlyFormat(data.dtCad)}
          </p>
        </div>
      )}
      {!!data.nrSeqManutencaoVeiculoOS && data.nrSeqUsuarioAlt && (
        <div className='row'>
          <p className='bg-inactive py-1'>
            {' '}
            Alterado por: {data.usuarioAlt.noLogin} -{' '}
            {dateOnlyFormat(data.dtAlteracao)}
          </p>
        </div>
      )}
    </CSDManutPage>
  );
}
