import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  GridView,
  Button,
} from 'ui/components';
import {
  getAcertoMotoristaModeloMedia,
  saveAcertoMotoristaModeloMedia,
  deleteAcertoMotoristaModeloMedia,
} from 'core/services/FRO/acertoMotoristaModeloMedia';

import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { MaskTypes } from 'ui/Helpers/masks';
import { buscaMotoristaEVeiculos } from 'core/services/FRO/viagem';

export default function AcertoMotoristaModeloMediaItem({
  registryKey,
  reload,
  transaction,
  onSelectPage,
  isActive,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [media, setMedia] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const gridView = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const obj = await getAcertoMotoristaModeloMedia(registryKey);
      setData({ ...obj });

      if (gridView && gridView.current)
        gridView.current.setDataSource(obj.acertoMotoristaMediaS);

      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);

    const lista = gridView.current ? gridView.current.getDataSource() : [];

    const obj = {
      ...data,
      acertoMotoristaMediaS: lista,
    };

    const { status, message: msg } = await saveAcertoMotoristaModeloMedia(obj);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();

      if (gridView && gridView.current) gridView.current.setDataSource([]);
    }

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onRemoveDocumento = async (selectedValue, datasource) => {
    let documentos = [];
    if (
      selectedValue.nrSeqAcertoMotoristaMedia === undefined ||
      selectedValue.nrSeqAcertoMotoristaMedia === null
    ) {
      documentos = datasource.filter(
        (Documento) => Documento !== selectedValue
      );
    } else {
      documentos = datasource.filter(
        (Documento) => Documento !== selectedValue
      );
      const dataDocumento = datasource.filter(
        (Documento) => Documento === selectedValue
      );
      dataDocumento[0].status = GridView.EnumStatus.Remover;
      documentos.push(dataDocumento[0]);
    }
    if (gridView && gridView.current)
      gridView.current.setDataSource(documentos);
  };

  const columnsAnexos = [
    {
      key: 'nrSeqTituloPagarDocumento',
      visible: false,
    },
    { key: 'mediaDe', title: 'Media De' },
    {
      key: 'mediaAte',
      title: 'Media Ate',
    },
    {
      key: 'vlrPercentual',
      title: 'Valor',
    },
    {
      key: 'nrSeqTituloPagarDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteAcertoMotoristaModeloMedia(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onAdicionarMedia = async () => {
    const lista = gridView.current.getDataSource();
    const listaValidacao = [];

    if (media.mediaDe === null || media.mediaDe === undefined) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar a media com o valor zerado'
      );
      return;
    }

    if (
      media.mediaAte === 0 ||
      media.mediaAte === null ||
      media.mediaAte === undefined
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar a media com o valor final zerado'
      );
      return;
    }

    if (
      media.vlrPercentual === 0 ||
      media.vlrPercentual === null ||
      media.vlrPercentual === undefined
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar com o valor percentual zerado'
      );
      return;
    }

    lista.forEach((elem) => listaValidacao.push(elem));
    listaValidacao.push(media);
    if (media.status !== GridView.EnumStatus.Alterar) {
      media.status = GridView.EnumStatus.Inserir;
    }
    lista.push(media);
    if (gridView && gridView.current) gridView.current.setDataSource(lista);
    setMedia({ mediaDe: 0, mediaAte: 0, vlrPercentual: 0 });
  };

  const onNew = () => {
    setData({});
    if (gridView && gridView.current) gridView.current.setDataSource([]);
    setMedia({ mediaDe: 0, mediaAte: 0, vlrPercentual: 0 });
  };

  const buscaVeiculoEMotorista = async (id) => {
    setLoading(true);

    const { status, message: msg, value } = await buscaMotoristaEVeiculos({
      nrSeqVeiculo: id,
    });

    setLoading(false);

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Error) return {};

    return {
      veiculoComp: value.veiculoCompA ?? {},
      veiculoCompB: value.veiculoCompB ?? {},
      nrSeqVeiculoComp: value.veiculoCompA?.nrSeqVeiculo,
      nrSeqVeiculoCompb: value.veiculoCompB?.nrSeqVeiculo,
    };
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Modelo Media'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      transaction={transaction}
      onDelete={data?.nrSeqAcertoMotoristaModeloMedia > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <div className='row'>
        <div className='col-3 mb-3'>
          <Autocomplete
            required
            label='Veiculo Principal'
            searchDataSource={onSearchVeiculo}
            selectedItem={data.veiculoPrinc}
            onSelectItem={async (veiculoPrinc) => {
              let dataSource = {};
              if (veiculoPrinc?.nrSeqVeiculo !== undefined) {
                dataSource = await buscaVeiculoEMotorista(
                  veiculoPrinc.nrSeqVeiculo
                );
              }
              setData({
                ...data,
                ...dataSource,
                veiculoPrinc,
                nrSeqVeiculoPrinc: veiculoPrinc.nrSeqVeiculo,
              });
            }}
            dataSourceTextProperty='noVeiculo'
          />
        </div>
        <div className='col-3 mb-3'>
          <Autocomplete
            label='Compartimento'
            searchDataSource={onSearchVeiculo}
            selectedItem={data.veiculoComp}
            onSelectItem={(veiculoComp) => {
              setData({
                ...data,
                veiculoComp,
                nrSeqVeiculoComp: veiculoComp.nrSeqVeiculo,
              });
            }}
            dataSourceTextProperty='noVeiculo'
          />
        </div>
        {data.nrSeqVeiculoComp > 0 && (
          <div className='col-3 mb-3'>
            <Autocomplete
              label='Compartimento B'
              searchDataSource={onSearchVeiculo}
              selectedItem={data.veiculoCompB}
              onSelectItem={(veiculoCompB) => {
                setData({
                  ...data,
                  veiculoCompB,
                  nrSeqveiculoCompB: veiculoCompB.nrSeqVeiculo,
                });
              }}
              dataSourceTextProperty='noVeiculo'
            />
          </div>
        )}
        {data.nrSeqVeiculoComp > 0 && (
          <div className='col-3 mb-3'>
            <Autocomplete
              label='Compartimento C'
              searchDataSource={onSearchVeiculo}
              selectedItem={data.veiculoCompC}
              onSelectItem={(veiculoCompC) => {
                setData({
                  ...data,
                  veiculoCompC,
                  nrSeqVeiculocompC: veiculoCompC.nrSeqVeiculo,
                });
              }}
              dataSourceTextProperty='noVeiculo'
            />
          </div>
        )}
      </div>
      <div className='row'>
        <div className='col-3 mb-3'>
          <Textbox
            label='Media De'
            text={media.mediaDe}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(mediaDe) => setMedia({ ...media, mediaDe })}
          />
        </div>
        <div className='col-3 mb-3'>
          <Textbox
            label='Media Ate'
            text={media.mediaAte}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(mediaAte) => setMedia({ ...media, mediaAte })}
          />
        </div>
        <div className='col-3 mb-3'>
          <Textbox
            label='Valor Percentual'
            text={media.vlrPercentual}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(vlrPercentual) =>
              setMedia({ ...media, vlrPercentual })
            }
          />
        </div>
        <div className='col-3 mt-3'>
          <Button
            theme={Theme.Primary}
            template={Button.Templates.Button}
            text='Adicionar'
            onClick={onAdicionarMedia}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columnsAnexos}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
