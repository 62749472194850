export default class Parametro {
  constructor(jsonObject = {}) {
    this.noParametro = jsonObject.noParametro;
    this.noConteudo = jsonObject.noConteudo;
    this.descricao = jsonObject.descricao;
    this.flgTipo = jsonObject.flgTipo;
    this.tamanho = jsonObject.tamanho;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
  }

  toJson = () => JSON.stringify(this);
}
