import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'ui/components';
import { Theme, BootstrapSizes } from 'ui/Helpers/utils';
import { getStatus } from 'core/services/SEG/whatsapp';
import ModalQRCode from './modalQRCode';
import ModalStatus from './modalStatus';

let getStatusInterval;
export default function Whatsapp() {
  const [state, setState] = useState({ viewQrCode: false, viewStatus: false });
  const [status, setStatus] = useState({ connected: false });

  const loadStatus = useCallback(async () => {
    const { whatsStatus } = await getStatus();
    setStatus(whatsStatus);
    return whatsStatus;
  }, []);

  useEffect(() => {
    loadStatus();

    return () => {
      clearInterval(getStatusInterval);
      getStatusInterval = null;
      setTimeout(() => {}, 1500);
    };
  }, []);

  const handleConnect = () => {
    setState({ ...state, viewQrCode: true });

    // Consulta o status a cada 2 segundos para ver se ele ja leu o qrcode
    getStatusInterval = setInterval(() => {
      loadStatus().then(({ connected }) => {
        if (connected) {
          clearInterval(getStatusInterval);
          getStatusInterval = null;
          setState({ ...state, viewQrCode: false });
        }
      });
    }, 1000);
  };

  const handleModalQRCodeClose = () => {
    setState({ ...state, viewQrCode: false });
    clearInterval(getStatusInterval);
    getStatusInterval = null;
  };

  const renderConnected = () => (
    <div className='alert alert-success'>
      <FontAwesomeIcon icon='wifi' className='me-2' />O telefone
      <Button
        template={Button.Templates.Link}
        text={status.id}
        theme={Theme.Success}
        onClick={() => setState({ ...state, viewStatus: true })}
      />
      está connectado ao Whatsapp. Utilize o celular para desconectar caso seja
      nescessário
    </div>
  );

  const renderDisconnected = () => (
    <div className='alert alert-danger'>
      <FontAwesomeIcon icon='wifi' className='me-2' />
      Não há nenhum número connectado de Whatsapp.
      <Button
        outline
        theme={Theme.Success}
        text='Conectar'
        size={BootstrapSizes.ExtraSmall}
        className='float-end'
        onClick={handleConnect}
      />
    </div>
  );

  return (
    <>
      {status.connected ? renderConnected() : renderDisconnected()}
      <ModalQRCode show={state.viewQrCode} onClose={handleModalQRCodeClose} />

      {status.connected && (
        <ModalStatus
          show={state.viewStatus}
          data={status.info}
          onClose={() => setState({ ...state, viewStatus: false })}
        />
      )}
    </>
  );
}
