import api from '../api';

export const getStatus = async () => {
  const response = await api.get(`/SEG/WhatsApp/Status`);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    whatsStatus: value,
  };
};

export const getQRCode = async () => {
  const response = await api.get(`/SEG/WhatsApp/GenerateQRCode`);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    url: value,
  };
};
