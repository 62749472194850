import React, { useState } from 'react';

import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { Button, Modal, Loader, Notification } from 'ui/components';

import { sendPocOrcamentoToAprove } from 'core/services/POC/pocOrcamento';

const style = {
  mensagem: {
    fontSize: '14px',
  },
};

export default function ModalAprovacaoDesconto({ show, onClose, data }) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSendToAprove = async () => {
    setLoading(true);
    const {
      message: msg,
      status,
      data: dataOrcamento,
    } = await sendPocOrcamentoToAprove(data);
    if (status === ResponseStatus.Success) {
      onClose(msg, dataOrcamento);
    } else {
      setMessage({
        message: msg,
        theme: Theme.Danger,
      });
    }

    setLoading(false);
  };

  return (
    <Modal
      show={show}
      title='Orçamento com desconto excedido'
      onClose={onClose}
      size={BootstrapSizes.Large}
      icon={['far', 'comment-alt']}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        <div className='row'>
          <div className='col-sm-12'>
            <div className='alert alert-info fs-8' role='alert'>
              <p style={style.mensagem}>
                O valor de desconto informado ultrapassa o percentual associado
                ao seu perfil de vendedor. Para prosseguir com estes valores
                será necessário a aprovação deste orçamento.
              </p>
            </div>
            .
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Confirmar'
          theme={Theme.Success}
          onClick={() => {
            onSendToAprove();
          }}
        />
        <Button
          text='Cancelar'
          theme={Theme.Danger}
          onClick={() => {
            onClose('', data);
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
