import qs from 'qs';
import Pagination from 'core/models/pagination';
import ClienteMotivoInativo from 'core/models/FIN/clienteMotivoInativo';
import api from '../api';

const baseURL = `/FIN/ClienteMotivoInativo`;

export const getClienteMotivoInativoList = async (filters) => {
  const response = await api.get(`/$ClienteMotivoInativo/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ClienteMotivoInativo(json)) ?? [],
  };
};

export const getClienteMotivoInativoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ClienteMotivoInativo(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getClienteMotivoInativoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    clienteMotivoInativo:
      value?.map((json) => new ClienteMotivoInativo(json)) ?? [],
  };
};

export const getClienteMotivoInativo = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ClienteMotivoInativo(value) : {};
};

export const saveClienteMotivoInativo = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteClienteMotivoInativo = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printClienteMotivoInativo = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelClienteMotivoInativo = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllClienteMotivoInativo = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
