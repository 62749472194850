class Bonus {
  constructor(jsonObject = {}) {
    this.nrSeqBonus = jsonObject.nrSeqBonus;
    this.cdBonus = jsonObject.cdBonus;
    this.noBonus = jsonObject.noBonus;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqOperadora = jsonObject.nrSeqOperadora;
    this.flgConsMinBonus = jsonObject.flgConsMinBonus;
    this.flgQuantDuracao = jsonObject.flgQuantDuracao;
    this.flgValor = jsonObject.flgValor;
    this.vlrInicioBonus = jsonObject.vlrInicioBonus;
    this.nrSeqGrupoTipoChamadoConsmin = jsonObject.nrSeqGrupoTipoChamadoConsmin;
  }

  toJson = () => JSON.stringify(this);
}
export default Bonus;
