import { Menu } from 'core/models/SEG';
import api from '../api';

// const baseURL = `/SEG/Menu`;

export const getMenus = async () => {
  const response = await api.get(`/Menu`);

  return response?.data ? response.data.map((json) => new Menu(json)) : [];
};

export const getMenuList = async (filters) => {
  const response = await api.get(`/Menu/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    data: value?.map((json) => new Menu(json)) ?? [],
  };
};

export const getMenuAutoComplete = async (filters) => {
  const response = await api.get(`/Menu/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    data: value?.map((json) => new Menu(json)) ?? [],
  };
};

export const getMenu = async (id) => {
  const response = await api.get(`/Menu/Find`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Menu(value) : {};
};
