import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getProfissaoList,
  printProfissoes,
  deleteProfissoes,
} from 'core/services/SEG/profissao';
import { CSDSelPage, GridView, Textbox } from 'ui/components';

const columns = [
  { key: 'nrSeqProfissao', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdProfissao', title: 'Código/CBO' },
  { key: 'noProfissao', title: 'Descrição' },
];

export default function Profissao({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async () => {
    setLoading(true);

    const { profissoes, pagination } = await getProfissaoList(filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(profissoes, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { profissoes, pagination } = await getProfissaoList({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(profissoes, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (SortBy) => {
    setFiltros({ ...filtros, SortBy });
    await search();
  };
  const onPageSizeChange = async (totalByPage) => {
    setFiltros({ ...filtros, totalByPage });
    await search();
  };
  const onPageChange = async (page) => {
    setFiltros({ ...filtros, page });
    await search();
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Profissao/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqProfissao = sourceRow.find((e) => e.key === 'nrSeqProfissao');
    onOpenMaintenance(nrSeqProfissao.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printProfissoes(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteProfissoes(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Profissões'
      loading={loading}
      onSearch={search}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-6'>
          <Textbox
            label='Descricao'
            text={filtros.noProfissao}
            onChangedValue={(e) => setFiltros({ ...filtros, noProfissao: e })}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            enableExcelExport
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
