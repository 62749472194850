import Pessoa from 'core/models/SEG/pessoa';
import PessoaDocumento from 'core/models/SEG/pessoaDocumento';

export default class ColetaConsignatario extends Pessoa {
  constructor(jsonObject = {}) {
    super(jsonObject);
    this.nrSeqPessoaConsig = jsonObject.nrSeqPessoaConsig;
    this.cdColetaConsignatario = jsonObject.cdColetaConsignatario;
    this.flgAtivo = jsonObject.flgAtivo;
    this.flgOrigem = jsonObject.flgOrigem;
    this.flgDestino = jsonObject.flgDestino;
    this.consignatarioDocumentos =
      jsonObject.consignatarioDocumentos?.map(
        (el) => new PessoaDocumento(el)
      ) ?? [];
  }

  toJson = () => JSON.stringify(this);
}
