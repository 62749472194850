import React from 'react';
import { FontAwesomeIcon } from 'ui/components';

const Modal = ({ show, title, onClose, children, size, position, icon }) => {
  if (show) document.body.classList.add('modal-show');
  else return null;

  const modalClasses = ['modal-dialog', 'modal-dialog-scrollable'];

  if (position) modalClasses.push(`modal-dialog-${position}`);
  else modalClasses.push(`modal-dialog-centered`);

  if (size) modalClasses.push(`modal-${size}`);

  return (
    <>
      <div className='modal show' style={{ display: 'block' }}>
        <div className={modalClasses.join(' ')} role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                {icon && <FontAwesomeIcon icon={icon} className='me-1' />}
                {title}
              </h5>
              {onClose && (
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={onClose}
                />
              )}
            </div>
            {children}
          </div>
        </div>
      </div>
      <div role='presentation' className='modal-backdrop show' />
    </>
  );
};

Modal.Body = ({ className, children }) => (
  <div className={className ? `modal-body ${className}` : 'modal-body'}>
    {children}
  </div>
);
Modal.Footer = ({ children }) => <div className='modal-footer'>{children}</div>;

export default Modal;
