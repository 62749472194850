import React, { useEffect, useRef, useState } from 'react';
import { Theme, ResponseStatus, BootstrapSizes } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getAprovaFinanceiroPagined,
  printAprovaFinanceiro,
  excelAprovaFinanceiro,
  saveAprovaReprova,
  saveCancela,
} from 'core/services/FIN/aprovaFinanceiro';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';
import { getTipoPendenciaFinanceiraList } from 'core/services/FIN/tipoPendenciaFinanceira';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import {
  CSDSelPage,
  GridView,
  DatePicker,
  Autocomplete,
  Textbox,
  Switch,
} from 'ui/components';
import ModalReprovar from './modalReprovar';

export default function AprovaFinanceiro({
  transaction,
  isActive,
  onOpenReport,
  findTransaction,
  onOpenTransaction,
}) {
  const gridView = useRef();
  const [data, setData] = useState({ flgApenasPendentes: true });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [show, setShow] = useState(false);
  const [pedido, setPedido] = useState({});

  const observacaoRef = useRef({});

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);
    const { dataPendencias, pagination } = await getAprovaFinanceiroPagined(
      params ?? data
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(dataPendencias, pagination);

    setLoading(false);
  };

  const onClickAprovar = async (e) => {
    setLoading(true);
    const obj = {
      ...e,
      flgAprovada: true,
    };

    const { message: pdf, status } = await saveAprovaReprova(obj);

    setLoading(false);

    if (status === ResponseStatus.Success) {
      const msgSave = `Documento ${e.nrDocumento} aprovado `;
      setMessage({
        message: msgSave,
        theme: Theme.Success,
      });
      setData({ ...data, flgApenasPendentes: true });
      search();

      if (pdf) {
        onOpenReport(transaction.noTransacao, pdf);
      }
    } else {
      setMessage({
        message: pdf,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
  };

  const onClickCancelar = async (e) => {
    setLoading(true);
    const obj = {
      ...e,
      flgAprovada: true,
    };

    const { message: pdf, status } = await saveCancela(obj);

    setLoading(false);

    if (status === ResponseStatus.Success) {
      const msgSave = `Documento ${e.nrDocumento} cancelado `;
      setMessage({
        message: msgSave,
        theme: Theme.Success,
      });
      setData({ ...data, flgApenasPendentes: true });
      search();

      if (pdf) {
        onOpenReport(transaction.noTransacao, pdf);
      }
    } else {
      setMessage({
        message: pdf,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
  };

  const onClickObservacao = async (e) => {
    setPedido(e);
    setShow(true);
  };

  useEffect(() => {
    async function load() {
      setLoading(true);

      const { dataPendencias, pagination } = await getAprovaFinanceiroPagined(
        data
      );

      if (gridView && gridView.current)
        gridView.current.setDataSource(dataPendencias, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...data, sortBy };
    setData(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...data, totalByPage };
    setData(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...data, page };
    setData(filters);
    await search(filters);
  };

  const onClickOpen = async (e) => {
    let transactionOpen = '';
    const cdtransacao = e.tipoPendenciaFinanceira.cdTransacao;
    if (cdtransacao.toLowerCase() === 'orcamento') {
      transactionOpen = findTransaction(TransacationCodes.Orcamento);
    } else if (cdtransacao.toLowerCase() === 'notafiscalentrada') {
      transactionOpen = findTransaction(TransacationCodes.NotaFiscalEntrada);
    }

    if (transactionOpen) {
      transactionOpen.registryKey = e.nrSeqPendente;
      onOpenTransaction(transactionOpen, true);
    }
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printAprovaFinanceiro(data);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onDownloadExcel = async () => {
    setLoading(true);

    const { value: base64 } = await excelAprovaFinanceiro(data);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'AprovaFinanceiro.csv';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoaCli: e,
    });
    if (msg) onSetMessage(status, msg);
    return clientes;
  };

  const onSearchTipoPendenciaFinanceira = async (e) => {
    const {
      status,
      message: msg,
      tipoPendenciaFinanceiras,
    } = await getTipoPendenciaFinanceiraList({
      noTipoPendenciaFinanceira: e,
    });
    if (msg) onSetMessage(status, msg);
    return tipoPendenciaFinanceiras;
  };

  const onSearchFornecedorAutoComplete = async (e) => {
    const {
      status,
      message: msg,
      data: fornecedores,
    } = await getFornecedorAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return fornecedores;
  };

  const columns = [
    { key: 'nrDocumento', title: 'Nr. Documento' },
    {
      key: 'tipoPendenciaFinanceira.noTipoPendenciaFinanceira',
      title: 'Tipo Pendência Financeira',
      sortable: false,
    },
    {
      key: 'dtCadastro',
      title: 'Data Cadastro',
      format: GridView.DataTypes.Date,
    },
    { key: 'fornecedor.noFornecedor', title: 'Fornecedor', sortable: false },
    {
      key: 'vlrTotal',
      title: 'Valor',
      sortable: false,
      format: GridView.DataTypes.Money,
    },
    { key: 'noObs', title: 'Observação', sortable: false },
    { key: 'vendedor.noPessoa', title: 'Vendedor', sortable: false },
    { key: 'pedidoCompra.cdPedidoCompra', title: 'Pedido', sortable: false },
    {
      key: 'noSituacao',
      title: 'Situação',
      colorText: 'noColor',
      sortable: false,
    },
    { key: 'nrSeqPendente', title: 'NrSeqPendente', visible: false },
    {
      key: 'nrSeqTipoPendenciaFinanceira',
      visible: false,
    },
    { key: 'nrSeqPendente', title: 'nrSeqPendente', visible: false },
    {
      key: 'nrSeqPendenciaFinanceira',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOpen(e),
      theme: Theme.Info,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Detalhe Pedido',
      tooltipDirection: 'bottom',
      title: 'Detalhe',
    },

    {
      key: 'nrSeqPendenciaFinanceira',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickAprovar(e, datasource),
      theme: Theme.Success,
      icon: 'check',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Aprovar',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgPendenteAprovacao',
      title: 'Aprovar',
    },
    {
      key: 'nrSeqPendenciaFinanceira',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickObservacao(e, datasource),
      theme: Theme.Danger,
      icon: 'ban',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Reprovar',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgPendenteAprovacao',
      title: 'Reprovar',
    },
    {
      key: 'nrSeqPendenciaFinanceira',
      title: 'Cancelar',
      visibleDynamic: '!flgPendenteAprovacao',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickCancelar(e, datasource),
      theme: Theme.Danger,
      icon: ['fa', 'ban'],
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Cancelar',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção Documentos Pendentes'
      loading={loading}
      onSearch={() => search()}
      onPrint={onPrint}
      onDownloadExcel={onDownloadExcel}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-2'>
          <Textbox
            maxLength={10}
            label='Nr. Documento'
            text={data.nrDocumento}
            onChangedValue={(nrDocumento) => setData({ ...data, nrDocumento })}
          />
        </div>
        <div className='col-6'>
          <Autocomplete
            label='Tipo Pendência Financeira'
            searchDataSource={onSearchTipoPendenciaFinanceira}
            selectedItem={data.tipoPendenciaFinanceira}
            onSelectItem={(tipoPendenciaFinanceira) => {
              setData({
                ...data,
                tipoPendenciaFinanceira,
                nrSeqTipoPendenciaFinanceira:
                  tipoPendenciaFinanceira.nrSeqTipoPendenciaFinanceira,
              });
            }}
            dataSourceTextProperty='noTipoPendenciaFinanceira'
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Apenas Pendentes'
            checked={data.flgApenasPendentes}
            onChange={(flg) => setData({ ...data, flgApenasPendentes: flg })}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Reprovados'
            checked={data.flgReprovado}
            onChange={(flgReprovado) => setData({ ...data, flgReprovado })}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-6'>
          <Autocomplete
            label='Cliente'
            searchDataSource={onSearchCliente}
            selectedItem={data.clientes}
            onSelectItem={(clientes) => {
              setData({
                ...data,
                clientes,
                nrSeqPessoaCli: clientes.nrSeqPessoaCli,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data de Cadastro Inicial'
            text={data.dtCadastro}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtCadastro) =>
              setData({ ...data, dtCadastroInicial: dtCadastro })
            }
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data de Cadastro Final'
            text={data.dtCadastro}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtCadastro) =>
              setData({ ...data, dtCadastroFinal: dtCadastro })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-6'>
          <Autocomplete
            label='Fornecedor'
            searchDataSource={onSearchFornecedorAutoComplete}
            selectedItem={data.fornecedor}
            onSelectItem={(fornecedor) => {
              setData({
                ...data,
                fornecedor,
                nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-2'>
          <Textbox
            maxLength={10}
            label='Código Pedido Compra'
            text={data.cdPedidoCompra}
            onChangedValue={(cdPedidoCompra) =>
              setData({ ...data, cdPedidoCompra })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            transaction={transaction}
          />
        </div>
      </div>
      <ModalReprovar
        show={show}
        ref={observacaoRef}
        pedido={pedido}
        onClose={() => {
          setShow(false);
        }}
        onSuccess={() => {
          const msg = 'O documento foi reprovado com sucesso';
          setMessage({
            message: msg,
            theme: Theme.Success,
          });
          setData({ ...data, flgApenasPendentes: true });
          search();
        }}
      />
    </CSDSelPage>
  );
}
