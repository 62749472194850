import React, { useState } from 'react';
import { Autocomplete, Textbox, Textarea } from 'ui/components';
// @ts-expect-error
import { ResponseStatus } from 'ui/Helpers/enums.ts';
import { IManutencaoDemanda } from 'core/interfaces/HELP/demanda';
import {
  getOperadoraAutoComplete,
  getClienteLinhasByCliente,
} from 'core/services/TEL';

interface IIccidMessage {
  show: boolean;
  status: number;
  message?: string;
}

export default function ManutencaoReciclarChip({
  data,
  setData,
  onSetMessage,
  setLoading,
}: IManutencaoDemanda) {
  const [iccidMessage, setIccidMessage] = useState<IIccidMessage>({
    show: false,
    status: ResponseStatus.Error,
  });

  const onSearchOperadora = async (e: string) => {
    const {
      status,
      message: msg,
      operadoras,
    } = await getOperadoraAutoComplete({ noOperadora: e });

    if (status === ResponseStatus.Error) {
      onSetMessage(status, msg);
      return [];
    }

    return operadoras.filter(
      (operadora: any) =>
        !operadora.noOperadora.includes('Oi') &&
        !operadora.noOperadora.includes('Importação Manual')
    );
  };

  const onSearchChip = async (nrIccid: string): Promise<void> => {
    setLoading(true);

    const {
      status,
      message: msg,
      data: linhas,
    } = await getClienteLinhasByCliente({
      nrIccid,
    });

    if (status === ResponseStatus.Success) {
      if (linhas.length > 0) {
        setIccidMessage({
          show: true,
          status: ResponseStatus.Error,
          message:
            'Esse ICCID esta vinculado a um chip de um cliente linha ativo. Ao Efetivar esta demanda o chip terá o ICCID removido',
        });
      } else {
        setIccidMessage({
          show: true,
          status: ResponseStatus.Success,
          message: 'Nenhum Cliente Linha ativo vinculado a esse chip',
        });
      }
    } else {
      onSetMessage(status, msg);
    }

    setLoading(false);
  };

  return (
    <>
      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Operadora Destino'
            enabled={data.flgPermiteAlterar}
            required
            searchDataSource={onSearchOperadora}
            selectedItem={data.operadoraDestino}
            onSelectItem={(operadoraDestino: any) =>
              setData({
                ...data,
                operadoraDestino,
                nrSeqOperadoraDestino: operadoraDestino.nrSeqOperadora,
              })
            }
            dataSourceTextProperty='noOperadora'
          />
        </div>
        <div className='col-3'>
          {/* @ts-expect-error */}
          <Textbox
            label='ICCID'
            text={data.nrIccid}
            required
            maxLength={20}
            onChangedValue={(nrIccid: string) => {
              setData({ ...data, nrIccid });
            }}
            onBlur={async (_: any, value: string) => {
              if (value.length < 20) {
                onSetMessage(
                  ResponseStatus.Error,
                  'ICCID deve conter 20 digítos'
                );
              } else if (value.length === 20) {
                await onSearchChip(value);
              }
            }}
          />
        </div>
        {iccidMessage.show && (
          <div className='col'>
            <div
              className={`card ${
                iccidMessage.status === ResponseStatus.Success
                  ? 'border-success'
                  : 'border-danger'
              }`}
            >
              <div
                className={`card-body ${
                  iccidMessage.status === ResponseStatus.Success
                    ? 'text-success'
                    : 'text-danger'
                }`}
              >
                <p className='card-text'>{iccidMessage.message}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <Textarea
            label='Observação'
            maxLength={2000}
            readOnly={!data.flgPermiteAlterar}
            rows={5}
            text={data.noObservacao}
            onChangedValue={(noObservacao: string) => {
              setData({ ...data, noObservacao });
            }}
          />
        </div>
      </div>
    </>
  );
}
