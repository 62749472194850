import qs from 'qs';
import Pagination from 'core/models/pagination';
import PedidoCompra from 'core/models/VEN/pedidoCompra';
import api from '../api';

const baseURL = `/VEN/PedidoCompra`;

export const getPedidoCompraList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PedidoCompra(json)) ?? [],
  };
};

export const getPedidoCompraAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    pedidocompras: value?.map((json) => new PedidoCompra(json)) ?? [],
  };
};

export const getPedidoCompraForNfEntrada = async (filters) => {
  const response = await api.get(`${baseURL}/SearchForNfEntrada`, {
    params: filters,
  });

  const { value } = response.data ?? {};

  return value ? new PedidoCompra(value) : {};
};

export const getPedidoCompraPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PedidoCompra(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPedidoCompra = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};

  return value ? new PedidoCompra(value) : {};
};

export const savePedidoCompra = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const onCancelaPedidoCompra = async (data) => {
  const response = await api.post(`${baseURL}/Cancel`, data);
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ? new PedidoCompra(value) : {},
  };
};

export const onReopenPedidoCompra = async (data) => {
  const response = await api.post(`${baseURL}/Reopen`, data);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ? new PedidoCompra(value) : {},
  };
};

export const onEncerrarPedidoCompra = async (data) => {
  const response = await api.post(`${baseURL}/Close`, data);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ? new PedidoCompra(value) : {},
  };
};

export const deletePedidoCompra = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPedidoCompra = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPedidoCompra = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPedidoCompra = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
export const groupOrder = async (filters) => {
  const response = await api.post(`${baseURL}/GroupOrder`, filters);
  return response.data ?? {};
};

export const getPedidoCompraTipoDespesaDefault = async (filters) => {
  const response = await api.get(`${baseURL}/SearchTipoDespesaDefault`, {
    params: filters,
  });
  const { value } = response.data ?? {};

  return value ? new PedidoCompra(value) : {};
};

export const getParametroFiltraProdutosDuplicados = async () => {
  const response = await api.get(
    `${baseURL}/SearchParametroProdutosDuplicados`,
    {}
  );

  const { value } = response.data ?? {};
  return value;
};

export const getParametroNaoConsiderarFreteNosProdutos = async () => {
  const response = await api.get(
    `${baseURL}/SearchParametroNaoConsiderarFreteNosProdutos`,
    {}
  );

  const { value } = response.data ?? {};
  return value;
};

export const printPedidoCompraSelecao = async (filters) => {
  const response = await api.get(`${baseURL}/PrintRelSelecao`, {
    params: filters,
  });
  return response.data ?? {};
};
